<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="cancelDeleteRole"
  >
    <template #header>
      <div style="margin-left: 0px">Delete</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ deleteobj.role }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="cancelDeleteRole"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <div>
    <hlx-modal
      class="upc"
      :modal-active="modal_active_role"
      :height="'515px'"
      :width="'500px'"
      :modal="true"
      @close="closerolesmodel"
    >
      <template #header>
        <div style="margin-left: 0px">
          {{ matchFromStatic("New email template") }}
        </div>
      </template>
      <template #content>
        <div class="modal-content">
          <div class="input-section-v2">
            <hlx-input
              :key="emailTriggersComponetN"
              v-model:value="payload.name"
              :label-animation="true"
              label-value="Trigger name"
              :required="true"
              :display-error="true"
              :custom-error="isRoleName"
              :custom-error-message="''"
              type="text"
              :clearable="true"
              @at-input="roleNameCheck"
            />

            <div style="width: 100%">
              <hlx-input
                :key="emailTriggersComponetC"
                :pre-val="$route.query.customName"
                :label-animation="true"
                label-value="Choose module"
                :required="true"
                :disabled="true"
                :display-error="true"
                :custom-error="isRoleName"
                :custom-error-message="''"
                type="text"
                :clearable="true"
                @at-input="roleNameCheck"
              />
            </div>
            <div class="input-wrapper" style="margin-bottom: 20px">
              <hlx-select
                :key="emailTriggersComponetE"
                v-model:value="payload.event"
                :options="eventValues"
                prop-value="value"
                :placeholder-value="'Choose event'"
                :label-animation="true"
                :required="true"
                :clearable="true"
              ></hlx-select>
            </div>
            <div style="width: 100%; margin-top: 20px">
              <div class="decision-name-wrapper">
                <!-- {{payload.tags}} -->
                <!-- {{ payload.tags }}{{"option"}}{{tagsList}} -->
                <hlx-input
                  :key="emailTriggersComponetD"
                  v-model:value="payload.description"
                  :label-animation="true"
                  label-value="Description"
                  type="textarea"
                  :rows="7"
                  :clearable="true"
                ></hlx-input>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="closerolesmodel"
          >Cancel</hlx-button
        >
        <!-- {{payload.role }} {{payload.role   != '' ? false : true}} -->
        <hlx-button
          class="primary sm"
          style="margin-left: 16px"
          :disabled="payload.role != '' ? false : true"
          @click="addNewRole()"
          >Add</hlx-button
        >
      </template>
    </hlx-modal>
  </div>

  <div class="app-layout catalog offering">
    <section class="new-right-panel upc">
      <div class="new-right-panel-parent-container no-filter-panel">
        <div id="mySidepanel" class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <section class="new-layout-basic-no-breadcrumb">
            <div class="new-layout-header">
              <span class="new-layout-header-container">
                <hlx-breadcrumb style="font-size: 16px" :auto-route="true" />
              </span>
            </div>

            <div class="new-layout-body catalog-v2">
              <!-- search and filter  -->
              <div
                v-if="tableData?.length > 0 || searchvalue !== ''"
                class="header-utils-and-switch-container"
                style="width: 100%; margin-bottom: 17px !important"
              >
                <span style="width: 100%"
                  ><span class="schema-header-title">{{
                    matchFromStatic("Email triggers")
                  }}</span>
                  <span style="font-size: 12px; font-weight: 300">
                    {{
                      matchFromStatic(
                        "Automated actions initiated by email events"
                      )
                    }}</span
                  ></span
                >
                <span class="table-header-utils">
                  <span class="util-items --search"
                    ><hlx-search
                      v-model="searchvalue"
                      :label-value="loadStaticData('LANG-0003')"
                      @search-key="productSearch"
                    ></hlx-search
                  ></span>

                  <span class="filter-table">
                    <hlx-button
                      class="secondary sm add-btn"
                      @click="showDrawerRight"
                    >
                      <i class="icon-filter-regular"></i>
                      <!-- <span v-if="filtertaglength != 0" class="filter-count">{{
                          filtertaglength
                        }}</span> -->
                    </hlx-button>
                  </span>

                  <span id="add-product-offer" class="util-items util-items-add"
                    ><hlx-button
                      v-show="permisstionData.add"
                      class="primary sm add-btn"
                      @click="viewPage({_id:''},false,'add')"
                      ><i
                        class="icon-plus-circle-regular"
                        style="margin-right: 5px"
                      ></i
                      >{{ matchFromStatic("New email template") }}</hlx-button
                    >
                    <hlx-context-menu
                      :top="containertop1"
                      :left="containerleft1"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      style="z-index: 10000"
                      :options="add_context_options"
                      :show="show_add_offer"
                      :style="{ marginTop: '5px', marginRight: '20px' }"
                      @chosen="chooseTicket"
                    ></hlx-context-menu>
                  </span>
                </span>
              </div>

              <!-- image -->
              <section
                v-if="tableData?.length === 0 && searchvalue == ''"
                style="
                  margin-top: 1.2rem;
                  display: flex;
                  flex-direction: column;
                  gap: 1.2rem;
                  align-items: center;
                "
              >
                <span>
                  <img
                    src="@/assets/svg/emailTrigeers.svg"
                    alt=""
                    style="width: 500px; height: 500px"
                  />
                </span>

                <span
                  class="util-items util-items-add"
                  @click="openuploaddrawer"
                >
                  <hlx-button
                    v-show="permisstionData.add"
                    class="primary media add-btn"
                    @click="viewPage({_id:''},false,'add')"
                    ><i
                      class="icon-plus-circle-regular"
                      style="margin-right: 5px"
                    ></i
                    >{{ matchFromStatic("New email template") }}</hlx-button
                  >
                </span>
              </section>

              <!-- TableData -->
              <div
                v-if="tableData.length > 0"
                style="height: 72vh; overflow: auto"
                class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
              >
                <hlx-table
                  :column-count="accounts_table_theads.length + 2"
                  :border="['table', 'header', 'horizontal']"
                  :row-hover="true"
                  theme="grey"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="accounts_table_theads_cc"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'center'"
                      style="padding: 0 15px"
                      :fixed="'left'"
                      @new-resize-width="resizedWidth"
                      >{{ matchFromStatic("S.no") }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-for="(i, index) in accounts_table_theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :width="i.width !== undefined ? i.width : ''"
                      :align="i.align !== undefined ? i.align : ''"
                      @sorting_func="sorting_Data"
                      @new-resize-width="resizedWidth"
                    >
                      {{ matchFromStatic(i.label) }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-show="supplierStatus == false"
                      v-if="permisstionData.edit || permisstionData.delete"
                      :align="'center'"
                      :width="70"
                      :fixed="'right'"
                      @new-resize-width="resizedWidth"
                      ><span class="action-icon">
                        <i
                          class="icon-settings-regular"
                          @click="reAssignOrgTableHeader()"
                        ></i> </span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr v-for="(i, index) in paginatedData" id="" :key="index">
                      <hlx-table-cell :align="'center'" :fixed="'left'">
                        {{ serialNumber(index) }}
                      </hlx-table-cell>
                      <hlx-table-cell
                        v-for="(j, col_index) in accounts_table_theads"
                        :key="col_index"
                        :align="j.align"
                        :fixed="j.fixed"
                      >
                        <div
                          v-if="
                            j.prop.toLowerCase() === 'name' &&
                            permisstionData.edit
                          "
                          :id="j.prop + index"
                          class="link-name"
                          @click="viewPage(i, false , 'view')"
                        >
                          {{ i[j.prop] }}
                        </div>
                        <div
                          v-if="
                            j.prop.toLowerCase() === 'name' &&
                            !permisstionData.edit
                          "
                        >
                          {{ i[j.prop] }}
                        </div>

                        <div v-if="j.prop === 'updatedAt'">
                          {{ dateAndTimeFormatter(i["updatedAt"]) }}
                        </div>
                        <div v-if="j.prop === 'createdAt'">
                          {{ dateAndTimeFormatter(i["createdAt"]) }}
                        </div>
                        <div v-if="j.prop.toLowerCase() === 'createdby'">
                          {{ i["createdBy"] }}
                        </div>

                        <!-- <div v-else>{{ i[j.prop] }}</div> -->
                      </hlx-table-cell>
                      <hlx-table-cell
                        v-show="supplierStatus == false"
                        v-if="permisstionData.edit || permisstionData.delete"
                        :align="'center'"
                        :width="120"
                        :fixed="'right'"
                      >
                        <span
                          :id="'table-context' + serialNumber(index - 1)"
                          class="action-icon"
                        >
                          <i
                            style="position: relative"
                            class="icon-more-vertical-filled"
                            :class="i.context === true ? 'active-action' : ''"
                            @click="
                              crudContextMenu($event, serialNumber(index - 1))
                            "
                          >
                            <hlx-context-menu
                              :top="containertop"
                              :left="containerleft"
                              :conditionvalue="150"
                              :conditiondown="2"
                              :conditionup="-38"
                              :options="computedTableAction(i)"
                              :data="index.toString()"
                              :show="i.context == true"
                              @chosen="
                                closeCrudContextMenu(
                                  $event,
                                  i,
                                  serialNumber(index - 1)
                                )
                              "
                            />
                          </i>
                        </span>
                      </hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>

              <div
                v-if="tableData.length === 0 && searchvalue !== ''"
                style="height: 78vh; overflow: auto"
                class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
              >
                <hlx-table
                  :column-count="accounts_table_theads.length + 2"
                  :border="['table', 'header']"
                  :row-hover="true"
                  theme="grey"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="accounts_table_theads_cc"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'center'"
                      style="padding: 0 15px"
                      :fixed="'left'"
                      >{{ "S.no" }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-for="(i, index) in accounts_table_theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :width="i.width !== undefined ? i.width : ''"
                      :align="i.align !== undefined ? i.align : ''"
                      @sorting_func="sorting_Data"
                    >
                      {{ i.label }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-show="supplierStatus == false"
                      v-if="permisstionData.edit || permisstionData.delete"
                      :align="'center'"
                      :width="70"
                      :fixed="'right'"
                      ><span class="action-icon">
                        <i
                          class="icon-settings-regular"
                          @click="reAssignOrgTableHeader()"
                        ></i> </span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell
                        :colspan="accounts_table_theads.length + 2"
                        >{{ "No data" }}</hlx-table-cell
                      >
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>
            </div>

            <div class="new-layout-footer"></div>
          </section>
        </div>
      </div>

      <div
        v-if="tableData?.length > 0"
        class="pagination-container"
        style="padding: 2%"
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :total="tableData?.length"
          rows-per-page
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    </section>
  </div>
</template>
<script>
import {
  // UPCAxiosInstance,
  MLTAxiosInstance,
  DMNAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: "edit",
    },
    supplierStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    
      currPage: 1,
      rowsPerPage: 10,
      filtertagvalue: [],
      emailTriggersComponetE: 0,
      emailTriggersComponetN: 0,
      emailTriggersComponetC: 0,
      eventValues: [],
      isRoleName: false,
      errorRole: "",
      roleList: [],
      permisstionData: {},
      emailFilterOptions: {
        type: "filter",
        module: "eventScheduler",

        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          module: this.$route.query.module,
        },
        searchQuery: "1",
      },
      tableHeaderOrg: [],
      deleteUser: [],
      tableHeaderOrgcc: [],
      tagsList: [],
      searchvalue: "",
      deleteobj: {},
      isModal: false,
      modal_active_role: false,
      payload: {
        name: "",
        module: "",
        description: "",
        event: "",
      },

      tagcomponetkey: 0,
      emailTriggersComponetD: 0,
      emailTriggersComponetI: 0,

      statusList: [],

      tablehead: "Contracts",
      externalRowsPerPage: 10,
      tableData: [],
      notification: [],
      accounts_table_theads_cc: [
        {
          sortable: false,
          resizable: true,
          width: 300,
          disabled: true,
          checked: true,
          name: "Name",
          label: "Name",
          prop: "name",
          align: "left",
        },

        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Created by",
          label: "Created by",
          prop: "createdby",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Created date",
          label: "Created date",
          prop: "createdAt",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Last Updated",
          label: "Last Updated",
          prop: "updatedAt",
          align: "left",
        },
      ],
      accounts_table_theads: [
        {
          sortable: false,
          resizable: true,
          checked: true,
          width: 300,
          disabled: true,
          label: "Name",
          name: "Name",
          prop: "name",
          align: "left",
        },

        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Created by",
          label: "Created by",
          prop: "createdby",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Created date",
          label: "Created date",
          prop: "createdAt",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Last Updated",
          label: "Last Updated",
          prop: "updatedAt",
          align: "left",
        },
      ],
      keyagreementRef: 0,
      rolecomponentkey: 0,
      name: "",
      formattedData: [],
      copyOfcontractTableData: [],
      duplicateTheads: [],
      re_arrange: false,

      contractData: [],
      filterPanelName: "productSpecification",
      modal_active: false,
      delete_modal_active: false,
      initialPayload: {},
      // userFilterOptions: {
      //   type: "filter",
      //   company: this.$cookies.get("company"),
      //   module: "contacts",
      //   isPaginated: true,
      //   paginatedQuery: {
      //     rowsPerPage: 100,
      //     currentPage: 1,
      //   },
      //   isNeedToBeSort: true,
      //   sortFieldAndOrder: {},
      //   limit: 100,
      //   filterQuery: {
      //     "@baseType": "organisation",
      //   },
      //   searchQuery: "",
      // },
      userDatacount: [],
    };
  },
  computed: {
    processedTableData() {
      return this.tableData?.map((role) => {
        // Ensure role.permission exists before processing it
        const permissionsAccess = role?.permission
          ? Object.entries(role.permission)
              .filter(([, value]) => value?.edit || value?.view)
              .map(([name]) => ({ name }))
          : []; // Default to an empty array if permission is not defined

        // You can determine the user count here if needed

        return {
          ...role,
          permissionsAccess,
          // Include user count here if applicable
        };
      });
    },
    paginatedData() {
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;

      // const sortedData = [...this.processedTableData].sort(
      //   (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)
      // );

      // return sortedData.slice(start, end);

      return this.tableData;
    },
  },
  watch: {
    // data: {
    //   handler(val) {
    //     this.payload = val;
    //     console.log(val, "product value");
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    payload: {
      handler(val) {
        console.log(val, "product value");
        // val.attachment[0]={name:`${val.name}.pdf`}
      },
      immediate: true,
      deep: true,
    },

    tableData: {
      handler(val) {
        this.copyOfcontractTableData = val;
        //     this.tableData =  val.map(role => {
        // const permissionsAccess = Object.entries(role.permission)
        //   .filter(([, value]) => value.edit || value.view)
        //   .map(([name]) => ({ name }));

        // return {
        //   ...role,
        //   permissionsAccess
        // };
        // });
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    // To get table header
    const value = await MLTAxiosInstance.get("partymanagement/individual/auth");

    let sampleTheadsd = await MLTAxiosInstance.get(
      `partyRole/get/${value.data.userId}`
    );

    const mergedPayload = sampleTheadsd?.data;

    console.log(mergedPayload, "mergedPayload");
    this.permisstionData =
      mergedPayload.permission.Settings.sections["Roles and permissions"];

    let sampleTheads = await MLTAxiosInstance.get(`/settings`);

    if (sampleTheads?.data?.entityRef?.["emailTriggers"]) {
      let offerTableHeadModal = sampleTheads.data.entityRef["emailTriggers"];
      let offerTableHeadOrg = sampleTheads.data.entityRef[
        "emailTriggers"
      ].filter((ele) => ele.checked);
      console.log(
        offerTableHeadOrg,
        "offerTableHeadModal",
        offerTableHeadModal
      );

      if (
        offerTableHeadModal.length === this.accounts_table_theads_cc.length
        // &&
        // offerTableHeadModal.every((item, index) => item.name ===  this.accounts_table_theads_cc[index].name)
      ) {
        this.accounts_table_theads_cc = JSON.parse(
          JSON.stringify(offerTableHeadModal)
        );
        this.accounts_table_theads = JSON.parse(
          JSON.stringify(offerTableHeadOrg)
        );
        this.tableHeaderOrgcc = JSON.parse(
          JSON.stringify(this.accounts_table_theads_cc)
        );
        this.tableHeaderOrg = JSON.parse(
          JSON.stringify(this.accounts_table_theads)
        );
      }
    }

    this.refeshContract();
    await this.ticketdataCalling(
      this.SpecificationcurrPage,
      this.SpecificationrowsPerPage
    );
    // let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList = await this.getReferanceData("statuslist");
    // this.eventValues = await this.getReferanceData("eventValues");
  },
  methods: {
    async changeRowsPerPage(count) {
      //

      this.rowsPerPage = count;
      this.ticketdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );
      // this.recalltable();
    },
    async currentPage(val) {
      this.currPage = val;
      this.ticketdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );
      // this.recalltable();

      this.table_height = this.paginatedData.length * 40 + 43;
    },
    async ticketdataCalling(currentPage, rowsPerPage) {
      // const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
      this.companyasignee = "Halleyx";
      this.emailFilterOptions["company"] = "Halleyx";
      this.emailFilterOptions["paginatedQuery"]["currentPage"] = currentPage;
      this.emailFilterOptions["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.emailFilterOptions
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
        this.paginatedData;
      }
    },
    sorting_Data(data, data1) {
      //
      //console.log(data, data1);
      this.emailFilterOptions.type = "filter";
      if (data === "icon-angle-up-small-filled sorting-icon") {
        this.emailFilterOptions.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = 1;
        this.emailFilterOptions.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        this.emailFilterOptions.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = -1;
        this.emailFilterOptions.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-up-small-filled") {
        this.emailFilterOptions.isNeedToBeSort = false;
        let obj = {};
        this.emailFilterOptions.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled") {
        this.emailFilterOptions.isNeedToBeSort = false;
        let obj = {};
        this.emailFilterOptions.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      }
    },
    cancelDeleteRole() {
      this.delete_modal_active = false;
      this.formattedData = [];
      this.rolecomponentkey += 1;
    },
    updateRole(id, selectedRole) {
      // Check if the id already exists in formattedData
      const existingIndex = this.formattedData.findIndex(
        (entry) => entry.relatedParty[0].id === id
      );

      if (existingIndex !== -1) {
        // Update the name if the ID exists
        this.formattedData[existingIndex].name = selectedRole.role;
      } else {
        // Push a new entry
        this.formattedData.push({
          relatedParty: [{ id }],
          name: selectedRole.role,
        });
      }

      console.log(this.formattedData, "this.formattedDatathis.formattedData");
    },

    async deleteExecRow() {
      this.rolecomponentkey += 1;

      const image = await UPCAxiosInstance.delete(
        `/eventScheduler/${this.deleteobj._id}?company=Halleyx`
      );

      if (image) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been removed.`,
        };

        this.refeshContract();

        this.delete_modal_active = false;
      }
    },
    viewPage(i, edit, mode) {
      edit;
      this.$cookies.set("emailTriggers", {
        id: i._id,
        module: this.$route.query.module,
        customName: this.$route.query.customName,
        mode: mode,
      });

      this.$router.push(`/settings/emailTriggersEdit`);
    },
    async addNewRole() {
      console.log(this.payload, "addNewRole");

      const payload = {
        name: this.payload.name,
        module: this.$route.query.module,
        description: this.payload.description,
        startDate: new Date().toJSON(),
        endDate: "2999-09-01T00:00:00.000Z",
        trigger: {
          baseType: "event",
          type: this.payload.event,
        },
        condition: {},
        action: [],
      };

      payload.lastUpdated = new Date().toJSON();
      payload.createdBy = this.$cookies.get("userName");
      try {
        const filterResultData = await UPCAxiosInstance.post(
          `/eventScheduler/V2`,
          payload
        );

        if (filterResultData.data) {
          this.notification = {
            type: "success",
            message: `Nice work! Your new role is now in the list!`,
          };

          this.closerolesmodel();
          this.refeshContract();
        }
      } catch (error) {
        if (error.response?.data?.code === 11000) {
          this.isRoleName = true;
          this.errorRole = this.payload.role;
        } else {
          this.isRoleName = true;
          this.errorRole = this.payload.role;
        }
      }
    },
    async closerolesmodel() {
      this.name = "";
      this.tags = [];
      this.description = "";
      this.isRoleName = false;
      this.tagsList = this.tagsList.map((item) => {
        item.checked = false;
        return item;
      });
      console.log(this.tagsList, "tagsList");

      this.tagcomponetkey += 1;
      this.emailTriggersComponetD += 1;
      this.emailTriggersComponetE += 1;
      this.emailTriggersComponetC += 1;
      this.emailTriggersComponetN += 1;

      this.modal_active_role = false;
    },
    roleNameCheck(val) {
      val;
      if (this.errorRole.length > 0 && this.errorRole === this.payload.role) {
        this.isRoleName = true;
      } else {
        this.isRoleName = false;
      }
    },
    receivedTags(e) {
      let arr = [];
      e.filter((item) => {
        if (item.checked == true) {
          arr.push(item.name);
        }
      });
      this.payload.tags = arr;
      console.log(this.payload.tags, "this.payload.tags");
    },
    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      obj.module = "role";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsList = await this.getReferanceData("tagsList");
      let arr = this.payload.tags;
      arr.push(e.name);
      this.payload.tags = arr;
      this.tagsList = tagsList.filter((item) => {
        return item.module === "role";
      });
    },
    newRoleModule() {
      this.payload = {
        name: "",
        tags: [],
        description: "",
      };
      // this.tagcomponetkey += 1;
      console.log(this.tagsList, "tagsList");
      this.tagsList = this.tagsList.map((item) => {
        item.checked = false;
        return item;
      });
      this.emailTriggersComponetD += 1;
      this.emailTriggersComponetE += 1;
      this.emailTriggersComponetC += 1;
      this.emailTriggersComponetN += 1;
      this.modal_active_role = true;
      this.tagcomponetkey += 1;

      console.log(
        (this.payload = {
          name: "",
          tags: [],
          description: "",
        }),
        this.payload.tags
      );
    },
    reAssignOrgTableHeader() {
      // If there is change in the table header then ressign to the Orginal data
      if (
        this.accounts_table_theads_cc != this.tableHeaderOrgcc &&
        this.tableHeaderOrgcc.length > 0
      ) {
        this.accounts_table_theads_cc = JSON.parse(
          JSON.stringify(this.tableHeaderOrgcc)
        );
      }
      if (
        this.accounts_table_theads != this.tableHeaderOrg &&
        this.tableHeaderOrg.length > 0
      ) {
        this.accounts_table_theads = JSON.parse(
          JSON.stringify(this.tableHeaderOrg)
        );
      }
      this.re_arrange = true;
    },
    async applyColumns(val) {
      // To get rearranged header
      console.log("applyColumns", val);
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      let concatedEntity = sampleTheads?.data?.entityRef || {};

      concatedEntity.emailTriggers = val;

      let reArrangeTableHeadPayload = { entityRef: concatedEntity };

      const changeTableHeader = await MLTAxiosInstance.patch(
        `/settings`,
        reArrangeTableHeadPayload
      );
      console.log(
        changeTableHeader,
        "reArrangeTableHeadPayload",
        reArrangeTableHeadPayload
      );

      let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
      console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

      let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
      this.accounts_table_theads_cc = entityRef["emailTriggers"];
      this.accounts_table_theads = this.accounts_table_theads_cc.filter(
        (ele) => ele.checked
      );
      this.tableHeaderOrgcc = JSON.parse(
        JSON.stringify(this.accounts_table_theads_cc)
      );
      this.tableHeaderOrg = JSON.parse(
        JSON.stringify(this.accounts_table_theads)
      );
    },
    resizedWidth(val) {
      // To get resized width
      console.log(val, "resizedWidth", val);

      const updateWidth = (array) => {
        let item = array.find((i) => i?.prop === val?.prop);
        if (item) item.width = val.width;
      };

      updateWidth(this.accounts_table_theads);
      updateWidth(this.accounts_table_theads_cc);
      console.log(
        this.accounts_table_theads,
        "resizedWidth loop",
        this.accounts_table_theads_cc
      );
      this.applyColumns(this.accounts_table_theads_cc);
    },

    mergePayloads(payload1, payload2) {
      const payload1Roles = [];
      payload1.forEach((item) => {
        if (item.role.length > 0) {
          // Check if there is at least one role
          payload1Roles.push(item.role[0].name);
          // Access the name of the first role
        }
      });

      console.log(payload1Roles, "payload1Roles");

      this.count = 0;
      payload2.map((item) => {
        for (let i = 0; i < payload1Roles.length; i++) {
          if (payload1Roles[i] === item.name) {
            this.count++;
            item.userCount = this.count++;
          }
        }
      });
      for (let i = 0; i < payload1Roles.length; i++) {
        if (payload1Roles[i] === "Upc admin") {
          this.count++;
        }
      }

      console.log(this.count, "payload2 user count", payload2);
    },
    addPermissionAccess(data) {
      return data.map((item) => {
        const permissionAccess = {};

        // Iterate over permissions
        for (const [key, value] of Object.entries(item.permission)) {
          if (value.edit || value.view === "true") {
            permissionAccess[key] = true;
          } else {
            permissionAccess[key] = false;
          }
        }

        // Add permissionAccess to each item
        return {
          ...item,
          permissionAccess,
        };
      });
    },
    addCancel() {
      this.modal_active = false;
    },
    addNewAccount() {
      //
      this.modal_active = true;
    },
    productSearch(val) {
      this.searchvalue = val;

      this.emailFilterOptions["company"] = "Halleyx";
      this.emailFilterOptions["type"] = "search";
      this.emailFilterOptions["searchQuery"] = this.searchvalue;

      this.emailFilterOptions.searchList = [
        {
          offerType: { $regex: val, $options: "i" },
        },
        {
          marketSegment: { $regex: val, $options: "i" },
        },
        {
          priority: {
            $regex: val,
            $options: "i",
          },
        },
        {
          status: {
            $regex: val,
            $options: "i",
          },
        },
        {
          "relatedParty.name": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "relatedParty.company": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "troubleTicketSpecification.Email id": {
            $regex: val,
            $options: "i",
          },
        },
      ];
      this.currPage = 1;
      this.rowsPerPage = 10;
      if (this.filtertagvalue.length != 0) {
        this.emailFilterOptions["type"] = "filter-search";
      }

      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    async refeshContract() {
      // let tableData = await UPCAxiosInstance.post(`/util/filterData`,this.emailFilterOptions);

      let tableData = await UPCAxiosInstance.post(
        `/util/filterData?company=Halleyx`,
        this.emailFilterOptions
      );
      console.log(tableData.data, "contract");
      this.roleList = await tableData.data.data;
      this.roleList = await this.capitalizeAndSort(this.roleList, "role");
      console.log(this.roleList, "this.roleList ");

      this.tableData = await tableData.data.data.sort(
        (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)
      );
      this.contractData = await tableData.data;
      console.log(tableData.data.length, "length");
    },

    // async changeRowsPerPage(count) {
    //   if (this.company != "") {
    //     this.rowsPerPage = count;
    //   }
    // },
    // async currentPage(val) {
    //   if (this.company != "") {
    //     this.currPage = val;
    //   }
    //   this.table_height = this.paginatedData.length * 40 + 43;
    // },
    contracttyprfunc(val) {
      this.payload.type = val.name;
    },
    emitStatus(val) {
      this.payload.status = val.name;
      console.log("Discount status", val);
    },

    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    showNewContactModal() {
      this.isModal = true;
    },
    async closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        this.viewPage(ele, true,"edit");
        // this.$router.push(`/SM/supplier/edit`);
      } else if ($event.toLowerCase() == "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;

        if (this.deleteobj.count > 0) {
          this.userFilterOptions.company = this.$cookies.get("company");
          this.userFilterOptions.filterQuery = {
            "@baseType": "organisation",
            company: this.$cookies.get("company"),
            "role.name": this.deleteobj.role,
          };

          const filterResultData = await MLTAxiosInstance.post(
            `util/filterData?company=${this.$cookies.get("company")}`,
            this.userFilterOptions
          );

          this.deleteUser = filterResultData.data.data;
          console.log(this.deleteUser, "this.deleteUser");

          this.roleList = this.roleList.filter(
            (item) => item.role !== this.deleteobj.role
          );
        }
      }
    },

    computedTableAction(data) {
      console.log(this.permisstionData, "this.permisstionData", data);

      if (this.permisstionData.delete && this.permisstionData.edit) {
        if (data.system === true) {
          return [
            {
              label: "Edit",
              icon: "icon-file-text-edit-regular",
            },
          ];
        } else {
          return [
            {
              label: "Edit",
              icon: "icon-file-text-edit-regular",
            },
            {
              label: "Delete",
              icon: "icon-trash-regular",
            },
          ];
        }
      } else if (this.permisstionData.delete) {
        if (data.system === false) {
          return [
            {
              label: "Delete",
              icon: "icon-trash-regular",
            },
          ];
        }
      } else {
        if (data.system === true) {
          return [
            {
              label: "Edit",
              icon: "icon-file-text-edit-regular",
            },
          ];
        }
      }
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    arrangeColumns(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
    // applyColumns(val) {
    //   this.duplicateTheads = [];
    //   val.forEach((e) => {
    //     if (e.checked === true) {
    //       this.duplicateTheads.push(e);
    //     }
    //   });
    //   this.accounts_table_theads = [...this.duplicateTheads];
    // },
  },
};
</script>
<style lang="scss" scoped>
.header-add-supplier {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  .tablehead-add-supplier {
    font-weight: bold;
  }
  .search-add-supplier {
    display: flex;
    gap: 10px;
  }
}
.contacts-username-supplier {
  display: flex;
  align-items: center;
  .right-head-supplier {
    display: flex;
    flex-direction: column;
  }
  .head-supplier-left {
    display: flex;
    justify-content: center;
    width: 44px;
    align-items: center;
    font-size: 45px;
    margin-right: 10px;
  }
  .head-supplier-right {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    .head-supplier-name {
      margin-right: 10px;
    }
  }
  .head-supplier-address {
    font-size: 10px;
    color: #929191;
    margin-top: 3px;
  }
}
</style>
