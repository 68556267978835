<template>
<hlx-alert-notification :notify="notification" :auto-close="true" />
<!-- 407px -->
<hlx-modal
      :key="modalKey"
      :modal-active="showAddNewRegionModal"
      :close-icon="true"
      :outer-layer-close-option="false"
      :height="dyheight"
      :width="'800px'"
      :modal="true"
      @close="closeAddNewRegionModal"
    >
      <template #header>
        <div>
          <!-- Add region -->
           {{matchFromStatic('Add region')}}
        </div>
      </template>
      <template #content>
        <div class="modal-content" style="display: flex; gap: 5%">
          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 20px;
              width: 50%;
            "
          >
            <div style="display: flex; justify-content: space-between; gap: 10px">
              <span style="width: 200%">
                <div
                  class="decision-name-wrapper"
                  :style="{ 'margin-bottom': customnameerror ? '-7.6%' : 0 }"
                >
                  <hlx-input
                    v-model:value="regionName"
                    :required="true"
                    :pre-val="regionName"
                    label-value="Region name"
                    :label-animation="true"
                    type="text"
                    :clearable="false"
                    :display-error="true"
                    :custom-error="isExistingName"
                    :custom-error-message="isExistingNameErrorMessage"
                    @focus-out="validateNameNew"
                    @at-input="validateName"
                  ></hlx-input>
                </div>
                <div style="width: 100%">
                  <hlx-status
                    :type="'select-status'"
                    :required="true"
                    :status-option="filteredstatus"
                    :prevalue="servicestatus"
                    @emit-select="emitStatus1"
                  ></hlx-status>
                </div>
              </span>
            </div>
  
            <div class="decision-name-wrapper">
              <hlx-select
                v-model:value="type"
                :required="true"
                :pre-value="type"
                :options="dropdown_data_type"
                :placeholder-value="'Type'"
                :label-animation="true"
                :label="'value'"
                :prop-value="'value'"
                :clearable="true"
              @selected-value="getSelectedSku"
              />
            </div>
  
            <!-- {{ addressExist }} -->
            <addressComponent
            :key="addressKey"
            :required="true"
            :customnameerror="addressExist"
            :type="type"
              @address-data="receivedAddressData"
            ></addressComponent>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              width: 50%;
            "
          >
            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="description"
                :pre-val="description"
                type="textarea"
                :label-value="'Description'"
                :required="false"
                :label-animation="true"
                :display-error="true"
                :rows="7"
                :custom-error-message="customErrorMessage"
                @focus-out="focusOutName"
                @at-input="validateName"
              ></hlx-input>
            </div>
  
            <div class="decision-name-wrapper">
              <hlx-multi-select
                :key="modalKey"
                :pre-value="tags"
                :options="tagsList"
                prop-value="name"
                :multi-select="true"
                :placeholder="'Tags'"
                :label-animation="true"
                :clearable="true"
                :tooltipposition="'left'"
                @add-data="addTagList"
                @custom-change="receivedTags"
              ></hlx-multi-select>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="closeAddNewRegionModal">
          <!-- Cancel -->
           {{matchFromStatic('Cancel')}}
        </hlx-button>
        <hlx-button
          class="primary sm"
          style="margin-left: 10px"
          :disabled="!enableAdd"
          @click="addNewRegion"
        >
          <!-- Add -->
           {{matchFromStatic('Add')}}
        </hlx-button>
      </template>
    </hlx-modal>
  </template>
  
  <script>

  import addressComponent from "@/components/Product/ServiceQualificationDuplicate/dynamicAddress.vue"
// import addressComponent from "./dynamicAddress.vue";
import {
  DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
  // UPCAxiosInstance
} from "@/config/axiosConfig";

export default {
    
  components: {
    addressComponent,
    },

    props: {
      openModal: {
        type: Boolean,
      default:false
      },
    },

    emits: ["addRegion","closeModal"],
    data() {
      return {
        addressExist: false,
        
        enableAdd:false,
        notification:"",
        dyheight:'407px',
        modalKey:1,
        characteristic: {
        "@type": "",
        street: "",
        city: "",
        country: "",
        // regio nName:'',
        default: false,
        description: "",
        postalCode: "",
        state: "",
        stateOrProvince: "",
        street1: "",
        street2: "",
          unit: "",
          lat: null,
          lng:null,
        },



        
        showAddNewRegionModal:false,
        regionName: "",
        description: "",
        type: null,
        tags: [],
        filteredstatus: [],
        servicestatus: 'Pending',
        dropdown_data_type: [
        // { value: "Full address" },
        // { value: "Street" },
        { value: "Province" },
        { value: "City" },
        { value: "Postal code" },
        // { value: "Prefix (FSA)" }
      ],
        tagsList: [],
        customnameerror: false,
        isExistingName: false,
        isExistingNameErrorMessage: "Region name already exists",
        regionList: [],
      };
    },

    watch: {


      
      regionName: {
      handler(val) {
        val

          let stopAdding = true;
          for (let index = 0; index < this.regionList.length; index++) {
        const element = this.regionList[index];
        element.name
          if (element.name?.toLowerCase() === this.regionName?.toLowerCase()) {
            stopAdding = false;
        }
        
          }

          if (stopAdding === false) { 
            console.log('region  already exist:::');
            this.isExistingName = true;
          } else {          
          console.log('region not already exist:::');
          this.isExistingName = false;            
          }

          
          if (this.type && this.regionName && this.servicestatus && !this.addressExist && stopAdding && this.characteristic?.description !== '') {
            this.enableAdd = true;
          } else {
            this.enableAdd = false;
          }

          
      },
      immediate: true,
      deep: true,
      },

      

      
      servicestatus: {
      handler(val) {
        val
          if (this.type && this.regionName && this.servicestatus && !this.addressExist && this.characteristic?.description !== '') {
            this.enableAdd = true;
          } else {
            this.enableAdd = false;
        }
      },
      immediate: true,
      deep: true,
      },

      addressExist: {
      handler(val) {
        val
          if (this.type && this.regionName && this.servicestatus && !this.addressExist && this.characteristic?.description !== '') {
            this.enableAdd = true;
          } else {
            this.enableAdd = false;
        }
      },
      immediate: true,
      deep: true,
      },

      characteristic: {
handler(val) {
  val
    if (this.type && this.regionName && this.servicestatus && !this.addressExist && this.characteristic?.description !== '') {
      this.enableAdd = true;
    } else {
      this.enableAdd = false;
  }
},
immediate: true,
deep: true,
},

    
      openModal: {
            async handler(val) {
            console.log(val, "addRegionsModalComp");
                console.log("addRegionsModalComp::",val);
          if (val) {
            this.showAddNewRegionModal = true;
          } else {
            this.showAddNewRegionModal = false;
                }               
            },
            immediate: true,
            deep: true,
      },

      type: {
        async handler(val) {
          console.log("type))", val);
          if (this.type && this.regionName && this.servicestatus && !this.addressExist && this.characteristic?.description !== '') {
            this.enableAdd = true;
          } else {
            this.enableAdd = false;
        }


          setTimeout(() => {
            console.log("this.modalKey",this.modalKey);
            this.modalKey
          }, 2000);
         
        },
        immediate: true,
        deep:true,
      }
    },

    async mounted() {

      let status = await this.getReferanceData('statuslist');
      console.log("status::", status);
      this.filteredstatus = status.filter((item) =>
        ["Active", "Inactive", "Pending"].includes(item.name)
      );



      let tagsListData =  await this.getReferanceData('tagsList')
    this.tagsList = tagsListData?.filter((item) => {
      return item.module === "sq region";
    });

      let regionList = await this.parentRegionList();
      this.regionList = regionList;

      

      
    },
    
    methods: {

      

  getSelectedSku(val){
        val
        console.log("getSelectedSku::::", val,
          // this.addressKey
        );

        // this.addressKey += 1;
        
        // this.modalKey += 1;
        if (val.value === "Full address") {
          this.dyheight = '643px'  
        } else {
          this.dyheight = '407px'
        }

   },

      geopoint(lat,lng) { 
        console.log("geopoint::::",lat,lng);
        this.lat = lat;
      this.lng = lng;
      },
      validateName() {
        // Add name validation logic
      },
      validateNameNew() {
        // Add additional validation logic
      },
      emitStatus1(status) {
        this.servicestatus = status.value;
        console.log("status::",
          // this.addressKey,
          status.value);
        
      },
      async addTagList(e) {
        console.log("addTagList::",e);
        
      let obj = {};
      obj.name = e.name;
      obj.module = "sq region";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsList = await DMNAxiosInstance.get(`mapRef/collection/tagsList`);
      let arr = this.tags;
      arr.push(e.name);
      this.tags = arr;
      this.tagsList = tagsList.data.data.filter((item) => {
        return item.module === "sq region";
      });
      // console.log(e);
      // this.tagsList.push(e);
    },
      receivedTags(e) {
        console.log("receivedTags::",e);        
        let arr = [];
      e.filter((item) => {
        if (item.checked == true) {
          arr.push(item.name);
        }
      });
        this.tags = arr;
      console.log("this.tags::",this.tags);
      
      },
      async receivedAddressData(val) {
        console.log("Address data received", val);

        this.characteristic = val?.characteristic;
        this.lat = val?.characteristic?.lat;
        this.lng = val?.characteristic?.lng;

        let r = await this.callFilterInclusion(this.type, val?.characteristic);
        console.log("r::", r);

        this.addressExist = r;
        
        
      },
      focusOutName() {
        // Custom focus out logic
        },
        closeAddNewRegionModal() { 
            this.regionName = '';
            this.servicestatus = '';
            this.type = '';
            this.address = '';
            this.description = '';
            this.tags = '';
            
          this.showAddNewRegionModal = false;
          this.$emit('closeModal', this.showAddNewRegionModal);
            

        },

      async addNewRegion() {

        this.showAddNewRegionModal = false;
        



        const obj = {

     

          description: this.characteristic.description,
          notes: this.description,
          postalCode: this.characteristic.postalCode,
          latitude: this.characteristic.lat,
          longitude: this.characteristic.lng,
          city: this.characteristic.city,
          country: this.characteristic.country,
          stateOrProvince: this.characteristic.state,
          street: this.characteristic.street,
          unit: this.characteristic.unit,
          createdAt: this.formatCurrentTime(),
          updatedAt: this.formatCurrentTime(),
          tags: this.tags,
          type: "inclusion",
          createdBy:this.$cookies.get('company'),
          statusInclusion: this.servicestatus,
          status:null,
          criteria: {
            type: this.type,
            regionName: this.regionName,
          },
          
        };

        

        obj
        console.log("finally____:", obj);

        
        if (this.enableAdd) {

          
          if (obj) {
          setTimeout(async() => {
            let postresponse = await UPCAxiosInstance.post(
        `/geographicAddress`,
        obj
      );



      if (postresponse) {
        this.notification = {
          type: "success",
          message: `Region created successfully!`,
        };



        

        this.$emit('addRegion', obj);



      }
          }, 1500);
       
    }
        }


        
      } 

    },
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  