<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">
        <!-- Delete data -->
        <span v-if="staticData">{{ staticData?.deleteData?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          <!-- Are you sure you want to delete -->
          <span v-if="staticData">{{ staticData?.cmAttachmentModalMessage1?.label[loginUserDetails]?.label }}</span>
          <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >
        <!-- Cancel -->
        <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >
        <!-- Delete -->
        <span v-if="staticData">{{ staticData?.delete?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
    </template>
  </hlx-modal>
  <div class="add-feature-drawer">
    <hlx-drawer
      :show="isModal"
      position="right"
      width="400"
      :footer="true"
      :show-close-icon="true"
      @close="closeDiscount"
    >
      <template #body>
        <div style="height: 90vh !important">
          <div
            style="
              border-bottom: 1px solid #d4d4d4;
              padding-bottom: 5px;
              padding: 13px;
            "
          >
            <b v-if="editMode == false">
              <!-- New ticket -->
              <span v-if="staticData">{{ staticData?.newTicket?.label[loginUserDetails]?.label }}</span>
            </b>
            <b v-if="editMode == true">
              <!-- Edit ticket -->
              <span v-if="staticData">{{ staticData?.editTicket?.label[loginUserDetails]?.label }}</span>
            </b>
          </div>

          <div
            :key="updateKey"
            class="ticket-drawer-res"
            style="
              display: flex;
              padding: 18px 13px;
              flex-direction: column;
              gap: 18px;
            "
          >
          <span
              v-for="(addFieldsObject, index) in labelFieldsValue"
              :key="index"
            >
              <!-- Dropdown preview -->
              <div
                v-if="addFieldsObject['@type'] == 'Dropdown'"
                class="feature-value-dropdown-preview"
              >
                <span
v-if="addFieldsObject.name === 'Requester' &&
                    $route.params.from.toLowerCase() === 'accounts'">
                  <hlx-select
                    :key="componentkey1R"
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-value="
                      preValFunc(
                        payload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject
                      )
                    "
                    :options="contactPayload"
                    :placeholder-value="addFieldsObject.name"
                    :label-animation="true"
                    :inline-search="true"
                    :required="addFieldsObject.mandatory"
                    :clearable="true"
                    :label="'name'"
                    :prop-value="'name'"
                    @selected-value="RelatedPArtyData"
                  ></hlx-select>
                </span>
                <span v-else-if="addFieldsObject.name === 'Assigned to'">
                  <hlx-select
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-value="
                      preValFunc(
                        payload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject
                      )
                    "
                    :options="assignedToo"
                    :placeholder-value="addFieldsObject.name"
                    :label="'name'"
                    :prop-value="'name'"
                    :label-animation="true"
                    :search-image-tag="true"
                    :sub-title="'company'"
                    :tag-value="'newRole'"
                    :image-link="'link'"
                    :inline-search="true"
                    @selected-value="AssignedData"
                  ></hlx-select>
                </span>
                <span v-else-if="addFieldsObject.name === 'Ticket category'">
                  <hlx-select
                    :key="componentkey1"
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-value="
                      preValFunc(
                        payload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject
                      )
                    "
                    :options="addFieldsObject.characteristicSpecification"
                    :placeholder-value="addFieldsObject.name"
                    :label-animation="true"
                    :inline-search="true"
                    :required="addFieldsObject.mandatory"
                    :clearable="true"
                    :label="'value'"
                    :prop-value="'value'"
                    @selected-value="TicketcategoryData"
                  ></hlx-select>
                </span>
                <span v-else>
                  <hlx-select
                  v-if="
                      addFieldsObject.name.toLowerCase() !== 'requester' ||
                      $route.params.from.toLowerCase() !== 'contacts'
                    "
                    :key="componentkey1"
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-value="
                      preValFunc(
                        payload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject
                      )
                    "
                    :options="addFieldsObject.characteristicSpecification"
                    :placeholder-value="addFieldsObject.name"
                    :label-animation="true"
                    :required="addFieldsObject.mandatory"
                    :clearable="true"
                    :label="'value'"
                    :prop-value="'value'"
                  ></hlx-select>
                </span>
              </div>

              <!-- Radio Button -->
              <div v-if="addFieldsObject['@type'] == 'Radio button'">
                <div v-if="addFieldsObject['@type'] == 'Radio button'">
                  <b> {{ addFieldsObject.name }}<sup v-if="addFieldsObject.mandatory" style="color:  #ff5050;">*</sup></b>
                  <br />
                </div>
                <br />
                <div
                  v-if="addFieldsObject['@type'] == 'Radio button'"
                  class="feature-value-radio-preview"
                >
                  <hlx-input
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-val="
                      preValFunc(
                        payload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject
                      )
                    "
                    :required="addFieldsObject.mandatory"
                    :options="addFieldsObject.characteristicSpecification"
                    :position="'horizontal'"
                    :prop-value="'value'"
                    :label="'value'"
                    :type="'border-radio-buttons'"
                  />
                </div>
              </div>
              <!--------- Date ----->
              <div
                v-if="addFieldsObject['@type'] == 'Date'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Date'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :pre-val="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :required="addFieldsObject.mandatory"
                  :date-format="'dd (short month), yyyy'"
                  :label-value="addFieldsObject.name"
                  :label-animation="true"
                  type="date"
                  icon="calendar-regular"
                  icon-position="right"
                />
              </div>
              <!-- Single line Text -->
              <div
                v-if="addFieldsObject['@type'] == 'Single line text'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Single line text'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <span
                  v-if="
                    payload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ] === 'Email id'
                  "
                >
                  <hlx-input
                  v-if="staticData"
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
               :default-mail-error="staticData?.pleaseEnterValidUrl?.label[loginUserDetails]?.label"
                    :pre-val="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :required="addFieldsObject.mandatory"
                    type="email"
                    :label-animation="true"
                    :label-value="addFieldsObject.name"
                    :display-error="true"
                    :custom-error="isEmailEmpty"
                    :custom-error-message=" staticData?.enterValidEmail?.label[loginUserDetails]?.label || 'Enter a valid email.(eg: user@example.com)'"
                    @at-input="
                      validateEmail(
                        payload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject.name
                      )
                    "
                  />
                </span>
                <span v-else>
                  <hlx-input
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-val="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :required="addFieldsObject.mandatory"
                    :type="'text'"
                    :label-animation="true"
                    :label-value="addFieldsObject.name"
                  />
                </span>
              </div>

              <!-- Multi line Text -->
              <div
                v-if="addFieldsObject['@type'] == 'Multi line text'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Multi line text'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :pre-val="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :type="'textarea'"
                  :required="addFieldsObject.mandatory"
                  :label-animation="true"
                  :label-value="addFieldsObject.name"
                />
              </div>

              <!-- Multi select -->

              <div
                v-if="addFieldsObject['@type'] == 'Multi select'"
                class="feature-value-radio-preview"
              >
                <hlx-multi-select
                  :key="componentKeyM"
                  :required="addFieldsObject.mandatory"
                  :pre-value="
                    preValFunction(
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ],
                      addFieldsObject
                    )
                  "
                  :options="addFieldsObject.characteristicSpecification"
                  :tooltipposition="'left'"
                  :placeholder="addFieldsObject.name"
                  :label-animation="true"
                  :prop-value="'value'"
                  :label="'value'"
                  :disabled="false"
                  @selected-array="selectedValue($event, addFieldsObject.name)"
                ></hlx-multi-select>
              </div>

              <!-- Number -->
              <div
                v-if="addFieldsObject['@type'] == 'Number'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Number'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :pre-val="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :required="addFieldsObject.mandatory"
                  :type="'number'"
                  :round-off="true"
                  :label-animation="true"
                  :label-value="addFieldsObject.name"
                />
              </div>

              <!-- Decimal -->
              <div
                v-if="addFieldsObject['@type'] == 'Decimal'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Decimal'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                v-model:value="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :pre-val="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :type="'number'"
                  :step="0.1"
                  :label-animation="true"
                  :label-value="addFieldsObject.name"
                  :required="addFieldsObject.mandatory"
                />
              </div>

              <!-- Address -->

              <div
                v-if="addFieldsObject['@type'] == 'Address'"
                class="feature-value-radio-preview"
              >
                <!-- {{payload.troubleTicketSpecification[0][addFieldsObject.name]}} -->
                <addressComponent
                  :view="false"
                  :required="addFieldsObject.mandatory"
                  :pre-val="
                    addressPreValue(
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    )
                  "
                  @address-data="
                    receivedAddressData($event, addFieldsObject.name)
                  "
                ></addressComponent>
              </div>
              <!-- Attachment -->
              <div
                v-if="addFieldsObject['@type'] == 'Attachment'"
                class="feature-value-attachment-preview"
              >
                <attachment
                  :key="attachmnetComponetKey"
                  :pre-value="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :required="addFieldsObject.mandatory"
                  @file-upload="attachmentEmit($event, addFieldsObject.name)"
                  @removed-data="removedEmit($event,   addFieldsObject.name
                    )"
                  @fileuploaddata="convertData"
                ></attachment>
                <!-- :pre-val=" payload?.tro ubleTicketSpecification[0][addFieldsObject?.name]" -->
              </div>
            </span>
          </div>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 16px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeDiscount"
            >
            <!-- Cancel -->
            <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
          <hlx-button
            v-if="editMode == false"
            class="primary sm"
            style="margin-left: 10px"
            :disabled="!isFormValid"
            @click="addNewticket"
            >
            <!-- Add -->
            <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
          <hlx-button
            v-else
            class="primary sm"
            style="margin-left: 10px"
            :disabled="!isFormValid"
            @click="editedTicket(id, payload)"
            >
            <!-- Save -->
            <span v-if="staticData">{{ staticData?.save?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>

  <section>
    <div v-if="tableData?.length >= 1" class="header-add-supplier">
      <div class="tablehead-add-supplier">{{ tablehead }}</div>
      <div class="search-add-supplier">
        <span
          ><hlx-search
          v-if="staticData"
            v-model="searchvalue"
            :label-value=staticData?.search?.label[loginUserDetails]?.label
            @search-key="productSearch"
          ></hlx-search
        ></span>

        <span v-if="isDataLoaded"
          ><hlx-button class="primary sm" @click="showNewContactModal">
            <i class="icon-plus-circle-regular"></i>
            <!-- New Ticket -->
            <span v-if="staticData">{{ staticData?.newTicket?.label[loginUserDetails]?.label }}</span>
          </hlx-button></span
        >
      </div>
    </div>
  </section>
  <div>
    <section>
      <div
        v-if="tableData.length > 0"
        style="height: 78vh; overflow: auto"
        class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
      >
        <hlx-table
            :label-value="tableModalLang"
          :column-count="tickets_table_theads.length + 2"
          :border="['table', 'header', 'horizontal']"
          :row-hover="true"
          theme="grey"
          :re-arrange-columns="toggleRearrangeModal"
          :re-arrange-heads="tickets_table_theads_cc"
          @close-rearrange="toggleRearrangeModal = false"
          @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head
              :width="60"
              :align="'center'"
              style="padding: 0 15px"
              :fixed="'left'"
              @new-resize-width="resizedWidth"
              >{{ "S.no" }}</hlx-table-head
            >
            <hlx-table-head
              v-for="(i, index) in tickets_table_theads"
              :key="index"
              :prop="i.prop"
              :sortable="i.sortable"
              :resizable="i.resizable"
              :width="i.width !== undefined ? i.width : ''"
              :align="i.align !== undefined ? i.align : ''"
              @sorting_func="sorting_Data"
              @new-resize-width="resizedWidth"
            >
              {{ i.label }}</hlx-table-head
            >
            <hlx-table-head :align="'center'" :width="70" :fixed="'right'" @new-resize-width="resizedWidth"
              ><span class="action-icon">
                <i
                  class="icon-settings-regular"
                   @click="reAssignOrgTableHeader()"
                ></i> </span
            ></hlx-table-head>
          </template>
          <template #tbody>
            <tr v-for="(i, index) in paginatedData" id="" :key="index">
              <hlx-table-cell :align="'center'" :fixed="'left'">
                {{ serialNumber(index) }}
              </hlx-table-cell>
              <hlx-table-cell
                v-for="(j, col_index) in tickets_table_theads"
                :key="col_index"
                :align="j.align"
                :fixed="j.fixed"
              >
                <div
                  v-if="j.prop.toLowerCase() === 'displayid'"
                  style="margin: 0.5rem 0"
                  class="link-name"
                  @click="chatApp(i)"
                >
                  {{ i.displayID }}
                </div>
                <div
                  v-if="j.prop.toLowerCase() === 'name'"
                  style="margin: 0.5rem 0"
                >
                  {{ i.name }}
                </div>
                <div
                    v-if="j.prop?.toLowerCase() === 'subject'"
                    style="margin: 0.5rem 0"
                  >
                    {{ i.troubleTicketSpecification[0]["Subject"] }}
                  </div>
                <div
                    v-if="j.prop?.toLowerCase() === 'user'"
                    style="margin: 0.5rem 0"
                  >
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        margin-bottom: 4px;
                      "
                    >
                      <i class="icon-user-alt-regular"></i>
                      {{ i.troubleTicketSpecification[0]["Requester"] }}
                    </div>
                    <div
                      style="display: flex; align-items: center; gap: 0.5rem"
                    >
                      <i class="icon-envelope-regular"></i>
                      {{ i.troubleTicketSpecification[0]["Email id"] }}
                    </div>
                  </div>
                <div
                  v-if="j.prop.toLowerCase() === 'type'"
                  style="margin: 0.5rem 0"
                >
                  {{ i[j.prop] }}
                </div>
                <div
                  v-if="j.prop.toLowerCase() === 'createdat'"
                  style="margin: 0.5rem 0"
                >
                  {{ dateAndTimeFormatter(i[j.prop]) }}
                </div>
                <div
                  v-if="j.prop.toLowerCase() === 'accounts'"
                  style="margin: 0.5rem 0"
                >
                  {{ i[j.prop] }}
                </div>
                <div
                  v-if="j.prop.toLowerCase() === 'troubleticketspecification'"
                  style="margin: 0.5rem 0"
                >
                  <span style="display: flex; gap: 6px">
              <span>{{i.troubleTicketSpecification[0]["Assigned to"]}}</span>
                      <span v-if="i.troubleTicketSpecification[0]['Assigned to']"
                        ><hlx-label class="primary sm">{{
                        "Upc Admin"
                        }}</hlx-label></span
                      >
                  </span>
                </div>
                <div
                    v-if="j.prop?.toLowerCase() === 'priority'"
                    style="margin: 0.5rem 0"
                  >
                    <span
                      v-if="i[j.prop]?.toLowerCase() === 'high'"
                      style="display: flex; gap: 2px"
                    >
                      <span
                        style="padding-top: 2px; color: rgba(252, 50, 50, 1)"
                        ><i class="icon-flag-filled"
                      /></span>
                      <span> {{ i.priority }}</span>
                    </span>
                    <span
                      v-if="i[j.prop]?.toLowerCase() === 'medium'"
                      style="display: flex; gap: 2px"
                    >
                      <span
                        style="padding-top: 2px; color: rgba(239, 201, 0, 1)"
                        ><i class="icon-flag-filled"
                      /></span>
                      <span> {{ i.priority }}</span>
                    </span>
                    <span
                      v-if="i[j.prop]?.toLowerCase() === 'low'"
                      style="display: flex; gap: 2px"
                    >
                      <span
                        style="padding-top: 2px; color: rgba(112, 187, 152, 1)"
                        ><i class="icon-flag-filled"
                      /></span>
                      <span> {{ i.priority }}</span>
                    </span>
                  </div>
                  <div
                    v-if="j.prop?.toLowerCase() === 'status'"
                    style="margin: 0.5rem 0"
                  >
                    <span
                      v-if="
                        i.troubleTicketSpecification[0].Status?.toLowerCase() ===
                        'open'
                      "
                      style="display: flex; gap: 2px"
                    >
                      <HlxStatus
                        :type="'custom'"
                        :point-color="'rgba(252, 50, 50, 1)'"
                        :status-value="i.troubleTicketSpecification[0].Status"
                        >{{ i[j.prop] }}</HlxStatus
                      ></span
                    >
                    <span
                      v-if="
                        i.troubleTicketSpecification[0].Status?.toLowerCase() ===
                        'close'
                      "
                      style="display: flex; gap: 2px"
                    >
                      <HlxStatus
                        :type="'custom'"
                        :point-color="'rgba(2, 181, 92, 1)'"
                        :status-value="i.troubleTicketSpecification[0].Status"
                        >{{ i[j.prop] }}</HlxStatus
                      >
                    </span>
                    <span
                      v-if="
                        i.troubleTicketSpecification[0].Status?.toLowerCase() ===
                        'in progress'
                      "
                      style="display: flex; gap: 2px"
                    >
                      <HlxStatus
                        :type="'custom'"
                        :point-color="'#F9A645'"
                        :status-value="i.troubleTicketSpecification[0].Status"
                        >{{ i[j.prop] }}</HlxStatus
                      >
                    </span>
                  </div>

                <!-- <div v-else>{{ i[j.prop] }}</div> -->
              </hlx-table-cell>
              <hlx-table-cell :align="'center'" :width="120" :fixed="'right'">
                <span :id="'table-context' + index" class="action-icon">
                  <i
                    style="position: relative"
                    class="icon-more-vertical-filled"
                    :class="i.context === true ? 'active-action' : ''"
                    @click="crudContextMenu($event, index)"
                  >
                    <hlx-context-menu
                      :top="containertop"
                      :left="containerleft"
                      :display="'display'"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      :options="computedTableAction(i)"
                      :data="index.toString()"
                      :show="i.context == true"
                      @chosen="closeCrudContextMenu($event, i, index)"
                    />
                  </i>
                </span>
              </hlx-table-cell>
            </tr>
          </template>
        </hlx-table>
      </div>
    </section>
    <div
      v-if="tableData?.length <= 0"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 70vh;
      "
    >
      <img
        src="@/assets/images/svg/ticketIllustration.svg"
        alt=""
        width="322px"
        height="300px"
      />
      <span v-if="isDataLoaded"
        ><hlx-button
          class="primary sm empty-table-add-button"
          @click="showNewContactModal"
        >
          <i class="icon-plus-circle-regular"></i>
          <!-- Add Tickets -->
          <span v-if="staticData">{{ staticData?.addTickets?.label[loginUserDetails]?.label }}</span>
        </hlx-button></span
      >
    </div>
  </div>
  <div
    v-if="tableData?.length > 10"
    class="cm-accounts-right-panel-pagination-container"
  >
    <hlx-pagination
      v-model:rows-per-page="externalRowsPerPage"
      :label-value=paginationLang
      :total="tableData?.length"
      rows-per-page
      enable-rows-per-page
      :rows-per-page-list="[10, 15, 20]"
      :options="{
        attributes: {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 0,
              right: 1,
            },
          },
        },
      }"
      @current-page="currentPage"
      @updated:rows-per-page="changeRowsPerPage"
    ></hlx-pagination>
  </div>
</template>
<script>
import { UPCAxiosInstance, MLTAxiosInstance } from "@/config/axiosConfig";
import addressComponent from "@/components/CM/autocompleteComponent.vue";
// import chatTicketVue from "@/components/Ticket/chatTicket.vue";
import attachment from "@/components/singleEmailComponent.vue";

export default {
  components: {
    addressComponent,
    attachment,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    module: {
      type: String,
      default: "",
    },
    company: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "edit",
    },
    accountStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loginUserDetails: {},
      staticData:null,
      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      assignedToo:[],
      query: {
        type: "filter-search",
        module: "ticket",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        filterQuery: {},
        searchQuery: "",
      },
      updateKey: 0,
      payload: {
        name: "",
        status: "",
        "@type": "TroubleTicket",
        priority: "",
        troubleTicketSpecification: [],
      },
      duplicatePayload: {
        name: "",
        status: "",
        "@type": "TroubleTicket",
        priority: "",
        duplicateTroubleTicketSpecification: [],
      },
      componentKey: 0,
      labelFieldsValue: [],
      duplicateLabelValues: [],
      searchvalue: "",
      deleteobj: {},
      isModal: false,
      editMode: false,
      tablehead: "Tickets",
      externalRowsPerPage: 10,
      tableData: [],
      duplicateTroubleTicketSpecification: [],
      notification: {},
      tickets_table_theads: [
        {
          name:"ID",
          checked:true,
          sortable: false,
          resizable: true,
          width: 80,
          label: "ID",
          prop: "displayID",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          label: "Category",
          name:"Category",
          prop: "name",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 100,
          name:"Status",
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 100,
          name:"Priority",
          label: "Priority",
          prop: "priority",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          name:"Assigned To",
          label: "Assigned To",
          prop: "troubleTicketSpecification",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          name:"Created By",
          label: "Created By",
          prop: "user",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 150,
          name:"Created on",
          label: "Created on",
          prop: "createdAt",
          align: "left",
        },
      ],
      tickets_table_theads_cc: [
        {
          name:"ID",
          checked:true,
          sortable: false,
          resizable: true,
          width: 80,
          label: "ID",
          prop: "displayID",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          label: "Category",
          name:"Category",
          prop: "name",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 100,
          name:"Status",
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 100,
          name:"Priority",
          label: "Priority",
          prop: "priority",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          name:"Assigned To",
          label: "Assigned To",
          prop: "troubleTicketSpecification",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          name:"Created By",
          label: "Created By",
          prop: "user",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 150,
          name:"Created on",
          label: "Created on",
          prop: "createdAt",
          align: "left",
        },
      ],
      templatePayload: [],
      copytemplatePayload: [],
componentKeyM:0,
attachmnetComponetKey:0,
      componentkey1R:0,
      initialPayload: {},
      contactpayload: [],
      name: "",
      copyOfTicketTableData: [],
      duplicateTheads: [],
      toggleRearrangeModal: false,
      currPage: 1,
      rowsPerPage: 10,
      contractData: [],
      filterPanelName: "productSpecification",
      modal_active: false,
      delete_modal_active: false,
      user: "",
      companyasignee: "",
      isDataLoaded:false,
    };
  },
  computed: {
    
    tableModalLang() {
      return {
        re_arrange_columns: this.matchFromStatic("Re-arrange columns"),
        Cancel: this.matchFromStatic("Cancel"),
        Apply: this.matchFromStatic("Apply"),
        SelectAll: this.matchFromStatic("Select all"),
      };
    },
    paginationLang() { 
      if (this.staticData) {      
      return {
          Rows_per_page : this.staticData?.comRowPerPage?.label[this.loginUserDetails]?.label,
          Showing: this.staticData?.comShowing?.label[this.loginUserDetails]?.label,
          of: this.staticData?.comOf?.label[this.loginUserDetails]?.label,
          results: this.staticData?.comResult?.label[this.loginUserDetails]?.label,
          Go_to: this.staticData?.comGoTo?.label[this.loginUserDetails]?.label,
        }
      } else {
        return {
          Rows_per_page : "Rows per page",
          Showing: "Showing",
          of: "of",
          results: "results",
          Go_to: "Go to",
        }
      }
    },
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.copyOfTicketTableData.slice(start, end);
    },
    mandatoryFields() {
      return this.labelFieldsValue
        .filter((field) => field.mandatory)
        .map((field) => field.name);
    },
    areMandatoryFieldsFilled() {
      return this.mandatoryFields.every(
        (field) => this.payload.troubleTicketSpecification[0][field] !== ""
      );
    },
    isEmailValid() {
      if (
        this.payload &&
        this.payload.troubleTicketSpecification[0] &&
        this.payload.troubleTicketSpecification[0]["Email id"]
      ) {
        const email = this.payload.troubleTicketSpecification[0]["Email id"];
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      } else {
        return true;
      }
    },
    // Validate the entire form
    isFormValid() {
      return this.areMandatoryFieldsFilled && this.isEmailValid;
    },
  },
  watch: {
    tableData: {
      handler(val) {
        this.copyOfTicketTableData = JSON.parse(JSON.stringify(val));
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("loginUserDetails");
    // loginUserDetails
    // await this.$store.dispatch("loginTenantDetails");
    // let r = await this.$store.getters.loginTenantDetails;
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
    console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);

    this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;

    // this.loginUserDetails.languageAbility[0].name
    // this.loginUserDetails.languageAbility[0].code

    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
      console.log("this.staticData:",organisationInfo , this.staticData);
      
    }
    catch (error) {
      console.error("error::", error)
    }



// Change table header

    let accountTicketTableHeads = await MLTAxiosInstance.get(`/settings`);

    try {
      if (this.module == "accounts") {
        this.tickets_table_theads = [
          {
            sortable: false,
            resizable: true,
            disabled:true,
            width: 80,
            label: "ID",
            prop: "displayID",
            align: "left",
            checked:true,
            name:"ID"
          },
          {
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          label: "Subject",
          prop: "subject",
          align: "left",
          checked:true,
          name:"Subject",
        },
        
          {
            sortable: false,
            resizable: true,
            disabled:true,
            width: 100,
            label: "Status",
            prop: "status",
            align: "left",
            checked:true,
            name:"Status"
          },
          {
            sortable: false,
            resizable: true,
            width: 100,
            label: "Priority",
            prop: "priority",
            align: "left",
            checked:true,
             name:"Priority"
          },
          {
            sortable: false,
            resizable: true,
            width: 200,
            label: "Category",
            prop: "name",
            align: "left",
            checked:true,
             name:"Category"
          },
          {
            sortable: false,
            resizable: true,
            width: 200,
            label: "Assigned To",
            prop: "troubleTicketSpecification",
            align: "left",
            checked:true,
             name:"Assigned To"

          },
          {
          sortable: false,
          resizable: true,
          width: 220,
          label: "Requester contact",
          prop: "user",
          align: "left",
          checked:true,
           name:"Requester contact"
        },
          {
            sortable: false,
            resizable: true,
            width: 180,
            label: "Created on",
            prop: "createdAt",
            align: "left",
            checked:true,
             name:"Created on"
          },
        ];
        this.tickets_table_theads_cc = JSON.parse(JSON.stringify(this.tickets_table_theads));
        if (accountTicketTableHeads?.data?.entityRef?.["accountTicket"]) {
  let accountTableHeadModal = accountTicketTableHeads.data.entityRef["accountTicket"];
  let accountTableHeadsOrg = accountTicketTableHeads.data.entityRef["accountTicket"].filter(ele => ele.checked);
  console.log(accountTableHeadsOrg,"accountTableHeadModal account",accountTableHeadModal)

  if (
    accountTableHeadModal.length === this.tickets_table_theads_cc.length 
// &&
//     accountTableHeadModal.every((item, index) => item.name === this.tickets_table_theads_cc[index].name)
  ) {
    console.log(accountTableHeadsOrg,"accountTableHeadModal dup",accountTableHeadModal)
    this.tickets_table_theads_cc = JSON.parse(JSON.stringify(accountTableHeadModal));
      this.tickets_table_theads = JSON.parse(JSON.stringify(accountTableHeadsOrg));
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.tickets_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tickets_table_theads))

  }
}
        this.query.filterQuery["relatedParty.company"] = this.company;
      } else if (this.module == "contacts") {
        this.tickets_table_theads = [
          {
            sortable: false,
            resizable: true,
            disabled:true,
            width: 80,
            label: "ID",
            prop: "displayID",
            align: "left",
            checked:true,
             name:"ID"
          },
          {
            sortable: false,
            resizable: true,
            disabled:true,
            width: null,
            label: "Category",
            prop: "name",
            align: "left",
            checked:true,
             name:"Category"
          },
          {
            sortable: false,
            resizable: true,
            width: 100,
            label: "Status",
            prop: "status",
            align: "left",
            checked:true,
             name:"Status"
          },
          {
            sortable: false,
            resizable: true,
            width: 100,
            label: "Priority",
            prop: "priority",
            align: "left",
            checked:true,
             name:"Priority"
          },
          {
            sortable: false,
            resizable: true,
            width: 200,
            label: "Account",
            prop: "user",
            align: "left",
            checked:true,
             name:"Account"
          },
          {
            sortable: false,
            resizable: true,
            width: 200,
            label: "Created on",
            prop: "createdAt",
            align: "left",
            name:"Created on",
            checked:true
          },
        ];
        this.tickets_table_theads_cc = JSON.parse(JSON.stringify(this.tickets_table_theads));
        if (accountTicketTableHeads?.data?.entityRef?.["contactTicket"]) {
  let accountTableHeadModal = accountTicketTableHeads.data.entityRef["contactTicket"];
  let accountTableHeadsOrg = accountTicketTableHeads.data.entityRef["contactTicket"].filter(ele => ele.checked);
  console.log(accountTableHeadsOrg,"accountTableHeadModal contact",accountTableHeadModal)

  if (
    accountTableHeadModal.length === this.tickets_table_theads_cc.length 
// &&
//     accountTableHeadModal.every((item, index) => item.name === this.tickets_table_theads_cc[index].name)
  ) {
    console.log(accountTableHeadsOrg,"accountTableHeadModal dup",accountTableHeadModal)
    this.tickets_table_theads_cc = JSON.parse(JSON.stringify(accountTableHeadModal));
      this.tickets_table_theads = JSON.parse(JSON.stringify(accountTableHeadsOrg));
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.tickets_table_theads_cc ))
      this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tickets_table_theads))
  }
}
        console.log(this.company, "company");
        if (this.company.query.company) {
          this.query.filterQuery["relatedParty.company"] =
            this.company.query.company;
        } else {
          this.query.filterQuery["relatedParty.company"] =
            this.company.params.name;
        }
      }

else if(this.module !== "contacts" && this.module !== "accounts"){
  this.tickets_table_theads = [
        {
          name:"ID",
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 80,
          label: "ID",
          prop: "displayID",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          label: "Category",
          name:"Category",
          prop: "name",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 100,
          name:"Status",
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 100,
          name:"Priority",
          label: "Priority",
          prop: "priority",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          name:"Assigned To",
          label: "Assigned To",
          prop: "troubleTicketSpecification",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          name:"Created By",
          label: "Created By",
          prop: "user",
          align: "left",
        },
        {
           checked:true,
          sortable: false,
          resizable: true,
          width: 150,
          name:"Created on",
          label: "Created on",
          prop: "createdAt",
          align: "left",
        },
      ]
      this.tickets_table_theads_cc = JSON.parse(JSON.stringify(this.tickets_table_theads));
        if (accountTicketTableHeads?.data?.entityRef?.["residentialCustomerTicket"]) {
  let accountTableHeadModal = accountTicketTableHeads.data.entityRef["residentialCustomerTicket"];
  let accountTableHeadsOrg = accountTicketTableHeads.data.entityRef["residentialCustomerTicket"].filter(ele => ele.checked);
  console.log(accountTableHeadsOrg,"accountTableHeadModal contact",accountTableHeadModal)

  if (
    accountTableHeadModal.length === this.tickets_table_theads_cc.length 
// &&
//     accountTableHeadModal.every((item, index) => item.name === this.tickets_table_theads_cc[index].name)
  ) {
    console.log(accountTableHeadsOrg,"accountTableHeadModal dup",accountTableHeadModal)
    this.tickets_table_theads_cc = JSON.parse(JSON.stringify(accountTableHeadModal));
      this.tickets_table_theads = JSON.parse(JSON.stringify(accountTableHeadsOrg));
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.tickets_table_theads_cc ))
      this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tickets_table_theads))
  }
}
}
      const response = await UPCAxiosInstance.post(
        "util/filterData",
        this.query
      );
      console.log(response.data, "res");
      this.tableData = response.data.data;
    } catch (error) {
      console.error("Error fetching autocomplete data:", error.message);
    }
    const contactpayload = await MLTAxiosInstance.get(
      `/partymanagement/individual/listuser?company=${this.$route.params.companyName}`
    );
    this.contactPayload = await contactpayload.data;

    try {
      // eslint-disable-next-line vue/no-async-in-computed-properties
      const response = await UPCAxiosInstance.get(`/ticketSpec`);
      console.log(response.data, "fields");
      this.copytemplatePayload = response.data.sort(
        (a, b) => a.priority - b.priority
      );
      this.labelFieldsValue = response.data.sort(
        (a, b) => a.priority - b.priority
      );
      let obj = {};
      this.labelFieldsValue.forEach((item) => {
        obj[item.name] = "";
      });
      this.duplicateLabelValues = this.labelFieldsValue;
      this.duplicatePayload.duplicateTroubleTicketSpecification.push(obj);
      this.payload.troubleTicketSpecification.push(obj);
    } catch (error) {
      console.error("Error fetching autocomplete data:", error.message);
    }

    const asignee = await MLTAxiosInstance.get(
      "partymanagement/individual/auth"
    );
    this.user = asignee.data;
    this.companyasignee = asignee.data.company;
    console.log("troubleTicketSpecification", this.copyOfTicketTableData);
    const response = await UPCAxiosInstance.get(`/templateTicket`);

    this.templatePayload = response.data;

    const instance = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${asignee.data.userId}?company=${asignee.data.company}`
    );
    this.instanceData = instance.data;

        const fulltableData = {
      company: `${asignee.data.company}`,
      type: "filter-search",
      module: "hierarchycontacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 100,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: {
        "role.name": "Upc admin",
      },
      searchQuery: "",
    };
    const fulltabledata = await MLTAxiosInstance.post(
      `/util/filterData?company=${asignee.data.company}`,
      fulltableData
    );
    this.assignedToo = fulltabledata.data.data;
    this.assignedToo.forEach((item) => {
      console.log((item.newRole = item.role[0].name));
    });
    console.log(fulltabledata, "fulltabledatafulltabledata");
this.payload.troubleTicketSpecification[0]["Assigned to"] =
      this.instanceData.name;

      this.isDataLoaded = true
  },
  methods: {
    reAssignOrgTableHeader(){
              // If there is change in the table header then ressign to the Orginal data
              if((this.tickets_table_theads_cc != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.tickets_table_theads_cc = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.tickets_table_theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.tickets_table_theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.toggleRearrangeModal=true
    },
    resizedWidth(val){
      // To change resized table header
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.tickets_table_theads);
  updateWidth(this.tickets_table_theads_cc);
console.log(this.tickets_table_theads,"resizedWidth loop",this.tickets_table_theads_cc)
this.applyColumns(this.tickets_table_theads_cc)
    },
     addressPreValue(val) {
      let addressPreVal = {};
      if (val) {
        addressPreVal = val;
      } else {
        addressPreVal = {
          mediumType: "",
          characteristic: {
            city: "",
            country: "",
            postCode: "",
            stateOrProvince: "",
            street1: "",
            street2: "",
            description: "",
            address1: "",
            default: false,
            "@type": "",
            preferred: false,
            postalCode: "",
            unit: "",
            state: "",
          },
        };
      }
      return addressPreVal;
    },
    receivedAddressData(val, name) {
      console.log(val, name, "val, name");
      try {
        // Ensure the target property is an array

        // If val is not empty, add it to the array
        if (val) {
          this.payload.troubleTicketSpecification[0][name] = val;
        }

        console.log(
          val,
          "attachmentEmit",
          this.payload.troubleTicketSpecification[0][name]
        );
      } catch (error) {
        console.error("An error occurred in attachmentEmit:", error);
        // You can add further error handling logic here, like setting a fallback value or notifying the user
      }
    },
    removedEmit(val, payload){
      this.payload.troubleTicketSpecification[0][payload] = val
      console.log(this.payload.troubleTicketSpecification[0][payload],this.payload.troubleTicketSpecification[0]);
      
    },
    attachmentEmit(val, name) {
      try {
        // Ensure the target property is an array
        if (
          typeof this.payload.troubleTicketSpecification[0][name] === "string"
        ) {
          this.payload.troubleTicketSpecification[0][name] = [
            this.payload.troubleTicketSpecification[0][name],
          ];
        } else if (
          !Array.isArray(this.payload.troubleTicketSpecification[0][name])
        ) {
          this.payload.troubleTicketSpecification[0][name] = [];
        }

        // If val is not empty, add it to the array
        if (val) {
          this.payload.troubleTicketSpecification[0][name].push(val);
        }

        // Remove any empty values from the array
        this.payload.troubleTicketSpecification[0][name] =
          this.payload.troubleTicketSpecification[0][name].filter(
            (item) => item
          );

        console.log(
          val,
          "attachmentEmit",
          this.payload.troubleTicketSpecification[0][name]
        );
      } catch (error) {
        console.error("An error occurred in attachmentEmit:", error);
        // You can add further error handling logic here, like setting a fallback value or notifying the user
      }
    },
    chatApp(ele) {
      this.$router.push({
        path: `/ticket/chatApp`,
        query: { id: `${ele.id}` },
      });
    },

    RelatedPArtyData(val) {
      console.log(val, "RelatedPArtyData");
      if (!this.payload.troubleTicketSpecification) {
        this.payload.troubleTicketSpecification = [];
      }
      if (!this.payload.troubleTicketSpecification[0]) {
        this.payload.troubleTicketSpecification[0] = {};
      }
      if (!this.payload.relatedParty) {
        this.payload.relatedParty = [];
      }

      // Ensure there's an object at the 0th index
      if (!this.payload.relatedParty[0]) {
        this.payload.relatedParty[0] = {};
      }

      this.payload.troubleTicketSpecification[0]["Email id"] =
        val?.contactMedium[0]?.characteristic?.emailAddress;
      // Now, safely update the properties
      this.payload.relatedParty[0].id = val._id;
      this.payload.relatedParty[0].name = val.name;
      this.payload.relatedParty[0].role = val.aristocraticTitle;
      this.payload.relatedParty[0].company = val.company;
      this.payload.relatedParty[0].createdBy = this.$cookies.get("company");
      console.log(this.payload, " this.payload this.payload");
    },
    initializeTroubleTicketSpecification() {
      if (!this.payload.troubleTicketSpecification) {
        this.payload.troubleTicketSpecification = [];
      }
    },

    createTicketSpecification(labelFieldsValue, ticketCategory, payload) {
  console.log(labelFieldsValue, ticketCategory, "createTicketSpecification", payload);

  // Ensure labelFieldsValue is an array and payload is an object
  if (!labelFieldsValue || !Array.isArray(labelFieldsValue)) {
    console.error('labelFieldsValue is not an array or is undefined');
    return {};
  }

  if (!payload || typeof payload !== 'object') {
    console.error('payload is not an object or is undefined');
    return {};
  }

  let obj = {};

  labelFieldsValue.forEach((item) => {
    // Check if payload has a pre-existing value for the current item
    let preValue = payload[item.name] || "";

    if (item["@type"] === "Dropdown" || item["@type"] === "Radio button") {
      let found = false;
      item.characteristicSpecification.forEach((itemch) => {
        if (itemch.default === true) {
          obj[item.name] = preValue !== "" ? preValue : itemch.value;
          found = true;
        }
      });
      if (!found) {
        obj[item.name] = preValue !== "" ? preValue : "";
      }

    } else if (item["@type"] === "Multi select") {
      let value = [];
      item.characteristicSpecification.forEach((itemch) => {
        if (itemch.default === true) {
          value.push(itemch.value);
        }
      });
      obj[item.name] = preValue !== "" ? preValue : (value.length > 0 ? value : []);

    } else {
      // If type is not Dropdown, Radio button, or Multi select, use pre-existing value if available
      obj[item.name] = preValue !== "" ? preValue : "";
    }
  });

  // Ensure TicketCategory is set
  obj["Ticket category"] = ticketCategory || 'Unknown Category';

  // Ensure "Assigned to" is set safely
  obj["Assigned to"] = this.instanceData?.name || 'Unassigned';

  return obj;
},
async TicketcategoryData(val) {
      console.log(val,"TicketcategoryData select data");
      
      let found = false;
      let payloadData = JSON.parse(
        JSON.stringify(this.payload.troubleTicketSpecification[0])
      );

      this.templatePayload.some((item) => {
        if (found) return true;
        if (
          val.value === item.category[0]?.name &&
          item.lifecycleState === "Active"
        ) {
          this.labelFieldsValue = item.fields;
          this.labelFieldsValue.sort((a, b) => a.priority - b.priority);
          found = true;
          try {
            this.initializeTroubleTicketSpecification();
            this.payload.troubleTicketSpecification[0] =
              this.createTicketSpecification(
                this.labelFieldsValue,
                val.value,
                payloadData
              );
            console.log("troubleTicketSpecification", this.payload);
          } catch (error) {
            console.error("Error fetching autocomplete data:", error);
          }
          return true;
        } else  {
          this.labelFieldsValue =  this.copytemplatePayload.sort((a, b) => a.priority - b.priority);
          console.log(this.labelFieldsValue ,"this.labelFieldsValue after sort else condtiton");
          try {
            this.initializeTroubleTicketSpecification();
            this.payload.troubleTicketSpecification[0] =
              this.createTicketSpecification(
                this.labelFieldsValue,
                val.value,
                payloadData
              );
            console.log("troubleTicketSpecification", this.payload);
          } catch (error) {
            console.error("Error fetching autocomplete data:", error);
          }
        }
        return false;
      });
    },
    async editedTicket(id, payload) {
      console.log("Payload", payload);
      let CM_ContactLog = {
        message: `${this.payload.troubleTicketSpecification[0]["Ticket category"]} Ticket has been updated`,
        detailedAction: [],
        "@type": "Accounts-overview",
        category: ["accounts"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };
      this.labelFieldsValue.map((item) => {
        let externalReferenceActionLog = this.buildBasicLogByDifferences(
          this.initialPayload.troubleTicketSpecification[0],
          this.payload.troubleTicketSpecification[0],
          item.name,
          item.name
        );
        CM_ContactLog.detailedAction = CM_ContactLog.detailedAction.concat(
          externalReferenceActionLog
        );
      });
      this.initLog(CM_ContactLog);

      console.log(CM_ContactLog, "CM_ContactLog");

      if (this.payload.troubleTicketSpecification[0]["Priority level"]) {
        this.payload["priority"] =
          this.payload.troubleTicketSpecification[0]["Priority level"];
      }
      if (this.payload.troubleTicketSpecification[0]["Status"]) {
        this.payload["status"] =
          this.payload.troubleTicketSpecification[0]["Status"];
      }
      // this.payload['priority'] = 'High'
      if (this.payload.troubleTicketSpecification[0]["Ticket category"]) {
        this.payload["name"] =
          this.payload.troubleTicketSpecification[0]["Ticket category"];
      }
      const patchTicket = await UPCAxiosInstance.patch(
        "/troubleTicket",
        payload
      );
      console.log("patchTicket", patchTicket);
      console.log(id, "id", this.payload.troubleTicketSpecification[0]);
      this.isModal = false;
      this.editMode = false;
      this.recalltable();
      if (patchTicket) {
        this.notification = {
          type: "success",
          message: `Nice work! Your new ticket is now in the list!`,
        };
      }
    },
    async recalltable() {
      const resoponstable = await UPCAxiosInstance.post(
        "util/filterData",
        this.query
      );
      this.tableData = resoponstable.data.data;
    },
    validateEmail(val, name) {
      if (name === "Email id") {
        const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (val.length > 0) {
          if (!reg.test(val)) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    async addNewticket() {
      this.isModal = false;
      this.editMode = false;
      if (this.module === "accounts") {
        const addedHierarchyLogPayload = {
          message: `${this.payload.troubleTicketSpecification[0]["Ticket category"]} Ticket has been added`,
          detailedAction: [],
          action: "Added",
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get("user"),
          ref: [this.$route.params.id],
          "@type": "Accounts-ticket",
          category: ["accounts"],
        };
        this.initLog(addedHierarchyLogPayload);
      }
      if (this.payload.troubleTicketSpecification[0]["Priority level"]) {
        this.payload["priority"] =
          this.payload.troubleTicketSpecification[0]["Priority level"];
      }
      if (this.payload.troubleTicketSpecification[0]["Status"]) {
        this.payload["status"] =
          this.payload.troubleTicketSpecification[0]["Status"];
      }
      // this.payload['priority'] = 'High'
      if (this.payload.troubleTicketSpecification[0]["Ticket category"]) {
        this.payload["name"] =
          this.payload.troubleTicketSpecification[0]["Ticket category"];
      }
      const response = await UPCAxiosInstance.post(
        `/troubleTicket`,
        this.payload
      );
      if (response) {
        this.notification = {
          type: "success",
          message: `Nice work! Your new ticket is now in the list!`,
        };
      }
      this.recalltable();
    },
    async tableDataCalling() {
      try {
        if (this.module == "accounts") {
          this.query.filterQuery["relatedParty.company"] = this.company;
        } else if (this.module == "contacts") {
          this.query.filterQuery["relatedParty.company"] = this.company;
          this.query.filterQuery["relatedParty.name"] = this.data.name;
          // this.
        }
        const response = await UPCAxiosInstance.post(
          "util/filterData",
          this.query
        );
        // console.log(response.data,'res');
        this.tableData = response.data.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
    },

    selectedValue($event, name) {
      console.log("$event", $event);
      const selectedValue = $event.map((item) => item.value);
      this.payload.troubleTicketSpecification[0][name] = selectedValue;
    },
    addCancel() {
      this.modal_active = false;
    },
    addNewAccount() {
      //
      this.modal_active = true;
    },
    productSearch(val) {
      this.searchvalue = val;
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.contractData,
          val.toLowerCase()
        );
        this.tableData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.contractData));
      }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    async refeshContract() {},
    closeDiscount() {
      this.isModal = false;
      this.delete_modal_active = false;
    },
    async changeRowsPerPage(count) {
      if (this.company != "") {
        this.rowsPerPage = count;
      }
    },
    async currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
      }
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    contracttyprfunc(val) {
      this.payload.type = val.name;
    },
    emitStatus(val) {
      this.payload.status = val.name;
      console.log("Discount status", val);
    },
    // RelatedPArtyData(val) {
    //   if (!this.payload.relatedParty) {
    //     this.payload.relatedParty = [];
    //   }

    //   // Ensure there's an object at the 0th index
    //   if (!this.payload.relatedParty[0]) {
    //     this.payload.relatedParty[0] = {};
    //   }

    //   // Now, safely update the properties
    //   this.payload.relatedParty[0].id = val._id;
    //   this.payload.relatedParty[0].name = val.name;
    //   this.payload.relatedParty[0].role = val.aristocraticTitle;
    //   this.payload.relatedParty[0].company = val.company;
    //   this.payload.relatedParty[0].createdBy = this.$cookies.get("company");
    // },
    // async addNewticket() {
    //   this.isModal = false;
    //   try {
    //     // eslint-disable-next-line vue/no-async-in-computed-properties
    //     // let relatedParty = [];
    //     // this.data.forEach((item) => {
    //     //   let val = item;
    //     //   val["company"] = this.company;
    //     //   relatedParty.push(val);
    //     // });
    //     // this.payload["relatedParty"] = relatedParty;
    //     if (this.payload.troubleTicketSpecification[0]["Priority level"]) {
    //       this.payload["priority"] =
    //         this.payload.troubleTicketSpecification[0]["Priority level"];
    //     } else {
    //       this.payload["priority"] = "High";
    //     }
    //     if (this.payload.troubleTicketSpecification[0]["Status"]) {
    //       this.payload["status"] =
    //         this.payload.troubleTicketSpecification[0]["Status"];
    //     } else {
    //       this.payload["status"] = "High";
    //     }
    //     // this.payload['priority'] = 'High'
    //     if (this.payload.troubleTicketSpecification[0]["Category"]) {
    //       this.payload["name"] =
    //         this.payload.troubleTicketSpecification[0]["Category"];
    //     } else {
    //       this.payload["name"] = "Ticket";
    //     }
    //     const response = await UPCAxiosInstance.post(
    //       `/troubleTicket`,
    //       this.payload
    //     );
    //     console.log(response.data, "data1");
    //     this.notification = {
    //       type: "success",
    //       message: `Nice work! Your new ticket is now in the list!`,
    //     };
    //   } catch (error) {
    //     console.error("Error fetching autocomplete data:", error.message);
    //   }
    //   this.tableDataCalling();
    // },
    async editDiscount() {},
    async deleteExecRow() {
      this.delete_modal_active = false;
      let i = this.deleteobj;
      const addedProductLocationLogPayload = {
        message: `${this.deleteobj.troubleTicketSpecification[0]["Ticket category"]} Ticket has been deleted`,
        detailedAction: [],
        action: "Delete",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Accounts-Ticket",
        category: ["accounts"],
      };
      this.initLog(addedProductLocationLogPayload);
      await UPCAxiosInstance.delete(`troubleTicket/${i._id}`);
      this.tableDataCalling();
    },
    crudContextMenu($event, index) {
      console.log($event, "crudContextMenu", index);
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    findDefaultValueForTicketCategory(payload) {
      try {
        // Check if payload is an array
        if (!Array.isArray(payload)) {
          throw new Error("Payload is not an array");
        }

        // Find the item with name "Ticket category"
        const ticketCategoryItem = payload.find(
          (item) => item.name === "Ticket category"
        );

        // Check if the item was found
        if (!ticketCategoryItem) {
          throw new Error('"Ticket category" item not found');
        }

        // Ensure characteristicSpecification is an array
        if (!Array.isArray(ticketCategoryItem.characteristicSpecification)) {
          throw new Error("characteristicSpecification is not an array");
        }

        // Find the characteristicSpecification with default: true
        const defaultSpecification =
          ticketCategoryItem.characteristicSpecification.find(
            (spec) => spec.default === true
          );

        // Check if defaultSpecification was found
        if (!defaultSpecification) {
          throw new Error("Default specification not found");
        }

        // Return the value of the default specification
        return defaultSpecification.value;
      } catch (error) {
        console.error(
          "Error finding default value for Ticket category:",
          error.message
        );
        return null; // Or handle as needed
      }
    },
    showNewContactModal() {
      this.payload = {
        name: "",
        status: "",
        "@type": "TroubleTicket",
        priority: "",
        troubleTicketSpecification: [],
      };

      this.paylaodpre = this.findDefaultValueForTicketCategory(this.labelFieldsValue);
      let found = false;
      this.templatePayload.some((item) => {
        if (found) return true;
        if (this.paylaodpre === item.category[0]?.name && item.lifecycleState === "Active") {
          this.labelFieldsValue = item.fields;
          this.labelFieldsValue.sort((a, b) => a.priority - b.priority);
          found = true;
        } else  {
          this.labelFieldsValue = this.copytemplatePayload.sort((a, b) => a.priority - b.priority);
        }
        return false;
      });

      try {
        let payload={}
        let obj = this.createTicketSpecification(this.labelFieldsValue, this.paylaodpre,payload);
        this.initializeTroubleTicketSpecification();
        this.payload.troubleTicketSpecification[0] = obj;
        console.log("troubleTicketSpecification", this.payload);
      } catch (error) {
        console.error("Error fetching autocomplete data:", error);
      }

      this.payload.troubleTicketSpecification[0]["Assigned to"] = this.instanceData.name;
      this.componentKeyM += 1;
      this.attachmnetComponetKey += 1;
      this.componentkey1R += 1;
      this.isModal = true;
      this.editMode = false;
      this.updateKey++;
    },
    async closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        this.isModal = true;
        this.editMode = true;
        this.componentKeyM+=1;
        this.templatePayload?.map((item) => {
          if (
            ele?.troubleTicketSpecification[0]["Ticket category"] ===
              item.category[0]?.name &&
            item.lifecycleState === "Active"
          ) {
            this.labelFieldsValue = item?.fields;
            console.log(this.labelFieldsValue, "this.labelFieldsValue");
          } else {
            this.labelFieldsValue = this.copytemplatePayload;
          }
        });
        this.payload = JSON.parse(
          JSON.stringify(this.copyOfTicketTableData[i])
        );
        this.initialPayload = JSON.parse(
          JSON.stringify(this.copyOfTicketTableData[i])
        );
        console.log("closeCrudContextMenu", this.payload);
        // this.editedTicket(this.payload._id,this.payload.troubleTicketSpecification)
        // this.$router.push(`/SM/supplier/edit`);
      } else if ($event.toLowerCase() == "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      }
    },
    // async editedTicket(id, payload) {
    //   console.log("Payload", payload);
    //   const patchTicket = await UPCAxiosInstance.patch("/troubleTicket", {
    //     id: id,
    //     troubleTicketSpecification: this.payload.troubleTicketSpecification[0],
    //   });
    //   console.log("patchTicket", patchTicket);
    //   console.log(id, "id", this.payload.troubleTicketSpecification[0]);
    // },
    computedTableAction(data) {
      if (data.status === "Open" || data.status === "In progress") {
        return [
          {
            label: "Edit",
            display: this.staticData?.edit?.label[this.loginUserDetails]?.label || "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            display: this.staticData?.delete?.label[this.loginUserDetails]?.label || "Delete",
            icon: "icon-trash-regular",
          },
        ];
      } else {
        return [
          {
            display: this.staticData?.delete?.label[this.loginUserDetails]?.label || "Delete",
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }
    },
    preValFunc(payload, spechfiled) {
      // console.log("----------------------------------",payload,"--------------------", spechfiled,"--------------------------------------------------------------------");
      if (payload != undefined && payload != "" && payload.length > 0) {
        console.log("payload log");
        payload;
      } else {
        spechfiled.characteristicSpecification.forEach((item) => {
          if (item.default == true) {
            console.log("defalt lofg--- log");
            payload = item.value;
          }
        });
      }
      // console.log(payload,"payloadpayload----------------------------");
      return payload;
    },
    preValFunction(payload, spechfiled) {
      console.log(
        payload,
        spechfiled,
        "........payload, spechfiled..........."
      );
      if (payload.length > 0) {
        console.log("payload log");
        payload;
      } else {
        let value = [];
        let count = 0;
        spechfiled.characteristicSpecification.forEach((item) => {
          if (item.default == true) {
            count = 1;
            value.push(item.value);
            // value= item.value
          }
        });
        if (count == 0) {
          value = [];
        }

        payload = value;
      }
      console.log(payload, "asdfghiop[jhgfxfghjkl;lkjhgfddfghjkl;lkjhgcxxghj]");
      return payload;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.toggleRearrangeModal = val;
      // this.updateTheads();
    },
    arrangeColumns(val) {
      this.toggleRearrangeModal = val;
      // this.updateTheads();
    },
    updateTheads() {
      this.tickets_table_theads_cc = this.tickets_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.tickets_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
   async applyColumns(val) {
// Func to rearrange table header
console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      if(this.module == "accounts"){
let concatedEntity = sampleTheads?.data?.entityRef || {};
concatedEntity.accountTicket = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh account", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.tickets_table_theads_cc = entityRef["accountTicket"]
this.tickets_table_theads = entityRef["accountTicket"].filter(ele=>ele.checked)
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.tickets_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tickets_table_theads))
      }
      else if(this.module == "contacts"){
        let concatedEntity = sampleTheads?.data?.entityRef || {};
concatedEntity.contactTicket = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh contact", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.tickets_table_theads_cc = entityRef["contactTicket"]
this.tickets_table_theads = entityRef["contactTicket"].filter(ele=>ele.checked)  
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.tickets_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tickets_table_theads))
      }
      else if(this.module !== "contacts" && this.module !== "accounts"){
        let concatedEntity = sampleTheads?.data?.entityRef || {};
concatedEntity.residentialCustomerTicket = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh contact", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.tickets_table_theads_cc = entityRef["residentialCustomerTicket"]
this.tickets_table_theads = entityRef["residentialCustomerTicket"].filter(ele=>ele.checked)  
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.tickets_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tickets_table_theads))
      }
    },
    // preValFunc(){
    //           let value = ''
    //           this.addFieldsObject.characteristicSpecification.forEach((item)=>{
    //                        if(item.default == true)
    //                        {
    //                         value= item.value
    //                        }
    //                   })

    //           return value
    //         },
    //         preValFunction(){
    //           let value = []
    //           let count = 0
    //           this.addFieldsObject.characteristicSpecification.forEach((item)=>{
    //                        if(item.default == true)
    //                        {
    //                         count = 1
    //                         value.push(item.value)
    //                         // value= item.value
    //                        }
    //                   })
    //                   if(count == 0){
    //                       value = []
    //                   }

    //           return value

    //         },
  },
};
</script>
<style lang="scss" scoped>
.header-add-supplier {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  .tablehead-add-supplier {
    font-weight: bold;
  }
  .search-add-supplier {
    display: flex;
    gap: 10px;
  }
}
.contacts-username-supplier {
  display: flex;
  align-items: center;
  .right-head-supplier {
    display: flex;
    flex-direction: column;
  }
  .head-supplier-left {
    display: flex;
    justify-content: center;
    width: 44px;
    align-items: center;
    font-size: 45px;
    margin-right: 10px;
  }
  .head-supplier-right {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    .head-supplier-name {
      margin-right: 10px;
    }
  }
  .head-supplier-address {
    font-size: 10px;
    color: #929191;
    margin-top: 3px;
  }
}
</style>
