<template>
    <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <div class="permissions-ui">
    <div class="radio-group">
      <div style="display: flex; gap: 6px; /* align-items: center; */">
        <hlx-input
          :checked="roleInfo?.private"
          ||
          false
          :type="'single-radio'"
          :name="'dmn'"
          :label-value="'Private'"
          @selected="selectedValue"
        />
        <p
          class="bg-default border"
          data-tooltip="Only the record owner, his subordinates and their superior(s) can access this role user’s record"
          data-tooltip-location="bottom"
        >
          <i class="icon-info-circle-regular"></i>
        </p>
      </div>
    </div>

    <div class="radio-group">
      <div style="display: flex; gap: 6px; /* align-items: center; */">
        <hlx-input
          :checked="roleInfo?.global"
          :type="'single-radio'"
          :name="'dmn'"
          :label-value="'Global'"
          @selected="selectedValue"
        />
        <p
          class="bg-default border"
          data-tooltip="Provides unrestricted access to all users with the necessary permissions."
          data-tooltip-location="bottom"
        >
          <i class="icon-info-circle-regular"></i>
        </p>
      </div>
      <div v-if="roleInfo?.global" class="nested-options">
        <div style="margin-top: 12px; margin-bottom: 18px">
          <hlx-input
            :checked="roleInfo?.view"
            ||
            false
            :type="'single-radio'"
            :name="'GlobalChild'"
            :label-value="'View'"
            @selected="selectedValueChild"
          />
        </div>
        <div style="margin-top: 12px; margin-bottom: 18px">
          <hlx-input
            :checked="roleInfo['view / edit']"
            :type="'single-radio'"
            :name="'GlobalChild'"
            :label-value="'View / Edit'"
            @selected="selectedValueChild"
          />
        </div>
        <div style="margin-top: 12px; margin-bottom: 18px">
          <hlx-input
            :checked="roleInfo?.['view / edit / delete']"
            :type="'single-radio'"
            :name="'GlobalChild'"
            :label-value="'View / Edit / Delete'"
            @selected="selectedValueChild"
          />
        </div>
      </div>
    </div>

    <div class="radio-group">
      <div style="display: flex; gap: 6px; /* align-items: center; */">
        <hlx-input
          :checked="roleInfo?.custom"
          :type="'single-radio'"
          :name="'dmn'"
          :label-value="'Custom'"
          @selected="selectedValue"
        />
        <p
          class="bg-default border"
          data-tooltip="Grants access based on specific visibility criteria and user roles."
          data-tooltip-location="bottom"
        >
          <i class="icon-info-circle-regular"></i>
        </p>
      </div>

      <div v-if="roleInfo?.custom == true" class="custom-permissions">
        <table class="permissions-table">
          <thead>
            <tr>
              <th class="table-header" style="width: 30%">
                Choose role to share data
              </th>
              <th class="table-header" style="width: 39%">Module</th>
              <th class="table-header" style="width: 30%">Set Permission</th>
              <th class="table-header" style="width: 1%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in customPermissions" :key="index">
              <td class="table-cell">
                <hlx-select
                  :key="rolecomponentkey"
                  v-module="row.role"
                  :pre-value="row.role"
                  :required="false"
                  :options="roleList"
                  prop-value="role"
                  :label="'role'"
                  :placeholder-value="''"
                  :label-animation="true"
                  :clearable="false"
                  @selected-value="
                    updateCustomPermission(index, 'role', $event.role)
                  "
                ></hlx-select>
              </td>
              <td class="table-cell">
                <hlx-select
                  v-module="row.module"
                  :pre-value="'Lead'"
                  :options="moduleList"
                  prop-value="name"
                  :label="'name'"
                  :placeholder-value="''"
                  :label-animation="false"
                  :required="false"
                  :clearable="false"
                  :disabled="true"
                  :error="show"
                  :custom-error="errorvalue"
                  @custom-error-emit-value="selecttheValueorNot"
                  @selected-value="
                    updateCustomPermission(index, 'module', $event.name)
                  "
                ></hlx-select>
              </td>
              <td class="table-cell">
                <hlx-select
                  v-module="row.permission"
                  :pre-value="row.permission"
                  :options="permissionList"
                  prop-value="value"
                  :label="'name'"
                  :placeholder-value="''"
                  :label-animation="false"
                  :required="false"
                  :clearable="false"
                  :disabled="false"
                  :error="show"
                  :custom-error="errorvalue"
                  @custom-error-emit-value="selecttheValueorNot"
                  @selected-value="
                    updateCustomPermission(index, 'permission', $event.value)
                  "
                ></hlx-select>
              </td>
              <td v-if="customPermissions.length > 1" class="table-cell">
                <i
                  class="icon-trash-regular"
                  @click="removeCustomPermission(index)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="add-new-roles" @click="addCustomPermission">+ Add</div>
      </div>
    </div>
  </div>
</template>

<script>
import { MLTAxiosInstance } from "@/config/axiosConfig";

export default {
  props: {
    roleName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      notification: {},
      moduleList: [
        {
          name: "Lead",
        },
      ],
      roleList: [],
      permissionList: [],
      selectedOption: "Private",
      roleInfo: {
        private: false,
        custom: false,
        global: false,
        "view / edit": false,
        "view / edit / delete": false,
        view: false,
      },
      globalPermission: "",
      customPermissions: [{ role: "", module: "Lead", permission: "" }],
    };
  },
  watch: {
    customPermissions: {
      handler(val) {
        console.log(val, "customPermissions");
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const getUserInfo = await MLTAxiosInstance.get(
      `/partyRole/${this.$route.query.id}`
    );
    this.roleInfo = await getUserInfo?.data?.accessZone;
    this.customPermissions = await getUserInfo.data?.accessZone?.condition;
    console.log(getUserInfo.data, "getUserInfo", this.roleInfo);

    let tableData = await MLTAxiosInstance.get(`/partyRole/userRoleCount`);
    console.log(tableData.data, "contract");
    this.roleList = await tableData.data;
    this.roleList = await this.capitalizeAndSort(this.roleList, "role");
    this.roleList = this.roleList.filter((item) => item.role !== this.roleName);
    this.permissionList = await this.getReferanceData("permissionList");
  },
  methods: {
    async updateCustomPermission(index, key, value) {
      if (this.customPermissions[index]) {
        this.customPermissions[index][key] = value;
        console.log(this.customPermissions, "updateCustomPermission");
      }
      const updateZone = await MLTAxiosInstance.patch("/partyRole", {
        id: this.$route.query.id,
        restrictionMode: false,
        accessZone: {
          custom: true,
          condition: this.customPermissions,
        },
      });
      updateZone;
      this.notification = {
          type: "success",
          message:
             "All set! Your changes have been saved successfully!",
        };
    },
    async selectedValueChild(val) {
      this.globalPermission = val.name;
      const obj = {
        global: true,
        [val.name.toLowerCase()]: true,
      };
      console.log(val, "child valie", obj);

      const updateZone = await MLTAxiosInstance.patch("/partyRole", {
        id: this.$route.query.id,
        restrictionMode: false,
        accessZone: obj,
      });
      this.notification = {
          type: "success",
          message:
            "All set! Your changes have been saved successfully!",
        };
      console.log(updateZone.data, "tested");
    },
    async selectedValue(val) {
      (this.roleInfo = {
        private: false,
        custom: false,
        global: false,
        "view / edit": false,
        "view / edit / delete": false,
        view: false,
      }),
        console.log(val, "selectedValue found", this.$route.query.id);

      if (val.name === "Private") {
        this.resetRoleInfo();
      } else if (val.name === "Global") {
        this.roleInfo.custom = false;
        this.roleInfo.private = false;
      } else if (val.name === "Custom") {
        this.resetRoleInfo();
      }

      if (val.name) {
        const obj = {
           [val.name.toLowerCase()]: val.checked ,
          "view":true
        };
        const updateZone = await MLTAxiosInstance.patch("/partyRole", {
          id: this.$route.query.id,
          restrictionMode: false,
          accessZone: obj,
        });
        this.notification = {
          type: "success",
          message:
             "All set! Your changes have been saved successfully!",
        };
        this.roleInfo = {
       
        view: true,
      }
        console.log(updateZone.data, "tested");
      }

      this.roleInfo[val.name.toLowerCase()] = true;
    },
    resetRoleInfo() {
      this.roleInfo.global = false;
      this.roleInfo.custom = false;
      this.customPermissions = [{ role: "", module: "Lead", permission: "" }];
      this.roleInfo["view / edit"] = false;
      this.roleInfo["view / edit / delete"] = false;
      this.roleInfo["view"] = false;
    },
    addCustomPermission() {
      this.customPermissions.push({ role: "", module: "Lead", permission: "" });
    },
    async removeCustomPermission(index) {
      this.customPermissions.splice(index, 1);
      const updateZone = await MLTAxiosInstance.patch("/partyRole", {
        id: this.$route.query.id,
        restrictionMode: false,
        accessZone: {
          custom: true,
          condition: this.customPermissions,
        },
      });
      this.notification = {
          type: "success",
          message:
            "All set! Your changes have been saved successfully!",
        };
      updateZone;
    },
  },
  addCustomPermission() {
    this.customPermissions.push({ role: "", module: "Lead", permission: "" });
  },
  removeCustomPermission(index) {
    this.customPermissions.splice(index, 1);
  },
};
</script>

<style lang="scss">
.permissions-ui {
  margin: 20px 54px;
  .radio-group {
    margin-bottom: 35px;
    label {
      margin-left: 10px;
    }
  }
  .info-icon {
    margin-left: 5px;
    color: gray;
    font-size: 12px;
    cursor: pointer;
  }
  .nested-options {
    margin-left: 25px;
    margin-top: 10px;
  }
  .custom-permissions {
    margin-top: 10px;
    .permissions-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;
      thead {
        border-radius: 10px;
      }
      .table-header {
        padding: 12px 21px;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        background-color: rgba(217, 217, 217, 0.4);
      }
      .table-cell {
        padding: 16px 21px;
        text-align: left;
      }
    }
    .add-new-roles {
      margin-top: 10px;
      cursor: pointer;
      color: rgba(84, 189, 149, 1);
      border: none;
      padding: 5px 10px;
      border-radius: 3px;
      display: flex;
      justify-content: flex-end;
      padding-right: 22px;
    }
  }
}
</style>
