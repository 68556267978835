<template>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <div class="add-feature-drawer">
    <hlx-drawer
      :show="openTaskModel"
      position="right"
      width="400"
      :footer="true"
      :show-close-icon="true"
      @close="openTaskModel = false"
    >
      <template #body>
        <div style="height: 90vh !important">
          <div
            style="
              border-bottom: 1px solid #d4d4d4;
              padding-bottom: 5px;
              padding: 13px;
            "
          >
            <b v-if="editMode == false">New Execution</b>
            <b v-if="editMode == true">Edit Execution</b>
          </div>
          <FieldRenderer
        :schema-payload="executionSchema"
        :parent-class-name="'parent-container-for-lead'"
        :layout="'1-column'"
        :data-payload="field3Data"
        @edited-payload="storeDataFromField3"
      ></FieldRenderer>
          <div
            :key="F"
            style="
              display: flex;
              padding: 18px 13px;
              flex-direction: column;
              gap: 18px;
            "
          >
           </div>
        </div>
      </template>
      <template #footer>
        <div
          class="shipping-apply-button"
          style="
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            padding-top: 20px;
            padding-right: 10px;
            border-top: 1px solid #d8d8d8;
          "
        >
        <hlx-button class="secondary sm" @click="openTaskModel=false">
          <!-- Cancel -->
          {{ loadStaticData("LANG-0020") }}
        </hlx-button>
        <hlx-button
          v-if="openTaskModel"
          class="primary sm"
          style="margin-left: 10px"
          @click="addData"
        >
          <!-- Add -->
          {{ loadStaticData("LANG-0010") }}
        </hlx-button>
        <hlx-button
          v-if="openTaskModels"
          class="primary sm"
          style="margin-left: 10px"
          @click="saveEditedContact"
        >
          <!-- Save -->
          {{ loadStaticData("LANG-0030") }}
        </hlx-button>
        </div>
      </template>
    </hlx-drawer>
  </div>
  <div v-if="contractData?.length >= 1" class="header-add-supplier">
    <div class="tablehead-add-supplier">{{ tablehead }}</div>
    <div class="search-add-supplier">
      <span
        ><hlx-search
          v-model="searchvalue"
          @search-key="productSearch"
        ></hlx-search
      ></span>
      <hlx-button class="primary sm" @click="showTaskModal">
        <i class="icon-plus-circle-regular"></i> Add task</hlx-button
      >
    </div>
  </div>
  <section
    v-for="(i, index) in example_data2.length"
    :key="index"
    style="margin-top: 35px"
  >
    <section
      class="lead-dropdowm-site-cover"
      style="cursor: pointer; align-items: center; margin-bottom: 8px"
      @click="closeSiteDetails(index, i)"
    >
      <div class="lead-dropdowm-site-num" style="display: contents">
        <div
          class="equipment-order-site-accordion"
          style="width: 100%; font-family: OpenSans; line-height: 1.8"
        >
          <div class="left">
            <div class="details">
              <div class="name">
                <span style="font-weight: bold">{{ "Site name : " }}</span>
                <span style="padding-right: 10px;font-weight: 100;">{{
                  " " + example_data2[index].chooseSite
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="chosenSiteIndex === index" class="lead-dropdowm-site-icon">
          <i style="color: #a6a6a6" class="icon-angle-up-filled"></i>
        </div>
        <div v-if="chosenSiteIndex !== index" class="lead-dropdowm-site-icon">
          <i style="color: #a6a6a6" class="icon-angle-down-filled"></i>
        </div>

        <!-- <div v-if="chosenSiteIndex === index">
                {{temporaryleadSiteDetails[chosenSiteIndex]?.siteName?temporaryleadSiteDetails[chosenSiteIndex]?.siteName:'Site -' + (chosenSiteIndex + 1) +' details'}}
              </div> -->
        <!-- <div class="lead-dropdowm-site-icon">
                <i style="color: #A6A6A6;"  class="icon-angle-down-filled"></i>
              </div> -->
      </div>
      <div class="delete-icon" @click.stop="deleteSite(index)">
            <i class="icon-trash-regular" style="margin-left:10px;color: red"></i>
          </div>
    </section>
    
    <div
      v-if="chosenSiteIndex === index"
      class="parent-container-for-lead-maintable-site"
      style="margin-bottom: 20px"
    >
      <!-- :address-data-props="siteAddressArray" -->
      <hlx-table
        :column-count="execution_table_theads.length + 2"
        :border="['table', 'header', 'horizontal']"
        :row-hover="true"
        theme="grey"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="execution_table_theads_cc"
        @close-rearrange="re_arrange = false"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
            @new-resize-width="resizedWidth"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(ii, index_index) in execution_table_theads"
            :key="index_index"
            :prop="ii.prop"
            :sortable="ii.sortable"
            :resizable="ii.resizable"
            :width="ii.width !== undefined ? ii.width : ''"
            :align="ii.align !== undefined ? ii.align : ''"
            @sorting_func="sorting_Data"
            @new-resize-width="resizedWidth"
          >
            {{ ii.label }}</hlx-table-head
          >
          <hlx-table-head
            :align="'center'"
            :width="70"
            :fixed="'right'"
            @new-resize-width="resizedWidth"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr
            v-for="(iii, data_index) in tableCalculation(index)"
            id=""
            :key="data_index"
          >
            <hlx-table-cell :align="'center'" :fixed="'left'">
              {{ data_index + 1 }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in execution_table_theads"
              :key="col_index"
              :align="j.align"
              :fixed="j.fixed"
            >
              <div v-if="j.prop === 'chooseAssignee'">
                <span style="display: flex; gap: 6px">
                  <span>{{ iii[j.prop] }}</span>
                  <span
                    ><hlx-label class="primary sm">{{
                      iii.role
                    }}</hlx-label></span
                  >
                </span>
              </div>
              <div v-else-if="j.prop === 'status'">
                <div>
                  <hlx-status :type="iii[j.prop]"></hlx-status>
                </div>
              </div>
              <div
                v-else-if="j.prop === 'lastUpdated'"
                style="margin: 0.5rem 0"
              >
                <span v-if="iii[j.prop]">
                  {{ dateAndTimeFormatter(iii[j.prop]) }}</span
                >
                <span v-else> - </span>
              </div>
              <div
                v-else-if="j.prop === 'createdDate'"
                style="margin: 0.5rem 0"
              >
                <span v-if="iii[j.prop]">
                  {{ dateAndTimeFormatter(iii[j.prop]) }}</span
                >
                <span v-else> - </span>
              </div>
              <div v-else-if="j.prop == 'taskName'" style="margin: 0.5rem 0">
                {{ iii[j.prop] }}
              </div>
              <!-- {{ dateAndTimeFormatter(i[j.prop]) }} -->

              <div v-else>{{ iii[j.prop] }}</div>
            </hlx-table-cell>
            <hlx-table-cell :align="'center'" :width="120" :fixed="'right'">
              <span :id="'table-context' + index" class="action-icon">
                
                <i
                  style="position: relative"
                  class="icon-more-vertical-filled"
                  :class="i.context === true ? 'active-action' : ''"
                  @click="crudContextMenu($event, iii, index)"
                >
                  <hlx-context-menu
                    :display="'display'"
                    :top="containertop"
                    :left="containerleft"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(iii)"
                    :data="index.toString()"
                    :show="iii.context == true"
                    @chosen="closeCrudContextMenu($event, iii, index)"
                  />
                </i>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>
  </section>
 
</template>
<script>
import {
  UPCAxiosInstance,
  DMNAxiosInstance,
  MLTAxiosInstance,
} from "@/config/axiosConfig";
import FieldRenderer from "@/components/FieldRenderer.vue";

export default {
  components: {
    FieldRenderer,
  },
  props: {
    permisstionData: {
      type: Object,
      required: true,
      default: () => {},
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: "edit",
    },
    supplierStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ogSchema:null,
      previousTaskCategory:null,
      changedExecutionSchema:null,
      showSiteInformationModal: false,
      chosenSiteIndex: null,
      field3Data:{
        executionsite:null,
        exestatus: null,
        taskcategory:null,
        assignee:null,
        viptv:null,
        vipinternet:null,
        viptvused:null,
        vipinternetused:null,
        omnumber:null,
        installationdate:null,
        suiterun:null,
        completeddate:null,
        requestedbilling:null,
        billstartdate:null,
        firstoccupany:null,
        taskname:null,
        createdAt:Date.now(),
        lastUpdated:Date.now()
      },
      execution_table_theads: [
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "Task name",
          prop: "taskName",
          align: "left",
          name: "Task name",
          checked: true,
          disabled: true,
        },
        {
          sortable: false,
          resizable: true,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
          name: "Status",
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "Assignee",
          prop: "assignee",
          align: "left",
          name: "Assignee",
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 170,
          label: "Created date",
          prop: "createdDate",
          align: "left",
          name: "Created date",
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 170,
          label: "Last updated",
          prop: "lastUpdated",
          align: "left",
          name: "Last updated",
          checked: true,
          disabled: false,
        },
      ],
      example_data2: [],
      selectedValue: "execution",
      schema: {},
      openTaskModel: false,
      tableHeaderOrg: [],
      tableHeaderOrgcc: [],
      searchvalue: "",
      deleteobj: {},
      isModal: false,
      payload: {
        name: "",
        type: "",
        attachment: [
          {
            name: "",
          },
        ],
        documentNumber: "",
        status: "Draft",
        characteristic: [],
        validFor: {
          startDateTime: new Date(),
          endDateTime: "",
          autoRenewal: false,
        },
      },
      contracttype: [
        { name: "Service Agreement" },
        { name: "Purchase Agreement" },
        { name: " Non-Disclosure Agreement (NDA)" },
        { name: "Partnership Agreement" },
      ],
      editMode: false,
      statusList: [],
      tablehead: "Execution",
      externalRowsPerPage: 10,
      tableData: [],
      notification: [],
      accounts_table_theads: [
        {
          sortable: false,
          resizable: true,
          width: 250,
          label: "Task name",
          prop: "taskName",
          align: "left",
          name: "Task name",
          checked: true,
          disabled: true,
        },
        {
          sortable: false,
          resizable: true,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
          name: "Status",
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "Assignee",
          prop: "Assignee",
          align: "left",
          name: "Assignee",
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          label: "Created date",
          prop: "createdDate",
          align: "left",
          name: "Created date",
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "Last updated",
          prop: "lastUpdated",
          align: "left",
          name: "Last updated",
          checked: true,
          disabled: false,
        },
      ],
      accounts_table_theads_cc: [
        {
          sortable: false,
          resizable: true,
          width: 250,
          label: "Task name",
          prop: "taskName",
          align: "left",
          name: "Task name",
          checked: true,
          disabled: true,
        },
        {
          sortable: false,
          resizable: true,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
          name: "Status",
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          label: "Assignee",
          prop: "Assignee",
          align: "left",
          name: "Assignee",
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "Created date",
          prop: "createdDate",
          align: "left",
          name: "Created date",
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "Last updated",
          prop: "lastUpdated",
          align: "left",
          name: "Last updated",
          checked: true,
          disabled: false,
        },
      ],
      keyagreementRef: 0,
      name: "",
      copyOfcontractTableData: [],
      duplicateTheads: [],
      re_arrange: false,
      currPage: 1,
      rowsPerPage: 10,
      contractData: [],
      filterPanelName: "productSpecification",
      modal_active: false,
      delete_modal_active: false,
      initialPayload: {},
    };
  },
  computed: {
    executionSchema() {
      console.log("this.billingInfo", this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        filtered = Object.entries(this.schema.executionInfo.schema).filter(
          ([, value]) => value.section && value.section.includes("execution")
        );

        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, "fieldSchema", fieldSchema);

        const entries = Object.entries(fieldSchema);

        // entries.sort((a, b) => {
        //   const orderA = a[1].others?.displayOrder ?? Infinity;
        //   const orderB = b[1].others?.displayOrder ?? Infinity;
        //   return orderA - orderB;
        // });

        const sortedData = Object.fromEntries(entries);

        // Object.keys(sortedData).forEach((key) => {
        //   if (sortedData[key]?.others) {
        //     sortedData[key].others.readOnly = true;
        //   }
        // });

        if (sortedData["preferredLanguage"]) {
          sortedData["preferredLanguage"].options = this.asyncLanguageOptions;
        }
        if (sortedData["numberOfSites"]) {
          sortedData["numberOfSites"].others.readOnly = true;
        }
        console.log(this.view, "sortedData", sortedData);
        return sortedData;
      } else if (this.isDrawerSchema) {
        console.log("this.isDrawerSchema", this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
  },
  watch: {
    // data: {
    //   handler(val) {
    //     this.payload = val;
    //     console.log(val, "product value");
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    payload: {
      handler(val) {
        console.log(val, "product value");
        // val.attachment[0]={name:`${val.name}.pdf`}
      },
      immediate: true,
      deep: true,
    },
    tableData: {
      handler(val) {
        this.copyOfcontractTableData = val;
        console.log(val, "product value");
        // val.attachment[0]={name:`${val.name}.pdf`}
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {

    if (
      this.$route.params.id != "id" &&
      this.$route.params.section != "create"
    ) {
      const getAgreement = await UPCAxiosInstance.get(
        `/task/contractTask/${this.$route.params.id}`
      );

      this.example_data2 =  await JSON.parse(JSON.stringify(getAgreement.data));
      this.field3Data = await JSON.parse(JSON.stringify(getAgreement.data));
      console.log(this.field3Data, "executionData");
    }
    let data = await UPCAxiosInstance.get(
      `customSchema/getSchema?module=agreement`
    );
    this.schema = await data.data.schema;
    this.ogSchema = JSON.parse(JSON.stringify(this.schema));
    console.log("this.billingInfo mounted", this.schema);

    let sampleTheads = await MLTAxiosInstance.get(`/settings`);

    if (sampleTheads?.data?.entityRef?.["supplierContractTable"]) {
      let offerTableHeadModal =
        sampleTheads.data.entityRef["supplierContractTable"];
      let offerTableHeadOrg = sampleTheads.data.entityRef[
        "supplierContractTable"
      ].filter((ele) => ele.checked);
      console.log(
        offerTableHeadOrg,
        "offerTableHeadModal",
        offerTableHeadModal
      );

      if (
        offerTableHeadModal.length === this.accounts_table_theads_cc.length
        // &&
        // offerTableHeadModal.every((item, index) => item.name ===  this.accounts_table_theads_cc[index].name)
      ) {
        this.accounts_table_theads_cc = JSON.parse(
          JSON.stringify(offerTableHeadModal)
        );
        this.accounts_table_theads = JSON.parse(
          JSON.stringify(offerTableHeadOrg)
        );
        this.tableHeaderOrgcc = JSON.parse(
          JSON.stringify(this.accounts_table_theads_cc)
        );
        this.tableHeaderOrg = JSON.parse(
          JSON.stringify(this.accounts_table_theads)
        );
      }
    }
    this.refeshContract();
    let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList = statusList.data.data;
    // let contracttype = await DMNAxiosInstance.get(`mapRef/collection/contratType`);
    // this.contracttype = contracttype.data.data;
    // console.log(this.contracttype,"this.contracttype");
  },

  methods: {
    computedTableAction() {
      return [
        {
          display: this.matchFromStatic("Edit"),
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },

        {
          display: this.matchFromStatic("Delete"),
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
    },
    crudContextMenu($event, i, index) {
      console.log($event, i, index,"$event, i, index", this.example_data2[this.chosenSiteIndex].taskData[index])
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 121;

        this.containertop =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().top + 18;
        this.example_data2[this.chosenSiteIndex].taskData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.example_data2[this.chosenSiteIndex].taskData[index].context = true;
      } else {
        this.example_data2[this.chosenSiteIndex]?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    closeCrudContextMenu($event, payload, i) {
      payload, i;
      if ($event.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        this.delete_name = payload.name;
        this.deleteobj = payload;
      }
      else payload, i;
      // if ($event.toLowerCase() === "edit") {
      // }
    },
     addData( ) {
  const siteIndex = this.example_data2.findIndex(item => item.chooseSite == this.field3Data.executionsite);

  if (siteIndex !== -1) {
    this.example_data2[this.chosenSiteIndex].taskData.push(this.field3Data);
  } else {
    this.example_data2.push({
      chooseSite: this.field3Data.executionsite,
      taskData: [this.field3Data],
    });
  }
  this.openTaskModel = false;
  this.notification = {
    type: "success",
    message: "Task added successfully",
  };
  console.log(this.example_data2, "rendering");
},
   async storeDataFromField3(data) {
      console.log(data,"rendering ");
      this.field3Data = data;
if(this.field3Data.taskcategory != this.previousTaskCategory) {
  let fetchFields = await UPCAxiosInstance.post(`/util/filterData`,{
      "type": "filter",
      "module": "execution",
      "isPaginated": true,
      "paginatedQuery": {
          "rowsPerPage": 10,
          "currentPage": 1
      },
      "isNeedToBeSort": true,
      "sortFieldAndOrder": {},
      "limit": 1,
      "filterQuery": {
        category: this.field3Data.taskcategory,
        status:"Active"
      }
  });
  if(fetchFields.data.data.length > 0) {
  let arr = fetchFields.data.data[0]
  this.schema.executionInfo.schema =  {...this.ogSchema?.executionInfo?.schema,...arr.fields}
  console.log(this.schema.executionInfo.schema,"fetchFields",data.taskcategory,arr,arr.fields);
  }
  else{
    let data = await UPCAxiosInstance.get(
      `customSchema/getSchema?module=agreement`
    );
    this.schema = await data.data.schema;
  }
}
this.previousTaskCategory = this.field3Data.taskcategory
    },
    async openSiteDetails(i, index) {
      console.log(index, "openSiteDetails", i);
      this.chosenSiteIndex = i - 1;
      this.showSiteInformationModal = true;
      this.siteSchema;
      this.siteDetailsEmpty;

      // await this.checkAddressValidation(this.currentSiteObject?.siteAddress);
      // await this.getSiteInputFocusOut(
      //   this.currentSiteObject?.sfdcId ? this.currentSiteObject?.sfdcId : '',
      //   { fieldName: 'SFdCID' }
      // );
      // await  this.getSiteInputFocusOut(this.currentSiteObject?.networkId ? this.currentSiteObject?.networkId : '' , {'fieldName':'networkId'})
      // this.characteristicpreval.characteristic = this.siteDetails[this.chosenSiteIndex].contactMedium[0].characteristic
      // console.log(this.characteristicpreval.characteristic,"Site Details",index,"leadPayload?.relatedParty[chosenSiteIndex]?.name")
      // console.log( this.siteDetails,"chosenSiteIndex",this.leadPayload?.relatedParty)
      // this.siteDetailsEmpty = JSON.parse(JSON.stringify(this.temporaryleadSiteDetails))
    },
    closeSiteDetails(index, i) {
      console.log(i);
      // this.siteDetailsEmpty = JSON.parse(
      //   JSON.stringify(this.temporaryleadSiteDetails)
      // );
      this.isSaveButtonClicked = true;
      // setTimeout(() => {
      // if (this.showSiteInformationModal) {
      console.log(
        this.chosenSiteIndex,
        "Forcing showSiteInformationModal to false",
        index
      );
      //     }
      // }, 50);
      if (this.chosenSiteIndex != index) {
        this.openSiteDetails(i, index);
      } else if (this.chosenSiteIndex == index) {
        this.chosenSiteIndex = null;
        // this.showSiteInformationModal = false;
        // this.showAllSitesModal = true;
      }
    },
    tableCalculation(index) {
      // let arr = [];
      let value = JSON.parse(JSON.stringify(this.example_data2));
      // arr.push(value[index]?.tableData);
      return value[index]?.taskData;
    },
    showTaskModal() {
      this.openTaskModel = !this.openTaskModel;
    },
    resizedWidth(val) {
      // To get resized width
      console.log(val, "resizedWidth", val);

      const updateWidth = (array) => {
        let item = array.find((i) => i?.prop === val?.prop);
        if (item) item.width = val.width;
      };

      updateWidth(this.accounts_table_theads);
      updateWidth(this.accounts_table_theads_cc);
      console.log(
        this.accounts_table_theads,
        "resizedWidth loop",
        this.accounts_table_theads_cc
      );
      this.applyColumns(this.accounts_table_theads_cc);
    },
    reAssignOrgTableHeader() {
      // If there is change in the table header then ressign to the Orginal data
      if (
        this.accounts_table_theads_cc != this.tableHeaderOrgcc &&
        this.tableHeaderOrgcc.length > 0
      ) {
        this.accounts_table_theads_cc = JSON.parse(
          JSON.stringify(this.tableHeaderOrgcc)
        );
      }
      if (
        this.accounts_table_theads != this.tableHeaderOrg &&
        this.tableHeaderOrg.length > 0
      ) {
        this.accounts_table_theads = JSON.parse(
          JSON.stringify(this.tableHeaderOrg)
        );
      }
      this.re_arrange = true;
    },
    addCancel() {
      this.modal_active = false;
    },
    addNewAccount() {
      //
      this.modal_active = true;
    },
    productSearch(val) {
      this.searchvalue = val;
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.contractData,
          val.toLowerCase()
        );
        this.tableData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.contractData));
      }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    async refeshContract() {
      this.tableData = [
        {
          siteDetails: "Prestige Elegance",
          status: "Active",
          approvar: "John doe",
          role: "Admin",
          address: "295 A lester street, waterloo, Ontario, Canada",
          comments: "",
          lastUpdated: "Nov 11, 2024 10:20 AM",
        },
        {
          siteDetails: "Prestige Skyline",
          status: "Inactive",
          role: "manager",
          approvar: "Emily devis",
          address: "295 A lester street, waterloo, Ontario, Canada",
          comments: "",
          lastUpdated: "Nov 11, 2024 10:20 AM",
        },
      ];
      this.contractData = [
        {
          siteDetails: "Prestige Elegance",
          status: "Active",
          approvar: "John doe",
          role: "Admin",
          address: "295 A lester street, waterloo, Ontario, Canada",
          comments: "",
          lastUpdated: "Nov 11, 2024 10:20 AM",
        },
        {
          siteDetails: "Prestige Skyline",
          status: "Inactive",
          role: "manager",
          approvar: "Emily devis",
          address: "295 A lester street, waterloo, Ontario, Canada",
          comments: "",
          lastUpdated: "Nov 11, 2024 10:20 AM",
        },
      ];
      // console.log(tableData.data.length, "length");
    },

    closeDiscount() {
      this.keyagreementRef += 1;
      this.isModal = false;
      this.delete_modal_active = false;
      this.payload = {
        name: "",
        type: "",
        attachment: [
          {
            name: "",
          },
        ],
        documentNumber: "",
        status: "Defalt",
        characteristic: [],
        validFor: {
          startDateTime: new Date(),
          endDateTime: "",
          autoRenewal: false,
        },
      };
    },
    async changeRowsPerPage(count) {
      if (this.company != "") {
        this.rowsPerPage = count;
      }
    },
    async currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
      }
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    contracttyprfunc(val) {
      this.payload.type = val.name;
    },
    emitStatus(val) {
      this.payload.status = val.name;
      console.log("Discount status", val);
    },
    async addNewcontract() {
      const attachmentName = this.payload.name.replace(/\s+/g, "_");
      this.payload.attachment[0] = { name: `${attachmentName}.pdf` };
      const addedProductLocationLogPayload = {
        message: `${this.payload.name} Contract has been added`,
        detailedAction: [],
        action: "ADDED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "supplier-Contract",
        category: ["supplier"],
      };
      this.initLog(addedProductLocationLogPayload);
      const addproduct = await UPCAxiosInstance.post(
        `/agreementManagement?company=${this.$route.params.companyName}`,
        this.payload
      );
      console.log(addproduct.data);
      if (addproduct.data) {
        this.notification = {
          type: "success",
          message: `Your item is added successfully!.`,
        };
      }
      this.refeshContract();
      this.closeDiscount();
    },
    async editDiscount() {
      let CM_LocationLog = {
        message: `${this.$route.params.companyName} Contract was updated`,
        detailedAction: [],
        "@type": "supplier-Contract",
        category: ["supplier"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };

      let nameActionLog = this.buildBasicLogByDifferences(
        this.initialPayload,
        this.payload,
        "Name",
        "Name"
      );
      console.log(nameActionLog, CM_LocationLog, "nameActionLog");

      let documentNumberActionLog = this.buildBasicLogByDifferences(
        this.initialPayload,
        this.payload,
        "documentNumber",
        "Contract number"
      );
      console.log(
        documentNumberActionLog,
        CM_LocationLog,
        "documentNumberActionLog"
      );

      let statusActionLog = this.buildBasicLogByDifferences(
        this.initialPayload,
        this.payload,
        "status",
        "status"
      );
      console.log(statusActionLog, CM_LocationLog, "statusActionLog");

      let typeActionLog = this.buildBasicLogByDifferences(
        this.initialPayload,
        this.payload,
        "type",
        "Contract type"
      );
      console.log(typeActionLog, "typeActionLogActionLog");

      let validForSActionLog = this.buildBasicLogByDifferences(
        this.initialPayload.validFor,
        this.payload.validFor,
        "startDateTime",
        "startDate"
      );
      console.log(validForSActionLog, "validForSActionLog");

      let validForESActionLog = this.buildBasicLogByDifferences(
        this.initialPayload.validFor,
        this.payload.validFor,
        "endDateTime",
        "endDate"
      );
      console.log(validForESActionLog, "validForESActionLog");

      let validForaActionLog = this.buildBasicLogByDifferences(
        this.initialPayload.validFor,
        this.payload.validFor,
        "autoRenewal",
        "autoRenewal"
      );
      console.log(validForaActionLog, "validForaActionLog");

      CM_LocationLog.detailedAction = CM_LocationLog.detailedAction.concat(
        validForaActionLog,
        validForESActionLog,
        validForSActionLog,
        typeActionLog,
        statusActionLog,
        documentNumberActionLog,
        nameActionLog
      );
      this.payload.id = this.payload._id;
      delete this.payload._id;
      const editDiscount = await UPCAxiosInstance.patch(
        `/agreementManagement?company=${this.$route.params.companyName}`,
        this.payload
      );
      console.log(editDiscount.data);
      if (editDiscount.data) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been updated.`,
        };
        this.initLog(CM_LocationLog);
      }
      this.refeshContract();
      this.closeDiscount();
    },
    async deleteExecRow() {
      const addedProductLocationLogPayload = {
        message: `${this.deleteobj.name} Contact has been deleted`,
        detailedAction: [],
        action: "Delete",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "supplier-Contract",
        category: ["supplier"],
      };
      this.initLog(addedProductLocationLogPayload);
      const addproduct = await UPCAxiosInstance.delete(
        `/agreementManagement/${this.deleteobj._id}?company=${this.$route.params.companyName}`,
        this.payload
      );
      console.log(addproduct.data);
      if (addproduct.data) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been removed..`,
        };
      }
      this.refeshContract();
      this.closeDiscount();
    },
    showNewContactModal() {
      this.isModal = true;
    },


    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    arrangeColumns(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
    async applyColumns(val) {
      // To get rearranged header
      console.log("applyColumns", val);
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      let concatedEntity = sampleTheads?.data?.entityRef || {};

      concatedEntity.supplierContractTable = val;

      let reArrangeTableHeadPayload = { entityRef: concatedEntity };

      const changeTableHeader = await MLTAxiosInstance.patch(
        `/settings`,
        reArrangeTableHeadPayload
      );
      console.log(
        changeTableHeader,
        "reArrangeTableHeadPayload",
        reArrangeTableHeadPayload
      );

      let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
      console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

      let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
      this.accounts_table_theads_cc = entityRef["supplierContractTable"];
      this.accounts_table_theads = this.accounts_table_theads_cc.filter(
        (ele) => ele.checked
      );
      this.tableHeaderOrgcc = JSON.parse(
        JSON.stringify(this.accounts_table_theads_cc)
      );
      this.tableHeaderOrg = JSON.parse(
        JSON.stringify(this.accounts_table_theads)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.header-add-supplier {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  .tablehead-add-supplier {
    font-weight: bold;
  }
  .search-add-supplier {
    display: flex;
    gap: 10px;
  }
}
.contacts-username-supplier {
  display: flex;
  align-items: center;
  .right-head-supplier {
    display: flex;
    flex-direction: column;
  }
  .head-supplier-left {
    display: flex;
    justify-content: center;
    width: 44px;
    align-items: center;
    font-size: 45px;
    margin-right: 10px;
  }
  .head-supplier-right {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    .head-supplier-name {
      margin-right: 10px;
    }
  }
  .head-supplier-address {
    font-size: 10px;
    color: #929191;
    margin-top: 3px;
  }
}
</style>
