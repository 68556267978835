<template>
  <!-- {{ editData?.data }} -->
  <hlx-modal
    :modal-active="treeModal"
    :height="'700px'"
    :width="'900px'"
    :header-content="true"
    @close="treeModal = false"
  >
    <template #header>
     <b>Order hierarchy</b>
    </template>
    <template #content>
      <div
        v-if="editData"
        class="modal-content2"
        style="height:600px"
      >
        <hlx-tree-v2
          :nodes="editData"
          :direction="tree_direction"
          :zoomable="true"
          :type="'orderTree'"
          :read-only="false"
          :is-draggable="true"
        />
      </div>
    </template>
  </hlx-modal>
  <hlx-modal
    :modal-active="delete_modal_active2"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active2 = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete site</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="delete_modal_active2 = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow2"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-modal
    :modal-active="delete_modal_active1"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="delete_modal_active1 = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow1"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <!-- Installataion Drawer -->
  <div class="shipping-drawer-container1">
    <hlx-drawer
      :show="show_right1"
      :width="'350'"
      position="right"
      :footer="true"
      :header="false"
      :show-close-icon="true"
      @close="closeRight"
    >
      <template #body>
        <div
          class="shipment-drawer"
          style="display: flex; flex-direction: column"
        >
          <div
            v-if="edit == false"
            class="header"
            style="
              padding: 20px;
              border-bottom: 1px solid #d8d8d8;
              font-weight: bold;
            "
          >
            {{ 'Add task' }}
          </div>
          <div
            v-else
            class="header"
            style="
              padding: 20px;
              border-bottom: 1px solid #d8d8d8;
              font-weight: bold;
            "
          >
            {{ 'Edit task' }}
          </div>
          <div
            class="shipping-field-render"
            style="padding: 20px"
          >
            <FieldRenderer
              :key="componentKey"
              :schema-payload="installationSchema"
              :data-payload="installation_payload"
              :parent-class-name="'parent-container-for-lead-overview'"
              :layout="'1-column'"
              @edited-payload="editedPayloadInstallation"
            ></FieldRenderer>
          </div>
        </div>
      </template>
      <template #footer>
        <div
          class="shipping-apply-button"
          style="
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            padding-top: 20px;
            padding-right: 10px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button
            class="secondary-success sm"
            @click="emitPayload"
          >
            <!-- Apply -->
            <span> Cancel </span>
          </hlx-button>
          <hlx-button
            v-if="edit == false"
            class="primary sm"
            @click="emitinstallationPayload"
          >
            <!-- Apply -->
            <span> Add </span>
          </hlx-button>
          <hlx-button
            v-if="edit == true"
            class="primary sm"
            @click="editinstallationPayload"
          >
            <!-- Apply -->
            <span> Save </span>
          </hlx-button>
        </div>
      </template>
    </hlx-drawer>
  </div>
  <!--- Shipment Drawer -->
  <div class="shipping-drawer-container">
    <hlx-drawer
      :show="show_right2"
      :width="'350'"
      position="right"
      :footer="true"
      :header="false"
      :show-close-icon="true"
      @close="closeRight1"
    >
      <template #body>
        <div
          class="shipment-drawer"
          style="display: flex; flex-direction: column"
        >
          <div
            v-if="edit == false"
            class="header"
            style="
              padding: 20px;
              border-bottom: 1px solid #d8d8d8;
              font-weight: bold;
            "
          >
            {{ 'Add Shipment' }}
          </div>
          <div
            v-else
            class="header"
            style="
              padding: 20px;
              border-bottom: 1px solid #d8d8d8;
              font-weight: bold;
            "
          >
            {{ 'Edit Shipment' }}
          </div>
          <div
            class="shipping-field-render"
            style="padding: 20px"
          >
            <FieldRenderer
              :key="componentKey"
              :schema-payload="shipmentSchema"
              :data-payload="shipmentPayload"
              :parent-class-name="'parent-container-for-lead-overview'"
              :layout="'1-column'"
              @edited-payload="shipmentMethod"
            ></FieldRenderer>
          </div>
        </div>
      </template>
      <template #footer>
        <div
          class="shipping-apply-button"
          style="
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            padding-top: 20px;
            padding-right: 10px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button
            class="secondary-success sm"
            @click="show_right2 = false"
          >
            <!-- Apply -->
            <span> Cancel </span>
          </hlx-button>
          <hlx-button
            v-if="edit == false"
            class="primary sm"
            @click="emitShipmentPayload"
          >
            <!-- Apply -->
            <span> Add </span>
          </hlx-button>
          <hlx-button
            v-if="edit == true"
            class="primary sm"
            @click="editShipmentPayload"
          >
            <!-- Apply -->
            <span> Save </span>
          </hlx-button>
        </div>
      </template>
    </hlx-drawer>
  </div>
  <section
    v-if="show_offering"
    class="product-offering-container"
    style="
      width: 100%;
      height: 100%;
      display: flex;
      padding: 20px;
      /* gap: 15px; */
      overflow: inherit;
      flex-direction: column;
    "
  >
    <div
      class="new-product-listing-container-toolbox"
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
      "
    >
      <div>
        <div
          class="data-view-nav-container"
          style="
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            cursor: pointer;
          "
          @click="clickBack"
        >
          <i class="icon-arrow-left-regular"></i>
          <span>Back</span>
        </div>
      </div>
      <div>
        <div
          class="data-view-search-container"
          style="display: flex; align-items: center; gap: 8px"
        >
          <span class="filter-table filter-icon-listing-page">
            <hlx-button
              class="secondary sm add-btn"
              @click="showDrawerRight"
            >
              <i class="icon-filter-regular"></i>
              <span
                v-if="filtertaglength != 0"
                class="filter-count"
                >{{ filtertaglength }}</span
              >
            </hlx-button>
          </span>
          <hlx-search
            v-model="searchvalue"
            @search-key="searchOffers"
          ></hlx-search>
          <hlx-badge
            :icon="'icon-shopping-cart-regular'"
            :count="selectedProductOfferingData.length"
            @click="clickBack"
          />
        </div>
      </div>
      <!-- <hlx-dataview
          :card-data="card _data"
          :title-prop="'name'"
          :description-prop="'description'"
          :category-prop="'category'"
          :label-prop="'status'"
          :footer-icon="'icon-plus-circle-regular'"
          :footer-text="'Add'"
          @card-data="selectedOffer"
        >
          <template #nav>
            <div
              class="data-view-nav-container"
              style="
                display: flex;
                align-items: center;
                gap: 8px;
                color: #54bd95;
                cursor: pointer;
              "
              @click="show_offering = false"
            >
              <i class="icon-arrow-left-regular"></i>
              <span style="margin-bottom: 3px">Back</span>
            </div>
          </template>
          <template #search>
            <div
              class="data-view-search-container"
              style="display: flex; align-items: center; gap: 8px"
            >
              <hlx-search @search-key="searchOffers"></hlx-search>
              hi
              <hlx-badge
                :icon="'icon-shopping-cart-regular'"
                :count="selectedProductOfferingData.length"
              />
            </div>
          </template>
          <template #footer>
            <hlx-pagination
              v-model:rows-per-page="externalRowsPerPage"
              :total="card_da ta.length"
              rows-per-page
              enable-rows-per-page
              :rows-per-page-list="[15, 30]"
              :options="{
                attributes: {
                  rowsPerPageTitle: true,
                  rowsPerPageResult: true,
                  rowsPerPageDropdown: {
                    position: {
                      top: 0,
                      right: 1,
                    },
                  },
                },
              }"
              @current-page="currentPageData"
              @updated:rows-per-page="changeRowsPerPage"
            ></hlx-pagination>
          </template>
        </hlx-dataview> -->
    </div>

    <div class="switchforRP">
      <div class="switchforRPright">
        <span class="switchforRPrightspan1">{{ 'Show retired product' }}</span>
        <span class="switchforRPrightspan2">
          <hlx-input
            v-model:value="retiredvmodel"
            :checked="false"
            :type="'switch'"
          />
        </span>
      </div>
    </div>

    <div class="listing-container">
      <div class="filter-panel-listing-page">
        <FilterComponent
          :key="filterComponentKey"
          :filterpanelname="'productOffering'"
          :company-name="company"
          :query-value="query"
          :display-data="displayFilterData"
          :module="'discountOffers'"
          @filtered-data="offeringFilterData"
        ></FilterComponent>
      </div>
      <div
        class="new-product-listing-cards"
        style="
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
          grid-gap: 50px;
        "
      >
        <div
          v-for="(item, index) in card_data"
          :key="index"
          class="listing-card"
          style="
            min-height: 300px;
            max-height: 420px;
            border: 1px solid #d8d8d8;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
          "
          @click="showConfigurationPage(item)"
        >
          <span
            style="
              height: 220px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <span
              v-if="item.prodSpecCharValue[0]?.attachment[0]?.image_url === ''"
              style="width: 100px"
            >
              <img
                style="height: 100px; width: 100px; object-fit: contain"
                src="../../../assets/images/Placeholder image.svg"
                alt="product image preview"
              />
            </span>
            <span
              v-else
              style="width: 100%; height: 100%"
            >
              <img
                style="height: inherit; width: inherit; object-fit: contain"
                :src="item.prodSpecCharValue[0]?.attachment[0]?.image_url"
                alt="product image preview"
              />
            </span>
          </span>
          <div
            style="
              border-top: 1px solid rgb(216, 216, 216);
              padding: 20px;
              min-height: 200px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <span style="display: flex; flex-direction: column">
              <span
                style="
                  margin-bottom: 10px;
                  display: -webkit-box;
                  max-width: 400px;
                  line-clamp: 2;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ item?.name }}
              </span>
              <span style="font-size: 12px; margin-bottom: 10px">
                {{ item.category }}
              </span>
            </span>
            <span style="display: flex; flex-direction: column">
              <span
                style="
                  font-weight: bold;
                  margin-bottom: 20px;
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  flex-wrap: wrap;
                "
              >
                {{
                  currencyFormatter(
                    item.price.whole + '.' + item.price.fraction,
                    {
                      currency:
                        item.prodSpecCharValue[0]?.tiers[0]?.price?.unit,
                    }
                  )
                }}
                &nbsp;
                <del
                  v-if="
                    item?.discountResponse?.productOffering?.price !== undefined
                  "
                  style="font-weight: 100; font-size: 14px"
                  >{{
                    currencyFormatter(
                      item?.discountResponse?.productOffering?.price,
                      {
                        currency:
                          item.prodSpecCharValue[0]?.tiers[0]?.price?.unit,
                      }
                    )
                  }}</del
                >

                <span
                  v-if="item.offering_type === 'intangible'"
                  style="font-weight: 100"
                >
                  {{ ' / ' + item?.billing_period }}
                </span>
                &nbsp;
                <span
                  v-if="
                    item?.discountResponse?.productOffering?.price !== undefined
                  "
                  class="card-price-discount"
                >
                  <div class="pricing-details-card">
                    <h5 style="margin-bottom: 10px">Price Details</h5>
                    <div
                      class="price-item"
                      style="font-weight: 100"
                    >
                      <span>Price</span>
                      <span
                        class="price"
                        style="font-weight: 100"
                      >
                        {{
                          currencyFormatter(
                            item?.discountResponse?.productOffering?.price,
                            {
                              currency:
                                item.prodSpecCharValue[0]?.tiers[0]?.price
                                  ?.unit,
                            }
                          )
                        }}
                        {{
                          item?.billing_period !== '' &&
                          item?.billing_period !== undefined &&
                          item?.offering_type === 'intangible'
                            ? '/' + item?.billing_period
                            : ''
                        }}</span
                      >
                    </div>
                    <div
                      class="price-item"
                      style="font-weight: 100"
                    >
                      <span>Product Discount</span>
                      <span
                        class="discount"
                        style="color: #02b55c"
                      >
                        - &nbsp;
                        <p
                          v-if="
                            item?.discountResponse?.productOffering
                              ?.appliedDiscount?.discountType?.name ==
                            'Percentage'
                          "
                        >
                          {{
                            item?.discountResponse?.productOffering
                              ?.appliedDiscount?.discountType?.value + '%'
                          }}
                        </p>
                        <p v-else>
                          {{
                            $store?.getters?.getDefaultSettings?.currency
                              ?.symbol +
                            item?.discountResponse?.productOffering
                              ?.appliedDiscount?.discountType?.value
                          }}
                        </p>
                      </span>
                    </div>
                    <hr />
                    <div class="price-item">
                      <span>Selling Price</span>
                      <span class="final-price">
                        {{
                          currencyFormatter(
                            item.price.whole + '.' + item.price.fraction,
                            {
                              currency:
                                item.prodSpecCharValue[0]?.tiers[0]?.price
                                  ?.unit,
                            }
                          )
                        }}</span
                      >
                    </div>
                  </div>
                  <i class="icon-info-circle-regular"></i>
                </span>
                <span
                  style="
                    color: rgba(38, 94, 75, 1);
                    font-weight: 900;
                    font-size: 14px;
                  "
                >
                  {{
                    item?.discountResponse?.productOffering?.appliedDiscount
                      ?.name
                  }}
                </span>
              </span>
              <span>
                <button
                  v-if="cartItems.includes(item.id) == false"
                  class="custom-addtocart-btn"
                  @click.stop="
                    selectedOffer($event, item), addToCart($event, item)
                  "
                >
                  <i
                    class="icon-shopping-cart-plus-regular"
                    @click.stop="
                      selectedOffer($event, item), addToCart($event, item)
                    "
                  ></i>
                  &nbsp; Add to cart
                </button>
                <button
                  v-else
                  class="custom-addtocart-btn item-added-to-cart"
                >
                  Added
                </button>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 0.75rem;
        position: fixed;
        box-shadow: 1px 1px 30px -16px;
        right: 0;
        left: 72px;
        bottom: 0;
        background: white;
      "
    >
      <hlx-pagination
        v-model:rows-per-page="externalRowsPerPage"
        :total="card_data_length"
        rows-per-page
        enable-rows-per-page
        :rows-per-page-list="[15, 30]"
        :options="{
          attributes: {
            rowsPerPageTitle: true,
            rowsPerPageResult: true,
            rowsPerPageDropdown: {
              position: {
                top: 0,
                right: 1,
              },
            },
          },
        }"
        @current-page="currentPageData"
        @updated:rows-per-page="changeRowsPerPage"
      ></hlx-pagination>
    </div>
  </section>
  <div
    v-if="!show_offering"
    class="custom-layout"
  >
    <!-- Sidebar -->
    <div class="custom-sidebar">
      <hlx-breadcrumb :auto-route="true"></hlx-breadcrumb>
      <leftPanel
        :side-bar-data="sideBarData"
        @value-name="selectedValueFunc"
      ></leftPanel>
    </div>

    <div class="custom-right-layout">
      <!-- Tab and Heading Area -->
      <div class="custom-header">
        <div
          style="
            display: flex;
            height: 50px;
            align-items: center;
            margin-bottom: 10px;
          "
        >
          <span style="font-size: 34px; margin-right: 10px; color: #959595"
            ><i class="icon-shopping-cart-regular"></i
          ></span>
          <div style="display: flex; flex-direction: column; gap: 8px">
            <div style="display: flex; align-items: center; gap: 8px">
              <span style="font-weight: bold">{{ 'C-0001' }}</span>
            </div>
            <div class="address-content-sm">
              {{
                '123 Maple Street, Unit 45B, Toronto, Ontario, M5A 1A1, Canada'
              }}
            </div>
          </div>

          <span style="width: 100%"></span>
          <span style="font-size: 24px; color: #54bd95"
            ><i class="icon-time-forward-regular"></i
          ></span>
        </div>
      </div>

      <div
        style="
          display: flex;
          font-weight: bold;
          margin-bottom: 20px;
          padding: 0 10px;
        "
      >
        {{ selectedTab }}
      </div>
      <div
        v-if="selectedTab == 'Overview'"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <hlx-switch
          v-model="selectedSubTab"
          :switch-items="tabData"
          :tab-view="true"
          value-prop="value"
          display-prop="name"
          @change="chosenSubTab"
        />
        <i
          v-if="selectedSubTab == 'order_information'"
          style="font-size: 24px"
          class="icon-line-chart-dots-regular"
          @click="treeModal = true"
        ></i>
        <div
          v-if="selectedSubTab === 'site_details'"
          class="newStieAdded"
          style="color: #54bd95; cursor: pointer"
          @click="newSiteadd"
        >
          <i class="icon-plus-regular"></i> Add site
        </div>
      </div>

      <!-- Order Information -->
      <div
        v-if="
          selectedTab == 'Overview' && selectedSubTab == 'order_information'
        "
        class="custom-order-info"
        style="position: relative"
      >
        <FieldRenderer
          :key="field1Key"
          :schema-payload="filteredSchemaForField1"
          :data-payload="field1Data"
          :parent-class-name="'parent-container-for-lead-overview'"
          :layout="'2-column'"
          :grid-field-margin="20"
          @lookup-data="emittedLookupDataForRemapFromField1"
          @edited-payload="storeDataFromField1"
        ></FieldRenderer>
      </div>

      <div
        v-if="
          selectedTab == 'Overview' && selectedSubTab == 'order_information'
        "
        class="custom-questions-section"
      >
        <FieldRenderer
          :schema-payload="filteredSchemaForField2"
          :data-payload="field2Data"
          :parent-class-name="'parent-container-for-lead-overview'"
          :layout="'1-column'"
          @edited-payload="storeDataFromField2"
        ></FieldRenderer>
      </div>

      <!-- Contact Details and Billing Details -->
      <div
        v-if="
          selectedTab == 'Overview' && selectedSubTab == 'order_information'
        "
        class="custom-contact-billing"
      >
        <div class="custom-billing-details">
          <FieldRenderer
            :key="field3Key"
            :schema-payload="filteredSchemaForField3"
            :parent-class-name="'parent-container-for-lead-overview'"
            :layout="'1-column'"
            :data-payload="field3Data"
            @lookup-data="emittedLookupDataForRemapFromField3"
            @edited-payload="storeDataFromField3"
          ></FieldRenderer>
        </div>
        <div class="custom-contact-details">
          <FieldRenderer
            :key="field4Key"
            :schema-payload="filteredSchemaForField4"
            :data-payload="field4Data"
            :parent-class-name="'parent-container-for-lead-overview'"
            :layout="'1-column'"
            @edited-payload="storeDataFromField4"
          ></FieldRenderer>
        </div>
      </div>

      <!-- Constructor Company and Requester Company -->
      <div
        v-if="
          selectedTab == 'Overview' && selectedSubTab == 'order_information'
        "
        class="custom-constructor-requester"
      >
        <div class="custom-constructor-company">
          <FieldRenderer
            :schema-payload="filteredSchemaForField5"
            :data-payload="field5Data"
            :parent-class-name="'parent-container-for-lead-overview'"
            :layout="'1-column'"
            @lookup-data="emittedLookupDataForRemapFromField5"
            @edited-payload="storeDataFromField5"
          ></FieldRenderer>
        </div>
        <div class="custom-requester-company">
          <FieldRenderer
            :schema-payload="filteredSchemaForField6"
            :data-payload="field6Data"
            :parent-class-name="'parent-container-for-lead-overview'"
            :layout="'1-column'"
            @lookup-data="emittedLookupDataForRemapFromField6"
            @edited-payload="storeDataFromField6"
          ></FieldRenderer>
        </div>
      </div>

      <!-- Notes Section -->
      <div
        v-if="
          selectedTab == 'Overview' && selectedSubTab == 'order_information'
        "
        class="custom-notes-section"
      >
        <notes
          @added-note="addNotes"
          @edit-note="editNotes"
          @delete-index="deleteNotes"
        ></notes>
      </div>

      <!-- Attachment Section -->
      <div
        v-if="
          selectedTab == 'Overview' && selectedSubTab == 'order_information'
        "
        class="custom-attachment-section"
      >
        <hlx-multi-upload
          :type="'single-detail-line-multi-upload'"
          :file-type="'.json,.xlsx,.doc,.pdf,.txt,.jpg,.jpeg,.png'"
          :file-size-limit="'20MB'"
          :loader="true"
          :is-loader="true"
          :pre-value="attachmentFilteredData"
          @native-data="addAttachment"
          @remove="removeAttachment"
          @download="downloadAttachmnet"
        ></hlx-multi-upload>
      </div>

      <!-- Accordion site section -->
      <div
        v-if="selectedTab == 'Overview' && selectedSubTab == 'site_details'"
        style="margin-top: 10px"
      >
        <section
          v-for="(i, index) in example_data.length"
          :key="index"
        >
          <section
            class="lead-dropdowm-site-cover"
            style="cursor: pointer; align-items: center; margin-bottom: 8px"
            @click="closeSiteDetails(index, i)"
          >
            <div
              class="lead-dropdowm-site-num"
              style="display: contents"
            >
              <div
                class="equipment-order-site-accordion"
                style="width: 100%; font-family: OpenSans; line-height: 1.8"
              >
                <div class="left">
                  <div class="icon cart-icon">
                    <!-- Shopping Cart Icon -->
                    <i class="icon-shopping-cart-regular"></i>
                  </div>
                  <div class="details">
                    <div class="name">
                      <span style="padding-right: 10px">{{
                        example_data[index].siteName ||
                        `Site -${index + 1} details`
                      }}</span>
                      <i
                        class="icon-buildings-alt-regular"
                        style="color: #7a7a7a; padding-right: 5px"
                      ></i>
                      <span style="padding-right: 10px">{{
                        example_data[index].numberOfUnits
                      }}</span>
                      <i
                        class="icon-check-circle-filled"
                        style="color: #54bd95"
                      ></i>
                    </div>
                    <span class="address">{{
                      example_data[index]?.siteAddress[0]?.characteristic
                        ?.description
                    }}</span>
                  </div>
                </div>
                <div
                  class="right"
                  style="padding-right: 10px"
                >
                  <!-- <span class="one-time"
                    >One time total: 0
                    <span style="color: #8d8d8d">/ CAD</span></span
                  >
                  <span class="recurring"
                    >Recurring total: 0
                    <span style="color: #8d8d8d">/ CAD</span></span
                  > -->
                </div>
              </div>
              <!-- {{
                  // siteDetailsEmpty[index]?.siteName
                  //   ? siteDetailsEmpty[index]?.siteName
                  //   : `${matchFromStatic('Site')} -` +
                  //     (index + 1) +
                  //     ` ${matchFromStatic('details')}`
                  `test - ${i}`
                }} -->
              <div
                v-if="chosenSiteIndex === index"
                class="lead-dropdowm-site-icon"
              >
                <i
                  style="color: #a6a6a6"
                  class="icon-angle-up-filled"
                ></i>
              </div>
              <div
                v-if="chosenSiteIndex !== index"
                class="lead-dropdowm-site-icon"
              >
                <i
                  style="color: #a6a6a6"
                  class="icon-angle-down-filled"
                ></i>
              </div>
              <div
                class="delete-icon"
                style="padding-left: 10px"
                @click.stop="deleteSite(index)"
              >
                <i
                  class="icon-trash-regular"
                  style="color: red"
                ></i>
              </div>

              <!-- <div v-if="chosenSiteIndex === index">
                {{temporaryleadSiteDetails[chosenSiteIndex]?.siteName?temporaryleadSiteDetails[chosenSiteIndex]?.siteName:'Site -' + (chosenSiteIndex + 1) +' details'}}
              </div> -->
              <!-- <div class="lead-dropdowm-site-icon">
                <i style="color: #A6A6A6;"  class="icon-angle-down-filled"></i>
              </div> -->
            </div>
          </section>
          <div
            v-if="chosenSiteIndex === index"
            class="parent-container-for-lead-maintable-site"
          >
            <!-- :address-data-props="siteAddressArray" -->
            <FieldRenderer
              :schema-payload="filteredCustomFieldSchema7"
              :parent-class-name="'parent-container-for-lead-overview'"
              :layout="'2-column'"
              :data-payload="example_data[chosenSiteIndex]"
              @lookup-data="emittedLookupDataForRemapFromFieldSite"
              @edited-payload="siteFieldsData($event, index)"
            ></FieldRenderer>
            <!-- <FieldRenderer
              :schema-payload="filteredCustomFieldSchema7"
              :parent-class-name="'parent-container-for-lead-overview'"
              :layout="'2-column'"
            ></FieldRenderer> -->
          </div>
        </section>
        <!-- <accordionSite></accordionSite> -->
      </div>

      <!-- Accordion product section -->
      <div
        v-if="selectedTab == 'Overview' && selectedSubTab == 'product_details'"
        style="margin-top: 10px"
      >
        <section
          v-for="(i, index) in example_data.length"
          :key="index"
        >
          {{ example_data[index].pricingTableDat }}

          <section
            class="lead-dropdowm-site-cover"
            style="cursor: pointer; align-items: center; margin-bottom: 8px"
            @click="closeSiteDetails(index, i)"
          >
            <div
              class="lead-dropdowm-site-num"
              style="display: contents"
            >
              <div
                class="equipment-order-site-accordion"
                style="width: 100%; font-family: OpenSans; line-height: 1.8"
              >
                <div class="left">
                  <div class="icon cart-icon">
                    <!-- Shopping Cart Icon -->
                    <i class="icon-shopping-cart-regular"></i>
                  </div>
                  <div class="details">
                    <div class="name">
                      <span style="padding-right: 10px">{{
                        example_data[index].siteName
                      }}</span>
                      <i
                        class="icon-buildings-alt-regular"
                        style="color: #7a7a7a; padding-right: 5px"
                      ></i>
                      <span style="padding-right: 10px">{{
                        example_data[index].numberOfUnits
                      }}</span>
                      <i
                        class="icon-check-circle-filled"
                        style="color: #54bd95"
                      ></i>
                    </div>
                    <span class="address">{{
                      example_data[index]?.siteAddress[0]?.characteristic
                        ?.description
                    }}</span>
                  </div>
                </div>
                <div
                  class="right"
                  style="padding-right: 10px"
                >
                  <span class="one-time"
                    >One time total: 0
                    <span style="color: #8d8d8d">/ CAD</span></span
                  >
                  <span class="recurring"
                    >Recurring total: 0
                    <span style="color: #8d8d8d">/ CAD</span></span
                  >
                </div>
              </div>

              <!-- {{
                  // siteDetailsEmpty[index]?.siteName
                  //   ? siteDetailsEmpty[index]?.siteName
                  //   : `${matchFromStatic('Site')} -` +
                  //     (index + 1) +
                  //     ` ${matchFromStatic('details')}`
                  `test - ${i}`
                }} -->
              <div
                v-if="chosenSiteIndex === index"
                class="lead-dropdowm-site-icon"
              >
                <i
                  style="color: #a6a6a6"
                  class="icon-angle-up-filled"
                ></i>
              </div>
              <div
                v-if="chosenSiteIndex !== index"
                class="lead-dropdowm-site-icon"
              >
                <i
                  style="color: #a6a6a6"
                  class="icon-angle-down-filled"
                ></i>
              </div>

              <!-- <div v-if="chosenSiteIndex === index">
                {{temporaryleadSiteDetails[chosenSiteIndex]?.siteName?temporaryleadSiteDetails[chosenSiteIndex]?.siteName:'Site -' + (chosenSiteIndex + 1) +' details'}}
              </div> -->
              <!-- <div class="lead-dropdowm-site-icon">
                <i style="color: #A6A6A6;"  class="icon-angle-down-filled"></i>
              </div> -->
            </div>
          </section>

          <div
            v-if="chosenSiteIndex === index"
            class="parent-container-for-lead-maintable-site"
            style="margin-bottom: 20px"
          >
            <!-- :address-data-props="siteAddressArray" -->
            <pricingTableComponent
              :show-summary="false"
              :edit-summary-data="quoteData"
              :show-edit-columns-inside-table="true"
              :edit-summary-data-from-rules="quoteSummaryDataFromRules"
              :re-arrange-table-module="'EOProductTable'"
              :data="example_data[index].pricingTableData"
              :edit-data="editPricingData"
              :tax-data="recipientTaxes"
              :checkbox-shipping="multiChecked"
              :shipping-options="shippingOptions"
              @add-new-offer="addNewOffer($event, index)"
              @summary="receivePricingData"
              @total-summary="receiveTotalPricingData"
              @summary-from-rules="receiveSummaryfromRules"
              @reconfigure="sendForReconfiguration"
            ></pricingTableComponent>
          </div>
        </section>
        <div>
          <pricingTableComponent
            :key="pricingComponentKey"
            :show-summary="true"
            :show-table="false"
            :edit-summary-data="quoteData"
            :edit-summary-data-from-rules="quoteSummaryDataFromRules"
            :data="totalPricingData"
            :edit-data="editPricingData"
            :tax-data="recipientTaxes"
            :checkbox-shipping="multiChecked"
            :shipping-options="shippingOptions"
            @add-new-offer="addNewOffer"
            @summary="receivePricingData"
            @total-summary="receiveTotalPricingData"
            @summary-from-rules="receiveSummaryfromRules"
            @reconfigure="sendForReconfiguration"
          ></pricingTableComponent>
        </div>
        <!-- <accordionSite></accordionSite> -->
      </div>

      <!-- approval  table -->
      <div v-if="selectedTab == 'Approval'">
        <approvalTable :field-render-schema="approvalSchema"></approvalTable>
      </div>

      <!-- Shipping Table-->
      <div v-if="selectedTab == 'Shipment'">
        <div
          style="display: flex; justify-content: flex-end; margin-bottom: 10px"
        >
          <hlx-button
            class="secondary sm"
            @click="addShipment"
            ><i class="icon-plus-circle-filled"></i> Add Shipment</hlx-button
          >
        </div>
        <div
          v-if="example_data1?.length <= 0"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 40px;
            height: 70vh;
          "
        >
          <img
            src="@/assets/images/shippingOrder.svg"
            alt=""
          />
        </div>
        <div
          v-if="example_data1.length > 0"
          style="height: 78vh; overflow: auto"
          class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
        >
          <hlx-table
            :column-count="shipment_table_theads.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="re_arrange"
            :re-arrange-heads="shipment_table_theads_cc"
            @close-rearrange="re_arrange = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <hlx-table-head
                :width="60"
                :align="'center'"
                style="padding: 0 15px"
                :fixed="'left'"
                @new-resize-width="resizedWidth"
                >{{ 'S.no' }}</hlx-table-head
              >
              <hlx-table-head
                v-for="(i, index) in shipment_table_theads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                @new-resize-width="resizedWidth"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head
                :align="'center'"
                :width="70"
                :fixed="'right'"
                @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                    class="icon-settings-regular"
                    @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>
            <template #tbody>
              <tr
                v-for="(i, index) in example_data1"
                id=""
                :key="index"
              >
                <hlx-table-cell
                  :align="'center'"
                  :fixed="'left'"
                >
                  {{ index + 1 }}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in shipment_table_theads"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div v-if="j.prop === 'siteDetails'">
                    <div style="display: flex; flex-direction: column">
                      <span>{{ i[j.prop] }}</span>
                      <span
                        style="
                          font-size: 10px;
                          font-weight: 400;
                          line-height: 13.62px;
                          /* text-align: center; */
                          text-underline-position: from-font;
                          text-decoration-skip-ink: none;
                          color: rgba(128, 128, 128, 1);
                        "
                        >{{ i.address }}</span
                      >
                    </div>
                  </div>
                  <div v-else-if="j.prop === 'shipmentDate'">
                    {{ dateAndTimeFormatter(i[j.prop]) }}
                  </div>

                  <div v-else>{{ i[j.prop] }}</div>
                </hlx-table-cell>
                <hlx-table-cell
                  :align="'center'"
                  :width="120"
                  :fixed="'right'"
                >
                  <span
                    :id="'table-context' + (index - 1)"
                    class="action-icon"
                  >
                    <i
                      style="position: relative"
                      class="icon-more-vertical-filled"
                      :class="i.context === true ? 'active-action' : ''"
                      @click="crudContextMenu($event, i, index)"
                    >
                      <hlx-context-menu
                        :display="'display'"
                        :top="containertop"
                        :left="containerleft"
                        :conditionvalue="150"
                        :conditiondown="2"
                        :conditionup="-38"
                        :options="computedTableAction()"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="closeCrudContextMenu($event, i, index)"
                      />
                    </i>
                  </span>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>
      </div>

      <!--Installation-->
      <div v-if="selectedTab == 'Installation'">
        <div
          style="display: flex; justify-content: flex-end; margin-bottom: 10px"
        >
          <hlx-button
            class="secondary sm"
            @click="addinstallation"
            ><i class="icon-plus-circle-filled"></i> Add task</hlx-button
          >
        </div>
        <section
          v-for="(i, index) in example_data2.length"
          :key="index"
        >
          <section
            class="lead-dropdowm-site-cover"
            style="cursor: pointer; align-items: center; margin-bottom: 8px"
            @click="closeSiteDetails(index, i)"
          >
            <div
              class="lead-dropdowm-site-num"
              style="display: contents"
            >
              <div
                class="equipment-order-site-accordion"
                style="width: 100%; font-family: OpenSans; line-height: 1.8"
              >
                <div class="left">
                  <div class="details">
                    <div class="name">
                      <span style="font-weight: bold">{{
                        'Site name : '
                      }}</span>
                      <span style="padding-right: 10px">{{
                        ' ' + example_data2[index].chooseSite
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="chosenSiteIndex === index"
                class="lead-dropdowm-site-icon"
              >
                <i
                  style="color: #a6a6a6"
                  class="icon-angle-up-filled"
                ></i>
              </div>
              <div
                v-if="chosenSiteIndex !== index"
                class="lead-dropdowm-site-icon"
              >
                <i
                  style="color: #a6a6a6"
                  class="icon-angle-down-filled"
                ></i>
              </div>

              <!-- <div v-if="chosenSiteIndex === index">
                {{temporaryleadSiteDetails[chosenSiteIndex]?.siteName?temporaryleadSiteDetails[chosenSiteIndex]?.siteName:'Site -' + (chosenSiteIndex + 1) +' details'}}
              </div> -->
              <!-- <div class="lead-dropdowm-site-icon">
                <i style="color: #A6A6A6;"  class="icon-angle-down-filled"></i>
              </div> -->
            </div>
          </section>
          <div
            v-if="chosenSiteIndex === index"
            class="parent-container-for-lead-maintable-site"
            style="margin-bottom: 20px"
          >
            <!-- :address-data-props="siteAddressArray" -->
            <hlx-table
              :column-count="installation_table_theads.length + 2"
              :border="['table', 'header', 'horizontal']"
              :row-hover="true"
              theme="grey"
              :re-arrange-columns="re_arrange"
              :re-arrange-heads="installation_table_theads_cc"
              @close-rearrange="re_arrange = false"
              @apply-rearranged-columns="applyColumns"
            >
              <template #thead>
                <hlx-table-head
                  :width="60"
                  :align="'center'"
                  style="padding: 0 15px"
                  :fixed="'left'"
                  @new-resize-width="resizedWidth"
                  >{{ 'S.no' }}</hlx-table-head
                >
                <hlx-table-head
                  v-for="(ii, index_index) in installation_table_theads"
                  :key="index_index"
                  :prop="ii.prop"
                  :sortable="ii.sortable"
                  :resizable="ii.resizable"
                  :width="ii.width !== undefined ? ii.width : ''"
                  :align="ii.align !== undefined ? ii.align : ''"
                  @sorting_func="sorting_Data"
                  @new-resize-width="resizedWidth"
                >
                  {{ ii.label }}</hlx-table-head
                >
                <hlx-table-head
                  :align="'center'"
                  :width="70"
                  :fixed="'right'"
                  @new-resize-width="resizedWidth"
                  ><span class="action-icon">
                    <i
                      class="icon-settings-regular"
                      @click="reAssignOrgTableHeader()"
                    ></i> </span
                ></hlx-table-head>
              </template>
              <template #tbody>
                <tr
                  v-for="(iii, data_index) in tableCalculation(index)"
                  id=""
                  :key="data_index"
                >
                  <hlx-table-cell
                    :align="'center'"
                    :fixed="'left'"
                  >
                    {{ data_index + 1 }}
                  </hlx-table-cell>
                  <hlx-table-cell
                    v-for="(j, col_index) in installation_table_theads"
                    :key="col_index"
                    :align="j.align"
                    :fixed="j.fixed"
                  >
                    <div v-if="j.prop === 'chooseAssignee'">
                      <span style="display: flex; gap: 6px">
                        <span>{{ iii[j.prop] }}</span>
                        <span
                          ><hlx-label class="primary sm">{{
                            iii.role
                          }}</hlx-label></span
                        >
                      </span>
                    </div>
                    <div v-else-if="j.prop === 'status'">
                      <div>
                        <hlx-status :type="iii[j.prop]"></hlx-status>
                      </div>
                    </div>
                    <div
                      v-else-if="j.prop === 'installation_date'"
                      style="margin: 0.5rem 0"
                    >
                      <span v-if="iii[j.prop]"> {{ iii[j.prop] }}</span>
                      <span v-else> - </span>
                    </div>
                    <div
                      v-else-if="j.prop === 'taskName'"
                      style="margin: 0.5rem 0"
                    >
                      {{ 'Installation' }}
                    </div>
                    <!-- {{ dateAndTimeFormatter(i[j.prop]) }} -->

                    <div v-else>{{ iii[j.prop] }}</div>
                  </hlx-table-cell>
                  <hlx-table-cell
                    :align="'center'"
                    :width="120"
                    :fixed="'right'"
                  >
                    <span
                      :id="'table-context' + data_index + index"
                      class="action-icon"
                    >
                      <i
                        style="position: relative"
                        class="icon-more-vertical-filled"
                        :class="i.context === true ? 'active-action' : ''"
                        @click="
                          crudContextMenu1(
                            $event,
                            i,
                            'table-context' + data_index + index,
                            index
                          )
                        "
                      >
                        <hlx-context-menu
                          :display="'display'"
                          :top="containertop"
                          :left="containerleft"
                          :conditionvalue="150"
                          :conditiondown="2"
                          :conditionup="-38"
                          :options="computedTableAction()"
                          :data="index.toString()"
                          :show="
                            'table-context' + data_index + index == idValue
                          "
                          @chosen="
                            closeCrudContextMenu1(
                              $event,
                              i,
                              'table-context' + (data_index - 1) + index,
                              index
                            )
                          "
                        />
                      </i>
                    </span>
                  </hlx-table-cell>
                </tr>
              </template>
            </hlx-table>
          </div>
        </section>
      </div>
    </div>
    <div class="bottom-bar">
      <hlx-button
        v-if="selectedSubTab !== 'order_information'"
        class="text sm"
        @click="previousorder"
      >
        Back
      </hlx-button>
      <hlx-button
        v-if="selectedSubTab !== 'product_details'"
        class="primary sm"
        style="margin-left: 10px"
        @click="nextOrder"
      >
        Next
      </hlx-button>

      <hlx-button
        v-if="$route.params.edit || selectedSubTab == 'product_details'"
        class="secondary sm"
        @click="saveEquipmentOrder"
      >
        Save
      </hlx-button>
      <hlx-button
        v-if="$route.params.edit || selectedTab == 'product_details'"
        class="primary sm"
        style="margin-left: 10px"
        @click="contractSubmit"
      >
        Submit for approval
      </hlx-button>
      <!--- old one -->
      <!-- <hlx-button
        v-if="selectedTab != 'Overview'"
        class="previous-button-style"
        :target="'_self'"
        >Previous</hlx-button
      >
      <hlx-button
        class="secondary-success sm"
        @click="saveEquipmentOrder"
        >Save</hlx-button
      >
      <hlx-button
        v-if="selectedTab == 'Overview'"
        class="primary sm"
        >Submit for approval</hlx-button
      > -->
    </div>
  </div>
</template>

<script>
import {
  MLTAxiosInstance,
  UPCAxiosInstance,
  DMNAxiosInstance,
} from '@/config/axiosConfig';
import pricingTableComponent from '@/components/UPC/CPQ/pricingTableComponent.vue';
import notes from '@/components/Order/notesComponent.vue';
import FieldRenderer from '@/components/FieldRenderer.vue';
import leftPanel from '@/components/leftpannelComponent.vue';
// import accordionSite from '@/components/Order/accordionSitesComponent.vue'
import FilterComponent from '@/components/filterPanelComponent.vue';
import approvalTable from '@/components/Order/approvalTableComponent.vue';
// import shipppingTable from '@/components/Order/shippingTableComponent.vue';
// import installationComponent from '@/components/Order/installationComponent.vue';
import { toRaw } from 'vue';

export default {
  components: {
    FieldRenderer,
    notes,
    leftPanel,
    pricingTableComponent,
    // accordionSite,
    approvalTable,
    // shipppingTable,
    // installationComponent,
    FilterComponent,
  },
  data() {
    return {
      recipient: {
        partyCharacteristic: [{}, {}, { value: '' }],
        contactMedium: [
          {},
          {
            value: '',
          },
          {
            characteristic: {
              state: 'Quebec',
            },
          },
          {},
          {},
          {
            characteristic: {
              country: 'Canada',
            },
          },
        ],
        taxDefinition: [],
      },
      recipientTaxes: null,
      example_data_site: {
        address: '',
        units: '',
        description: null,
        siteAddress: [
          {
            characteristic: {
              city: '',
              unit: '',
              country: '',
              state: '',
              postalCode: '',
              postalCodePrefix: '',
              stateOrProvince: '',
              latitude: '',
              longitude: '',
              street1: '',
              street2: '',
              fsa: '',
              description: '',
              street: '',
              default: true,
              '@type': '',
              numberOfUnits: '',
              stateCode: 'ON',
            },
            mediumType: 'postalAddress',
          },
        ],
        siteName: '',
        firstOccupancyDate: '',
        equipmentNetworkId: null,
        marketSegment: '',
        networkId: null,
        networkManager: {
          value: '',
          email: '',
          id: '',
        },
        numberOfUnits: '',
        sfdcId: '',
        status: null,
      },
      deleteIndexValue: null,
      delete_name: '',
      delete_modal_active2: false,
      attachmentFilteredData: [],
      editData: null,
      editSiteDetailsData: [],
      getConcattedSummaryArray: [],
      deleteIndex: 0,
      componentKey: 0,
      delete_modal_active: false,
      currentIndex: null,
      edit: false,
      containertop: 0,
      containerleft: 0,
      show_right2: false,

      parentOrderPayload: {
        externalId: [],
        priority: '1',
        status: 'Open',
        orderType: 'New',
        numberOfSites: 0,
        owner: {},
        companyName: '',
        dwellingType: '',
        isMajorRenovation: 'Yes',
        isNewMDU: 'Yes',
        isPlenumVentilationUsed: 'Yes',
        description: '',
        category: '',
        orderDate: '2025-01-15T18:54:55Z',
        geographicAddress: [
          {
            mediumType: 'billingAddress',
            characteristic: {
              city: '',
              unit: '',
              country: '',
              state: '',
              postalCode: '',
              postalCodePrefix: '',
              stateOrProvince: '',
              street1: '',
              street2: '',
              fsa: '',
              description: '',
              street: '',
              default: true,
              '@type': '',
              numberOfUnits: '',
              stateCode: '',
              latitude: 0,
              longitude: 0,
            },
          },
        ],
        requestedStartDate: '2025-01-15T00:00:00Z',
        requestedCompletionDate: '2025-01-15T00:00:00Z',
        relatedParty: [
          {
            role: 'orderContact',
            '@type': 'individual',
            referredType: '',
            _id: '',
            chooseContact: '',
            contactType: '',
            firstName: '',
            lastName: '',
            emailId: '',
            phoneNumber: {
              country: {
                name: '',
                short: '',
                dial_code: '',
                flags: '',
              },
              value: '',
              valid: true,
            },
            telephoneNumber: '',
            preferredLanguage: '',
          },
          {
            role: 'construction',
            '@type': 'Organisation',
            referredType: '',
            _id: '',
            name: '',
            sameAsOrder: false,
          },
          {
            role: 'requester',
            '@type': 'Organisation',
            referredType: '',
            _id: '',
            name: '',
            sameAsOrder: false,
          },
          {
            role: 'construction',
            '@type': 'individual',
            referredType: '',
            _id: '',
            title: '',
            firstName: '',
            lastName: '',
            emailId: '',
            phoneNumber: {
              country: {
                name: '',
                short: '',
                flags: '',
                dial_code: '',
              },
              value: '',
              valid: true,
            },
          },
          {
            role: 'requester',
            '@type': 'individual',
            referredType: '',
            _id: '',
            title: '',
            firstName: '',
            lastName: '',
            emailId: '',
            phoneNumber: {
              country: {
                name: '',
                short: '',
                dial_code: '',
                flags: '',
              },
              value: '',
              valid: true,
            },
          },
        ],
        '@type': 'rootOrder',
        '@baseType': 'productOrder',
      },
      selectedLeadAccountRef: null,
      deleteSites: false,
      numberofSites: 0,
      show_right1: false,
      installation_table_theads: [
        {
          sortable: false,
          resizable: true,
          width: 250,
          label: 'Task name',
          prop: 'taskName',
          align: 'left',
          name: 'Task name',
          checked: true,
          disabled: true,
        },
        {
          sortable: false,
          resizable: true,
          width: 100,
          label: 'Status',
          prop: 'status',
          align: 'left',
          name: 'Status',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          label: 'Assignee',
          prop: 'chooseAssignee',
          align: 'left',
          name: 'Assignee',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: 'Installation date',
          prop: 'installationDate',
          align: 'left',
          name: 'Installation date',
          checked: true,
          disabled: false,
        },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 150,
        //   label: 'Last updated',
        //   prop: 'lastUpdated',
        //   align: 'left',
        //   name: 'Last updated',
        //   checked: true,
        //   disabled: false,
        // },
      ],
      product_index: null,
      cartItems: [],
      card_data: [],
      search_offering: '',
      selectedProductOfferingData: [],
      currentPage: 1,
      sendSelectedProductOfferingData: [],
      tempPricingTableData: [],
      quotePricingData: {},
      editPricingData: [],
      reconfigure: false,
      filterComponentKey: 0,
      tabletMode: false,
      filterPrevalue: {},
      filterPrevalueDuplicate1: {},
      filterPrevalueDuplicate2: {},
      filtertagvalue: 0,
      show_right_filter: false,
      show_right: false,
      filtertaglength: 0,
      filterQuery: {
        type: 'filter',
        module: 'discountOffers',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: '',
        company: '',
      },
      searchvalue: '',
      displayAlternate: [{ name: 'Product offering price', display: 'number' }],
      query: {
        Status: ['lifecycleStatus'],
        Place: ['place.name'],
        Channel: ['channel.name'],
        'Market segment': ['marketSegment.name'],
        'Product offering term': ['productOfferingTerm.name'],
        'Product offering price': [
          'productOfferingPrice.productPrice.dutyFreeAmount',
        ],
        Category: ['category'],
      },
      displayFilterData: {},
      tempQuoteSummaryDataFromRules: {
        showOneTimeSummary: false,
        showRecurringSummary: false,
        oneTime: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_onetime: false,
        },
        recurring: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_recurring: false,
        },
        overAllTotal: 0,
        amountSaved: 0,
      },
      quoteSummaryDataFromRules: {
        showOneTimeSummary: false,
        showRecurringSummary: false,
        oneTime: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_onetime: false,
        },
        recurring: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_recurring: false,
        },
        overAllTotal: 0,
        amountSaved: 0,
      },
      card_data_length: 0,
      initialPricingTableData: [],
      initialQuoteAuthorizarionData: [],
      isValidQuoteItem: false,
      company: null,
      show_offering: false,
      showSiteInformationModal: false,
      showAllSitesModal: false,
      chosenSiteIndex: null,
      field1Key: 0,
      field4Key: 0,
      field3Key: 0,
      selectedCompanyNameFromField1: null,
      selectedCompanyNameFromField5: null,
      selectedCompanyNameFromField6: null,
      isField1CompanySelected: false,
      isField5CompanySelected: false,
      isField5ContactSelected: false,
      isField6ContactSelected: false,
      isField6CompanySelected: false,
      field5ContactPayload: {
        title: '',
        firstName: '',
        lastName: '',
        emailId: '',
        phoneNumber: '',
      },
      field6ContactPayload: {
        title: '',
        firstName: '',
        lastName: '',
        emailId: '',
        phoneNumber: '',
      },
      field1Data: {
        companyName: '',
        dwellingType: null,
        status: 'Open',
        orderType: 'New',
        numberOfSites: '',
        description: '',
        category: {},
        owner: {},
      },
      field2Data: {
        isMajorRenovation: '',
        isNewMDU: '',
        isPlenumVentilationUsed: '',
      },
      field3Data: {
        chooseContact: null,
        contactType: null,
        title: null,
        firstName: null,
        lastName: null,
        emailId: null,
        phoneNumber: null,
        telephoneNumber: null,
        preferredLanguage: null,
      },
      field4Data: {
        billingAddress: [
          {
            mediumType: 'billingLocation',
            characteristic: {
              '@type': '',
              street: '',
              city: '',
              country: '',
              default: false,
              description: '',
              postalCode: '',
              postalCodePrefix: '',
              state: '',
              stateOrProvince: '',
              street1: '',
              street2: '',
              unit: '',
              latitude: '',
              longitude: '',
            },
          },
        ],
      },
      field5Data: {
        copyPropertyCompanyDetails: null,
        companyName: '',
        companyId: null,
        companyEmailId: null,
        contactType: null,
        chooseContact: null,
        contactId: null,
        title: null,
        firstName: null,
        lastName: null,
        emailId: null,
        phoneNumber: null,
      },
      field6Data: {
        copyPropertyCompanyDetails: null,
        companyName: '',
        companyEmailId: null,
        title: null,
        firstName: null,
        lastName: null,
        emailId: null,
        phoneNumber: null,
      },
      dataPayloadForRemap: null,
      example_data: [],
      // example_data: [
      //   {
      //     address: '295 A Lester Street, Waterloo, Quebec, Canada, G1A 1Al1',
      //     name: 'Prestige Elegance',
      //     units: '40',
      //     pricingTableData: [],
      //     installation: [
      //       {
      //         taskName: 'Installation',
      //         status: 'Active',
      //         assignee: 'John doe',
      //         role: 'Admin',
      //         installation_date: 'Jan 12 2025',
      //         lastUpdated: 'Nov 11, 2024 10:20 AM',
      //       },
      //     ],
      //   },
      //   {
      //     address: '295 A Lester Street, Waterloo, Quebec, Canada, G1A 1Al1',
      //     name: 'Prestige Skyline',
      //     units: '40',
      //     pricingTableData: [],
      //     installation: [
      //       {
      //         taskName: 'Installation',
      //         status: 'Pending',
      //         role: 'manager',
      //         assignee: 'Emily devis',
      //         installation_date: 'Jan 12 2025',
      //         lastUpdated: 'Nov 11, 2024 10:20 AM',
      //       },
      //     ],
      //   },
      // ],
      totalPricingData: [],
      selectedTab: '',
      selectedSubTab: 'order_information',
      tabData: [],
      sideBarData: {
        main: [
          {
            id: 1,
            name: 'Overview',
            icon: 'icon-check-circle-filled',
            disabled: false,
            active: true,
            view: true,
          },
          {
            id: 1.1,
            name: 'Approval',
            icon: 'icon-check-circle-filled',
            active: false,
            disabled: false,
            view: true,
          },
          {
            id: 2,
            name: 'Shipment',
            icon: 'icon-check-circle-filled',
            active: false,
            disabled: false,
            view: true,
          },
          {
            id: 3,
            name: 'Installation',
            icon: 'icon-check-circle-filled',
            active: false,
            disabled: false,
            view: true,
          },
        ],
      },
      aclNavigationData: [],
      section_data: {
        main: [],
      },
      schema: {},
      selectedValue: 'overview',
      shipmentData: [],
      shipment_table_theads: [
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 250,
        //   label: 'ID',
        //   prop: 'id',
        //   align: 'left',
        //   name: 'ID',
        //   checked: true,
        //   disabled: true,
        // },
        {
          sortable: false,
          resizable: true,
          width: 250,
          label: 'Site details',
          prop: 'chooseSite',
          align: 'left',
          name: 'Site details',
          checked: true,
          disabled: true,
        },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 100,
        //   label: 'shipmentID',
        //   prop: 'shipmentid',
        //   align: 'left',
        //   name: 'shipmentID',
        //   checked: true,
        //   disabled: false,
        // },
        {
          sortable: false,
          resizable: true,
          width: 200,
          label: 'Shipped quantity',
          prop: 'shippedQuantity',
          align: 'left',
          name: 'Shipped quantity',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: 'Tracking number',
          prop: 'trackingNumber',
          align: 'left',
          name: 'Tracking number',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: 'Courier name',
          prop: 'courierName',
          align: 'left',
          name: 'Courier name',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: 'Shipment Date',
          prop: 'shipmentDate',
          align: 'left',
          name: 'shipmentDate',
          checked: true,
          disabled: false,
        },
      ],
      shipment_table_theads_cc: [
        {
          sortable: false,
          resizable: true,
          width: 250,
          label: 'ID',
          prop: 'id',
          align: 'left',
          name: 'ID',
          checked: true,
          disabled: true,
        },
        {
          sortable: false,
          resizable: true,
          width: 250,
          label: 'Site details',
          prop: 'siteDetails',
          align: 'left',
          name: 'Site details',
          checked: true,
          disabled: true,
        },
        {
          sortable: false,
          resizable: true,
          width: 100,
          label: 'shipmentID',
          prop: 'shipmentid',
          align: 'left',
          name: 'shipmentID',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          label: 'Shipped quantity',
          prop: 'quantity',
          align: 'left',
          name: 'Shipped quantity',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: 'Tracking number',
          prop: 'trackingnumber',
          align: 'left',
          name: 'Tracking number',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: 'Courier name',
          prop: 'courierName',
          align: 'left',
          name: 'Courier name',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: 'Shipment Date',
          prop: 'shipmentDate',
          align: 'left',
          name: 'shipmentDate',
          checked: true,
          disabled: false,
        },
      ],
      shipmentPayload: {
        shipmentDate: '',
        chooseSite: '',
        shippedQuantity: '',
        trackingNumber: '',
        courierName: '',
      },
      chooseSites: [],
      ruleData: [],
      example_data1: [],
      DMNAxiosInstanceVar: DMNAxiosInstance,
      filterFunctionBody: `
          let api = await this.DMNAxiosInstanceVar.post('util/filterData', {
            type: 'filter',
            module: 'referencedata',
            collection: 'manufacturers',
            isPaginated: false,
            paginatedQuery: {
              rowsPerPage: 100000,
              currentPage: 1,
            },
            isNeedToBeSort: false,
            sortFieldAndOrder: {},
            limit: 100000,
            filterQuery: {},
            searchQuery: '',
            company: this.company,
          })
          let refData = api.data.data.data;
          return refData.filter((i) => i.name === 'Ericsson')[0].value;
      `,
      installation_data: [],
      example_data2: [],
      installation_payload: {
        chooseSite: '',
        status: '',
        installationDate: '',
        chooseAssignee: '',
        role: '',
      },
      idValue: '',
      delete_modal_active1: false,
      treeModal: false,
      defaultBillingAddress: null,
      pricingComponentKey:0,
    };
  },
  computed: {
    filteredSchemaForField1() {
      console.log('this.schema', this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        if (this.selectedValue.toLowerCase() == 'overview') {
          filtered = Object.entries(this.schema).filter(
            ([, value]) =>
              value.section &&
              value.section.includes(this.selectedValue.toLowerCase())
          );
        }
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);
        if (
          this.selectedValue.toLowerCase() == 'overview' &&
          this.view == false
        ) {
          Object.keys(sortedData).forEach((key) => {
            if (sortedData[key]?.others) {
              sortedData[key].others.readOnly = true;
            }
          });
        }
        if (
          this.selectedValue.toLowerCase() == 'overview' &&
          this.view == true
        ) {
          Object.keys(sortedData).map((key) => {
            Object.keys(this.filterSettingsData.permission.Overview).map(
              (keyfiled) => {
                if (
                  key ===
                  this.filterSettingsData.permission.Overview[keyfiled].name
                ) {
                  if (
                    sortedData[key]?.others &&
                    this.filterSettingsData.permission.Overview[keyfiled]
                      .edit === true
                  ) {
                    sortedData[key].others.readOnly = false;
                  }
                }
              }
            );
          });
        }

        console.log(this.view, 'sortedData', sortedData);
        return sortedData;
      } else if (this.isDrawerSchema) {
        console.log('this.isDrawerSchema', this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    filteredSchemaForField2() {
      console.log('this.schema', this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        filtered = Object.entries(this.schema.questions.schema).filter(
          ([, value]) => value.section && value.section.includes('overview')
        );
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);
        Object.keys(sortedData).forEach((key) => {
          if (sortedData[key]?.others) {
            sortedData[key].others.readOnly = true;
          }
        });

        console.log(this.view, 'sortedData', sortedData);
        return sortedData;
        // else{
        // return sortedData;
        // }
      } else if (this.isDrawerSchema) {
        console.log('this.isDrawerSchema', this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    filteredSchemaForField3() {
      console.log('this.schema', this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};

        filtered = Object.entries(this.schema.contactInfo.schema).filter(
          ([, value]) => value.section && value.section.includes('overview')
        );

        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);

        console.log(this.view, 'sortedData', sortedData);
        return sortedData;
      } else if (this.isDrawerSchema) {
        console.log('this.isDrawerSchema', this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    filteredSchemaForField4() {
      console.log('this.schema', this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        filtered = Object.entries(this.schema.billingInfo.schema).filter(
          ([, value]) => value.section && value.section.includes('overview')
        );

        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);

        return sortedData;
      } else if (this.isDrawerSchema) {
        console.log('this.isDrawerSchema', this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    filteredSchemaForField5() {
      console.log('this.schema', this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        // if (this.selectedValue.toLowerCase() == 'overview') {
        filtered = Object.entries(
          this.schema.constructionCompanyInfo.schema
        ).filter(
          ([, value]) => value.section && value.section.includes('overview')
        );
        // }
        // if (this.selectedValue.toLowerCase() == 'contacts') {
        //   const contactSchema = this.schema?.contacts?.schema;
        //   if (contactSchema && Object.keys(contactSchema).length > 0) {
        //     filtered = Object.entries(contactSchema);
        //   }
        //   // Object.entries(this.schema).filter(
        //   //   ([, value]) =>
        //   //     value.section &&
        //   //     value.section.includes(this.selectedValue.toLowerCase())
        //   // );
        // }
        // if (this.selectedValue.toLowerCase() == 'sites') {
        //   const siteSchema = this.schema?.sites?.schema;
        //   if (siteSchema && Object.keys(siteSchema).length > 0) {
        //     filtered = Object.entries(siteSchema);
        //   }
        //   //  Object.entries(this.schema).filter(
        //   //   ([, value]) =>
        //   //     value.section &&
        //   //     value.section.includes(this.selectedValue.toLowerCase())
        //   // );
        //   siteSchema.siteAddress.others.isValidationRuleEnabled = false;
        // }
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);

        // if (
        //   this.selectedValue.toLowerCase() == 'overview' &&
        //   this.view == false
        // ) {
        // Object.keys(sortedData).forEach((key) => {
        //   if (sortedData[key]?.others) {
        //     sortedData[key].others.readOnly = true;
        //   }
        // });
        // }
        // if (
        //   this.selectedValue.toLowerCase() == 'overview' &&
        //   this.view == true
        // ) {
        // Object.keys(sortedData).map((key) => {
        //   Object.keys(this.filterSettingsData.permission.Overview).map(
        //     (keyfiled) => {
        //       if (
        //         key ===
        //         this.filterSettingsData.permission.Overview[keyfiled].name
        //       ) {
        //         if (
        //           sortedData[key]?.others &&
        //           this.filterSettingsData.permission.Overview[keyfiled].edit ===
        //             true
        //         ) {
        //           sortedData[key].others.readOnly = false;
        //         }
        //       }
        //     }
        //   );
        // });
        // }

        // if (sortedData['defaultLanguage']) {
        //   sortedData['defaultLanguage'].options = this.languageOptions;
        // }
        // if (sortedData['numberOfSites']) {
        //   sortedData['numberOfSites'].others.readOnly = true;
        // }
        // if (sortedData['leadSource']) {
        //   sortedData['leadSource'].others.readOnly = true;
        // }
        // console.log(this.view, 'sortedData', sortedData);
        return sortedData;
        // else{
        // return sortedData;
        // }
      } else if (this.isDrawerSchema) {
        console.log('this.isDrawerSchema', this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    filteredSchemaForField6() {
      console.log('this.schema', this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        // if (this.selectedValue.toLowerCase() == 'overview') {
        filtered = Object.entries(
          this.schema.requesterCompanyInfo.schema
        ).filter(
          ([, value]) => value.section && value.section.includes('overview')
        );
        // }
        // if (this.selectedValue.toLowerCase() == 'contacts') {
        //   const contactSchema = this.schema?.contacts?.schema;
        //   if (contactSchema && Object.keys(contactSchema).length > 0) {
        //     filtered = Object.entries(contactSchema);
        //   }
        //   // Object.entries(this.schema).filter(
        //   //   ([, value]) =>
        //   //     value.section &&
        //   //     value.section.includes(this.selectedValue.toLowerCase())
        //   // );
        // }
        // if (this.selectedValue.toLowerCase() == 'sites') {
        //   const siteSchema = this.schema?.sites?.schema;
        //   if (siteSchema && Object.keys(siteSchema).length > 0) {
        //     filtered = Object.entries(siteSchema);
        //   }
        //   //  Object.entries(this.schema).filter(
        //   //   ([, value]) =>
        //   //     value.section &&
        //   //     value.section.includes(this.selectedValue.toLowerCase())
        //   // );
        //   siteSchema.siteAddress.others.isValidationRuleEnabled = false;
        // }
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);

        // if (
        //   this.selectedValue.toLowerCase() == 'overview' &&
        //   this.view == false
        // ) {
        // Object.keys(sortedData).forEach((key) => {
        //   if (sortedData[key]?.others) {
        //     sortedData[key].others.readOnly = true;
        //   }
        // });
        // }
        // if (
        //   this.selectedValue.toLowerCase() == 'overview' &&
        //   this.view == true
        // ) {
        // Object.keys(sortedData).map((key) => {
        //   Object.keys(this.filterSettingsData.permission.Overview).map(
        //     (keyfiled) => {
        //       if (
        //         key ===
        //         this.filterSettingsData.permission.Overview[keyfiled].name
        //       ) {
        //         if (
        //           sortedData[key]?.others &&
        //           this.filterSettingsData.permission.Overview[keyfiled].edit ===
        //             true
        //         ) {
        //           sortedData[key].others.readOnly = false;
        //         }
        //       }
        //     }
        //   );
        // });
        // }

        // if (sortedData['defaultLanguage']) {
        //   sortedData['defaultLanguage'].options = this.languageOptions;
        // }
        // if (sortedData['numberOfSites']) {
        //   sortedData['numberOfSites'].others.readOnly = true;
        // }
        // if (sortedData['leadSource']) {
        //   sortedData['leadSource'].others.readOnly = true;
        // }
        // console.log(this.view, 'sortedData', sortedData);
        return sortedData;
        // else{
        // return sortedData;
        // }
      } else if (this.isDrawerSchema) {
        console.log('this.isDrawerSchema', this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },

    filteredCustomFieldSchema7() {
      console.log('this.schema', this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        // if (this.selectedValue.toLowerCase() == 'overview') {
        filtered = Object.entries(this.schema.siteInfo.schema).filter(
          ([, value]) => value.section && value.section.includes('overview')
        );
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);

        // Object.keys(sortedData).forEach((key) => {
        //   if (sortedData[key]?.others) {
        //     sortedData[key].others.readOnly = true;
        //   }
        // });
        // }
        // if (
        //   this.selectedValue.toLowerCase() == 'overview' &&
        //   this.view == true
        // ) {
        // Object.keys(sortedData).map((key) => {
        //   Object.keys(this.filterSettingsData.permission.Overview).map(
        //     (keyfiled) => {
        //       if (
        //         key ===
        //         this.filterSettingsData.permission.Overview[keyfiled].name
        //       ) {
        //         if (
        //           sortedData[key]?.others &&
        //           this.filterSettingsData.permission.Overview[keyfiled].edit ===
        //             true
        //         ) {
        //           sortedData[key].others.readOnly = false;
        //         }
        //       }
        //     }
        //   );
        // });
        // }

        sortedData.siteName.others.lookup.filterPayload.filterQuery = {
          contactId: this.selectedLeadAccountRef,
          name: {
            $regex: '',
            $options: 'i',
          },
        };

        if (sortedData['defaultLanguage']) {
          sortedData['defaultLanguage'].options = this.languageOptions;
        }
        if (sortedData['numberOfSites']) {
          sortedData['numberOfSites'].others.readOnly = true;
        }
        if (sortedData['leadSource']) {
          sortedData['leadSource'].others.readOnly = true;
        }
        console.log(this.view, 'sortedData', sortedData);
        return sortedData;
        // else{
        // return sortedData;
        // }
      } else if (this.isDrawerSchema) {
        console.log('this.isDrawerSchema', this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    approvalSchema() {
      console.log('this.schema', this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        // if (this.selectedValue.toLowerCase() == 'overview') {
        filtered = Object.entries(this.schema.sites.schema).filter(
          ([, value]) => value.section && value.section.includes('approval')
        );
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);

        Object.keys(sortedData).forEach((key) => {
          if (sortedData[key]?.others) {
            sortedData[key].others.readOnly = true;
          }
        });
        // }
        // if (
        //   this.selectedValue.toLowerCase() == 'overview' &&
        //   this.view == true
        // ) {
        // Object.keys(sortedData).map((key) => {
        //   Object.keys(this.filterSettingsData.permission.Overview).map(
        //     (keyfiled) => {
        //       if (
        //         key ===
        //         this.filterSettingsData.permission.Overview[keyfiled].name
        //       ) {
        //         if (
        //           sortedData[key]?.others &&
        //           this.filterSettingsData.permission.Overview[keyfiled].edit ===
        //             true
        //         ) {
        //           sortedData[key].others.readOnly = false;
        //         }
        //       }
        //     }
        //   );
        // });
        // }

        if (sortedData['defaultLanguage']) {
          sortedData['defaultLanguage'].options = this.languageOptions;
        }
        if (sortedData['numberOfSites']) {
          sortedData['numberOfSites'].others.readOnly = true;
        }
        if (sortedData['leadSource']) {
          sortedData['leadSource'].others.readOnly = true;
        }
        console.log(this.view, 'sortedData', sortedData);
        return sortedData;
        // else{
        // return sortedData;
        // }
      } else if (this.isDrawerSchema) {
        console.log('this.isDrawerSchema', this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    shipmentSchema() {
      console.log('this.schema', this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        // if (this.selectedValue.toLowerCase() == 'overview') {
        filtered = Object.entries(this.schema.shipment.schema).filter(
          ([, value]) => value.section && value.section.includes('shipment')
        );
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);

        // Object.keys(sortedData).forEach((key) => {
        //   if (sortedData[key]?.others) {
        //     sortedData[key].others.readOnly = true;
        //   }
        // });
        // }
        // if (
        //   this.selectedValue.toLowerCase() == 'overview' &&
        //   this.view == true
        // ) {
        // Object.keys(sortedData).map((key) => {
        //   Object.keys(this.filterSettingsData.permission.Overview).map(
        //     (keyfiled) => {
        //       if (
        //         key ===
        //         this.filterSettingsData.permission.Overview[keyfiled].name
        //       ) {
        //         if (
        //           sortedData[key]?.others &&
        //           this.filterSettingsData.permission.Overview[keyfiled].edit ===
        //             true
        //         ) {
        //           sortedData[key].others.readOnly = false;
        //         }
        //       }
        //     }
        //   );
        // });
        // }

        if (sortedData['defaultLanguage']) {
          sortedData['defaultLanguage'].options = this.languageOptions;
        }
        if (sortedData['numberOfSites']) {
          sortedData['numberOfSites'].others.readOnly = true;
        }
        if (sortedData['leadSource']) {
          sortedData['leadSource'].others.readOnly = true;
        }
        console.log(this.view, 'sortedData', sortedData);
        sortedData['chooseSite'].options = this.chooseSites;
        return sortedData;
        // else{
        // return sortedData;
        // }
      } else if (this.isDrawerSchema) {
        console.log('this.isDrawerSchema', this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    installationSchema() {
      console.log('this.schema', this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        // if (this.selectedValue.toLowerCase() == 'overview') {
        filtered = Object.entries(this.schema.installation.schema).filter(
          ([, value]) => value.section && value.section.includes('installation')
        );
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);

        // Object.keys(sortedData).forEach((key) => {
        //   if (sortedData[key]?.others) {
        //     sortedData[key].others.readOnly = true;
        //   }
        // });
        // }
        // if (
        //   this.selectedValue.toLowerCase() == 'overview' &&
        //   this.view == true
        // ) {
        // Object.keys(sortedData).map((key) => {
        //   Object.keys(this.filterSettingsData.permission.Overview).map(
        //     (keyfiled) => {
        //       if (
        //         key ===
        //         this.filterSettingsData.permission.Overview[keyfiled].name
        //       ) {
        //         if (
        //           sortedData[key]?.others &&
        //           this.filterSettingsData.permission.Overview[keyfiled].edit ===
        //             true
        //         ) {
        //           sortedData[key].others.readOnly = false;
        //         }
        //       }
        //     }
        //   );
        // });
        // }

        if (sortedData['defaultLanguage']) {
          sortedData['defaultLanguage'].options = this.languageOptions;
        }
        if (sortedData['numberOfSites']) {
          sortedData['numberOfSites'].others.readOnly = true;
        }
        if (sortedData['leadSource']) {
          sortedData['leadSource'].others.readOnly = true;
        }
        sortedData['chooseSite'].options = this.chooseSites;
        console.log(this.view, 'sortedData', sortedData);
        return sortedData;
        // else{
        // return sortedData;
        // }
      } else if (this.isDrawerSchema) {
        console.log('this.isDrawerSchema', this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
  },
  watch: {
    example_data: {
      handler(val) {
        val
        // this.totalPricingData = val.reduce((acc, obj) => {
        //   if (obj.pricingTableData !== undefined) {
        //     return acc.concat(JSON.parse(JSON.stringify(obj.pricingTableData)));
        //   }
        //   return acc;
        // }, []); // Provide an initial value as an empty array

        // console.log('concatted', this.totalPricingData);
      },
      immediate: true,
      deep: true,
    },
    field1Data: {
      handler(val) {
        console.log('field1Data', val);
      },
      immediate: true,
      deep: true,
    },
    field2Data: {
      async handler(val) {
        console.log(
          'EOPO',
          val,
          await this.EOProductRule(),
          await this.getProductsFromSKU('TELUS_SMART_HUB_BL130'),
          this.transformData(
            await this.getProductsFromSKU('TELUS_SMART_HUB_BL130')
          )
        );
        this.ruleData = this.transformData(
          await this.getProductsFromSKU('TELUS_SMART_HUB_BL130')
        );
      },
      immediate: true,
      deep: true,
    },
    field5Data: {
      handler(val) {
        val;
        console.log('field5DatawatchallData', {
          field1Data: this.field1Data,
          field2Data: this.field2Data,
          field3Data: this.field3Data,
          field4Data: this.field4Data,
          field5Data: this.field5Data,
          field6Data: this.field6Data,
        });
      },
      immediate: true,
      deep: true,
    },
    schema: {
      handler(val) {
        console.log('schemaData', val);
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    this.company = this.$cookies.get('company');
    console.log(await this.executeFilterFunction());
    let organisationInfo = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${this.$cookies.get(
        'user'
      )}?company=${this.$cookies.get('company')}`
    );
    this.defaultBillingAddress = organisationInfo.data.contactMedium.filter(
      (i) => {
        return i['mediumType'] == 'billingAddress';
      }
    )[0];
    this.field4Data.billingAddress = [];
    this.field4Data.billingAddress.push(
      JSON.parse(JSON.stringify(this.defaultBillingAddress))
    );
    let data1;
    console.log(this.$route.query.edit, 'edittti');
    if (this.$route.query.edit) {
      let filteredData = await UPCAxiosInstance.get(
        `/productOrder/v1/orderTree/${this.$route.query.id}`
      );

      this.editData = filteredData.data;

      console.log(
        this.editData,
        'editData',
        this.reverseTransformPayloadDynamic(this.editData)
      );
      data1 = this.reverseTransformPayloadDynamic(this.editData);
      this.field1Data = data1.field1Data;
      this.field2Data = data1.field2Data;
      this.field3Data = data1.field3Data;
      // this.field4Data = data1.field4Data
      this.field5Data = data1.field5Data;
    }
    // const result = [];

    // // Iterate through each object in the data array
    // for (const { geographicAddress, productOrderItem } of filteredData.data.child) {
    //   // Process the productOrderItem array using the function
    //   const processedResult = this.mapFromTMF648(productOrderItem);

    //   // Add the processed result to each geographicAddress
    //   for (const address of geographicAddress) {
    //     result.push({ ...address, pricingTableData: processedResult });
    //   }
    // }

    // if(this.$route.query.edit == true){

    // }
    // else if(this.$route.query.edit == true){

    // }
    // else{

    // }

    let navigationData = await UPCAxiosInstance.get('/customHierarchy');
    let arr = navigationData.data[0].module[0].main;

    let sectiondata = this.findObjectByAttribute(
      arr,
      'customFieldModule',
      'productorder'
    );

    this.section_data.main = sectiondata?.sections;

    let data = await UPCAxiosInstance.get(
      `customSchema/getSchema?module=productorder`
    );
    this.schema = data.data.schema;
    console.log(this.schema, 'schema');
    await this.$store.dispatch('discountOffersData');
    this.displayFilterData =
      this.$store.getters.productOfferingDiscountFilterData;
    const filterResultData2 = await UPCAxiosInstance.post(
      `util/filterData`,
      this.filterQuery
    );
    // this.offering_data = filterResultData.data.data
    // console.log("raw offer", filterResultData);
    console.log('Offering data', filterResultData2.data.total);
    this.card_data_length = filterResultData2.data.total;
    console.log(
      filterResultData2.data.data,
      this.filterQuery,
      'filterResultData2.data.data...'
    );

    this.card_data = this.transformData(filterResultData2.data.data);
    console.log('card_data', this.card_data);
    this.card_data.forEach((i) => {
      this.getProductImageData(i);
    });
    this.schema = data.data.schema;
    //     const result1 = filteredData.data.child.flatMap(({ geographicAddress, productOrderItem }) => {
    //   // Call the function for each productOrderItem
    //   const processedResult = this.mapFromTMF648(productOrderItem);

    //   // Add the processed result as pricingTableData to each geographicAddress
    //   return geographicAddress.map(address => ({
    //     ...address,
    //     pricingTableData: processedResult
    //   }));
    // });
    if (this.$route.query.edit) {
      const result = this.editData.child.reduce(
        (acc, item) => acc.concat(item.geographicAddress),
        []
      );
      this.example_data = result;

      console.log(result);

      console.log('siteDetails', result); // Output: A single combined array of all geographicAddress arrays
      this.example_data = result;
      // this.field4Data = data1.field4Data
      // this.schema.billingInfo.schema = {
      //             ...this.schema.billingInfo.schema,
      //           };
    }
    // setTimeout(() => {
    this.schema.billingInfo.schema.billingAddress.value = [];
    this.schema.billingInfo.schema.billingAddress.value.push(
      JSON.parse(JSON.stringify(this.defaultBillingAddress))
    );
    this.findTaxForRecipient(this.recipient);
    //   this.field4Key += 1;
    // }, 500);
  },
  methods: {
    async findTaxForRecipient(recipient) {
      console.log('findTaxForRecipient....123', recipient);
      // this.recipient =

      // const defaultSettings = await MLTAxiosInstance.get(`/settings`);
      let addressType = 'billingAddress';
      let taxData = {};
      let obj = {
        specificAccount: true,
        'appliesTo.tradingName': recipient?.name,
        'province.name': recipient?.contactMedium[3]?.characteristic?.state,
        'country.name.common':
          recipient?.contactMedium[5]?.characteristic?.country,
        locationRef: addressType,
      };
      obj;
      let obj1 = {
        specificMarket: true,
        'appliesTo.name': recipient?.partyCharacteristic[2]?.value,
        'province.name': recipient?.contactMedium[3]?.characteristic?.state,
        'country.name.common':
          recipient?.contactMedium[5]?.characteristic?.country,
        locationRef: addressType,
      };
      obj1;
      let obj2 = {
        allAccount: true,
        'province.name': recipient?.contactMedium[3]?.characteristic?.state,
        'country.name.common':
          recipient?.contactMedium[5]?.characteristic?.country,
        locationRef: addressType,
      };
      obj2;
      let obj3 = {
        allAccount: true,
        province: [],
        'country.name.common':
          recipient?.contactMedium[5]?.characteristic?.country,
        locationRef: addressType,
      };
      obj3;

      let f2x = {
        company: `${this.company}`,
        type: 'filter-search',
        module: 'tax',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 100,
        filterQuery: {},
        searchQuery: '',
      };
      console.log(f2x, 'lolololololo', recipient);
      f2x.filterQuery = obj;
      let tax1 = await UPCAxiosInstance.post('util/filterData', f2x);
      console.log(tax1.data.data, 'tax1');
      if (tax1.data.data.length == 0) {
        f2x.filterQuery = obj1;
        let tax2 = await UPCAxiosInstance.post('util/filterData', f2x);
        console.log(tax2.data.data, 'tax2');
        if (tax2.data.data.length == 0) {
          f2x.filterQuery = obj2;
          let tax3 = await UPCAxiosInstance.post('util/filterData', f2x);
          console.log(tax3.data.data, 'tax3');
          if (tax3.data.data.length == 0) {
            f2x.filterQuery = obj3;
            let tax4 = await UPCAxiosInstance.post('util/filterData', f2x);
            console.log(tax4.data.data, 'tax4');
            if (tax4.data.data.length != 0) {
              taxData = tax4.data.data[0];
            }
          } else {
            taxData = tax3.data.data[0];
          }
        } else {
          taxData = tax2.data.data[0];
        }
      } else {
        taxData = tax1.data.data[0];
      }
      // f2x.filterQuery = obj;
      // let x2x = await UPCAxiosInstance.post('util/filterData', f2x);
      // if (x2x.data.data.length == 0) {
      //   f2x.filterQuery = obj1;
      //   let x2x = await UPCAxiosInstance.post('util/filterData', f2x);
      //   taxData = x2x?.data?.data[0];
      // }
      // taxData = []
      console.log(
        recipient,
        taxData,
        this.getMatchingTaxes(taxData, recipient),
        'taxData'
      );
      this.recipientTaxes = this.getMatchingTaxes(taxData, recipient);
    },
    getMatchingTaxes(payload1, payload2) {
      // Safely extract the tax array from payload 1, default to an empty array if not present
      const taxArray1 = Array.isArray(payload1.tax) ? payload1.tax : [];

      // Safely extract the taxDefinition array from payload 2, default to an empty array if not present
      const taxDefinitionArray2 = Array.isArray(payload2.taxDefinition)
        ? payload2.taxDefinition
        : [];

      // Filter the taxDefinition array from payload 2 to get only checked items
      const checkedTaxes = taxDefinitionArray2.filter((tax) => tax.checked);

      // Map the checked taxes to get their tax names
      const checkedTaxNames = checkedTaxes
        .map((tax) => tax.taxname)
        .filter(Boolean); // Filter out undefined or null tax names

      // Filter the tax array from payload 1 to get items with names not matching the checked tax names
      const unmatchedTaxes = taxArray1.filter(
        (tax) => !checkedTaxNames.includes(tax.taxname)
      );

      // If there are unmatched taxes and taxType exists, add taxType to the first unmatched tax
      if (unmatchedTaxes.length > 0 && payload1.taxType) {
        unmatchedTaxes[0].taxType = payload1.taxType;
      }
      console.log('unmatchedTaxes......', unmatchedTaxes);

      // Ensure all keys in the returned array are correctly spelled
      const correctedUnmatchedTaxes = unmatchedTaxes.map((tax) => {
        return {
          taxName: tax?.taxname, // Correcting to taxName
          rate: tax?.rate,
          taxType:
            tax?.taxType?.toLowerCase() !== 'compound'
              ? 'cumulative'
              : 'compound', // Ensure correct spelling
        };
      });
      return correctedUnmatchedTaxes;
    },
    deleteExecRow2() {
      this.example_data.splice(this.deleteIndexValue, 1);
      this.field1Data.numberOfSites = this.example_data.length;
      this.delete_modal_active2 = false;
    },
    nextOrder() {
      // Find the index of the current selectedvalue
      const currentIndex = this.tabData.findIndex(
        (tab) => tab.value === this.selectedSubTab
      );
      console.log(this.selectedSubTab, this.tabData, 'sjl');

      // If there's a next value, update selectedvalue
      if (currentIndex !== -1 && currentIndex < this.tabData.length - 1) {
        this.selectedSubTab = this.tabData[currentIndex + 1].value;
      }
    },
    previousorder() {
      const currentIndex = this.tabData.findIndex(
        (tab) => tab.value === this.selectedSubTab
      );
      if (currentIndex > 0) {
        this.selectedSubTab = this.tabData[currentIndex - 1].value;
      }
    },
    async addAttachment(event) {
      try {
        if (!Array.isArray(event) || event.length === 0) {
          throw new Error('Invalid or empty file array provided.');
        }

        console.log(event, 'storeDataFromField3 addAttachment');

        const formData = new FormData();
        event.forEach((file, index) => {
          if (!(file instanceof File)) {
            console.warn(`Item at index ${index} is not a valid File object.`);
            return;
          }
          formData.append('files[]', file);
        });

        const response = await UPCAxiosInstance.post(
          `/gcpMultiple/v2/upload/`,
          formData
        );
        if (!response || !response.data) {
          throw new Error('No response data received from the upload API.');
        }

        const formattedData = response.data
          .map((item) => {
            if (!item.filename || !item.data?.attachment?.size) {
              console.warn('Invalid attachment data in API response:', item);
              return null;
            }
            return {
              properties: {
                name: item.filename,
                size: item.data.attachment.size,
              },
            };
          })
          .filter(Boolean); // Remove null values

        if (formattedData.length === 0) {
          console.warn(
            'No valid attachments were processed from the API response.'
          );
          return;
        }

        this.field1Data.attachment = [
          ...this.field1Data.attachment,
          ...formattedData,
        ];

        this.attachmentFilteredData = this.field1Data.attachment.filter(
          (item) => item['@type'] !== 'profile'
        );

        console.log(
          this.field1Data.attachment,
          'storeDataFromField3 addAttachment'
        );
      } catch (error) {
        console.error('Error adding attachment:', error.message);
      }
    },
    removeAttachment(val, index) {
      try {
        if (typeof index !== 'number' || index < 0) {
          throw new Error('Invalid index for attachment removal.');
        }

        this.attachmentFilteredData = this.field1Data.attachment.filter(
          (item) => item['@type'] !== 'profile'
        );

        const profileAttachments = this.field1Data.attachment.filter(
          (item) => item['@type'] === 'profile'
        );

        if (index >= this.attachmentFilteredData.length) {
          throw new Error(
            `Index ${index} out of bounds for attachment removal.`
          );
        }

        // Remove the specified attachment
        const removedItem = this.attachmentFilteredData.splice(index, 1);
        console.log('Removed attachment:', removedItem);

        // Combine updated filtered data with profile attachments
        this.field1Data.attachment = [
          ...this.attachmentFilteredData,
          ...profileAttachments,
        ];
      } catch (error) {
        console.error('Error removing attachment:', error.message);
      }
    },
    async downloadAttachmnet(val) {
      const url = await UPCAxiosInstance.get(
        `/gcpupload/files/${val.properties.name}`,
        {
          responseType: 'arraybuffer',
        }
      );

      this.downloadFromGCP(url.data, val.properties.name);
    },
    async downloadFromGCP(urlFetch, name) {
      console.log(urlFetch, name);
      const type = this.getFileTypeFromMimeType(name);
      const blob = new Blob([urlFetch], { type: type });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      document.body.appendChild(a);

      a.click();
    },
    getFileTypeFromMimeType(mimeType) {
      // Map MIME types to file types
      const mimeTypeMap = {
        'image/jpeg': 'JPEG',
        'image/png': 'PNG',
        'application/pdf': 'PDF',
        'text/plain': 'TXT',
        // Add more MIME types as needed
      };

      // Lookup the file type based on the MIME type
      const fileType = mimeTypeMap[mimeType] || 'Unknown';

      // Convert the file type back to MIME type format if found, otherwise return the original MIME type
      return fileType !== 'Unknown'
        ? `application/${fileType.toLowerCase()}`
        : mimeType;
    },
    addNotes(val) {
      console.log(val, 'addNotes');
      this.field1Data.note = val;
    },
    editNotes(val, index) {
      console.log(val, index, 'editNotes');
      this.field1Data.note = val;
    },
    deleteNotes(val) {
      // console.log(index, "deleteNotes");
      this.field1Data.note = val;
      // this.field3Data.note.splice(index, 1);
    },
    async executeFilterFunction() {
      // Dynamically create an async function
      // Use Function constructor to evaluate the expression safely
      // eslint-disable-next-line no-new-func
      const asyncFunction = new Function(
        'collectionName',
        `return (async function() { ${this.filterFunctionBody} }).call(this);`
      );

      // Execute the function
      try {
        const filteredData = await asyncFunction.call(this);
        console.log('Filtered Data:', filteredData);
      } catch (error) {
        console.error('Error executing dynamic function:', error);
      }
    },
    async chosenSubTab() {
      console.log('chsone', this.selectedSubTab);

      // this.openSiteDetails(1, 0);
      if (this.selectedSubTab == 'order_information') {
        this.field4Data.billingAddress = [];
        this.field4Data.billingAddress.push(
          JSON.parse(JSON.stringify(this.defaultBillingAddress))
        );
        this.schema.billingInfo.schema.billingAddress.value = [];
        this.schema.billingInfo.schema.billingAddress.value.push(
          JSON.parse(JSON.stringify(this.defaultBillingAddress))
        );
        this.field4Key += 1;
      }else if(this.selectedSubTab == 'product_details'){
        for (let j = 0; j < this.example_data.length; j++) { 
        let skus = await this.EOProductRule(
        this.example_data[j].numberOfUnits,
        this.field2Data.isPlenumVentilationUsed == ''
          ? 'Yes'
          : this.field2Data.isPlenumVentilationUsed
      );
      let arr = [];
      for (let i = 0; i < skus.skus.length; i++) {
        let value = await this.getProductsFromSKU(skus.skus[i].SKU);
        arr.push(value[0]);
      }
      this.ruleData = this.transformData(arr);
      this.ruleData.forEach((item, index) => {
        if (skus.skus[index]) {
          item.quantity = skus.skus[index].quantity;
        }
      });
      console.log(this.example_data, 'siteData', this.ruleData, skus);
      this.example_data[j].pricingTableData = this.ruleData;
    }
    this.totalPricingData = this.example_data.reduce((acc, obj) => {
          if (obj.pricingTableData !== undefined) {
            return acc.concat(JSON.parse(JSON.stringify(obj.pricingTableData)));
          }
          return acc;
        }, []); // Provide an initial value as an empty array

        console.log('concatted', this.totalPricingData);
        this.pricingComponentKey +=1
      this.example_data.forEach((item) => {
        item.name = item.siteName;
        item.english = item.siteName;
      });
      this.chooseSites = JSON.parse(JSON.stringify(this.example_data));
      console.log(this.chooseSites, 'site value');
      }
    },
    async emittedLookupDataForRemapFromFieldSite([
      dataPayloadDup,
      schemaToBeMapped,
      schema,
    ]) {
      if (dataPayloadDup && schemaToBeMapped) {
        let dataPayload = JSON.parse(JSON.stringify(dataPayloadDup));
        console.log(
          schema,
          dataPayloadDup,
          'emittedLookupDataForRemapFromFieldsite blast',
          schemaToBeMapped,
          'dataPayload blast',
          dataPayload
        );

        for (let i = 0; i < schemaToBeMapped.length; i++) {
          let schemaObjName = schemaToBeMapped[i].LHS;
          let schemaValue = schemaToBeMapped[i].RHS;
          let changeRhsValue = 'payload';
          const firstDotIndex = schemaValue.indexOf('.');
          console.log(schemaValue, 'dataPayloaddataPayload', dataPayload);
          if (firstDotIndex !== -1) {
            let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
            console.log(schemaObjName, 'newRHS', newRHS);
            newRHS;
            let stringifiedDataPayload = JSON.parse(
              JSON.stringify(dataPayload)
            );
            let result = this.evaluateRHS(newRHS, stringifiedDataPayload);
            console.log('remappped data', result);

            if (result) {
              console.log(
                'this.leadSchema[schemaObjName].value blast',
                schemaObjName
              );
              if (schema[schemaObjName]) {
                console.log(
                  'storeDataFromField3 this.leadSchema[schemaObjName].value 1 ',
                  schema[schemaObjName]
                );
                schema[schemaObjName].value = result;
                this.example_data[this.chosenSiteIndex][schemaObjName] = result;
                console.log(
                  schema.siteAddress,
                  this.example_data[this.chosenSiteIndex].siteAddress,
                  'boxx'
                );
                schema.isRemapData = false;
                schema.siteAddress.value = [];
                schema.siteAddress.value.push(
                  JSON.parse(
                    JSON.stringify(
                      this.example_data[this.chosenSiteIndex].siteAddress[0]
                    )
                  )
                );
              }
            }
          }
        }
      }
      console.log(schema, 'helloworld');
      // this.example_data_site.siteAddress = [];
      // this.example_data_site.siteAddress.push(
      //   JSON.parse(
      //     JSON.stringify(this.example_data[this.chosenSiteIndex].siteAddress[0])
      //   )
      // );
      // this.schema.siteInfo.schema = JSON.parse(JSON.stringify(schema));
    },
    async siteFieldsData($event, value) {
      console.log(value, 'helloworld', $event);
      this.example_data[value] = $event;
      // let skus = await this.EOProductRule(
      //   $event.numberOfUnits,
      //   this.field2Data.isPlenumVentilationUsed == ''
      //     ? 'Yes'
      //     : this.field2Data.isPlenumVentilationUsed
      // );
      // let arr = [];
      // for (let i = 0; i < skus.skus.length; i++) {
      //   let value = await this.getProductsFromSKU(skus.skus[i].SKU);
      //   arr.push(value[0]);
      // }
      // this.ruleData = this.transformData(arr);
      // this.ruleData.forEach((item, index) => {
      //   if (skus.skus[index]) {
      //     item.quantity = skus.skus[index].quantity;
      //   }
      // });
      // console.log(this.example_data, 'siteData', this.ruleData, skus);
      // this.example_data[value].pricingTableData = this.ruleData;
      // this.example_data.forEach((item) => {
      //   item.name = item.siteName;
      //   item.english = item.siteName;
      // });
      // this.chooseSites = JSON.parse(JSON.stringify(this.example_data));
      // console.log(this.chooseSites, 'site value');
    },
    notesFunction(value) {
      console.log(value, 'notesFunction');
    },
    newSiteadd() {
      this.example_data.push({
        address: '',
        units: '',
        description: null,
        siteAddress: [
          {
            characteristic: {
              city: '',
              unit: '',
              country: '',
              state: '',
              postalCode: '',
              postalCodePrefix: '',
              stateOrProvince: '',
              latitude: '',
              longitude: '',
              street1: '',
              street2: '',
              fsa: '',
              description: '',
              street: '',
              default: true,
              '@type': '',
              numberOfUnits: '',
              stateCode: 'ON',
            },
            mediumType: 'postalAddress',
          },
        ],
        siteName: '',
        firstOccupancyDate: '',
        equipmentNetworkId: null,
        marketSegment: '',
        networkId: null,
        networkManager: {
          value: '',
          email: '',
          id: '',
        },
        numberOfUnits: '',
        sfdcId: '',
        status: null,
      });
      this.field1Data.numberOfSites = this.example_data.length;
    },
    async saveEquipmentOrder() {
      let companyArr = [];
      let contactsArr = [];
      let constructionCompany = {
        customName: this.field5Data.companyName,
        '@baseType': 'constructionCompany',
        name: this.sanitizeDatabaseName(this.field5Data.companyName),
        companyName: this.sanitizeDatabaseName(this.field5Data.companyName),
        tradingName: this.sanitizeDatabaseName(this.field5Data.companyName),
        email: this.generateUniqueEmail(),
        parentCompany: this.company,
      };
      let requesterCompany = {
        customName: this.field6Data.companyName,
        '@baseType': 'requesterCompany',
        name: this.sanitizeDatabaseName(this.field6Data.companyName),
        companyName: this.sanitizeDatabaseName(this.field6Data.companyName),
        tradingName: this.sanitizeDatabaseName(this.field6Data.companyName),
        email: this.generateUniqueEmail(),
        parentCompany: this.company,
      };
      if (!this.isField5CompanySelected) {
        companyArr.push(constructionCompany);
      }
      if (!this.isField6CompanySelected) {
        companyArr.push(requesterCompany);
      }
      //       {
      //     "name": "Sudeep Singh",
      //     "birthDate": "",
      //     "firstName": "Sudeep",
      //     "lastName": "Singh",
      //     "title": "",
      //     "tradingName": "newconcompany",
      //     "companyName": "newconcompany",
      //     "status": "Active",
      //     "preferred": "",
      //     "partyCharacteristic": [
      //         {
      //             "name": "number of employees",
      //             "value": ""
      //         },
      //         {
      //             "name": "market segment",
      //             "value": ""
      //         }
      //     ],
      //     "contactMedium": [
      //         {
      //             "preferred": false,
      //             "mediumType": "email",
      //             "characteristic": {
      //                 "emailAddress": "sudeepsingh@gmail.com"
      //             }
      //         },
      //         {
      //             "mediumType": "phone",
      //             "characteristic": {
      //                 "phoneNumber": {
      //                     "country": {
      //                         "name": "Canada",
      //                         "short": "CA",
      //                         "flags": "https://flagcdn.com/ca.svg",
      //                         "dial_code": "+1"
      //                     },
      //                     "value": "(712) 772-2772",
      //                     "valid": true
      //                 }
      //             },
      //             "preferred": false
      //         },
      //         {
      //             "mediumType": "postalAddress",
      //             "characteristic": {
      //                 "city": "",
      //                 "country": "",
      //                 "postCode": "",
      //                 "stateOrProvince": "",
      //                 "street1": "",
      //                 "street2": "",
      //                 "timezone": "",
      //                 "description": "",
      //                 "@type": ""
      //             },
      //             "preferred": false
      //         }
      //     ],
      //     "externalReference": [
      //         {
      //             "name": "",
      //             "externalIdentifierType": "linkedIn"
      //         }
      //     ],
      //     "relatedParty": [
      //         {
      //             "role": "Consultant",
      //             "organization": "newconcompany",
      //             "@type": "multiorganization"
      //         }
      //     ],
      //     "tags": [],
      //     "aristocraticTitle": "Consultant",
      //     "role": "Consultant",
      //     "type": "individual",
      //     "@baseType": "customer",
      //     "notes": [],
      //     "attachment": [],
      //     "password": "",
      //     "email": "sudeepsingh@gmail.com",
      //     "familyName": "Sudeep Singh",
      //     "givenName": "Sudeep Singh",
      //     "parentCompany": "developerHalleyx",
      //     "company": "newconcompany",
      //     "lastUpdate": "2025-01-13T11:21:20.955Z"
      // }

      let constructionContact = {
        name: this.field5Data.firstName + ' ' + this.field5Data.lastName,
        firstName: this.field5Data.firstName,
        lastName: this.field5Data.lastName,
        familyName: this.field5Data.firstName + ' ' + this.field5Data.lastName,
        givenName: this.field5Data.firstName + ' ' + this.field5Data.lastName,
        '@baseType': 'customer',
        company: this.sanitizeDatabaseName(this.field5Data.companyName),
        companyName: this.sanitizeDatabaseName(this.field5Data.companyName),
        tradingName: this.sanitizeDatabaseName(this.field5Data.companyName),
        email: this.field5Data.emailId,
        title: this.field5Data.title,
        contactMedium: [
          {
            preferred: false,
            mediumType: 'email',
            characteristic: {
              emailAddress: this.field5Data.emailId,
            },
          },
          {
            mediumType: 'phone',
            characteristic: {
              phoneNumber: JSON.parse(
                JSON.stringify(this.field6Data.phoneNumber)
              ),
            },
            preferred: false,
          },
        ],
        parentCompany: this.company,
      };
      let requesterContact = {
        name: this.field6Data.firstName + ' ' + this.field6Data.lastName,
        firstName: this.field6Data.firstName,
        lastName: this.field6Data.lastName,
        familyName: this.field6Data.firstName + ' ' + this.field6Data.lastName,
        givenName: this.field6Data.firstName + ' ' + this.field6Data.lastName,
        '@baseType': 'customer',
        company: this.sanitizeDatabaseName(this.field6Data.companyName),
        companyName: this.sanitizeDatabaseName(this.field6Data.companyName),
        tradingName: this.sanitizeDatabaseName(this.field6Data.companyName),
        email: this.field6Data.emailId,
        title: this.field6Data.title,
        contactMedium: [
          {
            preferred: false,
            mediumType: 'email',
            characteristic: {
              emailAddress: this.field6Data.emailId,
            },
          },
          {
            mediumType: 'phone',
            characteristic: {
              phoneNumber: JSON.parse(
                JSON.stringify(this.field6Data.phoneNumber)
              ),
            },
            preferred: false,
          },
        ],
        parentCompany: this.company,
      };
      if (!this.isField5ContactSelected) {
        contactsArr.push(constructionContact);
      }
      if (!this.isField6ContactSelected) {
        contactsArr.push(requesterContact);
      }

      try {
        let company = await MLTAxiosInstance.post(
          `partymanagement/organisation/bulk`,
          companyArr
        );
        let contact = await MLTAxiosInstance.post(
          `partymanagement/individual/bulk`,
          contactsArr
        );
        if (!this.isField1CompanySelected) {
          let createParentCompany = {
            customName: this.field1Data.companyName,
            '@baseType': 'customer',
            type: 'company',
            name: this.sanitizeDatabaseName(this.field1Data.companyName),
            companyName: this.sanitizeDatabaseName(this.field1Data.companyName),
            tradingName: this.sanitizeDatabaseName(this.field1Data.companyName),
            email: this.generateUniqueEmail(),
            parentCompany: this.company,
            rootName: this.company,
          };
          let parentCompany = await MLTAxiosInstance.post(
            `/partymanagement/individual/account/signup`,
            createParentCompany
          );
          this.field1Data.companyId = parentCompany.data.data._id;
        }
        let backendPayload = this.transformPayload({
          field1Data: this.field1Data,
          field2Data: this.field2Data,
          field3Data: this.field3Data,
          field4Data: this.field4Data,
          field5Data: this.field5Data,
          field6Data: this.field6Data,
        });
        backendPayload.createdBy = this.$cookies.get('user');
        let parentOrder = await UPCAxiosInstance.post(
          `productOrder/v1`,
          backendPayload
        );
        let reformedData = this.reverseTransformPayloadDynamic(
          parentOrder.data
        );
        console.log(
          backendPayload,
          company,
          contact,
          parentOrder,
          reformedData,
          'parentOrderPayload'
        );
        console.log(
          this.field1Data,
          this.mapToTMF648(this.example_data[0].pricingTableData, parentOrder),
          'Post for Site Data and parentOrder'
        );
        for (let i = 0; i < this.example_data.length; i++) {
          let { pricingTableData, ...site } = this.example_data[i];
          let subOrderTemplate = {
            externalId: [],
            priority: '1',
            description: '',
            category: 'Site order',
            orderDate: '2025-01-17T18:54:55Z',
            requestedStartDate: '2025-01-10T00:00:00Z',
            requestedCompletionDate: '2025-01-15T00:00:00Z',
            geographicAddress: [site],
            relatedParty: [],
            productOrderItem: this.mapToTMF648(pricingTableData).quoteItem,
            createdBy: this.$cookies.get('user'),
            orderRelationship: [
              {
                '@type': 'parentOrder',
                relationshipType: 'parent',
                id: parentOrder.data._id,
                href:
                  'https://tmf-upc-ghubgwxrcq-pd.a.run.app/productOrder/v1/' +
                  parentOrder.data._id,
                category: parentOrder.data.category,
                name: parentOrder.data.companyName,
              },
            ],
            '@type': 'productOrder',
            isSubOrder: true,
          };
          let subOrder = await UPCAxiosInstance.post(
            `productOrder/v1`,
            subOrderTemplate
          );
          console.log(subOrderTemplate, 'suborderTemplate', subOrder);
        }
      } catch (error) {
        console.log(error);
      }
    },
    mapToTMF648(data) {
      console.log('mapped data', data);
      let idCounter = 1; // Initialize the ID counter

      const mappedData = data?.map((item) => {
        console.log(item, 'irtemmm');

        // Create the tmfObject without id initially
        const tmfObject = {
          offerId: item.offerId,
          offerType: item.offerType,
          bundleIndex: item?.bundleIndex,
          section: item?.section,
          address: item?.address,
          custom: item?.custom,
          notes: item?.notes,
          product_offering_name: item?.product_offering_name,
          description: item.description,
          unitOfMeasure: item.unitOfMeasure,
          additionalCharges: item.additionalCharges ?? [],
          '@type': 'QuoteItem',
          productSpecification: {
            ...item.productSpecification,
            '@type': 'ProductSpecification',
          },
          productOffering: {
            ...item.productOffering,
            '@type': 'ProductOffering',
          },
          productCharacteristic: item.productCharacteristic,
          prodSpecCharValueUse: item.prodSpecCharValueUse,
          prodSpecCharValue: item.prodSpecCharValue,
          discountResponse: item.discountResponse,
          supplierUnitCost: item.supplierUnitCost,
          unitMsrp: item.unitMsrp,
          skuid: item.skuid,
          rebate: item.rebate,
          action: 'new',
          quantity: item.quantity,
          duration: item.duration,
          billing_period_options: item.billing_period_options,
          billing_period: item.billing_period,
          priceType: item.priceType,
          discount: JSON.parse(
            JSON.stringify(item?.discount ?? { value: 0, type: '0%' })
          ),
          state: '',
          product: {
            '@type': 'product',
            productSpecification: {
              ...item.productSpecification,
              '@type': 'ProductSpecification',
            },
            productOffering: {
              ...item.productOffering,
              '@type': 'ProductOffering',
            },
            productCharacteristic: item.productCharacteristic,
          },
          quoteItemPrice: [
            {
              description: item.description,
              name: '',
              priceType: item.intangible === true ? 'Recurring' : 'One time',
              recurringChargePeriodLength:
                item.intangible === true ? item.duration : 0,
              recurringChargePeriod: item.billing_period,
              price: {
                taxRate: 0,
                dutyFreeAmount: {
                  unit: this.$store.getters.defaultSetting?.currency?.unit,
                  value: item.rate,
                },
                taxIncludedAmount: {
                  unit: this.$store.getters.defaultSetting?.currency?.unit,
                  value: item.lineTotal,
                },
              },
            },
          ],
        };

        // Add id only if section is false
        if (!item.section) {
          tmfObject.id = idCounter.toString();
          idCounter++; // Increment the ID counter for the next item
        }

        // Check if discount is present
        if (item?.discount?.value > 0) {
          tmfObject.quoteItemPrice[0].priceAlteration = [
            {
              applicationDuration: 0,
              description: '',
              name: `${item.discount.name}`,
              priceType: item.intangible === true ? 'Recurring' : 'One time',
              recurringChargePeriodLength: item.intangible === true ? 1 : 0,
              priority: 1,
              recurringChargePeriod: item.billing_period,
              price: {
                taxRate: item.tax,
                percentage:
                  item.discount.type === '%'
                    ? item.discount.value + item.discount.type
                    : this.$store.getters.defaultSetting?.currency?.symbol +
                      item.discount.value,
                dutyFreeAmount: {
                  unit: this.$store.getters.defaultSetting?.currency?.unit,
                  value: item.rate,
                },
                taxIncludedAmount: {
                  unit: this.$store.getters.defaultSetting?.currency?.unit,
                  value: item.lineTotal,
                },
              },
            },
          ];
        }

        return tmfObject;
      });

      // const quoteTotalPrice = [{
      //   price: {
      //     "dutyFreeAmount": {
      //       "unit": "GBP",
      //       "value": beforeTotal
      //     }},
      //     "taxIncludedAmount": {
      //       "unit": "GBP",
      //       "value": tax
      //     },
      //     "priceAlteration": discount,
      //     "quoteTotal": {
      //       "unit": "GBP",
      //       "value": afterTotal
      //     }
      // }]
      const obj = {};
      // obj.quoteTotalPrice = quoteTotalPrice
      obj.quoteItem = mappedData;
      return obj;
    },
    mapFromTMF648(tmfData) {
      const rawItems = tmfData?.map((item) => {
        let newAdditionalCharges;
        let newPrice;
        if (
          item.matchedOffer !== undefined &&
          item?.matchedOffer?.prodSpecCharValue.length > 0
        ) {
          newAdditionalCharges = item?.matchedOffer?.additionalCharges;
          newPrice =
            item?.matchedOffer?.prodSpecCharValue[0]?.tiers[0]?.price?.value?.toString();
        } else {
          newAdditionalCharges = item?.additionalCharges;
          newPrice =
            item?.quoteItemPrice[0]?.price?.dutyFreeAmount?.value?.toString();
        }

        const rawItem = {
          section: item?.section ? true : false,
          custom: item?.custom ? true : false,
          address: item?.address ? true : false,
          productSpecification: {
            ...item.productSpecification,
            '@type': 'ProductSpecification',
          },
          productOffering: {
            ...item.productOffering,
            '@type': 'ProductOffering',
          },
          offerId: item.offerId,
          productCharacteristic: item?.productCharacteristic,
          prodSpecCharValueUse: item?.prodSpecCharValueUse,
          prodSpecCharValue: item?.prodSpecCharValue,
          product: item?.product,
          additionalCharges: newAdditionalCharges || [],
          offerType: item.offerType,
          bundleIndex: item?.bundleIndex,
          product_offering_name: item.address
            ? item?.product_offering_name
            : item.section
            ? item?.product_offering_name
            : item?.custom
            ? item?.product_offering_name
            : item.productOffering?.name,
          unit_of_measure: item.section ? '' : 'Each', // Default value
          rate: newPrice,
          quantity: item?.quantity,
          skuid: item.skuid,
          discountResponse: item?.discountResponse,
          supplierUnitCost: item?.supplierUnitCost,
          unitMsrp: item?.unitMsrp,
          rebate: item?.rebate,
          duration: item?.duration,
          unitOfMeasure: item?.unitOfMeasure,
          billing_period: item?.billing_period,
          priceType: item?.priceType,
          billing_period_options: item?.billing_period_options,
          discount: item?.discount, // Default value
          amount: item?.quoteItemPrice[0]?.price?.dutyFreeAmount?.value,
          discount_type: '%', // Default value
          line_total:
            item?.quoteItemPrice[0]?.price?.taxIncludedAmount?.value?.toString(),
          tax: 0, // Default value
          notes: item?.notes || '', // Default value
          description: item?.description, // Default value
          commitment_length: item?.section ? '' : 1, // Default value
          // billing_period: item.quoteItemPrice[0]?.recurringChargePeriod,
          intangible: item?.quoteItemPrice[0]?.priceType === 'Recurring',
        };

        return rawItem;
      });

      return rawItems;
    },
    deleteSite(index) {
      // Make sure example_data is properly updated reactively
      // this.example_data.splice(index, 1); // This removes the item at the given index
      this.deleteSites = true;
      this.field1Data.numberOfSites = this.example_data.length;
      this.delete_modal_active2 = true;
      this.delete_name = this.example_data[index]?.siteName;
      this.deleteIndexValue = index;
    },
    deleteProduct(index) {
      this.example_data_product.splice(index, 1);
    },
    closeRight() {
      this.show_right1 = false;
    },
    closeRight1() {
      this.show_right2 = false;
    },
    closeSiteDetails(index, i) {
      console.log(i);
      // this.siteDetailsEmpty = JSON.parse(
      //   JSON.stringify(this.temporaryleadSiteDetails)
      // );
      this.isSaveButtonClicked = true;
      // setTimeout(() => {
      // if (this.showSiteInformationModal) {
      console.log(
        this.chosenSiteIndex,
        'Forcing showSiteInformationModal to false',
        index
      );
      //     }
      // }, 50);
      if (this.chosenSiteIndex != index) {
        this.openSiteDetails(i, index);
      } else if (this.chosenSiteIndex == index) {
        this.chosenSiteIndex = null;
        // this.showSiteInformationModal = false;
        // this.showAllSitesModal = true;
      }
      this.example_data_site = this.example_data[this.chosenSiteIndex];
    },
    async openSiteDetails(i, index) {
      console.log(index, 'openSiteDetails', i);
      this.chosenSiteIndex = i - 1;
      this.showSiteInformationModal = true;
      this.siteSchema;
      this.siteDetailsEmpty;

      // await this.checkAddressValidation(this.currentSiteObject?.siteAddress);
      // await this.getSiteInputFocusOut(
      //   this.currentSiteObject?.sfdcId ? this.currentSiteObject?.sfdcId : '',
      //   { fieldName: 'SFdCID' }
      // );
      // await  this.getSiteInputFocusOut(this.currentSiteObject?.networkId ? this.currentSiteObject?.networkId : '' , {'fieldName':'networkId'})
      // this.characteristicpreval.characteristic = this.siteDetails[this.chosenSiteIndex].contactMedium[0].characteristic
      // console.log(this.characteristicpreval.characteristic,"Site Details",index,"leadPayload?.relatedParty[chosenSiteIndex]?.name")
      // console.log( this.siteDetails,"chosenSiteIndex",this.leadPayload?.relatedParty)
      // this.siteDetailsEmpty = JSON.parse(JSON.stringify(this.temporaryleadSiteDetails))
    },
    storeDataFromField1(val) {
      console.log(
        'storeDataFromField6',
        this.selectedCompanyNameFromField1,
        this.field1Data.companyName,
        val
      );
      this.field1Data = val;
      let data = {
        address: '',
        name: '',
        units: '',
        description: null,
        siteAddress: [
          {
            characteristic: {
              city: '',
              unit: '',
              country: '',
              state: '',
              postalCode: '',
              postalCodePrefix: '',
              stateOrProvince: '',
              latitude: '',
              longitude: '',
              street1: '',
              street2: '',
              fsa: '',
              description: '',
              street: '',
              default: true,
              '@type': '',
              numberOfUnits: '',
              stateCode: 'ON',
            },
            mediumType: 'postalAddress',
          },
        ],
        siteName: '',
        firstOccupancyDate: '',
        equipmentNetworkId: null,
        marketSegment: '',
        networkId: null,
        networkManager: {
          value: '',
          email: '',
          id: '',
        },
        numberOfUnits: '',
        sfdcId: '',
        status: null,
      }; // Data to add if needed
      console.log(val.numberOfSites, 'sitesnumber');
      let targetCount = val.numberOfSites; // Desired total number of items
      if (targetCount != 0) {
        // Adjust the array to match the target count
        if (this.example_data.length > targetCount) {
          // Trim excess items
          this.example_data = this.example_data.slice(0, targetCount);
        } else if (this.example_data.length < targetCount) {
          // Add additional items
          this.example_data = [
            ...this.example_data,
            ...Array(targetCount - this.example_data.length).fill(data),
          ];
        }
      }

      console.log(this.example_data);
      this.isField1CompanySelected =
        this.selectedCompanyNameFromField1 === this.field1Data.companyName;
      // this.selectedCompanyNameFromField1 === this.field1Data.companyName;
    },
    storeDataFromField5(val) {
      console.log(
        'field5Datax',
        this.isPayloadChanged(this.field5Data, val),
        val
      );
      if (this.isPayloadChanged(this.field5Data, val)) {
        if (
          val.copyPropertyCompanyDetails !== '' &&
          val.copyPropertyCompanyDetails.length > 0 &&
          val.copyPropertyCompanyDetails[0].checked == true
        ) {
          val.companyName = this.field1Data.companyName;
          val.firstName = this.field3Data.firstName;
          val.lastName = this.field3Data.lastName;
          val.emailId = this.field3Data.emailId;
          val.phoneNumber = this.field3Data.phoneNumber;
          this.schema.constructionCompanyInfo.schema.chooseContact =
            this.schema.contactInfo.schema.chooseContact;
          this.schema.constructionCompanyInfo.schema.copyPropertyCompanyDetails.options =
            val.copyPropertyCompanyDetails;
          this.schema.constructionCompanyInfo.schema = {
            ...this.schema.constructionCompanyInfo.schema,
          };

          this.field5Data = JSON.parse(JSON.stringify(this.field5Data));
        }
        this.field5Data = val;
        this.isField5CompanySelected =
          this.selectedCompanyNameFromField5 === this.field5Data.companyName;
      }
      console.log('nasi', this.field5ContactPayload, {
        title: val.title,
        firstName: val.firstName,
        lastName: val.lastName,
        emailId: val.emailId,
        phoneNumber: val.phoneNumber,
      });

      this.isField5ContactSelected = !this.isPayloadChanged(
        this.field5ContactPayload,
        {
          title: val.title,
          firstName: val.firstName,
          lastName: val.lastName,
          emailId: val.emailId,
          phoneNumber: val.phoneNumber,
        }
      );
    },
    storeDataFromField6(val) {
      console.log(
        'field6Datax',
        this.isPayloadChanged(this.field6Data, val),
        val
      );
      if (this.isPayloadChanged(this.field6Data, val)) {
        if (
          val.copyPropertyCompanyDetails !== '' &&
          val.copyPropertyCompanyDetails.length > 0 &&
          val.copyPropertyCompanyDetails[0].checked == true
        ) {
          val.companyName = this.field1Data.companyName;
          val.firstName = this.field3Data.firstName;
          val.lastName = this.field3Data.lastName;
          val.emailId = this.field3Data.emailId;
          val.phoneNumber = this.field3Data.phoneNumber;
          this.schema.requesterCompanyInfo.schema.chooseContact =
            this.schema.contactInfo.schema.chooseContact;
          this.schema.requesterCompanyInfo.schema.copyPropertyCompanyDetails.options =
            val.copyPropertyCompanyDetails;
          this.schema.requesterCompanyInfo.schema = {
            ...this.schema.requesterCompanyInfo.schema,
          };

          this.field6Data = JSON.parse(JSON.stringify(this.field6Data));
        }
        this.field6Data = val;
        this.isField6CompanySelected =
          this.selectedCompanyNameFromField6 === this.field6Data.companyName;
      }
      console.log('nasi', this.field6ContactPayload, {
        title: val.title,
        firstName: val.firstName,
        lastName: val.lastName,
        emailId: val.emailId,
        phoneNumber: val.phoneNumber,
      });

      this.isField6ContactSelected = !this.isPayloadChanged(
        this.field6ContactPayload,
        {
          title: val.title,
          firstName: val.firstName,
          lastName: val.lastName,
          emailId: val.emailId,
          phoneNumber: val.phoneNumber,
        }
      );
    },
    storeDataFromField2(val) {
      this.field2Data = val;
    },
    storeDataFromField3(val) {
      this.field3Data = val;
    },
    storeDataFromField4(val) {
      this.field4Data = val;
    },
    transformToCodeBlock(inputString) {
      // Split the input string by semicolons and line breaks to handle different parts
      let lines = inputString
        .split(/\s*;\s*|\n/)
        .map((line) => line.trim())
        .filter((line) => line.length > 0);

      let formattedString = '';
      let indentLevel = 1; // Start with 1 level of indentation for the first line

      // Function to add indentation
      const indent = (level) => '  '.repeat(level);

      lines.forEach((line) => {
        // Add indentation before each line
        if (line.includes('{')) {
          formattedString += indent(indentLevel) + line + '\n';
          indentLevel++;
        } else if (line.includes('}')) {
          indentLevel--;
          formattedString += indent(indentLevel) + line + '\n';
        } else {
          formattedString += indent(indentLevel) + line + ';\n';
        }
      });

      // Return the final formatted string wrapped in backticks
      return `${formattedString}`;
    },
    async emittedLookupDataForRemapFromField1([
      dataPayloadDup,
      schemaToBeMapped,
      schema,
    ]) {
      if (dataPayloadDup && schemaToBeMapped) {
        let dataPayload = JSON.parse(JSON.stringify(dataPayloadDup));
        console.log(
          schema,
          dataPayloadDup,
          'emittedLookupDataForRemapFromField1',
          schemaToBeMapped,
          'dataPayload',
          dataPayload
        );
        console.log(
          dataPayload?.relatedParty?.filter((item) => item['@type'] === 'site'),
          'paaayloadd'
        );
        // const mappedData = dataPayload?.relatedParty
        //   ?.filter((item) => item['@type'] === 'site')
        //   .map((site) => ({
        //     address:
        //       site.contactMedium?.[0]?.characteristic?.description || null,
        //     name: site.name || null,
        //     units: site.numberOfUnits || null,
        //     description: site.description || null,
        //     siteAddress: site.contactMedium || null,
        //     siteName: site.name || null,
        //     firstOccupancyDate: site.firstOccupancyDate || null,
        //     equipmentNetworkId: site.equipmentNetworkId || null,
        //     marketSegment: site.marketSegment || null,
        //     networkId: site.networkId || null,
        //     networkManager: site.networkManager || null,
        //     numberOfUnits: site.numberOfUnits || null,
        //     sfdcId: site.sfdcId || null,
        //     status: site.status || null,
        //   }));
        // this.example_data = mappedData;
        this.selectedLeadAccountRef = dataPayload?.accountRef;
        console.log(this.example_data, 'example_dataaaa');

        for (let i = 0; i < schemaToBeMapped.length; i++) {
          let schemaObjName = schemaToBeMapped[i].LHS;
          let schemaValue = schemaToBeMapped[i].RHS;
          console.log('regexData:', !schemaValue.includes('api'));

          if (!schemaValue.includes('api')) {
            let changeRhsValue = 'payload';
            const firstDotIndex = schemaValue.indexOf('.');
            console.log(schemaValue, 'dataPayloaddataPayload', dataPayload);
            if (firstDotIndex !== -1) {
              let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
              console.log(schemaObjName, 'newRHS', newRHS);
              newRHS;
              let stringifiedDataPayload = JSON.parse(
                JSON.stringify(dataPayload)
              );

              let result = this.evaluateRHS(newRHS, stringifiedDataPayload);
              console.log('remappped data', result);
              if (
                schema['companyName'] != undefined &&
                schema['companyName'].value != undefined
              ) {
                schema['companyName'].value = dataPayload.name;
                this.selectedCompanyNameFromField1 = dataPayload.name;
                this.isField1CompanySelected = true;
              }

              // if (result == '' && schemaObjName == 'numberOfUnits') {
              //   console.log(
              //     'this.leadSchema[schemaObjName].value 3 ',
              //     this.siteSchemaWithValue[schemaObjName]
              //   );
              //   this.siteSchemaWithValue[schemaObjName].value = '';
              //   this.siteSchemaWithValue.isRemapData = false;
              //   // this.siteSchema.isRemapData = false
              // }

              if (result) {
                console.log(
                  'this.leadSchema[schemaObjName].value',
                  schemaObjName
                );
                if (schema[schemaObjName]) {
                  console.log(
                    'this.leadSchema[schemaObjName].value 1 ',
                    schema[schemaObjName]
                  );
                  schema[schemaObjName].value = result;
                  schema.isRemapData = false;
                }
                // else if (this.contactSchemaWithValue[schemaObjName]) {
                //   console.log(
                //     'this.leadSchema[schemaObjName].value 2 ',
                //     this.contactSchemaWithValue[schemaObjName]
                //   );
                //   this.contactSchemaWithValue[schemaObjName].value = result;
                //   if (schemaObjName == 'email') {
                //     this.selectedLeadContactEmail = result;
                //     this.emailArrayForContacts =
                //       this.emailArrayForContacts.filter(
                //         (email) => email !== this.selectedLeadContactEmail
                //       );
                //     // console.log(
                //     //   this.selectedLeadContactEmail,
                //     //   "this.emailArrayForContacts lookup",
                //     //   this.emailArrayForContacts
                //     // );
                //   }
                //   this.contactSchemaWithValue.isRemapData = false;
                // } else if (this.siteSchema[schemaObjName]) {
                //   console.log(
                //     'this.leadSchema[schemaObjName].value 3 ',
                //     this.siteSchemaWithValue[schemaObjName]
                //   );
                //   this.siteSchemaWithValue[schemaObjName].value = result;
                //   this.siteSchemaWithValue.isRemapData = false;
                //   // this.siteSchema.isRemapData = false
                // }
                // delete schema['name']
                // delete schema[schemaObjName]
                // console.log(schema,"this.schema",this.customFieldLeadSchema)
                // return
              }
            }
          } else {
            // let newRhs = `${schemaValue.substring(1)}`;
            let newRhs = `${schemaValue}`;
            newRhs = newRhs.replace('let', '; let');
            newRhs = newRhs.replace('return', '; return');
            console.log(this.filterFunctionBody, newRhs, 'regexData:');
            // Use Function constructor to evaluate the expression safely
            // eslint-disable-next-line no-new-func
            const asyncFunction = new Function(
              `return (async function() { ${newRhs} }).call(this);`
            );

            // Execute the function
            try {
              const filteredData = await asyncFunction.call(this);
              console.log('regexData:', filteredData);
              schema[schemaObjName].value = filteredData;
              schema.isRemapData = false;
            } catch (error) {
              console.error('Error executing dynamic function:', error);
            }
          }
        }

        // this.filteredSchemaForField1;
      }
      console.log(dataPayloadDup, 'dataPayloadDup.contactMedium');
      // setTimeout(async () => {
      let address = dataPayloadDup.contactMedium.filter(
        (item) => item.mediumType === 'billingLocation'
      )[0];
      if (address == undefined || address.characteristic == undefined) {
        address = {
          mediumType: 'billingLocation',
          characteristic: {
            street: '',
            '@type': '',
            city: '',
            country: '',
            default: false,
            description: '',
            numberOfUnits: '',
            postalCode: '',
            postalCodePrefix: '',
            fsa: '',
            state: '',
            stateOrProvince: '',
            street1: '',
            street2: '',
            unit: '',
            latitude: '',
            longitude: '',
          },
        };
      }
      address.characteristic.stateOrProvince =
        address?.characteristic?.state || ' ';
      address.characteristic.street = address?.characteristic?.address1 || '';
      this.field4Data.billingAddress = [];
      this.field4Data.billingAddress.push({
        characteristic: address.characteristic,
      });
      console.log('filteredSchemaForField2', this.field4Data);
      // this.filteredSchemaForField4;
      this.schema.billingInfo.schema = { ...this.schema.billingInfo.schema };

      this.field4Data = JSON.parse(JSON.stringify(this.field4Data));
      // this.field4Key += 1;
      this.field3Data.chooseContact = '';
      this.schema.contactInfo.schema['chooseContact'].options = [];
      this.schema.contactInfo.schema['chooseContact'].value = '';
      // this.filteredSchemaForField3;
      this.schema.contactInfo.schema = {
        ...this.schema.contactInfo.schema,
      };
      let relatedPartyArray = dataPayloadDup.relatedParty.filter((i) => {
        return i['@type'] === 'individual';
      });
      // for (const i of dataPayloadDup.relatedParty) {
      //   const con = await MLTAxiosInstance.get(
      //     `/partymanagement/individual/myinfo/${
      //       i.id
      //     }?company=${'developerHalleyx'}`
      //   );
      //   if (con.data.pipeLine == undefined) {
      //     con.data.newRole = con?.data?.role?.name;
      //     relatedPartyArray.push(con.data);
      //   }
      //   console.log(
      //     'relatedPartyArray',
      //     relatedPartyArray,
      //     con.data,
      //     con.data.pipeLine
      //   );
      // }

      console.log('relatedPartyArray', relatedPartyArray);
      // ---------------------------
      // Remap field 3
      if (relatedPartyArray !== undefined && relatedPartyArray.length > 0) {
        relatedPartyArray[0].default = true;
        console.log('remap3', relatedPartyArray[0]);
        this.field3Data.chooseContact = relatedPartyArray[0].name;
        this.field3Data.title = relatedPartyArray[0].title;
        this.field3Data.firstName = relatedPartyArray[0].firstName;
        this.field3Data.lastName = relatedPartyArray[0].lastName;
        this.field3Data.emailId = relatedPartyArray[0].contactMedium.filter(
          (item) => {
            return item.mediumType == 'email';
          }
        )[0].characteristic.emailAddress;
        let phoneNumber = relatedPartyArray[0].contactMedium.filter((item) => {
          return item.mediumType == 'phone';
        })[0].characteristic.phoneNumber;
        this.field3Data.phoneNumber = JSON.parse(JSON.stringify(phoneNumber));
        console.log('rozphonenummber', this.field3Data.phoneNumber);

        // ---------------------------
        // setTimeout(() => {
        this.schema.contactInfo.schema['chooseContact'].options =
          relatedPartyArray;
        // this.filteredSchemaForField3;
        this.schema.contactInfo.schema = {
          ...this.schema.contactInfo.schema,
        };

        this.field3Data = JSON.parse(JSON.stringify(this.field3Data));
      }

      // this.field1Key += 1;
      // }, 3000);
      // this.schema = { ...this.schema };

      this.field1Data = { ...this.field1Data };
      // }, 1300);
    },
    async emittedLookupDataForRemapFromField3([
      dataPayloadDup,
      schemaToBeMapped,
      schema,
    ]) {
      if (dataPayloadDup && schemaToBeMapped) {
        let dataPayload = JSON.parse(JSON.stringify(dataPayloadDup));
        console.log(
          schema,
          dataPayloadDup,
          'emittedLookupDataForRemapFromField1',
          schemaToBeMapped,
          'dataPayload',
          dataPayload
        );

        for (let i = 0; i < schemaToBeMapped.length; i++) {
          let schemaObjName = schemaToBeMapped[i].LHS;
          let schemaValue = schemaToBeMapped[i].RHS;
          let changeRhsValue = 'payload';
          const firstDotIndex = schemaValue.indexOf('.');
          console.log(schemaValue, 'dataPayloaddataPayload', dataPayload);
          if (firstDotIndex !== -1) {
            let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
            console.log(schemaObjName, 'newRHS', newRHS);
            newRHS;
            let stringifiedDataPayload = JSON.parse(
              JSON.stringify(dataPayload)
            );

            let result = this.evaluateRHS(newRHS, stringifiedDataPayload);
            console.log('remappped data', result);
            // if (
            //   schema['companyName'] != undefined &&
            //   schema['companyName'].value != undefined
            // ) {
            //   schema['companyName'].value = dataPayload.name;
            //   this.selectedCompanyNameFromField1 = dataPayload.name;
            // }

            // if (result == '' && schemaObjName == 'numberOfUnits') {
            //   console.log(
            //     'this.leadSchema[schemaObjName].value 3 ',
            //     this.siteSchemaWithValue[schemaObjName]
            //   );
            //   this.siteSchemaWithValue[schemaObjName].value = '';
            //   this.siteSchemaWithValue.isRemapData = false;
            //   // this.siteSchema.isRemapData = false
            // }

            if (result) {
              console.log(
                'this.leadSchema[schemaObjName].value',
                schemaObjName
              );
              if (schema[schemaObjName]) {
                console.log(
                  'this.leadSchema[schemaObjName].value 1 ',
                  schema[schemaObjName]
                );
                schema[schemaObjName].value = result;
                schema.isRemapData = false;
              }
              // else if (this.contactSchemaWithValue[schemaObjName]) {
              //   console.log(
              //     'this.leadSchema[schemaObjName].value 2 ',
              //     this.contactSchemaWithValue[schemaObjName]
              //   );
              //   this.contactSchemaWithValue[schemaObjName].value = result;
              //   if (schemaObjName == 'email') {
              //     this.selectedLeadContactEmail = result;
              //     this.emailArrayForContacts =
              //       this.emailArrayForContacts.filter(
              //         (email) => email !== this.selectedLeadContactEmail
              //       );
              //     // console.log(
              //     //   this.selectedLeadContactEmail,
              //     //   "this.emailArrayForContacts lookup",
              //     //   this.emailArrayForContacts
              //     // );
              //   }
              //   this.contactSchemaWithValue.isRemapData = false;
              // } else if (this.siteSchema[schemaObjName]) {
              //   console.log(
              //     'this.leadSchema[schemaObjName].value 3 ',
              //     this.siteSchemaWithValue[schemaObjName]
              //   );
              //   this.siteSchemaWithValue[schemaObjName].value = result;
              //   this.siteSchemaWithValue.isRemapData = false;
              //   // this.siteSchema.isRemapData = false
              // }
              // delete schema['name']
              // delete schema[schemaObjName]
              // console.log(schema,"this.schema",this.customFieldLeadSchema)
              // return
            }
          }
        }
      }

      // let address = dataPayloadDup.contactMedium.filter(
      //   (item) => item.mediumType === 'billingLocation'
      // )[0];
      // address.characteristic.stateOrProvince = address?.characteristic?.state;
      // address.characteristic.street = address?.characteristic?.address1;
      // this.field4Data.billingAddress = [];
      // this.field4Data.billingAddress.push({
      //   characteristic: address.characteristic,
      // });
      // console.log('filteredSchemaForField2', this.field4Data);
      // this.filteredSchemaForField4;
      // this.field4Key += 1;

      // let relatedPartyArray = [];
      // for (const i of dataPayloadDup.relatedParty) {
      //   const con = await MLTAxiosInstance.get(
      //     `/partymanagement/individual/myinfo/${
      //       i.id
      //     }?company=${'developerHalleyx'}`
      //   );
      //   if (con.data.pipeLine == undefined) {
      //     con.data.newRole = con.data.role.name;
      //     relatedPartyArray.push(con.data);
      //   }
      //   console.log(
      //     'relatedPartyArray',
      //     relatedPartyArray,
      //     con.data,
      //     con.data.pipeLine
      //   );
      // }

      // console.log('relatedPartyArray', relatedPartyArray);

      // // setTimeout(() => {
      // this.schema.contactInfo.schema['chooseContact'].options =
      //   relatedPartyArray;
      // this.field3Key += 1;
      // }, 3000);
    },
    async emittedLookupDataForRemapFromField5([
      dataPayloadDup,
      schemaToBeMapped,
      schema,
    ]) {
      if (dataPayloadDup && schemaToBeMapped) {
        let dataPayload = JSON.parse(JSON.stringify(dataPayloadDup));
        console.log(
          schema,
          dataPayloadDup,
          'emittedLookupDataForRemapFromField1',
          schemaToBeMapped,
          'dataPayload',
          dataPayload
        );
        for (let i = 0; i < schemaToBeMapped.length; i++) {
          let schemaObjName = schemaToBeMapped[i].LHS;
          let schemaValue = schemaToBeMapped[i].RHS;
          let changeRhsValue = 'payload';
          const firstDotIndex = schemaValue.indexOf('.');
          console.log(schemaValue, 'dataPayloaddataPayload', dataPayload);
          if (firstDotIndex !== -1) {
            let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
            console.log(schemaObjName, 'newRHS', newRHS);
            newRHS;
            let stringifiedDataPayload = JSON.parse(
              JSON.stringify(dataPayload)
            );

            let result = this.evaluateRHS(newRHS, stringifiedDataPayload);
            console.log('remappped data', result);
            if (
              schema['companyName'] != undefined &&
              schema['companyName'].value != undefined
            ) {
              schema['companyName'].value = dataPayload.name;
              this.selectedCompanyNameFromField5 = dataPayload.name;
            }

            // if (result == '' && schemaObjName == 'numberOfUnits') {
            //   console.log(
            //     'this.leadSchema[schemaObjName].value 3 ',
            //     this.siteSchemaWithValue[schemaObjName]
            //   );
            //   this.siteSchemaWithValue[schemaObjName].value = '';
            //   this.siteSchemaWithValue.isRemapData = false;
            //   // this.siteSchema.isRemapData = false
            // }

            if (result) {
              console.log(
                'this.leadSchema[schemaObjName].value',
                schemaObjName
              );
              if (schema[schemaObjName]) {
                console.log(
                  'this.leadSchema[schemaObjName].value 1 ',
                  schema[schemaObjName]
                );
                schema[schemaObjName].value = result;
                schema.isRemapData = false;
              }
              // else if (this.contactSchemaWithValue[schemaObjName]) {
              //   console.log(
              //     'this.leadSchema[schemaObjName].value 2 ',
              //     this.contactSchemaWithValue[schemaObjName]
              //   );
              //   this.contactSchemaWithValue[schemaObjName].value = result;
              //   if (schemaObjName == 'email') {
              //     this.selectedLeadContactEmail = result;
              //     this.emailArrayForContacts =
              //       this.emailArrayForContacts.filter(
              //         (email) => email !== this.selectedLeadContactEmail
              //       );
              //     // console.log(
              //     //   this.selectedLeadContactEmail,
              //     //   "this.emailArrayForContacts lookup",
              //     //   this.emailArrayForContacts
              //     // );
              //   }
              //   this.contactSchemaWithValue.isRemapData = false;
              // } else if (this.siteSchema[schemaObjName]) {
              //   console.log(
              //     'this.leadSchema[schemaObjName].value 3 ',
              //     this.siteSchemaWithValue[schemaObjName]
              //   );
              //   this.siteSchemaWithValue[schemaObjName].value = result;
              //   this.siteSchemaWithValue.isRemapData = false;
              //   // this.siteSchema.isRemapData = false
              // }
              // delete schema['name']
              // delete schema[schemaObjName]
              // console.log(schema,"this.schema",this.customFieldLeadSchema)
              // return
            }
          }
        }
      }

      // let address = dataPayloadDup.contactMedium.filter(
      //   (item) => item.mediumType === 'billingLocation'
      // )[0];
      // address.characteristic.stateOrProvince = address?.characteristic?.state;
      // address.characteristic.street = address?.characteristic?.address1;
      // this.field4Data.billingAddress = [];
      // this.field4Data.billingAddress.push({
      //   characteristic: address.characteristic,
      // });
      // console.log('filteredSchemaForField2', this.field4Data);
      // this.filteredSchemaForField4;
      // this.field4Key += 1;

      // this.field3Key += 1;
      // }, 3000);
      let relatedPartyArray = [];
      for (const i of dataPayloadDup.relatedParty) {
        const con = await MLTAxiosInstance.get(
          `/partymanagement/individual/myinfo/${
            i.id
          }?company=${'developerHalleyx'}`
        );
        if (con.data.pipeLine == undefined) {
          con.data.newRole = con?.data?.role?.name;
          relatedPartyArray.push(con.data);
        }
        console.log(
          'relatedPartyArray',
          relatedPartyArray,
          con.data,
          con.data.pipeLine
        );
      }

      console.log('relatedPartyArray', relatedPartyArray);
      relatedPartyArray = relatedPartyArray.filter((i) => {
        return i.newRole !== 'Account executive';
      });
      // setTimeout(() => {
      if (relatedPartyArray !== undefined && relatedPartyArray.length > 0) {
        relatedPartyArray[0].default = true;
        this.schema.constructionCompanyInfo.schema['chooseContact'].options =
          relatedPartyArray;
        this.field5Data.companyId = dataPayloadDup._id;
        this.field5Data.contactId = relatedPartyArray[0]._id;
        this.isField5ContactSelected = true;
        this.field5ContactPayload = {
          title: relatedPartyArray[0].title,
          firstName: relatedPartyArray[0].firstName,
          lastName: relatedPartyArray[0].lastName,
          emailId: relatedPartyArray[0].contactMedium.filter((item) => {
            return item?.mediumType == 'email';
          })[0].characteristic.emailAddress,
          phoneNumber: relatedPartyArray[0].contactMedium.filter((item) => {
            return item?.mediumType == 'phone';
          })[0].characteristic.phoneNumber,
        };
        this.field5Data.title = relatedPartyArray[0].title;
        this.field5Data.firstName = relatedPartyArray[0].firstName || '';
        this.field5Data.lastName = relatedPartyArray[0].lastName || '';
        this.field5Data.emailId = relatedPartyArray[0].contactMedium.filter(
          (item) => {
            return item?.mediumType == 'email';
          }
        )[0].characteristic.emailAddress;
        this.field5Data.phoneNumber = relatedPartyArray[0].contactMedium.filter(
          (item) => {
            return item?.mediumType == 'phone';
          }
        )[0].characteristic.phoneNumber;
        this.schema.constructionCompanyInfo.schema = {
          ...this.schema.constructionCompanyInfo.schema,
        };
      }
      this.field5Data = JSON.parse(JSON.stringify(this.field5Data));
    },
    async emittedLookupDataForRemapFromField6([
      dataPayloadDup,
      schemaToBeMapped,
      schema,
    ]) {
      if (dataPayloadDup && schemaToBeMapped) {
        let dataPayload = JSON.parse(JSON.stringify(dataPayloadDup));
        console.log(
          schema,
          dataPayloadDup,
          'emittedLookupDataForRemapFromField1',
          schemaToBeMapped,
          'dataPayload',
          dataPayload
        );
        for (let i = 0; i < schemaToBeMapped.length; i++) {
          let schemaObjName = schemaToBeMapped[i].LHS;
          let schemaValue = schemaToBeMapped[i].RHS;
          let changeRhsValue = 'payload';
          const firstDotIndex = schemaValue.indexOf('.');
          console.log(schemaValue, 'dataPayloaddataPayload', dataPayload);
          if (firstDotIndex !== -1) {
            let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
            console.log(schemaObjName, 'newRHS', newRHS);
            newRHS;
            let stringifiedDataPayload = JSON.parse(
              JSON.stringify(dataPayload)
            );

            let result = this.evaluateRHS(newRHS, stringifiedDataPayload);
            console.log('remappped data', result);
            if (
              schema['companyName'] != undefined &&
              schema['companyName'].value != undefined
            ) {
              schema['companyName'].value = dataPayload.name;
              this.selectedCompanyNameFromField6 = dataPayload.name;
            }

            // if (result == '' && schemaObjName == 'numberOfUnits') {
            //   console.log(
            //     'this.leadSchema[schemaObjName].value 3 ',
            //     this.siteSchemaWithValue[schemaObjName]
            //   );
            //   this.siteSchemaWithValue[schemaObjName].value = '';
            //   this.siteSchemaWithValue.isRemapData = false;
            //   // this.siteSchema.isRemapData = false
            // }

            if (result) {
              console.log(
                'this.leadSchema[schemaObjName].value',
                schemaObjName
              );
              if (schema[schemaObjName]) {
                console.log(
                  'this.leadSchema[schemaObjName].value 1 ',
                  schema[schemaObjName]
                );
                schema[schemaObjName].value = result;
                schema.isRemapData = false;
              }
              // else if (this.contactSchemaWithValue[schemaObjName]) {
              //   console.log(
              //     'this.leadSchema[schemaObjName].value 2 ',
              //     this.contactSchemaWithValue[schemaObjName]
              //   );
              //   this.contactSchemaWithValue[schemaObjName].value = result;
              //   if (schemaObjName == 'email') {
              //     this.selectedLeadContactEmail = result;
              //     this.emailArrayForContacts =
              //       this.emailArrayForContacts.filter(
              //         (email) => email !== this.selectedLeadContactEmail
              //       );
              //     // console.log(
              //     //   this.selectedLeadContactEmail,
              //     //   "this.emailArrayForContacts lookup",
              //     //   this.emailArrayForContacts
              //     // );
              //   }
              //   this.contactSchemaWithValue.isRemapData = false;
              // } else if (this.siteSchema[schemaObjName]) {
              //   console.log(
              //     'this.leadSchema[schemaObjName].value 3 ',
              //     this.siteSchemaWithValue[schemaObjName]
              //   );
              //   this.siteSchemaWithValue[schemaObjName].value = result;
              //   this.siteSchemaWithValue.isRemapData = false;
              //   // this.siteSchema.isRemapData = false
              // }
              // delete schema['name']
              // delete schema[schemaObjName]
              // console.log(schema,"this.schema",this.customFieldLeadSchema)
              // return
            }
          }
        }
      }

      // let address = dataPayloadDup.contactMedium.filter(
      //   (item) => item.mediumType === 'billingLocation'
      // )[0];
      // address.characteristic.stateOrProvince = address?.characteristic?.state;
      // address.characteristic.street = address?.characteristic?.address1;
      // this.field4Data.billingAddress = [];
      // this.field4Data.billingAddress.push({
      //   characteristic: address.characteristic,
      // });
      // console.log('filteredSchemaForField2', this.field4Data);
      // this.filteredSchemaForField4;
      // this.field4Key += 1;

      // this.field3Key += 1;
      // }, 3000);
      let relatedPartyArray = [];
      for (const i of dataPayloadDup.relatedParty) {
        const con = await MLTAxiosInstance.get(
          `/partymanagement/individual/myinfo/${
            i.id
          }?company=${'developerHalleyx'}`
        );
        if (con.data.pipeLine == undefined) {
          con.data.newRole = con?.data?.role?.name;
          relatedPartyArray.push(con.data);
        }
        console.log(
          'relatedPartyArray',
          relatedPartyArray,
          con.data,
          con.data.pipeLine
        );
      }

      console.log('relatedPartyArray', relatedPartyArray);
      relatedPartyArray = relatedPartyArray.filter((i) => {
        return i.newRole !== 'Account executive';
      });
      // setTimeout(() => {
      if (relatedPartyArray !== undefined && relatedPartyArray.length > 0) {
        relatedPartyArray[0].default = true;
        this.schema.requesterCompanyInfo.schema['chooseContact'].options =
          relatedPartyArray;
        this.field6Data.companyId = dataPayloadDup._id;
        this.field6Data.contactId = relatedPartyArray[0]._id;
        this.isField6ContactSelected = true;
        this.field6ContactPayload = {
          title: relatedPartyArray[0].title,
          firstName: relatedPartyArray[0].firstName,
          lastName: relatedPartyArray[0].lastName,
          emailId: relatedPartyArray[0].contactMedium.filter((item) => {
            return item?.mediumType == 'email';
          })[0].characteristic.emailAddress,
          phoneNumber: relatedPartyArray[0].contactMedium.filter((item) => {
            return item?.mediumType == 'phone';
          })[0].characteristic.phoneNumber,
        };
        this.field6Data.title = relatedPartyArray[0].title;
        this.field6Data.firstName = relatedPartyArray[0].firstName;
        this.field6Data.lastName = relatedPartyArray[0].lastName;
        this.field6Data.emailId = relatedPartyArray[0].contactMedium.filter(
          (item) => {
            return item?.mediumType == 'email';
          }
        )[0].characteristic.emailAddress;
        this.field6Data.phoneNumber = relatedPartyArray[0].contactMedium.filter(
          (item) => {
            return item?.mediumType == 'phone';
          }
        )[0].characteristic.phoneNumber;
        this.schema.requesterCompanyInfo.schema = {
          ...this.schema.requesterCompanyInfo.schema,
        };
      }
      this.field6Data = JSON.parse(JSON.stringify(this.field6Data));
    },
    evaluateRHS(RHS, dataPayload) {
      this.dataPayloadForRemap = JSON.parse(JSON.stringify(dataPayload));
      console.log(
        RHS,
        dataPayload,
        'this.dataPayloadForRemap',
        this.dataPayloadForRemap
      );
      try {
        const cleanedRHS = RHS.replace(/payload/g, 'dataPayloadForRemap')
          .replace(/\n/g, ' ')
          .trim();
        console.log(
          this.dataPayloadForRemap,
          cleanedRHS,
          'dataPayloadForRemap',
          dataPayload
        );
        // Use Function constructor to evaluate the expression safely
        // eslint-disable-next-line no-new-func
        const result = new Function(
          'dataPayloadForRemap',
          `return ${cleanedRHS};`
        )(this.dataPayloadForRemap);
        return result;
      } catch (error) {
        console.error('Error evaluating RHS:', error);
        return null;
      }
    },
    async selectedValueFunc(val) {
      // this.tabData = this.section_data.main.filter((i) => {
      //   return i.name == val;
      // });
      // this.tabData = this.tabData[0].tabs;
      // if (this.tabData != undefined && this.tabData.length > 0) {
      //   this.selectedSubTab = this.tabData[0].value;
      // } else {
      //   this.selectedSubTab = '';
      // }
      this.selectedTab = val;
      if (val.toLowerCase() == 'overview') {
        this.tabData = [
          {
            name: 'Order information',
            value: 'order_information',
          },
          {
            name: 'Site details',
            value: 'site_details',
          },
          {
            name: 'Product details',
            value: 'product_details',
          },
        ];
        // this.selectedSubTab = this.tabData[0].value;
      }
    },
    findObjectByAttribute(obj, key, value) {
      // Base case: If the current object has the key with the specified value
      if (obj[key] === value) {
        this.aclNavigationData.push(obj);
        return obj;
      }

      // Recursive case: If obj is an object or array, search its children
      if (typeof obj === 'object' && obj !== null) {
        for (const childKey in obj) {
          const child = obj[childKey];

          // If child is an array, iterate over its elements
          if (Array.isArray(child)) {
            for (const element of child) {
              const result = this.findObjectByAttribute(element, key, value);
              if (result) {
                this.aclNavigationData.push(obj);

                return result;
              }
            }
          }
          // If child is an object, recurse into it directly
          else if (typeof child === 'object') {
            const result = this.findObjectByAttribute(child, key, value);
            if (result) {
              this.aclNavigationData.push(obj);

              return result;
            }
          }
        }
      }

      // Return null if the object is not found
      return null;
    },
    async addNewOffer($event, index) {
      let data = $event;
      this.product_index = index;
      this.selectedProductOfferingData = [];
      console.log('123', data, $event, index);
      this.company = this.$cookies.get('company');
      this.filterQuery.company = this.$cookies.get('company');
      const filterResultData2 = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQuery
      );
      // this.offering_data = filterResultData.data.data
      // console.log("raw offer", filterResultData);
      console.log('Offering data', filterResultData2.data.total);
      this.card_data_length = filterResultData2.data.total;
      console.log(
        filterResultData2.data.data,
        this.filterQuery,
        'filterResultData2.data.data...'
      );

      this.card_data = this.transformData(filterResultData2.data.data);
      console.log('card_data', this.card_data);
      this.card_data.forEach((i) => {
        this.getProductImageData(i);
      });
      this.cartItems = [];
      this.show_offering = true;
      this.show_side_panel = false;
      this.filterPrevalueDuplicate1 = JSON.parse(
        JSON.stringify(this.filterPrevalue)
      );
      this.filterPrevalueDuplicate2 = JSON.parse(
        JSON.stringify(this.filterPrevalue)
      );
      // let arr = [];
      // data?.forEach((i) => {
      //   arr.push(JSON.parse(JSON.stringify(i)));
      // });
      if (data !== undefined) {
        this.tempPricingTableData = JSON.parse(JSON.stringify(data));
      }
      console.log(this.tempPricingTableData, 'edit data');
    },
    async offeringFilterData(filterSelectedvalue, filterQuery) {
      filterSelectedvalue, filterQuery;
      this.filterPrevalue = this.transformFilterPayload(filterSelectedvalue);

      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      filterQuery.company = this.company;
      this.filterQuery['paginatedQuery'] = {
        rowsPerPage: this.externalRowsPerPage,
        currentPage: this.currentPage,
      };
      //  filterQuery['filterQuery']['"@baseType"'] = 'supplier'
      if (this.filtertagvalue.length != 0) {
        console.log('filter ku ulla');

        this.filterQuery = filterQuery;
        this.filterQuery['module'] = 'discountOffers';
        this.filterQuery.type = 'filter';
        if (this.searchvalue != '') {
          this.filterQuery['searchQuery'] = this.searchvalue;
          this.filterQuery['type'] = 'filter-search';
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.filterQuery
        );
        console.log(filterResultData, 'filterResultData.data.data');

        this.card_data_length = filterResultData.data.total;
        this.card_data = this.transformData(filterResultData.data.data);
        console.log('card _data', this.card_data);
        this.card_data.forEach((i) => {
          this.getProductImageData(i);
        });
        // this.getOfferData();
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
      } else {
        console.log('filter ku veliya');

        this.filterPrevalue = {};
        this.filterQuery.filterQuery = {};
        this.filterPrevalueDuplicate1 = {};
        this.filterPrevalueDuplicate2 = {};
        this.offeringcurrPage = 1;
        this.offeringrowsPerPage = 10;
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.filterQuery
        );
        this.card_data_length = filterResultData.data.total;
        this.card_data = this.transformData(filterResultData.data.data);
        console.log('card _data', this.card_data);
        this.card_data.forEach((i) => {
          this.getProductImageData(i);
        });
        console.log(filterResultData, 'filterResultData.data.data');
        // this.offeringdataCalling(
        //   this.offeringcurrPage,
        //   this.offeringrowsPerPage
        // );
        // this.getOfferData();
      }
      console.log(
        filterSelectedvalue,
        filterQuery,
        this.transformFilterPayload(filterSelectedvalue),
        this.filterPrevalue,
        'configure filter data'
      );
    },
    transformData(originalData) {
      console.log(originalData, 'og_data');
      return originalData.map((item) => {
        const {
          name,
          description,
          lifecycleStatus,
          category,
          productSpecification,
          prodSpecCharValue,
          _id,
          productOfferingPrice,
        } = item;
        console.log('offering data card', item);
        const updatedProdSpecCharValue =
          this.getUpdatedProductSpecCharValue(prodSpecCharValue);
        updatedProdSpecCharValue;
        const lastCategory =
          category.length > 0 ? category[category.length - 1] : '';

        const pricing = this.getPricing(productOfferingPrice);
        const oneTimePrice = this.calculateOneTimePrice(pricing);
        oneTimePrice;
        let billingPeriodOptions = null;
        billingPeriodOptions = [
          ...new Set(
            updatedProdSpecCharValue?.map((item) => item?.billingPeriod)
          ),
        ];
        let whole = '0';
        let fraction = '00';
        let newWhole, newFraction;

        if (item?.discountResponse?.price !== undefined) {
          [whole, fraction] = String(item.discountResponse?.price)?.split('.');
          newWhole = whole !== undefined ? whole : '0';
          newFraction = fraction !== undefined ? fraction : '00';
        } else if (!item.isBundle) {
          [whole, fraction] = String(
            item.updatedProdSpecCharValue[0]?.tiers[0]?.price?.value
          )?.split('.');
          newWhole = whole !== undefined ? whole : '0';
          newFraction = fraction !== undefined ? fraction : '00';
        } else {
          let items = item.productOfferingPrice.filter((item) => {
            return item.lifecycleStatus == 'Active';
          });
          console.log(items[0]?.tiers[0]?.price?.value, 'ganesh');

          [whole, fraction] = String(items[0]?.tiers[0]?.price?.value)?.split(
            '.'
          );
          newWhole = whole !== undefined ? whole : '0';
          newFraction = fraction !== undefined ? fraction : '00';
        }
        // let [whole, fraction] = String(
        //   item.updatedProdSpecCharValue[0]?.tiers[0]?.price?.value
        // )?.split(".");
        // let newWhole, newFraction;
        // newWhole = whole !== undefined ? whole : "0";
        // newFraction = fraction !== undefined ? fraction : "00";
        console.log('prechana', { newWhole, newFraction });
        return {
          offerId: item._id,
          unitOfMeasure: item.unitOfMeasure,
          additionalCharges: item.additionalCharges,
          img_url: updatedProdSpecCharValue[0]?.attachment,
          currency: updatedProdSpecCharValue[0]?.tiers[0]?.price?.unit,
          discountResponse: item.discountResponse,
          productOfferingPrice: item.productOfferingPrice,
          relatedParty: item.relatedParty,
          name: name || '',
          description: description || '',
          status: lifecycleStatus || '',
          category: lastCategory || '',
          section: false,
          offerType: item.offerType,
          custom: false,
          address: false,
          brand: productSpecification?.brand || '',
          duration: updatedProdSpecCharValue[0]?.commitmentLength || 1,
          quantity: 1,
          skuid: updatedProdSpecCharValue[0]?.id,
          max_quantity:
            updatedProdSpecCharValue[0]?.tiers[
              updatedProdSpecCharValue[0]?.tiers?.length - 1
            ]?.max,
          billing_period: updatedProdSpecCharValue[0]?.billingPeriod,
          priceType: updatedProdSpecCharValue[0]?.priceType,
          billing_period_options: billingPeriodOptions?.map((value) => ({
            name: value,
            value: value,
          })) || [{ name: 'Monthly', value: 'Monthly' }],
          id: _id || '',
          offering_type:
            productSpecification?.['@baseType']?.toLowerCase() || '',
          // pricing: pricing,
          pricing: updatedProdSpecCharValue[0]?.tiers[0]?.price?.value,
          prodSpecCharValue: updatedProdSpecCharValue,
          prodSpecCharValueUse: item.prodSpecCharValueUse,
          // price: oneTimePrice
          //   ? {
          //       whole: oneTimePrice.whole || "0",
          //       fraction: oneTimePrice.fraction || "00",
          //     }
          //   : { whole: "0", fraction: "00" },
          price: { whole: newWhole, fraction: newFraction },
          productSpecification: {
            ...item.productSpecification,
            '@type': 'ProductSpecification',
          },
          productOffering: {
            name: item?.name,
            id: item._id,
            '@type': 'ProductOffering',
            href: item.href,
          },
          productCharacteristic: item.prodSpecCharValueUse,
        };
      });
    },
    async getProductImageData(val) {
      if (
        val.prodSpecCharValue[0]?.attachment !== undefined &&
        val?.prodSpecCharValue[0]?.attachment?.length > 0
      ) {
        console.log(val, 'image data for products');
        const url = await UPCAxiosInstance.get(
          `/gcpupload/files/${val.prodSpecCharValue[0]?.attachment[0]?.name}`,
          {
            responseType: 'arraybuffer',
          }
        );
        // console.log(await this.bufferToDataURL(url.data), "urlll get");
        this.imageComponentKey += 1;
        val.prodSpecCharValue[0].attachment[0].image_url =
          await this.bufferToDataURL(url.data);
        // this.rawProfileUrlData = url.data;
      } else if (val.prodSpecCharValue[0] !== undefined) {
        val.prodSpecCharValue[0].attachment = [];
        val.prodSpecCharValue[0].attachment.push({
          image_url: '',
        });
      }
    },
    getPricing(prices) {
      console.log(prices, 'card_data');
      if (!prices || prices.length === 0) return [];

      const priceMap = {};
      prices.forEach((price) => {
        const { pricingType, productofferPriceAlteration } = price;
        if (
          productofferPriceAlteration &&
          productofferPriceAlteration.length > 0
        ) {
          console.log('kulla if');
          productofferPriceAlteration.forEach((alteration) => {
            if (alteration.priority === '1') {
              if (
                !priceMap[pricingType] ||
                priceMap[pricingType].priority !== '1'
              ) {
                priceMap[pricingType] = {
                  name: price?.name || '',
                  pricingType: pricingType || '',
                  amount: alteration.productPrice.taxAmount?.Amount
                    ? alteration?.productPrice?.taxAmount?.Amount?.toString()?.replace(
                        '£',
                        ''
                      )
                    : '',
                  priority: alteration?.priority || '',
                };
              }
            }
          });
        } else {
          // console.log('kulla veliya if', price.productPrice.dutyFreeAmount);
          priceMap[pricingType] = {
            name: price?.name || '',
            pricingType: pricingType || '',
            amount: price?.productPrice?.dutyFreeAmount
              ? price?.productPrice?.dutyFreeAmount
                  ?.toString()
                  ?.replace('£', '')
              : '',
            priority: price?.productPrice?.priority || '',
          };
        }
      });

      return Object.values(priceMap).map((price) => ({
        ...price,
        amount: price.amount || '',
      }));
    },
    getUpdatedProductSpecCharValue(val) {
      let activeSku;
      activeSku = val.filter((item) => {
        return item.lifecycleStatus == 'Active';
      });
      if (activeSku.length == 0) {
        activeSku = val.filter((item) => {
          return item.default == true;
        });
      }
      return activeSku;
    },
    calculateOneTimePrice(pricing) {
      console.log('pricing card_data2', pricing);
      const oneTimePrice = pricing.find(
        (price) =>
          price?.name === 'One time' ||
          price?.name === 'Recurring' ||
          price?.name === 'Pay per use price'
      );
      if (oneTimePrice) {
        const amount = oneTimePrice.amount;
        const [whole, fraction] = amount.split('.');
        return { whole, fraction };
      }
      return null;
    },
    scrollToDiv() {
      this.$nextTick(() => {
        const element = document.getElementById('pricing-table-container-#1');
        if (element) {
          // Scroll smoothly to the target element
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    clickBack() {
      console.log('edit data 6', this.tempPricingTableData);
      this.show_offering = false;
      this.show_side_panel = false;
      // this.example_data[this.product_index].pricingTableData = JSON.parse(
      //   JSON.stringify(this.selectedProductOfferingData)
      // );
      // this.totalPricingData = this.example_data.reduce(
      //   (acc, obj) =>
      //     acc.concat(JSON.parse(JSON.stringify(obj.pricingTableData))),
      //   []
      // );
      this.searchOffers('');
      this.quoteSummaryDataFromRules = JSON.parse(
        JSON.stringify(this.tempQuoteSummaryDataFromRules)
      );
      if (this.selectedProductOfferingData.length === 0) {
        console.log(
          'edit data 6.1',
          this.sendSelectedProductOfferingData,
          this.tempPricingTableData
        );

        const x = JSON.parse(JSON.stringify(this.tempPricingTableData));
        this.sendSelectedProductOfferingData = JSON.parse(JSON.stringify(x));

        console.log(
          'edit data 6.2',
          this.sendSelectedProductOfferingData,
          this.tempPricingTableData,
          x
        );
      }
      this.scrollToDiv();
      this.selectedSubTab = 'product_details';
      console.log(this.selectedSubTab, 'subTab');
    },
    async selectedOffer($event, card) {
      console.log('newbundle', $event, card);

      const ele = $event.target;
      if (ele.classList.contains('item-added-to-cart')) {
        //
      } else if (ele.parentElement.classList.contains('item-added-to-cart')) {
        ///f
      } else {
        card = await this.supplierChanged(card);
        console.log(card, 'new card');
        if (card.offerType === 'Bundle') {
          this.selectedProductOfferingData.push(
            JSON.parse(JSON.stringify(card))
          );
          console.log(card, 'bundleIndex b');
          card.prodSpecCharValue.forEach((i, index) => {
            console.log(i, index, 'bundleIndex');

            i['bundleIndex'] = i._id + 'id' + index;
            i['quantity'] = 1;
            i['billing_period'] = i.billingPeriod;
            let [whole, fraction] = String(i.tiers[0].price.value)?.split('.');
            i['price'] = { whole: whole, fraction: fraction };
            this.selectedProductOfferingData.push(
              JSON.parse(JSON.stringify(i))
            );
          });
        } else {
          this.selectedProductOfferingData.push(
            JSON.parse(JSON.stringify(card))
          );
        }
        this.sendSelectedProductOfferingData = [
          ...toRaw(JSON.parse(JSON.stringify(this.tempPricingTableData))),
          ...toRaw(this.selectedProductOfferingData),
        ];
        console.log(
          'selected offers',
          this.selectedProductOfferingData,
          this.sendSelectedProductOfferingData
        );
      }
    },
    addToCart($event, item) {
      console.log('🚀 ~ addToCart ~ $event:', $event);
      this.cartItems.push(item.id);
      // const ele = $event.target;
      // if (ele.classList.contains("custom-addtocart-btn")) {
      //   ele.classList.add("item-added-to-cart");
      //   ele.children[0]?.classList?.add("icon-check-circle-filled");
      //   ele.children[0]?.classList?.remove("icon-shopping-cart-plus-regular");
      //   ele.innerText = "Added";
      // } else {
      //   ele.parentElement.classList.add("item-added-to-cart");
      //   ele.parentElement.children[0]?.classList?.add(
      //     "icon-check-circle-filled"
      //   );
      //   ele.parentElement.children[0]?.classList?.remove(
      //     "icon-shopping-cart-plus-regular"
      //   );
      //   ele.parentElement.innerText = "Added";
      // }
    },
    async supplierChanged(card) {
      let val2 = card.relatedParty.filter((i) => {
        return i.role == 'supplier';
      });
      let val = val2[0];
      let x = card?.productOfferingPrice[0]?.productOfferingAlteration?.filter(
        (i) => {
          return i?.company == val?.name;
        }
      );
      this.selectedSupplier = val;
      this.selectedConfigurationCost = val?.msrp?.value;

      if (this.newPrice !== null) {
        if (val?.productStockLevel?.cost == 0) {
          // trigger rules
          const supplierRules = await DMNAxiosInstance.post(
            `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
            {
              payload: {
                supplier: {
                  product: [
                    {
                      productOfferingId: card._id,
                      msrp: val?.msrp?.value,
                      sellingPrice: this.newPrice,
                      discount: {
                        skuDiscount: {
                          value: x !== undefined ? x[0].discount : '0',
                          active: 1,
                          cost: 0,
                          startDate:
                            x !== undefined ? x[0]?.validFor?.startDate : '',
                          endDate:
                            x !== undefined ? x[0]?.validFor?.endDate : '',
                        },
                        categoryDiscount: 0,
                        brandDiscount: 0,
                      },
                    },
                  ],
                },
              },
            }
          );
          supplierRules;
          card.supplierUnitCost =
            supplierRules?.data?.data?.supplier?.product[0]?.cost;
          card.rebate = 0;
          this.selectedConfigurationMargin =
            supplierRules?.data?.data?.supplier?.product[0]?.margin?.percentage;
        } else {
          const supplierRules = await DMNAxiosInstance.post(
            `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
            {
              payload: {
                supplier: {
                  product: [
                    {
                      productOfferingId: card._id,
                      msrp: val?.msrp?.value,
                      sellingPrice: this.newPrice,
                      discount: {
                        skuDiscount: {
                          value: 0,
                          active: 1,
                          cost: val?.productStockLevel?.cost,
                          startDate: '',
                          endDate: '',
                        },
                        categoryDiscount: 0,
                        brandDiscount: 0,
                      },
                    },
                  ],
                },
              },
            }
          );
          supplierRules;
          card.supplierUnitCost = val?.productStockLevel?.cost;
          this.selectedConfigurationMargin =
            supplierRules?.data?.data?.supplier?.product[0]?.margin?.percentage;
          card.rebate = 0;
        }
      }
      return card;
    },
    searchOffers(v) {
      this.search_offering = v;
      this.getOfferData();
    },
    async getOfferData() {
      console.log('get offer');
      let Specfilterquery = {
        type: 'search',
        module: 'discountOffering',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.externalRowsPerPage,
          currentPage: this.currentPage,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
          // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
        },
        searchQuery: this.search_offering,
      };

      Specfilterquery;
      // const filterResultData = await UPCAxiosInstance.post(
      //   `util/filterData`,
      //   Specfilterquery
      // );
      const filterResultData = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/listOffers?pageNo=${this.currentPage}&pageSize=${this.externalRowsPerPage}`
      );
      this.filterQuery['company'] = this.company;
      this.filterQuery['searchQuery'] = this.search_offering;
      this.filterQuery['paginatedQuery'] = {
        rowsPerPage: this.externalRowsPerPage,
        currentPage: this.currentPage,
      };
      if (this.filtertagvalue.length != 0) {
        this.filterQuery['type'] = 'filter-search';
      } else {
        this.filterQuery['type'] = 'search';
      }
      if (this.retiredvmodel === true) {
        console.log('true...');

        this.filterQuery.filterQuery = { lifecycleStatus: 'Inactive' };
      } else {
        console.log('false...');

        this.filterQuery.filterQuery = {};
      }
      const filterResultData2 = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQuery
      );
      // this.offering_data = filterResultData.data.data
      console.log('raw offer', filterResultData);
      console.log('Offering data', filterResultData2.data.total);
      this.card_data_length = filterResultData2.data.total;
      console.log(
        filterResultData2.data.data,
        this.filterQuery,
        'filterResultData2.data.data...'
      );

      this.card_data = this.transformData(filterResultData2.data.data);
      console.log('card_data', this.card_data);
      this.card_data.forEach((i) => {
        this.getProductImageData(i);
      });
    },
    shipmentMethod(val) {
      if (val) {
        this.shipmentPayload = val;
      }
    },
    emitShipmentPayload() {
      console.log(this.shipmentPayload, 'shipment');
      this.example_data1.push(this.shipmentPayload);
      this.show_right2 = false;
    },
    computedTableAction() {
      return [
        {
          display: this.matchFromStatic('Edit'),
          label: 'Edit',
          icon: 'icon-file-text-edit-regular',
        },
        // {
        //   display: this.matchFromStatic("Amendment"),
        //   label: "Amendment",
        //   icon: "icon-clipboard-edit-regular",
        // },
        // {
        //   display: this.matchFromStatic("Renew"),
        //   label: "Renew",
        //   icon: "icon-calendar-plus-alt-regular",
        // },

        {
          display: this.matchFromStatic('Delete'),
          label: 'Delete',
          icon: 'icon-trash-regular',
        },
      ];
    },
    crudContextMenu($event, i, index) {
      this.currentIndex = index;
      if ($event.target.className === 'icon-more-vertical-filled') {
        this.$nextTick(() => {
          console.log(
            document
              .querySelector('#table-context' + (index - 1))
              .getBoundingClientRect()
          );
          this.containerleft =
            document
              .querySelector('#table-context' + (index - 1))
              .getBoundingClientRect().left - 121;

          this.containertop =
            document
              .querySelector('#table-context' + (index - 1))
              .getBoundingClientRect().top + 18;
        });
        this.example_data1?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.example_data1[index].context = true;
      } else {
        this.example_data1?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    closeCrudContextMenu($event, payload, i) {
      payload, i;
      if ($event.toLowerCase() === 'delete') {
        this.delete_modal_active = true;
        this.delete_name = payload.chooseSite;
        this.deleteobj = payload;
      } else if ($event.toLowerCase() === 'edit') {
        this.shipmentPayload = payload;
        this.edit = true;
        this.show_right2 = true;
      }
    },
    editShipmentPayload() {
      this.example_data1[this.currentIndex] = this.shipmentPayload;
      this.shipmentPayload = {
        shipmentDate: '',
        chooseSite: '',
        shippedQuantity: '',
        trackingNumber: '',
        courierName: '',
      };
      this.show_right2 = false;
      console.log(this.shipmentPayload, 'shipmentPayloadedit');
    },
    addShipment() {
      this.shipmentPayload = {
        shipmentDate: '',
        chooseSite: '',
        shippedQuantity: '',
        trackingNumber: '',
        courierName: '',
      };
      this.edit = false;
      this.show_right2 = true;
      this.componentKey += 1;
      console.log(this.shipmentPayload, 'shipmentPayloadadd');
    },
    deleteExecRow() {
      this.example_data1.splice(this.currentIndex, 1);
      this.delete_modal_active = false;
    },
    deleteExecRow1() {
      this.example_data2.splice(this.currentIndex, 1);
      this.delete_modal_active1 = false;
    },
    emitinstallationPayload() {
      this.installation_payload.context = false;
      this.example_data2.push(this.installation_payload);
      console.log(this.example_data2, 'installation data');
      this.show_right1 = false;
    },
    addinstallation() {
      this.installation_payload = {
        chooseSite: '',
        status: '',
        installationDate: '',
        chooseAssignee: '',
      };
      this.edit = false;
      this.show_right1 = true;
      this.componentKey += 1;
    },
    editinstallationPayload() {
      console.log(this.example_data2, 'before');
      this.example_data2[this.currentIndex] = this.installation_payload;
      console.log(this.example_data2, 'after');
      this.installation_payload = {
        chooseSite: '',
        status: '',
        installationDate: '',
        chooseAssignee: '',
      };
      this.show_right1 = false;
    },
    editedPayloadInstallation(value) {
      this.installation_payload = value;
    },
    tableCalculation(index) {
      let arr = [];
      let value = JSON.parse(JSON.stringify(this.example_data2));
      arr.push(value[index]);
      return arr;
    },
    crudContextMenu1($event, i, id, index) {
      this.currentIndex = index;
      if ($event.target.className === 'icon-more-vertical-filled') {
        console.log('value', id);
        this.$nextTick(() => {
          console.log(document.querySelector('#' + id).getBoundingClientRect());
          this.containerleft =
            document.querySelector('#' + id).getBoundingClientRect().left - 121;

          this.containertop =
            document.querySelector('#' + id).getBoundingClientRect().top + 18;

          console.log(
            this.containertop,
            this.containerleft,
            'containertop or left'
          );
        });
        this.idValue = id;
        // this.example_data2?.forEach((e) => {
        //   if (e.context !== undefined) {
        //     e.context = false;
        //   }
        // });

        // this.example_data2[index].context = true;
        console.log(this.example_data2, 'context');
      } else {
        // this.example_data2?.forEach((e) => {
        //   if (e.context !== undefined) {
        //     e.context = false;
        //   }
        // });
      }
    },
    closeCrudContextMenu1($event, payload, i) {
      payload, i;
      if ($event.toLowerCase() === 'delete') {
        this.delete_modal_active1 = true;
        this.delete_name = this.example_data2[this.currentIndex].chooseSite;
        this.deleteobj = this.example_data2[this.currentIndex];
      } else if ($event.toLowerCase() === 'edit') {
        this.installation_payload = this.example_data2[this.currentIndex];
        this.edit = true;
        this.show_right1 = true;
      }
      this.idValue = -1;
    },
  },
};
</script>

<style scoped>
.custom-layout {
  display: flex;
  position: relative;
  height: 92vh;
}

/* Sidebar */
.custom-sidebar {
  width: 220px;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  padding: 1rem 0.5rem 1rem 1rem;
}

/* Header */
.custom-header {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  /* background-color: #eaeaea; */
  padding: 10px 10px;
  /* border: 1px solid #ddd; */
  text-align: center;
}

.previous-button-style {
  color: #54bd95;
}

.previous-button-style:hover {
  border-bottom: 1px solid #54bd95;
}

.custom-right-layout {
  padding: 1.8em 20px;
  overflow: auto;
  width: calc(100% - 220px);
  position: relative;
}

/* Order Information */
.custom-order-info {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

/* Contact and Billing Details */
.custom-contact-billing {
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.bottom-bar {
  /* background-color: #f1f1f1; */
  padding: 10px;
  text-align: center;
  border-top: 1px solid #d8d8d8;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  /* flex-shrink: 0; */
  position: fixed;
  bottom: 0;
  left: 72px; /* Match the width of the sidebar */
  right: 0;
  z-index: 10;
}

.custom-contact-details {
  text-align: center;
}

.custom-billing-details {
  text-align: center;
}

/* Constructor and Requester Company */
.custom-constructor-requester {
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

/* .custom-constructor-company { */
/* text-align: center; */
/* } */

/* .custom-requester-company { */
/* text-align: center; */
/* } */

/* Notes Section */
.custom-questions-section {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  text-align: center;
}
/* Notes Section */
.custom-notes-section {
  grid-column: 2 / 3;
  grid-row: 6 / 7;
  padding: 10px;
  text-align: center;
}

/* Attachment Section */
.custom-attachment-section {
  grid-column: 2 / 3;
  grid-row: 7 / 8;
  /* background-color: #f9f9f9; */
  padding: 10px;
  /* border: 1px solid #ddd; */
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .custom-layout {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto auto auto;
  }

  .custom-sidebar {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .custom-header {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .custom-order-info {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .custom-contact-billing {
    grid-column: 1 / 2;
  }

  .custom-constructor-requester {
    grid-column: 1 / 2;
  }

  .custom-notes-section {
    grid-column: 1 / 2;
    grid-row: 6 / 7;
  }

  .custom-attachment-section {
    grid-column: 1 / 2;
    grid-row: 7 / 8;
    margin-bottom: 10px;
  }
}

/* Equipment order specific container styling */
.equipment-order-site-accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  /* border-bottom: 1px solid #ccc; */
}

.left {
  display: flex;
  align-items: center;
}

.cart-icon {
  font-size: 24px;
  color: #000; /* Set desired color for the shopping cart */
  margin-right: 10px;
}

.tick-icon {
  font-size: 20px;
  color: green !important; /* Set green color for the tick */
  margin-left: 10px;
}

.details {
  display: flex;
  flex-direction: column;
}

.name {
  /* font-weight: bold; */
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 100;
}

.address {
  font-size: 12px;
  font-weight: 100;
  color: gray;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.one-time,
.recurring {
  font-size: 12px;
  font-weight: 100;
}
</style>
