<template>
  <div style="width:100%">
    <hlx-input
      v-if="fieldType == 'input'"
      v-model:value="payload"
      :pre-val="payload"
      type="location"
      :required="required"
      :label-value="labelValue"
      :label-animation="true"
      :lookup-prop="lookupProp"
      :display-error="true"
      :read-only="readOnly"
      :custom-error="customError"
      :custom-error-message="customErrorMessage"
      :location-data-prop="lookupData"
      @object-emit="triggerEmit"
      @at-input="getDataDebounced"
      @focus-out="emitFocusOut"
    ></hlx-input>
    <hlx-select
    v-else
    v-model:value="payload"
    :placeholder-value="labelValue"
    :options="lookupData"
    :label-animation="true"
    :disabled="readOnly"
    :inline-search="true"
    :pre-value="payload"
    :prop-value="lookupProp"
    :display-error="true"
    :label="'name'"
    @selected-value="triggerEmit"
    >
    </hlx-select>
  </div>
</template>

<script>
import { UPCAxiosInstance, MLTAxiosInstance } from '@/config/axiosConfig';
import debounce from 'lodash/debounce';

export default {
  props: {
    method:{
      type:String,
      default:'POST'
    },
    customErrorMessage:{
      type: String,
      default: '',
    },
    disableLookup:{
      type:Boolean,
      default:false,
    },
    customError:{
      type:Boolean,
      default:false,
    },
    readOnly:{
      type:Boolean,
      default:false,
    },
    lookupProp: {
      type: String,
      default: 'description'
    },
    minCharToSearch: {
      type: Number,
      default: 1
    },
    maxResultToDisplay: {
      type: Number,
      default: 5
    },
    fieldType: {
      type: String,
      default: 'input'
    },
    view: {
      type: Boolean,
      default: false,
    },
    labelValue: {
      type: String,
      default: 'Lookup component'
    },
    required: {
      type: Boolean,
      default: false,
    },
    preVal: {
      type: Object,
      default: () => {
        return {
          mediumType: 'postalAddress',
          characteristic: {
            city: '',
            unit: '',
            country: '',
            state: '',
            postalCode: '',
            stateOrProvince: '',
            street1: '',
            street2: '',
            description: '',
            address1: '',
            default: true,
            '@type': '',
          },
        };
      },
    },
    filterPayload: {
      type: Object,
      default: () => {
        return {
          type: 'search',
          module: 'saleslead',
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 100,
          filterQuery: {},
          searchList: [
          ],
          searchQuery: '',
        };
      },
    },
    url: {
      type: String,
      default: '/util/filterData'
    },
    type: {
      type: String,
      default: 'internal'
    },
    module: {
      type: String,
      default: 'Lead'
    },
    instance: {
      type: String,
      default: 'UPCAxiosInstance'
    }
  },
  emits: ['addressData','selectedValue', 'update:value', 'focusOut'],
  data() {
    return {
      canEmit: false,
      lookupData: [],
      payload: {
        value: ''
      },
      rawPayload: {},
      company: ''
    };
  },
  watch: {
    fieldType: {
      handler(val) {
        if (val != 'input') {
          this.getData()
        }
      },
      immediate: true,
      deep: true
    },
    preVal: {
      handler(val) {
        this.rawPayload = JSON.parse(JSON.stringify(val));
        this.payload = JSON.parse(JSON.stringify(val));
        this.canEmit = false;
      },
      immediate: true,
      deep: true,
    },
    payload: {
      handler(val) {
        console.log('assume', this.payload);
        this.$emit('update:value', val)
        // this.payload.characteristic.description =
        //   `${
        //     val.characteristic.address1 !== '' &&
        //     val.characteristic.address1 !== undefined
        //       ? val.characteristic.address1
        //       : ''
        //   }` +
        //   `${
        //     val.characteristic.unit !== '' &&
        //     val.characteristic.unit !== undefined
        //       ? ', ' + val.characteristic.unit
        //       : ''
        //   }` +
        //   `${
        //     val.characteristic.city !== '' &&
        //     val.characteristic.city !== undefined
        //       ? ', ' + val.characteristic.city
        //       : ''
        //   }` +
        //   `${
        //     val.characteristic.state !== '' &&
        //     val.characteristic.state !== undefined
        //       ? ', ' + val.characteristic.state
        //       : ''
        //   }` +
        //   `${
        //     val.characteristic.country !== '' &&
        //     val.characteristic.country !== undefined
        //       ? ', ' + val.characteristic.country
        //       : ''
        //   }` +
        //   `${
        //     val.characteristic.postalCode !== '' &&
        //     val.characteristic.postalCode !== undefined
        //       ? ', ' + val.characteristic.postalCode
        //       : ''
        //   }`;
        if (this.canEmit) {
          this.$emit('addressData', val);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const val = await MLTAxiosInstance.get('partymanagement/individual/auth');
    this.company = val.data.company;
    if (this.fieldType != 'input') {
      await this.getData()
    }
  },
  created() {
    // Wrap getData in debounce and assign it to a debounced method
    this.getDataDebounced = debounce(this.getData, 800);
  },
  methods: {
    emitFocusOut() {
      this.$emit('focusOut', true)
    },
    canEmitTrigger() {
      this.canEmit = true;
    },
    deepObjectCompare(obj1, obj2) {
      // Check if both inputs are objects
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return obj1 !== obj2;
      }

      // Check if both inputs are arrays
      if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) {
          return true;
        }

        for (let i = 0; i < obj1.length; i++) {
          if (this.deepObjectCompare(obj1[i], obj2[i])) {
            return true;
          }
        }

        return false;
      }

      // Check if both inputs have the same keys
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return true;
      }

      // Check each key-value pair recursively
      for (const key of keys1) {
        if (
          !keys2.includes(key) ||
          this.deepObjectCompare(obj1[key], obj2[key])
        ) {
          return true;
        }
      }

      return false;
    },
    async getData() {
      if (!this.disableLookup) {
        this.canEmit = true;
        const input = this.payload;
        let result = [];
        console.log(
          input.length,
          input.length >= this.minCharToSearch,
          'inputfk'
        );
   
        if (input.length >= this.minCharToSearch) {
          try {
            let filterPayload = this.filterPayload;
            filterPayload.filterQuery.name['$regex'] = input;
            filterPayload.company = this.company;
            filterPayload.paginatedQuery.rowsPerPage = this.maxResultToDisplay;
            // eslint-disable-next-line vue/no-async-in-computed-properties
            if (this.instance == 'UPCAxiosInstance' && this.type == 'internal') {
              const response = await UPCAxiosInstance.post(
                `/util/filterData`,
                filterPayload
              );
              result = response.data;
            } else if (
              this.instance == 'MLTAxiosInstance' &&
              this.type == 'internal'
            ) {
              if (this.method != 'GET') {
                const response = await MLTAxiosInstance.post(
                  `/util/filterData`,
                  filterPayload
                );
                result = response.data;
                this.lookupData = result.data;
                this.lookupData = this.lookupData?.filter((item) => {
                  return item.name != undefined && item.name !== '';
                });
              } else {
                const response = await MLTAxiosInstance.get(this.url);
                result = response.data;
                result.forEach((value) => {
                  value.name = this.getNestedLookupValue(value, this.lookupProp);
                });
                this.lookupData = result;
                this.lookupData = this.lookupData?.filter((item) => {
                  return item.name != undefined && item.name !== '';
                });
              }
            }
            console.log(result, 'result00');
          } catch (error) {
            console.error('Error fetching autocomplete data:', error.message);
          }
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.lookupData = result.data;
          this.lookupData = this.lookupData?.filter((item) => {
            return item.name != undefined && item.name !== '';
          });
          return result;
        } else {
          this.lookupData = []
        }
      }
      // this.lookupData.forEach((item) => {
      //   return item.name !== '' ? (item.description = item.name) : false;
      // });
    },
    getNestedLookupValue(obj, path) {
      return path.split(".").reduce((acc, key) => acc && acc[key], obj);
    },
    async addressData(val) {
      val;
      // this.canEmit = true
      // let address_components = await UPCAxiosInstance.get(
      //   `/address/details?place_id=${val.place_id}`
      // );
      // console.log(address_components);
      // let address1 = '';
      // let postcode = '';
      // let country = '';
      // let unit = '';
      // let city = '';
      // let state_or_province = '';
      // for (const component of address_components.data.result
      //   .address_components) {
      //   const componentType = component.types[0];

      //   switch (componentType) {
      //     case 'street_number': {
      //       address1 = `${component.long_name} ${address1}`;
      //       break;
      //     }
      //     case 'route': {
      //       address1 += component.short_name;
      //       break;
      //     }
      //     case 'postal_code': {
      //       postcode = `${component.long_name}${postcode}`;
      //       break;
      //     }
      //     case 'postal_code_suffix': {
      //       postcode = `${postcode}-${component.long_name}`;
      //       break;
      //     }
      //     case 'postal_code_prefix': {
      //       postcode = `${component.long_name}${
      //         postcode !== '' ? '-' + postcode : ''
      //       }`;
      //       break;
      //     }
      //     case 'country': {
      //       country = `${component.long_name}`;
      //       break;
      //     }
      //     case 'administrative_area_level_1': {
      //       state_or_province = `${component.long_name}`;
      //       break;
      //     }
      //     case 'administrative_area_level_2': {
      //       city = `${component.long_name}`;
      //       break;
      //     }
      //     case 'administrative_area_level_3': {
      //       city = `${city !== '' ? city + ', ' : ''}${component.long_name}`;
      //       break;
      //     }
      //     case 'sublocality_level_1': {
      //       city = `${component.long_name}`;
      //       break;
      //     }
      //     case 'subpremise': {
      //       unit = `${component.long_name}`;
      //       break;
      //     }
      // Add more cases for other address components as needed
      //   }
      // }

      // this.payload.characteristic.address1 = address1;
      // this.payload.characteristic.postalCode = postcode;
      // this.payload.characteristic.unit = unit;
      // this.payload.characteristic.city = city;
      // this.payload.characteristic.state = state_or_province;
      // this.payload.characteristic.country = country;
    },
    initAutocomplete() {
      const address1Field = this.$refs.address1Field;
      const address2Field = this.$refs.address2Field;
      const cityField = this.$refs.cityField;
      const unitField = this.$refs.unitField;
      const stateOrProvinceField = this.$refs.stateOrProvinceField;
      const countryField = this.$refs.countryField;
      const postalField = this.$refs.postalField;
      address2Field;
      postalField;
      countryField;
      cityField;
      unitField;
      stateOrProvinceField;
      this.autocomplete = new window.google.maps.places.Autocomplete(
        address1Field,
        {
          componentRestrictions: { country: ['us', 'ca', 'in'] },
          fields: ['address_components', 'geometry'],
          types: ['address'],
        }
      );

      address1Field.focus();

      this.autocomplete.addListener('place_changed', this.fillInAddress);
    },
    triggerEmit(val) {
      console.log(val, 'triggeremit');
      this.$emit('selectedValue', val)
    },
    fillInAddress() {
      // const place = this.autocomplete.getPlace();
      // let address1 = '';
      // let postcode = '';
      // let country = '';
      // // let unit = '';
      // let city = '';
      // let state_or_province = '';
      // console.log(place.address_components);
      // for (const component of place.address_components) {
        // const componentType = component.types[0];

        // switch (componentType) {
        //   case 'street_number': {
        //     address1 = `${component.long_name} ${address1}`;
        //     break;
        //   }
        //   case 'route': {
        //     address1 += component.short_name;
        //     break;
        //   }
        //   case 'postal_code': {
        //     postcode = `${component.long_name}${postcode}`;
        //     break;
        //   }
        //   case 'postal_code_suffix': {
        //     postcode = `${postcode}-${component.long_name}`;
        //     break;
        //   }
        //   case 'postal_code_prefix': {
        //     postcode = `${component.long_name}-${postcode}`;
        //     break;
        //   }
        //   case 'country': {
        //     country = `${component.long_name}`;
        //     break;
        //   }
        //   case 'administrative_area_level_1': {
        //     state_or_province = `${component.long_name}`;
        //     break;
        //   }
        //   case 'administrative_area_level_2': {
        //     city = `${component.long_name}`;
        //     break;
        //   }
        //   case 'administrative_area_level_3': {
        //     city = `${city !== '' ? city + ', ' : ''}${component.long_name}`;
        //     break;
        //   }
        //   case 'sublocality_level_1': {
        //     city = `${component.long_name}`;
        //     break;
        //   }
        //   // case 'subpremise': {
        //   //   unit = `${component.long_name}`;
        //   //   break;
        //   // }
        //   // Add more cases for other address components as needed
        // }
      // }

      // this.payload.characteristic.description = address1;
      // this.payload.characteristic.postalCode = postcode;
      // this.$refs.unitField.value = unit;
      // this.payload.characteristic.city = city;
      // this.payload.characteristic.state = state_or_province;
      // this.payload.characteristic.country = country;
      // this.$refs.address2Field.focus();
    },
  },
};
</script>
