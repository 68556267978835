<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <div :key="componentkey" class="app-layout">
    <section class="new-right-panel">
      <!-- <hlx-switch
        v-model="selectedValue"
        :switch-items="groupValues"
        :tab-view="true"
        value-prop="value"
        display-prop="name"
        @change="handleCurrentChange"
      /> -->
      <div class="new-right-panel-parent-container no-filter-panel">
        <div class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <section class="new-layout-basic-no-breadcrumb">
            <div class="new-layout-header">
              <span class="new-layout-header-container">
                <span class="schema-header-title">Category </span>
                <span class="subtitle">
                  Aligning products, trends, and consumer expectations
                  seamlessly.
                </span>
              </span>
            </div>
            <div class="new-layout-body">
              <hierarchyComponent
                :data="hierarchyData"
                :module="'PCM'"
                @payload="receivedHierarchy"
                @category="addCategoryPayload"
                @delete="deleteCategory"
                @edit-category="editPayload"
                @drag-child="dragChilddata"
                @drag-sibilng="dragSibilingdata"
              ></hierarchyComponent>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
    import { UPCAxiosInstance } from '@/config/axiosConfig';
    import hierarchyComponent from '@/components/CM/addAccountHierarchyComponent.vue';
export default {
    components: {
        hierarchyComponent
    },
    data()
    {
        return{
            module:'PCM',
            hierarchyData:[],
            componentkey:0,
            notification:{},
        }
    },
    watch:{
      hierarchyData:{
        handler(val)
        {
          val
          console.log(val,'llllokdsijf');
        }
      },
      immediate:true,
      deep:true 
    },
    async mounted(){
        let data = await UPCAxiosInstance.get(
        `/catalogManagement/category/Allhierarchy`
      );
      let Hdata =  this.removeNullAndEmptyChildren(data?.data)
      console.log(Hdata,'dataaa');
      this.hierarchyData = Hdata
    
    },
    methods:{
      async refreshTable(){
let data = await UPCAxiosInstance.get(
        `/catalogManagement/category/Allhierarchy`
      );
      if(data?.data.length != 0)
      {
      let Hdata =  this.removeNullAndEmptyChildren(data?.data)
      console.log(data,'refresh data');
      this.hierarchyData =  Hdata
      }else{
        this.hierarchyData = []
        this.componentkey +=1
      }
      },
        async categoryDataCalling(){
            
                    let data = await UPCAxiosInstance.get(
                `/catalogManagement/category/Allhierarchy`
            );
            console.log(data,'dataaaka');
            
            let Hdata =  this.removeNullAndEmptyChildren(data?.data)
            console.log(data,'dataaa');
            this.hierarchyData = Hdata
        },
        async receivedHierarchy(payload,id,type,currPayload){
          
            console.log(id,'lalalala');
      
        if(type=='Add parent'){
          console.log(payload,id,currPayload,"inside if")
           let parentId = await UPCAxiosInstance.post(
                    `/catalogManagement/category`,payload
                );
            parentId;
            let updatechild = await UPCAxiosInstance.patch(`/catalogManagement/category`,currPayload)
            updatechild;
            this.refreshTable()
            // let parentAdd = await UPCAxiosInstance.post(`/catalogManagement/category/parent/${id}`,{parentId:parentId._id})
            if(updatechild.data){
               this.notification = {
          type: "success",
          message: `All set! Your changes have been saved successfully!`,
        };
            }
        }
            else if(type == 'Add child')
            {
              console.log("adding child ++")
                let addpayload = payload.child
                addpayload['isRoot'] = false
                addpayload.parentId = currPayload._id
                console.log(addpayload,"payload from child",currPayload)
                let data = await UPCAxiosInstance.post(
                    `/catalogManagement/category`,addpayload
                );
                console.log(data.data,"ressss")
                if(data.data == 'Category already exists' && data.status ==201)
                {
                  this.notification = {
                      type: "danger",
                      message: `The ${addpayload.name} category already exists`,
                    };
                }else{
                let res = data.data
                    let data1 = currPayload?.subCategory
                    if(data1==undefined){
                      data1=[]
                    }
                    res.id = res._id
                    console.log(res,"data res",currPayload.subCategory)

                      data1.push(res)
                
                    console.log(data1,'lalal data');
                    currPayload.id = currPayload._id
                    currPayload.subCategory = data1
                    delete currPayload._id
                if(data.status == 201)
                {
                    
                    let data2 = await UPCAxiosInstance.patch(
                    `/catalogManagement/category`,currPayload
                );
                data2;
                this.notification = {
          type: "success",
          message: `Done! Nice work! Your new category is now in the list!`,
        };
                this.refreshTable()
                }
              }
            }
    //   this.categoryDataCalling()
        },
        async addCategoryPayload(val){
            val
            console.log(val,'dataaaaaaaaaa');
            let data = await UPCAxiosInstance.post(
                    `/catalogManagement/category`,val
                );
                data

                if(data.data == 'Category already exists' && data.status ==201)
                {
                  console.log(data.data,'lolll');
                  this.notification = {
                      type: "danger",
                      message: `The ${val.name} category already exists`,
                    };
                }
                else if(data.status ==201 && Object.keys(data.data).length !=0)
                {
                               this.notification = {
          type: "success",
          message: `Done! Nice work! Your new category is now in the list!`,
        };
                    let data1 = await UPCAxiosInstance.get(
                      `/catalogManagement/category/Allhierarchy`
                    );
                    console.log(data1,'dataaaka');
                  //   let Hdata =  this.removeNullAndEmptyChildren(data?.data)
                    console.log(data1,'dataaa');
                    this.hierarchyData = data1?.data
                }
                console.log(data,'data');
            // this.categoryDataCalling()
        },
        async editPayload(payload){
            //  if(payload._id != undefined)
            //  {
               payload.id = payload._id
               delete payload._id
            //  }
            let editPayload = payload
              console.log(payload,'edited');
            let data = await UPCAxiosInstance.patch(
                    `/catalogManagement/category`,editPayload
                );
                console.log(data,'edittt');
                if(data.data == 'Category already exists' && data.status ==200)
                {
                  console.log(data.data,'lolll');
                  this.notification = {
                      type: "danger",
                      message: `The ${editPayload.name} category already exists`,
                    };
                }
                else if(data.status===200 && Object.keys(data.data).length !=0){
                               this.notification = {
                              type: "success",
                              message: `All set! Your changes have been saved successfully!`,
                            };
                           this.refreshTable()
                }
        },
        async deleteCategory(id)
        {
          console.log(id.name,"name")
            let data = await UPCAxiosInstance.delete(
                    `/catalogManagement/category/remove/${id.name}`,
                ); 

                if(data.status===200){
                               this.notification = {
                              type: "success",
                              message: `Done! Your item has been removed.`,
                            };
                           this.refreshTable()
                }
          // this.refreshTable()
          // data;
       
        },
        async dragChilddata(targetid,obj,type,dragdata,targetdata)
        {
          console.log(dragdata.parentId,targetdata.id,'drag dataaa');
          let dragobj = {}
          dragobj['from'] = dragdata
          let id = targetdata._id
          dragobj['to'] = targetdata
          dragobj['to']['id'] = id
          delete dragobj['to']['_id']
          console.log(dragobj);
          let data = await UPCAxiosInstance.patch(`/catalogManagement/category/dragLevel`,dragobj)
          data
          this.refreshTable()
        },
        async dragSibilingdata(targetid,obj,type,dragdata,targetdata)
        {
            console.log(targetid,obj,type,dragdata,targetdata,'sibilingdataaaa');
            let dragobj = {}
          let dragid = dragdata._id
          dragobj['from'] = dragdata
          dragobj['from']['id'] = dragid
          delete dragobj['from']['_id']
          let id = targetdata._id
          dragobj['to'] = targetdata
          dragobj['to']['id'] = id
          delete dragobj['to']['_id']
          console.log(dragobj);
          let data = await UPCAxiosInstance.patch(`/catalogManagement/category/sibiling`,dragobj)
          data
          this.refreshTable()
        },
        removeNullAndEmptyChildren(payload) {
          console.log(payload,"remove null")
        if(Object.keys(payload).length != 0)
          {
    // Recursive function to check and remove null children
    const removeNullChildrenRecursive = (node) => {
      if (node && Array.isArray(node.subCategory)) {
        node.subCategory = node.subCategory
          .filter((subCategory) => subCategory !== null && removeNullChildrenRecursive(subCategory));

        if (node.subCategory.length === 0) {
          delete node.subCategory; // Remove 'subCategory' attribute if empty
        }
      }
      return node; // Returning the modified node
    };

    // Loop through the payload and remove null and empty children
    return payload.map((item) => removeNullChildrenRecursive(item));
        }
  },
},
}
</script>