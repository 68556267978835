<template>
  <hlx-modal
      :modal-active="apiModal"
      :height="'auto'"
      :width="'600px'"
      :modal="true"
      @close="apiModal = false"
    >
        <template #header>
        <div style="margin-left: 0px">Configure API</div>
      </template> 
      <template #content>
        <div class="modal-content">
          <div v-for="(api,indexx) in apiConfig" :key="indexx"  style="display: flex; flex-direction: column">
         <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 16px"><div style="width: 20%; font-weight: 600;">Entity name</div><div style="width: 80%">
          <hlx-input
          v-model:value="api.name"
                      :label-value="'Users'"
                      :label-animation="false"
                      :required="false"
                      :pre-val="api.name"
                    ></hlx-input>
                  </div></div>
         <div style="display: flex; justify-content: space-between; align-items: center;"><div style="width: 20%; font-weight: 600">API endpoint</div><div style="width: 80%"><hlx-input
          v-model:value="api.apiRef"
                      :label-value="'https://appbackend-ghubgwxrcq-pd.a.run.app/partymanagement/individual/listuser'"
                      :label-animation="false"
                      :required="false"
                      :pre-val="api.apiRef"
                    ></hlx-input></div></div>

                    <hr v-if="apiConfig.length>0" style="margin-top: 12px; margin-bottom: 12px">

                  </div>
                  <div
                  style="
                    color: #54bd95;
                    font-size: 14px;
                    margin-top: 8px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                  "
                  @click="addApiConfig"
                >
                  <i
                    class="icon-plus-circle-filled"
                    style="margin-right: 8px"
                  ></i>
                  <span>Add</span>
                </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="apiModal = false"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary sm"
          style="margin-left: 16px"
          @click="saveApi"
          >Save</hlx-button
        >
      </template>
    </hlx-modal>
  <hlx-drawer
    :show="show_right_filter"
    :width="300"
    position="right"
    :header="false"
    :footer="false"
    :show-close-icon="true"
    @close="show_right_filter = false"
  >
    <template #body>
      <div style="margin: 10px">
        <section class="header">
          <div class="new-layout-header">
            <span class="schema-header-title" style="font-size: 16px"
              >Filters</span
            >
          </div>
        </section>
        <section class="panel-body">
          <hlx-accordion
            v-for="(key, index) in tableData.filter(
              (i) => i.configType != 'rule' && i.sourceColumn != ''
            )"
            :key="index"
            :multiple="true"
          >
            <!-- Title -->
            <template #item-title>
              <div v-if="key.configType != 'rule'">{{ key.sourceColumn }}</div>
            </template>
            <!-- Body -->
            <template #item-body>
              <div
                v-if="
                  key.sourceType.toLowerCase() != 'number' &&
                  key.configType != 'rule'
                "
                class="string-container"
              >
                <hlx-select
                  :label-value="''"
                  :label-animation="false"
                  :required="false"
                  :pre-value="operator"
                  :options="stringOperators"
                  @selected-value="assignStr($event, index)"
                ></hlx-select>

                <span
                  v-for="(val, indexx) in key.filterValues"
                  :key="val"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div class="left">
                    <hlx-input
                      :label-value="''"
                      :label-animation="false"
                      :required="false"
                      :pre-val="val"
                      @focus-out="assignVal($event, index, indexx)"
                    ></hlx-input>
                  </div>
                  <div
                    v-if="key.filterValues.length > 1"
                    class="right"
                    @click="deleteVal(index, indexx)"
                  >
                    <i class="icon-trash-regular" style="margin-right: 8px"></i>
                  </div>
                </span>

                <div
                  style="
                    color: #54bd95;
                    font-size: 14px;
                    margin-top: 8px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                  "
                  @click="addInputs(index)"
                >
                  <i
                    class="icon-plus-circle-filled"
                    style="margin-right: 8px"
                  ></i>
                  <span>Add</span>
                </div>
              </div>

              <div
                v-if="
                  key.sourceType.toLowerCase() == 'number' &&
                  key.configType != 'rule'
                "
                class="string-container"
              >
                <span
                  v-for="(val, indexx) in key.filterValues"
                  :key="val"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div class="left">
                    <hlx-select
                      :label-value="''"
                      :label-animation="false"
                      :required="false"
                      :pre-value="val.operator"
                      :options="operatorOptions"
                      @selected-value="assignOp($event, index, indexx)"
                    ></hlx-select>
                    <hlx-input
                      :label-value="''"
                      :label-animation="false"
                      :required="false"
                      :pre-val="val.value"
                      @focus-out="assignNum($event, index, indexx)"
                    ></hlx-input>
                  </div>
                  <div
                    v-if="key.filterValues.length > 1"
                    class="right"
                    @click="deleteVal(index, indexx)"
                  >
                    <i class="icon-trash-regular" style="margin-right: 8px"></i>
                  </div>
                </span>

                <div
                  style="
                    color: #54bd95;
                    font-size: 14px;
                    margin-top: 8px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                  "
                  @click="addInputsNum(index)"
                >
                  <i
                    class="icon-plus-circle-filled"
                    style="margin-right: 8px"
                  ></i>
                  <span>Add</span>
                </div>
              </div>
            </template>
          </hlx-accordion>
        </section>
      </div>
    </template>
  </hlx-drawer>
  <section class="importContainer">
    <section class="header" style="display: flex; justify-content: space-between;">
      <div class="new-layout-header">
        <span class="schema-header-title">Import template</span>
        <span class="subtitle"> Configure import template. </span>
      </div>
      <div class="new-layout-header right">
        <div
      class="layout-footer"
      style="
        display: flex;
        align-items: center;
     
      "
    >
      <div
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button class="secondary sm" @click="cancelBack">Back</hlx-button>
        <hlx-button
          class="primary sm"
          style="margin-left: 20px"
          @click="saveTemplate"
          >Save</hlx-button
        >
      </div>
    </div>
      </div>
    </section>
  
    <section class="body" style="align-items:center">
      <section v-if="templateList.length>0" class="header left" style=" margin-bottom: 16px; margin-right:16px;width: 30%">
      <div v-if="!add" class="new-layout-header">
        <span class="schema-header-title" style="font-size:14px; margin-bottom: 8px">Choose template
        <i class="icon-edit-regular"  @click="addTemplate"></i
        ></span>
        <span class="subtitle"> <hlx-select
                    v-model:value="template"
                    :inline-search="true"
                    :pre-value="template"
                    :label-animation="false"
                    :placeholder-value="'Template'"
                    :options="templateList"
                    :label="'value'"
                    :prop-value="'value'"
                    @custom-change="loadTemplate(template)"
                  >
                  </hlx-select></span>
      </div>
      <div v-if="add" class="new-layout-header" style="margin-top: 16px">
        <span class="schema-header-title" style="font-size:14px; margin-bottom: 8px">Template name <i class="icon-edit-regular"  @click="add=false"></i
          ></span>
        <span class="subtitle"> <hlx-input
                    v-model:value="templateName"
                    
                  >
                  </hlx-input></span>
      </div>
    </section>
      <div v-if="showUpload" class="left">
        <hlx-file-upload
          :file-type="'.xlsx,.csv'"
          :file-size-limit="'20mb'"
          :custom-icon="icon"
          :type="'regular'"
          @native-data="readFile"
        />
      </div>
     

    </section>
    <section v-if="showSvg" class="body svg">
      <span class="upload_component_icon">
        <img class="file-icon" src="../../assets/svg/rafiki.svg" alt="" />
      </span>
      <div class="subtitle">
        Please upload the document for attribute import attribute mapping
      </div>
    </section>
    <section v-else style="overflow: auto">
      <hlx-table
        :column-count="4"
        :bold-headers="false"
        :row-hover="true"
        theme="grey"
        :striped-rows="false"
      >
        <template #thead>
    
          <hlx-table-head
            v-for="(i, index) in tableHeaders"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :fixed="i.fixed"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
          >
            {{ i.label }}</hlx-table-head
          >

          <hlx-table-head :align="'center'" :width="70"
            ><span></span>
          </hlx-table-head>
        </template>
        <template #tbody>
          <tr v-for="(i, index) in tableData" id="" :key="index">
         
            <hlx-table-cell
              v-for="(j, col_index) in tableHeaders"
              :key="col_index"
              :align="j.align"
              :fixed="j.fixed"
            >

            <div v-if="j?.prop == 'primary'">
              <hlx-input
          :id="key"
          v-model:value="i.required"
          :checked="i.required"
          :prop-value="'label'"
          :type="'single-checkbox'"
          :display-error="false"
        />
            </div>
              <div v-else-if="j?.prop == 'systemFields'">
                <span style="margin-top: 20px">
                  <hlx-select
                    v-model:value="i.destinationKey"
                    :inline-search="true"
                    :pre-value="i.destinationKey"
                    :label-animation="false"
                    :placeholder-value="'Select mapping key'"
                    :options="destinationKeys"
                    :label="'value'"
                    :prop-value="'value'"
                    @custom-change="getType(i.destinationKey, index)"
                  >
                  </hlx-select>
                </span>
              </div>
              <div v-else-if="j?.prop == 'csvHeaders' && i.configType == 'key'">
                <hlx-select
                  v-model:value="i.sourceColumn"
                  :inline-search="true"
                  :pre-value="i.sourceColumn"
                  :label-animation="false"
                  :placeholder-value="'Select source header'"
                  :options="sourceKeys"
                  :label="'value'"
                  :prop-value="'value'"
                >
                </hlx-select>
              </div>
              <div
                v-else-if="j?.prop == 'csvHeaders' && i.configType == 'rule'"
              >
                <hlx-input
                  v-model:value="i.rule"
                  :pre-val="i.rule"
                  :label-value="''"
                  :label-animation="false"
                  type="textarea"
                ></hlx-input>
              </div>
              <div v-else-if="j?.prop == 'sourceType'">
                <span>{{ i.sourceType }}</span>
              </div>
            </hlx-table-cell>
            <hlx-table-cell  :align="'center'" :fixed="'left'" >
              <!-- <i
                class="icon-code-square-regular"
                style="cursor: pointer"
                @click="changeConfig(i.configType, index)"
              ></i> -->
              <i
              :id="'import-context'+index"
                      style="position: relative"
                      class="icon-more-vertical-filled"
                      :class="i.context === true ? 'active-action' : ''"
                      @click="showrulecontextmenu(i,index)"
                    ></i>
                    <hlx-context-menu
                    :top="containertop"
            :left="containerleft"
            :conditionvalue="150"
            :conditiondown="2"
            :conditionup="50"
            style="z-index: 200000000"
                      
                        :options="computedTableAction(i.configType, index)"
                        :data="index.toString()"
                        :show="showContext == index"
                        @chosen="chosenContextMenu($event, i, index)"
                        @close-context-menu="showContext = -1"
                      />
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
      <section>
        <div
          style="
            color: #54bd95;
            font-size: 14px;
            margin-top: 8px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
          @click="addTableData"
        >
          <i class="icon-plus-circle-filled" style="margin-right: 8px"></i>
          <span>Add</span>
        </div>
      </section>
    </section>

    <!-- <div
      v-if="!showSvg && showUpload"
      class="layout-footer"
      style="
        border-top: 1px solid #d8d8d8;
        padding-right: 40px;
        display: flex;
        align-items: center;
        height: 10vh;
        margin-right: -16px;
        margin-left: -16px;
      "
    >
      <div
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button class="secondary sm" @click="cancelBack">Back</hlx-button>
        <hlx-button
          class="primary sm"
          style="margin-left: 20px"
          @click="saveTemplate"
          >Save</hlx-button
        >
      </div>
    </div> -->
  </section>
</template>

<script>
import { DMNAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";

export default {
  data() {
    return {
      code:"",
      add: false,
    currentKey:"",
    templateName:"",
      apiModal: false,
      apiConfig:[{name:"", apiRef:""}],
      showContext: -1,
      template:"",
      templateList:[],
      showUpload: true,
      templateData: {},
      show_right_filter: false,
      moduleSettings: [],
      showSvg: true,
      sourceKeys: [],
      tableHeaders: [
        {
          name: "Primary",
          checked: true,
          id: 1,
          disabled: true,
          prop: "primary",
          label: "Primary",
          sortable: false,
          resizable: true,
          width: 80,
          align: "left",
        },
        {
          name: "System fields",
          checked: true,
          id: 2,
          disabled: true,
          prop: "systemFields",
          label: "System fields",
          sortable: false,
          resizable: true,
          width: 600,
          align: "left",
        },
        {
          name: "CSV headers",
          checked: true,
          id: 3,
          disabled: true,
          prop: "csvHeaders",
          label: "CSV headers",
          sortable: false,
          resizable: true,
          width: 400,
          align: "left",
        },
        {
          name: "Source type",
          checked: true,
          id: 4,
          disabled: true,
          prop: "sourceType",
          label: "Source type",
          sortable: false,
          resizable: true,
          width: 200,
          align: "left",
        },
      ],
      operatorOptions: [
        { name: "==", value: "==" },
        { name: ">", value: ">" },
        { name: "<", value: "<" },
        { name: ">=", value: ">=" },
        { name: "<=", value: "<=" },
      ],
      stringOperators: [
        { name: "contains", value: "==" },
        { name: "not contains", value: "!=" },
      ],
      destinationKeys: [],
      tableData: [
        {
          destinationKey: "",
          required: false,
          sourceColumn: "",
          sourceType: "",
          rule: "",
          configType: "key",
        },
      ],
    };
  },

  async mounted() {
    let res = await DMNAxiosInstance.get(
      "/import/intellisense_by_module/?type=saleslead"
    );
    if (res.data.ok == 1) {
      this.destinationKeys = res.data.data.schema;
    }
    // this.destinationKeys = 
    
    
    
    // [
    //   {
    //     name: "saleslead.LeadSource",
    //     value: "saleslead.LeadSource",
    //     type: "Mixed",
    //   },
    //   {
    //     name: "saleslead.LeadSource.labels.en",
    //     value: "saleslead.LeadSource.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.LeadSource.others",
    //     value: "saleslead.LeadSource.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.Market type",
    //     value: "saleslead.Market type",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.Market type.labels.en",
    //     value: "saleslead.Market type.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.Market type.others",
    //     value: "saleslead.Market type.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead._id",
    //     value: "saleslead._id",
    //     type: "ObjectID",
    //   },
    //   {
    //     name: "saleslead._id.labels.en",
    //     value: "saleslead._id.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead._id.others",
    //     value: "saleslead._id.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.category",
    //     value: "saleslead.category",
    //     type: "Mixed",
    //   },
    //   {
    //     name: "saleslead.category.labels.en",
    //     value: "saleslead.category.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.category.others",
    //     value: "saleslead.category.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.channel",
    //     value: "saleslead.channel",
    //     type: "Mixed",
    //   },
    //   {
    //     name: "saleslead.channel.labels.en",
    //     value: "saleslead.channel.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.channel.others",
    //     value: "saleslead.channel.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.contactMedium[].labels.en",
    //     value: "saleslead.contactMedium[].labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.contactMedium[].others",
    //     value: "saleslead.contactMedium[].others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.contactSite",
    //     value: "saleslead.contactSite",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.contactSite.labels.en",
    //     value: "saleslead.contactSite.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.contactType",
    //     value: "saleslead.contactType",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.contactType.labels.en",
    //     value: "saleslead.contactType.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.createdBy",
    //     value: "saleslead.createdBy",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.createdBy.labels.en",
    //     value: "saleslead.createdBy.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.createdBy.others",
    //     value: "saleslead.createdBy.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.creationDate",
    //     value: "saleslead.creationDate",
    //     type: "Date",
    //   },
    //   {
    //     name: "saleslead.creationDate.labels.en",
    //     value: "saleslead.creationDate.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.creationDate.others",
    //     value: "saleslead.creationDate.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.description",
    //     value: "saleslead.description",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.description.labels.en",
    //     value: "saleslead.description.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.description.others",
    //     value: "saleslead.description.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.displayID",
    //     value: "saleslead.displayID",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.displayID.labels.en",
    //     value: "saleslead.displayID.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.displayID.others",
    //     value: "saleslead.displayID.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.dwellingtype",
    //     value: "saleslead.dwellingtype",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.dwellingtype.labels.en",
    //     value: "saleslead.dwellingtype.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.dwellingtype.others",
    //     value: "saleslead.dwellingtype.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.email",
    //     value: "saleslead.email",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.email.labels.en",
    //     value: "saleslead.email.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.estimatedRevenue",
    //     value: "saleslead.estimatedRevenue",
    //     type: "Mixed",
    //   },
    //   {
    //     name: "saleslead.estimatedRevenue.labels.en",
    //     value: "saleslead.estimatedRevenue.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.estimatedRevenue.others",
    //     value: "saleslead.estimatedRevenue.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.firstName",
    //     value: "saleslead.firstName",
    //     type: "text",
    //   },
    //   {
    //     name: "saleslead.firstName.labels.en",
    //     value: "saleslead.firstName.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.firstOccupancyDate",
    //     value: "saleslead.firstOccupancyDate",
    //     type: "Date",
    //   },
    //   {
    //     name: "saleslead.firstOccupancyDate.labels.en",
    //     value: "saleslead.firstOccupancyDate.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.firstOccupancyDate.others",
    //     value: "saleslead.firstOccupancyDate.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.href",
    //     value: "saleslead.href",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.href.labels.en",
    //     value: "saleslead.href.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.href.others",
    //     value: "saleslead.href.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.id",
    //     value: "saleslead.id",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.id.labels.en",
    //     value: "saleslead.id.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.id.others",
    //     value: "saleslead.id.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.lastName",
    //     value: "saleslead.lastName",
    //     type: "text",
    //   },
    //   {
    //     name: "saleslead.lastName.labels.en",
    //     value: "saleslead.lastName.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.lastUpdate",
    //     value: "saleslead.lastUpdate",
    //     type: "Date",
    //   },
    //   {
    //     name: "saleslead.lastUpdate.labels.en",
    //     value: "saleslead.lastUpdate.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.lastUpdate.others",
    //     value: "saleslead.lastUpdate.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.leadOwner",
    //     value: "saleslead.leadOwner",
    //     type: "Mixed",
    //   },
    //   {
    //     name: "saleslead.leadOwner.labels.en",
    //     value: "saleslead.leadOwner.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.leadOwner.others",
    //     value: "saleslead.leadOwner.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.leadSource",
    //     value: "saleslead.leadSource",
    //     type: "Mixed",
    //   },
    //   {
    //     name: "saleslead.leadSource.labels.en",
    //     value: "saleslead.leadSource.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.leadSource.others",
    //     value: "saleslead.leadSource.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.marketSegment",
    //     value: "saleslead.marketSegment",
    //     type: "Mixed",
    //   },
    //   {
    //     name: "saleslead.marketSegment.labels.en",
    //     value: "saleslead.marketSegment.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.marketSegment.others",
    //     value: "saleslead.marketSegment.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.marketingCampaign",
    //     value: "saleslead.marketingCampaign",
    //     type: "Mixed",
    //   },
    //   {
    //     name: "saleslead.marketingCampaign.labels.en",
    //     value: "saleslead.marketingCampaign.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.marketingCampaign.others",
    //     value: "saleslead.marketingCampaign.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.name",
    //     value: "saleslead.name",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.name.labels.en",
    //     value: "saleslead.name.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.name.others",
    //     value: "saleslead.name.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.networkId",
    //     value: "saleslead.networkId",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.networkId.labels.en",
    //     value: "saleslead.networkId.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.networkId.others",
    //     value: "saleslead.networkId.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.note[].labels.en",
    //     value: "saleslead.note[].labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.note[].others",
    //     value: "saleslead.note[].others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.numberOfSites",
    //     value: "saleslead.numberOfSites",
    //     type: "number",
    //   },
    //   {
    //     name: "saleslead.numberOfSites.labels.en",
    //     value: "saleslead.numberOfSites.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.numberOfSites.others",
    //     value: "saleslead.numberOfSites.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.numberOfUnits",
    //     value: "saleslead.numberOfUnits",
    //     type: "number",
    //   },
    //   {
    //     name: "saleslead.numberOfUnits.labels.en",
    //     value: "saleslead.numberOfUnits.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.numberOfUnits.others",
    //     value: "saleslead.numberOfUnits.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.phone",
    //     value: "saleslead.phone",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.phone.labels.en",
    //     value: "saleslead.phone.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.phoneNumber",
    //     value: "saleslead.phoneNumber",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.phoneNumber.labels.en",
    //     value: "saleslead.phoneNumber.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.preferredLanguage",
    //     value: "saleslead.preferredLanguage",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.preferredLanguage.labels.en",
    //     value: "saleslead.preferredLanguage.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.priority",
    //     value: "saleslead.priority",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.priority.labels.en",
    //     value: "saleslead.priority.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.priority.others",
    //     value: "saleslead.priority.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.productOffering",
    //     value: "saleslead.productOffering",
    //     type: "Mixed",
    //   },
    //   {
    //     name: "saleslead.productOffering.labels.en",
    //     value: "saleslead.productOffering.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.productOffering.others",
    //     value: "saleslead.productOffering.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.productSpecification",
    //     value: "saleslead.productSpecification",
    //     type: "Mixed",
    //   },
    //   {
    //     name: "saleslead.productSpecification.labels.en",
    //     value: "saleslead.productSpecification.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.productSpecification.others",
    //     value: "saleslead.productSpecification.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.rating",
    //     value: "saleslead.rating",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.rating.labels.en",
    //     value: "saleslead.rating.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.rating.others",
    //     value: "saleslead.rating.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.referredDate",
    //     value: "saleslead.referredDate",
    //     type: "Date",
    //   },
    //   {
    //     name: "saleslead.referredDate.labels.en",
    //     value: "saleslead.referredDate.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.referredDate.others",
    //     value: "saleslead.referredDate.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.relatedParty[].labels.en",
    //     value: "saleslead.relatedParty[].labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.relatedParty[].others",
    //     value: "saleslead.relatedParty[].others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.relatedParty[].contactMedium[].characteristic.phoneNumber",
    //     value:
    //       "saleslead.relatedParty[].contactMedium[].characteristic.phoneNumber",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.relatedParty[].contactMedium[].characteristic.firstName",
    //     value:
    //       "saleslead.relatedParty[].contactMedium[].characteristic.firstName",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.relatedParty[].contactMedium[].characteristic.emailAddress",
    //     value:
    //       "saleslead.relatedParty[].contactMedium[].characteristic.emailAddress",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.relatedParty[].@type",
    //     value: "saleslead.relatedParty[].@type",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.role",
    //     value: "saleslead.role",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.role.labels.en",
    //     value: "saleslead.role.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.salesOpportunity",
    //     value: "saleslead.salesOpportunity",
    //     type: "Mixed",
    //   },
    //   {
    //     name: "saleslead.salesOpportunity.labels.en",
    //     value: "saleslead.salesOpportunity.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.salesOpportunity.others",
    //     value: "saleslead.salesOpportunity.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.sdfcId",
    //     value: "saleslead.sdfcId",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.sdfcId.labels.en",
    //     value: "saleslead.sdfcId.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.sdfcId.others",
    //     value: "saleslead.sdfcId.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.siteAddress",
    //     value: "saleslead.siteAddress",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.siteAddress.labels.en",
    //     value: "saleslead.siteAddress.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.siteAddress.others",
    //     value: "saleslead.siteAddress.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.siteName",
    //     value: "saleslead.siteName",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.siteName.labels.en",
    //     value: "saleslead.siteName.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.siteName.others",
    //     value: "saleslead.siteName.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.status",
    //     value: "saleslead.status",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.status.labels.en",
    //     value: "saleslead.status.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.status.others",
    //     value: "saleslead.status.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.statusChangeDate",
    //     value: "saleslead.statusChangeDate",
    //     type: "Date",
    //   },
    //   {
    //     name: "saleslead.statusChangeDate.labels.en",
    //     value: "saleslead.statusChangeDate.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.statusChangeDate.others",
    //     value: "saleslead.statusChangeDate.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.statusChangeReason",
    //     value: "saleslead.statusChangeReason",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.statusChangeReason.labels.en",
    //     value: "saleslead.statusChangeReason.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.statusChangeReason.others",
    //     value: "saleslead.statusChangeReason.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.testField",
    //     value: "saleslead.testField",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.testField.labels.en",
    //     value: "saleslead.testField.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.testField.others",
    //     value: "saleslead.testField.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.title",
    //     value: "saleslead.title",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.title.labels.en",
    //     value: "saleslead.title.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.undefined",
    //     value: "saleslead.undefined",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.undefined.labels.en",
    //     value: "saleslead.undefined.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.validFor",
    //     value: "saleslead.validFor",
    //     type: "Mixed",
    //   },
    //   {
    //     name: "saleslead.validFor.labels.en",
    //     value: "saleslead.validFor.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.validFor.others",
    //     value: "saleslead.validFor.others",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.websiteUrl",
    //     value: "saleslead.websiteUrl",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.websiteUrl.labels.en",
    //     value: "saleslead.websiteUrl.labels.en",
    //     type: "String",
    //   },
    //   {
    //     name: "saleslead.websiteUrl.others",
    //     value: "saleslead.websiteUrl.others",
    //     type: "String",
    //   },
    // ];

    let settings = await UPCAxiosInstance.get("/importTemplate");
    this.moduleSettings = settings.data.filter(
      (i) => i.module.type == "saleslead"
    );
    this.templateList = this.moduleSettings
    this.templateList.map((i) => {i.value = i.module.name; i.name = i.value})
    this.templateData = this.moduleSettings[0].module;
    this.template = this.templateList[0].name
    if (this.moduleSettings == undefined) {
      this.moduleSettings = [];
    }
    if (this.templateData != undefined) {
      this.tableData = this.templateData.configUi;
      this.showSvg = false;
      this.showUpload = false;
      this.sourceKeys = this.templateData.configUi
        .filter((i) => i.configType == "key")
        .map((j) => {
          {
            (j.name = j.sourceColumn), (j.value = j.sourceColumn);
          }
          return j;
        });
    }
  },
  methods: {
    addTemplate(){
      this.tableData = [
        {
          destinationKey: "",
          required: false,
          sourceColumn: "",
          sourceType: "",
          rule: "",
          configType: "key",
        }
      ]
      this.showUpload = true
      this.showSvg = true
      this.apiConfig = [{name:"", apiRef:""}]
      this.add=true

    },
    addApiConfig(){
      this.apiConfig.push({name:"", apiRef:""})
    },
  saveApi(){
  this.tableData.find(i => i.destinationKey == this.currentKey).entity = this.apiConfig
  // this.tableData.find(i => i.destinationKey == this.currentKey).entity = this.apiConfig.apiRef
  this.apiModal = false
  console.log(this.tableData, this.currentKey,"key")
  this.apiConfig = [{name:"", apiRef:""}]
  },
    showrulecontextmenu(i,index) {
      this.containertop =
        document.querySelector("#import-context"+index).getBoundingClientRect().top +
        50;
      this.containerleft =
        document.querySelector("#import-context"+index).getBoundingClientRect().left -
        180;
        this.showContext = index
    },
    chosenContextMenu(val,i, index){
      if(val.toLowerCase().includes('switch')){
      if (i.configType == "key") {
        this.tableData[index].configType = "rule";
      } else {
        this.tableData[index].configType = "key";
      }
    }else if(val.toLowerCase() == "api config"){
      if(i.entity == undefined || i?.entity[0]?.name?.length == 0 ){
        i.entity = [{name:"", apiRef:""}]
      }
      this.currentKey = i.destinationKey
      this.apiConfig = i.entity
      this.apiModal = true
    }else if(val.toLowerCase() == "delete"){
      this.tableData.splice(index, 1)
    }
    this.showContext = -1

    },
    computedTableAction(configType, index) {
      console.log(index)
      let options = [
        {
          label: "Switch to rule",
          icon: "icon-file-text-edit-regular"
        },
        {
          label: "Delete",
          icon: "icon-trash-regular"
        }
      ];
      if(configType == "rule"){
        options = [
        {
          label: "Switch to key",
          icon: "icon-file-text-edit-regular"
        },
        {
          label: "API config",
          icon: "icon-file-code-regular"
        },
        {
          label: "Delete",
          icon: "icon-trash-regular"
        }
      ];
      }
      return options
     
    },
    cancelBack(){
      this.$router.back()
    },
    loadTemplate(template){
      this.templateData = this.templateList.find(i => i.name == template).module
      console.log(this.templateData,"template")
      this.tableData = this.templateData.configUi;
      this.showSvg = false;
      this.showUpload = false;
      this.sourceKeys = this.templateData.configUi
        .filter((i) => i.configType == "key")
        .map((j) => {
          {
            (j.name = j.sourceColumn), (j.value = j.sourceColumn);
          }
          return j;
        });
    },
    assignVal(val, index, indexx) {
      if (val.length > 0) {
        this.tableData[index]["filterValues"][indexx] = val;
      }
      console.log(this.tableData, "assignVal");
    },
    assignNum(val, index, indexx) {
      if (val.length > 0) {
        this.tableData[index]["filterValues"][indexx]["value"] = val;
      }
      console.log(this.tableData, "assignNum");
    },
    assignOp(val, index, indexx) {
      if (val.name.length > 0) {
        console.log(this.tableData[index],"this.tableData[index]")
        this.tableData[index]["filterValues"][indexx]["operator"] = val.value;
      }
      console.log(this.tableData, "assignVal");
    },
    assignStr(val, index) {
      if (val.name.length > 0) {
        this.tableData[index]["operator"] = val.value;
      }
      console.log(this.tableData, "assignVal");
    },
    deleteVal(index, indexx) {
      this.tableData[index]["filterValues"].splice(indexx, 1);
      console.log(this.tableData, "deleteVal");
    },
    addInputs(index) {
      this.tableData[index]["filterValues"].push("");
      console.log(this.tableData, "addInputs");
    },
    addInputsNum(index) {
      this.tableData[index]["filterValues"].push({ operator: "", value: "" });
      console.log(this.tableData, "addInputs");
    },
    getType(destinationKey, index) {
      this.tableData[index]["sourceType"] = this.destinationKeys.find(
        (i) => i.name == destinationKey
      ).type;
    },
    async readFile(event) {
      const file = event.target.files[0]; // Get the uploaded file from the input
      if (!file) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("file", file, file.name);
      try {
        let res = await DMNAxiosInstance.post(
          `/import/extract_headers`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.data.ok == 1) {
          this.showSvg = false;
          this.sourceKeys = res.data.data;
        }
      } catch (error) {
        console.error(
          "Error uploading file:",
          error.response?.data || error.message
        );
      }
    },
    // openContext(configType, index) {
    //   if (configType == "key") {
    //     this.tableData[index].configType = "rule";
    //   } else {
    //     this.tableData[index].configType = "key";
    //   }
    // },
    addTableData() {
      this.tableData.push({
        destinationKey: "",
        required: false,
        sourceColumn: "",
        sourceType: "",
        rule: "",
        configType: "key",
      });
    },
    async saveTemplate() {
      let templateObj = {};
      let obj = {};

      for (const i of this.tableData) {
        if (i.configType === "key") {
          obj[i.destinationKey] = { type: "value", key: i.sourceColumn, required: i.required };
        } else {
          const ruleRef = await this.createAndSaveRule(
            i.rule,
            i.destinationKey
          );
          obj[i.destinationKey] = {
            type: "rule",
            key: i.destinationKey.split(".").pop().replaceAll("[]",""),
            required: i.required,
            entity:{
              name:i?.entity?.name,
              apiRef:i?.entity?.apiRef
            },
            ruleRef,
          };
        }
        if(i.entity == undefined || i?.entity?.name == undefined || i?.entity?.name?.length == 0){
          delete obj[i.destinationKey].entity
        }
      }
      let filterRef = await this.createAndSaveRuleFilterRule(this.tableData);

      if(this.templateName.length != 0){
        this.template = this.templateName
      }
      templateObj["type"] = "saleslead";
      templateObj["name"] = this.template;
      templateObj["fieldMapping"] = obj;
      if(this.code != null){
      templateObj["dataFilter"] = { ruleRef: filterRef };
      }
      templateObj["configUi"] = this.tableData;
      let foundTemplate = this.templateList.find(i => i.module.name == this.template)
      if(foundTemplate){
        await UPCAxiosInstance.patch("/importTemplate", {_id:foundTemplate._id, id:foundTemplate.id, displayID:foundTemplate.displayID,module: templateObj});
      }else{
        await UPCAxiosInstance.post("/importTemplate", { module: templateObj });
      }
    },

    async createAndSaveRule(rule, destinationKey) {
      const ruleObj = {
        name: `Transformation rule- ${destinationKey} - ${Math.random()}`,
        description: `Transformation rule- ${destinationKey}`,
        ruleScript: rule,
        visualBuilderPayload: {
          type: "compute",
          config: {
            expression: rule,
          },
        },
        executionByPass: true,
        isAtomic: true,
        status: "active",
        type: "expression",
        buildMode: "Visual",
        ruleSet: [],
        createdBy: "639af00f9f515f57074584ef",
        _isExistBypass: false,
      };

      try {
        const response = await DMNAxiosInstance.post(
          "rule_engine/rule/add",
          ruleObj
        );
        return response.data.data["_id"];
      } catch (error) {
        console.error(`Error saving rule for ${destinationKey}:`, error);
        throw error; // Optional: Decide if you want to fail the entire process
      }
    },
    async createAndSaveRuleFilterRule(tableData) {
      let code = await this.generateFilterCode(tableData);
      console.log("code", code);
      this.code = code
      if(code != null){
      code = `Exec(${code})`;
      const ruleObj = {
        name: `Filter rule - ${Math.random()}`,
        description: `Filter rule`,
        ruleScript: code,
        visualBuilderPayload: {
          type: "compute",
          config: {
            expression: code,
          },
        },
        executionByPass: true,
        isAtomic: true,
        status: "active",
        type: "expression",
        buildMode: "Visual",
        ruleSet: [],
        createdBy: "639af00f9f515f57074584ef",
        _isExistBypass: false,
      };

      try {
        const response = await DMNAxiosInstance.post(
          "rule_engine/rule/add",
          ruleObj
        );
        return response.data.data["_id"];
      } catch (error) {
        console.error(`Error saving rule`, error);
        throw error; // Optional: Decide if you want to fail the entire process
      }
    }
    },
    // async generateFilterCode(config) {
    //   console.log(config,"config")
    //     const conditions = config.map(({ sourceColumn, sourceType, filterValues }) => {
    //         if (filterValues.length === 0 && filterValues[0] != '') return null;

    //         if (sourceType === "Number") {
    //             // Generate conditions for numbers
    //             return filterValues
    //                 .map(({ operator, value }) => `i['${sourceColumn}'] ${operator} ${value}`)
    //                 .join(" && ");
    //         } else if (sourceType === "String") {
    //             // Generate conditions for strings
    //             const values = filterValues.map((value ) => `i['${sourceColumn}'] == '${value}'`);
    //             return `(${values.join(" || ")})`;
    //         }

    //         return null;
    //     });

    //     // Combine all conditions into one filter statement
    //     const combinedConditions = conditions.filter(Boolean).join(" && ");
    //     return `sourceData = sourceData.filter(i => ${combinedConditions});`;
    // },
    async generateFilterCode(config) {
      console.log(config, "config");
      const conditions = config.map(
        ({ sourceColumn, sourceType, filterValues, operator }) => {

          if (
          sourceType.toLowerCase() != "number" &&
         filterValues == undefined
        ) {
          filterValues = [""]
        } else if (
          sourceType.toLowerCase() == "number" &&
          filterValues == undefined
        ) {
          filterValues = [{ operator: "", value: "" }] };
        
      

          if (filterValues?.length === 0 || filterValues?.operator?.length === 0 || filterValues[0].length === 0) return null;

          if (sourceType.toLowerCase() == "number") {
            if (filterValues[0].operator.length === 0) return null;
            // Generate conditions for numbers
            return filterValues
              .map(
                ({ operator, value }) =>
                  `i['${sourceColumn}'] ${operator} ${value}`
              )
              .join(" && ");
          } else if (sourceType.toLowerCase() != "number") {
            // Filter out empty values and generate conditions for strings
            const values = filterValues.filter(Boolean); // Remove empty strings
            if (values.length === 0) return null; // No valid filter values left
            // return `(${values.map(value => `i['${sourceColumn}'] ${operator} '${value}'`).join(" || ")})`;
            return `(${values
              .map((value) => `i['${sourceColumn}'] ${operator} '${value}'`)
              .join(" || ")})`;
          }

          return null;
        }
      );

      // Combine all conditions into one filter statement
      const combinedConditions = conditions.filter(Boolean).join(" && ");
      return combinedConditions
        ? `sourceData = sourceData.filter(i => ${combinedConditions});`
        : null;
    },

    async filterRule() {
      this.show_right_filter = true;
      this.tableData = this.tableData.map((key) => {
        if (
          key.sourceType.toLowerCase() != "number" &&
          key.filterValues == undefined
        ) {
          return { ...key, filterValues: [""] };
        } else if (
          key.sourceType.toLowerCase() == "number" &&
          key.filterValues == undefined
        ) {
          return { ...key, filterValues: [{ operator: "", value: "" }] };
        }
        // Return the user object without changes if condition is not met
        return key;
      });
    },
  },
};
</script>

<style lang="scss">
.importContainer {
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  .header {
    .new-layout-header {
      .subtitle {
        font-family: "OpenSans";
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  .body {
    padding-top: 1.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    .left {
      width: 100%;
    }
    .right {
      margin-left: 20px;
    }

    .subtitle {
      font-family: "OpenSans";
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .svg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .layout-footer {
    .footer-container {
      width: 100%;
      display: flex;
      gap: 1.3em;
      justify-content: flex-end;

      .primary {
        margin-left: 20px;
      }
    }
  }
}

.panel-body {
  border: 1px solid #d8d8d8;
  margin-top: 16px;
  border-radius: 3px;
  .string-container {
    display: flex;
    flex-direction: column;
    .right {
      margin-left: 10px;
    }
  }
  .accordion-item {
    .accordion-header {
      font-size: 0.85rem !important;
      font-weight: 200 !important;
    }
    .accordion-content {
      font-size: 0.75rem !important;
      font-weight: 200 !important;
      background: #fff !important;
      padding-left: 24px !important;
    }
  }
}
</style>
