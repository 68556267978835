<template>
  <!-- Filter drawer-->
  <hlx-drawer
    :show="show_right_filter"
    :width="300"
    position="right"
    :header="false"
    :footer="false"
    :show-close-icon="true"
    @close="closeRightFilter"
  >
    <template #body>
      <div style="display: flex">
        <FilterComponent
          :display-alternate-values="displayAlternate"
          :pre-value="dashboardPreval"
          :filterpanelname="'productOffering'"
          :company-name="company"
          :query-value="query"
          :display-data="displayFilterData"
          :module="'productOffering'"
          @filtered-data="offeringFilterData"
        ></FilterComponent>
      </div>
    </template>
  </hlx-drawer>

  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />

  <section class="choose-bundle-modal-window">
    <hlx-modal
      :modal-active="Add_bundle_modal"
      :height="'383px'"
      :width="'580px'"
      :modal="true"
      @close="Add_bundle_modal = false"
    >
      <template #header>
        <div style="margin-left: 0px">Add bundle offer</div>
      </template>

      <template #content>
        <div class="modal-content">
          <section class="choose-bundle-modal-window-cover">
            <section
              class="bundle-onetime"
              style="
                border: 1px solid #a7a7a78c;
                border-radius: 4px;
                width: 220px;
                height: 135px;
                padding: 24px 14px 24px 14px;
              "
              @click="selectedType = 'Onetime'"
            >
              <span
                style="
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  font-weight: bold;
                  padding-bottom: 18px;
                "
              >
                <i style="font-size: 36px" class="icon-credit-card-regular"></i>
                <span
                  style="
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17.5px;
                    letter-spacing: 0.07em;
                    text-align: left;
                    cursor: pointer;
                  "
                  @click="selectedType = 'Onetime'"
                  >One time</span
                >
              </span>
              <section style="color: #8a8a8a; font-size: 10px">
                One-time pricing refers to a single, upfront payment for a
                product or service
              </section>
            </section>
            <section
              class="bundle-recurring"
              style="
                border: 1px solid #a7a7a78c;
                border-radius: 4px;
                width: 220px;
                height: 135px;
                padding: 24px 14px 24px 14px;
              "
              @click="selectedType = 'Recurring'"
            >
              <span
                style="
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  font-weight: bold;
                  padding-bottom: 18px;
                "
                @click="selectedType = 'Recurring'"
              >
                <i
                  style="font-size: 36px"
                  class="icon-credit-card-change-regular"
                ></i>
                <span
                  style="
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17.5px;
                    letter-spacing: 0.07em;
                    text-align: left;
                  "
                  >Recurring</span
                >
              </span>
              <section style="color: #8a8a8a; font-size: 10px">
                Recurring pricing involves regular payments (e.g., monthly or
                annually)
              </section>
            </section>
          </section>
        </div>
      </template>

      <template #footer>
        <hlx-button class="secondary sm" @click="Add_bundle_modal = false"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary sm"
          style="margin-left: 16px"
          @click="categoryOffer(selectedType)"
          >Continue</hlx-button
        >
      </template>
    </hlx-modal>
  </section>

  <!-- Add modal/ -->
  <div>
    <hlx-modal
      class="upc"
      :modal-active="add_model"
      :height="'373px'"
      :width="'550px'"
      :modal="true"
      @close="add_model = false"
    >
      <template #header>
        <div style="margin-left: 0px">
          Add {{ offerType?.toLowerCase() }} offering
        </div>
      </template>
      <template #content>
        <div class="modal-content">
          <div v-if="offerType == 'Atomic'">
            <hlx-select
              :key="componentKey"
              v-model:value="offerPayload.productSpecification.name"
              :inline-search="true"
              :options="specdata"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Search product specification'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :clearable="true"
              @selected-value="handleSpecChanges"
            ></hlx-select>
          </div>
          <div v-if="offerType == 'Bundle'">
            <hlx-multi-select
              :key="componentKey"
              :options="selectedOffer"
              :placeholder="'Search product offering'"
              :label-animation="true"
              :prop-value="'name'"
              :label="'name'"
              :required="true"
              :disabled="false"
              :filter-select="false"
              :tooltipposition="'left'"
              @selected-array="receivedSelectedOffers"
            ></hlx-multi-select>
          </div>
          <br />

          <div class="modal-input-v2">
            <div class="input-section-v2">
              <hlx-input
                v-model:value="offerPayload.name"
                :required="true"
                :label-animation="true"
                label-value="Offer name"
                type="text"
                :clearable="true"
              />
            </div>
            <div class="input-section-v2">
              <hlx-multi-select
                :key="componentKey"
                :options="tagsOptions"
                :pre-value="offerPayload.tags"
                :placeholder="'Tags'"
                :label-animation="true"
                :prop-value="'name'"
                :label="'name'"
                :disabled="false"
                :filter-select="false"
                :tooltipposition="'left'"
                @add-data="addTagList"
                @custom-change="receivedTags"
              ></hlx-multi-select>
            </div>

            <!-- {{specdata}} -->
            <div class="input-section-v2">
              <hlx-multi-select
                :key="componentKey"
                :options="marketOptions"
                :placeholder="'Market'"
                :label-animation="true"
                :prop-value="'name'"
                :label="'name'"
                :disabled="false"
                :filter-select="false"
                :tooltipposition="'left'"
                @selected-array="marketValues"
              ></hlx-multi-select>
            </div>

            <div class="input-section-v2">
              <hlx-multi-select
                :key="componentKey"
                :options="channelOptions"
                :placeholder="'Channel'"
                :label-animation="true"
                :prop-value="'name'"
                :label="'name'"
                :disabled="false"
                :filter-select="false"
                :tooltipposition="'left'"
                @selected-array="channelValues"
              ></hlx-multi-select>
            </div>
            <div class="input-section-v2">
              <hlx-select
                :key="componentKey"
                v-model:value="offerPayload.relatedParty[0].name"
                :inline-search="true"
                :options="partyOptions"
                :placeholder-value="'Product contact'"
                :prop-value="'name'"
                :label="'name'"
                :label-animation="true"
                :clearable="true"
              ></hlx-select>
            </div>
            <div class="input-section-v2">
              <!-- <hlx-select
                :key="componentKey"
                :inline-search="true"
                v-model="offerPayload.lifecycleStatus"
                :options="statusOptions"
                :slot-options="false"
                :placeholder-value="'Status'"
                :prop-value="'name'"
                :label="'name'"
                :label-animation="true"
                :clearable="true"
              ></hlx-select> -->

              <!-- <hlx-select
                      
                      v-model="offerPayload.lifecycleStatus"
                      :slot-options="true"
                      :options="statusOptions"
                      :placeholder-value="'Status'"
                      :label="'name'"
                      :prop-value="'name'"
                      :label-animation="true"
                      :inline-search="true"
                      @selected-value="emitStatus"
                    >
                    <template #displayValue="value">
        <span style="padding-left: 0px; display: flex; align-items: center">
          <span
            :style="{
              height: '8px',
              width: '8px',
              borderRadius: '50%',
              backgroundColor: value.displayLabel.color,
              marginLeft: '-2px',
              marginRight: '8px'
            }"
          ></span>
          {{ value.displayLabel.name }}</span
        >
      </template>
      <template #options="option">
        <span style="padding-left: 8px; display: flex; align-items: center">
          <span
            :style="{
              height: '8px',
              width: '8px',
              borderRadius: '50%',
              backgroundColor: option.optionsData.color,
              marginLeft: '4px',
              marginRight: '8px',
            }"
          ></span
          >{{ option.optionsData.name }}</span
        >
      </template>
                </hlx-select> -->

              <div style="width: 100%">
                <hlx-status
                  :type="'select-status'"
                  :status-option="statusList"
                  :prevalue="offerPayload.lifecycleStatus"
                  @emit-select="emitStatus"
                >
                </hlx-status>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="add_model = false"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary sm"
          style="margin-left: 16px"
          :disabled="!enableButton"
          @click="addProductSpec()"
          >Add</hlx-button
        >
      </template>
    </hlx-modal>
  </div>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'200px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <div class="app-layout catalog offering">
    <section class="new-right-panel upc">
      <div class="new-right-panel-parent-container no-filter-panel">
        <div id="mySidepanel" class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <section class="new-layout-basic-no-breadcrumb">
            <div class="new-layout-header">
              <span class="new-layout-header-container">
                <span class="schema-header-title">Product catalogue </span>
                <span class="subtitle">
                  Centralized catalog for products offers.
                </span>
              </span>
            </div>

            <div
              v-if="view === 'Product offerings' && datalength != 0"
              class="new-layout-body catalog-v2"
            >
              <!-- search and filter  -->
              <div
                v-if="view === 'Product offerings' && datalength != 0"
                class="header-utils-and-switch-container"
                style="width: 100%"
              >
                <span class="table-header-utils">
                  <span class="util-items --search"
                    ><hlx-search
                      v-model="searchvalue"
                      @search-key="searchKey"
                    ></hlx-search
                  ></span>

                  <span class="filter-table">
                    <hlx-button
                      class="secondary sm add-btn"
                      @click="showDrawerRight"
                    >
                      <i class="icon-filter-regular"></i>
                      <span v-if="filtertaglength != 0" class="filter-count">{{
                        filtertaglength
                      }}</span>
                    </hlx-button>
                  </span>

                  <span
                    v-if="view === 'Product offerings'"
                    id="add-product-offer"
                    class="util-items util-items-add"
                    ><hlx-button
                      v-if="permisstionData.add "
                      class="primary sm add-btn"
                      @click.stop="contextOffer()"
                      ><i
                        class="icon-plus-circle-regular"
                        style="margin-right: 5px"
                      ></i
                      >New offer</hlx-button
                    >
                    <hlx-context-menu
                      :top="containertop1"
                      :left="containerleft1"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      style="z-index: 10000"
                      :options="add_context_options"
                      :show="show_add_offer"
                      :style="{ marginTop: '5px', marginRight: '20px' }"
                      @chosen="specOffer"
                    ></hlx-context-menu>
                  </span>
                </span>
              </div>

              <!-- TableData -->
              <div v-if="tableData?.length > 0" class="table-wrap">
                <hlx-table
                  :column-count="product_offerings_theads.length + 2"
                  :border="['table', 'header', 'horizontal']"
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="product_offerings_theads_cc"
                  @sorting_func="sorting_Data"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'center'"
                      style="padding: 0 15px"
                      :fixed="'left'"
                      @new-resize-width="resizedWidth"
                      >{{ "S.no" }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-for="(i, index) in product_offerings_theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :fixed="i.fixed"
                      :width="i.width !== undefined ? i.width : ''"
                      :align="i.align !== undefined ? i.align : ''"
                      @sorting_func="sorting_Data"
                      @new-resize-width="resizedWidth"
                    >
                      {{ i.label }}</hlx-table-head
                    >

                    <hlx-table-head
                      v-if="permisstionData.edit || permisstionData.delete"
                      :align="'center'"
                      :width="70"
                      :fixed="'right'"
                      @new-resize-width="resizedWidth"
                      ><span class="action-icon">
                        <i
                          class="icon-settings-regular"
                          @click="reAssignOrgTableHeader()"
                        ></i> </span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr v-for="(i, index) in paginatedData" id="" :key="index">
                      <hlx-table-cell :align="'center'" :fixed="'left'">
                        {{ serialNumber(index) }}
                      </hlx-table-cell>
                      <hlx-table-cell
                        v-for="(j, col_index) in product_offerings_theads"
                        :key="col_index"
                        :align="j.align"
                        :fixed="j.fixed"
                      >
                        <!-- {{i}}
                    {{j.prop}} -->
                        <div
                          v-if="
                            [
                              'type',
                              'name',
                              'updatedAt',
                              'executionCount',
                              'averageDuration',
                              'validFor',
                              'unitOfMeasure',
                            ].includes(j.prop) === true
                          "
                        >
                          <div
                            v-if="j.prop === 'name' && permisstionData.edit"
                            class="link-name"
                            style="width: 200px"
                            @click="viewPage(i)"
                          >
                            {{
                              i[j.prop].charAt(0).toUpperCase() +
                              i[j.prop].slice(1)
                            }}
                          </div>
                          <div
                            v-if="j.prop === 'name' && !permisstionData.edit "
                          
                          >
                            {{
                              i[j.prop].charAt(0).toUpperCase() +
                              i[j.prop].slice(1)
                            }}
                          </div>
                        </div>
                        <!-- For status -->
                        <div v-else-if="j.prop?.toLowerCase() === 'offertype'">
                          <div style="display: flex; align-items: center">
                            <i
                              v-if="i[j.prop] == 'Atomic'"
                              class="icon-box-regular"
                            ></i>
                            <i v-else class="icon-boxes-regular"></i>
                            {{ i[j.prop] }}
                          </div>
                        </div>
                        <div
                          v-else-if="j.prop?.toLowerCase() === 'description'"
                          :id="j.prop + index"
                          style="
                            width: 300px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          @mouseover="
                            tooltipover(
                              j.prop + index,
                              'tooltip-text' + j.prop + index
                            )
                          "
                          @mouseleave="
                            tooltipleave(
                              j.prop + index,
                              'tooltip-text' + j.prop + index
                            )
                          "
                        >
                          {{ i[j?.prop] }}
                          <p
                            id="tooltip-text"
                            :class="'tooltip-text' + j.prop + index"
                          >
                            {{ i[j?.prop] }}
                          </p>
                        </div>

                        <!-- market segement -->
                        <div
                          v-else-if="j.prop?.toLowerCase() === 'marketsegment'"
                        >
                          <div v-if="i[j.prop]?.length > 0">
                            <hlx-tagcount
                              :arrayfile="i[j.prop]"
                              :item-key="'name'"
                              :type="'label'"
                            ></hlx-tagcount>
                            <!-- <ul style="list-style: none">
                              <li
                                v-for="val in i[j.prop]"
                                :key="val"
                                style="display: flex"
                              >
                                {{
                                  val.name.charAt(0).toUpperCase() +
                                  val.name.slice(1)
                                }}
                              </li>
                            </ul> -->
                          </div>
                        </div>
                        <!-- Price -->
                        <div
                          v-else-if="j.prop == 'productOfferingPrice'"
                          class="product-price"
                        >
                          {{ computedProductPrice(i) }}
                        </div>
                        <!-- Party -->
                        <div
                          v-else-if="j.prop?.toLowerCase() == 'relatedparty'"
                        >
                          <ul
                            v-if="i[j.prop]?.length > 0"
                            style="list-style: none"
                          >
                            <li
                              v-for="val in i[j.prop]"
                              :key="val"
                              style="display: flex"
                            >
                              {{ val?.length }}
                              <div
                                v-if="
                                  val?.role?.toLowerCase() == 'product owner'
                                "
                              >
                                {{
                                  val?.name?.charAt(0)?.toUpperCase() +
                                  val?.name?.slice(1)
                                }}
                              </div>
                            </li>
                          </ul>
                        </div>

                        <!-- product offering price -->
                        <!-- <div v-else-if="j.prop?.toLowerCase() == 'productofferingprice'">
                          <ul style="list-style: none" v-if="i[j.prop].length>0">
                            <li
                              v-for="val in i[j.prop]"
                              :key="val"
                              style="display: flex"
                            >
                                {{
                                  val?.productPrice?.dutyFreeAmount
                                }}
                            </li>
                          </ul>
                        </div> -->
                        <div
                          v-else-if="
                            j.prop?.toLowerCase() === 'lifecyclestatus'
                          "
                        >
                          <hlx-status :type="i[j.prop]"></hlx-status>
                        </div>
                        <div v-else>
                          {{ i[j.prop] }}
                        </div>
                      </hlx-table-cell>
                      <hlx-table-cell
                        v-if="permisstionData.edit || permisstionData.delete"
                        v-show="editMode == true"
                        :align="'center'"
                        :width="120"
                        :fixed="'right'"
                      >
                        <span :id="'table-context' + index" class="action-icon">
                          <!-- <i class="icon-edit-regular"></i> &nbsp;
                      <i class="icon-copy-regular"></i> &nbsp;
                      <i class="icon-trash-regular"></i> -->
                          <i
                            style="position: relative"
                            class="icon-more-vertical-filled"
                            :class="i.context === true ? 'active-action' : ''"
                            @click="crudContextMenu($event, index)"
                          >
                            <hlx-context-menu
                              :top="containertop"
                              :left="containerleft"
                              :conditionvalue="150"
                              :conditiondown="2"
                              :conditionup="-38"
                              :options="computedTableAction(i)"
                              :data="index.toString()"
                              :show="i.context == true"
                              @chosen="closeCrudContextMenu($event, i, index)"
                            />
                            <!-- <span
                          v-if="index < rowsPerPagePS / 2"
                          style="position: absolute"
                        >
                          <hlx-context-menu
                            style="
                              z-index: 9000;
                              margin-right: 100px !important;
                              margin-top: -10px;
                              box-shadow: 0 0 20px 0 #d8d8d8;
                            "
                            :options="computedTableAction(i)"
                            :data="index.toString()"
                            :show="i.context == true"
                            @chosen="closeCrudContextMenu($event, i)"
                          />
                        </span>
                        <span v-else style="position: absolute">
                          <hlx-context-menu
                            style="
                              z-index: 9000;
                              margin-right: 100px !important;
                              margin-top: -125px;
                              box-shadow: 0 0 20px 0 #d8d8d8;
                            "
                            :options="computedTableAction(i)"
                            :data="index.toString()"
                            :show="i.context == true"
                            @chosen="closeCrudContextMenu($event, i)"
                          />
                        </span> -->
                          </i>
                        </span>
                      </hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>

              <div
                v-else-if="tableData <= 0 && datalength != 0"
                class="table-wrap"
              >
                <hlx-table
                  :column-count="product_offerings_theads.length + 2"
                  :border="['table', 'header']"
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="product_offerings_theads_cc"
                  @sorting_func="sorting_Data"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'center'"
                      style="padding: 0 15px"
                      :fixed="'left'"
                      >{{ "S.no" }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-for="(i, index) in product_offerings_theads"
                      :key="index"
                      :prop="i.prop"
                      :fixed="i.fixed"
                      :width="i.width !== undefined ? i.width : ''"
                      :align="i.headAlign !== undefined ? i.headAlign : ''"
                      @sorting_func="sorting_Data"
                    >
                      {{ i.label }}</hlx-table-head
                    >

                    <hlx-table-head
                      v-if="permisstionData.edit || permisstionData.delete"
                      v-show="editMode == true"
                      :align="'center'"
                      :width="70"
                      :fixed="'right'"
                      ><span class="action-icon">
                        <i class="icon-settings-regular"></i> </span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell :colspan="theads.length + 2">{{
                        "No data"
                      }}</hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>
            </div>

            <!-- no data  -->

            <div
              v-else-if="tableData?.length <= 0 && datalength != 0"
              class="table-wrap"
            >
              <hlx-table
                :column-count="product_offerings_theads?.length + 2"
                :border="['table', 'header', 'horizontal']"
                :bold-headers="false"
                :row-hover="true"
                theme="grey"
                :striped-rows="false"
                :re-arrange-columns="re_arrange"
                :re-arrange-heads="product_offerings_theads_cc"
                @sorting_func="sorting_Data"
                @close-rearrange="re_arrange = false"
                @apply-rearranged-columns="applyColumns"
              >
                <template #thead>
                  <hlx-table-head
                    :width="60"
                    :align="'center'"
                    style="padding: 0 15px"
                    :fixed="'left'"
                    >{{ "S.no" }}</hlx-table-head
                  >
                  <hlx-table-head
                    v-for="(i, index) in product_offerings_theads"
                    :key="index"
                    :prop="i.prop"
                    :sortable="i.sortable"
                    :resizable="i.resizable"
                    :fixed="i.fixed"
                    :width="i.width !== undefined ? i.width : ''"
                    :align="i.align !== undefined ? i.align : ''"
                    @sorting_func="sorting_Data"
                  >
                    {{ i.label }}</hlx-table-head
                  >

                  <hlx-table-head
                    v-if="permisstionData.edit || permisstionData.delete"
                    v-show="editMode == true"
                    :align="'center'"
                    :width="70"
                    :fixed="'right'"
                    ><span class="action-icon">
                      <i class="icon-settings-regular"></i> </span
                  ></hlx-table-head>
                </template>
                <template #tbody>
                  <tr>
                    <hlx-table-cell></hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell></hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell></hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell></hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell :colspan="theads?.length + 2">{{
                      "No data"
                    }}</hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell></hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell></hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell></hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell></hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell></hlx-table-cell>
                  </tr>
                </template>
              </hlx-table>
            </div>

            <!-- no data  -->

            <div
              v-else-if="
                tableData?.length <= 0 &&
                view === 'Product offerings' &&
                datalength == 0
              "
              class="new-layout-body"
              style="
                display: flex !important;
                flex-direction: column;
                align-items: center !important;
                padding: 5%;
              "
            >
              <section>
                <img
                  class="file-icon"
                  src="@/assets/images/svg/offer.svg"
                  alt=""
                />
              </section>

              <span
                v-if="view === 'Product offerings'"
                id="add-product-offer"
                class="util-items util-items-add"
                ><hlx-button
                  v-if="permisstionData.add "
                  class="primary sm add-btn"
                  @click.stop="contextOffer()"
                  ><i
                    class="icon-plus-circle-regular"
                    style="margin-right: 5px"
                  ></i
                  >New offer</hlx-button
                >
                <hlx-context-menu
                  :top="containertop1"
                  :left="containerleft1"
                  :conditionvalue="150"
                  :conditiondown="2"
                  :conditionup="-38"
                  style="z-index: 10000"
                  :options="add_context_options"
                  :show="show_add_offer"
                  :style="{ marginTop: '5px', marginRight: '20px' }"
                  @chosen="specOffer"
                ></hlx-context-menu>
              </span>
            </div>

            <div
              v-if="view === 'Product offerings'"
              class="new-layout-footer"
            ></div>
          </section>
        </div>
      </div>

      <div
        v-if="tableData?.length > 0 && view === 'Product offerings'"
        class="pagination-container"
        style="padding: 2%"
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :total="totalpageno"
          enable-rows-per-page
          rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import FilterComponent from "../../components/filterPanelComponent.vue";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    FilterComponent,
  },
  props: {
    value: {
      type: String,
      default: "All",
    },
  },
  data() {
    return {
      permisstionData: {},
      tableHeaderOrg: [],
      tableHeaderOrgcc: [],
      foundCategory: [],
      payloadSpec: {
        category: [],
        validFor: {
          endOfLife: "",
          endOfDate: "",
        },

        description: "",
        unitOfMeasure: "",
        lifecycleStatus: "In study",
        prodSpecCharValueUse: [],
        prodSpecCharValue: [],
        bundledProductOffering: [{ name: "" }],
        productOfferingPrice: [],
        marketSegment: [],
        channel: [],
        productSpecification: { name: "" },
        relatedParty: [{ name: "", role: "Product owner" }],
        status: "",
        name: "",
        tags: [],
        notes: [],
        attachment: [{ media: [] }],
      },
      bundleAlteration: {
        discount: 0,
        discountType: {
          value: "%",
          name: "Percentage",
        },
      },
      bundleTotalcheck: {
        id: uuidv4(),
        priceType: "One time",
        totalPrice: {
          unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          value: 0,
        },
        bundleAlteration: {
          discount: 0,
          discountType: {
            value: "%",
            name: "Percentage",
          },
        },
        tiers: [
          {
            min: 1,
            max: 99,
            price: {
              unit: this.$store.getters.getDefaultSettings?.currency?.unit,
              value: 0,
            },
          },
        ],
        type: "Bundle",
        MSRP: {
          unit: this.$store?.getters?.getDefaultSettings?.currency?.symbol,
          value: 0,
        },
        validFor: {
          startDate: new Date().toISOString(),
          endDate: "9999-12-31T00:00:00Z",
        },
        lifecycleStatus: "Active",
        commitmentLength: 1,
        billingPeriod: "Monthly",
        displayValue: "Months",
        endOfCommitment: {
          autoPay: true,
          expireTerm: false,
        },
      },
      totalPrice: 0,
      selectedType: "Onetime",
      Add_bundle_modal: false,
      selectedOffer: [],
      add_bundle_model: false,
      displayAlternate: [{ name: "Product offering price", display: "number" }],
      dashboardPreval: {},
      filterQuery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: "",
      },
      query: {
        Status: ["lifecycleStatus"],
        Place: ["place.name"],
        Channel: ["channel.name"],
        "Market segment": ["marketSegment.name"],
        "Product offering term": ["productOfferingTerm.name"],
        "Product offering price": [
          "productOfferingPrice.productPrice.dutyFreeAmount",
        ],
        Category: ["category"],
      },
      displayFilterData: {},
      categoryInfo: null,
      offerPayload: {
        attachment: [],
        isBundle: true,
        relatedProduct: [],
        lifecycleStatus: "In study",
        bundledProductOffering: [],
        marketSegment: [],
        channel: [],
        productOfferingPrice: [],
        productSpecification: { name: "" },
        relatedParty: [
          { name: this.$cookies.get("userName"), role: "Product owner" },
        ],
        status: "",
        name: "",
        tags: [],
        category: [],
      },
      partyOptions: [],
      tagsOptions: [],
      marketOptions: [],
      channelOptions: [],
      specdata: [],
      offerType: "",
      show_add_offer: false,
      add_context_options: [
        {
          label: "Atomic",
          value: "atomic",
          icon: "icon-box-regular",
        },
        {
          label: "Bundle",
          value: "bundle",
          icon: "icon-boxes-regular",
        },
      ],
      statusList: [],
      containerleft1: 0,
      containertop1: 0,
      error: false,
      errorMessage: "",
      componentKey: 1,
      datalength: 0,
      enableButton: false,

      categoryOptions: [],
      add_model: false,
      show: false,
      externalRowsPerPage: 10,
      show_right_filter: false,
      currencylist: [],
      filterPanelName: "",
      recurringChargePeriodType: {},
      priceobj: {},
      company: this.$cookies.get("company"),
      showpricedata: {},
      showPriceValue: false,
      offeringfiltercondition: [],
      offeringprice: [],
      filteredarray: [],
      filteredobj: { $in: [] },
      productOfferingfilteredobj: { $in: [] },
      productOfferingfilter: [],
      Specfilterquery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
          // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
        },
        searchQuery: "",
      },
      offeringfilterquery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      filterData: [],
      currentvalue: "ALL",
      sliderfilter: false,
      sliderMin: 0,
      sliderMax: 0,
      filterprice: {
        units: "ALL",
        min: 0,
        max: 0,
        key: "productOfferingPrice",
      },
      totalpageno: 0,
      containerleft: 0,
      containertop: 0,
      userInfo: this.$store.state.userInfo,
      filtermin: 0,
      filtermax: 0,
      filterloop: {},
      slidedata: 0,
      min: 0,
      max: 0,
      searchfilterdata: {},
      originaldata: {},
      filterdata: {},
      duplicatefilterdata: {},
      filterdat: {},
      filtered_options: {},
      filter_theads: [
        {
          prop: "place",
          label: "Place",
        },
        {
          prop: "channel",
          label: "Channel",
        },
        {
          prop: "category",
          label: "Category",
        },
        {
          prop: "productOfferingTerm",
          label: "Product offering term",
        },
        {
          prop: "marketSegment",
          label: "Market segment",
        },
        {
          prop: "productOfferingPrice",
          label: "Product offering price",
        },
      ],

      product_offerings_theads: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: true,
          width: null,
          align: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 1,
          disabled: true,
          prop: "offerType",
          label: "Type",
          sortable: false,
          resizable: true,
          width: 150,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 1,
          disabled: true,
          prop: "lifecycleStatus",
          label: "Status",
          sortable: false,
          resizable: true,
          width: 150,
          align: "left",
        },
        {
          name: "Market",
          checked: true,
          id: 1,
          disabled: false,
          prop: "marketSegment",
          label: "Market",
          sortable: false,
          resizable: true,
          width: 250,
          align: "left",
        },
        {
          name: "Price",
          checked: true,
          id: 1,
          disabled: true,
          prop: "productOfferingPrice",
          label: "Price",
          sortable: false,
          resizable: true,
          width: 200,
          align: "left",
          headAlign: "left",
        },
        {
          name: "Product owner",
          checked: true,
          id: 1,
          disabled: false,
          prop: "relatedParty",
          label: "Product owner",
          sortable: false,
          resizable: true,
          width: 250,
          align: "left",
        },
        // {
        //   name: "Price",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "productOfferingPrice",
        //   label: "Price",
        //   sortable: false,
        //   resizable: false,
        //   width: 100,
        //   align: "left",
        // },
      ],
      product_offerings_theads_cc: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: true,
          width: null,
          align: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 1,
          disabled: true,
          prop: "offerType",
          label: "Type",
          sortable: false,
          resizable: true,
          width: 150,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 1,
          disabled: true,
          prop: "lifecycleStatus",
          label: "Status",
          sortable: false,
          resizable: true,
          width: 150,
          align: "left",
        },
        {
          name: "Market",
          checked: true,
          id: 1,
          disabled: false,
          prop: "marketSegment",
          label: "Market",
          sortable: false,
          resizable: true,
          width: 250,
          align: "left",
        },
        {
          name: "Price",
          checked: true,
          id: 1,
          disabled: true,
          prop: "productOfferingPrice",
          label: "Price",
          sortable: false,
          resizable: true,
          width: 200,
          align: "left",
          headAlign: "left",
        },
        {
          name: "Product owner",
          checked: true,
          id: 1,
          disabled: false,
          prop: "relatedParty",
          label: "Product owner",
          sortable: false,
          resizable: true,
          width: 250,
          align: "left",
        },
      ],
      modal_active6: false,
      dropdown_data: [
        { name: "AND", value: "AND" },
        { name: "Between", value: "Between" },
        { name: "OR", value: "OR" },
      ],
      show_right: false,
      token: this.$route.params.token,
      editMode: true,
      editPermission: [],
      viewPermission: [],
      unique: Math.floor(Math.random() * 1000 + 1),
      delete_modal_active: false,
      searchtabledata: [],
      // businessrulepagespinner: true,
      // businessrulepagetable: false,
      duplicatetabledata: [],
      config_nav_items: [],
      delete_name: "",
      datum: {},
      success: false,
      success_msg: "",
      notification: {},
      expressions: [],
      view: "Product offerings",
      schema_type_switch: [
        { name: "Product offerings", value: "Product offerings" },
      ],
      selectedValue: "",
      // selectedValue: "Product offerings",
      show_add_menu: false,
      show_crud_menu: false,
      theads: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
          fixed: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 5,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
        },
        {
          name: "Category",
          checked: true,
          id: 2,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Channel",
          checked: true,
          id: 2,
          disabled: false,
          prop: "channel",
          label: "Channel",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 4,
          disabled: false,
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: false,
          width: 70,
          align: "left",
        },
        {
          name: "Market",
          checked: true,
          id: 3,
          disabled: false,
          prop: "market",
          label: "Market",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Place",
          checked: true,
          id: 5,
          disabled: false,
          prop: "place",
          label: "Place",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Validity",
          checked: true,
          id: 5,
          disabled: false,
          prop: "validity",
          label: "Validity",
          sortable: false,
          resizable: false,
          width: 190,
          align: "left",
        },
        {
          name: "Last modified",
          checked: true,
          id: 5,
          disabled: false,
          prop: "last_updated_at",
          label: "Last modified",
          sortable: false,
          resizable: false,
          width: 170,
          align: "left",
        },
      ],
      theads1: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 5,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
        },
        {
          name: "Category",
          checked: true,
          id: 2,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "Channel",
          checked: true,
          id: 2,
          disabled: false,
          prop: "channel",
          label: "Channel",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 4,
          disabled: false,
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Market",
          checked: true,
          id: 3,
          disabled: false,
          prop: "market",
          label: "Market",
          sortable: false,
          resizable: false,
          width: 90,
          align: "center",
        },
        {
          name: "Place",
          checked: true,
          id: 5,
          disabled: false,
          prop: "place",
          label: "Place",
          sortable: false,
          resizable: false,
          width: 90,
          align: "center",
        },
        {
          name: "Validity",
          checked: true,
          id: 5,
          disabled: false,
          prop: "validity",
          label: "Validity",
          sortable: false,
          resizable: false,
          width: 120,
          align: "left",
        },
        {
          name: "Last modified",
          checked: true,
          id: 5,
          disabled: false,
          prop: "last_updated_at",
          label: "Last modified",
          sortable: false,
          resizable: false,
          width: 175,
          align: "left",
        },
      ],
      filtertabledata: [
        {
          type: "Product Offering",
          relatedParty: [],
          _id: "64e5bc614ab1048d37e74e59",
          name: "My Quick BB Offer",
          bundledProductOffering: [],
          isBundle: false,
          isSellable: true,
          lastUpdate: "2023-08-23T07:56:23.284Z",
          lifecycleStatus: "In-Active",
          place: [],
          channel: [],
          attachment: [],
          productOfferingTerm: [],
          marketSegment: [],
          productOfferingPrice: [],
          agreement: [],
          prodSpecCharValueUse: [],
          __v: 0,
        },
        {
          _id: "64eb64eba4c164e0f5e55d0a",
          name: "check",
          bundledProductOffering: [],
          description: "check product offerings",
          priceType: "Recuring",
          isBundle: false,
          isSellable: true,
          lastUpdate: "2023-08-27T14:54:59.656Z",
          lifecycleStatus: "Active",
          place: [{ name: "Canada" }],
          channel: [{ name: "Sales channel" }],
          attachment: [],
          category: [{ name: "Bike", id: "64d19db97c1374c67da7f2e4" }],
          productOfferingTerm: [],
          marketSegment: [{ name: "France" }],
          productOfferingPrice: [],
          agreement: [],
          prodSpecCharValueUse: [
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Advanced" },
              ],
            },
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Premium" },
              ],
            },
          ],
          type: "Product Offering",
          __v: 0,
          relatedParty: [{ name: "John" }],
          productOffering: {
            name: "new name",
            id: "64ec05451c352016b39dfc06",
          },
        },
        {
          _id: "64ebfceca868bd9c44a7104a",
          name: "BB offer1",
          bundledProductOffering: [],
          description: "offer type exaample",
          priceType: "Recuring",
          isBundle: false,
          isSellable: true,
          lastUpdate: "2023-08-28T01:48:28.059Z",
          lifecycleStatus: "Active",
          place: [{ name: "Canada" }],
          channel: [
            { name: "Sales channel" },
            { name: "Distribution channel" },
          ],
          attachment: [],
          category: [{ name: "Bike", id: "64d19db97c1374c67da7f2e4" }],
          productOfferingTerm: [],
          marketSegment: [{ name: "France" }],
          productOfferingPrice: [],
          agreement: [],
          prodSpecCharValueUse: [
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Advanced" },
              ],
            },
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Premium" },
              ],
            },
          ],
          type: "Product Offering",
          relatedParty: [{ name: "Alex" }],
          __v: 0,
        },
        {
          _id: "64ec49c17c2c382dacaac6ae",
          name: "testOffering",
          bundledProductOffering: [],
          description: "",
          priceType: "",
          isBundle: false,
          isSellable: true,
          lastUpdate: "2023-08-28T07:05:12.105Z",
          lifecycleStatus: "Active",
          place: [""],
          channel: [],
          attachment: [],
          category: [],
          productOfferingTerm: [],
          marketSegment: [],
          productOfferingPrice: [],
          agreement: [],
          prodSpecCharValueUse: [
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Advanced" },
              ],
            },
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Premium" },
              ],
            },
          ],
          type: "Product Offering",
          relatedParty: [{ name: "John doe" }],
          __v: 0,
        },
      ],
      tableData: [],
      logData: [],
      re_arrange: false,
      OfferingcurrPage: 1,
      OfferingrowsPerPage: 10,
      offeringcurrPage: 1,
      offeringrowsPerPage: 10,
      currPagePS: 1,
      rowsPerPagePS: 10,
      filtercurrPage: 1,
      filterrowsPerPage: 10,
      // duplicateTableData: [],
      duplicateTheads: [],
      duplicateTheads1: [],
      searchvalue: "",
      id: "tenant1",
      name: "myself",
      deleteobj: {},
      filterobj: {},
      filtervalueobj: {},
      searchfiltervalue: "",
      filtertagvalue: [],
      filtertaglength: 0,
      filteredtabledata: [],
      fdata: [],
      parentelement: "",
      parentdata: {},
      parentarray: [],
      Manufacturer: [],
      unitOfMeasure: [],
      unit: "",
      relatedParty: "",
      endOfDate: "",
    };
  },
  computed: {
    ...mapGetters(["currencyList"]),
    // records(){
    //   return this.$store.getters.allRule
    // },
    filteroptions() {
      return this.filterobj;
    },
    paginatedData() {
      if (this.view === "Product offerings") {
        // const start = (this.currPagePO - 1) * this.rowsPerPagePO;
        // const end = start + this.rowsPerPagePO;
        return this.tableData;
      } else {
        return this.tableData;
      }
    },
    showingFrom() {
      if (this.view === "Product offerings") {
        return (this.currPagePO - 1) * this.rowsPerPagePO + 1;
      } else {
        return (this.currPagePS - 1) * this.rowsPerPagePS + 1;
      }
    },
    showingTo() {
      if (this.view === "Product offerings") {
        const lastItem = this.showingFrom + this.rowsPerPagePO - 1;
        return lastItem > this.totalRows ? this.totalRows : lastItem;
      } else {
        const lastItem = this.showingFrom + this.rowsPerPagePS - 1;
        return lastItem > this.totalRows ? this.totalRows : lastItem;
      }
    },
    totalRows() {
      return this.tableData?.length;
    },
  },
  watch: {
    product_offerings_theads: {
      handler(val) {
        console.log("product_offerings_theads", val);
      },
      deep: true,
      immediate: true,
    },
    offerPayload: {
      handler(val) {
        if (this.offerType == "Atomic") {
          if (val && val?.productSpecification?.name && val?.name) {
            this.enableButton = true;
          } else {
            this.enableButton = false;
          }
        } else {
          if (val && val?.bundledProductOffering.length > 0 && val?.name) {
            this.enableButton = true;
          } else {
            this.enableButton = false;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    dashboardPreval: {
      handler(val) {
        if (val.length === 0) {
          val;
        }
      },
      immediate: true,
      deep: true,
    },
    "$route.query.for": {
      handler(query) {
        if (!query) return;
        // console.log(query.trim());
        if (query.trim() == "offering") {
          this.selectedValue = "Product offerings";
          this.handleCurrentChange(this.selectedValue);
        }
      },
      deep: true,
      // immediate:true
    },
    // totalpageno: {
    //   handler(data) {

    //   },
    //   immediate: true,
    //   deep: true
    // },
    tableData: {
      handler() {
        // ;
        //         data?.forEach((e) => {
        //   // ;
        //   // if (e.context !== undefined) {
        //     e.context = false;
        //   // }
        // })
        // ;
        // if(data.length>1)
        // {
        //   this.tableData = data.sort(
        //     (a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate)
        //   );
        // }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

    let sampleTheadsd = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );

    const mergedPayload = sampleTheadsd.data.permission;
 

    this.permisstionData =
      mergedPayload.UPC.child["Product catalogue"].child.Offers;


    console.log(
      mergedPayload?.UPC.child["Product catalogue"].child.Offers,
      "OffersOffers"
    );

    // To get table header
    let sampleTheads = await MLTAxiosInstance.get(`/settings`);

    if (sampleTheads?.data?.entityRef?.["productOffering"]) {
      let offerTableHeadModal = sampleTheads.data.entityRef["productOffering"];
      let offerTableHeadOrg = sampleTheads.data.entityRef[
        "productOffering"
      ].filter((ele) => ele.checked);
      console.log(
        offerTableHeadOrg,
        "offerTableHeadModal",
        offerTableHeadModal
      );

      if (
        offerTableHeadModal.length === this.product_offerings_theads_cc.length
        // &&
        // offerTableHeadModal.every((item, index) => item.name ===  this.product_offerings_theads_cc[index].name)
      ) {
        this.product_offerings_theads_cc = JSON.parse(
          JSON.stringify(offerTableHeadModal)
        );
        this.product_offerings_theads = JSON.parse(
          JSON.stringify(offerTableHeadOrg)
        );
        this.tableHeaderOrgcc = JSON.parse(
          JSON.stringify(this.product_offerings_theads_cc)
        );
        this.tableHeaderOrg = JSON.parse(
          JSON.stringify(this.product_offerings_theads)
        );
      }
    }
    const filterResultData = await UPCAxiosInstance.post(
      `util/filterData`,
      this.Specfilterquery
    );
    this.datalength = filterResultData.data.total;
    this.tableData = filterResultData.data.data;
    this.totalpageno = filterResultData.data.total;
    this.paginatedData;

    this.duplicateTheads = JSON.parse(JSON.stringify(this.theads));
    this.duplicateTheads1 = JSON.parse(JSON.stringify(this.theads1));

    document.addEventListener("click", (event) => {
      if (!event.target.classList.contains(".util-items .util-items-add")) {
        this.show_add_offer = false;
      }
    });

    const specdata = await UPCAxiosInstance.post(`util/filterData`, {
      type: "search",
      module: "productSpecification",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 100,
        currentPage: 1,
      },
      isNeedToBeSort: false,
      sortFieldAndOrder: {
        name: 1,
      },
      limit: 100,
      filterQuery: {
        // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
      },
      searchQuery: "",
    });
    this.specdata = await specdata.data.data;

    this.company = val.data.company;
    this.userId = val.data.userId;
    // const rootName = this.$cookies.get("rootName");
    // const url =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/market"
    //       : `mapRef/collection/market?company=${this.$cookies.get("rootName")}`;
    // const unitOfmesures = await DMNAxiosInstance.get(
    //   url
    // );
    this.marketOptions = await this.getReferanceData("market");
    // const channelurl =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/channel"
    //       : `mapRef/collection/channel?company=${this.$cookies.get("rootName")}`;
    // const channel =  await this.getReferanceData('channel');
    this.channelOptions = await this.getReferanceData("channel");
    // const manufacturer = await DMNAxiosInstance.get(
    //   `mapRef/collection/tagsList`
    // );
    // this.tagsOptions = manufacturer.data.data;
    // const tagsListurl =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/tagsList"
    //       : `mapRef/collection/tagsList?company=${this.$cookies.get("rootName")}`;

    let tagsList = await this.getReferanceData("tagsList");
    this.tagsOptions = tagsList.filter((item) => {
      return item.module === "po";
    });

    // const statuslist =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/statuslist"
    //       : `mapRef/collection/statuslist?company=${this.$cookies.get("rootName")}`;
    const statusListpricing = await this.getReferanceData("statuslist");
    const statusOrder = ["Active", "Inactive", "In study", "Pending"];
    this.statusList = statusListpricing
      .filter((item) => statusOrder.includes(item.name))
      .sort(
        (a, b) => statusOrder.indexOf(a.name) - statusOrder.indexOf(b.name)
      );
    const partyOption = await MLTAxiosInstance.get(
      `/partymanagement/individual/listuser?company=${this.company}`
    );
    this.partyOptions = partyOption.data;
    document.addEventListener("click", (event) => {
      // console.log(this.tableData,"zsdxfghjk");
      if (!event.target.classList.contains("icon-more-vertical-filled")) {
        // ;
        // console.log(this.tableData,"zsdxfghjk");
        this.tableData?.forEach((e) => {
          // ;
          // console.log(e.context,"zsdxfghjk");
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    });

    this.$store.commit("token", this.$route.params.token);

    document.addEventListener("click", (event) => {
      if (!event.target.classList.contains("add-btn")) {
        this.show_add_menu = false;
      }
    });
    // console.log(this.$route.query.name, "naaaame");
    if (this.$route.query.name) {
      this.dashboardPreval = this.$store.state.filterPrevalue;
      console.log(this.dashboardPreval, "prevalfilter");
    }
    await this.$store.dispatch("productOfferFilterData");
    this.displayFilterData = this.$store.getters.productOfferingFilterData;
    console.log(this.displayFilterData, "this.filter");
    let priceArr = [];
    this.displayFilterData["Product offering price"].forEach((item) => {
      if (
        item &&
        item["name"] &&
        item["name"]["productPrice"] &&
        item["name"]["productPrice"]["dutyFreeAmount"] &&
        item["name"]["productPrice"]["dutyFreeAmount"] != null
      ) {
        priceArr.push(item["name"]["productPrice"]["dutyFreeAmount"]);
      }
      // if(item && item['name']?['productPrice']?['dutyFreeAmount'] && item['name']['productPrice']['dutyFreeAmount'] != null){
      // priceArr.push(item['name']?['productPrice']?['dutyFreeAmount'])
      // }
    });
    const minPrice = Math.min(...priceArr);
    const maxPrice = Math.max(...priceArr);
    this.displayAlternate[0].min = minPrice;
    this.displayAlternate[0].max = maxPrice;
    // console.log(maxPrice,minPrice,'minPrice');
    await this.$store.dispatch("initPermissionAnalayzer");
    this.appendStyleModalWindow();
    // this.categoryOffer("Onetime")

    // worst case scenario
  },

  methods: {
    resizedWidth(val) {
      console.log(val, "resizedWidth", val);

      const updateWidth = (array) => {
        let item = array.find((i) => i?.prop === val?.prop);
        if (item) item.width = val.width;
      };

      updateWidth(this.product_offerings_theads);
      updateWidth(this.product_offerings_theads_cc);
      console.log(
        this.product_offerings_theads,
        "resizedWidth loop",
        this.product_offerings_theads_cc
      );
      this.applyColumns(this.product_offerings_theads_cc);
    },
    appendStyleModalWindow() {
      document
        .querySelectorAll(".bundle-onetime, .bundle-recurring")
        .forEach((item) => {
          item.addEventListener("click", function () {
            document
              .querySelectorAll(
                ".bundle-onetime.selected, .bundle-recurring.selected"
              )
              .forEach((ele) => {
                ele.classList.remove("selected");
              });
            this.classList.add("selected");
          });
        });
    },

    receivedSelectedOffers(val) {
      const normalize = (str) => str?.toLowerCase().replace(/\s+/g, "");

      const result = val.map((item) => {
        this.leafBundle(item.category);

        // Store the filtered relatedProduct in offerPayload
        this.offerPayload.relatedProduct =
          this.offerPayload.relatedProduct.concat(item.relatedProduct);

        // Extract the SKUs from relatedParty where the role is 'supplier'
        const relatedPartySKUs = item.relatedParty
          .filter((party) => normalize(party.role) == "supplier")
          .map((party) => normalize(party.sku));

        this.offerPayload.attachment = this.offerPayload.attachment.concat(
          item.attachment
        );

        const matchedRelatedParty = item.relatedParty.filter((party) =>
          relatedPartySKUs.includes(normalize(party.sku))
        );

        let relatedParty =
          this.offerPayload.relatedParty.concat(matchedRelatedParty);
        this.offerPayload.relatedParty = relatedParty.filter(
          (product, index, self) =>
            index === self.findIndex((p) => p.sku === product.sku)
        );
        console.log(this.offerPayload.relatedParty, "result related partty");
        // Return the mapped object with matched prodSpecCharValue
        const _ = require("lodash");

        return {
          _id: item._id,
          displayID: item.displayID,
          name: item.name,
          unitOfMeasure: item.unitOfMeasure || "Each",
          productOfferingPrice: item.productOfferingPrice,
          prodSpecCharValue: item.prodSpecCharValue
            .filter(
              (spec) =>
                normalize(spec.priceType) === normalize(this.selectedType)
            )
            .map((spec) => _.omit(spec, "attachment")), // Remove attachments using Lodash
        };
      });

      console.log(result, "result 1.0");
      if (this.selectedType == "Onetime") {
        this.offerPayload.productSpecification["@baseType"] = "Tangible";
      } else {
        this.offerPayload.productSpecification["@baseType"] = "Intangible";
      }
      this.offerPayload.bundledProductOffering = result;

      this.aggregatePricingData(this.offerPayload.bundledProductOffering);
    },
    leafBundle(text) {
      console.log(text, "uniqueArray i9", text?.length);
      let lengthOfText = text?.length;

      if (typeof text === "string") {
        return text;
      }

      if (lengthOfText === 1) {
        console.log("leaf category text length final o/p", text);
        this.foundCategory = this.foundCategory.concat(text[0]); // Directly concat the only element
        return text[0];
      }

      if (text && lengthOfText >= 2) {
        const lastElement = text[lengthOfText - 1]; // Take the last element
        console.log(text, "leaf category text length final o/p", lastElement);
        this.foundCategory = this.foundCategory.concat(lastElement); // Push the last element to the array
        console.log(this.foundCategory, "uniqueArray i9.0");
        return lastElement;
      }
    },
    calculateSum(products, index) {
      console.log(products, "calculateSum index", index);
      const totalPrice = products?.reduce(
        (sum, product) => {
          // Use the price from tiers[0] if it exists, otherwise use the totalPrice
          let price =
            product?.totalPrice?.value ||
            product?.tiers?.[0]?.price?.value ||
            0;

          return sum + price;
        },
        0 // Initial sum value
      );
      this.totalPrice = totalPrice;
      this.bundleTotalcheck.totalPrice.value = totalPrice;
      this.bundleTotalcheck.tiers[0].price.value = totalPrice;
      this.offerPayload.productOfferingPrice[0] = this.bundleTotalcheck;
      console.log(
        totalPrice,
        "calculateSum payloadspec totalprice",
        this.offerPayload
      );
    },
    aggregatePricingData(dataArray) {
      const result = dataArray.map((item) => {
        const _ = require("lodash");

        const price = _.omit(item.productOfferingPrice[0] || {}, "attachment");
        return {
          _id: item._id,
          displayID: item.displayID,
          name: item.name,
          unitOfMeasure: item.unitOfMeasure || "Each",
          attachment: [],
          ...price,
        };
      });

      this.offerPayload.prodSpecCharValue = result;
      console.log(result, "result 1.0");
    },
    async categoryOffer(val) {
      this.Add_bundle_modal = false;
      this.add_model = true;
      this.componentKey += 1;
      if (val == "Onetime") {
        let offeringfilterquery = {
          type: "filter",
          module: "productOffering",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 50,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {
            // "name":-1
          },
          limit: 10,
          filterQuery: {
            "productSpecification.@baseType": "Tangible",
            lifecycleStatus: { $nin: ["Inactive"] },
            offerType: "Atomic",
          },
          searchQuery: val,
        };
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          offeringfilterquery
        );
        this.selectedOffer = await filterResultData.data.data;
      } else {
        this.bundleTotalcheck.priceType = "Recurring";
        let offeringfilterquery = {
          type: "filter",
          module: "productOffering",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {
            // "name":-1
          },
          limit: 10,
          filterQuery: {
            "productSpecification.@baseType": "Intangible",
            lifecycleStatus: { $nin: ["Inactive"] },
            offerType: "Atomic",
          },
          searchQuery: val,
        };
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          offeringfilterquery
        );
        this.selectedOffer = await filterResultData.data.data;
      }
    },
    makeWordsSingular(value) {
      if (value == "Years") {
        return "Year";
      } else if (value == "Months") {
        return "Month";
      } else if (value == "Weeks") {
        return "Week";
      } else {
        return value;
      }
    },
    async offeringFilterData(filterSelectedvalue, filterQuery) {
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      filterQuery.company = this.company;
      //  filterQuery['filterQuery']['"@baseType"'] = 'supplier'
      if (this.filtertagvalue.length != 0) {
        this.filterQuery = filterQuery;
        this.filterQuery.type = "filter";
        if (this.searchvalue != "") {
          this.filterQuery["searchQuery"] = this.searchvalue;
          this.filterQuery["type"] = "filter-search";
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.filterQuery
        );

        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
      } else {
        this.offeringcurrPage = 1;
        this.offeringrowsPerPage = 10;
        this.offeringdataCalling(
          this.offeringcurrPage,
          this.offeringrowsPerPage
        );
      }
    },
    computedProductPrice(offer) {
      if (
        offer &&
        offer.productSpecification &&
        offer.productOfferingPrice &&
        offer.productOfferingPrice.length > 0
      ) {
        let foundedPrice = null;
        if (
          offer.productSpecification["@baseType"] &&
          offer.productSpecification["@baseType"]?.toLowerCase() == "tangible"
        ) {
          foundedPrice = offer.productOfferingPrice.find((price) =>
            ["one time", "onetime"].includes(
              price?.pricingType?.toLowerCase() ||
                price?.priceType?.toLowerCase()
            )
          );
          let price, unit;
          unit = this.$store.getters.getDefaultSettings?.currency?.unit;

          if (foundedPrice?.productPrice?.dutyFreeAmount !== undefined) {
            //  console.log(offer.name,"intest",foundedPrice)
            console.log("in");
            price = foundedPrice?.productPrice?.dutyFreeAmount;
            unit =
              foundedPrice?.productPrice?.unit ||
              this.$store.getters.getDefaultSettings?.currency?.unit;
          } else {
            // console.log("else")
            // console.log(offer.name,"intesst",foundedPrice ,"in test")
            price = foundedPrice?.tiers[0]?.price?.value;
            unit =
              foundedPrice?.tiers[0]?.price?.unit ||
              this.$store.getters.getDefaultSettings?.currency?.unit;
          }
          console.log(
            this.$store?.getters?.getDefaultSettings?.currency?.symbol,
            "unit",
            offer.name
          );

          return this.currencyFormatter(price, {
            currency:
              unit || this.$store.getters.getDefaultSettings?.currency?.unit,
          });
        }
        if (
          offer.productSpecification["@baseType"] &&
          offer.productSpecification["@baseType"]?.toLowerCase() == "intangible"
        ) {
          foundedPrice = offer.productOfferingPrice.find((price) =>
            ["recurring"].includes(
              price?.pricingType?.toLowerCase() ||
                price?.priceType?.toLowerCase()
            )
          );
        }
        // console.log(foundedPrice,"intest",offer.name)

        if (foundedPrice) {
          let price, unit;
          if (foundedPrice?.productPrice?.dutyFreeAmount !== undefined) {
            price = foundedPrice?.productPrice?.dutyFreeAmount;
            unit =
              foundedPrice?.productPrice?.unit ||
              this.$store?.getters?.getDefaultSettings?.currency?.unit;
          } else {
            price = foundedPrice?.tiers[0]?.price?.value;
            unit = this.$store?.getters?.getDefaultSettings?.currency?.unit;
          }
          console.log(offer.name, "intest price", foundedPrice);
          return (
            this.currencyFormatter(price, {
              currency: unit,
            }) +
            " / " +
            this.makeWordsSingular(foundedPrice?.displayValue)
          );
        }
      }
      return this.currencyFormatter(0);
    },
    leaf(text) {
      if (text) {
        const total = text?.length;
        // console.log(text, "leafff", text[total - 1]);
        return text[total - 1];
      }
    },
    pricingCheck(leaves) {
      let leafCategory = this.leaf(leaves);

      let categoryInfo = UPCAxiosInstance.get(
        "/catalogManagement/category/" + leafCategory
      );
      this.categoryInfo = categoryInfo.data;
      return this.categoryInfo;
    },
    async handleSpecChanges(specs) {
      console.log("🚀 ~ handleSpecChanges ~ specs:", specs);
      if (!specs) return;
      this.offerPayload.category = specs.category;
      this.offerPayload.productSpecification = {
        ...this.offerPayload.productSpecification,
        id: specs._id,
        _id: specs._id,
        name: specs.name,
        description: specs.description,
        category: specs.category,
        "@type": specs["@type"],
        validFor: specs.validFor,
      };
      let leafCategory = this.leafCategory(specs.category);
      let categoryInfo = await UPCAxiosInstance.get(
        "/catalogManagement/category/" + leafCategory
      );
      console.log(categoryInfo.data, "specs.category");
      this.offerPayload.productSpecification["@baseType"] =
        categoryInfo.data.type;
      this.offerPayload.prodSpecCharValueUse = JSON.parse(
        JSON.stringify(specs.productSpecCharacteristic)
      );
    },
    leafCategory(text) {
      let lengthOfText = text?.length;
      if (typeof text === "string") {
        return text;
      }
      if (lengthOfText === 1) {
        console.log("leaf category text length final o/p ", text);
        return (text = text.toString());
      }
      if (text && lengthOfText > 1) {
        const total = text?.length;
        console.log(
          text,
          "leaf category text length final o/p",
          text[total - 1]
        );

        return text[total - 1];
      }
    },
    emitStatus(val1) {
      this.offerPayload.lifecycleStatus = val1["value"];
    },
    marketValues(val) {
      console.log(val, "marketSegment");
      this.offerPayload.marketSegment = val;
    },
    channelValues(val) {
      this.offerPayload.channel = val;
    },
    specOffer(val) {
      console.log(val, "tester");
      this.offerType = val;
      this.offerPayload.offerType = val;
      this.componentKey += 1;
      if (val == "Atomic") {
        this.add_model = true;
      } else {
        this.Add_bundle_modal = true;
      }
    },
    contextOffer() {
      this.$nextTick(() => {
        this.containerleft1 =
          document.getElementById("add-product-offer").getBoundingClientRect()
            .left - 0;
        this.containertop1 =
          document.getElementById("add-product-offer").getBoundingClientRect()
            .top + 40;
        this.show_add_offer = true;
      });
      // console.log()
      this.componentKey += 1;
      document
        .querySelectorAll(
          ".bundle-onetime.selected, .bundle-recurring.selected"
        )
        .forEach((el) => {
          el.classList.remove("selected");
        });
    },

    async capitalizeFirstWord(val) {
      let str = val;
      console.log(str, "testtt str");
      return await Promise.all(str.charAt(0).toUpperCase() + str.slice(1));
    },
    inputTrued($event, val) {
      this.unit = $event.Name;
      val;
      if (this.unit && this.payloadSpec.name) {
        this.enableButton = true;
      } else {
        this.enableButton = false;
      }
    },
    inputTrue(val) {
      if (val) {
        if (this.unit && this.payloadSpec.name) {
          this.enableButton = true;
        } else {
          this.enableButton = false;
        }
      } else {
        this.enableButton = false;
      }
    },

    async addProductSpec() {
      if (!this.offerPayload.relatedParty[0].name) {
        this.offerPayload.relatedParty[0].name = this.userInfo.name;
        this.offerPayload.relatedParty[0].role = "Product owner";
      }
      console.log(this.offerPayload.relatedParty, "result party");
      this.offerPayload.relatedProduct =
        this.offerPayload.relatedProduct.filter(
          (obj, index, self) =>
            index ===
            self.findIndex((t) => t._id == obj._id && t.name == obj.name)
        );
      if (this.offerPayload.offerType != "Atomic") {
        let uniqueArray = [...new Set(this.foundCategory)];
        console.log(uniqueArray, "uniqueArray i0", this.foundCategory);
        this.offerPayload.category = uniqueArray;
        this.calculateSum(this.offerPayload.prodSpecCharValue, 0);
      }
      // this.offerPayload.productSpecification["@baseType"]="Intangible"

      const addoffering = await UPCAxiosInstance.post(
        `/catalogManagement/productOffering`,
        this.offerPayload
      );
      console.log(addoffering.data, "addoffer");
      if (addoffering.data.name) {
        this.$router.push(
          `/UPC/product-catalog-table/offering/Add ${addoffering.data.offerType} offer/${addoffering.data._id}`
        );
      } else {
        this.notification = {
          type: "success",
          message: `Product offering ${this.offerPayload.name} already exists`,
        };
      }
    },

    async searchKey(val) {
      this.searchvalue = val;
      this.filterQuery["company"] = this.company;
      this.filterQuery["type"] = "search";
      this.filterQuery["searchQuery"] = this.searchvalue;
      this.filterQuery.searchList = [
        {
          offerType: { $regex: val, $options: "i" },
        },
        {
          marketSegment: { $regex: val, $options: "i" },
        },
        {
          "productOfferingPrice.productPrice.dutyFreeAmount": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "relatedParty.role": "Product owner",
          "relatedParty.name": { $regex: val, $options: "i" },
        },
      ];
      this.offeringcurrPage = 1;
      this.offeringrowsPerPage = 10;
      if (this.filtertagvalue.length != 0) {
        this.filterQuery["type"] = "filter-search";
      }
      this.offeringdataCalling(this.offeringcurrPage, this.offeringrowsPerPage);
    },

    async offeringdataCalling(currentPage, rowsPerPage) {
      if (this.$route.query.contact) {
        let arr = [];
        let obj = {};
        arr.push(this.$route.query.contact);
        obj["$in"] = arr;
        this.filterQuery["filterQuery"]["relatedParty.name"] = obj;
      }
      const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
      this.company = val.data.company;
      this.filterQuery["company"] = this.company;
      this.filterQuery["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQuery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQuery
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        this.paginatedData;
      }
    },
    async productOfferingFilter(filterSelectedvalue, filterQuery) {
      // ;
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      if (this.filtertagvalue.length != 0) {
        // this.totalpageno = spectotalpageno
        // this.tableData = spectabledata
        this.Specfilterquery = filterQuery;
        this.Specfilterquery.type = "filter";
        if (this.searchvalue != "") {
          this.Specfilterquery["searchQuery"] = this.searchvalue;
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.Specfilterquery
        );
        // console.log(filterResultData.data.data,'la');

        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        // ;
      } else {
        this.offeringcurrPage = 1;
        this.offeringrowsPerPage = 10;
        this.offeringdataCalling(
          this.offeringcurrPage,
          this.offeringrowsPerPage
        );
      }
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },

    async sliderval(val1, val2) {
      val1;
      val2;
      this.filterprice.checked = true;
      this.filterprice.value =
        this.filterprice.min + "-" + this.filterprice.max;
      // let obj = {};
      // obj.filter = this.filtertagvalue;
      if (this.sliderfilter == false) {
        this.filtertagvalue.push(this.filterprice);
        this.sliderfilter = true;
      }
      let obj = {};
      obj.filter = this.filtertagvalue;
      obj.currentpage = this.filtercurrPage;
      obj.rowsperpage = this.filterrowsPerPage;
      const filtertabledata = await UPCAxiosInstance.post(
        `catalogManagement/productOffering/filter`,
        obj
      );
      this.totalpageno = await filtertabledata.data.totallength;
      this.tableData = filtertabledata.data.data;
      this.tableData?.map((e) => {
        if (e.context == undefined) {
          e.context = false;
        }
      });
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipover1(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis1(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    tooltipleave1(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses1(data1, index1);
    },
    ellipsis(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipsis1(data, index) {
      this.$nextTick(() => {
        // if(event.target.tagName != 'SPAN')
        // {
        // console.log(data,index,event.target.tagName);
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - 30;
              top = Math.abs(top);
              let left = element1.getBoundingClientRect().left + 200;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
        // }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses1(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    async filterdatafunction() {
      // ;
      if (this.selectedvalue == "Product offerings") {
        await this.$store.dispatch("productSpecFilterData");

        this.filterdata = this.$store.getters.productOfferingFilterData;
        // const alldata = await UPCAxiosInstance.get(`catalogManagement/productOffering/filterlist/v1`);
        // this.filterdata = alldata.data
        // ;
      } else {
        await this.$store.dispatch("productOfferFilterData");

        this.filterdata = this.$store.getters.productOfferingFilterData;
        Object.keys(this.filterdata).forEach((e) => {
          // ;
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.key = e;
                ele.checked = false;
              }
            });
          }
          if (e == "Product offering price") {
            this.filterdata[e]?.forEach((ele) => {
              if (ele.price.amount != "") {
                if (this.offeringprice.includes(ele.price.amount) != true) {
                  this.offeringprice.push(ele.price.amount);
                }
              }
            });
          }
        });

        // this.datacalling()

        // const alldata = await UPCAxiosInstance.get(
        //   `catalogManagement/productOffering/filterlist`
        // );
        // this.filterdata = alldata.data;
        // ;
        // let pricedata = this.filterdata['productOfferingPrice'][this.currentvalue]
        // if(pricedata.length > 1)
        // {
        //   this.filterprice.min = pricedata[0]-1
        // }else if(pricedata.length == 1){
        //    this.filterprice.min = 0
        // }
        // this.filterprice.max = pricedata[pricedata.length - 1]+1
        // this.sliderMin = this.filterprice.min
        // this.sliderMax = this.filterprice.max
        // this.filterprice.min = this.filterdata['productOfferingPrice'][0].min
        // this.sliderMin = this.filterprice.min - 1
        // this.filterprice.max = this.filterdata['productOfferingPrice'][0].max
        // this.sliderMax = this.filterprice.max - 1
        // ,typeof(this.filterprice.max));
      }
    },
    async switchfilterdatafunction(val) {
      if (val == "Product offerings") {
        await this.$store.dispatch("productSpecFilterData");

        this.filterdata = this.$store.getters.productOfferingFilterData;
      } else {
        await this.$store.dispatch("productOfferFilterData");

        this.filterdata = this.$store.getters.productOfferingFilterData;
        Object.keys(this.filterdata).forEach((e) => {
          // ;
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.checked = false;
              }
            });
          }
          if (e == "Product offering price") {
            this.filterdata[e]?.forEach((ele) => {
              if (ele.price.amount != "") {
                if (this.offeringprice.includes(ele.price.amount) != true) {
                  this.offeringprice.push(ele.price.amount);
                  // );
                }
              }
            });
          }
        });
      }
    },
    insertSpaces(string) {
      let str = string
        .replace(/(_|-)/g, " ")
        .trim()
        .replace(/\w\S*/g, function (str) {
          return str.charAt(0).toUpperCase() + str.substr(1);
        })
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
      return str;
    },
    mindata(val) {
      this.min = val;
    },
    maxdata(val) {
      this.max = val;
    },
    openNav() {
      //  document.getElementById("mySidepanel").style.width = "250px";
      document.getElementById("mySidepanel").style.visibility = "visible";
      console.log("filter side pannala");
    },
    closeNav() {
      document.getElementById("mySidepanel").style.visibility = "hidden";
    },
    addOffer() {
      this.$router.push("/UPC/product-catalog-table/product_offering/add");
    },
    addSpec() {
      this.add_model = true;
      this.payloadSpec = {
        name: "",
        category: [],
        validFor: {
          endOfLife: "",
          endOfDate: "",
        },
        relatedParty: [],
        unitOfMeasure: {
          name: "",
        },
      };
    },
    change(val) {
      if (this.filtertagvalue.length == 0) {
        let obj = {};
        obj.value = val;
        this.filtertagvalue.push(obj);
      }
    },
    searchfilter($event, key) {
      this.searchfilterdata[key] = $event;
      this.searchfiltervalue = $event;
      if (
        this.searchfiltervalue &&
        this.searchfiltervalue != "" &&
        this.originaldata != undefined
      ) {
        // ;
        this.filterdat[key] = this.originaldata[key].filter((item) => {
          return item.value
            ?.toLowerCase()
            ?.toString()
            .includes(this.searchfiltervalue?.toLowerCase());
        });
      } else {
        this.filterdat = JSON.parse(JSON.stringify(this.originaldata));
      }
    },
    selectedvalue($event, data) {
      let finaldata = [];
      //
      //
      let arr_data = $event;

      let arr = [];
      arr_data.forEach((e) => {
        if (e.checked == true) {
          //
          arr.push(e.value);
        }
      });
      this.filtervalueobj[data] = arr;

      Object.keys(this.filtervalueobj).forEach((e) => {
        finaldata = [];
        this.filtervalueobj[e].forEach((ele) => {
          this.tableData?.forEach((element) => {
            if (element[e] === ele) {
              finaldata.push(element);
            } else if (element[e] === null) {
              finaldata.push(element);
            }
          });
        });
      });
      this.tableData = finaldata;
      //
      if (Object.keys(this.filtervalueobj).length <= 1) {
        this.filterFunction();
        //
      } else {
        let x = 0;
        let y = 0;
        while (x < Object.keys(this.filterobj).length) {
          while (y < Object.keys(this.filtervalueobj).length) {
            if (
              Object.keys(this.filterobj)[x] !=
              Object.keys(this.filtervalueobj)[y]
            ) {
              var arr5 = [];
              let arr6 = [];
              let arr7 = [];
              finaldata.forEach((element) => {
                arr5.push(element[Object.keys(this.filterobj)[x]]);
              });
              arr5.forEach((element) => {
                if (!arr6.includes(element)) {
                  arr6.push(element);
                }
              });
              arr6.forEach((element) => {
                let obj = {};
                //
                // if(element != null)
                // {
                obj["value"] = element;
                obj["name"] = element;
                obj.checked = true;
                obj.disabled = false;
                arr7.push(obj);
              });
              this.filterobj[Object.keys(this.filterobj)] = arr7;
            }
            y++;
          }
          x++;
        }
      }
      this.filterobj[data] = $event;
    },
    closeRight() {
      this.show_right = false;
    },
    computedTableAction() {
      // options;
      // data; 
      // let options = [
      //   {
      //     label: "Edit",
      //     icon: "icon-file-text-edit-regular",
      //   },
      //   {
      //     label: "Clone",
      //     icon: "icon-copy-alt-regular",
      //   },
      //   {
      //     label: "Delete",
      //     icon: "icon-trash-regular",
      //   },
      // ];
      if (this.permisstionData.delete && this.permisstionData.add && this.permisstionData.edit) {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Clone",
            icon: "icon-copy-alt-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      } else if (this.permisstionData.delete && this.permisstionData.edit) {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else if (this.permisstionData.delete ) {
        return [
          
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      }
      // return options.filter((option) => {
      //   if (
      //     data &&
      //     data.status &&
      //     data.status?.toLowerCase() == "draft" &&
      //     ["Draft", "Mark as In-active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status?.toLowerCase() == "active" &&
      //     ["Draft", "Mark as active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status?.toLowerCase() == "inactive" &&
      //     ["Draft", "Mark as In-active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
    },
    getWidth(id) {
      const descriptionDiv = document.getElementById(id);
      if (descriptionDiv) {
        // //
        return descriptionDiv.offsetWidth + "px";
      }
    },
    async applyColumns(val) {
      // ;
      console.log("applyColumns", val);
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      let concatedEntity = sampleTheads?.data?.entityRef || {};

      concatedEntity.productOffering = val;

      let reArrangeTableHeadPayload = { entityRef: concatedEntity };

      const changeTableHeader = await MLTAxiosInstance.patch(
        `/settings`,
        reArrangeTableHeadPayload
      );
      console.log(
        changeTableHeader,
        "reArrangeTableHeadPayload",
        reArrangeTableHeadPayload
      );

      let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
      console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

      let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
      this.product_offerings_theads_cc = entityRef["productOffering"];
      this.product_offerings_theads = this.product_offerings_theads_cc.filter(
        (ele) => ele.checked
      );
      this.tableHeaderOrgcc = JSON.parse(
        JSON.stringify(this.product_offerings_theads_cc)
      );
      this.tableHeaderOrg = JSON.parse(
        JSON.stringify(this.product_offerings_theads)
      );
    },
    updateTheads() {
      if (this.view === "Product offerings") {
        // Use the map function to update the 'checked' property
        this.product_offerings_theads_cc = this.product_offerings_theads_cc.map(
          (thead) => {
            // Check if the current thead has a matching 'prop' in duplicateTheads
            const isMatching = this.product_offerings_theads.some(
              (duplicateThead) => duplicateThead.prop === thead.prop
            );

            // Set the 'checked' property based on whether it's matching
            return { ...thead, checked: isMatching };
          }
        );
      }
    },
    reAssignOrgTableHeader() {
      // If there is change in the table header then ressign to the Orginal data
      if (
        this.product_offerings_theads_cc != this.tableHeaderOrgcc &&
        this.tableHeaderOrgcc.length > 0
      ) {
        this.product_offerings_theads_cc = JSON.parse(
          JSON.stringify(this.tableHeaderOrgcc)
        );
      }
      if (
        this.product_offerings_theads != this.tableHeaderOrg &&
        this.tableHeaderOrg.length > 0
      ) {
        this.product_offerings_theads = JSON.parse(
          JSON.stringify(this.tableHeaderOrg)
        );
      }
      this.re_arrange = true;
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = "";
          let format = this.$store.state.defaultSetting.dateFormat;
          if (
            format == "Month DD, YYYY" ||
            format == "mmmm dd, yyyy" ||
            format == "mmmm dd, yyyy"
          ) {
            month = objectDate.toLocaleString("default", { month: "long" });
          } else {
            month = objectDate.toLocaleString("default", { month: "short" });
          }
          let year = objectDate.getFullYear();
          let dateFormat = this.dateFormat(date, month, year, format);
          // let dateformat = month + " " + date + ", " + year;
          return dateFormat;
        }
      }
    },
    async refreshTable() {
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.Specfilterquery
      );

      this.tableData = filterResultData.data.data;
      this.totalpageno = filterResultData.data.total;
      this.paginatedData;
    },
    async closeCrudContextMenu($event, ele, i) {
      // //

      if ($event?.toLowerCase() === "edit") {
        if (this.view === "Product offerings") {
          this.$router.push(
            `/UPC/product-catalog-table/offering/Edit ${ele.offerType} offer/${ele._id}`
          );
        }
      } else if ($event?.toLowerCase() === "clone") {
        ele.constraint = [];
        ele.context = false;
        delete ele._id;
        const clonedata = await UPCAxiosInstance.post(
          `/catalogManagement/productOffering/clone`,
          ele
        );
        this.refreshTable();
        console.log(clonedata);
        if (clonedata.status == 201) {
          this.notification = {
            type: "success",
            message: `Nice work! Your product offering ${ele.name} is cloned!`,
          };
        }
      } else if ($event?.toLowerCase() == "delete") {
        if (ele.lifecycleStatus == "Active") {
          this.notification = {
            type: "Warning",
            message: `You can't delete an active product offering.`,
          };
        } else {
          this.delete_modal_active = true;
          this.deleteobj = ele;
          this.delete_name = ele.name;
          this.deleterowindex = i;
        }
      } else if (
        $event &&
        ["Mark as In-active", "Mark as active"].includes($event)
      ) {
        let updatePayload = {
          ...i,
          status: $event == "Mark as In-active" ? "inactive" : "active",
        };

        await DMNAxiosInstance.patch(
          `rule_engine/rule/${i["_id"]}`,
          updatePayload
        );
        // this.refreshTable();
      }
    },
    viewPage(i) {
      if (!i) return;
      this.$router.push(
        `/UPC/product-catalog-table/offering/Edit ${i.offerType} offer/${i._id}`
      );
    },

    async deleteExecRow() {
      this.delete_modal_active = false;
      let i = this.deleteobj;
      const deleteOffering = await UPCAxiosInstance.delete(
        `catalogManagement/productOffering/${i._id}`
      );
      deleteOffering;
      this.offeringcurrPage = 1;
      this.offeringdataCalling(this.offeringcurrPage, this.offeringrowsPerPage);

      this.notification = {
        type: "success",
        message: `Done! ${i.name} has been removed.`,
      };
      // }
      // this.activatemodalactive(i);
      i.context = false;
      // this.refreshTable();
    },
   async crudContextMenu($event, index) {
      // ;
      const getAll = await axios.get(`https://appbackend-ghubgwxrcq-pd.a.run.app/acl/`,{
      headers: {
        'hlx-api-key': 'bbd712b6bf575dcef3a510e3e0ec8405:f2c221f17b243986ecfd4ca33f1ece8ad4f1bbec5aa48da22d262a89671eb9c3eebabc4f9e514ab65d128fee0111802d6e750f955217f2324258c4e41e492367'
      }})
      let arr1 = this.tableData[index].relatedParty;
      const result = arr1.some(item => 
      getAll.data.includes(item.name) && item.role == "Product owner"
);
console.log(result,"foundExistance",arr1);
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;

        //
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      } 
    },
    async handleCurrentChange(val) {
      //
      // this.filterpanelname == val.name;
      switch (val) {
        case "Product offerings":
          // this.offeringcurrPage = 1;
          // this.offeringrowsPerPage = 10;
          this.tableData = [];
          this.offeringdataCalling(
            this.offeringcurrPage,
            this.offeringrowsPerPage
          );
          this.filterPanelName = "productOffering";
          this.view = val;

          break;
      }
      this.duplicatetabledata = this.tableData;
      this.searchtabledata = this.tableData;
      // //
    },
    serialNumber(index) {
      return (this.offeringcurrPage - 1) * this.offeringrowsPerPage + index + 1;
    },
    async currentPage(val) {
      this.offeringcurrPage = val;
      this.offeringdataCalling(this.offeringcurrPage, this.offeringrowsPerPage);
      this.table_height = this.paginatedData?.length * 40 + 43;
    },
    async changeRowsPerPage(count) {
      this.offeringrowsPerPage = count;
      this.offeringdataCalling(this.offeringcurrPage, this.offeringrowsPerPage);
      this.table_height = this.paginatedData?.length * 40 + 43;
    },
    resizewidthdata(data1, data2) {
      data1;
      data2;
      //
    },
    sorting_Data(data, data1) {
      //
      if (data === "icon-angle-up-small-filled sorting-icon") {
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = true;
          if (data1 === "name") {
            this.offeringfilterquery.sortFieldAndOrder = { name: 1 };
          } else if (data1 == "description") {
            this.offeringfilterquery.sortFieldAndOrder = { description: 1 };
          } else if (data1 == "lifecycleStatus") {
            this.offeringfilterquery.sortFieldAndOrder = { lifecycleStatus: 1 };
          } else if (data1 == "offerType") {
            this.offeringfilterquery.sortFieldAndOrder = { offerType: 1 };
          } else if (data1 == "lastUpdate") {
            this.offeringfilterquery.sortFieldAndOrder = { lastUpdate: 1 };
          } else if (data1 == "place") {
            this.offeringfilterquery.sortFieldAndOrder = { "place.name": 1 };
          } else if (data1 == "channel") {
            this.offeringfilterquery.sortFieldAndOrder = { "channel.name": 1 };
          } else if (data1 == "category") {
            this.offeringfilterquery.sortFieldAndOrder = { "category.name": 1 };
          } else if (data1 == "marketSegment") {
            this.offeringfilterquery.sortFieldAndOrder = {
              "marketSegment.name": 1,
            };
          }
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        }
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        // console.log('sort-down',data1);
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = true;
          if (data1 === "name") {
            this.offeringfilterquery.sortFieldAndOrder = { name: -1 };
          } else if (data1 == "description") {
            this.offeringfilterquery.sortFieldAndOrder = { description: -1 };
          } else if (data1 == "lifecycleStatus") {
            this.offeringfilterquery.sortFieldAndOrder = {
              lifecycleStatus: -1,
            };
          } else if (data1 == "offerType") {
            this.offeringfilterquery.sortFieldAndOrder = { offerType: -1 };
          } else if (data1 == "lastUpdate") {
            this.offeringfilterquery.sortFieldAndOrder = { lastUpdate: -1 };
          } else if (data1 == "place") {
            this.offeringfilterquery.sortFieldAndOrder = { "place.name": -1 };
          } else if (data1 == "channel") {
            this.offeringfilterquery.sortFieldAndOrder = { "channel.name": -1 };
          } else if (data1 == "category") {
            this.offeringfilterquery.sortFieldAndOrder = {
              "category.name": -1,
            };
          } else if (data1 == "marketSegment") {
            this.offeringfilterquery.sortFieldAndOrder = {
              "marketSegment.name": -1,
            };
          }
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        }
      } else if (data === "icon-angle-up-small-filled") {
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = false;
          this.offeringfilterquery.sortFieldAndOrder = {};
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        }
      } else if (data === "icon-angle-down-small-filled") {
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = false;
          this.offeringfilterquery.sortFieldAndOrder = {};
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        }
      }
    },
    // searchKey(val) {
    //   this.searchvalue = val;
    //   this.Specfilterquery["type"] = "search";
    //   this.Specfilterquery["searchQuery"] = this.searchvalue;
    //   this.offeringcurrPage = 1;
    //   this.offeringrowsPerPage = 10;
    //   this.offeringdataCalling(this.offeringcurrPage, this.offeringrowsPerPage);
    // },
    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      obj.module = "po";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      // const rootName = this.$cookies.get("rootName");
      // const tagsListurl =
      //   rootName === "Halleyx"
      //     ? "mapRef/collection/tagsList"
      //     : `mapRef/collection/tagsList?company=${this.$cookies.get("rootName")}`;

      let tagsList = await this.getReferanceData("tagsList");
      let arr = JSON.parse(JSON.stringify(this.offerPayload.tags));
      arr.push(e.name);
      this.offerPayload.tags = arr;
      console.log(this.offerPayload, "newCOnPayload");
      this.tagsOptions = tagsList.data.data;
      // console.log(e);
      // this.tagsList.push(e);
    },
    receivedTags(e) {
      // console.log(
      //   'received',
      //   e,
      //   this.newConPayload.tags.filter((item) => item !== e[0]?.name)
      // );
      // console.log(e,'received');
      let arr = [];
      e.filter((item) => {
        if (item.checked == true) {
          // console.log('received',item.name);
          arr.push(item.name);
        }
      });
      this.offerPayload.tags = arr;
      // this.newAccPayload.tags = [];
      // e.forEach((ele) => {
      //   if (ele.checked === true) {
      //     this.newAccPayload.tags.push(ele.name);
      //   } else {
      //     this.newAccPayload.tags = this.newAccPayload.tags.filter(
      //       (item) => item !== ele.name
      //     );
      // //   }
      // });
      // this.newConPayload.tags = e.reduce((tags, ele) => {
      //   if (ele.checked) {
      //     tags.push(ele.name);
      //   } else {
      //     tags = tags.filter((item) => item !== ele.name);
      //   }
      //   return tags;
      // }, []);
    },
  },
};
</script>
<style lang="scss">
.choose-bundle-modal-window {
  .choose-bundle-modal-window-cover {
    display: flex;
    gap: 16px;
    left: 11%;
    top: 32%;
    position: absolute;
    font-family: Quicksand;

    .bundle-onetime,
    .bundle-recurring {
      border: 1px solid #a7a7a78c !important;
      cursor: pointer;
      &:not(.selected):hover {
        border: 1px solid #54bd95 !important;
        cursor: pointer;
      }
    }
    .selected {
      border: 2px solid #54bd95 !important;
      cursor: pointer;
    }
  }
}
</style>
