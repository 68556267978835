<template>
  <!-- Modal for restore version -->
  <hlx-modal
    :modal-active="restore_modal_quote"
    :height="'260px'"
    :width="'434px'"
    :modal="true"
    @close="restore_modal_quote = false"
  >
    <template #header>
      <div style="margin-left: 0px">Restore version</div>
    </template>

    <template #content>
      <div class="modals-content">
        <p style="padding: 32px">
          Restoring this version will reset all recipient signatures and
          initials. Do you wish to proceed?
          <b>{{ restored_time_stamp }}</b
          >?
        </p>
      </div>
    </template>

    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="restore_modal_quote = !restore_modal_quote"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="restoreQuote"
        >Restore</hlx-button
      >
    </template>
  </hlx-modal>
  <!-- Successfully signed message block -->
  <div
    v-if="
      !quoteAccessDenied &&
      isQuoteSigned &&
      $route.query.request_for == 'client_approval'
    "
    class="accepted-quote-message-block"
    style="
      height: 100%;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    "
  >
    <img width="25%" src="@/assets/images/svg/contractSigned.svg" />
    <h3 style="padding: 0 5rem; color: #191a15; line-height: 1.9">
      Congratulations! 🎉 Your quote has been successfully signed and processed.
      We're thrilled to inform you that a copy of the signed quote will be sent
      to your email shortly. Thank you for choosing our services, and we look
      forward to working with you!
    </h3>
  </div>
  <div
    v-else-if="
      quoteAccessDenied && $route.query.request_for == 'client_approval'
    "
    class="access-denied-message-block"
    style="
      height: 100%;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    "
  >
    <img src="@/assets/images/svg/403.svg" />

    <h3 style="color: #191a15; line-height: 1.9">Access forbidden</h3>
    <p>
      Apologies, you've encountered a 403 error. It could be due to either a
      signed quote or an expired quote request.
    </p>
  </div>

  <!-- preview header -->
  <div
    v-if="
      (!quoteAccessDenied && !$route.query.request_for) ||
      (!quoteAccessDenied &&
        !isQuoteSigned &&
        $route.query.request_for == 'client_approval')
    "
    class="QuotePreviewStaticHeader"
    :style="{
      left: $route.query.request_for == 'client_approval' ? '0' : '72px',
      width:
        $route.query.request_for == 'client_approval'
          ? '100%'
          : 'calc(100% - 72px)',
    }"
  >
    <!-- container1 -->
    <span
      v-if="$route.query.request_for != 'client_approval'"
      class="container1"
      @click="handleQuoteBack"
    >
      <span class="div1">
        <i class="icon-arrow-left-regular"></i>
      </span>
      <span class="div2">
        {{ "Back" }}
      </span>
    </span>
    <!-- container2 -->
    <span class="container2">
      <span v-if="quoteData && quoteData?.description" class="div1">
        {{ quoteData?.description }}
      </span>
      <span class="div2">
        <hlx-status
          v-if="isWaitingForClientApproval && !isVersionVisible"
          :status-value="'Sent'"
          :point-color="'#FEDC2A'"
          :type="'custom'"
        />
        <hlx-status
          v-if="
            isQuoteAccepted &&
            !isVersionVisible &&
            $route.query.request_for != 'client_approval'
          "
          :type="'Completed'"
        />
      </span>
    </span>
    <!-- container3 -->
    <span class="container3">
      <!-- version -->
      <span v-if="$route.query.request_for != 'client_approval'" class="div1">
        <span v-if="quoteData && quoteData?.version">{{
          "Version:" + quoteData?.version
        }}</span>
      </span>
      <!-- download -->
      <span
        v-if="
          ![
            'client_approval',
            'manager_approval',
            'approver_approval',
          ].includes($route.query.request_for)
        "
        class="div3"
        @click="handleQuoteDownload1"
      >
        <!-- <i class="icon-download-regular"></i> -->
        <hlx-button class="secondary sm">
          <i
            style="color: var(--hlx-color-primary)"
            class="icon-download-regular"
          ></i
          >&nbsp; Download
        </hlx-button>
      </span>
      <!-- edit -->
      <span v-if="isNeedManagerApproval && !isVersionVisible" class="divnew1">
        <hlx-button class="secondarry sm --button-with-icon" @click="editQuote"
          ><i class="icon-credit-card-edit-regular"></i>Edit</hlx-button
        ></span
      >
      <!-- accept -->
      <span v-if="isClientNeedToSign && !isVersionVisible" class="divnew2">
        <hlx-button
          :disabled="!isClientNeedToSign || disableSignAndAcceptButton"
          class="primary sm --button-with-icon --ml"
          @click="signAndAccept"
          ><i class="icon-user-eye-regular"></i>Sign & Accept quote</hlx-button
        >
      </span>
      <!-- Start Signature button-->
      <span v-if="startButton == true" class="divnew2">
        <hlx-button
          class="primary sm --button-with-icon --ml"
          @click="focusSignature"
          >Start</hlx-button
        >
      </span>
      <!-- send for approval -->
      <span
        v-if="
          quoteData.state != 'approved' &&
          quoteData.state != 'accepted' &&
          isNeedToSentToCustomer &&
          initialRecipientsList &&
          initialRecipientsList?.length > 0 &&
          !isFromClientApproval &&
          !isVersionVisible &&
          (isNeedManagerApproval ||
            requestNotFromManagerOrClient ||
            isManagerApproved)
        "
        class="div2"
      >
        <hlx-button
          class="primary sm --button-with-icon"
          @click="sendToCustomer"
          >{{ "Send to customer" }}
        </hlx-button>
      </span>
      <!-- reject -->
      <span v-if="isApproverView && quoteData?.state == 'pending'">
        <hlx-button
          class="secondary sm --button-with-icon"
          @click="handleQuoteApproval('declined')"
        >
          {{ "Reject" }}
        </hlx-button>
      </span>
      <!-- approve -->
      <span v-if="isApproverView && quoteData?.state == 'pending'">
        <hlx-button
          class="primary sm --button-with-icon"
          @click="handleQuoteApproval('approved')"
        >
          Approve
        </hlx-button>
      </span>
    </span>
  </div>

  <!-- Alert notification -->
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <!-- Tool bar  -->
  <div
    style="
      display: grid;
      background-color: #e7e7e7;
      grid-template-columns: 2fr 0.5fr;
    "
  >
    <div
      v-if="
        (!quoteAccessDenied && !$route.query.request_for) ||
        (!quoteAccessDenied &&
          !isQuoteSigned &&
          $route.query.request_for == 'client_approval')
      "
      id="preview-page-container"
      ref="preview-page-container"
      class="quote-preview-page-container newQuotepreviewIdDivForPDF"
      style="
        display: flex;
        max-height: max-content;
        overflow-y: visible !important;
      "
    >
      <section
        class="quote-preview-page-each-container"
        style="padding: 0px; position: relative"
      >
        <!--logo and company details-->
        <div
          class="header-and-logo-section-area"
          style="
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ddd;
          "
        >
          <table style="margin: 0px !important">
            <tbody>
              <tr>
                <th style="border-bottom: 0px !important">
                  <span
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      style="
                        font-size: 20px;
                        color: #54bd95;
                        padding-left: 20px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                      >{{ quoteData.description }}</span
                    >
                    <div
                      v-if="profilePicturePrevalue == ''"
                      style="
                        height: 100px;
                        width: 100px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid lightgray;
                        color: lightgray;
                        border-radius: 5px;
                        margin-right: 20px;
                      "
                    >
                      <span>
                        {{ quoteFrom.charAt(0).toUpperCase() }}
                      </span>
                    </div>
                    <div
                      v-if="profilePicturePrevalue != ''"
                      style="
                        height: 70px;
                        width: 200px;
                        display: flex;
                        justify-content: flex-end;
                        margin-right: 20px;
                      "
                    >
                      <span>
                        <img
                          :src="profilePicturePrevalue"
                          style="height: 100%; width: 100%; object-fit: cover"
                        />
                      </span>
                    </div>
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Company details and Quotes valid date-->
        <section
          class="company-details-and-valid-date-container"
          style="
            display: flex;
            justify-content: space-between;
            padding: 0 20px 10px 20px;
          "
        >
          <!--Company details-->
          <span>
            <table>
              <tbody>
                <tr>
                  <td
                    style="
                      border-bottom: 0px !important;
                      padding: 0px;
                      font-size: 13px;
                      font-family: system-ui, -apple-system, BlinkMacSystemFont,
                        'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                        'Helvetica Neue', sans-serif;
                    "
                  >
                    <b>
                      {{
                        quoteFrom.charAt(0).toUpperCase() +
                        quoteFrom.slice(1).toLowerCase()
                      }}</b
                    >
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      border-bottom: 0px !important;
                      padding: 0px;
                      font-size: 13px;
                      font-family: system-ui, -apple-system, BlinkMacSystemFont,
                        'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                        'Helvetica Neue', sans-serif;
                    "
                  >
                    {{ senderAddress }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      border-bottom: 0px !important;
                      padding: 0px;
                      font-size: 13px;
                      font-family: system-ui, -apple-system, BlinkMacSystemFont,
                        'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                        'Helvetica Neue', sans-serif;
                    "
                  >
                    {{ sellerEmail }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      border-bottom: 0px !important;
                      padding: 0px;
                      font-size: 13px;
                      font-family: system-ui, -apple-system, BlinkMacSystemFont,
                        'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                        'Helvetica Neue', sans-serif;
                    "
                  >
                    {{ sellerPhone }}
                  </td>
                </tr>
              </tbody>
            </table>
          </span>
          <!-- Quote valid details -->
          <span>
            <table>
              <tbody>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Quote no" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ externalId }}</span
                      >
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Quote date" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{
                          dateAndTimeFormatter(quoteData?.validFor?.startDateTime)
                        }}</span
                      >
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Valid till" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{
                          dateAndTimeFormatter(quoteData?.validFor?.endDateTime)
                        }}</span
                      >
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </span>
        </section>

        <!-- Bill To and Ship to Container-->
        <section
          class="billto-and-shipto-container"
          style="display: flex; justify-content: space-between"
        >
          <div
            class="details-container-area"
            style="
              display: flex;
              flex-direction: column;
              background-color: #f9f9f9;
              padding: 5px 5px;
              margin: 0 20px;
            "
          >
            <table style="margin: 0px !important">
              <tbody>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <b
                      style="
                        font-size: 16px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                      >{{ "Bill to" }}</b
                    >
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Account" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ quoteFor }}</span
                      >
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Address" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ billingAddress }}</span
                      >
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Contact" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ recipientContact }}</span
                      >
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Email" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ toemail }}</span
                      >
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Phone" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ buyerPhone }}</span
                      >
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="details-container-area"
            style="
              display: flex;
              flex-direction: column;
              background-color: #f9f9f9;
              padding: 5px 5px;
              margin: 0 20px;
            "
          >
            <table style="margin: 0px !important">
              <tbody>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <b
                      style="
                        font-size: 16px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                      >{{ "Ship to" }}</b
                    >
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Account" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ quoteFor }}</span
                      >
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Address" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ buyerAddress }}</span
                      >
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Contact" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ recipientContact }}</span
                      >
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Email" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ toemail }}</span
                      >
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 0px !important; padding: 4px">
                    <span
                      style="
                        display: flex;
                        font-size: 13px;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                          'OpenSans', 'Helvetica Neue', sans-serif;
                      "
                    >
                      <span
                        style="
                          color: #5f5f5f;
                          margin-right: 15px;
                          width: 80px;
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ "Phone" }}</span
                      >
                      <span
                        style="
                          font-size: 13px;
                          font-family: system-ui, -apple-system,
                            BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
                            Cantarell, 'OpenSans', 'Helvetica Neue', sans-serif;
                        "
                        >{{ buyerPhone }}</span
                      >
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <!--table-->
        <section style="padding: 0 20px">
          <table>
            <thead>
              <tr
                style="
                  color: #000000 !important;
                  font-size: 12pt;
                  font-family: system-ui, -apple-system, BlinkMacSystemFont,
                    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                    'Helvetica Neue', sans-serif;
                "
              >
                <th
                  v-for="(i, index) in attributes"
                  :key="index"
                  style="
                    color: #000000 !important;
                    font-size: 13px;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont,
                      'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                      'Helvetica Neue', sans-serif;
                  "
                  :style="{ textAlign: i.align }"
                >
                  {{ i.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(i, colindex) in tableDataArray"
                :id="'value' + col_index + colindex"
                :key="colindex"
              >
                <td
                  v-for="(j, coindex) in attributes"
                  :key="coindex"
                  :style="{ textAlign: j.align }"
                  style="
                    background-color: #fff;
                    font-size: 13px;
                    color: #000;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont,
                      'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                      'Helvetica Neue', sans-serif;
                  "
                >
                  <span
                    v-if="
                      j.label == 'Name' &&
                      i['section'] === false &&
                      i['custom'] === true
                    "
                  >
                    {{ i["product_offering_name"] }}
                  </span>
                  <span v-if="j.label == 'Name' && i['section'] === true">
                    <b>{{ i["product_offering_name"] }}</b>
                  </span>
                  <span v-if="j.label == 'Name' && i['section'] !== true">
                    {{ i["productOffering"]["name"] }}
                  </span>
                  <span v-if="j.label == 'Item' && i['section'] !== true">
                    {{ i["id"] }}
                  </span>
                  <span v-if="j.label == 'Quantity' && i['section'] !== true">
                    {{ i["quantity"] }}
                  </span>
                  <span v-if="j.prop == 'price' && i['section'] !== true">
                    <span>
                      {{
                        currencyFormatter(
                          i?.quoteItemPrice[0]?.price?.dutyFreeAmount?.value,
                          {
                            currency:
                              i?.quoteItemPrice[0]?.price?.dutyFreeAmount?.unit,
                          }
                        )
                      }}<span
                        v-if="
                          i?.quoteItemPrice[0]?.recurringChargePeriod !=
                          'One time'
                        "
                        >{{
                          " / " + i?.quoteItemPrice[0]?.recurringChargePeriod
                        }}</span
                      >
                    </span>
                  </span>
                  <span v-if="j.prop == 'discount' && i['section'] !== true">
                    <span
                      v-if="
                        i.quoteItemPrice[0] != undefined &&
                        i.quoteItemPrice[0]?.priceAlteration != undefined &&
                        i.quoteItemPrice[0]?.priceAlteration[0] != undefined &&
                        i.quoteItemPrice[0]?.priceAlteration[0]?.price !=
                          undefined &&
                        i.quoteItemPrice[0]?.priceAlteration[0]?.price
                          ?.percentage != undefined
                      "
                    >
                      {{
                        i.quoteItemPrice[0]?.priceAlteration[0]?.price
                          ?.percentage || "0"
                      }}
                    </span>
                    <span v-else>
                      {{ "0" }}
                    </span>
                  </span>
                  <span v-if="j.label == 'Total' && i['section'] !== true">
                    <span>
                      {{
                        currencyFormatter(
                          i?.quoteItemPrice[0]?.price?.taxIncludedAmount?.value,
                          {
                            currency:
                              i?.quoteItemPrice[0]?.price?.taxIncludedAmount
                                ?.unit,
                          }
                        )
                      }}<span
                        v-if="
                          i?.quoteItemPrice[0]?.recurringChargePeriod !=
                          'One time'
                        "
                        >{{
                          " / " + i?.quoteItemPrice[0]?.recurringChargePeriod
                        }}</span
                      >
                    </span>
                  </span>
                </td>
              </tr>
              <!-- Add more rows as needed -->
            </tbody>
          </table>
        </section>

        <!--pricing section-->
        <section
          class="quote-pricing-summary-info-container"
          style="
            font-size: 12pt;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 20px;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
              'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
              'Helvetica Neue', sans-serif;
          "
        >
          <table
            v-for="(priceType, index) in computedQuoteTotalPrice"
            :key="index"
            class="quote-pricing-summary-wrapper"
            style="display: grid; width: 40%; font-size: 13px"
          >
            <tr
              v-if="
                priceType.price.dutyFreeAmount.value &&
                priceType.price.dutyFreeAmount.value != ''
              "
              style="display: flex; justify-content: space-between"
            >
              <td
                style="border: none !important; padding: 0px; font-weight: bold"
              >
                <p class="pricing-type-title --title">
                  {{
                    priceType.name == "One-time charges"
                      ? "Onetime"
                      : priceType.name == "Recurring charges"
                      ? "Recurring"
                      : ""
                  }}
                </p>
              </td>
            </tr>
            <tr
              v-if="
                priceType.price.dutyFreeAmount.value &&
                priceType.price.dutyFreeAmount.value != ''
              "
              style="display: flex; justify-content: space-between"
            >
              <td
                class="pricing-info"
                style="
                  font-size: 13px;
                  border: none !important;
                  padding: 0px;
                  width: 180px;
                "
              >
                <span class="label">Sub total</span>
              </td>
              <td
                class="pricing-info"
                style="font-size: 13px; border: none !important; padding: 0px"
              >
                <span class="label">{{
                  currencyFormatter(priceType.price.dutyFreeAmount.value, {
                    currency: priceType.price.dutyFreeAmount.unit,
                  })
                }}</span>
              </td>
            </tr>
            <tr
              v-if="priceType.name == 'One-time charges'"
              :key="col_index"
              style="display: flex; justify-content: space-between"
            >
              <td
                class="pricing-info"
                style="
                  font-size: 13px;
                  border: none !important;
                  padding: 0px;
                  width: 180px;
                "
              >
                <table
                  style="
                    margin: 0px !important;
                    padding: 0px !important;
                    border: none;
                  "
                >
                  <tr
                    v-for="(additionalCharge, col_index) in quoteData
                      ?.quotePrice?.additionalCharges"
                    :key="col_index"
                    style="
                      margin: 0px !important;
                      padding: 0px !important;
                      border: none;
                    "
                  >
                    <td
                      style="
                        margin: 0px !important;
                        padding: 0px !important;
                        border: none;
                      "
                    >
                      <span class="label">{{ additionalCharge?.name }}</span>
                    </td>
                  </tr>
                </table>
              </td>
              <td
                class="pricing-info"
                style="font-size: 13px; border: none !important; padding: 0px"
              >
                <table
                  style="
                    margin: 0px !important;
                    padding: 0px !important;
                    border: none;
                  "
                >
                  <tr
                    v-for="(additionalCharge, col_index) in quoteData
                      ?.quotePrice?.additionalCharges"
                    :key="col_index"
                    style="
                      margin: 0px !important;
                      padding: 0px !important;
                      border: none;
                    "
                  >
                    <td
                      style="
                        margin: 0px !important;
                        padding: 0px !important;
                        border: none;
                        text-align: right;
                      "
                    >
                      <span class="label">{{
                        currencyFormatter(additionalCharge.price, {
                          currency: priceType.price.dutyFreeAmount.unit,
                        })
                      }}</span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr
              v-if="
                priceType.price.taxIncludedAmount.value &&
                priceType.price.taxIncludedAmount.value != ''
              "
              style="display: flex; justify-content: space-between"
            >
              <td
                class="pricing-info"
                style="
                  font-size: 13px;
                  border: none !important;
                  padding: 0px;
                  width: 180px;
                "
              >
                <span class="label">Amount</span>
              </td>
              <td
                class="pricing-info"
                style="font-size: 13px; border: none !important; padding: 0px"
              >
                <span class="label">{{
                  currencyFormatter(priceType.price.taxIncludedAmount.value, {
                    currency: priceType.price.taxIncludedAmount.unit,
                  })
                }}</span>
              </td>
            </tr>
            <tr
              v-if="
                quoteData?.quotePrice?.summary['oneTime'].appliedTax.length !=
                  0 &&
                priceType['priceType'].toLowerCase() == 'onetime' &&
                quoteData?.quotePrice?.summary['oneTime']?.appliedTax[0]
                  ?.taxName &&
                quoteData?.quotePrice?.summary['oneTime']?.appliedTax[0]
                  ?.taxName.length != 0
              "
              style="display: flex; justify-content: space-between"
            >
              <td
                class="pricing-info"
                style="
                  font-size: 13px;
                  border: none !important;
                  padding: 0px;
                  width: 180px;
                "
              >
                <span class="label"><b>Tax</b></span>
              </td>
            </tr>
            <tr
              v-if="
                quoteData?.quotePrice?.summary['oneTime'].appliedTax.length !=
                  0 &&
                priceType['priceType'].toLowerCase() == 'onetime' &&
                quoteData?.quotePrice?.summary['oneTime']?.appliedTax[0]
                  ?.taxName &&
                quoteData?.quotePrice?.summary['oneTime']?.appliedTax[0]
                  ?.taxName.length != 0
              "
              style="display: flex; justify-content: space-between"
            >
              <td
                class="pricing-info"
                style="
                  font-size: 13px;
                  border: none !important;
                  padding: 0px;
                  width: 180px;
                "
              >
                <!-- {{ priceType['priceType'].toLowerCase() }} {{quoteData?.quotePrice?.summary['oneTime'].appliedTax[0].taxName}} -->
                <span
                  v-if="priceType['priceType'].toLowerCase() == 'onetime'"
                  class="label"
                  >{{
                    taxValue(
                      quoteData?.quotePrice?.summary["oneTime"]?.appliedTax[0]
                        ?.taxName
                    ) +
                    " (" +
                    quoteData?.quotePrice?.summary["oneTime"].appliedTax[0]
                      .percentage +
                    "%)"
                  }}</span
                >
              </td>
              <td style="border: none !important; padding: 0px">
                <span
                  v-if="priceType['priceType'].toLowerCase() == 'onetime'"
                  class="label"
                >
                  {{
                    currencyFormatter(
                      quoteData?.quotePrice?.summary["oneTime"]?.appliedTax[0]
                        ?.value,
                      {
                        currency: priceType.price.taxIncludedAmount.unit,
                      }
                    )
                  }}
                </span>
              </td>
            </tr>
            <tr
              v-if="
                quoteData?.quotePrice?.summary['recurring']?.appliedTax
                  .length != 0 &&
                priceType['priceType'].toLowerCase() == 'recurring' &&
                quoteData?.quotePrice?.summary['recurring']?.appliedTax[0]
                  ?.taxName &&
                quoteData?.quotePrice?.summary['recurring']?.appliedTax[0]
                  ?.taxName?.length != 0
              "
              style="display: flex; justify-content: space-between"
            >
              <td
                v-if="
                  quoteData?.quotePrice?.summary['recurring'].appliedTax
                    .length != 0 &&
                  priceType['priceType'].toLowerCase() == 'recurring'
                "
                class="pricing-info"
                style="
                  font-size: 13px;
                  border: none !important;
                  padding: 0px;
                  width: 180px;
                "
              >
                <span class="label"><b>Tax</b></span>
              </td>
            </tr>
            <tr
              v-if="
                quoteData?.quotePrice?.summary['recurring']?.appliedTax
                  .length != 0 &&
                priceType['priceType'].toLowerCase() == 'recurring' &&
                quoteData?.quotePrice?.summary['recurring']?.appliedTax[0]
                  .taxName &&
                quoteData?.quotePrice?.summary['recurring']?.appliedTax[0]
                  ?.taxName?.length != 0
              "
              style="display: flex; justify-content: space-between"
            >
              <td
                v-if="
                  quoteData?.quotePrice?.summary['recurring'].appliedTax
                    .length != 0 &&
                  priceType['priceType'].toLowerCase() == 'recurring'
                "
                class="pricing-info"
                style="
                  font-size: 13px;
                  border: none !important;
                  padding: 0px;
                  width: 180px;
                "
              >
                <span
                  v-if="priceType['priceType'].toLowerCase() == 'recurring'"
                  class="label"
                  >{{
                    taxValue(
                      quoteData?.quotePrice?.summary["recurring"]?.appliedTax[0]
                        ?.taxName
                    ) +
                    " (" +
                    quoteData?.quotePrice?.summary["recurring"].appliedTax[0]
                      .percentage +
                    "%)"
                  }}</span
                >
              </td>
              <td style="border: none !important; padding: 0px">
                <span
                  v-if="priceType['priceType'].toLowerCase() == 'recurring'"
                  class="label"
                >
                  {{
                    currencyFormatter(
                      quoteData?.quotePrice?.summary["recurring"]?.appliedTax[0]
                        ?.value,
                      {
                        currency: priceType.price.taxIncludedAmount.unit,
                      }
                    )
                  }}
                </span>
              </td>
            </tr>
            <tr
              v-if="
                priceType.priceAlteration[0] &&
                priceType.price.dutyFreeAmount.value &&
                priceType.price.dutyFreeAmount.value != ''
              "
              style="display: flex; justify-content: space-between"
            >
              <td
                class="pricing-info"
                style="
                  font-size: 13px;
                  border: none !important;
                  padding: 0px;
                  width: 180px;
                "
              >
                <span class="label">Discount</span>
              </td>
              <td style="border: none !important; padding: 0px">
                <span
                  v-if="priceType.priceAlteration[0].price.percentage != null"
                  class="label"
                  >{{ priceType.priceAlteration[0].price.value + "%" }}</span
                >
                <span
                  v-if="priceType.priceAlteration[0].price.amount != null"
                  class="label"
                  >{{
                    currencyFormatter(
                      priceType.priceAlteration[0].price.amount,
                      {
                        currency: quoteData?.quotePrice.quoteTotal.unit,
                      }
                    )
                  }}</span
                >
              </td>
            </tr>
            <tr
              v-if="
                priceType.price.dutyFreeAmount.value &&
                priceType.price.dutyFreeAmount.value != ''
              "
              style="display: flex; justify-content: space-between"
            >
              <td
                class="pricing-info"
                style="
                  font-size: 13px;
                  border: none !important;
                  padding: 0px;
                  width: 180px;
                  font-weight: bold;
                "
              >
                <span class="label">Total</span>
              </td>
              <td style="border: none !important; padding: 0px">
                <span class="label">{{
                  currencyFormatter(priceType.quoteTotal.value, {
                    currency: priceType.quoteTotal.unit,
                  })
                }}</span>
              </td>
            </tr>
            <tr
              v-if="index == quoteData?.quoteTotalPrice.length - 2"
              style="
                display: flex;
                justify-content: space-between;
                border-top: 1px solid #ddd;
                margin-top: 5px;
                padding-bottom: 10px;
                padding-top: 10px;
              "
            >
              <td
                class="pricing-info"
                style="
                  font-size: 12pt;
                  border: none !important;
                  padding: 0px;
                  width: 180px;
                  font-weight: bold;
                "
              >
                <span class="label">Overall total</span>
              </td>
              <td
                style="
                  font-size: 12pt;
                  border: none !important;
                  padding: 0px;
                  font-weight: bold;
                "
              >
                {{
                  currencyFormatter(quoteData?.quotePrice.quoteTotal.value, {
                    currency: quoteData?.quotePrice.quoteTotal.unit,
                  })
                }}
              </td>
            </tr>
            <tr
              v-if="index == quoteData?.quoteTotalPrice.length - 2"
              style="
                display: flex;
                justify-content: space-between;
                margin-top: 5px;
              "
            >
              <td
                class="pricing-info"
                style="
                  font-size: 13px;
                  border: none !important;
                  padding: 0px;
                  width: 180px;
                "
              >
                <span class="label">Total Contract Value</span>
              </td>
              <td
                style="font-size: 13px; border: none !important; padding: 0px"
              >
                {{
                  currencyFormatter(
                    quoteData?.quotePrice.totalContractValue.value,
                    {
                      currency: quoteData?.quotePrice.totalContractValue.unit,
                    }
                  )
                }}
              </td>
            </tr>
            <tr
              v-if="index == quoteData?.quoteTotalPrice.length - 2"
              style="
                display: flex;
                justify-content: space-between;
                margin-top: 5px;
              "
            >
              <td
                class="pricing-info"
                style="
                  font-size: 13px;
                  border: none !important;
                  padding: 0px;
                  width: 180px;
                "
              >
                <span class="label">You'll save</span>
              </td>
              <td
                style="font-size: 13px; border: none !important; padding: 0px"
              >
                {{
                  currencyFormatter(quoteData?.quotePrice.savings.value, {
                    currency: quoteData?.quotePrice.savings.unit,
                  })
                }}
              </td>
            </tr>
          </table>
        </section>

        <!-- currencyFormatter(quoteData.quotePrice.quoteTotal.value,{currency:quoteData.quotePrice.quoteTotal.unit}) }} -->
      </section>
      <section
        :id="'quote-preview-page-each-container2'"
        class="quote-preview-page-each-container"
        style="
          padding-top: 60px;
          margin-left: 20px;
          margin-right: 20px;
          /* position: relative; */
        "
      >
        <!--multiple Signature-->
        <section
          style="
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 20px;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
              'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, OpenSans,
              'Helvetica Neue', sans-serif;
          "
        >
          <table style="width: 0px !important">
            <tbody>
            <tr v-for="(i, index) in signaturePlaced?.nofields" :key="index">
              <td
                :id="i.focusId"
                :style="[i.sign == true ? 'margin-right:-9px' : '']"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  position: relative;
                "
              >
                <signaturepad
                  :key="updateModalKey"
                  :disabled="showSignaturePad(i)"
                  :details="detailsFunc(i)"
                  :signature-placed-value="'nofields'"
                  :next-sign="true"
                  :show-sign="i.sign"
                  :signature-value="i?.signatureRepresentation"
                  :pre-value="preValSignaturePlaced(i, 'nofields')"
                  :signer-id="i.id"
                  :signer-name="i.name"
                  :signer-color="i.color"
                  @already-placed-signature="alreadySign(i, $event, 'nofields')"
                  @emit-signature="
                    multipleSignature(index, 'nofields', i, $event)
                  "
                  @emit-focus="prevNextFocus(i, 'nofields', $event)"
                  @e-signature="signaturePlacement"
                  @auto-signature="autoSignature"
                ></signaturepad>
                <span
                  :id="i.focusId + 'next' + 'sign'"
                  class="focus-element"
                  style="
                    height: 24px;
                    width: 70px;
                    background: #eebf18;
                    position: absolute;
                    left: 231px;
                    padding: 3px;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    display: none;
                    border-radius: 0px 5px 5px 0px;
                    top: 9px;
                  "
                  @click="focusSign(i.focusId, i.focusId + 'next' + 'sign')"
                  >Next</span
                >
                <span
                  :id="i.focusId + 'prev' + 'sign'"
                  class="focus-element"
                  style="
                    height: 24px;
                    width: 70px;
                    position: absolute;
                    left: 300px;
                    padding: 3px;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    display: none;
                    top: 9px;
                  "
                  @click="focusSign(i.focusId, i.focusId + 'prev' + 'sign')"
                  >Previous</span
                >
              </td>
            </tr>
          </tbody>
          </table>
        </section>
        <!--Terms and conditions-->
        <span v-for="(i, index) in quoteData?.agreement" :key="index">
          <table style="padding: 0 20px" class="extra-margin">
            <tr
              v-if="i.terms.length != 0"
              style="font-size: 12pt; border: none !important"
            >
              <th
                style="
                  font-size: 12pt;
                  border: none !important;
                  font-weight: bold;
                  color: black !important;
                  font-family: system-ui, -apple-system, BlinkMacSystemFont,
                    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                    'Helvetica Neue', sans-serif;
                "
              >
                {{ i.name }}
              </th>
            </tr>
          </table>
          <span v-if="i['@referredType'] == 'TermsAndConditions'">
            <table style="padding: 0 20px" class="extra-margin">
              <tr v-for="(j, col_index) in i.terms" :key="col_index">
                <td
                  style="
                    font-size: 12pt;
                    border: none !important;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont,
                      'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                      'Helvetica Neue', sans-serif;
                  "
                >
                  {{ col_index + 1 + ". " + j.label }}
                </td>
              </tr>
            </table>
          </span>
        </span>
        <span> </span>
        <!--multiple Signature-->
        <section
          style="
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 20px;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
              'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, OpenSans,
              'Helvetica Neue', sans-serif;
          "
        >
          <table style="width: 0px !important">
            <tbody>
            <tr
              v-for="(i, index) in signaturePlaced?.termsandconditions"
              :key="index"
            >
              <td
                :id="i.focusId"
                :style="[i.sign == true ? 'margin-right:-9px' : '']"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  position: relative;
                "
              >
                <signaturepad
                  :key="updateModalKey"
                  :disabled="showSignaturePad(i)"
                  :details="detailsFunc(i)"
                  :signature-placed-value="'termsandconditions'"
                  :prev-sign="true"
                  :next-sign="true"
                  :signature-value="i?.signatureRepresentation"
                  :show-sign="i.sign"
                  :pre-value="preValSignaturePlaced(i, 'termsandconditions')"
                  :signer-id="i.id"
                  :signer-name="i.name"
                  :signer-color="i.color"
                  @already-placed-signature="
                    alreadySign(i, $event, 'termsandconditions')
                  "
                  @emit-signature="
                    multipleSignature(index, 'termsandconditions', i, $event)
                  "
                  @emit-focus="prevNextFocus(i, 'termsandconditions', $event)"
                  @e-signature="signaturePlacement"
                  @auto-signature="autoSignature"
                ></signaturepad>
                <span
                  :id="i.focusId + 'next' + 'sign'"
                  class="focus-element"
                  style="
                    height: 24px;
                    width: 70px;
                    background: #eebf18;
                    position: absolute;
                    left: 231px;
                    padding: 3px;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    display: none;
                    border-radius: 0px 5px 5px 0px;
                    top: 9px;
                  "
                  @click="focusSign(i.focusId, i.focusId + 'next' + 'sign')"
                  >Next</span
                >
                <span
                  :id="i.focusId + 'prev' + 'sign'"
                  class="focus-element"
                  style="
                    height: 24px;
                    width: 70px;
                    position: absolute;
                    left: 300px;
                    padding: 3px;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    display: none;
                    top: 9px;
                  "
                  @click="focusSign(i.focusId, i.focusId + 'prev' + 'sign')"
                  >Previous</span
                >
              </td>
            </tr>
          </tbody>
          </table>
        </section>
        <!--Attachments-->
        <span v-if="attachments.length != 0">
          <span v-for="(i, index) in attachments" :key="index">
            <table style="padding: 0 20px" class="extra-margin">
              <tbody>
              <tr v-if="index == 0">
                <th
                  style="
                    font-size: 12pt;
                    color: black !important;
                    border: none !important;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont,
                      'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                      'Helvetica Neue', sans-serif;
                  "
                >
                  Attachments
                </th>
              </tr>

              <tr
                v-if="i.mimeType.filename"
                style="font-size: 12pt; border: none !important"
              >
                <td
                  style="
                    font-size: 12pt;
                    border: none !important;
                    color: #0069c2;
                    cursor: pointer;
                    text-decoration: underline;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont,
                      'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                      'Helvetica Neue', sans-serif;
                  "
                >
                  <!-- {{ i.mimeType.url }} -->
                  <!-- :href="`https://tmf-upc-ghubgwxrcq-pd.a.run.app/gcpupload/files/${i.mimeType.filename}?api_key=${'hlx-api-key 4651efd8d5060bfb670cf710e76ce562:de9717fa1b4b6a11058a74a1678e9fcc078f1e3137057ae5ce229f4cea2035ac5c577c2caea4edfc4fdfd3980be485646e608b81d812fdbed8d60dc60e92da73'}`"  -->
                  <a
                    :href="`https://upc-halleyx-ghubgwxrcq-uc.a.run.app/UPC/Quotes/${i.mimeType.filename}`"
                  >
                    {{ i.mimeType.filename }}</a
                  >
                </td>
              </tr>
              <span v-if="i['@referredType'] == 'TermsAndConditions'">
                <tr v-for="(j, col_index) in i.terms" :key="col_index">
                  <td
                    style="
                      font-size: 12pt;
                      border: none !important;
                      font-family: system-ui, -apple-system, BlinkMacSystemFont,
                        'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
                        'OpenSans', 'Helvetica Neue', sans-serif;
                    "
                  >
                    {{ col_index + 1 + ". " + j.name }}
                  </td>
                </tr>
              </span>
              </tbody>
            </table>
          </span>
        </span>
        <!--signature-->
        <!--multiple Signature-->
        <section
          style="
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 20px;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
              'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, OpenSans,
              'Helvetica Neue', sans-serif;
          "
        >
          <table style="width: 0px !important">
            <tbody>
            <tr v-for="(i, index) in signaturePlaced?.pageend" :key="index">
              <td>
                <div
                  :id="i.focusId"
                  :style="[i.sign == true ? 'margin-right:-9px' : '']"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    position: relative;
                  "
                >
                  <!--      -->
                  <signaturepad
                    :key="updateModalKey"
                    :disabled="showSignaturePad(i)"
                    :details="detailsFunc(i)"
                    :signature-placed-value="'pageend'"
                    :prev-sign="true"
                    :signature-value="i?.signatureRepresentation"
                    :pre-value="preValSignaturePlaced(i, 'pageend')"
                    :show-sign="i.sign"
                    :signer-id="i.id"
                    :signer-name="i.name"
                    :signer-color="i.color"
                    @emit-signature="
                      multipleSignature(index, 'pageend', i, $event)
                    "
                    @already-placed-signature="alreadySign(i, $event, 'pageend')"
                    @emit-focus="prevNextFocus(i, 'pageend', $event)"
                    @e-signature="signaturePlacement"
                    @auto-signature="autoSignature"
                  ></signaturepad>
                  <span
                    :id="i.focusId + 'next' + 'sign'"
                    class="focus-element"
                    style="
                      height: 24px;
                      width: 70px;
                      background: #eebf18;
                      position: absolute;
                      left: 231px;
                      padding: 3px;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                      display: none;
                      border-radius: 0px 5px 5px 0px;
                      top: 9px;
                    "
                    @click="focusSign(i.focusId, i.focusId + 'next' + 'sign')"
                    >Next</span
                  >
                  <span
                    :id="i.focusId + 'prev' + 'sign'"
                    class="focus-element"
                    style="
                      height: 24px;
                      width: 70px;
                      position: absolute;
                      left: 300px;
                      padding: 3px;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                      display: none;
                      top: 9px;
                    "
                    @click="focusSign(i.focusId, i.focusId + 'prev' + 'sign')"
                    >Previous</span
                  >
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </section>
      <div class="quote-preview-page-signature-container">
        <table>
          <tbody>
            <tr style="display: flex; justify-content: space-between">
              <td style="border: none !important">
                <span>
                  <!-- <signatureComponent
                  v-if="
                    isNeedManagerApproval ||
                    requestNotFromManagerOrClient ||
                    isManagerApproved
                  "
                  mode="approver"
                  :editable="true"
                  :managerpre-value="managerApproval"
                  :sender="senderContact"
                  @signature-data="handleManagerSignature"
                ></signatureComponent> -->
                  <!-- <hlx-button
                v-if="
                    isNeedManagerApproval ||
                    requestNotFromManagerOrClient ||
                    isManagerApproved"
      class="primary sm --button-with-icon"
      @click="approverQuote"
      ><i class="icon-user-eye-regular"></i>Approve</hlx-button
    > -->
                </span>
              </td>
              <td style="width: 200px; border: none !important">
                <!-- v-if="
                  isNeedClientApproval ||
                  isClientSignPendingOnClientApproval ||
                  isClientApproved ||
                  (clientAuth &&
                    clientAuth.name &&
                    clientAuth.name == 'Quote client confirmation' &&
                    clientAuth.state &&
                    clientAuth.state == 'approved')
                " -->
                <!-- <signatureComponent
                mode="customer"
                :clientpre-value="clientApproval"
                :recipient="recipientContact"
                :editable="
                  isNeedClientApproval ||
                  isClientSignPendingOnClientApproval ||
                  isClientApproved ||
                  (clientAuth &&
                    clientAuth.name &&
                    clientAuth.name == 'Quote client confirmation' &&
                    clientAuth.state &&
                    clientAuth.state == 'approved')
                    ? true
                    : false
                "
                @signature-data="handleClientSignature"
              ></signatureComponent> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </section>
      <!-- <section class="quote-preview-page-each-container">
      <div class="quote-header">
        <section class="quote-header-left">
          <section class="quote-header-each-section">
            <h3>Quotation:</h3>
            <p>{{ quoteData.description }}</p>
          </section>
          <section class="quote-header-each-section">
            <h4>Date:</h4>
            <p>{{ convertDate(quoteData.quoteDate) }}</p>
          </section>
        </section>
      </div>
      <div class="quote-details">
        <section class="quote-details-left">
          <section class="quote-details-each-section">
            <h3>Quote from</h3>
            <p>{{ quoteFrom }}</p>
            <p>{{ senderAddress }}</p>
          </section>
        </section>
        <section class="quote-details-right">
          <section class="quote-details-each-section">
            <h3>Quote for</h3>
            <p>{{ quoteFor }}</p>
            <span>{{ buyerAddress }}</span>
          </section>
          <section class="quote-details-each-section">
            <h3>Bill To</h3>
            <p>{{ billTo }}</p>
            <span>{{ billingAddress }}</span>
          </section>
        </section>
      </div>
      <hlx-table
        :border="['table', 'header']"
        :bold-headers="false"
        :row-hover="false"
        theme="grey"
        :striped-rows="false"
        :column-count="6"
      >
        <template #thead>
          <hlx-table-head
            v-for="(i, col_index) in attributes"
            :key="col_index"
            :width="i.width"
            >{{ i.label }}</hlx-table-head
          >
        </template>
        <template #tbody>
          <tr
            v-for="(i, colindex) in firstPagetableArray"
            :id="'value' + col_index + colindex"
            :key="colindex"
          >
            <hlx-table-cell
              v-for="(j, coindex) in attributes"
              :key="coindex"
              :align="j.align"
            >
              <span v-if="j.label == 'Name'">
                {{ i["productOffering"]["name"] }}
              </span>
              <span v-if="j.label == 'Item'">
                {{ i["id"] }}
              </span>
              <span v-if="j.label == 'Quantity'">
                {{ i["quantity"] }}
              </span>
              <span v-if="j.prop == 'price'">
                <span>
                  {{
                    '$' + i?.quoteItemPrice[0]?.price?.dutyFreeAmount?.value || "0"
                  }}
                </span>
              </span>
              <span v-if="j.prop == 'discount'">
                <span
                  v-if="
                    i.quoteItemPrice[0] != undefined &&
                    i.quoteItemPrice[0]?.priceAlteration != undefined &&
                    i.quoteItemPrice[0]?.priceAlteration[0] != undefined &&
                    i.quoteItemPrice[0]?.priceAlteration[0]?.price !=
                      undefined &&
                    i.quoteItemPrice[0]?.priceAlteration[0]?.price
                      ?.percentage != undefined
                  "
                >
                  {{
                    i.quoteItemPrice[0]?.priceAlteration[0]?.price
                      ?.percentage || "0" + "%"
                  }}
                </span>
                <span v-else>
                  {{ "0" }}
                </span>
              </span>
              <span v-if="j.label == 'Total'">
                <span>
                  {{
                   '$' + i?.quoteItemPrice[0]?.price?.dutyFreeAmount?.value || "0"
                  }}
                </span>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table> -->
      <!-- Pricing calculation -->
      <!-- <section class="pricing-summary-info-container">
        <div
          v-for="priceType in Object.keys(calculatedPricingInfo)"
          :key="priceType"
          class="pricing-summary-wrapper"
        >
          
          <p class="pricing-type-title --title">{{ priceType == 'oneTime'?"Onetime":priceType == 'recurring'?"Recurring":"" }}</p>
          <p class="pricing-info"><span class="label">Item total</span><span class="label">{{ currencyFormat("$",calculatedPricingInfo[priceType]['itemTotal'].value,"" ) }}</span></p>
          <p class="pricing-info"><span class="label">Tax</span><span class="label">{{ currencyFormat("$",calculatedPricingInfo[priceType]['taxAmount'].value,"") }}</span></p>
          <p class="pricing-info"><span class="label">Discount</span><span class="label">{{ currencyFormat("$",calculatedPricingInfo[priceType]['discountAmount'].value,"") }}</span></p>
          <br>
          <p class="pricing-info"><span class="label">Total</span><span class="label">{{currencyFormat("$",formatter.format((parseFloat(calculatedPricingInfo[priceType]['itemTotal'].value) + parseFloat(calculatedPricingInfo[priceType]['taxAmount'].value)) - parseFloat(calculatedPricingInfo[priceType]['discountAmount'].value)),"") }}</span></p>
          
        </div>
      </section> -->
      <!-- </section> -->
      <!-- <section v-for="(containerData,index) in quoteArray" :key="index" class="quote-preview-page-each-container">
        <div  :id="'data-container'+index">
          <hlx-table
          :border="['table', 'header']"
          :bold-headers="false"
          :row-hover="false"
          theme="grey"
          :striped-rows="false"
          :column-count="6"
        >
          <template #thead>
            <hlx-table-head
              v-for="(i, col_index) in attributes"
              :key="col_index"
              :width="i.width"
              >{{ i.label }}</hlx-table-head
            >
          </template>
          <template #tbody>
            <tr v-for="(i, colindex) in containerData" :id="'value'+index+colindex" :key="colindex">
              <hlx-table-cell
                v-for="(j, coindex) in attributes"
                :key="coindex"
                :align="j.align"
              >
                <span v-if="j.label == 'Name'">
                  {{ i['productOffering']['name'] }}
                </span>
                <span v-if="j.label == 'Item'">
                  {{ i['id'] }}
                </span>
                <span v-if="j.label == 'Quantity'">
                  {{ i['quantity'] }}
                </span>
                <span v-if="j.prop == 'price'">
                  <span>
                    {{ '$' +  i?.quoteItemPrice[0]?.price?.dutyFreeAmount?.value || '0' }}
                  </span>
                </span>
                <span v-if="j.prop == 'discount'">
                  <span v-if="i.quoteItemPrice[0] != undefined && i.quoteItemPrice[0]?.priceAlteration != undefined && i.quoteItemPrice[0]?.priceAlteration[0] != undefined && i.quoteItemPrice[0]?.priceAlteration[0]?.price != undefined && i.quoteItemPrice[0]?.priceAlteration[0]?.price?.percentage != undefined">
                    {{ i.quoteItemPrice[0]?.priceAlteration[0]?.price?.percentage || '0' + '%' }}
                  </span>
                  <span v-if="j.prop == 'total'">
                    {{'-'}}
                  </span>
                  <span v-else>
                    {{'0'}}
                  </span>
                </span>
                <span v-if="j.label == 'Total'">
                <span>
                  {{
                   '$' + i?.quoteItemPrice[0]?.price?.dutyFreeAmount?.value || "0"
                  }}
                </span>
              </span>
              </hlx-table-cell>
            </tr>
          </template>
        </hlx-table>

        </div> -->

      <!-- Pricing calculation -->
      <!-- <section class="pricing-summary-info-container">
        <div
          v-for="priceType in Object.keys(calculatedPricingInfo)"
          :key="priceType"
          class="pricing-summary-wrapper"
        >
          
          <p class="pricing-type-title --title">{{ priceType == 'oneTime'?"Onetime":priceType == 'recurring'?"Recurring":"" }}</p>
          <p class="pricing-info"><span class="label">Item total</span><span class="label">{{ currencyFormat("$",calculatedPricingInfo[priceType]['itemTotal'].value,"" ) }}</span></p>
          <p class="pricing-info"><span class="label">Tax</span><span class="label">{{ currencyFormat("$",calculatedPricingInfo[priceType]['taxAmount'].value,"") }}</span></p>
          <p class="pricing-info"><span class="label">Discount</span><span class="label">{{ currencyFormat("$",calculatedPricingInfo[priceType]['discountAmount'].value,"") }}</span></p>
          <br>
          <p class="pricing-info"><span class="label">Total</span><span class="label">{{currencyFormat("$",formatter.format((parseFloat(calculatedPricingInfo[priceType]['itemTotal'].value) + parseFloat(calculatedPricingInfo[priceType]['taxAmount'].value)) - parseFloat(calculatedPricingInfo[priceType]['discountAmount'].value)),"") }}</span></p>
          
        </div>
      </section> -->
      <!-- </section>   -->

      <!-- <section
      v-for="(data, index) in additionalFields"
      :key="index"
      class="quote-preview-page-each-container"
    >
      <section v-for="(item, col_index) in data" :key="col_index"> -->
      <!--Terms and conditions-->
      <!-- <div
          v-if="item['@baseType'] && item['@baseType'] == 'Agreement'"
          class="quote-preview-page-terms-conditions"
        >
          <h4 v-if="item && item.terms.length != 0" :id="'additional-fields'+index + col_index">{{ item.name }}</h4>
          <div style="display: flex; flex-direction: column; margin-top: 5px">
            <span
              v-for="(valueoftermsandconditions, z_index) in item.terms"
              :id="'additional-fields' + index + col_index + z_index"
              :key="z_index"
              >{{ z_index + 1 + ". "
              }}{{ valueoftermsandconditions.label }}</span
            >
          </div>
        </div> -->

      <!--Notes-->
      <!-- <div
          v-if="item['@type'] && item['@type'] == 'Note'"
          :id="'additional-fields' + index + col_index"
          class="quote-preview-page-terms-conditions"
        >
          <h4 v-if="item.text && item.text != ''">{{ "Notes" }}</h4>
          <div
            class="content-editable-container"
            style="display: flex; flex-direction: column; margin-top: 5px"
          >
            <ContentEditable :prevalue="item.text"></ContentEditable>
          </div>
        </div> -->

      <!--Additional Notes-->
      <!-- <div
          v-if="item['@type'] && item['@type'] == 'AdiitionalNote'"
          :id="'additional-fields' + index + colindex"
          class="quote-preview-page-terms-conditions"
        >
          <div
            class="content-editable-container"
            style="display: flex; flex-direction: column; margin-top: 5px"
          >
            <h4 v-if="col_index == 0">{{ "Additional Notes" }}</h4>
            <span>{{ item.text }}</span>
          </div>
        </div> -->

      <!--signature-->
      <!-- <div
          v-if="col_index == data.length - 1 && index == additionalFields.length-1"
          class="quote-preview-page-signature-container"
        >
          <div class="signature-section">
            <div class="signature-section-left"> -->
      <!-- <h5>Signed by</h5> -->
      <!-- <signatureComponent
                v-if="
                  isNeedManagerApproval ||
                  requestNotFromManagerOrClient ||
                  isManagerApproved
                "
                :managerpre-value="managerApproval"
                :sender="senderContact"
                @signature-data="handleManagerSignature"
              ></signatureComponent>
            </div>
            <div class="signature-section-right"> -->
      <!-- <h5>Signed for</h5> -->
      <!-- {{ isNeedClientApproval }}
              {{ requestNotFromManagerOrClient }}
              {{ isClientApproved }} -->
      <!-- <signatureComponent
                v-if="
                  isNeedClientApproval ||
                  requestNotFromManagerOrClient ||
                  isClientApproved
                "
                :clientpre-value="clientApproval"
                :recipient="recipientContact"
                @signature-data="handleClientSignature"
              ></signatureComponent> -->
    </div>
    <section
      v-if="isVersionVisible"
      style="
        position: fixed;
        right: 0;
        background-color: white;
        margin-top: 82px;
      "
    >
      <versionHistoryListingComponent
        :version-history-list="quoteVersionList"
        @emit-selected-version="reassignQuoteData"
        @restore-quote="restoreQuoteData"
      ></versionHistoryListingComponent>
    </section>
  </div>
  <footer
    v-if="
      (!quoteAccessDenied && !$route.query.request_for) ||
      (!quoteAccessDenied &&
        !isQuoteSigned &&
        $route.query.request_for == 'client_approval')
    "
    v-show="false"
    class="tool-bar"
    :style="{
      left: $route.query.request_for == 'client_approval' ? '0' : '72px',
    }"
  >
    <div class="back-button" @click="handleQuoteBack">
      <i
        v-if="$route.query.request_for != 'client_approval'"
        class="icon-arrow-left-regular"
      ></i>
      <p v-if="$route.query.request_for != 'client_approval'">Back</p>
    </div>
    <!-- <hlx-button
      v-if="isNeedToSentManagerApproval && !isWaitingForManagerApproval"
      class="primary sm --button-with-icon"
      @click="sendForApproval"
      ><i class="icon-user-eye-regular"></i>Send for approval</hlx-button
    > -->
    <hlx-label
      v-if="isWaitingForClientApproval && !isVersionVisible"
      class="info --ml"
    >
      Waiting for client accept the quote</hlx-label
    >
    <hlx-label
      v-if="
        isQuoteAccepted &&
        !isVersionVisible &&
        $route.query.request_for != 'client_approval'
      "
      class="primary --ml"
    >
      Quote accepted</hlx-label
    >
    <!-- {{ isQuoteSignedByClient }} -->
    <!-- <hlx-label v-if="isWaitingForManagerApproval" class="info">
      Waiting for Manager approve the quote</hlx-label
    > -->
    <!-- <hlx-button
      v-if="isQuoteAccepted"
      class="secondary sm --button-with-icon"
      @click="handleQuoteDownload"
      >
      <i class="icon-download-regular"></i>Download</hlx-button
    > -->
    <i
      style="color: var(--hlx-color-primary)"
      class="icon-download-regular"
      @click="handleQuoteDownload1"
    ></i>

    <!-- <hlx-button
      v-if="isNeedManagerApproval"
      class="primary sm --button-with-icon"
      :disabled="!isQuoteSignedByManager"
      @click="signAndApprove"
      ><i class="icon-user-eye-regular"></i>Sign & send to customer</hlx-button
    > -->
    <!-- {{ isFromClientApproval }} -->
    <hlx-button
      v-if="
        !['client_approval', 'manager_approval', 'approver_approval'].includes(
          $route.query.request_for
        )
      "
      class="secondary sm --ml"
      @click="handleQuoteDownload1"
    >
      <i
        style="color: var(--hlx-color-primary)"
        class="icon-download-regular"
      ></i
      >&nbsp; Download
    </hlx-button>
    <hlx-button
      v-if="isNeedManagerApproval && inFuture && !isVersionVisible"
      class="primary sm --button-with-icon"
      :disabled="!isQuoteSignedByManager"
      @click="signAndApprove"
      ><i class="icon-user-eye-regular"></i>Send to customer</hlx-button
    >
    <hlx-button
      v-if="isNeedManagerApproval && !isVersionVisible"
      class="secondarry sm --button-with-icon"
      @click="editQuote"
      ><i class="icon-credit-card-edit-regular"></i>Edit</hlx-button
    >
    <!-- {{ "isClientNeedToSign" + isClientNeedToSign }}
{{ "isQuoteSignedByClient" + !isQuoteSignedByClient }}
{{ "isClientSignPendingOnClientApproval" + isClientSignPendingOnClientApproval }} 
   :disabled="!isManagerApproved" 
-->
    <hlx-button
      v-if="isClientNeedToSign && !isVersionVisible"
      :disabled="!isClientNeedToSign"
      class="primary sm --button-with-icon --ml"
      @click="signAndAccept"
      ><i class="icon-user-eye-regular"></i>Sign & Accept quote</hlx-button
    >
    <!-- Button for Sending the create quote to clients -->
    <hlx-button
      v-if="
        quoteData.state != 'approved' &&
        quoteData.state != 'accepted' &&
        isNeedToSentToCustomer &&
        !isFromClientApproval &&
        !isVersionVisible &&
        (isNeedManagerApproval ||
          requestNotFromManagerOrClient ||
          isManagerApproved)
      "
      class="primary sm --button-with-icon"
      @click="sendToCustomer"
      ><i class="icon-user-dollar-regular"></i>Send to customer</hlx-button
    >
  </footer>
  <!-- </div> -->
  <!-- </div>
      </section>
    </section> -->
  <!-- </div>  -->
  <!-- tiers[0] -->
</template>
<script>
import { mapState } from "vuex";
import {
  BPMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import signaturepad from "@/components/UPC/CPQ/signaturePadWholeComponent.vue";
// import signatureComponent from "@/components/UPC/CPQ/signatureComponent.vue";
// import multiSignComponent from "@/components/UPC/CPQ/multipleSignComponent.vue"
import versionHistoryListingComponent from "@/components/versionHistoryListingComponent.vue";
import axios from "axios";
// import { isClient } from "element-plus/es/utils";
console.log("🚀 ~ axios:", axios);
// import html2pdf from 'html2pdf'
// console.log("🚀 ~ html2pdf:", html2pdf)
// import html2pdf  from 'html2pdf.js';
// import jsPDF from 'jspdf';
// import { jsPDF } from "jspdf";
// import html2pdf from "html2pdf.js";
// console.log("🚀 ~ jsPDF:", jsPDF);
// import samplePdf from '@/sampleQuote.pdf'
// console.log("🚀 ~ samplePdf:", samplePdf)
import { Buffer } from "buffer";
export default {
  name: "PreviewQuoteTemplate",
  components: {
    //signatureComponent,
    // multiSignComponent,
    versionHistoryListingComponent,
    signaturepad,
  },
  beforeRouteLeave(to, from, next) {
    console.log(
      this.quoteApproved,
      "beforeRouteLeave",
      this.reassignedQuoteId,
      this.$route.query.quote_id
    );
    if (this.quoteApproved) {
      if (to.name !== "QuoteTable") {
        next({ name: "QuoteTable" });
      } else {
        next();
      }
      return;
    } else {
      let route = this.reassignedQuoteId
        ? this.reassignedQuoteId
        : this.$route.query.quote_id;
      console.log(this.reassignedQuoteId, "beforeRouteLeave", route);
      if (this.intervalID) {
        console.log("🚀 cleared", this.intervalID);
        clearInterval(this.intervalID);
        this.intervalID = null;
      }
      next();
      setTimeout(() => {
        this.$router.push({
          name: "CPQDefaultTemplate",
          query: { quote_id: route, mode: "edit" },
        });
      }, 0);
      return;
    }
  },
  data() {
    return {
      externalId:'',
      startButton: false,
      clientName: "",
      signCount: 0,
      signIdValue: "",
      prevValue: "",
      multipleSignatureCount: 0,
      disableSignAndAcceptButton: false,
      updateModalKey: 0,
      isQuoteSigned: false,
      contactName: "",
      signaturePlacedArea: "",
      multiBuyerEmail: [],
      quoteApproved: false,
      multipleSignatureLocation: [],
      signaturePlaced: {},
      reassignedQuoteId: "",
      restore_data_id: "",
      restored_time_stamp: "",
      restore_modal_quote: false,
      changeQuoteVersionData: {},
      quoteVersionList: [],
      displayId: "",
      attachments: [],
      inFuture: false,
      buyerPhone: "",
      sellerAddress: "",
      sellerEmail: "",
      sellerPhone: "",
      intervalID: null,
      billemail: "",
      fromemail: "",
      toemail: "",
      routeFrom: null,
      profilePicturePrevalue: "",
      hide: false,
      quoteAccessDenied: false,
      tableDataArray: [],
      data2: [],
      firstPagetableArray: [],
      indexlength: 0,
      indev: true,
      senderContact: "",
      recipientContact: "",
      managerApproval: {},
      clientApproval: {},
      notification: {},
      quoteData: {},
      isQuoteSignedByManager: false,
      isQuoteSignedByClient: false,
      managerAuth: null,
      clientAuth: null,
      // isNeedApproval: true,
      // isNeedClientSignature: false,
      // isNeedToSentToCustomer: true,
      quoteTotalPrice: 19999999.29,
      quoteDiscount: 19,
      // quoteData: {},
      approvalTemplate: "",
      client: null,
      quoteFrom: "",
      quoteFor: "",
      billTo: "",
      senderAddress: "",
      buyerAddress: "",
      billingAddress: "",
      quoteArray: [],
      tableData: [],
      duplicateArray: [],
      duplicateAdditionalFields: [],
      indexLength: 0,
      additionalFields: [],
      attributes: [
        {
          prop: "productOffering.name",
          label: "Name",
          width: 150,
          type: "string",
          format: "",
          align: "left",
        },
        {
          prop: "color",
          label: "Quantity",
          width: 70,
          type: "string",
          format: "",
          align: "right",
        },
        {
          prop: "price",
          label: "Unit price",
          width: 100,
          type: "string",
          format: "",
          align: "right",
        },
        {
          prop: "discount",
          label: "Discount",
          width: 70,
          type: "string",
          format: "",
          align: "right",
        },
        {
          prop: "total",
          label: "Total",
          width: 100,
          type: "string",
          format: "",
          align: "right",
        },
      ],
      quoteFromId: null,
      quoteForId: null,
      buyer: null,
    };
  },
  computed: {
    ...mapState({
      isVersionVisible: (state) => state.showVersion,
    }),
      isApproverView() {
      if (
        !this.quoteData ||
        (this.quoteData && Object.keys(this.quoteData).length == 0) ||
        (!this.$route.query.approverId &&
          !this.$route.query.requestFor &&
          this.$route.query.requestFor &&
          this.$route.query.requestFor !== "approval")
      )
        return false;
      const isApproverFound = this.quoteData?.authorization?.find(
        (auth) =>
          auth["@type"] == "approverAuthorization" &&
          auth.approver &&
          auth.approver[0] &&
          auth.approver[0].id == this.$route.query.approverId
      );
      if (isApproverFound) return true;
      return false;
    },
    computedQuoteTotalPrice() {
      if (this.quoteData && this.quoteData.quoteTotalPrice) {
        return this.quoteData?.quoteTotalPrice.filter(
          (price) =>
            !price["@type"] ||
            (price["@type"] && price["@type"] != "totalContractValue")
        );
      }
      return [];
    },
    isFromClientApproval() {
      return this.$route.query?.request_for == "client_approval";
    },
    requestNotFromManagerOrClient() {
      return (
        !this.$route.query.request_for ||
        (this.$route.query.request_for &&
          !["client_approval", "manager_approval"].includes(
            this.$route.query.request_for
          ))
      );
    },

    isNeedManagerApproval() {
      return (
        (this.quoteTotalPrice > 150000 || this.quoteDiscount > 10) &&
        ["inprogress"].includes(this.quoteData?.state?.toLowerCase()) &&
        this.$route.query?.request_for == "manager_approval"
      );
    },
    isQuoteAccepted() {
      return this.quoteData?.state?.toLowerCase() == "accepted";
    },
    isNeedToSentManagerApproval() {
      return false;
      // return (
      //   (this.quoteTotalPrice > 150000 || this.quoteDiscount > 10) &&
      //   ["inprogress"].includes(this.quoteData.state?.toLowerCase()) &&
      //   !["client_approval", "manager_approval"].includes(
      //     this.$route.query?.request_for
      //   )
      // );
    },
    isWaitingForClientApproval() {
      let isWaiting = this.quoteData?.authorization?.find(
        (authPerson) =>
          authPerson.name == "Quote client confirmation" &&
          authPerson.state?.toLowerCase() == "pending" &&
          this.$route.query?.request_for !== "client_approval"
      );
      console.log("🚀 ~ isWaitingForClientApproval ~ isWaiting:", isWaiting);
      if (isWaiting) return true;
      return false;
    },
    isClientSignPendingOnClientApproval() {
      let approverId = this.$route.query.approver_id;
      console.log("approverId", approverId);
      let isWaiting = this.quoteData?.authorization?.find(
        (authPerson) =>
          authPerson?.approver[0].id == approverId &&
          authPerson.name == "Quote client confirmation" &&
          authPerson.state?.toLowerCase() == "pending" &&
          this.$route.query?.request_for == "client_approval" &&
          this.isQuoteSignedByClient == false
      );
      console.log(
        "🚀 ~ isWaitingForClientApproval isClientSignPendingOnClientApproval ~ his.quoteData?.authorization:",
        this.quoteData?.authorization
      );
      console.log("🚀 ~ isWaitingForClientApproval ~ isWaiting:", isWaiting);
      if (isWaiting) return true;
      return false;
    },
    isClientNeedToSign() {
      // let isWaiting = this.quoteData?.authorization?.find(
      //   (authPerson) =>
      //     authPerson.name == "Quote client confirmation" &&
      //     authPerson.state?.toLowerCase() == "pending" &&
      //     this.$route.query?.request_for == "client_approval"
      // );
      // console.log(
      //   "🚀 ~ isClientNeedToSign ~ this.quoteData?.authorization:",
      //   this.quoteData?.authorization
      // );
      // console.log("🚀 ~ isClientNeedToSign ~ isWaiting:", isWaiting);
      // if (isWaiting) return true;
      // return false;
      let approverId = this.$route.query.approver_id;
      let getTotalSignatureCount = this.quoteData?.authorization?.find(
        (ele) => {
          console.log("element", ele);
          return (
            ele.name === "Quote client confirmation" &&
            ele?.approver[0]?.id === approverId
          );
        }
      )?.totalSignature;
      // If total signature count is 0 or less, return true immediately
      if (
        (!getTotalSignatureCount || getTotalSignatureCount <= 0) &&
        approverId
      ) {
        return true;
      }
      let count = 0;
      let clientSignedOrNot = false;
      console.log("for localHost");
      this.quoteData?.authorization?.forEach((client) => {
        if (
          client.name === "Quote client confirmation" &&
          client?.approver[0]?.id === approverId &&
          client?.signatureRepresentation != null &&
          client?.signatureRepresentation != undefined
        ) {
          console.log(client, "client");
          Object.keys(client).forEach((key) => {
            let value = client[key];
            console.log("client[key]", value);
            if (Array.isArray(value)) {
              value.forEach((ele) => {
                // if (Array.isArray(ele)) {
                //   ele.forEach((val) => {
                //     console.log("Val",val)
                console.log("client[key", ele);
                if (ele.sign) {
                  clientSignedOrNot = client?.signatureRepresentation;
                  count++;
                }
                // });
                // }
              });
            }
          });
        }
      });
      console.log(
        getTotalSignatureCount,
        "getTotalSignatureCount",
        count,
        "clientSignedOrNot =>",
        clientSignedOrNot
      );
      return count === getTotalSignatureCount && clientSignedOrNot;
    },
    isWaitingForManagerApproval() {
      let isWaiting = this.quoteData?.authorization?.find(
        (authPerson) =>
          authPerson.name == "Quote manager approval" &&
          authPerson.state?.toLowerCase() == "pending" &&
          this.$route.query?.request_for !== "manager_approval"
      );
      if (isWaiting) return true;
      return false;
    },
    isManagerApproved() {
      let isWaiting = this.quoteData?.authorization?.find(
        (authPerson) =>
          authPerson.name == "Quote manager approval" &&
          authPerson.state?.toLowerCase() == "approved"
      );
      if (isWaiting) return true;
      return false;
    },
    isClientApproved() {
      let isWaiting = this.quoteData?.authorization?.find(
        (authPerson) =>
          authPerson.name == "Quote client confirmation" &&
          authPerson.state?.toLowerCase() == "approved"
      );
      if (isWaiting) return true;
      return false;
    },
    // isNeedToSentToCustomer() {
    //   return (this.quoteTotalPrice < 150000 || this.quoteDiscount < 10) &&
    //     !["client_approval", "manager_approval"].includes(
    //       this.$route.query?.request_for
    //     )
    //     ? true
    //     : false;
    // },
    isNeedToSentToCustomer() {
      let isWaiting = this.quoteData?.authorization?.find(
        (authPerson) =>
          authPerson.name == "Quote client confirmation" &&
          authPerson.state?.toLowerCase() == "inprogress"
      );
      console.log(
        "🚀 ~ isNeedToSentToCustomer ~ this.quoteData:",
        this.quoteData
      );
      console.log("🚀 ~ isNeedToSentToCustomer ~ isWaiting:", isWaiting);
      if (isWaiting) return true;
      return false;
    },
    isNeedClientApproval() {
      return (
        (this.quoteData?.state?.toLowerCase() == "approved" ||
          this.quoteData?.state?.toLowerCase() == "inprogress") &&
        this.$route.query?.request_for == "client_approval"
      );
    },
    formatter() {
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },

    calculatedPricingInfo() {
      if (!this.quoteData || Object.keys(this.quoteData).length == 0) return {};
      let quoteItemPrices = this.quoteData?.quoteItem?.map((item) => {
        return item?.quoteItemPrice;
      });
      quoteItemPrices = quoteItemPrices.flat();
      // console.log(quoteItemPrices, "item prices");
      let calculatedPrice = quoteItemPrices.reduce(
        (finalObject, currentPrice) => {
          console.log(
            "priceType:",
            currentPrice.priceType,
            "\n" +
              "Previous:" +
              JSON.stringify(finalObject) +
              "\n current:" +
              JSON.stringify(currentPrice)
          );
          if (currentPrice.priceType?.toLowerCase() == "recurring") {
            // console.log("recccc")
            if (
              !finalObject.recurring ||
              (finalObject && Object.keys(finalObject).length == 0)
            ) {
              finalObject = {
                ...finalObject,
                recurring: {
                  itemTotal: { unit: "CAD", value: 0 },
                  itemQTY: 0,
                  taxAmount: { unit: "CAD", value: 0 },
                  taxPercent: 0,
                  discountAmount: { unit: "CAD", value: 0 },
                  discountPercent: 0,
                },
              };
            }

            // console.log(finalObject);
            //   item total
            finalObject.recurring.itemTotal = {
              value:
                parseFloat(finalObject.recurring.itemTotal.value) +
                parseFloat(currentPrice.price.dutyFreeAmount.value),
              unit: currentPrice.price.dutyFreeAmount.unit,
            };
            finalObject.recurring.itemTotal.value = this.formatter.format(
              finalObject.recurring.itemTotal.value
            );

            //  Tax amount
            let itemLevelTaxAmount =
              parseFloat(currentPrice.price.taxIncludedAmount.value) -
              parseFloat(currentPrice.price.dutyFreeAmount.value);
            finalObject.recurring.taxAmount = {
              value:
                parseFloat(finalObject.recurring.taxAmount.value) +
                itemLevelTaxAmount,
              unit: currentPrice.price.taxIncludedAmount.unit,
            };
            finalObject.recurring.taxAmount.value = this.formatter.format(
              finalObject.recurring.taxAmount.value
            );
            // recurringPeriod
            finalObject.recurring.recurringPeriod =
              currentPrice.recurringChargePeriod;

            //   Discounted amount and percent

            if (
              currentPrice.priceAlteration &&
              currentPrice.priceAlteration.length > 0
            ) {
              if (
                currentPrice.priceAlteration[0]?.price &&
                currentPrice.priceAlteration[0]?.price.percentage
              ) {
                // dicount percent
                let itemLevelDiscountAmount =
                  currentPrice.price.dutyFreeAmount.value *
                  (currentPrice.priceAlteration[0].price.percentage / 100);

                // console.log("discount:"
                // +itemLevelDiscountAmount,
                // " dutyFree:"+currentPrice.price.dutyFreeAmount.value+" discount value:"+currentPrice.price.dutyFreeAmount.value*(currentPrice.priceAlteration[0].price.percentage/100)+"  percent:"+currentPrice.priceAlteration[0].price.percentage)

                finalObject.recurring.discountAmount.value =
                  parseFloat(finalObject.recurring.discountAmount.value) +
                  itemLevelDiscountAmount;
                finalObject.recurring.discountAmount.value =
                  this.formatter.format(
                    finalObject.recurring.discountAmount.value
                  );
                finalObject.recurring.discountAmount.unit =
                  currentPrice.price.dutyFreeAmount.unit;
                // Discount percent
                finalObject.recurring.discountPercent =
                  finalObject.recurring.discountPercent +
                  currentPrice.priceAlteration[0].price.percentage;
              }
            }

            return finalObject;
          }
          if (
            ["nonrecurring", "onetime", "one time"].includes(
              currentPrice.priceType?.toLowerCase()
            )
          ) {
            // console.log("recccc")
            if (
              (finalObject && Object.keys(finalObject).length == 0) ||
              !finalObject.oneTime
            ) {
              finalObject = {
                ...finalObject,
                oneTime: {
                  itemTotal: { unit: "CAD", value: 0 },
                  itemQTY: 0,
                  taxAmount: { unit: "CAD", value: 0 },
                  taxPercent: 0,
                  discountAmount: { unit: "CAD", value: 0 },
                  discountPercent: 0,
                },
              };
            }

            //   item total
            finalObject.oneTime.itemTotal = {
              value:
                parseFloat(finalObject.oneTime.itemTotal.value) +
                parseFloat(currentPrice.price.dutyFreeAmount.value),
              unit: currentPrice.price.dutyFreeAmount.unit,
            };
            finalObject.oneTime.itemTotal.value = this.formatter.format(
              finalObject.oneTime.itemTotal.value
            );

            //  Tax amount
            let itemLevelTaxAmount =
              parseFloat(currentPrice.price.taxIncludedAmount.value) -
              parseFloat(currentPrice.price.dutyFreeAmount.value);
            finalObject.oneTime.taxAmount = {
              value:
                parseFloat(finalObject.oneTime.taxAmount.value) +
                itemLevelTaxAmount,
              unit: currentPrice.price.taxIncludedAmount.unit,
            };
            finalObject.oneTime.taxAmount.value = this.formatter.format(
              finalObject.oneTime.taxAmount.value
            );

            //   Discounted amount and percent

            if (
              currentPrice.priceAlteration &&
              currentPrice.priceAlteration.length > 0
            ) {
              if (
                currentPrice.priceAlteration[0]?.price &&
                currentPrice.priceAlteration[0]?.price.percentage
              ) {
                // dicount percent
                let itemLevelDiscountAmount =
                  currentPrice.price.dutyFreeAmount.value *
                  (currentPrice.priceAlteration[0].price.percentage / 100);

                // console.log("discount:"
                // +itemLevelDiscountAmount,
                // " dutyFree:"+currentPrice.price.dutyFreeAmount.value+" discount value:"+currentPrice.price.dutyFreeAmount.value*(currentPrice.priceAlteration[0].price.percentage/100)+"  percent:"+currentPrice.priceAlteration[0].price.percentage)

                finalObject.oneTime.discountAmount.value =
                  parseFloat(finalObject.oneTime.discountAmount.value) +
                  itemLevelDiscountAmount;
                finalObject.oneTime.discountAmount.value =
                  this.formatter.format(
                    finalObject.oneTime.discountAmount.value
                  );
                finalObject.oneTime.discountAmount.unit =
                  currentPrice.price.dutyFreeAmount.unit;
                // Discount percent
                finalObject.oneTime.discountPercent =
                  finalObject.oneTime.discountPercent +
                  currentPrice.priceAlteration[0].price.percentage;
              }
            }

            return finalObject;
          }
          return finalObject;
        },
        {}
      );
      return calculatedPrice ? calculatedPrice : {};
    },
  },
  watch: {
    "$route.query.routeFrom": {
      async handler(val) {
        console.log("🚀 ~ handler ~ val:", val);
        if (!val || this.quoteData?.state?.toLowerCase() == "inprogress")
          return;
        let intervalID = null;
        if (!this.intervalID) {
          intervalID = setInterval(async () => {
            // this.$router.go()
            let client = this.quoteData?.authorization?.map((auth) => {
              if (auth?.name == "Quote client confirmation") {
                return auth;
              }
            });
            let pendingClients = client?.some(
              (ele) => ele?.state?.toLowerCase() === "pending"
            );
            console.log(pendingClients, "clientclientclientclient", client);
            if (
              this.quoteData?.state?.toLowerCase() == "approved" &&
              pendingClients
              // client &&
              // client?.state?.toLowerCase() == "pending"
            ) {
              await this.refreshQuoteData(this.$route.query.quote_id, true);
              return;
            }
          }, 3000);
          this.intervalID = intervalID;
        }
        if (intervalID && this.quoteData?.state?.toLowerCase() == "accepted") {
          console.log("🚀 cleared", intervalID);
          clearInterval(intervalID);
          this.intervalID = null;
          return;
        }
      },
      immediate: true,
    },
    tableDataArray: {
      handler(val) {
        console.log(val);
      },
      immediate: true,
      deep: true,
    },
    tableData: {
      handler(val) {
        let arr = [];
        arr.push(JSON.parse(JSON.stringify(val.slice(4, val.length - 1))));
        this.indexLength += 1;
        this.duplicateArray = arr;
        this.firstPagetableArray = val.slice(0, 3);
        this.quoteArray = arr;
        // console.log("duplicate", this.data2);
      },
      immediate: true,
      deep: true,
    },
    duplicateArray: {
      handler(val) {
        val;
        // this.$nextTick(()=>{
        //   val.forEach((item,index)=>{
        //     if(document.querySelector('#data-container'+index))

        //    console.log(document.querySelector('#data-container'+index).getBoundingClientRect());
        //   })
        // })
      },
      immediate: true,
      deep: true,
    },
    quoteArray: {
      handler(val) {
        let height = 0;
        let container = [];
        let containers = [];
        this.$nextTick(() => {
          val;
          this.duplicateArray.forEach((item, index) => {
            console.log(item, "array");
            item.forEach((value, col_index) => {
              let containerheight = 800;
              if (document.getElementById("value" + index + col_index))
                height =
                  height +
                  document
                    .getElementById("value" + index + col_index)
                    .getBoundingClientRect().height;
              console.log(height);
              if (height < containerheight) {
                container.push(value);
                console.log(container, "value");
              } else {
                containers.push(container);
                this.data2 = containers;
                container = [];
                height = 0;
                container.push(value);
                this.indexLength += 1;
              }
            });
          });
        });

        this.componentkey += 1;
      },
    },
    additionalFields: {
      handler(val) {
        if (
          this.indexlength == 0 &&
          this.duplicateAdditionalFields.length != 0
        ) {
          var height = 0;
          var container = [];
          var containers = [];
          this.$nextTick(() => {
            val;
            console.log(
              this.duplicateAdditionalFields,
              "duplicateAdditionalFields",
              this.duplicateAdditionalFields.length
            );
            this.duplicateAdditionalFields.forEach((item, index) => {
              item.forEach((value, col_index) => {
                var containerheight = 800;
                console.log(value, "hello", col_index);

                if (
                  value["@baseType"] == "Agreement" &&
                  document.getElementById(
                    "additional-fields" + index + col_index
                  )
                ) {
                  height =
                    document
                      .getElementById("additional-fields" + index + col_index)
                      .getBoundingClientRect().height + height;
                  if (height < containerheight) {
                    console.log(height, "parent", containerheight, container);
                    var subvalueArr = [];
                    value["terms"].forEach((subvalue, z_index) => {
                      subvalue;
                      height =
                        document
                          .getElementById(
                            "additional-fields" + index + col_index + z_index
                          )
                          .getBoundingClientRect().height + height;
                      console.log(
                        value.name,
                        "containerrrrr",
                        subvalueArr,
                        height
                      );
                      if (height < containerheight) {
                        subvalueArr.push(subvalue);
                        if (value["terms"].length - 1 == z_index) {
                          let termsandconditions = JSON.parse(
                            JSON.stringify(value)
                          );
                          termsandconditions["terms"] = subvalueArr;
                          if (subvalueArr.length - 1 != z_index) {
                            termsandconditions.name = "";
                          }
                          container.push(termsandconditions);
                        }
                      } else {
                        let termsandconditions = JSON.parse(
                          JSON.stringify(value)
                        );
                        termsandconditions["terms"] = subvalueArr;
                        container.push(termsandconditions);
                        containers.push(container);
                        container = [];
                        height = 0;
                        subvalueArr = [];
                        subvalueArr.push(subvalue);
                        // let duplicateValue = JSON.parse(JSON.stringify(value))
                        // duplicateValue.name = ''
                        // duplicateValue['terms'] = []
                        console.log("val", containers);
                        // container.push(duplicateValue)
                      }
                    });
                  } else {
                    containers.push(container);
                    // this.additionalFields = containers
                    console.log("childddd", this.additionalFields);
                    container = [];
                    height = 0;
                    // let remainingarr = this.duplicateAdditionalFields[index].slice(col_index,item.length - 1)
                    container.push(value);
                    console.log(
                      container,
                      "childddd",
                      col_index,
                      item.length - 1
                    );
                  }
                } else if (value["@type"] == "Note") {
                  // Sample HTML content with inline styles
                  var htmlContent = value.text;

                  // Create a temporary element to hold the HTML content
                  var tempElement = document.createElement("div");
                  tempElement.innerHTML = htmlContent;

                  // Get all elements with inline styles
                  var elementsWithStyles =
                    tempElement.querySelectorAll("[style]");

                  // Loop through each element and remove the font-family property from inline styles
                  elementsWithStyles.forEach(function (element) {
                    var inlineStyles = element.getAttribute("style");
                    var updatedStyles = inlineStyles.replace(
                      /font-family\s*:\s*[^;]+;?/gi,
                      ""
                    );
                    updatedStyles = updatedStyles.replace(
                      /font-size\s*:\s*[^;]+;?/gi,
                      "font-size: 13px;"
                    );
                    element.setAttribute("style", updatedStyles);
                  });

                  // Get the updated HTML content
                  var updatedContent = tempElement.innerHTML;

                  let textValue = JSON.parse(JSON.stringify(value));
                  textValue["text"] = updatedContent;
                  console.log(height, "height", updatedContent.length);
                  // let noteLine = updatedContent.length/103
                  // let noteL

                  container.push(textValue);
                  console.log("ak", containers);
                }

                // this.additionalFields = containers

                // console.log(this.additionalFields,'lolo',containers);
                // if (height < containerheight) {
                //   container.push(value);
                //   console.log(container, "value");
                // } else {
                //   containers.push(container);
                //   this.additionalFields = containers;
                //   container = [];
                //   height = 0;
                //   container.push(value);
                //   this.indexLength += 1;
                // }
              });
            });
            if (height < 800) {
              containers.push(container);
            }
            this.additionalFields = containers;
            console.log(this.additionalFields, "additionalFields");
            this.indexlength = 1;
          });
        }
      },
      immediate: true,
      deep: true,
    },
    duplicateAdditionalFields: {
      handler(val) {
        val;
      },
      immediate: true,
      deep: true,
    },
  },

  async mounted() {
    console.log(this.$route.query.approver_id, "powow");
    if (this.$route.query.approver_id) {
      this.startButton = true;
    }
    const versioning = this.$route.query.versioning;
    versioning
      ? this.$store.dispatch("triggerVersionHistory", true)
      : this.$store.dispatch("triggerVersionHistory", false);
    let childElements = document.querySelectorAll(
      ".quote-preview-page-each-container"
    );

    childElements.forEach((element) => {
      element.style.backgroundColor = "white";
    });
    let id = this.$route.query.quote_id;
    if (
      this.$route.query.access &&
      this.$route.query.request_for == "client_approval"
    ) {
      let access = this.$route.query.access;
      console.log("🚀 ~ mounted ~ access:", access);
      const isValidToken = await UPCAxiosInstance.get(
        `/tokenblocker/isValidToken?token=${access}`,
        {
          headers: {
            Authorization: "Bearer " + access,
          },
        }
      );
      console.log("🚀 ~ mounted ~ isValidToken:", isValidToken);
      console.log(
        "🚀 ~ mounted ~ isValidToken.data:",
        isValidToken.data == false
      );
      if (isValidToken.data != undefined && isValidToken.data == false) {
        this.quoteAccessDenied = true;
        console.log(
          "🚀 ~ mounted ~ this.quoteAccessDenied:",
          this.quoteAccessDenied
        );
        return;
      }
      // this.$cookies.set("token", access);
      // this.$cookies.get("token");
      // console.log(
      //   "🚀 ~ mounted ~ this.$cookies.ge):",
      //   this.$cookies.get("token")
      // );
      // console.log
      // let quoteID = this.$route.query.quote
      let client = this.$route.query.client;
      // this.isNeedClientSignature = true;
      // this.isNeedApproval = false;
      // this.isNeedToSentToCustomer = false;
      console.log("🚀 ~ client:", client);
      let data = null;
      try {
        data = await UPCAxiosInstance.get(`/quotemanagement/${id}`, {
          headers: {
            Authorization: "Bearer " + access,
          },
        });

        // data = await axios.get(`http://localhost:5700/quotemanagement/${id}`, {
        //   headers: {
        //     Authorization: "Bearer " + access,
        //   },
        // });
      } catch (error) {
        if (error.response.status == 403) {
          this.quoteAccessDenied = true;
          return;
        }
        console.log("🚀 ~ mounted ~ error:", error);
      }
      console.log("🚀 ~ mounted ~ data:", data);
      this.quoteData = data?.data;
      console.log(this.quoteData, "quoteData");
      this.quoteApproved =
        this.quoteData.state.toLowerCase === "approved" ? true : false;

      //  console.log("🚀 ~ data:", data)
      // return
    }

    if (
      this.$route.query &&
      this.$route.query.request_for == "manager_approval"
    ) {
      // let quoteID = this.$route.query.quote
      // let client = this.$route.query.client;
      this.isNeedClientSignature = false;
      this.isNeedApproval = true;
      this.isNeedToSentToCustomer = false;
      // console.log("🚀 ~ client:", client);
      // let access = this.$route.query.access;
      // this.$cookies.set("token", access);
      //  const data = await UPCAxiosInstance.get(`/quotemanagement/${quoteID}`)

      //  console.log("🚀 ~ data:", data)
      // return
    }
    await this.refreshQuoteData(id);
    await this.getQuoteVersionList();
    // this.quoteData.authorization.forEach((item) => {
    //   if (item.name == "Quote manager approval") {
    //     this.managerApproval = item;
    //     console.log(
    //       "🚀 ~ this.quoteData.authorization.forEach ~ this.managerApproval:",
    //       this.managerApproval
    //     );
    //   } else if (item.name == "Quote client confirmation") {
    //     // console.log(item, "client");
    //     this.clientApproval = item;
    //   }
    // });

    // console.log(
    //   this.additionalFields,
    //   "additionalFields",
    //   this.additionalFields.length
    // );
    // agreementandnotesarr
    // console.log(agreementandnotesarr,'notes');
  },
  beforeUnmount() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
      return;
    }
  },
  methods: {
    async handleWorkflowUserTask(module, triggerPoint) {
      try {
        if (
          !module ||
          !triggerPoint ||
          !this.quoteData ||
          (this.quoteData &&
            this.quoteData.externalId &&
            Array.isArray(this.quoteData.externalId) &&
            this.quoteData.externalId.length == 0)
        )
          return;
        let initiateWorkflows = this.quoteData.externalId.filter(
          (item) =>
            item["@type"] == "workflow" && item["owner"] == "HalleyxBPMNEngine"
        );
        console.log(
          "🚀 ~ handleWorkflowUserTask ~ initiateWorkflows:",
          initiateWorkflows
        );
        if (initiateWorkflows.length == 0) return;
        //Get processInstance related to cuurentTask triggerPoint module and point
        const processInstanceReqPayload = {
          type: "filter",
          module: "processinstance",
          collection: "",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {},
          limit: 10,
          filterQuery: {
            instanceId: {
              $in: initiateWorkflows.map((item) => item.instanceId),
            },
            "pendingTasks.triggerPoint.from": "HalleyxBSS",
            "pendingTasks.triggerPoint.module": module,
            "pendingTasks.triggerPoint.actionPoint": triggerPoint,
          },
          searchQuery: "",
        };
        const currentInstances = await BPMNAxiosInstance.post(
          `/utils/filterData`,
          processInstanceReqPayload
        );
        console.log(
          "🚀 ~ handleWorkflowUserTask ~ currentInstances:",
          currentInstances
        );
        if (
          !currentInstances ||
          !currentInstances.data ||
          (currentInstances &&
            currentInstances.data &&
            currentInstances.data.data &&
            currentInstances.data.data.length == 0)
        )
          return;
        currentInstances.data.data.map(async (instance) => {
          let pendingUserTasks = instance?.pendingTasks?.filter((task) => {
            console.log("🚀 ~ pendingUserTasks ~ task:", task);
            let isTriggerPointMatched = task.triggerPoint.some(
              (trigger) =>
                trigger.from == "HalleyxBSS" &&
                trigger.module == module &&
                trigger.actionPoint == triggerPoint
            );
            if (isTriggerPointMatched) {
              return true;
            }
          });
          console.log(
            "🚀 ~ pendingUserTasks ~ pendingUserTasks:",
            pendingUserTasks
          );
          let currentTask = pendingUserTasks[0];
          if (!currentTask) return;
          // Complete user tasks
          await BPMNAxiosInstance.post(`/engine/completeTask`, {
            taskId: currentTask.task,
            instanceId: instance.instanceId,
            workflowId: instance.workflowId,
            context: { quoteData: this.quoteData },
          });
        });
        // Complete user tasks
        // await BPMNAxiosInstance.post(`/engine/complete-task`,{
        //   instanceId:currentInstances.data.data[0].instanceId,
        //   taskId:currentInstances.data.data[0].currentTask.id,
        //   userId:this.$cookies.get("user"),
        //   context:currentInstances.data.data[0].context
        // })
      } catch (error) {
        throw new Error(error);
      }
    },
    async handleQuoteApproval(state) {
      try {
        if (!state) throw new Error("approval state missing");
        if (state?.toLowerCase() === "approved") {
          this.quoteData.authorization = this.quoteData.authorization.map(
            (auth) => {
              if (
                auth["@type"] == "approverAuthorization" &&
                auth.approver &&
                auth.approver[0] &&
                auth.approver[0].id == this.$route.query.approverId
              ) {
                auth.state = "approved";
              }
              return auth;
            }
          );
        } else if (state?.toLowerCase() === "declined") {
          this.quoteData.authorization = this.quoteData.authorization.map(
            (auth) => {
              if (
                auth["@type"] == "approverAuthorization" &&
                auth.approver &&
                auth.approver[0] &&
                auth.approver[0].id == this.$route.query.approverId
              ) {
                auth.state = "declined";
              }
              return auth;
            }
          );
          this.quoteData.state = "cancelled";
        }
        await this.handleWorkflowUserTask(
          "QUOTEPREVIEW",
          "approveOrRejectButton"
        );
      } catch (error) {
        console.log("🚀 ~ handleQuoteApproval ~ error:", error);
      }
    },
    getRecipientList() {
      console.log("this.quoteData123", this.quoteData);

      let filterRecipient = this.quoteData?.relatedParty?.filter(
        (client) => client["@type"] === "recipient"
      );
      if (filterRecipient) {
        this.initialRecipientsListForChecking = filterRecipient;
        this.initialRecipientsList = filterRecipient;
      } else {
        this.initialRecipientsListForChecking = [];
        this.initialRecipientsList = [];
      }
      // this.propsForMultiSignComponent = this.recipientsList;
      this.recipientsList = JSON.parse(
        JSON.stringify(this.initialRecipientsList)
      );
      this.recipientsListOrg = JSON.parse(
        JSON.stringify(this.initialRecipientsList)
      );
      console.log(
        this.initialRecipientsList,
        "getRecipientList",
        this.recipientsList
      );
    },
    detailsFunc(value) {
      let obj = {};
      obj["contactInfo"] = value.email;
      obj["givenDate"] = this.quoteData?.validFor?.startDateTime;
      obj["requestedDate"] = this.quoteData?.validFor?.endDateTime;
      return obj;
    },
    focusSignature() {
      if (this.multipleSignatureCount != 0);
      this.startButton = false;
      //  console.log(this.clientName+this.signCount == this.clientName+'0' ,'testtt',document.getElementById(this.clientName+this.signCount+'sign').style.display)
      document.getElementById(
        this.clientName + this.signCount + "next" + "sign"
      ).style.display = "flex";
      if (this.multipleSignatureCount == 1) {
        document.getElementById(
          this.clientName + this.signCount + "next" + "sign"
        ).innerText = "Finish";
      } else {
        // document.getElementById(this.clientName+this.signCount+'prev'+'sign').style.display ='flex'
      }
      document
        .getElementById(this.clientName + "0")
        .scrollIntoView({ behavior: "smooth", block: "center" });
      document
        .getElementById(this.clientName + "0")
        .classList.add("focusColor");
      // const elementRect = document.getElementById(this.clientName+'0').getBoundingClientRect();

      // Adjust the window scroll position to add a 10px margin from the top
      // window.scrollBy({
      //   top: elementRect.top + 200,  // Subtract 10px from the scroll position
      //   behavior: 'smooth'          // Smooth scrolling
      // });

      // Set focus on the element
      // document.getElementById(this.clientName+'0').focus();
      // this.signCount += 1
    },
    focusSign(focusId, id) {
      // this.clientName+this.signCount
      // if(document.getElementById(id+'sign').innerText == 'Sign'){
      //   document.getElementById(id+'sign').style.left = 230+'px'
      //   if(this.signCount == this.multipleSignatureCount){
      //     document.getElementById(id+'sign').innerText  = 'Finish'
      //   }else{
      //     document.getElementById(id+'sign').innerText  = 'Next'
      //   }
      //   document.getElementById(id).classList.add('focusColor')
      // }else
      //   if(document.getElementById(id+'next'+'sign'))
      // {
      //       this.signCount += 1
      //      if(document.getElementById(this.clientName+this.signCount+'next'+'sign'))
      //      {
      //       document.getElementById(this.clientName+this.signCount+'next'+'sign').style.display = 'flex !important'
      //       document.getElementById(this.clientName+this.signCount).classList.add('focusColor')

      //      }
      // }
      id;
      console.log(id, focusId, "iddddd", document.getElementById(id).innerText);
      if (document.getElementById(id).innerText == "Next") {
        document.getElementById(focusId).classList.remove("focusColor");
        document.getElementById(
          this.clientName + this.signCount + "next" + "sign"
        ).style.display = "none";
        document.getElementById(
          this.clientName + this.signCount + "prev" + "sign"
        ).style.display = "none";
        this.signCount += 1;
        console.log(
          document.getElementById(
            this.clientName + this.signCount + "next" + "sign"
          ),
          "idddd",
          this.signCount,
          this.multipleSignatureCount
        );
        if (this.multipleSignatureCount - 1 == this.signCount) {
          if (
            document.getElementById(
              this.clientName + this.signCount + "next" + "sign"
            )
          ) {
            document.getElementById(
              this.clientName + this.signCount + "next" + "sign"
            ).style.display = "flex";
            document.getElementById(
              this.clientName + this.signCount + "next" + "sign"
            ).innerText = "Finish";
            document.getElementById(
              this.clientName + this.signCount + "prev" + "sign"
            ).style.display = "flex";
            document
              .getElementById(this.clientName + this.signCount)
              .scrollIntoView({ behavior: "smooth", block: "center" });
            console.log(
              document.getElementById(
                this.clientName + this.signCount + "next" + "sign"
              ).style.display,
              "idddd",
              document.getElementById(
                this.clientName + this.signCount + "next" + "sign"
              )
            );
            document
              .getElementById(this.clientName + this.signCount)
              .classList.add("focusColor");
          }
        } else {
          if (
            document.getElementById(
              this.clientName + this.signCount + "next" + "sign"
            )
          ) {
            document.getElementById(
              this.clientName + this.signCount + "next" + "sign"
            ).style.display = "flex";
            document.getElementById(
              this.clientName + this.signCount + "prev" + "sign"
            ).style.display = "flex";
            document
              .getElementById(this.clientName + this.signCount)
              .scrollIntoView({ behavior: "smooth", block: "center" });
            console.log(
              document.getElementById(
                this.clientName + this.signCount + "next" + "sign"
              ).style.display,
              "idddd",
              document.getElementById(
                this.clientName + this.signCount + "next" + "sign"
              )
            );
            document
              .getElementById(this.clientName + this.signCount)
              .classList.add("focusColor");
          }
        }
      } else if (document.getElementById(id).innerText == "Previous") {
        document.getElementById(focusId).classList.remove("focusColor");
        document.getElementById(
          this.clientName + this.signCount + "next" + "sign"
        ).style.display = "none";
        document.getElementById(
          this.clientName + this.signCount + "prev" + "sign"
        ).style.display = "none";
        this.signCount -= 1;
        console.log(
          document.getElementById(
            this.clientName + this.signCount + "next" + "sign"
          ),
          "idddd"
        );
        if (this.signCount == 0) {
          if (
            document.getElementById(
              this.clientName + this.signCount + "next" + "sign"
            )
          ) {
            document.getElementById(
              this.clientName + this.signCount + "next" + "sign"
            ).style.display = "flex";
            // document.getElementById(this.clientName+this.signCount+'prev'+'sign').style.display = 'flex'
            document
              .getElementById(this.clientName + this.signCount)
              .scrollIntoView({ behavior: "smooth", block: "center" });
            console.log(
              document.getElementById(
                this.clientName + this.signCount + "next" + "sign"
              ).style.display,
              "idddd",
              document.getElementById(
                this.clientName + this.signCount + "next" + "sign"
              )
            );
            document
              .getElementById(this.clientName + this.signCount)
              .classList.add("focusColor");
          }
        } else {
          if (
            document.getElementById(
              this.clientName + this.signCount + "next" + "sign"
            )
          ) {
            document.getElementById(
              this.clientName + this.signCount + "next" + "sign"
            ).style.display = "flex";
            document.getElementById(
              this.clientName + this.signCount + "prev" + "sign"
            ).style.display = "flex";
            document
              .getElementById(this.clientName + this.signCount)
              .scrollIntoView({ behavior: "smooth", block: "center" });
            console.log(
              document.getElementById(
                this.clientName + this.signCount + "next" + "sign"
              ).style.display,
              "idddd",
              document.getElementById(
                this.clientName + this.signCount + "next" + "sign"
              )
            );
            document
              .getElementById(this.clientName + this.signCount)
              .classList.add("focusColor");
          }
        }
      }
    },
    prevNextFocus(value, area, $event) {
      // this.$nextTick(()=>{
      let val = "";
      let val1 = "";
      if (area == "nofields" && $event == "next") {
        val = "termsandconditions";
        val1 = "next";
      } else if (area == "termsandconditions" && $event == "next") {
        val = "pageend";
        val1 = "prev";
      } else if (area == "termsandconditions" && $event == "prev") {
        val = "nofields";
        val1 = "next";
      } else if (area == "pageend" && $event == "prev") {
        val = "termsandconditions";
        val1 = "next";
      }

      if (document.getElementById(val1 + value.name + val)) {
        if (this.prevValue != "") {
          document.getElementById(this.prevValue).style.border = "none";
        }
        document.getElementById(val1 + value.name + val).style.border =
          "1px solid black";
        document
          .getElementById(val1 + value.name + val)
          .scrollIntoView({ behavior: "smooth" });
        this.prevValue = val1 + value.name + val;
      }
      // })
    },
    preValSignaturePlaced(value, type) {
      let sign = {};
      if (value.sign == true) {
        this.quoteData?.authorization?.find((auth) => {
          if (auth.name == "Quote client confirmation") {
            // auth.map((ele)=>{
            console.log(auth, "ele", auth[type]);
            if (auth[type]) {
              auth[type].map((ele) => {
                if (ele.id === value.id) {
                  console.log("signnn", ele, value, auth);
                  sign.signatureRepresentation = auth.signatureRepresentation;
                  sign.signType = auth.signType;
                  if (auth.fontName) {
                    sign.fontName = auth.fontName;
                  }
                }
              });
            }
            // })
          }
        });
      }
      console.log(sign, "signnn");
      return sign;
    },
    alreadySign(value, $event, type) {
      console.log($event, "alreadySign", value, type);
      if (
        value.signatureRepresentation &&
        value.signatureRepresentation != ""
      ) {
        value.sign = true;
      }
      console.log($event, "testtt", value);
      this.quoteData.authorization = this.quoteData.authorization.map(
        (client) => {
          if (
            client.name === "Quote client confirmation" &&
            client?.approver[0]?.id === $event.id &&
            client.signatureRepresentation != null &&
            client.signatureRepresentation != undefined
          ) {
            client[type] = client[type].map((ele) => {
              if (ele.id === $event.id) {
                ele = { ...value };
              }
              return ele;
            });
          }
          return client;
        }
      );

      //    if(value.signatureRepresentation && value.signatureRepresentation != ''){
      //    let str = value.focusId
      //    let lastChar = str.charAt(str.length - 1);
      //    let num = parseInt(lastChar, 10);
      //    num = num + 1

      //   console.log(document.getElementById(value.name+ num),'testtttttt',value,str,lastChar,num)
      //   if(document.getElementById(value.name+ num))
      //   {
      //     document.getElementById(value.name+ num).scrollIntoView({ behavior: "smooth" })
      //   }
      // }

      console.log("quoteData", this.quoteData.authorization);
    },
    taxValue(val) {
      console.log(val, "quoteData12");
      if (val && val.length != 0) {
        return val.join(", ");
      } else {
        return "";
      }
    },
    showSignaturePad(i) {
      let approverId = this.$route.query.approver_id || 0;
      console.log("approverId", approverId);
      console.log(
        this.quoteData.authorization,
        "showSignaturePad",
        i,
        "approverId",
        approverId,
        this.quoteData.authorization[1]?.signatureRepresentation
      );

      if (i?.id !== approverId) return true;
      return false;
    },
    getWord(val) {
      this.signatureData = val;
      console.log(val, "test");
    },
    getType(val) {
      this.signtype = val;
      console.log(val, "test");
    },
    getSignature(val) {
      console.log(val, "signature");
      this.signatureData = val;
      this.showSignatureModal = false;
    },
    async restoreQuote() {
      // Restore quote func
      let restoredQuote = await UPCAxiosInstance.post(`quotemanagement`, {
        restoringId: this.restore_data_id,
        isVersioning: true,
        isRestoring: true,
        displayID: this.quoteData.displayID,
      });
      // console.log(restoredQuote.data,"this.restore_data_id",this.restore_data_id)
      this.reassignedQuoteId = restoredQuote?.data?._id;
      // let updateRestoredQouteStatus= await UPCAxiosInstance.patch(`/quotemanagement`, {
      //       ...restoredQuote.data,
      //       id: this.restore_data_id,
      //     });
      console.log(restoredQuote, "restoredQuote", this.reassignedQuoteId);
      this.restore_modal_quote = false;
      // this.displayId=restoredQuote?.data?.data?.displayID
      this.getQuoteVersionList();
    },
    async getQuoteVersionList() {
      // To get version history list for the quote using displayID
      this.displayId = this.quoteData.displayID;
      let quoteVersion = await UPCAxiosInstance.post(`util/filterData`, {
        type: "filter",
        module: "quotes",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 100,
          currentPage: 1,
        },
        additionalPipelines: [],
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          version: -1,
          lastUpdate: -1,
        },
        limit: 10,
        filterQuery: {
          displayID: this.displayId,
        },
        searchQuery: "1",
      });
      this.quoteVersionList = quoteVersion.data.data;
      console.log(
        this.quoteData.displayID,
        "this.quoteVersion",
        quoteVersion,
        "quoteVersion.data.data",
        this.quoteVersionList
      );
    },
    reassignQuoteData(data) {
      console.log(data, "reassignQuoteData");
      this.changeQuoteVersionData = data;
      this.refreshQuoteData(data.id, false, true);
    },
    restoreQuoteData(data, index, timeStamp) {
      this.restore_data_id = data;
      this.restored_time_stamp = timeStamp;
      console.log("restoredQuote", index, timeStamp, data);
      this.restore_modal_quote = true;
    },
    autoSignature(val, val1) {
      let value = {};
      this.multipleSignatureLocation.filter((item) => {
        if (item.signerName == val.signerName) {
          value = item;
        }
      });
      console.log(value, val1, "pediiduo");
      this.signaturePlacement(JSON.parse(JSON.stringify(value)), val1);
    },
    signaturePlacement(val, val1) {
      this.$nextTick(() => {
        let obj = val;
        const sourceContainer = document.getElementById(
          "preview-page-container"
        );
        const sourceImage = document.getElementById(
          "already-signature-pad" + val1
        );

        // Get the target container
        // const targetContainer = document.getElementById("quote-preview-page-each-container2");

        // Get the position of the image relative to the viewport
        const imgRect = sourceImage.getBoundingClientRect();

        // Get the position of the source container relative to the viewport
        const sourceContainerRect = sourceContainer.getBoundingClientRect();

        // Calculate the relative position of the image inside the source container
        let relativeX = imgRect.left - sourceContainerRect.left - 50;
        let relativeY = imgRect.top - sourceContainerRect.top;
        let elem = document.createElement("img");
        elem.style.position = "absolute";
        elem.style.height = "70px";
        elem.style.width = "200px";
        elem.src = val;
        // relativeY = relativeY
        elem.style.left = relativeX + "px";
        elem.style.top = relativeY + "px";
        // sourceImage.style.display='none'
        // document.querySelector('#quote-preview-page-each-container2').appendChild(elem)
        obj.x = relativeX;
        obj.y = relativeY;
        this.multipleSignatureLocation.push(obj);
      });
    },
    multipleSignature(index, type, value, $event) {
      // document.getElementById(this.signIdValue+'0'+'sign').style.left= 250 + 'px'
      this.signaturePlacedArea = type;
      value.signatureRepresentation = $event.signatureRepresentation;
      console.log(
        $event,
        "signaturValue",
        index,
        type,
        value,
        this.signaturePlaced
      );

      this.quoteData.authorization = this.quoteData?.authorization?.map(
        (auth) => {
          if (auth.name === "Quote client confirmation") {
            console.log(auth, "ele", auth[this.signaturePlacedArea]);

            if (auth[this.signaturePlacedArea]) {
              auth[this.signaturePlacedArea] = auth[
                this.signaturePlacedArea
              ].map((ele) => {
                if (ele.id === $event.id) {
                  console.log("ele", ele, index, type, value, $event);
                  ele.sign = true;
                  auth.state = "approved";
                  auth.signatureRepresentation = $event.signatureRepresentation;
                  auth.signType = $event.signType;
                  if ($event.fontName) {
                    auth.fontName = $event.fontName;
                  }
                  value.sign = true;
                }
                return ele;
              });
            }
          }
          return auth;
        }
      );

      Object.keys(this.signaturePlaced).forEach((item) => {
        if (item != "assignedColors") {
          this.signaturePlaced[item].forEach((ele) => {
            if (ele.id == $event.id) {
              console.log(ele, "multipleSignature");
              if (ele.signatureRepresentation) {
                ele.signatureRepresentation = $event.signatureRepresentation;
              } else {
                ele.signatureRepresentation = $event.signatureRepresentation;
              }
            }
          });
        }
      });
      // if(value.signatureRepresentation && value.signatureRepresentation != ''){
      //      let str = value.focusId
      //      let lastChar = str.charAt(str.length - 1);
      //      let num = parseInt(lastChar, 10);
      //      num = num + 1

      //     //  if(this.multipleSignatureCount )
      //     console.log(document.getElementById(value.name+ num),'testtttttt',value,str,lastChar,num)
      //     if(document.getElementById(value.name+ num))
      //     {
      //       document.getElementById(value.name+ num).style.border = '1px solid black'
      //       document.getElementById(value.name+ num).scrollIntoView({ behavior: "smooth" })
      //     }
      //   }
      // console.log("multipleSignature",this.quoteData.authorization,this.signaturePlaced)
      // this.signaturePlaced[type] [index]
      // multisign emit for quote approver payload
      // console.log(type,"multipleSignature",index)

      // E-signature for quote PDF
      // console.log(val,val1,'lololo')
      //       this.$nextTick(()=>{
      //         let obj = val
      //         const sourceContainer = document.getElementById("preview-page-container");
      // const sourceImage = document.getElementById("already-signature-pad"+val1);

      // // Get the target container
      // // const targetContainer = document.getElementById("quote-preview-page-each-container2");

      // // Get the position of the image relative to the viewport
      // const imgRect = sourceImage.getBoundingClientRect();

      // // Get the position of the source container relative to the viewport
      // const sourceContainerRect = sourceContainer.getBoundingClientRect();

      // // Calculate the relative position of the image inside the source container
      // let relativeX = (imgRect.left - sourceContainerRect.left)-50;
      // let relativeY = imgRect.top - sourceContainerRect.top;
      // let elem = document.createElement('img')
      //         elem.style.position = 'absolute'
      //         elem.style.height = '70px'
      //         elem.style.width = '200px'
      //         elem.src = val
      //         // relativeY = relativeY
      // elem.style.left = relativeX + 'px';
      // elem.style.top = relativeY + 'px';
      //         // sourceImage.style.display='none'
      //         // document.querySelector('#quote-preview-page-each-container2').appendChild(elem)
      //         obj.x = relativeX
      //         obj.y = relativeY
      //         this.multipleSignatureLocation.push(obj)
      //       })
    },
    multiSignature(val) {
      this.$nextTick(() => {
        const sourceContainer = document.getElementById(
          "quote-preview-page-each-container2"
        );
        const sourceImage = document.querySelector(".already-signature-pad");

        // Get the target container
        // const targetContainer = document.getElementById("quote-preview-page-each-container2");

        // Get the position of the image relative to the viewport
        const imgRect = sourceImage.getBoundingClientRect();

        // Get the position of the source container relative to the viewport
        const sourceContainerRect = sourceContainer.getBoundingClientRect();

        // Calculate the relative position of the image inside the source container
        const relativeX = imgRect.left - sourceContainerRect.left + 60;
        const relativeY = imgRect.top - sourceContainerRect.top;
        let elem = document.createElement("img");
        elem.style.position = "absolute";
        elem.style.height = "70px";
        elem.style.width = "200px";
        elem.src = val;
        elem.style.left = relativeX + "px";
        elem.style.top = relativeY + "px";
        // sourceImage.style.display='none'
        // document.querySelector('#quote-preview-page-each-container2').appendChild(elem)
      });
    },
    downloadWithAuth(filename) {
      const url = `https://tmf-upc-ghubgwxrcq-pd.a.run.app/gcpupload/files/${filename}`;

      fetch(url, {
        method: "GET",
        headers: {
          "hlx-api-key":
            "4651efd8d5060bfb670cf710e76ce562:de9717fa1b4b6a11058a74a1678e9fcc078f1e3137057ae5ce229f4cea2035ac5c577c2caea4edfc4fdfd3980be485646e608b81d812fdbed8d60dc60e92da73",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.blob(); // Assuming the response is a PDF file
          } else {
            throw new Error("Network response was not ok");
          }
        })
        .then((blob) => {
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL); // Open the PDF in a new tab
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    },
    async downloadAttachment(value) {
      try {
        console.log(value, "attachment.name");

        // Fetch the URL from your API
        const urlFetchResponse = await UPCAxiosInstance.get(
          `/gcpupload/files/${value}`,
          {
            responseType: "blob", // Ensure the response is a blob
          }
        );
        const fileData = urlFetchResponse.data; // The file data as a blob
        console.log(fileData, "fileData");

        // Create a URL for the Blob
        const url = URL.createObjectURL(fileData);

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = value;
        console.log(link, "link");

        document.body.appendChild(link);
        link.click();

        // Clean up
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading attachment:", error);
      }
    },
    async redirectAttachment(filename) {
      const url = `https://upc-halleyx-ghubgwxrcq-uc.a.run.app/gcpupload/files/${filename}?api_key=${"hlx-api-key 4651efd8d5060bfb670cf710e76ce562:de9717fa1b4b6a11058a74a1678e9fcc078f1e3137057ae5ce229f4cea2035ac5c577c2caea4edfc4fdfd3980be485646e608b81d812fdbed8d60dc60e92da73"}`;
      window.open(url, "_blank");
    },
    async downloadFromGCP(urlFetch, name) {
      const response = await fetch(urlFetch);
      const fileData = await response.blob(); // Assuming the file is in binary format

      const blob = new Blob([fileData]);

      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = name;

      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    async refreshQuoteData(id, forceRefresh = false, isVersion = false) {
      // console.log("🚀 ~ mounted ~ id:", id);
      if (isVersion && !forceRefresh) {
        console.log(
          this.quoteData,
          "changeQuoteVersionData",
          this.changeQuoteVersionData
        );
        this.quoteData = await JSON.parse(
          JSON.stringify(this.changeQuoteVersionData)
        );
        this.tableDataArray = JSON.parse(
          JSON.stringify(this.quoteData.quoteItem)
        );
        console.log(
          this.quoteData,
          "changeQuoteVersionData after changed",
          this.changeQuoteVersionData
        );
        this.updateModalKey += 1;
        if (this.changeQuoteVersionData?.authorization[0]?.signaturPlaced) {
          this.signaturePlaced = JSON.parse(
            JSON.stringify(
              this.changeQuoteVersionData?.authorization[0]?.signaturPlaced
            )
          );
        } else {
          this.signaturePlaced = [];
        }
        // console.log("this.signaturePlaced ",this.signaturePlaced )
        //         const currentQuery = this.$route.query;
        //         await this.$router.replace({
        //   path: this.$route.path,
        //   query: {
        //     ...currentQuery,
        //     quote_id:  this.quoteData.id
        //   }
        // });
        return;
      }
      if (
        (this.quoteData && Object.keys(this.quoteData) == 0) ||
        forceRefresh
      ) {
        const data = await UPCAxiosInstance.get(`/quotemanagement/${id}`);
        // Client status
        let clientStatus = data?.data?.authorization?.find(
          (auth) => auth.name == "Quote client confirmation"
        );
        let currentClientStatus = this.quoteData?.authorization?.find(
          (auth) => auth.name == "Quote client confirmation"
        );
        if (
          forceRefresh &&
          clientStatus.state?.toLowerCase() == "inprogress" &&
          currentClientStatus.state?.toLowerCase() == "pending"
        ) {
          return;
        }
        this.quoteData = data?.data;

        

        // this.quoteData.authorization.forEach((item)=>{
        //     // if(item.approver.id == approverId){
        //       let count = 0
        //       let arr1 = (item.nofields || []).concat(item.termsandconditions || [])
        //       let arr = (arr1 || []).concat(item.pageend || [])
        //       arr.forEach((ele)=>{
        //         ele.focusId = item.approver[0].id + count
        //         count = count+1
        //       })
        //     // }
        //   })
        console.log(this.quoteData, "quoteData");
        // console.log("🚀 ~ mounted ~ data:", data);
      }
      if (this.$route.query.approver_id) {
        let approverId = this.$route.query.approver_id || 0;
        this.quoteData.authorization.forEach((item) => {
          if (item.approver[0].id == approverId) {
            this.multipleSignatureCount = item.totalSignature;
            this.clientName = item.clientName;
            console.log(item, "lolol");
          }
          // if(item.approver[0].id == approverId){
          //   let count = 0
          //   let arr1 = (item.nofields || []).concat(item.termsandconditions || [])
          //   let arr = (arr1 || []).concat(item.pageend || [])
          //   arr.forEach((ele)=>{
          //     ele.focusId = item.approver[0].id + count
          //     count = count+1
          //   })
          // }
        });
        console.log(this.multipleSignatureCount, this.clientName, "lolol");
      }
      let profileName = null;
      this.quoteData?.attachment?.forEach((item) => {
        if (item.attachmentType == "profile") {
          profileName = item?.name;
        }
      });
      this.quoteData.externalId.forEach((item)=>{
        console.log(item,'detailsss')
          if(item['@type']){
            if(item['@type'] == 'QuoteDisplayID'){
              this.externalId = item.id
            }
          }
        })
      if (this.quoteData && this.quoteData.attachment[0]) {
        let uploadedContent = await UPCAxiosInstance.get(
          "/gcpupload/files/" + profileName,
          {
            responseType: "arraybuffer",
            headers: {
              Authorization: this.$cookies.get("token"),
            },
          }
        );
        console.log("🚀 ~ mounted ~ uploadedContent:", uploadedContent);
        this.profilePicturePrevalue = this.bufferToDataURL(
          uploadedContent.data
        );
        console.log(
          "🚀 ~ mounted ~ this.profilePicturePrevalue:",
          this.profilePicturePrevalue
        );
      }
      if (this.quoteData && this.quoteData.attachment[0] && !isVersion) {
        let uploadedContent = await UPCAxiosInstance.get(
          "/gcpupload/files/" + profileName,
          {
            responseType: "arraybuffer",
            headers: {
              Authorization: this.$cookies.get("token"),
            },
          }
        );
        console.log("🚀 ~ mounted ~ uploadedContent:", uploadedContent);
        this.profilePicturePrevalue = this.bufferToDataURL(
          uploadedContent.data
        );
        console.log(
          "🚀 ~ mounted ~ this.profilePicturePrevalue:",
          this.profilePicturePrevalue
        );
      }

      // To get recipientsList
      this.getRecipientList();

      // this.quoteData.state =
      //   this.quoteData?.state?.toLowerCase() == "inprogress" &&
      //   (this.quoteTotalPrice <= 150000 || this.quoteDiscount <= 10)
      //     ? "Approved"
      //     : this.quoteData?.state;

      // this.quoteData = data?.data;
      this.signaturePlaced = this.quoteData?.authorization[0]?.signaturPlaced;
      this.attachments = [];
      this.quoteData.attachment.forEach((item) => {
        if (item.attachmentType == "quoteDocuments") {
          this.attachments.push(item);
        }
      });
      console.log(this.attachments, "attachments");
      this.quoteData.contactMedium.filter((item) => {
        if (item.mediumType == "senderPostalAddress") {
          this.senderAddress = item.characteristic?.description;
        }
        if (item.mediumType == "recipientShippingAddress") {
          this.buyerAddress = item.characteristic?.description;
        }
        if (item.mediumType == "recipientBillingAddress") {
          this.billingAddress = item.characteristic?.description;
        }
        if (item.mediumType == "buyerContactEmail") {
          this.toemail = item.characteristic.emailAddress;
        }
        if (item.mediumType == "sellerEmail") {
          this.fromemail = item.characteristic.emailAddress;
        }
        if (item.mediumType == "sellerPhone") {
          this.sellerPhone =
            item?.characteristic?.phoneNumber?.country?.dial_code +
            item?.characteristic?.phoneNumber?.value;
        }
        if (item.mediumType == "buyerPhone") {
          this.buyerPhone =
            item?.characteristic?.phoneNumber?.country?.dial_code +
            item?.characteristic?.phoneNumber?.value;
        }
        if (item.mediumType == "sellerEmail") {
          this.sellerEmail = item?.characteristic?.emailAddress;
        }
        if (item.mediumType == "senderPostalAddress") {
          this.sellerAddress =
            item?.characteristic?.characteristic?.description;
        }
      });
      this.quoteData.relatedParty.filter((item) => {
        if (item.role == "Seller") {
          this.quoteFrom = item.name;
          this.quoteFromId = item.id;
        }
        if (item.role == "Buyer") {
          let value = item.name.toLowerCase();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          this.quoteFor = value;
          this.quoteForId = item.id;
        }
        if (item.role == "Buyer contact") {
          this.billTo = item.name;
          let value = item.name.toLowerCase();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          this.recipientContact = value;
        }
        if (item.role == "Seller contact") {
          // this.billTo = item.name;
          let value = item.name.toLowerCase();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          this.senderContact = value;
        }
      });
      this.quoteArray = [];
      this.tableDataArray = JSON.parse(
        JSON.stringify(this.quoteData.quoteItem)
      );
      console.log(this.tableDataArray, "tableDataArray");
      this.tableData = JSON.parse(JSON.stringify(this.quoteData.quoteItem));

      // console.log(this.quoteData, "data", this.quoteArray);

      let agreementandnotesarr = [];
      if (this.quoteData.agreement && this.quoteData.agreement.length != 0) {
        this.quoteData.agreement.forEach((item) => {
          agreementandnotesarr.push(item);
        });
      }
      // console.log(this.quoteData.note, this.quoteData.note.length);
      if (this.quoteData.note && this.quoteData.note.length != 0) {
        this.quoteData.note.forEach((item) => {
          // console.log(item["@type"], item);
          if (item["@type"] == "Note" || item["@type"] == "AdiitionalNote")
            agreementandnotesarr.push(item);
        });
      }

      this.additionalFields = [];
      this.additionalFields.push(agreementandnotesarr);
      this.duplicateAdditionalFields = JSON.parse(
        JSON.stringify(this.additionalFields)
      );
      this.quoteData.authorization.forEach((item) => {
        if (item.name == "Quote manager approval") {
          this.managerApproval = item;
          console.log(
            "🚀 ~ this.quoteData.authorization.forEach ~ this.managerApproval:",
            this.managerApproval
          );
        } else if (item.name == "Quote client confirmation") {
          // console.log(item, "client");
          this.clientApproval = item;
        }
      });
    },
    handleQuoteBack() {
      if (
        (this.quoteData &&
          this.quoteData?.state &&
          this.quoteData?.state == "accepted") ||
        this.quoteApproved
      ) {
        if (
          this.$route.query.routeFrom &&
          this.$route.query.routeFrom == "QuoteTable"
        ) {
          this.$router.push({ name: "QuoteTable" });
        } else if (
          this.$route.query.routeFrom &&
          this.$route.query.routeFrom == "AccountsQuoteTable"
        ) {
          this.$router.push({
            name: "viewAccountPage",
            params: {
              companyName: this.quoteFor,
              id: this.quoteForId,
              type: "view",
              from: "accounts",
            },
          });
        } else {
          this.$router.push({ name: "QuoteTable" });
        }
        return;
      }
      if (
        this.$route.query.routeFrom &&
        this.$route.query.routeFrom == "QuoteTable"
      ) {
        this.$router.push({ name: "QuoteTable" });
      } else if (
        this.$route.query.routeFrom &&
        this.$route.query.routeFrom == "CPQDefaultTemplate"
      ) {
        this.$router.push({
          name: "CPQDefaultTemplate",
          query: { quote_id: this.$route.query.quote_id, mode: "edit" },
        });
      } else if (
        this.$route.query.routeFrom &&
        this.$route.query.routeFrom == "AccountsQuoteTable"
      ) {
        this.$router.push({
          name: "viewAccountPage",
          params: {
            companyName: this.quoteFor,
            id: this.quoteForId,
            type: "view",
            from: "accounts",
          },
        });
      } else {
        this.$router.push({ name: "QuoteTable" });
      }
    },
    async handleQuoteDownload() {
      let htmlCode = document.getElementById(
        "preview-page-container"
      ).outerHTML;
      console.log(htmlCode, "html");
      // let previewPage = this.$refs["preview-page-container"];
      //       const clonedElement = previewPage.cloneNode(true);
      const htmlContent1 =
        `<!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Price Quote</title>
        <style>
        *{
        margin:0px;
        padding:0px;
        }
            :root {
                --title-font: 'OpenSans', Inter;
                --body-font: 'OpenSans', Inter;
            }

            :root {
                --primary-color: rgba(101, 57, 192, 1);
                --secondary-color: rgba(101, 57, 192, 0.1);
                --primary-background: rgba(101, 57, 192, 1);
                --secondary-background: rgba(101, 57, 192, 0.1);
                --title-font: 'OpenSans';
                --body-font: 'OpenSans';
                --fallback-fonts: 'Noto Sans', 'Noto Looped Lao', 'Noto Looped Thai', 'Noto Music',
                    'Noto Naskh Arabic', 'Noto Nastaliq Urdu', 'Noto Rashi Hebrew', 'Noto Sans Adlam',
                    'Noto Sans Adlam Unjoined', 'Noto Sans Anatolian Hieroglyphs', 'Noto Sans AnatoHiero',
                    'Noto Sans Arabic', 'Noto Sans Armenian', 'Noto Sans Avestan', 'Noto Sans Balinese',
                    'Noto Sans Bamum', 'Noto Sans Bassa Vah', 'Noto Sans Batak', 'Noto Sans Bengali',
                    'Noto Sans Bhaiksuki', 'Noto Sans Brahmi', 'Noto Sans Buginese', 'Noto Sans Buhid',
                    'Noto Sans Canadian Aboriginal', 'Noto Sans CanAborig', 'Noto Sans Carian',
                    'Noto Sans Caucasian Albanian', 'Noto Sans CaucAlban', 'Noto Sans Chakma', 'Noto Sans Cham',
                    'Noto Sans Cherokee', 'Noto Sans Chorasmian', 'Noto Sans CJK JP', 'Noto Sans CJK KR',
                    'Noto Sans CJK SC', 'Noto Sans CJK TC', 'Noto Sans CJK HK', 'Noto Sans JP', 'Noto Sans KR',
                    'Noto Sans SC', 'Noto Sans TC', 'Noto Sans HK', 'Noto Sans Coptic', 'Noto Sans Cuneiform',
                    'Noto Sans Cypriot', 'Noto Sans Cypro Minoan', 'Noto Sans Deseret', 'Noto Sans Devanagari',
                    'Noto Sans Duployan', 'Noto Sans Egyptian Hieroglyphs', 'Noto Sans EgyptHiero',
                    'Noto Sans Elbasan', 'Noto Sans Elymaic', 'Noto Sans Ethiopic', 'Noto Sans Georgian',
                    'Noto Sans Glagolitic', 'Noto Sans Gothic', 'Noto Sans Grantha', 'Noto Sans Gujarati',
                    'Noto Sans Gunjala Gondi', 'Noto Sans Gurmukhi', 'Noto Sans Hanifi Rohingya',
                    'Noto Sans Hanunoo', 'Noto Sans Hatran', 'Noto Sans Hebrew Droid', 'Noto Sans Hebrew New',
                    'Noto Sans Hebrew', 'Noto Sans Imperial Aramaic', 'Noto Sans ImpAramaic',
                    'Noto Sans Indic Siyaq Numbers', 'Noto Sans Inscriptional Pahlavi', 'Noto Sans InsPahlavi',
                    'Noto Sans Inscriptional Parthian', 'Noto Sans InsParthi', 'Noto Sans Javanese',
                    'Noto Sans Kaithi', 'Noto Sans Kannada', 'Noto Sans Kayah Li', 'Noto Sans Kharoshthi',
                    'Noto Sans Khmer', 'Noto Sans Khojki', 'Noto Sans Khudawadi', 'Noto Sans Lao',
                    'Noto Sans Lepcha', 'Noto Sans Limbu', 'Noto Sans Linear A', 'Noto Sans Linear B',
                    'Noto Sans Lisu', 'Noto Sans Lycian', 'Noto Sans Lydian', 'Noto Sans Mahajani',
                    'Noto Sans Malayalam', 'Noto Sans Mandaic', 'Noto Sans Manichaean', 'Noto Sans Marchen',
                    'Noto Sans Masaram Gondi', 'Noto Sans Math', 'Noto Sans Mayan Numerals', 'Noto Sans Medefaidrin',
                    'Noto Sans Meetei Mayek', 'Noto Sans Mende Kikakui', 'Noto Sans Meroitic', 'Noto Sans Miao',
                    'Noto Sans Modi', 'Noto Sans Mongolian', 'Noto Sans Mono', 'Noto Sans Mro', 'Noto Sans Multani',
                    'Noto Sans Myanmar', 'Noto Sans Nabataean', 'Noto Sans Nandinagari', 'Noto Sans Newa',
                    'Noto Sans New Tai Lue', 'Noto Sans NKo', 'Noto Sans Nushu', 'Noto Sans Ogham',
                    'Noto Sans Ol Chiki', 'Noto Sans Old Hungarian', 'Noto Sans OldHung', 'Noto Sans Old Italic',
                    'Noto Sans Old North Arabian', 'Noto Sans OldNorArab', 'Noto Sans Old Permic',
                    'Noto Sans Old Persian', 'Noto Sans Old Sogdian', 'Noto Sans Old South Arabian',
                    'Noto Sans OldSouArab', 'Noto Sans Old Turkic', 'Noto Sans Oriya', 'Noto Sans Osage',
                    'Noto Sans Osmanya', 'Noto Sans Pahawh Hmong', 'Noto Sans Palmyrene', 'Noto Sans Pau Cin Hau',
                    'Noto Sans PhagsPa', 'Noto Sans Phoenician', 'Noto Sans Psalter Pahlavi', 'Noto Sans PsaPahlavi',
                    'Noto Sans', 'Noto Sans Rejang', 'Noto Sans Runic', 'Noto Sans Samaritan',
                    'Noto Sans Saurashtra', 'Noto Sans Sharada', 'Noto Sans Shavian', 'Noto Sans Siddham',
                    'Noto Sans Sinhala', 'Noto Sans Sogdian', 'Noto Sans Sora Sompeng', 'Noto Sans Soyombo',
                    'Noto Sans Sundanese', 'Noto Sans Syloti Nagri', 'Noto Sans Symbols2', 'Noto Sans Symbols',
                    'Noto Sans Syriac', 'Noto Sans Tagalog', 'Noto Sans Tagbanwa', 'Noto Sans Tai Le',
                    'Noto Sans Tai Tham', 'Noto Sans Tai Viet', 'Noto Sans Takri', 'Noto Sans Tamil',
                    'Noto Sans Tamil Supplement', 'Noto Sans Tangsa', 'Noto Sans Telugu', 'Noto Sans Thaana',
                    'Noto Sans Thai', 'Noto Sans Tifinagh Adrar', 'Noto Sans Tifinagh Agraw Imazighen',
                    'Noto Sans Tifinagh Ahaggar', 'Noto Sans Tifinagh Air', 'Noto Sans Tifinagh APT',
                    'Noto Sans Tifinagh Azawagh', 'Noto Sans Tifinagh Ghat', 'Noto Sans Tifinagh Hawad',
                    'Noto Sans Tifinagh', 'Noto Sans Tifinagh Rhissa Ixa', 'Noto Sans Tifinagh SIL',
                    'Noto Sans Tifinagh Tawellemmet', 'Noto Sans Tirhuta', 'Noto Sans Ugaritic', 'Noto Sans Vai',
                    'Noto Sans Vithkuqi', 'Noto Sans Wancho', 'Noto Sans Warang Citi', 'Noto Sans Yi',
                    'Noto Sans Zanabazar Square', 'Noto Sans Zanabazar', 'Noto Traditional Nushu', sans-serif;
            }

            .quote-preview-page-container {
              width: 100%; */
     max-height: 2100px; 
     display: flex; 
    flex-direction: column; 
     align-items: center;
    /* padding: 1rem; */
    /* overflow-y: scroll !important; */
    font-size: 24px;
    line-height: 24px;
}
    #quote-preview-page-each-container2{
      page-break-before: always !important;
    }
.quote-preview-page-container .quote-preview-page-each-container {
  min-height: 900px;
  width: 794px ;
  margin:0px !important;
  padding:0px !important;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header-left {
  display: flex;
  flex-direction: column;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header-left .quote-header-each-section {
  display: flex;
  align-items: center;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header-left .quote-header-each-section p {
  padding-left: 8px;
  padding-top: 2px;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header-right {
  display: flex;
  flex-direction: column;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header-right .quote-header-each-section {
  display: flex;
  align-items: center;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header-right .quote-header-each-section p {
  padding-left: 8px;
  padding-top: 2px;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-details {
  display: flex;
  justify-content: space-between;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-details-left, .quote-preview-page-container .quote-preview-page-each-container .quote-details-right {
  display: flex;
  flex-direction: column;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-details-left .quote-details-each-section, .quote-preview-page-container .quote-preview-page-each-container .quote-details-right .quote-details-each-section {
  width: 250px;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 0.5rem;
  border-radius: 4px;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-preview-signature-section {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.tool-bar {
  display: flex;
  gap: 1rem;
  padding: 0.75rem;
  box-shadow: 1px 1px 30px -16px;
  justify-content: flex-end;
}

.--button-with-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.quote-preview-page-terms-conditions .content-editable-container .content-editable-select {
  height: 100% !important;
  display: block !important;
}

.quote-preview-page-signature-container .signature-section {
  display: flex;
  justify-content: space-between;
}
.quote-preview-page-signature-container .signature-section-left, .quote-preview-page-signature-container .signature-section-right {
  display: flex;
  flex-direction: column;
}
.quote-preview-page-signature-container .signature-section-left h5, .quote-preview-page-signature-container .signature-section-right h5 {
  margin-bottom: 5px;
}

.quote-preview-page-terms-conditions {
  margin-bottom: 10px;
}

.preview-signature-section {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.preview-signature-section p {
  width: 64px;
  margin-right: 5px;
}

.signature-pad-whole-container {
  display: flex;
  flex-direction: column;
}
.signature-pad-whole-container .signature-pad-title-section {
  margin-bottom: 20px;
}
.signature-pad-whole-container .signature-date-section {
  margin-top: 10px;
}
.signature-pad-whole-container .signature-name-section {
  margin-top: 5px;
}

.pricing-summary-info-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: flex-end;
}
.pricing-summary-info-container .pricing-summary-wrapper {
  width: 40%;
  gap: 0.5rem 0;
  display: grid;
}
.pricing-summary-info-container .pricing-summary-wrapper .pricing-info {
  display: inline-grid;
  grid-template-columns: 80% 20%;
}
    .pricing-info {
            display: inline-grid;
            grid-template-columns: 80% 20%;
        }

        table {
        margin-top:20px;
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    th {
      color:#000000 !important;

    }

    tr:hover {
      background-color: #f5f5f5; 
    }

       
            .quote-page-container {
  width: 100%;
  display: flex;
  min-height: 100%;
  margin-left: auto;
  height: 90vh;
  scroll-behavior: smooth;
  margin-right: auto;
  justify-content: center;
  background-color: #f7f7f7;
}
.quote-page-container .quote-page {
  width: 100%;
  padding: 32px;
  display: flex;
  height: -moz-max-content;
  height: max-content;
  min-height: 100%;
  gap: 1rem;
  background-color: white;
  flex-direction: column;
}
.quote-page-container .quote-page .quote-number,
.quote-page-container .quote-page .quote-date,
.quote-page-container .quote-page .additional-fields {
  width: 50%;
}
.quote-page-container .quote-page .quote-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.quote-page-container .quote-page .quote-header .quote-title {
  font-weight: 400;
  margin: 0px;
  font-size: 2rem;
  line-height: 1.2;
  color: var(--hlx-text-color-primary);
  cursor: pointer;
  display: inline;
}
.quote-page-container .quote-page .quote-header .quote-add-sub-title-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}
.quote-page-container .quote-page .quote-header .quote-add-sub-title-button i {
  color: var(--hlx-color-primary);
}
.quote-page-container .quote-page .quote-header .quote-add-sub-title-button span.quote-add-sub-title-label {
  color: var(--hlx-text-color-primary);
  font-size: 24px;
}
.quote-page-container .quote-page .quote-basic-info-header {
  display: grid;
  grid-template-columns: 60% 40%;
}
.quote-page-container .quote-page .quote-basic-info-header .basic-info {
  box-sizing: border-box;
  min-width: 0px;
  gap: 1rem;
  flex-direction: column;
  display: flex;
}
.quote-page-container .quote-page .quote-basic-info-header .basic-info .quote-add-valid-date-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
}
.quote-page-container .quote-page .quote-basic-info-header .basic-info .quote-add-valid-date-button i {
  color: var(--hlx-color-primary);
}
.quote-page-container .quote-page .quote-basic-info-header .basic-info .quote-add-valid-date-button span.quote-add-valid-date-label {
  color: var(--hlx-text-color-primary);
  font-size: 24px;
}
.quote-page-container .quote-page .quote-basic-info-header .quote-logo-container {
  display: flex;
  align-items: center;
  margin: auto;
}
.quote-page-container .quote-page .custom-tile-2way {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0.875rem;
}
.quote-page-container .quote-page .custom-tile-2way .quote-sender,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient,
.quote-page-container .quote-page .custom-tile-2way .custom-tile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 0.5rem;
  border-radius: 4px;
  background: rgb(247, 250, 255);
}
.quote-page-container .quote-page .custom-tile-2way .quote-sender .sender-title-label,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .sender-title-label,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .sender-title-label {
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  font-size: 1.25rem;
  color: rgb(22, 28, 38);
  cursor: pointer;
  border-bottom: 1px dashed rgb(81, 97, 115);
}
.quote-page-container .quote-page .custom-tile-2way .quote-sender .--info-label,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .--info-label,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .--info-label {
  display: flex;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px 1rem;
  font-size: 0.875rem;
  color: var(--hlx-border-color-dark);
  align-items: center;
  justify-content: center;
}
.quote-page-container .quote-page .custom-tile-2way .quote-sender .business-details,
.quote-page-container .quote-page .custom-tile-2way .quote-sender .custom-tile-card,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .business-details,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .custom-tile-card,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .business-details,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .custom-tile-card {
  width: 100%;
  height: inherit;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  background: var(--hlx-color-white);
  gap: 0.5rem;
  padding: 24px;
  border: 1px solid var(--hlx-border-color-light);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.quote-page-container .quote-page .custom-tile-2way .quote-sender .business-details .no-data-placeholder-container,
.quote-page-container .quote-page .custom-tile-2way .quote-sender .custom-tile-card .no-data-placeholder-container,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .business-details .no-data-placeholder-container,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .custom-tile-card .no-data-placeholder-container,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .business-details .no-data-placeholder-container,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .custom-tile-card .no-data-placeholder-container {
  display: flex;
  height: inherit;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
}
.quote-page-container .quote-page .custom-tile-2way .quote-sender .business-details .no-data-placeholder-container .add-new-client-button,
.quote-page-container .quote-page .custom-tile-2way .quote-sender .custom-tile-card .no-data-placeholder-container .add-new-client-button,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .business-details .no-data-placeholder-container .add-new-client-button,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .custom-tile-card .no-data-placeholder-container .add-new-client-button,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .business-details .no-data-placeholder-container .add-new-client-button,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .custom-tile-card .no-data-placeholder-container .add-new-client-button {
  margin-right: 0.275rem;
}
.quote-page-container .quote-page .custom-tile-2way .quote-recipient {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 4px;
  background: rgb(247, 250, 255);
}
.quote-page-container .quote-page .add-terms-and-policy-container {
  display: flex;
  justify-content: space-between;
}
.quote-page-container .quote-page .add-terms-and-policy-container-left-section {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.quote-page-container .quote-page .add-terms-and-policy-container-signature-section {
  min-width: 30%;
  display: flex;
  justify-content: flex-end;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 0.5rem;
  border-radius: 4px;
  background: rgb(247, 250, 255);
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container {
  display: flex;
  flex-direction: column;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-one-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-one-container .label-wrapper {
  display: inline-flex;
  align-items: center;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-one-container .label-wrapper .terms-and-policy-each-element-section-header-value {
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  font-size: 1.25rem;
  color: rgb(22, 28, 38);
  cursor: pointer;
  border-bottom: 1px dashed #516173;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-one-container .label-wrapper .wrap {
  word-wrap: break-word;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-one-container .label-wrapper .icon-settings-regular {
  margin: 0px 1rem;
  color: #516173;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-additional-fields {
  display: flex;
  flex-direction: column;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-additional-fields .terms-and-policy-additional-fields-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-additional-fields .terms-and-policy-additional-fields-container .input-container {
  width: 98%;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-additional-fields .terms-and-policy-additional-fields-container .wrap {
  width: 98%;
  min-height: 36px;
  max-height: auto;
  background-color: white;
  word-wrap: break-word;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-add-new-group-or-term-element {
  display: flex;
  margin-top: 0.5rem;
  gap: 1px;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-add-new-group-or-term-element .terms-and-policy-add-valid-date-button {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-right: 0.5rem;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-add-new-group-or-term-element .terms-and-policy-add-valid-date-button .terms-and-policy-add-valid-date-label {
  margin-left: 0.25rem;
}
.quote-page-container .quote-page .signature-section-container {
  display: flex;
  justify-content: space-between;
}
.quote-page-container .quote-page .signature-section-container .from-signature-section-container .signature-section,
.quote-page-container .quote-page .signature-section-container .to-signature-section-container .signature-section {
  display: flex;
  flex-direction: column;
}
.quote-page-container .quote-page .signature-section-container .from-signature-section-container .signature-section .signature-input-container,
.quote-page-container .quote-page .signature-section-container .to-signature-section-container .signature-section .signature-input-container {
  margin-top: 1rem;
}
.quote-page-container .business-detail-container {
  padding: 20px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.quote-page-container .business-detail-container .info-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.quote-page-container .business-detail-container .info-header i {
  margin-left: auto;
}
.quote-page-container .business-detail-container .basic-business-info-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .basic-business-info-container .main-content-body-upload {
  align-items: center;
}
.quote-page-container .business-detail-container .basic-business-info-container .basic-business-info-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .address-information {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .address-information .address-info-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .address-information .address-info-body .vendor-radio-group {
  padding: 15px;
  display: flex;
  gap: 1rem;
}
.quote-page-container .business-detail-container .additional-information {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .additional-information .additional-info-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .additional-information .additional-info-body .vendor-radio-group {
  padding: 15px;
  display: flex;
  gap: 1rem;
}
.quote-page-container .business-detail-container .tax-information {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .tax-information .tax-info-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .tax-information .tax-info-body .vendor-radio-group {
  padding: 15px;
  display: flex;
  gap: 1rem;
}
.quote-page-container .--title-label {
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  font-size: 1.25rem;
  color: rgb(22, 28, 38);
  cursor: pointer;
  border-bottom: 1px dashed rgb(81, 97, 115);
}
.quote-page-container .--readable {
  border-bottom: 1px dashed rgb(81, 97, 115);
  cursor: pointer;
}
.quote-page-container .--info-label {
  display: inline-flex;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px 1rem;
  font-size: 0.875rem;
  color: var(--hlx-border-color-dark);
  align-items: center;
  justify-content: center;
}
.quote-page-container .label-wrapper {
  display: inline-flex;
}
.quote-page-container .--label-value-2 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
}
.quote-page-container .--additional-fields {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.quote-page-container .--Q-label {
  width: 200px;
  word-wrap: break-word;
  word-break: auto-phrase;
}
.quote-page-container .--required:after {
  content: "*";
  color: red;
}
.quote-page-container .form-field-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.quote-page-container .form-field-wrapper--child {
  display: grid;
  grid-template-columns: 106px auto;
  gap: 1rem;
}
.quote-page-container .form-field-wrapper i.business-title-editor {
  margin-left: auto;
}
.quote-page-container .form-field-wrapper.--mb-5 {
  margin-bottom: 0.5rem;
}
.quote-page-container .form-input-field-wrapper {
  display: grid;
  gap: 1rem;
}
.quote-page-container .form-input-field-wrapper.--2way {
  grid-template-columns: 1fr 1fr;
}
.quote-page-container .--form-label {
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  font-size: 0.875rem;
  color: rgb(81, 97, 115);
}
.quote-page-container .--form-value {
  word-break: break-word;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  font-size: 0.875rem;
  color: rgb(22, 28, 38);
}
.quote-page-container .quote-valid-till-date-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.quote-page-container .quote-valid-till-date-modal-content .day-configurator-wrapper {
  display: inline-flex;
  gap: 0.5rem;
  padding: 20px;
  align-items: center;
}
.quote-page-container .quote-valid-till-date-modal-content .day-configurator-wrapper :nth-child(2) {
  width: 20%;
}
.quote-page-container .Border_Multi_Radio {
}
.quote-page-container .custom-accordion-wrapper .custom-accordion-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quote-page-container .custom-accordion-wrapper .--accordion-body-wrapper {
  padding: 0.5rem 1rem;
}

.--title.--normal {
  font-weight: 600;
}
.--title .--w-50 {
  width: 50%;
}

.modal-backdrop .hlx-modal-container .modal main {
  padding: 0px !important;
}

.add-signature {
  border: 1px dashed #54bd95 !important;
}

.signature-pad {
}
.signature-pad .clear-button {
  color: rgb(25, 117, 255);
  cursor: pointer;
}
.signature-pad .clear-button:hover {
  text-decoration: underline;
}

.signature-pad-container {
  margin: 10px;
}
.signature-pad-container canvas {
  border: 1px solid black;
  background-color: white;
}

.signature-pad-upload-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.signature-pad-upload-button .clear-button {
  margin-left: 10px;
  font-size: 16px;
}
.signature-pad-upload-button .Upload,
.signature-pad-upload-button .Cancel {
  margin-right: 10px;
}

.name{
    height:10px !important
}

.already-signature-pad {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  border-radius: 4px;
  -o-object-fit: fill;
     object-fit: fill;
}
.already-signature-pad img {
  width: 200px;
  height: 30px;
}

.signature-title-section {
  margin-bottom: 20px;
}/*# sourceMappingURL=index.css.map */

</style>
    </head>
    <link href="https://fonts.googleapis.com/css2?family=OpenSans&amp;family=Inter" rel="stylesheet">

<body>` +
        htmlCode +
        `</body>

    </html>`;
      function convertToSinleLine(html) {
        return html.replace(/\s+/g, " ").trim();
      }
      let pdf = await UPCAxiosInstance.post(
        "/util/htmlToPdf",
        {
          config: {
            filename: this.quoteData.description,
            format: "A4",
          },
          content: convertToSinleLine(htmlContent1),
        },
        {
          headers: {
            Authorization: "Bearer " + this.$cookies.get("token"),
          },
          responseType: "arraybuffer",
        }
      );
      pdf = pdf.data;
      const blob = new Blob([pdf], { type: "application/pdf" });

      // Create a download link
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element
      const a = document.createElement("a");
      a.href = url;
      a.download = this.quoteData.description + ".pdf"; // Set the filename

      // Append the anchor element to the DOM
      document.body.appendChild(a);

      // Trigger the download
      a.click();
      return pdf;
      // console.log("🚀 ~ handleQuoteDownload ~ pdf:", typeof pdf);
      // // / Create a Blob from the PDF content
      // const blob = new Blob([pdf], { type: "application/pdf" });

      // // Create a download link
      // const url = window.URL.createObjectURL(blob);

      // // Create an anchor element
      // const a = document.createElement("a");
      // a.href = url;
      // a.download = this.quoteData.description + ".pdf"; // Set the filename

      // // Append the anchor element to the DOM
      // document.body.appendChild(a);

      // // Trigger the download
      // a.click();
      // Assuming pdfData is the data for the PDF
      // const pdfData = "data:application/pdf;base64,"+pdf;

      // // Create a temporary link element
      // const link = document.createElement("a");
      // link.href = pdfData;

      // // Set the download attribute and filename
      // link.download = this.quoteData.description+".pdf";

      // // Trigger the click event to download the PDF
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      // Assuming pdfData is the data for the PDF
      // const pdfData = "<<YOUR_PDF_DATA>>";

      // Create a blob from the PDF data
      // const blob = new Blob([pdf], { type: "application/pdf" });

      // Create a temporary URL for the blob
      // const url = window.URL.createObjectURL(pdf);

      // // Create a temporary link element
      // const link = document.createElement("a");
      // link.href = url;

      // // Set the download attribute and filename
      // link.download = "example.pdf";

      // // Trigger the click event to download the PDF
      // link.click();

      // // Clean up by revoking the URL object
      // window.URL.revokeObjectURL(url);
      // Create a blob from the PDF data
      // const blob = new Blob([pdf], { type: "application/pdf" });

      // // Create a temporary URL for the blob
      // const url = window.URL.createObjectURL(blob);

      // // Create a temporary link element
      // const link = document.createElement("a");
      // link.href = url;

      // // Set the download attribute and filename
      // link.download = "example.pdf";

      // // Trigger the click event to download the PDF
      // link.click();

      // // Clean up by revoking the URL object
      // window.URL.revokeObjectURL(url);
    },
    async handleQuoteDownload1() {
      let htmlCode = document.getElementById(
        "preview-page-container"
      ).outerHTML;
      console.log(htmlCode, "html");
      // let previewPage = this.$refs["preview-page-container"];
      //       const clonedElement = previewPage.cloneNode(true);
      const htmlContent1 =
        `<!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Price Quote</title>
        <style>
            :root {
                --title-font: 'OpenSans', Inter;
                --body-font: 'OpenSans', Inter;
            }

            :root {
                --primary-color: rgba(101, 57, 192, 1);
                --secondary-color: rgba(101, 57, 192, 0.1);
                --primary-background: rgba(101, 57, 192, 1);
                --secondary-background: rgba(101, 57, 192, 0.1);
                --title-font: 'OpenSans';
                --body-font: 'OpenSans';
                --fallback-fonts: 'Noto Sans', 'Noto Looped Lao', 'Noto Looped Thai', 'Noto Music',
                    'Noto Naskh Arabic', 'Noto Nastaliq Urdu', 'Noto Rashi Hebrew', 'Noto Sans Adlam',
                    'Noto Sans Adlam Unjoined', 'Noto Sans Anatolian Hieroglyphs', 'Noto Sans AnatoHiero',
                    'Noto Sans Arabic', 'Noto Sans Armenian', 'Noto Sans Avestan', 'Noto Sans Balinese',
                    'Noto Sans Bamum', 'Noto Sans Bassa Vah', 'Noto Sans Batak', 'Noto Sans Bengali',
                    'Noto Sans Bhaiksuki', 'Noto Sans Brahmi', 'Noto Sans Buginese', 'Noto Sans Buhid',
                    'Noto Sans Canadian Aboriginal', 'Noto Sans CanAborig', 'Noto Sans Carian',
                    'Noto Sans Caucasian Albanian', 'Noto Sans CaucAlban', 'Noto Sans Chakma', 'Noto Sans Cham',
                    'Noto Sans Cherokee', 'Noto Sans Chorasmian', 'Noto Sans CJK JP', 'Noto Sans CJK KR',
                    'Noto Sans CJK SC', 'Noto Sans CJK TC', 'Noto Sans CJK HK', 'Noto Sans JP', 'Noto Sans KR',
                    'Noto Sans SC', 'Noto Sans TC', 'Noto Sans HK', 'Noto Sans Coptic', 'Noto Sans Cuneiform',
                    'Noto Sans Cypriot', 'Noto Sans Cypro Minoan', 'Noto Sans Deseret', 'Noto Sans Devanagari',
                    'Noto Sans Duployan', 'Noto Sans Egyptian Hieroglyphs', 'Noto Sans EgyptHiero',
                    'Noto Sans Elbasan', 'Noto Sans Elymaic', 'Noto Sans Ethiopic', 'Noto Sans Georgian',
                    'Noto Sans Glagolitic', 'Noto Sans Gothic', 'Noto Sans Grantha', 'Noto Sans Gujarati',
                    'Noto Sans Gunjala Gondi', 'Noto Sans Gurmukhi', 'Noto Sans Hanifi Rohingya',
                    'Noto Sans Hanunoo', 'Noto Sans Hatran', 'Noto Sans Hebrew Droid', 'Noto Sans Hebrew New',
                    'Noto Sans Hebrew', 'Noto Sans Imperial Aramaic', 'Noto Sans ImpAramaic',
                    'Noto Sans Indic Siyaq Numbers', 'Noto Sans Inscriptional Pahlavi', 'Noto Sans InsPahlavi',
                    'Noto Sans Inscriptional Parthian', 'Noto Sans InsParthi', 'Noto Sans Javanese',
                    'Noto Sans Kaithi', 'Noto Sans Kannada', 'Noto Sans Kayah Li', 'Noto Sans Kharoshthi',
                    'Noto Sans Khmer', 'Noto Sans Khojki', 'Noto Sans Khudawadi', 'Noto Sans Lao',
                    'Noto Sans Lepcha', 'Noto Sans Limbu', 'Noto Sans Linear A', 'Noto Sans Linear B',
                    'Noto Sans Lisu', 'Noto Sans Lycian', 'Noto Sans Lydian', 'Noto Sans Mahajani',
                    'Noto Sans Malayalam', 'Noto Sans Mandaic', 'Noto Sans Manichaean', 'Noto Sans Marchen',
                    'Noto Sans Masaram Gondi', 'Noto Sans Math', 'Noto Sans Mayan Numerals', 'Noto Sans Medefaidrin',
                    'Noto Sans Meetei Mayek', 'Noto Sans Mende Kikakui', 'Noto Sans Meroitic', 'Noto Sans Miao',
                    'Noto Sans Modi', 'Noto Sans Mongolian', 'Noto Sans Mono', 'Noto Sans Mro', 'Noto Sans Multani',
                    'Noto Sans Myanmar', 'Noto Sans Nabataean', 'Noto Sans Nandinagari', 'Noto Sans Newa',
                    'Noto Sans New Tai Lue', 'Noto Sans NKo', 'Noto Sans Nushu', 'Noto Sans Ogham',
                    'Noto Sans Ol Chiki', 'Noto Sans Old Hungarian', 'Noto Sans OldHung', 'Noto Sans Old Italic',
                    'Noto Sans Old North Arabian', 'Noto Sans OldNorArab', 'Noto Sans Old Permic',
                    'Noto Sans Old Persian', 'Noto Sans Old Sogdian', 'Noto Sans Old South Arabian',
                    'Noto Sans OldSouArab', 'Noto Sans Old Turkic', 'Noto Sans Oriya', 'Noto Sans Osage',
                    'Noto Sans Osmanya', 'Noto Sans Pahawh Hmong', 'Noto Sans Palmyrene', 'Noto Sans Pau Cin Hau',
                    'Noto Sans PhagsPa', 'Noto Sans Phoenician', 'Noto Sans Psalter Pahlavi', 'Noto Sans PsaPahlavi',
                    'Noto Sans', 'Noto Sans Rejang', 'Noto Sans Runic', 'Noto Sans Samaritan',
                    'Noto Sans Saurashtra', 'Noto Sans Sharada', 'Noto Sans Shavian', 'Noto Sans Siddham',
                    'Noto Sans Sinhala', 'Noto Sans Sogdian', 'Noto Sans Sora Sompeng', 'Noto Sans Soyombo',
                    'Noto Sans Sundanese', 'Noto Sans Syloti Nagri', 'Noto Sans Symbols2', 'Noto Sans Symbols',
                    'Noto Sans Syriac', 'Noto Sans Tagalog', 'Noto Sans Tagbanwa', 'Noto Sans Tai Le',
                    'Noto Sans Tai Tham', 'Noto Sans Tai Viet', 'Noto Sans Takri', 'Noto Sans Tamil',
                    'Noto Sans Tamil Supplement', 'Noto Sans Tangsa', 'Noto Sans Telugu', 'Noto Sans Thaana',
                    'Noto Sans Thai', 'Noto Sans Tifinagh Adrar', 'Noto Sans Tifinagh Agraw Imazighen',
                    'Noto Sans Tifinagh Ahaggar', 'Noto Sans Tifinagh Air', 'Noto Sans Tifinagh APT',
                    'Noto Sans Tifinagh Azawagh', 'Noto Sans Tifinagh Ghat', 'Noto Sans Tifinagh Hawad',
                    'Noto Sans Tifinagh', 'Noto Sans Tifinagh Rhissa Ixa', 'Noto Sans Tifinagh SIL',
                    'Noto Sans Tifinagh Tawellemmet', 'Noto Sans Tirhuta', 'Noto Sans Ugaritic', 'Noto Sans Vai',
                    'Noto Sans Vithkuqi', 'Noto Sans Wancho', 'Noto Sans Warang Citi', 'Noto Sans Yi',
                    'Noto Sans Zanabazar Square', 'Noto Sans Zanabazar', 'Noto Traditional Nushu', sans-serif;
            }

            .quote-preview-page-container {
     display: flex; 
    flex-direction: column; 
     align-items: center;
    /* padding: 1rem; */
    /* overflow-y: scroll !important; */
    font-size: 24px;
    line-height: 24px;
}
.quote-preview-page-container .quote-preview-page-each-container {
padding:60px;
  min-height: 1086px;
  width: 870px ;
}

.extra-margin{
  margin:0 0 0 20px;
}

#quote-preview-page-each-container2{
margin-top:60px;
margin-left:60px;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 40px;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header-left {
  display: flex;
  flex-direction: column;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header-left .quote-header-each-section {
  display: flex;
  align-items: center;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header-left .quote-header-each-section p {
  padding-left: 8px;
  padding-top: 2px;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header-right {
  display: flex;
  flex-direction: column;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header-right .quote-header-each-section {
  display: flex;
  align-items: center;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-header-right .quote-header-each-section p {
  padding-left: 8px;
  padding-top: 2px;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-details {
  display: flex;
  justify-content: space-between;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-details-left, .quote-preview-page-container .quote-preview-page-each-container .quote-details-right {
  display: flex;
  flex-direction: column;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-details-left .quote-details-each-section, .quote-preview-page-container .quote-preview-page-each-container .quote-details-right .quote-details-each-section {
  width: 250px;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 0.5rem;
  border-radius: 4px;
}
.quote-preview-page-container .quote-preview-page-each-container .quote-preview-signature-section {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.tool-bar {
  display: flex;
  gap: 1rem;
  padding: 0.75rem;
  box-shadow: 1px 1px 30px -16px;
  justify-content: flex-end;
}

.--button-with-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.quote-preview-page-terms-conditions .content-editable-container .content-editable-select {
  height: 100% !important;
  display: block !important;
}

.quote-preview-page-signature-container .signature-section {
  display: flex;
  justify-content: space-between;
}
.quote-preview-page-signature-container .signature-section-left, .quote-preview-page-signature-container .signature-section-right {
  display: flex;
  flex-direction: column;
}
.quote-preview-page-signature-container .signature-section-left h5, .quote-preview-page-signature-container .signature-section-right h5 {
  margin-bottom: 5px;
}

.quote-preview-page-terms-conditions {
  margin-bottom: 10px;
}

.preview-signature-section {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.preview-signature-section p {
  width: 64px;
  margin-right: 5px;
}

.signature-pad-whole-container {
  display: flex;
  flex-direction: column;
}
.signature-pad-whole-container .signature-pad-title-section {
  margin-bottom: 20px;
}
.signature-pad-whole-container .signature-date-section {
  margin-top: 10px;
}
.signature-pad-whole-container .signature-name-section {
  margin-top: 5px;
}

.pricing-summary-info-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: flex-end;
}
.pricing-summary-info-container .pricing-summary-wrapper {
  width: 47%;
  gap: 0.5rem 0;
  display: grid;
}
.pricing-summary-info-container .pricing-summary-wrapper .pricing-info {
  display: inline-grid;
  grid-template-columns: 80% 20%;
}
    .pricing-info {
            display: inline-grid;
            grid-template-columns: 80% 20%;
        }

        table {
        margin-top:20px;
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    th {
      color:#000000 !important;

    }

    tr:hover {
      background-color: #f5f5f5; 
    }

       
            .quote-page-container {
  width: 100%;
  display: flex;
  min-height: 100%;
  margin-left: auto;
  height: 90vh;
  scroll-behavior: smooth;
  margin-right: auto;
  justify-content: center;
  background-color: #f7f7f7;
}
.quote-page-container .quote-page {
  width: 100%;
  padding: 32px;
  display: flex;
  height: -moz-max-content;
  height: max-content;
  min-height: 100%;
  gap: 1rem;
  background-color: white;
  flex-direction: column;
}
.quote-page-container .quote-page .quote-number,
.quote-page-container .quote-page .quote-date,
.quote-page-container .quote-page .additional-fields {
  width: 50%;
}
.quote-page-container .quote-page .quote-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.quote-page-container .quote-page .quote-header .quote-title {
  font-weight: 400;
  margin: 0px;
  font-size: 2rem;
  line-height: 1.2;
  color: var(--hlx-text-color-primary);
  cursor: pointer;
  display: inline;
}
.quote-page-container .quote-page .quote-header .quote-add-sub-title-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}
.quote-page-container .quote-page .quote-header .quote-add-sub-title-button i {
  color: var(--hlx-color-primary);
}
.quote-page-container .quote-page .quote-header .quote-add-sub-title-button span.quote-add-sub-title-label {
  color: var(--hlx-text-color-primary);
  font-size: 24px;
}
.quote-page-container .quote-page .quote-basic-info-header {
  display: grid;
  grid-template-columns: 60% 40%;
}
.quote-page-container .quote-page .quote-basic-info-header .basic-info {
  box-sizing: border-box;
  min-width: 0px;
  gap: 1rem;
  flex-direction: column;
  display: flex;
}
.quote-page-container .quote-page .quote-basic-info-header .basic-info .quote-add-valid-date-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
}
.quote-page-container .quote-page .quote-basic-info-header .basic-info .quote-add-valid-date-button i {
  color: var(--hlx-color-primary);
}
.quote-page-container .quote-page .quote-basic-info-header .basic-info .quote-add-valid-date-button span.quote-add-valid-date-label {
  color: var(--hlx-text-color-primary);
  font-size: 24px;
}
.quote-page-container .quote-page .quote-basic-info-header .quote-logo-container {
  display: flex;
  align-items: center;
  margin: auto;
}
.quote-page-container .quote-page .custom-tile-2way {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0.875rem;
}
.quote-page-container .quote-page .custom-tile-2way .quote-sender,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient,
.quote-page-container .quote-page .custom-tile-2way .custom-tile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 0.5rem;
  border-radius: 4px;
  background: rgb(247, 250, 255);
}
.quote-page-container .quote-page .custom-tile-2way .quote-sender .sender-title-label,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .sender-title-label,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .sender-title-label {
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  font-size: 1.25rem;
  color: rgb(22, 28, 38);
  cursor: pointer;
  border-bottom: 1px dashed rgb(81, 97, 115);
}
.quote-page-container .quote-page .custom-tile-2way .quote-sender .--info-label,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .--info-label,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .--info-label {
  display: flex;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px 1rem;
  font-size: 0.875rem;
  color: var(--hlx-border-color-dark);
  align-items: center;
  justify-content: center;
}
.quote-page-container .quote-page .custom-tile-2way .quote-sender .business-details,
.quote-page-container .quote-page .custom-tile-2way .quote-sender .custom-tile-card,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .business-details,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .custom-tile-card,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .business-details,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .custom-tile-card {
  width: 100%;
  height: inherit;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  background: var(--hlx-color-white);
  gap: 0.5rem;
  padding: 24px;
  border: 1px solid var(--hlx-border-color-light);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.quote-page-container .quote-page .custom-tile-2way .quote-sender .business-details .no-data-placeholder-container,
.quote-page-container .quote-page .custom-tile-2way .quote-sender .custom-tile-card .no-data-placeholder-container,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .business-details .no-data-placeholder-container,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .custom-tile-card .no-data-placeholder-container,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .business-details .no-data-placeholder-container,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .custom-tile-card .no-data-placeholder-container {
  display: flex;
  height: inherit;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
}
.quote-page-container .quote-page .custom-tile-2way .quote-sender .business-details .no-data-placeholder-container .add-new-client-button,
.quote-page-container .quote-page .custom-tile-2way .quote-sender .custom-tile-card .no-data-placeholder-container .add-new-client-button,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .business-details .no-data-placeholder-container .add-new-client-button,
.quote-page-container .quote-page .custom-tile-2way .quote-recipient .custom-tile-card .no-data-placeholder-container .add-new-client-button,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .business-details .no-data-placeholder-container .add-new-client-button,
.quote-page-container .quote-page .custom-tile-2way .custom-tile .custom-tile-card .no-data-placeholder-container .add-new-client-button {
  margin-right: 0.275rem;
}
.quote-page-container .quote-page .custom-tile-2way .quote-recipient {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 4px;
  background: rgb(247, 250, 255);
}
.quote-page-container .quote-page .add-terms-and-policy-container {
  display: flex;
  justify-content: space-between;
}
.quote-page-container .quote-page .add-terms-and-policy-container-left-section {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.quote-page-container .quote-page .add-terms-and-policy-container-signature-section {
  min-width: 30%;
  display: flex;
  justify-content: flex-end;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 0.5rem;
  border-radius: 4px;
  background: rgb(247, 250, 255);
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container {
  display: flex;
  flex-direction: column;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-one-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-one-container .label-wrapper {
  display: inline-flex;
  align-items: center;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-one-container .label-wrapper .terms-and-policy-each-element-section-header-value {
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  font-size: 1.25rem;
  color: rgb(22, 28, 38);
  cursor: pointer;
  border-bottom: 1px dashed #516173;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-one-container .label-wrapper .wrap {
  word-wrap: break-word;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-one-container .label-wrapper .icon-settings-regular {
  margin: 0px 1rem;
  color: #516173;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-additional-fields {
  display: flex;
  flex-direction: column;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-additional-fields .terms-and-policy-additional-fields-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-additional-fields .terms-and-policy-additional-fields-container .input-container {
  width: 98%;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-additional-fields .terms-and-policy-additional-fields-container .wrap {
  width: 98%;
  min-height: 36px;
  max-height: auto;
  background-color: white;
  word-wrap: break-word;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-add-new-group-or-term-element {
  display: flex;
  margin-top: 0.5rem;
  gap: 1px;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-add-new-group-or-term-element .terms-and-policy-add-valid-date-button {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-right: 0.5rem;
}
.quote-page-container .quote-page .terms-and-policy-whole-element-container .terms-and-policy-each-element-section .terms-and-policy-each-element-section-header-container .terms-and-policy-add-new-group-or-term-element .terms-and-policy-add-valid-date-button .terms-and-policy-add-valid-date-label {
  margin-left: 0.25rem;
}
.quote-page-container .quote-page .signature-section-container {
  display: flex;
  justify-content: space-between;
}
.quote-page-container .quote-page .signature-section-container .from-signature-section-container .signature-section,
.quote-page-container .quote-page .signature-section-container .to-signature-section-container .signature-section {
  display: flex;
  flex-direction: column;
}
.quote-page-container .quote-page .signature-section-container .from-signature-section-container .signature-section .signature-input-container,
.quote-page-container .quote-page .signature-section-container .to-signature-section-container .signature-section .signature-input-container {
  margin-top: 1rem;
}
.quote-page-container .business-detail-container {
  padding: 20px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.quote-page-container .business-detail-container .info-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.quote-page-container .business-detail-container .info-header i {
  margin-left: auto;
}
.quote-page-container .business-detail-container .basic-business-info-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .basic-business-info-container .main-content-body-upload {
  align-items: center;
}
.quote-page-container .business-detail-container .basic-business-info-container .basic-business-info-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .address-information {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .address-information .address-info-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .address-information .address-info-body .vendor-radio-group {
  padding: 15px;
  display: flex;
  gap: 1rem;
}
.quote-page-container .business-detail-container .additional-information {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .additional-information .additional-info-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .additional-information .additional-info-body .vendor-radio-group {
  padding: 15px;
  display: flex;
  gap: 1rem;
}
.quote-page-container .business-detail-container .tax-information {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .tax-information .tax-info-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quote-page-container .business-detail-container .tax-information .tax-info-body .vendor-radio-group {
  padding: 15px;
  display: flex;
  gap: 1rem;
}
.quote-page-container .--title-label {
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  font-size: 1.25rem;
  color: rgb(22, 28, 38);
  cursor: pointer;
  border-bottom: 1px dashed rgb(81, 97, 115);
}
.quote-page-container .--readable {
  border-bottom: 1px dashed rgb(81, 97, 115);
  cursor: pointer;
}
.quote-page-container .--info-label {
  display: inline-flex;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px 1rem;
  font-size: 0.875rem;
  color: var(--hlx-border-color-dark);
  align-items: center;
  justify-content: center;
}
.quote-page-container .label-wrapper {
  display: inline-flex;
}
.quote-page-container .--label-value-2 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
}
.quote-page-container .--additional-fields {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.quote-page-container .--Q-label {
  width: 200px;
  word-wrap: break-word;
  word-break: auto-phrase;
}
.quote-page-container .--required:after {
  content: "*";
  color: red;
}
.quote-page-container .form-field-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.quote-page-container .form-field-wrapper--child {
  display: grid;
  grid-template-columns: 106px auto;
  gap: 1rem;
}
.quote-page-container .form-field-wrapper i.business-title-editor {
  margin-left: auto;
}
.quote-page-container .form-field-wrapper.--mb-5 {
  margin-bottom: 0.5rem;
}
.quote-page-container .form-input-field-wrapper {
  display: grid;
  gap: 1rem;
}
.quote-page-container .form-input-field-wrapper.--2way {
  grid-template-columns: 1fr 1fr;
}
.quote-page-container .--form-label {
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  font-size: 0.875rem;
  color: rgb(81, 97, 115);
}
.quote-page-container .--form-value {
  word-break: break-word;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  font-size: 0.875rem;
  color: rgb(22, 28, 38);
}
.quote-page-container .quote-valid-till-date-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.quote-page-container .quote-valid-till-date-modal-content .day-configurator-wrapper {
  display: inline-flex;
  gap: 0.5rem;
  padding: 20px;
  align-items: center;
}
.quote-page-container .quote-valid-till-date-modal-content .day-configurator-wrapper :nth-child(2) {
  width: 20%;
}
.quote-page-container .Border_Multi_Radio {
}
.quote-page-container .custom-accordion-wrapper .custom-accordion-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quote-page-container .custom-accordion-wrapper .--accordion-body-wrapper {
  padding: 0.5rem 1rem;
}

.--title.--normal {
  font-weight: 600;
}
.--title .--w-50 {
  width: 50%;
}

.modal-backdrop .hlx-modal-container .modal main {
  padding: 0px !important;
}

.add-signature {
  border: 1px dashed #54bd95 !important;
}

.signature-pad {
}
.signature-pad .clear-button {
  color: rgb(25, 117, 255);
  cursor: pointer;
}
.signature-pad .clear-button:hover {
  text-decoration: underline;
}

.signature-pad-container {
  margin: 10px;
}
.signature-pad-container canvas {
  border: 1px solid black;
  background-color: white;
}

.signature-pad-upload-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.signature-pad-upload-button .clear-button {
  margin-left: 10px;
  font-size: 16px;
}
.signature-pad-upload-button .Upload,
.signature-pad-upload-button .Cancel {
  margin-right: 10px;
}

.name{
    height:10px !important
}

.already-signature-pad {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  border-radius: 4px;
  -o-object-fit: fill;
     object-fit: fill;
}
.already-signature-pad img {
  width: 200px;
  height: 70px;
}
.details-container-area{
background:#f3f5f7 !important;
-webkit-print-color-adjust: exact;
}
.signature-button{
-webkit-print-color-adjust: exact;
}
.signature-title-section {
  margin-bottom: 20px;
}/*# sourceMappingURL=index.css.map */

</style>
    </head>
    <link href="https://fonts.googleapis.com/css2?family=OpenSans&amp;family=Inter" rel="stylesheet">

<body>` +
        htmlCode +
        `</body>

    </html>`;
      function convertToSinleLine(html) {
        return html.replace(/\s+/g, " ").trim();
      }
      let pdf = await UPCAxiosInstance.post(
        "/util/V1/htmlToPdf",
        {
          config: {
            filename: this.quoteData.description,
            format: "A4",
            signatures: this.multipleSignatureLocation,
          },
          content: convertToSinleLine(htmlContent1),
        },
        {
          // headers: {
          //   Authorization: "Bearer " + this.$cookies.get("token"),
          // },
          responseType: "arraybuffer",
        }
      );
      pdf = pdf.data;
      // return pdf;
      console.log("🚀 ~ handleQuoteDownload ~ pdf:", typeof pdf);
      // / Create a Blob from the PDF content
      const blob = new Blob([pdf], { type: "application/pdf" });

      // Create a download link
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element
      const a = document.createElement("a");
      a.href = url;
      a.download = this.quoteData.description + ".pdf"; // Set the filename

      // Append the anchor element to the DOM
      document.body.appendChild(a);

      // Trigger the download
      a.click();
      return pdf;
      // Assuming pdfData is the data for the PDF
      // const pdfData = "data:application/pdf;base64,"+pdf;

      // // Create a temporary link element
      // const link = document.createElement("a");
      // link.href = pdfData;

      // // Set the download attribute and filename
      // link.download = this.quoteData.description+".pdf";

      // // Trigger the click event to download the PDF
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      // Assuming pdfData is the data for the PDF
      // const pdfData = "<<YOUR_PDF_DATA>>";

      // Create a blob from the PDF data
      // const blob = new Blob([pdf], { type: "application/pdf" });

      // Create a temporary URL for the blob
      // const url = window.URL.createObjectURL(pdf);

      // // Create a temporary link element
      // const link = document.createElement("a");
      // link.href = url;

      // // Set the download attribute and filename
      // link.download = "example.pdf";

      // // Trigger the click event to download the PDF
      // link.click();

      // // Clean up by revoking the URL object
      // window.URL.revokeObjectURL(url);
      // Create a blob from the PDF data
      // const blob = new Blob([pdf], { type: "application/pdf" });

      // // Create a temporary URL for the blob
      // const url = window.URL.createObjectURL(blob);

      // // Create a temporary link element
      // const link = document.createElement("a");
      // link.href = url;

      // // Set the download attribute and filename
      // link.download = "example.pdf";

      // // Trigger the click event to download the PDF
      // link.click();

      // // Clean up by revoking the URL object
      // window.URL.revokeObjectURL(url);
    },
    //     async handleQuoteDownload() {
    //       const htmlContent1 = `<!DOCTYPE html>
    // <html lang="en">

    // <head>
    //     <meta charset="UTF-8">
    //     <meta name="viewport" content="width=device-width, initial-scale=1.0">
    //     <title>Price Quote</title>
    //     <style>
    //         :root {
    //             --title-font: "OpenSans", Inter;
    //             --body-font: "OpenSans", Inter;
    //         }

    //         .total-in-words-screen {
    //             display: flex;
    //         }

    //         .total-in-words-mobile {
    //             display: none;
    //         }

    //         @media screen and (max-width: 568px) {
    //             .total-in-words-screen {
    //                 display: none;
    //             }

    //             .total-in-words-mobile {
    //                 display: flex;
    //             }
    //         }

    //         :root {
    //             --primary-color: rgba(101, 57, 192, 1);
    //             --secondary-color: rgba(101, 57, 192, 0.1);
    //             --primary-background: rgba(101, 57, 192, 1);
    //             --secondary-background: rgba(101, 57, 192, 0.1);
    //             --title-font: 'OpenSans';
    //             --body-font: 'OpenSans';
    //             --fallback-fonts: 'Noto Sans', 'Noto Looped Lao', 'Noto Looped Thai', 'Noto Music',
    //                 'Noto Naskh Arabic', 'Noto Nastaliq Urdu', 'Noto Rashi Hebrew', 'Noto Sans Adlam',
    //                 'Noto Sans Adlam Unjoined', 'Noto Sans Anatolian Hieroglyphs', 'Noto Sans AnatoHiero',
    //                 'Noto Sans Arabic', 'Noto Sans Armenian', 'Noto Sans Avestan', 'Noto Sans Balinese',
    //                 'Noto Sans Bamum', 'Noto Sans Bassa Vah', 'Noto Sans Batak', 'Noto Sans Bengali',
    //                 'Noto Sans Bhaiksuki', 'Noto Sans Brahmi', 'Noto Sans Buginese', 'Noto Sans Buhid',
    //                 'Noto Sans Canadian Aboriginal', 'Noto Sans CanAborig', 'Noto Sans Carian',
    //                 'Noto Sans Caucasian Albanian', 'Noto Sans CaucAlban', 'Noto Sans Chakma', 'Noto Sans Cham',
    //                 'Noto Sans Cherokee', 'Noto Sans Chorasmian', 'Noto Sans CJK JP', 'Noto Sans CJK KR',
    //                 'Noto Sans CJK SC', 'Noto Sans CJK TC', 'Noto Sans CJK HK', 'Noto Sans JP', 'Noto Sans KR',
    //                 'Noto Sans SC', 'Noto Sans TC', 'Noto Sans HK', 'Noto Sans Coptic', 'Noto Sans Cuneiform',
    //                 'Noto Sans Cypriot', 'Noto Sans Cypro Minoan', 'Noto Sans Deseret', 'Noto Sans Devanagari',
    //                 'Noto Sans Duployan', 'Noto Sans Egyptian Hieroglyphs', 'Noto Sans EgyptHiero',
    //                 'Noto Sans Elbasan', 'Noto Sans Elymaic', 'Noto Sans Ethiopic', 'Noto Sans Georgian',
    //                 'Noto Sans Glagolitic', 'Noto Sans Gothic', 'Noto Sans Grantha', 'Noto Sans Gujarati',
    //                 'Noto Sans Gunjala Gondi', 'Noto Sans Gurmukhi', 'Noto Sans Hanifi Rohingya',
    //                 'Noto Sans Hanunoo', 'Noto Sans Hatran', 'Noto Sans Hebrew Droid', 'Noto Sans Hebrew New',
    //                 'Noto Sans Hebrew', 'Noto Sans Imperial Aramaic', 'Noto Sans ImpAramaic',
    //                 'Noto Sans Indic Siyaq Numbers', 'Noto Sans Inscriptional Pahlavi', 'Noto Sans InsPahlavi',
    //                 'Noto Sans Inscriptional Parthian', 'Noto Sans InsParthi', 'Noto Sans Javanese',
    //                 'Noto Sans Kaithi', 'Noto Sans Kannada', 'Noto Sans Kayah Li', 'Noto Sans Kharoshthi',
    //                 'Noto Sans Khmer', 'Noto Sans Khojki', 'Noto Sans Khudawadi', 'Noto Sans Lao',
    //                 'Noto Sans Lepcha', 'Noto Sans Limbu', 'Noto Sans Linear A', 'Noto Sans Linear B',
    //                 'Noto Sans Lisu', 'Noto Sans Lycian', 'Noto Sans Lydian', 'Noto Sans Mahajani',
    //                 'Noto Sans Malayalam', 'Noto Sans Mandaic', 'Noto Sans Manichaean', 'Noto Sans Marchen',
    //                 'Noto Sans Masaram Gondi', 'Noto Sans Math', 'Noto Sans Mayan Numerals', 'Noto Sans Medefaidrin',
    //                 'Noto Sans Meetei Mayek', 'Noto Sans Mende Kikakui', 'Noto Sans Meroitic', 'Noto Sans Miao',
    //                 'Noto Sans Modi', 'Noto Sans Mongolian', 'Noto Sans Mono', 'Noto Sans Mro', 'Noto Sans Multani',
    //                 'Noto Sans Myanmar', 'Noto Sans Nabataean', 'Noto Sans Nandinagari', 'Noto Sans Newa',
    //                 'Noto Sans New Tai Lue', 'Noto Sans NKo', 'Noto Sans Nushu', 'Noto Sans Ogham',
    //                 'Noto Sans Ol Chiki', 'Noto Sans Old Hungarian', 'Noto Sans OldHung', 'Noto Sans Old Italic',
    //                 'Noto Sans Old North Arabian', 'Noto Sans OldNorArab', 'Noto Sans Old Permic',
    //                 'Noto Sans Old Persian', 'Noto Sans Old Sogdian', 'Noto Sans Old South Arabian',
    //                 'Noto Sans OldSouArab', 'Noto Sans Old Turkic', 'Noto Sans Oriya', 'Noto Sans Osage',
    //                 'Noto Sans Osmanya', 'Noto Sans Pahawh Hmong', 'Noto Sans Palmyrene', 'Noto Sans Pau Cin Hau',
    //                 'Noto Sans PhagsPa', 'Noto Sans Phoenician', 'Noto Sans Psalter Pahlavi', 'Noto Sans PsaPahlavi',
    //                 'Noto Sans', 'Noto Sans Rejang', 'Noto Sans Runic', 'Noto Sans Samaritan',
    //                 'Noto Sans Saurashtra', 'Noto Sans Sharada', 'Noto Sans Shavian', 'Noto Sans Siddham',
    //                 'Noto Sans Sinhala', 'Noto Sans Sogdian', 'Noto Sans Sora Sompeng', 'Noto Sans Soyombo',
    //                 'Noto Sans Sundanese', 'Noto Sans Syloti Nagri', 'Noto Sans Symbols2', 'Noto Sans Symbols',
    //                 'Noto Sans Syriac', 'Noto Sans Tagalog', 'Noto Sans Tagbanwa', 'Noto Sans Tai Le',
    //                 'Noto Sans Tai Tham', 'Noto Sans Tai Viet', 'Noto Sans Takri', 'Noto Sans Tamil',
    //                 'Noto Sans Tamil Supplement', 'Noto Sans Tangsa', 'Noto Sans Telugu', 'Noto Sans Thaana',
    //                 'Noto Sans Thai', 'Noto Sans Tifinagh Adrar', 'Noto Sans Tifinagh Agraw Imazighen',
    //                 'Noto Sans Tifinagh Ahaggar', 'Noto Sans Tifinagh Air', 'Noto Sans Tifinagh APT',
    //                 'Noto Sans Tifinagh Azawagh', 'Noto Sans Tifinagh Ghat', 'Noto Sans Tifinagh Hawad',
    //                 'Noto Sans Tifinagh', 'Noto Sans Tifinagh Rhissa Ixa', 'Noto Sans Tifinagh SIL',
    //                 'Noto Sans Tifinagh Tawellemmet', 'Noto Sans Tirhuta', 'Noto Sans Ugaritic', 'Noto Sans Vai',
    //                 'Noto Sans Vithkuqi', 'Noto Sans Wancho', 'Noto Sans Warang Citi', 'Noto Sans Yi',
    //                 'Noto Sans Zanabazar Square', 'Noto Sans Zanabazar', 'Noto Traditional Nushu', sans-serif;
    //         }

    //         .show-in-pdf {
    //             display: none;
    //         }

    //         .invoice-page .no-background {
    //             background: none;
    //         }

    //         .invoice-page {
    //             padding: 20px;
    //             line-height: 21px;
    //             font-family: 'OpenSans', Roboto, 'Helvetica Neue', Helvetica, Arial, var(--fallback-fonts);
    //             font-family: -webkit-var(--body-font, 'OpenSans'), var(--fallback-fonts);
    //             font-family: var(--body-font, 'OpenSans'), var(--fallback-fonts);
    //         }

    //         @media print {
    //             .invoice-page {
    //                 padding: 10px;
    //             }
    //         }

    //         .invoice-page .strong {
    //             font-weight: 600;
    //         }

    //         .invoice-page .invoice-heading {
    //             font-family: -webkit-var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //             font-family: var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //             font-size: 32px;
    //             line-height: 40px;
    //             color: -webkit-var(--primary-color, rgba(101, 57, 192, 1));
    //             color: var(--primary-color, rgba(101, 57, 192, 1));
    //             display: -webkit-flex;
    //             display: flex;
    //             flex-direction: column;
    //             margin-bottom: 0;
    //         }

    //         .invoice-page .invoice-heading .invoice-title {
    //             display: flex;
    //             display: -webkit-flex;
    //             flex-direction: row;
    //         }

    //         .invoice-page .invoice-heading .invoice-subtitle {
    //             font-size: 16px;
    //             line-height: 20px;
    //             color: rgba(0, 0, 0, 0.5);
    //             font-weight: bold;
    //         }

    //         .invoice-page .invoice-detail-irn-section {
    //             display: flex;
    //             display: -webkit-flex;
    //         }

    //         .invoice-page .invoice-detail-irn-section>img {
    //             height: 127px;
    //             width: 127px;
    //         }

    //         .invoice-page .invoice-header {
    //             display: -webkit-flex;
    //             display: flex;
    //             justify-content: space-between;
    //             -webkit-justify-content: space-between;
    //             margin-top: 10px;
    //         }

    //         .invoice-page .invoice-head-table {
    //             overflow: hidden;
    //             border-radius: 2px;
    //             border-style: hidden;
    //             box-shadow: none;
    //             margin: auto 0 15px;
    //             border: none;
    //         }

    //         .invoice-page .invoice-irn-lineitem-section {
    //             margin: auto 0 16px;
    //         }

    //         .invoice-page .invoice-footer-table {
    //             margin-top: 20px;
    //         }

    //         .invoice-page .print-label {
    //             margin-left: auto !important;
    //             color: #516173 !important;
    //             background: #f3f5f7;
    //             -webkit-print-color-adjust: exact;
    //         }

    //         .invoice-page .invoice-head-table tbody th {
    //             text-align: left;
    //             vertical-align: top;
    //             color: rgba(0, 0, 0, 0.5);
    //         }

    //         .invoice-page .invoice-head-table th {
    //             padding: 5px 15px 5px 0;
    //             border: none;
    //         }

    //         .invoice-page .invoice-head-table td {
    //             padding: 5px 15px 5px 0;
    //             font-weight: bold;
    //             border: none;
    //         }

    //         .invoice-page .invoice-irn-lineitem-section tbody th {
    //             text-align: left;
    //             vertical-align: top;
    //             color: rgba(0, 0, 0, 0.5);
    //         }

    //         .invoice-page .invoice-irn-lineitem-section th {
    //             padding: 4px 16px 4px 0;
    //             border: none;
    //         }

    //         .invoice-page .invoice-irn-lineitem-section td {
    //             padding: 4px 16px 4px 0;
    //             font-weight: bold;
    //             border: none;
    //         }

    //         .invoice-page .address-section-wrapper {
    //             display: -webkit-flex;
    //             display: flex;
    //             margin-bottom: 10px;
    //         }

    //         .invoice-page .shipped-section-wrapper {
    //             display: -webkit-flex;
    //             display: flex;
    //             margin-bottom: 10px;
    //             justify-content: space-between;
    //             flex-wrap: wrap;
    //         }

    //         .invoice-page .cos-section-wrapper {
    //             display: -webkit-flex;
    //             display: flex;
    //         }

    //         .invoice-page .address-section-billed-by {
    //             -webkit-flex: 1;
    //             flex: 1;
    //             text-align: left;
    //             background: -webkit-var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             background: var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             padding: 15px;
    //             -webkit-print-color-adjust: exact;
    //             color-adjust: exact;
    //             margin-top: 10px;
    //             margin-right: 5px;
    //             border-radius: 6px;
    //         }

    //         .invoice-page .address-section-billed-to {
    //             -webkit-flex: 1;
    //             flex: 1;
    //             text-align: left;
    //             background: -webkit-var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             background: var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             padding: 15px;
    //             -webkit-print-color-adjust: exact;
    //             color-adjust: exact;
    //             margin-top: 10px;
    //             margin-left: 5px;
    //             border-radius: 6px;
    //         }

    //         .invoice-page .address-section-shipped-to {
    //             text-align: left;
    //             background: -webkit-var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             background: var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             padding: 15px;
    //             -webkit-print-color-adjust: exact;
    //             color-adjust: exact;
    //             margin-top: 10px;
    //             border-radius: 6px;
    //             width: 49.5%;
    //         }

    //         .invoice-page .address-section-shipped-from {
    //             text-align: left;
    //             background: -webkit-var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             background: var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             padding: 15px;
    //             -webkit-print-color-adjust: exact;
    //             color-adjust: exact;
    //             margin-top: 10px;
    //             border-radius: 6px;
    //             width: 49.5%;
    //         }

    //         .invoice-page .address-section-transport {
    //             text-align: left;
    //             background: -webkit-var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             background: var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             padding: 15px;
    //             -webkit-print-color-adjust: exact;
    //             color-adjust: exact;
    //             margin-top: 10px;
    //             border-radius: 6px;
    //             width: 49.5%;
    //         }

    //         .invoice-page .address-section-billed-by .billed-by-heading {
    //             line-height: 31px;
    //             margin-bottom: 0;
    //             font-family: 'OpenSans' !important;
    //             font-size: 1.5em !important;
    //             font-weight: 500;
    //             font-family: -webkit-var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //             font-family: var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //         }

    //         .primary-text.billed-to-heading {
    //             line-height: 31px;
    //             margin-bottom: 0;
    //             font-family: 'OpenSans' !important;
    //             font-size: 1.5em !important;
    //             font-weight: 500;
    //             font-family: -webkit-var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //             font-family: var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //         }

    //         .invoice-page .business-name {
    //             font-weight: bolder;
    //         }

    //         .invoice-page .address-section-billed-to .billed-to-heading {
    //             line-height: 31px;
    //             margin-bottom: 0;
    //             font-family: -webkit-var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //             font-family: var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //         }

    //         .invoice-page .address-section-shipped-to .shipped-to-heading {
    //             line-height: 31px;
    //             margin-bottom: 0;
    //             font-family: -webkit-var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //             font-family: var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //         }

    //         .invoice-page .address-section-shipped-from .shipped-to-heading {
    //             line-height: 31px;
    //             margin-bottom: 0;
    //             font-family: -webkit-var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //             font-family: var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //         }

    //         .invoice-page .address-section-transport .transport-heading {
    //             line-height: 31px;
    //             margin-bottom: 0;
    //             font-family: -webkit-var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //             font-family: var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //         }

    //         .invoice-page .address-section-billed-by .billed-by-address {
    //             display: flex;
    //             display: -webkit-flex;
    //             flex-direction: column;
    //             -webkit-flex-direction: column;
    //         }

    //         .invoice-page .address-section-billed-to .billed-to-address {
    //             display: flex;
    //             display: -webkit-flex;
    //             flex-direction: column;
    //             -webkit-flex-direction: column;
    //         }

    //         .invoice-page .address-section-shipped-to .shipped-to-address {
    //             display: flex;
    //             display: -webkit-flex;
    //             flex-direction: column;
    //             -webkit-flex-direction: column;
    //         }

    //         .invoice-page .address-section-shipped-from .shipped-from-address {
    //             display: flex;
    //             display: -webkit-flex;
    //             flex-direction: column;
    //             -webkit-flex-direction: column;
    //         }

    //         .invoice-page .invoice-transport-table {
    //             display: flex;
    //             width: 100% !important;
    //         }

    //         .invoice-page .address-section-transport .transport-detail {
    //             display: flex;
    //             display: -webkit-flex;
    //             flex-direction: column;
    //             -webkit-flex-direction: column;
    //         }

    //         .invoice-page .invoice-transport-table .invoice-transport-heading {
    //             font-weight: bold;
    //             width: 30%;
    //             line-height: 1.5;
    //         }

    //         .invoice-page .invoice-transport-table .invoice-transport-data {
    //             padding-left: 10px;
    //             width: 70%;
    //             line-height: 1.5;
    //         }

    //         .invoice-page .address-section-transport p {
    //             margin-bottom: 5px;
    //         }

    //         .invoice-page .cos-column {
    //             -webkit-flex: 1;
    //             flex: 1;
    //             text-align: left;
    //             -webkit-print-color-adjust: exact;
    //             color-adjust: exact;
    //             margin: 10px 0;
    //             text-align: center;
    //         }

    //         .invoice-page .cos-column:first-child {
    //             margin-right: 5px;
    //         }

    //         .invoice-page .cos-column:last-child {
    //             margin-left: 5px;
    //         }

    //         .invoice-page .primary-text {
    //             color: -webkit-var(--primary-color, rgba(101, 57, 192, 1));
    //             color: var(--primary-color, rgba(101, 57, 192, 1));
    //         }

    //         .invoice-page .ant-checkbox-wrapper .ant-checkbox {
    //             margin-right: 4px;
    //         }

    //         .invoice-page .ant-checkbox-wrapper span {
    //             padding: 0;
    //         }

    //         .invoice-page .secondary-text,
    //         .ant-checkbox-checked .ant-checkbox-inner {
    //             background-color: -webkit-var(--primary-color, rgba(101, 57, 192, 1));
    //             background-color: var(--primary-color, rgba(101, 57, 192, 1));
    //         }

    //         .invoice-page .logo-wrapper {
    //             text-align: center;
    //             margin: 0;
    //             display: -webkit-box;
    //             display: -ms-flexbox;
    //             display: flex;
    //             display: -webkit-flex;
    //             -webkit-box-pack: end;
    //             -ms-flex-pack: end;
    //             justify-content: flex-end;
    //             -webkit-justify-content: flex-end;
    //             -webkit-box-align: start;
    //             -webkit-align-items: flex-start;
    //             -ms-flex-align: start;
    //             align-items: flex-start;
    //         }

    //         .invoice-page .refrens-header-wrapper .invoice-title-detail-logo-wrapper {
    //             display: grid;
    //             grid-template-areas: 'title title . logo' 'detail detail . logo';
    //             row-gap: 10px;
    //         }

    //         .invoice-page .refrens-header-wrapper .invoice-heading {
    //             grid-area: title;
    //         }

    //         .invoice-page .refrens-header-wrapper .invoice-detail-section {
    //             grid-area: detail;
    //         }

    //         .invoice-page .refrens-header-wrapper .logo-wrapper {
    //             grid-area: logo;
    //             justify-self: end;
    //             align-self: start;
    //         }

    //         .invoice-page .logo-wrapper img {
    //             max-width: 240px;
    //             max-height: 120px;
    //             -o-object-fit: scale-down;
    //             object-fit: scale-down;
    //         }

    //         .invoice-page .logo-wrapper.signature {
    //             display: flex;
    //             display: -webkit-flex;
    //             flex-direction: column;
    //             -webkit-flex-direction: column;
    //             align-items: center;
    //             -webkit-align-items: center;
    //         }

    //         .invoice-page .invoice-total-in-words-wrapper {
    //             flex: 1;
    //             -webkit-flex: 1;
    //             font-weight: 600;
    //             padding-top: var(--sizes-smaller);
    //         }

    //         .invoice-page .invoice-total-in-words {
    //             text-transform: uppercase;
    //         }

    //         .invoice-page .invoice-bank-and-logo-wrapper {
    //             display: flex;
    //             display: -webkit-flex;
    //             flex-direction: row;
    //             justify-content: space-between;
    //             -webkit-flex-direction: row;
    //             -webkit-justify-content: space-between;
    //             margin-top: 10px;
    //         }

    //         .invoice-page .link-button {
    //             background: none;
    //             cursor: pointer;
    //         }

    //         .invoice-page .phone-input {
    //             direction: ltr;
    //         }

    //         .invoice-page .address-phone {
    //             display: flex;
    //         }

    //         .invoice-page .address-email {
    //             display: flex;
    //         }

    //         .invoice-page .invoice-bank-table {
    //             border: none;
    //         }

    //         .invoice-page .invoice-bank-table th {
    //             padding: 0 15px 0 0;
    //             border: none;
    //         }

    //         .invoice-page .invoice-bank-table td {
    //             border: none;
    //             padding: 0px 0px 4px 0px;
    //             width: 50%;
    //         }

    //         .invoice-page .invoice-terms {
    //             list-style: decimal !important;
    //             padding-left: 25px;
    //         }

    //         .invoice-page .invoice-payment-table {
    //             border: none;
    //         }

    //         .invoice-page .invoice-payment-table td {
    //             padding: 0 15px 5px 0;
    //             border: none;
    //         }

    //         .invoice-page .invoice-payment-table th {
    //             padding: 0 15px 5px 0;
    //             border: none;
    //         }

    //         .invoice-page .invoice-bank-upi-wrapper {
    //             display: grid;
    //             grid-template-columns: 3fr auto;
    //         }

    //         .invoice-page .invoice-tag {
    //             display: inline-block;
    //             color: rgb(255, 255, 255);
    //             font-size: 13px;
    //             white-space: nowrap;
    //             margin: 8px;
    //             font-weight: 500;
    //             padding: 1px 6px;
    //             border-radius: 4px;
    //             line-height: 20px;
    //             align-self: center;
    //         }

    //         .invoice-page .letterhead-wrapper {
    //             margin-left: -32px;
    //             margin-top: -32px;
    //             margin-bottom: 32px;
    //             margin-right: -32px;
    //         }

    //         .invoice-page .hash-header {
    //             display: flex;
    //             justify-content: flex-start;
    //             align-items: center;
    //             gap: 4px;
    //         }

    //         .invoice-page .letterhead-footer-wrapper {
    //             margin-left: -32px;
    //             margin-top: 32px;
    //             margin-bottom: -38px;
    //             margin-right: -32px;
    //         }

    //         .invoice-page .letterhead-image {
    //             width: 100%;
    //             height: auto;
    //         }

    //         .invoice-page .invoice-tag.success {
    //             background: #52c41a;
    //             -webkit-print-color-adjust: exact;
    //         }

    //         .invoice-page .invoice-tag.warning {
    //             background: #faad14;
    //             -webkit-print-color-adjust: exact;
    //         }

    //         .invoice-page .invoice-tag.danger {
    //             background: #ea453d;
    //             -webkit-print-color-adjust: exact;
    //         }

    //         .invoice-page .invoice-tag.info {
    //             background: #2db7f5;
    //             -webkit-print-color-adjust: exact;
    //         }

    //         .invoice-page .invoice-tag.devider {
    //             background: #e8e8e8;
    //         }

    //         .invoice-page .secondary-button {
    //             align-items: center;
    //             background: -webkit-var(--primary-background, rgb(254, 62, 130));
    //             background: var(--primary-background, rgb(254, 62, 130));
    //             border-bottom-left-radius: 4px;
    //             border-bottom-right-radius: 4px;
    //             color: rgb(255, 255, 255);
    //             cursor: pointer;
    //             display: inline-flex;
    //             font-size: 15px;
    //             font-weight: 500;
    //             height: 35px;
    //             justify-content: center;
    //             letter-spacing: normal;
    //             line-height: 19.5px;
    //             margin-bottom: 2px;
    //             margin-left: 2px;
    //             margin-right: 2px;
    //             margin-top: 2px;
    //             padding-bottom: 8px;
    //             padding-left: 16px;
    //             padding-right: 16px;
    //             padding-top: 8px;
    //             text-align: center;
    //             text-decoration: none;
    //         }

    //         .invoice-page .pay-button-wrapper {
    //             text-align: center;
    //         }

    //         .invoice-page .pay-button-wrapper>small {
    //             color: rgba(0, 0, 0, 0.5);
    //         }

    //         .invoice-page .total-wrapper {
    //             display: flex;
    //             display: -webkit-flex;
    //             margin-top: 10px;
    //         }

    //         .invoice-page .invoice-bank-wrapper {
    //             display: -webkit-flex;
    //             display: flex;
    //             flex-direction: column;
    //             -webkit-flex-direction: column;
    //             padding: 10px 20px 0 20px;
    //             border-radius: 6px;
    //             margin-right: 13px;
    //             background-color: var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             -webkit-print-color-adjust: exact;
    //         }

    //         .invoice-page .invoice-upi-wrapper {
    //             display: -webkit-flex;
    //             display: flex;
    //             flex-direction: column;
    //             -webkit-flex-direction: column;
    //             text-align: center;
    //         }

    //         .invoice-page .invoice-upi-wrapper>button {
    //             background: none;
    //         }

    //         .invoice-page .invoice-upi-wrapper .upi-heading {
    //             font-weight: 500;
    //             font-size: 13px;
    //             font-family: -webkit-var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //             font-family: var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //         }

    //         .invoice-page .invoice-bank-wrapper .bank-heading {
    //             font-weight: 500;
    //             font-size: 16.38px;
    //             font-family: -webkit-var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //             font-family: var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //         }

    //         .invoice-page .qr-wrapper {
    //             display: -webkit-flex;
    //             display: flex;
    //             flex-direction: column;
    //             -webkit-flex-direction: column;
    //         }

    //         .invoice-page .qr-wrapper>img {
    //             margin: 0 auto;
    //         }

    //         .invoice-page .qr-wrapper>span {
    //             font-size: 10px;
    //             max-width: 120px;
    //             margin: 0 auto;
    //         }

    //         .invoice-page .shrinkable-upi {
    //             word-break: break-all;
    //         }

    //         .invoice-page .unshrinkable-upi {
    //             word-break: normal;
    //         }

    //         .invoice-page .invoice-table {
    //             overflow: hidden;
    //             border-radius: 2px;
    //             border-style: hidden;
    //             box-shadow: none;
    //             margin-bottom: 10px;
    //         }

    //         .invoice-page.invoice-table tbody th {
    //             text-align: left;
    //             vertical-align: top;
    //         }

    //         .invoice-page .invoice-items-table {
    //             border: 0;
    //             box-shadow: none;
    //             overflow: hidden;
    //             border-style: hidden;
    //             margin: 0 0 10px;
    //             width: 100%;
    //             border-radius: 6px;
    //             border-color: -webkit-var(--primary-color, rgba(101, 57, 192, 1));
    //             border-color: var(--primary-color, rgba(101, 57, 192, 1));
    //             -webkit-print-color-adjust: exact;
    //             color-adjust: exact;
    //             box-shadow: 0 0 0 1px rgba(101, 57, 192, 0.1);
    //         }

    //         .invoice-page .invoice-items-table .description .toastui-editor-contents p {
    //             margin-block: 0;
    //         }

    //         .invoice-page .invoice-items-table td.description,
    //         .invoice-page .invoice-items-table td.desc-column {
    //             padding-block-start: 0;
    //         }

    //         .invoice-page .invoice-hsn-table-wrapper {
    //             margin-top: 32px;
    //             page-break-inside: avoid;
    //         }

    //         .invoice-page .invoice-hsn-table {
    //             border: 1px solid #000;
    //             box-shadow: none;
    //             margin: 0;
    //             width: 100%;
    //             border-radius: 6px;
    //             -webkit-print-color-adjust: exact;
    //             color-adjust: exact;
    //         }

    //         .invoice-page .invoice-hsn-table th,
    //         .invoice-page .invoice-hsn-table td {
    //             padding: 5px 5px 5px 10px;
    //             border: 1px solid #000;
    //             text-align: center;
    //             vertical-align: middle;
    //             font-family: -webkit-var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //             font-family: var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //         }

    //         .invoice-page .invoice-tax-total-in-words-wrapper {
    //             flex: 1;
    //             -webkit-flex: 1;
    //             font-weight: 600;
    //             padding: 10px;
    //             border: 1px solid #000;
    //             border-top: 0;
    //         }

    //         .invoice-page .invoice-tax-total-in-words {
    //             text-transform: uppercase;
    //         }

    //         .invoice-page .invoice-items-table th {
    //             color: #fff;
    //             text-shadow: 0 0 #fff;
    //             background: -webkit-var(--primary-background, rgba(101, 57, 192, 1));
    //             background: var(--primary-background, rgba(101, 57, 192, 1));
    //             padding: 8px;
    //             border: 0;
    //             border-color: -webkit-var(--primary-color, rgba(101, 57, 192, 1));
    //             border-color: var(--primary-color, rgba(101, 57, 192, 1));
    //             text-align: center;
    //             font-family: -webkit-var(--title-font, 'OpenSans');
    //             font-family: var(--title-font, 'OpenSans');
    //         }

    //         .invoice-page .invoice-items-table td {
    //             padding: 8px;
    //             border: 0;
    //             background: -webkit-var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             background: var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             vertical-align: middle;
    //             text-align: center;
    //             white-space: nowrap;
    //         }

    //         .invoice-page .large-item-row.description {
    //             word-break: break-word;
    //         }

    //         .invoice-page .invoice-items-table td:first-child {
    //             padding-left: 8px;
    //         }

    //         .invoice-page .invoice-items-table td:nth-child(2) {
    //             white-space: normal;
    //         }

    //         .invoice-page .invoice-items-table input,
    //         .invoice-page .invoice-items-table textarea {
    //             padding: 4px 0;
    //             font-size: 13px;
    //             line-height: 20px;
    //             min-height: 40px;
    //         }

    //         .invoice-page .custom-column {
    //             white-space: normal !important;
    //         }

    //         .invoice-page .invoice-totals-table {
    //             width: 100%;
    //             border: none;
    //         }

    //         .invoice-page .invoice-extra-total-table {
    //             width: 100%;
    //             border: none;
    //         }

    //         .invoice-page .invoice-extra-total-table td,
    //         .invoice-page .invoice-extra-total-table th {
    //             padding: 2px 15px;
    //             border: none;
    //         }

    //         .invoice-page .invoice-extra-total-table td:last-child,
    //         .invoice-page .invoice-extra-total-table th:last-child {
    //             text-align: right;
    //         }

    //         .invoice-page .invoice-total-amount-table tr:last-child th,
    //         .invoice-page .invoice-total-amount-table tr:last-child td {
    //             border-top: solid 1px black;
    //             border-bottom: solid 1px black;
    //             font-size: 20px;
    //             font-weight: 600;
    //         }

    //         .invoice-page .invoice-totals-due-table tr:not(:first-child) th,
    //         .invoice-page .invoice-totals-due-table tr:not(:first-child) td {
    //             font-size: 13px;
    //             font-weight: 400;
    //             color: #516173;
    //         }

    //         .invoice-page .invoice-totals-due-table tr:not(:first-child) th {
    //             padding-left: 8px;
    //         }

    //         .invoice-page .invoice-totals-due-table tr:last-child th.due-amount,
    //         .invoice-page .invoice-totals-due-table tr:last-child td.due-amount {
    //             font-size: 18px;
    //             font-weight: 600;
    //             color: #161c26;
    //             padding-left: 0px;
    //         }

    //         .invoice-page .invoice-totals-table td,
    //         .invoice-page .invoice-totals-table th {
    //             padding: 8px 16px 8px 0px;
    //             border: none;
    //             font-weight: 400;
    //         }

    //         .invoice-page .invoice-totals-table td:last-child,
    //         .invoice-page .invoice-totals-table th:last-child {
    //             text-align: right;
    //         }

    //         .invoice-page .invoice-totals-due-table .invoice-totals-table-amount td,
    //         .invoice-page .invoice-totals-due-table .invoice-totals-table-amount th {
    //             font-size: 13px !important;
    //             font-weight: 400 !important;
    //             color: #201742 !important;
    //             padding-left: 0px !important;
    //         }

    //         .invoice-page .item-name-row {
    //             display: none;
    //         }

    //         .invoice-page .hide-background>td {
    //             background: none;
    //         }

    //         .invoice-page .item-name-row.full-width {
    //             display: table-row !important;
    //         }

    //         .invoice-page .item-name-row>td {
    //             text-align: left !important;
    //             white-space: normal;
    //         }

    //         .invoice-page .item-group-row>td {
    //             font-weight: bolder;
    //         }

    //         .invoice-page .small-item-row {
    //             display: none;
    //         }

    //         .invoice-page .small-item-row td:first-child {
    //             width: auto;
    //         }

    //         .invoice-page .large-item-row th:first-child,
    //         .invoice-page .large-item-row th:nth-child(2) {
    //             text-align: left;
    //         }

    //         .invoice-page .large-item-row td:first-child,
    //         .invoice-page .large-item-row td:nth-child(2) {
    //             text-align: left;
    //         }

    //         .invoice-page .large-item-row.gst-invoice td:first-child,
    //         .invoice-page .large-item-row.gst-invoice th:first-child,
    //         .invoice-page .large-item-row.gst-invoice td:nth-child(2),
    //         .invoice-page .large-item-row.gst-invoice th:nth-child(2) {
    //             display: table-cell;
    //         }

    //         .invoice-page .large-item-row.full-width td:first-child,
    //         .invoice-page .large-item-row.full-width th:first-child,
    //         .invoice-page .large-item-row.full-width td:nth-child(2),
    //         .invoice-page .large-item-row.full-width th:nth-child(2) {
    //             display: none !important;
    //         }

    //         .invoice-page .large-item-row.description td,
    //         .invoice-page .item-description td {
    //             text-align: left;
    //         }

    //         .invoice-page .images td {
    //             text-align: left;
    //         }

    //         .invoice-page .images {
    //             text-align: end !important;
    //         }

    //         .invoice-page .images img {
    //             height: 64px;
    //             width: 64px;
    //             object-fit: contain;
    //             margin-right: 8px;
    //         }

    //         .invoice-page .original-images img {
    //             height: auto;
    //             width: 100%;
    //             object-fit: contain;
    //             margin-top: 20px;
    //             margin-right: 8px;
    //         }

    //         .invoice-page .original-images td {
    //             text-align: left;
    //             white-space: pre-wrap;
    //         }

    //         .invoice-page .original-images-box {
    //             display: flex;
    //             flex-wrap: wrap;
    //         }

    //         .invoice-page .original-image-wrapper {
    //             height: auto;
    //             width: auto;
    //             position: relative;
    //             margin: 0.5rem 1rem 1rem 0;
    //             page-break-inside: avoid;
    //             break-inside: avoid;
    //         }

    //         .invoice-page .early-pay-wrapper {
    //             background-image: url('/public/images/invoice/earlypay/bannerbg.png');
    //             margin: -10px -10px 20px -10px;
    //             padding: 32px;
    //             color: #fff;
    //         }

    //         .invoice-page .early-pay-wrapper p {
    //             margin-top: 8px;
    //         }

    //         .invoice-page .center-align-text {
    //             text-align: center;
    //         }

    //         .invoice-page .early-pay-heading {
    //             display: inline-flex;
    //         }

    //         .invoice-page .early-pay-heading svg {
    //             width: 48px;
    //             height: 48px;
    //             margin-left: -5px;
    //         }

    //         .invoice-page .early-pay-heading>div {
    //             display: flex;
    //             align-items: center;
    //             text-align: left;
    //         }

    //         .invoice-page .early-pay-heading>div>strong {
    //             display: inline-block;
    //             padding-left: 5px;
    //             font-size: 22px;
    //             font-weight: 600;
    //         }

    //         .invoice-page .large-text {
    //             font-size: 16px;
    //             display: inline-block;
    //             margin-bottom: 4px;
    //         }

    //         .invoice-page .large-text>span {
    //             font-size: 22px;
    //         }

    //         .invoice-page .small-text {
    //             font-size: 13px;
    //             opacity: 0.7;
    //             display: inline-block;
    //             margin-bottom: 4px;
    //         }

    //         .invoice-page .invoice-status {
    //             display: flex;
    //         }

    //         .invoice-page .invoice-status>span {
    //             font-size: 13px !important;
    //             margin-right: 8px !important;
    //             font-weight: 500 !important;
    //         }

    //         .invoice-page .responsive-image {
    //             max-width: 100%;
    //             max-height: 100%;
    //             height: auto;
    //         }

    //         .invoice-page .responsive-qr-code {
    //             max-width: 120px;
    //             max-height: 120px;
    //             height: auto;
    //         }

    //         .invoice-page .page-footer {
    //             width: 100%;
    //             text-align: center;
    //             font-size: 10px;
    //         }

    //         .invoice-page .attachment-link {
    //             text-align: left;
    //             margin-bottom: 4px;
    //             background: none;
    //             display: flex;
    //             display: -webkit-flex;
    //         }

    //         .invoice-page .bank-total-wrapper {
    //             display: -webkit-flex;
    //             display: flex;
    //             justify-content: space-between;
    //             -webkit-justify-content: space-between;
    //             page-break-inside: avoid;
    //         }

    //         .invoice-page .bank-total-wrapper .bank-words-wrapper {
    //             display: -webkit-flex;
    //             display: flex;
    //             flex-direction: column;
    //             -webkit-flex-direction: column;
    //             flex-wrap: wrap;
    //             max-width: 60%;
    //         }

    //         .invoice-page .bank-total-wrapper .total-signature-wrapper {
    //             display: -webkit-flex;
    //             display: flex;
    //             flex-direction: column;
    //             -webkit-flex-direction: column;
    //             width: 40%;
    //             margin-left: 24px;
    //         }

    //         .invoice-page .bank-total-wrapper .bank-words-wrapper .invoice-total-in-words-wrapper {
    //             margin-bottom: 30px;
    //             font-weight: 600;
    //         }

    //         .invoice-page .attachment-wrapper {
    //             margin-top: 20px;
    //         }

    //         .invoice-page .invoice-notes-wrapper {
    //             margin-top: 20px;
    //         }

    //         .invoice-page .invoice-notes-wrapper p {
    //             orphans: 3;
    //             widows: 3;
    //         }

    //         .invoice-page .invoice-terms-wrapper {
    //             margin-top: 20px;
    //         }

    //         .invoice-page .invoice-payments-wrapper {
    //             margin-top: 20px;
    //         }

    //         .invoice-page .terms-heading {
    //             margin-bottom: 0.2rem;
    //             font-size: 16.38px;
    //             font-family: -webkit-var(--title-font, 'OpenSans');
    //             font-family: var(--title-font, 'OpenSans');
    //             page-break-inside: avoid;
    //         }

    //         .invoice-page .terms-heading::after {
    //             content: '';
    //             display: block;
    //             height: 80px;
    //             margin-bottom: -80px;
    //         }

    //         .invoice-page .invoice-terms>li {
    //             page-break-inside: avoid;
    //         }

    //         .invoice-page .payments-heading {
    //             margin-bottom: 0.2rem;
    //             font-size: 16.38px;
    //             font-family: -webkit-var(--title-font, 'OpenSans');
    //             font-family: var(--title-font, 'OpenSans');
    //             page-break-inside: avoid;
    //         }

    //         .invoice-page .payments-heading::after {
    //             content: '';
    //             display: block;
    //             height: 80px;
    //             margin-bottom: -80px;
    //         }

    //         .invoice-page .notes-heading {
    //             margin-bottom: 0.2rem;
    //             font-size: 16.38px;
    //             font-family: -webkit-var(--title-font, 'OpenSans');
    //             font-family: var(--title-font, 'OpenSans');
    //             page-break-inside: avoid;
    //         }

    //         .invoice-page .notes-heading::after {
    //             content: '';
    //             display: block;
    //             height: 80px;
    //             margin-bottom: -80px;
    //         }

    //         .invoice-page .attachment-heading {
    //             margin-bottom: 0.2rem;
    //             font-size: 16.38px;
    //             font-family: -webkit-var(--title-font, 'OpenSans');
    //             font-family: var(--title-font, 'OpenSans');
    //         }

    //         .invoice-page .attachment-link-wrapper {
    //             display: -webkit-flex;
    //             display: flex;
    //             flex-direction: column;
    //             -webkit-flex-direction: column;
    //         }

    //         .invoice-page .invoice-contact-wrapper {
    //             display: flex;
    //             justify-content: center;
    //             text-align: center;
    //             margin-top: 20px;
    //         }

    //         .invoice-page .address-section-transport .extra {
    //             margin-top: 10px;
    //         }

    //         .invoice-page .link {
    //             background: none;
    //         }

    //         /* Media Query */

    //         @media print {
    //             .no-pdf {
    //                 display: none;
    //             }

    //             .show-in-pdf {
    //                 display: block !important;
    //             }

    //             .invoice-page .early-pay-wrapper {
    //                 display: none;
    //             }

    //             .invoice-page .letterhead-first-page-wrapper {
    //                 margin-top: -42px;
    //             }

    //             .invoice-page .letterhead-footer-last-page-wrapper {
    //                 margin-top: 5px;
    //             }

    //             .invoice-page .letterhead-first-page {
    //                 height: 180px;
    //                 margin-top: 25px;
    //             }

    //             .invoice-page .letterhead-footer-last-page {
    //                 height: 180px;
    //                 margin-top: 15px;
    //                 margin-bottom: 20px;
    //             }

    //             .invoice-page .invoice-bank-wrapper {
    //                 margin-top: 10px;
    //                 padding: 10px 20px 0 20px;
    //                 border-radius: 6px;
    //                 margin-right: 13px;
    //                 margin-bottom: 24px;
    //                 -webkit-print-color-adjust: exact;
    //                 color-adjust: exact;
    //                 background-color: var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             }

    //             .invoice-page .invoice-bank-wrapper .bank-heading>button {
    //                 display: none;
    //             }

    //             .invoice-page .invoice-upi-wrapper .upi-heading>button {
    //                 display: none;
    //             }

    //             .invoice-page .invoice-payments-wrapper {
    //                 page-break-inside: avoid;
    //                 break-inside: avoid;
    //             }

    //             .invoice-page .invoice-payment-table-scroll {
    //                 page-break-inside: avoid;
    //                 break-inside: avoid;
    //             }

    //             .invoice-page .invoice-payment-table tr {
    //                 page-break-inside: avoid;
    //                 break-inside: avoid;
    //             }

    //             .ant-message {
    //                 display: none !important;
    //             }

    //             .invoice-page .invoice-terms-wrapper {
    //                 page-break-inside: avoid;
    //             }
    //         }

    //         @media screen and (max-width: 768px) {
    //             .invoice-page .invoice-bank-upi-wrapper {
    //                 grid-template-columns: 1fr;
    //                 grid-gap: 16px;
    //             }

    //             .invoice-page .invoice-upi-wrapper {
    //                 align-items: flex-start;
    //                 justify-content: center;
    //                 text-align: left;
    //             }
    //         }

    //         @media screen and (max-width: 568px) {
    //             .invoice-page .refrens-header-wrapper .invoice-title-detail-logo-wrapper {
    //                 grid-template-areas: 'title .' 'logo .' 'detail .';
    //             }

    //             .invoice-page .refrens-header-wrapper .logo-wrapper {
    //                 justify-self: start;
    //             }

    //             .invoice-page .address-section-wrapper {
    //                 flex-direction: column;
    //                 margin-bottom: 0;
    //             }

    //             .invoice-page .shipped-section-wrapper {
    //                 flex-direction: column;
    //             }

    //             .invoice-page .invoice-bank-and-logo-wrapper {
    //                 flex-direction: column-reverse;
    //                 justify-content: space-between;
    //             }

    //             .invoice-page .small-item-row {
    //                 display: table-row;
    //             }

    //             .invoice-page .small-item-row.full-width td {
    //                 padding-top: 0;
    //             }

    //             .invoice-page .small-item-row.full-width td:first-child {
    //                 font-weight: 600;
    //             }

    //             .invoice-page .invoice-items-table td {
    //                 text-align: left;
    //             }

    //             .invoice-page .invoice-items-table th {
    //                 text-align: left;
    //             }

    //             .invoice-page .large-item-row {
    //                 display: none;
    //             }

    //             .invoice-page .large-item-row.gst-invoice td:first-child,
    //             .invoice-page .large-item-row.gst-invoice th:first-child,
    //             .invoice-page .large-item-row.gst-invoice td:nth-child(2),
    //             .invoice-page .large-item-row.gst-invoice th:nth-child(2) {
    //                 display: none;
    //             }

    //             .invoice-page .early-pay-heading>div>strong {
    //                 font-size: 28px;
    //             }

    //             .invoice-page .invoice-bank-table th {
    //                 padding-right: 10px;
    //             }

    //             .invoice-page .invoice-head-table {
    //                 width: 100%;
    //             }

    //             .invoice-page .invoice-head-table th {
    //                 padding-right: 0;
    //             }

    //             .invoice-page .invoice-irn-lineitem-section {
    //                 width: 100%;
    //             }

    //             .invoice-page .invoice-irn-lineitem-section th {
    //                 padding-right: 10px;
    //             }

    //             .invoice-page .invoice-irn-lineitem-section td {
    //                 word-break: break-word;
    //             }

    //             .invoice-page .invoice-header .logo-wrapper {
    //                 margin-top: 0;
    //             }

    //             .invoice-page .bank-total-wrapper {
    //                 flex-direction: column-reverse;
    //             }

    //             .invoice-page .invoice-bank-wrapper {
    //                 margin-top: 10px;
    //                 padding: 10px 20px 0 20px;
    //                 border-radius: 6px;
    //                 -webkit-print-color-adjust: exact;
    //                 background-color: var(--secondary-background, rgba(101, 57, 192, 0.1));
    //             }

    //             .invoice-page .invoice-upi-wrapper {
    //                 margin-left: 0;
    //                 margin-top: 10px;
    //                 text-align: left;
    //             }

    //             .invoice-page .qr-wrapper>img {
    //                 margin: 0;
    //             }

    //             .invoice-page .invoice-upi-wrapper>button {
    //                 text-align: left;
    //                 padding-left: 10px;
    //             }

    //             .invoice-page .logo-wrapper img {
    //                 max-width: 180px;
    //             }

    //             .invoice-page .item-name-row {
    //                 display: table-row;
    //             }

    //             .invoice-page .address-section-billed-by {
    //                 margin-right: 0;
    //             }

    //             .invoice-page .address-section-billed-to {
    //                 margin-left: 0;
    //             }

    //             .invoice-page .address-section-shipped-to {
    //                 margin-right: 0;
    //                 width: 100%;
    //             }

    //             .invoice-page .address-section-shipped-from {
    //                 margin-right: 0;
    //                 width: 100%;
    //             }

    //             .invoice-page .address-section-transport {
    //                 width: 100%;
    //             }

    //             .invoice-page .invoice-payment-table-scroll {
    //                 overflow-x: auto;
    //                 padding: 0;
    //             }

    //             .invoice-page .bank-total-wrapper .total-signature-wrapper {
    //                 width: 100%;
    //                 margin-left: 0;
    //             }

    //             .invoice-page .bank-total-wrapper .bank-words-wrapper {
    //                 width: 100%;
    //                 max-width: unset;
    //             }
    //         }

    //         .bank-detail-label {
    //             word-break: normal;
    //         }

    //         .invoice-page .invoice-payment-table {
    //             border: none;
    //             margin-bottom: 0;
    //         }

    //         .large-item-row.full-remaining-width td.desc-column div {
    //             max-width: 100%;
    //             word-break: break-word;
    //         }

    //         @media screen and (max-width: 992px) {
    //             .invoice-page .invoice-items-table-wrapper {
    //                 overflow-x: auto;
    //                 padding: 0 1px;
    //             }

    //             .invoice-page .item-name-row.gst-invoice {
    //                 display: table-row;
    //             }

    //             .invoice-page .large-item-row.gst-invoice td:first-child,
    //             .invoice-page .large-item-row.gst-invoice th:first-child,
    //             .invoice-page .large-item-row.gst-invoice td:nth-child(2),
    //             .invoice-page .large-item-row.gst-invoice th:nth-child(2) {
    //                 display: none;
    //             }
    //         }

    //         @media screen and (max-width: 768px) {

    //             .invoice-page .large-item-row.gst-invoice td:first-child,
    //             .invoice-page .large-item-row.gst-invoice th:first-child,
    //             .invoice-page .large-item-row.gst-invoice td:nth-child(2),
    //             .invoice-page .large-item-row.gst-invoice th:nth-child(2) {
    //                 display: none;
    //             }
    //         }

    //         @media screen and (min-width: 992px) and (max-width: 1200px) {
    //             .invoice-page .item-name-row.aside-collpased {
    //                 display: none;
    //             }

    //             .invoice-page .large-item-row.aside-collpased td:first-child,
    //             .invoice-page .large-item-row.aside-collpased th:first-child,
    //             .invoice-page .large-item-row.aside-collpased td:nth-child(2),
    //             .invoice-page .large-item-row.aside-collpased th:nth-child(2) {
    //                 display: table-cell;
    //             }
    //         }

    //         @media (min-width: 768px) {
    //             .invoice-page .early-pay-wrapper {
    //                 margin: -48px -48px 20px -48px;
    //             }
    //         }

    //         .invoice-page .address-section-billed-by .billed-by-heading {
    //             line-height: 31px;
    //             margin-bottom: 0;
    //             font-family: -webkit-var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //             font-family: var(--title-font, 'OpenSans'), var(--fallback-fonts);
    //         }

    //         .invoice-page .primary-text {
    //             color: -webkit-var(--primary-color, rgba(101, 57, 192, 1));
    //             color: var(--primary-color, rgba(101, 57, 192, 1));
    //         }

    //         .gncDlO h2 {
    //             font-size: 1.5em;
    //         }
    //     </style>
    // </head>
    // <link href="https://fonts.googleapis.com/css2?family=OpenSans&amp;family=Inter" rel="stylesheet">

    // <body>
    //     <div elevation="1" class="sc-129tbm5-0 sc-1iti5zm-0 hJpunl">
    //         <div class="sc-gbydel-0 gncDlO">

    //             <div class="invoice-page aside-collpased">
    //                 <div>
    //                     <div class="refrens-header-wrapper">
    //                         <div class="invoice-title-detail-logo-wrapper">
    //                             <div class="invoice-heading">
    //                                 <div class="invoice-title"><span>Quotation</span><span
    //                                         class="invoice-tag warning no-print" color="warning">Created</span></div>
    //                                 <div class="invoice-status"></div>
    //                             </div>
    //                             <div class="invoice-detail-section">
    //                                 <table border="0" class="invoice-table invoice-head-table">
    //                                     <tbody>
    //                                         <tr>
    //                                             <th class="hash-header">Quotation No<div class="false">#</div>
    //                                             </th>
    //                                             <td>A00001</td>
    //                                         </tr>
    //                                         <tr>
    //                                             <th>Quotation Date</th>
    //                                             <td>
    //                                                 <div><span>Mar 27, 2024</span></div>
    //                                             </td>
    //                                         </tr>
    //                                     </tbody>
    //                                 </table>
    //                             </div>
    //                             <div class="logo-wrapper"><img class="responsive-image" alt=""
    //                                     src="https://og.refrens.com/img/660409599df7a20012798b9e/6604098999608400193a9d2f/ref1711540662128.png?fq=eyJ3IjoyMjAsImgiOjEyMCwiYnVja2V0IjoicmVmcmVucy5pbWFnZXMiLCJfX3YiOjJ9"
    //                                     srcset="https://og.refrens.com/img/660409599df7a20012798b9e/6604098999608400193a9d2f/ref1711540662128.png?fq=eyJ3IjoyMjAsImgiOjEyMCwiYnVja2V0IjoicmVmcmVucy5pbWFnZXMiLCJfX3YiOjJ9 220w, https://og.refrens.com/img/660409599df7a20012798b9e/6604098999608400193a9d2f/ref1711540662128.png?fq=eyJ3IjoxODAsImgiOjEwMCwiYnVja2V0IjoicmVmcmVucy5pbWFnZXMiLCJfX3YiOjJ9 180w">
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div class="address-section-wrapper">
    //                         <div class="address-section-billed-by">
    //                             <h2 class="primary-text billed-by-heading">Quotation From</h2><b>VPInd</b>
    //                             <div class="billed-by-address">
    //                                 <div class="billed-by-sub-address"><span>India&nbsp;</span></div>
    //                             </div>
    //                         </div>
    //                         <div class="address-section-billed-to">
    //                             <h2 class="primary-text billed-to-heading">Quotation For</h2><b>SXP</b>
    //                             <div class="billed-to-address">
    //                                 <div class="billed-to-sub-address"><span>India&nbsp;</span></div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div class="shipped-section-wrapper"></div>
    //                     <div class="invoice-items-table-wrapper">
    //                         <table class="invoice-table invoice-items-table" cellpadding="0" cellspacing="0">
    //                             <thead>
    //                                 <tr class="no-pdf small-item-row">
    //                                     <th>Qty</th>
    //                                     <th>Rate</th>
    //                                     <th>Amount</th>
    //                                 </tr>
    //                                 <tr invoicetype="BOS" class="large-item-row aside-collpased">
    //                                     <th width="10" aria-label="Item Number"></th>
    //                                     <th>Item</th>
    //                                     <th>Quantity</th>
    //                                     <th>Rate</th>
    //                                     <th>Amount</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr class="no-pdf item-name-row aside-collpased">
    //                                     <td colspan="100"><b>1.</b> <span class="">Iphone 15</span></td>
    //                                 </tr>
    //                                 <tr class="no-pdf small-item-row">
    //                                     <td>12</td>
    //                                     <td>₹1,00,000</td>
    //                                     <td>₹12,00,000</td>
    //                                 </tr>
    //                                 <tr class="large-item-row aside-collpased">
    //                                     <td>1.</td>
    //                                     <td class="" width="250"><span class="">Iphone 15</span></td>
    //                                     <td class="" width="10">12</td>
    //                                     <td class="" width="10">₹1,00,000</td>
    //                                     <td class="" width="10">₹12,00,000</td>
    //                                 </tr>
    //                                 <tr class="no-pdf item-name-row aside-collpased hide-background">
    //                                     <td colspan="100"><b>2.</b> <span class="">Samsung ultra 22</span></td>
    //                                 </tr>
    //                                 <tr class="no-pdf small-item-row hide-background">
    //                                     <td>1</td>
    //                                     <td>₹7,80,000</td>
    //                                     <td>₹7,80,000</td>
    //                                 </tr>
    //                                 <tr class="large-item-row aside-collpased hide-background">
    //                                     <td>2.</td>
    //                                     <td class="" width="250"><span class="">Samsung ultra 22</span></td>
    //                                     <td class="" width="10">1</td>
    //                                     <td class="" width="10">₹7,80,000</td>
    //                                     <td class="" width="10">₹7,80,000</td>
    //                                 </tr>
    //                                 <tr class="no-pdf item-name-row aside-collpased">
    //                                     <td colspan="100"><b>3.</b> <span class="">Samsung ultra 23</span></td>
    //                                 </tr>
    //                                 <tr class="no-pdf small-item-row">
    //                                     <td>1</td>
    //                                     <td>₹89,99,999</td>
    //                                     <td>₹89,99,999</td>
    //                                 </tr>
    //                                 <tr class="large-item-row aside-collpased">
    //                                     <td>3.</td>
    //                                     <td class="" width="250"><span class="">Samsung ultra 23</span></td>
    //                                     <td class="" width="10">1</td>
    //                                     <td class="" width="10">₹89,99,999</td>
    //                                     <td class="" width="10">₹89,99,999</td>
    //                                 </tr>
    //                                 <tr class="no-pdf item-name-row aside-collpased hide-background">
    //                                     <td colspan="100"><b>4.</b> <span class="">Samsung ultra 24</span></td>
    //                                 </tr>
    //                                 <tr class="no-pdf small-item-row hide-background">
    //                                     <td>1</td>
    //                                     <td>₹1,00,000</td>
    //                                     <td>₹1,00,000</td>
    //                                 </tr>
    //                                 <tr class="large-item-row aside-collpased hide-background">
    //                                     <td>4.</td>
    //                                     <td class="" width="250"><span class="">Samsung ultra 24</span></td>
    //                                     <td class="" width="10">1</td>
    //                                     <td class="" width="10">₹1,00,000</td>
    //                                     <td class="" width="10">₹1,00,000</td>
    //                                 </tr>
    //                                 <tr class="no-pdf item-name-row aside-collpased strong">
    //                                     <td colspan="100"> <span class="">Total</span></td>
    //                                 </tr>
    //                                 <tr class="no-pdf small-item-row">
    //                                     <td>15</td>
    //                                     <td>₹0</td>
    //                                     <td>₹1,10,79,999</td>
    //                                 </tr>
    //                                 <tr class="large-item-row aside-collpased strong">
    //                                     <td></td>
    //                                     <td class="" width="250"><span class="">Total</span></td>
    //                                     <td class="" width="10">15</td>
    //                                     <td class="" width="10"></td>
    //                                     <td class="" width="10">₹1,10,79,999</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </div>
    //                     <div class="bank-total-wrapper">
    //                         <div class="bank-words-wrapper"></div>
    //                         <div class="total-signature-wrapper">
    //                             <div class="invoice-total-calculation">
    //                                 <table border="0" class="invoice-table invoice-totals-table invoice-total-amount-table">
    //                                     <tbody>
    //                                         <tr>
    //                                             <th>Total (INR)</th>
    //                                             <td>₹1,10,79,999</td>
    //                                         </tr>
    //                                     </tbody>
    //                                 </table>
    //                                 <table border="0" class="invoice-table invoice-extra-total-table">
    //                                     <tbody>
    //                                         <tr>
    //                                             <th>Tax</th>
    //                                             <td>23%</td>
    //                                         </tr>
    //                                     </tbody>
    //                                 </table>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>

    // </body>

    // </html>`;
    //       console.log("🚀 ~ handleQuoteDownload ~ htmlContent1:", htmlContent1);
    //       let previewPage = this.$refs["preview-page-container"];
    //       console.log("🚀 ~ handleQuoteDownload ~ previewPage:", previewPage);
    //       console.log("🚀 ~ handleQuoteDownload ~ previewPage:", previewPage);
    //       // let pdf = html2pdf().from(previewPage).save()
    //       let pdf = html2pdf()
    //         .from(previewPage)
    //         .save(this.quoteData.description + ".pdf");
    //       console.log("🚀 ~ handleQuoteDownload ~ pdf:", pdf);
    //       // Generate PDF content
    //       // const pdfContent = 'Your PDF content here';

    //       // Create a Blob from the PDF content
    //       // const blob = new Blob([pdf], { type: 'application/pdf' });

    //       // // Create a download link
    //       // const url = window.URL.createObjectURL(blob);

    //       // // Create an anchor element
    //       // const a = document.createElement('a');
    //       // a.href = url;
    //       // a.download = this.quoteData.discountPercent+'.pdf'; // Set the filename

    //       // // Append the anchor element to the DOM
    //       // document.body.appendChild(a);

    //       // // Trigger the download
    //       // a.click();

    //       // // Cleanup
    //       // window.URL.revokeObjectURL(url);
    //       // document.body.removeChild(a);
    //       //   console.log("🚀 ~ downloadPdf ~ pdf:", pdf)
    //       // Create new jsPDF instance
    //       // const doc = new jsPDF();
    //       // console.log("🚀 ~ handleQuoteDownload ~ doc:", doc)

    //       // // Get HTML content to be converted to PDF
    //       // // const content = document.getElementById('contentToPdf');

    //       // // Options for pdf settings
    //       // const options = {
    //       //   background: 'white',
    //       //   scale: 3 // Increase scale factor for better quality
    //       // };

    //       // // Convert HTML to PDF
    //       // doc.html(previewPage, options, (docs) => {
    //       //   console.log("🚀 ~ doc.html ~ docs:", docs)
    //       //   // Save PDF
    //       //   console.log("🚀 ~ doc.html ~ doc.save('document.pdf'):", doc.save('document.pdf'))
    //       //   doc.save('document.pdf');
    //       // });
    //     },
    async handleManagerSignature(signature) {
      console.log("🚀 ~ handleManagerSignature ~ signature:", signature);
      let authPerson = this.quoteData.authorization.find((auth) => {
        if (auth.name == "Quote manager approval") {
          return true;
        }
        return false;
      });
      console.log("🚀 ~ authPerson ~ authPerson:", authPerson);
      authPerson = JSON.parse(JSON.stringify(authPerson));
      authPerson.givenDate = new Date().toISOString();
      authPerson.signatureRepresentation = "e-sign";
      authPerson.signatureRepresentation = signature;
      authPerson.state = "approved";
      this.managerAuth = authPerson;
      this.isQuoteSignedByManager = true;
      this.quoteData.authorization = this.quoteData.authorization.map(
        (authPerson) => {
          if (authPerson.name == "Quote manager approval") {
            return this.managerAuth;
          }
          return authPerson;
        }
      );
      this.quoteData.state = "approved";
      // Find buyer
      // let recipient = this.quoteData.relatedParty.find(
      //   (item) => item.role == "Buyer"
      // );
      // V1
      // await UPCAxiosInstance.patch(
      //   `quotemanagement/?company=${recipient.name}`,
      //   { ...this.quoteData, id: this.quoteData._id }
      // );

      // V1.1
      await UPCAxiosInstance.patch(`quotemanagement`, {
        ...this.quoteData,
        id: this.quoteData._id,
      });
      console.log(
        "🚀 ~ handleManagerSignature ~ this.quoteData.authorization:",
        this.quoteData.authorization
      );
    },
    async approverQuote() {
      // Func - After clicking the send to customer button
      // change the manager to approved and date of approving then patch it
      // Then create new version of quote before sendtion to clients.
      // console.log("🚀 ~ handleManagerSignature ~ signature:", signature);
      let authPerson = this.quoteData.authorization.find((auth) => {
        if (auth.name == "Quote manager approval") {
          return true;
        }
        return false;
      });
      console.log("🚀 ~ authPerson ~ authPerson:", authPerson);
      authPerson = JSON.parse(JSON.stringify(authPerson));
      authPerson.givenDate = new Date().toISOString();
      // authPerson.signatureRepresentation = "e-sign";
      // authPerson.signatureRepresentation = signature;
      authPerson.state = "approved";
      this.managerAuth = authPerson;
      this.isQuoteSignedByManager = true;
      this.quoteData.authorization = this.quoteData.authorization.map(
        (authPerson) => {
          if (authPerson.name == "Quote manager approval") {
            return this.managerAuth;
          }
          return authPerson;
        }
      );
      this.quoteData.state = "approved";
      // Find buyer
      // let recipient = this.quoteData.relatedParty.find(
      //   (item) => item.role == "Buyer"
      // );
      // V1
      // await UPCAxiosInstance.patch(
      //   `quotemanagement/?company=${recipient.name}`,
      //   { ...this.quoteData, id: this.quoteData._id }
      // );

      // V1.1
      await UPCAxiosInstance.patch(`quotemanagement`, {
        ...this.quoteData,
        id: this.quoteData._id,
      });
      // Create an new version of compeleted quote
      // this.quoteData._id = undefined;
      // this.quoteData.id = undefined;
      // this.quoteApproved=true;
      // this.quoteData.isVersioning = true;
      // let createdQuote = await UPCAxiosInstance.post("/quotemanagement", this.quoteData);
      // this.quoteData = await createdQuote.data
      // console.log("approverQuote func",createdQuote)
    },
    handleClientSignature(signature) {
      console.log("🚀 ~ handleClientSignature ~ signature:", signature);
      let authPerson = this.quoteData.authorization.find((auth) => {
        if (auth.name == "Quote client confirmation") {
          return true;
        }
        return false;
      });
      console.log("🚀 ~ authPerson ~ this.quoteData:", this.quoteData);
      authPerson = JSON.parse(JSON.stringify(authPerson));
      this.isQuoteSignedByClient = true;

      authPerson.givenDate = new Date().toISOString();
      authPerson.signatureRepresentation = "e-sign";
      authPerson.signatureRepresentation = signature;
      authPerson.state = "approved";
      this.clientAuth = authPerson;
    },
    async editQuote() {
      this.$router.push({
        name: "CPQDefaultTemplate",
        query: {
          mode: "edit",
          from: "manager_approval",
          quote_id: this.quoteData._id,
        },
      });
    },
    async signAndApprove() {
      // this.sendToCustomer();
      let updateQoute = await UPCAxiosInstance.patch(`/quotemanagement`, {
        ...this.quoteData,
        id: this.quoteData._id,
      });
      console.log("updateQoute after sign signAndApprove func", updateQoute);
      this.quoteData.authorization = this.quoteData.authorization.map(
        (authPerson) => {
          if (authPerson.name == "Quote manager approval") {
            return this.managerAuth;
          }
          if (authPerson.name == "Quote client confirmation") {
            authPerson.state = "pending";
          }
          return authPerson;
        }
      );
      this.quoteData.state = "approved";
      // V1.0
      // await UPCAxiosInstance.patch(
      //   `/quotemanagement${
      //     this.$route.query.client ? "?company=" + this.$route.query.client : ""
      //   }`,
      //   {
      //     ...this.quoteData,
      //     id: this.quoteData._id,
      //   }
      // );
      // V1.1
      await UPCAxiosInstance.patch(`/quotemanagement`, {
        ...this.quoteData,
        id: this.quoteData._id,
      });
      // await UPCAxiosInstance.patch(
      //   `quotemanagement/?company=${this.quoteFor}`,
      //   { ...this.quoteData, id: this.quoteData._id }
      // );
      // updatedQuote;
      this.$router.push({
        name: "previewTemplate",
        query: { quote_id: this.quoteData._id },
      });
      // console.log("🚀 ~ signAndApprove ~ updatedQuote:", updatedQuote);
    },
    async signAndAccept() {
      try {
        let approverId = this.$route.query.approver_id;
        console.log("approverId", approverId);
        // this.sendToCustomer();
        let isQuoteCompleted = !this.quoteData.authorization.some(
          (client) =>
            client.name === "Quote client confirmation" &&
            client.state === "pending" &&
            client.totalSignature != 0
        );

        console.log(
          "isQuoteCompleted signandaccept func",
          isQuoteCompleted,
          this.quoteData
        );
        if (!isQuoteCompleted) {
          this.disableSignAndAcceptButton = true;
          console.log(
            this.quoteData,
            "isQuoteCompleted ! func",
            isQuoteCompleted
          );
          this.quoteData.authorization = this.quoteData.authorization.map(
            (authPerson) => {
              console.log(authPerson, "this.org", approverId);
              if (
                authPerson.name == "Quote client confirmation" &&
                authPerson?.approver[0].id == approverId
              ) {
                console.log(authPerson, "this.clientAuth", this.clientAuth);
                authPerson.state = "accepted";
                return authPerson;
              }
              console.log("this.authPerson", this.authPerson);
              return authPerson;
            }
          );

          let updateQoute = await UPCAxiosInstance.patch(`/quotemanagement`, {
            ...this.quoteData,
            id: this.quoteData._id,
          });
          console.log(
            "not isQuoteCompleted updateQoute after sign signAndAccept func",
            updateQoute
          );
          await UPCAxiosInstance.post("/tokenblocker", {
            token: this.$cookies.get("token"),
            company: this.client,
          });
          this.isQuoteSigned = true;
          console.log(
            this.$cookies.get("token"),
            "quote data",
            updateQoute,
            "this.client",
            this.client
          );
        }
        if (isQuoteCompleted) {
          this.disableSignAndAcceptButton = true;
          let sender = this.quoteData.relatedParty.find(
            (item) => item.role == "Seller"
          );
          let senderContact = this.quoteData.relatedParty.find(
            (item) => item.role == "Seller contact"
          );
          console.log("🚀 ~ sendForApproval ~ senderContact:", senderContact);
          let sellerEmail = this.quoteData.contactMedium.find(
            (item) => item.mediumType == "sellerEmail"
          );
          if (sellerEmail) {
            sellerEmail = sellerEmail.characteristic.emailAddress;
          }
          let sellerContactEmail = this.quoteData.contactMedium.find(
            (item) => item.mediumType == "sellerContactEmail"
          );
          if (sellerContactEmail) {
            sellerContactEmail = sellerContactEmail.characteristic.emailAddress;
          }
          let recipient = this.quoteData.relatedParty.find(
            (item) => item.role == "Buyer"
          );
          this.buyer = recipient ? recipient : null;
          console.log("🚀 ~ sendForApproval ~ recipient:", recipient);
          let recipientContact = this.quoteData.relatedParty.find(
            (item) => item.role == "Buyer contact"
          );
          console.log(
            "🚀 ~ sendForApproval ~ recipientContact:",
            recipientContact
          );

          let buyerEmail = this.quoteData.contactMedium.find(
            (item) => item.mediumType == "buyerEmail"
          );
          if (buyerEmail) {
            buyerEmail = buyerEmail.characteristic.emailAddress;
          }
          let buyerContactEmail = this.quoteData.contactMedium.find(
            (item) => item.mediumType == "buyerContactEmail"
          );
          if (buyerContactEmail) {
            buyerContactEmail = buyerContactEmail.characteristic.emailAddress;
          }
          let pdf = await this.handleQuoteDownload1();
          console.log("🚀 ~ signAndAccept ~ pdf:", pdf);
          const formData = new FormData();
          formData.append("file", pdf);
          // let validToDate = Math.round(
          //   (new Date(this.quoteData.requestedQuoteCompletionDate).getTime() -
          //     new Date().getTime()) /
          //     (1000 * 60 * 60 * 24)
          // );
          // validToDate = validToDate > 0 ? validToDate : 1;
          // let tokenPayload = {
          //   user: {
          //     email: sellerContactEmail,
          //     company: sender.name,
          //   },
          //   validTo: validToDate + "d",
          //   additionalFgields: {
          //     quoteLogin: true,
          //   },
          // };
          // const unit8Array = Buffer.from(pdf);
          const uint8Array = new Uint8Array(pdf);

          // Convert Uint8Array to Buffer
          const buffer = Buffer.from(uint8Array);
          // console.log("🚀 ~ signAndAccept ~ unit8Array:", unit8Array)

          // Convert Uint8Array to Buffer (optional)
          // const buffer = Buffer.from(uint8Array);
          const dev = "http://localhost:8080";
          console.log("🚀 ~ sendToCustomer ~ dev:", dev);
          const prod = process.env.VUE_APP_BASE_URL;
          console.log("🚀 ~ sendToCustomer ~ prod:", prod);

          this.multiBuyerEmail = this?.quoteData?.authorization.slice(1);
          console.log("this.multiBuyerEmail", this?.multiBuyerEmail);

          for (let contact of this.multiBuyerEmail) {
            this.contactName = contact?.clientName;
            let quoteInvitationEmailContent = `

        <!DOCTYPE html
            PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:o="urn:schemas-microsoft-com:office:office">
        <head>
            <!--[if gte mso 9]>  <xml>   <o:OfficeDocumentSettings>   <o:AllowPNG/>   <o:PixelsPerInch>96</o:PixelsPerInch>   </o:OfficeDocumentSettings>  </xml>  <![endif]-->
            <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="format-detection" content="date=no" />
            <meta name="format-detection" content="address=no" />
            <meta name="format-detection" content="telephone=no" />
            <meta name="x-apple-disable-message-reformatting" />
            <style type="text/css" media="screen">
                <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet"><link href='https://fonts.googleapis.com/css?family=OpenSans' rel='stylesheet'><link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
            </style>
        </head>
        <body class="body"
            style="padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#f5f5f5; -webkit-text-size-adjust:none;">
            <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
            <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5">
                <tr>
                    <td align="center" valign="top">
                        <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                            <tr>
                                <td class="td">
                                    <table border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td style="width: 613px;height: 501px;">
                                                <!-- Header -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    bgcolor="#ffffff">
                                                    <tr>
                                                        <td>
                                                            <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                                class="wrapper">
                                                                <tr>
                                                                    <td>
                                                                        <table border="0" cellpadding="10" cellspacing="0"
                                                                            width="100%" class="wrapper" bgcolor="#f5f5f5">
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                                            style="border:none;border-collapse:collapse;"
                                                                            width="100%" class="wrapper" bgcolor="#ffffff">
                                                                            <tr>
                                                                                <td width="104"><a
                                                                                        href="">
                                                                                        <img
                                                                                            src=""
                                                                                            height="50"
                                                                                            style="display:block; border:none;padding-top: 20px; padding-left: 30px;"
                                                                                            alt="logo">
                                                                                            
                                                                                            </a></td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- END Header -->
                                                <!-- Start Content -->
                                                <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501"
                                                    cellpadding="0">
                                                    <tr>
                                                        <td bgcolor="#FFFFFF" class="p35-15">
                                                            <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                                                cellpadding="0">
                                                                <!-- headline -->
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="headline"
                                                                        style="text-align:center;">
                                                                        <img style="width: 15%;
                                                                        object-fit: contain;
                                                                        clip-path: circle();
                                                                        " src=""
                                                                            alt="" srcset="">
                                                                    </td>
                                                                </tr>
                                                                <!-- #headline -->
                                                                <!--  hero-title-1 -->
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" style="font-family: 'Quicksand';
                                                                        font-style: normal;
                                                                        font-weight: 600;
                                                                        font-size: 22px;
                                                                        padding-top:15px;
                                                                        line-height: 28px;
                                                                        text-align: center;
                                                                        color: #191A15;">
                                                                        Dear
                                                                        ${this.quoteFor},
                                                                        <br>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                        style="padding-top:25px;padding-left:100px;padding-right:100px;color:black;  font-family: OpenSans; font-size:13px; line-height:25px; font-weight: normal; text-align:justify; letter-spacing: .01em">
                                                                         

We're thrilled to inform you that your quote has been successfully signed and processed. Attached to this email, you'll find the signed PDF document containing all the details.

If you have any questions or need further assistance, please don't hesitate to reach out to us. We're here to help!

Thank you for choosing our services.
                                                                    </td>
                                                                </tr>
                                                               
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" style="padding-top:40px;padding-left:80px;padding-right:80px;font-family: 'Quicksand';
                                                                        font-style: normal;
                                                                        font-weight: 400;
                                                                        font-size: 13px;
                                                                        line-height: 18px;
                                                                        text-align: center;
                                                                        color: #A6A6A6;">
                                                                        Hiccups might happen. Your feedback and requests are
                                                                        very welcomed.
                                                                        <a href="mailto:${sellerEmail}"
                                                                            style="text-decoration: none;color:#54BD95">${sender.name}</a>
                                                                    </td>
                                                                </tr>
                                                        </td>
                                                    </tr>
                                                    <!-- END HERO -->
                                                </table>
                                                <!-- HERO Images-->
                                            </td>
                                        </tr>
                                    </table>
                                    <!-- END Content -->
                                    <!--Features-->
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td>
                                                <!--Start Content-->
                                                <!--Desktop RTBs-->
                                                <!--End Desktop RTBs-->
                                                <!--Mobile RTBs-->
                                                <!--[if !mso]><!-->
                                                <!--End Mobile RTBs-->
                                                <!--<![endif]-->
                                                <!--END Features-->
                                                <!-- Footer -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    style="padding: 0 15px;" bgcolor="#ffffff">
                                                    <tr>
                                                        <td bgcolor="#161C2B" class="disclaimer2"
                                                            style="padding: 40px 77px 40px 77px;color:#FFFFFF; font-family: OpenSans; font-size:13px; line-height:20px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                            <span><a href=""
                                                                    style="color: #FFFFFF;font-size: 13px;"><u>T&C</u><u
                                                                        style="padding-left:40px;padding-right: 40px;">Privacy</u><u>Cookies</u></a></span><br />
                                                            <br />
                                                            © 2024 ${this.quoteFrom}. Copyright and rights reserved <p
                                                                style="padding-left: 17px !important; font-family:OpenSans; font-size: 13px; color: #FFFFFF;margin-top: 15px;  margin-bottom: 0px; letter-spacing: .01em;line-height: 20px;">
                                                                ${this.senderAddress}
                                                            </p>
                                                         
                                                            
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- Footer -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    bgcolor="#ffffff">
                                                    <tr>
                                                        <td>
                                                            <style>
                                                                .nowrap {
                                                                    word-break: keep-all;
                                                                    -moz-hyphens: none;
                                                                    -ms-hyphens: none;
                                                                    -webkit-hyphens: none;
                                                                    hyphens: none;
                                                                }
                                                                a[x-apple-data-detectors] {
                                                                    color: inherit !important;
                                                                    text-decoration: none !important;
                                                                    font-size: inherit !important;
                                                                    font-family: inherit !important;
                                                                    font-weight: inherit !important;
                                                                    line-height: inherit !important;
                                                                }
                                                            </style>
                                                            <table border="0" cellpadding="0" cellspacing="0"
                                                                style="border:none;border-collapse:collapse; background-color:#ffffff;"
                                                                width="100%" class="wrapper mobileContainer">
                                                                <tr>
                                                                    <td class="mobileContainer" align="center"
                                                                        style="font-family:Helvetica, Arial, sans-serif; color:#939c9e; font-size:0px; padding-top:24px; font-weight:100; background-color:#ffffff; width:100%; ">
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- END Footer -->
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <img src=""
                width="1" height="1"></custom>
        </body>
        </html>`;
            let mailPayload = {
              to: [contact.email],
              cc: [
                sellerEmail,
                "vva303f22@gmail.com",
                "pcfor62@gmail.com",
                "ganeshbalaji.periyasamyraja@halleyx.com",
              ],
              template: quoteInvitationEmailContent,
              subject:
                "Quotation Accepted: " +
                this.quoteData.description +
                " from " +
                recipient.name,
              attachments: [
                {
                  filename: this.quoteData.description + ".pdf", // Name of the file to be attached
                  content: buffer, // Buffer containing the PDF file
                },
              ],
              // ...formData,
            };
            // formData.append("to", buyerContactEmail);
            // formData.append("cc", [
            //   // sellerEmail,
            //   // "pcfor62@gmail.com",
            //   // "ganeshbalaji.periyasamyraja@halleyx.com",
            // ]);
            // formData.append("template", quoteInvitationEmailContent);
            // formData.append("file", pdf);
            // formData.append("files[]", [pdf]);
            // formData.append("attachments", [pdf]);
            // formData.append(
            //   "subject",
            //   "Quotation approval: " +
            //     this.quoteData.description +
            //     " from " +
            //     recipient.name
            // );

            console.log("🚀 ~ sendForApproval ~ mailPayload:", mailPayload);
            // await axios.post("http://localhost:5070/email", mailPayload, {
            //   headers: { Authorization: this.$cookies.get("token") },
            // });
            const headers = {
              Authorization: "Bearer " + this.$cookies.get("token"),
            };
            // Update the quote
            // let updatedQuote = await UPCAxiosInstance.patch(
            //   `quotemanagement/?company=${this.quoteFor}`,
            //   { ...this.quoteData, id: this.quoteData._id },
            //   { headers }
            // );
            // if()

            // V1.0
            // let updatedQuote = await UPCAxiosInstance.patch(
            //   `quotemanagement${
            //     this.$route.query.client ? "?company=" + this.$route.query.client : ""
            //   }`,
            //   { ...this.quoteData, id: this.quoteData._id },
            //   { headers }
            // );
            // Set effective quote completion date
            this.quoteData.effectiveQuoteCompletionDate =
              new Date().toISOString();
            // V1.1
            // let updatedQuote = await UPCAxiosInstance.patch(
            //   `/quotemanagement`,
            //   { ...this.quoteData, id: this.quoteData._id },
            //   { headers }
            // );
            // this.quoteData._id = undefined;
            // this.quoteData.id = undefined;
            // // this.quoteData.state = "approved";
            // this.quoteApproved=true;
            // this.quoteData.isVersioning = true;
            // let createdQuote = await UPCAxiosInstance.post("/quotemanagement", this.quoteData,{ headers });
            // this.quoteData = createdQuote.data
            // console.log(this.quoteData,"Quote data before sending to customer",createdQuote)
            // Send a quote pdf to customer
            await MLTAxiosInstance.post("/email", mailPayload, { headers });
          }
          // Block the user access
          await UPCAxiosInstance.post("/tokenblocker", {
            token: this.$cookies.get("token"),
            company: this.client,
          });
          this.quoteData.state = "accepted";
          //Update quote and create a new version of quote
          let updateQoute = await UPCAxiosInstance.patch(`/quotemanagement`, {
            ...this.quoteData,
            id: this.quoteData._id,
          });
          this.isQuoteSigned = true;
          console.log(
            this.client,
            "updateQoute after sign and created a new verion of quote",
            updateQoute
          );
          //   // Create an new version of compeleted quote
          //   this.quoteData.state = "accepted";
          // this.quoteData._id = undefined;
          // this.quoteData.id = undefined;
          // this.quoteApproved=true;
          // this.quoteData.isVersioning = true;
          // let createdQuote = await UPCAxiosInstance.post("/quotemanagement", this.quoteData);
          // console.log(this.quoteData,"Quote data after compeleted",createdQuote)
          // this.quoteData = await createdQuote.data

          // console.log("updateQoute after sign and created a new verion of quote",createdQuote)
        }
      } catch (e) {
        console.log("error from signandApprove ", e);
        this.notification = {
          type: "danger",
          message: "Failed to accept the quote",
        };
      }
      // this.quoteAccessDenied = true;
      // this.$router.push({
      //   name: "previewTemplate",
      //   query: { quote_id: this.quoteData._id },
      // });
      // this.handleQuoteDownload()
      // console.log("🚀 ~ signAndApprove ~ createdQuote:", createdQuote);
    },

    async sendToCustomer() {
      await this.approverQuote();
      try {
        let sender = this.quoteData.relatedParty.find(
          (item) => item.role == "Seller"
        );
        let sellerEmail = this.quoteData.contactMedium.find(
          (item) => item.mediumType == "sellerEmail"
        );
        if (sellerEmail) {
          sellerEmail = sellerEmail.characteristic.emailAddress;
        }
        let sellerContactEmail = this.quoteData.contactMedium.find(
          (item) => item.mediumType == "sellerContactEmail"
        );
        if (sellerContactEmail) {
          sellerContactEmail = sellerContactEmail.characteristic.emailAddress;
        }
        let recipient = this.quoteData.relatedParty.find(
          (item) => item.role == "Buyer"
        );
        let recipientContact = this.quoteData.relatedParty.find(
          (item) => item.role == "Buyer contact"
        );

        let buyerEmail = this.quoteData.contactMedium.find(
          (item) => item.mediumType == "buyerEmail"
        );
        if (buyerEmail) {
          buyerEmail = buyerEmail.characteristic.emailAddress;
        }
        let buyerContactEmail = this.quoteData.contactMedium.find(
          (item) => item.mediumType == "buyerContactEmail"
        );
        if (buyerContactEmail) {
          buyerContactEmail = buyerContactEmail.characteristic.emailAddress;
        }
        let validToDate = Math.round(
          (new Date(this.quoteData.requestedQuoteCompletionDate).getTime() -
            new Date().getTime()) /
            (1000 * 60 * 60 * 24)
        );
        validToDate = validToDate > 0 ? validToDate : 1;

        this.multiBuyerEmail = this?.quoteData?.authorization.slice(1);
        this.multiBuyerEmail = this.multiBuyerEmail .filter((ele)=>{
         return ele.name == 'Quote client confirmation'
        })
        console.log("this.multiBuyerEmail", this?.multiBuyerEmail);

        for (let contact of this.multiBuyerEmail) {
          this.contactName = contact?.clientName;
          console.log(contact.email,"contact?.approver[0]?.id", contact);
          let tokenPayload = {
            user: {
              email: contact.email,
              company: recipient.name,
            },
            validTo: validToDate + "d",
            additionalFields: {
              isCustomerQuoteSignAccess: true,
            },
          };
          // const dev = "http://localhost:8080";
          // console.log("🚀 ~ sendToCustomer ~ dev:", dev);
          const prod = process.env.VUE_APP_BASE_URL;
          prod;
          // console.log("🚀 ~ sendToCustomer ~ prod:", prod);

          let generatedToken = await MLTAxiosInstance.post(
            "/partymanagement/individual/generateToken",
            tokenPayload
          );
          generatedToken = generatedToken.data;
          let quoteInvitationEmailContent = `

        <!DOCTYPE html
            PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:o="urn:schemas-microsoft-com:office:office">
        <head>
            <!--[if gte mso 9]>  <xml>   <o:OfficeDocumentSettings>   <o:AllowPNG/>   <o:PixelsPerInch>96</o:PixelsPerInch>   </o:OfficeDocumentSettings>  </xml>  <![endif]-->
            <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="format-detection" content="date=no" />
            <meta name="format-detection" content="address=no" />
            <meta name="format-detection" content="telephone=no" />
            <meta name="x-apple-disable-message-reformatting" />
            <style type="text/css" media="screen">
                <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet"><link href='https://fonts.googleapis.com/css?family=OpenSans' rel='stylesheet'><link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
            </style>
        </head>
        <body class="body"
            style="padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#f5f5f5; -webkit-text-size-adjust:none;">
            <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
            <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5">
                <tr>
                    <td align="center" valign="top">
                        <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                            <tr>
                                <td class="td">
                                    <table border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td style="width: 613px;height: 501px;">
                                                <!-- Header -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    bgcolor="#ffffff">
                                                    <tr>
                                                        <td>
                                                            <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                                class="wrapper">
                                                                <tr>
                                                                    <td>
                                                                        <table border="0" cellpadding="10" cellspacing="0"
                                                                            width="100%" class="wrapper" bgcolor="#f5f5f5">
                                                                            <tr>
                                                                                <!-- <td width="104"><a href="https://halleyx.com"><img src="" height="50" style="display:block; border:none;padding-top: 20px;" alt="logo"></a></td> -->
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                                            style="border:none;border-collapse:collapse;"
                                                                            width="100%" class="wrapper" bgcolor="#ffffff">
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- END Header -->
                                                <!-- Start Content -->
                                                <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501"
                                                    cellpadding="0">
                                                    <tr>
                                                        <td bgcolor="#FFFFFF" class="p35-15">
                                                            <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                                                cellpadding="0">
                                                                <!-- headline -->
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="headline"
                                                                        style="text-align:center;">
                                                                        <img style="width: 15%;
                                                                        object-fit: contain;
                                                                        clip-path: circle();
                                                                        " src=""
                                                                            alt="" srcset="">
                                                                    </td>
                                                                </tr>
                                                                <!-- #headline -->
                                                                <!--  hero-title-1 -->
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" style="font-family: 'Quicksand';
                                                                        font-style: normal;
                                                                        font-weight: 600;
                                                                        font-size: 22px;
                                                                        padding-top:15px;
                                                                        line-height: 28px;
                                                                        text-align: center;
                                                                        color: #191A15;">
                                                                        Dear 
                                                                        ${
                                                                          this
                                                                            .contactName ||
                                                                          recipient.name
                                                                        }
                                                                        <br>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                        style="padding-top:25px;padding-left:100px;padding-right:100px;color:black;  font-family: 'OpenSans'; font-size:13px; line-height:25px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                                        <p style="font-family:'OpenSans';text-align:justify;">Thank you for your interest in ${
                                                                          this
                                                                            .quoteFrom
                                                                        } and for reaching out to us for a quotation on our products.
                                                                         We appreciate your inquiry, and we are pleased to provide you with our pricing.The quote is attached, and it's valid until <Strong>${this.dateAndTimeFormatter(
                                                                           this
                                                                             .quoteData
                                                                             ?.requestedQuoteCompletionDate
                                                                         )}</Strong>
                                                                           </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" valign="top"
                                                                        style="color:black; font-family: 'OpenSans';padding-top: 15px;  font-size:13px; line-height:25px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                                        <div class="btn"
                                                                            style="text-align:center;padding-top: 25px;  ">
                                                                            <!--[if mso]>                                                                     <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://www.fitbit.com/campaign/shop/versa?color=black&utm_source=ET&utm_medium=EM&utm_campaign=20191117_PreBFV2_US_FM_M_SU_SW_32_NA&utm_content=HeroCTALink" style="font-family: Arial, Helvetica, sans-serif;height:36px;v-text-anchor:middle;width:150px;" arcsize="152%" stroke="f" fillcolor="#002A3A">                                                                     <w:anchorlock/>                                                                     <center>                                                                     <![endif]-->
                                                                            <a href='${prod}/UPC/Quotes/preview?client=${
            recipient.name
          }&client_id=${recipient.id}&quote_id=${
            this.quoteData._id
          }&approver_id=${
            contact?.approver[0]?.id
          }&request_for=client_approval&access=${generatedToken.token}'
                                                                                class="mob-btn" target="_blank"
                                                                                style="background-color:#54BD95;border-radius:6px;color:#ffffff;font-family:'OpenSans';font-size:13px; text-align:center;text-decoration:none;width: 218.18px;
                                                                            height: 38px;-webkit-text-size-adjust:none; padding: 10px 13px; line-height: 15px;">Accept
                                                                                Quote
                                                                            </a>
                                                                            <!--[if mso]>                                                                     </center>                                                                     </v:roundrect>                                                                     <![endif]-->
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" style="padding-top:40px;padding-left:80px;padding-right:80px;font-family: 'Quicksand';
                                                                        font-style: normal;
                                                                        font-weight: 400;
                                                                        font-size: 13px;
                                                                        line-height: 18px;
                                                                        text-align: center;
                                                                        color: #A6A6A6;">
                                                                        Hiccups might happen. Your feedback and requests are
                                                                        very welcomed.
                                                                        <a href="mailto:${sellerEmail}"
                                                                            style="text-decoration: none;color:#54BD95">${
                                                                              sender.name
                                                                            }</a>
                                                                    </td>
                                                                </tr>
                                                        </td>
                                                    </tr>
                                                    <!-- END HERO -->
                                                </table>
                                                <!-- HERO Images-->
                                            </td>
                                        </tr>
                                    </table>
                                    <!-- END Content -->
                                    <!--Features-->
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td>
                                                <!--Start Content-->
                                                <!--Desktop RTBs-->
                                                <!--End Desktop RTBs-->
                                                <!--Mobile RTBs-->
                                                <!--[if !mso]><!-->
                                                <!--End Mobile RTBs-->
                                                <!--<![endif]-->
                                                <!--END Features-->
                                                <!-- Footer -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    style="padding: 0 15px;" bgcolor="#ffffff">
                                                    <tr>
                                                        <td bgcolor="#161C2B" class="disclaimer2"
                                                            style="padding: 40px 77px 40px 77px;color:#FFFFFF; font-family: 'OpenSans'; font-size:13px; line-height:20px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                            <span><a href="https://halleyx.com"
                                                                    style="color: #FFFFFF;font-size: 13px;"><u>T&C</u><u
                                                                        style="padding-left:40px;padding-right: 40px;">Privacy</u><u>Cookies</u></a></span><br />
                                                            <br />
                                                            © 2023 ${
                                                              this.quoteFrom
                                                            }. Copyright and rights reserved <p
                                                                style="padding-left: 17px !important; font-family:'OpenSans'; font-size: 11pt; color: #FFFFFF;margin-top: 15px;  margin-bottom: 0px; letter-spacing: .01em;line-height: 20px;">
                                                                ${
                                                                  this
                                                                    .senderAddress
                                                                }
                                                            </p>
      
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- Footer -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    bgcolor="#ffffff">
                                                    <tr>
                                                        <td>
                                                            <style>
                                                                .nowrap {
                                                                    word-break: keep-all;
                                                                    -moz-hyphens: none;
                                                                    -ms-hyphens: none;
                                                                    -webkit-hyphens: none;
                                                                    hyphens: none;
                                                                }
                                                                a[x-apple-data-detectors] {
                                                                    color: inherit !important;
                                                                    text-decoration: none !important;
                                                                    font-size: inherit !important;
                                                                    font-family: inherit !important;
                                                                    font-weight: inherit !important;
                                                                    line-height: inherit !important;
                                                                }
                                                            </style>
                                                            <table border="0" cellpadding="0" cellspacing="0"
                                                                style="border:none;border-collapse:collapse; background-color:#ffffff;"
                                                                width="100%" class="wrapper mobileContainer">
                                                                <tr>
                                                                    <td class="mobileContainer" align="center"
                                                                        style="font-family:Helvetica, Arial, sans-serif; color:#939c9e; font-size:0px; padding-top:24px; font-weight:100; background-color:#ffffff; width:100%; ">
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- END Footer -->
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
          </custom>
        </body>
        </html>`;

          let mailPayload = await {
            to: [contact.email],
            cc: [buyerEmail, sellerEmail, "pcfor62@gmail.com"],
            template: quoteInvitationEmailContent,
            subject:
              "Quotation approval: " +
              this.quoteData.description +
              " from " +
              recipient.name,
          };
          console.log(generatedToken, "mailPayload", mailPayload);
          this.quoteData.authorization = this.quoteData.authorization.map(
            (authPerson) => {
              if (authPerson.name == "Quote client confirmation") {
                authPerson.state = "pending";
                //           if (authPerson.state !== "approved") {
                //   authPerson.state = "pending";
                // }
              }
              return authPerson;
            }
          );
          console.log(
            recipientContact,
            "🚀 ~ sendToCustomer ~ this.quoteData.authorization:",
            this.quoteData.authorization
          );
          this.quoteData.state = "approved";
          // if(this.quoteData) throw new Error(JSON.stringify(this.quoteData))
          await MLTAxiosInstance.post("/email", mailPayload);
          this.notification = {
            type: "success",
            message: `Email successfully sent to "${this.contactName}"`,
          };
        }
        // await UPCAxiosInstance.patch(
        //   `quotemanagement/?company=${this.quoteFor}`,
        //   { ...this.quoteData, id: this.quoteData._id }
        // );
        // await UPCAxiosInstance.patch(
        //   `quotemanagement${
        //     this.$route.query.client
        //       ? "?company=" + this.$route.query.client
        //       : ""
        //   }`,
        //   {
        //     ...this.quoteData,
        //     id: this.quoteData._id,
        //   }
        // );
        let updateQuote = await UPCAxiosInstance.patch(`/quotemanagement`, {
          ...this.quoteData,
          id: this.quoteData._id,
        });
        console.log(this.quoteData, "quote patch", updateQuote);
        //         this.quoteData={...updateQuote.data}
        //         this.quoteData.authorization= updateQuote.data.authorization
        //       this.quoteData._id = undefined;
        //       this.quoteData.id = undefined;
        //       this.quoteData.state = "approved";
        //       this.quoteApproved=true;
        //       this.quoteData.isVersioning = true;
        //       let createdQuote = await UPCAxiosInstance.post("/quotemanagement", this.quoteData);
        //       console.log(this.quoteData,"Quote data before sending to customer",createdQuote)
        //       this.quoteData = await createdQuote.data
        // console.log("quote patch end",this.quoteData,createdQuote)
        // const currentQuery = this.$route.query;
        // await this.$router.replace({
        //   path: this.$route.path,
        //   query: {
        //     ...currentQuery,
        //     quote_id: this.quoteData.id
        //   }
        // });
        // this.isWaitingForClientApproval = true
      } catch (error) {
        console.log("error from send to customer", error);
        this.notification = {
          type: "danger",
          message: "Failed to sent an email",
        };
      }

      // this.emailTemp = quoteInvitationEmailContent;
    },
    async sendForApproval() {
      try {
        let sender = this.quoteData.relatedParty.find(
          (item) => item.role == "Seller"
        );
        let senderContact = this.quoteData.relatedParty.find(
          (item) => item.role == "Seller contact"
        );
        // console.log("🚀 ~ sendForApproval ~ senderContact:", senderContact);
        let sellerEmail = this.quoteData.contactMedium.find(
          (item) => item.mediumType == "sellerEmail"
        );
        if (sellerEmail) {
          sellerEmail = sellerEmail.characteristic.emailAddress;
        }
        let sellerContactEmail = this.quoteData.contactMedium.find(
          (item) => item.mediumType == "sellerContactEmail"
        );
        if (sellerContactEmail) {
          sellerContactEmail = sellerContactEmail.characteristic.emailAddress;
        }
        let recipient = this.quoteData.relatedParty.find(
          (item) => item.role == "Buyer"
        );
        // console.log("🚀 ~ sendForApproval ~ recipient:", recipient);
        let recipientContact = this.quoteData.relatedParty.find(
          (item) => item.role == "Buyer contact"
        );
        recipientContact;
        // console.log(
        //   "🚀 ~ sendForApproval ~ recipientContact:",
        //   recipientContact
        // );

        let buyerEmail = this.quoteData.contactMedium.find(
          (item) => item.mediumType == "buyerEmail"
        );
        if (buyerEmail) {
          buyerEmail = buyerEmail.characteristic.emailAddress;
        }
        let buyerContactEmail = this.quoteData.contactMedium.find(
          (item) => item.mediumType == "buyerContactEmail"
        );
        if (buyerContactEmail) {
          buyerContactEmail = buyerContactEmail.characteristic.emailAddress;
        }
        // let validToDate = Math.round(
        //   (new Date(this.quoteData.requestedQuoteCompletionDate).getTime() -
        //     new Date().getTime()) /
        //     (1000 * 60 * 60 * 24)
        // );
        // validToDate = validToDate > 0 ? validToDate : 1;
        // let tokenPayload = {
        //   user: {
        //     email: sellerContactEmail,
        //     company: sender.name,
        //   },
        //   validTo: validToDate + "d",
        //   additionalFgields: {
        //     quoteLogin: true,
        //   },
        // };
        const dev = "http://localhost:8080";
        dev;
        // console.log("🚀 ~ sendToCustomer ~ dev:", dev);
        const prod = process.env.VUE_APP_BASE_URL;
        prod;
        // console.log("🚀 ~ sendToCustomer ~ prod:", prod);

        // let generatedToken = await MLTAxiosInstance.post(
        //   "/partymanagement/individual/generateToken",
        //   tokenPayload
        // );
        // generatedToken = generatedToken.data;
        let quoteInvitationEmailContent = `

        <!DOCTYPE html
            PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:o="urn:schemas-microsoft-com:office:office">
        <head>
            <!--[if gte mso 9]>  <xml>   <o:OfficeDocumentSettings>   <o:AllowPNG/>   <o:PixelsPerInch>96</o:PixelsPerInch>   </o:OfficeDocumentSettings>  </xml>  <![endif]-->
            <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="format-detection" content="date=no" />
            <meta name="format-detection" content="address=no" />
            <meta name="format-detection" content="telephone=no" />
            <meta name="x-apple-disable-message-reformatting" />
            <style type="text/css" media="screen">
                <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet"><link href='https://fonts.googleapis.com/css?family=OpenSans' rel='stylesheet'><link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
            </style>
        </head>
        <body class="body"
            style="padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#f5f5f5; -webkit-text-size-adjust:none;">
            <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
            <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5">
                <tr>
                    <td align="center" valign="top">
                        <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                            <tr>
                                <td class="td">
                                    <table border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td style="width: 613px;height: 501px;">
                                                <!-- Header -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    bgcolor="#ffffff">
                                                    <tr>
                                                        <td>
                                                            <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                                class="wrapper">
                                                                <tr>
                                                                    <td>
                                                                        <table border="0" cellpadding="10" cellspacing="0"
                                                                            width="100%" class="wrapper" bgcolor="#f5f5f5">
                                                                          
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                                            style="border:none;border-collapse:collapse;"
                                                                            width="100%" class="wrapper" bgcolor="#ffffff">
                                                                            
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- END Header -->
                                                <!-- Start Content -->
                                                <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501"
                                                    cellpadding="0">
                                                    <tr>
                                                        <td bgcolor="#FFFFFF" class="p35-15">
                                                            <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                                                cellpadding="0">
                                                                <!-- headline -->
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="headline"
                                                                        style="text-align:center;">
                                                                        <img style="width: 15%;
                                                                        object-fit: contain;
                                                                        clip-path: circle();
                                                                        " src=""
                                                                            alt="" srcset="">
                                                                    </td>
                                                                </tr>
                                                                <!-- #headline -->
                                                                <!--  hero-title-1 -->
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" style="font-family: 'Quicksand';
                                                                        font-style: normal;
                                                                        font-weight: 600;
                                                                        font-size: 22px;
                                                                        padding-top:15px;
                                                                        line-height: 28px;
                                                                        text-align: center;
                                                                        color: #191A15;">
                                                                        Hi, 
                                                                        ${sender.name}
                                                                        <br>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                        style="padding-top:25px;padding-left:100px;padding-right:100px;color:black;  font-family: 'OpenSans'; font-size:11pt; line-height:25px; font-weight: normal; text-align:justify; letter-spacing: .01em">
                                                                         I am writing to seek your approval for the quote for 
                                                                         <strong
                                                                          >
                                                                      ${recipient.name} </strong> quote <strong
                                                                          >
                                                                      ${this.quoteData.description}</strong>. The details of the quote are outlined below:

<p style="text-align:left;">
  <span style="font-weight:600;">Client Name</span>: ${recipient.name}
</p>
<p style="text-align:left;">
  <span style="font-weight:600;">Quote Number</span>: ${this.quoteData._id}
</p>
<p style="text-align:left;">
  <span style="font-weight:600;">Total Amount </span>: [Total Amount]
</p>
<p style="text-align:justify;">
Products/Services: [List of Products/Services]
After careful consideration and analysis of the client's requirements, we have tailored this quote to align with their needs while ensuring competitiveness in the market.

Your approval of this quote is crucial to proceed with finalizing the deal and securing the client's business. Please review the quote at your earliest convenience and let me know if any adjustments or clarifications are required.

Your prompt attention to this matter is greatly appreciated, as it will enable us to swiftly move forward in servicing our client's needs and fostering a positive business relationship.

Thank you for your time and support.
</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" valign="top"
                                                                        style="color:black; font-family: 'OpenSans';padding-top: 15px;  font-size:11pt; line-height:25px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                                        <div class="btn"
                                                                            style="text-align:center;padding-top: 25px;  ">
                                                                            <!--[if mso]>                                                                     <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://www.fitbit.com/campaign/shop/versa?color=black&utm_source=ET&utm_medium=EM&utm_campaign=20191117_PreBFV2_US_FM_M_SU_SW_32_NA&utm_content=HeroCTALink" style="font-family: Arial, Helvetica, sans-serif;height:36px;v-text-anchor:middle;width:150px;" arcsize="152%" stroke="f" fillcolor="#002A3A">                                                                     <w:anchorlock/>                                                                     <center>                                                                     <![endif]-->
                                                                            <a href='${prod}/UPC/Quotes/preview?approver=${sender.name}&quote_id=${this.quoteData._id}&request_for=manager_approval'
                                                                                class="mob-btn" target="_blank"
                                                                                style="background-color:#54BD95;border-radius:6px;color:#ffffff;font-family:'OpenSans';font-size:11pt; text-align:center;text-decoration:none;width: 218.18px;
                                                                            height: 38px;-webkit-text-size-adjust:none; padding: 10px 13px; line-height: 15px;">Accept
                                                                                Quote
                                                                            </a>
                                                                            <!--[if mso]>                                                                     </center>                                                                     </v:roundrect>                                                                     <![endif]-->
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" style="padding-top:40px;padding-left:80px;padding-right:80px;font-family: 'Quicksand';
                                                                        font-style: normal;
                                                                        font-weight: 400;
                                                                        font-size: 11pt;
                                                                        line-height: 18px;
                                                                        text-align: center;
                                                                        color: #A6A6A6;">
                                                                        Hiccups might happen. Your feedback and requests are
                                                                        very welcomed.
                                                                        <a href="mailto:${sellerEmail}"
                                                                            style="text-decoration: none;color:#54BD95">${sender.name}</a>
                                                                    </td>
                                                                </tr>
                                                        </td>
                                                    </tr>
                                                    <!-- END HERO -->
                                                </table>
                                                <!-- HERO Images-->
                                            </td>
                                        </tr>
                                    </table>
                                    <!-- END Content -->
                                    <!--Features-->
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td>
                                                <!--Start Content-->
                                                <!--Desktop RTBs-->
                                                <!--End Desktop RTBs-->
                                                <!--Mobile RTBs-->
                                                <!--[if !mso]><!-->
                                                <!--End Mobile RTBs-->
                                                <!--<![endif]-->
                                                <!--END Features-->
                                                <!-- Footer -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    style="padding: 0 15px;" bgcolor="#ffffff">
                                                    <tr>
                                                        <td bgcolor="#161C2B" class="disclaimer2"
                                                            style="padding: 40px 77px 40px 77px;color:#FFFFFF; font-family: 'OpenSans'; font-size:11pt; line-height:20px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                            <span><a href=""
                                                                    style="color: #FFFFFF;font-size: 11pt;"><u>T&C</u><u
                                                                        style="padding-left:40px;padding-right: 40px;">Privacy</u><u>Cookies</u></a></span><br />
                                                            <br />
                                                            © 2023 ${this.quoteFrom}. Copyright and rights reserved <p
                                                                style="padding-left: 17px !important; font-family:'OpenSans'; font-size: 11pt; color: #FFFFFF;margin-top: 15px;  margin-bottom: 0px; letter-spacing: .01em;line-height: 20px;">
                                                                ${this.senderAddress}
                                                            </p>
                                                            <span style="float:left;margin-left: -60px;padding-top:20px;"><img
                                                                    width="100"
                                                                    src="" /></span>
                                                            <span
                                                                style="float: right;margin-right:-50px;padding-top:20px;color: #FFFFFF;"><a
                                                                    href="https://www.linkedin.com/company/halleyx/"><i
                                                                        class="fa fa-linkedin fa-lg"
                                                                        style="color: #ffffff;"></i></a>
                                                                <a href="https://halleyx.com/"
                                                                    style="padding-left:5px;padding-right: 5px;"><i
                                                                        class="fa fa-facebook fa-lg"
                                                                        style="color: #ffffff;"></i>
                                                                </a>
                                                                <a href="https://www.instagram.com/halleyx_inc/"><i
                                                                        class="fa fa-instagram fa-lg"
                                                                        style="color: #ffffff;"></i></a></span>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- Footer -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    bgcolor="#ffffff">
                                                    <tr>
                                                        <td>
                                                            <style>
                                                                .nowrap {
                                                                    word-break: keep-all;
                                                                    -moz-hyphens: none;
                                                                    -ms-hyphens: none;
                                                                    -webkit-hyphens: none;
                                                                    hyphens: none;
                                                                }
                                                                a[x-apple-data-detectors] {
                                                                    color: inherit !important;
                                                                    text-decoration: none !important;
                                                                    font-size: inherit !important;
                                                                    font-family: inherit !important;
                                                                    font-weight: inherit !important;
                                                                    line-height: inherit !important;
                                                                }
                                                            </style>
                                                            <table border="0" cellpadding="0" cellspacing="0"
                                                                style="border:none;border-collapse:collapse; background-color:#ffffff;"
                                                                width="100%" class="wrapper mobileContainer">
                                                                <tr>
                                                                    <td class="mobileContainer" align="center"
                                                                        style="font-family:Helvetica, Arial, sans-serif; color:#939c9e; font-size:0px; padding-top:24px; font-weight:100; background-color:#ffffff; width:100%; ">
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- END Footer -->
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <img src=""
                width="1" height="1"></custom>
        </body>
        </html>`;
        let mailPayload = {
          to: [sellerContactEmail],
          cc: [
            sellerEmail,
            "pcfor62@gmail.com",
            "ganeshbalaji.periyasamyraja@halleyx.com",
          ],
          template: quoteInvitationEmailContent,
          subject:
            "Quotation approval: " +
            this.quoteData.description +
            " from " +
            recipient.name,
        };
        // console.log("🚀 ~ sendForApproval ~ mailPayload:", mailPayload);
        await MLTAxiosInstance.post("/email", mailPayload);
        this.quoteData.authorization = this.quoteData.authorization.map(
          (authPerson) => {
            if (authPerson.name == "Quote manager approval") {
              authPerson.state = "pending";
            }
            return authPerson;
          }
        );
        // await UPCAxiosInstance.patch(
        //   `quotemanagement/?company=${this.quoteFor}`,
        //   { ...this.quoteData, id: this.quoteData._id }
        // );
        // await UPCAxiosInstance.patch(
        //   `/quotemanagement${
        //     this.$route.query.client
        //       ? "?company=" + this.$route.query.client
        //       : ""
        //   }`,
        //   {
        //     ...this.quoteData,
        //     id: this.quoteData._id,
        //   }
        // );
        await UPCAxiosInstance.patch(`/quotemanagement`, {
          ...this.quoteData,
          id: this.quoteData._id,
        });
        this.notification = {
          type: "success",
          message: `Email succefully sent to ${senderContact.name} for approval`,
        };
        // this.approvalTemplate = quoteInvitationEmailContent;
      } catch (error) {
        this.notification = {
          type: "success",
          message: `Failed to sent for approval`,
        };
      }
    },
    convertDate(dateTime) {
      // Parse the input date string
      let date = new Date(dateTime);

      // Format the date to "MMM DD, YYYY" format
      const options = { year: "numeric", month: "short", day: "2-digit" };
      let convertedDate = date.toLocaleDateString("en-US", options);
      return convertedDate;
    },
  },
};
</script>
<style lang="scss">
.QuotePreviewStaticHeader {
  height: 67px;
  display: flex;
  width: calc(100% - 72px);
  position: fixed;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index: 78;
  top: 0px;
  left: 72px;

  .container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .div1 {
      font-size: 22px;
      padding: 0 0 0 14px;
    }

    .div2 {
      padding: 0 0 5px 9px;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 4px;
    text-align: center;

    .div1 {
      font-size: 14px;
      font-family: "Quicksand";
      font-weight: 700;
      width: 269px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

  .container3 {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    margin: 0 20px 0 0;

    .div1 {
      font-size: 14px;
      font-weight: 400;
      color: rgba(135, 135, 135, 1);
    }

    // .div3 {
    //   color: rgb(84, 189, 149);
    //   font-size: 18px;
    // }

    /* .div2 styles can be added here if needed */
  }
}
.newQuotepreviewIdDivForPDF {
  margin-top: 66px;
}

@media (max-width: 817px) {
  .newQuotepreviewIdDivForPDF {
    margin-top: 107px;
  }

  .QuotePreviewStaticHeader {
    gap: 3px;
    height: 106px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 72px);
    position: fixed;
    justify-content: center;
    align-items: center;
    background: white;
    z-index: 78;
    top: 0px;
    left: 72px;

    .container1 {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 36px;
      left: 1px;
    }
  }
}
</style>
