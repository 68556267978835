<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <div class="app-layout">
    <section class="new-right-panel">
      <div class="new-right-panel-parent-container no-filter-panel">
        <div class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <section class="layout-basic-no-breadcrumb data-table-view">
            <div class="new-layout-header">
              <span class="new-layout-header-container">
                <span class="schema-header-title">Rule validation</span>

                <span class="subtitle">
                  Execute rules by supplying input values.
                </span></span
              >
            </div>
            <div class="new-layout-body">
              <div
                class="header-utils-and-switch-container"
                style="width: 100%"
              >
                <span style="width: 70%">
                  <!-- <hlx-switch
                  :switch_items="switch_items"
                  :type="'tab'"
                  :position="'bottom'"
                  @chosen="switchmenu"
                /> -->
                  <!-- <hlx-switch
                  v-model="selectedValue"
                  :switch-items="switch_items"
                  :tab-view="true"
                  value-prop="value"
                  display-prop="name"
                  @change="handleCurrentChange"
                /> -->
                </span>
                <span class="schema-header-utils">
                  <div class="util-items search">
                    <hlx-search @search-key="search"></hlx-search>
                  </div>
                  <span>
                    <hlx-button
                      v-if="permisstionData.add"
                      class="primary sm add-btn"
                      style="display: flex"
                      @click.stop="showAddContextMenu()"
                    >
                      <i
                        class="icon-plus-circle-regular"
                        style="margin: 2px 4px 0 0"
                      ></i>
                      <span>Add test data</span></hlx-button
                    >
                    <hlx-context-menu
                      class="exec-data-add-menu"
                      style="z-index: 9000"
                      :options="addContextOptions"
                      :show="show_add_menu"
                      @chosen="closeAddContextMenu"
                    />
                  </span>
                  <!-- <hlx-button class="secondary-success sm filter-button"
                  ><i class="icon-filter-regular" style="margin-right: 5px"></i
                  >Filter</hlx-button
                > -->
                  <!-- <hlx-button
                  class="primary sm add-button"
                  @click.stop="addbutton"
                  ><i
                    class="icon-plus-circle-regular"
                    style="margin-right: 5px"
                  ></i
                  >Add
                  <hlx-context-menu
                    :options="schemaoptions"
                    :show="clicked"
                    style="margin-top: 15px; margin-right: 20px"
                    @chosen="chooseschemaoptions"
                  />
                </hlx-button> -->
                </span>
              </div>
              <hlx-table
                v-if="tableData.length > 0"
                :column-count="theads.length + 2"
                :border="['table', 'header', 'horizontal']"
                :bold-headers="false"
                :row-hover="true"
                theme="grey"
                :striped-rows="false"
                :re-arrange-columns="re_arrange"
                :re-arrange-heads="theadsC"
                @close-rearrange="re_arrange = false"
                @apply-rearranged-columns="applyColumns"
              >
                <template #thead>
                  <hlx-table-head
                    :width="60"
                    :align="'left'"
                    style="padding: 0 15px !important"
                    :fixed="'left'"
                    @new-resize-width="resizedWidth"
                    >{{ "S.no" }}</hlx-table-head
                  >
                  <hlx-table-head
                    v-for="(i, index) in duplicateTheads"
                    :key="index"
                    :prop="i.prop"
                    :sortable="i.sortable"
                    :resizable="i.resizable"
                    style="position: sticky; top: 0px"
                    :width="i.width !== undefined ? i.width : ''"
                    :align="i.align !== undefined ? i.align : ''"
                    @sorting_func="sorting_Data"
                    @new-resize-width="resizedWidth"
                  >
                    {{ i.label }}
                  </hlx-table-head>
                  <hlx-table-head
                    v-if="permisstionData.edit || permisstionData.delete"
                    v-show="editMode == true"
                    :width="60"
                    :fixed="'right'"
                    style="max-width: 60px"
                    @new-resize-width="resizedWidth"
                  >
                    <span class="action-icon"
                      ><i
                        class="icon-settings-regular"
                        @click="reAssignOrgTableHeader()"
                      ></i></span
                  ></hlx-table-head>
                </template>
                <template #tbody>
                  <tr v-for="(i, index) in paginatedData" id="" :key="index">
                    <hlx-table-cell :align="'center'" :fixed="'left'">{{
                      serialNumber(index)
                    }}</hlx-table-cell>
                    <hlx-table-cell
                      v-for="(j, col_index) in duplicateTheads"
                      :key="col_index"
                      :align="j.align"
                    >
                      <div
                        v-if="j.prop === 'name' && permisstionData.edit"
                        :id="j.prop + index"
                        class="link-name"
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                        :style="{
                          width: '150px',
                        }"
                        @click="gotoViewPage(i)"
                        @mouseover="
                          tooltipover(
                            j.prop + index,
                            'tooltip-text' + j.prop + index
                          )
                        "
                        @mouseleave="
                          tooltipleave(
                            j.prop + index,
                            'tooltip-text' + j.prop + index
                          )
                        "
                      >
                        {{ i[j.prop] }}
                        <p
                          id="tooltip-text"
                          :class="'tooltip-text' + j.prop + index"
                        >
                          {{ i[j?.prop] }}
                        </p>
                      </div>
                      <div
                        v-if="j.prop === 'name' && !permisstionData.edit"
                        :id="j.prop + index"
                        class="link-name"
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                        :style="{
                          width: '150px',
                        }"
                        @click="gotoViewPage(i)"
                        @mouseover="
                          tooltipover(
                            j.prop + index,
                            'tooltip-text' + j.prop + index
                          )
                        "
                        @mouseleave="
                          tooltipleave(
                            j.prop + index,
                            'tooltip-text' + j.prop + index
                          )
                        "
                      >
                        {{ i[j.prop] }}
                        <p
                          id="tooltip-text"
                          :class="'tooltip-text' + j.prop + index"
                        >
                          {{ i[j?.prop] }}
                        </p>
                      </div>
                      <div v-else-if="j.prop === 'updatedAt'">
                        {{ convertdateformat(i[j.prop]) }}
                      </div>
                      <div
                        v-else-if="j.prop.toLowerCase() === 'description'"
                        :id="j.prop + index"
                        class="table-desc-head"
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                        :style="
                          j.width === null
                            ? {
                                width: '300px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }
                            : ''
                        "
                        @mouseover="
                          tooltipover(
                            j.prop + index,
                            'tooltip-text' + j.prop + index
                          )
                        "
                        @mouseleave="
                          tooltipleave(
                            j.prop + index,
                            'tooltip-text' + j.prop + index
                          )
                        "
                      >
                        {{ i[j.prop] }}
                        <p
                          id="tooltip-text"
                          :class="'tooltip-text' + j.prop + index"
                        >
                          {{ i[j?.prop] }}
                        </p>
                      </div>
                      <div v-else>
                        {{ i[j.prop] }}
                      </div>
                    </hlx-table-cell>
                    <hlx-table-cell
                      v-if="permisstionData.edit || permisstionData.delete"
                      v-show="editMode == true"
                      :align="'center'"
                      :width="60"
                      style="max-width: 60px"
                      :fixed="'right'"
                    >
                      <span
                        :id="'table-context' + serialNumber(index - 1)"
                        class="action-icon"
                      >
                        <i
                          style="position: relative"
                          class="icon-more-vertical-filled"
                          :class="i.context === true ? 'active-action' : ''"
                          @click="
                            crudContextMenu($event, serialNumber(index - 1))
                          "
                        >
                          <hlx-context-menu
                            :top="containertop"
                            :left="containerleft"
                            :conditionvalue="150"
                            :conditiondown="2"
                            :conditionup="-38"
                            :options="computedTableAction"
                            :data="index.toString()"
                            :show="i.context == true"
                            @chosen="closeCrudContextMenu($event, i)"
                          />
                          <!-- <span
                        v-else
                        style="position: absolute"
                      >
                        <hlx-context-menu
                          style="
                            z-index: 9000;
                            margin-right: 83px !important;
                            margin-top: -128px;
                            box-shadow: 0 0 20px 0 #d8d8d8;
                          "
                          :options="crudOptions"
                          :data="index.toString()"
                          :show="i.context == true"
                          @chosen="closeCrudContextMenu($event, i)"
                        />
                      </span> -->
                        </i>
                      </span>
                    </hlx-table-cell>
                  </tr>
                </template>
              </hlx-table>
              <hlx-table
                v-else
                :column-count="theads.length + 2"
                :border="['table', 'header']"
                :bold-headers="false"
                theme="grey"
                :striped-rows="false"
                :re-arrange-columns="re_arrange"
                :re-arrange-heads="theadsC"
                :height="10 * 40 + 42"
                @close-rearrange="re_arrange = false"
                @apply-rearranged-columns="applyColumns"
              >
                <template #thead>
                  <hlx-table-head
                    :width="60"
                    :align="'left'"
                    @new-resize-width="resizedWidth"
                    >{{ "S.no" }}</hlx-table-head
                  >
                  <hlx-table-head
                    v-for="(i, index) in duplicateTheads"
                    :key="index"
                    :prop="i.prop"
                    :sortable="i.sortable"
                    :resizable="i.resizable"
                    style="position: sticky; top: 0px"
                    :width="i.width !== undefined ? i.width : ''"
                    :align="i.align !== undefined ? i.align : ''"
                    @sorting_func="sorting_Data"
                    @new-resize-width="resizedWidth"
                  >
                    {{ i.label }}
                  </hlx-table-head>
                  <hlx-table-head
                    v-if="permisstionData.edit || permisstionData.delete"
                    :width="100"
                    @new-resize-width="resizedWidth"
                  >
                    <span class="action-icon"
                      ><i
                        class="icon-settings-regular"
                        @click="reAssignOrgTableHeader()"
                      ></i></span
                  ></hlx-table-head>
                </template>
                <template #tbody>
                  <tr>
                    <hlx-table-cell
                      :colspan="theads.length + 2"
                    ></hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell
                      :colspan="theads.length + 2"
                    ></hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell
                      :colspan="theads.length + 2"
                    ></hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell
                      :colspan="theads.length + 2"
                    ></hlx-table-cell>
                  </tr>
                  <tr>
                    <hlx-table-cell :colspan="theads.length + 2">{{
                      "No data"
                    }}</hlx-table-cell>
                  </tr>
                </template>
              </hlx-table>
            </div>
          </section>
        </div>
      </div>
      <div class="new-layout-footer">
        <div class="pagination-container">
          <hlx-pagination
            v-model:rows-per-page="externalRowsPerPage"
            :total="totalPageNo"
            rows-per-page
            enable-rows-per-page
            :rows-per-page-list="[10, 15, 20]"
            :options="{
              attributes: {
                rowsPerPageTitle: true,
                rowsPerPageResult: true,
                rowsPerPageDropdown: {
                  position: {
                    top: 0,
                    right: 1,
                  },
                },
              },
            }"
            @current-page="currentPage"
            @updated:rows-per-page="changeRowsPerPage"
          ></hlx-pagination>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// import axios from "axios";
import { DMNAxiosInstance, MLTAxiosInstance } from "@/config/axiosConfig";

export default {
  data() {
    return {
      permisstionData: {},
      tableHeaderOrg: [],
      tableHeaderOrgcc: [],
      totalPageNo: 0,
      company: "",
      rulevalidatequery: {
        type: "filter",
        module: "rulevalidate",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      externalRowsPerPage: 10,
      containerleft: 0,
      containertop: 0,
      editPermission: [],
      viewPermission: [],
      token: localStorage.token,
      editMode: true,
      options: [],
      notification: {},
      delete_modal_active: false,
      addContextOptions: [
        {
          label: "Manual",
          value: "manual",
          icon: "icon-code-square-regular",
        },
        {
          label: "API",
          value: "api",
          icon: "icon-globe-regular",
        },
        {
          label: "Upload",
          value: "upload",
          icon: "icon-file-upload-regular",
        },
      ],
      crudOptions: [
        {
          label: "Edit",
          value: "edit",
          icon: "icon-edit-regular",
        },
        {
          label: "Delete",
          value: "delete",
          icon: "icon-trash-regular",
        },
        {
          label: "Execute",
          value: "execute",
          icon: "icon-code-regular",
        },
        {
          label: "View",
          value: "view",
          icon: "icon-eye-regular",
        },
      ],
      re_arrange: false,
      currPage: 1,
      rowsPerPage: 10,
      show_add_menu: false,
      show_crud_menu: false,
      theads: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: true,
          resizable: true,
          width: 150,
          align: "left",
        },
        {
          name: "Description",
          checked: true,
          id: 2,
          disabled: false,
          prop: "description",
          label: "Description",
          sortable: true,
          resizable: true,
          width: null,
          align: "left",
        },
        {
          name: "Schema",
          checked: true,
          id: 4,
          disabled: true,
          prop: "schemaName",
          label: "Schema",
          sortable: true,
          resizable: true,
          width: 100,
          align: "left",
        },
        {
          name: "Average duration",
          checked: true,
          id: 3,
          disabled: false,
          prop: "averageDuration",
          label: "Average duration",
          sortable: true,
          resizable: true,
          width: 165,
          align: "center",
        },
        {
          name: "Execution count",
          checked: true,
          id: 5,
          disabled: false,
          prop: "executionCount",
          label: "Execution count",
          sortable: true,
          resizable: true,
          width: 160,
          align: "center",
        },
        {
          name: "Last modified",
          checked: true,
          id: 6,
          disabled: false,
          prop: "updatedAt",
          label: "Last modified",
          sortable: true,
          resizable: true,
          width: 180,
          align: "left",
        },
      ],
      theadsC: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: true,
          resizable: true,
          width: 150,
          align: "left",
        },
        {
          name: "Description",
          checked: true,
          id: 2,
          disabled: false,
          prop: "description",
          label: "Description",
          sortable: true,
          resizable: true,
          width: null,
          align: "left",
        },
        {
          name: "Schema",
          checked: true,
          id: 4,
          disabled: true,
          prop: "schemaName",
          label: "Schema",
          sortable: true,
          resizable: true,
          width: 100,
          align: "left",
        },
        {
          name: "Average duration",
          checked: true,
          id: 3,
          disabled: false,
          prop: "averageDuration",
          label: "Average duration",
          sortable: true,
          resizable: true,
          width: 165,
          align: "center",
        },
        {
          name: "Execution count",
          checked: true,
          id: 5,
          disabled: false,
          prop: "executionCount",
          label: "Execution count",
          sortable: true,
          resizable: true,
          width: 160,
          align: "center",
        },
        {
          name: "Last modified",
          checked: true,
          id: 6,
          disabled: false,
          prop: "updatedAt",
          label: "Last modified",
          sortable: true,
          resizable: true,
          width: 180,
          align: "left",
        },
      ],
      add_context_options: [
        {
          label: "Manual",
          icon: "icon-add-file",
        },
        {
          label: "API",
          icon: "icon-www",
        },
        {
          label: "Upload",
          icon: "icon-upload",
        },
      ],
      crud_options: [
        {
          label: "Execute",
          icon: "icon-double-gear",
        },
        {
          label: "Edit",
          icon: "icon-pen",
        },
        {
          label: "delete",
          icon: "icon-trash-filled",
        },
      ],
      tableData: [],
      duplicateTableData: [],
      duplicateTheads: [],
      searchTerm: "",
      delete_id: "",
      delete_name: "",
      showAlert: false,
      alertMessage: "Row deleted successfully!",
      alertType: "Success",
      searchvalue: "",
      logs: [],
    };
  },
  computed: {
    paginatedData() {
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      return this.tableData;
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
    searchData() {
      return this.data_list.filter((item) =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
  async mounted() {
    // To get table header
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

    let sampleTheadsd = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );

    const mergedPayload = sampleTheadsd.data.permission;

    this.permisstionData = mergedPayload.DMN.child["Rule validation"];

    let sampleTheads = await MLTAxiosInstance.get(`/settings`);

    if (sampleTheads?.data?.entityRef?.["ruleValidation"]) {
      let offerTableHeadModal = sampleTheads.data.entityRef["ruleValidation"];
      let offerTableHeadOrg = sampleTheads.data.entityRef[
        "ruleValidation"
      ].filter((ele) => ele.checked);
      console.log(
        offerTableHeadOrg,
        "offerTableHeadModal",
        offerTableHeadModal
      );

      if (
        offerTableHeadModal.length === this.theadsC.length
        // &&
        // offerTableHeadModal.every((item, index) => item.name ===  this.theadsC[index].name)
      ) {
        this.theadsC = JSON.parse(JSON.stringify(offerTableHeadModal));
        this.theads = JSON.parse(JSON.stringify(offerTableHeadOrg));
        this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.theadsC));
        this.tableHeaderOrg = JSON.parse(JSON.stringify(this.theads));
      }
    }
    // const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.company = val.data.company;
    // let token = {
    //   headers: {
    //     Authorization: "Bearer  " + this.token,
    //   }
    // };
    // const val = await MLTAxiosInstance.get(`/partymanagement/individual/auth`);
    // this.company = val.data.company;
    // this.userId = val.data.company;
    // //
    // const access = await MLTAxiosInstance.get(
    //   `/partyRole/get/${val.data.company}/${val.data.userId}`
    // );

    // let arr = access.data;
    // this.editPermission = [];
    // //
    // if (arr.permission.User_settings.edit == false) {
    //   this.editPermission.push('setting');
    // }
    // if (arr.permission.User_settings.view == false) {
    //   this.viewPermission.push('setting');
    // }
    // if (arr.permission.Schema.edit == false) {
    //   this.editPermission.push('schema');
    // }
    // if (arr.permission.Schema.view == false) {
    //   this.viewPermission.push('Schema');
    // }
    // if (arr.permission.Test_data.view == false) {
    //   this.viewPermission.push('Database', 'Execute');
    // }
    // if (arr.permission.Test_data.edit == false) {
    //   this.editPermission.push('testdata', 'execute');
    // }
    // if (arr.permission.Rules.view == false) {
    //   this.viewPermission.push('Business rule');
    // }
    // if (arr.permission.Rules.edit == false) {
    //   this.editPermission.push('Business rule');
    // }
    // if (this.editPermission.includes('execute') == true) {
    //   //
    //   //   this.editPermission.includes(""),
    //   //   this.editMode,
    //   //   this.editPermission,
    //   //   "done"
    //   // );
    //   this.editMode = false;
    // }

    // this.$store.commit('token', this.$route.params.token);
    this.duplicateTheads = JSON.parse(JSON.stringify(this.theads));
    this.ruleValidateDataCalling(this.currPage, this.rowsPerPage);
    // await this.getAllExecData();
  },
  methods: {
    computedTableAction() {
      if (this.permisstionData.delete && this.permisstionData.edit) {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
          {
            label: "Execute",
            value: "execute",
            icon: "icon-code-regular",
          },
          {
            label: "View",
            value: "view",
            icon: "icon-eye-regular",
          },
        ];
      } else if (this.permisstionData.delete) {
        return [
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
          {
            label: "Execute",
            value: "execute",
            icon: "icon-code-regular",
          },
          {
            label: "View",
            value: "view",
            icon: "icon-eye-regular",
          },
        ];
      } else {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Execute",
            value: "execute",
            icon: "icon-code-regular",
          },
          {
            label: "View",
            value: "view",
            icon: "icon-eye-regular",
          },
        ];
      }
    },
    reAssignOrgTableHeader() {
      // If there is change in the table header then ressign to the Orginal data
      if (
        this.theadsC != this.tableHeaderOrgcc &&
        this.tableHeaderOrgcc.length > 0
      ) {
        this.theadsC = JSON.parse(JSON.stringify(this.tableHeaderOrgcc));
      }
      if (
        this.theads != this.tableHeaderOrg &&
        this.tableHeaderOrg.length > 0
      ) {
        this.theads = JSON.parse(JSON.stringify(this.tableHeaderOrg));
      }
      this.re_arrange = true;
    },
    resizedWidth(val) {
      console.log(val, "resizedWidth", val);

      const updateWidth = (array) => {
        let item = array.find((i) => i?.prop === val?.prop);
        if (item) item.width = val.width;
      };

      updateWidth(this.theads);
      updateWidth(this.theadsC);
      console.log(this.theads, "resizedWidth loop", this.theadsC);
      this.applyColumns(this.theadsC);
    },
    async ruleValidateDataCalling(
      rulevalidateCurrentPage,
      rulevalidateRowsPerPage
    ) {
      this.ruleValidateDataCalling["company"] = this.company;

      this.rulevalidatequery["paginatedQuery"]["currentPage"] =
        rulevalidateCurrentPage;
      this.rulevalidatequery["paginatedQuery"]["rowsPerPage"] =
        rulevalidateRowsPerPage;
      let filterResultData = await DMNAxiosInstance.post(
        `util/filterData`,
        this.rulevalidatequery
      );
      if (filterResultData.data.ok == 1) {
        filterResultData = filterResultData.data;
        this.totalPageNo = filterResultData.data.total;

        this.tableData = filterResultData.data.data;
        this.duplicate_table_data = this.tableData;
      }
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = objectDate.toLocaleString("default", { month: "short" });
          let year = objectDate.getFullYear();
          let dateformat = month + " " + date + ", " + year;
          return dateformat;
        }
      }
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    search(val) {
      //
      this.searchvalue = val;
      if (val != "") {
        this.rulevalidatequery["type"] = "search";
      } else {
        this.rulevalidatequery["type"] = "filter";
      }
      this.rulevalidatequery["searchQuery"] = this.searchvalue;
      this.currPage = 1;
      // this.rowsPerPage = 10;
      this.ruleValidateDataCalling(this.currPage, this.rowsPerPage);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return new Intl.DateTimeFormat("en-US", options)
        .format(date)
        .replace(",", "");
    },
    navTo(active) {
      this.$store.commit("token", this.$route.params.token);
      //
      if (active.name == "Home") {
        this.navHome();
      } else if (active.name == "logout") {
        this.logout();
      } else if (active.name == "settings") {
        this.setting();
      } else if (active.name == "Schema") {
        this.schema();
      } else if (active.name == "Business rule") {
        this.ruled();
      } else if (active.name === "Database") {
        this.execute();
      } else if (active.name == "Execute") {
        this.exe();
      } else if (active.name == "Help") {
        this.help();
      } else {
        this.audit();
        //
      }
    },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    help() {
      window.open("http://documentation.halleyx.com");
    },
    ruled() {
      this.$router.push(`/DMN/business_rules`);
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {
      this.$router.push(`/DMN/schemas`);
    },

    logout() {
      this.$router.push("/");
    },
    setting() {
      this.$router.push(`/settings`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },

    async deleteExecRow() {
      this.delete_modal_active = false;
      await DMNAxiosInstance.delete(
        `/rule_engine/execution_data/${this.delete_id}`
      )
        .then(() => {
          this.showAlert = true;
          this.ruleValidateDataCalling(this.currPage, this.rowsPerPage);
          if (this.paginatedData.length === 1) {
            this.currPage = this.currPage - 1;
          }
          setTimeout(() => {
            this.showAlert = false;
          }, 3000);
        })
        .catch((error) => {
          error;
          //
          this.showAlert = true;
          this.alertMessage = "Cannot delete row. Try again!";
          this.alertType = "danger";
          setTimeout(() => {
            this.showAlert = false;
          }, 3000);
        });
    },
    async getAllExecData() {
      this.logs = [];
      await DMNAxiosInstance.get(
        `/execution_logs?category=Execution data`
      ).then((res) => {
        this.logs = res.data.data;
        //
      });
      await DMNAxiosInstance.get(`/rule_engine/execution_data/`)
        .then((response) => {
          // Handle the response here
          // this.schemaOptions = response.data
          this.tableData = response.data;

          //
          this.tableData = this.tableData.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );
          this.tableData.forEach((e, index) => {
            e["index"] = index;
            e.context = false;
          });

          // let result = [];
          // result = this.tableData.map((obj1) => {
          //   const matchedData = this.logs.filter(
          //     (obj2) => obj2.id === obj1._id
          //   );
          //   const execCount = matchedData.length;
          //   const x =
          //     matchedData.reduce((sum, obj) => sum + obj.executedDuration, 0) /
          //       matchedData.length || 0;
          //   const averageExecDuration = x.toFixed(2) + " ms";
          //   return { ...obj1, matchedData, averageExecDuration, execCount };
          // });

          // this.tableData = result;

          this.duplicateTableData = JSON.parse(JSON.stringify(this.tableData));
        })
        .catch((error) => {
          // Handle the error here
          console.error(error);
        });

      document.addEventListener("click", (event) => {
        if (!event.target.classList.contains("add-btn")) {
          this.show_add_menu = false;
        }
        if (!event.target.classList.contains("icon-more-vertical-filled")) {
          // const elements = document.querySelectorAll("[class*=crud]");
          // for (const element of elements) {
          //   element.style.display = "none";
          // }

          if (!event.target.classList.contains("icon-more-vertical-filled")) {
            this.tableData.forEach((e) => {
              if (e.context !== undefined) {
                e.context = false;
              }
            });
          }
        }
      });
    },
    crudContextMenu($event, index) {
      // this.$nextTick(()=>{
      if (document.querySelector("#table-context" + index)) {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 102;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
      }
      // })

      if ($event.target.className === "icon-more-vertical-filled") {
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
        this.tableData[index].context = true;
        //
      } else {
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    searchKey(val) {
      this.searchTerm = val;
    },
    gotoDataExecuteView(id) {
      this.$router.push({
        name: "execute-data",
        query: { id: id },
      });
    },
    showAddContextMenu() {
      this.$router.push({
        name: "execute-add-manual",
        query: {},
      });
      // this.show_add_menu = !this.show_add_menu;
    },
    showListCrud(index) {
      if (document.querySelector(".crud" + index).style.display === "block") {
        document.querySelector(".crud" + index).style.display = "none";
      } else {
        const elements = document.querySelectorAll("[class*=crud]");
        for (const element of elements) {
          element.style.display = "none";
        }
        document.querySelector(".crud" + index).style.display = "block";
      }
    },
    fromCrudContextMenu($event, val) {
      if ($event === "Execute") {
        this.gotoDataExecuteView(val);
      }
    },
    fromAddContextMenu(val) {
      if (val === "Manual") {
        this.$router.push({
          name: "execute-add-manual",
          query: {},
        });
      } else if (val === "API") {
        this.$router.push({
          name: "execute-add-api",
          query: {},
        });
      } else if (val === "Upload") {
        this.$router.push({
          name: "execute-add-upload",
          query: {},
        });
      }
    },
    async applyColumns(val) {
      console.log("applyColumns", val);
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      let concatedEntity = sampleTheads?.data?.entityRef || {};

      concatedEntity.ruleValidation = val;

      let reArrangeTableHeadPayload = { entityRef: concatedEntity };

      const changeTableHeader = await MLTAxiosInstance.patch(
        `/settings`,
        reArrangeTableHeadPayload
      );
      console.log(
        changeTableHeader,
        "reArrangeTableHeadPayload",
        reArrangeTableHeadPayload
      );

      let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
      console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

      let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
      this.theadsC = entityRef["ruleValidation"];
      this.theads = this.theadsC.filter((ele) => ele.checked);
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.theadsC));
      this.tableHeaderOrg = JSON.parse(JSON.stringify(this.theads));
    },
    updateTheads() {
      // Use the map function to update the 'checked' property
      this.theads = this.theads.map((thead) => {
        // Check if the current thead has a matching 'prop' in duplicateTheads
        const isMatching = this.duplicateTheads.some(
          (duplicateThead) => duplicateThead.prop === thead.prop
        );

        // Set the 'checked' property based on whether it's matching
        return { ...thead, checked: isMatching };
      });
    },
    arrange(val) {
      this.updateTheads();
      //
      this.re_arrange = val;
    },
    currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
        this.ruleValidateDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    changeRowsPerPage(count) {
      //
      if (this.company != "") {
        this.rowsPerPage = count;
        this.ruleValidateDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    sorting_Data(data, data1) {
      //
      console.log(data, data1);
      this.rulevalidatequery.type = "filter";
      if (data === "icon-angle-up-small-filled sorting-icon") {
        this.rulevalidatequery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = 1;
        this.rulevalidatequery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ruleValidateDataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        this.rulevalidatequery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = -1;
        this.rulevalidatequery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ruleValidateDataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-up-small-filled") {
        this.rulevalidatequery.isNeedToBeSort = false;
        let obj = {};
        this.rulevalidatequery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ruleValidateDataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled") {
        this.rulevalidatequery.isNeedToBeSort = false;
        let obj = {};
        this.rulevalidatequery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ruleValidateDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    Contextmenu(index) {
      if (event.target.className === "icon-more-vertical-filled") {
        this.tableData[index].context.show = true;
      } else {
        // this.clicked = false;
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context.show = false;
          }
        });
      }
      // //
    },
    closeAddContextMenu(data1, data2) {
      const indexvalue = parseInt(data2);
      indexvalue;
      //
      this.fromAddContextMenu(data1);
    },
    closeCrudContextMenu($event, i) {
      if ($event === "Edit") {
        this.$router.push({
          name: "edit-execute-data",
          query: { mode: i.inputMode, id: i._id },
        });
      } else if ($event === "Delete") {
        // this.$router.push({
        //   name: "execute-add-api",
        // });
        // //
        this.delete_id = i._id;
        this.delete_name = i.name;
        this.delete_modal_active = true;
      } else if ($event === "Execute") {
        this.$router.push({
          name: "execute-data",
          query: { id: i._id, schema: i.schemaName },
        });
      } else if ($event === "View") {
        this.$router.push({
          name: "view-execute-data",
          query: { mode: i.inputMode, id: i._id },
        });
      }
    },
    gotoViewPage(i) {
      this.$router.push({
        name: "view-execute-data",
        query: { mode: i.inputMode, id: i._id },
      });
    },
  },
};
</script>

<style></style>
