<template>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete email message</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>Are you sure you want to delete the email message ?</p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="closeEmail"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <hlx-modal
    :modal-active="valid_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="validationemail()"
  >
    <template #header>
      <div style="margin-left: 0px">Email Validation</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>{{ `${combinedMessage}` }}</p>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="validationemail()"
        >Ok</hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />

  <div>
    <div
      v-show="showEmailModal == true"
      class="email_overlay"
      :class="{ expandedEmail: isExpanded }"
      style="width: 100%"
    >
      <div class="emailCrudModal" :class="{ expandedCurdEmail: isExpanded }">
        <!-- header for email  -->

        <div class="height_fit_for_body">
          <div
            v-show="showAllEmail"
            class="email_cc showAllEmail"
            style="justify-content: flex-start; color: #000; font-size: 14px"
            @click="showToCcBcc"
          >
            {{ firstTwoValue }}
            <span
              v-if="moreValueToCc > 0 || moreValueBcc > 0"
              class="moredetail_email"
            >
              <span v-if="moreValueToCc > 0">{{ moreValueToCc + "more" }}</span>
              <span v-if="moreValueBcc > 0">{{ `(${moreValueBcc}Bcc)` }}</span>
            </span>
          </div>
          <!-- To CC Bcc subject for email  -->
          <div v-show="showTo" class="email_cc">
            <div class="email_cc_to">To:</div>
            <div class="email_input">
              <div class="tag-email-container">
                <div class="container-input">
                  <div v-for="(i, index) in displayValueTo" :key="index">
                    <span>
                      <span v-if="defaultContain && index === 0">
                        <hlx-label
                          class="primary"
                          :options="{
                            'background-color': '#54bd95',
                            'text-color': 'white',
                            'border-color': '#54bd95',
                          }"
                          >{{ i.name }}</hlx-label
                        >
                      </span>
                      <span v-else>
                        <hlx-tag
                          class="tagToEmail"
                          :limited="true"
                          @tag-close="
                            removed(
                              tagValueTo,
                              index,
                              allTagsValue,
                              displayValueTo
                            )
                          "
                          >{{ i.name }}</hlx-tag
                        >
                      </span>
                    </span>
                  </div>
                  <input
                    ref="inputFieldto"
                    v-model="toEmailInput"
                    type="email"
                    @keyup.enter="
                      addvalueArray(
                        tagValueTo,
                        toEmailInput,
                        'inputFieldto',
                        displayValueTo
                      )
                    "
                    @blur="
                      addvalueArray(
                        tagValueTo,
                        toEmailInput,
                        'inputFieldto',
                        displayValueTo
                      )
                    "
                  />
                </div>
                <div></div>
              </div>
            </div>
            <div
              class="email_cc__BCC_Cc"
              :style="{ display: showCc || showBcc ? 'none' : 'flex' }"
            >
              <span
                :style="{ display: showCc ? 'none' : 'block' }"
                class="email_cc_Cc"
                @click="showCc = true"
                >Cc</span
              >
              <span
                :style="{ display: showBcc ? 'none' : 'block' }"
                style="margin-left: 16px"
                class="email_cc_Bcc"
                @click="showBcc = true"
                >Bcc</span
              >
            </div>
          </div>

          <div
            v-show="showEmailDescriptionTo"
            ref="emailDescriptionTo"
            class="email_description_option postion_description_to"
          >
            <div
              v-for="(email, index) in filteredData"
              :key="index"
              class="email_options_container"
              @click="
                addvalueArray(
                  tagValueTo,
                  email.email,
                  'inputFieldto',
                  displayValueTo,
                  email
                )
              "
            >
              <div class="email_options">
                <span
                  ><hlx-file-upload
                    :key="componentkey"
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Circle'"
                    :style-background="backgrounStyleupload"
                    :height="32"
                    :width="32"
                    :read-only="true"
                    :custom-icon="icon"
                    :type="'profile-upload'"
                    :name-display="email?.name"
                    :content="'Import'"
                    @fileData="profiledata"
                /></span>
                <span
                  style="
                    padding: 6px;
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                  "
                >
                  <span>{{ email?.name }}</span>
                  <span style="font-size: 12px">{{ email?.email }}</span>
                </span>
              </div>
            </div>
          </div>

          <div v-show="showCc" class="email_cc">
            <div class="email_cc_to">CC:</div>
            <div class="email_input">
              <div class="tag-email-container">
                <div class="container-input">
                  <div v-for="(i, index) in displayValueCc" :key="index">
                    <hlx-tag
                      :limited="true"
                      @tag-close="
                        removed(tagValueCc, index, allTagsValue, displayValueCc)
                      "
                      >{{ i.name }}</hlx-tag
                    >
                  </div>
                  <input
                    ref="inputFieldcc"
                    v-model="ccEmailInput"
                    type="email"
                    class="email-input"
                    @keyup.enter.prevent="
                      addvalueArray(
                        tagValueCc,
                        ccEmailInput,
                        'inputFieldcc',
                        displayValueCc
                      )
                    "
                    @blur="
                      addvalueArray(
                        tagValueCc,
                        ccEmailInput,
                        'inputFieldcc',
                        displayValueCc
                      )
                    "
                  />
                </div>
                <div>
                  <!-- @input="adjustInputSize($event.target)" -->
                </div>
              </div>
            </div>
            <span
              :style="{ display: showBcc ? 'none' : 'block' }"
              style="margin-left: 16px"
              class="email_cc_Bcc"
              @click="showBcc = true"
              >Bcc</span
            >
          </div>
          <div
            v-show="showEmailDescriptionCc"
            ref="emailDescriptionCc"
            class="email_description_option postion_description_cc"
          >
            <div
              v-for="(email, index) in filteredData"
              :key="index"
              class="email_options_container"
              @click="
                addvalueArray(
                  tagValueCc,
                  email.email,
                  'inputFieldcc',
                  displayValueCc,
                  email
                )
              "
            >
              <div class="email_options">
                <span
                  ><hlx-file-upload
                    :key="componentkey"
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Circle'"
                    :style-background="backgrounStyleupload"
                    :height="32"
                    :width="32"
                    :read-only="true"
                    :custom-icon="icon"
                    :type="'profile-upload'"
                    :name-display="email?.name"
                    :content="'Import'"
                    @fileData="profiledata"
                /></span>
                <span
                  style="
                    padding: 6px;
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                  "
                >
                  <span>{{ email?.name }}</span>
                  <span style="font-size: 12px">{{ email?.email }}</span>
                </span>
              </div>
            </div>
          </div>
          <div v-show="showBcc" class="email_cc">
            <div class="email_cc_to">Bcc:</div>
            <div class="email_input">
              <div class="tag-email-container">
                <div class="container-input">
                  <div v-for="(i, index) in displayValueBcc" :key="index">
                    <hlx-tag
                      :limited="true"
                      @tag-close="
                        removed(
                          tagValueBcc,
                          index,
                          allTagsValue,
                          displayValueBcc
                        )
                      "
                      >{{ i.name }}</hlx-tag
                    >
                  </div>
                  <input
                    ref="inputFieldbcc"
                    v-model="bccEmailInput"
                    type="email"
                    class="email-input"
                    @keyup.enter.prevent="
                      addvalueArray(
                        tagValueBcc,
                        bccEmailInput,
                        'inputFieldbcc',
                        displayValueBcc
                      )
                    "
                    @blur="
                      addvalueArray(
                        tagValueBcc,
                        bccEmailInput,
                        'inputFieldbcc',
                        displayValueBcc
                      )
                    "
                  />
                </div>
                <div>
                  <!-- @input="adjustInputSize($event.target)" -->
                </div>
              </div>
            </div>
            <span
              :style="{ display: showCc ? 'none' : 'block' }"
              class="email_cc_Cc"
              @click="showCc = true"
              >Cc</span
            >
          </div>
          <div
            v-show="showEmailDescriptionBcc"
            ref="emailDescriptionBcc"
            class="email_description_option postion_description_bcc"
          >
            <div
              v-for="(email, index) in filteredData"
              :key="index"
              class="email_options_container"
              @click="
                addvalueArray(
                  tagValueBcc,
                  email.email,
                  'inputFieldbcc',
                  displayValueBcc,
                  email
                )
              "
            >
              <div class="email_options">
                <span
                  ><hlx-file-upload
                    :key="componentkey"
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Circle'"
                    :style-background="backgrounStyleupload"
                    :height="32"
                    :width="32"
                    :read-only="true"
                    :custom-icon="icon"
                    :type="'profile-upload'"
                    :name-display="email?.name"
                    :content="'Import'"
                    @fileData="profiledata"
                /></span>
                <span
                  style="
                    padding: 6px;
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                  "
                >
                  <span>{{ email?.name }}</span>
                  <span style="font-size: 12px">{{ email?.email }}</span>
                </span>
              </div>
            </div>
          </div>
          <div
            class="email_subject"
            @click="
              concatEmail(displayValueTo, displayValueCc, displayValueBcc)
            "
          >
            <div class="email_input" style="margin: 0 10px 0 0">
              <input
                v-model="subjectEmailInput"
                type="text"
                placeholder="Subject"
              />
            </div>
            <!-- @input="adjustInputSize($event.target)" -->
          </div>
        </div>
        <!-- body text-editor component for email -->
        <div
          class="attachmeny_email_"
          style="max-height: 517px; overflow: auto"
        >
          <div
            class="email_text_editor_body"
            @click="
              concatEmail(displayValueTo, displayValueCc, displayValueBcc)
            "
          >
            <!-- <hlx-text-editor
              :key="componentKey"
              v-model="bodyContentv"
              :email-component="true"
              :border="false"
              @current-change="handleCurrentChange"
            ></hlx-text-editor> -->

            <hlx-text-editor
              v-model:modelValue="bodyContentv"
              :emailtriggers="true"
              :slot-options="true"
              :height="'450px'"
              :border="false"
              :read-only="false"
              :language-options="[]"
              :template-options="[]"
              :email-component="true"
              @current-change="texteditorvalue"
            >
            </hlx-text-editor>
          </div>
          <!-- :template-preval=""
          :language-preval="" -->
          <div
            v-if="filesArrayData.length > 0"
            class="box-attachment-supplier-parent-email"
          >
            <div
              v-for="(box, index) in filesArrayData"
              :key="index"
              class="box-attachment-supplier"
            >
              <span class="closeIconeSupplier" @click="handleClose(box, index)"
                ><i style="color: #6e6b7b" class="icon-trash-regular"></i
              ></span>
              <div>
                <span
                  v-if="getFileExtension(box.properties.name) === 'txt'"
                  class="upload_component_icon"
                  ><img
                    class="file-icon"
                    src="../assets/svg/multiUpload/text.svg"
                    alt=""
                /></span>
                <span
                  v-if="getFileExtension(box.properties.name) === 'docx'"
                  class="upload_component_icon"
                  ><img
                    class="file-icon"
                    src="../assets/svg/multiUpload/word.svg"
                    alt=""
                /></span>
                <span
                  v-if="getFileExtension(box.properties.name) === 'png'"
                  class="upload_component_icon"
                  ><img
                    class="file-icon"
                    src="../assets/svg/multiUpload/png.svg"
                    alt=""
                /></span>
                <span
                  v-if="getFileExtension(box.properties.name) === 'pdf'"
                  class="upload_component_icon"
                  ><img
                    class="file-icon"
                    src="../assets/svg/multiUpload/pdf.svg"
                    alt=""
                /></span>
                <span
                  v-if="getFileExtension(box.properties.name) === 'json'"
                  class="upload_component_icon"
                  ><img
                    class="file-icon"
                    src="../assets/svg/multiUpload/json.svg"
                    alt=""
                /></span>
                <span
                  v-if="getFileExtension(box.properties.name) === 'jpg'"
                  class="upload_component_icon"
                  ><img
                    class="file-icon"
                    src="../assets/svg/multiUpload/jpg.svg"
                    alt=""
                /></span>
                <span
                  v-if="getFileExtension(box.properties.name) === 'jpeg'"
                  class="upload_component_icon"
                  ><img
                    class="file-icon"
                    src="../assets/svg/multiUpload/jpeg.svg"
                    alt=""
                /></span>
                <span
                  v-if="getFileExtension(box.properties.name) === 'xlsx'"
                  class="upload_component_icon"
                  ><img
                    class="file-icon"
                    src="../assets/svg/multiUpload/excel.svg"
                    alt=""
                /></span>
              </div>
              <div style="display: flex; flex-direction: column">
                <span
                  class="attachmnet_uderline_email"
                  style="
                    font-weight: 400;
                    font-size: 12px;
                    white-space: nowrap;
                    width: 240px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  @click="attachmnetname(box.properties.name)"
                >
                  {{ box.properties.name }}
                </span>
              </div>
              <div v-if="box.loaderData === true">
                <hlx-loader load-type="horizontal-loop" />
              </div>
            </div>
          </div>

          <slot></slot>
        </div>
        <!-- footer for email -->
      </div>
    </div>
  </div>
</template>
<script>
// import { MLTAxiosInstance } from "@/config/axiosConfig";
// import hlxLabel from "./LabelComponent.vue";
// import HlxFileUpload from "./FileUploadComponent.vue";
// import HlxTextEditor from "./TexteditorComponent.vue";
// import HlxButton from "./ButtonComponent.vue";
// import hlxModal from "./ModalwindowComponent.vue";
// import hlxTag from "./TagComponent.vue";
// import hlxLoader from "./LoaderComponent.vue";

export default {
  // name: "HlxEmail",
  components: {
    // hlxLoader,
    // HlxFileUpload,
    // hlxLabel,
    // hlxTag,
    // HlxTextEditor,
    // hlxModal,
    // HlxButton,
  },
  props: {
    isLoader: {
      type: Boolean,
      default: false,
    },
    loadAttachmnet: {
      type: Boolean,
      default: false,
    },
    recipient: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: "",
    },
    bodyContent: {
      type: String,
      default: "",
    },
    subjectContent: {
      type: String,
      default: "",
    },
    defaultTo: {
      type: String,
      default: "",
    },
    fileType: {
      type: String,
      default: ".json,.xlsx,.doc,.pdf,.txt,.jpg,.jpeg,.png",
    },
    fileSizeLimit: {
      type: String,
      default: "1 GB",
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "upload-data",
    "close",
    "at-input",
    "recipient-detail",
    "body-content",
    "attachments",
    "updated-email-content",
    "attachment-name",
  ],
  data() {
    return {
      // buttonDisabled:true,
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#000000",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 12,
      },
      filesArrayData: [],
      valid_modal_active: false,
      bodyContentv: `<html>

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Email Template</title>
    <style>
        body {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            color: #333;
        }

        .container {
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
            background-color: #ffffff;
            border: 1px solid #ddd;
            border-radius: 5px;
        }

        h1,
        h2 {
            font-size: 16px;
            margin-bottom: 10px;
        }

        h2 {
            padding-bottom: 5px;
        }

        .section-title {
            font-weight: bold;
            margin: 20px 0 10px;
        }

        .details {
            margin-bottom: 20px;
        }

        .details p {
            margin: 5px 0;
        }

        .details span {
            font-weight: bold;
        }

        .table-container {
            margin-top: 20px;
            overflow: auto;
        }

        table {
            width: 100%;
            border-collapse: collapse;
        }

        th,
        td {
            text-align: left;
            border: 1px solid #ddd;
            padding: 8px;
        }

        th {
            background-color: #0a5ea5;
            color: #ffffff;
        }

        .footer {
            margin-top: 20px;
            font-size: 9px;
            text-align: center;
            color: #888;
        }

        a {
            color: #4b92c9;
            font-weight: normal;
            text-decoration: none;
        }

        @media screen and (max-width: 600px) {
            .container {
                padding: 10px;
            }

            table th,
            table td {
                font-size: 9px;
            }

            .footer {
                font-size: 8px;
            }
        }
    </style>
</head>

<body>
    <div class="container">
        <table width="100%" cellspacing="0" cellpadding="0" style="padding: 20px;">
            <tbody>
                <tr>
                    <td align="center" style="border:none">
                        <table width="600" cellspacing="0" cellpadding="0"
                            style="border-radius: 8px; overflow: hidden;">
                            <!-- Image Section -->
                            <tbody>
                                <tr>
                                    <td style="width: 50%; padding: 10px 10px 10px 30px;border:none"> <img
                                            src="https://storage.googleapis.com/halleyx-public-assests/bell-logo"
                                            alt="Image 1"
                                            style="width: 100%; max-width: 100px; height: 100px; display: block; border: none;">
                                    </td>
                                    <td style="width: 50%; padding: 0; margin: 0; vertical-align: top;border:none">
                                        <img src="https://storage.googleapis.com/halleyx-public-assests/bell-bg"
                                            alt="Image 2"
                                            style="width: 100%; max-width: 150px; height: auto; display: block; border: none;">
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 50%; padding: 10px 10px 10px 58px;border:none">
                                        <h2 style="margin:0;padding:0;font-size:16px">Bell Fibe</h2>
                                        <h2 style="margin:0;padding:0;font-size:16px">New lead created</h2>
                                        <!-- <img src="https://storage.googleapis.com/halleyx-public-assests/text-bell" alt="Image 3" style="width: 100%; max-width: 150px; height: auto; display: block; border: none;" /> -->
                                    </td>
                                    <td style="width: 50%; padding: 10px 10px 10px 58px;border:none"> <img
                                            src="https://storage.googleapis.com/halleyx-public-assests/bell-equipment"
                                            alt="Image 4"
                                            style="width: 100%; max-width: 180px; height: 180px; display: block; border: none;">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>Dear {{salesLead.networkManager.name}},</p>
        <p>A new lead has been created, and your approval of the <b>Network</b> is required. Please review the lead
            details below and take the appropriate action to approve or reject the Network ID.</p>
        <h2>Lead Information:</h2>
        <div class="details">
            <p><b>Company Name:</b> {{salesLead.name}}</p>
            <p><b>Lead Type:</b> {{salesLead.type}}</p>
            <p><b>Lead Source:</b> {{salesLead.leadSource}}</p>
            <p><b>Lead Category:</b> {{salesLead.category}}</p>
            <p><b>Lead Status:</b> {{salesLead.status}}</p>
            <p><b>Lead Owner:</b> {{salesLead.leadOwner.name}}</p>
        </div>
        <h2>Site Details:</h2>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Site name</th>
                        <th>Site address</th>
                        <th>Market segment</th>
                        <th>Number of Units</th>
                        <th>Network ID</th>
                    </tr>
                </thead>
                <tbody>
                    {{#salesLead.sites}}
                    <tr>
                        <td>{{name}}</td>
                        <td>{{address.description}}</td>
                        <td>{{marketSegment}}</td>
                        <td>{{numberOfUnits}}</td>
                        <td>{{networkId}}</td>
                    </tr>
                    {{/salesLead.sites}}
                </tbody>
            </table>
        </div>
        <hr>
        <p>Please <a href="{{leadApprovalFormLink}}">click here</a> to submit your approval.</p>
        <div class="footer">
            <p> If you have any queries, please feel free to reach out to our support team by email at <a
                    href="mailto:atelier.filth@bell.ca">atelier.filth@bell.ca</a> or by phone at 1-877-247-5888. </p>
            <p> <a href="#">About Bell</a> | <a href="#">Legal</a> | <a href="#">Preferences</a> | <a
                    href="#">Privacy</a>
            </p>
            <p> Corporate Secretary's Office of Bell Canada, Bell Satellite TV (Bell ExpressVu), Bell Mobility, Bell
                Media,
                and Bell Aliant. </p>
            <p> 1 Carrefour Alexander-Graham-Bell, Building A-7, Verdun, Quebec, H3E 3B3 </p>
            <p>© 2024, Bell Canada. All rights reserved.</p>
        </div>
    </div>
</body>

</html>`,
      defaultContain: false,
      isExpanded: false,
      enteredValues: [],
      overflowCount: 0,
      filesArray: [],
      filesArrayGet: [],
      inputValue: "",
      showAllEmail: false,
      filterValue: "",
      delete_modal_active: false,
      showEmailDescriptionTo: false,
      showEmailDescriptionCc: false,
      showEmailDescriptionBcc: false,
      showEmailModal: false,
      showTo: true,
      showCc: false,
      showBcc: false,
      toEmailInput: "",
      ccEmailInput: "",
      bccEmailInput: "",

      defaultValue: "",
      firstTwoValue: "",
      moreValueToCc: 0,
      moreValueBcc: 0,
      componentKey: 0,
      template: "",
      notification: {},
      tagValueTo: [],
      tagValueCc: [],
      tagValueBcc: [],
      displayValueTo: [],
      displayValueCc: [],
      displayValueBcc: [],
      allTagsValue: [],
      allTagsValuetest: [],
      dummy: [],
      moreMessage: "",
      isSendButtonDisabled: true,
      errorHandule: false,
      wrongMailTo: "",
      wrongMailCc: "",
      wrongMailBcc: "",
      combinedMessage: "",
    };
  },
  // computed: {
  //   isSendButtonDisabled() {
  //     return this.allTagsValuetest.length === 0;
  //   },
  // },

  // || this.template.trim() === "";

  watch: {
    loadAttachmnet: {
      handler(val) {
        console.log(this.filesArrayData, "before false");

        if (val === false) {
          this.filesArrayData = this.filesArrayData.map((item) => ({
            ...item,
            loaderData: false,
          }));
        }
        console.log(val, "valisLoadervar", this.filesArrayData);
      },
      immediate: true,
      deep: true,
    },

    toEmailInput: {
      handler(val) {
        if (val !== "") {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(val)) {
            this.errorHandule = true;
          }
          this.filteredData = this.filterData(val);
          this.showEmailDescriptionTo = true;
        } else {
          this.showEmailDescriptionTo = false;
          this.errorHandule = false;
        }
      },
      immediate: true,
      deep: true,
    },
    allTagsValuetest: {
      handler(val) {
        console.log(val.length);
        if (val.length > 0) {
          this.isSendButtonDisabled = false;
        } else {
          this.isSendButtonDisabled = true;
        }
      },
      immediate: true,
      deep: true,
    },
    tagValueBcc: {
      handler(val) {
        console.log(val.length);
        this.moreValueBcc = val.length;
      },
      immediate: true,
      deep: true,
    },
    subjectContent: {
      handler(val) {
        this.subjectEmailInput = val;
      },
      immediate: true,
      deep: true,
    },
    // bodyContent: {
    //   handler(val) {
    //     this.bodyContentv = val;
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    show: {
      handler(val) {
        console.log(val, "showval");
        this.showEmailModal = val;
        if (val == true) {
          if (this.defaultValue) {
            this.defaultContain = true;
            this.isSendButtonDisabled = false;
            this.tagValueTo.unshift(this.defaultValue.email);
            this.displayValueTo.unshit(this.defaultValue);
          }
        }
        val = false;
      },
      immediate: true,
      deep: true,
    },
    filesArray: {
      handler(val) {
        console.log(val, "filesarray");

        this.updatedemailcontent();
        this.filesArrayGet = val;
        console.log(this.filesArrayGet, "filesArrayGet");
      },
      immediate: true,
      deep: true,
    },
    ccEmailInput: {
      handler(val) {
        if (val !== "") {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(val)) {
            this.errorHandule = true;
          }
          this.filteredData = this.filterData(val);
          this.showEmailDescriptionCc = true;
        } else {
          this.errorHandule = false;
          this.showEmailDescriptionCc = false;
        }
      },
      immediate: true,
      deep: true,
    },
    firstTwoValue: {
      handler(val) {
        console.log(val);
      },
      immediate: true,
      deep: true,
    },

    defaultTo: {
      handler(val) {
        if (val) {
          if (val.length > 0) {
            this.defaultValue = val;
            this.tagValueTo.push(val);
            this.displayValueTo.push({ email: val, name: val });
            this.defaultContain = true;
            this.isSendButtonDisabled = false;
          }
        }
      },
      immediate: true,
      deep: true,
    },

    bccEmailInput: {
      handler(val) {
        if (val !== "") {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(val)) {
            this.errorHandule = true;
          }
          this.filteredData = this.filterData(val);
          this.showEmailDescriptionBcc = true;
        } else {
          this.errorHandule = false;
          this.showEmailDescriptionBcc = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  beforeUnmount() {
    window.removeEventListener("click", this.closeDescriptionOptions);
    window.removeEventListener("resize", this.checkOverflow);
  },
  mounted() {
    console.log("keerthika");
    window.addEventListener("click", this.closeDescriptionOptions);
    this.checkOverflow();
    window.addEventListener("resize", this.checkOverflow);
  },
  methods: {
    updatedemailcontent() {
      const updatedEmail = {
        to: this.displayValueTo,
        cc: this.displayValueCc,
        bcc: this.displayValueBcc,
        subject: this.subjectEmailInput,
        template: this.template,
        attachments: this.filesArray,
      };

      this.$emit("updated-email-content", updatedEmail);
      console.log("updated-email-content", updatedEmail);
    },
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
    handleClose(val, index) {
      console.log(val, index);
      this.filesArrayGet.splice(index, 1);

      this.checkOverflow();
    },
    checkOverflow() {
      if (this.filesArrayGet.length > 0) {
        const container = this.$refs.containerBox;

        const horizontalOverflow =
          container?.scrollWidth > container?.offsetWidth;
        const verticalOverflow =
          container?.scrollHeight > container?.offsetHeight;

        console.log(horizontalOverflow, verticalOverflow);

        if (horizontalOverflow || verticalOverflow) {
          const horizontalCount = horizontalOverflow
            ? Math.ceil(
                ((container?.scrollWidth - container?.offsetWidth) /
                  container?.scrollWidth) *
                  this.filesArrayGet.length
              )
            : 0;

          const verticalCount = verticalOverflow
            ? Math.ceil(
                ((container?.scrollHeight - container?.offsetHeight) /
                  container?.scrollHeight) *
                  this.filesArrayGet.length
              )
            : 0;

          this.overflowCount = Math.max(horizontalCount, verticalCount);
        } else {
          this.overflowCount = 0;
        }
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleEnter() {
      if (this.inputValue.trim() !== "") {
        this.enteredValues.push(this.inputValue); // Add entered value to the beginning of the array
        this.inputValue = ""; // Clear the input field
      }
    },
    chooseFiles(event) {
      const files = event.target.files;
      var attachmnetData = [];
      if (files && files.length > 0) {
        const currentTime = new Date(); // Get current date and time
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          console.log(file, "file");
          file.loaderData = true;
          this.filesArray.push(file);
          attachmnetData.push(file);
          this.$emit("attachments", attachmnetData);
          console.log(file, "file");
          if (this.isValidFileType(file) && this.isValidFileSize(file)) {
            const reader = new FileReader();
            reader.onload = () => {
              const fileData = reader.result;
              const fileInfo = {
                properties: file,
                data: fileData,
                lastUploaded: currentTime,
                loaderData: true, // Store current date and time when adding the file
              };
              this.filesArrayData.push(fileInfo);

              setTimeout(() => {
                this.dummy.push(fileInfo);
              }, 300);
              this.checkOverflow();
            };
            reader.readAsText(file);
          } else {
            if (!this.isValidFileType(file)) {
              this.err_msg = `Invalid file type. Please select files of type ${this.fileType}.`;
            } else {
              this.err_msg = `File size exceeds the limit. Maximum size allowed is ${this.fileSizeLimit.toUpperCase()}.`;
            }
          }
        }
        console.log("Files array:", this.filesArray, this.filesArray.length);
      }
    },
    attachmnetname(attachmnetname) {
      this.$emit("attachment-name", attachmnetname);
    },
    addFiles(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      if (files && files.length > 0) {
        const currentTime = new Date(); // Get current date and time
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          this.filesArray.push(file);
          console.log(file, "file");
          if (this.isValidFileType(file) && this.isValidFileSize(file)) {
            const reader = new FileReader();
            reader.onload = () => {
              const fileData = reader.result;
              const fileInfo = {
                properties: file,
                data: fileData,
                lastUploaded: currentTime,
              };
              this.dummy.push(fileInfo);
              this.checkOverflow();
            };
            reader.readAsText(file);
          } else {
            if (!this.isValidFileType(file)) {
              this.err_msg = `Invalid file type. Please select files of type ${this.fileType}.`;
            } else {
              this.err_msg = `File size exceeds the limit. Maximum size allowed is ${this.fileSizeLimit.toUpperCase()}.`;
            }
          }
        }
        console.log("Files array:", this.filesArray, this.filesArray.length);
      }
    },
    getFileExtension(filename) {
      const parts = filename?.split(".");

      const fileExtension = parts[parts?.length - 1];

      return fileExtension;
    },
    isValidFileType(file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return this.fileType.split(",").includes("." + fileExtension);
    },
    isValidFileSize(file) {
      const fileSize = file.size;
      const maxSizeInBytes = parseInt(this.fileSizeLimit) * 1024 * 1024;
      return fileSize <= maxSizeInBytes;
    },
    adjustInputSize(inputElement) {
      inputElement.style.width = (inputElement.value.length + 1) * 8 + "px";
    },
    showToCcBcc() {
      console.log(Array.isArray(this.tagValueCc) && this.tagValueCc.length);
      this.showAllEmail = false;
      this.showTo = true;
      this.showCc =
        Array.isArray(this.tagValueCc) && this.tagValueCc.length > 0;
      this.showBcc =
        Array.isArray(this.tagValueBcc) && this.tagValueBcc.length > 0;
    },
    concatEmail(to, cc, bcc) {
      const children = to.concat(cc);
      this.allTagsValue = children.concat(bcc);
      console.log(this.allTagsValue);
      this.firstTwoValue = children.map((user) => user.email).join(", ");
      console.log(this.firstTwoValue, children, "children");

      this.moreValueToCc = children.length - 2;

      this.moreValueBcc = bcc.length;

      if (this.allTagsValue.length <= 0) {
        this.showTo = true;
        this.showBcc = false;
        this.showCc = false;
      } else {
        this.showAllEmail = true;
        this.showTo = false;
        this.showBcc = false;
        this.showCc = false;
      }
      console.log(
        this.allTagsValue,
        this.moreValueToCc,
        this.moreValueBcc,
        "all values"
      );
    },
    formatFileSize(bytes) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
    closeDescriptionOptions(event) {
      if (
        !this.$refs.emailDescriptionTo.contains(event.target) &&
        !this.$refs.emailDescriptionCc.contains(event.target) &&
        !this.$refs.emailDescriptionBcc.contains(event.target)
      ) {
        this.showEmailDescriptionTo = false;
        this.showEmailDescriptionCc = false;
        this.showEmailDescriptionBcc = false;
      }
    },
    setEmail(val) {
      console.log(val, "select value");
    },
    atInput() {
      this.$emit("at-input", this.toEmailInput);
    },
    filterData(val) {
      console.log(val, this.recipient, "recipient");
      const filterValue = val.trim().toLowerCase();
      const filtered = this.recipient.filter((item) =>
        item?.email?.toLowerCase().includes(filterValue)
      );
      return filtered.slice(0, 10);
    },

    removed(valueObject, index, allTagsValue, displayvalue) {
      valueObject.splice(index, 1);
      displayvalue.splice(index, 1);
      this.updatedemailcontent();
      this.allTagsValue = allTagsValue;
    },
    addvalueArray(tagValue, emailInput, refValue, displayValue, emailName) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (emailInput.length > 0) {
        if (emailRegex.test(emailInput)) {
          // Handle individual case
          if (this.type === "individual") {
            if (tagValue.length === 0 && !tagValue.includes(emailInput)) {
              tagValue.push(emailInput);
              this.addToDisplayValue(displayValue, emailInput, emailName);
            }
          }
          // Handle group case
          else if (!tagValue.includes(emailInput)) {
            tagValue.push(emailInput);
            this.addToDisplayValue(displayValue, emailInput, emailName);
          }

          this.handleSuccess(tagValue, refValue, emailInput);
        }
      }
    },

    addToDisplayValue(displayValue, emailInput, emailName) {
      if (emailName) {
        displayValue.push(emailName);
      } else {
        displayValue.push({ email: emailInput, name: emailInput });
      }
    },

    handleSuccess(tagValue, refValue, emailInput) {
      console.log(this.displayValue, "displayValue");
      this.$emit("recipient-detail", tagValue, refValue);
      this.updatedemailcontent();
      this.$refs[refValue].focus();
      this.allTagsValuetest.push(emailInput);
      this.resetEmailInputs();
      console.log(tagValue, emailInput);
    },

    resetEmailInputs() {
      this.showEmailDescriptionTo = false;
      this.showEmailDescriptionCc = false;
      this.showEmailDescriptionBcc = false;
      this.toEmailInput = "";
      this.ccEmailInput = "";
      this.bccEmailInput = "";
    },
    addvalueindividual(tagValue, EmailInput) {
      tagValue = EmailInput;

      EmailInput = "";
      this.showEmailDescriptionTo = false;
      this.showEmailDescriptionCc = false;
      this.showEmailDescriptionBcc = false;
      this.toEmailInput = "";
      this.ccEmailInput = "";
      this.bccEmailInput = "";
      console.log(tagValue, EmailInput);
    },
    handleInvalidEmail(toEmailInput, ccEmailInput, bccEmailInput) {
      let invalidEmails = [];
      let invalidFields = [];

      // Validate "To" field email(s)

      if (toEmailInput && !this.isValidEmail(toEmailInput)) {
        this.wrongMailTo = toEmailInput;
        invalidEmails.push(toEmailInput);
        invalidFields.push("To");
        console.log(this.wrongMailTo, "Invalid email in To field");
      }

      // Validate "Cc" field email(s)
      if (ccEmailInput && !this.isValidEmail(ccEmailInput)) {
        this.wrongMailCc = ccEmailInput;
        invalidEmails.push(ccEmailInput);
        invalidFields.push("Cc");
        console.log(this.wrongMailCc, "Invalid email in Cc field");
      }

      // Validate "Bcc" field email(s)
      if (bccEmailInput && !this.isValidEmail(bccEmailInput)) {
        this.wrongMailBcc = bccEmailInput;
        invalidEmails.push(bccEmailInput);
        invalidFields.push("Bcc");
        console.log(this.wrongMailBcc, "Invalid email in Bcc field");
      }

      // If any invalid emails are found, show the combined error message
      if (invalidEmails.length > 0) {
        this.showCombinedErrorMessage(invalidEmails, invalidFields);
      }
    },

    // Function to check if an email is valid (simple regex example)
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    showCombinedErrorMessage(invalidEmails, invalidFields) {
      // Join all invalid email addresses into one string
      const emailString = invalidEmails.map((email) => `"${email}"`).join(", ");

      // Join all invalid field names into one string
      const fieldString = invalidFields.join(" and ");

      // Combine the error message dynamically
      if (invalidEmails.length === 1) {
        this.combinedMessage = `The address ${emailString} in the "${fieldString}" field was not recognized. Please make sure that all addresses are properly formed.`;
      } else {
        this.combinedMessage = `The addresses ${emailString} in the "${fieldString}" fields were not recognized. Please make sure that all addresses are properly formed.`;
      }
    },
    validationemail() {
      this.valid_modal_active = false;
      this.combinedMessage = "";
    },
    async sendEmail() {
      let formData = new FormData();
      for (let i = 0; i < this.filesArrayGet.length; i++) {
        formData.append("files[]", this.filesArrayGet[i]);
      }
      let email = {
        to: this.tagValueTo,
        cc: this.tagValueCc,
        bcc: this.tagValueBcc,
        subject: this.subjectEmailInput,
        template: this.template,
        Attachments: this.filesArrayGet,
      };
      this.$emit("close");
      this.$emit("upload-data", email, formData);
      this.closeEmail();
    },
    closeEmail() {
      this.isExpanded = false;
      this.$emit("close");
      this.filesArrayGet = [];
      this.showEmailModal = false;
      this.delete_modal_active = false;
      this.showCc = false;
      this.showBcc = false;
      this.showAllEmail = false;
      this.showTo = true;
      this.firstTwoValue = "";
      this.moreMessage = "";
      this.tagValueTo = [];
      this.tagValueCc = [];
      this.tagValueBcc = [];
      this.toEmailInput = "";
      this.ccEmailInput = "";
      this.bccEmailInput = "";
      this.subjectEmailInput = "";
      this.template = "";
      this.componentKey += 1;
    },
    // async sendEmaiindividual() {
    //   let email = {
    //     to: this.toEmailInput,
    //     cc: this.ccEmailInput,
    //     bcc: this.bccEmailInput,
    //     subject: this.subjectEmailInput,
    //     template: this.template,
    //     Attachments: this.filesArray,
    //   };
    //   console.log(email, "hgfdsa");
    //   const sendEmail = await MLTAxiosInstance.post(`/email`, email);
    //   console.log(sendEmail, "value send email");
    //   if (this.toEmailInput != "") {
    //     this.notification = {
    //       type: "success",
    //       message: "User invitation email sent",
    //     };
    //   }

    //   this.closeEmail();
    // },
    closeEmailindividual() {
      this.filesArrayGet = [];
      this.showEmailModal = false;
      this.delete_modal_active = false;
      this.showCc = false;
      this.showBcc = false;
      this.toEmailInput = "";
      (this.ccEmailInput = ""),
        (this.bccEmailInput = ""),
        (this.subjectEmailInput = ""),
        (this.template = "");
      this.componentKey += 1;
    },
    handleCurrentChange(val) {
      this.template = val;
      this.$emit("body-content", val);
      this.updatedemailcontent();
    },
    deleteemail() {
      this.delete_modal_active = true;
      // this.showEmailModal=false;
      console.log(this.delete_modal_active);
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .emailCrudModal {
    width: 100%; /* Adjust as needed */
    max-width: none; /* Reset max-width */
  }
  /* Add more responsive styles as needed */
}
.attachmnet_uderline_email {
  text-decoration: none;
  cursor: pointer;
}

/* Hover state: underline */
.attachmnet_uderline_email:hover {
  text-decoration: underline;
  cursor: pointer;
}
.horizontal-load-container-email {
  position: relative;
  height: 100px;
  width: 350px;
}
.box-attachment-supplier-parent-email {
  padding: 12px 20px;
  max-height: 220px !important;

  .box-attachment-supplier {
    position: relative;
    width: 315px;
    display: flex;
    height: auto;
    background-color: #f2f5f8;
    // border: 1px solid #d4d4d4;
    border-radius: 6px;
    padding: 6px 8px 6px 8px;
    margin: 10px 0 10px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    .closeIconeSupplier {
      position: absolute;
      left: 285px;
      height: 18px;
      color: #a6a6a6;
      cursor: pointer;
    }
    .upload_component_icon {
      height: 20px;
      align-items: center;
      display: flex;
      .file-icon {
        height: 20px;
        width: 20px;
      }
    }
  }
}
.container-box {
  padding: 12px 20px;
  max-width: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
}

.content-wrapper,
.uplod_inside_email {
  max-width: 100%;
  max-height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.object-name {
  margin-right: 10px;

  .icon-times-regular {
    color: #a6a6a6;
  }
  .hlx-tag {
    .file-icon {
      height: 16px;
    }
    border: 1px solid #d4d4d4;
    color: #000000;
    background: none;
    &:hover {
      cursor: default;
      background: none;

      // color: var(--hlx-color-info-light);
      border: 1px solid #d4d4d4;
      // background-color: black;
    }
  }
}

.overflow-count {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}
.email_cc_to {
  font-size: 14px;
}
.tag_email_input {
  display: flex;
  gap: 3px;
  align-items: center;
}
.expandedEmail {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(24 24 27 / 20%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
  font-family: "Opensans";
  .expandedCurdEmail {
    max-width: 90% !important;
  }
  .emailCrudModal {
    .email_input {
      width: 100%;
      margin: 0 10px;
      flex-grow: 1;
      max-width: 100%;
      .tag-email-container {
        display: flex;
        flex-wrap: wrap;
        max-width: 68vw !important;
      }

      .container-input {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 4px;
        max-width: 68vw !important ;
      }

      .container-input > div {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      input {
        width: 75vw !important;
        border: none;
        font-family: "Opensans";
        flex-grow: 1;
        flex-basis: 0;
        min-width: 0;
        box-sizing: border-box;
        margin-right: 10px;
      }
    }
    .email_subject {
      height: 40px;
      border-bottom: 1px solid #ebe9f1;
      background-color: #ffff;
      padding: 0 20px;
      display: flex;
      align-items: center;
      color: #a6a6a6;
      .email_text_editor_body {
        //   padding: 0 20px;
        height: 370px;
      }
    }

    border: 1px solid #eeee;
    background-color: #fff;
    border: 1px solid #ebe9f1;
    width: 100%; /* Adjust as needed */
    border-radius: 6px;
    .email_head {
      height: 48px;
      background-color: #f8f8f8;
      padding: 12px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .email_head_name {
        color: #5e5873;
        font-size: 14px;
      }
      .expandedverstion {
        color: #000000;
        &:hover {
          color: var(--hlx-color-primary);
        }
      }
      .email_head_icone {
        color: #000000;
        font-size: 1.2rem;
        &:hover {
          color: var(--hlx-color-primary);
        }
      }
    }
    .email_cc {
      height: 100%;
      border-bottom: 1px solid #ebe9f1;
      background-color: white;
      padding: 9px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #a6a6a6;

      .moredetail_email {
        border: 1px solid #d4d4d4;
        padding: 1px 3px;
        border-radius: 3px;
        margin-left: 6px;
        background: #f2f5f8;
      }
      .email_cc__BCC_Cc {
        //   width: 62px;
        //   gap: 16px;
        //   justify-content: space-between;
        display: flex;
        .email_cc_Cc,
        .email_cc_Bcc {
          cursor: pointer;
          color: #a6a6a6;
          font-size: 14px;
        }
        .email_cc_Cc:hover,
        .email_cc_Bcc:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.email_overlay {
  // z-index: 270;
  // position: fixed;
  // bottom: 15px;
  // right: 15px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // font-family: "Opensans";
  .email_footer {
    border-top: 1px solid #ebe9f1;
    height: 64px;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .email_footer_left,
    .email_footer_right {
      display: flex;
      cursor: pointer;
      color: #6e6b7b;
      align-items: center;
      gap: 16px;
      font-size: 22px;
      .icon-trash-regular:hover {
        color: var(--hlx-color-error) !important;
      }
      .icon-more-vertical-filled:hover,
      .icon-paperclip-regular:hover {
        color: var(--hlx-color-primary) !important;
      }
    }
  }
  .emailCrudModal {
    .email_input {
      width: 100%;
      margin: 0 10px;
      flex-grow: 1;
      max-width: 100%;
      .tag-email-container {
        display: flex;
        flex-wrap: wrap;
        max-width: 526px;
      }

      .container-input {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 4px;
        max-width: 526px;
      }

      .container-input > div {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      input {
        width: 526px;
        border: none;
        font-family: "Opensans";
        flex-grow: 1;
        flex-basis: 0;
        min-width: 0;
        box-sizing: border-box;
        margin-right: 10px;
      }
    }
    .email_subject {
      height: 40px;
      border-bottom: 1px solid #ebe9f1;
      background-color: #ffff;
      padding: 0 20px;
      display: flex;
      align-items: center;
      color: #a6a6a6;
      .email_text_editor_body {
        //   padding: 0 20px;
        height: 370px;
      }
    }

    border: 1px solid #eeee;
    background-color: #fff;
    border: 1px solid #ebe9f1;
    width: 100%; /* Adjust as needed */
    max-width: 100% !important;
    border-radius: 6px;
    .email_head {
      height: 48px;
      background-color: #f8f8f8;
      padding: 12px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .email_head_name {
        color: #5e5873;
        font-size: 14px;
      }
      .expandedverstion {
        color: #000000;
        &:hover {
          color: var(--hlx-color-primary);
        }
      }
      .email_head_icone {
        color: #000000;
        font-size: 1.2rem;
        &:hover {
          color: var(--hlx-color-primary);
        }
      }
    }
    .email_cc {
      height: 100%;
      border-bottom: 1px solid #ebe9f1 !important;
      background-color: white;
      padding: 9px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #a6a6a6;

      min-width: 660px;
      min-height: 46px;
      .moredetail_email {
        border: 1px solid #d4d4d4;
        padding: 1px 3px;
        border-radius: 3px;
        margin-left: 6px;
        background: #f2f5f8;
      }
      .email_cc_Cc,
      .email_cc_Bcc {
        cursor: pointer;
        color: #a6a6a6;
        font-size: 14px;
      }
      .email_cc_Cc:hover,
      .email_cc_Bcc:hover {
        text-decoration: underline;
      }
      .email_cc__BCC_Cc {
        //   width: 62px;
        //   gap: 16px;
        //   justify-content: space-between;
        display: flex;
      }
    }
  }
}
.postion_description_to {
  position: absolute;
  // left: 15px;
  z-index: 20;
}
.postion_description_cc {
  position: absolute;
  // left: 15px;
  z-index: 20;
}
.postion_description_bcc {
  position: absolute;
  // left: 15px;
  z-index: 20;
}

.email_description_option {
  margin-left: 45px;
  width: fit-content;
  // gap: 10px;
  padding: 5px 0px;
  display: flex;
  /* padding: 10px 10px; */
  align-items: center;
  flex-direction: column;
  border: 1px solid var(--hlx-border-color);
  border-radius: 5px;
  background: white;
  .email_options_container {
    padding: 1px 8px;
    gap: 17px;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    &:hover {
      background-color: var(--hlx-color-primary-light);
      cursor: pointer;
    }
    .email_options {
      display: flex;
      align-items: center;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
