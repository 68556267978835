<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <!-- Filter drawer-->




  
  <!-- add region modal -->
  <addRegionComponent
  :key="addregionKey"
  :open-modal="showAddNewRegionModal"
  @add-region="addRegion"
  @close-modal="closeAddRegionModal"
  ></addRegionComponent>


  




  <hlx-drawer
    :key="componentkey"
    :title="'upload drawer'"
    :show="show_upload_drawer"
    :width="widthofdrawer"
    position="right"
    :footer="true"
    :show-close-icon="true"
    @close="closeuploaddrawer"
  >
    <template #body>
      <!-- available offer -->
      <div v-if="check_modal_active === true">
        <div
          class="upload_image_header"
          style="padding: 7px; border-bottom: 1px solid #d8d8d8"
        >
          <span v-if="100 > 0"
            ><b style="font-size: 16px">Check Availability</b></span
          >
        </div>
        <span style="width: 100%; display: flex; gap: 10px; margin-top: 11px">
          <span v-if="100 < 0" style="width: 336px"
            ><hlx-input
              v-model:value="checkofferinput"
              :read-only="false"
              :pre-val="checkofferinput"
              label-value="Address"
              :label-animation="true"
              :display-error="false"
              :custom-error-message="'Please fill the field'"
              type="text"
              :location-data-prop="location1Data1"
              @address-data="addressData"
              @at-input="getLocaetions"
          /></span>

          <span v-if="100 > 0" style="width: 336px">
            <hlx-input
              v-model:value="checkofferinput"
              :read-only="false"
              :pre-val="checkofferinput"
              type="location"
              :label-value="'Address'"
              :label-animation="true"
              :display-error="true"
              :custom-error-message="'Please fill the field'"
              :location-data-prop="locationData1"
              @address-data="newaddress"
              @at-input="getLocations"
            ></hlx-input>
          </span>

          <hlx-button class="primary sm" @click="checkedbutton">
            Check</hlx-button
          >
        </span>
        <span> </span>

        <div
          style="
            overflow-y: scroll;
            height: 690px;
            /* width: 100%; */
            overflow-x: hidden;
          "
        >
          <div class="latitudelongitude">Available offers</div>
          <span v-show="cheackAvailable === true">
            <div v-if="newoffers?.length > 0">
              <div
                v-for="off in newoffers"
                :key="off"
                style="width: 100%; margin-top: 11px"
              >
                <div>
                  <hlx-card
                    :seperator="false"
                    :card-title="false"
                    :card-footer="false"
                    :width="409"
                    :height="38"
                    :active="false"
                  >
                    <template #card-body>
                      <div v-if="off" class="mainpage_iteqefm4">
                        <!-- {{ off?.name }} -->
                        <span
                          style="
                            background: none;
                            width: 383px;
                            display: flex;
                            justify-content: space-between;
                            padding-top: 4px;
                            padding-left: 16px;
                          "
                        >
                          <span
                            style="
                              color: #0065b2;
                              font-size: 14px;
                              font-weight: 400;
                            "
                            >{{ off?.name }}</span
                          >
                          <span style="font-size: 13px; font-weight: 400">{{
                            off?.price
                          }}</span>
                        </span>
                      </div>
                    </template>
                  </hlx-card>
                </div>
              </div>
            </div>
            <div v-else>
              <div style="width: 100%; margin-top: 11px">
                <hlx-card
                  :seperator="false"
                  :card-title="false"
                  :card-footer="false"
                  :width="409"
                  :height="38"
                  :active="false"
                >
                  <template #card-body>
                    <div class="mainpage_iteqefm4">
                      <!-- {{ off?.name }} -->
                      <span
                        style="
                          background: none;
                          width: 383px;
                          display: flex;
                          justify-content: space-between;
                          padding-top: 4px;
                          padding-left: 16px;
                        "
                      >
                        <span
                          style="
                            color: #0065b2;
                            font-size: 14px;
                            font-weight: 400;
                          "
                          >{{ "No Offer available" }}</span
                        >
                        <span style="font-size: 13px; font-weight: 400">{{
                          "-"
                        }}</span>
                      </span>
                    </div>
                  </template>
                </hlx-card>
              </div>
            </div>
          </span>
        </div>
      </div>

      <!-- address drawer -->
      <div v-else-if="selectedSwitch === 'Address'">
        <!-- head -->
        <div
          class="upload_image_header"
          style="padding: 7px; border-bottom: 1px solid #d8d8d8"
        >
          <span v-if="editMode == false"
            ><b style="font-size: 16px">Add Region</b></span
          >
          <span v-if="editMode == true"
            ><b style="font-size: 16px">Edit Region</b></span
          >
        </div>
        <div style="overflow-y: scroll; overflow-x: hidden">
          <div style="width: 100%; margin-top: 11px">
            <hlx-input
              v-model:value="regionName"
              :required="true"
              :pre-val="regionName"
              label-value="Region name"
              :label-animation="true"
              type="text"
              :clearable="false"
            />
          </div>
          <!-- {{ servicestatus }} -->
          <div style="width: 100%; padding-top: 10px">
            <hlx-status
              :type="'select-status'"
              :required="true"
              :status-option="filteredstatus"
              :prevalue="servicestatus"
              @emit-select="emitStatus1"
            >
            </hlx-status>
          </div>
          <div v-if="10 > 100" style="width: 100%; margin-top: 11px">
            <hlx-select
              v-model:value="propertytype"
              :required="true"
              :pre-value="propertytype"
              :options="dropdown_data_type"
              :placeholder-value="'Property type'"
              :label-animation="true"
              :label="'value'"
              :prop-value="'value'"
              :clearable="true"
            />
          </div>

          <!-- Region shape -->
          <div class="latitudelongitude">Region shape</div>

          <div>
            <hlx-input
              :position="'horizontal'"
              :options="radioOptions"
              :type="'radio-buttons'"
              @selected="emitRadio"
            />
          </div>

          <!-- Region shape -->
          <div v-if="vradio === 'Point of interest'" class="latitudelongitude">
            Point of interest
          </div>
          <div v-if="vradio === 'Radius'" class="latitudelongitude">Radius</div>
          <div v-if="vradio === 'Square'" class="latitudelongitude">Square</div>
          <div v-if="vradio === 'Hexagon'" class="latitudelongitude">
            Hexagon
          </div>
          <div v-if="vradio === 'Custom'" class="latitudelongitude">Custom</div>
          <!-- {{ distvalue }} -->
          <!-- inputs -->
          <div class="drawerbodytop">
            <div v-if="vradio !== 'Point of interest'" style="width: 100%">
              <hlx-input
                v-model:value="radiuscircle"
                :pre-val="radiuscircle"
                :disabled="false"
                :label-animation="true"
                label-value="Range"
                type="mixed"
                :clearable="true"
                name="Range"
                :suffix="true"
              >
                <template #suffix>
                  <div style="margin: -8px; width: 103px">
                    <hlx-select
                      v-model:value="rangeName"
                      :pre-value="rangeName"
                      :inline-search="true"
                      :border="false"
                      :options="dropdown_data_distance"
                      :placeholder-value="'Distance'"
                      :label-animation="false"
                      :label="'value'"
                      :prop-value="'value'"
                      :background-color="'#0000'"
                    >
                    </hlx-select>
                  </div>
                </template>
              </hlx-input>
            </div>

            <div v-if="vradio === 'Point of interest'">
              <addressautoComponent
                :pre-val="characteristicpreval"
                :apis="mapkey"
                @address-data="receivedAddressData"
                @geopoint="geopoint"
                @mapname="mapname1"
              ></addressautoComponent>
            </div>

            <div v-if="vradio === 'Radius'">
              <addressComponent
                :new-coordinate="newCoordinate"
                :pre-val="characteristicpreval"
                @address-data="receivedAddressData"
                @geopoint="geopoint"
              ></addressComponent>
            </div>

            <div v-if="vradio === 'Square'">
              <addressComponent
                :pre-val="characteristicpreval"
                @address-data="receivedAddressData"
                @geopoint="geopoint"
              ></addressComponent>
            </div>

            <div v-if="vradio === 'Hexagon'">
              <addressComponent
                :pre-val="characteristicpreval"
                @address-data="receivedAddressData"
                @geopoint="geopoint"
              ></addressComponent>
            </div>

            <div v-if="vradio === 'Custom'">
              <addressComponent
                :pre-val="characteristicpreval"
                @address-data="receivedAddressData"
                @geopoint="geopoint"
              ></addressComponent>
            </div>
          </div>

          <div class="miniInput">
            <div style="width: 100%">
              <hlx-input
                v-model:value="lat"
                :pre-val="lat"
                label-value="Latitude"
                :label-animation="true"
                type="number"
                :clearable="false"
              />
            </div>
            <div style="width: 100%">
              <hlx-input
                v-model:value="lng"
                :pre-val="lng"
                label-value="Longitude"
                :label-animation="true"
                type="number"
                :clearable="false"
              />
            </div>
          </div>
          <!-- v-if="vradio === 'Poin t of interest'" -->
          <!-- map -->
          <div
            v-if="vradio === 'Point of interest' || vradio === 'Radius'"
            class="mapbox"
          >
            <placeMap
              :key="Mapcomponentkey"
              v-model:searchQuery="addressquery"
              :map-center="mapCenter"
              :selected-shape="vradio"
              :radiuscircle1="radiuscircle1"
              @polygon-paths="polygonPathObject"
              @map-center="mapCenterNew"
            />
          </div>

          <div v-else class="mapbox">
            <placeMapnew
              :key="Mapcomponentkey"
              :map-center="mapCenter"
              :radiuscircle1="radiuscircle1"
              :polygonpath="polygonpathdata"
              @map-center="mapCenterNew"
            />
          </div>
        </div>
      </div>

      <!-- product drawer -->
      <div v-else-if="selectedSwitch === 'Products'">
        <div
          class="upload_image_header"
          style="padding: 7px; border-bottom: 1px solid #d8d8d8"
        >
          <span v-if="editMode == true"
            ><b style="font-size: 16px">Edit Products</b></span
          >
          <span v-if="editMode == false"
            ><b style="font-size: 16px">Add Products</b></span
          >
        </div>

        <div style="width: 100%; margin-top: 16px">
          <hlx-select
            v-model:value="regioncode"
            :required="true"
            :options="dropdown_data_regioncode"
            :pre-value="regioncode"
            :placeholder-value="'Region name'"
            :label-animation="true"
            :label="'region'"
            :prop-value="'region'"
            :clearable="true"
            @selected-value="emitedregiongroup"
          >
          </hlx-select>

          <br />
        </div>

        <div>
          <searchfilterComponent
            :key="updateKey"
            :dropdown-width="408"
            :module="'productoffering'"
            :filter-search-options="mainInfoServiceQualification"
            :filter-data="searchFilterData"
            :filter-checked-data="layout"
            :key-value-from-query-array="'label'"
            :filter-value-object-name="'name'"
            @removed-value="getRemovedValue"
            @searched-value="getSearchedValueNewDiscount"
            @checked-value="getcheckedValueNewDiscount"
          >
          </searchfilterComponent>
        </div>
        <br />
      </div>
    </template>
    <template #footer>
      <!-- available footer -->
      <div v-if="check_modal_active === true">
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 9px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary media sm" @click="closeuploaddrawer"
            >Cancel</hlx-button
          >
        </div>
      </div>

      <!-- address footer -->
      <div
        v-else-if="selectedSwitch === 'Address'"
        style="
          /* background: red; */
          background: white;
          position: fixed;
          z-index: 111;
          width: 403px;
        "
      >
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 9px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary media sm" @click="closeuploaddrawer"
            >Cancel</hlx-button
          >
          <hlx-button
            v-if="editMode == false"
            class="primary media sm"
            style="margin-left: 10px"
            :disabled="!enableButton"
            @click="addaddress"
            >Add</hlx-button
          >
          <hlx-button
            v-else
            class="primary media sm"
            style="margin-left: 10px"
            :disabled="!enableButton"
            @click="addaddress"
            >Save</hlx-button
          >
        </div>
      </div>

      <!-- product footer -->
      <div v-else-if="selectedSwitch === 'Products'">
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 9px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary media sm" @click="closeuploaddrawer"
            >Cancel</hlx-button
          >
          <hlx-button
            v-if="editMode == false"
            class="primary media sm"
            style="margin-left: 10px"
            :disabled="!enableButton"
            @click="addproduct"
            >Add</hlx-button
          >
          <hlx-button
            v-else
            class="primary media sm"
            style="margin-left: 10px"
            :disabled="!enableButton"
            @click="addproduct"
            >Save</hlx-button
          >
        </div>
      </div>
    </template>
  </hlx-drawer>

  <div>
    <!-- {{ regioncode }} -->
    <div>
      <hlx-modal
        style="z-index: 999999999"
        :modal-active="delete_modal_active"
        :height="'220px'"
        :width="'400px'"
        :modal="true"
        @close="delete_modal_active = false"
      >
        <template #header>
          <div style="margin-left: 0px">Delete data</div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              Are you sure you want to delete <b>{{ delete_name }}</b
              >?
            </p>
          </div>
        </template>
        <template #footer>
          <hlx-button class="secondary sm" @click="delete_modal_active = false"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 16px"
            @click="deleteExecRow"
            >Delete</hlx-button
          >
        </template>
      </hlx-modal>
    </div>

    <div>
      <!-- drawer -->
      <section>
        <!-- {{ checkofferinput }}
        {{ newoffers?.length }} -->
        <div class="add-contact-drawer">
          <hlx-drawer
            :key="closeRefresh"
            :show="show_right_filter"
            :width="300"
            position="right"
            :header="false"
            :footer="false"
            :show-close-icon="true"
            @close="closeRightFilter"
          >
            <template #body>
              <div style="display: flex">
                <span v-if="selectedSwitch == 'Address'">
                  <!-- {{ 'add' }} -->
                  <FilterComponent
                    :filterpanelname="'address'"
                    :company-name="$cookies.get('company')"
                    :query-value="query"
                    :display-data="filter"
                    :module="'address'"
                    @filtered-data="ticketfilterData"
                  ></FilterComponent>
                </span>

                <span v-else>
                  <!-- {{ 'prod' }} -->
                  <FilterComponent
                    :filterpanelname="'productqualification'"
                    :company-name="$cookies.get('company')"
                    :query-value="query2"
                    :display-data="filter"
                    :module="'productqualification'"
                    @filtered-data="ticketfilterData"
                  ></FilterComponent>
                </span>
              </div>
            </template>
          </hlx-drawer>
        </div>
      </section>

      <!-- header -->
      <div class="common_single_header_selfservice">
        <div class="head_selfservice">Service qualification</div>
        <div class="body_selfservice">
          Services are accessible at the location.
        </div>
      </div>
      <!-- body -->
      <div
        class="util-bar"
        style="
          display: flex;
          justify-content: space-between;
          margin: 20px 15px 15px 15px;
        "
      >
        <span class="switch-container">
          <hlx-switch
            v-model="selectedSwitch"
            :switch-items="switchOptions"
            :read-only="switchOff"
            value-prop="value"
            display-prop="name"
            @change="chosenSwitch"
          />
        </span>
        <hlx-drawer
          :show="show_right"
          :width="1000"
          position="right"
          :footer="false"
          :show-close-icon="true"
          @close="closeactivitylog"
        >
          <template #body>
            <div class="activitylog_drawer_componet">
              <activityLogComponent
                :key="activtykey"
                :activity-data="activityLogData"
                :totalfromprop="activitytotal"
                :module="'leftPanal'"
                @activityrowpage="activityrowpage1"
                @activitycurrentpage="activitycurrentpage1"
              ></activityLogComponent>
            </div>
          </template>
        </hlx-drawer>
        <span
          v-if="newone.length > 0 && selectedSwitch === 'Address'"
          style="
            display: flex;
            gap: 16px;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="width: 100%; padding-left: 10px">
            <hlx-search v-model="searchvalue" @search-key="searchKey" />
          </div>
          <span style="margin-top: 2px" @click="activityLogDrawer('Address')"
            ><i
              class="icon-time-forward-regular"
              style="
                font-size: 27px;
                color: rgb(84, 189, 149);
                margin-left: 0px;
              "
            ></i
          ></span>

          <span v-if="selectedSwitch === 'Products'">
            <hlx-button class="secondary sm" @click="checkofferbutton">
              Check availability</hlx-button
            ></span
          >

          <span class="filter-table">
            <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
              <i class="icon-filter-regular"></i>
              <span v-if="filtertaglength != 0" class="filter-count">{{
                filtertaglength
              }}</span>
            </hlx-button>
          </span>
          <span v-if="selectedSwitch === 'Address'">
            <hlx-button class="secondary sm">
              <i class="icon-download-regular"></i
              ><span style="padding: 6px">Import</span></hlx-button
            >
          </span>
          <span v-if="selectedSwitch === 'Products'">
            <hlx-button
              v-if="permisstionDataProduct.add"
              class="primary sm"
              @click="openuploaddrawer"
            >
              <i class="icon-plus-circle-regular"></i
              ><span style="padding: 6px">Add products</span></hlx-button
            >
          </span>
          <span v-if="selectedSwitch === 'Address'">
            <hlx-button
              v-if="permisstionDataRegion.add"
              class="primary sm"
              @click="openAddNewRegionModal"
            >
              <i class="icon-plus-circle-regular"></i
              ><span style="padding: 6px">Add region</span></hlx-button
            >

            <!-- <span @click="openAddNewRegionModal">
              Demo
            </span> -->



          </span>
        </span>

        <span
          v-if="newtwo1.length > 0 && selectedSwitch === 'Products'"
          style="
            display: flex;
            gap: 16px;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="width: 100%; padding-left: 10px">
            <hlx-search v-model="searchvalue" @search-key="searchKey" />
          </div>

          <span style="margin-top: 2px" @click="activityLogDrawer('Products')"
            ><i
              class="icon-time-forward-regular"
              style="
                font-size: 27px;
                color: rgb(84, 189, 149);
                margin-left: 0px;
              "
            ></i
          ></span>
          <span v-if="selectedSwitch === 'Products'">
            <hlx-button class="secondary sm" @click="checkofferbutton">
              Check availability</hlx-button
            ></span
          >

          <span class="filter-table">
            <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
              <i class="icon-filter-regular"></i>
              <span v-if="filtertaglength != 0" class="filter-count">{{
                filtertaglength
              }}</span>
            </hlx-button>
          </span>
          <span v-if="selectedSwitch === 'Address'">
            <hlx-button class="secondary sm">
              <i class="icon-download-regular"></i>Import</hlx-button
            >
          </span>
          <span v-if="selectedSwitch === 'Products'">
            <hlx-button
              v-if="permisstionDataProduct.add"
              class="primary sm"
              @click="openuploaddrawer"
            >
              <i class="icon-plus-circle-regular"></i> Add products</hlx-button
            >
          </span>
          <span v-if="selectedSwitch === 'Address'">
            <hlx-button
              v-if="permisstionDataRegion.add"
              class="primary sm"
              @click="openuploaddrawer"
            >
              <i class="icon-plus-circle-regular"></i> Add region</hlx-button
            >
          </span>
        </span>
      </div>
      <!-- demo -->

      <!-- {{ tableHeader.length }} -->
      <!-- {{ 'regioncode:'+regioncode }}
       {{ "dropdown_data_regioncode:"+dropdown_data_regioncode }} -->

      <!-- table -->
      <section v-if="tableData.length > 0" style="margin: 10px 15px">
        <hlx-table
          :key="componentkey"
          :column-count="tableHeader.length"
          :border="['table', 'header', 'horizontal']"
          :bold-headers="false"
          :row-hover="true"
          theme="grey"
          :striped-rows="false"
          :re-arrange-columns="toggleRearrangeModal"
          :re-arrange-heads="tableHeaderRearranging"
          @close-rearrange="toggleRearrangeModal = false"
          @apply-rearranged-columns="applyColumns"
        >
          <!-- :min-width="50" -->
          <template #thead>
            <hlx-table-head
              :width="60"
              :align="'center'"
              style="padding: 0 0"
              :fixed="'left'"
              @new-resize-width="resizedWidth"
            >
              {{ "S.no" }}</hlx-table-head
            >

            <hlx-table-head
              v-for="(header, index) in tableHeader"
              :key="index"
              :width="header.width"
              :sortable="header.sortable"
              :resizable="header.resizable"
              @new-resize-width="resizedWidth"
              ><span
                v-if="header.label === 'Image'"
                style="padding-left: 15px"
                >{{ header.label }}</span
              >
              <span
                v-else-if="header.label === 'Region'"
                style="padding-left: 1px"
                >{{ header.label }}</span
              >
              <span v-else>{{ header.label }}</span></hlx-table-head
            >
            <hlx-table-head
              v-if="permisstionData.edit || permisstionData.delete"
              :width="50"
              :fixed="'right'"
              style="max-width: 60px !important"
              @new-resize-width="resizedWidth"
              ><span class="action-icon">
                <i
                  class="icon-settings-regular"
                  @click="reAssignOrgTableHeader()"
                ></i></span
            ></hlx-table-head>
          </template>

          <template #tbody>
            <tr
              v-for="(i, index) in tableDataOnSelectedRows"
              id=""
              :key="index"
            >
              <hlx-table-cell :align="'center'" :fixed="'left'">
                {{ serialNumberAlter(index) }}
              </hlx-table-cell>
              <hlx-table-cell
                v-for="(j, col_index) in tableHeader"
                :key="col_index"
                :align="j.align"
                :fixed="j.fixed"
              >
                <div v-if="j.prop === 'regionName'">
                  <div
                    v-for="(addressLine, index1) in i.streetAddressLine"
                    :key="index1"
                  >
                    <span
                      v-if="addressLine.characteristic.regionName"
                      style="cursor: pointer; color: #2879b7"
                      onmouseover="this.style.textDecoration='underline';"
                      onmouseout="this.style.textDecoration='none';"
                      @click="callOverviewRoute(i?.id) ||callDirectEdit(i, index)"
                      >{{ addressLine.characteristic.regionName }}</span
                    >
                  </div>
                </div>
                <div v-else-if="j.prop === 'addressdescription'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <span v-if="addressLine.characteristic.description">{{
                      removeFirstComma(addressLine?.characteristic?.description)
                    }}</span>
                  </div>
                </div>
                <div v-else-if="j.prop === 'servicestatus'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <div>
                      <hlx-status
                        :type="addressLine.characteristic.servicestatus"
                      ></hlx-status>
                    </div>
                  </div>
                </div>
                <div v-else-if="j.prop === 'type'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <div>
                      <!-- <hlx-status
                        :type="addressLine.characteristic.servicestatus"
                      ></hlx-status> -->
                      {{ addressLine.characteristic.type }}
                    </div>
                  </div>
                </div>
                <div v-else-if="j.prop === 'createdat'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <div v-if="addressLine?.characteristic?.createdat">
                      <!-- <hlx-status
                        :type="addressLine.characteristic.servicestatus"
                      ></hlx-status> -->
                      {{ formatDate(addressLine?.characteristic?.createdat) }}
                    </div>
                  </div>
                </div>
                <div v-else-if="j.prop === 'updatedat'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <div v-if="addressLine?.characteristic?.updatedat">
                      <!-- <hlx-status
                        :type="addressLine.characteristic.servicestatus"
                      ></hlx-status> -->
                      {{ formatDate(addressLine?.characteristic?.updatedat) }}
                    </div>
                  </div>
                </div>
                <div v-else-if="j.prop === 'regionshape'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <span v-if="addressLine.characteristic.regionshape">{{
                      addressLine.characteristic.regionshape
                    }}</span>
                  </div>
                </div>
                <div v-else-if="j.prop === 'street'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <span v-if="addressLine.characteristic.street">{{
                      addressLine.characteristic.street
                    }}</span>
                  </div>
                </div>

                <div v-else-if="j.prop === 'state'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <span v-if="addressLine.characteristic.state">{{
                      addressLine.characteristic.state
                    }}</span>
                  </div>
                </div>

                <div
                  v-else-if="j.prop === 'availableOffer'"
                  style="width: 400px"
                >
                  <div style="width: 310px">
                    <hlx-tagcount
                      :arrayfile="i.productRelationship"
                      :item-key="'name'"
                      :type="'label'"
                    ></hlx-tagcount>
                  </div>
                </div>

                <div
                  v-else-if="j.prop === 'region'"
                  style="padding-left: 1px; cursor: pointer; color: #2879b7"
                  onmouseover="this.style.textDecoration='underline';"
                  onmouseout="this.style.textDecoration='none';"
                  @click="callDirectEdit(i, index)"
                >
                  <!-- {{ i[j.prop] }} -->
                  <div v-for="index in i?.place" :key="index">
                    {{ index?.region }}
                  </div>
                </div>

                <div v-else>
                  {{ i[j.prop] }}
                </div>
              </hlx-table-cell>
              <hlx-table-cell
                v-if="permisstionData.edit || permisstionData.delete"
                :align="'center'"
                :width="120"
                :fixed="'right'"
              >
                <span
                  :id="'table-context' + serialNumberAlter(index - 1)"
                  class="action-icon"
                >
                  <i
                    style="position: relative"
                    class="icon-more-vertical-filled"
                    :class="i.context === true ? 'active-action' : ''"
                    @click="
                      crudContextMenu($event, serialNumberAlter(index - 1))
                    "
                  >
                    <!-- {{ i.id }} -->
                    <hlx-context-menu
                      :top="containertop"
                      :left="containerleft"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      :options="computedTableAction(i)"
                      :data="index.toString()"
                      :show="i.context == true"
                      @chosen="
                        closeCrudContextMenu(
                          $event,
                          i,
                          serialNumberAlter(index - 1)
                        )
                      "
                    />
                  </i>
                </span>
              </hlx-table-cell>
            </tr>
          </template>
        </hlx-table>
        <div style="height: 90px"></div>
        <!-- Pagination -->
        <div
          v-if="tableData.length > 0"
          class="media-pagination-container"
          style="padding: 1rem 0; z-index: 899"
        >
          <span class="row2" style="margin-left: 92px">
            <hlx-pagination
              v-model:rows-per-page="externalRowsPerPage"
              :total="totalvalue"
              enable-rows-per-page
              :rows-per-page-list="[10, 15, 20]"
              :options="{
                attributes: {
                  rowsPerPageTitle: true,
                  rowsPerPageResult: true,
                  rowsPerPageDropdown: {
                    position: {
                      top: 0,
                      right: 1,
                    },
                  },
                },
              }"
              @current-page="currentPage"
              @updated:rows-per-page="changeRowsPerPage"
            ></hlx-pagination
          ></span>
        </div>
      </section>

      <div
        v-if="tableData.length === 0 && searchvalue !== ''"
        style="margin: 10px 15px"
      >
        <hlx-table
          :column-count="tableHeader.length + 2"
          :border="['table', 'header']"
          :bold-headers="false"
          :row-hover="true"
          theme="grey"
          :striped-rows="false"
          :re-arrange-columns="toggleRearrangeModal"
          :re-arrange-heads="tableHeaderRearranging"
          @close-rearrange="toggleRearrangeModal = false"
          @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head
              :width="60"
              :align="'left'"
              @new-resize-width="resizedWidth"
              >{{ "S.no" }}</hlx-table-head
            >
            <hlx-table-head
              v-for="(i, index) in tableHeader"
              :key="index"
              :prop="i.prop"
              :sortable="i.sortable"
              :resizable="i.resizable"
              style="position: sticky; top: 0px"
              :width="i.width !== undefined ? i.width : ''"
              :align="i.align !== undefined ? i.align : ''"
              @sorting_func="sorting_Data"
              @new-resize-width="resizedWidth"
            >
              {{ i.label }}
            </hlx-table-head>
            <hlx-table-head
              v-if="permisstionData.edit || permisstionData.delete"
              v-show="supplierStatus == false"
              :width="100"
            >
              <span class="action-icon"
                ><i
                  class="icon-settings-regular"
                  @click="reAssignOrgTableHeader()"
                  @new-resize-width="resizedWidth"
                ></i></span
            ></hlx-table-head>
          </template>
          <template #tbody>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell :colspan="tableHeader.length + 2">{{
                "No data"
              }}</hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
          </template>
        </hlx-table>
      </div>

      <!-- image -->
      <section
        style="
          margin-top: 1.2rem;
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          align-items: center;
        "
      >
        <span v-if="newone.length <= 0 && 'Address' === selectedSwitch">
          <img
            v-if="'Address' === selectedSwitch"
            src="@/assets/images/svg/SQregion.svg"
            alt=""
            style="width: 500px; height: 500px"
          />
        </span>
        <span v-if="newtwo1.length <= 0 && 'Products' === selectedSwitch">
          <img
            v-if="'Products' === selectedSwitch"
            src="@/assets/images/svg/rafiki.svg"
            alt=""
            style="width: 500px; height: 500px"
          />
        </span>

        <span
          v-if="newone.length <= 0 && 'Address' === selectedSwitch"
          class="util-items util-items-add"
          @click="showAddNewRegionModal = true"
        >
          <hlx-button
            v-if="permisstionDataRegion.add"
            class="primary media add-btn"
            @click="showAddNewRegionModal = true"
            ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i
            >Add region</hlx-button
          >
        </span>
        <span
          v-if="newtwo1.length <= 0 && 'Products' === selectedSwitch"
          class="util-items util-items-add"
          @click="openuploaddrawer"
        >
          <hlx-button
            v-if="permisstionDataProduct.add"
            class="primary media add-btn"
            @click="openuploaddrawer"
            ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i
            >Add Product</hlx-button
          >
        </span>
      </section>
    </div>
  </div>
</template>

<script>
import addRegionComponent from "@/components/Product/ServiceQualification/addRegionModalComponent.vue"
import searchfilterComponent from "@/components/SearchAndFilterComponent/searchAndFilter.vue";
import addressComponent from "./autoAddressService.vue";
import addressautoComponent from "./autoAddress2.vue";
import activityLogComponent from "@/components/SM/activityLogComponent.vue";
import FilterComponent from "@/components/filterPanelComponent.vue";

import placeMap from "./placeMap.vue";
import placeMapnew from "./placeMapnew.vue";

import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";

export default {
  name: "MapComponent",
  components: {
    FilterComponent,
    activityLogComponent,
    searchfilterComponent,
    addressComponent,
    placeMap,
    placeMapnew,
    addressautoComponent,
    addRegionComponent
  },
  data() {
    return {
      addregionKey:0,
      showAddNewRegionModal:false,
      canAdd: false,
      modalKey:0,
      permisstionData: {},
      permisstionDataRegion: {},
      permisstionDataProduct: {},
      tableHeaderOrg: [],
      tableHeaderOrgcc: [],
      closeRefresh: 0,
      switchOff: false,
      totalvalue: 0,
      SpecificationrowsPerPage: 10,
      SpecificationcurrPage: 1,
      templateFilterQuery: {
        type: "filter",
        module: "address",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
    "streetAddressLine.characteristic.category": {
        "$in": [
            "parent"
        ]
    }
},
        searchQuery: "",
        company: this.$cookies.get("userName"),
      },
      filtertaglength: 0,
      filtertagvalue: [],
      filter: [],
      query: {
        Status: "streetAddressLine.characteristic.servicestatus",
        "Type": "streetAddressLine.characteristic.type",
      },
      query2: {
        "Region name": "place.region",
        Products: "productRelationship.name",
      },
      show_right_filter: false,
      toggleRearrangeModal: false,
      activtykey: 0,
      intialval: {},
      activtylogPayload: {
        type: "filter",
        module: "log",
        lastUpdated: new Date().toJSON(),
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: {
            $in: [],
          },
        },
        searchQuery: "",
      },
      show_right: false,
      activitytotal: 0,
      activityLogData: [],
      newCoordinate: {},
      initialRegionName: "",
      notification: {},
      searchvalue: "",
      initialServiceQualificationProduct: [],

      polygonpathdata: [],
      newaddressnew: {},
      newone: [],
      newtwo: [],
      newone1: [],
      newtwo1: [],
      servicestatus: "",
      checkofferinput: "",
      cheackAvailable: "false",
      mainInfoServiceQualification: [],

      filterLayout: {
        layoutFor: "productoffering",
        filterQuery: {
          id: {
            $nin: [],
          },
        },
        layout: [
          {
            prop: "lifecycleStatus",
            label: "Status",
            type: "String",
            fieldToFilter: "lifecycleStatus",
          },
          {
            prop: "category",
            label: "Category",
            type: "Array",
            // fieldToFilter: "name"
          },
          {
            prop: "productOfferingPrice",
            label: "price",
            type: "Array",
            fieldToFilter: "name",
          },
        ],
      },
      layout: [
        {
          prop: "lifecycleStatus",
          label: "Status",
          type: "String",
          fieldToFilter: "lifecycleStatus",
          fieldToSearch: "lifecycleStatus",
        },
        {
          prop: "category",
          label: "Category",
          type: "Array",
          fieldToFilter: "category",
        },
        {
          prop: "productOfferingPrice",
          label: "price",
          type: "Array",
          fieldToFilter: "productOfferingPrice.name",
          fieldToSearch:
            "productOfferingPrice[0].productofferPriceAlteration[0].productprice.taxAmount.Amount",
        },
      ],

      place: {},
      rangeName: "Meter",
      dropdown_data_distance: [
        { value: "Meter" },
        { value: "Km" },
        { value: "Mile" },
      ],
      dropdown_data_productoffer: [],
      enableButton: false,
      specPreValue: [],
      emitedoffer: [],
      emitoffpost: [],
      dummydata: [],
      formattedObjects: {},
      emittedValues: {},
      addressquery: "",
      productoffer: "",
      filterResultData2: {},

      configarraynameduplicate: [],
      configarrayname: [],

      offerfilterquery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 100,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // name:1,
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },

      configarray: {},

      productspecification: "",
      dropdown_data_productspecification: [],
      componentkey: 0,
      Mapcomponentkey: 0,
      addresscomponentkey: 0,
      editpayload: {},
      delete_modal_active: false,
      check_modal_active: false,
      offer_modal_active: false,
      deleteobj: {},
      dropdowndt: [],

      getdata: null,
      radiuscircle: "",
      radiuscircle1: "",
      lat: "",
      lng: "",
      propertytype: "",
      dropdown_data_type: [
        { value: "Full address" },
        { value: "Street" },
        { value: "City" },
        { value: "Province" },
        { value: "Postal code" },
        { value: "Prefix (FSA)" }
      ],

      characteristicpreval: {
        characteristic: {
          "@type": "",
          street: "",
          city: "",
          country: "",
          // regio nName:'',
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
        },
      },

      characteristic: {
        "@type": "",
        street: "",
        city: "",
        country: "",
        // regio nName:'',
        default: false,
        description: "",
        postalCode: "",
        state: "",
        stateOrProvince: "",
        street1: "",
        street2: "",
        unit: "",
      },
      mediumType: "",
      searchFilterData: {},
      tableData: [],
      tableHeader: [],
      tableHeaderRearranging: [],
      currentTableName: "",
      showimport: true,
      widthofdrawer: 430,
      regionName: "",

      radioOptions: [
        { name: "Point of interest", checked: true, disabled: false, id: 1 },
        { name: "Radius", checked: false, disabled: false, id: 2 },
        { name: "Square", checked: false, disabled: false, id: 1 },
        { name: "Hexagon", checked: false, disabled: false, id: 2 },
      ],
      vradio: "Point of interest",

      regioncode: "",
      dropdown_data_regioncode: [],
      show_upload_drawer: false,

      selectedSwitch: "Address",
      currPage: 1,
      rowsPerPage: 10,
      externalRowsPerPage: 10,
      switchOptions: [
        {
          name: "Region",
          value: "Address",
        },
        {
          name: "Products",
          value: "Products",
        },
      ],
      tableHeader1: [
        {
          sortable: false,
          resizable: true,
          width: 100,
          name: "Region name",
          disabled: true,
          checked: true,
          label: "Region name",
          prop: "regionName",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 154,
          name: "Status",
          disabled: false,
          checked: true,
          label: "Status",
          prop: "servicestatus",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 154,
          name: "Type",
          disabled: false,
          checked: true,
          label: "Type",
          prop: "type",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          checked: true,
          disabled: true,
          name: "Address",
          label: "Address",
          prop: "addressdescription",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 154,
          name: "Created date",
          disabled: false,
          checked: true,
          label: "Created date",
          prop: "createdat",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 154,
          name: "Last updated",
          disabled: false,
          checked: true,
          label: "Last updated",
          prop: "updatedat",
          align: "left",
        },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 250,
        //   name: "Region shape",
        //   checked: true,
        //   disabled: false,
        //   label: "Region shape",
        //   prop: "regionshape",
        //   align: "left",
        // },
       
      ],
      tableHeader2: [
        {
          name: "Region",
          checked: true,
          sortable: false,
          resizable: true,
          disabled: true,
          // width: 850,
          label: "Region",
          prop: "region",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          name: "Products",
          checked: true,
          disabled: true,
          // width: 400,
          label: "Products",
          prop: "availableOffer",
          align: "left",
        },
      ],
      tableData1: [],
      tableData2: [],
      checkedValue: [],
      initialCheckedValueOffer: [],
      newoffers: [],
      editMode: false,
    };
  },

  computed: {
    tableDataOnSelectedRows() {
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      // console.log(this.tableData, "this.tableData");
      // return this.tableData.slice(start, end);
      return this.tableData;
    },
    dynamicHeight() {
      return this.vradio === "Radius" ? "178px" : "300px";
    },

    mapCenter() {
      console.log("this.lat,this.lng", this.lat, this.lng);

      return { lat: this.lat, lng: this.lng };
    },

    circleOptions() {
      return {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      };
    },
  },
  watch: {
    selectedSwitch: {
      async handler(val) {
        this.templateFilterQuery.filterQuery = {};
        this.templateFilterQuery.searchQuery = "";
        this.templateFilterQuery.searchList = [];
        this.templateFilterQuery.type = "filter";
        this.filtertaglength = 0;

        this.switchOff = true;

        let check = false;
        if (val) {
          const moduleType =
            this.selectedSwitch.toLowerCase() === "address"
              ? "address"
              : "productqualification";

          const obj = {
            type: "filter",
            module: moduleType,
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 1,
              currentPage: 1,
            },
            isNeedToBeSort: true,
            sortFieldAndOrder: {},
            limit: 10,
            filterQuery: {},
            searchQuery: "1",
          };

          const response = await UPCAxiosInstance.post(`util/filterData`, obj);
          this[
            this.selectedSwitch.toLowerCase() === "address"
              ? "newone1"
              : "newtwo1"
          ] = response?.data?.data;

          console.log(val, "selectedSwitch");
          this.searchvalue = "";

          let serviceQualificationTableHeads = await MLTAxiosInstance.get(
            `/settings`
          );
          console.log(
            "serviceQualificationTableHeads",
            serviceQualificationTableHeads
          );
          if (val === "Address") {
            this.permisstionData = this.permisstionDataRegion;
            // Fetch and store Vuex filter data
            await this.$store.dispatch("serviceQualificationAddressFilterData");
            this.filter = this.updateRegionNames(
              this.$store.getters.serviceQualificationAddressFilterData
            );
            console.log("filter data from store", this.filter);

            this.componentkey += 1;

            //           let response = await UPCAxiosInstance.get(`/geographicAddress`);
            // console.log(response, "geooooooooooo");
            // this.newone = await JSON.parse(JSON.stringify(response?.data)).reverse();
            // this.tableData = await JSON.parse(JSON.stringify(response?.data)).reverse();
            // this.tableData1 = await JSON.parse(JSON.stringify(response?.data)).reverse();
            this.widthofdrawer = 430;
            // await this.fetchData();
            this.currentTableName = val;

            if (
              serviceQualificationTableHeads?.data?.entityRef?.[
                "serviceQualificationRegion"
              ]
            ) {
              let serviceQualificationHeadModal =
                serviceQualificationTableHeads.data.entityRef[
                  "serviceQualificationRegion"
                ];
              let serviceQualificationOrg =
                serviceQualificationTableHeads.data.entityRef[
                  "serviceQualificationRegion"
                ].filter((ele) => ele.checked);
              console.log(
                serviceQualificationOrg,
                "serviceQualificationHeadModal top Add",
                serviceQualificationHeadModal
              );

              if (serviceQualificationHeadModal) {
                console.log(
                  serviceQualificationOrg,
                  "serviceQualificationHeadModal dup",
                  serviceQualificationHeadModal
                );
                this.tableHeaderRearranging = JSON.parse(
                  JSON.stringify(serviceQualificationHeadModal)
                );
                // this.tableHeader = JSON.parse(
                //   JSON.stringify(serviceQualificationOrg)
                // );
                this.tableHeader = this.tableHeader1;
                this.tableHeaderOrgcc = JSON.parse(
                  JSON.stringify(this.tableHeaderRearranging)
                );
                this.tableHeaderOrg = JSON.parse(
                  JSON.stringify(this.tableHeader)
                );
              }
            } else {
              this.tableHeader = this.tableHeader1;
              // this.tableHeaderRearranging = this.tableHeader1;
              this.tableHeaderRearranging = JSON.parse(
                JSON.stringify(this.tableHeader)
              );
            }

            console.log("address call", this.tableData, this.tableHeader);
          } else if (val === "Products") {
            this.permisstionData = this.permisstionDataProduct;

            await this.$store.dispatch("serviceQualificationProductFilterData");
            this.filter = this.updateRegionNames(
              this.$store.getters.serviceQualificationProductFilterData
            );
            console.log("filter data from store", this.filter);

            this.componentkey += 1;
            this.currentTableName = val;
            // this.getproductQualification();
            // this.tableData = JSON.parse(JSON.stringify(this.tableData2)).reverse();
            this.widthofdrawer = 430;
            this.getoffer();
            if (
              serviceQualificationTableHeads?.data?.entityRef?.[
                "serviceQualificationProducts"
              ]
            ) {
              let serviceQualificationHeadModal =
                serviceQualificationTableHeads.data.entityRef[
                  "serviceQualificationProducts"
                ];
              let serviceQualificationOrg =
                serviceQualificationTableHeads.data.entityRef[
                  "serviceQualificationProducts"
                ].filter((ele) => ele.checked);
              // console.log(
              //   serviceQualificationOrg,
              //   "serviceQualificationHeadModal top prod",
              //   serviceQualificationHeadModal
              // );

              if (serviceQualificationHeadModal>10) {
                // console.log(
                //   serviceQualificationOrg,
                //   "serviceQualificationHeadModal product",
                //   serviceQualificationHeadModal
                // );
                this.tableHeaderRearranging = JSON.parse(
                  JSON.stringify(serviceQualificationHeadModal)
                );
                this.tableHeader = JSON.parse(
                  JSON.stringify(serviceQualificationOrg)
                );
                this.tableHeaderOrgcc = JSON.parse(
                  JSON.stringify(this.tableHeaderRearranging)
                );
                this.tableHeaderOrg = JSON.parse(
                  JSON.stringify(this.tableHeader)
                );
              }
            } else {
              this.tableHeader = this.tableHeader2;
              this.tableHeaderRearranging = JSON.parse(
                JSON.stringify(this.tableHeader)
              );
            }

            // this.tableHeader = this.tableHeader2;
          }
          // Call to update ticket data (with pagination support)
          // await this.ticketdataCalling(1, 10);

          //check
          check = true;
          check;
        }
        if (check) {
          setTimeout(() => {
            this.switchOff = false;
            console.log("@@@");
            console.log("@@@switch end... :", this.switchOff);
          }, 2000);
        }
      },
      immediate: true,
      deep: true,
    },
    checkofferinput: {
      handler(val) {
        if (val === "") {
          this.cheackAvailable = false;
        }
        console.log(this.cheackAvailable, "this.cheackAvailable");
      },
      immediate: true,
      deep: true,
    },
    vratio: {
      handler(val) {
        console.log(val, "vratio..........");
      },
      immediate: true,
      deep: true,
    },
    polygonpathdata: {
      handler(val) {
        console.log(val, "polygonpathdata..........");
      },
      immediate: true,
      deep: true,
    },
    mainInfoServiceQualification: {
      handler(val) {
        this.mainInfoServiceQualification = val;
        console.log(val, "this.mainInfoServiceQualification");
      },
      immediate: true,
      deep: true,
    },

    emittedValuesiii: {
      async handler(emittedValues) {
        console.log(emittedValues, "configarrayname watching");
        const formattedObjects = emittedValues
          ? Object.keys(emittedValues).map((name) => ({
              name: name,
              value: emittedValues[name],
            }))
          : [];
        this.formattedObjects = formattedObjects;
        console.log("form form", this.formattedObjects);

        console.log(
          "this.filterResultData2",
          this.filterResultData2,
          this.filterResultData2?.data?.data?.length
        );

        this.dropdown_data_productoffer =
          await this.filterResultData2?.data?.data?.filter((item) => {
            return this.filterOffers(
              item,
              this.formattedObjects,
              this.configarray?.name
            );
          });

        this.dummydata = this.dropdown_data_productoffer;

        console.log(
          this.filterResultData2?.data?.data?.length,
          this.dropdown_data_productoffer?.length,
          this.dropdown_data_productoffer,
          "loooooood"
        );
      },
      immediate: true,
      deep: true,
    },

    rangeName: {
      async handler(val) {
        console.log("dist is watching", val);
        // v-if="vradio === 'Radius'"
        if (val === "Meter") {
          this.radiuscircle1 = parseInt(this.radiuscircle);
          console.log("meter", this.radiuscircle1);
        } else if (val === "Km") {
          this.radiuscircle1 = parseInt(this.radiuscircle) * 1000;
          console.log("km", this.radiuscircle1);
        } else if (val === "Mile") {
          this.radiuscircle1 = parseInt(this.radiuscircle) * 1609.344;
          console.log("Mile", this.radiuscircle1);
        } else {
          this.radiuscircle1 = parseInt(this.radiuscircle);
          console.log("other", this.radiuscircle1);
        }

        if (this.vradio === "Square") {
          // let cordinates = { lat: this.lat, lng: this.lng }
          const sideLength = this.radiuscircle1 / 111320;
          // const sideLength = 15;
          const halfSide = sideLength / 2;

          console.log(
            "poiuytrewqASDFGHJKL,MNBVCX",
            this.radiuscircle1,
            halfSide
          );

          const bottomLeftLat = this.lat - halfSide;
          const bottomLeftLng = this.lng - halfSide;
          const topRightLat = this.lat + halfSide;
          const topRightLng = this.lng + halfSide;

          const bottomRightLat = bottomLeftLat;
          const bottomRightLng = topRightLng;
          const topLeftLat = topRightLat;
          const topLeftLng = bottomLeftLng;

          const squareCoords = [
            { lat: parseFloat(bottomLeftLat), lng: parseFloat(bottomLeftLng) },
            {
              lat: parseFloat(bottomRightLat),
              lng: parseFloat(bottomRightLng),
            },

            { lat: parseFloat(topRightLat), lng: parseFloat(topRightLng) },
            { lat: parseFloat(topLeftLat), lng: parseFloat(topLeftLng) },
          ];
          console.log("squareCoords", squareCoords);

          this.polygonpathdata = squareCoords;
          console.log("squareCoords", this.polygonpathdata);
        }

        if (this.vradio === "Hexagon") {
          const angles = [0, 60, 120, 180, 240, 300];

          const hexagonCoords = angles.map((angle) => {
            const rad = (angle * Math.PI) / 180; // Convert angle to radians
            const latOffset = (this.radiuscircle1 / 111320) * Math.cos(rad); // Latitude offset
            const lngOffset = (this.radiuscircle1 / 111320) * Math.sin(rad); // Longitude offset

            return {
              lat: this.lat + latOffset,
              lng: this.lng + lngOffset,
            };
          });
          // Ensure the first point is the same as the last to close the hexagon
          hexagonCoords.push(hexagonCoords[0]);

          console.log(
            "Hexagon coordinates:",
            hexagonCoords,
            this.radiuscircle1,
            "dist..."
          );

          this.polygonpathdata = hexagonCoords;
          console.log("Hexagon coordinates:", this.polygonpathdata);
        }
      },
      immediate: true,
      deep: true,
    },

    radiuscircle: {
      async handler(val) {
        console.log("radiuscircle is watching", val);
        if (this.rangeName === "Meter") {
          this.radiuscircle1 = parseInt(val);
          console.log("meter");
        } else if (this.rangeName === "Km") {
          this.radiuscircle1 = parseInt(val) * 1000;
          console.log("km", this.radiuscircle1);
        } else if (this.rangeName === "Mile") {
          this.radiuscircle1 = parseInt(val) * 1609.344;
          console.log("Miles", this.radiuscircle1);
        } else {
          this.radiuscircle1 = parseInt(val);
          console.log("other");
        }

        if (this.vradio === "Square") {
          // let cordinates = { lat: this.lat, lng: this.lng }
          const sideLength = this.radiuscircle1 / 111320;
          // const sideLength = 15;
          const halfSide = sideLength / 2;

          const bottomLeftLat = this.lat - halfSide;
          const bottomLeftLng = this.lng - halfSide;
          const topRightLat = this.lat + halfSide;
          const topRightLng = this.lng + halfSide;

          const bottomRightLat = bottomLeftLat;
          const bottomRightLng = topRightLng;
          const topLeftLat = topRightLat;
          const topLeftLng = bottomLeftLng;

          const squareCoords = [
            { lat: parseFloat(bottomLeftLat), lng: parseFloat(bottomLeftLng) },
            {
              lat: parseFloat(bottomRightLat),
              lng: parseFloat(bottomRightLng),
            },

            { lat: parseFloat(topRightLat), lng: parseFloat(topRightLng) },
            { lat: parseFloat(topLeftLat), lng: parseFloat(topLeftLng) },
          ];
          console.log("squareCoords", squareCoords);
          this.polygonpathdata = squareCoords;
          console.log("squareCoords", this.polygonpathdata);
        }

        if (this.vradio === "Hexagon") {
          const angles = [0, 60, 120, 180, 240, 300];

          const hexagonCoords = angles.map((angle) => {
            const rad = (angle * Math.PI) / 180; // Convert angle to radians
            const latOffset = (this.radiuscircle1 / 111320) * Math.cos(rad); // Latitude offset
            const lngOffset = (this.radiuscircle1 / 111320) * Math.sin(rad); // Longitude offset

            return {
              lat: this.lat + latOffset,
              lng: this.lng + lngOffset,
            };
          });
          // Ensure the first point is the same as the last to close the hexagon
          hexagonCoords.push(hexagonCoords[0]);

          console.log(
            "Hexagon coordinates:",
            hexagonCoords,
            this.radiuscircle1,
            "number..."
          );

          this.polygonpathdata = hexagonCoords;
        }
      },
      immediate: true,
      deep: true,
    },

    regionName: {
      async handler(val) {
        console.log(val, this.servicestatus, "regionName watching");
        if (val && this.servicestatus) {
          this.enableButton = true;
          console.log(val, "editeddd");
        } else {
          this.enableButton = false;
        }
      },
      immediate: true,
      deep: true,
    },

    servicestatus: {
      async handler(val) {
        console.log(val, this.regionName, "filteredstatus watching");
        if (val && this.regionName) {
          this.enableButton = true;
          console.log("editeddddddddd");
        } else {
          this.enableButton = false;
        }
      },
      immediate: true,
      deep: true,
    },

    tableData2: {
      async handler(val) {
        console.log(val, "table 2 watching");
        this.tableData = JSON.parse(JSON.stringify(val)).reverse();
      },
      immediate: true,
      deep: true,
    },

    regioncode: {
      handler(val) {
        console.log(val, "regioncode watching");
        if (val) {
          this.enableButton = true;
        } else {
          this.enableButton = false;
        }
      },
      immediate: true,
      deep: true,
    },

    productspecification: {
      handler(val) {
        console.log(val, "productspecification watching");
      },
      immediate: true,
      deep: true,
    },
    tableData: {
      handler(val) {
        this.tableData = val;
        console.log(val, "tabledata.....");
      },
      immediate: true,
      deep: true,
    },
    propertytype: {
      handler(val) {
        console.log(val, "propertytype watching");
      },
      immediate: true,
      deep: true,
    },
    lat: {
      handler(val) {
        this.lat = val;
        this.polygonpathfunction();
      },
      immediate: true,
      deep: true,
    },
    lng: {
      handler(val) {
        this.lng = val;
        this.polygonpathfunction();
      },
      immediate: true,
      deep: true,
    },
  },
  async beforeCreate() {},
  async mounted() {
    // Fetch necessary data based on selected switch
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

    let sampleTheadsd = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );

    const mergedPayload = sampleTheadsd.data.permission;
    // const mergedPayloadRegion = sampleTheadsd.data.permission.sections.region;
    // const mergedPayloadProduct = sampleTheadsd.data.permission.sections.product;

    this.permisstionDataProduct =
      mergedPayload["Service qualification"].sections.product;
    this.permisstionDataRegion =
      mergedPayload["Service qualification"].sections.region;

    this.permisstionData = this.permisstionDataRegion;

    this.updateTableAndDrawer();

    try {
      // Fetch search filter data and status list in parallel
      const [searchFilterData] = await Promise.all([this.supplierFilterData()]);

      this.searchFilterData = searchFilterData;
      const rootName = this.$cookies.get("rootName");
      const url =
        rootName === "Halleyx"
          ? "mapRef/collection/statuslist"
          : `mapRef/collection/statuslist?company=${this.$cookies.get(
              "rootName"
            )}`;

      const { data: statusList } = await DMNAxiosInstance.get(url);
      this.statusList = statusList.data;

      console.log("Fetched status list:", statusList);

      // Filter the status list
      this.filteredstatus = statusList.data.filter((item) =>
        ["Active", "Inactive", "Pending"].includes(item.name)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error gracefully here
    }
  },
  methods: {
    callOverviewRoute(id) {
      id
      console.log("callOverviewRoute::",id);

      this.$router.push({
          name: "regionOverview",
          query: { id: id, mode: "edit" },
        });
      
     },
    
    formatDate(isoDate) {
    const date = new Date(isoDate);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
},
    closeAddRegionModal() {  
    },

    openAddNewRegionModal() { 
      this.addregionKey += 1;
      this.showAddNewRegionModal = true;
    },

    addRegion(val) {
      console.log("addRegion::",val);
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },

    reAssignOrgTableHeader() {
      // If there is change in the table header then ressign to the Orginal data
      if (
        this.tableHeaderRearranging != this.tableHeaderOrgcc &&
        this.tableHeaderOrgcc.length > 0
      ) {
        this.tableHeaderRearranging = JSON.parse(
          JSON.stringify(this.tableHeaderOrgcc)
        );
      }
      if (
        this.tableHeader != this.tableHeaderOrg &&
        this.tableHeaderOrg.length > 0
      ) {
        this.tableHeader = JSON.parse(JSON.stringify(this.tableHeaderOrg));
      }
      this.toggleRearrangeModal = true;
    },
    resizedWidth(val) {
      console.log(val, "resizedWidth", val);
      const updateWidth = (array) => {
        let item = array.find((i) => i?.prop === val?.prop);
        if (item) item.width = val.width;
      };

      updateWidth(this.tableHeader);
      updateWidth(this.tableHeaderRearranging);
      console.log(
        this.tableHeader,
        "resizedWidth loop",
        this.tableHeaderRearranging
      );
      this.applyColumns(this.tableHeaderRearranging);
    },
    async refreshTable() {
      this.templateFilterQuery.module =
        this.selectedSwitch == "Address" ? "address" : "productQualification";
      const response = await UPCAxiosInstance.post(
        `util/filterData`,
        this.templateFilterQuery
      );
      // const response = await UPCAxiosInstance.get(`/templateTicket`);

      this.tableData = response.data.data;
      this.totalvalue = response.data.total;
    },

    sorting_Data(data, data1) {
      //
      console.log(data, data1);
      this.Supplierfilterquery.type = "filter";
      if (data === "icon-angle-up-small-filled sorting-icon") {
        this.Supplierfilterquery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = 1;
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        this.Supplierfilterquery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = -1;
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-up-small-filled") {
        this.Supplierfilterquery.isNeedToBeSort = false;
        let obj = {};
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled") {
        this.Supplierfilterquery.isNeedToBeSort = false;
        let obj = {};
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      }
    },

    async searchKey(val) {
      console.log("searchKey", val);

      this.searchvalue = val;
      this.templateFilterQuery["company"] = this.$cookies.get("company");
      this.templateFilterQuery["type"] = "search";
      this.templateFilterQuery["searchQuery"] = this.searchvalue;
      this.templateFilterQuery.searchList =
        this.selectedSwitch == "Address"
          ? [
              {
                "streetAddressLine.characteristic.regionName": {
                  $regex: val,
                  $options: "i",
                },
              },
              {
                "streetAddressLine.characteristic.regionshape": {
                  $regex: val,
                  $options: "i",
                },
              },
              {
                "streetAddressLine.characteristic.servicestatus": {
                  $regex: val,
                  $options: "i",
                },
              },
              {
                "streetAddressLine.characteristic.description": {
                  $regex: val,
                  $options: "i",
                },
              },
            ]
          : [
              {
                "place.region": {
                  $regex: val,
                  $options: "i",
                },
              },
            ];
      this.currPage = 1;
      this.rowsPerPage = 10;
      if (this.filtertagvalue.length != 0) {
        this.templateFilterQuery["type"] = "filter-search";
      }

      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },

    async ticketfilterData(filterSelectedvalue, filterQuery) {
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      console.log(
        this.filtertaglength,
        "this.filtertaglength...................."
      );

      filterQuery.company = this.$cookies.get("company");
      //  filterQuery['filterQuery']['"@baseType"'] = 'supplier'
      if (this.filtertagvalue.length != 0) {
        this.templateFilterQuery = filterQuery;
        this.templateFilterQuery.type = "filter";
        if (this.searchvalue != "") {
          this.templateFilterQuery["searchQuery"] = this.searchvalue;
          this.templateFilterQuery["type"] = "filter-search";
        }
        this.templateFilterQuery.module =
          this.selectedSwitch == "Address" ? "address" : "productQualification";
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.templateFilterQuery
        );

        this.tableData = filterResultData.data.data;
        this.totalvalue = filterResultData.data.total;
      } else {
        this.currPage = 1;
        this.rowsPerPage = 10;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      }
    },

    async changeRowsPerPage(count) {
      //
      if (this.companyasignee != "") {
        this.rowsPerPage = count;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
        this.refreshTable();
      }
    },
    async currentPage(val) {
      if (this.companyasignee != "") {
        this.currPage = val;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
        this.refreshTable();
      }
      this.table_height = this.tableDataOnSelectedRows.length * 40 + 43;
    },

    async ticketdataCalling(currentPage, rowsPerPage) {
      console.log("ticketdataCalling", currentPage, rowsPerPage);
      const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
      this.companyasignee = val.data.company;
      this.templateFilterQuery["company"] = val.data.company;
      this.templateFilterQuery["paginatedQuery"]["currentPage"] = currentPage;
      this.templateFilterQuery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;


      this.templateFilterQuery.filterQuery = {
    "streetAddressLine.characteristic.category": {
        "$in": [
            "parent"
        ]
    }
}


      this.templateFilterQuery.module =
        this.selectedSwitch == "Address" ? "address" : "productQualification";
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.templateFilterQuery
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        console.log(
          "ticketdataCalling...",
          this.tableData,
          filterResultData.data.data
        );

        if (this.selectedSwitch === "Address") {
          this.newone = JSON.parse(JSON.stringify(filterResultData.data.data));
          this.fetchData();
        } else if (this.selectedSwitch === "Products") {
          this.newtwo = JSON.parse(JSON.stringify(filterResultData.data.data));
        }
        this.totalvalue = filterResultData.data.total;
        this.tableDataOnSelectedRows;
      }
      this.componentkey += 1;
    },

    updateRegionNames(data) {
      // Loop through each key in the object
      for (const key in data) {
        // Check if the current key's value is an array
        if (Array.isArray(data[key])) {
          // Loop through the array of objects under each key
          data[key].forEach((item) => {
            try {
              // Check if 'name' is an array and has at least one element
              if (Array.isArray(item.name) && item.name.length > 0) {
                // Set 'name' to the first element as a string
                item.name = item.name[0];
              } else {
                throw new Error(
                  `Invalid 'name' value in item: ${JSON.stringify(item)}`
                );
              }
            } catch (error) {
              console.error(`Error processing item in ${key}:`, error.message);
            }
          });
        } else {
          console.error(
            `Expected an array for key: ${key}, but found:`,
            data[key]
          );
        }
      }
      return data;
    },

    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
      // this.closeRefresh += 1;
    },

    async applyColumns(val) {
      // Function to rearrange table header
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      let concatedEntity = sampleTheads?.data?.entityRef || {};

      if (this.currentTableName === "Address") {
        // Update the serviceQualificationRegion in entityRef
        concatedEntity.serviceQualificationRegion = val;

        let reArrangeTableHeadPayload = { entityRef: concatedEntity };

        const changeTableHeader = await MLTAxiosInstance.patch(
          `/settings`,
          reArrangeTableHeadPayload
        );
        console.log(
          changeTableHeader,
          "reArrangeTableHeadPayload",
          reArrangeTableHeadPayload
        );
      } else if (this.currentTableName === "Products") {
        // Update the serviceQualificationProducts in entityRef
        concatedEntity.serviceQualificationProducts = val;

        let reArrangeTableHeadPayload = { entityRef: concatedEntity };

        const changeTableHeader = await MLTAxiosInstance.patch(
          `/settings`,
          reArrangeTableHeadPayload
        );
        console.log(
          changeTableHeader,
          "reArrangeTableHeadPayload",
          reArrangeTableHeadPayload
        );
      }

      // Refresh the table header
      let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
      console.log("sampleTheads", sampleTheadsRefresh?.data);
      console.log("sampleTheads mounted", sampleTheadsRefresh?.data?.entityRef);

      if (this.currentTableName === "Address") {
        this.tableHeaderRearranging =
          sampleTheadsRefresh?.data?.entityRef["serviceQualificationRegion"];
        this.tableHeader = this.tableHeaderRearranging.filter(
          (ele) => ele.checked
        );
        this.tableHeaderOrgcc = JSON.parse(
          JSON.stringify(this.tableHeaderRearranging)
        );
        this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tableHeader));
      } else if (this.currentTableName === "Products") {
        this.tableHeaderRearranging =
          sampleTheadsRefresh?.data?.entityRef["serviceQualificationProducts"];
        this.tableHeader = this.tableHeaderRearranging.filter(
          (ele) => ele.checked
        );
        this.tableHeaderOrgcc = JSON.parse(
          JSON.stringify(this.tableHeaderRearranging)
        );
        this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tableHeader));
      }
    },
    // updateTableAndDrawer() {
    async updateTableAndDrawer() {
      if (this.selectedSwitch === "Products") {
        // this.getproductQualification();
        this.tableHeader = this.tableHeader2;

        // Fetch and store Vuex filter data
        await this.$store.dispatch("serviceQualificationProductFilterData");
        this.filter = this.updateRegionNames(
          this.$store.getters.serviceQualificationProductFilterData
        );
        console.log("filter data from store", this.filter);

        // this.tableData = JSON.parse(JSON.stringify(this.tableData2)).reverse();
      } else if (this.selectedSwitch === "Address") {
        this.tableHeader = this.tableHeader1;

        // Fetch and store Vuex filter data
        await this.$store.dispatch("serviceQualificationAddressFilterData");
        this.filter = this.updateRegionNames(
          this.$store.getters.serviceQualificationAddressFilterData
        );
        console.log("filter data from store", this.filter);

        // this.tableData = JSON.parse(JSON.stringify(this.tableData1)).reverse();
      }

      // Call to update ticket data (with pagination support)
      await this.ticketdataCalling(this.currPage, this.rowsPerPage);
      this.widthofdrawer = 430;
    },

    closeactivitylog() {
      this.show_right = false;
    },
    async activiylogcall(val) {
      val;
      if (this.selectedSwitch === "Address") {
        this.activtylogPayload.filterQuery.category.$in[0] =
          "serviceQualificationRegion";
      } else {
        this.activtylogPayload.filterQuery.category.$in[0] =
          "serviceQualificationProduct";
      }
      const logaccess = await MLTAxiosInstance.post(
        `/util/filterData`,
        this.activtylogPayload
      );
      this.activityLogData = logaccess.data.data;
      this.activitytotal = logaccess.data.total;
      console.log(logaccess.data.data);
    },
    activityLogDrawer(val) {
      this.activiylogcall(val);
      this.show_right = true;
      this.activtykey += 1;
    },
    mapCenterNew(val) {
      console.log("new map center", val);
      this.lat = val.lat;
      this.lng = val.lng;
      this.newCoordinate = { lat: this.lat, lng: this.lng };
    },

    polygonPathObject(poly, typee) {
      console.log("polygonPathObject", poly, typee);
      this.polygonpathdata = { polygonData: poly, polygonType: typee };
      console.log("polygonPathObject", this.polygonpathdata);
    },

    callDirectEdit(i, index) {
      console.log("i, index", i, index, this.selectedSwitch, this.editMode);
      this.editMode = true;
      this.openuploaddrawer1(i, index);
    },

    haversineDistance(lat1, lng1, lat2, lng2) {
      const toRadians = (degrees) => degrees * (Math.PI / 180);

      const R = 6371e3; // Earth's radius in meters
      const φ1 = toRadians(lat1);
      const φ2 = toRadians(lat2);
      const Δφ = toRadians(lat2 - lat1);
      const Δλ = toRadians(lng2 - lng1);

      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c; // Distance in meters
    },

    isPointInCircle(point, center, radius) {
      const distance = this.haversineDistance(
        point.lat,
        point.lng,
        center.lat,
        center.lng
      );
      return distance <= radius;
    },

    isPointInPolygon(point, polygon) {
      const { lat, lng } = point;
      let isInside = false;

      for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].lat,
          yi = polygon[i].lng;
        const xj = polygon[j].lat,
          yj = polygon[j].lng;

        const intersect =
          yi > lng != yj > lng &&
          lat < ((xj - xi) * (lng - yi)) / (yj - yi) + xi;
        if (intersect) isInside = !isInside;
      }

      return isInside;
    },

    polygonpathfunction() {
      console.log(
        "started...polygonpathfunction...function",
        this.vradio,
        this.lat,
        this.lng
      );
      if (this.vradio === "Square") {
        // for square
        const sideLength = this.radiuscircle1 / 111320;
        // const sideLength = 15;
        const halfSide = sideLength / 2;

        const bottomLeftLat = this.lat - halfSide;
        const bottomLeftLng = this.lng - halfSide;
        const topRightLat = this.lat + halfSide;
        const topRightLng = this.lng + halfSide;

        const bottomRightLat = bottomLeftLat;
        const bottomRightLng = topRightLng;
        const topLeftLat = topRightLat;
        const topLeftLng = bottomLeftLng;

        const squareCoords = [
          { lat: parseFloat(bottomLeftLat), lng: parseFloat(bottomLeftLng) },
          { lat: parseFloat(bottomRightLat), lng: parseFloat(bottomRightLng) },

          { lat: parseFloat(topRightLat), lng: parseFloat(topRightLng) },
          { lat: parseFloat(topLeftLat), lng: parseFloat(topLeftLng) },
        ];
        console.log("squareCoords.........", squareCoords);

        this.polygonpathdata = squareCoords;
      } else if (this.vradio === "Hexagon") {
        // for hexagon

        const angles = [0, 60, 120, 180, 240, 300];

        const hexagonCoords = angles.map((angle) => {
          const rad = (angle * Math.PI) / 180; // Convert angle to radians
          const latOffset = (this.radiuscircle1 / 111320) * Math.cos(rad); // Latitude offset
          const lngOffset = (this.radiuscircle1 / 111320) * Math.sin(rad); // Longitude offset

          return {
            lat: this.lat + latOffset,
            lng: this.lng + lngOffset,
          };
        });
        // Ensure the first point is the same as the last to close the hexagon
        hexagonCoords.push(hexagonCoords[0]);

        console.log("Hexagon coordinates:", hexagonCoords);

        this.polygonpathdata = hexagonCoords;
      } else if (this.vradio === "Point of interest") {
        this.polygonpathdata;
      } else {
        this.polygonpathdata = null;
      }
      console.log("this.polygonpathdata ", this.polygonpathdata);
    },

    emitedregiongroup(val) {
      console.log("emitedregiongroup", val);
      this.radiuscircle1 = val?.area;
      this.polygonpathdata = val?.polygonpathdata;
      console.log(
        "this.polygonpathdata....",
        this.polygonpathdata,
        "val?.polygonpathdata",
        val?.polygonpathdata
      );
      const coordinates = val?.geopoint;
      this.lat = coordinates?.lat;
      this.lng = coordinates?.lng;
      this.vradio = val?.regionshape;
      console.log("this.vradio", this.vradio);
    },

    async checkewdbutton1() {
      if (this.checkofferinput) {
        console.log("nooos wergthgfdsaSWDEFG");

        console.log("111112222222333333", this.checkofferinput, this.newone);

        const e = this.newone.filter((item) =>
          item?.streetAddressLine[0]?.characteristic?.description
            ?.toLowerCase()
            .includes(this.checkofferinput.toLowerCase())
        );

        console.log("matched address", e);

        const que = e.map((itm) => {
          return itm?.streetAddressLine[0]?.characteristic?.regionName?.toLowerCase();
          // .includes(val.toLowerCase())
        });

        console.log();
        console.log(
          ";wwwwwwwww;",
          que,
          this.newtwo,
          "+++++++++++++++++",
          this.newoffers
        );

        const emptyarray = [];
        this.newtwo?.forEach((val) => {
          console.log(val);

          que?.forEach((myreg) => {
            if (
              myreg.toLowerCase().includes(val?.place[0]?.region.toLowerCase())
            ) {
              emptyarray.push(val);
            }
          });
        });

        console.log(
          "offer from region which matched",
          this.newoffers,
          emptyarray
        );

        const allProductRelationships = [];

        await emptyarray.forEach((item) => {
          allProductRelationships.push(...item.productRelationship);
        });

        console.log("geting offer obj", allProductRelationships);

        // Remove duplicates based on _id using a Set
        this.newoffers = await Array?.from(
          new Set(allProductRelationships?.map((a) => a?.name))
        ).map((id) => {
          return allProductRelationships?.find((a) => a?.name === id);
        });
        this.cheackAvailable = true;

        console.log("qwertyuiop", this.newoffers);
      } else {
        this.newoffers = [];
      }
    },
    getLocaetions(val) {
      this.checkofferinput = val;
    },

    async getLoqcations(val) {
      console.log(val);
    },

    async getLocations() {
      console.log("emitters");

      let result = [];

      this.autocompleteService =
        new window.google.maps.places.AutocompleteService();
      this.sessionToken =
        new window.google.maps.places.AutocompleteSessionToken();
      console.log(
        "tokeeeeeeeeeeeeeen",
        this.autocompleteService,
        this.sessionToken
      );

      console.log("checkofferinput", this.checkofferinput);

      const response = await UPCAxiosInstance.get(
        `/address/suggestionlist?input=${this.checkofferinput}`
      );

      result = response?.data;
      this.locationData1 = result;
      console.log("address for new avail", this.locationData1);
    },

    async newaddress(val) {
      console.log("final address", val);
      this.newaddressnew = val;
    },

    async checkedbutton() {
      if (this.checkofferinput) {
        console.log("geooooooooooo");

        const val = this.newaddressnew;
        console.log("val", val);
        let address_components = await UPCAxiosInstance.get(
          `/address/details?place_id=${val.place_id}`
        );

        console.log("address_components", address_components);

        const lastValue = address_components?.data?.result?.formatted_address;

        this.lat = address_components?.data?.result?.geometry?.location?.lat;
        this.lng = address_components?.data?.result?.geometry?.location?.lng;

        console.log(
          "geo",
          this.lat,
          this.lng,
          address_components?.data?.result?.geometry,
          this.newtwo
        );

        const allProductRelationship = [];

        const response = await UPCAxiosInstance.get(`/productQualification`);

        this.newtwo = await response?.data;

        this.newtwo.forEach((item) => {
          console.log("looping", item, item?.place, item?.place?.regionshape);

          // Use the find method to get the regionshape
          const regionShapeObject = item?.place?.find(
            (item) => item.regionshape !== undefined
          );
          const regionShape = regionShapeObject
            ? regionShapeObject.regionshape
            : null;
          console.log("regionShape", regionShape); // Output: Square

          const polyoathObject = item?.place?.find(
            (item) => item.polygonpathdata !== undefined
          );
          const polyoath = polyoathObject
            ? polyoathObject.polygonpathdata
            : null;
          console.log("polyoath", polyoath); // Output: Square

          const areaObject = item?.place?.find(
            (item) => item.area !== undefined
          );
          const area = areaObject ? areaObject.area : null;
          console.log("area", area); // Output: Square

          const latObject = item?.place?.find((item) => item.lat !== undefined);
          const lat = latObject ? latObject.lat : null;
          console.log("lat", lat); // Output: Square

          const lngObject = item?.place?.find((item) => item.lng !== undefined);
          const lng = lngObject ? lngObject.lng : null;
          console.log("lng", lng); // Output: Square

          if (regionShape) {
            if (
              regionShape.toLowerCase() === "square" ||
              regionShape.toLowerCase() === "hexagon"
            ) {
              console.log(polyoath, this.lat, this.lng);
              const bools = this.isPointInPolygon(
                { lat: this.lat, lng: this.lng },
                polyoath
              );
              console.log("bools", bools);

              if (bools) {
                allProductRelationship.push(...item.productRelationship);
              }
            } else if (regionShape.toLowerCase() === "radius") {
              console.log(
                "circleeeeeeeeee",
                { lat: this.lat, lng: this.lng },
                { lat: lat, lng: lng },
                area
              );
              const booly = this.isPointInCircle(
                { lat: this.lat, lng: this.lng },
                { lat: lat, lng: lng },
                area
              );
              console.log("booly", booly);

              if (booly) {
                allProductRelationship.push(...item.productRelationship);
              }
            } else if (regionShape.toLowerCase() === "point of interest") {
              console.log(polyoath, this.lat, this.lng, "point of interest");

              let bools = false;

              if (polyoath) {
                if (polyoath?.polygonType.toLowerCase() === "array") {
                  console.log("polyoath.typee", polyoath.polygonType);
                } else if (polyoath?.polygonType.toLowerCase() === "nested") {
                  console.log("polyoath.typee", polyoath.polygonType);
                  // let polygon = polyoath.poly[0]
                  console.log("polygon", polyoath.polygonData[0]);

                  bools = this.isPointInPolygon(
                    { lat: this.lat, lng: this.lng },
                    polyoath.polygonData[0]
                  );
                  console.log("bools", bools);
                } else if (
                  polyoath?.polygonType?.toLowerCase() === "multinested"
                ) {
                  console.log("polyoath.typee", polyoath?.polygonType);
                  let polygon = polyoath?.polygonData[0];
                  console.log("polygon", polygon);

                  let newset = [];
                  polyoath?.polygonData?.forEach((item) => {
                    console.log("item", item);

                    bools = this.isPointInPolygon(
                      { lat: this.lat, lng: this.lng },
                      item
                    );
                    console.log("bools", bools);
                    newset.push(bools);
                  });

                  console.log(this.isAnyTrue(newset));

                  bools = this.isAnyTrue(newset);
                }
              }

              if (bools) {
                allProductRelationship.push(...item.productRelationship);
              }
            }
          }
        });
        const newoof = await Array?.from(
          new Set(allProductRelationship?.map((a) => a?.name))
        ).map((id) => {
          return allProductRelationship?.find((a) => a?.name === id);
        });
        this.newoffers = newoof;
        console.log(newoof);

        console.log("lastValue", lastValue);

        this.cheackAvailable = true;

        //   console.log("final from address",this.newoffers);
      } else {
        this.newoffers = [];
      }
    },

    closeoffer(val) {
      console.log("closeoffer", val);
      this.offer_modal_active = false;
    },

    emitStatus1(val1) {
      this.servicestatus = val1["value"];
    },

    async checkofferbutton() {
      const response = await UPCAxiosInstance.get(`/productQualification`);

      this.newtwo = await response?.data;
      this.check_modal_active = true;

      this.show_upload_drawer = true;
    },

    // pricingSearchTrigger(val) {
    //   this.searchvalue = val;
    //   console.log("onsearch value", val);
    //   if (this.selectedSwitch === "Address") {
    //     if (val) {
    //       // this.search = true;
    //       const result = this.addressfunction(val, this.newone);
    //       console.log("🚀 ~ mediaSearchTrigger ~ result:", result);
    //       this.tableData = result;
    //     } else {
    //       // this.search = false;
    //       console.log("🚀 ~ mediaSearchTrigger ~ this.search:");
    //       this.tableData = this.newone;
    //     }
    //   }
    //   if (this.selectedSwitch === "Products") {
    //     if (val) {
    //       // this.search = true;
    //       const result = this.productfunction(val, this.newtwo);
    //       console.log("🚀 ~ mediaSearchTrigger ~ result:", result);
    //       this.tableData = result;
    //     } else {
    //       // this.search = false;
    //       console.log("🚀 ~ mediaSearchTrigger ~ this.search:");
    //       this.tableData = this.newtwo;
    //     }
    //   }
    // },

    // searchInArray(searchTerm, dataArray) {
    //   const searchTermLower = searchTerm?.toLowerCase();

    //   const result = dataArray.filter((obj) => {
    //     return Object.values(obj).some((value) => {
    //       if (typeof value === "string") {
    //         return value?.toLowerCase().includes(searchTermLower);
    //       }
    //       return false;
    //     });
    //   });

    //   return result;
    // },

    // async productsesrch(val) {
    //   console.log("onsearch value", val, this.newtwo);
    //   // const faster = JSON.stringify(this.newtwo);
    //   // this.newtd =
    //   if (val) {
    //     // this.search = true;
    //     const result = this.productfunction(val, this.newtwo);
    //     console.log("🚀 ~ serviceSearchTrigger ~ result:", result);
    //     this.tableData = result;
    //   } else {
    //     // this.search = false;
    //     this.tableData = this.newtwo;
    //     console.log("🚀 ~ serviceSearchTrigger ~ this.search:");
    //     // this.copyOfMediaTableData = this.copyOfMediaTableData;
    //   }
    // },

    // async addresssearch(val) {
    //   console.log("onsearch value", val, this.newone);
    //   // const faster = JSON.stringify(this.newone);
    //   if (val) {
    //     // this.search = true;
    //     const result = this.addressfunction(val, this.newone);
    //     console.log("🚀 ~ serviceSearaddchTrigger ~ result:", result);
    //     this.tableData = result;
    //     console.log("this.tabledata......", this.tableData);
    //   } else {
    //     // this.search = false;
    //     this.tableData = this.newone;
    //     console.log("🚀 ~ serviceSearchTrigger ~ this.search:");
    //     // this.copyOfMediaTableData = this.copyOfMediaTableData;
    //   }
    // },

    // productfunction(search, array) {
    //   const lowerCaseSearch = search.toLowerCase();
    //   return array?.filter(
    //     (item) =>
    //       // item?.place?.some((place) =>
    //       item?.place[0]?.region?.toLowerCase().includes(lowerCaseSearch)
    //     // )
    //   );
    // },

    // addressfunction(search, array) {
    //   const lowerCaseSearch = search.toLowerCase();
    //   return array?.filter(
    //     (item) =>
    //       item?.streetAddressLine[0]?.characteristic?.regionName
    //         ?.toLowerCase()
    //         .includes(lowerCaseSearch)
    //     // place?.region?.toLowerCase().includes(lowerCaseSearch)
    //   );
    // },
    getMinMax(arr) {
      if (!arr || arr.length === 0) {
        return this.currencyFormatter(0, {
          currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
        });
      }

      let values = arr.map((item) => item?.value);
      let min = Math.min(...values);
      let max = Math.max(...values);
      min = min !== undefined && min !== null && !isNaN(min) ? min : 0;
      max = max !== undefined && max !== null && !isNaN(max) ? max : 0;
      if (min === max) {
        if (min == 0) {
          return this.currencyFormatter(0, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          });
        }
        return this.currencyFormatter(min, { currency: arr[0]?.unit });
        // return `  ${min} ${arr[0]?.unit}`;
      } else {
        if (min == 0) {
          return `${this.currencyFormatter(0, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          })} - ${this.currencyFormatter(max, { currency: arr[0]?.unit })}`;
        }
        if (max == 0) {
          return `${this.currencyFormatter(min, {
            currency: arr[0]?.unit,
          })}-${this.currencyFormatter(0, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          })}`;
        }
        return `${this.currencyFormatter(min, {
          currency: arr[0]?.unit,
        })} - ${this.currencyFormatter(max, { currency: arr[0]?.unit })}`;
      }
    },
    async getSearchedValueNewDiscount(value) {
      console.log("value form offer", value);
      try {
        const filterPostQuery = await UPCAxiosInstance.post(
          `util/filterData`,
          value
        );

        const responseData = filterPostQuery.data.data;
        // console.log(responseData,"responseData")
        // console.log(responseData,"responseData 2")
        this.mainInfoServiceQualification = responseData.map(
          ({
            name,
            productOfferingPrice,
            prodSpecCharValue,
            id,
            category,
            productSpecification,
          }) => {
            const productType = productSpecification["@baseType"];
            const categoryType = this.leaf(category ? category : "");
            let price = "0";
            let unit;

            if (productOfferingPrice?.length > 0) {
              // const productPrice = productOfferingPrice[0]?.tiers;
              const updatedProductPrice = productOfferingPrice;
              if (updatedProductPrice) {
                let priceRange = updatedProductPrice
                  ?.map((e) => e?.tiers?.map((tier) => tier?.price))
                  .flat(Infinity);
                price = this.getMinMax(priceRange);
                unit = priceRange[0]?.unit;
              }
              price =
                price !== undefined || price !== null || !isNaN(price)
                  ? price
                  : 0;

              unit = unit !== undefined || unit !== null ? unit : unit;
              console.log(price, "prodSpecCharValue", unit);
              return {
                name: name,
                price:
                  prodSpecCharValue[0]?.billingPeriod !== undefined &&
                  prodSpecCharValue[0]?.billingPeriod !== null
                    ? productType === "Tangible"
                      ? price
                      : price + ` / ${prodSpecCharValue[0]?.billingPeriod}`
                    : price + ` / ${""}`,
                _id: id,
                category: categoryType,
              };
            } else {
              return {
                name: name,
                price:
                  prodSpecCharValue[0]?.billingPeriod !== undefined &&
                  prodSpecCharValue[0]?.billingPeriod !== null
                    ? productType === "Tangible"
                      ? this.currencyFormatter(0)
                      : this.currencyFormatter(0) +
                        ` / ${prodSpecCharValue[0]?.billingPeriod}`
                    : this.currencyFormatter(0) + ` / ${""}`,
                _id: id,
                category: categoryType,
              };
            }
          }
        );
      } catch {
        console.log("error from getsearchedvalue");
      }
    },
    async getcheckedValueNewDiscount(value) {
      this.checkedValue = [];
      console.log("getcheckedValueNewDiscount", value);
      this.checkedValue = value;
    },

    leaf(text) {
      console.log("leaf category text full", text);
      console.log("leaf category text length", text?.length);
      console.log("leaf category text typeof", typeof text);
      let lengthOfText = text?.length;
      if (typeof text === "string") {
        return text;
      }
      if (lengthOfText === 1) {
        console.log("leaf category text length final o/p ", text);
        return (text = text.toString());
      }
      if (text && lengthOfText > 1) {
        const total = text?.length;
        console.log(
          text,
          "leaf category text length final o/p",
          text[total - 1]
        );

        return text[total - 1];
      }
    },
    async supplierFilterData() {
      console.log("kjhfj");
      const supplierFilterRes = await UPCAxiosInstance.post(
        "util/filterLayout",
        this.filterLayout
      );
      // state.suppliersFilterData = supplierFilterRes.data;
      console.log("productRecommed 3.0 supplierfilterdata ", supplierFilterRes);
      console.log("productRecommed 3.0 2", supplierFilterRes?.data);

      return await supplierFilterRes?.data;
    },

    mapname1(val) {
      console.log("mapname1", val);
      this.addressquery = val;
    },

    geopoint(lat, lng) {
      console.log(lat, lng);
      this.lat = lat;
      this.lng = lng;
    },

    emithead(val) {
      this.regionName = val.name;
    },

    async deleteExecRow() {
      this.delete_modal_active = false;

      if (this.selectedSwitch === "Address") {
        //address delete start
        const deleteresponse = await UPCAxiosInstance.delete(
          `/geographicAddress/${this.deleteobj.id}`
        );
        let additionalChargeLogPayload = {
          message: `${this.deleteobj.streetAddressLine[0].characteristic.regionName} Region has been removed`,
          "@type": "serviceQualificationRegion",
          detailedAction: [],
          category: ["serviceQualificationRegion"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          action: "DELETED",
        };
        this.initLog(additionalChargeLogPayload);
        console.log(deleteresponse, "deleteteeee");
        regionObject;
        //address delete start

        this.deleteobj.streetAddressLine[0]?.characteristic?.regionName;
        this.newtwo;

        console.log(
          "new method for delete",
          this.deleteobj.streetAddressLine[0]?.characteristic?.regionName,
          this.newtwo
        );

        //delete in offer start
        const regionObject = this.newtwo.filter((obj) => {
          return (
            obj?.place[0]?.region ===
            this.deleteobj?.streetAddressLine[0]?.characteristic?.regionName
          );
        });
        console.log("regionObject", regionObject);

        regionObject.forEach(async (deleteobj) => {
          this.filterResultData2?.data?.data.forEach((item) => {
            console.log("dts", item.name);
            deleteobj?.productRelationship.forEach(async (dats) => {
              console.log("dats", dats.name);
              if (item.name === dats.name) {
                console.log("item.place", item?.place);
                const r = item.place.filter(
                  (item) => item.region !== deleteobj?.place[0].region
                );
                item.place = r;
                this.place = item;
                const deletework = await UPCAxiosInstance.patch(
                  `/catalogManagement/productOffering`,
                  { ...this.place, id: item?._id }
                );
                console.log("delete_work", deletework, this.place);
              } else {
                console.log("no match found");
              }
            });
          });

          //delete in SQ product
          const deleteall = await UPCAxiosInstance.delete(
            `/productQualification/${deleteobj?.id}`
          );
          console.log("deleteall", deleteall);
        });

        this.ticketdataCalling(this.currPage, this.rowsPerPage);

        if (deleteresponse) {
          this.notification = {
            type: "success",
            message: `Address deleted for Service Qualification successfully!`,
          };
        }
      } else if (this.selectedSwitch === "Products") {
        console.log("weee", this.deleteobj);
        this.filterResultData2?.data?.data.forEach((item) => {
          console.log("dts", item.name);
          this.deleteobj?.productRelationship.forEach(async (dats) => {
            console.log("dats", dats.name);
            if (item.name === dats.name) {
              // item.place
              console.log("item.place", item?.place);

              const r = item.place.filter(
                (item) => item.region !== this.deleteobj?.place[0].region
              );
              item.place = r;

              this.place = item;

              const deletework = await UPCAxiosInstance.patch(
                `/catalogManagement/productOffering`,
                { ...this.place, id: item?._id }
              );

              console.log("delete_work", deletework, this.place);
            } else {
              console.log("no match found");
            }
          });
        });

        const deleteresponse = await UPCAxiosInstance.delete(
          `/productQualification/${this.deleteobj.id}`
        );
        console.log(deleteresponse, "deleteteeee");
        const deletedRegionProductQualificationLogPayload = {
          message: `${this.deleteobj?.place[0]?.region} available region Product has been deleted `,
          detailedAction: this.deleteobj.productRelationship?.map((item) => {
            return {
              action: "Deleted",
              to: item.name,
              property: "Name",
            };
          }),
          "@type": "Offering-media",
          category: ["productOffering", "serviceQualificationProduct"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: this.deleteobj?.productRelationship?.map((item) => item?._id),
          action: "DELETED",
        };
        this.ticketdataCalling(this.currPage, this.rowsPerPage);

        if (deleteresponse) {
          this.notification = {
            type: "success",
            message: `Product deleted for Service Qualification successfully!`,
          };
          this.initLog(deletedRegionProductQualificationLogPayload);
        }
      }
    },
    crudContextMenu($event, index) {
      console.log("vontexmenucliked");
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    closeCrudContextMenu($event, payload, i) {
      if ($event.toLowerCase() === "edit") {
        this.editMode = true;
        const payload = JSON.parse(JSON.stringify(this.tableData[i]));
        this.intialval = JSON.parse(JSON.stringify(this.tableData[i]));
        this.initialServiceQualificationProduct = payload.productRelationship;
        this.openuploaddrawer1(payload, i);
        this.editpayload = payload;
        console.log(this.editpayload, payload, i, "jjjjjjjjjjjjjjjjjjjjjj");
      } else if ($event.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        this.deleteobj = payload;
        console.log("qqqqqqqqqqqqq", payload, this.deleteobj?._id);
        this.delete_name = payload?.name;
      }
    },
    computedTableAction() {
      if (this.permisstionData.delete && this.permisstionData.edit) {
        return [
          // {
          //   label: "Edit",
          //   icon: "icon-file-text-edit-regular",
          // },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      } else if (this.permisstionData.delete) {
        return [
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      } else {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      }
      // return [
      //   {
      //     label: "Edit",
      //     icon: "icon-file-text-edit-regular",
      //   },
      //   {
      //     label: "Delete",
      //     icon: "icon-trash-regular",
      //   },
      // ];
    },

    async fetchData() {
      let response = await UPCAxiosInstance.get(`/geographicAddress`);
      // const response = await UPCAxiosInstance.post(
      //   `util/filterData`,
      //   this.templateFilterQuery
      // );
      console.log(response, "geooooooooooo1");
      // this.newone = await JSON.parse(JSON.stringify(response?.data)).reverse();

      // this.tableData = await JSON.parse(JSON.stringify(response?.data)).reverse();
      const data = await response?.data;
      let region = [];
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (item?.streetAddressLine[0]?.characteristic?.category && item?.streetAddressLine[0]?.characteristic?.category === 'parent') {
          // null
          const small = item?.streetAddressLine[0]?.characteristic?.regionName;
        const polygonpathdata =
          item?.streetAddressLine[0]?.characteristic?.polygonpathdata;
        const radiuscircle1 =
          item?.streetAddressLine[0]?.characteristic?.radiuscircle1;
        const lat = item?.streetAddressLine[0]?.characteristic?.latitude;
        const lng = item?.streetAddressLine[0]?.characteristic?.longitude;
        const geopoint = { lat: lat, lng: lng };
        const regionshape =
          item?.streetAddressLine[0]?.characteristic?.regionshape;

        console.log("check......", polygonpathdata, radiuscircle1, geopoint);

        const obj = {};
        obj.region = small;
        obj.polygonpathdata = polygonpathdata;
        obj.area = radiuscircle1;
        obj.geopoint = geopoint;
        obj.regionshape = regionshape;

        region.push(obj);
        }
        
        
      }
      console.log("region is loading...", region);
      const region2 = Array.from(new Set(region));
      this.dropdown_data_regioncode = [];
      for (let i = 0; i < region2.length; i++) {
        const obj = region2[i];
        this.dropdown_data_regioncode.push(obj);
      }
      console.log(
        "region is loading after duplicate...",
        this.dropdown_data_regioncode
      );
    },

    async editdata(payload) {
      //logic start for avoid duplicate region name
      let booleanCondition = [];
      this.newone.forEach((item) => {
        console.log(
          "item",
          item?.streetAddressLine[0]?.characteristic?.regionName,
          this.regionName,
          payload?.streetAddressLine[0]?.characteristic?.regionName
        );
        if (
          item?.streetAddressLine[0]?.characteristic?.regionName.toLowerCase() ===
          this.regionName.toLowerCase()
        ) {
          console.log("true...");
          booleanCondition.push(true);
        } else {
          console.log("false...");
          booleanCondition.push(false);
        }
      });

      const bools = this.isAnyTrue(booleanCondition);
      console.log("bools...123", bools);
      //logic end

      if (bools) {
        //draweropening value and saving value are equal -region name
        if (
          this.initialRegionName.toLowerCase() ===
          payload?.streetAddressLine[0]?.characteristic?.regionName.toLowerCase()
        ) {
          let patchresponse = await UPCAxiosInstance.patch(
            `/geographicAddress`,
            {
              id: this.editpayload.id,
              ...payload,
            }
          );
          this.closeuploaddrawer();
          this.ticketdataCalling(this.currPage, this.rowsPerPage);
          console.log("patchwork address", patchresponse.data);

          // additionalChargeLogPayload
          if (patchresponse) {
            this.notification = {
              type: "success",
              message: `Address updated for Service Qualification successfully!`,
            };
          }
        }
        //else condition for both are not equal -region name
        else {
          console.log("new region name...");

          console.log("bools..true");
          this.notification = {
            type: "warning",
            message: `This Region name is already existed`,
          };
        }
      } else {
        let patchresponse = await UPCAxiosInstance.patch(`/geographicAddress`, {
          id: this.editpayload.id,
          ...payload,
        });
        this.closeuploaddrawer();
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
        console.log("patchwork address", patchresponse.data);

        if (patchresponse) {
          this.notification = {
            type: "success",
            message: `Address updated for Service Qualification successfully!`,
          };
        }
      }
    },

    async postData(payload) {
      //condition for avoid adding same region name
      let booleanCondition = [];
      this.newone.forEach((item) => {
        console.log(
          "item",
          item?.streetAddressLine[0]?.characteristic?.regionName,
          this.regionName,
          payload?.streetAddressLine[0]?.characteristic?.regionName
        );
        if (
          item?.streetAddressLine[0]?.characteristic?.regionName ===
          this.regionName
        ) {
          console.log("true...");
          booleanCondition.push(true);
        } else {
          console.log("false...");
          booleanCondition.push(false);
        }
      });

      const bools = this.isAnyTrue(booleanCondition);
      console.log("bools...123", bools);

      if (bools) {
        console.log("bools..true");
        this.notification = {
          type: "warning",
          message: `This Region name is already existed`,
        };
      } else {
        console.log("bools..false");
        let postresponse = await UPCAxiosInstance.post(
          `/geographicAddress`,
          payload
        );
        this.closeuploaddrawer();
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
        console.log("postwork", postresponse.data);
        let additionalChargeLogPayload = {
          message: `${payload.streetAddressLine[0].characteristic.regionName} Region has been added`,
          "@type": "serviceQualificationRegion",
          detailedAction: [],
          category: ["serviceQualificationRegion"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          action: "ADDED",
        };

        if (postresponse) {
          this.notification = {
            type: "success",
            message: `Address added for Service Qualification successfully!`,
          };
          this.initLog(additionalChargeLogPayload);
        }
      }
    },

    // currentPage(val) {
    //   this.currPage = val;
    // },

    serialNumberAlter(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    capitalizeFirstLetter(string) {
      return string.replace(/\b\w/g, (match) => match.toUpperCase());
    },
    updateRadioOptions() {
      const options = [
        { name: "Point of interest", checked: false, disabled: false, id: 1 },
        { name: "Radius", checked: false, disabled: false, id: 2 },
        { name: "Square", checked: false, disabled: false, id: 1 },
        { name: "Hexagon", checked: false, disabled: false, id: 2 },
      ];

      this.radioOptions = options.map((option) => {
        if (option.name === this.vradio) {
          return { ...option, checked: true };
        }
        return option;
      });
    },
    async openuploaddrawer1(payload, index) {
      this.editMode = true;
      this.editpayload = payload;
      console.log("calling............", this.selectedSwitch, this.editMode);
      if (this.editMode === true) {
        if (this.selectedSwitch === "Address") {
          this.servicestatus =
            payload?.streetAddressLine[0]?.characteristic?.servicestatus;

          console.log(payload, index, "openuploaddrawer1...........");
          this.characteristic.country = payload?.country;
          this.characteristicpreval.characteristic.postalCode =
            payload?.postalCode;
          this.characteristic.postalCode = payload?.postalCode;
          this.characteristic.city = payload?.city;
          this.characteristicpreval = payload?.streetAddressLine[0];
          this.regionName =
            payload?.streetAddressLine[0]?.characteristic?.regionName;
          this.initialRegionName =
            payload?.streetAddressLine[0]?.characteristic?.regionName;
          this.lat = payload?.streetAddressLine[0]?.characteristic?.latitude;
          this.lng = payload?.streetAddressLine[0]?.characteristic?.longitude;
          this.vradio =
            payload?.streetAddressLine[0]?.characteristic?.regionshape;
          this.rangeName =
            payload?.streetAddressLine[0]?.characteristic?.rangeName;

          if (this.vradio) {
            console.log("radius neww option", this.radioOptions);
            this.updateRadioOptions();
          }

          this.radiuscircle =
            payload?.streetAddressLine[0]?.characteristic?.radius;

          this.characteristic.street =
            payload?.streetAddressLine[0]?.characteristic?.street;
          this.characteristic.unit =
            payload?.streetAddressLine[0]?.characteristic?.unit;
          this.characteristic.state =
            payload?.streetAddressLine[0]?.characteristic?.state;
          this.propertytype =
            payload?.streetAddressLine[0]?.characteristic?.propertytype;
          this.addressquery =
            payload?.streetAddressLine[0]?.characteristic?.addressquery;

          this.characteristic.description =
            payload?.streetAddressLine[0]?.characteristic?.description;

          this.show_upload_drawer = true;
        } else if (this.selectedSwitch === "Products") {
          this.initialCheckedValueOffer = payload?.productRelationship;
          this.initialRegioncode = payload?.place[0].region;
          payload?.productRelationship?.forEach((checkedItem) => {
            const matchingItem = this.mainInfoServiceQualification?.find(
              (item) => item?._id === checkedItem?._id
            );
            if (matchingItem) {
              matchingItem.checked = true;
            }
          });
          console.log(
            this.mainInfoServiceQualification,
            "this.mainInfoServiceQualification"
          );
          this.getoffer();
          console.log(payload, index, "openuploaddrawer1...........");
          this.addresscomponentkey += 1;
          this.regioncode = payload?.place[0]?.region;
          this.radiuscircle1 = payload?.place[1]?.area;
          this.polygonpathdata = payload?.place[2]?.polygonpathdata;
          this.lat = payload?.place[3]?.lat;
          this.lng = payload?.place[3]?.lng;
          this.vradio = payload?.place[4]?.regionshape;

          this.productspecification = payload?.productSpecification?.name;
          this.formattedObjects = payload?.contextCharacteristic;
          // payload.productRelationship;
          payload?.productRelationship?.forEach((element) => {
            this.specPreValue.push(element?.name);
            this.emitoffpost.push(element);
          });
          this.dropdown_data_productoffer = this.filterResultData2?.data?.data;

          this.show_upload_drawer = true;
        }
      }
    },
    async openuploaddrawer() {
      if (this.editMode == false) {
        this.servicestatus = "Pending";
      }
      this.rangeName = "Meter";
      this.addresscomponentkey += 1;
      this.vradio = "Point of interest";
      this.show_upload_drawer = true;
      this.getoffer();
      this.mainInfoServiceQualification.forEach((val) => {
        val.checked = false;
      });
    },
    async closeuploaddrawer() {
      this.initialRegioncode = "";
      this.initialCheckedValueOffer = [];

      this.initialRegionName = "";
      this.checkofferinput = "";
      this.editpayload = {};
      this.newoffers = [];

      this.mainInfoServiceQualification.forEach((val) => {
        val.checked = false;
      });

      (this.radioOptions = [
        { name: "Point of interest", checked: true, disabled: false, id: 1 },
        { name: "Radius", checked: false, disabled: false, id: 2 },
        { name: "Square", checked: false, disabled: false, id: 1 },
        { name: "Hexagon", checked: false, disabled: false, id: 2 },
      ]),
        (this.servicestatus = "");

      this.check_modal_active = false;

      this.rangeName = "";
      this.show_upload_drawer = false;
      this.editMode = false;
      this.Mapcomponentkey += 1;
      this.componentkey += 1;
      this.addresscomponentkey += 1;
      this.characteristic.street = "";
      this.characteristic.unit = "";
      this.characteristic.city = "";
      this.characteristic.state = "";
      this.characteristic.country = "";
      this.characteristic.postalCode = "";
      this.vradio = "";
      this.regionName = "";
      this.lat = "";
      this.lng = "";
      this.radiuscircle = "";
      this.regionName = "";
      this.lat = "";
      this.lng = "";
      this.propertytype = "";
      this.addressquery = "";
      this.regioncode = "";
      this.formattedObjects = {};
      this.emitedoffer = [];
      this.emitoffpost = [];
      this.productspecification = "";
      this.specPreValue = [];
      this.configarray = {};
      this.addresscomponentkey += 1;
      this.place = {};

      this.characteristic.description = "";

      this.characteristicpreval.characteristic["@type"] = "";
      this.characteristicpreval.characteristic.street = "";
      this.characteristicpreval.characteristic.city = "";
      this.characteristicpreval.characteristic.country = "";
      this.characteristicpreval.characteristic.default = false;

      this.characteristicpreval.characteristic.postalCode = "";
      this.characteristicpreval.characteristic.state = "";
      this.characteristicpreval.characteristic.stateOrProvince = "";
      this.characteristicpreval.characteristic.street1 = "";
      this.characteristicpreval.characteristic.street2 = "";
      this.characteristicpreval.characteristic.unit = "";
      this.emittedValues = {};

      // if (this.selectedSwitch.toLowerCase() === "address") {
      //   let response = await UPCAxiosInstance.get(`/geographicAddress`);

      //   this.tableData = await JSON.parse(JSON.stringify(response?.data)).reverse();
      // } else if (this.selectedSwitch.toLowerCase() === "products") {
      //   let response = await UPCAxiosInstance.get(`/productQualification`);
      //   this.tableData = await JSON.parse(JSON.stringify(response?.data)).reverse();
      // }

      this.ticketdataCalling(this.currPage, this.rowsPerPage);

      // this.fetchData();
    },

    receivedAddressData(val) {
      console.log(val, "----------------address----------------");
      this.characteristicpreval = val;
      this.characteristic = val?.characteristic;
      this.mediumType = val?.mediumType;
      console.log(
        this.characteristic,
        "----------------address----------------"
      );
    },

    addaddress() {
      let latitude = { latitude: this.lat };
      let longitude = { longitude: this.lng };
      let radius = { radius: this.radiuscircle };
      let regionName = { regionName: this.regionName };
      let regionshape = { regionshape: this.vradio };
      let propertytype = { propertytype: this.propertytype };
      let addressquery = { addressquery: this.addressquery };
      let polygonpathdata = { polygonpathdata: this.polygonpathdata };
      let radiuscircle1 = { radiuscircle1: this.radiuscircle1 };
      let rangeName = { rangeName: this.rangeName };

      let addresssdescription = {
        description: this.characteristic?.description,
      };
      let servicestatus = { servicestatus: this.servicestatus };

      let characteristicData = {
        ...this.characteristic,
        ...latitude,
        ...longitude,
        ...radius,
        ...regionName,
        ...regionshape,
        ...propertytype,
        ...addressquery,
        ...addresssdescription,
        ...servicestatus,
        ...polygonpathdata,
        ...radiuscircle1,
        ...rangeName,
      };
      const obj = {
        country: this.characteristic?.country,

        postalCode: this.characteristic?.postalCode,

        city: this.characteristic?.city,

        latitude: this.lat,
        longitude: this.lng,

        streetAddressLine: [
          {
            mediumType: "geographicAddress",
            characteristic: characteristicData,
          },
        ],
      };
      this.postData1 = obj;
      if (this.editMode === false) {
        this.postData(this.postData1);
      } else {
        this.editdata(this.postData1);
        let payload = this.postData1;
        let additionalChargeLogPayload = {
          message: `${payload.streetAddressLine[0].characteristic.regionName} Region has been updated`,
          "@type": "serviceQualificationRegion",
          detailedAction: [],
          category: ["serviceQualificationRegion"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          action: "UPDATED",
        }; // Status log
        let statusActionLog = this.buildBasicLogByDifferences(
          this.intialval.streetAddressLine[0].characteristic,
          payload.streetAddressLine[0].characteristic,
          "servicestatus",
          "Status"
        );
        console.log(statusActionLog, "descriptionActionLog");

        let regionNameActionLog = this.buildBasicLogByDifferences(
          this.intialval.streetAddressLine[0].characteristic,
          payload.streetAddressLine[0].characteristic,
          "regionName",
          "regionName"
        );
        console.log(regionNameActionLog, "descriptionActionLog");

        let regionshapeActionLog = this.buildBasicLogByDifferences(
          this.intialval.streetAddressLine[0].characteristic,
          payload.streetAddressLine[0].characteristic,
          "regionshape",
          "regionshape"
        );
        console.log(regionshapeActionLog, "descriptionActionLog");

        let latitudeActionLog = this.buildBasicLogByDifferences(
          this.intialval.streetAddressLine[0].characteristic,
          payload.streetAddressLine[0].characteristic,
          "latitude",
          "latitude"
        );
        console.log(latitudeActionLog, "descriptionActionLog");

        let longitudeActionLog = this.buildBasicLogByDifferences(
          this.intialval.streetAddressLine[0].characteristic,
          payload.streetAddressLine[0].characteristic,
          "longitude",
          "longitude"
        );
        console.log(longitudeActionLog, "descriptionActionLog");

        let addressActionLog = this.buildBasicLogByDifferences(
          this.intialval.streetAddressLine[0].characteristic,
          payload.streetAddressLine[0].characteristic,
          "description",
          "Address"
        );
        console.log(addressActionLog, "descriptionActionLog");

        let rangeNameActionLog = this.buildBasicLogByDifferences(
          this.intialval.streetAddressLine[0].characteristic,
          payload.streetAddressLine[0].characteristic,
          "rangeName",
          "rangeName"
        );
        console.log(rangeNameActionLog, "descriptionActionLog");

        let radiusActionLog = this.buildBasicLogByDifferences(
          this.intialval.streetAddressLine[0].characteristic,
          payload.streetAddressLine[0].characteristic,
          "radius",
          "radius"
        );
        console.log(radiusActionLog, "descriptionActionLog");

        additionalChargeLogPayload.detailedAction =
          additionalChargeLogPayload.detailedAction.concat(
            statusActionLog,
            regionNameActionLog,
            regionshapeActionLog,
            latitudeActionLog,
            longitudeActionLog,
            addressActionLog,
            rangeNameActionLog,
            radiusActionLog
          );
        this.initLog(additionalChargeLogPayload);
      }
      console.log(this.postData1, "check missing");
      console.log(this.tableData1, "------loggggger-------------------");
      // this.closeuploaddrawer();
    },

    // for product.......

    // Function to filter offers based on the config array
    filterOffers(offers, config, spec) {
      if (offers?.productSpecification?.name === spec)
        return config.every((conf) => {
          return offers?.prodSpecCharValueUse.some((characteristic) => {
            // Check if the characteristic name matches the config name
            if (characteristic?.name === conf?.name) {
              // Check if any productSpecCharacteristicValue matches the config value
              return characteristic?.productSpecCharacteristicValue.some(
                (specValue) =>
                  // {
                  // if (
                  specValue?.value === conf?.value
                // ) {
                //   this.newOfferArray.push(offer)
                // }
                // }
              );
            }
            return false;
          });
        });
      // });
    },

    hasFormattedObjects(charValueUse, formattedObjects) {
      const formattedNames = formattedObjects.map((obj) => obj?.name);

      return charValueUse.some((use) => formattedNames.includes(use.name));
    },

    offeremit(val) {
      console.log(val, "offer emit oooooooooooooooooooooooo");
      this.emitedoffer = val;
      this.emitoffpost = [];
      for (let i = 0; i < this.emitedoffer.length; i++) {
        const e = this.emitedoffer[i];
        this.emitoffpost.push({ name: e.name });
      }
      console.log("this.emitoffpost", this.emitoffpost);
    },

    async emitconfig(emittedValue) {
      console.log(emittedValue, "emit config ccccccccccccccccccccccccccc");
      const { name, value } = emittedValue;

      // Check if the name already exists in emittedValues
      if (!this.emittedValues[name]) {
        // If not, initialize with an array containing the value
        this.emittedValues[name] = value;
      } else {
        // If exists, push the value to the existing array
        this.emittedValues[name] = value;
      }
      console.log(this.emittedValues, "this.emittedValues");
    },

    async emitspec(spec) {
      console.log("specification emit", spec);
      this.configarray = await spec;
      this.emittedValues = {};
    },

    async getoffer() {
      this.filterResultData2 = await UPCAxiosInstance.post(
        `util/filterData`,
        this.offerfilterquery
      );
      const d = await this.filterResultData2?.data?.data;

      this.dropdown_data_productoffer = await d;
      console.log(
        this.dropdown_data_productoffer,
        "this.dropdown_data_productoffer"
      );

      console.log("offer data get for drop down", this.filterResultData2);
    },

    compareData(initialRegion, initialOffers, recentRegion, recentOffers) {
      const commands = [];

      // Compare regions
      if (initialRegion !== recentRegion) {
        commands.push(
          `Change region from "${initialRegion}" to "${recentRegion}"`
        );
      }

      // Compare offers (add/remove offers)
      const initialOfferIds = new Set(initialOffers.map((offer) => offer._id));
      const recentOfferIds = new Set(recentOffers.map((offer) => offer._id));

      // Offers to add
      recentOffers.forEach((offer) => {
        if (!initialOfferIds.has(offer._id)) {
          commands.push(`Add offer: "${offer.name}"`);
        }
      });

      // Offers to remove
      initialOffers.forEach((offer) => {
        if (!recentOfferIds.has(offer._id)) {
          commands.push(`Remove offer: "${offer.name}"`);
        }
      });

      return commands;
    },

    evaluateChanges(data) {
      // Check if there is a "Change region" action in the data
      const hasChangeRegion = data.some((item) =>
        item.startsWith("Change region")
      );

      // Check if there are any other changes besides "Change region"
      const hasOtherChanges = data.some(
        (item) =>
          item.startsWith("Add offer") || item.startsWith("Remove offer")
      );

      // Determine the return value based on the conditions
      if (hasChangeRegion && !hasOtherChanges) {
        return "region only"; // Only region change present
      } else if (!hasChangeRegion) {
        return "not region only"; // No region change, other changes present
      } else if (hasChangeRegion && hasOtherChanges) {
        return "all"; // Region change plus other changes present
      }
    },

    async addproduct() {
      const obj = {};
      obj.place = [
        { region: this.regioncode },
        { area: this.radiuscircle1 },
        { polygonpathdata: this.polygonpathdata },
        { lat: this.lat, lng: this.lng },
        { regionshape: this.vradio },
      ];
      obj.productSpecification = { name: this.configarray?.name };
      obj.contextCharacteristic = this.formattedObjects;
      obj.productRelationship = this.checkedValue;

      console.log("obj....", obj);

      this.serviceProduct = obj;
      if (this.editMode === false) {
        const addedRegionProductsLogPayload = {
          message: `${this.regioncode} available region Product has been added`,
          detailedAction: obj.productRelationship?.map((item) => {
            return {
              action: "ADDED",
              to: item.name,
              property: "Product qualification",
            };
          }),
          "@type": "Offering-available region",
          category: ["productOffering", "serviceQualificationProduct"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: obj.productRelationship?.map((item) => item?._id),
          action: "ADDED",
        };

        console.log(
          addedRegionProductsLogPayload.detailedAction,
          "addedRegionProductsLogPayload.detailedAction"
        );

        //checking for duplicate to restrict adding product in SQ
        const finalValue = [];
        for (let i = 0; i < this.newtwo?.length; i++) {
          const element = this.newtwo[i];
          console.log(element);
          element.place[0].region;

          const reg = element.place[0].region === this.regioncode;
          console.log(reg, "reg");

          const productName = this.hasMatchingNames(
            this.checkedValue,
            element?.productRelationship
          );
          console.log(productName, "productName");

          finalValue.push(reg === true && productName === true);
        }

        console.log(this.isAnyTrue(finalValue));

        if (this.isAnyTrue(finalValue) === true) {
          console.log("no match found");
          this.notification = {
            type: "warning",
            message: `This production is already existed for the region`,
          };
        } else {
          this.postProduct(this.serviceProduct);
          console.log("match found");

          //adding in offer start

          //iterating the common offer
          this.filterResultData2?.data?.data.forEach((item) => {
            this.checkedValue.forEach(async (datas) => {
              if (item.name === datas.name) {
                console.log(item, item.place, this.formattedObjects, "lllll00");
                const obj = {
                  region: this.regioncode,
                };
                item.place.push(obj);
                console.log("place added", item);
                this.place = item;

                const postpatch = await UPCAxiosInstance.patch(
                  `/catalogManagement/productOffering`,
                  { ...this.place, id: item?._id }
                );

                console.log("post_work", postpatch, this.place);
              }
            });
          });
          //adding in offer end

          this.closeuploaddrawer();
        }

        console.log("finalValue", finalValue);

        this.initLog(addedRegionProductsLogPayload);
      } else {
        console.log(
          "🚀 ~ addproduct ~ this.initialServiceQualificationProduct,this.serviceProduct,:",
          this.initialServiceQualificationProduct,
          this.serviceProduct
        );
        const productQualificationDetailedLog =
          this.buildLogBasedOnDifferencesForArrayOfObjects(
            this.initialServiceQualificationProduct,
            obj.productRelationship,
            "_id",
            "product qualification"
          );
        console.log(
          "🚀 ~ addproduct ~ productQualificationDetailedLog:",
          productQualificationDetailedLog
        );
        // if(this.serviceProduct) return
        const updatedProductQualificationLogPayload = {
          message: `${this.regioncode} available region Product has been updated`,
          detailedAction: productQualificationDetailedLog,
          "@type": "Offering-available region",
          category: ["productOffering", "serviceQualificationProduct"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: this.initialServiceQualificationProduct?.map(
            (item) => item?._id
          ),
          action: "UPDATED",
        };

        //checking for duplicate to restrict editing product in SQ
        const finalValue = [];
        for (let i = 0; i < this.newtwo?.length; i++) {
          const element = this.newtwo[i];
          console.log(element);
          element.place[0].region;

          const reg = element.place[0].region === this.regioncode;
          console.log(reg, "reg");

          const productName = this.hasMatchingNames(
            this.checkedValue,
            element?.productRelationship
          );
          console.log(productName, "productName");

          finalValue.push(reg === true && productName === true);
        }

        console.log(this.isAnyTrue(finalValue));

        if (this.isAnyTrue(finalValue) === true) {
          console.log("no match found");
          this.notification = {
            type: "warning",
            message: `This production is already existed for the region`,
          };
        } else {
          this.patchProduct(this.serviceProduct);
          console.log(
            "match found",
            this.serviceProduct.place[0].region,
            this.serviceProduct.productRelationship,
            this.initialRegioncode,
            this.initialCheckedValueOffer
          );

          console.log(
            "this.compareData...",
            this.compareData(
              this.initialRegioncode,
              this.initialCheckedValueOffer,
              this.serviceProduct.place[0].region,
              this.serviceProduct.productRelationship
            )
          );

          //show comands
          const OPcomand = this.compareData(
            this.initialRegioncode,
            this.initialCheckedValueOffer,
            this.serviceProduct.place[0].region,
            this.serviceProduct.productRelationship
          );
          console.log("OPcomand...", OPcomand);

          const resultComand = this.evaluateChanges(OPcomand);
          console.log("resultComand...", resultComand);

          this.filterResultData2?.data?.data;
          this.initialRegioncode;
          this.initialCheckedValueOffer;
          this.serviceProduct.place[0].region;
          this.serviceProduct.productRelationship;

          //region only
          if (resultComand === "region only") {
            console.log(resultComand);
            this.filterResultData2?.data?.data.forEach((singleOffer) => {
              singleOffer;
              this.serviceProduct.productRelationship.forEach(async (item) => {
                if (item.name === singleOffer.name) {
                  const offerPlaceReplace = singleOffer.place.map((regn) => {
                    if (regn.region === this.initialRegioncode) {
                      console.log("ro old regn...", regn);
                      regn.region = this.serviceProduct.place[0].region;
                      console.log("ro new regn...", regn);
                      return regn;
                    } else {
                      return regn;
                    }
                  });
                  console.log("ro offerPlaceReplace...", offerPlaceReplace);
                  singleOffer.place = offerPlaceReplace;
                  const postpatch = await UPCAxiosInstance.patch(
                    `/catalogManagement/productOffering`,
                    { ...singleOffer, id: singleOffer?._id }
                  );
                  console.log(
                    "ro postpatch...",
                    postpatch,
                    singleOffer.place,
                    singleOffer
                  );
                }
              });
            });

            this.filterResultData2?.data?.data;
            this.initialRegioncode;
            this.initialCheckedValueOffer;
            this.serviceProduct.place[0].region;
            this.serviceProduct.productRelationship;

            //not region only
          } else if (resultComand === "not region only") {
            console.log(resultComand);
            this.filterResultData2?.data?.data.forEach((singleOffer) => {
              singleOffer;
              // to remove all
              this.initialCheckedValueOffer.forEach(async (item) => {
                if (item.name === singleOffer.name) {
                  console.log("nro singleOffer.place...", singleOffer.place);

                  const offerPlaceReplace = singleOffer.place.filter(
                    (regn) =>
                      regn.region !== this.serviceProduct.place[0].region
                  );
                  console.log("nro offerPlaceReplace...", offerPlaceReplace);

                  singleOffer.place = offerPlaceReplace;
                  const postpatch = await UPCAxiosInstance.patch(
                    `/catalogManagement/productOffering`,
                    { ...singleOffer, id: singleOffer?._id }
                  );
                  console.log(
                    "nro postpatch...",
                    postpatch,
                    singleOffer.place,
                    singleOffer
                  );
                }
              });
              // re-add all
              this.serviceProduct.productRelationship.forEach(async (item) => {
                if (item.name === singleOffer.name) {
                  const obj = { region: this.serviceProduct.place[0].region };
                  singleOffer.place.push(obj);
                  const postpatch = await UPCAxiosInstance.patch(
                    `/catalogManagement/productOffering`,
                    { ...singleOffer, id: singleOffer?._id }
                  );
                  console.log(
                    "nro postpatch...",
                    postpatch,
                    singleOffer.place,
                    singleOffer
                  );
                }
              });
            });

            this.filterResultData2?.data?.data;
            this.initialRegioncode;
            this.initialCheckedValueOffer;
            this.serviceProduct.place[0].region;
            this.serviceProduct.productRelationship;
            //all change
          } else if (resultComand === "all") {
            console.log(resultComand);
            this.filterResultData2?.data?.data.forEach((singleOffer) => {
              singleOffer;
              this.initialCheckedValueOffer.forEach(async (item) => {
                if (item.name === singleOffer.name) {
                  console.log("all singleOffer.place...", singleOffer.place);

                  const offerPlaceReplace = singleOffer.place.filter(
                    (regn) => regn.region !== this.initialRegioncode
                  );
                  console.log("all offerPlaceReplace...", offerPlaceReplace);

                  singleOffer.place = offerPlaceReplace;
                  const postpatch = await UPCAxiosInstance.patch(
                    `/catalogManagement/productOffering`,
                    { ...singleOffer, id: singleOffer?._id }
                  );
                  console.log(
                    "all postpatch...",
                    postpatch,
                    singleOffer.place,
                    singleOffer
                  );
                }
              });

              this.serviceProduct.productRelationship.forEach(async (item) => {
                if (item.name === singleOffer.name) {
                  const obj = { region: this.serviceProduct.place[0].region };
                  singleOffer.place.push(obj);
                  const postpatch = await UPCAxiosInstance.patch(
                    `/catalogManagement/productOffering`,
                    { ...singleOffer, id: singleOffer?._id }
                  );
                  console.log(
                    "all postpatch...",
                    postpatch,
                    singleOffer.place,
                    singleOffer
                  );
                }
              });
            });
          } else {
            // console.log(resultComand);
            this.filterResultData2?.data?.data.forEach((singleOffer) => {
              singleOffer;
              this.initialCheckedValueOffer.forEach(async (item) => {
                if (item.name === singleOffer.name) {
                  console.log("all singleOffer.place...", singleOffer.place);

                  const offerPlaceReplace = singleOffer.place.filter(
                    (regn) => regn.region !== this.initialRegioncode
                  );
                  console.log("all offerPlaceReplace...", offerPlaceReplace);

                  singleOffer.place = offerPlaceReplace;
                  const postpatch = await UPCAxiosInstance.patch(
                    `/catalogManagement/productOffering`,
                    { ...singleOffer, id: singleOffer?._id }
                  );
                  console.log(
                    "all remove postpatch...",
                    postpatch,
                    singleOffer.place,
                    singleOffer
                  );
                }
              });

              this.serviceProduct.productRelationship.forEach(async (item) => {
                if (item.name === singleOffer.name) {
                  const obj = { region: this.serviceProduct.place[0].region };
                  singleOffer.place.push(obj);
                  const postpatch = await UPCAxiosInstance.patch(
                    `/catalogManagement/productOffering`,
                    { ...singleOffer, id: singleOffer?._id }
                  );
                  console.log(
                    "all add  postpatch...",
                    postpatch,
                    singleOffer.place,
                    singleOffer
                  );
                }
              });
            });
          }

          this.closeuploaddrawer();
        }

        console.log("finalValue", finalValue);

        this.initLog(updatedProductQualificationLogPayload);
      }

      console.log(
        "offer emited from filter",
        this.emitoffpost,
        obj,
        this.checkedValue
      );

      console.log(this.tableData2, obj, "tableData2");
    },

    hasMatchingNames(array1, array2) {
      if (array1.length !== array2.length) {
        return false;
      }

      const names1 = array1.map((item) => item.name).sort();
      const names2 = array2.map((item) => item.name).sort();

      for (let i = 0; i < names1.length; i++) {
        if (names1[i] !== names2[i]) {
          return false;
        }
      }

      return true;
    },

    // check true in array of boolean
    isAnyTrue(array) {
      return array.includes(true);
    },

    removeItem(array, region) {
      return array.filter((element) => element !== region);
    },
    // async getproductQualification() {
    //   const response = await UPCAxiosInstance.get(`/productQualification`);
    //   console.log(response.data, "get service offer");
    //   this.newtwo = await response?.data;
    //   this.tableData2 = await JSON.parse(JSON.stringify(response?.data)).reverse();
    //   this.tableData = await JSON.parse(JSON.stringify(response?.data)).reverse();
    // },

    async postProduct(val) {
      const offerpost = await UPCAxiosInstance.post(
        `productQualification`,
        val
      );
      console.log("post offer", offerpost.data);

      this.ticketdataCalling(this.currPage, this.rowsPerPage);

      if (offerpost) {
        this.notification = {
          type: "success",
          message: `Product added for Service Qualification successfully!`,
        };
      }
    },

    async patchProduct(val) {
      const patchresponse = await UPCAxiosInstance.patch(
        `/productQualification`,
        {
          id: this.editpayload?.id,
          ...val,
        }
      );
      this.closeuploaddrawer();

      this.ticketdataCalling(this.currPage, this.rowsPerPage);

      console.log("patchwork product", patchresponse.data);

      if (patchresponse) {
        this.notification = {
          type: "success",
          message: `Product updated for Service Qualification successfully!`,
        };
      }
    },

    handleDragEnd(event) {
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.lat = newPosition.lat;
      this.lng = newPosition.lng;
    },
    emitRadio(val) {
      this.Mapcomponentkey += 1;
      console.log(val.name, "shapeeeeeeeeeeeeeeeeeeeee");
      this.vradio = val.name;
      this.clearDrawerData();
    },

    clearDrawerData() {
      this.radiuscircle = 0;
      this.radiuscircle1 = 0;
      this.rangeName = "Meter";

      (this.characteristic.description = ""), (this.characteristic.street = "");
      this.characteristic.unit = "";
      this.characteristic.city = "";
      this.characteristic.state = "";
      this.characteristic.country = "";
      this.characteristic.postalCode = "";

      this.lat = "";
      this.lng = "";

      this.characteristicpreval.characteristic["@type"] = "";
      this.characteristicpreval.characteristic.street = "";
      this.characteristicpreval.characteristic.city = "";
      this.characteristicpreval.characteristic.country = "";
      this.characteristicpreval.characteristic.default = false;
      this.characteristicpreval.characteristic.description = "";
      this.characteristicpreval.characteristic.postalCode = "";
      this.characteristicpreval.characteristic.state = "";
      this.characteristicpreval.characteristic.stateOrProvince = "";
      this.characteristicpreval.characteristic.street1 = "";
      this.characteristicpreval.characteristic.street2 = "";
      this.characteristicpreval.characteristic.unit = "";
    },
  },
};
</script>

<style lang="scss">
.drawerbodytop {
  display: flex;
  //justify-content: center;
  align-items: center;
  gap: 18px;
  flex-direction: column;
  // height: 264px;
  //margin-top: 15px;
}
.miniInput {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 13px;
  margin: 15px 2px;
}
.latitudelongitude {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  margin: 14px 7px;
}
.greyline {
  background: #d9d9d966;
  display: flex;
  flex-direction: row;
  font-family: "Opensans";
  justify-content: space-around;
  font-weight: 400;
  font-size: 12px;
  height: 28px;
  align-items: center;
}
.addaccesstype {
  color: #54bd95;
  margin: 3px 10px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Opensans";
  cursor: pointer;
}
.selectrowbody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 2px;
}
.drawercrudtext {
  font-family: "Opensans";
  font-weight: 400;
  font-size: 12px;
  width: 145px;
}
.availableoffercolumn {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mapbox {
  margin: 11px 1px;
}
.divflexrow {
  display: flex;
  flex-direction: row;
  background: red;
  gap: 2px;
  margin-bottom: 5px;
}
.divflexrow2 {
  display: flex;
  flex-direction: row;
  background: red;
  gap: 2px;
}
.divflexcolumn {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: none;
  margin-bottom: 0px;
}
.styleforcard {
  width: 250px;
  height: 250px;
  background: none;
}
</style>
