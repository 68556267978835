<template>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <div
    v-if="contractData?.length >= 1"
    class="header-add-supplier"
  >
    <div class="tablehead-add-supplier">{{}}</div>
    <div class="search-add-supplier">
      <span
        class="equipmentnetwork-id-section"
        style="position: relative"
      >
        <FieldRenderer
          :schema-payload="fieldRenderSchema"
          :parent-class-name="'parent-container-for-lead-overview'"
          :layout="'1-column'"
        ></FieldRenderer>
      </span>
    </div>
  </div>
  <div>
    <div
      v-if="tableData.length > 0"
      style="height: 78vh; overflow: auto"
      class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
    >
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header', 'horizontal']"
        :row-hover="true"
        theme="grey"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="accounts_table_theads_cc"
        @close-rearrange="re_arrange = false"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
            @new-resize-width="resizedWidth"
            >{{ 'S.no' }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
            @new-resize-width="resizedWidth"
          >
            {{ i.label }}</hlx-table-head
          >
          <hlx-table-head
            v-show="supplierStatus == false"
            :align="'center'"
            :width="70"
            :fixed="'right'"
            @new-resize-width="resizedWidth"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr
            v-for="(i, index) in paginatedData"
            id=""
            :key="index"
          >
            <hlx-table-cell
              :align="'center'"
              :fixed="'left'"
            >
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in accounts_table_theads"
              :key="col_index"
              :align="j.align"
              :fixed="j.fixed"
            >
              <div v-if="j.prop === 'siteDetails'">
                <div style="display: flex; flex-direction: column">
                  <span>{{ i[j.prop] }}</span>
                  <span
                    style="
                      font-size: 10px;
                      font-weight: 400;
                      line-height: 13.62px;
                      /* text-align: center; */
                      text-underline-position: from-font;
                      text-decoration-skip-ink: none;
                      color: rgba(128, 128, 128, 1);
                    "
                    >{{ i.address }}</span
                  >
                </div>
              </div>

              <div v-if="j.prop === 'approvar'">
                <span style="display: flex; gap: 6px">
                  <span>{{ i[j.prop] }}</span>
                  <span
                    ><hlx-label class="primary sm">{{
                      i.role
                    }}</hlx-label></span
                  >
                </span>
              </div>
              <div v-else-if="j.prop === 'status'">
                <div>
                  <hlx-status :type="i[j.prop]"></hlx-status>
                </div>
              </div>
              <div
                v-else-if="j.prop === 'comments'"
                style="margin: 0.5rem 0"
              >
                <span v-if="i[j.prop]"> {{ i[j.prop] }}</span>
                <span v-else> - </span>
              </div>
              <div
                v-else-if="j.prop === 'lastUpdated'"
                style="margin: 0.5rem 0"
              >
                {{ i[j.prop] }}
              </div>
              <!-- {{ dateAndTimeFormatter(i[j.prop]) }} -->

              <!-- <div v-else>{{ i[j.prop] }}</div> -->
            </hlx-table-cell>
            <hlx-table-cell
              v-show="supplierStatus == false"
              :align="'center'"
              :width="120"
              :fixed="'right'"
            >
              <span
                :id="'table-context' + serialNumber(index - 1)"
                class="action-icon"
              >
                <p
                  class="bg-default border"
                  data-tooltip="View rule"
                  data-tooltip-location="left"
                >
                  <i
                    style="position: relative; color: rgba(0, 101, 178, 1)"
                    class="icon-arrow-export-regular"
                  >
                  </i>
                </p>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>

    <div
      v-if="tableData.length === 0 && searchvalue !== ''"
      style="height: 78vh; overflow: auto"
      class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
    >
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header']"
        :row-hover="true"
        theme="grey"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="accounts_table_theads_cc"
        @close-rearrange="re_arrange = false"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
            @new-resize-width="resizedWidth"
            >{{ 'S.no' }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
            @new-resize-width="resizedWidth"
          >
            {{ i.label }}</hlx-table-head
          >
          <hlx-table-head
            v-show="supplierStatus == false"
            :align="'center'"
            :width="70"
            :fixed="'right'"
            @new-resize-width="resizedWidth"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell :colspan="accounts_table_theads.length + 2">{{
              'No data'
            }}</hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>

    <div
      v-if="contractData?.length <= 0"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 70vh;
      "
    >
      <!-- <img src="@/assets/images/suppliercontract.svg" alt="" /> -->
    </div>
  </div>
  <div
    v-if="tableData?.length > 10"
    class="cm-accounts-right-panel-pagination-container"
  >
    <hlx-pagination
      v-model:rows-per-page="externalRowsPerPage"
      :total="tableData?.length"
      rows-per-page
      enable-rows-per-page
      :rows-per-page-list="[10, 15, 20]"
      :options="{
        attributes: {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 0,
              right: 1,
            },
          },
        },
      }"
      @current-page="currentPage"
      @updated:rows-per-page="changeRowsPerPage"
    ></hlx-pagination>
  </div>
</template>
<script>
import {
  UPCAxiosInstance,
  DMNAxiosInstance,
  MLTAxiosInstance,
} from '@/config/axiosConfig';
import FieldRenderer from '@/components/FieldRenderer.vue';

export default {
  components: {
    FieldRenderer,
    // accordionSite
  },
  props: {
    fieldRenderSchema: {
      type: Object,
      required: true,
      default: () => {},
    },
    permisstionData: {
      type: Object,
      required: true,
      default: () => {},
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: 'edit',
    },
    supplierStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHeaderOrg: [],
      tableHeaderOrgcc: [],
      searchvalue: '',
      deleteobj: {},
      isModal: false,
      payload: {
        name: '',
        type: '',
        attachment: [
          {
            name: '',
          },
        ],
        documentNumber: '',
        status: 'Draft',
        characteristic: [],
        validFor: {
          startDateTime: new Date(),
          endDateTime: '',
          autoRenewal: false,
        },
      },
      contracttype: [
        { name: 'Service Agreement' },
        { name: 'Purchase Agreement' },
        { name: ' Non-Disclosure Agreement (NDA)' },
        { name: 'Partnership Agreement' },
      ],
      editMode: false,
      statusList: [],
      tablehead: 'Approval',
      externalRowsPerPage: 10,
      tableData: [],
      notification: [],
      accounts_table_theads: [
        {
          sortable: false,
          resizable: true,
          width: 250,
          label: 'Site details',
          prop: 'siteDetails',
          align: 'left',
          name: 'Site details',
          checked: true,
          disabled: true,
        },
        {
          sortable: false,
          resizable: true,
          width: 100,
          label: 'Status',
          prop: 'status',
          align: 'left',
          name: 'Status',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          label: 'Approvar',
          prop: 'approvar',
          align: 'left',
          name: 'Approvar',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: 'Comments',
          prop: 'comments',
          align: 'left',
          name: 'Comments',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: 'Last updated',
          prop: 'lastUpdated',
          align: 'left',
          name: 'Last updated',
          checked: true,
          disabled: false,
        },
      ],
      accounts_table_theads_cc: [
        {
          sortable: false,
          resizable: true,
          width: 250,
          label: 'Site details',
          prop: 'siteDetails',
          align: 'left',
          name: 'Site details',
          checked: true,
          disabled: true,
        },
        {
          sortable: false,
          resizable: true,
          width: 100,
          label: 'Status',
          prop: 'status',
          align: 'left',
          name: 'Status',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          label: 'Approvar',
          prop: 'approvar',
          align: 'left',
          name: 'Approvar',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: 'Comments',
          prop: 'comments',
          align: 'left',
          name: 'Comments',
          checked: true,
          disabled: false,
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: 'Last updated',
          prop: 'lastUpdated',
          align: 'left',
          name: 'Last updated',
          checked: true,
          disabled: false,
        },
      ],
      keyagreementRef: 0,
      name: '',
      copyOfcontractTableData: [],
      duplicateTheads: [],
      re_arrange: false,
      currPage: 1,
      rowsPerPage: 10,
      contractData: [],
      filterPanelName: 'productSpecification',
      modal_active: false,
      delete_modal_active: false,
      initialPayload: {},
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
  },
  watch: {
    // data: {
    //   handler(val) {
    //     this.payload = val;
    //     console.log(val, "product value");
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    payload: {
      handler(val) {
        console.log(val, 'product value');
        // val.attachment[0]={name:`${val.name}.pdf`}
      },
      immediate: true,
      deep: true,
    },
    tableData: {
      handler(val) {
        this.copyOfcontractTableData = val;
        console.log(val, 'product value');
        // val.attachment[0]={name:`${val.name}.pdf`}
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    // To get table header
    let sampleTheads = await MLTAxiosInstance.get(`/settings`);

    if (sampleTheads?.data?.entityRef?.['supplierContractTable']) {
      let offerTableHeadModal =
        sampleTheads.data.entityRef['supplierContractTable'];
      let offerTableHeadOrg = sampleTheads.data.entityRef[
        'supplierContractTable'
      ].filter((ele) => ele.checked);
      console.log(
        offerTableHeadOrg,
        'offerTableHeadModal',
        offerTableHeadModal
      );

      if (
        offerTableHeadModal.length === this.accounts_table_theads_cc.length
        // &&
        // offerTableHeadModal.every((item, index) => item.name ===  this.accounts_table_theads_cc[index].name)
      ) {
        this.accounts_table_theads_cc = JSON.parse(
          JSON.stringify(offerTableHeadModal)
        );
        this.accounts_table_theads = JSON.parse(
          JSON.stringify(offerTableHeadOrg)
        );
        this.tableHeaderOrgcc = JSON.parse(
          JSON.stringify(this.accounts_table_theads_cc)
        );
        this.tableHeaderOrg = JSON.parse(
          JSON.stringify(this.accounts_table_theads)
        );
      }
    }
    this.refeshContract();
    let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList = statusList.data.data;
    // let contracttype = await DMNAxiosInstance.get(`mapRef/collection/contratType`);
    // this.contracttype = contracttype.data.data;
    // console.log(this.contracttype,"this.contracttype");
  },
  methods: {
    resizedWidth(val) {
      // To get resized width
      console.log(val, 'resizedWidth', val);

      const updateWidth = (array) => {
        let item = array.find((i) => i?.prop === val?.prop);
        if (item) item.width = val.width;
      };

      updateWidth(this.accounts_table_theads);
      updateWidth(this.accounts_table_theads_cc);
      console.log(
        this.accounts_table_theads,
        'resizedWidth loop',
        this.accounts_table_theads_cc
      );
      this.applyColumns(this.accounts_table_theads_cc);
    },
    reAssignOrgTableHeader() {
      // If there is change in the table header then ressign to the Orginal data
      if (
        this.accounts_table_theads_cc != this.tableHeaderOrgcc &&
        this.tableHeaderOrgcc.length > 0
      ) {
        this.accounts_table_theads_cc = JSON.parse(
          JSON.stringify(this.tableHeaderOrgcc)
        );
      }
      if (
        this.accounts_table_theads != this.tableHeaderOrg &&
        this.tableHeaderOrg.length > 0
      ) {
        this.accounts_table_theads = JSON.parse(
          JSON.stringify(this.tableHeaderOrg)
        );
      }
      this.re_arrange = true;
    },
    addCancel() {
      this.modal_active = false;
    },
    addNewAccount() {
      //
      this.modal_active = true;
    },
    productSearch(val) {
      this.searchvalue = val;
      if (val !== '') {
        let searchData = this.searchNestedObjects(
          this.contractData,
          val.toLowerCase()
        );
        this.tableData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.contractData));
      }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === 'object' && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === 'string' && k !== 'password') {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    async refeshContract() {
      this.tableData = [
        {
          siteDetails: 'Prestige Elegance',
          status: 'Active',
          approvar: 'John doe',
          role: 'Admin',
          address: '295 A lester street, waterloo, Ontario, Canada',
          comments: '',
          lastUpdated: 'Nov 11, 2024 10:20 AM',
        },
        {
          siteDetails: 'Prestige Skyline',
          status: 'Inactive',
          role: 'manager',
          approvar: 'Emily devis',
          address: '295 A lester street, waterloo, Ontario, Canada',
          comments: '',
          lastUpdated: 'Nov 11, 2024 10:20 AM',
        },
      ];
      this.contractData = [
        {
          siteDetails: 'Prestige Elegance',
          status: 'Active',
          approvar: 'John doe',
          role: 'Admin',
          address: '295 A lester street, waterloo, Ontario, Canada',
          comments: '',
          lastUpdated: 'Nov 11, 2024 10:20 AM',
        },
        {
          siteDetails: 'Prestige Skyline',
          status: 'Inactive',
          role: 'manager',
          approvar: 'Emily devis',
          address: '295 A lester street, waterloo, Ontario, Canada',
          comments: '',
          lastUpdated: 'Nov 11, 2024 10:20 AM',
        },
      ];
      // console.log(tableData.data.length, "length");
    },

    closeDiscount() {
      this.keyagreementRef += 1;
      this.isModal = false;
      this.delete_modal_active = false;
      this.payload = {
        name: '',
        type: '',
        attachment: [
          {
            name: '',
          },
        ],
        documentNumber: '',
        status: 'Defalt',
        characteristic: [],
        validFor: {
          startDateTime: new Date(),
          endDateTime: '',
          autoRenewal: false,
        },
      };
    },
    async changeRowsPerPage(count) {
      if (this.company != '') {
        this.rowsPerPage = count;
      }
    },
    async currentPage(val) {
      if (this.company != '') {
        this.currPage = val;
      }
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    contracttyprfunc(val) {
      this.payload.type = val.name;
    },
    emitStatus(val) {
      this.payload.status = val.name;
      console.log('Discount status', val);
    },
    async addNewcontract() {
      const attachmentName = this.payload.name.replace(/\s+/g, '_');
      this.payload.attachment[0] = { name: `${attachmentName}.pdf` };
      const addedProductLocationLogPayload = {
        message: `${this.payload.name} Contract has been added`,
        detailedAction: [],
        action: 'ADDED',
        user: this.$cookies.get('userName'),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get('user'),
        ref: [this.$route.params.id],
        '@type': 'supplier-Contract',
        category: ['supplier'],
      };
      this.initLog(addedProductLocationLogPayload);
      const addproduct = await UPCAxiosInstance.post(
        `/agreementManagement?company=${this.$route.params.companyName}`,
        this.payload
      );
      console.log(addproduct.data);
      if (addproduct.data) {
        this.notification = {
          type: 'success',
          message: `Your item is added successfully!.`,
        };
      }
      this.refeshContract();
      this.closeDiscount();
    },
    async editDiscount() {
      let CM_LocationLog = {
        message: `${this.$route.params.companyName} Contract was updated`,
        detailedAction: [],
        '@type': 'supplier-Contract',
        category: ['supplier'],
        createdBy: this.$cookies.get('user'),
        user: this.$cookies.get('userName'),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: 'UPDATE',
        status: 'active',
      };

      let nameActionLog = this.buildBasicLogByDifferences(
        this.initialPayload,
        this.payload,
        'Name',
        'Name'
      );
      console.log(nameActionLog, CM_LocationLog, 'nameActionLog');

      let documentNumberActionLog = this.buildBasicLogByDifferences(
        this.initialPayload,
        this.payload,
        'documentNumber',
        'Contract number'
      );
      console.log(
        documentNumberActionLog,
        CM_LocationLog,
        'documentNumberActionLog'
      );

      let statusActionLog = this.buildBasicLogByDifferences(
        this.initialPayload,
        this.payload,
        'status',
        'status'
      );
      console.log(statusActionLog, CM_LocationLog, 'statusActionLog');

      let typeActionLog = this.buildBasicLogByDifferences(
        this.initialPayload,
        this.payload,
        'type',
        'Contract type'
      );
      console.log(typeActionLog, 'typeActionLogActionLog');

      let validForSActionLog = this.buildBasicLogByDifferences(
        this.initialPayload.validFor,
        this.payload.validFor,
        'startDateTime',
        'startDate'
      );
      console.log(validForSActionLog, 'validForSActionLog');

      let validForESActionLog = this.buildBasicLogByDifferences(
        this.initialPayload.validFor,
        this.payload.validFor,
        'endDateTime',
        'endDate'
      );
      console.log(validForESActionLog, 'validForESActionLog');

      let validForaActionLog = this.buildBasicLogByDifferences(
        this.initialPayload.validFor,
        this.payload.validFor,
        'autoRenewal',
        'autoRenewal'
      );
      console.log(validForaActionLog, 'validForaActionLog');

      CM_LocationLog.detailedAction = CM_LocationLog.detailedAction.concat(
        validForaActionLog,
        validForESActionLog,
        validForSActionLog,
        typeActionLog,
        statusActionLog,
        documentNumberActionLog,
        nameActionLog
      );
      this.payload.id = this.payload._id;
      delete this.payload._id;
      const editDiscount = await UPCAxiosInstance.patch(
        `/agreementManagement?company=${this.$route.params.companyName}`,
        this.payload
      );
      console.log(editDiscount.data);
      if (editDiscount.data) {
        this.notification = {
          type: 'success',
          message: `Done! Your item has been updated.`,
        };
        this.initLog(CM_LocationLog);
      }
      this.refeshContract();
      this.closeDiscount();
    },
    async deleteExecRow() {
      const addedProductLocationLogPayload = {
        message: `${this.deleteobj.name} Contact has been deleted`,
        detailedAction: [],
        action: 'Delete',
        user: this.$cookies.get('userName'),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get('user'),
        ref: [this.$route.params.id],
        '@type': 'supplier-Contract',
        category: ['supplier'],
      };
      this.initLog(addedProductLocationLogPayload);
      const addproduct = await UPCAxiosInstance.delete(
        `/agreementManagement/${this.deleteobj._id}?company=${this.$route.params.companyName}`,
        this.payload
      );
      console.log(addproduct.data);
      if (addproduct.data) {
        this.notification = {
          type: 'success',
          message: `Done! Your item has been removed..`,
        };
      }
      this.refeshContract();
      this.closeDiscount();
    },
    crudContextMenu($event, index) {
      if ($event.target.className === 'icon-more-vertical-filled') {
        this.containerleft =
          document
            .querySelector('#table-context' + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector('#table-context' + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    showNewContactModal() {
      this.isModal = true;
    },
    async closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === 'edit') {
        this.isModal = true;
        this.editMode = true;
        this.payload = JSON.parse(
          JSON.stringify(this.copyOfcontractTableData[i])
        );
        this.initialPayload = JSON.parse(
          JSON.stringify(this.copyOfcontractTableData[i])
        );
        // this.$router.push(`/SM/supplier/edit`);
      } else if ($event.toLowerCase() == 'delete') {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      }
    },

    computedTableAction(data) {
      data;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    arrangeColumns(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
    async applyColumns(val) {
      // To get rearranged header
      console.log('applyColumns', val);
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      let concatedEntity = sampleTheads?.data?.entityRef || {};

      concatedEntity.supplierContractTable = val;

      let reArrangeTableHeadPayload = { entityRef: concatedEntity };

      const changeTableHeader = await MLTAxiosInstance.patch(
        `/settings`,
        reArrangeTableHeadPayload
      );
      console.log(
        changeTableHeader,
        'reArrangeTableHeadPayload',
        reArrangeTableHeadPayload
      );

      let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
      console.log('sampleTheadsRefresh', sampleTheadsRefresh?.data);

      let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
      this.accounts_table_theads_cc = entityRef['supplierContractTable'];
      this.accounts_table_theads = this.accounts_table_theads_cc.filter(
        (ele) => ele.checked
      );
      this.tableHeaderOrgcc = JSON.parse(
        JSON.stringify(this.accounts_table_theads_cc)
      );
      this.tableHeaderOrg = JSON.parse(
        JSON.stringify(this.accounts_table_theads)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.header-add-supplier {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px !important;
  .tablehead-add-supplier {
    font-weight: bold;
  }
  .search-add-supplier {
    display: flex;
    gap: 10px;
  }
}
.contacts-username-supplier {
  display: flex;
  align-items: center;
  .right-head-supplier {
    display: flex;
    flex-direction: column;
  }
  .head-supplier-left {
    display: flex;
    justify-content: center;
    width: 44px;
    align-items: center;
    font-size: 45px;
    margin-right: 10px;
  }
  .head-supplier-right {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    .head-supplier-name {
      margin-right: 10px;
    }
  }
  .head-supplier-address {
    font-size: 10px;
    color: #929191;
    margin-top: 3px;
  }
}
</style>
