<template>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <section class="importContainer">
    <section
      class="header"
      style="
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      "
    >
      <div class="new-layout-header">
        <span class="new-layout-header-container">
          <hlx-breadcrumb style="font-size: 16px" :auto-route="true" />
        </span>
        <span class="schema-header-title">{{
          matchFromStatic("Import lead data")
        }}</span>
      </div>
      <div class="new-layout-header right">
        <div class="layout-footer" style="display: flex; align-items: center">
          <div
            class="go-back"
            style="
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <!-- <hlx-button class="secondary sm" @click="cancelBack">{{
              matchFromStatic("Back")
            }}</hlx-button> -->
            <hlx-button
              class="primary sm"
              style="margin-left: 20px"
              @click="openUploadModal"
              >{{ matchFromStatic("Upload") }}</hlx-button
            >
          </div>
        </div>
      </div>
    </section>

    <section v-if="showSvg" class="body svg">
      <span class="upload_component_icon">
        <img class="file-icon" src="../../assets/svg/rafiki.svg" alt="" />
      </span>
    </section>
    <section v-else style="margin-top: 24px">
      <lead-main-table-component
        :lead-data="leadData"
        :filtered-data="importData"
        :import-length="importLength"
        :field-mapping="fieldMapping"
        :csv-headers="csvHeaders"
        @emit-edit="loadData"
        @emit-delete="loadDataDelete"
      ></lead-main-table-component>
    </section>
  </section>

  <div class="to-style-modal">
    <hlx-modal
      :modal-active="importDataModal"
      :height="'335px'"
      :width="'450px'"
      :modal="true"
      @close="importDataModal = false"
    >
      <template #header>
        <div>{{ matchFromStatic("Import") }}</div>
      </template>
      <template #content>
        <div class="modal-content">
          <div
            class="new-layout-header"
            style="
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;
            "
          >
            <!-- <span class="schema-header-title" style="font-size:14px; margin-bottom: 8px; width: 30%; margin-right: 16px">Choose template</span>
        <span class="subtitle" style="width:70%"> <hlx-select
                    v-model:value="template"
                    :inline-search="true"
                    :pre-value="template"
                    :label-animation="false"
                    :placeholder-value="'Template'"
                    :options="templateList"
                    :label="'value'"
                    :prop-value="'value'"
                    @selected-value="getTemplate($event)"
                  >
                  </hlx-select></span> -->
          </div>
          <!-- <hlx-file-upload
              :file-type="'.xlsx,.csv,.json'"
              :file-size-limit="'20mb'"
              :custom-icon="icon"
              :type="'regular'"
              :content="'Import'"
              @file-data="abc"
            /> -->
          <!-- <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png,.json,.xlsx,.doc,.pdf,.txt'"
              :file-size-limit="'1gb'"
              :custom-icon="icon"
              :type="'regular'"
              :style-background="backgrounStyleupload"
              :icon="'icon-arrow-down-to-line-filled'"
              :content="'Import'"
              @file-data="imgDataPrimary"
              @delete-image="funcdelete"
              @download-image="func1"
            /> -->
          <!-- <hlx-file-upload
                  :file-type="'.jpg,.jpeg,.png,.json,.xlsx,.doc,.pdf,.txt'"
        :file-size-limit="'1gb'"
        :custom-icon="icon"
        :type="'upc-regular'"
        :icon="'icon-arrow-down-to-line-filled'"
        :content="'Import'"
        @delete-image="func"
        @download-image="func1"
        @file-data="imgDataPrimary"
      /> -->
          <hlx-file-upload
            :key="'unique-01'"
            :file-type="'.xlsx, .csv'"
            :file-size-limit="'20mb'"
            :custom-icon="icon"
            :type="'regular'"
            :label-value="labelValueStatic"
            @native-data="importEmit"
          />
          <hlx-import-progress
            v-if="isLoading && progress != 100"
            :tool-tip="true"
            :progress="progress"
            :stroke-width="20"
          />
        </div>
      </template>
    </hlx-modal>
  </div>
</template>

<script>
import { DMNAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";
// import VueCookies from "vue-cookies";
import leadMainTableComponent from "./leadMainTableComponent.vue";
export default {
  components: { leadMainTableComponent },

  data() {
    return {
      createdBy:"",
      labelValueStatic: {
        Select_files: this.matchFromStatic("Select files"),
        Upload_file: this.matchFromStatic("Upload file"),
        Upload: this.matchFromStatic("Upload"),
        Select_file: this.matchFromStatic("Select file"),
        or_drag_and_drop: this.matchFromStatic("or drag and drop"),
        Drag_and_drop_your_files: this.matchFromStatic(
          "Drag and drop your files"
        ),
        Choose_file: this.matchFromStatic("Choose file"),
        or: this.matchFromStatic("or"),
        Max: this.matchFromStatic("Max"),
        Cancel: this.matchFromStatic("Cancel"),
      },
      notification: [],
      fieldMapping: {},
      forTable: [],
      isLoading: false, // To track loading state
      apiKey: "",
      progress: 0, // Progress value
      progressInterval: null,
      showSvg: true,
      importDataModal: false,
      leadData: [],
      importData: [],
      uploadedData: [],
      csvHeaders: [],
      importLength: 0,
    };
  },
  watch: {
    importLength: {
      handler(val) {
        if (val) {
  console.log(val,"importlength")          
        }
      },
      deep: true,
      immediate: true,
    }
  },
  async mounted() {
    this.createdBy = this.$cookies.get(
          "user"
        )
    let template = await UPCAxiosInstance.get(
      `/importTemplate/675d7d6e12c2aa7e2836b99a`
    );
    this.fieldMapping = template.data.module.fieldMapping;
    this.csvHeaders = template.data.module.csvHeaders;
    let filterConstraint = {
      type: "filter",
      module: "referencedata",
      collection: "SampleImport",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {importedAt: -1},
      limit: 10,
      filterQuery: {},
      searchQuery: "",
      company: this.$cookies.get("company"),
    };

    let response = await DMNAxiosInstance.post(
      "/util/filterData/import",
      filterConstraint
    );

    let importData = this.flattenNestedArrays(response.data.data.data);
    this.importData = importData;
    this.importLength = this.flattenNestedArrays(response.data.data).total;
    let transformPayload = {
      filteredData: importData,
      fieldMapping: {
        "saleslead.dwellingtype": {
          type: "rule",
          key: "dwellingType",
          required: true,
          ruleRef: "67540ba75f0139cfa6c4d58c",
        },
        "saleslead.name": {
          type: "value",
          required: true,
          key: "Nom du développement",
        },
        "saleslead.numberOfSites": {
          type: "rule",
          key: "numberOfSites",
          required: true,
          ruleRef: "67540ba75f0139cfa6c4d5ee",
        },
        "saleslead.importedAt": {
          type: "value",
          key: "importedAt",
          required: false,
        },

        "saleslead.relatedParty[]": {
          type: "rule",
          key: "relatedParty",
          required: true,
          ruleRef: "675d7e8412c2aa7e2836b99b",
          entity: [
            {
              name: "Users",
              method: "POST",
              apiRef:
                "https://appbackend-ghubgwxrcq-pd.a.run.app/util/filterData",
              payload: {
                type: "filter",
                company: this.$cookies.get("company"),
                module: "hierarchycontacts",
                isPaginated: true,
                paginatedQuery: {
                  rowsPerPage: 10,
                  currentPage: 1,
                },
                isNeedToBeSort: true,
                sortFieldAndOrder: {},
                filterQuery: {
                  "@baseType": "organisation",
                  company: this.$cookies.get("company"),
                },
                searchQuery: "",
              },
            },
            {
              name: "Sites",
              method: "GET",
              apiRef: "https://tmf-upc-ghubgwxrcq-pd.a.run.app/geoSite",
            },
          ],
        },
        "saleslead.externalReference[]": {
          type: "rule",
          key: "externalReference",
          required: true,
          ruleRef: "6759ba244869b316c1d93f59",
        },
        "saleslead.leadSource": {
          type: "rule",
          key: "leadSource",
          required: true,
          ruleRef: "67540c3c5f0139cfa6c4dcd2",
        },
        "saleslead.firstName": {
          type: "rule",
          key: "firstName",
          required: true,
          ruleRef: "67540ba95f0139cfa6c4d714",
        },
        "saleslead.lastName": {
          type: "rule",
          key: "lastName",
          required: true,
          ruleRef: "67540baa5f0139cfa6c4d776",
        },
        "saleslead.email": {
          type: "rule",
          key: "email",
          required: true,
          ruleRef: "67540baa5f0139cfa6c4d7d8",
        },
        "saleslead.status": {
          type: "rule",
          key: "status",
          required: true,
          ruleRef: "67540bab5f0139cfa6c4d83a",
        },
        "saleslead.description": {
          type: "value",
          required: true,
          key: "Description",
        },
        "saleslead.numberOfUnits": {
          type: "value",
          required: true,
          key: "#MDU",
        },
      },
    };
    let leadData = await DMNAxiosInstance.post(
      "/import/transform_data/?type=saleslead",
      { ...transformPayload }
    );

    this.leadData = leadData.data.map((i) => {
      return i.saleslead;
    });
    if (this.leadData.length > 0) {
      console.log("leadData", this.leadData);
      this.showSvg = false;
    }
  },

  methods: {
    async loadData() {
      this.notification = {
        type: "success",
        message: `Lead imported successfully!`,
      };
      await this.loadTableData();
    },
    async loadDataDelete() {
      this.notification = {
        type: "success",
        message: `Record deleted successfully!`,
      };
      await this.loadTableData();
    },
    cancelBack() {
      this.$router.back();
    },
    startProgressSimulation() {
      this.progress = 0; // Reset progress
      this.progressInterval = setInterval(() => {
        if (this.progress < 75) {
          this.progress += 2; // Increment progress until 75%
        }
      }, 200);
    },
    stopProgressSimulation() {
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
        this.progressInterval = null;
      }
    },
    flattenNestedArrays(data) {
      // Recursively flattens any nested arrays in the given object or array
      if (Array.isArray(data)) {
        // If it's an array, recursively flatten each item, and then flatten the array itself
        const flattened = data.map((item) => this.flattenNestedArrays(item));
        // Flatten arrays containing single elements (i.e., [[item]] -> [item])
        return flattened.flat();
      } else if (data !== null && typeof data === "object") {
        // For objects, iterate over all keys and recursively flatten the values
        const flattenedObj = {};
        for (const [key, value] of Object.entries(data)) {
          flattenedObj[key] = this.flattenNestedArrays(value);
        }
        return flattenedObj;
      }
      return data; // Return the data as-is if it's neither an object nor an array
    },
    async loadTableData() {
      let filterConstraint = {
        type: "filter",
        module: "referencedata",
        collection: "SampleImport",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {importedAt: -1},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: this.$cookies.get("company"),
      };
      let response = await DMNAxiosInstance.post(
        "/util/filterData/import",
        filterConstraint
      );

      let importData = this.flattenNestedArrays(response.data.data.data);
      this.importData = importData;
      this.importLength = this.flattenNestedArrays(response.data.data).total;
      this.totalPageNo = this.importLength
      console.log("4xx", this.importLength)
      let transformPayload = {
        filteredData: importData,
        fieldMapping: {
          "saleslead.dwellingtype": {
            type: "rule",
            key: "dwellingType",
            required: true,
            ruleRef: "67540ba75f0139cfa6c4d58c",
          },
          "saleslead.name": {
            type: "value",
            required: true,
            key: "Nom du développement",
          },
          "saleslead.numberOfSites": {
            type: "rule",
            key: "numberOfSites",
            required: true,
            ruleRef: "67540ba75f0139cfa6c4d5ee",
          },
          "saleslead.importedAt": {
            type: "value",
            key: "importedAt",
            required: false,
          },

          "saleslead.relatedParty[]": {
            type: "rule",
            key: "relatedParty",
            required: true,
            ruleRef: "675d7e8412c2aa7e2836b99b",
            entity: [
              {
                name: "Users",
                method: "POST",
                apiRef:
                  "https://appbackend-ghubgwxrcq-pd.a.run.app/util/filterData",
                payload: {
                  type: "filter",
                  company: this.$cookies.get("company"),
                  module: "hierarchycontacts",
                  isPaginated: true,
                  paginatedQuery: {
                    rowsPerPage: 10,
                    currentPage: 1,
                  },
                  isNeedToBeSort: true,
                  sortFieldAndOrder: {},
                  filterQuery: {
                    "@baseType": "organisation",
                    company: this.$cookies.get("company"),
                  },
                  searchQuery: "",
                },
              },
              {
                name: "Sites",
                method: "GET",
                apiRef: "https://tmf-upc-ghubgwxrcq-pd.a.run.app/geoSite",
              },
            ],
          },
          "saleslead.externalReference[]": {
            type: "rule",
            key: "externalReference",
            required: true,
            ruleRef: "6759ba244869b316c1d93f59",
          },
          "saleslead.leadSource": {
            type: "rule",
            key: "leadSource",
            required: true,
            ruleRef: "67540c3c5f0139cfa6c4dcd2",
          },
          "saleslead.firstName": {
            type: "rule",
            key: "firstName",
            required: true,
            ruleRef: "67540ba95f0139cfa6c4d714",
          },
          "saleslead.lastName": {
            type: "rule",
            key: "lastName",
            required: true,
            ruleRef: "67540baa5f0139cfa6c4d776",
          },
          "saleslead.email": {
            type: "rule",
            key: "email",
            required: true,
            ruleRef: "67540baa5f0139cfa6c4d7d8",
          },
          "saleslead.status": {
            type: "rule",
            key: "status",
            required: true,
            ruleRef: "67540bab5f0139cfa6c4d83a",
          },
          "saleslead.description": {
            type: "value",
            required: true,
            key: "Description",
          },
          "saleslead.numberOfUnits": {
            type: "value",
            required: true,
            key: "#MDU",
          },
        },
      };
      let leadData = await DMNAxiosInstance.post(
        "/import/transform_data/?type=saleslead",
        { ...transformPayload }
      );

      this.leadData = leadData.data.map((i) => {
        return i.saleslead;
      });
      if (this.leadData.length > 0) {
        console.log("leadData", this.leadData);
        this.showSvg = false;
      }
    },
    removeNonPrimitiveKeys(obj) {
      for (const key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          delete obj[key];
        }
      }
      return obj;
    },
    openUploadModal() {
      this.importDataModal = true;
    },

    async importEmit(event) {
      const file = event.target.files[0]; // Get the uploaded file from the input
      if (!file) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("file", file, file.name);
      this.isLoading = true;
      this.startProgressSimulation();
      try {
        let res = await DMNAxiosInstance.post(
          `/import/map_data/?type=saleslead&id=675d7d6e12c2aa7e2836b99a`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.stopProgressSimulation();
        this.progress = 100;
        this.importDataModal = false;
        let fieldMapping = {
          "saleslead.dwellingtype": {
            type: "rule",
            key: "dwellingType",
            required: true,
            ruleRef: "67540ba75f0139cfa6c4d58c",
          },
          "saleslead.name": {
            type: "value",
            required: true,
            key: "Nom du développement",
          },
          "saleslead.numberOfSites": {
            type: "rule",
            key: "numberOfSites",
            required: true,
            ruleRef: "67540ba75f0139cfa6c4d5ee",
          },

          "saleslead.relatedParty[]": {
            type: "rule",
            key: "relatedParty",
            required: true,
            ruleRef: "675d7e8412c2aa7e2836b99b",
            entity: [
              {
                name: "Users",
                method: "POST",
                apiRef:
                  "https://appbackend-ghubgwxrcq-pd.a.run.app/util/filterData",
                payload: {
                  type: "filter",
                  company: this.$cookies.get("company"),
                  module: "hierarchycontacts",
                  isPaginated: true,
                  paginatedQuery: {
                    rowsPerPage: 10,
                    currentPage: 1,
                  },
                  isNeedToBeSort: true,
                  sortFieldAndOrder: {},
                  filterQuery: {
                    "@baseType": "organisation",
                    company: this.$cookies.get("company"),
                  },
                  searchQuery: "",
                },
              },
              {
                name: "Sites",
                method: "GET",
                apiRef: "https://tmf-upc-ghubgwxrcq-pd.a.run.app/geoSite",
              },
            ],
          },
          "saleslead.externalReference[]": {
            type: "rule",
            key: "externalReference",
            required: true,
            ruleRef: "6759ba244869b316c1d93f59",
          },
          "saleslead.leadSource": {
            type: "rule",
            key: "leadSource",
            required: true,
            ruleRef: "67540c3c5f0139cfa6c4dcd2",
          },
          "saleslead.firstName": {
            type: "rule",
            key: "firstName",
            required: true,
            ruleRef: "67540ba95f0139cfa6c4d714",
          },
          "saleslead.lastName": {
            type: "rule",
            key: "lastName",
            required: true,
            ruleRef: "67540baa5f0139cfa6c4d776",
          },
          "saleslead.email": {
            type: "rule",
            key: "email",
            required: true,
            ruleRef: "67540baa5f0139cfa6c4d7d8",
          },
          "saleslead.status": {
            type: "rule",
            key: "status",
            required: true,
            ruleRef: "67540bab5f0139cfa6c4d83a",
          },
          "saleslead.description": {
            type: "value",
            required: true,
            key: "Description",
          },
          "saleslead.numberOfUnits": {
            type: "value",
            required: true,
            key: "#MDU",
          },
        };
       
        this.fieldMapping = fieldMapping;
        if (res.data.ok == 1) {
          this.forTable = res.data.data;
          this.uploadedData = await DMNAxiosInstance.post(
            "/import/transform_data/?type=saleslead",
            {
              filteredData: res.data.data.filter((i) => i.isValid == true),
              fieldMapping,
            }
          );

          await this.loadTableData();
    
        }
      } catch (error) {
        console.error(
          "Error uploading file:",
          error.response?.data || error.message
        );
      }
      this.uploadedData = this.uploadedData.data.map((i) => {
        return i.saleslead;
      });
      this.notification = {
        type: "success",
        message: `${this.uploadedData.length} leads are imported successfully!`,
      };

      this.uploadedData.map(i => i.createdBy = this.createdBy)

     let updatedData = await UPCAxiosInstance.post(`salesLead`, this.uploadedData);
     if (updatedData?.data) {
  let uploadedSalesLeadData = JSON.parse(JSON.stringify(updatedData?.data));

  // Use Promise.all for parallel execution
  await Promise.all(
    uploadedSalesLeadData.map(async (ele) => {
      const leadCreationActivityLogPayload = {
        message: `${ele?.name} lead has been created`,
        detailedAction: [
          {
            action: 'Created lead',
            from: '',
            to: '',
            property: 'Lead Created',
            field: '',
            displayID: ele?.displayID,
          },
        ],
        action: 'created',
        user: this.$cookies.get('userName'),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get('user'),
        ref: [ele?.id],
        '@type': 'lead-creation',
        category: ['lead'],
      };

      try {
        let response = await UPCAxiosInstance.post(
          '/activityLog/v1',
          leadCreationActivityLogPayload
        );
        console.log('Activity log response:', response);
      } catch (error) {
        console.error('Error posting activity log:', error);
      }
    })
  );
}

     console.log("updatedData",updatedData)
      let collectionConfig = {
          name: "NetworkTable",
          keys: [
            "Network num",
            "Clli Num",
            "Nom CO",
            "Zone",
            "Gr",
            "Statut Projet",
            "Nom du développement",
            "networkManagerId",
            "Municipalite",
            "fsa",
            "Fo Statut",
            "Type De Projet",
            "Lancement Prevu",
            "Lancement Reel",
            "Ddi Prevu",
            "Qualify Prevu",
            "Qualify Reel",
            "Qualif Statut",
            "status",
          ],
        };
      let metadata = this.forTable
            .filter(
              (i) =>
                i.isValid == true &&
                i.importStatus.toLowerCase() === "imported successfully" &&
                i?.["Network num"] != null
            )
            .map((i) => {
              if (
                i.isValid &&
                i.importStatus.toLowerCase() === "imported successfully" &&
                i?.["Network num"] != null
              ) {
                delete i["Decl."];
                i.module = "saleslead";
                return this.removeNonPrimitiveKeys(i);
              }
            });

          if (collectionConfig != undefined) {
            await DMNAxiosInstance.post(
              `mapRef/addMany/import/MetaData`,
              metadata
            );
            const datum = metadata.map((entry) =>
              collectionConfig.keys.reduce((filteredEntry, key) => {
                if (
                  entry != undefined &&
                  entry != null &&
                  filteredEntry != undefined &&
                  filteredEntry != null
                ) {
                  if (key in entry) {
                    filteredEntry[key] = entry?.[key];
                  }
                  return filteredEntry;
                }
              }, {})
            );
            await DMNAxiosInstance.post(
              `mapRef/addMany/import/${collectionConfig.name}`,
              datum
            );
          }
    },
  },
};
</script>

<style lang="scss">
.importContainer {
  height: 100%;
  .svg {
    height: 100%;
  }
}
</style>