<template>
  <div class="add-feature-drawer">
    <hlx-drawer
      :show="isModal"
      position="right"
      width="400"
      :footer="true"
      :show-close-icon="true"
      @close="isModal = false"
    >
      <template #body>
        <div style="height: 90vh !important">
          <div
            style="
              border-bottom: 1px solid #d4d4d4;
              padding-bottom: 5px;
              padding: 13px;
            "
          >
            <b>Edit ticket</b>
          </div>

          <div
            :key="updateKey"
            class="ticket-drawer-res"
            style="
              display: flex;
              padding: 18px 13px;
              flex-direction: column;
              gap: 18px;
            "
          >
            <span
              v-for="(addFieldsObject, index) in labelFieldsValue"
              :key="index"
            >
              <!-- Dropdown preview -->
              <div
                v-if="addFieldsObject['@type'] == 'Dropdown'"
                class="feature-value-dropdown-preview"
              >
                <span v-if="addFieldsObject.name === 'Requester'">
                  <hlx-select
                    :key="componentkey1"
                    v-model:value="
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-value="
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :options="contactPayload"
                    :placeholder-value="addFieldsObject.name"
                    :label-animation="true"
                    :inline-search="true"
                    :required="addFieldsObject.mandatory"
                    :clearable="true"
                    :label="'name'"
                    :prop-value="'name'"
                    @selected-value="RelatedPArtyData"
                  ></hlx-select>
                </span>
                <span v-else-if="addFieldsObject.name === 'Assigned to'">
                  <hlx-select
                    v-model:value="
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-value="
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :options="assignedToo"
                    :placeholder-value="addFieldsObject.name"
                    :label="'name'"
                    :prop-value="'name'"
                    :label-animation="true"
                    :search-image-tag="true"
                    :sub-title="'company'"
                    :tag-value="'newRole'"
                    :image-link="'link'"
                    :inline-search="true"
                    @selected-value="AssignedData"
                  ></hlx-select>
                </span>
                <span v-else-if="addFieldsObject.name === 'Ticket category'">
                  <hlx-select
                    :key="componentkey1"
                    v-model:value="
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-value="
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :options="addFieldsObject.characteristicSpecification"
                    :placeholder-value="addFieldsObject.name"
                    :label-animation="true"
                    :required="addFieldsObject.mandatory"
                    :clearable="true"
                    :label="'value'"
                    :prop-value="'value'"
                    @selected-value="TicketcategoryData"
                  ></hlx-select>
                </span>
                <span v-else>
                  <hlx-select
                    :key="componentkey1"
                    v-model:value="
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-value="
                      preValFunc(
                        editPayload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject
                      )
                    "
                    :options="addFieldsObject.characteristicSpecification"
                    :placeholder-value="addFieldsObject.name"
                    :label-animation="true"
                    :required="addFieldsObject.mandatory"
                    :clearable="true"
                    :label="'value'"
                    :prop-value="'value'"
                  ></hlx-select>
                </span>
              </div>

              <!-- Radio Button -->
              <div v-if="addFieldsObject['@type'] == 'Radio button'">
                <div v-if="addFieldsObject['@type'] == 'Radio button'">
                  <b> {{ addFieldsObject.name }}<sup v-if="addFieldsObject.mandatory" style="color:  #ff5050;">*</sup></b>
                  <br />
                </div>
                <br />
                <div
                  v-if="addFieldsObject['@type'] == 'Radio button'"
                  class="feature-value-radio-preview"
                >
                  <hlx-input
                    v-model:value="
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-val="
                      preValFunc(
                        editPayload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject
                      )
                    "
                    :required="addFieldsObject.mandatory"
                    :options="addFieldsObject.characteristicSpecification"
                    :position="'horizontal'"
                    :prop-value="'value'"
                    :label="'value'"
                    :type="'border-radio-buttons'"
                  />
                </div>
              </div>
              <!--------- Date ----->
              <div
                v-if="addFieldsObject['@type'] == 'Date'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Date'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="
                    editPayload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ]
                  "
                  :pre-val="
                    editPayload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ]
                  "
                  :required="addFieldsObject.mandatory"
                  :date-format="'dd (short month), yyyy'"
                  :label-value="addFieldsObject.name"
                  :label-animation="true"
                  type="date"
                  icon="calendar-regular"
                  icon-position="right"
                />
              </div>
              <!-- Single line Text -->
              <div
                v-if="addFieldsObject['@type'] == 'Single line text'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Single line text'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <span
                  v-if="
                    editPayload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ] === editPayload.troubleTicketSpecification[0]['Email id']
                  "
                >
                  <hlx-input
                    v-model:value="
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-val="
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :required="addFieldsObject.mandatory"
                    type="email"
                    :label-animation="true"
                    :label-value="addFieldsObject.name"
                    :display-error="false"
                    :custom-error="isEmailEmpty"
                    :custom-error-message="'Enter a valid email.(eg: user@example.com)'"
                    @at-input="
                      validateEmail(
                        editPayload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject.name
                      )
                    "
                  />
                </span>
                <span v-else>
                  <hlx-input
                    v-model:value="
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-val="
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :required="addFieldsObject.mandatory"
                    :type="'text'"
                    :label-animation="true"
                    :label-value="addFieldsObject.name"
                  />
                </span>
              </div>

              <!-- Multi line Text -->
              <div
                v-if="addFieldsObject['@type'] == 'Multi line text'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Multi line text'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="
                    editPayload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ]
                  "
                  :pre-val="
                    editPayload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ]
                  "
                  :type="'textarea'"
                  :required="addFieldsObject.mandatory"
                  :label-animation="true"
                  :label-value="addFieldsObject.name"
                />
              </div>

              <!-- Multi select -->
              <div
                v-if="addFieldsObject['@type'] == 'Multi select'"
                class="feature-value-radio-preview"
              >
                <hlx-multi-select
                  :key="componentKeyM"
                  :required="addFieldsObject.mandatory"
                  :pre-value="
                    editPayload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ]
                  "
                  :options="addFieldsObject.characteristicSpecification"
                  :tooltipposition="'left'"
                  :placeholder="addFieldsObject.name"
                  :label-animation="true"
                  :prop-value="'value'"
                  :label="'value'"
                  :disabled="false"
                  @selected-array="selectedValue($event, addFieldsObject.name)"
                ></hlx-multi-select>
              </div>
              <!-- Address -->

              <!-- Attachment -->
              <div
                v-if="addFieldsObject['@type'] == 'Attachment'"
                class="feature-value-attachment-preview"
              >
                <attachment
                  :key="attachmnetComponetKey"
                  :pre-value="
                    editPayload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ]
                  "
                  @file-upload="attachmentEmit($event, addFieldsObject.name)"
                  @removed-data="removedEmit($event, addFieldsObject.name)"
                  @fileuploaddata="convertData"
                ></attachment>
                <!-- :pre-val=" payload?.tro ubleTicketSpecification[0][addFieldsObject?.name]" -->
              </div>
              <!-- Number -->
              <div
                v-if="addFieldsObject['@type'] == 'Number'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Number'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="
                    editPayload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ]
                  "
                  :pre-val="
                    editPayload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ]
                  "
                  :required="addFieldsObject.mandatory"
                  :type="'number'"
                  :round-off="true"
                  :label-animation="true"
                  :label-value="addFieldsObject.name"
                />
              </div>

              <!-- Decimal -->
              <div
                v-if="addFieldsObject['@type'] == 'Decimal'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Decimal'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="
                    editPayload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ]
                  "
                  :pre-val="
                    editPayload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ]
                  "
                  :type="'number'"
                  :step="0.1"
                  :label-animation="true"
                  :label-value="addFieldsObject.name"
                  :required="addFieldsObject.mandatory"
                />
              </div>

              <!-- Address -->
              <div
                v-if="addFieldsObject['@type'] == 'Address'"
                class="feature-value-radio-preview"
              >
                <addressComponent
                  :view="false"
                  :required="addFieldsObject.mandatory"
                  :pre-val="
                    addressPreValue(
                      editPayload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    )
                  "
                  @address-data="
                    receivedAddressData($event, addFieldsObject.name)
                  "
                ></addressComponent>
                <!-- :pre-val=" payload?.tro ubleTicketSpecification[0][addFieldsObject?.name]" -->
              </div>
            </span>
          </div>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 16px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="isModal = false"
            >Cancel</hlx-button
          >

          <hlx-button
            class="primary sm"
            style="margin-left: 10px"
            :disabled="!isFormValid"
            @click="editedTicket(id, editPayload)"
            >Save</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <main class="gen-ai-chat-window">
    <div class="chat-content">
      <section class="section">
        <div class="profile-info">
          <hlx-file-upload
            :key="profileComponentKey"
            :file-type="'.jpg,.jpeg,.png'"
            :file-size-limit="'20mb'"
            :uploadtype="'Rectangle'"
            :style-background="backgrounStyleupload"
            :height="50"
            :width="50"
            :read-only="defalt"
            :custom-icon="icon"
            :type="'profile-upload-preview'"
            :name-display="payload?.relatedParty[0]?.name"
            :content="'Import'"
          />
          <div class="profile-details">
            <span
              ><b class="company-name">{{
                payload?.relatedParty[0]?.name
              }}</b></span
            >
            <span class="id-info">
              <p>ID :</p>
              <p class="id-value">{{ payload?.relatedParty[0]?.displayID }}</p>
            </span>
          </div>
        </div>
        <div class="ticket-info">
          <p class="ticket-title-main"><b class="ticket-title">Tickets</b></p>
          <p class="ticket-description">
            Need assistance or have a question about your internet service? Just
            drop your query here. We'll take care of the rest!
          </p>
        </div>
        <div class="chat-header">
          <span class="chat-subject">
            <span style="display: flex; gap: 11px; align-items: center">
              <i
                class="icon-arrow-left-filled"
                style="font-size: 20px"
                @click="closeChat"
              ></i>
              <p v-if="payload?.troubleTicketSpecification[0]?.Subject">
                <b>{{ payload?.troubleTicketSpecification[0]?.Subject }}</b>
              </p>
              <span style="font-size: 14px; color: rgba(166, 166, 166, 1)"
                >Id:{{ payload?.displayID }}</span
              >

              <i class="icon-edit-filled" @click="editTicketFiled"></i>
            </span>
            <p
              v-if="payload?.troubleTicketSpecification[0]?.Description"
              class="chat-subject-title"
            >
              {{ payload?.troubleTicketSpecification[0]?.Description }}
            </p>
          </span>
        </div>
        <div class="status-info">
          <div class="status-data" style="display: flex; align-items: center">
            <p class="status-text">Status :</p>
            <span
              v-if="
                payload.troubleTicketSpecification[0].Status?.toLowerCase() ===
                'open'
              "
              style="display: flex; gap: 2px"
            >
              <HlxStatus
                :type="'custom'"
                :point-color="'rgba(252, 50, 50, 1)'"
                :status-value="payload.troubleTicketSpecification[0].Status"
                >{{ payload.Status }}</HlxStatus
              >
            </span>
            <span
              v-if="
                payload.troubleTicketSpecification[0].Status?.toLowerCase() ===
                'close'
              "
              style="display: flex; gap: 2px"
            >
              <HlxStatus
                :type="'custom'"
                :point-color="'rgba(2, 181, 92, 1)'"
                :status-value="payload.troubleTicketSpecification[0].Status"
                >{{ payload.Status }}</HlxStatus
              >
            </span>
            <span
              v-if="
                payload.troubleTicketSpecification[0].Status?.toLowerCase() ===
                'in progress'
              "
              style="display: flex; gap: 2px"
            >
              <HlxStatus
                :type="'custom'"
                :point-color="'#F9A645'"
                :status-value="payload.troubleTicketSpecification[0].Status"
                >{{ payload.Status }}</HlxStatus
              >
            </span>
          </div>

          <div class="priority-info">
            <p class="priority-text">Priority :</p>
            <div class="priority-wrapper">
              <span
                v-if="payload?.priority?.toLowerCase() === 'high'"
                class="priority-high"
              >
                <i class="icon-flag-filled" />
              </span>
              <span
                v-if="payload?.priority?.toLowerCase() === 'medium'"
                class="priority-medium"
              >
                <i class="icon-flag-filled" />
              </span>
              <span
                v-if="payload?.priority?.toLowerCase() === 'low'"
                class="priority-low"
              >
                <i class="icon-flag-filled" />
              </span>
              <span>{{ payload?.priority }}</span>
            </div>
          </div>
        </div>
        <div style="font-size: 14px; width: 100%; overflow: hidden">
          <div v-if="transformedSpecification.length" style="font-size: 14px">
            <div
              v-for="(item, index) in transformedSpecification"
              :key="index"
              class="specification-item"
              style="font-size: 14px; margin-left: 32px"
            >
              <div
                v-for="(value, key) in item"
                :key="key"
                class="specification-field"
                style="margin-bottom: 16px"
              >
                <div
                  v-if="
                    value &&
                    (value !== '' || (Array.isArray(value) && value.length))
                  "
                >
                  <!-- Handle date dynamically -->
                  <span v-if="isDate(value)" style="font-size: 14px">
                    <span class="item-value">{{ key }}:</span>
                    {{ dateAndTimeFormatter(value) }}
                  </span>

                  <span v-else-if="key === 'Assigned to'" style="display: flex">
                    <span class="item-value">{{ key }}:</span>
                    <span style="display: flex; gap: 8px">
                      <span>{{ value }}</span>
                      <span v-if="value"
                        ><hlx-label class="primary sm">{{
                          "Upc Admin"
                        }}</hlx-label></span
                      >
                    </span>
                  </span>
                  <!-- Handle tag counts dynamically -->
                  <div
                    v-else-if="Array.isArray(value) && isTagCountArray(value)"
                  >
                    <span style="display: flex; align-items: center; gap: 10px">
                      <span class="item-value">{{ key }}:</span>
                      <span style="width: 80%">
                        <hlx-tagcount
                          :arrayfile="value"
                          :item-key="'Name'"
                          :type="'label'"
                        ></hlx-tagcount>
                      </span>
                    </span>
                  </div>
                  <div
                    v-else-if="Array.isArray(value) && isAttachmentArray(value)"
                  >
                    <span style="display: flex; align-items: center; gap: 10px">
                      <span class="item-value">{{ key }}:</span>
                      <span style="width: 80%">
                        <div
                          v-if="value.length > 0"
                          class="box-attachment-chat-parent-email"
                        >
                          <div
                            v-for="(box, index) in value"
                            :key="index"
                            class="box-attachment-supplier"
                          >
                            <div>
                              <span
                                v-if="getFileExtension(box.name) === 'txt'"
                                class="upload_component_icon"
                              >
                                <img
                                  class="file-icon"
                                  src="@/assets/svg/multiUpload/text.svg"
                                  alt=""
                                />
                              </span>
                              <span
                                v-if="getFileExtension(box.name) === 'docx'"
                                class="upload_component_icon"
                              >
                                <img
                                  class="file-icon"
                                  src="@/assets/svg/multiUpload/word.svg"
                                  alt=""
                                />
                              </span>
                              <span
                                v-if="getFileExtension(box.name) === 'png'"
                                class="upload_component_icon"
                              >
                                <img
                                  class="file-icon"
                                  src="@/assets/svg/multiUpload/png.svg"
                                  alt=""
                                />
                              </span>
                              <span
                                v-if="getFileExtension(box.name) === 'pdf'"
                                class="upload_component_icon"
                              >
                                <img
                                  class="file-icon"
                                  src="@/assets/svg/multiUpload/pdf.svg"
                                  alt=""
                                />
                              </span>
                              <span
                                v-if="getFileExtension(box.name) === 'json'"
                                class="upload_component_icon"
                              >
                                <img
                                  class="file-icon"
                                  src="@/assets/svg/multiUpload/json.svg"
                                  alt=""
                                />
                              </span>
                              <span
                                v-if="getFileExtension(box.name) === 'jpg'"
                                class="upload_component_icon"
                              >
                                <img
                                  class="file-icon"
                                  src="@/assets/svg/multiUpload/jpg.svg"
                                  alt=""
                                />
                              </span>
                              <span
                                v-if="getFileExtension(box.name) === 'jpeg'"
                                class="upload_component_icon"
                              >
                                <img
                                  class="file-icon"
                                  src="@/assets/svg/multiUpload/jpeg.svg"
                                  alt=""
                                />
                              </span>
                              <span
                                v-if="getFileExtension(box.name) === 'xlsx'"
                                class="upload_component_icon"
                              >
                                <img
                                  class="file-icon"
                                  src="@/assets/svg/multiUpload/excel.svg"
                                  alt=""
                                />
                              </span>
                            </div>
                            <div style="display: flex; flex-direction: column">
                              <span
                                style="
                                  font-weight: 400;
                                  font-size: 12px;
                                  white-space: nowrap;
                                  width: 80px;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ box.name }}
                              </span>
                            </div>
                            <span
                              class="closeIconechat"
                              style="margin-left: -7px"
                              @click="downloadAttachment(box, index)"
                              ><i
                                style="color: #6e6b7b"
                                class="icon-download-regular"
                              ></i
                            ></span>
                          </div>
                        </div>
                      </span>
                    </span>
                  </div>
                  <!-- Handle other arrays of objects excluding attachment type -->
                  <div
                    v-else-if="
                      Array.isArray(value) &&
                      value.length &&
                      typeof value[0] === 'object'
                    "
                  >
                    <div v-for="(subItem, subIndex) in value" :key="subIndex">
                      <div
                        v-for="(subValue, subKey) in subItem"
                        :key="subKey"
                      
                      >
                        <span class="item-value">{{ subKey }}:</span>
                        {{ subValue }}
                      </div>
                    </div>
                  </div>
                  <!-- Handle address description -->
                  <div v-else-if="typeof value === 'object' && value !== null">
                    <div
                      v-if="
                        value.characteristic &&
                        'description' in value.characteristic
                      "
                     
                    >
                      <span class="item-value">{{ key }}:</span>
                      {{ value.characteristic.description }}
                    </div>
                    <div v-else>
                      <div
                        v-for="(subValue, subKey) in value"
                        :key="subKey"
                        
                      >
                        <span class="item-value">{{ subKey }}:</span>
                        {{ subValue }}
                      </div>
                    </div>
                  </div>
                  <!-- Handle regular values -->
                  <div
                    v-else
                    
                  >
                    <span class="item-value">{{ key }}:</span> {{ value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-else><em>No trouble ticket specification data available</em></div> -->
        </div>
      </section>
      <section class="section" style="margin-left: 41px">
        <div
          v-for="(message, index) in payload.externalIdenetifier"
          :key="index"
          :ref="
            index === payload.externalIdenetifier.length - 1
              ? 'lastMessage'
              : ''
          "
        >
          <div class="MesssageContainer">
            <div v-if="shouldDisplayDate(index)" class="headerMessage">
              {{ formatRelativeTime(message.createdAt) }}
            </div>
            <!-- <div><img src="@/assets/images/logo.png" alt=""  class="user-image"/></div> -->
            <span style="display: flex; width: 60%; gap: 10px">
              <span
                v-if="shouldDisplayDate(index) && instanceData?.name != ''"
                class="chat_app_profilepic"
              >
                <hlx-file-upload
                  :key="componentkey"
                  :file-type="'.jpg,.jpeg,.png'"
                  :file-size-limit="'20mb'"
                  :uploadtype="'Circle'"
                  :style-background="backgrounStyleuploadSupplier"
                  :height="25"
                  :width="25"
                  :read-only="true"
                  :custom-icon="icon"
                  :type="'profile-upload'"
                  :content="'Import'"
                  :name-display="instanceData?.name"
                  :profile-pic-preview="profilePicturePrevalue"
                />
              </span>
              <span v-else class="chat_app_profilepic">
                <hlx-file-upload
                  :key="componentkey"
                  :file-type="'.jpg,.jpeg,.png'"
                  :file-size-limit="'20mb'"
                  :uploadtype="'Circle'"
                  :style-background="backgrounStyleuploadSupplier"
                  :height="25"
                  :width="25"
                  :read-only="true"
                  :custom-icon="icon"
                  :type="'profile-upload'"
                  :content="'Import'"
                  :name-display="instanceData?.name"
                  :profile-pic-preview="profilePicturePrevalue"
              /></span>
              <span>
                <span
                  v-if="message.message"
                  :id="'messagers' + index"
                  class="messages-ticket-chat"
                >
                  {{ message.message }}
                  <span class="time-stamp-ticket-chat">{{
                    convertTime(message.createdAt)
                  }}</span>
                </span>
                <span
                  v-if="message.attachment && message.attachment.length > 0"
                >
                  <div class="chantAPP_fileupload_attachment">
                    <div class="box-attachment-supplier-parent">
                      <span class="time-stamp-ticket-chat">{{
                        convertTime(message.createdAt)
                      }}</span>
                      <div
                        v-for="(box, indexs) in message.attachment"
                        :key="indexs"
                        class="box-attachment-supplier"
                      >
                        <div>
                          <span
                            v-if="getFileExtension(box.name) === 'txt'"
                            class="upload_component_icon"
                          >
                            <img
                              class="file-icon"
                              src="@/assets/svg/multiUpload/text.svg"
                              alt=""
                            />
                          </span>
                          <span
                            v-if="getFileExtension(box.name) === 'docx'"
                            class="upload_component_icon"
                          >
                            <img
                              class="file-icon"
                              src="@/assets/svg/multiUpload/word.svg"
                              alt=""
                            />
                          </span>
                          <span
                            v-if="getFileExtension(box.name) === 'png'"
                            class="upload_component_icon"
                          >
                            <img
                              class="file-icon"
                              src="@/assets/svg/multiUpload/png.svg"
                              alt=""
                            />
                          </span>
                          <span
                            v-if="getFileExtension(box.name) === 'pdf'"
                            class="upload_component_icon"
                          >
                            <img
                              class="file-icon"
                              src="@/assets/svg/multiUpload/pdf.svg"
                              alt=""
                            />
                          </span>
                          <span
                            v-if="getFileExtension(box.name) === 'json'"
                            class="upload_component_icon"
                          >
                            <img
                              class="file-icon"
                              src="@/assets/svg/multiUpload/json.svg"
                              alt=""
                            />
                          </span>
                          <span
                            v-if="getFileExtension(box.name) === 'jpg'"
                            class="upload_component_icon"
                          >
                            <img
                              class="file-icon"
                              src="@/assets/svg/multiUpload/jpg.svg"
                              alt=""
                            />
                          </span>
                          <span
                            v-if="getFileExtension(box.name) === 'jpeg'"
                            class="upload_component_icon"
                          >
                            <img
                              class="file-icon"
                              src="@/assets/svg/multiUpload/jpeg.svg"
                              alt=""
                            />
                          </span>
                          <span
                            v-if="getFileExtension(box.name) === 'xlsx'"
                            class="upload_component_icon"
                          >
                            <img
                              class="file-icon"
                              src="@/assets/svg/multiUpload/excel.svg"
                              alt=""
                            />
                          </span>
                        </div>
                        <div style="display: flex; flex-direction: column">
                          <span
                            class="text-container_upload attachment_text_chnat_app"
                            style="
                              font-weight: 700;
                              font-size: 14px;
                              color: #575361;
                              padding: 0 20px 0 0;
                            "
                          >
                            {{ box.name }}
                          </span>
                          <span
                            style="
                              font-weight: 550;
                              font-size: 12px;
                              font-family: 'Opensans';
                              color: #857e95;
                            "
                          >
                            {{ formatFileSize(box.size) }}
                          </span>
                        </div>
                        <span
                          class="closeIconechat"
                          @click="downloadAttachment(box, index)"
                        >
                          <i class="icon-download-regular"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </span>
              </span>
            </span>
          </div>
        </div>

        <!-- <div
          v-for="(message, index) in payload.externalIdenetifier"
          :key="index"
          class="messages"
        ><span>{{ formatRelativeTime(message.createdAt) }}</span>
          <span>{{ message }}</span>
        </div> -->
      </section>
      <div id="messageContainer" style="background-color: red"></div>

      <div class="chantAPP_fileupload">
        <div class="box-attachment-ticket-parent">
          <div
            v-for="(box, index) in filesArrayGet"
            :key="index"
            class="box-attachment-ticket"
          >
            
            <div>
              <span
                v-if="getFileExtension(box.name) === 'txt'"
                class="upload_component_icon"
                ><img
                  class="file-icon"
                  src="@/assets/svg/multiUpload/text.svg"
                  alt=""
              /></span>
              <span
                v-if="getFileExtension(box.name) === 'docx'"
                class="upload_component_icon"
                ><img
                  class="file-icon"
                  src="@/assets/svg/multiUpload/word.svg"
                  alt=""
              /></span>
              <span
                v-if="getFileExtension(box.name) === 'png'"
                class="upload_component_icon"
                ><img
                  class="file-icon"
                  src="@/assets/svg/multiUpload/png.svg"
                  alt=""
              /></span>
              <span
                v-if="getFileExtension(box.name) === 'pdf'"
                class="upload_component_icon"
                ><img
                  class="file-icon"
                  src="@/assets/svg/multiUpload/pdf.svg"
                  alt=""
              /></span>
              <span
                v-if="getFileExtension(box.name) === 'json'"
                class="upload_component_icon"
                ><img
                  class="file-icon"
                  src="@/assets/svg/multiUpload/json.svg"
                  alt=""
              /></span>
              <span
                v-if="getFileExtension(box.name) === 'jpg'"
                class="upload_component_icon"
                ><img
                  class="file-icon"
                  src="@/assets/svg/multiUpload/jpg.svg"
                  alt=""
              /></span>
              <span
                v-if="getFileExtension(box.name) === 'jpeg'"
                class="upload_component_icon"
                ><img
                  class="file-icon"
                  src="@/assets/svg/multiUpload/jpeg.svg"
                  alt=""
              /></span>
              <span
                v-if="getFileExtension(box.name) === 'xlsx'"
                class="upload_component_icon"
                ><img
                  class="file-icon"
                  src="@/assets/svg/multiUpload/excel.svg"
                  alt=""
              /></span>
            </div>
            <div style="display: flex; flex-direction: column">
              <span
                class="text-container_upload"
                style="font-weight: 700; font-size: 14px; color: #575361"
              >
                {{ box.name }} </span
              ><span
                style="
                  font-weight: 550;
                  font-size: 12px;
                  font-family: 'Opensans';
                  color: #857e95;
                "
                >{{ formatFileSize(box.size) }}</span
              >
            </div>
            <span class="closeIconeSupplier" @click="handleClose(box, index)"
              ><i class="icon-times-regular"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
    <section class="prompt-tool-container">
      <div class="prompt-input-wrapper">
        <span class="email_link">
          <i
            style="font-size: 18px"
            class="icon-paperclip-regular"
            @click="triggerFileInput"
          >
          </i>

          <input
            id="fileUpload"
            ref="fileInput"
            autocomplete="off"
            type="file"
            :accept="fileType"
            name="fileUpload[]"
            style="display: none"
            multiple
            @change="chooseFiles"
          />
        </span>
        <textarea
          id="gen-ai-prompter"
          ref="promptTextarea"
          v-model="userPrompt"
          placeholder="Write your response for the issue"
          name="gen-ai-prompt-tool"
          :height="promptRowHeight"
          cols="50"
          class="prompt-textarea"
          :disabled="isPromptProcessing"
          @keydown.enter.prevent
          @input.self="handlePromptResize"
          @keyup.enter="processThePrompt(userPrompt, dummy)"
          @click="scrollToBottom"
        />
        <i
          class="icon-send-right-regular send-icon"
          @click.stop="processThePrompt(userPrompt, dummy)"
        ></i>
      </div>
    </section>
  </main>
</template>

<script>
// import attachment  from '@/components/uploadFileComponent.vue';
import addressComponent from "@/components/CM/autocompleteComponent.vue";
import attachment from "@/components/singleEmailComponent.vue";

import { UPCAxiosInstance, MLTAxiosInstance } from "@/config/axiosConfig";
export default {
  components: {
    addressComponent,
    attachment,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    view: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeChat"],
  data() {
    return {
      backgrounStyleuploadSupplier: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#fff",
        TextfontSize: 16,
      },
      componentKeyM: 0,
      // componentKeyM:0,
      attachmnetComponetKey: 0,
      editPayload: {},
      assignedToo: [],
      contactPayload: [],
      userPrompt: "",
      excludeFields: ["Status", "Priority level", "Subject","Requester", "Ticket category"],
      displayChat: false,
      payload: {},
      messages: [],
      labelFieldsValue: [],
      copytemplatePayload: [],
      templatePayload: [],
      isModal: false,
      dummy: [],
      filesArrayGet: [],
      filesArray: [],
      fileType: ".json,.xlsx,.doc,.pdf,.txt,.jpg,.jpeg,.png",
      fileSizeLimit: "25MB",
      profilePicturePrevalue: "",
    };
  },
  computed: {
    transformedSpecification() {
      // Filter out the troubleTicketSpecification from the payload
      const spec = this.payload.troubleTicketSpecification || [];

      // Transform and filter out attachment types and process other fields
      return spec.map((item) => {
        // Filter out attachment type objects and handle other fields
        const filteredItem = Object.fromEntries(
          Object.entries(item)
            .filter(
              ([key]) =>
                ![
                  "Status",
                  "Priority level",
                  "Subject",
                  "Description",
                ].includes(key)
            ) // Exclude specific keys
            .filter(([value]) => {
              if (Array.isArray(value)) {
                // Filter out attachments from arrays
                const filteredArray = value.filter((subItem) => {
                  if (typeof subItem === "object" && subItem !== null) {
                    return subItem["@type"] !== "attachment";
                  }
                  return true;
                });
                return filteredArray.length > 0; // Include the key if there are remaining values
              }
              // Include the key-value pair if it's not an attachment type object
              return !(
                typeof value === "object" &&
                value !== null &&
                value["@type"] === "attachment"
              );
            })
        );

        // Dynamically transform arrays of strings into tagcount objects
        Object.keys(filteredItem).forEach((key) => {
          if (Array.isArray(filteredItem[key])) {
            if (filteredItem[key].every((val) => typeof val === "string")) {
              // If the array consists of strings, transform them to tagcount objects
              filteredItem[key] = filteredItem[key].map((value) => ({
                Name: value,
                "@type": "tagcount",
              }));
            }
          }
        });

        return filteredItem;
      });
    },
    filteredData() {
      if (!this.payload || !this.payload.troubleTicketSpecification) {
        console.error("Payload or troubleTicketSpecification is missing.");
        return [];
      }

      return this.payload.troubleTicketSpecification.map((item) => {
        // Filter out excluded fields
        const filteredItem = Object.keys(item)
          .filter(
            (key) => !this.excludeFields || !this.excludeFields.includes(key)
          )
          .reduce((obj, key) => {
            if (key === "Tags") {
              // Check if item[key] is an array before mapping
              obj[key] = Array.isArray(item[key])
                ? item[key].map((tag) => ({ name: tag }))
                : [];
            } else {
              obj[key] = item[key];
            }
            return obj;
          }, {});

        console.log("Filtered Item:", filteredItem);
        return filteredItem;
      });
    },
    mandatoryFields() {
      return this.labelFieldsValue
        .filter((field) => field.mandatory)
        .map((field) => field.name);
    },
    areMandatoryFieldsFilled() {
      return this.mandatoryFields.every(
        (field) => this.payload.troubleTicketSpecification[0][field] !== ""
      );
    },
    isEmailValid() {
      if (
        this.payload &&
        this.payload.troubleTicketSpecification[0] &&
        this.payload.troubleTicketSpecification[0]["Email id"]
      ) {
        const email = this.payload.troubleTicketSpecification[0]["Email id"];
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      } else {
        return true;
      }
    },
    // Validate the entire form
    isFormValid() {
      return this.areMandatoryFieldsFilled && this.isEmailValid;
    },
  },
  watch: {
    filesArray: {
      handler(val) {
        console.log(val, "filesArray");

        // Enforce the 10-file limit
        if (val.length > 10) {
          this.err_msg = "You can only upload up to 10 files.";
          // Trim the array to only keep the first 10 files
          this.filesArray = val.slice(0, 10);
        }

        // Update filesArrayGet based on the trimmed filesArray
        this.filesArrayGet = this.filesArray;
        console.log(this.filesArrayGet, this.dummy, "filesArrayGet");
      },
      immediate: true,
      deep: true,
    },
    preValue: {
      handler(val) {
        console.log(val, this.dummy, "filesarray");

        if (val && Array.isArray(val) && val.length > 0) {
          this.filesArrayGet = val;
        } else {
          console.log("Invalid value detected:", val);
          // Handle the error as needed, such as setting filesArrayGet to an empty array or another default value
          // this.filesArrayGet = [];
        }

        console.log(this.filesArrayGet, "filesArrayGet");
      },
      immediate: true,
      deep: true,
    },

    view: {
      handler(val) {
        this.displayChat = val;
        console.log(val, "view");
      },
      immediate: true,
      deep: true,
    },
    data: {
      handler(val) {
        this.payload = JSON.parse(JSON.stringify(val));
        this.editPayload = JSON.parse(JSON.stringify(val));
        console.log(val, "data");
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    try {
      // eslint-disable-next-line vue/no-async-in-computed-properties
      const response = await UPCAxiosInstance.get(`/ticketSpec`);
      this.copytemplatePayload = response.data.sort(
        (a, b) => a.priority - b.priority
      );
      this.labelFieldsValue = response.data.sort(
        (a, b) => a.priority - b.priority
      );
      let obj = {};
      this.labelFieldsValue.forEach((item) => {
        obj[item.name] = "";
      });
      this.payload.troubleTicketSpecification.push(obj);
      this.duplicateLabelValues = JSON.parse(
        JSON.stringify(this.labelFieldsValue)
      );
      console.log("this payload", this.payload);
    } catch (error) {
      console.error("Error fetching autocomplete data:", error.message);
    }
    const response = await UPCAxiosInstance.get(`/templateTicket`);

    this.templatePayload = response.data;
    const contactpayload = await MLTAxiosInstance.get(
      `/partymanagement/individual/listuser?company=${this.$cookies.get(
        "company"
      )}`
    );
    this.contactPayload = await contactpayload.data.filter(
      (item) => item["@baseType"] === "customer"
    );
    const asignee = await MLTAxiosInstance.get(
      "partymanagement/individual/auth"
    );
    this.user = asignee.data;
    this.companyasignee = asignee.data.company;

    const instance = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${asignee.data.userId}?company=${asignee.data.company}`
    );
    this.instanceData = instance.data;
    if (this.instanceData) {
      const profileAttachment = this.instanceData.attachment.find(
        (att) => att.attachmentType === "profile"
      );

      // Get the name if it exists
      const profileName = profileAttachment ? profileAttachment.name : null;
      let uploadedContent = await UPCAxiosInstance.get(
        "/gcpupload/files/" + profileName,
        { responseType: "arraybuffer" }
      );
      this.profilePicture = profileName;
      console.log("🚀 ~ mounted ~ uploadedContent:", uploadedContent);
      this.profilePicturePrevalue = await this.bufferToDataURL(
        uploadedContent.data
      );
    }
    const fulltableData = {
      company: `${asignee.data.company}`,
      type: "filter-search",
      module: "hierarchycontacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 100,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: {
        "role.name": "Upc admin",
      },
      searchQuery: "",
    };
    const fulltabledata = await MLTAxiosInstance.post(
      `/util/filterData?company=${asignee.data.company}`,
      fulltableData
    );
    this.assignedToo = fulltabledata.data.data;
    this.assignedToo.forEach((item) => {
      console.log((item.newRole = item.role[0].name));
    });
    console.log(fulltabledata, "fulltabledatafulltabledata");
    this.payload.troubleTicketSpecification[0]["Assigned to"] =
      this.instanceData.name;
  },
  methods: {
    convertTime(val) {
      const utcDate = new Date(val);

      // Automatically get the user's system timezone
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Convert to local time without seconds
      const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: userTimeZone, // Dynamically use the user's timezone
      };

      const localTime = utcDate.toLocaleString("en-US", options);
      return localTime;
    },
    scrollToBottom() {
      console.log("scrollToBottom");

      this.$nextTick(() => {
        const element = document.getElementById("messageContainer");
        if (element) {
          // Scroll smoothly to the target element
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    getFileTypeFromMimeType(mimeType) {
      // Map MIME types to file types
      const mimeTypeMap = {
        "image/jpeg": "JPEG",
        "image/png": "PNG",
        "application/pdf": "PDF",
        "text/plain": "TXT",
        // Add more MIME types as needed
      };

      // Lookup the file type based on the MIME type
      const fileType = mimeTypeMap[mimeType] || "Unknown";

      // Convert the file type back to MIME type format if found, otherwise return the original MIME type
      return fileType !== "Unknown"
        ? `application/${fileType.toLowerCase()}`
        : mimeType;
    },
    async downloadFromGCP(urlFetch, name) {
      console.log(urlFetch, name);
      const type = this.getFileTypeFromMimeType(name);
      const blob = new Blob([urlFetch], { type: type });
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element
      const a = document.createElement("a");
      a.href = url;
      a.download = name; // Set the filename

      // Append the anchor element to the DOM
      document.body.appendChild(a);

      // Trigger the download
      a.click();
      // const response = await fetch(urlFetch);
      // const fileData = await response.blob(); // Assuming the file is in binary format

      // const blob = new Blob([fileData]);

      // const url = URL.createObjectURL(blob);

      // const link = document.createElement("a");
      // link.href = url;
      // link.download = name;

      // document.body.appendChild(link);
      // link.click();

      // URL.revokeObjectURL(url);
      // document.body.removeChild(link);
    },
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    async downloadAttachment(box, index) {
      console.log(box, index, "downloadAttachment");
      const url = await UPCAxiosInstance.get(`/gcpupload/files/${box.name}`, {
        responseType: "arraybuffer",
      });
      this.downloadFromGCP(url.data, box.name);
    },
    isTagCountArray(arr) {
      return (
        Array.isArray(arr) &&
        arr.every((subItem) => subItem && subItem["@type"] === "tagcount")
      );
    },
    isAttachmentArray(arr) {
      return (
        Array.isArray(arr) &&
        arr.some((subItem) => subItem && subItem["@type"] === "attachment")
      );
    },
    addressPreValue(val) {
      let addressPreVal = {};
      if (val) {
        addressPreVal = val;
      } else {
        addressPreVal = {
          mediumType: "",
          characteristic: {
            city: "",
            country: "",
            postCode: "",
            stateOrProvince: "",
            street1: "",
            street2: "",
            description: "",
            address1: "",
            default: false,
            "@type": "",
            preferred: false,
            postalCode: "",
            unit: "",
            state: "",
          },
        };
      }
      return addressPreVal;
    },
    receivedAddressData(val, name) {
      console.log(val, name, "val, name");
      try {
        // Ensure the target property is an array

        // If val is not empty, add it to the array
        if (val) {
          this.editPayload.troubleTicketSpecification[0][name] = val;
        }

        console.log(
          val,
          "attachmentEmit",
          this.editPayload.troubleTicketSpecification[0][name]
        );
      } catch (error) {
        console.error("An error occurred in attachmentEmit:", error);
        // You can add further error handling logic here, like setting a fallback value or notifying the user
      }
    },
    async convertData(event) {
      console.log(event, "convertData");
      let formData = new FormData();
      for (let i = 0; i < event.length; i++) {
        formData.append("files[]", event[i]);
      }
      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      console.log(data, "formDataconvert");
    },
    removedEmit(val, payload) {
      this.editPayload.troubleTicketSpecification[0][payload] = val;
      console.log(
        this.editPayload.troubleTicketSpecification[0][payload],
        this.editPayload.troubleTicketSpecification[0]
      );
    },
    attachmentEmit(val, name) {
      try {
        // Ensure the target property is an array
        if (
          typeof this.editPayload.troubleTicketSpecification[0][name] ===
          "string"
        ) {
          this.editPayload.troubleTicketSpecification[0][name] = [
            this.editPayload.troubleTicketSpecification[0][name],
          ];
        } else if (
          !Array.isArray(this.editPayload.troubleTicketSpecification[0][name])
        ) {
          this.editPayload.troubleTicketSpecification[0][name] = [];
        }

        // If val is not empty, add it to the array
        if (val) {
          this.editPayload.troubleTicketSpecification[0][name].push(val);
        }

        // Remove any empty values from the array
        this.editPayload.troubleTicketSpecification[0][name] =
          this.editPayload.troubleTicketSpecification[0][name].filter(
            (item) => item
          );

        console.log(
          val,
          "attachmentEmit",
          this.editPayload.troubleTicketSpecification[0][name]
        );
      } catch (error) {
        console.error("An error occurred in attachmentEmit:", error);
        // You can add further error handling logic here, like setting a fallback value or notifying the user
      }
    },
    formatFileSize(bytes) {
      console.log(bytes);
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
    handleClose(val, index) {
      console.log(val, index);
      this.filesArrayGet.splice(index, 1);
      this.dummy.splice(index, 1);
    },
    getFileExtension(filename) {
      // console.log(filename, "filename");
      if (filename != undefined && filename != "") {
        const parts = filename.split(".");
        const fileExtension = parts[parts.length - 1];
        return fileExtension;
      }
    },
    isValidFileType(file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return this.fileType.split(",").includes("." + fileExtension);
    },
    isValidFileSize(file) {
      const fileSize = file.size;
      const maxSizeInBytes = parseInt(this.fileSizeLimit) * 1024 * 1024;
      return fileSize <= maxSizeInBytes;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    chooseFiles(event) {
      const files = event.target.files;
      let errorMessages = [];
      const currentTime = new Date();

      // Calculate the current total size of files already in filesArray
      let totalSize = this.filesArray.reduce((acc, file) => acc + file.size, 0);

      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          // Validate file type
          if (!this.isValidFileType(file)) {
            errorMessages.push(
              `Invalid file type: ${file.name}. Allowed types: ${this.fileType}.`
            );
            continue; // Skip invalid file type
          }

          // Validate file size and total size limit
          if (totalSize + file.size > this.fileSizeLimitInBytes()) {
            errorMessages.push(
              `Adding file '${file.name}' exceeds total size limit of ${this.fileSizeLimit}.`
            );
            break; // Stop processing further files
          }

          // Add valid file to the filesArray and update total size
          this.filesArray.push(file);
          totalSize += file.size;

          // Emit updated filesArray
          // this.$emit('fileuploaddata', this.filesArray);

          // Process and read file
          this.processFile(file, currentTime);
        }

        // Show error messages (if any)
        if (errorMessages.length > 0) {
          this.err_msg = errorMessages.join(" ");
        }

        // Clear file input
        event.target.value = "";
      }
    },
    processFile(file, currentTime) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileInfo = {
          name: file.name,
          size: file.size,
          lastUploaded: currentTime,
          "@type": "attachment",
        };

        // Add to dummy array
        this.dummy.push(fileInfo);
      };

      reader.readAsText(file);
    },
    fileSizeLimitInBytes() {
      const size = parseInt(this.fileSizeLimit);
      const unit = this.fileSizeLimit.replace(/[0-9]/g, "").toUpperCase();

      switch (unit) {
        case "KB":
          return size * 1024;
        case "MB":
          return size * 1024 * 1024;
        case "GB":
          return size * 1024 * 1024 * 1024;
        default:
          return size; // Assume bytes if no unit is provided
      }
    },
    async editedTicket(id, payload) {
      let updatedAdditionalChargeLogPayload = {
        message: `${this.editPayload.troubleTicketSpecification[0]["Ticket category"]} ticket has been updated`,
        "@type": "tickets",
        detailedAction: [],
        category: ["tickets"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        action: "UPDATED",
      };
      Object.keys(this.editPayload.troubleTicketSpecification[0]).forEach(
        (key) => {
          const currentValue =
            this.editPayload.troubleTicketSpecification[0][key];
          const initialValue = this.payload.troubleTicketSpecification[0][key];

          if (Array.isArray(currentValue)) {
            
             if (typeof currentValue[0] === "object") {
              // Case: Array of objects
              updatedAdditionalChargeLogPayload.detailedAction = [
                ...updatedAdditionalChargeLogPayload.detailedAction,
                ...this.buildLogBasedOnDifferencesForArrayOfObjects(
                  initialValue,
                  currentValue,
                  "name",
                  key
                ),
              ];
            } else if (typeof currentValue[0] === "string") {
              // Case: Array of strings
              updatedAdditionalChargeLogPayload.detailedAction = [
                ...updatedAdditionalChargeLogPayload.detailedAction,
                ...this.findArrayOfStringDifferences(
                  initialValue,
                  currentValue,
                  key
                ),
              ];
            }
          }  else if (typeof currentValue[0] === "object" && currentValue[0].characteristic &&
            'description' in currentValue[0].characteristic) {
              // Case: Array of objects
              updatedAdditionalChargeLogPayload.detailedAction = [
                ...updatedAdditionalChargeLogPayload.detailedAction,
                ...this.buildBasicLogByDifferences(
                  initialValue.characteristic,
                  currentValue.characteristic,
                  "description",
                  key
                ),
              ];
            } else {
            // Default case: Handle basic log for non-array types
            updatedAdditionalChargeLogPayload.detailedAction = [
              ...updatedAdditionalChargeLogPayload.detailedAction,
              ...this.buildBasicLogByDifferences(
                this.payload.troubleTicketSpecification[0],
                this.editPayload.troubleTicketSpecification[0],
                key,
                key
              ),
            ];
          }
        }
      );
    
    
    
      if (this.editPayload.troubleTicketSpecification[0]["Priority level"]) {
        this.editPayload["priority"] =
          this.editPayload.troubleTicketSpecification[0]["Priority level"];
      }
      if (this.editPayload.troubleTicketSpecification[0]["Status"]) {
        this.editPayload["status"] =
          this.editPayload.troubleTicketSpecification[0]["Status"];
      }
      // this.editPayload['priority'] = 'High'
      if (this.editPayload.troubleTicketSpecification[0]["Ticket category"]) {
        this.editPayload["name"] =
          this.editPayload.troubleTicketSpecification[0]["Ticket category"];
      }
      const patchTicket = await UPCAxiosInstance.patch(
        "/troubleTicket",
        this.editPayload
      );
      console.log("Payload", payload, this.editPayload);
      console.log("patchTicket", patchTicket);
      console.log(id, "id", this.editPayload.troubleTicketSpecification[0]);
      this.payload = patchTicket.data;
      this.isModal = false;
      this.editMode = false;

      if (patchTicket) {
        this.notification = {
          type: "success",
          message: `Nice work! Your new ticket is now in the list!`,
        };
      }
      this.initLog(updatedAdditionalChargeLogPayload);
    },
    selectedValue($event, name) {
      const selectedValue = $event.map((item) => item.value);
      this.editPayload.troubleTicketSpecification[0][name] = selectedValue;
    },
    RelatedPArtyData(val) {
      console.log(val, "RelatedPArtyData");
      if (!this.editPayload.troubleTicketSpecification) {
        this.editPayload.troubleTicketSpecification = [];
      }
      if (!this.editPayload.troubleTicketSpecification[0]) {
        this.editPayload.troubleTicketSpecification[0] = {};
      }
      if (!this.editPayload.relatedParty) {
        this.editPayload.relatedParty = [];
      }

      // Ensure there's an object at the 0th index
      if (!this.editPayload.relatedParty[0]) {
        this.editPayload.relatedParty[0] = {};
      }

      this.editPayload.troubleTicketSpecification[0]["Email id"] =
        val?.contactMedium[0]?.characteristic?.emailAddress;
      // Now, safely update the properties
      this.editPayload.relatedParty[0].id = val._id;
      this.editPayload.relatedParty[0].name = val.name;
      this.editPayload.relatedParty[0].role = val.aristocraticTitle;
      this.editPayload.relatedParty[0].company = val.company;
      this.editPayload.relatedParty[0].createdBy = this.$cookies.get("company");
      console.log(this.editPayload, " this.payload this.payload");
    },
    initializeTroubleTicketSpecification() {
      if (!this.editPayload.troubleTicketSpecification) {
        this.editPayload.troubleTicketSpecification = [];
      }
    },
    createTicketSpecification(labelFieldsValue, ticketCategory, payload) {
      console.log(
        labelFieldsValue,
        ticketCategory,
        "createTicketSpecification",
        payload
      );

      // Ensure labelFieldsValue is an array and payload is an object
      if (!labelFieldsValue || !Array.isArray(labelFieldsValue)) {
        console.error("labelFieldsValue is not an array or is undefined");
        return {};
      }

      if (!payload || typeof payload !== "object") {
        console.error("payload is not an object or is undefined");
        return {};
      }

      let obj = {};

      labelFieldsValue.forEach((item) => {
        // Check if payload has a pre-existing value for the current item
        let preValue = payload[item.name] || "";

        if (item["@type"] === "Dropdown" || item["@type"] === "Radio button") {
          let found = false;
          item.characteristicSpecification.forEach((itemch) => {
            if (itemch.default === true) {
              obj[item.name] = preValue !== "" ? preValue : itemch.value;
              found = true;
            }
          });
          if (!found) {
            obj[item.name] = preValue !== "" ? preValue : "";
          }
        } else if (item["@type"] === "Multi select") {
          let value = [];
          item.characteristicSpecification.forEach((itemch) => {
            if (itemch.default === true) {
              value.push(itemch.value);
            }
          });
          obj[item.name] =
            preValue !== "" ? preValue : value.length > 0 ? value : [];
        } else {
          // If type is not Dropdown, Radio button, or Multi select, use pre-existing value if available
          obj[item.name] = preValue !== "" ? preValue : "";
        }
      });

      // Ensure TicketCategory is set
      obj["Ticket category"] = ticketCategory;

      // Ensure "Assigned to" is set safely
      obj["Assigned to"] = this.instanceData?.name;

      return obj;
    },
    async TicketcategoryData(val) {
      let found = false;
      let payloadData = JSON.parse(
        JSON.stringify(this.editPayload.troubleTicketSpecification[0])
      );

      this.templatePayload.some((item) => {
        if (found) return true;
        if (
          val.value === item.category[0]?.name &&
          item.lifecycleState === "Active"
        ) {
          this.labelFieldsValue = item.fields;
          this.labelFieldsValue.sort((a, b) => a.priority - b.priority);

          found = true;
          try {
            this.initializeTroubleTicketSpecification();
            this.editPayload.troubleTicketSpecification[0] =
              this.createTicketSpecification(
                this.labelFieldsValue,
                val.value,
                payloadData
              );
            console.log("troubleTicketSpecification", this.editPayload);
          } catch (error) {
            console.error("Error fetching autocomplete data:", error);
          }
          return true;
        } else if (val.value !== item.category[0]?.name) {
          this.labelFieldsValue.sort((a, b) => a.priority - b.priority);
          try {
            this.initializeTroubleTicketSpecification();
            this.editPayload.troubleTicketSpecification[0] =
              this.createTicketSpecification(
                this.labelFieldsValue,
                val.value,
                payloadData
              );
            console.log("troubleTicketSpecification", this.editPayload);
          } catch (error) {
            console.error("Error fetching autocomplete data:", error);
          }
        }
        return false;
      });
    },
    editTicketFiled() {
      this.isModal = true;
      this.editMode = true;
      this.componentKeyM += 1;
      let found = false;
      this.templatePayload?.map((item) => {
        if (found) return;
        if (
          this.payload?.troubleTicketSpecification[0]["Ticket category"] ===
            item.category[0]?.name &&
          item.lifecycleState === "Active"
        ) {
          this.labelFieldsValue = item?.fields.sort((a, b) => a.priority - b.priority);
          found = true;
        } else {
          this.labelFieldsValue = this.copytemplatePayload.sort((a, b) => a.priority - b.priority);
        }
      });

      // this.payload = JSON.parse(JSON.stringify(ele));
      // console.log( this.payload, "closeCrudContextMenu", i);
      this.editPayload = JSON.parse(JSON.stringify(this.payload));
      console.log("closeCrudContextMenu", this.payload);
      // this.$router.pus h(`/SM/supplier/edit`);
    },
    isDate(value) {
      const date = new Date(value);
      return (
        !isNaN(date.getTime()) &&
        typeof value === "string" &&
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)
      );
    },
    shouldDisplayDate(index) {
      const currentMessage = this.payload.externalIdenetifier[index];
      const previousMessage = this.payload.externalIdenetifier[index - 1];

      // Handle the case where there is no previous message
      if (!currentMessage) return false;
      if (index === 0) return true; // Always show for the first message

      const currentDate = new Date(
        currentMessage.createdAt
      ).toLocaleDateString();
      const previousDate = previousMessage
        ? new Date(previousMessage.createdAt).toLocaleDateString()
        : null;

      return currentDate !== previousDate;
    },

    formatRelativeTime(timestamp) {
      const now = new Date();
      const date = new Date(timestamp);

      const diffInTime = now - date;
      const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));

      // Check if the date is today
      if (now.toDateString() === date.toDateString()) {
        return "Today";
      }

      // Check if the date is yesterday
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      if (yesterday.toDateString() === date.toDateString()) {
        return "Yesterday";
      }

      // Check if the date is within this week
      if (diffInDays < 7) {
        return date.toLocaleDateString("en-US", { weekday: "long" });
      }

      // If the year is the same, show month and day
      if (date.getFullYear() === now.getFullYear()) {
        return date.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
        });
      }

      // Otherwise, return full date with year
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    preValFunc(payload, spechfiled) {
      // console.log("----------------------------------",payload,"--------------------", spechfiled,"--------------------------------------------------------------------");
      if (payload != undefined && payload != "" && payload.length > 0) {
        console.log("payload log");
        payload;
      } else {
        spechfiled.characteristicSpecification.forEach((item) => {
          if (item.default == true) {
            console.log("defalt lofg--- log");
            payload = item.value;
          }
        });
      }
      // console.log(payload,"payloadpayload----------------------------");
      return payload;
    },
    preValFunction(payload, spechfiled) {
      console.log(payload, spechfiled, "@......payload, spechfiled...........");
      if (payload.length > 0) {
        console.log("payload log");
        payload;
      } else {
        let value = [];
        let count = 0;
        spechfiled.characteristicSpecification.forEach((item) => {
          if (item.default == true) {
            count = 1;
            value.push(item.value);
            // value= item.value
          }
        });
        if (count == 0) {
          value = [];
        }

        payload = value;
      }
      console.log(payload, "asdfghiop[jhgfxfghjkl;lkjhgfddfghjkl;lkjhgcxxghj]");
      return payload;
    },
    async getvaluearr(event) {
      console.log(event, "eventevent");
      let formData = new FormData();
      for (let i = 0; i < event.length; i++) {
        formData.append("files[]", event[i]);
      }
      console.log("formData", formData.getAll("files[]"));
      await UPCAxiosInstance.post(`/gcpMultiple/v2/upload/`, formData);
    },
    async processThePrompt(userPrompt, attachment) {
      if (this.userPrompt || this.dummy.length > 0) {
        if (this.filesArrayGet.length > 0) {
          this.getvaluearr(this.filesArrayGet);
        }
        this.payload?.externalIdenetifier?.push({
          role: "admin Team",
          message: this.userPrompt,
          attachment: this.dummy,
          updatedAt: new Date().toJSON(),
          createdAt: new Date().toJSON(),
        });
        this.adminTeamMessage = "";
      }
      const patchTicket = await UPCAxiosInstance.patch(
        "/troubleTicket",
        this.payload
      );
      console.log(
        userPrompt,
        patchTicket,
        attachment,
        "userPrompt",
        this.messages
      );
      this.userPrompt = "";
      (this.dummy = []), (this.filesArrayGet = []), (this.filesArray = []);
      this.scrollToBottom();
    },
    closeChat() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">

.chantAPP_fileupload {
  .text-container_upload {
    font-weight: 700;
    font-size: 14px;
    color: #575361;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 17rem; /* Responsive width */
  }

  .box-attachment-ticket-parent{
    overflow-y: auto;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .box-attachment-ticket{
      position: relative;
      width: 402px !important;/* Full width for small screens */
      max-width: 400px; /* Max width for larger screens */
      display: flex;
      border: 1px solid #d4d4d4;
      border-radius: 6px;
      padding: 6px 8px;
      margin: 10px 0;
      align-items: center;
      gap: 20px;

      .upload_component_icon {
        height: 40px;
        display: flex;
        align-items: center;

        .file-icon {
          height: 28px;
          width: 32px;
        }
      }
    }
  }

}
@media screen and (max-width: 768px) {
.text-container_upload{
  width: 10rem !important;
}

}
.attachment_text_chnat_app{
  width: 17rem !important;
  
}
.chantAPP_fileupload_attachment {
  position: relative;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 6px;
  width: 100%; /* Make the width responsive */
  max-width: 600px; /* Set a maximum width for larger screens */
  background-color: rgba(84, 189, 149, 0.06);

  .text-container_upload {
    font-weight: 700;
    font-size: 14px;
    color: #575361;
    white-space: nowrap; /* Keep text on one line */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis;
    width: 10rem; /* Add ellipsis for overflowed text */
  }

  .box-attachment-supplier-parent {
    overflow-y: auto;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .box-attachment-supplier {
      position: relative; /* Set relative positioning */
      width: 100%; /* Take full width on small screens */
      max-width: 400px; /* Limit the width for larger screens */
      display: flex;
      align-items: center;
      border: 1px solid #d4d4d4;
      border-radius: 6px;
      padding: 10px; /* Padding adjusted */
      margin: 10px 0;
      gap: 10px; /* Space between elements */

      .upload_component_icon {
        height: 40px;
        display: flex;
        align-items: center;

        .file-icon {
          height: 28px;
          width: 32px;
        }
      }

      .closeIconechat {
        position: absolute;
        top: 50%; /* Center vertically */
        right: 10px; /* Align 10px from the right */
        transform: translateY(-50%); /* Adjust vertical position */
        cursor: pointer;
        color: #6e6b7b;
      }
    }
  }
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {

  .chantAPP_fileupload_attachment {
    // width: 90%; /* Reduce width for tablets */
    padding: 8px; /* Adjust padding */
  }

  .box-attachment-supplier-parent {
    flex-direction: column; /* Stack items on smaller screens */
  }

  .box-attachment-supplier {
    width: 100%; /* Full width for small screens */
  }
}

@media (max-width: 480px) {
  .chantAPP_fileupload_attachment {
    width: 100%; /* Full width for mobile devices */
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .box-attachment-supplier {
    padding: 4px 6px; /* Reduce padding for small screens */
  }
}
.chat_app_profilepic {
  .main-content-body-upload {
    .upload-panel img {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25) !important;
    }
  }
}
.gen-ai-chat-window {
  // margin: 1.5rem;
  margin-bottom: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
 // height: 100vh;
  overflow: hidden;
}

.chat-content {
  padding: 1.5rem;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 87vh;
}

.section {
  width: 100%;
}

.profile-info {
  display: flex;
  gap: 13px;
  width: max-content;
}

.profile-details {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.company-name {
  font-size: 14px;
}

.id-info {
  display: flex;
  width: 300px;
  font-size: 12px;
  color: rgba(93, 93, 93, 1);
}

.id-value {
  color: rgba(166, 166, 166, 1);
  font-size: 12px;
}

.ticket-info {
  margin: 24px 0;
}
.ticket-title-main {
  .ticket-title {
    font-size: 18px;
    padding-top: 24px;
  }
  margin-bottom: 6px;
}
.ticket-description {
  font-size: 12px;
}

.chat-header {
  display: flex;
  gap: 15px;
  align-items: center;
}

.icon-arrow-left-filled {
  font-size: 24px;
}

.chat-subject {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.chat-subject-title {
  width: 100%;
  margin-left: 32px;
  font-size: 10px;
  color: rgba(105, 105, 105, 1);
}

.icon-edit-filled {
  color: rgba(84, 189, 149, 1);
  font-size: 12px;
}

.status-info {
  margin: 10px;
  margin-left: 32px;
}

.status-wrapper {
  display: flex;
  gap: 6px;
  align-items: center;
  /* margin-left: 39px; */
}

.status-text {
  font-size: 14px;
  color: rgba(116, 116, 116, 1);
}

.vl {
  border-left: 1px solid rgba(136, 136, 136, 1);
  height: 17px;
}

.priority-info {
  display: flex;
  gap: 6px;
  align-items: center;
}

.priority-text {
  font-size: 14px;
  color: rgba(116, 116, 116, 1);
}

.priority-wrapper {
  margin: 0.5rem 0;
  display: flex;
  gap: 2px;
}

.priority-high {
  color: rgba(252, 50, 50, 1);
}

.priority-medium {
  color: rgba(239, 201, 0, 1);
}

.priority-low {
  color: rgba(2, 181, 92, 1);
}

.item-info {
  margin-top: -11px;
  margin-left: 34px;
  margin-bottom: 20px;
}

.item-details {
  margin-top: 10px;
}

.item-key {
  display: flex;
  align-items: center;
  gap: 6px;
}

.item-value {
  // width: 600px;
  font-size: 14px;
  color: rgba(116, 116, 116, 1);
}

.tag-wrapper {
  width: 100%;
}

.messages-ticket-chat {
  font-size: 14px;
  margin-bottom: 20px;
  text-align: justify;
  display:block;
  padding: 10px;
  border-radius: 6px;
  // width: max-conten;
  background-color: rgba(84, 189, 149, 0.06);
  position: relative;
  padding-right: 60px;
}
.time-stamp-ticket-chat {
  position: absolute;
  /* top: 80px; */
  right: 0;
  bottom: 0;
  padding: 5px;
  font-size: 10px;
}

.prompt-tool-container {
  width: calc(100% - 120px) !important;
  // display: flex;
  position: fixed;
  bottom: 0;
  left: 100px;
  background: white;
}

.prompt-input-wrapper {
  display: flex;
  align-items: center;
}

.icon-paperclip-regular {
  font-size: 18px;
}

.prompt-textarea {
  width: 100%;
  resize: none;
  border: none;
  outline: none;
}

.send-icon {
  font-size: 18px;
  cursor: pointer;
}

/* Optional: Additional styling for better visuals */
/* .gen-ai-chat-window {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.prompt-tool-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1.5rem;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.prompt-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
} */
.MesssageContainer {
  width: 100%;
  max-width: 100%;
  margin: 0 0;
  padding: 0px;
  // display: flex;
  /* border: 1px solid #ccc;  */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  */

  .headerMessage {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    font-family: "Opensans";
    margin-bottom: 9px;
    color: rgba(102, 102, 102, 1);
  }

  /* .dataMessage {
    text-align: left;
    font-size: 16px; 
    color: #333; 
  } */
}
.user-image {
  width: 25px;
  height: 25px;
  border: solid rgb(255, 255, 255) 4px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25); /* Ensures the image covers the entire circle */
}

.user-image-new {
  width: 25px;
  height: 25px;
  padding-top: 39px;
  /* border: solid black 1px; */
  border-radius: 145%;
  margin-right: 10px;
  -o-object-fit: cover;
  object-fit: contain;
}
.box-attachment-chat-parent-email {
  display: flex;
  gap: 15px;
  // padding: 0 20px;
  // max-height: 220px !important;

  flex-wrap: wrap;

  .box-attachment-supplier {
    position: relative;
    width: 136px;
    display: flex;
    height: auto;
    background-color: #f2f5f8;
    // border: 1px solid #d4d4d4;
    border-radius: 6px;
    padding: 6px 8px 6px 8px;
    // margin: 10px 0 10px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    .closeIconeSupplier {
      position: absolute;
      left: 285px;
      height: 18px;
      color: #a6a6a6;
      cursor: pointer;
    }
    .upload_component_icon {
      height: 20px;
      align-items: center;
      display: flex;
      .file-icon {
        height: 20px;
        width: 20px;
      }
    }
  }
}
@media (max-width: 768px) {
  .prompt-tool-container {
    width: 80% !important;
  }
}
</style>