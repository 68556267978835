<template>
    <hlx-alert-notification :notify="notification" auto-close />
    <div v-show="impersonite == true"><banner @exit="exitView()" /></div>
    <div class="app-layout" style="display:none">
      <section class="right-panel">
        <div class="layout-basic">
          <div class="layout-breadcrumb">
            <hlx-breadcrumb :items="items" @path="pathTo" />
          </div>
  
          <div class="layout-header"><p>Logged user</p></div>
  
          <div class="layout-body" style="overflow: hidden;">
  
            <div class="grid">
              <span class="grid-left">
                <p class="title">User</p>
                <p class="info">Update your user details</p>
              </span>
  
              <span class="grid-right">
                <span class="profile-with-info --2way">
                  <div class="profile-picture-preview">
                    <hlx-file-upload
                      :file-type="'.jpg,.jpeg,.png'"
                      :file-size-limit="'20mb'"
                      :uploadtype="'Rectangle'"
                      :style-background="backgrounStyleupload"
                      :height="100"
                      :width="100"
                      :read-only="false"
                      :custom-icon="icon"
                      :type="'profile-upload'"
                      :name-display="organisationData.tradingName"
                      :profile-pic-preview="profilePicturePrevalue"
                      :content="'Import'"
                      @native-data="handleProfilePictureUpload"
                    />
                  </div>
                  <div class="input-wrapper">
                    <hlx-input
                      :label-animation="true"
                      type="text"
                      :pre-val="organisationData.name"
                      :read-only="true"
                      label-value="User name"
                    />
                    <hlx-input
                      :label-animation="true"
                      type="email"
                      :pre-val="organisationData.email"
                      :read-only="true"
                      label-value="Email"
                    />
                  </div>
                </span>
                <span class="grid-right-alignment">
                  <!-- {{ orgPhone }} -->
                  <hlx-input
                    v-model:value="phoneNumber"
                    :pre-val="orgPhone.value"
                    type="phonenumber"
                    :country-code="orgPhone.country"
                    :label-value="'Phone no.'"
                    :label-animation="true"
                    :display-error="isPhoneNumberValidationFailed"
                    :custom-error="isPhoneNumberValidationFailed"
                    :custom-error-message="'Please fill the field'"
                    @focus-out="focusOutName"
                    @at-input="handlePhoneNumberChange"
                  ></hlx-input>
                </span>
                <span class="grid-right-alignment">
                  <addressComponent
                    :pre-val="addressData"
                    @address-data="handleAddressChange"
                  />
                </span>
                <div class="decision-name-wrapper" style="margin-top: 20px">
            <!-- {{newConPayload.role}} -->
            <hlx-select
              :key="addressKey"
              :pre-value="organisationData.languageAbility[0].name"
              :options="langList"
              :prop-value="'name'"
              :label="'name'"
              :placeholder-value="'Default Language'"
              :label-animation="true"
              :clearable="false"
              @selected-value="selectedData"
            ></hlx-select>
          </div>
  
              </span>
            </div>
          </div>
  
          <div class="layout-footer">
            <div class="footer-container">
              <hlx-button class="secondary sm" @click="back()">
                <!-- Back -->
                 {{matchFromStatic('Back')}}
              </hlx-button>
              <hlx-button
              v-if="permisstionData.edit"
                style="margin-left: 0px !important"
                class="primary sm"
                @click="updateInfo"
                >
                <!-- Save -->
                 {{ matchFromStatic('Save') }}
                </hlx-button
              >
            </div>
          </div>
        </div>
      </section>
    </div>
    <section style="display: flex">
    <section class="lead-overview-left-panel responsiveSM">
      <div class="layout-breadcrumb" style="margin-bottom:37px">
        <hlx-breadcrumb
            :items="getBreadcrumbs"
            :label-value="loadStaticData('LANG-0019')"
             @path="pathTo" />
        <!-- <hlx-breadcrumb
          v-if="view === false"
          :items="getBreadcrumbs"
          @path="goTo()"
        /> -->
      </div>
      <span style="margin-top: 25px">
        <leftPanel
        :key="keyLeft"
          :side-bar-data="leftPanelData"
          @value-name="selectedValueFunc"
        ></leftPanel>
      </span>
    </section>
    <section
      class="--layout-inner-right-portion --RL70 responsiveSM-right-portion"
      style="
        padding: 1.8em 35px;
        width: calc(100% - 220px);
        padding-left: 0px;
      "
    >
      <div class="decision-info-right-pannel">
        <div class="layout-breadcrumb expanded-side-panel">
          <hlx-breadcrumb
            v-if="view === true"
            :label-value="loadStaticData('LANG-0019')"
            :items="getBreadcrumbs"
            @path="goTo()"
          />
          <hlx-breadcrumb
            v-if="view === false"
            :label-value="loadStaticData('LANG-0019')"
            :items="getBreadcrumbs"
            @path="goTo()"
          />
        </div>
        <div
          class="body-container-center"
          style="height: 100%"
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span
              class="expanded-side-panel"
              style="margin-right: 25px"
            >
              <leftPanel
                :side-bar-data="leftPanelData"
                @value-name="selectedValueFunc"
              ></leftPanel>
            </span>
            <div style="display: flex; align-items: center; gap: 15px;width:100%;padding-left:20px">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  font-family: 'Quicksand';
                "
              >
                <div style="display: flex; align-items: center; gap: 12px">
                  <span style="font-weight: bold">
                    <!-- {{ 'User profile'}} -->
                    {{ matchFromStatic('User profile') }}
                  </span>
                </div>
                <div
                  style="color: #a6a6a6; font-size: 12px; width: max-content"
                >
                  <!-- {{ 'Manage your profile'}} -->
                {{matchFromStatic('Manage your profile')}}
                </div>
              </div>
            </div>
            <div
            
              class="go-back"
              style="
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right:20px 
              " 
            >
              <hlx-button v-show="permisstionData.edit" v-if=" view == true" class="secondary sm" @click="cancelFunc"
                >
                <!-- Account link -->
                <!-- {{"Cancel" }} -->
                {{ matchFromStatic('Cancel') }}
                </hlx-button
            >
            <hlx-button
              v-if=" selectedValue == 'Overview' && view == true"
              v-show="permisstionData.edit"
                class="primary sm"
                style="margin-left: 20px"
                @click="updateInfo"
                >
                <!-- Save -->
                <!-- {{ "Save" }}                 -->
                {{ matchFromStatic('Save') }}
                </hlx-button
              >
              <hlx-button
              v-show="permisstionData.edit"
              v-if=" selectedValue == 'Change password' && view == true && validateCurrentPassword == true && validateConfirmPassword == true && validateNewPassword == true && conditionConfirmPassword == true && conditionNewPassword == true"
                class="primary sm"
                style="margin-left: 20px"
                @click="updateInfo"
                >
                <!-- Save -->
                <!-- {{ "Save" }} -->
                {{ matchFromStatic('Save') }}
                </hlx-button
              >
              <hlx-button
              v-show="permisstionData.edit"
              v-else-if="selectedValue == 'Change password' && view==true&&(validateCurrentPassword == false || validateConfirmPassword == false || validateNewPassword == false || conditionConfirmPassword == false || conditionNewPassword == false)"
                class="primary sm"
                :disabled="true"
                style="margin-left: 20px"
                @click="updateInfo"
                >
                <!-- Save -->
                <!-- {{ "Save" }} -->
                {{ matchFromStatic('Save') }}
                </hlx-button
              >
              <hlx-button
              v-show="permisstionData.edit"
               v-if="view==false"
                class="primary sm"
                style="margin-left: 20px"
                @click="openEditPanel"
                >
                <!-- Save -->
                <!-- {{ "Edit" }} -->
                {{ matchFromStatic('Edit') }}
                </hlx-button
              >
            </div>
          </div>
          <div>
            <!--
              v-if="selectedValue.toLowerCase() === 'overview'" -->
        <div class="parent-container-for-login-profile" style="position: relative;">
        <fieldRenderer
              :key="componentKey"
              :layout="'mixed'"
              :schema-payload = "organisationSchema"
              :data-payload = "dataPayload"
              :is-add-clicked = "isAddButtonClicked"
              :is-save-clicked="isSaveButtonClicked"
              :status-list="[
                'Active',
                'Pending',
                'Inactive'
              ]"
              :parent-class-name="'parent-container-for-login-profile'"
              @edited-payload="patchEditedLead"
               @updated-payload="leadWholePayload"
               @password-validate="passwordValidate"
        ></fieldRenderer>
        <i v-if="selectedValue != 'Overview' && validateCurrentPassword == true" class="icon-check-regular" style="position: absolute;color:#54BD95;top:30px;right:0px"></i>
      </div>
          </div>
        </div>
      </div>
    </section>
  </section>
  </template>
  
  <script>
import CryptoJS from "crypto-js";
import banner from "@/components/bannerComponent.vue";
  import axios from "axios";
  import { MLTAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";
  // import places from 'places.js';
  import addressComponent from "@/components/CM/autocompleteComponent.vue";
  import FieldRenderer from "@/components/FieldRenderer.vue";
import leftPanel from '@/components/leftpannelComponent.vue';
  export default {
    name: "AddRoleView",
    components: { banner, addressComponent, leftPanel, FieldRenderer },
    data() {
      return {
        switchDataPayload:{},
        duplicateSchema:{},
        loginEmail:'',
        componentKey:0,
        passwordSchema:{},
        keyLeft:0,
        selectedLanguageName:'',
        permisstionData:{},
        languageOpt:[],
        conditionNewPassword:false,
        conditionConfirmPassword:false,
        validateCurrentPassword:false,
        validateNewPassword:false,
        validateConfirmPassword:false,
        currentPass:'',
        view:false,
        settingsID:'',
        tempOrganizationPayload:null,
      dataPayload:{
        defaultLanguage:"",
        attachment:[],
        firstName:"",
        lastName:"",
        status:"",
        userEmail:"",
        role:"",
        timeZone:""
      },
      duplicatedataPayload:{
        defaultLanguage:"",
        attachment:[],
        firstName:"",
        lastName:"",
        userStatus:"",
        userEmail:"",
        role:"",
      },
      PasswordPayload:{
        newPassword:"",
        currentPassword:"",
        confirmPassword:""
      },
      organisationSchema:{},
      duplicateOrganisationSchema:{},
      selectedValue:'overview',
      leftPanelData:{},
      tempSideBar:{
          main:
          [
    {
        "name": "Overview",
        "value": "overview",
        "icon": "icon-presentation-regular",
        "view": true,
        "active": true,
        "edit": false,
        "delete": false,
        "title": false,
        "add": false,
        "all": false,
        "customName": "Overview"
    },
    {
        "name": "Change password",
        "value": "change_password",
        "icon": "icon-check-circle-regular",
        "view": true,
        "edit": false,
        "delete": false,
        "title": false,
        "add": false,
        "all": false,
        "customName": "Change password",
        "active": false
    }
]
  },
      showDefault:false,
        addressKey:0,
        phoneNumber: null,
        profilePicture: null,
        backgrounStyleupload: {
          backgroundColor: "",
          bacgroundtTextColor: "#A8A8A8",
          border: false,
          borderColor: "#A8A8A8",
          TextfontSize: 16,
        },
        profilePicturePrevalue: "",
        phoneDefaultValue: {
          country: {
            name: "Canada",
            flags: "https://flagcdn.com/ca.svg",
            short: "CA",
            dial_code: "+1",
          },
          value: "",
          valid: true,
        },
        addressData: {
          characteristic: {
            "        @type": "",
            address1: "",
            city: "",
            country: "",
            description: "",
            postCode: "",
            postalCode: "",
            state: "",
            stateOrProvince: "",
            street1: "",
            street2: "",
            timezone: "",
            unit: "",
          },
  
          mediumType: "postalAddress",
        },
        organisationData: {},
        currenttimezone: "",
        timezone: [],
        showAlert: false,
        type: "Success",
        notification: {},
        city: "",
        address: "",
        number: "",
        state: "",
        country: "",
        time: "",
        individualIdentification: [
          { contactNumber: "", country: "", city: "", state: "", address: "" },
        ],
        name: "",
        id: "",
        email: "",
        viewPermission: [],
        editPermission: [],
        impersonitor: "",
        fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
        impersonite: false,
        options: [{ name: "", checked: false, disabled: false }],
        items: [
          { label: "Settings", link: "/" },
          { label: "General", link: "/general" },
        ],
        countries: [
          {
            id: 1,
            flag: "in",
            name: "India",
            value: "India",
            code: "+91",
            checked: false,
          },
          {
            id: 2,
            flag: "ca",
            name: "Canada",
            value: "Canada",
            code: "+1",
            checked: false,
          },
          {
            id: 3,
            flag: "us",
            name: "USA",
            value: "USA",
            code: "+1",
            checked: false,
          },
        ],
      };
    },
  
    computed: {
      filteredCustomFieldSchema() {
      console.log('this.organisationSchema', this.organisationSchema);
      if (this.organisationSchema) {
        const filtered = Object.entries(this.organisationSchema).filter(
          ([, value]) =>
            value.section &&
            value.section.includes(this.selectedValue.toLowerCase())
        );
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);
 
        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);
 
        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });
 
        // Convert back to an object
        const sortedData = Object.fromEntries(entries);
 
        return sortedData;
      } else {
        return {};
      }
    },
      countryWithIDDCode() {
        const defaultSettings = this.$store.getters.getDefaultSettings;
        console.log(
          "🚀 ~ countryWithIDDCode ~ defaultSettings:",
          defaultSettings
        );
        if (
          defaultSettings &&
          Object.prototype.hasOwnProperty.call(defaultSettings, "idd") &&
          Object.prototype.hasOwnProperty.call(defaultSettings, "country") &&
          Object.prototype.hasOwnProperty.call(defaultSettings, "flag") &&
          Object.prototype.hasOwnProperty.call(defaultSettings, "cca2")
        ) {
          return {
            // dial_code:defaultSettings.idd?.root+defaultSettings.idd?.suffixes?.join(""),
            name: defaultSettings?.country,
            short: defaultSettings.cca2,
            flags: defaultSettings.flags.png,
          };
        }
        return this.phoneDefaultValue.country;
      },
      orgAddress() {
        if (
          !this.organisationData ||
          (this.organisationData &&
            Object.keys(this.organisationData).length == 0)
        ) {
          return this.addressData;
        }
        const postalAddress = this.organisationData?.contactMedium?.find(
          (item) => item?.mediumType == "postalAddress"
        );
        if (postalAddress) {
          return postalAddress;
        }
        return this.addressData;
      },
      orgPhone() {
        if (
          !this.organisationData ||
          (this.organisationData &&
            Object.keys(this.organisationData).length == 0)
        ) {
          return { ...this.phoneDefaultValue, country: this.countryWithIDDCode };
        }
        const phoneNumber = this.organisationData?.contactMedium?.find(
          (item) => item?.mediumType == "phone"
        );
        console.log("🚀 ~ orgPhone ~ phoneNumber:", phoneNumber);
        if (phoneNumber) {
          return phoneNumber.characteristic.phoneNumber;
        }
        //
  
        console.log(
          "🚀 ~ orgPhone ~ this.countryWithIDDCode:",
          this.countryWithIDDCode
        );
        // this.phoneDefaultValue.country =
        return { ...this.phoneDefaultValue, country: this.countryWithIDDCode };
      },
    },
    watch: {
        organisationData: {
            handler(value) { 
                if (value && value?.languageAbility && value?.languageAbility?.length >0 && value?.languageAbility[0]?.name) {
                    value.languageAbility[0].name
                } else {
                    this.organisationData.languageAbility = [{
                        name:"English"
                    }]
                }
            },
      immediate: true,
      deep: true,
            
        },
      phoneNumber: {
        handler(value) {
          console.log("🚀 ~ handler ~ value:", value);
          if (value) {
            console.log("🚀 ~ handler ~ value:", value);
            let phoneNumberIndex = this.organisationData.contactMedium?.findIndex(
              (item) => item.mediumType == "phone"
            );
            console.log(
              "🚀 ~ handleAddressChange ~ phoneNumberIndex:",
              phoneNumberIndex
            );
            if (phoneNumberIndex >= 0) {
              this.organisationData.contactMedium[phoneNumberIndex] = {
                mediumType: "phone",
                preferred: true,
                characteristic: { phoneNumber: value },
              };
            } else {
              this.organisationData.contactMedium.push({
                mediumType: "phone",
                preferred: true,
                characteristic: { phoneNumber: value },
              });
            }
          }
        },
      },
    },
    async mounted() {
      // console.log(this.$cookies.get("userId"),'lsls')
      // await this.$store.dispatch("loginTenantDetails");
      const value = await MLTAxiosInstance.get("partymanagement/individual/auth");

      let currLang = JSON.parse(
            localStorage.getItem("userLanguageFromSetting")
          )
      this.selectedLanguageName = currLang[0]?.name?.toLowerCase() ||'english';

      // left panel

      let sampleTheadsd = await MLTAxiosInstance.get(
        `partyRole/get/${value.data.userId}`
      );

      const mergedPayload = sampleTheadsd?.data;

      // console.log(mergedPayload,"mergedPayload");
      this.permisstionsData = mergedPayload.permission.Settings.sections["User profile"].sections ;
        // console.log(this.permisstionsData,"this.permisstionsData",this.tempSideBar);
        

      this.leftPanelData = this.mergeSidebarData(
        this.tempSideBar,
        this.permisstionsData
      );

    //  const tenantPayload = await MLTAxiosInstance.get('/settings')
    //  const languageOptions =  Object.values(tenantPayload.data.languages).map(name => ({ name, english: name }))
    //  console.log(languageOptions,'languages')
      // const settings = await MLTAxiosInstance.get(`settings`)
      // this.settingsID = settings.data._id
      // this.duplicatedataPayload.timeZone = settings.data.currentTimezone
      const individual = await MLTAxiosInstance.get(`/partymanagement/individual/${this.$cookies.get("userId")}`)
      console.log(individual.data.firstName,'data123',individual.data.lastName,individual.data.email,individual.data.status,individual.data)
      this.duplicatedataPayload.attachment = individual.data.attachment
      this.duplicatedataPayload.firstName = individual.data.firstName
      this.duplicatedataPayload.lastName = individual.data.lastName
      this.duplicatedataPayload.userEmail = individual.data.email
      this.loginEmail = individual.data.email
      this.duplicatedataPayload.userStatus = individual.data.status
      this.duplicatedataPayload.defaultLanguage = individual?.data?.languageAbility[0]?.name.toLowerCase()  || "english"
      console.log("this.duplicatedataPayload.defaultLanguage:",this.duplicatedataPayload.defaultLanguage);
      this.duplicatedataPayload.role = individual.data.role;

      let langs = JSON.parse(localStorage.getItem('loginTenantDetailsLocal'))
      const languageOptins = await this.addTranslationsSame(langs?.languageAbility);
      // const languageOptins = await this.addTranslationsSame(this.$store?.getters?.loginTenantDetails?.languageAbility);
      console.log("languageOptins::",languageOptins);
      
      this.languageOpt = languageOptins;
  
     
      
      const schema = await UPCAxiosInstance.get(
      `/customSchema/getSchema?module=${'User Settings'}`
    );
    this.organisationSchema = JSON.parse(JSON.stringify(schema?.data?.schema));
    this.passwordSchema = JSON.parse(JSON.stringify(schema?.data?.schema));
    this.duplicateSchema = JSON.parse(JSON.stringify(schema?.data?.schema));
    this.duplicateOrganisationSchema = JSON.parse(JSON.stringify(schema?.data?.schema));
    this.switchDataPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload)) 
    this.tempOrganizationPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload)) 
    console.log(this.switchDataPayload,'switch')
    this.switchSidebar(this.selectedValue)
    let tenantDetail = JSON.parse(localStorage.getItem('loginTenantDetailsLocal'));
    tenantDetail.languageAbility;
    
    // this.organisationSchema['defaultLanguage'].options =  this.languageOpt 
    console.log(this.organisationSchema,'organisschema')

    let userRes = await MLTAxiosInstance.get(
      `partymanagement/individual/${value.data.userId}?company=${value.data.company}`
        );
        console.log("userRes:",userRes);
      
      this.organisationData = userRes.data;
    },
    methods: {
      switchSidebar(value){
        value
        console.log(this.selectedValue,'prodjdflsd')
        if(this.selectedValue == 'Overview'){
          console.log(this.switchDataPayload,'diufdh')
                this.organisationSchema = JSON.parse(JSON.stringify(this.duplicateOrganisationSchema))
                const filtered = Object.entries(this.organisationSchema).filter(
                            ([, value]) =>
                              value.section &&
                              value.section.includes('overview')
                );   
              const fieldSchema = Object.fromEntries(filtered);
              const entries = Object.entries(fieldSchema);
       
              // Sort based on `others.displayOrder` if it exists
              entries.sort((a, b) => {
                const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
                const orderB = b[1].others?.displayOrder ?? Infinity;
                return orderA - orderB; // Sort ascending
              });
              // Convert back to an object
              const data = Object.fromEntries(entries);

              
              if(data['defaultLanguage']){
                data['defaultLanguage'].options =  this.languageOpt 
            }
              this.organisationSchema = JSON.parse(JSON.stringify(data))
              this.duplicateOrganisationSchema = JSON.parse(JSON.stringify(this.organisationSchema))

              console.log(this.organisationSchema,'dkjnfnkjnskjdkj',this.dataPayload)
              
              Object.values(this.organisationSchema).forEach(item => {
              if (item.others && !item.others.readOnly) { 
                  item.others.readOnly = true;
              }
              });
              if(this.view == true){
                this.organisationSchema = JSON.parse(JSON.stringify(this.duplicateOrganisationSchema))
              }
              this.dataPayload = JSON.parse(JSON.stringify(this.switchDataPayload))

        
            }
           else if(this.selectedValue == 'Change password'){
            this.switchDataPayload = JSON.parse(JSON.stringify(this.tempOrganizationPayload))
            console.log(this.switchDataPayload,'sjdlsdljds')
          this.validateCurrentPassword = false
          this.validateConfirmPassword = false
          this.validateNewPassword = false
          this.dataPayload = JSON.parse(JSON.stringify(this.PasswordPayload))
          console.log(this.passwordSchema,'Password')
          this.passwordSchema = JSON.parse(JSON.stringify(this.duplicateSchema))
          const filtered = Object.entries(this.passwordSchema).filter(
                      ([, value]) =>
                        value.section &&
                        value.section.includes('change password')
          );   
          const fieldSchema = Object.fromEntries(filtered);
          const entries = Object.entries(fieldSchema);
       
              // Sort based on `others.displayOrder` if it exists
              entries.sort((a, b) => {
                const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
                const orderB = b[1].others?.displayOrder ?? Infinity;
                return orderA - orderB; // Sort ascending
              });

             

       
              // Convert back to an object
              const data = Object.fromEntries(entries);
              this.organisationSchema = data
              this.passwordSchema = JSON.parse(JSON.stringify(this.organisationSchema))

              Object.values(this.organisationSchema).forEach(item => {
              if (item.others && !item.others.readOnly) {
                  item.others.readOnly = true;
              }
              });
              if(this.view == true){
                this.organisationSchema = this.passwordSchema
              }
    }
      },
      cancelFunc(){
        this.view = false
        this.switchSidebar(this.selectedValue)
         if(this.selectedValue.toLowerCase() == 'overview'){
             this.switchDataPayload =  JSON.parse(JSON.stringify(this.duplicatedataPayload))
             this.dataPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))
        } else{
             this.dataPayload = {
                newPassword:"",
                currentPassword:"",
                confirmPassword:""
              }
        }
      },
      passwordValidate(val1,val2){
        if(this.selectedValue == 'Change password'){
          if(val2 == 'Enter new password'){
            this.conditionNewPassword = val1

          }
          else if(val2 == 'Confirm password'){
            this.conditionConfirmPassword = val1
          }
        }
      },
      openEditPanel(){
        this.view = true
        this.switchSidebar(this.selectedValue)
      },
      async selectedValueFunc(value,object){
        this.permisstionData = object
       this.selectedValue = value
       if(this.selectedValue == 'Overview'){
         this.organisationSchema = this.duplicateOrganisationSchema
       }else{
        this.organisationSchema = this.passwordSchema
       }
      this.switchSidebar(this.selectedValue)
        console.log(value,'selectedvalue',this.dataPayload)
    },
    patchEditedLead(val){
      this.tempOrganizationPayload = JSON.parse(JSON.stringify(val))
      console.log(this.selectedValue,'selectedValue',val,this.tempOrganizationPayload)
      if(this.selectedValue !=  'Overview' && this.selectedValue.toLowerCase() !=  'overview'){
        this.dataPayload = val
        console.log(val,'test')
        if(this.currentPass !=  this.tempOrganizationPayload?.currentPassword){
             this.currentPass = this.tempOrganizationPayload?.currentPassword
             this.passwordCheck()
        } 
        console.log('newwwww111',this.tempOrganizationPayload.newPassword)
        if(this.tempOrganizationPayload?.newPassword != ''){
          // console.log('newwwww',this.tempOrganizationPayload.newPassword,this.tempOrganizationPayload.currentPassword == this.tempOrganizationPayload.newPassword)
          this.newpasswordCheck()  
      }
      if(this.tempOrganizationPayload?.confirmPassword != '' && this.tempOrganizationPayload && this.tempOrganizationPayload?.confirmPassword){
        if(this.tempOrganizationPayload?.confirmPassword != this.tempOrganizationPayload?.newPassword){
            this.organisationSchema['confirmPassword'].others.passwordErrorValidation.content = 'Password must match the new password.'
            this.organisationSchema['confirmPassword'].others.passwordErrorValidation.display = true
            this.validateConfirmPassword = false
        }else{
          this.organisationSchema['confirmPassword'].others.passwordErrorValidation.content = ''
            this.organisationSchema['confirmPassword'].others.passwordErrorValidation.display = false
            this.validateConfirmPassword = true
        } 
      }
        // if(this.tempOrganizationPayload.currentPassword != this.tempOrganizationPayload.newPassword){
        //   this.organisationSchema['newPassword'].others.passwordErrorValidation.content = ''
        //   this.organisationSchema['newPassword'].others.passwordErrorValidation.display = false
        // }  if(this.tempOrganizationPayload.confirmPassword != this.tempOrganizationPayload.newPassword && this.tempOrganizationPayload.confirmPassword != ''&& this.tempOrganizationPayload.newPassword != ''){
        //   this.organisationSchema['confirmPassword'].others.passwordErrorValidation.content = 'Password must match the new password.'
        //   this.organisationSchema['confirmPassword'].others.passwordErrorValidation.display = true
        // }
        // if(this.tempOrganizationPayload.confirmPassword == this.tempOrganizationPayload.newPassword && this.tempOrganizationPayload.confirmPassword != ''&& this.tempOrganizationPayload.newPassword != ''){
        //   this.organisationSchema['confirmPassword'].others.passwordErrorValidation.content = ''
        //   this.organisationSchema['confirmPassword'].others.passwordErrorValidation.display = false
        // }
      }
    },
    async passwordCheck(){
      console.log('test123',this.tempOrganizationPayload.currentPassword)

      if(this.tempOrganizationPayload?.currentPassword != '' && this.tempOrganizationPayload && this.tempOrganizationPayload.currentPassword){
      let login = {
        email: this.loginEmail,
        password: this.tempOrganizationPayload.currentPassword,
        "@baseType": "organisation"
      };
        const move_dashboard = await MLTAxiosInstance.post(
          `partymanagement/individual/login`,
          login
        );
        if(move_dashboard.data == 'Incorrect email/password !!'){
          this.organisationSchema['currentPassword'].others.passwordErrorValidation.content = 'Incorrect password. Please try again.'
          this.organisationSchema['currentPassword'].others.passwordErrorValidation.display = true
          this.validateCurrentPassword = false
        }else{
          this.organisationSchema['currentPassword'].others.passwordErrorValidation.content = ''
          this.organisationSchema['currentPassword'].others.passwordErrorValidation.display = false
          this.validateCurrentPassword = true
        }
        console.log(move_dashboard, "🔥🔥🔥🔥");
      }
    },
    async newpasswordCheck(){
      if(this.tempOrganizationPayload?.newPassword != '' && this.tempOrganizationPayload && this.tempOrganizationPayload.newPassword){

      let login = {
        email: this.loginEmail,
        password: this.tempOrganizationPayload.newPassword,
        "@baseType": "organisation"
      };
        const move_dashboard = await MLTAxiosInstance.post(
          `partymanagement/individual/login`,
          login
        );
        if(move_dashboard.data == 'Incorrect email/password !!'){
          this.organisationSchema['newPassword'].others.passwordErrorValidation.content = ''
          this.organisationSchema['newPassword'].others.passwordErrorValidation.display = false
          this.validateNewPassword = true
        }else{
          this.validateNewPassword = false
          this.organisationSchema['newPassword'].others.passwordErrorValidation.content = 'New password cannot be the same as the current password.'
          this.organisationSchema['newPassword'].others.passwordErrorValidation.display = true
        } 
        console.log(move_dashboard, "🔥🔥🔥🔥1");
      }
    },
      isValidLanguageAbility(data) {
    if (!Array.isArray(data)) {
        return false; // Not an array
    }

    // Check each item in the array
    for (const item of data) {
        if (
            typeof item !== 'object' || // Ensure it's an object
            item === null || // Ensure it's not null
            typeof item.name !== 'string' || // Ensure `name` is a string
            typeof item.code !== 'string' // Ensure `code` is a string
        ) {
            return false; // Invalid item
        }
    }

    return true; // All checks passed
},
      selectedData(val){
      console.log("selectedData:",val);
      this.organisationData.languageAbility[0] =
        {name:val?.name, code:val?.code}
        console.log("this.organisationData.languageAbility:",this.organisationData.languageAbility);
        
    },
        async fetchLangList() {
    if (this.$route?.query?.contact) {
      let filterData = {
        "type": "filter",
        "company": "Halleyx",
        "module": "hierarchyaccounts",
        "isPaginated": true,
        "paginatedQuery": {
          "rowsPerPage": 10,
          "currentPage": 1
        },
        "isNeedToBeSort": true,
        "sortFieldAndOrder": {},
        "limit": 10,
        "filterQuery": {
          "tradingName": this.$route?.query?.contact
        },
        "searchQuery": ""
      };

      try {
        const tenantDataData = await MLTAxiosInstance.post(
          `util/filterData`,
          filterData
        );
        this.langList = tenantDataData?.data?.data[0].languageAbility?.map(language => ({
          ...language,
          checked: false
        }));
      } catch (error) {
        console.error('Error fetching language data:', error);
      }
    }
    }
, 
      async handleProfilePictureUpload(file) {
        console.log(
          "🚀 ~ handleProfilePictureUpload ~ file:",
          file,
          file.target,
          file.target.result
        );
        // console.log(file[0]);
        let uploadingFile = file.target.files;
        let formData = new FormData();
  
        // // Append all selected files to FormData object
        for (let i = 0; i < uploadingFile.length; i++) {
          formData.append("files[]", uploadingFile[i]);
        }
  
        // console.log("Uploaded File:", formData.getAll('files[]'));
        // this.$emit('upload-data', formData);
        // console.log("🚀 ~ handleProilePictureUpload ~ file:", file)
        // let profileFormData = new FormData()
        //  profileFormData.append('files[]',uploadingFile)
        console.log(
          "🚀 ~ handleProfilePictureUpload ~ this.profileFormData:",
          this.profileFormData
        );
        // const uploadedProfile = await axios.post('http://localhost:5700/gcpupload/upload',this.profileFormData,{headers:{Authorization:this.$cookies.get('token')}})
        const uploadedProfile = await UPCAxiosInstance.post(
          "/gcpMultiple/v2/upload",
          formData
        );
        this.profilePicture = {
          id: uploadedProfile.data[0]?.data._id,
          attachmentType: "profile",
          description: "Sender profile picture",
          mimeType: uploadedProfile.data[0]?.data?.attachment?.contentType,
          name: uploadedProfile.data[0]?.filename,
          url: uploadedProfile.data[0]?.url,
          size: {
            amount: uploadedProfile.data[0]?.data?.attachment?.size,
            units: "KB",
          },
        };
        console.log(
          "🚀 ~ handleProfilePictureUpload ~ this.profilePicture:",
          this.profilePicture
        );
        console.log("🚀 ~ saveQuote ~ uploadedProfile:", uploadedProfile);
      },
      handlePhoneNumberChange(phoneNumber) {
        console.log("🚀 ~ handlePhoneNumberChange ~ phoneNumber:", phoneNumber);
      },
      handleAddressChange(val) {
        console.log("🚀 ~ handleAddressChange ~ val:", val);
        // let address1 = JSON.parse(JSON.stringify(val));
        // // this.addressData = address1
        // console.log(
        //   "🚀 ~ handleAddressChange ~ this.organisationData.contactMedium:",
        //   this.organisationData.contactMedium
        // );
        // let postalAddressIndex = this.organisationData.contactMedium?.findIndex(
        //   (item) => item.mediumType == "postalAddress"
        // );
        // console.log(
        //   "🚀 ~ handleAddressChange ~ postalAddressIndex:",
        //   postalAddressIndex
        // );
        // if (postalAddressIndex >= 0) {
        //   this.organisationData.contactMedium[postalAddressIndex] = address1;
        // } else {
        //   this.organisationData.contactMedium.push(address1);
        // }
        // console.log(
        //   "🚀 ~ handleAddressChange ~ this.organisationData.contactMedium:",
        //   this.organisationData.contactMedium
        // );
        // this.organisationData.
      },
      async back() {
        this.$router.push(`/settings`);
      },
      async pathTo() {
        // if (val.label == "Settings") {
          this.$router.push(`/settings`);
        // }
      },
      async exitView() {
        let update = {
          id: this.userId,
          impersonation: false,
          impersinater: "",
          "@baseType": "organisation"
        };
        // const result =
        await axios.patch(this.fet + `/partymanagement/individual`, update);
  
        let token = {
          token: this.impersonitor,
          "@baseType": "organisation"
        };
        axios.post(this.fet + `/partymanagement/individual/auth`, token);
      
      },
      async updateInfo() {
        if(this.selectedValue == 'Overview'){
          let matchingData = this.languageOpt.filter(item => item.name?.toLowerCase() === this.tempOrganizationPayload.defaultLanguage?.toLowerCase())
          if (matchingData && matchingData[0] && matchingData[0].code) {
            matchingData[0].code
          } else {
            matchingData[0].name = matchingData[0].name.toLowerCase()
            matchingData = [{ "code": "en", "name": "english", "english": "English", "french": "anglais", "tamil": "ஆங்கிலம்", "checked": false, "disabled": false }]
          }
            
          console.log('valueeeeui', matchingData,this.tempOrganizationPayload.defaultLanguage)
        let obj = {
          id:this.$cookies.get("userId"),
          firstName: this.tempOrganizationPayload.firstName,
          lastName: this.tempOrganizationPayload.lastName,
          name:this.tempOrganizationPayload.firstName.concat(this.tempOrganizationPayload.lastName),
          attachment: this.tempOrganizationPayload.attachment,
          languageAbility:JSON.parse(JSON.stringify(matchingData)),
          role: this.tempOrganizationPayload.role,
          '@baseType':'organisation'
          }

          this.keyLeft += 1;
          // if(JSON.stringify(this.selectedLanguageName) == JSON.stringify(matchingData)){
          // }
        
        console.log(JSON.parse(JSON.stringify(obj)),matchingData,'svggg::')

        localStorage.setItem('userLanguageFromSetting', JSON.stringify(matchingData));
        
        console.log(JSON.parse(JSON.stringify(matchingData)),'svggg123')
        const updateInfo = await MLTAxiosInstance.patch(`partymanagement/individual`,obj)
        // let obj1={
        //   id:this.settingsID,
        //   currentTimezone: this.tempOrganizationPayload.timeZone,
        //   currentLanguage:'English'
        // }
        //  console.log(obj1,'dj')
        // await MLTAxiosInstance.patch(`settings`,obj1)

          if (updateInfo.data != undefined) {
            this.showAlert = true;
            this.notification = {
              type: this.type,
              message: "User profile updated successfully",
            };

            this.dataPayload = this.tempOrganizationPayload
            this.duplicatedataPayload = JSON.parse(JSON.stringify(this.tempOrganizationPayload))
            this.organisationSchema = JSON.parse(JSON.stringify(this.duplicateOrganisationSchema))
            this.componentKey +=1


    
    //  this.duplicateOrganisationSchema = JSON.parse(JSON.stringify(this.organisationSchema))
    //  Object.values(this.organisationSchema).forEach(item => {
    // if (item.others && !item.others.readOnly) { 
    //     item.others.readOnly = true;
    // }
    // });
    if(this.view == true){
       this.organisationSchema = this.duplicateOrganisationSchema
    }
    // this.organisationSchema['defaultLanguage'].options = this.languageOpt 

    }

      } else{
 
        const secretKey = "mySecretKey123"; // Pad or truncate to the required length
        const password = this.tempOrganizationPayload.confirmPassword;
        const encryptedData = this.encryptPassword(password, secretKey);
        console.log("Encrypted Data:", encryptedData);
 
        let obj = {
          id:this.$cookies.get("userId"),
          password: encryptedData,
          "@baseType": "organisation"
        }
       const updateInfo1=await MLTAxiosInstance.patch(`partymanagement/individual`,obj)
       if (updateInfo1.data != undefined) {
            this.showAlert = true;
            this.notification = {
              type: this.type,
              message: "Password updated successfully",
            };
          }

      }
      },
      encryptPassword(password, secretKey) {
      const key = CryptoJS.enc.Utf8.parse(this.padKey(secretKey, 32)); // AES-256 requires 32 bytes
      const iv = CryptoJS.lib.WordArray.random(16);

      const encrypted = CryptoJS.AES.encrypt(password, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return {
        iv: iv.toString(CryptoJS.enc.Base64),
        ciphertext: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
      };
},
 padKey(key, length) {
  return key.padEnd(length, "0").substring(0, length); // Pad with zeros or truncate
}
 

    },
  };
  </script>