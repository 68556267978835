<template>
    <discardComponent
    :show="showOverviewDiscardModal" 
    @close="showOverviewDiscardModal = false"
    @stay="handleStay"
    @discard="handleDiscard"> 
  </discardComponent>
  <hlx-alert-notification :notify="notification" auto-close />
  <div v-show="impersonite == true"><banner @exit="exitView()" /></div>
  <div class="app-layout" style="display:none">
    <section class="right-panel">
      <div class="layout-basic">
        <div class="layout-breadcrumb">
          <hlx-breadcrumb :items="items" @path="pathTo" />
        </div>

        <div class="layout-header"><p>General</p></div>

        <div class="layout-body">

          <div class="grid">
            <span class="grid-left">
              <p class="title">Organisation</p>
              <p class="info">Update your organisation details</p>
            </span>

            <span class="grid-right">
              <span class="profile-with-info --2way">
                <div class="profile-picture-preview">
                  <hlx-file-upload
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Rectangle'"
                    :style-background="backgrounStyleupload"
                    :height="100"
                    :width="100"
                    :read-only="false"
                    :custom-icon="icon"
                    :type="'profile-upload'"
                    :name-display="organisationData.tradingName"
                    :profile-pic-preview="profilePicturePrevalue"
                    :content="'Import'"
                    @native-data="handleProfilePictureUpload"
                  />
                </div>
                <div class="input-wrapper">
                  <hlx-input
                    :label-animation="true"
                    type="text"
                    :pre-val="organisationData.tradingName"
                    :read-only="true"
                    label-value="Organisation name"
                  />
                  <hlx-input
                    :label-animation="true"
                    type="email"
                    :pre-val="organisationData.email"
                    :read-only="true"
                    label-value="Email"
                  />
                </div>
              </span>
              <span class="grid-right-alignment">
                <!-- {{ orgPhone }} -->
                <hlx-input
                  v-model:value="phoneNumber"
                  :pre-val="orgPhone.value"
                  type="phonenumber"
                  :country-code="orgPhone.country"
                  :label-value="'Phone no.'"
                  :label-animation="true"
                  :display-error="isPhoneNumberValidationFailed"
                  :custom-error="isPhoneNumberValidationFailed"
                  :custom-error-message="'Please fill the field'"
                  @focus-out="focusOutName"
                  @at-input="handlePhoneNumberChange"
                ></hlx-input>
              </span>
              <span class="grid-right-alignment">
                <addressComponent
                  :pre-val="addressData"
                  @address-data="handleAddressChange"
                />
              </span>

              <!-- <span class="grid-right-alignment">
                <span style="display:flex;align-items:center;border:1px solid #d8d8d8;border-radius:8px">
                <span style="padding-left:10px;padding-right:10px">
                  <i class="icon-globe-regular"></i>
                </span>
                <hlx-select
                  v-model:value="time"
                  :pre-value="currenttimezone"
                  :options="timezone"
                  :placeholder-value="'Timezone'"
                  :label-animation="false"
                  :clearable="true"
                ></hlx-select>
                </span>
              </span> -->
            </span>
          </div>




          <!-- default settings -->
           <!-- <span> -->
          <span v-if="organisationData && organisationData?.tradingName && organisationData?.tradingName !== `Halleyx` && showDefault === false" class="default-setting-whole-section-name"
                >Location based settings</span
              >
              <section
              v-if="organisationData && organisationData?.tradingName && organisationData?.tradingName !== `Halleyx` && showDefault === false"
               class="holder1">
                <div class="default-setting-whole-container">
                  <section
                    v-for="(i, index) in locationbasedsettings"
                    :key="index"
                    class="default-setting-header-section"
                  >
                    <span class="default-setting-header-section-container">
                      <p class="default-setting-header-section-header">
                        {{ i.header }}
                      </p>
                      <p class="default-setting-header-section-sub-header">
                        {{ i.subheader }}
                      </p>
                    </span>
                  </section>
                </div>
                <div class="default-setting-whole-container1">
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">                 
                      <hlx-select
                        v-model="languageSearchValue"
                        :pre-value="langPre"
                        :inline-search="true"
                        :options="languagesOptionsTranslation"
                        :placeholder-value="''"
                        :label-animation="false"
                        :label="'name'"
                        :prop-value="'name'"
                        :clearable="true"
                        :required="false"
                        @selected-value="handleSelectedLanguage"
                      >
                      </hlx-select>
                    </span>
                  </section>
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <hlx-select
                        v-model:value="countrySearchValue"
                        :pre-value="defaultSettings.country"
                        :inline-search="true"
                        :options="locationOptions.Country"
                        :placeholder-value="''"
                        :label-animation="false"
                        :custom-search="true"
                        :label="'name'"
                        :prop-value="'name'"
                        :clearable="true"
                        :required="false"
                        @selected-value="handleSelectedCountry"
                      >
                      </hlx-select>
                    </span>
                  </section>
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <hlx-input
                        :pre-val="defaultSettings.currency.unit"
                        :label-animation="false"
                        :read-only="true"
                        label-value=""
                        type="text"
                        :clearable="true"
                      />
                    </span>
                  </section>
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <hlx-select
                        v-model="timeZoneSearchValue"
                        :pre-value="defaultSettings.currentTimezone"
                        :inline-search="true"
                        :options="timeZoneOptions"
                        :placeholder-value="'Timezone'"
                        :label-animation="false"
                        :label="'name'"
                        :prop-value="'name'"
                        :clearable="true"
                        :required="false"
                        @selected-value="handleSelectedTimezone"
                      >
                      </hlx-select>
                    </span>
                  </section>
                </div>
              </section>
              <span
              v-if="organisationData && organisationData?.tradingName && organisationData?.tradingName !== `Halleyx` && showDefault === false" class="default-setting-whole-section-name"
                >Formating Based settings</span
              >
              <section
              v-if="organisationData && organisationData?.tradingName && organisationData?.tradingName !== `Halleyx` && showDefault === false"
               class="holder1">
                <div class="default-setting-whole-container">
                  <section
                    v-for="(i, index) in formatingbasedsetting"
                    :key="index"
                    class="default-setting-header-section"
                  >
                    <span class="default-setting-header-section-container">
                      <p class="default-setting-header-section-header">
                        {{ i.header }}
                      </p>
                      <p class="default-setting-header-section-sub-header">
                        {{ i.subheader }}
                      </p>
                    </span>
                  </section>
                </div>
                <div class="default-setting-whole-container1">
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <!-- {{date}} -->
                      <hlx-select
                        v-model:value="defaultSettings.dateFormat"
                        :pre-value="defaultSettings.dateFormat"
                        :options="dateformatOptions"
                        :placeholder-value="''"
                        :label-animation="false"
                        :label="'name'"
                        :prop-value="'value'"
                        :clearable="true"
                        :required="false"
                      >
                        <!-- @custom-change="dateval(date)" -->
                      </hlx-select>
                    </span>
                  </section>
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <hlx-select
                        v-model:value="defaultSettings.paginationSize"
                        :pre-value="defaultSettings.paginationSize"
                        :options="paginationFormatOptions"
                        :placeholder-value="''"
                        :label-animation="false"
                        :label="'name'"
                        :prop-value="'value'"
                        :clearable="true"
                        :required="false"
                      >
                        <!-- @custom-change="checkval" -->
                      </hlx-select>
                    </span>
                  </section>
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <hlx-select
                        v-model:value="defaultSettings.taxAddressType"
                        :pre-value="defaultSettings.taxAddressType"
                        :options="taxAddressTypeOptions"
                        :placeholder-value="''"
                        :label-animation="false"
                        :label="'name'"
                        :prop-value="'value'"
                        :clearable="true"
                        :required="false"
                      >
                        <!-- @custom-change="checkval" -->
                      </hlx-select>
                    </span>
                  </section>
                </div>
              </section>
              <div
              v-if="organisationData && organisationData?.tradingName && organisationData?.tradingName !== `Halleyx` && showDefault === false" class="default-setting-whole-section-name"
                >Signature Based settings</div
              >
<span
v-if="organisationData && organisationData?.tradingName && organisationData?.tradingName !== `Halleyx` && showDefault === false"
 style="border:1px solid black;padding:2px;width:fit-content;margin-top:14px">
              <signaturepad 
          :key="updateModalKey"
            :signer-name=getUserInfo?.data?.company
          ></signaturepad>
        </span>
      <!-- </span> -->










        </div>

        <div class="layout-footer">
          <div class="footer-container">
            <hlx-button class="secondary sm" @click="back()">Back</hlx-button>
            <hlx-button
            v-if="permisstionData.edit"
              style="margin-left: 0px !important"
              class="primary sm"
              @click="updateInfo()"
              >Save</hlx-button
            >
          </div>
        </div>
      </div>
    </section>
  </div>
  <section style="display: flex">
    <section class="lead-overview-left-panel responsiveSM">
      <div class="layout-breadcrumb">
        <hlx-breadcrumb
            :items="getBreadcrumbs" 
            :label-value="loadStaticData('LANG-0019')"
             @path="pathTo" />
        <!-- <hlx-breadcrumb
          v-if="view === false"
          :items="getBreadcrumbs"
          @path="goTo()"
        /> -->
      </div>
      <span style="margin-top: 25px">
        <leftPanel
          :side-bar-data="leftPanelData"
          @value-name="selectedValueFunc"
        ></leftPanel>
      </span>
    </section>
    <section
      class="--layout-inner-right-portion --RL70 responsiveSM-right-portion"
      style="
        padding: 1.8em 35px;
        width: calc(100% - 220px);
        padding-left: 0px;
      "
    >
      <div class="decision-info-right-pannel">
        <div class="layout-breadcrumb expanded-side-panel">
          <hlx-breadcrumb
            v-if="view === true"
            :label-value="loadStaticData('LANG-0019')"
            :items="getBreadcrumbs"
            @path="goTo()"
          />
          <hlx-breadcrumb
            v-if="view === false"
            :label-value="loadStaticData('LANG-0019')"
            :items="getBreadcrumbs"
            @path="goTo()"
          />
        </div>
        <div
          class="body-container-center"
          style="height: 100%"
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span
              class="expanded-side-panel"
              style="margin-right: 25px"
            >
              <leftPanel
                :side-bar-data="leftPanelData"
                @value-name="selectedValueFunc"
              ></leftPanel>
            </span>
            <div style="display: flex; align-items: center; gap: 15px;width:100%; padding-left: 20px">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  font-family: 'Quicksand';
                "
              >
                <div style="display: flex; align-items: center; gap: 12px">
                  <span style="font-weight: bold">
                    {{ matchFromStatic('Organization settings')}}
                  </span>
                </div>
                <div
                  style="color: #a6a6a6; font-size: 12px; width: max-content"
                > 
                  {{ matchFromStatic('Manage your profile')}}
                </div>
              </div>
            </div>
            <div
              class="go-back"
              style="
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <hlx-button
                v-if="edit==true" class="secondary sm" 
              @click="cancelFunctionality"
                >
                <!-- Account link -->
                {{matchFromStatic("Cancel") }}
                </hlx-button
            >
              <hlx-button
               v-if="edit == true"
                class="primary sm"
                style="margin-left: 20px"
                @click="updateInfo"
                >
                <!-- Save -->
                {{ matchFromStatic("Save") }}
                </hlx-button
              >
              <hlx-button
              v-if="view==true && selectedValue == 'Overview' && ( tempOrganizationPayload.organisationAddress[0]?.characteristic.latitude == '' || tempOrganizationPayload.organisationAddress[0]?.characteristic.longitude == '' || tempOrganizationPayload.organisationAddress[0]?.characteristic.country == '')"
                class="primary sm"
                :disabled="true"
                style="margin-left: 20px"
                @click="updateInfo"
                >
                <!-- Save -->
                {{ matchFromStatic("Save") }}
                </hlx-button
              >
              <hlx-button
              v-if="view==true && selectedValue == 'Default settings'  && ( switchdataPayload.organisationAddress[0]?.characteristic.latitude != '' && switchdataPayload.organisationAddress[0]?.characteristic.longitude != '' && switchdataPayload.organisationAddress[0]?.characteristic.country != '')"
                class="primary sm"
                style="margin-left: 20px"
                @click="updateInfo"
                >
                <!-- Save -->
                {{ matchFromStatic("Save") }}
                </hlx-button
              >
              <hlx-button
              v-if="view==true && selectedValue == 'Default settings' && ( switchdataPayload.organisationAddress[0]?.characteristic.latitude == '' || switchdataPayload.organisationAddress[0]?.characteristic.longitude == '' || switchdataPayload.organisationAddress[0]?.characteristic.country == '')"
                class="primary sm"
                :disabled="true"
                style="margin-left: 20px"
                @click="updateInfo"
                >
                <!-- Save -->
                {{ matchFromStatic("Save") }}
                </hlx-button
              >

              <!-- <hlx-button
                v-if="view==false"
                class="primary sm"
                style="margin-left: 20px"
                @click="openEditPanel"
                >
                {{ matchFromStatic("Edit") }}
                </hlx-button
              > -->
              <hlx-button 
              v-if="edit == false"
              class="primary sm"
              style="margin-left: 20px" @click="changeEditMode"> 
              {{ matchFromStatic("Edit") }}
            </hlx-button>
            <hlx-button
            
            >
            </hlx-button>
            </div>
          </div>
          <div>
            <!--
              v-if="selectedValue.toLowerCase() === 'overview'" -->
        <div v-if="selectedValue == 'Overview'" class="parent-container-for-user-profile" style="position:relative">
          <fieldRenderer
              :key="updateFieldRenderKey"
              :layout="'mixed'"
              :schema-payload = "organisationSchema"
              :data-payload = "dataPayload"
              :is-add-clicked = "isAddButtonClicked"
              :is-save-clicked="isSaveButtonClicked"
              :service-qualification-enabled="false"
              :status-list="[
                'Active',
                'Pending',
                'Inactive'
              ]"
              :parent-class-name="'parent-container-for-user-profile'"
               @edited-payload="patchEdited"
               @updated-payload="leadWholePayload"
               @lookup-emit="emitLookup"
            ></fieldRenderer>
      </div>
      <div v-else class="parent-container-for-user-profile" style="position:relative">
             <fieldRenderer
              :key="updateFieldRenderKey"
              :layout="'mixed'"
              :schema-payload = "settingSchema"
              :data-payload = "defaultSettingsPayload"
              :is-add-clicked = "isAddButtonClicked"
              :is-save-clicked="isSaveButtonClicked"
              :service-qualification-enabled="false"
              :status-list="[
                'Active',
                'Pending',
                'Inactive'
              ]"
              :parent-class-name="'parent-container-for-user-profile'"
               @edited-payload="settingemit"
               @updated-payload="leadWholePayload"
               @lookup-emit="emitLookup"
            ></fieldRenderer>
      </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import signaturepad from "@/components/UPC/CPQ/signaturePadWholeComponent.vue";
import banner from "../components/bannerComponent.vue";
import axios from "axios";
import discardComponent from '@/components/discardComponent.vue';
import FieldRenderer from "@/components/FieldRenderer.vue";
import leftPanel from '@/components/leftpannelComponent.vue';
import { MLTAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";
// import places from 'places.js';
import addressComponent from "@/components/CM/autocompleteComponent.vue";
export default {
  name: "AddRoleView",
  components: { banner, addressComponent, signaturepad, leftPanel,FieldRenderer, discardComponent },
  beforeRouteLeave(to, from, next) {
  if(this.discardIt) { //this.discardIt will handled on discard modal window discard changes emit
    next(true)
    return
  }
	// Check for unsaved changes
	/**
	 * Param 1 initial paylaod to check against param 2
	 * Param 2 updated payload to check against param 1
	 */
  console.log(this.firstoverviewPayload,this.dataPayload,'djks',this.firstSettingPayload,this.defaultSettingsPayload)
  
  let payload1 = this.firstoverviewPayload
  payload1.attachment = []
  let payload2 = this.dataPayload
  payload2.attachment = []
  //  console.log('djks',this.isPayloadChanged(this.checkPayload,this.tempOrganizationPayload),this.checkPayload,this.tempOrganizationPayload)
   const unsavedChanges = this.isPayloadChanged(payload1,payload2)
   const unsavedChanges1 = this.isPayloadChanged(this.firstSettingPayload,this.defaultSettingsPayload)
    //This isPayloadChanged method was configured in global methods so we can call it any where on vue.It's find a difference between both data and return true if any changes otherwise return false
    console.log(unsavedChanges,'djks',unsavedChanges1)
// If unsaved changes found then display discard modal
if(unsavedChanges == true || unsavedChanges1 == true){
  this.showOverviewDiscardModal = true
}
// Then route as per validation
next(!unsavedChanges)
return
},
  data() {
    return {
      firstSettingPayload:{},
      firstoverviewPayload:{},
      edit:false,
      duplicateSettingSchema:{},
      permisstionData:{},
      switchdataPayload:{},
      currencyChange:'',
      view:false,
      discardIt:false,
        tempBack:false,
        showOverviewDiscardModal:false,  
      updateFieldRenderKey:0,
      sortedData:{},
      tempOrganizationPayload:null,
      dataPayload:{
        organisationName:"",
        organisationEmail:"",
        organisationProfilePicture:[],
        organisationAddress:[
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
              latitude:"",
              longitude:"",
            },
          },
          ],
          country:'',
        currency:'',
        dateFormat:'',
        symbol:'',
        url:''
      },
      duplicatedataPayload:{
        organisationName:"",
        organisationEmail:"",
        organisationProfilePicture:[],
        organisationAddress:[
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
              latitude:"",
              longitude:"",
            },
          },
          ],
          country:'',
        currency:'',
        dateFormat:'',
        symbol:'',
        url:''
      },
      discardPayload:{
        organisationName:"",
        organisationEmail:"",
        organisationProfilePicture:[],
        organisationAddress:[
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
              latitude:"",
              longitude:"",
            },
          },
          ],
          country:'',
        currency:'',
        dateFormat:'',
        symbol:'',
        url:''
      },
      defaultSettingsPayload:{
        country:'',
        symbol:'',
        currency:'',
        dateFormat:'',
        url:''
      },
      duplicatedefaultSettingsPayload:{
        country:'',
        currency:'',
        dateFormat:'',
        symbol:'',
        url:''
      },
      discardSettingsPayload:{
        country:'',
        currency:'',
        dateFormat:'',
        symbol:'',
        url:''
      },
      checkPayload:{},
      organisationSchema:{},
      defaultsettingSchema:{},
      settingSchema:{},
      duplicateOrganisationSchema:{},
      selectedValue:'Overview',
      leftPanelData:{},
      tempSideBar:{
          main:
          [
    {
        "name": "Overview",
        "value": "overview",
        "icon": "icon-presentation-regular",
        "view": true,
        "active": true,
        "edit": false,
        "delete": false,
        "title": false,
        "add": false,
        "all": false,
        "customName": "Overview"
    },
    {
        "name": "Default settings",
        "value": "default_settings",
        "icon": "icon-check-circle-regular",
        "view": true,
        "edit": false,
        "delete": false,
        "title": false,
        "add": false,
        "all": false,
        "customName": "Default settings",
        "active": false
    }
]
  },
      showDefault:false,
      taxAddressTypeOptions: [
        { name: 'Billing Address', value:'billingAddress' },
        { name: 'Site Address', value: 'siteAddress' },
      ],
      paginationFormatOptions: [
        { name: 5, value: 5 },
        { name: 10, value: 10 },
        { name: 15, value: 15 },
        { name: 20, value: 20 },
      ],
      dateformatOptions: [
        { name: "MMM DD, YYYY hh:mm AM/PM", value: "MMM DD, YYYY hh:mm AM/PM" },
        { name: "DD MMM, YYYY h:mm AM/PM", value: "DD MMM, YYYY h:mm AM/PM" },
        { name: "MM/DD/YYYY", value: "MM/DD/YYYY" },
        { name: "ddd, MMM DD, YYYY", value: "ddd, MMM DD, YYYY" },
        { name: "hh:mm:ss AM/PM", value: "hh:mm:ss AM/PM" },
        {
          name: "ddd, MMM DD, YYYY hh:mm AM/PM",
          value: "ddd, MMM DD, YYYY hh:mm AM/PM",
        },
        { name: "YYYY MMM", value: "YYYY MMM" },
        { name: "hh:mm", value: "hh:mm" },
        // { name: "dd mmmm yyyy", value: "dd mmmm yyyy" },
        // { name: "mmm dd, yyyy", value: "mmm dd, yyyy" },
      ],
      formatingbasedsetting: [
        {
          header: "Date and time format",
          subheader:
            "Tailor your timeline with ease by choosing your preferred date format settings",
        },
        {
          header: "Pagination settings",
          subheader:
            "Tailor your viewing experience for smoother, more organized content exploration.",
        },
        {
          header: "Tax",
          subheader:
            "",
        },
      ],
      getUserInfo:null,
      languagesOptionsTranslation:[],
      countrySearchValue: "canada",
      locationOptions: {
        Country: [],
        Language: [{ name: "English", value: "English" }],
        Currency: [],
        Timezone: [],
      },
      languagesOptions: [{ name: "English", value: "English" }],
      timeZoneOptions: [],
      defaultSettings: {
        flags: {},
        postalCode: {},
        country: "",
        idd: {},
        cca2: "",
        currency: {},
        currentTimezone: "",
        timezones: [],
        region: "",
        subRegion: "",
        languages: { eng: "English" },
        currentLanguage: "English",
        flag: "",
        dateFormat: "MMM DD, YYYY hh:mm AM/PM",
        paginationSize: 10,
        taxAddressType:"billingAddress"
      },
      languageSearchValue: "",
      locationbasedsettings: [
        {
          header: "Language",
          subheader: "Selecting preferred language options.",
        },
        {
          header: "Country",
          subheader:
            "Country Preferences for a personalized touch to your experience.",
        },
        {
          header: "Currency",
          subheader:
            "Customize your financial view by selecting your preferred currency options.",
        },
        {
          header: "Timezone",
          subheader:
            " Personalize your schedule by selecting your preferred timezone for accurate and timely updates.",
        },
      ],
      phoneNumber: null,
      profilePicture: null,
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 16,
      },
      profilePicturePrevalue: "",
      phoneDefaultValue: {
        country: {
          name: "Canada",
          flags: "https://flagcdn.com/ca.svg",
          short: "CA",
          dial_code: "+1",
        },
        value: "",
        valid: true,
      },
      addressData: {
        characteristic: {
          "        @type": "",
          address1: "",
          city: "",
          country: "",
          description: "",
          postCode: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          timezone: "",
          unit: "",
        },

        mediumType: "postalAddress",
      },
      organisationData: {},
      currenttimezone: "",
      timezone: [],
      showAlert: false,
      type: "Success",
      notification: {},
      city: "",
      address: "",
      number: "",
      state: "",
      country: "",
      time: "",
      individualIdentification: [
        { contactNumber: "", country: "", city: "", state: "", address: "" },
      ],
      name: "",
      id: "",
      email: "",
      viewPermission: [],
      editPermission: [],
      impersonitor: "",
      fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
      impersonite: false,
      options: [{ name: "", checked: false, disabled: false }],
      items: [
        { label: "Settings", link: "/" },
        { label: "General", link: "/general" },
      ],
      countries: [
        {
          id: 1,
          flag: "in",
          name: "India",
          value: "India",
          code: "+91",
          checked: false,
        },
        {
          id: 2,
          flag: "ca",
          name: "Canada",
          value: "Canada",
          code: "+1",
          checked: false,
        },
        {
          id: 3,
          flag: "us",
          name: "USA",
          value: "USA",
          code: "+1",
          checked: false,
        },
      ],
    };
  },

  computed: {
    filteredCustomFieldSchema() {
      console.log('this.organisationSchema', this.organisationSchema);
      if (this.organisationSchema) {
        const filtered = Object.entries(this.organisationSchema).filter(
          ([, value]) =>
            value.section &&
            value.section.includes(this.selectedValue.toLowerCase())
        );
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);
 
        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);
 
        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });
 
        // Convert back to an object
        const sortedData = Object.fromEntries(entries);
 
        return sortedData;
      } else {
        return {};
      }
    },
    countryWithIDDCode() {
      const defaultSettings = this.$store.getters.getDefaultSettings;
      console.log(
        "🚀 ~ countryWithIDDCode ~ defaultSettings:",
        defaultSettings
      );
      if (
        defaultSettings &&
        Object.prototype.hasOwnProperty.call(defaultSettings, "idd") &&
        Object.prototype.hasOwnProperty.call(defaultSettings, "country") &&
        Object.prototype.hasOwnProperty.call(defaultSettings, "flag") &&
        Object.prototype.hasOwnProperty.call(defaultSettings, "cca2")
      ) {
        return {
          // dial_code:defaultSettings.idd?.root+defaultSettings.idd?.suffixes?.join(""),
          name: defaultSettings?.country,
          short: defaultSettings.cca2,
          flags: defaultSettings.flags.png,
        };
      }
      return this.phoneDefaultValue.country;
    },
    orgAddress() {
      if (
        !this.organisationData ||
        (this.organisationData &&
          Object.keys(this.organisationData).length == 0)
      ) {
        return this.addressData;
      }
      const postalAddress = this.organisationData?.contactMedium.find(
        (item) => item?.mediumType == "postalAddress"
      );
      if (postalAddress) {
        return postalAddress;
      }
      return this.addressData;
    },
    orgPhone() {
      if (
        !this.organisationData ||
        (this.organisationData &&
          Object.keys(this.organisationData).length == 0)
      ) {
        return { ...this.phoneDefaultValue, country: this.countryWithIDDCode };
      }
      const phoneNumber = this.organisationData?.contactMedium.find(
        (item) => item?.mediumType == "phone"
      );
      console.log("🚀 ~ orgPhone ~ phoneNumber:", phoneNumber);
      if (phoneNumber) {
        return phoneNumber?.characteristic.phoneNumber;
      }
      //

      console.log(
        "🚀 ~ orgPhone ~ this.countryWithIDDCode:",
        this.countryWithIDDCode
      );
      // this.phoneDefaultValue.country =
      return { ...this.phoneDefaultValue, country: this.countryWithIDDCode };
    },
  },
  watch: {
    dataPayload:{
    handler(){
       
    },
    immediate:true,
    deep:true
    },
    countrySearchValue: {
      async handler(val) {
        if (val) {
          // let countryList = await MLTAxiosInstance.get(
          //   "/settings/v1/countryInfo?country=" + val
          // );
          const countryList = await MLTAxiosInstance.post("/util/filterData", {
            type: "filter",
            module: "countryinfo",
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: true,
            sortFieldAndOrder: {},
            limit: 2,
            filterQuery: {
              "name.common": { $regex: val, $options: "i" },
            },
            searchQuery: val,
            isNeedToMap: true,
            fieldsToMap: [
              {
                from: "name",
                isNested: true,
                nested: ["common"],
                to: "name",
              },
            ],
          });

          this.locationOptions.Country = countryList.data?.data;
          return;
        }
      },
    },
    phoneNumber: {
      handler(value) {
        console.log("🚀 ~ handler ~ value:", value);
        if (value) {
          console.log("🚀 ~ handler ~ value:", value);
          let phoneNumberIndex = this.organisationData.contactMedium?.findIndex(
            (item) => item.mediumType == "phone"
          );
          console.log(
            "🚀 ~ handleAddressChange ~ phoneNumberIndex:",
            phoneNumberIndex
          );
          if (phoneNumberIndex >= 0) {
            this.organisationData.contactMedium[phoneNumberIndex] = {
              mediumType: "phone",
              preferred: true,
              characteristic: { phoneNumber: value },
            };
          } else {
            this.organisationData.contactMedium.push({
              mediumType: "phone",
              preferred: true,
              characteristic: { phoneNumber: value },
            });
          }
        }
      },
    },
    organisationSchema:{
     handler(){
     },
     immediate:true,
     deep:true
    },
  },
  async mounted() {
// mount data

try{

  const value = await MLTAxiosInstance.get("partymanagement/individual/auth");

let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${value.data.userId}`
);

const mergedPayload = sampleTheadsd?.data;

console.log(mergedPayload,"mergedPayload");
this.permisstionsData = mergedPayload.permission.Settings.sections.Organisation.sections ;
  console.log(this.permisstionsData,"this.permisstionsData",this.tempSideBar);
  

this.leftPanelData = this.mergeSidebarData(
  this.tempSideBar,
  this.permisstionsData
);

  let organisationInfo = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$cookies.get(
          "user"
        )}?company=${this.$cookies.get("company")}`
      );
        this.dataPayload.organisationName = organisationInfo.data.name,
        this.dataPayload.organisationEmail = organisationInfo.data.email,
        this.dataPayload.attachment = organisationInfo.data.attachment
        console.log(organisationInfo.data,'contactmedium123')
        organisationInfo.data.contactMedium.forEach((item)=>{
          if(item.mediumType == 'postalAddress'){
            this.dataPayload.organisationAddress = [{
              "mediumType": "postalAddress",
              "characteristic":item?.characteristic
            }] 
          }
        })
      this.currencyChange = this.dataPayload.organisationAddress[0]?.characteristic.country
      this.organisationData = organisationInfo.data;
      console.log(this.dataPayload,'this.datapayload')

}catch(error){
  console.log(error,'error')
}


let presetting = null

        // default settings
        try{
        const defaultSettings = await MLTAxiosInstance.get(`/settings`);
      // console.log(defaultSettings, "mounted");
       presetting = defaultSettings.data;
      this.defaultSettings = presetting;
      this.defaultSettingsPayload.id = presetting._id
      this.defaultSettingsPayload.currency = presetting.currency.unit
      this.defaultSettingsPayload.symbol = presetting.currency.symbol
      this.defaultSettingsPayload.country = presetting.country
      this.defaultSettingsPayload.dateFormat = presetting.dateFormat
      this.defaultSettingsPayload.url = presetting.url
      // console.log(this.defaultSettings,'default',this.dataPayload)
        }catch(error){
          console.log(error)
        }



    this.tempOrganizationPayload= JSON.parse(JSON.stringify(this.dataPayload))
    this.firstoverviewPayload = JSON.parse(JSON.stringify(this.dataPayload))
    this.switchdataPayload = JSON.parse(JSON.stringify(this.defaultSettingsPayload))
    this.firstSettingPayload = JSON.parse(JSON.stringify(this.defaultSettingsPayload))
    console.log("this.dataPayload",this.dataPayload)


    console.log(this.$cookies.get("user"),'ksjdkj')
    this.$nextTick(() => {
    this.loadGoogleMapsScript().then(() => {
       // Initialize Google Maps components here
       const autocompleteService = new window.google.maps.places.AutocompleteService();
       console.log('AutocompleteService Loaded from autoaddress:', autocompleteService);
       this.isLeadAddressMounted = true
     });
    });
    // this.view = true
  //   try { 
  //     let userAuth = await MLTAxiosInstance.get(
  //     `partymanagement/individual/auth`
  //   );
  //     let userAuth1 = await userAuth;
  //   const access = await MLTAxiosInstance.get(
  //     `partyRole/get/${userAuth1.data.userId}`
  //   );
  //     if (access.data.role == "Root admin") {
  //       this.showDefault = true;
  //   }
  // }
  //   catch (error) {
  //   console.error("error:",error)
  // }
    
  //   const r = 'this.loadMapData()';
  //     console.log("r.........",r);
    
    //   this.currenttimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    //  this.timezonedata =  this.timezonedata.forEach((e)=>{
    //     let obj = {}
    //     obj.name = e
    //     obj.value = e
    //     this.timezone.push(obj)
    //   })
    //   //
    //   // var placesAutocomplete = places({
    //   //   appId: 'M0V31UQUU5',
    //   //   apiKey: 'bb33f2329d5c7bb57d8660211a6f5744',
    //   //   container: document.querySelector('#address'),
    //   //   templates:{
    //   //     value: function(suggestion) {
    //   //       return suggestion.name;
    //   //     }
    //   //   }
    //   // }).configure({
    //   //   type:'address'
    //   // });
    //   // placesAutocomplete.on('change',function resultselected(e){
    //   //   document.querySelector('#state').val = e.suggestion.administrative || '';
    //   //   document.querySelector('#city').val = e.suggestion.city || '';
    //   //   // document.querySelector('#postalCode').val = e.suggestion.postalCode || '';
    //   // })
    //   //
    //   const general = await MLTAxiosInstance.get(
    //     `partymanagement/individual/general`
    //   );
    //   this.name = general.data.name;
    //   this.id = general.data._id;
    //   this.email = general.data.email;
    //   this.company = general.data.company;
    //   this.city = general.data.individualIdentification[0].city;
    //   this.address = general.data.individualIdentification[0].address;
    //   this.number = general.data.individualIdentification[0].contactNumber;
    //   this.state = general.data.individualIdentification[0].state;
    console.log(this.organisationData,'organisation data')
    // const postalAddress = this.organisationData?.contactMedium.find(
    //   (item) => item?.mediumType == "postalLetters" // by asif from postalAddress to postalLetters
    // );
    
    // if (postalAddress) {
    //   this.addressData = postalAddress;
    // }
    // if (this.organisationData.attachment[0]) {
    //   let isProfilePicture = this.organisationData.attachment.find(
    //     (item) => item.attachmentType == "profile"
    //   );
    //   if (isProfilePicture) {
    //     let uploadedContent = await UPCAxiosInstance.get(
    //       "/gcpupload/files/" + this.organisationData.attachment[0]?.name,
    //       { responseType: "arraybuffer" }
    //     );
    //     console.log("🚀 ~ mounted ~ uploadedContent:", uploadedContent);
    //     this.profilePicturePrevalue = this.bufferToDataURL(
    //       uploadedContent.data
    //     );
    //     this.profilePicture = isProfilePicture;
    //   }
    // }



      // this.countrySearchValue = presetting.country;
      // Language mapping
      // if (
      //   this.defaultSettings &&
      //   this.defaultSettings.languages &&
      //   Object.values(this.defaultSettings).length > 0
      // )
      //   this.languagesOptions = Object.values(
      //     this.defaultSettings.languages
      //   ).map((item) => {
      //     return { name: item };
      //   });
      // this.timeZoneOptions = this.defaultSettings.timezones.map((item) => {
      //   return { name: item };
      // });



      // let countryList = await MLTAxiosInstance.post("/util/filterData", {
      //   type: "filter",
      //   module: "countryinfo",
      //   isPaginated: true,
      //   paginatedQuery: {
      //     rowsPerPage: 10,
      //     currentPage: 1,
      //   },
      //   isNeedToBeSort: true,
      //   sortFieldAndOrder: {},
      //   limit: 2,
      //   filterQuery: {
      //     "name.common": {
      //       $regex: presetting.country
      //         ? presetting.country?.substring(0, 3)
      //         : "ca",
      //       $options: "i",
      //     },
      //   },
      //   searchQuery: "ca",
      //   isNeedToMap: true,
      //   fieldsToMap: [
      //     {
      //       from: "name",
      //       isNested: true,
      //       nested: ["common"],
      //       to: "name",
      //     },
      //   ],
      // });
      // this.locationOptions.Country = countryList.data?.data;
      // console.log(formattedCountries);



      let organisationInfo1 = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${this.$cookies.get(
        "user"
      )}?company=${this.$cookies.get("company")}`
    );
    console.log("organisationInfo1",organisationInfo1?.data?.languageAbility)

    this.languagesOptionsTranslation = organisationInfo1?.data?.languageAbility

    this.langPre = organisationInfo1?.data?.languageAbility[0].name

   this.getUserInfo = await MLTAxiosInstance.get('partymanagement/individual/auth');
      console.log("getUserInfo",this.getUserInfo)
     
     this.duplicatedataPayload = JSON.parse(JSON.stringify(this.dataPayload))
     this.discardPayload = JSON.parse(JSON.stringify(this.dataPayload))
     this.checkPayload = JSON.parse(JSON.stringify(this.dataPayload))
    //  this.switchdataPayload = JSON.parse(JSON.stringify(this.dataPayload))

    // schema mapping
    const schema = await UPCAxiosInstance.get(
      `/customSchema/getSchema?module=${'organisation'}`
    );
    this.organisationSchema = schema?.data?.schema;
    this.duplicateOrganisationSchema = schema?.data?.schema;
        const filtered = Object.entries(this.organisationSchema).filter(
                ([, value]) =>
                  value.section &&
                  value.section.includes('overview')
              );   
     const fieldSchema = Object.fromEntries(filtered);
     const entries = Object.entries(fieldSchema);
       
              // Sort based on `others.displayOrder` if it exists
              entries.sort((a, b) => {
                const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
                const orderB = b[1].others?.displayOrder ?? Infinity;
                return orderA - orderB; // Sort ascending
              });
     
              // Convert back to an object
              const data = Object.fromEntries(entries);
     this.organisationSchema = data
     this.duplicateOrganisationSchema = JSON.parse(JSON.stringify(this.organisationSchema))
     Object.values(this.organisationSchema).forEach(item => {
    if (item.others && !item.others.readOnly) {
        item.others.readOnly = true;
    }
    });
    if(this.edit == true){
       this.organisationSchema = this.duplicateOrganisationSchema
    }
    
    
     const schema1 = await UPCAxiosInstance.get(
      `/customSchema/getSchema?module=${'organisation'}`
    );
    console.log(schema1.data.schema.settings.schema,'setting')
      this.settingSchema = JSON.parse(JSON.stringify(schema1?.data?.schema?.settings?.schema))
      this.duplicateSettingSchema = JSON.parse(JSON.stringify(schema1?.data?.schema?.settings?.schema))
      Object.values(this.settingSchema).forEach(item => {
    if (item.others && !item.others.readOnly) {
        item.others.readOnly = true;
    }
    });
    if(this.edit == true){
       this.settingSchema = JSON.parse(JSON.stringify(this.duplicateSettingSchema))
    }
    
    
    
      // 
    // this.organisationSchema = schema?.data?.schema;
    // const filtered = Object.entries(this.organisationSchema).filter(
    //             ([, value]) =>
    //               value.section &&
    //               value.section.includes('overview')
    //           );   
    //  const fieldSchema = Object.fromEntries(filtered);
    //  const entries = Object.entries(fieldSchema);
       
    //           // Sort based on `others.displayOrder` if it exists
    //           entries.sort((a, b) => {
    //             const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
    //             const orderB = b[1].others?.displayOrder ?? Infinity;
    //             return orderA - orderB; // Sort ascending
    //           });
       
    //           // Convert back to an object
    //           const data = Object.fromEntries(entries);
    //  this.organisationSchema = data
    //  this.duplicateOrganisationSchema = JSON.parse(JSON.stringify(this.organisationSchema))
    //  Object.values(this.organisationSchema).forEach(item => {
    // if (item.others && !item.others.readOnly) {
    //     item.others.readOnly = true;
    // }
    // });
    // if(this.view == true){
    //    this.organisationSchema = this.duplicateOrganisationSchema
    // }


  },
  methods: {
    cancelFunctionality(){
       this.edit = false
       this.dataPayload = JSON.parse(JSON.stringify(this.firstoverviewPayload))
       this.defaultSettingsPayload = JSON.parse(JSON.stringify(this.firstSettingPayload))
       Object.values(this.organisationSchema).forEach(item => {
        if (item.others && !item.others.readOnly) {
            item.others.readOnly = true;
        }
        });
        Object.values(this.settingSchema).forEach(item => {
        if (item.others && !item.others.readOnly) {
            item.others.readOnly = true;
        }
        });
        
    },
    async changeEditMode(){
        this.edit = true
        this.organisationSchema = JSON.parse(JSON.stringify(this.duplicateOrganisationSchema))
        this.settingSchema = JSON.parse(JSON.stringify(this.duplicateSettingSchema))
    },
   async cancelFunc(){
     this.view = false
    //  this.switchsideBar(this.selectedValue)
    // let data = this.dataPayload
    this.switchdataPayload = {
        organisationName:"",
        organisationEmail:"",
        organisationProfilePicture:[],
        organisationAddress:[
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
              latitude:"",
              longitude:"",
            },
          },
          ],
          country:'',
        currency:'',
        dateFormat:'',
        symbol:'',
        url:''
      }
    this.dataPayload = JSON.parse(JSON.stringify(this.discardPayload))
    // this.switchdataPayload = JSON.parse(JSON.stringify(this.discardPayload))
    },
    openEditPanel(){
    //  this.view = true
    // if(this.view == true){
       this.organisationSchema = this.duplicateOrganisationSchema
       this.settingSchema = this.duplicateSettingSchema
    // }
      // this.switchsideBar(this.selectedValue)
    },
    handleStay() {
      // //handle Stay funtionality to hide discard component
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.discardIt = false;
    },
    handleDiscard() {
      //Handle discard functionalities like hide discard component,set discardIt as true, and push the routes where ever you want.
      // if (this.tempBack) {
      console.log("handleDiscard")
      this.$router.push('/settings');
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.discardIt = true;
      // } else {
      //   this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      // }
      // this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      // this.discardIt = true;
    },
    preventPageReload(event) {
      this.showOverviewDiscardModal = false;
      const unsavedChanges = this.isPayloadChanged(
        this.duplicatedataPayload,
        this.tempOrganizationPayload
      );
      if (!unsavedChanges) return;

      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
      return;
    },
    settingemit(val){
      console.log(val,'default settings')
      this.switchdataPayload = JSON.parse(JSON.stringify(val))
      this.defaultSettingsPayload  = JSON.parse(JSON.stringify(val))
    }, 

    async patchEdited(val){
      console.log(val,'overview')
      this.tempOrganizationPayload = JSON.parse(JSON.stringify(val))
      this.dataPayload = this.tempOrganizationPayload
      if(this.selectedValue == 'Overview'){
        console.log(this.currencyChange,'couuu',this.tempOrganizationPayload)
        // let value =this.tempOrganizationPayload.contactMedium
        // if(typeof value === "object"){
        //   let arr = [this.tempOrganizationPayload.contactMedium]
        //   this.tempOrganizationPayload.contactMedium = arr
        // }
        if( this.currencyChange != this.tempOrganizationPayload.organisationAddress[0]?.characteristic.country){
        this.currencyChange = this.tempOrganizationPayload.organisationAddress[0]?.characteristic.country
        const relatedcountryList = await MLTAxiosInstance.post("/util/filterData", {
            type: "filter",
            module: "countryinfo",
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: true,
            sortFieldAndOrder: {},
            limit: 2,
            filterQuery: {
              "name.common": { $regex: this.tempOrganizationPayload.organisationAddress[0]?.characteristic.country, $options: "i" },
            },
            searchQuery: "",
            isNeedToMap: true,
            fieldsToMap: [
              {
                from: "name",
                isNested: true,
                nested: ["common"],
                to: "name",
              },
            ],
          }); 
          if(relatedcountryList.data.data.length != 0 ){
          const countryList = relatedcountryList.data.data.filter((item)=> item.name == this.tempOrganizationPayload.organisationAddress[0]?.characteristic.country)
          console.log(countryList,'couuu',relatedcountryList,this.tempOrganizationPayload.organisationAddress[0]?.characteristic.country)
          console.log(Object.keys(countryList[0].currencies),'couuu')
          let currencyKeys = Object.keys(countryList[0].currencies);
          this.defaultSettingsPayload.currency = currencyKeys[0]
          this.defaultSettingsPayload.country = this.tempOrganizationPayload.organisationAddress[0]?.characteristic.country
          console.log(countryList,'couuu',this.tempOrganizationPayload)
          }
       }
      }
    },
    // async emitLookup(val){
    //   if(this.selectedValue != 'Overview'){
    //   console.log(val,'testtttt')
    //   const countryList = await MLTAxiosInstance.post("/util/filterData", {
    //         type: "filter",
    //         module: "countryinfo",
    //         isPaginated: true,
    //         paginatedQuery: {
    //           rowsPerPage: 10,
    //           currentPage: 1,
    //         },
    //         isNeedToBeSort: true,
    //         sortFieldAndOrder: {},
    //         limit: 2,
    //         filterQuery: {
    //           "name.common": { $regex: val.name, $options: "i" },
    //         },
    //         searchQuery: val,
    //         isNeedToMap: true,
    //         fieldsToMap: [
    //           {
    //             from: "name",
    //             isNested: true,
    //             nested: ["common"],
    //             to: "name",
    //           },
    //         ],
    //       }); 
    //       console.log(countryList.data,'cou')
    //       if(countryList.data?.data.length != 0){
    //       this.switchdataPayload.country = val.name
    //       let currencyKeys = Object.keys(countryList.data.data[0].currencies);
    //       this.switchdataPayload.currency = currencyKeys[0]
    //       this.switchdataPayload.symbol = countryList.data.data[0].currencies[this.switchdataPayload.currency].symbol
    //       // this.defaultSettingsPayload = this.switchdataPayload
    //       console.log('countryList',countryList.data.data[0],this.switchdataPayload)
    //       this.selectedValueFunc('Default settings')
    //       }
    //     }
    // },
    loadGoogleMapsScript() {
    // const mapKey = process.env.VUE_APP_MAP_KEY;
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_KEY}&libraries=places`;
    script.async = true;
    document.head.appendChild(script);
  
    return new Promise((resolve) => {
      script.onload = () => {
        resolve();
      };
    });
  },
    async selectedValueFunc(value,object){
      this.permisstionData = object
        this.selectedValue = value
    
    },
  //   async switchsideBar(value){
  //     if(value == 'Overview'){
  //       this.selectedValue = value
  //       this.switchdataPayload.dateFormat = this.tempOrganizationPayload?.dateFormat
  //       this.switchdataPayload.id = this.tempOrganizationPayload?.id
  //       this.switchdataPayload.url = this.tempOrganizationPayload?.url
  //       this.switchdataPayload.currency = this.tempOrganizationPayload?.currency
  //       this.switchdataPayload.country = this.tempOrganizationPayload?.country
  //       this.switchdataPayload.symbol = this.tempOrganizationPayload?.symbol
  //       this.dataPayload = JSON.parse(JSON.stringify(this.switchdataPayload))

  //     //schema section
  //   //     const schema = await UPCAxiosInstance.get(
  //   //   `/customSchema/getSchema?module=${'organisation'}`
  //   // );
  //   this.organisationSchema = JSON.parse(JSON.stringify(this.duplicateOrganisationSchema))
  //   const filtered = Object.entries(this.organisationSchema).filter(
  //               ([, value]) =>
  //                 value.section &&
  //                 value.section.includes('overview')
  //             );   
  //    const fieldSchema = Object.fromEntries(filtered);
  //    const entries = Object.entries(fieldSchema);
       
  //             // Sort based on `others.displayOrder` if it exists
  //             entries.sort((a, b) => {
  //               const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
  //               const orderB = b[1].others?.displayOrder ?? Infinity;
  //               return orderA - orderB; // Sort ascending
  //             });
       
  //             // Convert back to an object
  //             const data = Object.fromEntries(entries);
  //    this.organisationSchema = data
  //    this.duplicateOrganisationSchema = JSON.parse(JSON.stringify(this.organisationSchema))
  //    Object.values(this.organisationSchema).forEach(item => {
  //   if (item.others && !item.others.readOnly) {
  //       item.others.readOnly = true;
  //   }
  //   });
  //   if(this.view == true){
  //      this.organisationSchema = this.duplicateOrganisationSchema
  //   }
  //     }else{
  //       console.log('settings')
  //       this.selectedValue = value
  //       this.switchdataPayload.organisationAddress = this.tempOrganizationPayload?.organisationAddress
  //       this.switchdataPayload.attachment = this.tempOrganizationPayload?.attachment
  //       this.switchdataPayload.organisationName = this.tempOrganizationPayload?.organisationName
  //       this.switchdataPayload.organisationEmail = this.tempOrganizationPayload?.organisationEmail
  //       this.dataPayload = JSON.parse(JSON.stringify(this.switchdataPayload))
       
  //   // this.organisationSchema = JSON.parse(JSON.stringify(this.settingSchema))
  //   const filtered = Object.entries(this.organisationSchema).filter(
  //               ([, value]) =>
  //                 value.section &&
  //                 value.section.includes('default settings')
  //             );   
  //    const fieldSchema = Object.fromEntries(filtered);
  //    const entries = Object.entries(fieldSchema);
       
  //             // Sort based on `others.displayOrder` if it exists
  //             entries.sort((a, b) => {
  //               const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
  //               const orderB = b[1].others?.displayOrder ?? Infinity;
  //               return orderA - orderB; // Sort ascending
  //             });
       
  //             // Convert back to an object
  //             const data = Object.fromEntries(entries);
  //    this.organisationSchema = data
  //    this.settingSchema = JSON.parse(JSON.stringify(this.organisationSchema))
  //    Object.values(this.organisationSchema).forEach(item => {
  //   if (item.others && !item.others.readOnly) {
  //       item.others.readOnly = true;
  //   }
  //   });
  //   if(this.view == true){
  //      this.organisationSchema = this.settingSchema
  //   }
  // }
  // console.log('scfhhema',this.organisationSchema,this.dataPayload)
  //   },
    handleSelectedCountry(selectedCountry) {
      console.log(
        "🚀 ~ handleSelectedCountry ~ selectedCountry:",
        selectedCountry
      );
      this.defaultSettings = { ...this.defaultSettings, ...selectedCountry };
      // Handle country mapping
      this.defaultSettings.country = selectedCountry?.name;

      // Handle subRegion mapping
      this.defaultSettings.subRegion = selectedCountry?.subregion;

      // Handle currency
      if (
        selectedCountry &&
        selectedCountry.currencies &&
        Object.keys(selectedCountry.currencies).length > 0
      ) {
        // this.timeZoneOptions = selectedCountry?.timezones.map(item => {return{name:item}})
        let currencyKeys = Object.keys(selectedCountry.currencies);
        // if(curren)
        this.defaultSettings.currency = {
          unit: currencyKeys[0],
          symbol: selectedCountry.currencies[currencyKeys[0]]?.symbol,
        };
      }
      // Handle language mapping
      if (
        selectedCountry &&
        selectedCountry.languages &&
        Object.keys(selectedCountry.languages).length > 0
      ) {
        let languagekeys = Object.keys(selectedCountry.languages);
        this.languagesOptions = languagekeys.map((item) => {
          return { name: selectedCountry.languages[item] };
        });
        // if(curren)
        this.defaultSettings.languages = selectedCountry.languages;
        this.defaultSettings.currentLanguage =
          selectedCountry.languages[languagekeys[0]];
        let englishIndex = this.languagesOptions.findIndex(
          (item) => item.name == "English"
        );
        if (englishIndex < 0) {
          this.languagesOptions.splice(0, 0, { name: "English" });
          this.defaultSettings.currentLanguage = "English";
        }
      }

      // Handle timezone
      if (
        selectedCountry &&
        selectedCountry.timezones &&
        selectedCountry.timezones.length > 0
      ) {
        this.timeZoneOptions = selectedCountry?.timezones.map((item) => {
          return { name: item };
        });
        this.defaultSettings.timezones = selectedCountry.timezones;
        this.defaultSettings.currentTimezone = selectedCountry.timezones[0];
      }
    },
    async handleProfilePictureUpload(file) {
      console.log(
        "🚀 ~ handleProfilePictureUpload ~ file:",
        file,
        file.target,
        file.target.result
      );
      // console.log(file[0]);
      let uploadingFile = file.target.files;
      let formData = new FormData();

      // // Append all selected files to FormData object
      for (let i = 0; i < uploadingFile.length; i++) {
        formData.append("files[]", uploadingFile[i]);
      }

      // console.log("Uploaded File:", formData.getAll('files[]'));
      // this.$emit('upload-data', formData);
      // console.log("🚀 ~ handleProilePictureUpload ~ file:", file)
      // let profileFormData = new FormData()
      //  profileFormData.append('files[]',uploadingFile)
      console.log(
        "🚀 ~ handleProfilePictureUpload ~ this.profileFormData:",
        this.profileFormData
      );
      // const uploadedProfile = await axios.post('http://localhost:5700/gcpupload/upload',this.profileFormData,{headers:{Authorization:this.$cookies.get('token')}})
      const uploadedProfile = await UPCAxiosInstance.post(
        "/gcpMultiple/v2/upload",
        formData
      );
      this.profilePicture = {
        id: uploadedProfile.data[0]?.data._id,
        attachmentType: "profile",
        description: "Sender profile picture",
        mimeType: uploadedProfile.data[0]?.data?.attachment?.contentType,
        name: uploadedProfile.data[0]?.filename,
        url: uploadedProfile.data[0]?.url,
        size: {
          amount: uploadedProfile.data[0]?.data?.attachment?.size,
          units: "KB",
        },
      };
      console.log(
        "🚀 ~ handleProfilePictureUpload ~ this.profilePicture:",
        this.profilePicture
      );
      console.log("🚀 ~ saveQuote ~ uploadedProfile:", uploadedProfile);
    },
    handlePhoneNumberChange(phoneNumber) {
      console.log("🚀 ~ handlePhoneNumberChange ~ phoneNumber:", phoneNumber);
    },
    handleAddressChange(val) {
      console.log("🚀 ~ handleAddressChange ~ val:", val);
      let address1 = JSON.parse(JSON.stringify(val));
      // this.addressData = address1
      console.log(
        "🚀 ~ handleAddressChange ~ this.organisationData.contactMedium:",
        this.organisationData.contactMedium
      );
      let postalAddressIndex = this.organisationData.contactMedium?.findIndex(
        (item) => item.mediumType == "postalAddress"
      );
      console.log(
        "🚀 ~ handleAddressChange ~ postalAddressIndex:",
        postalAddressIndex
      );
      if (postalAddressIndex >= 0) {
        this.organisationData.contactMedium[postalAddressIndex] = address1;
      } else {
        this.organisationData.contactMedium.push(address1);
      }
      console.log(
        "🚀 ~ handleAddressChange ~ this.organisationData.contactMedium:",
        this.organisationData.contactMedium
      );
      // this.organisationData.
    },
    async back() {
      this.$router.push(`/settings`);
    },
    async pathTo() {
      // if (val.label == "Settings") {
        if(this.selectedValue == 'Overview'){
        this.switchdataPayload.organisationAddress = this.tempOrganizationPayload?.organisationAddress
        this.switchdataPayload.attachment = this.tempOrganizationPayload?.attachment
        this.switchdataPayload.organisationName = this.tempOrganizationPayload?.organisationName
        this.switchdataPayload.organisationEmail = this.tempOrganizationPayload?.organisationEmail
      }else{
        this.switchdataPayload.dateFormat = this.tempOrganizationPayload?.dateFormat
        this.switchdataPayload.id = this.tempOrganizationPayload?.id
        this.switchdataPayload.url = this.tempOrganizationPayload.url
        this.switchdataPayload.currency = this.tempOrganizationPayload?.currency
        this.switchdataPayload.country = this.tempOrganizationPayload?.country
        this.switchdataPayload.symbol = this.tempOrganizationPayload?.symbol
      }
        this.$router.push(`/settings`);
      // }
    },
    async exitView() {
      let update = {
        id: this.userId,
        impersonation: false,
        impersinater: "",
      };
      // const result =
      await axios.patch(this.fet + `/partymanagement/individual`, update);

      let token = {
        token: this.impersonitor,
      };
      axios.post(this.fet + `/partymanagement/individual/auth`, token);
      // .then((val) => {

      //   let company = val.data.company;
      //   if (company != '') {
      //     let c_name = null;
      //     if (company == 'Halleyx') {
      //       c_name = '';
      //     } else {
      //       c_name = company + '.';

      //     }

      //     // window.location.href = `http://${c_name}window.location.hostname/DMN/dashboard/${this.impersonitor}`;
      //   }
      // });
    },
    // async updateInfo() {
    //   console.log(this.duplicatedataPayload,this.duplicatedefaultSettingsPayload,'duplicate',this.defaultSettingsPayload)
    //   if(this.selectedValue == 'Overview'){
    //   let data = JSON.parse(JSON.stringify(this.organisationData.contactMedium))
    //   this.organisationData.contactMedium.forEach((item,index)=>{
    //     if(item&&item.mediumType == 'postalAddress'){
    //        data.splice(index,1)
    //     } 
    //   })
    //   this.tempOrganizationPayload.contactMedium[0].mediumType = 'postalAddress'
    //   data.push(this.tempOrganizationPayload.contactMedium[0])
    //   let value = JSON.parse(JSON.stringify(data))
    //   console.log('kjs',value)
    //   let update = {
    //     id: this.organisationData._id,
    //     name: this.tempOrganizationPayload.tradingName,
    //     // individualIdentification: this.individualIdentification,
    //     email: this.tempOrganizationPayload.email,
    //     attachment: this.tempOrganizationPayload.attachment,
    //     contactMedium:  data,
    //   };
    //   console.log(this.dataPayload,'ir',update,this.organisationData.contactMedium)
    //   const updateInfo = await MLTAxiosInstance.patch(
    //     `partymanagement/organisation`,
    //     update
    //   );
    //   // console.log(update, updateInfo);
    //   // this.$router.push(`/settings`)
    //   if (updateInfo.data != undefined) {
    //     this.showAlert = true;
    //     this.notification = {
    //       type: this.type,
    //       message: "Organisation profile updated successfully",
    //     };
    //   }

    //   const relatedcountryList = await MLTAxiosInstance.post("/util/filterData", {
    //         type: "filter",
    //         module: "countryinfo",
    //         isPaginated: true,
    //         paginatedQuery: {
    //           rowsPerPage: 10,
    //           currentPage: 1,
    //         },
    //         isNeedToBeSort: true,
    //         sortFieldAndOrder: {},
    //         limit: 2,
    //         filterQuery: {
    //           "name.common": { $regex: this.tempOrganizationPayload.contactMedium[0].characteristic.country, $options: "i" },
    //         },
    //         searchQuery: "",
    //         isNeedToMap: true,
    //         fieldsToMap: [
    //           {
    //             from: "name",
    //             isNested: true,
    //             nested: ["common"],
    //             to: "name",
    //           },
    //         ],
    //       }); 
    //       const countryList = relatedcountryList.data.data.filter((item)=> item.name == this.tempOrganizationPayload.contactMedium[0].characteristic.country)
    //       console.log(countryList,'cou')
    //       let continent = countryList[0].continent || ''
    //       let currencyKeys = Object.keys(countryList[0].currencies);
          
    //       let addLanguage =countryList[0].languages
    //       addLanguage.eng="English"
    //       let obj={
    //         id: this.dataPayload.id,
    //         currency: {
    //           unit:currencyKeys[0],
    //           symbol:countryList[0].currencies[currencyKeys[0]].symbol
    //         },
    //         cca2:countryList[0].cca2,
    //         flags:countryList[0].flags,
    //         idd:countryList[0].idd,
    //         timezones:countryList[0].timezones,
    //         languages:addLanguage,
    //         states:countryList[0].states,
    //         region:countryList[0].region, 
    //         flag:countryList[0].flag,
    //         currentTimezone:countryList[0].timezones[0],
    //         subRegion:continent,
    //         timeZone:countryList[0].timezones[0],
    //         dateFormat: this.dataPayload.dateFormat,
    //         country:this.tempOrganizationPayload.contactMedium[0].characteristic.country
    //       }
    //       console.log(obj,'testtwt')

    //       // if(countryList.data?.data.length != 0){
    //       // this.dataPayload.country = val.name
    //       // obj.currency = currencyKeys[0]
    //       // obj.symbol = countryList.data.data[0].currencies[obj.currency].symbol
    //       const result = await MLTAxiosInstance.patch(
    //     `/settings`,
    //     obj
    //   );
    //   console.log(result,'testtwt')
    //   //defaultsettings load
    //   const defaultSettings = await MLTAxiosInstance.get(`/settings`);
    //   console.log(defaultSettings, "mounted");
    //   const presetting = defaultSettings.data;
    //   this.defaultSettings = presetting;
    //   this.dataPayload.id = presetting._id
    //   this.dataPayload.currency = presetting.currency.unit
    //   this.dataPayload.country = presetting.country
    //   this.dataPayload.dateFormat = presetting.dateFormat
    //       console.log('ir',obj)
    //   // overview reload
    //       let organisationInfo = await MLTAxiosInstance.get(
    //   `/partymanagement/organisation/${this.$cookies.get(
    //     "user"
    //   )}?company=${this.$cookies.get("company")}`
    // );
    // console.log("🚀 ~ mounted ~ organisationInfo:", organisationInfo.data);
  
    //   this.dataPayload.tradingName = organisationInfo.data.name || "iniosd",
    //   this.dataPayload.email = organisationInfo.data.email || "e@gamil.com",
    //   this.dataPayload.attachment = organisationInfo.data.attachment
    //   organisationInfo.data.contactMedium.forEach((item)=>{
    //     if(item.mediumType == 'postalAddress'){
    //       this.dataPayload.contactMedium[0].characteristic = item.characteristic
    //     }
    //   })
    // // this.dataPayload.contactMedium[0].characteristic  = organisationInfo.data.contactMedium[3].characteristic
    // console.log("this.dataPayload",this.dataPayload)
    // // this.organisationDataForFieldRender = { tradingName:organisationInfo.data.
    // this.organisationData = organisationInfo.data;
    // const postalAddress = this.organisationData?.contactMedium.find(
    //   (item) => item?.mediumType == "postalLetters" // by asif from postalAddress to postalLetters
    // );
    
    // if (postalAddress) {
    //   this.addressData = postalAddress;
    // }
    // if (this.organisationData.attachment[0]) {
    //   let isProfilePicture = this.organisationData.attachment.find(
    //     (item) => item.attachmentType == "profile"
    //   );
    //   if (isProfilePicture) {
    //     let uploadedContent = await UPCAxiosInstance.get(
    //       "/gcpupload/files/" + this.organisationData.attachment[0]?.name,
    //       { responseType: "arraybuffer" }
    //     );
    //     console.log("🚀 ~ mounted ~ uploadedContent:", uploadedContent);
    //     this.profilePicturePrevalue = this.bufferToDataURL(
    //       uploadedContent.data
    //     );
    //     this.profilePicture = isProfilePicture;
    //   }
    // }

    // this.duplicatedataPayload = JSON.parse(JSON.stringify(this.dataPayload))
    // this.checkPayload = JSON.parse(JSON.stringify(this.dataPayload))
    // this.discardPayload  = JSON.parse(JSON.stringify(this.dataPayload))
    // this.tempOrganizationPayload = JSON.parse(JSON.stringify(this.dataPayload))
    // // this.duplicatedefaultSettingsPayload = JSON.parse(JSON.stringify(this.defaultSettingsPayload))
    // // this.discardSettingsPayload  = JSON.parse(JSON.stringify(this.defaultSettingsPayload))

    // }else{

    //   let data = JSON.parse(JSON.stringify(this.organisationData.contactMedium))
    //   this.organisationData.contactMedium.forEach((item,index)=>{
    //     if(item&&item.mediumType == 'postalAddress'){
    //        data.splice(index,1)
    //     } 
    //   })
    //   this.duplicatedataPayload.contactMedium[0].mediumType = 'postalAddress'
    //   data.push(this.duplicatedataPayload.contactMedium[0])
    //   let value = JSON.parse(JSON.stringify(data))
    //   console.log('kjs',value)
    //   let update = {
    //     id: this.organisationData._id,
    //     name: this.duplicatedataPayload.tradingName,
    //     // individualIdentification: this.individualIdentification,
    //     email: this.duplicatedataPayload.email,
    //     attachment: this.duplicatedataPayload.attachment,
    //     contactMedium:  data,
    //   };
    //   console.log(this.dataPayload,'ir',update,this.organisationData.contactMedium)
    //   const updateInfo = await MLTAxiosInstance.patch(
    //     `partymanagement/organisation`,
    //     update
    //   );
    //   // console.log(update, updateInfo);
    //   // this.$router.push(`/settings`)
    //   // if (updateInfo.data != undefined) {
    //   //   this.showAlert = true;
    //   //   this.notification = {
    //   //     type: this.type,
    //   //     message: "Organisation profile updated successfully",
    //   //   };
    //   // }

    //   const relatedcountryList = await MLTAxiosInstance.post("/util/filterData", {
    //         type: "filter",
    //         module: "countryinfo",
    //         isPaginated: true,
    //         paginatedQuery: {
    //           rowsPerPage: 10,
    //           currentPage: 1,
    //         },
    //         isNeedToBeSort: true,
    //         sortFieldAndOrder: {},
    //         limit: 2,
    //         filterQuery: {
    //           "name.common": { $regex: this.duplicatedataPayload.contactMedium[0].characteristic.country, $options: "i" },
    //         },
    //         searchQuery: "",
    //         isNeedToMap: true,
    //         fieldsToMap: [
    //           {
    //             from: "name",
    //             isNested: true,
    //             nested: ["common"],
    //             to: "name",
    //           },
    //         ],
    //       }); 
    //       const countryList = relatedcountryList.data.data.filter((item)=> item.name == this.duplicatedataPayload.contactMedium[0].characteristic.country)
    //       console.log(countryList,'cou')
    //       let continent = countryList[0].continent || ''
    //       let currencyKeys = Object.keys(countryList[0].currencies);
          
    //       let addLanguage =countryList[0].languages
    //       addLanguage.eng="English"
    //       let obj={
    //         id: this.dataPayload.id,
    //         currency: {
    //           unit:currencyKeys[0],
    //           symbol:countryList[0].currencies[currencyKeys[0]].symbol
    //         },
    //         cca2:countryList[0].cca2,
    //         flags:countryList[0].flags,
    //         idd:countryList[0].idd,
    //         timezones:countryList[0].timezones,
    //         languages:addLanguage,
    //         states:countryList[0].states,
    //         region:countryList[0].region, 
    //         flag:countryList[0].flag,
    //         currentTimezone:countryList[0].timezones[0],
    //         subRegion:continent,
    //         timeZone:countryList[0].timezones[0],
    //         dateFormat: this.tempOrganizationPayload.dateFormat,
    //         country:this.duplicatedataPayload.contactMedium[0].characteristic.country
    //       }
    //       console.log(obj,'testtwt')

    //       // if(countryList.data?.data.length != 0){
    //       // this.dataPayload.country = val.name
    //       // obj.currency = currencyKeys[0]
    //       // obj.symbol = countryList.data.data[0].currencies[obj.currency].symbol
    //       const result = await MLTAxiosInstance.patch(
    //     `/settings`,
    //     obj
    //   );
    //   console.log(result,'testtwt')
    //   //defaultsettings load
    //   const defaultSettings = await MLTAxiosInstance.get(`/settings`);
    //   console.log(defaultSettings, "mounted");
    //   const presetting = defaultSettings.data;
    //   this.defaultSettings = presetting;
    //   this.dataPayload.id = presetting._id
    //   this.dataPayload.currency = presetting.currency.unit
    //   this.dataPayload.country = presetting.country
    //   this.dataPayload.dateFormat = presetting.dateFormat
    //       console.log('ir',obj)
    //   // overview reload
    //       let organisationInfo = await MLTAxiosInstance.get(
    //   `/partymanagement/organisation/${this.$cookies.get(
    //     "user"
    //   )}?company=${this.$cookies.get("company")}`
    // );
    // console.log("🚀 ~ mounted ~ organisationInfo:", organisationInfo.data);
  
    //   this.dataPayload.tradingName = organisationInfo.data.name || "iniosd",
    //   this.dataPayload.email = organisationInfo.data.email || "e@gamil.com",
    //   this.dataPayload.attachment = organisationInfo.data.attachment
    //   organisationInfo.data.contactMedium.forEach((item)=>{
    //     if(item.mediumType == 'postalAddress'){
    //       this.dataPayload.contactMedium[0].characteristic = item.characteristic
    //     }
    //   })
    // // this.dataPayload.contactMedium[0].characteristic  = organisationInfo.data.contactMedium[3].characteristic
    // console.log("this.dataPayload",this.dataPayload)
    // // this.organisationDataForFieldRender = { tradingName:organisationInfo.data.
    // this.organisationData = organisationInfo.data;
    // const postalAddress = this.organisationData?.contactMedium.find(
    //   (item) => item?.mediumType == "postalLetters" // by asif from postalAddress to postalLetters
    // );
    
    // if (postalAddress) {
    //   this.addressData = postalAddress;
    // }
    // if (this.organisationData.attachment[0]) {
    //   let isProfilePicture = this.organisationData.attachment.find(
    //     (item) => item.attachmentType == "profile"
    //   );
    //   if (isProfilePicture) {
    //     let uploadedContent = await UPCAxiosInstance.get(
    //       "/gcpupload/files/" + this.organisationData.attachment[0]?.name,
    //       { responseType: "arraybuffer" }
    //     );
    //     console.log("🚀 ~ mounted ~ uploadedContent:", uploadedContent);
    //     this.profilePicturePrevalue = this.bufferToDataURL(
    //       uploadedContent.data
    //     );
    //     this.profilePicture = isProfilePicture;
    //   }
    // }

    //   // let update = { 
    //   //   id: this.duplicatedataPayload.id,
    //   //   country: this.tempOrganizationPayload.country,
    //   //   // individualIdentification: this.individualIdentification,
    //   //   currency: {
    //   //     unit:this.tempOrganizationPayload.currency,
    //   //     symbol:this.defaultSettingsPayload.symbol

    //   //   },
    //   //   dateFormat: this.tempOrganizationPayload.dateFormat,
    //   //   // contactMedium:  data,
    //   // };
    //   // console.log(update,this.tempOrganizationPayload)
    //   // update
    //   // // await MLTAxiosInstance.patch(`/settings`);
    //   // const updateInfo = await MLTAxiosInstance.patch(
    //   //   `/settings`,
    //   //   update
    //   // );

    // this.duplicatedataPayload = JSON.parse(JSON.stringify(this.dataPayload))
    // this.checkPayload = JSON.parse(JSON.stringify(this.dataPayload))
    // this.discardPayload  = JSON.parse(JSON.stringify(this.dataPayload))
    // this.tempOrganizationPayload = JSON.parse(JSON.stringify(this.dataPayload))
    //   // console.log(update, updateInfo);
    //   // this.$router.push(`/settings`)
    //   if (updateInfo.data != undefined) {
    //     this.showAlert = true;
    //     this.notification = {
    //       type: this.type,
    //       message: "Default settings updated successfully",
    //     };
    //   }

    //   // this.defaul
    // }
    // },
    async updateInfo() {
     
        this.switchdataPayload.organisationAddress = this.tempOrganizationPayload.organisationAddress
        this.switchdataPayload.attachment = this.tempOrganizationPayload.attachment
        this.switchdataPayload.organisationName = this.tempOrganizationPayload.organisationName
        this.switchdataPayload.organisationEmail = this.tempOrganizationPayload.organisationEmail
        this.switchdataPayload.dateFormat = this.defaultSettingsPayload?.dateFormat
        this.switchdataPayload.id = this.defaultSettingsPayload?.id
        this.switchdataPayload.url = this.defaultSettingsPayload.url
        this.switchdataPayload.currency = this.defaultSettingsPayload?.currency
        this.switchdataPayload.country = this.defaultSettingsPayload?.country
        this.switchdataPayload.symbol = this.defaultSettingsPayload?.symbol

      console.log(this.switchdataPayload,'dataPayload')

      let data = JSON.parse(JSON.stringify(this.organisationData.contactMedium))
      this.organisationData.contactMedium.forEach((item,index)=>{
        if(item&&item.mediumType == 'postalAddress'){
           data.splice(index,1)
        } 
      })
      this.switchdataPayload.organisationAddress[0].mediumType = 'postalAddress'
      data.push(this.switchdataPayload.organisationAddress[0])
      let value = JSON.parse(JSON.stringify(data))
      console.log('kjs',value)
      let update = {
        id: this.organisationData._id,
        name: this.switchdataPayload.organisationName,
        email: this.switchdataPayload.organisationEmail,
        attachment: this.switchdataPayload.attachment,
        contactMedium: data,
      };
      console.log(this.dataPayload,'ir',update,this.organisationData.contactMedium)
      const updateInfo = await MLTAxiosInstance.patch(
        `partymanagement/organisation`,
        update
      );
      const relatedcountryList = await MLTAxiosInstance.post("/util/filterData", {
            type: "filter",
            module: "countryinfo",
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: true,
            sortFieldAndOrder: {},
            limit: 2,
            filterQuery: {
              "name.common": { $regex: this.switchdataPayload.organisationAddress[0]?.characteristic.country, $options: "i" },
            },
            searchQuery: "",
            isNeedToMap: true,
            fieldsToMap: [
              {
                from: "name",
                isNested: true,
                nested: ["common"],
                to: "name",
              },
            ],
          }); 
          const countryList = relatedcountryList.data.data.filter((item)=> item.name == this.switchdataPayload.organisationAddress[0]?.characteristic.country)
          let continent = countryList[0].continent || ''
          let currencyKeys = Object.keys(countryList[0].currencies);
          
          let addLanguage =countryList[0].languages
          addLanguage.eng="English"
          let obj={
            id: this.switchdataPayload.id,
            currency: {
              unit:currencyKeys[0],
              symbol:countryList[0].currencies[currencyKeys[0]].symbol
            },
            cca2:countryList[0].cca2,
            flags:countryList[0].flags,
            idd:countryList[0].idd,
            timezones:countryList[0].timezones,
            languages:addLanguage,
            states:countryList[0].states,
            region:countryList[0].region, 
            flag:countryList[0].flag,
            currentTimezone:countryList[0].timezones[0],
            subRegion:continent,
            timeZone:countryList[0].timezones[0],
            dateFormat: this.switchdataPayload.dateFormat,
            country:this.switchdataPayload.organisationAddress[0]?.characteristic.country
          }
          console.log(obj,'testtwt')

          // if(countryList.data?.data.length != 0){
          // this.dataPayload.country = val.name
          // obj.currency = currencyKeys[0]
          // obj.symbol = countryList.data.data[0].currencies[obj.currency].symbol
          const result = await MLTAxiosInstance.patch(
        `/settings`,
        obj
      );
      result

      if (updateInfo.data != undefined) {
        this.showAlert = true;
        this.notification = {
          type: this.type,
          message: "Organisation profile updated successfully",
        };
        // this.$store.commit('getDefaultSettings', this.switchdataPayload.dateFormat);
        // this.$store.commit('getDefaultSettings', this.switchdataPayload.currency.symbol);

        await this.$store.dispatch("loadDefaultSettings");

        this.firstoverviewPayload = JSON.parse(JSON.stringify(this.dataPayload))
        this.firstSettingPayload = JSON.parse(JSON.stringify(this.defaultSettingsPayload))
        
      }
      
      // this.dataPayload = JSON.parse(JSON.stringify(this.switchdataPayload))
      this.discardPayload = JSON.parse(JSON.stringify(this.switchdataPayload))
      this.duplicatedataPayload = JSON.parse(JSON.stringify(this.switchdataPayload))
      this.checkPayload = JSON.parse(JSON.stringify(this.switchdataPayload))

    }
  },
};
</script>
