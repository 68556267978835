<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />

  <div class="role">
    <section class="right-content">
      <div style="padding: 18px 0 0 10px; width: 8%">
        <hlx-breadcrumb :auto-route="true" />
      </div>
      <div style="display: flex; flex-direction: column; padding: 35px 0 0 0">
        <div class="title-container" style="gap: 63%; margin-bottom: 24px">
          <div
            class="header"
            style="
              display: flex;
              width: 100%;
              gap: 6px;
              font-family: QuickSand;
              flex-direction: column;
            "
          >
            <b
              style="
                display: flex;
                width: max-content;
                font-family: Quicksand;
                font-size: 18px;
                font-weight: 700;
                line-height: 22.5px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
              "
              >Assignment rule</b
            >
            <p
              style="
                width: max-content;
                font-size: 12px;
                font-weight: 300;
                line-height: 16.34px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
              "
            >
              Configure your assignment rule
            </p>
          </div>
          <div
            style="
              width: 88.5vw;
              margin-left: 1%;
              display: flex;
              gap: 63%;
              justify-content: space-between;
            "
          >
            <div
              class="go-back"
              style="width: 100%; display: flex; align-items: center"
            >
              <div>
                <hlx-button class="secondary sm" @click="rolesEditButton = true"
                  >Cancel</hlx-button
                >
                <!-- {{ editsupplierbutton }} -->
                <hlx-button
                  class="primary sm"
                  style="margin-left: 20px"
                  @click="editRole"
                  >Save</hlx-button
                >
              </div>
            </div>
          </div>
        </div>

        <div class="main-container">
          <div style="width: 100%">
            <hlx-input
              :key="emailTriggersComponetN"
              v-model:value="payload.name"
              :pre-val="payload.name"
              :label-animation="true"
              label-value="Rule name"
              :required="true"
              :display-error="true"
              :custom-error="isRoleName"
              :custom-error-message="'Role already exists'"
              type="text"
              :clearable="true"
              @at-input="roleNameCheck"
            />

            <div style="width: 100%">
              <hlx-input
                :key="emailTriggersComponetC"
                :pre-val="$cookies.get('assignmentRule').customName"
                :label-animation="true"
                label-value="Module"
                :required="true"
                :disabled="true"
                :display-error="true"
                :custom-error="isRoleName"
                :custom-error-message="'Role already exists'"
                type="text"
                :clearable="true"
                @at-input="roleNameCheck"
              />
            </div>
            <!-- <div class="input-wrapper" style="margin-bottom: 20px">
                <hlx-select
                  :key="emailTriggersComponetE"
                  v-model:value="payload.trigger.type"
                :pre-value="payload.trigger.type"
  
                  :options="eventValues"
                  prop-value="value"
                  :placeholder-value="'Choose event'"
                  :label-animation="true"
                  :required="true"
                  :clearable="true"
                ></hlx-select>
              </div> -->
            <div style="width: 100%">
              <div class="decision-name-wrapper">
                <!-- {{payload.tags}} -->
                <!-- {{ payload.tags }}{{"option"}}{{tagsList}} -->
                <hlx-input
                  :key="emailTriggersComponetD"
                  v-model:value="payload.description"
                  :pre-val="payload.description"
                  :label-animation="true"
                  label-value="Description"
                  type="textarea"
                  :rows="7"
                  :clearable="true"
                ></hlx-input>
              </div>
            </div>
          </div>
        </div>
        <hr style="margin: 15px 42px 5px 42px; width: 90%" />
        <conditionComponet
          style="padding: 11px 44px; width: 97%"
          :intellisense-list="intellisenseList"
        />
        <div style="padding: 8px 44px; width: 97%">
          <div
            class="header"
            style="
              display: flex;
              width: 100%;
              gap: 6px;
              font-family: QuickSand;
              flex-direction: column;
            "
          >
            <b
              style="
                display: flex;
                width: max-content;
                font-family: Quicksand;
                font-size: 18px;
                font-weight: 700;
                line-height: 22.5px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
              "
              >Assign to</b
            >
            <p
              style="
                font-size: 8px;
                font-weight: 400;
                line-height: 10.89px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: rgba(153, 153, 153, 1);
              "
            >
              You can either configure approver levels or allow the system to
              auto approve
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- <discardComponent
      :show="showSupplierDiscardModal"
      @stay="handleStay"
      @discard="handleDiscard"
      @close="showSupplierDiscardModal = false"
    /> -->
</template>

<script>
import {
  // DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
// import discardComponent from "@/components/discardComponent.vue";
//   import emailComponent from '@/components/emailComponent.vue';

import conditionComponet from "@/components/emailTriggers/conditionComponet.vue";

export default {
  name: "AddRoleView",
  components: {
    // discardComponent,
    //   emailComponent,
    conditionComponet,
  },

  data() {
    return {
      triggersInformation: this.$cookies.get("assignmentRule"),
      payload: {
        name: "",
        module: this.$cookies.get("assignmentRule").module,
        description: "",
        startDate: new Date().toJSON(),
        status: "Inactive",
        endDate: "2999-09-01T00:00:00.000Z",
        trigger: {
          baseType: "event",
          type: "",
        },
        condition: {},
        action: [],
      },
      rolesEditButton: true,
      showSupplierDiscardModal: false,

      isRoleName: false,

      eventValues: [],
      emailTriggersComponetN: 0,
      emailTriggersComponetC: 0,
      emailTriggersComponetE: 0,
      emailTriggersComponetD: 0,
    };
  },
  computed: {
    paginatedData() {
      return this.tableData;
    },
    // showingFrom() {
    //   return (this.currPage - 1) * this.rowsPerPage + 1;
    // },
    // showingTo() {
    //   const lastItem = this.showingFrom + this.rowsPerPage - 1;
    //   return lastItem > this.totalRows ? this.totalRows : lastItem;
    // },
    // totalRows() {
    //   return this.tableData.length;
    // },
  },
  watch: {
    payload: {
      handler(val) {
        console.log(val, "payload email trigers");
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    if (this.triggersInformation.id) {
      let geteventScheduler = await UPCAxiosInstance.get(
        `/assignmentRule/${this.triggersInformation.id}`
      );
      this.payload = geteventScheduler.data;
    } else {
      this.payload = {
        name: "",
        module: this.triggersInformation.module,
        description: "",
        startDate: new Date().toJSON(),
        status: "Inactive",
        endDate: "2999-09-01T00:00:00.000Z",
        trigger: {
          baseType: "event",
          type: "",
        },
        condition: {},
        action: [],
      };
    }
    this.eventValues = await this.getReferanceData("eventValues");
  },
  methods: {},
};
</script>
