<template>
    <div v-if="type=== 'Full address'">
      <hlx-input
        v-model:value="payload.characteristic.street"
        :read-only="view"
        :required="required"
        :pre-val="payload.characteristic.street"
        type="location"
        :label-value="'Street'"
        :label-animation="true"
        :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This address is already existed in exclusion'"
        :location-data-prop="locationData10"
        @address-data="addressData"
        @at-input="getLocations(payload.characteristic.street)"
      ></hlx-input>

      
      
      <div
        class="new-address-fields"
        style="display: flex; justify-content: space-between; gap: 12px; margin-bottom: 20px;margin-top: 20px"
      >
      <span style="width: 50%;">
        <hlx-input
        v-model:value="payload.characteristic.unit"
        :read-only="view"
        :required="required"
        :pre-val="payload.characteristic.unit"
        type="text"
        :label-value="'Unit/Appartment'"
        :label-animation="true"
        :display-error="false"
        :custom-error-message="'Please fill the field'"
        @at-input="canEmitTrigger"
      ></hlx-input>
       
        <!-- @at-input="canEmitTrigger" -->
        </span>
        <span style="width: 50%;">
            <hlx-input
          v-model:value="payload.characteristic.city"
          :read-only="view"
        :required="required"
          :pre-val="payload.characteristic.city"
          type="location"
          :label-value="'City'"
          :label-animation="true"
          :display-error="false"
          :custom-error-message="'Please fill the field'"
        :location-data-prop="locationData2"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.city)"
        />
        
          <!-- @at-input="canEmitTrigger" -->
          
        </span>
      </div>




      <div
        class="new-address-fields"
        style="display: flex; justify-content: space-between; gap: 12px; margin-bottom: 20px"
      >
      <span style="width: 50%;">
        <hlx-input
          v-model:value="payload.characteristic.state"
          :read-only="view"
        :required="required"
          :pre-val="payload.characteristic.state"
          type="location"
          :label-value="'State'"
          :label-animation="true"
          :display-error="false"
        :location-data-prop="locationData3"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.state)"
          />
       
          <!-- @at-input="canEmitTrigger" -->
        </span>
        <span style="width: 50%;">
            <hlx-input
          v-model:value="payload.characteristic.country"
          :read-only="view"
        :required="required"
          :pre-val="payload.characteristic.country"
          type="location"
          :label-value="'Country'"
          :label-animation="true"
          :display-error="false"
        :location-data-prop="locationData4"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.country)"
          />
        
        </span>
      </div>

      <div
        class="new-address-fields"
        style="display: flex; justify-content: space-between; gap: 12px; margin-bottom: 20px"
      >
      <span style="width: 50%;">
        <hlx-input
          v-model:value="payload.characteristic.postalCode"
          :read-only="view"
        :required="required"
          :pre-val="payload.characteristic.postalCode"
          :label-value="'Postal code'"
          :label-animation="true"
          :display-error="false"
        :location-data-prop="locationData20"
        @address-data="addressData"
        @at-input="getLocations(payload.characteristic.postalCode)"
        />
        <!-- @at-input="canEmitTrigger" -->
      </span>
      <span style="width: 50%;">
        <hlx-input
          v-model:value="payload.characteristic.postalCodePrefix"
          :read-only="view"
        :required="required"
          :pre-val="payload.characteristic.postalCodePrefix"
          type="location"
          :label-value="'Prefix(FSA)'"
          :label-animation="true"
          :display-error="false"
        :location-data-prop="locationData201"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.postalCodePrefix)"
        />
        </span>
      </div>


      <!-- lat lang -->
      <div
        class="new-address-fields"
        style="display: flex; justify-content: space-between; gap: 12px; margin-bottom: 20px"
      >
      <span style="width: 50%;">
       
        <hlx-input
          v-model:value="payload.characteristic.lat"
                :pre-val="payload.characteristic.lat"
                :read-only="view"
                label-value="Latitude"
                :label-animation="true"
                type="number"
                :clearable="false"
          />
      </span>

      
      <span style="width: 50%;">
        <hlx-input
          v-model:value="payload.characteristic.lng"
                :pre-val="payload.characteristic.lng"
                :read-only="view"
                label-value="Longitude"
                :label-animation="true"
                type="number"
                :clearable="false"
          />
      </span>
      </div>



    </div>


    <div v-if="type=== 'Street'">
      <hlx-input
        v-model:value="payload.characteristic.street"
        :read-only="view"
        :required="required"
        :pre-val="removeFirstComma(payload1.characteristic.description)"
        type="location"
        :label-value="'Street'"
        :label-animation="true"
        :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This street is already existed'"
        :location-data-prop="locationData1"
        @address-data="addressData"
        @at-input="getLocations(payload.characteristic.street)"
      ></hlx-input> 
    </div>

    <div v-if="type=== 'check availability'">
      <hlx-input
        v-model:value="payload.characteristic.street"
        :read-only="view"
        :required="required"
        :pre-val="removeFirstComma(payload1.characteristic.description)"
        type="location"
        :label-value="'Address'"
        :label-animation="true"
        :display-error="true"
        :custom-error-message="'Please fill the field'"
        :location-data-prop="locationData1"
        @address-data="addressData"
        @at-input="getLocations(payload.characteristic.street)"
      ></hlx-input> 
    </div>


    <div v-if="type=== 'City'" style="margin-bottom: 20px">
      <!-- {{ customnameerror }} -->
        <hlx-input
          v-model:value="payload.characteristic.city"
          :read-only="view"
        :required="required"
          :pre-val="payload1.characteristic.city"
          type="location"
          :label-value="'City'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This city is already existed'"
        :location-data-prop="locationData2"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.city)"
        />
    </div>



    <div v-if="type=== 'Province'" style="margin-bottom: 20px">
        <hlx-input
          v-model:value="payload.characteristic.state"
          :read-only="view"
        :required="required"
          :pre-val="(payload1.characteristic.state)"
          type="location"
          :label-value="'Province'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This provience is already existed'"
        :location-data-prop="locationData3"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.state)"
          />
    </div>



    <div v-if="type=== 'Postal code'" style="margin-bottom: 20px">
        <hlx-input
          v-model:value="payload.characteristic.postalCode"
          :read-only="view"
        :required="required"
          :pre-val="(payload1.characteristic.postalCode)"
          type="location"
          :label-value="'Postal code'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This postal code is already existed'"
        :location-data-prop="locationData20"
          
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.postalCode)"
          />
          <!-- @at-input="canEmitTrigger" -->
    </div>


    <div v-if="type=== 'Prefix (FSA)'" style="margin-bottom: 20px">
        <hlx-input
          v-model:value="payload.characteristic.postalCodePrefix"
          :read-only="view"
        :required="required"
          :pre-val="removeFirstComma(payload1.characteristic.description)"
          type="location"
          :label-value="'Prefix(FSA)'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This prefix is already existed'"
        :location-data-prop="locationData201"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.postalCodePrefix)"
        />
    </div>



    <div v-if="type=== 'Partial address'" style="margin-bottom: 20px">
      
      <span v-if="criteria === 'Street name + Prefix'">
        <hlx-input
        v-model:value="payload.characteristic.street"
        :read-only="view"
        :required="required"
        :pre-val="payload1.characteristic.street"
        type="location"
        :label-value="'Street'"
        :label-animation="true"
        :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This street is already existed'"
        :location-data-prop="locationData1"
        @address-data="addressData"
        @at-input="getLocations(payload.characteristic.street)"
      ></hlx-input>

      <div style="margin-top: 20px;">
        <hlx-input
          v-model:value="payload.characteristic.postalCodePrefix"
          :read-only="view"
        :required="required"
          :pre-val="payload1.characteristic.postalCodePrefix"
          type="location"
          :label-value="'Prefix(FSA)'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This prefix is already existed'"
        :location-data-prop="locationData201"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.postalCodePrefix)"
        /></div>
      </span>

      <span v-if="criteria === 'Street name + Postal code'">
        <hlx-input
        v-model:value="payload.characteristic.street"
        :read-only="view"
        :required="required"
        :pre-val="payload1.characteristic.street"
        type="location"
        :label-value="'Street'"
        :label-animation="true"
        :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This street is already existed'"
        :location-data-prop="locationData1"
        @address-data="addressData"
        @at-input="getLocations(payload.characteristic.street)"
      ></hlx-input> 
      
      <div style="margin-top: 20px;">
      <hlx-input
          v-model:value="payload.characteristic.postalCode"
          :read-only="view"
        :required="required"
          :pre-val="payload1.characteristic.postalCode"
          type="location"
          :label-value="'Postal code'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This postal code is already existed'"
        :location-data-prop="locationData20"
          
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.postalCode)"
          />
        </div>
      </span>

      <span v-if="criteria === 'City + Postal code'">
        <div  style="margin-bottom: 20px"><hlx-input
          v-model:value="payload.characteristic.city"
          :read-only="view"
        :required="required"
          :pre-val="payload1.characteristic.city"
          type="location"
          :label-value="'City'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This city is already existed'"
        :location-data-prop="locationData2"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.city)"
        /></div>

        <hlx-input
          v-model:value="payload.characteristic.postalCode"
          :read-only="view"
        :required="required"
          :pre-val="payload1.characteristic.postalCode"
          type="location"
          :label-value="'Postal code'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This postal code is already existed'"
        :location-data-prop="locationData20"
          
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.postalCode)"
          />
      </span>

      <span v-if="criteria === 'City + Prefix'">
        <div  style="margin-bottom: 20px"><hlx-input
          v-model:value="payload.characteristic.city"
          :read-only="view"
        :required="required"
          :pre-val="payload1.characteristic.city"
          type="location"
          :label-value="'City'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This city is already existed'"
        :location-data-prop="locationData2"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.city)"
        /></div>

        <hlx-input
          v-model:value="payload.characteristic.postalCodePrefix"
          :read-only="view"
        :required="required"
          :pre-val="payload1.characteristic.postalCodePrefix"
          type="location"
          :label-value="'Prefix(FSA)'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'This prefix is already existed'"
        :location-data-prop="locationData201"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.postalCodePrefix)"
        />
      </span>
        
    </div>
  </template>
  
  <script>
  import {
    // DMNAxiosInstance,
    // MLTAxiosInstance,
    UPCAxiosInstance,
  } from '@/config/axiosConfig';
  // import axios from 'axios';
  
  // import { loadGoogleMapsApi } from '../ServiceQualification/laodApi.js';
  
  export default {
    props: {
      view: {
        type: Boolean,
        default: false
      },

      parentInfo1: {
        type: Array,
              default: () => {
                  return [];
              },
          },

          

    parentInfo: {
        type: Object,
        default: null
          },

          
      type: {
        type: String,
        default: null
      },

      required: {
        type: Boolean,
        default: false
      },

      criteria: {
        type: String,
        default: null
          },

      module: {
        type: String,
        default: null
          },

      customnameerror: {
        type: Boolean,
        default: false
      },

          
      
      // apis: {
      //   type: String,
      // },
  
      // newCoordinate:{
      //   type:Object
      // },
  
      preVal: {
        type: Object,
        default: () => {
          return {
            mediumType: 'postalAddress',
            characteristic:{
            city: '',
            unit: '',
            country: '',
            state: '',
            postalCode: '',
            stateOrProvince: '',
            street1: '',
            street2: '',
            description: '',
            street: '',
            default: true,
            '@type': '',
            postalCodePrefix:'',
            }
          };
        },
      },
    },
    emits: ['addressData','geopoint','mapname','description'],
    data() {
        return {

          autocompleteService:null,


      placeService: null, // To fetch details later

          enableWatch: false,

            locationData1: null,
            locationData10: null,
            locationData2:null,
            locationData20:null,
            locationData201:null,
            locationData3:null,
            locationData4:null,
        addressnew:{},
        input:{},
        canEmit1: true,
        locationData: [],
        // added by ragavi
        addressSuggestion:[],
        payload: {
          mediumType: 'postalAddress',
          characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
              default: true,
            postalCodePrefix: '',
            lat: null,
              lng:null,
          '@type': '',
          }
            },
            payload1: {
          mediumType: 'postalAddress',
          characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
              default: true,
              postalCodePrefix:'',
            '@type': '',
            lat: null,
            lng:null,
          }
        },
          rawPayload: {},

          getprop:true,
      };
    },
    watch: {
  
      newCoordinate:{
        handler(val) {
          console.log("newCoordinate",val);
          if(val && val?.lat ){
            if (val && val?.lng) {

              // this.lat = val.lat;
              // this.lng = val.lng;

              if (this.enableWatch === true) {
                this.getGeocode(val?.lat,val?.lng);
                
              }
              
          }
          }
          
        },
        immediate: true,
        deep: true,
      },

      getprop: {
        handler(val) {
          val
          console.log("getprop::",val);
          
         },
        immediate: true,
        deep: true,
      },
  
  
      preVal: {
        handler(val) {
          this.canEmit1 = false
          // this.rawPayload = JSON.parse(JSON.stringify(val));

          if (this.getprop) {
            val
            this.payload = JSON.parse(JSON.stringify(val));
          this.payload1 = JSON.parse(JSON.stringify(val));
          this.payload1 = val;
           }
         
          console.log('pre val is watching',val, this.payload);
        },
        immediate: true,
        deep: true,
      },
  
  
  
      payload: {
        handler(val) {
          val
          // console.log('assume......', this.payload, val);
  
  
  
  
          this.payload.characteristic.description =
            `${val.characteristic.street !== '' && val.characteristic.street !== undefined ? val.characteristic.street : ''}` +
            `${val.characteristic.unit !== '' && val.characteristic.unit !== undefined ? ', ' + val.characteristic.unit : ''}` +
            `${val.characteristic.city !== '' && val.characteristic.city !== undefined ? ', ' + val.characteristic.city : ''}` +
            `${val.characteristic.state !== '' && val.characteristic.state !== undefined ? ', ' + val.characteristic.state : ''}` +
            `${val.characteristic.postalCode !== '' && val.characteristic.postalCode !== undefined ? ', ' + val.characteristic.postalCode : ''}` +
            `${val.characteristic.country !== '' && val.characteristic.country !== undefined ? ', ' + val.characteristic.country : ''}`;


          console.log("this,payload", this.payload, val);

          // this.canEmit1 = false;
            
          if (this.canEmit1) {
  
  
  
  
            // setTimeout(() => {
            // this.getprop = false;
              console.log("address emiting:::");
              
              this.$emit('addressData', val);
            // }, 500);

  
  
  
  
  
            
          }
        },
        immediate: true,
        deep: true,
      },
    },
    mounted() {
      console.log("started bro00123", this.apis);

      setTimeout(() => {
        this.getprop = false;
        this.canEmit1 = true;
      }, 2000);

      
  
  
  
  
        
    },
    methods: {
  
      getGeocode(lat, lng) {
        console.log("getGeocode running....",lat, lng);
        
        const geocoder = new window.google.maps.Geocoder();
        const latLng = new window.google.maps.LatLng(lat, lng);
        
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              console.log("results[0].....",results[0]);
              
              this.addressnew = results[0];
              this.addressData(results[0]);
            } else {
              this.addressnew = 'No results found';
            }
          } else {
            this.addressnew = 'Geocoder failed due to: ' + status;
          }
        });
      },
        
  
      canEmitTrigger() {
        this.canEmit1 = true
        },

      

      async getLocations(val) {

            console.log("getLocations::",val,this.payload.characteristic);
            
          this.canEmit1 = false;
  
        
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
      this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
      console.log("tokeeeeeeeeeeeeeen",this.autocompleteService,this.sessionToken);
  
  
        console.log("input",val);
        // const input ={}
        if(this.payload.characteristic.street === val){
          this.input = this.payload.characteristic.street;
        }else if(this.payload.characteristic.city === val){
          this.input = this.payload.characteristic.city;
        }else if(this.payload.characteristic.state === val){
          this.input = this.payload.characteristic.state;
        }else if(this.payload.characteristic.postalCode === val){
          this.input = this.payload.characteristic.postalCode;
        }else if(this.payload.characteristic.postalCodePrefix === val){
          this.input = this.payload.characteristic.postalCodePrefix;
        }else{
          this.input = this.payload.characteristic.country;
        }
        let result = [];
          try {

            
  console.log(this.input);
  
            if (this.payload.characteristic.street === '7654312%^$#') {
    
  
    const response = await UPCAxiosInstance.get(
  `/address/suggestionlist?input=${this.input}`
          );
  
    result = response?.data;
    let result1 = this.filterUniquePlaces(result);
    // this.locationData1 = result;
    console.log("this.locationData1:::", result1);
    


    const r =[];
    result1.forEach((item) => {

      


      
            item?.types.forEach((itm)=>{
              
              if(itm){
                r.push(item);
              }
              
            })
          })
          this.locationData1 = this.filterUniquePlaces(r);
          
  
  
  }
  
  
  
            if (this.input.length > 0) {

              this.autocompleteService.getPlacePredictions(
              {
                input: this.input,
                sessionToken: this.sessionToken,
                    componentRestrictions:
                  //     this.module === 'exclude' 
                  // ? { province: 'quebec' } 
                  //     :
                      { country: 'ca' },
                      // types: (this.module === "exclude") ? ['(cities)'] : undefined,
              },
  (predictions, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      console.log("Suggestion list:", this.module, predictions, this.input);


      let predictions1;

      if (this.module === "exclude") {
        predictions1 = predictions.filter(prediction =>
        {
          if (this.parentInfo?.address.long && prediction.description.toLowerCase().includes(' ' + `${this.parentInfo?.address.long.toLowerCase()}` + ',')) {
            console.log(this.parentInfo?.address.long,"match123 long:",prediction.description,);
            
            return true;
          } else if (this.parentInfo?.address.short && prediction.description.includes(' ' + `${this.parentInfo?.address.short.toUpperCase()}`)) {
            console.log(this.parentInfo?.address.short, "match123 short:",prediction.description);

            return true;
          } else if (this.parentInfo?.address && typeof this.parentInfo?.address === 'string' && prediction.description.toLowerCase().includes(' ' + `${this.parentInfo?.address.toLowerCase()}`)) {
            console.log(this.parentInfo?.address.short, "match123 common:",prediction.description);

            return true;
          } else {
            // console.log("match123 end:",prediction.description.toLowerCase(), this.parentInfo?.address.toLowerCase());

            return false;
              }
        }        
        )
      } else if (this.type === 'check availability') {

        console.log("check availability::");
        

        this.parentInfo1;

        const addressData = this.parentInfo1
          // || ["ON", "QC"];

                    // Normalize all data1 values to uppercase and trim them
                    const normalizedData1Array = addressData.map(value => value.toUpperCase().trim());

                    // Filter predictions that match any of the values in the array
                    predictions1 = predictions.filter(prediction =>
                        normalizedData1Array.some(data1Value =>
                            prediction.description.toUpperCase().includes(' '+`${data1Value.toUpperCase()}`)
                        )
                    );
                    console.log("Filtered Predictions:", predictions1, this.parentInfo1);



      } else {
        
        console.log("check availability::::::::::::::::::::");
        
        predictions1 = predictions
      }

              // prediction.description.toLowerCase().includes(' '+`${this.parentInfo?.address.long}` + ',')
              // || prediction.description.toLowerCase().includes(' '+`${this.parentInfo?.address.short}`+',')
              // || prediction.description.toLowerCase().includes(this.parentInfo?.address)
              // || prediction.description.toLowerCase().includes('qc')
           

      // let predictions1 = this.module === "exclude"
      //       ? predictions.filter(prediction =>
      //       regex.test(prediction.description)
      //         // || prediction.description.toLowerCase().includes('qc')
      //         )
      //   : predictions


        

        console.log("this.parentInfo:::123",this.parentInfo, this.parentInfo?.address.long || this.parentInfo?.address.short || this.parentInfo?.address )

        console.log("predictions1::",predictions1);
        



        if(this.payload.characteristic.city === val){

            
        const r =[];
        predictions1.forEach((item)=>{
            item?.types.forEach((itm)=>{
              
              if(itm.toLowerCase() === "locality"){
                r.push(item);
              }
              console.log("r",r);
            })
          })
          this.locationData2 = r;
  
          console.log("pendrive 22222222222");
        }



        else if(this.payload.characteristic.postalCode === val){


          
const r =[];
predictions1.forEach((item)=>{
    item?.types.forEach((itm)=>{
      
      if(itm.toLowerCase() === "postal_code"){
        r.push(item);
      }
      console.log("r",r);
    })
  })
  this.locationData20 = r;

  console.log("pendrive 22222222222");
        }



        else if(this.payload.characteristic.street === val){

            
const r =[];
          (async () => {
    for (let i = 0; i < predictions1.length; i++) {
        const item = predictions1[i];

        if (item?.types) {
            for (let j = 0; j < item.types.length; j++) {
                const itm = item.types[j];

                if (itm) {
                  r.push(item);

                  let rem = this.filterUniquePlaces(r);
                  this.locationData1 = rem;
                  this.locationData10 = rem;
                  
                  console.log("rem::::", rem);
  
  
                    
                }
            }
        }
    }
          })();

        }

        else if(this.payload.characteristic.postalCodePrefix === val){

            
const r =[];
predictions1.forEach((item)=>{
    item?.types.forEach((itm)=>{
      
      if(itm.toLowerCase() === "postal_code_prefix"){
        r.push(item);
      }
      console.log("r",r);
    })
  })
  this.locationData201 = r;

  console.log("pendrive 22222222222");
}


        else if (this.payload.characteristic.state === val) {

            
        const r =[];
        predictions1.forEach((item)=>{
            item?.types.forEach((itm)=>{
              
              if(itm.toLowerCase() === "administrative_area_level_1"){
                r.push(item);
              }
              console.log("r",r);
            })
          })
          this.locationData3 = r;
  
          console.log("pendrive 333333333333");
        }else if(this.payload.characteristic.country === val){

            
          const r =[];
          predictions1.forEach((item)=>{
            item?.types.forEach((itm)=>{
              
              if(itm.toLowerCase() === "country"){
                r.push(item);
              }
              console.log("r",r);
            })
          })
          this.locationData4 = r;
          // });
          console.log(this.locationData4);
  
          console.log("pendrive 44444444444");
        }
  
  
              
            } else {
              console.log('No predictions1 available or status not OK', status);
            }
          }
        );
      } else {
        console.log('Input is empty');
      }
  
  
  
  
  
  
  
  
  
  
  
  
          result = this.locationData;
          console.log(result);
        } catch (error) {
          console.error('Error fetching autocomplete data:', error.message);
          }
        
        return result;
      },




    async getPlaceDetails(placeId) {
  if (!this.placeService) {
    this.placeService = new window.google.maps.places.PlacesService(document.createElement('div'));
  }

  return new Promise((resolve, reject) => {
    this.placeService.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const postalCode = this.extractPostalCode(place);
        const obj = {
          postalCode: postalCode,
          postalCodePrefix: postalCode?.substr(0, 3),
        };
        console.log('Place details:', place);
        console.log('Postal Code:', postalCode);
        console.log('FSA Code:', postalCode?.substr(0, 3));
        console.log('Object:', obj);
        resolve(obj);
      } else {
        console.error('Place details fetch error:', status);
        reject(new Error(`Failed to fetch place details: ${status}`));
      }
    });
  });
},


    extractPostalCode(place) {
      const postalCodeComponent = place.address_components.find((component) =>
        component.types.includes('postal_code')
      );
      return postalCodeComponent ? postalCodeComponent.long_name : null;
      },



    

    async getPostalCode(lat, lon) {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`;

      let response;
    try {
        response = await fetch(url, { headers: { 'User-Agent': 'YourAppName/1.0' } });
        
        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();

        // Extract and return postal code
        if (data.address && data.address.postcode) {
            return data.address.postcode;
        } else {
          console.log(`no postal code  is:: ${url} is :: ${response}`);
          
          // throw new Error('Postal code not found in the response.');
          return '';
        }
    } catch (error) {
        console.error(`Failed to fetch postal code: ${error.message} is:: ${url} is :: ${response}`);
    }
}

,



      filterUniquePlaces(data) {
    const uniquePlaces = [];
    const seenPlaceIds = new Set();

    data.forEach(place => {
        if (!seenPlaceIds.has(place.place_id)) {
            uniquePlaces.push(place);
            seenPlaceIds.add(place.place_id);
        }
    });

    return uniquePlaces;
      },





      async addressData(val) {
        this.canEmit1 = true
        // this.getprop = false;
        console.log('val::))::',val);
        if(val){
          if(val?.place_id){
            
  
          
        let address_components = await UPCAxiosInstance.get(
          `/address/details?place_id=${val.place_id}`
              );

              this.lat = address_components.data.result.geometry.location.lat;
            this.lng = address_components.data.result.geometry.location.lng;
            
            let lat = address_components.data.result.geometry.location.lat;
            let lng = address_components.data.result.geometry.location.lng;

            let getPostalCode = await this.getPostalCode(address_components.data.result.geometry.location.lat, address_components.data.result.geometry.location.lng)

            if (getPostalCode) {
              console.log("getPostalCode:::",getPostalCode);
              
            }
            console.log("getPostalCode:",getPostalCode);
            


              
        this.$emit('geopoint',address_components.data.result.geometry.location.lat,address_components.data.result.geometry.location.lng);
        console.log(address_components,"emiting",address_components.data.result.name);
        this.$emit('mapname',address_components.data.result.name);
        this.$emit('description',address_components.data.result.formatted_address);
  
        
        
  
        console.log(address_components);
        let street = '';
        let postcode = '';
        let country = '';
        let unit = '';
        let city = '';
              let postcode_prefix = '';
        let state_or_province = '';
        for (const component of address_components.data.result
          .address_components) {
          const componentType = component.types[0];
  
          switch (componentType) {
            case 'street_number': {
              street = `${component.long_name} ${street}`;
              break;
            }
            case 'route': {
              street += component.short_name;
              break;
            }
            case 'postal_code': {
              postcode = `${component.long_name}${postcode}`;
              break;
            }
            case 'postal_code_suffix': {
              postcode = `${postcode}-${component.long_name}`;
              break;
            }
            case 'postal_code_prefix': {
                postcode_prefix = `${component.long_name}${
                postcode !== '' ? '-' + postcode : ''
              }`;
              break;
            }
            case 'country': {
              country = `${component.long_name}`;
              break;
            }
            // case 'administrative_area_level_2': {
            //   city = `${component.long_name}`;
            //   break;
            // }
            case 'locality': {
              city = `${component.long_name}`;
              break;
            }            
            case 'administrative_area_level_1': {
              state_or_province = `${component.long_name}`;
              break;
            }
            // case 'administrative_area_level_3': {
            //   city = `${city !== '' ? city + ', ' : ''}${component.long_name}`;
            //   break;
            // }
            case 'sublocality_level_1': {
              city = `${component.long_name}`;
              break;
            }
            case 'subpremise': {
              unit = `${component.long_name}`;
              break;
            }
          }
        }
            console.log("all::", street, postcode, unit, city, state_or_province, country);

  
        this.payload.characteristic.street = street;
        this.payload.characteristic.postalCode = postcode || getPostalCode;
        this.payload.characteristic.unit = unit;
        this.payload.characteristic.city = city;
        this.payload.characteristic.state = state_or_province;
        this.payload.characteristic.country = country;
        this.payload.characteristic.postalCodePrefix = postcode_prefix || postcode.substr(0, 3) || getPostalCode.substr(0, 3);
            // this.payload.characteristic.description = address_components.data.result.formatted_address;
            this.payload.characteristic.lat = lat;
            this.payload.characteristic.lng = lng;

            console.log("street watching:",street, postcode);
            

        this.payload1.characteristic.street = street;
        this.payload1.characteristic.postalCode = postcode || getPostalCode;
        this.payload1.characteristic.unit = unit;
        this.payload1.characteristic.city = city;
        this.payload1.characteristic.state = state_or_province;
        this.payload1.characteristic.country = country;
        this.payload1.characteristic.postalCodePrefix = postcode_prefix || postcode.substr(0, 3) || getPostalCode.substr(0, 3);
            // this.payload1.characteristic.description = address_components.data.result.formatted_address;
            this.payload1.characteristic.lat = lat;
            this.payload1.characteristic.lng = lng;

          //    setTimeout(() => {
          //   this.payload1 = this.payload;
          // }, 2000);

          // this.payload.characteristic.postalCodePrefix
          //   this.payload.characteristic.postalCode

            console.log("njhgbfvdc:", this.payload1);


            if (this.canEmit1) {
              // this.$emit('addressData', this.payload);              
            }
            
  
      }

    }
  
      },
    },
  };
  </script>
  