<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-drawer
    :show="show_right_filter"
    :width="300"
    position="right"
    :header="false"
    :footer="false"
    :show-close-icon="true"
    @close="closeRightFilter"
  >
    <template #body>
      <div style="display: flex">
        <FilterComponent
          :filterpanelname="'accounts'"
          :label-value=filterLang
          @filtered-data="accountsFilter"
        ></FilterComponent>
      </div>
    </template>
  </hlx-drawer>
  <section class="cm-accounts-landing-layout">
    <!-- <section class="cm-accounts-landing-filter-panel-container">

    </section> -->
    <section class="cm-accounts-right-panel-container">
      <div class="cm-accounts-right-panel-top-section">
        <div class="cm-accounts-right-panel-top-section-title">
          <!-- Accounts -->
           {{ loadStaticData('LANG-0090') }}
        </div>
        <div class="cm-accounts-right-panel-top-section-description">
          <!-- A unique identifier for each customer facilitating easy reference and
          organization. -->
          {{ loadStaticData('LANG-0091') }}
        </div>
        <div class="cm-accounts-right-panel-top-section-utility-container">
          <span class="search-container">
            <hlx-search 
            :label-value="loadStaticData('LANG-0003')||'Search'"
            @search-key="searchKey"
            ></hlx-search>
          </span>
          <span class="filter-table">
            <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
              <i class="icon-filter-regular"></i>
              <span v-if="filtertaglength != 0" class="filter-count">{{
                filtertaglength
              }}</span>
            </hlx-button>
          </span> 
          <hlx-button v-if="permisstionData.add" class="primary sm" @click="addNewAccountModal">
            <i class="icon-plus-circle-regular"></i> 
            <!-- Add account -->
             {{ loadStaticData('LANG-0092') }}
          </hlx-button>
        </div>
        <div
          v-if="tableData.length > 0 && tableData"
          class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
        >
          <hlx-table
            :label-value="tableModalLang"
            :column-count="accounts_table_theads.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="accounts_table_theads_cc"
            @close-rearrange="toggleRearrangeModal = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <!-- <hlx-table-head
                :width="60"
                :align="'center'"
                style="padding: 0 15px"
                :fixed="'left'"
                >{{ 'S.no' }}</hlx-table-head
              > -->
              <hlx-table-head
                v-for="(i, index) in accounts_table_theads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                 @new-resize-width="resizedWidth"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head
              v-if="permisstionData.edit || permisstionData.delete"
                v-show="editMode == true"
                :align="'center'"
                :width="70"
                :fixed="'right'"
                 @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>
            <template #tbody>
              <tr v-for="(i, index) in paginatedData" id="" :key="index">
                <!-- <hlx-table-cell
                  :align="'center'"
                  :fixed="'left'"
                >
                  {{ serialNumber(index) }}
                </hlx-table-cell> -->
                <hlx-table-cell
                  v-for="(j, col_index) in accounts_table_theads"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div
                    v-if="
                      j.prop.toLowerCase() === 'address' && i['contactMedium']
                    "
                    :id="j.prop + index"
                  >
                    <span v-if="i['contactMedium'][3]">
                      <span
                        v-if="
                          i['contactMedium'][3].characteristic?.description !=
                          ''
                        "
                      >
                        {{ i["contactMedium"][3].characteristic?.description }}
                      </span>
                      <span v-else>
                        {{ "-" }}
                      </span>
                    </span>
                  </div>
                  <div
                    v-else-if="j.prop.toLowerCase() === 'name' && permisstionData.edit"
                    @click="
                      $router.push({path:
                        '/CM/accounts/view/' +
                          i[j.prop] +
                          '/' +
                          i['_id'] +
                          '/view/accounts',
                           query: { status: `${i.status}` },
                      }
                      )
                    "
                  >
                    <span class="link-name">
                      {{ i["customName"] ? i["customName"] : i['name']}}
                    </span>
                  </div>
                  <div
                    v-else-if="j.prop.toLowerCase() === 'name' && !permisstionData.edit"
                 
                  >
                    <span >
                      {{ i["customName"] ? i["customName"] : i['name']}}
                    </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'lastupdated'">
                    <span>
                      {{ dateAndTimeFormatter(i["lastUpdate"]) }}
                    </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'status'">
                    <span v-if="i[j.prop] != ''">
                      <div>
                        <hlx-status :type="i[j.prop]"></hlx-status>
                      </div>
                    </span>
                    <span v-else>
                      {{ "-" }}
                    </span>
                  </div>
                  <div
                    v-else-if="
                      j.prop.toLowerCase() === 'contactmedium' &&
                      i['contactMedium']
                    "
                    style="margin: 0.5rem 0"
                  >
                    <div
                      v-if="i['contactMedium'][0]"
                      style="
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        margin-bottom: 4px;
                      "
                    >
                      <i class="icon-envelope-regular"></i>
                      <span>
                        {{ i["contactMedium"][0].characteristic?.emailAddress }}
                      </span>
                    </div>
                    <div
                      v-if="
                        i['contactMedium'][1] && i['contactMedium'][1] != ''
                      "
                      style="display: flex; align-items: center; gap: 0.5rem"
                    >
                      <i
                        v-if="
                          i['contactMedium'][1].characteristic?.phoneNumber
                            .value
                        "
                        class="icon-phone-regular"
                      ></i>
                      <span>
                        {{
                          i["contactMedium"][1].characteristic?.phoneNumber
                            .value
                        }}
                      </span>
                    </div>
                  </div>
                  <div v-else>{{ i[j.prop] }}</div>
                </hlx-table-cell>
                <hlx-table-cell
                  v-show="editMode === true"
                  :align="'center'"
                  :width="120"
                  :fixed="'right'"
                >
                  <span v-if="permisstionData.edit || permisstionData.delete" :id="'table-context' + index" class="action-icon">
                    <i
                      style="position: relative"
                      class="icon-more-vertical-filled"
                      :class="i.context === true ? 'active-action' : ''"
                      @click="crudContextMenu($event, index)"
                    >
                      <hlx-context-menu
                        :top="containertop"
                        :left="containerleft - 20"
                        :conditionvalue="150"
                        :display="'display'"
                        :conditiondown="2"
                        :conditionup="-38"
                        :options="computedTableAction(i)"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="closeCrudContextMenu($event, i, index)"
                      />
                    </i>
                  </span>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>
        <div v-else class="cm-accounts-right-panel-top-section-table-wrap">
          <hlx-table
            :column-count="accounts_table_theads.length + 2"
            :border="['table', 'header']"
            :bold-headers="false"
            :row-hover="true"
            theme="grey"
            :striped-rows="false"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="accounts_table_theads_cc"
            @close-rearrange="toggleRearrangeModal = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <hlx-table-head :width="60" :align="'left'"  @new-resize-width="resizedWidth">{{
                "S.no"
              }}</hlx-table-head>
              <hlx-table-head
                v-for="(i, index) in accounts_table_theads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                style="position: sticky; top: 0px"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                 @new-resize-width="resizedWidth"
              >
                {{ i.label }}
              </hlx-table-head>
              <hlx-table-head v-if="permisstionData.edit || permisstionData.delete" :width="100"  @new-resize-width="resizedWidth">
                <span class="action-icon"
                  ><i
                    class="icon-settings-regular"
                    @click="reAssignOrgTableHeader()"
                  ></i></span
              ></hlx-table-head>
            </template>
            <template #tbody>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell :colspan="accounts_table_theads.length + 2">{{
                  "No data"
                }}</hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>
      </div>
      <div class="cm-accounts-right-panel-pagination-container">
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :label-value=paginationLang
          :total="totalpageno"
          rows-per-page
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    </section>
  </section>

  <!-- Add new account modal -->

  <hlx-modal
    :key="modalKey"
    :modal-active="showAddNewAccountModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'750px'"
    :width="'800px'"
    :modal="true"
    @close="showAddNewAccountModal = false"
  >
    <template #header>
      <div>
        <!-- Add account -->
         {{ loadStaticData('LANG-0092') }}
      </div>
    </template>
    <template #content>
      <div class="modal-content" style="display: flex; gap: 5%">
        <div
          style="
            display: flex;
            flex-direction: column;
            /* justify-content: space-between; */
            gap: 20px;
            width: 50%;
          "
        >
          <!-- <hlxProfileUpload
            :file-type="'.jpg,.jpeg,.png'"
            :file-size-limit="'20mb'"
            :custom-icon="icon"
            :type="'upc-regular'"
            :icon="'icon-arrow-down-to-line-filled'"
            :content="'Import'"
            @file-data="profilpic"
            @delete-image="func"
          /> -->
          <!-- <div
            style="height: 38px; width: 100%; border: 1px dotted #d8d8d8"
          ></div> -->
          <div style="display: flex; justify-content: space-between; gap: 10px">
            <!-- <span
              style="
                height: 93px;
                display: flex;
                width: 93px;
                align-items: center;
                justify-content: center;
                min-width: 93px;
                border: 1px solid #d8d8d8;
                border-radius: 5px;
                font-size: 70px;
                color: #d8d8d8;
              "
            > -->
            <!-- <i class="icon-buildings-alt-regular"></i> -->
            <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png'"
              :file-size-limit="'20mb'"
              :uploadtype="'Rectangle'"
              :style-background="backgrounStyle"
              :height="100"
              :width="100"
              :read-only="false"
              :custom-icon="icon"
              :type="'profile-upload'"
              :name-display="newAccPayload.tradingName"
              :content="'Import'"
              @native-data="profileUpload"
            />
            <!-- </span> -->
            <span style="width: 200%">
              <div
                class="decision-name-wrapper"
                :style="{ 'margin-bottom': customnameerror ? '-7.6%' : 0 }"
                >
                <!-- style="margin-bottom: 25px" -->
                <hlx-input
                  v-model:value="newAccPayload.customName"
                  :pre-val="newAccPayload.customName"
                  :label-value="'Account name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
              <!-- <div class="decision-name-wrapper"> -->
              <!-- <hlx-select
                v-model="newAccPayload.status"
                :pre-value="newAccPayload.status"
                :options="statusList"
                prop-value="name"
                :inline-search="true"
                :search-image-tag="false"
                :placeholder-value="'status'"
                :label-animation="true"
                :clearable="true"
                @search-value="newValFromDropDown($event, 'status')"
              ></hlx-select> -->

              <div style="width: 100%">
                <hlx-status
                  :type="'select-status'"
                  :status-option="filteredstatus"
                  :prevalue="newAccPayload.status"
                  @emit-select="emitStatus1"
                >
                </hlx-status>
              </div>

              <!-- </div> -->
            </span>
          </div>
          <!-- <div class="decision-name-wrapper"> -->
          <addressComponent
            :key="addressKey"
            @address-data="receivedAddressData"
          ></addressComponent>
          <!-- <hlx-input
              v-model:value="newAccPayload.contactMedium[3].description"
              :pre-val="newAccPayload.contactMedium[3].description"
              type="location"
              :params="locationApi"
              :label-value="'Address'"
              :label-animation="true"
              :show-new-address-option="showNewAddressOption"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              :location-data-prop="locationData"
              @address-data="addressData"
              @focus-out="focusOutName"
              @at-input="getLocations"
              @add-new-location="addNewLocation"
            ></hlx-input>
          </div>
          <div
            class="new-address-fields"
            style="display: flex; gap: 12px"
          >
            <hlx-input
              v-model:value="newAccPayload.city"
              :pre-val="newAccPayload.city"
              :label-value="'City'"
              :label-animation="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            />
            <hlx-input
              v-model:value="newAccPayload.state"
              :pre-val="newAccPayload.state"
              :label-value="'State'"
              :label-animation="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            />
          </div>
          <div
            class="new-address-fields"
            style="display: flex; gap: 12px"
          >
            <hlx-input
              v-model:value="newAccPayload.country"
              :pre-val="newAccPayload.country"
              :label-value="'Country'"
              :label-animation="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            />
            <hlx-input
              v-model:value="newAccPayload.postalCode"
              :pre-val="newAccPayload.postalCode"
              :label-value="'Postal code'"
              :label-animation="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            />
          </div> -->
          <div class="decision-name-wrapper">
            <hlx-select
              v-model:value="newAccPayload.partyCharacteristic[0].value"
              :pre-value="newAccPayload.partyCharacteristic[0].value"
              :options="employeeCountList"
              prop-value="name"
              :placeholder-value="'Employees'"
              :label-animation="true"
              :clearable="true"
              :inline-search="true"
              :search-image-tag="false"
              @search-value="newValFromDropDown($event, 'employeeCount')"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
              v-model:value="locationType"
              :pre-value="
                newAccPayload.contactMedium[3]?.characteristic['@type']
              "
              :options="locationTypeList"
              prop-value="name"
              :placeholder-value="'Location type'"
              :label-animation="true"
              :clearable="true"
              :inline-search="true"
              :search-image-tag="false"
              @search-value="newValFromDropDown($event, 'locationType')"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
              v-model:value="newAccPayload.partyCharacteristic[1].value"
              :pre-value="newAccPayload.partyCharacteristic[1].value"
              :options="industryTypeList"
              prop-value="name"
              :placeholder-value="'Industry'"
              :label-animation="true"
              :clearable="true"
              :inline-search="true"
              :search-image-tag="false"
              @search-value="newValFromDropDown($event, 'industry')"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
              v-model:value="accExecParty"
              :pre-value="accExecParty"
              :options="executivesList"
              :placeholder-value="'Account executive'"
              :label="'name'"
              :prop-value="'name'"
              :label-animation="true"
              :search-image-tag="true"
              :sub-title="'company'"
              :tag-value="'newRole'"
              :image-link="'link'"
              :inline-search="true"
              @selected-value="selectedExecutive"
            >
            </hlx-select>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            /* gap: 20px; */
            /* justify-content: space-between; */
            width: 50%;
          "
        >
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="newAccPayload.description"
              :pre-val="newAccPayload.description"
              type="textarea"
              :label-value="'Description'"
              :required="false"
              :label-animation="true"
              :display-error="true"
              :custom-error="customnameerror"
              :rows="7"
            :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper" style="margin-bottom: 20px">
            <hlx-select
            
              v-model:value="newAccPayload.partyCharacteristic[2].value"
              :pre-value="newAccPayload.partyCharacteristic[2].value"
              :options="marketSegmentList"
               prop-value="name"
              :placeholder-value="'Market segment'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper" style="margin-bottom: 20px">
            <hlx-select
            
              v-model:value="newAccPayload.preferred"
              :pre-value="newAccPayload.preferred"
              :options="preferredMediumList"
              prop-value="value"
              :placeholder-value="'Preferred contact medium'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="
                newAccPayload.contactMedium[0].characteristic.emailAddress
              "
               :default-mail-error="'Please enter a valid URL in the format: www.abc.com'"
              :pre-val="
                newAccPayload.contactMedium[0].characteristic.emailAddress
              "
              type="email"
              :label-value="'Email id'"
              :required="true"
              :label-animation="true"
              :display-error="true"
              :custom-error="isExistingEmail"
              :custom-error-message="isExistingEmailErrorMessage"
               @focus-out="validateEmailNew"
              @at-input="validateEmail"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="
                newAccPayload.contactMedium[1].characteristic.phoneNumber
              "              
                    :default-number-error="'Enter a valid phone number'"
              :pre-val="
                newAccPayload.contactMedium[1].characteristic.phoneNumber.value
              "
              type="phonenumber"
              :country-code="
                newAccPayload.contactMedium[1].characteristic.phoneNumber
                  .country
              "
              :label-value="'Phone no.'"
              :label-animation="true"
              :display-error="true"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>

          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="
                newAccPayload.contactMedium[2].characteristic.faxNumber
              "
              :pre-val="newAccPayload.contactMedium[2].characteristic.faxNumber"
              :label-value="'Fax'"
              :label-animation="true"
              :display-error="true"
              :custom-error="customnameerror"
                    :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            {{ websiteRegex(newAccPayload.externalReference[0].name) }}
            <hlx-input
              :key="modalKey"
              v-model:value="newAccPayload.externalReference[0].name"
              :pre-val="newAccPayload.externalReference[0].name"
              :label-value="'Website URL'"
              type="text"
              :prefix="true"
              :label-animation="true"
              :display-error="true"
              :custom-error="isUrlEmpty"
                    :custom-error-message="'Please enter a valid URL in the format: www.abc.com'"
              @focus-out="websiteRegex"
              @at-input="validateName"
            >
              <!-- <template #prefix> https:// </template> -->
            </hlx-input>
          </div>

          <div class="decision-name-wrapper">
            <hlx-multi-select
              :key="modalKey"
              :pre-value="newAccPayload.tags"
              :options="tagsList"
              prop-value="name"
              :multi-select="true"
              :placeholder="'Tags'"
              :label-animation="true"
              :clearable="true"
              :tooltipposition="'left'"
              @add-data="addTagList"
              @custom-change="receivedTags"
            ></hlx-multi-select>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeAddNewAccountModal"
        >
        <!-- Cancel -->
         {{ loadStaticData('LANG-0020') }}
        </hlx-button
      >
      <hlx-button
        :disabled="!canAdd"
        class="primary sm"
        style="margin-left: 10px"
        @click="addNewAccount"
        >
        <!-- Add -->
         {{loadStaticData('LANG-0010') }}
        </hlx-button
      >
    </template>
  </hlx-modal>

  <hlx-modal
    :modal-active="showAddNewAdrressModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'400px'"
    :width="'650px'"
    :modal="true"
    @close="closeAddNewAdrressModal"
  >
    <template #header>
      <div>Add new address</div>
    </template>
    <template #content>
      <div
        class="modal-content add-contact-modal"
        style="
          padding: 20px;
          padding-bottom: 0;
          grid-template-columns: 38.5% 58.5%;
        "
      >
        <span class="left-section">
          <hlx-input
            v-model:value="newAddressPayload.streetNumber"
            :pre-val="newAddressPayload.streetNumber"
            :label-value="'Street number'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isFirstNameEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="newAddressPayload.unitNumber"
            :pre-val="newAddressPayload.unitNumber"
            :label-value="'Unit number'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isLastNameEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <hlx-input
              :key="key"
              v-model:value="newAddressPayload.city"
              :pre-val="newAddressPayload.city"
              :options="designationList"
              :required="true"
              prop-value="name"
              :label-value="'City'"
              :placeholder-value="'City'"
              :label-animation="true"
              :clearable="true"
            ></hlx-input>
          </div>
          <div class="input-wrapper">
            <hlx-input
              v-model:value="newAddressPayload.postalCode"
              :pre-val="newAddressPayload.postalCode"
              :label-value="'Postal code'"
              :label-animation="true"
              :required="true"
              :display-error="true"
              :custom-error="isLastNameEmpty"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
        </span>
        <span class="right-section">
          <hlx-input
            v-model:value="newAddressPayload.streetName"
            :pre-val="newAddressPayload.streetName"
            :label-value="'Street name'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="newAddressPayload.unitName"
            :pre-val="newAddressPayload.unitName"
            :label-value="'Unit name'"
            :label-animation="true"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <div style="display: flex; gap: 0.5rem">
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.state"
                :pre-val="newAddressPayload.state"
                :options="preferredList"
                :label-value="'State'"
                prop-value="value"
                :placeholder-value="'State'"
                :label-animation="true"
                :required="true"
                :clearable="true"
              ></hlx-input>
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.country"
                :pre-val="newAddressPayload.country"
                :options="preferredList"
                prop-value="value"
                :label-value="'Country'"
                :placeholder-value="'Country'"
                :required="true"
                :label-animation="true"
                :clearable="true"
              ></hlx-input>
            </div>
          </div>
          <hlx-input
            v-model:value="newAddressPayload.landmark"
            :pre-val="newAddressPayload.landmark"
            :label-value="'Nearby landmark'"
            :label-animation="true"
          ></hlx-input>
        </span>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeAddNewAdrressModal"
        >cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        :disabled="
          newAddressPayload.city === '' ||
          newAddressPayload.country === '' ||
          newAddressPayload.state === '' ||
          newAddressPayload.postalCode === '' ||
          newAddressPayload.streetNumber === '' ||
          newAddressPayload.streetName === ''
        "
        @click="addNewAddress"
        >Add</hlx-button
      >
    </template>
  </hlx-modal>
</template>

<script>
import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
  // UPCAxiosInstance
} from "@/config/axiosConfig";
import FilterComponent from "../../../components/filterComponent.vue";
import addressComponent from "../../../components/CM/autocompleteComponent.vue";
// import axios from "axios";
// import { mapGetters } from "vuex";

export default {
  components: {
    FilterComponent,
    addressComponent,
  },
  data() {
    return {
      permisstionData:{},
      paginationlabelValue:{
        Rows_per_page : "Rows per page data",
          Showing: "Showings",
          of: "for",
          results: "result",
          Go_to: "Goto",
      },
      filterlabelValue:{
        Filters : "Filters",
          Filters_Applied: "Filters Applied",
          Clear_All: "Clear All",
          selected: "selected",
      },
      SearchlabelValue:"search logo",
      loginUserDetails: {},
      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      isExistingName: false,
      isExistingNameErrorMessage: '',
      isExistingEmailErrorMessage: '',
      isExistingEmail: false,
      filteredstatus: [],
      filtertaglength: 0,
      show_right_filter: false,
      addressKey: 0,
      duplicatetabledata: [],
      searchtabledata: [],
      searchvalue: "",
      filtertagvalue: [],
      company: "",
      totalpageno: 0,
      accountsquery: {
        type: "filter",
        module: "hierarchyaccounts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
          "@baseType": "customer",
        },
        searchQuery: "",
        company: "",
      },
      filterPanel: false,
      modalKey: 0,
      notification: {},
      newAddressPayload: {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      },
      showAddNewAdrressModal: false,
      locationData: [],
      newAccPayload: {
        name: "",
        customName: "",
        parentCompany: "",
        tradingName: "",
        status: "Active",
        preferred: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "Industry",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              address1: "",
              default: true,
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [],
        tags: [],
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        attachment: [],
        organizationParentRelationship: {},
        rootName: "",
      },
      externalRowsPerPage: 10,
      tableData: [],
      accounts_table_theads: [
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 100,
          label: "ID",
          name:"ID",
          prop: "displayID",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 250,
          name:"Name",
          label: "Name",
          prop: "name",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: null,
          disabled:true,
          name:"Address",
          label: "Address",
          prop: "address",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          name:"Status",
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          name:"Contact medium",
          label: "Contact medium",
          prop: "contactMedium",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 170,
          name:"Last updated",
          label: "Last updated",
          prop: "lastUpdated",
          align: "left",
        },
      ],
      accounts_table_theads_cc: [
      {
        checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 100,
          label: "ID",
          name:"ID",
          prop: "displayID",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 250,
          name:"Name",
          label: "Name",
          prop: "name",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          name:"Address",
          label: "Address",
          prop: "address",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          name:"Status",
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          name:"Contact medium",
          label: "Contact medium",
          prop: "contactMedium",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 170,
          name:"Last updated",
          label: "Last updated",
          prop: "lastUpdated",
          align: "left",
        },
      ],
      duplicateTheads: [],
      toggleRearrangeModal: false,
      currPage: 1,
      rowsPerPage: 10,
      editMode: true,
      filterPanelName: "productSpecification",
      showAddNewAccountModal: false,
      payload: {
        name: "",
        tradingName: "",
        status: "Active",
        partyCharacteristic: [
        {
            name: "number of employees",
            value: "",
          },
          {
            name: "Industry",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: "",
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              address1: "",
              default: true,
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        organizationParentRelationship: {},
        rootName: "",
      },
      executivesList: [],
      statusList: [],
      marketSegmentList:[],
      preferredMediumList: [],
      industryTypeList: [],
      employeeCountList: [],
      locationTypeList: [],
      tagsList: [],
      isUrlEmpty: false,
      canAdd: false,
      accExecParty: "",
      locationType: "",
    };
  },
  computed: {
    tableModalLang() {     
      return {
        re_arrange_columns: this.matchFromStatic("Re-arrange columns"),
        Cancel: this.matchFromStatic("Cancel"),
        Apply: this.matchFromStatic("Apply"),
        SelectAll: this.matchFromStatic("Select all"),
      };
      
    },
    filterLang() { 
      
        return {
          Filters : this.loadStaticData('LANG-0094') || "Filters",
          Filters_Applied: this.loadStaticData('LANG-0095') || "Filters Applied",
          Clear_All: this.loadStaticData('LANG-0096') || "Clear All",
          selected: this.loadStaticData('LANG-0097') || "selected",
         
        
      }
    },
    
    paginationLang() {
      return {
        Rows_per_page: this.matchFromStatic("Rows per page"),
        Showing: this.matchFromStatic("Showing"),
        of: this.matchFromStatic("of"),
        results: this.matchFromStatic("results"),
        Go_to: this.matchFromStatic("Go to"),
      };
    },

    paginatedData() {
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      // return this.tableData.slice(start, end);
      return this.tableData;
    },
  },
  watch: {
    "$store.getters.defaultSetting": {
      handler(val) {
        this.newAccPayload.contactMedium[1].characteristic.phoneNumber.country.name =
          val?.country;
        this.newAccPayload.contactMedium[1].characteristic.phoneNumber.country.short =
          val?.cc2;
        this.newAccPayload.contactMedium[1].characteristic.phoneNumber.country.flags =
          val?.flags?.svg;
        console.log(val, "defaultSetting");
      },
      immediate: true,
      deep: true,
    },
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList.filter(
          (item) => item.name === "Active" || item.name === "Inactive"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    locationType: {
      handler(val) {
        this.newAccPayload.contactMedium[3].characteristic["@type"] = val;
      },
      immediate: true,
      deep: true,
    },
    newAccPayload: {
      handler(val) {
        console.log("new account", val);
        val["@baseType"] = "customer";
        val.name = this.sanitizeDatabaseName(val.customName);
        val.companyName = this.sanitizeDatabaseName(val.customName);
        val.tradingName = this.sanitizeDatabaseName(val.customName);
        val.email = val.contactMedium[0].characteristic.emailAddress;
        val.parentCompany = this.company;
        val.rootName = this.company;
        val.contactMedium[3].characteristic["@type"] = this.locationType;
        // console.log('val before watch', this.executivesList);
        // let accExec = this.executivesList.filter((item) => {
        //   return item.name === val.accountExecutive ? item : undefined;
        // });
        // val.relatedParty.splice(0, 1);
        // val.relatedParty.push(accExec[0]);
        // console.log('val after watch', val.relatedParty);
        for (let i = 0; i < val.contactMedium.length; i++) {
          if (val.contactMedium[i].mediumType === val.preferred) {
            val.contactMedium[i].preferred = true;
          } else {
            val.contactMedium[i].preferred = false;
          }
        }
        this.websiteRegex(val.externalReference[0].name);
        if (
          val.name !== undefined &&
          val.name.length > 0 &&
          this.validateEmail(
            val.contactMedium[0].characteristic.emailAddress
          ) &&
          this.validatePhoneNumber(
            val.contactMedium[1].characteristic.phoneNumber
          ) &&
          !this.isUrlEmpty
        ) {
          this.canAdd = true;
        } else {
          this.canAdd = false;
        }
        console.log(val, "payload");
        val.relatedParty.filter((item) => {
          if (item.role === "Account executive") {
            this.accExecParty = item.name;
          }
        });
        // console.log(this.payload);
      },
      immediate: true,
      deep: true,
    },
    accExecParty: {
      async handler(val) {
        console.log(this.company);
        let f = {
          company: `${this.company}`,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {
            "role.name": "Account executive",
            name: {
              $regex: `${val}`,
              $options: "i",
            },
          },
          searchQuery: "",
        };
        let x = await MLTAxiosInstance.post("util/filterData", f);
        console.log(x);
        // let exec = await MLTAxiosInstance.get(
        //   `/partymanagement/individual/listuser?company=${this.company}`
        // );
        console.log(x);

        this.executivesList = x.data.data;
        this.executivesList.forEach((item) => {
          console.log((item.newRole = item.role[0].name));
        });
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() { 

    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${val.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;

console.log(mergedPayload,"mergedPayloadmergedPayload");
 
this.permisstionData =
  mergedPayload.CM.child.Accounts;

  console.log(this.permisstionData,"this.permisstionData");
  



    

    let accountTableHeads = await MLTAxiosInstance.get(`/settings`);

if (accountTableHeads?.data?.entityRef?.["accounts"]) {
  let accountTableHeadModal = accountTableHeads.data.entityRef["accounts"];
  let accountTableHeadsOrg = accountTableHeads.data.entityRef["accounts"].filter(ele => ele.checked);
  console.log(accountTableHeadsOrg,"accountTableHeadModal",accountTableHeadModal)

  if (
    accountTableHeadModal.length === this.accounts_table_theads_cc.length
  ) {
    this.accounts_table_theads_cc = JSON.parse(JSON.stringify(accountTableHeadModal));
      this.accounts_table_theads = JSON.parse(JSON.stringify(accountTableHeadsOrg));
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
      this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
  }
}


    let tagsListData =  await this.getReferanceData('tagsList')
    this.tagsList = tagsListData?.filter((item) => {
      return item.module === "cm";
    });
  //  const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.company = val.data.company;
    console.log(this.company, "c");
    this.newAccPayload.parentCompany = this.company;
    this.accountsquery.company = this.company;
    await this.accountsDataCalling(this.currPage, this.rowsPerPage);
    // this.accountsData();

    // let tableData = await MLTAxiosInstance.get(`/partymanagement/organisation?company=Halleyx`);
    // console.log(tableData.data, 'data');
    // console.log(tableData.data.length, 'length');
    await this.$store.dispatch("initPermissionAnalayzer");

    let f = {
      company: `${this.company}`,
      type: "filter-search",
      module: "hierarchycontacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: {
        "role.name": "Account executive",
        name: {
          $regex: "",
          $options: "i",
        },
      },
      searchQuery: "",
    };
    let x = await MLTAxiosInstance.post("util/filterData", f);
    console.log(x);
    // let exec = await MLTAxiosInstance.get(
    //   `/partymanagement/individual/listuser?company=${this.company}`
    // );
    console.log(x);

    this.executivesList = x.data.data;
    this.executivesList.forEach((item) => {
      console.log((item.newRole = item.role[0].name));
    });
    // let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList =  await this.getReferanceData('statuslist');
    // let preferredList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountPreferredContactMedium`
    // );
    this.preferredMediumList =  await this.getReferanceData('cmAccountPreferredContactMedium');
    console.log(this.preferredMediumList);
    // let marketSegmentList = await DMNAxiosInstance.get(
    //   `mapRef/collection/market`
    // );
    this.marketSegmentList =  await this.getReferanceData('market');
    console.log(this.preferredMediumList);
    // let industryTypeList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountIndustryType`
    // );
    this.industryTypeList =  await this.getReferanceData('cmAccountIndustryType');
    // let employeeCountList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountEmployeeCount`
    // );
    this.employeeCountList =  await this.getReferanceData('cmAccountEmployeeCount');
    // let locationTypeList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountLocationType`
    // );
    this.locationTypeList =  await this.getReferanceData('cmAccountLocationType');

    // if (this.company != '') {
    // }
    
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
  },
  methods: {
    resizedWidth(val){
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.accounts_table_theads);
  updateWidth(this.accounts_table_theads_cc);
console.log(this.accounts_table_theads,"resizedWidth loop",this.accounts_table_theads_cc)
this.applyColumns(this.accounts_table_theads_cc)
    },
    reAssignOrgTableHeader(){
              // If there is change in the table header then ressign to the Orginal data
              if((this.accounts_table_theads_cc != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.accounts_table_theads_cc = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.accounts_table_theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.accounts_table_theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.toggleRearrangeModal=true
    },
    validatePhoneNumber(val) {
      return val.valid;
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    emitStatus1(val1) {
      this.newAccPayload.status = val1["value"];
    },
    sanitizeDatabaseName(databaseName) {
      // Replace dots with an underscore or any other symbol you want
      const sanitized = databaseName?.replace(/\./g, "_");

      // Validate the constraints for MongoDB database names
      const validDatabaseName = sanitized?.replace(/[^a-zA-Z0-9_]/g, "");

      return validDatabaseName;
    },
    async profileUpload(event) {
      console.log(event);
      let selectedFiles = event.target.files[0];
      let formData = new FormData();

      // Append all selected files to FormData object
      formData.append("files", selectedFiles);
      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      console.log(data);

      let obj = {};
      obj.name = data.data[0].filename;
      obj.size = data.data[0].data.attachment.size;
      obj.url = data.data[0].url;
      obj.id = data.data[0].data._id;
      obj["@type"] = "profile";
      // Get the current date and time in milliseconds
      const currentTimestamp = new Date().toJSON();

      // Format the current timestamp in the same format as '1705574420667'
      console.log(currentTimestamp);
      obj.lastUpdated = currentTimestamp;
      this.newAccPayload.attachment = [];
      this.newAccPayload.attachment.push(obj);

      console.log(this.newAccPayload);
    },
    async accountsFilter(filterSelectedvalue, filterQuery) {
      // ;
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      filterQuery.company = this.company;
      filterQuery["filterQuery"]["@baseType"] = "customer";
      if (this.filtertagvalue.length != 0) {
        // this.totalpageno = spectotalpageno
        // this.tableData = spectabledata
        this.accountsquery = filterQuery;
        this.accountsquery.type = "filter";
        if (this.searchvalue != "") {
          this.accountsquery["searchQuery"] = this.searchvalue;
          this.accountsquery["type"] = "filter-search";
        }
        const filterResultData = await MLTAxiosInstance.post(
          `util/filterData`,
          this.accountsquery
        );
        // console.log(filterResultData.data.data,'la');

        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        // ;
      } else {
        this.currPage = 1;
        this.rowsPerPage = 10;
        await this.accountsDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    selectedExecutive(val) {
      let obj = {};
      obj.name = val.name;
      obj.id = val._id;
      obj.role = "Account executive";
      this.newAccPayload.relatedParty.push(obj);
      console.log(val, this.newAccPayload.relatedParty);
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      }
    },
    validateEmailNew() {
      console.log('hleo');
      this.isExistingEmail = false
      this.isExistingEmailErrorMessage = ''
    },
    validateNameNew() {
      console.log('hleo');
      this.isExistingName = false
      this.isExistingNameErrorMessage = ''
    },
    websiteRegex(val) {
      const websiteRegex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

      if (val?.length >= 1) {
        if (!websiteRegex.test(val)) {
          this.isUrlEmpty = true; // URL is invalid
        } else {
          this.isUrlEmpty = false;
        }
      } else {
        this.isUrlEmpty = false;
      }
    },
    receivedAddressData(val) {
      console.log(val);
      if (val.characteristic.country !== "") {
        this.newAccPayload.contactMedium[1].characteristic.phoneNumber
        .country.name = val.characteristic.country
        this.newAccPayload.contactMedium[1].characteristic.phoneNumber
        .country.flags = ""
        this.newAccPayload.contactMedium[1].characteristic.phoneNumber
        .country.short = ""
      }
      let address1 = JSON.parse(JSON.stringify(val));
      // let address2 = JSON.parse(JSON.stringify(val))
      this.newAccPayload.contactMedium[3] = JSON.parse(
        JSON.stringify(address1)
      );
      // address2.mediumType = 'billingLocation'
      // this.newAccPayload.contactMedium.push(address2);
    },
    async addressData(val) {
      console.log(val);
      // let address_components = await axios.get(
      //   `/api/maps/api/place/details/json?place_id=${val.place_id}&key=""`
      // );
      // console.log(address_components);
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = "";
          let format = this.$store.state.defaultSetting.dateFormat;
          if (
            format == "Month DD, YYYY" ||
            format == "mmmm dd, yyyy" ||
            format == "mmmm dd, yyyy"
          ) {
            month = objectDate.toLocaleString("default", { month: "long" });
          } else {
            month = objectDate.toLocaleString("default", { month: "short" });
          }
          let year = objectDate.getFullYear();
          let dateFormat = this.dateFormat(date, month, year, format);
          // let dateformat = month + " " + date + ", " + year;
          return dateFormat;
        }
      }
    },
    async accountsData() {
      console.log("dat");
      const filterResultData = await MLTAxiosInstance.get(
        `partymanagement/organisation/?company=${this.company}`
      );
      this.tableData = filterResultData.data;
      //   this.tableData = this.tableData.sort(
      //   (a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate)
      // );
      //   this.searchtabledata = this.tableData
      //   this.duplicatetabledata = this.tableData
      this.totalpageno = filterResultData.data.length;
    },
    async searchKey(val) {
      this.searchvalue = val;
      this.accountsquery["type"] = "filter-search";
      this.accountsquery["searchQuery"] = this.searchvalue;
      this.currPage = 1;
      this.rowsPerPage = 10;
      if (this.filtertagvalue.length != 0) {
        this.accountsquery["type"] = "filter-search";
      }
      await this.accountsDataCalling(this.currPage, this.rowsPerPage);
      // this.searchvalue = val;
      // if (this.searchvalue && this.searchvalue != '') {
      //   this.tableData = this.searchtabledata.filter((item) => {
      //     if (
      //       item.name != undefined &&
      //       item.description != undefined &&
      //       item.status != undefined &&
      //       this.searchvalue != undefined
      //     ) {
      //       return (
      //         item.name
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchvalue.toLowerCase()) ||
      //         item.description
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchvalue.toLowerCase()) ||
      //         item.status
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchvalue.toLowerCase())
      //       );
      //     }
      //   });
      //   this.duplicatetabledata = this.tableData;
      // } else {
      //   this.tableData = this.searchtabledata;
      //   this.duplicatetabledata = this.searchtabledata;
      // }
    },
    async accountsDataCalling(currentPage, rowsPerPage) {
      const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
      this.company = val.data.company;
      this.accountsquery["company"] = this.company;
      this.accountsquery["paginatedQuery"]["currentPage"] = currentPage;
      this.accountsquery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      const filterResultData = await MLTAxiosInstance.post(
        `util/filterData`,
        this.accountsquery
      );
      console.log(
        filterResultData,
        "specfiltereddata",
        this.accountsquery,
        "alala",
        filterResultData.data.total
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        this.paginatedData;
      }
    },
    async currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
        await this.accountsDataCalling(this.currPage, this.rowsPerPage);
        console.log(this.tableData, "lal");
      }
    },
    async changeRowsPerPage(count) {
      //
      if (this.company != "") {
        this.rowsPerPage = count;
        await this.accountsDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },
    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      obj.module = "cm";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsList = await DMNAxiosInstance.get(`mapRef/collection/tagsList`);
      let arr = this.newAccPayload.tags;
      arr.push(e.name);
      this.newAccPayload.tags = arr;
      this.tagsList = tagsList.data.data.filter((item) => {
        return item.module === "cm";
      });
      // console.log(e);
      // this.tagsList.push(e);
    },
    receivedTags(e) {
      // console.log(
      //   'received',
      //   e,
      //   this.newConPayload.tags.filter((item) => item !== e[0]?.name)
      // );
      // console.log(e,'received');
      let arr = [];
      e.filter((item) => {
        if (item.checked == true) {
          // console.log('received',item.name);
          arr.push(item.name);
        }
      });
      this.newAccPayload.tags = arr;
      // this.newAccPayload.tags = [];
      // e.forEach((ele) => {
      //   if (ele.checked === true) {
      //     this.newAccPayload.tags.push(ele.name);
      //   } else {
      //     this.newAccPayload.tags = this.newAccPayload.tags.filter(
      //       (item) => item !== ele.name
      //     );
      // //   }
      // });
      // this.newConPayload.tags = e.reduce((tags, ele) => {
      //   if (ele.checked) {
      //     tags.push(ele.name);
      //   } else {
      //     tags = tags.filter((item) => item !== ele.name);
      //   }
      //   return tags;
      // }, []);
    },
    closeAddNewAccountModal() {
      this.addressKey += 1;
      let x = {
        name: "",
        tradingName: "",
        parentCompany: "",
        status: "Active",
        partyCharacteristic: [
        {
            name: "number of employees",
            value: "",
          },
          {
            name: "Industry",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              address1: "",
              default: true,
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        organizationParentRelationship: {},
        rootName: "",
      };
      this.accExecParty = "";
      this.modalKey += 1;
      this.newAccPayload = JSON.parse(JSON.stringify(x));
      this.showAddNewAccountModal = false;
    },
    async getLocations() {
      const input =
        this.newAccPayload.contactMedium[3].characteristic.description;
      let result = [];

      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await UPCAxiosInstance.get(
          `/suggestionlist/?input=${input}`
        );
        // console.log(response);
        result = response.data;
        console.log(result);
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      // console.log(result);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.locationData = result;
      if (this.locationData.length === 0 && input !== "") {
        this.showNewAddressOption = false;
      } else {
        this.showNewAddressOption = false;
      }
      return result;
    },
    async addNewAddress() {
      this.newAddressPayload.description =
        this.newAddressPayload.streetNumber +
        ", " +
        this.newAddressPayload.streetName +
        ", " +
        this.newAddressPayload.city +
        ", " +
        this.newAddressPayload.state +
        ", " +
        this.newAddressPayload.country +
        ", " +
        this.newAddressPayload.postalCode +
        ".";
      // console.log(this.newAddressPayload);
      let newAddress = await DMNAxiosInstance.post(
        `mapRef/geoAddress`,
        this.newAddressPayload
      );
      if (newAddress.data.ok === 1) {
        // console.log(this.newAddressPayload.description);
      }
      this.newAccPayload.contactMedium[3].characteristic.description =
        this.newAddressPayload.description;
      this.showAddNewAdrressModal = false;
      this.showAddNewAccountModal = true;
      const x = {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      };
      this.accExecParty = "";
      this.newAddressPayload = JSON.parse(JSON.stringify(x));
    },
    addNewLocation(val) {
      // console.log(val);
      val;
      this.showAddNewAdrressModal = true;
      this.showNewAddressOption = false;
      this.showAddNewAccountModal = false;
    },
    closeAddNewAdrressModal() {
      this.showAddNewAdrressModal = false;
      this.showAddNewAccountModal = true;
    },
    async addNewAccount() {
      console.log(this.newAccPayload);
      let payload = JSON.parse(JSON.stringify(this.newAccPayload));
      if (payload.contactMedium[3].characteristic.description !== "") {
        let billingAddress = JSON.parse(
          JSON.stringify(payload.contactMedium[3])
        );
        let siteAddress = JSON.parse(JSON.stringify(payload.contactMedium[3]));
        billingAddress.mediumType = "billingLocation";
        billingAddress.characteristic.phone =
          payload.contactMedium[1].characteristic;
        billingAddress.characteristic.email =
          payload.contactMedium[0].characteristic;
        billingAddress.characteristic.locationName = payload.name;
        billingAddress.characteristic.default = true;

        siteAddress.mediumType = "siteLocation";
        siteAddress.characteristic.phone =
          payload.contactMedium[1].characteristic;
        siteAddress.characteristic.email =
          payload.contactMedium[0].characteristic;
        siteAddress.characteristic.locationName = payload.name;
        siteAddress.characteristic.default = true;

        payload.contactMedium.push(billingAddress);
        payload.contactMedium.push(siteAddress);
      }
      payload.lastUpdate = new Date();
      let result
      this.isExistingName = false;
          this.isExistingNameErrorMessage = "";
          this.isExistingEmail = false;
          this.isExistingEmailErrorMessage = "";
      try {
        result = await MLTAxiosInstance.post(
          `/partymanagement/individual/account/signup`,
          payload
        );
      } catch (error) {
        console.log(error,error.response.data.message.includes('db already exists with different case'), result);
        if(error.response.data.message.includes('db already exists with different case')) {
          this.isExistingName = true;
          this.isExistingNameErrorMessage = "Account already exists with different case";
          return;
        } else if(error.response.data.message.includes('email_1 dup key')) {
          this.isExistingEmail = true;
          this.isExistingEmailErrorMessage = 
             "This email is already in use";
          return;
        } else {
          this.isExistingName = false;
          this.isExistingNameErrorMessage = "";
          this.isExistingEmail = false;
          this.isExistingEmailErrorMessage = "";
      }
      return
      }
      if (result.data.message == 'Company already exist') {
        this.isExistingName = true;
        this.isExistingNameErrorMessage = "Company name already exists";
        return;
      }
      console.log('new result', result);
      
      this.addressKey += 1;
      this.closeAddNewAccountModal();
      // console.log(result);
      if (result?.data?.tradingName !== "") {
        this.notification = {
          type: "success",
          message: `Nice work! Your new organization "${this.newAccPayload.name}" is now in the list!`,
        };
      }
      // this.accountsData();
      await this.accountsDataCalling();
    },
    addNewAccountModal() {
      this.modalKey += 1;
      this.isUrlEmpty = false;
      this.locationType = "";
      let x = {
        name: "",
        tradingName: "",
        status: "Active",
        partyCharacteristic: [
        {
            name: "number of employees",
            value: "",
          },
          {
            name: "Industry",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              address1: "",
              default: true,
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        organizationParentRelationship: {},
        rootName: "",
        parentCompany: "",
      };
      this.accExecParty = "";
      this.newAccPayload = JSON.parse(JSON.stringify(x));
      this.showAddNewAccountModal = true;
      // this.$router.push('/CM/accounts/add');
    },
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 159;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async closeCrudContextMenu($event, ele, i) {
      i;
      console.log(ele, "kskskjfhkjhhhhhhhhhhhhh hsksdjskjsksjks");
      if ($event.toLowerCase() === "edit") {
        this.$router.push({
          path: `/CM/accounts/view/${ele.name}/${ele._id}/edit/accounts`,
          query: { status: `${ele.status}` },
        });
      } else if ($event.toLowerCase() == "mark as in-active") {
        console.log(ele, "s dfghjk");
        let i = {
          id: ele._id,
          status: "Inactive",
        };
            let ii = {
          filterQuery: {
            company: { $exists: true, $eq: ele.tradingName },
          },
          updatePayload: {
            status: "Inactive",
          },
        };
        const contacts = await MLTAxiosInstance.patch(
          `/partymanagement/organisation/?company=${ele.tradingName}`,
          i
        );
       await MLTAxiosInstance.patch(
          `/partymanagement/individual/batch?company=${ele.tradingName}`,
          ii
        );
        console.log(contacts, "lllalsldddddddddddddddddddddddjsddslk");
        await this.accountsDataCalling(this.currPage, this.rowsPerPage);
        if (contacts.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        await this.accountsDataCalling(this.currPage, this.rowsPerPage);
      } else if ($event.toLowerCase() == "mark as active") {
        let i = {
          id: ele._id,
          status: "Active",
        };
        //   let ii = {
          
        //   filterQuery: {
           
        //       "company":{"$exists":true, "$eq": ele.tradingName}
        //   },
        //   updatePayload: {
        //     status: "Active",
        //   },
        // };
        //  await MLTAxiosInstance.patch(
        //   `/partymanagement/individual/batch?company=${ele.tradingName}`,ii
        // );
        const contacts = await MLTAxiosInstance.patch(
          `/partymanagement/organisation/?company=${ele.tradingName}`,
          i
        );
        if (contacts.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        await this.accountsDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    computedTableAction(data) {
     

      if (this.permisstionData.delete && this.permisstionData.edit ) {
        var options = [
        {
          label: "Edit",
          display: this.loadStaticData('LANG-0009') || "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Impersonate",
          display: this.loadStaticData('LANG-0098') || "Impersonate",
          icon: "icon-user-change-regular",
        },
        {
          label: "Mark as In-active",
          display: this.loadStaticData('LANG-0099') || "Mark as In-active",
          icon: "icon-trash-regular",
        },
        {
          label: "Mark as Active",
          display: this.loadStaticData('LANG-0100') || "Mark as Active",
          icon: "icon-trash-regular",
        },
      ]
      } if (this.permisstionData.delete){
        options =[ {
          label: "Mark as In-active",
          icon: "icon-trash-regular",
        },
        {
          label: "Mark as Active",
          icon: "icon-trash-regular",
        },
      ]
      }else  {
         options = [
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Impersonate",
          icon: "icon-user-change-regular",
        }
      ];
      } 

      return options?.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Mark as Active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1; // return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    showRearrangeModal(val) {
      // Open table rearranging modal window
      this.toggleRearrangeModal = val;
    },
    // arrangeColumns(val) {
    //   this.toggleRearrangeModal = val;
    //   this.updateTheads();
    // },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
   async applyColumns(val) {
    // Func to rearrange table header
    console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef || {};
concatedEntity.accounts = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.accounts_table_theads_cc = entityRef["accounts"]
this.accounts_table_theads = this.accounts_table_theads_cc.filter(ele=>ele.checked)
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
    },
  },
};
</script>
