<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />

  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <div class="add-feature-drawer">
    <hlx-drawer
      :show="drawer"
      position="right"
      :width="400"
      :show-close-icon="true"
      :footer="true"
      :header="true"
      @close="cancelAddfiled()"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <b v-if="editButton == false">Add field</b>
          <b v-else>Edit field</b>
        </div>
        <!-- Feature body -->
        <section class="body-area-feature-drawer">
          <!-- Label name field-->
          <div class="input-wrapper">
            <hlx-input
              v-model:value="addFieldsObject.name"
              type="text"
              :pre-val="addFieldsObject.name"
              :label-value="'Label name'"
              :disabled="configurableValue"
              :read-only="configurableValue"
              :label-animation="true"
              :required="true"
              :custom-error-message="'Please fill the field'"
            ></hlx-input>
          </div>

          <!-- description section-->

          <div class="input-wrapper">
            <hlx-input
              v-model:value="addFieldsObject.description"
              :pre-val="addFieldsObject.description"
              :label-value="'Description'"
              :label-animation="true"
              type="textarea"
              :rows="4"
              :display-error="false"
            ></hlx-input>
          </div>

          <!-- Display type -->
          <div class="input-wrapper">
            <hlx-select
              :key="componentkey"
              v-model:value="addFieldsObject['@type']"
              :pre-value="addFieldsObject['@type']"
              :options="typeOptions"
              :placeholder-value="'Input type'"
              :disabled="configurableValue"
              :required="true"
              :label-animation="true"
              :clearable="true"
              @custom-change="handleFieldsType"
            ></hlx-select>
          </div>

          <!--Mandatory-->
          <div class="input-wrapper">
            <span style="display: flex; align-items: center">
              <hlx-input
                v-model:value="addFieldsObject.mandatory"
                :checked="addFieldsObject.mandatory"
                :read-only="configurableValue"
                :disabled="configurableValue"
                label-value="Mandatory"
                :type="'single-checkbox'"
                @change="configureMode"
              />
              <p
                        class="bg-default border"
                        data-tooltip="This field must be completed for successful submission"
                        data-tooltip-location="bottom"
                        style="width: 100px;"
                      >
                      <i
                class="icon-info-circle-regular"
                style="font-size: 12px; padding: 2px 0px 0px 5px"
              ></i>
                      </p>
            </span>
          </div>

          <!-- options value -->
          <div
            v-if="
              addFieldsObject['@type'] == 'Dropdown' ||
              addFieldsObject['@type'] == 'Radio button' ||
              addFieldsObject['@type'] == 'Multi select'
            "
            class="input-wrapper"
          >
            <section style="display: flex; flex-direction: column">
              <draggable
                class="draggable-feature-values"
                :list="addFieldsObject.characteristicSpecification"
                item-key="id"
              >
                <template #item="{ element, index }">
                  <span
                    class="add-value"
                    style="
                      display: flex;
                      align-items: center;
                      width: 100%;
                      gap: 5px;
                      margin-bottom: 15px;
                    "
                  >
                    <i class="icon-drag-vertical-filled"></i>
                    <span style="width: 100%">
                      <hlx-input
                        v-model:value="
                          addFieldsObject.characteristicSpecification[index]
                            .value
                        "
                        :pre-val="element.value"
                        :label-animation="false"
                        type="text"
                        :clearable="true"
                      />
                    </span>
                    <i
                      class="icon-badge-check-regular"
                      :class="
                        element.default == true ? 'selectedDefaultValue' : ' '
                      "
                      @click="enabledefaultValue(index)"
                    ></i>
                    <i
                      v-if="
                        addFieldsObject.characteristicSpecification?.length > 1
                      "
                      class="icon-trash-regular"
                      @click="deleterowValue(index)"
                    ></i>
                  </span>
                </template>
              </draggable>
              <span
                style="
                  display: flex;
                  color: #54bd95;
                  align-items: center;
                  cursor: pointer;
                  margin-top: 5px;
                "
                @click="addRowVal"
              >
                <i style="margin-right: 5px" class="icon-plus-regular"></i>
                <p>Add value</p>
              </span>
            </section>
          </div>

          <!-- Preview -->
          <section class="feature-preview-area">
            <p class="--title-primary --bold feature-area-title">Preview</p>

            <!-- Dropdown preview -->
            <div
              v-if="addFieldsObject['@type'] == 'Dropdown'"
              class="feature-value-dropdown-preview"
            >
              <hlx-select
                :key="componentkey1"
                :options="addFieldsObject.characteristicSpecification"
                :placeholder-value="addFieldsObject.name"
                :required="addFieldsObject.mandatory"
                :label-animation="true"
                :clearable="true"
                :label="'value'"
                :prop-value="'value'"
                :pre-value="preValFunc()"
              ></hlx-select>
            </div>
            <!----------- date ---------->
            <div
              v-if="addFieldsObject['@type'] == 'Date'"
              class="feature-value-radio-preview"
              :style="[
                addFieldsObject['@type'] == 'Date'
                  ? 'display:block !important'
                  : 'display:flex !important',
              ]"
            >
              <hlx-input
                :key="componentkey"
                v-model:value="addFieldsObject.characteristicSpecification"
                :date-format="'dd (short month), yyyy'"
                :label-value="addFieldsObject.name"
                :required="addFieldsObject.mandatory"
                :label-animation="true"
                type="date"
                icon="calendar-regular"
                icon-position="right"
              ></hlx-input>
            </div>
            <!-- Radio Button -->
            <div v-if="addFieldsObject['@type'] == 'Radio button'">
              <div v-if="addFieldsObject['@type'] == 'Radio button'">
                <b> {{ addFieldsObject.name }}<sup v-if="addFieldsObject.mandatory" style="color:  #ff5050;">*</sup></b>
                <br />
              </div>
              <br />
              <div
                v-if="addFieldsObject['@type'] == 'Radio button'"
                class="feature-value-radio-preview"
              >
                <span>
                  <hlx-input
                    :options="addFieldsObject.characteristicSpecification"
                    :position="'horizontal'"
                    :pre-val="preValFunc()"
                    :required="addFieldsObject.mandatory"
                    :prop-value="'value'"
                    :label="'value'"
                    :type="'border-radio-buttons'"
                  />
                </span>
              </div>
            </div>
            <!-- Single line Text -->
            <div
              v-if="addFieldsObject['@type'] == 'Single line text'"
              class="feature-value-radio-preview"
              :style="[
                addFieldsObject['@type'] == 'Single line text'
                  ? 'display:block !important'
                  : 'display:flex !important',
              ]"
            >
              <hlx-input
                :type="'text'"
                :label-animation="true"
                :label-value="addFieldsObject.name"
                :required="addFieldsObject.mandatory"
              />
            </div>

            <!-- Multi line Text -->
            <div
              v-if="addFieldsObject['@type'] == 'Multi line text'"
              class="feature-value-radio-preview"
              :style="[
                addFieldsObject['@type'] == 'Multi line text'
                  ? 'display:block !important'
                  : 'display:flex !important',
              ]"
            >
              <hlx-input
                :type="'textarea'"
                :label-animation="true"
                :label-value="addFieldsObject.name"
                :required="addFieldsObject.mandatory"
              />
            </div>

            <!-- Multi select -->
            <div
              v-if="addFieldsObject['@type'] == 'Multi select'"
              class="feature-value-radio-preview"
            >
              <hlx-multi-select
                :key="componentkeym1"
             
                :pre-value="preValFunction()"
                :options="addFieldsObject.characteristicSpecification"
                :tooltipposition="'left'"
                :placeholder="addFieldsObject.name"
                :required="addFieldsObject.mandatory"
                :label-animation="true"
                :prop-value="'value'"
                :label="'value'"
                :disabled="false"
                @selected-array="selectedValue"
              ></hlx-multi-select>
            </div>
            <!-- Attachment -->
            <div
              v-if="addFieldsObject['@type'] == 'Attachment'"
              class="feature-value-attachment-preview"
            >
              <attachment
              :key="attachmnetComponetKey " :required="addFieldsObject.mandatory">
                
              </attachment>
            </div>
            <!-- Number -->
            <div
              v-if="addFieldsObject['@type'] == 'Number'"
              class="feature-value-radio-preview"
              :style="[
                addFieldsObject['@type'] == 'Number'
                  ? 'display:block !important'
                  : 'display:flex !important',
              ]"
            >
              <hlx-input
                :type="'number'"
                :round-off="true"
                :label-animation="true"
                :label-value="addFieldsObject.name"
                :required="addFieldsObject.mandatory"
              />
            </div>
            <!-- Decimal -->
            <div
              v-if="addFieldsObject['@type'] == 'Decimal'"
              class="feature-value-radio-preview"
              :style="[
                addFieldsObject['@type'] == 'Decimal'
                  ? 'display:block !important'
                  : 'display:flex !important',
              ]"
            >
              <hlx-input
                :type="'number'"
                :step="0.1"
                :label-animation="true"
                :required="addFieldsObject.mandatory"
                :label-value="addFieldsObject.name"
              />
            </div>

            <!-- Address -->
            <div
              v-if="addFieldsObject['@type'] == 'Address'"
              class="feature-value-radio-preview"
            >
              <addressComponent
                :view="false"
                :required="addFieldsObject.mandatory"
                @address-data="receivedAddressData"
              ></addressComponent>
            </div>
          </section>
        </section>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="cancelAddfiled()"
            >Cancel</hlx-button
          >
          <hlx-button
            v-if="editButton != true"
            class="primary sm"
            style="margin-left: 10px"
            :disabled="
              addFieldsObject.name && addFieldsObject['@type'] ? false : true
            "
            @click="emitaddFields"
            >Add</hlx-button
          >
          <hlx-button
            v-if="editButton == true"
            class="primary sm"
            style="margin-left: 10px"
            :disabled="
              addFieldsObject.name && addFieldsObject['@type'] ? false : true
            "
            @click="emiteditFields"
            >Save</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>

  <section class="layout-seperation">
    <section class="layout-left-panel">
      <div class="left-panel-container" @click="backToFields">
        <i class="icon-arrow-left-regular"></i>
        <p>Back</p>
      </div>
    </section>
    <section class="layout-right-panel">
      <section class="right-panel-container">
        <section class="header">
          <section class="header-left-section">
            <p class="title">Ticket fields</p>
            <p class="sub-title">
              Create fields tailored to your specific needs
            </p>
          </section>
          <section class="header-right-section">
            <span class="ticketserach">
              <hlx-search
                v-model="searchvalue"
                @search-key="TicketSearch"
              ></hlx-search
            ></span>
            <hlx-button class="primary sm" @click="addFieldFunc">
              <i class="icon-plus-circle-regular"></i>
              Add field
            </hlx-button>
          </section>
        </section>
        <section class="content">
          <section class="drag-drop-container">
            <draggable
              class="draggable-fields-value"
              :list="labelFieldsValue"
              item-key="name"
              @change="getComponentData()"
            >
              <template #item="{ element, index }">
                <div class="draggable-field-each-container">
                  <span class="draggable-field-each-container-left-section">
                    <i class="icon-drag-vertical-filled drag-icon"></i>
                    <p class="draggable-field-each-container-field-name" @click="editFieldsdrawer(index, isEnableDelete(element))">
                      {{ element.name }}
                      <sup
                        v-if="element.mandatory === true"
                        class="mandatory-filed-ticket"
                        >*</sup
                      >
                    </p>
                    <hlx-label class="primary sm">{{
                      element["@type"]
                    }}</hlx-label>

                    <i
                      v-if="isEnableDelete(element) == false"
                      class="icon-check-circle-filled ticket-circle-color"
                    ></i>
                  </span>

                  <span class="draggable-field-each-container-right-section">
                    <i
                      class="icon-edit-filled"
                      @click="editFieldsdrawer(index, isEnableDelete(element))"
                    ></i>
                    <i
                      v-if="isEnableDelete(element) == true"
                      class="icon-trash-regular"
                      @click="deleteFields(index)"
                    ></i>
                  </span>
                </div>
              </template>
            </draggable>
          </section>
        </section>
      </section>
    </section>
  </section>
</template>
<script>
import attachment from "@/components/singleEmailComponent.vue";

import draggable from "vuedraggable";
import addressComponent from "@/components/CM/autocompleteComponent.vue";
import { UPCAxiosInstance } from "@/config/axiosConfig";
export default {
  components: {
    attachment,
    draggable,
    addressComponent,
  },
  data() {
    return {
      searchToggle: false,
      componentKey1: 0,
      notification: {},
      previousIndex: 0,
      delete_modal_active: false,
      delete_name: "",
      deleteIndex: null,
      defaultLabelFields: [
        // { name: "Ticket category", type: "Dropdown", configurable: true },
        { name: "Requester", type: "Dropdown", configurable: true },
        { name: "Ticket category", type: "Dropdown", configurable: true },
        {
          name: "Ticket subject",
          type: "Single line text",
          configurable: true,
        },
        { name: "Email id", type: "Single line text", configurable: true },
        { name: "Subject", type: "Single line text", configurable: true },
        { name: "Priority level", type: "Dropdown", configurable: true },
        { name: "Status", type: "Dropdown", configurable: true },
        { name: "Assigned to", type: "Dropdown", configurable: true },
        { name: "Tags", type: "Checkbox", configurable: true },
        { name: "Description", type: "Multi line text", configurable: true },
        { name: "Attachment", type: "Attachment", configurable: true },
      ],
      labelFieldsValue: [],
      duplicatelabelFieldsValue: [],
      drawer: false,
      componentkeym1: 0,
      attachmnetComponetKey: 0,
      componentkey: 0,
      editButton: false,
      addFieldsObject: {
        name: "",
        description: "",
        mandatory: false,
        "@type": "",
        configurable: false,
        characteristicSpecification: [{ value: "", default: false }],
      },
      typeOptions: [
        { name: "Dropdown", value: "Dropdown" },
        { name: "Radio button", value: "Radio button" },
        { name: "Single line text", value: "Single line text" },
        { name: "Multi line text", value: "Multi line text" },
        { name: "Attachment", value: "Attachment" },
        { name: "Multi select", value: "Multi select" },
        { name: "Address", value: "Address" },
        { name: "Number", value: "Number" },
        { name: "Decimal", value: "Decimal" },
        { name: "Date", value: "Date" },
      ],
      editIndex: 0,
      configurableValue: false,
      isDataLoaded: false,
      searchvalue: "",
    };
  },
  watch: {
    addFieldsObject: {
      handler(val) {
        val;
      },
      immediate: true,
      deep: true,
    },
    labelFieldsValue: {
      handler(val) {
        this.Copythevalue = JSON.parse(JSON.stringify(val));
        console.log(val, "labelFieldsValue");
        // val.sort((a, b) => a.priority - b.priority);
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    try {
      // eslint-disable-next-line vue/no-async-in-computed-properties
      const response = await UPCAxiosInstance.get(`/ticketSpec`);
      console.log(response.data, "data");
      this.duplicatelabelFieldsValue = JSON.parse(
        JSON.stringify(response.data)
      ).sort((a, b) => a.priority - b.priority);

      this.labelFieldsValue = response.data.sort(
        (a, b) => a.priority - b.priority
      );
    } catch (error) {
      console.error("Error fetching autocomplete data:", error.message);
    }
    this.isDataLoaded = true;
    //  this.labelFieldsValue = JSON.parse(JSON.stringify(this.defaultLabelFields ))
  },

  methods: {
    TicketSearch(val) {
      if (val) {
        this.searchToggle = true;
        const result = this.searchNoteArray(val.toLowerCase());
        this.labelFieldsValue = result;
      } else {
        this.searchToggle = false;
        this.labelFieldsValue = JSON.parse(
          JSON.stringify(this.duplicatelabelFieldsValue)
        );
      }
    },
    searchNoteArray(val) {
      if (val !== "") {
        const result = this.duplicatelabelFieldsValue.filter((obj) => {
          return Object.values(obj).some((value) => {
            if (typeof value === "string") {
              return value.toLowerCase().includes(val);
            }
            return false;
          });
        });

        return result;
      } else {
        this.labelFieldsValue = JSON.parse(
          JSON.stringify(this.duplicatelabelFieldsValue)
        );
      }
    },
    Ticket(val) {
      this.searchvalue = val;
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.labelFieldsValue,
          val.toLowerCase()
        );
        // console.log(searchData, this.tableData);
        this.labelFieldsValue = JSON.parse(JSON.stringify(searchData));
      } else {
        this.labelFieldsValue = JSON.parse(
          JSON.stringify(this.duplicatelabelFieldsValue)
        );
      }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            // Recursively search nested objects
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    async getComponentData() {
      console.log("getComponentData");
      const mappingdata = this.Copythevalue.map(async (drag, index) => {
        drag.priority = index;
        console.log();
        try {
          // eslint-disable-next-line vue/no-async-in-computed-properties
          const response = await UPCAxiosInstance.patch(`/ticketSpec`, drag);
          console.log(response.data, "data");
          // this.labelFieldsValue = response.data;
        } catch (error) {
          console.error("Error fetching autocomplete data:", error.message);
        }
      });
      mappingdata;
      console.log(this.labelFieldsValue, this.Copythevalue, "mappingdata");
    },
    async fieldsAutoCalling() {
      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await UPCAxiosInstance.get(`/ticketSpec`);
        console.log(response.data, "data");
        this.labelFieldsValue = response.data.sort(
          (a, b) => a.priority - b.priority
        );
        this.duplicatelabelFieldsValue = response.data.sort(
          (a, b) => a.priority - b.priority
        );
        this.searchvalue = "";
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
    },
    backToFields() {
      this.$router.push("/settings/ticket");
    },
    addFieldFunc() {
      this.addFieldsObject = {
        name: "",
        description: "",
        mandatory: false,
        "@type": "",
        configurable: false,
        characteristicSpecification: [{ value: "", default: false }],
      };
      this.drawer = true;
      this.editButton = false;
      this.componentkey += 1;
      this.attachmnetComponetKey += 1;
    },
    preValFunc() {
      console.log("fdsklmkd  fvnkdmclds bjjok");

      let value = "";
      this.addFieldsObject.characteristicSpecification.forEach((item) => {
        if (item.default == true) {
          value = item.value;
        }
      });

      return value;
    },
    preValFunction() {
      console.log("fv dnkmlsckdl");

      let value = [];
      let count = 0;
      this.addFieldsObject.characteristicSpecification.forEach((item) => {
        if (item.default == true) {
          count = 1;
          value.push(item.value);
          // value= item.value
        }
      });
      if (count == 0) {
        value = [];
      }
      console.log(value, "value");

      return value;
    },
    enabledefaultValue(index) {
      this.addFieldsObject.characteristicSpecification[index].default =
        !this.addFieldsObject.characteristicSpecification[index].default;
      if (
        this.addFieldsObject.characteristicSpecification[this.previousIndex] &&
        index != this.previousIndex
      ) {
        this.addFieldsObject.characteristicSpecification[
          this.previousIndex
        ].default = false;
      }
      this.previousIndex = index;
      this.componentkeym1 += 1;
      let prevaluemul = this.preValFunction();
      if (prevaluemul.length === 0) {
        let arr = this.addFieldsObject.characteristicSpecification.map((i) => {
          return { value: i.value };
        });

        this.addFieldsObject.characteristicSpecification = [];
        this.addFieldsObject.characteristicSpecification = arr;
      }
      console.log(
        this.addFieldsObject.characteristicSpecification,
        this.preValFunction(),
        "characteristicSpecification data"
      );
    },
    addRowVal() {
      let valueObject = {
        value: "",
        default: false,
      };

      let length = this.addFieldsObject.characteristicSpecification.length;
      // let lastFeatureValue =
      //     this.addFieldsObject.characteristicSpecification[length - 1];
      // valueObject.valueSuffix = lastFeatureValue.valueSuffix;
      // valueObject.displayValue = lastFeatureValue.valueSuffix;
      console.log(
        length,
        "text",
        this.addFieldsObject.characteristicSpecification[length - 1]
      );
      if (this.addFieldsObject.characteristicSpecification[length - 1].value) {
        this.addFieldsObject.characteristicSpecification.push(valueObject);
      }
    },
    handleFieldsType() {
      let valueObject = [
        {
          value: "",
          default: false,
        },
      ];
      this.addFieldsObject.characteristicSpecification = valueObject;
    },
    deleterowValue(index) {
      if (this.addFieldsObject["@type"] == "Dropdown") {
        this.componentkey1 += 1;
      }
      this.addFieldsObject.characteristicSpecification.splice(index, 1);
    },
    async emitaddFields() {
      console.log(this.addFieldsObject, "data");
      this.addFieldsObject.priority = this.labelFieldsValue.length + 1;
      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await UPCAxiosInstance.post(
          `/ticketSpec`,
          this.addFieldsObject
        );
        console.log(response.data, "data");
        if (response.data.name) {
          this.notification = {
            type: "success",
            message: `Nice work! Your new fields ${this.addFieldsObject.name} is now in the list!`,
          };
        } else if (response.data.message) {
          this.notification = {
            type: "warning",
            message: `Ticket field ${this.addFieldsObject.name} already exists`,
          };
        }
        // this.fieldsAutoCalling()
        // this.labelFieldsValue = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }

      this.fieldsAutoCalling();

      // this.labelFieldsValue.push(this.addFieldsObject)
      this.drawer = false;
      this.cancelAddfiled();
    },
    cancelAddfiled() {
      this.drawer = false;
      this.configurableValue = false;
      this.componentkeyselect += 1;
      this.addFieldsObject = {
        priority: 0,
        name: "",
        description: "",
        mandatory: false,
        "@type": "",
        configurable: false,
        characteristicSpecification: [{ value: "", default: false }],
      };
    },
    editFieldsdrawer(index, mandatory) {
      if (mandatory === false) {
        this.configurableValue = true;
      } else {
        this.configurableValue = false;
      }
      console.log(mandatory, "mandatorymandatory");
      this.addFieldsObject = JSON.parse(
        JSON.stringify(this.labelFieldsValue[index])
      );
      console.log(
        this.addFieldsObject.characteristicSpecification,
        "objectVala"
      );
      if (this.addFieldsObject.characteristicSpecification == undefined) {
        let valueObject = [
          {
            value: "",
            default: false,
          },
        ];
        this.addFieldsObject["characteristicSpecification"] = valueObject;
      }
      console.log(this.addFieldsObject, "objectVala");
      this.editIndex = index;
      this.editButton = true;
      this.drawer = true;
    },
    async emiteditFields() {
      this.configurableValue = false;
      this.addFieldsObject["id"] = this.addFieldsObject._id;
      delete this.addFieldsObject._id;
      console.log(this.addFieldsObject, "data");
      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await UPCAxiosInstance.patch(
          `/ticketSpec`,
          this.addFieldsObject
        );
        console.log(response.data, "data");
        // this.labelFieldsValue = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      this.notification = {
        type: "success",
        message: `All set! Your changes have been saved successfully!`,
      };
      this.fieldsAutoCalling();
      // this.labelFieldsValue[this.editIndex] = this.addFieldsObject
      this.drawer = false;
      this.cancelAddfiled();
    },
    isEnableDelete(element) {
      let count = 0;
      this.defaultLabelFields.filter((item) => {
        if (item.name == element.name) {
          count += 1;
        }
      });
      if (count == 1) {
        return false;
      } else {
        return true;
      }
    },
    deleteFields(index) {
      this.delete_name = this.labelFieldsValue[index].name;
      this.deleteIndex = index;
      this.delete_modal_active = true;
    },
    async deleteExecRow() {
      try {
        let id = this.labelFieldsValue[this.deleteIndex]._id;
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await UPCAxiosInstance.delete(`/ticketSpec/${id}`);
        console.log(response.data, "data");
        // this.labelFieldsValue = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      this.notification = {
        type: "success",
        message: `Done! Your item has been removed.`,
      };
      this.fieldsAutoCalling();
      // this.labelFieldsValue.splice(this.deleteIndex, 1);
      this.delete_modal_active = false;
    },
  },
};
</script>
<style>
.ticketserach {
  margin-right: 14px;
}
</style>
