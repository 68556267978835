<template>
  <div style="display: flex; padding: 10px 19px;    align-items: center;">
    <span
      style="
        /* font-family: Open Sans; */
        font-size: 14px;
        font-weight: 600;
        line-height: 19.07px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      "
      >Suite ruin</span
    >
    <hlx-input
      v-model:value="suiteRunToggle"
      :checked="suiteRunToggle"
      :type="'switch'"
    />
  </div>
  <div v-if="suiteRunToggle === true">
    <div
      style="width: 100%; display: flex; gap: 25px; padding: 19px 19px 0 19px"
    >
      <div style="width: 50%">
        <hlx-input
          v-model:value="numUnits"
          :pre-val="numUnits"
          label-value="Number of Units"
          type="number"
          :clearable="true"
          :label-animation="true"
          :required="true"
          :round-off="true"
          :display-error="true"
          :custom-error="isNameEmpty"
        />
      </div>
      <div style="width: 50%">
        <hlx-input
          v-model:value="unitsPerFloor"
          :pre-val="unitsPerFloor"
          label-value="Number of Units per Floor"
          type="number"
          :clearable="true"
          :label-animation="true"
          :round-off="true"
          :required="true"
          :display-error="true"
          :custom-error="isNameEmpty"
        />
      </div>
    </div>
    <div style="padding-left: 19px; padding-right: 13px; width: 50%">
      <hlx-input
        v-model:value="numFloors"
        :pre-val="numFloors"
        label-value="Number of Floors"
        type="number"
        :clearable="true"
        :label-animation="true"
        :round-off="true"
        :required="true"
        :display-error="true"
        :custom-error="isNameEmpty"
      />
    </div>

    <div class="download-container">
      <span class="download-title">Download</span>
      <i class="icon-download-regular"></i>
      <span class="download-subtitle">Suit run template</span>
    </div>

    <!-- File Upload for Excel -->
    <div class="uploadSuiteRun">
      <div><b>Upload suite run</b></div>
      <div>
        <div>
          <div v-if="!isUploaded || error != ''">
            <hlx-button
              class="secondary sm"
              style="display: block"
              @click="openFileInput"
            >
              {{ "Upload" }}
            </hlx-button>
          </div>

          <input
            ref="fileprofile"
            type="file"
            accept=".xlsx, .xls"
            style="display: none"
            @change="handleFileUpload"
          />
        </div>

        <div v-if="isUploaded && error === ''">
          <div class="box-attachment-suitRun">
            <div>
              <span
                v-if="getFileExtension(fileName) === 'xlsx'"
                class="upload_component_icon"
              >
                <img
                  class="file-icon"
                  src="@/assets/svg/multiUpload/excel.svg"
                  alt=""
                />
              </span>
            </div>
            <div style="display: flex; flex-direction: column">
              <span
                style="
                  font-weight: 400;
                  font-size: 12px;
                  white-space: nowrap;
                  width: 146px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ fileName }}
              </span>
            </div>
            <span
              class="closeIconechat"
              style="margin-left: -7px"
              @click="downloadAttachment(box, index)"
              ><i style="color: #6e6b7b" class="icon-download-regular"></i
            ></span>

            <span>
              <i class="icon-trash-regular" @click="deleteFilename"></i>
            </span>
          </div>
        </div>

        <div v-if="error" class="error">
          {{ error }}
        </div>
      </div>
    </div>
    <div v-if="unitData.length > 0" class="suteDATA-table-container">
      <div class="suteDATA-table-wrapper">
        <table class="suteDATA-table">
          <thead>
            <tr class="suteDATA-table-header">
              <th class="suteDATA-sticky-column">Floor Number</th>
              <th
                v-for="i in unitsPerFloor"
                :key="i"
                class="suteDATA-unit-column"
              >
                Unit number {{ i }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(floor, rowIndex) in unitData"
              :key="rowIndex"
              class="suteDATA-table-row"
            >
              <td class="suteDATA-sticky-column">
                {{ capitalizeFirstLetter(floor.floor) }}
              </td>
              <td
                v-for="(unit, colIndex) in floor.units"
                :key="colIndex"
                class="suteDATA-unit-cell"
              >
                <div style="width: 120px">
                  <hlx-input
                    :pre-val="unit"
                    type="text"
                    required="false"
                    @at-input="updateUnitValue(rowIndex, colIndex, $event)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      numUnits: 15,
      unitsPerFloor: 5,
      unitData: [], // Consolidated data
      error: "",
      fileName: "",
      isUploaded: false,
      suiteRunToggle: false,
    };
  },
  computed: {
    numFloors() {
      if (this.numUnits && this.unitsPerFloor) {
        return Math.ceil(this.numUnits / this.unitsPerFloor);
      }

      return 0;
    },
  },
  methods: {
    deleteFilename() {
      this.fileName = "";
      this.isUploaded = false;
    },
    getFileExtension(filename) {
      const parts = filename.split(".");
      const fileExtension = parts[parts.length - 1];
      return fileExtension;
    },
    openFileInput() {
      this.$refs.fileprofile.click();
    },
    // Manual table generation
    generateTable() {
      this.error = "";

      // Validation
      if (this.numUnits !== this.unitsPerFloor * this.numFloors) {
        this.error =
          "The total number of units must equal Units per Floor × Number of Floors.";
        return;
      }

      // Generate the unit data and directly set it
      this.unitData = Array.from(
        { length: this.numFloors },
        (_, floorIndex) => {
          const floor = floorIndex + 1;
          const units = Array.from(
            { length: this.unitsPerFloor },
            (_, unitIndex) =>
              (floorIndex * this.unitsPerFloor + unitIndex + 1)
                .toString()
                .padStart(4, "0")
          );
          return { floor: `floor ${floor}`, units };
        }
      );
    },

    // Handle Excel file upload
    handleFileUpload(event) {
      this.error = "";

      const file = event.target.files[0];
      if (!file) {
        this.error = "No file selected.";
        return;
      }

      // Log or store the uploaded file name
      this.fileName = file.name;
      console.log("Uploaded File Name:", this.fileName);

      // Check if the file is an Excel file (either .xlsx or .xls)
      const fileType = this.fileName.split(".").pop().toLowerCase();
      if (fileType !== "xlsx" && fileType !== "xls") {
        this.error =
          "Invalid file type. Please upload an Excel file (.xlsx or .xls).";
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.isUploaded = true;
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert sheet to JSON
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Validate and process the data
        this.processExcelData(jsonData);
      };

      reader.readAsArrayBuffer(file);
    },
    // Process and validate the uploaded Excel data
    processExcelData(data) {
      if (data.length === 0 || !data[0].includes("Floor Number")) {
        this.error = "Invalid Excel format. Missing 'Floor Number' header.";
        return;
      }

      // Extract table data from the sheet
      this.unitData = data.slice(1).map((row) => ({
        floor: row[0], // Floor Number
        units: row.slice(1), // Unit Numbers
      }));

      // Set derived values
      this.numFloors = this.unitData.length;
      this.unitsPerFloor = this.unitData[0].units.length;
      this.numUnits = this.numFloors * this.unitsPerFloor;
    },

    // Update unit value
    updateUnitValue(rowIndex, colIndex, newValue) {
      this.unitData[rowIndex].units[colIndex] = newValue;
    },
  },
};
</script>

<style lang="scss">
// .download-container {
//   padding: 0 19px 28px 19px;
//   display: flex;
//   align-items: center;
//   gap: 10px;
//   cursor: pointer;
// }

// .download-title {
//   font-size: 14px;
//   font-weight: 600;
//   line-height: 19.07px;//
//   text-align: left;
//   text-underline-position: from-font;
//   text-decoration-skip-ink: none;
//   cursor: pointer;
// }

// .icon-download-regular {
//   color: rgba(1, 87, 159, 1);
//   font-size: 14px;
//   cursor: pointer;
// }

.download-subtitle {
  color: rgba(1, 87, 159, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}

.uploadSuiteRun {
  padding: 0 19px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.box-attachment-suitRun {
  position: relative;
  width: 230px;
  display: flex;
  height: auto;
  background-color: #f2f5f8;
  border-radius: 6px;
  padding: 6px 8px 6px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  .upload_component_icon {
    display: flex;
    width: 16px;
    .file-icon {
      width: 16px;
    }
  }
}
.error {
  color: red;
  margin-top: 10px;
}

.suteDATA-table-container {
  margin-top: 20px;
  margin: 20px; // Add margin around the table
}

// Add horizontal scrolling
.suteDATA-table-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  border: 1px solid #ddd;
}

.suteDATA-table {
  width: 100%;
  border-collapse: collapse;

  .suteDATA-table-header {
    th {
      /* font-family: Open Sans; */
      font-size: 14px;
      font-weight: 400;
      line-height: 19.07px;
      // text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      padding: 10px 16px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      background: rgba(216, 216, 216, 0.2);
      color: rgba(25, 26, 21, 1);
    }
    .suteDATA-sticky-column {
      border-right: 1px solid #ddd;
    }
  }

  .suteDATA-table-row {
    td {
      padding: 0 0 0 18px;
      text-align: left;
      border: none;
      /* font-family: Open Sans; */
      font-size: 14px;
      font-weight: 400;
      line-height: 19.07px;
      /* text-align: center; */
      text-underline-position: from-font;
      text-decoration-skip-ink: none; // Remove border for data cells
    }

    .suteDATA-sticky-column {
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 1;
      border-right: 1px solid #ddd;
    }

    .suteDATA-unit-column {
      text-align: center;
    }

    .suteDATA-unit-cell {
      padding: 14px;
      // background-color: #f9f9f9;
    }
  }
}
</style>
