import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from '@/config/axiosConfig';
import { createStore } from 'vuex';
import VueCookies from 'vue-cookies';

import { BPMNAxiosInstance } from '@/config/axiosConfig';

export default createStore({
  state: {
    leadMainFilterData: [],
    showVersion: false,
    selectedButton: '',
    bssDashboardData: [],
    bssBarchartData: [],
    bssPiechartQuotes: {},
    bssPiechartOrder: {},
    relatedParty: [],
    priceTypeOptions: [],
    pricePeriodOptions: [],
    pricePeriodLengthOptions: [],
    taxCategoryList: [],
    serviceSpecificationFilterData: [],
    resourceSpecificationFilterData: [],
    productSpecificationFilterData: [],
    productOfferingFilterData: [],
    productOfferingDiscountFilterData: [],
    accountsFilterData: [],
    quoteFilterData: [],
    emailTemplateFilterData: [],
    billFilterData: [],
    workflowFilterData: [],
    suppliersFilterData: [],
    contractFilterData: [],
    contactsFilterData: [],
    taxFilterData: [],
    ticketFilterData: [],
    ticketTemplateFilterData: [],
    serviceQualificationAddressFilterData: [],
    serviceQualificationAddressFilterData2: [],
    loginTenantDetails: [],
    loginUserDetails: [],
    serviceQualificationProductFilterData: [],
    supplierViewFilterData: [],
    loginUser: '',
    tempActiveSideBarItem: '',
    currencyList: [],
    Brand: [],
    Identifier: [],
    defaultSetting: {},
    currencyData: [],
    eloptions: [],
    userInfo: {},
    prestatus: '',
    prechannel: [],
    preplace: [],
    premarket: [],
    catgoryPreValue: [],
    ruleSetData: [],
    activityLog: undefined,
    placeOptions: [],
    filterPrevalue: {},
    channelOptions: [],
    marketOptions: [],
    categoryOptions: [],
    categoryDropDownOptions: [],
    isSidebar: true,
    tokens: '',
    treeData: [],
    user: {},
    persistedOffer: {},
    operators: [
      {
        type: 'integer',
        name: '>',
        value: '>',
        checked: false,
        disabled: false,
      },
      {
        type: 'integer',
        name: '<',
        value: '<',
        checked: false,
        disabled: false,
      },
      {
        type: 'integer',
        name: '>=',
        value: '>=',
        checked: false,
        disabled: false,
      },
      {
        type: 'integer',
        name: '<=',
        value: '<=',
        checked: false,
        disabled: false,
      },
      {
        type: 'integer',
        name: '==',
        value: '==',
        checked: false,
        disabled: false,
      },
      {
        type: 'integer',
        name: '!=',
        value: '!=',
        checked: false,
        disabled: false,
      },
      {
        type: 'integer',
        name: 'between',
        value: 'between',
        checked: false,
        disabled: false,
      },
      {
        type: 'string',
        name: 'is',
        value: 'is',
        checked: false,
        disabled: false,
      },
      {
        type: 'string',
        name: 'contains',
        value: 'contains',
        checked: false,
        disabled: false,
      },
      {
        type: 'string',
        name: 'isNot',
        value: 'isNot',
        checked: false,
        disabled: false,
      },
      {
        type: 'string',
        name: 'isNull',
        value: 'isNull',
        checked: false,
        disabled: false,
      },
      {
        type: 'string',
        name: 'isNotNull',
        value: 'isNotNull',
        checked: false,
        disabled: false,
      },
      {
        type: 'string',
        name: 'startsWith',
        value: 'startsWith',
        checked: false,
        disabled: false,
      },
      {
        type: 'string',
        name: 'endsWith',
        value: 'endsWith',
        checked: false,
        disabled: false,
      },
    ],
    statusOptions: [],
    intellisenceData: {},
    decision: {
      name: '',
      description: '',
      ruleset: [],
      decisions: [],
      decisionSource: {
        type: '',
        name: '',
        origin: '',
      },
      inputMapping: [{ from: { var: '' }, to: '' }],
      outputMapping: [{ from: '', to: '', category: 'basic' }],
    },
    sidebarData: [
      {
        name: 'Dashboard',
        label: 'Dashboard',
        icon: 'icon-grid-web-7-regular',
        description_en: 'Dashboard',
        description_fn: '*Dashboard',
        active: true,
        path: 'dashboard',
      },
      {
        name: 'Rule studio',
        label: 'Rule studio',
        icon: 'icon-code-regular',
        description_en: 'Residential business market ',
        description_fr: '*Residential business market ',
        path: 'rule_studio',
      },
      {
        name: 'Schema',
        label: 'Schema',
        icon: 'icon-desktop-code-regular',
        description_en: 'Small business markets',
        description_fr: '*Small business markets',
        path: 'schemas',
      },
      {
        name: 'Database',
        label: 'Data management',
        icon: 'icon-database-regular',
        description_en: 'Small business markets',
        description_fr: '*Small business markets',
        path: 'reference_data',
      },
      {
        name: 'Execute',
        label: 'Execution data',
        icon: 'icon-file-settings-regular',
        description_en: 'Small business markets',
        description_fr: '*Small business markets',
        path: 'rule_validation',
      },
      {
        name: 'Audit',
        label: 'Audit log',
        icon: 'icon-notebook-regular',
        description_en: 'Audit log',
        description_fr: '*Audit log',
        path: 'audit',
      },
      {
        name: 'Help',
        label: 'Help',
        icon: 'icon-question-circle-regular',
        description_en: 'Setting',
        description_fr: '*Setting',
        separator: true,
        // path: ''
      },
      {
        name: 'setting',
        label: 'Settings',
        icon: 'icon-settings-regular',
        description_en: 'Setting',
        description_fr: '*Setting',
        path: 'settings',
      },
      {
        name: 'logout',
        label: 'Logout',
        icon: 'icon-log-out-regular',
        description_en: 'Logout',
        description_fr: '*Logout',
        path: 'logout',
      },
    ],
    role: null,
    sidebarV2Permissions: [],
    viewPermission: [],
    editPermission: [],
    decisionHelper: {
      outputExpressionMapping: [],
      // currentCa
    },
    listOfSpecification: [],
    productCompleteNess: {
      series: [],
      category: [],
    },
    placeMetrics: {
      total: 0,
      places: [],
    },
    partyWithProductCount: [],
    statusMetrics: {},
    barChartData: {},
    countryList: null,
  },
  getters: {
    getCountryList: (state) => state.countryList,
    isVersionVisible: (state) => state.showVersion,
    getDefaultSettings: (state) => {
      return state.defaultSetting;
    },
    sidebarData: (state) => {
      return state.sidebarData.filter(
        (item) => !state.viewPermission.includes(item.name)
      );
    },
    listOfSpecification: (state) => {
      return state.listOfSpecification;
    },
    currencyList: (state) => {
      return state.currencyList;
    },
    Brand: (state) => {
      return state.Brand;
    },
    Identifier: (state) => {
      return state.Identifier;
    },
    defaultSetting: (state) => {
      console.log('🚀 ~ state:', state);
      return state.defaultSetting;
    },
    currencyData: (state) => {
      return state.currencyData;
    },
    priceTypeOptions: (state) => {
      return state.priceTypeOptions;
    },
    pricePeriodOptions: (state) => {
      return state.pricePeriodOptions;
    },
    pricePeriodLengthOptions: (state) => {
      return state.pricePeriodLengthOptions;
    },
    taxCategoryList: (state) => {
      return state.taxCategoryList;
    },
    sidebarV2Permissions: (state) => {
      return state.sidebarV2Permissions;
    },
    intellisenceData: (state) => {
      return state.intellisenceData;
    },
    ruleSetData: (state) => {
      return state.ruleSetData;
    },
    serviceSpecificationFilterData: (state) => {
      return state.serviceSpecificationFilterData;
    },
    resourceSpecificationFilterData: (state) => {
      return state.resourceSpecificationFilterData;
    },
    productSpecificationFilterData: (state) => {
      return state.productSpecificationFilterData;
    },
    productOfferingFilterData: (state) => {
      return state.productOfferingFilterData;
    },
    productOfferingDiscountFilterData: (state) => {
      return state.productOfferingDiscountFilterData;
    },
    accountsFilterData: (state) => {
      return state.accountsFilterData;
    },
    suppliersFilterData: (state) => {
      return state.suppliersFilterData;
    },
    contractFilterData: (state) => {
      return state.contractFilterData;
    },
    contactsFilterData: (state) => {
      return state.contactsFilterData;
    },
    taxFilterData: (state) => {
      return state.taxFilterData;
    },
    emailTemplateFilterData: (state) => {
      return state.emailTemplateFilterData;
    },
    ticketFilterData: (state) => {
      return state.ticketFilterData;
    },
    ticketTemplateFilterData: (state) => {
      return state.ticketTemplateFilterData;
    },
    serviceQualificationAddressFilterData: (state) => {
      return state.serviceQualificationAddressFilterData;
    },

    serviceQualificationAddressFilterData2: (state) => {
      return state.serviceQualificationAddressFilterData2;
    },
    loginTenantDetails: (state) => {
      return state.loginTenantDetails;
    },
    loginUserDetails: (state) => {
      return state.loginUserDetails;
    },
    serviceQualificationProductFilterData: (state) => {
      return state.serviceQualificationProductFilterData;
    },
    supplierViewFilterData: (state) => {
      return state.supplierViewFilterData;
    },
    leadMainFilterData: (state) => {
      return state.leadMainFilterData;
    },
    quoteFilterData: (state) => {
      return state.quoteFilterData;
    },
    billFilterData: (state) => {
      return state.billFilterData;
    },
    workflowFilterData: (state) => {
      return state.workflowFilterData;
    },
    bssDashboardData: (state) => {
      return state.bssDashboardData;
    },
    bssBarchartData: (state) => {
      return state.bssBarchartData;
    },
    bssPiechartOrder: (state) => {
      return state.bssPiechartOrder;
    },
    bssPiechartQuotes: (state) => {
      return state.bssPiechartQuotes;
    },
    loginUser: (state) => {
      return state.loginUser;
    },
    getTempActiveSideBarItem: (state) => {
      return state.tempActiveSideBarItem;
    },
    statusList: (state) => {
      return state.statusOptions;
    },
    categoryList: (state) => {
      return state.categoryOptions;
    },
    channelList: (state) => {
      return state.channelOptions;
    },
    marketList: (state) => {
      return state.marketOptions;
    },
    placeList: (state) => {
      return state.placeOptions;
    },
  },
  mutations: {
    SET_COUNTRY_LIST(state, payload) {
      state.countryList = payload;
    },
    toggleVersionHistory(state, payload) {
      state.showVersion = payload;
    },
    token(state, tokens) {
      state.tokens = tokens;
    },
    setSelectedButton(state, buttonName) {
      state.selectedButton = buttonName;
    },
    clearSelectedButton(state) {
      state.selectedButton = '';
    },
    setListOfSpecification(state, specification) {
      state.listOfSpecification = specification;
    },
    addToPersistOffer(state, offerData) {
      state.persistedOffer = offerData;
    },
    setPermissions(state, permissionPayload) {
      state.viewPermission = permissionPayload;
    },
    setTempActiveSideBarItem(state, item) {
      state.tempActiveSideBarItem = item;
    },
    intellisenceData(state, data) {
      state.intellisenceData = data;
    },
    filterPreval: (state, data) => {
      state.filterPrevalue = data;
    },
  },
  actions: {
    setCountryList({ commit }, countryList) {
      commit('SET_COUNTRY_LIST', countryList);
    },
    triggerVersionHistory({ commit }, payload) {
      commit('toggleVersionHistory', payload);
    },
    setSelectedButton({ commit }, buttonName) {
      commit('setSelectedButton', buttonName);
    },
    clearSelectedButton({ commit }) {
      commit('clearSelectedButton');
    },
    async initPermissionAnalayzer({ state, dispatch }) {
      //
      if (!VueCookies.get('token')) {
        return;
      }
      const val = await MLTAxiosInstance.get('partymanagement/individual/auth');
      VueCookies.set('user', val.data.userId);
      console.log(
        '🚀 ~ file: index.js:438 ~ initPermissionAnalayzer ~ val:',
        val
      );
      // val.data.company = val.data.company
      // this.ustatserId = val.data.company
      state.user = val.data;
      await dispatch('loadDefaultSettings');
      // state.defaultSetting
      const parties = await MLTAxiosInstance.get(
        `partymanagement/individual/listuser?company=${val.data.company}`
      );
      const loadParty = await parties.data.map((val) => {
        return { name: val.name, value: val.name, _id: val._id };
      });
      state.relatedParty = loadParty;
      // const userInfo = await MLTAxiosInstance.get(
      //   `partymanagement/individual/myinfo/${val.data.userId}`
      // )
      // state.userInfo = userInfo.data
      // console.log(state.userInfo,"from storeeee");
      const logs = await UPCAxiosInstance.get(`activityLog`);
      let imglog = await logs.data;
      imglog = await imglog.map((val) => {
        // val.updatedAt = val.lastUpdated;
        val.imageURL =
          'https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80';
        return val;
      });
      state.activityLog = await imglog.slice(0, 5);
      const access = await MLTAxiosInstance.get(
        `partyRole/get/${val.data.userId}`
      );
      if (
        state.categoryDropDownOptions &&
        state.categoryDropDownOptions.length === 0
      ) {
        // if (val.data.company === 'developerHalleyx') {
        //   const categorydata = await UPCAxiosInstance.get(
        //     `catalogManagement/category/dropdown`
        //   )
        //   // console.log(
        //   //   "🚀 ~ file: index.js:443 ~ initPermissionAnalayzer ~ categorydata:",
        //   //   categorydata
        //   // );
        //   state.categoryDropDownOptions = categorydata.data?.filter(
        //     (item) => item
        //   )
        // } else {
        //   state.categoryDropDownOptions = [
        //     {
        //       value: 'BBM',
        //       label: 'BBM',
        //       children: [
        //         {
        //           value: 'wireline',
        //           label: 'Wireline',
        //           children: [
        //             {
        //               value: 'Cloud',
        //               label: 'Cloud',
        //               children: [
        //                 {
        //                   value: 'Bell cloud',
        //                   label: 'Bell cloud'
        //                 },
        //                 {
        //                   value: 'Managed services',
        //                   label: 'Managed services'
        //                 },
        //                 {
        //                   value: 'Professional services',
        //                   label: 'Professional services'
        //                 }
        //               ]
        //             },
        //             {
        //               value: 'Contact center',
        //               label: 'Contact center',
        //               children: [
        //                 {
        //                   value: 'Voice Select',
        //                   label: 'Voice Select'
        //                 },
        //                 {
        //                   value: 'On Demand Contact Centre',
        //                   label: 'On Demand Contact Centre'
        //                 },
        //                 {
        //                   value:
        //                     'Intelligent Routing (Including SwitchReDirect) - Western Region',
        //                   label:
        //                     'Intelligent Routing (Including SwitchReDirect) - Western Region'
        //                 },
        //                 {
        //                   value:
        //                     'Intelligent Routing (Including SwitchReDirect)',
        //                   label:
        //                     'Intelligent Routing (Including SwitchReDirect)'
        //                 },
        //                 {
        //                   value:
        //                     'Intelligent Routing (Including SwitchReDirect)',
        //                   label:
        //                     'Intelligent Routing (Including SwitchReDirect)'
        //                 },
        //                 {
        //                   value: 'Enhanced Contact Solutions',
        //                   label: 'Enhanced Contact Solutions'
        //                 },
        //                 {
        //                   value: 'Contact Centre - Server Management',
        //                   label: 'Contact Centre - Server Management'
        //                 },
        //                 {
        //                   value: 'Contact Centre - Server Management',
        //                   label: 'Contact Centre - Server Management'
        //                 }
        //               ]
        //             },
        //             {
        //               value: 'infrastructure services',
        //               label: 'Infrastructure services',
        //               children: [
        //                 {
        //                   value: 'Products and services',
        //                   label: 'Products and services'
        //                 }
        //               ]
        //             },
        //             {
        //               value: 'internet and network',
        //               label: 'Internet and network',
        //               children: [
        //                 {
        //                   value: 'Analogue private Line',
        //                   label: 'Analogue private Line',
        //                   children: [
        //                     {
        //                       value: 'Class Signal Channel',
        //                       label: 'Class Signal Channel'
        //                     },
        //                     {
        //                       value: 'Local Access Voice Grade Channel',
        //                       label: 'Local Access Voice Grade Channel'
        //                     },
        //                     {
        //                       value: 'Schedule Channels',
        //                       label: 'Schedule Channels'
        //                     },
        //                     {
        //                       value: 'Voice Grade Analogue Channels',
        //                       label: 'Voice Grade Analogue Channels'
        //                     }
        //                   ]
        //                 },
        //                 {
        //                   value: 'Broadband',
        //                   label: 'Broadband',
        //                   children: [
        //                     {
        //                       value: 'Bandwidth Select',
        //                       label: 'Bandwidth Select'
        //                     },
        //                     {
        //                       value: 'Wireline Ethernet Connectivity Service',
        //                       label: 'Wireline Ethernet Connectivity Service'
        //                     }
        //                   ]
        //                 },
        //                 {
        //                   value: 'Broadcast',
        //                   label: 'Broadcast',
        //                   children: [
        //                     {
        //                       value: 'Analog Video (IX)',
        //                       label: 'Analog Video (IX)'
        //                     },
        //                     {
        //                       value: 'Audio/Radio services',
        //                       label: 'Audio/Radio services'
        //                     },
        //                     {
        //                       value: 'Broadcast Local',
        //                       label: 'Broadcast Local'
        //                     }
        //                   ]
        //                 },
        //                 {
        //                   value: 'Data',
        //                   label: 'Data',
        //                   children: [
        //                     {
        //                       value: 'Schedule 3A',
        //                       label: 'Schedule 3A'
        //                     }
        //                   ]
        //                 },
        //                 {
        //                   value: 'Digital service',
        //                   label: 'Digital service',
        //                   children: [
        //                     {
        //                       value: 'Digital Channel',
        //                       label: 'Digital Channel'
        //                     },
        //                     {
        //                       value:
        //                         'Digital Network Access - DS0-DS3 & Optical Channel',
        //                       label:
        //                         'Digital Network Access - DS0-DS3 & Optical Channel'
        //                     },
        //                     {
        //                       value: 'Digital Private Line',
        //                       label: 'Digital Private Line'
        //                     },
        //                     {
        //                       value: 'Digital Video (IX)',
        //                       label: 'Digital Video (IX)'
        //                     }
        //                   ]
        //                 },
        //                 {
        //                   value: 'Global network',
        //                   label: 'Global network',
        //                   children: [
        //                     {
        //                       value: 'Global Business Internet Dedicated',
        //                       label: 'Global Business Internet Dedicated'
        //                     },
        //                     {
        //                       value: 'Global Business Internet Service',
        //                       label: 'Global Business Internet Service'
        //                     },
        //                     {
        //                       value: 'Global IP VPN',
        //                       label: 'Global IP VPN'
        //                     },
        //                     {
        //                       value: 'Global Managed SD-WAN',
        //                       label: 'Global Managed SD-WAN'
        //                     },
        //                     {
        //                       value: 'Global Private Line',
        //                       label: 'Global Private Line'
        //                     }
        //                   ]
        //                 },
        //                 {
        //                   value: 'Internet',
        //                   label: 'Internet',
        //                   children: [
        //                     {
        //                       value: 'Business Internet Brokered (BIB)',
        //                       label: 'Business Internet Brokered (BIB)'
        //                     },
        //                     {
        //                       value: 'Business Internet Dedicated (BID)',
        //                       label: 'Business Internet Dedicated (BID)'
        //                     },
        //                     {
        //                       value: 'Business Internet Service',
        //                       label: 'Business Internet Service'
        //                     },
        //                     {
        //                       value: 'Business Internet Service (à la carte)',
        //                       label: 'Business Internet Service (à la carte)'
        //                     },
        //                     {
        //                       value: 'Business Internet Service (Legacy)',
        //                       label: 'Business Internet Service (Legacy)'
        //                     },
        //                     {
        //                       value: 'Disaggregated Broadband Service',
        //                       label: 'Disaggregated Broadband Service'
        //                     }
        //                   ]
        //                 },
        //                 {
        //                   value: 'Managed service',
        //                   label: 'Managed service',
        //                   children: [
        //                     {
        //                       value:
        //                         'Managed Infrastructure Service for Network',
        //                       label:
        //                         'Managed Infrastructure Service for Network'
        //                     },
        //                     {
        //                       value:
        //                         'Managed Infrastructure Service for Wireless',
        //                       label:
        //                         'Managed Infrastructure Service for Wireless'
        //                     }
        //                   ]
        //                 },
        //                 {
        //                   value: 'Private network',
        //                   label: 'Private network',
        //                   children: [
        //                     {
        //                       value: 'ATM',
        //                       label: 'ATM'
        //                     },
        //                     {
        //                       value: 'Ethernet (EI)',
        //                       label: 'Ethernet (EI)'
        //                     },
        //                     {
        //                       value: 'Ethernet Internetworking Priority',
        //                       label: 'Ethernet Internetworking Priority'
        //                     },
        //                     {
        //                       value: 'High Speed Metro',
        //                       label: 'High Speed Metro'
        //                     },
        //                     {
        //                       value: 'IP VPN',
        //                       label: 'IP VPN'
        //                     },
        //                     {
        //                       value: 'IP VPN - Wholesale',
        //                       label: 'IP VPN - Wholesale'
        //                     },
        //                     {
        //                       value: 'IP VPN Site Connect',
        //                       label: 'IP VPN Site Connect'
        //                     },
        //                     {
        //                       value: 'IP VPNe - Stop Sell',
        //                       label: 'IP VPNe - Stop Sell'
        //                     },
        //                     {
        //                       value: 'Public Internet VPN - Stop Sell',
        //                       label: 'Public Internet VPN - Stop Sell'
        //                     },
        //                     {
        //                       value: 'Wavelength',
        //                       label: 'Wavelength'
        //                     }
        //                   ]
        //                 },
        //                 {
        //                   value: 'Service',
        //                   label: 'Service',
        //                   children: [
        //                     {
        //                       value: 'Frame relay',
        //                       label: 'Frame relay'
        //                     },
        //                     {
        //                       value: 'Single source - stop sell',
        //                       label: 'Single source - stop sell'
        //                     },
        //                     {
        //                       value: 'site setup',
        //                       label: 'site setup'
        //                     }
        //                   ]
        //                 }
        //               ]
        //             },
        //             {
        //               value: 'security',
        //               label: 'Security',
        //               children: [
        //                 {
        //                   value: 'Cloud security',
        //                   label: 'Cloud security'
        //                 },
        //                 {
        //                   value: 'Managed services',
        //                   label: 'Managed services'
        //                 },
        //                 {
        //                   value: 'Networks services',
        //                   label: 'Networks services'
        //                 },
        //                 {
        //                   value: 'Network security',
        //                   label: 'Network security'
        //                 },
        //                 {
        //                   value: 'Professional security',
        //                   label: 'Professional security'
        //                 }
        //               ]
        //             },
        //             {
        //               value: 'voice and unified communications',
        //               label: 'Voice and unified communications',
        //               children: [
        //                 {
        //                   value: 'Collaboration and conferenccing',
        //                   label: 'Collaboration and conferenccing'
        //                 },
        //                 {
        //                   value: 'Managed services',
        //                   label: 'Managed services'
        //                 },
        //                 {
        //                   value: 'Professional services',
        //                   label: 'Professional services'
        //                 },
        //                 {
        //                   value: 'unified communication',
        //                   label: 'unified communication'
        //                 },
        //                 {
        //                   value: 'voice',
        //                   label: 'voice'
        //                 }
        //               ]
        //             }
        //           ]
        //         },
        //         {
        //           value: 'wireless',
        //           label: 'wireless',
        //           children: [
        //             {
        //               value: 'IOT',
        //               label: 'IOT',
        //               children: [
        //                 {
        //                   value: 'Add on',
        //                   label: 'Add on',
        //                   children: [
        //                     {
        //                       value: 'Bobo',
        //                       label: 'Bobo'
        //                     },
        //                     {
        //                       value: 'bobo energy',
        //                       label: 'bobo energy'
        //                     },
        //                     {
        //                       value: 'bobo manufacturing',
        //                       label: 'bobo manufacturing'
        //                     },
        //                     {
        //                       value: 'bobo profrssional service',
        //                       label: 'bobo profrssional service'
        //                     },
        //                     {
        //                       value: 'bobo smartcities',
        //                       label: 'bobo smartcities'
        //                     },
        //                     {
        //                       value: 'bobo transporation',
        //                       label: 'bobo transporation'
        //                     },
        //                     {
        //                       value: 'data',
        //                       label: 'data'
        //                     },
        //                     {
        //                       value: 'data solution',
        //                       label: 'data solution'
        //                     },
        //                     {
        //                       value: 'Iot other',
        //                       label: 'Iot other'
        //                     },
        //                     {
        //                       value: 'smart connect solution',
        //                       label: 'smart connect solution'
        //                     },
        //                     {
        //                       value: 'vas',
        //                       label: 'vas'
        //                     }
        //                   ]
        //                 },
        //                 {
        //                   value: 'Hardware',
        //                   label: 'Hardware',
        //                   children: [
        //                     {
        //                       value: 'Data',
        //                       label: 'Data'
        //                     },
        //                     {
        //                       value: 'Data Solution',
        //                       label: 'Data Solution'
        //                     },
        //                     {
        //                       value: 'One Time fee',
        //                       label: 'One Time fee'
        //                     },
        //                     {
        //                       value: 'professional services',
        //                       label: 'Professional services',
        //                       children: [
        //                         {
        //                           value: 'Data center consulting',
        //                           label: 'Data center consulting'
        //                         },
        //                         {
        //                           value: 'Data centre integration service',
        //                           label: 'Data centre integration service'
        //                         },
        //                         {
        //                           value: 'Digital transformation services',
        //                           label: 'Digital transformation services'
        //                         }
        //                       ]
        //                     }
        //                   ]
        //                 },
        //                 {
        //                   value: 'Price plan',
        //                   label: 'Price plan',
        //                   children: [
        //                     {
        //                       value: 'Bobo',
        //                       label: 'Bobo'
        //                     },
        //                     {
        //                       value: 'Connectivity',
        //                       label: 'Connectivity'
        //                     },
        //                     {
        //                       value: 'Smartcity',
        //                       label: 'Smartcity'
        //                     }
        //                   ]
        //                 }
        //               ]
        //             },
        //             {
        //               value: 'Mobility',
        //               label: 'Mobility',
        //               children: [
        //                 {
        //                   value: 'Apple business manager',
        //                   label: 'Apple business manager'
        //                 },
        //                 {
        //                   value: 'Add on',
        //                   label: 'Add on',
        //                   children: [
        //                     {
        //                       value: 'Bobo',
        //                       label: 'Bobo'
        //                     },
        //                     {
        //                       value: 'bobo energy',
        //                       label: 'bobo energy'
        //                     },
        //                     {
        //                       value: 'bobo manufacturing',
        //                       label: 'bobo manufacturing'
        //                     },
        //                     {
        //                       value: 'bobo profrssional service',
        //                       label: 'bobo profrssional service'
        //                     },
        //                     {
        //                       value: 'bobo smartcities',
        //                       label: 'bobo smartcities'
        //                     },
        //                     {
        //                       value: 'bobo transporation',
        //                       label: 'bobo transporation'
        //                     },
        //                     {
        //                       value: 'data',
        //                       label: 'data'
        //                     },
        //                     {
        //                       value: 'data solution',
        //                       label: 'data solution'
        //                     },
        //                     {
        //                       value: 'Iot other',
        //                       label: 'Iot other'
        //                     },
        //                     {
        //                       value: 'smart connect solution',
        //                       label: 'smart connect solution'
        //                     },
        //                     {
        //                       value: 'vas',
        //                       label: 'vas'
        //                     }
        //                   ]
        //                 },
        //                 {
        //                   value: 'Corporate smartphone care',
        //                   label: 'Corporate smartphone care'
        //                 },
        //                 {
        //                   value: 'mobile threat defense',
        //                   label: 'Mobile threat defense'
        //                 },
        //                 {
        //                   value: 'samsung knox',
        //                   label: 'Samsung knox'
        //                 }
        //               ]
        //             }
        //           ]
        //         }
        //       ]
        //     }
        //   ]
        // }
        // console.log(
        //   "🚀 ~ file: index.js:445 ~ initPermissionAnalayzer ~ state.categoryDropDownOptions:",
        //   state.categoryDropDownOptions
        // );
      }
      //
      const arr = access.data;
      state.role = access.data.role;
      const obj = {};
      console.log(arr, 'ghj');
      if (state.role == 'Upc admin') {
        obj.DMN = arr?.permission?.Dmn?.view;
        console.log(
          '🚀 ~ initPermissionAnalayzer ~ arr.permission?.Dmn.view:',
          arr.permission?.Dmn?.view
        );
        obj.UPC = arr?.permission?.UPC?.view;
        obj.CM = arr?.permission?.CM?.view;
        obj.Quotes = arr?.permission?.Quotes?.view;
        obj.Orders = arr?.permission?.Orders?.view;
        obj.Inventory = arr?.permission?.Inventory?.view;
        obj.Billing = arr?.permission?.Billing?.view;
        obj.Settings = arr?.permission?.Settings?.view;
        obj.SM = arr?.permission?.SM?.view;
        obj.TicketManagement = arr?.permission?.TicketManagement?.view;
        obj['Rule studio'] = arr?.permission?.Rules?.view;
        obj.Schema = arr?.permission?.Schema?.view;
        obj['Rule validation'] = arr?.permission?.Test_data?.view;
        obj['Schema'] = arr?.permission?.Schema?.view;
        obj['Data management'] = arr?.permission['Data management']?.view;
        // obj.Settings = arr.permission?.User_settings.view;

        obj['Service qualification'] =
          arr?.permission['Service qualification']?.view;
        obj['Multi dwelling unit'] =
          arr?.permission['Multi dwelling unit']?.view;
      } else if (state.role?.toLowerCase() == 'supplier') {
        obj.overview = arr.permission.overview.view;
        obj.location = arr.permission.location.view;
        obj.product = arr.permission.product.view;
        obj.discount = arr.permission.discount.view;
        obj.contact = arr.permission.contact.view;
        obj.contract = arr.permission.contract.view;
        obj.purchaseOrder = arr.permission.purchaseOrder.view;
        obj.activityLog = arr.permission.activityLog.view;
        obj.Settings = arr.permission.User_settings.view;
      } else if (state.role?.toLowerCase() == 'customer-contact') {
        obj.TicketManagement = arr.permission.TicketManagement.view;
        obj.DMN = arr.permission?.Dmn.view;
        console.log(
          '🚀 ~ initPermissionAnalayzer ~ arr.permission?.Dmn.view:',
          arr.permission
        );
        obj.UPC = arr.permission.Upc.view;
        obj.CM = arr.permission.CM.view;
        obj.Quotes = arr.permission.Quotes.view;
        obj.Orders = arr.permission.Orders.view;
        obj.Inventory = arr.permission.Inventory.view;
        obj.Billing = arr.permission.Billing.view;
        obj.Settings = arr.permission.Settings.view;
        obj.SM = arr.permission.SM.view;
        // obj.TicketManagement = arr.permission.TicketManagement.view;
        obj['Rule studio'] = arr.permission.Rules.view;
        obj.Schema = arr.permission.Schema.view;
        obj['Rule validation'] = arr.permission.Test_data.view;
        obj['Schema'] = arr.permission?.Schema.view;
        obj['Data management'] = arr.permission['Data management'].view;
        // obj.Settings = arr.permission?.User_settings.view;

        obj['Service qualification'] =
          arr.permission['Service qualification'].view;
      } else {
        obj.DMN = arr?.permission?.Dmn?.view;
        console.log(
          '🚀 ~ initPermissionAnalayzer ~ arr.permission?.Dmn.view:',
          arr.permission?.Dmn?.view
        );
        obj.UPC = arr?.permission?.UPC?.view;
        obj.CM = arr?.permission?.CM?.view;
        obj.Quotes = arr?.permission?.Quotes?.view;
        obj.Orders = arr?.permission?.Orders?.view;
        obj.Inventory = arr?.permission?.Inventory?.view;
        obj.Billing = arr?.permission?.Billing?.view;
        obj.Settings = arr?.permission?.Settings?.view;
        obj.SM = arr?.permission?.SM?.view;
        obj.TicketManagement = arr?.permission?.TicketManagement?.view;
        obj['Rule studio'] = arr?.permission?.Rules?.view;
        obj.Schema = arr?.permission?.Schema?.view;
        obj['Rule validation'] = arr?.permission?.Test_data?.view;
        obj['Schema'] = arr?.permission?.Schema?.view;
        obj['Data management'] = arr?.permission['Data management']?.view;
        // obj.Settings = arr.permission?.User_settings.view;

        obj['Service qualification'] =
          arr?.permission['Service qualification']?.view;
        obj['Multi dwelling unit'] =
          arr?.permission['Multi dwelling unit']?.view;
      }

      // obj.PCM = arr.permission?.PCM.view
      // console.log(arr.permission, 'sidebar-permissions')
      state.sidebarV2Permissions = obj;
      if (arr && Object.keys(arr).length > 0) {
        if (arr.permission?.User_settings?.edit === false) {
          state.editPermission.push('setting');
        }
        if (
          arr.permission?.User_settings?.view === false ||
          arr.permission?.User_settings?.view === 'false'
        ) {
          state.viewPermission.push('setting');
        }
        if (arr.permission?.Schema?.edit === false) {
          state.editPermission.push('schema');
        }
        if (
          arr.permission?.Schema?.view === false ||
          arr.permission?.Schema?.view === 'false'
        ) {
          state.viewPermission.push('Schema');
        }
        if (
          arr.permission?.Test_data?.view === false ||
          arr.permission?.Test_data?.view === 'false'
        ) {
          state.viewPermission.push('Database', 'Execute');
        }
        if (arr.permission?.Test_data?.edit === false) {
          state.editPermission.push('testdata');
        }
        if (
          arr.permission?.Rules?.view === false ||
          arr.permission?.Rules?.view === 'false'
        ) {
          state.viewPermission.push('Business rule');
        }
        if (arr.permission?.Rules?.edit === false) {
          state.editPermission.push('rules');
        }
      }
      //
      // console.log(state.viewPermission);

      // For currency
      // const currencyList = await DMNAxiosInstance.get(
      //   'mapRef/collection/Currency'
      // )
      // state.currencyList = currencyList.data?.data || []

      // // For tax
      // const taxList = await DMNAxiosInstance.get(
      //   'mapRef/collection/taxCategory'
      // )
      // state.taxCategoryList = taxList.data?.data || []

      // // For price type
      // const priceType = await DMNAxiosInstance.get(
      //   'mapRef/collection/PriceTypes'
      // )
      // state.priceTypeOptions = priceType.data?.data || []

      // // For price period
      // const pricePeriod = await DMNAxiosInstance.get(
      //   'mapRef/collection/pricePeriod'
      // )
      // state.pricePeriodOptions = pricePeriod.data?.data || []

      // // For price period length
      // const pricePeriodLength = await DMNAxiosInstance.get(
      //   'mapRef/collection/pricePeriodLength'
      // )
      // state.pricePeriodLengthOptions = pricePeriodLength.data?.data || []

      // // For market
      // if (state.marketOptions.length === 0) {
      //   const marketdropdown = await DMNAxiosInstance.get(
      //     'mapRef/collection/market'
      //   )
      //   state.marketOptions = await marketdropdown.data.data
      // }

      // // For category
      // if (state.categoryOptions.length === 0) {
      //   const categorydropdown = await DMNAxiosInstance.get(
      //     'mapRef/collection/category'
      //   )
      //   state.categoryOptions = await categorydropdown.data.data
      // }
      // // For place
      // if (state.placeOptions.length === 0) {
      //   const placedropdown = await DMNAxiosInstance.get(
      //     'mapRef/collection/place'
      //   )
      //   state.placeOptions = await placedropdown.data.data
      // }

      // // For channel
      // if (state.channelOptions.length === 0) {
      //   const channeldropdown = await DMNAxiosInstance.get(
      //     'mapRef/collection/channel'
      //   )
      //   state.channelOptions = await channeldropdown.data.data
      // }

      // const preplace = await DMNAxiosInstance.get(
      //   `/default/Place`
      // )
      // // console.log(preplace, "name");
      // const place = preplace.data[0].name
      // state.preplace.push(place.name)
      // const prechannels = await DMNAxiosInstance.get(
      //   `/default/Channel`
      // )
      // const channel = prechannels.data[0].name
      // state.prechannel.push(channel.name)
      // const premarket = await DMNAxiosInstance.get(
      //   `/default/Market`
      // )
      // const market = premarket.data[0].name
      // state.premarket.push(market.name)
      // const precategory = await DMNAxiosInstance.get(
      //   `/default/Category`
      // )
      // const category = precategory.data[0].name
      // state.catgoryPreValue.push(category.name)
      await DMNAxiosInstance.get(`mapRef/loadReferenceToCache`);
    },
    // async loadCuntryList
    async initSpecificationList({ state }) {
      //
      if (!VueCookies.get('token')) {
        return;
      }
      const val = await MLTAxiosInstance.get('partymanagement/individual/auth');
      // val.data.company = val.data.company
      // this.ustatserId = val.data.company
      state.user = val.data;
      const access = await MLTAxiosInstance.get(
        `partyRole/get/${val.data.userId}`
      );
      //
      const arr = access.data;
      state.role = access.data.role;
      const obj = {};
      if (state.role == 'Upc admin') {
        obj.DMN = arr.permission.Dmn.view;
        obj.UPC = arr.permission.UPC.view;
        obj.CM = arr.permission.CM.view;
        obj.Quotes = arr.permission.Quotes.view;
        obj.Orders = arr.permission.Orders.view;
        obj.Inventory = arr.permission.Inventory.view;
        obj.Billing = arr.permission.Billing.view;
        obj.SM = arr.permission.SM.view;
        obj.TicketManagement = arr.permission.TicketManagement.view;
        // obj.Settings = arr.permission.Settings.view;

        // obj.PCM = arr.permission?.PCM.view
        obj['Rule studio'] = arr.permission?.Rules.view;
        obj.Schema = arr.permission?.Schema.view;
        obj['Rule validation'] = arr.permission?.Test_data.view;
        obj.Settings = arr.permission?.User_settings.view;
        obj['Schema'] = arr.permission?.Schema.view;
        obj['Data management'] = arr.permission['Data management'].view;
        obj['Service qualification'] =
          arr.permission['Service qualification'].view;
        obj['Multi dwelling unit'] = arr.permission['Multi dwelling unit'].view;
      } else if (state.role?.toLowerCase() == 'supplier') {
        obj.overview = arr.permission.overview.view;
        obj.location = arr.permission.location.view;
        obj.product = arr.permission.product.view;
        obj.contact = arr.permission.contact.view;
        obj.contract = arr.permission.contract.view;
        obj.discount = arr.permission.discount.view;
        obj.purchaseAndOrder = arr.permission.purchaseAndOrder.view;
        obj.activityLog = arr.permission.activityLog.view;
      } else if (state.role?.toLowerCase() == 'customer-contact') {
        obj.TicketManagement = arr.permission.TicketManagement.view;
        obj.DMN = arr.permission.Dmn.view;
        obj.UPC = arr.permission.Upc.view;
        obj.CM = arr.permission.CM.view;
        obj.Quotes = arr.permission.Quotes.view;
        obj.Orders = arr.permission.Orders.view;
        obj.Inventory = arr.permission.Inventory.view;
        obj.Billing = arr.permission.Billing.view;
        obj.SM = arr.permission.SM.view;
        // obj.TicketManagement = arr.permission.TicketManagement.view;
        // obj.Settings = arr.permission.Settings.view;

        // obj.PCM = arr.permission?.PCM.view
        obj['Rule studio'] = arr.permission?.Rules.view;
        obj.Schema = arr.permission?.Schema.view;
        obj['Rule validation'] = arr.permission?.Test_data.view;
        obj.Settings = arr.permission?.User_settings.view;
        obj['Schema'] = arr.permission?.Schema.view;
        obj['Data management'] = arr.permission['Data management'].view;
        obj['Service qualification'] =
          arr.permission['Service qualification'].view;
      } else {
        obj.DMN = arr?.permission?.Dmn?.view;
        console.log(
          '🚀 ~ initPermissionAnalayzer ~ arr.permission?.Dmn.view:',
          arr.permission?.Dmn?.view
        );
        obj.UPC = arr?.permission?.UPC?.view;
        obj.CM = arr?.permission?.CM?.view;
        obj.Quotes = arr?.permission?.Quotes?.view;
        obj.Orders = arr?.permission?.Orders?.view;
        obj.Inventory = arr?.permission?.Inventory?.view;
        obj.Billing = arr?.permission?.Billing?.view;
        obj.Settings = arr?.permission?.Settings?.view;
        obj.SM = arr?.permission?.SM?.view;
        obj.TicketManagement = arr?.permission?.TicketManagement?.view;
        obj['Rule studio'] = arr?.permission?.Rules?.view;
        obj.Schema = arr?.permission?.Schema?.view;
        obj['Rule validation'] = arr?.permission?.Test_data?.view;
        obj['Schema'] = arr?.permission?.Schema?.view;
        obj['Data management'] = arr?.permission['Data management']?.view;
        // obj.Settings = arr.permission?.User_settings.view;

        obj['Service qualification'] =
          arr?.permission['Service qualification']?.view;
      }
      // console.log(obj)
      state.sidebarV2Permissions = obj;
      if (arr && Object.keys(arr).length > 0) {
        if (arr.permission?.User_settings?.edit === false) {
          state.editPermission.push('setting');
        }
        if (
          arr.permission?.User_settings.view === false ||
          arr.permission?.User_settings.view === 'false'
        ) {
          state.viewPermission.push('setting');
        }
        if (arr.permission?.Schema.edit === false) {
          state.editPermission.push('schema');
        }
        if (
          arr.permission?.Schema.view === false ||
          arr.permission?.Schema.view === 'false'
        ) {
          state.viewPermission.push('Schema');
        }
        if (
          arr.permission?.Test_data.view === false ||
          arr.permission?.Test_data.view === 'false'
        ) {
          state.viewPermission.push('Database', 'Execute');
        }
        if (arr.permission?.Test_data.edit === false) {
          state.editPermission.push('testdata');
        }
        if (
          arr.permission?.Rules.view === false ||
          arr.permission?.Rules.view === 'false'
        ) {
          state.viewPermission.push('Business rule');
        }
        if (arr.permission?.Rules.edit === false) {
          state.editPermission.push('rules');
        }
      }
      //
      // console.log(state.viewPermission);
    },
    async initRuleSetData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const ruleSetRes = await DMNAxiosInstance.get('rule_engine/rule_set');
        state.ruleSetData = ruleSetRes.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async Brand({ state }) {
      try {
        // const brandname = await DMNAxiosInstance.get('mapRef/collection/Brand');
        // console.log(brandname, 'data')
        state.Brand = await this.getReferanceData('Brand');
      } catch (error) {
        console.error(error);
      }
    },
    async Identifier({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const identifiername = await DMNAxiosInstance.get(
          'mapRef/collection/Identifier'
        );
        // console.log(identifiername, 'identifier');
        state.Identifier = await identifiername.data.data;
        // console.log(await this.getReferanceData('Identifier'),"tbersabtfdg dvfrfdes");
      } catch (error) {
        console.error(error);
      }
    },
    async loadDefaultSettings({ state }) {
      try {
        // const loginUser = await MLTAxiosInstance.get(
        //   "partymanagement/individual/auth"
        // );
        if (!VueCookies.get('token')) {
          return;
        }
        const defaultSetting = await MLTAxiosInstance.get(`/settings`); // console.log(brandname, 'data')
        console.log(
          '🚀 ~ loadDefaultSettings ~ defaultSetting:',
          defaultSetting
        );
        console.log(defaultSetting, 'data');
        state.defaultSetting = defaultSetting.data;
      } catch (error) {
        console.error(error);
      }
    },
    async currencyData({ state }) {
      try {
        // const currencydata = await DMNAxiosInstance.get(
        //   'mapRef/collection/Currency'
        // );
        state.currencyData = (await this.getReferanceData('Currency')) || [];
      } catch (error) {
        return error.message;
      }
    },
    async serviceSpecFilterData({ state }) {
      try {
        // const loginUser = await MLTAxiosInstance.get(
        //   'partymanagement/individual/auth'
        // )
        if (!VueCookies.get('token')) {
          return;
        }
        const obj = {
          layoutFor: 'serviceSpecification',
        };
        const serviceSpecFilterRes = await UPCAxiosInstance.post(
          `util/filterLayout`,
          obj
        );
        state.serviceSpecificationFilterData = serviceSpecFilterRes.data;
      } catch (error) {
        console.error(error);
      }
    },
    async resourceSpecFilterData({ state }) {
      try {
        // const loginUser = await MLTAxiosInstance.get(
        //   'partymanagement/individual/auth'
        // )
        if (!VueCookies.get('token')) {
          return;
        }
        const obj = {
          layoutFor: 'resourceSpecification',
        };
        const resourceSpecFilterRes = await UPCAxiosInstance.post(
          `util/filterLayout`,
          obj
        );
        state.resourceSpecificationFilterData = resourceSpecFilterRes.data;
      } catch (error) {
        console.error(error);
      }
    },
    async productSpecFilterData({ state }) {
      try {
        // const loginUser = await MLTAxiosInstance.get(
        //   'partymanagement/individual/auth'
        // )
        if (!VueCookies.get('token')) {
          return;
        }
        const obj = {
          layoutFor: 'productSpecification',
        };
        const productSpecFilterRes = await UPCAxiosInstance.post(
          `util/filterLayout`,
          obj
        );
        state.productSpecificationFilterData = productSpecFilterRes.data;
      } catch (error) {
        console.error(error);
      }
    },
    async productOfferFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'productOffering',
          layout: [
            {
              prop: 'lifecycleStatus',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'channel',
              label: 'Channel',
              type: 'Array',
              fieldToFilter: 'name',
            },
            {
              prop: 'category',
              label: 'Category',
              type: 'Array',
            },
            {
              prop: 'marketSegment',
              label: 'Market segment',
              type: 'Array',
              fieldToFilter: 'name',
            },
            {
              prop: 'productOfferingPrice',
              label: 'Product offering price',
              type: 'Array',
              fieldToFilter: 'slider',
            },
          ],
        };
        const minmax = await UPCAxiosInstance.get(
          `catalogManagement/productOffering/getMinMax`
        );
        console.log(minmax, 'minmax');
        let arr = [];
        arr.push(minmax.data);
        let productofferFilterRes = await UPCAxiosInstance.post(
          `util/filterLayout`,
          obj
        );
        let value = productofferFilterRes.data;
        value['Product offering price'] = arr;
        console.log(productofferFilterRes, 'daalalalkandalkjdlakjalkjaljaadl');
        state.productOfferingFilterData = value;
      } catch (error) {
        console.error(error);
      }
    },
    async discountOffersData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'discountOffers',
          layout: [
            {
              prop: 'lifecycleStatus',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'channel',
              label: 'Channel',
              type: 'Array',
              fieldToFilter: 'name',
            },
            {
              prop: 'category',
              label: 'Category',
              type: 'Array',
            },
            {
              prop: 'marketSegment',
              label: 'Market segment',
              type: 'Array',
              fieldToFilter: 'name',
            },
            {
              prop: 'productOfferingPrice',
              label: 'Product offering price',
              type: 'Array',
              fieldToFilter: 'slider',
            },
          ],
        };
        const minmax = await UPCAxiosInstance.get(
          `catalogManagement/productOffering/getMinMax`
        );
        console.log(minmax, 'minmax');
        let arr = [];
        arr.push(minmax.data);
        let productofferFilterRes = await UPCAxiosInstance.post(
          `util/filterLayout`,
          obj
        );
        let value = productofferFilterRes.data;
        value['Product offering price'] = arr;
        console.log(productofferFilterRes, 'daalalalkandalkjdlakjalkjaljaadl');
        state.productOfferingDiscountFilterData = value;
        // state.productOfferingDiscountFilterData = productofferFilterRes.data;
      } catch (error) {
        console.error(error);
      }
    },
    async accountFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'accounts',
          layout: [
            {
              prop: 'status',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'contactMedium',
              label: 'Contact medium',
              type: 'Array',
              fieldToFilter: 'mediumType',
            },
          ],
        };
        const accountFilterRes = await MLTAxiosInstance.post(
          'util/filterLayout',
          obj
        );
        state.accountsFilterData = accountFilterRes.data;
        console.log(accountFilterRes.data, 'accountFilterRes');
      } catch (error) {
        console.error(error);
      }
    },

    // async supplierFilterData({ state }) {
    //   try {
    //     console.log("kjhfj");
    //     const loginUser = await MLTAxiosInstance.get(
    //       "partymanagement/individual/auth"
    //     );
    //     const obj = {
    //       company: loginUser.data.company,
    //       // "@baseType": "supplier",
    //       layoutFor: "productoffering",
    //       layout: [
    //         {
    //           prop: "lifecycleStatus",
    //           label: "Status",
    //           type: "String",
    //           fieldToFilter: "lifecycleStatus",
    //         },
    //         {
    //           prop: "category",
    //           label: "Category",
    //           type: "Array",
    //           // fieldToFilter: "name"
    //         },
    //         {
    //           prop: "productOfferingPrice",
    //           label: "price",
    //           type: "Array",
    //           fieldToFilter: "name",
    //         },
    //       ],
    //     };
    //     const supplierFilterRes = await UPCAxiosInstance.post(
    //       "util/filterLayout",
    //       obj
    //     );
    //     state.suppliersFilterData = supplierFilterRes.data;
    //     console.log("supplier filter ", supplierFilterRes.data);
    //     console.log("supplier filter 1",supplierFilterRes)
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    async contactFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'contacts',
          layout: [
            {
              prop: 'status',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'contactMedium',
              label: 'Contact medium',
              type: 'Array',
              fieldToFilter: 'mediumType',
            },
          ],
        };
        const contactFilterRes = await MLTAxiosInstance.post(
          'util/filterLayout',
          obj
        );
        state.contactsFilterData = contactFilterRes.data;
        console.log(contactFilterRes.data, 'contactFilterRes');
      } catch (error) {
        console.error(error);
      }
    },
    async taxFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'tax',
          layout: [
            {
              prop: 'locationRef',
              label: 'Tax criteria',
              type: 'String',
              fieldToFilter: 'locationRef',
            },
            {
              prop: 'taxType',
              label: 'Tax calculation',
              type: 'String',
              fieldToFilter: 'taxType',
            },
            {
              prop: 'country.name.common',
              label: 'Country',
              type: 'Object',
              fieldToFilter: '',
            },
            {
              prop: 'province',
              label: 'Province',
              type: 'Array',
              fieldToFilter: 'name',
            },
          ],
        };
        const taxFilterRes = await UPCAxiosInstance.post(
          'util/filterLayout',
          obj
        );
        state.taxFilterData = taxFilterRes.data;
        console.log(taxFilterRes.data, 'taxFilterRes');
      } catch (error) {
        console.error(error);
      }
    },
    async emailTemplateFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'emailtemplate',
          layout: [
            {
              prop: 'name',
              label: 'Name',
              type: 'String',
              fieldToFilter: '',
            },
          ],
        };
        const emailFilterRes = await UPCAxiosInstance.post(
          'util/filterLayout',
          obj
        );
        state.emailTemplateFilterData = emailFilterRes.data;
        console.log(emailFilterRes, 'emailFilterres', obj);
      } catch (error) {
        console.error(error);
      }
    },
    async ticketFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'ticket',
          layout: [
            {
              prop: 'name',
              label: 'Name',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'priority',
              label: 'Priority',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'status',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'relatedParty',
              label: 'Account',
              type: 'Array',
              fieldToFilter: 'company',
            },
            {
              prop: 'troubleTicketSpecification',
              label: 'Assigned to',
              type: 'Array',
              fieldToFilter: 'Assigned to',
            },
            // {
            //   prop: "contactMedium",
            //   label: "Contact medium",
            //   type: "Array",
            //   fieldToFilter: "mediumType",
            // },
          ],
        };
        const ticketFilterRes = await UPCAxiosInstance.post(
          'util/filterLayout',
          obj
        );
        state.ticketFilterData = ticketFilterRes.data;
        console.log(ticketFilterRes.data, 'ticketFilterRes');
      } catch (error) {
        console.error(error);
      }
    },
    async ticketTemplateFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'tickettemplate',
          layout: [
            {
              prop: 'category',
              label: 'Category',
              type: 'Array',
              fieldToFilter: 'name',
            },
            {
              prop: 'lifecycleState',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            },
          ],
        };
        const ticketTemplateData = await UPCAxiosInstance.post(
          'util/filterLayout',
          obj
        );
        state.ticketTemplateFilterData = ticketTemplateData.data;
        console.log(ticketTemplateData.data, 'ticketTemplateData');
      } catch (error) {
        console.error(error);
      }
    },

    async loginTenantDetails({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );

        console.log('loginUser::::', loginUser);

        let loginTenantDetailsRes = await MLTAxiosInstance.get(
          `/partymanagement/organisation/${loginUser.data.userId}?company=${loginUser.data.company}`
        );

        state.loginTenantDetails = loginTenantDetailsRes?.data;
        await DMNAxiosInstance.get(`mapRef/loadReferenceToCache`);
        console.log(
          loginTenantDetailsRes,
          'loginTenantDetailsasas',
          loginUser,
          'loginUserloginUser'
        );
      } catch (error) {
        console.error(error);
      }
    },
    async loginUserDetails({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        let individualRes = await MLTAxiosInstance.get(
          `partymanagement/individual/auth`
        );
        let loginUserDetailsres = await MLTAxiosInstance.get(
          `partymanagement/individual/${individualRes.data.userId}?company=${individualRes.data.company}`
        );

        state.loginUserDetails = loginUserDetailsres?.data;
        console.log(loginUserDetailsres, 'loginUserDetailsres');
      } catch (error) {
        console.error(error);
      }
    },
    async serviceQualificationAddressFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'address',
          layout: [
            {
              prop: 'statusInclusion',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'criteria.type',
              label: 'Type',
              type: 'String',
              fieldToFilter: '',
              //       filterQuery: {
              //   'type': 'inclusion',
              // },
            },
            //     {
            //       "prop": "criteria.typeExclude",
            //       "label": "Type",
            //       "type": "String",
            //       "fieldToFilter": "",
            // //       filterQuery: {
            // //   'type': 'inclusion',
            // // },
            //     }
          ],
        };
        const serviceQualificationAddressFilterData =
          await UPCAxiosInstance.post('util/filterLayout', obj);

        state.serviceQualificationAddressFilterData =
          // this.filterData1(
          serviceQualificationAddressFilterData.data;
        // );
        // state.serviceQualificationAddressFilterData = serviceQualificationAddressFilterData.data;
        console.log(
          state.serviceQualificationAddressFilterData,
          'ticketTemplateData123'
        );
      } catch (error) {
        console.error(error);
      }
    },
    async serviceQualificationAddressFilterData2({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'address',
          layout: [
            // {
            //           prop: 'statusInclusion',
            //           label: 'Status',
            //           type: 'String',
            //           fieldToFilter: '',
            //         },
            //     {
            //       "prop": "criteria.type",
            //       "label": "Type",
            //       "type": "String",
            //       "fieldToFilter": "",
            // //       filterQuery: {
            // //   'type': 'inclusion',
            // // },
            //           },
            {
              prop: 'criteria.typeExclude',
              label: 'Type',
              type: 'String',
              fieldToFilter: '',
              //       filterQuery: {
              //   'type': 'inclusion',
              // },
            },
          ],
        };
        const serviceQualificationAddressFilterData =
          await UPCAxiosInstance.post('util/filterLayout', obj);

        state.serviceQualificationAddressFilterData2 =
          // this.filterData1(
          serviceQualificationAddressFilterData.data;
        // );
        // state.serviceQualificationAddressFilterData = serviceQualificationAddressFilterData.data;
        console.log(
          state.serviceQualificationAddressFilterData,
          'ticketTemplateData123'
        );
      } catch (error) {
        console.error(error);
      }
    },
    async serviceQualificationProductFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'productqualification',
          layout: [
            {
              prop: 'place.criteria.regionName',
              label: 'Region name',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'productRelationship',
              label: 'Products',
              type: 'Array',
              fieldToFilter: 'name',
              // filterQuery: {
              // },
            },
          ],
        };
        const serviceQualificationProductFilterData =
          await UPCAxiosInstance.post('util/filterLayout', obj);
        state.serviceQualificationProductFilterData =
          serviceQualificationProductFilterData.data;
        console.log(
          serviceQualificationProductFilterData.data,
          'ticketTemplateData22'
        );
      } catch (error) {
        console.error(error);
      }
    },
    async leadMainFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'saleslead',
          layout: [
            {
              prop: 'status',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'dwellingtype',
              label: 'Dwelling type',
              type: 'String',
              fieldToFilter: '',
            },
          ],
        };
        const leadFilterRes = await UPCAxiosInstance.post(
          'util/filterLayout',
          obj
        );
        state.leadMainFilterData = leadFilterRes.data;
        console.log(leadFilterRes.data, 'leadMainFilterData');
      } catch (error) {
        console.error(error);
      }
    },
    async supplierViewFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'supplier',
          layout: [
            {
              prop: 'status',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'contactMedium',
              label: 'Contact medium',
              type: 'Array',
              fieldToFilter: 'mediumType',
            },
          ],
        };
        const supplierFilterRes = await MLTAxiosInstance.post(
          'util/filterLayout',
          obj
        );
        state.supplierViewFilterData = supplierFilterRes.data;
        console.log(supplierFilterRes.data, 'supplierFilterRes');
      } catch (error) {
        console.error(error);
      }
    },
    async contractFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'agreement',
          layout: [
            {
              prop: 'status',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            }
          ],
        };
        const contractFilterRes = await UPCAxiosInstance.post(
          'util/filterLayout',
          obj
        );
        state.contractFilterData = contractFilterRes.data;
        console.log(contractFilterRes.data, 'supplierFilterRes');
      } catch (error) {
        console.error(error);
      }
    },
    async quoteFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'quotes',
          layout: [
            {
              prop: 'state',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'relatedParty',
              label: 'Account',
              type: 'Array',
              fieldToFilter: 'name',
              filterQuery: {
                'relatedParty.role': 'Buyer',
              },
            },
            {
              prop: 'relatedParty',
              label: 'Contact',
              type: 'Array',
              fieldToFilter: 'name',
              filterQuery: {
                'relatedParty.role': 'Buyer contact',
                'relatedParty.name': {
                  $ne: '',
                },
              },
            },
          ],
        };
        const quoteFilterDataRes = await UPCAxiosInstance.post(
          'util/filterLayout',
          obj
        );
        state.quoteFilterData = quoteFilterDataRes.data;
        console.log(quoteFilterDataRes.data, 'quoteFilterDataRes');
      } catch (error) {
        console.error(error);
      }
    },
    async workflowFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'workflow',
          layout: [
            {
              prop: 'name',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'contactMedium',
              label: 'Contact medium',
              type: 'Array',
              fieldToFilter: 'mediumType',
            },
          ],
        };
        const workflowFilterData = await BPMNAxiosInstance.post(
          'utils/filterLayout',
          obj
        );
        state.workflowFilterData = workflowFilterData.data;
        console.log(workflowFilterData.data, 'workflowFilterData');
      } catch (error) {
        console.error(error);
      }
    },
    async billFilterData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const loginUser = await MLTAxiosInstance.get(
          'partymanagement/individual/auth'
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: 'customerbill',
          layout: [
            {
              prop: 'state',
              label: 'Status',
              type: 'String',
              fieldToFilter: '',
            },
            {
              prop: 'relatedParty',
              label: 'Account',
              type: 'Array',
              fieldToFilter: 'name',
              filterQuery: {
                'relatedParty.role': 'Buyer',
              },
            },
            {
              prop: 'relatedParty',
              label: 'Contact',
              type: 'Array',
              fieldToFilter: 'name',
              filterQuery: {
                'relatedParty.role': 'Buyer contact',
                'relatedParty.name': {
                  $ne: '',
                },
              },
            },
          ],
        };
        const billFilterData = await UPCAxiosInstance.post(
          'util/filterLayout',
          obj
        );
        state.billFilterData = billFilterData.data;
        console.log(billFilterData.data, 'billFilterData');
      } catch (error) {
        console.error(error);
      }
    },
    async bssDashboardData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const dashboardData = await UPCAxiosInstance.get(
          'BSSSuiteDashboard?period=monthly'
        );
        state.bssDashboardData = dashboardData.data;
        console.log(dashboardData, 'Bss dashboard');
      } catch (error) {
        console.error(error);
      }
    },
    async bssBarchartData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const dashboardData = await UPCAxiosInstance.get(
          'BSSSuiteDashboard?period=monthly'
        );
        let arr = [];
        let barChartData = dashboardData.data[0].revenueByProduct;
        barChartData.forEach((item) => {
          let obj = {};
          arr;
          obj['x'] =
            item.product.name.charAt(0).toUpperCase() +
            item.product.name.slice(1);
          obj['y'] = item.totalRevenue;
          arr.push(obj);
        });
        state.bssBarchartData = arr;
        console.log(dashboardData, 'Bss dashboard');
      } catch (error) {
        console.error(error);
      }
    },
    async bssPiechartOrder({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const dashboardData = await UPCAxiosInstance.get(
          'BSSSuiteDashboard?period=monthly'
        );
        let arr = [];
        let arr1 = [];
        let obj = {};
        let pieChartData = dashboardData.data[0].orderStatusStats;
        pieChartData.forEach((item) => {
          arr.push(item.name.charAt(0).toUpperCase() + item.name.slice(1));
          arr1.push(item.count);
          obj['labels'] = arr;
          obj['series'] = arr1;
        });

        state.bssPiechartOrder = obj;

        // console.log(dashboardData, "Bss dashboard");
      } catch (error) {
        console.error(error);
      }
    },
    async bssPiechartQuotes({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const dashboardData = await UPCAxiosInstance.get(
          'BSSSuiteDashboard?period=monthly'
        );
        let arr = [];
        let arr1 = [];
        let obj = {};
        let pieChartData = dashboardData.data[0].quoteStatusStats;
        console.log(pieChartData, 'piechart');
        pieChartData.forEach((item) => {
          let val = '';
          if (item.name == 'inprogress') {
            val = 'Draft';
          } else if (item.name == 'rejected') {
            val = 'Declined';
          } else if (item.name == 'accepted') {
            val = 'Completed';
          }
          arr.push(val);
          arr1.push(item.count);
          obj['labels'] = arr;
          obj['series'] = arr1;
        });

        state.bssPiechartQuotes = obj;
        console.log(dashboardData, 'Bss dashboard');
      } catch (error) {
        console.error(error);
      }
    },
    async loginUserdata({ state }) {
      if (!VueCookies.get('token')) {
        return;
      }
      const loginUserRes = await MLTAxiosInstance.get(
        'partymanagement/individual/auth'
      );
      state.loginUser = loginUserRes.data.company;
    },
    async initIntellisenceData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        const intellisenceRes = await DMNAxiosInstance.post(
          '/util/filterData',
          {
            type: 'search',
            module: 'intellisense',
            isPaginated: true,
            sortFieldAndOrder: {
              name: 1,
            },
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: true,
            limit: 0,
            filterQuery: {},
            searchQuery: '',
          }
        );
        state.intellisenceData = intellisenceRes.data.data;
        console.log('intellisenceData 2', state.intellisenceData);
      } catch (error) {
        console.error(error);
      }
    },
    async loadUPCReferenceData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        // For place
        if (state.placeOptions.length === 0) {
          // const placedropdown = await DMNAxiosInstance.get(
          //   'mapRef/collection/place'
          // );
          state.placeOptions = await this.getReferanceData('place');
        }

        // For channel
        if (state.channelOptions.length === 0) {
          // const channeldropdown = await DMNAxiosInstance.get(
          //   'mapRef/collection/channel'
          // );
          state.channelOptions = await this.getReferanceData('channel');
        }

        // For market
        if (state.marketOptions.length === 0) {
          // const marketdropdown = await DMNAxiosInstance.get(
          //   'mapRef/collection/market'
          // );
          state.marketOptions = await this.getReferanceData('market');
        }

        // For category
        if (state.categoryOptions.length === 0) {
          // const categorydropdown = await DMNAxiosInstance.get(
          //   'mapRef/collection/category'
          // );
          state.categoryOptions = await this.getReferanceData('category');
        }
      } catch (error) {
        console.error(
          '🚀 ~ file: index.js:571 ~ loadUPCReferenceData ~ error:',
          error
        );
      }
    },
    async loadCurrencyList({ state }) {
      try {
        // const currencyList = await DMNAxiosInstance.get(
        //   'mapRef/collection/Currency'
        // );
        state.currencyList = (await this.getReferanceData('Currency')) || [];
      } catch (error) {
        return error.message;
      }
    },
    async loadDashboardData({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        let user, company;
        if (!VueCookies.get('user')) {
          const individualRes = await MLTAxiosInstance.get(
            'partymanagement/individual/auth'
          );
          console.log('🚀 ~ loadDashboardData ~ individualRes:', individualRes);
          company = individualRes.data.company;
          user = individualRes.data.userId;
          VueCookies.set('user', user);
          VueCookies.set('all', JSON.stringify(individualRes.data));
          VueCookies.set('company', company);
        }
        if (!user || !company) return;

        const userRes = await MLTAxiosInstance.get(
          `partymanagement/individual/${user}`
        );
        // const userRes = await MLTAxiosInstance.get(
        //   `partymanagement/individual/${company}/${user}`
        // );
        VueCookies.set('userName', userRes.data.name);
        // 1status data

        // For product compeleteness
        const productCompleteNess = await UPCAxiosInstance.get(
          `/dashboard/product_completeness/${company}`
        );
        if (productCompleteNess.data) {
          state.productCompleteNess = productCompleteNess.data;
        }

        // For place metrics
        const placeMetrics = await UPCAxiosInstance.get(
          `/dashboard/place_metric/${this.company}`
        );
        if (placeMetrics.data) {
          state.placeMetrics = placeMetrics.data;
        }

        // For party with product count metrics
        const partyWithProductCount = await UPCAxiosInstance.get(
          `/dashboard/party_with_product_count/${this.company}`
        );
        if (partyWithProductCount.data) {
          state.partyWithProductCount = partyWithProductCount.data;
        }

        const statusMetrics = await UPCAxiosInstance.get(
          `/catalogManagement/productOffering/dashboard_status_data/${this.company}`
        );
        state.statusMetrics = statusMetrics.data;

        const barChartData = await UPCAxiosInstance.get(
          `/catalogManagement/productOffering/dashboard_chart/${this.company}/category`
        );
        if (barChartData && barChartData.data) {
          state.barChartData = barChartData.data;

          this.categoryXData = barChartData.XData.category;
          this.marketXData = barChartData.XData.market;
          this.channelXData = barChartData.XData.channel;

          this.categoryYData = barChartData.YData.category;
          this.marketYData = barChartData.YData.market;
          this.channelYData = barChartData.YData.channel;
          // console.log(categorydata.data,category.data,"🎉");
          this.series[0].data = this.categoryYData;
        }
        // this.handleBarChatData({value:'category'})
        const interval = setInterval(() => {
          if (this.progress < 75) {
            this.progress += 2;
          } else {
            this.progress -= 5;
          }
        }, 200);
        setTimeout(() => {
          // this.progress = 100;
          clearInterval(interval);
        }, 15000);
      } catch (error) {
        return error.message;
      }
    },
    async loadLifeCycleStatus({ state }) {
      try {
        if (!VueCookies.get('token')) {
          return;
        }
        // const statusList = await DMNAxiosInstance.get(
        //   'mapRef/collection/lifecycleStatus'
        // );
        state.statusOptions =
          (await this.getReferanceData('lifecycleStatus')) || [];
        console.log(
          '🚀 ~ file: index.js:1371 ~ loadLifeCycleStatus ~ state.statusOptions:',
          state.statusOptions
        );
      } catch (error) {
        return error.message;
      }
    },
  },
  modules: {},
});
