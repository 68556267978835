<template>
    <div v-if="data_loaded == null">
      <hlx-loader load-type="dotted-jumper" />
    </div>
    <hlx-modal
      :modal-active="data_loaded"
      :height="'220px'"
      :width="'400px'"
      :modal="true"
    >
      <template #header>
        <div style="margin-left: 0px">{{ title }}</div>
      </template>
      <template #content>
        <div class="modal-content">
          <p>{{ errorMessage }}</p>
        </div>
      </template>
    </hlx-modal>
    <section v-if="data_loaded == false" class="form-body"     :style="{ '--hlx-color-primary': bannerStyle.backgroundColor }"
    >
      <div style="display: flex; flex-direction: row">
        <div class="banner" :style="bannerStyle" v-html="bannerHtml?.[selectedLanguage?.code?.toLowerCase()]"></div>
        <div
          class="language-selector"
          :style="{ 'background-color': bannerStyle.backgroundColor }"
        >
          <!-- Trigger Button -->
          <button class="language-button" @click="toggleDropdown">
            <span style="padding-left: 10px; padding-right: 10px">
              <i class="icon-globe-regular" style="font-size: 16px"></i>
            </span>
            {{ selectedLanguage?.code?.toUpperCase() }}
            <span :class="{ open: isOpen }"
              ><i class="icon-angle-down-regular"></i
            ></span>
          </button>

          <!-- Dropdown -->
          <ul v-if="isOpen" class="dropdown-menu">
            <li
              v-for="lang in langList"
              :key="lang.code"
              :class="{ selected: lang.checked }"
              @click="selectLanguage(lang)"
            >
              {{ lang?.code?.toUpperCase() }}
            </li>
          </ul>
        </div>
      </div>      <section class="form-content">
        <div v-for="(section, index) in customSchema" :key="index">
          <fieldRenderer
            :key="updateFieldRenderKeyForContact"
            :layout="'1-column'"
            :custom-language = customLanguage
            :schema-payload="customSchema[index]"
            :data-payload="dataPayload[index]"
            :is-add-clicked="isAddButtonClicked"
            :trigger-rule="triggerRuleForContacts"
            :updated-schema-after-remap="updatedContactSchemaForRemap"
            :is-save-clicked="isSaveButtonClickedForContact"
            :is-modal-window="modalPropsForFieldRender"
            :email-data-props="emailArrayForContacts"
            :status-list="[
              'Open',
              'Qualified',
              'Unqualified',
              'Network approved',
              'Network rejected',
              'Awaiting network approval',
              'Converted',
              'Closed Lost',
            ]"
            :parent-class-name="'parent-container-for-lead-maintable-contact'"
            @edited-payload="getLeadData($event, index)"
            @updated-schema="getContactUpdatedSchema"
            @input-focus-out="getContactInputFocusOut"
          ></fieldRenderer>
        </div>
      </section>
      <section
      class="footer"
      style="
        border-top: 1px solid #d8d8d8;
        padding-right: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 10vh; /* Set footer height */
        background-color: #fff;
        position: sticky;
        bottom: 0;
        z-index: 100
      "
    >
      <div
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button class="secondary-info sm" @click="cancelApiAdd"
          >{{staticMatch("Cancel")}}</hlx-button
        >
        <hlx-button
          class="primary-info sm"
          style="margin-left: 20px"
          :disabled="isSubmit"
          @click="sendWorkFlow"
          >{{staticMatch("Submit")}}</hlx-button
        >
      </div>
    </section>
    </section>
  </template>
  
  <script>
  import fieldRenderer from "@/components/FieldRenderer.vue";
  import {
    UPCAxiosInstance,
    DMNAxiosInstance,
    MLTAxiosInstance
  } from "@/config/axiosConfig";
  
  export default {
    components: {
      fieldRenderer,
    },



    data() {
      return {
        externalVisibilityRule: [],
      externalRule: "",
        isSubmit: true,
        customLanguage:{},
        langList:[],
      isOpen: false,
        bannerHtml: {},
        bannerStyle: {
          height: "75px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          fontSize: "18px",
          backgroundColor: "#00549a",
        },
        formConfig: {},
        dataPayload: {},
        customSchema: {},
        data_loaded: null,
        errormessage: "",
        title: "",
        formFeeder: {}, // Data used for triggering actions
      };
    },
    computed: {
    selectedLanguage() {
      // Find the currently selected language
      return this.langList.find((lang) => lang.checked) || this.langList[0];
    },
  },
    async mounted() {
  
  

      this.formFeeder = {
        lead: { leadId: "677c1361758d99f3e4f164f7" },
      };
      
      this.preview = JSON.parse(sessionStorage.getItem('pageData'))
    
        this.customSchema = this.preview.schema;
        this.bannerHtml = this.preview.html;
        this.bannerStyle = this.preview.style;
        this.bannerStyle["font-family"] = "QuickSand";
    this.bannerStyle["padding-left"] = "100px";
  
        const response = await UPCAxiosInstance.get(
          `/salesLead/${this.formFeeder.lead.leadId}`
        );
        let formConfig = {
          leadData: response.data,
          customSchema: this.customSchema,
        };
  
        const formData = await DMNAxiosInstance.post(
          `rule_engine/rule/execute/6777dcefcb521b724a22bf92`,
          { payload: { formConfig: { ...formConfig } } }
        );
        if (formData) {
          this.data_loaded = false;
        }
        this.customSchema = formData.data.data.formConfig.formData.customSchema;
        this.dataPayload = formData.data.data.formConfig.formData.dataPayload;
        let filterData = {
      type: "filter",
      company: "Halleyx",
      module: "hierarchyaccounts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 10,
      filterQuery: {
        tradingName: this.$cookies.get('company')
      },
      searchQuery: "",
    };

    try {
      const tenantDataData = await MLTAxiosInstance.post(
        `util/filterData`,
        filterData
      );
      this.langList = tenantDataData?.data?.data[0].languageAbility?.map(
        (language) => ({
          ...language,
          checked: false,
        })
      );
    }catch(err){
      console.log(err)
    }
      
    console.log(this.langList,"langList")
    },
    methods: {
      toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectLanguage(lang) {
      // Mark the selected language as checked
      this.langList.forEach((language) => (language.checked = false));
      lang.checked = true;

      // Close the dropdown
      this.isOpen = false;
      this.customLanguage = lang;
      // Perform additional actions, like updating the app's locale
      console.log(`Selected language: ${lang.code}`);
    },
    staticMatch(searchTerm) {
      try {
        let data = JSON.parse(localStorage.getItem("remsCache"));
        console.log("Retrieved data from cache:::", data);
        if (
          data === null ||
          data === "null" ||
          data === undefined ||
          data === "undefined" ||
          data === ""
        ) {
          data = [];
        }

        let languageAbility = [];
        let newsq = [this.customLanguage];

        if (
          newsq === null ||
          newsq === "null" ||
          newsq === undefined ||
          newsq === "undefined" ||
          newsq === ""
        ) {
          languageAbility = [{ name: "english", code: "en" }];
        } else {
          languageAbility = newsq;
        }

        // Find the object in the data array that matches the search term in the 'en.label' field
        const matchedObject = data.find(
          (item) =>
            item.en && item.en.label.toLowerCase() === searchTerm.toLowerCase()
        );

        // If no match is found, return null
        if (!matchedObject) {
          // console.log("no match matchFromStatic:::", searchTerm);
          return searchTerm;
        }

        // Iterate through languageAbility to find the desired translation
        for (const lang of languageAbility) {
          const translation = matchedObject[lang.code]?.label;
          if (translation) {
            // console.log("try matchFromStatic:::", translation, searchTerm);
            return translation;
          }
        }

        // If no translation is found, return null
        return searchTerm;
      } catch (error) {
        console.error("Error matchFromStatic:::", error);
        return searchTerm;
      }
    },
    async getLeadData(val, key) {
      this.dataPayload[key] = val;

      // for (var site in this.dataPayload) {
      //   if (site.toLowerCase().includes("siteinformation")) {
      //     this.dataPayload[site].approvalOptions =
      //       this.dataPayload.approval.approvalOptions;
      //   }
      // }
      for (var i in this.externalVisibilityRule) {
        let response = await DMNAxiosInstance.post(
          `rule_engine/rule/execute/${
            this.getNestedValue(
              this.customSchema,
              this.externalVisibilityRule[i]
            ).externalVisibilityRule
          }`,
          { payload: { execution: { dataPayload: this.dataPayload } } }
        );
        this.setNestedValue(
          this.customSchema,
          `${this.externalVisibilityRule[i]}.isVisible`,
          response.data.data.execution.visible
        );
      }
      if (this.dataPayload.approval.approvalOptions != null) {
        this.isSubmit = false;
      } else {
        this.isSubmit = true;
      }

      // this.dataPayload = JSON.parse(JSON.stringify(this.dataPayload));
      // let response = await DMNAxiosInstance.post(
      //   `rule_engine/rule/execute/${ moduleData.data.data.rule}`,
      //   { payload: { formConfig: { ...formConfig } } }
      // );
    },
    },
  };
  </script>
  
  <style lang="scss">
  .form-body {
    display: flex;
    flex-direction: column;
  
    .banner {
      height: 75px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 18px;
      background-color: #00549a;
    }
  
    .form-content {
      padding: 20px 30px;
    }
  
    .header-title {
      color: #000;
      font-family: "Open Sans", sans-serif;
      padding: 30px 0 20px 0;
      font-size: 16px;
    }
  }
  .language-selector {
  position: relative;
  display: flex;
  align-items: center;
}

.language-button {
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.language-button span {
  font-size: 16px;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.language-button span.open {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  padding: 5px 0;
  width: 100px; /* Adjust width to match design */
  text-align: center;
}

.dropdown-menu li {
  padding: 8px 10px;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown-menu li.selected {
  font-weight: bold;
  background-color: #e6f7ff;
}
  </style>
  