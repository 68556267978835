<template>
  <hlx-alert-notification :notify="notification" auto-close />
  <hlx-modal
    :modal-active="modal_active_role"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'370px'"
    :width="'410px'"
    :modal="true"
    @close="modal_active_role = false"
  >
    <template #header>
      <div>Change role</div>
    </template>
    <template #content>
      <div
        class="modal-content"
        style="display: flex; flex-direction: column; gap: 5%"
      >
        <div
          class="icon-container"
          style="display: flex; justify-content: center"
        >
          <i
            class="icon-user-change-regular"
            style="
              height: 50px;
              width: 50px;
              border-radius: 50px;
              background: #8ce1c1;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          ></i>
        </div>
        <p style="text-align: center; margin-top: 20px; margin-bottom: 20px">
          Select a new role for <strong>{{ userName }}</strong> to align with
          the appropriate responsibilities
        </p>
        <div class="decision-name-wrapper">
          <hlx-select
            :key="rolecomponentkey"
            v-model:value="roleData"
            :required="true"
            :options="roleList"
            prop-value="role"
            :label="'role'"
            :placeholder-value="'Role'"
            :label-animation="true"
            :clearable="false"
          ></hlx-select>
        </div>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="modal_active_role = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="editContactData"
        >Change</hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Inactive user</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to Inactive user <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>

    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Inactive</hlx-button
      >
    </template>
  </hlx-modal>

  <hlx-modal
    :modal-active="modal_active_Deactivate"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'385px'"
    :width="'410px'"
    :modal="true"
    @close="modal_active_Deactivate = false"
  >
    <template #header>
      <div>Deactivate</div>
    </template>
    <template #content>
      <div
        class="modal-content"
        style="display: flex; flex-direction: column; gap: 5%"
      >
        <div
          class="icon-container"
          style="display: flex; justify-content: center"
        >
          <i
            class="icon-user-times-alt-regular"
            style="
              height: 56px;
              width: 56px;
              border-radius: 50px;
              background: rgba(84, 189, 149, 0.14);
              font-size: 24px;
              color: rgba(84, 189, 149, 1);
              /* background: rgb(140, 225, 193); */
              display: flex;
              justify-content: center;
              align-items: center;
            "
          ></i>
        </div>
        <p
          style="
            margin-top: 20px;
            margin-bottom: 20px;
            /* font-family: Open Sans; */
            font-size: 12px;
            font-weight: 400;
            line-height: 16.34px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          "
        >
          Are you sure you want to deactivate
          <strong>{{ deactivateUserName }}</strong> ?This will lock them out of the system. Optionally, you may reassign their data to another active user.
        </p>
        <div class="decision-name-wrapper">
          <hlx-select
            :key="rolecomponentkey"
            v-model:value="roleData"
            :required="true"
            :options="hierarchyData.filter(item => item.name !== deactivateUserName)"
            prop-value="id"
            :label="'name'"
            :placeholder-value="'Choose user'"
            :label-animation="true"
            :clearable="false"
          ></hlx-select>
        </div>
        <p
          style="
            font-size: 10px;
            font-weight: 400;
            line-height: 13.62px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin-top: 6px;
            color: rgb(131, 131, 131);
          "
        >
          If not choose user, an admin will be assigned by default
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="modal_active_Deactivate = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="deactiveMethod"
        >Deactivate</hlx-button
      >
    </template>
  </hlx-modal>
  <div v-show="impersonite == true"><banner @exit="exitView()" /></div>
  <div class="app-layout" style="display: none">
    <section class="right-panel">
      <div class="layout-basic">
        <div class="layout-breadcrumb">
          <hlx-breadcrumb :items="items" :auto-route="true" />
        </div>

        <div class="layout-header"><p>Logged user</p></div>

        <div class="layout-body" style="overflow: hidden">
          <div class="grid">
            <span class="grid-left">
              <p class="title">User</p>
              <p class="info">Update your user details</p>
            </span>

            <span class="grid-right">
              <span class="profile-with-info --2way">
                <div class="profile-picture-preview">
                  <hlx-file-upload
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Rectangle'"
                    :style-background="backgrounStyleupload"
                    :height="100"
                    :width="100"
                    :read-only="false"
                    :custom-icon="icon"
                    :type="'profile-upload'"
                    :name-display="organisationData.tradingName"
                    :profile-pic-preview="profilePicturePrevalue"
                    :content="'Import'"
                    @native-data="handleProfilePictureUpload"
                  />
                </div>
                <div class="input-wrapper">
                  <hlx-input
                    :label-animation="true"
                    type="text"
                    :pre-val="organisationData.name"
                    :read-only="true"
                    label-value="User name"
                  />
                  <hlx-input
                    :label-animation="true"
                    type="email"
                    :pre-val="organisationData.email"
                    :read-only="true"
                    label-value="Email"
                  />
                </div>
              </span>
              <span class="grid-right-alignment">
                <!-- {{ orgPhone }} -->
                <hlx-input
                  v-model:value="phoneNumber"
                  :pre-val="orgPhone.value"
                  type="phonenumber"
                  :country-code="orgPhone.country"
                  :label-value="'Phone no.'"
                  :label-animation="true"
                  :display-error="isPhoneNumberValidationFailed"
                  :custom-error="isPhoneNumberValidationFailed"
                  :custom-error-message="'Please fill the field'"
                  @focus-out="focusOutName"
                  @at-input="handlePhoneNumberChange"
                ></hlx-input>
              </span>
              <span class="grid-right-alignment">
                <addressComponent
                  :pre-val="addressData"
                  @address-data="handleAddressChange"
                />
              </span>
              <div class="decision-name-wrapper" style="margin-top: 20px">
                <!-- {{newConPayload.role}} -->
                <hlx-select
                  :key="addressKey"
                  :pre-value="organisationData.languageAbility[0].name"
                  :options="langList"
                  :prop-value="'name'"
                  :label="'name'"
                  :placeholder-value="'Default Language'"
                  :label-animation="true"
                  :clearable="false"
                  @selected-value="selectedData"
                ></hlx-select>
              </div>
            </span>
          </div>
        </div>

        <div class="layout-footer">
          <div class="footer-container">
            <hlx-button class="secondary sm" @click="back()">Back</hlx-button>
            <hlx-button
              style="margin-left: 0px !important"
              class="primary sm"
              @click="updateInfo"
              >Save</hlx-button
            >
            <!-- <hlx-button
              style="margin-left: 0px !important"
              class="primary sm"
              @click=""
              >Edit</hlx-button
            > -->
          </div>
        </div>
      </div>
    </section>
  </div>
  <section style="display: flex">
    <section class="lead-overview-left-panel responsiveSM">
      <div class="layout-breadcrumb" style="margin-bottom: 37px">
        <hlx-breadcrumb
          :items="getBreadcrumbs"
          :label-value="loadStaticData('LANG-0019')"
          :auto-route="true"
        />
        <!-- <hlx-breadcrumb
          v-if="view === false"
          :items="getBreadcrumbs"
          @path="goTo()"
        /> -->
      </div>
      <span style="margin-top: 25px">
        <leftPanel
          :side-bar-data="leftPanelData"
          @value-name="selectedValueFunc"
        ></leftPanel>
      </span>
    </section>
    <section
      class="--layout-inner-right-portion --RL70 responsiveSM-right-portion"
      style="padding: 1.8em 35px; width: calc(100% - 220px); padding-left: 0px"
    >
      <div class="decision-info-right-pannel">
        <div class="layout-breadcrumb expanded-side-panel">
          <hlx-breadcrumb
            v-if="view === true"
            :label-value="loadStaticData('LANG-0019')"
            :items="getBreadcrumbs"
            @path="goTo()"
          />
          <hlx-breadcrumb
            v-if="view === false"
            :label-value="loadStaticData('LANG-0019')"
            :items="getBreadcrumbs"
            @path="goTo()"
          />
        </div>
        <div class="body-container-center" style="height: 100%">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span class="expanded-side-panel" style="margin-right: 25px">
              <leftPanel
                :side-bar-data="leftPanelData"
                @value-name="selectedValueFunc"
              ></leftPanel>
            </span>
            <div
              style="
                display: flex;
                align-items: center;
                gap: 15px;
                width: 100%;
                padding-left: 20px;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  font-family: 'Quicksand';
                "
              >
                <div style="display: flex; align-items: center; gap: 12px">
                  <span style="font-weight: bold">
                    {{ "Users" }}
                  </span>
                </div>
                <div
                  style="color: #a6a6a6; font-size: 12px; width: max-content"
                >
                  {{ "" }}
                </div>
              </div>
            </div>
            <div
              class="go-back"
              style="
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 20px;
              "
            >
              <hlx-button
              v-if = "edit == true"
                class="secondary sm custom-field-add-btn"
                @click="openAddFieldsPanel"
              >
                Actions
              </hlx-button>
              <hlx-context-menu
                :top="containertop1"
                :left="containerleft1"
                :conditionvalue="150"
                :conditiondown="2"
                :conditionup="-38"
                :options="computedTableAction(userDetails)"
                :show="showContextMenu"
                @chosen="fromAddContextMenu($event, userDetails, listViewIndex)"
                @close-context-menu="showContextMenu = false"
              ></hlx-context-menu>
              <!-- <hlx-button  v-if=" view == true" class="secondary sm" @click="pathTo" -->
              <!-- > -->
              <!-- Account link -->
              <!-- {{"Cancel" }} -->
              <!-- </hlx-button -->
              <!-- > -->
              <hlx-button
                v-if="selectedValue == 'Overview' && edit == true"
                class="primary sm"
                style="margin-left: 20px"
                :disabled="!isAllDetailsFilled"
                @click="updateInfo"
              >
                <!-- Save -->
                {{ "Save" }}
              </hlx-button>
              <hlx-button
                v-if="selectedValue == 'Additional details' && edit == true"
                class="primary sm"
                style="margin-left: 20px"
                :disabled="!isAllDetailsAdditionalDetailsFilled"
                @click="updateInfo"
              >
                <!-- Save -->
                {{ "Save" }}
              </hlx-button>
              <hlx-button 
              v-if = "edit == false"
            style="margin-left: 20px !important"
            class="primary sm"
            @click="edittoView"
              >Edit</hlx-button
            >
            </div>
          </div>
          <div>
            <!--
              v-if="selectedValue.toLowerCase() === 'overview'" -->
            <div
             v-if="selectedValue == 'Overview'"
              class="parent-container-for-login-profile-overview"
              style="position: relative"
            >
              <fieldRenderer
                :key="updateFieldRenderKey"
                :layout="'mixed' "
                :schema-payload="organisationSchema"
                :data-payload="dataPayload"
                :is-add-clicked="isAddButtonClicked"
                :is-save-clicked="isSaveButtonClicked"
                :status-list="['Active', 'Pending', 'Inactive']"
                :parent-class-name="'parent-container-for-login-profile-overview'"
                @edited-payload="patchEditedLead"
                @updated-payload="leadWholePayload"
                @password-validate="passwordValidate"
              ></fieldRenderer>
              <!-- <div style="display:flex;align-items:center;top:369px;font-size:12px;left:20px;position: absolute;">
              <span><hlx-input     :checked="restricted"     :type="'single-checkbox'"  @selected="restrictMode"       /></span>
              <p>Restrict Viewing of Other Users Data</p>
              </div> -->
            </div>
            <div
             v-else
              class="parent-container-for-login-profile-details"
              style="position: relative"
            >
              <fieldRenderer
                :key="updateFieldRenderKey"
                :layout="'modal'"
                :schema-payload="detailSchema"
                :data-payload="detailPayload"
                :is-add-clicked="isAddButtonClicked"
                :is-save-clicked="isSaveButtonClicked"
                :status-list="['Active', 'Pending', 'Inactive']"
                :parent-class-name="'parent-container-for-login-profile-details'"
                @edited-payload="detailemitPayload"
                @updated-payload="leadWholePayload"
                @password-validate="passwordValidate"
              ></fieldRenderer>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import banner from "@/components/bannerComponent.vue";
import axios from "axios";
import { MLTAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";
// import places from 'places.js';
import addressComponent from "@/components/CM/autocompleteComponent.vue";
import FieldRenderer from "@/components/FieldRenderer.vue";
import leftPanel from "@/components/leftpannelComponent.vue";
export default {
  name: "AddRoleView",
  components: { banner, addressComponent, leftPanel, FieldRenderer },
  data() {
    return {
    userName:'',
    companyName:'',
      adminName:'',
      hierarchyData: [],
      modal_active_Deactivate: false,
      deactivateUserName: "",
      edit:false,
      selectedLanguageName:"",
      restricted:false,
      parentName:'',
      duplicatedetailSchema:{},
      detailsdataPayload:{},
      detailSchema:{},
      temporaryPayload: {},
      additionalFieldsPayload: {
        Position: "",
        businessUnit: "",
        employeeId: "",
        fsa: "",
        group: "",
        marketSegment: "",
        province: "",
        reportingTo: "",
        reportingtoUser: "",
        sfdcPassword: "",
        sfdcToken: "",
        sfdcUsername: "",
        subGroup: "",
        userTitle: "",
        userType: "",
        userPhoneNumber: "",
        usertelephoneNumber: "",
      },
      stateList: [],
      contextOptions: [
        {
          label: "Reset password",
          value: "resetPassword",
          icon: "icon-lock-viewfinder-regular",
        },
        {
          label: "Deactivate",
          value: "deactivate",
          icon: "icon-power-off-square-regular",
        },
        {
          label: "Change Role",
          value: "changeRole",
          icon: "icon-user-change-regular",
        },
      ],
      roleList: [],
      showContextMenu: false,
      delete_modal_active: false,
      languageOpt: [],
      conditionNewPassword: false,
      modal_active_role: false,
      conditionConfirmPassword: false,
      validateCurrentPassword: false,
      validateNewPassword: false,
      validateConfirmPassword: false,
      currentPass: "",
      view: true,
      settingsID: "",
      switchdataPayload:{},
      tempOrganizationPayload: null,
      detailPayload: {
        defaultLanguage: "",
        attachment: [],
        firstName: "",
        lastName: "",
        status: "",
        email: "",
        role: "",
        Position:"",
        businessUnit:"",
        employeeId:"",
        fsa:"",
        group:"",
        marketSegment:"",
        province:"",
        reportingTo:"",
        reportingtoUser:"",
        sfdcPassword:"",
        sfdcToken:"",
        sfdcUsername:"",
        subGroup:"",
        userTitle:"",
        userType:"",
        userPhoneNumber:"",
        usertelephoneNumber:""
      },

      dataPayload: {
        defaultLanguage: "",
        attachment: [],
        firstName: "",
        lastName: "",
        status: "",
        email: "",
        role: "",
        Position:"",
        businessUnit:"",
        employeeId:"",
        fsa:"",
        group:"",
        marketSegment:"",
        province:"",
        reportingTo:"",
        reportingtoUser:"",
        sfdcPassword:"",
        sfdcToken:"",
        sfdcUsername:"",
        subGroup:"",
        userTitle:"",
        userType:"",
        userPhoneNumber:"",
        usertelephoneNumber:""
      },
      duplicatedataPayload: {
        defaultLanguage: "",
        attachment: [],
        firstName: "",
        lastName: "",
        status: "",
        email: "",
        role: "",
        Position:"",
        businessUnit:"",
        employeeId:"",
        fsa:"",
        group:"",
        marketSegment:"",
        province:"",
        reportingTo:"",
        reportingtoUser:"",
        sfdcPassword:"",
        sfdcToken:"",
        sfdcUsername:"",
        subGroup:"",
        userTitle:"",
        userType:"",
        userPhoneNumber:"",
        usertelephoneNumber:""
      },
      PasswordPayload: {
        newPassword: "",
        currentPassword: "",
        confirmPassword: "",
      },
      organisationSchema: {},
      duplicateOrganisationSchema: {},
      selectedValue: "overview",
      leftPanelData: {
        main: [
          {
            name: "Overview",
            value: "overview",
            icon: "icon-presentation-regular",
            view: true,
            active: true,
            edit: false,
            delete: false,
            title: false,
            add: false,
            all: false,
            customName: "Overview",
          },
          {
            name: "Additional details",
            value: "change_password",
            icon: "icon-check-circle-regular",
            view: true,
            edit: false,
            delete: false,
            title: false,
            add: false,
            all: false,
            customName: "Additional details",
            active: false,
          },
        ],
      },
      showDefault: false,
      roleData: "",
      addressKey: 0,
      phoneNumber: null,
      profilePicture: null,
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 16,
      },
      profilePicturePrevalue: "",
      phoneDefaultValue: {
        country: {
          name: "Canada",
          flags: "https://flagcdn.com/ca.svg",
          short: "CA",
          dial_code: "+1",
        },
        value: "",
        valid: true,
      },
      addressData: {
        characteristic: {
          "        @type": "",
          address1: "",
          city: "",
          country: "",
          description: "",
          postCode: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          timezone: "",
          unit: "",
        },

        mediumType: "postalAddress",
      },
      organisationData: {},
      currenttimezone: "",
      timezone: [],
      showAlert: false,
      type: "Success",
      notification: {},
      city: "",
      address: "",
      number: "",
      state: "",
      country: "",
      time: "",
      individualIdentification: [
        { contactNumber: "", country: "", city: "", state: "", address: "" },
      ],
      name: "",
      id: "",
      email: "",
      viewPermission: [],
      editPermission: [],
      impersonitor: "",
      userDetails: {},
      fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
      impersonite: false,
      options: [{ name: "", checked: false, disabled: false }],
      items: [
        { label: "Settings", link: "/" },
        { label: "General", link: "/general" },
      ],
      countries: [
        {
          id: 1,
          flag: "in",
          name: "India",
          value: "India",
          code: "+91",
          checked: false,
        },
        {
          id: 2,
          flag: "ca",
          name: "Canada",
          value: "Canada",
          code: "+1",
          checked: false,
        },
        {
          id: 3,
          flag: "us",
          name: "USA",
          value: "USA",
          code: "+1",
          checked: false,
        },
      ],
    };
  },

  computed: {
    isAllDetailsFilled(){
    if(this.tempOrganizationPayload){
      let overviewDataFilled = this.tempOrganizationPayload.firstName && this.tempOrganizationPayload.lastName 
                                && this.tempOrganizationPayload.email &&  this.tempOrganizationPayload.defaultLanguage 
                                && this.tempOrganizationPayload.status && this.tempOrganizationPayload.role

      // let overviewDataFilledWithOrgData = 

      return overviewDataFilled
      }
      else{
      return true
      }
    },
    isAllDetailsAdditionalDetailsFilled(){
        if(this.switchdataPayload){
      let additionalDetailsDataFilled = this.switchdataPayload?.usertype && this.switchdataPayload?.useremployeeId 
                                && this.switchdataPayload?.usertitle 
                                // &&  this.switchdataPayload?.userreportingTo 
                                // && this.switchdataPayload?.userBusinessUnit?.length>0 && this.usermarketSegment?.usermarketSegment?.length > 0 
                                && this.switchdataPayload?.userGroup && this.switchdataPayload.fsa && this.switchdataPayload.usersfdcPassword
                                && this.switchdataPayload?.usersfdcToken 

      return additionalDetailsDataFilled
      }
      else{
      return true
      }
    },
    filteredCustomFieldSchema() {
      console.log("this.organisationSchema", this.organisationSchema);
      if (this.organisationSchema) {
        const filtered = Object.entries(this.organisationSchema).filter(
          ([, value]) =>
            value.section &&
            value.section.includes(this.selectedValue.toLowerCase())
        );
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, "fieldSchema", fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);

        return sortedData;
      } else {
        return {};
      }
    },
    countryWithIDDCode() {
      const defaultSettings = this.$store.getters.getDefaultSettings;
      console.log(
        "🚀 ~ countryWithIDDCode ~ defaultSettings:",
        defaultSettings
      );
      if (
        defaultSettings &&
        Object.prototype.hasOwnProperty.call(defaultSettings, "idd") &&
        Object.prototype.hasOwnProperty.call(defaultSettings, "country") &&
        Object.prototype.hasOwnProperty.call(defaultSettings, "flag") &&
        Object.prototype.hasOwnProperty.call(defaultSettings, "cca2")
      ) {
        return {
          // dial_code:defaultSettings.idd?.root+defaultSettings.idd?.suffixes?.join(""),
          name: defaultSettings?.country,
          short: defaultSettings.cca2,
          flags: defaultSettings.flags.png,
        };
      }
      return this.phoneDefaultValue.country;
    },
    orgAddress() {
      if (
        !this.organisationData ||
        (this.organisationData &&
          Object.keys(this.organisationData).length == 0)
      ) {
        return this.addressData;
      }
      const postalAddress = this.organisationData?.contactMedium?.find(
        (item) => item?.mediumType == "postalAddress"
      );
      if (postalAddress) {
        return postalAddress;
      }
      return this.addressData;
    },
    orgPhone() {
      if (
        !this.organisationData ||
        (this.organisationData &&
          Object.keys(this.organisationData).length == 0)
      ) {
        return { ...this.phoneDefaultValue, country: this.countryWithIDDCode };
      }
      const phoneNumber = this.organisationData?.contactMedium?.find(
        (item) => item?.mediumType == "phone"
      );
      console.log("🚀 ~ orgPhone ~ phoneNumber:", phoneNumber);
      if (phoneNumber) {
        return phoneNumber.characteristic.phoneNumber;
      }
      //

      console.log(
        "🚀 ~ orgPhone ~ this.countryWithIDDCode:",
        this.countryWithIDDCode
      );
      // this.phoneDefaultValue.country =
      return { ...this.phoneDefaultValue, country: this.countryWithIDDCode };
    },
  },
  watch: {
    organisationData: {
      handler(value) {
        if (
          value &&
          value?.languageAbility &&
          value?.languageAbility?.length > 0 &&
          value?.languageAbility[0]?.name
        ) {
          value.languageAbility[0].name;
        } else {
          this.organisationData.languageAbility = [
            {
              name: "English",
            },
          ];
        }
      },
      immediate: true,
      deep: true,
    },
    phoneNumber: {
      handler(value) {
        console.log("🚀 ~ handler ~ value:", value);
        if (value) {
          console.log("🚀 ~ handler ~ value:", value);
          let phoneNumberIndex = this.organisationData.contactMedium?.findIndex(
            (item) => item.mediumType == "phone"
          );
          console.log(
            "🚀 ~ handleAddressChange ~ phoneNumberIndex:",
            phoneNumberIndex
          );
          if (phoneNumberIndex >= 0) {
            this.organisationData.contactMedium[phoneNumberIndex] = {
              mediumType: "phone",
              preferred: true,
              characteristic: { phoneNumber: value },
            };
          } else {
            this.organisationData.contactMedium.push({
              mediumType: "phone",
              preferred: true,
              characteristic: { phoneNumber: value },
            });
          }
        }
      },
    },
  },
  async mounted() {
    const hierarchyResponse = await MLTAxiosInstance.get("/userHierarchy");
      if (hierarchyResponse.data) {
        this.hierarchyData = hierarchyResponse.data;
      }

    console.log(this.$route.query.edit,'this.$route.query.edit',this.$route.query.edit == true)
    if(this.$route.query.edit == 'true'){
      this.edit = true
    }else{
      this.edit = false
    }
    await this.$store.dispatch("loginTenantDetails");
    console.log(this.$store?.getters?.loginTenantDetails,'loginvalue')
    // const value = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.selectedLanguageName =this.$store?.getters?.loginUserDetails?.languageAbility[0]?.name?.toLowerCase() ||'name';
    console.log(this.selectedLanguageName,'languagefielddsds')
    this.languageOptions = await this.addTranslationsSame(this.$store?.getters?.loginTenantDetails?.languageAbility);
    // console.log("this.languageOptions = await", this.languageOptions);

    
    
    // Get left panel data
//     this.languageOptions = this.languageOptions.map((language) => {
//     if (language.name === "english") {
//         return { ...language, checked: true }; 
//     }
//     return language; 
// });
    let organisationInfo = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${this.$cookies.get(
        "user"
      )}?company=${this.$cookies.get("company")}`
    );
    var countryValue = "";
    organisationInfo.data.contactMedium.forEach((item) => {
      if (item.mediumType == "postalAddress") {
        let country = [
          {
            mediumType: "postalAddress",
            characteristic: item?.characteristic,
          },
        ];
        countryValue = country[0]?.characteristic.country;
      }
    });
    console.log(countryValue, "country");
    countryValue;
    const countryList = await MLTAxiosInstance.post("/util/filterData", {
      type: "filter",
      module: "countryinfo",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: {
        "name.common": { $regex: countryValue, $options: "i" },
      },
      searchQuery: countryValue,
      isNeedToMap: true,
      fieldsToMap: [
        {
          from: "name",
          isNested: true,
          nested: ["common"],
          to: "name",
        },
      ],
    });
    console.log(countryList.data.data, "cou");
    // var states = []
    countryList.data.data.forEach((item) => {
      if (item.states.length != 0) {
        console.log(item.states,'statess')
        this.stateList = item.states;
        this.stateList.forEach(province => {
    province[this.selectedLanguageName] = province.name;
});

      }
    });

    const overviewData = await MLTAxiosInstance.get(
      `/partymanagement/individual/${this.$route.query.id}`
    );
    console.log(overviewData.data, "userDatata");
    this.companyName = overviewData.data.company
    this.userName = overviewData.data.name
    this.userDetails = overviewData.data;
    const asscss = await MLTAxiosInstance.get(`partyRole`);
    this.roleList = await asscss.data;
    this.roleList = await this.capitalizeAndSort(this.roleList, "role");
    this.roleList = this.roleList.filter(
            (item) => item.role !== overviewData.data.role
          );
    const tenantPayload = await MLTAxiosInstance.get("/settings");
    
    console.log(tenantPayload.data.languages, "languages");
    const languageOptions = Object.values(tenantPayload.data.languages).map(
      (name) => ({ name, english: name })
    );
    languageOptions
    // const settings = await MLTAxiosInstance.get(`settings`);
    this.settingsID = tenantPayload.data._id;
    // this.duplicatedataPayload.timeZone = tenantPayload.data.currentTimezone;
    this.duplicatedataPayload.defaultLanguage = tenantPayload.data.currentLanguage;
    // const individual = await MLTAxiosInstance.get(
    //   `/partymanagement/individual/${this.$cookies.get("userId")}`
    // );
    // console.log(
    //   individual.data.firstName,
    //   "data123",
    //   individual.data.lastName,
    //   individual.data.email,
    //   individual.data.status,
    //   individual.data.role
    // );
    console.log(overviewData.data,'dkkd');
    // this.duplicatedataPayload.attachment = overviewData.data.attachment ;
    // this.duplicatedataPayload.firstName = overviewData.data.firstName ;
    // this.duplicatedataPayload.lastName = overviewData.data.lastName 
    // this.duplicatedataPayload.email =  overviewData.data.email 
    // this.duplicatedataPayload.status = overviewData.data.status 
    // this.duplicatedataPayload.role = overviewData.data.role 
    // this.duplicatedataPayload.Position = overviewData.data?.Position 
    //   this.duplicatedataPayload.businessUnit = overviewData.data?.businessUnit 
    //   this.duplicatedataPayload.employeeId = overviewData.data?.employeeId 
    //   this.duplicatedataPayload.fsa = overviewData.data?.fsa 
    //   this.duplicatedataPayload.group = overviewData.data?.group 
    //   this.duplicatedataPayload.marketSegment = overviewData.data?.marketSegment 
    //   this.duplicatedataPayload.reportingTo = overviewData.data?.reportingTo 
    //   this.duplicatedataPayload.reportingtoUser = overviewData.data?.reportingtoUser 
    //   this.duplicatedataPayload.sfdcPassword = overviewData.data?.sfdcPassword 
    //   this.duplicatedataPayload.sfdcToken = overviewData.data?.sfdcToken 
    //   this.duplicatedataPayload.sfdcUsername = overviewData.data?.sfdcUsername 
    //   this.duplicatedataPayload.subGroup = overviewData.data?.subGroup 
    //   this.duplicatedataPayload.userTitle = overviewData.data?.userTitle 
    //   this.duplicatedataPayload.userType = overviewData.data?.userType 
    //   this.duplicatedataPayload.userPhoneNumber = overviewData.data?.userPhoneNumber 
    //   this.duplicatedataPayload.usertelephoneNumber= overviewData.data?.usertelephoneNumber 
    this.restricted = overviewData.data.restrictionMode
    this.duplicatedataPayload = overviewData.data
    this.duplicatedataPayload.defaultLanguage = overviewData.data.languageAbility[0].name.toLowerCase()
    this.parentName = this.duplicatedataPayload?.parentRelationship?.name

    this.dataPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))
    console.log(this.duplicatedataPayload,'datatesting')
    this.duplicatedataPayload['userreportingtoUser'] = this.duplicatedataPayload?.parentRelationship?.name
    let segmentarray =[]
    if(this.duplicatedataPayload.marketSegment){
     this.duplicatedataPayload.marketSegment.forEach((item)=>{
         segmentarray.push(item.name)
    })
  }
    console.log(segmentarray,'segment')
    // this.duplicatedataPayload['']
    this.duplicatedataPayload['usermarketSegment'] = segmentarray
    this.duplicatedataPayload['useremployeeId'] = this.duplicatedataPayload['employeeId']
    this.duplicatedataPayload['userPosition'] = this.duplicatedataPayload['position']
    this.duplicatedataPayload['province'] = this.duplicatedataPayload['region']

    this.detailPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))
    // this.detailPayload['userPosition'] = this.duplicatedataPayload.position 
    this.tempOrganizationPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))
    this.switchdataPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))
 
    console.log(this.detailPayload,'detailsss')

    const schema = await UPCAxiosInstance.get(
      `/customSchema/getSchema?module=${'User'}`
    );
      this.organisationSchema = JSON.parse(JSON.stringify(schema?.data?.schema))
      this.detailSchema = JSON.parse(JSON.stringify(schema?.data?.schema))
    const filtered = Object.entries(this.organisationSchema).filter(
                ([, value]) =>
                  value.section &&
                  value.section.includes('overview')
              );   
     const fieldSchema = Object.fromEntries(filtered);
     const entries = Object.entries(fieldSchema);
       
              // Sort based on `others.displayOrder` if it exists
              entries.sort((a, b) => {
                const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
                const orderB = b[1].others?.displayOrder ?? Infinity;
                return orderA - orderB; // Sort ascending
              });
       
              // Convert back to an object
              const data = Object.fromEntries(entries);
              data['defaultLanguage'].options =  this.languageOptions 
     this.organisationSchema = data
     this.duplicateOrganisationSchema = JSON.parse(JSON.stringify(this.organisationSchema))
     Object.values(this.organisationSchema).forEach(item => {
    if (item.others && !item.others.readOnly) {
        item.others.readOnly = true;
    }
    });
    if(this.edit == true){
       this.organisationSchema = this.duplicateOrganisationSchema
    }
    
    
    
     const filtered1 = Object.entries(this.detailSchema).filter(
                ([, value]) =>
                  value.section &&
                  value.section.includes('additional details')
              );   
     const fieldSchema1 = Object.fromEntries(filtered1);
     const entries1 = Object.entries(fieldSchema1);
       
              // Sort based on `others.displayOrder` if it exists
              entries1.sort((a, b) => {
                const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
                const orderB = b[1].others?.displayOrder ?? Infinity;
                return orderA - orderB; // Sort ascending
              });
       
              // Convert back to an object
              const data1 = Object.fromEntries(entries1);
              // console.log(this.stateList,'stateList',this.$store.getters.get)
              data1['userProvince'].options = this.stateList
     this.detailSchema = data1
     this.duplicatedetailSchema = JSON.parse(JSON.stringify(this.detailSchema))

     Object.values(this.detailSchema).forEach(item => {
    if (item.others && !item.others.readOnly) {
        item.others.readOnly = true;
    }
    });
    if(this.edit == true){
       this.detailSchema = this.duplicatedetailSchema
    }





     this.dataPayload =  this.mapPayloads(this.dataPayload,this.organisationSchema)
     this.detailPayload = this.mapPayloads(JSON.parse(JSON.stringify(this.detailPayload)),JSON.parse(JSON.stringify(this.detailSchema)))


    
    // try {
      // let organisationInfo = await MLTAxiosInstance.get(
      //   `/partymanagement/organisation/${this.$cookies.get(
      //     "user"
      //   )}?company=${this.$cookies.get("company")}`
      // );

      this.langList = organisationInfo?.data?.languageAbility?.map(
        (language) => ({
          ...language,
          checked: false,
        })
      )
    // } catch (error) {
    //   console.error("Error fetching language data:", error);
    // }

    // let individualRes = await MLTAxiosInstance.get(
    //   `partymanagement/individual/auth`
    // );
    // let userRes = await MLTAxiosInstance.get(
    //   `partymanagement/individual/${individualRes.data.userId}?company=${individualRes.data.company}`
    // );
    // console.log("userRes:", userRes);

    // //   const r = 'this.loadMapData()';
    // //     console.log("r.........",r);

    // //   let organisationInfo = await MLTAxiosInstance.get(
    // //     `/partymanagement/organisation/${this.$cookies.get(
    // //       "user"
    // //     )}?company=${this.$cookies.get("company")}`
    // //   );
    // //   console.log("🚀 ~ mounted ~ organisationInfo:", organisationInfo.data);
    // this.organisationData = userRes.data;
    // const postalAddress = this.organisationData?.contactMedium?.find(
    //   (item) => item?.mediumType == "postalAddress"
    // );
    // if (postalAddress) {
    //   this.addressData = postalAddress;
    // }
    // if (this.organisationData.attachment[0]) {
    //   let isProfilePicture = this.organisationData.attachment?.find(
    //     (item) => item.attachmentType == "profile"
    //   );
    //   if (isProfilePicture) {
    //     let uploadedContent = await UPCAxiosInstance.get(
    //       "/gcpupload/files/" + this.organisationData.attachment[0]?.name,
    //       { responseType: "arraybuffer" }
    //     );
    //     console.log("🚀 ~ mounted ~ uploadedContent:", uploadedContent);
    //     this.profilePicturePrevalue = this.bufferToDataURL(
    //       uploadedContent.data
    //     );
    //     this.profilePicture = isProfilePicture;
    //   }
    // }
    // this.addressKey += 1;
    this.duplicatedataPayload = JSON.parse(JSON.stringify(this.dataPayload));
    this.tempOrganizationPayload = JSON.parse(
      JSON.stringify(this.duplicatedataPayload)
    );
    const instance = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${this.$cookies.get("userId")}?company=${this.$cookies.get("company")}`
    );

    this.instanceData = instance.data;
    this.instanceAddress = this.instanceData.contactMedium.find(
      (item) => item.mediumType === "postalAddress"
    );

    // console.log(
    //   this.instanceData,
    //   this.instanceAddress?.characteristic?.description,
    //   "this.instanceData"
    // );
    
  },
  methods: {
    async deactiveMethod() {
    console.log(this.companyName,'dataPayload')
      if(this.roleData == ''){
        this.roleData = this.adminName
      }
      this.modal_active_Deactivate = false;
      let i = {
        id: this.$route.query.id,
        "@baseType": "organisation",
        status: "Inactive",
      };
      const contacts = await MLTAxiosInstance.patch(
        `/partymanagement/individual/?company=${this.companyName}`,
        i
      );
      console.log(contacts, "lllalsldddddddddddddddddddddddjsddslk");
      // this.contactsDataCalling(this.currPage, this.rowsPerPage);
      // this.refreshHierarchy();

      if (contacts.data.email) {
        this.alertmsg = "success";
        this.notification = {
          type: "success",
          message: `The user ${this.userName} has been successfully deactivated. All data has been reassigned to ${this.roleData}.`,
        };
        this.rolesEditButton = true;
        const overviewData = await MLTAxiosInstance.get(
      `/partymanagement/individual/${this.$route.query.id}`
    );
        this.duplicatedataPayload = overviewData.data
        this.userDetails = overviewData.data;

    this.duplicatedataPayload.defaultLanguage = overviewData.data.languageAbility[0].name.toLowerCase()
    this.parentName = this.duplicatedataPayload?.parentRelationship?.name

    this.dataPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))
    console.log(this.duplicatedataPayload,'datatesting')
    this.duplicatedataPayload['userreportingtoUser'] = this.duplicatedataPayload?.parentRelationship?.name
    let segmentarray =[]
     this.duplicatedataPayload.marketSegment.forEach((item)=>{
         segmentarray.push(item.name)
    })
    console.log(segmentarray,'segment')
    // this.duplicatedataPayload['']
    this.duplicatedataPayload['usermarketSegment'] = segmentarray
    this.duplicatedataPayload['useremployeeId'] = this.duplicatedataPayload['employeeId']
    this.duplicatedataPayload['userPosition'] = this.duplicatedataPayload['position']
    this.duplicatedataPayload['province'] = this.duplicatedataPayload['region']

    this.detailPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))
    // this.detailPayload['userPosition'] = this.duplicatedataPayload.position 
    this.tempOrganizationPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))
    this.switchdataPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))

    this.organisationSchema = JSON.parse(JSON.stringify(this.duplicateOrganisationSchema))
 
    console.log(this.detailPayload,'detailsss')
      } else {
        this.alertmsg = "danger";
        this.notification = {
          type: "danger",
          message: `Data could not be inserted.`,
        };
      }
      // this.contactsDataCalling(this.currPage, this.rowsPerPage);
    },
    edittoView(){
      this.edit = true
      this.organisationSchema = this.duplicateOrganisationSchema
      this.detailSchema = this.duplicatedetailSchema
    },
    restrictMode(val){
      this.restricted = val.checked
    },
    mapPayloads(payload,schema) {
      const result = {};
    //  console.log(this.dataPayload,this.organisationSchema,'resultdata')
      const firstPayloadKeys = Object.keys(payload);

      // Iterate through the keys of the second payload
      Object.keys(schema).forEach((key) => {
        console.log('testData')
        if (firstPayloadKeys.includes(key)) {
          // If the key exists in firstPayload, take its value
          result[key] = payload[key];
        } else {
          // Otherwise, assign an empty string
          result[key] = '';
        }
      });
     
      console.log(result,'resultData')
      return   JSON.parse(JSON.stringify(result))

    },
    async switchSidebar(value){
    if(value == 'Overview'){
      // this.switchdataPayload.Position = this.tempOrganizationPayload?.Position
      // this.switchdataPayload.businessUnit = this.tempOrganizationPayload?.businessUnit
      // this.switchdataPayload.employeeId = this.tempOrganizationPayload?.employeeId
      // this.switchdataPayload.fsa = this.tempOrganizationPayload?.fsa
      // this.switchdataPayload.group = this.tempOrganizationPayload?.group
      // this.switchdataPayload.marketSegment = this.tempOrganizationPayload?.marketSegment
      // this.switchdataPayload.province = this.tempOrganizationPayload?.province
      // this.switchdataPayload.reportingTo = this.tempOrganizationPayload?.reportingTo
      // this.switchdataPayload.reportingtoUser = this.tempOrganizationPayload?.reportingtoUser
      // this.switchdataPayload.sfdcPassword = this.tempOrganizationPayload?.sfdcPassword
      // this.switchdataPayload.sfdcToken = this.tempOrganizationPayload?.sfdcToken
      // this.switchdataPayload.sfdcUsername = this.tempOrganizationPayload?.sfdcUsername
      // this.switchdataPayload.subGroup = this.tempOrganizationPayload?.subGroup
      // this.switchdataPayload.userTitle = this.tempOrganizationPayload?.userTitle
      // this.switchdataPayload.userType = this.tempOrganizationPayload?.userType
      // this.switchdataPayload.userPhoneNumber = this.tempOrganizationPayload?.userPhoneNumber
      // this.switchdataPayload.usertelephoneNumber= this.tempOrganizationPayload?.usertelephoneNumber
      // this.dataPayload = JSON.parse(JSON.stringify(this.switchdataPayload))

      this.organisationSchema = JSON.parse(JSON.stringify(this.duplicateOrganisationSchema))
    const filtered = Object.entries(this.organisationSchema).filter(
                ([, value]) =>
                  value.section &&
                  value.section.includes('overview')
              );   
     const fieldSchema = Object.fromEntries(filtered);
     const entries = Object.entries(fieldSchema);
       
              // Sort based on `others.displayOrder` if it exists
              entries.sort((a, b) => {
                const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
                const orderB = b[1].others?.displayOrder ?? Infinity;
                return orderA - orderB; // Sort ascending
              });
       
              // Convert back to an object
              const data = Object.fromEntries(entries);
     this.organisationSchema = data
    
    //  this.mapPayloads()
     this.duplicateOrganisationSchema = JSON.parse(JSON.stringify(this.organisationSchema))
     Object.values(this.organisationSchema).forEach(item => {
    if (item.others && !item.others.readOnly) {
        item.others.readOnly = true;
    }
    });
    if(this.view == true){
       this.organisationSchema = this.duplicateOrganisationSchema
    }

      this.mapPayloads(this.dataPayload,this.organisationSchema)

    }else{
      this.switchdataPayload.firstName = this.tempOrganizationPayload?.firstName 
      this.switchdataPayload.lastName = this.tempOrganizationPayload?.lastName 
      this.switchdataPayload.attachment = this.tempOrganizationPayload?.attachment 
      this.switchdataPayload.defaultLanguage = this.tempOrganizationPayload?.defaultLanguage 
      this.switchdataPayload.role = this.tempOrganizationPayload?.role 
      this.switchdataPayload.status = this.tempOrganizationPayload?.status 
      this.dataPayload = JSON.parse(JSON.stringify(this.switchdataPayload))
      console.log(this.settingSchema,'tedjgdsyhj')
      this.organisationSchema = JSON.parse(JSON.stringify(this.settingSchema))
    const filtered = Object.entries(this.settingSchema).filter(
                ([, value]) =>
                  value.section &&
                  value.section.includes('additional details')
              );   
     const fieldSchema = Object.fromEntries(filtered);
     const entries = Object.entries(fieldSchema);
       
              // Sort based on `others.displayOrder` if it exists
              entries.sort((a, b) => {
                const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
                const orderB = b[1].others?.displayOrder ?? Infinity;
                return orderA - orderB; // Sort ascending
              });
       
              // Convert back to an object
              const data = Object.fromEntries(entries);
     this.organisationSchema = data
     this.settingSchema = JSON.parse(JSON.stringify(this.organisationSchema))
     Object.values(this.organisationSchema).forEach(item => {
    if (item.others && !item.others.readOnly) {
        item.others.readOnly = true;
    }
    });
    if(this.view == true){
       this.organisationSchema = this.settingSchema
    }

    }
    },
    async resetPasswordfunc(i) {
      console.log(i, "mail send request");
      if (i != undefined) {
        this.notification = {
          type: "success",
          message: `Password reset link has been successfully sent to ${this.userName} email`,
        };

        console.log(i, "sendEmail");
        if(this.$cookies.get('company') === 'Uat'){
        var prod = process.env.VUE_APP_BASE_URL_bell
      }else{
        prod = process.env.VUE_APP_BASE_URL
      }
      prod;
        const dev = "http://localhost:8080";
        dev;

        let vbnm = 2;
        let tokenPayload = {
          user: {
            email: i.email,
            company: i.company,
          },
          validTo: vbnm + "h",
          additionalFields: {
            supplierLogin: true,
          },
        };

        let generatedToken = await MLTAxiosInstance.post(
          "/partymanagement/individual/generateToken",
          tokenPayload
        );
        generatedToken = generatedToken.data;

        console.log(generatedToken, "mail send sucessfully");
        let mail = {
          to: [i.email],
          subject: `Password Reset Request`,
          template: `<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
    <link href='https://fonts.googleapis.com/css?family=Open+Sans' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <style type="text/css" media="screen">
        body {
            padding: 0 !important;
            margin: 0 !important;
            display: flex !important;
            justify-content: center !important;
            min-width: 100% !important;
            width: 100% !important;
            background: #f5f5f5;
            -webkit-text-size-adjust: none;
        }
        .mobile-shell {
            width: 100%;
        }
        .td {
            width: 100%;
        }
        .wrapper {
            width: 100%;
            table-layout: fixed;
        }
        .p35-15 {
            padding: 35px 17px;
        }
        .headline {
            text-align: center;
        }
        .hero-title-1 {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            padding-top: 15px;
            line-height: 28px;
            text-align: center;
            color: #191A15;
        }
        .hero-content {
            padding-top: 25px;
            padding-left: 100px;
            padding-right: 100px;
            color: black;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 25px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .btn {
            text-align: center;
            padding-top: 25px;
        }
        .btn a {
            background-color: #54BD95;
            border-radius: 6px;
            color: #ffffff;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            text-align: center;
            text-decoration: none;
            width: 218.18px;
            height: 38px;
            -webkit-text-size-adjust: none;
            padding: 10px 12px;
            line-height: 15px;
        }
        .disclaimer2 {
            padding: 40px 77px;
            color: #FFFFFF;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .footer-link a {
            color: #FFFFFF;
            font-size: 14px;
        }
        .address {
            padding-left: 17px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            color: #FFFFFF;
            margin-top: 15px;
            margin-bottom: 0px;
            letter-spacing: .01em;
            line-height: 20px;
        }
        .social-icons {
            float: right;
            margin-right: -50px;
            padding-top: 20px;
        }
        .social-icons a {
            padding-left: 5px;
            padding-right: 5px;
            color: #FFFFFF;
        }
    </style>
</head>

<body class="body" style="
 display: grid;
    justify-items: center;
    background-color: #f5f5f5;
">
    <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
    <table width="612px" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5 " style="margin: 0 auto">
        <tr>
            <td align="center" valign="top">
                <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                    <tr>
                        <td class="td">
                            <table border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td  height: 501px;">
                                        <!-- Header -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="wrapper">
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="10" cellspacing="0" width="77%" class="wrapper" bgcolor="#f5f5f5">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="wrapper" bgcolor="#ffffff">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Header -->
                                        <!-- Start Content -->
                                        <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501" cellpadding="0">
                                            <tr>
                                                <td bgcolor="#FFFFFF" class="p35-15">
                                                    <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                         <div class="container">
    <p>Hi ${i.name},</p>
      <p>
        We received a request to reset your password for your account. To reset your password, please click the link below:
      </p>
      <p>
        <a href=${prod}/supplierinvite/${i.company}/${i.id}/${
            generatedToken.token
          }?login=Userinvite&Password=${'RestPassword'} class="mob-btn" target="_blank"> https://www.${this.$cookies.get(
            "company"
          )}.com/password-reset?token=abcdef123456</a>
      </p>
      <p>
        If you didn’t request a password reset, please ignore this email.
      </p>
      <p>Thank you,</p>
      <p>${this.$cookies.get("company")} Team</p>
    </div>                                                 
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- HERO Images-->
                                    </td>
                                </tr>
                            </table>
                            <!-- END Content -->
                            <!-- Footer -->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding: 0 15px;" bgcolor="#ffffff">
                                <tr>
                                    <td bgcolor="#161C2B" class="disclaimer2">
                                        <span class="footer-link"><a href="https://halleyx.com">T&C</a><a style="padding-left:40px; padding-right: 40px;" href="https://halleyx.com">Privacy</a><a href="https://halleyx.com">Cookies</a></span><br/>
                                        <br/>
                                        © ${
                                          this.currentYear
                                        } ${this.$cookies.get(
            "company"
          )}. Copyright and rights reserved
                                        <p class="address">${
                                          this.instanceAddress.characteristic
                                            .description
                                        }</p>
                                        <span style="float:left; margin-left: -60px; padding-top:20px;"><img width="100" src=""/></span>
                                        <span class="social-icons"><a href="https://www.linkedin.com/company/halleyx/"><i class="fa fa-linkedin fa-lg"></i></a>
                                            <a href="https://halleyx.com/"><i class="fa fa-facebook fa-lg"></i></a>
                                            <a href="https://www.instagram.com/halleyx_inc/"><i class="fa fa-instagram fa-lg"></i></a></span>
                                    </td>
                                </tr>
                            </table>
                            <!-- END Footer -->
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    <img src="images?q=tbn:ANd9GcRYx7vG1ermb_muIP457oVO48JixkaC0RvhAA&usqp=CAU" width="1" height="1">
</body>

</html>

`,
        };
        // const sendEmail =
        await MLTAxiosInstance.post(`/email`, mail);
      }
    },
    async editContactData() {
      let obj = [
        {
          relatedParty: [
            {
              id: this.$route.query.id,
            },
          ],
          name: this.roleData,
        },
      ];

      let result = await MLTAxiosInstance.patch(
        `/partyRole/bulkwrites/v4`,
        obj
      );
      result;
      // console.log("noNotif", notif);
      if (result.status === 200) {
        this.notification = {
          type: "success",
          message: `The role for ${this.userName} has been successfully updated to ${this.roleData}.`,
        };
      }

      this.callAPI();
      this.closeAddNewContactModal();
    },

    async callAPI() {
      const overviewData = await MLTAxiosInstance.get(
      `/partymanagement/individual/${this.$route.query.id}`
    );
        this.duplicatedataPayload = overviewData.data
        this.userDetails = overviewData.data;
    this.duplicatedataPayload.defaultLanguage = overviewData.data.languageAbility[0].name.toLowerCase()
    this.parentName = this.duplicatedataPayload?.parentRelationship?.name

    this.dataPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))
    console.log(this.duplicatedataPayload,'datatesting')
    const asscss = await MLTAxiosInstance.get(`partyRole`);
    this.roleList = await asscss.data;
    this.roleList = await this.capitalizeAndSort(this.roleList, "role");
    this.roleList = this.roleList.filter(
            (item) => item.role !== overviewData.data.role
          );
    this.duplicatedataPayload['userreportingtoUser'] = this.duplicatedataPayload?.parentRelationship?.name
    let segmentarray =[]
    if(this.duplicatedataPayload.marketSegment){
     this.duplicatedataPayload.marketSegment.forEach((item)=>{
         segmentarray.push(item.name)
    })
    console.log(segmentarray,'segment')
    // this.duplicatedataPayload['']
    this.duplicatedataPayload['usermarketSegment'] = segmentarray
  }
    this.duplicatedataPayload['useremployeeId'] = this.duplicatedataPayload['employeeId']
    this.duplicatedataPayload['userPosition'] = this.duplicatedataPayload['position']
    this.duplicatedataPayload['province'] = this.duplicatedataPayload['region']

    this.detailPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))
    // this.detailPayload['userPosition'] = this.duplicatedataPayload.position 
    this.tempOrganizationPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))
    this.switchdataPayload = JSON.parse(JSON.stringify(this.duplicatedataPayload))

    this.organisationSchema = JSON.parse(JSON.stringify(this.duplicateOrganisationSchema))
    },
    closeAddNewContactModal() {
      console.log(this.tagsList, "tagsList");
      this.modalKey += 1;
      this.rolecomponentkey += 1;
      this.isEmailEmpty = false;
      this.editMode = false;
      this.userPayload = {
        firstName: "",
        lastName: "",
        email: "",
        role: "",
      };
      this.modal_active_role = false;
      this.modal_active_user = false;
    },
    computedTableAction(data) {
     
 const options = [
  
    {
      label: "Mark as active",
      display: this.matchFromStatic("Mark as active"),
      icon: "icon-grid-web-2-regular",
    },
    {
      label: "Reset password",
      display: this.matchFromStatic("Reset password"),
      icon: "icon-lock-viewfinder-regular",
    },
    {
      label: "Deactivate",
      display: this.matchFromStatic("Deactivate"),
      icon: "icon-power-off-square-regular",
    },
    {
      label: "Change role",
      display: this.matchFromStatic("Change role"),
      icon: "icon-user-change-regular",
    },
    {
      label: "Resend invite",
      display: this.matchFromStatic("Resend invite"),
      icon: "icon-user-change-regular",
    },
    // {
    //   label: "Delete",
    //   display: this.matchFromStatic("Delete"),
    //   icon: "icon-trash-regular",
    // },
  ];
  let userId = this.$cookies.get("user");

  return options.filter((option) => {
    if (
      userId &&
      userId == data.id &&
      ["Delete", "Deactivate", "Change role", "Resend invite"].includes(option.label)
    ) {
      return false;
    } else if (
      data &&
      data.status &&
      data.status.toLowerCase() === "pending" &&
      !["Reset password", "Delete", "Edit", "Change role"].includes(option.label)
    ) {
      return false;
    } else if (
      data &&
      data.status &&
      data.status.toLowerCase() === "active" &&
      ["Mark as active", "Resend invite"].includes(option.label)
    ) {
      return false;
    } else if (
      data &&
      data.status &&
      data.status.toLowerCase() === "inactive" &&
      ["Deactivate", "Resend invite"].includes(option.label)
    ) {
      return false;
    } else {
      return true;
    }
  });

    },
    async fromAddContextMenu($event, ele, i) {
      console.log("🚀 ~ closeCrudContextMenu ~ i:", i);
      console.log("🚀 ~ closeCrudContextMenu ~ ele:", ele);
      // //
      this.editUser = ele;
      this.listView = ele;
      this.editUser.role =
        ele.role && ele.role.length > 0 ? ele.role[0]?.name : "";

      if ($event.toLowerCase() == "deactivate") {
        this.modal_active_Deactivate = true;
        this.deactivateUser = ele;
        this.deactivateUserName = ele.firstName + " " + ele.lastName;
      } else if ($event.toLowerCase() == "mark as active") {
        let i = {
          id: ele._id,
          status: "Active",
          context: false,
        };
        const contacts = await MLTAxiosInstance.patch(
          `/partymanagement/individual/?company=${ele.company}`,
          { ...i, "@baseType": "organisation" }
        );
        this.dataPayload.status = "Active";
        if (contacts.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
          this.callAPI()
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        this.organisationSchema = this.duplicateOrganisationSchema;
        this.dataPayload.status = contacts.data.status;
        this.userDetails = contacts.data;
      } else if ($event.toLowerCase() === "send invite") {
        console.log(i);
        this.inviteUser(ele);
      } else if ($event.toLowerCase() === "reset password") {
        let i = {
          id: ele._id,
          setValidity: "Pending",
          context: false,
        };
        const contacts = await MLTAxiosInstance.patch(
          `/partymanagement/individual/?company=${ele.company}`,
          { ...i, "@baseType": "organisation" }
        );
        contacts;
        this.resetPasswordfunc(ele);
      } else if ($event.toLowerCase() == "change role") {
        console.log(ele, "checkkk ");
        this.userName = ele.firstName + ele.lastName;
        this.newConPayload = JSON.parse(JSON.stringify(ele));
        //  this.roleData = JSON.parse(JSON.stringify(ele))
        this.modal_active_role = true;
      }
    },
    async deleteExecRow() {
      console.log(this.deleteobj, "sdfghjk");
      this.delete_modal_active = false;
      let i = {
        id: this.deleteobj._id,
        status: "Inactive",
      };
      const contacts = await MLTAxiosInstance.patch(
        `/partymanagement/individual/?company=${this.deleteobj.company}`,
        { ...i, "@baseType": "organisation" }
      );

      this.dataPayload.status = contacts.data.status;
      this.dataPayload.role = contacts.data.role;

      this.userDetails = contacts.data;
      this.organisationSchema = this.duplicateOrganisationSchema;
      if (contacts.data.email) {
        this.alertmsg = "success";
        this.notification = {
          type: "success",
          message: `All set! Your changes have been saved successfully!`,
        };
      } else {
        this.alertmsg = "danger";
        this.notification = {
          type: "danger",
          message: `Data could not be inserted.`,
        };
      }
    },
    openAddFieldsPanel() {
      this.containerleft1 = document
        .querySelector(".custom-field-add-btn")
        .getBoundingClientRect().left;
      this.containertop1 =
        document.querySelector(".custom-field-add-btn").getBoundingClientRect()
          .top + 40;
      this.showContextMenu = !this.showContextMenu;
    },
    passwordValidate(val1, val2) {
      if (this.selectedValue == "Change password") {
        if (val2 == "Enter new password") {
          this.conditionNewPassword = val1;
        } else if (val2 == "Confirm password") {
          this.conditionConfirmPassword = val1;
        }
      }
    },
    openEditPanel() {
      this.view = true;
      this.organisationSchema = this.duplicateOrganisationSchema;
      console.log(this.organisationSchema, "schemanew");
    },
    async selectedValueFunc(value) {
      this.selectedValue = value
     if(this.selectedValue == 'Overview'){
      // this.mapPayloads(this.dataPayload,this.organisationSchema)
      this.dataPayload = this.tempOrganizationPayload
    //  Object.values(this.organisationSchema).forEach(item => {
    // if (item.others && !item.others.readOnly) {
    //     item.others.readOnly = true;
    // }
    // });
    // if(this.view == true){
    //    this.organisationSchema = this.duplicateOrganisationSchema
    // }
   
  }  else{
    this.detailPayload = JSON.parse(JSON.stringify(this.switchdataPayload)) 
    this.detailPayload = this.mapPayloads(JSON.parse(JSON.stringify(this.detailPayload)),JSON.parse(JSON.stringify(this.detailSchema)))
    console.log(this.duplicatedataPayload?.parentRelationship?.name,'parentName')
    this.detailPayload['userreportingtoUser'] = this.parentName
    //  Object.values(this.detailSchema).forEach(item => {
    // if (item.others && !item.others.readOnly) {
    //     item.others.readOnly = true;
    // }
    // });
    // if(this.view == true){
    //    this.detailSchema = this.duplicatedetailSchema
    // }
    // this.mapPayloads(this.detailPayload,this.detailSchema)

     }
    },
    detailemitPayload(val){
      console.log(val,'detailPayload')
      this.switchdataPayload = JSON.parse(JSON.stringify(val)) 
    },
    patchEditedLead(val) {
        console.log(this.dataPayload,'overviewpayload')
        this.tempOrganizationPayload = JSON.parse(JSON.stringify(val))
      
      // if (this.selectedValue == "Overview") {
      //   this.tempOrganizationPayload = val;
      // } else {
      //   this.temporaryPayload = val;
      // }
      // console.log(this.tempOrganizationPayload,'temporary')
      // if (this.selectedValue != "Overview") {
      //   this.dataPayload = val;
      //   console.log(val, "test");
      //   if (this.currentPass != this.tempOrganizationPayload.currentPassword) {
      //     this.currentPass = this.tempOrganizationPayload.currentPassword;
      //     this.passwordCheck();
      //   }
      //   console.log("newwwww111", this.tempOrganizationPayload.newPassword);
      //   if (this.tempOrganizationPayload.newPassword != "") {
      //     // console.log('newwwww',this.tempOrganizationPayload.newPassword,this.tempOrganizationPayload.currentPassword == this.tempOrganizationPayload.newPassword)
      //     this.newpasswordCheck();
      //   }
      //   if (this.tempOrganizationPayload.confirmPassword != "") {
      //     if (
      //       this.tempOrganizationPayload.confirmPassword !=
      //       this.tempOrganizationPayload.newPassword
      //     ) {
      //       this.organisationSchema[
      //         "confirmPassword"
      //       ].others.passwordErrorValidation.content =
      //         "Password must match the new password.";
      //       this.organisationSchema[
      //         "confirmPassword"
      //       ].others.passwordErrorValidation.display = true;
      //       this.validateConfirmPassword = false;
      //     } else {
      //       this.organisationSchema[
      //         "confirmPassword"
      //       ].others.passwordErrorValidation.content = "";
      //       this.organisationSchema[
      //         "confirmPassword"
      //       ].others.passwordErrorValidation.display = false;
      //       this.validateConfirmPassword = true;
      //     }
      //   }
      //   // if(this.tempOrganizationPayload.currentPassword != this.tempOrganizationPayload.newPassword){
      //   //   this.organisationSchema['newPassword'].others.passwordErrorValidation.content = ''
      //   //   this.organisationSchema['newPassword'].others.passwordErrorValidation.display = false
      //   // }  if(this.tempOrganizationPayload.confirmPassword != this.tempOrganizationPayload.newPassword && this.tempOrganizationPayload.confirmPassword != ''&& this.tempOrganizationPayload.newPassword != ''){
      //   //   this.organisationSchema['confirmPassword'].others.passwordErrorValidation.content = 'Password must match the new password.'
      //   //   this.organisationSchema['confirmPassword'].others.passwordErrorValidation.display = true
      //   // }
      //   // if(this.tempOrganizationPayload.confirmPassword == this.tempOrganizationPayload.newPassword && this.tempOrganizationPayload.confirmPassword != ''&& this.tempOrganizationPayload.newPassword != ''){
      //   //   this.organisationSchema['confirmPassword'].others.passwordErrorValidation.content = ''
      //   //   this.organisationSchema['confirmPassword'].others.passwordErrorValidation.display = false
      //   // }
      // }
    },
    async passwordCheck() {
      console.log("test");

      if (this.tempOrganizationPayload.currentPassword != "") {
        let login = {
          email: this.duplicatedataPayload.email,
          password: this.tempOrganizationPayload.currentPassword,
        };
        const move_dashboard = await MLTAxiosInstance.post(
          `partymanagement/individual/login`,
          login
        );
        if (move_dashboard.data == "Incorrect email/password !!") {
          this.organisationSchema[
            "currentPassword"
          ].others.passwordErrorValidation.content =
            "Incorrect password. Please try again.";
          this.organisationSchema[
            "currentPassword"
          ].others.passwordErrorValidation.display = true;
          this.validateCurrentPassword = false;
        } else {
          this.organisationSchema[
            "currentPassword"
          ].others.passwordErrorValidation.content = "";
          this.organisationSchema[
            "currentPassword"
          ].others.passwordErrorValidation.display = false;
          this.validateCurrentPassword = true;
        }
        console.log(move_dashboard, "🔥🔥🔥🔥");
      }
    },
    async newpasswordCheck() {
      let login = {
        email: this.duplicatedataPayload.email,
        password: this.tempOrganizationPayload.newPassword,
      };
      const move_dashboard = await MLTAxiosInstance.post(
        `partymanagement/individual/login`,
        login
      );
      if (move_dashboard.data == "Incorrect email/password !!") {
        this.organisationSchema[
          "newPassword"
        ].others.passwordErrorValidation.content = "";
        this.organisationSchema[
          "newPassword"
        ].others.passwordErrorValidation.display = false;
        this.validateNewPassword = true;
      } else {
        this.validateNewPassword = false;
        this.organisationSchema[
          "newPassword"
        ].others.passwordErrorValidation.content =
          "New password cannot be the same as the current password.";
        this.organisationSchema[
          "newPassword"
        ].others.passwordErrorValidation.display = true;
      }
      console.log(move_dashboard, "🔥🔥🔥🔥1");
    },
    isValidLanguageAbility(data) {
      if (!Array.isArray(data)) {
        return false; // Not an array
      }

      // Check each item in the array
      for (const item of data) {
        if (
          typeof item !== "object" || // Ensure it's an object
          item === null || // Ensure it's not null
          typeof item.name !== "string" || // Ensure `name` is a string
          typeof item.code !== "string" // Ensure `code` is a string
        ) {
          return false; // Invalid item
        }
      }

      return true; // All checks passed
    },
    selectedData(val) {
      console.log("selectedData:", val);
      this.organisationData.languageAbility[0] = {
        name: val?.name,
        code: val?.code,
      };
      console.log(
        "this.organisationData.languageAbility:",
        this.organisationData.languageAbility
      );
    },
    async fetchLangList() {
      if (this.$route?.query?.contact) {
        let filterData = {
          type: "filter",
          company: "Halleyx",
          module: "hierarchyaccounts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 10,
          filterQuery: {
            tradingName: this.$route?.query?.contact,
          },
          searchQuery: "",
        };

        try {
          const tenantDataData = await MLTAxiosInstance.post(
            `util/filterData`,
            filterData
          );
          this.langList = tenantDataData?.data?.data[0].languageAbility?.map(
            (language) => ({
              ...language,
              checked: false,
            })
          );
        } catch (error) {
          console.error("Error fetching language data:", error);
        }
      }
    },
    async handleProfilePictureUpload(file) {
      console.log(
        "🚀 ~ handleProfilePictureUpload ~ file:",
        file,
        file.target,
        file.target.result
      );
      // console.log(file[0]);
      let uploadingFile = file.target.files;
      let formData = new FormData();

      // // Append all selected files to FormData object
      for (let i = 0; i < uploadingFile.length; i++) {
        formData.append("files[]", uploadingFile[i]);
      }

      // console.log("Uploaded File:", formData.getAll('files[]'));
      // this.$emit('upload-data', formData);
      // console.log("🚀 ~ handleProilePictureUpload ~ file:", file)
      // let profileFormData = new FormData()
      //  profileFormData.append('files[]',uploadingFile)
      console.log(
        "🚀 ~ handleProfilePictureUpload ~ this.profileFormData:",
        this.profileFormData
      );
      // const uploadedProfile = await axios.post('http://localhost:5700/gcpupload/upload',this.profileFormData,{headers:{Authorization:this.$cookies.get('token')}})
      const uploadedProfile = await UPCAxiosInstance.post(
        "/gcpMultiple/v2/upload",
        formData
      );
      this.profilePicture = {
        id: uploadedProfile.data[0]?.data._id,
        attachmentType: "profile",
        description: "Sender profile picture",
        mimeType: uploadedProfile.data[0]?.data?.attachment?.contentType,
        name: uploadedProfile.data[0]?.filename,
        url: uploadedProfile.data[0]?.url,
        size: {
          amount: uploadedProfile.data[0]?.data?.attachment?.size,
          units: "KB",
        },
      };
      console.log(
        "🚀 ~ handleProfilePictureUpload ~ this.profilePicture:",
        this.profilePicture
      );
      console.log("🚀 ~ saveQuote ~ uploadedProfile:", uploadedProfile);
    },
    handlePhoneNumberChange(phoneNumber) {
      console.log("🚀 ~ handlePhoneNumberChange ~ phoneNumber:", phoneNumber);
    },
    handleAddressChange(val) {
      console.log("🚀 ~ handleAddressChange ~ val:", val);
      // let address1 = JSON.parse(JSON.stringify(val));
      // // this.addressData = address1
      // console.log(
      //   "🚀 ~ handleAddressChange ~ this.organisationData.contactMedium:",
      //   this.organisationData.contactMedium
      // );
      // let postalAddressIndex = this.organisationData.contactMedium?.findIndex(
      //   (item) => item.mediumType == "postalAddress"
      // );
      // console.log(
      //   "🚀 ~ handleAddressChange ~ postalAddressIndex:",
      //   postalAddressIndex
      // );
      // if (postalAddressIndex >= 0) {
      //   this.organisationData.contactMedium[postalAddressIndex] = address1;
      // } else {
      //   this.organisationData.contactMedium.push(address1);
      // }
      // console.log(
      //   "🚀 ~ handleAddressChange ~ this.organisationData.contactMedium:",
      //   this.organisationData.contactMedium
      // );
      // this.organisationData.
    },
    async back() {
      this.$router.back();
    },
    async pathTo() {
      // if (val.label == "Settings") {
      this.$router.back()
      // }
    },
    async exitView() {
      let update = {
        id: this.userId,
        impersonation: false,
        impersinater: "",
      };
      // const result =
      await axios.patch(this.fet + `/partymanagement/individual`, update);

      let token = {
        token: this.impersonitor,
      };
      axios.post(this.fet + `/partymanagement/individual/auth`, token);
    },
    async updateInfo() {
      let languagedata = []
      this.languageOptions.forEach((item)=>{
        if(item.name == this.tempOrganizationPayload.defaultLanguage){
            languagedata.push(item)
        }
      })
      console.log(this.tempOrganizationPayload,this.switchdataPayload,'testtttsts',languagedata)
      let arr = []
      this.switchdataPayload?.usermarketSegment.forEach((item)=>{
        let eachObj = {}
        eachObj.name = item
        arr.push(eachObj)
      })
      let name1 = this.tempOrganizationPayload.firstName+' '+this.tempOrganizationPayload.lastName
      console.log(name1,'namee')
      let additionalFieldsPayloadDefine = {
        marketSegment: arr,
        employeeId:this.switchdataPayload.useremployeeId,
        phoneNumber:this.switchdataPayload.userPhoneNumber,
        province:this.switchdataPayload.userProvince,
        group:this.switchdataPayload.userGroup,
        subgroup:this.switchdataPayload.usersubGroup,
        businessUnit:this.switchdataPayload.userBusinessUnit,
        position:this.switchdataPayload.userPosition,
        languageAbility:languagedata,
        name:name1,
        restrictionMode:this.restricted
      }
      additionalFieldsPayloadDefine
       let obj = { ...this.tempOrganizationPayload, ...this.switchdataPayload, ...additionalFieldsPayloadDefine }
       console.log(obj,'testtttsts')
      const updateInfo = await MLTAxiosInstance.patch(
        `partymanagement/individual`,
        { ...obj, "@baseType": "organisation", "id" : this.$route.query.id }
      );

      if (updateInfo.data != undefined) {
        this.showAlert = true;
        this.notification = {
          type: this.type,
          message: "User  updated successfully",
        };
      }
    },
  },
};
</script>
