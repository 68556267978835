<template>
  <div>
    <hlx-input
      v-model:value="payload.characteristic.street"
      :read-only="readOnly"
      :pre-val="payload.characteristic.street"
      type="location"
      :required="true"
      :label-value="addressLabel"
      :label-animation="true"
      :display-error="true"
      :custom-error="customError"
      :custom-error-message="customErrorMessage"
      :location-data-prop="locationData1"
      @address-data="addressData"
      @at-input="getLocations(payload.characteristic.street)"
      @focus-out="emitFocusOut"
    ></hlx-input>
    <div
      class="new-address-fields"
      style="display: flex; justify-content: space-between; gap: 12px"
    >
      <span style="width: 50%">
        <hlx-input
          v-model:value="payload.characteristic.unit"
          :read-only="readOnly"
          :pre-val="payload.characteristic.unit"
          type="text"
          :label-value="matchFromStatic('Unit/Appartment')"
          :label-animation="true"
          :display-error="true"
          :custom-error-message="matchFromStatic('Please fill the field')"
          @at-input="canEmitTrigger"
          @focus-out="emitFocusOut"
        ></hlx-input>
      </span>
      <span style="width: 50%">
        <hlx-input
          v-model:value="payload.characteristic.city"
          :read-only="readOnly"
          :pre-val="payload.characteristic.city"
          type="location"
          :label-value="matchFromStatic('City')"
          :label-animation="true"
          :required="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="matchFromStatic('Please fill the field')"
          :location-data-prop="locationData2"
          @address-data="addressData"
          @at-input="getLocations(payload.characteristic.city)"
          @focus-out="emitFocusOut"
        />
        <!-- @at-input="canEmitTrigger" -->
      </span>
    </div>
    <div
      class="new-address-fields"
      style="
        display: flex;
        justify-content: space-between;
        gap: 12px;
        margin-bottom: 20px;
      "
    >
      <span style="width: 50%">
        <hlx-input
          v-if="!isServiceQualificationEnabled"
          v-model:value="payload.characteristic.state"
          :read-only="readOnly"
          :pre-val="payload.characteristic.state"
          type="location"
          :label-value="matchFromStatic('State / Province')"
          :label-animation="true"
          :display-error="customnameerror"
          :required="true"
          :custom-error="customnameerror"
          :custom-error-message="matchFromStatic('Please fill the field')"
          :location-data-prop="locationData3"
          @address-data="addressData"
          @at-input="getLocations(payload.characteristic.state)"
          @focus-out="emitFocusOut"
        />

        <hlx-select
          v-else
          v-model:value="payload.characteristic.state"
          :pre-value="payload.characteristic.stateCode"
          :options="userStates"
          :inline-search="true"
          :required="true"
          :placeholder-value="matchFromStatic('State / Province')"
          :prop-value="'state_code'"
          :label-animation="true"
          @selected-value="selectedCountry"
        >
        </hlx-select>
        <!-- @at-input="canEmitTrigger" -->
      </span>
      <span style="width: 50%">
        <hlx-input
          v-model:value="payload.characteristic.country"
          :pre-val="
            isServiceQualificationEnabled
              ? userCountry.name
              : payload.characteristic.country
          "
          type="location"
          :label-value="matchFromStatic('Country')"
          :read-only="
            disableCountry || readOnly || isServiceQualificationEnabled
          "
          :label-animation="true"
          :display-error="customnameerror"
          :required="true"
          :custom-error="customnameerror"
          :custom-error-message="matchFromStatic('Please fill the field')"
          :location-data-prop="locationData4"
          @address-data="addressData"
          @at-input="getLocations(payload.characteristic.country)"
          @focus-out="emitFocusOut"
        />
        <!-- @at-input="canEmitTrigger" -->
      </span>
    </div>
    <div
      class="new-address-fields"
      style="display: flex; justify-content: space-between; gap: 12px"
    >
      <span style="width: 50%">
        <hlx-input
          v-model:value="payload.characteristic.postalCode"
          :read-only="readOnly"
          :pre-val="payload.characteristic.postalCode"
          :label-value="matchFromStatic('Postal code')"
          :label-animation="true"
          :required="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="matchFromStatic('Please fill the field')"
          @at-input="canEmitTrigger"
          @focus-out="emitFocusOut"
        />
      </span>
      <span style="width: 50%">
        <hlx-input
          v-model:value="payload.characteristic.fsa"
          :read-only="readOnly"
          :pre-val="getFSA(payload.characteristic.postalCode, userCountry.name)"
          :label-value="matchFromStatic('Prefix (FSA)')"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="matchFromStatic('Please fill the field')"
          @focus-out="emitFocusOut"
          @at-input="canEmitTrigger"
        />
      </span>
    </div>
    <div
      class="new-address-fields"
      style="
        display: flex;
        justify-content: space-between;
        gap: 12px;
        margin-top: 28px;
      "
    >
      <span style="width: 50%">
        <hlx-input
          v-model:value="payload.characteristic.latitude"
          :pre-val="payload?.characteristic?.latitude"
          :label-value="matchFromStatic('Latitude')"
          :label-animation="true"
          :read-only="readOnly"
          type="number"
          :display-error="true"
          :custom-error="isExistingName"
          :custom-error-message="isExistingNameErrorMessage"
          @at-input="canEmitTrigger"
          @focus-out="emitFocusOut"
        ></hlx-input>
      </span>
      <span style="width: 50%">
        <hlx-input
          v-model:value="payload.characteristic.longitude"
          :pre-val="payload?.characteristic?.longitude"
          :label-value="matchFromStatic('Longitude')"
          :label-animation="true"
          :read-only="readOnly"
          type="number"
          :display-error="true"
          :custom-error="isExistingName"
          :custom-error-message="isExistingNameErrorMessage"
          @focus-out="emitFocusOut"
          @at-input="canEmitTrigger"
        ></hlx-input>
      </span>
    </div>
  </div>
</template>

<script>
import {
  MLTAxiosInstance,
  // DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
} from '@/config/axiosConfig';
import axios from 'axios';
import debounce from 'lodash/debounce';

// import { loadGoogleMapsApi } from '../ServiceQualification/laodApi.js';

export default {
  props: {
    statesArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
    forceEmit: {
      type: Boolean,
      default: false,
    },
    customErrorMessage: {
      type: String,
      default: '',
    },
    disableCountry: {
      type: Boolean,
      default: false,
    },
    customError: {
      type: Boolean,
      default: false,
    },
    isServiceQualificationEnabled: {
      type: Boolean,
      default: false,
    },
    view: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    addressLabel: {
      type: String,
      default: 'Site address',
    },
    apis: {
      type: String,
      default: '',
    },
    apiKey: {
      type: String,
      default: 'DE92-ZE95-YR93-BU78',
    },
    authorizationType: {
      type: String,
      default: 'api-key',
    },
    type: {
      type: String,
      default: 'Google maps API',
    },
    newCoordinate: {
      type: Object,
      default: () => {
        return { lat: '', lng: '' };
      },
    },
    preVal: {
      type: Object,
      default: () => {
        return {
          mediumType: 'postalAddress',
          characteristic: {
            city: '',
            unit: '',
            country: '',
            state: '',
            postalCode: '',
            stateOrProvince: '',
            street1: '',
            street2: '',
            description: '',
            postalCodePrefix: '',
            street: '',
            default: true,
            latitude: '',
            longitude: '',
            fsa: '',
            '@type': '',
          },
        };
      },
    },
  },
  emits: [
    'addressData',
    'geopoint',
    'mapname',
    'description',
    'address-selected',
    'focusOut',
    'mountEmit',
  ],
  data() {
    return {
      focusOutNow: false,
      locationData1: '',
      isAddressChoosed: false,
      isLeadAddressMounted: false,
      addressnew: {},
      input: {},
      canEmit1: false,
      locationData: [],
      // added by ragavi
      addressSuggestion: [],
      payload: {
        mediumType: 'postalAddress',
        characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          postalCodePrefix: '',
          stateOrProvince: '',
          latitude: '',
          longitude: '',
          street1: '',
          street2: '',
          fsa: '',
          description: '',
          street: '',
          default: true,
          '@type': '',
        },
      },
      rawPayload: {},
      userCountry: {
        cca2: '',
        name: '',
      },
      userStates: [],
      fsaCodeData: [
        { country: 'Canada', format: 'ANA NAN', fsaLength: 3 },
        {
          country: 'United States',
          format: '12345 or 12345-6789',
          fsaLength: 3,
        },
        {
          country: 'United Kingdom',
          format: 'A9 9AA or AA9A 9AA',
          fsaLength: 4,
        }, // Outward code max length is 4
        { country: 'Australia', format: '1234', fsaLength: 2 },
        { country: 'India', format: '123456', fsaLength: 3 },
        { country: 'Germany', format: '12345', fsaLength: 2 },
        { country: 'France', format: '12345', fsaLength: 2 },
        { country: 'Italy', format: '12345', fsaLength: 1 },
        { country: 'Japan', format: '123-4567', fsaLength: 3 },
        { country: 'Netherlands', format: '1234 AB', fsaLength: 4 },
        { country: 'China', format: '123456', fsaLength: 2 },
        { country: 'Brazil', format: '12345-678', fsaLength: 5 },
        { country: 'Russia', format: '123456', fsaLength: 3 },
        { country: 'South Africa', format: '1234', fsaLength: 4 },
        { country: 'Mexico', format: '12345', fsaLength: 1 },
        { country: 'Argentina', format: '1234', fsaLength: 4 },
      ],
      ogAutocompleteList: [],
      getLocationsDebounced: null,
      processPayload: null,
    };
  },
  watch: {
    forceEmit: {
      handler(val) {
        if (val) {
          this.$emit('mountEmit', this.payload);
        }
      },
      immediate: true,
      deep: true,
    },
    type: {
      handler(val) {
        this.APItype = val;
      },
      immediate: true,
      deep: true,
    },
    newCoordinate: {
      handler(val) {
        console.log('newCoordinate', val);
        if (val && (val?.lat != undefined || val?.lat != null)) {
          if (val && (val?.lng != undefined || val?.lng != null)) {
            this.getGeocode(val?.lat, val?.lng);
          }
        }
      },
      immediate: true,
      deep: true,
    },

    preVal: {
      handler(val) {
        const checkPayloadChanged = this.isPayloadChanged(this.payload, val);
        console.log('checkPayloadChanged', checkPayloadChanged);
        this.rawPayload = JSON.parse(JSON.stringify(val));
        if (val.characteristic?.street != '') {
          this.payload = JSON.parse(JSON.stringify(val));
          this.payload.characteristic.state = JSON.parse(
            JSON.stringify(val.characteristic?.stateOrProvince)
          );
        }
        this.canEmit1 = false;
        console.log('qawsdcfvgbhnj', val);
      },
      immediate: true,
      deep: true,
    },

    // city:{
    //   handler(val){
    //     console.log(val);
    //   },
    //   immediate: true,
    //   deep: true,
    // },

    payload: {
      handler: function (val) {
        if (!this.processPayload) {
          this.processPayload = debounce(this.handlePayload, 800); // Debounce the handler function
        }
        this.processPayload(val); // Call the debounced function
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    console.log('12345678o');
    let userDetails = await MLTAxiosInstance.get('/settings');
    console.log(userDetails.data, 'userDetails');
    this.userCountry.cca2 = userDetails.data.cca2;
    this.userCountry.name = userDetails.data.country;
    this.payload.characteristic.country = this.userCountry.name;
    this.userStates = userDetails.data.states;

    // if (this.isLeadAddressMounted == false)
    //   this.loadGoogleMapsScript().then(() => {
    //    // Initialize Google Maps components here
    //    const autocompleteService = new window.google.maps.places.AutocompleteService();
    //    console.log('AutocompleteService Loaded from autoaddress:', autocompleteService);
    //    this.isLeadAddressMounted = true
    //  });
    // console.log('started bro00123', this.apis);
    //   this.getGeocode(38.352728,-121.979835)
  },
  methods: {
    selectedCountry(e) {
      this.payload.characteristic.stateOrProvince = e.name;
      this.payload.characteristic.stateCode = e.state_code;
      this.emitFocusOut();
    },
    emitFocusOut() {
      this.focusOutNow = true;
      this.payload.characteristic.description =
        `${
          this.payload.characteristic.street !== '' &&
          this.payload.characteristic.street !== undefined
            ? this.payload.characteristic.street
            : ''
        }` +
        `${
          this.payload.characteristic.unit !== '' &&
          this.payload.characteristic.unit !== undefined
            ? ', ' + this.payload.characteristic.unit
            : ''
        }` +
        `${
          this.payload.characteristic.city !== '' &&
          this.payload.characteristic.city !== undefined
            ? ', ' + this.payload.characteristic.city
            : ''
        }` +
        `${
          this.payload.characteristic.stateOrProvince !== '' &&
          this.payload.characteristic.stateOrProvince !== undefined
            ? ', ' + this.payload.characteristic.stateOrProvince
            : ''
        }` +
        `${
          this.payload.characteristic.country !== '' &&
          this.payload.characteristic.country !== undefined
            ? ', ' + this.payload.characteristic.country
            : ''
        }` +
        `${
          this.payload.characteristic.postalCode !== '' &&
          this.payload.characteristic.postalCode !== undefined
            ? ', ' + this.payload.characteristic.postalCode
            : ''
        }`;
      this.payload.characteristic.postalCodePrefix = this.getFSA(
        this.payload.characteristic.postalCode,
        this.payload.characteristic.country
      );
      console.log(this.payload.characteristic.postalCodePrefix, 'huyt');

      if (this.focusOutNow) {
        this.$emit('focusOut', JSON.parse(JSON.stringify(this.payload)));
        this.focusOutNow = false;
      }
    },
    handlePayload(val) {
      console.log('Processing payload...', this.payload, val);

      this.isPayloadChanged(this.payload, val);
      this.payload.characteristic.description =
        `${
          val.characteristic.street !== '' &&
          val.characteristic.street !== undefined
            ? val.characteristic.street
            : ''
        }` +
        `${
          val.characteristic.unit !== '' &&
          val.characteristic.unit !== undefined
            ? ', ' + val.characteristic.unit
            : ''
        }` +
        `${
          val.characteristic.city !== '' &&
          val.characteristic.city !== undefined
            ? ', ' + val.characteristic.city
            : ''
        }` +
        `${
          val.characteristic.state !== '' &&
          val.characteristic.state !== undefined
            ? ', ' + val.characteristic.state
            : ''
        }` +
        `${
          val.characteristic.country !== '' &&
          val.characteristic.country !== undefined
            ? ', ' + val.characteristic.country
            : ''
        }` +
        `${
          val.characteristic.postalCode !== '' &&
          val.characteristic.postalCode !== undefined
            ? ', ' + val.characteristic.postalCode
            : ''
        }`;

      if (this.canEmit1) {
        // setTimeout(() => {
        console.log('Emit address data');
        // this.$emit('addressData', val);

        // }, 1000);
      }
    },
    getNestedLookupValue(obj, path) {
      return path.split('.').reduce((acc, key) => acc && acc[key], obj);
    },
    getFSA(postalCode, country) {
      const countryData = this.fsaCodeData.find(
        (entry) => entry.country.toLowerCase() === country.toLowerCase()
      );

      if (!countryData) {
        return ``;
      }

      const { fsaLength } = countryData;

      // Extract the FSA based on the defined length
      const fsa = postalCode
        .replace(/[^A-Za-z0-9]/g, '')
        .substring(0, fsaLength);

      console.log(this.getNestedLookupValue(this.obj, 'a.b.c'));
      this.payload.characteristic.fsa = fsa || ``;
      this.payload.characteristic.postalCodePrefix = fsa || ``;
      return fsa || ``;
    },
    loadGoogleMapsScript() {
      // const mapKey = process.env.VUE_APP_MAP_KEY;
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_KEY}&libraries=places`;
      script.async = true;
      document.head.appendChild(script);

      return new Promise((resolve) => {
        script.onload = () => {
          resolve();
        };
      });
    },
    async getGeocode(lat, lng) {
      console.log(lat, 'getGeocode running....', lng);
      if (this.isLeadAddressMounted == false) {
        await this.loadGoogleMapsScript().then(() => {
          // Initialize Google Maps components here
          const autocompleteService =
            new window.google.maps.places.AutocompleteService();
          console.log(
            'AutocompleteService Loaded from autoaddress:',
            autocompleteService
          );
        });
        const geocoder = new window.google.maps.Geocoder();
        const latLng = new window.google.maps.LatLng(lat, lng);

        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              console.log('results[0].....', results[0]);

              this.addressnew = results[0];
              this.APItype = 'Google maps API';
              this.addressData(results[0]);
              this.APItype = 'Canada post API';
            } else {
              console.log('No results found');
              this.addressnew = 'No results found';
            }
          } else {
            console.log('No results found else');
            this.addressnew = 'Geocoder failed due to: ' + status;
          }
        });
      } else {
        const geocoder = new window.google.maps.Geocoder();
        const latLng = new window.google.maps.LatLng(lat, lng);

        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              console.log('results[0].....', results[0]);

              this.addressnew = results[0];
              this.APItype = 'Google maps API';
              this.addressData(results[0]);
              this.APItype = 'Canada post API';
            } else {
              this.addressnew = 'No results found';
            }
          } else {
            this.addressnew = 'Geocoder failed due to: ' + status;
          }
        });
      }
    },
    canEmitTrigger() {
      this.canEmit1 = true;
    },
    async fetchGoogleMapsLocations(val) {
      console.log('Google Maps API Logic Triggered', val);

      // this.canEmit1 = true;
      // this.isAddressChoosed = false;
      // this.$emit('address-selected', this.isAddressChoosed);
      this.autocompleteService =
        new window.google.maps.places.AutocompleteService();
      this.sessionToken =
        new window.google.maps.places.AutocompleteSessionToken();

      const inputMapping = {
        street: this.payload.characteristic.street,
        city: this.payload.characteristic.city,
        state: this.payload.characteristic.state,
        country: this.payload.characteristic.country,
      };

      this.input = Object.values(inputMapping).find((v) => v === val) || '';

      if (!this.input.length) {
        console.log('Input is empty');
        return [];
      }

      try {
        if (this.payload.characteristic.street === val) {
          const response = await UPCAxiosInstance.get(
            `/address/suggestionlist?input=${this.input}`
          );
          this.locationData1 = response?.data;
        }

        this.autocompleteService.getPlacePredictions(
          {
            input: this.input,
            sessionToken: this.sessionToken,
            componentRestrictions: { country: this.userCountry.cca2 },
          },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const filteredPredictions = this.filterGooglePredictions(
                predictions,
                val
              );
              console.log('Filtered Predictions:', filteredPredictions);
            } else {
              console.log('No predictions available or status not OK', status);
            }
          }
        );
      } catch (error) {
        console.error('Error fetching autocomplete data:', error.message);
      }
    },

    async fetchCanadaPostLocations(val) {
      console.log('Canada Post API Logic Triggered', val);

      // this.canEmit1 = true;
      // this.isAddressChoosed = false;
      // this.$emit('address-selected', this.isAddressChoosed);

      const inputMapping = {
        street: this.payload.characteristic.street,
        city: this.payload.characteristic.city,
        state: this.payload.characteristic.state,
        country: this.payload.characteristic.country,
      };

      this.input = Object.values(inputMapping).find((v) => v === val) || '';

      if (!this.input.length) {
        console.log('Input is empty');
        return [];
      }

      try {
        const response = await axios.get(
          `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/find/v2.10/json3.ws?Key=${this.apiKey}&SearchTerm=${this.input}&Country=${this.userCountry.cca2}`
        );
        const result = response.data.Items.map((i) => ({
          ...i,
          description: `${i.Text}, ${i.Description}`,
        }));

        this.ogAutocompleteList = JSON.parse(JSON.stringify(result));
        let arr = result.filter((i) => i.Next === 'Retrieve');
        let normalizeString = (str) =>
          str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        if (this.statesArray.length > 0) {
          const filteredResults = arr.filter((item) =>
            this.statesArray.some(
              (subStr) =>
                normalizeString(item.Text)
                  .toLowerCase()
                  .includes(subStr.toLowerCase()) ||
                normalizeString(item.Description)
                  .toLowerCase()
                  .includes(subStr.toLowerCase())
            )
          );
          this.locationData1 = filteredResults;
        } else {
          this.locationData1 = arr;
        }
        console.log(
          this.locationData1,
          arr,
          normalizeString('Québec'),
          'sqData'
        );
      } catch (error) {
        console.error('Error fetching Canada Post data:', error.message);
      }
    },

    filterGooglePredictions(predictions, val) {
      const typeMapping = {
        city: 'locality',
        state: 'administrative_area_level_1',
        country: 'country',
      };

      const type = Object.keys(typeMapping).find(
        (key) => this.payload.characteristic[key] === val
      );

      const targetType = typeMapping[type];

      if (!targetType) {
        return [];
      }

      return predictions.filter((item) =>
        item.types.some((type) => type.toLowerCase() === targetType)
      );
    },

    getLocations(val) {
      if (this.APItype === 'Google maps API') {
        this.getLocationsDebounced =
          this.getLocationsDebounced ||
          debounce(this.fetchGoogleMapsLocations, 800);
        this.getLocationsDebounced(val);
      } else if (this.APItype === 'Canada post API') {
        this.getLocationsDebounced =
          this.getLocationsDebounced ||
          debounce(this.fetchCanadaPostLocations, 800);
        this.getLocationsDebounced(val);
      }
    },
    // async getLocations(val) {
    //   console.log('getLocations', val, this.APItype);

    //   if (this.APItype === 'Google maps API') {
    //     this.canEmit1 = true;

    //     this.isAddressChoosed = false;
    //     this.$emit('address-selected', this.isAddressChoosed);
    //     this.autocompleteService =
    //       new window.google.maps.places.AutocompleteService();
    //     this.sessionToken =
    //       new window.google.maps.places.AutocompleteSessionToken();
    //     console.log(
    //       'tokeeeeeeeeeeeeeen',
    //       this.autocompleteService,
    //       this.sessionToken
    //     );

    //     console.log('input', val);
    //     // const input ={}
    //     if (this.payload.characteristic.street === val) {
    //       this.input = this.payload.characteristic.street;
    //     } else if (this.payload.characteristic.city === val) {
    //       this.input = this.payload.characteristic.city;
    //     } else if (this.payload.characteristic.state === val) {
    //       this.input = this.payload.characteristic.state;
    //     } else {
    //       this.input = this.payload.characteristic.country;
    //     }
    //     let result = [];
    //     try {
    //       console.log(this.input);

    //       if (this.payload.characteristic.street === val) {
    //         const response = await UPCAxiosInstance.get(
    //           `/address/suggestionlist?input=${this.input}`
    //         );

    //         result = response?.data;
    //         this.locationData1 = result;
    //       }
    //       if (this.input.length > 0) {
    //         this.autocompleteService.getPlacePredictions(
    //           {
    //             input: this.input,
    //             sessionToken: this.sessionToken,
    //             componentRestriction: { country: this.userCountry.cca2 },
    //           },
    //           (predictions, status) => {
    //             if (
    //               status === window.google.maps.places.PlacesServiceStatus.OK
    //             ) {
    //               console.log('suggestion list:', predictions);
    //               if (this.payload.characteristic.city === val) {
    //                 const r = [];
    //                 predictions.forEach((item) => {
    //                   item?.types.forEach((itm) => {
    //                     if (itm.toLowerCase() === 'locality') {
    //                       r.push(item);
    //                     }
    //                     console.log('r', r);
    //                   });
    //                 });
    //                 this.locationData2 = r;

    //                 console.log('pendrive 22222222222');
    //               } else if (this.payload.characteristic.state === val) {
    //                 // this.input = this.payload.characteristic.state;
    //                 //   this.locationData3 = predictions.filter(prediction => {
    //                 //   return prediction.types.includes('state');
    //                 // });console.log(this.locationData3);
    //                 const r = [];
    //                 predictions.forEach((item) => {
    //                   item?.types.forEach((itm) => {
    //                     if (
    //                       itm.toLowerCase() === 'administrative_area_level_1'
    //                     ) {
    //                       r.push(item);
    //                     }
    //                     console.log('r', r);
    //                   });
    //                 });
    //                 this.locationData3 = r;

    //                 console.log('pendrive 333333333333');
    //               } else if (this.payload.characteristic.country === val) {
    //                 // this.input = this.payload.characteristic.country;
    //                 // this.locationData4 = predictions.filter(item =>{
    //                 //   console.log('item',item?.types);
    //                 //   // return 'item';
    //                 const r = [];
    //                 predictions.forEach((item) => {
    //                   item?.types.forEach((itm) => {
    //                     if (itm.toLowerCase() === 'country') {
    //                       r.push(item);
    //                     }
    //                     console.log('r', r);
    //                   });
    //                 });
    //                 this.locationData4 = r;
    //                 // });
    //                 console.log(this.locationData4);

    //                 console.log('pendrive 44444444444');
    //               }
    //             } else {
    //               console.log(
    //                 'No predictions available or status not OK',
    //                 status
    //               );
    //             }
    //           }
    //         );
    //       } else {
    //         console.log('Input is empty');
    //       }
    //       result = this.locationData;
    //       console.log(result);
    //     } catch (error) {
    //       console.error('Error fetching autocomplete data:', error.message);
    //     }
    //     return result;
    //   } else if (this.APItype == 'Canada post API') {
    //     this.canEmit1 = true;

    //     this.isAddressChoosed = false;
    //     this.$emit('address-selected', this.isAddressChoosed);
    //     if (this.payload.characteristic.street === val) {
    //       this.input = this.payload.characteristic.street;
    //     } else if (this.payload.characteristic.city === val) {
    //       this.input = this.payload.characteristic.city;
    //     } else if (this.payload.characteristic.state === val) {
    //       this.input = this.payload.characteristic.state;
    //     } else {
    //       this.input = this.payload.characteristic.country;
    //     }
    //     let result = [];
    //     try {
    //       if (this.input.length > 0) {
    //         let find = await axios.get(
    //           `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/find/v2.10/json3.ws?Key=${this.apiKey}&SearchTerm=${this.input}&Country=${this.userCountry.cca2}`
    //         );
    //         result = find.data.Items;
    //         result.forEach((i) => {
    //           return (i.description = i.Text + ', ' + i.Description);
    //         });
    //         this.ogAutocompleteList = JSON.parse((JSON.stringify(result)))
    //         result = result.filter((i)=> {return i.Next == 'Retrieve'})
    //         this.locationData1 = result;
    //         console.log(this.locationData1, 'candapost');
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // },
    extractNumberOfUnits(fullString, addressPart) {
      // Remove the address part from the full string
      const remainingPart = fullString.replace(addressPart, '').trim();

      // Use a regex to extract the number of addresses
      const match = remainingPart.match(/\d+/); // Looks for the first numeric value
      const numberOfAddresses = match ? match[0] : null;

      return {
        remainingPart, // Remaining string (e.g., " - 308 Addresses")
        numberOfAddresses, // Number of addresses (e.g., "308")
      };
    },
    async addressData(val) {
      if (this.APItype == 'Google maps API') {
        console.log('val', val);
        if (val) {
          if (val?.place_id) {
            let address_components = await UPCAxiosInstance.get(
              `/address/details?place_id=${val.place_id}`
            );
            this.$emit(
              'geopoint',
              address_components.data.result.geometry.location.lat,
              address_components.data.result.geometry.location.lng
            );
            console.log(
              address_components.data.result.geometry.location.lng,
              address_components,
              'emiting',
              address_components.data.result.name
            );
            this.$emit('mapname', address_components.data.result.name);
            this.$emit(
              'description',
              address_components.data.result.formatted_address
            );

            console.log(address_components, 'huii');
            let street = '';
            let postcode = '';
            let country = '';
            let unit = '';
            let city = '';
            let state_or_province = '';
            let state_code = '';
            for (const component of address_components.data.result
              .address_components) {
              const componentType = component.types[0];

              switch (componentType) {
                case 'street_number': {
                  street = `${component.long_name} ${street}`;
                  break;
                }
                case 'route': {
                  street += component.short_name;
                  break;
                }
                case 'postal_code': {
                  postcode = `${component.long_name}${postcode}`;
                  break;
                }
                case 'postal_code_suffix': {
                  postcode = `${postcode}-${component.long_name}`;
                  break;
                }
                case 'postal_code_prefix': {
                  postcode = `${component.long_name}${
                    postcode !== '' ? '-' + postcode : ''
                  }`;
                  break;
                }
                case 'country': {
                  country = `${component.long_name}`;
                  break;
                }
                case 'administrative_area_level_1': {
                  state_or_province = `${component.long_name}`;
                  state_code = `${component.short_name}`;
                  break;
                }
                case 'administrative_area_level_2': {
                  city = `${component.long_name}`;
                  break;
                }
                case 'administrative_area_level_3': {
                  city = `${city !== '' ? city + ', ' : ''}${
                    component.long_name
                  }`;
                  break;
                }
                case 'sublocality_level_1': {
                  city = `${component.long_name}`;
                  break;
                }
                case 'subpremise': {
                  unit = `${component.long_name}`;
                  break;
                }
                case 'state_code': {
                  unit = `${component.state_code}`;
                  break;
                }
                // Add more cases for other address components as needed
              }
            }
            console.log(
              street,
              postcode,
              unit,
              city,
              state_or_province,
              country,
              'huiii'
            );

            this.payload.characteristic.street = street;
            this.payload.characteristic.postalCode = postcode;
            this.payload.characteristic.unit = unit;
            this.payload.characteristic.city = city;
            this.payload.characteristic.state = state_or_province;
            this.payload.characteristic.stateOrProvince = state_or_province;
            this.payload.characteristic.stateCode = state_code;
            this.payload.characteristic.country = country;
            this.payload.characteristic.longitude =
              address_components.data.result.geometry.location.lng;
            this.payload.characteristic.latitude =
              address_components.data.result.geometry.location.lat;
            this.payload.characteristic.street = street;
            this.payload.characteristic.description =
              `${street !== '' && street !== undefined ? street : ''}` +
              `${unit !== '' && unit !== undefined ? ', ' + unit : ''}` +
              `${city !== '' && city !== undefined ? ', ' + city : ''}` +
              `${
                state_or_province !== '' && state_or_province !== undefined
                  ? ', ' + state_or_province
                  : ''
              }` +
              `${
                country !== '' && country !== undefined ? ', ' + country : ''
              }` +
              `${
                postcode !== '' && postcode !== undefined ? ', ' + postcode : ''
              }`;
          }
        }
      } else if (this.APItype == 'Canada post API') {
        // this.canEmit1 = true;
        // this.isAddressChoosed = true;
        // this.$emit('address-selected', this.isAddressChoosed);
        console.log('candapost', val);
        let nouF1 = this.ogAutocompleteList.filter((i) => {
          return i.Highlight == val.Highlight;
        });
        nouF1 = JSON.parse(JSON.stringify(nouF1));
        let nouF2 = nouF1.filter((i) => {
          return i.Description.includes(val.Description);
        });
        let nouF3 = nouF2.filter((i) => {
          return i.Description !== val.Description;
        });
        // console.log(this.extractNumberOfUnits(nouF3[0].Description,val.Description), 'nou');
        if (nouF3.length > 0) {
          console.log('nouF3.length if', nouF3.length);
          this.payload.characteristic.numberOfUnits = this.extractNumberOfUnits(
            nouF3[0].Description,
            val.Description
          ).numberOfAddresses;
        } else {
          console.log('nouF3.length else', nouF3);
          this.payload.characteristic.numberOfUnits = '';
        }
        if (val.Next == 'Retrieve') {
          let retrieve = await axios.get(
            `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3.ws?Key=${this.apiKey}&Id=${val.Id}`
          );
          let address_components = retrieve.data.Items[0];
          let latLong = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=1${address_components.Label}&key=${process.env.VUE_APP_GOOGLE_MAP_KEY}`
          );
          console.log(
            'candapost',
            address_components,
            latLong,
            retrieve.data.Items[0]
          );
          let address_components2 = latLong.data.results[0]?.geometry?.location;
          let street = address_components.Line1;
          let postcode = address_components.PostalCode;
          let country = address_components.CountryName;
          let unit = address_components.BuildingNumber;
          let city = address_components.City;
          let state_or_province = address_components.ProvinceName;
          let state_code = address_components.ProvinceCode;

          console.log(street, postcode, unit, city, state_or_province, country);

          this.locationData1 = [];
          this.payload.characteristic.postalCode = postcode;
          this.payload.characteristic.unit = unit;
          this.payload.characteristic.city = city;
          this.payload.characteristic.state = state_or_province;
          this.payload.characteristic.stateOrProvince = state_or_province;
          this.payload.characteristic.stateCode = state_code;
          this.payload.characteristic.country = country;
          this.payload.characteristic.longitude = address_components2?.lng;
          this.payload.characteristic.latitude = address_components2?.lat;
          this.payload.characteristic.street = street;
          this.payload.characteristic.description =
            `${street !== '' && street !== undefined ? street : ''}` +
            `${unit !== '' && unit !== undefined ? ', ' + unit : ''}` +
            `${city !== '' && city !== undefined ? ', ' + city : ''}` +
            `${
              state_or_province !== '' && state_or_province !== undefined
                ? ', ' + state_or_province
                : ''
            }` +
            `${country !== '' && country !== undefined ? ', ' + country : ''}` +
            `${
              postcode !== '' && postcode !== undefined ? ', ' + postcode : ''
            }`;
        }
      }
      // setTimeout(() => {
      console.log(
        this.payload.characteristic.postalCodePrefix,
        (this.payload.characteristic.fsa =
          this.payload.characteristic.postalCodePrefix),
        'huyt'
      );

      this.canEmit1 = true;
      this.$emit('addressData', JSON.parse(JSON.stringify(this.payload)));
      this.isAddressChoosed = true;
      this.$emit('address-selected', this.isAddressChoosed);
      // }, 1000);
    },
  },
};
</script>
