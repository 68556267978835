<template>
  <div class="site-info">
    <h1>Site Information</h1>
    <div class="site-grid-header">
      <div>Name</div>
      <div>Address</div>
      <div>Network ID</div>
    </div>
    <div v-for="site in sites" :key="site.id" class="site-grid-row">
      <div>{{ site.name }}</div>
      <div>
        {{ site.street }}, {{ site.city }}, {{ site.province }},
        {{ site.country }}, {{ site.postalCode }}
      </div>
      <div>
        <input
          v-model="site.networkId"
          type="text"
          :placeholder="'Enter Network ID for ' + site.name"
        />
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="action-buttons">
      <!-- <button @click="approveAllSites">Approve All</button> -->
      <hlx-button class="primary-error sm" @click="openRejectModal"
        >Reject</hlx-button
      >
      <!-- <button @click="openRejectModal">Reject All</button> -->
      <hlx-button
        class="primary sm"
        :disabled="!isAllSitesFilled"
        @click="handleApprove"
        >Approve</hlx-button
      >
    </div>

    <!-- Rejection Modal -->
    <div v-if="isModalOpen" class="modal-overlay">
      <div class="modal">
        <h2>Rejection Reason</h2>
        <textarea
          v-model="rejectionReason"
          placeholder="Enter rejection reason"
        ></textarea>
        <div class="modal-actions">
          <button @click="handleRejection">Submit</button>
          <button @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BPMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";

export default {
  data() {
    return {
      relatedParty: [],
      relatedPartyD: [],
      isModalOpen: false,
      sites: [],
      payload: null,
      rejectionReason: "",
    };
  },
  computed: {
    filteredSites1() {
      return [
        ...JSON.parse(JSON.stringify(this.relatedPartyD)).filter(
          (party) => party["@type"] === "site"
        ),
      ];
    },
    isAllSitesFilled() {
      return this.sites.every((item) => item.networkId);
    },
  },
  async mounted() {
    // Check if access token is valid using API call
    if (!this.$route.query.access) {
      alert("Unauthorized access. Please login to view this page.");
      return;
    }
    const isValidTokenResponse = await MLTAxiosInstance.get(
      "/tokenblocker/v1/isValidToken?token=" +
        this.$route.query.access +
        "&isNeedToVerify=true"
    );
    if (!isValidTokenResponse.data) {
      alert("Unauthorized access. Please login to view this page.");
      return;
    }

    const { isValid, instanceId, workflowId, taskId, module, dataId } =
      isValidTokenResponse.data;
    this.instanceId = instanceId;
    this.workflowId = workflowId;
    this.taskId = taskId;
    this.module = module;
    this.dataId = dataId;

    if (!isValid) {
      alert("Unauthorized access. Please login to view this page.");
      return;
    }
    // Fetch by module and id
    if (!instanceId || !workflowId || !taskId || !module || !dataId) {
      alert("Invalid request. Please provide module and id to view this page.");
      return;
    }
    const moduleResponse = await UPCAxiosInstance.get(
      "/" + module + "/" + dataId + "?isAggregated=true",
      {
        headers: {
          Authorization: "Bearer " + this.$route.query.access,
        },
      }
    );
    // If no data found, show error message as something went wrong
    if (!moduleResponse.data) {
      alert("No data found. Something went wrong. Please try again later.");
      return;
    }
    if (Array.isArray(moduleResponse.data)) {
      this.payload = moduleResponse.data[0];
    } else {
      this.payload = moduleResponse.data;
    }
    // If data found, update the relatedParty data
    this.relatedPartyD = this.payload.relatedParty || [];
    this.sites = this.relatedPartyD.filter(
      (party) => party["@type"] === "site"
    );
    this.relatedParty = this.payload.relatedParty || [];
  },
  methods: {
    async handleApprove() {
      // Fetch workflows based on module and triggerPoint and currentTask
      // const processInstanceFilterPayload = {
      //   type: "filter",
      //   module: "processinstance",
      //   collection: "",
      //   isPaginated: true,
      //   paginatedQuery: {
      //     rowsPerPage: 10,
      //     currentPage: 1,
      //   },
      //   isNeedToBeSort: false,
      //   sortFieldAndOrder: {},
      //   limit: 10,
      //   filterQuery: {
      //     module: "salesLead",
      //     "currentTask.task": "networkManagerApproval-UserTask",
      //     "currentTask.triggerPoint.from": "HalleyxBSS",
      //     "currentTask.triggerPoint.module": "LEAD_NETWORK_APPROVAL_FORM",
      //     "currentTask.triggerPoint.actionPoint": "approveOrRejectButton",
      //   },
      //   searchQuery: "",
      // };
      // const processInstanceResponse = await BPMNAxiosInstance.post(
      //   "/utils/filterData",
      //   processInstanceFilterPayload,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + this.$route.query.access,
      //     },
      //   }
      // );
      this.payload.relatedParty = this.payload.relatedParty.map((party) => {
        if (party["@type"] === "site") {
          party.networkId = this.sites.find(
            (site) => site.id === party.id
          ).networkId;
        }
        return party;
      });
      this.payload.status = "Network approved";
      // If no data found, show error message as something went wrong
      // if (
      //   !processInstanceResponse.data ||
      //   (processInstanceResponse.data &&
      //     processInstanceResponse.data.data.length === 0)
      // ) {
      //   alert("No data found. Something went wrong. Please try again later.");
      //   return;
      // }
      // if (processInstanceResponse.data.data.length > 0) {
      //   //    Call complete user task API with payload to complete the task
      //   let completedProcessInstance = await processInstanceResponse.data.data.map(async (processInstance) => {
      //       const completeUserTaskPayload = {
      //         instanceId: processInstance.instanceId,
      //         taskId: processInstance.currentTask.task,
      //         workflowId: processInstance.workflowId,
      //         context:{
      //           [this.$route.query.module]: this.payload
      //         }
      //       };
      //         const completeUserTaskResponse = await BPMNAxiosInstance.post(
      //       "/engine/completeTask",
      //       completeUserTaskPayload,
      //       {
      //           headers: {
      //               Authorization: "Bearer " + this.$route.query.access,
      //           }
      //       }
      //   );
      //       return completeUserTaskResponse.data;
      //     });
      //     completedProcessInstance = await Promise.all(completedProcessInstance);

      //   if (completedProcessInstance) {
      //       alert("All sites approved!");
      //   } else {
      //       alert("Something went wrong. Please try again later.");
      //   }
      // } else {
      //   alert("No data found. Something went wrong. Please try again later.");
      // }
      const completeUserTaskPayload = {
        instanceId: this.instanceId,
        taskId: this.taskId,
        workflowId: this.workflowId,
        context: {
          [this.module]: this.payload,
        },
      };
      // Call complete user task API with payload to complete the task
      // Call complete user task API with payload to complete the task
      const completeUserTaskResponse = await BPMNAxiosInstance.post(
        "/engine/v1/completeTask",
        completeUserTaskPayload,
        {
          headers: {
            Authorization: "Bearer " + this.$route.query.access,
          },
        }
      );
      if (completeUserTaskResponse.data) {
        alert("All sites approved!");
      } else {
        alert("Something went wrong. Please try again later.");
      }
    },
    async handleRejection() {
      this.isModalOpen = false;
      // Fetch workflows based on module and triggerPoint and currentTask
      // const processInstanceFilterPayload = {
      //   type: "filter",
      //   module: "processinstance",
      //   collection: "",
      //   isPaginated: true,
      //   paginatedQuery: {
      //     rowsPerPage: 10,
      //     currentPage: 1,
      //   },
      //   isNeedToBeSort: false,
      //   sortFieldAndOrder: {},
      //   limit: 10,
      //   filterQuery: {
      //     module: "salesLead",
      //     "currentTask.task": "networkManagerApproval-UserTask",
      //     "currentTask.triggerPoint.from": "HalleyxBSS",
      //     "currentTask.triggerPoint.module": "LEAD_NETWORK_APPROVAL_FORM",
      //     "currentTask.triggerPoint.actionPoint": "approveOrRejectButton",
      //   },
      //   searchQuery: "",
      // };
      // const processInstanceResponse = await BPMNAxiosInstance.post(
      //   "/utils/filterData",
      //   processInstanceFilterPayload,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + this.$route.query.access,
      //     },
      //   }
      // );
      // this.payload.relatedParty = this.payload.relatedParty.map((party) => {
      //   if (party["@type"] === "site") {
      //     party.networkId = this.sites.find(
      //       (site) => site.id === party.id
      //     ).networkId;
      //   }
      //   return party;
      // });
      this.payload.status = "Network rejected";
      this.payload.rejectReason = this.rejectionReason;
      // If no data found, show error message as something went wrong
      // if (
      //   !processInstanceResponse.data ||
      //   (processInstanceResponse.data &&
      //     processInstanceResponse.data.data.length === 0)
      // ) {
      //   alert("No data found. Something went wrong. Please try again later.");
      //   return;
      // }
      // if (processInstanceResponse.data.data.length > 0) {
      //   //    Call complete user task API with payload to complete the task
      //   let completedProcessInstance = await processInstanceResponse.data.data.map(async (processInstance) => {
      //       const completeUserTaskPayload = {
      //         instanceId: processInstance.instanceId,
      //         taskId: processInstance.currentTask.task,
      //         workflowId: processInstance.workflowId,
      //         context:{
      //           [this.$route.query.module]: this.payload
      //         }
      //       };
      //         const completeUserTaskResponse = await BPMNAxiosInstance.post(
      //       "/engine/completeTask",
      //       completeUserTaskPayload,
      //       {
      //           headers: {
      //               Authorization: "Bearer " + this.$route.query.access,
      //           }
      //       }
      //   );
      //       return completeUserTaskResponse.data;
      //     });
      //     completedProcessInstance = await Promise.all(completedProcessInstance);

      //   if (completedProcessInstance) {
      //       alert("Sites approval rejected!");
      //   } else {
      //       alert("Something went wrong. Please try again later.");
      //   }
      // } else {
      //   alert("No data found. Something went wrong. Please try again later.");
      // }
      const completeUserTaskPayload = {
        instanceId: this.instanceId,
        taskId: this.taskId,
        workflowId: this.workflowId,
        context: {
          [this.module]: this.payload,
        },
      };

      // Call complete user task API with payload to complete the task
      const completeUserTaskResponse = await BPMNAxiosInstance.post(
        "/engine/v1/completeTask",
        completeUserTaskPayload,
        {
          headers: {
            Authorization: "Bearer " + this.$route.query.access,
          },
        }
      );
      if (completeUserTaskResponse.data) {
        alert("Sites approval rejected!");
      } else {
        alert("Something went wrong. Please try again later.");
      }
    },
    approveAllSites() {
      if (this.filteredSites.some((site) => !site.networkId)) {
        alert("Please fill in all Network IDs before approving.");
        return;
      }
      alert("All sites approved!");
      // Implement API call to save all approvals
    },
    openRejectModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.rejectionReason = "";
    },
    rejectAllSites() {
      if (!this.rejectionReason) {
        alert("Please provide a rejection reason.");
        return;
      }
      alert(`All sites rejected with reason: ${this.rejectionReason}`);
      // Implement API call to save rejections
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.site-info {
  padding: 20px;
}
h1 {
  text-align: center;
  margin-bottom: 20px;
}
.site-grid-header,
.site-grid-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
.site-grid-header {
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.site-grid-row:nth-child(odd) {
  background: #f9f9f9;
}
.site-grid-row input {
  width: 100%;
  padding: 5px;
}
.action-buttons {
  margin-top: 20px;
  text-align: center;
}
.action-buttons button {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
.action-buttons button:first-of-type {
  background-color: #28a745;
  color: white;
}
.action-buttons button:last-of-type {
  background-color: #dc3545;
  color: white;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
}
.modal textarea {
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
}
.modal-actions button {
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}
.modal-actions button:first-of-type {
  background-color: #007bff;
  color: white;
}
.modal-actions button:last-of-type {
  background-color: #6c757d;
  color: white;
}
</style>
