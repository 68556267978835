<template>
  <div class="nonresponsive-leftpannel">
    <div class="leftpannal-sidebar">
      <div class="sidebar-v2-partitions-holder">
        <div v-for="part in filteredSideBarData" :key="part" class="sidebar-v2-partitions">
          <div v-for="p in part" :key="p">
            <div
              v-if="p.view"
              class="sidebar-v2-partitions-items hover-leftpannel"
              :class="[p.active ? 'sidebar-v2-active-main' : '', p.disabled ? 'disabled-item' : '']"
              @click.stop="!p.disabled && newGotoFunc(p)"
            >
              <span class="leftpannel-box" :style="{ cursor: p.disabled ? 'not-allowed' : 'pointer' }">
                <span class="leftpannel-icone" :style="{ cursor: p.disabled ? 'not-allowed' : 'pointer' }">
                  <i :class="p.icon" :style="{ color: p.active ? '#54bd95' : '' }"></i>
                </span>
                <span :style="{ color: p.active ? '#fff' : '', cursor: p.disabled ? 'not-allowed' : 'pointer' }">
                  {{ matchFromStatic(p?.customName || p?.name) }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <span class="responsive-span-sm">
    <i
      class="icon-menu-regular responsive-menuicon-sm"
      @click="show_leftpannal_responsive = true"
    ></i>
  </span>
  <hlx-drawer
    :title="'Filter panel'"
    :show="show_leftpannal_responsive"
    :width="300"
    position="left"
    :footer="false"
    :click-outside-to-close="true"
    @close="show_leftpannal_responsive = false"
  >
    <template #body>
      <div style="margin-top: 19px; margin-left: 80px" class="leftpannal-sidebar">
        <div class="sidebar-v2-partitions-holder">
          <div v-for="part in filteredSideBarData" :key="part" class="sidebar-v2-partitions">
            <div v-for="p in part" :key="p">
              <div
                v-if="p.view"
                class="sidebar-v2-partitions-items hover-leftpannel"
                :class="[p.active ? 'sidebar-v2-active-main' : '', p.disabled ? 'disabled-item' : '']"
                @click.stop="!p.disabled && newGotoFunc(p)"
              >
                <span class="leftpannel-box" :style="{ cursor: p.disabled ? 'not-allowed' : 'pointer' }">
                  <span class="leftpannel-icone" :style="{ cursor: p.disabled ? 'not-allowed' : 'pointer' }">
                    <i :class="p.icon" :style="{ color: p.active ? '#54bd95' : '' }"></i>
                  </span>
                  <span :style="{ color: p.active ? '#fff' : '', cursor: p.disabled ? 'not-allowed' : 'pointer' }">
                    {{ matchFromStatic(p?.customName || p?.name) }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </hlx-drawer>
</template>

<script>
export default {
  props: {
    sideBarData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["value-name"],
  data() {
    return {
      selectedButton: "",
      show_leftpannal_responsive: false,
    };
  },
  computed: {
    filteredSideBarData() {
      return {
        main: this.sideBarData.main && Array.isArray(this.sideBarData.main)
          ? this.sideBarData.main.map(item => ({
              ...item,
              disabled: item.disabled || false, // Default to false if `disabled` is missing
            })).filter(item => item.view)
          : [],
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      const storedButton = this.$cookies.get("selectedButton");
      this.$router.beforeEach((to, from, next) => {
        this.clearSelectedButton();
        next();
      });
      this.checkAndProcessSideBarData(storedButton);
    });
  },
  methods: {
    clearSelectedButton() {
      this.selectedButton = "";
      this.$cookies.set("selectedButton", "");
    },
    checkAndProcessSideBarData(storedButton) {
      const processData = () => {
        if (this.sideBarData?.main?.length > 0) {
          const isValidButton = this.sideBarData.main.some(
            item => item.name === storedButton && item.view
          );

          const buttonToSelect = isValidButton
            ? storedButton
            : this.sideBarData.main.find(item => item.view)?.name;

          this.processSideBarData(buttonToSelect);
        } else {
          setTimeout(processData, 100);
        }
      };

      processData();
    },
    processSideBarData(selectedButton) {
      if (selectedButton) {
        this.selectedButton = selectedButton;
        this.$emit(
          "value-name",
          this.selectedButton,
          this.sideBarData.main.find(item => item.name === this.selectedButton) || {}
        );
        this.sideBarData.main.forEach(item => {
          item.active = item.name === this.selectedButton;
        });
      }
    },
    newGotoFunc(navItem) {
      this.selectedButton = navItem.name;
      this.$cookies.set("selectedButton", navItem.name);
      this.$emit("value-name", this.selectedButton, navItem);
      this.show_leftpannal_responsive = false;
      this.sideBarData.main.forEach(item => {
        item.active = item.name === this.selectedButton;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.leftpannal-sidebar {
  .sidebar-v2-active-main {
    background-color: #54bd95;
    border-radius: 5px;

    &:hover {
      background: #54bd95 !important;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .disabled-item {
    pointer-events: none;
    opacity: 0.6;
  }

  .hover-leftpannel:hover {
    border-radius: 5px;
    background-color: #a8dec9;
  }

  .sidebar-v2-partitions {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .leftpannel-box {
    display: flex;
    gap: 8px;
    justify-content: left;
    align-items: center;
    padding: 8px;
    font-size: 14px;

    .leftpannel-icone {
      width: 20px;
      height: 20px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px 2px 2px 2px;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
    }
  }
}
.nonresponsive-leftpannel {
  display: block !important;
}
@media screen and (max-width: 768px) {
  .nonresponsive-leftpannel {
    display: none !important;
  }
  .responsive-span-sm {
    display: block;
  }
}
</style>
