<template>
  <div class="dmnlayout">
    <div class="left-container">
      <!-- v-if="!['/', '/signup'].includes($route.fullPath)" -->
      <div
        v-if="$store.state.isSidebar && $route.query.request_for == undefined"
        class="sidebar-v2"
      >
        <span class="logo">
          <img
            src="./assets/images/logo.png"
            alt=""
            srcset=""
          />
        </span>
        <div class="sidebar-v2-partitions-holder">
          <div
            v-for="(part, partIndex) in sideBarData"
            :key="partIndex"
            class="sidebar-v2-partitions"
          >
            <div
              v-for="(p, pIndex) in part"
              :key="pIndex"
            >
              <div
                v-if="p.view"
                :ref="`${partIndex}-${pIndex}`"
                class="sidebar-v2-partitions-items"
                :class="p.active ? 'sidebar-v2-active-main' : ''"
                @click.stop="newGotoFunc($event, p)"
                @mouseover.stop="hoverChildItem($event)"
                @mouseleave.stop="blurChildItem($event)"
              >
                <span
                  v-if="p?.child !== undefined"
                  class="icon-container"
                  style="
                    width: 100%;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.8rem 0.65rem;
                  "
                >
                  <i :class="p.icon"></i>
                </span>
                <span
                  v-else
                  style="
                    width: 100%;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.8rem 0.65rem;
                  "
                  :data-tooltip="p.name"
                  data-tooltip-location="right"
                >
                  <i :class="p.icon"></i>
                </span>
                <i
                  v-if="p?.child !== undefined"
                  class="icon-more-vertical-regular"
                  style="position: absolute; right: 6px; font-size: 16px"
                  :style="
                    p.name == 'More' ? { color: 'transparent !important' } : ''
                  "
                ></i>
                <div
                  v-if="p?.child !== undefined"
                  id="first-child-container"
                >
                  <div class="sidebar-v2-child-container">
                    <span
                      v-for="(child, index) in p?.child"
                      :key="index"
                    >
                      <span
                        v-if="child.view"
                        :id="'sidebar-v2-active-child-id-' + index"
                        class="sidebar-v2-child-items"
                        :class="child.active ? 'sidebar-v2-active-child' : ''"
                        :style="
                          child.title
                            ? {
                                'font-family': 'QuickSand',
                                'font-weight': 'bold',
                                'border-bottom': '1px solid #a4a4a4',
                                'border-top-left-radius': '5px',
                                'padding-left': '5px',
                              }
                            : {
                                'border-bottom-left-radius':
                                  index === p?.child.length - 1 ? '5px' : '',
                                'border-bottom-right-radius':
                                  index === p?.child.length - 1 ? '5px' : '',
                              }
                        "
                        style="justify-content: space-between"
                        @click.stop="newGotoFunc($event, child, 'child')"
                        @mouseover.stop="hoverSubChildItem($event)"
                        @mouseleave.stop="blurSubChildItem($event)"
                      >
                        <span
                          style="display: flex; align-items: center; gap: 8px"
                        >
                          <i
                            :class="child.icon"
                            style="font-size: 22px"
                          ></i>
                          {{ matchFromStatic(child?.name) }}</span
                        >
                        <i
                          v-if="child.child !== undefined"
                          class="icon-angle-right-regular"
                          style="position: relative; top: 1px"
                        ></i>
                        <div
                          v-if="child.child !== undefined"
                          id="second-child-container"
                          class="sidebar-v2-child-container"
                          style="left: 240px"
                        >
                          <span
                            v-for="(subchild, subchildindex) in child.child"
                            :key="subchildindex"
                          >
                            <span
                              v-if="subchild.view"
                              :id="
                                'sidebar-v2-active-child-id-' + subchildindex
                              "
                              class="sidebar-v2-child-items"
                              :class="
                                subchild.active ? 'sidebar-v2-active-child' : ''
                              "
                              :style="
                                subchild.title
                                  ? {
                                      'font-family': 'QuickSand',
                                      'font-weight': 'bold',
                                      'border-bottom': '1px solid #a4a4a4',
                                      'border-top-left-radius': '5px',
                                      'padding-left': '5px',
                                      display: 'none',
                                    }
                                  : {
                                      'border-bottom-right-radius':
                                        child.child.length === 1 ? '5px' : '',
                                      'border-top-left-radius':
                                        subchildindex === 0 ||
                                        subchildindex === 1
                                          ? '5px'
                                          : '',
                                      'border-top-right-radius':
                                        subchildindex === 0 ||
                                        subchildindex === 1
                                          ? '5px'
                                          : '',
                                      'border-bottom-left-radius':
                                        subchildindex === child.child.length - 1
                                          ? '5px'
                                          : '',
                                      'border-bottom-right-radius':
                                        subchildindex === child.child.length - 1
                                          ? '5px'
                                          : '',
                                    }
                              "
                              @click.stop="
                                newGotoFunc($event, subchild, 'subchild')
                              "
                            >
                              <span
                                style="
                                  display: flex;
                                  align-items: center;
                                  gap: 8px;
                                "
                              >
                                <i
                                  :class="subchild.icon"
                                  style="font-size: 22px"
                                ></i>
                                {{ matchFromStatic(subchild.name) }}</span
                              >
                            </span>
                          </span>
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <sidebar
        v-if="$store.state.isSidebar"
        :nav-items="$store.getters.sidebarData"
        :lang="'en-US'"
        collapse
        collapsible
        @nav-to="navTo"
      ></sidebar> -->
    </div>
    <div class="right-main-container">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
// import sidebar from './components/sidebarComponent.vue';
// import LZString from 'lz-string';
import { MLTAxiosInstance, UPCAxiosInstance } from './config/axiosConfig';
import axios from 'axios';
export default {
  // components: { sidebar },
  data() {
    return {
      
      count: 0,
      stateslist: [],
      isLoading: false,
      mapCenter: { lat: 65.9678315, lng: -101.9522398 },
      polygonPaths: [],
      routeChanged: false,
      isAppReloaded: false,
      token: localStorage.token,
      viewPermission: [],
      editPermission: [],
      sideBarData:  {
                "main": [
                    {
                        "id": 1,
                        "name": "Unified product catalog",
                        "label": "UPC",
                        "icon": "icon-notebook-regular",
                        "link": "/UPC/dashboard",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "module",
                        "customName": "UPC",
                        "description": "Go to market faster with a single catalog for products, services, pricing, rules, bundles, add-ons, promotions and more.",
                        "child": [
                            {
                                "view": true,
                                "title": true,
                                "name": "Unified product catalog",
                                "label": "unified_product_catalog",
                                "link": "/UPC/dashboard",
                                "customName": "Unified product catalog",
                                "description": "Centralized catalog for products offers.",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 1.1,
                                "name": "Category",
                                "label": "category",
                                "link": "/PCM/categorytable",
                                "active": false,
                                "view": false,
                                "icon": "icon-microsoft-regular",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "section",
                                "customName": "Category",
                                "description": "Aligning products, trends, and consumer expectations seamlessly.",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 1.2,
                                "name": "Product catalogue",
                                "label": "product_catalogue",
                                "link": "/UPC/product-catalog-table",
                                "active": false,
                                "view": true,
                                "icon": "icon-box-regular",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "section",
                                "customName": "Product catalogue",
                                "child": [
                                    {
                                        "id": 1.201,
                                        "title": true,
                                        "name": "Offers",
                                        "label": "offers",
                                        "link": "/UPC/product-catalog-table/offering",
                                        "active": false,
                                        "view": true,
                                        "customName": "Offers",
                                        "icon": "icon-package-star-regular",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "id": 1.21,
                                        "name": "Offers",
                                        "label": "offers",
                                        "link": "/UPC/product-catalog-table/offering",
                                        "active": false,
                                        "view": true,
                                        "icon": "icon-package-star-regular",
                                        "customFieldModule": "productoffering",
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "subSection",
                                        "customName": "Offers",
                                        "sections": [
                                            {
                                                "name": "Overview",
                                                "value": "overview",
                                                "icon": "icon-presentation-regular",
                                                "view": true,
                                                "active": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Overview",
                                                "addDisabled": true,
                                                "editDisabled": false,
                                                "deleteDisabled": true,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Pricing",
                                                "value": "pricing",
                                                "icon": "icon-dollar-regular",
                                                "view": true,
                                                "active": false,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Pricing",
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Media",
                                                "value": "media",
                                                "icon": "icon-money-bill-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Media",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Discounts",
                                                "value": "discounts",
                                                "icon": "icon-badge-discount-alt-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Discounts",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Additional charges",
                                                "value": "additional_charges",
                                                "icon": "icon-money-bill-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Additional charges",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Recommended products",
                                                "value": "recommended_products",
                                                "icon": "icon-inbox-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Recommended products",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Available regions",
                                                "value": "available_regions",
                                                "icon": "icon-location-pin-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Available regions",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Suppliers",
                                                "value": "suppliers",
                                                "icon": "icon-boxes-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Suppliers",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Orders",
                                                "value": "orders",
                                                "icon": "icon-menu-left-square-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Orders",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Documents",
                                                "value": "documents",
                                                "icon": "icon-paperclip-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Documents",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Notes",
                                                "value": "notes",
                                                "icon": "icon-menu-left-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Notes",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Activity log",
                                                "value": "activity_log",
                                                "icon": "icon-time-forward-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Activity log",
                                                "active": false,
                                                "addDisabled": true,
                                                "editDisabled": true,
                                                "deleteDisabled": true,
                                                "viewDisabled": false,
                                                "allDisabled": true
                                            }
                                        ],
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "id": 1.22,
                                        "name": "Assistant",
                                        "label": "assistant",
                                        "link": "/UPC/product-catalog-table/offering/assistant",
                                        "active": false,
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "subSection",
                                        "customName": "Assistant",
                                        "icon": "icon-sparkle-regular",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "id": 1.23,
                                        "name": "Specification",
                                        "label": "specification",
                                        "link": "/UPC/product-catalog-table/specification",
                                        "active": false,
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "subSection",
                                        "customName": "Specification",
                                        "icon": "icon-server-regular",
                                        "sections": [
                                            {
                                                "name": "Overview",
                                                "value": "overview",
                                                "icon": "icon-presentation-regular",
                                                "view": true,
                                                "active": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Overview",
                                                "addDisabled": true,
                                                "editDisabled": false,
                                                "deleteDisabled": true,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Features",
                                                "value": "features",
                                                "icon": "icon-dollar-regular",
                                                "view": true,
                                                "active": false,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Features",
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Service and resource",
                                                "value": "service_and_resource",
                                                "icon": "icon-money-bill-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Service and resource",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Documents",
                                                "value": "documents",
                                                "icon": "icon-paperclip-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Documents",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Notes",
                                                "value": "notes",
                                                "icon": "icon-menu-left-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Notes",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Activity log",
                                                "value": "activity_log",
                                                "icon": "icon-time-forward-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Activity log",
                                                "active": false,
                                                "addDisabled": true,
                                                "editDisabled": true,
                                                "deleteDisabled": true,
                                                "viewDisabled": false,
                                                "allDisabled": true
                                            }
                                        ],
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "id": 1.24,
                                        "name": "Additional charges",
                                        "label": "additional_charges",
                                        "link": "/UPC/product-catalog-table/additional-charges",
                                        "active": false,
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Additional charges",
                                        "icon": "icon-credit-card-plus-regular",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "id": 1.25,
                                        "name": "Discounts",
                                        "label": "discounts",
                                        "link": "/UPC/product-catalog-table/product-discount",
                                        "active": false,
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Discounts",
                                        "icon": "icon-badge-discount-alt-regular",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 1.3,
                                "name": "Service catalogue",
                                "label": "service_catalogue",
                                "link": "/UPC/product-catalog-table/service",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Service catalogue",
                                "icon": "icon-cloud-regular",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Features",
                                        "value": "features",
                                        "icon": "icon-dollar-regular",
                                        "view": true,
                                        "active": false,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Features",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Service and resource",
                                        "value": "service_and_resource",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Service and resource",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Documents",
                                        "value": "documents",
                                        "icon": "icon-paperclip-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Documents",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Notes",
                                        "value": "notes",
                                        "icon": "icon-menu-left-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Notes",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-time-forward-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 1.4,
                                "name": "Resource catalogue",
                                "label": "resource_catalogue",
                                "link": "/UPC/product-catalog-table/resource",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Resource catalogue",
                                "icon": "icon-cpu-regular",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Features",
                                        "value": "features",
                                        "icon": "icon-dollar-regular",
                                        "view": true,
                                        "active": false,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Features",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Service and resource",
                                        "value": "service_and_resource",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Service and resource",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Documents",
                                        "value": "documents",
                                        "icon": "icon-paperclip-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Documents",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Notes",
                                        "value": "notes",
                                        "icon": "icon-menu-left-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Notes",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-time-forward-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 2,
                        "name": "Customer management",
                        "label": "customer_management",
                        "icon": "icon-user-settings-regular",
                        "link": "/CM/dashboard",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "CM",
                        "description": "Keep tabs on your ISP's performance with insights from the past 30 days, all on one dashboard.",
                        "child": [
                            {
                                "view": true,
                                "title": true,
                                "name": "Customer management",
                                "label": "customer_management",
                                "customName": "Customer management",
                                "link": "/CM/dashboard",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 10.1,
                                "name": "Lead",
                                "label": "lead",
                                "link": "/MDU/lead",
                                "icon": "icon-user-star-regular",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "description": "Potential customers interested in your products or services",
                                "all": false,
                                "level": "section",
                                "customName": "Lead",
                                "customFieldModule": "saleslead",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": true
                                    },
                                    {
                                        "name": "Contacts",
                                        "value": "contacts",
                                        "icon": "icon-user-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Contacts",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": true
                                    },
                                    {
                                        "name": "Sites",
                                        "value": "sites",
                                        "icon": "icon-buildings-alt-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Sites",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": true
                                    },
                                    {
                                        "name": "Notes",
                                        "value": "notes",
                                        "icon": "icon-note-list-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Notes",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Documents",
                                        "value": "documents",
                                        "icon": "icon-paperclip-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Documents",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-time-forward-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 2.1,
                                "name": "Accounts",
                                "label": "accounts",
                                "link": "/CM/accounts",
                                "icon": "icon-buildings-alt-regular",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Accounts",
                                "customFieldModule": "accounts",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Hierarchy",
                                        "value": "Hierarchy",
                                        "icon": "icon-dollar-regular",
                                        "view": true,
                                        "active": false,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Hierarchy",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Sites",
                                        "value": "sites",
                                        "icon": "icon-buildings-alt-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Sites",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Location",
                                        "value": "location",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Location",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Contacts",
                                        "value": "contacts",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Contacts",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Interaction",
                                        "value": "interaction",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Interaction",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Products",
                                        "value": "products",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Products",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Contracts",
                                        "value": "contracts",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Contracts",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Quotes and orders",
                                        "value": "quotes_and_orders",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Quotes and orders",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Billing",
                                        "value": "billing",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Billing",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Tickets",
                                        "value": "tickets",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Tickets",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Documents",
                                        "value": "documents",
                                        "icon": "icon-paperclip-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Documents",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Notes",
                                        "value": "notes",
                                        "icon": "icon-menu-left-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Notes",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-time-forward-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 2.2,
                                "name": "Contacts",
                                "label": "contacts",
                                "link": "/CM/contacts",
                                "icon": "icon-address-book-regular",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Contacts",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Contacts",
                                        "value": "contacts",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Contacts",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Interaction",
                                        "value": "interaction",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Interaction",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Products",
                                        "value": "products",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Products",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Contracts",
                                        "value": "contracts",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Contracts",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Quotes and orders",
                                        "value": "quotes_and_orders",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Quotes and orders",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Billing",
                                        "value": "billing",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Billing",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Tickets",
                                        "value": "tickets",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Tickets",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Documents",
                                        "value": "documents",
                                        "icon": "icon-paperclip-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Documents",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Notes",
                                        "value": "notes",
                                        "icon": "icon-menu-left-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Notes",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-time-forward-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                   
                    {
                        "id": 3,
                        "name": "Supplier management",
                        "label": "supplier_management",
                        "icon": "icon-boxes-regular",
                        "link": "/SM/supplier",
                        "active": false,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "SM",
                        "view": true,
                        "description": "Collaborative network, diverse suppliers, ensuring agility, scale, and competitive solutions.",
                        "sections": [
                            {
                                "name": "Overview",
                                "value": "overview",
                                "icon": "icon-presentation-regular",
                                "view": true,
                                "active": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Overview",
                                "addDisabled": true,
                                "editDisabled": false,
                                "deleteDisabled": true,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Location",
                                "value": "location",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Location",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Products",
                                "value": "products",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Products",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Contacts",
                                "value": "contacts",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Contacts",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Contracts",
                                "value": "contracts",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Contracts",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Discount",
                                "value": "discount",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Discount",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Purchase Order",
                                "value": "purchase_order",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Purchase Order",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Documents",
                                "value": "documents",
                                "icon": "icon-paperclip-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Documents",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Notes",
                                "value": "notes",
                                "icon": "icon-menu-left-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Notes",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Activity log",
                                "value": "activity_log",
                                "icon": "icon-time-forward-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Activity log",
                                "active": false,
                                "addDisabled": true,
                                "editDisabled": true,
                                "deleteDisabled": true,
                                "viewDisabled": false,
                                "allDisabled": true
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 4,
                        "name": "Quotes",
                        "label": "quotes",
                        "icon": "icon-file-list-regular",
                        "link": "/UPC/Quotes/dashboard",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Quotes",
                        "description": "Configured pricing quotes",
                        "child": [
                            {
                                "id": 4.101,
                                "view": true,
                                "title": true,
                                "name": "Configure Price Quote",
                                "active": false,
                                "label": "configure_price_quote",
                                "link": "/UPC/Quotes/dashboard",
                                "customName": "Configure Price Quote",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 4.1,
                                "view": true,
                                "title": false,
                                "name": "Quotes",
                                "active": false,
                                "label": "quotes",
                                "edit": false,
                                "delete": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Quotes",
                                "icon": "icon-file-list-regular",
                                "link": "/UPC/Quotes",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 4.2,
                                "title": false,
                                "name": "Quote assistant",
                                "label": "quote_assistant",
                                "link": "/UPC/Quotes/assistant",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Quote assistant",
                                "icon": "icon-sparkle-regular",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 15,
                        "name": "Contract",
                        "label": "contract",
                        "icon": "icon-file-text-shield-regular",
                        "link": "/contractManagement",
                        "active": false,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Contract",
                        "customFieldModule": "agreement",
                        "view": true,
                        "description": "Adaptable contracts designed for your needs.",
                        "sections": [
                            {
                                "name": "Overview",
                                "value": "overview",
                                "icon": "icon-check-circle-filled",
                                "view": true,
                                "active": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "segment",
                                "customName": "Overview",
                                "tabs": [
                                    {
                                        "name": "General info",
                                        "value": "general_info"
                                    },
                                    {
                                        "name": "Site details",
                                        "value": "site_details"
                                    },
                                    {
                                        "name": "Product details",
                                        "value": "product_details"
                                    },
                                    {
                                        "name": "Terms and condition",
                                        "value": "terms_and_condition"
                                    },
                                    {
                                        "name": "Contract document",
                                        "value": "contract_document"
                                    }
                                ],
                                "addDisabled": true,
                                "editDisabled": false,
                                "deleteDisabled": true,
                                "viewDisabled": false,
                                "allDisabled": false,
                                "filedView": false
                            },
                            {
                                "name": "Approval",
                                "value": "approval",
                                "icon": "icon-check-circle-filled",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "segment",
                                "customName": "Approval",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false,
                                "filedView": false
                            },
                            {
                                "name": "Signature",
                                "value": "Signature",
                                "icon": "icon-check-circle-filled",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "segment",
                                "customName": "Signature",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false,
                                "filedView": false
                            },
                            {
                                "name": "Execution",
                                "value": "Execution",
                                "icon": "icon-check-circle-filled",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "segment",
                                "customName": "Execution",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false,
                                "filedView": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 5,
                        "name": "Orders",
                        "label": "orders",
                        "icon": "icon-shopping-cart-arrow-down-regular",
                        "link": "/UPC/product-order/dashboard",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Orders",
                        "description": "Efficiently track and manage your stock levels.",
                        "child": [
                            {
                                "view": true,
                                "title": true,
                                "name": "Order management",
                                "label": "order_management",
                                "link": "/UPC/product-order/dashboard",
                                "customName": "Order management",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 5.1,
                                "name": "Inventory",
                                "title": false,
                                "label": "inventory",
                                "icon": "icon-warehouse-regular",
                                "link": "/Inventory/dashboard",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Inventory",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 53,
                                "name": "Equipment order",
                                "label": "equipment orders",
                                "icon": "icon-shopping-cart-arrow-down-regular",
                                "link": "/UPC/equipment-order",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Equipment order",
                                "description": "Track and Manage Orders",
                                "customFieldModule": "productorder",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-check-circle-filled",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Overview",
                                        "tabs": [
                                            {
                                                "name": "Order information",
                                                "value": "order_information"
                                            },
                                            {
                                                "name": "Site details",
                                                "value": "site_details"
                                            },
                                            {
                                                "name": "Product details",
                                                "value": "product_details"
                                            }
                                        ],
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Approval",
                                        "value": "approval",
                                        "icon": "icon-check-circle-filled",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Approval",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Shipment",
                                        "value": "shipment",
                                        "icon": "icon-check-circle-filled",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Shipment",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Installation",
                                        "value": "installation",
                                        "icon": "icon-check-circle-filled",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Installation",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Activation",
                                        "value": "activation",
                                        "icon": "icon-check-circle-filled",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Activation",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 5.2,
                                "title": false,
                                "name": "Product orders",
                                "label": "product_orders",
                                "link": "/UPC/product-order",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Product order",
                                "icon": "icon-delivery-cart-regular",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Oder item",
                                        "value": "oder_item",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Oder item",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Related item",
                                        "value": "related_item",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Related item",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 5.3,
                                "name": "Service orders",
                                "label": "service_orders",
                                "link": "/UPC/service-order",
                                "active": false,
                                "view": true,
                                "icon": "icon-scanner-regular",
                                "customName": "Service orders",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Oder item",
                                        "value": "oder_item",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Oder item",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Related item",
                                        "value": "related_item",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Related item",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 5.4,
                                "name": "Resource orders",
                                "label": "resource_orders",
                                "link": "/UPC/resource-order",
                                "active": false,
                                "view": true,
                                "icon": "icon-hard-drive-regular",
                                "customName": "Resource orders",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Oder item",
                                        "value": "oder_item",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Oder item",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Related item",
                                        "value": "related_item",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Related item",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 6,
                        "name": "Billing",
                        "label": "billing",
                        "icon": "icon-money-bill-regular",
                        "link": "/invoice/dashboard",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Billing",
                        "description": "Easily track your invoices, payment history, and current billing status.",
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 7,
                        "name": "Ticket management",
                        "label": "tickets",
                        "icon": "icon-coupon-regular",
                        "link": "/ticket",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Ticket management",
                        "description": "Centralized table for tickets.",
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 8,
                        "name": "Service qualification",
                        "label": "service_qualification",
                        "icon": "icon-map-pin-regular",
                        "link": "/servicequalification",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Service qualification",
                        "description": "Services are accessible at the location.",
                        "sections": [
                            {
                                "name": "Region",
                                "value": "region",
                                "icon": "icon-presentation-regular",
                                "view": true,
                                "active": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "region",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Exclude region",
                                        "value": "exclude_region",
                                        "icon": "icon-location-pin-times-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Exclude region",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Product",
                                "value": "product",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "product",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 9,
                        "name": "Decision manager",
                        "label": "decision_manager",
                        "icon": "icon-code-square-regular",
                        "link": "/DMN/dashboard",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "DMN",
                        "child": [
                            {
                                "view": true,
                                "title": true,
                                "name": "Decision manager",
                                "label": "decision_manager",
                                "link": "/DMN/dashboard",
                                "customName": "Decision manager",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 9.1,
                                "name": "Rule studio",
                                "label": "rule_studio",
                                "link": "/DMN/rule_studio",
                                "icon": "icon-laptop-code-regular",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Rule studio",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 9.2,
                                "name": "Rule validation",
                                "label": "rule_validation",
                                "link": "/DMN/rule_validation",
                                "active": false,
                                "view": true,
                                "icon": "icon-filter-edit-regular",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Rule validation",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 9.3,
                                "name": "Schema",
                                "label": "schema",
                                "link": "/schemas",
                                "active": false,
                                "view": true,
                                "icon": "icon-filter-edit-regular",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Schema",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 9.4,
                                "name": "Data management",
                                "label": "data_management",
                                "link": "/reference_data",
                                "active": false,
                                "view": true,
                                "icon": "icon-filter-edit-regular",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Data management",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 9.5,
                                "name": "Audit log",
                                "label": "audit_log",
                                "link": "/DMN/audit",
                                "active": false,
                                "view": true,
                                "icon": "icon-filter-edit-regular",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Audit log",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "description": "Get real-time monitoring and performance insights of the business rules to optimize decision-making capabilities.",
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    }
                ],
                "footer": [
                    {
                        "id": 23,
                        "name": "Help",
                        "label": "Help",
                        "icon": "icon-question-circle-regular",
                        "link": "https://halleyx-documentation-ghubgwxrcq-uc.a.run.app/",
                        "active": false,
                        "view": true,
                        "customName": "Help",
                        "addDisabled": true,
                        "editDisabled": true,
                        "deleteDisabled": true,
                        "viewDisabled": true,
                        "allDisabled": true
                    },
                    {
                        "id": 24,
                        "name": "Settings",
                        "label": "Settings",
                        "icon": "icon-settings-regular",
                        "link": "/settings",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Settings",
                        "sections": [
                            {
                                "value": "organization",
                                "name": "Organization",
                                "label": "organization",
                                "link": "organization",
                                "description": "Get access to your organisation details",
                                "icon": "general.svg",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Organisation",
                                "customFieldModule": "organisation",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Default settings",
                                        "value": "default_settings",
                                        "icon": "icon-check-circle-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Default settings",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    }
                                ],
                                "addDisabled": true,
                                "editDisabled": false,
                                "deleteDisabled": true,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Users",
                                "description": "Manage users",
                                "value": "users",
                                "link": "users",
                                "icon": "users.svg",
                                "view": true,
                                "active": false,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Users",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false,
                                "customFieldModule": "User",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Additional details",
                                        "value": "additional details",
                                        "icon": "icon-check-circle-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Additional details",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    }
                                ]
                            },
                            {
                                "name": "User profile",
                                "description": "Get access to user profile details",
                                "value": "user_profile",
                                "icon": "users.svg",
                                "view": true,
                                "edit": false,
                                "link": "loginDetails",
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "User profile",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Change password",
                                        "value": "change_password",
                                        "icon": "icon-delivery-cart-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Change password",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    }
                                ],
                                "addDisabled": true,
                                "editDisabled": false,
                                "deleteDisabled": true,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "API",
                                "description": "My API key",
                                "value": "api",
                                "icon": "key.svg",
                                "view": true,
                                "link": "",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "API Key",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Tenant",
                                "description": "Manage tenant",
                                "value": "tenant",
                                "link": "tenantTable",
                                "icon": "users.svg",
                                "view": true,
                                "active": false,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Tenant",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false,
                                "customFieldModule": ""
                            },
                            {
                                "name": "Roles and permissions",
                                "description": "Manage assigned roles and their permissions",
                                "value": "roles_and_permissions",
                                "view": true,
                                "link": "roles",
                                "icon": "roles.svg",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Roles and permissions",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Tax",
                                "description": "Add the tax",
                                "value": "tax",
                                "link": "tax",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "icon": "bill.svg",
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Tax",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Automation",
                                "description": "Real-time tracking",
                                "value": "automation",
                                "view": true,
                                "link": "automation",
                                "edit": false,
                                "delete": false,
                                "icon": "audit.svg",
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Automation",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Fields",
                                "value": "Fields",
                                "description": "Personalized Input Options",
                                "icon": "customFields.svg",
                                "view": true,
                                "link": "custom-field",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Fields",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Email template",
                                "description": "Create email template",
                                "value": "email_template",
                                "view": true,
                                "link": "emailTemplate",
                                "edit": false,
                                "icon": "customFields.svg",
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Email template",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Assignment rule",
                                "description": "Create email template",
                                "value": "assignment_rule",
                                "view": true,
                                "link": "navigation",
                                "edit": false,
                                "icon": "assigmentRule.svg",
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Assignment rule",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Email triggers",
                                "description": "Automated actions initiated by email events",
                                "value": "email_triggers",
                                "view": true,
                                "link": "navigation",
                                "edit": false,
                                "icon": "emailTiggers.svg",
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Email triggers",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Import template",
                                "description": "Create import template",
                                "value": "import_template",
                                "view": true,
                                "icon": "customFields.svg",
                                "link": "importTemplate",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Import template",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Forms",
                                "description": "Create forms",
                                "value": "forms",
                                "view": true,
                                "icon": "customFields.svg",
                                "link": "forms",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Forms",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "ID Settings",
                                "description": "Format the ID based on user convenience",
                                "value": "ID_Settings",
                                "link": "IdSettings",
                                "view": true,
                                "icon": "audit.svg",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "ID Settings",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 25,
                        "name": "Log out",
                        "label": "Log out",
                        "icon": "icon-log-out-regular",
                        "link": "/",
                        "active": false,
                        "customName": "Log out",
                        "view": true,
                        "addDisabled": true,
                        "editDisabled": true,
                        "deleteDisabled": true,
                        "viewDisabled": true,
                        "allDisabled": true
                    }
                ]
            },
      sideBarDataCopy: {
                "main": [
                    {
                        "id": 1,
                        "name": "Unified product catalog",
                        "label": "UPC",
                        "icon": "icon-notebook-regular",
                        "link": "/UPC/dashboard",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "module",
                        "customName": "UPC",
                        "description": "Go to market faster with a single catalog for products, services, pricing, rules, bundles, add-ons, promotions and more.",
                        "child": [
                            {
                                "view": true,
                                "title": true,
                                "name": "Unified product catalog",
                                "label": "unified_product_catalog",
                                "link": "/UPC/dashboard",
                                "customName": "Unified product catalog",
                                "description": "Centralized catalog for products offers.",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 1.1,
                                "name": "Category",
                                "label": "category",
                                "link": "/PCM/categorytable",
                                "active": false,
                                "view": false,
                                "icon": "icon-microsoft-regular",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "section",
                                "customName": "Category",
                                "description": "Aligning products, trends, and consumer expectations seamlessly.",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 1.2,
                                "name": "Product catalogue",
                                "label": "product_catalogue",
                                "link": "/UPC/product-catalog-table",
                                "active": false,
                                "view": true,
                                "icon": "icon-box-regular",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "section",
                                "customName": "Product catalogue",
                                "child": [
                                    {
                                        "id": 1.201,
                                        "title": true,
                                        "name": "Offers",
                                        "label": "offers",
                                        "link": "/UPC/product-catalog-table/offering",
                                        "active": false,
                                        "view": true,
                                        "customName": "Offers",
                                        "icon": "icon-package-star-regular",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "id": 1.21,
                                        "name": "Offers",
                                        "label": "offers",
                                        "link": "/UPC/product-catalog-table/offering",
                                        "active": false,
                                        "view": true,
                                        "icon": "icon-package-star-regular",
                                        "customFieldModule": "productoffering",
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "subSection",
                                        "customName": "Offers",
                                        "sections": [
                                            {
                                                "name": "Overview",
                                                "value": "overview",
                                                "icon": "icon-presentation-regular",
                                                "view": true,
                                                "active": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Overview",
                                                "addDisabled": true,
                                                "editDisabled": false,
                                                "deleteDisabled": true,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Pricing",
                                                "value": "pricing",
                                                "icon": "icon-dollar-regular",
                                                "view": true,
                                                "active": false,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Pricing",
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Media",
                                                "value": "media",
                                                "icon": "icon-money-bill-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Media",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Discounts",
                                                "value": "discounts",
                                                "icon": "icon-badge-discount-alt-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Discounts",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Additional charges",
                                                "value": "additional_charges",
                                                "icon": "icon-money-bill-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Additional charges",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Recommended products",
                                                "value": "recommended_products",
                                                "icon": "icon-inbox-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Recommended products",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Available regions",
                                                "value": "available_regions",
                                                "icon": "icon-location-pin-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Available regions",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Suppliers",
                                                "value": "suppliers",
                                                "icon": "icon-boxes-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Suppliers",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Orders",
                                                "value": "orders",
                                                "icon": "icon-menu-left-square-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Orders",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Documents",
                                                "value": "documents",
                                                "icon": "icon-paperclip-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Documents",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Notes",
                                                "value": "notes",
                                                "icon": "icon-menu-left-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Notes",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Activity log",
                                                "value": "activity_log",
                                                "icon": "icon-time-forward-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Activity log",
                                                "active": false,
                                                "addDisabled": true,
                                                "editDisabled": true,
                                                "deleteDisabled": true,
                                                "viewDisabled": false,
                                                "allDisabled": true
                                            }
                                        ],
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "id": 1.22,
                                        "name": "Assistant",
                                        "label": "assistant",
                                        "link": "/UPC/product-catalog-table/offering/assistant",
                                        "active": false,
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "subSection",
                                        "customName": "Assistant",
                                        "icon": "icon-sparkle-regular",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "id": 1.23,
                                        "name": "Specification",
                                        "label": "specification",
                                        "link": "/UPC/product-catalog-table/specification",
                                        "active": false,
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "subSection",
                                        "customName": "Specification",
                                        "icon": "icon-server-regular",
                                        "sections": [
                                            {
                                                "name": "Overview",
                                                "value": "overview",
                                                "icon": "icon-presentation-regular",
                                                "view": true,
                                                "active": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Overview",
                                                "addDisabled": true,
                                                "editDisabled": false,
                                                "deleteDisabled": true,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Features",
                                                "value": "features",
                                                "icon": "icon-dollar-regular",
                                                "view": true,
                                                "active": false,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Features",
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Service and resource",
                                                "value": "service_and_resource",
                                                "icon": "icon-money-bill-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Service and resource",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Documents",
                                                "value": "documents",
                                                "icon": "icon-paperclip-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Documents",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Notes",
                                                "value": "notes",
                                                "icon": "icon-menu-left-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Notes",
                                                "active": false,
                                                "addDisabled": false,
                                                "editDisabled": false,
                                                "deleteDisabled": false,
                                                "viewDisabled": false,
                                                "allDisabled": false
                                            },
                                            {
                                                "name": "Activity log",
                                                "value": "activity_log",
                                                "icon": "icon-time-forward-regular",
                                                "view": true,
                                                "edit": false,
                                                "delete": false,
                                                "title": false,
                                                "add": false,
                                                "all": false,
                                                "level": "segment",
                                                "customName": "Activity log",
                                                "active": false,
                                                "addDisabled": true,
                                                "editDisabled": true,
                                                "deleteDisabled": true,
                                                "viewDisabled": false,
                                                "allDisabled": true
                                            }
                                        ],
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "id": 1.24,
                                        "name": "Additional charges",
                                        "label": "additional_charges",
                                        "link": "/UPC/product-catalog-table/additional-charges",
                                        "active": false,
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Additional charges",
                                        "icon": "icon-credit-card-plus-regular",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "id": 1.25,
                                        "name": "Discounts",
                                        "label": "discounts",
                                        "link": "/UPC/product-catalog-table/product-discount",
                                        "active": false,
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Discounts",
                                        "icon": "icon-badge-discount-alt-regular",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 1.3,
                                "name": "Service catalogue",
                                "label": "service_catalogue",
                                "link": "/UPC/product-catalog-table/service",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Service catalogue",
                                "icon": "icon-cloud-regular",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Features",
                                        "value": "features",
                                        "icon": "icon-dollar-regular",
                                        "view": true,
                                        "active": false,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Features",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Service and resource",
                                        "value": "service_and_resource",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Service and resource",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Documents",
                                        "value": "documents",
                                        "icon": "icon-paperclip-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Documents",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Notes",
                                        "value": "notes",
                                        "icon": "icon-menu-left-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Notes",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-time-forward-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 1.4,
                                "name": "Resource catalogue",
                                "label": "resource_catalogue",
                                "link": "/UPC/product-catalog-table/resource",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Resource catalogue",
                                "icon": "icon-cpu-regular",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Features",
                                        "value": "features",
                                        "icon": "icon-dollar-regular",
                                        "view": true,
                                        "active": false,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Features",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Service and resource",
                                        "value": "service_and_resource",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Service and resource",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Documents",
                                        "value": "documents",
                                        "icon": "icon-paperclip-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Documents",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Notes",
                                        "value": "notes",
                                        "icon": "icon-menu-left-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Notes",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-time-forward-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 2,
                        "name": "Customer management",
                        "label": "customer_management",
                        "icon": "icon-user-settings-regular",
                        "link": "/CM/dashboard",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "CM",
                        "description": "Keep tabs on your ISP's performance with insights from the past 30 days, all on one dashboard.",
                        "child": [
                            {
                                "view": true,
                                "title": true,
                                "name": "Customer management",
                                "label": "customer_management",
                                "customName": "Customer management",
                                "link": "/CM/dashboard",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 10.1,
                                "name": "Lead",
                                "label": "lead",
                                "link": "/MDU/lead",
                                "icon": "icon-user-star-regular",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "description": "Potential customers interested in your products or services",
                                "all": false,
                                "level": "section",
                                "customName": "Lead",
                                "customFieldModule": "saleslead",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": true
                                    },
                                    {
                                        "name": "Contacts",
                                        "value": "contacts",
                                        "icon": "icon-user-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Contacts",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": true
                                    },
                                    {
                                        "name": "Sites",
                                        "value": "sites",
                                        "icon": "icon-buildings-alt-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Sites",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": true
                                    },
                                    {
                                        "name": "Notes",
                                        "value": "notes",
                                        "icon": "icon-note-list-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Notes",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Documents",
                                        "value": "documents",
                                        "icon": "icon-paperclip-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Documents",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-time-forward-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 2.1,
                                "name": "Accounts",
                                "label": "accounts",
                                "link": "/CM/accounts",
                                "icon": "icon-buildings-alt-regular",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Accounts",
                                "customFieldModule": "accounts",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Hierarchy",
                                        "value": "Hierarchy",
                                        "icon": "icon-dollar-regular",
                                        "view": true,
                                        "active": false,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Hierarchy",
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Sites",
                                        "value": "sites",
                                        "icon": "icon-buildings-alt-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Sites",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Location",
                                        "value": "location",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Location",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Contacts",
                                        "value": "contacts",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Contacts",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Interaction",
                                        "value": "interaction",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Interaction",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Products",
                                        "value": "products",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Products",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Contracts",
                                        "value": "contracts",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Contracts",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Quotes and orders",
                                        "value": "quotes_and_orders",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Quotes and orders",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Billing",
                                        "value": "billing",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Billing",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Tickets",
                                        "value": "tickets",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Tickets",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Documents",
                                        "value": "documents",
                                        "icon": "icon-paperclip-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Documents",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Notes",
                                        "value": "notes",
                                        "icon": "icon-menu-left-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Notes",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-time-forward-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 2.2,
                                "name": "Contacts",
                                "label": "contacts",
                                "link": "/CM/contacts",
                                "icon": "icon-address-book-regular",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Contacts",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Contacts",
                                        "value": "contacts",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Contacts",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Interaction",
                                        "value": "interaction",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Interaction",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Products",
                                        "value": "products",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Products",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Contracts",
                                        "value": "contracts",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Contracts",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Quotes and orders",
                                        "value": "quotes_and_orders",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Quotes and orders",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Billing",
                                        "value": "billing",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Billing",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Tickets",
                                        "value": "tickets",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Tickets",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Documents",
                                        "value": "documents",
                                        "icon": "icon-paperclip-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Documents",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Notes",
                                        "value": "notes",
                                        "icon": "icon-menu-left-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Notes",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-time-forward-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                   
                    {
                        "id": 3,
                        "name": "Supplier management",
                        "label": "supplier_management",
                        "icon": "icon-boxes-regular",
                        "link": "/SM/supplier",
                        "active": false,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "SM",
                        "view": true,
                        "description": "Collaborative network, diverse suppliers, ensuring agility, scale, and competitive solutions.",
                        "sections": [
                            {
                                "name": "Overview",
                                "value": "overview",
                                "icon": "icon-presentation-regular",
                                "view": true,
                                "active": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Overview",
                                "addDisabled": true,
                                "editDisabled": false,
                                "deleteDisabled": true,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Location",
                                "value": "location",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Location",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Products",
                                "value": "products",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Products",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Contacts",
                                "value": "contacts",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Contacts",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Contracts",
                                "value": "contracts",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Contracts",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Discount",
                                "value": "discount",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Discount",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Purchase Order",
                                "value": "purchase_order",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Purchase Order",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Documents",
                                "value": "documents",
                                "icon": "icon-paperclip-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Documents",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Notes",
                                "value": "notes",
                                "icon": "icon-menu-left-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Notes",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Activity log",
                                "value": "activity_log",
                                "icon": "icon-time-forward-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Activity log",
                                "active": false,
                                "addDisabled": true,
                                "editDisabled": true,
                                "deleteDisabled": true,
                                "viewDisabled": false,
                                "allDisabled": true
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 4,
                        "name": "Quotes",
                        "label": "quotes",
                        "icon": "icon-file-list-regular",
                        "link": "/UPC/Quotes/dashboard",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Quotes",
                        "description": "Configured pricing quotes",
                        "child": [
                            {
                                "id": 4.101,
                                "view": true,
                                "title": true,
                                "name": "Configure Price Quote",
                                "active": false,
                                "label": "configure_price_quote",
                                "link": "/UPC/Quotes/dashboard",
                                "customName": "Configure Price Quote",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 4.1,
                                "view": true,
                                "title": false,
                                "name": "Quotes",
                                "active": false,
                                "label": "quotes",
                                "edit": false,
                                "delete": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Quotes",
                                "icon": "icon-file-list-regular",
                                "link": "/UPC/Quotes",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 4.2,
                                "title": false,
                                "name": "Quote assistant",
                                "label": "quote_assistant",
                                "link": "/UPC/Quotes/assistant",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Quote assistant",
                                "icon": "icon-sparkle-regular",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 15,
                        "name": "Contract",
                        "label": "contract",
                        "icon": "icon-file-text-shield-regular",
                        "link": "/contractManagement",
                        "active": false,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Contract",
                        "customFieldModule": "agreement",
                        "view": true,
                        "description": "Adaptable contracts designed for your needs.",
                        "sections": [
                            {
                                "name": "Overview",
                                "value": "overview",
                                "icon": "icon-check-circle-filled",
                                "view": true,
                                "active": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "segment",
                                "customName": "Overview",
                                "tabs": [
                                    {
                                        "name": "General info",
                                        "value": "general_info"
                                    },
                                    {
                                        "name": "Site details",
                                        "value": "site_details"
                                    },
                                    {
                                        "name": "Product details",
                                        "value": "product_details"
                                    },
                                    {
                                        "name": "Terms and condition",
                                        "value": "terms_and_condition"
                                    },
                                    {
                                        "name": "Contract document",
                                        "value": "contract_document"
                                    }
                                ],
                                "addDisabled": true,
                                "editDisabled": false,
                                "deleteDisabled": true,
                                "viewDisabled": false,
                                "allDisabled": false,
                                "filedView": false
                            },
                            {
                                "name": "Approval",
                                "value": "approval",
                                "icon": "icon-check-circle-filled",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "segment",
                                "customName": "Approval",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false,
                                "filedView": false
                            },
                            {
                                "name": "Signature",
                                "value": "Signature",
                                "icon": "icon-check-circle-filled",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "segment",
                                "customName": "Signature",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false,
                                "filedView": false
                            },
                            {
                                "name": "Execution",
                                "value": "Execution",
                                "icon": "icon-check-circle-filled",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "segment",
                                "customName": "Execution",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false,
                                "filedView": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 5,
                        "name": "Orders",
                        "label": "orders",
                        "icon": "icon-shopping-cart-arrow-down-regular",
                        "link": "/UPC/product-order/dashboard",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Orders",
                        "description": "Efficiently track and manage your stock levels.",
                        "child": [
                            {
                                "view": true,
                                "title": true,
                                "name": "Order management",
                                "label": "order_management",
                                "link": "/UPC/product-order/dashboard",
                                "customName": "Order management",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 5.1,
                                "name": "Inventory",
                                "title": false,
                                "label": "inventory",
                                "icon": "icon-warehouse-regular",
                                "link": "/Inventory/dashboard",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Inventory",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 53,
                                "name": "Equipment order",
                                "label": "equipment orders",
                                "icon": "icon-shopping-cart-arrow-down-regular",
                                "link": "/UPC/equipment-order",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Equipment order",
                                "description": "Track and Manage Orders",
                                "customFieldModule": "productorder",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-check-circle-filled",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Overview",
                                        "tabs": [
                                            {
                                                "name": "Order information",
                                                "value": "order_information"
                                            },
                                            {
                                                "name": "Site details",
                                                "value": "site_details"
                                            },
                                            {
                                                "name": "Product details",
                                                "value": "product_details"
                                            }
                                        ],
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Approval",
                                        "value": "approval",
                                        "icon": "icon-check-circle-filled",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Approval",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Shipment",
                                        "value": "shipment",
                                        "icon": "icon-check-circle-filled",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Shipment",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Installation",
                                        "value": "installation",
                                        "icon": "icon-check-circle-filled",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Installation",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    },
                                    {
                                        "name": "Activation",
                                        "value": "activation",
                                        "icon": "icon-check-circle-filled",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "segment",
                                        "customName": "Activation",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false,
                                        "filedView": false
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 5.2,
                                "title": false,
                                "name": "Product orders",
                                "label": "product_orders",
                                "link": "/UPC/product-order",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Product order",
                                "icon": "icon-delivery-cart-regular",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Oder item",
                                        "value": "oder_item",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Oder item",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Related item",
                                        "value": "related_item",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Related item",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 5.3,
                                "name": "Service orders",
                                "label": "service_orders",
                                "link": "/UPC/service-order",
                                "active": false,
                                "view": true,
                                "icon": "icon-scanner-regular",
                                "customName": "Service orders",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Oder item",
                                        "value": "oder_item",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Oder item",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Related item",
                                        "value": "related_item",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Related item",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 5.4,
                                "name": "Resource orders",
                                "label": "resource_orders",
                                "link": "/UPC/resource-order",
                                "active": false,
                                "view": true,
                                "icon": "icon-hard-drive-regular",
                                "customName": "Resource orders",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Oder item",
                                        "value": "oder_item",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Oder item",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Related item",
                                        "value": "related_item",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Related item",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Activity log",
                                        "value": "activity_log",
                                        "icon": "icon-money-bill-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Activity log",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": true,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": true
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 6,
                        "name": "Billing",
                        "label": "billing",
                        "icon": "icon-money-bill-regular",
                        "link": "/invoice/dashboard",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Billing",
                        "description": "Easily track your invoices, payment history, and current billing status.",
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 7,
                        "name": "Ticket management",
                        "label": "tickets",
                        "icon": "icon-coupon-regular",
                        "link": "/ticket",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Ticket management",
                        "description": "Centralized table for tickets.",
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 8,
                        "name": "Service qualification",
                        "label": "service_qualification",
                        "icon": "icon-map-pin-regular",
                        "link": "/servicequalification",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Service qualification",
                        "description": "Services are accessible at the location.",
                        "sections": [
                            {
                                "name": "Region",
                                "value": "region",
                                "icon": "icon-presentation-regular",
                                "view": true,
                                "active": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "region",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Exclude region",
                                        "value": "exclude_region",
                                        "icon": "icon-location-pin-times-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Exclude region",
                                        "active": false,
                                        "addDisabled": false,
                                        "editDisabled": false,
                                        "deleteDisabled": false,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    }
                                ],
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Product",
                                "value": "product",
                                "icon": "icon-money-bill-regular",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "product",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 9,
                        "name": "Decision manager",
                        "label": "decision_manager",
                        "icon": "icon-code-square-regular",
                        "link": "/DMN/dashboard",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "DMN",
                        "child": [
                            {
                                "view": true,
                                "title": true,
                                "name": "Decision manager",
                                "label": "decision_manager",
                                "link": "/DMN/dashboard",
                                "customName": "Decision manager",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 9.1,
                                "name": "Rule studio",
                                "label": "rule_studio",
                                "link": "/DMN/rule_studio",
                                "icon": "icon-laptop-code-regular",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Rule studio",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 9.2,
                                "name": "Rule validation",
                                "label": "rule_validation",
                                "link": "/DMN/rule_validation",
                                "active": false,
                                "view": true,
                                "icon": "icon-filter-edit-regular",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Rule validation",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 9.3,
                                "name": "Schema",
                                "label": "schema",
                                "link": "/schemas",
                                "active": false,
                                "view": true,
                                "icon": "icon-filter-edit-regular",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Schema",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 9.4,
                                "name": "Data management",
                                "label": "data_management",
                                "link": "/reference_data",
                                "active": false,
                                "view": true,
                                "icon": "icon-filter-edit-regular",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Data management",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "id": 9.5,
                                "name": "Audit log",
                                "label": "audit_log",
                                "link": "/DMN/audit",
                                "active": false,
                                "view": true,
                                "icon": "icon-filter-edit-regular",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Audit log",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "description": "Get real-time monitoring and performance insights of the business rules to optimize decision-making capabilities.",
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    }
                ],
                "footer": [
                    {
                        "id": 23,
                        "name": "Help",
                        "label": "Help",
                        "icon": "icon-question-circle-regular",
                        "link": "https://halleyx-documentation-ghubgwxrcq-uc.a.run.app/",
                        "active": false,
                        "view": true,
                        "customName": "Help",
                        "addDisabled": true,
                        "editDisabled": true,
                        "deleteDisabled": true,
                        "viewDisabled": true,
                        "allDisabled": true
                    },
                    {
                        "id": 24,
                        "name": "Settings",
                        "label": "Settings",
                        "icon": "icon-settings-regular",
                        "link": "/settings",
                        "active": false,
                        "view": true,
                        "edit": false,
                        "delete": false,
                        "title": false,
                        "add": false,
                        "all": false,
                        "level": "",
                        "customName": "Settings",
                        "sections": [
                            {
                                "value": "organization",
                                "name": "Organization",
                                "label": "organization",
                                "link": "organization",
                                "description": "Get access to your organisation details",
                                "icon": "general.svg",
                                "active": false,
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Organisation",
                                "customFieldModule": "organisation",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Default settings",
                                        "value": "default_settings",
                                        "icon": "icon-check-circle-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Default settings",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    }
                                ],
                                "addDisabled": true,
                                "editDisabled": false,
                                "deleteDisabled": true,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Users",
                                "description": "Manage users",
                                "value": "users",
                                "link": "users",
                                "icon": "users.svg",
                                "view": true,
                                "active": false,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Users",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false,
                                "customFieldModule": "User",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Additional details",
                                        "value": "additional details",
                                        "icon": "icon-check-circle-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Additional details",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    }
                                ]
                            },
                            {
                                "name": "User profile",
                                "description": "Get access to user profile details",
                                "value": "user_profile",
                                "icon": "users.svg",
                                "view": true,
                                "edit": false,
                                "link": "loginDetails",
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "User profile",
                                "sections": [
                                    {
                                        "name": "Overview",
                                        "value": "overview",
                                        "icon": "icon-presentation-regular",
                                        "view": true,
                                        "active": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Overview",
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    },
                                    {
                                        "name": "Change password",
                                        "value": "change_password",
                                        "icon": "icon-delivery-cart-regular",
                                        "view": true,
                                        "edit": false,
                                        "delete": false,
                                        "title": false,
                                        "add": false,
                                        "all": false,
                                        "level": "",
                                        "customName": "Change password",
                                        "active": false,
                                        "addDisabled": true,
                                        "editDisabled": false,
                                        "deleteDisabled": true,
                                        "viewDisabled": false,
                                        "allDisabled": false
                                    }
                                ],
                                "addDisabled": true,
                                "editDisabled": false,
                                "deleteDisabled": true,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "API",
                                "description": "My API key",
                                "value": "api",
                                "icon": "key.svg",
                                "view": true,
                                "link": "",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "API Key",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Tenant",
                                "description": "Manage tenant",
                                "value": "tenant",
                                "link": "tenantTable",
                                "icon": "users.svg",
                                "view": true,
                                "active": false,
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Tenant",
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false,
                                "customFieldModule": ""
                            },
                            {
                                "name": "Roles and permissions",
                                "description": "Manage assigned roles and their permissions",
                                "value": "roles_and_permissions",
                                "view": true,
                                "link": "roles",
                                "icon": "roles.svg",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Roles and permissions",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Tax",
                                "description": "Add the tax",
                                "value": "tax",
                                "link": "tax",
                                "view": true,
                                "edit": false,
                                "delete": false,
                                "icon": "bill.svg",
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Tax",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Automation",
                                "description": "Real-time tracking",
                                "value": "automation",
                                "view": true,
                                "link": "automation",
                                "edit": false,
                                "delete": false,
                                "icon": "audit.svg",
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Automation",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Fields",
                                "value": "Fields",
                                "description": "Personalized Input Options",
                                "icon": "customFields.svg",
                                "view": true,
                                "link": "custom-field",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Fields",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Email template",
                                "description": "Create email template",
                                "value": "email_template",
                                "view": true,
                                "link": "emailTemplate",
                                "edit": false,
                                "icon": "customFields.svg",
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Email template",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Assignment rule",
                                "description": "Create email template",
                                "value": "assignment_rule",
                                "view": true,
                                "link": "navigation",
                                "edit": false,
                                "icon": "assigmentRule.svg",
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Assignment rule",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Email triggers",
                                "description": "Automated actions initiated by email events",
                                "value": "email_triggers",
                                "view": true,
                                "link": "navigation",
                                "edit": false,
                                "icon": "emailTiggers.svg",
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Email triggers",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Import template",
                                "description": "Create import template",
                                "value": "import_template",
                                "view": true,
                                "icon": "customFields.svg",
                                "link": "importTemplate",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Import template",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "Forms",
                                "description": "Create forms",
                                "value": "forms",
                                "view": true,
                                "icon": "customFields.svg",
                                "link": "forms",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "Forms",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            },
                            {
                                "name": "ID Settings",
                                "description": "Format the ID based on user convenience",
                                "value": "ID_Settings",
                                "link": "IdSettings",
                                "view": true,
                                "icon": "audit.svg",
                                "edit": false,
                                "delete": false,
                                "title": false,
                                "add": false,
                                "all": false,
                                "level": "",
                                "customName": "ID Settings",
                                "active": false,
                                "addDisabled": false,
                                "editDisabled": false,
                                "deleteDisabled": false,
                                "viewDisabled": false,
                                "allDisabled": false
                            }
                        ],
                        "addDisabled": false,
                        "editDisabled": false,
                        "deleteDisabled": false,
                        "viewDisabled": false,
                        "allDisabled": false
                    },
                    {
                        "id": 25,
                        "name": "Log out",
                        "label": "Log out",
                        "icon": "icon-log-out-regular",
                        "link": "/",
                        "active": false,
                        "customName": "Log out",
                        "view": true,
                        "addDisabled": true,
                        "editDisabled": true,
                        "deleteDisabled": true,
                        "viewDisabled": true,
                        "allDisabled": true
                    }
                ]
            },
      moreIcons: {
        id: 202,
        name: 'More',
        label: 'More',
        icon: 'icon-more-horizontal-filled',
        link: '/DMN/dashboard',
        active: false,
        view: true,
        child: [],
      },
    };
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    // computedSidebar() {
    //   // const sidebar = [
    //   //   {
    //   //     name: "Home",
    //   //     label: "Home",
    //   //     icon: "icon-grid-web-7-regular",
    //   //     description_en: "Home",
    //   //     description_fn: "*Home",
    //   //     active: true,
    //   //   },
    //   //   {
    //   //     name: "Business rule",
    //   //     label: "Business rule ",
    //   //     icon: "icon-code-regular",
    //   //     description_en: "Residential business market ",
    //   //     description_fr: "*Residential business market ",
    //   //   },
    //   //   {
    //   //     name: "Schema",
    //   //     label: "Schema",
    //   //     icon: "icon-desktop-code-regular",
    //   //     description_en: "Small business markets",
    //   //     description_fr: "*Small business markets",
    //   //   },
    //   //   {
    //   //     name: "Database",
    //   //     label: "Data management",
    //   //     icon: "icon-database-regular",
    //   //     description_en: "Small business markets",
    //   //     description_fr: "*Small business markets",
    //   //   },
    //   //   {
    //   //     name: "Execute",
    //   //     label: "Execution data",
    //   //     icon: "icon-file-settings-regular",
    //   //     description_en: "Small business markets",
    //   //     description_fr: "*Small business markets",
    //   //   },
    //   //   {
    //   //     name: "Audit",
    //   //     label: "Audit log",
    //   //     icon: "icon-notebook-regular",
    //   //     description_en: "Audit log",
    //   //     description_fr: "*Audit log",
    //   //   },
    //   //   {
    //   //     name: "Help",
    //   //     label: "Help",
    //   //     icon: "icon-question-circle-regular",
    //   //     description_en: "Setting",
    //   //     description_fr: "*Setting",
    //   //     separator: true,
    //   //   },
    //   //   {
    //   //     name: "setting",
    //   //     label: "Settings",
    //   //     icon: "icon-settings-regular",
    //   //     description_en: "Setting",
    //   //     description_fr: "*Setting",
    //   //   },
    //   //   {
    //   //     name: "logout",
    //   //     label: "Logout",
    //   //     icon: "icon-log-out-regular",
    //   //     description_en: "Logout",
    //   //     description_fr: "*Logout",
    //   //   },
    //   // ];
    //   // const filteredSidebar = sidebar.filter(
    //   //   (item) => !this.viewPermission.includes(item.name)
    //   // );
    //   //
    //   // return filteredSidebar;
    //   console.log(this.$store.state.getters.sidebarData(), "sidebarData");

    //   return this.$store.state.getters.sidebarData();
    // },
  },
  watch: {
    isIdle: {
      handler(val) {
        if (val == true) {
          this.$router.push('/');
        }
      },
    },
    '$store.state.role': {
      handler(role) {
        role;
        // console.log("🚀 ~ handler ~ role:", role);
        // if (role == "supplier") {
        //   this.sideBarData = {
        //     main: [
        //       {
        //         id: 1,
        //         name: "Overview",
        //         icon: "icon-presentation-regular",
        //         link: "/SM/Overview",
        //         active: true,
        //         view: true,
        //       },
        //       {
        //         id: 2,
        //         name: "Location",
        //         icon: "icon-location-pin-regular",
        //         link: "/SM/Location",
        //         active: false,
        //         view: true,
        //       },
        //       {
        //         id: 3,
        //         name: "Products",
        //         icon: "icon-box-regular",
        //         link: "/SM/Product",
        //         active: false,
        //         view: true,
        //       },
        //       {
        //         id: 4,
        //         name: "Contacts",
        //         icon: "icon-user-regular",
        //         link: "/SM/Contacts",
        //         active: false,
        //         view: true,
        //       },
        //       {
        //         id: 5,
        //         name: "Contracts",
        //         icon: "icon-file-regular",
        //         link: "/SM/Contracts",
        //         active: false,
        //         view: true,
        //       },
        //       {
        //         id: 6,
        //         name: "Purchase Order",
        //         icon: "icon-file-text-regular",
        //         link: "/SM/PurchaseOrder",
        //         active: false,
        //         view: true,
        //       },
        //       {
        //         id: 7,
        //         name: "Activity log",
        //         icon: "icon-clock-regular",
        //         link: "/SM/Activitylog",
        //         active: false,
        //         view: true,
        //       },

        //       {
        //         id: 8,
        //         name: "Schema",
        //         icon: "icon-desktop-code-regular",
        //         link: "/schemas",
        //         active: false,
        //         view: true,
        //       },
        //       {
        //         id: 9,
        //         name: "Data management",
        //         icon: "icon-database-regular",
        //         link: "/reference_data",
        //         active: false,
        //         view: true,
        //       },
        //     ],
        //     footer: [
        //       {
        //         id: 10,
        //         name: "Help",
        //         icon: "icon-question-circle-regular",
        //         link: "https://halleyx-documentation-ghubgwxrcq-uc.a.run.app/",
        //         active: false,
        //         view: true,
        //       },
        //       {
        //         id: 11,
        //         name: "Settings",
        //         icon: "icon-settings-regular",
        //         link: "/settings",
        //         active: false,
        //         view: false,
        //       },
        //       {
        //         id: 12,
        //         name: "Log out",
        //         icon: "icon-log-out-regular",
        //         link: "/",
        //         active: false,
        //         view: true,
        //       },
        //     ],
        //   };
        // }
        if (Object.keys(this.$store.getters.sidebarV2Permissions).length > 0) {
          // this.applyViewValues(
          //   this.$store.getters.sidebarV2Permissions,
          //   this.sideBarData
          // );
        }
      },
    },

    $route: {
      handler(to, from) {
        console.log(to, from, 'to from');
        if (to?.path !== from?.path) {
          this.routeChanged = true;
          console.log(true, 'to from');
        } else {
          this.routeChanged = false;
          console.log(false, 'to from');
        }
        if (to.path !== '/') {

          const matchingItem = this.findItemByLink(to.path);
          // console.log(matchingItem);
          if (matchingItem !== null) {
            this.setActiveById(this.sideBarData, matchingItem?.id);
          }
        }
      },
      immediate: true,
      deep: true,
    },
    // $store: {
    //   handler(val) {
    //     // console.log(val.getters.sidebarV2Permissions);
    //     if (val.getters.sidebarV2Permissions.SM === "true") {
    //       console.log(this.$store.getters.sidebarV2Permissions.SM, "true");
    //       this.sideBarData={
    //          main: [
    //       {
    //         id: 1,
    //         name: "Overview",
    //         icon: "icon-presentation-regular",
    //         link: "/SM/Overview",
    //         active: false,
    //         view: true,
    //       },
    //       {
    //         id: 2,
    //          name: "Location",
    //         icon: "icon-location-pin-regular",
    //         link: "/SM/Location",
    //         active: false,
    //         view: true,
    //       },
    //       {
    //         id: 3,
    //         name: "Products",
    //         icon: "icon-box-regular",
    //         link: "/SM/Product",
    //         active: false,
    //         view: true,
    //       },
    //       {
    //         id: 4,
    //         name: "Contacts",
    //         icon: "icon-user-regular",
    //         link: "/SM/Contacts",
    //         active: false,
    //         view: true,
    //       },
    //       {
    //        id: 5,
    //         name: "Contracts",
    //         icon: "icon-file-regular",
    //         link: "/SM/Contracts",
    //         active: false,
    //         view: true,
    //       },
    //       {
    //        id: 6,
    //         name: "Purchase Order",
    //         icon: "icon-file-text-regular",
    //         link: "/SM/PurchaseOrder",
    //         active: false,
    //         view: true,
    //       },
    //       {
    //        id: 7,
    //       name: "Activity log",
    //         icon: "icon-clock-regular",
    //         link: "/SM/Activitylog",
    //         active: false,
    //         view: true,
    //       },

    //        {
    //         id: 8,
    //         name: "Schema",
    //         icon: "icon-desktop-code-regular",
    //         link: "/schemas",
    //         active: false,
    //         view: true,
    //       },
    //       {
    //         id: 9,
    //         name: "Data management",
    //         icon: "icon-database-regular",
    //         link: "/reference_data",
    //         active: false,
    //         view: true,
    //       },
    //     ],
    //     footer: [
    //       {
    //         id: 10,
    //         name: "Help",
    //         icon: "icon-question-circle-regular",
    //         link: "https://halleyx-documentation-ghubgwxrcq-uc.a.run.app/",
    //         active: false,
    //         view: true,
    //       },
    //       {
    //         id: 11,
    //         name: "Settings",
    //         icon: "icon-settings-regular",
    //         link: "/settings",
    //         active: false,
    //         view: false,
    //       },
    //       {
    //         id: 12,
    //         name: "Log out",
    //         icon: "icon-log-out-regular",
    //         link: "/",
    //         active: false,
    //         view: true,
    //       },
    //     ],
    //       }
    //     }else{
    //       // this.sideBarData=this.$store.getters.
    //     }
    //     if (Object.keys(val.getters.sidebarV2Permissions).length > 0) {
    //       this.applyViewValues(
    //         val.getters.sidebarV2Permissions,
    //         this.sideBarData
    //       );
    //       console.log(
    //         this.$store.getters.sidebarV2Permissions,
    //         this.sideBarData
    //       );
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    isLoading: {
      handler(val) {
        console.log(val, 'isLoading');
        if (val) {
          val;
        } else {
          val;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    console.log("app.............");
    await this.$store.dispatch("loginUserDetails");
    
    let loginUserLanguage = this.$store?.getters?.loginUserDetails?.languageAbility;
    console.log("loginUserLanguage::12::",loginUserLanguage);
    
    
      // Store rems in localStorage
    if (loginUserLanguage) {
      localStorage.setItem('userLanguageFromSetting', JSON.stringify(loginUserLanguage));
      // console.log("loginUserLanguage222::",loginUserLanguage);            
    } else {
        // console.log("else:::");        
    }
      
    await this.loadCacheData();
    
    let datas;
    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      datas = organisationInfo?.data;
    } catch (error) { 
      console.log("error:", error);
    }

    // let rems = await this.addTranslationsForSQ( datas, this.$store?.getters?.loginTenantDetails?.languageAbility)
    // console.log("remsy:::",rems);

    let rems = datas;
    if (rems) {
      // Store rems in localStorage
      localStorage.setItem('remsCache', JSON.stringify(rems));

      // To retrieve rems later:
      let cachedRems = JSON.parse(localStorage.getItem('remsCache'));
      cachedRems;
      // console.log("Retrieved rems from cache:::🔥;))", cachedRems);

      rems;

      // let see = await this.patchMultiLanguageData(rems);

      // console.log("no iterate 👾👾::::::", rems, see);
      // console.log("see::::🥲🥲🥲___________::::",see);
    }

    const val = await MLTAxiosInstance.get('partymanagement/individual/auth');
    let sampleTheads = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );
    const mergedPayload = sampleTheads.data.permission;

    const transformOutputData = this.transformOutput(mergedPayload);

    const defaultSettings = await UPCAxiosInstance.get(`/customHierarchy`);
    if (defaultSettings.length > 0) {
      var mergedArray = [
        ...(defaultSettings?.data?.[0]?.module?.[0]?.main || []),
        ...(defaultSettings?.data?.[0]?.module?.[0]?.footer || []),
      ];
    } else {
      mergedArray = [
        ...(this.sideBarDataCopy?.main || []),
        ...(this.sideBarDataCopy?.footer || []),
      ];
    }
    this.sections = mergedArray;

    this.updateMyDataWithBossData(this.sections, transformOutputData);

    const fulldataSidebar = this.updateMyDataWithBossData(
      this.sections,
      transformOutputData
    );

    this.sideBarData = this.organizeSections(fulldataSidebar);

    // this.sideBarData = await this.translateNestedNames(this.sideBarData,
    // // 'fr',
    // loginUserLanguage[0]?.code
    // );

    console.log(this.sideBarData, 'sideBarDatasideBarData');

    this.isConsole(true);
    if (process.env.VUE_APP_ENV && process.env.VUE_APP_ENV == 'dev') {
      this.isConsole(true);
    }
    console.log(process.env.VUE_APP_ENV, process.env.VUE_APP_MODE, 'ENV');

    // this.$store.dispatch("initIntellisenceData");
    await this.$store.dispatch('initPermissionAnalayzer');
    await this.$store.dispatch('loadLifeCycleStatus');
    await this.$store.dispatch('loginTenantDetails');

    let loginTenantDetailsLocal = this.$store.getters.loginTenantDetails;
    localStorage.setItem(
      'loginTenantDetailsLocal',
      JSON.stringify(loginTenantDetailsLocal)
    );
    console.log('loginTenantDetailsLocal', loginTenantDetailsLocal);

    JSON.parse(localStorage.getItem('loginTenantDetailsLocal'));

    // let response;
    // try {
    //   let organisationInfo = await UPCAxiosInstance.get("/multi-language");
    //   response = organisationInfo;
    // } catch (error) {
    //   console.error("error:", error);
    // }

    console.log(
      'addTranslations:::',
      // datas?.data[0]?.modules,
      this.$store.getters.loginTenantDetails
    );

    // let rem = await this.addTranslations(
    //   response?.data[0]?.modules,
    //   this.$store.getters.loginTenantDetails?.languageAbility
    // );

    // console.log("rem::::::", rem);

    // response.data[0].modules = rem;

    // console.log("response::", response?.data[0]);

    // try {
    //   let organisationInfo1 = await UPCAxiosInstance.patch(
    //     "/multi-language",
    //     response?.data[0]
    //   );
    //   console.log("organisationInfo1::::", organisationInfo1);
    // } catch (error) {
    //   console.error("error:", error);
    // } commanded temp by asif

    // try{
    //   let datas = require(`@/language/defaultLanguage.js`).default;
    //   // console.log("language 111 datas",datas);

    //   let param = this.extractValues(datas);
    //   // console.log("language 111 param",param);

    //   const response = await translateTexts(param,"en");
    //   // console.log("language 111 response",response);

    //   //change the translated data into object
    //   if(response){
    //   let changedObj = this.applyTranslations(datas,response);
    //   // console.log("language 111 changedObj", changedObj);

    //   const translatedObjJSON = JSON.stringify(changedObj);
    //   localStorage.setItem('staticDataLanguage', translatedObjJSON);
    // }

    // }catch(err){
    //   console.log("err:",err);
    // }

    // await this.$store.dispatch('loadLifeCycleStatus')

    // await this.$store.dispatch('initPermissionAnalayzer');
    // if (
    //   this.$store.state.intellisenceData &&
    //   Object.keys(this.$store.state.intellisenceData).length == 0
    // ) {
    //   await this.$store.dispatch('initIntellisenceData');
    //   // console.log(
    //   //   '🚀 ~ file: App.vue:126 ~ mounted ~ this.$store.state.intellisenceData:',
    //   //   this.$store.state.intellisenceData
    //   // );
    // }
    // await this.$store.dispatch('loadUPCReferenceData')
    this.$nextTick(() => {
      this.checkForCollisions();
    });
    window.addEventListener('resize', this.checkForCollisions);

    // Get the countryList from localStorage
    // let countryList = localStorage.getItem("countryList");
    // console.log("storedCountryList countryList",countryList);

    //   if(countryList){
    //   let storedCountryList = JSON.parse(LZString?.decompress(countryList));
    //   console.log("storedCountryList...", storedCountryList);

    //   //check
    //   // if(!storedCountryList){
    //   //   console.log("storedCountryList map data is loading",storedCountryList);
    //   //   this.loadMapData();
    //   // }else{
    //   //   console.log("storedCountryList no need to call map data");

    //   // }

    //     //country
    //     let statedata = await this.loadcountry();

    //     let myCountry = await this.checkCountry();
    //     let localcuntry= localStorage.getItem("countryName");
    //     if(myCountry === localcuntry){
    //     if(statedata && storedCountryList){
    //       console.log("storedCountryList check",storedCountryList?.polygonPath?.length,statedata?.length);

    //   //check
    //     if(storedCountryList?.polygonPath?.length === statedata?.length){
    //       console.log("storedCountryList no need to call map data",storedCountryList,statedata,storedCountryList?.polygonPath?.length,statedata?.length);
    //     }else{
    //       console.log("storedCountryList map data is loading",storedCountryList,statedata);
    //     this.loadMapData();
    //     }
    //   }else{
    //     console.log("storedCountryList unable to match");
    //     this.loadMapData();

    //   }
    // }else{
    //   console.log("storedCountryList country not match");
    //   this.loadMapData();

    // }
    // }else{
    //   console.log("storedCountryList store is empty so load map");
    //   this.loadMapData();

    // }

    // this.fetchCoordinates("india");

    this.loadGoogleMapsScript().then(() => {
      // Initialize Google Maps components here
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      console.log(
        'AutocompleteService Loaded from autoaddress:',
        autocompleteService
      );
    });

    // await this.$store.dispatch("loginTenantDetails");
    console.log(
      'loginTenantDetails resrfsef:',
      this.$store?.getters?.loginTenantDetails?.languageAbility
    );
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkForCollisions);
  },
  updated() {
    // this.checkForCollisions()
  },
  created() {
    console.log('app is created');
    this.isAppReloaded = true;
    // setTimeout(() => {
    //   this.isAppReloaded = false
    // }, 3000);
  },
  // mounted(){    this.$router.push(`/DMN/dashboard`)},
  methods: {
  //   loadGoogleMapsScript() {
  //   // const mapKey = process.env.VUE_APP_MAP_KEY;
  //   console.log("loadGoogleMapsScript")
  //   const script = document.createElement('script');
  //   script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_KEY}&libraries=places`;
  //   script.async = true;
  //   document.head.appendChild(script);
  //   this.isCallGoogelApiCalled == true

  //   return new Promise((resolve) => {
  //     script.onload = () => {
  //       resolve();
  //     };
  //   });
    // },

  
    async loadCacheData() {

      let monthNames = [
        this.matchFromStatic("January"), this.matchFromStatic("February"), this.matchFromStatic("March"), this.matchFromStatic("April"), this.matchFromStatic("May"), this.matchFromStatic("June"), this.matchFromStatic("July"), this.matchFromStatic("August"), this.matchFromStatic("September"), this.matchFromStatic("October"), this.matchFromStatic("November"), this.matchFromStatic("December")
      ];

      // let languageAbility = JSON.parse(
      //       localStorage.getItem("userLanguageFromSetting")
      //     )
          
      // let monthNames1 = await this.languageTransferArray(monthNames, languageAbility[0]?.code);
   
      localStorage.setItem('monthDataLong', JSON.stringify(monthNames))

      let monthsShort = [
  this.matchFromStatic("Jan"), 
  this.matchFromStatic("Feb"), 
  this.matchFromStatic("Mar"), 
  this.matchFromStatic("Apr"), 
  this.matchFromStatic("May"), 
  this.matchFromStatic("Jun"), 
  this.matchFromStatic("Jul"), 
  this.matchFromStatic("Aug"), 
  this.matchFromStatic("Sep"), 
  this.matchFromStatic("Oct"), 
  this.matchFromStatic("Nov"), 
  this.matchFromStatic("Dec")
];


      // let monthsShort1 = await this.languageTransferArray(monthsShort, languageAbility[0]?.code);

      localStorage.setItem('monthDataShort', JSON.stringify(monthsShort))

      let days = {
  sun: this.matchFromStatic("SUN"),
  mon: this.matchFromStatic("MON"),
  tue: this.matchFromStatic("TUE"),
  wed: this.matchFromStatic("WED"),
  thu: this.matchFromStatic("THU"),
  fri: this.matchFromStatic("FRI"),
  sat: this.matchFromStatic("SAT")
};

      
      // let staticDataDays = await this.translateStaticDataDays(days, languageAbility[0]?.code)

      localStorage.setItem('dayDataShort', JSON.stringify(days))

      console.log("staticDataDays::@@::", days, "monthNames1::@@::", monthNames, "monthsShort1::@@::", monthsShort);
      
    },

    async translateNestedNames(data, targetLanguage) {
      // Your recursive translation logic here
      const translateRecursive = async (node) => {
        try {
          if (node.name) {
            node.name = await this.languageTransfer(node.name, targetLanguage);
          }
          if (node.child && Array.isArray(node.child)) {
            for (const child of node.child) {
              await translateRecursive(child);
            }
          }
        } catch (error) {
          console.error('Error in translation:', error);
          // Return the original node if any error occurs during translation
          return node;
        }
      };

      try {
        for (const item of data.main || []) {
          await translateRecursive.call(this, item); // Ensuring 'this' is bound
        }
        return data;
      } catch (error) {
        console.error('Error in translating data:', error);
        // Return the original data if any error occurs in the overall translation process
        return data;
      }
    },

    async patchMultiLanguageData(data) {
      try {
        // Validate input data
        if (!Array.isArray(data)) {
          throw new TypeError('Input data must be an array.');
        }

        let allSuccess = true; // Flag to track success

        for (const item of data) {
          try {
            // Validate individual item
            if (!item || typeof item !== 'object' || !item.id) {
              console.warn('Invalid item encountered, skipping:', item);
              allSuccess = false; // Mark as failed for invalid items
              continue; // Skip invalid items
            }

            const response = await UPCAxiosInstance.patch(
              '/multi-language',
              item
            );

            if (response?.status >= 200 && response?.status < 300) {
              // console.log(`Successfully patched item with ID ${item.id}:`, response.data);
            } else {
              console.error(
                `Failed to patch item with ID ${item.id}:`,
                response?.statusText || 'Unknown error'
              );
              allSuccess = false; // Mark as failed for unsuccessful responses
            }
          } catch (patchError) {
            console.error(
              `Error patching item with ID ${item.id || 'unknown'}:`,
              patchError
            );
            allSuccess = false; // Mark as failed for patch errors
          }
        }

        return allSuccess; // Return true if all successful, false otherwise
      } catch (error) {
        console.error('Unexpected error in patchMultiLanguageData:', error);
        return false; // Return false for unexpected errors
      }
    },
    organizeSections(data) {
      // Items to move to the footer section
      const footerNames = ['Help', 'Settings', 'Log out'];

      const footer = data.filter((item) =>
        footerNames.includes(item.customName)
      );
      const main = data.filter(
        (item) => !footerNames.includes(item.customName)
      );
      const fulldata = { main: main, footer: footer };
      return fulldata;
    },
    updateMyDataWithBossData(myData, bossData) {
      if (!myData) {
        return bossData || [];
      }

      function findMatchingBossItem(myItem, bossItems) {
        return bossItems.find(
          (bossItem) => bossItem.customName === myItem.customName
        );
      }

      function updateItem(myItem, bossItem) {
        if (bossItem) {
          myItem.view = bossItem.view;
          myItem.edit = bossItem.edit;
          myItem.delete = bossItem.delete;
          myItem.add = bossItem.add;
          myItem.all = bossItem.all;
          myItem.action = bossItem.action;
          myItem.partially = bossItem.partially;
          myItem.action = false;
          if (myItem.partially) {
            myItem.view = true;
          }
        } else {
          myItem.view = false;
          myItem.edit = false;
          myItem.delete = false;
          myItem.add = false;
          myItem.all = false;
          myItem.action = false;
          myItem.partially = false;
        }

        if (myItem.child) {
          for (let i = 0; i < myItem.child.length; i++) {
            const matchingBossChild = bossItem?.child
              ? findMatchingBossItem(myItem.child[i], bossItem.child)
              : null;
            updateItem(myItem.child[i], matchingBossChild);
          }
        }

        if (myItem.sections) {
          for (let i = 0; i < myItem.sections.length; i++) {
            const matchingBossSection = bossItem?.sections
              ? findMatchingBossItem(myItem.sections[i], bossItem.sections)
              : null;
            updateItem(myItem.sections[i], matchingBossSection);
          }
        }
      }

      for (let i = 0; i < myData.length; i++) {
        const matchingBossItem = bossData
          ? findMatchingBossItem(myData[i], bossData)
          : null;
        updateItem(myData[i], matchingBossItem);
      }

      return myData;
    },
    transformOutput(data) {
      return Object.entries(data).map(([key, value]) => {
        const transformed = {
          // Generate a unique ID if none exists
          name: key,
          label: key.toLowerCase(),
          view: value.view || false,
          edit: value.edit || false,
          delete: value.delete || false,
          add: value.add || false,
          all: value.all || false,
          partially: value.partially || false,

          active: false, // Default to false unless otherwise specified
          description: '', // Optional, add meaningful content if available
          customName: key,
        };

        if (value.child) {
          transformed.child = this.transformOutput(value.child);
        }

        if (value.sections) {
          transformed.sections = Object.entries(value.sections).map(
            ([sectionKey, sectionValue]) => ({
              name: sectionKey,
              value: sectionKey.toLowerCase(),
              view: sectionValue.view || false,
              edit: sectionValue.edit || false,
              delete: sectionValue.delete || false,
              add: sectionValue.add || false,
              all: sectionValue.all || false,
              partially: sectionValue.partially || false,
              customName: sectionKey,
            })
          );
        }

        return transformed;
      });
    },
    async addTranslations(data, languageAbility) {
      // Iterate through the languages in languageAbility
      for (const language of languageAbility) {
        const langCode = language.code;

        // Check and add translations for each key in the data
        for (const key in data) {
          let labels = data[key].label;

          // Ensure that labels is defined (if undefined, initialize it)
          if (!labels) {
            labels = data[key].label = {};
          }

          // If the language code doesn't exist in the labels, create it
          if (!labels[langCode]) {
            labels[langCode] = {};

            // Assume English as the base language for translation
            const englishContent = labels.en;

            // Translate each field in the English content asynchronously
            for (const field in englishContent) {
              const textToTranslate = englishContent[field];
              labels[langCode][field] = await this.languageTransfer(
                textToTranslate,
                langCode
              );
            }
          }
        }
      }

      return data;
    },
    extractValues(obj) {
      let valuesArray = [];

      function recurse(obj) {
        for (let key in obj) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            recurse(obj[key]);
          } else if (key === 'value') {
            valuesArray.push(obj[key]);
          }
        }
      }

      recurse(obj);
      return valuesArray;
    },

    // insertTranslatedTexts(obj, translatedTexts) {
    //   let index = 0;

    //   function recurse(obj) {
    //     for (let key in obj) {
    //       if (typeof obj[key] === 'object' && obj[key] !== null) {
    //         recurse(obj[key]);
    //       } else if (key === 'value') {
    //         obj[key] = translatedTexts[index];
    //         index++;
    //       }
    //     }
    //   }

    //   recurse(obj);
    // },

    applyTranslations(obj, translations) {
      let index = 0;

      function recurse(obj) {
        for (let key in obj) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            recurse(obj[key]);
          } else if (key === 'value') {
            obj[key] = translations[index++];
          }
        }
      }

      recurse(obj);
      return obj;
    },

    async countrysCall() {
      try {
        return await MLTAxiosInstance.get(`/countryInfo?fieldToFilter=name`);
      } catch (error) {
        console.error(error);
      }
    },
    async loadcountry() {
      let organisationInfo = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$cookies.get(
          'user'
        )}?company=${this.$cookies.get('company')}`
      );
      console.log(
        '🚀 ~ mounted ~ organisationInfo:',
        organisationInfo.data.contactMedium[1].characteristic.country
      );

      let countryList =
        organisationInfo.data.contactMedium[1].characteristic.country;

      // country
      let country = countryList;
      country;

      //call center
      let mapCenter = this.callCenter(country);
      mapCenter;

      // call country list data
      try {
        let payload = await this.countrysCall();
        let countryPayload = [];
        payload.data.forEach((item) => {
          if (item?.name) {
            item.value = item.name.common;
            countryPayload.push(item);
          }
        });
        // console.log("countryPayload...", countryPayload, payload);

        //filter our country
        const countryEmit = countryPayload.filter(
          (ctry) => ctry?.value.toLowerCase() === country?.toLowerCase()
        );
        // console.log("countryEmit...", countryEmit);

        //seperate into state
        const states = await this.countryValue(countryEmit);
        this.stateslist = states;
        console.log(
          'storedCountryList this.stateslist',
          states,
          this.stateslist
        );

        return states;
      } catch (error) {
        console.log('error...', error);
      }
    },
    async checkCountry() {
      let organisationInfo = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$cookies.get(
          'user'
        )}?company=${this.$cookies.get('company')}`
      );
      console.log(
        '🚀 ~ mounted ~ organisationInfo:',
        organisationInfo.data.contactMedium[1].characteristic.country
      );

      let countryList =
        organisationInfo.data.contactMedium[1].characteristic.country;
      return countryList || null;
    },
    async loadMapData() {
      let organisationInfo = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$cookies.get(
          'user'
        )}?company=${this.$cookies.get('company')}`
      );
      console.log(
        '🚀 ~ mounted ~ organisationInfo:',
        organisationInfo.data.contactMedium[1].characteristic.country
      );

      let countryList =
        organisationInfo.data.contactMedium[1].characteristic.country;

      localStorage.setItem('countryName', countryList);

      // country
      let country = countryList;
      country;

      //call center
      let mapCenter = this.callCenter(country);
      mapCenter;

      // call country list data
      try {
        let payload = await MLTAxiosInstance.get(
          `/countryInfo?fieldToFilter=name`
        );
        let countryPayload = [];
        payload.data.forEach((item) => {
          if (item?.name) {
            item.value = item.name.common;
            countryPayload.push(item);
          }
        });
        // console.log("countryPayload...", countryPayload, payload);

        //filter our country
        const countryEmit = countryPayload.filter(
          (ctry) => ctry?.value.toLowerCase() === country?.toLowerCase()
        );
        // console.log("countryEmit...", countryEmit);

        //seperate into state
        const states = await this.countryValue(countryEmit);
        states;

        //call polygon api for each state
        if (states) {
          states.forEach((state, ind) => {
            state.name;
            setTimeout(() => {
              // console.log("1....");
              // console.log("2....");

              this.fetchCoordinates(state.name);
            }, 1800 * ind);
          });
        }
      } catch (error) {
        console.error('error...', error);
      } finally {
        this.isLoading = true; // Set loading to false after data is fetched
      }
    },
    async callCenter(countryName) {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              address: countryName,
              key: process.env.VUE_APP_GOOGLE_MAP_KEY,
            },
          }
        );

        const results = response.data.results;
        console.log('results..', results);

        if (results.length > 0) {
          this.mapCenter = results[0].geometry.location;
          console.log('this.center...', this.center);
          return this.mapCenter;
        }
      } catch (error) {
        console.error('Error fetching country data:', error);
        return 'Error';
      }
    },
    async fetchCoordinates(query) {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search.php?q=${query}&polygon_geojson=1&format=jsonv2`
        );
        const data = response.data;
        // console.log("Fetched data:", data);
        this.processCoordinates(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    processCoordinates(data) {
      if (data[0]) {
        let arrayOfArray = [];
        const coordinates = data[0].geojson.coordinates;
        // let areaType = "";

        if (
          coordinates.length === 2 &&
          typeof coordinates[0] === 'number' &&
          typeof coordinates[1] === 'number'
        ) {
          arrayOfArray.push([{ lat: coordinates[1], lng: coordinates[0] }]);
          // console.log("Single coordinate:", coordinates);
          // areaType = "Single";
        } else if (
          Array.isArray(coordinates) &&
          coordinates.every(
            (coord) => Array.isArray(coord) && coord.length === 2
          )
        ) {
          arrayOfArray.push(coordinates.map(([lng, lat]) => ({ lat, lng })));
          // console.log("Array of coordinate pairs:", coordinates);
          // areaType = "Array";
        } else if (
          Array.isArray(coordinates) &&
          Array.isArray(coordinates[0]) &&
          coordinates[0].every(
            (coord) => Array.isArray(coord) && coord.length === 2
          )
        ) {
          coordinates.forEach((nestedCoords) => {
            arrayOfArray.push(nestedCoords.map(([lng, lat]) => ({ lat, lng })));
          });
          // console.log("Nested array of coordinate pairs:", coordinates);
          // areaType = "Nested";
        } else {
          coordinates.forEach((nestedCoords) => {
            arrayOfArray.push(
              nestedCoords[0].map(([lng, lat]) => ({ lat, lng }))
            );
          });
          // console.log("Multi-level nested array:", coordinates);
          // areaType = "MultiNested";
        }

        // console.log("areaType", areaType);
        // console.log("Processed polygon paths:", arrayOfArray);
        this.polygonPaths.push(arrayOfArray);

        this.mapCenter;
        this.polygonPaths;
        const obj = {};
        obj.polygonPath = this.polygonPaths;
        obj.mapCenter = this.mapCenter;
        // console.log("successfull true",obj);

        // Save the data to the store
        // this.$store.dispatch('setCountryList', obj);

        this.count = this.count + 1;

        obj.count = this.count;

        console.log('storedCountryList count', this.count);

        // let r = LZString.compress(JSON.stringify(obj))
        // console.log("r...123",r,obj);

        // Store the countryList in localStorage
        // localStorage.setItem("countryList", r);
        // let compressed = LZString.compress(JSON.stringify(largeData));
        // localStorage.setItem('compressedData', compressed);

        // }
      }
    },
    async countryValue(val) {
      // console.log(val, "ids");
      try {
        let payload = await MLTAxiosInstance.get(
          `/countryInfo/basedOnQuery?_id=${val[0]._id}`
        );
        // console.log("payload...", payload);

        const provinceStates = payload.data[0].states;
        // console.log("provinceStates...", provinceStates);

        return provinceStates;
      } catch (error) {
        console.log('error...', error);
        return null;
      }
    },
    checkForCollisions() {
      // setTimeout(() => {
      // Function to create a deep copy of an object
      function deepCopy(obj) {
        if (obj !== undefined) {
          return JSON.parse(JSON.stringify(obj));
        }
      }

      // Function to remove duplicates based on 'name' property and avoid proxies
      function removeDuplicates(array) {
        const uniqueNames = new Set();
        const uniqueArray = [];

        array.forEach((item) => {
          const itemCopy = deepCopy(item); // Create a deep copy to avoid proxies
          if (!uniqueNames.has(itemCopy?.name)) {
            uniqueNames.add(itemCopy?.name);
            uniqueArray.push(itemCopy);
          }
        });

        return uniqueArray;
      }
      const footerIcon = this.$refs['footer-0'][0]; // Assuming only one element in each ref array
      let mainIcons = Object.keys(this.$refs)
        .filter((ref) => ref.startsWith('main'))
        .map((ref) => this.$refs[ref][0]);

      console.log(footerIcon, mainIcons, 'collidI');
      mainIcons = mainIcons?.filter((i) => {
        return i !== undefined;
      });
      const lastMainIcon = mainIcons[mainIcons?.length - 1];

      if (footerIcon && lastMainIcon) {
        const footerIconRect = footerIcon.getBoundingClientRect();
        const lastMainIconRect = lastMainIcon.getBoundingClientRect();

        if (
          this.isColliding(footerIconRect, lastMainIconRect) &&
          this.sideBarData.main.length > 4
        ) {
          let filteredData = this.sideBarData.main.filter((items) => {
            return items.view === true;
          });

          if (filteredData[filteredData.length - 1].name === 'More') {
            this.moreIcons.child.unshift(filteredData[filteredData.length - 2]);
            this.moreIcons.child.unshift(filteredData[filteredData.length - 3]);
            this.moreIcons.child.unshift(filteredData[filteredData.length - 4]);
            this.moreIcons.child.unshift(filteredData[filteredData.length - 5]);
          } else {
            this.moreIcons.child.unshift(filteredData[filteredData.length - 1]);
            this.moreIcons.child.unshift(filteredData[filteredData.length - 2]);
            this.moreIcons.child.unshift(filteredData[filteredData.length - 3]);
            this.moreIcons.child.unshift(filteredData[filteredData.length - 4]);
          }
          // Function to remove duplicates based on 'name' property

          console.log('new log', this.moreIcons);
          // Update the child array to remove duplicates
          this.moreIcons.child = removeDuplicates(this.moreIcons.child);
          // Handle collision (e.g., hide the last main icon)
          // lastMainIcon.style.display = 'none';
          this.sideBarData.main = filteredData;
          console.log(this.sideBarData.main, 'old pop');
          if (filteredData[filteredData.length - 1].name === 'More') {
            this.sideBarData.main.pop();
            this.sideBarData.main.pop();
            this.sideBarData.main.pop();
            this.sideBarData.main.pop();
            this.sideBarData.main.pop();
          } else {
            this.sideBarData.main.pop();
            this.sideBarData.main.pop();
            this.sideBarData.main.pop();
            this.sideBarData.main.pop();
          }
          console.log(this.sideBarData.main, 'new pop');
          const obj = JSON.parse(JSON.stringify(this.moreIcons));
          const arr = JSON.parse(JSON.stringify(this.sideBarData.main));
          arr.push(obj);
          console.log(
            'Collide dhi',
            this.sideBarData.main,
            obj,
            arr,
            this.moreIcons
          );
          // if (this.sideBarData.main[this.sideBarData.main.length - 1].name !== "More") {
          this.sideBarData.main = [];
          this.sideBarData.main = JSON.parse(JSON.stringify(arr));
          console.log(
            'Collide dhi',
            this.sideBarData.main,
            obj,
            arr,
            this.moreIcons
          );
          // }
          // this.sideBarData.main[this.sideBarData.main.length - 1] = this.moreIcons
          // this.sideBarData.main.pop()
          // this.sideBarData.main.push(this.moreIcons)
          // this.checkForCollisions()
        } else {
          // Calculate and log the gap
          const gap = footerIconRect.top - lastMainIconRect.bottom;
          console.log('Gap between last main icon and footer icon:', gap);
          console.log('Not collide', gap, this.moreIcons);
          if (gap > 150) {
            if (
              this.sideBarData.main[this.sideBarData.main?.length - 1].child
                ?.length > 0 &&
              this.sideBarData.main[this.sideBarData.main.length - 1].name ===
                'More'
            ) {
              let removedObject =
                this.sideBarData.main[
                  this.sideBarData.main.length - 1
                ].child.shift(); // removes and returns the first element
              this.moreIcons.child.shift(); // removes and returns the first element
              this.sideBarData.main.splice(
                this.sideBarData.main.length - 1,
                0,
                removedObject
              );
              if (
                this.sideBarData.main[this.sideBarData.main?.length - 1].child
                  ?.length === 0 &&
                this.sideBarData.main[this.sideBarData.main.length - 1].name ===
                  'More'
              ) {
                console.log('kkk', this.sideBarData.main);
                this.sideBarData.main = this.sideBarData.main.filter((i) => {
                  return i.name !== 'More';
                });
                // this.sideBarData.main.pop()
                // console.log('kkk', this.sideBarData.main)
              }
            }
          }
          // lastMainIcon.style.display = '';
        }
      }
      // }, 2500);
    },
    isColliding(footer, top) {
      console.log(footer.top == top.bottom, 'collide3');
      return footer.top === top.bottom;
    },
    applyViewValues(payload1, payload2) {
      for (const mainItem of payload2.main) {
        // console.log("🚀 ~ applyViewValues ~ mainItem:", mainItem);
        // const mainItemId = mainItem.id.toString();
        const viewValue = payload1[mainItem.name];
        if (viewValue !== undefined) {
          mainItem.view = viewValue == 'true';
        }
        if (mainItem.child) {
          for (const childItem of mainItem.child) {
            // const childItemId = childItem.id.toString();
            const childViewValue = payload1[childItem.name];
            if (childViewValue !== undefined) {
              childItem.view = childViewValue == 'true';
            }
          }
        }
      }
      for (const mainItem of payload2.footer) {
        // const mainItemId = mainItem.id.toString();
        const viewValue = payload1[mainItem.name];
        if (viewValue !== undefined) {
          mainItem.view = viewValue == 'true';
        }
        if (mainItem.child) {
          for (const childItem of mainItem.child) {
            // const childItemId = childItem.id.toString();
            const childViewValue = payload1[childItem.name];
            if (childViewValue !== undefined) {
              childItem.view = childViewValue == 'true';
            }
          }
        }
      }
    },
    navTo(active) {
      this.$store.commit('setTempActiveSideBarItem', active.name);
      if (active.name == 'logout') {
        this.logout();
      } else if (active.name == 'Dashboard') {
        this.$router.push(`/DMN/dashboard`);
      } else if (active.name == 'Schema') {
        this.schema();
      } else if (active.name == 'Help') {
        window.open('https://halleyx-documentation-ghubgwxrcq-uc.a.run.app');
        // this.userGuide()
      } else if (active.name == 'Rule studio') {
        this.newApp();
      } else if (active.name === 'Database') {
        this.execute();
      } else if (active.name == 'Execute') {
        this.exe();
      } else if (active.name == 'setting') {
        this.$router.push(`/settings`);
      } else if (active.name == 'Audit') {
        this.$router.push(`/DMN/audit`);
      }
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    // logout() {
    //   this.$router.push("/");
    // },

    schema() {
      this.$router.push(`/DMN/schemas`);
    },
    userGuide() {
      this.$router.push(`/DMN/help`);
    },
    newApp() {
      this.$router.push({ name: 'business-rules', query: { Value: 'All' } });
    },

    navSetting() {
      this.$router.push(`/settings`);
    },
    keepElementInView(element) {
      if (!element || !element.getBoundingClientRect) return;

      const rect = element.getBoundingClientRect();
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;

      if (rect.bottom > viewportHeight) {
        let overflowAmount = rect.bottom - viewportHeight;
        overflowAmount = Math.ceil(overflowAmount);
        if (overflowAmount % 2 !== 0) {
          overflowAmount += 1;
        }

        // Store the moved state on the element
        element.dataset.moved = 'true';
        element.style.transform = `translateY(-${overflowAmount}px)`;
        console.log(
          `The element was moved up by ${overflowAmount}px to stay within the viewport.`
        );
      }
    },
    hoverChildItem(e) {
      console.log(e.target, 'hovered');

      // Ensure the DOM is updated before accessing elements
      this.$nextTick(() => {
        let targetElement = null;

        if (
          e.target.classList.contains('icon-container') &&
          e.target.parentElement?.children[2]?.children !== undefined
        ) {
          targetElement = e.target.parentElement.children[2].children[0];
        } else if (
          e.target.classList.contains('icon-more-vertical-regular') &&
          e.target.parentElement.children[2]?.children !== undefined
        ) {
          targetElement = e.target.parentElement.children[2].children[0];
        } else if (
          e.target.parentElement.parentElement?.children[2]?.children !==
          undefined
        ) {
          targetElement =
            e.target.parentElement.parentElement.children[2].children[0];
        }

        if (targetElement) {
          targetElement.style.display = 'flex';
          this.keepElementInView(targetElement);
        }
      });
    },

    blurChildItem(e) {
      console.log('blurred');
      if (e.target.parentElement?.children[0]?.children[2]?.children[0]) {
        e.target.parentElement.children[0].children[2].children[0].style.display =
          'none';
      }
    },
    hoverSubChildItem(e) {
      if (e.target.children[2]) {
        e.target.children[2].style.display = 'flex';
        e.target.children[2].style.top = e.target.offsetTop + 'px';
        this.keepElementInView(e.target.children[2]);
      }
    },
    blurSubChildItem(e) {
      if (e.target.children[2]) {
        e.target.children[2].style.display = 'none';
      }
    },
    newGotoFunc(e, navItem, child) {
      // console.log(e.target?.classList[0] === 'icon-angle-right-regular');
      this.$store.commit('setTempActiveSideBarItem', navItem);
      if (child === 'child' || child === 'subchild') {
        if (
          e.target?.classList[0]?.includes('icon') &&
          e.target?.classList[0] !== 'icon-angle-right-regular'
        ) {
          e.target.parentElement.parentElement.parentElement.parentElement.style.display =
            'none';
        } else if (e.target?.classList[0]?.includes('child')) {
          e.target.parentElement.parentElement.style.display = 'none';
        } else {
          {
            e.target.parentElement.parentElement.parentElement.style.display =
              'none';
          }
        }
      }

      if (navItem.name === 'Help') {
        window.open('https://halleyx-documentation-ghubgwxrcq-uc.a.run.app');
      } else {
        console.log('🚀 ~ newGotoFunc ~ navItem.link:', navItem.link);
        this.$router.push(navItem.link);
        //   if (navItem.id !== undefined) {
        //   this.setActiveById(this.sideBarData, navItem.id);
        // }
      }
    },
    setActiveById(payload, id) {
      // Find and update the active state recursively
      const findAndSetActive = (items, parent) => {
        for (const item of items) {
          if (item.id === id) {
            item.active = true;
            if (parent) {
              parent.active = true;
              this.setActiveParent(payload, id);
            }
          } else {
            item.active = false;
          }
          if (item.child) {
            findAndSetActive(item.child, item);
          }
        }
      };

      findAndSetActive([...payload.main, ...payload.footer], null);
    },

    setActiveParent(payload, id) {
      console.log(id);
      // Find the parent items recursively and set them to active
      const findAndSetActive = (items, childId) => {
        for (const item of items) {
          if (item.child && item.child.some((child) => child.id === childId)) {
            item.active = true;
            if (item.id !== childId) {
              const parent = this.findParent(payload, item.id);
              if (parent) {
                parent.active = true;
                findAndSetActive(
                  [...payload.main, ...payload.footer],
                  parent.id
                );
              }
            }
            return;
          }
          if (item.child) {
            findAndSetActive(item.child, childId);
          }
        }
      };

      findAndSetActive([...payload.main, ...payload.footer], id);
    },

    findParent(payload, id) {
      // Find the parent of the item with the given id
      const findParentRecursive = (items) => {
        for (const item of items) {
          if (item.child && item.child.some((child) => child.id === id)) {
            return item;
          }
          if (item.child) {
            const parent = findParentRecursive(item.child);
            if (parent) {
              return parent;
            }
          }
        }
        return null;
      };

      return findParentRecursive(payload.main);
    },

    findItemByLink(link) {
      console.log(link, 'item');

      const findItemRecursive = (items) => {
        let bestMatch = null;

        const checkItem = (item) => {
          if (this.isAppReloaded) {
            if (link.includes(item.link)) {
              if (!bestMatch || item.link.length > bestMatch.link.length) {
                bestMatch = item;
              }
            }
          } else {
            if (item.link === link) {
              return item;
            }
          }
          if (item.child) {
            for (const child of item.child) {
              checkItem(child);
            }
          }
        };

        for (const item of items) {
          checkItem(item);
        }

        return bestMatch;
      };

      return findItemRecursive([
        ...this.sideBarData?.main,
        ...this.sideBarData?.footer,
      ]);
    },
  },
};
</script>
<style>
#app {
  font-family: var(--hlx-font-type-content);
}
</style>
