<template>
  <!-- :style="singleColumnLayout == true && (isScreenSizeSmall || rendenredInModalWindow == 'form-container') ? 'grid-template-columns: repeat(3, 1fr)' :'grid-template-columns: repeat(6, 1fr)' " -->
  <!-- isScreenSizeSmall || rendenredInModalWindow
    ? 'form-container'
    : 'form-container-small' -->
  <!-- {{ this.companyName }} -->
  <div :class="getContainerClassName() || form - container">
    <template v-for="(field, key) in schema">
      <!-- Dynamic Field Rendering -->
      <div
        v-if="
          field.show &&
          field?.others?.isVisible &&
          (field.displayType === 'input' ||
            field.displayType === 'suiterun' ||
            field.displayType === 'password' ||
            field.displayType === 'email' ||
            field.displayType === 'date' ||
            field.displayType === 'number' ||
            field.displayType === 'currency' ||
            field.displayType === 'textarea' ||
            field.displayType === 'dropdown' ||
            field.displayType === 'multiselect' ||
            field.displayType === 'texteditor' ||
            field.displayType === 'header' ||
            field.displayType === 'formula' ||
            field.displayType === 'address' ||
            field.displayType === 'checkbox' ||
            field.displayType === 'switch' ||
            field.displayType === 'Basic-suite run' ||
            field.displayType === 'Advance-suite run' ||
            field.displayType === 'profile-image-upload' ||
            field.displayType === 'profile-regular' ||
            
            field.displayType === 'telephone' ||
            field.displayType === 'phonenumber' ||
            field.displayType === 'radio' ||
            field.displayType == 'siteaddress' ||
            field.displayType === 'url' ||
            field.displayType === 'user' ||
            field.displayType === 'lookup' ||
            field.displayType === 'label' ||
            field.displayType === 'label-date' ||
            field.displayType == 'status' ||
            field.displayType == 'singleinput')
        "
        :key="key"
        :class="getFieldClass(field)"
        :style="{
          display: field.displayType === 'profile-regular' ? 'block' : 'flex',
          width: '100%',
        }"
      >
        <!-- label field => key: value -->

        <div
          v-if="field.displayType === 'label'"
          class="label-field"
        >
          <div class="key">{{ getLabel(field, key) }}:</div>
          <div class="value">{{ field.value }}</div>
        </div>
        <div
          v-if="field.displayType === 'label-date'"
          class="label-field"
        >
          <div class="key">{{ getLabel(field, key) }}:</div>
          <div class="value">{{ dateAndTimeFormatter(field.value) }}</div>
        </div>

        <!-- Suite run component -->
        <suiterun
        v-if="field.displayType === 'Basic-suite run'"
          :suite-run-prop="field.value"
          :module="'Basic-SuiteRun'"
          @suite-run-em="emitSuiteRun($event,field,key)"
        />
        <suiterun
        v-if="field.displayType === 'Advance-suite run'"
          :suite-run-prop="field.value"
          :module="'advance-SuiteRun'"
          @suite-run-em="emitSuiteRun($event,field,key)"
        />

        <hlx-file-upload
          v-if="field.displayType === 'profile-regular'"
          :file-type="'.jpg,.jpeg,.png,.json,.xlsx,.doc,.pdf'"
          :file-size-limit="'20mb'"
          :custom-icon="icon"
          :type="'regular'"
          :regular-file-name="field?.value?.name"
          :read-only="field.others.readOnly"
          :content="'Import'"
          @native-data="fileUpload($event, key)"
          @delete-image="deleteProfilePicture($event, field, key)"
        />

        <!-- lookup field -->
        <lookupComponent
          v-if="field.displayType === 'lookup'"
          v-model:value="field.value"
          :label-value="getLabel(field, key)"
          :filter-payload="field.others.lookup.filterPayload"
          :url="field.others.lookup.url"
          :method="field.others.lookup.method"
          :type="field.others.lookup.type"
          :pre-val="
            typeof field.value === 'object'
              ? field?.value?.name || ''
              : field?.value || ''
          "
          :module="field.others.lookup.module"
          :min-char-to-search="field.others.lookup.minCharToSearch"
          :max-result-to-display="field.others.lookup.maxResultToDisplay"
          :read-only="field.others.readOnly"
          :required="field.required"
          :custom-error="schema[field.fieldName].others.showValidation"
          :custom-error-message="
            schema[field.fieldName].others.validationMessage != undefined
              ? schema[field.fieldName].others.validationMessage[
                  selectedLanguageCode
                ]
              : ''
          "
          :instance="field.others.lookup.instance"
          :lookup-prop="field.others.lookup.lookupProp"
          :field-type="field.others.lookup.fieldType"
          @selected-value="
            changeValueForObject(
              field.displayType,
              $event,
              key,
              typeof field.value
            )
          "
          @focus-out="hasRuleTrigger($event, field)"
        ></lookupComponent>

        <!-- Address component for lead -->
        <!-- :style="singleColumnLayout == true ? 'margin-top:20px': ''" -->
        <span
          v-if="field.displayType === 'siteaddress'"
          style="width: 100%"
        >
          <leadAddressComponent
            v-if="field.displayType == 'siteaddress'"
            :key="updateAddressComponent"
            :force-emit="!isPropsSentForAddressComp"
            :address-label="getLabel(field, key)"
            :states-array="serviceQualificationEnabledStates"
            :type="
              field?.others?.addressAPI?.type
                ? field?.others?.addressAPI?.type
                : 'Canada post API'
            "
            :api-key="'MX79-UR85-KJ99-MA52'"
            :new-coordinate="{ lat: field?.Latitude, lng: field?.Longitude }"
            :read-only="field.others.readOnly"
            :disable-country="field.others.disableCountry"
            :pre-val="{
              characteristic: characteristicpreval?.[0]?.characteristic,
            }"
            :is-service-qualification-enabled="
              field.others.isServiceQualificationEnabled
            "
            :custom-error="schema[field.fieldName].others.showValidation"
            :custom-error-message="
              schema[field.fieldName].others.validationMessage !== undefined
                ? typeof schema[field.fieldName].others.validationMessage ===
                  'string'
                  ? schema[field.fieldName].others.validationMessage
                  : schema[field.fieldName].others.validationMessage[
                      selectedLanguageCode
                    ] || ''
                : ''
            "
            @address-data="
              receivedAddressData(
                field.displayType,
                $event,
                key,
                typeof field.value,
                field
              )
            "
            @geopoint="geopoint"
            @focus-out="
              receivedAddressData(
                field.displayType,
                $event,
                key,
                typeof field.value,
                field,
                'focusOut'
              )
            "
            @mount-emit="
              receivedAddressDataWithoutEmit(
                field.displayType,
                $event,
                key,
                typeof field.value,
                field
              )
            "
          />
          <!-- @address-selected="onAddressSelected($event, field)" -->
        </span>

        <!-- Profile upload Fields -->
        <div
          v-if="field.displayType === 'profile-image-upload'"
          class="profile-picture-component"
        >
          <hlx-file-upload
            :file-type="'.jpg,.jpeg,.png'"
            :file-size-limit="'20mb'"
            :uploadtype="'square'"
            :style-background="backgrounStyleupload"
            :height="93"
            :width="93"
            :custom-icon="icon"
            :type="'profile-upload'"
            :name-display="field?.others?.profileName || ''"
            :profile-pic-preview="
              profilePicUrlBufferData
                ? profilePicUrlBufferData
                : field?.others?.profilePicUrl || ''
            "
            :read-only="field.others.readOnly"
            :content="'Import'"
            @native-data="profileUpload($event, key)"
            @delete-image="deleteProfilePicture($event, field, key)"
          />
        </div>

        <!-- Formula Fields -->

        <hlx-code-editor
          v-if="field.displayType === 'formula'"
          v-model:value="field.value"
          :intellisense-data="intellisenseData"
          :pre-value="field.value || ''"
          :line-number="true"
          :custom-error="schema[field.fieldName].others.showValidation"
          :custom-error-message="
            schema[field.fieldName].others.validationMessage != undefined
              ? schema[field.fieldName].others.validationMessage[
                  selectedLanguageCode
                ]
              : ''
          "
          :rows="3"
          :read-only="field.others.readOnly"
          @focus-out="hasRuleTrigger($event, field)"
        >
        </hlx-code-editor>
        <!-- Input Fields -->

        <h4
          v-if="field.displayType === 'header'"
          style="margin-bottom: 20px"
        >
          {{ getLabel(field, key) }}
        </h4>
        <hlx-select
          v-if="field.displayType === 'user'"
          :v-model:value="
            typeof field.value === 'object' ? field?.value?.value : field?.value
          "
          :pre-value="
            typeof field.value === 'object' ? field?.value?.value : field?.value
          "
          :options="field.options"
          :placeholder-value="getLabel(field, key)"
          :label="'name'"
          :prop-value="'name'"
          :required="field.required"
          :label-animation="true"
          :search-image-tag="true"
          :clearable="false"
          :sub-title="'company'"
          :disabled="field.others.readOnly"
          :display-error="true"
          :tag-value="'newRole'"
          :inline-search="true"
          @selected-value="
            changeValueForObject(
              field.displayType,
              $event,
              key,
              typeof field.value
            ),
              hasRuleTrigger($event, field)
          "
        >
          <!-- :custom-error="this.schema[field.fieldName].others.showValidation"
        :custom-error-message="this.schema[field.fieldName].others.validationMessage != undefined ?this.schema[field.fieldName].others.validationMessage[selectedLanguageCode] : ''" -->
          <!-- :image-link="'link'" -->
        </hlx-select>

        <hlx-input
          v-if="field.displayType === 'input'"
          :id="key"
          v-model:value="field.value"
          :required="field.required"
          :pre-val="field.value || ''"
          :type="field.type == 'String' || 'text' ? 'text' : 'number'"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :display-error="true"
          :custom-error="schema[field.fieldName].others.showValidation"
          :custom-error-message="
            schema[field.fieldName].others.validationMessage !== undefined
              ? typeof schema[field.fieldName].others.validationMessage ===
                'string'
                ? schema[field.fieldName].others.validationMessage
                : schema[field.fieldName].others.validationMessage[
                    selectedLanguageCode
                  ] || ''
              : ''
          "
          :read-only="field.others.readOnly"
          @focus-out="
            triggerValidationForInput($event, field),
              hasRuleTrigger($event, field)
          "
        />

        <div
          v-if="field.displayType === 'singleinput'"
          style="display: flex"
        >
          <span>{{ getLabel(field, key) + ': ' }}</span>
          <hlx-input
            :id="key"
            v-model:value="field.value"
            :required="field.required"
            :pre-val="field.value || ''"
            :type="field.type == 'String' || 'text' ? 'text' : 'number'"
            :label-value="getLabel(field, key)"
            :label-animation="true"
            :single-border="true"
            :display-error="true"
            :custom-error="schema[field.fieldName].others.showValidation"
            :custom-error-message="
              schema[field.fieldName].others.validationMessage !== undefined
                ? typeof schema[field.fieldName].others.validationMessage ===
                  'string'
                  ? schema[field.fieldName].others.validationMessage
                  : schema[field.fieldName].others.validationMessage[
                      selectedLanguageCode
                    ] || ''
                : ''
            "
            :read-only="field.others.readOnly"
            @focus-out="
              triggerValidationForInput($event, field),
                hasRuleTrigger($event, field)
            "
          />
        </div>

        <hlx-input
          v-if="field.displayType === 'password'"
          v-model:value="field.value"
          :label-animation="true"
          type="password"
          :required="field.required"
          :password-condition="
            field?.others?.passwordErrorValidation?.showPasswordCondition
          "
          :label-value="getLabel(field, key)"
          :label-value-objpass="staticPasswordValidation"
          :clearable="false"
          :display-error="true"
          :custom-error="field?.others.passwordErrorValidation?.display"
          :custom-error-message="field?.others.passwordErrorValidation?.content"
          :read-only="field.others.readOnly"
          @password-validation="emitValidation($event, field.labels.en.label)"
          @focus-out="hasRuleTrigger($event, field)"
        />

        <hlx-input
          v-if="field.displayType === 'email'"
          :id="key"
          v-model:value="field.value"
          :required="field.required"
          :pre-val="field.value || ''"
          :type="field.displayType"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :display-error="true"
          :custom-error="schema[field.fieldName].others.showValidation"
          :custom-error-message="
            schema[field.fieldName].others.validationMessage != undefined
              ? schema[field.fieldName].others.validationMessage[
                  selectedLanguageCode
                ]
              : ''
          "
          :read-only="field.others.readOnly"
          @focus-out="hasRuleTrigger($event, field)"
          @at-input="validateEmail"
        />

        <!-- <hlx-input
      v-if="field.displayType === 'telephone'"
      :id="key"
      v-model:value="field.value"
      :required="field.required"
      :pre-val="field.value || ''"
      :type="'telephone'"
       :display-error="true"
      :label-value="getLabel(field, key)"
      :label-animation="true"
    /> -->
        <hlx-input
          v-if="field.displayType === 'telephone'"
          :id="key"
          v-model:value="field.value"
          :placeholder-value="'Telephone number'"
          :required="field.required"
          :pre-val="field?.value?.value || ''"
          :display-error="true"
          :country-code="field?.value?.country || country"
          :type="'phonenumber'"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :custom-error="schema[field.fieldName].others.showValidation"
          :custom-error-message="
            schema[field.fieldName].others.validationMessage != undefined
              ? schema[field.fieldName].others.validationMessage[
                  selectedLanguageCode
                ]
              : ''
          "
          :country-label-value="
            field.others.countryLabel[selectedLanguageCode].label || 'Country'
          "
          :read-only="field.others.readOnly"
          @focus-out="hasRuleTrigger($event, field)"
        />

        <hlx-input
          v-if="field.displayType === 'phonenumber'"
          :id="key"
          v-model:value="field.value"
          :required="field.required"
          :pre-val="field?.value?.value || ''"
          :display-error="true"
          :country-code="field?.value?.country || country"
          :type="'phonenumber'"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :custom-error="schema[field.fieldName].others.showValidation"
          :custom-error-message="
            schema[field.fieldName].others.validationMessage != undefined
              ? schema[field.fieldName].others.validationMessage[
                  selectedLanguageCode
                ]
              : ''
          "
          :country-label-value="'Country'"
          :read-only="field.others.readOnly"
          @focus-out="hasRuleTrigger($event, field)"
        />

        <hlx-input
          v-if="field.displayType === 'url'"
          :id="key"
          v-model:value="field.value"
          :required="field.required"
          :display-error="true"
          :pre-val="field.value || ''"
          :type="field.type == 'String' || 'text' ? 'text' : 'number'"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :custom-error="
            schema[field.fieldName].others.showValidation || isUrlInvalid
          "
          :custom-error-message="
            schema[field.fieldName].others.validationMessage != undefined
              ? schema[field.fieldName].others.validationMessage[
                  selectedLanguageCode
                ]
              : '' || 'Please enter a valid URL in the format: www.abc.com'
          "
          :read-only="field.others.readOnly"
          @focus-out="
            callWebsiteRegex(field?.value ? field?.value : ''),
              hasRuleTrigger($event, field)
          "
        />

        <!-- Input Fields for Date -->
        <!-- dsfegrthujkl -->
        <hlx-input
          v-if="field.displayType === 'date'"
          :id="key"
          v-model:value="field.value"
          :pre-val="
            prePopulateDate(field.value, field.others.businessDatePreference)
          "
          :type="'date'"
          :date-format-language="selectedLanguageCode"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :single-border="false"
          :date-format="currentDateFormat"
          :clearable="false"
          icon="calendar-regular"
          icon-position="right"
          :required="field.required"
          :holiday-list="getHolidayDates(field.others.customDatePreference)"
          :disable-weekend="
            isDateWeekendDisabled(field.others.businessDatePreference)
          "
          :start-year="2000"
          :display-error="true"
          :custom-error="schema[field.fieldName].others.showValidation"
          :custom-error-message="
            schema[field.fieldName].others.validationMessage != undefined
              ? schema[field.fieldName].others.validationMessage[
                  selectedLanguageCode
                ]
              : ''
          "
          :end-year="2200"
          :read-only="field.others.readOnly"
          @at-input="focusOutDate($event, key)"
          @focus-out="focusOutDate($event, key), hasRuleTrigger($event, field)"
        />
        <!-- Input Fields -->

        <hlx-input
          v-if="field.displayType === 'number'"
          :id="key"
          v-model:value="field.value"
          :pre-val="field.value || ''"
          :type="'number'"
          :required="field.required"
          :label-value="getLabel(field, key)"
          :step="'1'"
          :round-off="true"
          :label-animation="true"
          :display-error="true"
          :custom-error="schema[field.fieldName].others.showValidation"
          :custom-error-message="
            schema[field.fieldName].others.validationMessage != undefined
              ? schema[field.fieldName].others.validationMessage[
                  selectedLanguageCode
                ]
              : ''
          "
          :min-limit="0"
          :read-only="field.others.readOnly"
          @focus-out="hasRuleTrigger($event, field)"
        />
        <!-- Input Fields -->
        <hlx-input
          v-if="field.displayType === 'currency'"
          :id="key"
          v-model:value="formData[key]"
          :pre-val="0"
          :required="field.required"
          :type="'number'"
          :custom-error="schema[field.fieldName].others.showValidation"
          :custom-error-message="
            schema[field.fieldName].others.validationMessage != undefined
              ? schema[field.fieldName].others.validationMessage[
                  selectedLanguageCode
                ]
              : ''
          "
          :symbol="$store?.getters?.getDefaultSettings?.currency?.symbol"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :read-only="field.others.readOnly"
          @focus-out="hasRuleTrigger($event, field)"
        />

        <!-- Textarea -->

        <hlx-input
          v-if="field.displayType === 'textarea'"
          :id="key"
          v-model:value="field.value"
          :pre-val="field.value != undefined ? formatTextAreaValue(field) : ''"
          :type="field.dataType || 'textarea'"
          :rows="6"
          :required="field.required"
          :label-value="getLabel(field, key)"
          :custom-error="schema[field.fieldName].others.showValidation"
          :custom-error-message="
            schema[field.fieldName].others.validationMessage != undefined
              ? schema[field.fieldName].others.validationMessage[
                  selectedLanguageCode
                ]
              : ''
          "
          :label-animation="true"
          :display-error="true"
          :read-only="field.others.readOnly"
          @focus-out="hasRuleTrigger($event, field)"
        />

        <!-- Dropdown -->
        <!-- {{selectedLanguageName.toLowerCase()}} -->
        <!-- {{field.value}} -->
        <!-- {{field.options}} -->
        <hlx-select
          v-if="field.displayType === 'dropdown'"
          :v-model:value="
            typeof field.value === 'object' ? field?.value?.value : field?.value
          "
          :pre-value="
            typeof field.value === 'object' ? field?.value?.value : field?.value
          "
          :inline-search="true"
          :options="field.options || []"
          :clearable="false"
          :placeholder-value="getLabel(field, key)"
          :prop-value="'name'"
          :required="field.required"
          :label="selectedLanguageName.toLowerCase()"
          :label-animation="true"
          :display-error="true"
          :disabled="field.others.readOnly"
          @selected-value="
            changeValueForObject(
              field.displayType,
              $event,
              key,
              typeof field.value
            ),
              hasRuleTrigger($event, field)
          "
        ></hlx-select>
        <!-- :custom-error="this.schema[field.fieldName].others.showValidation"
        :custom-error-message="this.schema[field.fieldName].others.validationMessage != undefined ?this.schema[field.fieldName].others.validationMessage[selectedLanguageCode] : ''" -->
        <!-- <hlx-select
          v-if="
            field.displayType === 'dropdown' 
          "
          :v-model:value="field?.value"
          :pre-value="field?.value"
          :inline-search="true"
          :options="field.options || []"
          :required="field.required"
          :clearable="true"
          :placeholder-value="getLabel(field, key)"
          :prop-value="'name'"
          :label="selectedLanguageName"
          :label-animation="true"
          @selected-value="changeValueForString($event, key)"
        ></hlx-select> -->

        <!-- Multi select -->
        <!-- <hlx-select
          v-if="
            field.displayType === 'multiselect' && typeof field.value === 'object'
          "
          :v-model:value="field?.value?.name"
          :pre-value="field?.value?.name"
          :inline-search="true"
          :options="field.options || []"
          :clearable="true"
          :placeholder-value="getLabel(field, key)"
          :prop-value="'name'"
          :multi-select="true"
          :required="field.required"
          :label="'name'"
          :label-animation="true"
          @selected-value="changeValueForObject($event, key)"
        ></hlx-select> -->
        <span
          v-if="field.displayType === 'multiselect'"
          style="padding-bottom: 22px; width: 100%"
        >
          <hlx-multi-select
            v-if="field.displayType === 'multiselect'"
            :key="updateMultiselectKey"
            :pre-value="field.values || []"
            :options="field.options"
            :prop-value="'name'"
            :placeholder="getLabel(field, key)"
            :label-animation="true"
            :clearable="false"
            :tooltipposition="'left'"
            :required="field.required"
            :disabled="field.others.readOnly"
            :custom-error="schema[field.fieldName].others.showValidation"
            :custom-error-message="
              schema[field.fieldName].others.validationMessage != undefined
                ? schema[field.fieldName].others.validationMessage[
                    selectedLanguageCode
                  ]
                : ''
            "
            :label="'name'"
            :display-error="true"
            @add-data="addTagList($event, key)"
            @selected-array="
              changeValueForString($event, key), hasRuleTrigger($event, field)
            "
          ></hlx-multi-select>
        </span>

        <!-- Radio Buttons -->
        <div
          v-if="field.displayType === 'radio'"
          style="display: flex; flex-direction: column; gap: 10px"
        >
          <span v-if="field.others.label"
            >{{ getLabel(field, key) }}
            <span
              v-if="field.required"
              style="color: #ff5050"
              >*</span
            ></span
          >
          <hlx-input
            v-if="field.displayType === 'radio'"
            :id="key"
            v-model:value="field.value"
            :pre-val="field.value"
            :prop-value="selectedLanguageName.toLowerCase()"
            :read-only="true"
            :position="field.others.position"
            :type="'radio-buttons'"
            :display-error="true"
            :options="field.options"
            @selected="hasRuleTrigger($event, field)"
          />
          <br />
        </div>

        <!-- checkbox Buttons -->
        <!-- <span v-if="field.displayType === 'checkbox'">
          {{ field.options }}
        </span> -->
        <div v-if="field.displayType === 'checkbox'">
          <hlx-input
            :id="key"
            v-model:value="field.value"
            :prop-value="'name'"
            :read-only="field.others.readOnly"
            :type="'checkbox'"
            :options="field.options"
            :display-error="true"
            @selected="hasRuleTrigger($event, field)"
          />
          <br />
        </div>
        <hlx-input
          v-if="field.displayType === 'switch'"
          :id="key"
          v-model:value="formData[key]"
          :prop-value="'name'"
          :read-only="field.others.readOnly"
          :type="'switch'"
          :display-error="true"
          @selected="hasRuleTrigger($event, field)"
        />

        <!-- Address -->
        <!-- <addressComponent
          v-if="field.displayType === 'address'"
        ></addressComponent> -->

        <!-- texteditor -->
        <hlx-text-editor
          v-if="field.displayType === 'texteditor'"
          v-model:modelValue="field.value"
          :height="textEditorheight"
          :read-only="field.others.readOnly"
          @current-change="texteditorValue"
          @focus-out="hasRuleTrigger($event, field)"
        />

        <!-- Status component -->
        <div
          v-if="field.displayType === 'status'"
          class="status-component"
        >
          <hlx-status
            :type="'select-status'"
            :prevalue="field?.value"
            :readonly="field.others.readOnly"
            :required="field.required"
            :label="selectedLanguageName.toLowerCase()"
            :placeholder-value="getLabel(field, key)"
            :status-option="field.options || []"
            @emit-select="
              changeValueForStatus($event, key), hasRuleTrigger($event, field)
            "
          />
        </div>
        <div
          v-if="
            field.isTooltip && field.labels[selectedLanguageCode].tooltip !== ''
          "
          :data-tooltip="field.labels[selectedLanguageCode].tooltip"
          data-tooltip-location="left"
          style="top: 10px; height: max-content"
        >
          <i class="icon-info-circle-regular"></i>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from '@/config/axiosConfig';
import diff from 'deep-diff';
// import addressComponent from '@/components/CM/autocompleteComponent.vue';
import leadAddressComponent from '@/views/Lead/leadAddressComponent.vue';
import debounce from 'lodash/debounce';
import lookupComponent from '@/components/CustomFields/lookupComponent.vue';
import suiterun from '@/views/contractManagement/suiteRuin.vue';

export default {
  components: {
    // addressComponent,
    leadAddressComponent,
    lookupComponent,
    suiterun,
  },
  props: {
    completePayload: {
      type: Object,
      default: () => {
        return null;
      },
    },
    customLanguage: {
      type: Object,
      default: () => {
        return null;
      },
    },
    updateAddressKey: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    gridFieldMargin: {
      type: Number,
      default: 0,
    },
    readOnly: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    triggerRule: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    addressDataProps: {
      type: Array,
      default: () => {
        return [];
      },
    },
    emailDataProps: {
      type: Array,
      default: () => {
        return [];
      },
    },
    updatedSchemaAfterRemap: {
      type: Object,
      default: () => {
        return {};
      },
    },
    serviceQualificationEnabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    editMode: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    layout: {
      type: String,
      default: '2-column',
    },
    textEditorheight: {
      type: String,
      default: '315px',
    },
    mixedColumnLayout: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    schemaPayload: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isModalWindow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    parentClassName: {
      type: String,
      required: true,
    },
    dataPayload: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isSaveClicked: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isAddClicked: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    checkValidation: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    statusList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    module: {
      type: Object,
      default: () => {
        return { name: 'saleslead', code: 'mdu' };
      },
    },
  },
  emits: [
    'rule-triggered',
    'is-mounted',
    'updated-schema',
    'edited-payload',
    'profile-buffer-data',
    'profile-name',
    'lookup-data',
    'lookup-emit',
    'password-validate',
    'input-focus-out',
  ],
  data() {
    return {
      suiteRunData: {
        toggle: true,
        unitsPerFloor: 2,
        fileName: '',
        numFloors: 1,
        numUnits: 2,
        unitData: [
          {
            floor: 'floor 1',
            units: ['0001', '0002'],
          },
        ],
      },
      transitionFiredCountFromMount: 0,
      updateAddressComponent: 0,
      serviceQualificationEnabledStates: [],
      isPropsSentForAddressComp: true,
      fsaCodeData: [
        { country: 'Canada', format: 'ANA NAN', fsaLength: 3 },
        {
          country: 'United States',
          format: '12345 or 12345-6789',
          fsaLength: 3,
        },
        {
          country: 'United Kingdom',
          format: 'A9 9AA or AA9A 9AA',
          fsaLength: 4,
        }, // Outward code max length is 4
        { country: 'Australia', format: '1234', fsaLength: 2 },
        { country: 'India', format: '123456', fsaLength: 3 },
        { country: 'Germany', format: '12345', fsaLength: 2 },
        { country: 'France', format: '12345', fsaLength: 2 },
        { country: 'Italy', format: '12345', fsaLength: 1 },
        { country: 'Japan', format: '123-4567', fsaLength: 3 },
        { country: 'Netherlands', format: '1234 AB', fsaLength: 4 },
        { country: 'China', format: '123456', fsaLength: 2 },
        { country: 'Brazil', format: '12345-678', fsaLength: 5 },
        { country: 'Russia', format: '123456', fsaLength: 3 },
        { country: 'South Africa', format: '1234', fsaLength: 4 },
        { country: 'Mexico', format: '12345', fsaLength: 1 },
        { country: 'Argentina', format: '1234', fsaLength: 4 },
      ],
      companyName: '',
      isSiteAddressAlreadyExited: false,
      staticPasswordValidation: {
        Password_must_contain: this.matchFromStatic('Password must contain'),
        characters_long: this.matchFromStatic('A minimum of 8 characters long'),
        lower_case_letter: this.matchFromStatic(
          'A minimum of 1 lower case letter [a-z]'
        ),
        upper_case_letter: this.matchFromStatic(
          'A minimum of 1 upper case letter [A-Z]'
        ),
        numeric_character: this.matchFromStatic(
          'A minimum of 1 numeric character [0-9]'
        ),
        special_character: this.matchFromStatic(
          'A minimum of 1 special character [:~!]'
        ),
      },
      currentDateFormat: 'mmm dd, yyyy',
      isRemapData: false,
      dupSchema: null,
      isFuncOver: false,
      dataPayloadForRemap: null,
      temporaryDataPayload: null,
      updateMultiselectKey: 0,
      backgrounStyleupload: {
        backgroundColor: '',
        bacgroundtTextColor: '#A8A8A8',
        border: false,
        borderColor: '#A8A8A8',
        TextfontSize: 16,
      },
      profilePicUrlBufferData: '',
      rendenredInModalWindow: false,
      intellisenseData: {
        schema: [],
        api: [{ label: 'API()' }],
        email: [{ label: 'Email()' }],
        keywords: [
          { label: 'if' },
          { label: 'then' },
          { label: 'else' },
          { label: 'and' },
          { label: 'or' },
          { label: 'function' },
          { label: 'filter' },
          { label: 'reduce' },
          { label: 'map' },
          { label: 'Date' },
          { label: 'Math' },
          { label: 'slice' },
          { label: 'substr' },
          { label: 'concat' },
          { label: 'true' },
          { label: 'false' },
        ],
        methods: {
          array: [
            {
              label: 'filter',
              syntax:
                'array.filter(function(currentValue, index, arr), thisValue)',
            },
            {
              label: 'reduce',
              syntax:
                'array.reduce(function(total, currentValue, currentIndex, arr), initialValue)',
            },
            {
              label: 'map',
              syntax:
                'array.map(function(currentValue, index, arr), thisValue)',
            },
          ],
          date: [
            {
              label: 'getDay',
              syntax: 'getDay()',
            },
            {
              label: 'getDate',
              syntax: 'getDate()',
            },
            {
              label: 'getTime',
              syntax: 'getTime()',
            },
            {
              label: 'getMonth',
              syntax: 'getMonth()',
            },
            {
              label: 'getFullYear',
              syntax: 'getFullYear()',
            },
            {
              label: 'setDate',
              syntax: 'setDate()',
            },
            {
              label: 'setTime',
              syntax: 'setTime()',
            },
            {
              label: 'setMonth',
              syntax: 'setMonth()',
            },
            {
              label: 'setFullYear',
              syntax: 'setFullYear()',
            },
          ],
          integer: [
            {
              label: 'random',
              syntax: 'Math.random()',
            },
            {
              label: 'round',
              syntax: 'Math.round(x)',
            },
          ],
          string: [
            { label: 'charAt', syntax: 'string.charAt(index)' },
            { label: 'slice', syntax: 'string.slice(start, end)' },
          ],
        },
      },
      country: {
        name: this.$store.getters.defaultSetting?.country,
        short: this.$store.getters.defaultSetting?.cca2,
        flags: this.$store.getters.defaultSetting?.flags?.svg,
      },
      addressSelected: false,
      characteristicpreval: [
        {
          mediumType: 'postalAddress',
          characteristic: {
            street: '',
            '@type': '',
            city: '',
            country: '',
            default: false,
            description: '',
            numberOfUnits: '',
            postalCode: '',
            postalCodePrefix: '',
            fsa: '',
            state: '',
            stateOrProvince: '',
            street1: '',
            street2: '',
            unit: '',
            latitude: '',
            longitude: '',
          },
        },
      ],
      isUrlInvalid: false,
      userLanguage: 'en',
      orginalDataPayload: null,
      copyOfPayload: null,
      // selected user language code
      selectedLanguageCode: 'en',
      selectedLanguageName: 'english',

      // Dynamically updated form data
      formData: {},

      // Schema definition
      schema: {},
      // {
      //   name: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'input',
      //     isRef: false,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Company name',
      //         name: 'English',
      //         tooltip: 'name',
      //       },
      //     },
      //     optionRef: '',
      //     required: true,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   status: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: false,
      //     isTooltip: false,
      //     labels: {
      //       en: {
      //         label: 'Status',
      //         name: 'English',
      //         tooltip: 'status',
      //       },
      //     },
      //     optionRef: '',
      //     required: true,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   marketingCampaign: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: false,
      //     labels: {
      //       en: {
      //         label: 'Lead source',
      //         name: 'English',
      //         tooltip: 'LeadSource',
      //       },
      //     },
      //     optionRef: 'LeadSource',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   type: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: false,
      //     labels: {
      //       en: {
      //         label: 'Lead type',
      //         name: 'English',
      //         tooltip: 'type',
      //       },
      //     },
      //     optionRef: 'LeadType',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   id: {
      //     type: 'String',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'id',
      //         name: 'English',
      //         tooltip: 'id',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   dwellingtype: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: false,
      //     isTooltip: false,
      //     labels: {
      //       en: {
      //         label: 'Dwelling type',
      //         name: 'English',
      //         tooltip: 'dwellingtype',
      //       },
      //     },
      //     optionRef: 'LeadDwellingType',
      //     required: true,
      //     section: ['overview'],
      //     show: true,
      //   },

      //   category: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Category',
      //         name: 'English',
      //         tooltip: 'category',
      //       },
      //     },
      //     optionRef: 'LeadCategory',
      //     required: true,
      //     section: ['overview'],
      //     show: true,
      //   },

      //   marketSegment: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Market segment',
      //         name: 'English',
      //         tooltip: 'marketSegment',
      //       },
      //     },
      //     optionRef: 'LeadMarketSegment',
      //     required: true,
      //     section: ['overview'],
      //     show: true,
      //   },

      //   referredDate: {
      //     type: 'Date',
      //     system: true,
      //     displayType: 'date',
      //     isRef: false,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Onboarding date',
      //         name: 'English',
      //         tooltip: 'onboardingData',
      //       },
      //     },
      //     optionRef: '',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   displayID: {
      //     type: 'String',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'displayID',
      //         name: 'English',
      //         tooltip: 'displayID',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   description: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'description',
      //     isRef: false,
      //     isTooltip: false,
      //     labels: {
      //       en: {
      //         label: 'Description',
      //         name: 'English',
      //         tooltip: 'description',
      //       },
      //     },
      //     optionRef: '',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   creationDate: {
      //     type: 'Date',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'creationDate',
      //         name: 'English',
      //         tooltip: 'creationDate',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   lastUpdate: {
      //     type: 'Date',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'lastUpdate',
      //         name: 'English',
      //         tooltip: 'lastUpdate',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   estimatedRevenue: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'estimatedRevenue',
      //         name: 'English',
      //         tooltip: 'estimatedRevenue',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   href: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'input',
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Website url',
      //         name: 'English',
      //         tooltip: 'websiteurl',
      //       },
      //     },
      //     optionRef: '',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   priority: {
      //     type: 'String',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'priority',
      //         name: 'English',
      //         tooltip: 'priority',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   rating: {
      //     type: 'String',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'rating',
      //         name: 'English',
      //         tooltip: 'rating',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   statusChangeReason: {
      //     type: 'String',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'statusChangeReason',
      //         name: 'English',
      //         tooltip: 'statusChangeReason',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   statusChangeDate: {
      //     type: 'Date',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'statusChangeDate',
      //         name: 'English',
      //         tooltip: 'statusChangeDate',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   channel: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Deal category',
      //         name: 'English',
      //         tooltip: 'leadDealCategory',
      //       },
      //     },
      //     optionRef: 'LeadDealCategory',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   validFor: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'validFor',
      //         name: 'English',
      //         tooltip: 'validFor',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   contactMedium: {
      //     type: 'Array',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'contactMedium',
      //         name: 'English',
      //         tooltip: 'contactMedium',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   note: {
      //     type: 'Array',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'note',
      //         name: 'English',
      //         tooltip: 'note',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   relatedParty: {
      //     type: 'Array',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'relatedParty',
      //         name: 'English',
      //         tooltip: 'relatedParty',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   productOffering: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Tags',
      //         name: 'English',
      //         tooltip: 'tags',
      //       },
      //     },
      //     optionRef: '',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   salesOpportunity: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'salesOpportunity',
      //         name: 'English',
      //         tooltip: 'salesOpportunity',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   productSpecification: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'productSpecification',
      //         name: 'English',
      //         tooltip: 'productSpecification',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   createdBy: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Lead owner',
      //         name: 'English',
      //         tooltip: 'leadOwner',
      //       },
      //     },
      //     optionRef: 'LeadOwner',
      //     required: true,
      //     section: [],
      //     show: true,
      //   },
      //   _id: {
      //     type: 'ObjectID',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: '_id',
      //         name: 'English',
      //         tooltip: '_id',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   sitesNumber: {},
      //   siteDrop: {},
      //   maxRecursive: {},
      // },
      //   {
      //     firstName: {
      //       type: 'String',
      //       displayType: 'input',
      //       labelEnglish: 'First Name',
      //       show: true,
      //       section: ['overview'],
      //     },
      //     lastName: {
      //       type: 'String',
      //       displayType: 'input',
      //       labelEnglish: 'Last Name',
      //       show: true,
      //       section: ['overview'],
      //     },
      //     description: {
      //       type: 'String',
      //       displayType: 'textarea',
      //       labelEnglish: 'Description',
      //       show: true,
      //       section: ['details'],
      //     },
      //     gender: {
      //       labelEnglish: 'Gender',
      //       type: 'String',
      //       displayType: 'radio',
      //       options: [
      //         { value: 'male', label: 'Male', checked: false },
      //         { value: 'female', label: 'Female', checked: false },
      //         { value: 'other', label: 'Other', checked: false },
      //       ],
      //       show: true,
      //     },
      //     category: {
      //       type: 'Array',
      //       displayType: 'dropdown',
      //       labelEnglish: 'Category',
      //       options: [
      //         { value: 'male', label: 'Male', checked: false },
      //         { value: 'female', label: 'Female', checked: false },
      //         { value: 'other', label: 'Other', checked: false },
      //       ],
      //       show: true,
      //       section: ['details'],
      //     },
      //     gender2: {
      //       labelEnglish: 'Gender 2',
      //       type: 'String',
      //       displayType: 'checkbox',
      //       options: [
      //         { value: 'male', label: 'Male', checked: false },
      //         { value: 'female', label: 'Female', checked: false },
      //         { value: 'other', label: 'Other', checked: false },
      //       ],
      //       show: true,
      //     },
      //     field1: {
      //       type: 'String',
      //       displayType: 'input',
      //       labelEnglish: 'Field 1',
      //       show: false,
      //       section: ['overview'],
      //     },
      //     field3: {
      //       type: 'String',
      //       displayType: 'address',
      //       labelEnglish: 'Field 3',
      //       show: true,
      //       section: ['overview'],
      //     },

      //     field2: {
      //       type: 'String',
      //       displayType: 'input',
      //       labelEnglish: 'String',
      //       show: true,
      //       section: ['overview'],
      //     },
      //     field4: {
      //       type: 'String',
      //       displayType: 'currency',
      //       labelEnglish: 'Currency',
      //       show: true,
      //       symbol: '$',
      //       section: ['overview'],
      //     },
      //     field5: {
      //       type: 'String',
      //       displayType: 'number',
      //       labelEnglish: 'Number',
      //       show: true,
      //       section: ['overview'],
      //     },
      //     field6: {
      //       type: 'String',
      //       displayType: 'texteditor',
      //       labelEnglish: 'Number',
      //       show: true,
      //       section: ['overview'],
      //     },
      //   },
      originalSchema: {},
      updadatedSchema: {},
      emailData: [],
      addressData: [],
    };
  },
  watch: {
    updateAddressKey: {
      handler(val) {
        if (val) {
          console.log('updateAddressKey', val);
          console.log('updateAddressKey', val);
          this.updateAddressComponent += 1;
          this.characteristicpreval[0].characteristic = {
            street: '',
            '@type': '',
            city: '',
            country: '',
            default: false,
            description: '',
            numberOfUnits: '',
            postalCode: '',
            postalCodePrefix: '',
            fsa: '',
            state: '',
            stateOrProvince: '',
            street1: '',
            street2: '',
            unit: '',
            latitude: '',
            longitude: '',
          };
        }
      },
      deep: true,
      immediate: true,
    },
    readOnly: {
      handler(val) {
        if (val == true) {
          Object.keys(this.schema).forEach((key) => {
            if (this.schema[key]?.others) {
              this.schema[key].others.readOnly = true;
            }
          });
        }
        if (val == false) {
          console.log(val, 'readOnly in custom');
          Object.keys(this.schema).forEach((key) => {
            if (this.schema[key]?.others) {
              this.schema[key].others.readOnly = false;
            }
          });
        }
      },
    },
    emailDataProps: {
      handler(val) {
        this.emailData = JSON.parse(JSON.stringify(val));
        // console.log('emailData from props', val ,  this.emailData );
      },
      deep: true,
      immediate: true,
    },
    addressDataProps: {
      handler(val) {
        this.addressData = JSON.parse(JSON.stringify(val));
        // console.log('emailData from props addressData', val);
      },
      deep: true,
      immediate: true,
    },
    // parentClassName:{
    //   handler(val) {
    //     const parentElement = document.querySelector(`.${val}`);
    //   console.log('this.parentClassName',val, parentElement);
    //   if (parentElement) {
    //     this.isScreenSizeSmall = parentElement.offsetWidth > '500';
    //     console.log('Parent width:', parentElement.offsetWidth);
    //   }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    isModalWindow: {
      handler(val) {
        this.$nextTick(() => {
          this.handleResize();
        });
        this.handleResize();
        this.getParentWidth();
        this.rendenredInModalWindow = val;
        // console.log(this.rendenredInModalWindow, 'isModalWindow', val);
      },
      deep: true,
      immediate: true,
    },
    isAddClicked: {
      handler(val) {
        if (val == true) {
          // console.log(val);
          // this.mapSchemaToData(this.orginalDataPayload, this.schema);
        }
      },
      deep: true,
      immediate: true,
    },
    updatedSchemaAfterRemap: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.handleResize();
          this.getParentWidth();
          // console.log(
          //   this.orginalDataPayload,
          //   'updatedSchemaAfterRemap',
          //   val,
          //   'this.copyOfPayload ',
          //   this.copyOfPayload
          // );
          this.mapSchemaToData(this.orginalDataPayload, val);
          this.$nextTick(() => {
            this.handleResize();
          });
        }
      },
      deep: true,
      immediate: true,
    },
    isSaveClicked: {
      handler(val) {
        if (val == true) {
          // console.log(val);
          // this.mapSchemaToData(this.orginalDataPayload, this.schema);
        }
      },
      deep: true,
      immediate: true,
    },
    triggerRule: {
      async handler(val) {
        console.log(this.schema, 'triggerRule', val);
        if (val && typeof val === 'object' && !Array.isArray(val)) {
          Object.keys(val).forEach((key) => {
            if (key == 'siteAddress') {
              console.log(this.schema, 'triggerRule inside the loop', val);
              this.isSiteAddressAlreadyExited = val[key];
            }
          });
        }
        if (
          val == true &&
          this.schema &&
          Object.keys(this.schema).length > 0 &&
          (this.emailData.length != 0 || this.addressData != 0)
        ) {
          console.log(val);
          // this.mapSchemaToData(this.orginalDataPayload, this.schema);
          const data = {
            payload: {
              data: this.schema,
              emailArrayData: this.emailData,
              addressArrayData: this.addressData,
            },
          };
          const visRule = await DMNAxiosInstance.post(
            'rule_engine/rule/execute/676e5426ca476dc1b987dfca',
            data
          );
          visRule;
          // console.log("Trigger rule again",visRule)
        }
        // if((val == 'siteAddress' || val == 'sfdcId' || val == 'networkId') && this.schema && Object.keys(this.schema).length > 0)
        // {
        //   // console.log("fieldRender ",this.schema)
        //   this.schema.val.others.showValidation = true
        //   this.schema.val.others.validationMessage  =  this.matchFromStatic('Site address already exists')
        //     // this.schema.siteAddress.others.validationMessage  = {
        //     //   en: "Site address already exists",
        //     //   fr: "Site address already exists french",
        //     //   ta: "Site address already exists tamil",
        //     //   hi: "Site address already exists hindi"
        //     // }
        //     // console.log("fieldRender ",this.schema)
        // }
        // if(val == 'nosites' && this.schema && Object.keys(this.schema).length > 0)
        // {
        //   // console.log("fieldRender ",this.schema)
        //   this.schema.siteAddress.others.showValidation = false
        //   // this.schema.siteAddress.others.validationMessage  =  this.matchFromStatic('Site address already exists')
        //     // console.log("fieldRender ",this.schema)
        // }
        const validationMessage = this.matchFromStatic(
          'Site address already exists'
        );
        Object.keys(val).forEach((key) => {
          if (val[key] && this.schema[key]?.others) {
            console.log(this.schema[key], 'valkey', val[key], key);
            this.schema[key].others.showValidation = val[key];
            this.schema[key].others.validationMessage = validationMessage;
            this.isSiteAddressAlreadyExited = val[key];
          } else if (this.schema[key]?.others) {
            this.schema[key].others.showValidation = false;
            this.isSiteAddressAlreadyExited = false;
          }
        });
        // if (this.schema?.['siteAddress']?.others?.showValidation) {
        // }
        // if (!this.schema?.['siteAddress']?.others?.showValidation) {
        // }
      },
      deep: true,
      immediate: true,
    },
    schemaPayload: {
      async handler(val) {
        await this.handleResize();
        await this.getParentWidth();
        // console.log('dataPayload schema', val);
        if (val && this.isFuncOver == false) {
          this.schema = { ...val };
          this.processFields(val);
          // console.log('this.schema ', this.schema);
        }
        this.$nextTick(() => {
          this.handleResize();
        });
      },
      deep: true,
      immediate: true,
    },
    schema: {
      handler: debounce(async function (oldval, val) {
        try {
          if (val) {
            console.log(oldval, 'oldval', val);

            if (
              typeof this.triggerRule === 'object' &&
              this.isPropsSentForAddressComp == false
            ) {
              console.log('this.triggerRule', this.triggerRule);

              // Validation message to match
              const validationMessage = this.matchFromStatic(
                'Site address already exists'
              );

              // Iterate through the keys in this.triggerRule
              Object.keys(this.triggerRule).forEach((key) => {
                if (this.schema[key]?.others) {
                  // Set showValidation to true for the keys in triggerRule that are true
                  if (!this.schema[key].others.showValidation) {
                    this.schema[key].others.showValidation =
                      this.triggerRule[key];

                    // Update validationMessage if showValidation is true
                    this.isSiteAddressAlreadyExited = this.triggerRule[key];
                    if (this.triggerRule[key]) {
                      this.schema[key].others.validationMessage =
                        validationMessage;
                      if (this.schema[key].displayType == 'siteAddress') {
                        this.isSiteAddressAlreadyExited = this.triggerRule[key];
                      }
                      // if (this.schema[key].displayType == 'siteAddress') {
                      //   this.isSiteAddressAlreadyExited = true;
                      // }
                    }
                  }
                }
              });

              // Set showValidation to false for all other keys in this.schema
              Object.keys(this.schema).forEach((key) => {
                if (!this.triggerRule[key] && this.schema[key]?.others) {
                  if (!this.schema[key].others.showValidation) {
                    this.schema[key].others.showValidation = false;
                  }
                }
              });
              // if (this.schema?.['siteAddress']?.others?.showValidation) {
              // }
              // if (!this.schema?.['siteAddress']?.others?.showValidation) {
              // }
            }
            console.log(
              JSON.parse(JSON.stringify(val)),
              'this.originalSchema',
              this.originalSchema
            );
            // this.selectedLanguageCode =
            //   this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;
            // this.selectedLanguageName =
            //   this.$store?.getters?.loginUserDetails?.languageAbility[0]?.name?.toLowerCase() ||
            //   'name';
            // console.log(
            //   JSON.parse(JSON.stringify(val)),
            //   'this.originalSchema',
            //   this.originalSchema
            // );
            this.originalSchema = JSON.parse(JSON.stringify(val));
            if (this.orginalDataPayload) {
              console.log(':sch', this.orginalDataPayload);
              // console.log(this.originalSchema,this.orginalDataPayload,"this.mapSchemaToData(",val)
              // let time = new Date().getTime();
              await this.mapSchemaToData(this.orginalDataPayload, val);
            }
            // Check val have displayType as status and field others contains transition rule
            // if (val && Object.keys(val).length > 0) {
            //   console.log("🚀 ~ val:sch", val);
            //   const field = Object.keys(val).find(
            //     (key) =>
            //       val[key].displayType === "status" &&
            //       val[key].others &&
            //       val[key].others.transitionRule &&
            //       val[key].others.transitionRule.isRuleEnabled
            //   );
            //   let fieldO = null;
            //   if (oldval) {
            //     fieldO = Object.keys(oldval).find(
            //       (key) =>
            //         oldval[key].displayType === "status" &&
            //         oldval[key].others &&
            //         oldval[key].others.transitionRule &&
            //         oldval[key].others.transitionRule.isRuleEnabled
            //     );
            //   }
            //   if (field && val[field] && val[field].value) {
            //     console.log(
            //       "🚀 ~ fieldO",
            //       fieldO,
            //       oldval[fieldO],
            //       oldval[fieldO].value,
            //       oldval[fieldO].value == val[field].value
            //     );
            //     if (
            //       fieldO &&
            //       oldval[fieldO] &&
            //       oldval[fieldO].value &&
            //       oldval[fieldO].value == val[field].value
            //     ) {
            //       // this.schema[field].options = [];
            //       return;
            //     }
            //     let transitionRule = await this.handleTransitionRuleTrigger(
            //       val[field].others.transitionRule,
            //       val[field].value,
            //       this.completePayload
            //     );
            //     if (transitionRule.length > 0) {
            //       console.log("🚀 ~ transitionRule:", transitionRule);
            //       this.schema[field].options = transitionRule;
            //     }
            //   }
            // }
          }
        } catch (error) {
          console.log(error, 'Error');
        }
        this.$nextTick(() => {
          this.handleResize();
        });
      }, 1000), // Adjust debounce delay as needed
      deep: true,
      immediate: true,
    },
    dataPayload: {
      handler(val) {
        // console.log(this.isRemapData, 'dataPayloaddataPayload', val);
        if (val && this.schema) {
          this.orginalDataPayload = JSON.parse(JSON.stringify(val));
          this.copyOfPayload = JSON.parse(JSON.stringify(val));
        }
        this.$nextTick(() => {
          this.handleResize();
        });
      },
      deep: true,
      immediate: true,
    },
    '$store.getters.getDefaultSettings.dateFormat': {
      handler(val) {
        this.currentDateFormat = this.getDateFormat(val)?.toLowerCase();
        // console.log('currentDateFormat', this.getDateFormat('hh:mm').toLowerCase());
        // console.log('currentDateFormat', this.getDateFormat('YYYY MMM').toLowerCase());
        // console.log('currentDateFormat', this.getDateFormat('ddd, MMM DD, YYYY hh:mm AM/PM').toLowerCase());
        // console.log('currentDateFormat', this.getDateFormat('hh:mm:ss AM/PM').toLowerCase());
        // console.log('currentDateFormat', this.getDateFormat('ddd, MMM DD, YYYY').toLowerCase());
        // console.log('currentDateFormat', this.getDateFormat('MM/DD/YYYY').toLowerCase());
        // console.log('currentDateFormat', this.getDateFormat('MMM DD, YYYY hh:mm AM/PM').toLowerCase());
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    // const value = await MLTAxiosInstance.get('partymanagement/individual/auth');
    this.companyName = this.$cookies.get('company');
    this.isFuncOver = false;
    let emailFilter = {
      type: 'filter',
      module: 'contacts',
      isPaginated: false,
      paginatedQuery: {
        rowsPerPage: 1000,
        currentPage: 1,
      },
      isNeedToBeSort: false,
      sortFieldAndOrder: {},
      limit: 1000,
      filterQuery: {
        '@baseType': 'customer',
      },
      searchQuery: '',
      company: this.companyName,
    };
    let data = await MLTAxiosInstance.post('util/filterData', emailFilter);
    data = data.data.data;
    data;
    // console.log(data, 'dataa');

    // const emailAddresses = data.map(
    //   (item) => item.contactMedium[0].characteristic.emailAddress
    // );
    // this.emailData = emailAddresses;
    // const addresses = data.map(
    //   (item) => item.contactMedium[2].characteristic.description
    // );
    // this.addressData = addresses;
    this.getParentWidth();
    this.updateMultiselectKey += 1;
    await this.$store.dispatch('loginUserDetails');
    // Initialize form data keys with default values
    Object.keys(this.schema).forEach((key) => {
      this.formData[key] = this.schema[key].default || '';
    });
    window.addEventListener('resize', this.getParentWidth);
    window.addEventListener('click', this.getParentWidth);
    await this.getIntellisenseData();
    // console.log(
    //   this.$store?.getters?.loginUserDetails?.languageAbility[0],
    //   'lang'
    // );
    this.selectedLanguageCode =
      this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;
    this.selectedLanguageName =
      this.$store?.getters?.loginUserDetails?.languageAbility[0]?.name?.toLowerCase() ||
      'name';
    if (
      this.customLanguage != undefined &&
      this.customLanguage != null &&
      Object.keys(this.customLanguage).length != 0
    ) {
      this.selectedLanguageCode = this.customLanguage.code;
      this.selectedLanguageName = this.customLanguage.name;
    }
    console.log('this.customLanguage', this.customLanguage);
    if (this.c) window.addEventListener('resize', this.handleResize);
    this.$nextTick(() => {
      this.handleResize();
    });
    this.originalSchema = JSON.parse(JSON.stringify(this.schema));
    this.$nextTick(() => {
      this.$emit('is-mounted', true);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.getParentWidth);
    window.removeEventListener('click', this.getParentWidth);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    emitSuiteRun(emitValue, field, key) {
      console.log(key, field, 'emitValue', emitValue);
      this.schema[key].value = emitValue;
    },
    async handleTransitionRuleTrigger(rule, status, payload) {
      try {
        const ruleResponse = await UPCAxiosInstance({
          // baseURL: "http://localhost:5700",
          url: '/transitionRule/execute',
          method: 'POST',
          data: {
            status: status,
            context: { [this.module.name]: payload },
            ruleId: rule.ruleId,
          },
        });
        if (
          ruleResponse.data &&
          ruleResponse.data.ok &&
          ruleResponse.data.data &&
          Array.isArray(ruleResponse.data.data) &&
          ruleResponse.data.data.length > 0
        ) {
          return ruleResponse.data.data.map((item) => {
            return { ...item, color: item.color };
          });
        }
        return [];
      } catch (error) {
        return [];
      }
    },
    getFSA(postalCode, country) {
      const countryData = this.fsaCodeData.find(
        (entry) => entry.country.toLowerCase() === country.toLowerCase()
      );

      if (!countryData) {
        return ``;
      }

      const { fsaLength } = countryData;

      // Extract the FSA based on the defined length
      const fsa = postalCode
        .replace(/[^A-Za-z0-9]/g, '')
        .substring(0, fsaLength);

      return fsa || ``;
    },
    async hasRuleTrigger(e, field, fieldType) {
      if (fieldType != 'siteAddress') {
        await this.mapSchemaToData(this.orginalDataPayload, this.schema);
      }
      console.log(
        'onhasRuleTrigger',
        field,
        field.others.isValidationRuleEnabled ||
          field.others.hasDependentVisibilityRule,
        !field.others.readOnly
      );
      if (
        (field.others.isValidationRuleEnabled ||
          field.others.hasDependentVisibilityRule) &&
        !field.others.readOnly
      ) {
        if (field.others.isValidationRuleEnabled) {
          field.others.validateCurrentField = true;
        }
        await this.visibilityValidationRule();
      }
      // Check for assignment rule and transition rule trigger
      if (
        this.completePayload &&
        Object.keys(this.completePayload).length > 0 &&
        this.module &&
        this.module.name
      ) {
        if (
          (field.others.assignmentRule &&
            field.others.assignmentRule.length > 0) ||
          (field.others.transitionRule &&
            field.others.transitionRule.length > 0)
        ) {
          // this.$emit('rule-triggered', field);
          // Call assignment rule execution
          try {
            field.others.assignmentRule.forEach(async (rule) => {
              let ruleResponse = await UPCAxiosInstance.post(
                '/assignmentRule/execute',
                {
                  context: { [this.module.name]: this.completePayload },
                  ruleId: rule.ruleId,
                }
              );
              if (ruleResponse && ruleResponse.data) {
                await this.$emit('rule-triggered', {
                  payload: ruleResponse.data[this.module.name],
                  schema: this.schema,
                  prop: field,
                });
              }

              console.log(ruleResponse, 'ruleResponse');
            });
            // let ruleResponse =await UPCAxiosInstance.post("/assignmentRule/execute", {
            //     context: { [this.module]: this.completePayload },
            //     ruleId: field.others.assignmentRule,
            // });

            // console.log(ruleResponse, "ruleResponse");
          } catch (error) {
            console.log(error, 'Error');
          }
        }
      }
      if (
        field.others &&
        field.others.transitionRule &&
        'isRuleEnabled' in field.others.transitionRule
      ) {
        if (field.others.transitionRule.isRuleEnabled) {
          let transitionRule = await this.handleTransitionRuleTrigger(
            field.others.transitionRule,
            this.completePayload.status,
            this.completePayload
          );
          if (transitionRule.length > 1) {
            console.log('🚀 ~ transitionRule:', transitionRule);
            this.schema['status'].options = transitionRule;
          }
        }
      }
      console.log('🚀 ~ hasRuleTrigger ~ rule-triggered:getLeadData');
    },
    async visibilityValidationRule() {
      const data = {
        payload: {
          data: this.schema,
          emailArrayData: this.emailData,
          addressArrayData: this.addressData,
        },
      };
      const visRule = await DMNAxiosInstance.post(
        'rule_engine/rule/execute/676e5426ca476dc1b987dfca',
        data
      );

      // console.log('API Response:', visRule);
      if (visRule?.data?.data?.data) {
        this.updatedSchema = JSON.parse(
          JSON.stringify(visRule?.data?.data?.data)
        );
      }
      if (
        this.isSiteAddressAlreadyExited &&
        this.updatedSchema['siteAddress']
      ) {
        this.updatedSchema['siteAddress'].others.showValidation = true;
        this.updatedSchema['siteAddress'].others.validationMessage =
          this.matchFromStatic('Site address already exists');
      }
      if (
        !this.isSiteAddressAlreadyExited &&
        this.updatedSchema['siteAddress']
      ) {
        this.updatedSchema['siteAddress'].others.showValidation = false;
        this.updatedSchema['siteAddress'].others.validationMessage = '';
      }
      let changes = diff(this.originalSchema, this.updatedSchema);
      // console.log(this.schema, 'this.updatedSchema', this.updatedSchema);
      // console.log('changeschanges', changes);
      // changes = false
      if (changes && changes.length > 0) {
        const schema = this.schema;
        const updatedSchema = this.updatedSchema;

        changes.map(({ path }) => {
          const element = path[0];

          if (element && schema[element] && updatedSchema[element]) {
            const currentElement = schema[element];
            let valueOfElement =
              currentElement.value !== undefined ? currentElement.value : null;

            if (element !== 'siteAddress') {
              schema[element] = updatedSchema[element];
            }

            if (valueOfElement !== null) {
              schema[element].value = valueOfElement;
            }
          }
        });
      }
      // this.schema = JSON.parse(JSON.stringify(this.updatedSchema));
      // this.applyChangesToSchema(this.schema, changes);
    },
    triggerValidationForInput($event, field) {
      console.log($event, 'triggerValidationForInput', field);
      this.$emit('input-focus-out', $event, field);
    },
    formatTextAreaValue(field) {
      console.log('metaDataTestField', field);
      if (field.others.formatValue) {
        let formattedString = ``;
        let stringified = field.value;

        if (stringified !== '') {
          // Parse the JSON string
          let jsonData = JSON.parse(stringified);

          // Transform the array into an object-like format
          // let transformedData = jsonData.reduce((acc, item) => {
          //   acc[item.key] = item.value;
          //   return acc;
          // }, {});
          formattedString = `${JSON.stringify(jsonData, null, 2).replace(
            /"([^"]+)":/g,
            '$1:'
          )}`;
        }
        let str = formattedString
          .replaceAll('{', '')
          .replaceAll('}', '')
          .replaceAll('"', '');
        return str;
      } else {
        return field.value;
      }
    },
    triggerValidation(e, field) {
      // console.log(field,'currfew');

      field.others.validateCurrentField = true;
    },
    texteditorValue(val) {
      val;
      // console.log("texteditorValue",val)
    },
    async deleteProfilePicture($event, val, displayType) {
      val;
      console.log($event, '$event', val);
      // console.log(val,"deleteProfilePicture");
      this.profilePicUrlBufferData = '';
      this.$emit('profile-buffer-data', '');
      this.schema[displayType].others.profileName = '';
      this.schema[displayType].others.profilePicUrl = '';
      this.schema[displayType].value = '';
      // this.$emit('profile-name', '');
    },
    emitValidation($event, fieldName) {
      this.$emit('password-validate', $event, fieldName);
      // console.log($event,fieldName,'validate')
    },
    getHolidayDates(pref) {
      if (pref.preference == 'all-days') {
        return [];
      } else if (pref.preference == 'past-dates') {
        return this.generateDates('past-dates', {});
      } else if (pref.preference == 'future-dates') {
        return this.generateDates('future-dates', {});
      } else if (pref.preference == 'range') {
        return this.generateDates('range', pref.range);
      }
    },
    generateDates(mode, range = {}) {
      const oneDay = 24 * 60 * 60 * 1000;
      const dates = [];
      const startYear = 2000;
      const endYear = 2200;

      const startDate2000 = new Date(`${startYear}-01-01T00:00:00.000Z`);
      const endDate2200 = new Date(`${endYear}-12-31T00:00:00.000Z`);
      const today = new Date();

      let startDate, endDate, excludeStart, excludeEnd;

      if (mode === 'past-dates') {
        // Generate dates from tomorrow to the end of 2200
        startDate = new Date(today.getTime() + oneDay);
        endDate = endDate2200;
      } else if (mode === 'future-dates') {
        // Generate dates from yesterday to the start of 2000
        startDate = new Date(today.getTime() - oneDay);
        endDate = startDate2000;
      } else if (mode === 'range') {
        if (!range.from || !range.to) {
          throw new Error(`Invalid range: 'from' and 'to' must be specified`);
        }

        // Parse and adjust exclusion dates to UTC
        excludeStart = new Date(
          Date.UTC(
            new Date(range.from).getUTCFullYear(),
            new Date(range.from).getUTCMonth(),
            new Date(range.from).getUTCDate()
          )
        );
        excludeEnd = new Date(
          Date.UTC(
            new Date(range.to).getUTCFullYear(),
            new Date(range.to).getUTCMonth(),
            new Date(range.to).getUTCDate()
          )
        );

        if (excludeStart > excludeEnd) {
          throw new Error(
            `Invalid range: 'from' date must be earlier than 'to' date`
          );
        }

        startDate = startDate2000;
        endDate = endDate2200;
      } else {
        throw new Error(`Unsupported mode: ${mode}`);
      }

      let currentDate = startDate;

      // Generate dates based on the mode
      while (
        mode === 'past-dates' || mode === 'range'
          ? currentDate <= endDate
          : currentDate >= endDate
      ) {
        const day = String(currentDate.getUTCDate());
        const month = String(currentDate.getUTCMonth() + 1);
        const year = currentDate.getUTCFullYear();
        const formattedDate = `${day}-${month}-${year}`;

        // Exclude dates within the range for 'range' mode
        if (
          mode === 'range' &&
          currentDate >= excludeStart &&
          currentDate <= excludeEnd
        ) {
          currentDate = new Date(currentDate.getTime() + oneDay);
          continue;
        }

        dates.push(formattedDate);

        currentDate = new Date(
          currentDate.getTime() +
            (mode === 'past-dates' || mode === 'range' ? oneDay : -oneDay)
        );
      }

      // console.log(dates, 'dates');
      return dates;
    },
    prePopulateDate(value, preference) {
      console.log(value, preference, 'dateFieldxxx');
      if (value != '' && value != undefined) {
        return value;
      } else {
        let arr = preference.filter((i) => {
          return i.value == 'default-today';
        });
        if (arr[0].checked == true) {
          const today = new Date();
          const day = String(today.getDate()).padStart(2, '0');
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
          const year = today.getFullYear();

          // Adding 1 to the day
          const nextDay = new Date(`${year}-${month}-${day}`);
          nextDay.setDate(nextDay.getDate());

          // Convert to ISO string
          const isoString = nextDay.toISOString();
          return isoString;
        } else {
          return '';
        }
      }
    },
    isDateWeekendDisabled(preference) {
      let arr = preference.filter((i) => {
        return i.value == 'business-days-only';
      });
      return arr[0].checked;
    },
    async remapLookupData(dataPayload, remapStructure, val) {
      console.log('lookupremap', dataPayload, remapStructure, val);

      val;
      // console.log(val, dataPayload, 'remapLookupData', remapStructure);
      if (dataPayload && remapStructure) {
        const dupRemapStructure = remapStructure
          .split(/;/) // Split by semicolon
          .map((line) => line.trim()) // Trim whitespace
          .filter((line) => line !== '') // Remove empty lines
          .map((line) => {
            // Split LHS and RHS accurately at the first '='
            const [LHS, ...RHSParts] = line.split(/=/);
            const RHS = RHSParts.join('=').trim(); // Rejoin to handle additional '=' characters in RHS

            // Clean up RHS without losing content
            const cleanedRHS = RHS.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
              .replace(/\n/g, ' ') // Replace newlines with spaces
              .trim(); // Trim any leading or trailing spaces

            return {
              LHS: LHS ? LHS.trim() : '', // Ensure LHS is properly trimmed
              RHS: cleanedRHS, // Use the cleaned RHS
            };
          });

        console.log('regexData:', dupRemapStructure);
        this.$emit('lookup-data', [
          dataPayload,
          dupRemapStructure,
          this.schema,
        ]);
        // await this.mapSchemaToData(this.orginalDataPayload,this.schema)
        // console.log('this.isRemapData', this.isRemapData);
        //     .map(line => {
        //   let [LHS, ...RHSParts] = line.split(/=(.+)/);
        //   return {
        //     LHS: LHS.trim(),
        //     RHS: RHSParts.join("=").trim()
        //   };
        // });

        //   for(let i=0;i<dupRemapStructure.length;i++){
        //    let schemaObjName = dupRemapStructure[i].LHS
        //    let schemaValue =  dupRemapStructure[i].RHS
        //    let changeRhsValue = 'payload'
        //   const firstDotIndex = schemaValue.indexOf('.');
        // console.log(schemaValue,"dataPayloaddataPayload",dataPayload)
        //   if (firstDotIndex !== -1) {
        //     let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
        //     console.log(schemaObjName, "newRHS", newRHS);
        //     newRHS
        //    let result =  this.evaluateRHS(newRHS,dataPayload)
        //    console.log("remappped data",result)
        //    if(result)
        //    {
        //     this.schema[schemaObjName].value = result
        //     console.log("this.schema",this.schema)
        //    }
        //   }

        //   // let result = eval(newRHS);
        // // Log the result (assuming companyName is defined in the scope)
        // // console.log(result);
        //     console.log(schemaObjName,"schemaValue",schemaValue)
        //   }
        // console.log(
        //   dupRemapStructure,
        //   'dupRemapStructure',
        //   dupRemapStructure.length
        // );
      }
    },
    async getUserRoleOptions(role) {
      console.log('roleeee');
      let filterData = {
        company: this.companyName,
        type: 'filter-search',
        module: 'hierarchycontacts',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 1000,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        isLimit: false,
        limit: 2,
        filterQuery: {
          'role.name': {
            $in: role,
          },
          '@baseType': 'organisation',
          name: {
            $regex: '',
            $options: 'i',
          },
        },
        searchQuery: '',
      };
      let api = await MLTAxiosInstance.post('util/filterData', filterData);
      console.log(api.data.data, role, 'getUserRoleOptions');
      return api.data.data;
    },
    getContainerClassName() {
      // if(this.isModalWindow){
      //   return 'form-container-modal'
      // }
      // else if(this.singleColumnLayout){
      //   return 'form-container-small'
      // }
      // else if(this.mixedColumnLayout){
      //   return 'form-container-mixed'
      // }
      // else{
      //   return 'form-container'
      // }
      switch (this.layout) {
        case '2-column':
          return 'form-container';
        case '1-column':
          return 'form-container-small';
        case 'modal':
          return 'form-container-modal';
        case 'mixed':
          return 'form-container-mixed';
        case 'single':
          return '';
        default:
          break;
      }
    },
    async bufferToDataURL(buffer) {
      // console.log('bufferToDataURL', buffer);
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = '';
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    async addTagList($event, val) {
      // console.log($event, val);
      let obj = {};
      obj.name = $event.name;
      obj.module = 'mdu';
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsList = await DMNAxiosInstance.get(`mapRef/collection/tagsList`);
      // console.log('tagsList', tagsList);
      let arr = this.schema[val]?.value;
      arr.push($event?.name);
      this.schema[val].value = arr;
      this.schema[val].options = tagsList.data.data.filter((item) => {
        return item.module === 'mdu';
      });
    },
    handleResize() {
      const isTablet = window.matchMedia('(max-width: 768px)').matches;
      const div1 = document.querySelector('.gf-2');
      const div2 = document.querySelector('.gf-3');

      if (isTablet) {
        // Tablet mode: Change class names
        if (div1) div1.className = 'form-field gf-2 tablet-grid-field-2';
        if (div2) div2.className = 'form-field gf-3 tablet-grid-field-3';

        // Remove styles applied by syncDivs
        if (div2) {
          div2.style.position = '';
          div2.style.width = '';
          div2.style.height = '';
          div2.style.left = '';
          div2.style.top = '';
        }

        // console.log('Entered tablet mode');
      } else {
        // Desktop mode: Revert class names
        if (div1) div1.className = 'form-field gf-2 grid-field-2';
        if (div2) div2.className = 'form-field gf-3 grid-field-3';

        this.syncDivs(); // Reapply sync logic for non-tablet
        // console.log('Exited tablet mode');
      }
    },
    syncDivs() {
      this.$nextTick(() => {
        const div1 = document.querySelector('.grid-field-2');
        const div2 = document.querySelector('.grid-field-3');

        if (div1 && div2) {
          const rect = div1.getBoundingClientRect();
          // console.log(
          //   'syncing divs',
          //   div1,
          //   div2,
          //   rect,
          //   div2.style.width,
          //   rect.width,
          //   rect.left
          // );
          if (this.isModalWindow) {
            div2.style.setProperty('position', 'absolute', 'important');
            div2.style.setProperty('width', `${rect.width}px`, 'important');
            div2.style.setProperty('height', `${rect.height}px`, 'important');
            div2.style.setProperty('left', `161px`, 'important');
            div2.style.setProperty('top', `135px`, 'important');
          } else if (this.layout == '2-column') {
            div2.style.setProperty('position', 'absolute', 'important');
            div2.style.setProperty('width', `${rect.width}px`, 'important');
            div2.style.setProperty('height', `${rect.height}px`, 'important');
            div2.style.setProperty(
              'left',
              `${109 + this.gridFieldMargin}px`,
              'important'
            );
            div2.style.setProperty('top', `76px`, 'important');
          } else if (this.layout == 'mixed') {
            div2.style.setProperty('position', 'absolute', 'important');
            div2.style.setProperty('width', `${rect.width}px`, 'important');
            div2.style.setProperty('height', `${rect.height}px`, 'important');
            div2.style.setProperty('left', `130px`, 'important');
            div2.style.setProperty('top', `76px`, 'important');
          } else if (this.layout != '1-column') {
            div2.style.setProperty('position', 'absolute', 'important');
            div2.style.setProperty('width', `${rect.width}px`, 'important');
            div2.style.setProperty('height', `${rect.height}px`, 'important');
            div2.style.setProperty('left', `${rect.left}px`, 'important');
            div2.style.setProperty('top', `${rect.top + 55}px`, 'important');
          }
        }
      });
    },
    async profileUpload(event, val) {
      // write your logic to handle profile picture along the data
      // console.log(event);
      let selectedFiles = event.target.files[0];
      let formData = new FormData();

      // Append all selected files to FormData object
      formData.append('files', selectedFiles);
      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      // console.log(data);

      let obj = {};
      obj.name = data.data[0].filename;
      obj.size = data.data[0].data.attachment.size;
      obj.url = data.data[0].url;
      obj.id = data.data[0].data._id;
      obj['@type'] = 'profile';
      // Get the current date and time in milliseconds
      const currentTimestamp = new Date().toJSON();

      // Format the current timestamp in the same format as '1705574420667'
      // console.log(currentTimestamp, 'val', val);
      obj.lastUpdated = currentTimestamp;
      this.schema[val].value = obj;
      // this.schema[val].others.profilePicUrl=obj.url;
      // this.schema[val].others.profileName = obj.name
      const url = await UPCAxiosInstance.get(`/gcpupload/files/${obj.name}`, {
        responseType: 'arraybuffer',
      });
      this.profilePicUrlBufferData = await this.bufferToDataURL(url.data);
      this.$emit('profile-buffer-data', this.profilePicUrlBufferData);
      this.$emit('profile-name', obj.name);
      console.log(this.schema, 'this.schema');
    },
    async fileUpload(event, val) {
      // write your logic to handle profile picture along the data
      // console.log(event);
      let selectedFiles = event.target.files[0];
      let formData = new FormData();

      // Append all selected files to FormData object
      formData.append('files', selectedFiles);
      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      // console.log(data);

      let obj = {};
      obj.name = data.data[0].filename;
      obj.size = data.data[0].data.attachment.size;
      obj.url = data.data[0].url;
      obj.id = data.data[0].data._id;
      obj['@type'] = 'profile';
      // Get the current date and time in milliseconds
      const currentTimestamp = new Date().toJSON();

      // Format the current timestamp in the same format as '1705574420667'
      // console.log(currentTimestamp, 'val', val);
      obj.lastUpdated = currentTimestamp;
      this.schema[val].value = obj;
      // this.schema[val].others.profilePicUrl=obj.url;
      // this.schema[val].others.profileName = obj.name
      const url = await UPCAxiosInstance.get(`/gcpupload/files/${obj.name}`, {
        responseType: 'arraybuffer',
      });
      this.profilePicUrlBufferData = await this.bufferToDataURL(url.data);
      this.$emit('profile-buffer-data', this.profilePicUrlBufferData);
      this.$emit('profile-name', obj.name);
      console.log(this.schema, 'this.schema');
    },
    async getIntellisenseData() {
      let res = await DMNAxiosInstance.get(
        `/import/intellisense_by_module/?type=${this.module?.name}`
      );

      if (res.data.ok == 1) {
        let schema = res.data.data.schema;
        schema.forEach((i) => {
          i.label = i.name;
          if (i.type == 'Mixed') {
            i.type = 'Object';
          }
          if (i.type == 'ObjectID') {
            i.type = 'String';
          }
          i.type = i.type.toLowerCase();
        });
        this.intellisenseData.schema = schema;
      }
    },
    focusOutDate($event, val) {
      // console.log($event, 'focusOutDate', val);
      this.schema[val].value = $event;
    },
    onAddressSelected(e, field) {
      console.log('address recieved on address', e, field);
      // console.log;
      // this.addressSelected = e;
      // this.triggerValidation(e, field);
    },
    async receivedAddressData(
      displayType,
      $event,
      val,
      typeOf,
      field,
      eventType = 'onInput'
    ) {
      console.log(
        eventType,
        'address recieved',
        JSON.parse(JSON.stringify($event)),
        field
      );
      $event.characteristic.stateOrProvince = $event.characteristic.state;
      $event.characteristic.postalCodePrefix = this.getFSA(
        $event.characteristic.postalCode,
        $event.characteristic.country
      );
      let normalizeString = (str) =>
        str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      $event.characteristic.state = normalizeString(
        $event.characteristic.state
      );
      $event.characteristic.stateOrProvince = normalizeString(
        $event.characteristic.state
      );

      try {
        // if (this.addressSelected) {
        if (this.schema[val].value[0]) {
          console.log('this.schema if', $event);
          this.schema[val].value[0] = JSON.parse(JSON.stringify($event));
        } else if (this.schema[val].value) {
          console.log('this.schema if else', $event);
          this.schema[val].value = this.characteristicpreval;
          this.schema[val].value[0] = JSON.parse(JSON.stringify($event));
        }
        this.characteristicpreval[0] = JSON.parse(JSON.stringify($event));
        console.log(
          typeOf,
          $event,
          'receivedAddressData',
          val,
          'this.addressSelected',
          this.addressSelected,
          this.schema
        );
        await this.mapSchemaToData(this.orginalDataPayload, this.schema);
        await this.changeValueForObject(
          displayType,
          $event.characteristic,
          val,
          typeOf
        );
        field;

        if (field.others.isServiceQualificationEnabled) {
          let payload = $event.characteristic;
          let sq = await UPCAxiosInstance.post(
            `productQualification/qualifyService`,
            payload
          );
          console.log('sq', sq);
          if (sq.data.exclusionResult !== undefined) {
            this.schema[field.fieldName].others.showValidation = true;
            this.schema[field.fieldName].others.validationMessage =
              sq.data.exclusionResult[0].criteria.reason[
                this.selectedLanguageCode
              ];
            this.isSiteAddressAlreadyExited = false;
          } else {
            if (!this.isSiteAddressAlreadyExited) {
              this.schema[field.fieldName].others.showValidation = false;
              this.schema[field.fieldName].others.validationMessage = '';
            }
          }
        } else {
          console.log('Rule triggered from else');
          this.hasRuleTrigger($event, this.schema[field.fieldName]);
        }
        if (eventType == 'onInput') {
          this.hasRuleTrigger($event, field, 'siteAddress');
        }
        // }
      } catch (error) {
        console.log('Error from receivedAddressData', error);
      }
    },
    async receivedAddressDataWithoutEmit(
      displayType,
      $event,
      val,
      typeOf,
      field
    ) {
      console.log(
        'address recieved WithoutEmit',
        JSON.parse(JSON.stringify($event)),
        field
      );
      $event.characteristic.stateOrProvince = $event.characteristic.state;
      $event.characteristic.postalCodePrefix = this.getFSA(
        $event.characteristic.postalCode,
        $event.characteristic.country
      );
      let normalizeString = (str) =>
        str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      $event.characteristic.state = normalizeString(
        $event.characteristic.state
      );
      $event.characteristic.stateOrProvince = normalizeString(
        $event.characteristic.state
      );

      try {
        // if (this.addressSelected) {
        // if (this.schema[val].value[0]) {
        //   console.log('this.schema if', $event);
        //   this.schema[val].value[0] = JSON.parse(JSON.stringify($event));
        // } else if (this.schema[val].value) {
        //   console.log('this.schema if else', $event);
        //   this.schema[val].value = this.characteristicpreval;
        //   this.schema[val].value[0] = JSON.parse(JSON.stringify($event));
        // }
        // this.characteristicpreval[0] = JSON.parse(JSON.stringify($event));
        // console.log(
        //   typeOf,
        //   $event,
        //   'receivedAddressData',
        //   val,
        //   'this.addressSelected',
        //   this.addressSelected,
        //   this.schema
        // );
        // await this.mapSchemaToData(this.orginalDataPayload, this.schema);
        // await this.changeValueForObject(
        //   displayType,
        //   $event.characteristic,
        //   val,
        //   typeOf
        // );
        // this.isSiteAddressAlreadyExited = false;
        field;

        if (field.others.isServiceQualificationEnabled) {
          let payload = $event.characteristic;
          let sq = await UPCAxiosInstance.post(
            `productQualification/qualifyService`,
            payload
          );
          console.log('sq', sq);
          if (sq.data.exclusionResult !== undefined) {
            this.schema[field.fieldName].others.showValidation = true;
            this.schema[field.fieldName].others.validationMessage =
              sq.data.exclusionResult[0].criteria.reason[
                this.selectedLanguageCode
              ];
            this.isSiteAddressAlreadyExited = false;
          } else {
            if (!this.isSiteAddressAlreadyExited) {
              this.schema[field.fieldName].others.showValidation = false;
              this.schema[field.fieldName].others.validationMessage = '';
            }
          }
        } else {
          // this.hasRuleTrigger($event, this.schema[field.fieldName]);
        }
        // }
      } catch (error) {
        console.log('Error from receivedAddressData', error);
      }
    },
    getParentWidth() {
      const parentElement = document.querySelector(`.${this.parentClassName}`);
      // console.log('this.parentClassName', this.parentClassName, parentElement);
      if (parentElement) {
        this.isScreenSizeSmall = parentElement.offsetWidth > '500';
        // console.log('Parent width:', parentElement.offsetWidth);
      }
    },
    callWebsiteRegex(val) {
      this.isUrlInvalid = this.websiteRegex(val);
    },
    changeValueForStatus($event, val) {
      // console.log($event, 'changeValueForStatus', val);
      if (this.schema[val]) {
        this.schema[val].value = $event.name;
      } else {
        // console.log('changeValueForString');
      }
    },
    changeValueForObject(displayType, $event, val, type) {
      // console.log('emited', displayType);
      this.$emit('lookup-emit', $event);
      // console.log(
      //   $event,
      //   'changeValueForObject',
      //   val,
      //   'type',
      //   type,
      //   displayType
      // );
      if (this.schema[val]?.others?.lookup?.isDataRemapEnabled) {
        this.temporaryDataPayload = JSON.parse(JSON.stringify($event));
        // const getMappingSchema = this.schema.val.others.lookup.remap
        const getMappingSchema = this.schema[val].others.lookup.remapData;
        this.schema[val].others.lookup.lookupData;
        // console.log(val, 'getMappingSchema', getMappingSchema);
        this.remapLookupData(this.temporaryDataPayload, getMappingSchema, val);
        // console.log($event, 'displayType => ', displayType);
        // console.log('temporaryDataPayload', this.temporaryDataPayload);
        // this.$emit("lookup-data",[$event , getMappingSchema])
      } else if (displayType?.toLowerCase() != 'siteaddress') {
        if (type == 'object') {
          const currentValue = this.schema[val].value || {};
          // console.log("currentValue",currentValue)
          const hasEmail = 'email' in currentValue;
          const hasId = 'id' in currentValue;
          if (this.schema[val]) {
            this.schema[val].value = {
              ...currentValue,
              value: $event.name,
              ...(hasEmail && { email: $event.email }),
              ...(hasId && { id: $event.id }),
            };
          } else {
            // console.log('change');
          }
          // console.log("this.schema[val]",this.schema[val])
        }
        if (type == 'string') {
          if (this.schema[val]) {
            this.schema[val].value = $event.name;
          } else {
            // console.log('changeValueForString');
          }
        }
      }
    },
    changeValueForString($event, val) {
      const selestedTagsString = $event.map((tag) => tag.name);
      // console.log($event, 'changeValueForString', val, selestedTagsString);
      if (this.schema[val]) {
        this.schema[val].values = selestedTagsString;
      } else {
        // console.log('changeValueForString');
      }
    },
    async mapData(data, schema) {
      if (schema.isRemapData != false) {
        console.log(schema, 'datadata', data);
        const updatedStructure = JSON.parse(JSON.stringify(schema));
        // console.log('🚀 ~ mapData ~ updatedStructure:', updatedStructure);

        for (const key in updatedStructure) {
          // console.log('🚀 ~ mapData ~ key:', key);
          // console.log(key, 'updatedStructure', updatedStructure);
          if (
            updatedStructure[key].displayType === 'profile-image-upload' &&
            Array.isArray(data.attachment) &&
            data.attachment.length > 0
          ) {
            const profileInfo = data?.attachment?.find(
              (ele) =>
                ele?.['@type'] === 'profile' ||
                ele?.['attachmentType'] === 'profile'
            );
            // console.log(profileInfo, 'schema.displayType', schema.displayType);
            if (profileInfo) {
              updatedStructure[key].others.profilePicUrl =
                profileInfo.url || null;
              updatedStructure[key].others.profileName =
                profileInfo.name || null;
              if (
                profileInfo.name != null &&
                profileInfo.name != undefined &&
                profileInfo.name != ''
              ) {
                const url = await UPCAxiosInstance.get(
                  `/gcpupload/files/${profileInfo.name}`,
                  { responseType: 'arraybuffer' }
                );
                this.profilePicUrlBufferData = await this.bufferToDataURL(
                  url.data
                );
                // console.log('obj.url obj.url', url.data);
                this.$emit('profile-buffer-data', this.profilePicUrlBufferData);
                this.$emit('profile-name', profileInfo.name);

                //  If the value mapped true
                updatedStructure[key].valueMapped = true;
                // console.log(
                //   this.profilePicUrlBufferData,
                //   'this.profilePicUrlBufferData'
                // );
              }
            }
          }

          const field = updatedStructure[key];
          const labelKey = field?.labels?.en?.label;

          let value = data[key] ?? data[labelKey];

          // console.log('valuevalue', value);

          for (const dataKey in data) {
            // console.log('🚀 ~ mapData ~ dataKey:', dataKey, data[dataKey]);
            if (Array.isArray(data[dataKey])) {
              if (key == 'phoneNumber' && data.contactMedium) {
                console.log(
                  key,
                  'Array.isArray',
                  data[dataKey],
                  data['contactMedium']
                );
                const matchedItem = data['contactMedium'].find((item) =>
                  Object.values(item).some(
                    (val) => val?.toString().toLowerCase() === key.toLowerCase()
                  )
                );
                matchedItem;
                let phoneObject = data[dataKey]?.find(
                  (item) => item.mediumType === 'phone'
                );

                if (!phoneObject) {
                  phoneObject = data['contactMedium']?.find(
                    (item) => item.mediumType === 'phone'
                  );
                }
                // console.log(
                //   phoneObject,
                //   'matchedItem',
                //   matchedItem,
                //   phoneObject?.characteristic?.phoneNumber,
                //   phoneObject?.characteristic?.phoneNumber?.value
                // );

                value = phoneObject?.characteristic?.phoneNumber?.value || '';
                //  If the value mapped true
                updatedStructure[key].valueMapped = true;
                break;
              }
              if (key == 'email' && data.contactMedium) {
                // console.log(key, 'Array.isArray', data[dataKey]);
                const matchedItem = data[dataKey].find((item) =>
                  Object.values(item).some(
                    (val) => val?.toString().toLowerCase() === key.toLowerCase()
                  )
                );
                // console.log('matchedItem', matchedItem);
                if (matchedItem) {
                  value = matchedItem?.characteristic?.emailAddress || '';
                  //  If the value mapped true
                  updatedStructure[key].valueMapped = true;
                  break;
                }
              }
              if (key == 'telephoneNumber' && data.contactMedium) {
                // console.log(key, 'Array.isArray', data[dataKey]);
                const matchedItem = data[dataKey]?.find((item) =>
                  Object.values(item).some(
                    (val) => val?.toString().toLowerCase() === key.toLowerCase()
                  )
                );
                matchedItem;
                const phoneObject = data[dataKey].find(
                  (item) => item.mediumType === 'telephone'
                );
                // console.log(phoneObject, 'matchedItem', matchedItem);
                if (phoneObject) {
                  value =
                    phoneObject.characteristic.telephoneNumber.value || '';
                  //  If the value mapped true
                  updatedStructure[key].valueMapped = true;
                }
                break;
              }
              const matchedItem = data?.[dataKey]?.find((item) => {
                if (item) {
                  // console.log(key, 'item to check', item);
                  return Object.values(item).some(
                    (val) => val?.toString().toLowerCase() === key.toLowerCase()
                  );
                }
                return false;
              });
              // console.log('🚀 ~ mapData ~ matchedItem:', matchedItem);
              if (
                matchedItem &&
                (updatedStructure[key].value === '' ||
                  updatedStructure[key].value === null ||
                  updatedStructure[key].value === undefined)
              ) {
                // console.log(schema[key], 'matchedItem', matchedItem ,typeof matchedItem);
                if (
                  typeof matchedItem === 'object' &&
                  matchedItem !== null &&
                  'value' in matchedItem &&
                  Object.keys(matchedItem).some(
                    (key) =>
                      key !== 'value' && key !== 'type' && key !== '@type'
                  )
                ) {
                  value = matchedItem;
                  //  If the value mapped true
                  updatedStructure[key].valueMapped = true;
                } else {
                  value =
                    matchedItem.link ||
                    matchedItem.name ||
                    matchedItem.value ||
                    '';
                }
                break;
              }
            }
          }
          // console.log('console.log', updatedStructure[key]);
          updatedStructure[key] = {
            ...field,
            value:
              value !== undefined && value !== '' && value !== null
                ? value
                : schema[key].value !== undefined
                ? schema[key].value
                : '',
          };
          if (
            updatedStructure[key].displayType === 'siteaddress' &&
            updatedStructure[key].value != ''
          ) {
            this.characteristicpreval = updatedStructure[key].value;
            if (
              updatedStructure[key].displayType == 'siteaddress' &&
              updatedStructure[key].others.isServiceQualificationEnabled
            ) {
              let filterPayload = {
                type: 'filter',
                module: 'address',
                isPaginated: true,
                paginatedQuery: {
                  rowsPerPage: 10,
                  currentPage: 1,
                },
                isNeedToBeSort: false,
                sortFieldAndOrder: {},
                limit: 10,
                filterQuery: {
                  type: {
                    $in: ['inclusion'],
                  },
                },
                searchQuery: '',
                company: this.company,
              };
              let sqData = await UPCAxiosInstance.post(
                'util/filterData',
                filterPayload
              );
              console.log(sqData.data, 'sqData');
              if (sqData.data != undefined) {
                this.serviceQualificationEnabledStates =
                  sqData?.data?.data?.map((item) => item?.stateOrProvince);
              }
            }
            // console.log(
            //   updatedStructure[key],
            //   'this.characteristicpreval',
            //   this.characteristicpreval
            // );
          }
        }
        // console.log('this.characteristicprevalval', updatedStructure);
        for (const key in updatedStructure) {
          // if (
          //   !(key in data) &&
          //   updatedStructure[key]['system'] != true &&
          //   updatedStructure[key]['System'] != false &&
          //   updatedStructure[key]['system'] != false &&
          //   updatedStructure[key].show &&
          //   updatedStructure[key].displayType !== null
          // ) {
          //   const field = updatedStructure[key];
          //   data[key] = field.value || '';
          // }
          if (
            updatedStructure[key].value == null ||
            updatedStructure[key].value == '' ||
            updatedStructure[key].value == undefined ||
            (typeof updatedStructure[key].value === 'object' &&
              Object.keys(updatedStructure[key].value).length === 0 &&
              updatedStructure[key].options.length > 0 &&
              updatedStructure[key].options)
          ) {
            const value = (updatedStructure[key].options || []).filter((i) => {
              return i.default == true || i.default == 'true';
            });
            if (value[0]?.value || value[0]?.name) {
              // console.log(
              //   updatedStructure[key],
              //   'updatedStructure[key]',
              //   value
              // );
              if (updatedStructure[key]?.displayType == 'multiselect') {
                updatedStructure[key].values = value
                  ? value.map((item) => item.value)
                  : value.map((item) => item.name);
              } else {
                updatedStructure[key].value = value[0]?.value
                  ? value[0]?.value
                  : value[0]?.name;
              }
            }
            // console.log(
            //   'newLog',
            //   updatedStructure[key],
            //   updatedStructure[key].value,
            //   key
            // );
          }
          if (updatedStructure[key]?.displayType == 'multiselect') {
            const multiselectPrevalue = updatedStructure[key]?.values || [];

            if (
              Array.isArray(updatedStructure[key]?.options) &&
              Array.isArray(multiselectPrevalue)
            ) {
              updatedStructure[key].options = updatedStructure[key].options.map(
                (itemA) => {
                  const matchingItem = multiselectPrevalue.find(
                    (itemB) => itemB?.name === itemA?.name
                  );
                  return matchingItem ? matchingItem : itemA;
                }
              );
            } else {
              // console.error('Options for multi select', key);
            }
          }
          if (
            !(key in data) &&
            // updatedStructure[key]['system'] != true &&
            // updatedStructure[key]['System'] != false &&
            // updatedStructure[key]['system'] != false &&
            updatedStructure[key].show &&
            updatedStructure[key].displayType !== null &&
            updatedStructure[key].valueMapped == false
          ) {
            const field = updatedStructure[key];
            data[key] = field.value || '';
          }
        }
        this.orginalDataPayload = JSON.parse(JSON.stringify(data));
        this.copyOfPayload = JSON.parse(JSON.stringify(data));
        console.log(data, 'updatedStructure for mapData', updatedStructure);
        this.updateMultiselectKey += 1;
        this.schema = JSON.parse(JSON.stringify(updatedStructure));
        this.$emit('updated-schema', this.schema);
        this.updateMultiselectKey += 1;
        // console.log(
        //   data,
        //   'updatedStructure for if after schema',
        //   updatedStructure
        // );
      } else {
        const updatedStructure = JSON.parse(JSON.stringify(schema));
        for (const key in updatedStructure) {
          // if (
          //   !(key in data) &&
          //   updatedStructure[key]['system'] != true &&
          //   updatedStructure[key]['System'] != false &&
          //   updatedStructure[key]['system'] != false &&
          //   updatedStructure[key].show &&
          //   updatedStructure[key].displayType !== null
          // ) {
          //   const field = updatedStructure[key];
          //   data[key] = field.value || '';
          // }
          if (
            updatedStructure[key].value == null ||
            updatedStructure[key].value == '' ||
            updatedStructure[key].value == undefined ||
            (typeof updatedStructure[key].value === 'object' &&
              Object.keys(updatedStructure[key].value).length === 0 &&
              updatedStructure[key].options)
          ) {
            const value = updatedStructure[key].options.filter((i) => {
              return i.default == true || i.default == 'true';
            });
            if (value[0]?.value || value[0]?.name) {
              // console.log(
              //   updatedStructure[key],
              //   'updatedStructure[key]',
              //   value
              // );
              updatedStructure[key].value = value[0]?.value
                ? value[0]?.value
                : value[0]?.name;

              //  If the value mapped true
              updatedStructure[key].valueMapped = true;
            }
            // console.log(
            //   'newLog',
            //   updatedStructure[key],
            //   updatedStructure[key].value,
            //   key
            // );
          }
          if (updatedStructure[key]?.displayType == 'multiselect') {
            const multiselectPrevalue = updatedStructure[key]?.value || [];

            if (
              Array.isArray(updatedStructure[key]?.options) &&
              Array.isArray(multiselectPrevalue)
            ) {
              updatedStructure[key].options = updatedStructure[key].options.map(
                (itemA) => {
                  const matchingItem = multiselectPrevalue.find(
                    (itemB) => itemB?.name === itemA?.name
                  );
                  return matchingItem ? matchingItem : itemA;
                }
              );
            } else {
              // console.error('Options for multi select', key);
            }
          }
          if (
            !(key in data) &&
            // updatedStructure[key]['system'] != true &&
            // updatedStructure[key]['System'] != false &&
            // updatedStructure[key]['system'] != false &&
            updatedStructure[key].show &&
            updatedStructure[key].displayType !== null &&
            updatedStructure[key].valueMapped == false
          ) {
            const field = updatedStructure[key];
            data[key] = field.value || '';
          }
        }
        delete schema.isRemapData;
        this.orginalDataPayload = JSON.parse(JSON.stringify(data));
        this.copyOfPayload = JSON.parse(JSON.stringify(data));
        console.log(data, 'updatedStructure else in mapData', updatedStructure);
        this.updateMultiselectKey += 1;
        this.schema = JSON.parse(JSON.stringify(updatedStructure));
        this.updateMultiselectKey += 1;
      }
      this.orginalDataPayload = JSON.parse(JSON.stringify(data));
      this.copyOfPayload = JSON.parse(JSON.stringify(data));
      await this.visibilityValidationRule();
      if (this.isPropsSentForAddressComp) {
        console.log(
          'this.isPropsSentForAddressComp',
          this.isPropsSentForAddressComp
        );
        this.isPropsSentForAddressComp = false;
      }
    },
    async processFields(fields) {
      console.log('🚀 ~ processFields ~ fields:', fields);
      const processedFields = { ...fields };

      const promises = Object.entries(processedFields).map(
        async ([key, field]) => {
          if (key != 'isRemapData') {
            if (field?.options?.length > 0) {
              return;
            } else if (
              !field.optionRef ||
              field.optionRef === 'referenceDataName'
            ) {
              // console.log(processedFields[key], 'isRemapDataisRemapData', key);
              processedFields[key] = { ...field, options: [] };
              return;
            }

            try {
              // const { data } = await DMNAxiosInstance.get(
              //   `mapRef/collection/${field.optionRef}`
              // );
              // let dropdownData = data?.data || [];
              const { data } = await DMNAxiosInstance.post(`redisJSON`, {
                key: `${this.companyName}.reference`,
                query: `$.${field.optionRef}`,
              });
              let dropdownData = data?.data || [];

              if (field.displayType === 'status') {
                // dropdownData = dropdownData.filter((ele) =>
                //   this.statusList.includes(ele.name)
                // );
                let statusList = [];
                // if (
                //   field.others &&
                //   field.others.transitionRule &&
                //   Object.keys(field.others.transitionRule).length > 0
                // ) {
                //   // Fire transition rule
                //   let transitionRule = field.others.transitionRule;
                //   console.log("transitionRule", this.schema,this.completePayload);
                //   statusList = await this.handleTransitionRuleTrigger(
                //     transitionRule,
                //     field.value?field.value:this.compeltePayload?.status,
                //     this.completePayload
                //   );
                // }
                if (statusList.length == 0) {
                  dropdownData = dropdownData.map((item) => ({
                    ...item,
                    color: item.hexCode,
                  }));
                } else {
                  dropdownData = statusList;
                }
              } else if (field.optionRef === 'tagsList') {
                dropdownData = dropdownData.filter(
                  (ele) => ele.module === this.module.code
                );
              }

              if (field.displayType === 'multiselect') {
                dropdownData.forEach((item) => {
                  item.checked = false;
                });
              }

              if (field.displayType === 'user') {
                // console.log('process fields called', field.others.userRole);

                let users = await this.getUserRoleOptions(
                  field.others.userRole
                );
                dropdownData = users;
                dropdownData.map((i) => {
                  i.newRole = i.role[0].name;
                });
              }

              processedFields[key] = {
                ...field,
                options: Array.isArray(dropdownData) ? dropdownData : [],
              };
              // this.isFuncOver = true
            } catch (error) {
              console.error(`Error processing field: ${key}`, error);
              processedFields[key] = { ...field, options: [] };
            }
          }
        }
      );

      await Promise.all(promises);

      // console.log(
      //   this.copyOfPayload,
      //   'Processed fields:',
      //   processedFields,
      //   this.isRemapData
      // );

      this.schema = JSON.parse(JSON.stringify(processedFields));

      if (this.orginalDataPayload && this.schema?.isRemapData != false) {
        await this.mapData(this.orginalDataPayload, this.schema);
        await this.mapSchemaToData(this.orginalDataPayload, this.schema);
      }
      console.log('transitionRule1.0', this.schema, this.completePayload);
      if (
        this.completePayload &&
        this.completePayload.status != '' &&
        this.transitionFiredCountFromMount == 0 &&
        'status' in fields &&
        fields.status.displayType == 'status' &&
        fields.status.others &&
        fields.status.others.transitionRule &&
        Object.keys(fields.status.others.transitionRule).length > 0
      ) {
        // Fire transition rule
        let transitionRule = fields.status.others.transitionRule;
        console.log('transitionRule2.0', this.schema, this.completePayload);
        let statusList = await this.handleTransitionRuleTrigger(
          transitionRule,
          this.schema.status.value
            ? this.schema.status.value
            : this.compeltePayload?.status,
          this.completePayload
        );
        this.transitionFiredCountFromMount = 1;
        if (statusList && statusList.length > 1) {
          fields.status.options = statusList;
        }
      }
    },
    async mapSchemaToData(ogData, schema) {
      console.log('🚀 ~ mapSchemaToData ~ ogData, schema:', ogData, schema);
      let data = JSON.parse(JSON.stringify(ogData));
      // console.log(schema, 'mapSchemaToData for schema', data);
      if (data && schema) {
        try {
          for (let key in schema) {
            const schemaField = schema[key];
            const schemaValue = schemaField.value || '';
            // console.log(
            //   '🚀 ~ mapSchemaToData ~ schemaField',
            //   schemaField,
            //   schemaField.value
            // );
            // console.log(data.attachment, 'sakthiv');
            if (schemaField.displayType === 'profile-image-upload') {
              data.attachment =
                data.attachment != '' && data.attachment != undefined
                  ? data.attachment
                  : [];
              const existingProfile = data?.attachment?.find(
                (ele) =>
                  ele?.['@type'] === 'profile' ||
                  ele?.['attachmentType'] === 'profile'
              );
              // console.log(
              //   schemaField.value,
              //   'existingProfile',
              //   existingProfile
              // );
              if (existingProfile) {
                // data.attachment = [schemaField.value];
                data.attachment = data.attachment.filter(
                  (ele) =>
                    ele?.['@type'] !== 'profile' &&
                    ele?.['attachmentType'] !== 'profile'
                );
                data.attachment.push(schemaField.value);

                // To check the value the mapped from schema to data
                schemaField.valueMapped = true;
              } else {
                data.attachment.push(schemaField.value);
              }
              if (data.attachment && Array.isArray(data.attachment)) {
                data.attachment = data?.attachment?.filter(
                  (item) => item !== null && item !== '' && item !== undefined
                );
              }
            }
            // console.log(
            //   `🚀 ~ mapSchemaToData ~ key in data ||
            // (schemaField.labels?.en?.label &&
            //   schemaField.labels.en.label in data):`,
            //   key in data,
            //   schemaField.labels?.en?.label &&
            //     schemaField.labels.en.label in data
            // );
            if (
              key in data ||
              (schemaField.labels?.en?.label &&
                schemaField.labels.en.label in data)
            ) {
              const dataKey = key in data ? key : schemaField.labels.en.label;
              data[dataKey] = schemaValue;

              // To check the value the mapped from schema to data
              schemaField.valueMapped = true;
              // console.log(
              //   '🚀 ~ mapSchemaToData ~ data[dataKey]:',
              //   data[dataKey],
              //   dataKey
              // );
              // console.log(schemaField, 'schemaValue', schemaValue);
            }
            // else {
            for (let dataKey in data) {
              if (Array.isArray(data[dataKey])) {
                // const matchingItem = data[dataKey].find((item) =>
                //   Object.values(item).some(
                //     (value) =>
                //       value?.toString().toLowerCase() === key.toLowerCase()
                //   )
                // );
                // console.log("data[dataKey]",data[dataKey])
                const matchingItem = data[dataKey]?.find((item) => {
                  if (!item) return false;
                  Object.values(item)?.some((keyName, value) => {
                    if (
                      keyName == 'phone' &&
                      key.toLowerCase() == 'phonenumber'
                    ) {
                      let phoneObject = data[dataKey].find(
                        (item) => item.mediumType === 'phone'
                      );
                      if (!phoneObject) {
                        phoneObject = data['contactMedium'].find(
                          (item) => item.mediumType === 'phone'
                        );
                      }
                      phoneObject.characteristic.phoneNumber.value =
                        schema[key]?.value;
                      // To check the value the mapped from schema to data
                      schemaField.valueMapped = true;
                      // console.log('phone if');
                    }
                    if (keyName == 'email' && key.toLowerCase() == 'email') {
                      let phoneObject = data[dataKey].find(
                        (item) => item.mediumType === 'email'
                      );
                      if (!phoneObject) {
                        phoneObject = data['contactMedium'].find(
                          (item) => item.mediumType === 'email'
                        );
                      }
                      phoneObject.characteristic.emailAddress =
                        schema[key]?.value;
                      // To check the value the mapped from schema to data
                      schemaField.valueMapped = true;
                      // console.log(schema[key]?.value,'phone if email',phoneObject);
                    }
                    if (
                      keyName == 'telephone' &&
                      key.toLowerCase() == 'telephonenumber'
                    ) {
                      let phoneObject = data[dataKey].find(
                        (item) => item.mediumType === 'telephone'
                      );
                      if (!phoneObject) {
                        phoneObject = data['contactMedium'].find(
                          (item) => item.mediumType === 'telephone'
                        );
                      }
                      phoneObject.characteristic.telephoneNumber.value =
                        schema[key].value;
                      // To check the value the mapped from schema to data
                      schemaField.valueMapped = true;
                      // console.log('phone if telephone');
                    }
                    if (
                      keyName &&
                      key &&
                      String(keyName).toLowerCase() ===
                        String(key).toLowerCase() &&
                      Array.isArray(data[dataKey])
                    ) {
                      const foundObject = data[dataKey].find((item) =>
                        Object.values(item).some(
                          (value) =>
                            String(value).toLowerCase() ===
                            String(key).toLowerCase()
                        )
                      );
                      if (foundObject) {
                        if (
                          typeof schemaValue == 'object' &&
                          typeof foundObject == 'object' &&
                          Object.keys(schemaValue).some(
                            (key) =>
                              key !== 'value' &&
                              key !== 'type' &&
                              key !== '@type'
                          )
                        ) {
                          Object.keys(schemaValue).forEach((key) => {
                            foundObject[key] = schemaValue[key];
                            // To check the value the mapped from schema to data
                            schemaField.valueMapped = true;
                          });
                        } else {
                          foundObject.value = schemaValue;
                          // To check the value the mapped from schema to data
                          schemaField.valueMapped = true;
                        }
                      }

                      return (
                        value?.toString().toLowerCase() ==
                        key.toString().toLowerCase()
                        // ||
                        // value?.toString().toLowerCase() == schema[key]?.displayType?.toLowerCase() ||
                        // key?.toString().toLowerCase() == schema[key]?.displayType?.toLowerCase()
                      );
                    }
                  });
                  if (matchingItem) {
                    if ('value' in matchingItem) {
                      console.log(
                        schemaValue,
                        "if ('value' in matchingItem)",
                        matchingItem
                      );
                      matchingItem.value = schemaValue;
                      // To check the value the mapped from schema to data
                      schemaField.valueMapped = true;
                    } else {
                      // console.log("matchingItem.mediumType ",matchingItem.mediumType )
                      if (matchingItem.mediumType.trim() == 'email') {
                        matchingItem.characteristic.emailAddress = schemaValue;
                        // To check the value the mapped from schema to data
                        schemaField.valueMapped = true;
                      }
                      if (matchingItem.mediumType.trim() == 'phone') {
                        matchingItem.characteristic.phoneNumber.value =
                          schemaValue;
                        // To check the value the mapped from schema to data
                        schemaField.valueMapped = true;
                      }
                      if (matchingItem.mediumType.trim() == 'telephone') {
                        matchingItem.characteristic.telephoneNumber.value =
                          schemaValue;
                        // To check the value the mapped from schema to data
                        schemaField.valueMapped = true;
                      } else {
                        // console.log(schemaValue, 'else', matchingItem);
                        matchingItem.value = schemaValue;
                        // To check the value the mapped from schema to data
                        schemaField.valueMapped = true;
                      }
                    }
                  }
                });
              }
            }
            // }
            if (data.attachment && Array.isArray(data.attachment)) {
              data.attachment = data.attachment.filter(
                (item) => item !== null && item !== '' && item !== undefined
              );
            }
            if (
              (!schemaField.valueMapped || schemaField.valueMapped === false) &&
              schemaField.show === true &&
              schemaField.others.isVisible === true
            ) {
              // data.key = schemaField.value || "Hello";
              if (key != 'profileUpload') {
                data[key] = schemaField.value || '';
              }
              console.log(key, 'schemaField inside', schemaField, data);
            }
          }
        } catch (error) {
          console.log('Error from mapSchemaToData', error);
        }
        if (schema.isRemapData == false) {
          delete schema.isRemapData;
          this.mapData(data, schema);
        }
        // console.log(schema,data,"Mappped data and schema",this.schema)
        this.$emit('edited-payload', data);
        this.$emit('updated-schema', this.schema);
        // console.log('mapSchemaToData', data);
      }
      // if (schema.isRemapData == false) {
      //   delete schema.isRemapData;
      //   this.mapData(data, schema);
      // }
      // this.$emit('edited-payload', JSON.parse(JSON.stringify(data)));
      // this.$emit('updated-schema', this.schema);
      // console.log('mapSchemaToData', data);
    },
    async readFile(data) {
      // Convert binary string to Uint8Array
      const binaryString = data.data;
      const byteArray = new Uint8Array(binaryString.length);

      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob with the correct MIME type
      const fileBlob = new Blob([byteArray], { type: data.properties.type });

      // Append to FormData
      let formData = new FormData();
      formData.append('file', fileBlob, data.properties.name);

      // Log the Blob size for debugging
      // console.log('Blob size:', fileBlob.size);

      // Send the FormData using Axios
      try {
        let res = await DMNAxiosInstance.post(
          `/import/extract_headers`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        res;
        // console.log('Response:', res.data);
      } catch (error) {
        console.error(
          'Error uploading file:',
          error.response?.data || error.message
        );
      }
    },
    // Get appropriate label
    getLabel(field, key) {
      if (
        this.customLanguage != undefined &&
        this.customLanguage != null &&
        Object.keys(this.customLanguage).length != 0
      ) {
        this.selectedLanguageCode = this.customLanguage.code;
        this.selectedLanguageName = this.customLanguage.name;
      }
      if (key == 'leadInformation') {
        console.log(field, this.selectedLaxnguageCode, key, 'kkkkkk');
      }
      if (key == 'leadInformation') {
        console.log(field, this.selectedLaxnguageCode, key, 'kkkkkk');
      }
      // console.log(field,key,field.labels[this.selectedLanguageCode],this.selectedLanguageCode,'kjiu')
      return field.labels[this.selectedLanguageCode]?.label || key;
      // return 'english'
    },

    // Determine field class based on layout requirements
    getFieldClass(field) {
      if (field.displayType === 'profile-regular') {
        return '';
      }
      if (
        field.displayType === 'textarea' ||
        field.displayType === 'texteditor'
      ) {
        return 'form-field textarea-field';
      }
      if (field.displayType === 'radio' || field.displayType === 'checkbox') {
        return 'form-field radio-field';
      }
      if (field.displayType === 'address') {
        return 'form-field address-field';
      }
      if (field.displayType === 'siteaddress') {
        return 'form-field site-address-field';
      }
      if (field.specialLayout === 'single-column') {
        return 'form-field single-column';
      }
      if (field.displayType === 'header') {
        return 'form-field two-column';
      }
      if (
        field?.others?.gridField !== undefined &&
        field?.others?.gridField !== ''
      ) {
        return `form-field gf-${field.others.gridField.slice(-1)} grid-field-${
          field.others.gridField
        }`;
      }
      return 'form-field';
    },
  },
};
</script>

<style lang="scss">
.data {
  position: relative;
}
.form-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Two equal columns */
  /* gap: 16px;  */
  /* Space between fields */
  padding: 20px;
  column-gap: 16px;

  .form-field {
    display: flex;
    flex-direction: row;
    grid-column: span 6;
    gap: 10px;
    grid-row: span 2;
    .input-container,
    .status-component {
      width: 100% !important;
    }

    .status-component,
    .profile-picture-component {
      margin-bottom: 20px;
    }
  }

  .grid-field-2,
  .grid-field-3 {
    grid-column: span 5;
  }
  .textarea-field {
    grid-row: span 4;
    grid-column: span 6; /* Spans across both columns */
  }
  .grid-field-1 {
    grid-column: span 1;
  }
  .grid-field-2,
  .grid-field-3 {
    grid-column: span 5;
  }
}

.form-field.label {
  margin-bottom: 8px; /* Space between label and input */
  font-weight: bold;
  font-size: 14px;
}

.form-field input,
.form-field textarea,
.form-field select {
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
}

.textarea-field {
  grid-row: span 4;
  // grid-column: span 6; /* Spans across both columns */
}

.address-field {
  grid-row: span 8; /* Spans across both columns */
}
.site-address-field {
  grid-row: span 10 !important; /* Spans across both columns */
}
.single-column {
  grid-column: span 3; /* Spans one column */
}

.tablet-grid-field-2,
.tablet-grid-field-3 {
  grid-column: span 3;
}
.special-field {
  grid-column: span 3; /* Special layout can adjust grid-column */
}
.radio-field {
  grid-column: span 3;
  grid-row: span 2;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.two-column {
  grid-column: span 3;
}
/* Tablet and smaller: Single column layout */
// @media (max-width: 768px) {
//   .form-container {
//     grid-template-columns: 1fr; /* Single column */
//   }
//   .two-column,
//   .two-column-field,
//   .textarea-field,
//   .radio-field {
//     grid-column: span 3; /* Full width */
//   }
// }
.form-container-small {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Two equal columns */
  /* gap: 16px;  */
  /* Space between fields */
  padding: 20px;
  column-gap: 16px;

  .form-field {
    display: flex;
    flex-direction: row;
    grid-column: span 3;
    gap: 10px;
    grid-row: span 2;
    .input-container,
    .status-component {
      width: 100% !important;
    }
    .status-component {
      margin-bottom: 20px;
    }
    .profile-picture-component {
      margin-bottom: 20px;
    }
  }
  .grid-field-1 {
    grid-column: span 1;
  }

  .grid-field-2,
  .grid-field-3 {
    grid-column: span 3;
  }
}

.form-container-modal {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Two equal columns */
  /* gap: 16px;  */
  /* Space between fields */
  padding: 20px;
  column-gap: 16px;

  .form-field {
    display: flex;
    flex-direction: row;
    grid-column: span 3;
    gap: 10px;
    grid-row: span 2;
    .input-container,
    .status-component {
      width: 100% !important;
    }
  }
  .grid-field-1 {
    grid-column: span 1;
  }

  .grid-field-2,
  .grid-field-3 {
    grid-column: span 2 !important;
  }
  .grid-field-3 {
    grid-row: span 1 !important;
  }
  .textarea-field {
    grid-row: span 4 !important;
  }
  .site-address-field {
    grid-row: span 10;
  }
}
.form-container-mixed {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Two equal columns */
  // gap: 20px;
  /* Space between fields */
  padding: 20px;
  column-gap: 16px;

  .status-component,
  .profile-picture-component {
    margin-bottom: 20px;
  }

  .form-field {
    display: flex;
    flex-direction: row;
    grid-column: span 12;
    gap: 10px;
    grid-row: span 2;
    .input-container,
    .status-component {
      width: 100% !important;
    }
  }
  .grid-field-1 {
    grid-column: span 1;
  }

  .grid-field-2,
  .grid-field-3 {
    grid-column: span 11 !important;
  }
  .grid-field-3 {
    grid-row: span 1 !important;
  }
  .site-address-field {
    grid-row: span 12;
  }
}
.label-field {
  display: flex;
  padding: 0 0 16px 0;
  .key {
    font-weight: 600;
    width: 150px;
  }
}
</style>
