<template>
  <hlx-modal
    :modal-active="deleteRule"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="deleteRule = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete rule</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>Are you sure you want to delete the rule ?</p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="deleteRule = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteRuleFunc"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <discardComponent
    :show="showOverviewDiscardModal"
    @close="showOverviewDiscardModal = false"
    @stay="handleStay"
    @discard="handleDiscard"
  >
  </discardComponent>
      <hlx-modal
    :modal-active="displayShare"
    :height="'400px'"
    :width="'600px'"
    :modal="true"
    @close="displayShare = false"
  >
    <template #header>
      <div style="margin-left: 0px; font-size: 16px">Share</div>
    </template>
    <template #content>
      <div class="modal-content">
        <span class="schema-header-title">{{
          matchFromStatic("Submission limits")
        }}</span>
      </div>
<div style="width: 30%; margin-left: 25px">
<hlx-input
            :id="key"
            v-model:value="limitType"
            :pre-val="limitType"
            :prop-value="'name'"
            :type="'radio-buttons'"
            :display-error="true"
            :options="options"
            @selected="selectedLimit"
          />
          <span style="margin-top: 24px">
          <hlx-input
          v-if="displayLimit"
          v-model:value="accessLimit"
          :pre-val="accessLimit"
          :label-value="'Limit'"
          :label-animation="true"
          :type="'number'"

        ></hlx-input>
    </span>
   
    </div>
    <div
    style="margin-left: 25px; margin-right: 25px;margin-top: 24px; display:flex; flex-direction: column;"
                >
                <div style="display: flex; justify-content: space-between; width: 100%">
                  <div style="width: 87%">
                <hlx-input
                v-model:value="url"
                
          :pre-val="url"
          :label-value="'Limit'"
          :label-animation="false"

        ></hlx-input>
</div>
        <hlx-button class="secondary sm" @click="copy()"
        >Copy</hlx-button
      >
    </div>
                  <!-- <input
                    ref="copy-click"
                    v-model="url"
                    class="api-key-holder"
                    data-copy-string="#ITN12345"
                    data-tooltip-text=""
                    data-tooltip-text-copied="✔ Copied to clipboard"
                    @click.stop="copyIntraction"
                  />
                  <span class="c-to-c">{{
                    matchFromStatic("Click to copy")
                  }}</span> -->
                  <div v-if="copyMsg == true">
                  <span
                  style="color:var(--hlx-color-primary); font-size: 12px"
                    >✔ {{ matchFromStatic("Link copied successfully") }}</span
                  >
                </div>
                </div>
    </template>
    
  </hlx-modal>
  <hlx-modal
    :modal-active="editModal"
    :height="'650px'"
    :width="'700px'"
    :modal="true"
    @close="editModal = false"
  >
    <template #header>
      <div style="margin-left: 0px">Edit rule</div>
    </template>
    <template #content>
        <div style="padding: 30px">
        <!-- <div style="margin-bottom: 16px">
          <span class="schema-header-title" style="font-size: 16px">{{
            matchFromStatic("Name")
          }}</span>
        </div> -->
 
        <div style="margin-bottom: 24px">
          <hlx-input
            v-model:value="ruleName"
            :pre-val="ruleName"
            :read-only="view ? true : false"
            :label-value="'Rule name'"
            :label-animation="true"
            :required="false"
         
          ></hlx-input>
        </div>
        <div style="margin-bottom: 24px">
        <!-- <hlx-input
          v-model:value="event"
          :pre-val="event"
          :read-only="view ? true : false"
          :label-value="'Event'"
          :label-animation="true"
          :required="false"
       
        ></hlx-input> -->

        <hlx-select
          v-model:value="event"
          :pre-value="event"
          :options="eventOptions"
          :read-only="view ? true : false"
          :placeholder-value="'Execution event'"
          :label-animation="true"
        ></hlx-select>
  </div>
        <div style="margin-bottom: 24px">
        <hlx-input
          v-model:value="ruleDescription"
          :pre-val="ruleDescription"
          :read-only="view ? true : false"
          :label-value="'Rule description'"
          :label-animation="true"
          type="textarea"

          :required="false"
       
        ></hlx-input>
  </div>
      
        <div>
        <div style="margin-bottom: 16px">
          <span class="schema-header-title" style="font-size: 16px">{{
            matchFromStatic("Rule")
          }}</span>
        </div>
        <hlx-code-editor
          v-model:value="ruleData"
          :intellisense-data="intellisenseData"
          :line-number="true"
          :beautify="false"
          :pre-value="ruleData"
          :read-only="false"
          :rows="5"
        ></hlx-code-editor>
      </div>
    </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="editModal = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="patchRule"
        >Save</hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-modal
    :modal-active="addModal"
    :height="'650px'"
    :width="'700px'"
    :modal="true"
    @close="addModal = false"
  >
    <template #header>
      <div style="margin-left: 0px">Add rule</div>
    </template>
    <template #content>
        <div style="padding: 30px">
        <!-- <div style="margin-bottom: 16px">
          <span class="schema-header-title" style="font-size: 16px">{{
            matchFromStatic("Name")
          }}</span>
        </div> -->
 
        <div style="margin-bottom: 24px">
          <hlx-input
            v-model:value="ruleName"
            :read-only="view ? true : false"
            :label-value="'Rule name'"
            :label-animation="true"
            :required="false"
         
          ></hlx-input>
        </div>
        <div style="margin-bottom: 24px">
        <!-- <hlx-input
          v-model:value="event"
          :pre-val="event"
          :read-only="view ? true : false"
          :label-value="'Event'"
          :label-animation="true"
          :required="false"
       
        ></hlx-input> -->

        <hlx-select
          v-model:value="event"
          :options="eventOptions"
          :read-only="view ? true : false"
          :placeholder-value="'Execution event'"
          :label-animation="true"
          :clearable="true"
        ></hlx-select>
  </div>
        <div style="margin-bottom: 24px">
        <hlx-input
          v-model:value="ruleDescription"
          :read-only="view ? true : false"
          :label-value="'Rule description'"
          :label-animation="true"
          type="textarea"

          :required="false"
       
        ></hlx-input>
  </div>
      
        <div>
        <div style="margin-bottom: 16px">
          <span class="schema-header-title" style="font-size: 16px">{{
            matchFromStatic("Rule")
          }}</span>
        </div>
        <hlx-code-editor
          v-model:value="ruleData"
          :intellisense-data="intellisenseData"
          :line-number="true"
          :beautify="false"
          :read-only="false"
          :rows="5"
        ></hlx-code-editor>
      </div>
    </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="addModal = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="AddRule"
        >Add</hlx-button
      >
    </template>
  </hlx-modal>

  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <section class="form-page">
    <section class="left">
      <div class="new-layout-header" style="margin-bottom: 24px">
        <span class="new-layout-header-container">
          <hlx-breadcrumb style="font-size: 16px" :auto-route="true" />
        </span>
        <span class="schema-header-title">{{
          matchFromStatic("Form builder")
        }}</span>
      </div>
      <div style="margin-bottom: 24px">
        <hlx-input
          v-model:value="payload.name"
          :pre-val="payload.name"
          :read-only="view ? true : false"
          :label-value="'Form name'"
          :label-animation="true"
          :required="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
        ></hlx-input>
      </div>
      <div style="margin-top: 24px">
        <hlx-input
          v-model:value="payload.description"
          :pre-val="payload.description"
          :read-only="view ? true : false"
          :label-animation="true"
          label-value="Description"
          type="textarea"
          :clearable="true"
        />
      </div>
      <div style="margin-top: 24px">
        <hlx-select
          v-model:value="modulename"
          :pre-value="modulename"
          :options="moduleoptions"
          :read-only="true"
          :placeholder-value="'Module name'"
          :label-animation="true"
          :clearable="true"
        ></hlx-select>
      </div>
    </section>
    <section class="right" style="margin-bottom: 60px">
        
      <div class="layout-footer" style="display: flex; align-items: center">
        <div
          class="go-back"
          style="
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 24px;
            margin-bottom: 53px;
            justify-content: flex-end;
          "
        >
        <hlx-button  v-if="edit == true" class="secondary sm"  style="margin-right:24px" @click="cancelEdit" >Cancel</hlx-button>
          <hlx-button class="secondary sm" :disabled="allowShare" @click="shareForm"><i class="icon-link-alt-regular"></i></hlx-button>
          <hlx-button
            v-if="add == true"
            class="primary sm"
            style="margin-left: 20px"
            @click="addForm"
            >Add</hlx-button
          >
          <hlx-button
            v-else-if="edit == true"
            class="primary sm"
            style="margin-left: 20px"
            @click="patchForm"
            >Save</hlx-button
          >
          <hlx-button
            v-else
            class="primary sm"
            style="margin-left: 20px"
            @click="makeEdit"
            >Edit</hlx-button
          >
          <span
            class="upload_component_icon"
            style="margin-left: 20px; cursor: pointer"
            @click="previewForm"
            ><img
              class="file-icon"
              src="../../assets/svg/eye-filled.svg"
              alt=""
          /></span>
        </div>
      </div>
      <div style="width: 100%; margin-bottom: 24px; display: flex; justify-content: space-between;">
        <div style="width: 70%;">
                  <hlx-switch
                    v-model="selectedSwitch"
                    :switch-items="schema_type_switch"
                    :tab-view="true"
                    value-prop="name"
                    display-prop="name"
                    @change="handleCurrentChange"
                  />
                  </div>
        <div v-if="!displayTemplate">
          <hlx-button class="secondary sm" @click="addModal = true"
        >Add rule</hlx-button
      >
                  </div>
                </div>

      <span v-show="displayTemplate">
      <div>
        <div style="margin-bottom: 16px">
          <span class="schema-header-title" style="font-size: 16px">{{
            matchFromStatic("Schema")
          }}</span>
        </div>
        <hlx-code-editor
          v-model:value="payload.schema"
          :intellisense-data="intellisenseData"
          :line-number="true"
          :beautify="false"
          :pre-value="preValue.schema"
          :read-only="false"
          :rows="10"
        ></hlx-code-editor>
      </div>
      <div>
        <div style="margin-bottom: 16px; margin-top: 16px; display: flex; flex-direction: row; justify-content: space-between">
          <div class="schema-header-title" style="font-size: 16px">{{
            matchFromStatic("Html")
          }}</div>
          <div
          class="language-selectorr"
        >
          <!-- Trigger Button -->
          <!-- <button class="language-button" @click="toggleDropdown">
            <span style="padding-left: 10px; padding-right: 8px">
              <i class="icon-globe-regular" style="font-size: 14px"></i>
            </span>
            {{ selectedLanguage?.code?.toUpperCase() }}
            <span :class="{ open: isOpen }"
              ><i class="icon-angle-down-regular"></i
            ></span>
          </button>

          <ul v-if="isOpen" class="dropdown-menu">
            <li
              v-for="lang in langList"
              :key="lang.code"
              :class="{ selected: lang.checked }"
              @click="selectLanguage(lang)"
            >
              {{ lang?.code?.toUpperCase() }}
            </li>
          </ul> -->

          <hlx-select
          v-model:value="customLanguage.code"
          :pre-value="'en'"
          :options="langList"
          :placeholder-value="'Language'"
          :label-animation="true"
          @selected-value="selectLanguage"
        ></hlx-select>

        </div>
        </div>
        <hlx-code-editor
        :key="customLanguage?.code?.toLowerCase()"
        v-model:value="payload.html[customLanguage?.code?.toLowerCase()]"
        :intellisense-data="intellisenseData"
          :line-number="true"
          :beautify="false"
          :pre-value="payload?.html?.en"
          :read-only="false"
          :rows="5"
        ></hlx-code-editor>
      </div>
      <div>
        <div style="margin-bottom: 16px; margin-top: 16px">
          <span class="schema-header-title" style="font-size: 16px">{{
            matchFromStatic("Style")
          }}</span>
        </div>
        <hlx-code-editor
          v-model:value="payload.style"
          :intellisense-data="intellisenseData"
          :line-number="true"
          :beautify="false"
          :pre-value="preValue.style"
          :read-only="false"
          :rows="5"
        ></hlx-code-editor>
      </div>
      </span>



      <!-- <span v-show="!displayTemplate">
        <div>
        <div style="margin-bottom: 16px">
          <span class="schema-header-title" style="font-size: 16px">{{
            matchFromStatic("Name")
          }}</span>
        </div>
        <div style="margin-bottom: 24px">
        <hlx-input
          v-model:value="ruleName"
          :pre-val="ruleName"
          :read-only="view ? true : false"
          :label-value="'Rule name'"
          :label-animation="false"
          :required="false"
       
        ></hlx-input>
      </div>
      </div>
        <div>
        <div style="margin-bottom: 16px">
          <span class="schema-header-title" style="font-size: 16px">{{
            matchFromStatic("Rule")
          }}</span>
        </div>
        <hlx-code-editor
          v-model:value="ruleData"
          :intellisense-data="intellisenseData"
          :line-number="true"
          :beautify="false"
          :pre-value="ruleData"
          :read-only="false"
          :rows="10"
        ></hlx-code-editor>
      </div>
      </span> -->



      <span v-show="!displayTemplate">
    
        <hlx-table
        v-if="ruleTableData?.length>0"
              :column-count="6"
              :border="['table', 'header', 'horizontal']"
              :row-hover="true"
              theme="grey"
             
            >
              <template #thead>
                <hlx-table-head
                  :width="60"
                  :align="'center'"
                  style="padding: 0 0"
                  :fixed="'left'"
                  @new-resize-width="resizedWidth"
                  >{{ "S.no" }}</hlx-table-head
                >
                <hlx-table-head
                  :width="350"
                  :align="'left'"
                  style="padding: 0 0"
                  @new-resize-width="resizedWidth"
                  >{{ "Name" }}</hlx-table-head
                >
                <hlx-table-head
                  :width="150"
                  :align="'left'"
                  style="padding: 0 0"
                  @new-resize-width="resizedWidth"
                  >{{ "Event" }}</hlx-table-head
                >
                <!-- <hlx-table-head
                  :align="'left'"
                  :width="300"
                  style="padding: 0 0"
                  @new-resize-width="resizedWidth"
                  >{{ "Description" }}</hlx-table-head
                > -->

                <hlx-table-head
                  :width="300"
                  :align="'left'"
                  style="padding: 0 0"
                  @new-resize-width="resizedWidth"
                  >{{ "Description" }}</hlx-table-head
                >
                <hlx-table-head
                  :width="60"
                  :align="'center'"
                  style="padding: 0 0"
                  :fixed="'right'"
                  @new-resize-width="resizedWidth"
                  ></hlx-table-head
                >
            
              </template>
              <template #tbody>
                <tr v-for="(i, index) in ruleTableData" id="" :key="index">
                  <hlx-table-cell :align="'center'" :fixed="'left'">
                    {{ index + 1 }}
                  </hlx-table-cell>
                  <hlx-table-cell :align="'left'" >
                    {{ i.name }}
                  </hlx-table-cell>
                  <hlx-table-cell :align="'left'" >
                    {{ i.event }}
                  </hlx-table-cell>
                  <hlx-table-cell :align="'left'" >
                    {{ i.description }}
                  </hlx-table-cell>
                
                  <hlx-table-cell
              
                    :align="'center'"
                    :width="120"
                    :fixed="'right'"
                  >
                    <span :id="'table-context' + index" class="action-icon">
                      <i
                        style="position: relative"
                        class="icon-more-vertical-filled"
                        :class="i.context === true ? 'active-action' : ''"
                        @click="crudContextMenu($event, index)"
                      >
                        <hlx-context-menu
                          :top="containertop"
                          :left="containerleft"
                          :conditionvalue="150"
                          :conditiondown="2"
                          :conditionup="-38"
                          :options="crudOptions"
                          :data="index.toString()"
                          :show="i.context == true"
                          @chosen="closeCrudContextMenu($event, i, index)"
                        />
                      </i>
                    </span>
                  </hlx-table-cell>
                </tr>
              </template>
            </hlx-table>

            <div v-else style="display:flex; justify-content:center"><div style="font-size:14px">No data</div></div>
      </span>




    </section>
  </section>
</template>

<script>

import { DMNAxiosInstance, MLTAxiosInstance } from "@/config/axiosConfig";
import discardComponent from '@/components/discardComponent.vue';
export default {
  components:{discardComponent},
  beforeRouteLeave(to, from, next) {
    if (this.discardIt) {
      //this.discardIt will handled on discard modal window discard changes emit
      next(true);
      return;
    }
    
    if (
      this.$route.path.includes("/edit") || this.$route.path.includes("/view")
    ) {
      console.log("isPayloadChangede")
      const dupSchemaProp = JSON.parse(this.dupe)
      dupSchemaProp.module = "saleslead"
      this.payload.module = "saleslead"

      delete dupSchemaProp?.updatedAt 
      delete dupSchemaProp?.createdAt;
      delete dupSchemaProp?._id;
      delete dupSchemaProp?.__v;
      delete dupSchemaProp?.lastUpdate;
      delete dupSchemaProp?.lastModifiedBy;
      delete dupSchemaProp?.createdBy;
     


      const unsavedChanges = this.isPayloadChanged(
        dupSchemaProp,
        this.payload
      );

      console.log(
        this.payload,
        'unsavedChanges',
        unsavedChanges
      );
      if (unsavedChanges) {
        this.showOverviewDiscardModal = true;
      }
      next(!unsavedChanges);
      return;
    } else {
      console.log("isPayloadChanged1")
      let a = JSON.stringify(this.payload)
      a = JSON.parse(a)
      a = Object.values(a).filter(i => i!="" && Object.keys(i) != undefined && Object.keys(i)?.length != 0)
      if(a.length > 0){
        console.log("isPayloadChanged2", Object.values(a))
        let unsavedChanges = true
        if (unsavedChanges) {
        console.log("isPayloadChanged3")
        this.showOverviewDiscardModal = true;
      }
      next(!unsavedChanges);
      return;
    }
  }
  },


  data() {
    return {
    customLanguage:{code:"en", name:"English", value:"en"},
      deleteRule: false,
      moduleoptions:[{name:"Lead", value: "Lead"}],
      modulename:"Lead",
      tempBack: false,
      discardIt: false,
      copyMsg: false,
      dupe:{},
      crudOptions:[{
            label: "Edit",
            display:"Edit",
            icon: "icon-edit-regular",
          },
          {
            label: "Delete",
            display:"Delete",
            icon: "icon-trash-regular",
          }],
        schema_type_switch: [
        { name: "Template", value: "template" },
        { name: "Rule", value: "Rule" }
      ],
      ruleTableData:[],
        toAddRules:[],
      selectedSwitch: "Template",
        options:[{name:"Once per user", value:"Once per user", default: true}, {name:"Custom limit", value:"Custom limit"}],
      customSchema: {},
      bannerHtml: "",
      url:"",
      bannerStyle: {},
      showOverviewDiscardModal: false,

      ruleData:"",
      ruleName:"",
      ruleId:"",
      rulePayload:{},
      ruleOptions: [],
      eventOptions:[{name:"On load", value:"On load"}, {name:"On submit", value:"On submit"}],
      
      displayPreview: false,
      displayShare: false,
      displayLimit: false,
      limitType: "Once per user",
      payload: {
        name: "",
        description: "",
        schema: "",
        html: {},
        style: "",
        rule: "",
      },
      ruleStudioquery: {
        type: "search",
        module: "expression",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 1000,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {},
        limit: 1000,
        filterQuery: {},
        searchQuery: "forms",
        company: this.$cookies.get("company"),
      },
      intellisenseData: {},
      displayTemplate: true,
      add: false,
      view: false,
      edit: false,
      preValue: {},
      notification: {},
      ruleRefs: {},
      dataPayload: {},
      accessLimit: 1,
      uniqueId:"",
      formId:"",
      allowShare: true,
      baseUrl:window.location.origin,
      deleteModal: false,
      editModal: false,
      addModal: false,
      langList:[],
      isOpen: false,
    };
  },  
  
  computed: {
    selectedLanguage() {
      // Find the currently selected language
      // console.log(
      //   this.langList.find((lang) => lang.checked) || this.langList[0],
      //   "mm"
      // );
      return this.langList.find((lang) => lang.checked) || this.langList[0];
    },
  },
  
  async mounted() {
    await this.getIntellisenseData();
    if (this.$route.path.includes("/add")) {
      this.add = true;
      this.view = false;
      this.edit = false;
      this.allowShare = true
    //   const schema = await UPCAxiosInstance.get(
    //   `/customSchema/getSchema?module=${"saleslead"}`
    // );
    // this.preValue.schema = JSON.stringify(schema?.data?.schema,null,2);
    // this.payload.schema = JSON.stringify(schema?.data?.schema,null,2);
    } else if (this.$route.path.includes("/edit")) {
        this.add = false;
        this.view = false;
        this.edit = true;
        this.allowShare = false
        let response = await await DMNAxiosInstance.get(
        `/form/${this.$route.path.split("/").pop()}`
      );
          this.formId = response.data.data._id
      this.payload.name = response.data.data.name;
      this.payload.description = response.data.data.description;
      this.payload.schema = response.data.data.schema;
      this.payload.html = response.data.data.html;
      this.payload.style = response.data.data.style;
      this.payload.rule = response.data.data.rule;
      this.payload.ruleData = response.data.data.ruleData;
      this.preValue.schema = JSON.stringify(response.data.data.schema, null, 4);
      this.preValue.html = JSON.stringify(response.data.data.html, null, 4);
      this.preValue.style = JSON.stringify(response.data.data.style, null, 4);
      this.dupe = JSON.stringify(response.data.data)
    } else {
      this.add = false;
      this.view = true;
      this.allowShare = false
      this.edit = false;
      let response = await await DMNAxiosInstance.get(
        `/form/${this.$route.path.split("/").pop()}`
      );
      this.formId = response.data.data._id
      this.payload.name = response.data.data.name;
      this.payload.description = response.data.data.description;
      this.payload.rule = response.data.data.rule;
      this.payload.ruleData = response.data.data.ruleData;

      this.payload.schema = response.data.data.schema;
      this.payload.style = response.data.data.style;
      this.payload.html = response.data.data.html;
      this.preValue.schema = JSON.stringify(response.data.data.schema, null, 4);
      this.preValue.html = JSON.stringify(response.data.data.html, null, 4);
      this.preValue.style = JSON.stringify(response.data.data.style, null, 4);
      this.dupe = JSON.stringify(response.data.data)

    }

    let filterResultData = await DMNAxiosInstance.post(
      `util/filterData`,
      this.ruleStudioquery
    );
    this.ruleOptions = filterResultData.data.data.data.map((i) => {
      return {
        value: i._id,
        name: i.name,
        checked: false,
        disabled: false,
      };
    });

    // let rule = await DMNAxiosInstance.get(
    //   `rule_engine/rule/${this.payload.rule}`,
    // );
    // this.ruleName = rule.data.data.name
    // this.ruleData = rule.data.data.ruleScript
    // this.ruleTableData.push({name:this.ruleName, event: "Mounted", ruleScript:this.ruleData})
    this.ruleTableData = this.payload.ruleData
    console.log("eeeg", this.payload);

    let filterData = {
      type: "filter",
      company: "Halleyx",
      module: "hierarchyaccounts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 10,
      filterQuery: {
        tradingName: this.$cookies.get('company'),
      },
      searchQuery: "",
    };

    try {
      const tenantDataData = await MLTAxiosInstance.post(
        `util/filterData`,
        filterData
      );
      this.langList = tenantDataData?.data?.data[0].languageAbility?.map(
        (language) => ({
          ...language,
          value: language.code
        })
      );
      console.log("this.langlist", this.langList, this.moduleoptions)
    } catch (error) {
      console.error("Error fetching language data:", error);
    }
  },
  
  methods: {
    async cancelEdit(){
      this.edit = false
      let response = await DMNAxiosInstance.get(
        `/form/${this.$route.path.split("/").pop()}`
      );
          this.formId = response.data.data._id
      this.payload.name = response.data.data.name;
      this.payload.description = response.data.data.description;
      this.payload.schema = response.data.data.schema;
      this.payload.html = response.data.data.html;
      this.payload.style = response.data.data.style;
      this.payload.rule = response.data.data.rule;
      this.payload.ruleData = response.data.data.ruleData;
      this.preValue.schema = JSON.stringify(response.data.data.schema, null, 4);
      this.preValue.html = JSON.stringify(response.data.data.html, null, 4);
      this.preValue.style = JSON.stringify(response.data.data.style, null, 4);
      this.dupe = JSON.stringify(response.data.data)

      console.log(this.preValue.schema, this.payload.schema, "kkk")
    },
    async deleteRuleFunc(){
      await DMNAxiosInstance.delete(
          `rule_engine/rule/${this.ruleId}`);
      this.notification = {
        type: "success",
        message: `Rule deleted successfully!`,
      };
    },
    handleStay() {
      // //handle Stay funtionality to hide discard component
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.tempBack = false;
    },
    handleDiscard() {
      //Handle discard functionalities like hide discard component,set discardIt as true, and push the routes where ever you want.
      
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.discardIt = true;
        this.$router.back();
   
    },

    copy(){
      this.copyMsg = true
      // Create a temporary textarea element
      const textarea = document.createElement("textarea");
      textarea.value = this.url; // Set the text to copy
      document.body.appendChild(textarea); // Append textarea to the document
      textarea.select(); // Select the text inside the textarea

      try {
        document.execCommand("copy"); // Execute the copy command
        setTimeout(() => {
      this.copyMsg = false
    }, 2000);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }

      document.body.removeChild(textarea); // Clean up by removing the textarea
 
    },

    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectLanguage(lang) {
      // Mark the selected language as checked
      this.langList.forEach((language) => (language.checked = false));
      lang.checked = true;

      // Close the dropdown
      this.isOpen = false;
      this.customLanguage = lang;
      // Perform additional actions, like updating the app's locale
      console.log(`Selected language: ${lang.code}`);
    },

    async patchRule(){
      this.rulePayload = {
            
             visualBuilderPayload: this.ruleData,
             executionByPass: this.executionByPass,
             isAtomic: true,
             status: 'active',
             type: 'expression',
             buildMode: 'code',
             ruleSet: [],
             createdBy: '639af00f9f515f57074584ef',
             _isExistBypass: false,
           }
      this.rulePayload.ruleScript = this.ruleData
      this.rulePayload.name = this.ruleName
      this.rulePayload.description = this.description
      this.rulePayload.ruleScript = this.ruleData
      await DMNAxiosInstance.patch(
          `rule_engine/rule/${this.ruleId}`,
          {
           ...this.rulePayload
          }
        );
        this.notification = {
        type: "success",
        message: `Rule updated successfully!`,
      };
      this.editModal = false
      this.dupe = JSON.stringify(this.payload)
    },
    async addRule(){
      this.rulePayload = {
            
            visualBuilderPayload: this.ruleData,
            executionByPass: this.executionByPass,
            isAtomic: true,
            status: 'active',
            type: 'expression',
            buildMode: 'code',
            ruleSet: [],
            createdBy: '639af00f9f515f57074584ef',
            _isExistBypass: false,
          }
          this.rulePayload.ruleScript = this.ruleData
      this.rulePayload.name = this.ruleName
      this.rulePayload.description = this.description
      this.rulePayload.ruleScript = this.ruleData
      let addedRule = await DMNAxiosInstance.post(
          `rule_engine/rule/${this.ruleId}`,
          {
           ...this.rulePayload
          }
        );

        this.toAddRules.push({id:addedRule.data.data._id, name:addedRule.data.data.name, event: this.event, description:addedRule.data.data.description})
        this.event = ""
        this.notification = {
        type: "success",
        message: `Rule added successfully!`,
      };
    },
    async editRule(ele){
      this.editModal = true
    
       let rule = await DMNAxiosInstance.get(
      `rule_engine/rule/${ele.id}`,
    );
    this.rulePayload = rule.data.data
    this.ruleId = ele.id
    this.ruleName = rule.data.data.name
    this.ruleData = rule.data.data.ruleScript
    this.ruleDescription = ele.description
    this.event = ele.event

    },
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 159;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.ruleTableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.ruleTableData[index].context = true;
      } else {
        this.ruleTableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async closeCrudContextMenu($event, ele) {
      if ($event.toLowerCase() === "edit") {
        this.editRule(ele)

      } else if ($event.toLowerCase() == "delete") {
        this.deleteRule = true;
      }
    },
    handleCurrentChange(val){
        if(val.name.toLowerCase() == "template"){
            this.displayTemplate = true
        }else{
            this.displayTemplate = false

        }
    },
    copyIntraction() {
  console.log("Copy interaction triggered");
  
  let input = this.$refs["copy-click"]; // Access the input element directly
  
  if (input) {
    input.select(); // Select the text in the input
    document.execCommand("copy"); // Copy the selected text to the clipboard

    // Add the 'copied' class for visual feedback
    input.classList.add("copied");

    // Remove the 'copied' class after a delay for the user feedback
    setTimeout(() => {
      input.classList.remove("copied");
    }, 2000);
  }
},

    async selectedLimit(val){
        if(val.name == "Custom limit"){
            this.displayLimit = true
            await DMNAxiosInstance.patch(
      `/tinyUrl/${this.uniqueId}`,{data:this.formId, baseUrl:this.baseUrl,"accessLimit": this.accessLimit}
    );
        }else{
            this.displayLimit = false
            this.accessLimit = 1
            await DMNAxiosInstance.patch(
      `/tinyUrl/${this.uniqueId}`,{data:this.formId, baseUrl:this.baseUrl,"accessLimit": this.accessLimit}
    );
        }
    },
    async shareForm(){
        this.displayShare = true
        let tinyUrl = await DMNAxiosInstance.post(
      `/tinyUrl/generate`,{data:this.formId, baseUrl:this.baseUrl,"accessLimit": this.accessLimit}
    );
    this.url = tinyUrl.data.data[0].tinyUrl
    this.uniqueId = tinyUrl.data.data[0].uniqueId
    //     let tinyUrl = await DMNAxiosInstance.post(
    //   `/tinyUrl/${this.$route.path.split("/").pop()}`
    // );
    },
    async previewForm() {
    //   this.displayPreview = true;

      this.customSchema = this.payload.schema;
      this.bannerHtml = this.payload.html;
      this.bannerStyle = this.payload.style;
      sessionStorage.setItem('pageData', JSON.stringify(this.payload));
      window.open('/forms/preview', '_blank');


    },

    async makeEdit() {
      this.add = false;
      this.view = false;
      this.edit = true;
    },
    async getIntellisenseData() {
      let res = await DMNAxiosInstance.get(
        `/import/intellisense_by_module/?type=saleslead`
      );

      if (res.data.ok == 1) {
        let schema = res.data.data.schema;
        schema.forEach((i) => {
          i.label = i.name;
          if (i.type == "Mixed") {
            i.type = "Object";
          }
          if (i.type == "ObjectID") {
            i.type = "String";
          }
          i.type = i.type.toLowerCase();
        });
        this.intellisenseData.schema = schema;
      }
    },
    cancelBack() {
      this.$router.back();
    },
    async addForm() {
      this.payload.module = "saleslead";
      this.payload.schema = JSON.parse(this.payload.schema);
      this.payload.html = JSON.parse(this.payload.html);
      this.payload.style = JSON.parse(this.payload.style);
      this.payload.ruleData = this.toAddRules
      let formData = await DMNAxiosInstance.post(`/form`, [{ ...this.payload }]);
      this.formId = formData.data.data._id
      this.allowShare = false
      await DMNAxiosInstance.patch(
      `/tinyUrl/${this.uniqueId}`,{data:this.formId, baseUrl:this.baseUrl,"accessLimit": this.accessLimit}
    );
      this.notification = {
        type: "success",
        message: `Form added successfully!`,
      };
    //   this.cancelBack();
    },
    async patchForm() {
      this.payload.module = "saleslead";
    //   this.payload.schema = this.payload.schema
    //   this.payload.html = this.payload.html
    //   this.payload.style = this.payload.style
      await DMNAxiosInstance.patch(
        `/form/${this.$route.path.split("/").pop()}`,
        { ...this.payload }
      );
      this.notification = {
        type: "success",
        message: `Form updated successfully!`,
      };
      this.dupe = JSON.stringify(this.payload)
    //   this.cancelBack();
    },
  },
};
</script>

<style lang="scss">
  .api-key-container {
          --duration: 400ms;
          --ease: cubic-bezier(0.19, 1, 0.22, 1);
          --bounce: cubic-bezier(0.6, -0.28, 0.735, 0.045);
          position: relative;
          .c-to-c,
          .c-to-cd {
            display: none;
            position: absolute;
            background-color: white;

            font-size: 12px;
            // right: -54%;
            padding: 2px 16px;
            white-space: nowrap;
            /* background-color: #d54f4f; */
            /* border: 1px solid; */
            border-radius: 4px;
            box-shadow: -5px 3px 18px 0px rgb(255 43 43 / 0%);
            pointer-events: none;
            backface-visibility: hidden;
          }
          border: 1px solid var(--hlx-color-disable);
          outline: none;
          border-radius: 5px;
          display: flex;
          width: 90%;
          height: 28px;
          align-items: center;
          justify-content: center;

          & > input {
            &:hover {
              & + .c-to-c {
                display: block;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                opacity: 1;
                transform: translate(151%, 0);
                transition-timing-function: var(--ease);
              }
            }
            &.copied ~ .c-to-cd {
              display: block;
              color: var(--hlx-color-success);
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              opacity: 1;
              transform: translate(116%, 0);
              transition-timing-function: var(--ease);
            }
            &.copied + .c-to-c {
              display: none;
            }
            // --ease: cubic-bezier(0.19, 1, 0.22, 1);
            // --bounce: cubic-bezier(0.6, -0.28, 0.735, 0.045);
            // &.copy-click {
            //   position: relative;
            //   padding-bottom: 2px;
            //   text-decoration: none;
            //   cursor: copy;
            //   color: #484848;
            //   border-bottom: 1px dashed #767676;
            //   transition: background-color calc(var(--duration) * 2)
            //     var(--ease);

            //   &::after {
            //     content: attr(data-tooltip-text);
            //     position: absolute;
            //     bottom: calc(100% + 6px);
            //     left: 50%;
            //     padding: 8px 16px;
            //     white-space: nowrap;
            //     background-color: rgb(196, 68, 68);
            //     border-radius: 4px;
            //     box-shadow: 0 0 0 -12px rgba(0, 0, 0, 0);
            //     pointer-events: none;
            //     backface-visibility: hidden;
            //     opacity: 0;
            //     transform: translate(-50%, 12px);
            //     transition: box-shadow calc(var(--duration) / 1.5)
            //         var(--bounce),
            //       opacity calc(var(--duration) / 1.5) var(--bounce),
            //       transform calc(var(--duration) / 1.5) var(--bounce);
            //   }

            //   &.is-hovered {
            //     &::after {
            //       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            //       opacity: 1;
            //       transform: translate(-50%, 0);
            //       transition-timing-function: var(--ease);
            //     }
            //   }

            //   &.is-copied {
            //     background-color: yellow;

            //     &::after {
            //       content: attr(data-tooltip-text-copied);
            //     }
            //   }
            // }
            border: none;
            outline: none;
            border: none;
            width: 100%;
            outline: none;
            padding-left: 10px;
            color: var(--hlx-color-info);
            padding-right: 10px;
          }
          .refresh-icon {
            border-radius: 0 5px 5px 0;
            padding: 2px 8px;
            background-color: var(--hlx-color-disable);
            color: var(--hlx-text-color-primary);
            i {
              cursor: pointer;
            }
          }
        }
.form-page {
  width: 100%;
  display: flex;
  .left {
    width: 30%;
    height: 100%;
    padding: 20px 20px;
  }
  .right {
    width: 70%;
    height: 100%;
    padding-right: 20px;
  }
}

.language-selectorr {
  position: relative;
  display: flex;
  align-items: center;
  
.language-button {
  color: #111;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.language-button span {
  font-size: 14px;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.language-button span.open {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  padding: 5px 0;
  width: 100px; /* Adjust width to match design */
  text-align: center;
}

.dropdown-menu li {
  padding: 8px 10px;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown-menu li.selected {
  font-weight: bold;
  background-color: #e6f7ff;
}
}


</style>
