<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <div></div>

  <section style="display: flex; flex-direction: column; height: 100vh">
    <main
      class="decision-add-container --layout-3-way"
      :style="
        isRightLayoutOpened
          ? 'display: grid;grid-template-columns: 200px calc(100% - 600px) 300px'
          : ''
      "
      style="height: 100vh"
    >
      <section
        class="cm-fields-left-panel responsiveSM"
        style="width: 220px; background-color: #fff; overflow: hidden"
      >
        <!-- Breadcrumb -->
        <div class="layout-breadcrumb">
          <!-- :items="getBreadcrumbs" -->
          <hlx-breadcrumb @path="goTo()" />
          <!-- @click -->
        </div>
        <div style="margin-top: 25px">
          <leftpannelComponent
            :side-bar-data="sideBarData"
            @value-name="valueleft"
          ></leftpannelComponent>
        </div>
      </section>

      <section
        class="--layout-inner-right-portion --RL70 responsiveSM-right-portion"
        style="overflow: scroll; scrollbar-width: none; -ms-overflow-style: none; --webkit-scrollbar: none;"
        :style="'width:100%'"
      >
        <div class="responsive-breadcrumbs-sm">
          <hlx-breadcrumb :items="getBreadcrumbs" @path="goTo()" />
        </div>
        <div>
          <div v-if="leftpannel == 'Overview' || leftpannel == ''">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
              "
            >
              <div style="display: flex; align-items: center; gap: 16px">
                <span class="leaftpanelForresponsive-sm">
                  <leftpannelComponent
                    :side-bar-data="sideBarData"
                    @value-name="valueleft"
                  ></leftpannelComponent>
                </span>
                <!-- <span class="responsive-span-sm"
                  ><i
                    class="icon-menu-regular responsive-menuicon-sm"
                    @click="show_left = true"
                  ></i
                ></span> -->
                <span style="">
                  <hlx-file-upload
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Rectangle'"
                    :style-background="backgrounStyleupload"
                    :height="50"
                    :width="50"
                    :read-only="defalt"
                    :custom-icon="icon"
                    :type="'profile-upload-preview'"
                    :name-display="regionName"
                    :content="'Import'"
                    :profile-pic-preview="profilePicUrl"
                  />
                </span>

                <div style="display: flex; flex-direction: column; gap: 8px">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      gap: 8px;
                      display: -webkit-inline-box;
                    "
                  >
                    <span style="font-weight: bold">
                      {{ regionName }}
                    </span>
                    <hlx-label v-if="status === 'Active'" class="primary sm">{{
                      status
                    }}</hlx-label>
                    <hlx-label
                      v-if="status && status === 'Inactive'"
                      class="error sm"
                      >{{ status }}</hlx-label
                    >
                    <hlx-label
                      v-if="status && status === 'Pending'"
                      class="warning sm"
                      >{{ status }}</hlx-label
                    >
                  </div>
                  <div class="address-content-sm">
                    <span v-if="type === 'Postal code'">{{ postalCode }}</span>
                    <span v-else-if="type === 'City'">{{ city }}</span>
                    <span v-else-if="type === 'Province'">{{
                      stateOrProvince
                    }}</span>
                    <span v-else>
                      {{ removeFirstComma(description) }}
                    </span>
                  </div>
                </div>
              </div>

              <div
                v-if="view && selectedValue.toLowerCase() === 'overview'"
                class="go-back"
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <hlx-button
                  v-if="permisstionData.edit"
                  class="primary sm"
                  style="margin-left: 20px"
                  @click="view = false"
                  >Edit</hlx-button
                >
              </div>
              <div
                v-if="!view && selectedValue.toLowerCase() === 'overview'"
                class="go-back"
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <hlx-button
                  v-if="permisstionData.edit"
                  class="secondary sm"
                  @click="goTo()"
                  >Cancel</hlx-button
                >
                <!-- {{ editsupplierbutton }} -->
                <hlx-button
                  v-if="permisstionData.edit"
                  class="primary sm"
                  style="margin-left: 20px"
                  :disabled="addressExist"
                  @click="patchRegion()"
                  >Save</hlx-button
                >
              </div>
            </div>
          </div>

          <div
            v-if="leftpannel != 'Overview' || leftpannel != ''"
            :style="{
              display:
                leftpannel !== '' && leftpannel !== 'Overview'
                  ? 'block'
                  : 'none',
            }"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
              "
            >
              <div style="display: flex; align-items: center; gap: 16px">
                <span class="leaftpanelForresponsive-sm">
                  <leftpannelComponent
                    :side-bar-data="sideBarData"
                    @value-name="valueleft"
                  ></leftpannelComponent>
                </span>
                <!-- <span class="responsive-span-sm" @click="show_left = true"
                  ><i class="icon-menu-regular responsive-menuicon-sm"></i
                ></span> -->
                <span style="">
                  <hlx-file-upload
                    :key="profileComponentKey"
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Rectangle'"
                    :style-background="backgrounStyleupload"
                    :height="50"
                    :width="50"
                    :read-only="defalt"
                    :custom-icon="icon"
                    :type="'profile-upload-preview'"
                    :name-display="regionName"
                    :content="'Import'"
                    :profile-pic-preview="profilePicUrl"
                  />
                </span>

                <div style="display: flex; flex-direction: column; gap: 8px">
                  <div style="display: flex; align-items: center; gap: 8px">
                    <span style="font-weight: bold">{{ regionName }}</span>
                    <hlx-label v-if="state === 'Active'" class="primary sm">{{
                      state
                    }}</hlx-label>
                    <hlx-label v-if="state === 'Inactive'" class="error sm">{{
                      state
                    }}</hlx-label>
                    <hlx-label v-if="state === 'Pending'" class="warning sm">{{
                      state
                    }}</hlx-label>
                  </div>
                  <div class="address-content-sm">
                    <span v-if="type === 'Postal code'">{{ postalCode }}</span>
                    <span v-else-if="type === 'City'">{{ city }}</span>
                    <span v-else-if="type === 'Province'">{{
                      stateOrProvince
                    }}</span>
                    <span v-else>
                      {{ removeFirstComma(description) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="decision-info-right-pannel" style="overflow: hidden">
          <div v-if="leftpannel == 'Overview' || leftpannel == ''">
            <div style="display: flex; gap: 5%; padding-top: 22px">
              <!-- <h1>asif</h1> -->
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                  width: 50%;
                "
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                  "
                >
                  <span style="width: 200%">
                    <div
                      class="decision-name-wrapper"
                      :style="{
                        'margin-bottom': customnameerror ? '-7.6%' : 0,
                      }"
                    >
                      <hlx-input
                        v-model:value="regionName"
                        :required="true"
                        :pre-val="regionName"
                        label-value="Region name"
                        :label-animation="true"
                        type="text"
                        :clearable="false"
                        :display-error="true"
                        :read-only="view"
                        :custom-error="isExistingName"
                        :custom-error-message="isExistingNameErrorMessage"
                        @focus-out="validateNameNew"
                        @at-input="validateName"
                      ></hlx-input>
                    </div>
                    <div style="width: 100%">
                      <hlx-status
                        :type="'select-status'"
                        :required="true"
                        :readonly="view"
                        :status-option="filteredstatus"
                        :prevalue="status"
                        @emit-select="emitStatus1"
                      ></hlx-status>
                    </div>
                  </span>
                </div>

                <div class="decision-name-wrapper">
                  <hlx-select
                    v-model:value="type"
                    :required="true"
                    :pre-value="type"
                    :options="dropdown_data_type"
                    :placeholder-value="'Type'"
                    :label-animation="true"
                    :label="'value'"
                    :prop-value="'value'"
                    :disabled="view"
                    :clearable="true"
                    @selected-value="getSelectedSku"
                  />
                </div>
                <!-- nul -->

                <!-- :new-coordinate="newCoordinate" -->
                <addressComponent
                  :key="addressKey"
                  :required="true"
                  :customnameerror="addressExist"
                  :type="type"
                  :view="view"
                  :pre-val="addressPreVal"
                  @address-data="receivedAddressData"
                ></addressComponent>
                <!-- @geopoint="geopoint" -->
              </div>
              <div style="display: flex; flex-direction: column; width: 50%">
                <div class="decision-name-wrapper">
                  <hlx-input
                    v-model:value="regionDescription"
                    :pre-val="regionDescription"
                    type="textarea"
                    :label-value="'Description'"
                    :read-only="view"
                    :required="false"
                    :label-animation="true"
                    :display-error="true"
                    :rows="7"
                    :custom-error-message="customErrorMessage"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                </div>

                <div class="decision-name-wrapper">
                  <hlx-multi-select
                    :key="modalKey"
                    :pre-value="tags"
                    :options="tagsList"
                    prop-value="name"
                    :multi-select="true"
                    :placeholder="'Tags'"
                    :label-animation="true"
                    :disabled="view"
                    :clearable="true"
                    :tooltipposition="'left'"
                    @add-data="addTagList"
                    @custom-change="receivedTags"
                  ></hlx-multi-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="leftpannel == 'Exclude region'">
          <excludedRegion
            :permisstion-data="permisstionData"
            :parentinfo="propParentInfo"
          ></excludedRegion>
        </div>

        <div v-if="leftpannel == 'Activity log'">
          <ActivityLogComponent
            :activity-data="logData"
            :totalfromprop="activitytotal"
            @activityrowpage="activityrowpage1"
            @activitycurrentpage="activitycurrentpage1"
          ></ActivityLogComponent>
        </div>
      </section>
    </main>
  </section>

  <discardComponent
    :show="showSupplierDiscardModal"
    @stay="handleStay"
    @discard="handleDiscard"
    @close="showSupplierDiscardModal = false"
  />
</template>

<script>
import excludedRegion from "@/components/Product/ServiceQualificationDuplicate/excludedRegion.vue";

import leftpannelComponent from "@/components/leftpannelComponent.vue";

import addressComponent from "@/components/Product/ServiceQualificationDuplicate/dynamicAddress.vue";

import discardComponent from "@/components/discardComponent.vue";

import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
// import { de } from "element-plus/es/locale";

export default {
  components: {
    leftpannelComponent,
    addressComponent,
    excludedRegion,
    discardComponent,
  },
  beforeRouteLeave(to, from, next) {
    if (this.discardIt) {
      //this.discardIt will handled on discard modal window discard changes emit
      next(true);
      return;
    }
    // Check for unsaved changes
    /**
     * Param 1 initial paylaod to check against param 2
     * Param 2 updated payload to check against param 1
     */
    const unsavedChanges = this.isPayloadChanged(
      this.initialPayload,
      this.discardPayload
       ); //This isPayloadChanged method was configured in global methods so we can call it any where on vue.It's find a difference between both data and return true if any changes otherwise return false
    console.log(unsavedChanges, "unsavedChanges");
    // If unsaved changes found then display discard modal
    if (unsavedChanges) {
      this.showSupplierDiscardModal = true;
    }
    // Then route as per validation
    next(!unsavedChanges);
    return;
  },
  data() {
    return {
      tempBack: false,
      enableAdd: false,
      showSupplierDiscardModal: false,
      addressExist: false,
      discardIt: false,
      permisstionData: {},
      discardPayload:{},
      initialPayload: {},
      addressKey: 1,

      regionName: "",

      status: "",

      type: "",

      postalCode: "",

      city: "",

      stateOrProvince: "",

      description: "",

      regionDescription: "",

      latitude: null,

      longitude: null,

      tags: [],

      createdAt: "",

      updatedAt: "",

      enableDiscardModal: false,

      addressPreVal: {
        mediumType: "postalAddress",
        characteristic: {
          city: "",
          unit: "",
          country: "",
          state: "",
          postalCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          street: "",
          default: true,
          postalCodePrefix: "",
          "@type": "",
          lat: null,
          lng: null,
        },
      },

      filteredstatus: [],

      view: false,

      selectedValue: "Overview",

      propParentInfo: null,

      payload: {
        mediumType: "",
        characteristic: {
          city: "",
          unit: "",
          country: "",
          state: "",
          postalCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          street: "",
          default: null,
          "@type": "",
          postalCodePrefix: "",
          lat: null,
          lng: null,
          regionName: "",
          servicestatus: "",
          type: "",
          tags: [],
          description1: "",
          category: "",
          updatedat: "",
          createdat: "",
        },
      },

      sideBarData: {},
      tempSideBar: {
        main: [
          {
            id: 1,
            name: "Overview",
            icon: "icon-presentation-regular",
            active: true,
            view: true,
            edit: true,
            delete: true,
            add: true,
            all: true,
          },
          {
            id: 2,
            name: "Exclude region",
            icon: "icon-location-pin-times-regular",
            active: false,
            view: true,
            edit: true,
            delete: true,
            add: true,
            all: true,
          },
          //                     {
          //     "id": 8,
          //     "name": "Activity log",
          //     "icon": "icon-time-forward-regular",
          //     "active": false,
          //     "view": true,
          //     "edit": true,
          //     "delete": true,
          //     "add": true,
          //     "all": true
          // }
        ],
      },

      leftpannel: "Overview",

      dropdown_data_type: [
        // { value: "Full address" },
        // { value: "Street" },
        { value: "City" },
        { value: "Province" },
        { value: "Postal code" },
        // { value: "Prefix (FSA)" }
      ],

      newCoordinate: {},

      tagsList: [],

      notification: "",

      initialData: {
        characteristic: {
          regionName: null,
          servicestatus: null,
          description1: null,
          tags: null,
          city: null,
          unit: null,
          state: null,
          postalCode: null,
          stateOrProvince: null,
          street1: null,
          street2: null,
          description: null,
          street: null,
          default: null,
          postalCodePrefix: null,
          "@type": null,
          lat: null,
          lng: null,
        },
      },
    };
  },

  computed: {
    getBreadcrumbs() {
      return [
        {
          label: "serviceQualification2",
          link: `/servicequalification`,
        },
      ];
    },
  },

  watch: {
    status: {
      handler(val) {
        val;
        if (this.type && this.regionName && this.status && !this.addressExist) {
          this.enableAdd = true;
        } else {
          this.enableAdd = false;
        }
        this.discardPayload.status = val;
      },
      immediate: true,
      deep: true,
    },

    type: {
      handler(val) {
        val;
        if (this.type && this.regionName && this.status && !this.addressExist) {
          this.enableAdd = true;
        } else {
          this.enableAdd = false;
        }
        this.discardPayload.type = val;
      },
      immediate: true,
      deep: true,
    },

    regionName: {
      handler(val) {
        val;
        if (this.type && this.regionName && this.status && !this.addressExist) {
          this.enableAdd = true;
        } else {
          this.enableAdd = false;
        }
        this.discardPayload.regionName = val;
        console.log(this.discardPayload, "this.discardPayload");
        
      },
      immediate: true,
      deep: true,
    },
    
    addressExist: {
      handler(val) {
        val;
        if (this.type && this.regionName && this.status && !this.addressExist) {
          this.enableAdd = true;
        } else {
          this.enableAdd = false;
        }
      },
      immediate: true,
      deep: true,
    },

tags: {
      handler(val) {
        val;
        this.discardPayload.tags = val;
      },
      immediate: true,
      deep: true,
    },

    city: {
      handler(val) {
        val;
        this.discardPayload.city = val;
      },
      immediate: true,
      deep: true,
    },

    postalCode: {
      handler(val) {
        val;
        this.discardPayload.postalCode = val;
      },
      immediate: true,
      deep: true,
    },

    stateOrProvince: {
      handler(val) {
        val;
        this.discardPayload.stateOrProvince = val;
      },
      immediate: true,
      deep: true,
    },


    regionDescription: {
      handler(val) {
        val;
        this.discardPayload.regionDescription = val;
      },
      immediate: true,
      deep: true,
    },

description: {
      handler(val) {
        val;
        this.discardPayload.description = val;
      },
      immediate: true,
      deep: true,
    },


  },

  async mounted() {

    this.$route.query.id

    if(this.$route.query.mode === 'view'){
      this.view = true;
    }else{
      this.view = false;
    }
        
    const value = await MLTAxiosInstance.get("partymanagement/individual/auth");

    let sampleTheadsd = await MLTAxiosInstance.get(
      `partyRole/get/${value.data.userId}`
    );

    const mergedPayload =
      sampleTheadsd?.data?.permission["Service qualification"]?.sections?.region
        ?.sections;

    console.log(mergedPayload, "mergedPayload");
    this.permisstionsData = mergedPayload;
    console.log(
      this.permisstionsData,
      "this.permisstionsData",
      this.tempSideBar
    );

    this.sideBarData = this.mergeSidebarData(
      this.tempSideBar,
      this.permisstionsData
    );
    console.log(this.sideBarData, "this.sideBarData");

    let response = await UPCAxiosInstance.get(
      `/geographicAddress/${this.$route.query.id}`
    );

    this.regionName = response.data.criteria.regionName;
    this.description = response.data.description;
    this.status = response.data.statusInclusion;
    this.type = response.data.criteria.type;
    this.regionDescription = response.data.notes;
    this.createdAt = response.data.createdAt;
    this.updatedAt = response.data.updatedAt;
    this.city = response.data.city;
    this.postalCode = response.data.postalCode;
    this.stateOrProvince = response.data.stateOrProvince;
    let tags = response?.data?.tags;
    console.log("tags:::1", tags);
    this.tags = tags;
    this.discardPayload = {
      regionName: this.regionName,
      status: this.status,
      type: this.type,
      regionDescription: this.regionDescription,
      city: this.city,
      postalCode: this.postalCode,
      stateOrProvince: this.stateOrProvince,
      tags: this.tags,
      description: this.description,
    }

    this.initialPayload = {
      regionName: response.data.criteria.regionName,
      status: response.data.statusInclusion,
      type: response.data.criteria.type,
      regionDescription: response.data.notes,
      city: response.data.city,
      postalCode: response.data.postalCode,
      stateOrProvince: response.data.stateOrProvince,
      tags: response?.data?.tags,
description: response.data.description,
    }
    

    
    let propParentInfo = {
      type: response.data.criteria.type.toLowerCase() || "",
      address:
        (await this.getAddressValue(
          response.data.criteria.type,
          response.data
        )) || "",
    };

    this.propParentInfo = propParentInfo;
    console.log("this.parentInfo:::", this.propParentInfo);

    

    this.addressPreVal = {
      characteristic: {
        city: this.city || "",
        unit: "",
        country: "",
        state: this.stateOrProvince || "",
        postalCode: this.postalCode || "",
        stateOrProvince: "",
        street1: "",
        street2: "",
        description: this.description || "",
        street: "",
        default: true,
        postalCodePrefix: "",
        "@type": "",
        lat: this.latitude || null,
        lng: this.longitude || null,
      },
    };

    let status = await this.getReferanceData("statuslist");
    console.log("status::", status);
    this.filteredstatus = status.filter((item) =>
      ["Active", "Inactive", "Pending"].includes(item.name)
    );



    // if (tags) {
    //   this.payload.characteristic.tags = tags.map(item => ({ name: item }));
    // }

    let tagsListData = await this.getReferanceData("tagsList");
    this.tagsList = tagsListData?.filter((item) => {
      return item.module === "sq region";
    });

    //   this.$nextTick(() => {
    // this.loadGoogleMapsScript().then(() => {
    //      // Initialize Google Maps components here
    //      const autocompleteService = new window.google.maps.places.AutocompleteService();
    //   console.log('AutocompleteService Loaded from autoaddress:', autocompleteService);

    //   this.enableWatch = true;

    //    });
    //   });
  },

  methods: {
    handleStay() {
      this.showSupplierDiscardModal = !this.showSupplierDiscardModal;
      this.tempBack = false;
    },
    handleDiscard() {
      //Set tempBack = true when Back is clicked from breadcrumbs
      if (this.tempBack) {
        this.$router.back();
      } else {
        this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      }

      this.showSupplierDiscardModal = !this.showSupplierDiscardModal;

      this.discardIt = true;
    },
    preventPageReload(event) {
      this.showSupplierDiscardModal = false;
      const unsavedChanges = this.isPayloadChanged(
        this.initialPayload,
        this.discardPayload
      );
      if (!unsavedChanges) return;

      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
      return;
    },
    arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false; // If arrays have different lengths, they are not equal
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false; // If any element doesn't match, the arrays are not equal
      }
      return true; // Arrays are equal if all elements match
    },
    isData1PresentInData2(data1, data2) {
      for (const key in data1) {
        // Check if the key exists in data2 and if the values match
        if (Object.prototype.hasOwnProperty.call(data1, key)) {
          if (Array.isArray(data1[key])) {
            // Handle array comparison
            if (
              !Array.isArray(data2[key]) ||
              !this.arraysEqual(data1[key], data2[key])
            ) {
              return true; // Return true if arrays are not equal
            }
          } else {
            // Handle regular key comparison
            if (data2[key] !== data1[key]) {
              return true; // Return true if data1's value doesn't match or is missing in data2
            }
          }
        }
      }
      return false; // All data from data1 is present in data2 and matches
    },

 

 

    async getAddressValue(type, data2) {
      const mapping = {
        // "Full address": "description",
        // "Street": "street",
        City: "city",
        Province: "stateOrProvince",
        "Postal code": "postalCode",
        // "Prefix (FSA)": "postalCodePrefix"
      };

      // Find the corresponding key in data2.characteristic
      const key = mapping[type];
      const value = key ? data2[key] : null;

      // If the type is "Province", add the short form logic
      if (type === "Province" && value) {
        const shortForm = await this.getProvinceShortForm(value);
        return { long: value.toLowerCase(), short: shortForm.toLowerCase() };
      }

      // For other types, just return the value
      return value.toLowerCase();
    },

    async getProvinceShortForm(provinceName) {
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        provinceName
      )}&key=${process.env.VUE_APP_GOOGLE_MAP_KEY}`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === "OK") {
          // Find the administrative area level 1 (province/state)
          const provinceComponent = data.results[0].address_components.find(
            (component) =>
              component.types.includes("administrative_area_level_1")
          );

          if (provinceComponent) {
            const shortForm = provinceComponent.short_name; // e.g., "QC" for Quebec
            return shortForm;
          }
        }

        console.error("Province not found or invalid response");
        return null;
      } catch (error) {
        console.error("Error fetching from Google Maps API:", error);
        return null;
      }
    },

    async patchRegion() {
      setTimeout(async () => {
        let nem = {
          // streetAddressLine:[this.payload]

          description: this.description || "",
          notes: this.regionDescription || "",
          postalCode: this.postalCode || "",
          latitude: null,
          longitude: null,
          city: this.city || "",
          country: this.country || "",
          stateOrProvince: this.stateOrProvince || "",
          street: "",
          unit: "",
          createdAt: this.createdAt || "",
          updatedAt: this.formatCurrentTime() || "",
          tags: this.tags || [],
          type: "inclusion",
          createdBy: this.$cookies.get('company'),
          statusInclusion: this.status || "",
          status:null,
          criteria: {
            type: this.type || "",
            regionName: this.regionName || "",
          },
        };

        let response = await UPCAxiosInstance.patch(`/geographicAddress`, {
          id: this.$route.query.id,
          ...nem,
        });
        console.log("response:patch:::", response);

        if (response) {
          this.notification = {
            type: "success",
            message: `Region details updated successfully!`,
          };
          this.initialPayload = JSON.parse(JSON.stringify( {
            regionName: this.regionName,
            status: this.status,
            type: this.type,
            regionDescription: this.regionDescription,
            city: this.city,
            postalCode: this.postalCode,
            stateOrProvince: this.stateOrProvince,
            tags: this.tags,
            description: this.description
          }));

          this.view = true;
        }
      }, 1100);
    },

    async addTagList(e) {
      console.log("addTagList::", e);

      let obj = {};
      obj.name = e.name;
      obj.module = "sq region";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsList = await DMNAxiosInstance.get(`mapRef/collection/tagsList`);
      let arr = this.tags;
      arr.push(e.name);
      this.tags = arr;
      this.tagsList = tagsList.data.data.filter((item) => {
        return item.module === "sq region";
      });
      // console.log(e);
      // this.tagsList.push(e);
    },

    receivedTags(e) {
      console.log("receivedTags::", e);
      let arr = [];
      e.filter((item) => {
        if (item.checked == true) {
          arr.push(item.name);
        }
      });
      this.tags = arr;
      console.log(
        "this.tags::"
        // this.payload.characteristic.tags
      );
    },

    async receivedAddressData(val) {
      console.log("receivedAddressData::", val, this.payload);
      // this.payload = val;

      this.city = val.characteristic.city;
      this.postalCode = val.characteristic.postalCode;
      this.stateOrProvince = val.characteristic.state;
      this.description = val.characteristic.description;

      let r = await this.callFilterInclusion(this.type, val.characteristic);
      console.log("r::", r);

      this.addressExist = r;
    },

    getSelectedSku(val) {
      val;
      console.log("getSelectedSku::::", val);

      this.type = val.value;

      let addressPreee = {
        characteristic: {
          city: "",
          unit: "",
          country: "",
          state: "",
          postalCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          street: "",
          default: true,
          postalCodePrefix: "",
          "@type": "",
          lat: null,
          lng: null,
        },
      };
      this.addressPreVal = addressPreee;
      this.addressKey += 1;
    },

    emitStatus1(status) {
      this.status = status?.value;
      console.log(
        "status::",
        // this.addressKey,
        status.value
      );
    },

    goTo() {
      if(this.leftpannel == 'Overview'){
        this.tempBack = true;
    console.log("this.initialPayload:::", this.initialPayload , this.discardPayload); 
    
        this.$router.back();
      }else{
        this.discardPayload = this.initialPayload
        this.tempBack = false;
        this.$router.back();
      }
      
     
    },

    compareInitWithFinal() {
      this.initialData;
      // this.enableDiscardModal
      let boold = this.isData1PresentInData2(
        this.initialData.characteristic,
        this.payload.characteristic
      );

      console.log(
        "compareInitWithFinal:",
        this.initialData.characteristic,
        this.payload.characteristic,
        boold
      );

      if (boold) {
        this.enableDiscardModal = true;
      } else {
        this.$router.back();
      }
    },

    async valueleft(val, object) {
      this.leftpannel = val;
      this.permisstionData = object;
      console.log(val, object, "objectobjectobject", this.permisstionData);
      if (object.edit === false || !object) {
        this.view = true;
      }
      if (val.toLowerCase() === "overview") {
        let response = await UPCAxiosInstance.get(
          `/geographicAddress/${this.$route.query.id}`
        );

        this.regionName = response.data.criteria.regionName;
        this.status = response.data.statusInclusion;
        this.type = response.data.criteria.type;
        this.regionDescription = response.data.notes;
        this.createdAt = response.data.createdAt;
        this.updatedAt = response.data.updatedAt;
        this.city = response.data.city;
        this.postalCode = response.data.postalCode;
        this.stateOrProvince = response.data.stateOrProvince;
        this.discardPayload = {
      regionName: this.regionName,
      status: this.status,
      type: this.type,
      regionDescription: this.regionDescription,
      city: this.city,
      postalCode: this.postalCode,
      stateOrProvince: this.stateOrProvince,
      tags: this.tags,
      description: this.description,
    }

    this.initialPayload = {
      regionName: response.data.criteria.regionName,
      status: response.data.statusInclusion,
      type: response.data.criteria.type,
      regionDescription: response.data.notes,
      city: response.data.city,
      postalCode: response.data.postalCode,
      stateOrProvince: response.data.stateOrProvince,
      tags: response?.data?.tags,
description: response.data.description,
    }
        // supply country detail as prop for exclusion
        let propParentInfo = {
          type: response.data.criteria.type.toLowerCase() || "",
          address:
            (await this.getAddressValue(
              response.data.criteria.type,
              response.data
            )) || "",
        };

        this.propParentInfo = propParentInfo;
        console.log("this.parentInfo:::", this.propParentInfo);

        this.addressPreVal = {
          characteristic: {
            city: this.city || "",
            unit: "",
            country: "",
            state: this.stateOrProvince || "",
            postalCode: this.postalCode || "",
            stateOrProvince: "",
            street1: "",
            street2: "",
            description: this.description || "",
            street: "",
            default: true,
            postalCodePrefix: "",
            "@type": "",
            lat: this.latitude || null,
            lng: this.longitude || null,
          },
        };

        let tags = response?.data?.tags;
        console.log("tags:::1", tags);
        this.tags = tags;

        //         if (response.data.streetAddressLine[0]) {
        //   this.payload.characteristic.description =
        //     this.payload.characteristic.description;
        // }
      }
    },
  },
};
</script>

<style></style>
