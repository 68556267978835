<template>
        <hlx-alert-notification :notify="notification" auto-close />
    <section class = "confirm">
      <div class="logo"><img src="https://halleyx.com/img/halleyx-logo-line-black.05c516d1.svg"/></div>
          <hlx-modal
          :key="componentKey"
          :modal-active = "modal_active2"
          :close-icon="false"
          :height="'550px'"
          :width="'550px'"
          :header-content="true"
        >
          <template #header>
            <div>Reset password</div>
          </template>
          <template #content>
            <div class="modal-content">
              <div class="wrapper">
              <div class="form-input-wrapper">
          <p class="input-header">Current password</p>
          <div class="form-input">
            <hlx-input
          v-model:value="password"
          :label-animation="false"
          type="password"
          :password-condition="false"
          :label-value="'Password'"
          :clearable="false"
          :display-error="true"
          :custom-error="passwordValidation.currentPassword.display"
          :custom-error-message="passwordValidation.currentPassword.content"
          @password-validation="currentPasswordValidate"
          />
          </div>
        </div>
        <div class="form-input-wrapper">
          <p class="input-header">New password</p>
          <div class="form-input">
            <hlx-input
          v-model:value="newPassword"
          :label-animation="false"
          type="password"
          :password-condition="true"
          :label-value="'Password'"
          :clearable="false"
          :display-error="true"
          :custom-error="passwordValidation.newPassword.display"
          :custom-error-message="passwordValidation.newPassword.content"
          @password-validation="newPasswordValidate"
          />
  
          </div>
        </div>
        <div class="form-input-wrapper">
          <p class="input-header">Confirm password</p>
          <div>
          <div class="form-input">
            <hlx-input
          v-model:value="conf_password"
          :label-animation="false"
          type="password"
          :password-condition="false"
          :label-value="'Password'"
          :clearable="false"
          :display-error="true"
          :custom-error="passwordValidation.confirmPassword.display"
          :custom-error-message="passwordValidation.confirmPassword.content"
          @password-validation="ConfirmPasswordValidate"
          />
              <!-- <hlx-input
              v-model:value="conf_password"
            :label_animation="true"
            type="confirmpassword"
            :password_condition="true"
            label_value="Confirm password"
            :clearable="false"
          /> -->
          </div>
        </div>
      
      </div>
      <div class="button-block">
        <hlx-button
        v-if="validateCurrentPassword == true && validateConfirmPassword == true && validateNewPassword == true && conditionConfirmPassword == true && conditionNewPassword == true"
        class="change primary" @click="changePassword">Change password</hlx-button
            >
          <hlx-button v-else :disabled="true"  class="change primary" >Change password</hlx-button
          >
          </div>
      </div>
  </div>
            </template>
            </hlx-modal>
    </section>
  </template>
  
  <script>
  import { MLTAxiosInstance } from '@/config/axiosConfig';
//   import axios from 'axios';
  export default {
     name: "InviteUser", 
     data() {
      return { 
        items: [
          { label: "Back", link: "/" },
        ],
         notification: {},
        showAlert: false,
        componentKey:0,
        validateCurrentPassword:false,
        validateNewPassword:false,
        validateConfirmPassword:false,
          conditionConfirmPassword:false,
          conditionNewPassword:false,
          token: this.$route.params.token,
          modal_active2:true,
          company: this.$cookies.get("company"),
          userId:'',
          password:'',
          conf_password:'',
          newPassword:'',
          passwordValidation:{
            currentPassword:{
                content:'',
                display:false
            },
            newPassword:{
                content:'',
                display:false
            },
            confirmPassword:{
                content:'',
                display:false
            }
          }
        //   fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST
      }
     },
     async mounted(){
        this.componentKey += 1
        //  console.log(this.$route.query.id,'iddd')

    //       let token = {
    //   headers: {
    //     Authorization: 'Bearer ' + this.token,
    //     },
    //   }; 
      
      
    //   let individualRes = await axios.get(this.fet +
    //     `/partymanagement/individual/auth`, token
    //   );
      
    //   this.company = individualRes.data.company;
    //   this.userId = individualRes.data.userId;    
    //   const userData = await MLTAxiosInstance.get(`/partymanagement/organisation/${this.$route.query.id}?company=${this.company}`)
    //   console.log(userData.data,'user')                                                                                                                                                                      
     },
     methods:{
        pathTo(){
          this.$router.replace('/settings/users')
        },
    async currentPasswordValidate(value){
        if(this.password != ''){
        value
        console.log(value,'vallal')
        let passwordCheck = {
        email: this.$route.query.email,
        password: this.password,
      };
      const move_dashboard = await MLTAxiosInstance.post(
          `partymanagement/individual/login`,
          passwordCheck
        );
        console.log(move_dashboard,'checkValidate')
        if(move_dashboard.data == 'Incorrect email/password !!'){
          this.passwordValidation['currentPassword'].content = 'Incorrect password. Please try again.'
          this.passwordValidation['currentPassword'].display = true
          this.validateCurrentPassword = false
        }else{
          this.passwordValidation['currentPassword'].content = ''
          this.passwordValidation['currentPassword'].display = false
          this.validateCurrentPassword = true
        }
    }
    },
    async newPasswordValidate(value){
        if(value == true){
            this.conditionNewPassword = true
        }else{
            this.conditionNewPassword = false
        }
        if(this.newPassword != ''){
        value
        console.log(value,'vallal')
        let login = {
        email: this.$route.query.email,
        password: this.newPassword,
      };
        const move_dashboard = await MLTAxiosInstance.post(
          `partymanagement/individual/login`,
          login
        );
        if(move_dashboard.data == 'Incorrect email/password !!'){
          this.passwordValidation['newPassword'].content = ''
          this.passwordValidation['newPassword'].display = false
          this.validateNewPassword = true
        }else{
          this.passwordValidation['newPassword'].content = 'New password cannot be the same as the current password.'
          this.passwordValidation['newPassword'].display = true
          this.validateNewPassword = false
        } 
    }
    },
    async ConfirmPasswordValidate(value){
        if(value == true){
            this.conditionConfirmPassword = true
        }else{
            this.conditionConfirmPassword = false
        }
        if(this.conf_password != ''){
            if(this.conf_password != this.newPassword){
            this.passwordValidation['confirmPassword'].content  = 'Password must match the new password.'
            this.passwordValidation['confirmPassword'].display = true
            this.validateConfirmPassword = false
        }else{
          this.passwordValidation['confirmPassword'].content = ''
            this.passwordValidation['confirmPassword'].display = false
            this.validateConfirmPassword = true
        } 
        }
    },
    async changePassword(){
        let obj = {
          id:this.$route.query.id,
          password:this.conf_password
        }
        const updateInfo=await MLTAxiosInstance.patch(`partymanagement/individual`,obj)
        if (updateInfo.data != undefined) {
            this.showAlert = true;
            this.notification = {
              type: this.type,
              message: "Password updated successfully",
            };
          }
    }
     }
  }
  </script>
  
  <style>
  
  </style>