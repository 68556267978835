<template>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />

  <div
    v-if="!AccessDenied && issupplierAccepted"
    class="accepted-quote-message-block"
    style="
      height: 100%;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    "
  >

    <img v-if="$route.query.Password === 'NewPassword'" width="92px" src="@/assets/svg/createPassword.svg" />
    <img v-if="$route.query.Password === 'RestPassword'" width="92px" src="@/assets/svg/resetPasword.svg" />
    <h3
      style="
        font-family: Quicksand;
        font-size: 18px;
        font-weight: 600;
        line-height: 22.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      "
    >
    <span v-if="$route.query.Password === 'NewPassword'">Account created successfully</span>
    <span v-if="$route.query.Password === 'RestPassword'">Password reset successfully</span>
      
    </h3>
    <p
      style="
        font-family: Quicksand;
        font-size: 10px;
        font-weight: 400;
        line-height: 12.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(94, 94, 94, 1);
      "
    >
      Your account is ready to use. Let's get started!
    </p>
    <hlx-button
      class="change primary"
      style="margin-top: 6px;"
      @click="$router.push(`/`)"
    >
      Login now
    </hlx-button>
  </div>

  <div
    v-else-if="AccessDenied"
    class="access-denied-message-block"
    style="
      height: 100%;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    "
  >
    <img src="@/assets/images/svg/403.svg" />

    <h3 style="color: #191a15; line-height: 1.9">Access forbidden</h3>
    <p>
      Apologies, you've encountered a 403 error. This could be due to either the
      recovery link you selected having expired.
    </p>
  </div>
  <section v-if="!AccessDenied && !issupplierAccepted" class="confirm">
    <!--  -->
    <div class="logo">
      <img src="https://halleyx.com/img/halleyx-logo-line-black.05c516d1.svg" />
    </div>
    <div>
      <hlx-modal
        :modal-active="modal_active2"
        :close-icon="false"
        :height="'400px !important'"
        :width="'550px !important'"
        :header-content="true"
      >
        <template #header>
          <div
            v-if="$route.query.Password === 'NewPassword'"
            style="margin: 0 auto"
          >
            Create password
          </div>
          <div
            v-if="$route.query.Password === 'RestPassword'"
            style="margin: 0 auto"
          >
          Reset password
          </div>
        </template>
        <template #content>
          <div class="modal-content">
            <div class="wrapper">
              <div class="form-input-wrapper">
                <p class="input-header">Create password</p>
                <div class="form-input">
                  <hlx-input
                    v-model:value="password"
                    :label_animation="true"
                    type="password"
                    :password_condition="true"
                    label_value="Password"
                    :clearable="false"
                  />
                </div>
              </div>
              <div class="form-input-wrapper">
                <p class="input-header">Confirm password</p>
                <div>
                  <div class="form-input">
                    <hlx-input
                      v-model:value="conf_password"
                      :label_animation="true"
                      type="confirmpassword"
                      :password_condition="true"
                      label_value="Confirm password"
                      :display-error="true"
                      :custom-error="isConfPasswoedEmpty"
                      :custom-error-message="'Passwords do not match'"
                      :clearable="false"
                      @at-input="confPassowrd"
                    />
                  </div>
                </div>
              </div>
              <div class="button-block">
                <hlx-button class="change primary" @click="login()">
                  <span 
                    >Create new password
                  </span>
                </hlx-button>
              </div>
            </div>
          </div>
        </template>
      </hlx-modal>
    </div>
  </section>
</template>

<script>
import CryptoJS from "crypto-js";
import axios from "axios";
import {
  UPCAxiosInstance,
  MLTAxiosInstance,
  DMNAxiosInstance,
} from "@/config/axiosConfig";

// import CryptoJS from "crypto-js";
export default {
  name: "InviteUser",
  data() {
    return {
      supplierData: [],
      AccessDenied: false,
      notification: {},
      isConfPasswoedEmpty: false,
      token: this.$route.params.token,
      modal_active2: true,
      company: "",
      userId: "",
      password: "",
      conf_password: "",
      decryptedText: "",

      fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
    };
  },
  computed: {
    issupplierAccepted() {
      return this.supplierData?.setValidity?.toLowerCase() == "active";
    },
  },
  async mounted() {
    console.log(this.$route.params.encryptedToken, "this.$route.params.token");
    console.log(this.$route.query.Password, "this.$route.query.Password");

    let access = this.$route.params.encryptedToken;
    console.log("🚀 ~ mounted ~ access:", access);
    try {
      const response = await UPCAxiosInstance.get(
        `/tokenblocker/isValidToken?token=${access}`,
        {
          headers: {
            Authorization: "Bearer " + access,
          },
        }
      );

      console.log("Response:", response.data);
    } catch (error) {
      if (error.response) {
        this.AccessDenied = true;
        console.log("Error data:", error.response.data);
        console.log("Error status:", error.response.setValidity);
        console.log("Error headers:", error.response.headers);
      } else if (error.request) {
        console.log("Error request:", error.request);
      } else {
        console.log("Error message:", error.message);
      }
      console.log("Error config:", error.config);
    }

    if (!this.AccessDenied) {
      let data = null;

      data = await MLTAxiosInstance.get(
        `/partymanagement/individual/${this.$route.params.id}?company=${this.$route.params.companyName}`,
        {
          headers: {
            Authorization: "Bearer " + access,
          },
        }
      );

      this.supplierData = data?.data;
    }
    console.log(this.supplierData, "supplierData");
  },
  methods: {
    padKey(key, length) {
  return key.padEnd(length, "0").substring(0, length); // Pad with zeros or truncate
},
    confPassowrd(val){
      console.log(val,"isConfPasswoedEmptyisConfPasswoedEmpty");
      
      if(this.password === val){
        this.isConfPasswoedEmpty = false;
      }else{
        this.isConfPasswoedEmpty = true;
      }
    },
    encryptPassword(password, secretKey) {
      const key = CryptoJS.enc.Utf8.parse(this.padKey(secretKey, 32)); // AES-256 requires 32 bytes
      const iv = CryptoJS.lib.WordArray.random(16);

      const encrypted = CryptoJS.AES.encrypt(password, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return {
        iv: iv.toString(CryptoJS.enc.Base64),
        ciphertext: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
      };
},
    async login() {
      let access = this.$route.params.encryptedToken;
      const headers = { Authorization: "Bearer " + access };
      console.log(headers, "this.$cookies.get(");
      if (this.password === this.conf_password) {
        const secretKey = "mySecretKey123"; // Pad or truncate to the required length
        const password = this.conf_password;
        const encryptedData = this.encryptPassword(password, secretKey);
        this.supplierData.setValidity = "Active";
        this.supplierData.password = encryptedData;

        try {
          const response = await MLTAxiosInstance.patch(
            `/partymanagement/individual?company=${this.$route.params.companyName}`,
            {
              ...this.supplierData,
              id: this.supplierData._id,
            },
            { headers }
          );

          // Log the response to the console
          console.log("Response:", response);
          if (response && response?.data && response?.data?.company) {
            console.log("t3t3t3 first response");
            // this.addLanguageForAllReference(response?.data);
          }
        } catch (error) {
          // Log any errors to the console
          console.error("Error:", error);
        }
        const postapi = await UPCAxiosInstance.post(
          "/tokenblocker",
          {
            token: this.$route.params.encryptedToken,
            company: this.$route.params.companyName,
          },
          { headers }
        );

        console.log("🚀 ~ signAndApprove ~ updatedQuote:", postapi);
        (this.password = ""), (this.conf_password = "");
      } else {
        console.log("please cheak password");
        (this.password = ""), (this.conf_password = "");
        this.isConfPasswoedEmpty = true;
      }
      // if (this.password === this.conf_password) {
      //   let update = {
      //     id: this.$route.params.id,
      //     status: "Active",
      //     password: this.password,
      //   };
      //   const addSupplier = await axios.patch(
      //     `https://appbackend-ghubgwxrcq-pd.a.run.app/partymanagement/individual?company=${this.$route.params.companyName}`,
      //     update,
      //     {
      //       headers: {
      //         "hlx-api-key": this.$route.params.encryptedToken,
      //       },
      //     }
      //   );
      //   this.isConfPasswoedEmpty = false;
      //   (this.password = ""), (this.conf_password = "");
      //   this.$router.push(`/`);
      //   if (addSupplier.data.email) {
      //     this.alertmsg = "success";
      //     this.notification = {
      //       type: "success",
      //       message: `All set! Your password have been set successfully!`,
      //     };
      //   }
      //   console.log(update, addSupplier.data, "ghjkl");
      // } else {
      //   console.log("please cheak password");
      //   this.isConfPasswoedEmpty = true;
      // }
    },
    async addLanguageForAllReference(data) {
      // let responses = await MLTAxiosInstance.get(
      //   `partymanagement/individual/${data?.userId}`
      // );
      console.log("t3t3t3 qqqqqqqq", data);
      const APIkeys = data?.apiKey;
      console.log("t3t3t3 APIkey", APIkeys);

      let access = this.$route.params.encryptedToken;
      const headers = { Authorization: "Bearer " + access };
      console.log("t3t3t3 headers", headers);

      // console.log("t3t3t3 addLanguageForAllReference");
      // // let language = result1.languageAbility;
      // let company = responses?.data?.company;
      // let APIkey = responses?.data?.apiKey;
      // console.log("t3t3t3 keyyyy",APIkey);

      //to get language
      let responses24 = await axios.get(
        `https://appbackend-ghubgwxrcq-pd.a.run.app/settings`,
        {
          headers: {
            "hlx-api-key": APIkeys,
          },
        }
      );
      console.log("t3t3t3 lang change", responses24);
      let language = responses24?.data?.preferredLanguages;

      const results = language?.reduce((acc, lang) => {
        acc[lang.name] = { type: "String", required: false };
        return acc;
      }, {});
      language = results;

      //   console.log("t3t3t3 wwwww",responses24)
      //   let language = responses24?.data?.preferredLanguages

      // const results = language.reduce((acc, lang) => {
      // acc[lang.name] = { "type": "String", "required": false };
      //   return acc;
      // }, {});
      // language = results;
      let payload1 = {
        type: "filter",
        module: "schema",
        isPaginated: false,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: data?.company,
      };
      console.log("t3t3t3 payload", payload1);
      let response = await DMNAxiosInstance.post(`util/filterData`, payload1, {
        headers,
      });
      let result2 = response?.data?.data?.data;
      console.log("t3t3t3 response", response, result2);
      for (let i = 0; i < result2?.length; i++) {
        let singleObj = result2[i];
        console.log("t3t3t3 each", singleObj);

        // if (singleObj && singleObj?.name && singleObj?.name === "testStatus") {
        let getSingleSchema = await this.getSingleSchema(
          singleObj?._id,
          APIkeys
        );
        console.log("t3t3t3 getSingleSchema", getSingleSchema);
        if (getSingleSchema) {
          let patchSchema = await this.patchSchema(
            getSingleSchema.name,
            getSingleSchema.schema,
            getSingleSchema.description,
            singleObj?._id,
            language,
            APIkeys
          );
          patchSchema;
          console.log("t3t3t3 patchSchema", patchSchema);
        } else {
          console.log("t3t3t3 getSingleSchema is null");
        }

        // }
        // console.log(`Processing item ${i + 1}: ${this.items[i]}`);
        // Add additional processing here
      }
      console.log("t3t3t3 time end");
    },

    async getSingleSchema(id, APIkey) {
      console.log("t3t3t3 getSingleSchema", id, APIkey);
      let access = this.$route.params.encryptedToken;
      const headers = { Authorization: "Bearer " + access };
      console.log("t3t3t3 headers", headers);
      let response = await axios.get(
        `https://dmnbackend-ghubgwxrcq-pd.a.run.app/rule_engine/schema/${id}`,
        {
          headers: {
            "hlx-api-key": APIkey,
          },
        }
      );
      console.log("t3t3t3 response of getSingleSchema", response);
      if (response && response?.data?.data && response?.data?.data?.name) {
        let obj = {
          name: response?.data?.data?.name,
          schema: response?.data?.data?.schema,
          description: response?.data?.data?.description,
        };
        console.log("t3t3t3 tttttt", obj);

        return obj;
      } else {
        return null;
      }
    },
    async patchSchema(name, schema, description1, id, language, APIkey) {
      console.log(
        "t3t3t3 patchSchema",
        name,
        schema,
        description1,
        id,
        language
      );
      let payload = {
        name: name,
        description: description1,
        schema: schema,
        type: "manual",
      };
      let payload1 = { ...language, ...payload.schema };
      payload.schema = payload1;
      console.log("t3t3t3 payload of patchSchema", payload1, payload);
      // let response = await MLTAxiosInstance.patch(
      //     `/rule_engine/schema/${id}`,
      //     payload
      // );
      let bools = this.checkDataPresence(payload1, language);
      if (bools) {
        let response = await axios.patch(
          `https://dmnbackend-ghubgwxrcq-pd.a.run.app/rule_engine/schema/${id}`,
          payload,
          {
            headers: {
              "hlx-api-key": APIkey,
            },
          }
        );
        console.log("t3t3t3 response of patchSchema", response);
        return response;
      } else {
        return {};
      }
    },
    checkDataPresence(object, news) {
      // Define the data you want to check
      const dataToCheck = news;

      // Check each key in the data to be checked
      for (let key in dataToCheck) {
        // If the key does not exist in the object or its structure does not match, return false
        if (
          !object[key] ||
          object[key].type !== dataToCheck[key].type ||
          object[key].required !== dataToCheck[key].required
        ) {
          return false;
        }
      }
      // If all keys and structures match, return true
      return true;
    },
  },
};
</script>

<style></style>
