<template>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  /> 

  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Mark as {{ contactStatus }}</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to mark <b>{{ delete_name }}</b> as
          <b>{{ contactStatus }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteContactRow"
        >Mark as {{ contactStatus }}</hlx-button
      >
    </template>
  </hlx-modal>

  <div
    v-if="fulltotal === undefined"
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 40px;
      height: 80vh;
    "
  >
    <img src="@/assets/images/supplierContact.svg" alt="" />
    <span
      ><hlx-button
      v-if="permisstionData.add"
        v-show="supplierStatus == false"
        class="primary sm empty-table-add-button"
        @click="showNewContactModal"
      >
        <i class="icon-plus-circle-regular"></i>
        New contact
      </hlx-button></span
    >
  </div>
  <div v-if="fulltotal > 0">
    <b> {{ "Contacts" }}</b>
    <div
      class="cm-accounts-right-panel-top-section-util-container"
      style="
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        margin-bottom: 12px;
        margin-top: 20px;
      "
    >
      <div style="display: flex; align-items: center; font-weight: bold">
        <hlx-switch
          v-model="selectedSwitch"
          :switch-items="switchOptions"
          value-prop="value"
          display-prop="name"
          @change="chosenSwitch"
        />
      </div>

      <div v-if="tableData.length > 0 || searchvalue !== ''">
        <div style="display: flex; gap: 16px">
          <span class="search-container" style="width: 250px">
            <hlx-search @search-key="contactSearch"></hlx-search>
          </span>
          <hlx-button
          v-if="permisstionData.add"
            v-show="supplierStatus == false"
            class="primary sm"
            @click="showNewContactModal"
          >
            <i class="icon-plus-circle-regular"></i> New contact
          </hlx-button>
        </div>
      </div>
    </div>
  </div>

  <div class="add-contact-drawer">
    <hlx-drawer
      :show="showContactModal"
      position="right"
      width="400"
      :footer="true"
      :title="'Add contact'"
      :show-close-icon="true"
      @close="closeContactModal"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <b>Add contact</b>
        </div>
        <div class="body-area-contact-drawer">
          <div style="display: flex; gap: 13px">
            <div>
              <hlx-file-upload
                :file-type="'.jpg,.jpeg,.png'"
                :file-size-limit="'20mb'"
                :uploadtype="'Rectangle'"
                :style-background="backgrounStyle"
                :height="100"
                :width="100"
                :read-only="false"
                :custom-icon="icon"
                :type="'profile-upload'"
                :name-display="payload.firstName"
                :content="'Import'"
                @native-data="profileUpload"
              />
            </div>
            <div style="width: 100%">
              <hlx-input
                v-model:value="payload.firstName"
                :pre-val="payload.firstName"
                :label-value="'First name'"
                :label-animation="true"
                :required="true"
                :display-error="true"
                :custom-error="isFirstNameEmpty"
                :custom-error-message="'Please fill the field'"
                @focus-out="focusOutName"
                @at-input="validateName"
              ></hlx-input>
              <hlx-input
                v-model:value="payload.lastName"
                :pre-val="payload.lastName"
                :label-value="'Last name'"
                :label-animation="true"
                :required="false"
                :display-error="true"
                :custom-error="isLastNameEmpty"
                :custom-error-message="'Please fill the field'"
                @focus-out="focusOutName"
                @at-input="validateName"
              ></hlx-input>
            </div>
          </div>
          <div class="input-wrapper" style="margin-bottom: 20px">
            <!-- <hlx-select
              :key="key"
              v-model:value="payload.status"
              :pre-value="payload.status"
              :options="statusList"
              :disabled="true"
              prop-value="name"
              :placeholder-value="'Status'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select> -->

            <div style="width: 100%">
              <hlx-status
                :type="'select-status'"
                :status-option="filteredstatus"
                :prevalue="payload.status"
                :readonly="true"
                @emit-select="emitStatus1"
              >
              </hlx-status>
            </div>
          </div>

          <addressComponent
            :key="addressKey"
            @address-data="receivedAddressData"
          ></addressComponent>
          <div class="input-wrapper" style="margin-top: 20px">
            <hlx-input
              v-model:value="payload.aristocraticTitle"
              :pre-val="payload.aristocraticTitle"
              :label-value="'Designation'"
              :label-animation="true"
              :required="false"
              :display-error="true"
            ></hlx-input>
          </div>
          <div class="input-wrapper" style="margin-bottom: 20px">
            <hlx-select
              :key="key"
              v-model:value="payload.preferred"
              :pre-value="payload.preferred"
              :options="preferredMediumList"
              prop-value="value"
              :placeholder-value="'Preferred contact medium'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <hlx-input
            v-model:value="payload.contactMedium[0].characteristic.emailAddress"
            :pre-val="payload.contactMedium[0].characteristic.emailAddress"
            :label-value="'Email id'"
            :label-animation="true"
            type="email"
            :required="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>

          <hlx-input
            v-model:value="payload.contactMedium[1].characteristic.phoneNumber"
            :pre-val="payload.contactMedium[1].characteristic.phoneNumber.value"
            type="phonenumber"
            :country-code="
              payload.contactMedium[1].characteristic.phoneNumber.country
            "
            :label-value="'Phone no.'"
            :label-animation="true"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="payload.externalReference[0].name"
            :pre-val="payload.externalReference[0].name"
            :label-value="'Linkedin id'"
            :label-animation="true"
            :display-error="true"
          ></hlx-input>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeContactModal"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 10px"
            :disabled="
              payload.firstName &&
              validateEmail(
                payload.contactMedium[0].characteristic.emailAddress
              ) &&
              validatePhoneNumber(
                payload.contactMedium[1].characteristic.phoneNumber
              ) !== ''
                ? false
                : true
            "
            @click="addContactRow"
            >Add</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <div class="add-contact-drawer">
    <hlx-drawer
      :show="showEditContactModal"
      position="right"
      width="400"
      :footer="true"
      :title="'Add contact'"
      :show-close-icon="true"
      @close="closeEditContactModal"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <!-- <span
            style="
              display: flex;
              width: 25px;
              height: 25px;
              position: fixed;
              right: 400px;
              background: white;
              z-index: 1000;
              top: 0;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
              box-shadow: -2px 0px 6px 0px #b9b6b6;
              cursor: pointer;
            "
            @click="closeEditContactModal"
          >
            <i class="icon-times-regular"></i>
          </span> -->
          <b>Edit contact</b>
        </div>
        <div class="body-area-contact-drawer">
          <div style="display: flex; gap: 13px">
            <div>
              <hlx-file-upload
                :key="profileComponentKey"
                :file-type="'.jpg,.jpeg,.png'"
                :file-size-limit="'20mb'"
                :uploadtype="'Rectangle'"
                :style-background="backgrounStyle"
                :height="100"
                :width="100"
                :read-only="view"
                :custom-icon="icon"
                :type="'profile-upload'"
                :name-display="editPayload.firstName"
                :content="'Import'"
                :profile-pic-preview="profilePicUrl"
                @native-data="editprofileUpload"
                @delete-image="deleteProfilePicture"
              />
            </div>
            <div style="width: 100%">
              <hlx-input
                v-model:value="editPayload.firstName"
                :pre-val="editPayload.firstName"
                :label-value="'First name'"
                :label-animation="true"
                :required="true"
                :display-error="true"
                :custom-error="isFirstNameEmpty"
                :custom-error-message="'Please fill the field'"
                @focus-out="focusOutName"
                @at-input="validateName"
              ></hlx-input>
              <hlx-input
                v-model:value="editPayload.lastName"
                :pre-val="editPayload.lastName"
                :label-value="'Last name'"
                :label-animation="true"
                :required="true"
                :display-error="true"
                :custom-error="isLastNameEmpty"
                :custom-error-message="'Please fill the field'"
                @focus-out="focusOutName"
                @at-input="validateName"
              ></hlx-input>
            </div>
          </div>
          <div class="input-wrapper" style="margin-bottom: 20px">
            <!-- <hlx-select
              :key="key"
              v-model:value="editPayload.status"
              :pre-value="editPayload.status"
              :options="statusList"
              :disabled="true"
              prop-value="name"
              :placeholder-value="'Status'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select> -->

            <div style="width: 100%">
              <hlx-status
                :type="'select-status'"
                :status-option="filteredstatus"
                :prevalue="editPayload.status"
                :readonly="true"
                @emit-select="emitStatus2"
              >
              </hlx-status>
            </div>
          </div>

          <addressComponent
            :pre-val="addressPreVal"
            @address-data="receivedAddressDataedit"
          ></addressComponent>
          <div class="input-wrapper" style="margin-top: 20px">
            <hlx-input
              v-model:value="editPayload.aristocraticTitle"
              :pre-val="editPayload.aristocraticTitle"
              :label-value="'Designation'"
              :label-animation="true"
              :required="false"
              :display-error="true"
              :custom-error="isLastNameEmpty"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="input-wrapper" style="margin-bottom: 20px">
            <hlx-select
              :key="key"
              v-model:value="editPayload.preferred"
              :pre-value="preferredType"
              :options="preferredMediumList"
              prop-value="value"
              :placeholder-value="'Preferred contact medium'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <hlx-input
            v-model:value="
              editPayload.contactMedium[0].characteristic.emailAddress
            "
            :pre-val="editPayload.contactMedium[0].characteristic.emailAddress"
            :label-value="'Email id'"
            :label-animation="true"
            type="email"
            :required="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="
              editPayload.contactMedium[1].characteristic.phoneNumber
            "
            :pre-val="
              editPayload.contactMedium[1].characteristic.phoneNumber.value
            "
            type="phonenumber"
            :country-code="
              editPayload.contactMedium[1].characteristic.phoneNumber.country
            "
            :label-value="'Phone no.'"
            :label-animation="true"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="editPayload.externalReference[0].name"
            :pre-val="editPayload.externalReference[0].name"
            :label-value="'Linkedin id'"
            :label-animation="true"
            :display-error="true"
          ></hlx-input>
          <!-- <hlx-input
            v-model:value="editPayload.notes"
            :pre-val="editPayload.notes"
            :label-value="'Notes'"
            :label-animation="true"
            type="textarea"
            :rows="4"
            :display-error="false"
          ></hlx-input> -->
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeEditContactModal"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 10px"
            :disabled="
              editPayload.firstName &&
              editPayload.lastName &&
              validateEmail(
                editPayload.contactMedium[0].characteristic.emailAddress
              ) &&
              validatePhoneNumber(
                editPayload.contactMedium[1].characteristic.phoneNumber
              ) !== ''
                ? false
                : true
            "
            @click="editContactRow"
            >Save</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>

  <div
    v-if="tableData.length > 0"
    style="height: 74vh; overflow: auto"
    class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
  >
    <hlx-table
      :column-count="accounts_table_theads.length + 2"
      :border="['table', 'header', 'horizontal']"
      :row-hover="true"
      theme="grey"
      :re-arrange-columns="re_arrange"
      :re-arrange-heads="accounts_table_theads_cc"
      @close-rearrange="re_arrange = false"
      @apply-rearranged-columns="applyColumns"

    >
      <template #thead>
        <hlx-table-head
          :width="60"
          :align="'center'"
          style="padding: 0 15px"
          :fixed="'left'"
          @new-resize-width="resizedWidth"
          >{{ "S.no" }}</hlx-table-head
        >
        <hlx-table-head
          v-for="(i, index) in accounts_table_theads"
          :key="index"
          :prop="i.prop"
          :sortable="i.sortable"
          :resizable="i.resizable"
          :width="i.width !== undefined ? i.width : ''"
          :align="i.align !== undefined ? i.align : ''"
          @sorting_func="sorting_Data"
          @new-resize-width="resizedWidth"
        >
          {{ i.label }}
        </hlx-table-head>
        <hlx-table-head
         v-if="permisstionData.edit || permisstionData.delete"
          v-show="supplierStatus == false"
          :align="'center'"
          :width="70"
          :fixed="'right'"
          @new-resize-width="resizedWidth"
          ><span class="action-icon">
            <i class="icon-settings-regular" @click="reAssignOrgTableHeader()"></i> </span
        ></hlx-table-head>
      </template>
      <template #tbody>
        <tr v-for="(i, index) in paginatedData" id="" :key="index">
          <hlx-table-cell :align="'center'" :fixed="'left'">
            {{ serialNumber(index) }}
          </hlx-table-cell>
          <hlx-table-cell
            v-for="(j, col_index) in accounts_table_theads"
            :key="col_index"
            :align="j.align"
            :fixed="j.fixed"
          >
            <div
              v-if="j.prop.toLowerCase() === 'firstname'"
              :id="j.prop + index"
            >
              <span>{{ i[j.prop] + " " + i["lastName"] }}</span>
            </div>

            <div
              v-else-if="j.prop.toLowerCase() === 'id' && permisstionData.edit"
              :id="j.prop + index"
              @click="goToContacts(i)"
            >
              <span class="link-name" style="color: #2879b7; cursor: pointer">
                {{ i[j.prop] }}
              </span>
            </div>
            <div
              v-else-if="j.prop.toLowerCase() === 'id' && !permisstionData.edit"
              
            >
              <span style="color: #2879b7; cursor: pointer">
                {{ i[j.prop] }}
              </span>
            </div>

            <div
              v-else-if="j.prop.toLowerCase() === 'address'"
              :id="j.prop + index"
              style="
                margin: 10px 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              :style="{
                width: j.width + 'px',
              }"
              @mouseover="
                tooltipover(j.prop + index, 'tooltip-text' + j.prop + index)
              "
              @mouseleave="
                tooltipleave(j.prop + index, 'tooltip-text' + j.prop + index)
              "
            >
              {{ i["contactMedium"][2]?.characteristic?.description }}
              <!-- <p id="tooltip-text" :class="'tooltip-text' + j.prop + index">
        {{ i["contactMedium"][3]["location"] }}
      </p> -->
            </div>

            <div v-else-if="j.prop.toLowerCase() === 'status'">
              <span v-if="i[j.prop] != ''">
                <div>
                  <hlx-status :type="i[j.prop]"></hlx-status>
                </div>
              </span>
            </div>
            <div
              v-else-if="j.prop.toLowerCase() === 'contactmedium'"
              style="margin: 0.5rem 0"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                  margin-bottom: 4px;
                "
              >
                <i class="icon-envelope-regular"></i>
                {{ i[j.prop][0]?.characteristic?.emailAddress }}
              </div>
              <div style="display: flex; align-items: center; gap: 0.5rem">
                <i class="icon-phone-regular"></i>
                {{ i[j.prop][1]?.characteristic?.phoneNumber?.value }}
              </div>
            </div>
            <div v-else>{{ i[j.prop] }}</div>
          </hlx-table-cell>
          <hlx-table-cell
           v-if="permisstionData.edit || permisstionData.delete"
            v-show="supplierStatus == false"
            :align="'center'"
            :width="120"
            :fixed="'right'"
          >
            <span :id="'table-context' + index" class="action-icon">
              <i
                style="position: relative"
                class="icon-more-vertical-filled"
                :class="i.context === true ? 'active-action' : ''"
                @click="crudContextMenu($event, i, index)"
              >
                <hlx-context-menu
                  :top="containertop"
                  :left="containerleft"
                  :conditionvalue="150"
                  :conditiondown="2"
                  :conditionup="-38"
                  :options="computedTableAction(i)"
                  :data="index.toString()"
                  :show="i.context == true"
                  @chosen="closeCrudContextMenu($event, i, index)"
                />
              </i>
            </span>
          </hlx-table-cell>
        </tr>
      </template>
    </hlx-table>
  </div>
  <div
    v-if="tableData.length <= 0 && searchvalue === ''"
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 40px;
      height: 70vh;
    "
  >
    <img src="@/assets/images/supplierActiveContact.svg" alt="" />
    <span v-if="selectedSwitch != 'inactive'">
      <hlx-button
      v-if="permisstionData.add"
        v-show="supplierStatus == false"
        class="primary sm"
        @click="showNewContactModal"
      >
        <i class="icon-plus-circle-regular"></i> New contact
      </hlx-button></span
    >
  </div>
  <div
    v-if="tableData.length === 0 && searchvalue !== ''"
    class="cm-accounts-right-panel-top-section-table-wrap"
  >
    <hlx-table
      :column-count="accounts_table_theads.length + 2"
      :border="['table', 'header']"
      :bold-headers="false"
      :row-hover="true"
      theme="grey"
      :striped-rows="false"
      :re-arrange-columns="re_arrange"
      :re-arrange-heads="accounts_table_theads_cc"
      @close-rearrange="re_arrange = false"
      @apply-rearranged-columns="applyColumns"
    >
      <template #thead>
        <hlx-table-head :width="60" :align="'left'" @new-resize-width="resizedWidth">{{
          "S.no"
        }}</hlx-table-head>
        <hlx-table-head
          v-for="(i, index) in accounts_table_theads"
          :key="index"
          :prop="i.prop"
          :sortable="i.sortable"
          :resizable="i.resizable"
          style="position: sticky; top: 0px"
          :width="i.width !== undefined ? i.width : ''"
          :align="i.align !== undefined ? i.align : ''"
          @sorting_func="sorting_Data"
          @new-resize-width="resizedWidth"
        >
          {{ i.label }}
        </hlx-table-head>
        <hlx-table-head  v-if="permisstionData.edit || permisstionData.delete" v-show="supplierStatus == false" :width="100" @new-resize-width="resizedWidth">
          <span class="action-icon"
            ><i class="icon-settings-regular" @click="reAssignOrgTableHeader()"></i></span
        ></hlx-table-head>
      </template>
      <template #tbody>
        <tr>
          <hlx-table-cell></hlx-table-cell>
        </tr>
        <tr>
          <hlx-table-cell></hlx-table-cell>
        </tr>
        <tr>
          <hlx-table-cell></hlx-table-cell>
        </tr>
        <tr>
          <hlx-table-cell></hlx-table-cell>
        </tr>
        <tr>
          <hlx-table-cell :colspan="accounts_table_theads.length + 2">{{
            "No data"
          }}</hlx-table-cell>
        </tr>
        <tr>
          <hlx-table-cell></hlx-table-cell>
        </tr>
        <tr>
          <hlx-table-cell></hlx-table-cell>
        </tr>
        <tr>
          <hlx-table-cell></hlx-table-cell>
        </tr>
        <tr>
          <hlx-table-cell></hlx-table-cell>
        </tr>
        <tr>
          <hlx-table-cell></hlx-table-cell>
        </tr>
      </template>
    </hlx-table>
  </div>

  <div v-if="paginationtotal > 10">
    <hlx-pagination
      v-model:rows-per-page="externalRowsPerPage"
      :total="paginationtotal"
      rows-per-page
      enable-rows-per-page
      :rows-per-page-list="[10, 15, 20]"
      :options="{
        attributes: {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 0,
              right: 1,
            },
          },
        },
      }"
      @current-page="currentPage"
      @updated:rows-per-page="changeRowsPerPage"
    ></hlx-pagination>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import {
  MLTAxiosInstance,
  UPCAxiosInstance,
  DMNAxiosInstance,
} from "@/config/axiosConfig";
import addressComponent from "@/components/CM/autocompleteComponent.vue";

export default {
  components: {
    addressComponent,
  },
  props: {
    permisstionData: {
      type: Object,
      required: true, 
      default: () => {},
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    supplierStatus: {
      type: Boolean,
      default: false,
    },
    // datacontact: {
    //   type: Array,
    //   default: () => {
    //     return [];
    //   },
    // },
    mode: {
      type: String,
      default: "edit",
    },
  },
  emits: ["contact-data", "new-contact", "edit-contact", "delete-contact"],
  data() {
    return {
      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      externalRowsPerPage: 10,
      paginationtotal: 0,
      filteredstatus: [],
      addressKey: 0,
      addressPreVal: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      },
      apiKey: "",
      profilePicUrl: "",
      emailid: "",
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 16,
      },
      notification: {},
      disableContact: true,
      selectedSwitch: "all",
      switchOptions: [
        {
          name: "All",
          value: "all",
        },
        {
          name: "Active",
          value: "active",
        },
        {
          name: "Pending",
          value: "pending",
        },
        {
          name: "Inactive",
          value: "inactive",
        },
      ],
      encodedEncryptedText: "",
      decryptedText: "",

      headpayload: {},
      contactStatus: "",
      showViewContactModal: false,
      showAddNewAdrressModal: false,
      delete_name: "",
      delete_index: "",
      delete_modal_active: false,
      dobKey: 0,
      isTitleEmpty: false,
      isFirstNameEmpty: false,
      isLastNameEmpty: false,
      isEmailEmpty: false,
      isAddButtonDisabled: false,
      tableData: [],
      showContactModal: false,
      showEditContactModal: false,
      payload: {
        familyName: "",
        givenName: "",
        rootName: "",
        name: "",
        firstName: "",
        lastName: "",
        tradingName: "",
        companyName: `${this.$route.params.companyName}`,
        "@baseType": "supplier",
        status: "Pending",
        lastUpdated: new Date().toJSON(),
        preferred: "",
        aristocraticTitle: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            preferred: true,
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        role: "supplier",
        type: "individual",
        notes: "",
        password: "",
        email: "",
        parentCompany: this.company,
      },
      initalpayloads:{},
      editPayload: {
        familyName: "",
        givenName: "",
        name: "",
        firstName: "",
        lastName: "",
        tradingName: "",
        companyName: `${this.$route.params.companyName}`,
        status: "Pending",
        lastUpdated: new Date().toJSON(),
        preferred: "",
        "@baseType": "supplier",
        aristocraticTitle: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],

        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        role: "supplier",
        type: "individual",
        notes: "",
        password: "",
        email: "",
        parentCompany: this.company,
      },
      rows: 5,
      titleList: [
        { name: "Mr.", value: "Mr." },
        { name: "Mrs.", value: "Mrs." },
      ],
      designationList: [],
      statusList: [],
      preferredMediumList: [],
      key: 0,
      accounts_table_theads: [
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 100,
        //   label: "ID",
        //   prop: "id",
        //   align: "left",
        // },
        {
          sortable: false,
          resizable: true,
          disabled:true,
          width: 150,
          label: "Name",
          prop: "firstName",
          align: "left",
          name:"Name",
          checked:true,
        },
        {
          sortable: false,
          resizable: true,
          disabled:false,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
          checked:true,
          name:"Status"
        },
        {
          sortable: false,
          resizable: true,
          width: 300,
          label: "Contact medium",
          prop: "contactMedium",
          disabled:true,
          align: "left",
          checked:true,
          name:"Contact medium"
        },
        {
          sortable: false,
          resizable: true,
          width: null,
          label: "Location",
          disabled:true,
          prop: "address",
          align: "left",
          checked:true,
          name:'Location'
        },
      ],
      currentYear: new Date().getFullYear(),
      instanceData: [],
      instanceAddress: {},
      switchvalue: "",
      accounts_table_theads_cc: [
      {
          sortable: false,
          resizable: true,
          disabled:true,
          width: 150,
          label: "Name",
          prop: "firstName",
          align: "left",
          name:"Name",
          checked:true,
        },
        {
          sortable: false,
          resizable: true,
          disabled:false,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
          checked:true,
          name:"Status"
        },
        {
          sortable: false,
          resizable: true,
          disabled:true,
          width: 300,
          label: "Contact medium",
          prop: "contactMedium",
          align: "left",
          checked:true,
          name:"Contact medium"
        },
        {
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          label: "Location",
          prop: "address",
          align: "left",
          checked:true,
          name:'Location'
        },
      ],
      duplicateTheads: [],
      re_arrange: false,
      currPage: 1,
      rowsPerPage: 10,
      editMode: true,
      copyOfContactTableData: [],
      locationApi: "https://appbackend-ghubgwxrcq-pd.a.run.app/autocomplete",
      locationData: [],
      preferredType: "",
      encryptedToken: "",
      newAddressPayload: {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      },
      activeTable: 0,
      inactiveTable: 0,
      pendingTable: 0,
      showNewAddressOption: false,
      fulltotal: 0,
      pendingtotal: 0,
      Activetotal: 0,
      Inactivetotal: 0,
      searchvalue: "",
      cookies: this.$cookies.get("token"),
      newProfileData: null,
      newProfileformData: null,
      rawProfileUrlData: null,
      profileComponentKey: 0,
      newProfileAdded: false,
      user: {},
      contactData: [],
    };
  },
  computed: {
    paginatedData() {
      return this.tableData;
    },
  },
  watch: {
    showEditContactModal: {
      handler(val) {
        console.log(val, "showEditContactModal");
      },
      immediate: true,
      deep: true,
    },
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList.filter(
          (item) =>
            item.name === "Active" ||
            item.name === "Inactive" ||
            item.name === "Pending"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    switchvalue: {
      handler(val) {
        if (val === "active") {
          this.EditPrevalueStatus = "Active";
        }
        if (val === "inactive") {
          this.EditPrevalueStatus = "Inactive";
        }
        if (val === "pending") {
          this.EditPrevalueStatus = "Pending";
        }
      },
      immediate: true,
      deep: true,
    },
    data: {
      handler(val) {
        this.headpayload = val;
        console.log(val, "product value");
      },
      immediate: true,
      deep: true,
    },
    // datacontact: {
    //   handler(val) {
    //     if (val.length > 0) {
    //       this.tableData = val;
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    payload: {
      handler(val) {
        console.log(val, this.$route.params.companyName);
        val.name = `${val.firstName}${val.lastName}`;
        val.parentCompany = this.company;
        val["@baseType"] = "supplier";
        val.companyName = this.$route.params.companyName;
        val.tradingName = this.$route.params.companyName;
        val.familyName = this.$route.params.companyName;
        val.givenName = this.$route.params.companyName;

        val.email = val.contactMedium[0].characteristic.emailAddress;
        val.rootName = this.company;
        if (val.title !== "") {
          this.isTitleEmpty = false;
        }
        if (val.firstName !== "") {
          this.isFirstNameEmpty = false;
        }
        if (val.lastName !== "") {
          this.isLastNameEmpty = false;
        }
        if (val.email !== "") {
          this.isEmailEmpty = false;
        }
        for (let i = 0; i < val.contactMedium.length; i++) {
          if (val.contactMedium[i].mediumType === val.preferred) {
            val.contactMedium[i].preferred = true;
          } else {
            val.contactMedium[i].preferred = false;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    editPayload: {
      handler(val) {
        console.log(val.aristocraticTitle);
        this.editPayload.aristocraticTitle = val.aristocraticTitle;
        val.id = val._id;
        val.parentCompany = this.company;
        val["@baseType"] = "supplier";
        val.name = `${val.firstName}${val.lastName}`;
        val.companyName = this.$route.params.companyName;
        val.tradingName = this.$route.params.companyName;
        val.familyName = this.$route.params.companyName;
        val.givenName = this.$route.params.companyName;

        val.email = val.contactMedium[0].characteristic.emailAddress;
        if (val.title !== "") {
          this.isTitleEmpty = false;
        }
        if (val.firstName !== "") {
          this.isFirstNameEmpty = false;
        }
        if (val.lastName !== "") {
          this.isLastNameEmpty = false;
        }
        if (val.email !== "") {
          this.isEmailEmpty = false;
        }
        if (val.contactMedium !== undefined) {
          val.email = val?.contactMedium[0].characteristic?.emailAddress;

          for (let i = 0; i < val.contactMedium.length; i++) {
            if (val.contactMedium[i].mediumType === val.preferred) {
              val.contactMedium[i].preferred = true;
            } else {
              val.contactMedium[i].preferred = false;
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
    tableData: {
      handler(val) {
        this.copyOfContactTableData = JSON.parse(JSON.stringify(val));
        console.log(this.copyOfContactTableData);
      },
      immediate: true,
      deep: true,
    },
  },

  async mounted() {
    let accountTableHeads = await MLTAxiosInstance.get(`/settings`);

if (accountTableHeads?.data?.entityRef?.["supplierContact"]) {
  let accountTableHeadModal = accountTableHeads.data.entityRef["supplierContact"];
  let accountTableHeadsOrg = accountTableHeads.data.entityRef["supplierContact"].filter(ele => ele.checked);
  console.log(accountTableHeadsOrg,"accountTableHeadModal",accountTableHeadModal)

  if (
    accountTableHeadModal.length === this.accounts_table_theads_cc.length 
  ) {
    console.log(accountTableHeadsOrg,"accountTableHeadModal dup",accountTableHeadModal)
    this.accounts_table_theads_cc = JSON.parse(JSON.stringify(accountTableHeadModal));
      this.accounts_table_theads = JSON.parse(JSON.stringify(accountTableHeadsOrg));
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
      this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
  }
}
    const fulltableData = {
      company: `${this.$route.params.companyName}`,
      type: "filter",
      module: "hierarchycontacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: this.rowsPerPage,
        currentPage: this.currPage,
      },
      isNeedToBeSort: true,
      lastUpdated: new Date().toJSON(),
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: {},
      searchQuery: "Active",
    };
    const fulltabledata = await MLTAxiosInstance.post(
      `/util/filterData?company=${this.$route.params.companyName}`,
      fulltableData
    );

    this.paginationtotal = fulltabledata.data.total;
    this.fulltotal = fulltabledata.data.total;
    this.tableData = fulltabledata.data.data;
    this.contactData = fulltabledata.data.data;
    let preferredList =  await this.getReferanceData('cmAccountPreferredContactMedium') 
    this.preferredMediumList = preferredList.filter(
      (item) => item.name !== "Fax"
    );
    let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList = statusList.data.data;

    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.user = val.data;
    this.company = val.data.company;
    this.userId = val.data.userId;

    const instance = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${val.data.userId}?company=${val.data.company}`
    );
    this.instanceData = instance.data;
    this.instanceAddress = this.instanceData.contactMedium.find(
      (item) => item.mediumType === "postalAddress"
    );

    // console.log(
    //   this.instanceData,
    //   this.instanceAddress.characteristic.description,
    //   "this.instanceData"
    // );
    this.payload.parentCompany = val.data.company;
    let apiKeyRes = await MLTAxiosInstance.get(
      `partymanagement/individual/getAPIKey`
    );

    if (apiKeyRes.data.ok == 0) {
      return;
    }
    this.apiKey = apiKeyRes.data.data;

    console.log(apiKeyRes.data.data, "apiKeyRes.data.data");
    // console.log(val.data.company,val.data,this.$cookies.get("token"),"dfbnlkn");
    this.$emit("contact-data", this.tableData);
    this.encrypt();
  },
  methods: {
    reAssignOrgTableHeader(){
           // If there is change in the table header then ressign to the Orginal data
           if((this.accounts_table_theads_cc != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.accounts_table_theads_cc = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.accounts_table_theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.accounts_table_theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.re_arrange = true
    },
    resizedWidth(val){
            // To get resized width of the table header
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.accounts_table_theads);
  updateWidth(this.accounts_table_theads_cc);
console.log(this.accounts_table_theads,"resizedWidth loop",this.accounts_table_theads_cc)
this.applyColumns(this.accounts_table_theads_cc)
    },
    encrypt() {
      const encrypted = CryptoJS.AES.encrypt(
        this.apiKey,
        CryptoJS.enc.Utf8.parse(process.env.VUE_APP_ENCODE_KEY),
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();

      // Convert to Base64 and replace characters
      this.encodedEncryptedText = this.base64UrlEncode(encrypted);
      console.log(this.encodedEncryptedText, "this.encodedEncryptedText");
    },
    base64UrlEncode(str) {
      return btoa(str)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    },
    base64UrlDecode(str) {
      str = str.replace(/-/g, "+").replace(/_/g, "/");
      while (str.length % 4) {
        str += "=";
      }
      return atob(str);
    },
    deleteProfilePicture() {
      this.deleteProfilePic = true;
    },
    async editprofileUpload(event) {
      this.newProfileAdded = true;
      this.newProfileData = event.target.files[0];
      this.newProfileformData = new FormData();
      this.newProfileformData.append("files", this.newProfileData);
    },
    async profileUpload(event) {
      console.log(event);
      let selectedFiles = event.target.files[0];
      let formData = new FormData();

      // Append all selected files to FormData object
      formData.append("files", selectedFiles);
      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      console.log(data);

      let obj = {};
      obj.name = data.data[0].filename;
      obj.size = data.data[0].data.attachment.size;
      obj.url = data.data[0].url;
      obj.id = data.data[0].data._id;
      obj["@type"] = "profile";
      // Get the current date and time in milliseconds
      const currentTimestamp = new Date().toJSON();

      // Format the current timestamp in the same format as '1705574420667'
      console.log(currentTimestamp);
      obj.lastUpdated = currentTimestamp;
      this.payload.attachment = [];
      this.payload.attachment.push(obj);

      console.log(this.payload);
    },
    findPreferredMediumType(val) {
      console.log("Contact Medium:", val.contactMedium);

      let preferredType = "";
      val.contactMedium.forEach((medium) => {
        console.log("Medium:", medium);
        // Check if the medium is preferred
        if (medium.preferred) {
          // If preferred, store the mediumType value
          preferredType = medium.mediumType;
        }
      });
      // Set preferredType to the data property
      this.preferredType = preferredType;
      console.log("Preferred Type:", this.preferredType);
    },
    emitStatus1(val1) {
      this.payload.status = val1["value"];
    },
    emitStatus2(val1) {
      this.editPayload.status = val1["value"];
    },
    receivedAddressData(val) {
      console.log(val);
      let address1 = JSON.parse(JSON.stringify(val));
      this.payload.contactMedium[2] = JSON.parse(JSON.stringify(address1));
    },
    receivedAddressDataedit(val) {
      console.log(val);
      let address1 = JSON.parse(JSON.stringify(val));
      this.editPayload.contactMedium[2] = JSON.parse(JSON.stringify(address1));
    },

    validatePhoneNumber(val) {
      return val.valid;
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      }
    },
    async editContactRow() {
            let CM_ContactLog = {
        message: `${this.editPayload.name} Contact has been updated`,
        detailedAction: [],
        "@type": "supplier-contact",
        category: ["supplier"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };

      let nameActionLog = this.buildBasicLogByDifferences(
        this.initalpayloads,
        this.editPayload,
        "firstName",
        "firstName"
      );
      console.log(nameActionLog, "nameActionLog");
      // lastName log
      let lastNameActionLog = this.buildBasicLogByDifferences(
        this.initalpayloads,
        this.editPayload,
        "lastName",
        "lastName"
      );
      console.log(lastNameActionLog, "lastNameActionLog");

      // aristocraticTitle log
      let aristocraticTitleActionLog = this.buildBasicLogByDifferences(
        this.initalpayloads,
        this.editPayload,
        "aristocraticTitle",
        "Designation"
      );

      console.log(aristocraticTitleActionLog, "aristocraticTitleActionLog");

      // statusActionLog log
      let statusActionLog = this.buildBasicLogByDifferences(
        this.initalpayloads,
        this.editPayload,
        "status",
        "status"
      );
      console.log(statusActionLog, "statusActionLog");

      // externalReference log
      let externalReferenceActionLog =
        this.buildLogBasedOnDifferencesForArrayOfObjects(
          this.initalpayloads.externalReference,
          this.editPayload.externalReference,
          "name",
          "Linked in"
        );
      console.log(externalReferenceActionLog, "externalReferenceActionLog");

      // Address differences
      let addressActionLog = this.buildBasicLogByDifferences(
        this.initalpayloads.contactMedium[2].characteristic,
        this.editPayload.contactMedium[2].characteristic,
        "description",
        "Address"
      );
      console.log(addressActionLog, "addressActionLog");

      // email differences
      let emailaddressActionLog = this.buildBasicLogByDifferences(
        this.initalpayloads.contactMedium[0].characteristic,
        this.editPayload.contactMedium[0].characteristic,
        "emailAddress",
        "Email"
      );
      console.log(emailaddressActionLog, "emailaddressActionLog");

      // phoneNumber differences
      let phoneNumberActionLog = this.buildBasicLogByDifferences(
        this.initalpayloads.contactMedium[1].characteristic.phoneNumber,
        this.editPayload.contactMedium[1].characteristic.phoneNumber,
        "value",
        "phoneNumber"
      );
      console.log(phoneNumberActionLog, "phoneNumberActionLog");

      CM_ContactLog.detailedAction = CM_ContactLog.detailedAction.concat(
        lastNameActionLog,
        aristocraticTitleActionLog,
        externalReferenceActionLog,
        nameActionLog,
        statusActionLog,
        addressActionLog,
        emailaddressActionLog,
        phoneNumberActionLog
      );
   
   
      if (this.newProfileAdded) {
        let data = await UPCAxiosInstance.post(
          `/gcpMultiple/v2/upload/`,
          this.newProfileformData
        );

        let obj = {};
        obj.name = data.data[0].filename;
        obj.size = data.data[0].data.attachment.size;
        obj.url = data.data[0].url;
        obj.id = data.data[0].data._id;
        obj["@type"] = "profile";
        // Get the current date and time in milliseconds
        const currentTimestamp = new Date().toJSON();

        // Format the current timestamp in the same format as '1705574420667'
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;

        let index = this.editPayload.attachment.findIndex(
          (obj) => obj["@type"] === "profile"
        );

        this.profilePicName = obj.name;
        const url = await UPCAxiosInstance.get(`/gcpupload/files/${obj.name}`, {
          responseType: "arraybuffer",
        });
        this.rawProfileUrlData = url.data;
        this.profilePicUrl = await this.bufferToDataURL(url.data);

        if (index !== -1) {
          this.editPayload.attachment[index] = obj;
        } else {
          this.editPayload.attachment.push(obj);
        }
      }
      if (this.deleteProfilePic === true && this.newProfileAdded === false) {
        await UPCAxiosInstance.delete(
          `/gcpupload/files/${this.profilePicName}`
        );
        let index = this.editPayload.attachment.findIndex(
          (obj) => obj["@type"] === "profile"
        );
        if (index !== -1) {
          this.editPayload.attachment.splice(index, 1);
          this.rawProfileUrlData = null;
          this.profilePicUrl = "";
          this.profilePicName = "";
        }
      }
      this.newProfileAdded = false;
      this.deleteProfilePic = false;
      if (this.editPayload.firstName === "") {
        this.isFirstNameEmpty = true;
      }
      if (this.editPayload.lastName === "") {
        this.isLastNameEmpty = true;
      }
      if (this.editPayload.contactMedium[0].emailAddress === "") {
        this.isEmailEmpty = true;
      }
      if (
        !this.isFirstNameEmpty &&
        !this.isLastNameEmpty &&
        !this.isEmailEmpty
      ) {
        try {
          let result = await MLTAxiosInstance.patch(
            `/partymanagement/individual?company=${this.$route.params.companyName}`,
            this.editPayload
          );
          result;
      this.initLog(CM_ContactLog);

          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };

          this.tableData[this.editPayload.index] = JSON.parse(
            JSON.stringify(this.editPayload)
          );
        } catch (error) {
          if (error.response) {
            console.log("Error data:", error.response.data);
            console.log("Error status:", error.response.status);
            console.log("Error headers:", error.response.headers);
          } else if (error.request) {
            this.notification = {
              type: "success",
              message: `This email was already registered`,
            };
            console.log("Error request:", error.request);
          } else {
            console.log("Error message:", error.message);
          }
          this.notification = {
            type: "success",
            message: `This email was already registered`,
          };
          console.log("Error config:", error.config);
        }
        const x = {
          familyName: "",
          givenName: "",
          name: "",
          firstName: "",
          lastName: "",
          tradingName: "",
          companyName: `${this.$route.params.companyName}`,
          status: "Pending",
          lastUpdated: new Date().toJSON(),
          preferred: "",
          "@baseType": "supplier",
          aristocraticTitle: "",
          contactMedium: [
            {
              preferred: true,
              mediumType: "email",
              characteristic: {
                emailAddress: "",
              },
            },
            {
              mediumType: "phone",
              characteristic: {
                phoneNumber: {
                  country: {
                    name: this.$store.getters.defaultSetting?.country,
                    short: this.$store.getters.defaultSetting?.cca2,
                    flags: this.$store.getters.defaultSetting?.flags?.svg,
                  },
                  value: "",
                },
              },
            },
            {
              mediumType: "postalAddress",
              characteristic: {
                city: "",
                country: "",
                postCode: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                timezone: "",
                description: "",
                "@type": "",
              },
            },
          ],
          externalReference: [
            {
              name: "",
              externalIdentifierType: "linkedIn",
            },
          ],
          role: "supplier",
          type: "individual",
          notes: "",
          password: "",
          email: "",
          parentCompany: this.company,
        };
        this.editPayload = JSON.parse(JSON.stringify(x));
        this.isFirstNameEmpty = false;
        this.isLastNameEmpty = false;
        this.isEmailEmpty = false;
        this.refershtable();
        this.closeEditContactModal();
      }
      this.refershtable();
      this.closeEditContactModal();
    },
    async addContactRow() {
      this.alertmsg = "success";
       const addedHierarchyLogPayload = {
        message: `${this.payload.name} contact has been added`,
        detailedAction: [],
        action: "Added",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "supplier-location",
        category: ["supplier"],
      };
      this.initLog(addedHierarchyLogPayload);
      if (this.payload.firstName === "") {
        this.isFirstNameEmpty = true;
      }

      if (this.payload.contactMedium[0].emailAddress === "") {
        this.isEmailEmpty = true;
      }
      if (
        // !this.isTitleEmpty &&
        !this.isFirstNameEmpty &&
        !this.isEmailEmpty
      ) {
        try {
          var result = await MLTAxiosInstance.post(
            `/partymanagement/individual/account/signup`,
            this.payload
          );
          console.log(result, "result");

          this.notification = {
            type: "success",
            message: `Nice work! Your new contact "${this.payload.tradingName}" is now in the list!`,
          };
        } catch (error) {
          if (error.result) {
            this.notification = {
              type: "success",
              message: `This email was already registered`,
            };
            console.log("Error data:", error.result.data);
            console.log("Error status:", error.result.status);
            console.log("Error headers:", error.result.headers);
          } else if (error.request) {
            console.log("Error request:", error.request);
            this.notification = {
              type: "success",
              message: `This email was already registered`,
            };
          } else {
            console.log("Error message:", error.message);
          }
          console.log("Error config:", error.config);
          this.notification = {
            type: "success",
            message: `This email was already registered`,
          };
        }

        this.addressKey += 1;
        this.isEmailEmpty = false;
        this.isLastNameEmpty = false;
        this.isEmailEmpty = false;

        this.closeContactModal();
        this.refershtable();
        this.showContactModal = false;
      }
      this.showContactModal = false;
      this.closeContactModal();
      this.refershtable(), console.log(this.payload, "payload value");
      //
    },
    closeContactModal() {
      this.addressKey += 1;
      let x = {
        familyName: "",
        givenName: "",
        name: "",
        firstName: "",
        lastName: "",
        tradingName: "",
        companyName: `${this.$route.params.companyName}`,
        status: "Pending",
        lastUpdated: new Date().toJSON(),
        preferred: "",
        "@baseType": "supplier",
        aristocraticTitle: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        role: "supplier",
        type: "individual",
        notes: "",
        password: "",
        email: "",
        parentCompany: this.company,
      };
      x;
      this.payload = JSON.parse(JSON.stringify(x));
      this.isEmailEmpty = false;
      this.isLastNameEmpty = false;
      this.isFirstNameEmpty = false;
      this.showContactModal = false;
    },
    async refershtable() {
      const fulltableData = {
        company: `${this.$route.params.companyName}`,
        type: "filter",
        module: "hierarchycontacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        isNeedToBeSort: true,
        lastUpdated: new Date().toJSON(),
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {},
        searchQuery: "Active",
      };
      const fulltabledata = await MLTAxiosInstance.post(
        `/util/filterData?company=${this.$route.params.companyName}`,
        fulltableData
      );

      this.paginationtotal = fulltabledata.data.total;
      this.fulltotal = fulltabledata.data.total;
      this.contactData = fulltabledata.data.total;

      if (
        this.switchvalue === "all" ||
        this.switchvalue === "" ||
        this.selectedSwitch == "all"
      ) {
        const fulltableData = {
          company: `${this.$route.params.companyName}`,
          type: "filter",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currPage,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {},
          searchQuery: "Active",
        };
        const fulltabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.$route.params.companyName}`,
          fulltableData
        );

        this.tableData = fulltabledata.data.data;
        this.contactData = fulltabledata.data.data;
        this.paginationtotal = fulltabledata.data.total;
        this.fulltotal = fulltabledata.data.total;
      }
      if (this.switchvalue === "pending") {
        const pendingtableData = {
          company: `${this.$route.params.companyName}`,
          type: "search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currPage,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {},
          searchQuery: "Pending",
        };
        const pendingtabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.$route.params.companyName}`,
          pendingtableData
        );
        this.tableData = pendingtabledata.data.data;
        this.contactData = pendingtabledata.data.data;
        this.paginationtotal = pendingtabledata.data.total;
      }
      if (this.switchvalue === "active") {
        const ActivetableData = {
          company: `${this.$route.params.companyName}`,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currPage,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: { status: "Active" },
          searchQuery: "",
        };
        const Activetabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.$route.params.companyName}`,
          ActivetableData
        );
        this.tableData = Activetabledata.data.data;
        this.contactData = Activetabledata.data.data;
        this.paginationtotal = Activetabledata.data.total;
      }
      if (this.switchvalue === "inactive") {
        const InactivetableData = {
          company: `${this.$route.params.companyName}`,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currPage,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: { status: "Inactive" },
          searchQuery: "",
        };
        const Inactivetabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.$route.params.companyName}`,
          InactivetableData
        );
        this.tableData = Inactivetabledata.data.data;
        this.contactData = Inactivetabledata.data.data;
        this.paginationtotal = Inactivetabledata.data.total;
      }
    },
    async chosenSwitch(val) {
      console.log(val, "switch contact");
      this.switchvalue = val.value;
      if (
        this.switchvalue === "all" ||
        this.switchvalue === "all" ||
        this.switchvalue === "" ||
        this.selectedSwitch == "all"
      ) {
        const fulltableData = {
          company: `${this.$route.params.companyName}`,
          type: "filter",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currPage,
          },

          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {},
          searchQuery: "Active",
        };
        const fulltabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.$route.params.companyName}`,
          fulltableData
        );

        this.tableData = fulltabledata.data.data;
        this.contactData = fulltabledata.data.data;
        this.paginationtotal = fulltabledata.data.total;
        this.fulltotal = fulltabledata.data.total;

        console.log(this.tableData, this.fulltotal);
      }
      if (this.switchvalue === "pending") {
        const pendingtableData = {
          company: `${this.$route.params.companyName}`,
          type: "search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currPage,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {},
          searchQuery: "Pending",
        };
        const pendingtabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.$route.params.companyName}`,
          pendingtableData
        );
        this.tableData = pendingtabledata.data.data;
        this.contactData = pendingtabledata.data.data;
        this.paginationtotal = pendingtabledata.data.total;
        console.log(this.tableData, this.pendingtotal);
      }
      if (this.switchvalue === "active") {
        const ActivetableData = {
          company: `${this.$route.params.companyName}`,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currPage,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: { status: "Active" },
          searchQuery: "",
        };
        const Activetabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.$route.params.companyName}`,
          ActivetableData
        );
        this.tableData = Activetabledata.data.data;
        this.contactData = Activetabledata.data.data;
        this.paginationtotal = Activetabledata.data.total;
        console.log(this.tableData, this.Activetotal);
      }
      if (this.switchvalue === "inactive") {
        const InactivetableData = {
          company: `${this.$route.params.companyName}`,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currPage,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: { status: "Inactive" },
          searchQuery: "",
        };
        const Inactivetabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.$route.params.companyName}`,
          InactivetableData
        );
        this.tableData = Inactivetabledata.data.data;
        this.contactData = Inactivetabledata.data.data;
        this.paginationtotal = Inactivetabledata.data.total;
        console.log(this.tableData, this.Inactivetotal);
      }
    },

    // goToContacts(i) {
    //   this.editPayload = JSON.parse(JSON.stringify(i));
    //   this.editPayload.index = i;
    //   this.showEditContactModal = true;
    // },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      console.log(data, index);
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },

    closeAddNewAdrressModal() {
      this.showAddNewAdrressModal = false;
      this.showContactModal = true;
    },
    // async getLocations() {
    //   var input = this.payload.contactMedium[2].location;
    //   if (this.showEditContactModal) {
    //     input = this.editPayload.contactMedium[2].location;
    //   }
    //   let result = [];

    //   try {
    //     // eslint-disable-next-line vue/no-async-in-computed-properties
    //     const response = await UPCAxiosInstance.get(
    //       `/suggestionlist/?input=${input}`
    //     ); // console.log(response);
    //     result = response.data;
    //   } catch (error) {
    //     console.error("Error fetching autocomplete data:", error.message);
    //   }
    //   this.locationData = result;
    //   if (this.locationData.length === 0 && input !== "") {
    //     this.showNewAddressOption = true;
    //   } else {
    //     this.showNewAddressOption = false;
    //   }
    //   return result;
    // },
    // async getEditLocations() {
    //   const input = this.editPayload.contactMedium[2].location;
    //   let result = [];

    //   try {
    //     // eslint-disable-next-line vue/no-async-in-computed-properties
    //     const response = await UPCAxiosInstance.get(
    //       `/suggestionlist/?input=${input}`
    //     ); // console.log(response);
    //     result = response.data;
    //   } catch (error) {
    //     console.error("Error fetching autocomplete data:", error.message);
    //   }
    //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   this.locationData = result;
    //   if (this.locationData.length === 0 && input !== "") {
    //     this.showNewAddressOption = true;
    //   } else {
    //     this.showNewAddressOption = false;
    //   }
    //   return result;
    // },
    async addNewAddress() {
      this.newAddressPayload.description =
        this.newAddressPayload.streetNumber +
        ", " +
        this.newAddressPayload.streetName +
        ", " +
        this.newAddressPayload.city +
        ", " +
        this.newAddressPayload.state +
        ", " +
        this.newAddressPayload.country +
        ", " +
        this.newAddressPayload.postalCode +
        ".";
      // console.log(this.newAddressPayload);
      let newAddress = await DMNAxiosInstance.post(
        `mapRef/geoAddress`,
        this.newAddressPayload
      );
      if (newAddress.data.ok === 1) {
        // console.log(this.newAddressPayload.description);
      }
      this.payload.contactMedium[2].location =
        this.newAddressPayload.description;
      this.showAddNewAdrressModal = false;
      this.showContactModal = true;
      const x = {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      };
      this.newAddressPayload = JSON.parse(JSON.stringify(x));
    },
    contactSearch(val) {
      this.searchvalue = val;
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.tableData,
          val.toLowerCase()
        );
        // console.log(searchData, this.tableData);
        this.tableData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.contactData));
      }
    },

    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            // Recursively search nested objects
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    deleteContactRow() {
      let row = this.tableData[this.delete_index];
      if (row.status === "Active") {
        row.status = "Inactive";
      } else {
        row.status = "Active";
      }
      this.$emit("edit-contact", row);
      // this.tableData.splice(this.delete_index, 1);
      this.$emit("contact-data", this.tableData);
      this.delete_modal_active = false;
    },
    crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        if (this.computedTableAction(i)[0]?.label === "Mark as Active") {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 185;
        } else if (
          this.computedTableAction(i)[1]?.label === "Mark as In-active"
        ) {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 160;
        } else {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 120;
        }
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async changeRowsPerPage(count) {
      this.rowsPerPage = count;
      this.refershtable();
      console.log(count);
    },
    async currentPage(val) {
      this.currPage = val;
      console.log(val);
      this.refershtable();
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    async closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        // this.$router.push(`/CM/accounts/edit/${ele._id}`);
        this.initalpayloads=ele
        console.log(this.tableData[i], "this.tableData[i]");
        this.editPayload = JSON.parse(JSON.stringify(this.tableData[i]));
        this.addressPreVal = this.tableData[i].contactMedium[2];
        this.findPreferredMediumType(this.tableData[i]);

        console.log(this.preferredType, "preferredType");
        this.editPayload.index = i;
        this.showEditContactModal = true;
        const profileName = this.editPayload.attachment.filter((item) => {
          return item["@type"] !== undefined && item["@type"] === "profile"
            ? item
            : false;
        });
        // console.log(profileName[0]?.name);
        this.profilePicName = profileName[0]?.name;
        const url = await UPCAxiosInstance.get(
          `/gcpupload/files/${profileName[0]?.name}`,
          { responseType: "arraybuffer" }
        );
        // console.log(new Uint8Array([url.data,"url incoming"]))
        this.rawProfileUrlData = url.data;
        this.profilePicUrl = await this.bufferToDataURL(url.data);
      } else if ($event.toLowerCase() == "mark as in-active") {
        let i = {
          id: ele._id,
          status: "Inactive",
        };
        const addSupplier = await MLTAxiosInstance.patch(
          `/partymanagement/individual?company=${this.$route.params.companyName}`,
          i
        );
        this.refershtable();
        if (addSupplier.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }

        console.log(addSupplier);
        this.refershtable();
        addSupplier;
      } else if ($event.toLowerCase() == "mark as active") {
        let i = {
          id: ele._id,
          status: "Pending",
        };
        const addSupplier = await MLTAxiosInstance.patch(
          `/partymanagement/individual?company=${this.$route.params.companyName}`,
          i
        );
        if (addSupplier.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        console.log(addSupplier);
        this.refershtable();
        addSupplier;
      } else if ($event.toLowerCase() === "send invite") {
        console.log(i);
        this.inviteUser(ele);
      }
    },
    async inviteUser(i) {
      console.log(i, "mail send request");
      if (i != undefined) {
        this.notification = {
          type: "success",
          message: "User invitation email sent",
        };

        this.sendEmail(i);
      }
    },
    async sendEmail(i) {
      console.log(i, "sendEmail");
      const prod = process.env.VUE_APP_BASE_URL;
      prod;
      const dev = "http://localhost:8080";
      dev;

      let vbnm = 2;
      let tokenPayload = {
        user: {
          email: i.email,
          company: i.company,
        },
        validTo: vbnm + "h",
        additionalFields: {
          supplierLogin: true,
        },
      };

      let generatedToken = await MLTAxiosInstance.post(
        "/partymanagement/individual/generateToken",
        tokenPayload
      );
      generatedToken = generatedToken.data;

      console.log(generatedToken, "mail send sucessfully");
      let mail = {
        to: [i.email],
        subject: `${i.company} user Invitation`,
        template: `
      <!--
 -->

<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
    <link href='https://fonts.googleapis.com/css?family=Open+Sans' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <style type="text/css" media="screen">
        body {
            padding: 0 !important;
            margin: 0 !important;
            display: flex !important;
            justify-content: center !important;
            min-width: 100% !important;
            width: 100% !important;
            background: #f5f5f5;
            -webkit-text-size-adjust: none;
        }
        .mobile-shell {
            width: 100%;
        }
        .td {
            width: 100%;
        }
        .wrapper {
            width: 100%;
            table-layout: fixed;
        }
        .p35-15 {
            padding: 35px 15px;
        }
        .headline {
            text-align: center;
        }
        .hero-title-1 {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            padding-top: 15px;
            line-height: 28px;
            text-align: center;
            color: #191A15;
        }
        .hero-content {
            padding-top: 25px;
            padding-left: 100px;
            padding-right: 100px;
            color: black;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 25px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .btn {
            text-align: center;
            padding-top: 25px;
        }
        .btn a {
            background-color: #54BD95;
            border-radius: 6px;
            color: #ffffff;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            text-align: center;
            text-decoration: none;
            width: 218.18px;
            height: 38px;
            -webkit-text-size-adjust: none;
            padding: 10px 12px;
            line-height: 15px;
        }
        .disclaimer2 {
            padding: 40px 77px;
            color: #FFFFFF;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .footer-link a {
            color: #FFFFFF;
            font-size: 14px;
        }
        .address {
            padding-left: 17px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            color: #FFFFFF;
            margin-top: 15px;
            margin-bottom: 0px;
            letter-spacing: .01em;
            line-height: 20px;
        }
        .social-icons {
            float: right;
            margin-right: -50px;
            padding-top: 20px;
        }
        .social-icons a {
            padding-left: 5px;
            padding-right: 5px;
            color: #FFFFFF;
        }
    </style>
</head>

<body class="body" style="
 display: grid;
    justify-items: center;
    background-color: #f5f5f5;
">
    <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
    <table width="612px" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5 " style="margin: 0 auto">
        <tr>
            <td align="center" valign="top">
                <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                    <tr>
                        <td class="td">
                            <table border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td  height: 501px;">
                                        <!-- Header -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="wrapper">
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="10" cellspacing="0" width="77%" class="wrapper" bgcolor="#f5f5f5">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="wrapper" bgcolor="#ffffff">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Header -->
                                        <!-- Start Content -->
                                        <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501" cellpadding="0">
                                            <tr>
                                                <td bgcolor="#FFFFFF" class="p35-15">
                                                    <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <!-- headline -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="headline">
                                                                <img style="width: 15%; object-fit: contain; clip-path: circle();" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtteDflN13M27CDlAPdC7L_fNJ4Eus3fEFBkI8JKxFLmcms3TsIv_EUcvuyjVo46v1k4&usqp=CAU" alt="">
                                                            </td>
                                                        </tr>
                                                        <!-- #headline -->
                                                        <!-- hero-title-1 -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1">
                                                                ${
                                                                  this.company
                                                                }<br>invited you
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-content">
                                                                You have been invited by ${
                                                                  this.company
                                                                }  to join  <a href="mailto:contact@halleyx.com" style="text-decoration: none; color:#54BD95">${
          this.$route.params.companyName
        }</a>. To get started, accept the invitation below.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="btn">
                                                                <a href=${prod}/supplierinvite/${
          this.$route.params.companyName
        }/${i._id}/${this.$cookies.get(
          "token"
        )}?login=Userinvite&Password=${'NewPassword'} class="mob-btn" target="_blank">Accept invitation</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF"  class="hero-content"  style="color:#a6a6a6">
                                                                Hiccups might happen. Your feedback and requests are very welcomed and encouraged at <a href="mailto:contact@halleyx.com" style="color:#54BD95">contact@halleyx.com</a>. We can't wait to see what you create with ${
                                                                  this.company
                                                                }.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- HERO Images-->
                                    </td>
                                </tr>
                            </table>
                            <!-- END Content -->
                            <!-- Footer -->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding: 0 15px;" bgcolor="#ffffff">
                                <tr>
                                    <td bgcolor="#161C2B" class="disclaimer2">
                                        <span class="footer-link"><a href="https://halleyx.com">T&C</a><a style="padding-left:40px; padding-right: 40px;" href="https://halleyx.com">Privacy</a><a href="https://halleyx.com">Cookies</a></span><br/>
                                        <br/>
                                        © ${this.currentYear} ${
          this.company
        }. Copyright and rights reserved
                                        <p class="address">${
                                          this.instanceAddress.characteristic
                                            .description
                                        }</p>
                                        <span style="float:left; margin-left: -60px; padding-top:20px;"><img width="100" src=""/></span>
                                        <span class="social-icons"><a href="https://www.linkedin.com/company/halleyx/"><i class="fa fa-linkedin fa-lg"></i></a>
                                            <a href="https://halleyx.com/"><i class="fa fa-facebook fa-lg"></i></a>
                                            <a href="https://www.instagram.com/halleyx_inc/"><i class="fa fa-instagram fa-lg"></i></a></span>
                                    </td>
                                </tr>
                            </table>
                            <!-- END Footer -->
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    <img src="images?q=tbn:ANd9GcRYx7vG1ermb_muIP457oVO48JixkaC0RvhAA&usqp=CAU" width="1" height="1">
</body>

</html>`,
      };
      // const sendEmail =
      await MLTAxiosInstance.post(`/email`, mail);
    },
    computedTableAction(data) {
      
  if (data.status && data.status.toLowerCase() === "active") {
    if (this.permissionData.edit && this.permissionData.delete) {
      return [
        { label: "Edit", icon: "icon-file-text-edit-regular" },
        { label: "Mark as In-active", icon: "icon-times-square-regular" },
        // { label: "Send invite", icon: "icon-send-right-regular" },
        // { label: "Mark as Active", icon: "icon-check-square-regular" },
        // { label: "Delete", icon: "icon-trash-regular" },
      ];
    } else if (this.permissionData.edit) {
      return [
        { label: "Edit", icon: "icon-file-text-edit-regular" },
        // { label: "Send invite", icon: "icon-send-right-regular" },
      ];
    } 
    // else if (permissionData.delete) {
    //   return [
    //     { label: "Mark as In-active", icon: "icon-times-square-regular" },
    //     { label: "Delete", icon: "icon-trash-regular" },
    //   ];
    // }
    return [
      { label: "Edit", icon: "icon-file-text-edit-regular" },
      { label: "Mark as In-active", icon: "icon-times-square-regular" },
    ];
  } else if (data.status && data.status.toLowerCase() === "pending") {
    if (this.permissionData.edit) {
      return [
        { label: "Edit", icon: "icon-file-text-edit-regular" },
        { label: "Send invite", icon: "icon-send-right-regular" },
      ];
    }
    return [
      { label: "Send invite", icon: "icon-send-right-regular" },
      { label: "Edit", icon: "icon-file-text-edit-regular" },
    ];
  } else if (data.status && data.status.toLowerCase() === "inactive") {
    if (this.permissionData.edit && this.permissionData.delete) {
      return [
        { label: "Mark as Active", icon: "icon-check-square-regular" },
        { label: "Delete", icon: "icon-trash-regular" },
        // { label: "Edit", icon: "icon-file-text-edit-regular" },
        // { label: "Mark as In-active", icon: "icon-times-square-regular" },
        // { label: "Send invite", icon: "icon-send-right-regular" },
      ];
    } 
    // else if (permissionData.edit) {
    //   return [
    //     { label: "Edit", icon: "icon-file-text-edit-regular" },
    //     { label: "Send invite", icon: "icon-send-right-regular" },
    //   ];
    // }
     else if (this.permissionData.delete) {
      return [
        { label: "Mark as Active", icon: "icon-check-square-regular" },
        { label: "Delete", icon: "icon-trash-regular" },
      ];
    }
    return [
      { label: "Mark as Active", icon: "icon-check-square-regular" },
      { label: "Delete", icon: "icon-trash-regular" },
    ];
  }


      // return options.filter((option) => {
      //   if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "active" &&
      //     ["Mark as Active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //   //  data.status &&
      //     data.status.toLowerCase() == "inactive" &&
      //     ["Mark as In-active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "pending" &&
      //     ["Mark as In-active", "Mark as Active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.re_arrange = val;
      // this.updateTheads();
    },
    arrangeColumns(val) {
      this.re_arrange = val;
      // this.updateTheads();
    },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
   async applyColumns(val) {
// Func to rearrange table header
console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef || {};
concatedEntity.supplierContact = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.accounts_table_theads_cc = entityRef["supplierContact"]
this.accounts_table_theads =this.accounts_table_theads_cc.filter(ele=>ele.checked)
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
    },

    showNewContactModal() {
      const x = {
        familyName: "",
        givenName: "",
        name: "",
        firstName: "",
        lastName: "",
        tradingName: "",
        companyName: `${this.$route.params.companyName}`,
        status: "Pending",
        lastUpdated: new Date().toJSON(),
        preferred: "",
        "@baseType": "supplier",
        aristocraticTitle: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        role: "supplier",
        type: "individual",
        notes: "",
        password: "",
        email: "",
        parentCompany: this.company,
      };
      this.payload = JSON.parse(JSON.stringify(x));
      this.isFirstNameEmpty = false;
      this.isLastNameEmpty = false;
      this.isEmailEmpty = false;

      this.showContactModal = true;
      this.key += 1;
    },

    closeEditContactModal() {
      this.bufferToDataURL(this.rawProfileUrlData);
      this.newProfileAdded = false;
      this.deleteProfilePic = false;
      this.key += 1;
      this.showViewContactModal = false;
      const x = {
        familyName: "",
        givenName: "",
        name: "",
        firstName: "",
        lastName: "",
        tradingName: "",
        companyName: `${this.$route.params.companyName}`,
        status: "Pending",
        lastUpdated: new Date().toJSON(),
        preferred: "",
        "@baseType": "supplier",
        aristocraticTitle: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        role: "supplier",
        type: "individual",
        notes: "",
        password: "",
        email: "",
        parentCompany: this.company,
      };
      this.editPayload = JSON.parse(JSON.stringify(x));
      this.showEditContactModal = false;
    },
  },
};
</script>
