<template>
  <hlx-loader load-type="dotted-jumper" />
</template>

<script>
import { MLTAxiosInstance } from "@/config/axiosConfig";
import router from "@/router"; // Adjust the path based on your project structure

export default {
  name: "DomainManager",
  async mounted() {
    try {
      // Fetch user authentication details
      const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
      this.company = val.data.company;
      this.userId = val.data.userId;

      // Fetch permissions for the user
      const access = await MLTAxiosInstance.get(
        `partyRole/get/${val.data.userId}`
      );
      const permissionsData = access.data.permission;

      // Set cookies for authentication
      this.$cookies.set("role",access.data.role)
      this.$cookies.set("token", this.$route.params.token);
      this.$cookies.set("company", this.company);
      this.$cookies.set("userId", this.userId);

      // Fetch organization information
      const organisationInfo = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$cookies.get(
          "user"
        )}?company=${this.$cookies.get("company")}`
      );
      this.$cookies.set("rootName", organisationInfo.data.rootName);

      // Handle query parameters if requestFor is 'approval'
      if (this.$route.query && this.$route.query.requestFor === "approval") {
        const currentParams = new URLSearchParams(window.location.search);
        Object.keys(this.$route.query).forEach((key) => {
          if (key !== "path") {
            currentParams.set(key, this.$route.query[key]);
          }
        });
        const path = this.$route.query.path;
        window.location.assign(
          `${window.location.protocol}//${window.location.hostname}:${window.location.port}${path}?${currentParams.toString()}`
        );
        return;
      }

      // Find the first permission with view: true
      const firstViewTrueKey = Object.keys(permissionsData).find(
        (key) => permissionsData[key].view
      );

      if (firstViewTrueKey) {
        // Dynamically find the corresponding route by name
        const route = router.options.routes.find(
          (r) => r.name === firstViewTrueKey
        );
        if (route) {
          // Navigate using Vue Router
          router.push(route.path);
          return;
        } else {
          console.error(
            `No matching route found for the permission key: ${firstViewTrueKey}`
          );
        }
      } else {
        console.error("No permissions with view: true were found.");
      }

      // Fallback if no matching permission or route is found
      const fallbackPath = "/DMN/dashboard";
      window.location.assign(
        `${window.location.protocol}//${window.location.hostname}:${window.location.port}${fallbackPath}`
      );
    } catch (error) {
      console.error("Error during mounted:", error);
    }
  },
};
</script>
