<template>

  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal = false"
  >
    <template #header>
      <div style="margin-left: 0px">
        <!-- Delete note -->
         {{matchFromStatic('Delete note')}}
      </div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          <!-- Are you sure you want to delete this note? -->
           {{matchFromStatic('Are you sure you want to delete this note?')}}
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal = false"
        >
        <!-- Cancel -->
         {{matchFromStatic('Cancel')}}
        </hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="noteDelete"
        >
        <!-- Delete -->
          {{matchFromStatic('Delete')}}
        </hlx-button
      >
    </template>
  </hlx-modal>
  <div class="upcv2-overview">
    <section class="right-view-body-feature">
      <span class="spec-feature --table-tool-bar" style="margin: 10px 0">
        <p
          v-if="notesTable.length > 0 || noteTrigger !== ''"
          class="--upcv2-body-title"
        >
          <!-- Notes -->
           {{ matchFromStatic('Notes') }}
        </p>
        <div
          v-if="notesTable.length > 0 || noteTrigger !== ''"
          class="--table-tool-bar-wrapper"
        >
          <span class="util-items search"
            ><hlx-search
            v-model="noteTrigger"
              :label-value="matchFromStatic('Search') || 'Search'"
              @search-key="searchTrigger"
            ></hlx-search
          ></span>
          <!-- Filter -->

          <span v-if="permisstionData.add" v-show="supplierStatus == false" class="util-items util-items-add"
            >
            <hlx-button 
              class="primary sm add-btn"
              :disabled="disabled"
              @click="showAddNotePanel = true;"
              ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i
              >
              <!-- Add note -->
               {{ matchFromStatic('Add note') }}
              </hlx-button
            >
          </span>
        </div>
      </span>
    </section>
    <section
      v-if="notesTable.length <= 0 && noteTrigger === ''"
      style="
        margin-top: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        align-items: center;
      "
    >
      <img src="@/assets/images/notes.png" alt="" style="width: 400px" />

      <span v-if="permisstionData.add" v-show="supplierStatus == false" class="util-items util-items-add"
        >
        <hlx-button class="primary sm add-btn" :disabled="disabled" @click="showAddNotePanel = true"
          ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i>
          <!-- Add notes -->           
          {{ matchFromStatic('Add notes') }}
          </hlx-button
        >
      </span>
    </section>
    <section
      v-if="notesTable.length > 0"
      class="section_accordtion_supplier_note"
      style="margin-right: 30px"
    >
      <div class="notepad_supplier_accordion_note">
        <hlx-accordion
          v-for="(note, index) in notesTable"
          :key="index"
          :multiple="true"
          :header-border="true"
          :direction="'left'"
          @accordionopen="accordionopen(index)"
          @accordionclose="accordionclose(index)"
        >
          <!-- Title -->
          <template #item-title>
            <div class="noter_fullhead_supplier_accordion_note">
              <div class="noter_head_supplier_accordion_note">
                <span class="note_username_supplier_note">
                  <!-- Note by -->
                   {{ matchFromStatic('Note by') }}
                  {{" "}}
                  <span class="note_username_supplier_color_note"
                    >{{ note.noteusername }}
                  </span></span
                >

                <span class="note_date_time_supplier_note">
                  <span class="note_date_time_supplier_value">{{
                    dateAndTimeFormatter(note?.date)
                  }}</span>

                  <!-- <span class="note_date_time_supplier_value">{{
                    convertdateformat(note.time)
                  }}</span> -->

                  <!-- <span class="note_date_time_supplier_value">{{
                    note.country
                  }}</span> -->
                </span>
                <div  class="supplier-delete-action" style="">
                  <div v-if="permisstionData.delete" v-show="supplierStatus == false">
                    <i
                      v-if="!disabled"
                      class="icon-trash-regular"
                      style="color: #dd2025"
                      @click.stop="deleteNoteModal(index)"
                    ></i>
                  </div>
                  <div v-if="permisstionData.add"  v-show="supplierStatus == false" >
                    <i
                    v-if="!disabled"
                      class="icon-edit-regular"
                      style="color: #70bb98"
                      @click="editOpen(note, index)"
                    ></i>
                  </div>
                </div>
              </div>

              <div :id="'displayHeader' + index" class="ellipsisnote">
                {{ note.note }}
              </div>
            </div>
          </template>
          <!-- Body -->
          <template #item-body>
            <main class="pricing-panel" style="line-height: 1.7;word-break: break-all;">
              {{ note.note }}
            </main>
          </template>
        </hlx-accordion>
      </div>
    </section>
    <section
      v-if="notesTable.length === 0 && noteTrigger !== ''"
      class="section_accordtion_supplier_note"
      style="
        margin-top: 1%;
        height: 82vh;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <!-- No data -->
       {{loadStaticData('LANG-0050')}}
    </section>
  </div>

  <!-- panel for notes -->
  <div class="add-contact-drawer">
    <hlx-drawer
      :show="showAddNotePanel"
      position="right"
      width="400"
      :footer="true"
      :title="loadStaticData('LANG-0071') || 'Edit notes'"
      :show-close-icon="true"
      @close="closeDrawer"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <!-- <span
            style="
              display: flex;
              width: 25px;
              height: 25px;
              position: fixed;
              right: 400px;
              background: white;
              z-index: 1000;
              top: 0;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
              box-shadow: -2px 0px 6px 0px #b9b6b6;
              cursor: pointer;
            "
            @click="closeDrawer"
          >
            <i class="icon-times-regular"></i>
          </span> -->
          <b v-if="editMode == false" >
             <!-- Add Note -->
              {{ loadStaticData('LANG-0072') }}
            </b>
          <b v-else>
             <!-- Edit Note -->              
             {{ loadStaticData('LANG-0071') }}
            </b>
        </div>
        <div class="body-area-contact-drawer">
          <!-- <hlx-input
            v-model:value="interactionPayload.reason"
            :pre-val="interactionPayload.reason"
            :label-value="'Subject'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input> -->
          <hlx-input
            v-model:value="interactionPayload.note"
            :pre-val="interactionPayload.note"
            :label-value="matchFromStatic('Note')"
            :label-animation="true"
            type="textarea"
            :rows="10"
            :custom-error="customnameerror"
            :custom-error-message="matchFromStatic('Please fill the field')"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeDrawer"
            >
            <!-- Cancel -->
             {{matchFromStatic('Cancel')}}
            </hlx-button
          >
          <hlx-button
            v-if="editMode == false"
            class="primary sm"
            style="margin-left: 10px"
            :disabled="interactionPayload.note === ''"
            @click="addNote"
            >
            <!-- Add -->
             {{matchFromStatic('Add')}}
            </hlx-button
          >
          <hlx-button
            v-else
            class="primary sm"
            style="margin-left: 10px"
            @click="editNote"
            >
            <!-- Save -->
             {{ matchFromStatic('Save') }}
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
</template>

<script>
import {
  MLTAxiosInstance,
  // UPCAxiosInstance
} from "@/config/axiosConfig";
export default {
  props: {
    permisstionData: {
      type: Object,
      required: true, 
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default:false
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
       supplierStatus:{
       type: Boolean,
      default: false,
    }
  },
  emits: ["added-note", "delete-index", "edit-note"],
  data() {
    return {
      loginUserDetails: {},
      noteopen: false,
      noteTrigger: "",
      notesTable: [],
      ogNotesTable: [],
      editMode: false,
      searchToggle: false,
      showAddNotePanel: false,
      interactionPayload: {
        id: "",
        reason: "",
        note: "",
        noteusername: "",
        date: new Date().toJSON(),
      },
      noteIndex: null,
      deleteIndex: 0,
      delete_modal: false,
      loginUser: {},
    };
  },
  watch: {
    data: {
      handler(val) {
        this.notesTable = val;
        let count = 0
        val.forEach((i)=> {
          i.id = count++
        })
        this.ogNotesTable = JSON.parse(JSON.stringify(val));
        
        this.notesTable.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        console.log(val, 'fk notes');
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {



    this.loginUser = await MLTAxiosInstance.get(
      "partymanagement/individual/auth"
    );
  },
  methods: {
    accordionclose(index) {
      this.noteopen = false;
      const element = document.getElementById("displayHeader" + index);
      console.log(element, this.noteopen, "accordionclose");
      if (element) {
        element.style.display = "-webkit-box";
      }
    },
    accordionopen(index) {
      this.noteopen = true;
      const element = document.getElementById("displayHeader" + index);
      console.log(element, this.noteopen, "accordionopen");
      if (element) {
        element.style.display = "none";
      }
    },
    closeDrawer() {
      this.showAddNotePanel = false;
      this.editMode = false;
      const val = {
        id: "",
        reason: "",
        note: "",
        noteusername: "",
        date: new Date().toJSON(),
      };
      this.interactionPayload = val;
    },
    async editOpen(val, index) {
      index
      this.noteIndex = val.id;
      this.editNoteVal = val;
      this.editMode = true;
      this.showAddNotePanel = true;
      this.interactionPayload = JSON.parse(JSON.stringify(val));
      this.noteTrigger = ""
      console.log(val, index, this.interactionPayload, 'fkk');
    },
    editNote() {
      console.log({
        val: this.interactionPayload,
        index: this.noteIndex,
      }, 'fkk');
      
      this.editMode = false;
      this.showAddNotePanel = false;
      this.$emit("edit-note", {
        val: this.interactionPayload,
        index: this.noteIndex,
      });
      this.notification = {
        type: "success",
        message: this.loadStaticData('LANG-0086') || `All set! Your changes have been saved successfully!`,
      };
      this.interactionPayload = {
        reason: "",
        note: "",
        noteusername: "",
        date: new Date().toJSON(),
        country: "EST",
      };
    },
    deleteNoteModal(index) {
      console.log(index);
      this.delete_modal = true;
      this.delete_index = index;
    },
    async noteDelete() {
      this.$emit("delete-index", this.delete_index);
      this.notification = {
        type: "success",
        message: this.loadStaticData('LANG-0073') ||`Done! Your item has been removed.`,
      };
      this.delete_modal = false;
    },
    async addNote() {
      this.interactionPayload.noteusername = this.loginUser.data.company;
      this.$emit("added-note", this.interactionPayload);
      this.notification = {
        type: "success",
        message: this.loadStaticData('LANG-0087')||`All set! Your new item is now in the list!`,
      };
      this.showAddNotePanel = false;
      this.interactionPayload = {
        reason: "",
        note: "",
        noteusername: "",
        date: new Date().toJSON(),
        country: "EST",
      };
    },
    searchTrigger(val) {
      if (val) {
        this.searchToggle = true;
        const result = this.searchNoteArray(val);
        this.notesTable = result;
      } else {
        this.searchToggle = false;
        this.notesTable = this.ogNotesTable;
      }
    },
    searchNoteArray(val) {
      if (val !== "") {
        const result = this.ogNotesTable.filter((obj) => {
          return Object.values(obj).some((value) => {
            if (typeof value === "string") {
              return value.toLowerCase().includes(val.toLowerCase());
            }
            return false;
          });
        });

        return result;
      } else {
        this.notesTable = this.ogNotesTable;
      }
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = objectDate.toLocaleString("default", { month: "short" });
          let year = objectDate.getFullYear();
          let dateformat = month + " " + date + ", " + year;
          return dateformat;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.ellipsisnote {
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.note_username_supplier_note {
  position: relative;
}
.section_accordtion_supplier_note {
  // height: 90vh;
}
.notepad_supplier_accordion_note {
  display: flex;
  flex-direction: column;
  gap: 15px !important;
  width: 100%;

  .accordion-item {
    background-color: #d4d4d41a;
    border: 1px solid #d8d8d8;
    border-radius: 6px !important;
    .accordion-content {
      padding-left: 40px;
      background-color: #fff;
      border-radius: 6px !important;
      border-bottom: 0 !important ;
      text-align: left;
      padding-left: 37px;
    }
    .accordion-header {
      height: 100%;
      padding: 16px;
      display: flex;
      flex-direction: row;
      border-bottom: 0 !important ;
      justify-content: space-between;
      align-items: center;
      color: var(--hlx-text-color-primary);
      cursor: pointer;
    }
  }
  .noter_fullhead_supplier_accordion_note {
    padding: 6px;
  }
  .note_username_supplier_color_note {
    color: #0065b2;
  }
  .note_username_supplier {
    font-weight: 400;
  }
  .noter_head_supplier_accordion_note {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
    .note_date_time_supplier_note {
      margin-left: auto;
      font-weight: 400 !important;
      .note_date_time_supplier_value {
        margin-right: 10px;
      }
    }
  }
}
.supplier-delete-action {
  position: absolute;
  right: -50px;
  height: 18px;
  font-size: 1.3em;
}

.header-add-supplier {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  .tablehead-add-supplier {
    font-weight: bold;
  }
  .search-add-supplier {
    display: flex;
    gap: 10px;
  }
}
.contacts-username-supplier {
  display: flex;
  align-items: center;
  .right-head-supplier {
    display: flex;
    flex-direction: column;
  }
  .head-supplier-left {
    display: flex;
    justify-content: center;
    width: 44px;
    align-items: center;
    font-size: 45px;
    margin-right: 10px;
  }
  .head-supplier-right {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    .head-supplier-name {
      margin-right: 10px;
    }
  }
  .head-supplier-address {
    font-size: 10px;
    color: #929191;
    margin-top: 3px;
  }
}
</style>
