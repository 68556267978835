<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <hlx-modal
    :modal-active="modal_active_role"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'370px'"
    :width="'410px'"
    :modal="true"
    @close="modal_active_role = false"
  >
    <template #header>
      <div v-if="editView === false">Change role</div>
    </template>
    <template #content>
      <div
        class="modal-content"
        style="display: flex; flex-direction: column; gap: 5%"
      >
        <div
          class="icon-container"
          style="display: flex; justify-content: center"
        >
          <i
            class="icon-user-change-regular"
            style="
              height: 50px;
              width: 50px;
              border-radius: 50px;
              background: #8ce1c1;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          ></i>
        </div>
        <p style="text-align: center; margin-top: 20px; margin-bottom: 20px">
          Select a new role for <strong>{{ userName }}</strong> to align with
          the appropriate responsibilities
        </p>
        <div class="decision-name-wrapper">
          <hlx-select
            :key="rolecomponentkey"
            v-model:value="roleData"
            :required="true"
            :options="roleList"
            prop-value="role"
            :label="'role'"
            :placeholder-value="'Role'"
            :label-animation="true"
            :clearable="false"
          ></hlx-select>
        </div>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="modal_active_role = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="ChangeRole"
        >Change</hlx-button
      >
    </template>
  </hlx-modal>

  <hlx-modal
    :modal-active="modal_active_Deactivate"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'385px'"
    :width="'410px'"
    :modal="true"
    @close="modal_active_Deactivate = false"
  >
    <template #header>
      <div>Deactivate</div>
    </template>
    <template #content>
      <div
        class="modal-content"
        style="display: flex; flex-direction: column; gap: 5%"
      >
        <div
          class="icon-container"
          style="display: flex; justify-content: center"
        >
          <i
            class="icon-user-times-alt-regular"
            style="
              height: 56px;
              width: 56px;
              border-radius: 50px;
              background: rgba(84, 189, 149, 0.14);
              font-size: 24px;
              color: rgba(84, 189, 149, 1);
              /* background: rgb(140, 225, 193); */
              display: flex;
              justify-content: center;
              align-items: center;
            "
          ></i>
        </div>
        <p
          style="
            margin-top: 20px;
            margin-bottom: 20px;
            /* font-family: Open Sans; */
            font-size: 12px;
            font-weight: 400;
            line-height: 16.34px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          "
        >
          Are you sure you want to deactivate
          <strong>{{ deactivateUserName }}</strong> ?This will lock them out of the system. Optionally, you may reassign their data to another active user.
        </p>
        <div class="decision-name-wrapper">
          <hlx-select
            :key="rolecomponentkey"
            v-model:value="roleData"
            :required="true"
            :options="hierarchyData?.filter(item => item?.name !== deactivateUserName)"
            prop-value="id"
            :label="'name'"
            :placeholder-value="'Choose user'"
            :label-animation="true"
            :clearable="false"
          ></hlx-select>
        </div>
        <p
          style="
            font-size: 10px;
            font-weight: 400;
            line-height: 13.62px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin-top: 6px;
            color: rgb(131, 131, 131);
          "
        >
          If not choose user, an admin will be assigned by default
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="modal_active_Deactivate = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="deactiveMethod"
        >Deactivate</hlx-button
      >
    </template>
  </hlx-modal>

  <hlx-modal
    :modal-active="modal_active_user"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'410px'"
    :width="'410px'"
    :modal="true"
    @close="closeAddNewContactModal"
  >
    <template #header>
      <div>Invite User</div>
    </template>
    <template #content>
      <div class="modal-content" style="display: flex; gap: 5%">
        <!--left side-->
        <div
          style="
            display: flex;
            flex-direction: column;
            /* justify-content: space-between; */
            width: 100%;
          "
        >
          <div class="decision-name-wrapper" style="margin-bottom: 20px">
            <!--first name-->
            <hlx-input
              :key="rolecomponentkeyF"
              v-model:value="userPayload.firstName"
              :label-value="'First name'"
              :label-animation="true"
              :required="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>

          <!--Last name-->
          <div class="decision-name-wrapper" style="margin-bottom: 20px">
            <hlx-input
              :key="rolecomponentkeyL"
              v-model:value="userPayload.lastName"
              :label-value="'Last name'"
              :label-animation="true"
              :required="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>

          <!--email-->
          <div class="decision-name-wrapper">
            <hlx-input
              :key="rolecomponentkeyE"
              v-model:value="userPayload.email"
              type="email"
              :label-value="'Email id'"
              :required="true"
              :label-animation="true"
              :display-error="true"
              :custom-error="isEmailEmpty"
              :custom-error-message="'This email was already registered'"
              @focus-out="validateEmailNew"
              @at-input="emailNameCheck"
            ></hlx-input>
          </div>

          <!--Role-->
          <div class="decision-name-wrapper">
            <hlx-select
              :key="rolecomponentkey"
              v-model:value="userPayload.role"
              :required="true"
              :options="roleList"
              prop-value="role"
              :label="'role'"
              :placeholder-value="'Role'"
              :label-animation="true"
              :clearable="false"
            ></hlx-select>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeAddNewContactModal"
        >Cancel</hlx-button
      >
      <hlx-button
        :disabled="
          userPayload.role != '' &&
          userPayload.firstName != '' &&
          userPayload.lastName != '' &&
          userPayload.email != '' &&
          validateEmail(userPayload.email)
            ? false
            : true
        "
        class="primary sm"
        style="margin-left: 10px"
        @click="addNewContact"
        >Invite</hlx-button
      >
    </template>
  </hlx-modal>
  <div class="app-layout">
    <section class="new-right-panel">
      <div class="new-right-panel-parent-container no-filter-panel">
        <div class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <div class="new-layout-basic-no-breadcrumb">
            <div class="new-layout-breadcrumb">
              <hlx-breadcrumb :items="items" @path="pathTo" />
            </div>
            <div>
              <span
v-if="$route.query.tenant == 'true'"
class="schema-header-title" style="margin-top: 16px;
    margin-left: 55px;">{{ currentCompanyName + ' users' }}</span>
      <span
      v-else
class="schema-header-title" style="margin-top: 16px;
    margin-left: 55px;">Users</span>
            </div>
            <div class="new-layout-body">
              <div
                class="header-utils-and-switch-container"
                style="width: 100%"
              >
                <div class="new-layout-header --no-left-padding">
                  <!-- <span v-elseclass="schema-header-title">Tenants</span> -->
                </div>
                <span style="width: 70%">
                  <hlx-switch
                    v-model="current_switch_value"
                    :switch-items="users_type_switch"
                    :tab-view="true"
                    value-prop="value"
                    display-prop="name"
                    @change="usersSelected"
                  />
                </span>
                <span
                  class="schema-header-utils"
                >
                  <span class="util-items search"
                    ><hlx-search
                      class="search"
                      :search-style="'style1'"
                      @search-key="handleUserSearch"
                    ></hlx-search
                  ></span>
                  <hlx-button class="primary sm" @click="addNewUser">
                    <i
                      class="icon-plus-circle-regular"
                      style="margin-right: 2px"
                    ></i>
                    <span>Invite User</span>
                    <!-- <span v-else>Add Tenants</span> -->
                  </hlx-button>
                  <!-- <span
                    v-if="inviteUser == true"
                    class="util-items util-items-add"
                    ><hlx-button
                      v-show="inviteUser == true"
                      class="primary sm add-btn"
                      ><i class="icon-plus-circle-regular"></i> Invite
                      user</hlx-button
                    >
                  </span> -->
                </span>
              </div>

              <!-- Table -->
              <div v-if="pagination == true" class="table-wrap">
                <!-- :flatten-data="flattenHierarchyData" -->
                <add-account-hierarchy-component
                  module="organisationUsers"
                  :data="hierarchyData"
                  @removed-organisation-user="handleUserRemoval"
                  @chosen-context-menu="handleContextMenu"
                  @dropped-child="handleChildDrag"
                />
              </div>
             
              <div v-if="pagination == false" class="table-wrap">
                <hlx-table
                  :column-count="headvalue.length + 2"
                  :border="
                    tableData.length == 0
                      ? ['table', 'header']
                      : ['table', 'header', 'horizontal']
                  "
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="theadsC"
                  @sorting_func="sorting_Data"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'center'"
                      style="padding: 0 15px"
                      :fixed="'left'"
                      >{{ "S.no" }}</hlx-table-head
                    >

                    <hlx-table-head
                      v-for="(i, index) in headvalue"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :fixed="i.fixed"
                      :width="i.width ? i.width : ''"
                      :align="i.align ? i.align : ''"
                      @sorting_func="sorting_Data"
                      @new-resize-width="resizedWidth"
                    >
                      {{ i.label }}
                    </hlx-table-head>
                    <hlx-table-head
                      :align="'center'"
                      :width="70"
                      :fixed="'right'"
                      ><span class="action-icon">
                        <i
                          class="icon-settings-regular"
                          @click="reAssignOrgTableHeader()"
                        ></i> </span
                    ></hlx-table-head>
                  </template>
                  <template v-if="tableData.length > 0" #tbody>
                    <tr v-for="(i, index) in paginatedData" id="" :key="index">
                      <hlx-table-cell :align="'center'" :fixed="'left'">
                        {{ serialNumber(index) }}
                      </hlx-table-cell>
                      <hlx-table-cell
                        v-for="(j, col_index) in headvalue"
                        :key="col_index"
                        :align="j.align"
                        :fixed="j.fixed"
                      >
                      
                        <!-- Role -->
                        <div
                          v-if="
                            j.prop?.trim() == 'role' &&
                            i[j.prop] &&
                            i[j.prop].length > 0 &&
                            i[j.prop][0]?.name
                          "
                          class="user-role"
                        >
                          <hlx-label class="primary sm">
                            {{ i[j.prop][0]?.name }}
                          </hlx-label>
                        </div>
                        <!-- No role -->
                        <div
                          v-else-if="j.prop?.trim() == 'role' && i[j.prop]"
                          class="user-role"
                        >
                          <hlx-label class="primary sm">
                            {{ i[j.prop] }}
                          </hlx-label>
                        </div>
                        <!-- Status -->
                        <div
                          v-else-if="j.prop?.trim() == 'status'"
                          class="user-status"
                        >
                          <hlx-status :type="i[j.prop]" />
                        </div>
                        <div
                          v-else-if="j.prop?.trim() == 'relatedParty'"
                          class="user-status"
                        >
                          <div v-for="val in i[j.prop]" :key="val">
                            <div v-if="val?.role?.toLowerCase() == 'manager'">
                              {{
                                val?.name?.charAt(0)?.toUpperCase() +
                                val?.name?.slice(1)
                              }}
                            </div>
                            <div v-else>-</div>
                          </div>
                        </div>
                        <!-- Last visit and created at -->
                        <div
                          v-else-if="
                            ['lastVisit', 'createdAt'].includes(j.prop)
                          "
                          class="user-created-last-visit"
                        >
                          {{ dateAndTimeFormatter(i[j.prop]) }}
                        </div>
                        <!-- <div v-if="j.prop.trim() == 'displayID'">
                         {{ i[j.prop] }}
                      </div> -->
                        <div v-else class="general-field">
                          {{ i[j.prop] }}
                        </div>
                      </hlx-table-cell>
                      <hlx-table-cell
                        :align="'center'"
                        :width="120"
                        :fixed="'right'"
                      >
                        <span :id="'table-context' + index" class="action-icon">
                          <!-- <i class="icon-edit-regular"></i> &nbsp;
                          <i class="icon-copy-regular"></i> &nbsp;
                          <i class="icon-trash-regular"></i> -->
                          <i
                            style="position: relative"
                            class="icon-more-vertical-filled"
                            :class="i.context === true ? 'active-action' : ''"
                            @click="crudContextMenu($event, i, index)"
                          >
                            <hlx-context-menu
                              :top="containertop"
                              :left="containerleft"
                              :conditionvalue="150"
                              :conditiondown="2"
                              :conditionup="-38"
                              :options="computedTableAction(i)"
                              :data="index.toString()"
                              :show="i.context == true"
                              @chosen="closeCrudContextMenu($event, i, index)"
                            />
                          </i>
                        </span>
                      </hlx-table-cell>
                    </tr>
                  </template>
                  <template v-else #tbody>
                    <tr v-for="item in 10" id="" :key="item">
                      <hlx-table-cell
                        v-if="item == 5"
                        :colspan="theads.length + 2"
                      >
                        No data
                      </hlx-table-cell>
                      <hlx-table-cell v-else :align="'center'">
                      </hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>

              <!-- Pagination -->
              <div
                v-if="tableData?.length > 0"
                class="pagination-container"
                style="
                  padding: 1rem;
                  position: fixed;
                  bottom: 0px;
                  width: -webkit-fill-available;
                "
              >
                <hlx-pagination
                  v-model:rows-per-page="externalRowsPerPage"
                  :total="totalPageNo"
                  enable-rows-per-page
                  rows-per-page
                  :rows-per-page-list="[10, 15, 20]"
                  :options="{
                    attributes: {
                      rowsPerPageTitle: true,
                      rowsPerPageResult: true,
                      rowsPerPageDropdown: {
                        position: {
                          top: 0,
                          right: 1,
                        },
                      },
                    },
                  }"
                  @current-page="currentPage"
                  @updated:rows-per-page="changeRowsPerPage"
                ></hlx-pagination>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import addAccountHierarchyComponent from "@/components/CM/addAccountHierarchyComponent.vue";
import { MLTAxiosInstance } from "@/config/axiosConfig";
import axios from "axios";
console.log("🚀 ~ axios:", axios);
export default {
  name: "OrganisationUserHierarchyView",
  components: {
    addAccountHierarchyComponent,
  },
  data() {
    return {
      currentCompanyName:'',
      addressKeyComponet: 0,
      newConPayload: {
        languageAbility: [{ name: "English" }],
        name: "",
        birthDate: "",
        firstName: "",
        lastName: "",
        title: "",
        tradingName: "",
        companyName: "",
        status: "Pending",
        preferred: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        relatedParty: [],
        tags: [],
        aristocraticTitle: "",
        role: "",
        type: "individual",
        "@baseType": "organisation",
        notes: [],
        attachment: [],
        password: "",
        email: "",
      },
      currPage:1,
      rowsPerPage:10,
      tableData: [],
      selectedHierarchyAccount: "",
      listView: {},
      searchtabledata: [],
      duplicatetabledata: [],
      totalPageNo:0,
      pagination:true,
      theads: [
        { name: "Name", checked: true, id: 2, width: 100 },
        { name: "Email", checked: true, id: 3, width: null },
        { name: "Role", checked: true, id: 4, width: 100 },
        { name: "Created on", checked: true, id: 5, width: 100 },
        { name: "Last visited", checked: true, id: 6, width: 100 },
        { name: "Status", checked: true, id: 7, width: 100 },
      ],
      theadsC: [
        {
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: true,
          disabled: true,
          checked: true,
          name: "Name",
          align: "left",
          width: 200,
        },
        {
          prop: "email",
          label: "Email",
          sortable: false,
          align: "left",
          disabled: true,
          checked: true,
          resizable: true,
          width: null,
          name: "Email",
        },
        {
          prop: "status",
          label: "Status",
          sortable: false,
          align: "left",
          disabled: true,
          checked: true,
          resizable: true,
          width: 93,
          name: "Status",
        },
        {
          prop: "role",
          label: "Role",
          sortable: false,
          align: "left",
          disabled: false,
          checked: true,
          resizable: true,
          width: 103,
          name: "Role",
        },

        {
          prop: "lastVisit",
          label: "Last visit",
          sortable: false,
          align: "left",
          disabled: false,
          checked: true,
          resizable: true,
          width: 203,
          name: "Last visit",
        },
      ],
      headvalue: [
      {
          prop: "displayID",
          label: "ID",
          sortable: false,
          resizable: true,
          disabled: true,
          checked: true,
          name: "ID",
          align: "left",
          width: 200,
        },
        {
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: true,
          disabled: true,
          checked: true,
          name: "Name",
          align: "left",
          width: 200,
        },
        {
          prop: "email",
          label: "Email",
          sortable: false,
          align: "left",
          disabled: true,
          checked: true,
          resizable: true,
          width: null,
          name: "Email",
        },
        {
          prop: "status",
          label: "Status",
          sortable: false,
          align: "left",
          disabled: true,
          checked: true,
          resizable: true,
          width: 93,
          name: "Status",
        },
        {
          prop: "role",
          label: "Role",
          sortable: false,
          align: "left",
          disabled: false,
          checked: true,
          resizable: true,
          width: 103,
          name: "Role",
        },
        {
          prop: "lastVisit",
          label: "Last visit",
          sortable: false,
          align: "left",
          disabled: false,
          checked: true,
          resizable: true,
          width: 203,
          name: "Last visit",
        },
      ],
      view: "All",
      userFilterOptions: {
        type: "filter",
        company: '',
        module: "hierarchycontacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          "@baseType": "organisation",
        },
        searchQuery: "",
      },
      filterQuery: {
        type: 'search',
        module: "hierarchycontacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
       
        },
        limit: 10,
        filterQuery: {
          "@baseType": "organisation",
        },
        searchQuery:"",
        company: this.$cookies.get("company"),
      },
      searchValue:'',
      roleData:'',
      items: [{ label: "Back", link: "/", icon: "icon-arrow-left-regular" }],
      userName: "",
      deactivateUserName: "",
      rolecomponentkey: 0,
      rolecomponentkeyF: 0,
      rolecomponentkeyL: 0,
      rolecomponentkeyE: 0,
      currentYear: new Date().getFullYear(),
      instanceAddress: {},
      instanceData: {},
      emailError: "",
      modal_active_user: false,
      modal_active_Deactivate: false,
      modal_active_role: false,
      newConPayloadRole: {},
      isEmailEmpty: false,
      userPayload: {
        firstName: "",
        lastName: "",
        email: "",
        role: "",
      },
      notification: {},
      breadCrumbItems: [
        {
          label: "/",
        },
      ],
      currentSwitchValue: "all",
      switchOptions: [
        {
          name: "All",
          value: "all",
        },
        {
          name: "Active",
          value: "active",
        },
        {
          name: "Inactive",
          value: "inactive",
        },
        {
          name: "Pending",
          value: "pending",
        },
      ],
      adminName:'',
      rootParent: "",
      hierarchyData: [],
      roleList: [],
      duplicateRoleList:[],
      filtertagvalue:0,
      flattenHierarchyData: [],
      users_type_switch: [
        { name: "All", value: "All" },
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Inactive" },
        { name: "Pending", value: "Pending" },
      ],
      current_switch_value: "All",

    };
  },
  computed:{
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  async mounted() {
   const tenantValue =  this.$cookies.get("tenantEachdetails")
   console.log(tenantValue,'Value',this.$cookies.get("role"),this.$route.query.tenant)
   if(this.$route.query.tenant == 'true'){
    this.currentCompanyName = tenantValue.tradingName
    // await this.refreshHierarchy();
    console.log()
   }else{
    this.currentCompanyName = this.$cookies.get('company')
  }
  await this.refreshHierarchy(this.currentCompanyName);

    const asscss = await MLTAxiosInstance.get(`partyRole`);
    this.roleList = await asscss.data;
    this.roleList = await this.capitalizeAndSort(this.roleList, "role");
    this.duplicateRoleList = await this.capitalizeAndSort(this.roleList, "role");
   

    console.log(this.roleList, "this.roleList");
    let organisationInfo = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${this.$cookies.get(
        "user"
      )}?company=${this.$cookies.get("company")}`
    );

    this.rootParent = organisationInfo.data.rootName;

    this.instanceData = organisationInfo.data;
    this.instanceAddress = this.instanceData.contactMedium.find(
      (item) => item.mediumType === "postalAddress"
    );


    let adminInfo = await MLTAxiosInstance.get(
      `/partymanagement/individual/${this.$cookies.get(
        "user"
      )}?company=${this.$cookies.get("company")}`
    );
    this.adminName = adminInfo.data.name
      console.log(this.adminName,'adminInfo')
    console.log(
      this.instanceData,
      this.instanceAddress?.characteristic?.description,
      "this.instanceData"
    );
  },
  methods: {
    crudContextMenu($event, i, index) {
      this.newConPayload.relatedParty = i.relatedParty;
      let foundExistance = i.relatedParty.some(
        (party) =>
          party._id == this.$cookies.get("user") && party.role == "Manager"
      );
      console.log(
        foundExistance,
        "foundExistance",
        this.$cookies.get("user"),
        i.id,
        i.relatedParty
      );
      // if(this.$cookies.get("user")==i.id || foundExistance)   {
      if ($event.target.className === "icon-more-vertical-filled") {
        if (this.computedTableAction(i)[0]?.label === "Mark as Active") {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 185;
        } else if (this.computedTableAction(i)[1]?.label === "Deactivate") {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 160;
        } else {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 120;
        }
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
      // }else{
      //   console.log("foundExistance else")
      //   this.notification = {
      //     type: "warning",
      //     message: `Heads up! You don't have permission to perform this action!`,
      //   };
      // }
    },
    async closeCrudContextMenu($event, ele, i) {
      console.log("🚀 ~ closeCrudContextMenu ~ i:", i);
      console.log("🚀 ~ closeCrudContextMenu ~ ele:", ele);
      // //
      this.editUser = ele;
      this.listView = ele;
      this.editUser.role =
        ele.role && ele.role.length > 0 ? ele.role[0]?.name : "";

      if ($event.toLowerCase() === "edit") {
        this.$router.replace({
          name: "additionalFieldsUserView",
          query: { id: ele.id },
        }); // this.$router.replace('/settings/users/edit')
        //     this.editView = true;
        //     this.modal_active_user = true
        //     this.newConPayload = JSON.parse(JSON.stringify(ele));
        //     console.log(this.newConPayload,"this.newConPayload edit view user");

        //   this.addressPreVal = this.newConPayload.contactMedium[2];

        //     const preferredMedium = this.newConPayload?.contactMedium?.find(
        //   (item) => item.preferred
        // );
        // console.log(preferredMedium);
        // // this.newConPayload = this.newConPayload;
        // this.newConPayload.preferred = preferredMedium
        //   ? preferredMedium.mediumType
        //   : null;
        // console.log(this.newConPayload);
      } else if ($event.toLowerCase() == "deactivate") {
        console.log(ele, "sdfghjk");
        let i = {
          id: ele._id,
          "@baseType": "organisation",
          status: "Inactive",
        };
        const contacts = await MLTAxiosInstance.patch(
          `/partymanagement/individual/?company=${ele.company}`,
          i
        );
        console.log(contacts, "lllalsldddddddddddddddddddddddjsddslk");
        // this.contactsDataCalling(this.currPage, this.rowsPerPage);
        this.handleUserSearch(this.searchValue);
        // await MLTAxiosInstance.get(`/userHierarchy?company=${this.currentCompanyName}`);
        if (contacts.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        // this.contactsDataCalling(this.currPage, this.rowsPerPage);
        this.handleUserSearch(this.searchValue);
        // await MLTAxiosInstance.get(`/userHierarchy?company=${this.currentCompanyName}`);
      } else if ($event.toLowerCase() == "mark as active") {
        let i = {
          id: ele._id,
          status: "Active",
          "@baseType": "organisation",
          context: false,
        };
        const contacts = await MLTAxiosInstance.patch(
          `/partymanagement/individual/?company=${ele.company}`,
          i
        );
        if (contacts.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        // this.contactsDataCalling(this.currPage, this.rowsPerPage);
        this.handleUserSearch(this.searchValue);
        // await MLTAxiosInstance.get(`/userHierarchy?company=${this.currentCompanyName}`);
      } else if ($event.toLowerCase() === "send invite") {
        console.log(i);
        this.inviteUser(ele);
      } else if ($event.toLowerCase() === "reset password") {
        let i = {
          id: ele._id,
          setValidity: "Pending",
          "@baseType": "organisation",
          context: false,
        };
        const contacts = await MLTAxiosInstance.patch(
          `/partymanagement/individual/?company=${ele.company}`,
          i
        );
        contacts;
        this.resetPasswordfunc(ele);
        //   this.$router.replace({
        //   name: 'resetPassword',
        //   query: { id: ele.id,
        //            email:ele.email
        //    },
        // });
      } else if ($event.toLowerCase() == "change role") {
        console.log(ele, "checkkk ");
        this.userName = ele.firstName + ele.lastName;
        this.newConPayload = JSON.parse(JSON.stringify(ele));
        //  this.roleData = JSON.parse(JSON.stringify(ele))
        this.modal_active_role = true;
      }
      // else if ($event.toLowerCase() == "delete") {
      //   this.delete_modal_active = true;
      //   this.deleteobj = ele;
      //   this.delete_name = ele.description;
      //   this.deleterowindex = i;
      //   // await axios.delete("http://localhost:5700/quotemanagement/" + ele._id, {
      //   //   headers: {
      //   //     Authorization: this.$cookies.get("token"),
      //   //   },
      //   // });
      // }
    },
    computedTableAction(data) {
      if (data.status && data.status.toLowerCase() == "active") {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Deactivate",
            icon: "icon-power-off-square-regular",
          },
          {
            label: "Reset password",
            icon: "icon-lock-viewfinder-regular",
          },
          {
            label: "Change role",
            icon: "icon-user-change-regular",
          },
        ];
      } else if (data.status && data.status.toLowerCase() == "pending") {
        return [
        {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Send invite",
            icon: "icon-send-right-regular",
          },
          
          {
            label: "Change role",
            icon: "icon-user-change-regular",
          },
        ];
      } else if (data.status && data.status.toLowerCase() == "inactive") {
        return [
        {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Mark as Active",
            icon: "icon-check-square-regular",
          }
        ];
      }
      // return options.filter((option) => {
      //   if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "active" &&
      //     ["Mark as Active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //   //  data.status &&
      //     data.status.toLowerCase() == "inactive" &&
      //     ["Deactivate"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "pending" &&
      //     ["Deactivate", "Mark as Active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    async currentPage(val) {
      console.log("🚀 ~ currentPage ~ val:", val);
      this.userFilterOptions.paginatedQuery.currentPage = val;
      if (this.searchValue.trim()) {
        this.userFilterOptions.searchQuery = this.searchValue;
        this.userFilterOptions.type = "filter-search";
      }
      this.loadTable();
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    async changeRowsPerPage(count) {
      this.userFilterOptions.paginatedQuery.rowsPerPage = count;
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    async usersSelected(val) {
      this.view = await val.name;
      if (this.view == "Active") {
        this.pagination = false
        this.userFilterOptions.filterQuery = {
          "@baseType": "organisation",
          company: this.currentCompanyName,
        };
        this.userFilterOptions.filterQuery.status = "Active";
      } else if (this.view == "Inactive") {
        this.pagination = false
        this.userFilterOptions.filterQuery = {
        "@baseType": "organisation",
        company: this.currentCompanyName,

      };
        this.userFilterOptions.filterQuery.status = "Inactive";
      } else if (this.view == "Pending") {
        this.pagination = false
        this.userFilterOptions.filterQuery = {
        "@baseType": "organisation",
        company: this.currentCompanyName,
      };
        this.userFilterOptions.filterQuery.status = "Pending";
      } else {
        this.pagination = true
        this.refreshHierarchy(this.currentCompanyName)
      }
      if(this.view != 'All'){

      const filterResultData = await MLTAxiosInstance.post(
        `util/filterData`,
        this.userFilterOptions
      );
      this.tableData = filterResultData.data.data;
      this.totalPageNo = filterResultData.data.total;

      this.paginatedData;

      console.log("🚀 ~ filterResultData:", filterResultData);
      }
    },
    async loadTable() {
      let tempIndex = this.userFilterOptions.paginatedQuery.currentPage;
      const filterResultData = await MLTAxiosInstance.post(
        `util/filterData`,
        this.userFilterOptions
      );
      this.userFilterOptions.paginatedQuery.currentPage = tempIndex;
      this.datalength = filterResultData.data.total;
      this.tableData = filterResultData.data.data;
      this.totalPageNo = filterResultData.data.total;
      this.paginatedData;
      console.log(
        "🚀 ~ loadTable ~ this.latestPersistPage:",
        this.latestPersistPage
      );
    },
    async organisationdataCalling(currentPage, rowsPerPage) {
      const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
      this.company = val.data.company;
      this.filterQuery["company"] = this.company;
      this.filterQuery["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQuery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      const filterResultData = await MLTAxiosInstance.post(
        `util/filterData`,
        this.filterQuery
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.hierarchyData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
        this.paginatedData;
      }
    },
    async handleUserSearch(val){
      this.searchValue = val
     
        // this.searchValue = val;
        if (val && val != "") {
          this.pagination = false
        this.userFilterOptions.type = "filter-search";
        this.userFilterOptions.searchQuery = val;
        this.userFilterOptions.company = this.$cookies.get(
        "company"
      )
        const filterResultData = await MLTAxiosInstance.post(
          `util/filterData`,
          this.userFilterOptions
        );
        console.log("🚀 ~ filterResultData:", filterResultData);
        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
      } else {
        if(this.view!= 'All'){
        this.pagination = false
        this.userFilterOptions.type = "filter";
        this.userFilterOptions.searchQuery == "";
        const filterResultData = await MLTAxiosInstance.post(
          `util/filterData`,
          this.userFilterOptions
        );
        console.log("🚀 ~ filterResultData:", filterResultData);
        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
        }else{
          this.pagination = true
          this.refreshHierarchy(this.currentCompanyName)
        }
      }
      if(val === ''){
        await this.refreshHierarchy(this.currentCompanyName);
      }
      // this.organisationdataCalling(this.currPage, this.rowsPerPage)
    },
    pathTo() {
      this.$router.push("/settings");
    },
    async deactiveMethod() {
      if(this.roleData == ''){
        this.roleData = this.adminName
      }
      this.modal_active_Deactivate = false;
      let i = {
        id: this.deactivateUser._id,
        "@baseType": "organisation",
        status: "Inactive",
      };
      const contacts = await MLTAxiosInstance.patch(
        `/partymanagement/individual/?company=${this.deactivateUser.company}`,
        i
      );
      console.log(contacts, "lllalsldddddddddddddddddddddddjsddslk");
      // this.contactsDataCalling(this.currPage, this.rowsPerPage);
      this.refreshHierarchy(this.currentCompanyName)

      if (contacts.data.email) {
        this.alertmsg = "success";
        this.notification = {
          type: "success",
          message: `The user ${this.deactivateUserName} has been successfully deactivated. All data has been reassigned to ${this.roleData}.`,
        };
        this.rolesEditButton = true;
      } else {
        this.alertmsg = "danger";
        this.notification = {
          type: "danger",
          message: `Data could not be inserted.`,
        };
      }
      // this.contactsDataCalling(this.currPage, this.rowsPerPage);
      this.refreshHierarchy(this.currentCompanyName)
    },
    async ChangeRole() {
      let obj = [
        {
          relatedParty: [
            {
              id: this.newConPayloadRole._id,
            },
          ],
          name: this.roleData,
        },
      ];

      let result = await MLTAxiosInstance.patch(
        `/partyRole/bulkwrites/v4`,
        obj
      );
      result;

      if (result.status === 200) {
        this.notification = {
          type: "success",
          message: `The role for ${this.newConPayloadRole.name} has been successfully updated to ${this.roleData} !`,
        };
      }

      this.closeAddNewContactModal();
      this.refreshHierarchy(this.currentCompanyName)
    },
    async handleContextMenu(event, ele, index) {
      console.log("handleContextMenu", event, ele, index);

      if (event === "Reset password") {
        this.deactivateUserName = ele.firstName + " " + ele.lastName;
        let i = {
          id: ele._id,
          setValidity: "Pending",
          context: false,
          "@baseType": "organisation",
        };
        const contacts = await MLTAxiosInstance.patch(
          `/partymanagement/individual/?company=${ele.company}`,
          i
        );
        contacts;
        this.resetPasswordfunc(ele);
      } else if (event === "Deactivate") {
        this.modal_active_Deactivate = true;
        this.deactivateUser = ele;
        this.deactivateUserName = ele.firstName + " " + ele.lastName;
      } else if (event === "Change role") {
        this.userName = ele.firstName + ele.lastName;
        this.newConPayloadRole = JSON.parse(JSON.stringify(ele));
        this.modal_active_role = true;
        this.roleList = this.duplicateRoleList.filter(
            (item) => item.role !== ele.role
          );
      } else if (
        event.toLowerCase() == "activate" ||
        event == "Mark as active"
      ) {
        let i = {
          id: ele._id,
          "@baseType": "organisation",
          status: "Active",
        };
        const contacts = await MLTAxiosInstance.patch(
          `/partymanagement/individual/?company=${ele.company}`,
          i
        );
        if (contacts.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
          this.rolesEditButton = true;
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        // this.contactsDataCalling(this.currPage, this.rowsPerPage);
        this.refreshHierarchy(this.currentCompanyName)
      } else if (["edit", "view"].includes(event.toLowerCase())) {
        if('view' == event){
          this.$router.push({
            name: "additionalFieldsUserView",
            query: { id: ele._id, edit: false },
          });
        }else{

          this.$router.push({
            name: "additionalFieldsUserView",
            query: { id: ele._id, edit: true },
          });
        }
      }
    },

    async resetPasswordfunc(i) {
      console.log(i, "mail send request");
      if (i != undefined) {
        this.notification = {
          type: "success",
          message: `Password reset link has been successfully sent to ${this.deactivateUserName} email.`,
        };

        console.log(i, "sendEmail");
        if(this.$cookies.get('company') === 'Uat'){
        var prod = process.env.VUE_APP_BASE_URL_bell
      }else{
        prod = process.env.VUE_APP_BASE_URL
      }
      prod;
        const dev = "http://localhost:8080";
        dev;

        let vbnm = 2;
        let tokenPayload = {
          user: {
            email: i.email,
            company: i.company,
          },
          validTo: vbnm + "h",
          additionalFields: {
            supplierLogin: true,
          },
        };

        let generatedToken = await MLTAxiosInstance.post(
          "/partymanagement/individual/generateToken",
          tokenPayload
        );
        generatedToken = generatedToken.data;

        console.log(generatedToken, "mail send sucessfully");
        let mail = {
          to: [i.email],
          subject: `Password Reset Request`,
          template: `<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
    <link href='https://fonts.googleapis.com/css?family=Open+Sans' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <style type="text/css" media="screen">
        body {
            padding: 0 !important;
            margin: 0 !important;
            display: flex !important;
            justify-content: center !important;
            min-width: 100% !important;
            width: 100% !important;
            background: #f5f5f5;
            -webkit-text-size-adjust: none;
        }
        .mobile-shell {
            width: 100%;
        }
        .td {
            width: 100%;
        }
        .wrapper {
            width: 100%;
            table-layout: fixed;
        }
        .p35-15 {
            padding: 35px 17px;
        }
        .headline {
            text-align: center;
        }
        .hero-title-1 {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            padding-top: 15px;
            line-height: 28px;
            text-align: center;
            color: #191A15;
        }
        .hero-content {
            padding-top: 25px;
            padding-left: 100px;
            padding-right: 100px;
            color: black;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 25px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .btn {
            text-align: center;
            padding-top: 25px;
        }
        .btn a {
            background-color: #54BD95;
            border-radius: 6px;
            color: #ffffff;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            text-align: center;
            text-decoration: none;
            width: 218.18px;
            height: 38px;
            -webkit-text-size-adjust: none;
            padding: 10px 12px;
            line-height: 15px;
        }
        .disclaimer2 {
            padding: 40px 77px;
            color: #FFFFFF;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .footer-link a {
            color: #FFFFFF;
            font-size: 14px;
        }
        .address {
            padding-left: 17px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            color: #FFFFFF;
            margin-top: 15px;
            margin-bottom: 0px;
            letter-spacing: .01em;
            line-height: 20px;
        }
        .social-icons {
            float: right;
            margin-right: -50px;
            padding-top: 20px;
        }
        .social-icons a {
            padding-left: 5px;
            padding-right: 5px;
            color: #FFFFFF;
        }
    </style>
</head>

<body class="body" style="
 display: grid;
    justify-items: center;
    background-color: #f5f5f5;
">
    <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
    <table width="612px" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5 " style="margin: 0 auto">
        <tr>
            <td align="center" valign="top">
                <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                    <tr>
                        <td class="td">
                            <table border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td  height: 501px;">
                                        <!-- Header -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="wrapper">
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="10" cellspacing="0" width="77%" class="wrapper" bgcolor="#f5f5f5">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="wrapper" bgcolor="#ffffff">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Header -->
                                        <!-- Start Content -->
                                        <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501" cellpadding="0">
                                            <tr>
                                                <td bgcolor="#FFFFFF" class="p35-15">
                                                    <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                         <div class="container">
    <p>Hi ${i.name},</p>
      <p>
        We received a request to reset your password for your account. To reset your password, please click the link below:
      </p>
      <p>
        <a href=${prod}/supplierinvite/${i.company}/${i.id}/${
            generatedToken.token
          }?login=Userinvite&Password=${"RestPassword"} class="mob-btn" target="_blank"> https://www.${this.$cookies.get(
            "company"
          )}.com/password-reset?token=abcdef123456</a>
      </p>
      <p>
        If you didn’t request a password reset, please ignore this email.
      </p>
      <p>Thank you,</p>
      <p>${this.currentCompanyName} Team</p>
    </div>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- HERO Images-->
                                    </td>
                                </tr>
                            </table>
                            <!-- END Content -->
                            <!-- Footer -->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding: 0 15px;" bgcolor="#ffffff">
                                <tr>
                                    <td bgcolor="#161C2B" class="disclaimer2">
                                        <span class="footer-link"><a href="https://halleyx.com">T&C</a><a style="padding-left:40px; padding-right: 40px;" href="https://halleyx.com">Privacy</a><a href="https://halleyx.com">Cookies</a></span><br/>
                                        <br/>
                                        © ${
                                          this.currentYear
                                        } ${this.$cookies.get(
            "company"
          )}. Copyright and rights reserved
                                        <p class="address">${
                                          this.instanceAddress.characteristic
                                            .description
                                        }</p>
                                        <span style="float:left; margin-left: -60px; padding-top:20px;"><img width="100" src=""/></span>
                                        <span class="social-icons"><a href="https://www.linkedin.com/company/halleyx/"><i class="fa fa-linkedin fa-lg"></i></a>
                                            <a href="https://halleyx.com/"><i class="fa fa-facebook fa-lg"></i></a>
                                            <a href="https://www.instagram.com/halleyx_inc/"><i class="fa fa-instagram fa-lg"></i></a></span>
                                    </td>
                                </tr>
                            </table>
                            <!-- END Footer -->
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    <img src="images?q=tbn:ANd9GcRYx7vG1ermb_muIP457oVO48JixkaC0RvhAA&usqp=CAU" width="1" height="1">
</body>

</html>

`,
        };
        // const sendEmail =
        await MLTAxiosInstance.post(`/email`, mail);
      }
    },
    emailNameCheck() {
      if (
        this.emailError.length > 0 &&
        this.emailError === this.userPayload.email
      ) {
        this.isEmailEmpty = true;
      } else {
        this.isEmailEmpty = false;
      }
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      }
    },
    async getSingleUser(i) {
      let sampleTheads = await MLTAxiosInstance.get(
        `/partymanagement/individual/${i}`
      );
      this.inviteUser(sampleTheads.data);
    },
    async inviteUser(i) {
      console.log(i, "mail send request");
      if (i != undefined) {
        this.notification = {
          type: "success",
          message: "User invitation email sent",
        };

        this.sendEmail(i);
      }
    },
    async sendEmail(i) {
      console.log(i, "sendEmail");
      if(this.$cookies.get('company') === 'Uat'){
        var prod = process.env.VUE_APP_BASE_URL_bell
      }else{
        prod = process.env.VUE_APP_BASE_URL
      }
      prod;
      const dev = "http://localhost:8080";
      dev;

      let vbnm = 24;
      let tokenPayload = {
        user: {
          email: i.email,
          company: i.company,
        },
        validTo: vbnm + "h",
        additionalFields: {
          supplierLogin: true,
        },
      };

      let generatedToken = await MLTAxiosInstance.post(
        "/partymanagement/individual/generateToken",
        tokenPayload
      );
      generatedToken = generatedToken.data;

      console.log(generatedToken, "mail send sucessfully");
      let mail = {
        to: [i.email],
        subject: `${i.company} user Invitation`,
        template: `
      <!--
 -->

<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
    <link href='https://fonts.googleapis.com/css?family=Open+Sans' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <style type="text/css" media="screen">
        body {
            padding: 0 !important;
            margin: 0 !important;
            display: flex !important;
            justify-content: center !important;
            min-width: 100% !important;
            width: 100% !important;
            background: #f5f5f5;
            -webkit-text-size-adjust: none;
        }
        .mobile-shell {
            width: 100%;
        }
        .td {
            width: 100%;
        }
        .wrapper {
            width: 100%;
            table-layout: fixed;
        }
        .p35-15 {
            padding: 35px 15px;
        }
        .headline {
            text-align: center;
        }
        .hero-title-1 {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            padding-top: 15px;
            line-height: 28px;
            text-align: center;
            color: #191A15;
        }
        .hero-content {
            padding-top: 25px;
            padding-left: 100px;
            padding-right: 100px;
            color: black;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 25px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .btn {
            text-align: center;
            padding-top: 25px;
        }
        .btn a {
            background-color: #54BD95;
            border-radius: 6px;
            color: #ffffff;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            text-align: center;
            text-decoration: none;
            width: 218.18px;
            height: 38px;
            -webkit-text-size-adjust: none;
            padding: 10px 12px;
            line-height: 15px;
        }
        .disclaimer2 {
            padding: 40px 77px;
            color: #FFFFFF;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .footer-link a {
            color: #FFFFFF;
            font-size: 14px;
        }
        .address {
            padding-left: 17px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            color: #FFFFFF;
            margin-top: 15px;
            margin-bottom: 0px;
            letter-spacing: .01em;
            line-height: 20px;
        }
        .social-icons {
            float: right;
            margin-right: -50px;
            padding-top: 20px;
        }
        .social-icons a {
            padding-left: 5px;
            padding-right: 5px;
            color: #FFFFFF;
        }
    </style>
</head>

<body class="body" style="
 display: grid;
    justify-items: center;
    background-color: #f5f5f5;
">
    <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
    <table width="612px" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5 " style="margin: 0 auto">
        <tr>
            <td align="center" valign="top">
                <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                    <tr>
                        <td class="td">
                            <table border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td  height: 501px;">
                                        <!-- Header -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="wrapper">
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="10" cellspacing="0" width="77%" class="wrapper" bgcolor="#f5f5f5">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="wrapper" bgcolor="#ffffff">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Header -->
                                        <!-- Start Content -->
                                        <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501" cellpadding="0">
                                            <tr>
                                                <td bgcolor="#FFFFFF" class="p35-15">
                                                    <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <!-- headline -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="headline">
                                                                <img style="width: 15%; object-fit: contain; clip-path: circle();" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtteDflN13M27CDlAPdC7L_fNJ4Eus3fEFBkI8JKxFLmcms3TsIv_EUcvuyjVo46v1k4&usqp=CAU" alt="">
                                                            </td>
                                                        </tr>
                                                        <!-- #headline -->
                                                        <!-- hero-title-1 -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1">
                                                                ${this.$cookies.get(
                                                                  "company"
                                                                )}<br>invited you
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-content">
                                                                You have been invited by ${this.$cookies.get(
                                                                  "company"
                                                                )}  to join  <a href="mailto:contact@halleyx.com" style="text-decoration: none; color:#54BD95">${
          i.company
        }</a>. To get started, accept the invitation below.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="btn">
                                                                <a href=${prod}/supplierinvite/${
          i.company
        }/${i._id}/${
          generatedToken.token
        }?login=Userinvite&Password=${"NewPassword"} class="mob-btn" target="_blank">Accept invitation</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF"  class="hero-content"  style="color:#a6a6a6">
                                                                Hiccups might happen. Your feedback and requests are very welcomed and encouraged at <a href="mailto:contact@halleyx.com" style="color:#54BD95">contact@halleyx.com</a>. We can't wait to see what you create with ${this.$cookies.get(
                                                                  "company"
                                                                )}.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- HERO Images-->
                                    </td>
                                </tr>
                            </table>
                            <!-- END Content -->
                            <!-- Footer -->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding: 0 15px;" bgcolor="#ffffff">
                                <tr>
                                    <td bgcolor="#161C2B" class="disclaimer2">
                                        <span class="footer-link"><a href="https://halleyx.com">T&C</a><a style="padding-left:40px; padding-right: 40px;" href="https://halleyx.com">Privacy</a><a href="https://halleyx.com">Cookies</a></span><br/>
                                        <br/>
                                        © ${
                                          this.currentYear
                                        } ${this.$cookies.get(
          "company"
        )}. Copyright and rights reserved
                                        <p class="address">${
                                          this?.instanceAddress?.characteristic
                                            ?.description
                                        }</p>
                                        <span style="float:left; margin-left: -60px; padding-top:20px;"><img width="100" src=""/></span>
                                        <span class="social-icons"><a href="https://www.linkedin.com/company/halleyx/"><i class="fa fa-linkedin fa-lg"></i></a>
                                            <a href="https://halleyx.com/"><i class="fa fa-facebook fa-lg"></i></a>
                                            <a href="https://www.instagram.com/halleyx_inc/"><i class="fa fa-instagram fa-lg"></i></a></span>
                                    </td>
                                </tr>
                            </table>
                            <!-- END Footer -->
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    <img src="images?q=tbn:ANd9GcRYx7vG1ermb_muIP457oVO48JixkaC0RvhAA&usqp=CAU" width="1" height="1">
</body>

</html>`,
      };
      // const sendEmail =
      await MLTAxiosInstance.post(`/email`, mail);
    },
    async addNewContact() {
      const newConPayload = {
        name: `${this.userPayload.firstName} ${this.userPayload.lastName}`,
        birthDate: "",
        firstName: `${this.userPayload.firstName}`,
        lastName: `${this.userPayload.lastName}`,
        title: "",
        tradingName: this.currentCompanyName,
        companyName: this.currentCompanyName,
        status: "Pending",
        setValidity: "Pending",
        languageAbility: [{ code: "en", name: "english" }],
        preferred: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: `${this.userPayload.email}`,
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          this.instanceAddress,
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        relatedParty: [],
        tags: [],
        aristocraticTitle: "",
        role: this.userPayload.role,
        type: "individual",
        "@baseType": "organisation",
        notes: [],
        attachment: [],
        password: "",
        email: this.userPayload.email,
      };
      newConPayload.parentCompany = this.rootParent;
      newConPayload[
        "familyName"
      ] = `${this.userPayload.firstName} ${this.userPayload.lastName}`;
      newConPayload[
        "givenName"
      ] = `${this.userPayload.firstName} ${this.userPayload.lastName}`;
      newConPayload[
        "name"
      ] = `${this.userPayload.firstName} ${this.userPayload.lastName}`;
      console.log(newConPayload, "newConPayload");

      let payload = JSON.parse(JSON.stringify(newConPayload));

      // payload.relatedParty.push({role:payload.aristocraticTitle,organization:payload.company ,"@type":"multiorganization"})

      payload.tradingName = this.currentCompanyName;
      payload.companyName = this.currentCompanyName;
      payload.company = this.currentCompanyName;

      payload.lastUpdate = new Date();
      let result;
      this.isExistingName = false;
      this.isExistingNameErrorMessage = "";
      this.isExistingEmail = false;
      this.isExistingEmailErrorMessage = "";
       delete payload.password 
      try {
        let result = await MLTAxiosInstance.post(
          `/partymanagement/individual/account/signup`,
          payload
        );
        console.log(result, "result");
        console.log(result.data.message, "result.data.tradingName");
        if (result.data.userId) {
          this.getSingleUser(result.data.userId);

          // this.notification = {
          //   type: "success",
          //   message: `Nice work! Your new user "${newConPayload.name}" is now in the list!`,
          // };
          this.closeAddNewContactModal();
          this.refreshHierarchy(this.currentCompanyName)
        } else {
          // this.notification = {
          //   type: "warning",
          //   message: `This email was already registered`,
          // };
          this.isEmailEmpty = true;
          this.emailError = this.userPayload.email;
        }
      } catch (error) {
        if (error.response) {
          console.log("Error k data:", error.response.data);
          console.log("Error status:", error.response.status);
          console.log("Error headers:", error.response.headers);
        } else if (error.request) {
          this.isEmailEmpty = true;
          this.emailError = this.userPayload.email;

          // this.notification = {
          //   type: "warning",
          //   message: `This email was already registered`,
          // };
          console.log("Error request:", error.request);
        } else {
          console.log("Error message:", error.message);
        }
        this.isEmailEmpty = true;
        // this.notification = {
        //   type: "warning",
        //   message: `This email was already registered`,
        // };
        console.log("Error config:", error.config);
      }
      this.addressKey += 1;

      if (result?.data) {
        // this.notification = {
        //   type: "success",
        //   message: `Nice work! Your new user "${newConPayload.name}" is now in the list!`,
        // };
      }

      // this.contactsDataCalling(this.currPage, this.rowsPerPage);
    },
    addNewUser() {
      this.modal_active_user = true;

      this.tagcomponetkey += 1;
    },
    closeAddNewContactModal() {
      console.log(this.tagsList, "tagsList");
      this.modalKey += 1;
      this.rolecomponentkey += 1;
      this.rolecomponentkeyF += 1;
      this.rolecomponentkeyL += 1;
      this.rolecomponentkeyE += 1;
      this.isEmailEmpty = false;
      this.editMode = false;
      this.userPayload = {
        firstName: "",
        lastName: "",
        email: "",
        role: "",
      };
      this.modal_active_role = false;
      this.modal_active_Deactivate = false;
      this.modal_active_user = false;
    },
    handleTabSelection(tab) {
      console.log(tab);
    },
    // Handle child drag
    async handleChildDragOld(relationShipType, child, parent) {
      console.log(
        "🚀 ~ handleChildDrag ~ relationShipType, child, parent:",
        relationShipType,
        child,
        parent
      );
      if (!relationShipType || !child || !parent) {
        this.notification = {
          message: `Failed to move user`,
          type: "error",
        };
        return;
      }
      let childPayload = null;
      let parentPayload = null;
      switch (relationShipType) {
        case "child": {
          // Check if child already have parentRelationship
          if (child.id && parent.childRelationship) {
            const isExistInTheParent = parent?.childRelationship?.find(
              (item) => item.id === child._id
            );
            if (isExistInTheParent) {
              this.notification = {
                // User friendly message for user already in the same parent
                message: `User ${child.name} is already in the same parent`,
                type: "primary",
              };
              return;
            }
            // this.notification = {
            //   // User friendly message for user already in the same parent
            //   message: `User ${child.name} is already in the same parent`,
            //   type: "primary",
            // };
            // return
          }
          // Attach child to the parent
          childPayload = {
            id: parent._id,
            type: "child",
            childRelationship: [
              {
                id: child._id,
                name: child.name,
              },
            ],
          };
          // Attach parent to the child
          parentPayload = {
            id: child._id,
            type: "parent",
            parentRelationship: {
              id: parent._id,
              name: parent.name,
            },
          };

          break;
        }
        case "sibling": {
          // Check if parentRelationship exist for parent then we can move the child to the parent otherwise we can't move the child to the parent instead we will move child to top level
          if (parent.parentRelationship && parent.parentRelationship.id) {
            // Assign parent as parent of the parent
            childPayload = {
              id: parent.parentRelationship.id,
              type: "child",
              childRelationship: [
                {
                  id: child._id,
                  name: child.name,
                },
              ],
            };
            // Attach parent to the child
            parentPayload = {
              id: child._id,
              type: "parent",
              parentRelationship: {
                id: parent.parentRelationship.id,
                name: parent.parentRelationship.name,
              },
            };
          } else if (child.parentRelationship && child.parentRelationship.id) {
            // Attach parent to the child
            parentPayload = {
              id: child._id,
              type: "parent",
              isParent: true,
              parentRelationship: {},
            };
            // Remove child from the parent
            childPayload = {
              id: child.parentRelationship.id,
              type: "child",
              childRelationship: [],
            };
          } else {
            // Send notification about in the same level
            this.notification = {
              message: `User ${child.name} is already in the same level`,
              type: "primary",
            };
            return;
          }
          break;
        }
        default:
          break;
      }
      console.log(childPayload, parentPayload, "handleChildDrag");

      // Update hierarchy child data
      // const updateChildPayload = await MLTAxiosInstance.post('/userHierarchy/update',childPayload);

      // if(updateChildPayload.data) {
      //   // Update hierarchy parent data
      //   const updateParentPayload = await MLTAxiosInstance.post('/userHierarchy/update',parentPayload);
      //   if(updateParentPayload.data) {
      //     await this.refreshHierarchy();
      //     this.notification = {
      //       message: `User ${child.name} successfully moved to ${parent.name}`,
      //       type: "success",
      //     };
      //   }
      // }
    },
    // Handle child drag
    async handleChildDrag(relationShipType, child, parent, hierarchyData) {
      console.log(
        "🚀 ~ handleChildDrag ~ relationShipType, child, parent:",
        relationShipType,
        hierarchyData,
        child,
        parent
      );
      if (!relationShipType || !child || !parent) {
        this.notification = {
          message: `Failed to move user`,
          type: "warning",
        };
      }

      switch (relationShipType) {
        case "child": {
          // Check if child is already in the same parent
          if (
            child.parentRelationship &&
            child.parentRelationship.id === parent.id
          ) {
            this.notification = {
              message: `User ${child.name} is already in the same parent`,
              type: "primary",
            };
            return;
          }
          // Link a child to a parent as a child
          // this.flattenHierarchyData = JSON.parse(
          //   JSON.stringify(hierarchyData)
          // ).map((items) => {
          //   // if (items.id === parent.id) {
          //   //   items.childRelationship = items.childRelationship || [];
          //   //   items.childRelationship.push({ name: child.name, id: child.id });
          //   // }
          //   if (items.id === child.id) {
          //     items.level = parent.level + "." + 1;
          //     items.parentRelationship = { name: parent.name, id: parent.id };
          //   }
          //   return items;
          // });

          break;
        }

        default:
          break;
      }

      const hierarchyResponse = await MLTAxiosInstance.post(
        "/userHierarchy/update/v2",
        { child, parent, relationship: relationShipType }
      );
      // if (
      //   hierarchyResponse.data ||
      //   (hierarchyResponse.data && hierarchyResponse.data.ok)
      // ) {
      //   this.hierarchyData = hierarchyResponse.data;
      //   this.notification = {
      //     message: `User ${child.name} successfully moved to ${parent.name}`,
      //     type: "success",
      //   };
      // }
      // const hierarchyResponse = await axios.post(
      //   "http://localhost:5070/userHierarchy/update/v2",
      // const hierarchyResponse = await MLTAxiosInstance.post(
      //   "/userHierarchy/update/v2",

      //   { child, parent, relationship: relationShipType },
      //   {
      //     headers: {
      //       Authorization: "Bearer " + this.$cookies.get("token"),
      //     },
      //   }
      // );
      if (
        hierarchyResponse.data ||
        (hierarchyResponse.data && hierarchyResponse.data.ok)
      ) {
        // this.hierarchyData = hierarchyResponse.data;
        await this.refreshHierarchy(this.currentCompanyName)
        let message = `User ${child.name} successfully moved to ${parent.name}`;
        if (
          !child.parentRelationship ||
          (child.parentRelationship && child.parentRelationship.id)
        ) {
          // message = `User ${child.name} successfully moved to top level`;
          message = hierarchyResponse.data.message;
        }
        this.notification = {
          message,
          type: "success",
        };
        return;
      }
    },
    async updateChildRelationship(payload = {}) {
      try {
        // Update hierarchy child data
        return await MLTAxiosInstance.post("/userHierarchy/update", payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    // Remove user from the hierarchy
    async handleUserRemoval(user) {
      if (!user) {
        this.notification = {
          message: `Failed to delete user`,
          type: "error",
        };
      }
      const response = await MLTAxiosInstance.post(
        `/partymanagement/individual/deleteAndUnlinkUser`,
        {
          email: user.email,
          userId: user.id,
        }
      );
      if (response.data) {
        this.hierarchyData = this.hierarchyData.filter(
          (item) => item.id !== user.id
        );
        this.notification = {
          message: `User ${user.name} removed successfully`,
          type: "success",
        };
        await         this.refreshHierarchy(this.currentCompanyName)

      }
      // const response = await axios.post(
      //   `http://localhost:5070/partymanagement/individual/deleteAndUnlinkUser`,
      //   {
      //     email: user.email,
      //     userId: user.id,
      //   },
      //   {
      //     headers: {

      //       Authorization: "Bearer " + this.$cookies.get("token"),
      //     },
      //   }
      // );
      // if (response.data) {
      //   this.hierarchyData = this.hierarchyData.filter(
      //     (item) => item.id !== user.id
      //   );
      //   this.notification = {
      //     message: `User ${user.name} removed successfully`,
      //     type: "success",
      //   };
      //   await this.refreshHierarchy();
      // }
    },
    // Reload the hierarchy data
    async refreshHierarchy(company) {
      const hierarchyResponse = await MLTAxiosInstance.get(`/userHierarchy?company=${company}`);
      if (hierarchyResponse.data) {
        this.hierarchyData = hierarchyResponse.data;
      }
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
