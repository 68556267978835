<template>
  <hlx-modal
    :modal-active="showAddNewAdrressModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'400px'"
    :width="'650px'"
    :modal="true"
    @close="closeAddNewAdrressModal"
  >
    <template #header>
      <div>
        <!-- Add new address -->
        <span v-if="staticData">{{ staticData?.addNewAddress?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #content>
      <div
        class="modal-content add-contact-modal"
        style="
          padding: 20px;
          padding-bottom: 0;
          grid-template-columns: 38.5% 58.5%;
        "
      >
        <span class="left-section">
          <hlx-input
          v-if="staticData"
            v-model:value="newAddressPayload.streetNumber"
            :pre-val="newAddressPayload.streetNumber"
            :label-value="'Street number'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isFirstNameEmpty"
            :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
          v-if="staticData"
            v-model:value="newAddressPayload.unitNumber"
            :pre-val="newAddressPayload.unitNumber"
            :label-value="'Unit number'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isLastNameEmpty"
            :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <hlx-input
              :key="key"
              v-model:value="newAddressPayload.city"
              :pre-val="newAddressPayload.city"
              :options="designationList"
              :required="true"
              prop-value="name"
              :label-value="'City'"
              :placeholder-value="'City'"
              :label-animation="true"
              :clearable="true"
            ></hlx-input>
          </div>
          <div class="input-wrapper">
            <hlx-input
          v-if="staticData"
              v-model:value="newAddressPayload.postalCode"
              :pre-val="newAddressPayload.postalCode"
              :label-value="'Postal code'"
              :label-animation="true"
              :required="true"
              :display-error="true"
              :custom-error="isLastNameEmpty"
            :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
        </span>
        <span class="right-section">
          <hlx-input
          v-if="staticData"
            v-model:value="newAddressPayload.streetName"
            :pre-val="newAddressPayload.streetName"
            :label-value="'Street name'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
          v-if="staticData"
            v-model:value="newAddressPayload.unitName"
            :pre-val="newAddressPayload.unitName"
            :label-value="'Unit name'"
            :label-animation="true"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <div style="display: flex; gap: 0.5rem">
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.state"
                :pre-val="newAddressPayload.state"
                :options="preferredList"
                :label-value="'State'"
                prop-value="value"
                :placeholder-value="'State'"
                :label-animation="true"
                :required="true"
                :clearable="true"
              ></hlx-input>
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.country"
                :pre-val="newAddressPayload.country"
                :options="preferredList"
                prop-value="value"
                :label-value="'Country'"
                :placeholder-value="'Country'"
                :required="true"
                :label-animation="true"
                :clearable="true"
              ></hlx-input>
            </div>
          </div>
          <hlx-input
            v-model:value="newAddressPayload.landmark"
            :pre-val="newAddressPayload.landmark"
            :label-value="'Nearby landmark'"
            :label-animation="true"
          ></hlx-input>
        </span>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeAddNewAdrressModal"
        >
        <!-- Cancel -->
        <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        :disabled="
          newAddressPayload.city === '' ||
          newAddressPayload.country === '' ||
          newAddressPayload.state === '' ||
          newAddressPayload.postalCode === '' ||
          newAddressPayload.streetNumber === '' ||
          newAddressPayload.streetName === ''
        "
        @click="addNewAddress"
        >
        <!-- Add -->
        <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">
        <!-- Mark as -->
        <span v-if="staticData">{{ staticData?.markAs?.label[loginUserDetails]?.label }}</span>
         {{ contactStatus }}</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          <!-- Are you sure you want to mark  -->
          <span v-if="staticData">{{ staticData?.cmContactModalMessage1?.label[loginUserDetails]?.label }}</span>
          <b>{{ delete_name }}</b>
           <!-- as -->
           <span v-if="staticData">{{ staticData?.as?.label[loginUserDetails]?.label }}</span>
          <b>{{ contactStatus }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >
        <!-- Cancel -->
        <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteContactRow"
        >
        <!-- Mark as  -->
        <span v-if="staticData">{{ staticData?.markAs?.label[loginUserDetails]?.label }}</span>
        {{ contactStatus }}</hlx-button
      >
    </template>
  </hlx-modal>
  <div v-if="fullTotal === 0" class="cm-accounts-overview-component">
    <img
      src="@/assets/images/addAccountContacts.svg"
      alt=""
      style="width: 400px"
    />
    <hlx-button
      v-if="mode === 'edit'"
      class="primary sm empty-table-add-button"
      @click="showNewContactModal"
    >
      <i class="icon-plus-circle-regular"></i>
      <!-- New contact -->
      <span v-if="staticData">{{ staticData?.newContact?.label[loginUserDetails]?.label }}</span>
    </hlx-button>
  </div>

  <div v-if="fullTotal > 0">
    <span style="display: flex; margin-bottom: 12px; margin-top: 20px">
      <b>
         <!-- {{ "Contacts" }} -->
         <span v-if="staticData">{{ staticData?.cmContactsTitle?.label[loginUserDetails]?.label }}</span>
        </b>
    </span>
    <div
      class="cm-accounts-right-panel-top-section-utility-container"
      style="
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        margin-bottom: 12px;
        margin-top: 12px;
      "
    >
      <div style="display: flex; align-items: center; font-weight: bold">
        <hlx-switch
        v-if="staticData"
          v-model="selectedSwitch"
          :switch-items="switchOptions"
          value-prop="value"
          display-prop="label"
          @change="chosenSwitch"
        />
      </div>
      <div style="display: flex; gap: 16px">
        <span class="search-container" style="width: 250px">
          <hlx-search
          :label-value=staticData?.search?.label[loginUserDetails]?.label
           @search-key="contactSearch"></hlx-search>
        </span>
        <span v-if="currentTotal > 0">
          <hlx-button
            v-if="mode === 'edit'"
            class="primary sm"
            @click="showNewContactModal"
          >
            <i class="icon-plus-circle-regular"></i>
             <!-- New contact -->
             <span v-if="staticData">{{ staticData?.newContact?.label[loginUserDetails]?.label }}</span>
          </hlx-button>
        </span>
      </div>
    </div>
  </div>
  <div
    v-if="currentTotal > 0"
    class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
  >
    <hlx-table
      :label-value="tableModalLang"
      :column-count="accounts_table_theads.length + 2"
      :border="['table', 'header', 'horizontal']"
      :row-hover="true"
      theme="grey"
      :re-arrange-columns="toggleRearrangeModal"
      :re-arrange-heads="accounts_table_theads_cc"
      @close-rearrange="toggleRearrangeModal = false"
      @apply-rearranged-columns="applyColumns"
    >
      <template #thead>
        <hlx-table-head
          :width="60"
          :align="'center'"
          style="padding: 0 15px"
          :fixed="'left'"
          @new-resize-width="resizedWidth"
          >
          <!-- {{ "S.no" }} -->
          <span v-if="staticData">{{ staticData?.sNo?.label[loginUserDetails]?.label }}</span>
          </hlx-table-head
        >
        <hlx-table-head
          v-for="(i, index) in accounts_table_theads"
          :key="index"
          :prop="i.prop"
          :sortable="i.sortable"
          :resizable="i.resizable"
          :width="i.width !== undefined ? i.width : ''"
          :align="i.align !== undefined ? i.align : ''"
          @sorting_func="sorting_Data"
          @new-resize-width="resizedWidth"
        >
          {{ i.label }}</hlx-table-head
        >
        <hlx-table-head
          v-if="mode === 'edit'"
          :align="'center'"
          :width="70"
          :fixed="'right'"
          @new-resize-width="resizedWidth"
          ><span class="action-icon">
            <i class="icon-settings-regular" style="cursor: pointer"  @click="reAssignOrgTableHeader()"></i> </span
        ></hlx-table-head>
        <!-- @click="arrange(true)" -->
      </template>
      <template #tbody>
        <tr v-for="(i, index) in paginatedData" id="" :key="index">
          <hlx-table-cell :align="'center'" :fixed="'left'">
            {{ serialNumber(index) }}
          </hlx-table-cell>
          <hlx-table-cell
            v-for="(j, col_index) in accounts_table_theads"
            :key="col_index"
            :align="j.align"
            :fixed="j.fixed"
          >
            <div
              v-if="j.prop.toLowerCase() === 'firstname'"
              :id="j.prop + index"
              style="
                display: flex;
                flex-direction: column;
                /* gap: 4px; */
                height: 50px;
                justify-content: space-evenly;
              "
              @click="goToContacts(i)"
            >
              <span class="link-name" style="color: #2879b7; cursor: pointer">{{
                i[j.prop] + " " + i["lastName"]
              }}</span>
              <span style="font-size: 12px">
                <i class="icon-briefcase-regular"></i>
                {{ i["aristocraticTitle"] }}</span
              >
            </div>
            <div
              v-else-if="j.prop.toLowerCase() === 'birthdate'"
              :id="j.prop + index"
            >
              {{ convertdateformat(i[j.prop]) }}
            </div>
            <div
              v-else-if="j.prop.toLowerCase() === 'address'"
              :id="j.prop + index"
              style="
                margin: 10px 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              :style="{
                width: j.width + 'px',
              }"
              @mouseover="
                tooltipover(j.prop + index, 'tooltip-text' + j.prop + index)
              "
              @mouseleave="
                tooltipleave(j.prop + index, 'tooltip-text' + j.prop + index)
              "
            >
              {{
                i["contactMedium"][2].characteristic["description"] !== "" &&
                i["contactMedium"][2].characteristic["description"] !==
                  undefined
                  ? i["contactMedium"][2].characteristic["description"]
                  : "-"
              }}
              <p id="tooltip-text" :class="'tooltip-text' + j.prop + index">
                {{
                  i["contactMedium"][2].characteristic["description"] !== "" &&
                  i["contactMedium"][2].characteristic["description"] !==
                    undefined
                    ? i["contactMedium"][2].characteristic["description"]
                    : "-"
                }}
              </p>
            </div>
            <!-- <div
              v-else-if="j.prop.toLowerCase() === 'notes'"
              :id="j.prop + index"
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              :style="{
                width: j.width + 'px',
              }"
              @mouseover="
                tooltipover(j.prop + index, 'tooltip-text' + j.prop + index)
              "
              @mouseleave="
                tooltipleave(j.prop + index, 'tooltip-text' + j.prop + index)
              "
            >
              {{ i[j.prop] }}
              <p
                id="tooltip-text"
                :class="'tooltip-text' + j.prop + index"
              >
                {{ i[j.prop] }}
              </p>
            </div> -->
            <div
              v-else-if="j.prop.toLowerCase() === 'linkedin'"
              :id="j.prop + index"
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              :style="{
                width: j.width + 'px',
              }"
              @mouseover="
                tooltipover(j.prop + index, 'tooltip-text' + j.prop + index)
              "
              @mouseleave="
                tooltipleave(j.prop + index, 'tooltip-text' + j.prop + index)
              "
            >
              {{ i["externalReference"][0].name }}
              <p id="tooltip-text" :class="'tooltip-text' + j.prop + index">
                {{ i["externalReference"][0].name }}
              </p>
            </div>
            <div v-else-if="j.prop.toLowerCase() === 'aristocraticTitle'">
              {{ i[j.prop] }}
            </div>
            <div v-else-if="j.prop.toLowerCase() === 'status'">
              <div>
                <hlx-status :type="i[j.prop]"></hlx-status>
              </div>
            </div>
            <div
              v-else-if="j.prop.toLowerCase() === 'contactmedium'"
              style="margin: 0.5rem 0"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                  margin-bottom: 4px;
                "
              >
                <i class="icon-envelope-regular"></i>
                {{ i[j.prop][0].characteristic?.emailAddress }}
              </div>
              <div
                v-if="
                  i[j.prop][1].characteristic?.phoneNumber.value !== '' &&
                  i[j.prop][1].characteristic?.phoneNumber.value !== undefined
                "
                style="display: flex; align-items: center; gap: 0.5rem"
              >
                <i class="icon-phone-regular"></i>
                {{ i[j.prop][1].characteristic?.phoneNumber.value }}
              </div>
            </div>
            <div v-else>{{ i[j.prop] }}</div>
          </hlx-table-cell>
          <hlx-table-cell
            v-if="mode === 'edit'"
            :align="'center'"
            :width="120"
            :fixed="'right'"
          >
            <span :id="'table-context' + index" class="action-icon">
              <i
                style="position: relative"
                class="icon-more-vertical-filled"
                :class="i.context === true ? 'active-action' : ''"
                @click="crudContextMenu($event, i, index)"
              >
                <hlx-context-menu
                v-if="staticData"
                  :top="containertop"
                  :left="containerleft"
                  :display="'display'"
                  :conditionvalue="150"
                  :conditiondown="2"
                  :conditionup="-38"
                  :options="computedTableAction(i)"
                  :data="index.toString()"
                  :show="i.context == true"
                  @chosen="closeCrudContextMenu($event, i, index)"
                />
              </i>
            </span>
          </hlx-table-cell>
        </tr>
      </template>
    </hlx-table>
  </div>
  <div v-if="currentTotal === 0">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 70vh;
      "
    >
      <img src="@/assets/images/supplierActiveContact.svg" alt="" />
      <span v-if="selectedSwitch != 'inactive'">
        <hlx-button
          v-if="mode === 'edit'"
          class="primary sm empty-table-add-button"
          @click="showNewContactModal"
        >
          <i class="icon-plus-circle-regular"></i>
          <!-- New contact -->
          <span v-if="staticData">{{ staticData?.newContact?.label[loginUserDetails]?.label }}</span>
        </hlx-button></span
      >
    </div>
  </div>
  <!-- drawer -->

  <div class="add-contact-drawer">
    <hlx-drawer
      :show="showContactModal"
      position="right"
      width="400"
      :footer="true"
      :title="'Add contact'"
      :show-close-icon="true"
      @close="closeAddContactModal"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <b>
            <!-- Add contact -->
            <span v-if="staticData">{{ staticData?.addContact?.label[loginUserDetails]?.label }}</span>
          </b>
        </div>
        <div class="body-area-contact-drawer">
          <hlx-input
          v-if="staticData"
            v-model:value="payload.firstName"
            :pre-val="payload.firstName"
            :label-value="'First name'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isFirstNameEmpty"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
          v-if="staticData"
            v-model:value="payload.lastName"
            :pre-val="payload.lastName"
            :label-value="'Last name'"
            :label-animation="true"
            :required="false"
            :display-error="true"
            :custom-error="isLastNameEmpty"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper" style="margin-bottom: 20px">
            <!-- <hlx-select
              :key="key"
              v-model:value="payload.status"
              :pre-value="payload.status"
              :options="statusList"
              prop-value="name"
              :placeholder-value="'status'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select> -->

            <div style="width: 100%">
              <hlx-status
                :type="'select-status'"
                :status-option="filteredstatus"
                :prevalue="payload.status"
                :readonly="true"
                @emit-select="emitStatus1"
              >
              </hlx-status>
            </div>
          </div>
          <!-- <hlx-input
            v-model:value="payload.contactMedium[2].description"
            :pre-val="payload.contactMedium[2].description"
            type="location"
            :params="locationApi"
            :label-value="'Address'"
            :label-animation="true"
            :show-new-address-option="showNewAddressOption"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            :location-data-prop="locationData"
            @focus-out="focusOutName"
            @at-input="getLocations"
            @add-new-location="addNewLocation"
          ></hlx-input> -->

          <addressComponent
            :pre-val="addressPreVal"
            @address-data="receivedAddressData"
          ></addressComponent>
          <div
            class="input-wrapper"
            style="margin-top: 20px; margin-bottom: 20px"
          >
            <hlx-select
              :key="key"
              v-model:value="payload.aristocraticTitle"
              :pre-value="payload.aristocraticTitle"
              :options="designationList"
              :required="true"
              prop-value="name"
              :placeholder-value="'Designation'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="input-wrapper" style="margin-bottom: 20px">
            <hlx-select
              :key="key"
              v-model:value="payload.preferred"
              :pre-value="payload.preferred"
              :options="preferredList"
              prop-value="value"
              :placeholder-value="'Preferred contact medium'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <hlx-input
          v-if="staticData"
            v-model:value="payload.contactMedium[0].characteristic.emailAddress"
               :default-mail-error="staticData?.pleaseEnterValidUrl?.label[loginUserDetails]?.label"
            :pre-val="payload.contactMedium[0].characteristic.emailAddress"
            :label-value="'Email id'"
            :label-animation="true"
            type="email"
            :required="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <!-- </div> -->
          <!-- <hlx-input
            v-model:value="payload.contactMedium[1].phoneNumber"
            :pre-val="payload.contactMedium[1].phoneNumber"
            type="number"
            :label-value="'Phone number'"
            :label-animation="true"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input> -->
          <hlx-input
          v-if="staticData"
            v-model:value="payload.contactMedium[1].characteristic.phoneNumber"
          :default-number-error="staticData?.pleaseEnterValidNumber?.label[loginUserDetails]?.label"
            :pre-val="payload.contactMedium[1].characteristic.phoneNumber.value"
            type="phonenumber"
            :country-code="
              payload.contactMedium[1].characteristic.phoneNumber.country
            "
            :label-value="'Phone no.'"
            :label-animation="true"
            :display-error="true"
            :custom-error="customnameerror"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="payload.externalReference[0].name"
            :pre-val="payload.externalReference[0].name"
            :label-value="'Linkedin id'"
            :label-animation="true"
            :display-error="true"
          ></hlx-input>
          <!-- <hlx-input
            :key="dobKey"
            v-model:value="payload.birthDate"
            :pre-val="payload.birthDate"
            :label-value="'Date of birth'"
            :label-animation="true"
            type="date"
            icon="calendar-regular"
            icon-position="right"
            :display-error="true"
          ></hlx-input> -->

          <!-- <hlx-input
            v-model:value="payload.notes"
            :pre-val="payload.notes"
            :label-value="'Notes'"
            :label-animation="true"
            type="textarea"
            :rows="4"
            :display-error="false"
          ></hlx-input> -->
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeAddContactModal"
            >
            <!-- Cancel -->
            <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >

          <hlx-button
            :disabled="!canAdd"
            class="primary sm"
            style="margin-left: 10px"
            @click="addContactRow"
            >
            <!-- Add -->
            <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <div class="add-contact-drawer">
    <hlx-drawer
      :show="showEditContactModal"
      position="right"
      width="400"
      :footer="true"
      :title="'Add contact'"
      :show-close-icon="true"
      @close="closeEditContactModal"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <b>
            <!-- Edit contact -->
            <span v-if="staticData">{{ staticData?.editContact?.label[loginUserDetails]?.label }}</span>
          </b>
        </div>
        <div class="body-area-contact-drawer">
          <hlx-input
          v-if="staticData"
            v-model:value="editPayload.firstName"
            :pre-val="editPayload.firstName"
            :label-value="'First name'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isFirstNameEmpty"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
          v-if="staticData"
            v-model:value="editPayload.lastName"
            :pre-val="editPayload.lastName"
            :label-value="'Last name'"
            :label-animation="true"
            :required="false"
            :display-error="true"
            :custom-error="isLastNameEmpty"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper" style="margin-bottom: 20px">
            <!-- <hlx-select
              :key="key"
              v-model:value="editPayload.status"
              :pre-value="editPayload.status"
              :options="statusList"
              prop-value="name"
              :placeholder-value="'Status'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select> -->

            <div style="width: 100%">
              <hlx-status
                :type="'select-status'"
                :status-option="filteredstatus"
                :prevalue="editPayload.status"
                @emit-select="emitStatus1"
              >
              </hlx-status>
            </div>
          </div>
          <!-- <hlx-input
            v-model:value="editPayload.contactMedium[2].description"
            :pre-val="editPayload.contactMedium[2].description"
            type="location"
            :params="locationApi"
            :label-value="'Address'"
            :label-animation="true"
            :show-new-address-option="showNewAddressOption"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            :location-data-prop="locationData"
            @focus-out="focusOutName"
            @at-input="getLocations"
            @add-new-location="addNewLocation"
          ></hlx-input> -->
          <addressComponent
            :pre-val="editAddressPreval"
            @address-data="receivedAddressData"
          ></addressComponent>
          <div
            class="input-wrapper"
            style="margin-top: 20px; margin-bottom: 20px"
          >
            <hlx-select
              :key="key"
              v-model:value="editPayload.aristocraticTitle"
              :pre-value="editPayload.aristocraticTitle"
              :options="designationList"
              :required="true"
              prop-value="name"
              :placeholder-value="'Designation'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="input-wrapper" style="margin-bottom: 20px">
            <hlx-select
              :key="key"
              v-model:value="editPayload.preferred"
              :pre-value="editPayload.preferred"
              :options="preferredList"
              prop-value="value"
              :placeholder-value="'Preferred contact medium'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <hlx-input
          v-if="staticData"
            v-model:value="
              editPayload.contactMedium[0].characteristic.emailAddress
            "
            :pre-val="editPayload.contactMedium[0].characteristic.emailAddress"
            :label-value="'Email id'"
            :label-animation="true"
            type="email"
            :required="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <!-- </div> -->
          <!-- <hlx-input
            v-model:value="editPayload.contactMedium[1].phoneNumber"
            :pre-val="editPayload.contactMedium[1].phoneNumber"
            type="number"
            :label-value="'Phone number'"
            :label-animation="true"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input> -->
          <hlx-input
          v-if="staticData"
            v-model:value="
              editPayload.contactMedium[1].characteristic.phoneNumber
            "
            :pre-val="
              editPayload.contactMedium[1].characteristic.phoneNumber.value
            "
            type="phonenumber"
            :country-code="
              editPayload.contactMedium[1].characteristic.phoneNumber.country
            "
            :label-value="'Phone no.'"
            :label-animation="true"
            :display-error="true"
            :custom-error="customnameerror"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="editPayload.externalReference[0].name"
            :pre-val="editPayload.externalReference[0].name"
            :label-value="'Linkedin id'"
            :label-animation="true"
            :display-error="true"
          ></hlx-input>
          <!-- <hlx-input
            :key="dobKey"
            v-model:value="editPayload.birthDate"
            :pre-val="editPayload.birthDate"
            :label-value="'Date of birth'"
            :label-animation="true"
            type="date"
            icon="calendar-regular"
            icon-position="right"
            :display-error="true"
          ></hlx-input> -->

          <!-- <hlx-input
            v-model:value="editPayload.notes"
            :pre-val="editPayload.notes"
            :label-value="'Notes'"
            :label-animation="true"
            type="textarea"
            :rows="4"
            :display-error="false"
          ></hlx-input> -->
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeEditContactModal"
            >
            <!-- Cancel -->
            <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
          <hlx-button
            class="primary sm"
            :disabled="!canAdd"
            style="margin-left: 10px"
            @click="editContactRow"
            >
            <!-- Save -->
            <span v-if="staticData">{{ staticData?.save?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
</template>

<script>
import addressComponent from "../../components/CM/autocompleteComponent.vue";

import {
  DMNAxiosInstance,
  UPCAxiosInstance,
  MLTAxiosInstance,
} from "@/config/axiosConfig";

export default {
  components: {
    addressComponent,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: "edit",
    },
    currentTotal: {
      type: Number,
      default: 0,
    },
    fullTotal: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    "contact-data",
    "new-contact",
    "edit-contact",
    "delete-contact",
    "initalpayload",
    "contactSwitchVal",
  ],
  data() {
    return {
      loginUserDetails: {},
      staticData:null,
      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      filteredstatus: [],
      contactStatus: "",
      showViewContactModal: false,
      showAddNewAdrressModal: false,
      delete_name: "",
      delete_index: "",
      delete_modal_active: false,
      dobKey: 0,
      isTitleEmpty: false,
      isFirstNameEmpty: false,
      isLastNameEmpty: false,
      isEmailEmpty: false,
      isAddButtonDisabled: false,
      tableData: [],
      showContactModal: false,
      showEditContactModal: false,
      payload: {
        name: "",
        birthDate: "",
        rootName: "",
        firstName: "",
        lastName: "",
        title: "",
        tradingName: "",
        companyName: "",
        status: "Pending",
        preferred: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        aristocraticTitle: "",
        role: "",
        type: "individual",
        notes: [],
        password: "",
        email: "",
        attachment: [],
      },
      selectedSwitch: "all",
      switchOptions: [
        {
          name: "All",
          value: "all",
        },
        {
          name: "Active",
          value: "active",
        },
        {
          name: "Pending",
          value: "pending",
        },
        {
          name: "Inactive",
          value: "inactive",
        },
      ],
      editPayload: {
        name: "",
        birthDate: "",
        rootName: "",
        firstName: "",
        lastName: "",
        title: "",
        tradingName: "",
        companyName: "",
        status: "Pending",
        preferred: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        aristocraticTitle: "",
        role: "",
        type: "individual",
        notes: [],
        password: "",
        email: "",
        attachment: [],
      },
      rows: 5,
      titleList: [
        { name: "Mr.", value: "Mr." },
        { name: "Mrs.", value: "Mrs." },
      ],
      designationList: [],
      statusList: [],
      preferredList: [
        { name: "Email", value: "email" },
        { name: "Phone number", value: "phone" },
      ],
      key: 0,
      instanceAddress: {},
      accounts_table_theads: [
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          name:"Name",
          label: "Name",
          prop: "firstName",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 400,
          name:"Address",
          label: "Address",
          prop: "address",
          align: "left",
        },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 170,
        //   label: 'Role',
        //   prop: 'role',
        //   align: 'left',
        // },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 100,
          name:"Status",
          label: "Status",
          prop: "status",
          disabled:true,
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 250,
          name:"Contact medium",
          disabled:true,
          label: "Contact medium",
          prop: "contactMedium",
          align: "left",
        },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 250,
        //   label: 'Preferred contact medium',
        //   prop: 'preferred',
        //   align: 'left',
        // },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 150,
        //   label: 'Linkedin Id',
        //   prop: 'linkedin',
        //   align: 'left',
        // },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 150,
        //   label: 'D.O.B',
        //   prop: 'birthDate',
        //   align: 'left',
        // },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 300,
        //   label: 'Notes',
        //   prop: 'notes',
        //   align: 'left',
        // },
      ],
      accounts_table_theads_cc: [
      {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          name:"Name",
          label: "Name",
          prop: "firstName",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 400,
          name:"Address",
          label: "Address",
          prop: "address",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 100,
          name:"Status",
          disabled:true,
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 250,
          disabled:true,
          name:"Contact medium",
          label: "Contact medium",
          prop: "contactMedium",
          align: "left",
        },
      ],
      duplicateTheads: [],
      toggleRearrangeModal: false,
      currPage: 1,
      rowsPerPage: 10,
      editMode: true,
      copyOfContactTableData: [],
      locationApi: "https://appbackend-ghubgwxrcq-pd.a.run.app/autocomplete",
      locationData: [],
      newAddressPayload: {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      },
      showNewAddressOption: false,
      addressPreVal: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      },
      editAddressPreval: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      },
      canAdd: false,
      apiKey: "",
    };
  },
  computed: {
    tableModalLang() {
      return {
        re_arrange_columns: this.matchFromStatic("Re-arrange columns"),
        Cancel: this.matchFromStatic("Cancel"),
        Apply: this.matchFromStatic("Apply"),
        SelectAll: this.matchFromStatic("Select all"),
      };
    },
    paginatedData() {
      return this.copyOfContactTableData;
    },
  },
  watch: {
    staticData: {
      handler(val) {
        val
        if (val) {
          val
          this.switchOptions = [
        {
          name: "All",
          value: "all",
          label: this.staticData?.all?.label[this.loginUserDetails]?.label   
        },
        {
          name: "Active",
          value: "active",
          label: this.staticData?.active?.label[this.loginUserDetails]?.label   
        },
        {
          name: "Pending",
          value: "pending",
          label: this.staticData?.pending?.label[this.loginUserDetails]?.label   
        },
        {
          name: "Inactive",
          value: "inactive",
          label: this.staticData?.inactive?.label[this.loginUserDetails]?.label   
        },
      ]
         
        }
      },
      immediate: true,
      deep: true,
    },
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList.filter(
          (item) =>
            item.name === "Active" ||
            item.name === "Inactive" ||
            item.name === "Pending"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    data: {
      handler(val) {
        if (val.length > 0) {
          console.log(val);
          const x = JSON.parse(JSON.stringify(val));
          x.forEach((item) => {
            if (item.role !== undefined && item.role[0] !== undefined) {
              item.role = item?.role[0]?.name;
            }
          });
          this.tableData = x;
        } else {
          this.tableData = [];
        }
      },
      immediate: true,
      deep: true,
    },
    payload: {
      handler(val) {
        console.log(val, "ghjikol;");
        val["@baseType"] = "customer";
        val.email = val.contactMedium[0].characteristic.emailAddress;
        if (val.title !== "") {
          this.isTitleEmpty = false;
        }
        if (val.firstName !== "") {
          this.isFirstNameEmpty = false;
        }
        // if (val.lastName !== '') {
        //   this.isLastNameEmpty = false;
        // }
        if (val.email !== "") {
          this.isEmailEmpty = false;
        }
        for (let i = 0; i < val.contactMedium.length; i++) {
          if (val.contactMedium[i].mediumType === val.preferred) {
            val.contactMedium[i].preferred = true;
          } else {
            val.contactMedium[i].preferred = false;
          }
        }
        console.log(
          val.firstName != "",
          this.validateEmail(val.contactMedium[0].characteristic.emailAddress),
          val.aristocraticTitle != "",
          this.validatePhoneNumber(
            val.contactMedium[1].characteristic.phoneNumber
          ),
          "ph valid 9"
        );
        if (
          //check the mandatory field
          val.firstName != "" &&
          this.validateEmail(
            val.contactMedium[0].characteristic.emailAddress
          ) &&
          val.aristocraticTitle != "" &&
          this.validatePhoneNumber(
            val.contactMedium[1].characteristic.phoneNumber
          )
        ) {
          this.canAdd = true;
        } else {
          this.canAdd = false;
        }
      },
      immediate: true,
      deep: true,
    },
    editPayload: {
      handler(val) {
        val.email = val.contactMedium[0].characteristic.emailAddress;
        if (val.title !== "") {
          this.isTitleEmpty = false;
        }
        if (val.firstName !== "") {
          this.isFirstNameEmpty = false;
        }
        // if (val.lastName !== '') {
        //   this.isLastNameEmpty = false;
        // }
        if (val.email !== "") {
          this.isEmailEmpty = false;
        }
        for (let i = 0; i < val.contactMedium.length; i++) {
          if (val.contactMedium[i].mediumType === val.preferred) {
            val.contactMedium[i].preferred = true;
          } else {
            val.contactMedium[i].preferred = false;
          }
        }
        if (
          //check the mandatory field
          val.firstName != "" &&
          this.validateEmail(
            val.contactMedium[0].characteristic.emailAddress
          ) &&
          val.aristocraticTitle != "" &&
          this.validatePhoneNumber(
            val.contactMedium[1].characteristic.phoneNumber
          )
        ) {
          this.canAdd = true;
        } else {
          this.canAdd = false;
        }
      },
      immediate: true,
      deep: true,
    },
    tableData: {
      handler(val) {
        this.copyOfContactTableData = JSON.parse(JSON.stringify(val));
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("loginUserDetails");
    // loginUserDetails
    // await this.$store.dispatch("loginTenantDetails");
    // let r = await this.$store.getters.loginTenantDetails;
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
    console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);

    this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;

    // this.loginUserDetails.languageAbility[0].name
    // this.loginUserDetails.languageAbility[0].code

    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
      console.log("this.staticData:",organisationInfo , this.staticData);
      
    }
    catch (error) {
      console.error("error::", error)
    }


    
    // const x = await DMNAxiosInstance.get('mapRef/collection/Designation');
    // x;

    // Get table header
    let accountAddContactTableHeads = await MLTAxiosInstance.get(`/settings`);

if (accountAddContactTableHeads?.data?.entityRef?.["accountAddContact"]) {
  let accountTableHeadModal = accountAddContactTableHeads.data.entityRef["accountAddContact"];
  let accountTableHeadsOrg = accountAddContactTableHeads.data.entityRef["accountAddContact"].filter(ele => ele.checked);
  console.log(accountTableHeadsOrg,"accountTableHeadModal",accountTableHeadModal)

  if (
    accountTableHeadModal.length === this.accounts_table_theads_cc.length 
// &&
//     accountTableHeadModal.every((item, index) => item.name === this.accounts_table_theads_cc[index].name)
  ) {
    console.log(accountTableHeadsOrg,"accountTableHeadModal dup",accountTableHeadModal)
    this.accounts_table_theads_cc = JSON.parse(JSON.stringify(accountTableHeadModal));
      this.accounts_table_theads = JSON.parse(JSON.stringify(accountTableHeadsOrg));
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
  }
}


    let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList = statusList.data.data;
    // let designationList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmContactsDesignation`
    // );
    this.designationList =  await this.getReferanceData('cmContactsDesignation') 
    // console.log(x);
    this.$emit("contact-data", this.tableData);
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.user = val.data;
    this.company = val.data.company;
    this.userId = val.data.userId;

    const instance = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${val.data.userId}?company=${val.data.company}`
    );
    this.instanceData = instance.data;
    this.instanceAddress = this.instanceData.contactMedium.find(
      (item) => item.mediumType === "postalAddress"
    );

    console.log(
      this.instanceData,
      this.instanceAddress.characteristic.description,
      "this.instanceData"
    );
    this.payload.parentCompany = val.data.company;
    let apiKeyRes = await MLTAxiosInstance.get(
      `partymanagement/individual/getAPIKey`
    );

    if (apiKeyRes.data.ok == 0) {
      return;
    }
    this.apiKey = apiKeyRes.data.data;
  },
  methods: {
    reAssignOrgTableHeader(){
           // If there is change in the table header then ressign to the Orginal data
           if((this.accounts_table_theads_cc != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.accounts_table_theads_cc = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.accounts_table_theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.accounts_table_theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.toggleRearrangeModal = true
    },
    resizedWidth(val){
      // To change resized table header
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.accounts_table_theads);
  updateWidth(this.accounts_table_theads_cc);
console.log(this.accounts_table_theads,"resizedWidth loop",this.accounts_table_theads_cc)
this.applyColumns(this.accounts_table_theads_cc)
    },
    chosenSwitch(val) {
      this.selectedSwitch = val.value;
      this.$emit("contactSwitchVal", val.value);
    },
    validatePhoneNumber(val) {
      console.log(val, "ph valid");
      return val.valid;
    },
    sanitizeDatabaseName(databaseName) {
      // Replace dots with an underscore or any other symbol you want
      const sanitized = databaseName?.replace(/\./g, "_");

      // Validate the constraints for MongoDB database names
      const validDatabaseName = sanitized?.replace(/[^a-zA-Z0-9_]/g, "");

      return validDatabaseName;
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      }
    },
    emitStatus(val1) {
      this.payload.status = val1["value"];
    },
    emitStatus1(val1) {
      this.editPayload.status = val1["value"];
    },
    receivedAddressData(val) {
      let address1 = JSON.parse(JSON.stringify(val));
      console.log("ass2", val, address1);
      // let address2 = JSON.parse(JSON.stringify(val))
      this.payload.contactMedium[2] = JSON.parse(JSON.stringify(address1));
      if (val.characteristic.country !== "") {
        this.payload.contactMedium[1].characteristic.phoneNumber
        .country.name = val.characteristic.country
        this.payload.contactMedium[1].characteristic.phoneNumber
        .country.flags = ""
        this.payload.contactMedium[1].characteristic.phoneNumber
        .country.short = ""
      }
      if (this.showEditContactModal) {
        this.editPayload.contactMedium[2] = JSON.parse(
          JSON.stringify(address1)
        );
        if (val.characteristic.country !== "") {
        this.editPayload.contactMedium[1].characteristic.phoneNumber
        .country.name = val.characteristic.country
        this.editPayload.contactMedium[1].characteristic.phoneNumber
        .country.flags = ""
        this.editPayload.contactMedium[1].characteristic.phoneNumber
        .country.short = ""
      }
      }
      // console.log(address1, this.payload.contactMedium[2], 'address');
      // address2.mediumType = 'billingLocation'
      // this.payload.contactMedium.push(address2);
    },
    goToContacts(i) {
      // showViewContactModal = true;
      //           showEditContactModal = true;
      // (editPayload = JSON.parse(JSON.stringify(i))),
      //   (editPayload.index = index);
      console.log(i, this.$route.params);
      this.$router.push(
        `/CM/contacts/view/${i.company}/${i._id}/${this.$route.params.type}/accounts?companyId=${this.$route.params.id}`
      );
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      console.log(data, index);
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    addNewLocation(val) {
      // console.log(val);
      val;
      this.showAddNewAdrressModal = true;
      this.showContactModal = false;
      this.showNewAddressOption = false;
    },
    closeAddNewAdrressModal() {
      this.showAddNewAdrressModal = false;
      this.showContactModal = true;
    },
    async getLocations() {
      var input = this.payload.contactMedium[2].description;
      if (this.showEditContactModal) {
        input = this.editPayload.contactMedium[2].description;
      }
      let result = [];

      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await UPCAxiosInstance.get(
          `/suggestionlist/?input=${input}`
        ); // console.log(response);
        result = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      this.locationData = result;
      if (this.locationData.length === 0 && input !== "") {
        this.showNewAddressOption = true;
      } else {
        this.showNewAddressOption = false;
      }
      return result;
    },
    async getEditLocations() {
      const input = this.editPayload.contactMedium[2].description;
      let result = [];

      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await UPCAxiosInstance.get(
          `/suggestionlist/?input=${input}`
        ); // console.log(response);
        result = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.locationData = result;
      if (this.locationData.length === 0 && input !== "") {
        this.showNewAddressOption = true;
      } else {
        this.showNewAddressOption = false;
      }
      return result;
    },
    async addNewAddress() {
      this.newAddressPayload.description =
        this.newAddressPayload.streetNumber +
        ", " +
        this.newAddressPayload.streetName +
        ", " +
        this.newAddressPayload.city +
        ", " +
        this.newAddressPayload.state +
        ", " +
        this.newAddressPayload.country +
        ", " +
        this.newAddressPayload.postalCode +
        ".";
      // console.log(this.newAddressPayload);
      let newAddress = await DMNAxiosInstance.post(
        `mapRef/geoAddress`,
        this.newAddressPayload
      );
      if (newAddress.data.ok === 1) {
        // console.log(this.newAddressPayload.description);
      }
      this.payload.contactMedium[2].description =
        this.newAddressPayload.description;
      this.showAddNewAdrressModal = false;
      this.showContactModal = true;
      const x = {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      };
      this.newAddressPayload = JSON.parse(JSON.stringify(x));
    },
    contactSearch(val) {
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.tableData,
          val.toLowerCase()
        );
        // console.log(searchData, this.tableData);
        this.copyOfContactTableData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.copyOfContactTableData = JSON.parse(
          JSON.stringify(this.tableData)
        );
      }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            // Recursively search nested objects
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    deleteContactRow() {
      let row = this.tableData[this.delete_index];
      if (row.status === "Active") {
        row.status = "Inactive";
      } else {
        row.status = "Active";
      }
      this.$emit("edit-contact", row);
      // this.tableData.splice(this.delete_index, 1);
      this.$emit("contact-data", this.tableData);
      this.delete_modal_active = false;
    },
    crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        if (this.computedTableAction(i)[0]?.label === "Mark as Active") {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 143;
        } else if (
          this.computedTableAction(i)[1]?.label === "Mark as In-active"
        ) {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 160;
        } else {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 120;
        }
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        // this.$router.push(`/CM/accounts/edit/${ele._id}`);
        this.editPayload = JSON.parse(JSON.stringify(this.tableData[i]));
        this.$emit("initalpayload", this.tableData[i]);
        this.editAddressPreval = this.editPayload.contactMedium[2];
        this.editPayload.index = i;
        this.showEditContactModal = true;
      } else if (
        $event.toLowerCase() === "mark as active" ||
        $event.toLowerCase() === "mark as in-active"
      ) {
        if (ele.status === "Active") {
          this.contactStatus = "In-active";
        } else {
          this.contactStatus = "Active";
        }
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.delete_index = i;
      } else if ($event.toLowerCase() === "send invite") {
        console.log(i);
        this.inviteUser(ele);
      }
    },
    async inviteUser(i) {
      console.log(i, "mail send request");
      if (i != undefined) {
        this.notification = {
          type: "success",
          message:  this.staticData?.cmContactNotiMessage1?.label[this.loginUserDetails]?.label || "User invitation email sent",
        };

        this.sendEmail(i);
      }
    },
    async sendEmail(i) {
      console.log(i, "sendEmail");
      const prod = process.env.VUE_APP_BASE_URL;
      prod;
      const dev = "http://localhost:8080";
      dev;

      let vbnm = 2;
      let tokenPayload = {
        user: {
          email: i.email,
          company: i.company,
        },
        validTo: vbnm + "h",
        additionalFields: {
          supplierLogin: true,
        },
      };

      let generatedToken = await MLTAxiosInstance.post(
        "/partymanagement/individual/generateToken",
        tokenPayload
      );
      generatedToken = generatedToken.data;

      console.log(generatedToken, "mail send sucessfully");
      let mail = {
        to: [i.email],
        subject: `${i.company} user Invitation`,
        template: `
      <!--
 -->

<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
    <link href='https://fonts.googleapis.com/css?family=Open+Sans' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <style type="text/css" media="screen">
        body {
            padding: 0 !important;
            margin: 0 !important;
            display: flex !important;
            justify-content: center !important;
            min-width: 100% !important;
            width: 100% !important;
            background: #f5f5f5;
            -webkit-text-size-adjust: none;
        }
        .mobile-shell {
            width: 100%;
        }
        .td {
            width: 100%;
        }
        .wrapper {
            width: 100%;
            table-layout: fixed;
        }
        .p35-15 {
            padding: 35px 15px;
        }
        .headline {
            text-align: center;
        }
        .hero-title-1 {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            padding-top: 15px;
            line-height: 28px;
            text-align: center;
            color: #191A15;
        }
        .hero-content {
            padding-top: 25px;
            padding-left: 100px;
            padding-right: 100px;
            color: black;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 25px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .btn {
            text-align: center;
            padding-top: 25px;
        }
        .btn a {
            background-color: #54BD95;
            border-radius: 6px;
            color: #ffffff;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            text-align: center;
            text-decoration: none;
            width: 218.18px;
            height: 38px;
            -webkit-text-size-adjust: none;
            padding: 10px 12px;
            line-height: 15px;
        }
        .disclaimer2 {
            padding: 40px 77px;
            color: #FFFFFF;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .footer-link a {
            color: #FFFFFF;
            font-size: 14px;
        }
        .address {
            padding-left: 17px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            color: #FFFFFF;
            margin-top: 15px;
            margin-bottom: 0px;
            letter-spacing: .01em;
            line-height: 20px;
        }
        .social-icons {
            float: right;
            margin-right: -50px;
            padding-top: 20px;
        }
        .social-icons a {
            padding-left: 5px;
            padding-right: 5px;
            color: #FFFFFF;
        }
    </style>
</head>

<body class="body" style="
 display: grid;
    justify-items: center;
    background-color: #f5f5f5;
">
    <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
    <table width="612px" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5 " style="margin: 0 auto">
        <tr>
            <td align="center" valign="top">
                <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                    <tr>
                        <td class="td">
                            <table border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td  height: 501px;">
                                        <!-- Header -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="wrapper">
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="10" cellspacing="0" width="77%" class="wrapper" bgcolor="#f5f5f5">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="wrapper" bgcolor="#ffffff">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Header -->
                                        <!-- Start Content -->
                                        <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501" cellpadding="0">
                                            <tr>
                                                <td bgcolor="#FFFFFF" class="p35-15">
                                                    <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <!-- headline -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="headline">
                                                                <img style="width: 15%; object-fit: contain; clip-path: circle();" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtteDflN13M27CDlAPdC7L_fNJ4Eus3fEFBkI8JKxFLmcms3TsIv_EUcvuyjVo46v1k4&usqp=CAU" alt="">
                                                            </td>
                                                        </tr>
                                                        <!-- #headline -->
                                                        <!-- hero-title-1 -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1">
                                                                ${
                                                                  this.company
                                                                }<br>invited you
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-content">
                                                                You have been invited by ${
                                                                  this.company
                                                                }  to join  <a href="mailto:contact@halleyx.com" style="text-decoration: none; color:#54BD95">${
          this.$route.params.companyName
        }</a>. To get started, accept the invitation below.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="btn">
                                                                <a href=${prod}/supplierinvite/${
          this.$route.params.companyName
        }/${i._id}/${this.$cookies.get(
          "token"
        )}?login=Userinvite&Password=${'NewPassword'} class="mob-btn" target="_blank">Accept invitation</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF"  class="hero-content"  style="color:#a6a6a6">
                                                                Hiccups might happen. Your feedback and requests are very welcomed and encouraged at <a href="mailto:contact@halleyx.com" style="color:#54BD95">contact@halleyx.com</a>. We can't wait to see what you create with ${
                                                                  this.company
                                                                }.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- HERO Images-->
                                    </td>
                                </tr>
                            </table>
                            <!-- END Content -->
                            <!-- Footer -->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding: 0 15px;" bgcolor="#ffffff">
                                <tr>
                                    <td bgcolor="#161C2B" class="disclaimer2">
                                        <span class="footer-link"><a href="https://halleyx.com">T&C</a><a style="padding-left:40px; padding-right: 40px;" href="https://halleyx.com">Privacy</a><a href="https://halleyx.com">Cookies</a></span><br/>
                                        <br/>
                                        © ${this.currentYear} ${
          this.company
        }. Copyright and rights reserved
                                        <p class="address">${
                                          this.instanceAddress.characteristic
                                            .description
                                        }</p>
                                        <span style="float:left; margin-left: -60px; padding-top:20px;"><img width="100" src=""/></span>
                                        <span class="social-icons"><a href="https://www.linkedin.com/company/halleyx/"><i class="fa fa-linkedin fa-lg"></i></a>
                                            <a href="https://halleyx.com/"><i class="fa fa-facebook fa-lg"></i></a>
                                            <a href="https://www.instagram.com/halleyx_inc/"><i class="fa fa-instagram fa-lg"></i></a></span>
                                    </td>
                                </tr>
                            </table>
                            <!-- END Footer -->
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    <img src="images?q=tbn:ANd9GcRYx7vG1ermb_muIP457oVO48JixkaC0RvhAA&usqp=CAU" width="1" height="1">
</body>

</html>`,
      };
      // const sendEmail =
      await MLTAxiosInstance.post(`/email`, mail);
    },
    computedTableAction(data) {
      if (data.status && data.status.toLowerCase() == "active") {
        return [
          {
            display:this.staticData?.edit?.label[this.loginUserDetails]?.label,
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            display:this.staticData?.markAsInactive?.label[this.loginUserDetails]?.label,
            label: "Mark as In-active",
            icon: "icon-times-square-regular",
          },
        ];
      } else if (data.status && data.status.toLowerCase() == "pending") {
        return [
          {
            display:this.staticData?.sendInvite?.label[this.loginUserDetails]?.label,
            label: "Send invite",
            icon: "icon-send-right-regular",
          },
          {
            display:this.staticData?.edit?.label[this.loginUserDetails]?.label,
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      } else if (data.status && data.status.toLowerCase() == "inactive") {
        return [
          {
            display:this.staticData?.markAsActive?.label[this.loginUserDetails]?.label,
            label: "Mark as Active",
            icon: "icon-check-square-regular",
          },
          {
            display:this.staticData?.delete?.label[this.loginUserDetails]?.label,
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }
      // let options = [
      //   {
      //     label: "Edit",
      //     icon: "icon-file-text-edit-regular",
      //   },
      //   {
      //     label: "Mark as In-active",
      //     icon: "icon-trash-regular",
      //   },
      //   {
      //     label: "Mark as Active",
      //     icon: "icon-trash-regular",
      //   },
      // ];
      // return options.filter((option) => {
      //   if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "active" &&
      //     ["Mark as Active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "inactive" &&
      //     ["Mark as In-active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.toggleRearrangeModal = val;
      // this.updateTheads();
    },
    arrangeColumns(val) {
      this.toggleRearrangeModal = val;
      // this.updateTheads();
    },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
   async applyColumns(val) {
// Func to rearrange table header
console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef || {};
concatedEntity.accountAddContact = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.accounts_table_theads_cc = entityRef["accountAddContact"]
this.accounts_table_theads =this.accounts_table_theads_cc.filter(ele=>ele.checked)
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
    },
    addContactRow() {
      if (this.payload.firstName === "") {
        this.isFirstNameEmpty = true;
      }
      // if (this.payload.lastName === '') {
      //   this.isLastNameEmpty = true;
      // }
      if (this.payload.contactMedium[0].emailAddress === "") {
        this.isEmailEmpty = true;
      }
      if (
        // !this.isTitleEmpty &&
        !this.isFirstNameEmpty &&
        // !this.isLastNameEmpty &&
        !this.isEmailEmpty
      ) {
        this.payload.name =
          this.payload.title +
          "" +
          this.payload.firstName +
          " " +
          this.payload.lastName;
          
        // console.log(this.payload);
        this.$emit("new-contact", { ...this.payload });
        this.tableData.push({ ...this.payload });
        const x = {
          name: "",
          birthDate: "",
          rootName: "",
          firstName: "",
          lastName: "",
          title: "",
          tradingName: "",
          companyName: "",
          status: "Pending",
          preferred: "",
          partyCharacteristic: [
            {
              name: "number of employees",
              value: "",
            },
            {
              name: "market segment",
              value: "",
            },
          ],
          contactMedium: [
            {
              preferred: true,
              mediumType: "email",
              characteristic: {
                emailAddress: "",
              },
            },
            {
              mediumType: "phone",
              characteristic: {
                phoneNumber: {
                  value: "",
                  valid: true,
                  country: {
                    name: this.$store.getters.defaultSetting?.country,
                    short: this.$store.getters.defaultSetting?.cca2,
                    flags: this.$store.getters.defaultSetting?.flags?.svg,
                  },
                },
              },
            },
            {
              mediumType: "postalAddress",
              characteristic: {
                city: "",
                country: "",
                postCode: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                timezone: "",
                description: "",
                "@type": "",
              },
            },
          ],
          externalReference: [
            {
              name: "",
              externalIdentifierType: "linkedIn",
            },
          ],
          relatedParty: [],
          tags: ["Active"],
          aristocraticTitle: "",
          role: "",
          type: "individual",
          notes: [],
          password: "",
          email: "",
          attachment: [],
        };
        this.payload = JSON.parse(JSON.stringify(x));
        // this.isTitleEmpty = false;
        this.isFirstNameEmpty = false;
        this.isLastNameEmpty = false;
        this.isEmailEmpty = false;
        this.dobKey += 1;
        this.$emit("contact-data", this.tableData);
        this.closeAddContactModal();
      }
    },
    editContactRow() {
      if (this.editPayload.firstName === "") {
        this.isFirstNameEmpty = true;
      }
      if (this.editPayload.lastName === "") {
        this.isLastNameEmpty = true;
      }
      if (this.editPayload.contactMedium[0].emailAddress === "") {
        this.isEmailEmpty = true;
      }
      if (
        // !this.isTitleEmpty &&
        !this.isFirstNameEmpty &&
        !this.isLastNameEmpty &&
        !this.isEmailEmpty
      ) {
        this.editPayload.name =
          this.editPayload.title +
          "" +
          this.editPayload.firstName +
          " " +
          this.editPayload.lastName;
        this.editPayload.familyName = 
          this.editPayload.name;
        this.editPayload.givenName = 
          this.editPayload.name;
        // console.log(this.editPayload);
        // this.tableData.push({...this.editPayload});
        console.log(this.editPayload.index, this.editPayload);
        this.$emit(
          "edit-contact",
          JSON.parse(JSON.stringify(this.editPayload))
        );
        this.tableData[this.editPayload.index] = JSON.parse(
          JSON.stringify(this.editPayload)
        );
        const x = {
          name: "",
          birthDate: "",
          rootName: "",
          firstName: "",
          lastName: "",
          title: "",
          tradingName: "",
          companyName: "",
          status: "Pending",
          preferred: "",
          partyCharacteristic: [
            {
              name: "number of employees",
              value: "",
            },
            {
              name: "market segment",
              value: "",
            },
          ],
          contactMedium: [
            {
              preferred: true,
              mediumType: "email",
              characteristic: {
                emailAddress: "",
              },
            },
            {
              mediumType: "phone",
              characteristic: {
                phoneNumber: {
                  value: "",
                  valid: true,
                  country: {
                    name: this.$store.getters.defaultSetting?.country,
                    short: this.$store.getters.defaultSetting?.cca2,
                    flags: this.$store.getters.defaultSetting?.flags?.svg,
                  },
                },
              },
            },
            {
              mediumType: "postalAddress",
              characteristic: {
                city: "",
                country: "",
                postCode: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                timezone: "",
                description: "",
                "@type": "",
              },
            },
          ],
          externalReference: [
            {
              name: "",
              externalIdentifierType: "linkedIn",
            },
          ],
          relatedParty: [],
          tags: ["Active"],
          aristocraticTitle: "",
          role: "",
          type: "individual",
          notes: [],
          password: "",
          email: "",
          attachment: [],
        };
        this.editPayload = JSON.parse(JSON.stringify(x));
        this.isFirstNameEmpty = false;
        this.isLastNameEmpty = false;
        this.isEmailEmpty = false;
        this.dobKey += 1;
        this.$emit("contact-data", this.tableData);
        this.closeEditContactModal();
      }
      this.tableData?.forEach((e) => {
        if (e.context !== undefined) {
          e.context = false;
        }
      });
    },
    showNewContactModal() {
      const x = {
        name: "",
        birthDate: "",
        rootName: "",
        firstName: "",
        lastName: "",
        title: "",
        tradingName: "",
        companyName: "",
        status: "Pending",
        preferred: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        aristocraticTitle: "",
        role: "",
        type: "individual",
        notes: [],
        password: "",
        email: "",
        attachment: [],
      };
      this.payload = JSON.parse(JSON.stringify(x));
      this.isFirstNameEmpty = false;
      this.isLastNameEmpty = false;
      this.isEmailEmpty = false;

      this.showContactModal = true;
      this.addressPreVal = this.payload.contactMedium[2];
      this.key += 1;
    },
    closeAddContactModal() {
      this.key += 1;
      this.showContactModal = false;
    },
    closeEditContactModal() {
      this.key += 1;
      this.showViewContactModal = false;
      const x = {
        name: "",
        birthDate: "",
        rootName: "",
        firstName: "",
        lastName: "",
        title: "",
        tradingName: "",
        companyName: "",
        status: "Pending",
        preferred: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        aristocraticTitle: "",
        role: "",
        type: "individual",
        notes: [],
        password: "",
        email: "",
        attachment: [],
      };
      this.editPayload = JSON.parse(JSON.stringify(x));
      this.showEditContactModal = false;
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = objectDate.toLocaleString("default", { month: "short" });
          let year = objectDate.getFullYear();
          let dateformat = month + " " + date + ", " + year;
          return dateformat;
        }
      }
    },
  },
};
</script>
