<template>
  <discardComponent
    :show="showOverviewDiscardModal"
    @close="showOverviewDiscardModal = false"
    @stay="handleStay"
    @discard="handleDiscard"
  >
  </discardComponent>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'200px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">
        <!-- Delete data -->
        {{ matchFromStatic('Delete data') }}
      </div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          <!-- Are you sure you want to delete -->
          {{ matchFromStatic('Are you sure you want to delete') }}
          <b>{{
            deleteObj?.name
              ? deleteObj?.name
              : deleteObj?.firstName + ' ' + deleteObj?.lastName
          }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="delete_modal_active = false"
      >
        <!-- Cancel -->
        {{ matchFromStatic('Cancel') }}
      </hlx-button>
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteContactRow"
      >
        <!-- Delete -->
        {{ matchFromStatic('Delete') }}
      </hlx-button>
    </template>
  </hlx-modal>
  <section style="display: flex">
    <section class="lead-overview-left-panel responsiveSM">
      <div class="layout-breadcrumb">
        <hlx-breadcrumb
          :items="getBreadcrumbs"
          :label-value="loadStaticData('LANG-0019')"
          @path="goTo()"
        />
        <!-- <hlx-breadcrumb
          v-if="view === false"
          :items="getBreadcrumbs"
          @path="goTo()"
        /> -->
      </div>
      <span style="margin-top: 25px">
        <leftPanel
          :side-bar-data="leftPanelData"
          @value-name="selectedValueFunc"
        ></leftPanel>
      </span>
    </section>
    <section
      class="--layout-inner-right-portion --RL70 responsiveSM-right-portion"
      style="padding: 1.8em 35px; width: calc(100% - 220px); padding-left: 0px"
    >
      <!-- :style="
          isRightLayoutOpened === false
            ? 'width:calc(100% - 220px)'
            : 'width:100%'
        " -->
      <div class="decision-info-right-pannel">
        <div class="layout-breadcrumb expanded-side-panel">
          <hlx-breadcrumb
            v-if="view === true"
            :label-value="loadStaticData('LANG-0019')"
            :items="getBreadcrumbs"
            @path="goTo()"
          />
          <hlx-breadcrumb
            v-if="view === false"
            :label-value="loadStaticData('LANG-0019')"
            :items="getBreadcrumbs"
            @path="goTo()"
          />
        </div>
        <div
          class="body-container-center"
          style="height: 100%"
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span
              class="expanded-side-panel"
              style="margin-right: 25px"
            >
              <leftPanel
                :side-bar-data="leftPanelData"
                @value-name="selectedValueFunc"
              ></leftPanel>
            </span>
            <div
              style="display: flex; align-items: center; gap: 15px;"
            >
            <!-- width: 60% -->
              <span>
                <hlx-file-upload
                  :key="profileComponentKey"
                  :file-type="'.jpg,.jpeg,.png'"
                  :file-size-limit="'20mb'"
                  :uploadtype="'Rectangle'"
                  :style-background="backgrounStyle"
                  :height="50"
                  :width="50"
                  :read-only="true"
                  :name-display="leadData.name"
                  :custom-icon="icon"
                  :type="'profile-upload'"
                  :profile-pic-preview="profilePicUrl"
                  :content="'Import'"
                  @native-data="profileUpload"
                />
              </span>

              <div
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  font-family: 'Quicksand';
                "
              >
                <div style="display: flex; align-items: center; gap: 12px">
                  <span style="font-weight: bold; width: max-content">
                    {{ leadData.name ? leadData.name : '-' }}
                  </span>
                  <hlx-label
                    v-if="leadData.status"
                    class="sm"
                    :class="
                      leadData.status === 'Closed lost' ? 'error' : 'primary'
                    "
                  >
                    {{ matchFromStatic(leadData?.status) }}
                  </hlx-label>
                </div>
                <div
                  style="color: #a6a6a6; font-size: 12px; width: max-content"
                >
                  {{ leadData?.description }}
                </div>
              </div>
            </div>

            <div
              v-if="view && selectedValue.toLowerCase() === 'overview'"
              class="go-back"
              style="
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <hlx-button
                v-show="accountStatus == false"
                class="primary sm"
                :disabled="leadData?.status?.toLowerCase() == 'closed lost' || leadData?.status == 'Converted' "
                style="margin-left: 20px"
                @click="view = false"
              >
                <!-- Edit -->
                {{ loadStaticData('LANG-0009') }}
              </hlx-button>
            </div>
            <div
              v-if="view && selectedValue.toLowerCase() === 'overview'"
              class="go-back"
              style="
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <!-- Account link -->
              <!-- <hlx-button class="secondary sm" @click="cancelEditAccount"
                >
                {{ loadStaticData("LANG-0029") }}
                </hlx-button
            > -->
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'overview'"
              style="display: flex"
            >
              <!-- <div class="button-container-lead-overview">
                <hlx-button
                  class="secondary sm"
                 style="display: flex; align-items: center; gap: 0.5rem"
                  @click="showDropdown = !showDropdown"
                  >
                  Edit 
                  {{ "Actions" }}
                  <i 
                  v-if = "showDropdown"
                  style="margin-right: 5px"
                  class="icon-angle-up-regular"
                        ></i>
                        <i 
                  v-if = "!showDropdown"
                  style="margin-right: 5px"
                  class="icon-angle-down-regular"
                        ></i>
                  </hlx-button
                >
                <div v-if="showDropdown" class="lead-overview-dropdown">
                <div v-if="leadData.status !== 'Completed'" class="dropdown-item" style="margin-bottom:6px">
                  <i class="icon-user-settings-regular"></i>
                  Convert to account
                </div>

                <div v-if="leadData.status === 'Completed'" class="dropdown-item" style="margin-bottom:6px">
                  <i class="icon-user-settings-regular"></i>
                  Account link
                </div>

                Always show Create quote 
                <div class="dropdown-item">
                  <i class="icon-file-text-regular"></i>
                  Create quote
                </div>
              </div>
              </div> -->

              <hlx-button
                v-show="permisstionData.edit"
                v-if="!view"
                :disabled="leadData?.status?.toLowerCase() == 'closed lost'"
                class="secondary sm"
                style="margin-left: 20px"
                @click="
                  leadData?.status?.toLowerCase() !== 'converted'
                    ? saveEditedLead('converted')
                    : pushToAccountOverviewPage()
                "
              >
                <!-- Edit -->
                {{
                  leadData?.status?.toLowerCase() != 'converted'
                    ? matchFromStatic('Create quote')
                    : matchFromStatic('Account link')
                }}
              </hlx-button>
              <hlx-button
                v-show="permisstionData.edit"
                v-if="!view"
                :disabled="leadData?.status?.toLowerCase() == 'closed lost' || leadData?.status == 'Converted' "
                class="primary sm"
                style="margin-left: 20px"
                @click="view = true"
              >
                <!-- Edit -->
                {{ loadStaticData('LANG-0009') }}
              </hlx-button>
              <hlx-button
                v-show="permisstionData.edit"
                v-if="view && selectedValue.toLowerCase() === 'overview'"
                class="secondary sm"
                style="margin-left: 20px"
                @click="view = false"
              >
                <!-- Save -->
                {{ matchFromStatic('Cancel') }}
              </hlx-button>
              <hlx-button
                v-show="permisstionData.edit"
                v-if="view && selectedValue.toLowerCase() === 'overview'"
                class="primary sm"
                style="margin-left: 20px"
                @click="saveEditedLead"
              >
                <!-- Save -->
                {{ loadStaticData('LANG-0030') }}
              </hlx-button>
            </div>
          </div>
          <div>
            <div
              v-if="selectedValue.toLowerCase() === 'overview'"
              class="parent-container-for-lead-overview"
              style="position: relative"
            >
              <fieldRenderer
                :schema-payload="filteredCustomFieldSchema"
                :data-payload="schemaProp"
                :complete-payload="completePayload"
                :is-save-clicked="isSaveButtonClicked"
                :edit-mode="ViewMode"
                :read-only="customFieldsReadOnly"
                :status-list="[
                  'Open',
                  'Network approved',
                  // 'In Discussion',
                  'Qualified',
                  'Network rejected',
                  'Unqualified',
                  'Awaiting network approval',
                  'Converted',
                  'Closed Lost',
                ]"
                :parent-class-name="'parent-container-for-lead-overview'"
                @edited-payload="patchEditedLead"
                @profile-buffer-data="getProfileImageBufferData"
                @profile-name="getProfileImageName"
                @lookup-data="getExistedLeadData"
              ></fieldRenderer>
            </div>
          </div>
          <!-- <div
            v-if="selectedValue.toLowerCase() === 'overview'"
            class="lead-flow-component-cover"
            style="display: none; gap: 5%; padding-top: 20px"
          >
            <div
              class="decision-info-left-pannel"
              style="width: 50%; gap: 20px"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  gap: 10px;
                  margin-bottom: 24px;
                "
              >
                <span>
                  <hlx-file-upload
                    :key="profileComponentKey"
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Rectangle'"
                    :style-background="backgrounStyle"
                    :height="93"
                    :width="93"
                    :read-only="view"
                    :custom-icon="icon"
                    :profile-pic-preview="profilePicUrl"
                    :type="'profile-upload'"
                    :content="'Import'"
                    @native-data="profileUpload"
                    @delete-image="deleteProfilePicture"
                  />
                </span>
                <span style="width: 100%">
                  <div
                    class="decision-name-wrapper"
                    :style="{
                      'margin-bottom': customnameerror ? '-7.6%' : 0,
                    }"
                    style="margin-bottom: 20px"
                  >
                    <hlx-input
                      v-model:value="leadData.name"
                      :pre-val="leadData.name"
                      :label-value="'Company name'"
                      :label-animation="true"
                      :required="true"
                      :display-error="true"
                      :custom-error="isExistingName"
                      :custom-error-message="isExistingNameErrorMessage"
                      @focus-out="validateNameNew"
                      @at-input="validateName"
                    ></hlx-input>
                  </div>
                  <div class="decision-name-wrapper">
                    <div style="width: 100%">
                      <hlx-status
                        :type="'select-status'"
                        :prevalue="leadData.status"
                        :status-option="filteredstatus"
                        @emit-select="leadStatus"
                      >
                      </hlx-status>
                    </div>
                  </div>
                </span>
              </div>
              <section class="left-section-select">
                <div>
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.leadSource?.name"
                    :inline-search="true"
                    :options="leadSourceOptions"
                    :required="true"
                    :slot-options="false"
                    :placeholder-value="'Lead source'"
                    :prop-value="'name'"
                    :label="'name'"
                    :label-animation="true"
                    :display-error="true"
                    :clearable="true"
                    @selected-value="getLeadSource"
                  ></hlx-select>
                </div>
                <div>
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.leadType?.name"
                    :inline-search="true"
                    :options="leadTypeOptions"
                    :required="true"
                    :slot-options="false"
                    :placeholder-value="'Lead type'"
                    :prop-value="'name'"
                    :display-error="true"
                    :label="'name'"
                    :label-animation="true"
                    :clearable="true"
                    @selected-value="getLeadType"
                  ></hlx-select>
                </div>
                <div>
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.dwellingtype"
                    :inline-search="true"
                    :display-error="true"
                    :options="dwellingTypeOptions"
                    :required="true"
                    :slot-options="false"
                    :placeholder-value="'Dwelling type'"
                    :prop-value="'name'"
                    :label="'name'"
                    :label-animation="true"
                    :clearable="true"
                    @selected-value="getLeadDwellingType"
                  ></hlx-select>
                </div>
                <div>
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.category?.name"
                    :inline-search="true"
                    :options="leadCategoryOptions"
                    :required="true"
                    :display-error="true"
                    :slot-options="false"
                    :placeholder-value="'Category'"
                    :prop-value="'name'"
                    :label="'name'"
                    :label-animation="true"
                    :clearable="true"
                    @selected-value="getLeadCategory"
                  ></hlx-select>
                </div>
                <div>
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.marketSegment?.name"
                    :inline-search="true"
                    :options="leadMarketSegmentOptions"
                    :required="true"
                    :slot-options="false"
                    :placeholder-value="'Market segment'"
                    :prop-value="'name'"
                    :label="'name'"
                    :label-animation="true"
                    :display-error="true"
                    :clearable="true"
                    @selected-value="getLeadMarketSegment"
                  ></hlx-select>
                </div>
                <div>
                  <hlx-input
                    v-model:value="leadData.onboardingDate"
                    :pre-val="leadData.onboardingDate"
                    :label-animation="true"
                    :single-border="false"
                    :label-value="'Onboarding date'"
                    :date-format="'dd (short month), yyyy'"
                    type="date"
                    :clearable="false"
                    icon="calendar-regular"
                    icon-position="right"
                    required="true"
                    :disable-weekend="false"
                    :start-year="2000"
                    :display-error="true"
                    :custom-error="startDateBoolean"
                    :custom-error-message="startDateMsg"
                    :end-year="9999"
                    @iso-date="getLeadOnboardingDate"
                    @at-input="getLeadOnboardingDate"
                    @focus-out="focusOutStartDate"
                  />
                </div>
              </section>
            </div>
            <span
              class="right-section"
              style="width: 50%"
            >
              <div style="margin-bottom: 12px">
                <hlx-input
                  v-model:value="leadData.description"
                  :pre-val="leadData.description"
                  type="textarea"
                  :label-value="'Description'"
                  :label-animation="true"
                  :rows="8"
                  :required="false"
                  :display-error="false"
                  :custom-error="customnameerror"
                  :custom-error-message="''"
                  @focus-out="focusOutName"
                ></hlx-input>
              </div>
              <div style="display: flex">
                <div style="width: 94%">
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.leadOwner?.name"
                    :inline-search="true"
                    :options="leadOwnerOptions"
                    :required="true"
                    :placeholder-value="'Lead owner'"
                    :prop-value="'name'"
                    :label="'name'"
                    :label-animation="true"
                    :clearable="true"
                    :display-error="true"
                    @selected-value="getLeadOwner"
                  ></hlx-select>
                </div>
                <div style="margin: auto; padding-bottom: 24px">
                  <i
                    style="color: #a6a6a6"
                    class="icon-info-circle-regular"
                  ></i>
                </div>
              </div>
              <div style="display: flex">
                <div style="width: 94%">
                  <hlx-input
                    :key="drawerKey"
                    v-model:value="leadData.numberOfSites"
                    :pre-val="leadData.numberOfSites"
                    type="number"
                    :label-value="'Number of sites'"
                    :label-animation="true"
                    :required="true"
                    :custom-error="customnameerror"
                    :display-error="true"
                    :custom-error-message="'Please fill the field'"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                </div>
                <div style="margin: auto; padding-bottom: 24px">
                  <i
                    style="color: #a6a6a6"
                    class="icon-info-circle-regular"
                  ></i>
                </div>
              </div>
              <div style="display: flex">
                <div style="width: 94%">
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.dealCategory?.name"
                    :inline-search="true"
                    :options="leadDealCategoryOptions"
                    :required="true"
                    :slot-options="false"
                    :placeholder-value="'Deal category'"
                    :prop-value="'name'"
                    :label="'name'"
                    :display-error="true"
                    :label-animation="true"
                    :clearable="true"
                    @selected-value="getLeadDealCategory"
                  ></hlx-select>
                </div>
                <div style="margin: auto; padding-bottom: 24px">
                  <i
                    style="color: #a6a6a6"
                    class="icon-info-circle-regular"
                  ></i>
                </div>
              </div>
              <div>
                <hlx-input
                  v-model:value="leadData.websiteUrl"
                  :pre-val="leadData?.websiteUrl"
                  :label-value="'Website URL'"
                  type="text"
                  :prefix="true"
                  :label-animation="true"
                  :display-error="true"
                  :custom-error="isUrlEmpty"
                  :custom-error-message="'Please enter a valid URL in the format: www.abc.com'"
                  @focus-out="websiteRegex"
                  @at-input="validateName"
                >
                </hlx-input>
              </div>
              <div>
                <hlx-multi-select
                  :key="componentKey"
                  :options="tagsOptions"
                  :pre-value="leadData.tags"
                  :placeholder="'Tags'"
                  :label-animation="true"
                  :prop-value="'name'"
                  :label="'name'"
                  :disabled="false"
                  :filter-select="false"
                  :tooltipposition="'left'"
                  @add-data="addTagList"
                  @custom-change="receivedTags"
                ></hlx-multi-select>
              </div>
            </span>
            <overViewComponent></overViewComponent> 
          </div> -->
          <div
            v-if="selectedValue.toLowerCase() === 'hierarchy'"
            class="lead-flow-component-cover"
          >
            <hierarchyComponent
              :account-status="accountStatus"
              :permisstion-data="permisstionData"
              :data="hierarchyData"
              module="CM"
              @updated-hierarchy="updatedHierarchy"
            ></hierarchyComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'contacts'"
            class=""
          >
            <leadContact
              :trading-name="leadData?.accountRef"
              :permisstion-data="permisstionData"
              :schema-for-contact-modal="filteredCustomFieldSchema"
              :schema-for-drawer="filteredCustomFieldSchemaForDrawer"
              :data-for-contact-modal="schemaPropForContacts"
              :is-save-clicked="isSaveButtonClicked"
              :payload="leadData"
              :lead-status="leadData?.status"
              :contact-data="contactsTableData"
              :full-total="fullTotal"
              :current-total="currentTotal"
              @contact-switch-val="contactSwitchVal"
              @new-contact="addNewContact"
              @edit-contact="editContactData"
              @initalpayload="initalpayload"
              @delete-contact="deleteContactData"
              @updated-payload="patchUpdatedPayloadForContact"
              @updated-schema="getContactUpdatedSchema"
            ></leadContact>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'interaction'"
            class="lead-flow-component-cover"
            style="justify-content: center !important"
          >
            <interactionComponent
              :account-status="accountStatus"
              :data="interactionData"
              :contact-data="contactTableData"
              @initial-payload="initialPayloadInt"
              @new-interaction-payload="receivedInteractionPayload"
              @delete-interaction-index="deleteInteractionPayload"
              @delete-interaction-data="deleteinteraction"
              @edit-interaction="editInteraction"
            ></interactionComponent>
          </div>
          <div v-if="selectedValue.toLowerCase() === 'orders'">
            <leadOrderComponent
              :permisstion-data="permisstionData"
              :account-status="accountStatus"
              @create-quote="handleQuoteCreation"
            ></leadOrderComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'sites'"
            class=""
          >
            <leadSite
              :trading-name="leadData?.displayID"
              :permisstion-data="permisstionData"
              :schema-for-site-modal="filteredCustomFieldSchema"
              :schema-for-drawer="filteredCustomFieldSchemaForDrawer"
              :data-for-site-modal="schemaPropForContacts"
              :is-save-clicked="isSaveButtonClicked"
              :payload="leadData"
              :lead-status="leadData?.status"
              :site-data="sitesTableData"
              :full-total="fullTotal"
              :current-total="currentTotal"
              @contact-switch-val="contactSwitchVal"
              @new-contact="contactData"
              @edit-contact="editContactData"
              @initalpayload="initalpayload"
              @drawer-open="schemaForDrawer"
              @delete-site="deleteSiteData"
              @updated-payload="patchUpdatedPayloadForSite"
              @add-payload="addNewSitePayload"
            ></leadSite>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'billing'"
            class=""
          >
            <templateComponent
              :account-status="accountStatus"
            ></templateComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'products'"
            class=""
          >
            <templateComponent
              :account-status="accountStatus"
              :module="'Products'"
            ></templateComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'contracts'"
            class=""
          >
            <templateComponent
              :account-status="accountStatus"
              :module="'Contracts'"
            ></templateComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'documents'"
            class=""
          >
            <attachmentsComponent
              :show-display-id="'true'"
              :permisstion-data="permisstionData"
              :supplier-status="accountStatus"
              :user-details="leadDetails"
              :data="attachmentData"
              :disabled="leadData?.status?.toLowerCase() === 'closed lost' || leadData?.status === 'Converted'"
              module="LEAD"
              @upload-data="receivedUploadData"
              @delete-index="deleteAttachmentRow"
              @activity-search="getSearchedDataForActivityLog"
            ></attachmentsComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'notes'"
            class=""
          >
            <noteComponent
              :permisstion-data="permisstionData"
              :supplier-status="ViewMode"
              :data="notesTableData"
              :disabled="leadData?.status?.toLowerCase() === 'closed lost' || leadData?.status === 'Converted'"
              @added-note="addNote"
              @delete-index="deleteNote"
              @edit-note="editNote"
            ></noteComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'tickets'"
            class=""
          >
            <TicketsComponent
              :module="'accounts'"
              :company="$route.params.companyName"
            ></TicketsComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'location'"
            class=""
          >
            <locationComponent
              :account-status="accountStatus"
              :data="payload.contactMedium"
              @new-address="receivedNewAddress"
              @update-address="receivedUpdateAddress"
              @initial-payload="initialPayloadLocation"
              @delete-index="deleteAddress"
            ></locationComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'activity log'"
            class=""
          >
            <ActivityLogComponent
              :id="$route.params.id"
              :activity-data="logData"
              :totalfromprop="activitytotal"
              :category="'lead'"
              @activityrowpage="activityrowpagefunc"
              @reload-data="activityLogGetAllData"
              @activitycurrentpage="activitycurrentpagefunc"
              @activity-search="getSearchedDataForActivityLog"
            ></ActivityLogComponent>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>

import ActivityLogComponent from './activityLogComponentV2.vue';
import leadSite from './leadSite.vue';
import leftPanel from '@/components/leftpannelComponent.vue';
import leadContact from './leadContact.vue';
import hierarchyComponent from '@/components/CM/addAccountHierarchyComponent.vue';
import interactionComponent from '@/components/CM/addAccountInteractionComponent.vue';
import leadOrderComponent from './leadOrder.vue';
import attachmentsComponent from '@/components/CM/addAccountAttachmentsComponent.vue';
import noteComponent from '@/components/CM/addAccountNotesComponent.vue';
import TicketsComponent from '@/components/CM/addAccountTicketsComponent.vue';
import locationComponent from '@/components/CM/addAccountLocationsComponent.vue';
import fieldRenderer from '@/components/FieldRenderer.vue';
import discardComponent from '@/components/discardComponent.vue';
import {
  DMNAxiosInstance,
  UPCAxiosInstance,
  MLTAxiosInstance,
} from '@/config/axiosConfig';
export default {
  components: {
    ActivityLogComponent,
    leftPanel,
    fieldRenderer,
    leadContact,
    leadSite,
    discardComponent,
    TicketsComponent,
    // overViewComponent,
    hierarchyComponent,
    interactionComponent,
    leadOrderComponent,
    attachmentsComponent,
    noteComponent,
    locationComponent,
  },
  beforeRouteLeave(to, from, next) {
    if (this.discardIt) {
      //this.discardIt will handled on discard modal window discard changes emit
      next(true);
      return;
    }
    const dupSchemaProp = { ...this.schemaProp };
    delete dupSchemaProp.updatedAt;
    delete dupSchemaProp.createdAt;
    delete dupSchemaProp.lastUpdate;
    dupSchemaProp?.attachment?.length <= 0
      ? delete dupSchemaProp.attachment
      : '';
    if (
      this.temporaryLeadOverviewPayload &&
      this.schemaProp &&
      this.selectedValue.toLowerCase() == 'overview'
    ) {
      let removeUnwantedData = this.temporaryLeadOverviewPayload;
      removeUnwantedData.relatedParty = removeUnwantedData.relatedParty.filter(
        (party) =>
          party['@type'] === 'leadOwner' || party['@type'] === 'leadSource'
      );
      delete removeUnwantedData.updatedAt;
      delete removeUnwantedData.createdAt;
      delete removeUnwantedData.lastUpdate;
      removeUnwantedData?.attachment?.length <= 0
        ? delete removeUnwantedData.attachment
        : '';

      const leadSourceObjectRemoveUnwanted =
        removeUnwantedData?.externalReference?.find(
          (item) => item.type === 'leadSource'
        );
      const leadSourceObjectdupSchemaProp =
        dupSchemaProp?.externalReference?.find(
          (item) => item.type === 'leadSource'
        );
      const leadSourceValueRemove = leadSourceObjectRemoveUnwanted
        ? leadSourceObjectRemoveUnwanted.value
        : null;
      const leadSourceValueDup = leadSourceObjectdupSchemaProp
        ? leadSourceObjectdupSchemaProp.value
        : null;
      if (
        (dupSchemaProp.metadata == '' || removeUnwantedData.metadata == '') &&
        leadSourceValueRemove?.toLowerCase() != 'sap' &&
        leadSourceValueDup?.toLowerCase() != 'sap'
      ) {
        delete removeUnwantedData.metadata;
        delete dupSchemaProp.metadata;
      }
      if (removeUnwantedData['leadType']) {
        delete removeUnwantedData['leadType'];
      }
      if (Object.prototype.hasOwnProperty.call(dupSchemaProp, 'leadType')) {
        delete dupSchemaProp.leadType;
      }

      if (leadSourceValueRemove?.toLowerCase() == 'sap') {
        console.log('leadSourceValueRemove', leadSourceValueRemove);
        // removeUnwantedData.websiteUrl ?
        delete removeUnwantedData?.websiteUrl;
        //  : ''
        // removeUnwantedData.profileUpload ?
        delete removeUnwantedData?.profileUpload;
        //  : ''
        // dupSchemaProp.websiteUrl ?
        delete dupSchemaProp?.websiteUrl;
        //  : ''
        // dupSchemaProp.profileUpload ?
        delete dupSchemaProp?.profileUpload;
        // : ''
      }
      console.log(removeUnwantedData, 'dupSchemaProp', dupSchemaProp);
      const unsavedChanges = this.isPayloadChanged(
        dupSchemaProp,
        removeUnwantedData
      );

      console.log(
        this.dupliactePayload,
        this.payload,
        'unsavedChanges',
        unsavedChanges
      );
      if (unsavedChanges) {
        this.showOverviewDiscardModal = true;
      }
      next(!unsavedChanges);
      return;
    } else {
      next(true);
      return;
    }
  },
  // vue add data
  data() {
    return {
      completePayload:{},
      newAccountIsCreated:false,
      selectedLeadName:null,
      selectedLeadAccountRef:null,
      dataPayloadForRemap:null,
      updatedContactArray:[],
      metaData: '',
      languageOptions: [],
      companyName: '',
      instanceData: null,
      instanceAddress: null,
      rootParent: null,
      orginalActivityLogPayload: [],
      orginalActivityLogTotal: 0,
      filterSettingsData: {},
      filterSettings: {
        type: 'filter',
        module: 'rolesetting',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 5,
        filterQuery: {
          'role.id': '',
          module: 'Multi dwelling unit',
          section: 'Lead',
        },
        searchQuery: '',
      },
      profileComponentKey: 0,
      logData: [],
      activityrowpage: 0,
      activitycurrentpage: 0,
      activitytotal: 0,
      offerActivity: {
        type: 'filter',

        lastUpdated: new Date().toJSON(),

        module: 'log',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.activityrowpage,
          currentPage: this.activitycurrentpage,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: 'lead',
          ref: `${this.$route.params.id}`,
        },
        searchQuery: '',
      },
      showDropdown: false,
      ViewMode: false,
      updatedContactSchema: null,
      tempBack: false,
      discardIt: false,
      showOverviewDiscardModal: false,
      isDrawerSchema: false,
      temporaryLeadOverviewPayload: null,
      profilePicUrl: '',
      notesTableData: [],
      attachmentData: [],
      leadDetails: {
        name: '',
        id: '',
      },
      containerleft: 0,
      containertop: 0,
      isActionDropdownOpen: false,
      notification: {},
      delete_modal_active: false,
      deleteObj: {},
      contactsTableData: [],
      sitesTableData: [],
      isSaveButtonClicked: false,
      customFieldLeadSchema: {},
      view: '',
      leadData: {},
      schemaProp: {},
      schemaPropForContacts: {},
      leadOrginalData: {},
      leadDealCategoryOptions: [],
      leadOwnerOptions: [],
      leadMarketSegmentOptions: [],
      categoryOptions: [],
      leadSourceOptions: [],
      leadTypeOptions: [],
      dwellingTypeOptions: [],
      leadContactType: [],
      leadCategoryOptions: [],
      permisstionsData: {},
      permisstionData: {},
      selectedValue: '',
      leftPanelData: {}, // To get selected value from the left panel
      tempSideBar: {
        main: [
          {
            id: 1,
            name: 'Overview',
            icon: 'icon-presentation-regular',
            active: true,
            view: true,
          },
          {
            id: 2,
            name: 'Contacts',
            icon: 'icon-user-regular',
            active: false,
            view: true,
          },
          {
            id: 3,
            name: 'Sites',
            icon: 'icon-buildings-alt-regular',
            active: false,
            view: true,
          },
          // {
          //   id: 4,
          //   name: "Interaction",
          //   icon: "icon-phone-office-regular",
          //   active: false,
          //   view: true,
          // },
          // {
          //   id: 5,
          //   name: "Orders",
          //   icon: "icon-shopping-cart-regular",
          //   active: false,
          //   view: true,
          // },
          {
            id: 6,
            name: 'Notes',
            icon: 'icon-note-list-regular',
            active: false,
            view: true,
          },
          {
            id: 7,
            name: 'Documents',
            icon: 'icon-paperclip-regular',
            active: false,
            view: true,
          },
          {
            id: 8,
            name: 'Activity log',
            icon: 'icon-time-forward-regular',
            active: false,
            view: true,
          },
        ],
      },
    };
  },
  computed: {
    customFieldsReadOnly() {
      return this.view == true ? false : true;
    },
    attachedPaginated() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.attachmentData.slice(start, end);
    },
    getBreadcrumbs() {
      return [
        {
          label: 'Lead',
          link: `/lead`,
        },
      ];
    },
    filteredCustomFieldSchema() {
      console.log('this.customFieldLeadSchema', this.customFieldLeadSchema);
      if (
        this.customFieldLeadSchema &&
        Object.keys(this.customFieldLeadSchema).length > 0
      ) {
        let filtered = {};
        if (this.selectedValue.toLowerCase() == 'overview') {
          filtered = Object.entries(this.customFieldLeadSchema).filter(
            ([, value]) =>
              value.section &&
              value.section.includes(this.selectedValue.toLowerCase())
          );
        }
        if (this.selectedValue.toLowerCase() == 'contacts') {
          const contactSchema = this.customFieldLeadSchema?.contacts?.schema;
          if (contactSchema && Object.keys(contactSchema).length > 0) {
            filtered = Object.entries(contactSchema);
          }
          // Object.entries(this.customFieldLeadSchema).filter(
          //   ([, value]) =>
          //     value.section &&
          //     value.section.includes(this.selectedValue.toLowerCase())
          // );
        }
        if (this.selectedValue.toLowerCase() == 'sites') {
          const siteSchema = this.customFieldLeadSchema?.sites?.schema;
          if (siteSchema && Object.keys(siteSchema).length > 0) {
            filtered = Object.entries(siteSchema);
          }
          //  Object.entries(this.customFieldLeadSchema).filter(
          //   ([, value]) =>
          //     value.section &&
          //     value.section.includes(this.selectedValue.toLowerCase())
          // );
          siteSchema.siteAddress.others.isValidationRuleEnabled = false;
        }
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);
        // if (this.selectedValue.toLowerCase() == "overview") {
        //   Object.keys(sortedData).map((key) => {
        //     Object.keys(this.filterSettingsData.permission.Overview).map(
        //       (keyfiled) => {
        //         if (
        //           key ===
        //           this.filterSettingsData.permission.Overview[keyfiled].name
        //         ) {
        //           if (
        //             sortedData[key]?.others &&
        //             this.filterSettingsData.permission.Overview[keyfiled]
        //               .view === false
        //           ) {
        //             sortedData[key].others.viewMode = false;
        //           } else {
        //             sortedData[key].others.viewMode = true;
        //           }
        //         }
        //       }
        //     );
        //   });
        // }
        // if (this.selectedValue.toLowerCase() == 'contacts') {
        //   Object?.keys(sortedData).map((key) => {
        //     Object?.keys(this.filterSettingsData?.permission?.Contacts).map(
        //       (keyfiled) => {
        //         if (
        //           key ===
        //           this.filterSettingsData?.permission.Contacts[keyfiled].name
        //         ) {
        //           if (
        //             sortedData[key]?.others &&
        //             this.filterSettingsData?.permission?.Contacts[keyfiled]
        //               .edit === false
        //           ) {
        //             sortedData[key].others.edit = false;
        //           } else {
        //             sortedData[key].others.edit = true;
        //           }
        //         }
        //       }
        //     );
        //   });
        // }
        // if (this.selectedValue.toLowerCase() == 'sites') {
        //   Object?.keys(sortedData)?.map((key) => {
        //     Object.keys(this.filterSettingsData?.permission?.Sites).map(
        //       (keyfiled) => {
        //         if (
        //           key ===
        //           this.filterSettingsData?.permission?.Sites[keyfiled]?.name
        //         ) {
        //           if (
        //             sortedData[key]?.others &&
        //             this.filterSettingsData.permission.Sites[keyfiled].edit ===
        //               false
        //           ) {
        //             sortedData[key].others.edit = false;
        //           } else {
        //             sortedData[key].others.edit = true;
        //           }
        //         }
        //       }
        //     );
        //   });
        //   sortedData.siteAddress.others.isValidationRuleEnabled = false;
        // }
        if (
          this.selectedValue.toLowerCase() == 'overview' &&
          this.view == false
        ) {
          Object.keys(sortedData).forEach((key) => {
            if (sortedData[key]?.others) {
              sortedData[key].others.readOnly = true;
            }
          });
        }
        if(this.selectedValue.toLowerCase() == 'overview' && this.view == true ){
          Object.keys(sortedData).forEach((key) => {
            if (sortedData[key]?.others) {
              sortedData[key].others.readOnly = false;
            }
          });
        }
        console.log(this.filterSettingsData?.permission?.Overview,"this.filterSettingsData?.permission?.Overview")
//         if (
//           this.selectedValue.toLowerCase() == 'overview' &&
//           this.view == true
//         ) {
// if(this.filterSettingsData?.permission?.Overview){

//           Object.keys(sortedData).map((key) => {
//             Object?.keys(this.filterSettingsData?.permission?.Overview)?.map(
//               (keyfiled) => {
//                 if (
//                   key ===
//                   this.filterSettingsData?.permission?.Overview[keyfiled]?.name
//                 ) {
//                   if (
//                     sortedData[key]?.others &&
//                     this.filterSettingsData?.permission?.Overview[keyfiled]
//                       ?.edit === true
//                   ) {
//                     sortedData[key].others.readOnly = false;
//                   }
//                 }
//               }
//             );
//           });
//         }
//       }
        if (sortedData['defaultLanguage']) {
          sortedData['defaultLanguage'].options = this.languageOptions;
        }
        if (sortedData['numberOfSites']) {
          sortedData['numberOfSites'].others.readOnly = true;
        }
        if (sortedData['leadSource']) {
          sortedData['leadSource'].others.readOnly = true;
        }
        if (sortedData['metadata'] != undefined) {
          sortedData['metadata'].others.readOnly = true;
        }
        console.log(this.view, sortedData['metadata'], 'sortedData', sortedData);
        return sortedData;
        // else{
        // return sortedData;
        // }
      } else if (this.isDrawerSchema) {
        console.log('this.isDrawerSchema', this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    filteredCustomFieldSchemaForDrawer() {
      console.log('this.customFieldLeadSchema', this.customFieldLeadSchema);
      if (
        this.customFieldLeadSchema &&
        Object.keys(this.customFieldLeadSchema).length > 0
      ) {
        let filtered = {};
        if (this.selectedValue.toLowerCase() == 'overview') {
          filtered = Object.entries(this.customFieldLeadSchema).filter(
            ([, value]) =>
              value.section &&
              value.section.includes(this.selectedValue.toLowerCase())
          );
        }
        if (this.selectedValue.toLowerCase() == 'contacts') {
          filtered = Object.entries(
            this.customFieldLeadSchema?.contacts?.schema
          );
          // Object.entries(this.customFieldLeadSchema).filter(
          //   ([, value]) =>
          //     value.section &&
          //     value.section.includes(this.selectedValue.toLowerCase())
          // );
        }
        if (this.selectedValue.toLowerCase() == 'sites') {
          filtered = Object.entries(this.customFieldLeadSchema?.sites?.schema);
          //  Object.entries(this.customFieldLeadSchema).filter(
          //   ([, value]) =>
          //     value.section &&
          //     value.section.includes(this.selectedValue.toLowerCase())
          // );
        }
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on odd numbers first, then even numbers in `others.displayOrder`
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity;
          const orderB = b[1].others?.displayOrder ?? Infinity;

          // Check for odd/even priority
          const isOddA = orderA % 2 !== 0;
          const isOddB = orderB % 2 !== 0;

          if (isOddA && !isOddB) return -1; // A is odd, B is even
          if (!isOddA && isOddB) return 1; // A is even, B is odd

          // If both are odd or even, sort numerically
          return orderA - orderB;
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);
        // console.log(
        //   this.filterSettingsData.permission,
        //   ' this.filterSettingsData.permission',
        //   'sortedData',
        //   sortedData
        // );
        // if (this.selectedValue.toLowerCase() === "contacts") {
        //   Object.keys(sortedData).map((key) => {
        //     Object.keys(this.filterSettingsData.permission.Contacts).map(
        //       (keyfiled) => {
        //         if (
        //           key ===
        //           this.filterSettingsData.permission.Contacts[keyfiled].name
        //         ) {
        //           if (
        //             sortedData[key]?.others &&
        //             this.filterSettingsData.permission.Contacts[keyfiled]
        //               .edit === true && this.filterSettingsData.permission.Contacts[keyfiled]
        //               .view === true
        //           ) {
        //             sortedData[key].others.readOnly = false;

        //           }else{
        //             sortedData[key].others.readOnly = true;
        //           }
        //         }
        //       }
        //     );
        //   });
        // }

        // if (this.selectedValue.toLowerCase() === "sites") {
        //   Object.keys(sortedData).map((key) => {
        //     Object.keys(this.filterSettingsData.permission.Sites).map(
        //       (keyfiled) => {
        //         if (
        //           key ===
        //           this.filterSettingsData.permission.Sites[keyfiled].name
        //         ) {
        //           if (
        //             sortedData[key]?.others &&
        //             this.filterSettingsData.permission.Sites[keyfiled]
        //               .edit === true && this.filterSettingsData.permission.Sites[keyfiled]
        //               .view === true
        //           ) {
        //             sortedData[key].others.readOnly = false;

        //           }else{
        //             sortedData[key].others.readOnly = true;
        //           }
        //         }
        //       }
        //     );
        //   });
        // }
        // if (this.selectedValue.toLowerCase() == "contacts") {
        //   Object.keys(sortedData).map((key) => {
        //     Object.keys(this.filterSettingsData.permission.Contacts).map(
        //       (keyfiled) => {
        //         if (
        //           key ===
        //           this.filterSettingsData.permission.Contacts[keyfiled].name
        //         ) {
        //           if (
        //             sortedData[key]?.others &&
        //             this.filterSettingsData.permission.Contacts[keyfiled]
        //               .edit === false
        //           ) {
        //             this.sortedData[key].others.edit = true;
        //             console.log("sortedData[key].others", sortedData[key].others,this.filterSettingsData.permission.Contacts);
        //           }else{
        //             this.sortedData[key].others.edit = false;
        //           }
        //         }
        //       }
        //     );
        //   });
        // }
        // if (this.selectedValue.toLowerCase() == "sites") {
        //   Object.keys(sortedData).map((key) => {
        //     Object.keys(this.filterSettingsData.permission.Sites).map(
        //       (keyfiled) => {
        //         if (
        //           key ===
        //           this.filterSettingsData.permission.Sites[keyfiled].name
        //         ) {
        //           if (
        //             sortedData[key]?.others &&
        //             this.filterSettingsData.permission.Sites[keyfiled].edit ===
        //               false
        //           ) {
        //             sortedData[key].others.edit = false;
        //           }
        //         }
        //       }
        //     );
        //   });
        // }
        if (sortedData['defaultLanguage']) {
          sortedData['defaultLanguage'].options = this.languageOptions;
        }
        console.log(
          this.view,
          'otherssortedDatasortedDatasortedData',
          sortedData
        );
        return sortedData;
      } else if (this.isDrawerSchema) {
        console.log('this.isDrawerSchema', this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
  },
  watch: {
    leadOrginalData: {
      async handler(val) {
        if (val) {
          const relatedPartyCopy = [...(val.relatedParty || [])];

          this.leadOrgRelatedParty = relatedPartyCopy.filter(
            (party) =>
              party['@type'] != 'leadOwner' || party['@type'] != 'leadSource'
          );
          if (this.metaData) {
            this.leadOrgRelatedParty.metadata = this.metaData;
          }
          const filteredRelatedParty = relatedPartyCopy.filter(
            (party) =>
              party['@type'] === 'leadOwner' || party['@type'] === 'leadSource'
          );

          this.schemaProp = {
            ...JSON.parse(JSON.stringify(val)),
            relatedParty: filteredRelatedParty,
          };
          this.schemaProp.leadType = val?.type ? val?.type : val?.leadType;
          this.schemaProp.leadType = this.schemaProp.type;
          if (this.metaData) {
            this.schemaProp.metadata = JSON.parse(
              JSON.stringify(this.metaData)
            );
          }
          delete this.schemaProp.type;
          console.log(val, 'this.schemaProp ', this.schemaProp);
          if (Array.isArray(val.relatedParty)) {
            this.schemaPropForContacts = val.relatedParty.filter(
              (ele) => ele['@type'] === 'individual'
            );
            console.log(
              'this.schemaPropForContacts',
              this.schemaPropForContacts
            );
            let contactsDataInPayload = val.relatedParty.filter(
              (ele) => ele['@type'] == 'individual'
            );
            let contactIds = contactsDataInPayload.map((contact) =>
              contact.userId
            );
            console.log('contactsTableData', contactsDataInPayload);
            let sitesDataInPayload = val.relatedParty.filter(
              (ele) => ele['@type'] == 'site' || ele['@referredType'] == 'site'
            );
            console.log('sitesDataInPayload', sitesDataInPayload);
            let siteIds = sitesDataInPayload.map((site) =>
              site.id ? site.id : site.siteId
            );
            let getAllSites = await UPCAxiosInstance.get('/geoSite');
            if (getAllSites) {
              this.sitesTableData = getAllSites.data.filter((ele) =>
                siteIds.includes(ele.id)
              );
              console.log(getAllSites, 'sitesTableData', this.sitesTableData);
            }
                     // get contacts data 
                let getContactPayload = {
                "type": "filter",
                "module": "hierarchycontacts",
                "isPaginated": true,
                "paginatedQuery": {
                    "rowsPerPage": 10,
                    "currentPage": 1
                },
                "isNeedToBeSort": false,
                "sortFieldAndOrder": {},
                "limit": 10,
                "filterQuery": {
                    // "tradingName": this.leadData.displayID
                    "contactId": val?.accountRef,
                },
                "searchQuery": "",
                "company": this.companyName
            }
            let getResponse = await MLTAxiosInstance.post("util/filterData", getContactPayload);
            if( getResponse?.data?.data)
            {
              this.contactsTableData = JSON.parse(JSON.stringify(getResponse?.data?.data))
  .filter((ele) => contactIds.includes(ele.id)) // Filter by contact IDs
  .map((contact) => ({
    title: contact.title || '',
    createdAt: new Date().toISOString(),
    lastUpdate: new Date().toISOString(),
    firstName: contact.firstName || '',
    lastName: contact.lastName || '',
    contactType: 'Signing authority', 
    defaultLanguage: contact.defaultLanguage || '',
    contactMedium: contact.contactMedium
      ? contact.contactMedium.filter(
          (medium) =>
            medium.mediumType === 'email' ||
            medium.mediumType === 'phone' ||
            medium.mediumType === 'telephone'
        )
      : [], 
    '@type': 'individual', 
    contactId: val?.accountRef|| '', 
    userId: contact.id || '', 
    displayId: contact.displayID 
  }));

            }
            console.log("this.contactsTableData",this.contactsTableData)
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventPageReload);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventPageReload);
  },
  async mounted() {
    await this.$store.dispatch('loginTenantDetails');
    this.languageOptions = await this.addTranslationsSame(
      this.$store?.getters?.loginTenantDetails?.languageAbility
    );
    // Get left panel data
    this.languageOptions = this.languageOptions.map((language) => {
      if (language.name === 'english') {
        return { ...language, default: true };
      }
      return language;
    });

    const value = await MLTAxiosInstance.get('partymanagement/individual/auth');
    this.companyName = await value.data.company;
    let sampleTheadsd = await MLTAxiosInstance.get(
      `partyRole/get/${value.data.userId}`
    );
    this.filterSettings.filterQuery['role.id'] = sampleTheadsd.data.id;
    const mergedPayload = sampleTheadsd.data.permission;

    this.permisstionsData =
      mergedPayload.CM.child.Lead.sections;
    const accessZone = sampleTheadsd.data.accessZone;
    this.leftPanelData = this.mergeSidebarData(
      this.tempSideBar,
      this.permisstionsData,
      accessZone
    );
    const varibled = await UPCAxiosInstance.post(
      `util/filterData`,
      this.filterSettings
    );

    this.filterSettingsData = varibled?.data?.data?.[0];
    console.log(varibled?.data?.data?.[0], 'varibled');
    console.log(
      this.permisstionData,
      this.leftPanelData,
      'mergedPayload this.leftPanelData',
      this.selectedValue
    );

    // console.log(this.mergeSidebarData(this.sideBarData,this.permisstionData),"this.mergeSidebarData(this.sideBarData,this.permisstionData)");
    // this.sideBarData = this.mergeSidebarData(this.sideBarData,this.permisstionData)
    // const leftPanel = await UPCAxiosInstance.get(`/customHierarchy`);

    // console.log('leftPanel.data', leftPanel?.data[0]?.module[0].main);
    // const multiDwellingObjLeftPanel =
    //   leftPanel?.data[0]?.module[0]?.main?.filter(
    //     (ele) => ele?.name == 'CM'
    //   );
    // const leadData = multiDwellingObjLeftPanel[0]?.child?.filter(
    //   (ele) => ele?.name == 'Lead'
    // );
    // console.log(
    //   leadData[0]?.sections,
    //   'multiDwellingObjLeftPanel',
    //   this.leftPanelData
    // );

    //  const leftPanelSelectedVal = this.$cookies.get("selectedButton");
    //  leftPanelSelectedVal ?  this.selectedValue = leftPanelSelectedVal : this.selectedValue='overview'
    // console.log(this.selectedValue,"this.selectedValue")
    // let translateLang = await this.alternateNamesForLeft(leadData[0].sections);

    // console.log('refdeseeeeeee 22', translateLang);

    // translateLang = translateLang.filter((item) => item.value != 'interaction');
    // this.leftPanelData = { main: translateLang };

    // if (this.selectedValue != '' && this.selectedValue != null && this.selectedValue != undefined) {
    //     this.leftPanelData.main = this.leftPanelData.main.map(item => {
    //         if (item.value !== '' && item.value != null && item.value != undefined) {
    //             return {
    //                 ...item,
    //                 active: item.value.toLowerCase() === this.selectedValue.toLowerCase()
    //             };
    //         }
    //         return item;
    //     });
    // }

    // this.leftPanelData.main = this.leftPanelData.main.filter(
    //   (item) => item.value !== 'interaction'
    // );
    console.log('this.leftPanelData', this.leftPanelData);
    // To get lead schema for custom field
    const schema = await UPCAxiosInstance.get(
      `/customSchema/getSchema?module=${'saleslead'}`
    );
    this.customFieldLeadSchema = schema?.data?.schema;
    console.log(schema, 'schema', this.customFieldLeadSchema);

    // To get dropdowm options from refernce data
    // this.leadDealCategoryOptions = await this.getReferanceData(
    //   "LeadDealCategory"
    // );
    // this.leadOwnerOptions = await this.getReferanceData("LeadOwner");
    // this.leadMarketSegmentOptions = await this.getReferanceData(
    //   "LeadMarketSegment"
    // );
    // this.leadCategoryOptions = await this.getReferanceData("LeadCategory");
    // this.leadSourceOptions = await this.getReferanceData("LeadSource");
    // this.leadTypeOptions = await this.getReferanceData("LeadType");
    // this.dwellingTypeOptions = await this.getReferanceData("LeadDwellingType");
    // this.leadContactType = await this.getReferanceData("LeadContactType");
    // let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    // this.statusList = statusList.data.data;
    let response;
    if(this.$route.params.id){
    response = await UPCAxiosInstance.get(
      `/salesLead/${this.$route.params.id}`
    );
    }
    if (this.$route.params.type === 'edit') {
      this.view = true;
    } else {
      this.view = false;
    }
    this.leadOrginalData = response?.data;
    let networkId = this.leadOrginalData?.relatedParty?.find(
      (i) => i?.['@type']?.toLowerCase() == 'site'
    )?.networkId;
    if (networkId != undefined) {
      let metaData = await DMNAxiosInstance.post(`mapRef/collection/MetaData`, {
        query: { 'Network num': networkId },
      });
      metaData = metaData?.data?.data[0];
      delete metaData?.index;
      delete metaData?.isValid;
      delete metaData?.importStatus;
      delete metaData?.importedAt;
      delete metaData?.__v;
      delete metaData?._id;
      this.leadOrginalData.metadata = JSON.stringify(metaData);
      this.metaData = JSON.stringify(metaData);
    }
    if(this.leadOrginalData)
    {
    this.leadData = JSON.parse(JSON.stringify(this.leadOrginalData));
    }
    this.leadDetails = {
      name: this.$route.params.leadName,
      id: this.$route.params.id,
    };
    this.attachmentData = this.leadData?.attachment
      ? this.leadData?.attachment
      : [];
    this.notesTableData = this.leadData?.notes ? this.leadData?.notes : [];
    console.log(this.attachmentData, ' this.leadDetails', this.leadDetails);
    if (Array.isArray(this.attachmentData) && this.attachmentData.length > 0) {
      const profileInfo = this.attachmentData?.find(
        (ele) =>
          ele?.['@type'] === 'profile' || ele?.['attachmentType'] === 'profile'
      );
      if (
        profileInfo?.name != null &&
        profileInfo?.name != undefined &&
        profileInfo?.name != ''
      ) {
        const url = await UPCAxiosInstance.get(
          `/gcpupload/files/${profileInfo.name}`,
          { responseType: 'arraybuffer' }
        );
        this.profilePicUrl = await this.bufferToDataURL(url?.data);
        console.log('obj.url obj.url', url?.data);
        console.log(this.profilePicUrl, 'this.profilePicUrlBufferData');
      }
    }
    let activitylogdata = await MLTAxiosInstance.post(
      `util/filterData`,
      this.offerActivity
    );

    const instance = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${value.data.userId}?company=${value.data.company}`
    );
    this.instanceData = instance?.data;
    this.instanceAddress = this.instanceData?.contactMedium?.find(
      (item) => item.mediumType === 'postalAddress'
    );
    // let organisationInfo = await MLTAxiosInstance.get(
    //   `/partymanagement/organisation/${this.$cookies.get(
    //     'user'
    //   )}?company=${this.$cookies.get('company')}`
    // );
    // console.log(instance,"organisationInfo",organisationInfo)
    this.rootParent = instance?.data?.rootName;
    console.log(activitylogdata, 'activity log response data');
    this.orginalActivityLogPayload = { ...activitylogdata.data.data };
    this.orginalActivityLogTotal = activitylogdata.data.total;
    this.logData = activitylogdata.data.data;
    this.activitytotal = activitylogdata.data.total;
  },
  methods: {
    async getExistedLeadData([dataPayload, schemaToBeMapped, schema]) {
      if (dataPayload && schemaToBeMapped) {
        console.log(
          schema,
          dataPayload,
          'getExistedLeadData',
          schemaToBeMapped
        );
        if(dataPayload.relatedParty){
        const filteredIndividuals = dataPayload?.relatedParty.filter(
  (ele) => ele["@type"] === "individual"
);

this.selectedLeadContactUserId = filteredIndividuals.length > 0 
  ? filteredIndividuals[0].userId 
  : null;

  this.selectedLeadContactDisplayId = filteredIndividuals.length > 0 
  ? filteredIndividuals[0].displayId 
  : null;
this.selectedLeadAccountRef = dataPayload?.accountRef
        }
        for (let i = 0; i < schemaToBeMapped.length; i++) {
          let schemaObjName = schemaToBeMapped[i].LHS;
          let schemaValue = schemaToBeMapped[i].RHS;
          let changeRhsValue = 'payload';
          const firstDotIndex = schemaValue.indexOf('.');
          console.log(schemaValue, 'dataPayloaddataPayload', dataPayload);
          if (firstDotIndex !== -1) {
            let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
            console.log(schemaObjName, 'newRHS', newRHS);
            newRHS;
            let result = this.evaluateRHS(newRHS, dataPayload);
            console.log('remappped data', result);
            if (
              schema['name'] != undefined &&
              schema['name'].value != undefined
            ) {
              schema['name'].value = dataPayload?.name;
              this.selectedLeadName = dataPayload?.name;
              this.selectedLeadParentRef = dataPayload?.id;
              this.selectedLeadAccountRef = dataPayload?.accountRef
              console.log(this.selectedLeadAccountRef ,"dataPayload?.name",this.selectedLeadName )
            }
          }

        }

        if (schema?.isRemapData == false) {
          this.leadPayloadEmpty = JSON.parse(
            JSON.stringify(this.temporaryleadPayload)
          );
          this.updatedLeadSchema = JSON.parse(JSON.stringify(schema));
        }
      }
    },
    evaluateRHS(RHS, dataPayload) {
      this.dataPayloadForRemap = dataPayload;
      try {
        const cleanedRHS = RHS.replace(/payload/g, 'dataPayloadForRemap')
          .replace(/\n/g, ' ')
          .trim();
        console.log(
          this.dataPayloadForRemap,
          cleanedRHS,
          'dataPayloadForRemap',
          dataPayload
        );
        // Use Function constructor to evaluate the expression safely
        // eslint-disable-next-line no-new-func
        const result = new Function(
          'dataPayloadForRemap',
          `return ${cleanedRHS};`
        )(this.dataPayloadForRemap);
        return result;
      } catch (error) {
        console.error('Error evaluating RHS:', error);
        return null;
      }
    },
    async pushToAccountOverviewPage() {
      let payload = await MLTAxiosInstance.get(
      `/partymanagement/organisation/accountInfo/${this.leadData.accountRef}?company=${this.companyName}`
    );
    if(payload.data){
      this.$router.push({path:
        '/CM/accounts/view/' +
        [payload?.data?.name] +
        '/' +
        [payload?.data?.id] +
        '/view/accounts',
        query: { status: `${payload?.data?.status}` },
      }
    )
    }
    console.log('push to account overview page');
    },
   async patchAllContact(getResponse) {

    // Array have only id , name and email
    // this.updatedContactArray = []
  
  if (getResponse) {
    const updatePromises = getResponse.map(async (ele) => {
      delete ele['@baseType'];
      if (ele) {
        delete ele?._id
        delete ele?.companyName
        ele['@baseType'] = 'customer';

        try {
          // const result = await MLTAxiosInstance.patch(
          //   `/partymanagement/individual`,
          //   ele
          // );
          // /?company=${ele?.tradingName}
          this.updatedContactArray.push({
            "id":ele.id,
            "name":ele?.givenName,
            "firstName" : ele?.firstName,
            "lastName" : ele?.lastName,
            "contactMedium":ele?.contactMedium,
            '@type':'individual',
            "displayId":ele?.displayID,
            // "email":ele?.contactMedium[0]?.characteristic?.emailAddress,
            // "phoneNumber":ele?.contactMedium[1]?.characteristic?.phoneNumber?.value?.value
          })
          // console.log('Update successful:', result.data);
        } catch (error) {
          console.error('Error updating customer:', error);
        }
      }
    });

    // Wait for all requests to complete
    await Promise.all(updatePromises);
  }
},
    async convertToAccountAndCreateQuote(){
      //  Convert lead to account and create quote
      // if(check != 'patched'){
      //   this.leadData.status = 'Converted'      
      // let changeStatusOfTheLead = await UPCAxiosInstance.patch(`/salesLead`, this.leadData);
      // this.leadOrginalData = changeStatusOfTheLead.data;
      // this.leadData = { ...changeStatusOfTheLead.data };
      // }
      this.discardIt = true;
      this.$router.push({ name: "CPQDefaultTemplate" });
      let email = this.leadData?.contactMedium?.[0]?.characteristic?.emailAddress ?? "";
      console.log("email = > ",email)
      // convert lead to account 

      // Step -1 Get all contacts filter by account ref id
      console.log("")
    let getContactPayload = {
    "type": "filter",
    "module": "hierarchycontacts",
    "isPaginated": true,
    "paginatedQuery": {
        "rowsPerPage": 10,
        "currentPage": 1
    },
    "isNeedToBeSort": false,
    "sortFieldAndOrder": {},
    "limit": 10,
    "filterQuery": {
        // "tradingName": this.leadData.displayID
        "contactId":this.leadData?.accountRef
    },
    "searchQuery": "",
    "company": this.companyName
}

// Step -2 Get the account payload of this lead filter by account ref id

// If multiple lead is created use parentRef from the child lead payload to get Parent lead id 
// let getAccountPayload  = {
//     "type": "filter",
//     "module": "hierarchyaccounts",
//     "isPaginated": true,
//     "paginatedQuery": {
//         "rowsPerPage": 10,
//         "currentPage": 1
//     },
//     "isNeedToBeSort": false,
//     "sortFieldAndOrder": {},
//     "limit": 10,
//     "filterQuery": {
//         "leadRef": this.leadData?.id,

//     },
//     "searchQuery": "",
//     "company": this.companyName
// }
let getAccount = await MLTAxiosInstance.get(
      `/partymanagement/organisation/accountInfo/${this.leadData?.accountRef}?company=${this.companyName}`
    );

// Step - 3 Get all sites filter by account ref id

let getAllSitePayload  = {
    "type": "filter",
    "module": "site",
    "isPaginated": true,
    "paginatedQuery": {
        "rowsPerPage": 10,
        "currentPage": 1
    },
    "isNeedToBeSort": false,
    "sortFieldAndOrder": {},
    "limit": 10,
    "filterQuery": {
       "contactId": this.leadData?.accountRef,
    },
    "searchQuery": "",
    "company": this.companyName
}
console.log("getAllSitePayload",getAllSitePayload)
console.log(getContactPayload)
let getResponse = await MLTAxiosInstance.post("util/filterData", getContactPayload);
// let getAccount = await MLTAxiosInstance.post("util/filterData", getAccountPayload);
let getAllSites = await UPCAxiosInstance.post("util/filterData", getAllSitePayload);
console.log(getResponse?.data , "leadToAccountPayload",getAccount)
console.log(getAllSites?.data?.data,"getAllSites",getAllSites)
let sitesData = []
if (getAllSites?.data?.data) {
  let dupSitesDataCopy = JSON.parse(JSON.stringify(getAllSites.data.data));
   sitesData = dupSitesDataCopy.map((site) => ({
    ...site,
    "@type":"site"
  }));
}

console.log("sitesData",sitesData)
// leadToAccountPayload.id = leadToAccountPayload._id
// console.log(getResponse?.data?.data)
let leadToAccountPayload = getAccount?.data
console.log("leadToAccountPayload",leadToAccountPayload)

 // Step - 4 patch every contacts as @basetype = customer
if(getResponse?.data?.data){
 await this.patchAllContact(getResponse?.data?.data)
}
let updateAccountRelatedParty = JSON.parse(JSON.stringify(leadToAccountPayload?.relatedParty))
updateAccountRelatedParty = updateAccountRelatedParty.filter(ele => ele['@type'] !== 'individual');
updateAccountRelatedParty = updateAccountRelatedParty.filter(ele => ele['@type'] !== 'site');
updateAccountRelatedParty = updateAccountRelatedParty.concat(this.updatedContactArray)
updateAccountRelatedParty = updateAccountRelatedParty.concat(sitesData)
console.log(this.updatedContactArray,"updateAccountRelatedParty",updateAccountRelatedParty)
// leadToAccountPayload = leadToAccountPayload.relatedParty.map((item) => {
//     if (item["@type"] === "individual") {
//       item = {
//         ...item,
//         email: email,
//         id:item.userId,
//         parentCompany: this.rootParent,
//         company: this.companyName,
//         "@baseType": "customer",
//         type: "individual",
//         companyName: this.companyName,
//         familyName:
//         item.firstName + " " + item?.lastName,
//         givenName:
//         item.firstName + " " + item?.lastName,
//         tradingName: this.companyName,
//         birthDate: "",
//         status: "Active",
//         role: "Sales Support",
//         contactName:this.tradingNameFromLead,
//         name: item.firstName + " " + item.lastName,
//         languageAbility: [{ code: "en", name: "english" }],
//       };
//     }
//     console.log("leadToAccountPayload",item)
//   })
//       let patchContact = MLTAxiosInstance.patch(
//         `/partymanagement/individual/`,
//         item
//       );
// let result = await MLTAxiosInstance.patch(
//         `/partymanagement/individual/?company=${getAccount?.data?.tradingName}`,
//         item
//       );
//       console.log(patchContact,"patchContact",item)
//       return {
//           id: item.userId,
//           name: item.firstName + " " + item.lastName,
//           role: "Sales Support",
//       };
//     }
//     return item; 
// });
// delete leadToAccountPayload.email
if (leadToAccountPayload['@baseType'] == 'customer') {
  // delete leadToAccountPayload.tradingName
  delete leadToAccountPayload?.__v
  const firstEmail = leadToAccountPayload?.relatedParty?.filter(item => item["@type"] === "individual" && item.preferred === true)
                         .map(item => item.contactMedium.find(medium => medium.mediumType === "email" && medium.preferred === true)?.value)[0];
  
  let leadId =  leadToAccountPayload._id
  delete leadToAccountPayload?._id
  delete leadToAccountPayload?.type
  //  leadToAccountPayload.displayID
  leadToAccountPayload.relatedParty = JSON.parse(JSON.stringify(updateAccountRelatedParty));
  let payload = {
    ...leadToAccountPayload, 
    id: leadId,   
    email:firstEmail,          
  };
  payload.status = 'Active'
  payload.attachment = leadToAccountPayload?.attachment
  // console.log("payload",payload)
   let convertLeadToAccount =  await MLTAxiosInstance.patch(
          `/partymanagement/organisation/leadAccount`,
          payload
        );
        if(convertLeadToAccount.data)
        {
          let attachmentLogPayloadForLead = {
          message: `${payload.name} lead has been converted to account`,
          detailedAction: [
            {
              action: 'Lead convreted',
              to: payload.name,
              from: '',
              property: 'Account',
              field: 'Account',
              displayID: payload?.displayId,
            },
          ],
          action: 'ADDED',
          user: this.$cookies.get('userName'),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get('user'),
          ref: [payload?.id ? payload?.id : payload?._id],
          '@type': 'account-note',
          category: ['account'],
        };
        // attachmentLogPayloadForLead.detailedAction = attachmentLogData;
        // this.initLog(attachmentLogPayloadForLead);
        let response = await UPCAxiosInstance.post(
          '/activityLog/v1',
          attachmentLogPayloadForLead
        );
        response
        }
        console.log(convertLeadToAccount,"convertLeadToAccount alreadt existed Acc",payload)
}
else {
  delete leadToAccountPayload['@baseType']
  // delete leadToAccountPayload.tradingName
  delete leadToAccountPayload?.__v
  leadToAccountPayload['@baseType'] = 'customer'
  const firstEmail = leadToAccountPayload?.relatedParty?.filter(item => item["@type"] === "individual" && item.preferred === true)
                         .map(item => item.contactMedium.find(medium => medium.mediumType === "email" && medium.preferred === true)?.value)[0];
  
  let leadId =  leadToAccountPayload._id
  delete leadToAccountPayload?._id
  delete leadToAccountPayload?.type
  //  leadToAccountPayload.displayID
  leadToAccountPayload.relatedParty = JSON.parse(JSON.stringify(updateAccountRelatedParty));
  let payload = {
    ...leadToAccountPayload, 
    id: leadId,   
    email:firstEmail,          
  };
  payload.status = 'Active'
  console.log("payload",payload)
   let convertLeadToAccount =  await MLTAxiosInstance.patch(
          `/partymanagement/organisation/leadAccount`,
          payload
        );
        if(convertLeadToAccount.data)
        {
          let attachmentLogPayloadForLead = {
          message: `${payload.name} lead has been converted to account`,
          detailedAction: [
            {
              action: 'Lead convreted',
              to: payload.name,
              from: '',
              property: 'Account',
              field: 'Account',
              displayID: payload?.displayId,
            },
          ],
          action: 'ADDED',
          user: this.$cookies.get('userName'),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get('user'),
          ref: [payload?.id ? payload?.id : payload?._id],
          '@type': 'account-note',
          category: ['account'],
        };
        // attachmentLogPayloadForLead.detailedAction = attachmentLogData;
        // this.initLog(attachmentLogPayloadForLead);
        let response = await UPCAxiosInstance.post(
          '/activityLog/v1',
          attachmentLogPayloadForLead
        );
        response
      }
        console.log("convertLeadToAccount",convertLeadToAccount)
}
    },
    async activitycurrentpagefunc(val) {
      this.activitycurrentpage = val;
      this.offerActivity.paginatedQuery.currentPage = val;
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.offerActivity
      );

      this.logData = activitylogdata.data.data;
      this.activitytotal = activitylogdata.data.total;
    },
    async activityLogGetAllData() {
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.offerActivity
      );
      this.logData = activitylogdata.data.data;
      this.activitytotal = activitylogdata.data.total;
      console.log(
        this.activitytotal,
        activitylogdata,
        'activityLogGetAllData',
        this.orginalActivityLogPayload,
        this.orginalActivityLogTotal
      );
    },
    async activityrowpagefunc(val) {
      this.activityrowpage = val;
      this.offerActivity.paginatedQuery.rowsPerPage = val;
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.offerActivity
      );
      console.log(
        activitylogdata.data.data,
        '123 activity log response data in offer2 row'
      );

      this.logData = activitylogdata.data.data;
      this.activitytotal = activitylogdata.data.total;
    },
    getContactUpdatedSchema(val) {
      this.updatedContactSchema = { ...val };
    },
    preventPageReload(event) {
      this.showOverviewDiscardModal = false;
      console.log(
        this.schemaProp,
        'this.temporaryLeadOverviewPayload',
        this.temporaryLeadOverviewPayload
      );
      const dupSchemaProp = { ...this.schemaProp };
      delete dupSchemaProp.updatedAt;
      delete dupSchemaProp.createdAt;
      delete dupSchemaProp.lastUpdate;
      if (
        this.temporaryLeadOverviewPayload &&
        this.schemaProp &&
        this.selectedValue.toLowerCase() == 'overview'
      ) {
        let removeUnwantedData = this.temporaryLeadOverviewPayload;
        removeUnwantedData.relatedParty =
          removeUnwantedData.relatedParty.filter(
            (party) =>
              party['@type'] === 'leadOwner' || party['@type'] === 'leadSource'
          );
        delete removeUnwantedData.updatedAt;
        delete removeUnwantedData.createdAt;
        delete removeUnwantedData.lastUpdate;
        const leadSourceObjectRemoveUnwanted =
          removeUnwantedData?.externalReference?.find(
            (item) => item.type === 'leadSource'
          );
        const leadSourceObjectdupSchemaProp =
          dupSchemaProp?.externalReference?.find(
            (item) => item.type === 'leadSource'
          );
        const leadSourceValueRemove = leadSourceObjectRemoveUnwanted
          ? leadSourceObjectRemoveUnwanted.value
          : '';
        const leadSourceValueDup = leadSourceObjectdupSchemaProp
          ? leadSourceObjectdupSchemaProp.value
          : '';
        if (
          (dupSchemaProp.metadata == '' || removeUnwantedData.metadata == '') &&
          leadSourceValueRemove?.toLowerCase() != 'sap' &&
          leadSourceValueDup?.toLowerCase() != 'sap'
        ) {
          delete removeUnwantedData.metadata;
          delete dupSchemaProp.metadata;
        }
        if (removeUnwantedData['leadType']) {
          delete removeUnwantedData['leadType'];
        }
        if (Object.prototype.hasOwnProperty.call(dupSchemaProp, 'leadType')) {
          delete dupSchemaProp.leadType;
        }
        if (leadSourceValueRemove?.toLowerCase() == 'sap') {
          console.log('leadSourceValueRemove', leadSourceValueRemove);
          // removeUnwantedData.websiteUrl ?
          delete removeUnwantedData?.websiteUrl;
          //  : ''
          // removeUnwantedData.profileUpload ?
          delete removeUnwantedData?.profileUpload;
          //  : ''
          // dupSchemaProp.websiteUrl ?
          delete dupSchemaProp?.websiteUrl;
          //  : ''
          // dupSchemaProp.profileUpload ?
          delete dupSchemaProp?.profileUpload;
          // : ''
        }
        const unsavedChanges = this.isPayloadChanged(
          dupSchemaProp,
          removeUnwantedData
        );
        console.log(
          this.temporaryLeadOverviewPayload,
          'removeUnwantedData',
          removeUnwantedData
        );
        if (!unsavedChanges) return;
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
        return;
      } else {
        return;
      }
    },
    handleStay() {
      // //handle Stay funtionality to hide discard component
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.tempBack = false;
    },
    handleDiscard() {
      //Handle discard functionalities like hide discard component,set discardIt as true, and push the routes where ever you want.
      console.log('store', this.$store.state.tempActiveSideBarItem);
      if (this.tempBack) {
        this.$router.back();
      } else {
        this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      }
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.discardIt = true;
    },
    async bufferToDataURL(buffer) {
      console.log('bufferToDataURL', buffer);
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = '';
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    schemaForDrawer(val) {
      console.log('schemaForDrawer', val);
      this.isDrawerSchema = val;
    },
    async alternateNamesForLeft(data) {
      try {
        // Retrieve the language setting from localStorage
        let languageAbility = JSON.parse(
          localStorage.getItem('userLanguageFromSetting')
        );

        // Extract the language code, default to 'en' if not available
        const lang = languageAbility?.[0]?.code || 'en';

        // Use a regular for loop instead of for...of
        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          if (item.customName) {
            // Update the customName field with the translated value
            item.customName = await this.languageTransfer(
              item.customName,
              lang
            );
          }
        }
        console.log('refdeseeeeeee', data);

        // Return the modified data
        return data;
      } catch (error) {
        console.error('Error updating names:', error);
        return data; // In case of an error, return the original data
      }
    },

    getProfileImageBufferData(val) {
      this.profilePicUrl = val;
      console.log('getProfileImageBufferData', val);
      this.profileComponentKey += 1;
    },
    getProfileImageName(val) {
      console.log('getProfileImageName', val);
    },
    async deleteNote(val) {
      console.log(val, 'deletednotes');

      let removed = this.leadData.notes.splice(val, 1);
      console.log(removed, 'removed');
      let editNotes = await UPCAxiosInstance.patch(`/salesLead`, this.leadData);
      if (editNotes) {
        const getUpdatedData = await UPCAxiosInstance.get(
          `/salesLead/${this.$route.params.id}`
        );
        this.leadOrginalData = getUpdatedData.data;
        this.leadData = { ...getUpdatedData.data };
        this.notesTableData = this.leadOrginalData.notes;
        let attachmentLogPayload = {
          message: `${getUpdatedData.data?.name} note has been deleted`,
          detailedAction: [
            {
              from: removed?.[0]?.note,
              to: '',
              property: 'Notes',
              field: 'Note',
              action: 'Note deleted',
              displayID: removed?.[0]?.displayId,
            },
          ],
          action: 'DELETED',
          user: this.$cookies.get('userName'),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get('user'),
          ref: [this.$route.params.id],
          '@type': 'lead-note',
          category: ['lead'],
        };
        // this.initLog(attachmentLogPayload);
        let response = await UPCAxiosInstance.post(
          '/activityLog/v1',
          attachmentLogPayload
        );
        console.log(response);
      }
    },

    async editNote(val) {
      console.log(val, 'editnotes');
      let oldValue = this.leadData.notes[val.index];
      let updatedValue = val.val;
      this.leadData.notes[val.index] = val.val;
      // let displayId =
      let displayId = val?.val?.displayId;
      console.log('oldValue', oldValue);
      let editNotes = await UPCAxiosInstance.patch(`/salesLead`, this.leadData);
      if (editNotes) {
        const getUpdatedData = await UPCAxiosInstance.get(
          `/salesLead/${this.$route.params.id}`
        );
        this.leadOrginalData = getUpdatedData.data;
        this.leadData = { ...getUpdatedData.data };
        this.notesTableData = this.leadOrginalData.notes;

        let notesLogPayload = {
          message: `${getUpdatedData?.data?.name} notes has been updated`,
          detailedAction: [],
          action: 'UPDATED',
          user: this.$cookies.get('userName'),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get('user'),
          ref: [this.$route.params.id],
          '@type': 'lead-notes',
          category: ['lead'],
        };

        // Checks notes changes
        console.log(oldValue, 'updatedValue', updatedValue);
        if (val.val !== oldValue) {
          let editedNoted = this.buildBasicLogByDifferencesVersion2(
            oldValue,
            updatedValue,
            'note',
            'Note',
            'Note edited',
            displayId
            // Display id
          );
          console.log('editedNoted if ', editedNoted);
          notesLogPayload.detailedAction = editedNoted;
        } else if (!val.val && oldValue) {
          let editedNoted = this.buildBasicLogByDifferencesVersion2(
            oldValue,
            '',
            'note',
            'Note',
            'Note removed',
            displayId
          );
          console.log('editedNoted else if ', editedNoted);
          notesLogPayload.detailedAction = editedNoted;
        }
        // else if (val.val && !oldValue) {
        //   let editedNoted = this.buildLogBasedOnDifferencesForArrayOfObjectsVersion2(
        //         [oldValue],
        //         [val.val],
        //         "note",
        //         "Notes",
        //         'note',
        //         'Note removed'
        //         )
        //         notesLogPayload.push(editedNoted)
        // }
        // this.initLog(notesLogPayload);
        let response = await UPCAxiosInstance.post(
          '/activityLog/v1',
          notesLogPayload
        );
        console.log(response);
      }
      console.log(this.notesTableData, 'notes table data');
    },
    async addNote(val) {
      if (this.notesTableData.length > 0) {
        let arrayOfDisplayId = this.notesTableData.map((ele) => {
          ele.displayId[0];
        });
        console.log(this.notesTableData, 'arrayOfDisplayId', arrayOfDisplayId);
        let noteId = this.generateDisplayId('NOTE', arrayOfDisplayId, '1');
        val = { ...val, displayId: noteId[0] };
      } else {
        let noteId = this.generateDisplayId('NOTE', [], '1');
        val = { ...val, displayId: noteId[0] };
      }
      console.log(val, 'Notes value');
      this.leadData.notes = this.leadData.notes ? this.leadData.notes : [];

      this.leadData.notes.unshift(val);

      let notes = await UPCAxiosInstance.patch(`/salesLead`, this.leadData);
      if (notes) {
        const getUpdatedData = await UPCAxiosInstance.get(
          `/salesLead/${this.$route.params.id}`
        );
        this.leadOrginalData = getUpdatedData.data;
        this.leadData = { ...getUpdatedData.data };
        this.notesTableData = this.leadOrginalData.notes;
        // let attachmentLogData =
        //   this.buildBasicLogByDifferencesVersion2(
        //     '',
        //     val,
        //     "note",
        //     "Note",
        //     "Note added"
        //   );
        // console.log("attachmentLogData",attachmentLogData)
        let attachmentLogPayloadForLead = {
          message: `${getUpdatedData.data.name} note has been added`,
          detailedAction: [
            {
              action: 'Note added',
              to: val?.note,
              from: '',
              property: 'Note',
              field: 'Note',
              displayID: val?.displayId,
            },
          ],
          action: 'ADDED',
          user: this.$cookies.get('userName'),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get('user'),
          ref: [this.$route.params.id],
          '@type': 'lead-note',
          category: ['lead'],
        };
        // attachmentLogPayloadForLead.detailedAction = attachmentLogData;
        // this.initLog(attachmentLogPayloadForLead);
        let response = await UPCAxiosInstance.post(
          '/activityLog/v1',
          attachmentLogPayloadForLead
        );
        console.log(response);
      }
    },
    async getSearchedDataForActivityLog(val) {
      console.log('getSearchedDataForActivityLog', val);
      this.offerActivity.type = 'filter-search';
      this.offerActivity.searchQuery = val;
      this.offerActivity['searchList'] = [
        {
          'detailedAction.action': {
            $regex: val,
            $options: 'i',
          },
        },
        {
          'detailedAction.displayID': {
            $regex: val,
            $options: 'i',
          },
        },
        {
          'detailedAction.from': {
            $regex: val,
            $options: 'i',
          },
        },
        {
          'detailedAction.to': {
            $regex: val,
            $options: 'i',
          },
        },
        {
          'detailedAction.property': {
            $regex: val,
            $options: 'i',
          },
        },
        // {
        //     "updatedAt": {
        //         "$regex": "f",
        //         "$options": "i"
        //     }
        // }
      ];
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.offerActivity
      );
      this.orginalActivityLogPayload = { ...activitylogdata.data.data };
      this.orginalActivityLogTotal = activitylogdata.data.total;
      this.logData = activitylogdata.data.data;
      this.activitytotal = activitylogdata.data.total;
    },

    async deleteAttachmentRow(val) {
      this.delete_name = this.attachmentData[val - 1].name;
      let displayId = this.attachmentData[val - 1].displayId;
      this.attachmentData.splice(val - 1, 1);
      console.log(
        displayId,
        'deleteAttachmentRow',
        val,
        this.attachmentData[val - 1]
      );

      this.leadData.attachment = await this.attachmentData;
      const deleteAttachment = await UPCAxiosInstance.patch(
        `/salesLead`,
        this.leadData
      );
      const image = await UPCAxiosInstance.delete(
        `gcpupload/files/${this.delete_name}`
      );
      if (deleteAttachment && image) {
        const getUpdatedData = await UPCAxiosInstance.get(
          `/salesLead/${this.$route.params.id}`
        );
        if (getUpdatedData.data) {
          let attachmentLogPayload = {
            message: `${this.payload?.name} attachment has been deleted`,
            detailedAction: [
              {
                action: 'Deleted attachment',
                property: 'Attachment',
                from: this.delete_name,
                to: '',
                field: 'Attachment',
                displayID: displayId?.[0],
              },
            ],
            action: 'DELETED',
            user: this.$cookies.get('userName'),
            lastUpdate: new Date().toISOString(),
            createdBy: this.$cookies.get('user'),
            ref: [this.$route.params.id],
            '@type': 'lead-attachment',
            category: ['lead'],
          };
          // this.initLog(attachmentLogPayload);
          let response = await UPCAxiosInstance.post(
            '/activityLog/v1',
            attachmentLogPayload
          );
          console.log(response);
        }
        this.leadOrginalData = getUpdatedData.data;
        this.leadData = { ...getUpdatedData.data };
        this.attachmentData = this.leadOrginalData.attachment;
      }
    },
    async receivedUploadData(val) {
      let attachmentId = '';
      // if(this.attachmentData.length > 0){
      //   let arrayOfDisplayId  = this.attachmentData.map((ele)=>{
      //     ele.displayId
      //   })
      //   console.log("arrayOfDisplayId",arrayOfDisplayId)
      //  attachmentId = this.generateDisplayId("DOC",arrayOfDisplayId,'1')
      //   val = { ...val, displayId: attachmentId[0] }
      // }
      // else{
      //   attachmentId = this.generateDisplayId("DOC",[],'1')
      //   // val = { ...val, displayId: noteId[0] }
      // }
      let data = await UPCAxiosInstance.post(`/gcpMultiple/v2/upload/`, val);
      console.log(data);
      const uploadedAttachments = [];
      let dupAttachmentsData = JSON.parse(JSON.stringify(this.attachmentData));
      let arrayOfDisplayId = '';
      console.log('arrayOfDisplayId', arrayOfDisplayId);
      data?.data?.forEach((item) => {
        if (dupAttachmentsData.length > 0) {
          arrayOfDisplayId = dupAttachmentsData
            .map((ele) => {
              return Array.isArray(ele.displayId) && ele.displayId.length > 0
                ? ele.displayId[0]
                : null;
            })
            .filter((id) => id !== null);

          console.log('arrayOfDisplayId', arrayOfDisplayId);
        }
        attachmentId = this.generateDisplayId('DOC', arrayOfDisplayId, '1');
        let obj = {};
        obj.name = item.filename;
        obj.size = item.data.attachment.size;
        obj.url = item.url;
        obj.id = item.data._id;
        if (Array.isArray(attachmentId[0])) {
          attachmentId = JSON.parse(JSON.stringify(attachmentId.flat(1)));
        }
        obj.displayId = attachmentId;
        // Get the current date and time in milliseconds
        const currentTimestamp = new Date().toJSON();

        // Format the current timestamp in the same format as '1705574420667'
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;
        this.leadData.attachment = this.leadData.attachment
          ? this.leadData.attachment
          : [];
        dupAttachmentsData.push(obj);
        this.leadData.attachment.unshift(obj);
        uploadedAttachments.push(obj);
      });
      const response = await UPCAxiosInstance.patch(
        `/salesLead`,
        this.leadData
      );
      const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      this.leadOrginalData = getUpdatedData.data;
      this.leadData = { ...getUpdatedData.data };
      if (response) {
        this.notification = {
          type: 'success',
          message:
            this.loadStaticData('LANG-0032') ||
            `Nice work! Your new file is now in the list!`,
        };
        let allDetailedAction = [];
        console.log('uploadedAttachments', uploadedAttachments);
        uploadedAttachments.forEach((item) => {
          allDetailedAction = [
            ...allDetailedAction,
            ...this.buildBasicLogByDifferencesVersion2(
              [],
              item,
              'name',
              'Attachment',
              'Added Attachment',
              item?.displayId
            ),
          ];
        });
        let attachmentLogPayloadForLead = {
          message: `${getUpdatedData.data.name} attachment has been added`,
          detailedAction: [],
          action: 'ADDED',
          user: this.$cookies.get('userName'),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get('user'),
          ref: [this.$route.params.id],
          '@type': 'lead-attachment',
          category: ['lead'],
        };
        allDetailedAction = this.removeEmptyEntries(allDetailedAction);
        // this.initLog(updatedAdditionalChargeLogPayload);
        const result = allDetailedAction.map((item) => ({
          ...attachmentLogPayloadForLead,
          detailedAction: [item],
        }));

        console.log('result', result);
        // let attachmentLogData =
        //   this.buildLogBasedOnDifferencesForArrayOfObjectsVersion2(
        //     [],
        //     uploadedAttachments,
        //     "name",
        //     "Attachment",
        //     "name",
        //     "Added attachment"
        //   );
        // attachmentLogPayloadForLead.detailedAction = attachmentLogData;
        // this.initLog(attachmentLogPayloadForLead);
        let response = await UPCAxiosInstance.post('/activityLog/v1', result);
        console.log(response);
      }
      this.attachmentData = this.leadOrginalData.attachment;
    },
    display(id) {
      console.log(id, 'containerleft');
      this.containerleft =
        document.getElementById(id).getBoundingClientRect().left + 5;
      this.containertop =
        document.getElementById(id).getBoundingClientRect().top + 50;
      console.log(this.containertop, 'containerleft', this.containerleft);
    },
    computedTableAction() {
      let options = [
        {
          label: 'Mark as primary',
          icon: 'icon-check-circle-regular',
        },
        {
          label: 'Edit',
          icon: 'icon-file-text-edit-regular',
        },
        {
          label: 'Delete',
          icon: 'icon-trash-regular',
        },
      ];
      return options;
    },
    async deleteContactData(val) {
      this.deleteObj = JSON.parse(JSON.stringify(val));
      this.delete_modal_active = true;
    },
    async deleteSiteData(val) {
      this.deleteObj = JSON.parse(JSON.stringify(val));
      this.delete_modal_active = true;
    },
    async deleteContactRow() {
      if (this.selectedValue.toLowerCase() == 'sites') {
        console.log(this.leadData, this.deleteObj, 'sites');
        this.leadData.relatedParty = this.leadData.relatedParty.filter(
          (item) => item?.id !== this.deleteObj?._id
        );
        const externalReference = this.leadData?.externalReference?.find(
          (ref) => ref.type.toLowerCase() == 'numberofsites'
        );

        if (externalReference && externalReference.value) {
          externalReference.value = Number(externalReference.value) - 1;
        }
        let deleteSite = await UPCAxiosInstance.delete(
          `/geoSite/${this.deleteObj._id}`
        );
        if (deleteSite) {
          const response = await UPCAxiosInstance.patch(
            `/salesLead`,
            this.leadData
          );
          console.log('response', response);
          const getUpdatedData = await UPCAxiosInstance.get(
            `/salesLead/${this.$route.params.id}`
          );
          if (getUpdatedData) {
            this.delete_modal_active = false;
            this.notification = {
              type: 'success',
              message:
                this.loadStaticData('LANG-0033') || `Site deleted successfully`,
            };
          }
          if (getUpdatedData) {
            const leadCreationActivityLogPayload = {
              message: `${this.deleteObj.name} site has been deleted`,
              detailedAction: [
                {
                  from: this.deleteObj.name,
                  to: '',

                  property: 'Site deleted',
                  action: 'Site deleted',
                  displayID: this.deleteObj?.displayID,
                },
              ],
              action: 'deleted',
              user: this.$cookies.get('userName'),
              lastUpdate: new Date().toISOString(),
              createdBy: this.$cookies.get('user'),
              ref: [this.$route.params.id],
              '@type': 'lead-site-deletion',
              category: ['lead'],
            };
            // this.initLog(leadCreationActivityLogPayload);
            let response = await UPCAxiosInstance.post(
              '/activityLog/v1',
              leadCreationActivityLogPayload
            );
            console.log(response);
          }
          this.leadOrginalData = getUpdatedData?.data;
          this.leadData = { ...getUpdatedData?.data };
        }
      }
      if (this.selectedValue.toLowerCase() == 'contacts') {
        this.leadData.relatedParty = this.leadData.relatedParty.filter(
          (item) =>
            item?.firstName?.toLowerCase() !==
            this.deleteObj?.firstName?.toLowerCase() // Replace 'key' with a unique identifier.
        );
        console.log(this.leadData, 'deleteContactData', this.deleteObj);
        const response = await UPCAxiosInstance.patch(
          `/salesLead`,
          this.leadData
        );
        console.log('response', response);
        const getUpdatedData = await UPCAxiosInstance.get(
          `/salesLead/${this.$route.params.id}`
        );
        if (getUpdatedData) {
          this.delete_modal_active = false;
          this.notification = {
            type: 'success',
            message:
              this.loadStaticData('LANG-0031') ||
              `Contact deleted successfully`,
          };
        }
        this.leadOrginalData = getUpdatedData.data;
        this.leadData = { ...getUpdatedData.data };
      }
    },
    async patchUpdatedPayloadForContact(val, initialContact, updatedContact) {
      console.log('patchUpdatedPayloadForContact', val);
      const response = await UPCAxiosInstance.patch(`/salesLead`, val);
      console.log('response', response);
      const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      if (getUpdatedData) {
        this.notification = {
          type: 'success',
          message:
            this.loadStaticData('LANG-0034') || `Contact updated successfully`,
        };
      }
      if (getUpdatedData.data) {
        let updatedAdditionalChargeLogPayload = {
          message: `${getUpdatedData?.data?.name} lead contact has been updated`,
          '@type': 'lead-contact',
          detailedAction: [],
          category: ['lead'],
          createdBy: this.$cookies.get('user'),
          user: this.$cookies.get('userName'),
          lastUpdate: new Date().toISOString(),
          ref: `${this.$route.params.id}`,
          action: 'Edited contact',
        };
        let allDetailedAction = [];
        // Update lead initial and updated payload
        delete initialContact.context;
        delete updatedContact.context;
        const initialLeadPayload = { ...initialContact };
        delete initialLeadPayload.updatedAt;
        delete initialLeadPayload.createdAt;
        delete initialLeadPayload.lastUpdate;
        delete initialLeadPayload['@type'];
        let updatedLeadPayload = updatedContact;
        delete updatedLeadPayload.updatedAt;
        delete updatedLeadPayload.createdAt;
        delete updatedLeadPayload.lastUpdate;
        delete updatedLeadPayload['@type'];
        console.log(updatedLeadPayload, 'dupSchemaProp', initialLeadPayload);

        Object.keys(updatedLeadPayload).forEach((key) => {
          const orgInitialValue = initialLeadPayload;
          const updatedPayload = updatedLeadPayload;
          const initialValue = initialLeadPayload[key];
          const currentValue = updatedLeadPayload[key];

          if (Array.isArray(currentValue)) {
            if (typeof currentValue[0] === 'object') {
              console.log(
                currentValue,
                'initialValue object',
                initialValue,
                key,
                '=> type',
                initialValue.type ? initialValue.type : initialValue['@type']
              );
              // Case: Array of objects
              if (key == 'contactMedium') {
                console.log('contactmedium', currentValue);
                let currentValueForEmail = currentValue.find((item) => {
                  if (item.mediumType == 'email') {
                    console.log('item.characteristic', item.characteristic);
                    return item.characteristic;
                  }
                }).characteristic;
                let initialValueForEmail = initialValue.find((item) => {
                  if (item.mediumType == 'email') {
                    console.log('item.characteristic', item.characteristic);
                    return item.characteristic;
                  }
                }).characteristic;
                if (initialValueForEmail && currentValueForEmail) {
                  // email differences
                  allDetailedAction = [
                    ...allDetailedAction,
                    this.buildBasicLogByDifferencesVersion2(
                      initialValueForEmail,
                      currentValueForEmail,
                      'emailAddress',
                      'Email',
                      'Edited contact',
                      updatedContact?.displayId
                    ),
                  ];
                }
                console.log(
                  currentValueForEmail,
                  'For Email',
                  initialValueForEmail
                );

                // Phone number
                let currentValueForPhone = currentValue.find((item) => {
                  if (item.mediumType == 'phone') {
                    console.log('item.characteristic', item.characteristic);
                    return item.characteristic;
                  }
                }).characteristic.phoneNumber;
                let initialValueForPhone = initialValue.find((item) => {
                  if (item.mediumType == 'phone') {
                    console.log('item.characteristic', item.characteristic);
                    return item.characteristic;
                  }
                }).characteristic.phoneNumber;
                console.log(
                  currentValueForPhone,
                  'For Phone',
                  initialValueForPhone
                );

                if (currentValueForPhone && initialValueForPhone) {
                  // phoneNumber differences
                  allDetailedAction = [
                    ...allDetailedAction,
                    this.buildBasicLogByDifferencesVersion2(
                      initialValueForPhone.value,
                      currentValueForPhone.value,
                      'value',
                      'phone number',
                      'Edited contact',
                      updatedContact?.displayId
                    ),
                  ];
                  // phoneNumber differences
                  allDetailedAction = [
                    ...allDetailedAction,
                    this.buildBasicLogByDifferencesVersion2(
                      initialValueForPhone.value.country,
                      currentValueForPhone.value.country,
                      'name',
                      'phone number country',
                      'Edited contact',
                      updatedContact?.displayId
                    ),
                  ];
                }

                // Telephone number
                let currentValueForTelephone = currentValue.find((item) => {
                  if (item.mediumType == 'telephone') {
                    console.log('item.characteristic', item.characteristic);
                    return item.characteristic;
                  }
                }).characteristic.telephoneNumber;
                let initialValueForTelephone = initialValue.find((item) => {
                  if (item.mediumType == 'telephone') {
                    console.log('item.characteristic', item.characteristic);
                    return item.characteristic;
                  }
                }).characteristic.telephoneNumber;
                console.log(
                  currentValueForTelephone,
                  'initialValueForTelephone',
                  initialValueForTelephone
                );
                if (initialValueForTelephone && currentValueForTelephone) {
                  // telephone differences
                  allDetailedAction = [
                    ...allDetailedAction,
                    ...this.buildBasicLogByDifferencesVersion2(
                      initialValueForTelephone.value
                        ? initialValueForTelephone.value
                        : {},
                      currentValueForTelephone.value,
                      'value',
                      'Telephone number',
                      'Edited contact',
                      updatedContact?.displayId
                    ),
                  ];
                  // telephone differences
                  allDetailedAction = [
                    ...allDetailedAction,
                    ...this.buildBasicLogByDifferencesVersion2(
                      initialValueForTelephone.value.country
                        ? initialValueForTelephone.value.country
                        : {},
                      currentValueForTelephone.value.country,
                      'name',
                      'Telephone country',
                      'Edited contact',
                      updatedContact?.displayId
                    ),
                  ];
                }
                console.log(
                  currentValueForTelephone,
                  'For telephone',
                  initialValueForTelephone
                );
              } else {
                allDetailedAction = [
                  ...allDetailedAction,
                  ...this.buildBasicLogByDifferencesVersion2(
                    initialValue[0],
                    currentValue[0],
                    'value',
                    initialValue?.[0].type
                      ? initialValue?.[0].type
                      : initialValue?.[0]?.['@type'],
                    'Edited contact',
                    updatedContact?.displayId
                  ),
                ];
              }
            } else if (typeof currentValue[0] === 'string') {
              // Case: Array of strings
              console.log(currentValue, 'initialValue string', initialValue);
              allDetailedAction = [
                ...allDetailedAction,
                ...this.findArrayOfStringDifferences(
                  initialValue,
                  updatedPayload,
                  key
                ),
              ];
            }
          } else {
            // Default case: Handle basic log for non-array types
            console.log('key for else', key);
            allDetailedAction = [
              ...allDetailedAction,
              ...this.buildBasicLogByDifferencesVersion2(
                orgInitialValue,
                updatedPayload,
                key,
                key == 'preferred' ? 'Mark as primary' : key,
                'Edited contact',
                updatedContact?.displayId
              ),
            ];
          }
          console.log(
            initialValue,
            'updatedAdditionalChargeLogPayload',
            updatedAdditionalChargeLogPayload,
            currentValue
          );
        });
        allDetailedAction = this.removeEmptyEntries(allDetailedAction);
        // this.initLog(updatedAdditionalChargeLogPayload);
        const result = allDetailedAction.map((item) => ({
          ...updatedAdditionalChargeLogPayload,
          detailedAction: [item],
        }));

        let response = await UPCAxiosInstance.post('/activityLog/v1', result);
        console.log('response', response);
      }
      console.log('getUpdatedData.data', getUpdatedData.data);
      this.leadOrginalData = getUpdatedData.data;
      this.leadData = { ...getUpdatedData.data };
    },
    async addNewContact(val, newContactPayload) {
      console.log(newContactPayload, 'addNewContact', val);
      const response = await UPCAxiosInstance.patch(`/salesLead`, val);
      console.log('response', response);
      const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      if (getUpdatedData) {
        this.notification = {
          type: 'success',
          message: this.matchFromStatic('Contact added successfully'),
        };
      }
      if (getUpdatedData) {
        const leadCreationActivityLogPayload = {
          message: `${
            newContactPayload.firstName + ' ' + newContactPayload.lastName
          } contact has been created`,
          detailedAction: [
            {
              action: 'Created contact',
              to:
                newContactPayload.firstName + ' ' + newContactPayload.lastName,
              from: '',
              property: 'Contact',
              displayID: newContactPayload?.displayId,
            },
          ],
          action: 'Contact created',
          user: this.$cookies.get('userName'),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get('user'),
          ref: [this.$route.params.id],
          '@type': 'lead-contact-creation',
          category: ['lead'],
        };
        // this.initLog(leadCreationActivityLogPayload);
        let responseForActivity = await UPCAxiosInstance.post(
          '/activityLog/v1',
          leadCreationActivityLogPayload
        );
        console.log('response', responseForActivity);
      }
      // newContactPayload.contactMedium.push(this.instanceAddress);
      // let email =
      // newContactPayload?.contactMedium?.[0]?.characteristic
      //     ?.emailAddress ?? "";
      // console.log("emailValue", email);
      // newContactPayload = {
      //   ...newContactPayload,
      //   password: "",
      //   email: email,
      //   parentCompany: this.rootParent,
      //   company: this.companyName,
      //   "@baseType": "lead",
      //   type: "individual",
      //   companyName: this.companyName,
      //   familyName:
      //     newContactPayload.firstName + " " + newContactPayload?.lastName,
      //   givenName:
      //     newContactPayload.firstName + " " + newContactPayload?.lastName,
      //   tradingName: this.companyName,
      //   birthDate: "",
      //   status: "Active",
      //   role: "Sales Support",
      //   name: newContactPayload.firstName + " " + newContactPayload.lastName,
      //   languageAbility: [{ code: "en", name: "english" }],
      // };

      // delete newContactPayload.contactType;
      // delete newContactPayload["@type"];
      // const contactPostInPartCollection = await MLTAxiosInstance.post(
      //   `partymanagement/individual/signup`,
      //   newContactPayload
      // );
      // console.log("contactPostInPartCollection", contactPostInPartCollection);
      // const contactpayload = await MLTAxiosInstance.get(
      //   `partymanagement/individual/listuser?company=${this.companyName}`
      // );
      // console.log(contactpayload.data, "contactpayload.data");

      console.log('newContactPayload altered', newContactPayload);
      this.leadOrginalData = getUpdatedData.data;
      this.leadData = { ...getUpdatedData.data };
    },
    async patchUpdatedPayloadForSite(
      leadData,
      siteData,
      initalSite,
      updatedSite
    ) {
      if (Array.isArray(siteData.contactMedium)) {
        siteData.contactMedium = siteData.contactMedium.map((medium) => {
        return { ...medium, mediumType: 'postalAddress' };
    });
    }
      //  patch the site data
      // console.log(val2,"patchUpdatedPayloadForSite",val)
      delete siteData._id
      siteData.context = false;
      const response = await UPCAxiosInstance.patch(`geoSite`, siteData);
      console.log('Response for site:', response.data);
      const relatedSite = leadData.relatedParty.find(
        (obj) =>
          (obj.name === response.data.siteName ||
            obj.name === response.data.name) &&
          obj['@type'] === 'site'
      );
      if (relatedSite) {
        console.log('relatedSite', relatedSite);
        relatedSite.name = response.data.siteName || response.data.name;
        relatedSite.id = response.data.id;
      }
      if (!relatedSite) {
        leadData.relatedParty.push({
          name: response.data.siteName || response.data.name,
          id: response.data.id,
          '@type': 'site',
        });
      }
      leadData.id = leadData._id;
      if (response) {
        const patchLead = await UPCAxiosInstance.patch(`salesLead`, leadData);
        if (patchLead) {
          this.notification = {
            type: 'success',
            message:
              this.loadStaticData('LANG-0035') || `Site updated successfully`,
          };
        }
        if (patchLead.data) {
          let updatedAdditionalChargeLogPayload = {
            message: `${patchLead?.data?.name} site has been updated`,
            '@type': 'lead-site',
            detailedAction: [],
            category: ['lead'],
            createdBy: this.$cookies.get('user'),
            user: this.$cookies.get('userName'),
            lastUpdate: new Date().toISOString(),
            ref: `${this.$route.params.id}`,
            action: 'Site updated',
          };

          // Update lead initial and updated payload
          const initialLeadPayload = JSON.parse(JSON.stringify(initalSite));
          // delete initialLeadPayload.updatedAt
          if (initialLeadPayload.marketSegment == 'Greenfield') {
            delete initialLeadPayload.onboardingDate;
            initialLeadPayload.firstOccupancyDate = new Date(
              initialLeadPayload.firstOccupancyDate
            );
          } else {
            delete initialLeadPayload.firstOccupancyDate;
            initialLeadPayload.onboardingDate = new Date(
              initialLeadPayload.onboardingDate
            );
          }
          delete initialLeadPayload.createdAt;
          delete initialLeadPayload.lastUpdate;
          delete initialLeadPayload['@type'];
          let updatedLeadPayload = JSON.parse(JSON.stringify(updatedSite));
          // delete updatedLeadPayload.updatedAt
          if (updatedLeadPayload.marketSegment == 'Greenfield') {
            delete updatedLeadPayload.onboardingDate;
          } else {
            delete updatedLeadPayload.firstOccupancyDate;
          }
          delete updatedLeadPayload.createdAt;
          delete updatedLeadPayload.lastUpdate;
          delete updatedLeadPayload['@type'];
          console.log(updatedLeadPayload, 'dupSchemaProp', initialLeadPayload);
          let allDetailedAction = [];
          Object.keys(updatedLeadPayload).forEach((key) => {
            const orgInitialValue = JSON.parse(
              JSON.stringify(initialLeadPayload)
            );
            const updatedPayload = JSON.parse(
              JSON.stringify(updatedLeadPayload)
            );
            const initialValue =
              initialLeadPayload[key] !== undefined
                ? JSON.parse(JSON.stringify(initialLeadPayload[key]))
                : '';

            const currentValue =
              updatedLeadPayload[key] !== undefined
                ? JSON.parse(JSON.stringify(updatedLeadPayload[key]))
                : '';

            if (Array.isArray(currentValue)) {
              if (typeof currentValue[0] === 'object') {
                console.log(
                  currentValue,
                  'initialValue object',
                  initialValue,
                  key,
                  '=> type',
                  initialValue.type ? initialValue.type : initialValue['@type']
                );
                // Case: Array of objects
                if (key == 'contactMedium') {
                  console.log(initialValue, 'contactmedium', currentValue);
                  //  Site address
                  let currentValueForTelephone =
                    currentValue[0]?.characteristic;
                  let initialValueForTelephone =
                    initialValue[0]?.characteristic;
                  if (initialValueForTelephone && currentValueForTelephone) {
                    // telephone differences
                    // let phoneNumberActionLogCountry =
                    allDetailedAction = [
                      ...allDetailedAction,
                      this.buildBasicLogByDifferencesVersion2(
                        initialValueForTelephone,
                        currentValueForTelephone,
                        'description',
                        'Site address',
                        'Edited site',
                        initialLeadPayload?.displayID,
                      ),
                    ];
                  }
                  console.log(
                    currentValueForTelephone,
                    'For telephone',
                    initialValueForTelephone
                  );
                } else {
                  console.log(
                    initialValue[0],
                    'key for else key for else',
                    key
                  );
                  allDetailedAction = [
                    ...allDetailedAction,
                    this.buildBasicLogByDifferencesVersion2(
                      initialValue,
                      currentValue,
                      'value',
                      // initialValue?.[0].type ?  initialValue?.[0].type : initialValue?.[0]?.['@type'],
                      key,
                      'Edited site',
                      initialLeadPayload?.displayID,
                    ),
                  ];
                }
              } else if (typeof currentValue[0] === 'string') {
                // Case: Array of strings
                console.log(currentValue, 'initialValue string', initialValue);
                updatedAdditionalChargeLogPayload.detailedAction = [
                  ...updatedAdditionalChargeLogPayload.detailedAction,
                  ...this.findArrayOfStringDifferences(
                    initialValue,
                    updatedPayload,
                    key
                  ),
                ];
              }
            } else if (typeof orgInitialValue[key] == 'object') {
              // Default case: Handle basic log for non-array types
              console.log('key for elseelse', key);
              allDetailedAction = [
                ...allDetailedAction,
                ...this.buildBasicLogByDifferencesVersion2(
                  orgInitialValue[key],
                  updatedPayload[key],
                  'value',
                  key,
                  'Edited site',
                  initialLeadPayload?.displayID,
                ),
              ];
            } else {
              // Default case: Handle basic log for non-array types
              console.log('key for else', key);
              allDetailedAction = [
                ...allDetailedAction,
                ...this.buildBasicLogByDifferencesVersion2(
                  orgInitialValue,
                  updatedPayload,
                  key,
                  key,
                  'Edited site',
                  initialLeadPayload?.displayID,
                ),
              ];
            }
            console.log(
              initialValue,
              'updatedAdditionalChargeLogPayload',
              updatedAdditionalChargeLogPayload,
              currentValue
            );
          });
          allDetailedAction = this.removeEmptyEntries(allDetailedAction);
          // this.initLog(updatedAdditionalChargeLogPayload);
          const result = allDetailedAction.map((item) => ({
            ...updatedAdditionalChargeLogPayload,
            detailedAction: [item],
          }));
          let response = await UPCAxiosInstance.post('/activityLog/v1', result);
          console.log('response', response);
        }
        console.log(patchLead, 'Lead patched response');
        this.leadOrginalData = patchLead.data;
        this.leadData = { ...patchLead.data };
      }
    },
    async addNewSitePayload(leadData, siteData) {
      // Add new site
      console.log(siteData, "addNewSitePayload", leadData);
      if (Array.isArray(siteData.contactMedium)) {
        siteData.contactMedium = siteData.contactMedium.map((medium) => {
        return { ...medium, mediumType: 'postalAddress' };
    });
}
siteData.contactId = this.leadData?.accountRef
      try {
        const response = await UPCAxiosInstance.post(`geoSite`, siteData);
        const siteDataResponse = response?.data;
        let siteDisplatId = response?.data?.displayID;
        console.log(siteDisplatId, 'Response for site:', response.data);
        const getContactCharacteristic = (key) =>
        siteDataResponse?.contactMedium?.[0]?.characteristic?.[key] ?? '';
        const relatedSite = leadData.relatedParty.find(
          (obj) =>
            (obj.name === response.data.siteName ||
              obj.name === response.data.name) &&
            obj['@type'] === 'site'
        );
        const siteDetails = {
    name: siteDataResponse?.siteName || siteDataResponse?.name,
    id: siteDataResponse?.id,
    '@type': 'site',
    street: getContactCharacteristic('street'),
    city: getContactCharacteristic('city'),
    province: getContactCharacteristic('stateOrProvince'),
    postalCode: getContactCharacteristic('postalCode'),
    postalCodePrefix: getContactCharacteristic('postalCodePrefix'),
    country: getContactCharacteristic('country'),
    latitude: getContactCharacteristic('latitude'),
    longitude: getContactCharacteristic('longitude'),
    networkId: siteDataResponse?.networkId ?? '',
    networkManager: siteDataResponse?.networkManager ?? '',
  };

  if (relatedSite) {
    console.log('relatedSite', relatedSite);
    Object.assign(relatedSite, siteDetails);
  } else {
    leadData.relatedParty.push(siteDetails);
  }
        const updatedLeadData = {
          ...leadData,
          externalReference: leadData.externalReference.map((ref) => {
            if (ref.type.toLowerCase() === 'numberofsites') {
              return {
                ...ref,
                value: Number(ref.value || 0) + 1,
              };
            }
            return ref;
          }),
        };
        
        console.log('updatedLeadData', updatedLeadData);
        delete updatedLeadData._id;
        updatedLeadData.id = leadData._id;
        if (response) {
          const patchLead = await UPCAxiosInstance.patch(
            `salesLead`,
            updatedLeadData
        );
          console.log(patchLead, 'Lead patched response');
          if (patchLead) {
            this.notification = {
              type: 'success',
              message:
                this.loadStaticData('LANG-0036') || `Site added successfully`,
            };
          }
          this.leadOrginalData = patchLead.data;
          this.leadData = { ...patchLead.data };
          if (patchLead) {
            const leadCreationActivityLogPayload = {
              message: `${siteData.name} site has been created`,
              detailedAction: [
                {
                  action: 'Created site',
                  from: '',
                  to: siteData.name,
                  property: 'Site',
                  displayID: siteDisplatId,
                },
              ],
              action: 'site created',
              user: this.$cookies.get('userName'),
              lastUpdate: new Date().toISOString(),
              createdBy: this.$cookies.get('user'),
              ref: [this.$route.params.id],
              '@type': 'lead-site-creation',
              category: ['lead'],
            };
            let response = await UPCAxiosInstance.post(
              '/activityLog/v1',
              leadCreationActivityLogPayload
            );
            console.log(response);
            // this.initLog(leadCreationActivityLogPayload);
          }
        }
      } catch (error) {
        console.error('Error patching site details:', error);
      }
    },
    async patchEditedLead(val) {
      let dupVal = { ...val };
      // to concat and remove duplicates if it existed
      let leadOwner = this.leadOrgRelatedParty.filter(
        (item) => item['@type'] !== 'leadOwner'
      );
      val.relatedParty = [
        ...new Map(
          [...val.relatedParty, ...leadOwner].map((item) => [
            item.id || JSON.stringify(item),
            item,
          ])
        ).values(),
      ];
      val.metadata = this.metaData;
      console.log(
        dupVal,
        'patchEditedLead',
        val,
        'this.leadOrgRelatedParty',
        this.schemaProp,
        this.leadOrgRelatedParty
      );
      this.isSaveButtonClicked = false;
      this.temporaryLeadOverviewPayload = JSON.parse(JSON.stringify(val));
      this.completePayload = JSON.parse(JSON.stringify(val));
      // const response = await UPCAxiosInstance.patch(`/salesLead`, val);
      // console.log('response', response);
      // const getUpdatedData = await UPCAxiosInstance.get(
      //   `/salesLead/${this.$route.params.id}`
      // );
      // this.leadOrginalData = getUpdatedData.data;
    },
    async saveEditedLead(status) {
      if(status == 'converted'){
        this.temporaryLeadOverviewPayload.status = 'Converted'
      }
      let allDetailedAction = [];
      let currentDate = new Date().toJSON();
      console.log(this.temporaryLeadOverviewPayload.status,"this.temporaryLeadOverviewPayload",this.temporaryLeadOverviewPayload)
      this.temporaryLeadOverviewPayload.type =
      this.temporaryLeadOverviewPayload.leadType;
      delete this.temporaryLeadOverviewPayload.leadType;
      delete this.temporaryLeadOverviewPayload?._id
      this.temporaryLeadOverviewPayload.tradingName = this.temporaryLeadOverviewPayload?.name
      this.temporaryLeadOverviewPayload.lastUpdate = currentDate;
      if(this.temporaryLeadOverviewPayload.status == 'Converted'){
        let dupData = JSON.parse(JSON.stringify(this.temporaryLeadOverviewPayload))
        let relatedPartyDuplicate = JSON.parse(JSON.stringify(this.temporaryLeadOverviewPayload.relatedParty))
        let sitesDataInPayload = relatedPartyDuplicate.filter(
              (ele) => ele['@type'] == 'site' || ele['@referredType'] == 'site'
            );
            console.log('sitesDataInPayload', sitesDataInPayload);
            let siteIds = sitesDataInPayload.map((site) =>
              site.id ? site.id : site.siteId
            );
            let getAllSites = await UPCAxiosInstance.get('/geoSite');
            let sitesTableData = []
            if (getAllSites) {
               sitesTableData = getAllSites.data.filter((ele) =>
                siteIds.includes(ele.id)
              );
            }
              // sitesTableData.map((ele)=>delete ele._id)
              sitesTableData = sitesTableData.map((ele) => {
              delete ele._id;
              ele['@type'] = 'site';
              return ele;
            });

            let leadRelatedPartyData = JSON.parse(JSON.stringify(this.temporaryLeadOverviewPayload.relatedParty))
            leadRelatedPartyData  =   leadRelatedPartyData.filter(ele => ele['@type'] != 'site')
            leadRelatedPartyData  = leadRelatedPartyData.concat(sitesTableData)
            dupData.relatedParty = JSON.parse(JSON.stringify(leadRelatedPartyData))  
            if(this.selectedLeadName){
        console.log("selectedLeadName",this.selectedLeadName)
        if((this.selectedLeadName == this.temporaryLeadOverviewPayload.name) && (this.selectedLeadName != this.leadOrginalData.name)){
          if(this.selectedLeadAccountRef){
          this.temporaryLeadOverviewPayload.accountRef = this.selectedLeadAccountRef 
          }
          // else{
          //   delete this.temporaryLeadOverviewPayload.accountRef
          // }
         let removeContact = this.temporaryLeadOverviewPayload.relatedParty.filter(ele=>ele['@type'] != 'individual')
         this.temporaryLeadOverviewPayload.relatedParty = JSON.parse(JSON.stringify(removeContact))
        }
      }
      delete dupData._id
      // dupData.accountRef =  this.temporaryLeadOverviewPayload.accountRef
        const response = await UPCAxiosInstance.patch(
        `/salesLead/v1`,
        dupData
      );
      console.log(response,dupData,this.temporaryLeadOverviewPayload,"leadRelatedPartyData",leadRelatedPartyData,sitesTableData)
    }
    console.log(
      currentDate,
      'this.temporaryLeadOverviewPayload',
      this.temporaryLeadOverviewPayload.lastUpdate
    );
    if(this.selectedLeadName){
        console.log(this.selectedLeadAccountRef,"selectedLeadName",this.selectedLeadName)
        if((this.selectedLeadName == this.temporaryLeadOverviewPayload.name) && (this.selectedLeadName != this.leadOrginalData.name)){
          if(this.selectedLeadAccountRef){
          this.temporaryLeadOverviewPayload.accountRef = this.selectedLeadAccountRef 
          }
          else{
            delete this.temporaryLeadOverviewPayload.accountRef
          }
         let removeContact = this.temporaryLeadOverviewPayload.relatedParty.filter(ele=>ele['@type'] != 'individual')
         this.temporaryLeadOverviewPayload.relatedParty = JSON.parse(JSON.stringify(removeContact))


         
         let siteDetails = JSON.parse(JSON.stringify(this.temporaryLeadOverviewPayload));

let sitesDataInPayload = siteDetails.relatedParty.filter(
  (ele) => ele['@type'] == 'site' || ele['@referredType'] == 'site'
);
console.log('sitesDataInPayload', sitesDataInPayload);
let siteIds = sitesDataInPayload.map((site) =>
  site.id ? site.id : site.siteId
);
//  Add reference id to all sites
let getAllSitesData = await UPCAxiosInstance.get('/geoSite');
if (getAllSitesData) {
  let sitesTableData = getAllSitesData?.data?.filter((ele) =>
    siteIds.includes(ele?.id)
  );
  console.log(getAllSitesData, 'sitesTableData', sitesTableData);

  const requests = sitesTableData.map((site) => {
    const siteCopy = { ...site };
    delete siteCopy._id;
    siteCopy.contactId = this.selectedLeadAccountRef ;

    return UPCAxiosInstance.patch(`geoSite`, siteCopy);
  });

  try {
    const responses = await Promise.all(requests);
    console.log('All sites updated successfully:', responses);
  } catch (error) {
    console.error('Error updating one or more sites:', error);
  }
}


        }
        console.log(this.temporaryLeadOverviewPayload,this.selectedLeadAccountRef,)
      }
      if(!this.selectedLeadName && (this.leadOrginalData.name != this.temporaryLeadOverviewPayload.name)){
        // delete this.temporaryLeadOverviewPayload.accountRef 
        let removeContact = this.temporaryLeadOverviewPayload.relatedParty.filter(ele=>ele['@type'] != 'individual')
        this.temporaryLeadOverviewPayload.relatedParty = JSON.parse(JSON.stringify(removeContact))
        this.newAccountIsCreated = true
      }
    if(this.temporaryLeadOverviewPayload.status != 'Converted') {
      delete this.temporaryLeadOverviewPayload._id
    this.temporaryLeadOverviewPayload.accountRef
      const response = await UPCAxiosInstance.patch(
        `/salesLead/v1`,
        this.temporaryLeadOverviewPayload
      );
      console.log('response', response);
      if(this.newAccountIsCreated){
        let siteDetails = JSON.parse(JSON.stringify(response?.data));

let sitesDataInPayload = siteDetails.relatedParty.filter(
  (ele) => ele['@type'] == 'site' || ele['@referredType'] == 'site'
);
console.log('sitesDataInPayload', sitesDataInPayload);
let siteIds = sitesDataInPayload.map((site) =>
  site.id ? site.id : site.siteId
);
//  Add reference id to all sites
let getAllSitesData = await UPCAxiosInstance.get('/geoSite');
if (getAllSitesData) {
  let sitesTableData = getAllSitesData?.data?.filter((ele) =>
    siteIds.includes(ele?.id)
  );
  console.log(getAllSitesData, 'sitesTableData', sitesTableData);

  const requests = sitesTableData.map((site) => {
    const siteCopy = { ...site };
    delete siteCopy._id;
    siteCopy.contactId = response?.data?.accountRef ;

    return UPCAxiosInstance.patch(`geoSite`, siteCopy);
  });

  try {
    const responses = await Promise.all(requests);
    console.log('All sites updated successfully:', responses);
    if(responses){
      this.newAccountIsCreated = false
    }
  } catch (error) {
    console.error('Error updating one or more sites:', error);
  }
}
      }
      }
      // let dupAccountData = JSON.parse(JSON.stringify(this.temporaryLeadOverviewPayload))
      // dupAccountData['@baseType'] = 'lead'
      // dupAccountData.leadRef = dupAccountData?.id
      // dupAccountData.type ="company"
      // dupAccountData.tradingName = 
      // dupAccountData.tradingName = dupAccountData?.name
      // delete dupAccountData._id
      // let result = await MLTAxiosInstance.post(
      //     `/partymanagement/individual/signup`,
      //     dupAccountData
      //   );
        // console.log("result",result)
      const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      if (getUpdatedData.data) {
        this.notification = {
          type: 'success',
          message: this.matchFromStatic('Lead details updated successfully!'),
        };
      }
      if (getUpdatedData.data) {
        let updatedAdditionalChargeLogPayload = {
          message: `${getUpdatedData?.data?.name} lead has been updated`,
          '@type': 'lead-overview',
          detailedAction: [],
          category: ['lead'],
          createdBy: this.$cookies.get('user'),
          user: this.$cookies.get('userName'),
          lastUpdate: new Date().toISOString(),
          ref: `${this.$route.params.id}`,
          action: 'Edited overview',
        };

        // Update lead initial and updated payload
        const initialLeadPayload = { ...this.schemaProp };
        delete initialLeadPayload.updatedAt;
        delete initialLeadPayload.createdAt;
        delete initialLeadPayload.lastUpdate;
        delete initialLeadPayload?.leadRef
        delete initialLeadPayload?._id
        let updatedLeadPayload = this.temporaryLeadOverviewPayload;
        updatedLeadPayload.relatedParty =
          updatedLeadPayload.relatedParty.filter(
            (party) =>
              party['@type'] === 'leadOwner' || party['@type'] === 'leadSource'
          );
        console.log('updatedLeadPayload', updatedLeadPayload);
        updatedLeadPayload.leadType = updatedLeadPayload.type;
        delete updatedLeadPayload.type;
        getUpdatedData.data.leadType = getUpdatedData?.data?.type;
        delete getUpdatedData?.data?.type;
        delete updatedLeadPayload.updatedAt;
        delete updatedLeadPayload.createdAt;
        delete updatedLeadPayload.lastUpdate;
        delete updatedLeadPayload?.leadRef
        delete updatedLeadPayload?._id
        console.log(updatedLeadPayload, 'dupSchemaProp', initialLeadPayload);

        Object.keys(getUpdatedData?.data).forEach((key) => {
          const orgInitialValue = JSON.parse(JSON.stringify(initialLeadPayload));
          const updatedPayload = JSON.parse(JSON.stringify(updatedLeadPayload));
          if(!orgInitialValue?.contactMedium?.length > 0 && !updatedPayload?.contactMedium?.length > 0)
        {
          delete orgInitialValue?.contactMedium
          delete updatedPayload?.contactMedium
        }
        if(!orgInitialValue?.note?.length > 0 && !updatedPayload?.note?.length > 0)
        {
          delete orgInitialValue?.note
          delete updatedPayload?.note
        }
        if(!orgInitialValue?.attachment?.length > 0 && !updatedPayload?.attachment?.length > 0)
        {
          delete orgInitialValue?.attachment
          delete updatedPayload?.attachment
        }
          const initialValue = initialLeadPayload[key];
          const currentValue = updatedLeadPayload[key];

          if (Array.isArray(currentValue) && currentValue.length > 0) {
            if (
              typeof currentValue[0] === 'object' ||
              typeof initialValue[0] === 'object'
            ) {
              // console.log(key,currentValue,"initialValue object",initialValue,key,"=> type",initialValue.type ?  initialValue.type : initialValue['@type'])
              // Case: Array of objects
              if (key?.toLowerCase() !== 'attachment') {
                let currentValueDup = JSON.parse(JSON.stringify(currentValue));
                // Use map to process each item in the array
                let detailedActions = currentValueDup.map((item, i) => {
                  return this.buildBasicLogByDifferencesVersion2(
                    initialValue[i],
                    currentValue[i],
                    'value',
                    currentValue[i]?.['@type'] === 'leadOwner'
    ? 'leadOwner'
    : currentValue[i]?.type
    ? currentValue[i].type
    : currentValue[i]?.['@type'] ,
                    'Edited overview',
                    getUpdatedData?.data?.displayID
                  );
                });
                console.log(
                  initialValue?.[0]?.type
                    ? initialValue?.[0]?.type
                    : initialValue?.[0]?.['@type']
                );
                console.log(
                  initialValue[0],
                  'detailedActions = >',
                  detailedActions,
                  currentValue[0]
                );
                // Append the actions to the detailedAction array
                allDetailedAction = [...allDetailedAction, ...detailedActions];
                detailedActions = detailedActions.flat();
                console.log('detailedActions = > end', detailedActions);
              } else {
                // console.log( initialValue?.[0]?.name,"currentValue[0].name",currentValue?.[0]?.name)
                if (initialValue && initialValue?.length > 0) {
                  console.log(
                    initialValue,
                    'currentValue value',
                    currentValue[0]
                  );
                  allDetailedAction = [
                    ...allDetailedAction,
                    ...this.buildBasicLogByDifferencesVersion2(
                      initialValue[0],
                      currentValue[0] ? currentValue[0] : [],
                      'name',
                      'Attachment',
                      'Edited overview',
                      getUpdatedData?.data?.displayID
                    ),
                  ];
                } else {
                  console.log('currentValue', currentValue[0]);
                  allDetailedAction = [
                    ...allDetailedAction,
                    ...this.buildBasicLogByDifferencesVersion2(
                      [],
                      currentValue[0] ? currentValue[0] : [],
                      'name',
                      'Attachment',
                      'Edited overview',
                      getUpdatedData?.data?.displayID
                    ),
                  ];
                }
              }
            } else if (typeof currentValue[0] === 'string') {
              // Case: Array of strings
              console.log(currentValue, 'initialValue string', initialValue);
              allDetailedAction = [
                ...allDetailedAction,
                ...this.findArrayOfStringDifferences(
                  initialValue,
                  updatedPayload,
                  key
                ),
              ];
            }
          } else {
            // Default case: Handle basic log for non-array types
            console.log(getUpdatedData?.data?.displayID, ' displayID');
            allDetailedAction = [
              ...allDetailedAction,
              ...this.buildBasicLogByDifferencesVersion2(
                orgInitialValue,
                updatedPayload,
                key,
                key == 'dwellingtype' ? 'Dwelling type' : key,
                'Edited overview',
                getUpdatedData?.data?.displayID
              ),
            ];
            console.log(
              updatedPayload,
              'orgInitialValue',
              orgInitialValue,
              key
            );
          }
          console.log(
            initialValue,
            'updatedAdditionalChargeLogPayload',
            updatedAdditionalChargeLogPayload,
            currentValue
          );
        });
        allDetailedAction = this.removeEmptyEntries(allDetailedAction);
        // this.initLog(updatedAdditionalChargeLogPayload);
        console.log('allDetailedAction', allDetailedAction);
        const result = allDetailedAction.map((item) => ({
          ...updatedAdditionalChargeLogPayload,
          detailedAction: [item],
        }));
        console.log(result, 'Update payload for activity log');
        let response = await UPCAxiosInstance.post('/activityLog/v1', result);
        console.log('response', response);
        // {{UPCBaseURL}}/activityLog/v1
      }
      this.leadOrginalData = getUpdatedData.data;
      this.leadData = getUpdatedData.data;
      this.isSaveButtonClicked = true;
      this.view = false;
      if(this.temporaryLeadOverviewPayload.status == 'Converted'){
        this.convertToAccountAndCreateQuote()
      }
    },
    leadStatus(val) {
      this.leadPayload.status = val?.name;
    },
    removeEmptyEntries(input) {
      return input
        .flatMap((item) => {
          if (Array.isArray(item)) {
            return this.removeEmptyEntries(item);
          }

          if (typeof item === 'object' && item !== null) {
            return item;
          }
          return [];
        })
        .filter((item) => Object.keys(item).length > 0); // Remove empty objects
    },
    async selectedValueFunc(val, object) {
      this.leftpannel = val;
      if (this.$route.query.access === 'view') {
        this.permisstionData = {
          view: object.view,
          add: false,
          edit: false,
          delete: false,
        };
      } else if (this.$route.query.access === 'edit') {
        this.permisstionData = {
          view: object.view,
          add: object.add,
          edit: object.edit,
          delete: false,
        };
      } else {
        this.permisstionData = object;
      }
      console.log(val, object, 'objectobjectobject', this.permisstionData);
      if (this.permisstionData.edit === false || !this.permisstionData) {
        this.ViewMode = true;
      } else {
        this.ViewMode = false;
      }
      this.selectedValue = val;
      if (this.selectedValue.toLowerCase() == 'activity log') {
        let activitylogdata = await MLTAxiosInstance.post(
          `util/filterData`,
          this.offerActivity
        );
        console.log(activitylogdata, 'activity log response data');

        this.logData = activitylogdata.data.data;
        this.activitytotal = activitylogdata.data.total;
      }
      if (this.selectedValue.toLowerCase() !== 'overview') {
        if (
          Array.isArray(this.attachmentData) &&
          this.attachmentData.length > 0
        ) {
          const profileInfo = this.attachmentData?.find(
            (ele) =>
              ele?.['@type'] === 'profile' ||
              ele?.['attachmentType'] === 'profile'
          );
          if (
            this.profilePicUrl == '' ||
            this.profilePicUrl == null ||
            this.profilePicUrl == undefined
          ) {
            if (
              profileInfo?.name != null &&
              profileInfo?.name != undefined &&
              profileInfo?.name != ''
            ) {
              const url = await UPCAxiosInstance.get(
                `/gcpupload/files/${profileInfo.name}`,
                { responseType: 'arraybuffer' }
              );
              this.profilePicUrl = await this.bufferToDataURL(url.data);
              console.log('obj.url obj.url', url.data);
              console.log(this.profilePicUrl, 'this.profilePicUrlBufferData');
            }
          }
        }
      }
      if (this.view == true) {
        this.view = false;
      }
    },
    goTo() {
      this.$router.push('/MDU/lead');
    },
  },
};
</script>

<style lang="scss">
.button-container-lead-overview {
  position: relative;
  display: inline-block;
}
.lead-overview-dropdown {
  border-radius: 4px;
  font-family: Opensans;
  width: max-content;
  font-size: 14px;
  margin-top: 12px;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
}
.parent-container-for-lead-overview {
  .form-container {
    padding-left: 0px !important;
  }
}
</style>
