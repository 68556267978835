<template>
  <div style="height:40px;width:40px;border:2px red solid" @click="updateLabels()"></div>
  <!-- <span class="schema-header-title">
                  {{staticData.leftPanel.unifiedProductCatalog.category.title.value}}</span>
                <span class="subtitle">
                  {{ staticData.leftPanel.unifiedProductCatalog.category.subTitle.value }}
                </span> -->
                <hlx-status
                    :type="'select-status'"
                    :status-option="filtered_data"
                    :prevalue="'active'"
                    @emit-select="emitStatusAcc"
                  >
                  </hlx-status>
  <div class="unique-task-list-container">
    <!-- Language Selection Dropdown -->
    <div class="unique-task-list-language-selector">
      <label>{{ translatedTexts.SelectLanguage }}:</label>
      <select v-model="selectedLanguage" @change="changeLanguage">
        <option value="en">English</option>
        <option value="es">Spanish</option>
        <option value="fr">French</option>
        <option value="it">Italian</option>
        <option value="ar">Arabic</option>
        <option value="hi">Hindi</option>
        <option value="ta">Tamil</option>
      </select>
    </div>

    <h2>{{ translatedTexts.title }}</h2>

    <input
      v-model="newTask"
      type="text"
      :placeholder="translatedTexts.addTaskPlaceholder"
      class="unique-task-list-input"
      @keyup.enter="addTask"
    />

    <!-- Conditionally Render Button Text -->
    <button class="unique-task-list-button" @click="addTask">
      {{ editedTaskIndex === -1 ? translatedTexts.addTaskButton : translatedTexts.saveTaskButton }}
    </button>

    <!-- Clear completed tasks button -->
    <button class="unique-task-list-button" @click="clearCompletedTasks">
      {{ translatedTexts.clearCompletedButton }}
    </button>

    <ul class="unique-task-list-items">
      <li
        v-for="(task, index) in tasks"
        :key="index"
        :class="{ 'unique-task-list-completed': task.completed }"
        class="unique-task-list-item"
      >
        <span @click="toggleTask(index)">
          {{ task.name }}
        </span>
        <button class="unique-task-list-button remove" @click="removeTask(index)">
          {{ translatedTexts.removeTaskButton }}
        </button>
        <button class="unique-task-list-button" @click="editTask(index)">
          {{ translatedTexts.editTaskButton }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { updateSchemaLabels } from '@/language/updateSchemaWithLang2';
// import { schemaData1 } from '@/language/schemaOff';
import {
  DMNAxiosInstance,
  // MLTAxiosInstance,
  // UPCAxiosInstance,
} from "@/config/axiosConfig";
// import axios from 'axios';
// import { translateTexts } from '@/language/translationService.js';
export default {
  name: "TaskList",
  data() {
    return {
      schemaData:require(`@/language/schemaOff.js`).default,
      preferredLanguages: [
        { code: "fr", name: "French" },
        { code: "es", name: "Spanish" },
      ],
      filtered_data:[],
      newTask: "", // Holds the input for the new task
      tasks: JSON.parse(localStorage.getItem('tasks')) || [], // Retrieve tasks from localStorage if available
      selectedLanguage: "en", // Default language
      editedTaskIndex: -1, // Index of the task currently being edited
      translatedTexts: {
        SelectLanguage: "Select Language",
        title: "My Task List",
        addTaskPlaceholder: "Add a new task",
        addTaskButton: "Add Task",
        saveTaskButton: "Save Task",
        removeTaskButton: "Remove",
        editTaskButton: "Edit",
        clearCompletedButton: "Clear Completed",
      },
    };
  },
  computed: {
    staticData() {
      const storedData = JSON.parse(localStorage.getItem('staticDataLanguage'));
      console.log("language 111 staticData",storedData);
      
      if(storedData){
        return storedData
      }else{
        return require(`@/language/defaultLanguage.js`).default.leftPanel.unifiedProductCatalog.category;
      }
    },
  },
  created() {
    this.changeLanguage(); // Initialize language based on default
  },
  async mounted() {
    console.log("this.schemaData_this.preferredLanguages_this.apiKey",this.schemaData);
    
    console.log("111111111111111_this.schemaData",this.$store.getters.defaultSetting);
    await this.$store.dispatch("loadDefaultSettings");
    let currLang = await this.$store.getters.defaultSetting.currentLanguage;
    console.log("111111111111111",this.$store.getters.defaultSetting,currLang);
    
    let statusList = await DMNAxiosInstance.get(`mapRef/collection/demoStatus`);
    console.log("111111111111111", statusList);
    // if()
    let lang = currLang.toLowerCase()
    // this.filtered_data = [{"english": item["english"]} for item in statusList.data.data if "english" in item]
    this.filtered_data
    let r = statusList.data.data.filter(item => item[lang]);  // Filter items that have the key stored in 'lang'
    console.log("111111111111111 2222", r);
    let w = r.map(item => ({ value: item[lang], name: item[lang] }));
    this.filtered_data = w

      // this.filteredstatus = statusList.data.data;
      console.log("111111111111111",w,this.filtered_data);
      // filteredstatus.filter(itm => itm)
    // this.filteredstatus = await this.getReferanceData('statuslist').filter(
    //   (item) => item.name === "Active" || item.name === "Inactive"
    // let rq = await this.updateLangSchemaByModule("saleslead")
    // console.log("r:::::q:::",rq);
    // this.patchAllSchema();
    // this.patchAllReference();
    
    // );
  },
  methods: {
    emitStatusAcc() { },
    async updateLabels() {
      console.log("this.schemaData_this.preferredLanguages_this.apiKey",this.schemaData,
          this.preferredLanguages,
          this.apiKey);
      
      try {
        const updatedSchema = await updateSchemaLabels(
          this.schemaData,
          this.preferredLanguages
        );
        console.log("Updated Schema:", updatedSchema);
      } catch (error) {
        console.error("Error updating labels:", error);
      }
    },
    addTask() {
      if (this.newTask.trim() === "") return;

      if (this.editedTaskIndex === -1) {
        // Add new task
        this.tasks.push({ name: this.newTask, completed: false });
      } else {
        // Edit existing task
        this.tasks[this.editedTaskIndex].name = this.newTask;
        this.editedTaskIndex = -1; // Reset the edit mode after saving
      }

      this.newTask = ""; // Clear the input field after adding or editing
      this.saveTasks();
    },
    toggleTask(index) {
      this.tasks[index].completed = !this.tasks[index].completed;
      this.saveTasks();
    },
    removeTask(index) {
      this.tasks.splice(index, 1);
      this.saveTasks();
    },
    editTask(index) {
      this.newTask = this.tasks[index].name; // Populate the input field with the task name
      this.editedTaskIndex = index; // Set the index for the task being edited
    },
    clearCompletedTasks() {
      this.tasks = this.tasks.filter((task) => !task.completed);
      this.saveTasks();
    },
    saveTasks() {
      // Save the updated tasks array to localStorage
      localStorage.setItem('tasks', JSON.stringify(this.tasks));
    },
    async changeLanguage() {
      console.log("language 111 changeLanguage");
      if (this.selectedLanguage === "en") {
        // Reset to default texts for English
        this.translatedTexts = {
          SelectLanguage: "Select Language",
          title: "My Task List",
          addTaskPlaceholder: "Add a new task",
          addTaskButton: "Add Task",
          saveTaskButton: "Save Task",
          removeTaskButton: "Remove",
          editTaskButton: "Edit",
          clearCompletedButton: "Clear Completed",
        };
      } else {
        // Translate texts for selected language
        console.log("language 111 else");
        
        const textsToTranslate = Object.values(this.translatedTexts);
        const translatedTextsArray = await this.languagetranslateTexts(
          textsToTranslate,
          this.selectedLanguage
        );

        // Directly assign the translated values to `translatedTexts`
        const keys = Object.keys(this.translatedTexts);
        keys.forEach((key, index) => {
          this.translatedTexts[key] = translatedTextsArray[index];
        });
      }
    },
    // async translateTexts(texts, targetLang) {
    //   try {
    //     const response = await axios.post(
    //     `https://translation.googleapis.com/language/translate/v2?key=${process.env.VUE_APP_TRANSLATE_KEY}`,
    //       {
    //         q: texts, // The array of strings to be translated
    //         target: targetLang, // Target language code (e.g., "es" for Spanish)
    //       }
    //     );

    //     console.log("language 111 translateTexts",response.data.data.translations.map((t) => t.translatedText));
        

    //     return response.data.data.translations.map((t) => t.translatedText);
    //   } catch (error) {
    //     console.error("Translation error:", error);
    //     return texts; // Fallback to original text if translation fails
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.unique-task-list-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: white; /* White background for the main container */
  border: 1px solid #54bd95; /* Green border */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for the container */
}

.unique-task-list-language-selector {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  label {
    margin-right: 8px;
    color: #333; /* Dark color for label text */
  }

  select {
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fafafa;
    color: #333;
  }
}

.unique-task-list-input {
  width: calc(100% - 100px);
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #f7f7f7; /* Light background for input field */
  color: #333; /* Dark text color for readability */
}

/* Style for the "Remove" button only */
.unique-task-list-item .unique-task-list-button.remove {
  margin-left: 8px;
  padding: 8px 12px;
  background-color: transparent; /* Transparent background */
  color: #54bd95; /* Green text */
  border: 1px solid #54bd95; /* Green border */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #54bd95; /* Green background on hover */
    color: white; /* White text when hovered */
  }
}

/* Previous style for all other buttons */
.unique-task-list-item .unique-task-list-button {
  margin-left: 8px;
  padding: 8px 12px;
  background-color: #54bd95; /* Green background */
  color: white; /* White text */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darkgreen; /* Darker green background on hover */
  }
}

.unique-task-list-items {
  list-style: none;
  padding: 0;

  .unique-task-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin: 4px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9; /* Light grey background for each task */
    cursor: pointer;
    transition: background-color 0.3s ease;

    &.unique-task-list-completed {
      background-color: #e0e0e0; /* Slightly darker background for completed tasks */
    }

    &:hover {
      background-color: #f0f0f0; /* Slightly darker hover effect */
    }

    span {
      color: #333; /* Dark text color for task name */
    }
  }
}
</style>
