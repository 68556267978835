<template>
  <div class="condition-container-emailTriggers">
    <table class="condition-table-emailTriggers">
      <thead>
        <tr>
          <th style="width: 10%">Condition</th>
          <th style="width: 25%"></th>
          <!-- <th style="width: 2%"></th> -->
          <th style="width: 35%"></th>
          <th style="width: 30%"></th>
          <th style="width: 5%"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(ruleItem, index) in visibilityRulePayload"
          :key="index"
          class="condition-row-emailTriggers"
        >
          <td>
            <template v-if="index === 0">
              <!-- Display plain text 'When' for the first row with centered text -->
              <div class="condition-text">{{ "When" }}</div>
            </template>
            <template v-else>
              <!-- Render hlx-select for other rows -->
              <hlx-select
                :key="rolecomponentkey"
                :required="false"
                :options="operatorList"
                :pre-value="ruleItem.conditionType"
                prop-value="name"
                :label="'name'"
                :placeholder-value="''"
                :label-animation="true"
                :clearable="false"
                @selected-value="
                  updateOperatorList(index, 'conditionType', $event.name)
                "
              ></hlx-select>
            </template>
          </td>

          <td>
            <hlx-select
              :key="rolecomponentkey"
              v-model:value="selectedIntellisenseValue"
              :pre-value="ruleItem.intellisenseName"
              :required="false"
              :options="intellisenseList"
              :inline-search="true"
              prop-value="label"
              :label="'label'"
              :placeholder-value="''"
              :label-animation="true"
              :clearable="false"
              @selected-value="selectedFieldItem($event, ruleItem, index)"
              @search-value="searchHAccount"
              @dropdownopen="searchHAccount"
            ></hlx-select>
          </td>
          <!-- <td class="text-center">is</td> -->
          <td :colspan="selectedtyplevalue === 'array' ? 3 : 0">
            <div
              v-if="selectedtyplevalue === 'array'"
              style="align-items: center; display: flex; gap: 15px"
            >
              <div style="width: 25%">
                <hlx-select
                  v-model:value="ruleItem.operator"
                  :prop-value="'value'"
                  :options="getVisibilityRuleOperatorTypeOptions(ruleItem)"
                  :pre-value="
                    getVisibilityRuleOperatorTypeOptions(ruleItem).some(
                      (item) => item.value == ruleItem?.operator
                    )
                      ? ruleItem.operator
                      : (getVisibilityRuleOperatorTypeOptions(ruleItem)[0]
                          ?.value,
                        (ruleItem.operator =
                          getVisibilityRuleOperatorTypeOptions(
                            ruleItem
                          )[0]?.value),
                        (ruleItem.showType =
                          getVisibilityRuleOperatorTypeOptions(
                            ruleItem
                          )[0]?.show))
                  "
                  @selected-value="selectedOperatorObject($event, ruleItem)"
                ></hlx-select>
              </div>
              <div style="width: 25%">
                <hlx-multi-select
                
                :prop-value="'name'"
                placeholder-value="Values"
             
                :tooltipposition="'left'"
                @selected-array="selectedValues($event, ruleItem)"
              ></hlx-multi-select>
              </div>
              <div>=</div>
              <div style="width: 25%">
                <hlx-select
                  v-model:value="ruleItem.appliesTo"
                  :prop-value="'value'"
                  :options="appliesList"
                  :pre-value="ruleItem.appliesTo"
                  @selected-value="selectedappliesTo($event, ruleItem)"
                ></hlx-select>
              </div>
              <div>{{ ruleItem.intellisenseName }}</div>
            </div>
            <div v-if="selectedtyplevalue != 'array'">
              <hlx-select
                v-model:value="ruleItem.operator"
                :prop-value="'value'"
                :options="getVisibilityRuleOperatorTypeOptions(ruleItem)"
                :pre-value="
                  getVisibilityRuleOperatorTypeOptions(ruleItem).some(
                    (item) => item.value == ruleItem?.operator
                  )
                    ? ruleItem.operator
                    : (getVisibilityRuleOperatorTypeOptions(ruleItem)[0]?.value,
                      (ruleItem.operator =
                        getVisibilityRuleOperatorTypeOptions(
                          ruleItem
                        )[0]?.value),
                      (ruleItem.showType =
                        getVisibilityRuleOperatorTypeOptions(
                          ruleItem
                        )[0]?.show))
                "
                @selected-value="selectedOperatorObject($event, ruleItem)"
              ></hlx-select>
            </div>
          </td>
          <td v-if="selectedtyplevalue != 'array'">
            <span
              v-if="ruleItem.showType !== ''"
              style="display: grid; width: 100%"
            >
              <hlx-multi-select
                v-if="ruleItem.showType == 'dropdown'"
                :prop-value="'name'"
                placeholder-value="Values"
                :options="ruleItem.options"
                :pre-value="ruleItem.values"
                :tooltipposition="'left'"
                @selected-array="selectedValues($event, ruleItem)"
              ></hlx-multi-select>
              <hlx-input
                v-else-if="ruleItem.showType == 'input'"
                v-model:value="ruleItem.value"
                :pre-val="ruleItem.value"
                @at-input="selectedValues($event, ruleItem)"
              >
              </hlx-input>
              <hlx-input
                v-else-if="ruleItem.showType == 'date'"
                v-model:value="ruleItem.value"
                type="date"
                icon="calendar-regular"
                icon-position="right"
                :pre-val="ruleItem.value"
                @at-input="selectedValues($event, ruleItem)"

              >
              </hlx-input>
              <hlx-input
                v-else-if="ruleItem.showType == 'number'"
                v-model:value="ruleItem.value"
                type="number"
                :pre-val="ruleItem.value"
                @at-input="selectedValues($event, ruleItem)"

              >
              </hlx-input>
              <div
                v-else-if="ruleItem.showType == 'double-number'"
                style="display: flex; flex-direction: row; gap: 10px"
              >
                <hlx-input
                  v-model:value="ruleItem.value"
                  type="number"
                  :pre-val="ruleItem.value"
                @at-input="selectedValues($event, ruleItem)"

                >
                </hlx-input>
                <hlx-input
                  v-model:value="ruleItem.value1"
                  type="number"
                  :pre-val="ruleItem.value1"
                @at-input="selectedValues($event, ruleItem)"

                >
                </hlx-input>
              </div>
              <div
                v-else-if="ruleItem.showType == 'double-date'"
                style="display: flex; flex-direction: row; gap: 10px"
              >
                <hlx-input
                  v-model:value="ruleItem.value"
                  type="date"
                  icon="calendar-regular"
                  icon-position="right"
                  :pre-val="ruleItem.value"
                @at-input="selectedValues($event, ruleItem)"

                >
                </hlx-input>
                <hlx-input
                  v-model:value="ruleItem.value1"
                  type="date"
                  icon="calendar-regular"
                  icon-position="right"
                  :pre-val="ruleItem.value1"
                @at-input="selectedValues($event, ruleItem)"

                >
                </hlx-input>
              </div>
            </span>
          </td>
          <td v-if="visibilityRulePayload.length > 1" style="padding: 0 21px">
            <i class="icon-trash-regular" @click="removeCondition(index)"></i>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="add-new-condition" @click="addCondition">+ Add condition</div>
  </div>
</template>

<script>
import { DMNAxiosInstance } from "@/config/axiosConfig";

export default {
emits: ['conditionData'],
  data() {
    return {
      selectedtyplevalue: "",
      intellisenseList: [],
      intellisenseFilterData: {
        type: "filter",

        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: this.$cookies.get('company'),
      },
      selectedIntellisenseValue: "",
      fieldDataForDropdowns: [],
      visibilityRulePayload: [
        {
          conditionType: "When",
          tmfFieldMapping: {},
          flatSchemaMapping: {},
          value: "",
          value1: "",
          valueType: "",
          values: [],
          operator: "",
          showType: "",
          intellisenseName: "",
          appliesTo:""
        },
      ],
      visibilityRuleOperatorTypeOptions: [
        {
          name: "Is not empty",
          value: "Is not empty",
          displayType: ["string"],
          show: "",
        },
        {
          name: "Is empty",
          value: "Is empty",
          displayType: ["string"],
          show: "",
        },
        {
          name: "Contains",
          value: "Contains",
          displayType: ["string"],
          show: "input",
        },
        {
          name: "Does not contain",
          value: "Does not contain",
          displayType: ["string"],
          show: "input",
        },
        {
          name: "Matches regex",
          value: "Matches regex",
          displayType: ["string"],
          show: "input",
        },
        {
          name: "Length >",
          value: "Length >",
          displayType: ["string"],
          show: "number",
        },
        {
          name: "Length <",
          value: "Length <",
          displayType: ["string"],
          show: "number",
        },

        {
          name: "Is selected",
          value: "Is selected",
          displayType: ["array"],
          show: "dropdown",
        },

        {
          name: "Is not selected",
          value: "Is not selected",
          displayType: ["array"],
          show: "dropdown",
        },
        {
          name: "Is equal to",
          value: "Is equal to",
          displayType: ["array"],
          show: "input",
        },
        {
          name: "Is one of",
          value: "Is one of",
          displayType: ["array"],
          show: "multiselect",
        },
        {
          name: "Is not one of",
          value: "Is not one of",
          displayType: ["array"],
          show: "multiselect",
        },
        {
          name: "Yes",
          value: "Yes",
          displayType: ["boolean"],
          show: "dropdown",
        },
        {
          name: "No",
          value: "No",
          displayType: ["boolean"],
          show: "dropdown",
        },

        {
          name: "Is null",
          value: "Is null",
          displayType: ["date"],
          show: "",
        },
        {
          name: "Is not null",
          value: "Is not null",
          displayType: ["date"],
          show: "",
        },
        {
          name: "Is before",
          value: "Is before",
          displayType: ["date"],
          show: "date",
        },
        {
          name: "Is after",
          value: "Is after",
          displayType: ["date"],
          show: "date",
        },
        {
          name: "Is on a specific date",
          value: "Is on a specific date",
          displayType: ["date"],
          show: "date",
        },

        {
          name: "Is greater than equal to specific date",
          value: "Is greater than equal to specific date",
          displayType: ["date"],
          show: "date",
        },
        {
          name: "Greater than or equal to X days from today",
          value: "Greater than or equal to X days from today",
          displayType: ["date"],
          show: "number",
        },
        {
          name: "Greater than X days from today",
          value: "Greater than X days from today",
          displayType: ["date"],
          show: "number",
        },
        {
          name: "Is less than equal to specific date",
          value: "Is less than equal to specific date",
          displayType: ["date"],
          show: "date",
        },
        {
          name: "Less than or equal to X days from today",
          value: "Less than or equal to X days from today",
          displayType: ["date"],
          show: "number",
        },
        {
          name: "Less than X days from today",
          value: "Less than X days from today",
          displayType: ["date"],
          show: "number",
        },
        {
          name: "Equal to X day(s) from today",
          value: "Equal to X day(s) from today",
          displayType: ["date"],
          show: "number",
        },
        {
          name: "Is within X day(s) from today",
          value: "Is within X day(s) from today",
          displayType: ["date"],
          show: "number",
        },
        {
          name: "Is between (X and Y days) from today",
          value: "Is between (X and Y days) from today",
          displayType: ["date"],
          show: "double-number",
        },
        {
          name: "Is within date range",
          value: "Is within date range",
          displayType: ["date"],
          show: "double-date",
        },
        {
          name: "Is greater than",
          value: "Is greater than",
          displayType: ["number"],
          show: "number",
        },
        {
          name: "Is greater than or equal to",
          value: "Is greater than or equal to",
          displayType: ["number"],
          show: "number",
        },
        {
          name: "Is less than or equal to",
          value: "Is less than or equal to",
          displayType: ["number"],
          show: "number",
        },
        {
          name: "Is not equal to",
          value: "Is not equal to",
          displayType: ["number"],
          show: "number",
        },
        {
          name: "Is within range (X to Y)",
          value: "Is within range (X to Y)",
          displayType: ["number"],
          show: "double-number",
        },

        {
          name: "Is less than",
          value: "Is less than",
          displayType: ["number"],
          show: "number",
        },

        {
          name: "Length <",
          value: "Length <",
          displayType: ["url"],
          show: "number",
        },
        {
          name: "Is not null",
          value: "Is not null",
          displayType: ["File Upload"],
          show: "",
        },
        {
          name: "Matches file type",
          value: "Matches file type",
          displayType: ["File Upload"],
          show: "input",
        },
        {
          name: "Matches file size limit",
          value: "Matches file size limit",
          displayType: ["File Upload"],
          show: "number",
        },
      ],
      appliesList: [
        {
          name: "Applies to ALL",
          value: "Applies to ALL",
        },
        {
          name: "Applies to ANY",
          value: "Applies to ANY",
        },
      ],
      conditionList: [],

      selectedConditionValue: "",
      selectedIntellisenseType: "",

      operatorList: [],
      conditions: [
        {
          logic: "when",
          field: "Number of sites",
          operator: "Greater than or equal to",
          value: 22,
        },
      ],
    };
  },
  watch: {
    selectedIntellisenseValue: {
      handler(v) {
        this.selectedCondition(v);
      },
      immediate: true,
      deep: true,
    },
    // visibilityRulePayload: {
    //   handler(value) {
    //    console.log(value,"visibilityRulePayload")
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  async mounted() {
    this.selectedCondition();
    this.operatorList = await this.getReferanceData("operatorList");
  },
  methods: {

    selectedappliesTo(){
      this.$emit("conditionData",this.visibilityRulePayload)

    },
    selectedValues(){
      this.$emit("conditionData",this.visibilityRulePayload)

    },
    updateOperatorList(index, key, value) {
      if (this.visibilityRulePayload[index]) {
        this.visibilityRulePayload[index][key] = value;
        console.log(this.visibilityRulePayload, "updateCustomPermission");
      }
      this.$emit("conditionData",this.visibilityRulePayload)
    },
    async selectedFieldItem(e, val, index, key) {
      console.log(e, "selected value called", val, index, key);
      if (e.flatSchemaMapping.type) {
        this.selectedtyplevalue = e.flatSchemaMapping.type;
        val.displayType = e.flatSchemaMapping.type;
      } else {
        val.displayType = e.type;
        this.selectedtyplevalue = e.type;
      }
      if (this.visibilityRulePayload[index]) {
        if (this.visibilityRulePayload[index].tmfFieldMapping) {
          this.visibilityRulePayload[index].tmfFieldMapping = e.tmfMongoMapping;
        }
        if (this.visibilityRulePayload[index].flatSchemaMapping) {
          this.visibilityRulePayload[index].flatSchemaMapping =
            e.flatSchemaMapping;
        }
        if (this.visibilityRulePayload[index].flatSchemaMapping) {
          this.visibilityRulePayload[index].intellisenseName = e.label;
        }
      }
      // this.$emit("conditionData",this.visibilityRulePayload)

    },
    selectedOperatorObject(e, item) {
      item.showType = e.show;
      this.$emit("conditionData",this.visibilityRulePayload)

    },
    deleteVisibilityRuleBlock(id) {
      this.visibilityRulePayload.splice(id, 1);
    },

    getVisibilityRuleOperatorTypeOptions(ruleItem) {
      let arr = this.visibilityRuleOperatorTypeOptions.filter((i) => {
        return i?.displayType?.includes(ruleItem?.displayType);
      });

      if (arr.some((item) => item.displayType.includes(ruleItem?.operator))) {
        // do nothing
      } else {
        // ruleItem.operator = arr[0].value
      }
      return arr;
    },

    searchHAccount(val) {
      console.log(val, "searchHAccount");
      this.selectedIntellisenseValue = "";
    },
    async selectedCondition(val) {
      this.intellisenseFilterData.searchQuery = val;

      let intellisenseListData = await DMNAxiosInstance.post(
        `util/filterData/intellisense`,
        this.intellisenseFilterData
      );

      this.intellisenseList = intellisenseListData.data.data.data;
    },
    async setFirstItemAsDefault(val) {
      // console.log('default value called');

      val.displayType = this.fieldDataForDropdowns[0]?.displayType;

      return this.fieldDataForDropdowns[0]?.fieldName;
    },
    // async selectedCondition(val) {
    //   this.intellisenseFilterData.searchQuery = val;

    //   let intellisenseListData = await DMNAxiosInstance.post(
    //     `util/filterData/intellisense`,
    //     this.intellisenseFilterData
    //   );

    //   this.intellisenseList = intellisenseListData.data.data.data;
    // },
    // searchHAccount(val) {
    //   console.log(val, "searchHAccount");
    //   this.selectedIntellisenseValue = "";
    // },
    updateCondition(data) {
      this.selectedConditionValue = data.name;
      console.log(data, "updateCondition", this.selectedConditionValue);
    },
    async updateIntellisense(data) {
      console.log(data.type, "updateIntellisense");
      this.selectedIntellisenseType = data.type;
      if (data.type.toLowerCase() === "string") {
        this.conditionList = await this.getReferanceData("conditionString");
      }
      if (data.type.toLowerCase() === "array") {
        this.conditionList = await this.getReferanceData("conditionArray");
      }
      if (data.type.toLowerCase() === "boolean") {
        this.conditionList = await this.getReferanceData("conditionBoolean");
      }
      if (data.type.toLowerCase() === "date") {
        this.conditionList = await this.getReferanceData("conditionDate");
      }
      if (data.type.toLowerCase() === "file upload") {
        this.conditionList = await this.getReferanceData("conditionFileUpload");
      }
      if (data.type.toLowerCase() === "number") {
        this.conditionList = await this.getReferanceData("conditionNumber");
      }
    },
    transformData(dataArray) {
      return dataArray.map((item) => ({
        _id: item._id,
        module: item.field.module,
        type: item.field.type,
        label: item.field.label,
        prop: item.field.tmfMongoMapping.prop,
        fieldType: item.field.tmfMongoMapping.type,
        lastModifiedBy: item.lastModifiedBy,
        __v: item.__v,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
      }));
    },
    addCondition() {
      this.visibilityRulePayload.push({
        conditionType: "",
        tmfFieldMapping: {},
        flatSchemaMapping: {},
        value: "",
        values: [],
        operator: "",
        showType: "",
        intellisenseName: "",
        appliesTo:""
      });
      // this.conditions.push({
      //   logic: "AND",
      //   field: "Number of sites",
      //   operator: "is",
      //   value: "",
      // });
    },
    removeCondition(index) {
      this.visibilityRulePayload.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
//   .condition-container {
//     // margin: 20px;
//   }

.condition-table-emailTriggers {
  width: 100%;
  margin-bottom: 10px;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.condition-header {
  width: 10%;
}

.condition-table-emailTriggers th,
.condition-table-emailTriggers td {
  padding: 8px;
  text-align: left;
}

.condition-row-emailTriggers {
  background-color: rgba(166, 166, 166, 0.15);
  border-radius: 6px;
}

.condition-text {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: center;
}

.text-center {
  text-align: center !important;
}

.delete-icon {
  padding: 0 21px;
}

.add-new-condition {
  color: rgba(84, 189, 149, 1);
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
}

.add-condition-button {
  background-color: #4caf50;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-condition-button:hover {
  background-color: #45a049;
}

.condition-select,
.condition-input {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #f5f5f5;
}

.condition-delete-button {
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.condition-delete-button:hover {
  background-color: #d9363e;
}
</style>
