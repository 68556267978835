<!-- eslint-disable vue/no-dupe-keys -->
<!-- eslint-disable no-case-declarations -->
<template>
  <div class="upc-dashboard">
    <section class="header-section">
      <div class="title">
        <h3 class="heading1">Welcome {{ name }} !</h3>
        <p class="heading2">
          Go to market faster with a single catalog for products, services,
          pricing, rules, bundles, add-ons, promotions and more.
        </p>
      </div>
      <div class="card-section">
        <div id="card1" class="card first" @click="handleStatus('card1', $event)">
          <h4 class="heading">Total products</h4>
          <section class="total" style="height: 90px">
            <div class="part2" style="width:30%">
              <h3 class="sub-heading">
                {{ statusMetrics["Total product"]?.total }}
              </h3>
              <div class="imageview">
                <div class="arrowbox">
                  <img
v-if="['increase', '-'].includes(
                    isDownOrIncrease(
                      statusMetrics['Total product']?.percent
                    )
                  )
                    " src="../../assets/images/greenarrow.svg" />
                  <img
v-else-if="['decrease'].includes(
                    isDownOrIncrease(
                      statusMetrics['Total product']?.percent
                    )
                  )
                    " src="../../assets/images/redarrow.svg" />
                  <h5
:class="{
                    success: ['increase', '-'].includes(isDownOrIncrease()),
                    danger: ['decrease'].includes(
                      isDownOrIncrease(
                        statusMetrics['Total product']?.percent
                      )
                    )
                  }">
                    {{ statusMetrics["Total product"]?.percent?.value }}%
                  </h5>
                </div>
              </div>
            </div>
            <div class="curve" style="height: 70px;width:70%">
              <VueApexCharts
              :type="areaChartoptions?.chart?.type" height="100%" width="100%" :options="areaChartoptions"
              :series="areaChartoptions?.series" ></VueApexCharts>
            </div>
          </section>
        </div>
        <div id="card2" class="card" @click="handleStatus('card2', $event)">
          <h4 class="heading">In study</h4>
          <section class="total" style="height: 90px">
            <div class="part2" style="width:30%">
              <h3 class="sub-heading">
                {{ statusMetrics["In study"]?.total }}
              </h3>
              <div class="imageview">
                <div class="arrowbox">
                  <!-- <img src="../../assets/images/redarrow.svg" /> -->
                  <img
v-if="['increase', '-'].includes(
                    isDownOrIncrease(statusMetrics['In study']?.percent)
                  )
                    " src="../../assets/images/greenarrow.svg" />
                  <img
v-else-if="['decrease'].includes(
                    isDownOrIncrease(statusMetrics['In study']?.percent)
                  )
                    " src="../../assets/images/redarrow.svg" />
                  <h5
:class="{
                    success: ['increase', '-'].includes(isDownOrIncrease()),
                    danger: ['decrease'].includes(
                      isDownOrIncrease(statusMetrics['In study']?.percent)
                    )
                  }">
                    {{ statusMetrics["In study"]?.percent?.value }}%
                  </h5>
                </div>
              </div>
            </div>
            <div class="curve" style="height: 70px;width:70%">
              <!-- <img src="../../assets/images/redline.svg" /> -->
              <VueApexCharts
              :type="inStudyareaChartoptions?.chart?.type" height="100%" width="100%" :options="inStudyareaChartoptions"
              :series="inStudyareaChartoptions?.series" ></VueApexCharts>
            </div>
          </section>
        </div>
        <div id="card3" class="card" @click="handleStatus('card3', $event)">
          <h4 class="heading">Active</h4>
          <section class="total" style="height: 90px">
            <div class="part2" style="width:30%">
              <h3 class="sub-heading">{{ statusMetrics["Active"]?.total }}</h3>
              <div class="imageview">
                <div class="arrowbox">
                  <!-- <img src="../../assets/images/greenarrow.svg" />
                  <h5 class="success">{{statusMetrics["Active"]?.percent?.value}}%</h5> -->
                  <img
v-if="['increase', '-'].includes(
                    isDownOrIncrease(statusMetrics['Active']?.percent)
                  )
                    " src="../../assets/images/greenarrow.svg" />
                  <img
v-else-if="['decrease'].includes(
                    isDownOrIncrease(statusMetrics['Active']?.percent)
                  )
                    " src="../../assets/images/redarrow.svg" />
                  <h5
:class="{
                    success: ['increase', '-'].includes(isDownOrIncrease()),
                    danger: ['decrease'].includes(
                      isDownOrIncrease(statusMetrics['Active']?.percent)
                    )
                  }">
                    {{ statusMetrics["Active"]?.percent?.value }}%
                  </h5>
                </div>
              </div>
            </div>
            <div class="curve" style="height: 70px;width:70%">
              <!-- <img src="../../assets/images/gc.svg" /> -->
              <VueApexCharts
              :type="activeareaChartoptions?.chart?.type" height="100%" width="100%" :options="activeareaChartoptions"
              :series="activeareaChartoptions?.series" ></VueApexCharts>
            </div>
          </section>
        </div>
        <div id="card4" class="card" @click="handleStatus('card4', $event)">
          <h4 class="heading">Launched</h4>
          <section class="total" style="height: 90px">
            <div class="part2" style="width:30%">
              <h3 class="sub-heading">
                {{ statusMetrics["Launched"]?.total }}
              </h3>
              <div class="imageview">
                <div class="arrowbox">
                  <!-- <img src="../../assets/images/greenarrow.svg" />
                  <h5 class="success">{{statusMetrics["Launched"]?.percent?.value}}%</h5> -->
                  <img
v-if="['increase', '-'].includes(
                    isDownOrIncrease(statusMetrics['Launched']?.percent)
                  )
                    " src="../../assets/images/greenarrow.svg" />
                  <img
v-else-if="['decrease'].includes(
                    isDownOrIncrease(statusMetrics['Launched']?.percent)
                  )
                    " src="../../assets/images/redarrow.svg" />
                  <h5
:class="{
                    success: ['increase', '-'].includes(isDownOrIncrease()),
                    danger: ['decrease'].includes(
                      isDownOrIncrease(statusMetrics['Launched']?.percent)
                    )
                  }">
                    {{ statusMetrics["Launched"]?.percent?.value }}%
                  </h5>
                </div>
              </div>
            </div>
            <div class="curve" style="height: 70px;width:70%">
              <!-- <img src="../../assets/images/gc.svg" /> -->
              <VueApexCharts
              :type="areaChartoptions?.chart?.type" height="100%" width="100%" :options="areaChartoptions"
              :series="areaChartoptions?.series" ></VueApexCharts>
            </div>
          </section>
        </div>
        <div id="card5" class="card" @click="handleStatus('card5', $event)">
          <h4 class="heading">Retired</h4>
          <section class="total" style="height: 90px">
            <div class="part2" style="width:30%">
              <h3 class="sub-heading">{{ statusMetrics["Retired"]?.total }}</h3>
              <div class="imageview">
                <div class="arrowbox">
                  <!-- <img src="../../assets/images/redarrow.svg" />
                  <h5 class="danger">{{statusMetrics["Retired"]?.percent?.value}}%</h5> -->
                  <img
v-if="['increase', '-'].includes(
                    isDownOrIncrease(statusMetrics['Retired']?.percent)
                  )
                    " src="../../assets/images/greenarrow.svg" />
                  <img
v-else-if="['decrease'].includes(
                    isDownOrIncrease(statusMetrics['Retired']?.percent)
                  )
                    " src="../../assets/images/redarrow.svg" />
                  <h5
:class="{
                    success: ['increase', '-'].includes(isDownOrIncrease()),
                    danger: ['decrease'].includes(
                      isDownOrIncrease(statusMetrics['Retired']?.percent)
                    )
                  }">
                    {{ statusMetrics["Retired"]?.percent?.value }}%
                  </h5>
                </div>
              </div>
            </div>
            <div class="curve" style="height: 70px;width:70%">
              <!-- <img src="../../assets/images/redline.svg" /> -->
              <VueApexCharts
              :type="areaChartoptions?.chart?.type" height="100%" width="100%" :options="areaChartoptions"
              :series="areaChartoptions?.series" ></VueApexCharts>
            </div>
          </section>
        </div>
        <div id="card6" class="card" @click="handleStatus('card6', $event)">
          <h4 class="heading">Obsolete</h4>
          <section class="total" style="height: 90px">
            <div class="part2" style="width:30%">
              <h3 class="sub-heading">
                {{ statusMetrics["Obsolete"]?.total }}
              </h3>
              <div class="imageview">
                <div class="arrowbox">
                  <img
v-if="['increase', '-'].includes(
                    isDownOrIncrease(statusMetrics['Obsolete']?.percent)
                  )
                    " src="../../assets/images/greenarrow.svg" />
                  <img
v-else-if="['decrease'].includes(
                    isDownOrIncrease(statusMetrics['Obsolete']?.percent)
                  )
                    " src="../../assets/images/redarrow.svg" />
                  <h5
:class="{
                    success: ['increase', '-'].includes(
                      isDownOrIncrease(statusMetrics['Obsolete']?.percent)
                    ),
                    danger: ['decrease'].includes(
                      isDownOrIncrease(statusMetrics['Obsolete']?.percent)
                    )
                  }">
                    {{ statusMetrics["Obsolete"]?.percent?.value }}%
                  </h5>
                </div>
              </div>
            </div>
            <div class="curve" style="height: 70px;width:70%">
              <VueApexCharts
              :type="areaChartoptions?.chart?.type" height="100%" width="100%" :options="areaChartoptions"
              :series="areaChartoptions?.series" ></VueApexCharts>
            </div>
          </section>
        </div>
      </div>
    </section>
    <section class="footer-section">
      <div class="upc-left">
        <section class="tab-container">
          <hlx-switch
v-model="selectedTab" :switch-items="UPCDashboardBarSwitchItems" :tab-view="true" value-prop="value"
            display-prop="label" @change="handleBarChatData" />

          <div class="upc-custom-bar-chart">
            <VueApexCharts
width="100%" height="150px" type="bar" :options="options" :series="series"
              @click="handleBarChart($event, selectedTab)"></VueApexCharts>
          </div>
        </section>
        <section class="layout-container">
          <div class="container">
            <section class="headers">
              <section class="header"> 
                <h4>Product completeness</h4>
              </section>
              <div class="horizontal-bar">
                <VueApexCharts
width="90%" height="150px" type="bar" :options="productCompletenessChartOptions"
                  :series="productCompletenessSeries" @click="handleBarChart($event, 'productCompleteness')">
                </VueApexCharts>
              </div>
            </section>
          </div>
          <div class="place-container">
            <section class="headers">
              <h4>Market segment</h4>
            </section>
            <!-- <VueApexCharts
              :type="placeMetrics.chart.type" height="100%" width="100%" :options="placeMetrics"
              :series="placeMetrics.series" @click="handleChartClick"></VueApexCharts> -->
          </div>
          <div class="contact-container">
            <section class="headers">
              <h4>Contact</h4>
              <h4>Products</h4>
            </section>
            <section class="holder">
              <span v-for="(party,index) in partyWithProductCount" :key="index">
              <div v-if="index<3" class="members first">
                <p  class="name" @click="handleRelatedParty(party?.party?.name)">{{ party?.party?.name }}</p>
                <p  class="count" @click="handleRelatedParty(party?.party?.name)">{{ party?.total }}</p>
              </div>
            </span>
            </section>
          </div>
        </section>
      </div>
      <div class="upc-right">
        <p style="font-weight: 600">Recent activity</p>
        <hlx-loader v-if="$store.state.activityLog == undefined" load-type="dotted-jumper" />
        <ActivityLogComponent v-else :module="'UPC'"></ActivityLogComponent>
      </div>
    </section>
  </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
// import deepdiff from "deepDiff"

import ActivityLogComponent from "@/components/UPC/ActivityLogComponent.vue";
import { MLTAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";
// import deepDiff from 'deep-diff';
export default {
  name: "UpcDashboardView",
  components: { VueApexCharts, ActivityLogComponent },
  data() {
    return {
      filterobj: {},
      productCompleteNess: {
        series: [],
        category: []
      },
      selectedTab: "category",
      arrowUp: "greenarrow.svg",
      UPCDashboardBarSwitchItems: [
        {
          label: "Category",

          value: "category"
        },

        {
          label: "Market",

          value: "market"
        },

        {
          label: "Channel",

          value: "channel"
        }
      ],

      categoryXData: [],

      marketXData: [],

      channelXData: [],

      // categoryYData: [
      //   {
      //     x: "Voice Services",

      //     y: 70
      //   },

      //   {
      //     x: "Television Services",

      //     y: 80
      //   },

      //   {
      //     x: "Business Solutions",

      //     y: 65
      //   },

      //   {
      //     x: "IoT Connectivity ",

      //     y: 45
      //   },

      //   {
      //     x: "Network Equipment",

      //     y: 50
      //   },

      //   {
      //     x: "Telecom Software",

      //     y: 60
      //   },

      //   {
      //     x: "Telecom Infrastructure",

      //     y: 80
      //   },

      //   {
      //     x: "Telecom Testing",

      //     y: 50
      //   }
      // ],
      categoryYData: [],

      marketYData: [],

      channelYData: [],
      seriesT: [
        {
          name: "Inflation",
          data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar"
        },
        plotOptions: {
          bar: {
            borderRadius: 6,
            borderRadiusApplication: "top",
            horizontal: false,
            columnWidth: "30%",
            endingShape: "rounded",
            dataLabels: {
              // position: 'top', // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#000000"]
          }
        },

        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          position: "bottom",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          tooltip: {
            enabled: true
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "%";
            }
          }
        },
        title: {
          text: "Monthly Inflation in Argentina, 2002",
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444"
          }
        }
      },
      // series: [{
      //   name: 'series-1',
      //   data: [30, 40, 45, 50, 49, 60, 70, 91]
      // }]
      options: {
        chart: { toolbar: { show: false }, width: "100%", type: "bar" },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            borderRadiusApplication: "top",
            // borderRadiusWhenStacked: "last",
            columnWidth: "31%",
            // barHeight: "70%",
            endingShape: "rounded"
            // dataLabels: {
            //   position: "top",
            //   maxItems: 100,
            //   hideOverflowingLabels: true,
            // }
          }
        },
        dataLabels: { enabled: false },
        stroke: { show: false, width: 2, colors: ["transparent"] },
        xaxis: {
          categories: [],
          tickAmount: undefined,
          tickPlacement: "between",
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: "bottom",
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: false,
            hideOverlappingLabels: false,
            showDuplicates: false,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Opensans",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label"
            },
            offsetX: 0,
            offsetY: 0,
            format: undefined,
            formatter: undefined,
            datetimeUTC: true,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm"
            }
          },
          axisBorder: {
            show: false,
            color: "#78909C",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0
          },
          axisTicks: {
            show: false,
            borderType: "transparent",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0
          },

          title: {
            text: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title"
            }
          },
          crosshairs: {
            show: true,
            width: 1,
            position: "back",
            opacity: 0.9,
            stroke: {
              color: "#b6b6b6",
              width: 0,
              dashArray: 0
            },
            fill: {
              type: "solid",
              color: "#B1B9C4",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            },
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0.4
            }
          },
          tooltip: {
            enabled: false,
            formatter: undefined,
            offsetY: 0,
            style: {
              fontSize: 0,
              fontFamily: 0
            }
          }
          // tooltip: false,
          // convertedCatToNumeric: false
        },
        yaxis: [
          {
            // axisTicks: { show: true },
            // axisBorder: { show: true, color: "" },
            // labels: { style: { colors: "" } },
            title: {
              text: "Number of products"
              // style: { color: "rgb(0, 143, 251)" }
            },
            tooltip: { enabled: false }
          }
          // {
          //   seriesName: "Execution count",
          //   opposite: true,
          //   axisTicks: { show: false },
          //   axisBorder: { show: true, color: "" },
          //   labels: { style: { colors: "" } },
          //   title: { text: "Execution count", style: { color: "#54bd95" } }
          // }
        ],

        responsive: [{ breakpoint: 480, options: { legend: { show: false } } }],
        // legend: { show: false },
        grid: { borderColor: "#f7f7f7" },
        // fill: { opacity: 1 },
        tooltip: {
          enabled: true
        },
        colors: ["#54bd95", "rgb(0, 143, 251)"]
      },
      totalProduct: 0,
      productCompletenessChartOptions: {
        chart: { toolbar: { show: false }, width: "100%" },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 4,
            borderRadiusApplication: "Top",
            borderRadiusWhenStacked: "all",
            columnWidth: "20%",
            barHeight: "30%",
            distributed: false,
            rangeBarOverlap: true,
            rangeBarGroupRows: false,
            hideZeroBarsWhenGrouped: false,
            isDumbbell: false,
            dumbbellColors: undefined,
            isFunnel: false,
            isFunnel3d: true,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  color: undefined
                }
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0
            }
          }
        },
        dataLabels: { enabled: false },
        stroke: { show: false, width: 2, colors: ["transparent"] },
        xaxis: {
          categories: []

          // axisTicks: {
          //   show: true
          // },

          // axisBorder: {
          //   show: true

          //   // color: "#54bd95"
          // },

          // labels: {
          //   style: {
          //     // colors: "#54bd95",
          //   }
          // }
        },
        yaxis: {
          // data: [2,4,6,8,10,12,14],
          // axis,,Ticks: { show: true },
          // axisBorder: { show: true, color: "" },
          // labels: { style: { colors: "" } },
          title: {
            // text: "Number of product"
            // style: { color: "rgb(0, 143, 251)" }
          },
          // min: 0,
          // max: 20,
          tickAmount: 1,

          tooltip: { enabled: false }
        },
        grid: { borderColor: "#f7f7f7" },
        tooltip: {
          enabled: true,
          // enabledOnSeries: ['image','description','price']
          onDatasetHover: {
            highlightDataSeries: false
          }
        },
        colors: ["#54bd95", "rgb(0, 143, 251)"]
      },

      series: [
        {
          name: "Product",
          type: "column",
          data: []
        }
      ],
      productCompletenessSeries: [{ name:'Product', data: [] }],
      progress: 1,
      company: this.$cookies.get("company"),
      placeMetrics: {
        series: [],
        chart: {
          // height: "300px",
          // width: "300px",
          height: "100%",
          type: "donut",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          pie: {
            customScale: 0.8,
            size: 100
          },
          background: "transparent"
        },
        colors: [
          "rgba(49, 132, 101, 1)",
          "rgba(84, 189, 149, 1)",
          "rgba(182, 233, 213, 1)"
        ],
        labels: [],
        dataLabels: {
          enabled: false
        },

        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: "300px",
        //         height: "300px"
        //       },
        //       legend: {
        //         show: false
        //       }
        //     }
        //   }
        // ],
        legend: {
          position: "right",
          offsetY: 0,
          offsetX: 2,
          height: 100,
          // width:100,
          show: true,
          verticalAlign: "center",
          horizontalAlign: "center"
          // floating: true,
        },
        content: {
          title: "Total sales",
          total: "$85K"
        }
      },
      partyWithProductCount: [],
      statusMetrics: {},
      userInfo: this.$store.state.userInfo,
      statusData:[],
      totalProductsData:[],
      areaChartoptions: {
  chart: {
    
    type: "area",
        width: "50%",
    zoom: {
            enabled: false,
          },
          toolbar: {
            show: false, // Disables the toolbar menu
          },
  },
   colors: ["#54bd95"],
  dataLabels: {
    enabled: false
  },
  stroke: {
          curve: "smooth",
          width: 2, // Set the stroke width to reduce line thickness
        },
  tooltip: {
          enabled: false, // Disables the tooltip
        },
  series: [],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.4,
      opacityTo: 0.9,
      stops: [0, 90, 100]
    }
  },
  xaxis: {
    labels: {
            show: false // Disable x-axis labels
          },
          axisBorder: {
            show: false // Disable x-axis border
          },
    
 axisTicks: {
        show: false
      },
  },
    grid: {
          show: false, // Optional: Enable or disable the grid
          xaxis: {
            lines: {
              show: false // Optional: Hide vertical grid lines
            }
          }
      },
  yaxis:[{
        axisTicks: {
        show: false
      },
      axisBorder: {
        show: false,
      },
      labels: {
        show:false,
        style: {
          colors: "#54bd95"
        }
      },
  }
  ]
},
activeareaChartoptions: {
  chart: {
    
    type: "area",
        width: "50%",
    zoom: {
            enabled: false,
          },
          toolbar: {
            show: false, // Disables the toolbar menu
          },
  },
   colors: ["#54bd95"],
  dataLabels: {
    enabled: false
  },
  stroke: {
          curve: "smooth",
          width: 2, // Set the stroke width to reduce line thickness
        },
  tooltip: {
          enabled: false, // Disables the tooltip
        },
  series: [
        {
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125], // Your data points
        }
  ],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.4,
      opacityTo: 0.9,
      stops: [0, 90, 100]
    }
  },
  xaxis: {
    labels: {
            show: false // Disable x-axis labels
          },
          axisBorder: {
            show: false // Disable x-axis border
          },
    
 axisTicks: {
        show: false
      },
  },
    grid: {
          show: false, // Optional: Enable or disable the grid
          xaxis: {
            lines: {
              show: false // Optional: Hide vertical grid lines
            }
          }
      },
  yaxis:[{
        axisTicks: {
        show: false
      },
      axisBorder: {
        show: false,
      },
      labels: {
        show:false,
        style: {
          colors: "#54bd95"
        }
      },
  }
  ]
},
inStudyareaChartoptions: {
  chart: {
    
    type: "area",
        width: "50%",
    zoom: {
            enabled: false,
          },
          toolbar: {
            show: false, // Disables the toolbar menu
          },
  },
   colors: ["#54bd95"],
  dataLabels: {
    enabled: false
  },
  stroke: {
          curve: "smooth",
          width: 2, // Set the stroke width to reduce line thickness
        },
  tooltip: {
          enabled: false, // Disables the tooltip
        },
  series: [
  {
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125], // Your data points
        }
  ],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.4,
      opacityTo: 0.9,
      stops: [0, 90, 100]
    }
  },
  xaxis: {
    labels: {
            show: false // Disable x-axis labels
          },
          axisBorder: {
            show: false // Disable x-axis border
          },
    
 axisTicks: {
        show: false
      },
  },
    grid: {
          show: false, // Optional: Enable or disable the grid
          xaxis: {
            lines: {
              show: false // Optional: Hide vertical grid lines
            }
          }
      },
  yaxis:[{
        axisTicks: {
        show: false
      },
      axisBorder: {
        show: false,
      },
      labels: {
        show:false,
        style: {
          colors: "#54bd95"
        }
      },
  }
  ]
},
launchedareaChartoptions: {
  chart: {
    
    type: "area",
        width: "50%",
    zoom: {
            enabled: false,
          },
          toolbar: {
            show: false, // Disables the toolbar menu
          },
  },
   colors: ["#54bd95"],
  dataLabels: {
    enabled: false
  },
  stroke: {
          curve: "smooth",
          width: 2, // Set the stroke width to reduce line thickness
        },
  tooltip: {
          enabled: false, // Disables the tooltip
        },
  series: [
  {
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125], // Your data points
        }
  ],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.4,
      opacityTo: 0.9,
      stops: [0, 90, 100]
    }
  },
  xaxis: {
    labels: {
            show: false // Disable x-axis labels
          },
          axisBorder: {
            show: false // Disable x-axis border
          },
    
 axisTicks: {
        show: false
      },
  },
    grid: {
          show: false, // Optional: Enable or disable the grid
          xaxis: {
            lines: {
              show: false // Optional: Hide vertical grid lines
            }
          }
      },
  yaxis:[{
        axisTicks: {
        show: false
      },
      axisBorder: {
        show: false,
      },
      labels: {
        show:false,
        style: {
          colors: "#54bd95"
        }
      },
  }
  ]
},
retiredareaChartoptions: {
  chart: {
    
    type: "area",
        width: "50%",
    zoom: {
            enabled: false,
          },
          toolbar: {
            show: false, // Disables the toolbar menu
          },
  },
   colors: ["#54bd95"],
  dataLabels: {
    enabled: false
  },
  stroke: {
          curve: "smooth",
          width: 2, // Set the stroke width to reduce line thickness
        },
  tooltip: {
          enabled: false, // Disables the tooltip
        },
  series: [
  {
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125], // Your data points
        }
  ],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.4,
      opacityTo: 0.9,
      stops: [0, 90, 100]
    }
  },
  xaxis: {
    labels: {
            show: false // Disable x-axis labels
          },
          axisBorder: {
            show: false // Disable x-axis border
          },
    
 axisTicks: {
        show: false
      },
  },
    grid: {
          show: false, // Optional: Enable or disable the grid
          xaxis: {
            lines: {
              show: false // Optional: Hide vertical grid lines
            }
          }
      },
  yaxis:[{
        axisTicks: {
        show: false
      },
      axisBorder: {
        show: false,
      },
      labels: {
        show:false,
        style: {
          colors: "#54bd95"
        }
      },
  }
  ]
},
obsoleteareaChartoptions: {
  chart: {
    
    type: "area",
        width: "50%",
    zoom: {
            enabled: false,
          },
          toolbar: {
            show: false, // Disables the toolbar menu
          },
  },
   colors: ["#54bd95"],
  dataLabels: {
    enabled: false
  },
  stroke: {
          curve: "smooth",
          width: 2, // Set the stroke width to reduce line thickness
        },
  tooltip: {
          enabled: false, // Disables the tooltip
        },
  series: [
  {
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125], // Your data points
        }
  ],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.4,
      opacityTo: 0.9,
      stops: [0, 90, 100]
    }
  },
  xaxis: {
    labels: {
            show: false // Disable x-axis labels
          },
          axisBorder: {
            show: false // Disable x-axis border
          },
    
 axisTicks: {
        show: false
      },
  },
    grid: {
          show: false, // Optional: Enable or disable the grid
          xaxis: {
            lines: {
              show: false // Optional: Hide vertical grid lines
            }
          }
      },
  yaxis:[{
        axisTicks: {
        show: false
      },
      axisBorder: {
        show: false,
      },
      labels: {
        show:false,
        style: {
          colors: "#54bd95"
        }
      },
  }
  ]
}
    };
  },
  watch: {
    statusMetrics: {
      handler(data) {
        // console.log(
        //   "🚀 ~ file: upcDashboardView.vue:841 ~ handler ~ data:",
        //   data
        // );
        // console.log(
        //   "🚀 ~ file: upcDashboardView.vue:843 ~ handler ~ data && Object.keys(data).length == 0:",
        //   data && Object.keys(data).length > 0
        // );
        if (data && Object.keys(data)?.length > 0) {
          this.totalProduct = data["Total product"];
          // this.optionsH.yaxis.min = 0;
          // this.optionsH.yaxis.max = this.totalProduct;
          // console.log("🚀 ~ file: upcDashboardView.vue:845 ~ handler ~ this.optionsH:", this.optionsH)
          let a, b, c;
          a = this.totalProduct - 2;
          console.log("🚀 ~ file: upcDashboardView.vue:849 ~ handler ~ a:", a);
          b = a - 2;
          console.log("🚀 ~ file: upcDashboardView.vue:851 ~ handler ~ b :", b);
          c = b - 1;
          console.log("🚀 ~ file: upcDashboardView.vue:853 ~ handler ~  c:", c);
          // this.seriesH[0].data[0] = a;
          // this.seriesH[0].data[1] = b;
          // this.seriesH[0].data[2] = c;
          // setTimeout(() => {

          // }, 3000);
        }
      },
      deep: true,
      immediate: true
    },
    selectedTab: {
      handler(tabValue) {
        // console.log(
        //   "🚀 ~ file: upcDashboardView.vue:903 ~ handler ~ tabValue:",
        //   tabValue
        // );
        if (!tabValue) return;

        let key = tabValue;

        switch (key) {
          case "market":
            this.series[0].data = this.marketYData;
            // this.series[0].data = [55,65,75,85,89];
            // this.options.xaxis.categories = this.marketXData
            break;

          case "channel":
            this.series[0].data = this.channelYData;
            // this.options.xaxis.categories = this.channelXData
            break;

          default:
            this.series[0].data = this.categoryYData;
            // this.options.xaxis.categories = this.categoryXData
            break;
        }
      },
      immediate: true,
      deep: true
    },
    areaChartoptions:{
      handler(val) {
         val
    },
    deep:true,
    immediate:true
  },
  inStudyareaChartoptions:{
      handler(val) {
         val
    },
    deep:true,
    immediate:true
  },
  activeareaChartoptions:{
      handler(val) {
         val
    },
    deep:true,
    immediate:true
  },
  launchedareaChartoptions:{
      handler(val) {
         val
    },
    deep:true,
    immediate:true
  },
  retiredareaChartoptions:{
      handler(val) {
         val
    },
    deep:true,
    immediate:true
  },
  obsoleteareaChartoptions:{
      handler(val) {
         val
    },
    deep:true,
    immediate:true
  },

  },

  async mounted() {
    let individualRes = await MLTAxiosInstance.get(
      `partymanagement/individual/auth`
    );
    this.company = individualRes.data.company;
    this.userId = individualRes.data.company;
    let userRes = await MLTAxiosInstance.get(
      `partymanagement/individual/${individualRes.data.userId}?company=${this.company}`
    );
    console.log("🚀 ~ mounted ~ userRes:", userRes)
    this.name = userRes.data.name;
    this.$cookies.set("userName", this.name);
    
    // For Total products
    
    let totalProduct = await UPCAxiosInstance.get(`/dashboard/productStatus`)
    console.log(totalProduct,'statusss')
    this.totalProductsData  = totalProduct.data
    console.log(totalProduct.data,'statusss1')
    if(totalProduct.status == 200){
      console.log(this.totalProductsData,'statusss')
      this.totalProductsData.forEach((item)=>{
        
        let value = item?.monthlyCountsArray
        let obj1 ={}
        let arr1 =[]
        value[0] = 0
        obj1['data'] = value
        console.log(value,'statusss')
        arr1.push(obj1)
        this.areaChartoptions.series = arr1
      })
    }
    // value

    // For  status

    let status = await UPCAxiosInstance.get(`dashboard/lineChart`)
    console.log(status,'statusss')
    this.statusData = status.data.data
    if(status.data.status == 200){
      this.statusData.forEach((e)=>{
        let obj ={}
        let arr =[]
        if(e.status == 'Active'){
          
          obj['data'] = e.counts
          arr.push(obj)
          this.activeareaChartoptions.series = arr
        }else if(e.status == 'In study'){
          obj['data'] = e.counts
          arr.push(obj)
          this.inStudyareaChartoptions.series = arr
        }
        
      })
    }

   

    // For product compeleteness

    let productCompleteNess = await UPCAxiosInstance.get(
      `/dashboard/product_completeness`
    );
    console.log("🚀 ~ mounted ~ productCompleteNess:", productCompleteNess)
    if(productCompleteNess.data == "STATUS_ARRAY is not defined")
    {
      this.productCompletenessSeries[0].data = [];
      this.productCompletenessChartOptions.xaxis= {
              categories: [1,4,6],
            }
    }
    else if (productCompleteNess.data) {
      this.productCompletenessSeries[0].data = productCompleteNess.data.series;

      this.productCompletenessChartOptions = {
        xaxis: { categories: productCompleteNess.data.category },
        yaxis: {
          tooltip: { enabledOnSeries: productCompleteNess.data.category }
        }
        // tooltip: {
        //   enabled: true,
        //   enabledOnSeries: productCompleteNess.data.category
        // },
      };
    }

    // For place metrics

    let placeMetrics = await UPCAxiosInstance.get(
      `/dashboard/place_metric`
    );
    console.log("🚀 ~ mounted ~ placeMetrics:", placeMetrics,placeMetrics.data.marketSegement)
    if (placeMetrics.data && Object.keys(placeMetrics.data).length > 0) {
      let data = placeMetrics.data.marketSegement
      this.placeMetrics.series = placeMetrics.data.total;
      this.placeMetrics = {
        ...this.placeMetrics,
        labels: data
      };
    }
    console.log(this.placeMetrics,'hell');
    // For party with product count metrics

    let partyWithProductCount = await UPCAxiosInstance.get(
      `/dashboard/party_with_product_count`
    );
    console.log("🚀 ~ mounted ~ partyWithProductCount:", partyWithProductCount)
    if (partyWithProductCount.data) {
      partyWithProductCount.data.forEach((e,index)=>{
        if(index<5){
          this.partyWithProductCount.push(e)
        }
      })
    }

    let statusMetrics = await UPCAxiosInstance.get(
      `/catalogManagement/productOffering/dashboard_status_data`
    );
    console.log(
      "🚀 ~ file: upcDashboardView.vue:1079 ~ mounted ~ statusMetrics:",
      statusMetrics
    );
    if (statusMetrics.data && statusMetrics.data.ok == 0) {
      this.statusMetrics = []
    } else {
      this.statusMetrics = statusMetrics.data;
    }

    let barChartData = await UPCAxiosInstance.get(
      `/catalogManagement/productOffering/dashboard_chart`
    );
    console.log("🚀 ~ mounted ~ barChartData:", barChartData)
    if (barChartData && barChartData.data) {
      barChartData = barChartData.data;

      this.categoryXData = barChartData.XData?.category;
      this.marketXData = barChartData.XData?.market;
      this.channelXData = barChartData.XData?.channel;

      this.categoryYData = barChartData.YData?.category;
      this.marketYData = barChartData.YData?.market;
      this.channelYData = barChartData.YData?.channel;
      // console.log(categorydata.data,category.data,"🎉");
      this.series[0].data = this.categoryYData;
    }
    this.options = {
        chart: { toolbar: { show: false }, width: "100%", type: "bar" },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            borderRadiusApplication: "top",
            // borderRadiusWhenStacked: "last",
            columnWidth: "31%",
            // barHeight: "70%",
            endingShape: "rounded"
            // dataLabels: {
            //   position: "top",
            //   maxItems: 100,
            //   hideOverflowingLabels: true,
            // }
          }
        },
        dataLabels: { enabled: false },
        stroke: { show: false, width: 2, colors: ["transparent"] },
        xaxis: {
          categories: [],
          tickAmount: undefined,
          tickPlacement: "between",
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: "bottom",
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: false,
            hideOverlappingLabels: false,
            showDuplicates: false,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Opensans",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label"
            },
            offsetX: 0,
            offsetY: 0,
            format: undefined,
            formatter: undefined,
            datetimeUTC: true,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm"
            }
          },
          axisBorder: {
            show: false,
            color: "#78909C",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0
          },
          axisTicks: {
            show: false,
            borderType: "transparent",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0
          },

          title: {
            text: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title"
            }
          },
          crosshairs: {
            show: true,
            width: 1,
            position: "back",
            opacity: 0.9,
            stroke: {
              color: "#b6b6b6",
              width: 0,
              dashArray: 0
            },
            fill: {
              type: "solid",
              color: "#B1B9C4",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            },
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0.4
            }
          },
          tooltip: {
            enabled: false,
            formatter: undefined,
            offsetY: 0,
            style: {
              fontSize: 0,
              fontFamily: 0
            }
          }
          // tooltip: false,
          // convertedCatToNumeric: false
        },
        yaxis: [
          {
            // axisTicks: { show: true },
            // axisBorder: { show: true, color: "" },
            // labels: { style: { colors: "" } },
            
            title: {
              text: "Number of products"
              // style: { color: "rgb(0, 143, 251)" }
            },
             labels: {
              // This will round the number to whole numbers
              formatter: function(value) {
                return Math.floor(value);
              }
            },
            tooltip: { enabled: false }
          }
          // {
          //   seriesName: "Execution count",
          //   opposite: true,
          //   axisTicks: { show: false },
          //   axisBorder: { show: true, color: "" },
          //   labels: { style: { colors: "" } },
          //   title: { text: "Execution count", style: { color: "#54bd95" } }
          // }
        ],

        responsive: [{ breakpoint: 480, options: { legend: { show: false } } }],
        // legend: { show: false },
        grid: { borderColor: "#f7f7f7" },
        // fill: { opacity: 1 },
        tooltip: {
          enabled: true
        },
        colors: ["#54bd95", "rgb(0, 143, 251)"]
      },

    await this.$store.dispatch('loadLifeCycleStatus')
    // this.handleBarChatData({value:'category'})
    let interval = setInterval(() => {
      if (this.progress < 75) {
        this.progress += 2;
      } else {
        this.progress -= 5;
      }
    }, 200);
    setTimeout(() => {
      // this.progress = 100;
      clearInterval(interval);
    }, 15000);
    // this.options.xaxis.categories = this.categoryXData
  },
  methods: {
    handleRelatedParty(value) {
      let arr = []
      arr.push(value)
      console.log(value, 'kkdkd');
      this.filterobj['relatedParty'] = arr
      this.$store.commit('filterPreval', this.filterobj)
      this.$store.commit('filterPreval', this.filterobj)
      this.$router.push({ name: 'offeringview', query: { contact: value } });

      // this.$router.push("product-catalog-table/offering")
    },
    handleStatus(id, $event) {
      $event
      let data = document.querySelector('#' + id).children[0].innerText
      let arr = []
      this.filterobj = {}
      if (data == 'In study') {
        arr.push('In study')
        this.filterobj['status'] = arr
        this.$store.commit('filterPreval', this.filterobj)
        this.$router.push({ name: 'offeringview', query: { name: this.filterobj } });
        // this.$router.push("product-catalog-table/offering")
        console.log(this.filterobj, 'filterobj');
      }
      else if (data == 'Active') {
        arr.push('Active')
        this.filterobj['status'] = arr
        this.$store.commit('filterPreval', this.filterobj)
        this.$router.push({ name: 'offeringview', query: { name: this.filterobj } });
        // this.$router.push("product-catalog-table/offering")
        console.log(this.filterobj, 'filterobj');
      }
      else if (data == 'Launched') {
        arr.push('Launched')
        this.filterobj['status'] = arr
        this.$store.commit('filterPreval', this.filterobj)
        this.$router.push({ name: 'offeringview', query: { name: this.filterobj } });
        // this.$router.push("product-catalog-table/offering")
        console.log(this.filterobj, 'filterobj');
      }
      else if (data == 'Retired') {
        arr.push('Retired')
        this.filterobj['status'] = arr
        this.$store.commit('filterPreval', this.filterobj)
        this.$router.push({ name: 'offeringview', query: { name: this.filterobj } });
        // this.$router.push("product-catalog-table/offering")
        console.log(this.filterobj, 'filterobj');
      }
      else if (data == 'Obsolete') {
        arr.push('Obsolete')
        this.filterobj['status'] = arr
        this.$store.commit('filterPreval', this.filterobj)
        this.$router.push({ name: 'offeringview', query: { name: this.filterobj } });
        // this.$router.push("product-catalog-table/offering")
        console.log(this.filterobj, 'filterobj');
      }
      else if (data == 'Total products') {
        // arr = ['In study', 'Active', 'Launched', 'Retired', 'Obsolete']
        // this.filterobj['status'] = arr
        // this.$store.commit('filterPreval',this.filterobj)
        this.$router.push("product-catalog-table/offering")
        // console.log(this.filterobj,'filterobj');
      }
      console.log(data);
    },
    handleChartClick(event, chartContext, config) {
      this.filterobj = {}
      const clickedValue = config;
      clickedValue
      const eve = event
      const charcontext = chartContext
      charcontext
      console.log(eve.target.parentElement.getAttribute('seriesName'),'lsk');
      let data = []
      let value = eve.target.parentElement.getAttribute('seriesName').toString()
      let result = '';
      for (let i = 0; i < value.length; i++) {
        if (value[i] === 'x' && i > 0 && value[i-1] >= 'A' && value[i-1] <= 'Z') {
            result += ' ';
        } else {
            result += value[i];
        }
    }
    result
    let str = value;
    let newStr = str.replace(/x/g, ' ');
      console.log(newStr,'jdkdkd');
      data.push(newStr)
      this.filterobj['market segment'] = data
      this.$store.commit('filterPreval', this.filterobj)
      this.$router.push({ name: 'offeringview', query: { name: this.filterobj } });
      // this.$router.push("product-catalog-table/offering")
      console.log(this.filterobj, 'filterobj');
    },
    handleBarChart($event, keyValue) {
      this.filterobj = {}
      if ($event.target.tagName == 'path') {   // Barchart graph
        let id = $event.target.getAttribute('id')
        var count = 0
        document.querySelectorAll('.apexcharts-bar-area').forEach((e, index) => {
          let compareid = e.getAttribute('id')
          if (compareid == id) {
            count = index
          }
        })
        let data = []
        if (keyValue != 'productCompleteness') {
          data.push(this.series[0]['data'][count]['x'])
          let key = ''
          if (keyValue.toLowerCase() == 'market') {
            key = 'market segment'
          }
          else if (keyValue.toLowerCase() == 'category') {
            key = 'category'
          }
          else if (keyValue.toLowerCase() == 'channel') {
            key = 'channel'
          }
          this.filterobj[key] = data
        } else {
          // let productcompletenesslength = count - this.series[0]['data']?.length 
          let productcompletenesslength = 2
          data.push(this.productCompletenessChartOptions['xaxis']['categories'][productcompletenesslength])
          this.filterobj[keyValue] = data
        }
        console.log(this.filterobj, 'filterobj');
      }
      else if ($event.target.tagName == 'tspan') //Barchart heading
      {
        let data = []
        let val = $event.target.nextElementSibling.innerHTML
        if (keyValue != 'productCompleteness') {
          this.series[0]['data'].forEach((e) => {
            if (val == e.x) {
              data.push(e.x)
            }
          })
          let key = ''
          if (keyValue.toLowerCase() == 'market') {
            key = 'market segment'
          }
          else if (keyValue.toLowerCase() == 'category') {
            key = 'category'
          }
          else if (keyValue.toLowerCase() == 'channel') {
            key = 'channel'
          }
          this.filterobj[key] = data
        }
        else {
          this.productCompletenessChartOptions['xaxis']['categories'].forEach((e) => {
            if (val == e) {
              data.push(e)
            }
          })
          this.filterobj[keyValue] = data
        }
        console.log(this.filterobj, 'filterobj');
      }
      this.$store.commit('filterPreval', this.filterobj)
      this.$router.push({ name: 'offeringview', query: { name: this.filterobj }})
    },
    isDownOrIncrease(metric) {
      // console.log(
      //   "🚀 ~ file: upcDashboardView.vue:840 ~ isDownOrIncrease ~ metric:",
      //   metric
      // );
      if (!metric) return "-";
      return metric.type;
    },
    getImageUrl(name) {
      return new URL(`../../assets/images/${name}`, import.meta.url).href;
    },
    async handleBarChatData(tabValue) {
      // console.log(
      //   "🚀 ~ file: upcDashboardView.vue:1024 ~ handleBarChatData ~ tabValue:",
      //   tabValue
      // );
      if (!tabValue) return;

      let key = tabValue.value;

      switch (key) {
        case "market":
          // eslint-disable-next-line no-case-declarations
          // let market = await UPCAxiosInstance.get(`/catalogManagement/productOffering/status/market`)
          // this.marketXData = await market.data
          // // eslint-disable-next-line no-case-declarations
          // let marketdata = await UPCAxiosInstance.get(`/catalogManagement/productOffering/dashboard_chart/market`)
          // this.marketYData = await marketdata.data
          // this.series[0].name = 'Market'
          this.series[0].data = this.marketYData;
          // this.series[0].data = [55,65,75,85,89];
          // this.options.xaxis.categories = this.marketXData
          break;

        case "channel":
          // eslint-disable-next-line no-case-declarations
          // let channel = await UPCAxiosInstance.get(`/catalogManagement/productOffering/status/channel`)
          // this.channelXData = await channel.data
          // // eslint-disable-next-line no-case-declarations
          // let channeldata = await UPCAxiosInstance.get(`/catalogManagement/productOffering/dashboard_chart/channel`)
          // this.channelYData = await channeldata.data
          // this.series[0].name = 'Channel'
          this.series[0].data = this.channelYData;
          // this.options.xaxis.categories = this.channelXData
          break;

        default:
          // eslint-disable-next-line no-case-declarations
          // let category = await UPCAxiosInstance.get(`/catalogManagement/productOffering/status/category`)
          // this.categoryXData = await category.data
          // if(this.categoryXData && this.categoryXData.length>0){
          //   this.options.plotOptions.bar.columnWidth = (3*this.categoryXData.length) + "%"

          // }
          // // eslint-disable-next-line no-case-declarations
          // let categorydata = await UPCAxiosInstance.get(`/catalogManagement/productOffering/dashboard_chart/category`)
          // this.categoryYData = await categorydata.data
          // console.log("🚀 ~ file: upcDashboardView.vue:900 ~ handleBarChatData ~ this.categoryYData:", this.categoryYData)
          // if(this.categor)
          // this.series[0].name = 'Category'
          console.log(this.categoryYData,'kdk')
          this.options = {
        chart: { toolbar: { show: false }, width: "100%", type: "bar" },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            borderRadiusApplication: "top",
            // borderRadiusWhenStacked: "last",
            columnWidth: "31%",
            // barHeight: "70%",
            endingShape: "rounded"
            // dataLabels: {
            //   position: "top",
            //   maxItems: 100,
            //   hideOverflowingLabels: true,
            // }
          }
        },
        dataLabels: { enabled: false },
        stroke: { show: false, width: 2, colors: ["transparent"] },
        xaxis: {
          categories: [],
          tickAmount: undefined,
          tickPlacement: "between",
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: "bottom",
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: false,
            hideOverlappingLabels: false,
            showDuplicates: false,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Opensans",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label"
            },
            offsetX: 0,
            offsetY: 0,
            format: undefined,
            formatter: undefined,
            datetimeUTC: true,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm"
            }
          },
          axisBorder: {
            show: false,
            color: "#78909C",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0
          },
          axisTicks: {
            show: false,
            borderType: "transparent",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0
          },

          title: {
            text: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title"
            }
          },
          crosshairs: {
            show: true,
            width: 1,
            position: "back",
            opacity: 0.9,
            stroke: {
              color: "#b6b6b6",
              width: 0,
              dashArray: 0
            },
            fill: {
              type: "solid",
              color: "#B1B9C4",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            },
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0.4
            }
          },
          tooltip: {
            enabled: false,
            formatter: undefined,
            offsetY: 0,
            style: {
              fontSize: 0,
              fontFamily: 0
            }
          }
          // tooltip: false,
          // convertedCatToNumeric: false
        },
        yaxis: [
          {
            // axisTicks: { show: true },
            // axisBorder: { show: true, color: "" },
            // labels: { style: { colors: "" } },
            
            title: {
              text: "Number of products"
              // style: { color: "rgb(0, 143, 251)" }
            },
             labels: {
              // This will round the number to whole numbers
              formatter: function(value) {
                return Math.floor(value);
              }
            },
            tooltip: { enabled: false }
          }
          // {
          //   seriesName: "Execution count",
          //   opposite: true,
          //   axisTicks: { show: false },
          //   axisBorder: { show: true, color: "" },
          //   labels: { style: { colors: "" } },
          //   title: { text: "Execution count", style: { color: "#54bd95" } }
          // }
        ],

        responsive: [{ breakpoint: 480, options: { legend: { show: false } } }],
        // legend: { show: false },
        grid: { borderColor: "#f7f7f7" },
        // fill: { opacity: 1 },
        tooltip: {
          enabled: true
        },
        colors: ["#54bd95", "rgb(0, 143, 251)"]
      },
          this.series[0].data = this.categoryYData;
          // this.options.xaxis.categories = this.categoryXData
          break;
      }
    }
    // deep: true,
    // immediate: true
  }
};
</script>
