<template>
  <section class="order-notes-section-wrapper">
    <div class="header">
      <i class="icon-note-list-regular"></i>
      <span>Notes</span>
    </div>
    <div class="order-input-wrapper">
      <textarea
        id="gen-ai-prompter"
        ref="promptTextarea"
        v-model="userNotes"
        placeholder="Write your notes"
        name="gen-ai-prompt-tool"
        :height="promptRowHeight"
        cols="50"
        class="order-textarea"
        :disabled="false"
      />
      <span v-if="editNote == false"></span>
      <i
        v-if="editNote == true"
        class="icon-times-regular send-icon"
        style="color: #dd2025"
        @click="closeNotes"
      ></i>
      <i
        class="icon-send-right-regular send-icon"
        :style="[
          userNotes == ''
            ? 'cursor:not-allowed;color:#8B8B8B'
            : 'cursor:pointer;color:#54BD95',
        ]"
        @click.stop="addNotes"
      ></i>
    </div>
  </section>
  <section
    v-if="notesTable.length > 0"
    class="section_accordtion_supplier_note"
    style="margin-right: 30px"
  >
    <div class="notepad_supplier_accordion_note">
      <hlx-accordion
        v-for="(note, index) in notesTable"
        :key="index"
        :multiple="true"
        :header-border="true"
        :direction="'left'"
        @accordionopen="accordionopen(index)"
        @accordionclose="accordionclose(index)"
      >
        <!-- Title -->
        <template #item-title>
          <div class="noter_fullhead_supplier_accordion_note">
            <div class="noter_head_supplier_accordion_note">
              <span class="note_username_supplier_note">
                <!-- Note by -->
                {{ matchFromStatic("Note by") }}
                {{ " " }}
                <span class="note_username_supplier_color_note"
                  >{{ note.noteusername }}
                </span></span
              >
              <span class="note_date_time_supplier_note">
                <span class="note_date_time_supplier_value">{{
                  dateAndTimeFormatter(note?.date)
                }}</span>

                <!-- <span class="note_date_time_supplier_value">{{
                    convertdateformat(note.time)
                  }}</span> -->

                <!-- <span class="note_date_time_supplier_value">{{
                    note.country
                  }}</span> -->
              </span>
              <div class="supplier-delete-action" style="">
                <div>
                  <i
                    class="icon-trash-regular"
                    style="color: #dd2025"
                    @click.stop="deleteNotes(index)"
                  ></i>
                </div>
                <div>
                  <i
                    class="icon-edit-regular"
                    style="color: #70bb98"
                    @click="editNotes(note, index)"
                  ></i>
                </div>
              </div>
            </div>

            <div
              :id="'displayHeader' + index"
              class="ellipsisnote1"
              style="
                max-width: 600px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
              "
            >
              {{ note.note }}
            </div>
          </div>
        </template>
        <!-- Body -->
        <template #item-body>
          <main
            class="pricing-panel"
            style="line-height: 1.7; word-break: break-all"
          >
            {{ note.note }}
          </main>
        </template>
      </hlx-accordion>
    </div>
  </section>
</template>

<script>
export default {
props:{
  data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: "edit",
    }
},
emits: ['added-note', 'edit-note', 'delete-index'],
  data() {
    return {
      userNotes: "",
      promptRowHeight: 40,
      notesTable: [],
      notesPayload: {},
      editNote: false,
      editIndex: false,
    };
  },
  watch: {
    data: {
      handler(val) {
        console.log(val, "product value",this.notesTable);
        this.notesTable = []
        let arr = val
        this.notesTable = arr
        // this.notesTable = val;
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    closeNotes() {
      this.editNote = false;
      this.userNotes = "";
    },
    deleteNotes(index) {
      
      this.notesTable.splice(index, 1);
      this.$emit("delete-index", this.notesTable);
    },
    editNotes(value, index) {
      this.userNotes = value.note;
      this.editNote = true;
      this.editIndex = index;
      // this.notesPayload[index].note = this.userNotes
    },
    addNotes() {
      if(this.editNote == true){
        this.notesTable[this.editIndex].note = this.userNotes
        this.editNote = false;
      this.userNotes = ''
      this.$emit("edit-note", this.notesTable);

      }else{
          this.notesPayload = {
            reason: "",
            note: "",
            noteusername: "",
            date: new Date().toJSON(),
            country: "EST",
          };
          this.notesPayload.note = this.userNotes;

          // this.notesPayload.noteusername = this.loginUser.data.company;
          this.notesTable.push(this.notesPayload);
          this.notesTable = this.notesTable.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          this.$emit("added-note", this.notesTable);
          this.userNotes = "";
          this.notesPayload = {
            reason: "",
            note: "",
            noteusername: "",
            date: new Date().toJSON(),
            country: "EST",
          };
        }
    },
    accordionclose(index) {
      this.noteopen = false;
      const element = document.getElementById("displayHeader" + index);
      console.log(element, this.noteopen, "accordionclose");
      if (element) {
        element.style.display = "-webkit-box";
      }
    },
    accordionopen(index) {
      this.noteopen = true;
      const element = document.getElementById("displayHeader" + index);
      console.log(element, this.noteopen, "accordionopen");
      if (element) {
        element.style.display = "none";
      }
    },
  },
};
</script>
