<template>
  <hlx-modal
    :modal-active="show"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'210px'"
    :width="'400px'"
    :modal="true"
    @close="$emit('close')"
  >
    <template #header>
      <div>
        <!-- Unsaved changes -->
         {{loadStaticData('LANG-0101')}}
      </div>
    </template>
    <template #content>
      <div class="modal-content">
        <!-- Do you want to save your changes before navigating away? -->
         {{ loadStaticData('LANG-0102')}}
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="discardAllChanges"
        >
        <!-- Discard -->
         {{ loadStaticData('LANG-0103')}}
        </hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="stay"        
        >
        <!-- Stay -->
         {{  loadStaticData('LANG-0104')}}
        </hlx-button
      >
    </template>
  </hlx-modal>
</template>
<script>
// import hlxButton from "../components/ButtonComponent.vue";
import {
  // MLTAxiosInstance,
  // DMNAxiosInstance,
  // UPCAxiosInstance,
} from "@/config/axiosConfig";
export default {
  name: "HlxTextEditor",
  components: {

  },
  props: {
    show:{
      type:Boolean,
      default:false 
    },
    labelData: {
      type: Object,
      default: function () {
        return {
          unsavedChanges : "Unsaved changes",
          discardModalMessage: "Do you want to save your changes before navigating away?",
          discard: "Discard",
          stay: "Stay",
        };
      },
    },
    message:{
      type:String,
      default:'Do you want to save your changes before navigating away?' 
    },
    primaryButtonValue:{
      type:String,
      default:'Stay' 
    },
    secondaryButtonValue:{
      type:String,
      default:'Discard' 
    },
  },
  emits: ["stay", "discard",'close'],
  data() {
    return {
      loginUserDetails: {},
    };
  },
  async mounted() {
         
  },
  methods: {
    discardAllChanges()
    {
      this.$emit('discard','')
    },
    stay()
    {
      console.log("🚀 ~ stay:", 'stay')
      this.$emit('stay','')
    }
    

  },
};
</script>