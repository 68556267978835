<template>
    <div
      class="activelog_page_supplier"
      style="padding-right: 10px; margin-bottom: 10px; overflow: auto"
    >
      <div class="header-add-supplier">
        <div class="tablehead-add-supplier">
          <!-- Activity log -->
          <span v-if="staticData">{{ staticData?.cmAccountLeftPanelActivityLog?.label[loginUserDetails]?.label }}</span>
        </div>
        <div
          v-if="activityData?.length > 0 || searchactivity != ''"
          class="search-add-supplier"
        >
          <span>
            <hlx-search
            v-if="staticData"          
            :label-value=staticData?.search?.label[loginUserDetails]?.label
            @search-key="activitysearch"></hlx-search></span>
        </div>
      </div>
  
      <!-- Table with data -->
      <section
        v-if="updatedLeadLogData?.length > 0"
        class="section_accordtion_supplier"
      >
        <hlx-table
          :label-value="tableModalLang"
          :column-count="leadTableheaders.length + 2"
          :border="['table', 'header', 'horizontal']"
          :row-hover="true"
          theme="grey"
          :re-arrange-columns="re_arrange"
          :re-arrange-heads="leadTableheadersCopy"
          @sorting_func="sorting_Data"
          @close-rearrange="re_arrange = false"
          @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head
              :width="120"
              :align="'center'"
              style="padding: 0 15px"
              :fixed="'left'"
              @new-resize-width="resizedWidth"
            >
              <!-- {{ "S.no" }} -->
              <!-- {{'Activity ID' }} -->
              {{ matchFromStatic('Activity ID') }}
            </hlx-table-head>

            <hlx-table-head
              v-for="(i, index) in leadTableheaders"
              :key="index"
              :prop="i.prop"
              :sortable="i.sortable"
              :resizable="i.resizable"
              :width="i.width !== undefined ? i.width : ''"
              :align="i.align !== undefined ? i.align : ''"
              @sorting_func="sorting_Data"
              @new-resize-width="resizedWidth"
            >
              {{ matchFromStatic(i.label) }}</hlx-table-head
            >
            <!-- <hlx-table-head
              :align="'center'"
              :width="70"
              :fixed="'right'"
              @new-resize-width="resizedWidth"
              ><span class="action-icon">
                <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                  @click="reAssignOrgTableHeader()"
                ></i> </span
            ></hlx-table-head> -->
          </template>

          <!-- table with data -->
          <template v-if="updatedLeadLogData?.length > 0" #tbody>
            <tr v-for="(i, index) in updatedLeadLogData" id="" :key="index">
              <hlx-table-cell :align="'center'" :fixed="'left'">
                {{ serialNumber(index) }}
              </hlx-table-cell>
              <hlx-table-cell
                v-for="(j, col_index) in leadTableheaders"
                :key="col_index"
                :align="j.align"
                :fixed="j.fixed"
              >
              <!-- {{permisstionData.edit}} -->
                <div
                  v-if="j.prop.toLowerCase() === 'user'"
                >
                  <span>
                    {{ i["user"] }}
                  </span>
                </div>

                <div v-else-if="j.prop.toLowerCase() === 'name'">
                  {{ i["name"] }}
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'status'">
                  <div v-if="i[j.prop] === 'Unqualified'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#999999'"
                      :status-value="'Unqualified'"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Awaiting network approval'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#F9A645'"
                      :status-value="'Awaiting network approval'"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Converted'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#54BD95'"
                      :status-value="'Converted'"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Network approved'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#F9D82C'"
                      :status-value="'Network approved'"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Network rejected'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#F9D82C'"
                      :status-value="'Network rejected'"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Open'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#0065B2'"
                      :status-value="'Open'"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Closed Lost'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#FC3232'"
                      :status-value="'Closed Lost'"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Qualified'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#FEDC2A'"
                      :status-value="'Qualified'"
                    ></hlx-status>
                  </div>
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'sites'">
                  <span>
                    {{
                      getNumberOfSiteValueForTable(i.externalReference, "sites")
                    }}
                  </span>
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'leadowner'">
                  <span>
                    {{
                      getNumberOfSiteValueForTable(i.relatedParty, "leadOwner")
                    }}
                  </span>
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'action'">
                  <span>
                    {{ i.detailedAction?.[0]?.["action"] ? capitalizeFirstLetterForTableAction( i.detailedAction?.[0]?.["action"]) : 'N/A' }}
                  </span>
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'field changed'">
                  <!-- <span>
                    {{ typeof i["added"] == 'object' ? i["added"].type : i["added"] }}
                  </span> -->
                  <span>
                    {{ formatString( i.detailedAction?.[0]?.['property'])  || 'N/A'}}
                  </span>
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'id'">
                  <!-- <span>
                    {{ typeof i["added"] == 'object' ? i["added"].type : i["added"] }}
                  </span> -->
                  <span>
                    {{ Array.isArray(i.detailedAction?.[0]?.['displayID']) ? i.detailedAction?.[0]?.['displayID'][0] : i.detailedAction?.[0]?.['displayID'] || '-'}}
                  </span>
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'changed from'">
  <span>
    <!-- {{ (i.detailedAction?.[0]?.['from']) }} -->
    <!-- {{ checkIsDateOrNot(i.detailedAction?.[0]?.['from']) }} -->
    {{ isValidDate(i.detailedAction?.[0]?.['from']) 
        ? dateAndTimeFormatter(i.detailedAction?.[0]?.['from']) 
        : ( capitalizeFirstLetterForTableAction(i.detailedAction?.[0]?.['from']) || '-') }}
  </span>
</div>
<div v-else-if="j.prop.toLowerCase() === 'changed to'">
  <span>
    <!-- {{ (i.detailedAction?.[0]?.['to']) }} -->
    <!-- {{ checkIsDateOrNot(i.detailedAction?.[0]?.['from']) }} -->
    {{ isValidDate(i.detailedAction?.[0]?.['to']) 
        ? dateAndTimeFormatter(i.detailedAction?.[0]?.['to']) 
        : ( capitalizeFirstLetterForTableAction(i.detailedAction?.[0]?.['to']) || '-') }}
  </span>
</div>


                <div v-else-if="j.prop.toLowerCase() === 'createdat'">
                  <span>
                    {{ dateAndTimeFormatter(i["createdAt"]) }}
                  </span>
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'timestamp'">
                  <span>
                    {{ dateAndTimeFormatter(i["createdAt"]) }}
                  </span>
                </div>
                <div v-else>{{ i[j.prop] }}</div>
              </hlx-table-cell>
              <!-- <hlx-table-cell
                :align="'center'"
                :width="120"
                :fixed="'right'"
              >
                <span
                  :id="'table-context' + serialNumber(index - 1)"
                  class="action-icon"
                >
                  <i
                    style="position: relative"
                    class="icon-more-vertical-filled"
                    :class="i.context === true ? 'active-action' : ''"
                    @click="crudContextMenu($event, serialNumber(index - 1))"
                  >
                    <hlx-context-menu
                      :top="containertop"
                      :display="'display'"
                      :left="containerleft - 20"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      :options="computedTableAction(i)"
                      :data="index.toString()"
                      :show="i.context == true"
                      @chosen="
                        closeCrudContextMenu($event, i, serialNumber(index - 1))
                      "
                    />
                  </i>
                </span>
              </hlx-table-cell> -->
            </tr>
          </template>
        </hlx-table>

        <!-- <div style="height: 90px"></div> -->
      </section>
  
      <div
        v-if="updatedLeadLogData?.length > 0"
        class="activirylog_pagination"
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :label-value=paginationLang
          :total="totalTableDataLength"
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    </div>

   <!-- Table for no data -->
   <section
        v-if="updatedLeadLogData.length <= 0 && searchactivity !== ''"
        class="lead-table"
      >
        <hlx-table
          :column-count="leadTableheaders.length + 2"
          :border="['table', 'header']"
          :row-hover="true"
          theme="grey"
          :re-arrange-columns="re_arrange"
          :re-arrange-heads="leadTableheadersCopy"
          @close-rearrange="re_arrange = false"
          @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head
              :width="60"
              :align="'center'"
              style="padding: 0 15px"
              :fixed="'left'"
              @new-resize-width="resizedWidth"
            >
              {{ loadStaticData("LANG-0046") }}
              <!-- {{ "S.no" }} -->
            </hlx-table-head>

            <hlx-table-head
              v-for="(i, index) in leadTableheaders"
              :key="index"
              :prop="i.prop"
              :sortable="i.sortable"
              :resizable="i.resizable"
              :width="i.width !== undefined ? i.width : ''"
              :align="i.align !== undefined ? i.align : ''"
              @sorting_func="sorting_Data"
              @new-resize-width="resizedWidth"
            >
              {{ i.label }}</hlx-table-head
            >
            <hlx-table-head
              :align="'center'"
              :width="70"
              :fixed="'right'"
              @new-resize-width="resizedWidth"
              >
              <!-- <span class="action-icon">
                <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                  @click="reAssignOrgTableHeader()"
                ></i> </span
            > -->
          </hlx-table-head>
          </template>

          <!-- table without data )-->
          <template #tbody>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell :colspan="leadTableheaders.length + 2">{{
                "No data"
              }}</hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
          </template>
        </hlx-table>
      </section>

      <!-- Table data with Image with illustartion -->
    <div v-if="dupUpdatedLogData?.length === 0 && searchactivity === ''">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 40px;
        "
      >
        <img
          height="390px"
          src="@/assets/images/supplierActivityLog.svg"
          alt=""
        />
      </div>
    </div>
  </template>
  <script>
  
  import {
    UPCAxiosInstance,
    // MLTAxiosInstance
  
  } from "@/config/axiosConfig";
  export default {
    props: {
      activityData: {
        type: Array,
        default: () => {
          return [];
        },
      },
      mode: {
        type: String,
        default: "edit",
      },
      module: {
        type: String,
        default: "",
      },
      category: {
        type: String,
        default: "",
      },
      id: {
        type: String,
        default: "",
      },
      totalfromprop: {
        type: Number,
        default: 0,
      },
    },
    emits: ["activitycurrentpage", "activityrowpage",'reload-data','activity-search'],
    data() {
      return {
        params:'',
        tableModalLang: {
        re_arrange_columns: this.matchFromStatic("Re-arrange columns"),
        Cancel: this.matchFromStatic("Cancel"),
        Apply: this.matchFromStatic("Apply"),
        SelectAll: this.matchFromStatic("Select all"),
      },
        totalTableDataLength:0,
        updatedLeadLogData:[],
        dupUpdatedLogData:[],
        leadTableheaders: [
        // {
        //   checked: true,
        //   sortable: false,
        //   resizable: true,
        //   disabled: true,
        //   width: 100,
        //   label:"Activity ID",
        //   name: "ID",
        //   prop: "displayID",
        //   align: "left",
        // },
        {
          checked: true,
          sortable: false,
          resizable: true,
          disabled: true,
          width: null,
          name: "User",
          label: 'User',
          prop: "user",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 180,
          disabled: true,
          name: "Action",
          label:'Action',
          prop: "action",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 180,
          disabled: true,
          name: "related entity",
          label: "Related entity",
          prop: "id",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 160,
          name: "Field changed",
          label:"Field changed",
          prop: "field changed",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 180,
          name: "Changed from",
          label: "Changed from",
          prop: "changed from",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 180,
          name: "Changed to",
          label: "Changed to",
          prop: "changed to",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 180,
          name: "Timestamp",
          label: "Timestamp",
          prop: "timestamp",
          align: "left",
        },
      ],
        loginUserDetails: {},
        staticData:null,
        activityDataPaginated: [],
        tableData: [],
        searchactivity: "",
        currPage: 1,
        rowsPerPage: 10,
        externalRowsPerPage: 10,
        excludedColors: ["#000000", "#FFFFFF", "#FF0000"],
        randomColor: "",
        overview: false,
        paginationLang:{   
          Rows_per_page : this.matchFromStatic("Rows per page"),
            Showing: this.matchFromStatic("Showing"),
            of: this.matchFromStatic("of"),
            results: this.matchFromStatic("results"),
            Go_to: this.matchFromStatic("Go to")}
      };
    },
    computed: {
      attachedPaginated() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      console.log("this.updatedLeadLogData",this.updatedLeadLogData.slice(start, end))
      return this.updatedLeadLogData.slice(start, end);
    },
   
    },
    watch: {
      id:{
        handler(val){
          if(val){
          this.params = val
          }
        },
        immediate: true,
        deep: true,

      },
      totalfromprop:{
        handler(val){
          console.log(val,"this.totalTableDataLength",val)
          if(val){
          this.totalTableDataLength = val
          }
        },
        immediate: true,
        deep: true,
      },
      activityData: {
        handler(val) {
            this.updatedLeadLogData= []
          console.log(val, "lastUpdate");
          this.activityDataPaginated = val;
          this.tableData = JSON.parse(JSON.stringify(val));

                // this.totalTableDataLength = val.length
      this.dupUpdatedLogData = JSON.parse(JSON.stringify(val)) 
      this.updatedLeadLogData = JSON.parse(JSON.stringify(val))
      this.updatedLeadLogData = this.updatedLeadLogData.filter((item)=>item.detailedAction.length > 0)
      console.log(this.updatedLeadLogData,"this.activityData",this.activityData)
    //   const pairs = [];
//       this.activityData.forEach((action) => {
//         const removedActions = action?.detailedAction.filter(
//           (detail) => detail?.action === "REMOVED"
//         );
//         const addedActions = action.detailedAction.filter(
//           (detail) => detail?.action === "ADDED"
//         );
//         action.detailedAction.forEach((detail) => {
//       if (detail?.action?.toLowerCase() === "updated") {
//         this.updatedLeadLogData.push({
//           property: this.formatString(detail.property),
//           removed: detail.from,
//           added: detail.to,
//           type: action.action,
//           createdAt: action.createdAt,
//           user: action.user,
//           displayID:detail?.displayID || "-"
//         });
//             }
//             if(detail?.action?.toLowerCase() =='created' || detail?.action?.toLowerCase() =='deleted' ){
//             const propertyVal = detail?.action?.toLowerCase() === "deleted" ? detail?.field : "-";
//             // const addedVal = detail?.action?.toLowerCase() == 'created' ? detail.name : null;
//             // const deletedVal = detail?.action?.toLowerCase() == 'deleted' ? detail.name : null;

//               console.log("Name:", detail.name, "Field:", detail.field, "Action:", detail.action , "propertyVal : ",propertyVal);
//               if(detail?.action?.toLowerCase() =='created'){
//                 this.updatedLeadLogData.push({
//               property: propertyVal,
//               added: detail.name,
//               type: detail.property,
//               createdAt: action.createdAt,
//               user: action.user,
//             });
//           }
//           if(detail?.action?.toLowerCase() =='deleted'){
//             this.updatedLeadLogData.push({
//               property: propertyVal,
//               removed: detail.name,
//               type: detail.property,
//               createdAt: action.createdAt,
//               user: action.user,
//             });
//           }
//             }
            
//             });
//         console.log(removedActions.length,removedActions,"addedActions",addedActions,addedActions.length,"action.detailedAction",action.detailedAction)
//         if(addedActions.length !=0 && removedActions.length !=0){
//         removedActions.map((obj1)=>{
//             addedActions.map((obj2)=>{
//                 console.log(obj1,"addObj",obj2)
//                 const commonKeysValue = Object.keys(obj1?.item || {}).filter((key) =>
//       Object.keys(obj2?.item || {}).includes(key)
//     );

//     const commonKeys = Object.keys(obj1).filter((key) =>
//   Object.keys(obj2).includes(key)
// );


// const hasCommonMatch = commonKeys.some((key) => {
//   console.log(`Comparing key '${key}':`, obj1.item[key], obj2.item[key]);
//   return obj1.item[key] === obj2.item[key];
// });
// if (hasCommonMatch) {
//   // action.detailedAction.push({
//         //   property: obj1.property,
//     console.log(commonKeysValue,"Common Keys:", commonKeys);
//         //   removed: obj1.item,
//         //   added: obj2.item,
//         //   type:obj1.item && obj2.item ? "Edited" : "",
//         //   createdAt:action.createdAt,
//         //   user:action.user
//         // });
//         this.updatedLeadLogData.push({
//           property: obj1.property,
//           removed: obj1.item,
//           added: obj2.item,
//           type:((obj1.item && obj2.item) && obj1.renameAction == null )? "Edited" : obj1.renameAction,
//           createdAt:action.createdAt,
//           user:action.user,
//           displayID:obj1?.displayID || "-"
//         })

//         // Optionally remove the original entries if required
//         // action.detailedAction = action.detailedAction.filter(
//         //   (detail) =>
//         //     !(detail.action === "REMOVED" && detail.item === obj1.item) &&
//         //     !(detail.action === "ADDED" && detail.item === obj2.item)
//         // );
//       }
//             })
//         })
//     }
//     else if(removedActions.length == 0 && addedActions.length != 0){
//         console.log("addedActions else if first")
//         addedActions.forEach((ele)=>{
//           this.updatedLeadLogData.push({
//             property: ele.property,
//             added: ele?.item ? ele?.item : ele?.to,
//             type:ele.renameAction ? ele.renameAction: ele.action,
//             createdAt:action.createdAt,
//             user:action.user,
//             displayID:ele?.displayID || "-"
//           })
//         })
//     }
//     else if(removedActions.length != 0 && addedActions.length == 0){
//         console.log("addedActions else if second")
//         removedActions.forEach((ele)=>{
//           this.updatedLeadLogData.push({
//             property: ele?.property,
//             added: ele.item,
//             type:ele.renameAction ? ele.renameAction: ele.action,
//             createdAt:action.createdAt,
//             user:action.user  ,
//             displayID:ele?.displayID || "-"
//           })
//         })
//     }
//       });
//       this.totalTableDataLength = this.updatedLeadLogData.length
//       this.dupUpdatedLogData = JSON.parse(JSON.stringify(this.updatedLeadLogData)) 
//       console.log(this.updatedLeadLogData,"this.activityData",this.activityData)
        },
        immediate: true,
        deep: true,
      },
      data: {
        handler(val) {
          this.payload = val;
          //console.log(val, "product value");
  
          this.activityDataPaginated.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );
        },
        immediate: true,
        deep: true,
      },
    },
  
    async mounted() {
      await this.$store.dispatch("loginTenantDetails");
      await this.$store.dispatch("loginUserDetails");
      // loginUserDetails
      // await this.$store.dispatch("loginTenantDetails");
      // let r = await this.$store.getters.loginTenantDetails;
      console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
      console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);
  
      this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;
  
      // this.loginUserDetails.languageAbility[0].name
      // this.loginUserDetails.languageAbility[0].code
  
      try {
        let organisationInfo = await UPCAxiosInstance.get('/multi-language');
        this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
        console.log("this.staticData:",organisationInfo , this.staticData);
        
      }
      catch (error) {
        console.error("error::", error)
      }
    },
    methods: {
      isValidDate(value) {
    // Check if the value matches the ISO 8601 date format
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    return isoDateRegex.test(value);
  },
  checkIsDateOrNot(val) {
    if (this.isValidDate(val)) {
      // Format the date
      const dateVal = this.dateAndTimeFormatter(val);
      if (dateVal === "NaN undefined, NaN 12:NaN AM") {
        return val; // Return the raw value if it matches the invalid format
      }
      return dateVal;
    }
    return val;
  },
      formatString(input) {
    if (!input) return 'N/A'; 
    return input
      .replace(/([A-Z])/g, ' $1') 
      .trim() 
      .toLowerCase() 
      .replace(/^\w/, (char) => char.toUpperCase()); 
  },
  capitalizeFirstLetterForTableAction(str) {
    if (typeof str === "string") {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } 
    else if (typeof str === "boolean") {
        const booleanString = str.toString(); 
        return booleanString.charAt(0).toUpperCase() + booleanString.slice(1).toLowerCase();
    }
    else {
        return str; 
    }
},
//     formatString(input) {
//   return input
//     .replace(/([A-Z])/g, " $1")
//     .replace(/^./, (str) => str.toUpperCase()) 
//     .trim();
// },
      rhsData(rhs) {
        console.log(rhs.action, "act123 rhsaction");
        if (rhs.action != "REMOVED") {
          if (rhs?.item?.valueSuffix) {
            const valueWithSuffix = `${rhs?.item?.value} ${rhs?.item?.valueSuffix}`;
            return this.capitalizeFirstLetter(valueWithSuffix);
          }
          if (rhs?.item?.name) {
            const nameValue = rhs.item.name;
            return this.capitalizeFirstLetter(nameValue);
          } else if (rhs?.item?.note) {
            const noteValue = rhs.item.note;
            return this.capitalizeFirstLetter(noteValue);
          } else if (rhs?.item?.new?.note) {
            const newNoteValue = rhs?.item?.new?.note;
            return this.capitalizeFirstLetter(newNoteValue);
          } else if (rhs.to) {
            const toValue = this.stripHtml(rhs.to);
            return this.capitalizeFirstLetter(toValue);
          } else if (rhs?.item?.new) {
            return this.capitalizeFirstLetter(rhs?.item?.new);
          } else if (rhs?.item?.value) {
            return this.capitalizeFirstLetter(rhs?.item?.value);
          } else if (rhs?.name) {
            return this.capitalizeFirstLetter(rhs?.name);
          } else if (rhs?.item) {
            return this.capitalizeFirstLetter(rhs?.item);
          }
        }
      },
      stripHtml(html) {
        let div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || "";
      },
      capitalizeFirstLetter(str) {
        if (typeof str !== "string" || str.length === 0) {
          return str;
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
      },
  
      lhsData(lhs) {
        console.log(lhs.action, "act123 lhsaction");
  
        if (
          // lhs.action === "ADDED" ||
          lhs.action === "UPDATED" ||
          lhs.action === "REMOVED" ||
          lhs.action === "DELETED" ||
          lhs.action === "UPDATE"
        ) {
          if (lhs?.item?.old?.note) {
            const noteValue = lhs.item.old.note;
            console.log(noteValue, "lhs?.item?.old?.note");
            return this.capitalizeFirstLetter(noteValue);
          }
          // Return the 'old' value if it exists
          if (lhs?.item?.old !== undefined && lhs?.item?.old !== null) {
            const oldValue = lhs?.item?.old;
            console.log(oldValue, "lhs?.item?.old");
            console.log(this.capitalizeFirstLetter(oldValue), "lhs?.item?.old");
            return this.capitalizeFirstLetter(oldValue);
          }
  
          // Return the note from 'old' if it exists
  
          // Return 'from' if it exists
          if (lhs.from !== undefined && lhs.from !== null) {
            const fromValue = this.stripHtml(lhs.from);
            console.log(fromValue, "lhs.from");
            return this.capitalizeFirstLetter(fromValue);
          }
  
          // Return formatted value and valueSuffix if they exist
          if (
            lhs?.item?.valueSuffix !== undefined &&
            lhs?.item?.valueSuffix !== null
          ) {
            const formattedValue = `${lhs?.item?.value} ${lhs?.item?.valueSuffix}`;
            console.log(
              formattedValue,
              "`${lhs?.item?.value} ${lhs?.item?.valueSuffix}`"
            );
            return this.capitalizeFirstLetter(formattedValue);
          }
  
          // Return 'to' if it exists
          if (lhs.to !== undefined && lhs.to !== null) {
            const toValue = this.stripHtml(lhs.to);
            console.log(toValue, "lhs.to");
            return this.capitalizeFirstLetter(toValue);
          }
          else if (lhs?.item?.value) {
            return this.capitalizeFirstLetter(lhs?.item?.value);
          }
          if (lhs?.item?.old !== undefined) {
            return this.capitalizeFirstLetter(lhs?.item?.old);
          }
          if (lhs.name !== undefined) {
            return this.capitalizeFirstLetter(lhs.name);
          }
          if (lhs?.item?.new !== undefined) {
            return this.capitalizeFirstLetter(lhs?.item?.new);
          }
          if (lhs.item?.name !== undefined) {
            return this.capitalizeFirstLetter(lhs.item?.name);
          }
          if (lhs?.item) {
            const itemValue = lhs?.item;
            return this.capitalizeFirstLetter(itemValue);
          }
        }
      },
      tooltipover(data, index) {
        let data1 = data;
        let index1 = index;
        this.ellipsis(data1, index1);
      },
      tooltipleave(data, index) {
        let data1 = data;
        let index1 = index;
        this.ellipses(data1, index1);
      },
      ellipsis(data, index) {
        console.log(data, index);
        this.$nextTick(() => {
          const element1 = document.querySelector("#" + data);
          if (element1 != null) {
            if (element1.clientWidth < element1.scrollWidth) {
              var style =
                element1.currentStyle || window.getComputedStyle(element1);
              if (style.textOverflow === "ellipsis") {
                const tooltip = document.querySelector("." + index);
                let height = tooltip.offsetHeight;
                let top = element1.getBoundingClientRect().top - height;
                let left = element1.getBoundingClientRect().left;
                tooltip.style.display = "block";
                tooltip.style.top = top + "px";
                tooltip.style.left = left + "px";
                return true;
              }
            }
            return false;
          }
        });
      },
      ellipses(data, index) {
        this.$nextTick(() => {
          const element1 = document.querySelector("#" + data);
          if (element1 != null) {
            if (element1.clientWidth < element1.scrollWidth) {
              var style =
                element1.currentStyle || window.getComputedStyle(element1);
              if (style.textOverflow === "ellipsis") {
                const tooltip = document.querySelector("." + index);
                // let top = element1.getBoundingClientRect().top - 40
                tooltip.style.display = "none";
                //  tooltip.style.top = top+'px'
                return true;
              }
            }
            return false;
          }
        });
      },
      pathDetails(val) {
        if (val.item) {
          //console.log(val.item, "pathDetails //console.log");
        }
      },
      headermessage(path) {
        path.forEach((change) => {
          //console.log(change.path[0]);
          if (
            change.path[0] === "category" ||
            change.path[0] === "validFor" ||
            change.path[0] === "endOfDate" ||
            change.path[0] === "endOfLife" ||
            change.path[0] === "brand" ||
            change.path[0] === "description" ||
            change.path[0] === "name"
          ) {
            //console.log("overview");
            return "overview";
          } else if (change.path[0] === "productSpecCharacteristic") {
            //console.log("productSpecCharacteristic");
            return "Features";
          } else if (change.path[0] === "serviceResourceMapping") {
            //console.log("serviceResourceMapping");
            return "Service";
          } else if (change.path[0] === "attachment") {
            //console.log("attachment");
            return "Documents";
          } else if (change.path[0] === "notes") {
            //console.log("notes");
            return "Notes";
          }
        });
        // const overview = ["category", "validFor", "endOfDate", "endOfLife", "brand", "description", "name"];
        // const Features = ["productSpecCharacteristic"];
        // const Service = ["serviceResourceMapping"];
        // const Documents = ["attachment"];
        // const Notes = ["notes"];
  
        // // Initialize empty arrays for each category
        // const categorizedChanges = {
        //   overview: [],
        //   Features: [],
        //   Service: [],
        //   Documents: [],
        //   Notes: []
        // };
  
        // // Process each change
        // path.forEach(change => {
        //   const path = change.path[0]; // Assuming path is an array with at least one element
  
        //   // Categorize based on the first path element
        //   if (overview.includes(path)) {
        //     categorizedChanges.overview.push(change);
        //   } else if (Features.includes(path)) {
        //     categorizedChanges.Features.push(change);
        //   } else if (Service.includes(path)) {
        //     categorizedChanges.Service.push(change);
        //   } else if (Documents.includes(path)) {
        //     categorizedChanges.Documents.push(change);
        //   } else if (Notes.includes(path)) {
        //     categorizedChanges.Notes.push(change);
        //   }
        // });
  
        // // Logging the categorized changes
        // //console.log('Overview:', categorizedChanges.overview);
        // //console.log('Features:', categorizedChanges.Features);
        // //console.log('Service:', categorizedChanges.Service);
        // //console.log('Documents:', categorizedChanges.Documents);
        // //console.log('Notes:', categorizedChanges.Notes);
      },
      // headermessage(path) {
  
      //   const overview = [
      //     "category",
      //     "validFor",
      //     "endOfDate",
      //     "endOfLife",
      //     "brand",
      //     "description",
      //     "name",
      //   ];
  
      //   path.map((item) => {
      //     this.overview = item.filter((value) => overview.includes(value));
      //   });
  
      //   //console.log(path, "categorycategory");
      // },
  
      getLabel() {
        if (this.areArraysEqual(this.array, this.array2)) {
          return "Features";
        } else if (this.areArraysEqual(this.array, this.array3)) {
          return "Features";
        } else if (this.areArraysEqual(this.array, this.array4)) {
          return "Service and resource";
        } else if (this.areArraysEqual(this.array, this.array5)) {
          return "Related offers";
        } else if (this.areArraysEqual(this.array, this.array6)) {
          return "Documents";
        } else if (this.areArraysEqual(this.array, this.array7)) {
          return "Notes";
        } else {
          return "No match";
        }
      },
      areArraysEqual(array1, array2) {
        if (array1.length !== array2.length) {
          return false;
        }
        for (let i = 0; i < array1.length; i++) {
          if (array1[i] !== array2[i]) {
            return false;
          }
        }
        return true;
      },
     async activitysearch(val) {
        if (val !== "") {
          // this.searchactivity = val
          // let searchData = this.searchNestedObjects(
          //   this.dupUpdatedLogData,
          //   val.toLowerCase()
          // );
          // this.updatedLeadLogData = JSON.parse(JSON.stringify(searchData));
          // this.totalTableDataLength = this.updatedLeadLogData .length
          console.log("activity-search",val)
          this.$emit("activity-search",val)
        } else {
          this.updatedLeadLogData = JSON.parse(JSON.stringify(this.dupUpdatedLogData));
          console.log(this.updatedLeadLogData,"this.dupUpdatedLogData",this.dupUpdatedLogData)
          this.totalTableDataLength = this.totalfromprop
          // this.$emit("reload-data","relaod")
          console.log("activity-search",val)
          this.$emit("activity-search",val)
        }
      },
  
      searchNestedObjects(objects, key) {
        return objects.reduce((acc, obj) => {
          const foundInObject = Object.entries(obj).some(([k, v]) => {
            if (typeof v === "object" && v !== null) {
              // Recursively search nested objects
              console.log(
                this.searchNestedObjects([v], key).length > 0,
                "object if"
              );
  
              return this.searchNestedObjects([v], key).length > 0;
            } else if (typeof v === "string" && k !== "password") {
              // Convert date strings to formatted versions for comparison
              if (Date.parse(v)) {
                const formattedDate = this.formatDate(v).toLowerCase();
                console.log("dateformate", formattedDate);
                console.log("dateformate", formattedDate.includes(key));
  
                return formattedDate.includes(key);
              }
              // Normal string comparison
              console.log(v.toLowerCase().includes(key), "normal");
  
              return v.toLowerCase().includes(key);
            }
            return false;
          });
  
          if (foundInObject) {
            acc.push(obj);
          }
  
          return acc;
        }, []);
      },
  
      // Helper function to format the date string as in UI
      formatDate(isoString) {
        const date = new Date(isoString);
        const options = {
          year: "numeric",
          month: "short", // 'short' gives abbreviated month names (e.g., Sep)
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true, // Enables AM/PM format
        };
        return new Intl.DateTimeFormat("en-US", options).format(date);
      },
      searchInArray(searchTerm, dataArray) {
        const searchTermLower = searchTerm.toLowerCase();
  
        if (searchTermLower !== "") {
          const result = dataArray.filter((obj) => {
            return Object.values(obj).some((value) => {
              if (typeof value === "string") {
                return value.toLowerCase().includes(searchTermLower);
              }
              return false;
            });
          });
  
          return result;
        }
      },
      serialNumber(index) {
        return (this.currPage - 1) * this.rowsPerPage + index + 1;
      },
  
      currentPage(val) {
        this.currPage = val;
        this.$emit("activitycurrentpage", val);
      },
      changeRowsPerPage(count) {
        this.rowsPerPage = count;
        this.$emit("activityrowpage", count);
      },
      isDate(value) {
        // Return true if the value matches "9999-12-31T00:00:00Z" exactly
        if (value === "9999-12-31T00:00:00Z") {
          return true;
        }
  
        const date = new Date(value);
        return (
          !isNaN(date.getTime()) &&
          typeof value === "string" &&
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/.test(value)
        );
      },
      dateTimeFormatter(value) {
        const date = new Date(value);
        const options = { month: "short", day: "numeric", year: "numeric" };
        return date.toLocaleDateString("en-US", options); // Example formatting
      }, // Output: Feb 20,2024 07:19AM
    },
  };
  </script>
  
  <style lang="scss">
  .ellipsis_activityLog {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .center-align-activityLog {
    padding-left: 46px;
  }
  .activitylog_drawer_componet {
    .section_accordtion_supplier {
      height: 86vh !important;
    }
    .activirylog_pagination {
      position: fixed;
      width: 62%;
      margin: -11px 0px;
      background: white;
      height: 100%;
    }
  }
  .activelog_page_supplier::-webkit-scrollbar {
    display: none;
  }
  .activirylog_pagination {
    position: fixed;
    width: 75%;
    margin: -11px 0px;
    background: white;
    height: 100%;
  }
  .activelog_page_supplier {
    .notepad_supplier_accordion {
      .accordion-item .accordion-content {
        // height: 40px !important;
        flex-direction: column;
        padding: 0 !important;
        background-color: white;
        border: none;
      }
      .activitylog_loop {
        border-bottom: 1px solid #d8d8d8;
      }
      .activitylog_loop:last-child {
        border-bottom: none;
      }
      .body_activelog_inside_detail {
        height: 40px;
        padding: 6px 16px 6px 43px;
        border-bottom: 1px solid #d8d8d8;
        display: flex;
        align-items: center;
      }
      .body_activelog_inside_detail:last-child {
        border-bottom: none;
      }
      .body_activelog_inside {
        display: flex;
        // justify-content: space-between;
        font-weight: 100;
        width: 100%;
        border-top: 1px solid #d8d8d8;
        padding: 6px 16px 6px 43px;
        background-color: #d8d8d833;
      }
    }
  
    .header_activelog_inside {
      .whiteBox_activirylog_hidden {
        width: 20px;
        height: 20px;
        background: white;
        position: absolute;
        /* top: 5px; */
      }
      .whiteDapa {
        width: 20px;
        height: 20px;
        background: white;
        position: absolute;
        margin-left: -29px;
      }
      // border:1px solid brown;
      display: flex;
      position: relative;
      // justify-content: space-between;
      align-items: center;
      font-weight: 100;
      // .Actions {
      //   width: 50%;
      // }
      .made_profile {
        // width: 50%;
        gap: 16px;
        display: flex;
        align-items: center;
        .profile_pic_activityLog {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 1px solid #d3d3d3;
        }
  
        // .Made {
        // }
      }
      // .Last {
      //   width: 30%;
      // }
    }
    .header_activelog {
      height: 40px;
      background-color: rgba(216, 216, 216, 0.2);
      border: 1px solid #d8d8d8;
      border-radius: 6px 6px 0px 0px;
      padding: 6px 16px 6px 42px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
    }
    .section_accordtion_supplier {
      height: 78vh;
    }
    .notepad_supplier_accordion {
      display: flex;
      flex-direction: column;
      gap: 0px !important;
      width: 100%;
  
      border-bottom: 0;
  
      .accordion-item {
        border-top: none !important;
        border: 1px solid #d8d8d8;
        border-radius: 0px !important;
        .hide-arrow_activtylog {
          .accordion-header i {
            visibility: hidden !important;
          }
        }
        .accordion-header {
          height: 60px;
          padding: 16px;
          display: flex;
          flex-direction: row;
          border-bottom: 0 !important ;
          justify-content: space-between;
          align-items: center;
          color: var(--hlx-text-color-primary);
          cursor: pointer;
          gap: 10px;
        }
      }
      .noter_fullhead_supplier_accordion {
        padding: 6px;
      }
      .noter_head_supplier_accordion {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        gap: 4px;
  
        .note_username_supplier {
          font-weight: 600;
          .note_username_supplier_color {
            color: #0065b2;
          }
        }
        .note_date_time_supplier {
          margin-left: auto;
          font-weight: 400 !important;
          .note_date_time_supplier_value {
            margin-right: 10px;
          }
        }
      }
    }
    .supplier-delete-action {
      color: var(--hlx-color-error) !important;
      position: absolute;
      top: 154px;
      right: 22px;
      font-size: 1.3em;
    }
  
    .header-add-supplier {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      .tablehead-add-supplier {
        font-weight: bold;
      }
      .search-add-supplier {
        display: flex;
        gap: 10px;
      }
    }
    .contacts-username-supplier {
      display: flex;
      align-items: center;
      .right-head-supplier {
        display: flex;
        flex-direction: column;
      }
      .head-supplier-left {
        display: flex;
        justify-content: center;
        width: 44px;
        align-items: center;
        font-size: 45px;
        margin-right: 10px;
      }
      .head-supplier-right {
        display: flex;
        font-size: 14px;
        font-weight: bold;
        .head-supplier-name {
          margin-right: 10px;
        }
      }
      .head-supplier-address {
        font-size: 10px;
        color: #929191;
        margin-top: 3px;
      }
    }
  }
  </style>
  