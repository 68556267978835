<template>
  <!-- <span v-if="!isComplete" class="custom-theme" :style="{ '--hlx-color-primary': bannerStyle.backgroundColor}"> -->
  <span
    v-if="!isComplete && expired == false"
    class="custom-theme"
    :style="{ '--hlx-color-primary': bannerStyle.backgroundColor }"
  >
    <div v-if="data_loaded == null">
      <hlx-loader load-type="dotted-jumper" />
    </div>
    <hlx-modal
      :modal-active="data_loaded"
      :height="'220px'"
      :width="'400px'"
      :modal="true"
    >
      <template #header>
        <div style="margin-left: 0px">{{ title }}</div>
      </template>
      <template #content>
        <div class="modal-content">
          <p>{{ errorMessage }}</p>
        </div>
      </template>
    </hlx-modal>
    <section v-if="data_loaded == false && expired == false" class="form-body">
      <!-- <section v-if="data_loaded == false" class="form-body"> -->
      <div style="display: flex; flex-direction: row">
        <div class="banner" :style="bannerStyle" v-html="bannerHtml?.[selectedLanguage?.code?.toLowerCase()]"></div>
        <div
          class="language-selector"
          :style="{ 'background-color': bannerStyle.backgroundColor }"
        >
          <!-- Trigger Button -->
          <button class="language-button" @click="toggleDropdown">
            <span style="padding-left: 10px; padding-right: 10px">
              <i class="icon-globe-regular" style="font-size: 16px"></i>
            </span>
            {{ selectedLanguage?.code?.toUpperCase() }}
            <span :class="{ open: isOpen }"
              ><i class="icon-angle-down-regular"></i
            ></span>
          </button>

          <!-- Dropdown -->
          <ul v-if="isOpen" class="dropdown-menu">
            <li
              v-for="lang in langList"
              :key="lang.code"
              :class="{ selected: lang.checked }"
              @click="selectLanguage(lang)"
            >
              {{ lang?.code?.toUpperCase() }}
            </li>
          </ul>
        </div>
      </div>
      <section class="form-content">
        <div
          v-for="(section, index) in customSchema"
          :key="index"
          style="width: 50%"
        >
          <fieldRenderer
            :key="updateFieldRenderKeyForContact"
            :layout="'1-column'"
            :custom-language="customLanguage"
            :schema-payload="customSchema[index]"
            :data-payload="dataPayload[index]"
            :is-add-clicked="isAddButtonClicked"
            :trigger-rule="triggerRuleForContacts"
            :updated-schema-after-remap="updatedContactSchemaForRemap"
            :is-save-clicked="isSaveButtonClickedForContact"
            :is-modal-window="modalPropsForFieldRender"
            :email-data-props="emailArrayForContacts"
            :status-list="[
              'Open',
              'Qualified',
              'Unqualified',
              'Network approved',
              'Network rejected',
              'Awaiting network approval',
              'Converted',
              'Closed Lost',
            ]"
            :parent-class-name="'parent-container-for-lead-maintable-contact'"
            @edited-payload="getLeadData($event, index)"
            @updated-schema="getContactUpdatedSchema"
            @input-focus-out="getContactInputFocusOut"
          ></fieldRenderer>
        </div>
      </section>
      <section
        class="footer"
        style="
          border-top: 1px solid #d8d8d8;
          padding-right: 40px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 10vh; /* Set footer height */
          background-color: #fff;
          position: sticky;
          bottom: 0;
          z-index: 100;
        "
      >
        <div
          class="go-back"
          style="
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          <hlx-button class="secondary sm" @click="cancel">{{
            staticMatch("Cancel")
          }}</hlx-button>
          <hlx-button
            class="primary sm"
            :disabled="isSubmit"
            style="margin-left: 20px"
            @click="sendWorkFlow"
            >{{ staticMatch("Submit") }}</hlx-button
          >
        </div>
      </section>
    </section>
  </span>

  <div
    v-else-if="isComplete && expired == false"
    class="custom-theme"
    :style="{
      '--hlx-color-primary': bannerStyle.backgroundColor,
      height: '100%',
    }"
  >
    <section class="form-body-complete" style="height: 100%">
      <div style="display: flex; flex-direction: row">
        <div class="banner" :style="bannerStyle" v-html="bannerHtml?.[selectedLanguage?.code?.toLowerCase()]"></div>
        <div
          class="language-selector"
          :style="{ 'background-color': bannerStyle.backgroundColor }"
        >
          <!-- Trigger Button -->
          <button class="language-button" @click="toggleDropdown">
            <span style="padding-left: 10px; padding-right: 10px">
              <i class="icon-globe-regular" style="font-size: 16px"></i>
            </span>
            {{ selectedLanguage?.code?.toUpperCase() }}
            <span :class="{ open: isOpen }"
              ><i class="icon-angle-down-regular"></i
            ></span>
          </button>

          <!-- Dropdown -->
          <ul v-if="isOpen" class="dropdown-menu">
            <li
              v-for="lang in langList"
              :key="lang.code"
              :class="{ selected: lang.checked }"
              @click="selectLanguage(lang)"
            >
              {{ lang?.code?.toUpperCase() }}
            </li>
          </ul>
        </div>
      </div>
      <section class="form-content" style="height: 100%">
        <div class="complete-form" style="height: 100%">
          <div style="padding-bottom: 48px">
            <i
              class="icon-check-circle-filled"
              :style="{
                color: bannerStyle.backgroundColor,
                'font-size': '54px',
              }"
            ></i>
          </div>
          <div style="padding-bottom: 10px">
            <span
              style="font-size: 20px; font-weight: 800"
              :style="{ 'font-family': 'QuickSand' }"
              >{{
                staticMatch("Your form has been submitted successfully")
              }}</span
            >
          </div>

          <div
            style="font-size: 16px; color: #767676"
            :style="{ 'font-family': 'QuickSand' }"
          >
            <span>{{
              staticMatch(
                "Check your email for details regarding your submission"
              )
            }}</span>
          </div>
        </div>
      </section>
    </section>
  </div>
  <div
    v-else-if="expired == true"
    class="custom-theme"
    :style="{
      '--hlx-color-primary': bannerStyle.backgroundColor,
      height: '100%',
    }"
  >
    <section class="form-body-complete" style="height: 100%">
      <div style="display: flex; flex-direction: row">
        <div class="banner" :style="bannerStyle" v-html="bannerHtml?.[selectedLanguage?.code?.toLowerCase()]"></div>
        <div
          class="language-selector"
          :style="{ 'background-color': bannerStyle.backgroundColor }"
        >
          <!-- Trigger Button -->
          <button class="language-button" @click="toggleDropdown">
            <span style="padding-left: 10px; padding-right: 10px">
              <i class="icon-globe-regular" style="font-size: 16px"></i>
            </span>
            {{ selectedLanguage?.code?.toUpperCase() }}
            <span :class="{ open: isOpen }"
              ><i class="icon-angle-down-regular"></i
            ></span>
          </button>

          <!-- Dropdown -->
          <ul v-if="isOpen" class="dropdown-menu">
            <li
              v-for="lang in langList"
              :key="lang.code"
              :class="{ selected: lang.checked }"
              @click="selectLanguage(lang)"
            >
              {{ lang?.code?.toUpperCase() }}
            </li>
          </ul>
        </div>
      </div>
      <section class="form-content" style="height: 100%">
        <div class="complete-form" style="height: 100%">
          <div style="padding-bottom: 48px">
            <i
              class="icon-exclamation-circle-filled"
              :style="{
                color: bannerStyle.backgroundColor,
                'font-size': '54px',
              }"
            ></i>
          </div>
          <div style="padding-bottom: 10px">
            <span
              style="font-size: 20px; font-weight: 800"
              :style="{ 'font-family': 'QuickSand' }"
              >{{
                staticMatch(
                  "Sorry, this form has expired and is no longer available"
                )
              }}</span
            >
          </div>

          <div
            style="font-size: 16px; color: #767676"
            :style="{ 'font-family': 'QuickSand' }"
          >
            <span>{{ staticMatch("Please contact your admin") }}</span>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import fieldRenderer from "@/components/FieldRenderer.vue";
import {
  UPCAxiosInstance,
  DMNAxiosInstance,
  MLTAxiosInstance,
  BPMNAxiosInstance,
} from "@/config/axiosConfig";
import VueCookies from "vue-cookies";
import diff from "deep-diff";

export default {
  components: {
    fieldRenderer,
  },

  
  data() {
    return {
      
      isOpen: false,
      expired: false,
      isComplete: false,
      isSubmit: true,
      externalVisibilityRule: [],
      externalRule: "",
      bannerHtml: {},
      bannerStyle: {
        height: "75px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        fontSize: "18px",
        backgroundColor: "#00549a",
      },
      formConfig: {},
      notification: {},
      customLanguage:{},
      instanceId: "",
      workflowId: "",
      module: "",
      formId: "",
      dataId: "",
      taskId: "",
      dataPayload: {},
      customSchema: {},
      data_loaded: null,
      errormessage: "",
      originalPayload: {},
      sites: [],
      langList: [],
      title: "",
      isNeedToCallWorkflowCompletion: false,
      unchanged: {},
      formFeeder: {},
      company: "", // Data used for triggering actions
      userId: "", // Data used for triggering actions
    };
  },
  computed: {
    selectedLanguage() {
      // Find the currently selected language
      return this.langList.find((lang) => lang.checked) || this.langList[0];
    },
  },
  async mounted() {
    if (!this.$route.query.access) {
      alert("Unauthorized access. Please login to view this page.");
      return;
    }
    const isValidTokenResponse = await MLTAxiosInstance.get(
      "/tokenblocker/v1/isValidToken?token=" +
        this.$route.query.access +
        "&isNeedToVerify=true"
    );
    if (!isValidTokenResponse.data) {
      alert("Unauthorized access. Please login to view this page.");
      return;
    }

    const {
      isValid,
      instanceId,
      workflowId,
      taskId,
      module,
      dataId,
      formId,
      isNeedToCallWorkflowCompletion,
    } = isValidTokenResponse.data;
    this.instanceId = instanceId;
    console.log(instanceId,"instanceId")
    let tinyUrl = await DMNAxiosInstance.get(
      `/tinyUrl/instance/${this.instanceId}`
    );
    if (tinyUrl.data.ok == 0) {
      this.expired = false;
    
    } else {
      this.expired = true;
    }
    this.workflowId = workflowId;
    this.formId = formId;
    this.taskId = taskId;
    this.module = module;
    this.dataId = dataId;
    this.isNeedToCallWorkflowCompletion = isNeedToCallWorkflowCompletion;

    if (!isValid) {
      alert("Unauthorized access. Please login to view this page.");
      return;
    }
    // Fetch by module and id
    if (!instanceId || !workflowId || !taskId || !module || !dataId) {
      alert("Invalid request. Please provide module and id to view this page.");
      return;
    }

    // this.$cookies.set("token", this.$route.query.access);
    // VueCookies.set("token", this.$route.query.access);

    const individualRes = await MLTAxiosInstance.get(
      "partymanagement/individual/auth"
    );
    let company = individualRes.data.company;
    this.company = company;
    this.userId = individualRes.data.userId;
    this.$cookies.set("company", company);
    VueCookies.set("company", company);
    // Initializing formFeeder data
    this.formFeeder = {
      lead: { leadId: this.dataId },
    };
    let moduleData = await DMNAxiosInstance.get(`/form/${this.formId}`);

    this.customSchema = moduleData.data.data.schema;
    // this.bannerHtml = moduleData.data.data.html;
    this.bannerHtml =  moduleData.data.data.html
    console.log(this.bannerHtml, "this.bannerHtml")
    this.bannerStyle = moduleData.data.data.style;
    this.bannerStyle["font-family"] = "QuickSand";
    this.bannerStyle["padding-left"] = "100px";

    // const response = await UPCAxiosInstance.get(`/${module}/${this.dataId}`);

    let response = await UPCAxiosInstance.get(
      "/" + module + "/" + dataId + "?isAggregated=true",
      {
        headers: {
          Authorization: "Bearer " + this.$route.query.access,
        },
      }
    );

    this.originalPayload = response.data;
    this.unchanged = JSON.stringify(response.data);
    let site = response.data.relatedParty.filter((i) => i?.["@type"] == "site");
    for (var i in site) {
      let siteRes = await UPCAxiosInstance.get(`/geoSite/${site[i]?.id}`);

      this.sites.push(siteRes.data);
    }

    let formConfig = {
      leadData: response.data,
      customSchema: this.customSchema,
    };

    const formData = await DMNAxiosInstance.post(
      `rule_engine/rule/execute/${moduleData.data.data.rule}`,
      { payload: { formConfig: { ...formConfig } } }
    );
    if (formData) {
      this.data_loaded = false;
    }
    this.customSchema = formData.data.data.formConfig.formData.customSchema;
    this.dataPayload = formData.data.data.formConfig.formData.dataPayload;
    this.externalVisibilityRule = this.findKeysWithExternalVisibilityRule(
      this.customSchema
    );

    let filterData = {
      type: "filter",
      company: "Halleyx",
      module: "hierarchyaccounts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 10,
      filterQuery: {
        tradingName: company,
      },
      searchQuery: "",
    };

    try {
      const tenantDataData = await MLTAxiosInstance.post(
        `util/filterData`,
        filterData
      );
      this.langList = tenantDataData?.data?.data[0].languageAbility?.map(
        (language) => ({
          ...language,
          checked: false,
        })
      );
      console.log(this.langList, "langList");
    } catch (error) {
      console.error("Error fetching language data:", error);
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectLanguage(lang) {
      // Mark the selected language as checked
      this.langList.forEach((language) => (language.checked = false));
      lang.checked = true;

      // Close the dropdown
      this.isOpen = false;
      this.customLanguage = lang;
      // Perform additional actions, like updating the app's locale
      console.log(`Selected language: ${lang.code}`);
    },
    staticMatch(searchTerm) {
      try {
        let data = JSON.parse(localStorage.getItem("remsCache"));
        console.log("Retrieved data from cache:::", data);
        if (
          data === null ||
          data === "null" ||
          data === undefined ||
          data === "undefined" ||
          data === ""
        ) {
          data = [];
        }

        let languageAbility = [];
        let newsq = [this.customLanguage];

        if (
          newsq === null ||
          newsq === "null" ||
          newsq === undefined ||
          newsq === "undefined" ||
          newsq === ""
        ) {
          languageAbility = [{ name: "english", code: "en" }];
        } else {
          languageAbility = newsq;
        }

        // Find the object in the data array that matches the search term in the 'en.label' field
        const matchedObject = data.find(
          (item) =>
            item.en && item.en.label.toLowerCase() === searchTerm.toLowerCase()
        );

        // If no match is found, return null
        if (!matchedObject) {
          // console.log("no match matchFromStatic:::", searchTerm);
          return searchTerm;
        }

        // Iterate through languageAbility to find the desired translation
        for (const lang of languageAbility) {
          const translation = matchedObject[lang.code]?.label;
          if (translation) {
            // console.log("try matchFromStatic:::", translation, searchTerm);
            return translation;
          }
        }

        // If no translation is found, return null
        return searchTerm;
      } catch (error) {
        console.error("Error matchFromStatic:::", error);
        return searchTerm;
      }
    },
    findKeysWithExternalVisibilityRule(obj) {
      const pathsWithRule = [];

      // Recursive function to traverse the object
      function scanObject(obj, currentPath = "") {
        if (obj && typeof obj === "object") {
          for (const key in obj) {
            const newPath = currentPath ? `${currentPath}.${key}` : key;
            if (obj[key] && typeof obj[key] === "object") {
              // Check if 'externalVisibilityRule' exists in the current object
              if ("externalVisibilityRule" in obj[key]) {
                pathsWithRule.push(newPath);
              }
              // Recurse into nested objects
              scanObject(obj[key], newPath);
            }
          }
        }
      }

      scanObject(obj);
      return pathsWithRule;
    },
    async cancel() {
      this.formFeeder = {
        lead: { leadId: this.dataId },
      };
      let moduleData = await DMNAxiosInstance.get(`/form/${this.formId}`);

      this.customSchema = moduleData.data.data.schema;
      this.bannerHtml =  moduleData.data.data.html
      this.bannerStyle = moduleData.data.data.style;

      const response = await UPCAxiosInstance.get(
        `/${this.module}/${this.dataId}`
      );
      this.originalPayload = response.data;
      let site = response.data.relatedParty.filter(
        (i) => i?.["@type"] == "site"
      );
      for (var i in site) {
        let siteRes = await UPCAxiosInstance.get(`/geoSite/${site[i]?.id}`);

        this.sites.push(siteRes.data);
      }

      let formConfig = {
        leadData: response.data,
        customSchema: this.customSchema,
      };

      const formData = await DMNAxiosInstance.post(
        `rule_engine/rule/execute/${moduleData.data.data.rule}`,
        { payload: { formConfig: { ...formConfig } } }
      );
      if (formData) {
        this.data_loaded = false;
      }
      this.customSchema = formData.data.data.formConfig.formData.customSchema;
      this.dataPayload = formData.data.data.formConfig.formData.dataPayload;
    },
    getNestedValue(obj, path) {
      return path.split(".").reduce((acc, key) => acc && acc[key], obj);
    },
    setNestedValue(obj, path, value) {
      path
        .split(".")
        .reduce(
          (acc, key, idx, arr) =>
            (acc[key] = idx === arr.length - 1 ? value : acc[key] || {}),
          obj
        );
    },

    async getLeadData(val, key) {
      this.dataPayload[key] = val;
      console.log("dataPayload", this.dataPayload)
      // for (var site in this.dataPayload) {
      //   if (site.toLowerCase().includes("siteinformation")) {
      //     this.dataPayload[site].approvalOptions =
      //       this.dataPayload.approval.approvalOptions;
      //   }
      // }
      for (var i in this.externalVisibilityRule) {
        let response = await DMNAxiosInstance.post(
          `rule_engine/rule/execute/${
            this.getNestedValue(
              this.customSchema,
              this.externalVisibilityRule[i]
            ).externalVisibilityRule
          }`,
          { payload: { execution: { dataPayload: this.dataPayload } } }
        );
        this.setNestedValue(
          this.customSchema,
          `${this.externalVisibilityRule[i]}.isVisible`,
          response.data.data.execution.visible
        );
      }

      if (this.dataPayload.approval.approvalOptions != null) {
        this.isSubmit = false;
      } else {
        this.isSubmit = true;
      }

      // this.dataPayload = JSON.parse(JSON.stringify(this.dataPayload));
      // let response = await DMNAxiosInstance.post(
      //   `rule_engine/rule/execute/${ moduleData.data.data.rule}`,
      //   { payload: { formConfig: { ...formConfig } } }
      // );
    },
    async sendWorkFlow() {
      if (this.isNeedToCallWorkflowCompletion) {
        let ruleRes = await DMNAxiosInstance.post(
          `rule_engine/rule/execute/67876d99cb521b724a22c008`,
          {
            payload: {
              originalPayload: this.originalPayload,
              dataPayload: this.dataPayload,
              sites: this.sites,
            },
          }
        );
        delete ruleRes.data.data.originalPayload?._id;
        delete ruleRes.data.data.originalPayload?.__v;

        await UPCAxiosInstance.patch(`/${this.module}`, {
          ...ruleRes.data.data.originalPayload,
        });

        for (var i in ruleRes.data.data.sites) {
          await UPCAxiosInstance.patch(`/geoSite`, {
            ...ruleRes.data.data.sites[i],
          });
        }

        const completeUserTaskPayload = {
          instanceId: this.instanceId,
          taskId: this.taskId,
          workflowId: this.workflowId,
          context: {
            [this.module]: ruleRes.data.data.originalPayload,
          },
        };
        var differences = diff(
          ruleRes.data.data.originalPayload,
          JSON.parse(this.unchanged)
        );
        console.log(
          differences,
          ruleRes.data.data.originalPayload,
          JSON.parse(this.unchanged),
          "xoxo"
        );

        console.log();

        // Call complete user task API with payload to complete the task
        const completeUserTaskResponse = await BPMNAxiosInstance.post(
          "/engine/v1/completeTask",
          completeUserTaskPayload,
          {
            headers: {
              Authorization: "Bearer " + this.$route.query.access,
            },
          }
        );
        let patched = await UPCAxiosInstance.patch(`/${this.module}`, {
          ...ruleRes.data.data.originalPayload,
        });

        if (completeUserTaskResponse && patched) {
          this.isComplete = true;
          let res = await DMNAxiosInstance.post(
            `rule_engine/rule/execute/678a7b14cb521b724a22c039`,
            {
              payload: {
                execution: {
                  differences: differences,
                  leadData: ruleRes.data.data.originalPayload,
                  user: { userId: this.userId, company: this.company },
                },
              },
            }
          );

          await UPCAxiosInstance.post(
            "/activityLog/v1",
            res.data.data.execution.logs
          );
          if(this.expired == false){
            await DMNAxiosInstance.post(`/tinyUrl/instance`, {
        data: this.formId,
        url: this.$route.fullPath,
        instanceId: this.instanceId,
      });
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.complete-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
}

.form-body {
  display: flex;
  flex-direction: column;

  .banner {
    height: 75px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    background-color: #00549a;
  }

  .form-content {
    padding: 20px 30px;
  }

  .header-title {
    color: #000;
    font-family: "Open Sans", sans-serif;
    padding: 30px 0 20px 0;
    font-size: 16px;
  }
}

.language-selector {
  position: relative;
  display: flex;
  align-items: center;
}

.language-button {
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.language-button span {
  font-size: 16px;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.language-button span.open {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  padding: 5px 0;
  width: 100px; /* Adjust width to match design */
  text-align: center;
}

.dropdown-menu li {
  padding: 8px 10px;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown-menu li.selected {
  font-weight: bold;
  background-color: #e6f7ff;
}
</style>
