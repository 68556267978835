<template>
  <!--lead filter drawer-->
  <hlx-drawer
    :show="show_filter"
    :width="300"
    position="right"
    :header="false"
    :footer="false"
    :show-close-icon="true"
    @close="show_filter = false"
  >
    <template #body>
      <div style="display: flex">
        <FilterComponent
          :filterpanelname="'saleslead'"
          :company-name="companyName"
          :query-value="query"
          :display-data="filter"
          :module="'saleslead'"
          @filtered-data="supplierfilterData"
        ></FilterComponent>
      </div>
    </template>
  </hlx-drawer>

  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />

  <!-- Modal window for adding new lead -->
  <div class="lead-add-main-table-modal">
    <hlx-modal
      :key="modalKey"
      :modal-active="showAddNewLeadModal"
      :close-icon="true"
      :outer-layer-close-option="false"
      :height="'700px'"
      :width="'800px'"
      :modal="true"
      @close="closeAddNewLeadModal()"
    >
      <template #header>
        <div>
          <!-- Add lead -->
          {{ loadStaticData("LANG-0005") }}
          <!-- add lead -->
        </div>
      </template>
      <template #content>
        <!-- New lead modal-->
        <div
          v-if="
            showAddNewLeadModal &&
            !showAllSitesModal &&
            !showSiteInformationModal
          "
          class="modal-content"
        >
          <section class="lead-modal-top">
            <section style="display: flex">
              <span class="lead-modal-top-num"> 1 </span>
              <span class="lead-modal-top-name">
                <!-- Account information -->
                {{ matchFromStatic("Account information") }}
              </span>
            </section>
            <span class="lead-modal-top-line"></span>
            <section style="display: flex">
              <span class="lead-modal-top-num" style="background: #aaaaaa">
                2
              </span>
              <span class="lead-modal-top-name" style="color: #aaaaaa">
                <!-- Site information -->
                {{ matchFromStatic("Site information") }}
              </span>
            </section>
          </section>
          <!-- Add new lead modal window content -->
          <section class="lead-modal-content">
            <!-- <span class="left-section" style="display:none">
            <div style="display: flex; justify-content: space-between;margin-bottom: 20px">
            <hlx-file-upload
              :key="profileUploadComponent"            
              :file-type="'.xlsx, .csv'"
              :file-size-limit="'20mb'"
              :uploadtype="'Rectangle'"
              :style-background="backgrounStyle"
              :height="100"
              :width="100"
              :read-only="false"
              :custom-icon="icon"
              :type ="'profile-upload'"
              :content="'Import'"
              @native-data="profileUpload"
            />
            <span style="width: 200%">
              <div
                :style="{ 'margin-bottom': customnameerror ? '-7.6%' : 0 ,'width': '100%','display':'flex','align-items': 'baseline','gap':'4px'}"
                >
                <span style="width:100%">
                <hlx-input
                   v-model:value="leadPayload.name"
                  :pre-val="leadPayload.name"
                  :label-value="'Company name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </span>
                <i style="color: #A6A6A6;" class="icon-info-circle-regular"></i>
              </div>

              <div style="width: 100%">
                <hlx-status
                  :type="'select-status'"
                  :prevalue="leadPayload.status"
                  :status-option="filteredstatus"
                  @emit-select="leadStatus"
                >
                </hlx-status>
              </div>
            </span>
          </div>
          <section class="left-section-select">
            <div>
          <hlx-select
             :key="componentKey"
              v-model:value="leadPayload.relatedParty[1].value"
              :pre-value="leadPayload.relatedParty[1].value"
              :inline-search="true"
              :options="leadSourceOptions"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Lead source'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="getLeadSource"
            ></hlx-select>
          </div>
        <div>
            <hlx-select
            :key="componentKey"
                 v-model:value="leadPayload.type"
              :pre-value="leadPayload.type"
              :inline-search="true"
              :options="leadTypeOptions"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Lead type'"
              :prop-value="'name'"
              :display-error="true"
              :label="'name'"
              :label-animation="true"
              :clearable="true"
              @selected-value="getLeadType"
            ></hlx-select>
          </div>
        <div>
            <hlx-select
            :key="componentKey"
                 v-model:value="leadPayload.dwellingtype"
              :pre-value="leadPayload.dwellingtype"
              :inline-search="true"
              :display-error="true"
              :options="dwellingTypeOptions"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Dwelling type'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :clearable="true"
              @selected-value="getLeadDwellingType"
            ></hlx-select>
          </div>
        <div>
            <hlx-select
            :key="componentKey"
                 v-model:value="leadPayload.category.name"
              :pre-value="leadPayload.category.name"
              :inline-search="true"
              :options="leadCategoryOptions"
              :required="true"
              :display-error="true"
              :slot-options="false"
              :placeholder-value="'Category'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :clearable="true"
              @selected-value="getLeadCategory"
            ></hlx-select>
          </div>
              </section>
        </span>
        <span class="right-section" style="display:none">
          <div style="margin-bottom:12px">
              <hlx-input
                v-model:value="leadPayload.description"
                :pre-val="leadPayload.description"
                type="textarea"
                :label-value="'Description'"
                :label-animation="true"
                :rows="8"
                :required="false"
                :display-error="false"
                :custom-error="customnameerror"
                :custom-error-message="''"
                @focus-out="focusOutName"
              ></hlx-input>
            </div>
            <div style="display:flex">
              <div style="width:94%">
            <hlx-select
              :key="componentKey"
              v-model:value="leadPayload.relatedParty[0].value"
              :pre-value="leadPayload.relatedParty[0].value"
              :inline-search="true"
              :options="leadOwnerOptions"
              :required="true"
              :placeholder-value="'Lead owner'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :clearable="true"
              :display-error="true"
              @selected-value="getLeadOwner"
            ></hlx-select>
              </div>
            <div style="margin: auto;padding-bottom: 24px;">
                <i style="color: #A6A6A6;"  class="icon-info-circle-regular"></i>
              </div>
          </div>
          <div style="display:flex">
            <div style="width:94%">
            <hlx-input 
                  :key="drawerKey"
                   v-model:value="leadPayload.externalReference[1].value"
                  :pre-val="leadPayload?.externalReference[1]?.value"
                   type="number"
                  :label-value="'Number of sites'"
                  :label-animation="true"
                  :required="true"
                  :custom-error="customnameerror"
                  :display-error="true"
                  :custom-error-message="'Please fill the field'"
                  @focus-out="focusOutName"
                  @at-input="validateName"
                ></hlx-input>
              </div> 
            <div style="margin: auto;padding-bottom: 24px;">
                <i style="color: #A6A6A6;"  class="icon-info-circle-regular"></i>
              </div>
          </div>
          <div style="display:flex">
            <div style="width:94%">
            <hlx-select
              :key="componentKey"
              v-model:value="leadPayload.dealCategory.name"
              :pre-value="leadPayload.dealCategory.name"
              :inline-search="true"
              :options="leadDealCategoryOptions"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Deal category'"
              :prop-value="'name'"
              :label="'name'"
              :display-error="true"
              :label-animation="true"
              :clearable="true"
              @selected-value="getLeadDealCategory"
            ></hlx-select>
            </div>
            <div style="margin: auto;padding-bottom: 24px;">
                <i style="color: #A6A6A6;"  class="icon-info-circle-regular"></i>
              </div>
          </div> 
          <div>
            <hlx-input
               v-model:value="leadPayload.externalReference[0].value"
              :pre-val="leadPayload.externalReference[0].value"
              :label-value="'Website URL'"
              type="text"
              :prefix="true"
              :label-animation="true"
              :display-error="true"
              :custom-error="isUrlEmpty"
              :custom-error-message="'Please enter a valid URL in the format: www.abc.com'"
              @focus-out="websiteRegex"
              @at-input="validateName"
            >
            </hlx-input>
          </div>
               <div>
                  <hlx-multi-select
                :key="componentKey"
                :options="tagsOptions"
                :pre-value="leadPayload.tags"
                :placeholder="'Tags'"
                :label-animation="true"
                :prop-value="'name'"
                :label="'name'"
                :disabled="false"
                :filter-select="false"
                :tooltipposition="'left'"
                @add-data="addTagList"
                @custom-change="receivedTags"
              ></hlx-multi-select>
              </div> 
            </span> -->
            <!-- :email-data-props="emailArrayForContacts" -->
            <div class="parent-container-for-lead-maintable-overview">
              <fieldRenderer
                :key="updateFieldRenderKeyForLead"
                :schema-payload="leadSchema"
                :layout="'modal'"
                :data-payload="leadPayloadEmpty"
                :is-save-clicked="isSaveButtonClickedForLead"
                :is-modal-window="modalPropsForFieldRender"
                :updated-schema-after-remap="updatedLeadSchema"
                :trigger-rule="triggerRuleForContacts"
                :status-list="[
                  'Open',
                  'Network approved',
                  // 'In Discussion',
                  'Qualified',
                  'Network rejected',
                  'Unqualified',
                  'Awaiting network approval',
                  'Converted',
                  'Closed Lost',
                ]"
                :parent-class-name="'parent-container-for-lead-maintable-overview'"
                :complete-payload="leadCompletePayload"
                @rule-triggered="handleRuleTrigger"
                @edited-payload="getLeadDataForOverview"
                @lookup-data="getExistedLeadData"
              ></fieldRenderer>
            </div>
          </section>
          <!-- Lead contact details -->
          <section
            style="
              margin-bottom: 12px;
              font-family: 'Quicksand';
              font-size: 14px;
              font-weight: bolder;
            "
          >
            <i class="icon-user-regular"></i>
            <span>
              <!-- Contact details -->
              {{ matchFromStatic("Contact details") }}
            </span>
          </section>
          <section class="lead-modal-content">
            <!-- <span class="left-section" style="display:none">
          <div>
          <hlx-select
              :key="componentKey"
              :pre-value="leadContactDetails?.title"
              :inline-search="true"
              :options="titleData"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Title'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="getTitleForContact"
            ></hlx-select>
          </div>
            <div>
              <hlx-input
                 :pre-val="leadContactDetails.firstName"
                  :label-value="'First name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
            </div>
            <div>
              <hlx-input
             :pre-val="leadContactDetails.lastName"
                  :label-value="'Last name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
            </div>
            <div>

          <hlx-select
          :key="componentKey"
              :pre-value="leadContactDetails.contactType"
              :inline-search="true"
              :options="leadContactType"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Type'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="getTypeOfContact"
            ></hlx-select>
          </div>
        </span> -->
            <!-- <span class="right-section" style="display:none">
          <div>
            <hlx-input
             :pre-val="leadContactDetails.contactMedium[0].characteristic.emailAddress"
              type="email"
              :label-value="'Email id'"
              :required="true"
              :label-animation="true"
              :display-error="true"
              :custom-error="isExistingEmail"
              :custom-error-message="isExistingEmailErrorMessage"
               @focus-out="validateEmailNew"
              @at-input="validateEmail"
              ></hlx-input>
            </div>
              <div>
              <hlx-input
             :pre-val="leadContactDetails.contactMedium[2].characteristic.telephoneNumber.value.value"
                  :label-value="'Telephone number'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
              <div>
                <hlx-input
             :pre-val="leadContactDetails.contactMedium[1].characteristic.phoneNumber.value"
              type="phonenumber"
              :label-value="'Phone number'"
              :label-animation="true"
              :display-error="true"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
              ></hlx-input>
              </div>
              <div>
          <hlx-select
          :key="componentKey"
              :pre-value="leadContactDetails.defaultLanguage"
              :inline-search="true"
              :options="specdata"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Preferred language'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="handleSpecChanges"
            ></hlx-select>
          </div>
        </span> -->
            <div class="parent-container-for-lead-maintable-contact">
              <fieldRenderer
                :key="updateFieldRenderKeyForContact"
                :layout="'modal'"
                :schema-payload="contactSchema"
                :data-payload="leadContactDetails"
                :is-add-clicked="isAddButtonClicked"
                :trigger-rule="triggerRuleForContacts"
                :updated-schema-after-remap="updatedContactSchemaForRemap"
                :is-save-clicked="isSaveButtonClickedForContact"
                :is-modal-window="modalPropsForFieldRender"
                :email-data-props="emailArrayForContacts"
                :status-list="[
                  'Open',
                  // 'In Discussion',
                  'Qualified',
                  'Unqualified',
                  'Network approved',
                  'Network rejected',
                  'Awaiting network approval',
                  'Converted',
                  'Closed Lost',
                ]"
                :parent-class-name="'parent-container-for-lead-maintable-contact'"
                :complete-payload="leadCompletePayload"
                @edited-payload="getLeadData"
                @updated-schema="getContactUpdatedSchema"
                @input-focus-out="getContactInputFocusOut"
              ></fieldRenderer>
            </div>
          </section>
        </div>

        <!-- Modal for showing all sites -->
        <div
          v-if="
            showAddNewLeadModal &&
            showAllSitesModal &&
            !showSiteInformationModal
          "
          class="modal-content"
        >
          <section class="lead-modal-top">
            <section style="display: flex">
              <span class="lead-modal-top-num"> 1 </span>
              <span class="lead-modal-top-name">
                <!-- Account information -->
                {{ loadStaticData("LANG-0024") }}
              </span>
            </section>
            <span class="lead-modal-top-line"></span>
            <section style="display: flex">
              <span class="lead-modal-top-num"> 2 </span>
              <span class="lead-modal-top-name">
                <!-- Site information -->
                {{ loadStaticData("LANG-0023") }}
              </span>
            </section>
          </section>
          <section
            v-for="(i, index) in temporaryleadPayload?.externalReference[1]
              ?.value
              ? typeof temporaryleadPayload.externalReference[1].value ===
                'string'
                ? Math.round(
                    Number(temporaryleadPayload.externalReference[1].value)
                  )
                : Math.round(temporaryleadPayload.externalReference[1].value)
              : 1"
            :key="index"
          >
            <section
              class="lead-dropdowm-site-cover"
              style="cursor: pointer"
              @click="openSiteDetails(i, index)"
            >
              <div class="lead-dropdowm-site-num">
                {{
                  siteDetailsEmpty[index]?.siteName
                    ? siteDetailsEmpty[index]?.siteName
                    : `${matchFromStatic("Site")} -` +
                      (index + 1) +
                      ` ${matchFromStatic("details")}`
                }}
              </div>
              <div class="lead-dropdowm-site-icon">
                <i style="color: #a6a6a6" class="icon-angle-down-filled"></i>
              </div>
            </section>
          </section>
        </div>

        <!-- Modal for showing specific sites info -->

        <div
          v-if="
            showAddNewLeadModal && showAllSitesModal && showSiteInformationModal
          "
          class="modal-content"
        >
          <section class="lead-modal-top">
            <section style="display: flex">
              <span class="lead-modal-top-num"> 1 </span>
              <span class="lead-modal-top-name">
                <!-- Account information -->
                {{ loadStaticData("LANG-0024") }}
              </span>
            </section>
            <span class="lead-modal-top-line"></span>
            <section style="display: flex">
              <span class="lead-modal-top-num"> 2 </span>
              <span class="lead-modal-top-name">
                <!-- Site information -->
                {{ loadStaticData("LANG-0023") }}
              </span>
            </section>
          </section>
          <!-- <section class="lead-dropdowm-site-cover" @click="closeSiteDetails(chosenSiteIndex)">
            <div class="lead-dropdowm-site-num">
              {{ siteDetailsEmpty[chosenSiteIndex].siteName ? siteDetailsEmpty[chosenSiteIndex].siteName : 'Site -' + (chosenSiteIndex+1) +' details'}}
            </div>
            <div class="lead-dropdowm-site-icon">
              <i style="color: #A6A6A6;"  class="icon-angle-up-filled"></i>
            </div>
          </section> -->

          <!-- @click="chosenSiteIndex === index ? closeSiteDetails(index,i) : openSiteDetails(i, index)"> -->
          <!-- @click="closeSiteDetails(chosenSiteIndex)" -->
          <section
            v-for="(i, index) in temporaryleadPayload?.externalReference[1]
              ?.value
              ? typeof temporaryleadPayload.externalReference[1].value ===
                'string'
                ? Math.round(
                    Number(temporaryleadPayload.externalReference[1].value)
                  )
                : Math.round(temporaryleadPayload.externalReference[1].value)
              : 1"
            :key="index"
          >
            <section
              class="lead-dropdowm-site-cover"
              style="cursor: pointer"
              @click="closeSiteDetails(index, i)"
            >
              <div class="lead-dropdowm-site-num" style="display: contents">
                {{
                  siteDetailsEmpty[index]?.siteName
                    ? siteDetailsEmpty[index]?.siteName
                    : `${matchFromStatic("Site")} -` +
                      (index + 1) +
                      ` ${matchFromStatic("details")}`
                }}
                <div
                  v-if="chosenSiteIndex === index"
                  class="lead-dropdowm-site-icon"
                >
                  <i style="color: #a6a6a6" class="icon-angle-up-filled"></i>
                </div>
                <div
                  v-if="chosenSiteIndex !== index"
                  class="lead-dropdowm-site-icon"
                >
                  <i style="color: #a6a6a6" class="icon-angle-down-filled"></i>
                </div>
                <!-- <div v-if="chosenSiteIndex === index">
                {{temporaryleadSiteDetails[chosenSiteIndex]?.siteName?temporaryleadSiteDetails[chosenSiteIndex]?.siteName:'Site -' + (chosenSiteIndex + 1) +' details'}}
              </div> -->
                <!-- <div class="lead-dropdowm-site-icon">
                <i style="color: #A6A6A6;"  class="icon-angle-down-filled"></i>
              </div> -->
              </div>
            </section>
            <div
              v-if="chosenSiteIndex === index"
              class="parent-container-for-lead-maintable-site"
            >
              <!-- :address-data-props="siteAddressArray" -->
              <fieldRenderer
                :key="updateFieldRenderKey"
                :layout="'modal'"
                :schema-payload="siteSchemaArrayOfObjects[chosenSiteIndex]"
                :data-payload="siteDetailsPayload"
                :is-add-clicked="isAddButtonClicked"
                :updated-schema-after-remap="updatedSiteSchemaForRemap"
                :is-save-clicked="isSaveButtonClicked"
                :is-modal-window="modalPropsForFieldRender"
                :trigger-rule="triggerRuleForSites[chosenSiteIndex]"
                :status-list="[
                  'Open',
                  'Qualified',
                  'Unqualified',
                  'Awaiting network approval',
                  'Network approved',
                  'Network rejected',
                  'Converted',
                  'Closed Lost',
                ]"
                :parent-class-name="'parent-container-for-lead-maintable-site'"
                :complete-payload="leadCompletePayload"
                @rule-triggered="handleRuleTrigger"
                @edited-payload="getSiteDetails"
                @lookup-data="getExistedLeadData"
                @updated-schema="getSiteUpdatedSchema"
                @input-focus-out="getSiteInputFocusOut"
                @is-mounted="runFuncToTriggerFocusOutRule"
              ></fieldRenderer>
            </div>
          </section>
          <!-- Add new lead modal window content -->
          <section class="lead-modal-content">
            <!-- <span class="left-section" style="display:none">
            <div>
              <hlx-input
                   v-model:value="siteDetails[chosenSiteIndex].name"
                  :pre-val="siteDetails[chosenSiteIndex].name"
                  :label-value="'Site name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
            </div>
            <div style="display:flex">
              <div style="width:94%">
              <hlx-input
                  v-model:value = "siteDetails[chosenSiteIndex].networkId"
                  :pre-val="siteDetails[chosenSiteIndex].networkId"
                  :label-value="'Network id'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
            <div style="margin: auto;padding-bottom: 24px;">
                <i style="color: #A6A6A6;"  class="icon-info-circle-regular"></i>
              </div>
          </div>
          <div style="display:flex">
              <div style="width:94%">
              <hlx-input
                  v-model:value = "siteDetails[chosenSiteIndex].sdfcId"
                  :pre-val="siteDetails[chosenSiteIndex]?.sdfcId"
                  :label-value="'SDFC site id'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
            <div style="margin: auto;padding-bottom: 24px;">
                <i style="color: #A6A6A6;"  class="icon-info-circle-regular"></i>
              </div>
          </div>
          <div style="display:flex">
              <div style="width:94%">
              <hlx-input
                  v-model:value = "siteDetails[chosenSiteIndex].numberOfUnits"
                  :pre-val="siteDetails[chosenSiteIndex]?.numberOfUnits"
                  :label-value="'Number of units'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
            <div style="margin: auto;padding-bottom: 24px;">
                <i style="color: #A6A6A6;"  class="icon-info-circle-regular"></i>
              </div>
          </div>
          <div>
            <hlx-select
            :key="componentKey"
              :inline-search="true"
              :options="leadMarketSegmentOptions"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Market segment'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="getLeadMarketSegment"
            ></hlx-select>
          </div>
        <div>
            <hlx-input
                  :label-animation="true"
                  :single-border="false"
                  :label-value="'Onboarding date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  :clearable="false"
                  icon="calendar-regular"
                  icon-position="right"
                      required="true"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                 :custom-error="startDateBoolean"
                  :custom-error-message="startDateMsg"
                  :end-year="9999"
                  @iso-date="getLeadOnboardingDate"
                    @at-input="getLeadOnboardingDate"
                    @focus-out="focusOutStartDate"
                />
              </div>
        </span>
        <span class="right-section" style="display:none">
          <div class="right-section-address" style="margin-bottom: 20px">
              <addressComponent
                      :new-coordinate="{ lat: '23', lng: '72' }"
                      :pre-val="characteristicpreval"
                      @address-data="receivedAddressData"
                      @geopoint="geopoint"
              ></addressComponent>
            </div>
            <div style="display:none;gap:12px">
              <div style="width:50%">
              <hlx-input
                   v-model:value="siteDetails[chosenSiteIndex].contactMedium[0].characteristic.latitude"
                  :pre-val="siteDetails[chosenSiteIndex].contactMedium[0].characteristic.latitude"
                  :label-value="'Latitude'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="getLatitudeValue"
                  @at-input="validateName"
                ></hlx-input>
              </div>
              <div style="width:50%">
              <hlx-input
                    v-model:value="siteDetails[chosenSiteIndex].contactMedium[0].characteristic.longitude"
                  :pre-val="siteDetails[chosenSiteIndex]?.contactMedium[0]?.characteristic?.longitude"
                  :label-value="'Longitude'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
            </div>
            <div>
          <hlx-select
          :key="componentKey"
          :pre-value="siteDetails[chosenSiteIndex]?.tags?.name"
              :inline-search="true"
              :options="specdata"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Tags'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="setSiteTags"
            ></hlx-select>
          </div>
        </span> -->
            <!-- :updated-schema-after-remap = "updatedSiteSchemaForRemap" -->
          </section>
        </div>
      </template>
      <template #footer>
        <hlx-button
          v-if="
            showAddNewLeadModal &&
            !showAllSitesModal &&
            !showSiteInformationModal
          "
          class="secondary sm"
          @click="closeAddNewLeadModal"
        >
          <!-- Cancel -->
          {{ loadStaticData("LANG-0020") }}
        </hlx-button>

        <hlx-button
          v-if="
            showAddNewLeadModal &&
            !showAllSitesModal &&
            !showSiteInformationModal
          "
          :disabled="!isLeadDetailsFilled"
          class="primary sm"
          style="margin-left: 10px"
          @click="showSitesDropDown"
        >
          <!-- Next -->
          {{ loadStaticData("LANG-0021") }}
        </hlx-button>
        <!-- {{ "showAllSitesModal" + showAllSitesModal }}
         {{"showSiteInformationModal" + showSiteInformationModal}}
         {{ "showAddNewLeadModal" + showAddNewLeadModal }} -->
        <hlx-button
          v-if="
            showAddNewLeadModal &&
            (showAllSitesModal || showSiteInformationModal)
          "
          class="secondary sm"
          style="margin-left: 10px"
          @click="goToLeadDetails"
        >
          <!-- Back -->
          {{ loadStaticData("LANG-0019") }}
        </hlx-button>

        <!-- Add button modal window-->
        <hlx-button
          v-if="
            showAddNewLeadModal &&
            (showAllSitesModal || showSiteInformationModal)
          "
          :disabled="!isAllSitesDetailsFilled || disableAddButton"
          class="primary sm"
          style="margin-left: 10px"
          @click="getSiteDataAfterSiteButtonClicked"
        >
          <!-- Add button post new lead -->
          {{ loadStaticData("LANG-0010") }}
        </hlx-button>
      </template>
    </hlx-modal>
  </div>
  <!-- Lead main table view -->

  <section class="lead">
    <section class="lead-table-cover">
      <section class="lead-header">
        <!-- Lead -->
        {{ loadStaticData("LANG-0001") }}
        <span class="lead-sub-header">
          {{ loadStaticData("LANG-0002") }}
          <!-- Potential customers interested in your products or services. -->
        </span>
      </section>
      <section v-if="leadOrgData.length > 0" class="lead-add-search">
        <span>
          <hlx-search
            :label-value="loadStaticData('LANG-0003')"
            @search-key="searchKey"
          ></hlx-search>
        </span>
        <span>
          <hlx-button class="secondary sm add-btn" @click="openLeadFilter">
            <i class="icon-filter-regular"></i>
            <span v-if="filterTagLength != 0" class="filter-count">{{
              filterTagLength
            }}</span>
          </hlx-button>
        </span>
        <span>
          <hlx-button
            v-if="permisstionData.add"
            class="secondary sm add-btn"
            @click="openImport"
          >
            <i class="icon-download-regular"></i>
            <!-- Import -->
            {{ loadStaticData("LANG-0004") }}
          </hlx-button>
        </span>
        <span>
          <!-- Add button -->
          <hlx-button
            v-if="permisstionData.add"
            class="primary sm"
            @click="OpenAddLeadModal"
          >
            <i class="icon-plus-circle-regular"></i>
            <!-- Add lead -->
            {{ loadStaticData("LANG-0005") }}
          </hlx-button>
        </span>
      </section>

      <!-- Table  -->
      <section
        v-if="tableData.length > 0"
        class="lead-table"
        style="margin-bottom: 4rem"
      >
        <hlx-table
          :label-value="tableModalLang"
          :column-count="leadTableheaders.length + 2"
          :border="['table', 'header', 'horizontal']"
          :row-hover="true"
          theme="grey"
          :re-arrange-columns="re_arrange"
          :re-arrange-heads="leadTableheadersCopy"
          @sorting_func="sorting_Data"
          @close-rearrange="re_arrange = false"
          @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head
              :width="60"
              :align="'center'"
              style="padding: 0 15px"
              :fixed="'left'"
              @new-resize-width="resizedWidth"
            >
              <!-- {{ "S.no" }} -->
              {{ loadStaticData("LANG-0046") }}
            </hlx-table-head>

            <hlx-table-head
              v-for="(i, index) in leadTableheaders"
              :key="index"
              :prop="i.prop"
              :sortable="i.sortable"
              :resizable="i.resizable"
              :width="i.width !== undefined ? i.width : ''"
              :align="i.align !== undefined ? i.align : ''"
              @sorting_func="sorting_Data"
              @new-resize-width="resizedWidth"
            >
              <!-- {{ i.label+"1212" }} -->
              {{ matchFromStatic(i.label) }}
            </hlx-table-head>
            <hlx-table-head
              v-if="permisstionData.edit || permisstionData.delete"
              :align="'center'"
              :width="70"
              :fixed="'right'"
              @new-resize-width="resizedWidth"
              ><span class="action-icon">
                <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                  @click="reAssignOrgTableHeader()"
                ></i> </span
            ></hlx-table-head>
          </template>

          <!-- table with data -->
          <template v-if="tableData?.length > 0" #tbody>
            <tr v-for="(i, index) in paginatedData" id="" :key="index">
              <hlx-table-cell :align="'center'" :fixed="'left'">
                {{ serialNumber(index) }}
              </hlx-table-cell>
              <hlx-table-cell
                v-for="(j, col_index) in leadTableheaders"
                :key="col_index"
                :align="j.align"
                :fixed="j.fixed"
              >
                <!-- {{permisstionData.edit}} -->
                <div
                  v-if="
                    j.prop.toLowerCase() === 'displayid' &&
                    (permisstionData.view === true ||
                      permisstionData.edit === true)
                  "
                  @click="navigateWithQuery(i, 'view')"
                >
                  <span class="link-name">
                    {{ i["displayID"] }}
                  </span>
                </div>

                <div
                  v-else-if="
                    j.prop.toLowerCase() === 'displayid' &&
                    permisstionData.edit === false
                  "
                >
                  <span>
                    {{ i["displayID"] }}
                  </span>
                </div>

                <div v-else-if="j.prop.toLowerCase() === 'name'">
                  {{ i["name"] }}
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'status'">
                  <div v-if="i[j.prop] === 'Unqualified'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#999999'"
                      :status-value="matchFromStatic('Unqualified')"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Awaiting network approval'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#F9A645'"
                      :status-value="
                        matchFromStatic('Awaiting network approval')
                      "
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Converted'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#54BD95'"
                      :status-value="matchFromStatic('Converted')"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Network approved'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#F9D82C'"
                      :status-value="matchFromStatic('Network approved')"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Network rejected'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#F9D82C'"
                      :status-value="matchFromStatic('Network rejected')"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Open'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#0065B2'"
                      :status-value="matchFromStatic('Open')"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Closed Lost'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#FC3232'"
                      :status-value="matchFromStatic('Closed Lost')"
                    ></hlx-status>
                  </div>
                  <div v-if="i[j.prop] === 'Qualified'">
                    <hlx-status
                      :type="'custom'"
                      :point-color="'#FEDC2A'"
                      :status-value="matchFromStatic('Qualified')"
                    ></hlx-status>
                  </div>
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'sites'">
                  <span>
                    {{
                      getNumberOfSiteValueForTable(i.externalReference, "sites")
                    }}
                  </span>
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'leadowner'">
                  <span>
                    {{
                      getNumberOfSiteValueForTable(i.relatedParty, "leadOwner")
                    }}
                  </span>
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'dwellingtype'">
                  <span>
                    <!-- {{ i["dwellingtype"] }} -->
                    {{ matchFromStatic(i["dwellingtype"]) }}
                  </span>
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'createdat'">
                  <span>
                    {{ dateAndTimeFormatter(i["createdAt"]) }}
                  </span>
                </div>
                <div v-else-if="j.prop.toLowerCase() === 'lastupdated'">
                  <span>
                    {{ dateAndTimeFormatter(i["updatedAt"]) }}
                    <!-- {{ i["lastUpdate"] ? dateAndTimeFormatter(i["lastUpdate"]) : dateAndTimeFormatter(i["updatedAt"]) }} -->
                  </span>
                </div>
                <div v-else>{{ i[j.prop] }}</div>
              </hlx-table-cell>
              <hlx-table-cell
                v-if="permisstionData.edit || permisstionData.delete"
                :align="'center'"
                :width="120"
                :fixed="'right'"
              >
                <span
                  :id="'table-context' + serialNumber(index - 1)"
                  class="action-icon"
                >
                  <i
                    style="position: relative"
                    class="icon-more-vertical-filled"
                    :class="i.context === true ? 'active-action' : ''"
                    @click="crudContextMenu($event, serialNumber(index - 1))"
                  >
                    <hlx-context-menu
                      :top="containertop"
                      :display="'display'"
                      :left="containerleft - 20"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      :options="computedTableAction(i)"
                      :data="index.toString()"
                      :show="i.context == true"
                      @chosen="
                        closeCrudContextMenu($event, i, serialNumber(index - 1))
                      "
                    />
                  </i>
                </span>
              </hlx-table-cell>
            </tr>
          </template>
        </hlx-table>
      </section>

      <!-- Table for no data -->
      <section
        v-if="tableData.length <= 0 && searchvalue !== ''"
        class="lead-table"
      >
        <hlx-table
          :column-count="leadTableheaders.length + 2"
          :border="['table', 'header']"
          :row-hover="true"
          theme="grey"
          :re-arrange-columns="re_arrange"
          :re-arrange-heads="leadTableheadersCopy"
          @close-rearrange="re_arrange = false"
          @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head
              :width="60"
              :align="'center'"
              style="padding: 0 15px"
              :fixed="'left'"
              @new-resize-width="resizedWidth"
            >
              {{ loadStaticData("LANG-0046") }}
              <!-- {{ "S.no" }} -->
            </hlx-table-head>

            <hlx-table-head
              v-for="(i, index) in leadTableheaders"
              :key="index"
              :prop="i.prop"
              :sortable="i.sortable"
              :resizable="i.resizable"
              :width="i.width !== undefined ? i.width : ''"
              :align="i.align !== undefined ? i.align : ''"
              @sorting_func="sorting_Data"
              @new-resize-width="resizedWidth"
            >
              {{ i.label }}</hlx-table-head
            >
            <hlx-table-head
              :align="'center'"
              :width="70"
              :fixed="'right'"
              @new-resize-width="resizedWidth"
              ><span class="action-icon">
                <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                  @click="reAssignOrgTableHeader()"
                ></i> </span
            ></hlx-table-head>
          </template>

          <!-- table without data )-->
          <template #tbody>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell :colspan="leadTableheaders.length + 2">{{
                matchFromStatic("No data")
              }}</hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
          </template>
        </hlx-table>
      </section>
    </section>

    <!-- table data image - illustration -->
    <section
      v-if="tableData.length <= 0 && searchvalue == ''"
      style="
        margin-top: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        align-items: center;
      "
    >
      <img
        src="@/assets/images/leadLandingTable.svg"
        alt=""
        style="width: 600px"
      />

      <span>
        <!-- Add button -->
        <hlx-button
          v-if="permisstionData.add"
          class="primary sm"
          @click="OpenAddLeadModal"
        >
          <i class="icon-plus-circle-regular"></i>
          <!-- Add 
            lead -->
          <!-- Add lead -->
          {{ loadStaticData("LANG-0005") }}
        </hlx-button>
      </span>
    </section>
  </section>

  <!-- Pagination -->
  <div
    v-if="tableData.length > 0"
    class="lead-contact-pagination-container"
    :style="{ padding: '1rem 0', width: '100%' }"
  >
    <!-- :total="tableData.length" -->
    <hlx-pagination
      v-model:rows-per-page="externalRowsPerPage"
      :label-value="paginationLang"
      :total="totalPageNo"
      enable-rows-per-page
      :rows-per-page-list="[10, 15, 20]"
      :options="{
        attributes: {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 0,
              right: 1,
            },
          },
        },
      }"
      @current-page="currentPage"
      @updated:rows-per-page="changeRowsPerPage"
    ></hlx-pagination>
  </div>

  <div class="to-style-modal">
    <hlx-modal
      :modal-active="importDataModal"
      :height="'335px'"
      :width="'450px'"
      :modal="true"
      @close="importDataModal = false"
    >
      <template #header>
        <div>
          <!-- Import -->
          {{ matchFromStatic("Import") }}
        </div>
      </template>
      <template #content>
        <div class="modal-content">
          <div
            class="new-layout-header"
            style="
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;
            "
          >
            <span
              class="schema-header-title"
              style="
                font-size: 14px;
                margin-bottom: 8px;
                width: 30%;
                margin-right: 16px;
              "
            >
              <!-- Choose template -->
              {{ matchFromStatic("Choose template") }}
            </span>
            <span class="subtitle" style="width: 70%">
              <hlx-select
                v-model:value="template"
                :inline-search="true"
                :pre-value="template"
                :label-animation="false"
                :placeholder-value="'Template'"
                :options="templateList"
                :label="'value'"
                :prop-value="'value'"
                @selected-value="getTemplate($event)"
              >
              </hlx-select
            ></span>
          </div>
          <!-- <hlx-file-upload
              :file-type="'.xlsx,.csv,.json'"
              :file-size-limit="'20mb'"
              :custom-icon="icon"
              :type="'regular'"
              :content="'Import'"
              @file-data="abc"
            /> -->
          <!-- <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png,.json,.xlsx,.doc,.pdf,.txt'"
              :file-size-limit="'1gb'"
              :custom-icon="icon"
              :type="'regular'"
              :style-background="backgrounStyleupload"
              :icon="'icon-arrow-down-to-line-filled'"
              :content="'Import'"
              @file-data="imgDataPrimary"
              @delete-image="funcdelete"
              @download-image="func1"
            /> -->
          <!-- <hlx-file-upload
                  :file-type="'.jpg,.jpeg,.png,.json,.xlsx,.doc,.pdf,.txt'"
        :file-size-limit="'1gb'"
        :custom-icon="icon"
        :type="'upc-regular'"
        :icon="'icon-arrow-down-to-line-filled'"
        :content="'Import'"
        @delete-image="func"
        @download-image="func1"
        @file-data="imgDataPrimary"
      /> -->
          <hlx-file-upload
            :key="importDataFileUploadKey"
            :file-type="'.xlsx, .csv'"
            :file-size-limit="'20mb'"
            :custom-icon="icon"
            :type="'regular'"
            @native-data="newEmit"
          />

          <div
            v-if="importData.length > 0 || insights.length > 0"
            class="new-layout-header"
          >
            <div class="subtitle" style="font-size: 14px; margin-bottom: 8px">
              <span>
                <!-- You are importing -->
                {{ matchFromStatic("You are importing") }}
                {{ insights.length + importData.length }}
                <!-- records -->
                {{ matchFromStatic("records") }}
              </span>
              <span v-if="insights.length > 0" style="color: #fc3232"
                >,
                <!-- with -->
                {{ matchFromStatic("with") }}
                {{ insights.length }}
                <!-- records having the following discrepancies -->
                {{
                  matchFromStatic("records having the following discrepancies")
                }}
              </span>
              <hlx-table
                :column-count="2"
                :border="['table', 'header', 'horizontal']"
                :bold-headers="false"
                :row-hover="true"
                theme="grey"
                :striped-rows="false"
              >
                <template #thead>
                  <hlx-table-head
                    v-for="(i, index) in errorTableHeaders"
                    :key="index"
                    :prop="i.prop"
                    :sortable="i.sortable"
                    :resizable="i.resizable"
                    :fixed="i.fixed"
                    :width="i.width !== undefined ? i.width : ''"
                    :align="i.align !== undefined ? i.align : ''"
                  >
                    {{ matchFromStatic(i.label) }}</hlx-table-head
                  >

                  <hlx-table-head :align="'center'" :width="70"
                    ><span></span>
                  </hlx-table-head>
                </template>

                <template #tbody>
                  <tr v-for="(i, index) in errorTableData" id="" :key="index">
                    <hlx-table-cell
                      v-for="(j, col_index) in errorTableHeaders"
                      :key="col_index"
                      :align="j.align"
                      :fixed="j.fixed"
                    >
                      <div v-if="j?.prop == 'errorPosition'">
                        <span>{{ i.errorPosition }}</span>
                      </div>
                      <div v-else-if="j?.prop == 'error'">
                        <span>{{ i.error }}</span>
                      </div>
                    </hlx-table-cell>
                  </tr>
                </template>
              </hlx-table>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button
          class="secondary-success sm"
          @click="importDataModal = false"
        >
          <!-- Cancel -->
          {{ loadStaticData("LANG-0020") }}
        </hlx-button>
        <hlx-button class="primary sm deletedialogok" @click="importdatamodal1">
          <!-- Import -->
          {{ matchFromStatic("Import") }}
        </hlx-button>
      </template>
    </hlx-modal>
  </div>
</template>

<script>
// import addressComponent from './leadAddressComponent.vue'
import fieldRenderer from "@/components/FieldRenderer.vue";
import {
  DMNAxiosInstance,
  UPCAxiosInstance,
  MLTAxiosInstance,
} from "@/config/axiosConfig";
import FilterComponent from "@/components/filterPanelComponent.vue";

export default {
  components: {
    fieldRenderer,
    FilterComponent,
  },
  //vue add data
  data() {
    return {
      leadCompletePayload: {
        name: "",
        leadType: "",
        description: "",
        dwellingtype: "",
        externalReference: [
          {
            type: "websiteUrl",
            value: "",
          },
          {
            type: "numberOfSites",
            value: 1,
          },
          {
            type: "leadSource",
            value: "",
          },
        ],
        category: {},
        status: "Open",
        relatedParty: [
          {
            "@type": "leadOwner",
            value: "",
            email: "",
            id: "",
          },
        ],
      },
      log: () => {},
      showConsole: true,
      selectedSiteArray: [],
      indexOfSelectedSite: [],
      selectedLeadContactUserId: "",
      selectedLeadContactDisplayId: "",
      selectedLeadAccountRef: "",
      marketSegmentValue: "",
      marketSegmentOptions: [],
      updatedMarketSegmentOptions: [],
      contactSchemaDuplicate: null,
      ViewAndEdit: [],
      view: [],
      accessZoneDelete: false,
      accessZoneEdit: false,
      accessZoneView: false,
      isDuplicateSiteSfdcId: [],
      isDuplicateSiteNetworkId: [],
      duplicateIndicesSdfcId: [],
      duplicateIndicesNetworkId: [],
      languageOptions: [],
      duplicateIndices: [],
      siteAddressExistedInside: [],
      siteSdfcIdInside: [],
      siteNetworkId: [],
      siteSchemaArrayOfObjects: [],
      showNextButton: false,
      triggerRuleForSites: [],
      triggerRuleForContacts: false,
      instanceData: null,
      instanceAddress: null,
      rootParent: null,
      selectedLeadName: "",
      selectedLeadContactEmail: "",
      emailArrayForContacts: null,
      siteAddressArray: null,
      isSiteAlreadyExisted: 0,
      leadName: "",
      createLead: null,
      isduplicateEmail: 0,
      tableHeaderOrg: [],
      tableHeaderOrgcc: [],
      re_arrange: false,
      reassignSiteDetailsEmpty: [
        {
          siteName: "",
          networkId: "",
          remapSiteId: "",
          networkManager: {
            value: "",
            email: "",
            id: "",
          },
          createdAt: "",
          lastUpdate: "",
          sfdcId: "",
          numberOfUnits: "",
          tags: [],
          firstOccupancyDate: "",
          onboardingDate: "",
          marketSegment: {},
          siteAddress: [
            {
              mediumType: "postalAddress",
              characteristic: {
                "@type": "",
                street: "",
                city: "",
                country: "",
                default: false,
                description: "",
                postalCode: "",
                postalCodePrefix: "",
                state: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                unit: "",
                latitude: "234",
                longitude: "",
              },
            },
          ],
        },
      ],
      currentSiteObject: null,
      contactSchemaWithValue: null,
      siteSchemaWithValue: null,
      updatedContactSchemaForRemap: null,
      updatedSiteSchema: null,
      updatedLeadSchema: null,
      updatedSiteSchemaForRemap: null,
      totalSites: null,
      exitedLeadContactDetails: null,
      reassignSiteDetailsPayload: {
        siteName: "",
        remapSiteId: "",
        networkId: "",
        createdAt: "",
        networkManager: {
          value: "",
          email: "",
          id: "",
        },
        lastUpdate: "",
        sfdcId: "",
        numberOfUnits: "",
        tags: [],
        firstOccupancyDate: "",
        onboardingDate: "",
        marketSegment: {},
        siteAddress: [
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
              street: "",
              city: "",
              country: "",
              default: false,
              description: "",
              postalCode: "",
              postalCodePrefix: "",
              state: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              unit: "",
              latitude: "76717",
              longitude: "",
            },
          },
        ],
      },
      reassignLeadContactDetails: {
        "@type": "individual",
        title: "",
        createdAt: "",
        lastUpdate: "",
        firstName: "",
        lastName: "",
        contactType: "",
        defaultLanguage: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
          {
            mediumType: "telephone",
            characteristic: {
              telephoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
        ],
      },
      reassingnLeadPayloadEmpty: {
        name: "",
        leadType: "",
        description: "",
        dwellingtype: "",
        externalReference: [
          {
            type: "websiteUrl",
            value: "",
          },
          {
            type: "numberOfSites",
            value: 1,
          },
          {
            type: "leadSource",
            value: "",
          },
        ],
        category: {},
        status: "Open",
        relatedParty: [
          {
            "@type": "leadOwner",
            value: "",
            email: "",
            id: "",
          },
        ],
      },
      temporaryleadSiteDetailsReupdate: [
        {
          siteName: "",
          remapSiteId: "",
          networkId: "",
          createdAt: "",
          networkManager: {
            value: "",
            email: "",
            id: "",
          },
          lastUpdate: "",
          sfdcId: "",
          numberOfUnits: "",
          tags: [],
          firstOccupancyDate: "",
          onboardingDate: "",
          marketSegment: {},
          siteAddress: [
            {
              mediumType: "postalAddress",
              characteristic: {
                "@type": "",
                street: "",
                city: "",
                country: "",
                default: false,
                description: "",
                postalCode: "",
                postalCodePrefix: "",
                state: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                unit: "",
                latitude: "12",
                longitude: "",
              },
            },
          ],
        },
      ],
      temporaryleadSiteDetails: [
        {
          siteName: "",
          remapSiteId: "",
          networkId: "",
          createdAt: "",
          networkManager: {
            value: "",
            email: "",
            id: "",
          },
          lastUpdate: "",
          sfdcId: "",
          numberOfUnits: "",
          tags: [],
          firstOccupancyDate: "",
          onboardingDate: "",
          marketSegment: {},
          siteAddress: [
            {
              mediumType: "postalAddress",
              characteristic: {
                "@type": "",
                street: "",
                city: "",
                country: "",
                default: false,
                description: "",
                postalCode: "",
                postalCodePrefix: "",
                state: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                unit: "",
                latitude: "090",
                longitude: "",
              },
            },
          ],
        },
      ],
      temporaryleadPayload: {
        name: "",
        leadType: "",
        description: "",
        dwellingtype: "",
        externalReference: [
          {
            type: "websiteUrl",
            value: "",
          },
          {
            type: "numberOfSites",
            value: 1,
          },
          {
            type: "leadSource",
            value: "",
          },
        ],
        category: {},
        status: "Open",
        relatedParty: [
          {
            "@type": "leadOwner",
            value: "",
            email: "",
            id: "",
          },
        ],
      },
      temporaryleadContactPayload: {
        "@type": "individual",
        title: "",
        createdAt: "",
        lastUpdate: "",
        firstName: "",
        lastName: "",
        contactType: "",
        defaultLanguage: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
          {
            mediumType: "telephone",
            characteristic: {
              telephoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
        ],
      },
      filterTagLength: 0,
      totalPageNo: 0,
      filtertagvalue: [],
      filterQuery: {
        type: "filter",
        module: "saleslead",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          updatedAt: -1,
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: "",
      },
      query: {
        Status: ["status"],
        "Dwelling type": ["dwellingtype"],
      },
      show_filter: false,
      insights: [],
      errorTableData: [],
      templateList: [],
      templateId: "",
      loginUserLanguage: null,
      companyName: "",
      disableAddButton: false,
      searchvalue: "",
      currPage: 1,
      rowsPerPage: 10,
      siteDetailsPayload: {
        siteName: "",
        remapSiteId: "",
        networkId: "",
        networkManager: {
          value: "",
          email: "",
          id: "",
        },
        createdAt: "",
        lastUpdate: "",
        sfdcId: "",
        numberOfUnits: "",
        tags: [],
        firstOccupancyDate: "",
        onboardingDate: "",
        marketSegment: {},
        siteAddress: [
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
              street: "",
              city: "",
              country: "",
              default: false,
              description: "",
              postalCode: "",
              postalCodePrefix: "",
              state: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              unit: "",
              latitude: "99889",
              longitude: "",
            },
          },
        ],
      },
      isSaveButtonClickedForContact: false,
      isSaveButtonClicked: false,
      isSaveButtonClickedForLead: false,
      dupSiteDetailsForMapping: [],
      siteDetailsEmpty: [
        {
          siteName: "",
          remapSiteId: "",
          networkId: "",
          networkManager: {
            value: "",
            email: "",
            id: "",
          },
          createdAt: "",
          lastUpdate: "",
          sfdcId: "",
          numberOfUnits: "",
          tags: [],
          firstOccupancyDate: "",
          onboardingDate: "",
          marketSegment: {},
          siteAddress: [
            {
              mediumType: "postalAddress",
              characteristic: {
                "@type": "",
                street: "",
                city: "",
                country: "",
                default: false,
                description: "",
                postalCode: "",
                postalCodePrefix: "",
                state: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                unit: "",
                latitude: "989876",
                longitude: "",
              },
            },
          ],
        },
      ],
      modalPropsForFieldRender: true,
      customFieldLeadSchema: {},
      leadSchema: null,
      contactSchema: null,
      siteSchema: null,
      filteredSite: null,
      profileUploadComponent: 0,
      importDataFileUploadKey: 0,
      permisstionData: {},
      notification: [],
      importData: [],
      importDataModal: false,
      leadContactDetails: {
        "@type": "individual",
        title: "",
        createdAt: "",
        lastUpdate: "",
        firstName: "",
        lastName: "",
        contactType: "",
        defaultLanguage: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
          {
            mediumType: "telephone",
            characteristic: {
              telephoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
        ],
      },
      leadContactDetailsEmpty: {
        "@type": "individual",
        title: "",
        createdAt: "",
        lastUpdate: "",
        firstName: "",
        lastName: "",
        contactType: "",
        defaultLanguage: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
          {
            mediumType: "telephone",
            characteristic: {
              telephoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
        ],
      },
      tableData: [],
      leadOrgData: [],
      modalKey: 0,
      titleData: [{ name: "Mr" }, { name: "Mrs" }],
      isUrlEmpty: false,
      siteDetails: [],
      chosenSiteIndex: "",
      componentKey: 0,
      statusList: [],
      filteredstatus: [],
      newCoordinate: {},
      characteristicpreval: {
        characteristic: {
          "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          postalCodePrefix: "",
          fsa: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
          latitude: "",
          longitude: "",
        },
      },
      leadContactType: [],
      leadDealCategoryOptions: [],
      leadOwnerOptions: [],
      leadMarketSegmentOptions: [],
      leadCategoryOptions: [],
      leadSourceOptions: [],
      leadTypeOptions: [],
      dwellingTypeOptions: [],
      leadPayloadEmpty: {
        name: "",
        leadType: "",
        description: "",
        dwellingtype: "",
        externalReference: [
          {
            type: "websiteUrl",
            value: "",
          },
          {
            type: "numberOfSites",
            value: 1,
          },
          {
            type: "leadSource",
            value: "",
          },
        ],
        category: {},
        status: "Open",
        relatedParty: [
          {
            "@type": "leadOwner",
            value: "",
            email: "",
            id: "",
          },
        ],
      },
      leadPayload: {
        name: "",
        description: "",
        leadType: "",
        dwellingtype: "",
        externalReference: [
          {
            type: "websiteUrl",
            value: "",
          },
          {
            type: "numberOfsites",
            value: 1,
          },
          {
            type: "leadSource",
            value: "",
          },
        ],
        category: {},
        status: "Open",
        relatedParty: [
          {
            "@type": "leadOwner",
            value: "",
            email: "",
            id: "",
          },
        ],
        // contactMedium: [
        //   {
        //     preferred: true,
        //     mediumType: "email",
        //     characteristic: {
        //       emailAddress: "",
        //     },
        //   },
        //   {
        //     mediumType: "phone",
        //     characteristic: {
        //       phoneNumber: {
        //         country: {
        //           name: this.$store.getters.defaultSetting?.country,
        //           short: this.$store.getters.defaultSetting?.cca2,
        //           flags: this.$store.getters.defaultSetting?.flags?.svg,
        //         },
        //         value: "",
        //         valid: true,
        //       },
        //     },
        //   },
        //   {
        //     mediumType: "fax",
        //     characteristic: {
        //       faxNumber: "",
        //     },
        //   },
        //   {
        //     mediumType: "postalAddress",
        //     characteristic: {
        //       city: "",
        //       country: "",
        //       postCode: "",
        //       stateOrProvince: "",
        //       street1: "",
        //       street2: "",
        //       description: "",
        //       address1: "",
        //       default: true,
        //       "@type": "",
        //     },
        //   },
        // ],
      },
      showSiteInformationModal: false,
      showAllSitesModal: false,
      showAddNewLeadModal: false,
      errorTableHeaders: [
        {
          name: "Error position",
          checked: true,
          id: 1,
          disabled: true,
          prop: "errorPosition",
          label: "Error position",
          sortable: false,
          resizable: false,
          width: 100,
          align: "left",
        },
        {
          name: "Error",
          checked: true,
          id: 2,
          disabled: true,
          prop: "error",
          label: "Error",
          sortable: false,
          resizable: false,
          width: 300,
          align: "left",
        },
      ],
      leadTableheaders: [
        {
          checked: true,
          sortable: false,
          resizable: true,
          disabled: true,
          width: 100,
          label: this.loadStaticData("LANG-0039") || "ID",
          name: "ID",
          prop: "displayID",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          disabled: true,
          width: null,
          name: "Name",
          label: this.loadStaticData("LANG-0040") || "Company name",
          prop: "name",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 160,
          disabled: true,
          name: "Dwelling Type",
          label: this.loadStaticData("LANG-0041") || "Dwelling Type",
          prop: "dwellingtype",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 140,
          disabled: true,
          name: "Lead owner",
          label: "Lead owner",
          prop: "leadOwner",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 160,
          name: "Status",
          label: this.loadStaticData("LANG-0042") || "Status",
          prop: "status",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 140,
          name: "Number of sites",
          label: this.loadStaticData("LANG-0043") || "Number of sites",
          prop: "sites",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 200,
          name: "Created at",
          label: this.loadStaticData("LANG-0044") || "Created at",
          prop: "createdat",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 200,
          name: "Last updated",
          label: this.loadStaticData("LANG-0045") || "Last updated",
          prop: "lastUpdated",
          align: "left",
        },
      ],
      leadTableheadersCopy: [
        {
          checked: true,
          sortable: false,
          resizable: true,
          disabled: true,
          width: 100,
          label: "ID",
          name: "ID",
          prop: "displayID",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          disabled: true,
          width: null,
          name: "Name",
          label: "Company name",
          prop: "name",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 160,
          disabled: true,
          name: "Dwelling Type",
          label: "Dwelling Type",
          prop: "dwellingtype",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 140,
          disabled: true,
          name: "Lead Owner",
          label: "Lead owner",
          prop: "leadOwner",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 160,
          name: "Status",
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 140,
          name: "Number of sites",
          label: "Number of sites",
          prop: "sites",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 200,
          name: "Created at",
          label: "Created at",
          prop: "createdat",
          align: "left",
        },
        {
          checked: true,
          sortable: false,
          resizable: true,
          width: 200,
          name: "Last updated",
          label: "Last updated",
          prop: "lastUpdated",
          align: "left",
        },
      ],
    };
  },
  computed: {
    tableModalLang() {
      return {
        re_arrange_columns: this.matchFromStatic("Re-arrange columns"),
        Cancel: this.matchFromStatic("Cancel"),
        Apply: this.matchFromStatic("Apply"),
        SelectAll: this.matchFromStatic("Select all"),
      };
    },
    paginationLang() {
      return {
        Rows_per_page: this.matchFromStatic("Rows per page"),
        Showing: this.matchFromStatic("Showing"),
        of: this.matchFromStatic("of"),
        results: this.matchFromStatic("results"),
        Go_to: this.matchFromStatic("Go to"),
      };
    },

    isLeadDetailsFilled() {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      const isEmailValid =
        this.temporaryleadContactPayload?.contactMedium[0]?.characteristic
          ?.emailAddress &&
        emailRegex.test(
          this.temporaryleadContactPayload.contactMedium[0].characteristic
            .emailAddress
        );

      this.log(isEmailValid, " this.isduplicateEmail", this.isduplicateEmail);
      // this.log("Phone number valid" , this.temporaryleadContactPayload?.contactMedium[1]?.characteristic?.phoneNumber?.value)
      return (
        this.temporaryleadPayload?.name &&
        this.temporaryleadPayload?.externalReference[1].value > 0 &&
        this.temporaryleadPayload?.relatedParty[0]?.value &&
        this.temporaryleadContactPayload?.firstName &&
        this.temporaryleadContactPayload?.lastName &&
        this.temporaryleadContactPayload?.defaultLanguage &&
        this.temporaryleadContactPayload?.contactMedium[1]?.characteristic
          ?.phoneNumber?.value?.valid &&
        this.temporaryleadContactPayload?.contactMedium[1]?.characteristic
          ?.phoneNumber?.value &&
        !this.showNextButton &&
        isEmailValid &&
        this.temporaryleadContactPayload?.contactMedium[0]?.characteristic
          ?.emailAddress
      );
      // this.isduplicateEmail == 0 &&
      // && this.temporaryleadPayload?.relatedParty[1]?.value
      // && this.leadPayloadEmpty.leadSource.name && this.leadPayloadEmpty.leadType.name &&
      //        this.leadPayloadEmpty.dwellingtype && this.leadPayloadEmpty.category.name && this.leadPayloadEmpty.marketSegment.name
      //        && this.leadPayloadEmpty.onboardingDate &&
    },
    isAllSitesDetailsFilled() {
      this.log(
        this.siteDetails.length,
        "isAllSitesDetailsFilled",
        this.siteDetails
      );

      // let isSitesHaveDuplicate = this.triggerRuleForSites.every(
      //   (value) => value === "nosites"
      // );

      // // Check Sfdc ID
      // let isSitesSfdcDuplicate = this.isDuplicateSiteSfdcId.every(
      //   (value) => value === "nosfdc"
      // );
      // this.log("isSitesSfdcDuplicate",isSitesSfdcDuplicate)

      // // Check network ID
      // let isSitesNetworkDuplicate = this.isDuplicateSiteNetworkId.every(
      //   (value) => value === "nonetwork"
      // );

      // Retrieve the numberOfSites value
      const numberOfSitesValue =
        this.temporaryleadPayload.externalReference.find(
          (item) => item.type.toLowerCase() === "numberofsites"
        )?.value;

      // Check is there any duplicates inside the sites
      let isSitesHaveDuplicate = !this.triggerRuleForSites.some((obj) =>
        Object.values(obj).some((value) => value === true)
      );
      this.log("isSitesNetworkDuplicate", isSitesHaveDuplicate);

      // If there are no site details or the count doesn't match numberOfSitesValue, return false
      if (
        this.siteDetailsEmpty.length < 0 ||
        this.siteDetailsEmpty.length !== numberOfSitesValue
      ) {
        this.log(
          this.siteDetailsEmpty.length,
          "Site details missing or count mismatch",
          numberOfSitesValue,
          "temp length",
          this.temporaryleadSiteDetails?.length
        );
        return false;
      }
      this.log("this.temporaryleadSiteDetails", this.temporaryleadSiteDetails);

      // Check if every site object is filled
      const areAllSitesFilled = this.temporaryleadSiteDetails.every((site) => {
        const siteUnits = site.numberOfUnits
          ? Number(site.numberOfUnits)
          : null;

        const isAddressFilled =
          site.siteAddress &&
          site.siteAddress[0]?.characteristic &&
          site.siteAddress[0].characteristic.street !== "" &&
          site.siteAddress[0].characteristic.city !== "" &&
          site.siteAddress[0].characteristic.postalCode !== "" &&
          site.siteAddress[0].characteristic.postalCodePrefix !== "";

        const isSiteNameFilled = site.siteName && site.siteName.trim() !== "";
        const isUnitsValid = siteUnits !== null && siteUnits >= 1;

        // Check for marketSegment-specific date fields
        const isDateValid =
          (site.marketSegment === "Greenfield" &&
            site.firstOccupancyDate.trim() !== "") ||
          (site.marketSegment === "Brownfield" &&
            site.occupancyDate?.trim() !== "");

        return (
          isAddressFilled &&
          isSiteNameFilled &&
          isUnitsValid &&
          isSitesHaveDuplicate &&
          isDateValid
        );
      });

      this.log(
        this.duplicateIndices,
        "Are all sites filled:",
        areAllSitesFilled
      );
      return areAllSitesFilled && this.duplicateIndices.length == 0;
      // && this.isSiteAlreadyExisted == 0;
    },
    paginatedData() {
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      // this.log(this.tableData?.length,"paginatedTableData",this.tableData.slice(start, end))
      // return this.tableData.slice(start, end);
      return this.tableData;
    },
  },
  watch: {
    siteSchemaArrayOfObjects: {
      handler(val) {
        if (this.chosenSiteIndex == 0 && val[this.chosenSiteIndex]) {
          // val[this.chosenSiteIndex].marketSegment
          this.log("val[this.chosenSiteIndex]", val[this.chosenSiteIndex]);
        }
      },
      deep: true,
      immediate: true,
    },
    "temporaryleadPayload.externalReference": {
      handler(val) {
        this.log(val, "val from site number");
        const numberOfSites = val.find(
          (item) => item.type === "numberOfSites"
        )?.value;
        this.siteSchemaArrayOfObjects;
        for (let i = 0; i < numberOfSites; i++) {
          if (
            !this.siteSchemaArrayOfObjects[i] &&
            this.siteEmplySchemaWithoutData
          ) {
            this.log(this.siteEmplySchemaWithoutData, " Withoutdata");
            this.siteSchemaArrayOfObjects[i] = JSON.parse(
              JSON.stringify(this.siteEmplySchemaWithoutData)
            );
          }
          if (i != 0) {
            this.siteSchemaArrayOfObjects[i].marketSegment.options = JSON.parse(
              JSON.stringify(this.updatedMarketSegmentOptions)
            );
          }
        }
        this.log(
          "this.siteSchemaArrayOfObjects",
          this.siteSchemaArrayOfObjects
        );
      },
      deep: true,
      immediate: true,
    },
    temporaryleadSiteDetails: {
      handler(val) {
        this.log(val);
        if (val) {
          if (val[0]?.marketSegment != this.marketSegmentValue) {
            this.marketSegmentValue = val[0]?.marketSegment;
            this.log("marketSegmentValue", this.marketSegmentValue);
            this.updatedMarketSegmentOptions = this.marketSegmentOptions.filter(
              (opt) => {
                if (opt.name == this.marketSegmentValue) {
                  return { ...opt, default: true };
                }
              }
            );
            this.updatedMarketSegmentOptions.map((ele) => (ele.default = true));
            this.log(
              "this.updatedMarketSegmentOptions",
              this.updatedMarketSegmentOptions
            );
            const numberOfSites =
              this.temporaryleadPayload?.externalReference?.find(
                (item) => item.type === "numberOfSites"
              )?.value;
            for (let i = 0; i < numberOfSites; i++) {
              if (i != 0) {
                this.updatedMarketSegmentOptions.map(
                  (ele) => (ele.default = true)
                );
                this.log("index value index", i);
                this.siteSchemaArrayOfObjects[i].marketSegment.options =
                  JSON.parse(JSON.stringify(this.updatedMarketSegmentOptions));
                this.siteSchemaArrayOfObjects[i].marketSegment.options.map(
                  (ele) => {
                    ele.default = true;
                  }
                );
              }
            }
          }
        }
        this.log(
          "this.siteSchemaArrayOfObjects",
          this.siteSchemaArrayOfObjects
        );
        // const newVal = [
        //   {
        //     siteName: "RB 1",
        //     siteAddress: [
        //       {
        //         mediumType: "postalAddress",
        //         characteristic: {
        //           description: "Johnson City, New York, United States, 13790-1201",
        //         },
        //       },
        //     ],
        //   },
        //   {
        //     siteName: "RB 2",
        //     siteAddress: [
        //       {
        //         mediumType: "postalAddress",
        //         characteristic: {
        //           description: "5353 New Peachtree Rd Ofc I, 5353, Atlanta, Georgia, United States, 30341-2853",
        //         },
        //       },
        //     ],
        //   },
        // ];

        // if (!Array.isArray(this.siteAddressArray)) {
        //   this.siteAddressArray = [];
        // }

        // if (this.temporaryleadSiteDetails) {
        //   this.temporaryleadSiteDetails.forEach((site) => {
        //     site.siteAddress.forEach((address) => {
        //       const description = address.characteristic.description;
        //       if (description && !this.siteAddressArray.includes(description)) {
        //         this.siteAddressArray.push(description);
        //       }
        //     });
        //   });
        // }
        if (val && val.length > 0) {
          // Site network id
          //           let siteNetworkId = val.map((obj) => obj?.networkId);

          // // Find duplicate indices in the `siteNetworkId` array
          // this.duplicateIndicesNetworkId = siteNetworkId
          //   .map((networkId, index) =>
          //     siteNetworkId.indexOf(networkId) !== index ? index : null
          //   )
          //   .filter((index) => index !== null);

          // this.log("Duplicate Indices for Network ID:", this.duplicateIndicesNetworkId);

          //             this.log(this.duplicateIndicesNetworkId,"this.siteNetworkId",siteNetworkId)

          // Site SFDC id

          let siteSdfcIdInside = val.map((obj) => obj?.networkId);

          // Find duplicate indices in the `siteNetworkId` array
          this.duplicateIndicesSdfcId = siteSdfcIdInside
            .map((sfdc, index) =>
              siteSdfcIdInside.indexOf(sfdc) !== index ? index : null
            )
            .filter((index) => index !== null);
          this.log(
            this.duplicateIndicesSdfcId,
            "this.siteNetworkId siteSdfcIdInside",
            this.siteSdfcIdInside
          );

          //  siteAddress is an array before mapping
          this.siteAddressExistedInside = val
            ?.map((obj) =>
              Array.isArray(obj?.siteAddress)
                ? obj?.siteAddress
                    ?.map((medium) => medium?.characteristic?.description)
                    .filter(
                      (description) => description && description?.trim() !== ""
                    )
                : []
            )
            .flat();

          // Find duplicate indices
          this.duplicateIndices = this.siteAddressExistedInside
            .map((description, index) =>
              this.siteAddressExistedInside?.indexOf(description) !== index
                ? index
                : null
            )
            .filter((index) => index !== null);
          this.log(
            this.duplicateIndices,
            "Duplicate Indices:",
            this.siteAddressExistedInside
          );
          // this.duplicateIndices?.forEach(index => {
          //   if (this.triggerRuleForSites[index] !== undefined) {
          //     this.triggerRuleForSites[index] = 'sites';
          //   }
          // });

          // this.log("Duplicate Indices:", this.duplicateIndices);
          // this.log(
          //   this.duplicateIndices,
          //   "this.siteAddressExistedInside",
          //   this.siteAddressExistedInside
          // );
        }
        // this.log(
        //   "Updated siteAddressArray:",
        //   this.siteAddressArray,
        //   "New Value Processed:",
        //   this.temporaryleadSiteDetails
        // );
      },
      deep: true,
      immediate: true,
    },
    contactSchemaWithValue: {
      handler(val) {
        if (val != null || val != "") {
          console.log(
            val,
            "contactSchemaWithValue val",
            this.contactSchemaWithValue
          );
          this.showNextButton = val?.email?.others?.showValidation;
          // this.log(this.showNextButton);
        }
      },
      deep: true,
      immediate: true,
    },
    // "currentSiteObject.sfdcId": {
    //   handler: async function (oldVal , newVal) {
    //     const chosenSdfc = newVal;
    //     let filterQueryCheckDes = {
    //       type: "filter",
    //       module: "site",
    //       isPaginated: true,
    //       paginatedQuery: {
    //         rowsPerPage: 5,
    //         currentPage: 1,
    //       },
    //       isNeedToBeSort: true,
    //       limit: 100,
    //       searchQuery: "",
    //       filterQuery: {
    //         "sfdcId": { $in: [chosenSdfc] },
    //       },
    //       company: this.companyName,
    //     };
    //     if(newVal?.trim() && (oldVal.toLowerCase() != newVal.toLowerCase())){
    //       this.log(newVal,"oldVal sfdcId",oldVal)
    //     try {
    //       const valDecCheck = await UPCAxiosInstance.post(
    //         "util/filterData",
    //         filterQueryCheckDes
    //       );

    //       if (
    //         valDecCheck.data?.total > 0 ||
    //         this.duplicateIndices.includes(this.chosenSiteIndex)
    //       ) {
    //         this.triggerRuleForSites[this.chosenSiteIndex] = "sites";
    //         this.log("Site exists or duplicate found");
    //       } else {
    //         this.triggerRuleForSites[this.chosenSiteIndex] = "nosites";
    //         this.log("No site found or not a duplicate");
    //       }
    //     }
    //      catch (error) {
    //       this.triggerRuleForSites[this.chosenSiteIndex] = "nosites";
    //       console.error("Error during address validation:", error);
    //     }
    //   }

    //     this.log("Current triggerRuleForSites state:", this.triggerRuleForSites);
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // "currentSiteObject.networkId": {
    //   handler: async function (oldVal,newVal) {
    //     const chosenNetworkId = newVal;
    //     let filterQueryCheckDes = {
    //       type: "filter",
    //       module: "site",
    //       isPaginated: true,
    //       paginatedQuery: {
    //         rowsPerPage: 5,
    //         currentPage: 1,
    //       },
    //       isNeedToBeSort: true,
    //       limit: 100,
    //       searchQuery: "",
    //       filterQuery: {
    //         "networkId": { $in: [chosenNetworkId] },
    //       },
    //       company: this.companyName,
    //     };
    //     if(newVal?.trim()){
    //       this.log(newVal,"oldVal networkId",oldVal)
    //     try {
    //       const valDecCheck = await UPCAxiosInstance.post(
    //         "util/filterData",
    //         filterQueryCheckDes
    //       );

    //       if (
    //         valDecCheck.data?.total > 0 ||
    //         this.duplicateIndices.includes(this.chosenSiteIndex)
    //       ) {
    //         this.triggerRuleForSites[this.chosenSiteIndex] = "sites";
    //         this.log("Site exists or duplicate found");
    //       } else {
    //         this.triggerRuleForSites[this.chosenSiteIndex] = "nosites";
    //         this.log("No site found or not a duplicate");
    //       }
    //     }
    //      catch (error) {
    //       this.triggerRuleForSites[this.chosenSiteIndex] = "nosites";
    //       console.error("Error during address validation:", error);
    //     }
    //   }

    //     this.log("Current triggerRuleForSites state:", this.triggerRuleForSites);
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    "currentSiteObject.siteAddress": {
      handler: async function (oldVal, newVal) {
        const chosenSite = newVal?.[0]?.characteristic ?? {};
        const chosenSiteDup = oldVal?.[0]?.characteristic ?? {};
        chosenSiteDup;
        chosenSite;
        // this.log(newVal,chosenSiteDup, chosenSite?.description, 'chosenSite', chosenSite);
        // const state = chosenSite.state ?? '';
        // const postalCode = chosenSite.postalCode ?? '';
        // const postalCodePrefix = chosenSite.postalCodePrefix ?? '';
        // const street = chosenSite.street ?? '';
        // const description = chosenSite.description ?? '';
        // const descriptionDup = chosenSiteDup.description ?? '';
        // this.log(
        //   description,
        //   'statestate',
        //   street,
        //   state,
        //   postalCode,
        //   postalCodePrefix,
        //   'descriptionDup',
        //   descriptionDup
        // );

        // // this.log(newVal?.[0]?.characteristic.description,"currentSiteObject.siteAddress",oldVal)
        // // let filterQuery = {
        // //   type: "filter",
        // //   module: "site",
        // //   isPaginated: true,
        // //   paginatedQuery: {
        // //     rowsPerPage: 5,
        // //     currentPage: 1,
        // //   },
        // //   isNeedToBeSort: true,
        // //   limit: 100,
        // //   searchQuery: "",
        // //   filterQuery: {
        // //     "contactMedium.characteristic.state": { "$in": [state] },
        // //     "contactMedium.characteristic.postalCode": { "$in": [postalCode] },
        // //   },
        // //   company: this.companyName,
        // // };
        // let filterQueryCheckDes = {
        //   type: 'filter',
        //   module: 'site',
        //   isPaginated: true,
        //   paginatedQuery: {
        //     rowsPerPage: 5,
        //     currentPage: 1,
        //   },
        //   isNeedToBeSort: true,
        //   limit: 100,
        //   searchQuery: '',
        //   filterQuery: {
        //     'contactMedium.characteristic.description': {
        //       $in: [
        //         this.temporaryleadSiteDetails[this.chosenSiteIndex]
        //           ?.siteAddress?.[0]?.characteristic?.description,
        //       ],
        //     },
        //   },
        //   company: this.companyName,
        // };
        // // const val = await UPCAxiosInstance.post(
        // //   "util/filterData",
        // //   filterQuery
        // // );
        // // this.log(val.data.total, "val.total", val.response);
        // // // const isduplicateEmail = val.data.total || 0;
        // // if(val.data.total !=0){
        // // this.siteSchemaArrayOfObjects[this.chosenSiteIndex].siteAddress.others.isValidationRuleEnabled = false
        // // this.siteSchemaArrayOfObjects[this.chosenSiteIndex].siteAddress.others.validationErrorMessage  = {
        // //   en: "Site address already exists",
        // //   fr: "Site address already exists french",
        // //   ta: "Site address already exists tamil",
        // //   hi: "Site address already exists hindi"
        // // }
        // // this.siteSchemaArrayOfObjects[this.chosenSiteIndex].siteAddress.others.showValidation = true
        // // this.siteEmplySchemaWithoutData[this.chosenSiteIndex] = JSON.parse(JSON.stringify(this.siteSchemaWithValue))
        // // this.log(descriptionDup,"oldVal siteAddress",description , "Checker" , descriptionDup.toLowerCase() != description.toLowerCase())
        // if (
        //   typeof this.triggerRuleForSites[this.chosenSiteIndex] !== 'object'
        // ) {
        //   this.triggerRuleForSites[this.chosenSiteIndex] = {};
        // }
        // this.siteSchemaArrayOfObjects[this.chosenSiteIndex].siteAddress.others.showValidation = true
        // this.siteEmplySchemaWithoutData[this.chosenSiteIndex] = JSON.parse(JSON.stringify(this.siteSchemaWithValue))
        // this.log(descriptionDup,"oldVal siteAddress",description , "Checker" , descriptionDup.toLowerCase() != description.toLowerCase())
        if (
          typeof this.triggerRuleForSites[this.chosenSiteIndex] !== "object"
        ) {
          this.triggerRuleForSites[this.chosenSiteIndex] = {};
        }
        try {
          // this.temporaryleadSiteDetails[this.chosenSiteIndex]?.siteAddress?.[0]?.characteristic?.description.
          // && !(descriptionDup.toLowerCase() != description.toLowerCase())
          // Check if description is not empty, null, or undefined
          if (
            this.temporaryleadSiteDetails[
              this.chosenSiteIndex
            ]?.siteAddress?.[0]?.characteristic?.description.trim()
          ) {
            // const valDecCheck = await UPCAxiosInstance.post(
            //   'util/filterData',
            //   filterQueryCheckDes
            // );
            const valDecCheck = 0;
            if (
              typeof this.triggerRuleForSites[this.chosenSiteIndex] !== "object"
            ) {
              this.triggerRuleForSites[this.chosenSiteIndex] = {};
            }
            //  const siteAddressDupInside =  this.siteAddressExistedInside.includes(description)
            if (
              // valDecCheck.data.total > 0 ||
              valDecCheck > 0 ||
              this.duplicateIndices.includes(this.chosenSiteIndex)
            ) {
              this.triggerRuleForSites[this.chosenSiteIndex].siteAddress = true;
              // this.log('statestate from methods From if ');
            } else {
              this.log(
                this.duplicateIndices,
                "Description is empty or invalid else watch."
              );
              this.triggerRuleForSites[
                this.chosenSiteIndex
              ].siteAddress = false;
            }
          } else {
            if (
              typeof this.triggerRuleForSites[this.chosenSiteIndex] !== "object"
            ) {
              this.triggerRuleForSites[this.chosenSiteIndex] = {};
            }
            // this.triggerRuleForSites[this.chosenSiteIndex].siteAddress = false;
            // this.log('Description is empty or invalid.');
          }
          // }
        } catch (error) {
          if (
            typeof this.triggerRuleForSites[this.chosenSiteIndex] !== "object"
          ) {
            this.triggerRuleForSites[this.chosenSiteIndex] = {};
          }
          // this.triggerRuleForSites[this.chosenSiteIndex].siteAddress = false;
          console.error("Error during address validation:", error);
        }

        // this.log('isSiteAlreadyExisted', this.isSiteAlreadyExisted);
      },
      deep: true,
      immediate: true,
    },
    //     "temporaryleadContactPayload.contactMedium": {
    //       handler: async function (val) {
    //         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // const email = Array.isArray(val)
    //   ? val.find(
    //       (medium) =>
    //         medium.mediumType === "email" && medium.characteristic?.emailAddress
    //     )?.characteristic?.emailAddress
    //   : '';

    // if (emailRegex.test(email)) {
    //     const contactpayload = await MLTAxiosInstance.get(
    //       `partymanagement/individual/listuser?company=${this.companyName}`
    //     );
    //     this.log(contactpayload.data,"contactpayload.data")
    //    this.emailArrayForContacts = contactpayload.data.map((item) => item.email);
    //    this.contactSchema = JSON.parse(JSON.stringify(this.contactSchemaWithValue))
    //   this.log(`${email} is a valid email address.`);

    // } else {
    //   this.log(`${email} is not a valid email address.`);
    // }

    //       // await this.checkDuplicateEmail();
    //     },
    //       deep: true,
    //       immediate: true,
    //     },
    leadName: {
      handler: async function (val) {
        // if (newVal !== oldVal) {
        val;
        // await this.checkDuplicateEmail();
        // this.log(this.selectedLeadName, 'leadName', val);
        if (
          this.selectedLeadName !== "" &&
          this.leadName != "" &&
          this.leadName.toLowerCase() != this.selectedLeadName.toLowerCase() &&
          !this.emailArrayForContacts.includes(this.selectedLeadContactEmail)
        ) {
          // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const email = Array.isArray(this.contactSchemaWithValue)
            ? this.contactSchemaWithValue.find(
                (medium) =>
                  medium.mediumType === "email" &&
                  medium.characteristic?.emailAddress
              )?.characteristic?.emailAddress
            : "";

          // if (emailRegex.test(email)) {
          const contactpayload = await MLTAxiosInstance.get(
            `partymanagement/individual/listuser?company=${this.companyName}`
          );
          this.log(contactpayload.data, "contactpayload.data");
          this.emailArrayForContacts = contactpayload.data
            .filter((item) => item["@baseType"] === "lead" && item?.email)
            .map((item) => item.email);
          this.contactSchema = JSON.parse(
            JSON.stringify(this.contactSchemaWithValue)
          );
          this.log(`${email} is a valid email address.`);

          // } else {
          //   this.log(`${email} is not a valid email address.`);
          // }
          this.emailArrayForContacts.push(this.selectedLeadContactEmail);
          let reAssignContactSchema = this.contactSchema;
          reAssignContactSchema;
          // this.log('reAssignContactSchema', reAssignContactSchema);
          this.contactSchemaWithValue.email.others.validateCurrentField = true;
          this.contactSchema = JSON.parse(
            JSON.stringify(this.contactSchemaWithValue)
          );
          this.leadContactDetails = JSON.parse(
            JSON.stringify(this.temporaryleadContactPayload)
          );
          // this.log(
          //   this.selectedLeadContactEmail,
          //   'this.emailArrayForContacts push',
          //   this.emailArrayForContacts
          // );
          this.triggerRuleForContacts = true;
        }
        if (
          this.selectedLeadName !== "" &&
          this.leadName != "" &&
          this.leadName.toLowerCase() === this.selectedLeadName.toLowerCase()
        ) {
          // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const email = Array.isArray(this.contactSchemaWithValue)
            ? this.contactSchemaWithValue.find(
                (medium) =>
                  medium.mediumType === "email" &&
                  medium.characteristic?.emailAddress
              )?.characteristic?.emailAddress
            : "";

          // if (emailRegex.test(email)) {
          const contactpayload = await MLTAxiosInstance.get(
            `partymanagement/individual/listuser?company=${this.companyName}`
          );
          // this.log(contactpayload.data, 'contactpayload.data');
          this.emailArrayForContacts = contactpayload.data
            .filter((item) => item["@baseType"] === "lead" && item?.email)
            .map((item) => item.email);
          this.leadContactDetails = JSON.parse(
            JSON.stringify(this.temporaryleadContactPayload)
          );
          this.contactSchema = JSON.parse(
            JSON.stringify(this.contactSchemaWithValue)
          );
          this.log(`${email} is a valid email address.`);

          // }
          // else {
          //   this.log(`${email} is not a valid email address.`);
          // }
          this.emailArrayForContacts = this.emailArrayForContacts.filter(
            (email) => email !== this.selectedLeadContactEmail
          );
          this.contactSchemaWithValue.email.others.validateCurrentField = true;
          this.contactSchema = JSON.parse(
            JSON.stringify(this.contactSchemaWithValue)
          );
          this.log(
            this.selectedLeadContactEmail,
            "this.emailArrayForContacts push else",
            this.emailArrayForContacts
          );
          this.triggerRuleForContacts = true;
        }
        // }
      },
      immediate: true,
    },
    statusList: {
      handler(val) {
        if (val) {
          const filteredstatuslist = this.statusList.filter(
            (item) =>
              item.name === "Open" ||
              item.name === "Network approved" ||
              item.name === "Network rejected" ||
              // item.name === "In Discussion" ||
              item.name === "Qualified" ||
              item.name === "Unqualified" ||
              item.name === "Awaiting network approval" ||
              item.name === "Converted" ||
              item.name === "Closed Lost"
          );
          this.filteredstatus = filteredstatuslist;
          this.log("filteredstatuslist", this.filteredstatus);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    this.log = this.showConsole ? console.log.bind(console) : () => {};
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("leadMainFilterData");
    this.filter = this.$store.getters.leadMainFilterData;
    this.log("leadMainFilterData", this.filter);
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    const view = await UPCAxiosInstance.get("/userHierarchy/view");
    this.view = view.data;
    const ViewAndEdit = await UPCAxiosInstance.get("/userHierarchy/delete");
    this.ViewAndEdit = ViewAndEdit.data;
    this.companyName = await val.data.company;
    let sampleTheadsd = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );

    const mergedPayload = sampleTheadsd.data.permission;
    console.log(mergedPayload.CM.child.Lead, "mergedPayload", mergedPayload);
    this.permisstionData = mergedPayload.CM.child.Lead;
    //  const accessZone =  sampleTheadsd.data.accessZone
    // const permisstionzone =  this.getEffectivePermissions(accessZone,subZone)
    // this.permisstionData =  permisstionzone
    //  this.log(permisstionzone,"permisstionzone");

    // if(this.accessZone.view === true){
    //   this.accessZoneView = true
    // }else if(this.accessZone["view / edit"] === true){
    //   this.accessZoneView = true
    //   this.accessZoneEdit = true
    // }
    // else if(this.accessZone["view / edit / delete"] === true){
    //   this.accessZoneView = true
    //   this.accessZoneEdit = true
    //   this.accessZoneDelete = true
    // }

    // To get table header
    let sampleTheads = await MLTAxiosInstance.get(`/settings`);

    if (sampleTheads?.data?.entityRef?.["lead"]) {
      let leadTableHeaderFromMounted = sampleTheads.data.entityRef["lead"];
      let offerTableHeadOrg = sampleTheads.data.entityRef["lead"].filter(
        (ele) => ele.checked
      );
      this.log(
        offerTableHeadOrg,
        "leadTableHeaderFromMounted",
        leadTableHeaderFromMounted
      );

      if (
        leadTableHeaderFromMounted.length === this.leadTableheadersCopy.length
        // &&
        // leadTableHeaderFromMounted.every((item, index) => item.name ===  this.leadTableheadersCopy[index].name)
      ) {
        this.leadTableheadersCopy = this.translateDataNameField(
          JSON.parse(JSON.stringify(leadTableHeaderFromMounted))
        );
        // this.leadTableheadersCopy = JSON.parse(
        //   JSON.stringify(leadTableHeaderFromMounted)
        // );
        this.leadTableheaders = JSON.parse(JSON.stringify(offerTableHeadOrg));
        this.tableHeaderOrgcc = JSON.parse(
          JSON.stringify(this.leadTableheadersCopy)
        );
        console.log(
          "sdfghm,",
          JSON.parse(JSON.stringify(this.leadTableheadersCopy))
        );

        this.tableHeaderOrg = JSON.parse(JSON.stringify(this.leadTableheaders));
      }
    }

    //  Get all leads data - table data
    try {
      // const response = await UPCAxiosInstance.get(`/salesLead`);
      const filterData = {
        company: this.companyName,
        type: "filter",
        module: "saleslead",
        isPaginated: true,
        // lastUpdated: new Date().toJSON(),
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: { updatedAt: -1 },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      };

      const response = await UPCAxiosInstance.post(
        `util/filterData?company=${this.companyName}`,
        filterData
      );
      this.tableData = await response.data.data;
      this.totalPageNo = await response.data.total;
      // this.tableData = response?.data || [];
      // this.tableData = this.tableData?.filter(ele=>ele?.externalReference?.length>0 || ele.numberOfUnits)
      this.leadOrgData = JSON.parse(JSON.stringify(this.tableData));
      this.log(response, "this.tableData", this.tableData);
    } catch (error) {
      this.log("Error fetching salesLead data:", error);
      this.tableData = [];
    }

    // To get lead schema for custom field
    const schema = await UPCAxiosInstance.get(
      `/customSchema/getSchema?module=${"saleslead"}`
    );

    this.log(schema, "schema");
    this.customFieldLeadSchema = schema?.data?.schema;
    this.filteredCustomFieldSchema();
    // this.loadGoogleMapsScript().then(() => {
    //      // Initialize Google Maps components here
    //      const autocompleteService = new window.google.maps.places.AutocompleteService();
    //      this.log('AutocompleteService Loaded from autoaddress:', autocompleteService);
    //      this.isLeadAddressMounted = true
    //    });

    let settings = await UPCAxiosInstance.get("/importTemplate");
    const contactpayload = await MLTAxiosInstance.get(
      `partymanagement/individual/listuser?company=${this.companyName}`
    );
    //  let leadContact = contactpayload.data.filter((data)=>{
    //   return data.status == 'lead'
    //  })
    this.emailArrayForContacts = contactpayload.data
      .filter((item) => item["@baseType"] === "lead" && item?.email)
      .map((item) => item.email);

    this.log(
      contactpayload.data,
      "contactpayload.data",
      this.emailArrayForContacts
    );
    let getAllSites = await UPCAxiosInstance.get("/geoSite");
    this.siteAddressArray = getAllSites.data
      .map((item) =>
        item.contactMedium.map((cm) => cm.characteristic.description)
      )
      .flat()
      .filter((description) => description);
    this.log(
      this.emailArrayForContacts,
      "check duplication",
      this.siteAddressArray
    );

    this.templateList = settings?.data?.filter(
      (i) => i?.module?.type == "saleslead"
    );
    this.templateList.map((i) => {
      i.value = i?.module?.name;
      i.name = i?.value;
    });
    const instance = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${val.data.userId}?company=${val.data.company}`
    );

    this.instanceData = instance.data;
    this.instanceAddress = this.instanceData.contactMedium.find(
      (item) => item.mediumType === "postalAddress"
    );
    let organisationInfo = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${this.$cookies.get(
        "user"
      )}?company=${this.$cookies.get("company")}`
    );
    let senderCompanyInfo = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${"678394a2a2479269ce839114"}?company=${this.$cookies.get(
        "company"
      )}`
    );
    this.log("senderCompanyInfo", senderCompanyInfo);
    this.rootParent = organisationInfo.data.rootName;
    // To get dropdowm options from refernce data
    //     this.leadDealCategoryOptions = await this.getReferanceData('LeadDealCategory')
    //  this.leadOwnerOptions =  await this.getReferanceData('LeadOwner')
    //  this.leadMarketSegmentOptions = await this.getReferanceData('LeadMarketSegment')
    //  this.leadCategoryOptions = await this.getReferanceData('LeadCategory')
    //  this.leadSourceOptions = await this.getReferanceData('LeadSource')
    //  this.leadTypeOptions = await this.getReferanceData('LeadType')
    //  this.dwellingTypeOptions = await this.getReferanceData('LeadDwellingType')
    //  this.leadContactType =  await this.getReferanceData('LeadContactType')
    //  let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    //  this.statusList = statusList.data.data;

    //     this.log(this.statusList,"leadDealCategoryOptions",this.leadDealCategoryOptions)
  },
  methods: {
    // Construct complete data
    // buildCompletePayload(info) {
    //   this.leadCompletePayload = { ...this.leadCompletePayload, ...info };
    // },
    // Handle rule triggers like assignment and transition rules
    handleRuleTrigger({ payload, schema, prop }) {
      console.log(
        "🚀 ~ handleRuleTrigger ~ (payload,schema,prop:",
        payload,
        schema,
        prop
      );
      // console.log("🚀 ~ handleRuleTrigger ~ payload:", payload);
      try {
        if (!payload) return;
        // Destructure overview data
        // this.temporaryLeadPayload = {...this.temporaryLeadPayload,
        //   ...{name:payload.name, category:payload.category,dwellingType}
        // }
        // Destructure contact data
        // Destructure site data
        this.temporaryleadSiteDetails = payload.relatedParty
          .filter((party) => party["@type"] == "site")
          .map((site) => {
            site.siteName = site.name;
            site.siteAddress = JSON.parse(JSON.stringify(site.contactMedium));
            return site;
          });
        console.log(
          this.temporaryleadSiteDetails,
          "Rule triggered",
          this.temporaryleadSiteDetails[this.chosenSiteIndex]
        );
        // console.log(
        //   "🚀 ~ handleRuleTrigger ~ this.temporaryleadSiteDetails:",
        //   this.temporaryleadSiteDetails,
        //   this.chosenSiteIndex
        // );
        // Schema for site
        this.siteSchemaArrayOfObjects[this.chosenSiteIndex] = JSON.parse(
          JSON.stringify(this.siteSchemaWithValue)
        );
        this.siteDetailsPayload = JSON.parse(
          JSON.stringify(this.temporaryleadSiteDetails[this.chosenSiteIndex])
        );
        console.log(
          this.siteDetailsPayload,
          "this.siteSchemaWithValue",
          this.siteSchemaWithValue
        );
        // this.siteSchemaArrayOfObjects[
        //   this.chosenSiteIndex
        // ].networkManager.value =
        //   this.temporaryleadSiteDetails[this.chosenSiteIndex]?.networkManager;
      } catch (error) {
        console.error("Error during rule trigger:");
      }
    },
    changeSiteSchemaFunc() {
      const numberOfSites = this.temporaryleadPayload.externalReference.find(
        (item) => item.type === "numberOfSites"
      )?.value;
      for (let i = 0; i < numberOfSites; i++) {
        if (i != 0) {
          this.siteSchemaArrayOfObjects[i].marketSegment.options = JSON.parse(
            JSON.stringify(this.updatedMarketSegmentOptions)
          );
        }
      }
      this.log("changeSiteSchemaFunc");
    },
    navigateWithQuery(i, data) {
      const permisttionData = this.dataVisibilityPermission(
        i.createdBy,
        this.ViewAndEdit,
        this.view,
        this.permisstionData
      );

      this.log(permisttionData, "permisttionData navigateWithQuery");
      const query = this.getAccessQuery(permisttionData);

      this.$router.push({
        path: `/MDU/lead/${data}/` + i["name"] + "/" + i["_id"],
        query: { ...this.$route.query, ...{ access: query } },
      });
    },
    getAccessQuery(role) {
      if (role.view && !role.edit) {
        return "view";
      } else if (role.view && role.edit && role.delete) {
        return "all";
      } else if (role.view && role.edit && !role.delete) {
        return "edit";
      }
    },
    async runFuncToTriggerFocusOutRule(val) {
      if (
        val == true &&
        this.currentSiteObject?.sfdcId &&
        this.currentSiteObject?.networkId
      ) {
        this.log("After mounted field ", val);
        // Error to show network id and sfdc id
        // await this.getSiteInputFocusOut(
        //   this.currentSiteObject?.sfdcId ? this.currentSiteObject?.sfdcId : '',
        //   { fieldName: 'SFDCID' }
        // );
        // await  this.getSiteInputFocusOut(this.currentSiteObject?.networkId ? this.currentSiteObject?.networkId : '' , {'fieldName':'networkId'})
      }
    },
    getContactInputFocusOut(val) {
      this.log("getContactInputFocusOut", val);
    },
    async getSiteInputFocusOut(val, field) {
      this.log(field, "getSiteInputFocusOut", val);
      // if (field.fieldName.toLowerCase() == 'sfdcid') {
      //   let filterQueryCheckDes = {
      //     type: 'filter',
      //     module: 'site',
      //     isPaginated: true,
      //     paginatedQuery: {
      //       rowsPerPage: 5,
      //       currentPage: 1,
      //     },
      //     isNeedToBeSort: true,
      //     limit: 100,
      //     searchQuery: '',
      //     filterQuery: {
      //       sfdcId: { $in: [val.trim()] },
      //     },
      //     company: this.companyName,
      //   };
      //   if (val?.trim()) {
      //     this.log(val, 'oldVal sfdcId');
      //     try {
      //       const valDecCheck = await UPCAxiosInstance.post(
      //         'util/filterData',
      //         filterQueryCheckDes
      //       );
      //       if (
      //         typeof this.triggerRuleForSites[this.chosenSiteIndex] !== 'object'
      //       ) {
      //         this.triggerRuleForSites[this.chosenSiteIndex] = {};
      //       }
      //       if (
      //         valDecCheck.data?.total > 0 ||
      //         this.duplicateIndicesSdfcId.includes(this.chosenSiteIndex)
      //       ) {
      //         this.triggerRuleForSites[this.chosenSiteIndex].sfdcId = true;
      //         this.log('Site exists or duplicate found');
      //       } else {
      //         this.triggerRuleForSites[this.chosenSiteIndex].sfdcId = false;
      //         this.log('No site found or not a duplicate');
      //       }
      //     } catch (error) {
      //       this.triggerRuleForSites[this.chosenSiteIndex].sfdcId = false;
      //       console.error('Error during address validation:', error);
      //     }
      //     this.log('this.isDuplicateSiteSfdcId', this.isDuplicateSiteSfdcId);
      //   }
      // }
      //     if(field.fieldName.toLowerCase() == 'networkid')
      //     {
      //           let filterQueryCheckDes = {
      //       type: "filter",
      //       module: "site",
      //       isPaginated: true,
      //       paginatedQuery: {
      //         rowsPerPage: 5,
      //         currentPage: 1,
      //       },
      //       isNeedToBeSort: true,
      //       limit: 100,
      //       searchQuery: "",
      //       filterQuery: {
      //         "networkId": { $in: [val.trim()] },
      //       },
      //       company: this.companyName,
      //     };
      //         if(val?.trim()){
      //       this.log(val,"oldVal networkId")
      //     try {
      //       const valDecCheck = await UPCAxiosInstance.post(
      //         "util/filterData",
      //         filterQueryCheckDes
      //       );
      //       if (typeof this.triggerRuleForSites[this.chosenSiteIndex] !== "object") {
      //     this.triggerRuleForSites[this.chosenSiteIndex] = {};
      //   }
      //       if (
      //         valDecCheck.data?.total > 0 ||
      //         this.duplicateIndicesNetworkId.includes(this.chosenSiteIndex)
      //       ) {
      //         this.triggerRuleForSites[this.chosenSiteIndex].networkId = true
      //         this.log("Site exists or duplicate found");
      //       } else {
      //         this.triggerRuleForSites[this.chosenSiteIndex].networkId = false
      //         this.log("No site found or not a duplicate");
      //       }
      //     }
      //      catch (error) {
      //       this.triggerRuleForSites[this.chosenSiteIndex].networkId = false
      //       console.error("Error during address validation:", error);
      //     }
      //   }
      // }
      this.log(
        this.triggerRuleForSites[this.chosenSiteIndex],
        "this.isDuplicateSiteNetworkId",
        this.isDuplicateSiteNetworkId
      );
    },
    async checkAddressValidation(newVal) {
      const chosenSite = newVal?.[0]?.characteristic ?? {};
      this.log(newVal, chosenSite?.description, "chosenSite", chosenSite);
      const state = chosenSite.state ?? "";
      const postalCode = chosenSite.postalCode ?? "";
      const postalCodePrefix = chosenSite.postalCodePrefix ?? "";
      const street = chosenSite.street ?? "";
      const description = chosenSite.description ?? "";
      this.log(
        "statestate from methods",
        street,
        state,
        postalCode,
        postalCodePrefix
      );

      // let filterQuery = {
      //   type: "filter",
      //   module: "site",
      //   isPaginated: true,
      //   paginatedQuery: {
      //     rowsPerPage: 5,
      //     currentPage: 1,
      //   },
      //   isNeedToBeSort: true,
      //   limit: 100,
      //   searchQuery: "",
      //   filterQuery: {
      //     "contactMedium.characteristic.state": { "$in": [state] },
      //     "contactMedium.characteristic.postalCode": { "$in": [postalCode] },
      //   },
      //   company: this.companyName,
      // };
      let filterQueryCheckDes = {
        type: "filter",
        module: "site",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 5,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        limit: 100,
        searchQuery: "",
        filterQuery: {
          "contactMedium.characteristic.description": { $in: [description] },
        },
        company: this.companyName,
      };
      filterQueryCheckDes;
      // const val = await UPCAxiosInstance.post(
      //   "util/filterData",
      //   filterQuery
      // );
      // this.log(val.data.total, "val.total", val.response);
      // // const isduplicateEmail = val.data.total || 0;
      // if(val.data.total !=0){
      // this.siteSchemaArrayOfObjects[this.chosenSiteIndex].siteAddress.others.isValidationRuleEnabled = false
      // this.isSiteAlreadyExisted = valDecCheck.data.total;
      // // this.siteSchemaArrayOfObjects[this.chosenSiteIndex].siteAddress.others.isValidationRuleEnabled  = {
      // //   en: "Site address already exists",
      // //   fr: "Site address already exists french",
      // //   ta: "Site address already exists tamil",
      // //   hi: "Site address already exists hindi"
      // // }
      // this.siteSchemaArrayOfObjects[this.chosenSiteIndex].siteAddress.others.showValidation = true
      // let reassingnSiteObj = this.siteSchemaArrayOfObjects[this.chosenSiteIndex]
      // this.siteSchemaArrayOfObjects[this.chosenSiteIndex] = reassingnSiteObj
      if (typeof this.triggerRuleForSites[this.chosenSiteIndex] !== "object") {
        this.triggerRuleForSites[this.chosenSiteIndex] = {};
        // this.triggerRuleForSites[this.chosenSiteIndex].siteAddress = false
      }
      try {
        // Check if description is not empty, null, or undefined
        if (description?.trim()) {
          // const valDecCheck = await UPCAxiosInstance.post(
          //   'util/filterData',
          //   filterQueryCheckDes
          // );
          const valDecCheck = 0;
          // const siteAddressDupInside =  this.siteAddressExistedInside.includes(description)
          if (
            // valDecCheck.data.total > 0 ||
            valDecCheck > 0 ||
            this.duplicateIndices.includes(this.chosenSiteIndex)
          ) {
            this.triggerRuleForSites[this.chosenSiteIndex].siteAddress = true;
            this.log("statestate from methods From if ");
          } else {
            // this.triggerRuleForSites[this.chosenSiteIndex].siteAddress = false;
          }
        } else {
          // this.triggerRuleForSites[this.chosenSiteIndex].siteAddress = false;
          this.log("Description is empty or invalid else check.");
        }
      } catch (error) {
        // this.triggerRuleForSites[this.chosenSiteIndex].siteAddress = false;
        console.error("Error during address validation:", error);
      }

      this.log("isSiteAlreadyExisted", this.isSiteAlreadyExisted);
    },
    pushAndSliceFromArray(index, arrayToBePushed, checkerArray) {
      if (checkerArray) {
        if (index !== 0) {
          const chosenSite = checkerArray[index - 1];
          if (chosenSite && chosenSite.siteAddress) {
            chosenSite.siteAddress.forEach((address) => {
              const description = address.characteristic?.description;

              if (description) {
                const occurrences = arrayToBePushed.filter(
                  (item) => item === description
                ).length;

                if (occurrences > 0) {
                  // If `description` exists more than once, remove the last occurrence.
                  const lastIndex = arrayToBePushed.lastIndexOf(description);
                  if (lastIndex !== -1) {
                    arrayToBePushed.splice(lastIndex, 1);
                  }
                }

                if (!arrayToBePushed.includes(description)) {
                  // Add the description only if it's not in the array anymore.
                  arrayToBePushed.push(description);
                }
              }
            });
          }
          this.log(checkerArray[index - 1], arrayToBePushed, "From if", index);
        } else {
          this.log(checkerArray[index], arrayToBePushed, "From else", index);
        }
        return arrayToBePushed;
      }
    },
    async checkDuplicateEmail() {
      let emailValue =
        this.temporaryleadContactPayload?.contactMedium?.[0]?.characteristic
          ?.emailAddress ?? "";
      this.log("emailValue", emailValue);
      let filterQuery = {
        type: "filter",
        module: "hierarchyaccounts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 5,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        limit: 100,
        searchQuery: "",
        filterQuery: {
          email: emailValue,
          type: "Lead",
        },
        company: this.companyName,
      };

      try {
        const val = await MLTAxiosInstance.post("util/filterData", filterQuery);
        this.log(val.data.data, "val.total", val.response, this.leadName);
        if (
          val.data?.total === 1 &&
          val?.data?.data.some((ele) => ele.name === this.leadName)
        ) {
          this.isduplicateEmail = 0;
        } else {
          this.isduplicateEmail =
            val.data?.total == undefined ? 0 : val.data?.total;
          this.log(val.data?.total, "Contact email already exists");
        }
      } catch (error) {
        console.error("Error fetching email total:", error);
        this.isduplicateEmail = 0;
      }
      this.log("isduplicateEmail", this.isduplicateEmail);
    },
    toggleSiteDetails(index) {
      // Toggle the chosen site index; close if the same site is clicked
      this.chosenSiteIndex = this.chosenSiteIndex === index ? null : index;
    },
    resizedWidth(val) {
      this.log(val, "resizedWidth", val);

      // const updateWidth = (array) => {
      //   let item = array.find((i) => i?.prop === val?.prop);
      //   if (item) item.width = val.width;
      // };

      // updateWidth(this.leadTableheaders);
      // updateWidth(this.leadTableheadersCopy);
      // this.log(
      //   this.leadTableheaders,
      //   "resizedWidth loop",
      //   this.leadTableheadersCopy
      // );
      // this.applyColumns(this.leadTableheadersCopy);
    },
    async applyColumns(val) {
      this.log("applyColumns", val);
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      let concatedEntity = sampleTheads?.data?.entityRef || {};

      concatedEntity.lead = val;

      let reArrangeTableHeadPayload = { entityRef: concatedEntity };

      const changeTableHeader = await MLTAxiosInstance.patch(
        `/settings`,
        reArrangeTableHeadPayload
      );
      this.log(
        changeTableHeader,
        "reArrangeTableHeadPayload",
        reArrangeTableHeadPayload
      );

      let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
      this.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

      let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
      this.leadTableheadersCopy = entityRef["lead"];
      this.leadTableheaders = this.leadTableheadersCopy.filter(
        (ele) => ele.checked
      );
      this.tableHeaderOrgcc = JSON.parse(
        JSON.stringify(this.leadTableheadersCopy)
      );
      this.tableHeaderOrg = JSON.parse(JSON.stringify(this.leadTableheaders));
    },
    reAssignOrgTableHeader() {
      // If there is change in the table header then ressign to the Orginal data
      if (
        this.leadTableheadersCopy != this.tableHeaderOrgcc &&
        this.tableHeaderOrgcc.length > 0
      ) {
        this.leadTableheadersCopy = JSON.parse(
          JSON.stringify(this.tableHeaderOrgcc)
        );
      }
      if (
        this.leadTableheaders != this.tableHeaderOrg &&
        this.tableHeaderOrg.length > 0
      ) {
        this.leadTableheaders = JSON.parse(JSON.stringify(this.tableHeaderOrg));
      }
      this.re_arrange = true;
    },
    getNumberOfSiteValueForTable(val, show) {
      this.log(show, "getNumberOfSiteValueForTable", val);
      if (show === "sites") {
        // this.log("getNumberOfSiteValueForTable", val);
        const siteData = val.find(
          (ele) => ele?.type?.toLowerCase() === "numberofsites"
        );
        return siteData ? siteData.value : "-";
      } else if (show === "leadOwner") {
        // this.log("getNumberOfSiteValueForTable for leadowner", val);
        const leadSourceData = val.find(
          (ele) => ele["@type"] && ele["@type"]?.toLowerCase() == "leadowner"
        );
        return leadSourceData ? leadSourceData.value : "-";
      }
      return "-";
    },
    getContactUpdatedSchema(val) {
      this.contactSchemaWithValue = { ...val };
      // this.log('contactSchemaWithValue', val);
    },
    getSiteUpdatedSchema(val) {
      this.siteSchemaWithValue = { ...val };
      // this.log('siteSchemaWithValue', val);
    },
    async getExistedLeadData([dataPayload, schemaToBeMapped, schema]) {
      let selectedSite = [];
      // let sitePushedIntoArray = true
      if (dataPayload && schemaToBeMapped) {
        this.log(schema, dataPayload, "getExistedLeadData", schemaToBeMapped);
        if (dataPayload.relatedParty) {
          const filteredIndividuals = dataPayload?.relatedParty.filter(
            (ele) => ele["@type"] === "individual"
          );

          this.selectedLeadContactUserId =
            filteredIndividuals.length > 0
              ? filteredIndividuals[0].userId
              : null;

          this.selectedLeadContactDisplayId =
            filteredIndividuals.length > 0
              ? filteredIndividuals[0].displayId
              : null;
          this.selectedLeadAccountRef = dataPayload?.accountRef;
          this.log("this.selectedLeadAccountRef", this.selectedLeadAccountRef);
          if (this.selectedLeadAccountRef) {
            let externalReference = JSON.parse(
              JSON.stringify(this.temporaryleadPayload.externalReference)
            );
            const numberOfSites = externalReference.find(
              (item) => item.type === "numberOfSites"
            )?.value;
            for (let i = 0; i < numberOfSites; i++) {
              this.siteSchemaArrayOfObjects[
                i
              ].siteName.others.lookup.filterPayload.company = this.companyName;
              this.siteSchemaArrayOfObjects[
                i
              ].siteName.others.lookup.filterPayload.filterQuery = {
                contactId: this.selectedLeadAccountRef,
                name: {
                  $regex: "",
                  $options: "i",
                },
              };
            }
          }
        }
        this.log(
          this.siteSchemaArrayOfObjects,
          "this.selectedLeadAccountRef else",
          this.selectedLeadAccountRef
        );
        for (let i = 0; i < schemaToBeMapped.length; i++) {
          let schemaObjName = schemaToBeMapped[i].LHS;
          let schemaValue = schemaToBeMapped[i].RHS;
          let changeRhsValue = "payload";
          const firstDotIndex = schemaValue.indexOf(".");
          this.log(schemaValue, "dataPayloaddataPayload", dataPayload);
          if (firstDotIndex !== -1) {
            let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
            this.log(schemaObjName, "newRHS", newRHS);
            newRHS;
            let result = this.evaluateRHS(newRHS, dataPayload);
            this.log("remappped data", result);
            if (
              schema["name"] != undefined &&
              schema["name"].value != undefined
            ) {
              schema["name"].value = dataPayload?.name;
              this.selectedLeadName = dataPayload?.name;
              this.selectedLeadParentRef = dataPayload?.id;
            }

            if (result == "" && schemaObjName == "numberOfUnits") {
              this.log(
                "this.leadSchema[schemaObjName].value 3 ",
                this.siteSchemaWithValue[schemaObjName]
              );
              this.siteSchemaWithValue[schemaObjName].value = "";
              this.siteSchemaWithValue.isRemapData = false;
              // this.siteSchema.isRemapData = false
            }

            if (result) {
              this.log("this.leadSchema[schemaObjName].value", schemaObjName);
              if (schema[schemaObjName]) {
                this.log(
                  "this.leadSchema[schemaObjName].value 1 ",
                  schema[schemaObjName]
                );
                schema[schemaObjName].value = result;
                schema.isRemapData = false;
                if (
                  this.siteSchemaArrayOfObjects?.[this.chosenSiteIndex]?.[
                    schemaObjName
                  ]
                ) {
                  // To get the datapayload in separate array of the choosen site
                  this.selectedSiteArray[this.chosenSiteIndex] = JSON.parse(
                    JSON.stringify(dataPayload)
                  );
                  selectedSite.push(dataPayload);

                  // To get the index in separate array of the choosen site
                  this.indexOfSelectedSite.push(this.chosenSiteIndex);
                  // sitePushedIntoArray = false

                  // Ensure the object exists at the specified index
                  if (this.temporaryleadSiteDetails[this.chosenSiteIndex]) {
                    this.temporaryleadSiteDetails[
                      this.chosenSiteIndex
                    ].remapSiteId = dataPayload.id || dataPayload.name; // Replace id
                    this.currentSiteObject.remapSiteId =
                      dataPayload.id || dataPayload.name;
                    this.siteDetailsPayload.remapSiteId =
                      dataPayload.id || dataPayload.name;
                    console.log(
                      this.currentSiteObject,
                      "Set timeout",
                      this.temporaryleadSiteDetails
                    );
                  } else {
                    console.error("No object found at the specified index");
                  }

                  this.siteSchemaWithValue.isRemapData = false;
                }
              } else if (this.contactSchemaWithValue[schemaObjName]) {
                this.log(
                  "this.leadSchema[schemaObjName].value 2 ",
                  this.contactSchemaWithValue[schemaObjName]
                );
                this.contactSchemaWithValue[schemaObjName].value = result;
                if (schemaObjName == "email") {
                  this.selectedLeadContactEmail = result;
                  this.emailArrayForContacts =
                    this.emailArrayForContacts?.filter(
                      (email) => email !== this.selectedLeadContactEmail
                    );
                  // this.log(
                  //   this.selectedLeadContactEmail,
                  //   "this.emailArrayForContacts lookup",
                  //   this.emailArrayForContacts
                  // );
                }
                this.contactSchemaWithValue.isRemapData = false;
              } else if (this.siteSchema[schemaObjName]) {
                this.log(
                  "this.leadSchema[schemaObjName].value 3 ",
                  this.siteSchemaWithValue[schemaObjName]
                );
                this.siteSchemaWithValue[schemaObjName].value = result;
                this.siteSchemaWithValue.isRemapData = false;
                // this.siteSchema.isRemapData = false
              }
              // delete schema['name']
              // delete schema[schemaObjName]
              // this.log(schema,"this.schema",this.customFieldLeadSchema)
              // return
            }
          }
          // this.customFieldLeadSchema[schemaObjName].value = schema[schemaObjName]?.value ? schema[schemaObjName]?.value : this.customFieldLeadSchema[schemaObjName].value
          // let result = eval(newRHS);
          // Log the result (assuming companyName is defined in the scope)
          // this.log(result);
          //   this.log(
          //     schemaObjName,
          //     'schemaValue',
          //     schemaValue,
          //     'schemaObjName',
          //     schemaObjName
          //   );
        }
        this.log(
          dataPayload,
          "this.selectedSiteArray",
          this.selectedSiteArray,
          "selectedSite",
          this.temporaryleadSiteDetails,
          "this.currentSiteObject",
          this.currentSiteObject
        );
        // this.log(
        //   this.siteSchema,
        //   'schemaschema',
        //   this.contactSchema,
        //   this.leadSchema
        // );
        if (this?.contactSchemaWithValue?.isRemapData == false) {
          this.leadContactDetails = JSON.parse(
            JSON.stringify(this.temporaryleadContactPayload)
          );
          this.updatedContactSchemaForRemap = JSON.parse(
            JSON.stringify(this.contactSchemaWithValue)
          );
        }
        //   this.customFieldLeadSchema = { ...this.customFieldLeadSchema, ...schema };
        //     let removeDuplicates = {}
        //     Object.keys(this.customFieldLeadSchema).forEach(key => {
        //   removeDuplicates[key] = this.customFieldLeadSchema[key];
        // });
        // this.customFieldLeadSchema = JSON.parse(JSON.stringify(removeDuplicates))

        // this. filteredCustomFieldSchema()
        this.log("this.siteSchemaWithValue", this.siteSchemaWithValue);
        if (this?.siteSchemaWithValue?.isRemapData == false) {
          this.updatedSiteSchemaForRemap = JSON.parse(
            JSON.stringify(this.siteSchemaWithValue)
          );
          this.log(
            this.temporaryleadSiteDetails,
            "this.siteSchemaWithValue",
            this.siteSchemaWithValue,
            "this.updatedSiteSchemaForRemap",
            this.updatedSiteSchemaForRemap
          );
          // this.siteDetailsPayload = JSON.parse(
          //   JSON.stringify(this.currentSiteObject)
          // );
        }

        if (
          schema?.isRemapData == false &&
          this?.siteSchemaWithValue?.isRemapData != false
        ) {
          this.leadPayloadEmpty = JSON.parse(
            JSON.stringify(this.temporaryleadPayload)
          );
          this.updatedLeadSchema = JSON.parse(JSON.stringify(schema));
        }
        if (this?.contactSchemaWithValue?.isRemapData == false) {
          this.updatedContactSchemaForRemap = JSON.parse(
            JSON.stringify(this.contactSchemaWithValue)
          );
          this.log(
            this.leadSchema,
            "this.updatedContactSchemaForRemap",
            this.updatedContactSchemaForRemap
          );
        }
      }

      //   this.log("getExistedLeadData",val)
      //   const duplicatedExitingLeadData = {...val}
      //   let leadSourceAndOwnerDetails = duplicatedExitingLeadData.relatedParty.filter(party => party["@type"] === "leadOwner" || party["@type"] === "leadSource")

      //   let leadContactAndSiteDetails = duplicatedExitingLeadData.relatedParty.filter(party => party["@type"] != "leadOwner" && party["@type"] !== "leadSource" && party["@type"] !== "site")
      //   duplicatedExitingLeadData.relatedParty = leadSourceAndOwnerDetails
      // this.leadPayloadEmpty = {
      // name: duplicatedExitingLeadData?.name || "",
      // type:duplicatedExitingLeadData?.type || "",
      //   description:duplicatedExitingLeadData?.description || "",
      //   dwellingtype:duplicatedExitingLeadData?.dwellingtype || "",
      //   externalReference: duplicatedExitingLeadData?.externalReference || [
      //     {
      //       type:'websiteUrl',
      //        value:""
      //     },
      //     {
      //       type:'numberOfSites',
      //        value:1
      //     }
      //   ],
      //   category:duplicatedExitingLeadData?.category || {},
      //   status: duplicatedExitingLeadData?.status || "Open",
      //   relatedParty:duplicatedExitingLeadData?.relatedParty || [
      //     {
      //       '@type':'leadOwner',
      //        value:""
      //     },
      //     {
      //       '@type':'leadSource',
      //       value:""
      //     }
      //   ],
      //  },
      //   this.exitedLeadContactDetails = (leadContactAndSiteDetails.filter(contact => contact['@type'] == 'individual'))[0] ||
      //  this.log("exitedLeadContactDetails",this.exitedLeadContactDetails)
      //  this.filteredCustomFieldSchema()
      //  this.leadPayloadEmpty
      //   this.log(duplicatedExitingLeadData,"duplicatedExitingLeadData",leadContactAndSiteDetails,"leadSourceAndOwnerDetails",leadSourceAndOwnerDetails,"this.leadPayloadEmpty",this.leadPayloadEmpty)
    },

    evaluateRHS(RHS, dataPayload) {
      this.dataPayloadForRemap = dataPayload;
      try {
        const cleanedRHS = RHS.replace(/payload/g, "dataPayloadForRemap")
          .replace(/\n/g, " ")
          .trim();
        this.log(
          this.dataPayloadForRemap,
          cleanedRHS,
          "dataPayloadForRemap",
          dataPayload
        );
        // Use Function constructor to evaluate the expression safely
        // eslint-disable-next-line no-new-func
        const result = new Function(
          "dataPayloadForRemap",
          `return ${cleanedRHS};`
        )(this.dataPayloadForRemap);
        return result;
      } catch (error) {
        console.error("Error evaluating RHS:", error);
        return null;
      }
    },

    async supplierfilterData(filterSelectedvalue, filterQuery) {
      this.filtertagvalue = filterSelectedvalue;
      this.filterTagLength = filterSelectedvalue.length;
      filterQuery.company = this.company;
      //  filterQuery['filterQuery']['"@baseType"'] = 'supplier'
      if (this.filtertagvalue.length != 0) {
        this.filterQuery = filterQuery;
        this.filterQuery.type = "filter";
        if (this.searchvalue != "") {
          this.filterQuery["searchQuery"] = this.searchvalue;
          this.filterQuery["type"] = "filter-search";
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.filterQuery
        );

        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
      } else {
        this.currPage = 1;
        this.rowsPerPage = 10;
        this.getLeadTableData(this.currPage, this.rowsPerPage);
      }
    },
    async getLeadTableData(currentPage, rowsPerPage) {
      this.log("getLeadTableData", currentPage, rowsPerPage);
      this.filterQuery["company"] = this.companyName;
      this.filterQuery["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQuery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      //   const filterData = {
      //   company: this.companyName,
      //   type: "filter",
      //   module: "saleslead",
      //   isPaginated: true,
      //   lastUpdated: new Date().toJSON(),
      //   paginatedQuery: {
      //     rowsPerPage: rowsPerPage,
      //     currentPage: currentPage,
      //   },
      //   isNeedToBeSort: true,
      //   sortFieldAndOrder: {},
      //   limit: 10,
      //   filterQuery: {},
      //   searchQuery: "",
      // };

      const response = await UPCAxiosInstance.post(
        `util/filterData?company=${this.companyName}`,
        this.filterQuery
      );
      if (response.data.data != undefined) {
        this.tableData = await response.data.data;
        this.totalPageNo = await response.data.total;
        this.leadOrgData = JSON.parse(JSON.stringify(this.tableData));
        this.paginatedData;
      }
    },
    openLeadFilter() {
      // to show filter drawer
      this.show_filter = true;
    },

    getTemplate(val) {
      this.templateId = val._id;
    },
    loadGoogleMapsScript() {
      // const mapKey = process.env.VUE_APP_MAP_KEY;
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_KEY}&libraries=places`;
      script.async = true;
      document.head.appendChild(script);

      return new Promise((resolve) => {
        script.onload = () => {
          resolve();
        };
      });
    },
    async closeAddNewLeadModal() {
      // To get lead schema for custom field
      // const schema = await UPCAxiosInstance.get(
      //   `/customSchema/getSchema?module=${'saleslead'}`
      // );

      // this.log(schema, 'schema');
      this.leadContactDetails = JSON.parse(
        JSON.stringify(this.leadContactDetailsEmpty)
      );
      this.temporaryleadContactPayload = JSON.parse(
        JSON.stringify(this.leadContactDetailsEmpty)
      );
      // this.customFieldLeadSchema = schema?.data?.schema;
      // await this.filteredCustomFieldSchema();
      this.showAddNewLeadModal = false;
      this.showSiteInformationModal = false;
      this.showAllSitesModal = false;
      this.siteDetailsPayload = JSON.parse(
        JSON.stringify(this.reassignSiteDetailsPayload)
      );
      // this.siteDetailsEmpty = JSON.parse(
      //   JSON.stringify(this.reassignSiteDetailsEmpty)
      // );
      // this.contactSchema = JSON.parse(
      //   JSON.stringify(this.contactSchemaDuplicate)
      // );
      // this.siteDetailsPayload = JSON.parse(
      //   JSON.stringify(this.reassignSiteDetailsPayload)
      // );

      // this.leadContactDetails = JSON.parse(
      //   JSON.stringify(this.reassignLeadContactDetails)
      // );
      // this.leadContactDetails = JSON.parse(
      //   JSON.stringify(this.reassignLeadContactDetails)
      // );
      this.leadPayloadEmpty = JSON.parse(
        JSON.stringify(this.reassingnLeadPayloadEmpty)
      );
      this.log(
        this.siteDetailsPayload,
        " this.siteDetailsPayload ",
        this.reassignSiteDetailsPayload,
        this.siteDetailsEmpty
      );
      this.filteredCustomFieldSchema();
      this.temporaryleadSiteDetails = JSON.parse(
        JSON.stringify(this.temporaryleadSiteDetailsReupdate)
      );
      this.log(
        this.contactSchema,
        "leadContactDetails",
        this.leadContactDetails
      );
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    async changeRowsPerPage(count) {
      this.rowsPerPage = count;
      await this.getLeadTableData(this.currPage, this.rowsPerPage);
    },
    async filteredCustomFieldSchema() {
      this.log("this.customFieldLeadSchema", this.customFieldLeadSchema);

      const filteredOverview = Object.entries(
        this.customFieldLeadSchema
      ).filter(
        ([, value]) => value.section && value.section.includes("overview")
      );

      let filteredContact = Object.entries(
        this.customFieldLeadSchema.contacts.schema
      );
      this.log("filteredContact top", filteredContact);
      //  filteredContact = Object.entries(this.customFieldLeadSchema).filter(
      //   ([, value]) => value.section && value.section.includes("contacts")
      // );
      let filteredSite = Object.entries(
        this.customFieldLeadSchema.sites.schema
      );
      //  filteredSite = Object.entries(this.customFieldLeadSchema).filter(
      //   ([, value]) => value.section && value.section.includes("sites")
      // );
      this.log("filteredSite top", filteredSite);

      const fieldSchemaSite = Object.fromEntries(filteredSite);
      const fieldSchemaLead = Object.fromEntries(filteredOverview);
      const fieldSchemaContact = Object.fromEntries(filteredContact);
      this.log("filteredContact ", filteredContact);
      // Convert the object into an array of entries [key, value]
      const entries = Object.entries(fieldSchemaLead);
      const entriesForContact = Object.entries(fieldSchemaContact);
      const entriesForSite = Object.entries(fieldSchemaSite);
      // Sort based on `others.displayOrder` if it exists
      entries.sort((a, b) => {
        const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
        const orderB = b[1].others?.displayOrder ?? Infinity;
        return orderA - orderB; // Sort ascending
      });
      entriesForContact.sort((a, b) => {
        const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
        const orderB = b[1].others?.displayOrder ?? Infinity;
        return orderA - orderB; // Sort ascending
      });
      entriesForSite.sort((a, b) => {
        const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
        const orderB = b[1].others?.displayOrder ?? Infinity;
        // Handle custom placement logic for fields like 'siteAddress' and 'firstOccupancyDate'
        if (a[0] === "siteAddress") {
          // Ensure 'siteAddress' is treated as occupying multiple places (e.g., positions 5, 7, 9, 11)
          if (a[1].others.displayOrder === 5) return -1; // Move to the desired position
          if (a[1].others.displayOrder === 7) return -1;
          if (a[1].others.displayOrder === 9) return -1;
          if (a[1].others.displayOrder === 11) return -1;
        }
        return orderA - orderB; // Sort ascending
      });

      // Convert back to an object
      const sortedfieldSchemaLead = Object.fromEntries(entries);
      const sortedfieldContactLead = Object.fromEntries(entriesForContact);
      const sortedfieldSiteLead = Object.fromEntries(entriesForSite);
      this.log(
        sortedfieldContactLead,
        "sortedfieldSchemaLead",
        sortedfieldSchemaLead
      );
      if (sortedfieldSchemaLead && sortedfieldSchemaLead.metadata) {
        sortedfieldSchemaLead.metadata.show = false;
      }
      this.languageOptions = await this.addTranslationsSame(
        this.$store?.getters?.loginTenantDetails?.languageAbility
      );
      this.log("languageOptions ", this.languageOptions);
      this.languageOptions = this.languageOptions.map((lang) => ({
        ...lang,
        default: lang?.code == "en" || lang?.name?.toLowerCase() == "english",
      }));

      sortedfieldContactLead["defaultLanguage"].options = this.languageOptions;
      sortedfieldSiteLead.siteAddress.others.isValidationRuleEnabled = false;
      this.leadSchema = JSON.parse(JSON.stringify(sortedfieldSchemaLead));
      this.contactSchema = JSON.parse(JSON.stringify(sortedfieldContactLead));
      this.contactSchemaDuplicate = JSON.parse(
        JSON.stringify(sortedfieldContactLead)
      );
      this.siteEmplySchemaWithoutData = JSON.parse(
        JSON.stringify(sortedfieldSiteLead)
      );
      const data = await DMNAxiosInstance.get(
        `mapRef/collection/${this.siteEmplySchemaWithoutData?.marketSegment?.optionRef}`
      );
      this.marketSegmentOptions = data?.data?.data;
      this.log("this.marketSegmentOptions", this.marketSegmentOptions);
      this.siteSchema = JSON.parse(JSON.stringify(sortedfieldSiteLead));
      this.log(
        sortedfieldContactLead,
        "this.contactSchema",
        this.contactSchema,
        "this.languageOptions",
        this.languageOptions
      );
    },
    async importdatamodal1() {
      await UPCAxiosInstance.post(`salesLead`, this.importData);
      this.notification = {
        type: "success",
        message:
          this.loadStaticData("LANG-0025") ||
          "Leads are imported successfully!.",
      };
      this.importDataModal = false;
      try {
        const response = await UPCAxiosInstance.get(`/salesLead`);
        this.tableData = response?.data || [];
        this.tableData = this.tableData?.filter(
          (ele) => ele?.externalReference?.length > 0 || ele.numberOfUnits
        );
        // this.log(response,"this.tableData",this.tableData)
      } catch (error) {
        console.error("Error fetching salesLead data:", error);
        this.tableData = [];
      }
    },
    async newEmit(event) {
      const file = event.target.files[0]; // Get the uploaded file from the input
      if (!file) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("file", file, file.name);
      try {
        let res = await DMNAxiosInstance.post(
          `import/map_data/?type=saleslead&id=${this.templateId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.data.ok == 1) {
          this.importData = res.data.data.map((i) => i.saleslead);
          this.insights = res.data.insights;
          this.insights.map((i) => {
            i.missingFields.map((j) => {
              let obj = {};
              obj["errorPosition"] = i.recordIndex;
              if (j.csvHeader == null) {
                obj["error"] = `${j.systemField} => ${j.message}`;
              } else {
                obj[
                  "error"
                ] = `${j.systemField}-${j.csvHeader} => ${j.message}`;
              }
              this.errorTableData.push(obj);
            });
            this.log(this.errorTableData, "this.errorTableData");
          });
        }
      } catch (error) {
        console.error(
          "Error uploading file:",
          error.response?.data || error.message
        );
      }
    },
    openImport() {
      // this.importDataModal = true
      this.$router.push({
        path: `/MDU/lead/import`,
      });
    },
    async closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        this.navigateWithQuery(ele, "edit");

        // this.$router.push({
        //   path: `/MDU/lead/edit/${ele.name}/${ele._id}`,
        // });
      } else if ($event.toLowerCase() == "mark as closed lost") {
        ele.context = false;
        try {
          const response = await UPCAxiosInstance.patch("/salesLead", {
            ...ele,
            status: "Closed Lost",
          });
          const getUpdatedData = await UPCAxiosInstance.get(`/salesLead`);
          this.tableData = getUpdatedData.data;
          this.leadOrgData = getUpdatedData.data;
          if (getUpdatedData.data) {
            this.notification = {
              type: "success",
              message: this.matchFromStatic(
                "Lead status updated successfully!"
              ),
            };
          }
          this.log("Patch successful:", response.data);
        } catch (error) {
          console.error(i, "Error updating salesLead:", error);
        }
      }
    },
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 138;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    computedTableAction(data) {
      data;
      // this.dataVisibilityPermission(data.createdBy,this.ViewAndEdit,this.view,this.permisstionData)
      const permisttionData = this.dataVisibilityPermission(
        data.createdBy,
        this.ViewAndEdit,
        this.view,
        this.permisstionData
      );

      this.log(permisttionData, "permisttionDatadataVisibilityPermission");
      if (permisttionData.edit) {
        return [
          {
            display: this.loadStaticData("LANG-0009"),
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            // display: this.loadStaticData('LANG-0051'),
            display: this.matchFromStatic("Create quote"),
            label: "Create quote",
            icon: "icon-file-text-regular",
          },
        ];
      }

      // let options = [
      //   {
      //     label: "Edit",
      //     icon: "icon-file-text-edit-regular",
      //   },
      //   {
      //     label: "Mark as closed lost",
      //     icon: "icon-times-circle-regular",
      //   },
      // ];
      // return options.filter((option) => {
      //   if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "active" &&
      //     ["Mark as Active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "inactive" &&
      //     ["Mark as In-active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
    },
    leadStatus(val) {
      this.leadPayload.status = val?.name;
    },
    async searchKey(val) {
      // if(val){
      this.searchvalue = val;
      this.filterQuery["type"] = "filter-search";
      this.filterQuery["searchQuery"] = this.searchvalue;
      this.filterQuery["searchList"] = [
        {
          dwellingtype: {
            $regex: val,
            $options: "i",
          },
        },
        {
          "relatedParty.value": {
            $regex: val,
            $options: "i",
          },
        },
        {
          displayID: {
            $regex: val,
            $options: "i",
          },
        },
      ];
      this.currPage = 1;
      this.rowsPerPage = 10;
      //   const filterData = {
      //   company: this.companyName,
      //   type: "filter-search",
      //   module: "saleslead",
      //   isPaginated: true,
      //   lastUpdated: new Date().toJSON(),
      //   paginatedQuery: {
      //     rowsPerPage: this.rowsPerPage,
      //     currentPage: this.currPage,
      //   },
      //   isNeedToBeSort: true,
      //   sortFieldAndOrder: {},
      //   limit: 10,
      //   filterQuery: {},
      //   searchQuery: val,
      // };

      const response = await UPCAxiosInstance.post(
        `util/filterData?company=${this.companyName}`,
        this.filterQuery
      );
      if (response.data.data != undefined) {
        this.tableData = await response.data.data;
        this.totalPageNo = await response.data.total;
        this.paginatedData;
        // }
      }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    async currentPage(val) {
      this.currPage = val;
      await this.getLeadTableData(this.currPage, this.rowsPerPage);
    },
    OpenAddLeadModal() {
      this.isLeadDetailsFilled = false;
      this.importDataModal = false;
      this.leadPayload = JSON.parse(JSON.stringify(this.leadPayloadEmpty));
      this.siteDetails = [];
      this.showAllSitesModal = false;
      this.showSiteInformationModal = false;
      this.showAddNewLeadModal = true;
    },
    getTypeOfContact(val) {
      this.log("getTypeOfContact", val);
      this.leadContactDetails.contactType = val?.name;
      this.log("this.leadContactDetails", this.leadContactDetails);
    },
    async getSiteDataAfterSiteButtonClicked() {
      this.siteDetailsEmpty = JSON.parse(
        JSON.stringify(this.temporaryleadSiteDetails)
      );
      await this.addNewLeadAndSite();
    },
    async addNewLeadAndSite() {
      // Add new lead function
      let currentDate = new Date().toJSON();
      this.leadContactDetails.createdAt = currentDate;
      this.leadContactDetails.preferred = true;
      this.leadContactDetails.lastUpdate = currentDate;
      let newContactPayload = JSON.parse(
        JSON.stringify(this.leadContactDetails)
      );
      const email = Array.isArray(newContactPayload.contactMedium)
        ? newContactPayload.contactMedium.find(
            (medium) =>
              medium.mediumType === "email" &&
              medium.characteristic?.emailAddress
          )?.characteristic?.emailAddress
        : "";

      // Post it in user
      newContactPayload.contactMedium.push(this.instanceAddress);
      newContactPayload = {
        ...newContactPayload,

        email: email,
        root: this.rootParent,
        parentCompany: this.companyName,
        company: this.companyName,
        "@baseType": "lead",
        type: "individual",
        companyName: this.companyName,
        familyName:
          newContactPayload.firstName + " " + newContactPayload?.lastName,
        givenName:
          newContactPayload.firstName + " " + newContactPayload?.lastName,
        tradingName: this.leadPayloadEmpty?.name,
        birthDate: "",
        status: "Active",
        role: "Sales Support",
        name: newContactPayload.firstName + " " + newContactPayload.lastName,
        languageAbility: [{ code: "en", name: "english" }],
      };

      // delete newContactPayload.contactType;
      delete newContactPayload["@type"];
      try {
        if (this.selectedLeadContactEmail != email) {
          const contactPostInPartCollection = await MLTAxiosInstance.post(
            `partymanagement/individual/account/signup`,
            newContactPayload
          );
          this.log("contactPostInPartCollection", contactPostInPartCollection);
          this.leadContactDetails.userId =
            contactPostInPartCollection?.data?.userId;
          this.leadContactDetails.displayId =
            contactPostInPartCollection?.data?.displayID;
        } else if (this.selectedLeadContactEmail == email) {
          this.leadContactDetails.userId = this.selectedLeadContactUserId;
          this.leadContactDetails.displayId = this.selectedLeadContactDisplayId;
        }
      } catch (error) {
        console.log("Error from adding the contact");
      }
      this.leadPayloadEmpty.createdAt = currentDate;
      this.leadPayloadEmpty.lastUpdate = currentDate;

      this.disableAddButton = true;
      this.dupSiteDetailsForMapping = JSON.parse(
        JSON.stringify(this.siteDetailsEmpty)
      );
      this.log(
        this.leadPayloadEmpty,
        "leadContactDetails",
        this.leadContactDetails
      );
      this.log("this.siteDetailsEmpty", this.siteDetailsEmpty);
      this.log("this.dupSiteDetailsForMapping", this.dupSiteDetailsForMapping);
      this.siteDetailsEmpty = this.siteDetailsEmpty.map((detail) => {
        let updatedDetail = { ...detail };
        if (updatedDetail.siteAddress) {
          updatedDetail.contactMedium = updatedDetail.siteAddress;
          delete updatedDetail.siteAddress;
        }
        if (updatedDetail.siteName) {
          updatedDetail.name = updatedDetail.siteName;
          delete updatedDetail.siteName;
          updatedDetail.contactName = this.leadPayloadEmpty?.name;
        }
        if (Array.isArray(updatedDetail.contactMedium)) {
          updatedDetail.contactMedium = updatedDetail.contactMedium.map(
            (medium) => {
              return { ...medium, mediumType: "postalAddress" };
            }
          );
        }
        updatedDetail.createdAt = currentDate;
        updatedDetail.lastUpdate = currentDate;
        updatedDetail.tradingName = this.leadPayloadEmpty.name;

        return updatedDetail;
      });
      let dupSiteDetails = JSON.parse(JSON.stringify(this.siteDetailsEmpty));
      this.leadPayloadEmpty.relatedParty.push(this.leadContactDetails);
      // this.leadPayload.relatedParty[this.chosenSiteIndex + 2].name = this.siteDetails[this.chosenSiteIndex].name
      // To add the new lead
      this.log("this.siteDetails", this.siteDetailsEmpty);
      this.log(
        dupSiteDetails,
        "this.leadPayload",
        this.leadPayloadEmpty,
        "dupSiteDetailsForMapping",
        this.dupSiteDetailsForMapping
      );
      try {
        const patchRequests = this.siteDetailsEmpty.map(async (site) => {
          let response;
          if (site.remapSiteId) {
            site.id = site.remapSiteId;
            delete site.remapSiteId;
            response = await UPCAxiosInstance.patch(`geoSite`, site);
          } else {
            response = await UPCAxiosInstance.post(`geoSite`, site);
            this.log("Response for site:", site, response.data);
          }
          if (response) {
            const existingSite = this.leadPayloadEmpty.relatedParty.find(
              (party) =>
                party.name == response.data.name && party.id == response.data.id
            );

            if (!existingSite) {
              this.leadPayloadEmpty.relatedParty.push({
                name: response?.data?.name ?? "",
                id: response?.data?.id ?? "",
                "@type": "site",
                street:
                  response?.data?.contactMedium?.[0]?.characteristic?.street ??
                  "",
                city:
                  response?.data?.contactMedium?.[0]?.characteristic?.city ??
                  "",
                province:
                  response?.data?.contactMedium?.[0]?.characteristic
                    ?.stateOrProvince ?? "",
                postalCode:
                  response?.data?.contactMedium?.[0]?.characteristic
                    ?.postalCode ?? "",
                postalCodePrefix:
                  response?.data?.contactMedium?.[0]?.characteristic
                    ?.postalCodePrefix ?? "",
                country:
                  response?.data?.contactMedium?.[0]?.characteristic?.country ??
                  "",
                latitude:
                  response?.data?.contactMedium?.[0]?.characteristic
                    ?.latitude ?? "",
                longitude:
                  response?.data?.contactMedium?.[0]?.characteristic
                    ?.longitude ?? "",
                networkId: response?.data?.networkId ?? "",
                networkManager: response?.data?.networkManager ?? "",
              });
            }
          }
        });

        const responses = await Promise.all(patchRequests);
        this.log("All responses:", responses);
        this.leadPayloadEmpty.type = this.leadPayloadEmpty?.leadType;
        this.leadPayloadEmpty.tradingName = this.leadPayloadEmpty?.name;
        delete this.leadPayloadEmpty.leadType;
        this.leadPayloadEmpty.tradingName = this.leadPayloadEmpty?.name;
        if (this.selectedLeadName == this.leadPayloadEmpty.name) {
          this.leadPayloadEmpty.accountRef = this.selectedLeadAccountRef;
        }
       delete this.leadPayloadEmpty._id
        if (responses) {
          this.createLead = await UPCAxiosInstance.post(
            `salesLead/v1`,
            this.leadPayloadEmpty
          );
          this.log(this.createLead, "Lead created response");
        }
        if (responses) {
          this.showSiteInformationModal = false;
          this.showAllSitesModal = false;
          this.showAddNewLeadModal = false;
          this.disableAddButton = false;
          if (this.selectedLeadContactEmail != email) {
            newContactPayload.contactId = this.createLead?.data?.accountRef;
            newContactPayload.id = this.leadContactDetails.userId;
            // delete newContactPayload.email
            let result = await MLTAxiosInstance.patch(
              `/partymanagement/individual`,
              newContactPayload
            );
            this.log(result, "result for patch");
            newContactPayload;
          }
          let getAllContactsOfLead = {
            type: "filter-search",
            module: "hierarchycontacts",
            isPaginated: false,
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: false,
            sortFieldAndOrder: {},
            limit: 2,
            filterQuery: {
              "@baseType": "lead",
            },
          };

          let siteDetails = JSON.parse(JSON.stringify(this.createLead?.data));

          let sitesDataInPayload = siteDetails.relatedParty.filter(
            (ele) => ele["@type"] == "site" || ele["@referredType"] == "site"
          );
          this.log("sitesDataInPayload", sitesDataInPayload);
          let siteIds = sitesDataInPayload.map((site) =>
            site.id ? site.id : site.siteId
          );
          //  Add reference id to all sites
          let getAllSitesData = await UPCAxiosInstance.get("/geoSite");
          if (getAllSitesData) {
            let sitesTableData = getAllSitesData?.data?.filter((ele) =>
              siteIds.includes(ele?.id)
            );
            this.log(getAllSitesData, "sitesTableData", sitesTableData);

            const requests = sitesTableData.map((site) => {
              const siteCopy = { ...site };
              delete siteCopy._id;
              siteCopy.contactId = this.createLead?.data?.accountRef;

              return UPCAxiosInstance.patch(`geoSite`, siteCopy);
            });

            try {
              const responses = await Promise.all(requests);
              this.log("All sites updated successfully:", responses);
            } catch (error) {
              console.error("Error updating one or more sites:", error);
            }
          }
          // let company = this.$store?.getters?.loginUserDetails?.company
          // get all email of contacts to check duplicates
          const contactpayload = await MLTAxiosInstance.post(
            `util/filterData`,
            getAllContactsOfLead
          );
          this.log(contactpayload.data, "contactpayload.data");
          //  let leadContact = contactpayload.data.filter((data)=>{
          //   return data.status == 'lead'
          //  })
          this.emailArrayForContacts = contactpayload?.data?.data
            .filter((item) => item["@baseType"] === "lead" && item?.email)
            .map((item) => item.email);
          let getAllSites = await UPCAxiosInstance.get("/geoSite");
          this.siteAddressArray = getAllSites.data
            .map((item) =>
              item.contactMedium.map((cm) => cm.characteristic.description)
            )
            .flat()
            .filter((description) => description);
          this.log("this.siteAddressArray", this.siteAddressArray);
        }
        await this.getLeadTableData();
        if (responses) {
          // this.tableData = await response?.data || [];
          // this.tableData = await this.tableData?.filter(ele=>ele?.externalReference?.length > 0 || ele.numberOfUnits)
          // this.leadOrgData = JSON.parse(JSON.stringify(this.tableData))
          // this.log(this.tableData,"this.tableData",response.data)
          // this.showSiteInformationModal = false;
          // this.showAllSitesModal = false;
          // this.showAddNewLeadModal = false;
          // this.disableAddButton = false;
          this.notification = {
            type: "success",
            message:
              this.loadStaticData("LANG-0037") ||
              `New lead created successfully!.`,
          };
          this.filteredCustomFieldSchema();
          this.siteDetailsPayload = JSON.parse(
            JSON.stringify(this.reassignSiteDetailsPayload)
          );
          this.siteDetailsEmpty = { ...this.reassignSiteDetailsEmpty };
          this.leadContactDetails = { ...this.reassignLeadContactDetails };
          this.leadPayloadEmpty = { ...this.reassingnLeadPayloadEmpty };
          await this.$store.dispatch("leadMainFilterData");
          this.filter = this.$store.getters.leadMainFilterData;
        }
        if (responses) {
          // Activity log for lead creation
          const leadCreationActivityLogPayload = {
            message: `${this.createLead?.data?.name} lead has been created`,
            detailedAction: [
              {
                action: "Created lead",
                from: "",
                to: "",
                property: "Lead Created",
                field: "",
                displayID: this.createLead?.data?.displayID,
              },
            ],
            action: "created",
            user: this.$cookies.get("userName"),
            lastUpdate: new Date().toISOString(),
            createdBy: this.$cookies.get("user"),
            ref: [this.createLead?.data?.id],
            "@type": "lead-creation",
            category: ["lead"],
          };
          // this.initLog(leadCreationActivityLogPayload);
          let response = await UPCAxiosInstance.post(
            "/activityLog/v1",
            leadCreationActivityLogPayload
          );
          this.log(response);
          this.log(
            leadCreationActivityLogPayload,
            "leadCreationActivityLogPayload"
          );
        }
        if (responses) {
          let dupLeadData = JSON.parse(JSON.stringify(this.createLead?.data));
          delete dupLeadData?._id;
          let tmfToSfdc = await UPCAxiosInstance.post(`/webhook/tmftosfdc`, {
            data: this.createLead?.data,
            type: "Contact",
          });
          await UPCAxiosInstance.post(`/webhook/triggerInistated`, {
            module: "Contact",
            action: "POST",
            data: tmfToSfdc?.data,
          });
          console.log(tmfToSfdc);
        }
        // if (responses) {
        //   // Create an account for this lead => status of this account is Lead
        //   const accountPayload = {
        //     name: this.leadPayloadEmpty.name,
        //     email: `${this.leadPayloadEmpty.name}lead@gmail.com`,
        //     status: 'Lead',
        //     externalReference: this.leadPayloadEmpty.externalReference,
        //     relatedParty: this.leadPayloadEmpty.relatedParty.filter(
        //       party => party["@type"] === "leadOwner" || party["@type"] === "leadSource"
        //     ),
        //   };
        //   accountPayload['@baseType'] = "customer"
        //   try {
        //    let resultForAddingAccount = await MLTAxiosInstance.post(
        //             `/partymanagement/individual/account/signup`,
        //             accountPayload
        //           );
        //           this.log("resultForAddingAccount",resultForAddingAccount)
        //   } catch (error) {
        //       this.log("Error from adding an account")
        //   }
        //   // Contact add for that account
        //   const accountContact = {
        //     email: this.leadContactDetails.contactMedium[0].characteristic.emailAddress,
        //     companyName: this.leadPayloadEmpty.name,
        //     company: this.leadPayloadEmpty.name,
        //     tradingName: this.leadPayloadEmpty.name,
        //     parentCompany: this.companyName,
        //     rootName: this.companyName,
        //     firstName: this.leadContactDetails.firstName,
        //     lastName: this.leadContactDetails.lastName,
        //     role: "customer-contact",
        //     relatedParty: [],
        //   };
        //   // Add related party
        //   accountContact.relatedParty.push({
        //     role: "Supervisor",
        //     organization: this.leadPayloadEmpty.name,
        //     "@type": "multiorganization",
        //   });
        //   accountContact['@baseType'] = "customer"
        //   try {
        //    let resultForAddingContact = await MLTAxiosInstance.post(
        //           `/partymanagement/individual/account/signup`,
        //           accountContact
        //         );
        //         this.log("resultForAddingContact",resultForAddingContact)
        //   } catch (error) {
        //     this.log("Error from adding the contact",error)
        //   }
        //   this.log(accountContact, "accountPayload", accountPayload);
        // }
      } catch (error) {
        console.error("Error patching site details:", error);
      }
      //   try {

      // }
      //   // this.log(response,"this.tableData",this.tableData)
      //   } catch (error) {
      //     console.error("Error fetching salesLead data:", error);
      //     this.tableData = [];
      //   }
    },
    getTitleForContact(val) {
      this.log("getTitleForContact", val);
      this.leadContactDetails.title = val?.name;
    },
    websiteRegex(val) {
      const websiteRegex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

      if (val?.length >= 1) {
        if (!websiteRegex.test(val)) {
          this.isUrlEmpty = true;
        } else {
          this.isUrlEmpty = false;
        }
      } else {
        this.isUrlEmpty = false;
      }
    },
    goToLeadDetails() {
      this.log("this.customFieldLeadSchema", this.customFieldLeadSchema);
      // this.componentKey+=1
      this.log(
        this.contactSchema,
        "goToLeadDetails contact",
        this.leadContactDetails
      );
      this.log(this.leadSchema, "goToLeadDetails lead", this.leadPayloadEmpty);
      this.showAllSitesModal = false;
      this.showSiteInformationModal = false;
      this.showAddNewLeadModal = true;
      //         this.leadSchema
      let reAssignContactSchema = this.contactSchema;
      let reassignContactData = this.leadContactDetails;
      this.leadContactDetails = JSON.parse(JSON.stringify(reassignContactData));
      this.contactSchema = JSON.parse(JSON.stringify(reAssignContactSchema));
      // this.leadPayloadEmpty
      this.siteDetailsEmpty = JSON.parse(
        JSON.stringify(this.temporaryleadSiteDetails)
      );
    },
    getLeadDealCategory(val) {
      this.leadPayload.dealCategory = { name: val.name };
    },
    getLeadOwner(val) {
      this.log("getLeadSource", val);
      this.leadPayload.relatedParty[0].value = val.name;
      this.log("this.leadPayload", this.leadPayload);
    },
    getLeadSource(val) {
      // to get choosen lead source

      this.log("getLeadSource", val);
      this.leadPayload.relatedParty[1].value = val.name;
      this.log("this.leadPayload", this.leadPayload);
    },
    getLeadType(val) {
      this.log("getLeadType", val);
      this.leadPayload.type = val?.name;
    },
    getLeadDwellingType(val) {
      this.leadPayload.dwellingtype = val.name;
    },
    getLeadCategory(val) {
      this.leadPayload.category = { name: val.name };
    },
    getLeadMarketSegment(val) {
      this.siteDetails[this.chosenSiteIndex].marketSegment = { name: val.name };
      this.log("this.siteDetails", this.siteDetails);
    },
    getLeadOnboardingDate(val) {
      this.log("getLeadOnboardingDate", val);
      this.siteDetails.onboardingDate = val;
    },
    showSitesDropDown() {
      // To create an lead
      // this.isSaveButtonClickedForLead = true
      // this.isSaveButtonClickedForContact = true
      this.leadContactDetails = JSON.parse(
        JSON.stringify(this.temporaryleadContactPayload)
      );
      this.contactSchema = JSON.parse(
        JSON.stringify(this.contactSchemaWithValue)
      );
      this.leadPayloadEmpty = JSON.parse(
        JSON.stringify(this.temporaryleadPayload)
      );
      this.showAllSitesModal = true;
      this.isSaveButtonClickedForLead = false;
      this.isSaveButtonClickedForContact = false;
      this.openSiteDetails(1, 0);
    },
    getLeadDataForOverview(val) {
      if (val) {
        this.log("getLeadDataForOverview", val);
        // this.leadPayloadEmpty = JSON.parse(JSON.stringify(val))
        this.temporaryleadPayload = JSON.parse(JSON.stringify(val));
        // this.buildCompletePayload(this.temporaryleadPayload)
        this.leadName = this.temporaryleadPayload.name;
        // this.totalSites = this.temporaryleadPayload.externalReference[1].value;

        this.leadCompletePayload.name = this.temporaryleadPayload.name;
        this.leadCompletePayload.category = this.temporaryleadPayload.category;
        this.leadCompletePayload.dwellingtype =
          this.temporaryleadPayload.dwellingtype;
        this.leadCompletePayload.description =
          this.temporaryleadPayload.description;
        this.leadCompletePayload.status = this.temporaryleadPayload.status;
        this.leadCompletePayload.type = this.temporaryleadPayload.leadType;
        // Find lead owner and update
        let leadOwner = this.temporaryleadPayload.relatedParty.find(
          (party) => party["@type"] === "leadOwner"
        );
        this.leadCompletePayload.relatedParty =
          this.leadCompletePayload.relatedParty.map((party) => {
            if (party["@type"] == "leadOwner") {
              return leadOwner;
            }
            return party;
          });
      }
    },
    getLeadData(Val) {
      if (Val) {
        console.log("🚀 ~ getLeadData ~ Val:", Val);
        // this.leadContactDetails = JSON.parse(JSON.stringify(Val))
        this.temporaryleadContactPayload = JSON.parse(JSON.stringify(Val));
        this.log("getLeadData", Val);
        let individualIndex = this.leadCompletePayload.relatedParty.findIndex(
          (party) => party["@type"] === "individual"
        );
        if (individualIndex > -1) {
          this.leadCompletePayload.relatedParty.splice(individualIndex, 1, Val);
        } else {
          this.leadCompletePayload.relatedParty.push(Val);
        }
      }
      // this.showAllSitesModal=true
      // this.isSaveButtonClickedForLead = false
      // this.isSaveButtonClickedForContact = false
    },

    async openSiteDetails(i, index) {
      this.log(index, "openSiteDetails", i);
      this.chosenSiteIndex = i - 1;
      // this.showSiteInformationModal = true;
      this.siteDetailsEmpty[this.chosenSiteIndex]
        ? this.siteDetailsEmpty[this.chosenSiteIndex]
        : (this.siteDetailsEmpty[this.chosenSiteIndex] = {
            siteName: "",
            networkId: "",
            remapSiteId: "",
            networkManager: {
              value: "",
              email: "",
              id: "",
            },
            createdAt: "",
            lastUpdate: "",
            sfdcId: "",
            numberOfUnits: "",
            tags: [],
            firstOccupancyDate: "",
            onboardingDate: "",
            marketSegment: {},
            siteAddress: [
              {
                mediumType: "postalAddress",
                characteristic: {
                  "@type": "",
                  street: "",
                  city: "",
                  country: "",
                  default: false,
                  description: "",
                  postalCode: "",
                  postalCodePrefix: "",
                  state: "",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  unit: "",
                  latitude: "",
                  longitude: "",
                },
              },
            ],
          });

      // this.leadPayloadEmpty.relatedParty [this.chosenSiteIndex + 2] ? this.leadPayloadEmpty.relatedParty[this.chosenSiteIndex + 2]  : this.leadPayloadEmpty.relatedParty[this.chosenSiteIndex + 2] = {
      //   name:this.siteDetailsEmpty?.[this.chosenSiteIndex]?.siteName || '',
      //   id:"",
      //   '@type':'site',
      // }
      this.siteDetailsPayload = this.siteDetailsEmpty[this.chosenSiteIndex];
      this.currentSiteObject = JSON.parse(
        JSON.stringify(this.siteDetailsPayload)
      );
      // await this.checkAddressValidation(this.currentSiteObject?.siteAddress);
      this.showSiteInformationModal = true;
      this.log(
        this.siteSchemaArrayOfObjects,
        this.siteSchema,
        "adnsfba",
        this.siteDetailsPayload,
        this.leadPayloadEmpty
      );
      this.siteSchema;
      this.siteDetailsEmpty;

      // Error from network id and SFDC id

      // await this.getSiteInputFocusOut(
      //   this.currentSiteObject?.sfdcId ? this.currentSiteObject?.sfdcId : '',
      //   { fieldName: 'SFdCID' }
      // );
      // await  this.getSiteInputFocusOut(this.currentSiteObject?.networkId ? this.currentSiteObject?.networkId : '' , {'fieldName':'networkId'})
      // this.characteristicpreval.characteristic = this.siteDetails[this.chosenSiteIndex].contactMedium[0].characteristic
      // this.log(this.characteristicpreval.characteristic,"Site Details",index,"leadPayload?.relatedParty[chosenSiteIndex]?.name")
      // this.log( this.siteDetails,"chosenSiteIndex",this.leadPayload?.relatedParty)
      // this.siteDetailsEmpty = JSON.parse(JSON.stringify(this.temporaryleadSiteDetails))
    },
    setSiteTags(val) {
      this.siteDetails[this.chosenSiteIndex].tags = { name: val?.name };
    },
    closeSiteDetails(index, i) {
      this.log(i);
      this.siteDetailsEmpty = JSON.parse(
        JSON.stringify(this.temporaryleadSiteDetails)
      );
      this.isSaveButtonClicked = true;
      // setTimeout(() => {
      // if (this.showSiteInformationModal) {
      this.log(
        this.chosenSiteIndex,
        "Forcing showSiteInformationModal to false",
        index
      );
      //     }
      // }, 50);
      if (this.chosenSiteIndex != index) {
        this.openSiteDetails(i, index);
      } else if (this.chosenSiteIndex == index) {
        this.showSiteInformationModal = false;
        this.showAllSitesModal = true;
      }
    },
    async getSiteDetails(val) {
      console.log("🚀 ~ getSiteDetails ~ val:", val);
      try {
        if (val) {
          if (Array.isArray(val)) {
            val = val[0];
          }
          this.currentSiteObject = { ...val };
          // this.temporaryleadSiteDetails = { ...val}
          this.temporaryleadSiteDetails.splice(this.chosenSiteIndex, 1, val);
          this.log(
            this.temporaryleadSiteDetails[this.chosenSiteIndex],
            "temporaryleadSiteDetails",
            this.temporaryleadSiteDetails
          );
          let relatedParty = this.leadCompletePayload.relatedParty.filter(
            (party) => party["@type"] !== "site"
          );

          let sites = JSON.parse(
            JSON.stringify(this.temporaryleadSiteDetails)
          ).map((site) => {
            site.contactMedium = JSON.parse(JSON.stringify(site.siteAddress));
            site.name = site.siteName;
            site["@type"] = "site";
            delete site.siteAddress;
            delete site.siteName;
            return site;
          });
          console.log(relatedParty, "Before sites");
          console.log("🚀 ~ sites ~ sites:", sites);
          console.log("After sites");
          this.leadCompletePayload.relatedParty = relatedParty.concat(sites);
          // this.log(
          //   this.temporaryleadSiteDetails,
          //   "this.temporaryleadSiteDetails",
          //   val
          // );
          // this.log(
          //   this.chosenSiteIndex,
          //   "this.temporaryleadSiteDetails",
          //   this.temporaryleadSiteDetails
          // );
          // this.siteAddressArray = this.pushAndSliceFromArray(this.chosenSiteIndex,this.siteAddressArray,this.temporaryleadSiteDetails)
          //   this.log(this.siteDetailsEmpty,"getSiteDetails",val)
          //  await this.siteDetailsEmpty.splice(this.chosenSiteIndex, 1,val)
          //   this.log("this.siteDetailsEmpty")
          //   this.dupSiteDetailsForMapping = JSON.parse(JSON.stringify(this.siteDetailsEmpty))
          //   // this.leadPayloadEmpty.relatedParty[this.chosenSiteIndex + 2].name = this.siteDetailsEmpty?.[this.chosenSiteIndex]?.siteName
          //   this.log(this.siteDetailsEmpty,"chosenSiteIndex",this.leadPayloadEmpty)
          //   this.showSiteInformationModal = false
          //   this.showAllSitesModal=true
          //   this.isSaveButtonClicked = false
          console.log(
            "🚀 ~ getSiteDetails ~ this.temporaryleadSiteDetails:",
            this.temporaryleadSiteDetails
          );
          console.log(
            "🚀 ~ getSiteDetails ~ this.leadCompletePayload:",
            this.leadCompletePayload
          );
        }
      } catch (error) {
        console.error("🚀 ~ getSiteDetails ~ error:", error);
      }
      // this.log(
      //   this.siteDetailsEmpty,
      //   "chosenSiteIndex",
      //   this.temporaryleadSiteDetails
      // );
    },
    receivedAddressData(val) {
      this.siteDetails[this.chosenSiteIndex].contactMedium[0].characteristic =
        val.characteristic;
      // this.log(
      //   this.siteDetails[this.chosenSiteIndex].contactMedium,
      //   "receivedAddressData",
      //   val
      // );
    },
    geopoint(lat, log) {
      this.log(lat, "geopoint", log);
      this.siteDetails[
        this.chosenSiteIndex
      ].contactMedium[0].characteristic.latitude = lat;
      this.siteDetails[
        this.chosenSiteIndex
      ].contactMedium[0].characteristic.longitude = log;
    },
  },
};
</script>

<style lang="scss">
.lead-add-main-table-modal {
  .modal-backdrop1 {
    .hlx-modal-container {
      height: 680px !important;
      width: 800px !important;
      overflow-y: auto;
    }
  }
}
.to-style-modal {
  .modal-backdrop1 {
    .hlx-modal-container {
      height: 600px !important;
      overflow-y: auto;
    }
  }
}
.parent-container-for-lead-maintable-overview {
  .form-container {
    padding-bottom: 0px;
  }
}
</style>
