<template>
  <!-- Filter drawer-->
  <hlx-drawer
    :show="show_right_filter"
    :width="300"
    position="right"
    :header="false"
    :footer="false"
    :show-close-icon="true"
    @close="closeRightFilter"
  >
    <template #body>
      <div style="display: flex">
        <FilterComponent
          :filterpanelname="'execution'"
          :company-name="companyasignee"
          :query-value="query"
          :display-data="filter"
          :module="'execution'"
          @filtered-data="ticketfilterData"
        ></FilterComponent>
      </div>
    </template>
  </hlx-drawer>

  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <div>
    <hlx-modal
      class="upc"
      :modal-active="add_model"
      :height="'439px'"
      :width="'500px'"
      :modal="true"
      @close="add_model = false"
    >
      <template #header>
        <div style="margin-left: 0px">New template</div>
      </template>
      <template #content>
        <div class="modal-content">
          <div class="input-section-v2">
            <hlx-input
              :key="ticketComponetI"
              v-model:value="payload.name"
              :label-animation="true"
              label-value="Name"
              :required="true"
              type="text"
              :clearable="true"
            />
            <div style="width: 100%; margin-top: 20px">
              <hlx-status
                :type="'select-status'"
                :status-option="filteredstatus"
                :prevalue="payload.status"
                @emit-select="emitStatus"
              >
              </hlx-status>
            </div>
            <div style="width: 100%; margin-top: 20px">
              <!-- v-model:value="payload.category[0].name" -->
              <!-- :pre-value="payload?.category[0]?.name" -->
              <hlx-select
                :key="CategoryCompentKey"
                :options="categoryType"
                :label="'value'"
                :prop-value="'value'"
                :placeholder-value="'Category'"
                :label-animation="true"
                :clearable="true"
                :required="true"
                @selected-value="categoryData"
              ></hlx-select>
            </div>

            <div style="width: 100%; margin-top: 20px">
              <hlx-input
                :key="ticketComponetD"
                v-model:value="payload.description"
                :label-animation="true"
                label-value="Description"
                type="textarea"
                :clearable="true"
              ></hlx-input>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="closeticket"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary sm"
          style="margin-left: 16px"
          :disabled="
            payload.name !== '' &&
            payload?.category[0]?.name !== '' &&
            categoryType?.length > 0
              ? false
              : true
          "
          @click="addNewTicket()"
          >Add</hlx-button
        >
      </template>
    </hlx-modal>
  </div>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'200px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <div v-if="addFields == false" class="app-layout catalog offering">
    <section class="new-right-panel upc">
      <div class="new-right-panel-parent-container no-filter-panel">
        <div id="mySidepanel" class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <section class="new-layout-basic-no-breadcrumb">
            <div class="new-layout-header">
              <span class="new-layout-header-container">
                <hlx-breadcrumb style="font-size: 16px" :auto-route="true" />

                <span class="schema-header-title">Tasks</span>
                <span class="subtitle">
                  Streamlined Actions for Efficiency
                </span>
              </span>
            </div>

            <div class="new-layout-body catalog-v2">
              <!-- search and filter  -->
              <div
                v-if="tableData?.length > 0 || searchvalue !== ''"
                class="header-utils-and-switch-container"
                style="width: 100%"
              >
                <span class="table-header-utils">
                  <span class="util-items --search"
                    ><hlx-search
                      v-model="searchvalue"
                      @search-key="searchKey"
                    ></hlx-search
                  ></span>

                  <span class="filter-table">
                    <hlx-button
                      class="secondary sm add-btn"
                      @click="showDrawerRight"
                    >
                      <i class="icon-filter-regular"></i>
                      <span v-if="filtertaglength != 0" class="filter-count">{{
                        filtertaglength
                      }}</span>
                    </hlx-button>
                  </span>

                  <span id="add-product-offer" class="util-items util-items-add"
                    ><hlx-button
                      class="primary sm add-btn"
                      @click.stop="addTicket()"
                      ><i
                        class="icon-plus-circle-regular"
                        style="margin-right: 5px"
                      ></i
                      >New template</hlx-button
                    >
                    <hlx-context-menu
                      :top="containertop1"
                      :left="containerleft1"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      style="z-index: 10000"
                      :options="add_context_options"
                      :show="show_add_offer"
                      :style="{ marginTop: '5px', marginRight: '20px' }"
                      @chosen="chooseTicket"
                    ></hlx-context-menu>
                  </span>
                </span>
              </div>

              <!-- image -->
              <section
                v-if="tableData?.length === 0 && searchvalue == ''"
                style="
                  margin-top: 1.2rem;
                  display: flex;
                  flex-direction: column;
                  gap: 1.2rem;
                  align-items: center;
                "
              >
                <span>
                  <img
                    src="@/assets/svg/TicketTemplateSVG.svg"
                    alt=""
                    style="width: 500px; height: 500px"
                  />
                </span>

                <span
                  class="util-items util-items-add"
                  @click="openuploaddrawer"
                >
                  <hlx-button class="primary media add-btn" @click="addTicket()"
                    ><i
                      class="icon-plus-circle-regular"
                      style="margin-right: 5px"
                    ></i
                    >New template</hlx-button
                  >
                </span>
              </section>

              <!-- TableData -->
              <div v-if="tableData?.length > 0" class="table-wrap">
                <hlx-table
                  :column-count="product_offerings_theads?.length + 2"
                  :border="['table', 'header', 'horizontal']"
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="product_offerings_theads_cc"
                  @sorting_func="sorting_Data"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'center'"
                      style="padding: 0 15px"
                      :fixed="'left'"
                      @new-resize-width="resizedWidth"
                      >{{ "S.no" }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-for="(i, index) in product_offerings_theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :fixed="i.fixed"
                      :width="i.width !== undefined ? i.width : ''"
                      :align="i.align !== undefined ? i.align : ''"
                      @sorting_func="sorting_Data"
                      @new-resize-width="resizedWidth"
                    >
                      {{ i.label }}</hlx-table-head
                    >

                    <hlx-table-head
                      :align="'center'"
                      :width="70"
                      :fixed="'right'"
                      @new-resize-width="resizedWidth"
                      ><span class="action-icon">
                        <i
                          class="icon-settings-regular"
                          @click="reAssignOrgTableHeader()"
                        ></i> </span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr v-for="(i, index) in paginatedData" id="" :key="index">
                      <hlx-table-cell :align="'center'" :fixed="'left'">
                        {{ serialNumber(index) }}
                      </hlx-table-cell>
                      <hlx-table-cell
                        v-for="(j, col_index) in product_offerings_theads"
                        :key="col_index"
                        :align="j.align"
                        :fixed="j.fixed"
                      >
                        <div
                          v-if="j.prop === 'name'"
                          class="link-name"
                          style="width: 200px"
                          @click="viewPage(i)"
                        >
                          {{
                            i[j.prop]?.charAt(0)?.toUpperCase() +
                            i[j.prop]?.slice(1)
                          }}
                        </div>
                        <div v-else-if="j.prop?.toLowerCase() === 'status'">
                          <hlx-status :type="i[j.prop]"></hlx-status>
                        </div>
                        <div
                          v-else-if="j.prop.toLowerCase() === 'category'"
                          style="margin: 0.5rem 0"
                        >
                          {{ i?.category }}
                        </div>
                        <div v-else>
                          <span>
                            {{ dateAndTimeFormatter(i[j.prop]) }}
                          </span>
                        </div>
                      </hlx-table-cell>
                      <hlx-table-cell
                        v-show="editMode == true"
                        :align="'center'"
                        :width="120"
                        :fixed="'right'"
                      >
                        <span :id="'table-context' + index" class="action-icon">
                          <!-- <i class="icon-edit-regular"></i> &nbsp;
                  <i class="icon-copy-regular"></i> &nbsp;
                  <i class="icon-trash-regular"></i> -->
                          <i
                            style="position: relative"
                            class="icon-more-vertical-filled"
                            :class="i.context === true ? 'active-action' : ''"
                            @click="crudContextMenu($event, index)"
                          >
                            <hlx-context-menu
                              :top="containertop"
                              :left="containerleft"
                              :conditionvalue="150"
                              :conditiondown="2"
                              :conditionup="-38"
                              :options="computedTableAction(i)"
                              :data="index.toString()"
                              :show="i.context == true"
                              @chosen="closeCrudContextMenu($event, i, index)"
                            />
                            <!-- <span
                      v-if="index < rowsPerPagePS / 2"
                      style="position: absolute"
                    >
                      <hlx-context-menu
                        style="
                          z-index: 9000;
                          margin-right: 100px !important;
                          margin-top: -10px;
                          box-shadow: 0 0 20px 0 #d8d8d8;
                        "
                        :options="computedTableAction(i)"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="closeCrudContextMenu($event, i)"
                      />
                    </span>
                    <span v-else style="position: absolute">
                      <hlx-context-menu
                        style="
                          z-index: 9000;
                          margin-right: 100px !important;
                          margin-top: -125px;
                          box-shadow: 0 0 20px 0 #d8d8d8;
                        "
                        :options="computedTableAction(i)"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="closeCrudContextMenu($event, i)"
                      />
                    </span> -->
                          </i>
                        </span>
                      </hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>

              <div
                v-if="tableData?.length <= 0 && searchvalue != ''"
                class="table-wrap"
              >
                <hlx-table
                  :column-count="product_offerings_theads?.length + 2"
                  :border="['table', 'header']"
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="product_offerings_theads_cc"
                  @sorting_func="sorting_Data"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'center'"
                      style="padding: 0 15px"
                      :fixed="'left'"
                      >{{ "S.no" }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-for="(i, index) in product_offerings_theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :fixed="i.fixed"
                      :width="i.width !== undefined ? i.width : ''"
                      :align="i.align !== undefined ? i.align : ''"
                      @sorting_func="sorting_Data"
                    >
                      {{ i.label }}</hlx-table-head
                    >

                    <hlx-table-head
                      v-show="editMode == true"
                      :align="'center'"
                      :width="70"
                      :fixed="'right'"
                      ><span class="action-icon">
                        <i
                          class="icon-settings-regular"
                          @click="reAssignOrgTableHeader()"
                        ></i> </span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell :colspan="theads?.length + 2">{{
                        "No data"
                      }}</hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>
            </div>

            <div class="new-layout-footer"></div>
          </section>
        </div>
      </div>

      <div
        v-if="tableData?.length > 0"
        class="pagination-container"
        style="padding: 2%"
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :total="totalvalue"
          enable-rows-per-page
          rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    </section>
  </div>
</template>

<script>
// import draggable from "vuedraggable";
import {
  DMNAxiosInstance,
  UPCAxiosInstance,
  MLTAxiosInstance,
} from "@/config/axiosConfig";
import FilterComponent from "@/components/filterPanelComponent.vue";

// import { mapGetters } from "vuex";

export default {
  components: {
    // draggable,
    FilterComponent,
  },
  props: {
    value: {
      type: String,
      default: "All",
    },
  },
  data() {
    return {
      tableHeaderOrg: [],
      tableHeaderOrgcc: [],
      ticketService: [],
      companyasignee: "",
      templateFilterQuery: {
        type: "filter",
        module: "execution",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: this.$cookies.get("company"),
      },
      ticketSpec: [],
      data1: [
        { name: "Priority level", label: "Dropdown" },
        { name: "Status", label: "Dropdown" },
        { name: "Tags", label: "MultiSelect" },
        { name: "Additional Comments", label: "Description field" },
        { name: "Attachment", label: "File uploader" },
      ],
      prompt: "",
      categoryType: [],
      isBasedOnFeature: true,
      isBasedOnPrompt: false,
      disableAIToolBarButton: false,
      AIChoicesForDescription: [],
      aiContextMenuPositionTop: 0,
      aiContextMenuPositionLeft: 0,

      isAIPromptContextMenuVisible: false,
      AIPromptChoicesForDescription: [],
      aiPromptContextMenuPositionTop: 0,
      aiPromptContextMenuPositionLeft: 0,

      isAIContextMenuVisible: false,
      activitytotal: 0,
      activityrowpage: 0,
      activitycurrentpage: 0,
      specActivity: {
        type: "filter",

        module: "log",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.activityrowpage,
          currentPage: this.activitycurrentpage,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: "productSpecification",
          ref: `${this.$route.params.id}`,
        },
        searchQuery: "",
      },
      logData: [],
      suffixValue: "",
      color: "",
      configValue: {
        url: `${process.env.VUE_APP_UPC_BASE}/tree`,
        key: "",
      },
      userDetails: {
        name: "",
        id: "",
      },
      viewMode: this.$route.params.view,
      discardWhiteList: ["_id", "__v"],
      rfsOptions: [],
      physicalOptions: [],
      logicalOptions: [],
      imgPrimary: {},
      error: false,
      event: null,
      isReload: false,
      primaryNavigationButtonValue: "Stay",
      secondaryNavigationButtonValue: "Discard",
      secondaryReloadButtonValue: "Leave",
      noteTrigger: "",
      searchNotes: [],
      searchToggle: false,
      noteIndex: 0,
      editNoteVal: {},
      userName: "",
      notesTable: [],
      editModeNote: false,
      interactionPayload: {
        id: "",
        reason: "",
        note: "",
        noteusername: "",
        date: new Date(),
      },
      discardIt: false,
      showOverviewDiscardModal: false,
      initialOverviewPayload: {
        name: "",
        category: [],
        validFor: {
          endOfLife: "",
          endOfDate: "",
        },
        relatedParty: [],
        unitOfMeasure: {
          name: "",
        },
      },
      filter: [],
      show_right_filter: false,
      Specfilterquery: {
        type: "search",
        module: "productSpecification",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
          // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
        },
        searchQuery: "",
      },
      showAddNotePanel: false,
      searchattach: "",
      delete_modal_active: false,
      deleteobj: {},
      delete_name: "",
      deleterowindex: 0,
      attachment_table: [
        {
          sortable: false,
          resizable: false,
          width: null,
          label: "Filename",
          prop: "name",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "File size",
          prop: "attachment",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 250,
          label: "Created By",
          prop: "lastUpdated",
          align: "left",
        },
      ],
      drawer: false,
      offer_table: [],
      userInfo: this.$store.state.userInfo,
      modal_active: false,
      editIndex: 0,
      editRow: {},
      editButton: false,
      searchData: [],
      search: false,
      featureAdd: false,
      offerTable: [],
      attachmentTable: [],
      show_add_menu: false,




      currPage: 1,
      rowsPerPage: 10,
      externalRowsPerPage: 10,
      totalPageNo: 0,
      SpecificationrowsPerPage: 10,
      SpecificationcurrPage: 1,
      filtertaglength: 0,
      filtertagvalue: [],
    
      categoryOptions: [],
      unit: "",
      ticketComponetD: 0,
      CategoryCompentKey: 0,
      ticketComponetI: 0,


      statusList: [],
      paymentList: [],
      ShipingList: [],
      Manufacturer: [],
      unitOfMeasure: [],
      relatedParty: "",
      endOfDate: "",
      tagsList: [
        {
          name: "SaaS",
          checked: true,
          disabled: false,
        },
      ],
      locationApi: "https://appbackend-ghubgwxrcq-pd.a.run.app/autocomplete",
      isRightLayoutOpened: false,
      breadCrumbItems: [
        {
          label: "Product specification ",
          link: `/UPC/product-catalog-table/product`,
        },
        { label: this.$route.params.company, link: "" },
      ],
      customnameerror: false,

      contactPayload: [],
      selectedValue: "Overview",
      tabItems: [
        { name: "Overview", value: "Overview" },
        { name: "Hierarchy", value: "Hierarchy" },
        {
          name: "Contacts",
          value: "Contacts",
        },
        { name: "Interaction", value: "Interaction" },
        { name: "Rules", value: "Rules" },
        { name: "Quotes and orders", value: "Quotes and orders" },
        { name: "Approvals", value: "Approvals" },
      ],
      recievedContactData: [],
      showAddNewAdrressModal: false,
      newAddressPayload: {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      },
      swatchcomponetkey: 0,
      locationData: [],
      ProductTabledata: {},
      leftpannel: "",
      disableEdit: false,

      payload: {
        fields: {},
        category: "",
        name: "",
        status: "Draft",
        description: "",
      },

      editTree: false,
      isTreePanel: false,
      tree: {
        type: "",
        value: "",
        description: "",
      },
      preselectedColor: "",

      specOptions: [],
      attachmentData: [],
      productName: "",
      searchedData: [],
      searched: false,
      addFields: false,
      displayAlternate: [{ name: "Product offering price", display: "number" }],
      dashboardPreval: {},
      filterQuery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: "",
      },
      query: {
        Status: ["status"],
        Category: ["category"],
      },
      displayFilterData: {},
      categoryInfo: null,
      offerPayload: {
        status: "",
        bundledproductOffering: [{ name: "" }],
        marketSegment: [],
        channel: [],
        productSpecification: { name: "" },
        relatedParty: [
          { name: this.$cookies.get("userName"), role: "Product owner" },
        ],
        name: "",
        tags: [],
        category: [],
      },
      partyOptions: [],
      tagsOptions: [],
      marketOptions: [],
      channelOptions: [],
      specdata: [],
      offerType: "",
      show_add_offer: false,
      add_context_options: [
        {
          label: "MVNO",
          value: "MVNO",
          icon: "icon-mobile-regular",
        },
        {
          label: "ISP",
          value: "ISP",
          icon: "icon-wifi-regular",
        },
      ],
      containerleft1: 0,
      containertop1: 0,
      errorMessage: "",
      componentKey: 1,
      datalength: 0,
      add_model: false,
      show: false,
      currencylist: [],
      filterPanelName: "",
      recurringChargePeriodType: {},
      priceobj: {},
      company: this.$cookies.get("company"),
      showpricedata: {},
      showPriceValue: false,
      offeringfiltercondition: [],
      offeringprice: [],
      filteredarray: [],
      filteredobj: { $in: [] },
      productOfferingfilteredobj: { $in: [] },
      productOfferingfilter: [],

      offeringfilterquery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      filterData: [],
      componentkeyselect: 0,
      currentvalue: "ALL",
      sliderfilter: false,
      sliderMin: 0,
      sliderMax: 0,
      filterprice: {
        units: "ALL",
        min: 0,
        max: 0,
        key: "productOfferingPrice",
      },
      totalpageno: 0,
      containerleft: 0,
      containertop: 0,
      filtermin: 0,
      filtermax: 0,
      filterloop: {},
      slidedata: 0,
      min: 0,
      max: 0,
      searchfilterdata: {},
      originaldata: {},
      filterdata: {},
      duplicatefilterdata: {},
      filterdat: {},
      filtered_options: {},

      product_offerings_theads: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: true,
          width: null,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 1,
          disabled: true,
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: true,
          width: 200,
          align: "left",
        },
        {
          name: "Category",
          checked: true,
          id: 1,
          disabled: false,
          prop: "Category",
          label: "Category",
          sortable: false,
          resizable: true,
          width: 250,
          align: "left",
        },
        {
          name: "Created on",
          checked: true,
          id: 1,
          disabled: false,
          prop: "createdAt",
          label: "Created on",
          sortable: false,
          resizable: true,
          width: 300,
          align: "left",
        },
      ],
      product_offerings_theads_cc: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: true,
          width: null,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 1,
          disabled: true,
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: true,
          width: 200,
          align: "left",
        },
        {
          name: "Category",
          checked: true,
          id: 1,
          disabled: false,
          prop: "Category",
          label: "Category",
          sortable: false,
          resizable: true,
          width: 250,
          align: "left",
        },
        {
          name: "Created on",
          checked: true,
          id: 1,
          disabled: false,
          prop: "createdAt",
          label: "Created on",
          sortable: false,
          resizable: true,
          width: 300,
          align: "left",
        },
      ],

      modal_active6: false,
      dropdown_data: [
        { name: "AND", value: "AND" },
        { name: "Between", value: "Between" },
        { name: "OR", value: "OR" },
      ],
      show_right: false,
      token: this.$route.params.token,
      editMode: true,
      editPermission: [],
      viewPermission: [],
      unique: Math.floor(Math.random() * 1000 + 1),
      searchtabledata: [],
      // businessrulepagespinner: true,
      // businessrulepagetable: false,
      duplicatetabledata: [],
      config_nav_items: [],
      datum: {},
      success: false,
      success_msg: "",
      expressions: [],

      show_crud_menu: false,

      tableData: [],
      totalvalue: 0,
      re_arrange: false,
      OfferingcurrPage: 1,
      OfferingrowsPerPage: 10,
      offeringcurrPage: 1,
      offeringrowsPerPage: 10,
      currPagePS: 1,
      rowsPerPagePS: 10,
      filtercurrPage: 1,
      filterrowsPerPage: 10,
      // duplicateTableData: [],
      duplicateTheads: [],
      duplicateTheads1: [],
      searchvalue: "",
      filterobj: {},
      filtervalueobj: {},
      searchfiltervalue: "",
      filteredtabledata: [],
      fdata: [],
      parentelement: "",
      parentdata: {},
      parentarray: [],
      templatePayload: {},
      previewPayload: {
        name: "",
        status: "",
        "@type": "TroubleTicket",
        priority: "",
        troubleTicketSpecification: [],
        relatedParty: [
          {
            id: "",
            name: "",
            role: "",
            company: "",
          },
        ],
      },
      fieldsSort: [],
      editFieldIndex: 0,
      labelFieldsValue: [],
      filteredstatus: [],
      isDisabled: true,
    };
  },
  computed: {
    paginatedData() {
        return this.tableData;
    },
    totalRows() {
      return this.tableData?.length;
    },
  },
  watch: {
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList.filter(
          (item) =>
            item.name === "Active" ||
            item.name === "Inactive" ||
            item.name === "Draft"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },

  },
  async mounted() {
    // To get table header
    let sampleTheads = await MLTAxiosInstance.get(`/settings`);

    if (sampleTheads?.data?.entityRef?.["settingsexecution"]) {
      let offerTableHeadModal =
        sampleTheads.data.entityRef["settingsexecution"];
      let offerTableHeadOrg = sampleTheads.data.entityRef[
        "settingsexecution"
      ].filter((ele) => ele.checked);
      console.log(
        offerTableHeadOrg,
        "offerTableHeadModal",
        offerTableHeadModal
      );

      if (
        offerTableHeadModal?.length === this.product_offerings_theads_cc?.length
        // &&
        // offerTableHeadModal.every((item, index) => item.name ===  this.product_offerings_theads_cc[index].name)
      ) {
        this.product_offerings_theads_cc = JSON.parse(
          JSON.stringify(offerTableHeadModal)
        );
        this.product_offerings_theads = JSON.parse(
          JSON.stringify(offerTableHeadOrg)
        );
        this.tableHeaderOrgcc = JSON.parse(
          JSON.stringify(this.product_offerings_theads_cc)
        );
        this.tableHeaderOrg = JSON.parse(
          JSON.stringify(this.product_offerings_theads)
        );
      }
    }
    try {
      this.refreshTable(); // Refresh the table immediately

      // Fetch assignee data
      const { data: asignee } = await MLTAxiosInstance.get(
        "partymanagement/individual/auth"
      );
      this.user = asignee;
      this.companyasignee = asignee.company;

      await this.$store.dispatch("executionFilterData");
      this.filter = this.$store.getters.executionFilterData;
      const ticketSpec = await this.getReferanceData(
        "agreementtaskcategoryRef"
      );

      this.ticketSpec = ticketSpec;

      this.categoryType = this.filterCategoryType(
        this.ticketSpec,
        this.tableData
      );

      this.ticketdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );

      this.statusList = await this.getReferanceData("statuslist");

      // Fetch contact payload and filter customers
      const { data: contactPayload } = await MLTAxiosInstance.get(
        `/partymanagement/individual/listuser?company=${this.$cookies.get(
          "company"
        )}`
      );
      this.contactPayload = contactPayload.filter(
        (item) => item["@baseType"] === "customer"
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  methods: {
    resizedWidth(val) {
      console.log(val, "resizedWidth", val);

      const updateWidth = (array) => {
        let item = array.find((i) => i?.prop === val?.prop);
        if (item) item.width = val.width;
      };

      updateWidth(this.product_offerings_theads);
      updateWidth(this.product_offerings_theads_cc);
      console.log(
        this.product_offerings_theads,
        "resizedWidth loop",
        this.product_offerings_theads_cc
      );
      this.applyColumns(this.product_offerings_theads_cc);
    },
    reAssignOrgTableHeader() {
      // If there is change in the table header then ressign to the Orginal data
      if (
        this.product_offerings_theads_cc != this.tableHeaderOrgcc &&
        this.tableHeaderOrgcc?.length > 0
      ) {
        this.product_offerings_theads_cc = JSON.parse(
          JSON.stringify(this.tableHeaderOrgcc)
        );
      }
      if (
        this.product_offerings_theads != this.tableHeaderOrg &&
        this.tableHeaderOrg?.length > 0
      ) {
        this.product_offerings_theads = JSON.parse(
          JSON.stringify(this.tableHeaderOrg)
        );
      }
      this.re_arrange = true;
    },
    async applyColumns(val) {
      // To get rearranged header
      console.log("applyColumns", val);
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      let concatedEntity = sampleTheads?.data?.entityRef || {};

      concatedEntity.settingsexecution = val;

      let reArrangeTableHeadPayload = { entityRef: concatedEntity };

      const changeTableHeader = await MLTAxiosInstance.patch(
        `/settings`,
        reArrangeTableHeadPayload
      );
      console.log(
        changeTableHeader,
        "reArrangeTableHeadPayload",
        reArrangeTableHeadPayload
      );

      let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
      console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

      let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
      this.product_offerings_theads_cc = entityRef["settingsexecution"];
      this.product_offerings_theads = this.product_offerings_theads_cc.filter(
        (ele) => ele.checked
      );
      this.tableHeaderOrgcc = JSON.parse(
        JSON.stringify(this.product_offerings_theads_cc)
      );
      this.tableHeaderOrg = JSON.parse(
        JSON.stringify(this.product_offerings_theads)
      );
    },

    // Function to filter the category type based on selected categories
    filterCategoryType(ticketSpec, tableData) {
      const selectedCategories = tableData
        .filter((item) => item.status === "Active")
        .map((item) => item.category || "");

      const categoryType = ticketSpec.filter(
        (option) => !selectedCategories.includes(option.value)
      );

      return categoryType;
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },

    async categoryData(val) {
      this.payload.category = val.value;
    },

    async ticketfilterData(filterSelectedvalue, filterQuery) {
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue?.length;
      console.log(this.filtertaglength, "this.filtertaglength");

      filterQuery.company = this.companyasignee;
      //  filterQuery['filterQuery']['"@baseType"'] = 'supplier'
      if (this.filtertagvalue?.length != 0) {
        this.templateFilterQuery = filterQuery;
        this.templateFilterQuery.type = "filter";
        if (this.searchvalue != "") {
          this.templateFilterQuery["searchQuery"] = this.searchvalue;
          this.templateFilterQuery["type"] = "filter-search";
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.templateFilterQuery
        );

        this.tableData = filterResultData.data.data;
        this.totalvalue = filterResultData.data.total;
      } else {
        this.currPage = 1;
        this.rowsPerPage = 10;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      }
    },
    async ticketdataCalling(currentPage, rowsPerPage) {
      const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
      this.companyasignee = val.data.company;
      this.templateFilterQuery["company"] = val.data.company;
      this.templateFilterQuery["paginatedQuery"]["currentPage"] = currentPage;
      this.templateFilterQuery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.templateFilterQuery
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalvalue = filterResultData.data.total;
        this.paginatedData;
      }
    },
    sorting_Data(data, data1) {
      //
      console.log(data, data1);
      this.Supplierfilterquery.type = "filter";
      if (data === "icon-angle-up-small-filled sorting-icon") {
        this.Supplierfilterquery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = 1;
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        this.Supplierfilterquery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = -1;
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-up-small-filled") {
        this.Supplierfilterquery.isNeedToBeSort = false;
        let obj = {};
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled") {
        this.Supplierfilterquery.isNeedToBeSort = false;
        let obj = {};
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      }
    },
    async searchKey(val) {
      this.searchvalue = val;
      this.templateFilterQuery["company"] = this.companyasignee;
      this.templateFilterQuery["type"] = "search";
      this.templateFilterQuery["searchQuery"] = this.searchvalue;
      this.currPage = 1;
      this.rowsPerPage = 10;
      if (this.filtertagvalue?.length != 0) {
        this.templateFilterQuery["type"] = "filter-search";
      }

      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },
    async changeRowsPerPage(count) {
      //
      if (this.companyasignee != "") {
        this.rowsPerPage = count;
        this.ticketdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
        this.refreshTable();
      }
    },
    async currentPage(val) {
      if (this.companyasignee != "") {
        this.currPage = val;
        this.ticketdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
        this.refreshTable();
      }
      this.table_height = this.paginatedData?.length * 40 + 43;
    },
    emitStatus(val) {
      this.payload.status = val.name;
      console.log(this.payload, "this.payload");
    },

    async addNewTicket() {
      const response = await UPCAxiosInstance.post(`/execution`, this.payload);

      if (response) {
        this.notification = {
          type: "success",
          message: `Nice work! Your new ticket is now in the list!`,
        };
      }
      console.log(response, "vresponse");
      this.cancelModel();
      await this.refreshTable();
      this.categoryType = await this.filterCategoryType(
        this.ticketSpec,
        this.tableData
      );
      // this.add_model = false;
      // this.addFields = true;
    },
    cancelModel() {
      this.add_model = false;
      this.payload = {
        fields: {},
        category: "",
        name: "",
        status: "Draft",
        description: "",
      };
    },
    async refreshTable() {
      const response = await UPCAxiosInstance.post(
        `util/filterData`,
        this.templateFilterQuery
      );
      // const response = await UPCAxiosInstance.get(`/templateTicket`);

      this.tableData = response.data.data;
      this.totalvalue = response.data.total;
    },

    addTicket() {
      this.add_model = true;
      this.ticketComponetD += 1;
      this.CategoryCompentKey += 1;
      this.ticketComponetI += 1;
      this.payload = {
        fields: {},
        category: "",
        name: "",
        status: "Draft",
        description: "",
      };
    },

    computedTableAction(data) {
      let options = [
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        // {
        //   label: "Clone",
        //   icon: "icon-copy-alt-regular",
        // },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
    async closeCrudContextMenu($event, ele, i) {
      // //

      if ($event?.toLowerCase() === "edit") {
        this.viewPage(ele);
      } else if ($event?.toLowerCase() === "clone") {
        delete ele._id;
        const clonedata = await UPCAxiosInstance.post(
          `/catalogManagement/productOffering/clone`,
          ele
        );
        this.refreshTable();
        console.log(clonedata);
        if (clonedata.status == 201) {
          this.notification = {
            type: "success",
            message: `Nice work! Your product offering ${ele.name} is cloned!`,
          };
        }
      } else if ($event?.toLowerCase() == "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      } else if (
        $event &&
        ["Mark as In-active", "Mark as active"].includes($event)
      ) {
        let updatePayload = {
          ...i,
          status: $event == "Mark as In-active" ? "inactive" : "active",
        };

        await DMNAxiosInstance.patch(
          `rule_engine/rule/${i["_id"]}`,
          updatePayload
        );
        // this.refreshTable();
      }
    },
    viewPage(i) {
    
      this.$router.push({
        path: `/settings/execution-template-edit`,
        query: { id: `${i._id}`, name: `${i.name}` },
      });

    },

    async deleteExecRow() {
      this.delete_modal_active = false;
      let i = this.deleteobj;
      const deleteOffering = await UPCAxiosInstance.delete(
        `execution/${i._id}`
      );
      deleteOffering;
      this.offeringcurrPage = 1;

      this.notification = {
        type: "success",
        message: `Done! ${i.name} has been removed.`,
      };
      // }
      // this.activatemodalactive(i);
      i.context = false;
      await this.refreshTable();
      this.categoryType = await this.filterCategoryType(
        this.ticketSpec,
        this.tableData
      );

      // this.refreshTable();
    },

    closeticket() {
      this.add_model = false;
      this.ticketComponetD += 1;
      this.ticketComponetI += 1;
      this.CategoryCompentKey += 1;
      this.payload = {
        fields: {},
        name: "",
        status: "Draft",
        description: "",
      };
    },
    crudContextMenu($event, index) {
      // ;
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;

        //
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },

    serialNumber(index) {
      return (this.offeringcurrPage - 1) * this.offeringrowsPerPage + index + 1;
    },
  },
};
</script>
<style lang="scss">
.example {
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
