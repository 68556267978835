import axios from "axios";
import VueCookies from "vue-cookies";
console.log("token",VueCookies.get("token"))
let headers = {}
if(VueCookies.get("token") != null){
headers = {
  Authorization: "Bearer " + VueCookies.get("token"),
}
}else{
  headers = {
    'hlx-api-key': process.env.VUE_APP_API_KEY
  }
}
const UPCAxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_UPC_BASE,
  // baseURL: 'http://localhost:5700',
  headers
});
// console.log("🚀 ~ VueCookies.get(token):", VueCookies.get("token"));
// const UPCAxiosInstance = axios.create({ baseURL: 'http://localhost:5900' })

const DMNAxiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_HOST_PROTOCOL1}://${process.env.VUE_APP_HOST1}/`,
  headers
});
// DMNAxiosInstance.defaults.params = {
//   company: process.env.VUE_APP_COMPANY
// }
const MLTAxiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_HOST_PROTOCOL}://${process.env.VUE_APP_HOST}/`,
  headers
});

const BPMNAxiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_HOST_PROTOCOL}://${process.env.VUE_APP_BPMN_BASE_URL}/`,
  headers
});
// MLTAxiosInstance.defaults.params = {
// company: process.env.VUE_APP_COMPANY
// company: VueCookies.get('company')
// }
// axios.defaults.headers.common['Authorization'] = ;
//
//   window.location.pathname.split("dashboard/"),
//   VueCookies.get("token"),
//   "locccc"
// );
export {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
  BPMNAxiosInstance
};
