<template>
    <div class="example-component">
      <h1>{{ title }}</h1>
      <p>{{ description }}</p>
  
      <input v-model="inputValue" placeholder="Type something..." />
  
      <button @click="handleClick">Click Me</button>
      <p v-if="message">{{ message }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "ExampleComponent",
    props: {
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        default: "This is a Vue.js component example.",
      },
    },
    data() {
      return {
        inputValue: "",
        message: "",
      };
    },
    computed: {
      // Add computed properties here if needed
    },
    watch: {
      // Add watchers here if needed
    },
    mounted() {
      console.log("ExampleComponent mounted.");
    },
    methods: {
      handleClick() {
        this.message = `You typed: ${this.inputValue}`;
      },
    },
  };
  </script>
  
  <style scoped>
  .example-component {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  button {
    background-color: #42b983;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  button:hover {
    background-color: #3a9c73;
  }
  </style>
  