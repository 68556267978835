<template>
   <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
 
   <div v-if="tableData?.length >= 1" class="header-add-supplier">
      <div class="tablehead-add-supplier" >{{ tablehead }}</div>
      <div class="search-add-supplier">
          <span><hlx-search v-model="searchvalue" @search-key="searchKey"></hlx-search></span>
       
         
      </div>
      
   </div>
  <div>
    
   <div
            v-if="tableData.length > 0"
            class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
          >
            <hlx-table
              :column-count="accounts_table_theads.length + 2"
              :border="['table', 'header', 'horizontal']"
              :row-hover="true"
              theme="grey"
              :re-arrange-columns="re_arrange"
              :re-arrange-heads="accounts_table_theads_cc"
              @close-rearrange="re_arrange =false"
              @apply-rearranged-columns="applyColumns"
            >
              <template #thead>
                <hlx-table-head
                  :width="60"
                  :align="'center'"
                  style="padding: 0 15px"
                  :fixed="'left'"
                     @new-resize-width="resizedWidth"
                  >{{ "S.no" }}</hlx-table-head
                >
                <hlx-table-head
                  v-for="(i, index) in accounts_table_theads"
                  :key="index"
                  :prop="i.prop"
                  :sortable="i.sortable"
                  :resizable="i.resizable"
                  :width="i.width !== undefined ? i.width : ''"
                  :align="i.align !== undefined ? i.align : ''"
                  @sorting_func="sorting_Data"
                     @new-resize-width="resizedWidth"
                >

                <span v-if="i.label.toLowerCase().includes('amount')">
                  {{ i.label }} ({{
                $store?.getters?.getDefaultSettings?.currency?.unit
              }})
                </span>

                <span v-else >{{ i.label }}</span>

                  </hlx-table-head
                >
                <hlx-table-head
                  v-show="editMode == true"
                  :align="'center'"
                  :width="70"
                  :fixed="'right'"
                     @new-resize-width="resizedWidth"
                  ><span class="action-icon">
                    <i
                      class="icon-settings-regular"
                      style="cursor: pointer"
                      @click="reAssignOrgTableHeader()"
                    ></i> </span
                ></hlx-table-head>
              </template>
              <template #tbody>
                <tr v-for="(i, index) in paginatedData" id="" :key="index">
                  <hlx-table-cell :align="'center'" :fixed="'left'">
                    {{ serialNumber(index) }}
                  </hlx-table-cell>
                  <hlx-table-cell
                    v-for="(j, col_index) in accounts_table_theads"
                    :key="col_index"
                    :align="j.align"
                    :fixed="j.fixed"
                  >
                 
                    <div v-if="j.prop.toLowerCase() === 'id'">
                      {{ i[j.prop] }}
                    </div>
                
                    <div v-if="j.prop.toLowerCase() === 'ordernumber'">
                      {{ i[j.prop] }}
                    </div>
                    <div
                      v-else-if="j.prop.toLowerCase() === 'orderdate'"
                      style="margin: 0.5rem 0"
                    >
                      {{ i[j.prop] }}
                    </div>
                    <div
                      v-else-if="j.prop.toLowerCase() === 'customername'"
                      style="margin: 0.5rem 0"
                    >
                      {{ i[j.prop] }}
                    </div>
                    <div v-else-if="j.prop.toLowerCase() === 'status'">
                      <div>
                <hlx-status :type="i[j.prop]"></hlx-status>
              </div>
                    </div>
                   
                    
                    <div
                      v-else-if="j.prop.toLowerCase() === 'amount'"
                      style="margin: 0.5rem 0"
                    >

                    <span
                   
                  >
                    {{
                      currencyFormatter(i[j.prop], {
                        currency:
                          $store?.getters?.getDefaultSettings?.currency?.unit,
                      })
                    }}
                  </span>

                        
                    </div>
                   
                    <!-- <div v-else>{{ i[j.prop] }}</div> -->
                  </hlx-table-cell>
                  <hlx-table-cell
                    v-show="editMode === true"
                    :align="'center'"
                    :width="120"
                    :fixed="'right'"
                  >
                    <span :id="'table-context' + index" class="action-icon">
                      <i
                        style="position: relative"
                        class="icon-more-vertical-filled"
                        :class="i.context === true ? 'active-action' : ''"
                        @click="crudContextMenu($event, index)"
                      >
                        <hlx-context-menu
                          :top="containertop"
                          :left="containerleft"
                          :conditionvalue="150"
                          :conditiondown="2"
                          :conditionup="-38"
                          :options="computedTableAction(i)"
                          :data="index.toString()"
                          :show="i.context == true"
                          @chosen="closeCrudContextMenu($event, i, index)"
                        />
                      </i>
                    </span>
                  </hlx-table-cell>
                </tr>
              </template>
            </hlx-table>
          </div>
          <div
    v-if="tableData?.length <= 0"
    style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 70vh;
      "
  >
    <img src="@/assets/images/supplierPurchase.svg" alt="" />

  </div>
        </div>
        <!-- <div class="cm-accounts-right-panel-pagination-container">
          <hlx-pagination
            v-model:rows-per-page="externalRowsPerPage"
            :total="3"
            rows-per-page
            enable-rows-per-page
            :rows-per-page-list="[10, 15, 20]"
            :options="{
              attributes: {
                rowsPerPageTitle: true,
                rowsPerPageResult: true,
                rowsPerPageDropdown: {
                  position: {
                    top: 0,
                    right: 1,
                  },
                },
              },
            }"
            @current-page="currentPage"
            @updated:rows-per-page="changeRowsPerPage"
          ></hlx-pagination>
        </div> -->
      
  </template>
  <script>
import {MLTAxiosInstance} from "@/config/axiosConfig"
  export default {
    props: {
      permisstionData: {
      type: Object,
      required: true, 
      default: () => {},
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: 'edit',
    },
  },
    data() {
      return {
        tableHeaderOrg:[],
        tableHeaderOrgcc:[],
          editMode: true,
        username: "GlobalTech Supplies",
        tagName: "Active",
        addressName: "123 Main Street Suite 456 Toronto, ON M5V 2B8, Canada",
        tablehead:"Purchase Order",
        externalRowsPerPage: 10,
       tableData: [
           {
            id: "732158",
             ordernumber: "09637 - 63839",
            status: "Active",
            amount: "35,000.00",
            orderdate: "Jan 10, 2024",
            customername:"Emily Thompson",
           
          },
          {
            id: "732158",
            ordernumber: "09637 - 63839",
            status: "Active",
            amount: "35,000.00",
            orderdate: "Jan 10, 2024",
            customername:"Emily Thompson",
           
          },
          {
            id: "732158",
            ordernumber: "09637 - 63839",
            status: "Active",
            amount: "35,000.00",
            orderdate: "Jan 10, 2024",
            customername:"Emily Thompson",
           
          },
        ],
        statusList: [
          { name: "Active", value: "Active" },
          { name: "Inactive", value: "Inactive" },
        ],
        Shiping: [
          { name: "Active", value: "Active" },
          { name: "Inactive", value: "Inactive" },
        ],
       
        accounts_table_theads: [
          {
            sortable: false,
            resizable: true,
            width: 100,
            label: "ID",
            prop: "id",
            align: "left",
            name:"ID",
            disabled:true,
            checked:true,
          },
          {
            sortable: false,
            resizable: true,
            width: 150,
            label: "Order number",
            prop: "ordernumber",
            align: "left",
            name: "Order number",
            disabled:true,
            checked:true,
          },
          {
            sortable: false,
            resizable: true,
            width: 100,
            label: "Order date",
            prop: "orderdate",
            align: "left",
            name:"Order date",
            disabled:true,
            checked:true,
          },
          {
            sortable: false,
            resizable: true,
            width: null,
            label: "Account name",
            prop: "customername",
            align: "left",
            name:"Account name",
            disabled:false,
            checked:true,
          },
          {
            sortable: false,
            resizable: true,
            width: 100,
            label: "Status",
            prop: "status",
            align: "left",
            name:"Status",
            disabled:false,
            checked:true,
          },
          {
            sortable: false,
            resizable: true,
            width: 150,
            label: "Amount",
            prop: "amount",
            align: "left",
            name: "Amount",
            disabled:false,
            checked:true,
          },    
        ], 
        accounts_table_theads_cc: [
          {
            sortable: false,
            resizable: true,
            width: 100,
            label: "ID",
            prop: "id",
            align: "left",
            name:"ID",
            disabled:true,
            checked:true,
          },
          {
            sortable: false,
            resizable: true,
            width: 150,
            label: "Order number",
            prop: "ordernumber",
            align: "left",
            name: "Order number",
            disabled:true,
            checked:true,
          },
          {
            sortable: false,
            resizable: true,
            width: 100,
            label: "Order date",
            prop: "orderdate",
            align: "left",
            name:"Order date",
            disabled:true,
            checked:true,
          },
          {
            sortable: false,
            resizable: true,
            width: null,
            label: "Account name",
            prop: "customername",
            align: "left",
            name:"Account name",
            disabled:false,
            checked:true,
          },
          {
            sortable: false,
            resizable: true,
            width: 100,
            label: "Status",
            prop: "status",
            align: "left",
            name:"Status",
            disabled:false,
            checked:true,
          },
          {
            sortable: false,
            resizable: true,
            width: 150,
            label: "Amount",
            prop: "amount",
            align: "left",
            name: "Amount",
            disabled:false,
            checked:true,
          },  
        ],
        name: "",
        duplicateTheads: [],
        re_arrange: false,
        currPage: 1,
        rowsPerPage: 10,
        
        filterPanelName: "productSpecification",
        modal_active: false,
        delete_modal_active:false,
      };
    },
    computed: {
      paginatedData() {
        return this.tableData;
      },
    },
    watch: {
    data: {
      handler(val) {
       this.payload=val;
       console.log(val,"product value");
      },
      immediate: true,
      deep: true,
    },
  },
    async mounted() {
       // To get table header   
  let sampleTheads = await MLTAxiosInstance.get(`/settings`);

if (sampleTheads?.data?.entityRef?.["supplierPurchaseOrderTable"]) {
  let offerTableHeadModal = sampleTheads.data.entityRef["supplierPurchaseOrderTable"];
  let offerTableHeadOrg = sampleTheads.data.entityRef["supplierPurchaseOrderTable"].filter(ele => ele.checked);
  console.log(offerTableHeadOrg,"offerTableHeadModal",offerTableHeadModal)

  if (
    offerTableHeadModal.length ===  this.accounts_table_theads_cc.length 
    // &&
    // offerTableHeadModal.every((item, index) => item.name ===  this.accounts_table_theads_cc[index].name)
  ) {
    this.accounts_table_theads_cc = JSON.parse(JSON.stringify(offerTableHeadModal));
      this.accounts_table_theads = JSON.parse(JSON.stringify(offerTableHeadOrg));
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
      this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
  }
}
    },
    methods: {
      resizedWidth(val){
      // To get resized width
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.accounts_table_theads);
  updateWidth(this.accounts_table_theads_cc);
console.log(this.accounts_table_theads,"resizedWidth loop",this.accounts_table_theads_cc)
this.applyColumns(this.accounts_table_theads_cc)
    },
    reAssignOrgTableHeader(){
           // If there is change in the table header then ressign to the Orginal data
           if((this.accounts_table_theads_cc != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.accounts_table_theads_cc = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.accounts_table_theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.accounts_table_theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.re_arrange = true
    },
      addCancel() {
        this.modal_active = false;
      },
      addNewAccount() {
        //
        this.modal_active = true;
      },
      crudContextMenu($event, index) {
        if ($event.target.className === "icon-more-vertical-filled") {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 93;
          this.containertop = document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().top;
          this.tableData?.forEach((e) => {
            if (e.context !== undefined) {
              e.context = false;
            }
          });
  
          this.tableData[index].context = true;
        } else {
          this.tableData?.forEach((e) => {
            if (e.context !== undefined) {
              e.context = false;
            }
          });
        }
      },
      async closeCrudContextMenu($event, ele, i) {
        if ($event.toLowerCase() === "edit") {
          this.$router.push(`/SM/supplier/edit`);
        } else if ($event.toLowerCase() == "delete") {
          this.delete_modal_active = true;
          this.deleteobj = ele;
          this.delete_name = ele.name;
          this.deleterowindex = i;
        }
      },
     
      computedTableAction(data) {
        let options = [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
          
        ];
        return options.filter((option) => {
          if (
            data &&
            data.status &&
            data.status.toLowerCase() == "draft" &&
            ["Draft", "Mark as In-active"].includes(option.label)
          ) {
            return false;
          } else if (
            data &&
            data.status &&
            data.status.toLowerCase() == "active" &&
            ["Draft", "Mark as active"].includes(option.label)
          ) {
            return false;
          } else if (
            data &&
            data.status &&
            data.status.toLowerCase() == "inactive" &&
            ["Draft", "Mark as In-active"].includes(option.label)
          ) {
            return false;
          } else {
            return true;
          }
        });
      },
      serialNumber(index) {
        return (this.currPage - 1) * this.rowsPerPage + index + 1;
      },
      arrange(val) {
        this.re_arrange = val;
        this.updateTheads();
      },
      arrangeColumns(val) {
        this.re_arrange = val;
        this.updateTheads();
      },
      updateTheads() {
        this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
          (thead) => {
            // Check if the current thead has a matching 'prop' in duplicateTheads
            const isMatching = this.accounts_table_theads.some(
              (duplicateThead) => duplicateThead.prop === thead.prop
            );
  
            // Set the 'checked' property based on whether it's matching
            return { ...thead, checked: isMatching };
          }
        );
      },
     async applyColumns(val) {
      // To get rearranged header
      console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef || {};

concatedEntity.supplierPurchaseOrderTable = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.accounts_table_theads_cc = entityRef["supplierPurchaseOrderTable"];
this.accounts_table_theads = this.accounts_table_theads_cc.filter(ele => ele.checked);
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .header-add-supplier{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
  .tablehead-add-supplier{
      font-weight: bold;
      
  }
   .search-add-supplier{
      display: flex;
      gap: 10px;
    }
  }
  .contacts-username-supplier {
  display: flex;
  align-items: center;
  .right-head-supplier{
      display: flex;
      flex-direction: column;
  }
  .head-supplier-left {
      display: flex;
      justify-content: center;
      width: 44px;
      align-items: center;
      font-size: 45px;
      margin-right: 10px;
    }
    .head-supplier-right {
      display: flex;
      font-size: 14px;
      font-weight: bold;
      .head-supplier-name{
          margin-right: 10px;
      }
    }
    .head-supplier-address {
      font-size: 10px;
      color: #929191;
      margin-top: 3px;
    }
   
  }
  </style>
  