<template>
  <hlx-alert-notification :notify="notification" auto-close />
  <div v-show="impersonite == true"><banner @exit="exitView()" /></div>
  <div class="dmn-dashbord">
    <section class="right-view">
      <div class="dmn-header">
        <div class="first-zone">
          <!-- <h3 class="content-one">Hey User!</h3> -->
          <h3 class="content-one">Welcome {{ name }} !</h3>
          <p class="content-two">
            Get real-time monitoring and performance insights of the business
            rules to optimize decision-making capabilities.
          </p>
          <!-- <p class="content-two">Take a look at your dashboard!</p> -->
          <br />
          <div class="zone-container">
            <div class="one" @click="goTo('Decision tables')">
              <img src="../assets/images/table.svg" width="53px" />
              <div class="one-1">
                <h3 class="count">{{ decisionTableCount }}</h3>
                <p class="sub-header"><b>Decision tables</b></p>
              </div>
            </div>

            <div class="two" @click="goTo('Expressions')">
              <div class="divider">
                <img src="../assets/images/code.svg" width="53px" />
                <div class="one-1">
                  <h3 class="count">{{ expressionCount }}</h3>
                  <p class="sub-header"><b>Expressions</b></p>
                </div>
              </div>
            </div>
            <div class="three" @click="goTo('Logical rules')">
              <img src="../assets/images/imp.svg" width="53px" />
              <div class="one-1">
                <h3 class="count">{{ rulesCount }}</h3>
                <p class="sub-header"><b>Logical rules</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dmn-chart">
        <div class="chart-section-one">
          <div class="chart-one">
            <div class="apex">
              <div style="display: flex; justify-content: space-between">
                <p class="apex-header"><b>Rule performance</b></p>
                <div style="width: 150px" class="dmn-hlx-custom-select-wrapper">
                  <hlx-select
                    v-model:value="rulePerformancePayload.filter"
                    :options="rangeDropdown"
                    :placeholder-value="'Range'"
                    :pre-value="'Last 12 months'"
                    :label-animation="true"
                    :small="true"
                    :clearable="false"
                    @custom-change="selectRulePerformance"
                  >
                  </hlx-select>
                </div>
              </div>
              <VueApexCharts
                v-if="rulePerformancePayload.filter === 'Last 12 months'"
                type="bar"
                height="170"
                :options="performanceChartOne.chartOptions"
                :series="performanceChartOne.series"
              >
              </VueApexCharts>
              <VueApexCharts
                v-if="rulePerformancePayload.filter === 'Last 10 days'"
                type="bar"
                height="170"
                :options="performanceChartTwo.chartOptions"
                :series="performanceChartTwo.series"
              >
              </VueApexCharts>
              <VueApexCharts
                v-if="rulePerformancePayload.filter === 'Last 12 hours'"
                type="bar"
                height="170"
                :options="performanceChartThree.chartOptions"
                :series="performanceChartThree.series"
              >
              </VueApexCharts>
              <!-- <VueApexCharts
              v-if="this.rulePerformancePayload.filter === 'Last 12 months'"
                :type="this.barchart.chart.type"
                height="100%"
                width="100%"
                :options="this.barchart"
                :series="this.barchart.series"
              ></VueApexCharts> -->
              <!-- <VueApexCharts
              v-if="this.rulePerformancePayload.filter === 'Last 10 days'"
                :type="this.barchart2.chart.type"
                height="100%"
                width="100%"
                :options="this.barchart2"
                :series="this.barchart2.series"
              ></VueApexCharts>
              <VueApexCharts
              v-if="this.rulePerformancePayload.filter === 'Last 12 hours'"
                :type="this.barchart3.chart.type"
                height="100%"
                width="100%"
                :options="this.barchart3"
                :series="this.barchart3.series"
              ></VueApexCharts> -->
            </div>
          </div>
          <div class="divide-two">
            <div class="pie">
              <div class="pie-chart">
                <p class="apex-header" style="text-align: center">
                  <b>Execution insights</b>
                </p>
                <div class="chart-holder">
                  <VueApexCharts
                    :type="piechart.chart.type"
                    height="100%"
                    width="100%"
                    :options="piechart"
                    :series="piechart.series"
                  ></VueApexCharts>
                </div>
              </div>
            </div>
            <div class="card">
              <p class="card-header" style="text-align: center">
                <b>Average duration</b>
              </p>
              <p class="header1 center">
                <b>{{ averageExecDuration }} ms</b>
              </p>
              <p class="base-header center">Minimum</p>
              <p class="header2 center">
                <b>{{ minimumExecDuration }} ms</b>
              </p>
              <p class="base-header center">Maximum</p>
              <p class="header2 center">
                <b>{{ maximumExecDuration }} ms</b>
              </p>
            </div>
          </div>
        </div>
        <div class="chart-section-two">
          <div class="line-graph">
            <div class="apex">
              <p class="apex-header"><b>Execution count</b></p>
              <VueApexCharts
                v-if="rulePerformancePayload.filter === 'Last 12 months'"
                type="area"
                height="170"
                :options="lineChartOne.chartOptions"
                :series="lineChartOne.series"
              >
              </VueApexCharts>
              <VueApexCharts
                v-if="rulePerformancePayload.filter === 'Last 10 days'"
                type="area"
                height="170"
                :options="lineChartTwo.chartOptions"
                :series="lineChartTwo.series"
              >
              </VueApexCharts>
              <VueApexCharts
                v-if="rulePerformancePayload.filter === 'Last 12 hours'"
                type="area"
                height="170"
                :options="lineChartThree.chartOptions"
                :series="lineChartThree.series"
              >
              </VueApexCharts>
              <!-- <VueApexCharts
                :type="this.linechart.chart.type"
                height="100%"
                width="100%"
                :options="this.linechart"
                :series="this.linechart.series"
              ></VueApexCharts> -->
            </div>
          </div>
          <div class="list-view">
            <div class="list-header">
              <span class="header-name"><b>Team member</b></span>
              <span class="count">{{ count }}</span>
              <span
                v-show="roles != 'Read only' && roles != 'Business user'"
                class="invite"
                ><i
                  class="icon-plus-circle-regular"
                  @click="modal_active = true"
                ></i>
                &nbsp;<b>Invite user</b></span
              >
            </div>
            <div class="list-body">
              <div v-for="element in list" :key="element" class="row">
                <div class="user-icon">
                  <span v-if="element.svg">
                    <img :src="getImgUrl(element.svg)" />
                  </span>
                  <!-- <span>
                    <i class="icon-address-book-filled"></i>
                  </span> -->
                </div>
                <div class="user-info">
                  <p class="name">{{ element.name }}</p>
                  <p class="mail" style="color: var(--hlx-color-disable-text)">
                    {{ element.email }}
                  </p>
                </div>
                <div v-if="element.role" class="permission">
                  <hlx-label class="primary sm role">{{
                    element.role
                  }}</hlx-label>
                </div>
                <!-- <i class="icon-vertical-dots settings"></i> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dmn-chart_tablet">
        <div class="chart-one">
          <div class="apex">
            <div style="display: flex; justify-content: space-between">
              <p class="apex-header"><b>Rule performance</b></p>
              <div style="width: 150px" class="dmn-hlx-custom-select-wrapper">
                <hlx-select
                  v-model:value="rulePerformancePayload.filter"
                  :options="rangeDropdown"
                  :placeholder-value="'Range'"
                  :pre-value="'Last 12 months'"
                  :label-animation="true"
                  :small="true"
                  :clearable="false"
                  @custom-change="selectRulePerformance"
                >
                </hlx-select>
              </div>
            </div>
            <VueApexCharts
              v-if="rulePerformancePayload.filter === 'Last 12 months'"
              type="bar"
              height="170"
              :options="performanceChartOne.chartOptions"
              :series="performanceChartOne.series"
            >
            </VueApexCharts>
            <VueApexCharts
              v-if="rulePerformancePayload.filter === 'Last 10 days'"
              type="bar"
              height="170"
              :options="performanceChartTwo.chartOptions"
              :series="performanceChartTwo.series"
            >
            </VueApexCharts>
            <VueApexCharts
              v-if="rulePerformancePayload.filter === 'Last 12 hours'"
              type="bar"
              height="170"
              :options="performanceChartThree.chartOptions"
              :series="performanceChartThree.series"
            >
            </VueApexCharts>
            <!-- <VueApexCharts
              v-if="this.rulePerformancePayload.filter === 'Last 12 months'"
                :type="this.barchart.chart.type"
                height="100%"
                width="100%"
                :options="this.barchart"
                :series="this.barchart.series"
              ></VueApexCharts> -->
            <!-- <VueApexCharts
              v-if="this.rulePerformancePayload.filter === 'Last 10 days'"
                :type="this.barchart2.chart.type"
                height="100%"
                width="100%"
                :options="this.barchart2"
                :series="this.barchart2.series"
              ></VueApexCharts>
              <VueApexCharts
              v-if="this.rulePerformancePayload.filter === 'Last 12 hours'"
                :type="this.barchart3.chart.type"
                height="100%"
                width="100%"
                :options="this.barchart3"
                :series="this.barchart3.series"
              ></VueApexCharts> -->
          </div>
        </div>
        <div class="line-graph">
          <div class="apex">
            <p class="apex-header"><b>Execution count</b></p>
            <VueApexCharts
              v-if="rulePerformancePayload.filter === 'Last 12 months'"
              type="area"
              height="170"
              :options="lineChartOne.chartOptions"
              :series="lineChartOne.series"
            >
            </VueApexCharts>
            <VueApexCharts
              v-if="rulePerformancePayload.filter === 'Last 10 days'"
              type="area"
              height="170"
              :options="lineChartTwo.chartOptions"
              :series="lineChartTwo.series"
            >
            </VueApexCharts>
            <VueApexCharts
              v-if="rulePerformancePayload.filter === 'Last 12 hours'"
              type="area"
              height="170"
              :options="lineChartThree.chartOptions"
              :series="lineChartThree.series"
            >
            </VueApexCharts>
            <!-- <VueApexCharts
                :type="this.linechart.chart.type"
                height="100%"
                width="100%"
                :options="this.linechart"
                :series="this.linechart.series"
              ></VueApexCharts> -->
          </div>
        </div>
        <div class="chart-section-last">
          <div class="divide-two">
            <div class="pie">
              <div class="pie-chart">
                <p class="apex-header" style="text-align: center">
                  <b>Execution insights</b>
                </p>
                <div class="chart-holder">
                  <VueApexCharts
                    :type="piechart.chart.type"
                    height="100%"
                    width="100%"
                    :options="piechart"
                    :series="piechart.series"
                  ></VueApexCharts>
                </div>
              </div>
            </div>
            <div class="card">
              <p class="card-header" style="text-align: center">
                <b>Average duration</b>
              </p>
              <p class="header1 center">
                <b>{{ averageExecDuration }} ms</b>
              </p>
              <p class="base-header center">Minimum</p>
              <p class="header2 center">
                <b>{{ minimumExecDuration }} ms</b>
              </p>
              <p class="base-header center">Maximum</p>
              <p class="header2 center">
                <b>{{ maximumExecDuration }} ms</b>
              </p>
            </div>
          </div>
          <div class="list-view">
            <div class="list-header">
              <span class="header-name"><b>Team member</b></span>
              <span class="count">{{ count }}</span>
              <span
                v-show="roles != 'Read only' && roles != 'Business user'"
                class="invite"
                ><i
                  class="icon-plus-circle-regular"
                  @click="modal_active = true"
                ></i>
                &nbsp;<b>Invite user</b></span
              >
            </div>
            <div class="list-body">
              <div v-for="element in list" :key="element" class="row">
                <div class="user-icon">
                  <span v-if="element.svg">
                    <img :src="getImgUrl(element.svg)" />
                  </span>
                  <span>
                    <i class="icon-address-book-filled"></i>
                  </span>
                </div>
                <div class="user-info">
                  <p class="name">{{ element.name }}</p>
                  <p class="mail" style="color: var(--hlx-color-disable-text)">
                    {{ element.email }}
                  </p>
                </div>
                <div class="permission">
                  <hlx-label class="primary sm role">{{
                    element.role
                  }}</hlx-label>
                </div>
                <!-- <i class="icon-vertical-dots settings"></i> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="invite-user">
      <hlx-modal
        :modal-active="modal_active"
        :height="'370px'"
        :width="'600px'"
        :modal="true"
        @close="modal_active = false"
      >
        <template #header>
          <div>Invite user</div>
        </template>
        <template #content>
          <div class="modal-content1">
            <div class="textfield">
              <div class="form-input-wrapper">
                <p class="input-header">Work email</p>
                <div class="form-input">
                  <hlx-input
                    v-model:value="email"
                    :label-animation="false"
                    label-value="Email"
                    type="email"
                    :custom-error="errors"
                    :custom-error-message="message"
                    :display-error="error"
                  />
                </div>
              </div>
              <div class="form-input-wrapper secondary">
                <p class="input-header">Role</p>
                <div class="form-input">
                  <hlx-select
                    v-model:value="role"
                    :pre-value="role"
                    :options="dropdown_data"
                    :placeholder-value="'Role'"
                    :label-animation="false"
                    :clearable="true"
                    @custom-change="selectValue"
                  ></hlx-select>
                </div>
              </div>
              <span class="icon1"><i class="icon-add-bg"></i></span>
              <!-- <span class="icon2"><i class="icon-trash-regular"></i></span> -->
            </div>
          </div>
        </template>
        <template #footer>
          <div class="button-area">
            <hlx-button class="secondary sm" @click="modal_active = false"
              >Cancel</hlx-button
            >

            <hlx-button class="primary sm" @click="invite()">Send</hlx-button>
          </div>
        </template>
      </hlx-modal>
    </div>
  </div>
</template>

<script>
import { MLTAxiosInstance, DMNAxiosInstance } from "@/config/axiosConfig";

import banner from "../components/bannerComponent.vue";
import VueApexCharts from "vue3-apexcharts";
export default {
  name: "DashboardView",
  components: { VueApexCharts, banner },
  data() {
    return {
      message: "",
      error: false,
      errors: false,
      role: "Business user",
      count: "",
      lineChartOne: {
        series: [
          {
            name: "Rule",
            data: [],
          },
          {
            name: "Expression",
            data: [],
          },
          {
            name: "Decision",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            height: 150,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          legend: { show: false },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            tooltip: false,
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yaxis: {
            title: {
              text: "Count",
              style: {
                color: "rgb(0, 143, 251)",
              },
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
          grid: {
            borderColor: "#f7f7f7",
          },
        },
      },
      lineChartTwo: {
        series: [
          {
            name: "Rule",
            data: [],
          },
          {
            name: "Expression",
            data: [],
          },
          {
            name: "Decision",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            height: 150,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          legend: { show: false },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            tooltip: false,
            categories: [],
          },
          yaxis: {
            title: {
              text: "Execution count",
              style: {
                color: "rgb(0, 143, 251)",
              },
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
          grid: {
            borderColor: "#f7f7f7",
          },
        },
      },
      lineChartThree: {
        series: [
          {
            name: "Rule",
            data: [],
          },
          {
            name: "Expression",
            data: [],
          },
          {
            name: "Decision",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            height: 150,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          legend: { show: false },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            tooltip: false,
            categories: [],
          },
          yaxis: {
            title: {
              text: "Execution count",
              style: {
                color: "rgb(0, 143, 251)",
              },
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
          grid: {
            borderColor: "#f7f7f7",
          },
        },
      },
      performanceChartOne: {
        series: [
          {
            name: "Average duration (ms)",
            type: "column",
            data: [],
          },
          {
            name: "Execution count",
            type: "column",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "35%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: [],
            tooltip: false,
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "",
              },
              labels: {
                style: {
                  colors: "",
                },
              },
              title: {
                text: "Average duration (ms)",
                style: {
                  color: "rgb(0, 143, 251)",
                },
              },
              tooltip: {
                enabled: false,
              },
            },
            {
              seriesName: "Execution count",
              opposite: true,
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: true,
                color: "",
              },
              labels: {
                style: {
                  colors: "",
                },
              },
              title: {
                text: "Execution count",
                style: {
                  color: "#54bd95",
                },
              },
            },
          ],
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  show: false,
                },
              },
            },
          ],
          legend: { show: false },
          grid: {
            borderColor: "#f7f7f7",
          },
          fill: {
            opacity: 1,
          },
          tooltip: {},
        },
      },
      performanceChartTwo: {
        series: [
          {
            name: "Average duration (ms)",
            type: "column",
            data: [],
          },
          {
            name: "Execution count",
            type: "column",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "35%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: [],
            tooltip: false,
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "",
              },
              labels: {
                style: {
                  colors: "",
                },
              },
              title: {
                text: "Average duration (ms)",
                style: {
                  color: "rgb(0, 143, 251)",
                },
              },
              tooltip: {
                enabled: false,
              },
            },
            {
              seriesName: "Execution count",
              opposite: true,
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: true,
                color: "",
              },
              labels: {
                style: {
                  colors: "",
                },
              },
              title: {
                text: "Execution count",
                style: {
                  color: "#54bd95",
                },
              },
            },
          ],
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  show: false,
                },
              },
            },
          ],
          legend: { show: false },
          grid: {
            borderColor: "#f7f7f7",
          },
          fill: {
            opacity: 1,
          },
          tooltip: {},
        },
      },
      performanceChartThree: {
        series: [
          {
            name: "Average duration (ms)",
            type: "column",
            data: [],
          },
          {
            name: "Execution count",
            type: "column",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "35%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: [],
            tooltip: false,
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "",
              },
              labels: {
                style: {
                  colors: "",
                },
              },
              title: {
                text: "Average duration (ms)",
                style: {
                  color: "rgb(0, 143, 251)",
                },
              },
              tooltip: {
                enabled: false,
              },
            },
            {
              seriesName: "Execution count",
              opposite: true,
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: true,
                color: "",
              },
              labels: {
                style: {
                  colors: "",
                },
              },
              title: {
                text: "Execution count",
                style: {
                  color: "#54bd95",
                },
              },
            },
          ],
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  show: false,
                },
              },
            },
          ],
          legend: { show: false },
          grid: {
            borderColor: "#f7f7f7",
          },
          fill: {
            opacity: 1,
          },
          tooltip: {},
        },
      },
      rangeDropdown: [
        {
          name: "Last 12 months",
          value: "Last 12 months",
          checked: false,
          disabled: false,
        },
        {
          name: "Last 10 days",
          value: "Last 10 days",
          checked: false,
          disabled: false,
        },
        {
          name: "Last 12 hours",
          value: "Last 12 hours",
          checked: false,
          disabled: false,
        },
      ],
      rulePerformancePayload: {
        filter: "last 12 months",
        group: true,
      },
      fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
      token: this.$cookies.get("token"),
      impersonite: false,
      config_nav_items: [],
      selectvalue: "",
      email: "",
      nav_items: [],
      modal_active: false,
      dropdown_data: [
        { name: "Business admin", value: "Business admin" },
        { name: "Business user", value: "Business user" },
        { name: "Read only", value: "Read only" },
      ],
      list: [],
      piechart: {
        series: [100, 0],
        chart: {
          width: "100%",
          height: "40%",
          type: "donut",
          toolbar: {
            show: false,
          },
        },
        colors: ["#54bd95", "#f8a523"],
        labels: ["Rules passed", "Rules failed"],
        dataLabels: {
          enabled: false,
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 20,
                height: "auto",
              },
              legend: {
                show: true,
              },
            },
          },
        ],
        legend: {
          position: "bottom",
          offsetY: -0,
          height: 40,
          show: true,
          verticalAlign: "center",
        },
        content: {
          title: "Total sales",
          total: "$85K",
        },
      },
      linechart: {
        grid: {
          borderColor: "#f7f7f7",
          padding: {},
        },
        series: [
          {
            name: "Rule",
            data: [31, 40, 28, 51, 42, 109, 100, 78, 43, 60],
          },
          {
            name: "Expression",
            data: [60, 20, 58, 69, 42, 10, 10, 78, 43, 60],
          },
          {
            name: "Decision",
            data: [10, 30, 88, 59, 12, 80, 50, 78, 43, 60],
          },
        ],

        chart: {
          height: "70%",
          width: "100%",
          type: "area",
          toolbar: {
            show: false,
          },
        },
        colors: ["#54bd95", "rgb(0, 143, 251)", "#419e7b"],
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 0,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },

        xaxis: {
          lines: {
            show: false,
          },
          show: false,
          categories: [],
        },
        yaxis: {
          borderColor: "#ffffff",
          show: false,
        },
        // tooltip: {
        //   x: {
        //     format: 'dd/MM/yy HH:mm'
        //   },
        // },
      },

      barchart: {
        xaxis: {
          categories: [],
          tooltip: false,
        },
        series: [
          {
            name: "Average duration",
            type: "column",
            data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6],
          },
          {
            name: "Execution count",
            type: "column",
            data: [20, 29, 37, 36, 44, 45, 50, 58],
          },
        ],
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#54bd95",
            },
            labels: {
              style: {
                colors: "#54bd95",
              },
            },
            title: {
              text: "Average duration",
              style: {
                color: "#54bd95",
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          // {
          //   seriesName: 'Execution count',
          //   opposite: true,
          //   axisTicks: {
          //     show: false,
          //   },
          //   axisBorder: {
          //     show: true,
          //     color: 'rgb(0, 143, 251)'
          //   },
          //   labels: {
          //     style: {
          //       colors: 'rgb(0, 143, 251)',
          //     },
          //   },
          //   title: {
          //     text: "Execution count",
          //     style: {
          //       color: 'rgb(0, 143, 251)',
          //     }
          //   }
          // },
        ],
        grid: {
          borderColor: "#f7f7f7",
          // padding:{
          //   top:-20,

          // },
        },

        chart: {
          // type: "line",
          // height: "100%",
          // width: "100%",
          // stacked: true,
          // stackType: "10%",
          toolbar: {
            show: false,
          },
        },
        colors: ["#54bd95", "rgb(0, 143, 251)"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "35%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
        fill: {
          opacity: 1,
        },
        legend: { show: false },
      },
      barchart2: {
        xaxis: {
          categories: [],
          tooltip: false,
        },
        series: [
          {
            name: "Average duration",
            type: "column",
            data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6],
          },
          {
            name: "Execution count",
            type: "column",
            data: [20, 29, 37, 36, 44, 45, 50, 58],
          },
        ],
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#54bd95",
            },
            labels: {
              style: {
                colors: "#54bd95",
              },
            },
            title: {
              text: "Average duration",
              style: {
                color: "#54bd95",
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          {
            seriesName: "Execution count",
            opposite: true,
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: true,
              color: "rgb(0, 143, 251)",
            },
            labels: {
              style: {
                colors: "rgb(0, 143, 251)",
              },
            },
            title: {
              text: "Execution count",
              style: {
                color: "rgb(0, 143, 251)",
              },
            },
          },
        ],
        grid: {
          borderColor: "#f7f7f7",
          // padding:{
          //   top:-20,

          // },
        },

        chart: {
          // type: "line",
          // height: "100%",
          // width: "100%",
          // stacked: true,
          // stackType: "10%",
          toolbar: {
            show: false,
          },
        },
        colors: ["#54bd95", "rgb(0, 143, 251)"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "35%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
        fill: {
          opacity: 1,
        },
        legend: { show: false },
      },
      barchart3: {
        xaxis: {
          categories: [],
          tooltip: false,
        },
        series: [
          {
            name: "Average duration",
            type: "column",
            data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6],
          },
          {
            name: "Execution count",
            type: "column",
            data: [20, 29, 37, 36, 44, 45, 50, 58],
          },
        ],
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#54bd95",
            },
            labels: {
              style: {
                colors: "#54bd95",
              },
            },
            title: {
              text: "Average duration",
              style: {
                color: "#54bd95",
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          {
            seriesName: "Execution count",
            opposite: true,
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: true,
              color: "rgb(0, 143, 251)",
            },
            labels: {
              style: {
                colors: "rgb(0, 143, 251)",
              },
            },
            title: {
              text: "Execution count",
              style: {
                color: "rgb(0, 143, 251)",
              },
            },
          },
        ],
        grid: {
          borderColor: "#f7f7f7",
          // padding:{
          //   top:-20,

          // },
        },

        chart: {
          // type: "line",
          // height: "100%",
          // width: "100%",
          // stacked: true,
          // stackType: "10%",
          toolbar: {
            show: false,
          },
        },
        colors: ["#54bd95", "rgb(0, 143, 251)"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "35%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
        fill: {
          opacity: 1,
        },
        legend: { show: false },
      },
      company: "Halleyx",
      impersonitor: "",
      name: "",
      userId: "",
      dashboardAuth: {
        username: "testuser@test.com",
        password: " ",
      },
      dashboardRequestBody: {
        unit: "day",
        number: 2,
        groupBy: "year",
        range: {
          from: "2022-04-22T02:20:24.945Z",
          to: "2023-04-22T02:20:24.945Z",
        },
      },
      rulesCount: 0,
      expressionCount: 0,
      decisionTableCount: 0,
      averageExecDuration: 0,
      minimumExecDuration: 0,
      maximumExecDuration: 0,
      viewPermission: [],
      editPermission: [],
      notification: {},
      tokened: "",
      roles: "",
    };
  },

  async mounted() {
    localStorage.token = this.$route.params.token;
    // console.log(this.$store.state.userInfo,"from store")
    let individualRes = await MLTAxiosInstance.get(
      `partymanagement/individual/auth`
    );

    this.company = individualRes.data.company;
    this.userId = individualRes.data.company;
    let userRes = await MLTAxiosInstance.get(
      `partymanagement/individual/${individualRes.data.userId}`
    );
    // let userRes = await MLTAxiosInstance.get(
    //   `partymanagement/individual/${individualRes.data.company}/${individualRes.data.userId}`
    // );
    // console.log(userRes.data,"🍃🍃🍃")
    this.impersonite = userRes.data.impersonation;
    this.impersonitor = userRes.data.impersinater;
    this.name = userRes.data.name;
    this.userId = userRes.data._id;
    // const access = await MLTAxiosInstance.get(
    //   `partyRole/get/${individualRes.data.company}/${individualRes.data.userId}`
    // );

    const access = await MLTAxiosInstance.get(
      `partyRole/get/${individualRes.data.userId}`
    );

    this.roles = await access.data.role;
    let listUsers = await MLTAxiosInstance.get(
      `partymanagement/individual/listuser`
    );
    this.list = await listUsers.data;
    this.count = await listUsers.data.length;

    // });

    await DMNAxiosInstance
      // .get(`http://localhost:5080/rule_engine/metrics/rules_count?company=Halleyx`)
      .get(`rule_engine/metrics/rules_count`)
      .then((res) => {
        this.decisionTableCount = res.data.businessRules.decisionCount;
        this.expressionCount = res.data.businessRules.expressionCount;
        this.rulesCount = res.data.businessRules.ruleCount;
        this.minimumExecDuration = res.data.executionDuration.minimumDuration;
        this.maximumExecDuration = res.data.executionDuration.maximumDuration;
        this.averageExecDuration = res.data.executionDuration.averageDuration;
        this.piechart.series = [];
        const strings = Object.values(res.data.executionStatus);
        const numbers = strings.map((str) => parseFloat(str));
        this.piechart.series = numbers;
      });
    const last12Months = this.getLast12Months();
    this.lineChartOne.chartOptions.xaxis.categories = last12Months;
    this.performanceChartOne.chartOptions.xaxis.categories = last12Months;
    this.performanceChartTwo.chartOptions.xaxis.categories =
      this.getLast10Days();
    this.lineChartTwo.chartOptions.xaxis.categories = this.getLast10Days();
    this.performanceChartThree.chartOptions.xaxis.categories =
      this.getLast12Hours();
    this.lineChartThree.chartOptions.xaxis.categories = this.getLast12Hours();

    this.loadAllCharts();

    // this.rulePerformanceChart()
    //
  },
  methods: {
    goTo(val) {
      this.$router.push({
        name: "business-rules",
        query: { Value: val },
      });
    },
    async invite() {
      var standardEmail =
        /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/;

      if (standardEmail.test(this.email) === false) {
        this.error = true;
        this.errors = true;
        this.message = "Enter business email";
      } else {
        this.inviteUser();
      }
    },
    async inviteUser() {
      const invited = {
        name: "",
        email: this.email,
        role: this.role,
        companyName: this.company,
        status: "Active",
      };
      const signp = await MLTAxiosInstance.post(
        `partymanagement/individual/account/signup`,
        invited
      );

      this.tokened = signp.data.token;

      if (signp.data != undefined) {
        this.notification = {
          type: "success",
          message: "User invitation email sent",
        };
        let listUsers = await MLTAxiosInstance.get(
          `partymanagement/individual/listuser/${this.company}`
        );
        this.list = await listUsers.data;
        this.count = await listUsers.data.length;
        this.sendEmail();
        this.modal_active = false;
      }
    },
    async sendEmail() {
      let mail = {
        to: [this.email],
        subject: "Halleyx user Invitation",
        template: `
      <!-- 
 -->

<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <!--[if gte mso 9]>  <xml>   <o:OfficeDocumentSettings>   <o:AllowPNG/>   <o:PixelsPerInch>96</o:PixelsPerInch>   </o:OfficeDocumentSettings>  </xml>  <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <style type="text/css" media="screen">
    <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
    <link href='https://fonts.googleapis.com/css?family=Open Sans' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">  
</style>
</head>

<body class="body"
    style="padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#f5f5f5; -webkit-text-size-adjust:none;">
    <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->



    
    
    <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5">
        <tr>
            <td align="center" valign="top">
                <table  border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                    <tr>
                        <td class="td" >
                            <table  border="0" cellspacing="0" cellpadding="0" >
                                <tr>
                                    <td style="width: 612px;
                                    height: 501px;">
                                        <!-- Header -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                        class="wrapper">
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="10" cellspacing="0"
                                                                    width="100%" class="wrapper" bgcolor="#f5f5f5">
                                                                    <tr>
                                                                        <!-- <td width="104"><a href="https://halleyx.com"><img src="https://halleyx.com/img/logo-dark.9e286c66.png" height="50" style="display:block; border:none;padding-top: 20px;" alt="logo"></a></td> -->
                                                                        



                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                    style="border:none;border-collapse:collapse;"
                                                                    width="100%" class="wrapper" bgcolor="#ffffff">
                                                                    <tr>
                                                                        <td width="104"><a href="https://halleyx.com"><img src="https://halleyx.com/img/halleyx-logo-line-black.42985758.svg" height="50" style="display:block; border:none;padding-top: 20px; padding-left: 30px;" alt="logo"></a></td>
                                                                        



                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Header -->
                                        <!-- Start Content -->
                                        <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501"
                                            cellpadding="0">
                                            <tr>
                                                <td bgcolor="#FFFFFF" class="p35-15" >
                                                    <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                                        cellpadding="0">

                                                        <!-- headline -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="headline"
                                                                style="text-align:center;">
                                                                <img style="width: 15%;
                                                                object-fit: contain;
                                                                clip-path: circle();
                                                                " src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtteDflN13M27CDlAPdC7L_fNJ4Eus3fEFBkI8JKxFLmcms3TsIv_EUcvuyjVo46v1k4&usqp=CAU" alt="" srcset="">    
                                                            </td>
                                                        </tr>
                                                        <!-- #headline -->

                                                        <!--  hero-title-1 -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                style="font-family: 'Quicksand';
                                                                font-style: normal;
                                                                font-weight: 600;
                                                                font-size: 22px;
                                                                padding-top:15px;
                                                                line-height: 28px;
                                                                text-align: center;
                                                                color: #191A15;">
                                                            ${this.name}
                                                            <br>
invited you

                                                        </td>
                                                               
                                                        </tr>
                                                       
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                style="padding-top:25px;padding-left:100px;padding-right:100px;color:black;  font-family: Open Sans; font-size:14px; line-height:25px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                                You have been invited by 
                                                                <!-- <a href="mailto:someone@example.com" style="text-decoration: none;color:#54BD95">${this.name}</a> -->
                                                                <a href="mailto:someone@example.com" style="text-decoration: none;color:#54BD95">${this.name}</a>
                                                                
                                                                to join ${this.company}. To get started, accept the invitation below.
 </td>
                                                               
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1" valign="top"
                                                            style="color:black; font-family: Open Sans;padding-top: 15px;  font-size:14px; line-height:25px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                            <div class="btn" style="text-align:center;padding-top: 25px;  ">
                                                                <!--[if mso]>                                                                     <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://www.fitbit.com/campaign/shop/versa?color=black&utm_source=ET&utm_medium=EM&utm_campaign=20191117_PreBFV2_US_FM_M_SU_SW_32_NA&utm_content=HeroCTALink" style="font-family: Arial, Helvetica, sans-serif;height:36px;v-text-anchor:middle;width:150px;" arcsize="152%" stroke="f" fillcolor="#002A3A">                                                                     <w:anchorlock/>                                                                     <center>                                                                     <![endif]-->
                                                                <a href='https://halleyx-dmnuat-ghubgwxrcq-uc.a.run.app/invite/${this.tokened}'
                                                                    class="mob-btn" target="_blank"
                                                                    style="background-color:#54BD95;border-radius:6px;color:#ffffff;font-family:Open Sans;font-size:14px; text-align:center;text-decoration:none;width: 218.18px;
                                                                    height: 38px;-webkit-text-size-adjust:none; padding: 10px 12px; line-height: 15px;">Accept invitation
                                                                    </a>
                                                                <!--[if mso]>                                                                     </center>                                                                     </v:roundrect>                                                                     <![endif]-->
                                                            </div> </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                style="padding-top:40px;padding-left:80px;padding-right:80px;font-family: 'Quicksand';
                                                                font-style: normal;
                                                                font-weight: 400;
                                                                font-size: 14px;
                                                                line-height: 18px;
                                                                text-align: center;
                                                                color: #A6A6A6;">
                                                                Hiccups might happen. Your feedback and requests are very welcomed and  encouraged at 
                                                                
                                                                We can't wait to see what you create with ${this.company}.
                                                                <!-- <a href="mailto:someone@example.com" style="text-decoration: none;color:#54BD95"></a> -->
                                                                <a href="mailto:contact@halleyx.com" style="text-decoration: none;color:#54BD95">${this.email}</a>
                                                                to join ${this.company}. To get started, accept the invitation below.
 </td>
                                                               
                                                        </tr>
                                                        
                                                        
                                                </td>
                                            </tr>
                                            <!-- END HERO -->
                                        </table>
                                        <!-- HERO Images-->
                                       
                                    </td>
                                </tr>
                            </table>
                            <!-- END Content -->
                            <!--Features-->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td>
                                        <!--Start Content-->
                                        <!--Desktop RTBs-->
                                       
                                        <!--End Desktop RTBs-->
                                        <!--Mobile RTBs-->
                                        <!--[if !mso]><!-->
                                       
                                        <!--End Mobile RTBs-->
                                        <!--<![endif]-->
                                        <!--END Features-->
                                        <!-- Footer -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding: 0 15px;"
                                            bgcolor="#ffffff">
                                            <tr>
                                                
                                                <td bgcolor="#161C2B" class="disclaimer2"
                                                    style="padding: 40px 77px 40px 77px;color:#FFFFFF; font-family: Open Sans; font-size:14px; line-height:20px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                    <span><a href="https://halleyx.com" style="color: #FFFFFF;font-size: 14px;"><u>T&C</u><u style="padding-left:40px;padding-right: 40px;">Privacy</u><u>Cookies</u></a></span><br/>
                                                    <br/>
                                                    © 2023 Halleyx Inc. Copyright and rights reserved                                                    <p
                                                        style="padding-left: 17px !important; font-family:Open Sans; font-size: 14px; color: #FFFFFF;margin-top: 15px;  margin-bottom: 0px; letter-spacing: .01em;line-height: 20px;">
                                                        18 King St E #1400, Toronto, ON M5C 1C4

                                                    </p>
                                                    <span style="float:left;margin-left: -60px;padding-top:20px;"><img width="100" src="https://halleyx.com/img/halleyx-logo-line-white.c09fdc43.svg"/></span>
                                                    <span style="float: right;margin-right:-50px;padding-top:20px;color: #FFFFFF;"><a href="https://www.linkedin.com/company/halleyx/"><i class="fa fa-linkedin fa-lg" style="color: #ffffff;" ></i></a>
                                                        <a href="https://halleyx.com/" style="padding-left:5px;padding-right: 5px;"><i class="fa fa-facebook fa-lg" style="color: #ffffff;"></i>
                                                        </a>
                                                        <a href="https://www.instagram.com/halleyx_inc/"><i class="fa fa-instagram fa-lg" style="color: #ffffff;"></i></a></span>
                                                </td>
                                               
                                            </tr>
                                        </table>
                                        <!-- Footer -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <style>
                                                        .nowrap {
                                                            word-break: keep-all;
                                                            -moz-hyphens: none;
                                                            -ms-hyphens: none;
                                                            -webkit-hyphens: none;
                                                            hyphens: none;
                                                        }

                                                        a[x-apple-data-detectors] {
                                                            color: inherit !important;
                                                            text-decoration: none !important;
                                                            font-size: inherit !important;
                                                            font-family: inherit !important;
                                                            font-weight: inherit !important;
                                                            line-height: inherit !important;
                                                        }
                                                    </style>

                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                        style="border:none;border-collapse:collapse; background-color:#ffffff;"
                                                        width="100%" class="wrapper mobileContainer">

                                                        <tr>
                                                            <td class="mobileContainer" align="center"
                                                                style="font-family:Helvetica, Arial, sans-serif; color:#939c9e; font-size:0px; padding-top:24px; font-weight:100; background-color:#ffffff; width:100%; ">
                                                            </td>
                                                        </tr>




                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Footer -->
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    <img src="images?q=tbn:ANd9GcRYx7fvG1ermb_muIP457oVO48JixkaC0RvhAA&usqp=CAU"
        width="1" height="1"></custom>
</body>

</html>`,
      };
      // const sendEmail =
      await MLTAxiosInstance.post(`/email`, mail);
    },
    loadAllCharts() {
      this.rulePerformancePayload.filter = "Last 10 days";
      this.ExecutionCountChart(this.rulePerformancePayload.filter);
      this.rulePerformanceChart(this.rulePerformancePayload.filter);
      this.rulePerformancePayload.filter = "Last 12 hours";
      this.ExecutionCountChart(this.rulePerformancePayload.filter);
      this.rulePerformanceChart(this.rulePerformancePayload.filter);
      this.rulePerformancePayload.filter = "Last 12 months";
    },
    selectRulePerformance() {
      this.ExecutionCountChart(this.rulePerformancePayload.filter);
      this.rulePerformanceChart(this.rulePerformancePayload.filter);
      if (this.rulePerformancePayload.filter === "Last 12 months") {
        const last12Months = this.getLast12Months();
        this.lineChartOne.chartOptions.xaxis.categories = last12Months;
        this.performanceChartOne.chartOptions.xaxis.categories = last12Months;
      } else if (this.rulePerformancePayload.filter === "Last 10 days") {
        this.performanceChartTwo.chartOptions.xaxis.categories =
          this.getLast10Days();
        this.lineChartTwo.chartOptions.xaxis.categories = this.getLast10Days();
        //
      } else if (this.rulePerformancePayload.filter === "Last 12 hours") {
        this.performanceChartThree.chartOptions.xaxis.categories =
          this.getLast12Hours();
        this.lineChartThree.chartOptions.xaxis.categories =
          this.getLast12Hours();
        //
      }
    },
    async ExecutionCountChart(val) {
      //
      await DMNAxiosInstance.post(
        `rule_engine/metrics/execution_count`,
        this.rulePerformancePayload
      ).then((res) => {
        //
        if (val === "Last 12 months") {
          this.getLast12MonthsFilledFunc2(res.data);
        } else if (val === "Last 10 days") {
          const ruleArray = [];
          const expressionArray = [];
          const decisionArray = [];

          for (const key in res.data) {
            const { rule, expression, decision } = res.data[key];
            ruleArray.push(rule);
            expressionArray.push(expression);
            decisionArray.push(decision);
          }

          this.lineChartTwo.series[0].data = ruleArray;
          this.lineChartTwo.series[1].data = expressionArray;
          this.lineChartTwo.series[2].data = decisionArray;
        } else if (val === "Last 12 hours") {
          this.replaceArrayItemsFunc2(res.data);
        }
      });
    },
    async rulePerformanceChart(val) {
      //

      await DMNAxiosInstance.post(
        `rule_engine/metrics/rule_performance?company=${this.company}`,
        this.rulePerformancePayload
      )
        // .post(`${"https"}://${process.env.VUE_APP_HOST1}/rule_engine/metrics/rule_performance?id={{id}}&name={{name}}`, this.rulePerformancePayload)
        .then((res) => {
          //
          if (val === "Last 12 months") {
            this.getLast12MonthsFilled(res.data);
          } else if (val === "Last 10 days") {
            this.replacePayloadDates(res.data);
          } else if (val === "Last 12 hours") {
            this.replaceArrayItems(res.data);
          }
        });
    },
    getLast12Hours() {
      const hours = [];
      const currentTime = new Date();

      for (let i = 11; i >= 0; i--) {
        const hour = new Date(currentTime.getTime() - i * 60 * 60 * 1000);
        const formattedHour = `${hour
          .getHours()
          .toString()
          .padStart(2, "0")}:00`;
        hours.push(formattedHour);
      }

      return hours;
    },
    replaceArrayItemsFunc2(obj) {
      const arr = this.getLast12Hours();
      const dupObj = this.removeDateFromKeys(obj);
      const updatedArr = arr.slice(); // Duplicate the original array
      const updatedArr2 = arr.slice(); // Duplicate the original array
      const updatedArr3 = arr.slice(); // Duplicate the original array

      for (let i = 0; i < updatedArr.length; i++) {
        const key = updatedArr[i];
        if (Object.prototype.hasOwnProperty.call(dupObj, key)) {
          updatedArr[i] = dupObj[key].rule;
          updatedArr2[i] = dupObj[key].expression;
          updatedArr3[i] = dupObj[key].decision;
        } else {
          updatedArr[i] = 0;
          updatedArr2[i] = 0;
          updatedArr3[i] = 0;
        }
      }
      //
      this.lineChartThree.series[1].data = updatedArr;
      this.lineChartThree.series[0].data = updatedArr2;
      this.lineChartThree.series[2].data = updatedArr3;
      return updatedArr;
    },
    replaceArrayItems(obj) {
      const arr = this.getLast12Hours();
      const dupObj = this.removeDateFromKeys(obj);
      const updatedArr = arr.slice(); // Duplicate the original array
      const updatedArr2 = arr.slice(); // Duplicate the original array

      for (let i = 0; i < updatedArr.length; i++) {
        const key = updatedArr[i];
        if (Object.prototype.hasOwnProperty.call(dupObj, key)) {
          updatedArr[i] = dupObj[key].executionCount;
          updatedArr2[i] = dupObj[key].executionDuration.averageDuration;
        } else {
          updatedArr[i] = 0;
          updatedArr2[i] = 0;
        }
      }
      //
      this.performanceChartThree.series[1].data = updatedArr;
      this.performanceChartThree.series[0].data = updatedArr2;
      return updatedArr;
    },
    removeDateFromKeys(payload) {
      const updatedPayload = {};

      for (const key in payload) {
        if (Object.prototype.hasOwnProperty.call(payload, key)) {
          const hour = key.split(" ")[1];
          updatedPayload[hour] = payload[key];
        }
      }

      return updatedPayload;
    },
    getLast10Days() {
      const result = [];

      for (let i = 0; i < 10; i++) {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - i);
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const date = `${day}/${month}/${year}`;
        result.unshift(date);
      }
      return result;
    },
    replacePayloadDates(payload) {
      const last10Days = this.getLast10Days();
      const updatedPayload = {};
      const updatedPayload2 = {};
      for (const key of last10Days) {
        if (Object.prototype.hasOwnProperty.call(payload, key)) {
          updatedPayload[key] = payload[key].executionCount;
          updatedPayload2[key] = payload[key].executionDuration.averageDuration;
          delete payload[key];
        } else {
          updatedPayload[key] = 0;
          updatedPayload2[key] = 0;
        }
      }
      //
      this.performanceChartTwo.series[1].data = Object.values(updatedPayload);
      this.performanceChartTwo.series[0].data = Object.values(updatedPayload2);
      return { ...updatedPayload, ...payload };
    },
    getLast12Months() {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const currentDate = new Date();
      const result = [];

      for (let i = 0; i < 12; i++) {
        const monthIndex = (currentDate.getMonth() - i + 12) % 12;
        result.unshift(months[monthIndex]);
      }

      return result;
    },
    getLast12MonthsFilled(payload) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const currentDate = new Date();
      const last12Months = [];

      for (let i = 0; i < 12; i++) {
        const monthIndex = (currentDate.getMonth() - i + 12) % 12;
        last12Months.unshift(months[monthIndex]);
      }

      const result = [...last12Months];
      const result2 = [...last12Months];
      for (const [key, value] of Object.entries(payload)) {
        const monthYear = key.split("-");
        const monthIndex = parseInt(monthYear[1], 10);
        //
        if (monthIndex === 1) {
          const x = result.indexOf("Jan");
          result[x] = value.executionCount;
          result2[x] = value.executionDuration.averageDuration;
        } else if (monthIndex === 2) {
          const x = result.indexOf("Feb");
          result[x] = value.executionCount;
          result2[x] = value.executionDuration.averageDuration;
        } else if (monthIndex === 3) {
          const x = result.indexOf("Mar");
          result[x] = value.executionCount;
          result2[x] = value.executionDuration.averageDuration;
        } else if (monthIndex === 4) {
          const x = result.indexOf("Apr");
          result[x] = value.executionCount;
          result2[x] = value.executionDuration.averageDuration;
        } else if (monthIndex === 5) {
          const x = result.indexOf("May");
          result[x] = value.executionCount;
          result2[x] = value.executionDuration.averageDuration;
        } else if (monthIndex === 6) {
          const x = result.indexOf("Jun");
          result[x] = value.executionCount;
          result2[x] = value.executionDuration.averageDuration;
        } else if (monthIndex === 7) {
          const x = result.indexOf("Jul");
          result[x] = value.executionCount;
          result2[x] = value.executionDuration.averageDuration;
        } else if (monthIndex === 8) {
          const x = result.indexOf("Aug");
          result[x] = value.executionCount;
          result2[x] = value.executionDuration.averageDuration;
        } else if (monthIndex === 9) {
          const x = result.indexOf("Sep");
          result[x] = value.executionCount;
          result2[x] = value.executionDuration.averageDuration;
        } else if (monthIndex === 10) {
          const x = result.indexOf("Oct");
          result[x] = value.executionCount;
          result2[x] = value.executionDuration.averageDuration;
        } else if (monthIndex === 11) {
          const x = result.indexOf("Nov");
          result[x] = value.executionCount;
          result2[x] = value.executionDuration.averageDuration;
        } else if (monthIndex === 12) {
          const x = result.indexOf("Dec");
          result[x] = value.executionCount;
          result2[x] = value.executionDuration.averageDuration;
        }
        // result[last12Months.length - monthIndex] = value;
      }
      result.forEach((e, index) => {
        //
        if (typeof e === "string") {
          result[index] = 0;
        }
      });
      result2.forEach((e, index) => {
        //
        if (typeof e === "string") {
          result2[index] = 0;
        }
      });
      // this.barchart.series[1].data = result
      this.performanceChartOne.series[1].data = result;
      this.performanceChartOne.series[0].data = result2;
      // this.barchart.series[0].data = result2
      //

      return result;
    },
    getLast12MonthsFilledFunc2(payload) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const currentDate = new Date();
      const last12Months = [];

      for (let i = 0; i < 12; i++) {
        const monthIndex = (currentDate.getMonth() - i + 12) % 12;
        last12Months.unshift(months[monthIndex]);
      }

      const result = [...last12Months];
      const result2 = [...last12Months];
      const result3 = [...last12Months];
      for (const [key, value] of Object.entries(payload)) {
        const monthYear = key.split("-");
        const monthIndex = parseInt(monthYear[1], 10);
        //
        let monthName = "";
        switch (monthIndex) {
          case 1:
            monthName = "Jan";
            break;
          case 2:
            monthName = "Feb";
            break;
          case 3:
            monthName = "Mar";
            break;
          case 4:
            monthName = "Apr";
            break;
          case 5:
            monthName = "May";
            break;
          case 6:
            monthName = "Jun";
            break;
          case 7:
            monthName = "Jul";
            break;
          case 8:
            monthName = "Aug";
            break;
          case 9:
            monthName = "Sep";
            break;
          case 10:
            monthName = "Oct";
            break;
          case 11:
            monthName = "Nov";
            break;
          case 12:
            monthName = "Dec";
            break;
          default:
            break;
        }

        const x = result.indexOf(monthName);
        result[x] = value.rule;
        result2[x] = value.expression;
        result3[x] = value.decision;
        // result[last12Months.length - monthIndex] = value;
      }
      result.forEach((e, index) => {
        //
        if (typeof e === "string") {
          result[index] = 0;
        }
      });
      result2.forEach((e, index) => {
        //
        if (typeof e === "string") {
          result2[index] = 0;
        }
      });
      result3.forEach((e, index) => {
        //
        if (typeof e === "string") {
          result3[index] = 0;
        }
      });
      // this.barchart.series[1].data = result
      this.lineChartOne.series[1].data = result2;
      this.lineChartOne.series[0].data = result;
      this.lineChartOne.series[2].data = result3;
      // this.barchart.series[0].data = result2
      //

      return result;
    },
    async exitView() {
      //
      // let tokens = {
      //   headers: {
      //     Authorization: "Bearer  " + this.token,
      //   },
      // };
      let update = {
        id: this.userId,
        impersonation: false,
        impersinater: "",
      };
      const result = await MLTAxiosInstance.patch(
        this.fet + `/partymanagement/individual`,
        update
      );
      result;
      //
      // let token = {
      //   headers: {
      //     Authorization: "Bearer  " + this.impersonitor,
      //   },
      // };
      //
      MLTAxiosInstance.get(this.fet + `/partymanagement/individual/auth`).then(
        (val) => {
          //
          let company = val.data.company;
          if (company != "") {
            let c_name = null;
            if (company == "Halleyx") {
              c_name = "";
            } else {
              c_name = company + ".";
              //
            }
            // location.replace(window.location.protocol+"//"+`${c_name}`+window.location.hostname+':'+window.location.port +`/DMN/dashboard/${this.impersonitor}`)

            window.location.href =
              window.location.protocol +
              "//" +
              `${c_name}` +
              window.location.hostname +
              ":" +
              window.location.port +
              `/DMN/dashboard/${this.impersonitor}`;
          }
        }
      );
    },
    navTo(active) {
      //
      if (active.name == "setting") {
        this.navSetting();
      } else if (active.name == "logout") {
        this.logOut();
      } else if (active.name == "Business rule") {
        this.newApp();
      } else if (active.name == "Schema") {
        this.schema();
      } else if (active.name === "Database") {
        this.execute();
      } else if (active.name == "Execute") {
        this.exe();
      } else if (active.name == "Help") {
        this.help();
      } else if (active.name == "Home") {
        this.$router.push(`/DMN/dashboard`);
      } else {
        this.audit();
        //
      }
    },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    help() {
      window.open("https://documentation.halleyx.com");
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {
      this.$router.push(`/DMN/schemas`);
    },
    newApp() {
      this.$router.push(`/DMN/business_rules`);
    },
    // logOut() {
    //   this.$router.push("/");
    // },
    navSetting() {
      this.$router.push(`/settings`);
    },
    getImgUrl(pet) {
      // var images = require.context("../assets/", false, /\.svg$/);
      //
      return require("../assets/images/" + pet);
    },
    selectValue(val) {
      this.selectvalue = val;
    },
  },
};
</script>

<style></style>
