<template>
  <div class="dashbord-panel">
    <div v-show="impersonite == true"><banner @exit="exitView()" /></div>
    <section class="right-panel">
      <div class="dashboard-title">
        <!-- <p class="dashboard-search-container">
          <hlx-search
            :search-style="'style1'"
            @search-key="search"
          />
        </p> -->
        <p class="text-container">{{ matchFromStatic("Settings") }}</p>
      </div>
      <div class="card-holder" style="overflow: visible">
        <div
          class="cards-container"
          style="
            height: 80%;
            padding: 20px 0 20px 0;
            display: grid;
            grid-gap: 2rem;
            grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
          "
        >
          <hlx-card
            v-for="(card, index) in card_data"
            :key="index"
            class="custom-card"
            :seperator="false"
            :card-title="false"
            :card-footer="false"
            :hover="true"
            height="210"
            :class="{
              '--disable-hover': card.name && card.name.toLowerCase() == 'api',
            }"
            @click="moveTo(card.link,card.name)"
          >
            <template #card-body>
              <div
                style="padding: 0 15px"
                :class="{
                  '--disable-hover':
                    card.name && card.name.toLowerCase() == 'api',
                }"
              >
                <span v-if="card?.icon">
                  <img :src="getImgUrl(card.icon)" :alt="card.icon" />
                </span>
                <span v-else>
                  <div class="circleInCard">
                    <i :class="card.icon"></i>
                  </div>
                </span>

                <p class="card-head">
                  <b>{{ matchFromStatic(card.name) }}</b>
                </p>
                <p
                  class="card-desc copy-click"
                  data-tooltip-text="Click to copy"
                  data-tooltip-text-copied="✔ Copied to clipboard"
                >
                  {{ matchFromStatic(card.description) }}
                </p>
                <!-- <hlx-input
                  :disabled="false"
                  :label_animation="true"
                  label_value="Multi"
                  type="mixed"
                  :clearable="true"
                  name="username"
                  :suffix="true"
                  >
                  <template #suffix>
                    <i
                    class="icon-arrows-repeat-regular"
                    @click.stop="refreshAPIKey"
                    ></i>
                  </template>
                </hlx-input> -->
                <div
                  v-if="card.name && card.name.toLowerCase() == 'api'"
                  class="api-key-container"
                >
                  <input
                    ref="copy-click"
                    v-model="apiKey"
                    class="api-key-holder"
                    data-copy-string="#ITN12345"
                    data-tooltip-text=""
                    data-tooltip-text-copied="✔ Copied to clipboard"
                    @click.stop="copyIntraction"
                  />
                  <span class="c-to-c">{{
                    matchFromStatic("Click to copy")
                  }}</span>
                  <span class="c-to-cd"
                    >✔ {{ matchFromStatic("Copied to clipboard") }}</span
                  >
                  <span class="refresh-icon" @click.stop="refreshAPIKey">
                    <i class="icon-arrows-repeat-regular"> </i>
                  </span>
                </div>
              </div>
            </template>
            <template #card-footer>
              {{ card.card_footer }}
            </template>
          </hlx-card>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import banner from "../components/bannerComponent.vue";
import { MLTAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";
export default {
  name: "SettingView",
  components: { banner },
  data() {
    return {
      impersonite: false,
      fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
      token: localStorage.token,
      apiKey: "",
      example_data: [
        {
          title: "Complete account setup",
          description: "",
          subtitle: "",
          active: false,
        },
        {
          title: "Choose a plan",
          description: "",
          subtitle: "",
          active: false,
        },
        {
          title: "Choose payment method",
          description: "",
          subtitle: "",
          active: false,
        },
      ],
      example_data2: [
        {
          title: "Complete account setup",
          description: "",
          subtitle: "",
          active: true,
        },
        {
          title: "Choose a plan",
          description: "",
          subtitle: "",
          active: false,
        },
        {
          title: "Choose payment method",
          description: "",
          subtitle: "",
          active: false,
        },
      ],
      example_data3: [
        {
          title: "Complete account setup",
          description: "",
          subtitle: "",
          active: true,
        },
        {
          title: "Choose a plan",
          description: "",
          subtitle: "",
          active: true,
        },
        {
          title: "Choose payment method",
          description: "",
          subtitle: "",
          active: true,
        },
      ],
      switch_items: [{ name: "Monthly billing" }, { name: "Annual billing" }],
      card_data: [],
      user_card: [],
      user_card_temp: [],

      // [
      //   {
      //     icon: "general.svg",
      //     customName:"General",
      //     link: "organization",
      //     name: "Organization",
      //     description: "Get access to your organisation details",
      //   },
      //   {
      //     icon: "users.svg",
      //     link: "users",
      //    customName:"Users",
      //     name: "Users",
      //     description: "Manage users",
      //   },
      //   {
      //     icon: "users.svg",
      //     link: "loginDetails",
      //     customName:"User profile",
      //     name: "User profile",
      //     description: "Get access to user profile details",
      //     icon: "icon-user-regular"
      //   },

      //   {
      //     icon: "key.svg",
      //     link: "",
      //     customName:"API",
      //     name: "API",
      //     description: "My API key",
      //   },
      //   {
      //     icon: "roles.svg",
      //     customName:"Roles and permissions",
      //     link: "roles",
      //     name: "Roles and permissions",
      //     description: "Manage assigned roles and their permissions",
      //   },

      //   {
      //     icon: "bill.svg",
      //     link: "tax",
      //     customName:"Tax",
      //     name: "Tax",
      //     description: "Add the tax",
      //   },

      //   {
      //     icon: "audit.svg",
      //     link: "automation",
      //     customName:"Automation",
      //     name: "Automation",
      //     description: "Real-time tracking",
      //     icon: "icon-line-chart-dots-regular"
      //   },
      //   {
      //     icon: "customFields.svg",
      //     link: "custom-field",
      //     customName:"Fileds",
      //     name: "Fields",
      //     description: "Personalized Input Options",
      //   },
      //   {
      //     icon: "customFields.svg",
      //     link: "emailTemplate",
      //     name: "Email template",
      //     customName:"Email template",
      //     description: "Create email template",
      //   },
      //   {
      //     icon: "customFields.svg",
      //     link: "importTemplate",
      //     name: "Import template",
      //     customName:"Import template",
      //     description: "Create import template",
      //   },
      //   {
      //     icon: "audit.svg",
      //     link: "IdSettings",
      //     customName:" ",
      //     name: "ID Settings",
      //     description: "Format the ID based on user convenience",
      //   },

      // ],
      tenant_card: [
        {
          icon: "general.svg",
          link: "general",
          name: "General",
          description: "Get access to your profile details",
        },
        {
          icon: "users.svg",
          link: "tenantTable",
          name: "Tenants",
          description: "Manage tenants",
        },
        {
          icon: "users.svg",
          link: "users",
          name: "Users",
          description: "Manage users",
        },
        {
          icon: "roles.svg",
          link: "roles",
          name: "Roles and permissions",
          description: "Manage assigned roles and their permissions",
        },
        // {
        //   icon: "bill.svg",
        //   link: "bill",
        //   name: "Billing",
        //   description: "Get access to your billing, plan and payment details",
        // },
        {
          icon: "key.svg",
          link: "apiUsage",
          name: "API",
          description: "My API key",
        },
        {
          icon: "bill.svg",
          link: "tax",
          name: "Tax",
          description: "Add the tax",
        },
        {
          icon: "customFields.svg",
          link: "emailTemplate",
          name: "Email template",
          description: "Create email template",
        },
        {
          icon: "customFields.svg",
          link: "importTemplate",
          name: "Import template",
          description: "Create import template",
        },
        {
          icon: "customFields.svg",
          link: "forms",
          name: "Forms",
          description: "Create forms",
        },
      ],
      user: {},
      card_data2: [
        {
          name: "Basic",
          price: "12",
          description: "Basic plan",
          currency: "usd",
          features: [
            "faster",
            "light",
            "Advanced",
            "faster",
            "light",
            "Advanced",
            "faster",
            "light",
            "Advanced",
          ],
        },
        {
          name: "Basic",
          price: "12",
          description: "Basic plan",
          currency: "usd",
          features: [
            "faster",
            "light",
            "Advanced",
            "faster",
            "light",
            "Advanced",
            "faster",
            "light",
            "Advanced",
          ],
        },
      ],
      company: "",
      impersonitor: "",
      name: "",
      userId: "",
      editPermission: [],
      viewPermission: [],
    };
  },
  async mounted() {
    const value = await MLTAxiosInstance.get("partymanagement/individual/auth");

    const defaultSettings = await UPCAxiosInstance.get(`/customHierarchy`);
    console.log(
      defaultSettings?.data?.[0]?.module?.[0]?.footer.find(
        (item) => item.name === "Settings"
      )?.sections,
      "defaultSettings"
    );
    this.user_card_temp = defaultSettings?.data?.[0]?.module?.[0]?.footer.find(
      (item) => item.name === "Settings"
    )?.sections;

    
    let sampleTheadsd = await MLTAxiosInstance.get(
      `partyRole/get/${value.data.userId}`
    );

    console.log(this.user_card_temp,"this.user_card_temp")


    const mergedPayload = sampleTheadsd.data.permission;

    this.permisstionsData = mergedPayload?.Settings?.sections;

    this.user_card = this.user_card_temp.filter((card) => {
      const permissionKey = card?.customName;

      // Check if the permission exists, otherwise assume view is false
      return this.permisstionsData[permissionKey]?.view === true || false;
    });

    console.log(this.user_card,"user_card")
    // Get API key
    this.user = value.data;
    this.company = value.data.company;
    this.userId = value.data.userId;
    let apiKeyRes = await MLTAxiosInstance.get(
      `partymanagement/individual/getAPIKey`
    );

    if (apiKeyRes.data.ok == 0) {
      return;
    }
    this.apiKey = apiKeyRes.data.data;

    // Get role of the user
    // const access =await MLTAxiosInstance.get(
    //     `partyRole/get/${userAuth.data.userId}`
    //   );

    // console.log(access,"access role")
    // if (this.company == "Halleyx" && access.data.role == "Root admin") {
    //   this.card_data = this.tenant_card;
    // } else {
    this.card_data = this.user_card;
    // }

    // let arr = access.data;
    // if (arr && Object.keys(arr) > 0) {
    //   //
    //   this.editPermission = [];
    //   this.viewPermission = [];
    //   if (arr.permission.User_settings.edit == false) {
    //     this.editPermission.push("setting");
    //   }
    //   if (arr.permission.User_settings.view == false) {
    //     this.viewPermission.push("setting");
    //   }
    //   if (arr.permission.Schema.edit == false) {
    //     this.editPermission.push("schema");
    //   }
    //   if (arr.permission.Schema.view == false) {
    //     this.viewPermission.push("Schema");
    //   }
    //   if (arr.permission.Test_data.view == false) {
    //     this.viewPermission.push("Database", "Execute");
    //   }
    //   if (arr.permission.Test_data.edit == false) {
    //     this.editPermission.push("testdata");
    //   }
    //   if (arr.permission.Rules.view == false) {
    //     this.viewPermission.push("Business rule");
    //   }
    //   if (arr.permission.Rules.edit == false) {
    //     this.editPermission.push("rules");
    //   }
    // }
    const userInfo = await MLTAxiosInstance.get(
      `partymanagement/individual/${this.userId}`
    );
    if (userInfo.data) {
      this.impersonitor = userInfo.data.impersinater;
      this.name = userInfo.data.name;
      this.userId = userInfo.data._id;
      this.impersonite = userInfo.data.impersonation;
    }
  },

  methods: {
    async refreshAPIKey() {
      let apiKeyRes = await MLTAxiosInstance.post(
        `partymanagement/individual/generateAPIKey`,
        this.user
      );
      if (apiKeyRes.ok == 0) {
        return;
      }

      this.apiKey = apiKeyRes.data.data;
    },
    copyIntraction() {
      let input = this.$refs["copy-click"][0];
      if (input) {
        setTimeout(() => {
          input.classList.add("copied");
        }, 700);
        setTimeout(() => {
          input.classList.remove("copied");
          input.select();
          document.execCommand("copy");
        }, 2000);
      }
    },
    getImgUrl(pet) {
      //
      // var images = require.context("../assets/", false, /\.svg$/);
      //
      try {
        return require("../assets/images/" + pet);
      } catch (err) {
        return "";
      }
    },
    moveTo(link, name) {
  this.$router.push({
    path: `/settings/${link}`,
    query: { name: name }
  });
}
  },
};
</script>

<style></style>
