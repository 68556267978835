<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />

  <section style="display: flex; flex-direction: column; height: 100vh">
    <main
      class="decision-add-container --layout-3-way"
      :style="
        isRightLayoutOpened
          ? 'display: grid;grid-template-columns: 200px calc(100% - 600px) 300px'
          : ''
      "
      style="height: 100vh"
    >
      <!-- --------------------------------------------------------------------Left pannel-------------------------------------------------- -->
      <section
        class="cm-fields-left-panel responsiveSM"
        style="width: 220px; background-color: #fff; overflow: hidden"
      >
        <!-- Breadcrumb -->
        <div class="layout-breadcrumb">
          <hlx-breadcrumb :auto-route="true" />
        </div>
        <div style="margin-top: 25px">
          <leftpannelComponent
            :side-bar-data="sideBarData"
            @value-name="valueleft"
          ></leftpannelComponent>
        </div>
      </section>

      <section
        class="--layout-inner-right-portion --RL70 responsiveSM-right-portion"
        :style="'width:100%'"
      >
        <div class="responsive-breadcrumbs-sm">
          <hlx-breadcrumb :items="getBreadcrumbs" @path="goTo()" />
        </div>
        <div>
          <div>
            <div
              style="
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
              "
            >
              <div style="display: flex; align-items: center; gap: 16px">
                <span class="leaftpanelForresponsive-sm">
                  <leftpannelComponent
                    :side-bar-data="sideBarData"
                    @value-name="valueleft"
                  ></leftpannelComponent>
                </span>

                <span style="">
                  <hlx-file-upload
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Rectangle'"
                    :style-background="backgrounStyleupload"
                    :height="50"
                    :width="50"
                    :read-only="defalt"
                    :custom-icon="icon"
                    :type="'profile-upload-preview'"
                    :name-display="payload.customName"
                    :content="'Import'"
                    :profile-pic-preview="profilePicUrl"
                  />
                </span>

                <div style="display: flex; flex-direction: column; gap: 8px">
                  <div style="display: flex; align-items: center; gap: 8px">
                    <span style="font-weight: bold">{{
                      payload.displayID || "C-0001"
                    }}</span>
                    <hlx-label
                      v-if="
                        payload.status.length > 0 && payload.status === 'Active'
                      "
                      class="primary sm"
                      >{{ "Active" }}</hlx-label
                    >
                    <hlx-label
                      v-if="
                        payload.status.length > 0 &&
                        payload.status === 'Pending'
                      "
                      class="warning sm"
                      >{{ "Pending" }}</hlx-label
                    >
                    <hlx-label
                      v-if="
                        payload.status.length > 0 &&
                        payload.status === 'Inactive'
                      "
                      class="error sm"
                      >{{ "Inactive" }}</hlx-label
                    >
                  </div>
                  <div class="address-content-sm">
                    {{
                      payload?.relatedParty?.find(
                        (item) => item.type === "site"
                      )?.address ||
                      "123 Maple Street, Unit 45B, Toronto, Ontario, M5A 1A1, Canada"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="leftpannel == 'Overview'" class="contract-overview-info">
          {{ leftpannel }}
        </div>
        <div class="decision-info-right-pannel" style="overflow: hidden">
          <div v-if="leftpannel == 'Overview' || leftpannel == ''">
            <contractTab />
          </div>
        </div>
        <div class="decision-info-right-pannel" style="overflow: hidden">
          <div v-if="leftpannel == 'Approval' || leftpannel == ''">
            <contractApproval />
          </div>
        </div>
        <div class="decision-info-right-pannel" style="overflow: hidden">
          <div v-if="leftpannel == 'Execution' || leftpannel == ''">
            <contractExecution />
          </div>
        </div>
      </section>
    </main>
  </section>
</template>

<script>
// import deepDiff from "deep-diff";
import {
  //   MLTAxiosInstance,
  // DMNAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import leftpannelComponent from "@/components/leftpannelComponent.vue";
import contractApproval from "@/views/contractManagement/contractApproval.vue";
import contractExecution from "@/views/contractManagement/contractExecution.vue";
import contractTab from "@/views/contractManagement/contactTabView.vue";
export default {
  components: {
    leftpannelComponent,
    contractApproval,
    contractTab,
    contractExecution,
  },

  data() {
    return {
      sideBarData: {
        main: [
          {
            id: 1,
            name: "Overview",
            icon: "icon-check-circle-filled",
            disabled: false,
            active: true,
            view: true,
          },
          {
            id: 1.1,
            name: "Approval",
            icon: "icon-check-circle-filled",
            active: false,
            disabled: false,
            view: true,
          },
          {
            id: 2,
            name: "Signature",
            icon: "icon-check-circle-filled",
            active: false,
            disabled: true,
            view: true,
          },
          {
            id: 3,
            name: "Execution",
            icon: "icon-check-circle-filled",
            active: false,
            disabled: false,
            view: true,
          },
        ],
      },
      leftpannel: "",
      payload: {
        attachment: [],
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        tags: [],
        notes: [],
        companyName: "",
        tradingName: "",
        customName: "",
        status: "Active",
        type: "",
        "@baseType": "",
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
          {
            "@type": "leadTime",
            leadTime: 1,
            leadPeriod: "Days",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  async mounted() {
    if (
      this.$route.params.id != "id" &&
      this.$route.params.section != "create"
    ) {
      const getAgreement = await UPCAxiosInstance.get(
        `/agreementManagement/${this.$route.params.id}`
      );
      this.payload = await getAgreement.data;
    }
  },

  methods: {
    rewrite(data) {
      return data
        ?.filter((item) => item.type == "site")
        .map((item) => item?.description);
    },
    valueleft(val) {
      this.leftpannel = val;
    },
  },
};
</script>

<style lang="scss">
.contract-overview-info {
  padding: 0 10px;
  margin: 23px 0 4px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.upload-supplier {
  .main-content-body-upload .upload-panel {
    height: 140px !important;
  }
}

.address-content-sm {
  color: #a6a6a6;
  font-size: 12px;
  width: max-content;
}
.responsive-menuicon-sm {
  display: none;
}
.responsive-span-sm {
  display: none;
}
.responsiveSM-right-portion {
  padding: 1.8em 20px;
}
.responsive-breadcrumbs-sm {
  display: none;
}
.leaftpanelForresponsive-sm {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .leaftpanelForresponsive-sm {
    display: block !important;
  }
  .responsive-breadcrumbs-sm {
    display: block;
  }
  .responsiveSM {
    display: none !important;
  }
  .responsiveSM-right-portion {
    padding: 0 20px 1.8em 20px !important;
    // padding-top: none !important;
    min-width: 100% !important;
  }
  .address-content-sm {
    width: 400px;
  }
  .responsive-menuicon-sm {
    display: block;
    font-size: 24px !important;
  }
  .responsive-span-sm {
    display: block;
  }
}
</style>
