<template>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header> 
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-drawer
    :show="isModal"
    position="right"
    width="400"
    :footer="true"
    :title="'Add attachment'"
    :show-close-icon="true"
    @close="closeDiscount"
  >
    <template #body>
      <div>
        <div
          class="header-area-contact-drawer"
          style="
            border-bottom: 1px solid #d4d4d4;
            padding-bottom: 5px;
            padding: 4px 0 13px 0;
          "
        >
          <b>Contract</b>
        </div>

        <section>
          <section style="">
            <section class="productDiscountSection" style="margin-top: 12px">
              <hlx-input
                v-model:value="payload.documentNumber"
                :pre-val="payload.documentNumber"
                :label-animation="true"
                label-value="Contract number"
                type="number"
                :round-off="true"
                :clearable="true"
                required="true"
              />
              <br />
              <hlx-input
                v-model:value="payload.name"
                :pre-val="payload.name"
                :label-animation="true"
                label-value="Name"
                type="text"
                :clearable="true"
                required="true"
              />
            </section>
            <section class="productDiscountSection">
              <hlx-input
                v-model:value="payload.description"
                :pre-val="payload.description"
                :label-animation="false"
                type="textarea"
                label-value="Description"
                :clearable="false"
              />
            </section>
            <section class="productDiscountSection">
              <hlx-status
                :type="'select-status'"
                :status-option="statusList"
                :prevalue="payload.status"
                @emit-select="emitStatus"
              />
              <br />
              <hlx-select
                :key="keyagreementRef"
                :pre-value="payload?.type"
                :options="contracttype"
                prop-value="name"
                :label="'name'"
                :placeholder-value="'Contract type'"
                :label-animation="true"
                :clearable="true"
                @selected-value="contracttyprfunc"
              ></hlx-select>
            </section>
            <section class="productDiscountSection">
              <hlx-input
                v-model:value="payload.validFor.startDateTime"
                :label-animation="true"
                :single-border="false"
                :label-value="'Start date'"
                :placeholder-value="'Start date'"
                :date-format="'dd (short month), yyyy'"
                type="date"
                :clearable="false"
                icon="calendar-regular"
                :pre-val="payload.validFor?.startDateTime"
                icon-position="right"
                :disable-weekend="true"
                :start-year="2000"
                :display-error="false"
                :custom-error-message="'Please set a default value'"
                :custom-error="false"
                :end-year="2050"
                
                :holiday-list="holidays"
                @formattedDate="calenderValueStartDate"
              />
            </section>
            <section class="productDiscountSection">
              <hlx-input
                v-model:value="payload.validFor.endDateTime"
                :label-animation="true"
                :single-border="false"
                :placeholder-value="'End date'"
                :label-value="'End date'"
                :date-format="'dd (short month), yyyy'"
                type="date"
                required="true"
                :clearable="false"
                icon="calendar-regular"
                :pre-val="payload.validFor?.endDateTime"
                icon-position="right"
                :disable-weekend="true"
                :start-year="2000"
                :display-error="false"
                :custom-error-message="'Please set a default value'"
                :custom-error="false"
                :end-year="2050"
                :holiday-list="holidays"
                @formattedDate="calenderValueEndDate"
              />
            </section>

            <section class="productDiscountSection">
              <span style="display: flex; align-items: center">
                <span>Auto-renewal</span>
                <hlx-input
                  v-model:value="payload.validFor.autoRenewal"
                  :checked="payload.validFor.autoRenewal"
                  :type="'switch'"
              /></span>
            </section>

            <!-- <div class="productDiscountSection">
              <span class="select-width-half" style="align-items: center">
                <span style="width: 26%"> Cost per unit = </span>

                 <span  style="width: 39%">
                  <hlx-input
                    v-model:value="discountNewDataEmit.discountType.value"
                    :label-animation="false"
                    type="number"
                    :clearable="false"
                    :display-error="false"
                    :read-only="false"
                    :custom-error-message="'Please set a default value'"
                    :custom-error="false"
                    :pre-val="discountNewDataEmit.discountType.value"
                    :symbol="
                      $store?.getters?.getDefaultSettings?.currency?.symbol
                    "
                  />
                </span> -->
            <!-- <span style="width: 45%">
                  <hlx-input
                    v-model:value="
                      discountNewDataEmit.maximumDiscountRules[0].value
                    "
                    :pre-val="
                      discountNewDataEmit?.maximumDiscountRules[0]?.value
                    "
                    :label-animation="true"
                    :placeholder-value="'Discount'"
                    :label-value="'Discount'"
                    type="number"
                    :clearable="false"
                    :display-error="false"
                    :custom-error-message="'Please set a default value'"
                    :custom-error="false"
                    :symbol="'%'"
                  />
                </span>
                On MSRP
              </span>
            </div> -->
          </section>
        </section>
      </div>
    </template>
    <template #footer>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding: 16px;
          border-top: 1px solid #d8d8d8;
        "
      >
        <hlx-button class="secondary sm" @click="closeDiscount"
          >Cancel</hlx-button
        >
        <hlx-button
          v-if="editMode == false"
          class="primary sm"
          style="margin-left: 10px"
          :disabled="payload.name !== '' && payload.documentNumber !== ''&& payload.validFor.endDateTime !='' ? false
              : true"
          @click="addNewcontract"
          >Add</hlx-button
        >
        <hlx-button
          v-else
          class="primary sm"
          style="margin-left: 10px"
          :disabled="adddiscountbutton"
          @click="editDiscount"
          >Save</hlx-button
        >
      </div>
    </template>
  </hlx-drawer>
  <div v-if="contractData?.length >= 1" class="header-add-supplier">
    <div class="tablehead-add-supplier">{{ tablehead }}</div>
    <div class="search-add-supplier">
      <span
        ><hlx-search v-model="searchvalue" @search-key="productSearch"></hlx-search
      ></span>

      <span v-show="supplierStatus == false"
        ><hlx-button v-if="permisstionData.add" class="primary sm" @click="showNewContactModal">
          <i class="icon-plus-circle-regular"></i>
          Add contracts
        </hlx-button></span
      >
    </div>
  </div>
  <div>
    <div
      v-if="tableData.length > 0"
      style="height: 78vh; overflow: auto"
      class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
    >
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header', 'horizontal']"
        :row-hover="true"
        theme="grey"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="accounts_table_theads_cc"
        @close-rearrange="re_arrange = false"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
              @new-resize-width="resizedWidth"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
              @new-resize-width="resizedWidth"
          >
            {{ i.label }}</hlx-table-head
          >
          <hlx-table-head
          v-if="permisstionData.edit || permisstionData.delete"
            v-show="supplierStatus == false"
            :align="'center'"
            :width="70"
            :fixed="'right'"
              @new-resize-width="resizedWidth"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr v-for="(i, index) in paginatedData" id="" :key="index">
            <hlx-table-cell :align="'center'" :fixed="'left'">
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in accounts_table_theads"
              :key="col_index"
              :align="j.align"
              :fixed="j.fixed"
            >
              <div v-if="j.prop.toLowerCase() === 'documentnumber'">
                {{ i[j.prop] }}
              </div>

              <div v-if="j.prop.toLowerCase() === 'name'">
                {{ i[j.prop] }}
              </div>
              <div v-else-if="j.prop.toLowerCase() === 'status'">
                <div>
                  <hlx-status :type="i[j.prop]"></hlx-status>
                </div>
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'type'"
                style="margin: 0.5rem 0"
              >
                {{ i[j.prop] }}
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'startdatetime'"
                style="margin: 0.5rem 0"
              >
                {{ convertdateformat(i?.validFor?.startDateTime) }}
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'enddatetime'"
                style="margin: 0.5rem 0"
              >
                {{ convertdateformat(i?.validFor?.endDateTime) }}
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'attachment'"
                style="margin: 0.5rem 0"
              >
                <span style="display: flex; align-items: center; gap: 9px">
                  <span
                    ><img
                      class="file-icon"
                      src="@/assets/svg/multiUpload/pdf.svg"
                      alt=""
                  /></span>

                  <span> {{ i[j.prop][0]?.name }}</span>
                </span>
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'validfor'"
                style="margin: 0.5rem 0"
              >
                <hlx-input :checked="i[j.prop].autoRenewal" :type="'switch'" />
              </div>
              <!-- <div v-else>{{ i[j.prop] }}</div> -->
            </hlx-table-cell>
            <hlx-table-cell
            v-if="permisstionData.edit || permisstionData.delete"
              v-show="supplierStatus == false"
              :align="'center'"
              :width="120"
              :fixed="'right'"
            >
              <span :id="'table-context' + serialNumber(index - 1)" class="action-icon">
                <i
                  style="position: relative"
                  class="icon-more-vertical-filled"
                  :class="i.context === true ? 'active-action' : ''"
                  @click="crudContextMenu($event, serialNumber(index - 1))"
                >
                  <hlx-context-menu
                    :top="containertop"
                    :left="containerleft"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(i)"
                    :data="index.toString()"
                    :show="i.context == true"
                    @chosen="closeCrudContextMenu($event, i,  serialNumber(index - 1))"
                  />
                </i>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>




    <div
      v-if="tableData.length === 0 && searchvalue!== ''"
      style="height: 78vh; overflow: auto"
      class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
    >
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header']"
        :row-hover="true"
        theme="grey"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="accounts_table_theads_cc"
        @close-rearrange="re_arrange = false"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
              @new-resize-width="resizedWidth"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
              @new-resize-width="resizedWidth"
          >
            {{ i.label }}</hlx-table-head
          >
          <hlx-table-head
          v-if="permisstionData.edit || permisstionData.delete"
            v-show="supplierStatus == false"
            :align="'center'"
            :width="70"
            :fixed="'right'"
              @new-resize-width="resizedWidth"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell :colspan="accounts_table_theads.length + 2">{{
              "No data"
            }}</hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>






    <div
      v-if="contractData?.length <= 0"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 70vh;
      "
    >
      <img src="@/assets/images/suppliercontract.svg" alt="" />
      <span
        ><hlx-button
        v-if="permisstionData.add"
          v-show="supplierStatus == false"
          class="primary sm empty-table-add-button"
          @click="showNewContactModal"
        >
          <i class="icon-plus-circle-regular"></i>
          Add contracts
        </hlx-button></span
      >
    </div>
  </div>
  <div
    v-if="tableData?.length > 10"
    class="cm-accounts-right-panel-pagination-container"
  >
    <hlx-pagination
      v-model:rows-per-page="externalRowsPerPage"
      :total="tableData?.length"
      rows-per-page
      enable-rows-per-page
      :rows-per-page-list="[10, 15, 20]"
      :options="{
        attributes: {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 0,
              right: 1,
            },
          },
        },
      }"
      @current-page="currentPage"
      @updated:rows-per-page="changeRowsPerPage"
    ></hlx-pagination>
  </div>
</template>
<script>
import { UPCAxiosInstance, DMNAxiosInstance ,MLTAxiosInstance } from "@/config/axiosConfig";
export default {
  props: {
    permisstionData: {
      type: Object,
      required: true, 
      default: () => {},
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: "edit",
    },
    supplierStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      searchvalue: "",
      deleteobj: {},
      isModal: false,
      payload: {
        name: "",
        type: "",
        attachment: [
          {
            name: "",
          },
        ],
        documentNumber: "",
        status: "Draft",
        characteristic: [],
        validFor: {
          startDateTime: new Date(),
          endDateTime: "",
          autoRenewal: false,
        },
      },
      contracttype: [
        { name: "Service Agreement" },
        { name: "Purchase Agreement" },
        { name: " Non-Disclosure Agreement (NDA)" },
        { name: "Partnership Agreement" },
      ],
      editMode: false,
      statusList: [],
      // statusList: [
      //   {
      //     name: "Inprogress",
      //     value: "inProgress",
      //     color: "#2879b7",
      //   },
      //   {
      //     name: "Hold",
      //     value: "hold",
      //     color: "#f8a523",
      //   },
      //   {
      //     name: "Acknowledged",
      //     value: "acknowledged",
      //     color: "#86a523",
      //   },
      //   {
      //     name: "Completed",
      //     value: "completed",
      //     color: "#007247",
      //   },
      //   {
      //     name: "Rejected",
      //     value: "rejected",
      //     color: "#ff5050",
      //   },
      // ],
      tablehead: "Contracts",
      externalRowsPerPage: 10,
      tableData: [],
      notification: [],
      accounts_table_theads: [
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "Contract number",
          prop: "documentNumber",
          align: "left",
          name: "Contract number",
          checked:true,
          disabled:true
        },
        {
          sortable: false,
          resizable: true,
          width: 300,
          label: "Contract name",
          name: "Contract name",
          prop: "name",
          align: "left",
          checked:true,
          disabled:true
        },
        {
          sortable: false,
          resizable: true,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
          name: "Status",
          checked:true,
          disabled:false
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          label: "Contract type",
          prop: "type",
          align: "left",
          name: "Contract type",
          checked:true,
          disabled:false
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "Start date",
          prop: "startDateTime",
          align: "left",
          name: "Start date",
          checked:true,
          disabled:false
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "End date",
          prop: "endDateTime",
          align: "left",
          name: "End date",
          checked:true,
          disabled:false
        },
        {
          sortable: false,
          resizable: true,
          width: 350,
          label: "Documents",
          prop: "attachment",
          align: "left",
          name: "Documents",
          checked:true,
          disabled:false
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "Auto-renewal",
          prop: "validFor",
          align: "left",
          name: "Auto-renewal",
          checked:true,
          disabled:true
        },
      ],
      accounts_table_theads_cc:[
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "Contract number",
          prop: "documentNumber",
          align: "left",
          name: "Contract number",
          checked:true,
          disabled:true
        },
        {
          sortable: false,
          resizable: true,
          width: 300,
          label: "Contract name",
          prop: "name",
          align: "left",
          checked:true,
          name: "Contract name",
          disabled:true
        },
        {
          sortable: false,
          resizable: true,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
          name: "Status",
          checked:true,
          disabled:false
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          label: "Contract type",
          prop: "type",
          align: "left",
          name: "Contract type",
          checked:true,
          disabled:false
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "Start date",
          prop: "startDateTime",
          align: "left",
          name: "Start date",
          checked:true,
          disabled:false
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "End date",
          prop: "endDateTime",
          align: "left",
          name: "End date",
          checked:true,
          disabled:false
        },
        {
          sortable: false,
          resizable: true,
          width: 350,
          label: "Documents",
          prop: "attachment",
          align: "left",
          name: "Documents",
          checked:true,
          disabled:false
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          label: "Auto-renewal",
          prop: "validFor",
          align: "left",
          name: "Auto-renewal",
          checked:true,
          disabled:true
        },
      ],
      keyagreementRef:0,
      name: "",
      copyOfcontractTableData: [],
      duplicateTheads: [],
      re_arrange: false,
      currPage: 1,
      rowsPerPage: 10,
      contractData: [],
      filterPanelName: "productSpecification",
      modal_active: false,
      delete_modal_active: false,
      initialPayload:{},
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
  },
  watch: {
    // data: {
    //   handler(val) {
    //     this.payload = val;
    //     console.log(val, "product value");
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    payload: {
      handler(val) {
        console.log(val, "product value");
        // val.attachment[0]={name:`${val.name}.pdf`}
      },
      immediate: true,
      deep: true,
    },
    tableData: {
      handler(val) {
        this.copyOfcontractTableData = val;
        console.log(val, "product value");
        // val.attachment[0]={name:`${val.name}.pdf`}
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
      // To get table header   
let sampleTheads = await MLTAxiosInstance.get(`/settings`);

if (sampleTheads?.data?.entityRef?.["supplierContractTable"]) {
  let offerTableHeadModal = sampleTheads.data.entityRef["supplierContractTable"];
  let offerTableHeadOrg = sampleTheads.data.entityRef["supplierContractTable"].filter(ele => ele.checked);
  console.log(offerTableHeadOrg,"offerTableHeadModal",offerTableHeadModal)

  if (
    offerTableHeadModal.length ===  this.accounts_table_theads_cc.length 
    // &&
    // offerTableHeadModal.every((item, index) => item.name ===  this.accounts_table_theads_cc[index].name)
  ) {
     this.accounts_table_theads_cc = JSON.parse(JSON.stringify(offerTableHeadModal));
       this.accounts_table_theads = JSON.parse(JSON.stringify(offerTableHeadOrg));
       this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
       this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
  }
}
    this.refeshContract();
    let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList = statusList.data.data;
    // let contracttype = await DMNAxiosInstance.get(`mapRef/collection/contratType`);
    // this.contracttype = contracttype.data.data;
    // console.log(this.contracttype,"this.contracttype");
  },
  methods: {
    resizedWidth(val){
      // To get resized width
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.accounts_table_theads);
  updateWidth(this.accounts_table_theads_cc);
console.log(this.accounts_table_theads,"resizedWidth loop",this.accounts_table_theads_cc)
this.applyColumns(this.accounts_table_theads_cc)
    },
    reAssignOrgTableHeader(){
           // If there is change in the table header then ressign to the Orginal data
           if((this.accounts_table_theads_cc != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.accounts_table_theads_cc = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.accounts_table_theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.accounts_table_theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.re_arrange = true
    },
    addCancel() {
      this.modal_active = false;
    },
    addNewAccount() {
      //
      this.modal_active = true;
    },
    productSearch(val) {
      this.searchvalue = val;
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.contractData,
          val.toLowerCase()
        );
        this.tableData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.contractData));
      }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    async refeshContract() {
      let tableData = await UPCAxiosInstance.get(
        `agreementManagement?company=${this.$route.params.companyName}`
      );
      console.log(tableData.data, "contract");
      this.tableData = await tableData.data;
      this.contractData = await tableData.data;
      console.log(tableData.data.length, "length");
    },
     
    closeDiscount() {
      this.keyagreementRef+=1
      this.isModal = false;
      this.delete_modal_active = false;
      this.payload = {
        name: "",
        type: "",
        attachment: [
          {
            name: "",
          },
        ],
        documentNumber: "",
        status: "Defalt",
        characteristic: [],
        validFor: {
          startDateTime: new Date(),
          endDateTime: "",
          autoRenewal: false,
        },
      };
    },
     async changeRowsPerPage(count) {
      if (this.company != "") {
        this.rowsPerPage = count;
      
      }
    },
    async currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
     
      }
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    contracttyprfunc(val) {
      this.payload.type = val.name;
    },
    emitStatus(val) {
      this.payload.status = val.name;
      console.log("Discount status", val);
    },
    async addNewcontract() {
      const attachmentName = this.payload.name.replace(/\s+/g, "_");
      this.payload.attachment[0] = { name: `${attachmentName}.pdf` };
      const addedProductLocationLogPayload = {
        message: `${this.payload.name} Contract has been added`,
        detailedAction: [],
        action: "ADDED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "supplier-Contract",
        category: ["supplier"],
      };
      this.initLog(addedProductLocationLogPayload);
      const addproduct = await UPCAxiosInstance.post(
        `/agreementManagement?company=${this.$route.params.companyName}`,
        this.payload
      );
      console.log(addproduct.data);
      if (addproduct.data) {
        this.notification = {
          type: "success",
          message: `Your item is added successfully!.`,
        };
      }
      this.refeshContract();
      this.closeDiscount();
    },
    async editDiscount() {
            let CM_LocationLog = {
        message: `${this.$route.params.companyName} Contract was updated`,
        detailedAction: [],
        "@type": "supplier-Contract",
        category: ["supplier"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };

      let nameActionLog = this.buildBasicLogByDifferences(
        this.initialPayload,
        this.payload,
        "Name",
        "Name"
      );
      console.log(nameActionLog, CM_LocationLog, "nameActionLog");
  
  let documentNumberActionLog = this.buildBasicLogByDifferences(
        this.initialPayload,
        this.payload,
        "documentNumber",
        "Contract number"
      );
      console.log(documentNumberActionLog, CM_LocationLog, "documentNumberActionLog");


let statusActionLog = this.buildBasicLogByDifferences(
        this.initialPayload,
        this.payload,
        "status",
        "status"
      );
      console.log(statusActionLog, CM_LocationLog, "statusActionLog");

let typeActionLog = this.buildBasicLogByDifferences(
        this.initialPayload,
        this.payload,
        "type",
        "Contract type"
      );
      console.log(typeActionLog, "typeActionLogActionLog");

let validForSActionLog = this.buildBasicLogByDifferences(
        this.initialPayload.validFor,
        this.payload.validFor,
        "startDateTime",
        "startDate"
      );
      console.log(validForSActionLog, "validForSActionLog");

let validForESActionLog = this.buildBasicLogByDifferences(
        this.initialPayload.validFor,
        this.payload.validFor,
        "endDateTime",
        "endDate"
      );
      console.log(validForESActionLog, "validForESActionLog");

let validForaActionLog = this.buildBasicLogByDifferences(
        this.initialPayload.validFor,
        this.payload.validFor,
        "autoRenewal",
        "autoRenewal"
      );
      console.log(validForaActionLog, "validForaActionLog");

CM_LocationLog.detailedAction=CM_LocationLog.detailedAction.concat(
  validForaActionLog,
  validForESActionLog,
  validForSActionLog,
  typeActionLog,
  statusActionLog,
  documentNumberActionLog,
  nameActionLog
)
      this.payload.id = this.payload._id;
      delete this.payload._id;
      const editDiscount = await UPCAxiosInstance.patch(
        `/agreementManagement?company=${this.$route.params.companyName}`,
        this.payload
      );
      console.log(editDiscount.data);
      if (editDiscount.data) {

        this.notification = {
          type: "success",
          message: `Done! Your item has been updated.`,
        };
      this.initLog(CM_LocationLog);

      }
      this.refeshContract();
      this.closeDiscount();
    },
    async deleteExecRow() {
         const addedProductLocationLogPayload = {
        message: `${this.deleteobj.name} Contact has been deleted`,
        detailedAction: [],
        action: "Delete",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "supplier-Contract",
        category: ["supplier"],
      };
      this.initLog(addedProductLocationLogPayload);
      const addproduct = await UPCAxiosInstance.delete(
        `/agreementManagement/${this.deleteobj._id}?company=${this.$route.params.companyName}`,
        this.payload
      );
      console.log(addproduct.data);
      if (addproduct.data) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been removed..`,
        };
      }
      this.refeshContract();
      this.closeDiscount();
    },
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    showNewContactModal() {
      this.isModal = true;
    },
    async closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        this.isModal = true;
        this.editMode = true;
        this.payload = JSON.parse(
          JSON.stringify(this.copyOfcontractTableData[i])
        );
        this.initialPayload = JSON.parse(
          JSON.stringify(this.copyOfcontractTableData[i])
        );
        // this.$router.push(`/SM/supplier/edit`);
      } else if ($event.toLowerCase() == "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      }
    },

    computedTableAction(data) {
      data;
      if (this.permisstionData.delete && this.permisstionData.edit) {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else if (this.permisstionData.delete ) {
        return [
          
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      }
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    arrangeColumns(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
   async applyColumns(val) {
      // To get rearranged header
      console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef || {};

concatedEntity.supplierContractTable = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.accounts_table_theads_cc = entityRef["supplierContractTable"];
this.accounts_table_theads = this.accounts_table_theads_cc.filter(ele => ele.checked);
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
    },
  },
};
</script>
<style lang="scss" scoped>
.header-add-supplier {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  .tablehead-add-supplier {
    font-weight: bold;
  }
  .search-add-supplier {
    display: flex;
    gap: 10px;
  }
}
.contacts-username-supplier {
  display: flex;
  align-items: center;
  .right-head-supplier {
    display: flex;
    flex-direction: column;
  }
  .head-supplier-left {
    display: flex;
    justify-content: center;
    width: 44px;
    align-items: center;
    font-size: 45px;
    margin-right: 10px;
  }
  .head-supplier-right {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    .head-supplier-name {
      margin-right: 10px;
    }
  }
  .head-supplier-address {
    font-size: 10px;
    color: #929191;
    margin-top: 3px;
  }
}
</style>
