<template>
  <div v-if="data_loaded == null">
    <hlx-loader load-type="dotted-jumper" />
  </div>
  <hlx-modal
    :modal-active="data_loaded"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
  >
    <template #header>
      <div style="margin-left: 0px">{{ title }}</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>{{ errorMessage }}</p>
      </div>
    </template>
  </hlx-modal>
  <section v-if="data_loaded == false" class="form-body">
    <div class="banner" :style="bannerStyle" v-html="bannerHtml?.[selectedLanguage?.code?.toLowerCase()]"></div>
    <section class="form-content">
      <div v-for="(section, index) in customSchema" :key="index">
        <fieldRenderer
          :key="updateFieldRenderKeyForContact"
          :layout="'1-column'"
          :custom-language = customLanguage

          :schema-payload="customSchema[index]"
          :data-payload="dataPayload[index]"
          :is-add-clicked="isAddButtonClicked"
          :trigger-rule="triggerRuleForContacts"
          :updated-schema-after-remap="updatedContactSchemaForRemap"
          :is-save-clicked="isSaveButtonClickedForContact"
          :is-modal-window="modalPropsForFieldRender"
          :email-data-props="emailArrayForContacts"
          :status-list="[
            'Open',
            'Qualified',
            'Unqualified',
            'Network approved',
            'Network rejected',
            'Awaiting network approval',
            'Converted',
            'Closed Lost',
          ]"
          :parent-class-name="'parent-container-for-lead-maintable-contact'"
          @edited-payload="getLeadData($event, index)"
          @updated-schema="getContactUpdatedSchema"
          @input-focus-out="getContactInputFocusOut"
        ></fieldRenderer>
      </div>
    </section>
    <section
      class="footer"
      style="
        border-top: 1px solid #d8d8d8;
        padding-right: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 10vh; /* Set footer height */
        background-color: #fff;
        position: sticky;
        bottom: 0;
      "
    >
      <div
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button class="secondary sm" @click="cancelApiAdd"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary sm"
          :disabled="isSubmit"
          style="margin-left: 20px"
          @click="saveAPIData"
          >Submit</hlx-button
        >
      </div>
    </section>
  </section>
</template>

<script>
import fieldRenderer from "@/components/FieldRenderer.vue";
import {
  UPCAxiosInstance,
  DMNAxiosInstance,
  MLTAxiosInstance,
} from "@/config/axiosConfig";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";

export default {
  components: {
    fieldRenderer,
  },
  data() {
    return {
      isSubmit: true,
      customLanguage:{},
      bannerHtml: {},
      bannerStyle: {
        height: "75px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        fontSize: "18px",
        backgroundColor: "#00549a",
      },
      formConfig: {},
      dataPayload: {},
      customSchema: {},
      data_loaded: null,
      errormessage: "",
      title: "",
      langList:[],
      externalVisibilityRule: [],
      externalRule: "",
      formFeeder: {}, // Data used for triggering actions
    };
  },
  computed: {
    selectedLanguage() {
      // Find the currently selected language
      return this.langList.find((lang) => lang.checked) || this.langList[0];
    },
  },
  async mounted() {


const decryptToken = (encryptedToken, secretKey) => {
    try {
        if (!encryptedToken) {
            throw new Error("Encrypted token is missing.");
        }

        const parts = encryptedToken.split(':');
        if (parts.length !== 2) {
            throw new Error("Invalid token format. Expected 'IV:EncryptedData'.");
        }

        const ivHex = parts[0];
        const encryptedHex = parts[1];

        const key = CryptoJS.enc.Hex.parse(secretKey);
        const iv = CryptoJS.enc.Hex.parse(ivHex);
        const encryptedBytes = CryptoJS.enc.Hex.parse(encryptedHex);

        const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedBytes }, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        const plaintext = decrypted.toString(CryptoJS.enc.Utf8);

        return plaintext;

    } catch (error) {
        console.error("Decryption Error:", error); // Keep the full error for debugging
        return null
    }
};

// Example usage (replace with your actual values)
const secretKey = 'ae6f7b9370e48a054b3def884d7fd7312f3e2a400c02f343a5939e8fc12f7f75';
const urlParams = new URLSearchParams(window.location.search);
const encryptedToken = urlParams.get('token');// Replace with your actual token

try {
    let decryptedToken = decryptToken(encryptedToken, secretKey);
    console.log("Final Decrypted Token:", decryptedToken);
    if (decryptedToken.startsWith("Bearer ")) {
      decryptedToken = decryptedToken.substring("Bearer ".length); 
      // VueCookies.set("token", decryptedToken);// Remove "Bearer "
    }
    this.$cookies.set("token", decryptedToken);
    
    VueCookies.set("token", decryptedToken);
    // console.log("token hj", VueCookies.get("token"), this.$cookies.get("token"))
} catch (err) {
    console.error("Failed to decrypt token:", err.message);
}


    const individualRes = await MLTAxiosInstance.get(
      "partymanagement/individual/auth"
    );
    let company = individualRes.data.company;
    this.$cookies.set("company", company);
    VueCookies.set("company", company);
    // Initializing formFeeder data
    this.formFeeder = {
      lead: { leadId: "677c1361758d99f3e4f164f7" },
    };
    let tinyUrl = await DMNAxiosInstance.get(
      `/tinyUrl/${this.$route.path.split("/").pop()}`
    );
    if (tinyUrl.data.ok == 0) {
      this.errorMessage = tinyUrl.data.error;
      this.title = tinyUrl.data.title;
      this.data_loaded = true;
    } else {
      this.customSchema = tinyUrl.data.form.schema;
      this.bannerHtml = tinyUrl.data.form.html;
      this.bannerStyle = tinyUrl.data.form.style;

      const response = await UPCAxiosInstance.get(
        `/salesLead/${this.formFeeder.lead.leadId}`
      );
      let formConfig = {
        leadData: response.data,
        customSchema: this.customSchema,
      };

      const formData = await DMNAxiosInstance.post(
        `rule_engine/rule/execute/${tinyUrl.data.form.rule}`,
        { payload: { formConfig: { ...formConfig } } }
      );
      if (formData) {
        this.data_loaded = false;
      }
      this.customSchema = formData.data.data.formConfig.formData.customSchema;
      this.dataPayload = formData.data.data.formConfig.formData.dataPayload;

      let filterData = {
      type: "filter",
      company: "Halleyx",
      module: "hierarchyaccounts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 10,
      filterQuery: {
        tradingName: this.$cookies.get("company"),
      },
      searchQuery: "",
    };

    try {
      const tenantDataData = await MLTAxiosInstance.post(
        `util/filterData`,
        filterData
      );
      this.langList = tenantDataData?.data?.data[0].languageAbility?.map(
        (language) => ({
          ...language,
          checked: false,
        })
      );
    }catch(err){
      console.log(err)
    }
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectLanguage(lang) {
      // Mark the selected language as checked
      this.langList.forEach((language) => (language.checked = false));
      lang.checked = true;

      // Close the dropdown
      this.isOpen = false;
      this.customLanguage = lang;
      // Perform additional actions, like updating the app's locale
      console.log(`Selected language: ${lang.code}`);
    },
    staticMatch(searchTerm) {
      try {
        let data = JSON.parse(localStorage.getItem("remsCache"));
        console.log("Retrieved data from cache:::", data);
        if (
          data === null ||
          data === "null" ||
          data === undefined ||
          data === "undefined" ||
          data === ""
        ) {
          data = [];
        }

        let languageAbility = [];
        let newsq = [this.customLanguage];

        if (
          newsq === null ||
          newsq === "null" ||
          newsq === undefined ||
          newsq === "undefined" ||
          newsq === ""
        ) {
          languageAbility = [{ name: "english", code: "en" }];
        } else {
          languageAbility = newsq;
        }

        // Find the object in the data array that matches the search term in the 'en.label' field
        const matchedObject = data.find(
          (item) =>
            item.en && item.en.label.toLowerCase() === searchTerm.toLowerCase()
        );

        // If no match is found, return null
        if (!matchedObject) {
          // console.log("no match matchFromStatic:::", searchTerm);
          return searchTerm;
        }

        // Iterate through languageAbility to find the desired translation
        for (const lang of languageAbility) {
          const translation = matchedObject[lang.code]?.label;
          if (translation) {
            // console.log("try matchFromStatic:::", translation, searchTerm);
            return translation;
          }
        }

        // If no translation is found, return null
        return searchTerm;
      } catch (error) {
        console.error("Error matchFromStatic:::", error);
        return searchTerm;
      }
    },
    async getLeadData(val, key) {
      this.dataPayload[key] = val;

      // for (var site in this.dataPayload) {
      //   if (site.toLowerCase().includes("siteinformation")) {
      //     this.dataPayload[site].approvalOptions =
      //       this.dataPayload.approval.approvalOptions;
      //   }
      // }
      for (var i in this.externalVisibilityRule) {
        let response = await DMNAxiosInstance.post(
          `rule_engine/rule/execute/${
            this.getNestedValue(
              this.customSchema,
              this.externalVisibilityRule[i]
            ).externalVisibilityRule
          }`,
          { payload: { execution: { dataPayload: this.dataPayload } } }
        );
        this.setNestedValue(
          this.customSchema,
          `${this.externalVisibilityRule[i]}.isVisible`,
          response.data.data.execution.visible
        );
      }
      if (this.dataPayload.approval.approvalOptions != null) {
        this.isSubmit = false;
      } else {
        this.isSubmit = true;
      }

      // this.dataPayload = JSON.parse(JSON.stringify(this.dataPayload));
      // let response = await DMNAxiosInstance.post(
      //   `rule_engine/rule/execute/${ moduleData.data.data.rule}`,
      //   { payload: { formConfig: { ...formConfig } } }
      // );
    },
  },
};
</script>

<style lang="scss">
.form-body {
  display: flex;
  flex-direction: column;

  .banner {
    height: 75px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    background-color: #00549a;
  }

  .form-content {
    padding: 20px 30px;
  }

  .header-title {
    color: #000;
    font-family: "Open Sans", sans-serif;
    padding: 30px 0 20px 0;
    font-size: 16px;
  }
}
</style>
