<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <hlx-modal
    :modal-active="modal_active6"
    :height="'300px'"
    :width="'900px'"
    :modal="true"
    @close="modal_active6 = false"
  >
    <template #header>
      <div>Custom filter</div>
    </template>
    <template #content>
      <div
        class="modal-content5"
        style="display: flex; padding: 10px; justify-content: center"
      >
        <span style="margin: 10px">
          <hlx-input
            :label-animation="false"
            type="date"
            :clearable="false"
            icon="calendar"
            iconposition="right"
          />
        </span>
        <span style="margin: 10px">
          <hlx-select
            v-model:value="selectvalue"
            :options="dropdown_data"
            :placeholder-value="'Choose the condition'"
            :label-animation="true"
            :clearable="true"
            @custom-change="selectValue"
          ></hlx-select>
        </span>
        <span style="margin: 10px">
          <hlx-input
            :label-animation="false"
            type="date"
            :clearable="false"
            icon="calendar"
            iconposition="right"
          />
        </span>
      </div>
    </template>
    <template #footer>
      <hlx-button class="primary sm" @click="modal_active6 = false"
        >Save</hlx-button
      >
    </template>
  </hlx-modal>

  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <!-- Rule Type -->

  <hlx-modal
    :modal-active="showRuleType"
    :height="'370px'"
    :width="'650px'"
    :modal="true"
    @close="showRuleType = false"
  >
    <template #header>
      <div style="margin-left: 0px">Add logical rule</div>
    </template>
    <template #content>
      <div class="modal-content">
        <div class="cards-container-exp-builder">
          <hlx-card
            v-for="(card, index) in this.card"
            :key="index"
            :width="300"
            :hover="true"
            :seperator="false"
            :card-title="true"
            :card-footer="false"
            :padding="true"
            @click="chosenTile(index)"
          >
            <!-- :active="index === 0 ? true : false" -->
            <template #card-title>
              <div class="card-title-exp-builder">
                {{ card.card_title }}
              </div>
            </template>
            <template #card-body>
              <div class="card-body-exp-builder">
                {{ card.card_body }}
              </div>
            </template>
          </hlx-card>
        </div>
      </div>
    </template>
  </hlx-modal>

  <hlx-drawer
    :show="show_right"
    :width="500"
    position="right"
    :footer="true"
    :show-close-icon="true"
    @close="closeRight"
  >
    <template #header>
      <p>Filter</p>
    </template>
    <template #body>
      <!-- {{filteroptions}} -->
      <div>
        <div
          v-for="(i, index) in dup_theads"
          :key="index"
          style="margin-bottom: 10px"
        >
          <span style="display: flex">
            <p>{{ i.name }}</p>
            <i
              class="icon-more-vertical-filled"
              style="transform: rotate(90deg)"
              @click="modal_active6 = true"
            >
              <!-- <hlx-context-menu :options="this.options" @chosen="chosen" :show="this.clicked"/> -->
            </i>
          </span>
          <!-- {{filteroptions[i.prop]}} -->
          <hlx-multi-select
            :options="filteroptions[i.prop]"
            :placeholder="''"
            :label-animation="false"
            :prop-value="'value'"
            :label="'name'"
            :disabled="false"
            :filter-select="true"
            @add-data="addruleset"
            @selected-value="selectedvalue($event, i.prop)"
          ></hlx-multi-select>
        </div>
      </div>
    </template>
  </hlx-drawer>

  <div class="app-layout">
    <section class="new-right-panel">
      <div class="new-right-panel-parent-container" style="display:block">
        <div class="new-right-panel-left-container">
         
        </div>
        <div class="new-right-panel-right-container">
          <section class="new-layout-basic-no-breadcrumb">
            <div class="new-layout-header">
              <span class="new-layout-header-container">
                <span class="schema-header-title"
                  >Rule studio<i class="icon-clock"></i>
                </span>
                <span class="subtitle">
                  Effortlessly create, manage, and customize various types of
                  business rules, including expressions, logical rules and
                  decision tables.
                </span>
              </span>
            </div>

            <div class="new-layout-body">
              <div
                class="header-utils-and-switch-container"
                style="width: 100%"
              >
                <!-- <hlx-switch
                :type="'tab'"
                :position="'bottom'"
                :switch-items="schema_type_switch"
                @chosen="schemaSelected"
              /> -->
                <span style="width: 70%">
                  <hlx-switch
                    v-model="selectedValue"
                    :switch-items="schema_type_switch"
                    :tab-view="true"
                    value-prop="name"
                    display-prop="name"
                    @change="handleCurrentChange"
                  />
                </span>
                <span class="schema-header-utils">
                  <span class="util-items search"
                    ><hlx-search @search-key="searchKey"></hlx-search
                  ></span>
                  <!-- <span class="util-items"
                  ><hlx-button class="secondary-success sm" @click="filterFunctionality"
                    ><i class="icon-filter-regular"></i>Filter</hlx-button
                  ></span
                > -->
                  <span style="margin-right: 8px;" >
                    <hlx-button
                      class="secondary sm add-btn"
                      @click="showDrawerRight()"
                      ><i class="icon-filter-regular"></i
                    ></hlx-button>
                    <hlx-drawer
                      :show="show_right_filter"
                      :width="300"
                      position="right"
                      :header="false"
                      :footer="false"
                      :show-close-icon="true"
                      @close="closeRightFilter"
                    >
                      <template #body>
                        <div class="filter-panel-layout">
                          <!-- {{filtertagvalue}} -->
                          <div
                            v-if="filtertagvalue.length > 0"
                            class="applied-filter-section"
                          >
                            <div class="applied-filter-section-header">
                              <p>Filters Applied</p>
                              <p
                                class="clear-all-button"
                                @click="clearallfilter"
                              >
                                Clear All
                              </p>
                            </div>
                            <div class="applied-filter-section-area">
                              <span
                                v-for="(
                                  filtervalue, arrindex
                                ) in filtertagvalue"
                                :key="arrindex"
                              >
                                <hlx-tag
                                  @tag-close="removedfilterdata(filtervalue)"
                                  >{{ filtervalue.value }}</hlx-tag
                                >
                              </span>
                            </div>
                          </div>
                          <div class="select-filter-option">
                            <span class="select-filter-option-header">
                              <p
                                class="select-filter-option-count-filter-section"
                              >
                                Filters
                              </p>
                              <span>
                                <p class="select-filter-option-count">
                                  {{ filtertagvalue.length }} selected
                                </p>
                              </span>
                            </span>
                            <span class="select-filter-option-section">
                              <hlx-accordion
                                v-for="(i, index) in Object.keys(filterdata)"
                                :key="index"
                                :multiple="true"
                              >
                                <template #item-title>
                                  <span
                                    class="select-filter-option-section-title"
                                  >
                                    <span
                                      class="select-filter-option-section-title-name"
                                    >
                                      {{ insertSpaces(i) }}
                                    </span>
                                    <span
                                      class="select-filter-option-section-title-count"
                                    >
                                      {{ filterdata[i].length }}
                                    </span>
                                  </span>
                                </template>
                                <template #item-body>
                                  <div
                                    v-if="i != 'averageDuration'"
                                    class="select-filter-body-section"
                                  >
                                    <div
                                      v-for="(j, col_index) in filterdata[i]"
                                      :key="col_index"
                                      class="select-filter-body-section-value-container"
                                    >
                                      <span
                                        class="select-filter-body-section-value"
                                      >
                                        <span
                                          class="select-filter-body-section-value-details"
                                          >{{ j.value }}</span
                                        >
                                      </span>
                                      <span
                                        class="select-filter-body-section-value-checkbox"
                                      >
                                        <hlx-input
                                          v-model:value="j.checked"
                                          :checked="j.checked"
                                          :type="'single-checkbox'"
                                          @selected="filtercheckedvalue(i, j)"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div v-else class="filter-slide-container">
                                    <!-- {{i}} -->
                                    <span style="width: 100%">
                                      <hlx-min-max-slider
                                        v-model:min-value="filterprice.min"
                                        v-model:max-value="filterprice.max"
                                        :min="sliderMin"
                                        :max="sliderMax"
                                        @slidervalue="sliderval"
                                      />
                                    </span>
                                  </div>
                                </template>
                              </hlx-accordion>
                              <hlx-accordion :multiple="true">
                                <template #item-title>
                                  <span
                                    class="select-filter-option-section-title-name"
                                  >
                                    {{ "Last modified" }}
                                  </span>
                                </template>
                                <template #item-body>
                                  <hlx-date-picker
                                    from-key="startDateTime"
                                    to-key="endDateTime"
                                    :label-animation="false"
                                    :label-value="'Validity'"
                                    :left="325"
                                    :top="170"
                                    type="date"
                                    icon="calendar-regular"
                                    icon-position="right"
                                    required="true"
                                    @selected-range="handleCurrentDate"
                                  ></hlx-date-picker>
                                </template>
                              </hlx-accordion>
                            </span>
                          </div>
                        </div>
                      </template>
                    </hlx-drawer>
                  </span>
                  <span
                    :class="'rule-add-btn'"
                    class="util-items util-items-add"
                    ><hlx-button
                     v-if="permisstionData.add "
                      class="primary sm add-btn"
                      @click.stop="showAddContextMenu"
                      ><i
                        class="icon-plus-circle-regular"
                        style="margin-right: 5px"
                      ></i
                      >Add</hlx-button
                    >
                    <hlx-context-menu
                      :top="containertop1"
                      :left="containerleft1"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      style="z-index: 10000"
                      :options="add_context_options"
                      :show="show_add_menu"
                      :style="{ marginTop: '5px', marginRight: '20px' }"
                      @chosen="fromAddContextMenu"
                    ></hlx-context-menu>
                  </span>
                </span>
              </div>
              <!-- {{this.view}} -->
              <div v-if="tableData?.length > 0" class="table-wrap">
                <!-- {{'abc'}} -->
                <hlx-table
                  :column-count="theads.length + 2"
                  :border="['table', 'header', 'horizontal']"
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="theadsC"
                  @sorting_func="sorting_Data"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'center'"
                      style="padding: 0 15px"
                      :fixed="'left'"
                      @new-resize-width="resizedWidth"
                      >{{ "S.no" }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-for="(i, index) in theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :width="i.width !== undefined ? i.width : ''"
                      :align="i.align !== undefined ? i.align : ''"
                      @sorting_func="sorting_Data"
                        @new-resize-width="resizedWidth"
                    >
                      {{ i.label }}</hlx-table-head
                    >

                    <hlx-table-head
                     v-if="permisstionData.edit || permisstionData.delete"
                      v-show="editMode == true"
                      :align="'center'"
                      :width="60"
                      :fixed="'right'"
                        @new-resize-width="resizedWidth"
                      ><span class="action-icon"
                        ><i
                          class="icon-settings-regular"
                          @click="reAssignOrgTableHeader()"
                        ></i></span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr v-for="(i, index) in paginatedData" id="" :key="index">
                      <hlx-table-cell :align="'center'" :fixed="'left'">
                        {{ serialNumber(index) }}
                      </hlx-table-cell>
                      <hlx-table-cell
                        v-for="(j, col_index) in theads"
                        :key="col_index"
                        :align="j.align"
                      >
                        <!-- {{i}} -->
                        <!-- {{j.prop}} -->
                        <div
                          v-if="
                            [
                              'status',
                              'type',
                              'name',
                              'updatedAt',
                              'executionCount',
                              'averageDuration',
                            ].includes(j.prop) === true
                          "
                        >
                          <div
                            v-if="j.prop === 'name' &&  !permisstionData.edit"
                            :id="j.prop + index"
                           
                            style="
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                            :style="{
                              width: '150px',
                            }"
                            
                            @mouseover="
                              tooltipover(
                                j.prop + index,
                                'tooltip-text' + j.prop + index
                              )
                            "
                            @mouseleave="
                              tooltipleave(
                                j.prop + index,
                                'tooltip-text' + j.prop + index
                              )
                            "
                          >
                            {{
                              i[j.prop].charAt(0).toUpperCase() +
                              i[j.prop].slice(1)
                            }}
                            <p
                              id="tooltip-text"
                              :class="'tooltip-text' + j.prop + index"
                            >
                              {{ i[j?.prop] }}
                            </p>
                          </div>
                          <div
                            v-if="j.prop === 'name' && permisstionData.edit"
                            :id="j.prop + index"
                            class="link-name"
                            style="
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                            :style="{
                              width: '150px',
                            }"
                            @click="viewPage(i)"
                            @mouseover="
                              tooltipover(
                                j.prop + index,
                                'tooltip-text' + j.prop + index
                              )
                            "
                            @mouseleave="
                              tooltipleave(
                                j.prop + index,
                                'tooltip-text' + j.prop + index
                              )
                            "
                          >
                            {{
                              i[j.prop].charAt(0).toUpperCase() +
                              i[j.prop].slice(1)
                            }}
                            <p
                              id="tooltip-text"
                              :class="'tooltip-text' + j.prop + index"
                            >
                              {{ i[j?.prop] }}
                            </p>
                          </div>
                          <span v-if="j.prop === 'type'">
                            <!-- {{i[j.prop]}} -->
                            <span
                              v-if="
                                i[j.prop] != 'rule' && i[j.prop] != 'decision'
                              "
                            >
                              {{
                                i[j.prop].charAt(0).toUpperCase() +
                                i[j.prop].slice(1)
                              }}
                            </span>
                            <span v-else-if="i[j.prop] == 'decision'">
                              {{ "Decision tables" }}
                            </span>
                            <span v-else>
                              {{ "Logical rule" }}
                            </span>
                          </span>
                          <span v-if="j.prop === 'updatedAt'">
                            {{ convertdateformat(i[j.prop]) }}
                          </span>
                          <div>
                            <hlx-status :type="i[j.prop]"></hlx-status>
                          </div>
                          <div v-if="j.prop === 'executionCount'">
                            {{ i[j.prop] ? i[j.prop] : 0 }}
                            <!-- <span v-for=" (l,ind) in i['executionLogs']" :key="ind">
                      <span v-if="ind == i['executionLogs'].length-1">
                        {{l.executionCount}}
                      </span>
                    </span> -->
                          </div>
                          <div v-if="j.prop === 'averageDuration'">
                            <!-- {{i[j.prop] + ' ms'}} -->
                            {{
                              i[j.prop]
                                ? Math.round(
                                    (i[j.prop] + Number.EPSILON) * 10
                                  ) / 10
                                : 0
                            }}
                            ms

                            <!-- <span v-for=" (l,ind) in i['executionLogs']" :key="ind">
                      <span v-if="ind == i['executionLogs'].length-1">
                        {{l.averageDuration}}
                      </span>
                    </span> -->
                          </div>
                        </div>
                        <div
                          v-else-if="j.prop.toLowerCase() === 'description'"
                          :id="j.prop + index"
                          style="
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          :style="
                            j.width === null
                              ? {
                                  width: '180px',
                                }
                              : ''
                          "
                          @mouseover="
                            tooltipover(
                              j.prop + index,
                              'tooltip-text' + j.prop + index
                            )
                          "
                          @mouseleave="
                            tooltipleave(
                              j.prop + index,
                              'tooltip-text' + j.prop + index
                            )
                          "
                        >
                          {{ i[j.prop] }}
                          <p
                            id="tooltip-text"
                            :class="'tooltip-text' + j.prop + index"
                          >
                            {{ i[j?.prop] }}
                          </p>
                        </div>
                        <div v-else>
                          {{ i[j.prop] }}
                        </div>
                      </hlx-table-cell>
                      <hlx-table-cell
                       v-if="permisstionData.edit || permisstionData.delete"
                        v-show="editMode == true"
                        :align="'center'"
                        :width="60"
                        :fixed="'right'"
                      >
                        <span
                          :id="'table-context' + serialNumber(index - 1)"
                          class="action-icon"
                          ><i
                            style="position: relative"
                            class="icon-more-vertical-filled"
                            :class="i.context === true ? 'active-action' : ''"
                            @click="
                              crudContextMenu($event, serialNumber(index - 1))
                            "
                          >
                            <hlx-context-menu
                              :top="containertop"
                              :left="containerleft"
                              :conditionvalue="150"
                              :conditiondown="2"
                              :conditionup="-38"
                              :options="computedTableAction(i)"
                              :data="index.toString()"
                              :show="i.context == true"
                              @chosen="closeCrudContextMenu($event, i)"
                            />
                            <!-- <span
                            v-else
                            style="position: absolute"
                          >
                            <hlx-context-menu
                              style="
                                z-index: 9000;
                                margin-right: 100px !important;
                                margin-top: -125px;
                                box-shadow: 0 0 20px 0 #d8d8d8;
                              "
                              :options="computedTableAction(i)"
                              :data="index.toString()"
                              :show="i.context == true"
                              @chosen="closeCrudContextMenu($event, i)"
                            />
                          </span> -->
                          </i>
                          <!-- <div class="custom-context-menu-container" :id="'custom-context-menu-container'+serialNumber(index)+this.unique"> -->
                          <!-- <div class="custom-context-menu-container"  v-for="(i,index) in this.optionsD" :key="index"> -->
                          <!-- <i :class="this.optionsD[index].icon" id="icon-value"></i> -->
                          <!-- <p class="hlx-select-option-value">{{this.optionsD[index][this.Name]}}</p> -->
                          <!-- </div> -->
                          <!-- </div> -->
                        </span>
                      </hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>
              <div v-else class="table-wrap">
                <hlx-table
                  :column-count="theads.length + 2"
                  :border="['table', 'header']"
                  :bold-headers="false"
                  :row-hover="false"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="theadsC"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head :width="60" :align="'left'"   @new-resize-width="resizedWidth">{{
                      "S.no"
                    }}</hlx-table-head>
                    <hlx-table-head
                      v-for="(i, index) in theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      style="position: sticky; top: 0px"
                      :width="i.width !== undefined ? i.width : ''"
                      :align="i.align !== undefined ? i.align : ''"
                      @sorting_func="sorting_Data"
                        @new-resize-width="resizedWidth"
                    >
                      {{ i.label }}
                    </hlx-table-head>
                    <hlx-table-head  v-if="permisstionData.edit || permisstionData.delete" :width="100"   @new-resize-width="resizedWidth">
                      <span class="action-icon"
                        ><i
                          class="icon-settings-regular"
                        @click="reAssignOrgTableHeader()"
                        ></i></span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell :colspan="theads.length + 2">{{
                        "No data"
                      }}</hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>
              <!-- <div class="table-wrap" v-else>
              {{'opop'}}
              <hlx-table
                :column-count="this.theads"
                :border="['table', 'header', 'horizontal']"
                :bold-headers="false"
                :row-hover="true"
                theme="grey"
                :striped-rows="false"
                :reArrange-columns="this.re_arrange"
                :re-arrange-heads="this.theads"
                @sorting_func="sorting_Data"
                @close-re-arrange="arrange"
                @apply-re-arranged-columns="applyColumns"
              >
                <template #thead>
                  <hlx-table-head :width="70" :align="'center'"
                    >S.no</hlx-table-head
                  >
                  <hlx-table-head
                    v-for="(i, index) in this.theads"
                    :key="index"
                    :prop="i.prop"
                    :align="i.align"
                    :sortable="i.sortable"
                    :resizable="i.resizable"
                    @sorting_func="sorting_Data"
                    style="position: sticky; top: 0px"
                    :width="i.width !== undefined ? i.width : ''"
                    >{{ i.label }}</hlx-table-head
                  >
                  <hlx-table-head :align="'center'"
                    ><span class="action-gear"
                      ><i
                        class="icon-settings-regular"
                        @click="arrange(true)"
                      ></i></span
                  ></hlx-table-head>
                </template>
                <template #tbody>
                  <tr v-for="(i, index) in paginatedData" :key="index" id="">
                    <hlx-table-cell :align="'center'" :width="70">
                      {{ serialNumber(index) }}
                    </hlx-table-cell>
                    <hlx-table-cell
                      v-for="(j, col_index) in this.theads"
                      :key="col_index"
                      :align="j.align"
                    >
                      <div
                        v-if="j.prop.toLowerCase() === 'status'"
                        style="width: 100%; display: flex; justify-content: left"
                      >
                        <span
                          v-if="i[j.prop].toLowerCase() === 'success'"
                          style="
                            width: 100%;
                            display: flex;
                            justify-content: left;
                          "
                          ><hlx-label class="primary">{{
                            i[j.prop].charAt(0).toUpperCase() + i[j.prop].slice(1)
                          }}</hlx-label></span
                        >
                        <span
                          v-if="i[j.prop].toLowerCase() === 'draft'"
                          style="
                            width: 100%;
                            display: flex;
                            justify-content: left;
                          "
                          ><hlx-label class="info">{{
                            i[j.prop].charAt(0).toUpperCase() + i[j.prop].slice(1)
                          }}</hlx-label></span
                        >
                        <span
                          v-if="i[j.prop].toLowerCase() === 'failed'"
                          style="
                            width: 100%;
                            display: flex;
                            justify-content: left;
                          "
                          ><hlx-label class="error">{{
                            i[j.prop].charAt(0).toUpperCase() + i[j.prop].slice(1)
                          }}</hlx-label></span
                        >
                      </div>
                      <div v-else>
                        {{ i[j.prop] }}
                      </div>
                    </hlx-table-cell>
                    <hlx-table-cell :align="'center'">
                      <span class="action-icon"
                        ><i
                          class="icon-more-vertical-filled"
                          @click="crudContextMenu($event, index)"
                          :class="
                            i.context['show'] === true ? 'active-action' : ''
                          "
                        ></i>
                      </span>
                      <hlx-context-menu
                        style="z-index: 9000; margin-top: 4px"
                        :options="this.crudOptions"
                        :data="index.toString()"
                        @chosen="closeCrudContextMenu($event, i)"
                        :show="i.context['show'] == true"
                      />
                    </hlx-table-cell>
                  </tr>
                </template>
              </hlx-table>
            </div> -->
            </div>
          </section>
        </div>
      </div>
      <div class="new-layout-footer">
        <div class="pagination-container">
          <hlx-pagination
            v-model:rows-per-page="externalRowsPerPage"
            :total="totalPageNo"
            enable-rows-per-page
            :rows-per-page-list="[10, 15, 20]"
            :options="{
              attributes: {
                rowsPerPageTitle: true,
                rowsPerPageResult: true,
                rowsPerPageDropdown: {
                  position: {
                    top: 0,
                    right: 1,
                  },
                },
              },
            }"
            @current-page="currentPage"
            @updated:rows-per-page="changeRowsPerPage"
          ></hlx-pagination>
        </div>
      </div>
    </section>
    <div style="display: flex">
      </div>
  </div>
</template>

<script>
import { DMNAxiosInstance, MLTAxiosInstance } from "@/config/axiosConfig";
// import FilterComponent from '../../../components/filterPanelComponent.vue';
export default {
  components: {
    // FilterComponent,
  },
  props: {
    value: {
      type: String,
      default: "All",
    },
  },
  data() {
    return {
      permisstionData :{},

      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      card: [
        {
          card_title: "Simple",
          card_body:
            "Create rule with simple conditions for string, integer and boolean type variables",
        },
        {
          card_title: "Advanced",
          card_body:
            "Create rules with range based conditions or custom conditions for array variables",
        },
      ],
      totalPageNo: 0,
      showRuleType: false,
      ruleStudioquery: {
        type: "filter",
        module: "",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      externalRowsPerPage: 10,
      sliderfilter: false,
      filterprice: {
        min: 0,
        max: 0,
        key: "averageDuration",
      },
      sliderMin: 0,
      sliderMax: 0,
      filtertagvalue: [],
      filterdata: {},
      containerleft: 0,
      containertop: 0,
      containerleft1: 0,
      containertop1: 0,
      modal_active6: false,
      dropdown_data: [
        { name: "AND", value: "AND" },
        { name: "Between", value: "Between" },
        { name: "OR", value: "OR" },
      ],
      show_right: false,
      token: this.$route.params.token,
      editMode: true,
      editPermission: [],
      viewPermission: [],
      unique: Math.floor(Math.random() * 1000 + 1),
      delete_modal_active: false,
      searchtabledata: [],
      // businessrulepagespinner: true,
      // businessrulepagetable: false,
      duplicatetabledata: [],
      config_nav_items: [],
      delete_name: "",
      datum: {},
      success: false,
      success_msg: "",
      notification: {},
      expressions: [],
      view: "Schemas",
      schema_type_switch: [
        { name: "All", value: "all" },
        { name: "Decision tables", value: "Decision tables" },
        { name: "Decision matrix", value: "Decision matrix" },
        { name: "Expressions", value: "expressions" },
        { name: "Logical rules", value: "logical rules" },
      ],
      selectedValue: "All",
      show_add_menu: false,
      show_crud_menu: false,
      add_context_options: [
        {
          label: "Expression",
          value: "expression",
          icon: "icon-code-regular",
        },
        {
          label: "Decision tables",
          value: "decision-table",
          icon: "icon-layout-web-12-regular",
        },
        {
          label: "Decision matrix",
          value: "decision-matrix",
          icon: "icon-layout-web-12-regular",
        },
        {
          label: "Logical rule",
          value: "rule",
          icon: "icon-desktop-code-regular",
        },
      ],
      crudOptions: [
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
        {
          label: "Execute",
          icon: "icon-file-code-regular",
        },
        {
          label: "View dependency",
          icon: "icon-eye-regular",
        },
      ],
      theadsC:[
      {
          name: "Name",
          checked: true,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: true,
          resizable: true,
          width: null,
          align: "left",
        },
        {
          name: "Description",
          checked: true,
          id: 2,
          disabled: false,
          prop: "description",
          label: "Description",
          sortable: true,
          resizable: true,
          width: 150,
          align: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 5,
          disabled: true,
          prop: "type",
          label: "Type",
          sortable: true,
          resizable: true,
          width: 100,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 4,
          disabled: false,
          prop: "status",
          label: "Status",
          sortable: true,
          resizable: true,
          width: 90,
          align: "left",
        },
        {
          name: "Average duration",
          checked: true,
          id: 3,
          disabled: false,
          prop: "averageDuration",
          label: "Average duration",
          sortable: true,
          resizable: true,
          width: 145,
          align: "center",
        },

        {
          name: "Execution count",
          checked: true,
          id: 5,
          disabled: false,
          prop: "executionCount",
          label: "Execution count",
          sortable: true,
          resizable: true,
          width: 150,
          align: "center",
        },
        {
          name: "Last modified",
          checked: true,
          id: 5,
          disabled: false,
          prop: "updatedAt",
          label: "Last modified",
          sortable: true,
          resizable: true,
          width: 175,
          align: "left",
        },
      ],
      theads: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: true,
          resizable: true,
          width: null,
          align: "left",
        },
        {
          name: "Description",
          checked: true,
          id: 2,
          disabled: false,
          prop: "description",
          label: "Description",
          sortable: true,
          resizable: true,
          width: 150,
          align: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 5,
          disabled: true,
          prop: "type",
          label: "Type",
          sortable: true,
          resizable: true,
          width: 100,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 4,
          disabled: false,
          prop: "status",
          label: "Status",
          sortable: true,
          resizable: true,
          width: 90,
          align: "left",
        },
        {
          name: "Average duration",
          checked: true,
          id: 3,
          disabled: false,
          prop: "averageDuration",
          label: "Average duration",
          sortable: true,
          resizable: true,
          width: 145,
          align: "center",
        },

        {
          name: "Execution count",
          checked: true,
          id: 5,
          disabled: false,
          prop: "executionCount",
          label: "Execution count",
          sortable: true,
          resizable: true,
          width: 150,
          align: "center",
        },
        {
          name: "Last modified",
          checked: true,
          id: 5,
          disabled: false,
          prop: "updatedAt",
          label: "Last modified",
          sortable: true,
          resizable: true,
          width: 175,
          align: "left",
        },
      ],
      dup_theads: [
        {
          name: "Type",
          checked: true,
          id: 5,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: true,
          resizable: true,
          width: 100,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 4,
          disabled: false,
          prop: "status",
          label: "Status",
          sortable: true,
          resizable: true,
          width: 90,
          align: "left",
        },
        {
          name: "Average duration",
          checked: true,
          id: 3,
          disabled: false,
          prop: "averageDuration",
          label: "Average duration",
          sortable: true,
          resizable: true,
          width: 145,
          align: "center",
        },

        {
          name: "Execution count",
          checked: true,
          id: 5,
          disabled: false,
          prop: "executionCount",
          label: "Execution count",
          sortable: true,
          resizable: true,
          width: 150,
          align: "center",
        },
        {
          name: "Last modified",
          checked: true,
          id: 5,
          disabled: false,
          prop: "updatedAt",
          label: "Last modified",
          sortable: true,
          resizable: true,
          width: 152.3,
          align: "left",
          type: "date",
        },
      ],
      theads1: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "name",
          label: "Name",
          sortable: true,
          resizable: true,
          width: 152.3,
          align: "left",
        },
        {
          name: "Description",
          checked: true,
          id: 2,
          disabled: false,
          prop: "description",
          label: "Description",
          sortable: true,
          resizable: true,
          width: 172.3,
          align: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 5,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: true,
          resizable: true,
          width: 122.3,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 4,
          disabled: false,
          prop: "status",
          label: "Status",
          sortable: true,
          resizable: true,
          width: 122.3,
          align: "left",
        },
        {
          name: "Average duration",
          checked: true,
          id: 3,
          disabled: false,
          prop: "averageDuration",
          label: "Average duration",
          sortable: true,
          resizable: true,
          width: 132.3,
          align: "center",
        },

        {
          name: "Execution count",
          checked: true,
          id: 5,
          disabled: false,
          prop: "executionCount",
          label: "Execution count",
          sortable: true,
          resizable: true,
          width: 132.3,
          align: "center",
        },
        {
          name: "Last modified",
          checked: true,
          id: 5,
          disabled: false,
          prop: "updatedAt",
          label: "Last modified",
          sortable: true,
          resizable: true,
          width: 152.3,
          align: "left",
        },
      ],
      tableData: [],
      logData: [],
      re_arrange: false,
      currPage: 1,
      rowsPerPage: 10,
      // duplicateTableData: [],
      duplicateTheads: [],
      duplicateTheads1: [],
      searchvalue: "",
      id: "tenant1",
      name: "myself",
      deleteobj: {},
      filterobj: {},
      filtervalueobj: {},
      switchItemsnamevalue: "",
      show_right_filter: false,
    };
  },
  computed: {
    // records(){
    //   return this.$store.getters.allRule
    // },
    filteroptions() {
      return this.filterobj;
    },
    paginatedData() {
      return this.tableData;
      // //
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      // //
      // this.tableData.forEach((e) => {
      //   if (e.context !== undefined) {
      //     e.context.show = false;
      //   } else {
      //     e.context = { show: false };
      //   }
      // });
      // //
      // let a = this.tableData.slice(start, end)
      //       a.forEach((e) => {
      //   if (e.context !== undefined) {
      //     e.context.show = false;
      //   } else {
      //     e.context = { show: false };
      //   }
      // });
      // //
      // this.table_height = this.tableData.slice(start, end).length *53 +42;
      // return this.tableData.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  watch: {
    filterobj: {
      // handler(val) {
      // if(val != undefined)
      // {
      // }
      // }
    },
    immediate: true,
    deep: true,
  },
  async mounted() {
// To get table header

const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${val.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;


this.permisstionData =
  mergedPayload.DMN.child["Rule studio"];


let sampleTheads = await MLTAxiosInstance.get(`/settings`);

if (sampleTheads?.data?.entityRef?.["ruleStudio"]) {
  let offerTableHeadModal = sampleTheads.data.entityRef["ruleStudio"];
  let offerTableHeadOrg = sampleTheads.data.entityRef["ruleStudio"].filter(ele => ele.checked);
  console.log(offerTableHeadOrg,"offerTableHeadModal",offerTableHeadModal)

  if (
    offerTableHeadModal.length ===  this.theadsC.length 
    // &&
    // offerTableHeadModal.every((item, index) => item.name ===  this.theadsC[index].name)
  ) {
     this.theadsC = JSON.parse(JSON.stringify(offerTableHeadModal));
       this.theads = JSON.parse(JSON.stringify(offerTableHeadOrg));
       this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.theadsC ))
       this.tableHeaderOrg = JSON.parse(JSON.stringify(this.theads))
  }
}

    // this.duplicateTheads = JSON.parse(JSON.stringify(this.theads));
    this.duplicateTheads1 = JSON.parse(JSON.stringify(this.theads1));
    // let token = {
    //   headers: {
    //     Authorization: "Bearer  " + this.token,
    //   }
    // };

    // const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    this.company = val.data.company;
    this.userId = val.data.company;
    // const access = await MLTAxiosInstance.get(
    //   `partyRole/get/${val.data.company}/${val.data.userId}`
    // );

    // const access = await MLTAxiosInstance.get(
    //   `partyRole/get/${val.data.userId}`
    // );

    // let arr = access.data;

    // this.editPermission = [];
    // if (arr && Object.keys(arr).length > 0) {
    //   if (arr.permission.User_settings.edit == false) {
    //     this.editPermission.push("setting");
    //   }
    //   if (arr.permission.User_settings.view == false) {
    //     this.viewPermission.push("setting");
    //   }
    //   if (arr.permission.Schema.edit == false) {
    //     this.editPermission.push("schema");
    //   }
    //   if (arr.permission.Schema.view == false) {
    //     this.viewPermission.push("Schema");
    //   }
    //   if (arr.permission.Test_data.view == false) {
    //     this.viewPermission.push("Database", "Execute");
    //   }
    //   if (arr.permission.Test_data.edit == false) {
    //     this.editPermission.push("testdata");
    //   }
    //   if (arr.permission.Rules.view == false) {
    //     this.viewPermission.push("Business rule");
    //   }
    //   if (arr.permission.Rules.edit == false) {
    //     this.editPermission.push("Business rule");
    //   }
    //   if (this.editPermission.includes("Business rule") == true) {
    //     this.editMode = false;
    //   }
    // }

    document.addEventListener("click", (event) => {
      if (!event.target.classList.contains("icon-more-vertical-filled")) {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    });
    //
    let currenttab = this.value;
    let view = "";
    this.schema_type_switch.forEach((e) => {
      if (e.name.toLowerCase() === currenttab.toLowerCase()) {
        e.checked = true;
        view = e.name;
      } else {
        e.checked = false;
      }
    });
    // this.selectedValue = this.schema_type_switch[0].name
    //
    switch (view) {
      case "Expressions":
        this.ruleStudioquery.module = "expression";
        this.currPage = 1;
        this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
        this.selectedValue = "Expressions";
        //
        break;
      case "Logical rules":
        this.ruleStudioquery.module = "logicalrule";
        this.currPage = 1;
        this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
        this.selectedValue = "Logical rules";
        break;
      case "Decision tables":
        this.ruleStudioquery.module = "decision";
        this.currPage = 1;
        this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
        this.selectedValue = "Decision tables";
        break;
      case "Decision matrix":
        this.ruleStudioquery.module = "rulestudio";
        this.currPage = 1;
        this.ruleStudioDataCalling(this.currPage, this.rowsPerPage, true);
        this.selectedValue = "Decision matrix";
        break;
      case "All":
        this.ruleStudioquery.module = "rulestudio";
        this.currPage = 1;
        this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
        this.selectedValue = "All";
        break;
    }
    this.duplicatetabledata = this.tableData;
    this.searchtabledata = this.tableData;
    this.$store.commit("token", this.$route.params.token);
    this.filterdatafunction();

    document.addEventListener("click", (event) => {
      if (!event.target.classList.contains("add-btn")) {
        this.show_add_menu = false;
      }
      if (!event.target.classList.contains("icon-more-vertical-filled")) {
        // const elements = document.querySelectorAll("[class*=crud]");
        // for (const element of elements) {
        //   element.style.display = "none";
        // }
        // this.tableData.forEach((e) => {
        //   if (e.context !== undefined) {
        //     e.context.show = false
        //   }else{
        //     e.context.show = false
        //   }
        // })
      }
    });
    //  //
  },
  methods: {
    reAssignOrgTableHeader(){
            // If there is change in the table header then ressign to the Orginal data
      if((this.theadsC != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.theadsC = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.re_arrange = true
    },
    resizedWidth(val){
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.theads);
  updateWidth(this.theadsC);
console.log(this.theads,"resizedWidth loop",this.theadsC)
this.applyColumns(this.theadsC)
    },
    chosenTile(index) {
      if (index == 0) {
        this.$router.push(`/DMN/rule_studio/logical_rule`);
      } else {
        this.$router.push(`/DMN/rule_studio/advanced_logical_rule`);
      }
    },
    async ruleStudioDataCalling(
      ruleStudiocurrPage,
      ruleStudiorowsPerPage,
      isMatrix = false
    ) {
      this.ruleStudioquery["paginatedQuery"]["currentPage"] =
        ruleStudiocurrPage;
      console.log("true");
      if (isMatrix == true) {
        this.ruleStudioquery["filterQuery"]["type"] = "decisionMatrix";
      } else {
        this.ruleStudioquery["filterQuery"] = {};
        // "decisionMatrix"
      }
      this.ruleStudioquery["paginatedQuery"]["rowsPerPage"] =
        ruleStudiorowsPerPage;
      this.ruleStudioquery["company"] = this.company;

      let filterResultData = await DMNAxiosInstance.post(
        `util/filterData`,
        this.ruleStudioquery
      );
      if (filterResultData.data.ok == 1) {
        filterResultData = filterResultData.data;
        this.totalPageNo = filterResultData.data.total;
        this.tableData = filterResultData.data.data;
        this.duplicate_table_data = this.tableData;
      }
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },
    async clearallfilter() {
      this.filtertagvalue.forEach((e) => {
        e.checked = false;
      });
      this.filtertagvalue = [];
      this.sliderfilter = false;
      if (this.filtertagvalue.length == 0) {
        let currenttab = this.value;
        let view = "";
        this.schema_type_switch.forEach((e) => {
          if (e.name.toLowerCase() === currenttab.toLowerCase()) {
            e.checked = true;
            view = e.name;
          } else {
            e.checked = false;
          }
        });
        // this.selectedValue = this.schema_type_switch[0].name
        //
        switch (view) {
          case "Expressions":
            var alldata = await DMNAxiosInstance.get(
              `rule_engine/rule?type=expression`
            );
            this.tableData = await alldata.data.data;
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.currPage = 1;
            this.paginatedData;
            //
            this.selectedValue = "Expressions";
            break;
          case "Logical rules":
            var allrule = await DMNAxiosInstance.get(
              `rule_engine/rule?type=rule`
            );
            this.tableData = await allrule.data.data;
            // .filter(item=> item.type.toLowerCase() == 'rule' )
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.currPage = 1;
            this.paginatedData;
            this.selectedValue = "Logical rules";
            break;
          case "Decision tables":
            var alldecision = await DMNAxiosInstance.get(
              `rule_engine/rule?type=decision`
            );
            this.tableData = await alldecision.data.data;
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            //
            // .filter(item=> item.type.toLowerCase() == 'decision' )

            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.currPage = 1;
            this.paginatedData;
            this.selectedValue = "Decision tables";
            break;
          case "All":
            var all = await DMNAxiosInstance.get(`rule_engine/rule`);
            this.tableData = await all.data.data;
            // console.log(this.tableData,'aa');
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.currPage = 1;
            this.paginatedData;
            this.selectedValue = "All";
            break;
        }
      }
    },
    async removedfilterdata(value) {
      this.filtertagvalue.forEach((e, index) => {
        if (e.value == value.value && e.key == value.key) {
          this.filtertagvalue.splice(index, 1);
        }
        if (value.key == "averageDuration") {
          this.sliderfilter = false;
        }
      });
      Object.keys(this.filterdata).forEach((e) => {
        if (
          value.key == e &&
          value.key != "averageDuration" &&
          value.key != "updatedAt"
        ) {
          this.filterdata[e]?.forEach((ele) => {
            if (ele.value == value.value) {
              ele.checked = false;
            }
          });
        }
      });

      let obj = {};
      obj.filter = this.filtertagvalue;
      if (this.filtertagvalue.length != 0) {
        const filtertabledata = await DMNAxiosInstance.post(
          `rule_engine/rule/filterdata`,
          obj
        );
        this.tableData = filtertabledata.data;
      } else {
        let currenttab = this.value;
        let view = "";
        this.schema_type_switch.forEach((e) => {
          if (e.name.toLowerCase() === currenttab.toLowerCase()) {
            e.checked = true;
            view = e.name;
          } else {
            e.checked = false;
          }
        });
        // this.selectedValue = this.schema_type_switch[0].name
        //
        switch (view) {
          case "Expressions":
            var alldata = await DMNAxiosInstance.get(
              `rule_engine/rule?type=expression`
            );
            this.tableData = await alldata.data.data;
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.currPage = 1;
            this.paginatedData;
            //
            this.selectedValue = "Expressions";
            break;
          case "Logical rules":
            var allrule = await DMNAxiosInstance.get(
              `rule_engine/rule?type=rule`
            );
            this.tableData = await allrule.data.data;
            // .filter(item=> item.type.toLowerCase() == 'rule' )
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.currPage = 1;
            this.paginatedData;
            this.selectedValue = "Logical rules";
            break;
          case "Decision tables":
            var alldecision = await DMNAxiosInstance.get(
              `rule_engine/rule?type=decision`
            );
            this.tableData = await alldecision.data.data;
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            //
            // .filter(item=> item.type.toLowerCase() == 'decision' )

            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.currPage = 1;
            this.paginatedData;
            this.selectedValue = "Decision tables";
            break;
          case "All":
            var all = await DMNAxiosInstance.get(`rule_engine/rule`);
            this.tableData = await all.data.data;
            // console.log(this.tableData,'aa');
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.currPage = 1;
            this.paginatedData;
            this.selectedValue = "All";
            break;
        }
      }
    },
    async sliderval(val1, val2) {
      // console.log('aajd');
      val1;
      val2;
      this.filterprice.checked = true;
      this.filterprice.value =
        this.filterprice.min + "-" + this.filterprice.max;
      // let obj = {};
      // obj.filter = this.filtertagvalue;
      if (this.sliderfilter == false) {
        this.filtertagvalue.push(this.filterprice);
        this.sliderfilter = true;
      }
      let obj = {};
      obj.filter = this.filtertagvalue;
      // obj.currentpage = this.filtercurrPage
      // obj.rowsperpage = this.filterrowsPerPage
      const filtertabledata = await DMNAxiosInstance.post(
        `rule_engine/rule/filterdata`,
        obj
      );
      // this.totalpageno = await filtertabledata.data.totallength
      this.tableData = filtertabledata.data;
      this.tableData?.map((e) => {
        if (e.context == undefined) {
          e.context = false;
        }
      });
    },
    async handleCurrentDate(val) {
      //  console.log(val,'dddd');
      val.key = "updatedAt";
      val.value = val.startDateTime + "-" + val.endDateTime;
      val.checked = true;
      this.filtertagvalue.push(val);
      let obj = {};
      obj.filter = this.filtertagvalue;
      // obj.currentpage = this.filtercurrPage
      // obj.rowsperpage = this.filterrowsPerPage
      const filtertabledata = await DMNAxiosInstance.post(
        `rule_engine/rule/filterdata`,
        obj
      );
      // this.totalpageno = await filtertabledata.data.totallength
      this.tableData = filtertabledata.data;
      this.tableData?.map((e) => {
        // console.log(e);
        if (e.context == undefined) {
          e.context = false;
        }
      });
    },
    insertSpaces(string) {
      let str = string
        .replace(/(_|-)/g, " ")
        .trim()
        .replace(/\w\S*/g, function (str) {
          return str.charAt(0).toUpperCase() + str.substr(1);
        })
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
      return str;
    },
    async filtercheckedvalue(key, value) {
      console.log(value,"filtercheckedvalue",key)
      if (value.checked == true) {
        this.filtertagvalue.push(value);
      } else if (value.checked == false) {
        this.filtertagvalue.forEach((e, index) => {
          if (e.value == value.value) {
            this.filtertagvalue.splice(index, 1);
          }
        });
      }
      let obj = {};
      obj.filter = this.filtertagvalue;
      if (this.filtertagvalue.length != 0) {
        const filtertabledata = await DMNAxiosInstance.post(
          `rule_engine/rule/filterdata`,
          obj
        );
        this.tableData = filtertabledata.data;
      } else {
        let currenttab = this.value;
        let view = "";
        this.schema_type_switch.forEach((e) => {
          if (e.name.toLowerCase() === currenttab.toLowerCase()) {
            e.checked = true;
            view = e.name;
          } else {
            e.checked = false;
          }
        });
        // this.selectedValue = this.schema_type_switch[0].name
        //
        switch (view) {
          case "Expressions":
            var alldata = await DMNAxiosInstance.get(
              `rule_engine/rule?type=expression`
            );
            this.tableData = await alldata.data.data;
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.currPage = 1;
            this.paginatedData;
            //
            this.selectedValue = "Expressions";
            break;
          case "Logical rules":
            var allrule = await DMNAxiosInstance.get(
              `rule_engine/rule?type=rule`
            );
            this.tableData = await allrule.data.data;
            // .filter(item=> item.type.toLowerCase() == 'rule' )
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.currPage = 1;
            this.paginatedData;
            this.selectedValue = "Logical rules";
            break;
          case "Decision tables":
            var alldecision = await DMNAxiosInstance.get(
              `rule_engine/rule?type=decision`
            );
            this.tableData = await alldecision.data.data;
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            //
            // .filter(item=> item.type.toLowerCase() == 'decision' )

            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.currPage = 1;
            this.paginatedData;
            this.selectedValue = "Decision tables";
            break;
          case "All":
            var all = await DMNAxiosInstance.get(`rule_engine/rule`);
            this.tableData = await all.data.data;
            // console.log(this.tableData,'aa');
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.currPage = 1;
            this.paginatedData;
            this.selectedValue = "All";
            break;
        }
      }
    },
    async filterdatafunction() {
      const alldata = await DMNAxiosInstance.get(`rule_engine/rule/filter`);
      this.filterdata = alldata.data;
      this.filterprice.min = this.filterdata["averageDuration"].min;
      this.filterprice.max = this.filterdata["averageDuration"].max;
      this.sliderMin = this.filterdata["averageDuration"].min;
      this.sliderMax = this.filterdata["averageDuration"].max + 1;
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = objectDate.toLocaleString("default", { month: "short" });
          let year = objectDate.getFullYear();
          let dateformat = month + " " + date + ", " + year;
          return dateformat;
        }
      }
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    filterFunction() {
      this.dup_theads.forEach((ele) => {
        let arr = [];
        let arr1 = [];
        this.tableData.forEach((e) => {
          arr.push(e[ele.prop]);
          arr.forEach((element) => {
            if (!arr1.includes(element)) {
              arr1.push(element);
            }
          });
        });
        this.filterobj[ele.prop] = arr1;
      });
      this.dup_theads.forEach((e) => {
        //
        let arr = [];
        this.filterobj[e.prop].forEach((ele) => {
          let obj = {};
          if (e.type == "date") {
            obj["value"] = ele;
            obj["name"] = this.formatDate(ele);
          } else {
            obj["value"] = ele;
            obj["name"] = ele;
          }
          obj.checked = true;
          obj.disabled = false;
          arr.push(obj);
        });
        this.filterobj[e.prop] = arr;
      });
    },
    filterFunctionality() {
      this.filterFunction();
      this.show_right = true;
    },
    selectedvalue($event, data) {
      let finaldata = [];
      //
      //
      let arr_data = $event;

      let arr = [];
      arr_data.forEach((e) => {
        if (e.checked == true) {
          //
          arr.push(e.value);
        }
      });
      this.filtervalueobj[data] = arr;

      Object.keys(this.filtervalueobj).forEach((e) => {
        finaldata = [];
        this.filtervalueobj[e].forEach((ele) => {
          this.tableData.forEach((element) => {
            if (element[e] === ele) {
              finaldata.push(element);
            } else if (element[e] === null) {
              finaldata.push(element);
            }
          });
        });
      });
      this.tableData = finaldata;
      //
      if (Object.keys(this.filtervalueobj).length <= 1) {
        this.filterFunction();
        //
      } else {
        let x = 0;
        let y = 0;
        while (x < Object.keys(this.filterobj).length) {
          while (y < Object.keys(this.filtervalueobj).length) {
            if (
              Object.keys(this.filterobj)[x] !=
              Object.keys(this.filtervalueobj)[y]
            ) {
              var arr5 = [];
              let arr6 = [];
              let arr7 = [];
              finaldata.forEach((element) => {
                arr5.push(element[Object.keys(this.filterobj)[x]]);
              });
              arr5.forEach((element) => {
                if (!arr6.includes(element)) {
                  arr6.push(element);
                }
              });
              arr6.forEach((element) => {
                let obj = {};
                //
                // if(element != null)
                // {
                obj["value"] = element;
                obj["name"] = element;
                obj.checked = true;
                obj.disabled = false;
                arr7.push(obj);
              });
              this.filterobj[Object.keys(this.filterobj)] = arr7;
            }
            y++;
          }
          x++;
        }
        //    Object.keys(this.filterobj).forEach((e)=>{
        //     Object.keys(this.filtervalueobj).forEach((ele)=>{
        //       //
        //       if(e != ele)
        //       {
        //         var arr5 = []
        //         let arr6 = []
        //         let arr7 = []
        //         finaldata.forEach((element)=>{
        //           //
        //           arr5.push(element[e]);
        //         })
        //         arr5.forEach((element)=>{
        //           if (!arr6.includes(element)) {
        //                 arr6.push(element);
        //             }
        //         })
        //         arr6.forEach((element)=>{
        //           let obj = {}
        //           //
        //           // if(element != null)
        //           // {
        //             obj['value'] = element
        //             obj['name'] = element
        //           // }
        //           // else{
        //           //   obj['value'] = '(blanks)'
        //           //   obj['name'] = '(blanks)'
        //           // }
        //           obj.checked = true
        //           obj.disabled = false
        //           arr7.push(obj)
        //         })
        //        this.filterobj[e] = arr7
        //         //
        //       }
        //       else{
        //         if(ele == data)
        //         {
        //             this.filterobj[data] = $event
        //         }
        //         else{
        //           //
        //           var arr3 =[]
        //           var arr4 = []
        //           var arr10 = []
        //             $event.forEach((element)=>{
        //               if(element.checked == true)
        //               {
        //                 //
        //                 this.searchtabledata.forEach((elem)=>{
        //                   if(element.value ==  elem[data])
        //                   {
        //                     //
        //                     arr3.push(elem[ele])
        //                   }
        //                 })
        //               }
        //             })
        //             //
        //             arr3.forEach((element)=>{
        //               if (!arr4.includes(element)) {
        //                 arr4.push(element);
        //                   }
        //             })
        //                   //
        //                    arr4.forEach((element)=>{
        //                     let obj3={}
        //                         if(element != null)
        //                         {
        //
        //                           obj3['name'] = element
        //                           obj3['value'] = element
        //                           obj3.checked = false
        //                           obj3.disabled = false
        //                           //
        //                           //
        //                           arr10.push(obj3)
        //                         }
        //                        else{
        //                         obj3['value'] = '(blanks)'
        //                         obj3['name'] = '(blanks)'
        //                       obj3.checked = true
        //                       obj3.disabled = false
        //                        arr10.push(obj3)
        //                       }

        //                    })
        //
        //             // arr10.forEach((element)=>{
        //               let count = 0
        //               let i = 0;
        //               let j = 0;
        //               while(i<this.filtervalueobj[ele].length)
        //               {
        //                 j=0;
        //                 count = 0
        //                 while(j<arr10.length)
        //                 {
        //
        //                   if(this.filtervalueobj[ele][i] === arr10[j].value)
        //                   {
        //                     count = count+1
        //                   }
        //                   else{
        //                     j
        //                   }
        //                   if(count == 1)
        //                   {
        //                     //
        //                     arr10[j].checked = true
        //                     break;
        //                   }
        //                   j=j+1;
        //                 }
        //                 i=i+1;
        //               }
        //             this.filterobj[ele] = arr10
        //
        //         }
        //       }
        //     })
        //    })
      }
      this.filterobj[data] = $event;
    },
    closeRight() {
      this.show_right = false;
    },
    computedTableAction(data) {
      let options = [
    { label: "Edit", icon: "icon-file-text-edit-regular" },
    { label: "Execute", icon: "icon-file-code-regular" },
    { label: "View dependency", icon: "icon-eye-regular" },
    { label: "Draft", icon: "icon-file-text-edit-regular" },
    { label: "Mark as active", icon: "icon-file-text-edit-regular" },
    { label: "Mark as In-active", icon: "icon-file-text-edit-regular" },
    { label: "Delete", icon: "icon-trash-regular" },
  ];

  // Filter based on data status
  options = options.filter((option) => {
    if (!data || !data.status) return true; // If no status, include all options
    const status = data.status.toLowerCase();

    const invalidOptions = {
      draft: ["Draft", "Mark as In-active"],
      active: ["Draft", "Mark as active"],
      inactive: ["Draft", "Mark as In-active"],
    };

    return !(invalidOptions[status] || []).includes(option.label);
  });

  // Filter based on permissions
  if (!this.permisstionData.edit && !this.permisstionData.delete) {
    return options; // No additional filtering
  }

  return options.filter((option) => {
    if (!this.permisstionData.edit && ["Edit"].includes(option.label)) return false;
    if (!this.permisstionData.delete && ["Delete"].includes(option.label)) return false;
    return true;
  });
 
    },
    getWidth(id) {
      const descriptionDiv = document.getElementById(id);
      if (descriptionDiv) {
        // //
        return descriptionDiv.offsetWidth + "px";
      }
    },
   async applyColumns(val) {
      console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef || {};

concatedEntity.ruleStudio = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.theadsC= entityRef["ruleStudio"];
this.theads = this.theadsC.filter(ele => ele.checked);
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.theadsC ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.theads))
    },
    // Vue 3 method to update theads
    updateTheads() {
      // Use the map function to update the 'checked' property
      this.theads = this.theads.map((thead) => {
        // Check if the current thead has a matching 'prop' in duplicateTheads
        const isMatching = this.duplicateTheads.some(
          (duplicateThead) => duplicateThead.prop === thead.prop
        );

        // Set the 'checked' property based on whether it's matching
        return { ...thead, checked: isMatching };
      });
    },
    arrange(val) {
      // //
      this.re_arrange = val;
      // this.updateTheads();
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return new Intl.DateTimeFormat("en-US", options)
        .format(date)
        .replace(",", "");
    },
    navTo(active) {
      this.$store.commit("token", this.$route.params.token);
      //
      if (active.name == "Home") {
        this.navHome();
      } else if (active.name == "logout") {
        this.logout();
      } else if (active.name == "settings") {
        this.setting();
      } else if (active.name == "Schema") {
        this.schema();
      } else if (active.name == "Business rule") {
        this.ruled();
      } else if (active.name === "Database") {
        this.execute();
      } else if (active.name == "Help") {
        this.help();
      } else if (active.name == "Execute") {
        this.exe();
      } else {
        this.audit();
        //
      }
    },
    ruled() {
      this.$router.push(`/DMN/rule_studio`);
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {
      this.$router.push(`/DMN/schemas`);
    },

    // logout() {
    //   this.$router.push("/");
    // },
    setting() {
      this.$router.push(`/settings`);
    },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },
    async refreshTable() {
      //
      const alldata = await DMNAxiosInstance.get(`rule_engine/rule`);
      //       let data = await alldata.data.data.map((val)=>{

      //         // //
      //         if(val.executionLogs&&val.executionLogs.length!=0){
      //           return val
      //         }
      // //         else{
      // //           let add = {
      // //             action: "Execute rule:645d30b6b9bd62d5dde40ceb",
      // //            averageDuration:
      // // 0,
      // // category
      // // :
      // // "Rule",
      // // createdAt
      // // :
      // // "2023-05-11T18:21:40.755Z",
      // // createdBy
      // // :
      // // "639af00f9f515f57074584ef",
      // // executedDuration
      // // :
      // // 312,
      // // executionCount
      // // :
      // // 1,
      // // id
      // // :
      // // "645d30b6b9bd62d5dde40ceb",
      // // message
      // // :
      // // "Successfully executed",
      // // status
      // // :
      // // "Success",
      // // updatedAt
      // // :
      // // "2023-05-11T18:21:40.755Z"
      // //           }
      // //           // //
      // //           val.executionLogs[0]=add
      // //           return val
      // //         }
      //       })
      // //
      this.tableData = await alldata.data.data;
      // //
      this.tableData.forEach((e, index) => {
        e["index"] = index;
        e.context = false;
      });
      //
      // this.duplicatetabledata = await alldata.data.data;
      this.tableData = this.tableData.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      //

      this.paginatedData;
      //
      this.duplicatetableData = this.tableData;
      this.searchtabledata = this.tableData;
      this.duplicateTheads1 = this.theads1;
      // this.duplicateTheads = this.theads;
    },
    async closeCrudContextMenu($event, i) {
      // //
      if ($event.toLowerCase() == "edit") {
        if (i.type.toLowerCase() == "rule") {
          // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule/${i["_id"]}`;

          this.$router.push({
            name: "rule-edit",
            params: { id: i["_id"], token: `${this.$store.state.tokens}` },
          });
        }
        else if(i.type.toLowerCase() == "advanced") {
          // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule/${i["_id"]}`;

          this.$router.push({
            name: "advanced-rule-edit",
            params: { id: i["_id"], token: `${this.$store.state.tokens}` },
          });
        }
         else if (i.type.toLowerCase() == "expression") {
          this.$router.push({
            name: "expression-edit",
            params: { id: i["_id"], token: `${this.$store.state.tokens}` },
          });
          // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/expression/${i["_id"]}`;
        } else if (i.type.toLowerCase() == "decision") {
          // //
          this.decision_name = i.name;
          const alldata = await DMNAxiosInstance.get(
            `rule_engine/rule?type=decision`
          );
          //
          let obj = await alldata.data.data.find(
            (item) => item.name === this.decision_name
          );
          //
          // this.$router.push({ name: 'decision-edit', params:{ id: obj["_id"],token:$}})
          this.$router.push(
            `/DMN/rule_studio/decision_table/edit/${obj["decisionId"]}`
          );
          // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/decision_table/${obj["_id"]}`;
        }
      } else if ($event.toLowerCase() == "delete") {
        this.activatemodalactive(i);
        i.context = false;
      } else if ($event.toLowerCase() == "execute") {
        // if (
        //   i.type.toLowerCase() == "rule" ||
        //   i.type.toLowerCase() == "expression"
        // ) {
        this.$router.push({ name: "rule-execute", params: { id: i["_id"] } });
        // }
        i.context = false;
      } else if ($event.toLowerCase() == "view dependency") {
        if (
          i.type.toLowerCase() == "rule" ||
          i.type.toLowerCase() == "expression" ||
          i.type.toLowerCase() == "decision"
        ) {
          this.$router.push({
            name: "dep-tree-view",
            params: { id: i["_id"], name: i["name"] },
          });
        }
        i.context = false;
      } else if (
        $event &&
        ["Mark as In-active", "Mark as active"].includes($event)
      ) {
        let updatePayload = {
          ...i,
          status: $event == "Mark as In-active" ? "inactive" : "active",
        };

        await DMNAxiosInstance.patch(
          `rule_engine/rule/${i["_id"]}`,
          updatePayload
        );
        this.refreshTable();
      }
    },
    viewPage(i) {
      console.log("from business rules")

      if (i.type.toLowerCase() == "rule") {
        // this.$router.push(`/DMN/rule_studio/logical_rule/${i['_id']}`);
        this.$router.push({ name: "rule-view", params: { id: i["_id"] } });

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule/${i["_id"]}`;

        // this.$router.push({ name: 'rule-edit', params: { id: i["_id"] } })
      }
      else if (i.type.toLowerCase() == "advanced") {
        // this.$router.push(`/DMN/rule_studio/logical_rule/${i['_id']}`);
        this.$router.push({ name: "advanced-rule-view", params: { id: i["_id"] } });

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule/${i["_id"]}`;

        // this.$router.push({ name: 'rule-edit', params: { id: i["_id"] } })
      }
       else if (i.type.toLowerCase() == "expression") {
        // this.$router.push(`/DMN/rule_studio/expression/${i['_id']}`);  
        this.$router.push({
          name: "expression-view",
          params: { id: i["_id"] },
        });



        

        // this.$router.push({ name: 'expression-edit', params: { id: i["_id"] } })
        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/expression/${i["_id"]}`;
      }
      
      
      
      
      else if (i.type.toLowerCase() == "decisionmatrix") {
        // this.$router.push(`/DMN/rule_studio/expression/${i['_id']}`);
        this.$router.push({
          name: "decision-view",
          params: { id: i["_id"] },
        });



        

        // this.$router.push({ name: 'expression-edit', params: { id: i["_id"] } })
        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/expression/${i["_id"]}`;
      }
      
      
      
      
      
      else if (i.type.toLowerCase() == "decision") {
        // this.decision_name = i.name;
        // const alldata = await DMNAxiosInstance.get(
        //   `rule_engine/rule?id=${this.id}&name=${this.name}&type=decision`,
        //   {
        //     auth: {
        //       username: "testuser@test.com",
        //       password: " ",
        //     },
        //   }
        // );
        // //
        // let obj = await alldata.data.data.find(
        //   (item) => item.name === this.decision_name
        // );
        //
        // this.$router.push({ name: 'decision-edit', params:{ id: obj["_id"],token:$}})

        // this.$router.push(
        //   `/DMN/rule_studio/decision_table/${i.decisionId}/${i._id}`
        // );
        this.$router.push({
          name: "decision-view",
          params: { id: i["decisionId"] },
        });

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/decision_table/${obj["_id"]}`;
      }
    },
    async closeCrudContext(i) {
      // //
      if (i.type.toLowerCase() == "rule") {
        this.$router.push(`/DMN/rule_studio/logical_rule/${i["_id"]}`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule/${i["_id"]}`;

        // this.$router.push({ name: 'rule-edit', params: { id: i["_id"] } })
      } else if (i.type.toLowerCase() == "expression") {
        this.$router.push(`/DMN/rule_studio/expression/${i["_id"]}`);

        // this.$router.push({ name: 'expression-edit', params: { id: i["_id"] } })
        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/expression/${i["_id"]}`;
      } else if (i.type.toLowerCase() == "decision") {
        // this.decision_name = i.name;
        // const alldata = await DMNAxiosInstance.get(
        //   `rule_engine/rule?id=${this.id}&name=${this.name}&type=decision`,
        //   {
        //     auth: {
        //       username: "testuser@test.com",
        //       password: " ",
        //     },
        //   }
        // );
        // //
        // let obj = await alldata.data.data.find(
        //   (item) => item.name === this.decision_name
        // );
        //
        // this.$router.push({ name: 'decision-edit', params:{ id: obj["_id"],token:$}})

        this.$router.push(
          `/DMN/rule_studio/decision_table/edit/${i.decisionId}`
        );

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/decision_table/${obj["_id"]}`;
      }
    },
    activatemodalactive(i) {
      //

      this.delete_modal_active = true;
      this.deleteobj = i;
      this.delete_name = i.name;
    },
    async deleteExecRow() {
      this.delete_modal_active = false;
      // this.businessrulepagespinner = false
      let i = this.deleteobj;
      // this.delete_name = i.name
      //         setTimeout(() => {
      //   this.businessrulepagespinner = false
      //   this.businessrulepagetable = true
      // }, 300);
      if (
        i.type.toLowerCase() == "rule" ||
        i.type.toLowerCase() == "expression"
      ) {
        await DMNAxiosInstance.delete(`rule_engine/rule/${i["_id"]}`).then(
          () => {
            this.refreshTable();
            if (this.paginatedData.length === 1) {
              this.currPage = this.currPage - 1;
            }
            this.success = true;
            var str = i.type.charAt(0).toUpperCase() + i.type.slice(1);
            this.success_msg = `${str} deleted successfully`;
            this.notification = { type: "success", message: this.success_msg };
          }
        );
      } else {
        this.decision_name = i.name;
        let obj = this.tableData.find(
          (item) => item.name === this.decision_name
        );
        // //
        //
        await DMNAxiosInstance.delete(
          `rule_engine/rule/${i["_id"]}?type=decision&decision_id=${obj["decisionId"]}`
        ).then(async () => {
          if (this.paginatedData.length === 1) {
            this.currPage = this.currPage - 1;
          }
          this.refreshTable();
          this.success = true;
          this.success_msg = `Decision deleted successfully`;
          this.notification = { type: "success", message: this.success_msg };
        });
      }
    },
    crudContextMenu($event, index) {
      //
      this.containerleft =
        document
          .querySelector("#table-context" + index)
          ?.parentElement?.getBoundingClientRect()?.left - 165;
      this.containertop = document
        .querySelector("#table-context" + index)
        ?.parentElement?.getBoundingClientRect()?.top;
      if ($event.target.className === "icon-more-vertical-filled") {
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
        this.tableData[index].context = true;

        //
      } else {
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async handleCurrentChange(val) {
      //
      this.switchItemsnamevalue = val;
      // this.selectedValue = val.name
      console.log(val.name);
      // console.log(this.switchItemsnamevalue,"dftyguij");
      this.view = val.name;
      switch (val.name) {
        case "Expressions":
          this.ruleStudioquery.module = "expression";
          this.currPage = 1;
          this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
          //
          break;
        case "Logical rules":
          this.ruleStudioquery.module = "logicalrule";
          this.currPage = 1;
          this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
          break;
        case "Decision tables":
          this.ruleStudioquery.module = "decision";
          this.currPage = 1;
          this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
          break;
        case "Decision matrix":
          this.ruleStudioquery.module = "rulestudio";
          this.currPage = 1;
          this.ruleStudioDataCalling(this.currPage, this.rowsPerPage, true);
          break;
        case "All":
          this.ruleStudioquery.module = "rulestudio";
          this.currPage = 1;
          this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
          break;
      }
      this.duplicatetabledata = this.tableData;
      this.searchtabledata = this.tableData;
      // //
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
        this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
        console.log(this.tableData);
      }
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    changeRowsPerPage(count) {
      if (this.company != "") {
        this.rowsPerPage = count;
        this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
        console.log(this.tableData);
      }
    },
    resizewidthdata(data1, data2) {
      data1;
      data2;
      //
    },
    sorting_Data(data, data1) {
      //
      console.log(data, data1);
      this.ruleStudioquery.type = "filter";
      if (data === "icon-angle-up-small-filled sorting-icon") {
        this.ruleStudioquery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = 1;
        this.ruleStudioquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        this.ruleStudioquery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = -1;
        this.ruleStudioquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-up-small-filled") {
        this.ruleStudioquery.isNeedToBeSort = false;
        let obj = {};
        this.ruleStudioquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled") {
        this.ruleStudioquery.isNeedToBeSort = false;
        let obj = {};
        this.ruleStudioquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    // Contextmenu(index) {
    // if (event.target.className == "icon-more-vertical-filled") {
    //   this.tableData[index].context.show = true;
    // } else {
    //   // this.clicked = false;
    //   this.tableData.forEach((e) => {
    //     if (e.context != undefined) {
    //       e.context.show = false;
    //     }
    //   });
    // }
    // //
    // },
    closecontextmenu(data1, data2) {
      const indexvalue = parseInt(data2);
      indexvalue;
      //
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    updatedRowsPerPage(val) {
      this.rowsPerPage = val;
      //
    },
    // paginateData(data, pageNumber, rowsPerPage) {
    //   const startIndex = (pageNumber - 1) * rowsPerPage;
    //   const endIndex = startIndex + rowsPerPage;
    //   return data.slice(startIndex, endIndex);
    // },
    changePageNumber(pg) {
      this.pageNumber = pg;
    },
    searchKey(val) {
      //
      this.searchvalue = val;
      if (val != "") {
        this.ruleStudioquery["type"] = "search";
      } else {
        this.ruleStudioquery["type"] = "filter";
      }
      this.ruleStudioquery["searchQuery"] = this.searchvalue;
      this.currPage = 1;
      this.ruleStudioDataCalling(this.currPage, this.rowsPerPage);
    },
    showAddContextMenu() {
      this.containerleft1 =
        document.querySelector(".rule-add-btn").getBoundingClientRect().left -
        78;
      this.containertop1 =
        document.querySelector(".rule-add-btn").getBoundingClientRect().top +
        40;

      if (this.switchItemsnamevalue?.name === "Decision tables") {
        this.$router.push(`/DMN/rule_studio/decision_table/add`);
      } else if (this.switchItemsnamevalue?.name === "Expressions") {
        this.$router.push(`/DMN/rule_studio/expression`);
      } else if (this.switchItemsnamevalue?.name === "Logical rules") {
        this.$router.push(`/DMN/rule_studio/logical_rule`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule`;
      } else if (this.switchItemsnamevalue?.name === "All") {
        this.show_add_menu = !this.show_add_menu;
      } else {
        this.show_add_menu = !this.show_add_menu;
      }
    },
    fromAddContextMenu(val) {
      console.log(val, "value 2192");
      if (val === "Decision tables") {
        this.$router.push(`/DMN/rule_studio/decision_table/add`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/decision_table/add`;
      } else if (val === "Expression") {
        this.$router.push(`/DMN/rule_studio/expression`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/expression`;
      } else if (val === "Logical rule") {
        this.showRuleType = true;
        // this.$router.push(`/DMN/rule_studio/logical_rule`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule`;
      } else if (val === "Decision matrix") {
        this.$router.push(`/test-matrix-0511`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule`;
      }
    },
  },
};
</script>

<style></style>
