<template>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>

    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <hlx-drawer
    :show="isModal"
    position="right"
    width="400"
    :footer="true"
    :title="'Add attachment'"
    :show-close-icon="true"
    @close="closeDiscount"
  >
    <template #body>
      <div>
        <div
          class="header-area-contact-drawer"
          style="
            border-bottom: 1px solid #d4d4d4;
            padding-bottom: 5px;
            padding: 4px 0 13px 0;
          "
        >
          <b v-if="editMode === false">Add discount</b>
          <b v-if="editMode === true">Edit discount</b>
        </div>

        <section>
          <section style="">
            <section class="productDiscountSection" style="margin-top: 12px">
              <hlx-input
                v-model:value="discountNewDataEmit.name"
                :pre-val="discountNewDataEmit.name"
                :label-animation="true"
                label-value="Name"
                type="text"
                :clearable="true"
                :required="true"
              />
            </section>
            <section class="productDiscountSection">
              <hlx-input
                v-model:value="discountNewDataEmit.description"
                :pre-val="discountNewDataEmit.description"
                :label-animation="false"
                type="textarea"
                label-value="Description"
                :required="true"
                :clearable="false"
              />
            </section>
            <section class="productDiscountSection">
              <hlx-status
                :type="'select-status'"
                :status-option="statusList"
                :prevalue="discountNewDataEmit.lifecycleState.toLowerCase()"
                @emit-select="emitStatus"
              />
              <br />
              <hlx-select
                :key="keyagreementRef"
                :pre-value="discountNewDataEmit?.agreementRef[0]?.name"
                :options="agreementRefData"
                :required="true"
                prop-value="name"
                :label="'name'"
                :placeholder-value="'Select supplier contract'"
                :label-animation="true"
                :clearable="true"
                @selected-value="discountagreementRefselect"
              ></hlx-select>
            </section>
            <section class="productDiscountSection">
              <hlx-input
                v-model:value="discountNewDataEmit.validFor.startDate"
                :label-animation="true"
                :single-border="false"
                :label-value="'Start date'"
                :placeholder-value="'Start date'"
                :date-format="'dd (short month), yyyy'"
                type="date"
                :clearable="false"
                icon="calendar-regular"
                :pre-val="discountNewDataEmit.validFor?.startDate"
                icon-position="right"
                :disable-weekend="true"
                :start-year="2000"
                :display-error="false"
                :custom-error-message="'Please set a default value'"
                :custom-error="false"
                :end-year="2050"
                :holiday-list="holidays"
                @formattedDate="calenderValueStartDate"
              />
            </section>
            <section class="productDiscountSection">
              <hlx-input
                v-model:value="discountNewDataEmit.validFor.endDate"
                :label-animation="true"
                :single-border="false"
                :placeholder-value="'End date'"
                :label-value="'End date'"
                :date-format="'dd (short month), yyyy'"
                type="date"
                :clearable="false"
                icon="calendar-regular"
                :pre-val="discountNewDataEmit.validFor?.endDate"
                icon-position="right"
                :disable-weekend="true"
                :start-year="2000"
                :display-error="false"
                :custom-error-message="'Please set a default value'"
                :custom-error="false"
                :end-year="2050"
                :holiday-list="holidays"
                @formattedDate="calenderValueEndDate"
              />
            </section>

            <section class="productDiscountSection">
              <h5
                style="
                  font-family: OpenSans;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 19.07px;
                  margin-bottom: 16px;
                  margin-top: 8px;
                "
              >
                Product details
              </h5>
              <searchfilterComponent
                :key="updateKey"
                :dropdown-width="373"
                :module="'productoffering'"
                :filter-search-options="mainInfoNewDiscount"
                :filter-data="searchFilterData"
                :filter-checked-data="layout"
                :key-value-from-query-array="'label'"
                :filter-value-object-name="'name'"
                @removed-value="getRemovedValue"
                @searched-value="getSearchedValueNewDiscount"
                @checked-value="getcheckedValueNewDiscount"
              >
              </searchfilterComponent>
            </section>

            <div class="productDiscountSection">
              <span class="select-width-half" style="align-items: center">
                <span style="width: 26%"> Cost per unit = </span>

                <!-- <span  style="width: 39%">
                  <hlx-input
                    v-model:value="discountNewDataEmit.discountType.value"
                    :label-animation="false"
                    type="number"
                    :clearable="false"
                    :display-error="false"
                    :read-only="false"
                    :custom-error-message="'Please set a default value'"
                    :custom-error="false"
                    :pre-val="discountNewDataEmit.discountType.value"
                    :symbol="
                      $store?.getters?.getDefaultSettings?.currency?.symbol
                    "
                  />
                </span> -->
                <span style="width: 45%">
                  <hlx-input
                    :key="updateKey"
                    v-model:value="
                      discountNewDataEmit.maximumDiscountRules[0].value
                    "
                    :pre-val="
                      discountNewDataEmit?.maximumDiscountRules[0]?.value
                    "
                    :label-animation="true"
                    :placeholder-value="'Discount'"
                    :label-value="'Discount'"
                    type="mixed-number"
                    :disabled="false"
                    :clearable="true"
                    :suffix="true"
                    :prefix="false"
                  >
                    <template #suffix> %</template>
                  </hlx-input>
                </span>
                On MSRP
              </span>
            </div>
          </section>
        </section>
      </div>
      <hlx-modal
        :modal-active="exitoffring"
        :height="'220px'"
        :width="'400px'"
        :modal="true"
        @close="closeDiscount"
      >
        <template #header>
          <div style="margin-left: 0px">Existing Offers</div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              This product already has a discount applied. Make any changes
              needed before proceeding.
            </p>
          </div>
        </template>
        <template #footer>
          <hlx-button class="secondary sm" @click="closeDiscount"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 16px"
            @click="stayoffering"
            >stay</hlx-button
          >
        </template>
      </hlx-modal>
    </template>
    <template #footer>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding: 16px;
          border-top: 1px solid #d8d8d8;
        "
      >
        <hlx-button class="secondary sm" @click="closeDiscount"
          >Cancel</hlx-button
        >
        <hlx-button
          v-if="editMode == false"
          class="primary sm"
          style="margin-left: 10px"
          :disabled="discountButton"
          @click="addNewDiscount"
          >Add</hlx-button
        >
        <hlx-button
          v-else
          class="primary sm"
          style="margin-left: 10px"
          :disabled="adddiscountbutton"
          @click="editDiscount"
          >Save</hlx-button
        >
      </div>
    </template>
  </hlx-drawer>

  <div
    v-if="discountData.length > 0 || searchvalue !== ''"
    class="header-add-supplier"
  >
    <div class="tablehead-add-supplier">{{ tablehead }}</div>
    <div class="search-add-supplier">
      <span><hlx-search @search-key="productSearch"></hlx-search></span>
      <!-- <span
        ><hlx-button class="secondary sm"
          ><i class="icon-filter-regular"></i></hlx-button
      ></span> -->
      <span v-show="supplierStatus == false"
        ><hlx-button v-if="permisstionData.add" class="primary sm" @click="discountdrawer">
          <i class="icon-plus-circle-regular"></i>
          Add Discount
        </hlx-button></span
      >
    </div>
  </div>
  <div>
    <div
      v-if="discountData.length > 0"
      style="height: 78vh; overflow: auto"
      class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
    >
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header', 'horizontal']"
        :row-hover="true"
        theme="grey"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="accounts_table_theads_cc"
        @close-rearrange="arrangeColumns"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
                  @new-resize-width="resizedWidth"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
                  @new-resize-width="resizedWidth"
          >
            <span v-if="['MSRP', 'stockedProduct'].includes(i?.prop)">
              {{ i.label }} ({{
                $store?.getters?.getDefaultSettings?.currency?.unit
              }})
            </span>
            <span v-else>
              {{ i.label }}
            </span></hlx-table-head
          >
          <hlx-table-head
          v-if="permisstionData.edit || permisstionData.delete"
            v-show="supplierStatus == false"
            :align="'center'"
            :width="70"
            :fixed="'right'"
                  @new-resize-width="resizedWidth"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr v-for="(i, index) in paginatedData" id="" :key="index">
            <hlx-table-cell :align="'center'" :fixed="'left'">
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in accounts_table_theads"
              :key="col_index"
              :align="j.align"
              :fixed="j.fixed"
            >
              <div v-if="j.prop.toLowerCase() === 'validfor'">
                {{ convertdateformat(i.validFor.startDate) }}
              </div>

              <div v-else-if="j.prop.toLowerCase() === 'enddate'">
                {{ convertdateformat(i.validFor.endDate) }}
              </div>

              <div v-else-if="j.prop?.toLowerCase() === 'lifecyclestate'">
                <hlx-status :type="i[j.prop]"></hlx-status>
              </div>
              <div v-else-if="j.prop?.toLowerCase() === 'appliesto'">
                <hlx-tagcount
                  :arrayfile="i[j.prop]"
                  :item-key="'name'"
                  :type="'label'"
                ></hlx-tagcount>
              </div>
              <div
                v-else-if="j.prop?.toLowerCase() === 'maximumdiscountrules'"
                style="margin-left: 14px"
              >
                {{ i[j.prop][0]?.value }} %
              </div>
              <div v-else-if="j.prop?.toLowerCase() === 'agreementref'">
                {{ i[j.prop][0]?.name }}
              </div>

              <div v-else>
                {{ i[j.prop] }}
              </div>
            </hlx-table-cell>
            <hlx-table-cell
              v-show="supplierStatus == false"
              v-if="permisstionData.edit || permisstionData.delete"
              :align="'center'"
              :width="120"
              :fixed="'right'"
            >
              <span
                :id="'table-context' + serialNumber(index - 1)"
                class="action-icon"
              >
                <i
                  style="position: relative"
                  class="icon-more-vertical-filled"
                  :class="i.context === true ? 'active-action' : ''"
                  @click="crudContextMenu($event, serialNumber(index - 1))"
                >
                  <hlx-context-menu
                    :top="containertop"
                    :left="containerleft"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(i)"
                    :data="index.toString()"
                    :show="i.context == true"
                    @chosen="closeCrudContextMenu($event, i, index)"
                  />
                </i>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>
    <div
      v-if="discountData.length <= 0 && searchvalue === ''"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 70vh;
      "
    >
      <img
        class="temp-Product-illustration"
        src="@/assets/images/addDiscount.svg"
        alt="Product image"
        srcset=""
      />
      <span v-show="supplierStatus == false"
        ><hlx-button v-if="permisstionData.add" class="primary sm" @click="discountdrawer">
          <i class="icon-plus-circle-regular"></i>
          Add Discount
        </hlx-button></span
      >
    </div>
    <div
      v-if="discountData.length === 0 && searchvalue !== ''"
      class="cm-accounts-right-panel-top-section-table-wrap"
    >
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header']"
        :bold-headers="false"
        :row-hover="true"
        theme="grey"
        :striped-rows="false"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="theads"
        @close-rearrange="arrange"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head :width="60" :align="'left'"       @new-resize-width="resizedWidth">{{
            "S.no"
          }}</hlx-table-head>
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            style="position: sticky; top: 0px"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
                  @new-resize-width="resizedWidth"
          >
            {{ i.label }}
          </hlx-table-head>
          <hlx-table-head v-if="permisstionData.edit || permisstionData.delete" v-show="supplierStatus == false" :width="100"       @new-resize-width="resizedWidth">
            <span class="action-icon"
              ><i
                class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
              ></i></span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell :colspan="accounts_table_theads.length + 2">{{
              "No data"
            }}</hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>
  </div>
  <div v-if="discountData?.length > 10">
    <hlx-pagination
      v-model:rows-per-page="externalRowsPerPage"
      :total="discountData.length"
      rows-per-page
      enable-rows-per-page
      :rows-per-page-list="[10, 15, 20]"
      :options="{
        attributes: {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 0,
              right: 1,
            },
          },
        },
      }"
      @current-page="currentPage"
      @updated:rows-per-page="changeRowsPerPage"
    ></hlx-pagination>
  </div>
</template>
<script>
import {
  UPCAxiosInstance,
  // DMNAxiosInstance,
  MLTAxiosInstance,
} from "@/config/axiosConfig";

import searchfilterComponent from "@/components/SearchAndFilterComponent/searchAndFilter.vue";
export default {
  components: {
    searchfilterComponent,
  },
  props: {
    permisstionData: {
      type: Object,
      required: true, 
      default: () => {},
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },

    mode: {
      type: String,
      default: "edit",
    },
    supplierStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      discountButton: true,
      agreementRefData: [],
      editinpurvalue: 0,
      inputValues: [],
      modal_edit_cascode: false,
      payload: {},
      Specfilterquery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },

        filterQuery: {},
        searchQuery: "",
      },
      productdata: [],
      cascaderButton: true,
      totalPageNo: 0,
      SpecificationcurrPage: 1,
      SpecificationrowsPerPage: 10,
      Supplierfilterquery: {
        type: "search",
        module: "supplierManagement",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      stock: 0,
      msrp: "",
      cost: "",
      code: {
        reginTime: [
          {
            region: this.regioncode,
            leadTime: "",
          },
        ],
      },
      adddiscountbutton: false,
      mainInfoNewDiscount: [],
      isModal: false,
      prevalue2: "",
      ProductSelectValue: [],
      delete_modal_active: false,
      producttype: "manufacture",
      Varientid: "VAR-2024-ABC123",
      priceName: "CAD",
      unitofmeasure: "SKU-A9876",
      Offeringname: "732158",
      Offeringid: "QuantumTech Pro Series Router",
      SKUvalue: "Each",
      editMode: false,
      username: "GlobalTech Supplies",
      tagName: "Active",
      addressName: "123 Main Street Suite 456 Toronto, ON M5V 2B8, Canada",
      tablehead: "Discounts",
      externalRowsPerPage: 10,
      company: "",
      taxCategoryList: [],
      tableData: [
        {
          id: "732158",
          variantname: "QuantumTech Pro 5000X",
          sku: "SKU-12345",
          name: "QuantumTech Pro Series Router",
          stockquantity: "23",
          regioncode: "5 Regions",
          unitofmeasure: "Each",
          msrp: " $4699.99",
          cad: "$800.00",
        },
        {
          id: "732158",
          variantname: "QuantumTech Pro 5000X",
          sku: "SKU-12345",
          name: "QuantumTech Pro Series Router",
          stockquantity: "23",
          regioncode: "5 Regions",
          unitofmeasure: "Each",
          msrp: " $4699.99",
          cad: "$800.00",
        },
        {
          id: "732158",
          variantname: "QuantumTech Pro 5000X",
          sku: "SKU-12345",
          name: "QuantumTech Pro Series Router",
          stockquantity: "23",
          regioncode: "5 Regions",
          unitofmeasure: "Each",
          msrp: " $4699.99",
          cad: "$800.00",
        },
      ],
      IntangibleinputValues: 0,
      locationdata: {},
      dropdown_data1: [],
      statusList: [],
      Shiping: [
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Inactive" },
      ],
      editPayload: {
        name: "",
        place: {},
        productStockLevel: {
          unit: 0,
        },
      },
      discountNewDataEmit: {
        relatedParty: [],
        description: "",
        type: "Supplier discount",
        name: "",
        lifecycleState: "Initialized",
        validFor: {
          startDate: new Date(),
          endDate: "",
        },
        agreementRef: [{}],
        maximumDiscountRules: [{ value: 0 }],
        minimumDiscountRules: [],
        discountType: { name: "", value: "" },
        extarctedType: "",
        appliesTo: [],
        applyTo: [],
        isSpecified: false,
        specifiedAccounts: [],
      },
      rawdata: {
        relatedParty: [],
        description: "",
        type: "Supplier discount",
        name: "",
        lifecycleState: "Initialized",
        validFor: {
          startDate: new Date(),
          endDate: "",
        },
        maximumDiscountRules: [{ value: 0 }],
        minimumDiscountRules: [],
        discountType: { name: "", value: "" },
        extarctedType: "",
        appliesTo: [],
        applyTo: [],
        isSpecified: false,
        specifiedAccounts: [],
      },
      discountserarch: [],
      updateKey: 0,
      keyagreementRef: 0,
      accounts_table_theads_cc: [
        {
          sortable: false,
          resizable: true,
          width: null,
          label: "Name",
          name: "Name",
          prop: "name",
          align: "left",
          checked:true,
          disabled:true,
        },
        {
          sortable: false,
          resizable: true,
          width: 80,
          label: "Status",
          name: "Status",
          prop: "lifecycleState",
          align: "left",
          checked:true,
          disabled:false,
        },
        {
          sortable: false,
          resizable: true,
          width: 250,
          label: "Supplier contract",
          name: "Supplier contract",
          prop: "agreementRef",
          align: "left",
          checked:true,
          disabled:false,
        },
        {
          sortable: false,
          resizable: true,
          width: 110,
          label: "Start date",
          name: "Start date",
          prop: "validFor",
          align: "left",
          checked:true,
          disabled:true,
        },
        {
          sortable: false,
          resizable: true,
          width: 110,
          label: "End date",
          name: "End date",
          prop: "endDate",
          align: "left",
          checked:true,
          disabled:true,
        },

        {
          sortable: false,
          resizable: true,
          width: 100,
          label: "Discount",
          name: "Discount",
          prop: "maximumDiscountRules",
          align: "left",
          checked:true,
          disabled:true,
        },
        {
          sortable: false,
          resizable: true,
          width: 250,
          label: "Offers",
          name: "Offers",
          prop: "appliesTo",
          align: "left",
          checked:true,
          disabled:false,
        },
      ],
      accounts_table_theads: [
        {
          sortable: false,
          resizable: true,
          width: null,
          label: "Name",
          name: "Name",
          prop: "name",
          align: "left",
          checked:true,
          disabled:true,
        },
        {
          sortable: false,
          resizable: true,
          width: 80,
          label: "Status",
          name: "Status",
          prop: "lifecycleState",
          align: "left",
          checked:true,
          disabled:false,
        },
        {
          sortable: false,
          resizable: true,
          width: 250,
          label: "Supplier contract",
          name: "Supplier contract",
          prop: "agreementRef",
          align: "left",
          checked:true,
          disabled:false,
        },
        {
          sortable: false,
          resizable: true,
          width: 110,
          label: "Start date",
          name: "Start date",
          prop: "validFor",
          align: "left",
          checked:true,
          disabled:true,
        },
        {
          sortable: false,
          resizable: true,
          width: 110,
          label: "End date",
          name: "End date",
          prop: "endDate",
          align: "left",
          checked:true,
          disabled:true,
        },

        {
          sortable: false,
          resizable: true,
          width: 100,
          label: "Discount",
          name: "Discount",
          prop: "maximumDiscountRules",
          align: "left",
          checked:true,
          disabled:true,
        },
        {
          sortable: false,
          resizable: true,
          width: 250,
          label: "Offers",
          name: "Offers",
          prop: "appliesTo",
          align: "left",
          checked:true,
          disabled:false,
        },
      ],
      selectedvalueproduct: "",
      name: "",
      notification: [],

      searchFilterData: {},
      filterLayout: {
        // "@baseType": "supplier",
        layoutFor: "productoffering",
        filterQuery: {
          id: {
            $nin: [],
          },
        },
        layout: [
          {
            prop: "lifecycleStatus",
            label: "Status",
            type: "String",
            fieldToFilter: "lifecycleStatus",
          },
          {
            prop: "category",
            label: "Category",
            type: "Array",
            // fieldToFilter: "name"
          },
          {
            prop: "productOfferingPrice",
            label: "price",
            type: "Array",
            fieldToFilter: "name",
          },
        ],
      },
      layout: [
        {
          prop: "lifecycleStatus",
          label: "Status",
          type: "String",
          fieldToFilter: "lifecycleStatus",
          fieldToSearch: "lifecycleStatus",
        },
        {
          prop: "category",
          label: "Category",
          type: "Array",
          fieldToFilter: "category",
        },
        {
          prop: "productOfferingPrice",
          label: "price",
          type: "Array",
          fieldToFilter: "productOfferingPrice.name",
          fieldToSearch:
            "productOfferingPrice[0].productofferPriceAlteration[0].productprice.taxAmount.Amount",
        },
      ],
      selectcomponentkey: 0,
      featureObj: [],

      searchvalue: "",
      prductfilter: [],
      editproductPayload: {},
      editPayloadId: "",
      payloadProduct: {},
      duplicateTheads: [],
      re_arrange: false,
      currPage: 1,
      rowsPerPage: 10,
      productselect: {},
      filterPanelName: "productSpecification",
      modal_active: false,
      modal_active_cascode: false,
      modal_active_edit: false,
      regioncode: "",
      deleteobj: {},
      locationSelectValue: [],
      supplierdata: {},
      categoryInfo: {},
      filterdata: [],
      productdatafull: [],
      userId: "",
      removedSearchfiltervalur: [],
      discountData: [],
      copyOfDiscountTableData: [],
      offringData: [],
      exitoffring: false,
      exitpfferinglist: [],
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.discountData.slice(start, end);
    },
  },

  watch: {
    mainInfoNewDiscount: {
      handler(val) {
        this.mainInfoNewDiscount = val;
        console.log(val, "this.mainInfoNewDiscount");
      },
      immediate: true,
      deep: true,
    },

    prevalue2: {
      handler(val) {
        this.selectedproductFunc(val);
      },
      immediate: true,
      deep: true,
    },
    discountNewDataEmit: {
      handler(val) {
        console.log(val, "discountNewDataEmit");
        if (val.name && val.agreementRef[0].name) {
          this.discountButton = false;
        } else {
          this.discountButton = true;
        }
      },
      immediate: true,
      deep: true,
    },
    editinpurvalue: {
      handler(val) {
        console.log(val, "editinpurvalue");
      },
      immediate: true,
      deep: true,
    },
    discountData: {
      handler(val) {
        this.copyOfDiscountTableData = JSON.parse(JSON.stringify(val));
      },
      immediate: true,
      deep: true,
    },
    selectedvalueproduct: {
      async handler(val) {
        console.log(val, "editinpurvalue");

        if (val.length > 0) {
          const qureydata = {
            type: "filter",
            module: "productOffering",
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 10,
            },
            isNeedToBeSort: false,
            sortFieldAndOrder: {
              // "name":-1
            },

            filterQuery: {
              name: { $regex: val, $options: "i" },
            },
            searchQuery: "",
          };
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            qureydata
          );

          this.ProductSelectValue = filterResultData?.data?.data;
        } else {
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.Specfilterquery
          );

          this.ProductSelectValue = filterResultData?.data?.data;
        }
        console.log(this.ProductSelectValue, "this.ProductSelectValu");
      },
      immediate: true,
      deep: true,
    },

    payload: {
      handler(val) {
        console.log(val, "productselect.name");
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
      // To get table header   
  let sampleTheads = await MLTAxiosInstance.get(`/settings`);

if (sampleTheads?.data?.entityRef?.["SupplierDiscountTable"]) {
  let offerTableHeadModal = sampleTheads.data.entityRef["SupplierDiscountTable"];
  let offerTableHeadOrg = sampleTheads.data.entityRef["SupplierDiscountTable"].filter(ele => ele.checked);
  console.log(offerTableHeadOrg,"offerTableHeadModal",offerTableHeadModal)

  if (
    offerTableHeadModal.length ===  this.accounts_table_theads_cc.length 
    // &&
    // offerTableHeadModal.every((item, index) => item.name ===  this.accounts_table_theads_cc[index].name)
  ) {
    this.accounts_table_theads_cc = JSON.parse(JSON.stringify(offerTableHeadModal));
      this.accounts_table_theads = JSON.parse(JSON.stringify(offerTableHeadOrg));
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
      this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
  }
}
    this.refeshDiscount();
    this.supplierFilterData();
    console.log("Additional charges mounted");
    this.searchFilterData = await this.supplierFilterData();

    console.log(this.supplierdata, this.locationSelectValue, "supplierdata");
    console.log(this.payload);

    // let statusList = await DMNAxiosInstance.get(
    //   `mapRef/collection/discountStatuslist`
    // );
    this.statusList =  await this.getReferanceData('discountStatuslist');
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    // console.log(val,"acces");
    this.company = val.data.company;
    const agreementRefData = await UPCAxiosInstance.get(
      `/agreementManagement?company=${this.$route.params.companyName}`
    );
    this.agreementRefData = agreementRefData.data;

    const updateSpecWithChar = await UPCAxiosInstance.get(
      `/catalogManagement/productOffering`
    );
    this.offringData = updateSpecWithChar.data;
    console.log(updateSpecWithChar, "constaintvalue");
  },
  methods: {
    resizedWidth(val){
      // To get resized width
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.accounts_table_theads);
  updateWidth(this.accounts_table_theads_cc);
console.log(this.accounts_table_theads,"resizedWidth loop",this.accounts_table_theads_cc)
this.applyColumns(this.accounts_table_theads_cc)
    },
    reAssignOrgTableHeader(){
           // If there is change in the table header then ressign to the Orginal data
           if((this.accounts_table_theads_cc != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.accounts_table_theads_cc = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.accounts_table_theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.accounts_table_theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.re_arrange = true
    },
    getRemovedValue(val) {
      this.removedSearchfiltervalur.push(val);
      console.log(
        "getRemovedValue",
        val,
        "removedSearchfiltervalur",
        this.removedSearchfiltervalur
      );
    },
    discountagreementRefselect(val) {
      this.discountNewDataEmit.agreementRef[0] = val;
      console.log(val, "discountNewDataEmit.validFor?.startDate");
      this.discountNewDataEmit.validFor.startDate = val.validFor.startDateTime;
      this.discountNewDataEmit.validFor.endDate = val.validFor.endDateTime;
    },
    async deleteExecRow() {
      const addedProductLocationLogPayload = {
        message: `${this.deleteobj.name} Discount has been deleted`,
        detailedAction: [],
        action: "Delete",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "supplier-Discount",
        category: ["supplier"],
      };
      this.initLog(addedProductLocationLogPayload);
      const deleteDiscount = await UPCAxiosInstance.delete(
        `/discount/${this.deleteobj._id}?company=${this.$route.params.companyName}`
      );
      if (deleteDiscount.status == 204) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been removed.`,
        };
        this.delete_modal_active = false;
        this.refeshDiscount();
      }
      this.deleteofferingdicount();
    },
    emitStatus(val) {
      this.discountNewDataEmit.lifecycleState = val.name;
      console.log("Discount status", val);
    },
    async addNewDiscount() {
      this.discountData.map((offer) => {
        offer.appliesTo.map((items) => {
          this.discountNewDataEmit?.appliesTo?.map(async (val) => {
            if (items.name === val.name) {
              this.exitpfferinglist.push(val);
              this.exitoffring = true;
            }
          });
        });
      });

      if (!this.exitoffring) {
        this.adddiscountbutton = true;
        const addDiscount = await UPCAxiosInstance.post(
          `/discount?company=${this.$route.params.companyName}`,
          this.discountNewDataEmit
        );
        const addedProductLocationLogPayload = {
          message: `${this.discountNewDataEmit.name} Discount has been added`,
          detailedAction: [],
          action: "ADDED",
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get("user"),
          ref: [this.$route.params.id],
          "@type": "supplier-Discount",
          category: ["supplier"],
        };
        this.initLog(addedProductLocationLogPayload);
        console.log(addDiscount.data);
        if (addDiscount.data) {
          this.notification = {
            type: "success",
            message: `Your item is added successfully!.`,
          };
        }
        this.addofferingdicount();
        this.refeshDiscount();
        this.closeDiscount();
      }
    },
    async addofferingdicount() {
      this.offringData?.map((offer) => {
        this.discountNewDataEmit?.appliesTo?.map(async (val) => {
          if (offer?._id === val?._id) {
            // Ensure offer.productOfferingPrice[0] is defined
            if (!offer.productOfferingPrice || !offer.productOfferingPrice[0]) {
              offer.productOfferingPrice = [{}]; // Initialize with an array containing an empty object
            }

            // Ensure offer.productOfferingPrice[0].productOfferingAlteration is defined
            if (!offer.productOfferingPrice[0].productOfferingAlteration) {
              offer.productOfferingPrice[0].productOfferingAlteration = [];
            }

            const constaintvalue = {
              "@type": "supplier-discount",
              company: this.$route.params.companyName,
              customName: this.$route.query.customName,
              name: this.discountNewDataEmit.name,
              discount: this.discountNewDataEmit.maximumDiscountRules[0].value,
              status: this.discountNewDataEmit.lifecycleState,
              validFor: this.discountNewDataEmit.validFor,
            };

            // Push the new alteration to the productOfferingAlteration array
            offer.productOfferingPrice[0].productOfferingAlteration.push(
              constaintvalue
            );

            // Send the updated offer object using PATCH
            try {
              const offering = await UPCAxiosInstance.patch(
                `/catalogManagement/productOffering`,
                offer
              );
              console.log(offer, "inside the loop");
              console.log(offering, "offering");
            } catch (error) {
              console.error("Error updating the offer:", error);
            }
          }
        });
      });
    },

    stayoffering() {
      this.exitoffring = false;
    },
    async deleteofferingdicount() {
      console.log(this.deleteobj, "deleteobj");
      this.offringData?.map((offer) => {
        this.deleteobj?.appliesTo?.map(async (val) => {
          if (offer?._id === val?._id) {
            offer.constraint = offer.constraint.filter(
              (item) =>
                !(
                  item.company === this.$route.params.companyName &&
                  item.name === this.deleteobj.name &&
                  item.discount === this.deleteobj.maximumDiscountRules[0].value
                )
            );
            console.log(offer, offer.constraint, "inside the loop ");
            const offering = await UPCAxiosInstance.patch(
              `/catalogManagement/productOffering`,
              { ...offer, id: offer?._id }
            );
            console.log(offering, "offering");
          }
        });
      });
    },
    async editDiscount() {
      this.discountNewDataEmit.id = this.discountNewDataEmit._id;
      delete this.discountNewDataEmit._id;
      let CM_LocationLog = {
        message: `${this.$route.params.companyName} Discount has been updated`,
        detailedAction: [],
        "@type": "supplier-Contract",
        category: ["supplier"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };

      let nameActionLog = this.buildBasicLogByDifferences(
        this.rawdata,
        this.discountNewDataEmit,
        "Name",
        "Name"
      );
      console.log(nameActionLog, CM_LocationLog, "nameActionLog");

      let documentNumberActionLog = this.buildBasicLogByDifferences(
        this.rawdata,
        this.discountNewDataEmit,
        "description",
        "description"
      );
      console.log(
        documentNumberActionLog,
        CM_LocationLog,
        "documentNumberActionLog"
      );

      let statusActionLog = this.buildBasicLogByDifferences(
        this.rawdata,
        this.discountNewDataEmit,
        "lifecycleState",
        "status"
      );
      console.log(statusActionLog, CM_LocationLog, "statusActionLog");

      let typeActionLog = this.buildBasicLogByDifferences(
        this.rawdata.agreementRef[0],
        this.discountNewDataEmit.agreementRef[0],
        "name",
        "Supplier contract"
      );
      console.log(typeActionLog, "typeActionLogActionLog");

      let validForSActionLog = this.buildBasicLogByDifferences(
        this.rawdata.validFor,
        this.discountNewDataEmit.validFor,
        "startDate",
        "startDate"
      );
      console.log(validForSActionLog, "validForSActionLog");

      let validForESActionLog = this.buildBasicLogByDifferences(
        this.rawdata.validFor,
        this.discountNewDataEmit.validFor,
        "endDate",
        "endDate"
      );
      console.log(validForESActionLog, "validForESActionLog");
      let discountActionLog = this.buildBasicLogByDifferences(
        this.rawdata.maximumDiscountRules[0],
        this.discountNewDataEmit.maximumDiscountRules[0],
        "value",
        "Discount"
      );
      console.log(validForESActionLog, "validForESActionLog");

      let validForaActionLog = this.buildLogBasedOnDifferencesForArrayOfObjects(
        this.rawdata.appliesTo,
        this.discountNewDataEmit.appliesTo,
        "name",
        "Product"
      );
      console.log(validForaActionLog, "validForaActionLog");

      CM_LocationLog.detailedAction = CM_LocationLog.detailedAction.concat(
        validForaActionLog,
        validForESActionLog,
        discountActionLog,
        validForSActionLog,
        typeActionLog,
        statusActionLog,
        documentNumberActionLog,
        nameActionLog
      );
      const editDiscount = await UPCAxiosInstance.patch(
        `/discount?company=${this.$route.params.companyName}`,
        this.discountNewDataEmit
      );
      console.log(editDiscount.data);
      if (editDiscount.data) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been updated.`,
        };
        this.initLog(CM_LocationLog);
      }
      this.refeshDiscount();
      this.closeDiscount();
    },

    closeDiscount() {
      this.mainInfoNewDiscount.forEach((val) => {
        val.checked = false;
      });
      this.exitpfferinglist = [];
      this.exitoffring = false;
      this.adddiscountbutton = false;
      this.isModal = false;
      this.updateKey += 1;
      this.keyagreementRef += 1;
      this.editMode = false;
      this.discountNewDataEmit = {
        relatedParty: [],
        description: "",
        type: "Supplier discount",
        name: "",
        lifecycleState: "Initialized",
        validFor: {
          startDate: new Date(),
          endDate: "",
        },
        agreementRef: [{}],
        maximumDiscountRules: [{ value: 0 }],
        minimumDiscountRules: [],
        discountType: { name: "", value: "" },
        extarctedType: "",
        appliesTo: [],
        applyTo: [],
        isSpecified: false,
        specifiedAccounts: [],
      };
    },
    discountdrawer() {
      this.isModal = true;
    },

    async refeshDiscount() {
      const getDiscount = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/supplierView?company=${this.$route.params.companyName}`
      );
      this.discountData = getDiscount.data;
      this.discountserarch = getDiscount.data;
      console.log(this.discountData, "this.discountData");
    },

    async supplierFilterData() {
      try {
        console.log("kjhfj");
        const supplierFilterRes = await UPCAxiosInstance.post(
          "util/filterLayout",
          this.filterLayout
        );
        // state.suppliersFilterData = supplierFilterRes.data;
        console.log(
          "productRecommed 3.0 supplierfilterdata ",
          supplierFilterRes
        );
        console.log("productRecommed 3.0 2", supplierFilterRes.data);

        return await supplierFilterRes.data;
      } catch (error) {
        console.error(error);
      }
    },

    productSearch(val) {
      this.searchvalue = val;
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.discountserarch,
          val.toLowerCase()
        );
        this.discountData = JSON.parse(JSON.stringify(searchData));
        console.log(this.discountData, "this.discountData");
      } else {
        this.discountData = JSON.parse(JSON.stringify(this.discountserarch));
      }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    async getSearchedValueNewDiscount(value) {
      try {
        const filterPostQuery = await UPCAxiosInstance.post(
          `util/filterData`,
          value
        );

        const responseData = filterPostQuery.data.data;
        console.log(responseData, "responseData");
        console.log(responseData, "responseData 2");
        this.mainInfoNewDiscount = responseData.map(
          ({
            name,
            productOfferingPrice,
            prodSpecCharValue,
            id,
            category,
            productSpecification,
          }) => {
            const productType = productSpecification["@baseType"];
            const categoryType = this.leaf(category ? category : "");
            let price = "0";
            let unit;

            if (productOfferingPrice?.length > 0) {
              const productPrice = productOfferingPrice[0]?.productPrice;
              console.log(
                productOfferingPrice[0]?.productPrice,
                "productOfferingPrice[0].productPrice"
              );
              if (productPrice) {
                console.log(
                  "wer5t6y78uiokjhgvc",
                  prodSpecCharValue[0]?.tiers[0]?.price?.value,
                  prodSpecCharValue
                );
                price = `${
                  prodSpecCharValue[0]?.tiers[0]?.price?.value || "0"
                } ${productPrice.unit || ""}`;
                unit = productPrice.unit || "";
              }
              price = price !== undefined ? price : 0;
              unit = unit !== undefined ? unit : unit;
              return {
                name: name,
                price:
                  prodSpecCharValue[0]?.billingPeriod !== undefined &&
                  prodSpecCharValue[0]?.billingPeriod !== null
                    ? productType === "Tangible"
                      ? this.currencyFormatter(price, { currency: unit })
                      : this.currencyFormatter(price, { currency: unit }) +
                        ` / ${prodSpecCharValue[0]?.billingPeriod}`
                    : this.currencyFormatter(price, { currency: unit }) +
                      ` / ${""}`,
                _id: id,
                category: categoryType,
              };
            } else {
              return {
                name: name,
                price:
                  prodSpecCharValue[0]?.billingPeriod !== undefined &&
                  prodSpecCharValue[0]?.billingPeriod !== null
                    ? productType === "Tangible"
                      ? this.currencyFormatter(0)
                      : this.currencyFormatter(0) +
                        ` / ${prodSpecCharValue[0]?.billingPeriod}`
                    : this.currencyFormatter(0) + ` / ${""}`,
                _id: id,
                category: categoryType,
              };
            }
          }
        );

        // console.log(this.productName,"productName")
        // console.log(this.relatedProducts,"this.relatedProducts")
        // console.log(this.mainInfo,"this.relatedProducts 1")
      } catch {
        console.log("error from getsearchedvalue");
      }
    },
    async getcheckedValueNewDiscount(value) {
      this.discountNewDataEmit.appliesTo = value;
    },

    async refeshData() {
      const tableData = await UPCAxiosInstance.get(
        `/productStock?company=${this.$route.params.companyName}`
      );
      this.productdata = tableData.data;
      console.log(this.productdata, "this.productdata ");
    },
    viewPage(val) {
      (this.editproductPayload = val), console.log(val, "edit flow");
    },
     changeRowsPerPage(count) {
     
        this.rowsPerPage = count;
       
      
    },
     currentPage(val) {
    
        this.currPage = val;
  
      // this.table_height = this.paginatedData.length * 40 + 43;
    },
    payloadval(val) {
      this.payload.minStockLevel = { unit: val };
      // this.editPayload.minStockLevel = { unit: val };
      console.log(val, this.payload, "payloadval");
    },
    payloadvaledit(val) {
      // this.Pa.minStockLevel = { unit: val };
      this.editPayload.minStockLevel = { unit: val };
      this.editPayload.id = this.editPayloadId;
      console.log(val, this.editPayload, "payloadval");
    },
    sorting_Data(data, data1) {
      //
      console.log(data, data1);
      this.Supplierfilterquery.type = "filter";
      if (data === "icon-angle-up-small-filled sorting-icon") {
        this.Supplierfilterquery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = 1;
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.supplierdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        this.Supplierfilterquery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = -1;
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.supplierdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-up-small-filled") {
        this.Supplierfilterquery.isNeedToBeSort = false;
        let obj = {};
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.supplierdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled") {
        this.Supplierfilterquery.isNeedToBeSort = false;
        let obj = {};
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.supplierdataCalling(this.currPage, this.rowsPerPage);
      }
    },
    async supplierdataCalling(SpecificationcurrPage, SpecificationrowsPerPage) {
      this.Supplierfilterquery["paginatedQuery"]["currentPage"] =
        SpecificationcurrPage;
      this.Supplierfilterquery["paginatedQuery"]["rowsPerPage"] =
        SpecificationrowsPerPage;
    },

    async selectedproductFunc() {},
    leaf(text) {
      console.log("leaf category text full", text);
      console.log("leaf category text length", text?.length);
      console.log("leaf category text typeof", typeof text);
      let lengthOfText = text?.length;
      if (typeof text === "string") {
        return text;
      }
      if (lengthOfText === 1) {
        console.log("leaf category text length final o/p ", text);
        return (text = text.toString());
      }
      if (text && lengthOfText > 1) {
        const total = text?.length;
        console.log(
          text,
          "leaf category text length final o/p",
          text[total - 1]
        );

        return text[total - 1];
      }
    },
    async setvalue(val) {
      this.productselect = val;
      let leafCategory = this.leaf(val.category);
      let categoryInfo = await UPCAxiosInstance.get(
        "/catalogManagement/category/" + leafCategory
      );
      console.log("🚀 ~ computedPriceTypes ~ categoryInfo:", categoryInfo);
      if (categoryInfo.data) {
        this.categoryInfo = categoryInfo.data;
      }

      this.filterdata = this.productselect.prodSpecCharValue.filter((value) => {
        if (this.categoryInfo.type === "Tangible") {
          if (value.SKU) {
            return (
              value.priceType?.toLowerCase() ===
              this.categoryInfo.pricingType[0].toLowerCase()
            );
          }
        } else {
          return (
            value.priceType?.toLowerCase() ===
            this.categoryInfo.pricingType[0].toLowerCase()
          );
        }
      });

      console.log(this.filterdata, "filterdata");

      this.payload.name = val.name;
      this.selectedvalueproduct = val._id;
      console.log(this.selectedvalueproduct, "this.selectedvalueproduct");
      this.cascaderButton = false;
      console.log(this.payload, val);
      this.detailproduct = true;
      document.getElementById("showTheProductDetail").style.display = "block";
    },

    editcascadCancel() {
      this.modal_edit_cascode = false;
      this.selectcomponentkey += 1;
      this.inputComponentkey += 1;
      this.editPayload = {};
      this.productselect = {};
      document.getElementById("showTheProductDetail").style.display = "none";
    },

    async editchildContinue() {
      const payload = {
        id: this.editPayload.id,
        productStockLevel: {
          unit: this.editinpurvalue,
        },
      };
      let result = await UPCAxiosInstance.patch(
        `/productStock?company=${this.$route.params.companyName}`,
        payload
      );
      this.refeshData();
      this.editcascadCancel();
      console.log(result, payload);
    },
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.discountData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.discountData[index].context = true;
      } else {
        this.discountData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        this.editMode = true;
        this.isModal = true;
        this.mainInfoNewDiscount.forEach((val) => {
          val.checked = false;
        });
        this.discountNewDataEmit = JSON.parse(
          JSON.stringify(this.copyOfDiscountTableData[i])
        );
        this.rawdata = JSON.parse(
          JSON.stringify(this.copyOfDiscountTableData[i])
        );
        ele?.appliesTo.forEach((checkedItem) => {
          const matchingItem = this.mainInfoNewDiscount.find(
            (item) => item._id === checkedItem._id
          );
          if (matchingItem) {
            matchingItem.checked = true;
          }
        });
        console.log(this.mainInfoNewDiscount, "this.mainInfoNewDiscount");
        //
        console.log(
          $event,
          ele,
          i,
          this.discountNewDataEmit,
          "edit closeCrudContextMenu"
        );
      } else if ($event.toLowerCase() == "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      }
    },
    computedTableAction(data) {
      console.log(data);
      if (this.permisstionData.delete && this.permisstionData.edit) {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else if (this.permisstionData.delete ) {
        return [
          
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      }
    },
    // computedTableAction(data) {
    //   const baseType = data.stockedProduct["@baseType"];

    //   if (baseType === "Tangible") {
    //     return [
    //       {
    //         label: "Edit",
    //         icon: "icon-file-text-edit-regular",
    //       },
    //       {
    //         label: "Delete",
    //         icon: "icon-trash-regular",
    //       },
    //     ];
    //   } else if (baseType === "Intangible") {
    //     return [
    //       {
    //         label: "Delete",
    //         icon: "icon-trash-regular",
    //       },
    //     ];
    //   } else {
    //     return [];
    //   }
    // },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    arrangeColumns(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );
          return { ...thead, checked: isMatching };
        }
      );
    },
    async applyColumns(val) {
     // To get rearranged header
    console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef || {};

concatedEntity.SupplierDiscountTable = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.accounts_table_theads_cc = entityRef["SupplierDiscountTable"];
this.accounts_table_theads = this.accounts_table_theads_cc.filter(ele => ele.checked);
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
    },
  },
};
</script>
<style lang="scss">
.value-data-supplier {
  display: flex;
  font-size: 14px;
  padding: 14px;
  .value-data-supplier_left {
    width: 102%;
  }
  .value-data-supplier_right {
    width: 80%;
  }
}
.header_product_sku_stock {
  height: 30px;
  background: rgba(217, 217, 217, 0.4);
  margin-bottom: 20px;
  padding: 5px 16px;
}
#showTheProductDetail {
  display: none;
}
.add_delete_supplier {
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 8%;
  justify-content: space-between;
}
.regain_time {
  display: flex;
  align-items: center;
  padding: 5px 16px;
  .body_sno {
    width: 10%;
  }
  .body_region {
    width: 50%;
  }
  .body_lead {
    width: 50%;
  }
}
.head_regain_time {
  height: 50px;
  background: rgba(217, 217, 217, 0.2);
  padding: 5px 16px;
  border-radius: 6px;
  font-size: 12px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  .body_sno {
    width: 10%;
  }
  .body_region {
    width: 50%;
  }
  .body_lead {
    width: 50%;
  }
}
.product_supplier {
  margin-bottom: 20px;
  .supplier-right-model-content {
    gap: 20px;
  }
}
.Cascader_product_supplier {
  margin-top: 20px;
}
.product_detail_supplier_modal {
  .header-area-contact-drawer {
    padding: 8px 0 12px 8px;
    border-bottom: 1px solid #d3d3d3;
  }
  .sidenav-right {
    overflow: hidden;
    .body-panel-right {
      height: 87vh !important ;
    }
  }

  .toplayer_supplier {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text_product_supplier {
      font-weight: bold;
      margin-top: 10px;
    }
    .product_detail_supplier {
      font-size: 36px;
      display: flex;
      align-items: center;
      align-content: flex-end;
      justify-content: space-evenly;
      flex-wrap: nowrap;
      color: #54bd95;

      flex-direction: row-reverse;
      width: 70px;
      height: 90px;
      border-radius: 50%;
      background-color: #54bd9524;
    }
  }
}
.product-detail-supplier {
  background: rgba(217, 217, 217, 0.2);
  padding: 5px;
  border-radius: 6px;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  .value-data-supplier {
    display: flex;
    font-size: 14px;
    padding: 14px;
    .value-data-supplier_left {
      width: 102%;
    }
    .value-data-supplier_right {
      width: 80%;
    }
  }
}
.header-add-supplier {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  .tablehead-add-supplier {
    font-weight: bold;
  }
  .search-add-supplier {
    display: flex;
    gap: 10px;
  }
}
.contacts-username-supplier {
  display: flex;
  align-items: center;
  .right-head-supplier {
    display: flex;
    flex-direction: column;
  }
  .head-supplier-left {
    display: flex;
    justify-content: center;
    width: 44px;
    align-items: center;
    font-size: 45px;
    margin-right: 10px;
  }
  .head-supplier-right {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    .head-supplier-name {
      margin-right: 10px;
    }
  }
  .head-supplier-address {
    font-size: 10px;
    color: #929191;
    margin-top: 3px;
  }
}
</style>
