<template>
  <div>
    <hlx-modal
      class="upc"
      :modal-active="add_model"
      :height="'403px'"
      :width="'500px'"
      :modal="true"
      @close="add_model = false"
    >
      <template #header>
        <div style="margin-left: 0px">Edit template</div>
      </template>
      <template #content>
        <div class="modal-content">
          <div class="input-section-v2">
            <hlx-input
              v-model:value="editPayload.name"
              :pre-val="editPayload.name"
              :label-animation="true"
              :required="true"
              label-value="Name"
              type="text"
              :clearable="true"
            />
            <div style="width: 100%; margin-top: 20px">
              <hlx-status
                :type="'select-status'"
                :status-option="filteredstatus"
                :prevalue="editPayload.status"
                @emit-select="emitStatus"
              >
              </hlx-status>
            </div>
            <div style="width: 100%; margin-top: 20px">
              <hlx-input
                v-model:value="editPayload.description"
                :pre-val="editPayload.description"
                :label-animation="true"
                label-value="Description"
                type="textarea"
                :clearable="true"
              ></hlx-input>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="add_model = false"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary sm"
          style="margin-left: 16px"
          :disabled="editPayload.name !== '' ? false : true"
          @click="editTemplateTicket()"
          >Save</hlx-button
        >
      </template>
    </hlx-modal>
  </div>
  <!-- {{ userLanguages + 'hi' }} -->
  <!-- {{ $store?.getters?.loginUserDetails?.languageAbility }} -->
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <!-- Field delete -->
  <div class="confirm-sustomfield-delete-modal">
    <hlx-modal
      :modal-active="deleteModal"
      :height="'220px'"
      :width="'400px'"
      :modal="true"
      @close="deleteModal = false"
    >
      <template #header>
        <div>Delete field</div>
      </template>
      <template #content>
        <p
          style="
            text-align: left;
            padding-left: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
          "
        >
          Are you sure you want to delete the field<b>{{
            ' "' + deleteName + '"'
          }}</b
          >?
        </p>
      </template>
      <template #footer>
        <div class="footer-form-delte">
          <div class="button-area">
            <hlx-button class="secondary sm" @click="deleteModal = false"
              >Cancel</hlx-button
            >

            <hlx-button
              class="primary sm"
              @click="removeField(deleteIndex, deleteVal)"
              >Delete</hlx-button
            >
          </div>
        </div>
      </template>
    </hlx-modal>
  </div>
  <div class="add-contact-drawer">
    <hlx-drawer
      :key="resetComponent"
      :show="showAddFieldPanel"
      position="right"
      width="550"
      :footer="true"
      :title="'Add fields'"
      :show-close-icon="true"
      @close="closeDrawer"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <b v-if="editMode == false"> Add Field</b>
          <b v-else> Edit Field</b>
        </div>
        <div class="body-area-contact-drawer">
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              margin-bottom: 40px;
            "
          >
            <hlx-switch
              v-model="selectedDefinitionTab"
              :switch-items="definitionTabs"
              value-prop="value"
              display-prop="name"
              @change="chosenDefinitionTab"
            />
          </div>
          <div
            v-show="selectedDefinitionTab == 'Custom logic'"
            class="visibility-rule-block"
          >
            <div
              v-if="fieldData?.displayType?.toLowerCase() == 'lookup'"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
              "
            >
              <span>Data mapping</span>
              <hlx-input
                v-model:value="fieldData.others.lookup.isDataRemapEnabled"
                :checked="fieldData?.others?.lookup?.isDataRemapEnabled"
                :type="'switch'"
              ></hlx-input>
            </div>
            <br />
            <hlx-code-editor
              v-if="fieldData.others?.lookup?.isDataRemapEnabled"
              v-model:value="fieldData.others.lookup.remapData"
              :intellisense-data="intellisenseData"
              :pre-value="fieldData.others.lookup.remapData"
              :line-number="true"
            />
          </div>
          <div
            v-show="selectedDefinitionTab == 'Configuration'"
            class="visibility-rule-block"
          >
            <hlx-input
              v-model:value="fieldData.fieldName"
              :pre-val="fieldData.fieldName"
              :label-value="'Field name'"
              :label-animation="true"
              :rows="10"
              :read-only="editMode"
              :required="true"
              :display-error="true"
              :custom-error="isFieldNameInvalid"
              :custom-error-message="'Field name already exists'"
              @at-input="validateFieldName"
              @focus-out="blurFieldName"
            ></hlx-input>
            <hlx-input
              v-for="(languages, langIndex) in fieldData.labels"
              :key="langIndex"
              v-model:value="fieldData.labels[langIndex].label"
              :pre-val="fieldData.labels[langIndex].label"
              :label-value="`Label name - ${fieldData.labels[langIndex].name}`"
              :label-animation="true"
              :rows="10"
              :required="
                Object.keys(fieldData.labels).indexOf(langIndex) === 0
                  ? true
                  : false
              "
              :display-error="true"
              :custom-error="fieldData.labels[langIndex].isLabelNameInvalid"
              :custom-error-message="
                fieldData.labels[langIndex].isLabelNameInvalidMessage
              "
              @at-input="validateLabelName($event, fieldData.labels[langIndex])"
            ></hlx-input>
            <!-- <br />
          <hlx-input
            :key="resetComponent"
            v-model:value="fieldData.labelFrench"
            :pre-val="fieldData.labelFrench"
            :label-value="'Label name - French'"
            :label-animation="true"
            :rows="10"
            :required="true"
            :custom-error="customnameerror"
            :custom-error-message="'-'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input> -->
            <!-- <br /> -->
            <hlx-input
              v-model:value="fieldData.description"
              :pre-val="fieldData.description"
              :label-value="'Description'"
              type="textarea"
              :label-animation="true"
              :rows="10"
              :required="false"
              :custom-error="customnameerror"
              :custom-error-message="'Label name - French'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
            <br />
            <hlx-select
              :key="resetDropdown"
              v-model:value="fieldData.displayType"
              :pre-value="fieldData.displayType"
              :placeholder-value="'Input type'"
              :options="displayTypeOptions"
              :label-animation="true"
              :required="true"
            ></hlx-select>
            <br />
            <div v-if="fieldData?.displayType?.toLowerCase() == 'user'">
              <hlx-multi-select
                :pre-value="fieldData?.others?.userRole"
                :options="userRoles"
                :label="'role'"
                :prop-value="'role'"
                :placeholder="'Role'"
                :label-animation="true"
                @selected-array="selectedRoles($event, fieldData.others)"
              >
              </hlx-multi-select>
            </div>
            <br v-if="fieldData?.displayType?.toLowerCase() == 'user'" />
            <!-- Visibility rules -->
            <hlx-input
              v-if="fieldData.displayType != 'header'"
              v-model:value="fieldData.required"
              :checked="fieldData.required"
              type="single-checkbox"
              :label-value="'Mandatory'"
              @selected="mandatoryCheckBoxClicked"
            ></hlx-input>
            <br v-if="fieldData.displayType != 'header'" />
            <hlx-input
              v-if="fieldData.displayType != 'header'"
              v-model:value="fieldData.others.readOnly"
              :checked="fieldData.others.readOnly"
              type="single-checkbox"
              :label-value="'Read only'"
            ></hlx-input>
            <br v-if="fieldData.displayType != 'header'" />
            <hlx-input
              v-model:value="fieldData.isTooltip"
              :checked="fieldData.isTooltip"
              type="single-checkbox"
              :label-value="'Tooltip description'"
            ></hlx-input>
            <br v-if="fieldData.isTooltip" />
            <div
              v-for="(languages, langIndex) in fieldData.labels"
              :key="langIndex"
            >
              <hlx-input
                v-if="fieldData.isTooltip"
                :key="langIndex"
                v-model:value="fieldData.labels[langIndex].tooltip"
                :pre-val="fieldData.labels[langIndex].tooltip"
                :label-value="`Tooltip description - ${fieldData.labels[langIndex].name}`"
                :label-animation="true"
                :character-limit="250"
                :display-error="true"
                type="textarea"
              ></hlx-input>
            </div>
            <br
              v-if="
                fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                fieldData?.displayType?.toLowerCase() == 'radio' ||
                fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                fieldData?.displayType?.toLowerCase() == 'status' ||
                fieldData?.displayType?.toLowerCase() == 'multiselect' ||
                fieldData?.displayType?.toLowerCase() == 'siteaddress' ||
                fieldData?.displayType?.toLowerCase() == 'lookup'
              "
            />
            <hr
              v-if="
                fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                fieldData?.displayType?.toLowerCase() == 'radio' ||
                fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                fieldData?.displayType?.toLowerCase() == 'status' ||
                fieldData?.displayType?.toLowerCase() == 'multiselect' ||
                fieldData?.displayType?.toLowerCase() == 'siteaddress' ||
                fieldData?.displayType?.toLowerCase() == 'lookup'
              "
            />
            <br
              v-if="
                fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                fieldData?.displayType?.toLowerCase() == 'radio' ||
                fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                fieldData?.displayType?.toLowerCase() == 'status' ||
                fieldData?.displayType?.toLowerCase() == 'multiselect' ||
                fieldData?.displayType?.toLowerCase() == 'siteaddress' ||
                fieldData?.displayType?.toLowerCase() == 'lookup'
              "
            />
            <div
              v-if="
                fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                fieldData?.displayType?.toLowerCase() == 'radio' ||
                fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                fieldData?.displayType?.toLowerCase() == 'status' ||
                fieldData?.displayType?.toLowerCase() == 'multiselect' ||
                fieldData?.displayType?.toLowerCase() == 'siteaddress' ||
                fieldData?.displayType?.toLowerCase() == 'lookup'
              "
              style="display: flex; flex-direction: column"
            >
              <b style="margin-bottom: 8px">Input value preference</b>
              <span style="margin-bottom: 8px; font-size: 12px"
                >Your Desired Input Options</span
              >
            </div>
            <div
              v-if="
                fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                fieldData?.displayType?.toLowerCase() == 'radio' ||
                fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                fieldData?.displayType?.toLowerCase() == 'status' ||
                fieldData?.displayType?.toLowerCase() == 'multiselect'
              "
              style="display: flex; flex-direction: column"
            >
              <br />
              <hlx-input
                type="radio-buttons"
                :options="isRefOrNotOptions"
                :prop-value="'name'"
                :pre-val="fieldData.others.selectedRefOption"
                @selected="checkIsref"
              >
              </hlx-input>
              <br v-if="fieldData.others.selectedRefOption != 'custom'" />

              <b
                v-if="fieldData.others.selectedRefOption != 'custom'"
                style="margin-bottom: 8px"
                >Values</b
              >
              <span
                v-if="fieldData.others.selectedRefOption != 'custom'"
                style="margin-bottom: 8px; font-size: 12px"
                >Add your input values</span
              >
              <div
                v-if="
                  fieldData.others.selectedRefOption != 'custom' &&
                  !fieldData.isRef
                "
              >
                <div
                  style="
                    display: flex;
                    gap: 20px;
                    justify-content: space-between;
                    margin-bottom: 20px;
                  "
                >
                  <span
                    style="display: flex; flex-direction: column; width: 100%"
                  >
                    <hlx-input
                      v-model:value="csvValues"
                      :pre-val="csvValues"
                    ></hlx-input>
                    <span style="font-size: 12px"
                      >Seperate multiple values by using comma</span
                    >
                  </span>
                  <span>
                    <hlx-button class="secondary sm" @click="addCsvValues">
                      Add
                    </hlx-button>
                  </span>
                </div>
                <div
                  style="
                    margin-bottom: 20px;
                    height: 35px;
                    width: 100%;
                    background: #d8d8d8;
                    padding: 0 50px 0 30px;
                    display: flex;
                  "
                >
                  <span
                    v-for="(languages, lIndex) in userLanguages"
                    :key="lIndex"
                    style="display: flex; height: 100%; align-items: center"
                  >
                    <span
                      v-if="lIndex === 0 || lIndex === 1"
                      :style="
                        lIndex === 0 || lIndex === 1
                          ? 'width: 165px'
                          : 'width: 0px'
                      "
                    >
                      {{ languages.name }}
                    </span>
                  </span>
                  <!-- <span></span> -->
                </div>
                <div
                  v-for="(item, itemIndex) in newRefData"
                  :key="itemIndex"
                  style="
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 8px;
                  "
                  class="list-values"
                  draggable="true"
                  @dragstart="dragRefDataStart(itemIndex)"
                  @dragover.prevent
                  @dragleave="dragRefDataLeave(itemIndex)"
                  @dragend="dragRefDataEnd(itemIndex)"
                >
                  <i class="icon-drag-vertical-filled" style="cursor: grab"></i>
                  <hlx-color-palette
                    v-if="fieldData.displayType.toLowerCase() == 'status'"
                    :pre-value="item.hexCode"
                    @custom-change="colorValue($event, item)"
                  ></hlx-color-palette>
                  <hlx-input
                    v-model:value="item.value"
                    :pre-val="item.value"
                    :label-value="'Value - English'"
                    :label-animation="false"
                  >
                  </hlx-input>
                  <hlx-input
                    v-model:value="item[userLanguages[1].name.toLowerCase()]"
                    :pre-val="item[userLanguages[1].name.toLowerCase()]"
                    :label-value="`Value - ${userLanguages[1].name.toLowerCase()}`"
                    :label-animation="false"
                  >
                  </hlx-input>
                  <span
                    style="display: flex; gap: 8px"
                    data-tooltip="Set as default"
                    data-tooltip-location="left"
                  >
                    <i
                      class="icon-badge-check-regular"
                      :style="
                        item.default == 'true' || item.default == true
                          ? { color: '#54bd95' }
                          : {}
                      "
                      @click="setDefaultNewRefData(itemIndex)"
                    ></i>
                    <i
                      v-if="newRefData.length > 1"
                      class="icon-trash-regular"
                      @click="deleteNewRefData(itemIndex)"
                    ></i>
                  </span>
                </div>
                <span
                  style="
                    display: flex;
                    color: rgb(84, 189, 149);
                    align-items: center;
                    cursor: pointer;
                    margin-top: 5px;
                    margin-bottom: 8px;
                  "
                  @click="addNewRefData"
                  ><i class="icon-plus-regular"></i> Add value
                </span>
              </div>
            </div>
            <!-- <hlx-input
              v-if="
                fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                fieldData?.displayType?.toLowerCase() == 'radio' ||
                fieldData?.displayType?.toLowerCase() == 'status' ||
                fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                fieldData?.displayType?.toLowerCase() == 'multiselect'
              "
              v-model:value="fieldData.isRef"
              :checked="fieldData.isRef"
              type="single-checkbox"
              :label-value="'Import reference'"
              @selected="checkIsref"
            ></hlx-input> -->
            <br
              v-if="
                fieldData.isRef != 'custom' &&
                fieldData.isRef &&
                (fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                  fieldData?.displayType?.toLowerCase() == 'radio' ||
                  fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                  fieldData?.displayType?.toLowerCase() == 'status' ||
                  fieldData?.displayType?.toLowerCase() == 'multiselect')
              "
            />
            <hlx-select
              v-if="
                fieldData.isRef != 'custom' &&
                fieldData.isRef &&
                (fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                  fieldData?.displayType?.toLowerCase() == 'radio' ||
                  fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                  fieldData?.displayType?.toLowerCase() == 'status' ||
                  fieldData?.displayType?.toLowerCase() == 'multiselect')
              "
              :options="getReferenceDataOptions(fieldData?.displayType)"
              :pre-value="fieldData?.optionRef"
              :inline-search="true"
              :prop-value="'name'"
              :placeholder-value="'Choose a reference data'"
              @selected-value="selectedReferenceData"
            ></hlx-select>
            <br
              v-if="
                fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                fieldData?.displayType?.toLowerCase() == 'radio' ||
                fieldData?.displayType?.toLowerCase() == 'status' ||
                fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                fieldData?.displayType?.toLowerCase() == 'multiselect'
              "
            />

            <!-- <br v-if="fieldData.isTooltip" />
          <hlx-input
            v-if="fieldData.isTooltip"
            :key="resetComponent"
            v-model:value="fieldData.tooltipFrench"
            type="textarea"
            :label-value="'Tooltip description - French'"
          ></hlx-input>
          <br v-if="fieldData.isTooltip" /> -->
            <!-- Hide checkbox removed -->
            <!-- <hlx-input
            :checked="!fieldData.show"
            type="single-checkbox"
            :label-value="'Hide'"
            @selected="showHideField"
          ></hlx-input> -->
            <br v-if="fieldData.others" />
            <div
              v-if="fieldData.others"
              style="
                display: flex;
                align-items: space-between;
                justify-content: center;
                flex-direction: column;
              "
            >
              <div
                v-if="fieldData?.displayType?.toLowerCase() == 'siteaddress'"
              >
                <hlx-select
                  v-model:value="fieldData.others.addressAPI.type"
                  :pre-value="fieldData?.others?.addressAPI?.type"
                  :options="addressAPIOptions"
                  :label="'name'"
                  :prop-value="'name'"
                  :placeholder-value="'Address API'"
                  :label-animation="true"
                >
                </hlx-select>

                <br />
                <hlx-select
                  v-if="
                    fieldData?.others?.addressAPI?.type == 'Canada post API'
                  "
                  v-model:value="fieldData.others.addressAPI.authType"
                  :pre-value="fieldData?.others?.addressAPI?.authType"
                  :options="authenticationMethods"
                  :label="'name'"
                  :prop-value="'name'"
                  :placeholder-value="'Authentication method'"
                  :label-animation="true"
                >
                </hlx-select>
                <br />

                <hlx-input
                  v-if="fieldData?.others?.addressAPI?.authType == 'API Key'"
                  v-model:value="fieldData.others.addressAPI.key"
                  :pre-val="fieldData.others.addressAPI.key"
                  :label-value="'API key'"
                  :label-animation="true"
                >
                </hlx-input>
                <hlx-input
                  v-else-if="
                    fieldData?.others?.addressAPI?.authType == 'Bearer Token'
                  "
                  v-model:value="fieldData.others.addressAPI.token"
                  :pre-val="fieldData.others.addressAPI.token"
                  :label-value="'Token'"
                  :label-animation="true"
                >
                </hlx-input>
                <div
                  v-else-if="
                    fieldData?.others?.addressAPI?.authType == 'Basic Auth'
                  "
                >
                  <hlx-input
                    v-model:value="fieldData.others.addressAPI.userName"
                    :pre-val="fieldData.others.addressAPI.userName"
                    :label-value="'Username'"
                    :label-animation="true"
                  >
                  </hlx-input>
                  <br />
                  <hlx-input
                    v-model:value="fieldData.others.addressAPI.password"
                    :pre-val="fieldData.others.addressAPI.password"
                    :label-value="'Password'"
                    :label-animation="true"
                  >
                  </hlx-input>
                </div>
                <br />
                <hlx-input
                  v-model:value="fieldData.others.isServiceQualifcationEnabled"
                  :checked="fieldData.others.isServiceQualifcationEnabled"
                  type="single-checkbox"
                  :label-value="'Eligible for qualification'"
                >
                </hlx-input>
              </div>
              <div
                v-if="
                  fieldData?.displayType?.toLowerCase() == 'lookup' ||
                  fieldData?.displayType?.toLowerCase() == 'siteaddress'
                "
              >
                <hlx-select
                  v-if="fieldData?.displayType?.toLowerCase() == 'lookup'"
                  :pre-value="fieldData?.others?.lookup?.module"
                  :options="lookupModules"
                  :label="'module'"
                  :prop-value="'module'"
                  :placeholder-value="'Lookup module'"
                  :label-animation="true"
                  @selected-value="selectedLookupModule"
                >
                </hlx-select>

                <!-- <br />
              <hlx-select
                v-model:value="fieldData.others.lookup.fieldType"
                :pre-value="fieldData?.others?.lookup?.fieldType"
                :options="lookupFieldTypes"
                :label="'name'"
                :prop-value="'value'"
                :placeholder-value="'Lookup field type'"
                :label-animation="true"
              >
              </hlx-select> -->
                <br
                  v-if="
                    fieldData?.displayType?.toLowerCase() == 'lookup' ||
                    fieldData?.displayType?.toLowerCase() == 'siteaddress'
                  "
                />

                <hlx-input
                  v-if="fieldData?.displayType?.toLowerCase() == 'lookup'"
                  v-model:value="fieldData.others.lookup.lookupProp"
                  :pre-val="fieldData.others.lookup.lookupProp"
                  :label-value="'Lookup attribute'"
                  :label-animation="true"
                >
                </hlx-input>
                <br v-if="fieldData?.displayType?.toLowerCase() == 'lookup'" />
                <!-- <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 16px;
                  "
                >
                  <span>Data mapping</span>
                  <hlx-input
                    v-model:value="fieldData.others.lookup.isDataRemapEnabled"
                    :checked="fieldData?.others?.lookup?.isDataRemapEnabled"
                    :type="'switch'"
                  ></hlx-input>
                </div> -->
              </div>
              <!-- <hlx-code-editor
                v-if="fieldData.others?.lookup?.isDataRemapEnabled"
                v-model:value="fieldData.others.lookup.remapData"
                :intellisense-data="intellisenseData"
                :pre-value="fieldData.others.lookup.remapData"
                :line-number="true"
              >
              </hlx-code-editor> -->
              <br v-if="fieldData.others?.lookup?.isDataRemapEnabled" />
              <div v-if="fieldData?.displayType?.toLowerCase() == 'lookup'">
                <br />
                <span
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    margin-bottom: 20px;
                  "
                >
                  <b>Search settings</b>
                </span>
                <hr />
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    margin-top: 20px;
                    align-items: center;
                  "
                >
                  <span style="display: flex; flex-direction: column">
                    <span>Minimum Characters to Start Search</span>
                    <span style="font-size: 12px"
                      >Min: 1 character, Max: 10 characters</span
                    >
                  </span>
                  <span>
                    <hlx-input
                      v-model:value="fieldData.others.lookup.minCharToSearch"
                      :pre-val="fieldData.others.lookup.minCharToSearch"
                      type="number"
                      :min-limit="1"
                      :max-limit="10"
                    >
                    </hlx-input>
                  </span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span style="display: flex; flex-direction: column">
                    <span>Maximum Number of Results to Display</span>
                    <span style="font-size: 12px"
                      >Min: 5 results, Max: 20 results</span
                    >
                  </span>
                  <span>
                    <hlx-input
                      v-model:value="fieldData.others.lookup.maxResultToDisplay"
                      :pre-val="fieldData.others.lookup.maxResultToDisplay"
                      type="number"
                      :min-limit="5"
                      :max-limit="20"
                    >
                    </hlx-input>
                  </span>
                </div>
              </div>

              <hr v-if="fieldData?.displayType?.toLowerCase() == 'date'" />
              <br v-if="fieldData?.displayType?.toLowerCase() == 'date'" />
              <div
                v-if="fieldData?.displayType?.toLowerCase() == 'date'"
                style="margin-bottom: 20px; padding: 0 3px"
              >
                <span
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    margin-bottom: 20px;
                  "
                >
                  <b>Date preferences</b>
                  <span style="font-size: 12px"
                    >Your Desired Input Options</span
                  >
                </span>
                <hlx-input
                  v-model:value="
                    fieldData.others.customDatePreference.preference
                  "
                  :pre-val="fieldData.others.customDatePreference.preference"
                  :type="'radio-buttons'"
                  :options="datePreferences"
                >
                </hlx-input>
                <br
                  v-if="
                    fieldData.others.customDatePreference.preference == 'range'
                  "
                />
                <hlx-date-picker
                  v-if="
                    fieldData.others.customDatePreference.preference == 'range'
                  "
                  :pre-value="fieldData.others.customDatePreference.range"
                  from-key="from"
                  to-key="to"
                  :label-animation="true"
                  :label-value="'Select date range'"
                  :left="550"
                  :top="160"
                  type="date"
                  icon="calendar-regular"
                  icon-position="right"
                  required="true"
                  @selected-range="customDatePreferenceRange"
                ></hlx-date-picker>
                <br />
                <span
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    margin-bottom: 20px;
                  "
                >
                  <b>Custom date selector</b>
                  <span style="font-size: 12px"
                    >Business Day Focused Date Selection Made Simple</span
                  >
                </span>
                <hlx-input
                  v-model:value="fieldData.others.businessDatePreference"
                  :type="'checkbox'"
                  :options="businessDatePreferences"
                >
                </hlx-input>
              </div>
            </div>
          </div>
          <!-- visibility rule block  -->
          <div
            v-show="selectedDefinitionTab == 'Visibility rule'"
            class="visibility-rule-block"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
              "
            >
              <span>Visibility rule</span>
              <hlx-input
                v-model:value="fieldData.others.isVisibilityRuleEnabled"
                :checked="fieldData.others.isVisibilityRuleEnabled"
                :type="'switch'"
              ></hlx-input>
            </div>
            <div
              style="
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: space-between;
                padding: 8px;
                border: 1px solid #d8d8d8;
                border-radius: 5px;
                margin-bottom: 20px;
              "
            >
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span> Field name </span>
                <span>
                  {{
                    fieldData.fieldName != "" &&
                    fieldData.fieldName != undefined
                      ? fieldData.fieldName
                      : "-"
                  }}
                </span>
              </div>
              <br />
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span> Input type </span>
                <span>
                  {{
                    fieldData.displayType !== ""
                      ? fieldData?.displayType?.charAt(0)?.toUpperCase() +
                        fieldData?.displayType?.slice(1)
                      : "-"
                  }}
                </span>
              </div>
            </div>
            <div
              v-if="fieldData.others.isVisibilityRuleEnabled"
              style="display: flex; align-items: center"
            >
              When
              <span style="width: 70px; margin: 0 20px"
                ><hlx-select
                  v-model:value="fieldData.others.visibilityRuleCondition"
                  :pre-value="'All'"
                  :prop-value="'name'"
                  :options="visibilityRuleConditionTypeOptions"
                ></hlx-select
              ></span>
              of the following matches,
            </div>
            <div v-if="fieldData.others.isVisibilityRuleEnabled">
              <span
                v-for="(ruleItem, itemIndex) in visibilityRulePayload"
                :key="itemIndex"
                class="rule-block"
                style="display: block; margin: 16px 0"
              >
                <span>
                  {{ "Rule - " + (itemIndex + 1) }}
                </span>
                <span
                  style="
                    display: block;
                    margin: 16px 0px 0px 0px;
                    padding: 16px 10px;
                    /* background-color: #A6A6A666; */
                    background: #d8d8d830;
                    border-radius: 5px;
                  "
                >
                  <span
                    style="
                      display: flex;
                      flex-direction: column;
                      gap: 20px;
                      align-items: center;
                    "
                  >
                    <hlx-select
                      v-model:value="ruleItem.fieldName"
                      :options="fieldDataForDropdowns"
                      :pre-value="
                        ruleItem.fieldName !== ''
                          ? ruleItem.fieldName
                          : setFirstItemAsDefault(ruleItem)
                      "
                      :prop-value="'fieldName'"
                      label="name"
                      @selected-value="selectedFieldItem($event, ruleItem)"
                    ></hlx-select>
                    <hlx-select
                      v-model:value="ruleItem.operator"
                      :prop-value="'value'"
                      :options="getVisibilityRuleOperatorTypeOptions(ruleItem)"
                      :pre-value="
                        getVisibilityRuleOperatorTypeOptions(ruleItem).some(
                          (item) => item.value == ruleItem?.operator
                        )
                          ? ruleItem.operator
                          : (getVisibilityRuleOperatorTypeOptions(ruleItem)[0]
                              ?.value,
                            (ruleItem.operator =
                              getVisibilityRuleOperatorTypeOptions(
                                ruleItem
                              )[0]?.value),
                            (ruleItem.showType =
                              getVisibilityRuleOperatorTypeOptions(
                                ruleItem
                              )[0]?.show))
                      "
                      @selected-value="selectedOperatorObject($event, ruleItem)"
                    ></hlx-select>
                  </span>
                  <span
                    v-if="ruleItem.showType !== ''"
                    style="display: grid; margin-top: 20px; width: 100%"
                  >
                    <hlx-multi-select
                      v-if="ruleItem.showType == 'dropdown'"
                      :prop-value="'name'"
                      placeholder-value="Values"
                      :options="ruleItem.options"
                      :pre-value="ruleItem.values"
                      :tooltipposition="'left'"
                      @selected-array="selectedValues($event, ruleItem)"
                    ></hlx-multi-select>
                    <hlx-input
                      v-else-if="ruleItem.showType == 'input'"
                      v-model:value="ruleItem.value"
                      :pre-val="ruleItem.value"
                    >
                    </hlx-input>
                    <hlx-input
                      v-else-if="ruleItem.showType == 'date'"
                      v-model:value="ruleItem.value"
                      type="date"
                      icon="calendar-regular"
                      icon-position="right"
                      :pre-val="ruleItem.value"
                    >
                    </hlx-input>
                    <hlx-input
                      v-else-if="ruleItem.showType == 'number'"
                      v-model:value="ruleItem.value"
                      type="number"
                      :pre-val="ruleItem.value"
                    >
                    </hlx-input>
                    <div
                      v-else-if="ruleItem.showType == 'double-number'"
                      style="display: flex; flex-direction: column; gap: 10px"
                    >
                      <hlx-input
                        v-model:value="ruleItem.values[0]"
                        type="number"
                        :pre-val="ruleItem.values[0]"
                      >
                      </hlx-input>
                      <hlx-input
                        v-model:value="ruleItem.values[1]"
                        type="number"
                        :pre-val="ruleItem.values[1]"
                      >
                      </hlx-input>
                    </div>
                    <div
                      v-else-if="ruleItem.showType == 'double-date'"
                      style="display: flex; flex-direction: column; gap: 10px"
                    >
                      <hlx-input
                        v-model:value="ruleItem.values[0]"
                        type="date"
                        icon="calendar-regular"
                        icon-position="right"
                        :pre-val="ruleItem.values[0]"
                      >
                      </hlx-input>
                      <hlx-input
                        v-model:value="ruleItem.values[1]"
                        type="date"
                        icon="calendar-regular"
                        icon-position="right"
                        :pre-val="ruleItem.values[1]"
                      >
                      </hlx-input>
                    </div>
                  </span>
                  <div
                    v-if="visibilityRulePayload.length > 1"
                    style="
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                    "
                  >
                    <span
                      style="color: #fc3232; cursor: pointer"
                      @click="deleteVisibilityRuleBlock(itemIndex)"
                      ><i class="icon-trash-regular"></i> &nbsp; Delete</span
                    >
                  </div>
                </span>
                <br />
                <hr />
              </span>
            </div>
            <div
              v-if="fieldData.others.isVisibilityRuleEnabled"
              style="
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <span
                style="color: #54bd95; cursor: pointer"
                @click="addOneMoreVisibilityRuleBlock"
                >+ Add condition</span
              >
            </div>
            <div
              v-if="fieldData.others.isVisibilityRuleEnabled"
              style="display: flex; flex-direction: column; gap: 16px"
            >
              <span>Then,</span>
              <hlx-select
                v-model:value="fieldData.others.visibilityRuleAction"
                :prop-value="'value'"
                label="name"
                :options="visibilityRuleActionTypeOptions"
                :pre-value="fieldData.others.visibilityRuleAction"
              ></hlx-select>
            </div>
          </div>
          <!-- validation rule block  -->
          <div
            v-show="selectedDefinitionTab == 'Field validation'"
            class="visibility-rule-block"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
              "
            >
              <span>Field validation</span>
              <hlx-input
                v-model:value="fieldData.others.isValidationRuleEnabled"
                :checked="fieldData.others.isValidationRuleEnabled"
                :disabled="fieldData.displayType == 'header'"
                :type="'switch'"
              ></hlx-input>
            </div>
            <div
              style="
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: space-between;
                padding: 8px;
                border: 1px solid #d8d8d8;
                border-radius: 5px;
                margin-bottom: 20px;
              "
            >
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span> Field name </span>
                <span>
                  {{
                    fieldData.fieldName != "" &&
                    fieldData.fieldName != undefined
                      ? fieldData.fieldName
                      : "-"
                  }}
                </span>
              </div>
              <br />
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span> Input type </span>
                <span>
                  {{
                    fieldData.displayType !== ""
                      ? fieldData?.displayType?.charAt(0)?.toUpperCase() +
                        fieldData?.displayType?.slice(1)
                      : "-"
                  }}
                </span>
              </div>
            </div>
            <!-- <div
              v-if="fieldData.others.isVisibilityRuleEnabled"
              style="display: flex; align-items: center"
            >
              When
              <span style="width: 70px; margin: 0 20px"
                ><hlx-select
                  v-model:value="fieldData.others.visibilityRuleCondition"
                  :pre-value="'All'"
                  :prop-value="'name'"
                  :options="visibilityRuleConditionTypeOptions"
                ></hlx-select
              ></span>
              of the following matches,
            </div> -->
            <div v-if="fieldData.others.isValidationRuleEnabled">
              <span
                v-for="(ruleItem, itemIndex) in validationRulePayload"
                :key="itemIndex"
                style="display: block; margin: 16px 0"
              >
                <span>
                  {{ "Rule - " + (itemIndex + 1) }}
                </span>
                <span
                  style="
                    display: block;
                    margin: 16px 0px 0px 0px;
                    padding: 16px 10px;
                    /* background-color: #A6A6A666; */
                    background: #d8d8d830;
                    border-radius: 5px;
                  "
                >
                  <span
                    style="
                      display: flex;
                      flex-direction: column;
                      gap: 20px;
                      align-items: center;
                    "
                  >
                    <!-- <hlx-select
                    v-model:value="ruleItem.fieldName"
                    :options="fieldDataForDropdowns"
                    :pre-value="
                      ruleItem.fieldName !== ''
                        ? ruleItem.fieldName
                        : setFirstItemAsDefault(ruleItem)
                    "
                    :prop-value="'fieldName'"
                    label="name"
                    @selected-value="selectedFieldItem($event, ruleItem)"
                  ></hlx-select> -->
                    <hlx-select
                      v-model:value="ruleItem.operator"
                      :prop-value="'value'"
                      :disabled="ruleItem.isNotDeletable"
                      :options="getValidationRuleOperatorTypeOptions(ruleItem)"
                      :pre-value="
                        getValidationRuleOperatorTypeOptions(ruleItem).some(
                          (item) => item.value == ruleItem?.operator
                        )
                          ? ruleItem.operator
                          : (getValidationRuleOperatorTypeOptions(ruleItem)[0]
                              ?.value,
                            (ruleItem.operator =
                              getValidationRuleOperatorTypeOptions(
                                ruleItem
                              )[0]?.value),
                            (ruleItem.showType =
                              getValidationRuleOperatorTypeOptions(
                                ruleItem
                              )[0]?.show))
                      "
                      @selected-value="selectedOperatorObject($event, ruleItem)"
                    ></hlx-select>
                  </span>
                  <span
                    v-if="ruleItem.showType !== ''"
                    style="display: grid; margin-top: 20px; width: 100%"
                  >
                    <!-- <span v-if="ruleItem.showType == 'dropdown'">{{ ruleItem.values }}</span> -->
                    <hlx-multi-select
                      v-if="ruleItem.showType == 'dropdown'"
                      :refresh-key="ruleItemKey"
                      :prop-value="'value'"
                      :label="'value'"
                      placeholder-value="Values"
                      :options="ruleItem.options"
                      :pre-value="ruleItem.values"
                      :tooltipposition="'left'"
                      @selected-array="selectedValues($event, ruleItem)"
                    ></hlx-multi-select>
                    <hlx-input
                      v-else-if="ruleItem.showType == 'input'"
                      v-model:value="ruleItem.value"
                      :pre-val="ruleItem.value"
                    >
                    </hlx-input>
                    <hlx-input
                      v-else-if="ruleItem.showType == 'date'"
                      v-model:value="ruleItem.value"
                      type="date"
                      icon="calendar-regular"
                      icon-position="right"
                      :pre-val="ruleItem.value"
                    >
                    </hlx-input>
                    <hlx-input
                      v-else-if="ruleItem.showType == 'number'"
                      v-model:value="ruleItem.value"
                      type="number"
                      :pre-val="ruleItem.value"
                    >
                    </hlx-input>
                    <div
                      v-else-if="ruleItem.showType == 'double-number'"
                      style="display: flex; flex-direction: column; gap: 10px"
                    >
                      <hlx-input
                        v-model:value="ruleItem.values[0]"
                        type="number"
                        :pre-val="ruleItem.values[0]"
                      >
                      </hlx-input>
                      <hlx-input
                        v-model:value="ruleItem.values[1]"
                        type="number"
                        :pre-val="ruleItem.values[1]"
                      >
                      </hlx-input>
                    </div>
                    <div
                      v-else-if="ruleItem.showType == 'double-date'"
                      style="display: flex; flex-direction: column; gap: 10px"
                    >
                      <hlx-input
                        v-model:value="ruleItem.values[0]"
                        type="date"
                        icon="calendar-regular"
                        icon-position="right"
                        :pre-val="ruleItem.values[0]"
                      >
                      </hlx-input>
                      <hlx-input
                        v-model:value="ruleItem.values[1]"
                        type="date"
                        icon="calendar-regular"
                        icon-position="right"
                        :pre-val="ruleItem.values[1]"
                      >
                      </hlx-input>
                    </div>
                  </span>
                  <br />
                  <span style="margin-top: 20px">
                    <div
                      v-for="(languages, langIndex) in fieldData.labels"
                      :key="langIndex"
                    >
                      <hlx-input
                        :key="langIndex"
                        v-model:value="ruleItem.validationMessages[langIndex]"
                        :pre-val="ruleItem.validationMessages[langIndex]"
                        :label-value="`Validation message - ${fieldData.labels[langIndex].name}`"
                        :label-animation="true"
                        :character-limit="100"
                        :required="langIndex == 'en' ? true : false"
                        :display-error="true"
                        type="textarea"
                      ></hlx-input>
                    </div>
                  </span>
                  <div
                    v-if="validationRulePayload.length > 1"
                    style="
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                    "
                  >
                    <span
                      v-if="!ruleItem.isNotDeletable"
                      style="color: #fc3232; cursor: pointer"
                      @click="deleteValidationRuleBlock(itemIndex)"
                      ><i class="icon-trash-regular"></i> &nbsp; Delete</span
                    >
                  </div>
                </span>
                <br />
                <hr />
              </span>
            </div>
            <div
              v-if="fieldData.others.isValidationRuleEnabled"
              style="
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <span
                style="color: #54bd95; cursor: pointer"
                @click="addOneMoreValidationRuleBlock"
                >+ Add condition</span
              >
            </div>
            <!-- <div
              v-if="fieldData.others.isVisibilityRuleEnabled"
              style="display: flex; flex-direction: column; gap: 16px"
            >
              <span>Then,</span>
              <hlx-select
                v-model:value="fieldData.others.visibilityRuleAction"
                :prop-value="'value'"
                label="name"
                :options="visibilityRuleActionTypeOptions"
                :pre-value="fieldData.others.visibilityRuleAction"
              ></hlx-select>
            </div> -->
          </div>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeDrawer"
            >Cancel</hlx-button
          >
          <hlx-button
            v-if="editMode == false"
            class="primary sm"
            style="margin-left: 10px"
            :disabled="!canAdd"
            @click="addCustomItem"
            >Add</hlx-button
          >
          <hlx-button
            v-else-if="editMode == true && fieldData.fieldName !== ''"
            class="primary sm"
            :disabled="!canEdit"
            style="margin-left: 10px"
            @click="editCustomItem"
            >Save</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <div class="add-contact-drawer">
    <hlx-drawer
      :key="resetComponent"
      :show="showExistingFieldPanel"
      position="right"
      width="450"
      :footer="true"
      :title="'Choose from library'"
      :show-close-icon="true"
      @close="closeExistingFieldsDrawer"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <b>Choose from library</b>
        </div>
        <div class="body-area-contact-drawer">
          <hlx-select
            :key="existingFieldKey"
            :inline-search="true"
            :placeholder-value="'Select field from library'"
            :label-animation="true"
            :prop-value="'name'"
            :label="'name'"
            :options="existingFieldData"
            @selected-value="chosenExistingFieldData"
          >
          </hlx-select>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeExistingFieldsDrawer"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 10px"
            @click="addExistingItem"
            >Add</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <section>
    <section style="display: flex; font-size: 14px">
      <section
        class="cm-fields-left-panel responsiveSM"
        style="background-color: transparent; overflow-y: hidden; width: 110px"
      >
        <div
          class="left-panel-container"
          style="
            font-size: 16px;
            display: flex;
            padding: 12px 10px;
            cursor: pointer;
            align-items: center;
          "
          @click="previousLevel"
        >
          <i
            class="icon-arrow-left-regular"
            style="display: flex; position: relative; margin-right: 8px"
          ></i>
          <p>Back</p>
        </div>
        <span style="margin-top: 25px"> </span>
      </section>
      <section style="width: 100%">
        <div
          class="title-bar"
          style="
            display: flex;
            padding: 26px 20px 0px;
            /* align-items: center; */
            justify-content: space-between;
            flex-direction: column;
          "
        >
          <span style="display: flex; flex-direction: column">
            <b style="margin-bottom: 8px">{{ "Task" }}</b>
            <span>{{ "Create fields tailored to your specific needs" }}</span>
          </span>
          <span
            style="
              display: flex;
              padding-top: 35px;
              align-items: center;
              /* gap: 20%; */
              justify-content: space-between;
            "
          >
            <p
              class="--upcv2-header-title --staus-label"
              style="
                display: flex;
                gap: 0.5rem;
                align-items: flex-start;
                flex-direction: column;
              "
            >
              <span style="display: flex">
                <span>{{ originalFieldData?.name }} </span>
                <span style="margin-left: 10px"
                  ><hlx-status :type="originalFieldData?.status"></hlx-status
                ></span>
                <span>
                  <i
                    class="icon-edit-filled"
                    @click="editTemplate(originalFieldData)"
                  ></i>
                </span>
              </span>
              <span style="color: #a6a6a6; font-size: 11px; font-weight: 400">{{
                originalFieldData?.description
              }}</span>
              <span style="display: flex; align-items: center; gap: 10px">
                <span>Task category : </span>
                <span style="width: 300px">
                  <div>
                    <hlx-select
                      :key="componentkeyselect"
                      :single-border="true"
                      :inline-search="true"
                      :options="categoryType"
                      :label="'value'"
                      :prop-value="'value'"
                      :placeholder-value="'Category'"
                      :required="false"
                      :label-animation="false"
                      :clearable="false"
                      :pre-value="originalFieldData?.category"
                      @custom-change="handleFieldsType"
                      @selected-value="categoryData"
                    ></hlx-select>
                  </div>
                </span>
              </span>
            </p>
            <span style="display: flex; justify-content: flex-end; gap: 8px">
              <hlx-search
                v-model="searchTerm"
                @search-key="fieldSearch"
              ></hlx-search>

              <hlx-button
                class="secondary sm custom-field-add-btn"
                @click="fromAddContextMenu"
                ><i class="icon-plus-circle-regular"></i>&nbsp; Add
                field</hlx-button
              >

              <hlx-button
                class="primary sm add-btn"
                style="margin-left: 10px"
                @click="publichtheTemplate"
                ><i
                  class="icon-send-right-regular"
                  style="margin-right: 5px"
                ></i
                >Publish</hlx-button
              >
            </span>
          </span>
        </div>
        <div
          style="
            padding: 20px;
            height: calc(100vh - 75px);
            display: flex;
            flex-direction: column;
            overflow-y: auto;
          "
        >
          <!-- <br /> -->
          <b>{{ $route.params.name }}</b>
          <br />
          <hlx-switch
            v-model="selectedSubTab"
            :switch-items="tabData"
            :tab-view="true"
            value-prop="value"
            display-prop="name"
            @change="chosenSubTab"
          />
          <br />
          <div class="fields-container" style="display: flex !important; width: 100% !important; grid-gap: 2rem !important;flex-direction: column;">
            <span
            v-for="(field, fieldIndex) in getFilteredConcattedFields(false)"
            :id="'custom-field-' + fieldIndex"
            :key="fieldIndex"
            style="width: 100% !important; "
              draggable="true"
              @dragstart="dragRowStart(fieldIndex)"
              @dragover.prevent
              @dragleave="dragRowLeave(fieldIndex)"
              @dragend="dragRowEnd(fieldIndex)"
              @mouseover="showControls(fieldIndex)"
              @mouseleave="hideControls(fieldIndex)"
            >
              <span class="field-item">
                <span class="field-item-child">
                  <i class="icon-drag-vertical-filled"></i>
                  <span>{{ field.labels[userLanguage]?.label }}</span>
                  <span v-if="field.required" style="color: #ff5050">{{
                    " * "
                  }}</span>
                  <hlx-label class="primary sm">{{
                    field.displayType.charAt(0).toUpperCase() +
                    field.displayType.slice(1)
                  }}</hlx-label>
                  <div
                    v-if="field.system"
                    data-tooltip="This is a system field."
                    data-tooltip-location="bottom"
                  >
                    <i class="icon-check-circle-filled"></i>
                  </div>
                </span>
                <span
                  :id="'show-field-controls-' + fieldIndex"
                  class="show-field-on-hover"
                >
                  <i
                    class="icon-edit-regular"
                    @click="editField(fieldIndex, false)"
                  ></i>
                  <i
                    v-if="!field.system"
                    class="icon-trash-regular"
                    @click="removeFieldModal(fieldIndex, false)"
                  ></i>
                </span>
              </span>
            </span>
          </div>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";

//import leftPanel from "@/components/leftpannelComponent.vue";
import { toRaw } from "vue";

export default {
  data() {
    return {
      filteredstatus: [],

      tableData: [],
      templateFilterQuery: {
        type: "filter",
        module: "execution",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: this.$cookies.get("company"),
      },
      categoryType: [],
      add_model: false,
      editPayload: {},
      companyName: "",
      selectedSubTab: "",
      tabData: [],
      existingFieldKey: 0,
      overwriteCanEdit: false,
      searchTerm: "",
      ogConcattedFields: [],
      isFieldNameInvalid: false,
      isRefOrNotOptions: [
        {
          name: "Enter manually",
          value: "manual",
          checked: true,
        },
        {
          name: "Choose from picklist",
          value: "reference",
          checked: false,
        },
        // {
        //   name: 'Custom options',
        //   value: 'custom',
        //   checked: false,
        // },
      ],
      fieldDataCopy: {},
      customHierarchyPayload: [],
      canEdit: true,
      isLabelNameInvalid: false,
      aclNavigationData: [],
      selectedExistingFieldData: {},
      existingFieldData: [],
      showExistingFieldPanel: false,
      tempSchemaRef: "",
      ruleItemKey: 0,
      csvValues: "",
      roleSegment: "",
      authenticationMethods: [
        {
          name: "No Auth",
        },
        {
          name: "API Key",
        },
        {
          name: "Basic Auth",
        },
        {
          name: "Bearer Token",
        },
      ],
      businessDatePreferences: [
        {
          name: "Allow only business days",
          value: "business-days-only",
          checked: false,
        },
        {
          name: "Default to today's date",
          value: "default-today",
          checked: false,
        },
      ],
      datePreferences: [
        { name: "Allow all days", value: "all-days" },
        { name: "Allow future dates only", value: "future-dates" },
        { name: "Allow past dates only", value: "past-dates" },
        { name: "Apply date range", value: "range" },
      ],
      definitionTabs: [
        {
          name: "Configuration",
          value: "Configuration",
        },
        {
          name: "Visibility rule",
          value: "Visibility rule",
        },
        {
          name: "Field validation",
          value: "Field validation",
        },
        {
          name: "Custom logic",
          value: "Custom logic",
        },
      ],
      selectedDefinitionTab: "Configuration",
      intellisenseData: {
        schema: [],
        api: [{ label: "API()" }],
        email: [{ label: "Email()" }],
        keywords: [
          { label: "if" },
          { label: "then" },
          { label: "else" },
          { label: "and" },
          { label: "or" },
          { label: "function" },
          { label: "filter" },
          { label: "reduce" },
          { label: "map" },
          { label: "Date" },
          { label: "Math" },
          { label: "slice" },
          { label: "substr" },
          { label: "concat" },
          { label: "true" },
          { label: "false" },
        ],
        methods: {
          array: [
            {
              label: "filter",
              syntax:
                "array.filter(function(currentValue, index, arr), thisValue)",
            },
            {
              label: "reduce",
              syntax:
                "array.reduce(function(total, currentValue, currentIndex, arr), initialValue)",
            },
            {
              label: "map",
              syntax:
                "array.map(function(currentValue, index, arr), thisValue)",
            },
          ],
          date: [
            {
              label: "getDay",
              syntax: "getDay()",
            },
            {
              label: "getDate",
              syntax: "getDate()",
            },
            {
              label: "getTime",
              syntax: "getTime()",
            },
            {
              label: "getMonth",
              syntax: "getMonth()",
            },
            {
              label: "getFullYear",
              syntax: "getFullYear()",
            },
            {
              label: "setDate",
              syntax: "setDate()",
            },
            {
              label: "setTime",
              syntax: "setTime()",
            },
            {
              label: "setMonth",
              syntax: "setMonth()",
            },
            {
              label: "setFullYear",
              syntax: "setFullYear()",
            },
          ],
          integer: [
            {
              label: "random",
              syntax: "Math.random()",
            },
            {
              label: "round",
              syntax: "Math.round(x)",
            },
          ],
          string: [
            { label: "charAt", syntax: "string.charAt(index)" },
            { label: "slice", syntax: "string.slice(start, end)" },
          ],
        },
      },
      visibilityRuleReset: 0,
      canAdd: false,
      notification: {},
      deleteIndex: null,
      deleteVal: null,
      deleteName: null,
      deleteModal: false,
      resetDropdown: 0,
      fieldDataForDropdowns: [],
      visibilityRulePayload: [
        {
          fieldName: "",
          fieldNameRef: "",
          values: [],
          value: "",
          operator: "",
          options: [],
          displayType: "",
          optionRef: "",
          showType: "",
        },
      ],
      validationRulePayload: [
        {
          fieldName: "",
          fieldNameRef: "",
          values: [],
          value: "",
          operator: "",
          options: [],
          displayType: "",
          optionRef: "",
          showType: "",
          validationMessages: {
            en: "",
          },
        },
      ],
      visibilityRuleOperatorTypeOptions: [
        {
          name: "Is not empty",
          value: "Is not empty",
          displayType: ["input", "texteditor"],
          show: "",
        },
        {
          name: "Is empty",
          value: "Is empty",
          displayType: [
            "input",
            "textarea",
            "texteditor",
            "dropdown",
            "radio",
            "multiselect",
            "status",
            "date",
            "number",
            "currency",
            "checkbox",
            "siteaddress",
            "formula",
            "email",
            "user",
            "url",
          ],
          show: "",
        },
        {
          name: "Contains",
          value: "Contains",
          displayType: ["input"],
          show: "input",
        },
        {
          name: "Does not contain",
          value: "Does not contain",
          displayType: ["input"],
          show: "input",
        },
        {
          name: "Matches regex",
          value: "Matches regex",
          displayType: ["input"],
          show: "input",
        },
        {
          name: "Length >",
          value: "Length >",
          displayType: ["input", "texteditor"],
          show: "number",
        },
        {
          name: "Length <",
          value: "Length <",
          displayType: ["input", "texteditor"],
          show: "number",
        },
        {
          name: "Contains keyword",
          value: "Contains keyword",
          displayType: ["textarea", "texteditor"],
          show: "input",
        },
        {
          name: "Does not contain keyword",
          value: "Does not contain keyword",
          displayType: ["textarea", "texteditor"],
          show: "input",
        },
        {
          name: "Is selected",
          value: "Is selected",
          displayType: ["dropdown", "radio", "multiselect", "status"],
          show: "dropdown",
        },
        {
          name: "Is selected date",
          value: "Is selected date",
          displayType: ["date"],
          show: "date",
        },
        {
          name: "Is not selected",
          value: "Is not selected",
          displayType: ["dropdown", "radio", "multiselect", "status"],
          show: "dropdown",
        },
        {
          name: "Is equal to",
          value: "Is equal to",
          displayType: [
            "dropdown",
            "radio",
            "multiselect",
            "number",
            "formula",
            "status",
          ],
          show: "input",
        },
        {
          name: "Is one of",
          value: "Is one of",
          displayType: ["multiselect"],
          show: "dropdown",
        },
        {
          name: "Is not one of",
          value: "Is not one of",
          displayType: ["multiselect"],
          show: "dropdown",
        },
        {
          name: "Is checked",
          value: "Is checked",
          displayType: ["checkbox"],
          show: "dropdown",
        },
        {
          name: "Is unchecked",
          value: "Is unchecked",
          displayType: ["checkbox"],
          show: "dropdown",
        },
        {
          name: "Is null",
          value: "Is null",
          displayType: ["date", "siteaddress"],
          show: "",
        },
        {
          name: "Is not null",
          value: "Is not null",
          displayType: ["date", "siteaddress"],
          show: "",
        },
        {
          name: "Is before",
          value: "Is before",
          displayType: ["date"],
          show: "date",
        },
        {
          name: "Is after",
          value: "Is after",
          displayType: ["date"],
          show: "date",
        },
        {
          name: "Is greater than",
          value: "Is greater than",
          displayType: ["number", "currency", "formula"],
          show: "number",
        },
        {
          name: "Is greater than or equal to",
          value: "Is greater than or equal to",
          displayType: ["number", "currency", "formula"],
          show: "number",
        },
        {
          name: "Is less than or equal to",
          value: "Is less than or equal to",
          displayType: ["number", "currency", "formula"],
          show: "number",
        },
        {
          name: "Is not equal to",
          value: "Is not equal to",
          displayType: ["number", "currency", "formula"],
          show: "number",
        },
        {
          name: "Is within range (X to Y)",
          value: "Is within range (X to Y)",
          displayType: ["number", "currency", "formula"],
          show: "double-number",
        },
        {
          name: "Is greater than equal to specific date",
          value: "Is greater than equal to specific date",
          displayType: ["date"],
          show: "date",
        },
        {
          name: "Greater than or equal to X days from today",
          value: "Greater than or equal to X days from today",
          displayType: ["date"],
          show: "number",
        },
        {
          name: "Greater than X days from today",
          value: "Greater than X days from today",
          displayType: ["date"],
          show: "number",
        },
        {
          name: "Is less than",
          value: "Is less than",
          displayType: ["number", "currency", "formula"],
          show: "number",
        },
        {
          name: "Is less than equal to specific date",
          value: "Is less than equal to specific date",
          displayType: ["date"],
          show: "date",
        },
        {
          name: "Less than or equal to X days from today",
          value: "Less than or equal to X days from today",
          displayType: ["date"],
          show: "number",
        },
        {
          name: "Less than X days from today",
          value: "Less than X days from today",
          displayType: ["date"],
          show: "number",
        },
        {
          name: "Equal to X day(s) from today",
          value: "Equal to X day(s) from today",
          displayType: ["date"],
          show: "number",
        },
        {
          name: "Is within X day(s) from today",
          value: "Is within X day(s) from today",
          displayType: ["date"],
          show: "number",
        },
        {
          name: "Is between (X and Y days) from today",
          value: "Is between (X and Y days) from today",
          displayType: ["date"],
          show: "double-number",
        },
        {
          name: "Is within date range",
          value: "Is within date range",
          displayType: ["date"],
          show: "double-date",
        },
        {
          name: "Is valid email",
          value: "Is valid email",
          displayType: ["email"],
          show: "",
        },
        {
          name: "Is not unique email",
          value: "Is not unique email",
          displayType: ["email"],
          show: "",
        },
        {
          name: "Is not unique address",
          value: "Is not unique address",
          displayType: ["siteaddress"],
          show: "",
        },
        {
          name: "Contains domain",
          value: "Contains domain",
          displayType: ["email"],
          show: "input",
        },
        {
          name: "Does not contain domain",
          value: "Does not contain domain",
          displayType: ["email"],
          show: "input",
        },
        {
          name: "Is complete",
          value: "Is complete",
          displayType: ["siteaddress"],
          show: "",
        },
        {
          name: "Is valid URL",
          value: "Is valid URL",
          displayType: ["url"],
          show: "",
        },
        {
          name: "Is secure (https)",
          value: "Is secure (https)",
          displayType: ["url"],
          show: "",
        },
        {
          name: "Is not secure (http)",
          value: "Is not secure (http)",
          displayType: ["url"],
          show: "",
        },
        {
          name: "Matches domain",
          value: "Matches domain",
          displayType: ["url"],
          show: "input",
        },
        {
          name: "Length <",
          value: "Length <",
          displayType: ["url"],
          show: "number",
        },
        {
          name: "Is uploaded",
          value: "Is uploaded",
          displayType: ["profile-image-upload"],
          show: "",
        },
        {
          name: "Matches file type",
          value: "Matches file type",
          displayType: ["profile-image-upload"],
          show: "input",
        },
        {
          name: "Matches file size limit",
          value: "Matches file size limit",
          displayType: ["profile-image-upload"],
          show: "number",
        },
      ],
      visibilityRuleConditionTypeOptions: [
        {
          name: "All",
          value: "All",
        },
        {
          name: "Any",
          value: "Any",
        },
      ],
      visibilityRuleActionTypeOptions: [
        {
          name: "Show field",
          value: "show",
        },
        {
          name: "Hide field",
          value: "hide",
        },
      ],
      userLanguage: "en",
      lookupModules: [
        {
          module: "Accounts",
          instance: "MLTAxiosInstance",
          filterPayload: {
            type: "filter-search",
            module: "hierarchyaccounts",
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: false,
            sortFieldAndOrder: {},
            limit: 10,
            filterQuery: {
              "@baseType": "customer",
            },
            searchQuery: "",
          },
          url: "/util/filterData",
        },
        {
          module: "Contacts",
          instance: "MLTAxiosInstance",
          filterPayload: {
            company: this.companyName,
            type: "filter-search",
            module: "hierarchyaccounts",
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 5,
              currentPage: 1,
            },
            isNeedToBeSort: true,
            sortFieldAndOrder: {},
            limit: 2,
            filterQuery: {
              "@baseType": {
                $ne: "supplier",
              },
            },
            searchQuery: "",
          },
          url: "/util/filterData",
        },
        {
          module: "Residential contacts",
          instance: "MLTAxiosInstance",
          filterPayload: {
            type: "filter-search",
            module: "contacts",
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: false,
            sortFieldAndOrder: {},
            limit: 10,
            filterQuery: {
              "@baseType": "residential-customer",
            },
            searchQuery: "",
          },
          url: "/util/filterData",
        },
        {
          module: "Sales lead",
          instance: "MLTAxiosInstance",
          filterPayload: {
            type: "filter-search",
            module: "saleslead",
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: false,
            sortFieldAndOrder: {},
            limit: 10,
            filterQuery: {
              "role.name": "Account executive",
              name: {
                $regex: "",
                $options: "i",
              },
            },
            searchQuery: "",
          },
          url: "/util/filterData",
        },
      ],
      lookupFieldTypes: [
        {
          name: "Input",
          value: "input",
        },
        {
          name: "Dropdown",
          value: "dropdown",
        },
      ],
      addressAPIOptions: [
        {
          name: "Google maps API",
          value: "Google maps API",
        },
        {
          name: "Canada post API",
          value: "Canada post API",
        },
      ],
      userRoles: [],
      displayTypeOptions: [
        { name: "Address", value: "siteaddress" },
        { name: "Checkbox", value: "checkbox" },
        { name: "Currency", value: "currency" },
        { name: "Date", value: "date" },
        { name: "Dropdown", value: "dropdown" },
        { name: "Email", value: "email" },
        { name: "Formula", value: "formula" },
        { name: "Header", value: "header" },
        { name: "Lookup", value: "lookup" },
        { name: "Multi line text", value: "textarea" },
        { name: "Multiselect", value: "multiselect" },
        { name: "Number", value: "number" },
        { name: "Phone number", value: "phonenumber" },
        { name: "Radio select", value: "radio" },
        { name: "Single line text", value: "input" },
        { name: "Single Border text", value: "singleinput" },
        { name: "Status", value: "status" },
        { name: "Switch", value: "switch" },

        { name: "Text editor", value: "texteditor" },
        { name: "Upload - profile picture", value: "profile-image-upload" },
        { name: "Upload - Regular", value: "profile-regular" },
        { name: "Suite run", value: "suite-run" },
        { name: "URL", value: "url" },
        { name: "User", value: "user" },
      ],
      contextOptions: [
        {
          label: "Add a new field",
          value: "newfield",
          icon: "icon-plus-circle-regular",
        },
        {
          label: "Choose from library",
          value: "existingfield",
          icon: "icon-server-regular",
        },
      ],
      containerleft1: 0,
      containertop1: 0,
      showContextMenu: false,
      refDataKey: 0,
      dragRefDataStartIndex: null,
      dragRefDataEndIndex: null,
      newRefData: [
        {
          value: "",
          name: "",
          english: "",
          type: "String",
          required: false,
          default: false,
        },
      ],
      editMode: false,
      fieldData: {
        isRef: false,
        required: false,
        isTooltip: false,
        tooltipEnglish: "",
        tooltipFrench: "",
        show: true,
        labels: {
          en: {
            label: "",
            name: "English",
            tooltip: "",
          },
        },
        others: {
          isServiceQualifcationEnabled: false,
          businessDatePreference: [
            {
              name: "Allow only business days",
              value: "business-days-only",
              checked: true,
            },
            {
              name: "Default to today's date",
              value: "default-today",
              checked: false,
            },
          ],
          customDatePreference: {
            preference: "",
            range: { from: "", to: "" },
          },
          userRole: [],
          readOnly: false,
          lookup: {
            minCharToSearch: 1,
            maxResultToDisplay: 5,
            module: "",
            instance: "",
            fieldType: "input",
            filterPayload: {},
            url: "",
            lookupProp: "",
            isDataRemapEnabled: false,
            remapData: "",
          },
          addressAPI: {
            authType: "",
            token: "",
            userName: "",
            password: "",
            type: "canada post",
            key: "",
          },
          isVisibilityRuleEnabled: false,
          isValidationRuleEnabled: false,
          showValidation: false,
          validationMessage: "",
          ruleIdReference: "",
          ruleData: "",
          isVisible: true,
          visibilityRulePayload: [
            {
              fieldName: "",
              fieldNameRef: "",
              values: [],
              value: "",
              operator: "",
              options: [],
              displayType: "",
              optionRef: "",
              showType: "",
            },
          ],
          validationRulePayload: [
            {
              fieldName: "",
              fieldNameRef: "",
              values: [],
              value: "",
              operator: "",
              options: [],
              displayType: "",
              optionRef: "",
              showType: "",
              validationMessages: {
                en: "",
              },
            },
          ],
          visibilityRuleAction: "show",
          visibilityRuleCondition: "All",
        },
      },
      editIndex: null,
      originalFieldData: [],
      selectedTab: "execution",
      concattedFields: [],
      section_data: {
        main: [],
      },
      systemGeneratedFieldsData: {},
      customGeneratedFieldsData: {},
      showAddFieldPanel: false,
      userLanguages: {},
      referenceDataOptions: [],
      statusReferenceDataOptions: [],
      statusList: [],
    };
  },
  watch: {
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList.filter(
          (item) =>
            item.name === "Active" ||
            item.name === "Inactive" ||
            item.name === "Draft"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    aclNavigationData: {
      handler(val) {
        val;
        // console.log(val, 'iopio');
      },
      immediate: true,
      deep: true,
    },
    "fieldData.others.isValidationRuleEnabled": {
      async handler(val) {
        if (val) {
          // console.log('validayio');

          await this.selectedValidationFieldItem();
        }
      },
      immediate: true,
      deep: true,
    },
    "$store.getters.loginTenantDetails.languageAbility": {
      handler(val) {
        this.userLanguages = val;
      },
      immediate: true,
      deep: true,
    },
    fieldData: {
      handler(val) {
        if (!this.editMode) {
          if (
            val.fieldName !== "" &&
            val.labels[this.userLanguage] !== undefined &&
            val.labels[this.userLanguage]?.label !== "" &&
            val.displayType !== "" &&
            !this.isFieldNameInvalid &&
            !this.isLabelNameInvalid
          ) {
            this.canAdd = true;
          } else {
            this.canAdd = false;
          }
        } else {
          // console.log(
          //   'canEdit',
          //   val,
          //   this.isFieldNameInvalid,
          //   this.isLabelNameInvalid
          // );
          if (
            val.fieldName !== "" &&
            val.labels.en !== undefined &&
            val.labels.en?.label !== "" &&
            val.displayType !== "" &&
            !this.isFieldNameInvalid &&
            !this.isLabelNameInvalid
          ) {
            // console.log(
            //   'canEdit',
            //   val,
            //   this.isFieldNameInvalid,
            //   this.isLabelNameInvalid
            // );
            if (!this.overwriteCanEdit) {
              this.canEdit = true;
            }
          } else {
            // console.log(
            //   'canEdit',
            //   val,
            //   this.isFieldNameInvalid,
            //   this.isLabelNameInvalid
            // );
            if (!this.overwriteCanEdit) {
              this.canEdit = false;
            }
          }
        }
        // console.log('fullData', val);
      },
      immediate: true,
      deep: true,
    },
    visibilityRulePayload: {
      handler(val) {
        val;
        // console.log(val, 'rulePayload');
      },
      immediate: true,
      deep: true,
    },
    validationRulePayload: {
      handler(val) {
        let isEnabledAdd = true;
        let isEnabledEdit = true;
        val.forEach((i) => {
          i.fieldName = this.fieldData.fieldName;
          i.fieldNameRef = this.fieldData.fieldNameRef;
          if (
            this.fieldData.others.isValidationRuleEnabled &&
            i.validationMessages.en == ""
          ) {
            if (this.editMode) {
              isEnabledEdit = false;
            } else {
              isEnabledAdd = false;
            }
          }
        });
        this.canAdd = isEnabledAdd;
        this.canEdit = isEnabledEdit;

        // console.log(val, 'rulePayload');
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    let refDataFilterPayload = {
      type: "filter",
      module: "reference",
      isPaginated: false,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: false,
      sortFieldAndOrder: {
        updatedAt: -1,
      },
      limit: 10,
      filterQuery: {
        isReference: true,
      },
      searchQuery: "",
      company: this.companyName,
    };
    let refData = await DMNAxiosInstance.post(
      "util/filterData",
      refDataFilterPayload
    );

    this.referenceDataOptions = refData?.data?.data?.data;
    this.statusReferenceDataOptions = refData?.data?.data?.data.filter(
      (i) => i.isStatus == true
    );

    let savedFields = await UPCAxiosInstance.get(
      `/execution/${this.$route.query.id}`
    );
    console.log(savedFields, "savedFieldssavedFields");
    this.originalFieldData = JSON.parse(JSON.stringify(savedFields.data));
    savedFields = savedFields.data.fields;
    this.intellisenseData.schema = savedFields;
    console.log(this.intellisenseData, "intelll", savedFields);
    const ticketSpec = await this.getReferanceData("agreementtaskcategoryRef");
    await this.refreshTable();
    this.ticketSpec = ticketSpec;
    this.categoryType = this.filterCategoryType(
      this.ticketSpec,
      this.tableData,
      { value: this.originalFieldData.category }
    );
    if (this.intellisenseData.schema) {
      const extractSchemaData = (fields, parentKey = "") => {
        const result = [];

        const recursiveExtract = (obj, parentKey) => {
          Object.entries(obj).forEach(([key, value]) => {
            const fullKey = parentKey ? `${parentKey}.${key}` : key; // Build dot notation key

            if (value.type) {
              result.push({
                label: fullKey,
                type: value.type !== "Mixed" ? value.type : "Array",
              });
            }
            if (value.fields) {
              recursiveExtract(value.fields, fullKey); // Traverse nested schemas
            }
          });
        };

        recursiveExtract(fields, parentKey);
        return result;
      };

      this.intellisenseData.schema = extractSchemaData(
        this.intellisenseData.schema
      );

      this.intellisenseData.schema = this.intellisenseData.schema.filter(
        (item) => item.label !== ""
      );
    }
    let currentSectionItems = await UPCAxiosInstance.get("/section");
    currentSectionItems =
      currentSectionItems.data[0].section[this.selectedTab.toLowerCase()];

    currentSectionItems;

    const filteredData = this.filterBySection(savedFields, "");

    this.systemGeneratedFieldsData = filteredData;
    console.log(
      this.systemGeneratedFieldsData,
      this.customGeneratedFieldsData,
      "this.customGeneratedFieldsDatasystemGeneratedFieldsData"
    );
    this.concattedFieldsArray();
    this.userLanguages?.forEach((item) => {
      this.fieldData.labels[item.code] = {
        label: "",
        tooltip: "",
        name: item.name,
      };
    });
    // Object.entries(
    //   this.userLanguages
    // )?.forEach(([code, name]) => {
    //   this.fieldData.labels[code] = {
    //     label: '',
    //     tooltip: '',
    //     name: name,
    //   };
    // });
    let statusList = await this.getReferanceData("statuslist");
    this.statusList = statusList;
    let roles = await MLTAxiosInstance.get("partyRole?size=1&limit=1000");
    this.userRoles = roles.data;
    this.userRoles.sort((a, b) =>
      a.role.toLowerCase() > b.role.toLowerCase()
        ? 1
        : b.role.toLowerCase() > a.role.toLowerCase()
        ? -1
        : 0
    );
    // console.log(this.userRoles, 'this.userRoles');
  },
  methods: {
    emitStatus(val) {
      this.editPayload.status = val.name;
      console.log(this.payload, "this.payload");
    },
    cancelModel() {
      this.add_model = false;
    },
    async editTemplateTicket() {
      this.editPayload.id = this.editPayload._id;
      delete this.editPayload._id;

      await this.updateTicketData(
        this.editPayload,
        "All set! Your changes have been saved successfully!"
      );
      this.cancelModel();
      this.originalFieldData.name = this.editPayload.name;
      this.originalFieldData.status = this.editPayload.status;
      this.originalFieldData.description = this.editPayload.description;
    },
    async publichtheTemplate() {
      // Find the category in tableData
      // const currentCategory = this.tableData.find(
      //   (item) => item.category === this.originalFieldData.category
      // );

      // // Check if the category is already active
      // if (currentCategory && currentCategory.status === "Active") {
      //   this.notification = {
      //     type: "warning",
      //     message: `The ${this.originalFieldData.category} category is already active!`,
      //   };
      //   return; // Stop the function here if already active
      // }

      // If not active, proceed with publishing
      this.originalFieldData.status = "Active";
      const dataSt = {
        id: this.originalFieldData._id,
        status: "Active",
      };

      try {
        const response = await UPCAxiosInstance.patch(`/execution`, dataSt);

        console.log(response.data, "data");
        this.searchvalue = "";
        // this.payload = response.data;
        this.originalFieldData.status = "Active";
        this.notification = {
          type: "success",
          message: `All set! Your template has been published successfully!`,
        };
      } catch (error) {
        console.error("Error publishing the template:", error.message);
        this.notification = {
          type: "error",
          message: `Failed to publish the template.`,
        };
      }
    },
    async updateTicketData(
      data,
      successMessage = "Changes saved successfully!"
    ) {
      try {
        await UPCAxiosInstance.patch(`/execution`, data);

        this.searchvalue = "";
        this.notification = {
          type: "success",
          message: successMessage,
        };
      } catch (error) {
        console.error("Error updating ticket data:", error.message);
        this.notification = {
          type: "error",
          message: `Error updating ticket: ${error.message}`,
        };
      }
    },
    async categoryData(val) {
      if (!this.originalFieldData.category)
        this.originalFieldData.category = "";
      this.originalFieldData.category = val.value;
      const dataSt = {
        id: this.originalFieldData._id,
        category: this.originalFieldData.category,
      };
      await this.updateTicketData(dataSt, "Category updated successfully!");
      await this.refreshTable();

      this.categoryType = await this.filterCategoryType(
        this.ticketSpec,
        this.tableData,
        { value: this.originalFieldData.category }
      );
    },
    filterCategoryType(ticketSpec, tableData, currentData) {
      const selectedCategories = tableData
        .filter((item) => item.lifecycleState === "Active")
        .map((item) => item.category || "");
      const categoryType = ticketSpec.filter(
        (option) => !selectedCategories.includes(option.value)
      );
      if (currentData) {
        categoryType.unshift(currentData);
      }
      return categoryType;
    },
    async refreshTable() {
      const response = await UPCAxiosInstance.post(
        `util/filterData`,
        this.templateFilterQuery
      );
      // const response = await UPCAxiosInstance.get(`/templateTicket`);

      this.tableData = response.data.data;
      this.totalvalue = response.data.total;
    },
    editTemplate(i) {
      this.add_model = true;
      this.editPayload = JSON.parse(JSON.stringify(i));
    },
    chosenSubTab(val) {
      console.log("devRoz", val);

      const filteredData = this.filterBySection(
        this.originalFieldData.fields,
        this.selectedTab
      );
      this.systemGeneratedFieldsData = filteredData;
      this.concattedFieldsArray();
    },
    chosenSwitch(val) {
      console.log(val, "devRoz");
    },
    getReferenceDataOptions(type) {
      if (type == "status") {
        return this.statusReferenceDataOptions;
      } else {
        return this.referenceDataOptions;
      }
    },
    fieldSearch(val) {
      val;
      if (val != "") {
        let fields = JSON.parse(JSON.stringify(this.ogConcattedFields));
        this.concattedFields = fields.filter((obj) => {
          // Check `displayType` and `fieldName`
          if (
            obj.displayType?.toLowerCase().includes(val.toLowerCase()) ||
            obj.fieldName?.toLowerCase().includes(val.toLowerCase())
          ) {
            return true;
          }

          // Check values in `labels` object
          if (obj.labels) {
            for (const locale in obj.labels) {
              const labelValues = Object.values(obj.labels[locale]);
              if (
                labelValues.some((value) =>
                  String(value).toLowerCase().includes(val.toLowerCase())
                )
              ) {
                return true;
              }
            }
          }

          return false;
        });
        // console.log('search', this.concattedFields);
      } else {
        this.concattedFields = JSON.parse(
          JSON.stringify(this.ogConcattedFields)
        );
      }
    },
    extractCustomNames(arr, roleSegment, fieldName, label, exitLabel) {
      const result = {};

      function traverse(item) {
        if (typeof item === "object" && !Array.isArray(item)) {
          // Check for the desired level and add customName to the result
          if (item.level === "section" || item.level === "module") {
            result[item.level] = item.customName;
          }
          // Traverse child objects or arrays
          for (const key in item) {
            traverse(item[key]);
          }
        } else if (Array.isArray(item)) {
          // Traverse arrays
          item.forEach(traverse);
        }
      }

      traverse(arr);
      if (roleSegment) {
        result["segment"] = roleSegment;
      }
      if (fieldName) {
        result["customName"] = fieldName;
      }
      if (label) {
        result["name"] = label;
      }
      if (exitLabel) {
        result["exitName"] = exitLabel;
      }
      return result;
    },
    mandatoryCheckBoxClicked(val) {
      // console.log(val, 'mvop');
      if (val.checked) {
        if (!this.fieldData.others.isValidationRuleEnabled) {
          this.fieldData.others.isValidationRuleEnabled = true;
          this.validationRulePayload = [];
          this.validationRulePayload.push({
            fieldName: "",
            fieldNameRef: "",
            values: [],
            value: "",
            operator: "Is empty",
            options: [],
            displayType: "",
            optionRef: "",
            isNotDeletable: true,
            showType: "",
            validationMessages: {
              en: "",
            },
          });
        } else {
          let items = this.validationRulePayload.filter((i) => {
            return i.operator == "Is empty";
          });
          if (items.length == 0) {
            this.validationRulePayload.push({
              fieldName: "",
              fieldNameRef: "",
              values: [],
              value: "",
              operator: "Is empty",
              options: [],
              displayType: "",
              optionRef: "",
              isNotDeletable: true,
              showType: "",
              validationMessages: {
                en: "",
              },
            });
          }
        }
      } else {
        this.validationRulePayload = this.validationRulePayload.filter(
          (item) => !item.isNotDeletable
        );
      }
    },
    colorValue(e, item) {
      item.hexCode = e;
    },
    addCsvValues() {
      if (this.csvValues != "") {
        let arr = this.parseCSVString(this.csvValues);
        if (this.newRefData[0].value == "") {
          this.newRefData = arr;
        } else {
          this.newRefData = [...this.newRefData, ...arr];
        }
        this.csvValues = "";
      }
    },
    parseCSVString(csvString) {
      // Trim the string and split by commas
      const values = csvString
        .split(",")
        .map((item) => item.trim())
        .filter(Boolean);

      // Transform each value into the desired object structure
      return values.map((value) => ({
        value: value,
        name: "",
        english: "",
        type: "String",
        required: false,
        default: false,
      }));
    },
    customDatePreferenceRange(val) {
      this.fieldData.others.customDatePreference.range = val;
    },
    chosenDefinitionTab(e) {
      this.selectedDefinitionTab = e.name;
      if (e.name == "Field validation") {
        this.ruleItemKey += 1;
      }
    },
    selectedLookupModule($event) {
      // console.log($event, 'lookupi');
      this.fieldData.others.lookup.filterPayload = $event.filterPayload;
      this.fieldData.others.lookup.instance = $event.instance;
      this.fieldData.others.lookup.module = $event.module;
      this.fieldData.others.lookup.url = $event.url;
    },
    selectedOperatorObject(e, item) {
      item.showType = e.show;
    },
    getVisibilityRuleOperatorTypeOptions(ruleItem) {
      let arr = this.visibilityRuleOperatorTypeOptions.filter((i) => {
        return i?.displayType?.includes(ruleItem?.displayType);
      });
      // console.log(
      //   'getVisibilityRuleOperatorTypeOptions',
      //   arr.some((item) => item.displayType.includes(ruleItem?.operator)),
      //   ruleItem.operator
      // );
      if (arr.some((item) => item.displayType.includes(ruleItem?.operator))) {
        // do nothing
      } else {
        // ruleItem.operator = arr[0].value
      }
      return arr;
    },
    getValidationRuleOperatorTypeOptions() {
      let arr = this.visibilityRuleOperatorTypeOptions.filter((i) => {
        return i?.displayType?.includes(this.fieldData?.displayType);
      });
      // console.log(
      //   'getVisibilityRuleOperatorTypeOptions',
      //   arr.some((item) => item.displayType.includes(this.fieldData?.operator)),
      //   this.fieldData.operator
      // );
      if (
        arr.some((item) => item.displayType.includes(this.fieldData?.operator))
      ) {
        // do nothing
      } else {
        // ruleItem.operator = arr[0].value
      }
      return arr;
    },
    deleteVisibilityRuleBlock(id) {
      this.visibilityRulePayload.splice(id, 1);
    },
    deleteValidationRuleBlock(id) {
      this.validationRulePayload.splice(id, 1);
    },
    async setFirstItemAsDefault(val) {
      // console.log('default value called');

      val.displayType = this.fieldDataForDropdowns[0]?.displayType;

      return this.fieldDataForDropdowns[0]?.fieldName;
    },
    async selectedFieldItem(e, val) {
      // console.log('selected value called');
      val.displayType = e.displayType;
      val.optionRef = e.optionRef;
      let dropdownData;
      if (
        val.displayType == "dropdown" ||
        val.displayType == "radio" ||
        val.displayType == "checkbox" ||
        val.displayType == "status" ||
        val.displayType == "multiselect"
      ) {
        // const response = await DMNAxiosInstance.get(
        //   `mapRef/collection/${val.optionRef}`
        // );

        // dropdownData = response?.data?.data;

        const { data } = await DMNAxiosInstance.post(`redisJSON`, {
          key: `${this.companyName}.reference`,
          query: `$.${val.optionRef}`,
        });
        dropdownData = data?.data || [];
        val.options = dropdownData;
      }
      if (this.selectedTab.toLowerCase() == "contacts") {
        val.fieldNameRef = "contacts." + val.fieldName;
      } else if (this.selectedTab.toLowerCase() == "sites") {
        val.fieldNameRef = "sites." + val.fieldName;
      } else {
        val.fieldNameRef = val.fieldName;
      }
    },
    async selectedValidationFieldItem() {
      // console.log('selected value called', this.newRefData);
      // val.displayType = e.displayType;
      // val.optionRef = e.optionRef;
      let dropdownData;
      if (this.editMode) {
        if (
          this.fieldData.displayType == "dropdown" ||
          this.fieldData.displayType == "radio" ||
          this.fieldData.displayType == "status" ||
          this.fieldData.displayType == "multiselect"
        ) {
          // const response = await DMNAxiosInstance.get(
          //   `mapRef/collection/${this.fieldData.optionRef}`
          // );

          // dropdownData = response?.data?.data;

          const { data } = await DMNAxiosInstance.post(`redisJSON`, {
            key: `${this.companyName}.reference`,
            query: `$.${this.fieldData.optionRef}`,
          });
          dropdownData = data?.data || [];
          // console.log('selected value called');

          this.validationRulePayload?.forEach((i) => {
            i.options = dropdownData;
          });
          // this.fieldData.options = dropdownData;
        }
      } else {
        if (
          this.fieldData.displayType == "dropdown" ||
          this.fieldData.displayType == "radio" ||
          this.fieldData.displayType == "status" ||
          this.fieldData.displayType == "multiselect"
        ) {
          // const response = await DMNAxiosInstance.get(
          //   `mapRef/collection/${this.fieldData.optionRef}`
          // );

          // dropdownData = response?.data?.data;
          // console.log('selected value called');

          this.validationRulePayload?.forEach((i) => {
            i.options = this.newRefData;
          });
          // this.fieldData.options = dropdownData;
        }
      }
    },
    selectedValues(e, item) {
      item.values = e.map((item) => JSON.parse(JSON.stringify(item.value)));
      // console.log('visibilityRulePayload dpd', e, item);
    },
    selectedRoles(e, i) {
      // console.log('visibilityRulePayload dpd', e, i);
      const roles = e.map((item) => item.role);
      i.userRole = roles;
      return i;
    },
    getOptionsWithFieldName() {
      let arr = [];
      arr = this.getFilteredConcattedFields(false);
      arr.forEach((i) => {
        i.name = i?.labels?.en?.label;
      });
      return arr;
    },
    addOneMoreVisibilityRuleBlock() {
      this.visibilityRulePayload.push({
        fieldName: "",
        fieldNameRef: "",
        values: [],
        value: "",
        operator: "",
        options: [],
        displayType: "",
        optionRef: "",
        showType: "",
      });
    },
    addOneMoreValidationRuleBlock() {
      let tempOptions = JSON.parse(
        JSON.stringify(this.validationRulePayload[0].options)
      );
      tempOptions.forEach((i) => {
        i.checked = false;
      });
      this.validationRulePayload.push({
        fieldName: "",
        fieldNameRef: "",
        values: [],
        value: "",
        operator: "",
        options: tempOptions,
        displayType: "",
        optionRef: "",
        showType: "",
        validationMessages: { en: "" },
      });
    },
    blurFieldName() {
      if (!this.editMode) {
        this.fieldData.fieldName = this.toCamelCaseForMongo(
          this.fieldData.fieldName
        );
        if (this.selectedTab.toLowerCase() == "contacts") {
          this.fieldData.fieldNameRef = "contacts." + this.fieldData.fieldName;
        } else if (this.selectedTab.toLowerCase() == "sites") {
          this.fieldData.fieldNameRef = "sites." + this.fieldData.fieldName;
        } else {
          this.fieldData.fieldNameRef = this.fieldData.fieldName;
        }
        this.validateFieldName();
      }
    },
    validateFieldName() {
      if (!this.editMode) {
        // Convert the reactive payload to raw data
        const rawPayload = Array.isArray(this.getFilteredConcattedFields(false))
          ? this.getFilteredConcattedFields(false).map((item) => toRaw(item))
          : [];
        // console.log(
        //   this.isFieldNameInvalid,
        //   this.getFilteredConcattedFields(false),
        //   this.fieldData.fieldName,
        //   'isFieldNameInvalid'
        // );
        this.isFieldNameInvalid = rawPayload.some(
          (item) => item.fieldName === this.fieldData.fieldName
        );
      }
    },
    validateLabelName(e, labelItem) {
      labelItem.isLabelNameInvalidMessage = "";
      let ogLabel = "";
      let existingLabel = false;
      if (this.editMode) {
        ogLabel = this.concattedFields[this.editIndex].labels;
        existingLabel = Object.values(ogLabel).some((obj) =>
          Object.values(obj).includes(e)
        );
      }
      // console.log(existingLabel, 'editIndex');
      // Convert the reactive payload to raw data
      const rawPayload = Array.isArray(this.getFilteredConcattedFields(false))
        ? this.getFilteredConcattedFields(false).map((item) => toRaw(item))
        : [];
      // console.log(
      //   this.isFieldNameInvalid,
      //   this.getFilteredConcattedFields(false),
      //   this.fieldData.fieldName,
      //   'isFieldNameInvalid'
      // );
      let isLabelNameInvalid = rawPayload.some(
        (item) => item.labels.en.label === this.fieldData.labels.en.label
      );
      if (isLabelNameInvalid && !existingLabel) {
        labelItem.isLabelNameInvalid = true;
        labelItem.isLabelNameInvalidMessage = "Label name already exists";
        this.isLabelNameInvalid = true;
      } else {
        labelItem.isLabelNameInvalid = false;
        labelItem.isLabelNameInvalidMessage = "";
        this.isLabelNameInvalid = false;
      }
    },
    toCamelCaseForMongo(input) {
      // Remove all non-alphanumeric characters except underscores
      const sanitizedInput = input?.replace(/[^a-zA-Z0-9_]/g, " ");
      if (sanitizedInput !== undefined) {
        // Convert to camel case
        const camelCaseString = sanitizedInput
          .split(/\s+/) // Split by spaces
          .map((word, index) =>
            index === 0
              ? word.toLowerCase()
              : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join("");

        // Ensure it starts with a letter or underscore for MongoDB compatibility
        if (!/^[a-zA-Z_]/.test(camelCaseString)) {
          return `_${camelCaseString}`;
        }

        return camelCaseString;
      }
    },
    removeFieldModal(index, val) {
      val;
      let filtered = this.concattedFields.filter((item) => {
        return item.show === !val;
      });
      this.deleteIndex = index;
      this.deleteVal = val;
      this.deleteName = filtered[index]?.labels[this.userLanguage]?.label;
      this.deleteModal = true;
    },
    async removeField(index, val) {
      let filtered = this.concattedFields.filter((item) => {
        return item.show === !val;
      });
      // let obj = this.fieldData;

      let payload = {};
      payload = JSON.parse(JSON.stringify(this.originalFieldData));
      payload.id = payload._id;
      delete payload._id;

      payload.fields[filtered[index].fieldName] = {};

      let patchData = await UPCAxiosInstance.patch("execution", payload);

      patchData;
      filtered.splice(index, 1);
      this.deleteModal = false;
      if (patchData.status == 200) {
        this.notification = {
          type: "success",
          message: `Done! "${this.deleteName}" field is removed from the list`,
        };
      } else {
        this.notification = {
          type: "error",
          message: `Something went wrong! try again later`,
        };
      }
      let savedFields = await UPCAxiosInstance.get(`/execution/${payload.id}`);
      this.originalFieldData = JSON.parse(JSON.stringify(savedFields.data));
      savedFields = savedFields.data.fields;

      const filteredData = this.filterBySection(savedFields, "");

      this.systemGeneratedFieldsData = filteredData;

      this.concattedFieldsArray();
    },
    concattedFieldsArray() {
      let concat = {
        ...this.systemGeneratedFieldsData,
        ...this.customGeneratedFieldsData,
      };
      //console.log('devRoz concat', concat);
      // let filteredConcat = concat
      let filteredConcat = Object.fromEntries(
        Object.entries(concat).filter(
          ([, value]) =>
            value.displayType != undefined &&
            value.displayType != "" &&
            value.displayType != "-"
        )
      );
      this.concattedFields = this.convertObjectToArray(
        JSON.parse(JSON.stringify(filteredConcat))
      );
      this.concattedFields.forEach((item, index) => {
        item.id = index;
      });
      this.ogConcattedFields = JSON.parse(JSON.stringify(this.concattedFields));
      this.fieldSearch(this.searchTerm);
      //console.log('final rox', this.concattedFields);
    },
    convertObjectToArray(obj) {
      return Object.keys(obj).map((key) => ({
        fieldName: key,
        ...obj[key], // Spread the properties directly into the object
      }));
    },
    findObjectByAttribute(obj, key, value) {
      // Base case: If the current object has the key with the specified value
      if (obj[key] === value) {
        this.aclNavigationData.push(obj);
        return obj;
      }

      // Recursive case: If obj is an object or array, search its children
      if (typeof obj === "object" && obj !== null) {
        for (const childKey in obj) {
          const child = obj[childKey];

          // If child is an array, iterate over its elements
          if (Array.isArray(child)) {
            for (const element of child) {
              const result = this.findObjectByAttribute(element, key, value);
              if (result) {
                this.aclNavigationData.push(obj);

                return result;
              }
            }
          }
          // If child is an object, recurse into it directly
          else if (typeof child === "object") {
            const result = this.findObjectByAttribute(child, key, value);
            if (result) {
              this.aclNavigationData.push(obj);

              return result;
            }
          }
        }
      }

      // Return null if the object is not found
      return null;
    },
    getFilteredConcattedFields(val) {
      let arr = [];
      let arr2 = [];
      arr = this.concattedFields.filter((item) => {
        return item.show === !val;
      });
      arr2 = JSON.parse(JSON.stringify(arr));
      arr2.forEach((i) => {
        i.name = i?.labels?.en?.label;
      });
      this.fieldDataForDropdowns = arr2;
      if (val) {
        return [];
      }
      return this.concattedFields.filter((item) => {
        return item.show === !val;
      });
    },
    fromAddContextMenu() {
      this.showAddFieldPanel = true;
      this.fieldData.displayType = "";
      this.resetDropdown += 1;
      this.userLanguages?.forEach((item) => {
        this.fieldData.labels[item.code] = {
          label: "",
          tooltip: "",
          name: item.name,
        };
      });

      this.newRefData = [
        {
          value: "",
          name: "",
          english: "",
          type: "String",
          required: false,
          default: false,
        },
      ];
    },
 
 
  
  
 
    setDefaultNewRefData(id) {
      this.newRefData.forEach((item) => {
        item.default = false;
      });
      this.newRefData[id].default = true;
    },
    deleteNewRefData(id) {
      this.newRefData.splice(id, 1);
    },
    addNewRefData() {
      const obj = {
        value: "",
        english: "",
        type: "String",
        required: false,
        default: false,
      };
      obj[this.userLanguages[1].name.toLowerCase()] = "";
      this.newRefData.push(JSON.parse(JSON.stringify(obj)));
    },
    async checkIsref(val) {
      // console.log(val, 'huyt');

      if (val.value == "reference" && val.checked) {
        this.fieldData.others.selectedRefOption = "reference";
        this.fieldData.isRef = true;
        if (this.editMode) {
          this.fieldData.optionRef =
            "executionTemplate" + this.fieldData.fieldName + "Ref";
        } else {
          this.fieldData.optionRef = "";
        }
        this.newRefData = [
          {
            value: "",
            name: "",
            english: "",
            type: "String",
            required: false,
            default: false,
          },
        ];
      } else if (val.value == "manual" && val.checked) {
        this.fieldData.others.selectedRefOption = "manual";
        this.fieldData.isRef = false;
        this.fieldData.optionRef =
          "executionTemplate" + this.fieldData.fieldName + "Ref";
        let dropdownData = [];
        // const response = await DMNAxiosInstance.get(
        //   `mapRef/collection/${this.fieldData.optionRef}`
        // );

        // dropdownData = response?.data?.data;

        const { data } = await DMNAxiosInstance.post(`redisJSON`, {
          key: `${this.companyName}.reference`,
          query: `$.${this.fieldData.optionRef}`,
        });
        dropdownData = data?.data || [];
        // console.log(this.fieldData, dropdownData, 'ghjkl');

        if (dropdownData == undefined || dropdownData?.length == 0) {
          this.newRefData = [];
          this.addNewRefData();
        } else {
          this.newRefData = dropdownData;
        }
      } else {
        this.fieldData.others.selectedRefOption = "custom";
      }
    },
    showHideField(val) {
      //console.log(val, 'showval');

      this.fieldData.show = !val.checked;
    },
    async selectedReferenceData(val) {
      //console.log(val, 'opref');

      this.fieldData.optionRef = val?.name;
      let dropdownData = [];
      // const response = await DMNAxiosInstance.get(
      //   `mapRef/collection/${this.fieldData.optionRef}`
      // );

      // dropdownData = response?.data?.data;

      const { data } = await DMNAxiosInstance.post(`redisJSON`, {
        key: `${this.companyName}.reference`,
        query: `$.${this.fieldData.optionRef}`,
      });
      dropdownData = data?.data || [];
      // if (!this.editMode) {
      this.validationRulePayload.forEach((i) => {
        i.values = [];
      });
      this.validationRulePayload.map((i) => {
        i.options = JSON.parse(JSON.stringify(dropdownData));
      });
      this.ruleItemKey += 1;
      // }
      // fk
    },
    async editField(index, val) {
      let filtered = this.concattedFields.filter((item) => {
        return item.show === !val;
      });
      this.fieldData = JSON.parse(JSON.stringify(filtered[index]));
      let translationLabels = JSON.parse(JSON.stringify(this.fieldData.labels));
      // console.log(translationLabels, 'transaltionlabels');
      this.fieldData.labels = {};
      this.userLanguages?.forEach((item) => {
        this.fieldData.labels[item.code] = {
          label: translationLabels[item.code].label,
          tooltip: translationLabels[item.code]?.tooltip,
          name: translationLabels[item.code].name,
        };
      });
      this.fieldDataCopy = JSON.parse(JSON.stringify(filtered[index]));
      // console.log(this.fieldDataCopy, 'this.fieldDataCopy');

      this.isRefOrNotOptions.map((i) => {
        i.value == this.fieldData.others.selectedRefOption
          ? (i.checked = true)
          : (i.checked = false);
      });

      if (this.fieldData.displayType == "date") {
        this.businessDatePreferences =
          this.fieldData.others.businessDatePreference;
      }
      // const en = JSON.parse(JSON.stringify(this.fieldData.labels.en));
      // this.fieldData.labels = JSON.parse(JSON.stringify({}));
      // this.fieldData.labels.en = JSON.parse(JSON.stringify(en));
      if (this.fieldData.others?.visibilityRulePayload?.length > 0) {
        this.visibilityRulePayload = JSON.parse(
          JSON.stringify(this.fieldData.others?.visibilityRulePayload)
        );
      } else {
        this.visibilityRulePayload = [
          {
            fieldName: "",
            fieldNameRef: "",
            values: [],
            value: "",
            operator: "",
            options: [],
            displayType: "",
            optionRef: "",
            showType: "",
          },
        ];
      }

      // console.log(
      //   this.fieldData.isRef,
      //   this.fieldData.displayType,
      //   'this.fieldData',
      //   this.fieldData
      // );

      if (
        (this.fieldData?.displayType?.toLowerCase() == "dropdown" ||
          this.fieldData?.displayType?.toLowerCase() == "radio" ||
          this.fieldData?.displayType?.toLowerCase() == "checkbox" ||
          this.fieldData?.displayType?.toLowerCase() == "status" ||
          this.fieldData?.displayType?.toLowerCase() == "multiselect") &&
        this.fieldData?.optionRef != ""
        // && !this.fieldData.isRef
      ) {
        let filterData = {
          type: "filter",
          module: "referencedata",
          collection: this.fieldData.optionRef,
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 1000,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {},
          limit: 1000,
          filterQuery: {},
          searchQuery: "",
          company: this.companyName,
        };
        let data = await DMNAxiosInstance.post(`/util/filterData`, filterData);

        this.newRefData = data?.data?.data?.data;
        // console.log(data, 'rasputin1');
      }
      if (this.fieldData.others?.validationRulePayload?.length > 0) {
        this.validationRulePayload = JSON.parse(
          JSON.stringify(this.fieldData.others?.validationRulePayload)
        );
        let tempOptions = JSON.parse(JSON.stringify(this.newRefData));
        // console.log(
        //   this.validationRulePayload,
        //   tempOptions,
        //   this.newRefData,
        //   'rasputin'
        // );
        // this.ruleItemKey += 1;
        this.validationRulePayload.map((i) => {
          i.options = JSON.parse(JSON.stringify(tempOptions));
        });
        // console.log(
        //   this.validationRulePayload,
        //   tempOptions,
        //   this.newRefData,
        //   'rasputin'
        // );
      } else {
        this.validationRulePayload = [
          {
            fieldName: "",
            fieldNameRef: "",
            values: [],
            value: "",
            operator: "",
            options: [],
            displayType: "",
            optionRef: "",
            showType: "",
            validationMessages: {
              en: "",
            },
          },
        ];
      }
      this.editMode = true;
      this.editIndex = filtered[index].id;
      this.showAddFieldPanel = true;
      this.ruleItemKey += 1;
    },
    showControls(id) {
      document
        .getElementById("show-field-controls-" + id)
        ?.classList.add("show-controls");
    },
    hideControls(id) {
      document
        .getElementById("show-field-controls-" + id)
        ?.classList.remove("show-controls");
    },
    showControlsForHiddenFields(id) {
      document
        .getElementById("show-hidden-field-controls-" + id)
        .classList.add("show-controls");
    },
    hideControlsForHiddenFields(id) {
      document
        .getElementById("show-hidden-field-controls-" + id)
        .classList.remove("show-controls");
    },
    dragRowStart(i) {
      this.drag_start = i;
    },
    dragRowLeave(i) {
      this.drag_leave = i;
    },
    dragRowEnd() {
      this.dragging = false;
      // //console.log('devRoz swap', this.drag_start, this.drag_leave, this.concattedFields);

      const newData = this.swapObjectPositions(
        this.drag_start,
        this.drag_leave
      );
      newData;

      //console.log(newData);
    },
    dragRefDataStart(i) {
      this.dragRefDataStartIndex = i;
    },
    dragRefDataLeave(i) {
      this.dragRefDataEndIndex = i;
    },
    dragRefDataEnd() {
      const [obj1, obj2] = [
        this.newRefData[this.dragRefDataStartIndex],
        this.newRefData[this.dragRefDataEndIndex],
      ];

      // Swap the objects
      this.newRefData[this.dragRefDataStartIndex] = obj2;
      this.newRefData[this.dragRefDataEndIndex] = obj1;
    },
    swapObjectPositions(index1, index2) {
      // Destructure the objects at the given indices
      //console.log('devRoz in swap', index1, index2, this.concattedFields);

      const [obj1, obj2] = [
        this.concattedFields[index1],
        this.concattedFields[index2],
      ];

      // Swap the objects
      this.concattedFields[index1] = obj2;
      this.concattedFields[index2] = obj1;
      //console.log('devRoz after swap', index1, index2, this.concattedFields);
    },
    openAddFieldsPanel() {
      this.containerleft1 =
        document.querySelector(".custom-field-add-btn").getBoundingClientRect()
          .left - 70;
      this.containertop1 =
        document.querySelector(".custom-field-add-btn").getBoundingClientRect()
          .top + 40;
      this.showContextMenu = !this.showContextMenu;
    },
    closeDrawer() {
      this.selectedDefinitionTab = "Configuration";
      this.showAddFieldPanel = false;
      this.fieldData = {
        isRef: false,
        required: false,
        isTooltip: false,
        tooltipEnglish: "",
        tooltipFrench: "",
        show: true,
        displayType: "",
        labels: {
          en: {
            label: "",
            name: "English",
            tooltip: "",
          },
        },
        others: {
          isServiceQualifcationEnabled: false,
          businessDatePreference: "",
          customDatePreference: {
            preference: "",
            range: { from: "", to: "" },
          },
          userRole: [],
          readOnly: false,
          lookup: {
            minCharToSearch: 1,
            maxResultToDisplay: 5,
            module: "",
            instance: "",
            fieldType: "input",
            filterPayload: {},
            url: "",
            lookupProp: "",
            isDataRemapEnabled: false,
            remapData: "",
          },
          addressAPI: {
            authType: "",
            token: "",
            userName: "",
            password: "",
            type: "canada post",
            key: "",
          },
          isVisibilityRuleEnabled: false,
          isValidationRuleEnabled: false,
          showValidation: false,
          validationMessage: "",
          ruleIdReference: "",
          ruleData: "",
          visibilityRulePayload: [
            {
              fieldName: "",
              fieldNameRef: "",
              values: [],
              value: "",
              operator: "",
              options: [],
              displayType: "",
              optionRef: "",
              showType: "",
            },
          ],
          isVisible: true,
          visibilityRuleAction: "show",
          visibilityRuleCondition: "All",
        },
      };
      this.visibilityRulePayload = [
        {
          fieldName: "",
          fieldNameRef: "",
          values: [],
          value: "",
          operator: "",
          options: [],
          displayType: "",
          optionRef: "",
          showType: "",
        },
      ];
      this.userLanguages?.forEach((item) => {
        this.fieldData.labels[item.code] = {
          label: "",
          tooltip: "",
          name: item.name,
        };
      });
      // Object.entries(this.userLanguages)?.forEach(([code, name]) => {
      //   this.fieldData.labels[code] = {
      //     label: '',
      //     tooltip: '',
      //     name: name,
      //   };
      // });
      this.editMode = false;
    },
  
    filterBySection(savedFields) {
      const result = [];

      const filterRecursive = (obj) => {
        console.log("devRoz", obj, this.selectedSubTab);
        if (obj != undefined) {
          Object.entries(obj).forEach(([key, value]) => {
            if (this.selectedSubTab != "") {
              if (value.section) {
                result.push({ key, ...value }); // Add the matched item to the result array
              }
            } else {
              if (value.section) {
                result.push({ key, ...value }); // Add the matched item to the result array
              }
            }
            // if (
            //   value.section &&
            //   value.section.includes(selectedTab.toLowerCase()) &&
            //   value.tab?.includes(this.selectedSubTab.toLowerCase())
            // ) {
            //   result.push({ key, ...value }); // Add the matched item to the result array
            // }
            if (value.schema) {
              // this.tempSchemaRef = key;
              filterRecursive(value.schema); // Recursively check nested schema
            }
          });
        }
      };

      filterRecursive(savedFields);
      // Sort by `displayOrder` in ascending order
      result.sort((a, b) => a.others.displayOrder - b.others.displayOrder);
      return result;
    },
    getFieldItems(fieldsArray, payloadObject) {
      return fieldsArray?.reduce((filteredPayload, field) => {
        if (Object.prototype.hasOwnProperty.call(payloadObject, field)) {
          filteredPayload[field] = payloadObject[field];
        }
        return filteredPayload;
      }, {});
    },
    async addCustomItem() {
      let obj = this.fieldData;
      obj.required = this.fieldData.required;
      obj.id = this.concattedFields.length;
      obj.others.displayOrder = this.concattedFields.length;
      obj.others.visibilityRulePayload = JSON.parse(
        JSON.stringify(this.visibilityRulePayload)
      );
      obj.others.validationRulePayload = JSON.parse(
        JSON.stringify(this.validationRulePayload)
      );
      obj.others.validationRulePayload.forEach((payload) => {
        delete payload.options;
      });
      obj.others.visibilityRulePayload.forEach((payload) => {
        delete payload.options;
      });

      try {
        if (
          !obj.isRef &&
          (obj?.displayType?.toLowerCase() == "dropdown" ||
            obj?.displayType?.toLowerCase() == "radio" ||
            obj?.displayType?.toLowerCase() == "checkbox" ||
            obj?.displayType?.toLowerCase() == "status" ||
            obj?.displayType?.toLowerCase() == "multiselect")
        ) {
          let Subschema = {
            name: {
              type: "String",
              required: false,
            },
            value: {
              type: "String",
              required: false,
            },
            english: {
              type: "String",
              required: false,
            },
            hexCode: {
              type: "String",
              required: false,
            },
            default: {
              type: "String",
              required: false,
            },
          };
          Subschema[this.userLanguages[1].name.toLowerCase()] = {
            type: "String",
            required: false,
          };
          let schema = [
            {
              name: "executionTemplate" + obj.fieldName + "Ref",
              type: "manual",
              description: "",
              schema: Subschema,
              isReference: true,
              isStatus: obj.displayType == "status" ? true : false,
            },
          ];
          let addSchema = await DMNAxiosInstance.post(
            "rule_engine/schema/add",
            schema
          );

          this.newRefData.forEach((item) => {
            item.english = JSON.parse(JSON.stringify(item.value));
            item.name = JSON.parse(JSON.stringify(item.value));
          });
          let addData = await DMNAxiosInstance.post(
            `mapRef/addMany/${addSchema.data.data[0].name}`,
            this.newRefData
          );
          addData;

          obj.optionRef = addSchema.data.data[0].name;
        }
      } catch (error) {
        //console.log(error);
      }

      let payload = {};
      payload = JSON.parse(JSON.stringify(this.originalFieldData));
      payload.id = payload._id;
      delete payload._id;
      if (!payload.fields) {
        payload.fields = {};
      }
      payload.fields[obj.fieldName] = obj;
      payload.fields[obj.fieldName].labels[this.userLanguage].label =
        obj.labels[this.userLanguage].label;
      payload.fields[obj.fieldName].section = [];
      payload.fields[obj.fieldName].section.push(
        this.selectedTab.toLowerCase()
      );
      payload.fields[obj.fieldName].tab = [];
      payload.fields[obj.fieldName].tab.push(this.selectedSubTab.toLowerCase());
      payload.status = "Draft";
      // let patchData = {}

      console.log(payload, "payloadpayloadpayload");

      let patchData = await UPCAxiosInstance.patch("execution", payload);

      if (patchData.status == 200) {
        this.notification = {
          type: "success",
          message: `Done! New custom field is added to the list`,
        };
      } else {
        this.notification = {
          type: "error",
          message: `Something went wrong! try again later`,
        };
      }
      let savedFields = await UPCAxiosInstance.get(`/execution/${payload.id}`);
      this.originalFieldData = JSON.parse(JSON.stringify(savedFields.data));
      savedFields = savedFields.data.fields;

      const filteredData = this.filterBySection(savedFields, "");

      this.systemGeneratedFieldsData = filteredData;
      this.concattedFields.push(obj);
      this.fieldData = {
        isRef: false,
        required: false,
        isTooltip: false,
        tooltipEnglish: "",
        tooltipFrench: "",
        show: true,
        displayType: "",
        labels: {
          en: {
            label: "",
            name: "English",
            tooltip: "",
          },
        },
        others: {
          isServiceQualifcationEnabled: false,
          businessDatePreference: "",
          customDatePreference: {
            preference: "",
            range: { from: "", to: "" },
          },
          userRole: [],
          readOnly: false,
          lookup: {
            minCharToSearch: 1,
            maxResultToDisplay: 5,
            module: "",
            instance: "",
            fieldType: "input",
            filterPayload: {},
            url: "",
            lookupProp: "",
            isDataRemapEnabled: false,
            remapData: "",
          },
          addressAPI: {
            authType: "",
            token: "",
            userName: "",
            password: "",
            type: "canada post",
            key: "",
          },
          isVisibilityRuleEnabled: false,
          isValidationRuleEnabled: false,
          showValidation: false,
          validationMessage: "",
          ruleIdReference: "",
          ruleData: "",
          isVisible: true,
          visibilityRulePayload: [
            {
              fieldName: "",
              fieldNameRef: "",
              values: [],
              value: "",
              operator: "",
              options: [],
              displayType: "",
              optionRef: "",
              showType: "",
            },
          ],
          visibilityRuleAction: "show",
          visibilityRuleCondition: "All",
        },
      };
      this.userLanguages?.forEach((item) => {
        this.fieldData.labels[item.code] = {
          label: "",
          tooltip: "",
          name: item.name,
        };
      });

      this.resetComponent += 1;
      this.closeDrawer();
    },
    async editCustomItem() {
      this.overwriteCanEdit = true;
      this.canEdit = false;
      let obj = this.fieldData;
      obj.required = this.fieldData.required;

      let data = {};
      this.newRefData.forEach((item) => {
        item.english = JSON.parse(JSON.stringify(item.value));
        item.name = JSON.parse(JSON.stringify(item.value));
      });
      data.data = this.newRefData;
      if (
        (this.fieldData?.displayType?.toLowerCase() == "dropdown" ||
          this.fieldData?.displayType?.toLowerCase() == "radio" ||
          this.fieldData?.displayType?.toLowerCase() == "status" ||
          this.fieldData?.displayType?.toLowerCase() == "checkbox" ||
          this.fieldData?.displayType?.toLowerCase() == "multiselect") &&
        !this.fieldData.isRef &&
        data.data.length > 0 &&
        data.data[0].value !== ""
      ) {
        let filterData = {
          type: "filter",
          module: "referencedata",
          collection: this.fieldData.optionRef,
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 1000,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {},
          limit: 1000,
          filterQuery: {},
          searchQuery: "",
          company: this.companyName,
        };
        let editRefData = await DMNAxiosInstance.post(
          `/util/filterData`,
          filterData
        );
        if (editRefData.data.message === "Given collection is not exist") {
          try {
            if (
              !obj.isRef &&
              (obj.displayType == "dropdown" ||
                obj.displayType == "radio" ||
                obj.displayType == "status" ||
                obj.displayType == "checkbox" ||
                obj.displayType == "multiselect")
            ) {
              let Subschema = {
                name: {
                  type: "String",
                  required: false,
                },
                value: {
                  type: "String",
                  required: false,
                },
                english: {
                  type: "String",
                  required: false,
                },
                hexCode: {
                  type: "String",
                  required: false,
                },
                default: {
                  type: "String",
                  required: false,
                },
              };
              Subschema[this.userLanguages[1].name.toLowerCase()] = {
                type: "String",
                required: false,
              };
              let schema = [
                {
                  name: "executionTemplate" + obj.fieldName + "Ref",
                  type: "manual",
                  description: "",
                  schema: Subschema,
                  isReference: true,
                  isStatus: obj.displayType == "status" ? true : false,
                },
              ];
              let addSchema = await DMNAxiosInstance.post(
                "rule_engine/schema/add",
                schema
              );
              //console.log(addSchema, 'addSchema');

              this.newRefData.forEach((item) => {
                // console.log(item, 'ref data edited');

                item.english = JSON.parse(JSON.stringify(item.value));
                item.name = JSON.parse(JSON.stringify(item.value));
              });
              let addData = await DMNAxiosInstance.post(
                `mapRef/addMany/${addSchema.data.data[0].name}`,
                this.newRefData
              );
              addData;
              //console.log(addData, 'addSchema');

              obj.optionRef = addSchema.data.data[0].name;
            }
          } catch (error) {
            //console.log(error);
          }
        } else {
          let patchData = await DMNAxiosInstance.patch(
            `mapRef/updateManyCollection/${this.fieldData.optionRef}`,
            data
          );
          patchData;
        }
      }

      this.fieldData.others.visibilityRulePayload = JSON.parse(
        JSON.stringify(this.visibilityRulePayload)
      );
      this.fieldData.others.validationRulePayload = JSON.parse(
        JSON.stringify(this.validationRulePayload)
      );

      this.originalFieldData.fields[this.fieldData.fieldName] = JSON.parse(
        JSON.stringify(this.fieldData)
      );
      this.originalFieldData.fields[this.fieldData.fieldName].labels[
        this.userLanguage
      ].label = JSON.parse(
        JSON.stringify(this.fieldData.labels[this.userLanguage].label)
      );

      let copyOriginalFieldData = JSON.parse(
        JSON.stringify(this.originalFieldData)
      );
      copyOriginalFieldData.id = copyOriginalFieldData._id;
      copyOriginalFieldData.status = "Draft";
      let patchSchema = await UPCAxiosInstance.patch(
        "execution",
        copyOriginalFieldData
      );

      this.resetComponent += 1;
      let savedFields = await UPCAxiosInstance.get(
        `/execution/${copyOriginalFieldData.id}`
      );
      this.originalFieldData = JSON.parse(JSON.stringify(savedFields.data));
      savedFields = savedFields.data.fields;

      const filteredData = this.filterBySection(savedFields, "");

      this.systemGeneratedFieldsData = filteredData;

      this.concattedFieldsArray();
      if (patchSchema.status == 200) {
        this.notification = {
          type: "success",
          message: `Done! "${
            this.fieldData.labels[this.userLanguage].label
          }" field is updated successfully`,
        };
      } else {
        this.notification = {
          type: "error",
          message: `Something went wrong! try again later`,
        };
      }
      this.fieldData = {
        isRef: false,
        required: false,
        isTooltip: false,
        tooltipEnglish: "",
        tooltipFrench: "",
        show: true,
        displayType: "",
        labels: {
          en: {
            label: "",
            name: "English",
            tooltip: "",
          },
        },
        others: {
          isServiceQualifcationEnabled: false,
          businessDatePreference: "",
          customDatePreference: {
            preference: "",
            range: { from: "", to: "" },
          },
          userRole: [],
          readOnly: false,
          lookup: {
            minCharToSearch: 1,
            maxResultToDisplay: 5,
            module: "",
            instance: "",
            fieldType: "input",
            filterPayload: {},
            url: "",
            lookupProp: "",
            isDataRemapEnabled: false,
            remapData: "",
          },
          addressAPI: {
            authType: "",
            token: "",
            userName: "",
            password: "",
            type: "canada post",
            key: "",
          },
          isVisibilityRuleEnabled: false,
          isValidationRuleEnabled: false,
          showValidation: false,
          validationMessage: "",
          ruleIdReference: "",
          ruleData: "",
          isVisible: true,
          visibilityRulePayload: [
            {
              fieldName: "",
              fieldNameRef: "",
              values: [],
              value: "",
              operator: "",
              options: [],
              displayType: "",
              optionRef: "",
              showType: "",
            },
          ],
          visibilityRuleAction: "show",
          visibilityRuleCondition: "All",
        },
      };
      //console.log(patchSchema, 'patchData');
      this.closeDrawer();
      this.canEdit = true;
      this.overwriteCanEdit = false;
    },
    previousLevel() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.circleInCard {
  width: 70px;
  height: 70px;
  background-color: #d8edfc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0065b2;
  font-size: 30px;
}

.customFieldsCardDescription {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.system-field {
  margin-bottom: 10px;
}

.fields-container {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  .show-field-on-hover {
    display: none;
  }
  .show-controls {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .field-item {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon-check-circle-filled {
      color: #54bd95;
    }
  }
  .field-item-child {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 8px;
    .icon-check-circle-filled {
      color: #54bd95;
    }
  }
}

.list-values {
  .input-container {
    width: 100% !important;
  }
}
</style>
