<template>
 <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <div class="add-feature-drawer">
    <hlx-drawer
      :show="filed_modal_active"
      position="right"
      width="580"
      :footer="true"
      :show-close-icon="true"
      @close="closeFiledPemisttion"
    >
      <template #body>
        <div style="height: 90vh !important">
          <div
            style="
              border-bottom: 1px solid #d4d4d4;
              padding-bottom: 5px;
              padding: 13px;
            "
          >
            <b>Field permissions</b>
          </div>
          <div
            :key="F"
            style="
              overflow-x: hidden;
              display: flex;
              padding: 18px 13px;
              flex-direction: column;
              gap: 18px;
            "
          >
            <div
              class="product-detail-supplier"
              style="width: 550px; margin: 15px auto"
            >
              <div v-if="moduleName" class="value-data-supplier">
                <span class="value-data-supplier_left">Module </span>
                <span
                  class="value-data-supplier_right" 
                  style="text-align: right"
                  >{{ capitalizeFirstLetter(moduleName) }}</span
                >
              </div>
              <div v-if="sectionName" class="value-data-supplier">
                <span class="value-data-supplier_left">Feature </span>
                <span
                  class="value-data-supplier_right"
                  style="text-align: right"
                  >{{ capitalizeFirstLetter(sectionName) }}</span
                >
              </div>
              <div v-if="subSectionName" class="value-data-supplier">
                <span class="value-data-supplier_left">Sub feature </span>
                <span
                  class="value-data-supplier_right"
                  style="text-align: right"
                  >{{ capitalizeFirstLetter(subSectionName) }}</span
                >
              </div>
              <div v-if="segmentName" class="value-data-supplier">
                <span class="value-data-supplier_left">Section </span>
                <span
                  class="value-data-supplier_right"
                  style="text-align: right"
                  >{{ capitalizeFirstLetter(segmentName) }}</span
                >
              </div>
            </div>
            <div class="permissions-table-container">
              <table class="permissions-table">
                <thead>
                  <tr>
                    <th class="permissions-header">Fields</th>
                    <th class="permissions-header">All</th>
                    <th class="permissions-header">Edit</th>
                    <th class="permissions-header">View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(permissions, field) in formattedOutput"
                    :key="field"
                  > 

                    <td class="permissions-cell">{{ field }}</td> 
                    <td class="permissions-cell">
                    
                      <hlx-input
                        v-model:value="permissions.all"
                        :checked="permissions.all"
                        :type="'single-partial-checkbox'"
                        :partially-checked="false"
                        :disabled="!( editMode === false  )"
                        @selected="segmentAll($event, permissions)"
                      />
                    </td>
                    <td class="permissions-cell">
                      <hlx-input
                        v-model:value="permissions.edit"
                        :checked="permissions.edit"
                        :type="'single-partial-checkbox'"
                        :partially-checked="false"
                        :disabled=" !( editMode === false   )"
                        @selected="segmentEdit($event, permissions)"
                      />
                    </td>
                    <td class="permissions-cell">
                      <hlx-input
                        v-model:value="permissions.view"
                        :checked="permissions.view"
                        :type="'single-partial-checkbox'"
                        :partially-checked="false"
                        :disabled="  !( editMode === false   )"
                        @selected="segmentView($event, permissions)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 16px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeFiledPemisttion"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 10px"
            :disabled="editMode"
            @click="editedFileds()"
            >Save</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <div class="accordion" style="padding-left: 52px; padding-top: 20px">
    <div
      style="
        display: flex;
        align-items: center;
        font-family: 'Opensans';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.07px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      "
    >
      <div style="width: 23.3%">Modules</div>
      <div
        style="
          width: 70%;
          display: flex;
          gap: 19%;
          align-items: center;
          flex-direction: row;
        "
      >
        <div>All</div>
        <div>Add</div>
        <div>Edit</div>
        <div>View</div>
        <div>Delete</div>
      </div>
    </div>
    <div v-for="(section, index) in sections" :key="index">
      <button
        class="accordion-btn"
        :class="{ active: section.isOpen }"
        @click="toggleSection(index, section)"
      >
        <div style="width: 23%">
          <i
            :style="{
              visibility:
                section?.child?.length ||
                section?.section?.length ||
                section?.sections?.length
                  ? 'visible'
                  : 'hidden',
            }"
            :class="
              section.isOpen
                ? 'icon-angle-down-regular'
                : 'icon-angle-right-regular'
            "
            class="accordion-icon"
          ></i>
          {{ capitalizeFirstLetter(section.name || section.customName) }}
        </div>
        <div style="display: flex; width: 76%; gap:  18.7%;">
          <hlx-input
            v-model:value="section.all"
            :checked="section.all"
            :type="'single-partial-checkbox'"
            :partially-checked="false"
            :disabled="!(section.allDisabled == false && editMode === false)"
            @selected="updateAll($event, section, section)"
          />
          <hlx-input
            v-model:value="section.add"
            :checked="section.add"
            :type="'single-partial-checkbox'"
            :partially-checked="section.partially"
            :disabled="!(section.addDisabled == false && editMode === false)"
            @selected="updateAdd($event, section, section)"
          />
          <hlx-input
            v-model:value="section.edit"
            :checked="section.edit"
            :type="'single-partial-checkbox'"
            :partially-checked="section.partially"
            :disabled="!(section.editDisabled == false && editMode === false)"
            @selected="updateEdit($event, section, section)"
          />
          <hlx-input
            v-model:value="section.view"
            :checked="section.view"
            :type="'single-partial-checkbox'"
            :partially-checked="section.partially"
            :disabled="!(section.viewDisabled == false && editMode === false)"
            @selected="updateView($event, section, section)"
          />
          <hlx-input
            v-model:value="section.delete"
            :checked="section.delete"
            :type="'single-partial-checkbox'"
            :partially-checked="section.partially"
            :disabled="!(section.deleteDisabled == false && editMode === false)"
            @selected="updateDelete($event, section, section)"
          />
        </div>
        <div
          v-if="section?.filedView === true"
          style="margin-left: -141px"
          @click="filedLeftPanel(section,section)"
        >
          <p
            class="bg-default border"
            data-tooltip="Field permissions"
            data-tooltip-location="left"
          >
            <i
              class="icon-sliders-horizontal-alt-regular"
              style="color: rgba(0, 101, 178, 1); font-size: 16px"
            ></i>
          </p>
        </div>
      </button>
      <div class="panel" :class="{ show: section.isOpen }">
        <div
          v-for="(inner, innerIndex) in section.child ||
          section.section ||
          section.sections"
          :key="innerIndex"
        >
          <button
            v-if="!inner.title"
            class="accordion-btn inner-accord"
            :class="{ active: inner.isOpen }"
            @click="toggleInnerSection(index, innerIndex)"
          >
            <div style="width: 21.6%">
              <i
                :style="{
                  visibility:
                    inner?.child?.length ||
                    inner?.section?.length ||
                    inner?.sections?.length
                      ? 'visible'
                      : 'hidden',
                }"
                :class="
                  inner.isOpen
                    ? 'icon-angle-down-regular'
                    : 'icon-angle-right-regular'
                "
                class="accordion-icon"
              ></i>
              {{ capitalizeFirstLetter(inner.customName || inner.name) }}
            </div>
            <div style="display: flex; width: 80%; gap: 18.45%">
              <hlx-input
                v-model:value="inner.all"
                :checked="inner.all"
                :disabled=" !(inner.allDisabled == false && editMode === false)"
                :type="'single-partial-checkbox'"
                :partially-checked="false"
                @selected="updateAll($event, inner, section)"
              />
              <hlx-input
                v-model:value="inner.add"
                :checked="inner.add"
                :disabled=" !(inner.addDisabled == false && editMode === false)"
                :type="'single-partial-checkbox'"
                :partially-checked="inner.partially"
                @selected="updateAdd($event, inner, section)"
              />
              <hlx-input
                v-model:value="inner.edit"
                :disabled=" !(inner.editDisabled == false && editMode === false)"
                :checked="inner.edit"
                :type="'single-partial-checkbox'"
                :partially-checked="inner.partially"
                @selected="updateEdit($event, inner, section)"
              />
              <hlx-input
                v-model:value="inner.view"
                :disabled=" !(inner.viewDisabled == false && editMode === false)"
                :checked="inner.view"
                :type="'single-partial-checkbox'"
                :partially-checked="inner.partially"
                @selected="updateView($event, inner, section)"
              />
              <hlx-input
                v-model:value="inner.delete"
                :disabled=" !(inner.deleteDisabled == false && editMode === false)"
                :checked="inner.delete"
                :type="'single-partial-checkbox'"
                :partially-checked="inner.partially"
                @selected="updateDelete($event, inner, section)"
              />
            </div>
            <div
              v-if="inner?.filedView === true"
              style="margin-left: -141px"
              @click="filedLeftPanel(inner,section, inner)"
            >
              <p
                class="bg-default border"
                data-tooltip="Field permissions"
                data-tooltip-location="left"
              >
                <i
                  class="icon-sliders-horizontal-alt-regular"
                  style="color: rgba(0, 101, 178, 1); font-size: 16px"
                ></i>
              </p>
            </div>
          </button>
          <div class="panel" :class="{ show: inner.isOpen }">
            <div
              v-for="(third, thirdIndex) in inner.child ||
              inner.section ||
              inner.sections"
              :key="thirdIndex"
            >
              <button
                v-if="!third.title"
                class="accordion-btn inner-accord third-accord"
                :class="{ active: third.isOpen }"
                @click="toggleThirdLayer(index, innerIndex, thirdIndex)"
              >
                <div style="width: 19.9%">
                  <i
                    :style="{
                      visibility:
                        third?.child?.length ||
                        third?.section?.length ||
                        third?.sections?.length
                          ? 'visible'
                          : 'hidden',
                    }"
                    :class="
                      third.isOpen
                        ? 'icon-angle-down-regular'
                        : 'icon-angle-right-regular'
                    "
                    class="accordion-icon"
                  ></i>
                  {{ capitalizeFirstLetter(third.customName || third.name) }}
                </div>
                <div style="display: flex; width: 78.7%; gap: 18.8%">
                  <hlx-input
                    v-model:value="third.all"
                    :disabled=" !(third.allDisabled == false && editMode === false)"
                    :checked="third.all"
                    :type="'single-partial-checkbox'"
                    :partially-checked="false"
                    @selected="updateAll($event, third, section, inner)"
                  />
                  <hlx-input
                    v-model:value="third.add"
                    :checked="third.add"
                    :disabled=" !(third.addDisabled == false && editMode === false)"
                    :type="'single-partial-checkbox'"
                    :partially-checked="third.partially"
                    @selected="updateAdd($event, third, section, inner)"
                  />
                  <hlx-input
                    v-model:value="third.edit"
                    :disabled=" !(third.editDisabled == false && editMode === false)"
                    :checked="third.edit"
                    :type="'single-partial-checkbox'"
                    :partially-checked="third.partially"
                    @selected="updateEdit($event, third, section, inner)"
                  />
                  <hlx-input
                    v-model:value="third.view"
                    :disabled=" !(third.viewDisabled == false && editMode === false)"
                    :checked="third.view"
                    :type="'single-partial-checkbox'"
                    :partially-checked="third.partially"
                    @selected="updateView($event, third, section, inner)"
                  />
                  <hlx-input
                    v-model:value="third.delete"
                    :disabled=" !(third.deleteDisabled == false && editMode === false)"
                    :checked="third.delete"
                    :type="'single-partial-checkbox'"
                    :partially-checked="third.partially"
                    @selected="updateDelete($event, third, section, inner)"
                  />
                </div>
                <div
                  v-if="third?.filedView === true"
                  style="margin-left: -141px"
                  @click="filedLeftPanel(third,section, inner, third)"
                >
                  <p
                    class="bg-default border"
                    data-tooltip="Field permissions"
                    data-tooltip-location="left"
                  >
                    <i
                      class="icon-sliders-horizontal-alt-regular"
                      style="color: rgba(0, 101, 178, 1); font-size: 16px"
                    ></i>
                  </p>
                </div>
              </button>
              <div class="panel" :class="{ show: third.isOpen }">
                <div
                  v-for="(forth, forthIndex) in third.child ||
                  third.section ||
                  third.sections"
                  :key="forthIndex"
                >
                  <button
                    v-if="!forth.title"
                    class="accordion-btn inner-accord fourth-accord"
                    :class="{ active: forth.isOpen }"
                    @click="
                      toggleForthLayer(
                        index,
                        innerIndex,
                        thirdIndex,
                        forthIndex
                      )
                    "
                  >
                    <div style="width: 19.8%">
                      <i
                        :style="{
                          visibility:
                            forth?.child?.length ||
                            forth?.section?.length ||
                            forth?.sections?.length
                              ? 'visible'
                              : 'hidden',
                        }"
                        :class="
                          forth.isOpen
                            ? 'icon-angle-down-regular'
                            : 'icon-angle-right-regular'
                        "
                        class="accordion-icon"
                      ></i>
                      {{
                        capitalizeFirstLetter(forth.customName || forth.name)
                      }}
                    </div>
                    <div style="display: flex; width: 75.6%; gap: 19.55%">
                      <hlx-input
                        v-model:value="forth.all"
                        :disabled=" !(forth.allDisabled == false && editMode === false)"
                        :checked="forth.all"
                        :type="'single-partial-checkbox'"
                        :partially-checked="false"
                        @selected="
                          updateAll($event, forth, section, inner, third)
                        "
                      />
                      <hlx-input
                        v-model:value="forth.add"
                        :checked="forth.add"
                        :disabled=" !(forth.addDisabled == false && editMode === false)"
                        :type="'single-partial-checkbox'"
                        :partially-checked="forth.partially"
                        @selected="
                          updateAdd($event, forth, section, inner, third)
                        "
                      />
                      <hlx-input
                        v-model:value="forth.edit"
                        :checked="forth.edit"
                        :disabled="!(forth.editDisabled == false && editMode === false)"
                        :type="'single-partial-checkbox'"
                        :partially-checked="forth.partially"
                        @selected="
                          updateEdit($event, forth, section, inner, third)
                        "
                      />
                      <hlx-input
                        v-model:value="forth.view"
                        :checked="forth.view"
                        :disabled="!(forth.viewDisabled == false && editMode === false)"
                        :type="'single-partial-checkbox'"
                        :partially-checked="forth.partially"
                        @selected="
                          updateView($event, forth, section, inner, third)
                        "
                      />
                      <hlx-input
                        v-model:value="forth.delete"
                        :checked="forth.delete"
                        :disabled="!(forth.deleteDisabled== false && editMode === false)" 
                        :type="'single-partial-checkbox'"
                        :partially-checked="forth.partially"
                        @selected="
                          updateDelete($event, forth, section, inner, third)
                        "
                      />
                    </div>
                    <div
                      v-if="forth?.filedView === true"
                      style="margin-left: -141px"
                      @click="filedLeftPanel(forth,section, inner, third, forth)"
                    >
                      <p
                        class="bg-default border"
                        data-tooltip="Field permissions"
                        data-tooltip-location="left"
                      >
                        <i
                          class="icon-sliders-horizontal-alt-regular"
                          style="color: rgba(0, 101, 178, 1); font-size: 16px"
                        ></i>
                      </p>
                    </div>
                  </button>
                  <div class="panel" :class="{ show: forth.isOpen }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MLTAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";

export default {
  props: {
    initialPayload: {
      type: Array,
      default: () => {
        return [];
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["permission-value", 'intial-value'],
  data() {
    return {
      FiledReander:{},
      filterSettings: {
        type: "filter",
        module: "rolesetting",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 5,
        filterQuery: {
          "role.id": this.$route.query.id,
          module: "Multi dwelling unit",
          section: "Lead",
        },
        searchQuery: "",
      },
      tempVariable: {},
      sections: [],
      IntialPayload: [],
      updatedPayload: [],
      filed_modal_active: false,
      isSubFunctionCalled: false,
      filterSettingsData: {},
      moduleName: "",
      sectionName: "",
      subSectionName: "",
      segmentName: "",
      selectedValue: [],
      customFieldLeadSchema: {},
      filedDetails: {},
      formattedOutput: {},
      CopyformattedOutput: {},
      filedData: {},
      roleData: {},
    };
  },
  watch: {

    initialPayload: {
      handler(val) {
        this.sections = val
        console.log(val, "valinitialPayload");
        
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.company = await val.data.company;
    let sampleTheads = await MLTAxiosInstance.get(
      `/partyRole/${this.$route.query.id}`
    );
    this.roleData = sampleTheads.data;
    const mergedPayload = sampleTheads.data.permission;
    const transformOutputData = this.transformOutput(mergedPayload);
    const defaultSettings = await UPCAxiosInstance.get(`/customHierarchy`);
    const mergedArray = [
      ...defaultSettings?.data[0]?.module[0].main,
      ...defaultSettings?.data[0]?.module[0].footer,
    ];
    const sectionData = mergedArray;
    const currentData = this.updateMyDataWithBossData(
      sectionData,
      transformOutputData
    );
    this.IntialPayload = JSON.parse(JSON.stringify(currentData));
    this.$emit("intial-value", this.IntialPayload);
    this.sections = currentData;
    const schema = await UPCAxiosInstance.get(
      `/customSchema/getSchema?module=${"saleslead"}`
    );
    this.customFieldLeadSchema = schema?.data?.schema;
  },
  methods: {
    async editedFileds() {
      this.filedData[this.segmentName] = this.formattedOutput;
      this.filterSettingsData.permission = this.filedData;
      console.log(
        this.filterSettingsData,
        "rolesAndpermistionVar",
        this.filedData
      );
      const rolesAndpermistionVar = await UPCAxiosInstance.patch(
        `/roleSetting`,
        this.filterSettingsData
      );
      if (rolesAndpermistionVar.data) {
        this.alertmsg = "success";
        this.notification = {
          type: "success",
          message: `All set! Your changes have been saved successfully!`,
        };
        this.rolesEditButton = true
      } else {
        this.alertmsg = "danger";
        this.notification = {
          type: "danger",
          message: `Data could not be inserted.`,
        };
      }
      console.log(rolesAndpermistionVar, "rolesAndpermistionVar");
      this.CopyformattedOutput = this.formattedOutput;
      this.closeFiledPemisttion();
    },
    segmentAll(event, permissions) {
      if (event.checked === true) {
        permissions.all = true;
        permissions.edit = true;
        permissions.view = true;
      } else {
        permissions.all = false;
        permissions.edit = false;
        permissions.view = false;
      }
    },
    segmentEdit(event, permissions) {
      if (event.checked === true) {
        permissions.all = true;
        permissions.edit = true;
        permissions.view = true;
      } else {
        permissions.all = false;
        permissions.edit = false;
      }
    },
    segmentView(event, permissions) {
      if (event.checked === true) {
        permissions.view = true;
      } else {
        permissions.all = false;
        permissions.edit = false;
        permissions.view = false;
      }
    },
    formatData(data) {
      console.log(data, "dataformatData");

      const result = {};
      for (const key in data) {
        if (data[key]?.labels?.en?.label) {
          const label = data[key].labels.en.label;
          result[label] = {
            name: key,
            view: false,
            edit: false,
            all: false,
          };
        }
      }
      return result;
    },
    filteredCustomFieldSchema() {
      if (
        this.customFieldLeadSchema &&
        Object.keys(this.customFieldLeadSchema).length > 0
      ) {
        const sortFields = (fieldSchema) => {
          const entries = Object.entries(fieldSchema);
          entries.sort((a, b) => {
            const orderA = a[1].others?.displayOrder ?? Infinity;
            const orderB = b[1].others?.displayOrder ?? Infinity;
            return orderA - orderB;
          });
          return Object.fromEntries(entries);
        };
        const groupedData = {};
        this.selectedValue.map((item) => {
          const itemLowerCase = item.toLowerCase();
          const itemOriginal = item;
          let fieldSchema = {};
          if (itemLowerCase === "overview") {
            fieldSchema = Object.entries(this.customFieldLeadSchema).filter(
              ([, value]) =>
                value.section && value.section.includes(itemLowerCase)
            );
            fieldSchema = Object.fromEntries(fieldSchema);
            const formattedData = this.formatData(fieldSchema);
            groupedData[itemOriginal] = sortFields(formattedData);
          }
          if (itemLowerCase === "contacts") {
            const contactSchema = this.customFieldLeadSchema?.contacts?.schema;
            if (contactSchema && Object.keys(contactSchema).length > 0) {
              fieldSchema = Object.entries(contactSchema);
              fieldSchema = Object.fromEntries(fieldSchema);
              const formattedData = this.formatData(fieldSchema);
              groupedData[itemOriginal] = sortFields(formattedData);
            }
          }
          if (itemLowerCase === "sites") {
            const siteSchema = this.customFieldLeadSchema?.sites?.schema;
            if (siteSchema && Object.keys(siteSchema).length > 0) {
              fieldSchema = Object.entries(siteSchema);
              fieldSchema = Object.fromEntries(fieldSchema);
              const formattedData = this.formatData(fieldSchema);
              groupedData[itemOriginal] = sortFields(formattedData);
            }
          }
        });
        return groupedData;
      }
    },
    updateFieldValues(data, value) {
      if (data && typeof data === "object") {
        for (const section in data) {
          if (data[section] && typeof data[section] === "object") {
            for (const field in data[section]) {
              if (
                data[section][field] &&
                typeof data[section][field] === "object"
              ) {
                data[section][field].view = value;
                data[section][field].edit = value;
                data[section][field].all = value;
              }
            }
          }
        }
      } else {
        for (const field in data) {
          if (data[field] && typeof data[field] === "object") {
            data[field].view = value;
            data[field].edit = value;
            data[field].all = value;
          }
        }
      }
      return data;
    },
    updatePermissionsFileds(payload, value) {
      if (payload && typeof payload === "object") {
        Object.keys(payload).forEach((key) => {
          if (payload[key] && typeof payload[key] === "object") {
            payload[key].view = value;
            payload[key].edit = value;
            payload[key].all = value;
          }
        });
      }
      return payload;
    },
    updateFieldValuesEdit(data, value) {
      if (data && typeof data === "object") {
        for (const section in data) {
          if (data[section] && typeof data[section] === "object") {
            for (const field in data[section]) {
              if (
                data[section][field] &&
                typeof data[section][field] === "object"
              ) {
                if (value === true) {
                  data[section][field].view = value;
                  data[section][field].edit = value;
                  data[section][field].all = value;
                } else if (value === false) {
                  data[section][field].edit = value;
                  data[section][field].all = value;
                }
              }
            }
          }
        }
      } else {
        for (const field in data) {
          if (data[field] && typeof data[field] === "object") {
            if (value === true) {
              data[field].view = value;
              data[field].edit = value;
              data[field].all = value;
            } else if (value === false) {
              data[field].edit = value;
              data[field].all = value;
            }
          }
        }
      }
      return data;
    },
    updatePermissionsFiledsEdit(payload, value) {
      if (payload && typeof payload === "object") {
        Object.keys(payload).forEach((key) => {
          if (payload[key] && typeof payload[key] === "object") {
            if (value === true) {
              payload[key].view = value;
              payload[key].edit = value;
              payload[key].all = value;
            } else if (value === false) {
              payload[key].edit = value;
              payload[key].all = value;
            }
          }
        });
      }
      return payload;
    },
    updateFieldValuesView(data, value) {
      if (data && typeof data === "object") {
        for (const section in data) {
          if (data[section] && typeof data[section] === "object") {
            for (const field in data[section]) {
              if (
                data[section][field] &&
                typeof data[section][field] === "object"
              ) {
                if (value === true) {
                  data[section][field].view = value;
                } else {
                  data[section][field].view = value;
                  data[section][field].edit = value;
                  data[section][field].all = value;
                }
              }
            }
          }
        }
      } else {
        for (const field in data) {
          if (data[field] && typeof data[field] === "object") {
            if (value === true) {
              data[field].view = value;
            } else {
              data[field].view = value;
              data[field].edit = value;
              data[field].all = value;
            }
          }
        }
      }
      return data;
    },
    updatePermissionsFiledsView(payload, value) {
      if (payload && typeof payload === "object") {
        Object.keys(payload).forEach((key) => {
          if (payload[key] && typeof payload[key] === "object") {
            if (value === false) {
              payload[key].view = value;
              payload[key].edit = value;
              payload[key].all = value;
            } else {
              payload[key].view = value;
            }
          }
        });
      }
      return payload;
    },
    filedLeftPanel(EvetData,firstname, secondname, thirdname, forthname) {
      console.log(EvetData,"EvetDataEvetData");
      this.FiledReander = EvetData
      this.filed_modal_active = true;
      const levels = [firstname, secondname, thirdname, forthname];
      levels.map((item) => {
        if (item && item?.level === "module") {
          this.moduleName = item.customName || item.name;
        } else if (item && item?.level === "section") {
          this.sectionName = item.customName || item.name;
        } else if (item && item?.level === "subSection") {
          this.subSectionName = item.customName || item.name;
        } else if (item && item?.level === "segment") {
          this.segmentName = item.customName || item.name;
        }
      });
      this.selectedValue = [this.segmentName];
      this.formattedOutput = this.filedData[this.segmentName];
      this.CopyformattedOutput = JSON.parse(
        JSON.stringify(this.filedData[this.segmentName])
      );
    },
    segmentCustomNames(value) {
      const result = [];
      const traverse = (node) => {
        if (Array.isArray(node)) {
          node.map(traverse);
        } else if (node && typeof node === "object") {
          if (node.level === "segment" && node.customName) {
            result.push(node.customName);
          }
          Object.values(node).map(traverse);
        }
      };
      traverse(value);
      return result;
    },
    filtedData(value) {
      if (value) {
        this.selectedValue = this.segmentCustomNames(value);
      }
      const formattedData = this.filteredCustomFieldSchema();
      this.tempVariable = formattedData;
    },
    closeFiledPemisttion() {
      this.filedData[this.segmentName] = this.CopyformattedOutput;
      this.filed_modal_active = false;
    },
    updateMyDataWithBossData(myData, bossData) {
      if (!myData) {
        return bossData || [];
      }
      function findMatchingBossItem(myItem, bossItems) {
        return bossItems.find(
          (bossItem) =>
            bossItem?.customName?.toLowerCase() ===
            myItem?.customName?.toLowerCase()
        );
      }
      function updateItem(myItem, bossItem) {
        if (myItem.customName === "Log out" || myItem.customName === "Help") {
          myItem.view = true;
          myItem.edit = true;
          myItem.delete = true;
          myItem.add = true;
          myItem.all = true;
          myItem.action = false;
          myItem.disabled = true;
          myItem.partially = false;
          return;
        }
        if (bossItem) {
          myItem.view = bossItem.view;
          myItem.edit = bossItem.edit;
          myItem.delete = bossItem.delete;
          myItem.add = bossItem.add;
          myItem.all = bossItem.all;
          myItem.disabled = false;
          myItem.action = bossItem.action;
          myItem.partially = bossItem.partially;
        } else {
          myItem.view = false;
          myItem.edit = false;
          myItem.delete = false;
          myItem.add = false;
          myItem.all = false;
          myItem.action = false;
          myItem.disabled = false;
          myItem.partially = false;
        }
        if (myItem.child) {
          for (let i = 0; i < myItem.child.length; i++) {
            const matchingBossChild = bossItem?.child
              ? findMatchingBossItem(myItem.child[i], bossItem.child)
              : null;
            updateItem(myItem.child[i], matchingBossChild);
          }
        }
        if (myItem.sections) {
          for (let i = 0; i < myItem.sections.length; i++) {
            const matchingBossSection = bossItem?.sections
              ? findMatchingBossItem(myItem.sections[i], bossItem.sections)
              : null;
            updateItem(myItem.sections[i], matchingBossSection);
          }
        }
      }
      for (let i = 0; i < myData.length; i++) {
        const matchingBossItem = bossData
          ? findMatchingBossItem(myData[i], bossData)
          : null;
        updateItem(myData[i], matchingBossItem);
      }
      return myData;
    },
    checkPermissions(payload) {
      const hasTruePermission = (item) => {
        return item.view || item.edit || item.delete || item.add || item.all;
      };
      const checkChildPermissions = (children) => {
        if (!children) return false;
        for (const child of children) {
          if (hasTruePermission(child)) return true;
          if (child.sections) {
            for (const section of child.sections) {
              if (hasTruePermission(section)) return true;
            }
          }
          if (child.child && checkChildPermissions(child.child)) {
            return true;
          }
          if (!child.child && child.sections) {
            for (const section of child.sections) {
              if (hasTruePermission(section)) return true;
            }
          }
        }
        return false;
      };
      return checkChildPermissions(payload.child);
    },
    transformData(data) {
      const result = {};
      data.map((item) => {
        const {
          name,
          customName,
          view,
          edit,
          delete: del,
          add,
          all,
          partially,
          child,
          sections,
        } = item;
        const transformed = {
          view: !!view,
          edit: !!edit,
          delete: !!del,
          add: !!add,
          all: !!all,
          partially: !!partially,
        };
        name;
        if (child) {
          transformed.child = {};
          child.map((subItem) => {
            const childResult = this.transformData([subItem]);
            const childKey = Object.keys(childResult)[0];
            transformed.child[childKey] = childResult[childKey];
          });
        }
        if (sections) {
          transformed.sections = {};
          sections.map((section) => {
            const sectionTransformed = {
              view: !!section.view,
              edit: !!section.edit,
              delete: !!section.delete,
              add: !!section.add,
              all: !!section.all,
              partially: !!section.partially,
            };
            if (section.sections) {
              sectionTransformed.sections = this.transformData(
                section.sections
              );
            }
            transformed.sections[section.customName] = sectionTransformed;
          });
        }
        result[customName] = transformed;
      });
      return result;
    },
    transformOutput(data) {
      if (!data || Object.keys(data).length === 0) {
        return null;
      }
      return Object.entries(data).map(([key, value]) => {
        const transformed = {
          name: key,
          label: key.toLowerCase(),
          view: value.view || false,
          edit: value.edit || false,
          delete: value.delete || false,
          add: value.add || false,
          all: value.all || false,
          active: false,
          description: "",
          customName: key,
          partially: value.partially || false,
        };
        if (value.child) {
          transformed.child = this.transformOutput(value.child);
        }
        if (value.sections) {
          transformed.sections = Object.entries(value.sections).map(
            ([sectionKey, sectionValue]) => {
              const sectionTransformed = {
                name: sectionKey,
                value: sectionKey.toLowerCase(),
                view: sectionValue.view || false,
                edit: sectionValue.edit || false,
                delete: sectionValue.delete || false,
                add: sectionValue.add || false,
                all: sectionValue.all || false,
                customName: sectionKey,
                icon: "icon-placeholder",
                partially: sectionValue.partially || false,
              };
              if (sectionValue.sections) {
                sectionTransformed.sections = this.transformOutput(
                  sectionValue.sections
                );
              }
              return sectionTransformed;
            }
          );
        }
        return transformed;
      });
    },
    updateAll(
      setTrue,
      data,
      firstParent,
      secondParent,
      thirdParent,
      forthParent
    ) {
      const setTrueval = setTrue.checked;
      const setPermissions = (item, setTrueval) => {
        item.active = false;
        if (item.viewDisabled === false) {
          item.view = setTrueval;
        } else {
          item.view = false;
        }
        if (item.editDisabled === false) {
          item.edit = setTrueval;
        } else {
          item.edit = false;
        }
        if (item.deleteDisabled === false) {
          item.delete = setTrueval;
        } else {
          item.delete = false;
        }
        if (item.addDisabled === false) {
          item.add = setTrueval;
        } else {
          item.add = false;
        }
        if (item.allDisabled === false) {
          item.all = setTrueval;
        } else {
          item.all = false;
        }
        if (setTrueval === false) {
          item.partially = false;
        }
        if (item.child) {
          item.child.map((child) => setPermissions(child, setTrueval));
        }
        if (item.sections) {
          item.sections.map((section) => setPermissions(section, setTrueval));
        }
      };
      setPermissions(data, setTrueval);
      if (firstParent && setTrueval) {
        firstParent.partially = true;
      } else if (firstParent && setTrueval === false) {
        firstParent.partially = this.checkPermissions(firstParent);
      }
      if (firstParent && setTrueval && secondParent) {
        firstParent.partially = true;
        secondParent.partially = true;
      } else if (firstParent && setTrueval === false && secondParent) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
      }
      if (firstParent && setTrueval && secondParent && thirdParent) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
      }
      if (
        firstParent &&
        setTrueval &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
        forthParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
        forthParent.partially = this.checkPermissions(secondParent);
      }
      if (data.level === "segment") {
        this.filedData[data.customName] = this.updatePermissionsFileds(
          this.tempVariable[data.customName],
          setTrueval
        );
      } else {
        this.filedData = this.updateFieldValues(this.tempVariable, setTrueval);
      }
      const mergedPayload = this.transformData(this.sections);
      this.filterSettingsData.permission = this.filedData;
      this.$emit("permission-value", mergedPayload, this.filterSettingsData);
      return data;
    },
    updateAdd(
      setTrue,
      data,
      firstParent,
      secondParent,
      thirdParent,
      forthParent
    ) {
      const setTrueval = setTrue.checked;
      var setPermissions = (item, setTrueval) => {
        item.active = false;
        if (item.addDisabled === false) {
          item.add = setTrueval;
          if (setTrueval === true) {
            item.view = true;
          }
          if (setTrueval === false) {
            item.all = setTrueval;
          }
        } else {
          item.add = false;
        }
        if (item.child) {
          item.child.map((child) => setPermissions(child, setTrueval));
        }
        if (item.sections) {
          item.sections.map((section) => setPermissions(section, setTrueval));
        }
      };
      setPermissions(data, setTrueval);
      if (firstParent && setTrueval) {
        firstParent.partially = true;
      } else if (firstParent && setTrueval === false) {
        firstParent.partially = this.checkPermissions(firstParent);
      }
      if (firstParent && setTrueval && secondParent) {
        firstParent.partially = true;
        secondParent.partially = true;
      } else if (firstParent && setTrueval === false && secondParent) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
      }
      if (firstParent && setTrueval && secondParent && thirdParent) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
      }
      if (
        firstParent &&
        setTrueval &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
        forthParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
        forthParent.partially = this.checkPermissions(secondParent);
      }
      const mergedPayload = this.transformData(this.sections);
      this.filterSettingsData.permission = this.filedData;
      this.$emit("permission-value", mergedPayload, this.filterSettingsData);
      return data;
    },
    updateEdit(
      setTrue,
      data,
      firstParent,
      secondParent,
      thirdParent,
      forthParent
    ) {
      const setTrueval = setTrue.checked;
      const setPermissions = (item, setTrueval) => {
        item.active = false;
        if (item.editDisabled === false) {
          item.edit = setTrueval;
          if (setTrueval === true) {
            item.view = true;
          }
          if (setTrueval === false) {
            item.all = setTrueval;
          }
        } else {
          item.edit = false;
        }
        if (item.child) {
          item.child.map((child) => setPermissions(child, setTrueval));
        }
        if (item.sections) {
          item.sections.map((section) => setPermissions(section, setTrueval));
        }
      };
      setPermissions(data, setTrueval);
      if (firstParent && setTrueval) {
        firstParent.partially = true;
      } else if (firstParent && setTrueval === false) {
        firstParent.partially = this.checkPermissions(firstParent);
      }
      if (firstParent && setTrueval && secondParent) {
        firstParent.partially = true;
        secondParent.partially = true;
      } else if (firstParent && setTrueval === false && secondParent) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
      }
      if (firstParent && setTrueval && secondParent && thirdParent) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
      }
      if (
        firstParent &&
        setTrueval &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
        forthParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
        forthParent.partially = this.checkPermissions(secondParent);
      }
      const mergedPayload = this.transformData(this.sections);
      if (data.level === "segment") {
        this.filedData[data.customName] = this.updatePermissionsFiledsEdit(
          this.tempVariable[data.customName],
          setTrueval
        );
      } else {
        this.filedData = this.updateFieldValuesEdit(
          this.tempVariable,
          setTrueval
        );
      }
      this.filterSettingsData.permission = this.filedData;
      this.$emit("permission-value", mergedPayload, this.filterSettingsData);
      return data;
    },
    updateView(
      setTrue,
      data,
      firstParent,
      secondParent,
      thirdParent,
      forthParent
    ) {
      const setTrueval = setTrue.checked;
      const setPermissions = (item, setTrueval) => {
        item.active = false;
        if (item.viewDisabled === false) {
          item.view = setTrueval;
          if (setTrueval === true) {
            item.view = true;
          }
          if (setTrueval === false) {
            item.all = setTrueval;
            item.edit = setTrueval;
            item.add = setTrueval;
            item.delete = setTrueval;
          }
        } else {
          item.view = false;
        }
        if (item.child) {
          item.child.map((child) => setPermissions(child, setTrueval));
        }
        if (item.sections) {
          item.sections.map((section) => setPermissions(section, setTrueval));
        }
      };
      setPermissions(data, setTrueval);
      if (firstParent && setTrueval) {
        firstParent.partially = true;
      } else if (firstParent && setTrueval === false) {
        firstParent.partially = this.checkPermissions(firstParent);
      }
      if (firstParent && setTrueval && secondParent) {
        firstParent.partially = true;
        secondParent.partially = true;
      } else if (firstParent && setTrueval === false && secondParent) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
      }
      if (firstParent && setTrueval && secondParent && thirdParent) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
      }
      if (
        firstParent &&
        setTrueval &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
        forthParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
        forthParent.partially = this.checkPermissions(secondParent);
      }
      const mergedPayload = this.transformData(this.sections);
      if (data.level === "segment") {
        this.filedData[data.customName] = this.updatePermissionsFiledsView(
          this.tempVariable[data.customName],
          setTrueval
        );
      } else {
        this.filedData = this.updateFieldValuesView(
          this.tempVariable,
          setTrueval
        );
      }
      this.filterSettingsData.permission = this.filedData;
      this.$emit("permission-value", mergedPayload, this.filterSettingsData);
      return data;
    },
    updateDelete(
      setTrue,
      data,
      firstParent,
      secondParent,
      thirdParent,
      forthParent
    ) {
      const setTrueval = setTrue.checked;
      const setPermissions = (item, setTrueval) => {
        item.active = false;
        if (setTrueval === true) {
          item.view = true;
        }
        if (item.deleteDisabled === false) {
          item.delete = setTrueval;
          if (setTrueval === false) {
            item.all = setTrueval;
          }
        } else {
          item.delete = false;
        }
        if (item.child) {
          item.child.map((child) => setPermissions(child, setTrueval));
        }
        if (item.sections) {
          item.sections.map((section) => setPermissions(section, setTrueval));
        }
      };
      setPermissions(data, setTrueval);
      if (firstParent && setTrueval) {
        firstParent.partially = true;
      } else if (firstParent && setTrueval === false) {
        firstParent.partially = this.checkPermissions(firstParent);
      }
      if (firstParent && setTrueval && secondParent) {
        firstParent.partially = true;
        secondParent.partially = true;
      } else if (firstParent && setTrueval === false && secondParent) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
      }
      if (firstParent && setTrueval && secondParent && thirdParent) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
      }
      if (
        firstParent &&
        setTrueval &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
        forthParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
        forthParent.partially = this.checkPermissions(secondParent);
      }
      const mergedPayload = this.transformData(this.sections);
      this.filterSettingsData.permission = this.filedData;
      this.$emit("permission-value", mergedPayload, this.filterSettingsData);
      return data;
    },
    async toggleSection(index, section) {
      if (this.sections[index]) {
        this.sections[index].isOpen = !this.sections[index].isOpen;
      }
      if (!this.isSubFunctionCalled) {
        this.isSubFunctionCalled = true;
        this.filtedData(section);
        const varibled = await UPCAxiosInstance.post(
          `util/filterData`,
          this.filterSettings
        );
        this.filterSettingsData = varibled.data.data[0];
        const variableData = this.mergePayloads(
          this.filterSettingsData?.permission,
          this.tempVariable
        );
        this.filedData = variableData;
        console.log(
          this.tempVariable,
          this.filedData,
          this.filterSettings,
          variableData,
          "this.tempVariable"
        );
        if (varibled.data.data.length > 0) {
          console.log(this.tempVariable, this.filedData, this.filterSettings);
        } else {
          const payloadVal = {
            module: "Multi dwelling unit",
            section: "Lead",
            permission: this.filedData,
            role: {
              id: this.$route.query.id,
              name: this.roleData.role,
            },
          };
          const filterResultDatafiled = await UPCAxiosInstance.post(
            `roleSetting`,
            payloadVal
          );
          filterResultDatafiled;
          this.filterSettingsData = filterResultDatafiled.data;
        }
      }
    },
    mergePayloads(payload1, payload2) {
      // If payload1 is null, undefined, or empty, return payload2
      if (!payload1 || Object.keys(payload1).length === 0) {
        return payload2;
      }

      // If payload2 is null, undefined, or empty, return payload1
      if (!payload2 || Object.keys(payload2).length === 0) {
        return payload1;
      }

      for (const category in payload2) {
        if (Object.prototype.hasOwnProperty.call(payload2, category)) {
          // If the category exists in payload1, merge its keys
          if (Object.prototype.hasOwnProperty.call(payload1, category)) {
            const keys2 = payload2[category];
            for (const key in keys2) {
              if (Object.prototype.hasOwnProperty.call(keys2, key)) {
                if (
                  !Object.prototype.hasOwnProperty.call(payload1[category], key)
                ) {
                  // Add the missing key to payload1
                  payload1[category][key] = keys2[key];
                }
              }
            }
          } else {
            // If the category doesn't exist in payload1, add it entirely
            payload1[category] = payload2[category];
          }
        }
      }
      return payload1;
    },
    toggleInnerSection(sectionIndex, innerIndex) {
      if (
        this.sections[sectionIndex] &&
        (this.sections[sectionIndex].child ||
          this.sections[sectionIndex].sections)
      ) {
        const target = this.sections[sectionIndex].child
          ? this.sections[sectionIndex].child[innerIndex]
          : this.sections[sectionIndex].sections[innerIndex];
        if (target) {
          target.isOpen = !target.isOpen;
        }
      }
    },
    toggleThirdLayer(sectionIndex, innerIndex, thirdIndex) {
      if (
        this.sections[sectionIndex] &&
        this.sections[sectionIndex].child &&
        this.sections[sectionIndex].child[innerIndex] &&
        this.sections[sectionIndex].child[innerIndex].child
      ) {
        const target =
          this.sections[sectionIndex].child[innerIndex].child[thirdIndex];
        if (target) {
          target.isOpen = !target.isOpen;
        }
      }
    },
    toggleForthLayer(sectionIndex, innerIndex, thirdIndex, forthIndex) {
      if (
        this.sections[sectionIndex] &&
        this.sections[sectionIndex].child &&
        this.sections[sectionIndex].child[innerIndex] &&
        this.sections[sectionIndex].child[innerIndex].child &&
        this.sections[sectionIndex].child[innerIndex].child[thirdIndex]
      ) {
        const target =
          this.sections[sectionIndex].child[innerIndex].child[thirdIndex];
        if (
          target &&
          (target.sections || target.child) &&
          (target.sections
            ? target.sections[forthIndex]
            : target.child[forthIndex])
        ) {
          const forthTarget = target.sections
            ? target.sections[forthIndex]
            : target.child[forthIndex];
          forthTarget.isOpen = !forthTarget.isOpen;
        }
      }
    },
  },
};
</script>

<style scoped>
.accordion {
  overflow-y: auto;
  scrollbar-width: none;
}
.accordion::-webkit-scrollbar {
  display: none;
}
.pannelFirstLayer {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  padding-left: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.pannelsecoundLayer {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  padding-left: 58px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.pannelTiredLayer {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  padding-left: 78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.pannelForthLayer {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  padding-left: 54px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.fourth-accord {
  padding-left: 54px !important;
}
.accordion-btn {
  position: relative;
  display: flex;
  align-items: center;
  font-family: "Opensans";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.07px;
  cursor: pointer;
  padding: 14px 0;
  width: 100%;
  text-align: left;
  border: none;
  transition: 0.3s;
}
.accordion-icon {
  float: left;
  margin-right: 10px;
  color: black;
  transition: transform 0.3s;
}
.accordion-btn.active .accordion-icon {
  color: balck;
}
.panel {
  max-height: 0;
  overflow: hidden;
  height: auto;
  transition: max-height 0.3s ease, opacity 0.3s;
  opacity: 0;
}
.panel.show {
  opacity: 1;
  max-height: 100%;
}
.inner-accord {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-left: 30px;
}
.third-accord {
  padding-left: 52px;
}
.permissions-table-container {
  width: 100%;
  overflow-x: hidden;
}
.permissions-table {
  width: 112%;
  overflow-x: hidden;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 15px;
}
.permissions-header {
  padding: 12px;
  text-align: left;
  font-weight: bold;
}
.permissions-cell {
  padding: 12px;
}
.permissions-checkbox {
  cursor: pointer;
  margin: 0;
}
</style>
