<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />

  
  <!-- add region modal -->
  <addRegionComponent
  :key="addregionKey"
  :open-modal="showAddNewRegionModal"
  @add-region="addRegion"
  @close-modal="closeAddRegionModal"
  ></addRegionComponent>

  
    <!-- Filter drawer -->
    <hlx-drawer
                    :show="show_right_filter"
                    :width="300"
                    position="right"
                    :header="false"
                    :footer="false"
                    :show-close-icon="true"
                    @close="closeRightFilter"
                  >
                    <template #body>
                      <div style="display: flex">
                        <FilterComponent
                        :key="filterkey"
                          :filterpanelname="'address'"
                          :company-name="company"
                          :query-value="query"
                          :display-data="filter"
                          :module="'address'"
                          @filtered-data="quotesfilterData"
                        ></FilterComponent>
                      </div>
                    </template>
                  </hlx-drawer>



<!-- delete -->
<hlx-modal
        style="z-index: 999999999"
        :modal-active="delete_modal_active"
        :height="'220px'"
        :width="'400px'"
        :modal="true"
        @close="delete_modal_active = false"
      >
        <template #header>
          <div style="margin-left: 0px">
            <!-- Delete region -->
            {{ matchFromStatic('Delete region') }}
          </div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              <!-- Are you sure you want to delete  -->
               {{ matchFromStatic('Are you sure you want to delete') }}
              <b>{{ delete_name }}</b
              >?
            </p>
          </div>
        </template>
        <template #footer>
          <hlx-button class="secondary sm" @click="delete_modal_active = false"
            >
            <!-- Cancel -->
             {{ matchFromStatic('Cancel') }}
            </hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 16px"
            @click="deleteExecRow"
            >
            <!-- Delete -->
             {{ matchFromStatic('Delete') }}
            </hlx-button
          >
        </template>
      </hlx-modal>




   <section style="margin: 10px 15px">

    
  <!-- header -->
  <section
      v-if="tableData && tableData?.length > 0 || searchvalue !== ''"
      class="right-view-body-feature">
      <span class="spec-feature --table-tool-bar" style="margin: 10px 0">
        <div class="--table-tool-bar-wrapper">

        <!-- search -->
          <span class="util-items search"
            ><hlx-search
            v-model="searchvalue"
            :label-value="matchFromStatic('Search')"
              @search-key="searchKey"
            ></hlx-search
          ></span>

          <!-- Filter -->
             <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
              <i class="icon-filter-regular"></i>
              <span v-if="filtertaglength != 0" class="filter-count">{{
                filtertaglength
              }}</span>
            </hlx-button>


          <hlx-button
          v-if="permisstionData.add"
              class="primary sm"
              @click="openAddNewRegionModal"
            >
              <i class="icon-plus-circle-regular"></i> 
              <!-- Add region -->
               {{ matchFromStatic('Add region') }}
              </hlx-button
            >

        </div>
      </span>
    </section>






      <!-- table -->
  <section v-if="tableData && tableData?.length > 0">
<hlx-table
  :key="refreshTable1"
  :column-count="tableHeads + 2"
  :border="['table', 'header', 'horizontal']"
  :theme="'grey'"
  :bold-headers="false"
  :row-hover="true"
  :striped-rows="false"
  :re-arrange-columns="re_arrange"
  :re-arrange-heads="theads"
  @close-rearrange="arrange"
  @apply-rearranged-columns="applyColumns"
>
  <template #thead>
    <hlx-table-head
      :width="60"
      :align="'left'"
      style="padding: 0 15px !important; max-width: 60px !important"
      :fixed="'left'"
      >{{ matchFromStatic('S.no') }}</hlx-table-head
    >
    <hlx-table-head
      v-for="(i, index) in tableHeads"
      :key="index"
      :prop="i.prop"
      :width="i.width"
      :align="'left'"
      :resizable="true"
    >
      {{ matchFromStatic(i.label) }}
    </hlx-table-head>
    <hlx-table-head v-if=" permisstionData.delete" :align="'center'" :width="60" :fixed="'right'"
      ><span class="action-icon">
        <i
          class="icon-settings-regular"
          style="cursor: default"
        ></i> </span
    ></hlx-table-head>
  </template>
  <template #tbody>
    <tr v-for="(i, index) in tableDataOnSelectedRows" :key="index">
      <hlx-table-cell :width="60" fixed="left">
        {{ serialNumber(index) }}
      </hlx-table-cell>
      <hlx-table-cell
        v-for="(j, col_index) in tableHeads"
        :key="col_index"
        :align="'left'"
        :width="i.width"
      >


      <div v-if="j.prop === 'regionName'">
                  <!-- <div
                    v-for="(addressLine, index1) in i.streetAddressLine"
                    :key="index1"
                  >
                    <span 
                      v-if="addressLine.characteristic.regionName && permisstionData.edit || permisstionData.partially || permisstionParentData.partially"
                      style="cursor: pointer; color: #2879b7"
                      onmouseover="this.style.textDecoration='underline';"
                      onmouseout="this.style.textDecoration='none';"
                      @click="callOverviewRoute(i?.id)"
                      >{{ addressLine.characteristic.regionName }}</span
                    >
 <span v-else>{{ addressLine.characteristic.regionName || "" }}</span>

                  </div> -->


                  <span
                      v-if="i.criteria.regionName && permisstionData.edit || permisstionData.partially || permisstionParentData.partially"
                      style="cursor: pointer; color: #2879b7"
                      onmouseover="this.style.textDecoration='underline';"
                      onmouseout="this.style.textDecoration='none';"
                      @click="callOverviewRoute(i?.id, false)"
                      >{{ i.criteria.regionName }}</span
                    >

                </div>
                <div v-else-if="j.prop === 'addressdescription'">
                  <!-- <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <span v-if="addressLine.characteristic.description">{{
                      removeFirstComma(addressLine?.characteristic?.description)
                    }}</span>
                  </div> -->
                  {{ removeFirstComma(i.description) }}
                </div>
                <div v-else-if="j.prop === 'servicestatus'">
                  <!-- <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <div>
                      <hlx-status
                        :type="addressLine.characteristic.servicestatus"
                      ></hlx-status>
                    </div>
                  </div> -->

                  <hlx-status
                        :type="matchFromStatic(i?.statusInclusion)"
                      ></hlx-status>


                </div>
                <div v-else-if="j.prop === 'type'">
                  <!-- <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <div>
                      {{ addressLine.characteristic.type }}
                    </div>
                  </div> -->

                  {{ matchFromStatic(i.criteria.type) }}

                </div>
                <div v-else-if="j.prop === 'createdat'">
                  <!-- <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <div v-if="addressLine?.characteristic?.createdat">
                      {{ formatDate(addressLine?.characteristic?.createdat) }}
                    </div>
                  </div> -->

                  {{ dateAndTimeFormatter(i.createdAt) }}

                </div>
                <div v-else-if="j.prop === 'updatedat'">
                  <!-- <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <div v-if="addressLine?.characteristic?.updatedat">
                      {{ formatDate(addressLine?.characteristic?.updatedat) }}
                    </div>
                  </div> -->

                  {{ dateAndTimeFormatter(i.updatedAt) }}

                </div>


      </hlx-table-cell>
      <hlx-table-cell v-if=" permisstionData.delete" :align="'center'" :width="120" :fixed="'right'">
        <span :id="'table-context' + index" class="action-icon">
          <i
            style="position: relative"
            class="icon-more-vertical-filled"
            :class="i.context === true ? 'active-action' : ''"
            @click="crudContextMenu($event, i, index)"
          >
            <hlx-context-menu
            :display="'display'"
              :top="containertop"
              :left="containerleft"
              :conditionvalue="150"
              :conditiondown="2"
              :conditionup="-38"
              :options="computedTableAction(i)"
              :data="index.toString()"
              :show="i.context == true"
              @chosen="closeCrudContextMenu($event, i, index)"
            />
          </i>
        </span>
      </hlx-table-cell>
    </tr>
  </template>
</hlx-table>

  <!-- pagination -->
   
 <span class="newsssssssssssssss">
  <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :label-value="paginationLang"
          :total="totalpageno"
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </span>
</section>


<!-- no data -->
<section v-if="tableData.length === 0 && searchvalue !== ''">
<hlx-table
    :column-count="tableHeads.length + 2"
    :border="['table', 'header']"
    :bold-headers="false"
    :row-hover="true"
    theme="grey"
    :striped-rows="false"
    :re-arrange-columns="toggleRearrangeModal"
    :re-arrange-heads="tableHeadsRearranging"
    @close-rearrange="toggleRearrangeModal = false"
    @apply-rearranged-columns="applyColumns"
  >
    <template #thead>
      <hlx-table-head :width="60" :align="'left'"   @new-resize-width="resizedWidth">{{
        matchFromStatic("S.no")
      }}</hlx-table-head>
      <hlx-table-head
        v-for="(i, index) in tableHeads"
        :key="index"
        :prop="i.prop"
        :sortable="i.sortable"
        :resizable="i.resizable"
        style="position: sticky; top: 0px"
        :width="i.width !== undefined ? i.width : ''"
        :align="i.align !== undefined ? i.align : ''"
        @sorting_func="sorting_Data"
          @new-resize-width="resizedWidth"
      >
        {{ matchFromStatic(i.label) }}
      </hlx-table-head>
      <hlx-table-head v-if=" permisstionData.delete" v-show="supplierStatus == false" :width="100">
        <span class="action-icon"
          ><i
            class="icon-settings-regular"
            @click="toggleRearrangeModal = true"
              @new-resize-width="resizedWidth"
          ></i></span
      ></hlx-table-head>
    </template>
    <template #tbody>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell :colspan="tableHeads?.length + 2">{{
          matchFromStatic("No data")
        }}</hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
</section>


        <!-- image -->
        <section
         v-if="tableData && tableData?.length == 0 && searchvalue === ''"
        style="
          margin-top: 1.2rem;
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          align-items: center;
        "
      >

      <span>
          <img
            src="@/assets/images/svg/SQregion.svg"
            alt=""
            style="width: 500px; height: 500px"
          />
        </span>
        <span class="util-items util-items-add">
          <hlx-button
          v-if="permisstionData.add"
            class="primary media add-btn"
              @click="openAddNewRegionModal"
            ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i
            >
            <!-- Add region -->
             {{ matchFromStatic('Add region') }}
            </hlx-button
          >
        </span>
        
    
    </section>






  </section>
</template>

<script>
import addRegionComponent from "@/components/Product/ServiceQualificationDuplicate/addRegionModalComponent.vue"
import {
//   DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
  // UPCAxiosInstance
} from "@/config/axiosConfig";
import FilterComponent from "@/components/filterPanelComponent.vue";
export default {
  components: {
    addRegionComponent,
    FilterComponent
  },
  props: {
    permisstionData: {
      type: Object,
      required: true, 
      default: () => {},
    },
    permisstionParentData: {
      type: Object,
      required: true, 
      default: () => {},
    },
  },

    data() {
      return {

        paginationLang:{
        Rows_per_page: this.matchFromStatic("Rows per page"),
        Showing: this.matchFromStatic("Showing"),
        of: this.matchFromStatic("of"),
        results: this.matchFromStatic("results"),
        Go_to: this.matchFromStatic("Go to"),
      },

      addregionKey: 0,

          
      tableData: [],

      totalpageno:0,


            tableHeads: [
        {
          sortable: false,
          resizable: true,
          width: 100,
          name: "Region name",
          disabled: true,
          checked: true,
          label: "Region name",
          prop: "regionName",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 154,
          name: "Status",
          disabled: false,
          checked: true,
          label: "Status",
          prop: "servicestatus",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 154,
          name: "Type",
          disabled: false,
          checked: true,
          label: "Type",
          prop: "type",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          checked: true,
          disabled: true,
          name: "Address",
          label: "Address",
          prop: "addressdescription",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 154,
          name: "Created date",
          disabled: false,
          checked: true,
          label: "Created date",
          prop: "createdat",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 154,
          name: "Last updated",
          disabled: false,
          checked: true,
          label: "Last updated",
          prop: "updatedat",
          align: "left",
        },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 250,
        //   name: "Region shape",
        //   checked: true,
        //   disabled: false,
        //   label: "Region shape",
        //   prop: "regionshape",
        //   align: "left",
        // },
       
      ],



      notification: "",


      
            
      filtertaglength: 0,

            
filterQueryforBill: {
        type: "filter",
        module: "address",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {},
        limit: 10,
                filterQuery: {
            "type": {
                "$in": [
                    "inclusion"
                ]
            }
        },
        searchQuery: "",
        company: this.$cookies.get("userName"),
      },

      
currPage: 1,
rowsPerPage: 10,
      externalRowsPerPage: 10,

      searchvalue: "",

      delete_modal_active: false,
      
      deleteobj: {},

      delete_name:'',

      showAddNewRegionModal: false,

      show_right_filter: false,
      
      company: this.$cookies.get("company"),

      query:{
        'Type':["criteria.type"],
        'Status':["statusInclusion"],
      },
      
      filter:[],

        }
  },

  computed: {
   
    tableDataOnSelectedRows() {
      return this.tableData;
    },

    
  }
  ,

  async mounted() {
    // Fetch and store Vuex filter data
    await this.$store.dispatch("serviceQualificationAddressFilterData");
      
    this.ticketdataCalling(this.currPage, this.rowsPerPage);

    console.log("this.$store.getters.serviceQualificationAddressFilterData::", this.$store.getters.serviceQualificationAddressFilterData);

    this.filter = this.$store.getters.serviceQualificationAddressFilterData;

    // this.filter =
      // this.updateRegionNames(
      //         this.$store.getters.serviceQualificationAddressFilterData
      //       );
            console.log("filter data from store", this.filter);


  },

  methods: {

    

    updateRegionNames(data) {
      // Loop through each key in the object
      for (const key in data) {
        // Check if the current key's value is an array
        if (Array.isArray(data[key])) {
          // Loop through the array of objects under each key
          data[key].forEach((item) => {
            try {
              // Check if 'name' is an array and has at least one element
              if (Array.isArray(item.name) && item.name.length > 0) {
                // Set 'name' to the first element as a string
                item.name = item.name[0];
              } else {
                throw new Error(
                  `Invalid 'name' value in item: ${JSON.stringify(item)}`
                );
              }
            } catch (error) {
              console.error(`Error processing item in ${key}:`, error.message);
            }
          });
        } else {
          console.error(
            `Expected an array for key: ${key}, but found:`,
            data[key]
          );
        }
      }
      return data;
    },
    
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },


    crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 102;

        this.containertop =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().top + 10;  
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },


    
    closeCrudContextMenu($event, payload, i) {
      payload, i
      if ($event.toLowerCase() === "edit") {
        this.callOverviewRoute(payload?.id, true);
        //"jjjjjjjjjjjjjjjjjjjjjj");
      } else if ($event.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        this.deleteobj = payload;
        console.log("qqqqqqqqqqqqq", payload, this.deleteobj?._id);
        // this.delete_name = payload?.name;
      }
    },
    computedTableAction() {      
      if (this.permisstionData.delete) {
        return [
          {
          display: this.matchFromStatic('Edit'),
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            display: this.matchFromStatic('Delete'),
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }
    },


    async deleteExecRow() { 
      
      this.delete_modal_active = false;

      await UPCAxiosInstance.delete(
          `/geographicAddress/${this.deleteobj.id}`
      );

      // if (deleteresponse) {
        this.notification = {
            type: "success",
            message: this.matchFromStatic('Region deleted successfully!'),
          };
      // }

      this.ticketdataCalling(this.currPage, this.rowsPerPage);


      await this.deleteRegionRelation(this.deleteobj.id);

      
    },


    openAddNewRegionModal() { 
      this.addregionKey += 1;
      this.showAddNewRegionModal = true;
    },

    addRegion(val) {
      console.log("addRegion::",val);
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },

    closeAddRegionModal() {  
    },
    

    callOverviewRoute(id, edit) {
      id
      console.log("callOverviewRoute::",id);

      if (edit) {
        this.$router.push({
          name: "regionOverviewTest",
          query: { id: id, mode: "edit" },
        });
      } else {
        this.$router.push({
          name: "regionOverviewTest",
          query: { id: id, mode: "view" },
        });
      }
      
      
     },

    formatDate(isoDate) {
    const date = new Date(isoDate);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
},
    
    async ticketdataCalling(currentPage, rowsPerPage) {
  currentPage, rowsPerPage
  console.log("ticketdataCalling...123",currentPage, rowsPerPage);
      this.filterQueryforBill["company"] = this.$cookies.get('company');
        // this.$cookies.get('company');
      this.filterQueryforBill["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQueryforBill["paginatedQuery"]["rowsPerPage"] = rowsPerPage;

      this.filterQueryforBill.filterQuery = {
        "type": {
                "$in": [
                    "inclusion"
                ]
            }
      }



      this.filterQueryforBill.module = 'address';
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);
        
        // this.totalvalue = filterResultData.data.total;      
      }
    },



    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
        },


        
        async searchKey(val) {
          console.log("searchKey", val);

          // this.filtertaglength = 0;
      
      this.searchvalue = val;
      this.filterQueryforBill["company"] = this.$cookies.get("company");
      this.filterQueryforBill["type"] = "filter-search"
 || "search";
      this.filterQueryforBill["searchQuery"] = val;
      this.filterQueryforBill.searchList =
       [
      {
          "criteria.type": {
            $regex: val,
            $options: "i",
          },
          },
        
      {
          "description": {
            $regex: val,
            $options: "i",
          },
        },
        {
                "statusInclusion": {
                  $regex: val,
                  $options: "i",
                },
        },
        {
                "criteria.regionName": {
                  $regex: val,
                  $options: "i",
                },
        },
        {
                "createdAt": {
                  $regex: val,
                  $options: "i",
                },
        },
        {
                "updatedAt": {
                  $regex: val,
                  $options: "i",
                },
        },
      ];
      // this.currPage = 1;
      // this.rowsPerPage = 10;
      if (this.filtertagvalue?.length != 0) {
        this.filterQueryforBill["type"] = "filter-search";
      }
      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },

    

    
    async quotesfilterData(filterSelectedvalue, filterQuery) {
      console.log("quotesfilterData::))",filterSelectedvalue, filterQuery);
      this.filtertaglength = filterSelectedvalue?.length;

      // this.searchvalue = '';

      this.filterQueryforBill['filterQuery'] = this.addFilterQuery(
        filterQuery,
        "type",
        "inclusion");
        console.log("this.filterQueryforBill::",this.filterQueryforBill, filterQuery);
        
      // this.filterQueryforBill = filterQuery;

      

      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);        
        // this.totalvalue = filterResultData.data.total;      
      }      
        },


        async changeRowsPerPage(count) {
      //
      if (this.companyasignee != "") {
        this.rowsPerPage = count;
        this.ticketdataCalling(
          this.currPage,
        this.rowsPerPage
        );
        // this.refreshTable();
      }
    },
    
    async currentPage(val) {
      if (this.companyasignee != "") {
        this.currPage = val;
        this.ticketdataCalling(
          this.currPage,
        this.rowsPerPage
        );
        // this.refreshTable();
      }
    },


  }

}
</script>

<style>

</style>