<template>
  <!-- modal window for back this page -->
  <hlx-modal
        style="z-index: 999999999"
        :modal-active="delete_modal_active"
        :height="'220px'"
        :width="'400px'"
        :modal="true"
        @close="closeDiscardModal()"
      >
        <template #header>
          <div style="margin-left: 0px">Unsaved changes</div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              Do you want to save your changes before navigating away?
            </p>
          </div>
        </template>
        <template #footer>
          <hlx-button
class="secondary sm"               
          @click="deleteExecRow()"     
            >Discard</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 16px"
            @click="closeDiscardModal()"
            >Stay</hlx-button
          >
        </template>
      </hlx-modal>
  <!-- Filter drawer -->
  <hlx-drawer
    :show="show_right_filter"
    :width="300"
    position="right"
    :header="false"
    :footer="false"
    :show-close-icon="true"
    @close="closeRightFilter"
  >
    <template #body>
      <div style="display: flex">
        <FilterComponent
          :key="filterComponentKey"
          :display-alternate-values="displayAlternate"
          :pre-value="filterPrevalueDuplicate1"
          :filterpanelname="'productOffering'"
          :company-name="company"
          :query-value="query"
          :display-data="displayFilterData"
          :module="'discountOffers'"
          @filtered-data="offeringFilterData"
        ></FilterComponent>
      </div>
    </template>
  </hlx-drawer>

  <!-- drawer 2 -->
  <div v-if="1 > 10" class="add-contact-drawer"> // by asif
  <hlx-drawer
    :key="recipientKey"
    :show="showRecipientDrawer"
    :width="400"
    position="right"
    :header="false"
    :footer="true"
    :show-close-icon="true"
    @close="closeSidePanelForQuote"
  >
    <template #body>
      <!-- Recipients -->

      <div class="second_drawer_style_for_recepient">
      <div
        class="upload_image_header"
        style="padding: 7px; border-bottom: 1px solid #d8d8d8"
      >
        <b style="font-size: 16px">Recipients</b>
      </div>
      <!-- Recipients select -->

      <div style="width: 100%; margin-top: 11px">
        <hlx-select
          :key='recipientSelectKey'
          v-model:value="wen"
          :pre-value="wen"
          :slot-options="true"
          :required="false"
          :options="recipientContacts"
          :placeholder-value="'Recipient name'"
          :label-animation="false"
          :single-border="false"
          :label="'name'"
          :prop-value="'name'"
          :clearable="true"
          :inline-search="true"
          @selected-value="addNewContactList"
        >
          <template #displayValue="value">
            <span
              style="
                padding-left: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
              "
            >
              {{ value["displayLabel"]["name"] }}
            </span>
          </template>
          <template #options="option">
            <div
              v-if="option && option.optionsData && option.optionsData.name"
              style="
                display: flex;
                align-items: center;
                font-size: 14px;
                padding-left: 8px;
                font-family: 'Opensans';
                height: 31px !important;
              "
            >
              <span style="font-size: 14px; font-weight: 400">{{
                option.optionsData.name
              }}</span>
              <span style="padding-left: 10px; width: 20px"
                ><i class="icon-buildings-alt-regular"></i
              ></span>
              <span
                style="
                  font-size: 11px;
                  font-weight: 400;
                  padding-top: 3px;
                  padding-left: 8px;
                  color: #595959;
                "
                >{{ option.optionsData.company }}</span
              >
            </div>
          </template>
        </hlx-select>
      </div>

      <!-- div for show contact -->
      <div
        v-if="showDraggableFunctionQuoteRecepient === false"
        style=""
        class="quoteWholeDivContact"
      >
        <div
          v-for="(i, index) in recipientsList"
          :key="index"
          class="outerMainContainer"
        >
          <div class="main1">
            <section>
              <hlx-file-upload
                :key="componentkey"
                :file-type="'.jpg,.jpeg,.png'"
                :file-size-limit="'20mb'"
                :uploadtype="'Circle'"
                :style-background="backgrounStyleuploadQuoteRecipient"
                :height="30"
                :width="32"
                :read-only="true"
                :custom-icon="icon"
                :type="'profile-upload'"
                :profile-icon="'icon-user-alt-regular'"
                :content="'Import'"
                :name-display="i?.customName || i?.name"
                :profile-pic-preview="i?.src"
              />
            </section>
          </div>
          <div class="main2">
            <div class="div1">
              <span class="span1">{{ i?.name }}</span>
              <span class="span2"
                ><hlx-label class="primary sm">{{ i?.role }}</hlx-label></span
              >
            </div>
            <div class="div2">{{ i?.company }}</div>
          </div>
          <div class="main3" @click="closeTheBox(i)">
            <i class="icon-times-regular"></i>
          </div>
        </div>
      </div>

      <!-- draggable receipent -->
      <div
        v-if="showDraggableFunctionQuoteRecepient === true"
        style=""
        class="quoteWholeDivContact"
      >
        <draggable
          class="draggable-feature-values"
          :list="recipientsList"
          item-key="id"
        >
          <template #item="{ element, index }">
            <div class="outerMainContainer">
              <div style="margin: 0px 0px 4px 0px; color: #7e7e7e">
                <i class="icon-drag-vertical-filled"></i>
              </div>
              <div class="draggableApprovalDefaultSpanForIcon">
                <span>{{ index + 1 }}</span>
              </div>
              <div class="main1">
                <section>
                  <hlx-file-upload
                    :key="componentkey"
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Circle'"
                    :style-background="backgrounStyleuploadQuoteRecipient"
                    :height="30"
                    :width="32"
                    :read-only="true"
                    :custom-icon="icon"
                    :type="'profile-upload'"
                    :profile-icon="'icon-user-alt-regular'"
                    :content="'Import'"
                    :name-display="element?.customName || element?.name"
                    :profile-pic-preview="i?.src"
                  />
                </section>
              </div>
              <div class="main2">
                <div class="div1">
                  <span class="span1" style="font-size: 14px">{{
                    element.name
                  }}</span>
                  <span class="span2"
                    ><hlx-label class="primary sm">{{
                      element.role
                    }}</hlx-label></span
                  >
                </div>
                <div class="div2" style="font-size: 10px">
                  {{ element.company }}
                </div>
              </div>
              <div @click="closeTheBox(element)">
                <i class="icon-times-regular"></i>
              </div>
            </div>
          </template>
        </draggable>
      </div>

      <!-- Set signing order -->
      <!-- <div
        style="width: 100%; margin-top: 30px; display: flex; margin-left: 3px"
      >
        <span class="switchforRPrightspan1" style="font-family: 'opensans'"
          ><b>{{ "Set signing order" }}</b></span
        >
        <span class="switchforRPrightspan2">
          <hlx-input :checked="retiredProduc" :type="'switch'" />
        </span>
      </div> -->

      <!-- <div style="width: 100%; margin-top: 11px; margin-left: 3px">
        <span
          style="
            color: #8d8b8b;
            font-size: 13px;
            font-weight: 400;
            display: block;
            font-family: 'OpenSans';
            width: 329px;
          "
        >
          Customize the order in which your recipients will sign this document.
        </span>
      </div> -->

      <!-- switch box -->
      <div style="" class="divSwitchBoxToggle">
        <span class="switchforRPrightspan1" style="font-family: 'opensans'"
          ><b>{{ "Set signing order" }}</b></span
        >
        <span class="switchforRPrightspan2">
          <hlx-input
            v-model:value="showDraggableFunctionQuoteRecepient"
            :checked="showDraggableFunctionQuoteRecepient"
            :type="'switch'"
          />
        </span>
      </div>
      <div style="width: 100%; margin-top: 11px; margin-left: 3px">
        <span class="conetntDivDefaultTemplateSwitchUnder">
          Customize the order in which your recipients will sign this document.
        </span>
      </div>
      <div style="" class="divSwitchBoxToggle">
        <span class="switchforRPrightspan1" style="font-family: 'opensans'"
          ><b>{{ "Download" }}</b></span
        >
        <span class="switchforRPrightspan2">
          <hlx-input :checked="false" :type="'switch'" />
        </span>
      </div>

      <div style="width: 100%; margin-top: 11px; margin-left: 3px">
        <span class="conetntDivDefaultTemplateSwitchUnder">
          Allows recipients to download this document.
        </span>
      </div>
    </div>
    </template>
    <template #footer>
                <div
                  style="
                    display: flex;
                    justify-content: flex-end;
                    padding: 19px;
                    border-top: 1px solid #d8d8d8;
                  "
                >
                  <hlx-button
                    class="secondary sm"
                    @click="closeSidePanelForQuote()"
                    >Cancel</hlx-button
                  >
                  <hlx-button
                    class="primary sm"
                    style="margin-left: 10px"
                    @click.self="saveRecepientSignatureFlow"
                    >Save</hlx-button
                  >
                </div>
              </template>
  </hlx-drawer>
</div>
  <!-- Alert -->
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <!-- <img :src="profilePicturePrevalue"> -->

  <!-- modal window for add account -->
  <div>
    <hlx-modal
      :key="modalKeyAccount"
      :modal-active="showAddNewAccountModal"
      :close-icon="true"
      :outer-layer-close-option="false"
         :height="'750px !important'"
      :width="'800px !important'"
      :modal="true"
      @close="closeAddNewAccountModal"
    >
      <template #header>
        <div>Add account</div>
      </template>
      <template #content>
        <div class="modal-content" style="display: flex; gap: 5%">
          <div
            style="display: flex; flex-direction: column; gap: 20px; width: 50%"
          >
            <div
              style="display: flex; justify-content: space-between; gap: 10px"
            >
              <hlx-file-upload
               :key="refreshProfile1"
                :file-type="'.jpg,.jpeg,.png'"
                :file-size-limit="'20mb'"
                :uploadtype="'Rectangle'"
                :style-background="backgrounStyle"
                :height="100"
                :width="100"
                :read-only="false"
                :custom-icon="icon"
                :type="'profile-upload'"
                :name-display="newAccPayload.tradingName"
                :content="'Import'"
                @native-data="profileUploadAcc"
              />
              <!-- </span> -->
              <span style="width: 200%">
                <div
                  class="decision-name-wrapper"
                  :style="{ 'margin-bottom': '-7.6%' }"
                  style="margin-bottom: 25px"
                >
                  <hlx-input
                    v-model:value="newAccPayload.customName"
                    :pre-val="newAccPayload.customName"
                    :label-value="'Account name'"
                    :label-animation="true"
                    :required="true"
                    :display-error="customnameerror"
                    :custom-error="customnameerror"
                    :custom-error-message="'Please fill the field'"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                </div>

                <div style="width: 100%">
                  <hlx-status
                    :type="'select-status'"
                    :status-option="filteredstatus"
                    :prevalue="newAccPayload.status"
                    @emit-select="emitStatusAcc"
                  >
                  </hlx-status>
                </div>
              </span>
            </div>
            <addressComponent
              :key="addressKey"
              @address-data="receivedAddressData"
            ></addressComponent>
            <div class="decision-name-wrapper">
              <hlx-select
                v-model:value="newAccPayload.partyCharacteristic[0].value"
                :pre-value="newAccPayload.partyCharacteristic[0].value"
                :options="employeeCountList"
                prop-value="name"
                :placeholder-value="'Employees'"
                :label-animation="true"
                :clearable="true"
                :inline-search="true"
                :search-image-tag="false"
                @search-value="newValFromDropDown($event, 'employeeCount')"
              ></hlx-select>
            </div>
            <div class="decision-name-wrapper">
              <hlx-select
                v-model:value="locationType"
                :pre-value="
                  newAccPayload.contactMedium[3]?.characteristic['@type']
                "
                :options="locationTypeList"
                prop-value="name"
                :placeholder-value="'Location type'"
                :label-animation="true"
                :clearable="true"
                :inline-search="true"
                :search-image-tag="false"
                @search-value="newValFromDropDown($event, 'locationType')"
              ></hlx-select>
            </div>
            <div class="decision-name-wrapper">
              <hlx-select
                v-model:value="newAccPayload.partyCharacteristic[1].value"
                :pre-value="newAccPayload.partyCharacteristic[1].value"
                :options="industryTypeList"
                prop-value="name"
                :placeholder-value="'Industry'"
                :label-animation="true"
                :clearable="true"
                :inline-search="true"
                :search-image-tag="false"
                @search-value="newValFromDropDown($event, 'industry')"
              ></hlx-select>
            </div>
            <div class="decision-name-wrapper">
              <hlx-select
                v-model:value="accExecParty"
                :pre-value="accExecParty"
                :options="executivesList"
                :placeholder-value="'Account executive'"
                :label="'name'"
                :prop-value="'name'"
                :label-animation="true"
                :search-image-tag="true"
                :sub-title="'company'"
                :tag-value="'newRole'"
                :image-link="'link'"
                :inline-search="true"
                @selected-value="selectedExecutive"
              >
              </hlx-select>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              /* gap: 20px; */
              /* justify-content: space-between; */
              width: 50%;
            "
          >
            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="newAccPayload.description"
                :pre-val="newAccPayload.description"
                type="textarea"
                :label-value="'Description'"
                :required="false"
                :label-animation="true"
                :display-error="true"
                :custom-error="customnameerror"
                :rows="7"
                :custom-error-message="'Please fill the field'"
                @focus-out="focusOutName"
                @at-input="validateName"
              ></hlx-input>
            </div>
            <div class="decision-name-wrapper" style="margin-bottom: 20px">
              <hlx-select
                v-model:value="newAccPayload.partyCharacteristic[2].value"
                :pre-value="newAccPayload.partyCharacteristic[2].value"
                :options="marketSegmentList"
                prop-value="name"
                :placeholder-value="'Market segment'"
                :label-animation="true"
                :clearable="true"
              ></hlx-select>
            </div>
            <div class="decision-name-wrapper" style="margin-bottom: 20px">
              <hlx-select
                v-model:value="newAccPayload.preferred"
                :pre-value="newAccPayload.preferred"
                :options="preferredMediumList"
                prop-value="value"
                :placeholder-value="'Preferred contact medium'"
                :label-animation="true"
                :clearable="true"
              ></hlx-select>
            </div>
            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="
                  newAccPayload.contactMedium[0].characteristic.emailAddress
                "
                :pre-val="
                  newAccPayload.contactMedium[0].characteristic.emailAddress
                "
                type="email"
                :label-value="'Email id'"
                :required="true"
                :label-animation="true"
                :display-error="true"
                :custom-error="customnameerror"
                :custom-error-message="'Please fill the field'"
                @focus-out="focusOutName"
                @at-input="validateEmail"
              ></hlx-input>
            </div>
            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="
                  newAccPayload.contactMedium[1].characteristic.phoneNumber
                "
                :pre-val="
                  newAccPayload.contactMedium[1].characteristic.phoneNumber
                    .value
                "
                type="phonenumber"
                :country-code="
                  newAccPayload.contactMedium[1].characteristic.phoneNumber
                    .country
                "
                :label-value="'Phone no.'"
                :label-animation="true"
                :display-error="true"
                :custom-error="customnameerror"
                :custom-error-message="'Please fill the field'"
                @focus-out="focusOutName"
                @at-input="validateName"
              ></hlx-input>
            </div>

            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="
                  newAccPayload.contactMedium[2].characteristic.faxNumber
                "
                :pre-val="
                  newAccPayload.contactMedium[2].characteristic.faxNumber
                "
                :label-value="'Fax'"
                :label-animation="true"
                :display-error="true"
                :custom-error="customnameerror"
                :custom-error-message="'Please fill the field'"
                @focus-out="focusOutName"
                @at-input="validateName"
              ></hlx-input>
            </div>
            <div class="decision-name-wrapper">
              {{ websiteRegex(newAccPayload.externalReference[0].name) }}
              <!-- {{ "testing--------" }} -->
              <hlx-input
                :key="modalKeyAccount"
                v-model:value="newAccPayload.externalReference[0].name"
                :pre-val="newAccPayload.externalReference[0].name"
                :label-value="'Website URL'"
                type="text"
                :prefix="true"
                :label-animation="true"
                :display-error="true"
                :custom-error="isUrlEmpty"
                :custom-error-message="'Please enter a valid URL in the format: www.abc.com'"
                @focus-out="websiteRegex"
                @at-input="validateName"
              >
              </hlx-input>
            </div>

            <div class="decision-name-wrapper">
              <hlx-multi-select
                :key="modalKeyAccount"
                :pre-value="newAccPayload.tags"
                :options="tagsList"
                prop-value="name"
                :multi-select="true"
                :placeholder="'Tags'"
                :label-animation="true"
                :clearable="true"
                :tooltipposition="'left'"
                @add-data="addTagList"
                @custom-change="receivedTags"
              ></hlx-multi-select>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="closeAddNewAccountModal"
          >Cancel</hlx-button
        >
        <hlx-button
          :disabled="!canAdd"
          class="primary sm"
          style="margin-left: 10px"
          @click="addNewAccount()"
          >Add</hlx-button
        >
      </template>
    </hlx-modal>
  </div>
  <hlx-drawer
    :show="show_right"
    :width="1000"
    position="right"
    :footer="false"
    :show-close-icon="true"
    @close="closeactivitylog"
  >
    <template #body>
      <div class="activitylog_drawer_componet">
        <activityLogComponent
          :key="activtykey"
          :activity-data="activityLogData"
          :module="'leftPanal'"
          :totalfromprop="activitytotal"
          @activityrowpage="activityrowpage1"
          @activitycurrentpage="activitycurrentpage1"
        ></activityLogComponent>
      </div>
    </template>
  </hlx-drawer>
  <!-- modal window for add contact -->
  <hlx-modal
  :key="refreshContactModal"
    :modal-active="showAddNewContactModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'570px !important'"
    :width="'800px !important'"
    :modal="true"
    @close="closeAddNewContactModal"
  >
    <template #header>
      <div>Add contact</div>
    </template>
    <template #content>
      <div
        class="modal-content"
        style="display: flex; gap: 5%"
      >
        <!--left side-->
        <div


          style="
            display: flex;
            flex-direction: column;
            /* justify-content: space-between; */
            width: 50%;
          "
        >
          <div style="display: flex; justify-content: space-between; gap: 10px">
            <!-- <span
              style="
                height: 93px;
                display: flex;
                width: 93px;
                align-items: center;
                justify-content: center;
                min-width: 93px;
                border: 1px solid #d8d8d8;
                border-radius: 5px;
                font-size: 70px;
                color: #d8d8d8;
              "
            >
              <i class="icon-buildings-alt-regular"></i>
            </span> -->
            <!-- {{refreshProfile}}
            {{newConPayload?.name}} -->
            <hlx-file-upload
            :key="refreshProfile"
              :file-type="'.jpg,.jpeg,.png'"
              :file-size-limit="'20mb'"
              :uploadtype="'Rectangle'"
              :style-background="backgrounStyle"
              :height="100"
              :width="100"
              :read-only="false"
              :custom-icon="icon"
              :type="'profile-upload'"
              :content="'Import'"
              :name-display="`${newConPayload.firstName} ${newConPayload.lastName}`"
              @native-data="profileUploadCon"
            />
            <span style="width: 200%">
              <div
                class="decision-name-wrapper"
                style="margin-bottom: 20px"
              >
                <!--first name-->
                <hlx-input
                  :key="componentkey"
                  v-model:value="newConPayload.firstName"
                  :pre-val="newConPayload.firstName"
                  :label-value="'First name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="customnameerror"
                  :custom-error="customnameerror"
                  :custom-error-message="'Please fill the field'"
                  @focus-out="focusOutName"
                  @at-input="validateName"
                ></hlx-input>
              </div>

              <!--Last name-->
              <div
                class="decision-name-wrapper"
                style="margin-bottom: 20px"
              >
                <hlx-input
                  :key="componentkey"
                  v-model:value="newConPayload.lastName"
                  :pre-val="newConPayload.lastName"
                  :label-value="'Last name'"
                  :label-animation="true"
                  :required="false"
                  :display-error="customnameerror"
                  :custom-error="customnameerror"
                  :custom-error-message="'Please fill the field'"
                  @focus-out="focusOutName"
                  @at-input="validateName"
                ></hlx-input>
              </div>
            </span>
          </div>

          <!--Address-->
          <addressComponent
            :key="addressKey"
            @address-data="receivedAddressData1"
          ></addressComponent>

          <!--status-->
          <div
            class="decision-name-wrapper"
            style="margin-top: 20px"
          >
            <!-- <hlx-select
              v-model="newConPayload.status"
              :pre-value="newConPayload.status"
              :options="statusList"
              prop-value="name"
              :inline-search="true"
              :search-image-tag="false"
              :placeholder-value="'Status'"
              :label-animation="true"
              :clearable="true"
              @search-value="newValFromDropDown($event, 'status')"
            ></hlx-select>  -->

            <div style="width: 100%">
              <hlx-status
                :type="'select-status'"
                :status-option="statusContact"
                :prevalue="newConPayload.status"
                @emit-select="emitStatusCon"
              >
              </hlx-status>
            </div>
          </div>
        </div>

        <!--right side-->
        <div
          style="
            display: flex;
            flex-direction: column;
            /* gap: 20px; */
            /* justify-content: space-between; */
            width: 50%;
          "
        >
          <!--organistion-->
          <div
            class="decision-name-wrapper"
            style="margin-bottom: 20px"
          >
          <!-- {{accountPreviewInContact}}
          {{selectedHierarchyAccount}} -->
            <hlx-select
              :key="addressKey"
              v-model:value="selectedHierarchyAccount"
              :pre-value="accountPreviewInContact"
              :options="organizationList"
              :prop-value="'name'"
              :label="'name'"
              :required="true"
              :inline-search="true"
              :placeholder-value="'Choose an organisation'"
              :label-animation="true"
              :clearable="true"
              :footer="false"
              @selected-value="selectedHierarchyAccountDetails"
            ></hlx-select>
          </div>

          <!--Role-->
          <div
            class="decision-name-wrapper"
            style="margin-bottom: 20px"
          >
            <!-- {{newConPayload.role}} -->
            <hlx-select
              :key="addressKey"
              v-model:value="newConPayload.aristocraticTitle"
              :pre-value="newConPayload.aristocraticTitle"
              :options="designationList"
              :required="true"
              :prop-value="'name'"
              :label="'name'"
              :placeholder-value="'Designation'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>

          <!---prefered medium-->
          <div
            class="decision-name-wrapper"
            style="margin-bottom: 20px"
          >
            <hlx-select
              :key="componentkey"
              v-model:value="newConPayload.preferred"
              :pre-value="newConPayload.preferred"
              :options="preferredMediumList"
              prop-value="value"
              :placeholder-value="'Preferred contact medium'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>

          <!--email-->
          <div
            class="decision-name-wrapper"
            style="margin-bottom: 2px"
          >
            <hlx-input
              :key="componentkey"
              v-model:value="
                newConPayload.contactMedium[0].characteristic.emailAddress
              "
              :pre-val="
                newConPayload.contactMedium[0].characteristic.emailAddress
              "
              type="email"
              :label-value="'Email id'"
              :required="true"
              :label-animation="true"
              :display-error="true"
              :custom-error="isExistingEmail"
              :custom-error-message="isExistingEmailErrorMessage"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>

          <!--Phone number-->
          <div class="decision-name-wrapper">
            <hlx-input
              :key="componentkey"
              v-model:value="
                newConPayload.contactMedium[1].characteristic.phoneNumber
              "
              :pre-val="
                newConPayload.contactMedium[1].characteristic.phoneNumber.value
              "
              type="phonenumber"
              :country-code="
                newConPayload.contactMedium[1].characteristic.phoneNumber
                  .country
              "
              :label-value="'Phone no.'"
              :label-animation="true"
              :display-error="true"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>

          <!--Linked id-->
          <div
            class="decision-name-wrapper"
            style="margin-bottom: 20px"
          >
            <hlx-input
              :key="componentkey"
              v-model:value="newConPayload.externalReference[0].name"
              :pre-val="newConPayload.externalReference[0].name"
              :label-value="'Linkedin id'"
              :label-animation="true"
              :required="false"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>

          <!--Tags-->
          <div class="decision-name-wrapper">
            <hlx-multi-select
              :key="componentkey"
              :pre-value="newConPayload.tags"
              :options="tagsList"
              prop-value="name"
              :multi-select="true"
              :placeholder="'Tags'"
              :label-animation="true"
              :clearable="true"
              :tooltipposition="'left'"
              @add-data="addTagList"
              @custom-change="receivedTags"
            ></hlx-multi-select>
          </div>

          <!--Notes-->
          <!-- <div class="decision-name-wrapper">
          <hlx-input
          v-model:value="newConPayload.notes"
          :label-animation="true"
          label-value="Notes"
          type="textarea"
          :clearable="true"
        />
        </div> -->
        </div>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="closeAddNewContactModal"
        >Cancel</hlx-button
      >
      <hlx-button
        :disabled="!canAddContact"
        class="primary sm"
        style="margin-left: 10px"
        @click="addNewContact"
        >Add</hlx-button
      >
    </template>
  </hlx-modal>

  <!-- top static box -->
  <div v-if="!show_offering && !show_configuration" class="staticHeading">
    <!-- div 1 -->
    <span class="leftContainer">
      <span class="divMinus1" @click="openDiscardModal"
        ><i class="icon-arrow-left-regular"></i
      ></span>
      <span class="div1"><i class="icon-papers-regular"></i></span>

      <span class="div2">
        <span class="span1">{{ invoiceTemplatePayload.header.title.value }}</span>
        <span v-if="quoteData && quoteData?.state" class="span2">
          <span class="spanNew1"></span>
          <span class="spanNew2"></span>
          <span class="spanNew3"></span>
          <!-- {{ this.quoteData?.state }} -->
          <!-- {{ statusVmodel(this.quoteData?.state) }} -->
            <!-- {{showStatus}} -->
          <hlx-status
            :type="'select-status'"
            :status-option="statusList1"
            :prevalue="reverseStatusMapping(showStatus)"
            :label-animation="false"
            :readonly="statusBool"
            @emit-select="emitStatus"
          ></hlx-status
        ></span>
        <span v-else class="span2">
          <span class="spanNew1"></span>
          <span class="spanNew2"></span>
          <span class="spanNew3"></span>
          <!-- {{ this.quoteData?.state }} -->
          <!-- {{ statusVmodel(this.quoteData?.state) }} -->
          <hlx-status
            :type="'select-status'"
            :status-option="statusList1"
            :prevalue="draftStatus"
            :label-animation="false"
            :readonly="statusBool"
            @emit-select="emitStatus"
          ></hlx-status
        ></span>
      </span>

      <span v-if="quoteData && quoteData?.lastUpdate" class="div3">{{
        formatUpdateTime(quoteData.lastUpdate)
      }}</span>
      <span v-else class="div4">{{ "" }}</span>
    </span>

    <!-- div 2 -->
    <span class="rightContainer" style="width:196px;">
      <!-- <span class="div1">{{ "Version no : " + quoteVersion }}</span> -->

<!-- {{enableButton}} -->
      <span
class="divMinus1"   
      :style="{ color: !enableButton ? '#d8d8d8' : '#54bd95' }" 
      data-tooltip="Clone" 
      data-tooltip-location="left" 
@click="enableButton ? cloneQuote() : null" 
><i class="icon-copy-alt-regular"></i></span>

      <!-- <span
class="divMinus3"
      data-tooltip="Convert to cart" 
data-tooltip-location="left"
></span> -->


      <!-- <span class="div2"
        ></span
      > -->

      <span class="div3"
        ><hlx-button
class="primary sm"
        
        :disabled="!(isValidDraftQuote && quoteDataLoaded)"
        @click="saveQuote('save')"
        >{{
          "Save"
        }}</hlx-button></span>


<!-- :disabled="!(quoteDataLoaded && isValidQuoteTemplate)" -->
<!-- {{isClickable}} -->
      <span
class="divMinus2" 
      :style="{ color: isClickable ? '#54bd95' :'#d8d8d8' }" 
      data-tooltip="Preview"
data-tooltip-location="left"
@click="isClickable ? previewQuote() : null"
><i class="icon-eye-filled"></i></span>
    </span>
  </div>
  <!-- top static end -->

  <!-- Side Panel -->

  <div
    v-if="!show_offering && !show_configuration && 0" by asif
    :style="{
      zIndex: '999',
      backgroundColor: 'white',
      borderLeft: show_side_panel ? '1px solid #D8D8D8' : 'none',
      width: show_side_panel ? '114px' : '',
    }"
    class="default-quote-right-panel"
  >
    <div
      :style="{
        zIndex: 999,
        right: show_side_panel ? '114px' : '0px',
        position: 'fixed',
        backgroundColor: '#54BD95',
        borderRadius: '10%',
      }"
      @click="showSidePanedFunc"
    >
      <i
        v-if="!show_side_panel"
        style="color: white"
        class="icon-angle-left-small-filled"
      ></i>
      <i
        v-if="show_side_panel"
        style="color: white"
        class="icon-angle-right-small-filled"
      ></i>
    </div>
    <div
      v-if="show_side_panel"
      class="default-"
      style="
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: #727272;
        align-content: center;
        align-items: center;
        gap: 1rem;
      "
    >
      <div
        class=""
        :style="{
          display: 'flex',
          flexDirection: 'column',
          wordWrap: 'break-word',
          alignItems: 'center',
          gap: '6px',
          padding: '12px',
          cursor:
            quoteDataLoaded && isValidDraftQuote && isClickable
              ? 'pointer'
              : 'not-allowed',
        }"
        @click="
          quoteDataLoaded && isValidDraftQuote && isClickable
            ? showVersionHistory()
            : null
        "
      >
        <i
          class="icon-stopwatch-alt-regular"
          :style="{
            fontSize: '28px',
            color: quoteDataLoaded && isValidDraftQuote ? '#727272' : '#d4d4d4',
            cursor: isValidDraftQuote ? 'pointer' : 'not-allowed',
          }"
        ></i>
        <p
          :style="{
            color: quoteDataLoaded && isValidDraftQuote ? '#727272' : '#d4d4d4',
            cursor: isValidDraftQuote ? 'pointer' : 'not-allowed',
          }"
        >
          Version history
        </p>
      </div>
      <div
        class=""
        style="
          display: flex;
          flex-direction: column;
          word-wrap: break-word;
          align-items: center;
          cursor: pointer;
          gap: 6px;
          padding: 12px;
        "
        @click="openDrawerForRecipients"
      >
        <i
          class="icon-user-regular"
          style="font-size: 28px; color: #727272"
        ></i>
        <p>Recipient</p>
      </div>

      <div
        class=""
        style="
          display: flex;
          flex-direction: column;
          word-wrap: break-word;
          align-items: center;
          cursor: pointer;
          gap: 6px;
          padding: 12px;
        "
        @click="activityLogDrawer()"
      >
        <i
          class="icon-time-forward-regular"
          style="font-size: 28px; color: #727272"
        ></i>
        <p>Activity log</p>
        <!-- {{$route.query.quote_id}} -->
      </div>
      <div
        class=""
        style="
          display: flex;
          flex-direction: column;
          word-wrap: break-word;
          align-items: center;
          cursor: pointer;
          padding: 12px;
          gap: 6px;
        "
        @click="showWorkflowFunc"
      >
        <i
          class="icon-settings-regular"
          style="font-size: 28px; color: #727272"
        ></i>
        <p>Work flow</p>
      </div>
    </div>
  </div>
  <section v-if="showWorkFlow">
    <hlx-drawer
      :show="showWorkFlow"
      position="right"
      :show-close-icon="true"
      :width="400"
      :footer="false"
      :title="'Work flow'"
      @close="showWorkFlow = !showWorkFlow"
    >
      <template #body>
        <section
          style="
            border-bottom: 1px solid #d4d4d4;
            padding-left: 22px;
            padding-bottom: 16px;
            padding-top: 6px;
          "
        >
          <div class="header-area-contact-drawer">
            <b>Work flow</b>
          </div>
        </section>
        <section style="margin-top: 8px" class="body-area-pricing-drawer">
          <quoteWorkflowSidebarComponent></quoteWorkflowSidebarComponent>
        </section>
      </template>
    </hlx-drawer>
  </section>

  <main
    class="quote-page-container"
    :style="{
      overflow: show_side_panel ? 'visible' : 'auto',
    }"
  >
    <!-- Header -->
    <section
      v-if="!show_offering && !show_configuration"
      class="quote-page"
      style="padding: 8rem 2rem 6rem 2rem"
    >
      <!-- Header -->
      <section class="quote-header">
        <!-- Title -->
        <span>
          <h1
            v-if="invoiceTemplatePayload.header.title.readOnly"
            class="quote-title"
            :class="{
              '--readable': invoiceTemplatePayload.header.title.readOnly,
            }"
            style="text-align: center"
            @click.stop="
              invoiceTemplatePayload.header.title.readOnly =
                !invoiceTemplatePayload.header.title.readOnly
            "
          >
            {{ invoiceTemplatePayload.header.title.value }}
          </h1>
          <div
            v-else-if="!invoiceTemplatePayload.header.title.readOnly"
            :style="{ width: dynamicWidthHead }"
          >
            <hlx-input
              v-model:value="invoiceTemplatePayload.header.title.value"
              :pre-val="invoiceTemplatePayload.header.title.value"
              :single-border="true"
              :label-animation="false"
              :character-limit="150"
              type="text"
              :focus="!invoiceTemplatePayload.header.title.readOnly"
              :label-value="invoiceTemplatePayload.header.title.value"
              :clearable="false"
              iconposition="right"
              @focus-out="handleInputFocusOut('quote-title')"
            />
          </div>
          <!-- Quote version -->
          <!-- <hlx-label
            v-if="quoteVersion"
            class="info quote-version-label"
            style="margin-left: auto"
            >{{ 'Version ' + quoteVersion }}</hlx-label
          > -->
        </span>
        <!-- Sub title -->
        <h3
          v-if="
            invoiceTemplatePayload.header.isQuoteSubtitle &&
            invoiceTemplatePayload.header.subtitle.readOnly
          "
          class="quote-sub-title"
          :class="{
            '--readable': invoiceTemplatePayload.header.subtitle.readOnly,
          }"
          style="
            cursor: pointer;
            border-bottom: 1px dashed #516173;
            text-align: center;
            font-size: 13px;
            font-weight: normal;
          "
          @click.stop="
            invoiceTemplatePayload.header.subtitle.readOnly =
              !invoiceTemplatePayload.header.subtitle.readOnly
          "
        >
          {{ invoiceTemplatePayload.header.subtitle.value }}
        </h3>
        <div
          v-else-if="
            invoiceTemplatePayload.header.subtitle.value?.trim() != '' ||
            invoiceTemplatePayload.header.isQuoteSubtitle
          "
          :style="{ width: dynamicWidthSub }"
        >
          <hlx-input
            v-model:value="invoiceTemplatePayload.header.subtitle.value"
            :focus="
              !invoiceTemplatePayload.header.subtitle.readOnly &&
              invoiceTemplatePayload.header.isQuoteSubtitle
            "
            :pre-val="invoiceTemplatePayload.header.subtitle.value"
            :label-animation="false"
            type="text"
            :character-limit="200"
            :single-border="true"
            :label-value="invoiceTemplatePayload.header.subtitle.value"
            :clearable="false"
            iconposition="right"
            @focus-out="handleInputFocusOut('quote-subtitle')"
          />
        </div>
        <div
          v-if="!invoiceTemplatePayload.header.isQuoteSubtitle"
          class="quote-add-sub-title-button"
          @click="enableSubtitle"
        >
          <i class="icon-plus-square-regular"></i>
          <span class="quote-add-sub-title-label">Add sub title</span>
        </div>
      </section>

      <!-- Basic info -->
      <div class="custom-accordion-wrapper">
        <div
          class="custom-accordion-header"
          @click="isBasicSection = !isBasicSection"
        >
          <span class="--title">Basic information</span>
          <i v-if="!isBasicSection" class="icon-angle-right-regular"></i>
          <i v-if="isBasicSection" class="icon-angle-down-regular"></i>
        </div>
        <section
          v-if="isBasicSection"
          class="quote-basic-info-header --accordion-body-wrapper"
        >
          <div class="basic-info">
            <!-- Quote number -->
            <div class="quote-number-panel --label-value-2">
              <span class="label-wrapper --Q-label">
                <p
                  v-if="
                    invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel
                      .readOnly
                  "
                  class="quote-number-label"
                  :class="{
                    '--readable':
                      invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel
                        .readOnly,
                  }"
                  @click="
                    invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel.readOnly =
                      !invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel
                        .readOnly
                  "
                >
                  {{
                    invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel.value
                  }}
                </p>
                <hlx-input
                  v-else-if="
                    !invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel
                      .readOnly
                  "
                  v-model:value="
                    invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel.value
                  "
                  :focus="
                    !invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel
                      .readOnly
                  "
                  :pre-val="
                    invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel.value
                  "
                  :single-border="true"
                  :label-animation="false"
                  type="text"
                  :label-value="
                    invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel.value
                  "
                  :clearable="false"
                  iconposition="right"
                  @focus-out="
                    invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel.readOnly =
                      !invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel
                        .readOnly
                  "
                />
                <span
                  v-if="
                    invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel
                      .readOnly
                  "
                  class="--required"
                >
                </span>
              </span>
              <span class="quote-number"
                ><hlx-input
                  v-model:value="
                    invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value
                  "
                  :pre-val="
                    invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value
                  "
                  :label-animation="false"
                  type="text"
                  :disabled="true"
                  :readonly="true"
                  :single-border="true"
                  :label-value="
                    invoiceTemplatePayload.quoteBasicInfo.quoteNumber.label
                  "
                  :clearable="false"
              /></span>
            </div>
            <!-- Quote date -->
            <div class="quote-date-panel --label-value-2">
              <span class="label-wrapper --Q-label">
                <p
                  v-if="
                    invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel.readOnly
                  "
                  class="quote-number-label"
                  :class="{
                    '--readable':
                      invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel
                        .readOnly,
                  }"
                  @click="
                    invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel.readOnly =
                      !invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel
                        .readOnly
                  "
                >
                  {{ invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel.value }}
                </p>
                <hlx-input
                  v-else-if="
                    !invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel.readOnly
                  "
                  v-model:value="
                    invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel.value
                  "
                  :focus="
                    !invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel.readOnly
                  "
                  :pre-val="
                    invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel.value
                  "
                  :single-border="true"
                  :label-animation="false"
                  type="text"
                  :label-value="
                    invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel.value
                  "
                  :clearable="false"
                  iconposition="right"
                  @focus-out="
                    invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel.readOnly =
                      !invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel
                        .readOnly
                  "
                />
                <span
                  v-if="
                    invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel.readOnly
                  "
                  class="--required"
                >
                </span>
              </span>
              <span class="quote-date">
                <hlx-input
                  v-model:value="
                    invoiceTemplatePayload.quoteBasicInfo.quoteDate.value
                  "
                  :pre-val="invoiceTemplatePayload.quoteBasicInfo.quoteDate.value"
                  :label-animation="false"
                  :type="'date'"
                  :read-only="true"
                  :single-border="true"
                  :clearable="false"
                  icon="calendar-regular"
                  icon-position="right"
                  @at-input="handleQuoteDateChange"
              /></span>
            </div>
            <!-- Quote valid date -->
            <!-- Hide and show validate date button  -->
            <!-- <div
              v-if="!invoiceTemplatePayload.quoteBasicInfo.isQuoteValidTillDate"
              class="quote-add-valid-date-button"
              @click="
                invoiceTemplatePayload.quoteBasicInfo.isQuoteValidTillDate =
                  !invoiceTemplatePayload.quoteBasicInfo.isQuoteValidTillDate
              "
            >
              <i class="icon-plus-square-regular"></i>
              <span class="quote-add-valid-date-label"
                >Add Next bill date</span
              >
            </div> -->

            <!-- v-if="invoiceTemplatePayload.quoteBasicInfo.isQuoteValidTillDate" -->
            <div class="quote-date-panel --label-value-2">
              <span class="label-wrapper --Q-label">
                <p
                  v-if="
                    invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDateLabel
                      .readOnly
                  "
                  class="quote-number-label"
                  :class="{
                    '--readable':
                      invoiceTemplatePayload.quoteBasicInfo
                        .quoteValidTillDateLabel.readOnly,
                  }"
                  @click="
                    invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDateLabel.readOnly =
                      !invoiceTemplatePayload.quoteBasicInfo
                        .quoteValidTillDateLabel.readOnly
                  "
                >
                  {{
                    invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDateLabel
                      .value
                  }}
                </p>
                <hlx-input
                  v-else-if="
                    !invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDateLabel
                      .readOnly
                  "
                  v-model:value="
                    invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDateLabel
                      .value
                  "
                  :focus="
                    !invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDateLabel
                      .readOnly
                  "
                  :single-border="true"
                  :pre-val="
                    invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDateLabel
                      .value
                  "
                  :label-animation="false"
                  type="text"
                  :label-value="
                    invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDateLabel
                      .value
                  "
                  :clearable="false"
                  iconposition="right"
                  @focus-out="
                    invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDateLabel.readOnly =
                      !invoiceTemplatePayload.quoteBasicInfo
                        .quoteValidTillDateLabel.readOnly
                  "
                />
                <span
                  v-if="
                    invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDateLabel
                      .readOnly
                  "
                  class="--required"
                >
                </span>
              </span>
              <span class="quote-date"
                >
                <!-- {{"next bill date"}} -->
                <hlx-input
                  :key="dateref"
                  v-model:value="
                    invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value
                  "
                  :pre-val="
                    invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value
                  "
                  :single-border="true"
                  :label-animation="false"
                  :start-year="fullYear"
                  :holiday-list="holidays"
                  
              :disable-weekend="true"
                  type="date"
                  :clearable="false"
                  icon="calendar-regular"
                  icon-position="right"
              /></span>
              <i
                class="icon-settings-regular"
                @click.stop="
                  invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting.enabled =
                    !invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting
                      .enabled
                "
              ></i>
              <!-- <i
                class="icon-times-regular"
                @click.stop="
                  invoiceTemplatePayload.quoteBasicInfo.isQuoteValidTillDate =
                    !invoiceTemplatePayload.quoteBasicInfo.isQuoteValidTillDate
                "
              ></i> -->
            </div>
            <div
              v-if="
                invoiceTemplatePayload.quoteBasicInfo.additionalFields.length > 0
              "
              class="--additional-fields"
            >
              <div
                v-for="(field, index) in invoiceTemplatePayload.quoteBasicInfo
                  .additionalFields"
                :key="field"
                class="additional-fields-panel --label-value-2"
              >
                <span class="label-wrapper --Q-label">
                  <!-- <p
                                  class="additional-fields-label"
                                      >
                                      {{
                      field.label }}</p> -->
                  <hlx-input
                    v-model:value="field.label"
                    :pre-val="field.label"
                    :label-animation="false"
                    :single-border="true"
                    type="text"
                    label-value="Field name"
                    :clearable="false"
                  />
                </span>
                <span class="additional-fields">
                  <!-- {{ field.value
                                  }} -->
                  <hlx-input
                    v-model:value="field.value"
                    :pre-val="field.value"
                    :label-animation="false"
                    type="text"
                    :single-border="true"
                    label-value="value"
                    :clearable="false"
                  />
                </span>
                <i
                  class="icon-times-regular"
                  @click.stop="removeAdditionalField(index)"
                ></i>
              </div>
            </div>
            <div
              class="quote-add-valid-date-button"
              @click="addAdditionalField"
            >
              <i class="icon-plus-square-regular"></i>
              <span class="quote-add-valid-date-label">Add more fields</span>
            </div>
            <div class="quote-additional-fields-panel --label-value-2">
              <span class="quote-additional-fields-label"></span
              ><span class="quote-additional-fields"></span>
            </div>
            <div class="quote-add-more-fields-button">
              <span class="quote-add-more-fields-label"></span
              ><span class="quote-add-more-fields"></span>
            </div>
          </div>
          <div class="quote-logo-container">
            <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png'"
              :file-size-limit="'20mb'"
              :uploadtype="'Rectangle'"
              :style-background="backgrounStyleupload"
              :height="100"
              :width="100"
              :read-only="false"
              :custom-icon="icon"
              :type="'profile-upload'"
              :name-display="
                invoiceTemplatePayload.sender.businessDetails.businessName.value
              "
              :content="'Import'"
              :profile-pic-preview="profilePicturePrevalue"
              @native-data="handleProfilePictureUpload"
            />
          </div>
        </section>
      </div>

      <!-- Quote sender and recipient -->
      <section class="custom-accordion-wrapper">
        <div
          class="custom-accordion-header"
          @click="isSenderAndClientSection = !isSenderAndClientSection"
        >
          <span class="--title">Sender and client information</span>
          <i
            v-if="!isSenderAndClientSection"
            class="icon-angle-right-regular"
          ></i>
          <i
            v-if="isSenderAndClientSection"
            class="icon-angle-down-regular"
          ></i>
        </div>
        <section
          v-if="isSenderAndClientSection"
          class="custom-tile-2way quote-sender-and-recipient-panel --accordion-body-wrapper"
        >
          <!-- Sender -->
          <div class="quote-sender">
            <div class="sender-title-area">
              <span class="label-wrapper">
                <hlx-input
                  v-if="!invoiceTemplatePayload.sender.title.readOnly"
                  v-model:value="invoiceTemplatePayload.sender.title.value"
                  :focus="!invoiceTemplatePayload.sender.title.readOnly"
                  :pre-val="invoiceTemplatePayload.sender.title.value"
                  :label-animation="false"
                  type="text"
                  :label-value="invoiceTemplatePayload.sender.title.value"
                  :clearable="false"
                  iconposition="right"
                  :single-border="true"
                  @focus-out="handleInputFocusOut('sender-title')"
                />
                <p
                  v-if="invoiceTemplatePayload.sender.title.readOnly"
                  class="sender-title-label"
                  :class="{
                    '--readable': invoiceTemplatePayload.sender.title.readOnly,
                  }"
                  @click.stop="
                    invoiceTemplatePayload.sender.title.readOnly =
                      !invoiceTemplatePayload.sender.title.readOnly
                  "
                >
                  {{ invoiceTemplatePayload.sender.title.value }}
                </p>
                <span class="--info-label"
                  >(&nbsp;Service provider details&nbsp;)</span
                >
              </span>
            </div>
            <div class="sender-picker">
              <hlx-select
                v-model="senderSearchKey"
                :pre-value="senderSearchKey"
                :required="true"
                :options="senderCompanyList"
                :disabled="true"
                :placeholder-value="'Select your details'"
                :single-border="true"
                :label-animation="false"
                :label="'tradingName'"
                :prop-value="'tradingName'"
                :clearable="false"
                :inline-search="true"
                :custom-search="true"
                :error="isValidSenderCompany == false"
                :custom-error="senderCompanyValidationMessage"
                :footer-icon="'icon-plus-circle-regular'"
                :footer-content="'Add new client'"
                :footer="true"
                @key-press="searchSenderList"
                @footer-click="callFooterEvent"
                @custom-change="returnOptions"
                @selected-value="handleSenderChanges"
              >
              </hlx-select>
            </div>
            <div class="business-details">
              <span class="form-field-wrapper --mb-5">
                <sapn class="--form-title">Business details</sapn>
                <i
                  v-if="noNeed"
                  class="icon-edit-regular business-title-editor"
                  @click="editCompany = !editCompany"
                ></i>
              </span>
              <span class="form-field-wrapper--child">
                <span class="--form-label">Business name</span>
                <span class="--form-value">{{
                  invoiceTemplatePayload.sender.businessDetails.businessName.value
                }}</span>
              </span>
              <span class="form-field-wrapper--child">
                <span class="--form-label">Address</span>
                <span class="--form-value">{{
                  invoiceTemplatePayload.sender.businessDetails.address.value
                    .characteristic.description
                }}</span>
              </span>
              <span class="form-field-wrapper--child">
                <span class="--form-label">Email</span>
                <span class="--form-value">{{
                  invoiceTemplatePayload.sender.businessDetails.email.value
                }}</span>
              </span>
              <span class="form-field-wrapper--child">
                <span class="--form-label">Phone</span>
                <span class="--form-value">{{
                  invoiceTemplatePayload.sender.businessDetails.phone.value
                    ?.country?.dial_code +
                  " " +
                  invoiceTemplatePayload.sender.businessDetails.phone.value.value
                }}</span>
              </span>
            </div>
          </div>
          <!-- Recipient -->
          <div class="quote-recipient">
            <div class="sender-title-area">
              <span class="label-wrapper">
                <hlx-input
                  v-if="!invoiceTemplatePayload.recipient.title.readOnly"
                  v-model:value="invoiceTemplatePayload.recipient.title.value"
                  :focus="!invoiceTemplatePayload.recipient.title.readOnly"
                  :pre-val="invoiceTemplatePayload.recipient.title.value"
                  :label-animation="false"
                  type="text"
                  :single-border="true"
                  :label-value="invoiceTemplatePayload.recipient.title.value"
                  :clearable="false"
                  iconposition="right"
                  @focus-out="handleInputFocusOut('recipient-title')"
                />
                <p
                  v-if="invoiceTemplatePayload.recipient.title.readOnly"
                  class="sender-title-label"
                  :class="{
                    '--readable': invoiceTemplatePayload.recipient.title.readOnly,
                  }"
                  @click.stop="
                    invoiceTemplatePayload.recipient.title.readOnly =
                      !invoiceTemplatePayload.recipient.title.readOnly
                  "
                >
                  {{ invoiceTemplatePayload.recipient.title.value }}
                </p>
                <span class="--info-label">(&nbsp;Client details &nbsp;)</span>
              </span>
            </div>
            <div class="sender-picker">
              <!-- v-model="
                  invoiceTemplatePayload.recipient.businessDetails.businessName
                    .value
                " -->
              <!-- v-model:value="
                  recipientSearchPlaceholder
                "
                v-model="
                  recipientSearchPlaceHolder
                " -->
              <hlx-select
                v-model:value="
                  invoiceTemplatePayload.recipient.businessDetails.businessName
                    .value
                "
                :pre-value="
                  invoiceTemplatePayload?.recipient?.businessDetails?.businessName
                    ?.value
                "
                :required="true"
                :options="recipientLists"
                :placeholder-value="'Select your details'"
                :label-animation="false"
                :label="'customName'"
                :prop-value="'tradingName'"
                :clearable="true"
                :inline-search="true"
                :custom-search="true"
                :error="isValidRecipientCompany == false"
                :custom-error="recipientCompanyValidationMessage"
                :footer-icon="'icon-plus-circle-regular'"
                :single-border="true"
                :footer-content="'Add new client'"
                :footer="true"
                @footer-click="addNewAccountModal()"
                @key-press="searchRecipientList"
                @selected-value="handleRecipientChanges"
                @search-value="test1"
              >
                <!-- @custom-change="handleRecipientChanges"  -->
              </hlx-select>
            </div>
            <div
              v-if="
                invoiceTemplatePayload.recipient.businessDetails.businessName
                  .value != ''
              "
              class="business-details"
            >
              <span class="form-field-wrapper --mb-5">
                <sapn class="--form-title">Business details</sapn>
                <i
                  v-if="inDoubt"
                  class="icon-edit-regular business-title-editor"
                  @click="editCompany = !editCompany"
                ></i>
              </span>
              <span class="form-field-wrapper--child">
                <span class="--form-label">Business name</span>
                <span
v-if="invoiceTemplatePayload.recipient.businessDetails.businessName.value ===
                accountPreviewInContact" class="--form-value">{{
                  accountPreviewInContact
                }}</span>
                <span v-else class="--form-value">{{
                  accountPreviewInContact
                }}</span>
              </span>
              <span class="form-field-wrapper--child">
                <span class="--form-label">Address</span>
                <span class="--form-value">{{
                  invoiceTemplatePayload.recipient.businessDetails.address.value
                    .characteristic.description
                }}</span>
              </span>
              <span class="form-field-wrapper--child">
                <span class="--form-label">Email</span>
                <span class="--form-value">{{
                  invoiceTemplatePayload.recipient.businessDetails.email.value
                }}</span>
              </span>
              <!-- Recipient phone -->
              <span class="form-field-wrapper--child">
                <span class="--form-label">Phone</span>
                <span class="--form-value">{{
                  invoiceTemplatePayload.recipient.businessDetails.phone.value
                    ?.country?.dial_code +
                  " " +
                  invoiceTemplatePayload.recipient.businessDetails.phone.value
                    .value
                }}</span>
              </span>
            </div>
            <div v-else class="custom-tile-card">
              <p class="no-data-placeholder-container">
                <span class="--form-label">
                  Select a client/business from list
                </span>
                <span class="--form-label"> Or </span>
                <hlx-button class="primary sm" @click="addNewAccountModal()"
                  ><i class="plus-circle-regular add-new-client-button"></i>Add
                  new client</hlx-button
                >
              </p>
            </div>
          </div>
        </section>

        <!-- Sender and Buyer contact -->
        <section
          v-if="isSenderAndClientSection"
          class="quote-sender-and-recipient-contact-panel custom-tile-2way --accordion-body-wrapper"
        >
          <div class="sender-contact custom-tile">
            <div class="sender-title-area">
              <span class="label-wrapper">
                <hlx-input
                  v-if="!invoiceTemplatePayload.senderContact.title.readOnly"
                  v-model:value="invoiceTemplatePayload.senderContact.title.value"
                  :focus="!invoiceTemplatePayload.senderContact.title.readOnly"
                  :pre-val="invoiceTemplatePayload.senderContact.title.value"
                  :label-animation="false"
                  type="text"
                  :single-border="true"
                  :label-value="invoiceTemplatePayload.senderContact.title.value"
                  :clearable="false"
                  iconposition="right"
                  @focus-out="handleInputFocusOut('sender-contact-title')"
                />
                <p
                  v-if="invoiceTemplatePayload.senderContact.title.readOnly"
                  class="sender-title-label"
                  :class="{
                    '--readable':
                      invoiceTemplatePayload.senderContact.title.readOnly,
                  }"
                  @click.stop="
                    invoiceTemplatePayload.senderContact.title.readOnly =
                      !invoiceTemplatePayload.senderContact.title.readOnly
                  "
                >
                  {{ invoiceTemplatePayload.senderContact.title.value }}
                </p>
                <span class="--info-label"
                  >(&nbsp;Service provider contact details&nbsp;)</span
                >
              </span>
            </div>

            <div class="sender-picker">
              <!-- v-model:value="invoiceTemplatePayload.senderContact.contactDetails.name.value"
                 -->
              <hlx-select
              v-model:value="invoiceTemplatePayload.senderContact.contactDetails.name.value"
                :pre-value="
                  invoiceTemplatePayload.senderContact.contactDetails?.name.value
                "
                :required="true"
                :options="senderCompanyContacts1"
                :placeholder-value="'Select your details'"
                :label-animation="false"
                :single-border="true"
                :clearable="true"
                :inline-search="true"
                :custom-search="true"
                :error="isValidSenderCompanyContact == false"
                :custom-error="senderCompanyContactValidationMessage"
                :footer-icon="'icon-plus-circle-regular'"
                :footer-content="'Add new contact'"
                :footer="false"
                :label="'name'"
                :prop-value="'name'"
                @key-press="searchSenderContact"
                @footer-click="callFooterEvent"
                @custom-change="returnOptions"
                @selected-value="handleSelectedSenderContacts"
              >
              </hlx-select>
            </div>

            <div
              v-if="
                invoiceTemplatePayload.senderContact.contactDetails?.name.value !=
                ''
              "
              class="business-details"
            >
              <span class="form-field-wrapper --mb-5">
                <sapn class="--form-title">Contact details</sapn>
                <i
                  v-if="inDoubt"
                  class="icon-edit-regular business-title-editor"
                  @click="editCompany = !editCompany"
                ></i>
              </span>
              <span class="form-field-wrapper">
                <span class="--form-label">Name</span>
                <span class="--form-value">{{
                  invoiceTemplatePayload.senderContact.contactDetails?.name.value
                }}</span>
              </span>

              <span class="form-field-wrapper">
                <span class="--form-label">Email</span>
                <span class="--form-value">{{
                  invoiceTemplatePayload.senderContact.contactDetails.email.value
                }}</span>
              </span>
              <span class="form-field-wrapper">
                <span class="--form-label">Phone</span>
                <span class="--form-value">{{
                  invoiceTemplatePayload.senderContact.contactDetails.phone.value
                    ?.country?.dial_code +
                  " " +
                  invoiceTemplatePayload.senderContact.contactDetails.phone.value
                    ?.value
                }}</span>
              </span>
            </div>
            <div v-else class="business-details">
              <p class="no-data-placeholder-container">
                <span class="--form-label">Select your contact</span>
                <span class="--form-label">or</span>
                <hlx-button class="sm primary">Add new contact</hlx-button>
              </p>
            </div>
          </div>

          <div class="recipient-contact custom-tile">
            <div class="sender-title-area">
              <span class="label-wrapper">
                <hlx-input
                  v-if="!invoiceTemplatePayload.recipientContact.title.readOnly"
                  v-model:value="
                    invoiceTemplatePayload.recipientContact.title.value
                  "
                  :focus="!invoiceTemplatePayload.recipientContact.title.readOnly"
                  :pre-val="invoiceTemplatePayload.recipientContact.title.value"
                  :label-animation="false"
                  type="text"
                  :label-value="
                    invoiceTemplatePayload.recipientContact.title.value
                  "
                  :clearable="false"
                  iconposition="right"
                  :single-border="true"
                  @focus-out="handleInputFocusOut('recipient-contact-title')"
                />
                <p
                  v-if="invoiceTemplatePayload.recipientContact.title.readOnly"
                  class="sender-title-label"
                  :class="{
                    '--readable':
                      invoiceTemplatePayload.recipientContact.title.readOnly,
                  }"
                  @click.stop="
                    invoiceTemplatePayload.recipientContact.title.readOnly =
                      !invoiceTemplatePayload.recipientContact.title.readOnly
                  "
                >
                  {{ invoiceTemplatePayload.recipientContact.title.value }}
                </p>
                <span class="--info-label"
                  >(&nbsp;Client contact details&nbsp;)</span
                >
              </span>
            </div>

            <div class="sender-picker">
              <!-- v-model:value=" -->
              <!-- {{    invoiceTemplatePayload.recipientContact.contactDetails.name.value}} -->
              <!-- " -->

              <hlx-select
                :key="addContactKey"
                v-model:value="
                  invoiceTemplatePayload.recipientContact.contactDetails.name
                    .value
                "
                :pre-value="
                  invoiceTemplatePayload?.recipientContact?.contactDetails?.name
                    ?.value
                "
                :slot-options="true"
                :required="true"
                :options="recipientContacts"
                :placeholder-value="'Select your details'"
                :label-animation="false"
                :single-border="true"
                :label="'name'"
                :prop-value="'name'"
                :clearable="true"
                :inline-search="true"
                :footer-icon="'icon-plus-circle-regular'"
                :footer-content="'Add new contact'"
                :footer="true"
                @key-press="searchRecipientContact"
                @footer-click="addNewContactModal()"
                @selected-value="handleSelectedRecipientContact"
              >
                <template #displayValue="value">
                  <span
                    style="
                      padding-left: 8px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    {{ value["displayLabel"]["name"] }}
                  </span>
                </template>
                <template #options="option">
                  <div
                    v-if="
                      option && option.optionsData && option.optionsData.name
                    "
                    style="
                      display: flex;
                      align-items: center;
                      font-size: 14px;
                      padding-left: 8px;
                      font-family: 'Opensans';
                      height: 31px !important;
                    "
                  >
                    <span style="font-size: 14px; font-weight: 400">{{
                      option.optionsData.name
                    }}</span>
                    <span style="padding-left: 10px; width: 20px"
                      ><i class="icon-buildings-alt-regular"></i
                    ></span>
                    <span
                      style="
                        font-size: 11px;
                        font-weight: 400;
                        padding-top: 3px;
                        padding-left: 8px;
                        color: #595959;
                      "
                      >{{ option.optionsData.company }}</span
                    >
                  </div>
                </template>
              </hlx-select>
            </div>

            <div
              v-if="
                invoiceTemplatePayload.recipientContact.contactDetails?.name
                  .value != ''
              "
              class="business-details"
            >
              <span class="form-field-wrapper --mb-5">
                <sapn class="--form-title">Contact details</sapn>
                <i
                  v-if="inDoubt"
                  class="icon-edit-regular business-title-editor"
                  @click="editCompany = !editCompany"
                ></i>
              </span>
              <span class="form-field-wrapper">
                <span class="--form-label">Name</span>
                <span v-if="recipientContactCompleteInfo && recipientContactCompleteInfo?.name" class="--form-value">{{
                  recipientContactCompleteInfo?.name
                }}</span>
              </span>

              <span class="form-field-wrapper">
                <span class="--form-label">Email</span>
                <span class="--form-value">{{
                  invoiceTemplatePayload.recipientContact.contactDetails.email
                    .value
                }}</span>
              </span>
              <!-- Recipient contact phone -->
              <span class="form-field-wrapper">
                <span class="--form-label">Phone</span>
                <span class="--form-value">{{
                  invoiceTemplatePayload.recipientContact.contactDetails.phone
                    .value?.country?.dial_code +
                  " " +
                  invoiceTemplatePayload.recipientContact.contactDetails.phone
                    .value.value
                }}</span>
              </span>
            </div>
            <div v-else class="business-details">
              <p class="no-data-placeholder-container">
                <span class="--form-label">Select your client contact</span>
                <span class="--form-label">or</span>
                <hlx-button class="sm primary" @click="addNewContactModal()"
                  >Add new contact</hlx-button
                >
              </p>
            </div>
          </div>

          <!-- <div class="custom-tile recipient-contact">
          <div class="sender-title-area">
            <span class="label-wrapper">
              <hlx-input
                v-if="!invoiceTemplatePayload.recipient.title.readOnly"
                v-model:value="invoiceTemplatePayload.recipient.title.value"
                :pre-val="invoiceTemplatePayload.recipient.title.value"
                :label-animation="false"
                type="text"
                :label-value="invoiceTemplatePayload.recipient.title.value"
                :clearable="false"
                iconposition="right"
                @focus-out="handleInputFocusOut('recipient-title')"
              />
              <p
                v-if="invoiceTemplatePayload.recipient.title.readOnly"
                class="sender-title-label"
                :class="{
                  '--readable': invoiceTemplatePayload.recipient.title.readOnly,
                }"
                @click.stop="
                  invoiceTemplatePayload.recipient.title.readOnly =
                    !invoiceTemplatePayload.recipient.title.readOnly
                "
              >
                {{ invoiceTemplatePayload.recipient.title.value }}
              </p>
              <span class="--info-label">(&nbsp;Client details&nbsp;)</span>
            </span>
          </div>
          <div class="sender-picker">
            <hlx-select
              v-model:name="
                invoiceTemplatePayload.recipient.businessDetails.businessName.value
              "
              v-model:value="
                invoiceTemplatePayload.recipient.businessDetails.businessName.value
              "
              :pre-value="
                invoiceTemplatePayload.recipient.businessDetails.businessName.value
              "
              :options="invoiceTemplatePayload.recipient.list.value"
              :placeholder-value="'Select your details'"
              :label-animation="false"
              :label="'name'"
              :prop-value="'value'"
              :clearable="true"
              @custom-change="returnOptions"
              @selected-value="returnSelectedObject"
            >
            </hlx-select>
          </div>
          <div class="business-details">
            <span class="form-field-wrapper --mb-5">
              <sapn class="--form-title">Business details</sapn>
              <i
                class="icon-edit-regular business-title-editor"
                @click="editCompany = !editCompany"
              ></i>
            </span>
            <span class="form-field-wrapper">
              <span class="--form-label">Business name</span>
              <span class="--form-value"></span>
            </span>

            <span class="form-field-wrapper">
              <span class="--form-label">Address</span>
              <span class="--form-value"></span>
            </span>
          </div>
        </div> -->
        </section>
      </section>

      <!-- Shipping details -->
      <!-- <hlx-input
        v-model:value="invoiceTemplatePayload.isShippingDetails.value"
        :pre-val="invoiceTemplatePayload.isShippingDetails.value"
        :single-border="true"
        :label-value="invoiceTemplatePayload.isShippingDetails.label"
        :checked="invoiceTemplatePayload.isShippingDetails.value"
        :type="'single-checkbox'"
      /> -->

      <!-- Sender and recipient shipping info -->
      <section v-if="1>2" class="custom-accordion-wrapper">
        <div
          class="custom-accordion-header"
          @click="isShippingAndBillingSection = !isShippingAndBillingSection"
        >
          <span class="--title">Shipping and billing information</span>
          <i
            v-if="!isShippingAndBillingSection"
            class="icon-angle-right-regular"
          ></i>
          <i
            v-if="isShippingAndBillingSection"
            class="icon-angle-down-regular"
          ></i>
        </div>
        <section
          v-if="isShippingAndBillingSection"
          class="custom-tile-2way shipping-container"
        >
          <!-- @Shipping address -->
          <div class="custom-tile shipping-to">
            <div class="title-area">
              <span class="label-wrapper">
                <hlx-input
                  v-if="
                    !invoiceTemplatePayload.recipientShippingDetails.title
                      .readOnly
                  "
                  v-model:value="
                    invoiceTemplatePayload.recipientShippingDetails.title.value
                  "
                  :focus="
                    !invoiceTemplatePayload.recipientShippingDetails.title
                      .readOnly
                  "
                  :pre-val="
                    invoiceTemplatePayload.recipientShippingDetails.title.value
                  "
                  :label-animation="false"
                  type="text"
                  :label-value="
                    invoiceTemplatePayload.recipientShippingDetails.title.value
                  "
                  :clearable="false"
                  iconposition="right"
                  @focus-out="handleInputFocusOut('recipient-shipping-title')"
                />
                <p
                  v-if="
                    invoiceTemplatePayload.recipientShippingDetails.title.readOnly
                  "
                  class="sender-title-label"
                  :class="{
                    '--readable':
                      invoiceTemplatePayload.recipientShippingDetails.title
                        .readOnly,
                  }"
                  @click.stop="
                    invoiceTemplatePayload.recipientShippingDetails.title.readOnly =
                      !invoiceTemplatePayload.recipientShippingDetails.title
                        .readOnly
                  "
                >
                  {{
                    invoiceTemplatePayload.recipientShippingDetails.title.value
                  }}
                </p>
              </span>
            </div>
            <div class="sender-picker">
              <hlx-select
                v-model:value="shippingAddressSearchKey"
                :pre-value="
                  invoiceTemplatePayload.recipientShippingDetails.address.value
                    .characteristic.description
                "
                :options="recipientSiteAddresses"
                :slot-options="true"
                :single-border="true"
                :disabled="showView"
                :clearable="true"
                :inline-search="true"
                :placeholder-value="'Select your address'"
                :label-animation="false"
                :label="'description'"
                :prop-value="'description'"
                @selected-value="handleSelectedShippingAddress"
              >
                <template #displayValue="value">
                  <span
                    style="
                      padding-left: 8px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    {{ value["displayLabel"].description }}
                  </span>
                </template>
                <template #options="option">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      font-size: 14px;
                      padding-left: 8px;
                      font-family: 'Opensans';
                      height: 31px !important;
                    "
                  >
                    <span style="font-size: 14px; font-weight: 400">{{
                      option.optionsData.description
                    }}</span>
                    <span style="padding-left: 10px; width: 20px"
                      ><i class="icon-buildings-alt-regular"></i
                    ></span>
                    <span
                      style="
                        font-size: 11px;
                        font-weight: 400;
                        padding-top: 3px;
                        padding-left: 8px;
                        color: #595959;
                      "
                      >{{ option.optionsData.companyName }}</span
                    >
                  </div>
                </template>
              </hlx-select>
            </div>
            <div class="custom-tile-card">
              <span v-if="inDoubt" class="form-field-wrapper --mb-5">
                <sapn class="--form-title">Shipping details</sapn>
                <i
                  v-if="inDoubt"
                  class="icon-edit-regular business-title-editor"
                  @click="editCompany = !editCompany"
                ></i>
              </span>
              <hlx-input
                v-if="inFuture"
                v-model:value="
                  invoiceTemplatePayload.recipientShippingDetails
                    .isBusinessAddress.value
                "
                :checked="
                  invoiceTemplatePayload.recipientShippingDetails
                    .isBusinessAddress.value
                "
                :label-value="
                  invoiceTemplatePayload.recipientShippingDetails
                    .isBusinessAddress.label
                "
                :type="'single-checkbox'"
                @change="
                  makeRecipientAddressAsShippingOrBillingAddress(
                    $event,
                    'shipping'
                  )
                "
              />
              <addressComponent
                :key="addresscomponetshipping"
                :pre-val="
                  invoiceTemplatePayload.recipientShippingDetails.address.value
                "
                :view="
                  invoiceTemplatePayload.recipientShippingDetails.address.readOnly
                "
                @address-data="receivedAddressData"
              />
              <hlx-input
                v-model:value="
                  invoiceTemplatePayload.recipientShippingDetails.shippingNotes
                    .value
                "
                :pre-val="
                  invoiceTemplatePayload.recipientShippingDetails.shippingNotes
                    .value
                "
                :label-animation="true"
                :label-value="
                  invoiceTemplatePayload.recipientShippingDetails.shippingNotes
                    .label
                "
                type="textarea"
                :clearable="true"
              />
            </div>
            <div class="multiaddress-checkbox-quote">
              <hlx-input
                v-model:value="
                  invoiceTemplatePayload.recipientShippingDetails.address
                    .multiShipping
                "
                :checked="false"
                :type="'single-checkbox'"
                @selected="
                  multiShippingCheckbox(
                    $event,
                    invoiceTemplatePayload.recipientShippingDetails.address.value
                  )
                "
              />
              {{ "Add multiple shipping address" }}
              <p
                class="bg-default border"
                data-tooltip="Add multiple shipping address"
                data-tooltip-location="bottom"
                style="width: 100px"
              >
                <i
                  class="icon-info-circle-regular"
                  style="font-size: 12px; padding: 2px 0px 0px 5px"
                ></i>
              </p>
            </div>
          </div>
          <!-- @Billing address -->
          <div class="custom-tile billing-address">
            <div class="title-area">
              <span class="label-wrapper">
                <hlx-input
                  v-if="
                    !invoiceTemplatePayload.recipientBillingDetails.title.readOnly
                  "
                  v-model:value="
                    invoiceTemplatePayload.recipientBillingDetails.title.value
                  "
                  :focus="
                    !invoiceTemplatePayload.recipientBillingDetails.title.readOnly
                  "
                  :pre-val="
                    invoiceTemplatePayload.recipientBillingDetails.title.value
                  "
                  :single-border="true"
                  :label-animation="false"
                  type="text"
                  :label-value="
                    invoiceTemplatePayload.recipientBillingDetails.title.value
                  "
                  :clearable="false"
                  iconposition="right"
                  @focus-out="handleInputFocusOut('recipient-billing-title')"
                />
                <p
                  v-if="
                    invoiceTemplatePayload.recipientBillingDetails.title.readOnly
                  "
                  class="sender-title-label"
                  :class="{
                    '--readable':
                      invoiceTemplatePayload.recipientBillingDetails.title
                        .readOnly,
                  }"
                  @click.stop="
                    invoiceTemplatePayload.recipientBillingDetails.title.readOnly =
                      !invoiceTemplatePayload.recipientBillingDetails.title
                        .readOnly
                  "
                >
                  {{ invoiceTemplatePayload.recipientBillingDetails.title.value }}
                </p>
              </span>
            </div>
            <div class="sender-picker">
              <hlx-select
                v-model:value="billingAddressSearchKey"
                :pre-value="
                  invoiceTemplatePayload.recipientBillingDetails.address.value
                    .characteristic.description
                "
                :options="recipientBillingAddresses"
                :slot-options="true"
                :placeholder-value="'Select your billing address'"
                :label-animation="false"
                :label="'description'"
                :clearable="true"
                :inline-search="true"
                :prop-value="'description'"
                :single-border="true"
                @selected-value="handleSelectedBillingAddress"
              >
                <template #displayValue="value">
                  <span
                    style="
                      padding-left: 8px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    {{ value["displayLabel"].description }}
                  </span>
                </template>
                <template #options="option">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      font-size: 14px;
                      padding-left: 8px;
                      font-family: 'Opensans';
                      height: 31px !important;
                    "
                  >
                    <span style="font-size: 14px; font-weight: 400">{{
                      option.optionsData.description
                    }}</span>
                    <span style="padding-left: 10px; width: 20px"
                      ><i class="icon-buildings-alt-regular"></i
                    ></span>
                    <span
                      style="
                        font-size: 11px;
                        font-weight: 400;
                        padding-top: 3px;
                        padding-left: 8px;
                        color: #595959;
                      "
                      >{{ option.optionsData.companyName }}</span
                    >
                  </div>
                </template>
              </hlx-select>
            </div>
            <div class="custom-tile-card">
              <span v-if="inDoubt" class="form-field-wrapper --mb-5">
                <sapn class="--form-title">Shipping details</sapn>
                <i
                  v-if="inDoubt"
                  class="icon-edit-regular business-title-editor"
                  @click="editCompany = !editCompany"
                ></i>
              </span>
              <hlx-input
                v-if="inFuture"
                v-model:value="
                  invoiceTemplatePayload.recipientBillingDetails.isBusinessAddress
                    .value
                "
                :checked="
                  invoiceTemplatePayload.recipientBillingDetails.isBusinessAddress
                    .value
                "
                :label-value="
                  invoiceTemplatePayload.recipientBillingDetails.isBusinessAddress
                    .label
                "
                :single-border="true"
                :type="'single-checkbox'"
                @change="
                  makeRecipientAddressAsShippingOrBillingAddress(
                    $event,
                    'billing'
                  )
                "
              />
              <addressComponent
                :pre-val="
                  invoiceTemplatePayload.recipientBillingDetails.address.value
                "
                @address-data="receivedAddressData"
              />
              <hlx-input
                v-model:value="
                  invoiceTemplatePayload.recipientBillingDetails.shippingNotes
                    .value
                "
                :pre-val="
                  invoiceTemplatePayload.recipientBillingDetails.shippingNotes
                    .value
                "
                :label-animation="true"
                :label-value="
                  invoiceTemplatePayload.recipientBillingDetails.shippingNotes
                    .label
                "
                type="textarea"
                :clearable="true"
              />
            </div>
            <div style="height: 40px"></div>
          </div>
        </section>
      </section>

      <!-- Product and price -->
      <section class="custom-accordion-wrapper">
        <div
          class="custom-accordion-header"
          @click="isProductAndPriceSection = !isProductAndPriceSection"
        >
          <span class="--title">Products and services</span>
          <i
            v-if="!isProductAndPriceSection"
            class="icon-angle-right-regular"
          ></i>
          <i
            v-if="isProductAndPriceSection"
            class="icon-angle-down-regular"
          ></i>
        </div>
        <section
          v-if="isProductAndPriceSection && true"
          id="pricing-table-container-#1"
          class="shipping-container"
        >
          <!-- @Product info -->
          <pricingTableComponent
            :edit-summary-data="quoteData"
            :edit-summary-data-from-rules="quoteSummaryDataFromRules"
            :data="sendSelectedProductOfferingData"
            :edit-data="editPricingData"
            :tax-data="recipientTaxes"
            :checkbox-shipping="checkboxShipping"
            :shipping-options="shippingOptions"
            @add-new-offer="addNewOffer"
            @summary="receivePricingData"
            @total-summary="receiveTotalPricingData"
            @summary-from-rules="receiveSummaryfromRules"
            @reconfigure="sendForReconfiguration"
          ></pricingTableComponent>
        </section>
      </section>

      <!-- recipient add Terms and policy and other container -->
      <section class="add-terms-and-policy-container">
        <div class="add-terms-and-policy-container-left-section">
          <div
            v-for="(specificField, index) in termsAndPolicyAreaData"
            :key="index"
            class="add-terms-and-policy-container-left-section-specific-container"
          >
            <hlx-button
              v-if="specificField.checked != true"
              class="text sm add-signature"
              @click="addSpecificFields(specificField)"
              >{{ specificField?.name }}</hlx-button
            >
          </div>
          <div></div>
        </div>
        <div class="add-terms-and-policy-container-signature-section">
          <span>
            <!-- <multipleSignature
              :recipient-lists="recipientsList"
              :account-name="
                invoiceTemplatePayload.recipient.businessDetails.businessName
                  .value
              "
              :pre-value="signaturePlacePreVal['nofields']"
              :assign-data-color="assignedColors"
              @assign-data-color-object="emitColordata"
              @emit-data="signatureData($event, 'nofields')"
            ></multipleSignature> -->
            <!-- <multipleSignature v-else :assign-data-color="assignedColors" @assign-data-color-object="emitColordata" @emit-data="signatureData($event,'nofields')"></multipleSignature> -->
          </span>
          <!-- <signature-component></signature-component> -->
        </div>
      </section>

      <!-- recipient add Terms and policy each section -->
      <section class="terms-and-policy-whole-element-container">
        <div v-if="terms == true" class="terms-and-policy-each-element-section">
          <div
            v-for="(fields, index) in invoiceTemplatePayload
              .recipientTermsandConditions.conditions"
            :key="index"
            class="terms-and-policy-each-element-section-header-container"
          >
            <span class="terms-and-policy-one-container">
              <span class="label-wrapper">
                <p
                  v-if="
                    invoiceTemplatePayload.recipientTermsandConditions.conditions[
                      index
                    ].readOnly
                  "
                  :class="{ '--readable': fields.readOnly }"
                  class="terms-and-policy-each-element-section-header-value"
                  @click.stop="
                    invoiceTemplatePayload.recipientTermsandConditions.conditions[
                      index
                    ].readOnly =
                      !invoiceTemplatePayload.recipientTermsandConditions
                        .conditions[index].readOnly
                  "
                >
                  {{ fields.label }}
                </p>
                <hlx-input
                  v-else-if="
                    !invoiceTemplatePayload.recipientTermsandConditions
                      .conditions[index].readOnly
                  "
                  v-model:value="fields.label"
                  :pre-val="fields.label"
                  :single-border="true"
                  :label-animation="false"
                  type="text"
                  :focus="!fields.readOnly"
                  :label-value="invoiceTemplatePayload.header.title.value"
                  :clearable="false"
                  iconposition="right"
                  @focus-out="termandConditionsInputFocusOut(index)"
                />
              </span>
              <i
                class="icon-times-regular" 
                @click="removeAdditionalGroup(index)"
              ></i>
            </span>
            <span
              v-for="(additionalFields, col_index) in fields.additionalFields"
              :key="col_index"
              draggable="true"
              class="terms-and-policy-additional-fields"
              @dragstart="dragRowStart(index, col_index)"
              @dragleave="dragRowLeave(index, col_index)"
              @dragend="dragRowEnd(index, col_index)"
            >
              <span class="terms-and-policy-additional-fields-container">
                <div
                  v-if="additionalFields.textEditor"
                  :class="{ '--readable': additionalFields.textEditor }"
                  class="wrap"
                  @click.stop="
                    additionalFields.textEditor = !additionalFields.textEditor
                  "
                >
                  {{ additionalFields.label }}
                </div>
                <hlx-input
                  v-else-if="!additionalFields.textEditor"
                  v-model:value="additionalFields.label"
                  :pre-val="additionalFields.label"
                  :single-border="true"
                  :label-animation="false"
                  :focus="!additionalFields.textEditor"
                  type="text"
                  :clearable="true"
                  @focus-out="
                    termandConditionstextEditorFocusOut(index, col_index)
                  "
                />
                <i
                  class="icon-times-regular"
                  @click="removeAdditionalTerms(index, col_index)"
                ></i>
              </span>
            </span>
            <span class="terms-and-policy-add-new-group-or-term-element">
              <div
                class="terms-and-policy-add-valid-date-button"
                @click="addAdditionalTerm(fields, index)"
              >
                <i class="icon-plus-square-regular"></i>
                <span class="terms-and-policy-add-valid-date-label">
                  Add New Term</span
                >
              </div>
              <div
                v-if="
                  index ==
                  invoiceTemplatePayload.recipientTermsandConditions.conditions
                    .length -
                    1
                "
                class="terms-and-policy-add-valid-date-button"
                @click="addAdditionalGroup"
              >
                <i class="icon-plus-square-regular"></i>
                <span class="terms-and-policy-add-valid-date-label">
                  Add New Group</span
                >
              </div>
            </span>
          </div>
          <span
            v-if="terms == true && attachments == true"
            style="display: flex; justify-content: flex-end"
          >
            <span>
              <!-- <multipleSignature
                :recipient-lists="recipientsList"
                :account-name="
                  invoiceTemplatePayload.recipient.businessDetails.businessName
                    .value
                "
                :pre-value="signaturePlacePreVal['termsandconditions']"
                :assign-data-color="assignedColors"
                @assign-data-color-object="emitColordata"
                @emit-data="signatureData($event, 'termsandconditions')"
              ></multipleSignature> -->
              <!-- <multipleSignature v-else  :assign-data-color="assignedColors" @assign-data-color-object="emitColordata" @emit-data="signatureData($event,'termsandconditions')"></multipleSignature> -->
            </span>
          </span>
        </div>
        <div v-if="notes == true" class="terms-and-policy-each-element-section">
          <div class="terms-and-policy-each-element-section-header-container">
            <span class="terms-and-policy-one-container">
              <span class="label-wrapper">
                <p
                  v-if="invoiceTemplatePayload.recipientNotes.readOnly"
                  :class="{
                    '--readable': invoiceTemplatePayload.recipientNotes.readOnly,
                  }"
                  class="terms-and-policy-each-element-section-header-value"
                  @click.stop="
                    invoiceTemplatePayload.recipientNotes.readOnly =
                      !invoiceTemplatePayload.recipientNotes.readOnly
                  "
                >
                  {{ invoiceTemplatePayload.recipientNotes.label }}
                </p>
                <hlx-input
                  v-else-if="!invoiceTemplatePayload.recipientNotes.readOnly"
                  v-model:value="invoiceTemplatePayload.recipientNotes.label"
                  :pre-val="invoiceTemplatePayload.recipientNotes.label"
                  :single-border="true"
                  :label-animation="false"
                  type="text"
                  :focus="!invoiceTemplatePayload.recipientNotes.readOnly"
                  :label-value="invoiceTemplatePayload.recipientNotes.label"
                  :clearable="false"
                  iconposition="right"
                  @focus-out="handleInputFocusOut('recipientNotes')"
                />
              </span>
              <i
                class="icon-times-regular"
                @click="removeTermsandPolicyField('Add notes')"
              ></i>
            </span>
          </div>
          <hlx-text-editor
          v-model:modelValue="invoiceTemplatePayload.recipientNotes.value"
            :height="'300px'"
            @current-change="notesValue"
          ></hlx-text-editor>
        </div>
        <div
          v-if="attachments == true"
          class="terms-and-policy-each-element-section"
        >
          <div class="terms-and-policy-each-element-section-header-container">
            <span class="terms-and-policy-one-container">
              <span class="label-wrapper">
                <p
                  v-if="invoiceTemplatePayload.recipientAttachments.readOnly"
                  :class="{
                    '--readable':
                      invoiceTemplatePayload.recipientAttachments.readOnly,
                  }"
                  class="terms-and-policy-each-element-section-header-value"
                  @click.stop="
                    invoiceTemplatePayload.recipientAttachments.readOnly =
                      !invoiceTemplatePayload.recipientAttachments.readOnly
                  "
                >
                  {{ invoiceTemplatePayload.recipientAttachments.label }}
                </p>
                <hlx-input
                  v-else-if="
                    !invoiceTemplatePayload.recipientAttachments.readOnly
                  "
                  v-model:value="
                    invoiceTemplatePayload.recipientAttachments.label
                  "
                  :pre-val="invoiceTemplatePayload.recipientAttachments.label"
                  :single-border="true"
                  :label-animation="false"
                  type="text"
                  :focus="!invoiceTemplatePayload.recipientAttachments.readOnly"
                  :label-value="invoiceTemplatePayload.recipientAttachments.label"
                  :clearable="false"
                  iconposition="right"
                  @focus-out="handleInputFocusOut('recipientAttachments')"
                />
              </span>
              <i
                class="icon-times-regular"
                @click="removeTermsandPolicyField('Add attachments')"
              ></i>
            </span>
          </div>
          <hlx-multi-upload
            :pre-value="preValueAttachments"
            :type="'single-line-multi-upload'"
            :file-type="'.json,.xlsx,.doc,.pdf,.txt,.jpg,.jpeg,.png'"
            :file-size-limit="'1GB'"
            @native-data="attachmentValue"
            @remove="removeAttachmentValue"
          ></hlx-multi-upload>
        </div>
        <div
          v-if="additional == true"
          class="terms-and-policy-each-element-section"
        >
          <div class="terms-and-policy-each-element-section-header-container">
            <span class="terms-and-policy-one-container">
              <span class="label-wrapper">
                <p
                  v-if="invoiceTemplatePayload.recipientAdditionalInfo.readOnly"
                  :class="{
                    '--readable':
                      invoiceTemplatePayload.recipientAdditionalInfo.readOnly,
                  }"
                  class="terms-and-policy-each-element-section-header-value"
                  @click.stop="
                    invoiceTemplatePayload.recipientAdditionalInfo.readOnly =
                      !invoiceTemplatePayload.recipientAdditionalInfo.readOnly
                  "
                >
                  {{ invoiceTemplatePayload.recipientAdditionalInfo.label }}
                </p>
                <hlx-input
                  v-else-if="
                    !invoiceTemplatePayload.recipientAdditionalInfo.readOnly
                  "
                  v-model:value="
                    invoiceTemplatePayload.recipientAdditionalInfo.label
                  "
                  :pre-val="invoiceTemplatePayload.recipientAdditionalInfo.label"
                  :single-border="true"
                  :label-animation="false"
                  type="text"
                  :focus="
                    !invoiceTemplatePayload.recipientAdditionalInfo.readOnly
                  "
                  :label-value="
                    invoiceTemplatePayload.recipientAdditionalInfo.label
                  "
                  :clearable="false"
                  iconposition="right"
                  @focus-out="handleInputFocusOut('recipientAdditionalInfo')"
                />
              </span>
              <i
                class="icon-times-regular"
                @click="removeTermsandPolicyField('Add additional info')"
              ></i>
            </span>
          </div>
          <div
            v-if="
              invoiceTemplatePayload.recipientAdditionalInfo.additionalFields
                .length > 0
            "
            class="--additional-fields"
          >
            <div
              v-for="(field, index) in invoiceTemplatePayload
                .recipientAdditionalInfo.additionalFields"
              :key="field"
              class="additional-fields-panel --label-value-2"
            >
              <span class="label-wrapper --Q-label">
                <hlx-input
                  v-model:value="field.label"
                  :pre-val="field.label"
                  :label-animation="false"
                  :single-border="true"
                  type="text"
                  label-value="Field name"
                  :clearable="false"
                />
              </span>
              <span class="additional-fields">
                <hlx-input
                  v-model:value="field.value"
                  :pre-val="field.value"
                  :label-animation="false"
                  :single-border="true"
                  type="text"
                  label-value="value"
                  :clearable="false"
                />
              </span>
              <i
                class="icon-times-regular"
                @click.stop="removeAdditionalInfo(index)"
              ></i>
            </div>
          </div>
          <div class="quote-add-valid-date-button" @click="addAdditionalInfo">
            <i class="icon-plus-square-regular"></i>
            <span class="quote-add-valid-date-label"> Add more fields</span>
          </div>
        </div>
        <span
          v-if="terms == true || attachments == true"
          style="display: flex; justify-content: flex-end"
        >
          <span>
            <!-- <multipleSignature
              :recipient-lists="recipientsList"
              :account-name="
                invoiceTemplatePayload.recipient.businessDetails.businessName
                  .value
              "
              :pre-value="signaturePlacePreVal['pageend']"
              :assign-data-color="assignedColors"
              @assign-data-color-object="emitColordata"
              @emit-data="signatureData($event, 'pageend')"
            ></multipleSignature> -->
            <!-- <multipleSignature v-else :assign-data-color="assignedColors" @assign-data-color-object="emitColordata" @emit-data="signatureData($event,'pageend')"></multipleSignature> -->
          </span>
        </span>
      </section>

      <!--recipient Signature section-->
      <section v-if="inDoubt">
        <div class="signature-section-container">
          <!--From signature-->
          <div class="from-signature-section-container">
            <span v-if="hide == true" class="signature-section">
              <signatureComponent
                :show-title="true"
                mode="app"
                @signature-data="fromSignatureValue"
              ></signatureComponent>
              <p
                v-if="invoiceTemplatePayload.fromSignature.readOnly"
                :class="{
                  '--readable': invoiceTemplatePayload.fromSignature.readOnly,
                }"
                class="signature-input-container"
                @click.stop="
                  invoiceTemplatePayload.fromSignature.readOnly =
                    !invoiceTemplatePayload.fromSignature.readOnly
                "
              >
                {{ invoiceTemplatePayload.fromSignature.label }}
              </p>
              <hlx-input
                v-else-if="!invoiceTemplatePayload.fromSignature.readOnly"
                v-model:value="invoiceTemplatePayload.fromSignature.label"
                :pre-val="invoiceTemplatePayload.fromSignature.label"
                :single-border="true"
                :label-animation="false"
                type="text"
                :focus="!invoiceTemplatePayload.fromSignature.readOnly"
                :label-value="invoiceTemplatePayload.header.title.value"
                :clearable="false"
                iconposition="right"
                @focus-out="handleInputFocusOut('recipientfromsignature')"
              />
            </span>
          </div>

          <!--To signature-->
          <div class="to-signature-section-container">
            <span class="signature-section">
              <signatureComponent
                :editable="false"
                mode="customer1"
                @signature-data="toSignatureValue"
              ></signatureComponent>
              <p
                v-if="invoiceTemplatePayload.toSignature.readOnly"
                :class="{
                  '--readable': invoiceTemplatePayload.toSignature.readOnly,
                }"
                class="signature-input-container"
                @click.stop="
                  invoiceTemplatePayload.toSignature.readOnly =
                    !invoiceTemplatePayload.toSignature.readOnly
                "
              >
                {{ invoiceTemplatePayload.toSignature.label }}
              </p>
              <hlx-input
                v-else-if="!invoiceTemplatePayload.toSignature.readOnly"
                v-model:value="invoiceTemplatePayload.toSignature.label"
                :pre-val="invoiceTemplatePayload.toSignature.label"
                :single-border="true"
                :label-animation="false"
                type="text"
                :focus="!invoiceTemplatePayload.toSignature.readOnly"
                :label-value="invoiceTemplatePayload.header.title.value"
                :clearable="false"
                iconposition="right"
                @focus-out="handleInputFocusOut('recipienttosignature')"
              />
            </span>
          </div>
        </div>
      </section>
      <!-- <footer
        v-if="10 < 1"
        class="footer-action-button tool-bar"
        style="z-index: 270"
      >
        <div class="back-button" @click="$router.push({ name: 'QuoteTable' })">
          <i class="icon-arrow-left-regular"></i>
          <p>Back</p>
        </div>

        <hlx-button
          class="primary sm --ml"
          :disabled="!(quoteDataLoaded && isValidQuoteTemplate)"
          @click="saveQuote"
        >
          Continue</hlx-button
        >
        <span
          :class="{ 'disable-it': !(isValidDraftQuote && quoteDataLoaded) }"
          class="custom-text-button"
          @click="saveQuote('save')"
        >
          Save</span
        >
      </footer> -->
    </section>
    <section
      v-else-if="show_offering && !show_configuration"
      class="product-offering-container"
      style="
        width: 100%;
        height: 100%;
        display: flex;
        padding: 20px;
        /* gap: 15px; */
        overflow: inherit;
        flex-direction: column;
      "
    >
      <div
        class="new-product-listing-container-toolbox"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
        "
      >
        <div>
          <div
            class="data-view-nav-container"
            style="
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 14px;
              cursor: pointer;
            "
            @click="clickBack"
          >
            <i class="icon-arrow-left-regular"></i>
            <span>Back</span>
          </div>
        </div>
        <div>
          <div
            class="data-view-search-container"
            style="display: flex; align-items: center; gap: 8px"
          >
            <span class="filter-table filter-icon-listing-page">
              <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
                <i class="icon-filter-regular"></i>
                <span v-if="filtertaglength != 0" class="filter-count">{{
                  filtertaglength
                }}</span>
              </hlx-button>
            </span>
            <hlx-search
              v-model="searchvalue"
              @search-key="searchOffers"
            ></hlx-search>
            <hlx-badge
              :icon="'icon-shopping-cart-regular'"
              :count="selectedProductOfferingData.length"
              @click="clickBack"
            />
          </div>
        </div>
        <!-- <hlx-dataview
          :card-data="card _data"
          :title-prop="'name'"
          :description-prop="'description'"
          :category-prop="'category'"
          :label-prop="'status'"
          :footer-icon="'icon-plus-circle-regular'"
          :footer-text="'Add'"
          @card-data="selectedOffer"
        >
          <template #nav>
            <div
              class="data-view-nav-container"
              style="
                display: flex;
                align-items: center;
                gap: 8px;
                color: #54bd95;
                cursor: pointer;
              "
              @click="show_offering = false"
            >
              <i class="icon-arrow-left-regular"></i>
              <span style="margin-bottom: 3px">Back</span>
            </div>
          </template>
          <template #search>
            <div
              class="data-view-search-container"
              style="display: flex; align-items: center; gap: 8px"
            >
              <hlx-search @search-key="searchOffers"></hlx-search>
              hi
              <hlx-badge
                :icon="'icon-shopping-cart-regular'"
                :count="selectedProductOfferingData.length"
              />
            </div>
          </template>
          <template #footer>
            <hlx-pagination
              v-model:rows-per-page="externalRowsPerPage"
              :total="card_da ta.length"
              rows-per-page
              enable-rows-per-page
              :rows-per-page-list="[15, 30]"
              :options="{
                attributes: {
                  rowsPerPageTitle: true,
                  rowsPerPageResult: true,
                  rowsPerPageDropdown: {
                    position: {
                      top: 0,
                      right: 1,
                    },
                  },
                },
              }"
              @current-page="currentPageData"
              @updated:rows-per-page="changeRowsPerPage"
            ></hlx-pagination>
          </template>
        </hlx-dataview> -->
      </div>

      <div class="switchforRP">
        <div class="switchforRPright">
          <span class="switchforRPrightspan1">{{
            "Show retired product"
          }}</span>
          <span class="switchforRPrightspan2">
            <hlx-input
              v-model:value="retiredvmodel"
              :checked="false"
              :type="'switch'"
            />
          </span>
        </div>
      </div>

      <div class="listing-container">
        <div class="filter-panel-listing-page">
          <FilterComponent
            :key="filterComponentKey"
            :display-alternate-values="displayAlternate"
            :pre-value="filterPrevalueDuplicate2"
            :filterpanelname="'productOffering'"
            :company-name="company"
            :query-value="query"
            :display-data="displayFilterData"
            :module="'discountOffers'"
            @filtered-data="offeringFilterData"
          ></FilterComponent>
        </div>
        <div
          class="new-product-listing-cards"
          style="
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
            grid-gap: 50px;
          "
        >
          <div
            v-for="(item, index) in card_data"
            :key="index"
            class="listing-card"
            style="
              min-height: 300px;
              max-height: 420px;
              border: 1px solid #d8d8d8;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
            "
            @click="showConfigurationPage(item)"
          >
            <span
              style="
                height: 220px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <span
                v-if="
                  item.prodSpecCharValue[0]?.attachment[0]?.image_url === ''
                "
                style="width: 100px"
              >
                <img
                  style="height: 100px; width: 100px; object-fit: contain"
                  src="@/assets/images/Placeholder image.svg"
                  alt="product image preview"
                />
              </span>
              <span v-else style="width: 100%; height: 100%">
                <img
                  style="height: inherit; width: inherit; object-fit: contain"
                  :src="item.prodSpecCharValue[0]?.attachment[0]?.image_url"
                  alt="product image preview"
                />
              </span>
            </span>
            <div
              style="
                border-top: 1px solid rgb(216, 216, 216);
                padding: 20px;
                min-height: 200px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <span style="display: flex; flex-direction: column">
                <span
                  style="
                    margin-bottom: 10px;
                    display: -webkit-box;
                    max-width: 400px;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ item?.name }}
                </span>
                <span style="font-size: 12px; margin-bottom: 10px">
                  {{ item.category }}
                </span>
              </span>
              <span style="display: flex; flex-direction: column">
                <span
                  style="
                    font-weight: bold;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    flex-wrap: wrap;
                  "
                >
                  {{
                    currencyFormatter(
                      item.price.whole + "." + item.price.fraction,
                      {
                        currency:
                          item.prodSpecCharValue[0]?.tiers[0]?.price?.unit,
                      }
                    )
                  }}
                  &nbsp;
                  <del
                    v-if="
                      item?.discountResponse?.productOffering?.price !==
                      undefined
                    "
                    style="font-weight: 100; font-size: 14px"
                    >{{
                      currencyFormatter(
                        item?.discountResponse?.productOffering?.price,
                        {
                          currency:
                            item.prodSpecCharValue[0]?.tiers[0]?.price?.unit,
                        }
                      )
                    }}</del
                  >

                  <span
                    v-if="item.offering_type === 'intangible'"
                    style="font-weight: 100"
                  >
                    {{ " / " + item?.billing_period }}
                  </span>
                  &nbsp;
                  <span
                    v-if="
                      item?.discountResponse?.productOffering?.price !==
                      undefined
                    "
                    class="card-price-discount"
                  >
                    <div class="pricing-details-card">
                      <h5 style="margin-bottom: 10px">Price Details</h5>
                      <div class="price-item" style="font-weight: 100">
                        <span>Price</span>
                        <span class="price" style="font-weight: 100">
                          {{
                            currencyFormatter(
                              item?.discountResponse?.productOffering?.price,
                              {
                                currency:
                                  item.prodSpecCharValue[0]?.tiers[0]?.price
                                    ?.unit,
                              }
                            )
                          }}
                          {{
                            item?.billing_period !== "" &&
                            item?.billing_period !== undefined &&
                            item?.offering_type === "intangible"
                              ? "/" + item?.billing_period
                              : ""
                          }}</span
                        >
                      </div>
                      <div class="price-item" style="font-weight: 100">
                        <span>Product Discount</span>
                        <span class="discount" style="color: #02b55c">
                          - &nbsp;
                          <p
                            v-if="
                              item?.discountResponse?.productOffering
                                ?.appliedDiscount?.discountType?.name ==
                              'Percentage'
                            "
                          >
                            {{
                              item?.discountResponse?.productOffering
                                ?.appliedDiscount?.discountType?.value + "%"
                            }}
                          </p>
                          <p v-else>
                            {{
                              $store?.getters?.getDefaultSettings?.currency
                                ?.symbol +
                              item?.discountResponse?.productOffering
                                ?.appliedDiscount?.discountType?.value
                            }}
                          </p>
                        </span>
                      </div>
                      <hr />
                      <div class="price-item">
                        <span>Selling Price</span>
                        <span class="final-price">
                          {{
                            currencyFormatter(
                              item.price.whole + "." + item.price.fraction,
                              {
                                currency:
                                  item.prodSpecCharValue[0]?.tiers[0]?.price
                                    ?.unit,
                              }
                            )
                          }}</span
                        >
                      </div>
                    </div>
                    <i class="icon-info-circle-regular"></i>
                  </span>
                  <span
                    style="
                      color: rgba(38, 94, 75, 1);
                      font-weight: 900;
                      font-size: 14px;
                    "
                  >
                    {{
                      item?.discountResponse?.productOffering?.appliedDiscount
                        ?.name
                    }}
                  </span>
                </span>
                <span>
                  <button
                    v-if="cartItems.includes(item.id) == false"
                    class="custom-addtocart-btn"
                    @click.stop="
                      selectedOffer($event, item), addToCart($event, item)
                    "
                  >
                    <i
                      class="icon-shopping-cart-plus-regular"
                      @click.stop="
                        selectedOffer($event, item), addToCart($event, item)
                      "
                    ></i>
                    &nbsp; Add to cart
                  </button>
                  <button
                    v-else
                    class="custom-addtocart-btn item-added-to-cart"
                  >
                    Added
                  </button>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 0.75rem;
          position: fixed;
          box-shadow: 1px 1px 30px -16px;
          right: 0;
          left: 72px;
          bottom: 0;
          background: white;
        "
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :total="card_data_length"
          rows-per-page
          enable-rows-per-page
          :rows-per-page-list="[15, 30]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPageData"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    </section>
    <section
      v-else-if="show_offering && show_configuration"
      style="width: 100%; position: relative"
    >
      <configComponent
        v-if="!isDepFlow"
        :offer-id="configOfferId"
        :reconfigure="reconfigure"
        :reconfigure-data="reconfigureData"
        :from-cpq="true"
        @for-cpq="changeConfigToDep"
        @close="closeConfigurationPage"
        @selected-configuration="selectedOfferFromConfiguration($event)"
      ></configComponent>
      <depConfigComponent
        v-if="isDepFlow && false"
        :from-cpq="true"
        :offer-id="depPayload.offerId"
        :dependency-id="depPayload.dependencyId"
        @selected-configuration-from-dep="
          selectedOfferFromConfigurationFromDep($event)
        "
        @close-dep="closeConfigurationPage"
      ></depConfigComponent>
    </section>

    <!-- Next bill date modal window -->
    <hlx-modal
      :modal-active="
        invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting.enabled
      "
      :height="'450px'"
      :width="'450px'"
      :modal="true"
      @close="
        invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting.enabled = false
      "
    >
      <template #header>
        <div>Set a next bill date</div>
      </template>
      <template #content>
        <div class="quote-valid-till-date-modal-content">
          <div class="day-configurator-wrapper">
            <span>Set next bill date to</span
            ><span class="valid-till-input">
              <hlx-input
                v-model:value="
                  invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting.value
                "
                :pre-val="
                  invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting.value
                "
                :label-animation="false"
                type="number"
                :steps="'1'"
                label-value="Days"
                :clearable="false"
                @at-input="
                  addDatesToGivenDateV1(
                    invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate
                      .value,
                    invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting
                      .value
                  )
                " /></span
            ><span>days after Quotation date</span>
          </div>
          <hlx-label
            class="primary"
            :options="{
              'background-color': '#f7f7f7',
              'text-color': 'black',
              'border-color': '#f7f7f7',
            }"
          >
            Your Expiry date will be
            {{
              dateAndTimeFormatter(
                addDatesToGivenDateV1(
                  invoiceTemplatePayload.quoteBasicInfo.quoteDate.value,
                  invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting.value
                )
              )
            }}
          </hlx-label>
        </div>
      </template>
      <template #footer>
        <hlx-button class="primary sm" @click="buildAfterGivenDate"
          >Save</hlx-button
        >
      </template>
    </hlx-modal>

    <!-- sender company detail modal window -->
    <hlx-modal
      :modal-active="editCompany"
      :height="'700px'"
      :width="'750px'"
      :modal="true"
      @close="editCompany = false"
    >
      <template #header>
        <div>Business details</div>
      </template>
      <template #content>
        <section class="business-detail-container">
          <div class="basic-business-info-container">
            <div
              class="info-header"
              @click="isBasicInfoToggled = !isBasicInfoToggled"
            >
              <span class="--title">Basic information</span>
              <i
                v-if="!isBasicInfoToggled"
                class="icon-angle-right-regular"
              ></i>
              <i v-if="isBasicInfoToggled" class="icon-angle-down-regular"></i>
            </div>
            <section v-if="isBasicInfoToggled" class="basic-business-info-body">
              <hlx-input
                v-model:value="
                  invoiceTemplatePayload.sender.businessDetails.businessName.value
                "
                :pre-val="
                  invoiceTemplatePayload.sender.businessDetails.businessName.value
                "
                :label-animation="true"
                label-value="Business name"
                type="text"
                required="true"
                :clearable="true"
              />
              <hlx-select
                v-model:value="
                  invoiceTemplatePayload.sender.businessDetails.country['value']
                "
                :pre-value="
                  invoiceTemplatePayload.sender.businessDetails.country['value']
                "
                required="true"
                :options="countryList"
                :placeholder-value="'Country'"
                :label-animation="true"
                :label="'displayName'"
                :prop-value="'displayName'"
                :clearable="true"
                @custom-change="returnOptions"
                @selected-value="returnSelectedObject"
              >
              </hlx-select>
              <hlx-input
                v-model:value="
                  invoiceTemplatePayload.sender.businessDetails.city.value
                "
                :pre-val="
                  invoiceTemplatePayload.sender.businessDetails.city.value
                "
                :label-animation="true"
                label-value="City"
                type="text"
                :clearable="true"
              />
            </section>
          </div>
          <!-- Tax  -->
          <!-- Future confifuration temporarily commented -->
          <!-- <div class="tax-information">
            <div class="info-header" @click="isTaxInfoToggled = !isTaxInfoToggled">
              <span class="--title">Tax information <span class="--info-label">(&nbsp; Optional &nbsp;)</span></span>
              <i v-if="!isTaxInfoToggled" class="icon-angle-right-regular"></i>
              <i v-if="isTaxInfoToggled" class="icon-angle-down-regular"></i>
            </div>
            <section v-if="isTaxInfoToggled" class="tax-info-body">
              <div class="form-input-field-wrapper --2way">
                <hlx-input v-model:value="invoiceTemplatePayload.sender.businessDetails.businessGSTIN
            .value
          " :pre-val="invoiceTemplatePayload.sender.businessDetails.businessGSTIN
            .value
          " :label-animation="true" label-value="Business GSTIN" type="text"
                  :clearable="true" /><hlx-input v-model:value="invoiceTemplatePayload.sender.businessDetails.businessPANNumber
            .value
          " :pre-val="invoiceTemplatePayload.sender.businessDetails.businessPANNumber
            .value
          " :label-animation="true" label-value="Business PAN number" type="text" :clearable="true" />
              </div>
              <div class="vendor-type-panel">
                <p class="--title --normal">Vendor type</p>
                <span class="vendor-radio-group">
                  <hlx-input v-model:value="invoiceTemplatePayload.sender.businessDetails.vendorType"
                    :checked="invoiceTemplatePayload.sender.businessDetails.vendorType" label-value="Individual"
                    :type="'single-radio'" :name="'vendortype'" />
                  <hlx-input v-model:value="invoiceTemplatePayload.sender.businessDetails.vendorType"
                    :checked="invoiceTemplatePayload.sender.businessDetails.vendorType" label-value="Company"
                    :type="'single-radio'" :name="'vendortype'" />
                </span>
              </div>
              <hlx-select v-model:value="invoiceTemplatePayload.sender.businessDetails.taxTreatment['value']
          " :options="taxTreatmentList" :placeholder-value="'Tax treatment'" :label-animation="true"
                :label="'name'" :prop-value="'value'" :clearable="true" @custom-change="returnOptions"
                @selected-value="returnSelectedObject">
              </hlx-select>

            </section>
          </div> -->

          <!-- Address -->
          <div class="address-information">
            <div
              class="info-header"
              @click="isAddressInfoToggled = !isAddressInfoToggled"
            >
              <span class="--title"
                >Address information
                <span class="--info-label">(&nbsp; Optional &nbsp;)</span></span
              >
              <i
                v-if="!isAddressInfoToggled"
                class="icon-angle-right-regular"
              ></i>
              <i
                v-if="isAddressInfoToggled"
                class="icon-angle-down-regular"
              ></i>
            </div>
            <section v-if="isAddressInfoToggled" class="address-info-body">
              <addressComponent
                :pre-val="
                  invoiceTemplatePayload.sender.businessDetails.address.value
                "
                @address-data="receivedAddressData"
              />
            </section>
          </div>
          <!-- Additional details -->
          <div class="additional-information">
            <div
              class="info-header"
              @click="isAdditionalInfoToggled = !isAdditionalInfoToggled"
            >
              <span class="--title"
                >Additional information
                <span class="--info-label">(&nbsp; Optional &nbsp;)</span></span
              >
              <i
                v-if="!isAdditionalInfoToggled"
                class="icon-angle-right-regular"
              ></i>
              <i
                v-if="isAdditionalInfoToggled"
                class="icon-angle-down-regular"
              ></i>
            </div>
            <section
              v-if="isAdditionalInfoToggled"
              class="additional-info-body"
            >
              <hlx-input
                v-model:value="
                  invoiceTemplatePayload.sender.businessDetails.additionalDetails
                    .displayName.value
                "
                :pre-val="
                  invoiceTemplatePayload.sender.businessDetails.additionalDetails
                    .displayName.value
                "
                :label-animation="true"
                label-value="Display name"
                type="email"
                required="true"
                display-error="true"
                :clearable="true"
              />
              <div class="form-input-field-wrapper --2way">
                <span class="email-wrapper">
                  <hlx-input
                    v-model:value="
                      invoiceTemplatePayload.sender.businessDetails
                        .additionalDetails.email.value
                    "
                    :pre-val="
                      invoiceTemplatePayload.sender.businessDetails
                        .additionalDetails.email.value
                    "
                    :label-animation="true"
                    label-value="Email"
                    type="text"
                    required="true"
                    :clearable="true"
                  />
                  <hlx-input
                    :checked="false"
                    label-value="Show email in invoice"
                    :type="'single-checkbox'"
                  />
                </span>
                <span class="phone-number-wrapper">
                  <hlx-input
                    v-model:value="
                      invoiceTemplatePayload.sender.businessDetails
                        .additionalDetails.phoneNumber.value
                    "
                    :pre-val="
                      invoiceTemplatePayload.sender.businessDetails
                        .additionalDetails.phoneNumber.value.value
                    "
                    type="phonenumber"
                    :country-code="
                      invoiceTemplatePayload.sender.businessDetails
                        .additionalDetails.phoneNumber.countryCode.value
                    "
                    :label-value="'Phone no.'"
                    :label-animation="true"
                    :display-error="isPhoneNumberValidationFailed"
                    :custom-error="isPhoneNumberValidationFailed"
                    :custom-error-message="'Please fill the field'"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                  <hlx-input
                    :checked="false"
                    label-value="Show phone number in invoice"
                    :type="'single-checkbox'"
                  />
                </span>
              </div>
            </section>
          </div>
        </section>
      </template>
      <template #footer>
        <hlx-button class="primary sm" @click="buildAfterGivenDate"
          >Save</hlx-button
        >
      </template>
    </hlx-modal>

    <!-- client company -->
    <hlx-modal
      :modal-active="isNewClient"
      :height="'700px'"
      :width="'750px'"
      :modal="true"
      @close="isNewClient = false"
    >
      <template #header>
        <div>Add new Client</div>
      </template>
      <template #content>
        <section class="business-detail-container">
          <div class="basic-business-info-container">
            <div
              class="info-header"
              @click="isBasicInfoToggled = !isBasicInfoToggled"
            >
              <span class="--title">Basic information</span>
              <i
                v-if="!isBasicInfoToggled"
                class="icon-angle-right-regular"
              ></i>
              <i v-if="isBasicInfoToggled" class="icon-angle-down-regular"></i>
            </div>
            <section v-if="isBasicInfoToggled" class="basic-business-info-body">
              <span class="profile-upload">
                <hlx-file-upload
                  :file-type="'.jpg,.jpeg,.png'"
                  :file-size-limit="'20mb'"
                  :uploadtype="'Rectangle'"
                  :style-background="backgrounStyleupload"
                  :height="100"
                  :width="100"
                  :read-only="false"
                  :custom-icon="icon"
                  :type="'profile-upload'"
                  :name-display="
                    invoiceTemplatePayload.sender.businessDetails.businessName
                      .value
                  "
                  :content="'Import'"
                />
              </span>
              <hlx-input
                v-model:value="
                  invoiceTemplatePayload.sender.businessDetails.businessName.value
                "
                :pre-val="
                  invoiceTemplatePayload.sender.businessDetails.businessName.value
                "
                :label-animation="true"
                label-value="Business name"
                type="text"
                required="true"
                :clearable="true"
              />
              <hlx-select
                v-model:value="
                  invoiceTemplatePayload.sender.businessDetails.country['value']
                "
                required="true"
                :options="countryList"
                :placeholder-value="'Country'"
                :label-animation="true"
                :label="'name'"
                :prop-value="'value'"
                :clearable="true"
                @custom-change="returnOptions"
                @selected-value="returnSelectedObject"
              >
              </hlx-select>
              <hlx-input
                v-model:value="
                  invoiceTemplatePayload.sender.businessDetails.city.value
                "
                :pre-val="
                  invoiceTemplatePayload.sender.businessDetails.city.value
                "
                :label-animation="true"
                label-value="City"
                type="text"
                :clearable="true"
              />
            </section>
          </div>
          <!-- Tax -->
          <div class="tax-information">
            <div
              class="info-header"
              @click="isTaxInfoToggled = !isTaxInfoToggled"
            >
              <span class="--title"
                >Tax information
                <span class="--info-label">(&nbsp; Optional &nbsp;)</span></span
              >
              <i v-if="!isTaxInfoToggled" class="icon-angle-right-regular"></i>
              <i v-if="isTaxInfoToggled" class="icon-angle-down-regular"></i>
            </div>
            <section v-if="isTaxInfoToggled" class="tax-info-body">
              <div class="form-input-field-wrapper --2way">
                <hlx-input
                  v-model:value="
                    invoiceTemplatePayload.sender.businessDetails.businessGSTIN
                      .value
                  "
                  :pre-val="
                    invoiceTemplatePayload.sender.businessDetails.businessGSTIN
                      .value
                  "
                  :label-animation="true"
                  label-value="Business GSTIN"
                  type="text"
                  :clearable="true"
                /><hlx-input
                  v-model:value="
                    invoiceTemplatePayload.sender.businessDetails
                      .businessPANNumber.value
                  "
                  :pre-val="
                    invoiceTemplatePayload.sender.businessDetails
                      .businessPANNumber.value
                  "
                  :label-animation="true"
                  label-value="Business PAN number"
                  type="text"
                  :clearable="true"
                />
              </div>
              <div class="vendor-type-panel">
                <p class="--title --normal">Vendor type</p>
                <span class="vendor-radio-group">
                  <hlx-input
                    v-model:value="
                      invoiceTemplatePayload.sender.businessDetails.vendorType
                    "
                    :checked="
                      invoiceTemplatePayload.sender.businessDetails.vendorType
                    "
                    label-value="Individual"
                    :type="'single-radio'"
                    :name="'vendortype'"
                  />
                  <hlx-input
                    v-model:value="
                      invoiceTemplatePayload.sender.businessDetails.vendorType
                    "
                    :checked="
                      invoiceTemplatePayload.sender.businessDetails.vendorType
                    "
                    label-value="Company"
                    :type="'single-radio'"
                    :name="'vendortype'"
                  />
                </span>
              </div>
              <hlx-select
                v-model:value="
                  invoiceTemplatePayload.sender.businessDetails.taxTreatment[
                    'value'
                  ]
                "
                :options="taxTreatmentList"
                :placeholder-value="'Tax treatment'"
                :label-animation="true"
                :label="'name'"
                :prop-value="'value'"
                :clearable="true"
                @custom-change="returnOptions"
                @selected-value="returnSelectedObject"
              >
              </hlx-select>
            </section>
          </div>

          <!-- Address -->
          <div class="address-information">
            <div
              class="info-header"
              @click="isAddressInfoToggled = !isAddressInfoToggled"
            >
              <span class="--title"
                >Address information
                <span class="--info-label">(&nbsp; Optional &nbsp;)</span></span
              >
              <i
                v-if="!isAddressInfoToggled"
                class="icon-angle-right-regular"
              ></i>
              <i
                v-if="isAddressInfoToggled"
                class="icon-angle-down-regular"
              ></i>
            </div>
            <section v-if="isAddressInfoToggled" class="address-info-body">
              <addressComponent
                :pre-val="
                  invoiceTemplatePayload.sender.businessDetails.address.value
                "
                @address-data="receivedAddressData"
              />
            </section>
          </div>
          <!-- Additional details -->
          <div class="additional-information">
            <div
              class="info-header"
              @click="isAdditionalInfoToggled = !isAdditionalInfoToggled"
            >
              <span class="--title"
                >Additional information
                <span class="--info-label">(&nbsp; Optional &nbsp;)</span></span
              >
              <i
                v-if="!isAdditionalInfoToggled"
                class="icon-angle-right-regular"
              ></i>
              <i
                v-if="isAdditionalInfoToggled"
                class="icon-angle-down-regular"
              ></i>
            </div>
            <section
              v-if="isAdditionalInfoToggled"
              class="additional-info-body"
            >
              <hlx-input
                v-model:value="
                  invoiceTemplatePayload.sender.businessDetails.additionalDetails
                    .displayName.value
                "
                :pre-val="
                  invoiceTemplatePayload.sender.businessDetails.additionalDetails
                    .displayName.value
                "
                :label-animation="true"
                label-value="Display name"
                type="email"
                required="true"
                display-error="true"
                :clearable="true"
              />
              <div class="form-input-field-wrapper --2way">
                <span class="email-wrapper">
                  <hlx-input
                    v-model:value="
                      invoiceTemplatePayload.sender.businessDetails
                        .additionalDetails.email.value
                    "
                    :pre-val="
                      invoiceTemplatePayload.sender.businessDetails
                        .additionalDetails.email.value
                    "
                    :label-animation="true"
                    label-value="Email"
                    type="text"
                    required="true"
                    :clearable="true"
                  />
                  <hlx-input
                    :checked="false"
                    label-value="Show email in invoice"
                    :type="'single-checkbox'"
                  />
                </span>
                <span class="phone-number-wrapper">
                  <hlx-input
                    v-model:value="
                      invoiceTemplatePayload.sender.businessDetails
                        .additionalDetails.phoneNumber.value
                    "
                    :pre-val="
                      invoiceTemplatePayload.sender.businessDetails
                        .additionalDetails.phoneNumber.value
                    "
                    type="phonenumber"
                    :country-code="
                      invoiceTemplatePayload.sender.businessDetails
                        .additionalDetails.phoneNumber.countryCode.value
                    "
                    :label-value="'Phone no.'"
                    :label-animation="true"
                    :display-error="true"
                    :custom-error="customnameerror"
                    :custom-error-message="'Please fill the field'"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                  <hlx-input
                    :checked="false"
                    label-value="Show phone number in invoice"
                    :type="'single-checkbox'"
                  />
                </span>
              </div>
            </section>
          </div>
        </section>
      </template>
      <template #footer>
        <hlx-button class="primary sm" @click="buildAfterGivenDate"
          >Save</hlx-button
        >
      </template>
    </hlx-modal>
  </main>

  <!-- @Discard Modal window -->
  <!-- DiscardComponent -->
  <discardComponent
    :show="enableDiscardModal"
    @stay="handleStay"
    @close="handleDiscardClose"
    @discard="handleDiscard"
  >
  </discardComponent>
  <!-- <previewTemplate :show-version-history="showVersion"></previewTemplate> -->
</template>
<script>
import draggable from "vuedraggable";
import quoteWorkflowSidebarComponent from "@/components/UPC/CPQ/quoteWorkflowSidebarComponent.vue";
import { mapActions } from "vuex";
// import addressComponent from "../../../components/CM/autocompleteComponent.vue";
// import previewTemplate from './previewTemplate.vue';
import discardComponent from "@/components/discardComponent.vue";
import addressComponent from "@/components/CM/autocompleteComponent.vue";
import signatureComponent from "@/components/UPC/CPQ/signatureComponent.vue";
// import multipleSignature from "@/components/UPC/CPQ/multipleSignatureComponent.vue";
import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";

import FilterComponent from "../../components/filterPanelComponent.vue";

import pricingTableComponent from "@/components/UPC/CPQ/pricingTableComponent.vue";
import configComponent from "@/views/CPQ_Test/configurationTestView.vue";
import depConfigComponent from "@/views/CPQ_Test/dependentFlowPreview.vue";
import { toRaw } from "vue";
import activityLogComponent from "@/components/SM/activityLogComponent.vue";

// import axios from "axios";
export default {
  /** */

  name: "DefaultTemplate",

  components: {
    draggable,
    activityLogComponent,
    quoteWorkflowSidebarComponent,
    discardComponent,
    addressComponent,
    signatureComponent,
    pricingTableComponent,
    configComponent,
    depConfigComponent,
    FilterComponent,
    // multipleSignature,
  },
//   beforeRouteLeave(to, from, next) { // comand by asif
//     if (this.discardIt) {
//       // this.discardIt will be handled on discard modal window discard changes emit
//       next(true);
//       return;
//     }
//     // Check for unsaved changes
//     /**
//      * Param 1 initial payload to check against param 2
//      * Param 2 updated payload to check against param 1
//      */
//     const unsavedChanges = this.isPayloadChanged(
//       this.initialData,
//       this.invoiceTemplatePayload
//     ); // This isPayloadChanged method was configured in global methods so we can call it anywhere in Vue. It finds a difference between both data and returns true if any changes; otherwise, returns false
//     console.log("🚀 ~ beforeRouteLeave ~ unsavedChanges:", unsavedChanges);

//     const unsavedChangesInPricingTable = this.isPayloadChanged(
//       this.initialPricingTableData,
//       this.quotePricingData
//     );
//     console.log(unsavedChangesInPricingTable, "unsavedChangesInPricingTable");
//     // If unsaved changes found then display discard modal
//     if (unsavedChanges) {
//       this.enableDiscardModal = true;
//     }
//     if (unsavedChangesInPricingTable) {
//       this.enableDiscardModal = true;
//     }
//     // Then route as per validation
//     next(!unsavedChangesInPricingTable && !unsavedChanges);
//     return;
// }
// ,

  props: {},
  emits: [],

  data() {
    return {
      delete_modal_active:false,
      isPreviewClicked:false,
      refreshProfile:0,
      refreshProfile1:0,
      statusList1:[
    {
        "_id": "001",
        "name": "On hold",
        "value": "On hold",
        "color": "rgba(38, 94, 75, 1)",
        "__v": 0
    },
    {
        "_id": "002",
        "name": "Validated",
        "value": "Validated",
        "color": "#663300",
        "__v": 0
    },
    {
        "_id": "003",
        "name": "Sent",
        "value": "Sent",
        "color": "#E4E456",
        "__v": 0
    },
    {
        "_id": "004",
        "name": "Partially paid",
        "value": "Partially paid",
        "color": "rgba(249, 166, 69, 1)",
        "__v": 0
    },
    {
        "_id": "005",
        "name": "Settled",
        "value": "Settled",
        "color": "rgba(2, 181, 92, 1)",
        "__v": 0
    }
],
      invoicePayload:{
    "id": "",
    "href": "https://server:port/tmfapi/Customer_Bill_Management/v5/customerBill/CB-123",
    "billNo": "780123456",
    "amountDue": {
        "quoteTotalPrice": [
            {
                "name": "One-time charges",
                "priceType": "Onetime",
                "price": {
                    "dutyFreeAmount": {
                        "unit": "GBP",
                        "value": 1
                    },
                    "taxIncludedAmount": {
                        "unit": "GBP",
                        "value": 1
                    }
                },
                "priceAlteration": [],
                "quoteTotal": {
                    "unit": "GBP",
                    "value": 1.12
                }
            },
            {
                "name": "Recurring charges",
                "priceType": "Recurring",
                "price": {
                    "dutyFreeAmount": {
                        "unit": "GBP",
                        "value": 0
                    },
                    "taxIncludedAmount": {
                        "unit": "GBP",
                        "value": 0
                    }
                },
                "priceAlteration": [],
                "quoteTotal": {
                    "unit": "GBP",
                    "value": 0
                }
            },
            {
                "name": "Total contract value",
                "@type": "totalContractValue",
                "price": {
                    "unit": "GBP",
                    "value": 1.12
                }
            }
        ],
        "quotePrice": {
            "price": {
                "dutyFreeAmount": {
                    "unit": "GBP",
                    "value": 1
                },
                "taxIncludedAmount": {
                    "unit": "GBP",
                    "value": 1
                }
            },
            "quoteTotal": {
                "unit": "GBP",
                "value": 1
            },
            "savings": {
                "unit": "GBP",
                "value": 0
            },
            "totalContractValue": {
                "unit": "GBP",
                "value": 1.12
            },
            "additionalCharges": [],
            "summary": {
                "showOneTimeSummary": true,
                "showRecurringSummary": false,
                "priceable": true,
                "oneTime": {
                    "subTotal": 1,
                    "tax": 0,
                    "discount": [],
                    "item_wise_discount_onetime": true,
                    "amount": 1,
                    "appliedTax": [
                        {
                            "value": 0.12,
                            "percentage": 12,
                            "taxName": [
                                "GRT"
                            ]
                        }
                    ],
                    "totalAfterTax": 1.12,
                    "totalAfterDiscount": 1.12
                },
                "recurring": {
                    "subTotal": 0,
                    "tax": 0,
                    "discount": [],
                    "item_wise_discount_recurring": true,
                    "amount": 0,
                    "appliedTax": [
                        {
                            "value": 0
                        }
                    ],
                    "totalAfterTax": 0,
                    "totalAfterDiscount": 0
                },
                "tax": [
                    {
                        "taxName": "GRT",
                        "rate": "12",
                        "taxType": "cumulative"
                    }
                ],
                "additionalCharges": [],
                "overAllTotal": 1,
                "amountSaved": 0,
                "quoteTotalCost": 1,
                "quoteMargin": "0.00",
                "overAllTotalAfterTax": 1.12,
                "overAllTotalAfterTaxAfterDiscount": "1.12",
                "totalContractValue": 1.12,
                "totalContractValueAfterDiscount": 1.12
            }
        }
    },
    "paymentRef": [
        {
            "appliedAmount": {
                "unit": "EUR",
                "value": 50.00
            },
            "payment": {
                "id": "PAY-9876",
                "href": "https://server:port/tmfapi/Payment_Management/v5/payment/PAY-9876",
                "name": "csp-bill",
                "@referredtype": "Payment",
                "@type": "PaymentRef"
            },
            "@type": "AppliedPayment"
        }
    ],
    "productRef":[
    {
            "quoteItem": [
                {
                    "section": false,
                    "address": false,
                    "custom": true,
                    "notes": "",
                    "product_offering_name": "New item",
                    "description": "",
                    "unitOfMeasure": "Each",
                    "additionalCharges": [],
                    "@type": "QuoteItem",
                    "productSpecification": {
                        "@type": "ProductSpecification"
                    },
                    "productOffering": {
                        "@type": "ProductOffering"
                    },
                    "action": "new",
                    "quantity": 1,
                    "duration": 1,
                    "billing_period": "One time",
                    "discount": {
                        "value": 0,
                        "type": "%"
                    },
                    "state": "",
                    "product": {
                        "@type": "product",
                        "productSpecification": {
                            "@type": "ProductSpecification"
                        },
                        "productOffering": {
                            "@type": "ProductOffering"
                        }
                    },
                    "quoteItemPrice": [
                        {
                            "description": "",
                            "name": "",
                            "priceType": "One time",
                            "recurringChargePeriodLength": 0,
                            "recurringChargePeriod": "One time",
                            "price": {
                                "taxRate": 0,
                                "dutyFreeAmount": {
                                    "unit": "GBP",
                                    "value": 1
                                },
                                "taxIncludedAmount": {
                                    "unit": "GBP",
                                    "value": 1
                                }
                            }
                        }
                    ],
                    "id": "1"
                }
            ]
        }
    ],
    "billCycleRef": {
        "id": "BC-1234",
        "href": "http://server:port/tmfapi/Customer_Bill_Management/v5/billcycle/BC-1234",
        "@referredType": "BillCycle",
        "@type": "BillCycleRef"
    },
    "billDate": new Date().toJSON(),
    "attachmentRef": [],
    "billingAccountRef": {
        "id": "A0815",
        "href": "http://host:port/tmfapi/Account_Management/v5/billingAccount/A0815",
        "name": "BA Peter Retep",
        "@referredType": "BillingAccount",
        "@type": "BillingAccountRef"
    },
    "financialAccountRef": {
        "id": "FA12345",
        "href": "http://host:port/tmfapi/Account_Management/v5/financialAccount/FA12345",
        "name": "FA Peter Retep",
        "@referredType": "FinancialAccount",
        "@type": "FinancialAccountRef"
    },
    "category": "normal",
    "lastUpdate": "2020-01-05T12:00:56.982Z",
    "nextBillDate": "2020-02-05T12:00:00.000Z",
    "paymentDueDate": "2020-01-15T00:00:00.000Z",
    "remainingAmount": {
        "unit": "EUR",
        "value": 0.00
    },
    "runType": "onCycle",
    "state": "",
    "taxExcludedAmount": {},
    "taxIncludedAmount": {},
    "taxItem": [
        {
            "taxAmount": {
                "unit": "EUR",
                "value": 10.00
            },
            "taxCategory": "VAT",
            "taxRate": 0.25,
            "@type": "TaxItem"
        }
    ],
    "@type": "CustomerBill"
},
productOfferingItem:[],
productOfferingSummary:{},
      initialRecipientsList:[],
      addresscomponetshipping: 0,
      checkboxShipping: false,
      shippingOptions: [],
      backgrounStyleuploadQuoteRecipient: {
        backgroundColor: "",
        bacgroundtTextColor: "#000",
        borderColor: "#A8A8A8",
        TextfontSize: 14,
      },
      propsForMultiSignComponent: [],
      isDepFlow: false,
      dateref: 0,
      holidays: [],
      holidays1: ["1-1-2024", "24-10-2024", "25-10-2024"],
      fullYear: 0,
      addContactKey: 0,
      statusContact: [],
      showDraggableFunctionQuoteRecepient: false,
      quoteDataLoaded: false,
      recipientSearchPlaceHolder: "",
      accountPreviewInContact: "",
      draftStatus: "On hold",
      signaturePlacePreVal: {},
      signaturePlaced: {},
      assignedColors: {},
      showWorkFlow: false,
      showVersion: false,
      modall: false,
      wen: null,
      recipientSelectKey:0,
      recipientKey: 0,
      recipientsList: [],
      retiredvmodel: false,
      showRecipientDrawer: false,
      retiredProduct: false,
      show_side_panel: false,
      enableButton: false,
      designationList: [],
      activityLogData: [],
      canAddContact: false,
      companyCon: "",
      organizationList: [],
      componentkey: 0,
      activitytotal: 0,
      refreshSelect: 0,
      addressKey: 0,
      selectedHierarchyAccount: "",
      showAddNewContactModal: false,
      newConPayload: {
        name: "",
        birthDate: "",
        firstName: "",
        lastName: "",
        title: "",
        tradingName: "",
        companyName: "",
        status: "Pending",
        preferred: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        relatedParty: [],
        tags: [],
        aristocraticTitle: "",
        role: "",
        type: "individual",
        "@baseType": "customer",
        notes: [],
        attachment: [],
        password: "",
        email: "",
      },
      showStatus: "",
      tagsList: [],
      preferredMediumList: [],
      marketSegmentList: [],
      companyNew: "",
      executivesList: [],
      industryTypeList: [],
      locationTypeList: [],
      employeeCountList: [],
      filteredstatus: [],
      modalKeyAccount: 0,
      accExecParty: "",
      canAdd: false,
      // companyCM: "",
      locationType: "",
      isUrlEmpty: false,
      showAddNewAccountModal: false,
      newAccPayload: {
        name: '',
        customName: '',
        parentCompany: '',
        tradingName: '',
        status: 'Active',
        preferred: '',
        partyCharacteristic: [
          {
            name: 'number of employees',
            value: '',
          },
          {
            name: 'Industry',
            value: '',
          },
          {
            name: 'market segment',
            value: '',
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: 'email',
            characteristic: {
              emailAddress: '',
            },
          },
          {
            mediumType: 'phone',
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: '',
                valid: true,
              },
            },
          },
          {
            mediumType: 'fax',
            characteristic: {
              faxNumber: '',
            },
          },
          {
            mediumType: 'postalAddress',
            characteristic: {
              city: '',
              country: '',
              postCode: '',
              stateOrProvince: '',
              street1: '',
              street2: '',
              description: '',
              address1: '',
              default: true,
              '@type': '',
            },
          },
        ],
        externalReference: [
          {
            name: '',
            externalIdentifierType: 'internetSite',
          },
        ],
        relatedParty: [],
        tags: [],
        role: 'Root admin',
        type: 'company',
        password: '',
        email: '',
        attachment: [],
        organizationParentRelationship: {},
        rootName: '',
      },
      activtykey: 0,
      newAccPayloadCopy: {
        name: "",
        tradingName: "",
        status: "Active",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "Industry",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              address1: "",
              default: true,
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        organizationParentRelationship: {},
        rootName: "",
        parentCompany: "",
      },
      duplicateAttachmentValue: [],
      status: "",
      statusBool: false,
      statusList: [],
      recipientTaxes: [],
      preValueAttachments: [],
      cartItems: [],
      quoteVersion: 0,
      filterComponentKey: 0,
      tabletMode: false,
      filterPrevalue: {},
      filterPrevalueDuplicate1: {},
      filterPrevalueDuplicate2: {},
      filtertagvalue: 0,
      show_right_filter: false,
      show_right: false,
      filtertaglength: 0,
      filterQuery: {
        type: "filter",
        module: "discountOffers",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: "",
      },
      searchvalue: "",
      displayAlternate: [{ name: "Product offering price", display: "number" }],
      query: {
        Status: ["lifecycleStatus"],
        Place: ["place.name"],
        Channel: ["channel.name"],
        "Market segment": ["marketSegment.name"],
        "Product offering term": ["productOfferingTerm.name"],
        "Product offering price": [
          "productOfferingPrice.productPrice.dutyFreeAmount",
        ],
        Category: ["category"],
      },
      displayFilterData: {},
      tempQuoteSummaryDataFromRules: {
        showOneTimeSummary: false,
        showRecurringSummary: false,
        oneTime: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_onetime: false,
        },
        recurring: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_recurring: false,
        },
        overAllTotal: 0,
        amountSaved: 0,
      },
      quoteSummaryDataFromRules: {
        showOneTimeSummary: false,
        showRecurringSummary: false,
        oneTime: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_onetime: false,
        },
        recurring: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_recurring: false,
        },
        overAllTotal: 0,
        amountSaved: 0,
      },

      card_data_length: 0,
      isValidQuoteItem: false,
      showView: false,
      isValidQuoteToSave: false,
      initialPricingTableData: [],
      initialQuoteAuthorizarionData:[],
      initialData: {
        state: "On hold",
        header: {
          title: {
            value: "Invoice",
            label: "Invoice",
            readOnly: true,
          },
          subtitle: { value: "", label: "Sub title", readOnly: true },
          isQuoteSubtitle: false,
        },
        quoteBasicInfo: {
          quoteNumberLabel: {
            value: "Invoice no",
            label: "Invoice number",
            readOnly: true,
          },
          quoteNumber: { value: "", label: "QOT-9XX9", readOnly: true },
          quoteDateLabel: {
            value: "Invoice date",
            label: "Invoice date",
            readOnly: true,
          },
          quoteDate: {
            value: new Date().toJSON(),
            label: "Date",
            readOnly: true,
          },
          isQuoteValidTillDate: false,
          quoteValidTillDateLabel: {
            value: "Next bill date",
            label: "Next bill date",
            readOnly: true,
          },
          validTillDateSetting: {
            enabled: false,
            unit: "day",
            value: 15,
            // date:new Date(),
          },
          quoteValidTillDate: {
            value: new Date().toJSON(),
            label: "DD/MM/YYYY",
            readOnly: true,
          },
          additionalFields: [],
          // quoteNumberLabel:"Quote NO",
          // quoteNumber:"MCF-1242",
        },
        sender: {
          title: {
            value: "Invoice from",
            label: "Invoice from",
            readOnly: true,
          },
          list: {
            value: [
              { name: "Albert", value: "Albert Ind" },
              { name: "john doe", value: "Deccoy" },
            ],
            label: "A",
            readOnly: true,
          },
          businessDetails: {
            businessName: {
              readOnly: false,
              value: "",
              label: "Vendor's business name",
            },
            id: {
              readOnly: false,
              value: "",
              label: "",
            },
            country: {
              value: "India",
              label: "Vendor's business name",
            },
            city: {
              value: "",
              label: "City",
            },
            email: {
              value: "",
              label: "Email",
            },
            businessGSTIN: {
              value: "",
              label: "Vendor's business name",
            },
            businessPANNumber: {
              value: "",
              label: "Vendor's business name",
            },
            vendorType: {
              value: "",
              label: "Vendor's business name",
            },
            taxTreatment: {
              value: "",
              label: "Vendor's business name",
            },

            address: {
              value: {
                characteristic: {
                  "@type": "",
                  address1: "",
                  city: "",
                  country: "",
                  description: "",
                  postCode: "",
                  postalCode: "",
                  state: "",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  timezone: "",
                  unit: "",
                },

                mediumType: "postalAddress",
              },
              label: "Vendor's business name",
            },
            additionalDetails: {
              displayName: {
                value: "",
                label: "Vendor's business name",
              },
              email: {
                value: "",
                label: "Vendor's business name",
              },
              phoneNumber: {
                value: { country: "91", value: "78", valid: false },
                countryCode: { value: "91" },
                label: "Vendor's business name",
              },
              showEmail: {
                value: false,
                label: "Vendor's business name",
              },
              showPhoneNumber: {
                value: false,
                countryCode: { value: "91" },
                label: "Vendor's business name",
              },
            },
          },
          quoteDate: { value: "12/12/2023", label: "Date", readOnly: true },
          isQuoteValidTillDate: false,
          quoteValidTillDateLabel: {
            value: "Next bill date",
            label: "Next bill date",
            readOnly: true,
          },
          quoteValidTillDate: {
            value: "12/12/2023",
            label: "DD/MM/YYYY",
            readOnly: true,
          },
          additionalFields: [],
          // quoteNumberLabel:"Quote NO",
          // quoteNumber:"MCF-1242",
        },
        recipient: {
          title: {
            value: "Invoice to",
            label: "Invoice to",
            readOnly: true,
          },

          list: {
            value: [
              { name: "Albert", value: "Albert Ind" },
              { name: "john doe", value: "Deccoy" },
            ],
            label: "A",
            readOnly: true,
          },
          businessDetails: {
            businessName: {
              readOnly: false,
              value: "",
              label: "Vendor's business name",
            },
            id: {
              readOnly: false,
              value: "",
              label: "",
            },
            email: {
              value: "",
              label: "Email",
            },
            logo: {
              readOnly: false,
              value: {},
              label: "Vendor's business name",
            },

            country: {
              value: "India",
              label: "Vendor's business name",
            },
            city: {
              value: "",
              label: "City",
            },
            businessGSTIN: {
              value: "",
              label: "Vendor's business name",
            },
            businessPANNumber: {
              value: "",
              label: "Vendor's business name",
            },
            vendorType: {
              value: "",
              label: "Vendor's business name",
            },
            taxTreatment: {
              value: "",
              label: "Vendor's business name",
            },

            address: {
              value: {
                characteristic: {
                  "@type": "",
                  address1: "",
                  city: "",
                  country: "",
                  description: "",
                  postCode: "",
                  postalCode: "",
                  state: "",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  timezone: "",
                  unit: "",
                },

                mediumType: "postalAddress",
              },
              label: "Vendor's business name",
            },
            additionalDetails: {
              displayName: {
                value: "",
                label: "Vendor's business name",
              },
              email: {
                value: "",
                label: "Vendor's business name",
              },
              phoneNumber: {
                value: "",
                countryCode: { value: "91" },
                label: "Vendor's business name",
              },
              showEmail: {
                value: false,
                label: "Vendor's business name",
              },
              showPhoneNumber: {
                value: false,
                countryCode: {
                  value: {
                    name: "india",
                    dial_code: "+91",
                    flags: "",
                    short: "In",
                  },
                },
                label: "Vendor's business name",
              },
            },
          },
          quoteDate: { value: "12/12/2023", label: "Date", readOnly: true },
          isQuoteValidTillDate: false,
          quoteValidTillDateLabel: {
            value: "Next bill date",
            label: "Next bill date",
            readOnly: true,
          },
          quoteValidTillDate: {
            value: "12/12/2023",
            label: "DD/MM/YYYY",
            readOnly: true,
          },
          additionalFields: [],
          // quoteNumberLabel:"Quote NO",
          // quoteNumber:"MCF-1242",
        },
        senderContact: {
          title: {
            value: "Invoice contact",
            label: "Invoice contact",
            readOnly: true,
          },
          list: {
            value: [
              { name: "Jemy", value: "Jemy" },
              { name: "john nill", value: "john" },
            ],
            label: "A",
            readOnly: true,
          },
          contactDetails: {
            // firstName:
            name: {
              value: "",
              readOnly: true,
              label: "",
            },
            id: {
              readOnly: false,
              value: "",
              label: "",
            },
            email: {
              value: "",
              readOnly: true,
              label: "",
            },
          },

          quoteDate: { value: "12/12/2023", label: "Date", readOnly: true },
          isQuoteValidTillDate: false,
          quoteValidTillDateLabel: {
            value: "Next bill date",
            label: "Next bill date",
            readOnly: true,
          },
          quoteValidTillDate: {
            value: "12/12/2023",
            label: "DD/MM/YYYY",
            readOnly: true,
          },
          additionalFields: [],
          // quoteNumberLabel:"Quote NO",
          // quoteNumber:"MCF-1242",
        },
        recipientContact: {
          title: {
            value: "Client contact",
            label: "Client contact",
            readOnly: true,
          },
          list: {
            value: [
              { name: "Jemy", value: "Jemy" },
              { name: "john nill", value: "john" },
            ],
            label: "A",
            readOnly: true,
          },
          contactDetails: {
            // firstName:
            name: {
              value: "",
              readOnly: true,
              label: "",
            },
            id: {
              readOnly: false,
              value: "",
              label: "",
            },
            email: {
              value: "",
              readOnly: true,
              label: "",
            },
          },
          businessDetails: {
            businessName: {
              readOnly: false,
              value: "Deccoy",
              label: "Vendor's business name",
            },
            country: {
              value: "India",
              label: "Vendor's business name",
            },
            city: {
              value: "",
              label: "City",
            },
            businessGSTIN: {
              value: "",
              label: "Vendor's business name",
            },
            businessPANNumber: {
              value: "",
              label: "Vendor's business name",
            },
            vendorType: {
              value: "",
              label: "Vendor's business name",
            },
            taxTreatment: {
              value: "",
              label: "Vendor's business name",
            },

            address: {
              value: {
                characteristic: {
                  "@type": "",
                  address1: "",
                  city: "",
                  country: "",
                  description: "",
                  postCode: "",
                  postalCode: "",
                  state: "",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  timezone: "",
                  unit: "",
                },

                mediumType: "postalAddress",
              },
              label: "Vendor's business name",
            },
            additionalDetails: {
              displayName: {
                value: "",
                label: "Vendor's business name",
              },
              email: {
                value: "",
                label: "Vendor's business name",
              },
              phoneNumber: {
                value: { country: "91", value: "78", valid: false },
                countryCode: { value: "91" },
                label: "Vendor's business name",
              },
              showEmail: {
                value: false,
                label: "Vendor's business name",
              },
              showPhoneNumber: {
                value: false,
                countryCode: { value: "91" },
                label: "Vendor's business name",
              },
            },
          },
          quoteDate: { value: "12/12/2023", label: "Date", readOnly: true },
          isQuoteValidTillDate: false,
          quoteValidTillDateLabel: {
            value: "Next bill date",
            label: "Next bill date",
            readOnly: true,
          },
          quoteValidTillDate: {
            value: new Date().toISOString(),
            label: "DD/MM/YYYY",
            readOnly: true,
          },
          additionalFields: [],
          // quoteNumberLabel:"Quote NO",
          // quoteNumber:"MCF-1242",
        },
        isShippingDetails: {
          value: false,
          label: "Show shipping & billing details",
        },

        recipientShippingDetails: {
          title: {
            value: "Shipping to",
            label: "Shipping to",
            readOnly: true,
          },
          address: {
            value: {
              characteristic: {
                "@type": "",
                address1: "",
                city: "",
                country: "",
                description: "",
                postCode: "",
                postalCode: "",
                state: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                timezone: "",
                unit: "",
              },

              mediumType: "postalAddress",
            },
            label: "Vendor's business name",
            readOnly: true,
          },
          isBusinessAddress: {
            value: false,
            readOnly: true,
            label: "Same as your client address",
          },

          shippingNotes: {
            value: "",
            readOnly: true,
            label: "Notes",
          },
        },
        recipientBillingDetails: {
          title: {
            value: "Billing address",
            label: "Billing address",
            readOnly: true,
          },
          address: {
            value: {
              characteristic: {
                "@type": "",
                address1: "",
                city: "",
                country: "",
                description: "",
                postCode: "",
                postalCode: "",
                state: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                timezone: "",
                unit: "",
              },

              mediumType: "postalAddress",
            },
            label: "Vendor's business name",
            readOnly: true,
          },
          isBusinessAddress: {
            value: false,
            readOnly: true,
            label: "Same as your client address",
          },

          shippingNotes: {
            value: "",
            readOnly: true,
            label: "Notes",
          },
        },
        recipientTermsandConditions: {
          conditions: [
            {
              additionalFields: [],
              label: "Add terms and conditions",
              readOnly: true,
            },
          ],
        },
        recipientNotes: {
          label: "Add notes",
          readOnly: true,
          value: "",
        },
        recipientAttachments: {
          label: "Add attachments",
          readOnly: true,
          value: [],
        },
        recipientAdditionalInfo: {
          additionalFields: [],
          readOnly: true,
          label: "Add additional info",
        },

        fromSignature: {
          label: "Manager signature",
          value: null,
          readOnly: true,
        },
        toSignature: {
          label: "Client signature",
          value: null,
          readOnly: true,
        },
      },
      enableDiscardModal: false,
      discardIt: false,
      configOfferId: "",
      show_configuration: false,
      imageComponentKey: 0,
      notes1: "",
      notification: {},
      hide: "false",
      profilePicturePrevalue: "",
      profileFormData: null,
      quoteData: null,
      client: null,
      clientUserId: null,
      clientContact: null,
      isValidQuote: false,
      senderCompleteInfo: null,
      recipientCompleteInfo: null,
      recipientContactCompleteInfo: null,
      senderContactCompleteInfo: null,
      senderSearchKey: "",
      senderContactSearchKey: "",
      recipientContactSearchKey: "",
      recipientSearchKey: "",
      shippingAddressSearchKey: "",
      billingAddressSearchKey: "",
      isValidSenderCompany: null,
      senderCompanyValidationMessage:
        "Kindly select a company to proceed with quote",
      isValidSenderCompanyContact: null,
      senderCompanyContactValidationMessage:
        "Kindly select a company contact to proceed with quote",
      isValidRecipientCompany: null,
      recipientCompanyValidationMessage:
        "Kindly select a client company to proceed with quote",
      isValidRecipientCompanyContact: null,
      recipientCompanyContactValidationMessage:
        "Kindly select a client company contact to proceed with quote",
      senderCompanyList: [],
      externalRowsPerPage: 15,
      grid_view: true,
      // car d_data: [],
      card_data: [],
      changePos: false,
      termsandConditionsPayload: [
        { name: "Work will resume after advance payment.", id: 1 },
        { name: "Applicable taxes will be extra.", id: 2 },
        { name: "Work will resume after no advance payment.", id: 3 },
      ],
      terms: false,
      notes: false,
      isBasicSection: true,
      isSenderAndClientSection: true,
      isShippingAndBillingSection: false,
      isAdditionalInfoSection: false,
      isProductAndPriceSection: true,
      attachments: false,
      recipientSearchValue: "",
      additional: false,
      input: "1.Applicable taxes will be extra",
      addSpecificFieldsArrayValue: [],
      addSpecificFieldsArray: [],
      termsAndPolicyAreaData: [
        { name: "Add terms and conditions", checked: false },
        { name: "Add notes", checked: false },
        // { name: "Add attachments", checked: false },
        { name: "Add additional info", checked: false },
      ],
      profilePicture: null,
      noNeed: false,
      inDoubt: false,
      recipientSiteAddresses: [],
      recipientBillingAddresses: [],
      inFuture: false,
      isBasicInfoToggled: false,
      isPhoneNumberValidationFailed: false,
      isTaxInfoToggled: false,
      isAddressInfoToggled: false,
      isAdditionalInfoToggled: false,
      additionalFieldTemplate: {
        label: "",
        value: "",
      },
      senderCompanyContacts: [],
      senderCompanyContacts1: [],
      recipientContacts: [],
      recipientLists: [],
      editCompany: false,
      isNewClient: false,
      updateClient: false,
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 16,
      },
      countryList: [],
      recipientAddressLists: [],
      invoiceTemplatePayload: {
        state: "On hold",
        header: {
          title: {
            value: "Invoice",
            label: "Invoice",
            readOnly: true,
          },
          subtitle: { value: "", label: "Sub title", readOnly: true },
          isQuoteSubtitle: false,
        },
        quoteBasicInfo: {
          quoteNumberLabel: {
            value: "Invoice no",
            label: "Invoice number",
            readOnly: true,
          },
          quoteNumber: { value: "", label: "", readOnly: true },
          quoteDateLabel: {
            value: "Invoice date",
            label: "Invoice date",
            readOnly: true,
          },
          quoteDate: {
            value: new Date().toJSON(),
            label: "Date",
            readOnly: true,
          },
          isQuoteValidTillDate: false,
          quoteValidTillDateLabel: {
            value: "Next bill date",
            label: "Next bill date",
            readOnly: true,
          },
          validTillDateSetting: {
            enabled: false,
            unit: "day",
            value: 15,
            // date:new Date(),
          },
          quoteValidTillDate: {
            value: new Date().toJSON(),
            label: "DD/MM/YYYY",
            readOnly: true,
          },
          additionalFields: [],
          // quoteNumberLabel:"Quote NO",
          // quoteNumber:"MCF-1242",
        },
        sender: {
          title: {
            value: "Invoice from",
            label: "Invoice from",
            readOnly: true,
          },
          list: {
            value: [
              { name: "Albert", value: "Albert Ind" },
              { name: "john doe", value: "Deccoy" },
            ],
            label: "A",
            readOnly: true,
          },
          businessDetails: {
            businessName: {
              readOnly: false,
              value: "",
              label: "Vendor's business name",
            },
            id: {
              readOnly: false,
              value: "",
              label: "",
            },
            country: {
              value: "India",
              label: "Vendor's business name",
            },
            city: {
              value: "",
              label: "City",
            },
            email: {
              value: "",
              label: "Email",
            },
            phone: {
              value: {
                value: "",
                country: {
                  dial_code: "",
                },
              },
              code: "",
              label: "Phone",
            },
            businessGSTIN: {
              value: "",
              label: "Vendor's business name",
            },
            businessPANNumber: {
              value: "",
              label: "Vendor's business name",
            },
            vendorType: {
              value: "",
              label: "Vendor's business name",
            },
            taxTreatment: {
              value: "",
              label: "Vendor's business name",
            },

            address: {
              value: {
                characteristic: {
                  "@type": "",
                  address1: "",
                  city: "",
                  country: "",
                  description: "",
                  postCode: "",
                  postalCode: "",
                  state: "",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  timezone: "",
                  unit: "",
                },

                mediumType: "postalAddress",
              },
              label: "Vendor's business name",
            },
            additionalDetails: {
              displayName: {
                value: "",
                label: "Vendor's business name",
              },
              email: {
                value: "",
                label: "Vendor's business name",
              },
              phoneNumber: {
                value: { country: "91", value: "78", valid: false },
                countryCode: { value: "91" },
                label: "Vendor's business name",
              },
              showEmail: {
                value: false,
                label: "Vendor's business name",
              },
              showPhoneNumber: {
                value: false,
                countryCode: { value: "91" },
                label: "Vendor's business name",
              },
            },
          },
          quoteDate: { value: "12/12/2023", label: "Date", readOnly: true },
          isQuoteValidTillDate: false,
          quoteValidTillDateLabel: {
            value: "Next bill date",
            label: "Next bill date",
            readOnly: true,
          },
          quoteValidTillDate: {
            value: "12/12/2023",
            label: "DD/MM/YYYY",
            readOnly: true,
          },
          additionalFields: [],
          // quoteNumberLabel:"Quote NO",
          // quoteNumber:"MCF-1242",
        },
        recipient: {
          title: {
            value: "Invoice to",
            label: "Invoice to",
            readOnly: true,
          },

          list: {
            value: [
              { name: "Albert", value: "Albert Ind" },
              { name: "john doe", value: "Deccoy" },
            ],
            label: "A",
            readOnly: true,
          },
          businessDetails: {
            businessName: {
              readOnly: false,
              value: "",
              label: "Vendor's business name",
            },
            id: {
              readOnly: false,
              value: "",
              label: "",
            },
            email: {
              value: "",
              label: "Email",
            },
            phone: {
              value: {
                value: "",
                country: {
                  dial_code: "",
                },
              },
              code: "",
              label: "Phone",
            },
            logo: {
              readOnly: false,
              value: {},
              label: "Vendor's business name",
            },

            country: {
              value: "India",
              label: "Vendor's business name",
            },
            city: {
              value: "",
              label: "City",
            },
            businessGSTIN: {
              value: "",
              label: "Vendor's business name",
            },
            businessPANNumber: {
              value: "",
              label: "Vendor's business name",
            },
            vendorType: {
              value: "",
              label: "Vendor's business name",
            },
            taxTreatment: {
              value: "",
              label: "Vendor's business name",
            },

            address: {
              value: {
                characteristic: {
                  "@type": "",
                  address1: "",
                  city: "",
                  country: "",
                  description: "",
                  postCode: "",
                  postalCode: "",
                  state: "",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  timezone: "",
                  unit: "",
                },

                mediumType: "postalAddress",
              },
              label: "Vendor's business name",
            },
            additionalDetails: {
              displayName: {
                value: "",
                label: "Vendor's business name",
              },
              email: {
                value: "",
                label: "Vendor's business name",
              },
              phoneNumber: {
                value: "",
                countryCode: { value: "91" },
                label: "Vendor's business name",
              },
              showEmail: {
                value: false,
                label: "Vendor's business name",
              },
              showPhoneNumber: {
                value: false,
                countryCode: {
                  value: {
                    name: "india",
                    dial_code: "+91",
                    flags: "",
                    short: "In",
                  },
                },
                label: "Vendor's business name",
              },
            },
          },
          quoteDate: { value: "12/12/2023", label: "Date", readOnly: true },
          isQuoteValidTillDate: false,
          quoteValidTillDateLabel: {
            value: "Next bill date",
            label: "Next bill date",
            readOnly: true,
          },
          quoteValidTillDate: {
            value: "12/12/2023",
            label: "DD/MM/YYYY",
            readOnly: true,
          },
          additionalFields: [],
          // quoteNumberLabel:"Quote NO",
          // quoteNumber:"MCF-1242",
        },
        senderContact: {
          title: {
            value: "Invoice contact",
            label: "Invoice contact",
            readOnly: true,
          },
          list: {
            value: [
              { name: "Jemy", value: "Jemy" },
              { name: "john nill", value: "john" },
            ],
            label: "A",
            readOnly: true,
          },
          contactDetails: {
            // firstName:
            name: {
              value: "",
              readOnly: true,
              label: "",
            },
            id: {
              readOnly: false,
              value: "",
              label: "",
            },
            email: {
              value: "",
              readOnly: true,
              label: "",
            },
            phone: {
              value: {
                value: "",
                country: {
                  dial_code: "",
                },
              },
              code: "",
              label: "Phone",
            },
          },
          phone: {
            value: "",
            code: "",
            label: "Phone",
          },
          quoteDate: { value: "12/12/2023", label: "Date", readOnly: true },
          isQuoteValidTillDate: false,
          quoteValidTillDateLabel: {
            value: "Next bill date",
            label: "Next bill date",
            readOnly: true,
          },
          quoteValidTillDate: {
            value: "12/12/2023",
            label: "DD/MM/YYYY",
            readOnly: true,
          },
          additionalFields: [],
          // quoteNumberLabel:"Quote NO",
          // quoteNumber:"MCF-1242",
        },
        recipientContact: {
          title: {
            value: "Client contact",
            label: "Client contact",
            readOnly: true,
          },
          list: {
            value: [
              { name: "Jemy", value: "Jemy" },
              { name: "john nill", value: "john" },
            ],
            label: "A",
            readOnly: true,
          },
          phone: {
            value: "",
            code: "",
            label: "Phone",
          },
          contactDetails: {
            // firstName:
            name: {
              value: "",
              readOnly: true,
              label: "",
            },
            id: {
              readOnly: false,
              value: "",
              label: "",
            },
            email: {
              value: "",
              readOnly: true,
              label: "",
            },
            phone: {
              value: {
                value: "",
                country: {
                  dial_code: "",
                },
              },
              code: "",
              label: "Phone",
            },
          },
          businessDetails: {
            businessName: {
              readOnly: false,
              value: "Deccoy",
              label: "Vendor's business name",
            },
            country: {
              value: "India",
              label: "Vendor's business name",
            },
            city: {
              value: "",
              label: "City",
            },
            businessGSTIN: {
              value: "",
              label: "Vendor's business name",
            },
            businessPANNumber: {
              value: "",
              label: "Vendor's business name",
            },
            vendorType: {
              value: "",
              label: "Vendor's business name",
            },
            taxTreatment: {
              value: "",
              label: "Vendor's business name",
            },

            address: {
              value: {
                characteristic: {
                  "@type": "",
                  address1: "",
                  city: "",
                  country: "",
                  description: "",
                  postCode: "",
                  postalCode: "",
                  state: "",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  timezone: "",
                  unit: "",
                },

                mediumType: "postalAddress",
              },
              label: "Vendor's business name",
            },
            additionalDetails: {
              displayName: {
                value: "",
                label: "Vendor's business name",
              },
              email: {
                value: "",
                label: "Vendor's business name",
              },
              phoneNumber: {
                value: { country: "91", value: "78", valid: false },
                countryCode: { value: "91" },
                label: "Vendor's business name",
              },
              showEmail: {
                value: false,
                label: "Vendor's business name",
              },
              showPhoneNumber: {
                value: false,
                countryCode: { value: "91" },
                label: "Vendor's business name",
              },
            },
          },
          quoteDate: { value: "12/12/2023", label: "Date", readOnly: true },
          isQuoteValidTillDate: false,
          quoteValidTillDateLabel: {
            value: "Next bill date",
            label: "Next bill date",
            readOnly: true,
          },
          quoteValidTillDate: {
            value: new Date().toISOString(),
            label: "DD/MM/YYYY",
            readOnly: true,
          },
          additionalFields: [],
          // quoteNumberLabel:"Quote NO",
          // quoteNumber:"MCF-1242",
        },
        isShippingDetails: {
          value: false,
          label: "Show shipping & billing details",
        },

        recipientShippingDetails: {
          title: {
            value: "Shipping to",
            label: "Shipping to",
            readOnly: true,
          },
          address: {
            value: {
              characteristic: {
                "@type": "",
                address1: "",
                city: "",
                country: "",
                description: "",
                postCode: "",
                postalCode: "",
                state: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                timezone: "",
                unit: "",
              },

              mediumType: "postalAddress",
            },
            label: "Vendor's business name",
            readOnly: true,
          },
          isBusinessAddress: {
            value: false,
            readOnly: true,
            label: "Same as your client address",
          },

          shippingNotes: {
            value: "",
            readOnly: true,
            label: "Notes",
          },
        },
        recipientBillingDetails: {
          title: {
            value: "Billing address",
            label: "Billing address",
            readOnly: true,
          },
          address: {
            value: {
              characteristic: {
                "@type": "",
                address1: "",
                city: "",
                country: "",
                description: "",
                postCode: "",
                postalCode: "",
                state: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                timezone: "",
                unit: "",
              },

              mediumType: "postalAddress",
            },
            label: "Vendor's business name",
            readOnly: true,
          },
          isBusinessAddress: {
            value: false,
            readOnly: true,
            label: "Same as your client address",
          },

          shippingNotes: {
            value: "",
            readOnly: true,
            label: "Notes",
          },
        },
        recipientTermsandConditions: {
          conditions: [
            {
              additionalFields: [],
              label: "Add terms and conditions",
              readOnly: true,
            },
          ],
        },
        recipientNotes: {
          "@baseType": "Notes",
          label: "Add notes",
          readOnly: true,
          value: "",
        },
        recipientAttachments: {
          label: "Add attachments",
          readOnly: true,
          value: [],
        },
        recipientAdditionalInfo: {
          additionalFields: [],
          readOnly: true,
          label: "Add additional info",
        },

        fromSignature: {
          label: "Manager signature",
          value: null,
          readOnly: true,
        },
        toSignature: {
          label: "Client signature",
          value: null,
          readOnly: true,
        },
      },
      addressTemplate: {
        characteristic: {
          "@type": "",
          address1: "",
          city: "",
          country: "",
          description: "",
          postCode: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          timezone: "",
          unit: "",
        },

        mediumType: "postalAddress",
      },
      label: "Vendor's business name",
      termsandConditionsnameAndFields: {
        additionalFields: [],
        label: "Add terms and conditions",
        readOnly: true,
      },
      termsAndConditionAdditionalFields: {
        label: "",
        textEditor: true,
      },
      drag_leave_index: 0,
      drag_start_index: 0,
      drag_start_col_index: 0,
      drag_leave_col_index: 0,
      recipientadditionalFieldTemplate: {
        label: "",
        value: "",
      },
      company: null,
      userId: null,
      companyId: null,
      offering_data: [
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex boardd",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "low stock",
          },
          card_footer: {
            price: { whole: "655678", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "abc",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock",
          },
          card_footer: {
            price: { whole: "65", fraction: "32" },
            currency: "USD",
          },
        },
      ],
      show_offering: false,
      search_offering: "",
      selectedProductOfferingData: [],
      currentPage: 1,
      sendSelectedProductOfferingData: [],
      tempPricingTableData: [],
      quotePricingData: {},
      editPricingData: [],
      reconfigure: false,
    };
  },

  computed: {
    isClickable() {
      return (
        this.quoteDataLoaded && // by asif
        this.isValidQuoteTemplate 
        // && 
        // this.recipientsList.length > 0
      );
    },

    // dynamicWidthHead() {
    //   const minLength = 9; // initial width
    //   const charWidth = 10; // avg width per character
    //   const minWidth = 100; // minimum width
    //   const additionalWidth =
    //     this.invoiceTemplatePayload.header.title.value.length > minLength
    //       ? (this.invoiceTemplatePayload.header.title.value.length - minLength) *
    //         charWidth
    //       : 0;
    //   const finalWidth = minWidth + additionalWidth;
    //   let controlledwidth = 0;
    //   if (finalWidth > 1300) {
    //     controlledwidth = 1300;
    //   } else {
    //     controlledwidth = finalWidth;
    //   }
    //   return `${controlledwidth}px`;
    // },

    dynamicWidthHead() {
    const minWidth = 100; // Minimum width for the input field
    const maxWidth = 1300; // Maximum width for the input field
    const title = this.invoiceTemplatePayload.header.title.value;

    // Get the actual width of the text using the getTextWidth method
    const textWidth = this.getTextWidth(title);

    // Final width, ensuring it respects the minimum and maximum constraints
    const finalWidth = Math.min(Math.max(minWidth, textWidth + 20), maxWidth);

    // Return the computed width as a string with 'px' appended
    return `${finalWidth}px`;
  },
      
  dynamicWidthSub() {
    const minWidth = 100; // Minimum width for the input field
    const maxWidth = 1300; // Maximum width for the input field
    const title = this.invoiceTemplatePayload.header.subtitle.value;

    // Get the actual width of the text using the getTextWidth method
    const textWidth = this.getTextWidth(title);

    // Final width, ensuring it respects the minimum and maximum constraints
    const finalWidth = Math.min(Math.max(minWidth, textWidth + 20), maxWidth);

    // Return the computed width as a string with 'px' appended
    return `${finalWidth}px`;
  },

    // dynamicWidthSub() {
    //   const minLength = 9; // initial width
    //   const charWidth = 10; // avg width per character
    //   const minWidth = 100; // minimum width
    //   const additionalWidth =
    //     this.invoiceTemplatePayload.header.subtitle.value.length > minLength
    //       ? (this.invoiceTemplatePayload.header.subtitle.value.length -
    //           minLength) *
    //         charWidth
    //       : 0;
    //   const finalWidth = minWidth + additionalWidth;
    //   let controlledwidth = 0;
    //   if (finalWidth > 1300) {
    //     controlledwidth = 1300;
    //   } else {
    //     controlledwidth = finalWidth;
    //   }
    //   return `${controlledwidth}px`;
    // },

    isValidQuoteTemplate() {
      if (
        !this.isValidSenderCompany ||
        !this.isValidSenderCompanyContact ||
        !this.isValidRecipientCompany ||
        !this.isValidRecipientCompanyContact ||
        !this.isValidQuoteItem
      ) {
        return false;
      }
      return true;
    },
    isValidDraftQuote() {
      if (!this.isValidSenderCompany) {
        return false;
      }
      return true;
    },
    isValidQuoteTemplateToSave() {
      if (
        !this.isValidSenderCompany ||
        !this.isValidSenderCompanyContact ||
        !this.isValidRecipientCompanyContact ||
        !this.isValidRecipientCompany ||
        !this.isValidQuoteItem
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    signaturePlacePreVal: {
      handler(val) {
        val;
      },
      immediate: true,
      deep: true,
    },
    assignedColors: {
      handler(val) {
        val;
      },
      immediate: true,
      deep: true,
    },
    "invoiceTemplatePayload.quoteBasicInfo.quoteDate.value"(val) {
      console.log("invoiceTemplatePayload.quoteBasicInfo.quoteDate.value", val);
      const fullYear = new Date(val)?.getFullYear();
      console.log("fullYear.....", fullYear);
      this.fullYear = fullYear;

      const disdate = new Date(val);
      disdate;

      const r = this.generateDateList(this.fullYear, disdate);
      this.holidays = r;

      // this.holidays = this.holidays1;
      console.log(
        "r..........",
        r,
        "r..........",
        this.holidays,
        this.fullYear
      );
      this.dateref += 0;

      // const d2 = new Date(this.invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value);
    },
    "invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value"(val) {
      console.log(
        "invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value",
        val
      );
      const d1 = new Date(
        this.invoiceTemplatePayload.quoteBasicInfo.quoteDate.value
      );
      const d2 = new Date(val);
      d1 < d2;
      console.log("d1 < d2...", d1 < d2);

      // if(d1 < d2){
      //   console.log("d1 < d2 correct");

      // }else{
      //   setTimeout(() => {
      //     this.invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value=this.invoiceTemplatePayload.quoteBasicInfo.quoteDate.value;
      //     console.log("d1 < d2 incorrect");
      //   }, 100);

      // }
    },
    "invoiceTemplatePayload.header.title.value"(newVal) {
      this.truncateValue(newVal);
      console.log("lorem", newVal);
    },

    "invoiceTemplatePayload.header.subtitle.value"(newVal) {
      this.truncateValuesub(newVal);
      console.log("lorem", newVal);
    },

    retiredvmodel: {
      async handler(val) {
        console.log("retired Product...", val);
        this.getOfferData();

        // if(val === true){
        // }else{
        //   this.getO fferData();
        // }
      },
      immediate: true,
      deep: true,
    },

    accExecParty: {
      async handler(val) {
        console.log(this.companyNew);
        let f = {
          company: `${this.companyNew}`,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {
            "role.name": "Account executive",
            name: {
              $regex: `${val}`,
              $options: "i",
            },
          },
          searchQuery: "",
        };
        let x = await MLTAxiosInstance.post("util/filterData", f);
        console.log(x);
        // let exec = await MLTAxiosInstance.get(
        //   `/partymanagement/individual/listuser?company=${this.company}`
        // );
        console.log(x);

        this.executivesList = x.data.data;
        this.executivesList.forEach((item) => {
          console.log((item.newRole = item.role[0].name));
        });
      },
      immediate: true,
      deep: true,
    },

    newAccPayload: {
      handler(val) {
        console.log('new account', val);
        val['@baseType'] = 'customer';
        val.name = this.sanitizeDatabaseName(val.customName);
        val.companyName = this.sanitizeDatabaseName(val.customName);
        val.tradingName = this.sanitizeDatabaseName(val.customName);
        val.email = val.contactMedium[0].characteristic.emailAddress;
        val.parentCompany = this.companyNew;
        val.rootName = this.companyNew;
        val.contactMedium[3].characteristic['@type'] = this.locationType;
        // console.log('val before watch', this.executivesList);
        // let accExec = this.executivesList.filter((item) => {
        //   return item.name === val.accountExecutive ? item : undefined;
        // });
        // val.relatedParty.splice(0, 1);
        // val.relatedParty.push(accExec[0]);
        // console.log('val after watch', val.relatedParty);
        for (let i = 0; i < val.contactMedium.length; i++) {
          if (val.contactMedium[i].mediumType === val.preferred) {
            val.contactMedium[i].preferred = true;
          } else {
            val.contactMedium[i].preferred = false;
          }
        }
        this.websiteRegex(val.externalReference[0].name);
        if (
          val.name !== undefined &&
          val.name.length > 0 &&
          this.validateEmail(
            val.contactMedium[0].characteristic.emailAddress
          ) &&
          this.validatePhoneNumber(
            val.contactMedium[1].characteristic.phoneNumber
          ) &&
          !this.isUrlEmpty
        ) {
          this.canAdd = true;
        } else {
          this.canAdd = false;
        }
        console.log(val, 'payload');
        val.relatedParty.filter((item) => {
          if (item.role === 'Account executive') {
            this.accExecParty = item.name;
          }
        });
        // console.log(this.payload);
      },
      immediate: true,
      deep: true,
    },

    selectedHierarchyAccount: {
      handler(v) {
        console.log(v, "adacc vvvvvvvvvvvvvv");

        this.selectedHierarchyContactFunc(v);
      },
      immediate: true,
      deep: true,
    },

    newConPayload: {
      handler(val) {
        console.log(
          val.contactMedium[0].characteristic.emailAddress,
          "pop",
          val
        );
        val["@baseType"] = "customer";
        let name = val.firstName + " " + val.lastName;
        val["familyName"] = this.sanitizeDatabaseName(name);
        val["givenName"] = this.sanitizeDatabaseName(name);
        val["name"] = this.sanitizeDatabaseName(name);

        // val.companyName = val.name;
        // val.tradingName = val.name;
        val.email = val.contactMedium[0].characteristic.emailAddress;
        val.parentCompany = this.companyCon;
        // val.rootName = this.company;
        // val.contactMedium[3].characteristic['@type'] = this.locationType
        // console.log('val before watch', this.executivesList);
        // let accExec = this.executivesList.filter((item) => {
        //   return item.name === val.accountExecutive ? item : undefined;
        // });
        // val.relatedParty.splice(0, 1);
        // val.relatedParty.push(accExec[0]);
        // console.log('val after watch', val.relatedParty);
        for (let i = 0; i < val.contactMedium.length; i++) {
          if (val.contactMedium[i].mediumType === val.preferred) {
            val.contactMedium[i].preferred = true;
          } else {
            val.contactMedium[i].preferred = false;
          }
        }
        if (
          //check the mandatory field
          val.firstName != "" &&
          this.validateEmail(
            val.contactMedium[0].characteristic.emailAddress
          ) &&
          val.aristocraticTitle != "" &&
          this.validatePhoneNumber(
            val.contactMedium[1].characteristic.phoneNumber
          )
        ) {
          this.canAddContact = true;
        } else {
          this.canAddContact = false;
        }
        console.log(val, "payload");
        // val.relatedParty.filter((item) => {
        //   if (item.role === 'Account executive') {
        //     this.accExecParty = item.name;
        //   }
        // });
        // console.log(this.payload);
      },
      immediate: true,
      deep: true,
    },

    invoiceTemplatePayload: {
      async handler(val) {
        console.log("🚀 ~ watch ~ val invoiceTemplatePayload", val);
        //preview account in contact
        // this.accountPreviewInContact =
        //   val?.recipient?.businessDetails?.businessName?.value;
        // this.newConPayload.tradingName = this.accountPreviewInContact;
        // this.newConPayload.company = this.accountPreviewInContact;
        // this.newConPayload.companyName = this.accountPreviewInContact;
        if (!val) return;
        // await this.saveQuote(true)
      },
      immediate: true,
      deep: true,
    },
    initialData: {
      handler(val) {
        console.log("🚀 ~ watch ~ val initialData", val);
      },
      immediate: true,
      deep: true,
    },

    quotePricingData: {
      handler(val) {
        console.log("🚀 ~ watch ~ val quotePricingData", val);
        console.log("quoteItem updated");
        this.isValidQuoteItem = val?.quoteItem?.length > 0 ? true : false;
      },
      immediate: true,
      deep: true,
    },

    show_offering: {
      handler(val) {
        if (val) {
          this.selectedProductOfferingData = [];
          this.sendSelectedProductOfferingData = [];
          // this.searchOffers('')
        }
      },
      immediate: true,
      deep: true,
    },
    // isValidSenderCompany:{
    //   handler(value) {
    //   }
    // },
    tabletMode: {
      handler(val) {
        if (!val) {
          this.filterPrevalueDuplicate2 = {};
          this.filterPrevalueDuplicate2 = JSON.parse(
            JSON.stringify(this.filterPrevalue)
          );
          this.filterComponentKey += 1;
          this.filterQuery["module"] = "discountOffers";
          this.show_right_filter = false;
        } else {
          this.filterPrevalueDuplicate1 = {};
          this.filterPrevalueDuplicate1 = JSON.parse(
            JSON.stringify(this.filterPrevalue)
          );
          this.filterComponentKey += 1;
          this.filterQuery["module"] = "discountOffers";
        }
      },
      immediate: true,
      deep: true,
    },
  },
  /**this hook help to initiate beforeunload event to manage page reload */
  beforeMount() {
    window.addEventListener("beforeunload", this.preventPageReload);
  },
  /**This hook help to remove beforeunload event which we're created during mount */
  async beforeUnmount() {
    //     if (window.performance) {
    //   console.info("window.performance works fine on this browser");
    // }
    // console.info(performance.navigation.type);
    // if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
    //   console.info( "This page is reloaded" );
    // } else {
    //   console.info( "This page is not reloaded");
    // }
    // throw new Error('Haaaahaaa')
    // if(!this.$route.query.mode  || (this.$route.query.mode && this.$route.query.mode != "edit")){
    //   await UPCAxiosInstance.delete('/genericID', {
    //       module: "product",
    //       subModule: "quote",
    //       prefix: "QOT",
    //       ID: this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value
    //   });
    // }
    window.removeEventListener("beforeunload", this.preventPageReload);
    window.removeEventListener("resize", this.checkForResize);
  },
  async mounted() {
    // this.show_side_panel = false;
    this.triggerVersionHistory(false);
    const val1 = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.companyNew = val1.data.company;
    this.companyCon = val1.data.company;

    window.addEventListener("resize", this.checkForResize);
    console.log("quoteData...123 123 123");
    this.company = this.$cookies.get("company");
    this.userId = this.$cookies.get("user");
    this.client = this.$route.query.client;
    this.clientUserId = this.$route.query.id;
    this.invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value =
      this.addDatesToGivenDateV1(
        this.invoiceTemplatePayload.quoteBasicInfo.quoteDate.value,
        this.invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting.value
      );
    await this.$store.dispatch("discountOffersData");
    this.displayFilterData =
      this.$store.getters.productOfferingDiscountFilterData;
    let priceArr = [];
    this.displayFilterData["Product offering price"].forEach((item) => {
      if (
        item &&
        item["name"] &&
        item["name"]["productPrice"] &&
        item["name"]["productPrice"]["dutyFreeAmount"] &&
        item["name"]["productPrice"]["dutyFreeAmount"] != null
      ) {
        priceArr.push(item["name"]["productPrice"]["dutyFreeAmount"]);
      }
      // if(item && item['name']?['productPrice']?['dutyFreeAmount'] && item['name']['productPrice']['dutyFreeAmount'] != null){
      // priceArr.push(item['name']?['productPrice']?['dutyFreeAmount'])
      // }
    });
    const minPrice = Math.min(...priceArr);
    const maxPrice = Math.max(...priceArr);
    this.displayAlternate[0].min = minPrice;
    this.displayAlternate[0].max = maxPrice;
    this.quoteVersion = "1";

    if (!this.$route.query.mode || this.$route.query?.mode != "edit") {
      // Generate quote number
      const quoteIDRes = await UPCAxiosInstance.post("/genericID", {
        module: "product",
        subModule: "invoice",
        prefix: "INV",
        // isBasedOnCollection: true,
        // collection: "customerbill",
      });
      if (quoteIDRes.data && quoteIDRes.data.ok && quoteIDRes.data.ID) {
        this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value =
          quoteIDRes.data.ID;
      } else {
        this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value =
          "CB-991I99";
      }
    }
    if (
      this.$route.query.mode &&
      this.$route.query.mode == "edit"
      // &&
      // this.$route.query.from &&
      // this.$route.query.from == "manager_approval"
    ) {
      const quoteData = await UPCAxiosInstance.get(
        `/customerBill/${this.$route.query.quote_id}`
      );
      this.quoteData = quoteData?.data;
      console.log("emit...123 quoteData",this.quoteData);
      console.log(this.quoteData, "this.quoteData...authorization top",quoteData);
      // this.signaturePlacePreVal =
      //   this.quoteData?.authorization[0]?.signaturPlaced;
      // this.signaturePlaced = this.quoteData?.authorizati on[0]?.signaturPlaced; //
      // this.assignedColors =
      //   this.quoteData?.authorization[0]?.signaturPlaced?.assignedColors;
      console.log(
        this.quoteData,
        "this.quoteData...authorization one",
        this.quoteData,
        this.signaturePlacePreVal
      );
      // this.initialQuoteAuthorizarionData=  JSON.parse(JSON.stringify(this.quoteData?.authorization))
      // this.showStatus = this.computedQuoteState(
      //   this.quoteData?.state,
      //   this.quoteData?.authorization
      // );
      this.showStatus = this.quoteData?.state;
      console.log(
        "this.showStatus, ",
        this.showStatus,
        this.quoteData?.state,
        // this.initialQuoteAuthorizarionData,
        // this.quoteData?.authorization
      );

      this.enableButton = true;
      // this.recipientsList = this.quoteData?.authorization?.map((ele)=>{
      //     if(ele?.name === "Quote client confirmation")
      //   {
      //     return  ele?.approver
      //   }
      //   }).filter(Boolean).flat(1).filter(ele=>ele?.name)
      console.log("recipientsList mount", this.recipientsList);
      //       this.statusList = this.statusList.filter((item)=>
      //       {

      //         item.value = this.computedQuoteState(item.name,this.quoteData.authorization);
      //         item.name = this.computedQuoteState(item.name,this.quoteData.authorization);
      //         console.log("item....",item);
      //         return item

      //       })
      // console.log("qwertyu...",this.statusList)

      this.quoteVersion = this.quoteData?.version;
      // Mapping quote date
      this.invoiceTemplatePayload.quoteBasicInfo.quoteDate.value =
        this.quoteData?.quoteDate;
      // Check if we have quote Next bill date
      this.invoiceTemplatePayload.quoteBasicInfo.isQuoteValidTillDate =
        this.quoteData?.requestedQuoteCompletionDate !==
          this.quoteData?.quoteDate || null;
      // Next bill date mapping
      this.invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value =
        this.quoteData?.nextBillDate;
      // this.addDatesToGivenDate(
      //   this.quoteData.quoteDate,
      //   this.invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting.value
      // );

      console.log("this.invoiceTemplatePayload obj", this.invoiceTemplatePayload);

      // To get recipientsList
      this.getRecipientList();

      //Prevalue for Attachments
      let arr = [];
      this.quoteData?.attachmentRef.forEach((item) => {
        if (item?.attachmentType == "quoteDocuments") {
          let obj = {};
          obj["name"] = item?.mimeType?.filename;
          let obj1 = {};
          obj1["properties"] = obj;
          arr.push(obj1);
        }
      });
      if (arr.length != 0) {
        this.attachments = true;
        this.termsAndPolicyAreaData.forEach((item) => {
          if (item?.name == "Add attachments") {
            item.checked = true;
          }
        });
        this.preValueAttachments = arr;
        this.invoiceTemplatePayload.recipientAttachments.value =
          this.quoteData?.attachmentRef;
      }

      console.log(
        "quoteData",
        this.quoteData,
        this.preValueAttachments,
        this.mapFromTMF648(this.quoteData?.quoteItem)
      );
      // map profile data

      // this.profilePicturePrevalue = this.quoteData.attachment[0]?this.quoteData.attachment[0].
      if (this.quoteData.attachmentRef[0]) {
        let uploadedContent = await UPCAxiosInstance.get(
          "/gcpupload/files/" + this.quoteData.attachmentRef[0]?.name,
          { responseType: "arraybuffer" }
        );
        console.log("🚀 ~ mounted ~ uploadedContent:", uploadedContent);
        this.profilePicturePrevalue = await this.bufferToDataURL(
          uploadedContent.data
        );
      }

      this.editPricingData = this.mapFromTMF648(this.quoteData?.productRef[0]?.quoteItem);
      console.log("emit...123 editPricingData", this.editPricingData);
      if (this.quoteData?.amountDue?.quotePrice?.summary !== undefined) {
        this.quoteSummaryDataFromRules = JSON.parse(
          JSON.stringify(this.quoteData?.amountDue?.quotePrice?.summary)
        );
        console.log("emit...123 quoteSummaryDataFromRules",this.quoteSummaryDataFromRules)
      }
      // this.$nextTick(() => {});
      this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value =
        this.quoteData?.displayID;
      this.quoteAPIPayloadToTemplatePayload(quoteData.data);
      console.log(this.invoiceTemplatePayload, "@payload");
      let sender = this.quoteData.relatedParty.find(
        (item) => item.role == "Seller"
      );
      if (sender) {
        this.isValidSenderCompany = true;
      }
      console.log("🚀 ~ mounted ~ sender:", sender);
      let senderContact = this.quoteData.relatedParty.find(
        (item) => item.role == "Seller contact"
      );
      this.userId = senderContact.id;
      if (senderContact) {
        this.isValidSenderCompanyContact = true;
      }
      console.log("🚀 ~ sendForApproval ~ senderContact:", senderContact);
      // let sellerEmail = this.quoteData.contactMedium.find( // command for temp by asif
      //   (item) => item.mediumType == "sellerEmail"
      // );
      // if (sellerEmail) {
      //   sellerEmail = sellerEmail.characteristic.emailAddress;
      // }
      // let sellerContactEmail = this.quoteData.contactMedium.find(
      //   (item) => item.mediumType == "sellerContactEmail"
      // );
      // if (sellerContactEmail) {
      //   sellerContactEmail = sellerContactEmail.characteristic.emailAddress;
      // }
      let recipient = this.quoteData.relatedParty.find(
        (item) => item.role == "Buyer"
      );
      this.client = recipient?.name;
      if (recipient) {
        this.isValidRecipientCompany = true;
      }
      console.log("🚀 ~ sendForApproval ~ recipient:", recipient);
      let recipientContact = this.quoteData.relatedParty.find(
        (item) => item.role == "Buyer contact"
      );
      this.clientContact = recipientContact;
      this.clientUserId = recipientContact.id;
      if (recipientContact) {
        this.isValidRecipientCompanyContact = true;
      }
      console.log("🚀 ~ sendForApproval ~ recipientContact:", recipientContact);

      // let buyerEmail = this.quoteData.contactMedium.find( // command for temp by asif
      //   (item) => item.mediumType == "buyerEmail"
      // );
      // if (buyerEmail) {
      //   buyerEmail = buyerEmail.characteristic.emailAddress;
      // }
      // let buyerContactEmail = this.quoteData.contactMedium.find(
      //   (item) => item.mediumType == "buyerContactEmail"
      // );
      // if (buyerContactEmail) {
      //   buyerContactEmail = buyerContactEmail.characteristic.emailAddress;
      // }
    }

    // Prepare sender company list
    let senderCompanyFilter = {
      company: "Halleyx",
      type: "filter-search",
      module: "hierarchyaccounts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {
        // name: 1,
      },
      limit: 2,
      filterQuery: {
        status: { $regex: "active", $options: "i" },
        "@baseType": { $nin: ["supplier", "customer"] },
        $and: [
          { tradingName: { $ne: "Halleyx" } },
          {
            $or: [
              { tradingName: { $regex: this.company, $options: "i" } },
              { tradingName: { $regex: "", $options: "i" } },
            ],
          },
        ],
      },
      searchQuery: "",
    };

    let recipientListOptions = {
      company: this.company,
      type: "filter-search",
      module: "hierarchyaccounts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {
        name: 1,
      },
      limit: 2,
      filterQuery: {
        status: { $regex: "active", $options: "i" },
        "@baseType": "customer",
      },
      searchQuery: "",
    };

    let senderCompanyInfo = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${this.userId}?company=${this.company}`
    );
    senderCompanyInfo = senderCompanyInfo.data;
    this.senderCompleteInfo = senderCompanyInfo.data;
    this.isValidSenderCompany = true;

    // Map sender default profile picture
    if (senderCompanyInfo?.attachment[0]) {
      const isProfilePic = senderCompanyInfo.attachment.find(
        (item) => item.attachmentType == "profile"
      );
      if (isProfilePic) {
        let uploadedContent = await UPCAxiosInstance.get(
          "/gcpupload/files/" + isProfilePic?.name,
          { responseType: "arraybuffer" }
        );
        this.profilePicture = isProfilePic;
        console.log("🚀 ~ mounted ~ uploadedContent:", uploadedContent);
        this.profilePicturePrevalue = await this.bufferToDataURL(
          uploadedContent.data
        );
      }
    }
    console.log("🚀 ~ mounted ~ senderCompanyInfo:", senderCompanyInfo);
    // sender company preloads
    let senderCompanies = await MLTAxiosInstance.post(
      "/util/filterData",
      senderCompanyFilter
    );
    this.senderCompanyList = senderCompanies.data.data;
    this.senderCompanyList.push(senderCompanyInfo);

    // Sender company contact loggined user
    let senderCompanyContact = await MLTAxiosInstance.get(
      `/partymanagement/individual/${this.userId}?company=${this.company}`
    );
    this.isValidSenderCompanyContact = true;
    this.senderContactCompleteInfo = senderCompanyContact.data;
    this.prepareSenderContacts(senderCompanyInfo);
    this.senderCompanyContacts.push(senderCompanyContact.data);
    this.senderCompanyContacts1.push(senderCompanyContact.data);
    console.log("this.senderCompanyContacts...",this.senderCompanyContacts, senderCompanyContact.data);
    

    this.invoiceTemplatePayload.senderContact.contactDetails.name.value =
      senderCompanyContact.data?.name;
    // Find phone
    let senderContactPhone = senderCompanyContact.data.contactMedium.find(
      (item) => item.mediumType == "phone"
    );
    if (
      senderContactPhone &&
      senderContactPhone.characteristic &&
      senderContactPhone.characteristic.phoneNumber &&
      senderContactPhone.characteristic.phoneNumber.value &&
      senderContactPhone.characteristic.phoneNumber.country.dial_code
    ) {
      this.invoiceTemplatePayload.senderContact.contactDetails.phone.value =
        senderContactPhone.characteristic.phoneNumber;
    }
    this.senderContactSearchKey = senderCompanyContact.data?.name;
    this.invoiceTemplatePayload.senderContact.contactDetails.email.value =
      senderCompanyContact.data.email;

    this.invoiceTemplatePayload.senderContact.contactDetails.id.value =
      senderCompanyContact.data._id;
    this.invoiceTemplatePayload.sender.businessDetails.businessName.value =
      senderCompanyInfo.tradingName;
    this.senderSearchKey = senderCompanyInfo.tradingName;
    this.invoiceTemplatePayload.sender.businessDetails.id.value =
      senderCompanyInfo._id;
    this.invoiceTemplatePayload.sender.businessDetails.email.value =
      senderCompanyInfo.email;
    // Map phone number
    let phone = senderCompanyInfo.contactMedium?.find(
      (item) => item.mediumType == "phone"
    );
    if (
      phone &&
      phone.characteristic &&
      phone.characteristic.phoneNumber &&
      phone.characteristic.phoneNumber.value &&
      phone.characteristic.phoneNumber.country.dial_code
    ) {
      this.invoiceTemplatePayload.sender.businessDetails.phone.value =
        phone.characteristic.phoneNumber;
    }
    let address = senderCompanyInfo.contactMedium?.find(
      (item) => item.mediumType == "postalAddress"
    );
    console.log("🚀 ~ mounted ~ address:", address);
    this.invoiceTemplatePayload.sender.businessDetails.address.value = address
      ? address
      : this.invoiceTemplatePayload.sender.businessDetails.address.value;
    this.invoiceTemplatePayload.sender.businessDetails.city.value = address
      ? address.characteristic.city
      : this.invoiceTemplatePayload.sender.businessDetails.address.value
          .characteristic.city;

    // Country list
    // let countryList = await DMNAxiosInstance.get(`mapRef/collection/country`);
    // this.countryList = countryList.data.data;

    // Recipient preloads
    let recipientLists = await MLTAxiosInstance.post(
      "/util/filterData",
      recipientListOptions
    );
    console.log("🚀 ~ mounted ~ recipientLists:", recipientLists);
    this.recipientLists = recipientLists.data.data || [];

    this.invoiceTemplatePayload.recipient.businessDetails.businessName.value =
      this.client
        ? this.client
        : this.invoiceTemplatePayload.recipient.businessDetails.businessName
            .value;
    this.invoiceTemplatePayload.recipient.businessDetails.id.value = this
      .clientUserId
      ? this.clientUserId
      : this.invoiceTemplatePayload.recipient.businessDetails.id.value;
    if (this.client && this.clientUserId) {
      // let recipient = this.recipientLists.find(
      //   (item) => item.tradingName == this.client
      // );
      let recipientInfo = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.clientUserId}?company=${this.client}`
      );
      console.log("🚀 ~ mounted ~ recipinetInfo:", recipientInfo);
      this.recipientCompleteInfo = recipientInfo.data;
      this.prepareRecipientContacts(recipientInfo.data);
      this.handleRecipientChanges(
        recipientInfo.data,
        this.$route.query?.mode == "edit"
      );
      if (this.clientContact) {
        let recipientContactInfo = await MLTAxiosInstance.get(
          `/partymanagement/individual/${this.clientContact.id}?company=${this.client}`
        );
        console.log(
          "🚀 ~ mounted ~ recipientContactInfo:",
          recipientContactInfo
        );
        if (recipientContactInfo.data) {
          console.log(
            "🚀 ~ mounted ~ recipientContactInfo.data:",
            recipientContactInfo.data
          );
          this.invoiceTemplatePayload.recipientContact.contactDetails.name.value =
            recipientContactInfo.data?.name;
          this.invoiceTemplatePayload.recipientContact.contactDetails.id.value =
            recipientContactInfo.data._id;
          this.invoiceTemplatePayload.recipientContact.contactDetails.email.value =
            recipientContactInfo.data.email;
          // Find recipient phone
          let recipientContactPhone =
            recipientContactInfo.data.contactMedium.find(
              (item) => item.mediumType == "phone"
            );
          if (
            recipientContactPhone &&
            recipientContactPhone.characteristic &&
            recipientContactPhone.characteristic.phoneNumber &&
            recipientContactPhone.characteristic.phoneNumber.value &&
            recipientContactPhone.characteristic.phoneNumber.country.dial_code
          ) {
            this.invoiceTemplatePayload.recipientContact.contactDetails.phone.value =
              recipientContactPhone.characteristic.phoneNumber;
          }
          this.recipientContactCompleteInfo = recipientContactInfo.data;
          // this.invoiceTemplatePayload.recipientContact.contact
          let isContactThere = this.recipientContacts.find(
            (item) => item?.name == recipientContactInfo.data?.name
          );
          console.log("🚀 ~ mounted ~ isContactThere:", isContactThere);
          if (!isContactThere) {
            this.recipientContacts.push(recipientContactInfo.data);
          }
          // this.recipientContacts = this.recipientContacts.filter(i=>i.firstName)
          console.log("this.recipientContacts", this.recipientContacts);
          // this.invoiceTemplatePayload.recipientContact.contactDetails.phone = recipientContact.info.data?.name
        }
      }
    }
    // // Buyer contact
    // let recipientContact = await MLTAxiosInstance.post('/util/filterData',recipientContactOptions)
    // console.log("🚀 ~ mounted ~ recipientContact:", recipientContact)
    // this.recipientContacts = recipientContact.data.data

    // Product offering 👇

    // this.getOfferData();
    console.log("this.recipientContacts outer", this.recipientContacts);
    this.initialData = JSON.parse(JSON.stringify(this.invoiceTemplatePayload));
    // this.quoteDataLoaded = this.initialData?true:false
    this.initialPricingTableData = JSON.parse(
      JSON.stringify(this.quotePricingData)
    );
    console.log(this.quoteData,'attachment')
    this.quoteData?.attachmentRef?.forEach((item,index)=>{
        if(item.attachmentType == 'profile'){
          this.profilePicture = item
          delete this.quoteData[index]
        }
      })
    // modal window for add contact and account

    // let designationList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmContactsDesignation`
    // );
    this.designationList =  await this.getReferanceData('cmContactsDesignation');

    // let tagsList = await DMNAxiosInstance.get(`mapRef/collection/tagsList`);
    let tagsList = await this.getReferanceData('tagsList')
    this.tagsList = tagsList.filter((item) => {
      return item?.module === "cm";
    });

    // let preferredList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountPreferredContactMedium`
    // );
    this.preferredMediumList =  await this.getReferanceData('cmAccountPreferredContactMedium');

    // let marketSegmentList = await DMNAxiosInstance.get(
    //   `mapRef/collection/market`
    // );
    this.marketSegmentList =  await this.getReferanceData('market');

    let f = {
      company: `${this.companyNew}`,
      type: "filter-search",
      module: "hierarchycontacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: {
        "role.name": "Account executive",
        name: {
          $regex: "",
          $options: "i",
        },
      },
      searchQuery: "",
    };
    let x = await MLTAxiosInstance.post("util/filterData", f);
    console.log(x);
    // let exec = await MLTAxiosInstance.get(
    //   `/partymanagement/individual/listuser?company=${this.company}`
    // );
    console.log(x);

    this.executivesList = x.data.data;
    this.executivesList.forEach((item) => {
      console.log((item.newRole = item.role[0].name));
    });

    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.companyNew = val.data.company;

    // let industryTypeList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountIndustryType`
    // );
    this.industryTypeList =  await this.getReferanceData('cmAccountIndustryType');

    // let employeeCountList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountEmployeeCount`
    // );
    this.employeeCountList =  await this.getReferanceData('cmAccountEmployeeCount');

    // let locationTypeList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountLocationType`
    // );
    this.locationTypeList =  await this.getReferanceData('cmAccountLocationType');

    // let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    // console.log("12345678", statusList);

    let filteredstatus = await this.getReferanceData('statuslist')
    this.filteredstatus = filteredstatus.filter(
      (item) => item.name === "Active" || item.name === "Inactive"
    );

    let statusContact = await this.getReferanceData('statuslist')
      this.statusContact = statusContact.filter(
      (item) =>
        item.name === "Active" ||
        item.name === "Inactive" ||
        item.name === "Pending"
    );

    const statusOrder = [
      "Draft",
      "Awaiting approval",
      "Approved",
      "Sent",
      "Completed",
      "Expired",
      "Rejected",
    ];

    let statusList = await this.getReferanceData('statuslist');
    this.statusList = statusList.filter((item) => statusOrder.includes(item.name))
      .sort(
        (a, b) => statusOrder.indexOf(a.name) - statusOrder.indexOf(b.name)
      );

    console.log("this.statusList..", this.statusList);
    this.quoteDataLoaded = true;
    console.log(
      this.quoteData,
      "this.quoteData...authorization end",
      this.invoiceTemplatePayload
    );
  },

  methods: {

    openDiscardModal(){
      this.delete_modal_active = true;
    },
    
    closeDiscardModal(){
      this.delete_modal_active = false;
    },

    deleteExecRow(){
      this.closeDiscardModal();
      this.$router.push({ name: 'invoiceDashboard' })
    },

    statusMapping(status) {
      
    switch (status) {
      case 'Settled':
        return 'settled';
      case 'Partially paid':
        return 'partiallyPaid';
      case 'Sent':
        return 'sent';
      case 'Validated':
        return 'validated';
      case 'On hold':
        return 'onHold';
      default:
        return status; // in case the status doesn't match
    }
  },

  reverseStatusMapping(statusKey) {
    switch (statusKey) {
      case 'settled':
        return 'Settled';
      case 'partiallyPaid':
        return 'Partially paid';
      case 'sent':
        return 'Sent';
      case 'validated':
        return 'Validated';
      case 'onHold':
        return 'On hold';
      default:
        return statusKey;
    }
  },

    getTextWidth(text, font = "16px Arial") {
    // Create a canvas element for measuring text width
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font; // Set the font used in the input

    // Measure the width of the given text
    const metrics = context.measureText(text);
    return metrics.width;
  },

    closeSidePanelForQuote(){
      this.recipientKey += 1;
      this.initialRecipientsList.length>0?this.recipientsList= JSON.parse(JSON.stringify(this.initialRecipientsList)):this.recipientsList=[]
      // this.recipientsList.length>0?this.propsForMultiSignComponent=this.recipientsList:this.propsForMultiSignComponent=[]
this.showRecipientDrawer = false
this.show_side_panel = false
    },
    multiShippingCheckbox(event, values) {
      console.log("emit...123 checkboxShipping",event?.checked);
      this.checkboxShipping = event.checked;

      this.showView = event.checked;

      this.addresscomponetshipping += 1;

      console.log(event, values, "multiShippingCheckbox");
    },
    showSidePanedFunc() {
      this.show_side_panel = !this.show_side_panel;
    },
    changeConfigToDep(val) {
      this.depPayload = JSON.parse(JSON.stringify(val));
      this.isDepFlow = true;
    },
    //mearge confite two time came so coment this line 
    // changeConfigToDep(val) {
    //   this.depPayload = JSON.parse(JSON.stringify(val));
    //   this.isDepFlow = true;
    // },
    getRecipientList() {
      if (this.quoteData?.authorization) {
        this.initialRecipientsList = this.quoteData.authorization
          .map((ele) => {
            if (
              ele?.name === "Quote client confirmation" &&
              ele?.approver?.[0].id != undefined &&
              ele?.clientName !== undefined
            ) {
              return {
                name: ele.clientName,
                role: ele.role,
                id: ele.approver[0].id,
                company: ele.approver[0].company,
                email: ele.email,
              };
            }
            return undefined;
          })
          .filter((recipient) => recipient !== undefined);
      } else {
        this.initialRecipientsList = [];
      }
      // this.propsForMultiSignComponent = this.recipientsList;
     this.recipientsList= JSON.parse(JSON.stringify(this.initialRecipientsList))
      console.log(this.initialRecipientsList,"getRecipientList", this.recipientsList);
    },
    generateDateList(year1, endDate) {
      const startDate = new Date(year1, 0, 1); // Start from January 1st of the given year1
      const end = new Date(endDate); // Convert the provided end date to a Date object
      let dates = [];

      while (startDate <= end) {
        // Format the date as "d-m-yyyy" without leading zeros
        const day = startDate.getDate(); // Get the day without padding
        const month = startDate.getMonth() + 1; // Months are 0-based, so we add 1
        const year = startDate.getFullYear();

        // Push the formatted date into the array
        dates.push(`${day}-${month}-${year}`);

        // Move to the next day
        startDate.setDate(startDate.getDate() + 1);
      }

      return dates;
    },

    emitStatusAcc(val1) {
      this.newAccPayload.status = val1["value"];
    },

    emitStatusCon(val1) {
      this.newConPayload.status = val1["value"];
    },

    test1(val) {
      console.log(val, "lololo");
    },
    async saveRecepientSignatureFlow(){
//       this.quoteData.authorization.push({
//   priority: this.recipientLists 
// });
// this.quoteData?.authorization?.map((ele) => {
//   if (ele?.name === "Quote client confirmation") {
//     ele.approver=[]
//     this.recipientsList.map((contact)=>{
//       if(contact.id!==ele.id && !ele.approver.some(appr => appr.id === contact.id)){
//         ele.approver.push(contact);
//       }
//     })
//   }
// });
this.showRecipientDrawer=false
this.show_side_panel = false
this.initialRecipientsList=JSON.parse(JSON.stringify(this.recipientsList))
// this.propsForMultiSignComponent = this.recipientsList
      console.log(this.quoteData,"saveRecepientSignatureFlow",this.recipientsList)
      this.recipientKey += 1;
      //  let updateQuote = await UPCAxiosInstance.patch(
      //       "/quotemanagement",
      //       {
      //         ...this.quoteData,
      //         id: this.quoteData._id,
      //       }
      //     );
      // console.log(updateQuote,"saveRecepientSignatureFlow")
      // this.tempRecipientsList=[]
      // this.tempPropsForMultiSignComponent=[]
    },
    signatureData($event, signaturePlace) {
      console.log($event, signaturePlace, "opqoq");
      if (this.signaturePlaced[signaturePlace]) {
        this.signaturePlaced[signaturePlace] = $event;
      } else {
        this.signaturePlaced[signaturePlace] = $event;
      }
      this.signaturePlaced["assignedColors"] = this.assignedColors;
      console.log(this.signaturePlaced, "lololo");
    },
    emitColordata(val) {
      this.assignedColors = JSON.parse(JSON.stringify(val));
      console.log(val, "testt");
    },
    showWorkflowFunc() {
      this.showWorkFlow = true;
    },
    closeactivitylog() {
      this.show_right = false;
    },
    async activiylogcall() {
      const logpayload = {
        type: "filter",
        module: "log",
        lastUpdated: new Date().toJSON(),
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: "CPQ",

          ref: this.quoteData.externalId,
        },
        searchQuery: "",
      };
      const logaccess = await MLTAxiosInstance.post(
        `/util/filterData`,
        logpayload
      );
      this.activityLogData = logaccess.data.data;
      this.activitytotal = logaccess.data.total;
      console.log(logaccess.data.data);
    },
    // showWorkflowFunc() {
    //   this.showWorkFlow = true;
    // },
    ...mapActions(["triggerVersionHistory"]),
    async showVersionHistory() {
      this.triggerVersionHistory(true);
      await this.saveQuote();
    },
    async profileUploadAcc(event) {
      console.log(event);
      let selectedFiles = event.target.files[0];
      let formData = new FormData();

      // Append all selected files to FormData object
      formData.append('files', selectedFiles);
      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      console.log(data);

      let obj = {};
      obj.name = data.data[0].filename;
      obj.size = data.data[0].data.attachment.size;
      obj.url = data.data[0].url;
      obj.id = data.data[0].data._id;
      obj['@type'] = 'profile';
      // Get the current date and time in milliseconds
      const currentTimestamp = new Date().toJSON();

      // Format the current timestamp in the same format as '1705574420667'
      console.log(currentTimestamp);
      obj.lastUpdated = currentTimestamp;
      this.newAccPayload.attachment = [];
      this.newAccPayload.attachment.push(obj);

      console.log(this.newAccPayload);
    },

    async profileUploadCon(event) {
      console.log(event);
      let selectedFiles = event.target.files[0];
      let formData = new FormData();

      // Append all selected files to FormData object
      formData.append('files', selectedFiles);
      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      console.log(data);

      let obj = {};
      obj.name = data.data[0].filename;
      obj.size = data.data[0].data.attachment.size;
      obj.url = data.data[0].url;
      obj.id = data.data[0].data._id;
      obj['@type'] = 'profile';
      // Get the current date and time in milliseconds
      const currentTimestamp = new Date().toJSON();

      // Format the current timestamp in the same format as '1705574420667'
      console.log(currentTimestamp);
      obj.lastUpdated = currentTimestamp;
      this.newConPayload.attachment = [];
      this.newConPayload.attachment.push(obj);

      console.log(this.newConPayload);
    },

    closeTheBox(item) {
      console.log("item...", item);

      // Find the index of the clicked item
      const index = this.recipientsList.findIndex(
        (recipient) =>
          recipient.name === item.name &&
          recipient.role === item.role &&
          recipient.company === item.company
      );
      console.log("index...", index, this.recipientsList);

      // If the item is found, remove it
      if (index !== -1) {
        this.recipientsList.splice(index, 1);
        console.log(`Removed:`, this.recipientsList);
      } else {
        console.log(`Item not found in the list`);
      }
    },

    addNewContactList(val) {
      // if (this.propsForMultiSignComponent) {
      //   const existingItem = this.propsForMultiSignComponent.find(
      //     (ele) => ele.id === val.id
      //   );

      //   if (!existingItem) {
      //               console.log("if else")
      //     const obj = {
      //     name: val.name,
      //     role: val.aristocraticTitle,
      //     company: val.company,
      //     id: val?.id,
      //     email: val?.email,
      //   };
      //     this.propsForMultiSignComponent.push(obj);
      //   }
      //   else if(existingItem){
      //     console.log("else")
      //     return
      //   }
      // }
      // console.log(
      //   "this.propsForMultiSignComponent",
      //   this.propsForMultiSignComponent
      // );
      this.showDraggableFunctionQuoteRecepient = this
        .showDraggableFunctionQuoteRecepient
        ? true
        : false;
      console.log("addNew Contact List...", val);
      const existingItemForRecipients= this.recipientsList.find(
          (ele) => ele.id === val.id
        );
      if (val && !existingItemForRecipients) {
        val.aristocraticTitle;
        val.name;
        val.company;
        const obj = {
          name: val.name,
          role: val.aristocraticTitle,
          company: val.company,
          id: val?.id,
          email: val?.email,
        };
        this.recipientsList.push(obj);
      }
      else if(existingItemForRecipients){
        this.recipientSelectKey += 1;
          return 
        }
        this.wen = "";
      this.recipientSelectKey += 1;
      console.log(
        "this.recipientKey...",
        this.recipientsList,
        this.recipientKey,
        this.wen
      );
    },

    openDrawerForRecipients() {
      this.recipientKey+=1;
      this.showDraggableFunctionQuoteRecepient = false;
      this.showRecipientDrawer = true;
      console.log("getRecipientList click", this.recipientsList);
    },

    closeContactDrawer() {
      this.initialRecipientsList.length>0?this.recipientsList= JSON.parse(JSON.stringify(this.initialRecipientsList)):this.recipientsList=[]
      this.showRecipientDrawer = false;
    },

    selectedHierarchyAccountDetails(val) {
      val;
      console.log("adacc selectedHierarchyAccountDetails...", val);

      this.newConPayload.tradingName = this.selectedHierarchyAccount;
      this.newConPayload.company = this.selectedHierarchyAccount;
      this.newConPayload.companyName = this.selectedHierarchyAccount;
    },

    async addNewContact() {
      console.log('this.newConPayload...', this.newConPayload);
      this.canAddContact = false;
      // this.newConPayload["role"] = this.rolee;
      let payload = JSON.parse(JSON.stringify(this.newConPayload));
      payload.role = payload.aristocraticTitle;
      payload.relatedParty.push({
        role: payload.aristocraticTitle,
        organization: payload.company,
        '@type': 'multiorganization',
      });

      if (this.selectedHierarchyAccount === '') {
        payload.tradingName = this.companyCon;
        payload.companyName = this.companyCon;
        payload.company = this.companyCon;
      }
      payload.lastUpdate = new Date();

      console.log('adacc finalpayload',this.companyCon , payload, this.selectedHierarchyAccount);

      let result;
      this.isExistingName = false;
      this.isExistingNameErrorMessage = '';
      this.isExistingEmail = false;
      this.isExistingEmailErrorMessage = '';
      try {
        result = await MLTAxiosInstance.post(
          `/partymanagement/individual/account/signup`,
          payload
        );
      } catch (error) {
        console.log(
          error,
          error.response.data.message.includes(
            'db already exists with different case'
          ),
          result
        );
        if (
          error.response.data.message.includes(
            'db already exists with different case'
          )
        ) {
          this.isExistingName = true;
          this.isExistingNameErrorMessage =
            'Account already exists with different case';
          return;
        } else if (
          error.response.data.message.includes(
            'This email was already registered'
          )
        ) {
          this.isExistingEmail = true;
          this.isExistingEmailErrorMessage = 'This email is already in use';
          return;
        } else {
          this.isExistingName = false;
          this.isExistingNameErrorMessage = '';
          this.isExistingEmail = false;
          this.isExistingEmailErrorMessage = '';
        }
        return;
      }
      if (result.data.message == 'This email was already registered') {
        this.isExistingEmail = true;
        this.isExistingEmailErrorMessage = 'This email is already registered';
        return;
      }
      console.log('new result', result);

      this.addressKey += 1;

      if (result?.data?.tradingName !== '') {
        this.recipientContacts.push(payload);
        this.handleSelectedRecipientContact(payload);
        this.notification = {
          type: 'success',
          message: `Nice work! Your new contact "${this.newConPayload.name}" is now in the list!`,
        };
      }
      this.closeAddNewContactModal();
      // this.contactsDataCalling(this.currPage, this.rowsPerPage);
    },

    closeAddNewContactModal() {
      this.addressKey += 1;
      let x = {
        name: '',
        birthDate: '',
        firstName: '',
        lastName: '',
        title: '',
        tradingName: '',
        companyName: '',
        status: 'Pending',
        preferred: '',
        partyCharacteristic: [
          {
            name: 'number of employees',
            value: '',
          },
          {
            name: 'market segment',
            value: '',
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: 'email',
            characteristic: {
              emailAddress: '',
            },
          },
          {
            mediumType: 'phone',
            characteristic: {
              phoneNumber: {
                value: '',
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: 'postalAddress',
            characteristic: {
              city: '',
              country: '',
              postCode: '',
              stateOrProvince: '',
              street1: '',
              street2: '',
              timezone: '',
              description: '',
              '@type': '',
            },
          },
        ],
        externalReference: [
          {
            name: '',
            externalIdentifierType: 'linkedIn',
          },
        ],
        relatedParty: [],
        tags: [],
        aristocraticTitle: '',
        role: '',
        type: 'individual',
        '@baseType': 'customer',
        notes: [],
        attachment: [],
        password: '',
        email: '',
      };
      this.newConPayload = JSON.parse(JSON.stringify(x));
      this.modalKey += 1;
      this.showAddNewContactModal = false;
    },

    async selectedHierarchyContactFunc(val) {
      const f = {
        company: this.company,
        type: 'filter-search',
        module: 'hierarchyaccounts',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 5,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {
        status: { $regex: 'active', $options: 'i' },
        '@baseType': 'customer',
      },
        searchQuery: val,
      };
      let data = await MLTAxiosInstance.post(`util/filterData`, f);
      console.log(data);
      this.organizationList = data.data.data;
    }, 

    async addNewContactModal() {
      
      // this.newConPayload.name = ""
        
        this.refreshContactModal += 1;  
        // setTimeout(() => {
          this.refreshProfile += 1;
          this.newConPayload.name = ""
        // }, 5000);
      
      // this.newConPayload = JSON.parse(JSON.stringify(this.newConPayloadCopy));
      this.selectedHierarchyContactFunc('');
      if(this.accountPreviewInContact){
        const f = {
        company: this.company,
        type: 'filter-search',
        module: 'hierarchyaccounts',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 5,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {
        status: { $regex: 'active', $options: 'i' },
        '@baseType': 'customer',
      },
        searchQuery: this.accountPreviewInContact,
      };
      let data = await MLTAxiosInstance.post(`util/filterData`, f);
      this.organizationList = data.data.data;
      }
      this.newConPayload['role'] = '';
      // this.selectedHierarchyAccount = "";
      this.addressKey += 1;
      this.refreshSelect += 1;
      this.componentkey += 1;
      // if(!this.accountPreviewInContact){
      // this.accountPreviewInContact =
      //   this.invoiceTemplatePayload?.recipient?.businessDetails?.businessName?.value;
      // }
      this.selectedHierarchyAccount = this.accountPreviewInContact || this.accountPreviewInContact1;
      
        this.newConPayload.tradingName = this.accountPreviewInContact;
        this.newConPayload.company = this.accountPreviewInContact;
        this.newConPayload.companyName = this.accountPreviewInContact;

      this.newConPayload.contactMedium[1].characteristic.phoneNumber.country = {
        name: this.$store.getters.defaultSetting?.country,
        short: this.$store.getters.defaultSetting?.cca2,
        flags: this.$store.getters.defaultSetting?.flags[0]?.svg,
      };
      this.showAddNewContactModal = true;
    },

    computedQuoteState(state, authorization) {
      // Find client signature status
      let clientSignature = "";
      if (authorization) {
        if (authorization?.length > 0) {
          clientSignature = authorization.find(
            (auth) => auth.name == "Quote client confirmation"
          );
        }
      }

      return state
        ? state.toLowerCase() == "accepted"
          ? "Completed"
          : state.toLowerCase() == "pending"
          ? "Awaiting approval"
          : state.toLowerCase() == "approved" &&
            clientSignature &&
            clientSignature.state?.toLowerCase() == "inprogress"
          ? // ? "Approved"  change by asif
            "Sent"
          : state.toLowerCase() == "approved" &&
            clientSignature &&
            clientSignature.state?.toLowerCase() == "pending"
          ? "Sent"
          : state.toLowerCase() == "rejected"
          ? "Rejected"
          : state.toLowerCase() == "cancelled"
          ? "Rejected"
          : state.toLowerCase() == "inprogress" &&
            clientSignature &&
            clientSignature.state?.toLowerCase() == "inprogress"
          ? "Draft"
          : state.charAt(0).toUpperCase() + state.slice(1).toLowerCase()
        : "";
    },

    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      obj.module = "cm";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      // let tagsList = await DMNAxiosInstance.get(`mapRef/collection/tagsList`);
      let arr = this.newAccPayload.tags;
      arr.push(e.name);
      this.newAccPayload.tags = arr;
      let tagsList = await this.getReferanceData('tagsList')
      this.tagsList = tagsList.filter((item) => {
        return item.module === "cm";
      });
      // console.log(e);
      // this.tagsList.push(e);
    },

    selectedExecutive(val) {
      let obj = {};
      obj.name = val.name;
      obj.id = val._id;
      obj.role = "Account executive";
      this.newAccPayload.relatedParty.push(obj);
      console.log(val, this.newAccPayload.relatedParty);
    },

    async addNewAccount() {
      console.log(this.newAccPayload);
      let payload = JSON.parse(JSON.stringify(this.newAccPayload));
      if (payload.contactMedium[3].characteristic.description !== '') {
        let billingAddress = JSON.parse(
          JSON.stringify(payload.contactMedium[3])
        );
        let siteAddress = JSON.parse(JSON.stringify(payload.contactMedium[3]));
        billingAddress.mediumType = 'billingLocation';
        billingAddress.characteristic.phone =
          payload.contactMedium[1].characteristic;
        billingAddress.characteristic.email =
          payload.contactMedium[0].characteristic;
        billingAddress.characteristic.locationName = payload.name;
        billingAddress.characteristic.default = true;

        siteAddress.mediumType = 'siteLocation';
        siteAddress.characteristic.phone =
          payload.contactMedium[1].characteristic;
        siteAddress.characteristic.email =
          payload.contactMedium[0].characteristic;
        siteAddress.characteristic.locationName = payload.name;
        siteAddress.characteristic.default = true;

        payload.contactMedium.push(billingAddress);
        payload.contactMedium.push(siteAddress);
      }
      payload.lastUpdate = new Date();
      let result;
      this.isExistingName = false;
      this.isExistingNameErrorMessage = '';
      this.isExistingEmail = false;
      this.isExistingEmailErrorMessage = '';
      try {
        result = await MLTAxiosInstance.post(
          `/partymanagement/individual/account/signup`,
          payload
        );
      } catch (error) {
        console.log(
          error,
          error.response.data.message.includes(
            'db already exists with different case'
          ),
          result
        );
        if (
          error.response.data.message.includes(
            'db already exists with different case'
          )
        ) {
          this.isExistingName = true;
          this.isExistingNameErrorMessage =
            'Account already exists with different case';
          return;
        } else if (error.response.data.message.includes('email_1 dup key')) {
          this.isExistingEmail = true;
          this.isExistingEmailErrorMessage = 'This email is already in use';
          return;
        } else {
          this.isExistingName = false;
          this.isExistingNameErrorMessage = '';
          this.isExistingEmail = false;
          this.isExistingEmailErrorMessage = '';
        }
        return;
      }
      if (result.data.message == 'Company already exist') {
        this.isExistingName = true;
        this.isExistingNameErrorMessage = 'Company name already exists';
        return;
      }
      console.log('new result', result);

      this.addressKeyAccount += 1;
      this.closeAddNewAccountModal();
      // console.log(result);
      if (result?.data?.tradingName !== '') {
        this.notification = {
          type: 'success',
          message: `Nice work! Your new organization "${this.newAccPayload.name}" is now in the list!`,
        };
        this.handleRecipientChanges(payload, this.$route.query?.mode == 'edit');
      }
      // this.accountsData();
      // await this.accountsDataCalling();
    },

    addNewAccountModal() {
      this.refreshProfile1 += 1;
      console.log('add new account...');
      this.modalKeyAccount += 1;
      this.isUrlEmpty = false;
      this.locationType = '';
      this.accExecParty = '';
      this.newAccPayload = JSON.parse(JSON.stringify(this.newAccPayloadCopy));
      this.newAccPayload.contactMedium[1].characteristic.phoneNumber.country = {
        name: this.$store.getters.defaultSetting?.country,
        short: this.$store.getters.defaultSetting?.cca2,
        flags: this.$store.getters.defaultSetting?.flags[0].svg,
      };
      this.showAddNewAccountModal = true;
      // this.$router.push('/CM/accounts/add');
    },

    sanitizeDatabaseName(databaseName) {
      // Replace dots with an underscore or any other symbol you want
      const sanitized = databaseName?.replace(/\./g, "_");

      // Validate the constraints for MongoDB database names
      const validDatabaseName = sanitized?.replace(/[^a-zA-Z0-9_]/g, "");

      return validDatabaseName;
    },

    websiteRegex(val) {
      const websiteRegex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

      if (val?.length >= 1) {
        if (!websiteRegex.test(val)) {
          this.isUrlEmpty = true; // URL is invalid
        } else {
          this.isUrlEmpty = false;
        }
      } else {
        this.isUrlEmpty = false;
      }
    },

    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      }
    },

    validatePhoneNumber(val) {
      return val.valid;
    },

    closeAddNewAccountModal() {
      this.addressKey += 1;
      this.accExecParty = "";
      this.modalKeyAccount += 1;
      this.newAccPayload = JSON.parse(JSON.stringify(this.newAccPayloadCopy));
      this.showAddNewAccountModal = false;
    },

    deepEqual(obj1, obj2) {
      // Check if both are strictly equal
      if (obj1 === obj2) {
        return true;
      }

      // Check if either is not an object or is null
      if (
        typeof obj1 !== "object" ||
        obj1 === null ||
        typeof obj2 !== "object" ||
        obj2 === null
      ) {
        return false;
      }

      // Get the keys of both objects
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      // Check if the number of keys is different
      if (keys1.length !== keys2.length) {
        return false;
      }

      // Recursively check each key and value
      for (let key of keys1) {
        if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }

      return true;
    },
    emitStatus(val) {
      this.status = val["value"];
    },

    statusVmodel(val) {
      if (!val) return "null";
      else {
        console.log("statusvmodel...", val);

        const status = val?.toLowerCase();

        switch (status?.toLowerCase()) {
          case "inprogress":
            this.statusBool = false;
            return "Draft";
          case "awaiting approval":
            this.statusBool = false;
            return "Awaiting approval";
          case "approved":
            this.statusBool = false;
            return "Approved";
          case "send":
            this.statusBool = false;
            return "Sent";
          case "sent":
            this.statusBool = false;
            return "Sent";
          case "completed":
            this.statusBool = true;
            return "Completed";
          case "expired":
            this.statusBool = true;
            return "Expired";
          case "declined":
            this.statusBool = true;
            return "Declined";
          case "rejected":
            this.statusBool = true;
            return "Rejected";
          default:
            return val;
        }
      }
    },

    formatCurrentTime() {
      const now = new Date();
      const year = now.getUTCFullYear();
      const month = String(now.getUTCMonth() + 1).padStart(2, "0");
      const day = String(now.getUTCDate()).padStart(2, "0");
      const hours = String(now.getUTCHours()).padStart(2, "0");
      const minutes = String(now.getUTCMinutes()).padStart(2, "0");
      const seconds = String(now.getUTCSeconds()).padStart(2, "0");
      const milliseconds = String(now.getUTCMilliseconds()).padStart(3, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    },

    formatUpdateTime(timestamp) {
      if (timestamp) {
        const date = new Date(timestamp);
        const now = new Date();

        const timeDiff = now - date;
        const minutesDiff = Math.floor(timeDiff / (1000 * 60));
        const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        if (minutesDiff < 1) {
          return "Updated just now";
        } else if (minutesDiff < 60) {
          return `Updated ${minutesDiff} minute${
            minutesDiff > 1 ? "s" : ""
          } ago`;
        } else if (hoursDiff < 24) {
          return `Updated ${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
        } else if (daysDiff < 1) {
          return "Updated today";
        } else if (daysDiff === 1) {
          return "Updated yesterday";
        } else if (date.getFullYear() === now.getFullYear()) {
          return `Updated ${date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
          })}`;
        } else {
          return `Updated ${date.getFullYear()} ${date.toLocaleDateString(
            "en-US",
            { month: "short", day: "numeric" }
          )}`;
        }
      }
    },

    async cloneQuote() {
    console.log("clonning....");






    
      if (this.quoteData) {



        
      // Generate quote number
      const quoteIDRes = await UPCAxiosInstance.post("/genericID", {
        module: "product",
        subModule: "invoice",
        prefix: "INV",
        // isBasedOnCollection: true,
        // collection: "customerbill",
      });
      if (quoteIDRes.data && quoteIDRes.data.ok && quoteIDRes.data.ID) {
        this.quoteData.billNo =
          quoteIDRes.data.ID;
      }
   



      
        // this.quoteData.displayID = "";
        this.quoteData.id = "";
        this.quoteData._id = "";
        this.quoteData.state = "onHold";
        this.quoteData.description = this.quoteData.description + "_copy";
        this.quoteData.lastUpdate = `${this.formatCurrentTime()}`;
        this.quoteData.createdAt = `${this.formatCurrentTime()}`;
        this.quoteData.lastUpdate = `${this.formatCurrentTime()}`;

        const clonedQuote = await UPCAxiosInstance.post(
          "/customerBill",
          this.quoteData
        );
        console.log("clonedQuote...", clonedQuote);

        if (clonedQuote.status == 201) {
          this.notification = {
            type: "success",
            message: `Nice work! Your Invoice ${this.quoteData.billNo} is cloned!`,
          };
        }
      }
    },

    checkForResize() {
      if (window.innerWidth < 1024 && !this.tabletMode) {
        this.tabletMode = true;
        console.log(window.innerWidth, this.tabletMode, "iw resize");
      } else if (window.innerWidth > 1024 && this.tabletMode) {
        this.tabletMode = false;
        console.log(window.innerWidth, this.tabletMode, "iw resize");
      }
    },
    showDrawerRight() {
      // this.filterPrevalueDuplicate1 = {}
      // this.filterPrevalueDuplicate1 = JSON.parse(JSON.stringify(this.filterPrevalue))
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },
    transformFilterPayload(payload) {
      return payload.reduce((acc, item) => {
        if (item.checked) {
          let key = item.key.toLowerCase();
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item.name);
        }
        return acc;
      }, {});
    },
    async offeringFilterData(filterSelectedvalue, filterQuery) {
      filterSelectedvalue, filterQuery;
      this.filterPrevalue = this.transformFilterPayload(filterSelectedvalue);

      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      filterQuery.company = this.company;
      this.filterQuery["paginatedQuery"] = {
        rowsPerPage: this.externalRowsPerPage,
        currentPage: this.currentPage,
      };
      //  filterQuery['filterQuery']['"@baseType"'] = 'supplier'
      if (this.filtertagvalue.length != 0) {
        console.log("filter ku ulla");

        this.filterQuery = filterQuery;
        this.filterQuery["module"] = "discountOffers";
        this.filterQuery.type = "filter";
        if (this.searchvalue != "") {
          this.filterQuery["searchQuery"] = this.searchvalue;
          this.filterQuery["type"] = "filter-search";
        }
        //   const filterResultData = await UPCAxiosInstance.post(
        //     `util/filterData`,
        //     this.filterQuery
        //   );
        //   console.log(filterResultData, 'filterResultData.data.data');

        //   this.card_data_length = filterResultData.data.total;
        // this.card_ data = this.transformData(filterResultData.data.data);
        // console.log("card _data", this.card_d ata);
        // this.card _data.forEach((i) => {
        //   this.getProductImageData(i);
        // });
        this.getOfferData();
        // this.tableData = filterResultData.data.data;
        // this.totalpageno = filterResultData.data.total;
      } else {
        console.log("filter ku veliya");

        this.filterPrevalue = {};
        this.filterQuery.filterQuery = {};
        this.filterPrevalueDuplicate1 = {};
        this.filterPrevalueDuplicate2 = {};
        this.offeringcurrPage = 1;
        this.offeringrowsPerPage = 10;
        //   const filterResultData = await UPCAxiosInstance.post(
        //     `util/filterData`,
        //     this.filterQuery
        //   );
        //   this.card_data_length = filterResultData.data.total;
        // this.car d_data = this.transformData(filterResultData.data.data);
        // console.log("card _data", this.card _data);
        // this.card_ data.forEach((i) => {
        //   this.getProductImageData(i);
        // });
        //   console.log(filterResultData, 'filterResultData.data.data');
        // this.offeringdataCalling(
        //   this.offeringcurrPage,
        //   this.offeringrowsPerPage
        // );
        this.getOfferData();
      }
      console.log(
        filterSelectedvalue,
        filterQuery,
        this.transformFilterPayload(filterSelectedvalue),
        this.filterPrevalue,
        "configure filter data"
      );
    },
    sendForReconfiguration(item) {
      console.log("emit... sendForReconfiguration",item);
      console.log(item,'totalVs')
      this.reconfigureData = JSON.parse(JSON.stringify(item.data));
      this.reconfigureDataIndex = item.index;
      this.reconfigure = true;
      this.configOfferId = item.data.offerId;
      this.show_configuration = true;
      this.isDepFlow = false;
      this.show_offering = true;
      console.log(
        this.configOfferId,
        this.show_offering,
        this.show_configuration,
        "ide"
      );
    },
    truncateValue(newval) {
      console.log("newvaluewss", newval);
      if (newval?.length > 150) {
        this.invoiceTemplatePayload.header.title.readOnly =
            !this.invoiceTemplatePayload.header.title.readOnly;

        this.invoiceTemplatePayload.header.title.value = newval?.slice(0, 150);
        console.log(this.invoiceTemplatePayload.header.title.value);
      } else {
        this.invoiceTemplatePayload.header.title.value = newval;
        console.log(this.invoiceTemplatePayload.header.title.value);
      }
    },

    truncateValuesub(newval) {
      console.log("newvaluewss", newval);
      if (newval?.length > 200) {
        this.invoiceTemplatePayload.header.subtitle.readOnly =
              !this.invoiceTemplatePayload.header.subtitle.readOnly;
              
        this.invoiceTemplatePayload.header.subtitle.value = newval?.slice(0, 200);
        console.log(this.invoiceTemplatePayload.header.subtitle.value);
      } else {
        this.invoiceTemplatePayload.header.subtitle.value = newval;
        console.log(this.invoiceTemplatePayload.header.subtitle.value);
      }
    },
    activityLogDrawer() {
      this.activiylogcall();
      this.show_right = true;
      this.activtykey += 1;
    },
    /**Here we'll check for difference between 2 data using our global method and return if no changes found otherwise prevent page loading */
    preventPageReload(event) {
      console.log("🚀 ~ preventPageReload ~ event:", event);
      const unsavedChanges = this.isPayloadChanged(
        {
          ...this.initialData,
          quoteBasicInfo: {
            ...this.initialData.quoteBasicInfo,
            quoteNumber: {
              ...this.initialData.quoteBasicInfo.quoteNumber,
              value: "",
            },
          },
        },
        {
          ...this.invoiceTemplatePayload,
          quoteBasicInfo: {
            ...this.invoiceTemplatePayload.quoteBasicInfo,
            quoteNumber: {
              ...this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber,
              value: "",
            },
          },
        }
      );
      console.log("unsavedChanges", unsavedChanges);
      if (!unsavedChanges) return;

      // this.enableDiscardModal = true;
      // Chrome requires returnValue to be set.

      event.preventDefault();
      // event.stopPropagation();
      event.returnValue = "";
      return false;
    },
    scrollToDiv() {
      this.$nextTick(() => {
        const element = document.getElementById("pricing-table-container-#1");
        if (element) {
          // Scroll smoothly to the target element
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    clickBack() {
      console.log("edit data 6", this.tempPricingTableData);
      this.show_offering = false;
      this.show_side_panel = false;
      this.searchOffers("");
      this.quoteSummaryDataFromRules = JSON.parse(
        JSON.stringify(this.tempQuoteSummaryDataFromRules)
      );
      if (this.selectedProductOfferingData.length === 0) {
        console.log(
          "edit data 6.1",
          this.sendSelectedProductOfferingData,
          this.tempPricingTableData
        );

        const x = JSON.parse(JSON.stringify(this.tempPricingTableData));
        console.log("emit...123 sendSelectedProductOfferingData",x)
        this.sendSelectedProductOfferingData = JSON.parse(JSON.stringify(x));

        console.log(
          "edit data 6.2",
          this.sendSelectedProductOfferingData,
          this.tempPricingTableData,
          x
        );
      }
      this.scrollToDiv();
    },
    handleStay() {
      // //handle Stay funtionality to hide discard component
      this.enableDiscardModal = !this.enableDiscardModal;
      console.log(
        "🚀 ~ handleStay ~ this.enableDiscardModal:",
        this.enableDiscardModal
      );
    },
    handleDiscardClose() {
      this.enableDiscardModal = false;
    },
    async handleDiscard() {
      // Handle discard functionalities like hide discard component,set discardIt as true, and push the routes where ever you want.
      // this.$router.push({ name: "QuoteTable" });

      // this.$router.back();
      // this.enableDiscardModal = !this.enableDiscardModal;
      // this.discardIt = true;

      // Delete quoteID
      // if (
      //   this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value &&
      //   this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value != "QOT-9XX9" && (!this.$router.query.mode || ( this.$route.query.mode && this.$router.query.mode != "edit"))
      // ) {
      //   await UPCAxiosInstance.delete(`/genericID`, {
      //     module: "product",
      //     subModule: "quote",
      //     prefix: "QOT",
      //     ID: this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value,
      //   });
      // }
      if (this.tempBack) {
        this.$router.back();
      } else {
        this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      }
      this.enableDiscardModal = !this.enableDiscardModal;
      this.discardIt = true;
    },
    addToCart($event, item) {
      console.log("🚀 ~ addToCart ~ $event:", $event);
      this.cartItems.push(item.id);
      // const ele = $event.target;
      // if (ele.classList.contains("custom-addtocart-btn")) {
      //   ele.classList.add("item-added-to-cart");
      //   ele.children[0]?.classList?.add("icon-check-circle-filled");
      //   ele.children[0]?.classList?.remove("icon-shopping-cart-plus-regular");
      //   ele.innerText = "Added";
      // } else {
      //   ele.parentElement.classList.add("item-added-to-cart");
      //   ele.parentElement.children[0]?.classList?.add(
      //     "icon-check-circle-filled"
      //   );
      //   ele.parentElement.children[0]?.classList?.remove(
      //     "icon-shopping-cart-plus-regular"
      //   );
      //   ele.parentElement.innerText = "Added";
      // }
    },
    showConfigurationPage(val) {
      console.log(val, "showConfigurationPage");
      this.show_configuration = true;
      this.isDepFlow = false;
      this.configOfferId = val.id;
    },
    closeConfigurationPage() {
      console.log("hello world");
      this.show_configuration = false;
      this.configOfferId = "";
      this.filterPrevalueDuplicate1 = JSON.parse(
        JSON.stringify(this.filterPrevalue)
      );
      this.filterPrevalueDuplicate2 = JSON.parse(
        JSON.stringify(this.filterPrevalue)
      );
    },
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      // this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    async getProductImageData(val) {
      if (
        val.prodSpecCharValue[0]?.attachment !== undefined &&
        val?.prodSpecCharValue[0]?.attachment?.length > 0
      ) {
        console.log(val, "image data for products");
        const url = await UPCAxiosInstance.get(
          `/gcpupload/files/${val.prodSpecCharValue[0]?.attachment[0]?.name}`,
          {
            responseType: "arraybuffer",
          }
        );
        // console.log(await this.bufferToDataURL(url.data), "urlll get");
        this.imageComponentKey += 1;
        val.prodSpecCharValue[0].attachment[0].image_url =
          await this.bufferToDataURL(url.data);
        // this.rawProfileUrlData = url.data;
      } else if (val.prodSpecCharValue[0] !== undefined) {
        val.prodSpecCharValue[0].attachment = [];
        val.prodSpecCharValue[0].attachment.push({
          image_url: "",
        });
      }
    },
    handleQuoteDateChange(date) {
      console.log("🚀 ~ handleQuoteDateChange ~ date:", date);
      this.invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value =
        this.addDatesToGivenDateV1(
          date,
          this.invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting.value
        );
    },
    async handleProfilePictureUpload(file) {
      console.log(
        "🚀 ~ handleProfilePictureUpload ~ file:",
        file,
        file.target,
        file.target.result
      );
      // console.log(file[0]);
      let uploadingFile = file.target.files;
      let formData = new FormData();

      // // Append all selected files to FormData object
      for (let i = 0; i < uploadingFile.length; i++) {
        formData.append("files[]", uploadingFile[i]);
      }

      // console.log("Uploaded File:", formData.getAll('files[]'));
      // this.$emit('upload-data', formData);
      // console.log("🚀 ~ handleProilePictureUpload ~ file:", file)
      // let profileFormData = new FormData()
      //  profileFormData.append('files[]',uploadingFile)
      console.log(
        "🚀 ~ handleProfilePictureUpload ~ this.profileFormData:",
        this.profileFormData
      );
      // const uploadedProfile = await axios.post('http://localhost:5700/gcpupload/upload',this.profileFormData,{headers:{Authorization:this.$cookies.get('token')}})
      const uploadedProfile = await UPCAxiosInstance.post(
        "/gcpMultiple/v2/upload",
        formData
      );
      this.profilePicture = {
        id: uploadedProfile.data[0]?.data._id,
        attachmentType: "profile",
        description: "Sender profile picture",
        mimeType: uploadedProfile.data[0]?.data?.attachment?.contentType,
        name: uploadedProfile.data[0]?.filename,
        url: uploadedProfile.data[0]?.url,
        size: {
          amount: uploadedProfile.data[0]?.data?.attachment?.size,
          units: "KB",
        },
      };
      console.log(
        "🚀 ~ handleProfilePictureUpload ~ this.profilePicture:",
        this.profilePicture
      );
      console.log("🚀 ~ saveQuote ~ uploadedProfile:", uploadedProfile);
    },
    mapFromTMF648(tmfData) {
      const rawItems = tmfData?.map((item) => {
        const rawItem = {
          section: item?.section ? true : false,
          custom: item?.custom ? true : false,
          address: item?.section ? true : false,
          productSpecification: {
            ...item.productSpecification,
            "@type": "ProductSpecification",
          },
          productOffering: {
            ...item.productOffering,
            "@type": "ProductOffering",
          },
          offerId: item.offerId,
          productCharacteristic: item?.productCharacteristic,
          prodSpecCharValueUse: item?.prodSpecCharValueUse,
          product: item?.product,
          additionalCharges: item?.additionalCharges || [],
          product_offering_name: item.section
            ? item?.product_offering_name
            : item?.custom
            ? item?.product_offering_name
            : item.productOffering?.name,
          unit_of_measure: item.section ? "" : "Each", // Default value
          rate: item?.quoteItemPrice[0]?.price?.dutyFreeAmount?.value?.toString(),
          quantity: item?.quantity,
          discountResponse: item?.discountResponse,
          supplierUnitCost: item?.supplierUnitCost,
          unitMsrp: item?.unitMsrp,
          rebate: item?.rebate,
          duration: item?.duration,
          unitOfMeasure: item?.unitOfMeasure,
          billing_period: item?.billing_period,
          billing_period_options: item?.billing_period_options,
          discount: item?.discount, // Default value
          amount: item?.quoteItemPrice[0]?.price?.dutyFreeAmount?.value,
          discount_type: "%", // Default value
          line_total:
            item?.quoteItemPrice[0]?.price?.taxIncludedAmount?.value?.toString(),
          tax: 0, // Default value
          notes: item?.notes || "", // Default value
          description: item?.description, // Default value
          commitment_length: item?.section ? "" : 1, // Default value
          // billing_period: item.quoteItemPrice[0]?.recurringChargePeriod,
          intangible: item?.quoteItemPrice[0]?.priceType === "Recurring",
        };

        return rawItem;
      });

      return rawItems;
    },
    receivePricingData(val) {
      console.log("emit... receivePricingData",val)
      console.log(val,'totalVs/ QuoteItem')
      console.log(val, "quotePricingData --");
      this.productOfferingItem = JSON.parse(JSON.stringify(val))
      this.quotePricingData = JSON.parse(JSON.stringify(val));
    },
    receiveTotalPricingData(val) {
      console.log("emit... receiveTotalPricingData",val);
      console.log(val,'totalVs/summary')
      console.log(val, "received pricing data from table");
      this.productOfferingSummary =JSON.parse(JSON.stringify(val))
      this.quoteTotalPricingData = JSON.parse(JSON.stringify(val));
    },
    receiveSummaryfromRules(val) {
      console.log("emit.. receiveSummaryfromRules",val);
      console.log(val,'totalVs')
      if (val !== undefined) {
        this.tempQuoteSummaryDataFromRules = JSON.parse(JSON.stringify(val));
      } else {
        this.tempQuoteSummaryDataFromRules = {
          showOneTimeSummary: false,
          showRecurringSummary: false,
          oneTime: {
            subTotal: 0,
            tax: 0,
            discount: [],
            total: 0,
          },
          recurring: {
            subTotal: 0,
            tax: 0,
            discount: [],
            total: 0,
          },
          overAllTotal: 0,
          amountSaved: 0,
          totalContractValue: 0,
        };
      }
    },
    quoteAPIPayloadToTemplatePayload(payload) {
      // console.log("🚀 ~ quoteAPIPayloadToTemplatePayload ~ payload:", payload)
      // Map title
      this.invoiceTemplatePayload.header.title.value = payload.description;
      this.invoiceTemplatePayload.state = payload.state;
      // Map quote numner
      // this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value =
      //   payload?.displayID;
        // invoice no
        this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value =
        payload?.billNo;
        //invoice date
        this.invoiceTemplatePayload.quoteBasicInfo.quoteDate.value =
        payload?.billDate;
        payload?.attachmentRef?.filter((item) => {
        console.log(this.invoiceTemplatePayload,'payload')
        if (item["@type"] == "subTitleNote") {
          this.invoiceTemplatePayload.header.subtitle.value = item.text;
          if (item.text) {
            this.invoiceTemplatePayload.header.isQuoteSubtitle = true;
          }
        }
        });
      // Map other titles and values  // command for temp by asif
      // payload.note.filter((item) => {
      //   // console.log(this.invoiceTemplatePayload,'payload')
      //   if (item["@type"] == "subTitleNote") {
      //     this.invoiceTemplatePayload.header.subtitle.value = item.text;
      //     if (item.text) {
      //       this.invoiceTemplatePayload.header.isQuoteSubtitle = true;
      //     }
      //   } else if (item["@type"] == "quoteNumberLabel") {
      //     this.invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel.value =
      //       item.text;
      //   } else if (item["@type"] == "quoteDateLabel") {
      //     this.invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel.value =
      //       item.text;
      //   } else if (item["@type"] == "quoteDate") {
      //     this.invoiceTemplatePayload.quoteBasicInfo.quoteDate.value = item.text;
      //   } else if (item["@type"] == "quoteValidTillDateLabel") {
      //     this.invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDateLabel.value =
      //       item.text;
      //   } else if (item["@type"] == "senderTitle") {
      //     this.invoiceTemplatePayload.sender.title.value = item.text;
      //   } else if (item["@type"] == "recipientTitle") {
      //     this.invoiceTemplatePayload.recipient.title.value = item.text;
      //   } else if (item["@type"] == "recipientContactTitle") {
      //     this.invoiceTemplatePayload.recipientContact.title.value = item.text;
      //   } else if (item["@type"] == "senderContactTitle") {
      //     this.invoiceTemplatePayload.senderContact.title.value = item.text;
      //   } else if (item["@type"] == "recipientShippingDetailsTitle") {
      //     this.invoiceTemplatePayload.recipientShippingDetails.title.value =
      //       item.text;
      //   } else if (item["@type"] == "recipientBillingDetailsTitle") {
      //     this.invoiceTemplatePayload.recipientBillingDetails.title.value =
      //       item.text;
      //   } else if (item["@type"] == "Note") {
      //     this.invoiceTemplatePayload.recipientNotes.value = item.text;
      //     if (this.invoiceTemplatePayload.recipientNotes.value.length > 0) {
      //       let termsIndex = this.termsAndPolicyAreaData.findIndex(
      //         (item) => item?.name == "Add notes"
      //       );
      //       if (
      //         termsIndex >= 0 &&
      //         this.termsAndPolicyAreaData[termsIndex] != undefined
      //       ) {
      //         this.termsAndPolicyAreaData[termsIndex].checked = true;
      //         this.showFields(this.termsAndPolicyAreaData[termsIndex]);
      //       }
      //     }
      //   } else if (item["@type"] == "noteTitle") {
      //     this.invoiceTemplatePayload.recipientNotes.label.value = item.text;
      //   } else if (item["@type"] == "attachmentTitle") {
      //     this.invoiceTemplatePayload.recipientAttachments.label.value =
      //       item.text;
      //   } else if (item["@type"] == "AdditionalNote") {
      //     let labelAndValues = item.text.split(":");
      //     this.invoiceTemplatePayload.recipientAdditionalInfo.additionalFields.push(
      //       {
      //         label: labelAndValues[0],
      //         value: labelAndValues[1],
      //       }
      //     );
      //     if (
      //       this.invoiceTemplatePayload.recipientAdditionalInfo.additionalFields
      //         .length > 0
      //     ) {
      //       let termsIndex = this.termsAndPolicyAreaData.findIndex(
      //         (item) => item?.name == "Add additional info"
      //       );
      //       if (
      //         termsIndex >= 0 &&
      //         this.termsAndPolicyAreaData[termsIndex] != undefined
      //       ) {
      //         this.termsAndPolicyAreaData[termsIndex].checked = true;
      //         this.showFields(this.termsAndPolicyAreaData[termsIndex]);
      //       }
      //     }
      //   } else if (item["@type"] == "quoteBasicAdditionalNote") {
      //     let labelAndValues = item.text.split(":");
      //     this.invoiceTemplatePayload.quoteBasicInfo.additionalFields.push({
      //       label: labelAndValues[0],
      //       value: labelAndValues[1],
      //     });
      //   }
      // });
      // Map terms and contions
      const notesEntry = payload.attachmentRef.find(item => item["@baseType"] === "Notes");

// If "Notes" entry is found, assign its value to recipientNotes
if (notesEntry) {
  this.invoiceTemplatePayload.recipientNotes = notesEntry;
}
if (this.invoiceTemplatePayload.recipientNotes.value.length > 0) {
            let termsIndex = this.termsAndPolicyAreaData.findIndex(
              (item) => item?.name == "Add notes"
            );
            if (
              termsIndex >= 0 &&
              this.termsAndPolicyAreaData[termsIndex] != undefined
            ) {
              this.termsAndPolicyAreaData[termsIndex].checked = true;
              this.showFields(this.termsAndPolicyAreaData[termsIndex]);
            }
          }
        
      payload.attachmentRef.filter((item) => {
        if (item["@type"] == "AdditionalNote") {
          let labelAndValues = item?.text?.split(":");
          this.invoiceTemplatePayload.recipientAdditionalInfo.additionalFields.push({
            label: labelAndValues?.[0] || "Default Label",
            value: labelAndValues?.[1] || "Default Value",
          });
          if (
            this.invoiceTemplatePayload.recipientAdditionalInfo.additionalFields
              .length > 0
          ) {
            let termsIndex = this.termsAndPolicyAreaData.findIndex(
              (item) => item?.name == "Add additional info"
            );
            if (
              termsIndex >= 0 &&
              this.termsAndPolicyAreaData[termsIndex] != undefined
            ) {
              this.termsAndPolicyAreaData[termsIndex].checked = true;
              this.showFields(this.termsAndPolicyAreaData[termsIndex]);
            }
          }
        }
      });
      this.invoiceTemplatePayload.recipientTermsandConditions.conditions = // command for temp by asif
      payload.attachmentRef.filter((item) => item['@baseType'] === 'Agreement')
        .map((item) => {
          return {
            additionalFields: item.terms,
            label: item?.name,
            readOnly: true,
          };
        });
        console.log(this.invoiceTemplatePayload.recipientTermsandConditions.conditions);
        
      if ( 
        this.invoiceTemplatePayload.recipientTermsandConditions.conditions
          .length > 0
      ) {
        let termsIndex = this.termsAndPolicyAreaData.findIndex(
          (item) => item?.name == "Add terms and conditions"
        );
        if (
          termsIndex >= 0 &&
          this.termsAndPolicyAreaData[termsIndex] != undefined
        ) {
          this.termsAndPolicyAreaData[termsIndex].checked = true;
          this.showFields(this.termsAndPolicyAreaData[termsIndex]);
        }
      }
      // Map address
      // const recipientShippingAddress = payload.contactMedium?.find(
      //   (item) => item.mediumType == "recipientShippingAddress"
      // );
      // this.invoiceTemplatePayload.recipientShippingDetails.address.value =
      //   recipientShippingAddress.characteristic;
      // const recipientBillingAddress = payload.contactMedium?.find(
      //   (item) => item.mediumType == "recipientBillingAddress"
      // );
      // this.invoiceTemplatePayload.recipientBillingDetails.address.value =
      //   recipientBillingAddress.characteristic;

      // Map additional notes
      // payload.note.map(item => {
      //   if(item["@type"] == "additionalNote"){
      //     let labelAndValues = item.text.split(":")
      //     return {label:labelAndValues[0],value:labelAndValues[1]}
      //   }
      // }).filter(item)
      // Map subtitle
      // Map title
      // Map title
    },
    addNewOffer(data) {
      console.log("emit... addNewOffer",data)
      console.log(data,'totalVs')
      this.cartItems = [];
      this.show_offering = true;
      this.show_side_panel = false;
      this.filterPrevalueDuplicate1 = JSON.parse(
        JSON.stringify(this.filterPrevalue)
      );
      this.filterPrevalueDuplicate2 = JSON.parse(
        JSON.stringify(this.filterPrevalue)
      );
      // let arr = [];
      // data?.forEach((i) => {
      //   arr.push(JSON.parse(JSON.stringify(i)));
      // });
      if (data !== undefined) {
        this.tempPricingTableData = JSON.parse(JSON.stringify(data));
      }
      console.log(this.tempPricingTableData, "edit data");
    },
    async currentPageData(val) {
      this.currentPage = val;
      await this.getOfferData();
    },
    async changeRowsPerPage(val) {
      this.externalRowsPerPage = val;
      await this.getOfferData();
    },
    selectedOfferFromConfiguration(val) {
      if (this.reconfigure) {
        const newArr = JSON.parse(JSON.stringify(this.tempPricingTableData));
        newArr[this.reconfigureDataIndex] = JSON.parse(JSON.stringify(val));
        this.sendSelectedProductOfferingData = newArr;
        this.show_configuration = false;
        this.show_offering = false;
      } else {
        console.log(typeof val, "offers");

        this.selectedProductOfferingData.push(JSON.parse(JSON.stringify(val)));
        this.sendSelectedProductOfferingData = [
          ...toRaw(JSON.parse(JSON.stringify(this.tempPricingTableData))),
          ...toRaw(this.selectedProductOfferingData),
        ];
        console.log(this.sendSelectedProductOfferingData, "new card");
        this.show_configuration = false;
      }
      this.filterPrevalueDuplicate1 = JSON.parse(
        JSON.stringify(this.filterPrevalue)
      );
      this.filterPrevalueDuplicate2 = JSON.parse(
        JSON.stringify(this.filterPrevalue)
      );
    },
    selectedOfferFromConfigurationFromDep(val) {
      if (this.reconfigure) {
        const newArr = JSON.parse(JSON.stringify(this.tempPricingTableData));
        newArr[this.reconfigureDataIndex] = JSON.parse(JSON.stringify(val));
        this.sendSelectedProductOfferingData = newArr;
        this.show_configuration = false;
        this.show_offering = false;
      } else {
        console.log(typeof val, "offers");
        val.forEach((item) => {
          this.selectedProductOfferingData.push(
            JSON.parse(JSON.stringify(item))
          );
        });
        this.sendSelectedProductOfferingData = [
          ...toRaw(JSON.parse(JSON.stringify(this.tempPricingTableData))),
          ...toRaw(this.selectedProductOfferingData),
        ];
        console.log(this.sendSelectedProductOfferingData, "new card");
        this.show_configuration = false;
        this.show_offering = false;
      }
      this.filterPrevalueDuplicate1 = JSON.parse(
        JSON.stringify(this.filterPrevalue)
      );
      this.filterPrevalueDuplicate2 = JSON.parse(
        JSON.stringify(this.filterPrevalue)
      );
    },
    async selectedOffer($event, card) {
      const ele = $event.target;
      if (ele.classList.contains("item-added-to-cart")) {
        //
      } else if (ele.parentElement.classList.contains("item-added-to-cart")) {
        ///f
      } else {
        card = await this.supplierChanged(card);
        console.log(card, "new card");
        this.selectedProductOfferingData.push(JSON.parse(JSON.stringify(card)));
        this.sendSelectedProductOfferingData = [
          ...toRaw(JSON.parse(JSON.stringify(this.tempPricingTableData))),
          ...toRaw(this.selectedProductOfferingData),
        ];
        console.log(
          "selected offers",
          this.selectedProductOfferingData,
          this.sendSelectedProductOfferingData
        );
      }
    },
    async supplierChanged(card) {
      let val2 = card.relatedParty.filter((i) => {
        return i.role == "supplier";
      });
      let val = val2[0];
      let x = card?.productOfferingPrice[0]?.productOfferingAlteration?.filter(
        (i) => {
          return i?.company == val?.name;
        }
      );
      this.selectedSupplier = val;
      this.selectedConfigurationCost = val?.msrp?.value;

      if (this.newPrice !== null) {
        if (val?.productStockLevel?.cost == 0) {
          // trigger rules
          const supplierRules = await DMNAxiosInstance.post(
            `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
            {
              payload: {
                supplier: {
                  product: [
                    {
                      productOfferingId: card._id,
                      msrp: val?.msrp?.value,
                      sellingPrice: this.newPrice,
                      discount: {
                        skuDiscount: {
                          value: x !== undefined ? x[0].discount : "0",
                          active: 1,
                          cost: 0,
                          startDate:
                            x !== undefined ? x[0]?.validFor?.startDate : "",
                          endDate:
                            x !== undefined ? x[0]?.validFor?.endDate : "",
                        },
                        categoryDiscount: 0,
                        brandDiscount: 0,
                      },
                    },
                  ],
                },
              },
            }
          );
          supplierRules;
          card.supplierUnitCost =
            supplierRules?.data?.data?.supplier?.product[0]?.cost;
          card.rebate = 0;
          this.selectedConfigurationMargin =
            supplierRules?.data?.data?.supplier?.product[0]?.margin?.percentage;
        } else {
          const supplierRules = await DMNAxiosInstance.post(
            `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
            {
              payload: {
                supplier: {
                  product: [
                    {
                      productOfferingId: card._id,
                      msrp: val?.msrp?.value,
                      sellingPrice: this.newPrice,
                      discount: {
                        skuDiscount: {
                          value: 0,
                          active: 1,
                          cost: val?.productStockLevel?.cost,
                          startDate: "",
                          endDate: "",
                        },
                        categoryDiscount: 0,
                        brandDiscount: 0,
                      },
                    },
                  ],
                },
              },
            }
          );
          supplierRules;
          card.supplierUnitCost = val?.productStockLevel?.cost;
          this.selectedConfigurationMargin =
            supplierRules?.data?.data?.supplier?.product[0]?.margin?.percentage;
          card.rebate = 0;
        }
      }
      return card;
    },
    searchOffers(v) {
      this.search_offering = v;
      this.getOfferData();
    },
    async getOfferData() {
      console.log("get offer");
      let Specfilterquery = {
        type: "search",
        module: "discountOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.externalRowsPerPage,
          currentPage: this.currentPage,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
          // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
        },
        searchQuery: this.search_offering,
      };

      Specfilterquery;
      // const filterResultData = await UPCAxiosInstance.post(
      //   `util/filterData`,
      //   Specfilterquery
      // );
      const filterResultData = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/listOffers?pageNo=${this.currentPage}&pageSize=${this.externalRowsPerPage}`
      );
      this.filterQuery["company"] = this.company;
      this.filterQuery["searchQuery"] = this.search_offering;
      this.filterQuery["paginatedQuery"] = {
        rowsPerPage: this.externalRowsPerPage,
        currentPage: this.currentPage,
      };
      if (this.filtertagvalue.length != 0) {
        this.filterQuery["type"] = "filter-search";
      } else {
        this.filterQuery["type"] = "search";
      }
      if (this.retiredvmodel === true) {
        console.log("true...");

        this.filterQuery.filterQuery = { lifecycleStatus: "Inactive" };
      } else {
        console.log("false...");

        this.filterQuery.filterQuery = {};
      }
      console.log("this.filterQuery",this.filterQuery);
      
      const filterResultData2 = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQuery
      );
      // this.offering_data = filterResultData.data.data
      console.log("raw offer", filterResultData);
      console.log("Offering data", filterResultData2.data.total);
      this.card_data_length = filterResultData2.data.total;
      console.log(
        filterResultData2.data.data,
        this.filterQuery,
        "filterResultData2.data.data..."
      );

      this.card_data = this.transformData(filterResultData2.data.data);
      console.log("card_data", this.card_data);
      this.card_data.forEach((i) => {
        this.getProductImageData(i);
      });
    },
    transformData(originalData) {
      console.log(originalData, "og_data");
      return originalData.map((item) => {
        const {
          name,
          description,
          lifecycleStatus,
          category,
          productSpecification,
          prodSpecCharValue,
          _id,
          productOfferingPrice,
        } = item;
        console.log("offering data card", item,prodSpecCharValue);
        const lastCategory =
          category.length > 0 ? category[category.length - 1] : "";

        const pricing = this.getPricing(productOfferingPrice);
        const oneTimePrice = this.calculateOneTimePrice(pricing);
        oneTimePrice;
        let billingPeriodOptions = null;
        billingPeriodOptions = [
          ...new Set(prodSpecCharValue?.map((item) => item?.billingPeriod)),
        ];
        let whole = "0";
        let fraction = "00";
        let newWhole, newFraction;

        if (item?.discountResponse?.price !== undefined) {
          [whole, fraction] = String(item.discountResponse?.price)?.split(".");
          newWhole = whole !== undefined ? whole : "0";
          newFraction = fraction !== undefined ? fraction : "00";
        } else if(!item.isBundle){
          [whole, fraction] = String(
            item.prodSpecCharValue[0]?.tiers[0]?.price?.value
          )?.split(".");
          newWhole = whole !== undefined ? whole : "0";
          newFraction = fraction !== undefined ? fraction : "00";
        } else {
          let items = item.productOfferingPrice.filter((item)=> {
        return item.lifecycleStatus == 'Active'
      })
      console.log(items[0]?.tiers[0]?.price?.value, 'ganesh');
       
          [whole, fraction] = String(
            items[0]?.tiers[0]?.price?.value
          )?.split(".");
          newWhole = whole !== undefined ? whole : "0";
          newFraction = fraction !== undefined ? fraction : "00";
        }
        // let [whole, fraction] = String(
        //   item.prodSpecCharValue[0]?.tiers[0]?.price?.value
        // )?.split(".");
        // let newWhole, newFraction;
        // newWhole = whole !== undefined ? whole : "0";
        // newFraction = fraction !== undefined ? fraction : "00";
        console.log("prechana", { newWhole, newFraction },prodSpecCharValue,item);
        return {
          offerId: item._id,
          unitOfMeasure: item.unitOfMeasure,
          additionalCharges: item.additionalCharges,
          img_url: prodSpecCharValue[0]?.attachment,
          currency:  "",
          discountResponse: item.discountResponse,
          productOfferingPrice: item.productOfferingPrice,
          relatedParty: item.relatedParty,
          name: name || "",
          description: description || "",
          status: lifecycleStatus || "",
          category: lastCategory || "",
          section: false,
          custom: false,
          brand: productSpecification?.brand || "",
          duration: prodSpecCharValue[0]?.commitmentLength || 1,
          quantity: 1,
          max_quantity:
            prodSpecCharValue[0]?.tiers[prodSpecCharValue[0]?.tiers?.length - 1]
              ?.max,
          billing_period: prodSpecCharValue[0]?.billingPeriod,
          billing_period_options: billingPeriodOptions?.map((value) => ({
            name: value,
            value: value,
          })) || [{ name: "Monthly", value: "Monthly" }],
          id: _id || "",
          offering_type:
            productSpecification?.["@baseType"]?.toLowerCase() || "",
          // pricing: pricing,
          pricing: prodSpecCharValue[0]?.tiers[0]?.price?.value,
          prodSpecCharValue: prodSpecCharValue,
          prodSpecCharValueUse: item.prodSpecCharValueUse,
          // price: oneTimePrice
          //   ? {
          //       whole: oneTimePrice.whole || "0",
          //       fraction: oneTimePrice.fraction || "00",
          //     }
          //   : { whole: "0", fraction: "00" },
          price: { whole: newWhole, fraction: newFraction },
          productSpecification: {
            ...item.productSpecification,
            "@type": "ProductSpecification",
          },
          productOffering: {
            name: item?.name,
            id: item._id,
            "@type": "ProductOffering",
            href: item.href,
          },
          productCharacteristic: item.prodSpecCharValueUse,
        };
      });
    },

    getPricing(prices) {
      console.log(prices, "card_data");
      if (!prices || prices.length === 0) return [];

      const priceMap = {};
      prices.forEach((price) => {
        const { pricingType, productofferPriceAlteration } = price;
        if (
          productofferPriceAlteration &&
          productofferPriceAlteration.length > 0
        ) {
          console.log("kulla if");
          productofferPriceAlteration.forEach((alteration) => {
            if (alteration.priority === "1") {
              if (
                !priceMap[pricingType] ||
                priceMap[pricingType].priority !== "1"
              ) {
                priceMap[pricingType] = {
                  name: price?.name || "",
                  pricingType: pricingType || "",
                  amount: alteration.productPrice.taxAmount?.Amount
                    ? alteration?.productPrice?.taxAmount?.Amount?.toString()?.replace(
                        "£",
                        ""
                      )
                    : "",
                  priority: alteration?.priority || "",
                };
              }
            }
          });
        } else {
          // console.log('kulla veliya if', price.productPrice.dutyFreeAmount);
          priceMap[pricingType] = {
            name: price?.name || "",
            pricingType: pricingType || "",
            amount: price?.productPrice?.dutyFreeAmount
              ? price?.productPrice?.dutyFreeAmount
                  ?.toString()
                  ?.replace("£", "")
              : "",
            priority: price?.productPrice?.priority || "",
          };
        }
      });

      return Object.values(priceMap).map((price) => ({
        ...price,
        amount: price.amount || "",
      }));
    },

    calculateOneTimePrice(pricing) {
      console.log("pricing card_data2", pricing);
      const oneTimePrice = pricing.find(
        (price) =>
          price?.name === "One time" ||
          price?.name === "Recurring" ||
          price?.name === "Pay per use price"
      );
      if (oneTimePrice) {
        const amount = oneTimePrice.amount;
        const [whole, fraction] = amount.split(".");
        return { whole, fraction };
      }
      return null;
    },
    async createNewVersionOfQuoteGoToPreviewPage(quote) {
      quote._id = undefined;
      quote.id = undefined;
      quote.state = "draft";
      quote.isVersioning = true;
      let createdOrUpdatedQuote = await UPCAxiosInstance.post(
        "/quotemanagement",
        quote
      );
      await this.updateInitialData();
      this.$router.push({
        name: "previewTemplate",
        query: {
          quote_id: createdOrUpdatedQuote.data?._id,
          client: this.recipientCompleteInfo?.tradingName,
          routeFrom: "CPQDefaultTemplate",
        },
      });
      console.log(
        quote,
        "createNewVersionOfQuoteGoToPreviewPage",
        createdOrUpdatedQuote
      );
      this.notification = {
            type: "success",
            message: quote.description + " Quote saved successfully",
          };
      // this.updateInitialData()
    },
    async createNewVersionOfQuote(quote) {
      console.log(quote, "createNewVersionOfQuote");
      quote._id = undefined;
      quote.id = undefined;
      quote.state = "draft";
      quote.isVersioning = true;
      // quote.quoteDate = new Date().toISOString();
      // quote.authorization = quote.authorization.map((auth) => {
      //   auth.state = "inProgress";
      //   auth.requestedDate = "";
      //   auth.signatureRepresentation = "";
      //   auth.givenDate = new Date().toISOString();
      //   return auth;
      // });

      // let validTillDateSetting = quote.note.find(
      //   (item) => item.name === "validTillDateSetting"
      // );

      // quote.validFor = {
      //   endDateTime: quote.validFor.endDateTime
      //     ? this.addDatesToGivenDateV1(
      //         quote.validFor.endDateTime,
      //         validTillDateSetting ? validTillDateSetting.value : 15
      //       )
      //     : "",
      //   startDateTime: new Date().toISOString(),
      // };
      // quote.requestedQuoteCompletionDate = quote.validFor.endDateTime
      //   ? this.addDatesToGivenDateV1(
      //       quote.validFor.endDateTime,
      //       validTillDateSetting ? validTillDateSetting.value : 15
      //     )
      //   : "";
      // quote.effectiveQuoteCompletionDate = "";
      let createdQuote = await UPCAxiosInstance.post("/quotemanagement", quote);
      console.log("created createNewVersionOfQuote", createdQuote);
      if (createdQuote && createdQuote.data) {
        this.$router.replace({
          path: this.$router.currentRoute.path,
          query: { quote_id: createdQuote.data._id, mode: "edit" },
        });
      }
      // this.initialData=createdQuote.data
      //     let restoredQuote = await UPCAxiosInstance.post(`quotemanagement`,{
      // restoringId:   createdQuote.data._id,
      // isVersioning:true,
      // isRestoring:true
      //   });
      //   console.log(restoredQuote,"restoredQuote")
      this.notification = {
            type: "success",
            message: quote.description + " Quote saved successfully",
          };
    },
    // activiylogData() {
    //   let updatedAdditionalChargeLogPayload = {
    //     message: `${this.invoiceTemplatePayload.header.title.value} Quote has been updated`,
    //     "@type": "Offering-additional-charge",
    //     detailedAction: [],
    //     category: ["CPQ"],
    //     createdBy: this.$cookies.get("user"),
    //     user: this.$cookies.get("userName"),
    //     lastUpdate: new Date().toISOString(),
    //     ref: [this.quoteData?.externalId],
    //     action: "UPDATED",
    //   };
    //   let nameActionLog = this.buildBasicLogByDifferences(
    //     this.initialData.header.title,
    //     this.invoiceTemplatePayload.header.title,
    //     "value",
    //     "Name"
    //   );
    //   let subtitleActionLog = this.buildBasicLogByDifferences(
    //     this.initialData.header.subtitle,
    //     this.invoiceTemplatePayload.header.subtitle,
    //     "value",
    //     "Subtitle"
    //   );

    //   let recipientActionLog = this.buildBasicLogByDifferences(
    //     this.initialData.recipient.businessDetails.businessName,
    //     this.invoiceTemplatePayload.recipient.businessDetails.businessName,
    //     "value",
    //     "Client details"
    //   );

    //   let quoteBasicInfoActionLog = this.buildBasicLogByDifferences(
    //     this.initialData.quoteBasicInfo.quoteDate,
    //     this.invoiceTemplatePayload.quoteBasicInfo.quoteDate,
    //     "value",
    //     "quoteDate"
    //   );

    //   let quoteValidTillDateActionLog = this.buildBasicLogByDifferences(
    //     this.initialData.quoteBasicInfo.quoteValidTillDate,
    //     this.invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate,
    //     "value",
    //     "ValidTillDate"
    //   );

    //   let senderContactActionLog = this.buildBasicLogByDifferences(
    //     this.initialData.recipientContact.contactDetails.name,
    //     this.invoiceTemplatePayload.recipientContact.contactDetails.name,
    //     "value",
    //     " Client contact"
    //   );

    //   let appliesToActionLog = this.buildLogBasedOnDifferencesForArrayOfObjects(
    //     this.initialData.quoteBasicInfo.additionalFields,
    //     this.invoiceTemplatePayload.quoteBasicInfo.additionalFields,
    //     "value",
    //     "Add more fields"
    //   );

    //   let paricingtable = this.compareArraysByIndexAndName(
    //     this.initialPricingTableData.quoteItem,
    //     this.quotePricingData.quoteItem,
    //     "product_offering_name",
    //     "product_offering_name"
    //   );
    //   let attachmentquote = this.compareArraysByIndexAndName(
    //     this.initialData.recipientAttachments.value,
    //     this.invoiceTemplatePayload.recipientAttachments.value,
    //     "name",
    //     "Attachment"
    //   );

    //   let termsandconditionsLog = this.compareArraysByIndexAndName(
    //     this.initialData.recipientTermsandConditions.conditions,
    //     this.invoiceTemplatePayload.recipientTermsandConditions.conditions,
    //     "label",
    //     "Terms and conditions"
    //   );

    //   let termsandconditionsLognes = this.findChangesNested(
    //     this.initialData.recipientTermsandConditions.conditions,
    //     this.invoiceTemplatePayload.recipientTermsandConditions.conditions,
    //     "label",
    //     "additionalFields",
    //     "Terms and conditions"
    //   );
    //   console.log(
    //     termsandconditionsLognes,
    //     "termsandconditionsLognes",
    //     termsandconditionsLog
    //   );

    //   let UNOacticitylog = this.compareArraysForUpdates(
    //     this.initialPricingTableData.quoteItem,
    //     this.quotePricingData.quoteItem,
    //     "unitOfMeasure",
    //     "Unit Of Measure",
    //     "product_offering_name"
    //   );

    //   let billing_periodlog = this.compareArraysForUpdates(
    //     this.initialPricingTableData.quoteItem,
    //     this.quotePricingData.quoteItem,
    //     "billing_period",
    //     "Billing",
    //     "product_offering_name"
    //   );

    //   let quantitylog = this.compareArraysForUpdates(
    //     this.initialPricingTableData.quoteItem,
    //     this.quotePricingData.quoteItem,
    //     "quantity",
    //     "Quantity",
    //     "product_offering_name"
    //   );

    //   let Unitpricelog = this.findPriceChanges(
    //     this.initialPricingTableData.quoteItem,
    //     this.quotePricingData.quoteItem,
    //     "dutyFreeAmount",
    //     "Unit price"
    //   );
    //   let Amountlog = this.findPriceChanges(
    //     this.initialPricingTableData.quoteItem,
    //     this.quotePricingData.quoteItem,
    //     "taxIncludedAmount",
    //     "Amount"
    //   );
    //   let discountActionLog = this.buildBasicLogByDifferences(
    //     this.initialPricingTableData.discount,
    //     this.quotePricingData.discount,
    //     "value",
    //     "Discount"
    //   );
    //   let discounttypActionLog = this.buildBasicLogByDifferences(
    //     this.initialPricingTableData.discount,
    //     this.quotePricingData.discount,
    //     "type",
    //     "Discount type"
    //   );
    //   updatedAdditionalChargeLogPayload.detailedAction =
    //     updatedAdditionalChargeLogPayload.detailedAction.concat(
    //       nameActionLog,
    //       discountActionLog,
    //       discounttypActionLog,
    //       Amountlog,
    //       subtitleActionLog,
    //       recipientActionLog,
    //       quoteBasicInfoActionLog,
    //       quoteValidTillDateActionLog,
    //       Unitpricelog,
    //       quantitylog,
    //       billing_periodlog,
    //       UNOacticitylog,
    //       paricingtable,
    //       appliesToActionLog,
    //       senderContactActionLog,
    //       attachmentquote,
    //       termsandconditionsLog,
    //       termsandconditionsLognes
    //     );
    //   this.initLog(updatedAdditionalChargeLogPayload);
    //   console.log(
    //     nameActionLog,
    //     subtitleActionLog,
    //     recipientActionLog,
    //     quoteBasicInfoActionLog,
    //     quoteValidTillDateActionLog,
    //     Unitpricelog,
    //     quantitylog,
    //     billing_periodlog,
    //     UNOacticitylog,
    //     paricingtable,
    //     appliesToActionLog,
    //     senderContactActionLog,
    //     attachmentquote,
    //     termsandconditionsLog,
    //     termsandconditionsLognes,
    //     "updatedAdditionalChargeLogPayload detailedAction"
    //   );
    //   console.log(
    //     this.initialPricingTableData.quoteItem,
    //     this.quotePricingData.quoteItem,
    //     " this.initialPricingTableData.quoteItem",
    //     this.invoiceTemplatePayload,
    //     this.initialData
    //   );
    //   console.log(
    //     updatedAdditionalChargeLogPayload,
    //     "-----------------------------------updatedAdditionalChargeLogPayload------------------------------"
    //   );
    // },
    async saveQuote(mode) {
      // await this.activiylogData();
      console.log("saveQuote top", this.quoteData, this.signaturePlacePreVal,"mode:",mode);
      // try {
        const unsavedChanges = this.isPayloadChanged(
          this.initialData,
          this.invoiceTemplatePayload
        );
        const unsavedChangesInPricingTable = this.isPayloadChanged(
          this.initialPricingTableData,
          this.quotePricingData
        );
        console.log(
          unsavedChangesInPricingTable,
          "unsavedChanges",
          unsavedChanges
        );
        this.invoiceTemplatePayload.recipientNotes.value = this.notes1;
        this.initialPricingTableData = JSON.parse(
          JSON.stringify(this.quotePricingData)
        );
        
        const { sender, recipient, senderContact, recipientContact } =
          this.invoiceTemplatePayload;
        // Validation
        // Check if quote have sender
        if (
          !sender ||
          (sender &&
            sender.businessDetails &&
            sender.businessDetails.businessName &&
            !sender.businessDetails.businessName.value)
        ) {
          this.isValidSenderCompany = false;
        }

        // Check if quote have sender contacts
        if (
          !senderContact ||
          (mode != "save" &&
            senderContact &&
            senderContact.contactDetails &&
            !senderContact.contactDetails?.name.value)
        ) {
          this.isValidSenderCompanyContact = false;
        }

        // Check if quote have recipient
        if (
          !recipient ||
          (mode != "save" &&
            recipient &&
            recipient.businessDetails &&
            recipient.businessDetails.businessName &&
            !recipient.businessDetails.businessName.value)
        ) {
          this.isValidRecipientCompany = false;
        }

        // Check if quote have recipient contacts
        if (
          !recipientContact ||
          (mode != "save" &&
            recipientContact &&
            recipientContact &&
            recipientContact.contactDetails &&
            !recipientContact.contactDetails?.name.value)
        ) {
          this.isValidRecipientCompanyContact = false;
        }

        // Check if it pass all validation
        this.isValidQuote = this.isValidQuoteTemplate;

        console.log("saveQuote end ", this.quoteData);
        let quoteAPIPayload = {
          id: "",
          href: "",
          lastUpdate: new Date().toISOString(),
          category: "BSBS Market",
          description: this.invoiceTemplatePayload.header.title.value,
          effectiveQuoteCompletionDate: "",
          expectedFulfillmentStartDate: "",
          expectedQuoteCompletionDate: "",
          amountDue:{
            
          },
          // externalId: [
          //   {
          //     name: "quoteNumber",
          //     value: this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value,
          //   },
          // ],
          externalId:
            this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value,
          displayID: this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value,
          instantSyncQuote: false,
          quoteDate: new Date(
            this.invoiceTemplatePayload.quoteBasicInfo.quoteDate.value
          ).toISOString(),
          // requestedQuoteCompletionDate: new Date( // by asif
          //   this.invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value
          // ).toISOString(),
          version: "1",
          agreement: [],
          billingAccount: [],
          contactMedium: [],
          note: [],
          attachment: [],
          productOfferingQualification: [],
          // authorization: [
          //   {
          //     givenDate: "2019-05-08T12:45:12.031Z",
          //     name: "Discount validation",
          //     requestedDate: "2019-05-09T12:45:12.031Z",
          //     signatureRepresentation: "Manager approval",
          //     state: "validated",
          //     approver: [
          //       {
          //         name: "Joe Doe",
          //         role: "B2B Discount approver",
          //       },
          //     ],
          //   },
          // ],
          relatedParty: [
            // {
            //     "name": "Jean Pontus",
            //     "role": "Buyer Contact",
            //     "id": "qsdd-9888",
            //     "@referredType": "Individual"
            // },
            // {
            //     "name": "PerfectCustomer",
            //     "role": "Buyer organization",
            //     "id": "55",
            //     "@referredType": "Organization"
            // },
            // {
            //     "name": "Joe Doe",
            //     "role": "Seller Contact",
            //     "id": "45gh-gh89",
            //     "@referredType": "Individual"
            // }
          ],
          state: this.invoiceTemplatePayload.state,
          validFor: {
            endDateTime:
              this.invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value,
            startDateTime:
              this.invoiceTemplatePayload.quoteBasicInfo.quoteDate.value,
          },
          "@type": "Quote",
          
    "productRef": [
        {
            "quoteItem": [
                {
                    "section": false,
                    "address": false,
                    "custom": true,
                    "notes": "",
                    "product_offering_name": "New item",
                    "description": "",
                    "unitOfMeasure": "Each",
                    "additionalCharges": [],
                    "@type": "QuoteItem",
                    "productSpecification": {
                        "@type": "ProductSpecification"
                    },
                    "productOffering": {
                        "@type": "ProductOffering"
                    },
                    "action": "new",
                    "quantity": 1,
                    "duration": 1,
                    "billing_period": "One time",
                    "discount": {
                        "value": 0,
                        "type": "%"
                    },
                    "state": "",
                    "product": {
                        "@type": "product",
                        "productSpecification": {
                            "@type": "ProductSpecification"
                        },
                        "productOffering": {
                            "@type": "ProductOffering"
                        }
                    },
                    "quoteItemPrice": [
                        {
                            "description": "",
                            "name": "",
                            "priceType": "One time",
                            "recurringChargePeriodLength": 0,
                            "recurringChargePeriod": "One time",
                            "price": {
                                "taxRate": 0,
                                "dutyFreeAmount": {
                                    "unit": "GBP",
                                    "value": 1
                                },
                                "taxIncludedAmount": {
                                    "unit": "GBP",
                                    "value": 1
                                }
                            }
                        }
                    ],
                    "id": "1"
                }
            ]
        }
    ],
        };

        /**Temporary block */
        quoteAPIPayload.productRef[0].quoteItem = this.quotePricingData?.quoteItem;
        quoteAPIPayload.quoteTotalPrice =
          this.quoteTotalPricingData?.quoteTotalPrice || [];
        quoteAPIPayload.quotePrice =
          this.quoteTotalPricingData?.quotePrice || {};
        console.log("🚀 ~ saveQuote ~ quoteAPIPayload:", quoteAPIPayload);
        // Map sender info
        let senderParty = [
          {
            name: this.invoiceTemplatePayload.sender.businessDetails.businessName
              .value,
            role: "Seller",
            id: this.invoiceTemplatePayload.sender.businessDetails.id.value,
            "@referredType": "Organisation",
          },
        ];
        let senderPartyContactMedium = [
          {
            mediumType: "sellerPhone",
            characteristic: {
              phoneNumber:
                this.invoiceTemplatePayload.sender.businessDetails.phone.value,
              "@preferred": true,
            },
          },
          {
            mediumType: "sellerContactPhone",
            characteristic: {
              phoneNumber:
                this.invoiceTemplatePayload.senderContact.contactDetails.phone
                  .value,
              "@preferred": true,
            },
          },
          {
            mediumType: "sellerEmail",
            characteristic: {
              emailAddress:
                this.invoiceTemplatePayload.sender.businessDetails.email.value,
              "@preferred": true,
            },
          },
          {
            mediumType: "sellerContactEmail",
            characteristic: {
              emailAddress:
                this.invoiceTemplatePayload.senderContact.contactDetails.email
                  .value,
              "@preferred": true,
            },
          },
          {
            mediumType: "senderPostalAddress",
            characteristic: {
              ...this.invoiceTemplatePayload.sender.businessDetails.address.value
                ?.characteristic,
              "@preferred": true,
            },
          },
        ];
        let senderPartyContact = [
          {
            name: this.invoiceTemplatePayload.senderContact.contactDetails?.name
              .value,
            role: "Seller contact",
            id: this.invoiceTemplatePayload.senderContact.contactDetails.id.value,
            "@referredType": "Individual",
          },
        ];

        // Map recipient info
        let recipientParty = [
          {
            name: this.invoiceTemplatePayload.recipient.businessDetails
              .businessName.value,
            role: "Buyer",
            id: this.invoiceTemplatePayload.recipient.businessDetails.id.value,
            "@referredType": "Organisation",
          },
        ];
        let recipientPartyContactMedium = [
          {
            mediumType: "buyerPhone",
            characteristic: {
              phoneNumber:
                this.invoiceTemplatePayload.recipient.businessDetails.phone.value,
              "@preferred": true,
            },
          },
          {
            mediumType: "buyerContactPhone",
            characteristic: {
              phoneNumber:
                this.invoiceTemplatePayload.recipientContact.contactDetails.phone
                  .value,
              "@preferred": true,
            },
          },
          {
            mediumType: "buyerEmail",
            characteristic: {
              emailAddress:
                this.invoiceTemplatePayload.recipient.businessDetails.email.value,
              "@preferred": true,
            },
          },
          {
            mediumType: "buyerContactEmail",
            characteristic: {
              emailAddress:
                this.invoiceTemplatePayload.recipientContact.contactDetails.email
                  .value,
              "@preferred": true,
            },
          },
          {
            mediumType: "recipientPostalAddress",
            characteristic: {
              ...this.invoiceTemplatePayload.recipient.businessDetails.address
                .value.characteristic,
              "@preferred": true,
            },
          },
          {
            mediumType: "recipientShippingAddress",
            characteristic: {
              ...this.invoiceTemplatePayload.recipientShippingDetails.address
                .value.characteristic,
              "@preferred": true,
            },
          },
          {
            mediumType: "recipientBillingAddress",
            characteristic: {
              ...this.invoiceTemplatePayload.recipientBillingDetails.address.value
                .characteristic,
              "@preferred": true,
            },
          },
        ];
        let recipientPartyContact = [
          {
            name: this.invoiceTemplatePayload.recipientContact.contactDetails
              ?.name.value,
            role: "Buyer contact",
            id: this.invoiceTemplatePayload.recipientContact.contactDetails.id
              .value,
            "@referredType": "Individual",
          },
        ];

        quoteAPIPayload.contactMedium = quoteAPIPayload.contactMedium.concat(
          senderPartyContactMedium,
          recipientPartyContactMedium
        );
        quoteAPIPayload.relatedParty = quoteAPIPayload.relatedParty.concat(
          senderParty,
          senderPartyContact,
          recipientParty,
          recipientPartyContact
        );

        // Map terms and condition
        const termsConditions =
          this.invoiceTemplatePayload.recipientTermsandConditions.conditions.map(
            (terms) => {
              let agreementTemplate = {
                id: "",
                name: terms.label,
                "@baseType": "Agreement",
                "@referredType": "TermsAndConditions",
                terms: terms.additionalFields,
              };
              return agreementTemplate;
            }
          );
        quoteAPIPayload.agreement = termsConditions;
        // Map notes
        let notes = [
          {
            id: "",
            author:
              this.invoiceTemplatePayload.senderContact.contactDetails?.name
                .value,
            date: new Date().toISOString(),
            text: this.invoiceTemplatePayload.recipientNotes.value,
            "@type": "Note",
          },
        ];
        quoteAPIPayload.note = quoteAPIPayload.note.concat(notes);

        // Map additional fields
        let additionalFields =
          this.invoiceTemplatePayload.recipientAdditionalInfo.additionalFields.map(
            (item) => {
              return {
                id: "",
                author:
                  this.invoiceTemplatePayload.senderContact.contactDetails?.name
                    .value,
                date: new Date().toISOString(),
                text: item.label + ":" + item.value,
                "@type": "AdditionalNote",
              };
            }
          );
        quoteAPIPayload.note = quoteAPIPayload.note.concat(additionalFields);

        // Map header additional fields
        let basicAdditionalFields =
          this.invoiceTemplatePayload.quoteBasicInfo.additionalFields.map(
            (item) => {
              return {
                id: "",
                author:
                  this.invoiceTemplatePayload.senderContact.contactDetails?.name
                    .value,
                date: new Date().toISOString(),
                text: item.label + ":" + item.value,
                "@type": "quoteBasicAdditionalNote",
              };
            }
          );
        quoteAPIPayload.note = quoteAPIPayload.note.concat(
          basicAdditionalFields
        );

        // Map subtitle fields
        let subtitle = [
          {
            id: "",
            author:
              this.invoiceTemplatePayload.senderContact.contactDetails?.name
                .value,
            date: new Date().toISOString(),
            text: this.invoiceTemplatePayload.header.subtitle.value,
            "@type": "subTitleNote",
          },
        ];

        quoteAPIPayload.note = quoteAPIPayload.note.concat(subtitle);
        // Next bill date setting
        const validTillDateSetting = [
          {
            id: "",
            value:
              this.invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting
                .value,
            unit: this.invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting
              .unit,
            "@type": "validTillDateSetting",
          },
        ];
        quoteAPIPayload.note =
          quoteAPIPayload.note.concat(validTillDateSetting);
        // Map subtitle fields
        let otherLabelFields = [
          {
            id: "",
            author:
              this.invoiceTemplatePayload.senderContact.contactDetails?.name
                .value,
            date: new Date().toISOString(),
            text: this.invoiceTemplatePayload.quoteBasicInfo.quoteNumberLabel
              .value,
            "@type": "quoteNumberLabel",
          },
          {
            id: "",
            author:
              this.invoiceTemplatePayload.senderContact.contactDetails?.name
                .value,
            date: new Date().toISOString(),
            text: this.invoiceTemplatePayload.quoteBasicInfo.quoteDateLabel.value,
            "@type": "quoteDateLabel",
          },
          {
            id: "",
            author:
              this.invoiceTemplatePayload.senderContact.contactDetails?.name
                .value,
            date: new Date().toISOString(),
            text: this.invoiceTemplatePayload.quoteBasicInfo
              .quoteValidTillDateLabel.value,
            "@type": "quoteValidTillDateLabel",
          },
          // {
          //   id: "",
          //   author:
          //     this.invoiceTemplatePayload.senderContact.contactDetails?.name.value,
          //   date: new Date().toISOString(),
          //   text: this.invoiceTemplatePayload.quoteBasicInfo.quoteAdd.value,
          //   "@type": "quoteBasicAdditionalFieldLabel",
          // },
          {
            id: "",
            author:
              this.invoiceTemplatePayload.senderContact.contactDetails?.name
                .value,
            date: new Date().toISOString(),
            text: this.invoiceTemplatePayload.sender.title.value,
            "@type": "senderTitle",
          },
          {
            id: "",
            author:
              this.invoiceTemplatePayload.senderContact.contactDetails?.name
                .value,
            date: new Date().toISOString(),
            text: this.invoiceTemplatePayload.recipient.title.value,
            "@type": "recipientTitle",
          },
          {
            id: "",
            author:
              this.invoiceTemplatePayload.senderContact.contactDetails?.name
                .value,
            date: new Date().toISOString(),
            text: this.invoiceTemplatePayload.senderContact.title.value,
            "@type": "senderContactTitle",
          },
          {
            id: "",
            author:
              this.invoiceTemplatePayload.senderContact.contactDetails?.name
                .value,
            date: new Date().toISOString(),
            text: this.invoiceTemplatePayload.recipientContact.title.value,
            "@type": "recipientContactTitle",
          },
          {
            id: "",
            author:
              this.invoiceTemplatePayload.senderContact.contactDetails?.name
                .value,
            date: new Date().toISOString(),
            text: this.invoiceTemplatePayload.recipientShippingDetails.title
              .value,
            "@type": "recipientShippingDetailsTitle",
          },
          {
            id: "",
            author:
              this.invoiceTemplatePayload.senderContact.contactDetails?.name
                .value,
            date: new Date().toISOString(),
            text: this.invoiceTemplatePayload.recipientBillingDetails.title.value,
            "@type": "recipientBillingDetailsTitle",
          },
        ];

        quoteAPIPayload.note = quoteAPIPayload.note.concat(otherLabelFields);

        

        // Map attachments
        console.log(
          this.invoiceTemplatePayload.recipientAttachments.value,
          "attachments before"
        );
        quoteAPIPayload.attachment =
          this.invoiceTemplatePayload.recipientAttachments.value;
        console.log(quoteAPIPayload, "attachments");
        // attachments;
        console.log(quoteAPIPayload, "attachments");
        if (this.profilePicture) {
          console.log(
            "🚀 ~ saveQuote ~ this.profilePicture:",
            this.profilePicture
          );
          // Map profile picturec
          quoteAPIPayload.attachment = quoteAPIPayload.attachment.concat([
            this.profilePicture,
          ]);
          console.log(quoteAPIPayload, "attachments");
        }

        //next bill date
        this.invoicePayload.nextBillDate = 
        this.invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value
        // title
          this.invoicePayload.description = quoteAPIPayload.description
          this.invoicePayload.relatedParty = quoteAPIPayload.relatedParty
          this.invoicePayload.productRef[0].quoteItem = this.productOfferingItem.quoteItem;
          this.invoicePayload.attachmentRef = quoteAPIPayload.attachment
          this.invoicePayload.lastUpdate = quoteAPIPayload.lastUpdate

          this.invoicePayload.amountDue = this.productOfferingSummary;

          this.invoicePayload.attachmentRef = this.invoicePayload.attachmentRef.concat(quoteAPIPayload.contactMedium);

          this.invoicePayload.attachmentRef =
          this.invoicePayload.attachmentRef.concat(quoteAPIPayload.agreement)
          // sub title
          const subTitleNote = quoteAPIPayload.note.find(item => item["@type"] === "subTitleNote");
          console.log("billingSubTitle...",
          subTitleNote,
           quoteAPIPayload.note);
          
          this.invoicePayload.attachmentRef = this.invoicePayload.attachmentRef.concat(subTitleNote)

          console.log("view termss...",
          quoteAPIPayload,this.invoicePayload
          );
          
          let additionalFieldsinvoicePayload =this.invoiceTemplatePayload.recipientAdditionalInfo.additionalFields.map(
            (item) => {
              return {
                id: '',
                author:
                  this.invoiceTemplatePayload.senderContact.contactDetails?.name
                    .value,
                date: new Date().toISOString(),
                text: item.label + ':' + item.value,
                '@type': 'AdditionalNote',
              };
            }
          );
          this.invoicePayload.attachmentRef = this.invoicePayload.attachmentRef.concat(additionalFieldsinvoicePayload);

          const existingNotesIndex = this.invoicePayload.attachmentRef.findIndex(item => item["@baseType"] === "Notes");

if (existingNotesIndex !== -1) {
    // If found, update the existing entry's value
    this.invoicePayload.attachmentRef[existingNotesIndex].value = this.invoiceTemplatePayload.recipientNotes;
} else {
    // If not found, push the new notesEntry
    this.invoicePayload.attachmentRef.push(this.invoiceTemplatePayload.recipientNotes);
}
          // this.invoicePayload.amountDue = this.productOfferingSummary.quotePrice
          // this.invoicePayload.amountDue = this.productOfferingSummary.quoteTotalPrice
          console.log("quoteAPIPayload......",this.invoicePayload, quoteAPIPayload.contactMedium,quoteAPIPayload,this.invoiceTemplatePayload?.quoteBasicInfo?.quoteNumber?.value);

           // created new invoice
           if(this.$route?.query?.mode != "edit"){

            if(!this.status){
            this.invoicePayload.state = "onHold";
          }else{
            this.invoicePayload.state = this.statusMapping(this.status);
          }

          this.invoicePayload.lastUpdate = `${this.formatCurrentTime()}`;
            this.invoicePayload.billNo = this.invoiceTemplatePayload.quoteBasicInfo.quoteNumber.value
            console.log("before save...",this.invoicePayload)
          const createdOrUpdatedQuote = await UPCAxiosInstance.post(
            "/customerBill",
            this.invoicePayload
          );
          console.log(createdOrUpdatedQuote,'createdOrUpdatedInvoice',createdOrUpdatedQuote?.data?.description)

          if(createdOrUpdatedQuote?.data?.description){
            this.notification = {
            type: "success",
            message: createdOrUpdatedQuote?.data?.description + " Bill saved successfully",
          };

          console.log("log to check router",this.$router.currentRoute.path,"----",createdOrUpdatedQuote);
          
                  //push edit mode
                  console.log("this.isPreviewClicked",this.isPreviewClicked);

                  setTimeout(() => {
                                      if(this.isPreviewClicked){
                                
                                this.$router.push({
                                  name: "previewInvoicepage",
                                  query: {
                                    quote_id: createdOrUpdatedQuote?.data?._id,
                                  },
                                });

                      }else{
                                      this.$router.replace({
                              path: this.$router.currentRoute.path,
                              query: { quote_id: createdOrUpdatedQuote.data._id, mode: "edit" },
                            });
                      }
                  }, 1000);                                    
                    
        }


          }
           //old invoice edit
          else if(this.$route?.query?.mode == "edit"){
            console.log("edit mode is on");

            if(!this.status){
            this.invoicePayload.state = this.quoteData?.state;
          }else{
            this.invoicePayload.state = this.statusMapping(this.status);
          }
          this.invoicePayload.lastUpdate = `${this.formatCurrentTime()}`;
          this.invoicePayload.billNo = this.quoteData?.billNo
            const createdOrUpdatedQuote = await UPCAxiosInstance.patch(
            "/customerBill",
            {
              ...this.invoicePayload,
              id: this.quoteData._id,
            }
             );
             createdOrUpdatedQuote

             this.quoteData.lastUpdate =
               createdOrUpdatedQuote?.data?.lastUpdate

          this.notification = {
            type: "success",
            message: createdOrUpdatedQuote?.data?.description + " Bill saved successfully",
          };

          setTimeout(() => {
            if(this.isPreviewClicked){
              
              console.log("else111111111111111 123 if");
      
              this.$router.push({
                name: "previewInvoicepage",
                query: {
                  quote_id: createdOrUpdatedQuote?.data?._id,
                },  
              });

    }else{
      console.log("else111111111111111 123 else");
      
                    this.$router.replace({
            path: this.$router.currentRoute.path,
            query: { quote_id: createdOrUpdatedQuote.data._id, mode: "edit" },
          });
    }
          }, 1000);          

          }
          
          
          
         
          // let createdOrUpdatedQuote = await UPCAxiosInstance.post(
          //   "/customerBill",
          //   this.invoicePayload
          // );
          // console.log(createdOrUpdatedQuote,'createdOrUpdatedInvoice')
          // if(createdOrUpdatedQuote.status == '201'){
          //   this.notification = {
          //   type: "success",
          //   message: quoteAPIPayload.description + " Invoice saved successfully",
          // };
          // }

      // }catch(error){
      //   console.log(error)
      // }
// check signature is edited or not
 

    },
    previewQuote(){
      console.log("create template... previewQuote");
      // this.enableDiscardModal = false;
      // this.$router.push(`/invoice/previewpage`);


      if(this.$route?.query?.mode == "edit"){
        
        this.isPreviewClicked = true;
        this.saveQuote('save');

        // this.$router.push({
        //     name: "previewInvoicepage",
        //     query: {
        //       quote_id: this.quoteData?._id,
        //     },
        //   });
      }else{
        this.isPreviewClicked = true;
        this.saveQuote('save');
      }

      
    },


    updateInitialData() {
      this.initialData = JSON.parse(JSON.stringify(this.invoiceTemplatePayload));
    },
    async searchRecipientList(value) {
      // value = this.recipientSearchPlaceHolder;
      console.log("🚀 ~ searchRecipientList ~ value:", value, this.company);
      // if(this.recipientSearchValue && this.recipientSearchValue.trim()) {
      if (value == undefined || !this.company) return;
      value = value.trim();
      let recipientListOptions = {
        company: this.company,
        type: "filter-search",
        module: "hierarchyaccounts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          name: 1,
        },
        limit: 2,
        filterQuery: {
          "@baseType": "customer",
          status: { $regex: "active", $options: "i" },
        },
        searchQuery: value,
      };
      let recipientLists = await MLTAxiosInstance.post(
        "/util/filterData",
        recipientListOptions
      );
      this.recipientLists = recipientLists?.data?.data?.filter(
        (item) => item?.status?.toLowerCase() === "active"
      );
      if (this.recipientCompleteInfo) {
        this.recipientLists = this.recipientLists.filter(
          (recipient) =>
            recipient.tradingName != this.recipientCompleteInfo.tradingName
        );
        this.recipientLists.splice(0, 0, this.recipientCompleteInfo);
      }
      // }
    },
    async searchSenderList(value) {
      if (value == undefined || this.company) return;
      let senderCompanyFilter = {
        company: "Halleyx",
        type: "filter-search",
        module: "hierarchyaccounts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          // name: 1,
        },
        limit: 2,
        filterQuery: {
          status: { $regex: "active", $options: "i" },
          "@baseType": { $nin: ["supplier", "customer"] },
          $and: [
            { tradingName: { $ne: "Halleyx" } },
            {
              $or: [{ tradingName: { $regex: "", $options: "i" } }],
            },
          ],
        },

        searchQuery: value,
      };
      let senderCompanies = await MLTAxiosInstance.post(
        "/util/filterData",
        senderCompanyFilter
      );
      this.senderCompanyList = senderCompanies.data.data;
      if (this.senderCompleteInfo) {
        this.senderCompanyList = this.senderCompanyList.filter(
          (sender) => sender.tradingName != this.senderCompleteInfo.tradingName
        );
        this.senderCompanyList.splice(0, 0, this.senderCompleteInfo);
      }
    },
    async searchRecipientContact(value) {
      console.log("🚀 ~ searchRecipientList ~ value:", value);
      // if(this.recipientSearchValue && this.recipientSearchValue.trim()) {
      if (
        value == undefined ||
        !this.invoiceTemplatePayload.recipient.businessDetails.businessName.value
      )
        return;
      value = value.trim();
      let recipientContactOptions = {
        company:
          this.invoiceTemplatePayload.recipient.businessDetails.businessName
            .value,
        type: "filter-search",
        module: "contacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          name: 1,
        },
        limit: 2,
        filterQuery: {
          status: { $regex: "active", $options: "i" },
          "@baseType": "customer",
        },
        searchQuery: value,
      };
      let recipientLists = await MLTAxiosInstance.post(
        "/util/filterData",
        recipientContactOptions
      );
      this.recipientContacts = recipientLists?.data?.data?.filter(
        (item) =>
          item?.status?.toLowerCase() === "active" ||
          item?.status?.toLowerCase() === "pending"
      );
      if (
        this.recipientContactCompleteInfo &&
        Object.keys(this.recipientContactCompleteInfo).length > 0
      ) {
        this.recipientContacts = this.recipientContacts.filter(
          (recipient) =>
            recipient?.name != this.recipientContactCompleteInfo?.name
        );
        this.recipientContacts.splice(0, 0, this.recipientContactCompleteInfo);
      }

      // }
      console.log(
        "🚀 ~ searchRecipientContact ~ this.invoiceTemplatePayload.recipient.businessDetails.businessName.value:",
        this.invoiceTemplatePayload.recipient.businessDetails.businessName.value
      );
    },
    async searchSenderContact(value) {
      console.log("🚀 ~ searchRecipientList ~ value:", value);
      // if(this.recipientSearchValue && this.recipientSearchValue.trim()) {
      if (value == undefined || !this.company) return;
      value = value.trim();
      let senderContactFilterOptions = {
        company: this.company,
        type: "filter-search",
        module: "contacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          name: 1,
        },
        limit: 2,
        filterQuery: {
          status: { $regex: "active", $options: "i" },
          "@baseType": "organisation",
        },
        searchQuery: value,
      };
      let recipientLists = await MLTAxiosInstance.post(
        "/util/filterData",
        senderContactFilterOptions
      );
      this.senderCompanyContacts = recipientLists.data.data;
      if (this.senderContactCompleteInfo) {
        this.senderCompanyContacts = this.senderCompanyContacts.filter(
          (sender) =>
            sender.tradingName != this.senderContactCompleteInfo.tradingName
        );
        this.senderCompanyContacts.splice(0, 0, this.senderContactCompleteInfo);
      }

      // }
    },
    handleSelectedRecipientContact(contact) {
      this.recipientContactCompleteInfo = contact;
      this.searchRecipientContact("");
      console.log("🚀 ~ handleRecipientContactChanges ~ contact:", contact);
      this.isValidRecipientCompanyContact = true;
      this.invoiceTemplatePayload.recipientContact.contactDetails.email.value =
        contact.email;
      // Find recipient phone
      let recipientContactPhone = contact.contactMedium.find(
        (medium) => medium.mediumType == "phone"
      );
      if (
        recipientContactPhone &&
        recipientContactPhone.characteristic.phoneNumber
      ) {
        this.invoiceTemplatePayload.recipientContact.contactDetails.phone.value =
          recipientContactPhone.characteristic.phoneNumber;
      }
      this.invoiceTemplatePayload.recipientContact.contactDetails.name.value =
        contact?.name;
      this.invoiceTemplatePayload.recipientContact.contactDetails.id.value =
        contact._id;
    },
    handleSelectedSenderContacts(contact) {
      this.senderContactCompleteInfo = contact;
      this.searchSenderContact("");

      this.isValidSenderCompanyContact = true;

      console.log("🚀 ~ handleRecipientContactChanges ~ contact:", contact);
      this.invoiceTemplatePayload.senderContact.contactDetails.email.value =
        contact.email;
      // Find phone
      let senderContactPhone = contact.contactMedium.find(
        (medium) => medium.mediumType == "phone"
      );
      if (senderContactPhone && senderContactPhone.characteristic.phoneNumber) {
        this.invoiceTemplatePayload.senderContact.contactDetails.phone.value =
          senderContactPhone.characteristic.phoneNumber;
      }
      this.invoiceTemplatePayload.senderContact.contactDetails.name.value =
        contact?.name;
      this.invoiceTemplatePayload.senderContact.contactDetails.id.value =
        contact._id;
    },
    makeRecipientAddressAsShippingOrBillingAddress(isSame, type) {
      console.log(
        "🚀 ~ makeRecipientAddressAsShippingOrBillingAddress ~ isSame:",
        isSame
      );
      let { name, checked } = isSame;
      if (
        name == "Same as your client address" &&
        checked &&
        type == "shipping"
      ) {
        this.invoiceTemplatePayload.recipientShippingDetails.address.value =
          this.invoiceTemplatePayload.recipient.businessDetails.address.value;
        this.invoiceTemplatePayload.recipientShippingDetails.address.readOnly = true;
      } else if (
        name == "Same as your client address" &&
        !checked &&
        type == "shipping"
      ) {
        this.invoiceTemplatePayload.recipientShippingDetails.address.value =
          this.addressTemplate;
        this.invoiceTemplatePayload.recipientShippingDetails.address.readOnly = false;
      } else if (
        name == "Same as your client address" &&
        checked &&
        type == "billing"
      ) {
        this.invoiceTemplatePayload.recipientBillingDetails.address.value =
          this.invoiceTemplatePayload.recipientShippingDetails.address.value;
        this.invoiceTemplatePayload.recipientShippingDetails.address.readOnly = true;
      } else if (
        name == "Same as your client address" &&
        !checked &&
        type == "billing"
      ) {
        this.invoiceTemplatePayload.recipientBillingDetails.address.value =
          this.addressTemplate;
        this.invoiceTemplatePayload.recipientShippingDetails.address.readOnly = false;
      }
    },
    buildAfterGivenDate() {
      this.invoiceTemplatePayload.quoteBasicInfo.quoteValidTillDate.value =
        this.addDatesToGivenDateV1(
          this.invoiceTemplatePayload.quoteBasicInfo.quoteDate.value,
          this.invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting.value
        );
      this.invoiceTemplatePayload.quoteBasicInfo.validTillDateSetting.enabled = false;
    },
    addDatesToGivenDate(givenDate = null, toAdd) {
      console.log(
        "🚀 ~ addDatesToGivenDate ~ givendate=null,toAdd:",
        givenDate,
        toAdd
      );
      if (givenDate == null) return;
      Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        console.log("🚀 ~ addDatesToGivenDate ~ date:", date);
        return date.toJSON();
      };
      var customDate = new Date(givenDate);

      return customDate.addDays(toAdd);
    },
    addDatesToGivenDateV1(givenDate = null, toAdd) {
      console.log(
        "🚀 ~ addDatesToGivenDate ~ givendate=null,toAdd:",
        givenDate,
        toAdd
      );
      if (givenDate == null) return;
      Date.prototype.addDays = function (noOfDaysToAdd) {
        // Copy the startDate to avoid mutating the original date
        let currentDate = new Date(this.valueOf());
        // Define a function to check if a given date is a weekend (Saturday or Sunday)
        function isWeekend(date) {
          const dayOfWeek = date.getDay();
          return dayOfWeek === 0 || dayOfWeek === 6; // 0 = Sunday, 6 = Saturday
        }

        // Define a function to add days to a date while skipping weekends
        function addDays(date, days) {
          let result = new Date(date);
          result.setDate(result.getDate() + days);
          return result;
        }

        // Loop through each day to add and skip weekends
        let addedBusinessDays = 0;
        while (addedBusinessDays < noOfDaysToAdd) {
          currentDate = addDays(currentDate, 1);
          if (!isWeekend(currentDate)) {
            addedBusinessDays++;
          }
        }

        return currentDate.toJSON(); // Return the date after adding business days
      };
      var customDate = new Date(givenDate);

      return customDate.addDays(toAdd);
    },
    enableSubtitle() {
      this.invoiceTemplatePayload.header.isQuoteSubtitle =
        !this.invoiceTemplatePayload.header.isQuoteSubtitle;
      this.invoiceTemplatePayload.header.subtitle.readOnly =
        !this.invoiceTemplatePayload.header.subtitle.readOnly;
    },
    addAdditionalField() {
      this.invoiceTemplatePayload.quoteBasicInfo.additionalFields.push(
        JSON.parse(JSON.stringify(this.additionalFieldTemplate))
      );
    },
    removeAdditionalField(index) {
      this.invoiceTemplatePayload.quoteBasicInfo.additionalFields.splice(
        index,
        1
      );
    },
    handleInputFocusOut(prop) {
      if (!prop) return;

      switch (prop) {
        // Quote title
        case "quote-title":
          if (
            this.invoiceTemplatePayload.header.title.value?.trim().length === 0
          ) {
            this.invoiceTemplatePayload.header.title.value = "Invoice";
          }
          this.invoiceTemplatePayload.header.title.readOnly =
            !this.invoiceTemplatePayload.header.title.readOnly;
          break;
        // Quote subtitle
        case "quote-subtitle":
          if (
            this.invoiceTemplatePayload.header.subtitle.value?.trim().length === 0
          ) {
            this.invoiceTemplatePayload.header.subtitle.value = "";
            this.invoiceTemplatePayload.header.isQuoteSubtitle = false;
            this.invoiceTemplatePayload.header.subtitle.readOnly = true;
          } else {
            this.invoiceTemplatePayload.header.subtitle.readOnly =
              !this.invoiceTemplatePayload.header.subtitle.readOnly;
          }
          break;
        // Sender title
        case "sender-title":
          if (
            this.invoiceTemplatePayload.sender.title.value?.trim().length === 0
          ) {
            this.invoiceTemplatePayload.sender.title.value = "Quotaion from";
          }
          this.invoiceTemplatePayload.sender.title.readOnly =
            !this.invoiceTemplatePayload.sender.title.readOnly;
          break;
        // Seller contact title
        case "sender-contact-title":
          if (
            this.invoiceTemplatePayload.senderContact.title.value?.trim()
              .length === 0
          ) {
            this.invoiceTemplatePayload.senderContact.title.value =
              "Quotaion from";
          }
          this.invoiceTemplatePayload.senderContact.title.readOnly =
            !this.invoiceTemplatePayload.senderContact.title.readOnly;
          break;
        // Buyer contact title
        case "recipient-contact-title":
          if (
            this.invoiceTemplatePayload.recipientContact.title.value?.trim()
              .length === 0
          ) {
            this.invoiceTemplatePayload.recipientContact.title.value =
              "Quotaion from";
          }
          this.invoiceTemplatePayload.recipientContact.title.readOnly =
            !this.invoiceTemplatePayload.recipientContact.title.readOnly;
          break;
        // recipient title
        case "recipient-title":
          if (
            this.invoiceTemplatePayload.recipient.title.value?.trim().length === 0
          ) {
            this.invoiceTemplatePayload.recipient.title.value = "Quotaion from";
          }
          this.invoiceTemplatePayload.recipient.title.readOnly =
            !this.invoiceTemplatePayload.recipient.title.readOnly;
          break;
        // Sender shipping title
        case "recipient-billing-title":
          if (
            this.invoiceTemplatePayload.recipientBillingDetails.title.value?.trim()
              .length === 0
          ) {
            this.invoiceTemplatePayload.recipientBillingDetails.title.value =
              "Billing to";
          }
          this.invoiceTemplatePayload.recipientBillingDetails.title.readOnly =
            !this.invoiceTemplatePayload.recipientBillingDetails.title.readOnly;
          break;
        // Recipient shipping title
        case "recipient-shipping-title":
          if (
            this.invoiceTemplatePayload.recipientShippingDetails.title.value?.trim()
              .length === 0
          ) {
            this.invoiceTemplatePayload.recipientShippingDetails.title.value =
              "Shipping to";
          }
          this.invoiceTemplatePayload.recipientShippingDetails.title.readOnly =
            !this.invoiceTemplatePayload.recipientShippingDetails.title.readOnly;
          break;

        // Recipient Notes
        case "recipientNotes":
          if (
            this.invoiceTemplatePayload.recipientNotes.label?.trim().length === 0
          ) {
            this.invoiceTemplatePayload.recipientNotes.label = "Add notes";
          }
          this.invoiceTemplatePayload.recipientNotes.readOnly =
            !this.invoiceTemplatePayload.recipientNotes.readOnly;
          break;

        // Recipient Attachments
        case "recipientAttachments":
          if (
            this.invoiceTemplatePayload.recipientAttachments.label?.trim()
              .length === 0
          ) {
            this.invoiceTemplatePayload.recipientAttachments.label =
              "Add attachments";
          }
          this.invoiceTemplatePayload.recipientAttachments.readOnly =
            !this.invoiceTemplatePayload.recipientAttachments.readOnly;
          break;

        // Recipient Additional Info
        case "recipientAdditionalInfo":
          if (
            this.invoiceTemplatePayload.recipientAdditionalInfo.label?.trim()
              .length === 0
          ) {
            this.invoiceTemplatePayload.recipientAdditionalInfo.label =
              "Add attachments";
          }
          this.invoiceTemplatePayload.recipientAdditionalInfo.readOnly =
            !this.invoiceTemplatePayload.recipientAdditionalInfo.readOnly;
          break;
        // Recipient From signature
        case "recipientfromsignature":
          if (
            this.invoiceTemplatePayload.fromSignature.label?.trim().length === 0
          ) {
            this.invoiceTemplatePayload.fromSignature.label = "Add signature";
          }
          this.invoiceTemplatePayload.fromSignature.readOnly =
            !this.invoiceTemplatePayload.fromSignature.readOnly;
          break;

        // Recipient TO signature
        case "recipienttosignature":
          if (
            this.invoiceTemplatePayload.toSignature.label?.trim().length === 0
          ) {
            this.invoiceTemplatePayload.toSignature.label = "Add signature";
          }
          this.invoiceTemplatePayload.toSignature.readOnly =
            !this.invoiceTemplatePayload.toSignature.readOnly;
          break;

        default:
          console.log("default case");
      }
    },
    receivedAddressData(val) {
      console.log(val);
      let address1 = JSON.parse(JSON.stringify(val));
      // let address2 = JSON.parse(JSON.stringify(val))
      this.newAccPayload.contactMedium[3] = JSON.parse(
        JSON.stringify(address1)
      );
      // let address1 = JSON.parse(JSON.stringify(val));
      // this.invoiceTemplatePayload.sender.businessDetails.address = JSON.parse(
      //   JSON.stringify(address1)
      // );
    },
    receivedAddressData1(val) {
      console.log(val);
      let address1 = JSON.parse(JSON.stringify(val));
      // let address2 = JSON.parse(JSON.stringify(val))
      this.newConPayload.contactMedium[2] = JSON.parse(
        JSON.stringify(address1)
      );
      // let address1 = JSON.parse(JSON.stringify(val));
      // this.invoiceTemplatePayload.sender.businessDetails.address = JSON.parse(
      //   JSON.stringify(address1)
      // );
    },
    handleRecipientChanges(recipient, editMode = false) {
      console.log('🚀 ~ handleRecipientChanges ~ editMode:', editMode);
      console.log('🚀 ~ handleRecipientChanges ~ recipient:', recipient);
      if (!recipient) return;

      this.invoiceTemplatePayload.recipientContact.contactDetails.name.value = '';
      this.addContactKey += 1;
      console.log(
        'editmode...',
        editMode,
        this.invoiceTemplatePayload.recipientContact.contactDetails.name.value
      );

        if(recipient && recipient?.name){
          console.log("adacc account name emited from account",recipient?.name,recipient);

        this.accountPreviewInContact = recipient?.name;
        this.accountPreviewInContact1 = recipient?.name;
        this.newConPayload.tradingName = this.accountPreviewInContact;
      this.newConPayload.company = this.accountPreviewInContact;
      this.newConPayload.companyName = this.accountPreviewInContact;

      }
      this.recipientCompleteInfo = recipient;
      this.isValidRecipientCompany = true;
      this.recipientSearchPlaceHolder = '';
      this.searchRecipientList('');
      this.invoiceTemplatePayload.recipient.businessDetails.businessName.value =
        recipient?.tradingName;
      this.invoiceTemplatePayload.recipient.businessDetails.email.value =
        recipient?.email;
      // Find phone
      let phone = recipient.contactMedium?.find(
        (item) => item.mediumType == 'phone'
      );
      if (
        phone &&
        phone.characteristic &&
        phone.characteristic.phoneNumber &&
        phone.characteristic.phoneNumber.value
      ) {
        this.invoiceTemplatePayload.recipient.businessDetails.phone.value =
          phone.characteristic.phoneNumber;
      }
      this.invoiceTemplatePayload.recipient.businessDetails.id.value =
        recipient._id;

      let address = recipient.contactMedium?.find(
        (item) => item.mediumType == 'postalAddress'
      );

      this.invoiceTemplatePayload.recipient.businessDetails.address.value =
        address
          ? address
          : this.invoiceTemplatePayload.recipient.businessDetails.address.value;
      this.invoiceTemplatePayload.recipient.businessDetails.city.value = address
        ? address.characteristic.city
        : this.invoiceTemplatePayload.recipient.businessDetails.address.value
            .characteristic.city;

      this.buildClientShippingAndBillingAddress(
        recipient.contactMedium,
        editMode,
        recipient.name
      );
      this.prepareRecipientContacts(recipient);
      this.findTaxForRecipient(recipient);

      console.log(
        this.invoiceTemplatePayload,
        '-------------------------------',
        this.initialPricingTableData,
        '-------------------------------------------------',
        this.quotePricingData,
        'fix the select value for all the values',
        this.initialData,
        '--------------------------------------------------------------------------',
        this.invoiceTemplatePayload
      );
    },
    async findTaxForRecipient(recipient) {
      const defaultSettings = await MLTAxiosInstance.get(`/settings`);
      let addressType = defaultSettings?.data?.taxAddressType;
      let taxData = {};
      let obj = {
        specificAccount: true,
        "appliesTo.tradingName": recipient.name,
        "province.name": recipient.contactMedium[3].characteristic.state,
        "country.name.common":
          recipient.contactMedium[5].characteristic.country,
        locationRef: addressType,
      };
      obj;
      let obj1 = {
        specificMarket: true,
        "appliesTo.name": recipient.partyCharacteristic[2].value,
        "province.name": recipient.contactMedium[3].characteristic.state,
        "country.name.common":
          recipient.contactMedium[5].characteristic.country,
        locationRef: addressType,
      };
      obj1;
      let obj2 = {
        allAccount: true,
        "province.name": recipient.contactMedium[3].characteristic.state,
        "country.name.common":
          recipient.contactMedium[5].characteristic.country,
        locationRef: addressType,
      };
      obj2;
      let obj3 = {
        allAccount: true,
        province: [],
        "country.name.common":
          recipient.contactMedium[5].characteristic.country,
        locationRef: addressType,
      };
      obj3;

      let f2x = {
        company: `${this.company}`,
        type: "filter-search",
        module: "tax",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 100,
        filterQuery: {},
        searchQuery: "",
      };
      console.log(f2x, "lolololololo", recipient);
      f2x.filterQuery = obj;
      let tax1 = await UPCAxiosInstance.post("util/filterData", f2x);
      console.log(tax1.data.data, "tax1");
      if (tax1.data.data.length == 0) {
        f2x.filterQuery = obj1;
        let tax2 = await UPCAxiosInstance.post("util/filterData", f2x);
        console.log(tax2.data.data, "tax2");
        if (tax2.data.data.length == 0) {
          f2x.filterQuery = obj2;
          let tax3 = await UPCAxiosInstance.post("util/filterData", f2x);
          console.log(tax3.data.data, "tax3");
          if (tax3.data.data.length == 0) {
            f2x.filterQuery = obj3;
            let tax4 = await UPCAxiosInstance.post("util/filterData", f2x);
            console.log(tax4.data.data, "tax4");
            if (tax4.data.data.length != 0) {
              taxData = tax4.data.data[0];
            }
          } else {
            taxData = tax3.data.data[0];
          }
        } else {
          taxData = tax2.data.data[0];
        }
      } else {
        taxData = tax1.data.data[0];
      }
      // f2x.filterQuery = obj;
      // let x2x = await UPCAxiosInstance.post('util/filterData', f2x);
      // if (x2x.data.data.length == 0) {
      //   f2x.filterQuery = obj1;
      //   let x2x = await UPCAxiosInstance.post('util/filterData', f2x);
      //   taxData = x2x?.data?.data[0];
      // }
      // taxData = []
      console.log(
        recipient,
        taxData,
        this.getMatchingTaxes(taxData, recipient),
        "taxData"
      );
      this.recipientTaxes = this.getMatchingTaxes(taxData, recipient);
    },
    getMatchingTaxes(payload1, payload2) {
      // Safely extract the tax array from payload 1, default to an empty array if not present
      const taxArray1 = Array.isArray(payload1.tax) ? payload1.tax : [];

      // Safely extract the taxDefinition array from payload 2, default to an empty array if not present
      const taxDefinitionArray2 = Array.isArray(payload2.taxDefinition)
        ? payload2.taxDefinition
        : [];

      // Filter the taxDefinition array from payload 2 to get only checked items
      const checkedTaxes = taxDefinitionArray2.filter((tax) => tax.checked);

      // Map the checked taxes to get their tax names
      const checkedTaxNames = checkedTaxes
        .map((tax) => tax.taxname)
        .filter(Boolean); // Filter out undefined or null tax names

      // Filter the tax array from payload 1 to get items with names not matching the checked tax names
      const unmatchedTaxes = taxArray1.filter(
        (tax) => !checkedTaxNames.includes(tax.taxname)
      );

      // If there are unmatched taxes and taxType exists, add taxType to the first unmatched tax
      if (unmatchedTaxes.length > 0 && payload1.taxType) {
        unmatchedTaxes[0].taxType = payload1.taxType;
      }
      // Ensure all keys in the returned array are correctly spelled
      const correctedUnmatchedTaxes = unmatchedTaxes.map((tax) => {
        return {
          taxName: tax?.taxname, // Correcting to taxName
          rate: tax?.rate,
          taxType:
            tax?.taxType?.toLowerCase() !== "compound"
              ? "cumulative"
              : "compound", // Ensure correct spelling
        };
      });

      return correctedUnmatchedTaxes;
    },

    handleSenderChanges(sender) {
      console.log("🚀 ~ handleSenderChanges ~ sender:", sender);
      if (!sender) return;
      this.senderCompleteInfo = sender;
      this.isValidSenderCompany = true;
      this.searchSenderList("");
      this.invoiceTemplatePayload.sender.businessDetails.businessName.value =
        sender.tradingName;
      this.invoiceTemplatePayload.sender.businessDetails.email.value =
        sender.email;
      // Find phone
      let phone = sender.contactMedium?.find(
        (item) => item.mediumType == "phone"
      );
      if (
        phone &&
        phone.characteristic &&
        phone.characteristic.phoneNumber &&
        phone.characteristic.phoneNumber.value
      ) {
        this.invoiceTemplatePayload.sender.businessDetails.phone.value =
          phone.characteristic.phoneNumber;
      }
      this.invoiceTemplatePayload.sender.businessDetails.id.value = sender._id;
      let address = sender.contactMedium?.find(
        (item) => item.mediumType == "postalAddress"
      );
      console.log("🚀 ~ handleSenderChanges ~ address:", address);

      this.invoiceTemplatePayload.sender.businessDetails.address.value = address
        ? address
        : this.invoiceTemplatePayload.sender.businessDetails.address.value;
      this.invoiceTemplatePayload.sender.businessDetails.city.value = address
        ? address.characteristic.city
        : this.invoiceTemplatePayload.sender.businessDetails.address.value
            .characteristic.city;
      // this.buildClientShippingAndBillingAddress(sender.contactMedium); // command for temp by asif
      this.prepareSenderContacts(sender);
    },
    buildClientShippingAndBillingAddress(
      contactMediums,
      editMode = false,
      companyName
    ) {
      // Find default contact address
      let address = contactMediums?.find(
        (item) => item.mediumType == "postalAddress"
      );
      console.log("contactMediums...", contactMediums, companyName);

      // Prepare site adress list for dropdown
      let siteAddress = contactMediums
        ?.map((item) => {
          if (
            item &&
            item.mediumType?.toLowerCase() == "sitelocation" &&
            item.characteristic &&
            item.characteristic.description
          ) {
            console.log(
              "🚀 ~ siteAddress ~ {...item,decription:item.characteristic.description}:",
              { ...item, decription: item.characteristic.description }
            );
            return { ...item, description: item.characteristic.description };
          }
        })
        .filter((item) => item);
      console.log("🚀 ~ siteAddress ~ siteAddress:", siteAddress);
      this.recipientSiteAddresses = siteAddress.map((item) => ({
        ...item,
        companyName: companyName,
      }));
      this.shippingOptions = this.recipientSiteAddresses;
      console.log(
        "emit...123 shippingOptions",
        this.recipientSiteAddresses
      );

      // this.recipientSiteAddresses = siteAddress;
      let defaultSiteAddress = null;
      // Find default site address if we dont found then asing default contact address as site address
      if (!editMode) {
        defaultSiteAddress = contactMediums?.find(
          (item) =>
            item &&
            item.mediumType &&
            item.mediumType?.toLowerCase() == "sitelocation" &&
            item.characteristic &&
            item.characteristic.default
        );
        console.log(
          "🚀 ~ buildClientShippingAndBillingAddress ~ defaultSiteAddress:",
          defaultSiteAddress
        );
      } else {
        console.log(
          "🚀 ~ buildClientShippingAndBillingAddress ~ this.quoteData.contactMediums?:",
          this.quoteData.contactMediums
        );
        // Find default site address if we dont found then asing default contact address as site address
        defaultSiteAddress = this.quoteData.contactMedium?.find(
          (item) =>
            item &&
            item.mediumType &&
            item.mediumType?.toLowerCase() == "recipientshippingaddress" &&
            item.characteristic
        );
        console.log(
          "🚀 ~ buildClientShippingAndBillingAddress ~ defaultSiteAddress:",
          defaultSiteAddress
        );
      }
      if (defaultSiteAddress) {
        this.invoiceTemplatePayload.recipientShippingDetails.address.value =
          defaultSiteAddress;
        this.shippingAddressSearchKey =
          defaultSiteAddress.characteristic?.description;
      } else if (address) {
        this.invoiceTemplatePayload.recipientShippingDetails.isBusinessAddress.value = true;
        this.invoiceTemplatePayload.recipientShippingDetails.address.value =
          address;
        this.shippingAddressSearchKey = address.characteristic?.description;
      } else {
        throw new Error("No address found");
      }
      // Prepare billing address for dropdown

      let billingAddress = contactMediums
        ?.map((item) => {
          if (
            item &&
            item.mediumType?.toLowerCase() == "billinglocation" &&
            item.characteristic &&
            item.characteristic.description
          ) {
            return {
              ...item,
              description: item.characteristic.description,
            };
          }
        })
        .filter((item) => item);
      console.log("🚀 ~ billingAddress ~ billingAddress:", billingAddress);
      this.recipientBillingAddresses = billingAddress.map((item) => ({
        ...item,
        companyName: companyName,
      }));
      console.log(
        "this.recipientBillingAddresses...",
        this.recipientBillingAddresses
      );

      // this.recipientBillingAddresses = billingAddress;

      // Find default billing address if we dont found then asing default contact address as site address
      let defaultBillingAddress = null;

      if (!editMode) {
        defaultBillingAddress = contactMediums?.find(
          (item) =>
            item &&
            item.mediumType &&
            item.mediumType?.toLowerCase() == "billinglocation" &&
            item.characteristic &&
            item.characteristic.default
        );
        console.log(
          "🚀 ~ buildClientShippingAndBillingAddress ~ defaultBillingAddress:",
          defaultBillingAddress
        );
      } else {
        defaultBillingAddress = this.quoteData.contactMedium?.find(
          (item) =>
            item &&
            item.mediumType &&
            item.mediumType?.toLowerCase() == "recipientbillingaddress" &&
            item.characteristic
        );
        console.log(
          "🚀 ~ buildClientShippingAndBillingAddress ~ defaultBillingAddress:",
          defaultBillingAddress
        );
      }
      if (defaultBillingAddress) {
        this.invoiceTemplatePayload.recipientBillingDetails.address.value =
          defaultBillingAddress;
        this.billingAddressSearchKey =
          defaultBillingAddress.characteristic?.description;
      } else if (address) {
        this.invoiceTemplatePayload.recipientBillingDetails.isBusinessAddress.value = true;
        this.invoiceTemplatePayload.recipientBillingDetails.address.value =
          address;
        this.billingAddressSearchKey = address.characteristic?.description;
      } else {
        throw new Error("No address found");
      }
    },
    handleSelectedBillingAddress(address) {
      console.log("🚀 ~ handleSelectedBillingAddress ~ address:", address);
      this.invoiceTemplatePayload.recipientBillingDetails.address.value = address;
      this.billingAddressSearchKey = address.characteristic?.description;

      this.invoiceTemplatePayload.recipientShippingDetails.address.readOnly = true;
    },
    handleSelectedShippingAddress(address) {
      console.log("🚀 ~ handleSelectedShippingAddress ~ address:", address);
      this.invoiceTemplatePayload.recipientShippingDetails.address.value =
        address;
      this.shippingAddressSearchKey = address.characteristic?.description;
      this.invoiceTemplatePayload.recipientShippingDetails.address.readOnly = true;
    },
    async prepareSenderContacts(sender) {
      let senderContactFilterOptions = {
        company: sender.tradingName,
        type: "filter-search",
        module: "hierarchycontacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {
          "role?.name": {
            $in: [
              "Account executive",
              "Sales manager",
              "Sales person",
              "Upc admin",
            ],
          },
          name: {
            $regex: "",
            $options: "i",
          },
          "@baseType": "organisation",
          status: { $regex: "active", $options: "i" },
        },
        searchQuery: "",
      };
      let senderContacts = await MLTAxiosInstance.post(
        "/util/filterData",
        senderContactFilterOptions
      );
      // this.quoteSen
      this.senderCompanyContacts = senderContacts.data.data;
    },
    async prepareRecipientContacts(recipient) {
      if (!recipient) return;
      console.log("🚀 ~ prepareRecipientContacts ~ recipient:", recipient);
      let recipientContactFilterOptions = {
        company: recipient.tradingName,
        type: "filter-search",
        module: "hierarchycontacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {
          status: { $regex: "active", $options: "i" },
          // "role?.name":{"$in":["Account executive","Sales manager","Sales person"]},
          name: {
            $regex: "",
            $options: "i",
          },
          "@baseType": "customer",
        },
        searchQuery: "",
      };
      let recipientContact = await MLTAxiosInstance.post(
        "/util/filterData",
        recipientContactFilterOptions
      );
      this.recipientContacts = recipientContact.data.data;
    },
    addSpecificFields(data) {
      switch (data?.name) {
        case "Add terms and conditions":
          this.showFields(data);
          break;
        case "Add notes":
          this.showFields(data);
          break;
        // case "Add attachments":
        //   this.showFields(data);
        //   break;
        case "Add additional info":
          this.showFields(data);
          break;
        case "Add contact details":
          this.showFields(data);
          break;
      }
    },
    showFields(data) {
      data.checked = true;
      this.addSpecificFieldsArrayValue.push(data?.name);
      this.addSpecificFieldsArray.push(data);
      if (data?.name == "Add terms and conditions") {
        this.terms = true;
        if (
          this.invoiceTemplatePayload.recipientTermsandConditions.conditions
            .length == 0
        ) {
          let data = JSON.parse(
            JSON.stringify(this.termsandConditionsnameAndFields)
          );
          this.invoiceTemplatePayload.recipientTermsandConditions.conditions.push(
            data
          );
        }
      } else if (data?.name == "Add notes") {
        this.notes = true;
      } else if (data?.name == "Add additional info") {
        this.additional = true;
      } else if (data?.name == "Add attachments") {
        this.attachments = true;
      }
    },
    removeTermsandPolicyField(data) {
      this.termsAndPolicyAreaData.forEach((e) => {
        if (data == e?.name) {
          e.checked = false;
        }
      });
      this.addSpecificFieldsArray.forEach((e, index) => {
        if (e?.name == data) {
          if (data == "Add notes") {
            this.notes = false;
            let notesObj = {
              label: "Add notes",
              readOnly: true,
              value: "",
            };
            this.invoiceTemplatePayload.recipientNotes = JSON.parse(
              JSON.stringify(notesObj)
            );
          } else if (data == "Add additional info") {
            this.additional = false;
            let additionalInfoObj = {
              additionalFields: [],
              readOnly: true,
              label: "Add additional info",
            };
            this.invoiceTemplatePayload.recipientAdditionalInfo = JSON.parse(
              JSON.stringify(additionalInfoObj)
            );
          } else if (data == "Add attachments") {
            this.attachments = false;
            let attachmentsObj = {
              label: "Add attachments",
              readOnly: true,
              value: "",
            };
            this.invoiceTemplatePayload.recipientAttachments = JSON.parse(
              JSON.stringify(attachmentsObj)
            );
          }
          this.addSpecificFieldsArray.splice(index, 1);
          this.addSpecificFieldsArrayValue.splice(index, 1);
        }
      });
    },

    addAdditionalTerm(fields, index) {
      fields;
      let data = JSON.parse(
        JSON.stringify(this.termsAndConditionAdditionalFields)
      );
      this.invoiceTemplatePayload.recipientTermsandConditions.conditions[
        index
      ].additionalFields.push(data);
    },
    removeAdditionalTerms(index, col_index) {
      this.invoiceTemplatePayload.recipientTermsandConditions.conditions[
        index
      ].additionalFields.splice(col_index, 1);
    },
    addAdditionalGroup() {
      let data = JSON.parse(
        JSON.stringify(this.termsandConditionsnameAndFields)
      );
      this.invoiceTemplatePayload.recipientTermsandConditions.conditions.push(
        data
      );
    },
    removeAdditionalGroup(index) {
      this.invoiceTemplatePayload.recipientTermsandConditions.conditions.splice(
        index,
        1
      );
      if (
        this.invoiceTemplatePayload.recipientTermsandConditions.conditions
          .length == 0
      ) {
        this.terms = false;
        let data = "Add terms and conditions";
        this.termsAndPolicyAreaData.forEach((e) => {
          if (data == e?.name) {
            e.checked = false;
          }
        });
      }
    },
    dragRowStart(index, col_index) {
      this.drag_start_index = index;
      this.drag_start_col_index = col_index;
    },
    dragRowLeave(index, col_index) {
      this.drag_leave_index = index;
      this.drag_leave_col_index = col_index;
    },
    dragRowEnd() {
      this.swapObjects(
        this.drag_start_index,
        this.drag_start_col_index,
        this.drag_leave_index,
        this.drag_leave_col_index
      );
    },
    swapObjects(
      drag_start_index,
      drag_start_col_index,
      drag_leave_index,
      drag_leave_col_index
    ) {
      // Destructure the objects at the given indices
      const [obj1, obj2] = [
        this.invoiceTemplatePayload.recipientTermsandConditions.conditions[
          drag_start_index
        ].additionalFields[drag_start_col_index],
        this.invoiceTemplatePayload.recipientTermsandConditions.conditions[
          drag_leave_index
        ].additionalFields[drag_leave_col_index],
      ];

      // Swap the objects
      this.invoiceTemplatePayload.recipientTermsandConditions.conditions[
        drag_start_index
      ].additionalFields[drag_start_col_index] = obj2;
      this.invoiceTemplatePayload.recipientTermsandConditions.conditions[
        drag_leave_index
      ].additionalFields[drag_leave_col_index] = obj1;
    },
    termandConditionsInputFocusOut(index) {
      if (
        this.invoiceTemplatePayload.recipientTermsandConditions.conditions[
          index
        ].label?.trim().length === 0
      ) {
        this.invoiceTemplatePayload.recipientTermsandConditions.conditions[
          index
        ].label = "Add terms and conditions";
      }
      this.invoiceTemplatePayload.recipientTermsandConditions.conditions[
        index
      ].readOnly =
        !this.invoiceTemplatePayload.recipientTermsandConditions.conditions[index]
          .readOnly;
    },
    termandConditionstextEditorFocusOut(index, col_index) {
      if (
        this.invoiceTemplatePayload.recipientTermsandConditions.conditions[
          index
        ].label?.trim().length === 0
      ) {
        this.invoiceTemplatePayload.recipientTermsandConditions.conditions[
          index
        ].additionalFields[col_index].label = "";
      }
      this.invoiceTemplatePayload.recipientTermsandConditions.conditions[
        index
      ].additionalFields[col_index].textEditor =
        !this.invoiceTemplatePayload.recipientTermsandConditions.conditions[index]
          .additionalFields[col_index].textEditor;
    },
    notesValue(value) {
      this.invoiceTemplatePayload.recipientNotes.value = value;
      console.log(value,"notesValue",this.invoiceTemplatePayload);

      this.notes1 = value;
    },
    async removeAttachmentValue(val1, val2, val3) {
      val1;
      val3;
      let value = this.invoiceTemplatePayload.recipientAttachments.value.filter(
        (item) => {
          if (item.filename) {
            return val2 != item.filename;
          } else {
            return val2 != item?.mimeType?.filename;
          }
        }
      );
      this.invoiceTemplatePayload.recipientAttachments.value = JSON.parse(
        JSON.stringify(value)
      );
      console.log(
        "attachments2",
        this.invoiceTemplatePayload.recipientAttachments.value
      );
    },
    async attachmentValue(event) {
      console.log("🚀 ~ attachmentValue ~ event:", event[0]);
      console.log(
        "🚀 ~ attachmentValue ~ event:",
        event,
        event.target,
        event.Target
      );
      let formData = new FormData();
      for (let i = 0; i < event.length; i++) {
        formData.append("files[]", event[i]);
      }
      let attachmentData = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      for (let j = 0; j < attachmentData.data.length; j++) {
        const item = attachmentData.data[j];
        const url = await UPCAxiosInstance.get(
          `/gcpupload/files/${item.data.name}`,
          {
            responseType: "arraybuffer",
          }
        );
        console.log(
          "formData",
          attachmentData.data,
          url,
          await this.bufferToDataURL(url.data)
        );
      }
      // attachmentData.data.forEach(async(item)=>{
      //   const url =  await UPCAxiosInstance.get(`/gcpupload/files/${item.data.attachment.name}`, {
      //       responseType: "arraybuffer",
      //     });
      // })
      let attachments = attachmentData.data?.map((attachment) => {
        let obj = attachment;
        obj["attachmentType"] = "quoteDocuments";
        // return obj
        return {
          attachmentType: "quoteDocuments",
          description: "Additional documents",
          mimeType: attachment,
          name: "Handset commercial brochure.pdf",
          url: "www.tmf_hanset/contentMangement/brochure.pdf",
          size: {
            amount: 3.7,
            units: "Mb",
          },
          validFor: {
            startDateTime: "2019-05-06T12:45:12.031Z",
          },
        };
      });
      const array2Ids =
        this.invoiceTemplatePayload.recipientAttachments.value.map(
          (item) => item.mimeType.filename
        );
      attachments.forEach((item) => {
        if (array2Ids.includes(item.mimeType.filename) != true)
          this.invoiceTemplatePayload.recipientAttachments.value.push(item);
      });
      console.log(this.invoiceTemplatePayload, "attachments");
    },
    addAdditionalInfo() {
      let data = JSON.parse(
        JSON.stringify(this.recipientadditionalFieldTemplate)
      );
      this.invoiceTemplatePayload.recipientAdditionalInfo.additionalFields.push(
        data
      );
    },
    removeAdditionalInfo(index) {
      this.invoiceTemplatePayload.recipientAdditionalInfo.additionalFields.splice(
        index,
        1
      );
    },
    fromSignatureValue(value) {
      this.invoiceTemplatePayload.fromSignature.value = value;
    },
    toSignatureValue(value) {
      this.invoiceTemplatePayload.toSignature.value = value;
    },
  },
};
</script>

<style lang="scss">
.product-offering-container {
  .custom-addtocart-btn {
    border: 1px solid #54bd95;
    color: #54bd95;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: #54bd9533;
    }
    i {
      font-size: 18px;
    }
  }
  .item-added-to-cart {
    background: #54bd95;
    color: white;
    cursor: default;
    &:hover {
      background: #54bd95;
      color: white;
    }
  }
  .footer-panel-fk .hlx-pagination-container {
    justify-content: space-between !important;
  }
  .icon-button {
    max-width: 40px !important;
    max-height: 40px !important;
  }
  .icon-shopping-cart-regular {
    font-size: 24px !important;
  }
  .icon-button__badge {
    font-size: 10px !important;
    top: -3px !important;
    right: -3px !important;
  }
  .list,
  .grid {
    background: white !important;
    color: black !important;
  }
  .grid.primary-bg {
    background: #54bd95 !important;
    color: white !important;
  }
  .list.primary-bg {
    background: #54bd95 !important;
    color: white !important;
  }
  .white-bg {
    background: transparent !important;
  }
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  padding: 20px !important;
  overflow: inherit !important;
  flex-direction: column !important;
  padding-bottom: 100px !important;
  .footer-panel {
    height: 75px !important;
    display: flex !important;
    background: white !important;
    bottom: 0 !important;
    width: calc(100vw - 72px - 47px) !important;
    justify-content: center !important;
    align-items: center !important;
    position: fixed !important;
    padding: 0 10px !important;
  }
  .card-title {
    font-family: QuickSand !important;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    font-weight: bold !important;
    height: 60px !important;
    border-bottom: 1px solid #e5e7eb !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.005em !important;
    padding: 0px !important;
    color: #6b7280 !important;
    .card-header {
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
      height: 60px !important;
      font-size: var(--hlx-font-content-size-sm) !important;
      align-items: center !important;
      margin: 0 16px 0 16px !important;
      color: var(--hlx-text-color-primary) !important;
    }
    .card-footer {
      height: 60px !important;
      border-top: 1px solid #e5e7eb !important;
      border-bottom-right-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      letter-spacing: 0.005em !important;
      color: #6b7280 !important;
      footer {
        width: 100% !important;
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        .card-footerr {
          width: 100% !important;
          display: flex !important;
          justify-content: space-between !important;
          align-items: center !important;
          margin: 0 16px 0 16px !important;
          align-items: center !important;
          height: 60px !important;
        }
      }
    }
  }
}
.filter-panel-listing-page {
  .filter-panel-layout1 {
    padding: 0px !important;
  }
}
.filter-icon-listing-page {
  display: none;
}
.listing-container {
  display: grid;
  grid-template-columns: 300px auto;
}
@media screen and (max-width: 1024px) {
  .listing-container {
    display: grid;
    grid-template-columns: auto;
  }
  .filter-panel-listing-page {
    display: none;
  }
  .filter-icon-listing-page {
    display: block;
  }
}

.pricing-details-card {
  display: none;
  position: absolute;
  // top: 42%; /* Adjust as needed to position below the icon */
  // left: 66%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  bottom: 120%;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
  width: 200px; /* Adjust width as needed */
}
.card-price-discount {
  position: relative;
}
.card-price-discount:hover {
  .pricing-details-card {
    display: block;
  }
}
.second_drawer_style_for_recepient{
  margin: 12px 10px 0px 10px;
}
</style>
