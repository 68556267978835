<template>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <!-- header -->
  <div class="common_single_header_selfservice">
    <div class="head_selfservice">
      <!-- Service qualification -->
      {{ matchFromStatic("Contract") }}
    </div>
    <div class="body_selfservice">
      <!-- Services are accessible at the location. -->
      {{ matchFromStatic("Adaptable contracts designed for your needs.") }}
    </div>
  </div>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <!-- Filter drawer -->
  <hlx-drawer
    :show="show_right_filter"
    :width="300"
    position="right"
    :header="false"
    :footer="false"
    :show-close-icon="true"
    @close="closeRightFilter"
  >
    <template #body>
      <div style="display: flex">
        <FilterComponent
          :key="filterkey"
          :filterpanelname="'agreement'"
          :company-name="$cookies.get('company')"
          :query-value="query"
          :display-data="filter"
          :module="'agreement'"
          @filtered-data="quotesfilterData"
        ></FilterComponent>
      </div>
    </template>
  </hlx-drawer>

  <section style="margin: 10px 15px">
    <!-- header -->
    <section
      v-if="(tableData && tableData?.length > 0) || searchvalue !== ''"
      class="right-view-body-feature"
    >
      <span class="spec-feature --table-tool-bar" style="margin: 10px 0">
        <div class="--table-tool-bar-wrapper">
          <!-- search -->
          <span class="util-items search"
            ><hlx-search
              v-model="searchvalue"
              :label-value="matchFromStatic('Search')"
              @search-key="searchKey"
            ></hlx-search
          ></span>

          <!-- Filter -->
          <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
            <i class="icon-filter-regular"></i>
            <span v-if="filtertaglength != 0" class="filter-count">{{
              filtertaglength
            }}</span>
          </hlx-button>

          <hlx-button class="primary sm" @click="callDirectAdd">
            <i class="icon-plus-circle-regular"></i>
            <!-- Add product -->
            {{ matchFromStatic("Create contract") }}
          </hlx-button>
        </div>
      </span>
    </section>

    <!-- table -->
    <section v-if="tableData && tableData?.length > 0">
      <hlx-table
        :key="refreshTable1"
        :column-count="tableHeads + 2"
        :border="['table', 'header', 'horizontal']"
        :theme="'grey'"
        :bold-headers="false"
        :row-hover="true"
        :striped-rows="false"
        
        :re-arrange-columns="re_arrange"
                    :re-arrange-heads="accounts_table_theads_cc"
                    @close-rearrange="re_arrange = false"
        
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'left'"
            style="padding: 0 15px !important; max-width: 60px !important"
            :fixed="'left'"
            >{{ matchFromStatic("S.no") }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in tableHeads"
            :key="index"
            :prop="i.prop"
            :width="i.width"
            :align="'left'"
            :resizable="true"
          >
            {{ matchFromStatic(i.label) }}
          </hlx-table-head>
          <hlx-table-head :align="'center'" :width="60" :fixed="'right'"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                
                @click="reAssignOrgTableHeader()"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr v-for="(i, index) in tableDataOnSelectedRows" :key="index">
            <hlx-table-cell :width="60" fixed="left">
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in tableHeads"
              :key="col_index"
              :align="'left'"
              :width="i.width"
            >
              <div
                v-if="j.prop === 'displayID'"
                style="padding-left: 1px; cursor: pointer; color: #2879b7"
                onmouseover="this.style.textDecoration='underline';"
                onmouseout="this.style.textDecoration='none';"
                @click="callDirectEdit(i, index)"
              >
                {{ i?.[j.prop] }}

                <div></div>
              </div>
              <div v-else-if="j.prop.toLowerCase() === 'status'">
                <span v-if="i[j.prop] != ''">
                  <div>
                    <hlx-status :type="i[j.prop]"></hlx-status>
                  </div>
                </span>
                <span v-else>
                  {{ "-" }}
                </span>
              </div>
              <div v-if="j.prop === 'createdAt'">
                {{ dateAndTimeFormatter(i["createdAt"]) }}
              </div>

              <div v-if="j.prop === 'updatedAt'">
                {{ dateAndTimeFormatter(i["updatedAt"]) }}
              </div>
              <div v-if="j.prop == 'companyName'">
                {{ i[j.prop] }}
              </div>

              <div v-else-if="j.prop == 'contractName'">{{ i?.[j.prop] }}</div>
            </hlx-table-cell>
            <hlx-table-cell :align="'center'" :width="120" :fixed="'right'">
              <span :id="'table-context' + index" class="action-icon">
                <i
                  style="position: relative"
                  class="icon-more-vertical-filled"
                  :class="i.context === true ? 'active-action' : ''"
                  @click="crudContextMenu($event, i, index)"
                >
                  <hlx-context-menu
                    :display="'display'"
                    :top="containertop"
                    :left="containerleft"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(i)"
                    :data="index.toString()"
                    :show="i.context == true"
                    @chosen="closeCrudContextMenu($event, i, index)"
                  />
                </i>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>

      <!-- pagination -->

      <span class="newsssssssssssssss">
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :label-value="paginationLang"
          :total="totalpageno"
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </span>
    </section>

    <!-- no data -->
    <section v-if="tableData.length === 0 && searchvalue !== ''">
      <hlx-table
        :column-count="tableHeads.length + 2"
        :border="['table', 'header']"
        :bold-headers="false"
        :row-hover="true"
        theme="grey"
        :striped-rows="false"
        :re-arrange-columns="toggleRearrangeModal"
        :re-arrange-heads="tableHeadsRearranging"
        @close-rearrange="toggleRearrangeModal = false"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'left'"
            @new-resize-width="resizedWidth"
            >{{ matchFromStatic("S.no") }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in tableHeads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            style="position: sticky; top: 0px"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
            @new-resize-width="resizedWidth"
          >
            {{ matchFromStatic(i.label) }}
          </hlx-table-head>
          <hlx-table-head v-show="supplierStatus == false" :width="100">
            <span class="action-icon"
              ><i
                class="icon-settings-regular"
                @click="toggleRearrangeModal = true"
                @new-resize-width="resizedWidth"
              ></i></span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell :colspan="tableHeads?.length + 2">{{
              matchFromStatic("No data")
            }}</hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </section>

    <!-- image -->
    <section
      v-if="tableData && tableData?.length == 0 && searchvalue === ''"
      style="
        margin-top: 1.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        align-items: center;
      "
    >
      <span>
        <img
          src="@/assets/images/svg/contractImg.svg"
          alt=""
          style="width: 500px; height: 500px"
        />
      </span>
      <span class="util-items util-items-add">
        <hlx-button class="primary media add-btn" @click="callDirectEdit"
          ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i>
          <!-- Add product -->
          {{ matchFromStatic("Create contract") }}
        </hlx-button>
      </span>
    </section>
  </section>
</template>

<script>
import {
  // DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import FilterComponent from "@/components/filterPanelComponent.vue";

export default {
  components: {
    FilterComponent,
  },
  data() {
    return {
      tableHeaderOrg:[],
      re_arrange: false,
      show_right_filter: false,
      delete_name: "",
      query: {
        Status: ["status"],
      },

      paginationLang: {
        Rows_per_page: this.matchFromStatic("Rows per page"),
        Showing: this.matchFromStatic("Showing"),
        of: this.matchFromStatic("of"),
        results: this.matchFromStatic("results"),
        Go_to: this.matchFromStatic("Go to"),
      },
      tableHeaderOrgcc: [],
      tableData: [],

      totalpageno: 0,
      accounts_table_theads_cc:[
      {
          name: "ID",
          checked: true,
          sortable: false,
          resizable: true,
          disabled: true,
          width: 100,
          label: "ID",
          prop: "displayID",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          name: "Contract name",
          checked: true,
          disabled: true,
          width: 200,
          label: "Contract name",
          prop: "contractName",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          name: "Status",
          checked: true,
          disabled: false,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          name: "Company name",
          checked: true,
          disabled: false,
          width: 150,
          label: "Company name",
          prop: "companyName",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          name: "Created date",
          checked: true,
          disabled: false,
          width: 150,
          label: "Created date",
          prop: "createdAt",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          name: "Last updated",
          checked: true,
          disabled: true,
          width: 150,
          label: "Last updated",
          prop: "updatedAt",
          align: "left",
        },
      ],
      tableHeads: [
        {
          name: "ID",
          checked: true,
          sortable: false,
          resizable: true,
          disabled: true,
          width: 100,
          label: "ID",
          prop: "displayID",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          name: "Contract name",
          checked: true,
          disabled: true,
          width: 200,
          label: "Contract name",
          prop: "contractName",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          name: "Status",
          checked: true,
          disabled: true,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          name: "Company name",
          checked: true,
          disabled: true,
          width: 150,
          label: "Company name",
          prop: "companyName",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          name: "Created date",
          checked: true,
          disabled: true,
          width: 150,
          label: "Created date",
          prop: "createdAt",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          name: "Last updated",
          checked: true,
          disabled: true,
          width: 150,
          label: "Last updated",
          prop: "updatedAt",
          align: "left",
        },
      ],

      notification: "",

      filtertaglength: 0,

      filterQueryforBill: {
        type: "filter",
        module: "agreement",
        isPaginated: true,
        updatedAt: new Date().toJSON(),
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: this.$cookies.get("userName"),
      },

      currPage: 1,
      rowsPerPage: 10,
      externalRowsPerPage: 10,

      searchvalue: "",

      componentkey: 0,

      delete_modal_active: false,

      deleteobj: {},

      filter: [],
    };
  },

  computed: {
    tableDataOnSelectedRows() {
  // // Clone and sort tableData to avoid mutating the original array
  // const sortedData = [...this.tableData].sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
  
  // // If pagination logic is needed, uncomment and use the following:
  // // const start = (this.currPage - 1) * this.rowsPerPage;
  // // const end = start + this.rowsPerPage;
  // // return sortedData.slice(start, end);

  return this.tableData; // Return the sorted array
},
  },

  async mounted() {
    let sampleTheads = await MLTAxiosInstance.get(`/settings`);
  
      if (sampleTheads?.data?.entityRef?.["contractManagement"]) {
        let offerTableHeadModal = sampleTheads.data.entityRef["contractManagement"];
        let offerTableHeadOrg = sampleTheads.data.entityRef[
          "contractManagement"
        ].filter((ele) => ele.checked);
        console.log(
          offerTableHeadOrg,
          "offerTableHeadModal",
          offerTableHeadModal
        );
  
        if (
          offerTableHeadModal.length === this.accounts_table_theads_cc.length
          // &&
          // offerTableHeadModal.every((item, index) => item.name ===  this.accounts_table_theads_cc[index].name)
        ) {
          this.accounts_table_theads_cc = JSON.parse(
            JSON.stringify(offerTableHeadModal)
          );
          this.tableHeads = JSON.parse(
            JSON.stringify(offerTableHeadOrg)
          );
          this.tableHeaderOrgcc = JSON.parse(
            JSON.stringify(this.accounts_table_theads_cc)
          );
          this.tableHeaderOrg = JSON.parse(
            JSON.stringify(this.tableHeads)
          );
        }
      }
      this.ticketdataCalling(this.currPage, this.rowsPerPage);
      await this.$store.dispatch("contractFilterData");
    this.filter = this.$store.getters.contractFilterData;
    console.log("filter data from store", this.filter);
  },

  methods: {
    callDirectEdit(i) {
      this.$router.push(`/contract/edit/${i._id}`);
    },
    callDirectAdd() {
      this.$router.push(`/contract/create/id`);
    },
    async deleteExecRow() {
      const addproduct = await UPCAxiosInstance.delete(
        `/agreementManagement/${this.deleteobj._id}`,
        this.payload
      );

      this.notification = {
        type: "success",
        message: `Done! Your item has been removed.`,
      };

      addproduct;
      this.delete_modal_active = false;
      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },

    computedTableAction() {
      return [
        {
          display: this.matchFromStatic("Edit"),
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        // {
        //   display: this.matchFromStatic("Amendment"),
        //   label: "Amendment",
        //   icon: "icon-clipboard-edit-regular",
        // },
        // {
        //   display: this.matchFromStatic("Renew"),
        //   label: "Renew",
        //   icon: "icon-calendar-plus-alt-regular",
        // },
        {
          display: this.matchFromStatic("Terminate"),
          label: "Terminate",
          icon: "icon-file-times-regular",
        },

        {
          display: this.matchFromStatic("Delete"),
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
    },
    crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 121;

        this.containertop =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().top + 18;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    closeCrudContextMenu($event, payload, i) {
      payload, i;
      if ($event.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        this.delete_name = payload.name;
        this.deleteobj = payload;
      }
      else payload, i;
      if ($event.toLowerCase() === "edit") {
        this.$router.push(`/contract/edit/${payload._id}`);
      }
    },

    async ticketdataCalling(currentPage, rowsPerPage) {
      currentPage, rowsPerPage;
      console.log("ticketdataCalling...123", currentPage, rowsPerPage);
      this.filterQueryforBill["company"] = "developerHalleyx";
      // this.$cookies.get('company');
      this.filterQueryforBill["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQueryforBill["paginatedQuery"]["rowsPerPage"] = rowsPerPage;

      this.filterQueryforBill.module = "agreement";
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log(
          "ticketdataCalling...",
          this.tableData,
          filterResultData.data.data
        );

        // this.totalvalue = filterResultData.data.total;
      }
    },

    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },

    async searchKey(val) {
      console.log("searchKey", val);

      this.searchvalue = val;
      this.filterQueryforBill["company"] = this.$cookies.get("company");
      this.filterQueryforBill["type"] = "filter-search" || "search";
      this.filterQueryforBill["searchQuery"] = val;

      if (this.filtertagvalue?.length != 0) {
        this.filterQueryforBill["type"] = "filter-search";
      }
      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },

    async quotesfilterData(filterSelectedvalue, filterQuery) {
      console.log("quotesfilterData::))", filterSelectedvalue, filterQuery);
      this.filtertaglength = filterSelectedvalue?.length;
      this.filterQueryforBill = filterQuery;

      //  this.searchvalue = '';

      this.filterQueryforBill["company"] = this.$cookies.get("company");

      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log(
          "ticketdataCalling...",
          this.tableData,
          filterResultData.data.data
        );

        // this.totalvalue = filterResultData.data.total;
      }
    },
    
    updateTheads() {
        this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
          (thead) => {
            // Check if the current thead has a matching 'prop' in duplicateTheads
            const isMatching = this.tableHeads.some(
              (duplicateThead) => duplicateThead.prop === thead.prop
            );
  
            // Set the 'checked' property based on whether it's matching
            return { ...thead, checked: isMatching };
          }
        );
      },
    arrange(val) {
        this.re_arrange = val;
        this.updateTheads();
      },
      arrangeColumns(val) {
        this.re_arrange = val;
        this.updateTheads();
      },
    reAssignOrgTableHeader() {
      // If there is change in the table header then ressign to the Orginal data
      if (
        this.accounts_table_theads_cc != this.tableHeaderOrgcc &&
        this.tableHeaderOrgcc.length > 0
      ) {
        this.accounts_table_theads_cc = JSON.parse(
          JSON.stringify(this.tableHeaderOrgcc)
        );
      }
      if (
        this.tableHeads != this.tableHeaderOrg &&
        this.tableHeaderOrg.length > 0
      ) {
        this.tableHeads = JSON.parse(
          JSON.stringify(this.tableHeaderOrg)
        );
      }
      this.re_arrange = true;
    },
    async applyColumns(val) {
      // To get rearranged header
      console.log("applyColumns", val);
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      let concatedEntity = sampleTheads?.data?.entityRef || {};

      concatedEntity.contractManagement = val;

      let reArrangeTableHeadPayload = { entityRef: concatedEntity };

      const changeTableHeader = await MLTAxiosInstance.patch(
        `/settings`,
        reArrangeTableHeadPayload
      );
      console.log(
        changeTableHeader,
        "reArrangeTableHeadPayload",
        reArrangeTableHeadPayload
      );

      let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
      console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

      let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
      this.accounts_table_theads_cc = entityRef["contractManagement"];
      this.tableHeads = this.accounts_table_theads_cc.filter(
        (ele) => ele.checked
      );
      this.tableHeaderOrgcc = JSON.parse(
        JSON.stringify(this.accounts_table_theads_cc)
      );
      this.tableHeaderOrg = JSON.parse(
        JSON.stringify(this.tableHeads)
      );
    },
    resizedWidth(val) {
      // To get resized width
      console.log(val, "resizedWidth", val);

      const updateWidth = (array) => {
        let item = array.find((i) => i?.prop === val?.prop);
        if (item) item.width = val.width;
      };

      updateWidth(this.tableHeads);
      updateWidth(this.accounts_table_theads_cc);
      console.log(
        this.tableHeads,
        "resizedWidth loop",
        this.accounts_table_theads_cc
      );
      this.applyColumns(this.accounts_table_theads_cc);
    },
    async currentPage(val) {
      if (this.companyasignee != "") {
        this.currPage = val;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
        //  this.refreshTable();
      }
    },

    async changeRowsPerPage(count) {
      //
      if (this.companyasignee != "") {
        this.rowsPerPage = count;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
        // this.refreshTable();
      }
    },
  },
};
</script>

<style></style>
