<template>
    <section class="layout-seperation">
      <section class="layout-left-panel">
        <div
          class="left-panel-container"
          @click="previousLevel"
        >
          <i class="icon-arrow-left-regular"></i>
          <p>Back</p>
        </div>
      </section>
      <section class="layout-right-panel">
        <section class="right-panel-container">
          <section class="header">
            <section v-if="$route.query.name === 'Email triggers'" class="header-left-section">
              <p class="title">{{matchFromStatic("Email triggers")}}</p>
              <p class="sub-title">{{matchFromStatic("Automated actions initiated by email events")}}</p>
            </section>
            <section v-if="$route.query.name === 'Assignment rule'" class="header-left-section">
              <p class="title">{{matchFromStatic("Assignment rule")}}</p>
              <p class="sub-title">{{matchFromStatic("Reassign the tasks efficiently based on rule")}}</p>
            </section>
          </section>
          <section class="content">
            <div class="card-holder">
              <div class="cards-container">
                <hlx-card
                  v-for="(card, index) in card_data"
                  :key="index"
                  class="custom-card"
                  :seperator="false"
                  :card-title="false"
                  :card-footer="false"
                  :hover="true"
                  height="210"
                  @click="nextLevel(card)"
                >
                  <template #card-body>
                    <div style="padding: 0 15px">
                      <div class="circleInCard">
                        <i :class="card.icon"></i>
                      </div>
                      <p class="card-head">
                        <b>{{ matchFromStatic(card.name) }}</b>
                      </p>
                      <p class="card-desc copy-click customFieldsCardDescription">
                        {{ matchFromStatic(card.description) }}
                      </p>
                    </div>
                  </template>
                  <template #card-footer>
                    {{ matchFromStatic(card.card_footer) }}
                  </template>
                </hlx-card>
              </div>
            </div>
          </section>
        </section>
      </section>
    </section>
  </template>
  
  <script>
  import { UPCAxiosInstance } from '@/config/axiosConfig';
  
  export default {
    data() {
      return {
        card_data: [], // Current display data for cards
        navigationHistory: [], // Stack to store the navigation history
        originalCardData: [], // Initial data from the API
      };
    },
    async mounted() {
      let navigationData = await UPCAxiosInstance.get('/customHierarchy');
      console.log(navigationData,'navigation')
      this.originalCardData = JSON.parse(
        JSON.stringify(navigationData.data[0].module[0].main)
      );
   
      let footerData = navigationData.data[0].module[0].footer[1].sections.filter((item)=>{
        if(item.name == 'Organization' || item.name == 'User profile' || item.name == 'Users'){
          return item
        }
      })
      let settingsData = navigationData.data[0].module[0].footer[1]
      delete settingsData['sections']
      settingsData['child'] = JSON.parse(JSON.stringify(footerData))
      console.log(footerData,'footer',settingsData)
      this.originalCardData.push(settingsData)
      let filteredOriginalData = this.originalCardData.filter((item) => {
        return !item.title;
      });
      console.log(filteredOriginalData,'fjdks')
      this.card_data = JSON.parse(JSON.stringify(filteredOriginalData));
    },
    methods: {
      previousLevel() {
        // Check if there is any previous level in history
        if (this.navigationHistory.length > 0) {
          const data = this.navigationHistory.pop(); // Restore the last level
          console.log(data,this.navigationHistory, 'previous');
          if (this.navigationHistory.length > 0) {
          this.card_data = this.navigationHistory[this.navigationHistory.length - 1]
          } else {
            this.card_data = this.originalCardData
          }
        } else {
          this.$router.back();
        }
      },
      nextLevel(card) {
        if (card.child !== undefined) {
          // Save the current level to the history stack
          console.log();
          
  
          // Filter and set the next level data
          let filteredChildData = card.child.filter((item) => {
            return !item.title;
          });
          this.card_data = JSON.parse(JSON.stringify(filteredChildData));
          
          this.navigationHistory.push(JSON.parse(JSON.stringify(this.card_data)));
          
        } else {
          if(this.$route.query.name === 'Email triggers'){
            if(card.name.toLowerCase() === "lead"){
          this.$router.push(`/settings/emailTriggers?module=${"salesLead"}&customName=${card.name}`)
            }else{
          this.$router.push(`/settings/emailTriggers?module=${card.name}&customName=${card.name}`)

            }
          }else if(this.$route.query.name === 'Assignment rule'){
            if(card.name.toLowerCase() === "lead"){
          this.$router.push(`/settings/assignmentRule?module=${"salesLead"}&customName=${card.name}`)
            }else{
          this.$router.push(`/settings/assignmentRule?module=${card.name}&customName=${card.name}`)

            }
          }
        }
      },
    },
  };
  </script>
  
  <style lang="scss">
  .customFieldsCardDescription {
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  </style>
  