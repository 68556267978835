import { createRouter, createWebHistory } from "vue-router";
import VueCookies from "vue-cookies";
// Decision flow
import decisionAddView from "../views/DesicionTable/DecisionAddView.vue";
import decisionEditViewV1 from "../views/DesicionTable/DecisionEditViewV1.vue";
import decisionView from "../views/DesicionTable/DecisionView.vue";
// import decisionAddView from '../views/DesicionTable/DecisionAddView.vue'
// import editDescisionView from '../views/DesicionTable/DecisionEdit'
// import addDecisionTable from '../views/DesicionTable/DecisionAddView'
// import viewDecisionView from '../views/DesicionTable/ViewDecisionView'
import matrix from "../components/Matrix/MatrixView.vue";
import grid from "../components/Grid/TestView.vue";
import signupView from "../views/signupView.vue";
import signinView from "../views/signinView";
import settingView from "../views/settingView";
import dashboardView from "../views/dashboardView";
import accountView from "../views/accountView.vue";
import planView from "../views/planView.vue";
import paymentView from "../views/paymentView.vue";
import roleView from "../views/rolesAndPermissions.vue";
import addView from "../views/addRoleView.vue";
import organizationView from "../views/generalView.vue";
import apiView from "../views/apiUsageView.vue";
import billView from "../views/billingView.vue";
import userView from "../views/userView.vue";
import additionalFieldsUserView from "@/views/additionalFieldsUserView.vue";
import testView from "../views/testView.vue";
// import tenantView from '../views/tenantView.vue';
import tenantView from "../views/tenantTableView.vue";
import addTenantView from "../views/addTenantView";
import defaultSetting from "../views/activityView";

import mongodbaddschemaflow from "../views/SchemaFlow/mongodbaddschemaflow.vue";
// import localaddschemaflow from '../views/SchemaFlow/localaddschemaflow.vue'
import localaddschemaflow from "../views/SchemaFlow/LocalSchemaAddView.vue";

import selectschemaflow from "../views/SchemaFlow/selectschemaflow.vue";
import addSchemaView from "../views/SchemaFlow/AddSchemaView.vue";
import addCustomVariable from "../views/SchemaFlow/AddCustomVariableView.vue";
import dataExecuteTableView from "../views/DataExecution/DataTableView";
import dataExecuteView from "../views/DataExecution/DataExecuteViewV1";
import addManualDataView from "../views/DataExecution/AddManualDataViewV1.vue";
import editExecuteDataView from "../views/DataExecution/EditExecutionDataViewV1";
import viewExecuteDataView from "../views/DataExecution/ViewExecutionDataViewV1";
import addAPIDataView from "../views/DataExecution/AddAPIDataView";
import addUploadDataView from "../views/DataExecution/AddUploadDataView";
// import businessRulesView from '../views/BusinessRules copy'
import businessRulesView from "../views/BusinessRules";
// import addExpressionView from '../views/Expressions/AddExpressionView.vue'
import addExpressionViewV3 from "../views/Expressions/AddExpressionViewV3.vue";
// import editExpressionView from '../views/Expressions/EditExpressionView.vue'
import editExpressionViewV3 from "../views/Expressions/EditExpressionViewV3.vue";
import ViewExpressionViewV3 from "../views/Expressions/ViewExpressionViewV3.vue";
// import addRuleView from '../views/Rules/AddRuleView.vue'
import addRuleViewV3 from "../views/Rules/AddRuleViewV3.vue";
import editRuleViewV3 from "../views/Rules/EditRuleViewV3.vue";
import addAdvancedLogicalRuleView from "../views/Rules/AddAdvancedLogicalRuleView.vue";
import viewAdvancedLogicalRuleView from "../views/Rules/ViewAdvancedLogicalRuleView.vue";
import editAdvancedLogicalRuleView from "../views/Rules/EditAdvancedLogicalRuleView.vue";
// import editRuleView from '../views/Rules/EditRuleViewV1.vue'
import ViewRuleViewV3 from "../views/Rules/ViewRuleViewV3.vue";
import executeRuleView from "../views/Rules/ExecuteRuleViewV1.vue";
import showallreference from "../views/Referencedata/showallreferencedata.vue";
import addeditreferencedata from "../views/Referencedata/add_edit_reference_data.vue";
import schematable from "../views/SchemaFlow/Schematable.vue";
import editSchema from "../views/SchemaFlow/EditSchemaViewV1.vue";
// import viewSchema from "../views/SchemaFlow/LocalSchemaView.vue";
// import editSchema from "../views/SchemaFlow/LocalSchemaView.vue";
import viewSchema from "../views/SchemaFlow/SchemaView.vue";
import editVariable from "../views/SchemaFlow/EditCustomVariableView.vue";
import depTreeView from "../views/ViewDependencyTreeView";
import schemaDepTreeView from "../views/ViewDependencyTreeViewForSchema";
import auditview from "../views/Audit/AuditView.vue";
import domainManager from "../components/DomainManager.vue";
import editRoleView from "../views/editRoleView.vue";
import addAPI from "../views/API/AddAPIView.vue";
import addAPI2 from "../views/API/AddAPIView2.vue";
import editAPI from "../views/API/EditAPIView.vue";
import editEmail from "../views/Email/EditEmailView.vue";
// import viewAPI from '../views/API/ViewAPIView.vue'
import listAPI from "../views/API/ListAPIView.vue";
import listEmail from "../views/Email/ListEmailView.vue";
import addEmail from "../views/Email/AddEmailView.vue";
// import userGuideView from '../views/UserGuideView.vue'
// import app from '../App.vue'
import confirmView from "../views/inviteView.vue";
import SupplierconfirmView from "../views/SupplierInvite.vue";
import store from "../store";

// UPC routes
import productCatalogTable from "../views/UPC/CatalogTableView.vue";
import upcDashboard from "../views/UPC/upcDashboardView.vue";
import offeringview from "../views/UPC/offeringTableView.vue";
import offeringAssistant from "../views/UPC/ProductOffering/offeringAssistant.vue";

// service catalog
import serviceCatalogTable from "../views/UPC/ServiceCatalogTableView.vue";
import serviceEdit from "../views/UPC/ServiceSpecification/editServiceView.vue";

// resource catalog
import resourceCatalogTable from "../views/UPC/ResourceCatalogTableView.vue";
import resourceEdit from "../views/UPC/ResourceSpecification/editResourceView.vue";

// UPC routes v.20
import overviewSpecification from "../views/UPC/ProductSpecification/overviewProductSpecView.vue";
// CM routes
import cmDashboard from "../views/CM/cmDashboardView.vue";
import cmAccountsLandingPage from "../views/CM/Accounts/accountsTableView.vue";
import addNewAccountPage from "../views/CM/Accounts/addNewAccountView.vue";
import editAccountPage from "../views/CM/Accounts/editAccountView.vue";
import viewAccountPage from "../views/CM/Accounts/viewAccountView.vue";
import viewContactPage from "../views/CM/Contacts/viewContactView.vue";

import cmContactsLandingPage from "../views/CM/Contacts/contactsTableView.vue";
import viewResContactPage from "../views/CM/ResidentialCustomers/viewContactView.vue";

import cmResContactsLandingPage from "../views/CM/ResidentialCustomers/contactsTableView.vue";

// SM routes
import smSupplierLandingPAge from "../views/SupplierManagement/addSupplierTableView.vue";
import smSupplierEditSupplier from "../views/SupplierManagement/editSupplierTableView.vue";

// PCM routes
import categoryTableView from "../views/PCM/categoryTableView.vue";
import categoryActivity from "../views/PCM/activityCategoryTableView.vue";

// test
import configTest from "../views/configTestView.vue";
import configTest2 from "../views/CPQ_Test/configurationTestView.vue";
import configTest3 from "../views/CPQ_Test/offeringListTestView.vue";
import configTest4 from "../views/CPQ_Test/cartTestView.vue";
import bpmn from "../views/BPMN/testBPMN.vue";
import emailview from "../views/emailView.vue";
import demo from "../views/demo.vue";
import pricingTable from "../components/UPC/CPQ/pricingTableComponent.vue";

// test category
import categoryTestView from "../views/categoryTestView.vue";

// templatebuilder
import TemplateBuilder from "../views/TemplateBuilder/templateBuilderView.vue";

// templatebuilder
import formBuilder from "../views/formBuilderView.vue";

// Quote
// import quoteTable from "../views/Quotes/quoteTableView.vue";
// @Offering routes

import productOfferingOverview from "@/views/UPC/ProductOffering/overView.vue";
import productOfferingPreview from "@/views/CPQ_Test/configurationTestView.vue";
import productOfferingDepFLowPreview from "@/views/CPQ_Test/dependentFlowPreview.vue";

// Testing
// dynamic loader
import dynamicLoader from "@/views/test/dynamicLoading.vue";

// CPQ
import placeholderTableComponent from "../components/CM/billingDashboard.vue";
// SMSupplier view
import smSupplierOverview from "../views/SMsupplierView/overviewView.vue";
import smSupplierlocationView from "../views/SMsupplierView/locationView.vue";
import smSuppliercontactView from "../views/SMsupplierView/contactView.vue";
import smSupplierdiscountView from "../views/SMsupplierView/discountView.vue";
import smSuppliercontractView from "../views/SMsupplierView/contractView.vue";
import smSupplieractivitylogView from "../views/SMsupplierView/activitylogView.vue";
import smSupplierproductView from "../views/SMsupplierView/productView.vue";
import smSupplierpurchaseOrderView from "../views/SMsupplierView/purchaseOrderView.vue";
import email from "../views/emailView.vue";

/** @Quote pages start */
import quoteDashboardIllustration from "../views/UPC/Order management/orderManagementDashboard.vue";
import CPQDefaultTemplate from "@/views/UPC/CPQ/defaultTemplate.vue";
import previewTemplate from "@/views/UPC/CPQ/previewTemplate.vue";
import QuoteTable from "@/views/UPC/CPQ/quoteLandingPage.vue";
import signerPreview from "@/views/UPC/CPQ/signerPreview.vue";
import quoteAssistant from "@/views/UPC/CPQ/quoteAssistant.vue";
import quoteHistoryPage from "@/views/UPC/CPQ/quoteHistoryPage.vue";
/** @Quote pages end */
import graphMap from "@/components/Product/graphMap.vue";
import AdditionalCharges from "@/views/UPC/additionalChargeView.vue";
import LoginScreen from "@/components/SelfServeFlow/LoginPage.vue";
import CustomerDashboard from "@/components/SelfServeFlow/CustomerDashboard.vue";
import productDiscountView from "@/views/UPC/productDiscount/productDiscountView.vue";
import productCatalogDashboard from "@/components/UPC/catalogTableDashboard.vue";
import MyService from "@/components/SelfServeFlow/MyService.vue";
import "@/config/axiosConfig.js";

/* Invoices */

/** Order management */
// Product order
import inventoryDashboardIllustration from "../views/Inventory/inventoryTable.vue";
// import billingDashboardIllustration from '../views/UPC/Order management/orderManagementDashboard.vue';
import orderDashboardIllustration from "../views/UPC/Order management/orderManagementDashboard.vue";
import equipmentOrderOverview from "../views/UPC/Equipment order/equipmentOrderOverview.vue";
import equipmentOrderLandingTable from "@/views/UPC/Equipment order/equipmentOrderLandingTable.vue";
import orderLandingPage from "../views/UPC/Order management/productOrder/orderLandingPage.vue";
import orderOverviewPage from "@/views/UPC/Order management/productOrder/orderOverviewPage.vue";

// Service order
import serviceOrderLandingPage from "../views/UPC/Order management/serviceOrder/orderLandingPage.vue";
import serviceOrderOverviewPage from "@/views/UPC/Order management/serviceOrder/orderOverviewPage.vue";

// Resource order
import resourceOrderLandingPage from "../views/UPC/Order management/Resource order/orderLandingPage.vue";
import resourceOrderOverviewPage from "@/views/UPC/Order management/Resource order/orderOverviewPage.vue";
/** End of order management */
// service qualification
// import serviceQualification from "@/components/Product/ServiceQualification/serviceQualificationLocationTable.vue";
import availableOffer from "@/components/Product/ServiceQualification/availableOffer.vue";

// map demo
import serviceaddress from "@/components/Product/ServiceQualification/autoAddressService.vue";
import mapdrag from "@/components/Product/ServiceQualification/MapDrag.vue";
import MapCircle from "@/components/Product/ServiceQualification/MapCircle.vue";
import MapPoint from "@/components/Product/ServiceQualification/MapPoint.vue";
import MapAreaofintrest from "../components/Product/ServiceQualification/MapAreaofintrest.vue";
import placeMap from "@/components/Product/ServiceQualification/placeMap.vue";

// tax
import taxView from "../views/Settings/taxView.vue";

//formTemplate
import formRendererView from "@/views/formRendererView.vue";
import formRendererPreviewView from "@/views/formRendererPreviewView.vue";
import formRendererTestView from "@/views/formRendererTestView.vue";

//emailTemplate
import emailTemplateView from "../views/Settings/EmailTemplate/emailTemplatetableView.vue";
import createEmailTemplate from "@/views/Settings/EmailTemplate/createEmailTemplate.vue";
import htmlEmailTemplate from "@/views/Settings/EmailTemplate/htmlEmailTemplateBuilder.vue";

//emailTemplate
import importTemplateNavigationView from "../views/Settings/importTemplateNavigationView.vue";
import importTemplateDefinitionView from "../views/Settings/importTemplateDefinitionView.vue";
//emailTemplate
import formTemplateNavigationView from "../views/Settings/formTemplateNavigationView.vue";
import formTemplateDefinitionView from "../views/Settings/formTemplateDefinitionView.vue";
import addFormView from "../views/Settings/addFormView.vue";
import editFormView from "../views/Settings/addFormView.vue";
import viewFormView from "../views/Settings/addFormView.vue";

// automation
import automationView from "../views/Settings/automationView.vue";
// import customFieldsView from '../views/Settings/customFieldsView.vue';
import customFieldsView from "../views/Settings/customFieldNavigation.vue";
import customFieldsDefinitionView from "../views/Settings/customFieldDefinition.vue";
import quotingAutomation from "../views/Settings/quotingAutomationView.vue";
import defaultApproval from "../views/Settings/defaultApproval.vue";
import automationViewForProductOffering from "../views/Settings/automationViewForProductOffering.vue";
import dependentProductsTable from "../views/Settings/dependentProductsTable.vue";

// ticket
import ticketTableView from "../views/Ticket/ticketTableView";
import ticketView from "../views/Ticket/ticketView.vue";
import chatApp from "../views/Ticket/chatAppView.vue";
import ticketFieldView from "../views/Ticket/ticketFields.vue";
import ticketTemplateView from "../views/Ticket/ticketTemplate.vue";
import editTemplateView from "../views/Ticket/editTemplate.vue";

import accordianTable from "@/components/Product/accordianTable.vue";
import chartMap3 from "@/components/Product/chartMap3.vue";

// pdfviewer
import pdfviewer from "../views/UPC/CPQ/PdfViewer.vue";

// invoice
import invoiceView from "../views/Invoice/invoiceDashboard.vue";
import previewInvoicepage from "../views/Invoice/previewTemplate.vue";
import invoiceTemplate from "../views/Invoice/createTemplate.vue";

//translate
import translateT from "../views/UPC/CPQ/translateT.vue";

// MDU flow
import mduDashboard from "@/MDU/mduDashboard.vue";

// Lead flow
import LeadMainTable from "@/views/Lead/leadMainTable.vue";
import LeadImportView from "@/views/Lead/leadImport.vue";
import leadOverview from "@/views/Lead/leadOverview.vue";
import leadContact from "@/views/Lead/leadContact.vue";

//loginDetails
import loginDetails from "@/views/Settings/loginDetails.vue";

//CF
import customFieldsRendering from "@/views/Settings/customFieldsRendering.vue";

//static data
import staticDataView from "@/views/Settings/staticData/staticDataView.vue";
import staticDataControl from "@/views/Settings/staticData/staticDataControl.vue";
import bulkPatcher from "@/views/Settings/staticData/bulkPatcher.vue";

//serviceQualification2
import serviceQualification2 from "@/components/Product/ServiceQualification/serviceQualification2.vue";
import sqNew from "@/components/Product/ServiceQualification/sqNew.vue";
// import regionOverview from "@/components/Product/ServiceQualification/regionOverview.vue";

//serviceQualification2 dupe
import sqNewTest from "@/components/Product/ServiceQualificationDuplicate/sqNew.vue";
import regionOverviewTest from "@/components/Product/ServiceQualificationDuplicate/regionOverview.vue";

// ID settings
import idSettings from "@/views/Settings/IdGeneration/idGenerationView.vue";

//Reset password
import resetPassword from "@/views/resetPasswordView.vue";

// Organisation use hierarchy
import organisationUserHierarchy from "@/views/organisationUserHierarchyView.vue";

// Sample form
import sampleForm from "@/views/sampleFormView.vue";

//Email tiggers
import emailTriggersNavigation from "@/views/emailTriggers/emailTriggersNavigation.vue";
import emailTriggers from "@/views/emailTriggers/emailTriggersTable.vue";
import emailTriggersEdit from "@/views/emailTriggers/emailTriggersEdit.vue";

//Assigment rule
import assigmentRuleTable from "@/views/assigmentRule/assigmentRuleTable.vue";
import assigmentRuleEdit from "@/views/assigmentRule/assigmentRuleEdit.vue";

//contract management
import contractManagementTable from "@/views/contractManagement/contractManagementTableView.vue";
import contractManagementOverview from "@/views/contractManagement/contractOverview.vue";
import templateNavigation from "@/views/contractManagement/templateNavigation.vue";
import executionTemplate from "@/views/contractManagement/executionTemplate.vue";
import executionTableTemplate from "@/views/contractManagement/executionTableTemplate.vue";

const routes = [
  {
    path: "/contract/:section/:id",
    name: "contractManagementOverview",
    component: contractManagementOverview,
    props: true,
  },
  {
    path: "/contractManagement",
    name: "contractManagementTable",
    component: contractManagementTable,
  },
  {
    path: "/settings/template-field",
    name: "templateNavigation",
    component: templateNavigation,
  },
  {
    path: "/settings/execution-template-edit",
    name: "executionTemplate",
    component: executionTemplate,
  },
  {
    path: "/settings/execution-template",
    name: "executionTableTemplate",
    component: executionTableTemplate,
  },
  {
    path: "/sampleForm",
    name: "sampleForm",
    component: sampleForm,
  },
  // Form renderer
  {
    path: "/forms/:id",
    name: "formRendererView",
    component: formRendererView,
  },
  {
    path: "/forms/preview",
    name: "formRendererPreviewView",
    component: formRendererPreviewView,
  },
  {
    path: "/form",
    name: "formRendererTestView",
    component: formRendererTestView,
  },
  {
    path: "/settings/navigation",
    name: "emailTriggersNavigation",
    component: emailTriggersNavigation,
  },
  {
    path: "/settings/emailTriggersEdit",
    name: "emailTriggersEdit",
    component: emailTriggersEdit,
  },
  {
    path: "/settings/assigmentRuleEdit",
    name: "assigmentRuleEdit",
    component: assigmentRuleEdit,
  },
  {
    path: "/settings/emailTriggers",
    name: "emailTriggers",
    component: emailTriggers,
  },
  {
    path: "/settings/assignmentRule",
    name: "assigmentRuleTable",
    component: assigmentRuleTable,
  },
  {
    path: "/settings/users",
    name: "userView",
    component: organisationUserHierarchy,
  },
  {
    path: "/resetPassword/:id",
    name: "resetPassword",
    component: resetPassword,
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: resetPassword,
  },
  // {
  //   path: "/servicequalification/region",
  //   name: "regionOverview",
  //   component: regionOverview,
  // },
  {
    path: "/servicequalification/region",
    name: "regionOverviewTest",
    component: regionOverviewTest,
  },
  {
    path: "/MDU/dashboard",
    name: "Multi dwelling unit",
    component: mduDashboard,
  },
  {
    path: "/fieldRenderer",
    name: "customFieldsRendering",
    component: customFieldsRendering,
  },
  {
    path: "/bulkPatcherlanguage",
    name: "bulkPatcher",
    component: bulkPatcher,
  },
  {
    path: "/settings/staticData",
    name: "staticDataView",
    component: staticDataView,
  },
  {
    path: "/settings/staticDataControl",
    name: "staticDataControl",
    component: staticDataControl,
  },
  {
    path: "/form/:modlule",
    name: "leadForm",
    component: formBuilder,
  },
  {
    path: "/MDU/lead/view/contact",
    name: "leadContact",
    component: leadContact,
  },
  {
    path: "/MDU/lead/:type/:leadName/:id",
    name: "leadOverview",
    component: leadOverview,
  },
  {
    path: "/MDU/lead",
    name: "leadMainTable",
    component: LeadMainTable,
  },
  {
    path: "/MDU/lead/import",
    name: "leadImportView",
    component: LeadImportView,
  },
  {
    path: "/translate",
    name: "translateT",
    component: translateT,
  },
  {
    path: "/invoice/default",
    name: "invoiceTemplate",
    component: invoiceTemplate,
  },
  {
    path: "/invoice/dashboard",
    name: "Billing",
    component: invoiceView,
  },
  {
    path: "/invoice/previewpage",
    name: "previewInvoicepage",
    component: previewInvoicepage,
  },
  {
    path: "/UPC/Quotes/:fileName",
    name: "pdfviewer",
    component: pdfviewer,
  },
  {
    path: "/settings/ticket/template",
    name: "ticketTemplateView",
    component: ticketTemplateView,
  },
  {
    path: "/chartMap3",
    name: "chartMap3",
    component: chartMap3,
  },
  {
    path: "/settings/automation/quoting",
    name: "quotingAutomation",
    component: quotingAutomation,
  },
  {
    path: "/accordianTable",
    name: "accordianTable",
    component: accordianTable,
  },
  {
    path: "/settings/automation/quoting/defaultApproval",
    name: "defaultApproval",
    component: defaultApproval,
  },
  {
    path: "/settings/ticket/editTemplate",
    name: "editTemplateView",
    component: editTemplateView,
  },
  {
    path: "/settings/ticket/fields",
    name: "ticketFieldView",
    component: ticketFieldView,
  },
  {
    path: "/settings/automation",
    name: "automationView",
    component: automationView,
  },
  // {
  //   path: '/settings/custom-fields',
  //   name: 'customFieldsView',
  //   component: customFieldsView
  // },
  {
    path: "/settings/custom-field",
    name: "customFieldsView",
    component: customFieldsView,
  },
  {
    path: "/settings/custom-field/definition/:module/:name",
    name: "customFieldsDefinitionView",
    component: customFieldsDefinitionView,
  },
  {
    path: "/settings/automation/productoffering",
    name: "automationViewForProductOffering",
    component: automationViewForProductOffering,
  },
  {
    path: "/settings/automation/dependentProductsTable",
    name: "dependentProductsTable",
    component: dependentProductsTable,
  },
  {
    path: "/settings/ticket",
    name: "Ticket management",
    component: ticketView,
  },
  {
    path: "/settings/tax",
    name: "taxView",
    component: taxView,
  },
  {
    path: "/settings/idsettings",
    name: "idSettings",
    component: idSettings,
  },
  {
    path: "/settings/emailTemplate",
    name: "emailTemplateView",
    component: emailTemplateView,
  },
  {
    path: "/settings/emailTemplate/create/:id",
    name: "createEmailTemplate",
    component: createEmailTemplate,
  },
  {
    path: "/settings/emailTemplate/create",
    name: "createEmailTemplate",
    component: createEmailTemplate,
  },
  {
    path: "/settings/emailTemplate/editor/:id",
    name: "htmlEmailTemplate",
    component: htmlEmailTemplate,
  },
  {
    path: "/settings/emailTemplate/editor",
    name: "htmlEmailTemplate",
    component: htmlEmailTemplate,
  },

  // @import template

  {
    path: "/settings/importTemplate",
    name: "importTemplateNavigationView",
    component: importTemplateNavigationView,
  },
  {
    path: "/settings/importTemplate/definition/:module",
    name: "importTemplateDefinitionView",
    component: importTemplateDefinitionView,
  },

  // @import template

  // @forms template

  {
    path: "/settings/forms",
    name: "formTemplateNavigationView",
    component: formTemplateNavigationView,
  },
  {
    path: "/settings/forms/definition/:module",
    name: "formTemplateDefinitionView",
    component: formTemplateDefinitionView,
  },
  {
    path: "/settings/forms/definition/:module/edit/:id",
    name: "editFormView",
    component: editFormView,
  },
  {
    path: "/settings/forms/definition/:module/view/:id",
    name: "viewFormView",
    component: viewFormView,
  },
  {
    path: "/settings/forms/definition/:module/add",
    name: "addFormView",
    component: addFormView,
  },

  // @forms template

  /** @Order management routes start */
  // @Product Order
  {
    path: "/Inventory/dashboard",
    name: "inventoryDashboardIllustration",
    component: inventoryDashboardIllustration,
  },
  {
    path: "/ticket",
    name: "ticket Table View",
    component: ticketTableView,
  },
  {
    path: "/ticket/chatApp",
    name: "chatApp",
    component: chatApp,
  },
  {
    path: "/Billing/dashboard",
    name: "placeholderTableComponent",
    component: placeholderTableComponent,
  },
  {
    path: "/UPC/Quotes/dashboard",
    name: "Quotes",
    component: quoteDashboardIllustration,
  },
  {
    path: "/UPC/product-order/dashboard",
    name: "Orders",
    component: orderDashboardIllustration,
  },
  {
    path: "/UPC/equipment-order",
    name: "Equiment orders",
    component: equipmentOrderLandingTable,
  },
  {
    path: "/UPC/equipment-order/overview",
    name: "EquimentOrderOverview",
    component: equipmentOrderOverview,
  },
  {
    path: "/UPC/product-order",
    name: "orderLandingPage",
    component: orderLandingPage,
  },
  // @Product order Overview
  {
    path: "/UPC/product-order/overview/:orderId",
    name: "orderOverviewPage",
    component: orderOverviewPage,
  },
  // @Service Order
  {
    path: "/UPC/service-order",
    name: "serviceOrderLandingPage",
    component: serviceOrderLandingPage,
  },
  // @Service order Overview
  {
    path: "/UPC/service-order/overview/:orderId",
    name: "serviceOrderOverviewPage",
    component: serviceOrderOverviewPage,
  },
  // @Resource Order
  {
    path: "/UPC/resource-order",
    name: "resourceOrderLandingPage",
    component: resourceOrderLandingPage,
  },
  // @Resource order Overview
  {
    path: "/UPC/resource-order/overview/:orderId",
    name: "resourceOrderOverviewPage",
    component: resourceOrderOverviewPage,
  },

  /** @End of order management */

  // smSupplier route
  {
    path: "/SM/Overview",
    name: "smSupplierOverview",
    component: smSupplierOverview,
  },
  {
    path: "/email",
    name: "email",
    component: email,
  },
  {
    path: "/serviceaddress",
    name: "serviceaddress",
    component: serviceaddress,
  },
  {
    path: "/Areaofintrest",
    name: "Areaofintrest",
    component: MapAreaofintrest,
  },
  {
    path: "/placemap",
    name: "placeMap",
    component: placeMap,
  },
  {
    path: "/mapdrag",
    name: "mapdrag",
    component: mapdrag,
  },
  {
    path: "/MapPoint",
    name: "MapPoint",
    component: MapPoint,
  },
  {
    path: "/MapCircle",
    name: "MapCircle",
    component: MapCircle,
  },
  {
    path: "/sqNew",
    name: "Service qualification",
    component: serviceQualification2,
  },
  {
    path: "/servicequalification1",
    name: "sqNew",
    component: sqNew,
  },
  {
    path: "/servicequalification",
    name: "sqNewTest",
    component: sqNewTest,
  },

  {
    path: "/availableOffer",
    name: "availableOffer",
    component: availableOffer,
  },
  {
    path: "/UPC/product-catalog-table",
    name: "productCatalogDashboard",
    component: productCatalogDashboard,
  },
  {
    path: "/UPC/product-catalog-table/product-discount",
    name: "productDiscountView",
    component: productDiscountView,
  },
  {
    path: "/graphMap",
    name: "graphMap",
    component: graphMap,
  },
  {
    path: "/selfserve",
    name: "LoginScreen",
    component: LoginScreen,
  },
  {
    path: "/selfserve/customerdashboard",
    name: "CustomerDashboard",
    component: CustomerDashboard,
  },
  {
    path: "/selfserve/myservice",
    name: "MyService",
    component: MyService,
  },
  {
    path: "/UPC/product-catalog-table/additional-charges",
    name: "AdditionalCharges",
    component: AdditionalCharges,
  },
  {
    path: "/SM/PurchaseOrder",
    name: "smSupplierpurchaseOrderView",
    component: smSupplierpurchaseOrderView,
  },
  {
    path: "/SM/Product",
    name: "smSupplierproductView",
    component: smSupplierproductView,
  },
  {
    path: "/SM/Location",
    name: "smSupplierlocationView",
    component: smSupplierlocationView,
  },
  {
    path: "/SM/Contacts",
    name: "smSuppliercontactView",
    component: smSuppliercontactView,
  },

  {
    path: "/SM/Discount",
    name: "smSupplierdiscountView",
    component: smSupplierdiscountView,
  },

  {
    path: "/SM/Contracts",
    name: "smSuppliercontractView",
    component: smSuppliercontractView,
  },
  {
    path: "/SM/Activitylog",
    name: "smSupplieractivitylogView",
    component: smSupplieractivitylogView,
  },

  // @testing
  {
    path: "/test/pricingTable",
    name: "pricingTable",
    component: pricingTable,
  },
  {
    path: "/test/dynamicloader",
    name: "dynamicloader",
    component: dynamicLoader,
  },
  /** ****************************************************@Quote management router start  ********************************************************/
  // @Quote default
  {
    path: "/UPC/Quotes/default",
    name: "CPQDefaultTemplate",
    component: CPQDefaultTemplate,
  },
  // {
  //   path: "/Quotes/edit",
  //   name: "QuoteEdit",
  //   component: CPQDefaultTemplate,
  // },
  // @Quote table
  {
    path: "/UPC/Quotes",
    name: "QuoteTable",
    component: QuoteTable,
  },
  {
    path: "/UPC/Quotes/version",
    name: "quoteHistoryPage",
    component: quoteHistoryPage,
  },
  {
    path: "/UPC/Quotes/preview",
    name: "previewTemplate",
    component: previewTemplate,
  },
  {
    path: "/UPC/Quotes/signerPreview",
    name: "signerPreview",
    component: signerPreview,
  },
  {
    path: "/UPC/Quotes/assistant",
    name: "quoteAssistant",
    component: quoteAssistant,
  },
  /** *********************************************************@Quote management router end ************************************************************/
  // @offering overview
  {
    path: "/UPC/product-catalog-table/offering",
    name: "offeringview",
    component: offeringview,
  },
  // Offering assistant
  {
    path: "/UPC/product-catalog-table/offering/assistant",
    name: "offeringAssistant",
    component: offeringAssistant,
  },
  {
    path: "/UPC/product-catalog-table/offering/:name/:id",
    name: "productOfferingOverview",
    component: productOfferingOverview,
  },
  {
    path: "/UPC/product-catalog-table/offering/:id/configuration-preview",
    name: "productOfferingPreview",
    component: productOfferingPreview,
  },
  {
    path: "/UPC/product-catalog-table/offering/configuration-dep-preview/:id/:depId",
    name: "productOfferingDepFLowPreview",
    component: productOfferingDepFLowPreview,
  },
  {
    path: "/PCM/testcategorytable",
    name: "categoryTestView",
    component: categoryTestView,
  },
  {
    path: "/templateBuilder",
    name: "TemplateBuilder",
    component: TemplateBuilder,
  },
  // {
  //   path: "/quote",
  //   name: "quoteTable",
  //   component: quoteTable,
  // },
  {
    path: "/emailview",
    name: "emailview",
    component: emailview,
  },
  {
    path: "/emailview",
    name: "emailview",
    component: emailview,
  },
  {
    path: "/demo",
    name: "demo",
    component: demo,
  },
  {
    path: "/UPC/product-catalog-table/productspecification/overview/:company/:id/:view",
    name: "overviewSpecification",
    component: overviewSpecification,
  },
  {
    path: "/UPC/product-catalog-table/servicespecification/overview/:company/:id/:view",
    name: "serviceEdit",
    component: serviceEdit,
  },
  {
    path: "/UPC/product-catalog-table/resourcespecification/overview/:company/:id/:view",
    name: "resourceEdit",
    component: resourceEdit,
  },
  {
    path: "/PCM/categorytable",
    name: "categoryTableView",
    component: categoryTableView,
  },
  {
    path: "/PCM/categorytable/activity",
    name: "categoryActivity",
    component: categoryActivity,
  },
  {
    path: "/test",
    name: "testConfigView",
    component: configTest,
  },
  {
    path: "/test-config",
    name: "testConfigView",
    component: configTest2,
  },
  {
    path: "/test-config",
    name: "testConfigView",
    component: configTest2,
  },
  {
    path: "/test-cart-config",
    name: "configTest4",
    component: configTest4,
  },
  {
    path: "/test-config2",
    name: "testConfigView2",
    component: configTest3,
  },
  {
    path: "/test-bpmn/:id",
    name: "bpmn",
    component: bpmn,
  },
  {
    path: "/SM/supplier/edit/:companyName/:id",
    name: "smSupplierEditSupplier",
    component: smSupplierEditSupplier,
  },
  {
    path: "/SM/supplier",
    name: "SM",
    component: smSupplierLandingPAge,
  },
  {
    path: "/CM/residential-customers",
    name: "cmResContactsLandingPage",
    component: cmResContactsLandingPage,
  },
  {
    path: "/CM/contacts",
    name: "cmContactsLandingPage",
    component: cmContactsLandingPage,
  },
  {
    path: "/CM/accounts",
    name: "cmAccountsLandingPage",
    component: cmAccountsLandingPage,
  },
  {
    path: "/CM/accounts/add",
    name: "addNewAccountPage",
    component: addNewAccountPage,
  },
  {
    path: "/CM/accounts/view/:companyName/:id/:type/:from",
    name: "viewAccountPage",
    component: viewAccountPage,
  },
  {
    path: "/CM/contacts/view/:name/:id/:type/:from",
    name: "viewContactPage",
    component: viewContactPage,
  },
  {
    path: "/CM/residential-customers/view/:name/:id/:type/:from",
    name: "viewResContactPage",
    component: viewResContactPage,
  },
  {
    path: "/CM/accounts/edit/:companyName/:id/:type",
    name: "editAccountPage",
    component: editAccountPage,
  },
  {
    path: "/CM/dashboard",
    name: "CM",
    component: cmDashboard,
  },
  {
    path: "/UPC/dashboard",
    name: "UPC",
    component: upcDashboard,
  },
  {
    path: "/UPC/product-catalog-table/specification",
    name: "productCatalogTable",
    component: productCatalogTable,
  },
  {
    path: "/UPC/product-catalog-table/resource",
    name: "resourceCatalogTable",
    component: resourceCatalogTable,
  },
  {
    path: "/UPC/product-catalog-table/service",
    name: "serviceCatalogTable",
    component: serviceCatalogTable,
  },
  {
    path: "/test-matrix-0511",
    name: "MatrixView",
    component: matrix,
  },
  {
    path: "/test-grid-0511",
    name: "GridView",
    component: grid,
  },
  {
    path: "/invite/:token",
    name: "InviteUser",
    component: confirmView,
  },
  {
    path: "/supplierinvite/:companyName/:id/:encryptedToken",
    name: "SupplierInviteUser",
    component: SupplierconfirmView,
  },
  {
    path: "/editRole/:id",
    component: editRoleView,
  },
  {
    path: "/DMN/custom_edit/:id",
    component: editVariable,
  },
  {
    path: "/schemas/edit/:id",
    component: editSchema,
  },
  {
    path: "/schemas/view/:id",
    component: viewSchema,
  },
  {
    path: "/DMN/audit",
    component: auditview,
  },
  {
    path: "/DMN/schemas/mongodb",
    component: mongodbaddschemaflow,
  },
  {
    path: "/schemas/local/",
    component: localaddschemaflow,
  },
  {
    path: "/DMN/schemas/selected_schema_flow",
    component: selectschemaflow,
  },
  {
    path: "/schemas",
    name: "schema-table",
    component: schematable,
  },
  {
    path: "/settings/API",
    component: listAPI,
    name: "list-api",
  },
  {
    path: "/settings/API/edit/:id",
    component: editAPI,
    name: "edit-api",
  },
  // {
  //   path: '/DMN/API/view/:id',
  //   component: viewAPI,
  //   name: 'view-api'
  // },
  {
    path: "/settings/API/add",
    component: addAPI,
    name: "add-api",
  },
  {
    path: "/settings/API/add",
    component: addAPI2,
    name: "add-api2",
  },
  {
    path: "/settings/email",
    component: listEmail,
    name: "list-email",
  },
  {
    path: "/settings/email/edit/:id",
    component: editEmail,
    name: "edit-email",
  },
  {
    path: "/settings/email/add",
    component: addEmail,
    name: "add-email",
  },
  {
    path: "/reference_data/edit/:schema",
    component: addeditreferencedata,
  },
  {
    path: "/reference_data",
    component: showallreference,
  },
  {
    path: "/DMN/rule_validation",
    name: "execute-data-table",
    component: dataExecuteTableView,
  },
  {
    path: "/DMN/rule_validation/edit",
    name: "edit-execute-data",
    component: editExecuteDataView,
    props: true,
  },
  {
    path: "/DMN/rule_validation/view",
    name: "view-execute-data",
    component: viewExecuteDataView,
    props: true,
  },
  {
    path: "/DMN/rule_validation/execute",
    name: "execute-data",
    component: dataExecuteView,
    props: true,
  },
  {
    path: "/DMN/rule_validation/add/manual",
    name: "execute-add-manual",
    component: addManualDataView,
  },
  {
    path: "/DMN/rule_validation/add/api",
    name: "execute-add-api",
    component: addAPIDataView,
  },
  {
    path: "/DMN/rule_validation/add/upload",
    name: "execute-add-upload",
    component: addUploadDataView,
  },
  {
    path: "/DMN/schemas/add",
    name: "schema-add",
    component: addSchemaView,
  },
  {
    path: "/DMN/schemas/custom-variable/add",
    name: "schema-custom-variable",
    component: addCustomVariable,
  },
  {
    path: "/DMN/rule_studio",
    name: "business-rules",
    component: businessRulesView,
    props: (route) => ({ value: route.query.Value }),
  },
  {
    path: "/DMN/rule_studio/decision_table",
    children: [
      {
        path: "add",
        name: "decision-add-v1",
        component: decisionAddView,
      },
      {
        path: "/decisionId/:id",
        name: "decision-view",
        component: decisionView,
      },
      {
        path: "edit/:decisionId",
        name: "decision-edit-v1",
        component: decisionEditViewV1,
      },
    ],
  },
  {
    path: "/DMN/rule_studio/expression",
    name: "expression-add",
    component: addExpressionViewV3,
  },
  {
    path: "/DMN/rule_studio/expression/:id",
    name: "expression-edit",
    component: editExpressionViewV3,
  },
  {
    path: "/DMN/rule_studio/expression/:id/view",
    name: "expression-view",
    component: ViewExpressionViewV3,
  },
  {
    path: "/DMN/rule_studio/logical_rule",
    name: "rule-add",
    component: addRuleViewV3,
  },
  {
    path: "/DMN/rule_studio/advanced_logical_rule",
    name: "advanced-rule-add",
    component: addAdvancedLogicalRuleView,
  },
  {
    path: "/DMN/rule_studio/advanced_logical_rule/:id/view",
    name: "advanced-rule-view",
    component: viewAdvancedLogicalRuleView,
  },
  {
    path: "/DMN/rule_studio/advanced_logical_rule/:id/edit",
    name: "advanced-rule-edit",
    component: editAdvancedLogicalRuleView,
  },
  {
    path: "/DMN/rule_studio/logical_rule/:id",
    name: "rule-edit",
    component: editRuleViewV3,
  },
  {
    path: "/DMN/rule_studio/logical_rule/:id/view",
    name: "rule-view",
    component: ViewRuleViewV3,
  },
  {
    path: "/DMN/rule_studio/rule/execute/:id",
    name: "rule-execute",
    component: executeRuleView,
  },
  {
    path: "/DMN/business_rules/depTree/:id/:name",
    name: "dep-tree-view",
    component: depTreeView,
  },
  {
    path: "/DMN/schemas/depTree/:node",
    name: "schema-dep-tree-view",
    component: schemaDepTreeView,
  },
  {
    path: "/settings/defaultSetting",
    name: "defaultSetting",
    component: defaultSetting,
  },
  {
    path: "/settings/tenant/addTenant",
    name: "addTenantView",
    component: addTenantView,
  },
  {
    path: "/test",
    name: "testView",
    component: testView,
  },
  {
    path: "/settings/tenant",
    name: "tenantView",
    component: tenantView,
  },
  // {
  //   path: '/settings/tenantTable',
  //   name: 'tenantTableView',
  //   component: tenantTableView,
  // },
  // {
  //   path: "/settings/users",
  //   name: "userView",
  //   component: userView,
  // },
  {
    path: "/settings/users/edit",
    name: "additionalFieldsUserView",
    component: additionalFieldsUserView,
  },
  // {
  //   path: "/settings/users/edit",
  //   name: "additionalFieldsUserView",
  //   component: additionalFieldsUserView,
  // },
  {
    path: "/settings/bill",
    name: "billView",
    component: billView,
  },

  {
    path: "/settings/loginDetails",
    name: "loginDetails",
    component: loginDetails,
  },
  {
    path: "/apiUsage",
    name: "apiView",
    component: apiView,
  },
  {
    path: "/apiUsage",
    name: "apiView",
    component: apiView,
  },
  {
    path: "/settings/organization",
    name: "organizationView",
    component: organizationView,
  },
  {
    path: "/settings/addRoles",
    name: "addRoleView",
    component: addView,
  },
  {
    path: "/settings/roles",
    name: "roleView",
    component: roleView,
  },
  {
    path: "/payment",
    name: "paymentView",
    component: paymentView,
    props: (route) => ({
      user: { a: 1 },
      ...route.params,
    }),
  },
  {
    path: "/planView",
    name: "planView",
    component: planView,
  },
  {
    path: "/accountView",
    name: "accountView",
    component: accountView,
  },

  {
    path: "/DMN/dashboard",
    name: "DMN",
    component: dashboardView,
  },
  {
    path: "/DMN/domain_switcher/:token",
    name: "dashboaard",
    component: domainManager,
  },
  // ,{
  //   path: '/DMN/dashboard/:token',
  //   name: 'dashboard',
  //   component: dashboardView,
  // },
  {
    path: "/settings",
    name: "setting",
    component: settingView,
  },
  {
    path: "/signup",
    name: "signUp",
    component: signupView,
  },
  {
    path: "/",
    name: "signIn",
    component: signinView,
  },
  {
    path: "/",
    name: "user",
    component: userView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("🚀 ~ router.beforeEach ~ to:", to);
  // console.log("🚀 ~ router.beforeEach ~ next:", next)
  console.log("🚀 ~ router.beforeEach ~ from:", from);
  // window.scrollTo(0, 0);
  // console.log(
  //   "🚀 ~ router.beforeEach ~ VueCookies.get(token):",
  //   VueCookies.get("token")
  // );
  // Check for login

  // next()
  if (
    to &&
    [
      "signUp",
      "signIn",
      "InviteUser",
      "SupplierInviteUser",
      "pdfviewer",
      "formRendererPreviewView",
      "formRendererTestView",
      "formRendererView",
    ].includes(to.name)
  ) {
    store.state.isSidebar = false;
    next();
  }

  if (
    to &&
    ![
      "signUp",
      "signIn",
      "InviteUser",
      "SupplierInviteUser",
      "pdfviewer",
      "formRendererPreviewView",
      "formRendererTestView",
      "formRendererView",
    ].includes(to.name)
  ) {
    if (to.name == "sampleForm" && to.query.access) {
      // VueCookies.set("token", to.query.access);
      next();
    }
    if (to.name == "previewTemplate" && to.query.access) {
      VueCookies.set("token", to.query.access);
      // console.log(
      //   "🚀 ~ router.beforeEach ~ VueCookies.get(token):",
      //   VueCookies.get("token")
      // );
      next();
    } else if (from.name === undefined && from.path === "/") {
      //  &&
      // to.name === "previewTemplate" &&
      // to.query &&
      // to.query.requestFor &&
      // to.query.requestFor === "approval"
      if (VueCookies.get("token")) {
        next();
      } else {
        next({
          name: "signIn",
          redirect: to.fullPath,
        });
      }
    } else if (VueCookies.get("token")) {
      // console.log(
      //   "🚀 ~ router.beforeEach ~ VueCookies.get(token):",
      //   VueCookies.get("token")
      // );
      next();
      //  return;
    } else {
      console.log("🚀 ~ router.beforeEach ~ to: in else", to);
      next({
        name: "signIn",
        query: { redirect: to.fullPath },
      });
      console.log("🚀 ~ router.beforeEach ~ to: in else", to);
      return;
    }
    //  return
  }
  //
  let currentPath = to.path.split(/DMN\/(\w+)/gim)[1];
  if (to.path && to.path.split("/")[1] === "settings") {
    currentPath = to.path.split("/")[1];
  }
  store.state.sidebarData = store.state.sidebarData.map((item) => {
    if (
      item.path &&
      currentPath &&
      item.path.toLowerCase() === currentPath.toLowerCase()
    ) {
      item.active = true;
    } else {
      item.active = false;
    }
    return item;
  });
});

export default router;
