<template>
  <div v-if="module === 'advance-SuiteRun'" style="padding-bottom: 20px">
    <div style="display: flex; padding: 10px 19px; align-items: center">
      <span
        style="
          /* font-family: Open Sans; */
          font-size: 14px;
          font-weight: 600;
          line-height: 19.07px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        "
        >Suite run</span
      >
      <hlx-input
        v-model:value="suiteRunToggle"
        :checked="suiteRunToggle"
        :type="'switch'"
        @selected="suiteRunToggleVal"
      />
    </div>
    <div v-if="suiteRunToggle === true">
      <div
        style="width: 100%; display: flex; gap: 25px; padding: 19px 19px 0 19px"
      >
        <div style="width: 50%">
          <hlx-input
            v-model:value="numUnits"
            :pre-val="numUnits"
            label-value="Number of Units"
            type="number"
            :clearable="true"
            :label-animation="true"
            :required="true"
            :round-off="true"
            :display-error="true"
            :custom-error="isnumberOfUnit"
            @at-input="numUnitsVal"
          />
        </div>
        <div style="width: 50%">
          <hlx-input
            v-model:value="unitsPerFloor"
            :pre-val="unitsPerFloor"
            label-value="Number of Units per Floor"
            type="number"
            :clearable="true"
            :label-animation="true"
            :round-off="true"
            :required="true"
            :display-error="true"
            :custom-error="isnumberOfUnitPerFloor"
            @at-input="unitsPerFloorVal"
          />
        </div>
      </div>
      <div style="padding-left: 19px; padding-right: 13px; width: 50%">
        <hlx-input
          v-model:value="numFloors"
          :pre-val="numFloors"
          label-value="Number of Floors"
          type="number"
          :clearable="true"
          :label-animation="true"
          :round-off="true"
          :required="true"
          :display-error="true"
          :custom-error="isnumberOfFloor"
          @at-input="numFloorsVal"
        />
      </div>
      <div v-if="error" class="error">
        {{ error }}
      </div>
      <div
        class="download-container"
        @click="
          downloadAttachmnet(
            'SuiterunTemplate (123456789098765432345678909876543234567890987654321234567).xlsx'
          )
        "
      >
        <span class="download-title">Download</span>
        <i class="icon-download-regular"></i>
        <span class="download-subtitle">Suit run template</span>
      </div>

      <!-- File Upload for Excel -->
      <div class="uploadSuiteRun">
        <div><b>Upload suite run</b></div>
        <div>
          <div>
            <div v-if="!isUploaded || error != ''">
              <hlx-button
                class="secondary sm"
                style="display: block"
                @click="openFileInput"
              >
                {{ "Upload" }}
              </hlx-button>
            </div>

            <input
              ref="fileprofile"
              type="file"
              accept=".xlsx, .xls"
              style="display: none"
              @change="handleFileUpload"
            />
          </div>

          <div v-if="isUploaded && error === ''">
            <div class="box-attachment-suitRun">
              <div>
                <span
                  v-if="getFileExtension(fileName) === 'xlsx'"
                  class="upload_component_icon"
                >
                  <img
                    class="file-icon"
                    src="@/assets/svg/multiUpload/excel.svg"
                    alt=""
                  />
                </span>
              </div>
              <div style="display: flex; flex-direction: column">
                <span
                  style="
                    font-weight: 400;
                    font-size: 12px;
                    white-space: nowrap;
                    width: 146px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ fileName }}
                </span>
              </div>
              <span
                class="closeIconechat"
                style="margin-left: -7px"
                @click="downloadAttachment(box, index)"
                ><i style="color: #6e6b7b" class="icon-download-regular"></i
              ></span>

              <span>
                <i class="icon-trash-regular" @click="deleteFilename"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="unitData.length > 0" class="suteDATA-table-container">
        <div class="suteDATA-table-wrapper">
          <table class="suteDATA-table">
            <thead>
              <tr class="suteDATA-table-header">
                <th class="suteDATA-sticky-column">Floor Number</th>
                <th
                  v-for="i in unitsPerFloor"
                  :key="i"
                  class="suteDATA-unit-column"
                >
                  Unit number {{ i }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(floor, rowIndex) in unitData"
                :key="rowIndex"
                class="suteDATA-table-row"
              >
                <td class="suteDATA-sticky-column">
                  {{
                    capitalizeFirstLetter(floor.floor) ||
                    `Floor ${rowIndex + 1}`
                  }}
                </td>
                <td
                  v-for="(unit, colIndex) in floor.units"
                  :key="colIndex"
                  class="suteDATA-unit-cell"
                >
                  <div style="width: 120px">
                    <hlx-input
                      :pre-val="unit"
                      type="text"
                      required="false"
                      @at-input="updateUnitValue(rowIndex, colIndex, $event)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div v-if="module === 'Basic-SuiteRun'" style="width: 97%">
    <div
      style="
        display: flex;
        /* padding: 10px 3px; */
        margin-bottom: 25px;
        align-items: center;
      "
    >
      <span
        style="
          /* font-family: Open Sans; */
          font-size: 14px;
          font-weight: 600;
          line-height: 19.07px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        "
        >Suite run</span
      >
      <hlx-input
        v-model:value="suiteRunToggle"
        :checked="suiteRunToggle"
        :type="'switch'"
        @selected="suiteRunToggleVal"
      />
    </div>
    <div v-if="suiteRunToggle === true">
      <div>
        <div>
          <hlx-input
            v-model:value="numUnits"
            :pre-val="numUnits"
            read-only="true"
            label-value="Number of Units"
            type="number"
            :clearable="true"
            :label-animation="true"
            :required="true"
            :round-off="true"
            :display-error="true"
            :custom-error="isnumberOfUnit"
            @at-input="numUnitsVal"
          />
        </div>
        <div>
          <hlx-input
            v-model:value="unitsPerFloor"
            :pre-val="unitsPerFloor"
            label-value="Number of Units per Floor"
            type="number"
            read-only="true"
            :clearable="true"
            :label-animation="true"
            :round-off="true"
            :required="true"
            :display-error="true"
            :custom-error="isnumberOfUnitPerFloor"
            @at-input="unitsPerFloorVal"
          />
        </div>
      </div>
      <div>
        <hlx-input
          v-model:value="numFloors"
          :pre-val="numFloors"
          label-value="Number of Floors"
          type="number"
          read-only="true"
          :clearable="true"
          :label-animation="true"
          :round-off="true"
          :required="true"
          :display-error="true"
          :custom-error="isnumberOfFloor"
          @at-input="numFloorsVal"
        />
      </div>
      <div v-if="error" class="error">
        {{ error }}
      </div>
      <div
        class="download-container"
        style="padding: 0 19px 28px 4px !important"
        @click="
          downloadAttachmnet(
            'SuiterunTemplate (123456789098765432345678909876543234567890987654321234567).xlsx'
          )
        "
      >
        <span class="download-title">Download</span>
        <i class="icon-download-regular"></i>
        <span class="download-subtitle">Suit run template</span>
      </div>

      <!-- File Upload for Excel -->
      <div class="uploadSuiteRun" style="padding: 0 !important">
        <div style="width: max-content"><b>Upload suite run</b></div>
        <div>
          <div>
            <div v-if="!isUploaded || error != ''">
              <hlx-button
                class="secondary sm"
                style="display: block"
                @click="openFileInput"
              >
                {{ "Upload" }}
              </hlx-button>
            </div>

            <input
              ref="fileprofile"
              type="file"
              accept=".xlsx, .xls"
              style="display: none"
              @change="handleFileUpload"
            />
          </div>

          <div v-if="isUploaded && error === ''">
            <div class="box-attachment-suitRun">
              <div>
                <span
                  v-if="getFileExtension(fileName) === 'xlsx'"
                  class="upload_component_icon"
                >
                  <img
                    class="file-icon"
                    src="@/assets/svg/multiUpload/excel.svg"
                    alt=""
                  />
                </span>
              </div>
              <div style="display: flex; flex-direction: column">
                <span
                  style="
                    font-weight: 400;
                    font-size: 12px;
                    white-space: nowrap;
                    width: 146px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ fileName }}
                </span>
              </div>
              <span
                class="closeIconechat"
                style="margin-left: -7px"
                @click="downloadAttachment(box, index)"
                ><i style="color: #6e6b7b" class="icon-download-regular"></i
              ></span>

              <span>
                <i class="icon-trash-regular" @click="deleteFilename"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="unitData.length > 0" class="suteDATA-table-container">
        <div class="suteDATA-table-wrapper">
          <table class="suteDATA-table">
            <thead>
              <tr class="suteDATA-table-header">
                <th class="suteDATA-sticky-column">Floor Number</th>
                <th
                  v-for="i in unitsPerFloor"
                  :key="i"
                  class="suteDATA-unit-column"
                >
                  Unit number {{ i }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(floor, rowIndex) in unitData"
                :key="rowIndex"
                class="suteDATA-table-row"
              >
                <td class="suteDATA-sticky-column">
                  {{
                    capitalizeFirstLetter(floor.floor) ||
                    `Floor ${rowIndex + 1}`
                  }}
                </td>
                <td
                  v-for="(unit, colIndex) in floor.units"
                  :key="colIndex"
                  class="suteDATA-unit-cell"
                >
                  <div style="width: 120px">
                    <hlx-input
                      :pre-val="unit"
                      type="text"
                      required="false"
                      @at-input="updateUnitValue(rowIndex, colIndex, $event)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // MLTAxiosInstance,
  // DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import * as XLSX from "xlsx";

export default {
  props: {
    module: {
      type: String,
      default: "",
    },
    suiteRunProp: {
      type: Object,
      default: () => {
        return {
          toggle: false,
          numUnits: 0,
          unitsPerFloor: 0,
          numFloors: 0,
          unitData: [],
          fileName: "",
        };
      },
    },
  },
  emits: ["suiteRunEm"],
  data() {
    return {
      numUnits: 0,
      unitsPerFloor: 0,
      numFloors: 0,
      unitData: [], // Consolidated data
      error: "",
      fileName: "",
      isUploaded: false,
      suiteRunToggle: false,
      suitRunEmit: {},
    };
  },
  // computed: {
  //   numFloors
  // },
  watch: {
    suiteRunProp: {
      handler(val) {
        if (val) {
          this.suiteRunToggle = val.toggle;
          this.unitsPerFloor = val.unitsPerFloor;
          this.fileName = val.fileName;
          this.numFloors = val.numFloors;
          this.numUnits = val.numUnits;
          this.unitData = val.unitData;

          if (this.suiteRunProp?.fileName?.length > 0) {
            this.isUploaded = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },

    numUnits: "checkAndGenerateTable",
    unitsPerFloor: "checkAndGenerateTable",
    numFloors: "checkAndGenerateTable",
  },
  methods: {
    async downloadAttachmnet(val) {
      const url = await UPCAxiosInstance.get(`/gcpupload/files/${val}`, {
        responseType: "arraybuffer",
      });

      this.downloadFromGCP(url.data, val);
    },
    async downloadFromGCP(urlFetch, name) {
      console.log(urlFetch, name);
      const type = this.getFileTypeFromMimeType(name);
      const blob = new Blob([urlFetch], { type: type });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "suite run.xlsx"; // Set default file name here
      document.body.appendChild(a);

      a.click();
      document.body.removeChild(a); // Clean up after download
      window.URL.revokeObjectURL(url); // Revoke the object URL
    },
    getFileTypeFromMimeType(mimeType) {
      const mimeTypeMap = {
        "image/jpeg": "JPEG",
        "image/png": "PNG",
        "application/pdf": "PDF",
        "text/plain": "TXT",
      };

      const fileType = mimeTypeMap[mimeType] || "Unknown";

      return fileType !== "Unknown"
        ? `application/${fileType.toLowerCase()}`
        : mimeType;
    },
    suiteRunToggleVal(val) {
      this.suitRunEmit.toggle = val;
      this.suitRunEmit.unitsPerFloor = this.unitsPerFloor;
      this.suitRunEmit.fileName = this.fileName;
      this.suitRunEmit.numFloors = this.numFloors;
      this.suitRunEmit.numUnits = this.numUnits;
      this.suitRunEmit.unitData = this.unitData;

      this.$emit("suiteRunEm", this.suitRunEmit);
    },
    numUnitsVal(val) {
      this.suitRunEmit.toggle = this.suiteRunToggle;
      this.suitRunEmit.unitsPerFloor = this.unitsPerFloor;
      this.suitRunEmit.fileName = this.fileName;
      this.suitRunEmit.numFloors = this.numFloors;
      this.suitRunEmit.numUnits = val;
      this.suitRunEmit.unitData = this.unitData;
      this.$emit("suiteRunEm", this.suitRunEmit);
    },
    unitsPerFloorVal(val) {
      this.suitRunEmit.toggle = this.suiteRunToggle;
      this.suitRunEmit.unitsPerFloor = val;
      this.suitRunEmit.fileName = this.fileName;
      this.suitRunEmit.numFloors = this.numFloors;
      this.suitRunEmit.numUnits = this.numUnits;
      this.suitRunEmit.unitData = this.unitData;
      this.$emit("suiteRunEm", this.suitRunEmit);
    },
    numFloorsVal(val) {
      this.suitRunEmit.toggle = this.suiteRunToggle;
      this.suitRunEmit.unitsPerFloor = this.unitsPerFloor;
      this.suitRunEmit.fileName = this.fileName;
      this.suitRunEmit.numFloors = val;
      this.suitRunEmit.numUnits = this.numUnits;
      this.suitRunEmit.unitData = this.unitData;
      this.$emit("suiteRunEm", this.suitRunEmit);
    },
    checkAndGenerateTable() {
      if (
        this.numUnits > 0 &&
        this.unitsPerFloor > 0 &&
        this.unitsPerFloor > 0
      ) {
        this.generateTable();
      } else {
        this.unitData = []; // Reset the table if inputs are invalid
        this.error =
          "Please ensure all inputs are valid before generating the table.";
      }
    },
    deleteFilename() {
      this.fileName = "";
      this.isUploaded = false;
    },
    getFileExtension(filename) {
      const parts = filename.split(".");
      const fileExtension = parts[parts.length - 1];
      return fileExtension;
    },
    openFileInput() {
      this.$refs.fileprofile.click();
    },
    // Manual table generation
    generateTable() {
      this.error = "";

      const totalUnits = this.numFloors * this.unitsPerFloor;
      if (this.numUnits > totalUnits) {
        this.error =
          "The total number of units cannot exceed Units per Floor × Number of Floors.";
        return;
      }

      // Extend or shrink the unitData array based on the number of floors
      if (this.unitData.length < this.numFloors) {
        // Add new floors with empty units
        for (let i = this.unitData.length; i < this.numFloors; i++) {
          this.unitData.push({
            floor: `floor ${i + 1}`,
            units: Array(this.unitsPerFloor).fill(""), // Create empty columns
          });
        }
      } else if (this.unitData.length > this.numFloors) {
        // Trim excess floors
        this.unitData = this.unitData.slice(0, this.numFloors);
      }

      // Adjust each floor's units array based on the number of units per floor
      this.unitData = this.unitData.map((floorData, floorIndex) => {
        const newUnits = [...floorData.units]; // Clone existing units

        if (newUnits.length < this.unitsPerFloor) {
          // Add empty slots for new columns
          for (let i = newUnits.length; i < this.unitsPerFloor; i++) {
            newUnits.push("");
          }
        } else if (newUnits.length > this.unitsPerFloor) {
          // Trim excess columns
          newUnits.length = this.unitsPerFloor;
        }

        // Return updated floor data
        return {
          floor: floorData.floor || `floor ${floorIndex + 1}`,
          units: newUnits,
        };
      });

      this.suitRunEmit.toggle = this.suiteRunToggle;
      this.suitRunEmit.unitsPerFloor = this.unitsPerFloor;
      this.suitRunEmit.fileName = this.fileName;
      this.suitRunEmit.numFloors = this.numFloors;
      this.suitRunEmit.numUnits = this.numUnits;
      this.suitRunEmit.unitData = this.unitData;
      this.$emit("suiteRunEm", this.suitRunEmit);
    },

    handleFileUpload(event) {
      this.error = "";

      const fileInput = event.target; // Reference to the file input
      const file = fileInput.files[0];
      if (!file) {
        this.error = "No file selected.";
        return;
      }

      this.fileName = file.name;
      this.suitRunEmit.toggle = this.suiteRunToggle;
      this.suitRunEmit.unitsPerFloor = this.unitsPerFloor;
      this.suitRunEmit.fileName = this.fileName;
      this.suitRunEmit.numFloors = this.numFloors;
      this.suitRunEmit.numUnits = this.numUnits;
      this.suitRunEmit.unitData = this.unitData;
      this.$emit("suiteRunEm", this.suitRunEmit);
      console.log("Uploaded File Name:", this.fileName);

      const fileType = this.fileName.split(".").pop().toLowerCase();
      if (fileType !== "xlsx" && fileType !== "xls") {
        this.error =
          "Invalid file type. Please upload an Excel file (.xlsx or .xls).";
        // Reset the file input to allow re-upload
        fileInput.value = "";
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.isUploaded = true;
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        this.processExcelData(jsonData);

        // Reset the file input to allow re-upload
        fileInput.value = "";
      };

      reader.readAsArrayBuffer(file);
    },
    // // Handle Excel file upload
    // handleFileUpload(event) {
    //   this.error = "";

    //   const file = event.target.files[0];
    //   if (!file) {
    //     this.error = "No file selected.";
    //     return;
    //   }

    //   // Log or store the uploaded file name
    //   this.fileName = file.name;
    //   console.log("Uploaded File Name:", this.fileName);

    //   // Check if the file is an Excel file (either .xlsx or .xls)
    //   const fileType = this.fileName.split(".").pop().toLowerCase();
    //   if (fileType !== "xlsx" && fileType !== "xls") {
    //     this.error =
    //       "Invalid file type. Please upload an Excel file (.xlsx or .xls).";
    //     return;
    //   }

    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     this.isUploaded = true;
    //     const data = new Uint8Array(e.target.result);
    //     const workbook = XLSX.read(data, { type: "array" });

    //     const sheetName = workbook.SheetNames[0];
    //     const sheet = workbook.Sheets[sheetName];

    //     // Convert sheet to JSON
    //     const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    //     // Validate and process the data
    //     this.processExcelData(jsonData);
    //   };

    //   reader.readAsArrayBuffer(file);
    // },
    // Process and validate the uploaded Excel data
    //     import * as XLSX from "xlsx";

    // handleFileUpload(event) {
    //   const file = event.target.files[0];
    //   const reader = new FileReader();

    //   reader.onload = (e) => {
    //     const binaryStr = e.target.result;
    //     const workbook = XLSX.read(binaryStr, { type: "binary" });

    //     // Assuming the data is in the first sheet
    //     const sheetName = workbook.SheetNames[0];
    //     const sheet = workbook.Sheets[sheetName];
    //     const data = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Convert sheet to a 2D array

    //     // Process the data
    //     this.processExcelData(data);
    //   };

    //   reader.readAsBinaryString(file);
    // },

    processExcelData(data) {
      if (
        data.length === 0 ||
        (!data[0].includes("FloorNumber") && !data[0].includes("Floor Number"))
      ) {
        this.error = "Invalid Excel format. Missing 'Floor Number' header.";
        return;
      }

      const headers = data[0];
      const floorIndex = headers.findIndex(
        (header) => header === "FloorNumber" || header === "Floor Number"
      );

      if (floorIndex === -1) {
        this.error = "Invalid Excel format. 'Floor Number' header is missing.";
        return;
      }

      this.unitData = data.slice(1).map((row) => {
        const completeRow = Array.from(
          { length: headers.length },
          (_, i) => row[i] || ""
        );
        return {
          floor: completeRow[floorIndex],
          units: completeRow.slice(floorIndex + 1),
        };
      });

      this.numFloors = this.unitData.length;
      this.unitsPerFloor = this.unitData[0]?.units.length || 0;
      this.numUnits = this.numFloors * this.unitsPerFloor;

      console.log(this.unitData, "Processed Unit Data");
      console.log(
        this.numFloors,
        this.unitsPerFloor,
        this.numUnits,
        "Derived Values"
      );

      // Emit processed data
      this.suitRunEmit.toggle = this.suiteRunToggle;
      this.suitRunEmit.unitsPerFloor = this.unitsPerFloor;
      this.suitRunEmit.fileName = this.fileName;
      this.suitRunEmit.numFloors = this.numFloors;
      this.suitRunEmit.numUnits = this.numUnits;
      this.suitRunEmit.unitData = this.unitData;

      this.$emit("suiteRunEm", this.suitRunEmit);
    },

    // Update unit value
    updateUnitValue(rowIndex, colIndex, newValue) {
      this.unitData[rowIndex].units[colIndex] = newValue;
      this.suitRunEmit.toggle = this.suiteRunToggle;
      this.suitRunEmit.unitsPerFloor = this.unitsPerFloor;
      this.suitRunEmit.fileName = this.fileName;
      this.suitRunEmit.numFloors = this.numFloors;
      this.suitRunEmit.numUnits = this.numUnits;
      this.suitRunEmit.unitData = this.unitData;
      this.$emit("suiteRunEm", this.suitRunEmit);
    },
  },
};
</script>

<style lang="scss">
.download-container {
  padding: 0 19px 28px 19px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  .download-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: pointer;
    .icon-download-regular {
      color: rgba(1, 87, 159, 1);
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.download-subtitle {
  color: rgba(1, 87, 159, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}

.uploadSuiteRun {
  padding: 0 19px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.box-attachment-suitRun {
  position: relative;
  width: 230px;
  display: flex;
  height: auto;
  background-color: #f2f5f8;
  border-radius: 6px;
  padding: 6px 8px 6px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  .upload_component_icon {
    display: flex;
    width: 16px;
    .file-icon {
      width: 16px;
    }
  }
}
.error {
  color: red;
  margin-top: 10px;
}

.suteDATA-table-container {
  margin-top: 20px;
  margin: 20px; // Add margin around the table
}

// Add horizontal scrolling
.suteDATA-table-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  border: 1px solid #ddd;
}

.suteDATA-table {
  width: 100%;
  border-collapse: collapse;

  .suteDATA-table-header {
    th {
      /* font-family: Open Sans; */
      font-size: 14px;
      font-weight: 400;
      line-height: 19.07px;
      // text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      padding: 10px 16px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      background: rgba(216, 216, 216, 0.2);
      color: rgba(25, 26, 21, 1);
    }
    .suteDATA-sticky-column {
      position: sticky;
      border-right: 1px solid #ddd;
    }
  }

  .suteDATA-table-row {
    td {
      padding: 0 0 0 18px;
      text-align: left;
      border: none;
      /* font-family: Open Sans; */
      font-size: 14px;
      font-weight: 400;
      line-height: 19.07px;
      /* text-align: center; */
      text-underline-position: from-font;
      text-decoration-skip-ink: none; // Remove border for data cells
    }

    .suteDATA-sticky-column {
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 1;
      border-right: 1px solid #ddd;
    }

    .suteDATA-unit-column {
      text-align: center;
    }

    .suteDATA-unit-cell {
      padding: 14px;
      // background-color: #f9f9f9;
    }
  }
}
</style>
