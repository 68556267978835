<template>
  <!-- Notification -->
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <section class="activity">
    <section class="new-right-panel">
      <div class="new-right-panel-parent-container no-filter-panel">
        <div class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <div class="new-layout-basic-no-breadcrumb">
            <div class="new-layout-breadcrumb" style="height: 24px !important">
              <hlx-breadcrumb :items="items" @path="pathTo" />
            </div>
            <div class="new-layout-body">
              <!-- <span
                class="header-utils-and-switch-container"
                style="width: 100%"
              >
                <span
                  style="display: flex; align-items: center; font-weight: bold"
                  >Default settings</span
                >
              </span> -->
              <span class="default-setting-whole-section-name"
                >Location based settings</span
              >
              <section class="holder1">
                <div class="default-setting-whole-container">
                  <section
                    v-for="(i, index) in locationbasedsettings"
                    :key="index"
                    class="default-setting-header-section"
                  >
                    <span class="default-setting-header-section-container">
                      <p class="default-setting-header-section-header">
                        {{ i.header }}
                      </p>
                      <p class="default-setting-header-section-sub-header">
                        {{ i.subheader }}
                      </p>
                    </span>
                  </section>
                </div>
                <div class="default-setting-whole-container1">
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <hlx-select
                        v-model="languageSearchValue"
                        :pre-value="defaultSettings.currentLanguage"
                        :inline-search="true"
                        :options="languagesOptionsTranslation"
                        :placeholder-value="''"
                        :label-animation="false"
                        :label="'name'"
                        :prop-value="'name'"
                        :clearable="true"
                        :required="false"
                        @selected-value="handleSelectedLanguage"
                      >
                      </hlx-select>
                    </span>
                  </section>
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <hlx-select
                        v-model:value="countrySearchValue"
                        :pre-value="defaultSettings.country"
                        :inline-search="true"
                        :options="locationOptions.Country"
                        :placeholder-value="''"
                        :label-animation="false"
                        :custom-search="true"
                        :label="'name'"
                        :prop-value="'name'"
                        :clearable="true"
                        :required="false"
                        @selected-value="handleSelectedCountry"
                      >
                      </hlx-select>
                    </span>
                  </section>
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <hlx-input
                        :pre-val="defaultSettings.currency.unit"
                        :label-animation="false"
                        :read-only="true"
                        label-value=""
                        type="text"
                        :clearable="true"
                      />
                    </span>
                  </section>
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <hlx-select
                        v-model="timeZoneSearchValue"
                        :pre-value="defaultSettings.currentTimezone"
                        :inline-search="true"
                        :options="timeZoneOptions"
                        :placeholder-value="'Timezone'"
                        :label-animation="false"
                        :label="'name'"
                        :prop-value="'name'"
                        :clearable="true"
                        :required="false"
                        @selected-value="handleSelectedTimezone"
                      >
                      </hlx-select>
                    </span>
                  </section>
                </div>
              </section>
              <span class="default-setting-whole-section-name"
                >Formating Based settings</span
              >
              <section class="holder1">
                <div class="default-setting-whole-container">
                  <section
                    v-for="(i, index) in formatingbasedsetting"
                    :key="index"
                    class="default-setting-header-section"
                  >
                    <span class="default-setting-header-section-container">
                      <p class="default-setting-header-section-header">
                        {{ i.header }}
                      </p>
                      <p class="default-setting-header-section-sub-header">
                        {{ i.subheader }}
                      </p>
                    </span>
                  </section>
                </div>
                <div class="default-setting-whole-container1">
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <!-- {{date}} -->
                      <hlx-select
                        v-model:value="defaultSettings.dateFormat"
                        :pre-value="defaultSettings.dateFormat"
                        :options="dateformatOptions"
                        :placeholder-value="''"
                        :label-animation="false"
                        :label="'name'"
                        :prop-value="'value'"
                        :clearable="true"
                        :required="false"
                      >
                        <!-- @custom-change="dateval(date)" -->
                      </hlx-select>
                    </span>
                  </section>
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <hlx-select
                        v-model:value="defaultSettings.paginationSize"
                        :pre-value="defaultSettings.paginationSize"
                        :options="paginationFormatOptions"
                        :placeholder-value="''"
                        :label-animation="false"
                        :label="'name'"
                        :prop-value="'value'"
                        :clearable="true"
                        :required="false"
                      >
                        <!-- @custom-change="checkval" -->
                      </hlx-select>
                    </span>
                  </section>
                  <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <hlx-select
                        v-model:value="defaultSettings.taxAddressType"
                        :pre-value="defaultSettings.taxAddressType"
                        :options="taxAddressTypeOptions"
                        :placeholder-value="''"
                        :label-animation="false"
                        :label="'name'"
                        :prop-value="'value'"
                        :clearable="true"
                        :required="false"
                      >
                        <!-- @custom-change="checkval" -->
                      </hlx-select>
                    </span>
                  </section>
                  <!-- <section class="default-setting-value-section">
                    <span class="default-setting-value-section-container">
                      <hlx-select
                        :pre-value="languagesOptionsTranslation[0]?.name"
                        :options="languagesOptionsTranslation"
                        :placeholder-value="' Language'"
                        :label-animation="false"
                        :label="'name'"
                        :prop-value="'name'"
                        :clearable="true"
                        :required="false"
                        @selected-value="handleSelectedLanguageTranslate"
                      >
                      </hlx-select>
                    </span>
                  </section> -->
                </div>
              </section>

              <span class="default-setting-whole-section-name"
                >Signature Based settings</span
              >
              <span
                style="
                  border: 1px solid black;
                  padding: 2px;
                  width: fit-content;
                  margin-top: 14px;
                "
              >
                <signaturepad
                  :key="updateModalKey"
                  :signer-name="getUserInfo?.data?.company"
                ></signaturepad>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="layout-footer" style="margin-right: 2%">
        <div class="footer-container" style="gap: 2%; margin-right: 2%">
          <hlx-button class="secondary sm" @click="back()">Back</hlx-button>
          <hlx-button
            style="margin-left: 0px !important"
            class="primary sm"
            @click="updateInfo()"
            >Save</hlx-button
          >
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { MLTAxiosInstance } from "@/config/axiosConfig";
import signaturepad from "@/components/UPC/CPQ/signaturePadWholeComponent.vue";
export default {
  name: "ActivityView",
  components: {
    signaturepad,
  },
  emits: ["change"],
  data() {
    return {
      getUserInfo: null,
      showSignaturePad: false,
      languagesOptionsTranslation: [],
      languageSearchValue: "",
      timeZoneSearchValue: "",
      notification: {},
      defaultSettings: {
        flags: {},
        postalCode: {},
        country: "",
        idd: {},
        cca2: "",
        currency: {},
        currentTimezone: "",
        timezones: [],
        region: "",
        subRegion: "",
        languages: { eng: "English" },
        currentLanguage: "English",
        flag: "",
        dateFormat: "MMM DD, YYYY hh:mm AM/PM",
        paginationSize: 10,
        taxAddressType: "billingAddress",
      },
      languagesOptions: [{ name: "English", value: "English" }],
      timeZoneOptions: [],
      countrySearchValue: "canada",
      date: "MMM DD, YYYY hh:mm AM/PM",
      pagination: 10,
      paginationFormatOptions: [
        { name: 5, value: 5 },
        { name: 10, value: 10 },
        { name: 15, value: 15 },
        { name: 20, value: 20 },
      ],
      taxAddressTypeOptions: [
        { name: "Billing Address", value: "billingAddress" },
        { name: "Site Address", value: "siteAddress" },
      ],
      dateformatOptions: [
        { name: "MMM DD, YYYY hh:mm AM/PM", value: "MMM DD, YYYY hh:mm AM/PM" },
        { name: "MM/DD/YYYY", value: "MM/DD/YYYY" },
        { name: "DD MMM, YYYY h:mm AM/PM", value: "DD MMM, YYYY h:mm AM/PM" },

        { name: "ddd, MMM DD, YYYY", value: "ddd, MMM DD, YYYY" },
        { name: "hh:mm:ss AM/PM", value: "hh:mm:ss AM/PM" },
        {
          name: "ddd, MMM DD, YYYY hh:mm AM/PM",
          value: "ddd, MMM DD, YYYY hh:mm AM/PM",
        },
        { name: "YYYY MMM", value: "YYYY MMM" },
        { name: "hh:mm", value: "hh:mm" },
        // { name: "dd mmmm yyyy", value: "dd mmmm yyyy" },
        // { name: "mmm dd, yyyy", value: "mmm dd, yyyy" },
      ],
      // dateformatOptions:[
      //      {name:'MM/DD/YYYY',value:'MM/DD/YYYY'},
      //      {name:'DD/MM/YYYY',value:'DD/MM/YYYY'},
      //      {name:'YYYY/MM/DD',value:'YYYY/MM/DD'},
      //      {name:'YY/MM/DD',value:'YY/MM/DD'},
      //      {name:'Month DD, YYYY',value:'Month DD, YYYY'},
      //      {name:'DD Month YYYY',value:'DD Month YYYY'},
      //      {name:'YYYY-MM-DD',value:'YYYY-MM-DD'},
      //      {name:'Mon DD, YYYY',value:'Mon DD, YYYY'},
      // ],
      prevalue: "",
      locationbasedsettings: [
        {
          header: "Language",
          subheader: "Selecting preferred language options.",
        },
        {
          header: "Country",
          subheader:
            "Country Preferences for a personalized touch to your experience.",
        },
        {
          header: "Currency",
          subheader:
            "Customize your financial view by selecting your preferred currency options.",
        },
        {
          header: "Timezone",
          subheader:
            " Personalize your schedule by selecting your preferred timezone for accurate and timely updates.",
        },
      ],
      formatingbasedsetting: [
        {
          header: "Date and time format",
          subheader:
            "Tailor your timeline with ease by choosing your preferred date format settings",
        },
        {
          header: "Pagination settings",
          subheader:
            "Tailor your viewing experience for smoother, more organized content exploration.",
        },
        {
          header: "Tax",
          subheader: "",
        },
      ],
      locationOptions: {
        Country: [],
        Language: [{ name: "English", value: "English" }],
        Currency: [],
        Timezone: [],
      },
      items: [
        { label: "Settings", link: "/" },
        { label: "Default settings", link: "/" },
      ],
      table_height: Number,
      partially_checked: false,
      theads: [
        {
          prop: "action",
          label: "Activity",
          sortable: false,
          resizable: true,
          width: 250,
        },
        {
          prop: "createdBy",
          label: "Created By",
          sortable: false,
          resizable: true,
          width: 250,
        },
        {
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: true,
          width: 100,
        },
        {
          prop: "createdAt",
          label: "Timestamp",
          sortable: false,
          resizable: true,
          width: 250,
        },
      ],
      currPage: 1,
      rowsPerPage: 10,
      headvalue: [
        {
          prop: "action",
          label: "Activity",
          sortable: false,
          resizable: true,
          width: 250,
        },
        {
          prop: "createdBy",
          label: "Created By",
          sortable: false,
          resizable: true,
          width: 250,
        },
        {
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: true,
          width: 100,
        },
        {
          prop: "createdAt",
          label: "Timestamp",
          sortable: false,
          resizable: true,
          width: 250,
        },
      ],
      tableData: [
        {
          activity: "deleted new role",
          timestamp: "2023-03-09T17:12:21.866+00:00",
        },
        {
          activity: "added new role",
          timestamp: "2023-03-09T17:12:21.866+00:00",
        },
        {
          activity: "added new role",
          timestamp: "2023-03-09T17:12:21.866+00:00",
        },
        {
          activity: "added user",
          timestamp: "2023-03-09T17:12:21.866+00:00",
        },
        {
          activity: "added new role",
          timestamp: "2023-03-09T17:12:21.866+00:00",
        },
        {
          activity: "edited user",
          timestamp: "2023-03-09T17:12:21.866+00:00",
        },
        {
          activity: "user deleted",
          timestamp: "2023-03-09T17:12:21.866+00:00",
        },
        {
          activity: "user updated",
          timestamp: "2023-03-09T17:12:21.866+00:00",
        },
        {
          activity: "login",
          timestamp: "2023-03-09T17:12:21.866+00:00",
        },
      ],
      company: "",
      id: "",
      language: "English",
      currency: "",
      timezone: "",
    };
  },
  computed: {
    // languageOptions(){
    //   if()
    // },
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  watch: {
    countrySearchValue: {
      async handler(val) {
        if (val) {
          // let countryList = await MLTAxiosInstance.get(
          //   "/settings/v1/countryInfo?country=" + val
          // );
          const countryList = await MLTAxiosInstance.post("/util/filterData", {
            type: "filter",
            module: "countryinfo",
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: true,
            sortFieldAndOrder: {},
            limit: 2,
            filterQuery: {
              "name.common": { $regex: val, $options: "i" },
            },
            searchQuery: val,
            isNeedToMap: true,
            fieldsToMap: [
              {
                from: "name",
                isNested: true,
                nested: ["common"],
                to: "name",
              },
            ],
          });

          this.locationOptions.Country = countryList.data?.data;
          return;
        }
      },
    },
    paginatedData: {
      handler(data) {
        const example = data.map((val) => {
          return {
            title: val.action,
            subtitle: val.createdAt,
            active: true,
            visited: true,
          };
        });
        this.example_data = example;
      },
    },
    currency: {
      handler(data) {
        this.currency = data;
      },
    },
    timezone: {
      handler(val) {
        this.timezone = val;
      },
    },
  },
  async mounted() {
    try {
      // const response = await fetch(countryApiUrl);
      // const countries = await response.json();

      // Process the country data and create the desired format
      // const formattedCountries = countries.map(country => ({
      //   name: country.name.common,
      //   value: country.cca2 // or use cca3 for a 3-letter country code
      // }));
      const defaultSettings = await MLTAxiosInstance.get(`/settings`);
      console.log(defaultSettings, "mounted");
      const presetting = defaultSettings.data;
      this.defaultSettings = presetting;
      // this.countrySearchValue = presetting.country;
      // Language mapping
      if (
        this.defaultSettings &&
        this.defaultSettings.languages &&
        Object.values(this.defaultSettings).length > 0
      )
        this.languagesOptions = Object.values(
          this.defaultSettings.languages
        ).map((item) => {
          return { name: item };
        });
      this.timeZoneOptions = this.defaultSettings.timezones.map((item) => {
        return { name: item };
      });
      // this.currency = await presetting.currency.unit;
      // this.prevalue = await presetting.country;
      // this.timezone = await presetting.timezones;
      // this.pagination = await presetting.paginationSize;
      // this.date = await presetting.dateFormat;
      // const countryApiUrl = 'https://restcountries.com/v3.1/all';
      // );
      console.log(
        "🚀 ~ mounted ~ presetting.country?presetting.country?.substring(3)::",
        presetting.country ? presetting.country?.substring(3) : "ca"
      );
      // let countryList = await MLTAxiosInstance.get(
      //   `/settings/v1/countryInfo?country=${
      //     presetting.country ? presetting.country?.substring(0, 3) : "ca"
      //   }`
      // );
      let countryList = await MLTAxiosInstance.post("/util/filterData", {
        type: "filter",
        module: "countryinfo",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {
          "name.common": {
            $regex: presetting.country
              ? presetting.country?.substring(0, 3)
              : "ca",
            $options: "i",
          },
        },
        searchQuery: "ca",
        isNeedToMap: true,
        fieldsToMap: [
          {
            from: "name",
            isNested: true,
            nested: ["common"],
            to: "name",
          },
        ],
      });
      this.locationOptions.Country = countryList.data?.data;
      // console.log(formattedCountries);

      let organisationInfo = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$cookies.get(
          "user"
        )}?company=${this.$cookies.get("company")}`
      );
      console.log("organisationInfo", organisationInfo?.data?.languageAbility);

      this.languagesOptionsTranslation =
        organisationInfo?.data?.languageAbility;

      this.getUserInfo = await MLTAxiosInstance.get(
        "partymanagement/individual/auth"
      );
      console.log("getUserInfo", this.getUserInfo);
    } catch (error) {
      console.error("Error fetching country information:", error);
    }
  },
  methods: {
    async dateval(val) {
      // console.log(val);
      this.date = val;
    },
    async checkval(val) {
      console.log("🚀 ~ checkval ~ val:", val);
      // const customvalues = await MLTAxiosInstance.get(`settings/conversion/${val}`)
      // // console.log(customvalues.data,"valsssss");
      //  this.currency = await customvalues.data.currency
      //  this.timezone = await customvalues.data.timezones
    },
    async handleSelectedLanguage(selectedLanguage) {
      console.log(
        "🚀 ~ handleSelectedLanguage ~ selectedLanguage:",
        selectedLanguage
      );
      this.defaultSettings.currentLanguage = selectedLanguage.name;
      // const transformedObject = { [selectedLanguage.code]: selectedLanguage.name };
      // const response = await MLTAxiosInstance.patch(`/settings`,{languages: transformedObject});
      // console.log("response",response)
    },
    handleSelectedCountry(selectedCountry) {
      console.log(
        "🚀 ~ handleSelectedCountry ~ selectedCountry:",
        selectedCountry
      );
      this.defaultSettings = { ...this.defaultSettings, ...selectedCountry };
      // Handle country mapping
      this.defaultSettings.country = selectedCountry?.name;

      // Handle subRegion mapping
      this.defaultSettings.subRegion = selectedCountry?.subregion;

      // Handle currency
      if (
        selectedCountry &&
        selectedCountry.currencies &&
        Object.keys(selectedCountry.currencies).length > 0
      ) {
        // this.timeZoneOptions = selectedCountry?.timezones.map(item => {return{name:item}})
        let currencyKeys = Object.keys(selectedCountry.currencies);
        // if(curren)
        this.defaultSettings.currency = {
          unit: currencyKeys[0],
          symbol: selectedCountry.currencies[currencyKeys[0]]?.symbol,
        };
      }
      // Handle language mapping
      if (
        selectedCountry &&
        selectedCountry.languages &&
        Object.keys(selectedCountry.languages).length > 0
      ) {
        let languagekeys = Object.keys(selectedCountry.languages);
        this.languagesOptions = languagekeys.map((item) => {
          return { name: selectedCountry.languages[item] };
        });
        // if(curren)
        this.defaultSettings.languages = selectedCountry.languages;
        this.defaultSettings.currentLanguage =
          selectedCountry.languages[languagekeys[0]];
        let englishIndex = this.languagesOptions.findIndex(
          (item) => item.name == "English"
        );
        if (englishIndex < 0) {
          this.languagesOptions.splice(0, 0, { name: "English" });
          this.defaultSettings.currentLanguage = "English";
        }
      }

      // Handle timezone
      if (
        selectedCountry &&
        selectedCountry.timezones &&
        selectedCountry.timezones.length > 0
      ) {
        this.timeZoneOptions = selectedCountry?.timezones.map((item) => {
          return { name: item };
        });
        this.defaultSettings.timezones = selectedCountry.timezones;
        this.defaultSettings.currentTimezone = selectedCountry.timezones[0];
      }
    },
    async back() {
      this.$router.push(`/settings`);
    },
    async updateInfo() {
      try {
        this.defaultSettings.languages = this.languagesOptionsTranslation;
        console.log(this.defaultSettings, "final");
        this.defaultSettings.relatedParty = [
          {
            id: this.$cookies.get("user"),
          },
        ];
        this.defaultSettings.lastUpdate = new Date().toISOString();
        await MLTAxiosInstance.post(`settings`, this.defaultSettings);
        await this.$store.dispatch("loadDefaultSettings");

        this.notification = {
          type: "success",
          message: "Settings updated successfully",
        };
      } catch (error) {
        this.notification = {
          type: "danger",
          message: "Settings failed to updated",
        };
      }
    },
    async pathTo() {
      // if (val.label == "Settings") {
      this.$router.push(`/settings`);
      // }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return new Intl.DateTimeFormat("en-US", options)
        .format(date)
        .replace(",", "");
    },
    selectAll($event) {
      this.select_all = $event.target.checked;
      if (this.select_all === true) {
        this.partially_checked = false;
        document
          .querySelectorAll("[id^=check-drag" + this.unique + "]")
          .forEach((ele) => {
            if (ele.disabled === false) {
              ele.checked = true;
            }
          });
        this.tableData.forEach((ele) => {
          if (ele.disabled === false) {
            ele.checked = true;
          }
        });

        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.remove("grey");
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.add("theme");
      } else {
        this.partially_checked = false;
        document
          .querySelectorAll("[id^=check-drag" + this.unique + "]")
          .forEach((ele) => {
            if (ele.disabled === false) {
              ele.checked = false;
            }
            if (ele.checked === true) {
              this.partially_checked = true;
              document
                .getElementById("select-all-checkmark" + this.unique)
                .classList.add("theme");
            }
          });
        this.tableData.forEach((ele) => {
          if (ele.disabled === false) {
            ele.checked = false;
          }
        });
        if (this.partially_checked === false) {
          //
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("theme");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("grey");
        } else if (this.partially_checked === true) {
          //
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("grey");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("theme");
        }
      }
    },
    checkItem(val, $event) {
      this.atleastOne = false;
      this.all_checked = true;

      val.checked = $event.target.checked;
      if ($event.target.checked === false) {
        document.querySelector(".select-all" + this.unique).checked = false;
      }
      this.$emit("change", this.list);
      this.tableData.forEach((ele) => {
        if (ele.checked === false) {
          this.all_checked = false;
        }
        if (ele.checked === true) {
          this.atleastOne = true;
        }
      });
      if (this.all_checked === true) {
        document.querySelector(".select-all" + this.unique).checked = true;
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.add("theme");
      } else if (
        document.getElementById("select-all-checkmark" + this.unique)
      ) {
        this.partially_checked = false;
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.add("grey");
      }
      if (this.atleastOne === true && this.all_checked === false) {
        this.partially_checked = true;
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.remove("grey");
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.add("theme");
      } else if (this.all_checked === true) {
        this.partially_checked = false;
      }
    },
    applyColumns(val) {
      this.headvalue = [];
      val.forEach((e) => {
        if (e.checked === true) {
          this.headvalue.push(e);
        }
      });
    },
    arrange(val) {
      this.re_arrange = val;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },
  },
};
</script>

<style></style>
