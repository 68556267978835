<template>



<hlx-button class="secondary sm" @click="openDrawer">
              <!-- Check availability -->
               {{matchFromStatic('Check availability')}}
              </hlx-button
            >


      
  <!-- check availability -->
  <div class="add-contact-drawer">
    <hlx-drawer
    :key="componentkey"
      :show="showDrawer"
      position="right"
      width="400"
      :footer="true"
      :title="'Edit notes'"
      :show-close-icon="true"
      @close="closeDrawer"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          
          <b>
            <!-- Check availability -->
             {{matchFromStatic('Check availability')}}
            </b>
        </div>
        <div class="body-area-contact-drawer">
  
            <div class="decision-name-wrapper" style="margin-bottom:20px;">




                <div style="display: flex; justify-content: space-between;">
           
                  <span style="width: 281px;">
            <addressComponent
            :key="addressKey"
            :parent-info1="regionData"
            :type="'check availability'"
            :view="false"
                :pre-val="addressPreVal"
              @address-data="receivedAddressData"
              ></addressComponent>
            </span>

            <span>
              <hlx-button
class="primary sm" 
              :disabled="!showButton"
              @click="callCheckAvailability">
            <!-- Check -->
             {{matchFromStatic('Check')}}
            </hlx-button
          >
            </span>
                </div>

          




            </div>






            <div
          style="
            overflow-y: auto;
            height: 598px;
            overflow-x: hidden;
          "
        >
            <!-- v-show="cheackAvailable === true" -->
           <!-- {{"cheackAvailable:"}}{{ cheackAvailable }} -->
          <span 
           v-show="cheackAvailable === true"
          >
            <div v-if="products?.length > 0">
                <div class="latitudelongitude">Available offers</div>
              <div
                v-for="off in products"
                :key="off"
                style="width: 100%; margin-top: 11px"
              >
                <div>
                  <hlx-card
                    :seperator="false"
                    :card-title="false"
                    :card-footer="false"
                    :width="346"
                    :height="38"
                    :active="false"
                  >
                    <template #card-body>
                      <div v-if="off" class="mainpage_iteqefm4">
                        <span
                          style="
                            background: none;
                            width: 340px;
                            display: flex;
                            justify-content: space-between;
                            padding-top: 4px;
                            padding-left: 16px;
                          "
                        >
                          <span
                            style="
                              color: #0065b2;
                              font-size: 14px;
                              font-weight: 400;
                            "
                            >{{ off?.name }}</span
                          >
                          <span style="font-size: 13px; font-weight: 400">{{
                            off?.price
                          }}</span>
                        </span>
                      </div>
                    </template>
                  </hlx-card>
                </div>
              </div>
            </div>
            <div v-else>
              {{ "Product not available:" }}
              <div style="width: 100%; margin-top: 11px">
                <hlx-card
                  :seperator="false"
                  :card-title="false"
                  :card-footer="false"
                  :width="346"
                  :height="38"
                  :active="false"
                >
                  <template #card-body>
                    <div class="mainpage_iteqefm4">
                      <span
                        style="
                          background: none;
                          width: 340px;
                          display: flex;
                          justify-content: space-between;
                          padding-top: 4px;
                          padding-left: 16px;
                        "
                      >
                        <span
                          style="
                            color: #0065b2;
                            font-size: 14px;
                            font-weight: 400;
                          "
                          >
                          <!-- {{ "No Offer available" }} -->
                            <!-- {{ validation }} -->
                            <span v-if="validation && validation && validation?.en">
                                {{ validation?.en }}
                            </span>
                            <span v-else>
                                {{ "Address is not in our region list" }}
                            </span>
                          </span
                        >
                        <!-- <span style="font-size: 13px; font-weight: 400">{{
                          "-"
                        }}</span> -->
                      </span>
                    </div>
                  </template>
                </hlx-card>
              </div>
            </div>
          </span>
        </div>



        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeDrawer"
            >
            <!-- Cancel -->
             {{matchFromStatic('Cancel')}}
            </hlx-button
          >
          <hlx-button
            v-if="cheackAvailable === true"
            class="primary sm"
            style="margin-left: 10px"
            @click="clearDrawer"
            >
            <!-- Clear -->
             {{matchFromStatic('Clear')}}
            </hlx-button
          >
          <!-- <hlx-button
            v-else
            class="primary sm"
            style="margin-left: 10px"
            @click="addExcluded"
            >
            Save
            </hlx-button
          > -->
        </div>
      </template>
    </hlx-drawer>
  </div>






</template>

<script>


import addressComponent from "@/components/Product/ServiceQualification/dynamicAddress.vue"


import {
//   DMNAxiosInstance,
 // MLTAxiosInstance,
//  UPCAxiosInstance,
 UPCAxiosInstance
} from "@/config/axiosConfig";

export default {

    components: {
        addressComponent
    },
    data() {
      return {

        showButton: false,

        myProductList:[],

        addressKey:1,

addressPreVal:{
  mediumType: 'postalAddress',
  characteristic: {
  city: '',
  unit: '',
  country: '',
  state: '',
  postalCode: '',
  stateOrProvince: '',
  street1: '',
  street2: '',
  description: '',
  street: '',
      default: true,
      postalCodePrefix:'',
    '@type': '',
  lat:null,
  lng:null,
  }
},
            componentkey: 0,
            showDrawer: false,
            checkofferinput: '',
            
            autocompleteService: null,
            sessionToken: null,

            locationData1: '',

            payload: {
          
              postalCode : '',
            street : '',
            city : '',
            stateOrProvince : '',
            country : '',
            unit : '',
            latitude : null,
              longitude: null,
              postalCodePrefix: '',

            },

            validation: {},
            products: [],      
            cheackAvailable:false,      
            regionData:[],
        }
  },

  async mounted() {

    // let normalizeString = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    let myProductList = await this.parentRegionList();

    console.log("this.myProductList::", myProductList);

    for (let i = 0; i < myProductList.length; i++) { 
      let obj = myProductList[i];
      if (obj.value.criteria.type.toLowerCase() === "city") {
        this.regionData.push(obj.value.city)

        console.log("this.city::12", obj.value.criteria.type, this.regionData);
        
      }else if (obj.value.criteria.type.toLowerCase() === "province") {


        console.log("this.provience::12", obj.value.criteria.type, this.regionData);
        

        let rem = await this.getAddressValue('Province', obj.value);


        rem.short;
        console.log("remss::",rem);


        this.regionData.push(rem.short);



        // this.regionData.push(obj.value.state)
      }else if (obj.value.criteria.type.toLowerCase() === "postal code") {
        console.log("this.postalcode::12",obj.value.criteria.type, this.regionData);
        this.regionData.push(obj.value.postalCode)
      }
    }
    

    this.regionData;

    console.log("this.regionData::", this.regionData);
    


    
  },

    methods: {


      async getAddressValue(type, data2) {
    const mapping = {
        // "Full address": "description",
        // "Street": "street",
        // "City": "city",
        "Province": "stateOrProvince",
        // "Postal code": "postalCode",
        // "Prefix (FSA)": "postalCodePrefix"
    };

    // Find the corresponding key in data2.characteristic
    const key = mapping[type];
    const value = key ? data2[key] : null;

    // If the type is "Province", add the short form logic
    if (type === "Province" && value) {
        const shortForm = await this.getProvinceShortForm(value);
        return { long: value.toLowerCase(), short: shortForm.toLowerCase() };
    }

    // For other types, just return the value
    return value.toLowerCase();
},


      async getProvinceShortForm(provinceName) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(provinceName)}&key=${process.env.VUE_APP_GOOGLE_MAP_KEY}`;

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === "OK") {
            // Find the administrative area level 1 (province/state)
            const provinceComponent = data.results[0].address_components.find(component =>
                component.types.includes("administrative_area_level_1")
            );

            if (provinceComponent) {
                const shortForm = provinceComponent.short_name; // e.g., "QC" for Quebec
                return shortForm;
            }
        }

        console.error("Province not found or invalid response");
        return null;
    } catch (error) {
        console.error("Error fetching from Google Maps API:", error);
        return null;
    }
},




        async receivedAddressData(val) { 
            
            this.payload = {
                
                // mediumType: 'postalAddress',

                // characteristic: obj

                
            description : val.characteristic.description,
            postalCode : val.characteristic.postalCode,
            postalCodePrefix : val.characteristic.postalCodePrefix,
            street : val.characteristic.street,
            city : val.characteristic.city,
            stateOrProvince : val.characteristic.state,
            country : val.characteristic.country,
            unit : val.characteristic.unit,
            latitude : val.characteristic.lat,
            longitude : val.characteristic.lng,
                
            }

          console.log("receivedAddressData::", val);

          setTimeout(() => {
            this.showButton = true;
          }, 1100);

        },

        openDrawer() {
                this.showDrawer = true;
                this.componentkey += 1;
        },

        closeDrawer() { 
            this.showDrawer = false;
          this.componentkey += 1;
          this.clearDrawer();
      },

      async callCheckAvailability() {

        this.showButton = false;

        this.products = [];

        const filterPostQuery = await UPCAxiosInstance.post(
          `/productQualification/qualifyService`,
          this.payload
        );

        let response = filterPostQuery.data;

          console.log("filterPostQuery::", response, this.payload);

          if(response && response.exclusionResult && response.exclusionResult.length > 0) {
            this.validation = response.exclusionResult[0].criteria.reason;
            this.products = [];
          } else if(response && response.inclusionResult && response.inclusionResult.length > 0) {
            for (let index = 0; index < response.inclusionResult.length; index++) {
              const element = response.inclusionResult[index];
              element
              this.products.push(...element.productRelationship);
              
            }
            
          }


        this.cheackAvailable = true;
        
            
        
      },


        clearDrawer() {
            this.products = [];
            this.cheackAvailable = false;
          this.validation = {};
          this.showButton = false;

            let addressPreee = {
        mediumType: 'postalAddress',
        characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
          default: true,
          postalCodePrefix: '',
          '@type': '',
          lat: null,
          lng: null,
        }
      }; 
      this.addressPreVal = addressPreee;
      this.addressKey += 1;

        },

        async getParentExclusion(val) {
          let query = {
            company: this.$cookies.get("userName"),
                type: "filter",
                module: "address",
                isPaginated: true,
                paginatedQuery: {
                    rowsPerPage: 10,
                    currentPage: 1,
                },
                isNeedToBeSort: true,
                sortFieldAndOrder: {},
                limit: 10,
                filterQuery: {
                   "type": {
        "$in": [
            "exclusion"
        ]
    },


          "parentId": {
        "$in": [
            `${val}`
        ]
    }
            },
                searchQuery: "",
            };

            query

            const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          query
        );

            if (
                filterResultData.data.data != undefined &&
                filterResultData.data.total != 0
            ) {

              console.log("getParentExclusion:", filterResultData.data.data);
              
                // let val = filterResultData.data.data.map(itm => ({id:itm?.id, value: itm?.streetAddressLine[0].characteristic, name:itm?.streetAddressLine[0].characteristic.regionName }))

                return filterResultData.data.data;
            } 




      },





        async checkedbutton() { 

            let mainProvience = this.payload.stateOrProvince
            let mainCity = this.payload.city
            let mainPostalCode = this.payload.postalCode

            mainProvience
            mainCity
            mainPostalCode

            let rem = await this.productList();

            let cityTypeList = []
          let provienceTypeList = []
            let postalCodeTypeList = []

            for (let i = 0; i < rem.length; i++) {
                console.log(rem[i]);
                let val = rem[i]
                val

                if (val.place[0].criteria.type === 'Province') {

                    val
                    provienceTypeList.push(val)



                } else if (val.place[0].criteria.type === 'City') {

                    val
                    cityTypeList.push(val)



                } else if(val.place[0].criteria.type.toLowerCase() === 'postal code') {
                  console.log("match with no type")
                  val

                  postalCodeTypeList.push(val)

                }

            }


            this.checkCityType(cityTypeList)
            this.checkProvienceType(provienceTypeList)
            this.checkPostalCodeType(postalCodeTypeList)





        },



        async checkProvienceType(val) {
            let normalizeString = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            val

            //iterate parents region
            for (let i = 0; i < val.length; i++) { 
                let obj = val[i];
                // obj
                // obj.place[0].characteristic.stateOrProvince
                // this.payload.characteristic.stateOrProvince
                // this.payload.characteristic.postalCode

                if (normalizeString(obj.place[0].characteristic.state.toLowerCase()) === normalizeString(this.payload.characteristic.state.toLowerCase())) {
                    console.log("present in this region", this.payload.characteristic, obj);

                    // check exclusion
                    let exclusionList = await this.getParentExclusion(obj.place[0].id)
                    exclusionList
                  // let noEx = 0; 

                  //iterate parents exclude
                    for (let j = 0; j < exclusionList.length; j++){
                        let exObj = exclusionList[j]
                      exObj
                      console.log("each exclude region:",exObj);

                      //type partial
                      if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'partial address') {     
                        console.log("type:",exObj.streetAddressLine[0].characteristic);
                                           

                        //if street, postalCode
                        if (exObj.streetAddressLine[0].characteristic.criteria === 'Street name + Postal code') {
                          if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                            &&
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                          ) {
                            exObj
                            console.log('matched with "Street name + Postal code"');
                            this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];

                            
                          }
                        }
                        //if street, postalCodePrefix
                        else if (exObj.streetAddressLine[0].characteristic.criteria === 'Street name + Prefix') {
                          exObj
                          if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                            &&
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                          ) {
                            exObj
                            console.log('matched with "Street name + Prefix"');
                            this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                          }
                        }
                        //if city, postalCode
                        else if (exObj.streetAddressLine[0].characteristic.criteria === 'City + Postal code') {
                          exObj
                          if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.city.toLowerCase()) === normalizeString(this.payload.characteristic.city.toLowerCase())
                            &&
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                          ) {
                            exObj
                            console.log('matched with "City + Postal code"');
                            this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                          }
                        }
                        //if city, postalCodePrefix
                        else if (exObj.streetAddressLine[0].characteristic.criteria === 'City + Prefix') {
                          exObj
                          if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.city.toLowerCase()) === normalizeString(this.payload.characteristic.city.toLowerCase())
                            &&
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                          ) {
                            exObj
                            console.log('matched with "City + Prefix"');
                            this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                          }
                        }
                      } 

                      //type city
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'city') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.city.toLowerCase()) === normalizeString(this.payload.characteristic.city.toLowerCase())
                        ) {
                          exObj
                          console.log('matched with "city"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }

                      //type street
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'street') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                        ) {
                          exObj
                          console.log('matched with "street"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }

                      //type postalCode
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'postal code') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                        ) {
                          exObj
                          console.log('matched with "postal code"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }

                      //type postalcode prefix
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'prefix (fsa)') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCodePrefix.toLowerCase()) === normalizeString(this.payload.characteristic.postalCodePrefix.toLowerCase())
                        ) {
                          exObj
                          console.log('matched with "prefix (fsa)"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }

                      //type full address
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'full address') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                          normalizeString(exObj.streetAddressLine[0].characteristic.street) === normalizeString(this.payload.characteristic.street) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.unit) === normalizeString(this.payload.characteristic.unit) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.city) === normalizeString(this.payload.characteristic.city) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.state) === normalizeString(this.payload.characteristic.state) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.postalCode) === normalizeString(this.payload.characteristic.postalCode) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.country) === normalizeString(this.payload.characteristic.country)
                        ) {
                          exObj
                          console.log('matched with "full address"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }


                      
                        // if (exObj.streetAddressLine[0].characteristic.description.toLowerCase() === this.payload.characteristic.description.toLowerCase())  {
                        //     val
                        //     console.log("validation::", exObj.streetAddressLine[0].characteristic.validation);
                        //     this.validation = exObj.streetAddressLine[0].characteristic.validation
                            

                        // } else {
                        //     noEx = noEx + 1;
                        // }
                  }



                  const isEmpty = (obj) => obj && Object.keys(obj).length === 0;
                  if (isEmpty(this.validation)) {
                    console.log("match with exclusion");
                    
                    this.products.push(...obj.productRelationship)
                    this.validation = {}
                    
                  } else {
                    console.log("not mmatch with any exclusion");
                    
                  }



                  
                    // if (noEx === exclusionList.length) {
                    //     this.products.push(...obj.productRelationship)
                    //     this.validation = {}
                    // }


                } else {
                    console.log('not present in this region', this.payload.characteristic, obj.place[0].characteristic);
                    
                }

                

            }
            
            

            this.cheackAvailable = true
            
         },

      async checkCityType(val) {
        let normalizeString = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            val

            for (let i = 0; i < val.length; i++) { 
                let obj = val[i];
                obj
                obj.place[0].characteristic.stateOrProvince
                this.payload.characteristic.stateOrProvince
                this.payload.characteristic.postalCode

                if (normalizeString(obj.place[0].characteristic.city.toLowerCase()) === normalizeString(this.payload.characteristic.city.toLowerCase())) {
                    console.log("present in this region");

                    // check exclusion
                    let exclusionList = await this.getParentExclusion(obj.place[0].id)
                    exclusionList
                  // let noEx = 0;

                   //iterate parents exclude
                   for (let j = 0; j < exclusionList.length; j++){
                        let exObj = exclusionList[j]
                      exObj
                      console.log("each exclude region:",exObj);

                      //type partial
                      if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'partial address') {     
                        console.log("type:",exObj.streetAddressLine[0].characteristic);
                                           

                        //if street, postalCode
                        if (exObj.streetAddressLine[0].characteristic.criteria === 'Street name + Postal code') {
                          if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                            &&
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                          ) {
                            exObj
                            console.log('matched with "Street name + Postal code"');
                            this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];

                            
                          }
                        }
                        //if street, postalCodePrefix
                        else if (exObj.streetAddressLine[0].characteristic.criteria === 'Street name + Prefix') {
                          exObj
                          if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                            &&
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                          ) {
                            exObj
                            console.log('matched with "Street name + Prefix"');
                            this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                          }
                        }
                        //if city, postalCode
                        else if (exObj.streetAddressLine[0].characteristic.criteria === 'City + Postal code') {
                          exObj
                          if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                            &&
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                          ) {
                            exObj
                            console.log('matched with "City + Postal code"');
                            this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                          }
                        }
                        //if city, postalCodePrefix
                        else if (exObj.streetAddressLine[0].characteristic.criteria === 'City + Prefix') {
                          exObj
                          if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                            &&
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                          ) {
                            exObj
                            console.log('matched with "City + Prefix"');
                            this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                          }
                        }
                      } 

                      //type city
                      // else if (exObj.streetAddressLine[0].characteristic.type.toLowerCase() === 'city') {  
                      //   console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                      //   exObj
                      //   if (
                      //       normalizeString(exObj.streetAddressLine[0].characteristic.city.toLowerCase()) === normalizeString(this.payload.characteristic.city.toLowerCase())
                      //   ) {
                      //     exObj
                      //     console.log('matched with "city"');
                      //     this.validation = exObj.streetAddressLine[0].characteristic.validation;
                      //       this.products = [];
                      //   }

                      // }

                      //type street
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'street') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                        ) {
                          exObj
                          console.log('matched with "street"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }

                      //type postalCode
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'postal code') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                        ) {
                          exObj
                          console.log('matched with "postal code"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }

                      //type postalcode prefix
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'prefix (fsa)') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCodePrefix.toLowerCase()) === normalizeString(this.payload.characteristic.postalCodePrefix.toLowerCase())
                        ) {
                          exObj
                          console.log('matched with "prefix (fsa)"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }

                      //type full address
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'full address') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                          normalizeString(exObj.streetAddressLine[0].characteristic.street) === normalizeString(this.payload.characteristic.street) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.unit) === normalizeString(this.payload.characteristic.unit) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.city) === normalizeString(this.payload.characteristic.city) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.state) === normalizeString(this.payload.characteristic.state) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.postalCode) === normalizeString(this.payload.characteristic.postalCode) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.country) === normalizeString(this.payload.characteristic.country)
                        ) {
                          exObj
                          console.log('matched with "full address"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }


                      
                        // if (exObj.streetAddressLine[0].characteristic.description.toLowerCase() === this.payload.characteristic.description.toLowerCase())  {
                        //     val
                        //     console.log("validation::", exObj.streetAddressLine[0].characteristic.validation);
                        //     this.validation = exObj.streetAddressLine[0].characteristic.validation
                            

                        // } else {
                        //     noEx = noEx + 1;
                        // }
                  }


                  const isEmpty = (obj) => obj && Object.keys(obj).length === 0;
                  if (isEmpty(this.validation)) {
                    console.log("match with exclusion");
                    
                    this.products.push(...obj.productRelationship)
                    this.validation = {}                        
                    
                  } else {
                    console.log("not mmatch with any exclusion");
                    
                  }

                  
                    // for (let j = 0; j < exclusionList.length; j++){
                    //     let exObj = exclusionList[j]
                    //     exObj
                    //     if (exObj.streetAddressLine[0].characteristic.description.toLowerCase() === this.payload.characteristic.description.toLowerCase())  {
                    //         val
                    //         console.log("validation::", exObj.streetAddressLine[0].characteristic.validation);
                    //         this.validation = exObj.streetAddressLine[0].characteristic.validation
                            

                    //     } else {
                    //         noEx = noEx + 1;
                    //     }
                    // }
                    // if (noEx === exclusionList.length) {
                    //     this.products.push(...obj.productRelationship)
                    //     this.validation = {}
                    // }








                } else {
                    console.log('not present in this region');
                    
                }

                

            }
            
            



            this.cheackAvailable = true            
         },

         async checkPostalCodeType(val) {
            let normalizeString = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            val

            //iterate parents region
            for (let i = 0; i < val.length; i++) { 
                let obj = val[i];
                obj
                obj.place[0].characteristic.stateOrProvince
                this.payload.characteristic.stateOrProvince
                this.payload.characteristic.postalCode

                if (normalizeString(obj.place[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())) {
                    console.log("present in this region", this.payload.characteristic, obj);

                    // check exclusion
                    let exclusionList = await this.getParentExclusion(obj.place[0].id)
                    exclusionList
                  // let noEx = 0; 

                  //iterate parents exclude
                    for (let j = 0; j < exclusionList.length; j++){
                        let exObj = exclusionList[j]
                      exObj
                      console.log("each exclude region:",exObj);

                      //type partial
                      if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'partial address') {     
                        console.log("type:",exObj.streetAddressLine[0].characteristic);
                                           

                        //if street, postalCode
                        if (exObj.streetAddressLine[0].characteristic.criteria === 'Street name + Postal code') {
                          if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                            &&
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                          ) {
                            exObj
                            console.log('matched with "Street name + Postal code"');
                            this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];

                            
                          }
                        }
                        //if street, postalCodePrefix
                        else if (exObj.streetAddressLine[0].characteristic.criteria === 'Street name + Prefix') {
                          exObj
                          if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                            &&
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                          ) {
                            exObj
                            console.log('matched with "Street name + Prefix"');
                            this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                          }
                        }
                        //if city, postalCode
                        else if (exObj.streetAddressLine[0].characteristic.criteria === 'City + Postal code') {
                          exObj
                          if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                            &&
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                          ) {
                            exObj
                            console.log('matched with "City + Postal code"');
                            this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                          }
                        }
                        //if city, postalCodePrefix
                        else if (exObj.streetAddressLine[0].characteristic.criteria === 'City + Prefix') {
                          exObj
                          if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                            &&
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                          ) {
                            exObj
                            console.log('matched with "City + Prefix"');
                            this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                          }
                        }
                      } 

                      //type city
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'city') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.city.toLowerCase()) === normalizeString(this.payload.characteristic.city.toLowerCase())
                        ) {
                          exObj
                          console.log('matched with "city"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }

                      //type street
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'street') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.street.toLowerCase()) === normalizeString(this.payload.characteristic.street.toLowerCase())
                        ) {
                          exObj
                          console.log('matched with "street"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }

                      //type postalCode
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'postal code') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCode.toLowerCase()) === normalizeString(this.payload.characteristic.postalCode.toLowerCase())
                        ) {
                          exObj
                          console.log('matched with "postal code"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }

                      //type postalcode prefix
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'prefix (fsa)') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                            normalizeString(exObj.streetAddressLine[0].characteristic.postalCodePrefix.toLowerCase()) === normalizeString(this.payload.characteristic.postalCodePrefix.toLowerCase())
                        ) {
                          exObj
                          console.log('matched with "prefix (fsa)"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }

                      //type full address
                      else if (exObj.streetAddressLine[0].characteristic.typeExclude.toLowerCase() === 'full address') {  
                        console.log("type:", exObj.streetAddressLine[0].characteristic);
                        
                        exObj
                        if (
                          normalizeString(exObj.streetAddressLine[0].characteristic.street) === normalizeString(this.payload.characteristic.street) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.unit) === normalizeString(this.payload.characteristic.unit) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.city) === normalizeString(this.payload.characteristic.city) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.state) === normalizeString(this.payload.characteristic.state) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.postalCode) === normalizeString(this.payload.characteristic.postalCode) &&
                          normalizeString(exObj.streetAddressLine[0].characteristic.country) === normalizeString(this.payload.characteristic.country)
                        ) {
                          exObj
                          console.log('matched with "full address"');
                          this.validation = exObj.streetAddressLine[0].characteristic.validation;
                            this.products = [];
                        }

                      }


                      
                        // if (exObj.streetAddressLine[0].characteristic.description.toLowerCase() === this.payload.characteristic.description.toLowerCase())  {
                        //     val
                        //     console.log("validation::", exObj.streetAddressLine[0].characteristic.validation);
                        //     this.validation = exObj.streetAddressLine[0].characteristic.validation
                            

                        // } else {
                        //     noEx = noEx + 1;
                        // }
                  }



                  const isEmpty = (obj) => obj && Object.keys(obj).length === 0;
                  if (isEmpty(this.validation)) {
                    console.log("match with exclusion");
                    
                    this.products.push(...obj.productRelationship)
                    this.validation = {}                        
                    
                  } else {
                    console.log("not mmatch with any exclusion");
                    
                  }



                  
                    // if (noEx === exclusionList.length) {
                    //     this.products.push(...obj.productRelationship)
                    //     this.validation = {}
                    // }


                } else {
                    console.log('not present in this region', this.payload.characteristic, obj.place[0].characteristic);
                    
                }

                

            }
            
            

            this.cheackAvailable = true
            
         },
        

     }

}
</script>

<style>
.product-item1212{
    border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>