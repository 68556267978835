import deepdiff from 'deep-diff';
import '@/config/axiosConfig';
import VueCookies from 'vue-cookies';
import axios from 'axios';
import he from 'he';
import {
  MLTAxiosInstance,
  DMNAxiosInstance,
  UPCAxiosInstance,
} from '@/config/axiosConfig';
// import axios from "axios";
const install = function (Vue) {
  // 1. add global method or property
  Vue.myGlobalMethod = function () {
    // some logic ...
  };

  // 2. add a global asset
  // Vue.directive('my-directive', {
  //   bind (el, binding, vnode, oldVnode) {
  //     // some logic ...
  //   }
  //   ...
  // })
  // By varsan
  const originalConsoleLog = console.log;
  // 3. inject some component options
  Vue.mixin({
    beforeRouteEnter(to, from, next) {
      console.log('🚀 ~ beforeRouteEnter ~ to:', to);
      // Check if the user is authenticated
      if (
        to &&
        ![
          'signUp',
          'signIn',
          'InviteUser',
          'SupplierInviteUser',
          'pdfviewer',
        ].includes(to.name)
      ) {
        if (to.name == 'previewTemplate' && to.query.access) {
          VueCookies.set('token', to.query.access);
          // // console.log(
          // //   "🚀 ~ router.beforeEach ~ VueCookies.get(token):",
          // //   VueCookies.get("token")
          // // );
          next();
        } else if (VueCookies.get('token')) {
          // // console.log(
          // //   "🚀 ~ router.beforeEach ~ VueCookies.get(token):",
          // //   VueCookies.get("token")
          // // );
          next();
          //  return;
        } else {
          // console.log("🚀 ~ router.beforeEach ~ to: in else", to);
          console.log('🚀 ~ beforeRouteEnter ~ to:', to);
          // next({
          //   name: "signIn",
          //   query: { redirect: to.fullPath },
          // });
          // console.log("🚀 ~ router.beforeEach ~ to: in else", to);
          return;
        }
        //  return
      }
    },
    methods: {
      transformPricingData(data2) {
        console.log('customidata', data2);
  
        let transformedData = [];
        let data = JSON.parse(JSON.stringify(data2));
  
        data.forEach((originalData2) => {
          const originalData = JSON.parse(JSON.stringify(originalData2));
  
          let newOptions = originalData?.billing_period_options || [
            { name: 'Monthly', value: 'Monthly' },
          ];
  
          if (!('product_offering_name' in originalData)) {
            console.log(originalData, 'loi8');
  
            transformedData.push({
              offerId: originalData.offerId,
              bundleIndex: originalData.bundleIndex,
              productSpecification: originalData.productSpecification,
              productOffering: originalData.productOffering,
              productCharacteristic: originalData.productCharacteristic,
              prodSpecCharValueUse: originalData.prodSpecCharValueUse,
              prodSpecCharValue: originalData.prodSpecCharValue,
              product_offering_name: originalData?.name,
              discountResponse: originalData.discountResponse,
              unit_of_measure: 'Each',
              rate: String(
                parseFloat(
                  `${originalData?.price?.whole}.${originalData?.price?.fraction}`
                )?.toFixed(2)
              ),
              quantity: originalData.quantity,
              max_quantity: originalData.max_quantity,
              discount: originalData?.discount || { value: 0, type: '%' },
              amount: 0,
              line_total: null,
              unitOfMeasure: originalData2.unitOfMeasure,
              additionalCharges: originalData2.additionalCharges ?? [],
              section: false,
              address: false,
              tax: 0.0,
              skuid: originalData.skuid,
              supplierUnitCost: originalData.supplierUnitCost || 0,
              unitMsrp: originalData.unitMsrp || 0,
              rebate: originalData.rebate || 0,
              custom: originalData.custom,
              offerType: originalData.offerType,
              notes: '',
              description: originalData.description,
              duration: originalData.duration,
              priceType: originalData.priceType,
              billing_period:
                originalData.offering_type === 'intangible'
                  ? originalData?.billing_period
                  : originalData?.billing_period == ''
                  ? originalData.prodSpecCharValue[0].priceType
                  : 'One time',
              billing_period_options: originalData?.billing_period_options || [
                { name: 'Monthly', value: 'Monthly' },
                { name: 'Annually', value: 'Annually' },
              ],
              intangible: originalData.offering_type === 'intangible',
            });
          } else {
            transformedData.push({
              ...originalData,
              amount: 0,
              billing_period:
                originalData.intangible === true
                  ? originalData?.billing_period || 'Monthly'
                  : 'One time',
              billing_period_options: newOptions,
              priceType: originalData.priceType,
              discount: originalData?.discount || { value: 0, type: '%' },
              discountResponse: originalData.discountResponse,
              supplierUnitCost: originalData.supplierUnitCost,
              unitMsrp: originalData.unitMsrp,
              rebate: originalData.rebate,
            });
          }
        });
        transformedData.forEach((i, index) => {
          if (data2[index].discount !== undefined) {
            i.discount = JSON.parse(JSON.stringify(data2[index].discount));
          } else {
            i.discount = JSON.parse(JSON.stringify({ value: 0, type: '%' }));
          }
        });
        console.log('1.1.2', data2, data, transformedData);
        return transformedData;
        
      },

      async triggerPricingRules(prop ,pricing_table_data,total_wise_discount_array_onetime,item_wise_discount_onetime,total_wise_discount_array_recurring,item_wise_discount_recurring,additionalChargesArray,taxData) {
        const quotePayload = {
          quote: {
            quoteItem: JSON.parse(JSON.stringify(this.transformPricingData(pricing_table_data))),
            summary: {
              showOneTimeSummary: false,
              showRecurringSummary: false,
              priceable: true,
              oneTime: {
                subTotal: 0,
                tax: 0,
                discount: total_wise_discount_array_onetime,
                item_wise_discount_onetime: item_wise_discount_onetime,
              },
              recurring: {
                subTotal: 0,
                tax: 0,
                discount: total_wise_discount_array_recurring,
                item_wise_discount_recurring: item_wise_discount_recurring,
              },
              tax: taxData,
              additionalCharges: additionalChargesArray,
              overAllTotal: 0,
              amountSaved: 0,
            },
          },
        };
        let data = {};
        switch (prop) {
          case 'rate':
            data = await DMNAxiosInstance.post(
              `/rule_engine/rule/execute/66827e3ac0f9d2676a34c929`,
              { payload: quotePayload }
            );
            break;
          case 'quantity':
            data = await DMNAxiosInstance.post(
              `/rule_engine/rule/execute/66827e3ac0f9d2676a34c929`,
              { payload: quotePayload }
            );
            break;
          case 'amount':
            data = await DMNAxiosInstance.post(
              `/rule_engine/rule/execute/66828f4ed5f0c403af9e5b00
   `,
              { payload: quotePayload }
            );
            break;
        }
        // let ruleResult = await this.triggerPricingRules()
        // console.log(val, 'new val ');
        // console.log(ruleResult.data.data.quote.quoteItem, 'new val');
        console.log('update rules', data?.data?.data?.quote);
        const showOnetimeSummary =
          data?.data?.data?.quote?.summary?.showOneTimeSummary;
          const showRecurringSummary =
          data?.data?.data?.quote?.summary?.showRecurringSummary;
          const quoteSummaryFromRules = data?.data?.data?.quote?.summary;
         pricing_table_data = data?.data?.data?.quote?.quoteItem;


        return {
          showOnetimeSummary:showOnetimeSummary,
          showRecurringSummary:showRecurringSummary,
          quoteSummaryFromRules:quoteSummaryFromRules,
          pricing_table_data:pricing_table_data
        }
      },
      async EOProductRule(NoU = 0, isPlenum = 'Yes') {
        console.log('EOP', NoU, isPlenum);

        let body = {
          payload: {
            data: {
              skus: [],
              numberOfUnits: NoU,
              isPlenumVentilationUsed: isPlenum,
            },
          },
        };

        let rule = await DMNAxiosInstance.post(
          `/rule_engine/rule/execute/6789153b5ab16ce855ba2156`,
          body
        );

        return rule.data.data.data;
      },
      async getProductsFromSKU(sku) {
        console.log('EOP', sku);

        let filterPayload = {
          type: 'filter',
          module: 'productOffering',
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {},
          limit: 10,
          filterQuery: {
            'prodSpecCharValue.SKU': sku,
          },
          searchQuery: '',
          company: this.$cookies.get('company'),
          searchList: [],
        };

        let data = await UPCAxiosInstance.post(
          `util/filterData`,
          filterPayload
        );
        return data.data.data;
      },
      reverseTransformPayloadDynamic(parentOrderPayload) {
        const inputData = {};

        // Map attributes from field1Data and field2Data directly
        inputData.field1Data = this.extractDynamicAttributes(
          parentOrderPayload,
          [
            'status',
            'orderType',
            'numberOfSites',
            'owner',
            'companyName',
            'dwellingType',
            'description',
            'category',
          ]
        );

        inputData.field2Data = this.extractDynamicAttributes(
          parentOrderPayload,
          ['isMajorRenovation', 'isNewMDU', 'isPlenumVentilationUsed']
        );

        // Extract field3Data for orderContact role
        inputData.field3Data = this.extractRelatedParty(
          parentOrderPayload.relatedParty,
          'orderContact'
        );

        // Map geographicAddress to field4Data.billingAddress
        inputData.field4Data = {
          billingAddress: (parentOrderPayload.geographicAddress || []).map(
            (address) =>
              this.extractDynamicAttributes(address, Object.keys(address))
          ),
        };

        // Split field5Data based on roles and types
        inputData.field5Data = {
          ...this.extractDynamicAttributesByRoleAndType(
            parentOrderPayload.relatedParty,
            'construction',
            'Organisation'
          ),
          ...this.extractDynamicAttributesByRoleAndType(
            parentOrderPayload.relatedParty,
            'construction',
            'Individual'
          ),
        };

        // Split field6Data based on roles and types
        inputData.field6Data = {
          ...this.extractDynamicAttributesByRoleAndType(
            parentOrderPayload.relatedParty,
            'requester',
            'Organisation'
          ),
          ...this.extractDynamicAttributesByRoleAndType(
            parentOrderPayload.relatedParty,
            'requester',
            'Individual'
          ),
        };

        return inputData;
      },

      // Extract dynamic attributes for a list of keys
      extractDynamicAttributes(source, keys) {
        const result = {};
        for (const key of keys) {
          if (source[key] !== undefined) {
            result[key] = source[key];
          }
        }
        return result;
      },

      // Extract attributes dynamically for relatedParty by role and type
      extractDynamicAttributesByRoleAndType(relatedParties, role, type) {
        const party = relatedParties.find(
          (party) => party.role === role && party['@type'] === type
        );
        return party ? { ...party } : {};
      },

      // Extract a related party for a specific role dynamically
      extractRelatedParty(relatedParties, role) {
        const party = relatedParties.find((party) => party.role === role);
        return party ? { ...party } : {};
      },
      transformPayload(inputData) {
        const transformedPayload = {
          externalId: [],
          priority: '1',
          status: inputData.field1Data.status || 'Open',
          orderType: inputData.field1Data.orderType || 'New',
          numberOfSites: inputData.field1Data.numberOfSites || 0,
          owner: inputData.field1Data.owner || {},
          customName: this.field1Data.companyName || '',
          companyName:
            this.sanitizeDatabaseName(this.field1Data.companyName) || '',
          companyId: inputData.field1Data.companyId || '',
          dwellingType: inputData.field1Data.dwellingType || '',
          isMajorRenovation: inputData.field2Data.isMajorRenovation || 'Yes',
          isNewMDU: inputData.field2Data.isNewMDU || 'Yes',
          isPlenumVentilationUsed:
            inputData.field2Data.isPlenumVentilationUsed || 'Yes',
          description: inputData.field1Data.description || '',
          category: inputData.field1Data.category || '',
          orderDate: new Date().toISOString(),
          geographicAddress: inputData.field4Data.billingAddress.map(
            (address) => ({
              mediumType: 'billingAddress',
              characteristic: address.characteristic,
            })
          ),
          requestedStartDate: '2025-01-15T00:00:00Z',
          requestedCompletionDate: '2025-01-15T00:00:00Z',
          relatedParty: [
            // Role: orderContact
            {
              role: 'orderContact',
              '@type': 'individual',
              ...inputData.field3Data,
            },
            // Role: construction (Organisation)
            {
              role: 'construction',
              '@type': 'Organisation',
              companyName: inputData.field5Data.companyName,
              companyEmailId: inputData.field5Data.companyEmailId,
            },
            // Role: construction (Individual)
            {
              role: 'construction',
              '@type': 'individual',
              ...this.pick(inputData.field5Data, [
                'contactType',
                'chooseContact',
                'firstName',
                'lastName',
                'emailId',
                'phoneNumber',
              ]),
            },
            // Role: requester (Organisation)
            {
              role: 'requester',
              '@type': 'Organisation',
              companyName: inputData.field6Data.companyName,
              companyEmailId: inputData.field6Data.companyEmailId,
            },
            // Role: requester (Individual)
            {
              role: 'requester',
              '@type': 'individual',
              ...this.pick(inputData.field6Data, [
                'contactType',
                'firstName',
                'lastName',
                'emailId',
                'phoneNumber',
              ]),
            },
          ],
          '@type': 'rootOrder',
          '@baseType': 'productOrder',
        };

        return transformedPayload;
      },

      // Utility function to pick keys from an object
      pick(obj, keys) {
        return keys.reduce((acc, key) => {
          if (obj[key] !== undefined) {
            acc[key] = obj[key];
          }
          return acc;
        }, {});
      },
      generateUniqueEmail(domain = 'example.com') {
        const randomString = () => Math.random().toString(36).substring(2, 10); // Generate a random string
        const timestamp = Date.now(); // Ensure uniqueness using the current timestamp
        return `user_${randomString()}_${timestamp}@${domain}`;
      },
      sanitizeDatabaseName(databaseName) {
        // Replace dots with an underscore or any other symbol you want
        const sanitized = databaseName?.replace(/\./g, '_');

        // Validate the constraints for MongoDB database names
        const validDatabaseName = sanitized?.replace(/[^a-zA-Z0-9_]/g, '');

        return validDatabaseName;
      },
      dataVisibilityPermission(orginalID, ViewAndEdit, view, role) {
        const viewAndEditSet = new Set(ViewAndEdit); // Convert to Set for O(1) lookup
        const viewSet = new Set(view); // Convert to Set for O(1) lookup

        // Default permission object
        const permissions = {
          view: role.view,
          add: role.add,
          edit: role.edit,
          delete: false,
        };

        if (role.view && viewAndEditSet.has(orginalID)) {
          return permissions;
        } else if (role.view && viewSet.has(orginalID)) {
          // User has view permission but no edit or add permission
          return {
            view: role.view,
            add: false,
            edit: false,
            delete: false,
          };
        }

        return role; // No specific permission, return the original role
      },
      // getEffectivePermissions(accessZone, subAccess) {
      //   if (accessZone.view === true) {
      //     return { edit: false, delete: false, add: false, view: true };
      //   }

      //   if (accessZone["view / edit"] === true) {
      //     return { edit: true, delete: false, add: true, view: true };
      //   }

      //   if (accessZone["view / edit / delete"] === true) {
      //     return { edit: true, delete: true, add: true, view: true };
      //   }

      //   // If none of the main access options are true, return subAccess
      //   return subAccess;
      // },
      generateDisplayId(note, arrayOfStrings, countToGenerate) {
        const validArray = Array.isArray(arrayOfStrings) ? arrayOfStrings : [];

        const numbers = validArray.map((str) => {
          if (str && typeof str === 'string' && str.includes('-')) {
            const parts = str.split('-'); // Split by "-"
            return parts[1] ? parseInt(parts[1], 10) : 0; // Get the numeric part
          }
          return 0;
        });

        const maxNumber = numbers.length > 0 ? Math.max(...numbers) : 0;

        const result = [];
        for (let i = 1; i <= countToGenerate; i++) {
          const nextNumber = (maxNumber + i).toString().padStart(3, '0'); // Always pad to 3 digits
          result.push(`${note}-${nextNumber}`);
        }

        return result;
      },
      getDateFormat(formatStr) {
        // Regular expressions to remove parts of the format (ddd and time)
        const patternsToRemove = [
          /\s?ddd,?\s?/, // ddd (day of the week)
          /\s?h:mm (AM\/PM)?/, // hh:mm or hh:mm AM/PM
          /\s?hh:mm (AM\/PM)?/, // hh:mm or hh:mm AM/PM
          /\s?hh:mm:ss (AM\/PM)?/, // hh:mm:ss or hh:mm:ss AM/PM
          /\s?hh:mm/, // hh:mm:ss or hh:mm:ss AM/PM
          /\s?(AM\/PM)/, // AM/PM part
          /\s?h/, // trailing hour part
        ];

        // Remove the unwanted parts from the format
        for (const pattern1 of patternsToRemove) {
          formatStr = formatStr?.replace(pattern1, '')?.trim();
        }
        for (const pattern2 of patternsToRemove) {
          formatStr = formatStr?.replace(pattern2, '')?.trim();
        }
        if (formatStr == '') {
          return 'mmm dd, yyyy';
        }
        return formatStr; // Return the trimmed format
      },
      generateNextUniqueID(existingData) {
        try {
          // Validate that existingData is an array
          if (!Array.isArray(existingData)) {
            throw new TypeError('Input must be an array');
          }

          // Extract valid uniqueIDs and find the highest number
          // const highestNumber = existingData.reduce((max, item, index) => {
          const highestNumber = existingData.reduce((max, item) => {
            if (item && typeof item.uniqueID === 'string') {
              const match = item.uniqueID.match(/^LANG-(\d+)$/);
              if (match) {
                const numberPart = parseInt(match[1], 10);
                return Math.max(max, numberPart);
              }
            }
            // Skip invalid entries silently
            return max;
          }, 0); // Start with 0 if no numbers are found

          // Increment the highest number by 1
          const nextNumber = highestNumber + 1;

          // Format the next uniqueID with leading zeros
          const nextUniqueID = 'LANG-' + nextNumber.toString().padStart(4, '0');

          return nextUniqueID;
        } catch (error) {
          // Log and rethrow the error for debugging
          console.error(error.message);
          throw error;
        }
      },

      filterData1(data) {
        return {
          Status: data.Status,
          Type: data.Type.filter((type) =>
            ['Postal code', 'Province', 'City'].some((allowed) =>
              Array.isArray(type.name)
                ? type.name.includes(allowed)
                : type.name === allowed
            )
          ).map((type) => ({
            ...type,
            name: Array.isArray(type.name) ? type.name[0] : type.name,
          })),
        };
      },

      //     for (const langObj of data) {
      //         // Ensure the name field is in lowercase
      //         langObj.name = langObj.name.toLowerCase();

      //         for (const targetLang of data) {
      //             const translation = await this.languageTransfer(langObj.name, targetLang.code);

      //             if (targetLang.code === "en") {
      //                 langObj[targetLang.name.toLowerCase()] = capitalizeFirstLetter(translation);
      //             } else {
      //                 langObj[targetLang.name.toLowerCase()] = translation.toLowerCase();
      //             }
      //         }
      //     }
      //     return data;
      // }

      translateDataNameField(data, translator) {
        translator;
        return data.map((item) => {
          if (item.name) {
            return {
              ...item,
              name: this.matchFromStatic(item.name),
            };
          }
          return item;
        });
      },

      async addTranslationsSame(data) {
        const capitalizeFirstLetter = (str) =>
          str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

        // Ensure the `name` field is lowercase
        data.forEach((langObj) => {
          langObj.name = langObj.name.toLowerCase();
        });

        const names = data.map((langObj) => langObj.name); // Collect all names in lowercase

        for (const targetLang of data) {
          if (targetLang.code === 'en') {
            // Directly assign English names to the corresponding field
            //Pleasssss
            data.forEach((langObj) => {
              langObj[targetLang.name.toLowerCase()] = capitalizeFirstLetter(
                langObj.name
              );
            });
            continue;
          }

          try {
            // Make a batch API call for other languages
            const translations = await this.languageTransferArray(
              names,
              targetLang.code
            );

            // Assign the translations to the corresponding language objects
            data.forEach((langObj, index) => {
              langObj[targetLang.name.toLowerCase()] = capitalizeFirstLetter(
                translations[index]
              );
            });
          } catch (error) {
            console.error(`Error translating to ${targetLang.code}:`, error);
            // Assign default value or leave it blank if translation fails
            data.forEach((langObj) => {
              langObj[
                targetLang.name.toLowerCase()
              ] = `Error: Translation unavailable`;
            });
          }
        }

        return data;
      },
      async customsBulkPatchRoles(dataRole) {
        // Dynamically construct filterQuery
        const filterQuery = {};

        if (dataRole.module) {
          filterQuery.module = dataRole.module;
        }

        if (dataRole.section) {
          filterQuery.section = dataRole.section;
        }

        if (dataRole.subSection) {
          filterQuery.subSection = dataRole.subSection;
        }

        const filterSettings = {
          type: 'filter',
          module: 'rolesetting',
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 50,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 5,
          filterQuery, // Dynamically constructed
          searchQuery: '',
        };

        try {
          const response = await UPCAxiosInstance.post(
            `util/filterData`,
            filterSettings
          );

          if (response?.data?.data?.length) {
            var filadArray = response.data.data;
            // Iterate through all objects in the array
            filadArray.forEach((permissionValue) => {
              // Ensure the segment exists
              // if (!permissionValue.permission[dataRole.segment]) {
              //   permissionValue.permission[dataRole.segment] = {};
              // }

              // // Create or update the customName key dynamically
              // if (
              //   !permissionValue.permission[dataRole.segment][dataRole.name]
              // ) {
              //   permissionValue.permission[dataRole.segment][dataRole.name] = {
              //     name: dataRole.customName,
              //     edit: true,
              //     view: true,
              //     all: true,
              //   };
              // } else {
              // Update existing key
              const permission =
                permissionValue.permission[dataRole.segment][dataRole.name];
              permission.name = dataRole.customName;
              permission.edit = true;
              permission.view = true;
              permission.all = true;
              // }
            });

            console.log('Updated Permission Values:', filadArray);
            if (filadArray.length > 0) {
              const response1 = await UPCAxiosInstance.patch(
                `/roleSetting/bulkupdate`,
                filadArray
              );
              console.log('Response from bulkPatch:', response1);
            }
          } else {
            console.warn('No data found in response:', filadArray);
          }
        } catch (error) {
          console.error('Error in customsBulkPatchRoles:', error);
        }
      },

      async customsBulkPatchRolesEdit(dataRole) {
        // Dynamically construct filterQuery
        console.log('dataRole:::', dataRole);

        const filterQuery = {};

        if (dataRole.module) {
          filterQuery.module = dataRole.module;
        }

        if (dataRole.section) {
          filterQuery.section = dataRole.section;
        }

        if (dataRole.subSection) {
          filterQuery.subSection = dataRole.subSection;
        }

        const filterSettings = {
          type: 'filter',
          module: 'rolesetting',
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 50,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 5,
          filterQuery, // Dynamically constructed
          searchQuery: '',
        };

        try {
          const response = await UPCAxiosInstance.post(
            `util/filterData`,
            filterSettings
          );

          if (response?.data?.data?.length) {
            const filadArray = response.data.data;

            // Iterate through all objects in the array
            filadArray.forEach((permissionValue) => {
              // Ensure `permission` exists and is an object
              if (
                permissionValue?.permission &&
                typeof permissionValue.permission === 'object'
              ) {
                const { segment, exitName, name, customName } = dataRole;

                // Access the segment in the permission object
                if (permissionValue.permission[segment]) {
                  const segmentPermissions =
                    permissionValue.permission[segment];

                  // Delete the existing field if it matches `exitName`
                  if (segmentPermissions[exitName]) {
                    console.log(`Deleting existing field: ${exitName}`);
                    delete segmentPermissions[exitName];
                  }

                  // Check if the new key exists or needs to be created
                  if (!segmentPermissions[name]) {
                    console.log(`Creating new permission for: ${name}`);
                    segmentPermissions[name] = {
                      name: customName,
                      edit: true,
                      view: true,
                      all: true,
                    };
                  } else {
                    console.log(`Updating permission for: ${name}`);
                    const permission = segmentPermissions[name];
                    permission.name = customName;
                    permission.edit = true;
                    permission.view = true;
                    permission.all = true;
                  }
                } else {
                  console.error(
                    `Segment '${segment}' not found in permission.`
                  );
                }
              } else {
                console.error(
                  'Invalid permission structure in:',
                  permissionValue
                );
              }
            });
            if (filadArray.length > 0) {
              const response1 = await UPCAxiosInstance.patch(
                `/roleSetting/bulkupdate`,
                filadArray
              );
              console.log('Response from bulkPatch:', response1);
            }

            console.log('Updated Permission Values:', filadArray);
          }
        } catch (error) {
          console.error('Error in customsBulkPatchRoles:', error);
        }
      },
      async customsBulkPatchRolesDelete(dataRole) {
        // Dynamically construct filterQuery
        console.log('dataRole:::', dataRole);

        const filterQuery = {};

        if (dataRole.module) {
          filterQuery.module = dataRole.module;
        }

        if (dataRole.section) {
          filterQuery.section = dataRole.section;
        }

        if (dataRole.subSection) {
          filterQuery.subSection = dataRole.subSection;
        }

        const filterSettings = {
          type: 'filter',
          module: 'rolesetting',
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 50,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 5,
          filterQuery, // Dynamically constructed
          searchQuery: '',
        };

        try {
          const response = await UPCAxiosInstance.post(
            `util/filterData`,
            filterSettings
          );

          if (response?.data?.data?.length) {
            const filadArray = response.data.data;

            // Iterate through all objects in the array
            filadArray.forEach((permissionValue) => {
              // Ensure `permission` exists and is an object
              if (
                permissionValue?.permission &&
                typeof permissionValue.permission === 'object'
              ) {
                const { segment, name } = dataRole;

                // Access the segment in the permission object
                if (permissionValue.permission[segment]) {
                  const segmentPermissions =
                    permissionValue.permission[segment];

                  // Delete the existing field if it matches `exitName`
                  if (segmentPermissions[name]) {
                    console.log(`Deleting existing field: ${name}`);
                    delete segmentPermissions[name];
                  }
                } else {
                  console.error(
                    `Segment '${segment}' not found in permission.`
                  );
                }
              } else {
                console.error(
                  'Invalid permission structure in:',
                  permissionValue
                );
              }
            });
            if (filadArray.length > 0) {
              const response1 = await UPCAxiosInstance.patch(
                `/roleSetting/bulkupdate`,
                filadArray
              );
              console.log('Response from bulkPatch:', response1);
            }

            console.log('Updated Permission Values:', filadArray);
          }
        } catch (error) {
          console.error('Error in customsBulkPatchRoles:', error);
        }
      },

      async translateStaticDataDays(staticDataDays1, targetLanguage) {
        const daysArray = Object.keys(staticDataDays1).map(
          (day) => staticDataDays1[day]
        );

        // Translate the array of days
        const translatedDays = await this.languageTransferArray(
          daysArray,
          targetLanguage
        );

        // Map the translated days back into the same object structure
        const translatedStaticDataDays = Object.keys(staticDataDays1).reduce(
          (result, day, index) => {
            result[day] = translatedDays[index];
            return result;
          },
          {}
        );

        console.log('poiuytrewq:', translatedStaticDataDays);

        return translatedStaticDataDays;
      },

      loadStaticData(uniqueID, languageAbility2) {
        languageAbility2;

        try {
          // To retrieve rems later:
          let languageAbility = JSON.parse(
            localStorage.getItem('userLanguageFromSetting')
          );
          console.log(
            'Retrieved languageAbility2 from cache:::',
            // languageAbility[0].name,
            uniqueID,
            languageAbility
          );

          // Retrieve cached data from localStorage
          const cachedRems = JSON.parse(localStorage.getItem('remsCache'));
          if (!cachedRems || !Array.isArray(cachedRems)) {
            console.error('Cached data is missing or invalid.');
            return null;
          }

          // Check if the languageAbility is provided and valid
          if (
            !languageAbility ||
            !Array.isArray(languageAbility) ||
            !languageAbility[0]?.code
          ) {
            console.error('Language ability is missing or invalid.');
            return null;
          }

          const preferredLanguage = languageAbility[0].code; // e.g., 'fr'

          // Find the matching item by uniqueID
          const matchedItem = cachedRems.find(
            (item) => item.uniqueID?.toLowerCase() === uniqueID?.toLowerCase()
          );

          if (!matchedItem) {
            console.error(`No item found for uniqueID: ${uniqueID}`);
            return null;
          }

          // Return the label for the preferred language
          const languageData = matchedItem[preferredLanguage];
          if (!languageData || !languageData.label) {
            console.error(
              `No data found for the preferred language: ${preferredLanguage}`
            );
            return null;
          }

          console.log('Retrieved languageData:::123', languageData);

          return languageData.label;
        } catch (error) {
          console.error('error in loadStaticData:', uniqueID);
        }
      },

      translateDateToLanguage(dateString) {
        // Split the input date string
        const dateParts = dateString.split(/[\s,]+/);

        // Translate each part
        const translatedParts = dateParts.map((part) =>
          this.matchFromStatic(part)
        );

        // Reconstruct the translated date string
        let r = translatedParts.join(' ');
        console.log('safszf:', r);
        return r.replace(/"/g, '');
      },

      matchFromStatic(searchTerm, data1, languageAbility2) {
        try {
          if (searchTerm) languageAbility2;
          data1;

          let data = JSON.parse(localStorage.getItem('remsCache'));
          // console.log("Retrieved data from cache:::", data);
          if (
            data === null ||
            data === 'null' ||
            data === undefined ||
            data === 'undefined' ||
            data === ''
          ) {
            data = [];
          }

          let languageAbility = [];
          let newsq = localStorage.getItem('userLanguageFromSetting') || [
            { name: 'english', code: 'en' },
          ];

          if (
            newsq === null ||
            newsq === 'null' ||
            newsq === undefined ||
            newsq === 'undefined' ||
            newsq === ''
          ) {
            languageAbility = [{ name: 'english', code: 'en' }];
          } else {
            languageAbility = JSON.parse(newsq);
          }

          // Find the object in the data array that matches the search term in the 'en.label' field
          const matchedObject = data.find(
            (item) =>
              item.en &&
              item.en.label.toLowerCase() === searchTerm.toLowerCase()
          );

          // If no match is found, return null
          if (!matchedObject) {
            // console.log("no match matchFromStatic:::", searchTerm);
            return searchTerm;
          }

          // Iterate through languageAbility to find the desired translation
          for (const lang of languageAbility) {
            const translation = matchedObject[lang.code]?.label;
            if (translation) {
              // console.log("try matchFromStatic:::", translation, searchTerm);
              return translation;
            }
          }

          // If no translation is found, return null
          // console.log("null matchFromStatic:::", searchTerm);
          return searchTerm;
        } catch (error) {
          console.error('Error matchFromStatic:::', error);
          return searchTerm;
        }
      },

      async translateSingleRefPatch(data) {
        console.log('data:::1333', data);

        try {
          // Get the language abilities from local storage
          let languageAbility = JSON.parse(
            localStorage.getItem('loginTenantDetailsLocal')
          ).languageAbility;

          // Iterate through each language in languageAbility
          for (let i = 0; i < languageAbility.length; i++) {
            const lang = languageAbility[i];
            const langCode = lang.code;
            const langName = lang.name.toLowerCase(); // Use the name as the key
            console.log('langCode:::', langCode);

            // Skip if the translation already exists in data for this language
            if (data[langName]) {
              console.log(`${langName} translation already exists.`);
              continue; // Skip to the next language
            }

            // Translate the 'english' key into the current language
            const translation = await this.languageTransfer(
              data.english || data.English,
              langCode
            );
            console.log('const translation123', translation);

            // Add the translation to the data object using the language name as the key
            data[langName] = translation;
          }

          return data;
        } catch (error) {
          console.error('Error in translateSingleRefPatch:::', error);
          return data;
        }
      },

      async addTranslationsForSQ(data, languages) {
        // console.log("nhfgbvdnjhfgbvdc",data, languages);

        try {
          // Validate input data
          if (!Array.isArray(data)) {
            throw new TypeError('Input data must be an array.');
          }
          if (!Array.isArray(languages)) {
            throw new TypeError('Languages must be an array.');
          }

          const updatedData = await Promise.all(
            data.map(async (item) => {
              if (!item || typeof item !== 'object') {
                console.error('Invalid item encountered:', item);
                return item; // Skip invalid items
              }

              const updatedItem = { ...item }; // Shallow copy to avoid mutation

              const translationPromises = languages.map(async (language) => {
                try {
                  if (!language || !language.code || !language.name) {
                    console.warn(
                      'Invalid language object encountered:',
                      language
                    );
                    return;
                  }

                  const langCode = language.code;

                  if (!updatedItem[langCode]) {
                    const labelSource = updatedItem?.en?.label;
                    // const tooltipSource = updatedItem?.en?.tooltip;

                    if (!labelSource) {
                      console.warn(
                        `Missing source text for 'en' in item:`,
                        updatedItem
                      );
                      return;
                    }

                    const label = await this.languageTransfer(
                      labelSource,
                      langCode
                    );
                    // console.log("ujytgrjuytgr",labelSource, langCode);

                    updatedItem[langCode] = {
                      label: label || labelSource, // Fallback to source label
                      name: language.name.toLowerCase(),
                      // tooltip: tooltip || tooltipSource, // Fallback to source tooltip
                    };
                  }
                } catch (languageError) {
                  console.error(
                    `Error translating to ${language?.code || 'unknown'}:`,
                    languageError
                  );
                }
              });

              await Promise.all(translationPromises); // Wait for all translations
              return updatedItem;
            })
          );

          return updatedData;
        } catch (error) {
          console.error('Error in addTranslationsForSQ:', error);
          throw error; // Rethrow to allow upstream error handling
        }
      },

      async patchAllReference() {
        await this.$store.dispatch('loginTenantDetails');
        console.log(
          'loginTenantDetails response:',
          this.$store.getters.loginTenantDetails
        );

        const payload1 = {
          type: 'filter',
          module: 'reference',
          isPaginated: false,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {
            updatedAt: -1,
          },
          limit: 10,
          filterQuery: {},
          searchQuery: '',
          company: this.$cookies.get('company'),
        };

        console.log('payload:', payload1);

        let response = await DMNAxiosInstance.post('util/filterData', payload1);
        const data = response?.data?.data?.data;

        console.log('References:', data);

        const validNames = new Set([
          'organisation',
          'party',
          'productoffering',
          'productcategory',
          'productqualification',
          'address',
          'geojson',
          'customerbill',
          'dependentflow',
          'serviceinventory',
          'discountoffers',
          'quotes',
          'tax',
          'productstock',
          'ticket',
          'tickettemplate',
          'servicespecification',
          'resourcespecification',
          'productspecification',
          'productorder',
          'resourceorder',
          'serviceorder',
          'log',
          'salesopportunity',
          'saleslead',
          'country',
          'quote',
          'countryinfo',
          'iconpackage',
          'Reasonref',
        ]);

        if (data) {
          for (let singleObj of data) {
            console.log('Single reference:', singleObj);

            if (!validNames.has(singleObj?.name?.toLowerCase())) {
              console.log('Not a valid reference name:', singleObj?.name);

              const newPayload = {
                type: 'filter',
                module: 'referencedata',
                collection: singleObj?.name,
                isPaginated: false,
                paginatedQuery: {
                  rowsPerPage: 10,
                  currentPage: 1,
                },
                isNeedToBeSort: false,
                sortFieldAndOrder: {},
                limit: 10,
                filterQuery: {},
                searchQuery: '',
                company: this.$cookies.get('company'),
              };

              console.log('New Payload for reference collection:', newPayload);

              let response1 = await DMNAxiosInstance.post(
                'util/filterData',
                newPayload
              );
              const referenceData = response1?.data?.data?.data;

              console.log('Single reference data:', referenceData);

              if (referenceData) {
                for (let singleObj1 of referenceData) {
                  console.log('Single schema from reference:', singleObj1);

                  const patchData = await this.lookupSingleReferenceTranslate(
                    singleObj1,
                    this.$store?.getters?.loginTenantDetails?.languageAbility
                  );
                  console.log('Patch data:', patchData);

                  if (patchData) {
                    // Uncomment this when ready to make the patch request
                    let responses = await DMNAxiosInstance.patch(
                      `mapRef/updateCollection/${singleObj?.name}/${singleObj1?._id}`,
                      patchData
                    );
                    console.log('Patch Data to send:', responses?.data);
                  } else {
                    console.log('No patch data to send for:', singleObj1);
                  }
                }
              } else {
                console.log(
                  'No data found for reference collection:',
                  singleObj?.name
                );
              }
            } else {
              console.log('Already present in valid names:', singleObj?.name);
            }
          }
        } else {
          console.log('No references found.');
        }
      },

      async patchAllSchema() {
        try {
          // Attempt to dispatch the loginTenantDetails action
          await this.$store.dispatch('loginTenantDetails');
          let language =
            this.$store.getters.loginTenantDetails?.languageAbility;

          if (!language) {
            throw new Error('Language ability is not available');
          }

          // Construct the language map
          const languageMap = language.reduce((acc, lang) => {
            acc[lang.name.toLowerCase()] = { type: 'String', required: false };
            return acc;
          }, {});
          language = languageMap;

          // Prepare the payload for API request
          let payload1 = {
            type: 'filter',
            module: 'schema',
            isPaginated: false,
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: false,
            sortFieldAndOrder: {},
            limit: 10,
            filterQuery: {},
            searchQuery: '',
            company: this.$cookies.get('company'),
          };

          // Send the API request to fetch the schema data
          let response = await DMNAxiosInstance.post(
            'util/filterData',
            payload1
          );

          if (!response || !response.data || !response.data.data) {
            throw new Error('Invalid response from the server');
          }

          let result2 = response.data.data.data;
          if (!result2 || result2.length === 0) {
            console.log('No schema data found.');
            return;
          }

          // Process each schema and patch it
          const patchPromises = result2.map(async (singleObj) => {
            try {
              let getSingleSchema = await this.getSingleSchema(singleObj?._id);
              if (!getSingleSchema) {
                throw new Error(`Schema not found for ID: ${singleObj?._id}`);
              }

              let patchSchema = await this.patchSchema(
                getSingleSchema.name,
                getSingleSchema.schema,
                getSingleSchema.description,
                singleObj?._id,
                language
              );
              if (!patchSchema) {
                throw new Error(
                  `Failed to patch schema for ID: ${singleObj?._id}`
                );
              }
              return patchSchema;
            } catch (error) {
              console.error(
                `Error processing schema for ID: ${singleObj?._id}`,
                error
              );
            }
          });

          // Wait for all patching operations to complete
          await Promise.all(patchPromises);

          console.log('big patch Done');
        } catch (error) {
          console.error('Error during patchAllSchema execution:', error);
        }
      },

      async getSingleSchema(id) {
        console.log('t3t3t3 getSingleSchema', id);

        try {
          let response = await DMNAxiosInstance.get(
            `/rule_engine/schema/${id}`
          );
          console.log('t3t3t3 response of getSingleSchema', response);

          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.name
          ) {
            let obj = {
              name: response.data.data.name,
              schema: response.data.data.schema,
              description: response.data.data.description,
            };
            console.log('t3t3t3 tttttt', obj);
            return obj;
          } else {
            return null;
          }
        } catch (error) {
          console.error('Error fetching schema:', error);
          return null;
        }
      },

      async patchSchema(name, schema, description1, id, language) {
        const lowercaseOverlapKeys = (schema, language) => {
          // Convert language keys to lowercase for comparison
          const languageKeysLower = Object.keys(language).map((key) =>
            key.toLowerCase()
          );

          // Process schema
          return Object.keys(schema).reduce((acc, key) => {
            const lowerKey = key.toLowerCase();
            if (languageKeysLower.includes(lowerKey)) {
              // Lowercase only if the key is in language (case-insensitive match)
              acc[lowerKey] = schema[key];
            } else {
              // Retain original key if no match in language
              acc[key] = schema[key];
            }
            return acc;
          }, {});
        };

        console.log('patchSchema', name, schema, description1, id, language);

        let normalizedSchema = lowercaseOverlapKeys(schema, language);

        // Merge language and normalized schema after processing
        let payload = {
          name: name,
          description: description1,
          schema: { ...language, ...normalizedSchema }, // Merge language and schema
          type: 'manual',
        };

        console.log('payload of patchSchema', payload);

        // Check if required data is present in the schema
        let bools = this.checkDataPresence(payload?.schema, language);
        if (bools) {
          try {
            // Send the PATCH request
            let response = await DMNAxiosInstance.patch(
              `/rule_engine/schema/${id}`,
              payload
            );

            // Check if the response status is not successful
            if (response.status >= 400) {
              console.error(
                `Error: ${response.status} - ${response.statusText}`
              );
              return { error: `Server error: ${response.statusText}` };
            }

            console.log('response of patchSchema', response);
            return response;
          } catch (error) {
            // Handle network errors or request issues
            if (error.response) {
              // The request was made, but the server responded with a status code outside 2xx
              console.error('API error response:', error.response);
              return { error: `API error: ${error.response.statusText}` };
            } else if (error.request) {
              // The request was made, but no response was received
              console.error('No response received from server:', error.request);
              return {
                error:
                  'No response from server, check your network connection.',
              };
            } else {
              // Something else happened (e.g., setup or configuration issue)
              console.error('Request setup error:', error.message);
              return { error: `Request setup failed: ${error.message}` };
            }
          }
        } else {
          console.warn('Data presence check failed');
          return { error: 'Invalid data: Missing required schema values.' }; // Return an error message if data check fails
        }
      },

      checkDataPresence(object, news) {
        // Define the data you want to check
        const dataToCheck = news;

        // Check each key in the data to be checked
        for (let key in dataToCheck) {
          // If the key does not exist in the object or its structure does not match, return false
          if (
            !object[key] ||
            object[key].type !== dataToCheck[key].type ||
            object[key].required !== dataToCheck[key].required
          ) {
            return false;
          }
        }
        // If all keys and structures match, return true
        return true;
      },

      async lookupSingleReferenceTranslate(data, languageAbility) {
        const filteredData = { ...data };
        delete filteredData._id;
        delete filteredData.__v;

        const fieldValue =
          filteredData.name ||
          filteredData.Name ||
          filteredData.value ||
          filteredData.Value;

        if (fieldValue) {
          const translations = {};

          for (const language of languageAbility) {
            const { code, name } = language;

            const translatedValue = await this.languageTransfer(
              String(fieldValue),
              code
            );
            translations[name.toLowerCase()] = translatedValue;
          }

          return { ...filteredData, ...translations };
        } else {
          console.log(
            'No name or value is present in the schema from the single reference.'
          );
        }

        return filteredData;
      },

      capitalizeFirstLetter(string) {
        if (!string) return '';
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
      },
      async supplierFilterData() {
        let filterLayout = {
          layoutFor: 'productoffering',
          filterQuery: {
            id: {
              $nin: [],
            },
          },
          layout: [
            {
              prop: 'lifecycleStatus',
              label: 'Status',
              type: 'String',
              fieldToFilter: 'lifecycleStatus',
            },
            {
              prop: 'category',
              label: 'Category',
              type: 'Array',
              // fieldToFilter: "name"
            },
            {
              prop: 'productOfferingPrice',
              label: 'price',
              type: 'Array',
              fieldToFilter: 'name',
            },
          ],
        };
        // console.log("kjhfj");
        const supplierFilterRes = await UPCAxiosInstance.post(
          'util/filterLayout',
          filterLayout
        );
        // state.suppliersFilterData = supplierFilterRes.data;
        // console.log("productRecommed 3.0 supplierfilterdata ", supplierFilterRes);
        // console.log("productRecommed 3.0 2", supplierFilterRes?.data);

        return await supplierFilterRes?.data;
      },

      // iterateAllRegion() {

      // },

      async callFilterInclusion(type, characteristic) {
        type, characteristic;
        console.log();

        let data = '';
        let type2 = '';
        if (type == 'Province') {
          // data = 'Province';
          data = characteristic?.state || '';
          type2 = 'stateOrProvince';
        } else if (type == 'City') {
          // data = 'City';
          data = characteristic?.city || '';
          type2 = 'city';
        } else if (type == 'Postal code') {
          // data = 'Postal code';
          data = characteristic?.postalCode || '';
          type2 = 'postalCode';
        }
        data;
        type2;

        let filterQueryforBill = {
          type: 'filter-search',
          module: 'address',
          isPaginated: false,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {},
          limit: 10,
          filterQuery: {
            'criteria.type': {
              $in: [type],
            },
            type: {
              $in: ['inclusion'],
            },
            [type2]: {
              $in: [data],
            },
          },
          searchQuery: '',
          company: this.$cookies.get('company'),
        };

        filterQueryforBill;
        console.log('filterQueryforBill:::', filterQueryforBill);

        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          filterQueryforBill
        );
        filterResultData;
        console.log('filterResultData:::', filterResultData);

        if (filterResultData?.data?.data?.length > 0) {
          return true;
        } else {
          return false;
        }
      },

      async callFilterExclusion(type, criteria, characteristic) {
        type, criteria, characteristic;
        let data = '';
        let type2 = '';
        if (type == 'Province') {
          // data = 'Province';
          data = characteristic?.state || '';
          type2 = 'stateOrProvince';
        } else if (type == 'City') {
          // data = 'City';
          data = characteristic?.city || '';
          type2 = 'city';
        } else if (type == 'Postal code') {
          // data = 'Postal code';
          data = characteristic?.postalCode || '';
          type2 = 'postalCode';
        } else if (type == 'street') {
          // data = 'street';
          data = characteristic?.street || '';
          type2 = 'street';
        } else if (type == 'Prefix (FSA)') {
          // data = 'Prefix (FSA)';
          data = characteristic?.prefix || '';
          type2 = 'postalCodePrefix';
        } else if (type == 'Partial address') {
          if (criteria == 'Street name + Prefix') {
            // data = 'Partial address';
            data = characteristic?.street || '';
            type2 = 'street';
          } else if (criteria == 'Street name + Postal code') {
            // data = 'Partial address';
            data = characteristic?.street || '';
            type2 = 'street';
          } else if (criteria == 'City + Postal code') {
            // data = 'Partial address';
            data = characteristic?.city || '';
            type2 = 'city';
          } else if (criteria == 'City + Prefix') {
            // data = 'Partial address';
            data = characteristic?.city || '';
            type2 = 'city';
          }
          // // data = 'Partial address';
          // data = characteristic.partialAddress
          // type2 = 'partialAddress'
        }
        data;
        type2;

        let filterQueryforBill = {
          type: 'filter',
          module: 'address',
          isPaginated: false,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {},
          limit: 10,
          filterQuery: {
            'criteria.typeExclude': {
              $in: [type],
            },
            type: {
              $in: ['exclusion'],
            },
            description: {
              $in: [characteristic?.description || ''],
            },
            parentId: {
              $in: [`${this.$route.query.id}`],
            },
            [type2]: {
              $in: [data],
            },
          },
          searchQuery: '',
          company: this.$cookies.get('company'),
        };

        filterQueryforBill;
        console.log('filterQueryforBill:::', filterQueryforBill);

        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          filterQueryforBill
        );
        filterResultData;
        console.log('filterResultData:::', filterResultData);

        if (filterResultData?.data?.data?.length > 0) {
          return true;
        } else {
          return false;
        }
      },

      removeFirstComma(input) {
        if (typeof input !== 'string') {
          return input || ''; // Return an empty string if input is undefined/null, or the input itself if it's a non-string.
        }
        return input.replace(',', '');
      },

      updateRegionNames(data) {
        // Loop through each key in the object
        for (const key in data) {
          // Check if the current key's value is an array
          if (Array.isArray(data[key])) {
            // Loop through the array of objects under each key
            data[key].forEach((item) => {
              try {
                // Check if 'name' is an array and has at least one element
                if (Array.isArray(item.name) && item.name.length > 0) {
                  // Set 'name' to the first element as a string
                  item.name = item.name[0];
                } else {
                  throw new Error(
                    `Invalid 'name' value in item: ${JSON.stringify(item)}`
                  );
                }
              } catch (error) {
                console.error(
                  `Error processing item in ${key}:`,
                  error.message
                );
              }
            });
          } else {
            console.error(
              `Expected an array for key: ${key}, but found:`,
              data[key]
            );
          }
        }
        return data;
      },

      addFilterQuery(input, key, value) {
        // Ensure input is a valid object
        if (typeof input !== 'object' || input === null) {
          throw new Error('Invalid input: Expected an object.');
        }

        // Ensure filterQuery exists in the input
        if (!input.filterQuery || typeof input.filterQuery !== 'object') {
          input.filterQuery = {};
        }

        // Add or merge the new filter
        input.filterQuery[key] = {
          $in: Array.isArray(value) ? value : [value],
        };

        return input?.filterQuery;
      },

      async deleteRegionRelation(id) {
        id;
        await UPCAxiosInstance.delete(`/geographicAddress/${id}`);

        let filter = {
          type: 'filter',
          module: 'address',
          isPaginated: false,
          paginatedQuery: {
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currPage,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {},
          limit: 10,
          filterQuery: {
            type: {
              $in: ['exclusion'],
            },
            parentId: {
              $in: [`${id}`],
            },
          },
          searchQuery: '',
          company: this.$cookies.get('userName'),
        };

        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          filter
        );

        for (let i = 0; i < filterResultData?.data?.data?.length; i++) {
          let obj1 = filterResultData.data.data[i];
          obj1;

          await UPCAxiosInstance.delete(`/geographicAddress/${obj1?.id}`);
        }

        let filter12 = {
          type: 'filter',
          module: 'productQualification',
          isPaginated: false,
          paginatedQuery: {
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currPage,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {},
          limit: 10,
          filterQuery: {
            'place.id': {
              $in: [`${id}`],
            },
          },
          searchQuery: '',
          company: this.$cookies.get('userName'),
        };

        const filterResultData12 = await UPCAxiosInstance.post(
          `util/filterData`,
          filter12
        );

        for (let j = 0; j < filterResultData12.data.data.length; j++) {
          let obj12 = filterResultData12.data.data[j];
          obj12;
          console.log('each obj12::', obj12);

          await UPCAxiosInstance.delete(`/productQualification/${obj12?.id}`);
        }
      },

      async parentRegionList() {
        let filter = {
          type: 'filter',
          module: 'address',
          isPaginated: false,
          paginatedQuery: {
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currPage,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {},
          limit: 10,
          filterQuery: {
            type: {
              $in: ['inclusion'],
            },
          },
          searchQuery: '',
          company: this.$cookies.get('company'),
        };

        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          filter
        );

        console.log('qwertyuiop::', filterResultData, filter);

        if (
          filterResultData.data.data != undefined &&
          filterResultData.data.total != 0
        ) {
          let val = filterResultData.data.data.map((itm) => ({
            name: itm?.criteria?.regionName,
            value: itm,
          }));

          return val;
        }
      },

      async productList() {
        let filter = {
          type: 'filter',
          module: 'productQualification',
          isPaginated: false,
          paginatedQuery: {
            rowsPerPage: this.rowsPerPage,
            currentPage: this.currPage,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {},
          limit: 10,
          filterQuery: {},
          searchQuery: '',
          company: this.$cookies.get('userName'),
        };

        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          filter
        );

        if (
          filterResultData.data.data != undefined &&
          filterResultData.data.total != 0
        ) {
          // let val = filterResultData.data.data.map(itm => ({id:itm?.id, value: itm?.streetAddressLine[0].characteristic, name:itm?.streetAddressLine[0].characteristic.regionName }))

          return filterResultData.data.data;
        }
      },

      async translateDataForStatic(data) {
        let preferredLanguages = JSON.parse(
          localStorage.getItem('loginTenantDetailsLocal')
        ).languageAbility;

        // Iterate over the preferred languages array
        for (const language of preferredLanguages) {
          const langCode = language.code;
          if (!data[langCode]) {
            // Avoid overwriting if translation already exists
            data[langCode] = {
              label: await this.languageTransfer(data.en.label, langCode),
              name: language.name.toLowerCase(), // Convert the name to lowercase
            };
          }
        }
        return data;
      },

      async languageTransfer(data, lang) {
        console.log('languageTransfer::', data, lang);

        try {
          // Ensure that data is a string
          if (typeof data !== 'string') {
            console.error('Data must be a string');
            return ''; // Or you could throw an error depending on your use case
          }

          const response = await axios.post(
            `https://translation.googleapis.com/language/translate/v2?key=${process.env.VUE_APP_TRANSLATE_KEY}`,
            { q: data, target: lang },
            { headers: { 'Content-Type': 'application/json' } }
          );

          const translations = response.data.data.translations.map(
            (t) => t.translatedText
          );

          return he.decode(translations[0] || '');
        } catch (error) {
          console.error('Translation error:', error);
          return '';
        }
      },

      async languageTransferArray(dataArray, lang) {
        console.log('languageTransfer::', dataArray, lang);

        try {
          // Ensure dataArray is an array
          if (!Array.isArray(dataArray)) {
            console.error('Data must be an array of strings');
            return []; // Or throw an error if required
          }

          // Limit to a maximum of 10 items
          const limitedData = dataArray.slice(0, 12);

          const response = await axios.post(
            `https://translation.googleapis.com/language/translate/v2?key=${process.env.VUE_APP_TRANSLATE_KEY}`,
            { q: limitedData, target: lang },
            { headers: { 'Content-Type': 'application/json' } }
          );

          const translations = response.data.data.translations.map((t) =>
            he.decode(t.translatedText || '')
          );

          return translations;
        } catch (error) {
          console.error('Translation error:', error);
          return [];
        }
      },

      async updateLangSchemaByModule(moduleName) {
        await this.$store.dispatch('loginTenantDetails');
        const preferredLanguages =
          this.$store?.getters?.loginTenantDetails?.languageAbility;
        console.log('rew:::', preferredLanguages);

        try {
          const response = await UPCAxiosInstance.get(
            `/customSchema/getSchema?module=${moduleName}`
          );
          const schemaData = await response?.data;
          console.log('skema rew:::getdata', schemaData);

          let data = await this.updateSchemaLabels(
            schemaData,
            preferredLanguages
          );

          const response1 = await UPCAxiosInstance.patch(`/customSchema`, data);
          response1;
          console.log('skema response1:', response1?.data);

          return response1;
        } catch (error) {
          console.error('Error fetching schema:', error);
          return null;
        }
      },

      async updateSchemaLabels(schemaData, preferredLanguages) {
        // Change schemaData._id to id
        if (schemaData._id) {
          schemaData.id = schemaData._id;
          delete schemaData._id;
        }

        const schema = schemaData.schema;

        for (const fieldKey in schema) {
          if (Object.prototype.hasOwnProperty.call(schema, fieldKey)) {
            const field = schema[fieldKey];

            // Add default fields if not present

            // Add default fields if not present
            if (!Object.prototype.hasOwnProperty.call(field, 'displayType'))
              field.displayType = null;
            if (!Object.prototype.hasOwnProperty.call(field, 'type'))
              field.type = null;
            if (!Object.prototype.hasOwnProperty.call(field, 'required'))
              field.required = false;
            if (!Object.prototype.hasOwnProperty.call(field, 'show'))
              field.show = true;
            if (!Object.prototype.hasOwnProperty.call(field, 'tooltipEnglish'))
              field.tooltipEnglish = '';
            if (!Object.prototype.hasOwnProperty.call(field, 'isTooltip'))
              field.isTooltip = true;
            if (!Object.prototype.hasOwnProperty.call(field, 'section'))
              field.section = [];
            if (!Object.prototype.hasOwnProperty.call(field, 'optionRef'))
              field.optionRef = 'referenceDataName';
            if (!Object.prototype.hasOwnProperty.call(field, 'isRef'))
              field.isRef = true;
            if (!Object.prototype.hasOwnProperty.call(field, 'tooltip'))
              field.tooltip = false;
            if (!Object.prototype.hasOwnProperty.call(field, 'System'))
              field.System = false;
            if (!Object.prototype.hasOwnProperty.call(field, 'others'))
              field.others = {};

            // Check if 'labels' exists, if not, create it
            if (!field.labels) {
              field.labels = {};
            }

            // Check if English label exists, if not, add it
            if (!field.labels.en) {
              field.labels.en = {
                label: fieldKey,
                name: 'English',
                tooltip: fieldKey,
              };
            }

            // Iterate over preferred languages
            for (const language of preferredLanguages) {
              const langCode = language.code;

              // If the preferred language does not exist, translate and add it
              if (!field.labels[langCode]) {
                const translatedLabel = await this.languageTransfer(
                  fieldKey,
                  langCode
                );
                //   console.log("translatedLabel:", translatedLabel);

                // Add translated label, name, and tooltip
                field.labels[langCode] = {
                  label: translatedLabel || fieldKey,
                  name: language.name,
                  tooltip: translatedLabel || fieldKey,
                };
              }
            }
          }
        }

        return schemaData;
      },

      websiteRegex(val) {
        const websiteRegex =
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

        if (val?.length >= 1) {
          if (!websiteRegex.test(val)) {
            return true; // URL is invalid
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
      mergeSidebarData(payload1, payload2) {
        // Handle null or undefined accessZone
        // if (!accessZone) {
        //   return {
        //     main: payload1.main.map((item) => ({
        //       ...item,
        //       ...(payload2[item.name] || {}),
        //     })),
        //   };
        // }

        // if (accessZone?.view === true) {
        //   return {
        //     main: payload1.main.map((item) => ({
        //       ...item,
        //       edit: false,
        //       delete: false,
        //       add: false,
        //       view: true,
        //     })),
        //   };
        // }

        // if (accessZone?.["view / edit"] === true) {
        //   return {
        //     main: payload1.main.map((item) => ({
        //       ...item,
        //       edit: true,
        //       delete: false,
        //       add: true,
        //       view: true,
        //     })),
        //   };
        // }

        // if (accessZone?.["view / edit / delete"] === true) {
        //   return {
        //     main: payload1.main.map((item) => ({
        //       ...item,
        //       edit: true,
        //       delete: true,
        //       add: true,
        //       view: true,
        //     })),
        //   };
        // }

        // If no accessZone conditions match, merge payload1 and payload2
        return {
          main: payload1.main.map((item) => ({
            ...item,
            ...(payload2[item.name] || {}),
          })),
        };
      },

      async languagetranslateTexts(texts, targetLang) {
        try {
          const response = await axios.post(
            `https://translation.googleapis.com/language/translate/v2?key=${process.env.VUE_APP_TRANSLATE_KEY}`,
            {
              q: texts, // The array of strings to be translated
              target: targetLang, // Target language code (e.g., "es" for Spanish)
            }
          );

          console.log(
            'language 111 translateTexts',
            response.data.data.translations.map((t) => t.translatedText)
          );

          return response.data.data.translations.map((t) => t.translatedText);
        } catch (error) {
          console.error('Translation error:', error);
          return texts; // Fallback to original text if translation fails
        }
      },
      async getReferanceData(routeName) {
        const rootName = this.$cookies.get('rootName');
        const statuslist =
          rootName === 'Halleyx'
            ? `mapRef/collection/${routeName}`
            : `mapRef/collection/${routeName}?company=${this.$cookies.get(
                'rootName'
              )}`;
        const referanceDatavalue = await DMNAxiosInstance.get(statuslist);
        // console.log(referanceDatavalue.data.data,"referanceDatavalue");

        return await referanceDatavalue.data.data;
      },

      loadGoogleMapsScript() {
        // const mapKey = process.env.VUE_APP_MAP_KEY;
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_KEY}&libraries=places`;
        script.async = true;
        document.head.appendChild(script);

        return new Promise((resolve) => {
          script.onload = () => {
            resolve();
          };
        });
      },

      async fetchCoordinatesPlugin(query) {
        // this.polygonPaths = [];
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/search.php?q=${query}&polygon_geojson=1&format=jsonv2`
          );
          const data = response.data;
          console.log('Fetched data:', data);
          let data1 = this.processCoordinates(data);

          console.log('adcsz log:', data1);

          return data1;
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      },

      processCoordinates(data) {
        if (data[0]) {
          let arrayOfArray = [];
          const coordinates = data[0].geojson.coordinates;
          let areaType = '';

          // Single coordinate pair
          if (
            coordinates.length === 2 &&
            typeof coordinates[0] === 'number' &&
            typeof coordinates[1] === 'number'
          ) {
            arrayOfArray.push([{ lat: coordinates[1], lng: coordinates[0] }]);
            console.log('Single coordinate:', coordinates);
            // this.polygoncheck = true;
            areaType = 'Single';
          }
          // Array of coordinate pairs
          else if (
            Array.isArray(coordinates) &&
            coordinates.every(
              (coord) => Array.isArray(coord) && coord.length === 2
            )
          ) {
            arrayOfArray.push(coordinates.map(([lng, lat]) => ({ lat, lng })));
            console.log('Array of coordinate pairs:', coordinates);
            // this.polygoncheck = true;
            areaType = 'Array';
          }
          // Nested array of coordinate pairs
          else if (
            Array.isArray(coordinates) &&
            Array.isArray(coordinates[0]) &&
            coordinates[0].every(
              (coord) => Array.isArray(coord) && coord.length === 2
            )
          ) {
            coordinates.forEach((nestedCoords) => {
              arrayOfArray.push(
                nestedCoords.map(([lng, lat]) => ({ lat, lng }))
              );
            });
            console.log('Nested array of coordinate pairs:', coordinates);
            // this.polygoncheck = false;
            areaType = 'Nested';
          }
          // Multi-level nested array
          else {
            coordinates.forEach((nestedCoords) => {
              arrayOfArray.push(
                nestedCoords[0].map(([lng, lat]) => ({ lat, lng }))
              );
            });
            console.log('Multi-level nested array:', coordinates);
            // this.polygoncheck = false;
            areaType = 'MultiNested';
          }

          console.log('areaType', areaType);
          console.log('Processed polygon paths:', arrayOfArray);

          let geoCode = {
            geodata: arrayOfArray,
            type: areaType,
          };
          // this.polygonPaths =
          // arrayOfArray = [];
          return geoCode;
          // this.$emit("polygonPaths", this.polygonPaths,areaType);
        }
      },

      formatCurrentTime() {
        const now = new Date();
        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, '0');
        const day = String(now.getUTCDate()).padStart(2, '0');
        const hours = String(now.getUTCHours()).padStart(2, '0');
        const minutes = String(now.getUTCMinutes()).padStart(2, '0');
        const seconds = String(now.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
      },

      async currentUser() {
        let userRes = await MLTAxiosInstance.get(
          `partymanagement/individual/${VueCookies.get(
            'user'
          )}?company=${VueCookies.get('company')}`
        );
        return userRes.data ? userRes.data : null;
      },
      findDifferences(array1, array2, keyField) {
        // Filter array1 to find objects that are not in array2
        const differences = array1.filter((obj1) => {
          // Check if there is no matching object in array2
          return !array2.some(
            (obj2) => obj1[keyField] === obj2[keyField]
            // && JSON.stringify(obj1) === JSON.stringify(obj2)
          );
        });

        return differences;
      },
      findDifferenceV1(oldArray, newArray, key) {
        // Create a map from the new array for quick lookup
        const newMap = new Map(newArray.map((item) => [item[key], item]));

        // Find objects to remove (present in oldArray but not in newArray)
        const toRemove = oldArray.filter((item) => !newMap.has(item[key]));

        // Find objects to add (present in newArray but not in oldArray)
        const oldMap = new Map(oldArray.map((item) => [item[key], item]));
        const toAdd = newArray.filter((item) => !oldMap.has(item[key]));

        return { toRemove, toAdd };
      },
      findPriceChanges(oldArray, newArray, keyValue, property) {
        // Create a map for quick lookup of oldArray prices
        const oldMap = new Map();

        oldArray.forEach((item) => {
          if (item.quoteItemPrice) {
            item.quoteItemPrice.forEach((priceItem) => {
              if (priceItem.price && priceItem.price[keyValue]) {
                oldMap.set(item.name, priceItem.price[keyValue].value);
              }
            });
          }
        });

        // Create a map for quick lookup of newArray prices
        const newMap = new Map();

        newArray.forEach((item) => {
          if (item.quoteItemPrice) {
            item.quoteItemPrice.forEach((priceItem) => {
              if (priceItem.price && priceItem.price[keyValue]) {
                newMap.set(item.name, priceItem.price[keyValue].value);
              }
            });
          }
        });

        // Find differences
        const results = [];

        newMap.forEach((newValue, name) => {
          if (oldMap.has(name)) {
            const oldValue = oldMap.get(name);
            if (oldValue !== newValue) {
              results.push({
                action: 'UPDATE',
                from: oldValue,
                to: newValue,
                property: property,
              });
            }
          }
        });

        return results;
      },
      compareArraysForUpdates(
        oldArray,
        newArray,
        keyVal,
        property,
        Addtitonal
      ) {
        const results = [];
        const oldMap = new Map(oldArray.map((item) => [item.id, item[keyVal]]));

        newArray.forEach((item) => {
          if (oldMap.has(item.id)) {
            const oldUNO = oldMap.get(item.id);
            if (oldUNO !== item[keyVal]) {
              const propertyValue =
                Addtitonal && item[Addtitonal]
                  ? `${item[Addtitonal]} - ${property}`
                  : property;
              results.push({
                action: 'UPDATE',
                from: oldUNO,
                to: item[keyVal],
                property: propertyValue,
              });
            }
          }
        });

        return results;
      },

      compareArraysByIndexAndName(oldArray, newArray, keyVal, property) {
        const results = [];

        // Check for added objects
        newArray.forEach((item, index) => {
          if (
            index >= oldArray.length ||
            oldArray[index][keyVal] !== item[keyVal]
          ) {
            results.push({
              action: 'ADDED',
              to: item[keyVal],
              property: property,
            });
          }
        });

        // Check for removed objects
        oldArray.forEach((item, index) => {
          if (
            index >= newArray.length ||
            newArray[index][keyVal] !== item[keyVal]
          ) {
            results.push({
              action: 'REMOVED',
              from: item[keyVal],
              property: property,
            });
          }
        });

        return results;
      },

      findDifferencePricingTire(initialPayload, updatedPayload) {
        const changes = [];

        // Map to track already matched items
        const usedItems = new Set();

        // Helper function to check if an item is modified (not completely new)
        function isItemModified(initialItem, updatedItem) {
          return (
            initialItem.price.value !== updatedItem.price.value ||
            initialItem.min !== updatedItem.min ||
            initialItem.max !== updatedItem.max
          );
        }

        // Loop through each initial item and compare with updated items
        initialPayload.forEach((initialItem) => {
          let matched = false;

          updatedPayload.forEach((updatedItem, index) => {
            if (!usedItems.has(index)) {
              // Check if there are any matches in min, max, or price
              if (
                initialItem.price.value === updatedItem.price.value ||
                initialItem.min === updatedItem.min ||
                initialItem.max === updatedItem.max
              ) {
                // Mark the item as used
                usedItems.add(index);
                matched = true;

                // Check if it's an edit
                if (isItemModified(initialItem, updatedItem)) {
                  // Capture individual changes
                  if (initialItem.price.value !== updatedItem.price.value) {
                    changes.push({
                      action: 'UPDATE',
                      property: 'Price',
                      from: initialItem.price.value,
                      to: updatedItem.price.value,
                    });
                  }

                  if (initialItem.min !== updatedItem.min) {
                    changes.push({
                      action: 'UPDATE',
                      property: 'Min',
                      from: initialItem.min,
                      to: updatedItem.min,
                    });
                  }

                  if (initialItem.max !== updatedItem.max) {
                    changes.push({
                      action: 'UPDATE',
                      property: 'Max',
                      from: initialItem.max,
                      to: updatedItem.max,
                    });
                  }
                }
              }
            }
          });

          // If no match found, consider it as deleted
          if (!matched) {
            changes.push({
              action: 'DELETED',
              property: 'Price',
              from: initialItem.price.value,
            });
            changes.push({
              action: 'DELETED',
              property: 'Min',
              from: initialItem.min,
            });
            changes.push({
              action: 'DELETED',
              property: 'Max',
              from: initialItem.max,
            });
          }
        });

        // Handle new additions
        updatedPayload.forEach((updatedItem, index) => {
          if (!usedItems.has(index)) {
            changes.push({
              action: 'ADDED',
              property: 'Price',
              to: updatedItem.price.value,
            });
            changes.push({
              action: 'ADDED',
              property: 'Min',
              to: updatedItem.min,
            });
            changes.push({
              action: 'ADDED',
              property: 'Max',
              to: updatedItem.max,
            });
          }
        });

        return changes;
      },

      findChangesNested(payload1, payload2) {
        const changes = [];

        // Create a map of payloads by label for easy lookup
        const mapPayload1 = new Map(
          payload1.map((item) => [item.label, item.additionalFields])
        );
        const mapPayload2 = new Map(
          payload2.map((item) => [item.label, item.additionalFields])
        );

        // Loop through payload1 to find removed and updated fields
        payload1.forEach((item1) => {
          const fields1 = item1.additionalFields;
          const fields2 = mapPayload2.get(item1.label) || [];

          // Find removed and updated fields
          fields1.forEach((field1) => {
            const field2 = fields2.find((f) => f.label === field1.label);

            if (!field2) {
              // Field removed
              changes.push({
                action: 'REMOVED',
                to: field1.label,
                property: item1.label,
              });
            } else if (field1.textEditor !== field2.textEditor) {
              // Field updated
              changes.push({
                action: 'UPDATED',
                to: field2.label,
                property: item1.label,
                from: field1.textEditor,
                toValue: field2.textEditor,
              });
            }
          });
        });

        // Loop through payload2 to find added fields
        payload2.forEach((item2) => {
          const fields2 = item2.additionalFields;
          const fields1 = mapPayload1.get(item2.label) || [];

          // Find added fields
          fields2.forEach((field2) => {
            const field1 = fields1.find((f) => f.label === field2.label);

            if (!field1) {
              // Field added
              changes.push({
                action: 'ADDED',
                to: field2.label,
                property: item2.label,
              });
            }
          });
        });

        return changes;
      },
      buildLogBasedOnDifferencesForArrayOfObjects(
        oldArray,
        newArray,
        key,
        property
      ) {
        const { toRemove = [], toAdd = [] } = this.findDifferenceV1(
          oldArray,
          newArray,
          key
        );
        const log = [];
        toRemove.forEach((item) => {
          log.push({ action: 'REMOVED', item, property });
        });
        toAdd.forEach((item) => {
          log.push({ action: 'ADDED', item, property });
        });
        return log;
      },
      buildLogBasedOnDifferencesForArrayOfObjectsVersion2(
        oldArray,
        newArray,
        key,
        property,
        objValue,
        action,
        displayID
      ) {
        const { toRemove = [], toAdd = [] } = this.findDifferenceV1(
          oldArray,
          newArray,
          key
        );
        const log = [];
        toRemove.forEach((item) => {
          log.push({
            action: 'REMOVED',
            item: item[objValue],
            property,
            renameAction: action ? action : null,
            displayID: displayID,
          });
        });
        toAdd.forEach((item) => {
          log.push({
            action: 'ADDED',
            item: item[objValue],
            property,
            renameAction: action ? action : null,
            displayID: displayID,
          });
        });
        console.log('buildLogBasedOnDifferencesForArrayOfObjects', log);
        return log;
      },
      buildBasicLogByDifferencesVersion2(
        oldData,
        newData,
        key,
        property,
        action,
        displayID
      ) {
        if (!key) return [];
        if (!oldData || !newData) return [];
        const log = [];
        if (oldData[key] && newData[key] && oldData[key] !== newData[key] && (oldData[key].length > 0 || newData[key].length > 0)) {
          log.push({
            action: action,
            from: oldData[key],
            to: newData[key],
            property,
            displayID: displayID || '-',
          });
        } else if (!oldData[key] && newData[key]) {
          log.push({
            action: action,
            to: newData[key],
            from: '-',
            property,
            displayID: displayID || '-',
          });
        } else if (oldData[key] && !newData[key]) {
          log.push({
            action: action,
            from: oldData[key],
            property,
            to: '-',
            displayID: displayID || '-',
          });
        }
        console.log(displayID, 'buildBasicLogByDifferencesVersion2', log);
        return log;
      },
      buildBasicLogByDifferences(oldData, newData, key, property) {
        if (!key) return [];
        if (!oldData || !newData) return [];
        const log = [];
        if (oldData[key] && newData[key] && oldData[key] !== newData[key]) {
          log.push({
            action: 'UPDATED',
            from: oldData[key],
            to: newData[key],
            property,
          });
        } else if (!oldData[key] && newData[key]) {
          log.push({ action: 'ADDED', to: newData[key], property });
        } else if (oldData[key] && !newData[key]) {
          log.push({ action: 'REMOVED', from: oldData[key], property });
        }
        return log;
      },
      buildBasicLogyesOrno(oldData, newData, key, property, Trueval, Falseval) {
        if (!key) return [];
        if (!oldData || !newData) return [];

        const log = [];

        // Handling true/false values
        const oldVal = oldData[key] ? Trueval : Falseval;
        const newVal = newData[key] ? Trueval : Falseval;

        if (oldVal && newVal && oldVal !== newVal) {
          log.push({
            action: 'UPDATED',
            from: oldVal,
            to: newVal,
            property,
          });
        } else if (!oldData[key] && newData[key]) {
          log.push({ action: 'ADDED', to: newVal, property });
        } else if (oldData[key] && !newData[key]) {
          log.push({ action: 'REMOVED', from: oldVal, property });
        }

        return log;
      },
      findArrayOfStringDifferences(initial, updated, property) {
        const removed = initial.filter((value) => !updated.includes(value));
        const added = updated.filter((value) => !initial.includes(value));
        const log = [];

        removed.forEach((item) => {
          log.push({ action: 'REMOVED', item, property });
        });
        added.forEach((item) => {
          log.push({ action: 'ADDED', item, property });
        });
        return log;
      },
      async initLog(log) {
        try {
          await UPCAxiosInstance.post('/activityLog', log);
        } catch (error) {
          console.error('Error logging:', error);
          return null;
        }
      },

      // Function to remove duplicates from an array of objects
      removeDuplicatesV1(array, key) {
        if (!key) return array;
        const mappedArray = new Map(array.map((c) => [c[key], c]));
        const uniqueValues = [...mappedArray.values()];
        return uniqueValues;
      },
      // Function to remove duplicates based on a property
      removeDuplicates(array, property) {
        try {
          const seen = new Set();
          return array.filter((item) => {
            const value = item[property];
            if (seen.has(value)) {
              return false;
            }
            seen.add(value);
            return true;
          });
        } catch (error) {
          return array;
        }
      },
      async capitalizeAndSort(inputPromise, key) {
        try {
          // Wait for the inputPromise to resolve
          const input = await inputPromise;

          // Check if the input is an array
          if (Array.isArray(input)) {
            if (input.length === 0) return input; // Handle empty array case

            // Case 1: Array of objects
            if (typeof input[0] === 'object' && !Array.isArray(input[0])) {
              // Check if the key is valid
              if (typeof key !== 'string' || key.trim() === '') {
                throw new Error('Invalid key provided');
              }

              // Sort the array of objects by the specified key
              input.sort((a, b) => {
                const aValue = a[key] || '';
                const bValue = b[key] || '';
                if (aValue < bValue) return -1;
                if (aValue > bValue) return 1;
                return 0;
              });

              // Capitalize the first letter of the key value for each object
              return input.map((item) => {
                if (item[key]) {
                  return {
                    ...item,
                    [key]:
                      item[key].charAt(0).toUpperCase() + item[key].slice(1),
                  };
                }
                return item; // Return the item unchanged if the key doesn't exist
              });
            }

            // Case 2: Array of strings
            if (typeof input[0] === 'string') {
              return input.map(
                (str) => str.charAt(0).toUpperCase() + str.slice(1)
              );
            }
          }

          // Case 3: Single object
          if (typeof input === 'object' && !Array.isArray(input)) {
            if (key && input[key]) {
              return {
                ...input,
                [key]: input[key].charAt(0).toUpperCase() + input[key].slice(1),
              };
            }
            return input; // Return the object unchanged if the key doesn't exist
          }

          // Case 4: Single string
          if (typeof input === 'string') {
            return input.charAt(0).toUpperCase() + input.slice(1);
          }

          throw new Error('Unsupported input type'); // Handle unsupported types
        } catch (error) {
          console.error('Error in processInput:', error.message);
          throw error; // Re-throw the error for further handling if needed
        }
      },

      convertTextTo(inputText, conversionType = 'pascalcase') {
        if (!inputText || typeof inputText != 'string') return inputText;
        switch (conversionType) {
          case 'lowercase':
            return inputText.toLowerCase();
          case 'uppercase':
            return inputText.toUpperCase();
          case 'snakecase':
            return inputText.replace(/\s+/g, '_').toLowerCase();
          case 'camelcase':
            return inputText
              .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                return index === 0 ? word.toLowerCase() : word.toUpperCase();
              })
              .replace(/\s+/g, '');
          case 'pascalcase':
            return inputText
              .replace(/\w+/g, function (word) {
                return (
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                );
              })
              .replace(/\s+/g, '');
          case 'pascalcaseWithSpace':
            return inputText.replace(/\w+/g, function (word) {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            });
          // .replace(/\s+/g, "");
          case 'kebabcase':
            return inputText.replace(/\s+/g, '-').toLowerCase();
          case 'lowerpascalcase':
            return (
              inputText.charAt(0).toLowerCase() +
              inputText
                .slice(1)
                .replace(/\s+/g, '')
                .replace(/\w+/g, function (word) {
                  return (
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  );
                })
            );
          case 'upperpascalcase':
            return (
              inputText.charAt(0).toUpperCase() +
              inputText
                .slice(1)
                .replace(/\s+/g, '')
                .replace(/\w+/g, function (word) {
                  return (
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  );
                })
            );
          default:
            return 'Invalid conversion type';
        }
      },
      // dateAndTimeFormatter(
      //   inputDate,
      //   outputFormat = "MMM DD, YYYY hh:mm AM/PM"
      // ) {
      //   // check for default formatting
      //   const defaultSettings = this.$store.getters.getDefaultSettings;
      //   if(defaultSettings && defaultSettings.dateFormat) {
      //     outputFormat = defaultSettings.dateFormat
      //   }
      //   // Map of month names for formatting
      //   const months = [
      //     "Jan",
      //     "Feb",
      //     "Mar",
      //     "Apr",
      //     "May",
      //     "Jun",
      //     "Jul",
      //     "Aug",
      //     "Sep",
      //     "Oct",
      //     "Nov",
      //     "Dec",
      //   ];

      //   // Function to pad single digits with leading zero
      //   const padZero = (num) => (num < 10 ? "0" : "") + num;

      //   // Parse input date string
      //   let date;
      //   if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(inputDate)) {
      //     date = new Date(inputDate);
      //   } else {
      //     const dateParts = inputDate.split(/[ /:\-.,]/);
      //     const [day, month, year] = dateParts
      //       .slice(0, 3)
      //       .map((part) => parseInt(part));
      //     const hour = parseInt(dateParts[3]) || 0;
      //     const minute = parseInt(dateParts[4]) || 0;
      //     // const amPm = dateParts[5] && dateParts[5].toLowerCase();
      //     date = new Date(year, month - 1, day, hour, minute);
      //   }

      //   // Get components for output format
      //   const formattedMonth = months[date.getMonth()];
      //   const formattedDay = padZero(date.getDate());
      //   const formattedYear = date.getFullYear();
      //   let formattedHour = date.getHours();
      //   const formattedMinute = padZero(date.getMinutes());
      //   const amPmFormat = formattedHour >= 12 ? "PM" : "AM";
      //   formattedHour = formattedHour % 12 || 12; // Convert to 12-hour format

      //   // Format output date
      //   const outputDate = outputFormat
      //     .replace("DD", formattedDay)
      //     .replace("MMM", formattedMonth)
      //     .replace("YYYY", formattedYear)
      //     .replace("hh", padZero(formattedHour))
      //     .replace("h", formattedHour)
      //     .replace("mm", formattedMinute)
      //     .replace("AM/PM", amPmFormat);

      //   return outputDate;
      //   // // Example usage:
      //   // const inputDate = '2024-04-17T07:15:47.478Z';
      //   // const outputFormat = 'MMM DD, YYYY hh:mm AM/PM';
      //   // console.log(convertDateTime(inputDate, outputFormat));
      // },
      dateAndTimeFormatterV1(
        inputDate,
        outputFormat = 'MMM DD, YYYY hh:mm AM/PM'
      ) {
        if (!inputDate) return '-';
        // check for default formatting
        const defaultSettings = this.$store.getters.getDefaultSettings;
        if (defaultSettings && defaultSettings.dateFormat) {
          outputFormat = defaultSettings.dateFormat;
        }

        // Map of month names for formatting
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];

        // Map of day names for formatting
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        // Function to pad single digits with leading zero
        const padZero = (num) => (num < 10 ? '0' : '') + num;

        // Parse input date
        let date;
        if (inputDate instanceof Date) {
          date = inputDate;
        } else if (typeof inputDate === 'number') {
          date = new Date(inputDate);
        } else if (typeof inputDate === 'string') {
          // check if input date is empty and return empty string
          if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(inputDate)) {
            date = new Date(inputDate);
          } else {
            const dateParts = inputDate.split(/[ /:\-.,]/);
            const [day, month, year] = dateParts
              .slice(0, 3)
              .map((part) => parseInt(part));
            const hour = parseInt(dateParts[3]) || 0;
            const minute = parseInt(dateParts[4]) || 0;
            date = new Date(year, month - 1, day, hour, minute);
          }
        } else {
          return inputDate;
          // throw new Error("Invalid input date format.");
        }

        // Get components for output format
        const formattedMonth = months[date.getMonth()];
        const formattedDay = padZero(date.getDate());
        const formattedYear = date.getFullYear();
        let formattedHour = date.getHours();
        const formattedMinute = padZero(date.getMinutes());
        const formattedSecond = padZero(date.getSeconds());
        const amPmFormat = formattedHour >= 12 ? 'PM' : 'AM';
        formattedHour = formattedHour % 12 || 12; // Convert to 12-hour format

        // Format output date
        let outputDate = outputFormat
          .replace(/\bDD\b/g, formattedDay)
          .replace(/\bMMM\b/g, formattedMonth)
          .replace(/\bMM\b/g, formattedMonth)
          .replace(/\bYYYY\b/g, formattedYear)
          .replace(/\bhh\b/g, padZero(formattedHour))
          .replace(/\bh\b/g, formattedHour)
          .replace(/\bmm\b/g, formattedMinute)
          .replace(/\bss\b/g, formattedSecond)
          .replace('AM/PM', amPmFormat);

        // Replace day of the week if format includes it
        if (outputFormat.includes('ddd')) {
          const formattedDayOfWeek = days[date.getDay()];
          outputDate = outputDate.replace('ddd', formattedDayOfWeek);
        }

        return outputDate;
      },
      dateAndTimeFormatter(
        inputDate,
        outputFormat = 'MMM DD, YYYY hh:mm AM/PM'
      ) {
        if (!inputDate) return '-';
        // Check for default formatting
        const defaultSettings = this.$store.getters.getDefaultSettings;
        if (defaultSettings && defaultSettings.dateFormat) {
          outputFormat = defaultSettings.dateFormat;
        }

        // Map of month names for formatting
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];

        // Map of day names for formatting
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        // Function to pad single digits with leading zero
        const padZero = (num) => (num < 10 ? '0' : '') + num;

        // Parse input date
        let date;
        if (inputDate instanceof Date) {
          date = inputDate;
        } else if (typeof inputDate === 'number') {
          date = new Date(inputDate);
        } else if (typeof inputDate === 'string') {
          if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(inputDate)) {
            date = new Date(inputDate);
          } else {
            const dateParts = inputDate.split(/[ /:\-.,]/);
            const [day, month, year] = dateParts
              .slice(0, 3)
              .map((part) => parseInt(part));
            const hour = parseInt(dateParts[3]) || 0;
            const minute = parseInt(dateParts[4]) || 0;
            date = new Date(year, month - 1, day, hour, minute);
          }
        } else {
          return inputDate;
        }

        // Get components for output format
        const formattedMonth = months[date.getMonth()];
        const formattedDay = padZero(date.getDate());
        const formattedYear = date.getFullYear();
        let formattedHour = date.getHours();
        const formattedMinute = padZero(date.getMinutes());
        const formattedSecond = padZero(date.getSeconds());
        const amPmFormat = formattedHour >= 12 ? 'PM' : 'AM';
        formattedHour = formattedHour % 12 || 12; // Convert to 12-hour format

        // Format output date
        let outputDate = outputFormat
          .replace(/\bDD\b/g, formattedDay)
          .replace(/\bMMM\b/g, formattedMonth)
          .replace(/\bMM\b/g, formattedMonth)
          .replace(/\bYYYY\b/g, formattedYear)
          .replace(/\bhh\b/g, padZero(formattedHour))
          .replace(/\bh\b/g, formattedHour)
          .replace(/\bmm\b/g, formattedMinute)
          .replace(/\bss\b/g, formattedSecond)
          .replace('AM/PM', amPmFormat);

        // Replace day of the week if format includes it
        if (outputFormat.includes('ddd')) {
          const formattedDayOfWeek = days[date.getDay()];
          outputDate = outputDate.replace('ddd', formattedDayOfWeek);
        }

        // Handle the format with space and comma
        outputDate = outputDate.replace('MMM ,', 'MMM,'); // Remove space before comma

        return this.translateDateToLanguage(JSON.stringify(outputDate));
      },
      isConsole(isEnable = false) {
        if (!isEnable) {
          console.log = function () {};
        } else {
          console.log = originalConsoleLog;
        }
      },

      findOneAndUpdate(list = [], key = null, value = '', updatePayload = {}) {
        // console.log("🚀 ~ findOneAndUpdate ~ list:", list);
        try {
          if (key) {
            const itemIndex = list.findIndex((item) => item[key] == value);
            // console.log("🚀 ~ findOneAndUpdate ~ itemIndex:", itemIndex);
            // if (itemIndex == 0) {
            //   list[0] = updatePayload;
            // }
            if (itemIndex >= 0) {
              list.splice(itemIndex, 1, updatePayload);
            } else {
              list.push(updatePayload);
            }
            return list;
          }
          throw list;
        } catch (error) {
          throw new Error(error);
        }
      },
      isPayloadChanged(initialData, changedData) {
        // console.log(
        // "🚀 ~ isPayloadChanged ~ initialData,changedData:",
        // initialData,
        // changedData
        // );
        var availableDifferences = deepdiff(initialData, changedData);
        console.log(
          '🚀 ~ isPayloadChanged ~ availableDifferences:',
          availableDifferences
        );
        // console.log(differences,'kdk');
        if (availableDifferences != undefined) {
          return true;
        }
        return false;
      },
      customDiff(initialData, changedData) {
        return deepdiff(initialData, changedData);
      },
      currencyFormat(
        currencyunit,
        amount,
        priceperiod = '',
        minimumFractionDigits = 2,
        maximumFractionDigits = 2
      ) {
        const lang = 'en-US';
        // if(amount)
        console.log(amount, 'amount');
        // // console.log("🚀 ~ install ~ number:", number)
        if (priceperiod !== '') {
          // console.log("🚀 ~ install ~ priceperiod:", priceperiod);
          const options = {
            style: 'decimal', // Other options: 'currency', 'percent', etc.
            minimumFractionDigits,
            maximumFractionDigits,
          };
          const number = parseFloat(amount);
          amount = number.toLocaleString(lang, options);
          const data = currencyunit + amount + ' / ' + priceperiod;
          return data;
        } else {
          const options = {
            style: 'decimal', // Other options: 'currency', 'percent', etc.
            minimumFractionDigits,
            maximumFractionDigits,
          };
          // let number
          if (typeof amount == 'string' && !amount.includes(',')) {
            amount = parseFloat(amount);
          }
          // console.log("🚀 ~ install ~ amount:", typeof amount);
          // // console.log("🚀 ~ install ~ number:", number)
          amount = amount.toLocaleString(lang, options);
          // console.log("🚀 ~ install ~ amount:", amount);
          // console.log(amount, 'amount')
          const data1 = currencyunit + amount;
          // console.log("🚀 ~ install ~ data1:", data1);
          return data1;
        }
      },
      currencyFormatter(amount, options) {
        console.log('🚀 ~ install ~ amount:', amount, options);
        // let a = true
        // if(a){

        // return amount
        // }
        // console.log("🚀 ~ install ~ amount:", amount);
        // console.log(
        // "🚀 ~ install ~ this.$store.getters.getDefaultSettings:",
        // this.$store.getters.getDefaultSettings
        // );
        options = {
          currency: this.$store.getters.getDefaultSettings?.currency?.unit,
          symbol: this.$store.getters.getDefaultSettings?.currency?.symbol,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          lang: this.$store.getters.getDefaultSettings?.cca2
            ? 'en-' + this.$store.getters.getDefaultSettings?.cca2
            : 'en-US',
          withUnit: false,
          style: 'currency',
          ...options,
        };
        if (!this.$store.getters.getDefaultSettings) {
          options.currency = 'CAD';
          options.lang = 'en-CA';
        }
        const { currency, lang } = options;
        // console.log("🚀 ~ install ~ options:", options);
        // console.log("🚀 ~ install ~ symbol:", symbol);
        if (!currency) return amount;
        // check for symbol if symbol not recieved get corresponded symbol
        // if(!symbol) {
        //     let currencyInfo = await axios.get(
        //       `https://restcountries.com/v3.1/currency/${unit}?fields=currencies`
        //     )
        //     if(currencyInfo.data) {
        //       symbol = currencyInfo.data[0]?.currencies[unit]?.symbol
        //     }
        // }
        // Check and convert amount string to number
        // console.log("🚀 ~ currencyFormatter ~ amount:", amount);
        if (
          (typeof amount == 'string' && !amount.includes(',')) ||
          typeof amount == 'string'
        ) {
          amount = parseFloat(amount);
          // console.log("🚀 ~ currencyFormatter ~ amount:", amount);
        }
        // Convert number to formated number
        // amount = amount.toLocaleString(lang, options)
        try {
          let convertedCurrency = new Intl.NumberFormat(lang, options).format(
            amount
          );
          if (options.withUnit) {
            convertedCurrency = convertedCurrency + ' / ' + currency;
          }
          // console.log("🚀 ~ install ~ convertedCurrency:", convertedCurrency);

          return convertedCurrency;
        } catch (error) {
          // console.log("🚀 ~ install ~ error:", error);
          return amount;
        }
      },
      bufferToDataURL(buffer) {
        // Convert the buffer to a Uint8Array
        const uint8Array = new Uint8Array(buffer);

        // Convert Uint8Array to a binary string
        let binary = '';
        uint8Array.forEach((byte) => {
          binary += String.fromCharCode(byte);
        });

        // Base64 encode the binary string
        const base64String = window.btoa(binary);
        this.profilePicUrl = `data:image/png;base64,${base64String}`;
        // Construct and return the data URL
        return `data:image/png;base64,${base64String}`;
      },
      dateFormat(date, month, year, format) {
        let dateformat = '';
        switch (format) {
          case 'Month DD, YYYY' || 'mmmm dd, yyyy' || 'mmm dd, yyyy':
            dateformat = month + ' ' + date + ', ' + year;
            break;
          case 'dd mmmm yyyy':
            dateformat = date + ' ' + month + ' ' + year;
            break;
          default:
            dateformat = month + ' ' + date + ', ' + year;
        }
        return dateformat;
      },
      logout() {
        this.$cookies.remove('token');
        // this.$router.push("/");
        const add =
          window.location.protocol +
          '//' +
          window.location.hostname +
          ':' +
          window.location.port;
        const urlObject = new URL(add);
        const hostName = urlObject.origin;
        window.location.replace(hostName);
      },
      handleCancel(from, to, options) {
        // console.log(
        // "🚀 ~ file: customPlugin.js:32 ~ handleCancel ~ from,to:",
        // from,
        // to
        // );
        if (
          (from === 'productCatalogTableOffering' || !from) &&
          to === 'productCatalogTable'
        ) {
          this.$router.push({
            name: 'productCatalogTable',
            query: options.query,
          });
        } else if ((from === 'view' || !from) && to === 'productCatalogTable') {
          this.$router.push({
            name: 'productCatalogTable',
            params: options.params,
          });
        }
      },
      // beforeRouteLeave(to, from) {
      //   // console.log(
      //   // "🚀 ~ file: customPlugin.js:35 ~ beforeRouteLeave ~ to, from:",
      //   // to,
      //   // from
      //   // );
      //   const answer = window.confirm(
      //     "Do you really want to leave? you have unsaved changes!"
      //   );
      //   if (!answer) return false;
      // },
      convertdateformat(date1) {
        if (date1 != null) {
          let objectDate = new Date(date1);
          console.log(objectDate, date1);
          if (isNaN(objectDate) == false) {
            let date = objectDate.toLocaleString('default', { day: 'numeric' });
            let month = '';
            let format = this.$store.state.defaultSetting.dateFormat;
            if (
              format == 'Month DD, YYYY' ||
              format == 'mmmm dd, yyyy' ||
              format == 'mmmm dd, yyyy'
            ) {
              month = objectDate.toLocaleString('default', { month: 'long' });
            } else {
              month = objectDate.toLocaleString('default', { month: 'short' });
            }
            let year = objectDate.getFullYear();
            let dateFormat = this.dateFormat(date, month, year, format);
            // let dateformat = month + " " + date + ", " + year;
            return dateFormat;
          }
        }
      },
      activeSidebar(currentPath) {
        this.$store.state.sidebarData = this.$store.state.sidebarData.map(
          (item) => {
            if (
              item.path &&
              currentPath &&
              item.path.toLowerCase() === currentPath.toLowerCase()
            ) {
              item.active = true;
            } else {
              item.active = false;
            }
            return item;
          }
        );
      },
    },
  });

  // 4. add an instance method
  // Vue.prototype.$myMethod = function (methodOptions) {
  //
  //   // some logic ...
  // }
};

const customPlugin = {
  install,
};
export default customPlugin;
