/* eslint-disable */

<template>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete site</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />

  <div class="custom-header">
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <hlx-switch
        v-model="selectedTab"
        :switch-items="tabData"
        :tab-view="true"
        value-prop="name"
        display-prop="name"
        @change="selectedValueFunc"
      />

      <div
        v-if="selectedTab === 'Site details'"
        class="newStieAdded"
        @click="newSiteadd"
      >
        <i class="icon-plus-regular"></i> Add site
      </div>
    </div>
  </div>

  <div v-if="selectedTab === 'General info'" style="padding-bottom: 40px">
    <div class="custom-order-info" style="position: relative">
      <FieldRenderer
        :schema-payload="basicDetailsFieldSchema"
        :parent-class-name="'parent-container-for-lead-overview'"
        :layout="'2-column'"
        :grid-field-margin="20"
        :data-payload="field3Data"
        @lookup-data="emittedLookupDataForRemapFromField3"
        @edited-payload="storeDataFromField3"
      ></FieldRenderer>
    </div>
    <div class="custom-contact-billing">
      <div class="custom-billing-details">
        <FieldRenderer
          :schema-payload="contactDetailsFieldSchema"
          :parent-class-name="'parent-container-for-lead-overview'"
          :layout="'1-column'"
          :data-payload="field3Data"
          @lookup-data="emittedLookupDataForRemapFromFieldContact"
          @edited-payload="storeContact"
        ></FieldRenderer>
      </div>
      <div class="custom-contact-details">
        <FieldRenderer
          :schema-payload="billingDetailsFieldSchema"
          :parent-class-name="'parent-container-for-lead-overview'"
          :layout="'1-column'"
          :update-address-key="updatedAddressCompKey"
          :data-payload="field3Data"
          @edited-payload="storeBilling"
        ></FieldRenderer>
      </div>
    </div>

    <div class="custom-attachment-section">
      <hlx-multi-upload
        :type="'single-detail-line-multi-upload'"
        :file-type="'.json,.xlsx,.doc,.pdf,.txt,.jpg,.jpeg,.png'"
        :file-size-limit="'20MB'"
        :loader="true"
        :is-loader="true"
        :pre-value="attachmentFilteredData"
        @native-data="addAttachment"
        @remove="removeAttachment"
        @download="downloadAttachmnet"
      ></hlx-multi-upload>
    </div>
    <div class="custom-notes-section">
      <notes
        :data="field3Data.note"
        @added-note="addNotes"
        @edit-note="editNotes"
        @delete-index="deleteNotes"
      ></notes>
    </div>
  </div>

  <div v-if="selectedTab === 'Site details'" style="padding: 20px">
    <section v-for="(i, index) in example_data.length" :key="index">
      <section
        class="lead-dropdowm-site-cover"
        style="
          cursor: pointer;
          align-items: center;
          margin-bottom: 8px;
          width: 97%;
          position: relative;
        "
        @click="closeSiteDetails(index, i)"
      >
        <div class="lead-dropdowm-site-num" style="display: contents">
          <div
            class="equipment-order-site-accordion"
            style="width: 100%; font-family: OpenSans; line-height: 1.8"
          >
            <div class="left">
              <div class="icon cart-icon">
                <!-- Shopping Cart Icon -->
                <i class="icon-shopping-cart-regular"></i>
              </div>
              <div class="details">
                <div class="name">
                  <span style="padding-right: 10px">{{
                    example_data[index].siteName || `Site -${index + 1} details`
                  }}</span>
                  <i
                    class="icon-buildings-alt-regular"
                    style="color: #7a7a7a; padding-right: 5px; font-size: 12px"
                  ></i>
                  <span
                    class="contactAccourdtionNumber"
                    style="padding-right: 10px"
                    >{{ example_data[index].numberOfUnits }}</span
                  >
                  <i
                    class="icon-check-circle-filled"
                    style="color: #54bd95"
                  ></i>
                </div>
                <span class="address" v-if="example_data[index]?.siteAddress">{{
                  example_data[index]?.siteAddress[0]?.characteristic
                    ?.description
                }}</span>
              </div>
            </div>
            <div class="right" style="padding-right: 10px">
              <span class="one-time"
                >One time total:
                {{
                  currencyFormatter(
                    example_data[index].quoteTotalPrice?.[0]?.quoteTotal?.value,
                    {
                      currency:
                        $store?.getters?.getDefaultSettings?.currency?.unit,
                    }
                  )
                }}
                <span style="color: #8d8d8d"
                  >/
                  {{
                    $store?.getters?.getDefaultSettings?.currency?.unit
                  }}</span
                ></span
              >
              <span class="recurring"
                >Recurring total:
                {{
                  currencyFormatter(
                    example_data[index].quoteTotalPrice?.[1]?.quoteTotal?.value,
                    {
                      currency:
                        $store?.getters?.getDefaultSettings?.currency?.unit,
                    }
                  )
                }}
                <span style="color: #8d8d8d"
                  >/
                  {{
                    $store?.getters?.getDefaultSettings?.currency?.unit
                  }}</span
                ></span
              >
            </div>
          </div>

          <div v-if="chosenSiteIndex === index" class="lead-dropdowm-site-icon">
            <i style="color: #a6a6a6" class="icon-angle-up-filled"></i>
          </div>
          <div v-if="chosenSiteIndex !== index" class="lead-dropdowm-site-icon">
            <i style="color: #a6a6a6" class="icon-angle-down-filled"></i>
          </div>
          <div class="delete-icon" @click.stop="deleteSite(index)">
            <i class="icon-trash-regular" style="color: red"></i>
          </div>
        </div>
      </section>
      <div
        v-if="chosenSiteIndex === index"
        class="parent-container-for-lead-maintable-site"
      >
        <!-- :address-data-props="siteAddressArray" -->
        <FieldRenderer
          :schema-payload="filteredCustomFieldSchema7"
          :parent-class-name="'parent-container-for-lead-overview'"
          :layout="'2-column'"
          :data-payload="exampleDataSite"
          @lookup-data="emittedLookupDataForRemapFromFieldSite"
          @edited-payload="siteFieldsData"
        ></FieldRenderer>
        <suiteRuin
          :suite-run-prop="example_data[chosenSiteIndex].suiteRun"
          @suite-run-em="suiteRunEm"
        />
      </div>
    </section>
  </div>

  <div v-if="selectedTab == 'Product details'" style="padding: 20px">
    <section
      v-if="show_offering"
      class="product-offering-container"
      style="
        width: 100%;
        height: 100%;
        display: flex;
        padding: 20px;
        /* gap: 15px; */
        overflow: inherit;
        flex-direction: column;
      "
    >
      <div
        class="new-product-listing-container-toolbox"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
        "
      >
        <div>
          <div
            class="data-view-nav-container"
            style="
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 14px;
              cursor: pointer;
            "
            @click="clickBack"
          >
            <i class="icon-arrow-left-regular"></i>
            <span>Back</span>
          </div>
        </div>
        <div>
          <div
            class="data-view-search-container"
            style="display: flex; align-items: center; gap: 8px"
          >
            <span class="filter-table filter-icon-listing-page">
              <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
                <i class="icon-filter-regular"></i>
                <span v-if="filtertaglength != 0" class="filter-count">{{
                  filtertaglength
                }}</span>
              </hlx-button>
            </span>
            <hlx-search
              v-model="searchvalue"
              @search-key="searchOffers"
            ></hlx-search>
            <hlx-badge
              :icon="'icon-shopping-cart-regular'"
              :count="selectedProductOfferingData.length"
              @click="clickBack"
            />
          </div>
        </div>
      </div>

      <div class="switchforRP">
        <div class="switchforRPright">
          <span class="switchforRPrightspan1">{{
            "Show retired product"
          }}</span>
          <span class="switchforRPrightspan2">
            <hlx-input
              v-model:value="retiredvmodel"
              :checked="false"
              :type="'switch'"
            />
          </span>
        </div>
      </div>

      <div class="listing-container">
        <div class="filter-panel-listing-page">
          <FilterComponent
            :key="filterComponentKey"
            :filterpanelname="'productOffering'"
            :company-name="company"
            :query-value="query"
            :display-data="displayFilterData"
            :module="'discountOffers'"
            @filtered-data="offeringFilterData"
          ></FilterComponent>
        </div>
        <div
          class="new-product-listing-cards"
          style="
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
            grid-gap: 50px;
          "
        >
          <div
            v-for="(item, index) in card_data"
            :key="index"
            class="listing-card"
            style="
              min-height: 300px;
              max-height: 420px;
              border: 1px solid #d8d8d8;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
            "
            @click="showConfigurationPage(item)"
          >
            <span
              style="
                height: 220px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <span
                v-if="
                  item.prodSpecCharValue[0]?.attachment[0]?.image_url === ''
                "
                style="width: 100px"
              >
                <img
                  style="height: 100px; width: 100px; object-fit: contain"
                  src="@/assets/images/Placeholder image.svg"
                  alt="product image preview"
                />
              </span>
              <span v-else style="width: 100%; height: 100%">
                <img
                  style="height: inherit; width: inherit; object-fit: contain"
                  :src="item.prodSpecCharValue[0]?.attachment[0]?.image_url"
                  alt="product image preview"
                />
              </span>
            </span>
            <div
              style="
                border-top: 1px solid rgb(216, 216, 216);
                padding: 20px;
                min-height: 200px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <span style="display: flex; flex-direction: column">
                <span
                  style="
                    margin-bottom: 10px;
                    display: -webkit-box;
                    max-width: 400px;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ item?.name }}
                </span>
                <span style="font-size: 12px; margin-bottom: 10px">
                  {{ item.category }}
                </span>
              </span>
              <span style="display: flex; flex-direction: column">
                <span
                  style="
                    font-weight: bold;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    flex-wrap: wrap;
                  "
                >
                  {{
                    currencyFormatter(
                      item.price.whole + "." + item.price.fraction,
                      {
                        currency:
                          item.prodSpecCharValue[0]?.tiers[0]?.price?.unit,
                      }
                    )
                  }}
                  &nbsp;
                  <del
                    v-if="
                      item?.discountResponse?.productOffering?.price !==
                      undefined
                    "
                    style="font-weight: 100; font-size: 14px"
                    >{{
                      currencyFormatter(
                        item?.discountResponse?.productOffering?.price,
                        {
                          currency:
                            item.prodSpecCharValue[0]?.tiers[0]?.price?.unit,
                        }
                      )
                    }}</del
                  >

                  <span
                    v-if="item.offering_type === 'intangible'"
                    style="font-weight: 100"
                  >
                    {{ " / " + item?.billing_period }}
                  </span>
                  &nbsp;
                  <span
                    v-if="
                      item?.discountResponse?.productOffering?.price !==
                      undefined
                    "
                    class="card-price-discount"
                  >
                    <div class="pricing-details-card">
                      <h5 style="margin-bottom: 10px">Price Details</h5>
                      <div class="price-item" style="font-weight: 100">
                        <span>Price</span>
                        <span class="price" style="font-weight: 100">
                          {{
                            currencyFormatter(
                              item?.discountResponse?.productOffering?.price,
                              {
                                currency:
                                  item.prodSpecCharValue[0]?.tiers[0]?.price
                                    ?.unit,
                              }
                            )
                          }}
                          {{
                            item?.billing_period !== "" &&
                            item?.billing_period !== undefined &&
                            item?.offering_type === "intangible"
                              ? "/" + item?.billing_period
                              : ""
                          }}</span
                        >
                      </div>
                      <div class="price-item" style="font-weight: 100">
                        <span>Product Discount</span>
                        <span class="discount" style="color: #02b55c">
                          - &nbsp;
                          <p
                            v-if="
                              item?.discountResponse?.productOffering
                                ?.appliedDiscount?.discountType?.name ==
                              'Percentage'
                            "
                          >
                            {{
                              item?.discountResponse?.productOffering
                                ?.appliedDiscount?.discountType?.value + "%"
                            }}
                          </p>
                          <p v-else>
                            {{
                              $store?.getters?.getDefaultSettings?.currency
                                ?.symbol +
                              item?.discountResponse?.productOffering
                                ?.appliedDiscount?.discountType?.value
                            }}
                          </p>
                        </span>
                      </div>
                      <hr />
                      <div class="price-item">
                        <span>Selling Price</span>
                        <span class="final-price">
                          {{
                            currencyFormatter(
                              item.price.whole + "." + item.price.fraction,
                              {
                                currency:
                                  item.prodSpecCharValue[0]?.tiers[0]?.price
                                    ?.unit,
                              }
                            )
                          }}</span
                        >
                      </div>
                    </div>
                    <i class="icon-info-circle-regular"></i>
                  </span>
                  <span
                    style="
                      color: rgba(38, 94, 75, 1);
                      font-weight: 900;
                      font-size: 14px;
                    "
                  >
                    {{
                      item?.discountResponse?.productOffering?.appliedDiscount
                        ?.name
                    }}
                  </span>
                </span>
                <span>
                  <button
                    v-if="cartItems.includes(item.id) == false"
                    class="custom-addtocart-btn"
                    @click.stop="
                      selectedOffer($event, item), addToCart($event, item)
                    "
                  >
                    <i
                      class="icon-shopping-cart-plus-regular"
                      @click.stop="
                        selectedOffer($event, item), addToCart($event, item)
                      "
                    ></i>
                    &nbsp; Add to cart
                  </button>
                  <button
                    v-else
                    class="custom-addtocart-btn item-added-to-cart"
                  >
                    Added
                  </button>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 0.75rem;
          position: fixed;
          box-shadow: 1px 1px 30px -16px;
          right: 0;
          left: 72px;
          bottom: 0;
          background: white;
        "
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :total="card_data_length"
          rows-per-page
          enable-rows-per-page
          :rows-per-page-list="[15, 30]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPageData"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    </section>
    <div v-else>
      <section v-for="(i, index) in example_data.length" :key="index">
        <section
          class="lead-dropdowm-site-cover"
          style="
            cursor: pointer;
            align-items: center;
            margin-bottom: 8px;
            width: 97%;
            position: relative;
          "
          @click="closeSiteDetails(index, i)"
        >
          <div class="lead-dropdowm-site-num" style="display: contents">
            <div
              class="equipment-order-site-accordion"
              style="width: 100%; font-family: OpenSans; line-height: 1.8"
            >
              <div class="left">
                <div class="icon cart-icon">
                  <!-- Shopping Cart Icon -->
                  <i class="icon-shopping-cart-regular"></i>
                </div>
                <div class="details">
                  <div class="name">
                    <span style="padding-right: 10px">{{
                      example_data[index].siteName ||
                      `Site -${index + 1} details`
                    }}</span>
                    <i
                      class="icon-buildings-alt-regular"
                      style="
                        color: #7a7a7a;
                        padding-right: 5px;
                        font-size: 12px;
                      "
                    ></i>
                    <span
                      class="contactAccourdtionNumber"
                      style="padding-right: 10px"
                      >{{ example_data[index].numberOfUnits }}</span
                    >
                  </div>
                  <span
                    class="address"
                    v-if="example_data[index]?.siteAddress"
                    >{{
                      example_data[index]?.siteAddress[0]?.characteristic
                        ?.description
                    }}</span
                  >
                </div>
              </div>
              <div class="right" style="padding-right: 10px">
                <span class="one-time"
                  >One time total:
                  {{
                    currencyFormatter(
                      example_data[index].quoteTotalPrice?.[0]?.quoteTotal
                        ?.value,
                      {
                        currency:
                          $store?.getters?.getDefaultSettings?.currency?.unit,
                      }
                    )
                  }}
                  <span style="color: #8d8d8d"
                    >/
                    {{
                      $store?.getters?.getDefaultSettings?.currency?.unit
                    }}</span
                  ></span
                >
                <span class="recurring"
                  >Recurring total:
                  {{
                    currencyFormatter(
                      example_data[index].quoteTotalPrice?.[1]?.quoteTotal
                        ?.value,
                      {
                        currency:
                          $store?.getters?.getDefaultSettings?.currency?.unit,
                      }
                    )
                  }}
                  <span style="color: #8d8d8d"
                    >/
                    {{
                      $store?.getters?.getDefaultSettings?.currency?.unit
                    }}</span
                  ></span
                >
              </div>
            </div>

            <div
              v-if="chosenSiteIndex === index"
              class="lead-dropdowm-site-icon"
            >
              <i style="color: #a6a6a6" class="icon-angle-up-filled"></i>
            </div>
            <div
              v-if="chosenSiteIndex !== index"
              class="lead-dropdowm-site-icon"
            >
              <i style="color: #a6a6a6" class="icon-angle-down-filled"></i>
            </div>

            <!-- <div v-if="chosenSiteIndex === index">
                {{temporaryleadSiteDetails[chosenSiteIndex]?.siteName?temporaryleadSiteDetails[chosenSiteIndex]?.siteName:'Site -' + (chosenSiteIndex + 1) +' details'}}
              </div> -->
            <!-- <div class="lead-dropdowm-site-icon">
                <i style="color: #A6A6A6;"  class="icon-angle-down-filled"></i>
              </div> -->
          </div>
        </section>
        <div
          v-if="chosenSiteIndex === index"
          class="parent-container-for-lead-maintable-site"
          style="margin-bottom: 20px"
        >
          <!-- :address-data-props="siteAddressArray" -->
          <pricingTableComponent
            :show-summary="false"
            :edit-summary-data="quoteData"
            :edit-summary-data-from-rules="quoteSummaryDataFromRules"
            :data="example_data[index].pricingTableData"
            :re-arrange-table-module="'contractProductTable'"
            :show-edit-columns-inside-table="true"
            :edit-data="editPricingData"
            :tax-data="recipientTaxes"
            :checkbox-shipping="multiChecked"
            :shipping-options="shippingOptions"
            @add-new-offer="addNewOffer($event, index)"
            @summary="receivePricingData"
            @total-summary="receiveTotalPricingDataTable"
            @summary-from-rules="receiveSummaryfromRulestable"
            @reconfigure="sendForReconfiguration"
          ></pricingTableComponent>
        </div>
      </section>

      <div>
        <pricingTableComponent
          :key="pricingTotalSummery"
          :show-summary="true"
          :show-table="false"
          :edit-summary-data="quoteData"
          :edit-summary-data-from-rules="quoteSummaryDataFromRules"
          :data="totalPricingData"
          :edit-data="editPricingData"
          :tax-data="recipientTaxes"
          :checkbox-shipping="multiChecked"
          :shipping-options="shippingOptions"
          @add-new-offer="addNewOffer"
          @total-summary="receiveTotalPricingData"
          @summary-from-rules="receiveSummaryfromRules"
          @reconfigure="sendForReconfiguration"
        ></pricingTableComponent>
      </div>
    </div>
    <!-- <accordionSite></accordionSite> -->
  </div>

  <div v-if="selectedTab === 'Terms and condition'">
    <FieldRenderer
      :schema-payload="filteredCustomFieldSchema8"
      :parent-class-name="'parent-container-for-lead-overview'"
      :layout="'1-column'"
      :text-editorheight="'580px'"
      :data-payload="field3Data"
      @edited-payload="storeDataFromField3"
    ></FieldRenderer>
  </div>

  <div
    v-if="selectedTab === 'Contract document'"
    style="height: 580px; padding: 20px"
  >
    <FieldRenderer
      :schema-payload="filteredCustomFieldSchema9"
      :parent-class-name="'parent-container-for-lead-overview'"
      :layout="'single'"
      :data-payload="field3Data"
      @edited-payload="storeDataFromField3"
    ></FieldRenderer>
  </div>

  <div
    style="
      background: white;
      padding: 10px;
      text-align: center;
      border-top: 1px solid #d8d8d8;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      /* flex-shrink: 0; */
      position: fixed;
      bottom: 0;
      left: 72px;
      right: 0;
      z-index: 10;
    "
  >
    <hlx-button
      v-if="selectedTab !== 'General info'"
      class="text sm"
      @click="previousContract"
    >
      Back
    </hlx-button>
    <hlx-button
      v-if="
        $route.params.section === 'create' &&
        selectedTab !== 'Contract document'
      "
      class="primary sm"
      style="margin-left: 10px"
      @click="nextContract"
    >
      Next
    </hlx-button>

    <hlx-button
      v-if="
        $route.params.section != 'create' || selectedTab == 'Contract document'
      "
      class="secondary sm"
      @click="contractSave"
    >
      Save
    </hlx-button>
    <hlx-button
      v-if="
        $route.params.section != 'create' || selectedTab == 'Contract document'
      "
      class="primary sm"
      style="margin-left: 10px"
      @click="contractSubmit"
    >
      Submit for approval
    </hlx-button>
  </div>

  <discardComponent
    :show="showSupplierDiscardModal"
    @stay="handleStay"
    @discard="handleDiscard"
    @close="showSupplierDiscardModal = false"
  />
</template>

<script>
import {
  // MLTAxiosInstance,
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import discardComponent from "@/components/discardComponent.vue";

import FilterComponent from "@/components/filterPanelComponent.vue";
import notes from "@/components/Order/notesComponent.vue";
import FieldRenderer from "@/components/FieldRenderer.vue";
import suiteRuin from "./suiteRuin.vue";
import pricingTableComponent from "@/components/UPC/CPQ/pricingTableComponent.vue";
import { toRaw } from "vue";

export default {
  components: {
    discardComponent,
    FilterComponent,
    FieldRenderer,
    notes,
    suiteRuin,
    pricingTableComponent,
  },
  beforeRouteLeave(to, from, next) {
    if (this.discardIt) {
      //this.discardIt will handled on discard modal window discard changes emit
      next(true);
      return;
    }
    // Check for unsaved changes
    /**
     * Param 1 initial paylaod to check against param 2
     * Param 2 updated payload to check against param 1
     */
    const unsavedChanges = this.isPayloadChanged(
      this.initialPayload,
      this.payload
    ); //This isPayloadChanged method was configured in global methods so we can call it any where on vue.It's find a difference between both data and return true if any changes otherwise return false
    console.log(unsavedChanges, "unsavedChanges");
    // If unsaved changes found then display discard modal
    if (unsavedChanges) {
      this.showSupplierDiscardModal = true;
    }
    // Then route as per validation
    next(!unsavedChanges);
    return;
  },
  data() {
    return {
      defaultBillingAddress: null,
      recipient: {
        partyCharacteristic: [{}, {}, { value: "" }],
        contactMedium: [
          {},
          {
            value: "",
          },
          {
            characteristic: {
              state: "Quebec",
            },
          },
          {},
          {},
          {
            characteristic: {
              country: "Canada",
            },
          },
        ],
        taxDefinition: [],
      },
      recipientTaxes: null,
      initialPayload: {},

      tempBack: false,
      showSupplierDiscardModal: false,
      billigReqiredFileds: [],
      contactReqiredFileds: [],
      basicReqiredFileds: [],
      delete_name: "",
      deleteIndexValue: null,
      delete_modal_active: false,
      exampleDataSite: {
        pricingTableData: this.tranformData,
        quoteTotalPrice:this.totalPriceRec_one,
        address: "",
        lookupData: false,
        name: "",
        units: "",
        description: null,
        siteAddress: [
          {
            characteristic: {
              city: "",
              unit: "",
              country: "",
              state: "",
              postalCode: "",
              postalCodePrefix: "",
              stateOrProvince: "",
              latitude: "",
              longitude: "",
              street1: "",
              street2: "",
              fsa: "",
              description: "",
              street: "",
              default: true,
              "@type": "",
              numberOfUnits: "",
              stateCode: "ON",
            },
            mediumType: "postalAddress",
          },
        ],
        siteName: "",
        firstOccupancyDate: "",
        equipmentNetworkId: null,
        marketSegment: "",
        networkId: null,
        networkManager: {
          value: "",
          email: "",
          id: "",
        },
        numberOfUnits: "",
        sfdcId: "",
        status: null,
      },
      tranformData: [],
      totalPriceRec_one:{},
      chooseContact: {
        type: "filter",
        module: "hierarchyaccounts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 5,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        limit: 100,
        searchQuery: "",
        filterQuery: {
          "@baseType": "customer",
        },
        company: "developerHalleyx",
      },
      pricingTotalSummery: 0,
      productRule: [],
      selectedLeadAccountRef: 0,
      siteDetailsPayload: {
        siteName: "",
        networkId: "",
        networkManager: {
          value: "",
          email: "",
          id: "",
        },
        createdAt: "",
        lastUpdate: "",
        sfdcId: "",
        numberOfUnits: "",
        tags: [],
        firstOccupancyDate: "",
        onboardingDate: "",
        marketSegment: {},
        siteAddress: [
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
              street: "",
              city: "",
              country: "",
              default: false,
              description: "",
              postalCode: "",
              postalCodePrefix: "",
              state: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              unit: "",
              latitude: "99889",
              longitude: "",
            },
          },
        ],
      },
      siteSchemaWithValue: null,
      updatedAddressCompKey: false,
      field3DataBilling: {},
      attachmentFilteredData: [],
      displayFilterData: {},
      card_data_length: 0,
      initialPricingTableData: [],
      initialQuoteAuthorizarionData: [],
      isValidQuoteItem: false,
      company: null,
      show_offering: false,
      showSiteInformationModal: false,
      showAllSitesModal: false,
      chosenSiteIndex: null,
      product_index: null,
      cartItems: [],
      card_data: [],
      search_offering: "",
      selectedProductOfferingData: [],
      currentPage: 1,
      sendSelectedProductOfferingData: [],
      tempPricingTableData: [],
      quotePricingData: {},
      editPricingData: [],
      reconfigure: false,
      filterComponentKey: 0,
      tabletMode: false,
      filterPrevalue: {},
      filterPrevalueDuplicate1: {},
      filterPrevalueDuplicate2: {},
      filtertagvalue: 0,
      show_right_filter: false,
      show_right: false,
      filtertaglength: 0,
      // filterPrevalueDuplicate1: {},

      filterQuery: {
        type: "filter",
        module: "discountOffers",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: "",
      },
      totalPricingData: [],

      // card_data_length: 0,
      // initialPricingTableData: [],
      // initialQuoteAuthorizarionData: [],
      // tempPricingTableData: [],
      // quotePricingData: {},
      // editPricingData: [],
      tempQuoteSummaryDataFromRules: {
        showOneTimeSummary: false,
        showRecurringSummary: false,
        oneTime: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_onetime: false,
        },
        recurring: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_recurring: false,
        },
        overAllTotal: 0,
        amountSaved: 0,
      },
      quoteSummaryDataFromRules: {
        showOneTimeSummary: false,
        showRecurringSummary: false,
        oneTime: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_onetime: false,
        },
        recurring: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_recurring: false,
        },
        overAllTotal: 0,
        amountSaved: 0,
      },
      addressInfo: {
        title: "",
        telephoneNumber: "",
        preferredLanguage: "",
        phoneNumber: "",
        lastName: "",
        firstName: "",
        emailId: "",
        billingType: "",
        billingAddress: [
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
              street: "",
              city: "",
              country: "",
              default: false,
              description: "",
              postalCode: "",
              postalCodePrefix: "",
              state: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              unit: "",
              latitude: "",
              longitude: "",
            },
          },
        ],
      },
      field3Data: {
        productRef: [],
        billingAddress: [
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
              street: "",
              city: "",
              country: "",
              default: false,
              description: "",
              postalCode: "",
              postalCodePrefix: "",
              state: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              unit: "",
              latitude: "",
              longitude: "",
            },
          },
        ],
        contractName: null,

        chooseContact: null,
        contactType: null,
        emailId: null,
        firstName: null,
        lastName: null,
        title: null,
        marketsegment:null,
        phoneNumber: null,
        preferredLanguage: null,
        telephoneNumber: null,
        contractType: null,
        status: null,
        description: null,
        sfdcId: null,
        quoteId: null,
        startDate: null,
        endDate: null,
        companyName: null,
        contractDocument: [],
        numberOfSites: 0,
        note: [],
        attachment: [],
        terms: null,
        termsAndCondition: "",
        contactMedium: [],
        relatedParty: [],
        product: [],
      },
      example_data_product: [],

      example_data: [
        {
          siteName: "",
          networkId: "",
          networkManager: {
            value: "",
            email: "",
            id: "",
          },
          createdAt: "",
          lastUpdate: "",
          sfdcId: "",
          numberOfUnits: "",
          tags: [],
          firstOccupancyDate: "",
          onboardingDate: "",
          marketSegment: {},
          siteAddress: [
            {
              mediumType: "postalAddress",
              characteristic: {
                "@type": "",
                street: "",
                city: "",
                country: "",
                default: false,
                description: "",
                postalCode: "",
                postalCodePrefix: "",
                state: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                unit: "",
                latitude: "99889",
                longitude: "",
              },
            },
          ],
        },
      ],
      asyncLanguageOptions: [],
      selectedTab: "General info",
      selectedSubTab: "",
      tabData: [
        {
          name: "General info",
          value: "general_info",
        },
        {
          name: "Site details",
          value: "site_details",
        },
        {
          name: "Product details",
          value: "product_details",
        },
        {
          name: "Terms and condition",
          value: "terms_and_condition",
        },
        {
          name: "Contract document",
          value: "contract_document",
        },
      ],
      selectedValue: "overview",
      schema: {},
      subtreeValue: "general_info",
    };
  },
  computed: {
    mandatoryFields() {
      const q = this.basicDetailsFieldSchema
        .filter((field) => field.mandatory)
        .map((field) => field.name);
      console.log("loooog mandatoryFields", q);

      return q;
    },
    basicDetailsFieldSchema() {
      if (!this.schema || Object.keys(this.schema).length === 0) return {};

      let filteredSchema = this.filterSchema(
        this.schema,
        this.selectedValue,
        this.subtreeValue
      );
      console.log(filteredSchema, "filteredSchemafilteredSchema");
      if (this.schema && Array.isArray(Object.values(this.schema))) {
        const requiredFields = Object.values(this.schema)
          .filter(
            (item) => item && typeof item === "object" && item.required === true
          ) // Ensure item is valid and required is true
          .map((item) => item.fieldNameRef || ""); // Provide a default value for fieldNameRef if it's undefined/null

        this.basicValueRequired(requiredFields);
      }

      // console.log(requiredFields, "this.schemarequiredFields");

      let sortedSchema = this.sortSchema(filteredSchema);

      if (sortedSchema["preferredLanguage"]) {
        sortedSchema["preferredLanguage"].options = this.asyncLanguageOptions;
      }

      if (sortedSchema["leadSource"]) {
        sortedSchema["leadSource"].others.readOnly = true;
      }

      return sortedSchema;
    },
    contactDetailsFieldSchema() {
      console.log("this.schema", this.schema);
      if (this.schema && Object.keys(this.schema)?.length > 0) {
        let filtered = {};
        filtered = Object.entries(this.schema?.contactInfo?.schema).filter(
          ([, value]) => value?.section && value?.section.includes("overview")
        );
        if (this.schema && Array.isArray(Object.values(this.schema))) {
          const requiredFields = Object.values(this.schema)
            .filter(
              (item) =>
                item && typeof item === "object" && item.required === true
            )
            .map((item) => item.fieldNameRef || ""); // Provide a default value for fieldNameRef if it's undefined/null
          this.concatValueRequired(requiredFields, this.contactReqiredFileds);
        }

        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, "fieldSchema", fieldSchema);

        const entries = Object.entries(fieldSchema);

        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity;
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB;
        });

        const sortedData = Object.fromEntries(entries);

        if (sortedData["preferredLanguage"]) {
          sortedData["preferredLanguage"].options = this.asyncLanguageOptions;
        }
        if (sortedData["numberOfSites"]) {
          sortedData["numberOfSites"].others.readOnly = true;
        }
        if (sortedData["leadSource"]) {
          sortedData["leadSource"].others.readOnly = true;
        }
        console.log(this.view, "sortedData", sortedData);
        return sortedData;
      } else if (this.isDrawerSchema) {
        console.log("this.isDrawerSchema", this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    billingDetailsFieldSchema() {
      console.log("this.billingInfo", this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        filtered = Object.entries(this.schema.billingInfo.schema).filter(
          ([, value]) => value.section && value.section.includes("overview")
        );
        if (this.schema && Array.isArray(Object.values(this.schema))) {
          const requiredFields = Object.values(this.schema)
            .filter(
              (item) =>
                item && typeof item === "object" && item.required === true
            ) // Ensure item is valid and required is true
            .map((item) => item.fieldNameRef || ""); // Provide a default value for fieldNameRef if it's undefined/null

          this.concatValueRequired(requiredFields, this.billigReqiredFileds);
        }
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, "fieldSchema", fieldSchema);

        const entries = Object.entries(fieldSchema);

        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity;
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB;
        });

        const sortedData = Object.fromEntries(entries);

        // Object.keys(sortedData).forEach((key) => {
        //   if (sortedData[key]?.others) {
        //     sortedData[key].others.readOnly = true;
        //   }
        // });

        if (sortedData["preferredLanguage"]) {
          sortedData["preferredLanguage"].options = this.asyncLanguageOptions;
        }
        if (sortedData["numberOfSites"]) {
          sortedData["numberOfSites"].others.readOnly = true;
        }
        console.log(this.view, "sortedData", sortedData);
        return sortedData;
      } else if (this.isDrawerSchema) {
        console.log("this.isDrawerSchema", this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    filteredCustomFieldSchema7() {
      console.log("this.schema", this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        // if (this.selectedValue.toLowerCase() == 'overview') {
        filtered = Object.entries(this.schema.sitesInfo.schema).filter(
          ([, value]) => value.section && value.section.includes("overview")
        );
        const fieldSchema = Object.fromEntries(filtered);
        console.log(
          this.selectedValue,
          "this.leadSchema[schemaObjName].value computed",
          this.schema.sitesInfo.schema
        );

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);

        Object.keys(sortedData).forEach((key) => {
          if (sortedData[key]?.others) {
            sortedData[key].others.readOnly = false;
          }
        });
        sortedData.siteName.others.lookup.filterPayload.filterQuery = {
          contactId: this.selectedLeadAccountRef,
          name: {
            $regex: "",
            $options: "i",
          },
        };
        if (sortedData["preferredLanguage"]) {
          sortedData["preferredLanguage"].options = this.languageOptions;
        }
        if (sortedData["numberOfSites"]) {
          sortedData["numberOfSites"].others.readOnly = true;
        }
        if (sortedData["leadSource"]) {
          sortedData["leadSource"].others.readOnly = true;
        }
        console.log(this.view, "sortedData", sortedData);
        return sortedData;
        // else{
        // return sortedData;
        // }
      } else if (this.isDrawerSchema) {
        console.log("this.isDrawerSchema", this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    filteredCustomFieldSchema8() {
      console.log("this.schema", this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        // if (this.selectedValue.toLowerCase() == 'overview') {
        filtered = Object.entries(
          this.schema.termsAndConditionInfo.schema
        ).filter(
          ([, value]) => value.section && value.section.includes("overview")
        );
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, "fieldSchema", fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);

        Object.keys(sortedData).forEach((key) => {
          if (sortedData[key]?.others) {
            sortedData[key].others.readOnly = false;
          }
        });

        if (sortedData["preferredLanguage"]) {
          sortedData["preferredLanguage"].options = this.languageOptions;
        }
        if (sortedData["numberOfSites"]) {
          sortedData["numberOfSites"].others.readOnly = true;
        }
        if (sortedData["leadSource"]) {
          sortedData["leadSource"].others.readOnly = true;
        }
        console.log(this.view, "sortedData", sortedData);
        return sortedData;
        // else{
        // return sortedData;
        // }
      } else if (this.isDrawerSchema) {
        console.log("this.isDrawerSchema", this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
    filteredCustomFieldSchema9() {
      console.log("this.schema", this.schema);
      if (this.schema && Object.keys(this.schema).length > 0) {
        let filtered = {};
        // if (this.selectedValue.toLowerCase() == 'overview') {
        filtered = Object.entries(
          this.schema.contractDocumentInfo.schema
        ).filter(
          ([, value]) => value.section && value.section.includes("overview")
        );
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, "fieldSchema", fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);

        Object.keys(sortedData).forEach((key) => {
          if (sortedData[key]?.others) {
            sortedData[key].others.readOnly = false;
          }
        });

        if (sortedData["preferredLanguage"]) {
          sortedData["preferredLanguage"].options = this.languageOptions;
        }
        if (sortedData["numberOfSites"]) {
          sortedData["numberOfSites"].others.readOnly = true;
        }
        if (sortedData["leadSource"]) {
          sortedData["leadSource"].others.readOnly = true;
        }
        console.log(this.view, "sortedData", sortedData);
        return sortedData;
        // else{
        // return sortedData;
        // }
      } else if (this.isDrawerSchema) {
        console.log("this.isDrawerSchema", this.isDrawerSchema);
        return;
      } else {
        return {};
      }
    },
  },
  watch: {
    example_data: {
      handler(val) {
        this.totalPricingData = val.reduce((acc, obj) => {
          if (obj.pricingTableData !== undefined) {
            return acc.concat(JSON.parse(JSON.stringify(obj.pricingTableData)));
          }
          return acc;
        }, []); // Provide an initial value as an empty array
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    if (
      this.$route.params.id != "id" &&
      this.$route.params.section != "create"
    ) {
      const getAgreement = await UPCAxiosInstance.get(
        `/agreementManagement/${this.$route.params.id}`
      );

      this.field3Data = await JSON.parse(JSON.stringify(getAgreement.data));
      this.example_data = this.field3Data.relatedParty;
      this.chooseContact.filterQuery.name = {
        $regex: this.field3Data.companyName,
        $options: "i",
      };
      const chooseContact = await MLTAxiosInstance.post(
        `util/filterData`,
        this.chooseContact
      );

      const dataPayloadDup = chooseContact.data.data[0];
      this.selectedLeadAccountRef = dataPayloadDup?.accountRef;
      console.log(this.selectedLeadAccountRef, "this.selectedLeadAccountRef");

      dataPayloadDup.relatedParty = dataPayloadDup?.relatedParty?.filter(
        (i) => {
          return i["@type"] !== "leadOwner";
        }
      );

      console.log(dataPayloadDup.relatedParty, "dataPayloadDup.relatedParty");

      var relatedPartyArray = [];

      relatedPartyArray = await Promise.all(
        dataPayloadDup?.relatedParty?.map(async (i) => {
          try {
            const con = await MLTAxiosInstance.get(
              `/partymanagement/individual/myinfo/${
                i.id
              }?company=${this.$cookies.get("company")}`
            );

            if (con.data.pipeLine === undefined) {
              con.data.newRole = con?.data?.role?.name;
              return con.data; // Return the transformed object
            }
          } catch (error) {
            console.error(`Error fetching data for id ${i.id}:`, error);
          }
          return null; // Return null for cases where the condition is not met
        })
      );

      let organisationInfo = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$cookies.get(
          "user"
        )}?company=${this.$cookies.get("company")}`
      );
      this.defaultBillingAddress = organisationInfo.data.contactMedium.filter(
        (i) => {
          return i["mediumType"] == "billingAddress";
        }
      )[0];
      this.field3Data.billingAddress = [];
      this.field3Data.billingAddress.push(
        JSON.parse(JSON.stringify(this.defaultBillingAddress))
      );

      // Filter out `null` values
      const filteredRelatedPartyArray = relatedPartyArray.filter(
        (item) => item !== null
      );
      if (relatedPartyArray.length > 0) {
        relatedPartyArray[0].default = true;
      }

      console.log(
        chooseContact.data.data[0],
        "chooseContactchooseContact",
        filteredRelatedPartyArray
      );
    }

    this.attachmentFilteredData = this.field3Data.attachment?.filter(
      (item) => item["@type"] !== "profile"
    );
    let data = await UPCAxiosInstance.get(
      `customSchema/getSchema?module=agreement`
    );
    this.schema = data.data.schema;
    // this.schema.contactInfo.schema["chooseContact"].options = relatedPartyArray;

    this.company = this.$cookies.get("company");
    await this.$store.dispatch("discountOffersData");
    this.displayFilterData =
      this.$store.getters.productOfferingDiscountFilterData;
    const filterResultData2 = await UPCAxiosInstance.post(
      `util/filterData`,
      this.filterQuery
    );
    console.log("Offering data", filterResultData2.data.total);
    this.card_data_length = filterResultData2.data.total;
    console.log(
      filterResultData2.data.data,
      this.filterQuery,
      "filterResultData2.data.data..."
    );
    this.card_data = this.transformData(filterResultData2.data.data);

    this.card_data.forEach((i) => {
      this.getProductImageData(i);
    });
    await this.fetchLanguageOptions();
    this.schema.billingInfo.schema.billingAddress.value = [];
    this.schema.billingInfo.schema.billingAddress.value.push(
      JSON.parse(JSON.stringify(this.defaultBillingAddress))
    );
    this.findTaxForRecipient(this.recipient);
  },
  methods: {
    async findTaxForRecipient(recipient) {
      console.log("findTaxForRecipient....123", recipient);
      // this.recipient =

      // const defaultSettings = await MLTAxiosInstance.get(`/settings`);
      let addressType = "billingAddress";
      let taxData = {};
      let obj = {
        specificAccount: true,
        "appliesTo.tradingName": recipient?.name,
        "province.name": recipient?.contactMedium[3]?.characteristic?.state,
        "country.name.common":
          recipient?.contactMedium[5]?.characteristic?.country,
        locationRef: addressType,
      };
      obj;
      let obj1 = {
        specificMarket: true,
        "appliesTo.name": recipient?.partyCharacteristic[2]?.value,
        "province.name": recipient?.contactMedium[3]?.characteristic?.state,
        "country.name.common":
          recipient?.contactMedium[5]?.characteristic?.country,
        locationRef: addressType,
      };
      obj1;
      let obj2 = {
        allAccount: true,
        "province.name": recipient?.contactMedium[3]?.characteristic?.state,
        "country.name.common":
          recipient?.contactMedium[5]?.characteristic?.country,
        locationRef: addressType,
      };
      obj2;
      let obj3 = {
        allAccount: true,
        province: [],
        "country.name.common":
          recipient?.contactMedium[5]?.characteristic?.country,
        locationRef: addressType,
      };
      obj3;

      let f2x = {
        company: `${this.company}`,
        type: "filter-search",
        module: "tax",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 100,
        filterQuery: {},
        searchQuery: "",
      };
      console.log(f2x, "lolololololo", recipient);
      f2x.filterQuery = obj;
      let tax1 = await UPCAxiosInstance.post("util/filterData", f2x);
      console.log(tax1.data.data, "tax1");
      if (tax1.data.data.length == 0) {
        f2x.filterQuery = obj1;
        let tax2 = await UPCAxiosInstance.post("util/filterData", f2x);
        console.log(tax2.data.data, "tax2");
        if (tax2.data.data.length == 0) {
          f2x.filterQuery = obj2;
          let tax3 = await UPCAxiosInstance.post("util/filterData", f2x);
          console.log(tax3.data.data, "tax3");
          if (tax3.data.data.length == 0) {
            f2x.filterQuery = obj3;
            let tax4 = await UPCAxiosInstance.post("util/filterData", f2x);
            console.log(tax4.data.data, "tax4");
            if (tax4.data.data.length != 0) {
              taxData = tax4.data.data[0];
            }
          } else {
            taxData = tax3.data.data[0];
          }
        } else {
          taxData = tax2.data.data[0];
        }
      } else {
        taxData = tax1.data.data[0];
      }
      // f2x.filterQuery = obj;
      // let x2x = await UPCAxiosInstance.post('util/filterData', f2x);
      // if (x2x.data.data.length == 0) {
      //   f2x.filterQuery = obj1;
      //   let x2x = await UPCAxiosInstance.post('util/filterData', f2x);
      //   taxData = x2x?.data?.data[0];
      // }
      // taxData = []
      console.log(
        recipient,
        taxData,
        this.getMatchingTaxes(taxData, recipient),
        "taxData"
      );
      this.recipientTaxes = this.getMatchingTaxes(taxData, recipient);
    },
    getMatchingTaxes(payload1, payload2) {
      // Safely extract the tax array from payload 1, default to an empty array if not present
      const taxArray1 = Array.isArray(payload1.tax) ? payload1.tax : [];

      // Safely extract the taxDefinition array from payload 2, default to an empty array if not present
      const taxDefinitionArray2 = Array.isArray(payload2.taxDefinition)
        ? payload2.taxDefinition
        : [];

      // Filter the taxDefinition array from payload 2 to get only checked items
      const checkedTaxes = taxDefinitionArray2.filter((tax) => tax.checked);

      // Map the checked taxes to get their tax names
      const checkedTaxNames = checkedTaxes
        .map((tax) => tax.taxname)
        .filter(Boolean); // Filter out undefined or null tax names

      // Filter the tax array from payload 1 to get items with names not matching the checked tax names
      const unmatchedTaxes = taxArray1.filter(
        (tax) => !checkedTaxNames.includes(tax.taxname)
      );

      // If there are unmatched taxes and taxType exists, add taxType to the first unmatched tax
      if (unmatchedTaxes.length > 0 && payload1.taxType) {
        unmatchedTaxes[0].taxType = payload1.taxType;
      }
      console.log("unmatchedTaxes......", unmatchedTaxes);

      // Ensure all keys in the returned array are correctly spelled
      const correctedUnmatchedTaxes = unmatchedTaxes.map((tax) => {
        return {
          taxName: tax?.taxname, // Correcting to taxName
          rate: tax?.rate,
          taxType:
            tax?.taxType?.toLowerCase() !== "compound"
              ? "cumulative"
              : "compound", // Ensure correct spelling
        };
      });
      return correctedUnmatchedTaxes;
    },
    receivePricingData(val) {
      console.log("fk 1", val);
      console.log(val, "quotePricingData --");
    },
    goTo() {
      this.tempBack = true;
      // // this.$router.back();
      // this.$router.push("/SM/supplier");
    },
    handleStay() {
      this.showSupplierDiscardModal = !this.showSupplierDiscardModal;
      this.tempBack = false;
    },
    handleDiscard() {
      //Set tempBack = true when Back is clicked from breadcrumbs
      if (this.tempBack) {
        this.$router.back();
      } else {
        this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      }

      this.showSupplierDiscardModal = !this.showSupplierDiscardModal;

      this.discardIt = true;
    },
    preventPageReload(event) {
      this.showSupplierDiscardModal = false;
      const unsavedChanges = this.isPayloadChanged(
        this.initialPayload,
        this.payload
      );
      if (!unsavedChanges) return;

      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
      return;
    },
    receiveSummaryfromRulestable(val) {
      console.log(val, "receiveTotalPricingDataTable");
    },
    receiveTotalPricingDataTable(val) {
      this.example_data[this.product_index].quoteTotalPrice =
        val.quoteTotalPrice;
      console.log(
        val,
        "receiveTotalPricingDataTable",
        this.example_data[this.product_index]
      );
    },
    concatValueRequired(val, assignedVal) {
      assignedVal = val;
      console.log(
        assignedVal,
        "assignedValassignedVal",
        this.basicReqiredFileds
      );
    },
    basicValueRequired(val) {
      this.basicReqiredFileds = val;
      console.log("basicValueRequired", this.basicReqiredFileds);
    },
    async fetchLanguageOptions() {
      await this.$store.dispatch("loginTenantDetails");

      this.asyncLanguageOptions = await this.addTranslationsSame(
        this.$store?.getters?.loginTenantDetails?.languageAbility
      );
      this.asyncLanguageOptions = this.asyncLanguageOptions.map((lang) => ({
        ...lang,
        default: lang?.code === "en" || lang?.name?.toLowerCase() === "english",
      }));

      console.log(this.asyncLanguageOptions, "this.asyncLanguageOptions;");
    },

    filterSchema(schema, selectedValue, subtreeValue) {
      if (!schema || Object.keys(schema).length === 0) return {};
      if (selectedValue.toLowerCase() !== "overview") return schema;

      return Object.fromEntries(
        Object.entries(schema).filter(
          ([, value]) =>
            value.section?.includes(selectedValue.toLowerCase()) &&
            value.tab?.includes(subtreeValue)
        )
      );
    },

    sortSchema(schema) {
      const entries = Object.entries(schema);
      entries.sort((a, b) => {
        const orderA = a[1].others?.displayOrder ?? Infinity;
        const orderB = b[1].others?.displayOrder ?? Infinity;
        return orderA - orderB;
      });
      return Object.fromEntries(entries);
    },

    // updateReadOnlyState(sortedData, view, permission) {
    //   Object.keys(sortedData).forEach((key) => {
    //     if (sortedData[key]?.others) {
    //       sortedData[key].others.readOnly = !view;

    //       if (view) {
    //         permission.Overview?.forEach(({ name, edit }) => {
    //           if (key === name && edit) {
    //             sortedData[key].others.readOnly = false;
    //           }
    //         });
    //       }
    //     }
    //   });
    // },

    deleteExecRow() {
      this.example_data.splice(this.deleteIndexValue, 1);
      this.field3Data.numberOfSites = this.example_data.length;
      this.field3Data.relatedParty = this.example_data;
      this.delete_modal_active = false;
    },
    showConfigurationPage(val) {
      console.log(val, "showConfigurationPage");
      this.show_configuration = true;
      this.isDepFlow = false;
      this.configOfferId = val.id;
    },
    addOrReplace(newObjects) {
      let array = this.example_data[this.chosenSiteIndex].pricingTableData;
      newObjects.forEach((newObject) => {
        // Remove any objects with overlapping categories
        array = array.filter((item) => {
          return !item.productSpecification.category.some((cat) =>
            newObject.category.includes(cat)
          );
        });
        // Add the new object to the array
        array.push(newObject);
      });

      return array;
    },
    async emittedLookupDataForRemapFromFieldContact([
      dataPayloadDup,
      schemaToBeMapped,
      schema,
    ]) {
      if (dataPayloadDup && schemaToBeMapped) {
        let dataPayload = JSON.parse(JSON.stringify(dataPayloadDup));
        console.log(
          schema,
          dataPayloadDup,
          "emittedLookupDataForRemapFromField1",
          schemaToBeMapped,
          "dataPayload",
          dataPayload
        );

        for (let i = 0; i < schemaToBeMapped.length; i++) {
          let schemaObjName = schemaToBeMapped[i].LHS;
          let schemaValue = schemaToBeMapped[i].RHS;
          let changeRhsValue = "payload";
          const firstDotIndex = schemaValue.indexOf(".");
          console.log(schemaValue, "dataPayloaddataPayload", dataPayload);
          if (firstDotIndex !== -1) {
            let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
            console.log(schemaObjName, "newRHS", newRHS);
            newRHS;
            let stringifiedDataPayload = JSON.parse(
              JSON.stringify(dataPayload)
            );

            let result = this.evaluateRHS(newRHS, stringifiedDataPayload);
            console.log("remappped data", result);

            if (result) {
              console.log(
                "this.leadSchema[schemaObjName].value",
                schemaObjName
              );
              if (schema[schemaObjName]) {
                console.log(
                  "this.leadSchema[schemaObjName].value 1 ",
                  schema[schemaObjName]
                );
                schema[schemaObjName].value = result;
                schema.isRemapData = false;
              }
            }
          }
        }
      }

      console.log(
        this.field3Data,
        this.contactDetailsFieldSchema,
        "contactDetailsFieldSchemacheck"
      );
    },
    chosenSubTab() {
      if (this.selectedTab === "Site details") {
        this.openSiteDetails(1, 0);
      }
    },
    suiteRunEm(data) {
      console.log(data, this.chosenSiteIndex, "suiteRunEm");
      this.example_data[this.chosenSiteIndex].suiteRun = data;
      this.field3Data.relatedParty = this.example_data;
    },
    nextContract() {
      // Find the index of the current selectedvalue
      const currentIndex = this.tabData.findIndex(
        (tab) => tab.name === this.selectedTab
      );

      // If there's a next value, update selectedvalue
      if (currentIndex !== -1 && currentIndex < this.tabData.length - 1) {
        this.selectedTab = this.tabData[currentIndex + 1].name;
      }
    },
    previousContract() {
      const currentIndex = this.tabData.findIndex(
        (tab) => tab.name === this.selectedTab
      );
      if (currentIndex > 0) {
        this.selectedTab = this.tabData[currentIndex - 1].name;
      }
    },
    async contractSave() {
      if (this.$route.params.section == "create") {
        const contractSave = await UPCAxiosInstance.post(
          `/agreementManagement`,
          this.field3Data
        );

        const taskList = await DMNAxiosInstance.post(
          `/rule_engine/rule/execute/67922ba776f4164534ee8264`,{
            payload:{
              contract:contractSave.data
            }
          }
          
        );
     let taskRule = await taskList.data.data.contract.data;
     const addExec = await UPCAxiosInstance.post(`/task`,taskRule);

        // const taskList =  await this.generateContractData(contractSave.data)
        console.log(addExec.data,"taskList save contract",contractSave.data);
        this.notification = {
          type: "success",
          message: `Nice work! Your new contarct is now in the list!`,
        };
        this.$router.push(`/contractManagement`);
      } else {
        this.field3Data.id = this.$route.params.id;
        delete this.field3Data._id;
        const contractSave = await UPCAxiosInstance.patch(
          `/agreementManagement`,
          this.field3Data
        );
        this.field3Data = await contractSave.data;
        this.notification = {
          type: "success",
          message: `All set! Your changes have been saved successfully!`,
        };
        console.log(contractSave.data);
      }
    },
    transformData(originalData) {
      console.log(originalData, "og_data");
      return originalData.map((item) => {
        const {
          name,
          description,
          lifecycleStatus,
          category,
          productSpecification,
          prodSpecCharValue,
          _id,
          productOfferingPrice,
        } = item;
        console.log("offering data card", item);
        const updatedProdSpecCharValue =
          this.getUpdatedProductSpecCharValue(prodSpecCharValue);
        updatedProdSpecCharValue;
        const lastCategory =
          category.length > 0 ? category[category.length - 1] : "";

        const pricing = this.getPricing(productOfferingPrice);
        const oneTimePrice = this.calculateOneTimePrice(pricing);
        oneTimePrice;
        let billingPeriodOptions = null;
        billingPeriodOptions = [
          ...new Set(
            updatedProdSpecCharValue?.map((item) => item?.billingPeriod)
          ),
        ];
        let whole = "0";
        let fraction = "00";
        let newWhole, newFraction;

        if (item?.discountResponse?.price !== undefined) {
          [whole, fraction] = String(item.discountResponse?.price)?.split(".");
          newWhole = whole !== undefined ? whole : "0";
          newFraction = fraction !== undefined ? fraction : "00";
        } else if (!item.isBundle) {
          [whole, fraction] = String(
            item.updatedProdSpecCharValue[0]?.tiers[0]?.price?.value
          )?.split(".");
          newWhole = whole !== undefined ? whole : "0";
          newFraction = fraction !== undefined ? fraction : "00";
        } else {
          let items = item.productOfferingPrice.filter((item) => {
            return item.lifecycleStatus == "Active";
          });
          console.log(items[0]?.tiers[0]?.price?.value, "ganesh");

          [whole, fraction] = String(items[0]?.tiers[0]?.price?.value)?.split(
            "."
          );
          newWhole = whole !== undefined ? whole : "0";
          newFraction = fraction !== undefined ? fraction : "00";
        }
        // let [whole, fraction] = String(
        //   item.updatedProdSpecCharValue[0]?.tiers[0]?.price?.value
        // )?.split(".");
        // let newWhole, newFraction;
        // newWhole = whole !== undefined ? whole : "0";
        // newFraction = fraction !== undefined ? fraction : "00";
        console.log("prechana", { newWhole, newFraction });
        return {
          offerId: item._id,
          unitOfMeasure: item.unitOfMeasure,
          additionalCharges: item.additionalCharges,
          img_url: updatedProdSpecCharValue[0]?.attachment,
          currency: updatedProdSpecCharValue[0]?.tiers[0]?.price?.unit,
          discountResponse: item.discountResponse,
          productOfferingPrice: item.productOfferingPrice,
          relatedParty: item.relatedParty,
          name: name || "",
          description: description || "",
          status: lifecycleStatus || "",
          category: lastCategory || "",
          section: false,
          offerType: item.offerType,
          custom: false,
          address: false,
          brand: productSpecification?.brand || "",
          duration: updatedProdSpecCharValue[0]?.commitmentLength || 1,
          quantity: 1,
          skuid: updatedProdSpecCharValue[0]?.id,
          max_quantity:
            updatedProdSpecCharValue[0]?.tiers[
              updatedProdSpecCharValue[0]?.tiers?.length - 1
            ]?.max,
          billing_period: updatedProdSpecCharValue[0]?.billingPeriod,
          priceType: updatedProdSpecCharValue[0]?.priceType,
          billing_period_options: billingPeriodOptions?.map((value) => ({
            name: value,
            value: value,
          })) || [{ name: "Monthly", value: "Monthly" }],
          id: _id || "",
          offering_type:
            productSpecification?.["@baseType"]?.toLowerCase() || "",
          // pricing: pricing,
          pricing: updatedProdSpecCharValue[0]?.tiers[0]?.price?.value,
          prodSpecCharValue: updatedProdSpecCharValue,
          prodSpecCharValueUse: item.prodSpecCharValueUse,
          // price: oneTimePrice
          //   ? {
          //       whole: oneTimePrice.whole || "0",
          //       fraction: oneTimePrice.fraction || "00",
          //     }
          //   : { whole: "0", fraction: "00" },
          price: { whole: newWhole, fraction: newFraction },
          productSpecification: {
            ...item.productSpecification,
            "@type": "ProductSpecification",
          },
          productOffering: {
            name: item?.name,
            id: item._id,
            "@type": "ProductOffering",
            href: item.href,
          },
          productCharacteristic: item.prodSpecCharValueUse,
        };
      });
    },
    async getProductImageData(val) {
      if (
        val.prodSpecCharValue[0]?.attachment !== undefined &&
        val?.prodSpecCharValue[0]?.attachment?.length > 0
      ) {
        console.log(val, "image data for products");
        const url = await UPCAxiosInstance.get(
          `/gcpupload/files/${val.prodSpecCharValue[0]?.attachment[0]?.name}`,
          {
            responseType: "arraybuffer",
          }
        );
        // console.log(await this.bufferToDataURL(url.data), "urlll get");
        this.imageComponentKey += 1;
        val.prodSpecCharValue[0].attachment[0].image_url =
          await this.bufferToDataURL(url.data);
        // this.rawProfileUrlData = url.data;
      } else if (val.prodSpecCharValue[0] !== undefined) {
        val.prodSpecCharValue[0].attachment = [];
        val.prodSpecCharValue[0].attachment.push({
          image_url: "",
        });
      }
    },
    getPricing(prices) {
      console.log(prices, "card_data");
      if (!prices || prices.length === 0) return [];

      const priceMap = {};
      prices.forEach((price) => {
        const { pricingType, productofferPriceAlteration } = price;
        if (
          productofferPriceAlteration &&
          productofferPriceAlteration.length > 0
        ) {
          console.log("kulla if");
          productofferPriceAlteration.forEach((alteration) => {
            if (alteration.priority === "1") {
              if (
                !priceMap[pricingType] ||
                priceMap[pricingType].priority !== "1"
              ) {
                priceMap[pricingType] = {
                  name: price?.name || "",
                  pricingType: pricingType || "",
                  amount: alteration.productPrice.taxAmount?.Amount
                    ? alteration?.productPrice?.taxAmount?.Amount?.toString()?.replace(
                        "£",
                        ""
                      )
                    : "",
                  priority: alteration?.priority || "",
                };
              }
            }
          });
        } else {
          // console.log('kulla veliya if', price.productPrice.dutyFreeAmount);
          priceMap[pricingType] = {
            name: price?.name || "",
            pricingType: pricingType || "",
            amount: price?.productPrice?.dutyFreeAmount
              ? price?.productPrice?.dutyFreeAmount
                  ?.toString()
                  ?.replace("£", "")
              : "",
            priority: price?.productPrice?.priority || "",
          };
        }
      });

      return Object.values(priceMap).map((price) => ({
        ...price,
        amount: price.amount || "",
      }));
    },
    getUpdatedProductSpecCharValue(val) {
      let activeSku;
      activeSku = val.filter((item) => {
        return item.lifecycleStatus == "Active";
      });
      if (activeSku.length == 0) {
        activeSku = val.filter((item) => {
          return item.default == true;
        });
      }
      return activeSku;
    },
    calculateOneTimePrice(pricing) {
      console.log("pricing card_data2", pricing);
      const oneTimePrice = pricing.find(
        (price) =>
          price?.name === "One time" ||
          price?.name === "Recurring" ||
          price?.name === "Pay per use price"
      );
      if (oneTimePrice) {
        const amount = oneTimePrice.amount;
        const [whole, fraction] = amount.split(".");
        return { whole, fraction };
      }
      return null;
    },
    async selectedOffer($event, card) {
      console.log("newbundle", $event, card);

      const ele = $event.target;
      if (ele.classList.contains("item-added-to-cart")) {
        //
      } else if (ele.parentElement.classList.contains("item-added-to-cart")) {
        ///f
      } else {
        card = await this.supplierChanged(card);
        console.log(card, "new card");
        if (card.offerType === "Bundle") {
          this.selectedProductOfferingData.push(
            JSON.parse(JSON.stringify(card))
          );
          console.log(card, "bundleIndex b");
          card.prodSpecCharValue.forEach((i, index) => {
            console.log(i, index, "bundleIndex");

            i["bundleIndex"] = i._id + "id" + index;
            i["quantity"] = 1;
            i["billing_period"] = i.billingPeriod;
            let [whole, fraction] = String(i.tiers[0].price.value)?.split(".");
            i["price"] = { whole: whole, fraction: fraction };
            this.selectedProductOfferingData.push(
              JSON.parse(JSON.stringify(i))
            );
          });
        } else {
          this.selectedProductOfferingData.push(
            JSON.parse(JSON.stringify(card))
          );
        }
        this.sendSelectedProductOfferingData = [
          ...toRaw(JSON.parse(JSON.stringify(this.tempPricingTableData))),
          ...toRaw(this.selectedProductOfferingData),
        ];
        console.log(
          "selected offers",
          this.selectedProductOfferingData,
          this.sendSelectedProductOfferingData
        );
      }
    },
    async addNewOffer($event, index) {
      let data = $event;
      this.product_index = index;
      this.selectedProductOfferingData = [];

      this.card_data_length = this.field3Data.productRef.total;

      this.card_data = this.transformData(this.field3Data.productRef);
      console.log("card_data", this.card_data);
      this.card_data.forEach((i) => {
        this.getProductImageData(i);
      });
      this.cartItems = [];
      this.show_offering = true;
      this.show_side_panel = false;
      this.filterPrevalueDuplicate1 = JSON.parse(
        JSON.stringify(this.filterPrevalue)
      );
      this.filterPrevalueDuplicate2 = JSON.parse(
        JSON.stringify(this.filterPrevalue)
      );

      if (data !== undefined) {
        this.tempPricingTableData = JSON.parse(JSON.stringify(data));
      }
      console.log(this.tempPricingTableData, "edit data");
    },
    async offeringFilterData(filterSelectedvalue, filterQuery) {
      filterSelectedvalue, filterQuery;
      this.filterPrevalue = this.transformFilterPayload(filterSelectedvalue);

      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      filterQuery.company = this.company;
      this.filterQuery["paginatedQuery"] = {
        rowsPerPage: this.externalRowsPerPage,
        currentPage: this.currentPage,
      };
      //  filterQuery['filterQuery']['"@baseType"'] = 'supplier'
      if (this.filtertagvalue.length != 0) {
        console.log("filter ku ulla");

        this.filterQuery = filterQuery;
        this.filterQuery["module"] = "discountOffers";
        this.filterQuery.type = "filter";
        if (this.searchvalue != "") {
          this.filterQuery["searchQuery"] = this.searchvalue;
          this.filterQuery["type"] = "filter-search";
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.filterQuery
        );
        console.log(filterResultData, "filterResultData.data.data");

        this.card_data_length = filterResultData.data.total;
        this.card_data = this.transformData(filterResultData.data.data);
        console.log("card _data", this.card_data);
        this.card_data.forEach((i) => {
          this.getProductImageData(i);
        });
        // this.getOfferData();
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
      } else {
        console.log("filter ku veliya");

        this.filterPrevalue = {};
        this.filterQuery.filterQuery = {};
        this.filterPrevalueDuplicate1 = {};
        this.filterPrevalueDuplicate2 = {};
        this.offeringcurrPage = 1;
        this.offeringrowsPerPage = 10;
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.filterQuery
        );
        this.card_data_length = filterResultData.data.total;
        this.card_data = this.transformData(filterResultData.data.data);
        console.log("card _data", this.card_data);
        this.card_data.forEach((i) => {
          this.getProductImageData(i);
        });
        console.log(filterResultData, "filterResultData.data.data");
        // this.offeringdataCalling(
        //   this.offeringcurrPage,
        //   this.offeringrowsPerPage
        // );
        // this.getOfferData();
      }
      console.log(
        filterSelectedvalue,
        filterQuery,
        this.transformFilterPayload(filterSelectedvalue),
        this.filterPrevalue,
        "configure filter data"
      );
    },
    scrollToDiv() {
      this.$nextTick(() => {
        const element = document.getElementById("pricing-table-container-#1");
        if (element) {
          // Scroll smoothly to the target element
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    clickBack() {
      console.log("edit data 6", this.tempPricingTableData);
      this.show_offering = false;
      this.show_side_panel = false;
      this.example_data[this.product_index].pricingTableData =
        this.addOrReplace(this.selectedProductOfferingData);
      // this.totalPricingData = this.example_data.reduce(
      //   (acc, obj) =>
      //     acc.concat(JSON.parse(JSON.stringify(obj.pricingTableData))),
      //   []
      // );
      this.field3Data.relatedParty = this.example_data;

      console.log(
        this.selectedProductOfferingData,
        "this.selectedProductOfferingData"
      );
      this.searchOffers("");
      this.quoteSummaryDataFromRules = JSON.parse(
        JSON.stringify(this.tempQuoteSummaryDataFromRules)
      );
      if (this.selectedProductOfferingData.length === 0) {
        console.log(
          "edit data 6.1",
          this.sendSelectedProductOfferingData,
          this.tempPricingTableData
        );
        const x = JSON.parse(JSON.stringify(this.tempPricingTableData));
        // this.sendSelectedProductOfferingData = JSON.parse(JSON.stringify(x));
        this.sendSelectedProductOfferingData = this.addOrReplace(
          this.selectedProductOfferingData
        );

        console.log(
          "edit data 6.2",
          this.sendSelectedProductOfferingData,
          this.tempPricingTableData,
          x
        );
      }
      this.scrollToDiv();
      this.pricingTotalSummery += 1;
      this.selectedSubTab = "product_details";
      console.log(this.selectedSubTab, "subTab");
    },
    addToCart($event, item) {
      console.log("🚀 ~ addToCart ~ $event:", $event);
      this.cartItems.push(item.id);
    },
    async supplierChanged(card) {
      let val2 = card.relatedParty.filter((i) => {
        return i.role == "supplier";
      });
      let val = val2[0];
      let x = card?.productOfferingPrice[0]?.productOfferingAlteration?.filter(
        (i) => {
          return i?.company == val?.name;
        }
      );
      this.selectedSupplier = val;
      this.selectedConfigurationCost = val?.msrp?.value;

      if (this.newPrice !== null) {
        if (val?.productStockLevel?.cost == 0) {
          // trigger rules
          const supplierRules = await DMNAxiosInstance.post(
            `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
            {
              payload: {
                supplier: {
                  product: [
                    {
                      productOfferingId: card._id,
                      msrp: val?.msrp?.value,
                      sellingPrice: this.newPrice,
                      discount: {
                        skuDiscount: {
                          value: x !== undefined ? x[0].discount : "0",
                          active: 1,
                          cost: 0,
                          startDate:
                            x !== undefined ? x[0]?.validFor?.startDate : "",
                          endDate:
                            x !== undefined ? x[0]?.validFor?.endDate : "",
                        },
                        categoryDiscount: 0,
                        brandDiscount: 0,
                      },
                    },
                  ],
                },
              },
            }
          );
          supplierRules;
          card.supplierUnitCost =
            supplierRules?.data?.data?.supplier?.product[0]?.cost;
          card.rebate = 0;
          this.selectedConfigurationMargin =
            supplierRules?.data?.data?.supplier?.product[0]?.margin?.percentage;
        } else {
          const supplierRules = await DMNAxiosInstance.post(
            `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
            {
              payload: {
                supplier: {
                  product: [
                    {
                      productOfferingId: card._id,
                      msrp: val?.msrp?.value,
                      sellingPrice: this.newPrice,
                      discount: {
                        skuDiscount: {
                          value: 0,
                          active: 1,
                          cost: val?.productStockLevel?.cost,
                          startDate: "",
                          endDate: "",
                        },
                        categoryDiscount: 0,
                        brandDiscount: 0,
                      },
                    },
                  ],
                },
              },
            }
          );
          supplierRules;
          card.supplierUnitCost = val?.productStockLevel?.cost;
          this.selectedConfigurationMargin =
            supplierRules?.data?.data?.supplier?.product[0]?.margin?.percentage;
          card.rebate = 0;
        }
      }
      return card;
    },
    searchOffers(v) {
      this.search_offering = v;
      this.getOfferData();
    },
    async getOfferData() {
      console.log("get offer");
      let Specfilterquery = {
        type: "search",
        module: "discountOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.externalRowsPerPage,
          currentPage: this.currentPage,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
          // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
        },
        searchQuery: this.search_offering,
      };

      Specfilterquery;
      // const filterResultData = await UPCAxiosInstance.post(
      //   `util/filterData`,
      //   Specfilterquery
      // );
      const filterResultData = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/listOffers?pageNo=${this.currentPage}&pageSize=${this.externalRowsPerPage}`
      );
      this.filterQuery["company"] = this.company;
      this.filterQuery["searchQuery"] = this.search_offering;
      this.filterQuery["paginatedQuery"] = {
        rowsPerPage: this.externalRowsPerPage,
        currentPage: this.currentPage,
      };
      if (this.filtertagvalue.length != 0) {
        this.filterQuery["type"] = "filter-search";
      } else {
        this.filterQuery["type"] = "search";
      }
      if (this.retiredvmodel === true) {
        console.log("true...");

        this.filterQuery.filterQuery = { lifecycleStatus: "Inactive" };
      } else {
        console.log("false...");

        this.filterQuery.filterQuery = {};
      }
      const filterResultData2 = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQuery
      );
      // this.offering_data = filterResultData.data.data
      console.log("raw offer", filterResultData);
      console.log("Offering data", filterResultData2.data.total);
      this.card_data_length = filterResultData2.data.total;
      console.log(
        filterResultData2.data.data,
        this.filterQuery,
        "filterResultData2.data.data..."
      );

      this.card_data = this.transformData(filterResultData2.data.data);
      console.log("card_data", this.card_data);
      this.card_data.forEach((i) => {
        this.getProductImageData(i);
      });
    },
    siteFieldsData(val) {
      this.example_data[this.chosenSiteIndex] = val;
      this.field3Data.relatedParty = this.example_data;

      console.log(
        val,
        "storeDataFromField3 siteFieldthis.field3Data.relatedPartys",
        this.field3Data.relatedParty
      );
    },
    addNotes(val) {
      console.log(val, "addNotes");
      this.field3Data.note = val;
    },
    editNotes(val, index) {
      console.log(val, index, "editNotes");
      this.field3Data.note = val;
    },
    deleteNotes(val) {
      // console.log(index, "deleteNotes");
      this.field3Data.note = val;
      // this.field3Data.note.splice(index, 1);
    },
    async storeDataFromField3(val) {
      console.log(val, "storeDataFromField3");
      let type = val.contractType;
      let conditionForType = val.contractType !== this.field3Data.contractType;

      // Mapping categories based on contract type
      const categoryMap = {
        "Bulk Tv": ["Fibe TV"],
        "Bulk Internet": ["Fibe Internet"],
        "Bulk Phone": ["Fibe phone"],
        "Bulk Tv + Bulk Internet": ["Fibe TV", "Fibe Internet"],
        "Bulk Tv + Bulk Internet + Bulk Phone": [
          "Fibe TV",
          "Fibe Internet",
          "Fibe phone",
        ],
      };
      let mappedInfo = categoryMap[type] || [];

      if (mappedInfo.length > 0) {
        const addInfo = await this.addOn(mappedInfo);
        const infoMapped = await this.fetchFilteredData(mappedInfo);
        this.field3Data.productRef = infoMapped;
        if (infoMapped.length > 0) {
          this.productRule = await this.fetchProductRules(
            mappedInfo,
            infoMapped
          );
          this.productRule = this.productRule.concat(addInfo);
          console.log(this.productRule, "field3Data.productRef");
        }
      }

      const transformedData = this.transformData(this.productRule);
      this.tranformData = transformedData;
      const prop = "rate"; // or 'quantity' or 'amount'
      const pricing_table_data = transformedData; // your actual data
      const total_wise_discount_array_onetime = [];
      const item_wise_discount_onetime = [];
      const total_wise_discount_array_recurring = [];
      const item_wise_discount_recurring = [];
      const additionalChargesArray = [];
      const taxData = [];

  
      this.totalPriceRec_one =await this.triggerPricingRules(
          prop,
          pricing_table_data,
          total_wise_discount_array_onetime,
          item_wise_discount_onetime,
          total_wise_discount_array_recurring,
          item_wise_discount_recurring,
          additionalChargesArray,
          taxData
        ),
   console.log(this.totalPriceRec_one,"receiveTotalPricingDataTable")
      
      this.adjustExampleData(val, transformedData);

      if (conditionForType) {
        this.example_data.forEach((item) => {
          item.pricingTableData = transformedData;
        });
      }

      this.updateField3Data(val);
      console.log(
        this.productRule,
        "Product Rule",
        val,
        "Field 3 Data",
        this.field3Data,
        this.billingDetailsFieldSchema["billingAddress"]
      );
    },
    async addOn(services) {
      console.log(services, "addOn");
      const replacements = {
        "Fibe TV": "Fibe Device",
        "Fibe Internet": "Fibe Router",
      };

      const mappedInfo = services.map((service) => replacements[service]);
      const response = await UPCAxiosInstance.post(`util/filterData`, {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: { rowsPerPage: 10, currentPage: 1 },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 100,
        filterQuery: { category: { $in: mappedInfo }, offerType: "Atomic" },
        searchQuery: "",
      });

      console.log(response.data.data, "Add on");
      const productOfferings = response.data.data || [];
      const processedData = productOfferings.reduce(
        (acc, product) => {
          if (!acc.categoryMap.has(product.category)) {
            acc.categoryMap.set(product.category, true);
            acc.processed.push({
              ...product,
              productOfferingPrice: [
                {
                  ...product,
                  tiers: [
                    {
                      min: 1,
                      max: 9,
                      price: {
                        unit: "CAD",
                        value: 0,
                      },
                    },
                  ],
                  MSRP: { unit: "CAD", value: 0 },
                },
              ],
              prodSpecCharValue: [
                {
                  ...product,
                  tiers: [
                    {
                      min: 1,
                      max: 9,
                      price: {
                        unit: "CAD",
                        value: 0,
                      },
                    },
                  ],
                  MSRP: { unit: "CAD", value: 0 },
                },
              ],
            });
          }
          return acc;
        },
        { processed: [], categoryMap: new Map() }
      ).processed;

      console.log(processedData, "processed Info");
      return processedData;
    },
    async fetchFilteredData(mappedInfo) {
      const response = await UPCAxiosInstance.post(`util/filterData`, {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: { rowsPerPage: 10, currentPage: 1 },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 100,
        filterQuery: { category: { $in: mappedInfo }, offerType: "Atomic" },
        searchQuery: "",
      });
      console.log(response.data, "Mapped Info from type", mappedInfo);
      return response.data.data || [];
    },
    async fetchProductRules(mappedInfo, products) {
      const response = await DMNAxiosInstance.post(
        `/rule_engine/rule/execute/67891ac35ab16ce855ba2df8`,
        {
          payload: { execution: { categories: mappedInfo, products } },
        }
      );
      return response.data.data.execution.productSelected || [];
    },
    adjustExampleData(val, transformedData) {
      let targetCount = val.numberOfSites;
      const dataTemplate = this.createDataTemplate(transformedData);

      if (targetCount > 0) {
        if (this.example_data.length > targetCount) {
          this.example_data = this.example_data.slice(0, targetCount);
        } else {
          this.example_data = [
            ...this.example_data,
            ...Array(targetCount - this.example_data.length).fill(dataTemplate),
          ];
        }
      } else {
        this.example_data = [];
      }
    },
    createDataTemplate(pricingTableData) {
      return {
        pricingTableData,
        address: "",
        name: "",
        units: "",
        description: null,
        siteAddress: [this.createEmptyAddress()],
        siteName: "",
        firstOccupancyDate: "",
        equipmentNetworkId: null,
        marketSegment: "",
        networkId: null,
        networkManager: { value: "", email: "", id: "" },
        numberOfUnits: "",
        sfdcId: "",
        status: null,
      };
    },
    createEmptyAddress() {
      return {
        mediumType: "postalAddress",
        characteristic: {
          "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          postalCodePrefix: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
          latitude: "",
          longitude: "",
        },
      };
    },
    updateField3Data(val) {
      this.field3Data = JSON.parse(JSON.stringify(val));
      this.field3Data.relatedParty = this.example_data;

      if (!this.field3Data.companyName) {
        this.updatedAddressCompKey = true;
        this.field3Data.billingAddress = [this.createEmptyAddress()];
        this.updateBillingDetailsSchema();
      }
    },
    updateBillingDetailsSchema() {
      Object.keys(this.billingDetailsFieldSchema).forEach((key) => {
        const field = this.billingDetailsFieldSchema[key];
        if (field.displayType === "siteaddress") {
          field.value = [this.createEmptyAddress()];
        } else {
          field.value = "";
        }
      });
    },
    storeBilling(val) {
      console.log(val, "check the emit of the filed render", this.field3Data);

      console.log(val, "storeDataFromField3 billing");
      this.field3Data = val;
    },
    storeContact(val) {
      console.log(val, "storeDataFromField3");
      console.log(val, "check the emit of the filed render", this.field3Data);

      this.field3Data = val;
    },
    async addAttachment(event) {
      try {
        console.log(event, "eventeventaddAttachment");
        if (!Array.isArray(event) || event.length === 0) {
          throw new Error("Invalid or empty file array provided.");
        }

        const formData = new FormData();
        event.forEach((file, index) => {
          if (!(file instanceof File)) {
            console.warn(`Item at index ${index} is not a valid File object.`);
            return;
          }
          formData.append("files[]", file);
        });

        const response = await UPCAxiosInstance.post(
          `/gcpMultiple/v2/upload/`,
          formData
        );
        if (!response || !response.data) {
          throw new Error("No response data received from the upload API.");
        }

        const formattedData = response.data
          .map((item) => {
            if (!item.filename || !item.data?.attachment?.size) {
              console.warn("Invalid attachment data in API response:", item);
              return null;
            }
            return {
              properties: {
                name: item.filename,
                size: item.data.attachment.size,
              },
            };
          })
          .filter(Boolean); // Remove null values

        if (formattedData.length === 0) {
          console.warn(
            "No valid attachments were processed from the API response."
          );
          return;
        }

        this.field3Data.attachment = [
          ...this.field3Data.attachment,
          ...formattedData,
        ];

        this.attachmentFilteredData = this.field3Data.attachment.filter(
          (item) => item["@type"] !== "profile"
        );
      } catch (error) {
        console.error("Error adding attachment:", error.message);
      }
    },
    removeAttachment(val, index) {
      try {
        if (typeof index !== "number" || index < 0) {
          throw new Error("Invalid index for attachment removal.");
        }

        this.attachmentFilteredData = this.field3Data.attachment.filter(
          (item) => item["@type"] !== "profile"
        );

        const profileAttachments = this.field3Data.attachment.filter(
          (item) => item["@type"] === "profile"
        );

        if (index >= this.attachmentFilteredData.length) {
          throw new Error(
            `Index ${index} out of bounds for attachment removal.`
          );
        }

        // Remove the specified attachment
        const removedItem = this.attachmentFilteredData.splice(index, 1);
        console.log("Removed attachment:", removedItem);

        // Combine updated filtered data with profile attachments
        this.field3Data.attachment = [
          ...this.attachmentFilteredData,
          ...profileAttachments,
        ];
      } catch (error) {
        console.error("Error removing attachment:", error.message);
      }
    },
    async downloadAttachmnet(val) {
      const url = await UPCAxiosInstance.get(
        `/gcpupload/files/${val.properties.name}`,
        {
          responseType: "arraybuffer",
        }
      );

      this.downloadFromGCP(url.data, val.properties.name);
    },
    async downloadFromGCP(urlFetch, name) {
      console.log(urlFetch, name);
      const type = this.getFileTypeFromMimeType(name);
      const blob = new Blob([urlFetch], { type: type });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = name;
      document.body.appendChild(a);

      a.click();
    },
    getFileTypeFromMimeType(mimeType) {
      // Map MIME types to file types
      const mimeTypeMap = {
        "image/jpeg": "JPEG",
        "image/png": "PNG",
        "application/pdf": "PDF",
        "text/plain": "TXT",
        // Add more MIME types as needed
      };

      // Lookup the file type based on the MIME type
      const fileType = mimeTypeMap[mimeType] || "Unknown";

      // Convert the file type back to MIME type format if found, otherwise return the original MIME type
      return fileType !== "Unknown"
        ? `application/${fileType.toLowerCase()}`
        : mimeType;
    },
    emittedLookupDataForRemapFromFieldSite([
      dataPayloadDup,
      schemaToBeMapped,
      schema,
    ]) {
      if (dataPayloadDup && schemaToBeMapped) {
        let dataPayload = JSON.parse(JSON.stringify(dataPayloadDup));
        console.log(
          schema,
          dataPayloadDup,
          "emittedLookupDataForRemapFromFieldsite blast",
          schemaToBeMapped,
          "dataPayload blast",
          dataPayload
        );

        for (let i = 0; i < schemaToBeMapped.length; i++) {
          let schemaObjName = schemaToBeMapped[i].LHS;
          let schemaValue = schemaToBeMapped[i].RHS;
          let changeRhsValue = "payload";
          const firstDotIndex = schemaValue.indexOf(".");
          console.log(schemaValue, "dataPayloaddataPayload", dataPayload);
          if (firstDotIndex !== -1) {
            let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
            console.log(schemaObjName, "newRHS", newRHS);
            newRHS;
            let stringifiedDataPayload = JSON.parse(
              JSON.stringify(dataPayload)
            );
            let result = this.evaluateRHS(newRHS, stringifiedDataPayload);
            console.log("remappped data", result);

            if (result) {
              console.log(
                "this.leadSchema[schemaObjName].value blast",
                schemaObjName
              );
              if (schema[schemaObjName]) {
                console.log(
                  "storeDataFromField3 this.leadSchema[schemaObjName].value 1 ",
                  schema[schemaObjName]
                );
                schema[schemaObjName].value = result;
                this.example_data[this.chosenSiteIndex].lookupData = true;
                schema.isRemapData = false;
              }
            }
            console.log(
              this.example_data[this.chosenSiteIndex],
              "this.example_data[this.currentIndex]"
            );
          }
        }
      }
    },
    async emittedLookupDataForRemapFromField3([
      dataPayloadDup,
      schemaToBeMapped,
      schema,
    ]) {
      if (dataPayloadDup && schemaToBeMapped) {
        let dataPayload = JSON.parse(JSON.stringify(dataPayloadDup));

        this.field3Data.companyName = dataPayload.name || "";

        this.selectedLeadAccountRef = dataPayload?.accountRef;
        this.openSiteDetails(1, 0);
        for (let i = 0; i < schemaToBeMapped.length; i++) {
          let schemaObjName = schemaToBeMapped[i].LHS;
          let schemaValue = schemaToBeMapped[i].RHS;
          let changeRhsValue = "payload";
          const firstDotIndex = schemaValue.indexOf(".");

          if (firstDotIndex !== -1) {
            let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
            newRHS;
            let stringifiedDataPayload = JSON.parse(
              JSON.stringify(dataPayload)
            );
            let result = this.evaluateRHS(newRHS, stringifiedDataPayload);

            console.log(result, "resultevaluateRHS");

            if (result) {
              if (schema[schemaObjName]) {
                schema[schemaObjName].value = result;
                schema.isRemapData = false;
              }
              if (this.contactDetailsFieldSchema[schemaObjName]) {
                this.contactDetailsFieldSchema[schemaObjName].value = result;
                if (schemaObjName == "phoneNumber") {
                  this.contactDetailsFieldSchema[schemaObjName].value = result;
                }
              }
              if (this.billingDetailsFieldSchema[schemaObjName]) {
                this.billingDetailsFieldSchema[schemaObjName].value = result;

                if (this.field3Data?.companyName == "") {
                  this.field3Data.billingAddress = [];
                  this.billingDetailsFieldSchema["billingAddress"].value = [];
                }
                this.field3Data.billingAddress = result;
                this.field3Data.contactMedium = result;
              }
            }
          }
        }
      }
      dataPayloadDup.relatedParty = dataPayloadDup.relatedParty.filter((i) => {
        return i["@type"] !== "leadOwner";
      });

      console.log(dataPayloadDup.relatedParty, "dataPayloadDup.relatedParty");

      let relatedPartyArray = [];

      // for (const i of dataPayloadDup.relatedParty) {
      //   try {
      //     const con = await MLTAxiosInstance.get(
      //       `/partymanagement/individual/myinfo/${
      //         i.id
      //       }?company=${"developerHalleyx"}`
      //     );

      //     if (con.data.pipeLine === undefined) {
      //       con.data.newRole = con?.data?.role?.name;
      //       relatedPartyArray.push(con.data);
      //     }
      //   } catch (error) {
      //     console.error(`Error fetching data for id ${i.id}:`, error);
      //   }
      // }
      if (relatedPartyArray.length > 0) {
        relatedPartyArray[0].default = true;
      }

      this.schema.contactInfo.schema["chooseContact"].options =
        relatedPartyArray;

      this.field3Data = JSON.parse(JSON.stringify(this.field3Data));

      console.log(this.field3Data, "this.field3Datathis.field3Data");

      //   // this.schema = { ...this.schema };

      //   this.field3Data = { ...this.field3Data };
      // }, 1300);
    },

    evaluateRHS(RHS, dataPayload) {
      this.dataPayloadForRemap = JSON.parse(JSON.stringify(dataPayload));
      console.log(
        dataPayload,
        "this.dataPayloadForRemap",
        this.dataPayloadForRemap
      );
      try {
        const cleanedRHS = RHS.replace(/payload/g, "dataPayloadForRemap")
          .replace(/\n/g, " ")
          .trim();
        console.log(
          this.dataPayloadForRemap,
          cleanedRHS,
          "dataPayloadForRemap",
          dataPayload
        );
        // Use Function constructor to evaluate the expression safely
        // eslint-disable-next-line no-new-func
        const result = new Function(
          "dataPayloadForRemap",
          `return ${cleanedRHS};`
        )(this.dataPayloadForRemap);
        return result;
      } catch (error) {
        console.error("Error evaluating RHS:", error);
        return null;
      }
    },
    newSiteadd() {
      this.example_data.push({
        pricingTableData: this.tranformData,
        quoteTotalPrice: this.totalPriceRec_one,
        address: "",
        lookupData: false,
        name: "",
        units: "",
        description: null,
        siteAddress: [
          {
            characteristic: {
              city: "",
              unit: "",
              country: "",
              state: "",
              postalCode: "",
              postalCodePrefix: "",
              stateOrProvince: "",
              latitude: "",
              longitude: "",
              street1: "",
              street2: "",
              fsa: "",
              description: "",
              street: "",
              default: true,
              "@type": "",
              numberOfUnits: "",
              stateCode: "ON",
            },
            mediumType: "postalAddress",
          },
        ],
        siteName: "",
        firstOccupancyDate: "",
        equipmentNetworkId: null,
        marketSegment: "",
        networkId: null,
        networkManager: {
          value: "",
          email: "",
          id: "",
        },
        numberOfUnits: "",
        sfdcId: "",
        status: null,
      });
      this.field3Data.numberOfSites = this.example_data.length;
    },
    deleteSite(index) {
      this.delete_name = this.example_data[index]?.siteName;
      console.log(this.delete_name, "this.delete_name");
      this.deleteIndexValue = index;
      this.delete_modal_active = true;
    },

    closeSiteDetails(index, i) {
      console.log(
        i,
        "closeSiteDetailscloseSiteDetails",
        index,
        this.example_data
      );

      this.isSaveButtonClicked = true;
      console.log(
        this.chosenSiteIndex,
        "Forcing showSiteInformationModal to false",
        index
      );
      //     }
      // }, 50);
      if (this.chosenSiteIndex != index) {
        this.openSiteDetails(i, index);
      } else if (this.chosenSiteIndex == index) {
        this.chosenSiteIndex = null;
        // this.showSiteInformationModal = false;
        // this.showAllSitesModal = true;
      }
      console.log(
        i,
        "closeSiteDetailscloseSiteDetails",
        index,
        this.example_data[this.chosenSiteIndex]
      );
      this.exampleDataSite = this.example_data[this.chosenSiteIndex];
    },
    async openSiteDetails(i, index) {
      console.log(index, "openSiteDetails", i);
      this.chosenSiteIndex = i - 1;
      this.product_index = i - 1;
      this.showSiteInformationModal = true;
    },
    async selectedValueFunc(val) {
      if (val.name === "Product details") {
        this.totalPricingData = this.example_data.reduce((acc, obj) => {
          if (obj.pricingTableData !== undefined) {
            return acc.concat(JSON.parse(JSON.stringify(obj.pricingTableData)));
          }
          return acc;
        }, []);
        this.pricingTotalSummery += 1;
      }
      this.selectedTab = val.name;
      this.subtreeValue = val.value;
    },
  },
};
</script>

<style scoped>
.contactAccourdtionNumber {
  padding-right: 10px;
  /* font-family: Open Sans; */
  font-size: 10px;
  font-weight: 400;
  line-height: 10.89px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(122, 122, 122, 1);
}
.newStieAdded {
  color: rgba(84, 189, 149, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}
.delete-icon {
  position: absolute;
  top: 18px;
  right: -35px;
  cursor: pointer;
}

.delete-icon i {
  /* color: red; */
  font-size: 16px; /* Adjust size of the icon */
}
.custom-layout {
  display: grid;
  grid-template-columns: 0.8fr 3.2fr; /* Reduced sidebar size */
  grid-template-rows: auto auto auto auto auto auto;
  gap: 10px;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
}

/* Sidebar */
.custom-sidebar {
  grid-column: 1 / 2;
  grid-row: 1 / 8;
  /* background-color: #f4f4f4; */
  padding: 10px;
  /* border: 1px solid #ddd; */
}

/* Header */
.custom-header {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  /* background-color: #eaeaea; */
  padding: 10px 10px;
  /* border: 1px solid #ddd; */
  text-align: center;
}

/* Order Information */
.custom-order-info {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

/* Contact and Billing Details */
.custom-contact-billing {
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.custom-contact-details {
  text-align: center;
}

.custom-billing-details {
  text-align: center;
}

/* Constructor and Requester Company */
.custom-constructor-requester {
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.custom-constructor-company {
  text-align: center;
}

.custom-requester-company {
  text-align: center;
}

/* Notes Section */
.custom-questions-section {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  text-align: center;
}
/* Notes Section */
.custom-notes-section {
  grid-column: 2 / 3;
  grid-row: 6 / 7;
  padding: 10px;
  text-align: center;
}

/* Attachment Section */
.custom-attachment-section {
  grid-column: 2 / 3;
  grid-row: 7 / 8;
  /* background-color: #f9f9f9; */
  padding: 10px;
  /* border: 1px solid #ddd; */
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .custom-layout {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto auto auto;
  }

  .custom-sidebar {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .custom-header {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .custom-order-info {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .custom-contact-billing {
    grid-column: 1 / 2;
  }

  .custom-constructor-requester {
    grid-column: 1 / 2;
  }

  .custom-notes-section {
    grid-column: 1 / 2;
    grid-row: 6 / 7;
  }

  .custom-attachment-section {
    grid-column: 1 / 2;
    grid-row: 7 / 8;
    margin-bottom: 10px;
  }
}

/* Equipment order specific container styling */
.equipment-order-site-accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  /* border-bottom: 1px solid #ccc; */
}

.left {
  display: flex;
  align-items: center;
}

.cart-icon {
  font-size: 24px;
  color: #000; /* Set desired color for the shopping cart */
  margin-right: 10px;
}

.tick-icon {
  font-size: 20px;
  color: green !important; /* Set green color for the tick */
  margin-left: 10px;
}

.details {
  display: flex;
  flex-direction: column;
}

.name {
  /* font-weight: bold; */
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 100;
}

.address {
  font-size: 12px;
  font-weight: 100;
  color: gray;
}

.right {
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  align-items: center;
}

.one-time,
.recurring {
  font-size: 12px;
  font-weight: 100;
}
</style>
