<template>
<hlx-alert-notification :notify="notification" :auto-close="true" />




    <!-- Filter drawer -->
    <hlx-drawer
                    :show="show_right_filter"
                    :width="300"
                    position="right"
                    :header="false"
                    :footer="false"
                    :show-close-icon="true"
                    @close="closeRightFilter"
                  >
                    <template #body>
                      <div style="display: flex">
                        <FilterComponent
                        :key="filterkey"
                          :filterpanelname="'address'"
                          :company-name="company"
                          :query-value="query"
                          :display-data="filter"
                          :module="'address'"
                          @filtered-data="quotesfilterData"
                        ></FilterComponent>
                      </div>
                    </template>
                  </hlx-drawer>






    <!-- {{ regioncode }} -->
    <div>
      <hlx-modal
        style="z-index: 999999999"
        :modal-active="delete_modal_active"
        :height="'220px'"
        :width="'400px'"
        :modal="true"
        @close="delete_modal_active = false"
      >
        <template #header>
          <div style="margin-left: 0px">Delete data</div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              Are you sure you want to delete the Excluded region?
            </p>
          </div>
        </template>
        <template #footer>
          <hlx-button class="secondary sm" @click="delete_modal_active = false"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 16px"
            @click="deleteExecRow"
            >Delete</hlx-button
          >
        </template>
      </hlx-modal>
    </div>














  <!-- add exclisive drawer -->
  <div class="add-contact-drawer">
    <hlx-drawer
    :key="componentkey"
      :show="showDrawer"
      position="right"
      width="400"
      :footer="true"
      :title="'Edit notes'"
      :show-close-icon="true"
      @close="closeDrawer"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          
          <b v-if="editMode == false" >
             Add exclusion
            </b>
          <b v-else>
            Edit exclusion              
            </b>
        </div>
        <div class="body-area-contact-drawer">
        


  
            <div class="decision-name-wrapper" style="margin-bottom:20px;">
              <hlx-select
                v-model:value="type"
                :required="true"
                :pre-value="type"
                :options="dropdown_data_type"
                :placeholder-value="'Exclude type'"
                :label-animation="true"
                :label="'value'"
                :prop-value="'value'"
                :clearable="true"
              @selected-value="emitExcludeType"
              />
            </div>


            <div v-if="type ==='Partial address'" class="decision-name-wrapper" style="margin-bottom:20px;">
              <hlx-select
                v-model:value="criteria"
                :required="true"
                :pre-value="criteria"
                :options="dropdown_data_criteria"
                :placeholder-value="'Choose criteria'"
                :label-animation="true"
                :label="'value'"
                :prop-value="'value'"
                :clearable="true"
              @selected-value="criteriaEmit"
              />
            </div>
  

            
            <!-- :new-coordinate="newCoordinate" -->
             <!-- {{ addressExist }} -->
            <addressComponent
            :key="addressKey"
                  :required="true"
            :type="type"
            :customnameerror="addressExist"
            :criteria="criteria"
            :module="'exclude'"
            :parent-info="parentinfo"
                :pre-val="addressPreVal"
              @address-data="receivedAddressData"
            ></addressComponent>



                  
                  
                  
                 <div style="margin-bottom:20px;">
                  <hlx-select
                  :key="resonKey"
                      v-model="reason.en"
                :pre-value="reason.en"
                :required="true"
                      :options="reasonList"
                      :label-animation="true"
                :placeholder-value="'Reason'"
                      :label="'value'"
                      :prop-value="'value'"
                      :clearable="true"
                      @selected-value="reasonListEmit"
                    ></hlx-select>
                  </div>




 <div v-for="language in languages" :key="language.code">
    <hlx-input
      v-model:value="reason[language.code]"
      :pre-val="reason[language.code]"
      type="textarea"
      :label-value="'Validation message - ' + capitalizeFirstLetter(language?.name)"
      :required="true"
      :label-animation="true"
      :display-error="true"
      :rows="4"
      :custom-error-message="customErrorMessage"
      @focus-out="focusOutName"
      @at-input="validateName"
    ></hlx-input>
  </div>








        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeDrawer"
            >
            Cancel
            </hlx-button
          >
          <hlx-button
            v-if="editMode == false"
            class="primary sm"
            style="margin-left: 10px"
          :disabled="!enableAdd"
            @click="addExcluded"
            >
            Add
            </hlx-button
          >
          <hlx-button
            v-else
            class="primary sm"
            style="margin-left: 10px"
            @click="addExcluded"
            >
            Save
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>







  
  <section
  v-if="tableData && tableData?.length > 0 || searchvalue !== ''" 
  style="margin: 10px 15px">

    
  <!-- header -->
  <section class="right-view-body-feature">
      <span class="spec-feature --table-tool-bar" style="margin: 10px 0">
        <p
          class="--upcv2-body-title"
        >
        <!-- Exclude region -->
         {{matchFromStatic('Exclude region')}}
        </p>
        <div
          class="--table-tool-bar-wrapper"
        >
          <span class="util-items search"
            ><hlx-search
              v-model="searchvalue"
            :label-value="matchFromStatic('Search')"
              @search-key="searchKey"
            ></hlx-search
          ></span>

          
          <!-- Filter -->
          <!-- <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
              <i class="icon-filter-regular"></i>
            </hlx-button> -->



          <span class="util-items util-items-add"
            ><hlx-button 
            v-if=" permisstionData.add "
              class="primary sm add-btn"
              @click="openDrawer"
              ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i
              >
              <!-- Exclude region -->
               {{matchFromStatic('Exclude region')}}
              </hlx-button
            >
          </span>
        </div>
      </span>
    </section>
  </section>













  <!-- table -->
  <section v-if="tableData && tableData?.length > 0" style="margin: 10px 15px">










      <hlx-table
        :key="refreshTable1"
        :column-count="tableHeads + 2"
        :border="['table', 'header', 'horizontal']"
        :theme="'grey'"
        :bold-headers="false"
        :row-hover="true"
        :striped-rows="false"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="theads"
        @close-rearrange="arrange"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'left'"
            style="padding: 0 15px !important; max-width: 60px !important"
            :fixed="'left'"
            >{{ matchFromStatic("S.no") }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in tableHeads"
            :key="index"
            :prop="i.prop"
            :width="i.width"
            :align="'left'"
            :resizable="true"
          >
            {{ matchFromStatic(i.label) }}
          </hlx-table-head>
          <hlx-table-head v-show="permisstionData.edit || permisstionData.delete" :align="'center'" :width="60" :fixed="'right'"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                style="cursor: default"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr v-for="(i, index) in tableDataOnSelectedRows" :key="index">
            <hlx-table-cell :width="60" fixed="left">
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in tableHeads"
              :key="col_index"
              :align="'left'"
              :width="i.width"
            >
              <div v-if="j.prop === 'Exclude type'">
                
                  <!-- {{i.criteria.typeExclude}} -->
                <!-- <div
                    v-for="(addressLine, index1) in i.streetAddressLine"
                    :key="index1"
                  >
                    <span v-if="addressLine.characteristic.typeExclude">{{
                      addressLine?.characteristic?.typeExclude
                    }}</span>
                  </div>                -->

                  {{ matchFromStatic(i.criteria.typeExclude) }}
                  
              </div>


              <div v-else-if="j.prop === 'Location'" style="width:255px">




                <div v-if="i.criteria.typeExclude === 'Partial address'">
                  <div v-if="i.criteria.criteria === 'Street name + Prefix'">
                    <div>
                      <div class="flex_gap_box_location">
                        <div>{{ i.street }}</div>                      
                          <hlx-label
                        class="info sm" >
                      <span>{{ 'Street' }}</span>
                      </hlx-label>
                      </div>
                      <div class="flex_gap_box_location">
                      <div>{{ i.postalCodePrefix }}</div>
                      <hlx-label
                        class="info sm" >
                      <span>{{ 'FSA' }}</span>
                      </hlx-label>
                    </div>
                    </div>                    
                  </div>
                  <div v-if="i.criteria.criteria === 'Street name + Postal code'">
                    <div>
                      <div class="flex_gap_box_location">
                      <div>{{ i.street }}</div>
                      <hlx-label
                        class="info sm" >
                      <span>{{ 'Street' }}</span>
                      </hlx-label>
                    </div>
                    <div class="flex_gap_box_location">
                      <div>{{ i.postalCode }}</div>
                      <hlx-label
                        class="info sm" >
                      <span>{{ 'Postal code' }}</span>
                      </hlx-label>
                    </div>
                    </div>
                  </div>
                  <div v-if="i.criteria.criteria === 'City + Postal code'">
                    <div>
                      <div class="flex_gap_box_location">                      
                      <div>{{ i.city }}</div>
                      <hlx-label
                        class="info sm" >
                      <span>{{ 'City' }}</span>
                      </hlx-label>
                    </div>
                    <div class="flex_gap_box_location">
                      <div>{{ i.postalCode }}</div>
                      <hlx-label
                        class="info sm" >
                      <span>{{ 'Postal code' }}</span>
                      </hlx-label>
                    </div>
                    </div>
                  </div>
                  <div v-if="i.criteria.criteria === 'City + Prefix'">
                    <div>
                      <div class="flex_gap_box_location"> 
                      <div>{{ i.city }}</div>
                      <hlx-label
                        class="info sm" >
                      <span>{{ 'City' }}</span>
                      </hlx-label>
                    </div>
                    <div class="flex_gap_box_location">
                      <div>{{ i.postalCodePrefix }}</div>
                      <hlx-label
                        class="info sm" >
                      <span>{{ 'FSA' }}</span>
                      </hlx-label>
                    </div>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div>
                    {{ removeFirstComma(i.description) }}
                  </div>
                </div>






                </div>



                <div v-else-if="j.prop === 'Reason'" style="width:165px">
                
                  

                  {{ matchFromStatic(i.criteria.reason.en) }}
              </div>




              <div v-else-if="j.prop === 'Created date'" style="width:150px">
                
                
                  
                  {{ dateAndTimeFormatter(i.createdAt) }}

              </div>





              <div v-else-if="j.prop === 'Last updated'" style="width:150px">
                
                

                  {{dateAndTimeFormatter(i.updatedAt)}}

              </div>

              
             

              <span v-else> 
                {{ i[j.prop] }}                 
              </span>
            </hlx-table-cell>
            <hlx-table-cell v-show="permisstionData.edit || permisstionData.delete" :align="'center'" :width="120" :fixed="'right'">
              <span :id="'table-context' + index" class="action-icon">
                <i
                  style="position: relative"
                  class="icon-more-vertical-filled"
                  :class="i.context === true ? 'active-action' : ''"
                  @click="crudContextMenu($event, i, index)"
                >
                  <hlx-context-menu
                  :display="'display'"
                    :top="containertop"
                    :left="containerleft"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(i)"
                    :data="index.toString()"
                    :show="i.context == true"
                    @chosen="closeCrudContextMenu($event, i, index)"
                  />
                </i>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>

      <!-- space -->

      <div style="height: 31px;width:100px; border: 2px white solid;display: flex;"></div>


      <!-- pagination -->

      <div
          v-if="tableData.length > 0"
          class="media-pagination-container"
          style="height: 71px; z-index: 273"
        >
          <span class="row1"></span>
          <span class="row2" style="z-index: 1">
            <hlx-pagination
              v-model:rows-per-page="externalRowsPerPage"
              style="z-index: 1"
              :total="totalpageno"
              enable-rows-per-page
              :rows-per-page-list="[10, 15, 20]"
              :options="{
                attributes: {
                  rowsPerPageTitle: true,
                  rowsPerPageResult: true,
                  rowsPerPageDropdown: {
                    position: {
                      top: 0,
                      right: 1,
                    },
                  },
                },
              }"
              @current-page="currentPage"
              @updated:rows-per-page="changeRowsPerPage"
            ></hlx-pagination
          ></span>
        </div>




    </section>
    <!-- no data -->
    <section v-if="tableData.length === 0 && searchvalue !== ''" style="margin: 10px 15px">
      <hlx-table
          :column-count="tableHeads.length + 2"
          :border="['table', 'header']"
          :bold-headers="false"
          :row-hover="true"
          theme="grey"
          :striped-rows="false"
          :re-arrange-columns="toggleRearrangeModal"
          :re-arrange-heads="tableHeadsRearranging"
          @close-rearrange="toggleRearrangeModal = false"
          @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head :width="60" :align="'left'"   @new-resize-width="resizedWidth">{{
              matchFromStatic("S.no")
            }}</hlx-table-head>
            <hlx-table-head
              v-for="(i, index) in tableHeads"
              :key="index"
              :prop="i.prop"
              :sortable="i.sortable"
              :resizable="i.resizable"
              style="position: sticky; top: 0px"
              :width="i.width !== undefined ? i.width : ''"
              :align="i.align !== undefined ? i.align : ''"
              @sorting_func="sorting_Data"
                @new-resize-width="resizedWidth"
            >
              {{ matchFromStatic(i.label) }}
            </hlx-table-head>
            <hlx-table-head v-if="permisstionData.edit || permisstionData.delete" v-show="supplierStatus == false" :width="100">
              <span class="action-icon"
                ><i
                  class="icon-settings-regular"
                  @click="toggleRearrangeModal = true"
                    @new-resize-width="resizedWidth"
                ></i></span
            ></hlx-table-head>
          </template>
          <template #tbody>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell :colspan="tableHeads?.length + 2">{{
                matchFromStatic("No data")
              }}</hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
          </template>
        </hlx-table>


    </section>



    
        <!-- image -->
        <section
         v-if="tableData && tableData?.length == 0 && searchvalue === ''"
        style="
          margin-top: 1.2rem;
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          align-items: center;
        "
      >

      <span>
          <img
            src="@/assets/images/svg/ExcludeRegion.svg"
            alt=""
            style="width: 500px; height: 500px"
          />
        </span>
        <span class="util-items util-items-add">
          <hlx-button
          v-if=" permisstionData.add "
            class="primary media add-btn"
            @click="openDrawer"
            ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i
            >
            <!-- Exclude region -->
             {{ matchFromStatic('Exclude region') }}
            </hlx-button
          >
        </span>
        
    
    </section>







</template>

<script>
import {
//   DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
  // UPCAxiosInstance
} from "@/config/axiosConfig";

import FilterComponent from "@/components/filterPanelComponent.vue";

import addressComponent from "@/components/Product/ServiceQualificationDuplicate/dynamicAddress.vue"
export default {
    components: {    
        addressComponent,

    FilterComponent,
},


  props: {
    permisstionData: {
      type: Object,
      required: true, 
      default: () => {},
    },

    parentinfo: {
        type: Object,
        default: null
          },

  },


    data() {
      return {

        addressExist:false,

        languages:  [{'name':"english", "code":"en"}] ,
        
      show_right_filter: false,

        postalCodePrefix: '',

        addressKey: 1,
        resonKey: 1,

        addressPreVal:{
          mediumType: 'postalAddress',
          characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
              default: true,
              postalCodePrefix:'',
            '@type': '',
            lat: null,
            lng:null,
          }
        },

        delete_modal_active: false,
        deleteobj:{},

        reasonList: [
          {value:'Address is outside the serviceable area'},
          { value: 'Infrastructure upgrade required' },
          { value: 'Underground fiber installation required' },
          { value: 'Fiber installation requires additional permits' },
          { value: 'Temporarily unavailable due to maintenance' },
          { value: 'No permission to lay fiber cables in the area' },
          { value: 'Service by our sister company Telebec' },
        ],

        validationEng:"",
        validationFre:"",

        description:'',


            
      tableData:[],

      
      query:{
        'Type':["criteria.typeExclude"]
      },
      

      company: this.$cookies.get("userName"),

      filter:[],

            tableHeads: [
        {
          sortable: false,
          resizable: true,
          label: "Exclude type",
          prop: "Exclude type",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          label: "Location",
          prop: "Location",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          label: "Reason",
          prop: "Reason",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          label: "Created date",
          prop: "Created date",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          label: "Last updated",
          prop: "Last updated",
          align: "left",
        },
      ],



            notification:"",




            showDrawer: false,

        editMode: false,
        editPayload: null,


            dropdown_data_type: [
        { value: "Full address" },
        { value: "Partial address" },
        { value: "Street" },
        { value: "City" },
        { value: "Province" },
        { value: "Postal code" },
        { value: "Prefix (FSA)" }
        ],


        criteria:'',



        dropdown_data_criteria: [
        { value: "Street name + Prefix" },
        { value: 'Street name + Postal code' },
        { value: "City + Postal code" },
        { value: "City + Prefix" },
        // { value: "Postal code" },
        // { value: "Partial address" },
        // { value: "Prefix (FSA)" }
            ],



            
            filteredstatus: [],


            type: '',

            reason: {},

            payload: {},


            componentkey: 0,

            
            filtertaglength: 0,

            
      filterQueryforBill: {
        type: "filter",
        module: "address",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
          filterQuery:          {
    "streetAddressLine.characteristic.category": {
        "$in": [
            "child"
        ]
    }
},
 
        searchQuery: "",
      },

            
      currPage: 1,
      rowsPerPage: 10,
            externalRowsPerPage: 10,

        searchvalue: "",
            
        attribute:[],



            enableAdd:false,

            
        }
  },


    
  computed: {
    tableDataOnSelectedRows(){
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      return this.tableData;
    }
  },
  watch: {


    criteria: {
      handler(val) {
        val
        if (val.toLowerCase() == "street name + prefix") {
          this.attribute = ["matchedAddresses.street", "matchedAddresses.postalCodePrefix"];
        } else if (val.toLowerCase() == "street name + postal code") {
          this.attribute = ["matchedAddresses.street", "matchedAddresses.postalCode"];
        } else if (val.toLowerCase() == "city + postal code") {
          this.attribute = ["matchedAddresses.city", "matchedAddresses.postalCode"];
        } else if (val.toLowerCase() == "city + prefix") {
          this.attribute = ["matchedAddresses.city", "matchedAddresses.postalCodePrefix"];
        }
      },
      immediate: true,
      deep: true
    },

    parentinfo: {
      handler(val) {

        console.log("dropdown_data_type: {:::", this.dropdown_data_type, this.parentinfo);


        if (val && this.parentinfo && this.parentinfo.type) {

          // let vals = this.parentinfo.type.toLowerCase();



          switch (this.parentinfo.type) {
            case "province":
              this.dropdown_data_type = [
                { value: "Full address" },
                { value: "Partial address" },
                // { value: "Street" },
                { value: "City" },
                { value: "Postal code" },
                { value: "Prefix (FSA)" },
              ];
              break;
            case "city":
              this.dropdown_data_type = [
                // { value: "Street" },
                { value: "Partial address" },
                // { value: "Province" },
                { value: "Postal code" },
                { value: "Prefix (FSA)" },
                { value: "Full address" },
              ];
              break;
            // case "street":
            //   this.dropdown_data_type = [
            //     { value: "Postal code" },
            //     { value: "Prefix (FSA)" },
            //   ];
            //   break;
            // case "prefix (fsa)":
            //   this.dropdown_data_type = [
            //     { value: "Postal code" },
            //     { value: "Prefix (FSA)" },
            //   ];
            //   break;
            case "postal code":
              this.dropdown_data_type = [
              { value: "Full address" },
              ];
              break;
            // case "country":
            //   this.dropdown_data_type = [
            //     { value: "Full address" },
            //     { value: "Partial address" },
            //     { value: "Street" },
            //     { value: "City" },
            //     { value: "Postal code" },
            //     { value: "Prefix (FSA)" },
            //     { value: "Province" },
            //   ];
            //   break;
            // Add cases for other types as needed
            // default:
            //     this.dropdown_data_type = this.dropdown_data_type;
          }


        }
      },
      immediate: true,
      deep: true,
    },



    type: {
      async handler(val) {
        val
        if (this.type && this.reason.en && !this.addressExist) {
          this.enableAdd = true;
        } else {
          this.enableAdd = false;
        }



        
        // let fans = await this.fetchCoordinatesPlugin('texas')
        // console.log("fans::123",fans);
      },
      immediate: true,
      deep: true
    },
    reason: {
      handler(val) {
        val
        if (this.type && this.reason.en && !this.addressExist) {
          this.enableAdd = true;
        } else {
          this.enableAdd = false;
        }
      },
      immediate: true,
      deep: true
    },
    addressExist: {
      handler(val) {
        val
        if (this.type && this.reason.en && !this.addressExist) {
          this.enableAdd = true;
        } else {
          this.enableAdd = false;
        }
      },
      immediate: true,
      deep: true
    },
  
  

  },
  


  


  async mounted() { 

    
    // languages: JSON.parse(localStorage.getItem('loginTenantDetailsLocal').languageAbility || [{'name':"english", "code":"en"}] ),

        

    let data = JSON.parse(localStorage.getItem("loginTenantDetailsLocal"))?.languageAbility;
          console.log("data data data cache:::", data);
          if (data === null || data === 'null' || data === undefined || data === 'undefined' || data === '') { 
            data = [{'name':"english", "code":"en"}]
    }

    this.languages = data;

    
      
    
    await this.$store.dispatch("serviceQualificationAddressFilterData2");

    
    this.filter = this.$store.getters.serviceQualificationAddressFilterData2;



        
    let status = await this.getReferanceData('statuslist');
      console.log("status::", status);
      this.filteredstatus = status.filter((item) =>
        ["Active", "Inactive", "Pending"].includes(item.name)
        );




        
    this.ticketdataCalling(this.currPage, this.rowsPerPage);

    },





  methods: {
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },

    loadEditPayload(payload) {
      payload;
      this.editpayload = payload;
      this.editMode = true;
      console.log("loadEditPayload))::", payload);
      payload;

      this.type = payload.criteria.typeExclude
      // this.reason.en = payload.criteria.reason.en
      // this.reason.fr = payload.criteria.reason.fr
      this.reason = payload.criteria.reason
      this.criteria = payload.criteria.criteria
      // let obj = {

      this.city = payload.city;
      this.unit = payload.unit;
      this.country = payload.country;
      this.state = payload.stateOrProvince;
      this.postalCode = payload.postalCode;
      this.postalCodePrefix = payload.postalCodePrefix;
      this.description = payload.description;
      this.street = payload.street;
      this.lat = payload.latitude;
      this.lng = payload.longitude;

      // }

      this.addressPreVal = {
  characteristic: {
    city: payload.city || '',
    unit: payload.unit || '',
    country: payload.country || '',
    state: payload.stateOrProvince || '',
          postalCode: payload.postalCode || '',
          postalCodePrefix: payload.postalCodePrefix || '',
    description: payload.description || '',
    street: payload.street || '',
    lat: payload.latitude || null,
    lng: payload.longitude || null,
  }
};
      



      this.componentkey += 1;
      
      this.showDrawer = true;
     },




    async deleteExecRow() {




      const deleteresponse = await UPCAxiosInstance.delete(
          `/geographicAddress/${this.deleteobj.id}`
        );


      if (deleteresponse) {
        this.notification = {
            type: "success",
            message: this.matchFromStatic('Excluded region deleted successfully!'),
          };
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
        this.delete_modal_active = false;
        }


    },

    clearDrawer() { 

      this.type = ''
      // this.reason.en = ''
      // this.reason.fr = ''
      this.reason = {}
      this.criteria = ''

      let addressPreee = {
        mediumType: 'postalAddress',
        characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
          default: true,
          postalCodePrefix: '',
          '@type': '',
          lat: null,
          lng:null,
        }
      }; 

      this.addressPreVal = addressPreee;
      this.addressKey += 1;
      this.resonKey += 1;
      this.editMode = false;


    },


    criteriaEmit(val) {
      console.log("criteriaEmit:::", val);
      this.criteria = val.value;

          // this.reason.en = ''
      // this.reason.fr = ''
          this.reason = {}

      let addressPreee = {
        mediumType: 'postalAddress',
        characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
          default: true,
          postalCodePrefix: '',
          '@type': '',
          lat: null,
          lng:null,
        }
      }; 
      this.addressPreVal = addressPreee;
      this.addressKey += 1;
      this.resonKey += 1;


     },
      

    // async reasonListEmit(val) {
    //   this.reason.en = val.value;
    //   console.log("reasonListEmit::", this.reason);
    //   // this.validationEng = this.reason
    //   this.reason.fr = await this.languageTransfer(val.value, 'fr')
    // },

    async reasonListEmit(val) {
  this.reason.en = val.value; // Assuming val.value is in English
  console.log("reasonListEmit::", this.reason);

  // Loop through languages and dynamically translate
  const languages = [
    { name: 'english', code: 'en' },
    { name: 'french', code: 'fr' },
    { name: 'tamil', code: 'ta' }
  ];

  // Loop through languages array and translate the value
  for (const language of languages) {
    this.reason[language.code] = await this.languageTransfer(val.value, language.code);
  }

  console.log("Updated reason object with translations:", this.reason);
},



        
    computedTableAction() {
      if (this.permisstionData.delete && this.permisstionData.edit) {
        return [
          {
            display: this.matchFromStatic('Edit'),
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            display: this.matchFromStatic('Delete'),
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else if (this.permisstionData.delete ) {
        return [
          
          {
            display: this.matchFromStatic('Delete'),
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else {
        return [
          {
            display: this.matchFromStatic('Edit'),
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      }
        },


        



        crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 102;

        this.containertop =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().top + 10;  
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },




    async closeCrudContextMenu($event, payload, i) {
      if ($event.toLowerCase() === "edit") {
        $event, payload, i;

        let editPayload = JSON.parse(JSON.stringify(this.tableData[i]));
        // this.editPayload;
        this.loadEditPayload(editPayload);

      // this.$router.push({
      //     name: "invoiceTemplate",
      //     query: { quote_id: payload?._id, mode: "edit" },
      //   });
      } else if ($event.toLowerCase() === "delete") {
        $event, payload, i;
        // this.deleteobj = payload;
        this.delete_modal_active = true;
        this.deleteobj.id = payload.id;
      }
    },


    


        serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
        },


        
        async searchKey(val) {
      console.log("searchKey",val);
      
      this.searchvalue = val;
      this.filterQueryforBill["company"] = this.$cookies.get("company");
      this.filterQueryforBill["type"] = "filter-search"
 || "search";
      this.filterQueryforBill["searchQuery"] = val;
      this.filterQueryforBill.searchList =
       [
      {
          "criteria.typeExclude": {
            $regex: val,
            $options: "i",
          },
          },
          {
          "criteria.reason.en": {
            $regex: val,
            $options: "i",
          },
          },
        
      {
          "description": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "city": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "postalCodePrefix": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "postalCode": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "street": {
            $regex: val,
            $options: "i",
          },
        },
    //     // {
    //     //   "state": {
    //     //     $regex: val.toLowerCase() === "on hold" ? "onhold" : val,
    //     //     $options: "i",
    //     //   },
    //     // },
    //     {
    //       "definition.name": {
    //         $regex: val,
    //         $options: "i",
    //       },
    //     },
    //     {
    //       "tag": {
    //         $regex: val,
    //         $options: "i",
    //       },
    //     },
      ];
      // this.currPage = 1;
      // this.rowsPerPage = 10;
      if (this.filtertagvalue?.length != 0) {
        this.filterQueryforBill["type"] = "filter-search";
      }

      this.ticketdataCalling(this.currPage, this.rowsPerPage);
        },


        


        async ticketdataCalling(currentPage, rowsPerPage) {
  currentPage, rowsPerPage
  console.log("ticketdataCalling...123",currentPage, rowsPerPage);
      this.filterQueryforBill["company"] = this.$cookies.get('company');
        // this.$cookies.get('company');
      this.filterQueryforBill["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQueryforBill["paginatedQuery"]["rowsPerPage"] = rowsPerPage;


      this.filterQueryforBill.filterQuery = {
    "type": {
        "$in": [
            "exclusion"
        ]
    },


          "parentId": {
        "$in": [
            `${this.$route.query.id}`
        ]
    }
            }



      this.filterQueryforBill.module = 'address';
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);
        
        // this.totalvalue = filterResultData.data.total;      
      }
    },



        
    async currentPage(val) {
      if (this.companyasignee != "") {
        this.currPage = val;
        this.ticketdataCalling(
          this.currPage,
        this.rowsPerPage
        );
        // this.refreshTable();
      }
    },

    async changeRowsPerPage(count) {
      //
      if (this.companyasignee != "") {
        this.rowsPerPage = count;
        this.ticketdataCalling(
          this.currPage,
        this.rowsPerPage
        );
        // this.refreshTable();
      }
    },

    async quotesfilterData(filterSelectedvalue, filterQuery) {
      console.log("quotesfilterData::))", filterSelectedvalue, filterQuery);
      this.filtertaglength = filterSelectedvalue?.length;
      this.filterQueryforBill = filterQuery;

      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);
        
        // this.totalvalue = filterResultData.data.total;      
      }
      
        },







        
        openDrawer() {
            this.showDrawer = true;
            this.componentkey += 1;
        },



        closeDrawer() { 
            this.showDrawer = false;
          this.componentkey += 1;
          this.clearDrawer();

        },

        emitExcludeType(val) {
            this.type = val.value;

          // this.reason.en = ''
          // this.reason.fr = ''
          this.reason = {}


            let addressPreee = {
        mediumType: 'postalAddress',
        characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
          default: true,
          postalCodePrefix: '',
          '@type': '',
          lat:null,
          lng:null,
        }
      }; 
      this.addressPreVal = addressPreee;
      this.addressKey += 1;
      this.resonKey += 1;


        },



        async addExcluded() { 

          



            let obj1 = {

                
        description: this.description,
        type: "exclusion",
        parentId: this.$route.query.id,
        criteria: {
          typeExclude: this.type,
          reason: this.reason,
          criteria: this.criteria,
          attribute: this.attribute
            },
        postalCode: this.postalCode,
        street: this.street,
        city: this.city,
        stateOrProvince: this.stateOrProvince,
        country: this.country,
        unit: this.unit,
        latitude: this.latitude,
              longitude: this.longitude,
              postalCodePrefix: this.postalCodePrefix
        

          }


          
          if (this.editMode == false) {
            obj1.updatedAt = `${this.formatCurrentTime()}`
            obj1.createdAt = `${this.formatCurrentTime()}`
            let postresponse = await UPCAxiosInstance.post(
          `/geographicAddress`,
          obj1
            );  

            if (postresponse) {
              this.notification = {
                    type: "success",
                    message: this.matchFromStatic('Excluded region added successfully!'),
            };  
            }
            
                
          } 
          // edit mode
          else {
            obj1.updatedAt = `${this.formatCurrentTime()}`

            let patchresponse = await UPCAxiosInstance.patch(
            `/geographicAddress`,
            {
              id: this.editpayload.id,
              ...obj1,
            }
            );  
            if (patchresponse) {
              this.notification = {
                    type: "success",
                    message: this.matchFromStatic('Excluded region details updated successfully!'),
            };  
            }
            }
            

                this.clearDrawer();
                this.showDrawer = false;
                this.componentkey += 1;

                this.ticketdataCalling(this.currPage, this.rowsPerPage);





        },



        async receivedAddressData(val) {
            console.log("receivedAddressData:::regg::", val);

            this.description = val.characteristic.description
            this.postalCode = val.characteristic.postalCode
            this.street = val.characteristic.street
            this.city = val.characteristic.city
            this.stateOrProvince = val.characteristic.state
            this.country = val.characteristic.country
            this.unit = val.characteristic.unit
            this.latitude = val.characteristic.lat
          this.longitude = val.characteristic.lng
            this.postalCodePrefix = val.characteristic.postalCodePrefix


            let r = await this.callFilterExclusion(this.type, this.criteria, val.characteristic);
        console.log("r::", r);

          this.addressExist = r;
        
            
        },



        formatDate(isoDate) {
    const date = new Date(isoDate);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
},
    }

}
</script>

<style>
.flex_gap_box_location{
  display: flex;
  column-gap: 7px;
  margin: 7px;
}

</style>